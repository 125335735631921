import { useCallback, useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Image,
  Spinner,
} from "react-bootstrap";
import "./contentPlayer.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getDomain } from "../../hooks/apiHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faLock,
  faPauseCircle,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";

// const tracks = [
//   {
//     id: 0,
//     title: "Brahms: St Anthony Chorale - Theme, Two Pianos Op.56b",
//     audioUrl:
//       "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3",
//     relicData:{relicArtworkUrl:'https://cdn.pixabay.com/photo/2014/11/30/14/11/cat-551554_1280.jpg'},
//     metadata: {title:'my great song', artistName: 'great artist'}
//   }
// ];

// tracks is an array of objects with {id: string, url: string}
export default function ContentPlayer({ contentData, contentType, preview }) {
  const { contentId } = useParams();
  // const [selectedContent, setselectedContent] = useState(tracks[0]);
  const [selectedContent, setselectedContent] = useState(null);
  const [userData, setUserData] = useState(null);
  const [viewTracked, setViewTracked] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const audioRef = useRef(null);
  const apiUrl = getDomain();

  const containerRef = useRef(null);

  function sendHeightToParent() {
    
    if (containerRef.current) {
      setTimeout(() => {
        let height = containerRef?.current?.offsetHeight;
        if(height){
        // replace "px" with an empty string
        height = height.toString().replace("px", "");
        console.log('sending height from player', height)
        window.parent.postMessage({ frameHeight: height, source:'player' }, "*");
        }
      }, 100);
    }
  }

  // Send height to window parent after initial mount
  // useEffect(() => {
  //   sendHeightToParent();
  // }, []);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleProgressBarClick = (event) => {
    const progressBarWidth = event.target.offsetWidth;
    const clickPositionInPixels =
      event.clientX - event.target.getBoundingClientRect().left;
    const clickPositionInPercentage =
      (clickPositionInPixels / progressBarWidth) * 100;
    const newAudioTime =
      (clickPositionInPercentage / 100) * audioRef.current.duration;

    audioRef.current.currentTime = newAudioTime;
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      const duration = audioRef.current.duration;
      const calculatedProgress = (currentTime / duration) * 100;
      setProgress(calculatedProgress);

      // Calculate remaining time
      const remaining = duration - currentTime;
      setRemainingTime(remaining);
      if (audioRef.current.ended) {
        setIsPlaying(false);
        setProgress(0);
        setRemainingTime(duration);
      }
    }
  };

  // Format the remaining time in a user-friendly way
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  useEffect(() => {
    console.log("setting up message listener");
    // The event listener function
    const receiveMessage = (event) => {
      // Add security checks such as validating the origin
      // if (event.origin !== "http://example.com") {
      //   return;
      // }

      // Handle the received message
      console.log("Received message:", event.data);
      if (event.data.udValue && event.data.source ==='player_parent') {
        const rawUserData = JSON.parse(event.data.udValue);
        console.log(rawUserData);
        setUserData(rawUserData);
      }
    };

    // Register the event listener
    window.addEventListener("message", receiveMessage);

    let anon_id = localStorage.getItem("anon_id");
    if (!anon_id) {
      anon_id = "anon_" + Math.random().toString(36).substr(2, 16);
      localStorage.setItem("anon_id", anon_id);
    }
    setUserData((prev) => ({
      ...prev,
      anon_id: anon_id,
    }));

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);
  const trackContentViewed = useCallback(async () => {
    let parts = selectedContent?.creator?.id?.split("_");
    let locationId = parts.length > 1 ? parts[1] : null;
    setViewTracked("loading");
    const trackedResponse = await axios.post(`${apiUrl}/actions/content`, {
      ...userData,
      location: { id: locationId || selectedContent?.locationId },
      content: {
        type: contentType || "track", //defaults to track if no type is passed in
        id: contentId,
        title: selectedContent?.title,
      },
      url: window.location.href,
    });
    setViewTracked(trackedResponse);
    console.log(`trackedResponse is`, trackedResponse);
    return trackedResponse;
  }, [
    apiUrl,
    selectedContent,
    contentId,
    userData,
    contentType,
    setViewTracked,
  ]);
  useEffect(() => {
    //once we've listened to more than 10% of the track, send a trackContentViewed webhook
    if (progress > 10 && userData && selectedContent && !viewTracked) {
      trackContentViewed();
    }
  }, [userData, selectedContent, progress, trackContentViewed, viewTracked]);

  useEffect(() => {
    if (selectedContent) {
      // Function to handle the loadedmetadata event
      const handleLoadedMetadata = () => {
        if (audioRef) {
          // Access the duration property after metadata is loaded
          setRemainingTime(audioRef.current.duration);
        }

        // Add an event listener for the loadedmetadata event
        audioRef.current.addEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );

        // Clean up the event listener on component unmount
        return () => {
          audioRef.current.removeEventListener(
            "loadedmetadata",
            handleLoadedMetadata
          );
        };
      };
      if (1 === 0) {
        handleLoadedMetadata(); //this never gets called but makes the react error go away
      }
    }
  }, [selectedContent]); // Make sure to update when the audio URL changes

  useEffect(() => {
    if (contentData) {
      // console.log(`contentData is`, contentData);
      setselectedContent(contentData);
    } else {
      const asyncFetchSong = async () => {
        console.log(":", `${apiUrl}/content/${contentId}`);
        let dataUrl = `${apiUrl}/content/${contentId}`;

        const pulledcontentData = await axios.get(`${dataUrl}`).catch((err) => {
          console.log("err:", err);
        });
        // console.log("pulledcontentData:", pulledcontentData);
        setselectedContent(pulledcontentData?.data);
        // setCurrentSong(prev => ({
        //    ...prev,
        //    audioUrl: 'https://streetteam-storage-dev.s3.amazonaws.com/test/Drake%20Final%20Mix%20Loud%20Ref.mp3',
        //    artworkUrl: 'https://cdn.pixabay.com/photo/2014/11/30/14/11/cat-551554_1280.jpg',
        //    metadata: {title:'my great song'}
        // }));
      };
      asyncFetchSong();
    }
  }, [apiUrl, contentId, contentData]);

  useEffect(() => {
    if (selectedContent) {
      // The data is loaded, send updated height to parent
      sendHeightToParent();
    }
  }, [selectedContent]);

  if (!selectedContent) {
    return (
      <Container fluid className="transparent-theme">
        <Container fluid className="rounded-dark-theme content-center">
          <Spinner />
        </Container>
      </Container>
    );
  }
  return (
    // <Container
    //   className="d-flex flex-column justify-content-center vh-100 bg-white"
    //   // style={{ height: "1000px" }}
    // >
    <Container
      ref={containerRef}
      fluid
      className="transparent-theme px-0 h-75"
      // style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
    >
      <Container
        fluid
        className="rounded-dark-theme shadow fluid p-0"
        style={{
          background:
            "radial-gradient(at top left, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, .95) 95%)",
        }}
      >
        {/* {contentType === "relic" && !preview && !window.location.href.includes('preview') && userData && !userData.id && (
          <div className="overlay">
            <div className="lock-icon">
              <FontAwesomeIcon className="full-icon" icon={faLock} />
            </div>
          </div>
        )} */}
        <Row className="align-items-center m-0">
          <Col className="px-0" xs={4}>
            <Image className="p-0"
              width="100%"
              height="100%"
              src={selectedContent?.image?.url || 'https://assets.streetteam.me/icons/streetteam-logo.png'}
              rounded
              onLoad={sendHeightToParent}
            />
          </Col>
          <Col
            xs={8}
            className="d-flex flex-column justify-content-center ps-4 pe-4 my-1 gap-1"
          >
            {/* Title */}
            <div className="">
              <h4 className="text-white my-0 track-title">
                {selectedContent?.title || "Track"}
              </h4>
            </div>

            {/* Artist Name */}
            <div className="">
              <h2 className="content-player my-0 track-artist">
                {selectedContent?.creator?.name || "Artist"}
              </h2>
            </div>

            {/* Progress Bar, Time Remaining and Play Button */}
            <div className="d-flex align-items-center my-0 gap-3">
              <ProgressBar
                className="streamProgressBar"
                variant="light"
                now={progress}
                onClick={handleProgressBarClick}
                style={{ flex: 4, cursor: "pointer" }}
              />
              <span className="content-player" style={{ flex: 1 }}>
                -{formatTime(remainingTime)}
              </span>
              <div className="ml-3" style={{ flex: 1 }}>
                <button
                  className="px-0"
                  onClick={togglePlayPause}
                  style={{
                    // height: "48px",
                    // width: "48px",
                    border: "none",
                    background: "transparent",
                  }}
                >
                  <FontAwesomeIcon
                    size="lg"
                    color="white"
                    className="button-icon w-100"
                    icon={!isPlaying ? faPlayCircle : faPauseCircle}
                  />
                </button>
              </div>
            </div>
          </Col>
        </Row>
        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          src={selectedContent?.audio?.url}
        />
      </Container>
    </Container>
    // </Container>
  );
}
