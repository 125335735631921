import React, { useState, useEffect } from "react";
import Alert from 'react-bootstrap/Alert';
import { useLocation } from "react-router-dom";

/**
 * 
 * @returns This componenet just takes an email passed as a query parameter and displays a warning widget using that email address
 */
const ExistingLocationWarning = () => {
    const [error, setError] = useState();
    // Parse URL parameters
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    useEffect(() => {
        setError(email);
      }, [email]);

    return (
      
      error ?
        <div style={{ textAlign: "center" }}>
        <Alert variant="danger" style={{ width: "100%", height: "100%"}}>
        <Alert.Heading>
        ⚠️Warning⚠️
        </Alert.Heading>
        <b>{error}</b> already has a StreetTeam subscription.
        <br/>
        <a href="https://app.streetteam.me" target="_blank" rel="noreferrer" style={{ color: 'blue' }}>Login to your existing account</a> or create a new account using a different email.
        </Alert>
        </div>
        :
        null
      
    )
}

export default ExistingLocationWarning;
