import React from "react";
import { Form, Row, Col, Button, Spinner, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRefresh,
  faCheckCircle,
  faTimesCircle,
  faClone,
} from "@fortawesome/free-solid-svg-icons";

const DomainSetupStep3 = ({
  funnelLinks,
  domain,
  handleLinkChange,
  handleLinkValidation,
  ADD_DOMAIN_LINK,
}) => {
  return (
    <div className="d-flex flex-column">
      <Accordion defaultActiveKey="0" flush>
        {/* Step 1: Clone the Fan Magnet Template */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <strong>1. Clone the Fan Magnet template.</strong>
          </Accordion.Header>
          <Accordion.Body className="d-flex justify-content-center flex-column">
            <div className="video-container">
              <iframe
                className="video-iframe"
                title="domainStep1Gif"
                src="https://share.descript.com/embed/ckyGVtROXXG"
                frameBorder="0"
                allowFullScreen
              ></iframe>{" "}
            </div>
            <Button
              variant="secondary"
              className="mt-3"
              onClick={() =>  window.open(ADD_DOMAIN_LINK, "_blank", "width=1280,height=720")}
            >
              <FontAwesomeIcon className="me-2" icon={faClone} />
              Clone Template
            </Button>
          </Accordion.Body>
        </Accordion.Item>

        {/* Step 2: Customize Your Links */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <strong>2. Verify your links.</strong>
          </Accordion.Header>
          <Accordion.Body>
            <Form.Group controlId="funnelLinks">
              {Object.keys(funnelLinks).map((key) => (
                <Row key={key} className="mt-2 align-items-center">
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="rounded-start"
                        style={{
                          backgroundColor: "#f0f0f0",
                          padding: "7px 8px",
                        }}
                      >
                        <span>{domain}</span>
                      </div>
                      <Form.Control
                        className="rounded-start-0 rounded-end"
                        type="text"
                        value={funnelLinks[key].path}
                        onChange={(e) => handleLinkChange(e.target.value, key)}
                      />
                      <div
                        // className="ms-2"
                        style={{ flexShrink: 0 }}
                      >
                        {funnelLinks[key].validationState === "in_progress" ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="ms-1"
                            style={{
                              // cursor: "pointer",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                        ) : funnelLinks[key].validationState === "validated" ? (
                          <FontAwesomeIcon
                            style={{
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                            }}
                            className="text-success ms-1"
                            icon={faCheckCircle}
                            onClick={() => handleLinkValidation(key)}
                          />
                        ) : funnelLinks[key].validationState !== "idle" ? (
                          <FontAwesomeIcon
                            style={{
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                            }}
                            className="text-danger ms-1"
                            icon={faTimesCircle}
                            onClick={() => handleLinkValidation(key)}
                          />
                        ) : (
                          <></>
                        )}
                      </div>{" "}
                    </div>
                  </Col>
                </Row>
              ))}

              <div className="d-flex flex-column mt-2">
                <Button
                  variant="secondary"
                  onClick={() => {
                    Object.keys(funnelLinks).forEach(handleLinkValidation);
                  }}
                >
                  <FontAwesomeIcon className="me-2" icon={faRefresh} />
                  Verify Links
                </Button>
              </div>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default DomainSetupStep3;
