import React from "react";
import "./App.scss";
import SubmitRelics from "./widgets/relics/SubmitRelics";
import { Route, Routes } from "react-router-dom";
import SubmitRelicSourceFiles from "./widgets/relics/SubmitRelicSourceFiles";
import SubmitRelicFinalAssets from "./widgets/relics/SubmitRelicFinalAssets";
import HomePage from "./pages/HomePage";
import SingleRelicAssetSubmission from "./widgets/relics/SubmitRelicSourceFiles_generic";
import ChatWidget from "./widgets/artistAi/ChatWidget";
import GPTIFrameConfigurator from "./widgets/artistAi/GPTIFrameConfigurator";
import UsageDashboard from "./widgets/artistAi/UsageDashboard";
import Page from "./components/Page";
import ExistingLocationWarning from "./widgets/sales/ExistingLocationWarning";
import ActionSummary from "./pages/actionsDashboard/actionsDashboard";
import ArtistAI from "./pages/artistAiEngage";
import FunnelAccelerator from "./pages/FunnelAccelerator";
import UploadSong from "./pages/uploadTrack/UploadSong";
import UploadRelic from "./pages/musicRelics/uploadRelic/UploadRelic";
import ContentPlayer from "./pages/streamContent/ContentPlayer";
import AdCreator from "./pages/adCreator";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthCallback from "./auth/AuthCallback";
import MetaMetricsConnection from "./pages/FunnelAccelerator/MetaMetricsConnection";
// import Onboarding from "./pages/ArtistLaunch";
// import UploadRelicSubmission from "./widgets/relics/SubmitRelicSourceFiles";
import ArtistLaunchHub from "./pages/ArtistLaunch";
import LocationProvider from "./context/Locations/LocationProvider";
import RelicHub from "./pages/ArtistLaunch/components/RelicHub/RelicHub";
import SoundBoard from "./pages/SoundBoards";
// import CardContainer from "./pages/ArtistLaunch/components/RelicHub/RelicHubPreview";
import RelicHubPreview from "./pages/ArtistLaunch/components/RelicHub/RelicHubPreview";
import CustomerBilling from "./pages/CustomerBilling";
import { ArtistApplication } from "./pages/ArtistLaunch/components/Journey/Step1Application/ArtistApplication";
import TestApiAuth from "./pages/Testing/ApiAuth";
import ErrorBoundary from "./components/ErrorBoundary";
import AiApplicationSummary from "./pages/ArtistLaunch/components/Journey/Step1Application/AiApplicationSummary";
import ApplicationReview from "./pages/ArtistLaunch/components/Journey/Step1Application/ApplicationReview";
import IFrameConfigurator from "./widgets/IFrameConfigurator/IFrameConfigurator";
import ContactProvider from "./context/Contacts/ContactProvider";
import ApplicationReviewWidget from "./pages/ArtistLaunch/components/Journey/Step1Application/ApplicationReviewWidget";
import NotFound from "./pages/NotFound";
import Relic from "./pages/ArtistLaunch/components/RelicHub/Relic";
import AccountVerification from "./pages/CustomerBilling/AccountVerification";
import ArtistIdForm from "./pages/CustomerBilling/AdminBillingPortal";
import FanMap from "./pages/FanMap/components/FanMap";
// import DiscordIntegration from "./pages/discordIntegration/discordIntegration";

const App = () => {
  return (
    <>
      <ErrorBoundary>
        <LocationProvider>
          <ContactProvider>
            <ToastContainer autoClose={3000} />
            <Routes>
              <Route
                path="/"
                element={
                  <Page title="Home Page">
                    <HomePage />
                  </Page>
                }
              ></Route>
              <Route
                path=":notFound/*"
                element={
                  <Page title="Not Found">
                    <NotFound />
                  </Page>
                }
              ></Route>
              <Route
                path="/location/:locationId/soundboard"
                element={
                  <Page title="SoundBoard">
                    <SoundBoard />
                  </Page>
                }
              />
              <Route
                path="/:accountSlug/map"
                element={
                  <Page title="Fan Map">
                    <FanMap />
                  </Page>
                }
              />
              <Route
                path="/account/verification"
                element={
                  <Page title="Account Verification">
                    <AccountVerification />
                  </Page>
                }
              />
              <Route
                path="app-summary"
                element={
                  <Page title="Application Summary">
                    <AiApplicationSummary />
                  </Page>
                }
              />
              <Route
                path="app-review"
                element={
                  <Page title="Artist Breakthrough Application">
                    <ApplicationReview />
                  </Page>
                }
              />
              <Route
                path="apply"
                element={
                  <Page title="Artist Breakthrough Application">
                    <ArtistApplication />
                  </Page>
                }
              />
              <Route
                path="apply-live"
                element={
                  <Page title="Artist Breakthrough Application">
                    <ArtistApplication />
                  </Page>
                }
              />
              <Route path="/page-testing">
                <Route
                  path="auth"
                  element={
                    <Page title="Test API Auth">
                      <TestApiAuth />
                    </Page>
                  }
                />
                <Route
                  path="apply"
                  element={
                    <Page title="Artist Breakthrough">
                      <ArtistApplication testmode={true} />
                    </Page>
                  }
                />
                <Route
                  path="app-review"
                  element={
                    <Page title="Artist Breakthrough Application">
                      <ApplicationReview />
                    </Page>
                  }
                />
                <Route
                  path="app-live"
                  element={
                    <Page title="Artist Breakthrough Application">
                      <ArtistApplication testmode={true} />
                    </Page>
                  }
                />
                <Route
                  path="app-review-widget"
                  element={
                    <Page title="Test App Review">
                      <ApplicationReviewWidget testmode={true} />
                    </Page>
                  }
                />
              </Route>
              <Route path="/widgets">
                <Route
                  path="location-warning"
                  element={
                    <Page title="Warning">
                      <ExistingLocationWarning />
                    </Page>
                  }
                />
                <Route
                  path="submit-relics"
                  element={
                    <Page title="Submit Relics">
                      <SubmitRelics />
                    </Page>
                  }
                />
                <Route
                  path="upload-relic"
                  element={
                    <Page title="Relic creator">
                      <UploadRelic />
                    </Page>
                  }
                />
                <Route
                  path="submit-relic-generic"
                  element={
                    <Page title="Single Relic Asset Submission">
                      <SingleRelicAssetSubmission />
                    </Page>
                  }
                />
                <Route
                  path="upload-relic-assets"
                  element={
                    <Page title="Submit Relic Source Files">
                      <SubmitRelicSourceFiles />
                    </Page>
                  }
                />
                <Route
                  path="submit-completed-relic"
                  element={
                    <Page title="Submit Relic Final Assets">
                      <SubmitRelicFinalAssets />
                    </Page>
                  }
                />
                <Route
                  path="chat"
                  element={
                    <Page title="Chat Widget">
                      <ChatWidget />
                    </Page>
                  }
                />
              </Route>
              <Route path="/actions">
                <Route
                  path="dashboard"
                  element={
                    <Page title="Actions Dashboard">
                      <ActionSummary />
                    </Page>
                  }
                />
              </Route>
              <Route path="launch">
                <Route
                  path=""
                  element={
                    <Page title="Artist Launch">
                      <ArtistLaunchHub />
                    </Page>
                  }
                />
              </Route>
              {/* <Route
                path=":step"
                element={
                  <Page title="Artist Launch">
                    <ArtistLaunchHub />
                  </Page>
                }
              /> */}
              {/* <Route
              path="start"
              element={
                <Page title="Getting Started">
                  <Onboarding />
                </Page>
              }
            />
            <Route
              path="streams"
              element={
                <Page title="Streams">
                  <UploadSong />
                </Page>
              }
            />
            <Route
              path="fans"
              element={
                <Page title="Fans">
                  <UploadRelicSubmission />
                </Page>
              }
            />
            <Route
              path="sales"
              element={
                <Page title="Sales">
                  <UploadRelicSubmission />
                </Page>
              }
            /> */}
              <Route path="preview">
                <Route
                  path="hub/:locationId/:relicId"
                  element={
                    <Page title="Relic Hub Preview">
                      <RelicHubPreview />
                    </Page>
                  }
                ></Route>
              </Route>
              <Route
                path="funnel-accelerator"
                element={
                  <Page title="Funnel Accelerator">
                    <FunnelAccelerator />
                  </Page>
                }
              ></Route>
              <Route
                path="billing-portal"
                element={
                  <Page title="Customer Billing Portal">
                    <CustomerBilling />
                  </Page>
                }
              ></Route>
              <Route
                path="admin-billing-portal"
                element={
                  <Page title="Admin Billing Portal">
                    <ArtistIdForm />
                  </Page>
                }
              ></Route>
              <Route
                path="hub/:locationId/:relicId"
                element={
                  <Page title="Relic Hub">
                    <RelicHub />
                  </Page>
                }
              ></Route>
              <Route
                path="relic/:locationId/:relicId"
                element={
                  <Page title="Relic">
                    <Relic />
                  </Page>
                }
              ></Route>
              <Route path="auth">
                <Route
                  path="meta"
                  element={
                    <Page title="Auth Meta">
                      <MetaMetricsConnection />
                    </Page>
                  }
                />
                <Route
                  path="callback"
                  element={
                    <Page title="Auth Meta Callback">
                      <AuthCallback />
                    </Page>
                  }
                />
              </Route>
              <Route path="/streaming">
                <Route
                  path="upload-track"
                  element={
                    <Page title="Upload Song">
                      <UploadSong />
                    </Page>
                  }
                />
                <Route
                  path="relic/:contentId"
                  element={
                    <Page title="Stream">
                      <ContentPlayer contentType="relic" />
                    </Page>
                  }
                />
                <Route
                  path="track/:contentId"
                  element={
                    <Page title="Stream">
                      <ContentPlayer contentType="track" />
                    </Page>
                  }
                />
              </Route>
              <Route path="/config">
                <Route
                  path="gpt"
                  element={
                    <Page title="GPT IFrame Configurator">
                      <GPTIFrameConfigurator />
                    </Page>
                  }
                />
                <Route
                  path="iframe"
                  element={
                    <Page title="IFrame Configurator">
                      <IFrameConfigurator />
                    </Page>
                  }
                />
                <Route
                  path="engage"
                  element={
                    <Page title="ArtistAI Engage | Configuration">
                      <ArtistAI />
                    </Page>
                  }
                />
                <Route
                  path="ads"
                  element={
                    <Page title="Ad Creator">
                      <AdCreator />
                    </Page>
                  }
                />
              </Route>
              <Route
                path="/usage"
                element={
                  <Page title="Usage">
                    <UsageDashboard />
                  </Page>
                }
              />
            </Routes>
          </ContactProvider>
        </LocationProvider>
      </ErrorBoundary>
    </>
  );
};

export default App;
