import React, { useState, useEffect, useCallback } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Container,
  Table,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { FUNNEL_DEFINITION } from "./utils";

// Create axios instance
// const axiosInstance = axios.create({
//   baseURL: window.location.hostname.includes("localhost")
//     ? process.env.REACT_APP_LOCAL_DOMAIN
//     : "",
// });
const axiosInstance = axios.create({
  baseURL: "https://cloud.streetteam.me",
});

const ActionSummary = () => {
  const [funnelFilters, setFunnelFilters] = useState({});
  const [funnelData, setFunnelData] = useState({ summary7: {}, summary14: {} });
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [modalData, setModalData] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  const api_key = queryParams.get("api_key");
  const location_id = queryParams.get("location_id");

  useEffect(() => {
    if (!api_key || !location_id) {
      alert("Missing api_key or location_id in the URL.");
    }
  }, [api_key, location_id]);

  const handleInputChange = (event) => {
    setFunnelFilters((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  function formatData(data7, data14) {
    const new7Data = data7;
    const new14Data = data14;
    let summary7 = {};
    let summary14 = {};
    new7Data.forEach((item) => {
      const action = item?.value?.properties?.name;
      summary7[action] = {
        count: (summary7[action]?.count || 0) + 1,
        items: [...(summary7[action]?.items || []), item],
      };
    });
    new14Data.forEach((item) => {
      const action = item?.value?.properties?.name;
      summary14[action] = {
        count: (summary14[action]?.count || 0) + 1,
        items: [...(summary14[action]?.items || []), item],
      };
    });
    // const newDataKeys = Object.keys(summary);
    // newDataKeys.forEach((key) => {
    //   const dataToFilter = summary[key].items;
    //   newFormat[key] = {
    //     filtered7: { count: new7Data.length, items: new7Data },
    //     filtered14: { count: new14Data.length, items: new14Data },
    //     filtered30: { count: dataToFilter.length, items: dataToFilter },
    //   };
    // });
    return { summary7, summary14 };
  }

  const loadData = useCallback(async () => {
    try {
      setIsLoading(true);
      let today = new Date();
      today.setDate(today.getDate() - 1); // Dont count results from today
      let prev7DateStart = new Date();
      let prev14DateStart = new Date();
      let prev14DateEnd = new Date();
      prev7DateStart.setDate(today.getDate() - 6);
      prev14DateStart.setDate(today.getDate() - 13);
      prev14DateEnd.setDate(today.getDate() - 7);

      const apiParams = {
        actions: "",
        campaigns: "",
        mediums: "",
        sources: "",
        location_id,
        breakdowns: "",
        expand: "datetime",
        ...funnelFilters,
      };

      const response7 = await axiosInstance.get("/action-summary2", {
        headers: {
          Authorization: `Bearer ${api_key}`,
        },
        params: {
          start_date: prev7DateStart.toLocaleDateString(),
          end_date: today.toLocaleDateString(),
          ...apiParams,
        },
      });

      const response14 = await axiosInstance.get("/action-summary2", {
        headers: {
          Authorization: `Bearer ${api_key}`,
        },
        params: {
          start_date: prev14DateStart.toLocaleDateString(),
          end_date: prev14DateEnd.toLocaleDateString(),
          ...apiParams,
        },
      });

      // const response30 = await axiosInstance.get("/action-summary2", {
      //   headers: {
      //     Authorization: `Bearer ${api_key}`,
      //   },
      //   params: {
      //     start_date: prev30Date.toLocaleDateString(),
      //     end_date: prev14Date.toLocaleDateString(),
      //     ...apiParams,
      //   },
      // });
      const { summary7, summary14 } = formatData(
        response7?.data?.results,
        response14?.data?.results
      );
      setFunnelData({ summary7, summary14 });
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [api_key, location_id, funnelFilters]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    loadData();
  };

  const FunnelRow = ({ rowSchema }) => {
    function getVals(d) {
      switch (rowSchema.type) {
        case "action":
          return d[rowSchema.title]?.count;
        case "calculation":
          const numerators = d[rowSchema.numerator]?.count;
          const denominators = d[rowSchema.denominator]?.count;
          if (!numerators || !denominators) {
            return "-";
          }
          const calculatePercentage = (numerator, denominator) => {
            let percentage = (numerator / denominator) * 100;
            if (Number.isInteger(percentage)) {
              return percentage; // return the integer value
            } else if (percentage < 1) {
              return Number(percentage.toFixed(2)); // return with two decimal places
            } else {
              return Number(percentage.toFixed(1)); // return with one decimal place
            }
          };
          const calc =
            denominators !== 0
              ? calculatePercentage(numerators, denominators)
              : "-";

          return `${calc}%`;
        default:
          return 0;
      }
    }
    function getItems(d) {
      switch (rowSchema.type) {
        case "action":
          return d[rowSchema.title]?.items;
        default:
          return 0;
      }
    }
    const val1 = getVals(funnelData.summary7);
    const val2 = getVals(funnelData.summary14) + val1;

    return (
      <tr
        style={{ fontWeight: rowSchema.type !== "calculation" ? "bold" : "" }}
      >
        <td>{rowSchema.title}</td>
        <td
          onClick={() => setModalData(getItems(funnelData.summary7))}
          style={{ cursor: "pointer" }}
        >
          {val1 || "-"}
        </td>
        <td>{val2 || "-"}</td>
        {/* <td>{val3 || "-"}</td> */}
        <td>Coming Soon</td>
      </tr>
    );
  };

  const DetailRow = ({ data }) => {
    const date = new Date(data?.value?.datetime).toDateString();
    return (
      <tr>
        <td style={{ fontSize: 12 }}>{date}</td>
        <td style={{ fontSize: 12 }}>{data?.value?.contact?.email}</td>
        <td style={{ fontSize: 12 }}>
          {JSON.stringify(data?.value?.properties)}
        </td>
      </tr>
    );
  };

  return (
    <div>
      <Container
        style={{
          border: "1px solid lightgray",
          borderRadius: "5px",
          padding: "20px",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Campaigns</Form.Label>
                <Form.Control
                  type="text"
                  name="campaigns"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>Mediums</Form.Label>
                <Form.Control
                  type="text"
                  name="mediums"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Sources</Form.Label>
                <Form.Control
                  type="text"
                  name="sources"
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">
            {isLoading ? <Spinner /> : "Update"}
          </Button>
        </Form>
      </Container>
      <Container
        style={{
          border: "1px solid lightgray",
          borderRadius: "5px",
          padding: "20px",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th></th>
              <th>Last 7 Days</th>
              <th>Last 14 Days</th>
              <th>Last 30 Days</th>
            </tr>
          </thead>
          <tbody>
            {FUNNEL_DEFINITION.map((r) => (
              <FunnelRow key={r.field} rowSchema={r} />
            ))}
          </tbody>
        </Table>
      </Container>
      <Modal
        show={!!modalData}
        onHide={() => setModalData(null)}
        centered
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflow: "scroll" }}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Email</th>
                <th>Properties</th>
              </tr>
            </thead>
            <tbody>
              {(modalData || []).map((i) => (
                <DetailRow key={i?.value?.datetime} data={i} />
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalData(null)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionSummary;
