import { useState, useEffect, useContext } from "react";
// import { useLocation } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMusic,
  faHeart,
  faCommentDollar,
  faRocket,
  faCalendarDay,
  faUserAlt,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import "../../App.scss";
// import { api } from "../api";
import ArtistProfile from "./components/Onboarding/Step1ArtistProfile/ArtistProfile";
import Streams from "./components/Onboarding/Step2Streams/Streams";
import Fans from "./components/Onboarding/Step3Fans/Fans";
import Sales from "./components/Onboarding/Step4Sales/Sales";
import OnboardingCard from "./components/Onboarding/OnboardingCard";
import LocationContext from "../../context/Locations/LocationContext";
import ContactContext from "../../context/Contacts/ContactContext";
import { toast } from "react-toastify";
import { useLocationDataCheck } from "../../hooks/useLocationDataCheck";
import { fetchMilestones } from "./api";
import Milestones from "./components/Milestones/Milestones";
// import { useMemo } from "react";
import Launch from "./components/Onboarding/Step5Launch/Launch";
import Breakthrough from "./components/Journey/Step2Breakthrough";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArtistApplication } from "./components/Journey/Step1Application/ArtistApplication";
// import AiApplicationSummary from "./components/Journey/Step1Application/AiApplicationSummary";
import ApplicationReview from "./components/Journey/Step1Application/ApplicationReview";

// import { set } from "react-hook-form";

const onboardingCards = [
  {
    icon: faUserAlt,
    title: "Artist Profile",
    description: "Create your artist profile.",
    actionText: "Get Started",
    actionValue: "profile",
    col: 12,
  },
  {
    icon: faMusic,
    title: "Streams",
    description: "Introduce your music to new listeners.",
    actionText: "Get Streams",
    actionValue: "streams",
    col: 4,
  },
  {
    icon: faHeart,
    title: "Fans",
    description: "Convert new listeners into engaged fans.",
    actionText: "Get Fans",
    actionValue: "fans",
    col: 4,
  },
  {
    icon: faCommentDollar,
    title: "Sales",
    description: "Offer exclusive content to your new fans.",
    actionText: "Get Sales",
    actionValue: "sales",
    col: 4,
  },
  {
    icon: faRocket,
    title: "Launch",
    description: "Launch your StreetTeam™ community.",
    actionText: "Get Launched",
    actionValue: "launch",
    col: 12,
  },
];

const journeyCards = [
  {
    icon: faCalendarDay,
    title: "Next Step",
    description: "Book an Artist Breakthrough™ session.",
    actionText: "Book Free Call",
    actionValue: "application",
    col: 12,
  },
];

const milestoneCards = [
  {
    category: "streams",
    icon: faMusic,
    title: "Streams",
    description: "Introduce your music to new listeners.",
    actionText: "Copy Link",
    actionValue: "application",
    col: 4,
    formatType: "integer",
  },
  {
    category: "fans",
    icon: faHeart,
    title: "Fans",
    description: "Convert new listeners into engaged fans.",
    actionText: "Copy Link",
    actionValue: "application",
    col: 4,
    formatType: "integer",
  },
  {
    category: "sales",
    icon: faCommentDollar,
    title: "Sales",
    description: "Offer exclusive content to your new fans.",
    actionText: "Copy Link",
    actionValue: "application",
    col: 4,
    formatType: "currency",
  },
];

// Assuming 'steps' is an array with values ['profile', 'streams', 'fans', 'sales', 'launch']
// const steps = ["profile", "streams", "fans", "sales", "launch"];

const computeOnboardingStepStatus = (onboardingSteps, testmode = false) => {
  if (!onboardingSteps) return {};
  // console.log(
  //   "computeOnboardingStepStatus incoming onboardingSteps",
  //   onboardingSteps
  // );
  const isDate = (date) => {
    // console.log("isDate invoked with date:", date);
    return !isNaN(Date.parse(date));
  };

  let statuses = {
    profile: isDate(onboardingSteps.step_1) ? "completed" : "incomplete",
    streams: isDate(onboardingSteps.step_2) ? "completed" : "incomplete",
    fans: isDate(onboardingSteps.step_3) ? "completed" : "incomplete",
    sales: isDate(onboardingSteps.step_4) ? "completed" : "incomplete",
    launch: isDate(onboardingSteps.step_5) ? "completed" : "incomplete",
  };

  if (testmode) {
    statuses = {
      profile: "completed",
      streams: "completed",
      fans: "completed",
      sales: "completed",
      launch: "incomplete",
    };
  }

  // console.log("computeOnboardingStepStatus initial statuses", statuses);

  // Define the ordered steps
  const stepsOrder = ["profile", "streams", "fans", "sales", "launch"];

  // Find the first step that is 'incomplete' and set it to 'next'
  const nextStep = stepsOrder.find((step) => statuses[step] === "incomplete");
  if (nextStep) {
    statuses[nextStep] = "next";
  } else {
    // If no steps are 'incomplete', all steps are 'completed'
    // Optionally set all statuses to 'completed' here if required
  }

  console.log("computeOnboardingStepStatus final statuses", statuses);

  return statuses;
};

function ArtistLaunchHub() {
  // const inactiveStyle = {
  //   opacity: 0.35,
  // };
  // const navigate = useNavigate(); // React Router's useNavigation hook

  // const location = useLocation();
  const [active, setActive] = useState("");
  // const [showApplicationReview, setShowApplicationReview] = useState(false);
  const { locationData, setLocationData } = useContext(LocationContext);
  const [contactData] = useContext(ContactContext);
  const [allStepsCompleted, setAllStepsCompleted] = useState(false); // New state variable
  const [editAfterComplete, setEditAfterComplete] = useState(false);
  const [milestoneData, setMilestoneData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [launchFields, setLaunchFields] = useState({});
  const [stepStatus, setStepStatus] = useState(() =>
    computeOnboardingStepStatus(locationData?.onboarding)
  );
  // const [artistJourney, setArtistJourney] = useState({
  //   breakthrough: contactData?.breakthrough ?? {},
  //   momentum: contactData?.momentum ?? {},
  // });

  const [breakthroughJourney, setBreakthroughJourney] = useState([
    { id: "masterclass", completed: false, fields: {}, steps: [] },
    {
      id: "application",
      completed: false,
      fields: { ...contactData?.breakthrough?.application?.fields },
      steps: [],
    },
    { id: "breakthrough", completed: false, fields: {}, steps: [] },
    { id: "momentum", completed: false, fields: {}, steps: [] },
    { id: "program", completed: false, fields: {}, steps: [] },
  ]);

  // const [artistJourney, setArtistJourney] = useState([
  //   { id: "annual_vision", completed: false, fields: {}, steps: [] },
  //   { id: "artist_identity", completed: false, fields: {}, steps: [] },
  //   { id: "streetteam_onboarding", completed: false, fields: {}, steps: [] },
  //   { id: "fan_magnet", completed: false, fields: {}, steps: [] },
  //   { id: "tribal_accelerator", completed: false, fields: {}, steps: [] },
  //   { id: "revenue_maximizer", completed: false, fields: {}, steps: [] },
  //   { id: "automation_machine", completed: false, fields: {}, steps: [] },
  //   { id: "optimization", completed: false, fields: {}, steps: [] },
  //   { id: "deep_dive", completed: false, fields: {}, steps: [] },
  // ]);

  // Function to update a specific step's completion status

  useEffect(() => {
    if (contactData?.breakthrough?.application?.summary) {
      setBreakthroughJourney((prevSteps) =>
        prevSteps.map((step) =>
          step.id === "application" ? { ...step, completed: true } : step
        )
      );
    }
  }, [contactData?.breakthrough?.application?.summary]);

  // useEffect to track changes in breakthroughJourney
  useEffect(() => {
    console.log("breakthroughJourney changed:", breakthroughJourney);
  }, [breakthroughJourney]);

  useLocationDataCheck(locationData);

  // const [applicationSummary, setApplicationSummary] = useState([]);

  // console.log("locationData", locationData);

  const isAnyStepActive = active ? true : false;

  // console.log("Parent component rendering");

  useEffect(() => {
    // load data
    if (!locationData?.id) return;
    async function getMilestones(locationId) {
      try {
        const data = await fetchMilestones(locationId); // replace 'your-location-id' with the actual value
        setMilestoneData(data);
        setIsLoaded(true);
      } catch (error) {
        console.error("Error fetching milestones:", error);
        setIsLoaded(true);
        toast.error("Error fetching milestones");
      }
    }

    getMilestones(locationData?.id);
  }, [locationData?.id]);

  // Assuming 'steps' is an array of step names in the correct order
  const steps = ["profile", "streams", "fans", "sales", "launch"];

  // Mapping between step names and their indices
  const stepNameToIndex = {
    profile: 1,
    streams: 2,
    fans: 3,
    sales: 4,
    launch: 5,
  };

  // Function to get step index by name
  const getStepIndex = (stepName) => {
    return stepNameToIndex[stepName];
  };

  // // useEffect to track changes in locationData
  useEffect(() => {
    // Function to check if an object is empty
    const isObjectEmpty = (obj) => {
      return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    // Check if locationData is null or an empty object
    if (!locationData || isObjectEmpty(locationData)) return;

    console.log("locationData changed:", locationData);
  }, [locationData]);

  // Update stepStatus when locationData changes
  useEffect(() => {
    const newStepStatus = computeOnboardingStepStatus(locationData?.onboarding);
    console.log('computeOnboardingStepStatus is',newStepStatus);
    console.log(`newStepStatus of profile is`, newStepStatus['profile']);
    setStepStatus(newStepStatus);

    if (newStepStatus.launch === "completed") {
      setAllStepsCompleted(true);
    }
  }, [locationData?.onboarding]);

  const setCompleted = (stepName, fields) => {
    console.log(
      "setCompleted invoked with step:",
      stepName,
      "and fields:",
      fields
    );

    // Update launchFields state
    setLaunchFields((prevLaunchFields) => {
      console.log("Previous launchFields:", prevLaunchFields);
      return {
        ...prevLaunchFields,
        [stepName]: fields,
      };
    });

    // Update locationData state
    setLocationData((prevState) => {
      const stepIndex = getStepIndex(stepName);
      const currentStepKey = `step_${stepIndex}`;
      const currentDate = new Date().toISOString().split("T")[0]; // Setting to today's date in "YYYY-MM-DD"

      let newOnboarding = {
        ...prevState.onboarding,
        [currentStepKey]: currentDate,
      };

      console.log("New onboarding data:", newOnboarding);

      return {
        ...prevState,
        onboarding: newOnboarding,
      };
    });

    // After updating the locationData, compute the new statuses
    const newStepStatus = computeOnboardingStepStatus(locationData?.onboarding);

    // Set active to the next step or clear if all steps are completed
    const nextStep = steps.find((step) => newStepStatus[step] === "next");
    setActive("");

    // If all steps are completed
    if (!nextStep) {
      console.log("all steps are completed. Setting all steps to completed.");
      setAllStepsCompleted(true);
      setActive("application");
    }
  };

  // Function to navigate back to the Onboarding Hub
  const navigateBackToHub = () => {
    setActive(""); // Clear the active step to display the Onboarding Hub
  };

  const completeBreakthroughJourneyStep = (stepId, fields) => {
    setBreakthroughJourney((prevJourney) => {
      // Find the index of the step in the array
      const stepIndex = prevJourney.findIndex((step) => step.id === stepId);

      if (stepIndex !== -1) {
        // Create a new array with the updated step
        const updatedJourney = [...prevJourney];
        const currentStep = updatedJourney[stepIndex];

        // Update the step with new fields
        updatedJourney[stepIndex] = {
          ...currentStep,
          completed: true, // Set to true if the step is completed
          fields: { ...currentStep.fields, ...fields },
        };

        // Show success message
        if (stepId === "application") {
          toast.success("Successfully submitted");
          setActive("");
        }

        return updatedJourney;
      }
      return prevJourney;
    });
  };

  // next steps are [breakthrough, momentum, optimization, graduation]

  // navigate("/launch");

  // useEffect(() => {
  //

  //   if (stepStatuses.launch === "completed") {
  //     setAllStepsCompleted(true);
  //   }
  // }, [stepStatuses]);

  // useEffect(() => {
  //   if (location.pathname === "/onboarding/streams") {
  //     setActive("streams");
  //   } else if (location.pathname === "/onboarding/fans") {
  //     setActive("fans");
  //   } else if (location.pathname === "/onboarding/sales") {
  //     setActive("sales");
  //   } else {
  //   }
  // }, [location]);

  return (
    <div
      className="min-vh-100"
      style={{
        backgroundColor: "#F9FAFB",
        paddingTop: "50px",
        paddingBottom: "50px",
        // marginTop: "50px",
        marginBottom: "50px",
      }}
    >
      {isLoaded ? (
        <Container
          className=""
          style={{
            backgroundColor: "#fff",
            border: "1px solid #e0e0e0",
            padding: "40px",
            borderRadius: "10px",
          }}
        >
          <div className="text-center">
            <img
              src="https://images.leadconnectorhq.com/image/f_webp/q_80/r_1200/u_https://assets.cdn.filesafe.space/ZxaXDE2BkNQQ1H0xq2vR/media/651567f15cf2e994a365fd5a.png"
              alt="ARTIST LAUNCH"
              style={{ width: "300px", height: "auto" }}
            />
            <p style={{ fontSize: "20px", marginTop: "-10px" }}>
              Attract, engage, and monetize your fanbase.
            </p>
            <hr className="my-4" />
          </div>
          {isAnyStepActive ? (
            <>
              <button
                className="custom-back-button"
                onClick={navigateBackToHub}
              >
                <i className="fa fa-arrow-left"></i> Back to Launch Hub
              </button>

              {active === "profile" && (
                <ArtistProfile setCompleted={setCompleted} />
              )}
              {active === "streams" && <Streams setCompleted={setCompleted} />}
              {active === "fans" && <Fans setCompleted={setCompleted} />}
              {active === "sales" && <Sales setCompleted={setCompleted} />}
              {active === "launch" && (
                <Launch
                  milestoneCards={milestoneCards}
                  milestoneData={milestoneData}
                  setMilestoneData={setMilestoneData}
                  setCompleted={setCompleted}
                  stepStatus={stepStatus}
                  launchFields={launchFields}
                  setActive={setActive}
                />
              )}
              {active === "application" &&
                (contactData?.breakthrough?.application?.summary ? (
                  <ApplicationReview contact={contactData} />
                ) : (
                  <>
                    <Container
                      className="my-4 d-flex flex-column justify-content-center align-items-center gap-3" // Added flexbox centering
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #e0e0e0",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        borderRadius: "10px",
                      }}
                    >
                      <h2 className="text-center mt-4">
                        Book Your Artist Breakthrough Session™
                      </h2>
                      <ArtistApplication
                        setCompleted={completeBreakthroughJourneyStep}
                      />
                    </Container>
                  </>
                ))}
              {active === "breakthrough" && (
                <Breakthrough setCompleted={completeBreakthroughJourneyStep} />
              )}
            </>
          ) : allStepsCompleted && milestoneData && !editAfterComplete ? (
            <>
              <button
                className="custom-back-button"
                onClick={() => setEditAfterComplete(true)}
              >
                <i className="fa fa-arrow-left"></i> Edit my Information
              </button>

              <Milestones
                milestoneCards={milestoneCards}
                milestoneData={milestoneData}
                setActive={setActive}
              />
              {!contactData?.breakthrough?.application?.summary ? (
                <Row className="mt-4 text-center">
                  {journeyCards.map((card, index) => (
                    <Col className="mb-4" md={card.col || 12} key={index}>
                      <OnboardingCard
                        icon={card.icon}
                        title={card.title}
                        description={card.description}
                        actionText={card.actionText}
                        setActive={setActive}
                        actionValue={card.actionValue}
                        style={card.style || {}}
                        status={stepStatus[card.actionValue]}
                      />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Row className="mt-4 text-center">
                  <Col className="mb-4" md={12}>
                    <Container
                      className="my-4 d-flex justify-content-center flex-column"
                      style={{
                        backgroundColor: "#fff",
                        border: "1px solid #e0e0e0",
                        borderRadius: "10px",
                        padding: "30px",
                        maxWidth: "100vw",
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon
                        className="mb-3"
                        icon={faUserCheck}
                        size="3x"
                      />
                      <h1> Congratulations! </h1>{" "}
                      <h5> You've completed the Artist Launch Challenge.</h5>
                      <p className="mt-2">
                        {" "}
                        Stay tuned for next steps coming soon.{" "}
                      </p>{" "}
                      <Button
                        variant="link"
                        className="text-secondary my-0"
                        onClick={() => setActive("application")}
                      >
                        View Application Details
                      </Button>
                    </Container>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              {allStepsCompleted && editAfterComplete && (
                <button
                  className="custom-back-button"
                  onClick={() => setEditAfterComplete(false)}
                >
                  <i className="fa fa-arrow-left"></i> Back to Milestones
                </button>
              )}

              <Row className="mt-4 text-center">
                {onboardingCards.map((card, index) => {
                  if (card.actionValue !== "launch") {
                    return (
                      <Col className="mb-4" md={card.col || 12} key={index}>
                        <OnboardingCard
                          icon={card.icon}
                          title={card.title}
                          description={card.description}
                          actionText={card.actionText}
                          setActive={setActive}
                          actionValue={card.actionValue}
                          style={card.style || {}}
                          status={stepStatus[card.actionValue]}
                        />
                      </Col>
                    );
                  }
                  return null;
                })}
                {journeyCards.map((card, index) => (
                  <Col className="mb-4" md={card.col || 12} key={index}>
                    <OnboardingCard
                      icon={card.icon}
                      title={card.title}
                      description={card.description}
                      actionText={card.actionText}
                      setActive={setActive}
                      actionValue={card.actionValue}
                      style={card.style || {}}
                      status={stepStatus[card.actionValue]}
                    />
                  </Col>
                ))}
              </Row>
              <Row className="mt-4 text-center"></Row>
            </>
          )}
        </Container>
      ) : (
        <div className="text-center vh-100 d-flex flex-column justify-content-center align-items-center">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
}

export default ArtistLaunchHub;
