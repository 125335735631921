import {
  Button,
  Card,
  Container,
  Spinner,
  // Tooltip,
  // OverlayTrigger,
} from "react-bootstrap";
// import { PlusSquare, DashSquare } from "react-bootstrap-icons";
// import moment from "moment"; // You can install moment.js for date manipulation

import { useContext, useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  faSave,
  faCancel,
  // faSyncAlt,
  // faCheckCircle,
  // faSpinner,
  // faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import "../../App.scss";
import {
  fetchConnectionData,
  fetchGoalsData,
  fetchMetricsData,
  // fetchSpendData,
  saveGoalsData,
  // syncMetaAds,
} from "./api";
import logo from "../../mm-logo-white.png";
import { FunnelRow } from "./FunnelRow";
import { ONE_DAY, TIME_FRAMES } from "./constants";
import {
  getValue,
  formatPercentage,
  formatCurrency,
  formatMultiplier,
  calculateRate,
  calculateValuePerMetric,
  getFormattedValue,
} from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LocationContext from "../../context/Locations/LocationContext";
import { useLocationDataCheck } from "../../hooks/useLocationDataCheck";

const FunnelAccelerator = () => {
  const { locationData } = useContext(LocationContext);
  const [data, setData] = useState(null);
  const [goals, setGoals] = useState({
    adSpend: 20,
    costPerStream: 0.3,
    optinRate: 25,
    salesRate: 20,
    valuePerSale: 10,
    valuePerFan: 0,
    valuePerStream: 0,
    costPerFan: 0,
    costPerSale: 0,
    streams: 1000,
    fans: 0,
    sales: 0,
    profitMultiplier: 0,
    newRevenue: 0,
  });

  const [showDetailedMetrics, setShowDetailedMetrics] = useState(false);
  // const [showCostMetrics, setShowCostMetrics] = useState(false);
  const [showValuePerStreamMetrics, setShowValuePerStreamMetrics] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const windowUrl = useLocation();
  const searchParams = new URLSearchParams(windowUrl.search);

  const location_id = locationData?.id || searchParams.get("crm_location_id");
  const api_key =
    searchParams.get("api_key") || localStorage.getItem("api_key") || "";

  const [originalGoals, setOriginalGoals] = useState({
    adSpend: 20,
    costPerStream: 0.3,
    optinRate: 25,
    salesRate: 20,
    valuePerSale: 10,
    valuePerFan: 0,
    valuePerStream: 0,
    costPerFan: 0,
    costPerSale: 0,
    streams: 1000,
    fans: 0,
    sales: 0,
    profitMultiplier: 0,
    newRevenue: 0,
  });
  const [isEdited, setIsEdited] = useState(false);
  // const [metaConnection, setMetaConnection] = useState(null);
  // const [isSyncing, setIsSyncing] = useState(false);

  useLocationDataCheck(locationData);

  // useEffect(() => {
  //   fetchGoalsData(location_id)
  //     .then((data) => {
  //       setGoals(data);
  //       setOriginalGoals(data);
  //     })
  //     .catch((error) => {
  //       // Handle error here, perhaps set some state to show an error message in your UI
  //     });
  // }, [location_id]);

  //useEffect to log changes to goals.streams
  useEffect(() => {
    console.log("goals.streams changed to: ", goals.streams);
  }, [goals.streams]);

  useEffect(() => {
    // Setting location_id and api_key to localStorage
    // Overwrite if they don't match
    if (location_id && localStorage.getItem("location_id") !== location_id) {
      localStorage.setItem("location_id", location_id);
    }
    if (api_key && localStorage.getItem("api_key") !== api_key) {
      localStorage.setItem("api_key", api_key);
    }
  }, [location_id, api_key]);

  useEffect(() => {
    if (
      goals &&
      originalGoals &&
      JSON.stringify(goals) !== JSON.stringify(originalGoals)
    ) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  }, [goals, originalGoals]);

  useEffect(() => {
    setGoals((prevGoals) => {
      // const streams = Math.round(
      //   prevGoals?.adSpend / prevGoals?.costPerStream || 0
      // );
      // const streams = prevGoals?.streams || 1000;
      const fans = Math.round((goals?.streams * prevGoals?.optinRate) / 100);
      const sales = Math.round((fans * prevGoals?.salesRate) / 100);
      const newRevenue = parseFloat(
        (sales * prevGoals?.valuePerSale).toFixed(2)
      );
      const costPerFan =
        parseFloat((prevGoals?.adSpend / fans).toFixed(2)) || 0;
      const costPerSale =
        parseFloat((prevGoals?.adSpend / sales).toFixed(2)) || 0;
      const valuePerFan = parseFloat((newRevenue / fans).toFixed(2)) || 0;
      const valuePerStream =
        parseFloat((newRevenue / goals?.streams).toFixed(2)) || 0;
      const profitMultiplier =
        parseFloat((valuePerStream / 0.004).toFixed(2)) || 0;

      return {
        ...prevGoals,
        fans,
        sales,
        newRevenue,
        costPerFan,
        costPerSale,
        valuePerFan,
        valuePerStream,
        profitMultiplier,
      };
    });
  }, [
    goals?.adSpend,
    goals?.streams,
    goals?.costPerStream,
    goals?.optinRate,
    goals?.salesRate,
    goals?.valuePerSale,
  ]);

  function mapMetricNames(data) {
    return {
      spend: {
        total: data.spend?.total ?? 0,
        impressions: data.spend?.impressions ?? 0,
      },
      metrics: {
        count: {
          streams: data.count?.content ?? 0,
          fans: data.count?.registration ?? 0,
          sales: data.count?.purchase ?? 0,
          vips: data.count?.subscription ?? 0,
        },
        unique: {
          streams: data.unique?.content ?? 0,
          fans: data.unique?.registration ?? 0,
          sales: data.unique?.purchase ?? 0,
          vips: data.unique?.subscription ?? 0,
        },
      },
      value: {
        total: data.value?.total ?? 0,
        new: data.value?.purchase ?? 0,
        recurring: data.value?.subscription ?? 0,
      },
    };
  }

  const formatDateToISOString = (date, timeZone) => {
    const dateInTimeZone = new Date(date.toLocaleString("en-US", { timeZone }));
    return dateInTimeZone.toISOString().split("T")[0];
  };

  const handleSave = async () => {
    console.log("Saving goals data:", goals);
    try {
      let savedGoalData = await saveGoalsData(location_id, goals);
      console.log("Saved goals data:", savedGoalData);
      setOriginalGoals(goals);
      setIsEdited(false);
    } catch (error) {
      toast.error("Error saving goals data");
      console.error("Error saving goals data:", error);
    }
  };

  const handleCancel = () => {
    setGoals(originalGoals);
    setIsEdited(false);
  };

  // const fetchAndUpdateAdSpend = async () => {
  //   const timeframes = [7, 14, 30, 90];
  //   const timeframeLabels = {
  //     7: "7 Days",
  //     14: "14 Days",
  //     30: "30 Days",
  //     90: "90 Days",
  //   };
  //   // console.log("Starting fetchAndUpdateAdSpend...");

  //   for (let days of timeframes) {
  //     const label = timeframeLabels[days];

  //     // console.log(`Fetching ad spend data for the past ${days} days...`);

  //     const until = moment().format("YYYY-MM-DD"); // Today's date
  //     const since = moment().subtract(days, "days").format("YYYY-MM-DD"); // Date 'days' ago
  //     // console.log(`Time range: ${since} to ${until}`);

  //     const adSpendData = await fetchSpendData(
  //       metaConnection?.ad_account_id,
  //       metaConnection?.access_token,
  //       since,
  //       until
  //     );

  //     // console.log("Fetched ad spend data:", adSpendData);

  //     if (adSpendData && adSpendData > 0) {
  //       // console.log("Updating data...");
  //       setData((prevData) => {
  //         const updatedData = { ...prevData };

  //         // console.log("Previous data:", updatedData);

  //         if (updatedData.hasOwnProperty(label)) {
  //           updatedData[label].spend.total = parseFloat(adSpendData);
  //         }

  //         // console.log("Updated data:", updatedData);
  //         return updatedData;
  //       });
  //     } else {
  //       // console.log("No ad spend data to update.");
  //     }
  //   }

  //   // console.log("Completed fetchAndUpdateAdSpend.");
  //   // Update the last_sync_date to current date-time
  //   metaConnection.last_sync_date = new Date().toISOString();

  //   // Update the state with the new last_sync_date
  //   setMetaConnection(metaConnection);
  // };

  // const handleSyncMetaAds = async (locationId) => {
  //   try {
  //     setIsSyncing(true);
  //     await syncMetaAds(locationId);
  //     // console.log("Synced meta ads:", data);

  //     // Fetch and update ad spend
  //     await fetchAndUpdateAdSpend();
  //     setIsSyncing(false);

  //     // Handle successful sync here, e.g., update state or show notification
  //   } catch (error) {
  //     setIsSyncing(false);
  //     console.error("Error while syncing meta ads:", error);
  //     // Handle error here, e.g., show error message
  //   }
  // };

  // Function to determine the appropriate icon and tooltip text
  // const getSyncIconAndTooltip = (last_sync_date) => {
  //   const currentDate = new Date();
  //   const lastSyncDate = new Date(last_sync_date);

  //   const isToday = currentDate.toDateString() === lastSyncDate.toDateString();

  //   if (isSyncing) {
  //     return {
  //       icon: faSpinner,
  //       color: "blue",
  //       tooltip: "Syncing...",
  //     };
  //   } else if (isToday) {
  //     return {
  //       icon: faCheckCircle,
  //       color: "green",
  //       tooltip: `Last Synced: ${lastSyncDate.toLocaleDateString()}`,
  //     };
  //   } else {
  //     return {
  //       icon: faSyncAlt,
  //       color: "red",
  //       tooltip: "Out of Sync",
  //     };
  //   }
  // };

  // const SyncIconWithTooltip = ({
  //   last_sync_date,
  //   handleSync,
  //   location_id,
  //   metaConnection,
  // }) => {
  //   if (!metaConnection?.isComplete) {
  //     return (
  //       <FontAwesomeIcon
  //         className="ms-2"
  //         icon={faExclamationCircle}
  //         style={{ cursor: "pointer" }}
  //         onClick={() => {
  //           window.location.href = "/auth/meta";
  //         }}
  //       />
  //     );
  //   }
  //   const { icon, color, tooltip } = getSyncIconAndTooltip(last_sync_date);

  //   return (
  //     <OverlayTrigger
  //       placement="right"
  //       delay={{ show: 250, hide: 400 }}
  //       overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
  //     >
  //       <FontAwesomeIcon
  //         className="ms-2"
  //         icon={icon}
  //         style={{ cursor: "pointer", color }}
  //         onClick={() => handleSync(location_id)}
  //       />
  //     </OverlayTrigger>
  //   );
  // };

  useEffect(() => {
    if (!location_id || !api_key) {
      // window.alert("You need to add location_id and api_key!");
      return;
    }

    const fetchData = async () => {
      try {
        console.log("Starting fetchData");

        const timeZone =
          Intl.DateTimeFormat().resolvedOptions().timeZone ||
          "America/New_York";
        console.log(`Time zone determined as: ${timeZone}`);

        const today = new Date(
          new Date().toLocaleString("en-US", { timeZone })
        );
        console.log(`Today's date: ${today}`);

        const timeframes = TIME_FRAMES.map(({ days, label }) => {
          const startDate = new Date(today - days * ONE_DAY);
          console.log(
            `Time frame - ${label}: Start Date: ${startDate}, End Date: ${today}`
          );
          return { startDate, endDate: today, label };
        });

        let fetchedTimeframeData = {};
        for (const timeframe of timeframes) {
          const formattedStartDate = formatDateToISOString(
            timeframe.startDate,
            timeZone
          );
          const formattedEndDate = formatDateToISOString(today, timeZone);
          console.log(
            `Fetching metrics data for timeframe: ${timeframe.label}, Start Date: ${formattedStartDate}, End Date: ${formattedEndDate}`
          );

          const response = await fetchMetricsData(
            location_id,
            formattedStartDate,
            formattedEndDate
          );

          const mappedData = mapMetricNames(response.metrics);
          console.log(`Data fetched for ${timeframe.label}:`, mappedData);
          fetchedTimeframeData[timeframe.label] = mappedData;
        }

        console.log("Fetched timeframe data:", fetchedTimeframeData);

        console.log("Fetching connection data");
        const connectionData = await fetchConnectionData(location_id);
        console.log("Connection data fetched:", connectionData);

        console.log("Fetching goals data");
        const goalsResponse = await fetchGoalsData(location_id);
        console.log("Goals data fetched:", goalsResponse);

        let goalData = null;
        if (goalsResponse?.goalData) {
          goalData = goalsResponse?.goalData;
          console.log("Goal data found:", goalData);
        } else if (goalsResponse === "No Goals Found") {
          console.log("No goals found, processing initial goals");
          const initialGoals = {
            adSpend: 20,
            costPerStream: 0.3,
            optinRate: 25,
            salesRate: 20,
            valuePerSale: 10,
            valuePerFan: 0,
            valuePerStream: 0,
            costPerFan: 0,
            costPerSale: 0,
            streams: 1000,
            fans: 0,
            sales: 0,
            profitMultiplier: 0,
            newRevenue: 0,
          };
          console.log("Saving initial goals:", initialGoals);
          const savedData = await saveGoalsData(location_id, initialGoals);
          console.log("Saved initial goals:", savedData.result);
          goalData = savedData.result;
        }

        console.log("Setting states with fetched data");
        setData(fetchedTimeframeData);
        setGoals(goalData);
        setOriginalGoals(goalData);
      } catch (error) {
        console.error("An error occurred during fetching:", error);
        toast.error("An error occurred during fetching");
      }
    };

    fetchData();
  }, [api_key, location_id]);

  const metricPaths = {
    streams: { path: "metrics.unique.streams", formatter: (v) => v || 0 },
    fans: { path: "metrics.unique.fans", formatter: (v) => v || 0 },
    sales: { path: "metrics.count.sales", formatter: (v) => v || 0 },
    vips: { path: "metrics.unique.vips", formatter: (v) => v || 0 },
    adSpend: {
      path: "spend.total",
      formatter: (v) => formatCurrency(v ?? 0),
    },
    costPerStream: {
      path: "spend.total",
      secondaryPath: "metrics.unique.streams",
      formatter: calculateValuePerMetric,
      postFormatter: formatCurrency,
    },
    costPerFan: {
      path: "spend.total",
      secondaryPath: "metrics.unique.fans",
      formatter: calculateValuePerMetric,
      postFormatter: formatCurrency,
    },
    costPerSale: {
      path: "spend.total",
      secondaryPath: "metrics.count.sales",
      formatter: calculateValuePerMetric,
      postFormatter: formatCurrency,
    },
    optinRate: {
      path: "metrics.unique.fans",
      secondaryPath: "metrics.unique.streams",
      formatter: calculateRate,
      postFormatter: formatPercentage,
    },
    salesRate: {
      path: "metrics.unique.sales",
      secondaryPath: "metrics.unique.fans",
      formatter: calculateRate,
      postFormatter: formatPercentage,
    },
    valuePerSale: {
      path: "value.new",
      secondaryPath: "metrics.count.sales",
      formatter: calculateValuePerMetric,
      postFormatter: formatCurrency,
    },
    valuePerFan: {
      path: "value.new",
      secondaryPath: "metrics.unique.fans",
      formatter: calculateValuePerMetric,
      postFormatter: formatCurrency,
    },
    valuePerStream: {
      path: "value.new",
      secondaryPath: "metrics.unique.streams",
      formatter: calculateValuePerMetric,
      postFormatter: formatCurrency,
    },
    valuePerSpotifyStream: {
      path: "value.new",
      secondaryPath: "metrics.unique.streams",
      formatter: (newValue, streams) =>
        streams === 0 || newValue === 0 || isNaN(newValue)
          ? 0
          : formatCurrency(0.004, 3, true),
    },
    profitMultiplier: {
      path: "value.new",
      secondaryPath: "metrics.unique.streams",
      formatter: (newValue, streams) =>
        streams === 0 || isNaN(newValue)
          ? 0
          : formatMultiplier(newValue / streams / 0.004),
    },
    newRevenue: { path: "value.new", formatter: (v) => formatCurrency(v ?? 0) },
    recurringRevenue: {
      path: "value.recurring",
      formatter: (v) => formatCurrency(v ?? 0),
    },
    totalRevenue: {
      path: "value.total",
      formatter: (v) => formatCurrency(v ?? 0),
    },
  };

  const calculateMetrics = (metric) => {
    let timeFrames = [
      data["7 Days"],
      data["14 Days"],
      data["30 Days"],
      data["90 Days"],
    ];
    const { path, secondaryPath, formatter, postFormatter } =
      metricPaths[metric];
    return [
      0,
      ...timeFrames.map((dataFrame) => {
        const value = getValue(dataFrame, path);
        const secondaryValue = secondaryPath
          ? getValue(dataFrame, secondaryPath)
          : undefined;
        return postFormatter
          ? postFormatter(formatter(value, secondaryValue))
          : formatter(value, secondaryValue);
      }),
    ];
  };

  const RowBreak = () => {
    return <div className="w-100 bg-black" style={{ height: 15 }}></div>;
  };

  const syncGoals = (timeframe) => {
    // Assuming data is already fetched and available
    // console.log("Syncing goals for", timeframe);
    if (data && data[timeframe]) {
      setGoals((prevGoals) => {
        const { metrics, spend, value } = data[timeframe];
        const streams = metrics?.unique?.streams ?? 0;
        const fans = metrics?.unique?.fans ?? 0;
        const sales = metrics?.count?.sales ?? 0;
        const adSpend = spend?.total ?? 0;
        const newRevenue = value?.new ?? 0;

        const costPerStream =
          streams === 0 ? 0 : parseFloat((adSpend / streams).toFixed(2));
        const optinRate =
          streams === 0 ? 0 : parseFloat(((fans / streams) * 100).toFixed(2));
        const salesRate =
          fans === 0 ? 0 : parseFloat(((sales / fans) * 100).toFixed(2));
        const valuePerSale =
          sales === 0 ? 0 : parseFloat((newRevenue / sales).toFixed(2));
        const valuePerFan =
          fans === 0 ? 0 : parseFloat((newRevenue / fans).toFixed(2));
        const valuePerStream =
          streams === 0 ? 0 : parseFloat((newRevenue / streams).toFixed(2));
        const costPerFan =
          fans === 0 ? 0 : parseFloat((adSpend / fans).toFixed(2));
        const costPerSale =
          sales === 0 ? 0 : parseFloat((adSpend / sales).toFixed(2));
        const profitMultiplier = parseFloat(
          (valuePerStream / 0.004 || 0).toFixed(2)
        );

        let newGoals = {
          ...prevGoals,
          adSpend,
          costPerStream,
          optinRate,
          salesRate,
          valuePerSale,
          valuePerFan,
          valuePerStream,
          costPerFan,
          costPerSale,
          streams,
          fans,
          sales,
          newRevenue,
          profitMultiplier,
        };

        // console.log("New goals:", newGoals);

        return newGoals;
      });
    } else {
      toast.error(`${timeframe} data is not available.`);
      console.error(`${timeframe} data is not available.`);
    }
  };

  const cancelButton = isEdited ? (
    <Button variant="outline-info" className="me-2 mt-2" onClick={handleCancel}>
      <FontAwesomeIcon className="me-1" icon={faCancel} /> Cancel
    </Button>
  ) : null;

  const saveButton = isEdited ? (
    <Button className="mt-2" onClick={handleSave}>
      <FontAwesomeIcon className="me-1" icon={faSave} /> Save
    </Button>
  ) : null;

  if (!data) {
    return (
      <Card
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontSize: "24px",
        }}
      >
        <Spinner animation="border" role="status" />
        {/* <Row className="mt-3">Loading...</Row> */}
      </Card>
    );
  } else {
    return (
      <>
        <Container
          fluid
          className="px-5 py-3 flex-column align-items-center"
          style={{
            minWidth: "800px",
            // maxWidth: "1400px",
            margin: "0 auto",
          }}
        >
          <div
            className="d-flex flex-column rounded"
            style={{ border: "15px solid black" }}
          >
            <FunnelRow
              title={
                <img
                  src={logo}
                  alt="Modern Musician Logo"
                  style={{ maxHeight: "40px", maxWidth: "100%" }}
                />
              }
              data={["Goal", "7 Days", "14 Days", "30 Days", "90 Days"]}
              cellClasses=""
              isHeader={true}
              syncGoals={syncGoals}
            />
            <RowBreak />
            {/* <FunnelRow
              title={
                <>
                  <div>
                    Ad Spend
                    <SyncIconWithTooltip
                      last_sync_date={metaConnection?.last_sync_date}
                      handleSync={handleSyncMetaAds}
                      location_id={location_id}
                      metaConnection={metaConnection}
                    />
                  </div>
                </>
              }
              data={[
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    className="form-control"
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      color: "transparent", // Make the input text transparent
                      backgroundColor: "transparent",
                      border: isEditing
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : "none",
                      outlineColor: "rgba(0, 0, 0, 0.12)",
                      boxShadow: "none",
                    }}
                    onFocus={() => setIsEditing(true)}
                    onBlur={() => setIsEditing(false)}
                    value={goals?.adSpend}
                    onChange={(e) =>
                      setGoals((prevGoals) => ({
                        ...prevGoals,
                        adSpend: e.target.value,
                      }))
                    }
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      pointerEvents: "none", // Make the span unclickable
                    }}
                  >
                    {formatCurrency(goals?.adSpend, 2)}
                  </span>
                </div>,
                ...calculateMetrics("adSpend").slice(1),
              ]}
            />
            <RowBreak />
            <FunnelRow
              title="Cost Per Stream"
              toggleMetrics={true}
              showMetrics={showCostMetrics}
              onClick={() => setShowCostMetrics((prev) => !prev)}
              data={[
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    step="0.01"
                    className={`form-control ${
                      isEditing ? "blinking-cursor" : ""
                    }`}
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      color: "transparent", // Make the input text transparent
                      backgroundColor: "transparent",
                      border: isEditing
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : "none",
                      outlineColor: "rgba(0, 0, 0, 0.12)",
                      boxShadow: "none",
                    }}
                    onFocus={() => setIsEditing(true)}
                    onBlur={() => setIsEditing(false)}
                    value={goals?.costPerStream}
                    onChange={(e) =>
                      setGoals((prevGoals) => ({
                        ...prevGoals,
                        costPerStream: parseFloat(e.target.value),
                      }))
                    }
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      pointerEvents: "none", // Make the span unclickable
                    }}
                  >
                    {getFormattedValue(goals?.costPerStream, "currency")}
                  </span>
                </div>,
                ...calculateMetrics("costPerStream").slice(1),
              ]}
            />
            {showCostMetrics && (
              <>
                <FunnelRow
                  title="Cost Per Fan"
                  data={[
                    getFormattedValue(goals?.costPerFan, "currency"),
                    ...calculateMetrics("costPerFan").slice(1),
                  ]}
                />
                <FunnelRow
                  title="Cost Per Sale"
                  data={[
                    getFormattedValue(goals?.costPerSale, "currency"),
                    ...calculateMetrics("costPerSale").slice(1),
                  ]}
                />
              </>
            )} */}
            <RowBreak />
            {/* <FunnelRow
              title="Streams"
              data={[
                getFormattedValue(goals?.streams?.toFixed(0), "string"),
                ...calculateMetrics("streams").slice(1),
              ]}
            />{" "} */}
            <FunnelRow
              title="Streams"
              data={[
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    className="form-control"
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      color: "transparent", // Make the input text transparent
                      backgroundColor: "transparent",
                      border: isEditing
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : "none",
                      outlineColor: "rgba(0, 0, 0, 0.12)",
                      boxShadow: "none",
                    }}
                    onFocus={() => setIsEditing(true)}
                    onBlur={() => setIsEditing(false)}
                    value={goals?.streams}
                    onChange={(e) =>
                      setGoals((prevGoals) => ({
                        ...prevGoals,
                        streams: Number(e.target.value),
                      }))
                    }
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      pointerEvents: "none", // Make the span unclickable
                    }}
                  >
                    {getFormattedValue(goals?.streams, "number")}
                  </span>
                </div>,
                ...calculateMetrics("streams").slice(1),
              ]}
            />{" "}
            <FunnelRow
              title="Optin Rate"
              data={[
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    className="form-control"
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      color: "transparent", // Make the input text transparent
                      backgroundColor: "transparent",
                      border: isEditing
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : "none",
                      outlineColor: "rgba(0, 0, 0, 0.12)",
                      boxShadow: "none",
                    }}
                    onFocus={() => setIsEditing(true)}
                    onBlur={() => setIsEditing(false)}
                    value={goals?.optinRate}
                    onChange={(e) =>
                      setGoals((prevGoals) => ({
                        ...prevGoals,
                        optinRate: e.target.value,
                      }))
                    }
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      pointerEvents: "none", // Make the span unclickable
                    }}
                  >
                    {getFormattedValue(goals?.optinRate, "percentage")}
                  </span>
                </div>,
                ...calculateMetrics("optinRate").slice(1),
              ]}
            />{" "}
            <FunnelRow
              title="Fans"
              data={[
                getFormattedValue(goals?.fans.toFixed(0), "string"),
                ...calculateMetrics("fans").slice(1),
              ]}
            />{" "}
            <FunnelRow
              title="Sales Rate"
              data={[
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    className="form-control"
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      color: "transparent", // Make the input text transparent
                      backgroundColor: "transparent",
                      border: isEditing
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : "none",
                      outlineColor: "rgba(0, 0, 0, 0.12)",
                      boxShadow: "none",
                    }}
                    onFocus={() => setIsEditing(true)}
                    onBlur={() => setIsEditing(false)}
                    value={goals?.salesRate}
                    onChange={(e) =>
                      setGoals((prevGoals) => ({
                        ...prevGoals,
                        salesRate: e.target.value,
                      }))
                    }
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      pointerEvents: "none", // Make the span unclickable
                    }}
                  >
                    {getFormattedValue(goals?.salesRate, "percentage")}
                  </span>
                </div>,
                ...calculateMetrics("salesRate").slice(1),
              ]}
            />{" "}
            <FunnelRow
              title="Sales"
              data={[
                getFormattedValue(goals?.sales.toFixed(0), "string"),
                ...calculateMetrics("sales").slice(1),
              ]}
            />{" "}
            <RowBreak />
            <FunnelRow
              title="Value Per Sale"
              data={[
                <div style={{ position: "relative" }}>
                  <input
                    type="number"
                    className="form-control"
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      color: "transparent", // Make the input text transparent
                      backgroundColor: "transparent",
                      border: isEditing
                        ? "1px solid rgba(0, 0, 0, 0.12)"
                        : "none",
                      outlineColor: "rgba(0, 0, 0, 0.12)",
                      boxShadow: "none",
                    }}
                    onFocus={() => setIsEditing(true)}
                    onBlur={() => setIsEditing(false)}
                    value={goals?.valuePerSale}
                    onChange={(e) =>
                      setGoals((prevGoals) => ({
                        ...prevGoals,
                        valuePerSale: e.target.value,
                      }))
                    }
                  />
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      pointerEvents: "none", // Make the span unclickable
                    }}
                  >
                    {formatCurrency(goals?.valuePerSale, 0)}
                  </span>
                </div>,
                ...calculateMetrics("valuePerSale").slice(1),
              ]}
              toggleMetrics={true}
              showMetrics={showDetailedMetrics}
              onClick={() => setShowDetailedMetrics((prev) => !prev)}
            />
            {showDetailedMetrics && (
              <>
                <FunnelRow
                  title="Value Per Fan"
                  data={[
                    formatCurrency(goals?.valuePerFan, 2),
                    ...calculateMetrics("valuePerFan").slice(1),
                  ]}
                />
                <FunnelRow
                  title="Value Per Stream"
                  data={[
                    formatCurrency(goals?.valuePerStream, 2),
                    ...calculateMetrics("valuePerStream").slice(1),
                  ]}
                  toggleMetrics={true}
                  showMetrics={showValuePerStreamMetrics}
                  onClick={() => setShowValuePerStreamMetrics((prev) => !prev)}
                />

                {showValuePerStreamMetrics && (
                  <>
                    <FunnelRow
                      title="Value Per Spotify Stream"
                      data={[
                        formatCurrency(0.004, 3),
                        ...calculateMetrics("valuePerSpotifyStream").slice(1),
                      ]}
                    />
                    <FunnelRow
                      title="Profit Multiplier"
                      data={[
                        formatMultiplier(goals?.profitMultiplier, 2),
                        ...calculateMetrics("profitMultiplier").slice(1),
                      ]}
                    />
                  </>
                )}
              </>
            )}
            <RowBreak />
            <FunnelRow
              title="New Revenue"
              data={[
                formatCurrency(goals?.newRevenue, 2),
                ...calculateMetrics("newRevenue").slice(1),
              ]}
            />
            {/* <FunnelRow
            title="Recurring Revenue"
            data={calculateMetrics("recurringRevenue")}
          />
          <RowBreak />
          <FunnelRow
            title="Total Revenue"
            data={calculateMetrics("totalRevenue")}
          /> */}
          </div>
          <div className="d-flex justify-content-end mt-3">
            {cancelButton}
            {saveButton}
          </div>
        </Container>
      </>
    );
  }
};

export default FunnelAccelerator;
