import logo from "../logo.png";

export default function HomePage(){
    return (
    <div
        className="App"
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <img
          height={200}
          width={200}
          src={logo}
          alt="logo"
          style={{ marginBottom: 50, marginTop: 50 }}
        />
        <h1>StreetTeam on Serverless Cloud</h1>
        <p>
          For a guide and recipes on how to configure / customize this project,
          <br />
          check out the
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Documentation
          </a>
          .
        </p>
        <p>
          The information below is being fetched from your Serverless Cloud API:
        </p>
        <h3>Edit this React.js app locally:</h3>
        <p>
          Open your terminal to the project directory and run <code>npm i</code>{" "}
          to install the React dependencies. Then run <code>cloud dev</code> to
          launch the local React dev server. You can access the API on your
          personal developer sandbox by appending <code>/api</code> to the local
          dev server's localhost address.
        </p>
        <p> Radioactive Robots </p>
        </div>)
}