// import { params } from "@ampt/sdk";
const HIGHLEVEL_API_BASE_URL = `https://rest.gohighlevel.com`;

const MM_CRM_LOCATION_ID = "40w74OIRtBJaXkcwkVNQ";
const ST_CRM_LOCATION_ID = "ZxaXDE2BkNQQ1H0xq2vR";

export {
    HIGHLEVEL_API_BASE_URL,
    MM_CRM_LOCATION_ID,
    ST_CRM_LOCATION_ID,
};
    
