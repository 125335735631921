// Define the major cities and their coordinates
const majorCities = [
    { name: "Fargo", lat: 46.8772, lng: -96.7898 },
    { name: "Bismarck", lat: 46.8083, lng: -100.7837 },
    { name: "Grand Forks", lat: 47.9253, lng: -97.0329 },
    { name: "Minot", lat: 48.2325, lng: -101.2923 },
    { name: "West Fargo", lat: 46.8759, lng: -96.9003 },
    { name: "Williston", lat: 48.1475, lng: -103.6174 },
    { name: "Mandan", lat: 46.8267, lng: -100.8891 },
    { name: "Dickinson", lat: 46.8792, lng: -102.7896 },
    { name: "Jamestown", lat: 46.9105, lng: -98.7084 },
    { name: "Wahpeton", lat: 46.2656, lng: -96.6114 },
    { name: "Minneapolis", lat: 44.9778, lng: -93.2650 },
    { name: "St. Paul", lat: 44.9537, lng: -93.0900 },
    { name: "Rochester", lat: 44.0121, lng: -92.4802 },
    { name: "Duluth", lat: 46.7867, lng: -92.1005 },
    { name: "Bloomington", lat: 44.8408, lng: -93.2983 },
    { name: "Brooklyn Park", lat: 45.0941, lng: -93.3563 },
    { name: "Plymouth", lat: 45.0101, lng: -93.4555 },
    { name: "Maple Grove", lat: 45.0725, lng: -93.4558 },
    { name: "Woodbury", lat: 44.9239, lng: -92.9594 },
    { name: "St. Cloud", lat: 45.5579, lng: -94.1632 },
    { name: "Eagan", lat: 44.8041, lng: -93.1669 },
    { name: "Eden Prairie", lat: 44.8547, lng: -93.4708 },
    { name: "Blaine", lat: 45.1608, lng: -93.2349 },
    { name: "Lakeville", lat: 44.6497, lng: -93.2427 },
    { name: "Coon Rapids", lat: 45.1732, lng: -93.3030 },
    { name: "Burnsville", lat: 44.7677, lng: -93.2777 },
    { name: "Apple Valley", lat: 44.7319, lng: -93.2177 },
    { name: "Edina", lat: 44.8897, lng: -93.3499 },
    { name: "Minnetonka", lat: 44.9212, lng: -93.4687 },
    { name: "St. Louis Park", lat: 44.9489, lng: -93.3363 },
    { name: "Moorhead", lat: 46.8739, lng: -96.7676 },
    { name: "Shakopee", lat: 44.7974, lng: -93.5273 },
    { name: "Maplewood", lat: 44.9530, lng: -92.9952 },
    { name: "Richfield", lat: 44.8816, lng: -93.2688 },
    { name: "Roseville", lat: 45.0061, lng: -93.1566 },
    { name: "Inver Grove Heights", lat: 44.8478, lng: -93.0427 },
    { name: "Andover", lat: 45.2333, lng: -93.2913 },
    { name: "Brooklyn Center", lat: 45.0761, lng: -93.3327 },
    { name: "Savage", lat: 44.7573, lng: -93.3617 },
    { name: "Oakdale", lat: 44.9630, lng: -92.9649 },
    { name: "Fridley", lat: 45.0831, lng: -93.2598 },
    { name: "Shoreview", lat: 45.0761, lng: -93.1316 },
    { name: "Ramsey", lat: 45.2571, lng: -93.4555 },
    { name: "Chanhassen", lat: 44.8621, lng: -93.5619 },
    { name: "Prior Lake", lat: 44.7133, lng: -93.4227 },
    { name: "White Bear Lake", lat: 45.0831, lng: -93.0050 },
    { name: "Chaska", lat: 44.7895, lng: -93.6007 },
    { name: "Elk River", lat: 45.3033, lng: -93.5671 },
    { name: "Champlin", lat: 45.1889, lng: -93.3977 },
    { name: "Crystal", lat: 45.0341, lng: -93.3590 },
    { name: "Faribault", lat: 44.2959, lng: -93.2688 },
    { name: "New Brighton", lat: 45.0704, lng: -93.2080 },
    { name: "Golden Valley", lat: 44.9920, lng: -93.3591 },
    { name: "Lino Lakes", lat: 45.1603, lng: -93.0887 },
    { name: "New Hope", lat: 45.0388, lng: -93.3866 },
    { name: "South St. Paul", lat: 44.8936, lng: -93.0407 },
    { name: "Northfield", lat: 44.4583, lng: -93.1616 },
    { name: "West St. Paul", lat: 44.8933, lng: -93.0830 },
    { name: "Columbia Heights", lat: 45.0489, lng: -93.2479 },
    { name: "Willmar", lat: 45.1219, lng: -95.0433 },
    { name: "Forest Lake", lat: 45.2761, lng: -92.9840 },
    { name: "Stillwater", lat: 45.0560, lng: -92.8088 },
    { name: "Hopkins", lat: 44.9240, lng: -93.4050 },
    { name: "Albert Lea", lat: 43.6478, lng: -93.3683 },
    { name: "New York", lat: 40.7128, lng: -74.006 },
    { name: "Los Angeles", lat: 34.0522, lng: -118.2437 },
    { name: "Chicago", lat: 41.8781, lng: -87.6298 },
    { name: "Houston", lat: 29.7604, lng: -95.3698 },
    { name: "Phoenix", lat: 33.4484, lng: -112.074 },
    { name: "Philadelphia", lat: 39.9526, lng: -75.1652 },
    { name: "San Antonio", lat: 29.4241, lng: -98.4936 },
    { name: "San Diego", lat: 32.7157, lng: -117.1611 },
    { name: "Dallas", lat: 32.7767, lng: -96.797 },
    { name: "San Jose", lat: 37.3541, lng: -121.9552 },
    { name: "Austin", lat: 30.2672, lng: -97.7431 },
    { name: "Jacksonville", lat: 30.3322, lng: -81.6557 },
    { name: "Fort Worth", lat: 32.7555, lng: -97.3308 },
    { name: "Columbus", lat: 39.9612, lng: -82.9988 },
    { name: "San Francisco", lat: 37.7749, lng: -122.4194 },
    { name: "Indianapolis", lat: 39.7684, lng: -86.1581 },
    { name: "Charlotte", lat: 35.2271, lng: -80.8431 },
    { name: "Seattle", lat: 47.6062, lng: -122.3321 },
    { name: "Denver", lat: 39.7392, lng: -104.9903 },
    { name: "Washington, D.C.", lat: 38.8951, lng: -77.0369 },
    { name: "Boston", lat: 42.3601, lng: -71.0589 },
    { name: "El Paso", lat: 31.7619, lng: -106.485 },
    { name: "Nashville", lat: 36.1627, lng: -86.7816 },
    { name: "Detroit", lat: 42.3314, lng: -83.0458 },
    { name: "Oklahoma City", lat: 35.4634, lng: -97.5151 },
    { name: "Portland", lat: 45.5051, lng: -122.675 },
    { name: "Las Vegas", lat: 36.1699, lng: -115.1398 },
    { name: "Memphis", lat: 35.1495, lng: -90.049 },
    { name: "Louisville", lat: 38.2527, lng: -85.7585 },
    { name: "Baltimore", lat: 39.2904, lng: -76.6122 },
    { name: "Milwaukee", lat: 43.0389, lng: -87.9065 },
    { name: "Albuquerque", lat: 35.0844, lng: -106.6504 },
    { name: "Tucson", lat: 32.2226, lng: -110.9747 },
    { name: "Fresno", lat: 36.7468, lng: -119.7726 },
    { name: "Sacramento", lat: 38.5816, lng: -121.4944 },
    { name: "Mesa", lat: 33.4152, lng: -111.8315 },
    { name: "Atlanta", lat: 33.749, lng: -84.388 },
    { name: "Kansas City", lat: 39.0997, lng: -94.5786 },
    { name: "Long Beach", lat: 33.7701, lng: -118.1937 },
    { name: "Omaha", lat: 41.2565, lng: -95.9345 },
    { name: "Raleigh", lat: 35.7796, lng: -78.6382 },
    { name: "Colorado Springs", lat: 38.8339, lng: -104.8253 },
    { name: "Miami", lat: 25.7617, lng: -80.1918 },
    { name: "Oakland", lat: 37.8044, lng: -122.2711 },
    { name: "Tulsa", lat: 36.154, lng: -95.9928 },
    { name: "Cleveland", lat: 41.4993, lng: -81.6944 },
    { name: "Wichita", lat: 37.6889, lng: -97.3361 },
    { name: "Arlington", lat: 32.7357, lng: -97.1081 },
    { name: "New Orleans", lat: 29.9511, lng: -90.0715 },
    { name: "Bakersfield", lat: 35.3733, lng: -119.0187 },
    { name: "Tampa", lat: 27.9506, lng: -82.4572 },
    { name: "Aurora", lat: 39.7294, lng: -104.8319 },
    { name: "Anaheim", lat: 33.8366, lng: -117.9143 },
    { name: "Santa Ana", lat: 33.7455, lng: -117.8677 },
    { name: "St. Louis", lat: 38.627, lng: -90.1994 },
    { name: "Riverside", lat: 33.9806, lng: -117.3755 },
    { name: "Corpus Christi", lat: 27.8006, lng: -97.3964 },
    { name: "Lexington", lat: 38.0406, lng: -84.5037 },
    { name: "Pittsburgh", lat: 40.4406, lng: -79.9959 },
    { name: "Anchorage", lat: 61.2181, lng: -149.9003 },
    { name: "Stockton", lat: 37.9577, lng: -121.2908 },
    { name: "Cincinnati", lat: 39.1031, lng: -84.512 },
    { name: "Toledo", lat: 41.6639, lng: -83.5552 },
    { name: "Greensboro", lat: 36.0726, lng: -79.791 },
    { name: "Newark", lat: 40.7357, lng: -74.1724 },
    { name: "Plano", lat: 33.0198, lng: -96.6989 },
    { name: "Henderson", lat: 36.0397, lng: -114.9819 },
    { name: "Lincoln", lat: 40.8136, lng: -96.7026 },
    { name: "Orlando", lat: 28.5383, lng: -81.3792 },
    { name: "Jersey City", lat: 40.7282, lng: -74.0776 },
    { name: "Chula Vista", lat: 32.6401, lng: -117.0842 },
    { name: "Buffalo", lat: 42.8864, lng: -78.8784 },
    { name: "Fort Wayne", lat: 41.0793, lng: -85.1394 },
    { name: "Chandler", lat: 33.3062, lng: -111.8413 },
    { name: "St. Petersburg", lat: 27.7676, lng: -82.6409 },
    { name: "Laredo", lat: 27.5306, lng: -99.4803 },
    { name: "Durham", lat: 35.994, lng: -78.8986 },
    { name: "Irvine", lat: 33.6846, lng: -117.8265 },
    { name: "Madison", lat: 43.0731, lng: -89.4012 },
    { name: "Norfolk", lat: 36.8508, lng: -76.2859 },
    { name: "Lubbock", lat: 33.5779, lng: -101.8552 },
    { name: "Gilbert", lat: 33.3528, lng: -111.789 },
    { name: "Winston-Salem", lat: 36.0999, lng: -80.2442 },
    { name: "Glendale", lat: 33.5387, lng: -112.1859 },
    { name: "Reno", lat: 39.5296, lng: -119.8138 },
    { name: "Hialeah", lat: 25.8576, lng: -80.2781 },
    { name: "Garland", lat: 32.9126, lng: -96.6389 },
    { name: "Chesapeake", lat: 36.7682, lng: -76.2875 },
    { name: "Irving", lat: 32.814, lng: -96.9489 },
    { name: "North Las Vegas", lat: 36.1989, lng: -115.1175 },
    { name: "Scottsdale", lat: 33.4942, lng: -111.9261 },
    { name: "Baton Rouge", lat: 30.4515, lng: -91.1871 },
    { name: "Fremont", lat: 37.5485, lng: -121.9886 },
    { name: "Richmond", lat: 37.5407, lng: -77.436 },
    { name: "Boise", lat: 43.615, lng: -116.2023 },
    { name: "San Bernardino", lat: 34.1083, lng: -117.2898 },
    { name: "Birmingham", lat: 33.5207, lng: -86.8025 },
    { name: "Spokane", lat: 47.6588, lng: -117.426 },
    { name: "Rochester", lat: 43.161, lng: -77.6109 },
    { name: "Des Moines", lat: 41.6005, lng: -93.6091 },
    { name: "Modesto", lat: 37.6391, lng: -120.9969 },
    { name: "Fayetteville", lat: 35.0526, lng: -78.8784 },
    { name: "Tacoma", lat: 47.2529, lng: -122.4443 },
    { name: "Oxnard", lat: 34.1975, lng: -119.177 },
    { name: "Toronto", lat: 43.65107, lng: -79.347015 },
    { name: "Vancouver", lat: 49.2827, lng: -123.1207 },
    { name: "Montreal", lat: 45.5017, lng: -73.5673 },
    { name: "Calgary", lat: 51.0447, lng: -114.0719 },
    { name: "Ottawa", lat: 45.4215, lng: -75.6981 },
    { name: "Edmonton", lat: 53.5444, lng: -113.4909 },
    { name: "Winnipeg", lat: 49.8951, lng: -97.1384 },
    { name: "Quebec City", lat: 46.8139, lng: -71.208 },
    { name: "Halifax", lat: 44.6488, lng: -63.5752 },
  
    // Alabama
    { name: "Birmingham", lat: 33.5207, lng: -86.8025 },
    { name: "Montgomery", lat: 32.3792, lng: -86.3077 },
    { name: "Mobile", lat: 30.6954, lng: -88.0399 },
    { name: "Huntsville", lat: 34.7304, lng: -86.5861 },
    { name: "Tuscaloosa", lat: 33.2098, lng: -87.5692 },
  
    // Alaska
    { name: "Anchorage", lat: 61.2181, lng: -149.9003 },
    { name: "Fairbanks", lat: 64.8378, lng: -147.7164 },
    { name: "Juneau", lat: 58.3019, lng: -134.4197 },
    { name: "Sitka", lat: 57.0531, lng: -135.3300 },
    { name: "Ketchikan", lat: 55.3422, lng: -131.6461 },
  
    // Arizona
    { name: "Phoenix", lat: 33.4484, lng: -112.0740 },
    { name: "Tucson", lat: 32.2226, lng: -110.9747 },
    { name: "Mesa", lat: 33.4152, lng: -111.8315 },
    { name: "Chandler", lat: 33.3062, lng: -111.8413 },
    { name: "Scottsdale", lat: 33.4942, lng: -111.9261 },
  
    // Arkansas
    { name: "Little Rock", lat: 34.7465, lng: -92.2896 },
    { name: "Fort Smith", lat: 35.3859, lng: -94.3985 },
    { name: "Fayetteville", lat: 36.0626, lng: -94.1574 },
    { name: "Springdale", lat: 36.1867, lng: -94.1288 },
    { name: "Jonesboro", lat: 35.8423, lng: -90.7043 },
  
    // California
    { name: "Los Angeles", lat: 34.0522, lng: -118.2437 },
    { name: "San Diego", lat: 32.7157, lng: -117.1611 },
    { name: "San Jose", lat: 37.3382, lng: -121.8863 },
    { name: "San Francisco", lat: 37.7749, lng: -122.4194 },
    { name: "Fresno", lat: 36.7372, lng: -119.7871 },
  
    // Colorado
    { name: "Denver", lat: 39.7392, lng: -104.9903 },
    { name: "Colorado Springs", lat: 38.8339, lng: -104.8214 },
    { name: "Aurora", lat: 39.7294, lng: -104.8319 },
    { name: "Fort Collins", lat: 40.5853, lng: -105.0844 },
    { name: "Lakewood", lat: 39.7047, lng: -105.0814 },
  
    // Connecticut
    { name: "Bridgeport", lat: 41.1792, lng: -73.1894 },
    { name: "New Haven", lat: 41.3082, lng: -72.9251 },
    { name: "Stamford", lat: 41.0534, lng: -73.5387 },
    { name: "Hartford", lat: 41.7658, lng: -72.6734 },
    { name: "Waterbury", lat: 41.5582, lng: -73.0515 },
  
    // Delaware
    { name: "Wilmington", lat: 39.7391, lng: -75.5398 },
    { name: "Dover", lat: 39.1582, lng: -75.5244 },
    { name: "Newark", lat: 39.6837, lng: -75.7497 },
    { name: "Middletown", lat: 39.4496, lng: -75.7163 },
    { name: "Smyrna", lat: 39.2998, lng: -75.6045 },
  
    // Florida
    { name: "Jacksonville", lat: 30.3322, lng: -81.6557 },
    { name: "Miami", lat: 25.7617, lng: -80.1918 },
    { name: "Tampa", lat: 27.9506, lng: -82.4572 },
    { name: "Orlando", lat: 28.5383, lng: -81.3792 },
    { name: "St. Petersburg", lat: 27.7676, lng: -82.6403 },
  
    // Georgia
    { name: "Atlanta", lat: 33.7490, lng: -84.3880 },
    { name: "Augusta", lat: 33.4735, lng: -82.0105 },
    { name: "Columbus", lat: 32.4600, lng: -84.9877 },
    { name: "Savannah", lat: 32.0809, lng: -81.0912 },
    { name: "Athens", lat: 33.9519, lng: -83.3576 },
  
    // Hawaii
    { name: "Honolulu", lat: 21.3069, lng: -157.8583 },
    { name: "Pearl City", lat: 21.3972, lng: -157.9733 },
    { name: "Hilo", lat: 19.7070, lng: -155.0886 },
    { name: "Kailua", lat: 21.4022, lng: -157.7394 },
    { name: "Waipahu", lat: 21.4034, lng: -158.0094 },
  
    // Idaho
    { name: "Boise", lat: 43.6150, lng: -116.2023 },
    { name: "Meridian", lat: 43.6121, lng: -116.3915 },
    { name: "Nampa", lat: 43.5407, lng: -116.5635 },
    { name: "Idaho Falls", lat: 43.4916, lng: -112.0339 },
    { name: "Pocatello", lat: 42.8713, lng: -112.4455 },
  
    // Illinois
    { name: "Chicago", lat: 41.8781, lng: -87.6298 },
    { name: "Aurora", lat: 41.7606, lng: -88.3201 },
    { name: "Rockford", lat: 42.2711, lng: -89.0937 },
    { name: "Joliet", lat: 41.5250, lng: -88.0817 },
    { name: "Naperville", lat: 41.7508, lng: -88.1535 },
  
    // Indiana
    { name: "Indianapolis", lat: 39.7684, lng: -86.1581 },
    { name: "Fort Wayne", lat: 41.0793, lng: -85.1394 },
    { name: "Evansville", lat: 37.9716, lng: -87.5711 },
    { name: "South Bend", lat: 41.6764, lng: -86.2510 },
    { name: "Carmel", lat: 39.9784, lng: -86.1180 },
  
    // Iowa
    { name: "Des Moines", lat: 41.5868, lng: -93.6250 },
    { name: "Cedar Rapids", lat: 41.9779, lng: -91.6656 },
    { name: "Davenport", lat: 41.5236, lng: -90.5776 },
    { name: "Sioux City", lat: 42.4963, lng: -96.4059 },
    { name: "Iowa City", lat: 41.6611, lng: -91.5302 },
  
    // Kansas
    { name: "Wichita", lat: 37.6860, lng: -97.3356 },
    { name: "Overland Park", lat: 38.9822, lng: -94.6708 },
    { name: "Kansas City", lat: 39.0997, lng: -94.5786 },
    { name: "Topeka", lat: 39.0473, lng: -95.6752 },
    { name: "Olathe", lat: 38.8814, lng: -94.8191 },
  
    // Kentucky
    { name: "Louisville", lat: 38.2527, lng: -85.7585 },
    { name: "Lexington", lat: 38.0406, lng: -84.5037 },
    { name: "Bowling Green", lat: 36.9903, lng: -86.4436 },
    { name: "Owensboro", lat: 37.7715, lng: -87.1113 },
    { name: "Covington", lat: 39.0837, lng: -84.5086 },
  
    // Louisiana
    { name: "New Orleans", lat: 29.9511, lng: -90.0715 },
    { name: "Baton Rouge", lat: 30.4515, lng: -91.1871 },
    { name: "Shreveport", lat: 32.5252, lng: -93.7502 },
    { name: "Lafayette", lat: 30.2241, lng: -92.0198 },
    { name: "Lake Charles", lat: 30.2266, lng: -93.2174 },
  
    // Maine
    { name: "Portland", lat: 43.6591, lng: -70.2568 },
    { name: "Lewiston", lat: 44.1004, lng: -70.2148 },
    { name: "Bangor", lat: 44.8016, lng: -68.7712 },
    { name: "South Portland", lat: 43.6415, lng: -70.2409 },
    { name: "Auburn", lat: 44.0979, lng: -70.2312 },
  
    // Maryland
    { name: "Baltimore", lat: 39.2904, lng: -76.6122 },
    { name: "Frederick", lat: 39.4143, lng: -77.4105 },
    { name: "Rockville", lat: 39.0839, lng: -77.1528 },
    { name: "Gaithersburg", lat: 39.1434, lng: -77.2014 },
    { name: "Bowie", lat: 39.0068, lng: -76.7791 },
  
    // Massachusetts
    { name: "Boston", lat: 42.3601, lng: -71.0589 },
    { name: "Worcester", lat: 42.2626, lng: -71.8023 },
    { name: "Springfield", lat: 42.1015, lng: -72.5898 },
    { name: "Lowell", lat: 42.6334, lng: -71.3162 },
    { name: "Cambridge", lat: 42.3736, lng: -71.1097 },
  
    // Michigan
    { name: "Detroit", lat: 42.3314, lng: -83.0458 },
    { name: "Grand Rapids", lat: 42.9634, lng: -85.6681 },
    { name: "Warren", lat: 42.5145, lng: -83.0147 },
    { name: "Sterling Heights", lat: 42.5803, lng: -83.0302 },
    { name: "Lansing", lat: 42.7325, lng: -84.5555 },
  
    // Mississippi
    { name: "Jackson", lat: 32.2988, lng: -90.1848 },
    { name: "Gulfport", lat: 30.3674, lng: -89.0928 },
    { name: "Southaven", lat: 34.9919, lng: -90.0023 },
    { name: "Hattiesburg", lat: 31.3271, lng: -89.2903 },
    { name: "Biloxi", lat: 30.3960, lng: -88.8853 },
  
    // Missouri
    { name: "Kansas City", lat: 39.0997, lng: -94.5786 },
    { name: "St. Louis", lat: 38.6270, lng: -90.1994 },
    { name: "Springfield", lat: 37.2080, lng: -93.2923 },
    { name: "Independence", lat: 39.0911, lng: -94.4155 },
    { name: "Columbia", lat: 38.9517, lng: -92.3341 },
  
    // Montana
    { name: "Billings", lat: 45.7833, lng: -108.5007 },
    { name: "Missoula", lat: 46.8721, lng: -113.9940 },
    { name: "Great Falls", lat: 47.4942, lng: -111.2833 },
    { name: "Bozeman", lat: 45.6769, lng: -111.0429 },
    { name: "Butte", lat: 46.0038, lng: -112.5348 },
  
    // Nebraska
    { name: "Omaha", lat: 41.2565, lng: -95.9345 },
    { name: "Lincoln", lat: 40.8136, lng: -96.7026 },
    { name: "Bellevue", lat: 41.1544, lng: -95.9146 },
    { name: "Grand Island", lat: 40.9264, lng: -98.3420 },
    { name: "Kearney", lat: 40.6995, lng: -99.0817 },
  
    // Nevada
    { name: "Las Vegas", lat: 36.1699, lng: -115.1398 },
    { name: "Henderson", lat: 36.0397, lng: -114.9819 },
    { name: "Reno", lat: 39.5296, lng: -119.8138 },
    { name: "North Las Vegas", lat: 36.1989, lng: -115.1175 },
    { name: "Sparks", lat: 39.5349, lng: -119.7527 },
  
    // New Hampshire
    { name: "Manchester", lat: 42.9956, lng: -71.4548 },
    { name: "Nashua", lat: 42.7654, lng: -71.4676 },
    { name: "Concord", lat: 43.2081, lng: -71.5376 },
    { name: "Derry", lat: 42.8806, lng: -71.3273 },
    { name: "Dover", lat: 43.1979, lng: -70.8737 },
  
    // New Jersey
    { name: "Newark", lat: 40.7357, lng: -74.1724 },
    { name: "Jersey City", lat: 40.7128, lng: -74.0060 },
    { name: "Paterson", lat: 40.9168, lng: -74.1718 },
    { name: "Elizabeth", lat: 40.6639, lng: -74.2107 },
    { name: "Edison", lat: 40.5187, lng: -74.4121 },
  
    // New Mexico
    { name: "Albuquerque", lat: 35.0844, lng: -106.6504 },
    { name: "Las Cruces", lat: 32.3199, lng: -106.7637 },
    { name: "Rio Rancho", lat: 35.2328, lng: -106.6630 },
    { name: "Santa Fe", lat: 35.6870, lng: -105.9378 },
    { name: "Roswell", lat: 33.3943, lng: -104.5230 },
  
    // New York
    { name: "New York City", lat: 40.7128, lng: -74.0060 },
    { name: "Buffalo", lat: 42.8864, lng: -78.8784 },
    { name: "Rochester", lat: 43.1566, lng: -77.6088 },
    { name: "Yonkers", lat: 40.9312, lng: -73.8987 },
    { name: "Syracuse", lat: 43.0481, lng: -76.1474 },
  
    // North Carolina
    { name: "Charlotte", lat: 35.2271, lng: -80.8431 },
    { name: "Raleigh", lat: 35.7796, lng: -78.6382 },
    { name: "Greensboro", lat: 36.0726, lng: -79.7919 },
    { name: "Durham", lat: 35.9940, lng: -78.8986 },
    { name: "Winston-Salem", lat: 36.0999, lng: -80.2442 },
  
    // North Dakota - Skipped
  
    // Ohio
    { name: "Columbus", lat: 39.9612, lng: -82.9988 },
    { name: "Cleveland", lat: 41.4993, lng: -81.6944 },
    { name: "Cincinnati", lat: 39.1031, lng: -84.5120 },
    { name: "Toledo", lat: 41.6528, lng: -83.5379 },
    { name: "Akron", lat: 41.0814, lng: -81.5190 },
  
    // Oklahoma
    { name: "Oklahoma City", lat: 35.4676, lng: -97.5164 },
    { name: "Tulsa", lat: 36.1540, lng: -95.9928 },
    { name: "Norman", lat: 35.2226, lng: -97.4395 },
    { name: "Broken Arrow", lat: 36.0609, lng: -95.7975 },
    { name: "Edmond", lat: 35.6528, lng: -97.4781 },
  
    // Oregon
    { name: "Portland", lat: 45.5051, lng: -122.6750 },
    { name: "Salem", lat: 44.9429, lng: -123.0351 },
    { name: "Eugene", lat: 44.0521, lng: -123.0868 },
    { name: "Gresham", lat: 45.5122, lng: -122.6587 },
    { name: "Hillsboro", lat: 45.5246, lng: -122.9888 },
  
    // Pennsylvania
    { name: "Philadelphia", lat: 39.9526, lng: -75.1652 },
    { name: "Pittsburgh", lat: 40.4406, lng: -79.9959 },
    { name: "Allentown", lat: 40.6084, lng: -75.4902 },
    { name: "Erie", lat: 42.1292, lng: -80.0851 },
    { name: "Reading", lat: 40.3356, lng: -75.9269 },
  
    // Rhode Island
    { name: "Providence", lat: 41.8240, lng: -71.4128 },
    { name: "Warwick", lat: 41.7001, lng: -71.4162 },
    { name: "Cranston", lat: 41.7798, lng: -71.4373 },
    { name: "Pawtucket", lat: 41.8787, lng: -71.3826 },
    { name: "East Providence", lat: 41.8137, lng: -71.3701 },
  
    // South Carolina
    { name: "Columbia", lat: 34.0007, lng: -81.0348 },
    { name: "Charleston", lat: 32.7765, lng: -79.9311 },
    { name: "North Charleston", lat: 32.8546, lng: -79.9748 },
    { name: "Mount Pleasant", lat: 32.8323, lng: -79.8284 },
    { name: "Rock Hill", lat: 34.9249, lng: -81.0251 },
  
    // South Dakota
    { name: "Sioux Falls", lat: 43.5460, lng: -96.7313 },
    { name: "Rapid City", lat: 44.0805, lng: -103.2310 },
    { name: "Aberdeen", lat: 45.4647, lng: -98.4865 },
    { name: "Brookings", lat: 44.3114, lng: -96.7985 },
    { name: "Watertown", lat: 44.8897, lng: -97.1450 },
  
    // Tennessee
    { name: "Nashville", lat: 36.1627, lng: -86.7816 },
    { name: "Memphis", lat: 35.1495, lng: -90.0490 },
    { name: "Knoxville", lat: 35.9606, lng: -83.9207 },
    { name: "Chattanooga", lat: 35.0456, lng: -85.3097 },
    { name: "Clarksville", lat: 36.5298, lng: -87.3595 },
  
    // Texas
    { name: "Houston", lat: 29.7604, lng: -95.3698 },
    { name: "San Antonio", lat: 29.4241, lng: -98.4936 },
    { name: "Dallas", lat: 32.7767, lng: -96.7970 },
    { name: "Austin", lat: 30.2672, lng: -97.7431 },
    { name: "Fort Worth", lat: 32.7555, lng: -97.3308 },
  
    // Utah
    { name: "Salt Lake City", lat: 40.7608, lng: -111.8910 },
    { name: "West Valley City", lat: 40.6916, lng: -112.0010 },
    { name: "Provo", lat: 40.2338, lng: -111.6585 },
    { name: "West Jordan", lat: 40.6097, lng: -111.9391 },
    { name: "Orem", lat: 40.2969, lng: -111.6946 },
  
    // Vermont
    { name: "Burlington", lat: 44.4759, lng: -73.2121 },
    { name: "South Burlington", lat: 44.4669, lng: -73.1710 },
    { name: "Rutland", lat: 43.6106, lng: -72.9726 },
    { name: "Essex", lat: 44.4900, lng: -73.1125 },
    { name: "Colchester", lat: 44.5401, lng: -73.1737 },
  
    // Virginia
    { name: "Virginia Beach", lat: 36.8529, lng: -75.9780 },
    { name: "Norfolk", lat: 36.8508, lng: -76.2859 },
    { name: "Chesapeake", lat: 36.7682, lng: -76.2875 },
    { name: "Arlington", lat: 38.8816, lng: -77.0910 },
    { name: "Richmond", lat: 37.5407, lng: -77.4360 },
  
    // Washington
    { name: "Seattle", lat: 47.6062, lng: -122.3321 },
    { name: "Spokane", lat: 47.6588, lng: -117.4260 },
    { name: "Tacoma", lat: 47.2529, lng: -122.4443 },
    { name: "Vancouver", lat: 45.6387, lng: -122.6615 },
    { name: "Bellevue", lat: 47.6101, lng: -122.2015 },
  
    // West Virginia
    { name: "Charleston", lat: 38.3498, lng: -81.6326 },
    { name: "Huntington", lat: 38.4192, lng: -82.4452 },
    { name: "Morgantown", lat: 39.6295, lng: -79.9559 },
    { name: "Parkersburg", lat: 39.2667, lng: -81.5615 },
    { name: "Wheeling", lat: 40.0630, lng: -80.7209 },
  
    // Wisconsin
    { name: "Milwaukee", lat: 43.0389, lng: -87.9065 },
    { name: "Madison", lat: 43.0731, lng: -89.4012 },
    { name: "Green Bay", lat: 44.5133, lng: -88.0133 },
    { name: "Kenosha", lat: 42.5847, lng: -87.8212 },
    { name: "Racine", lat: 42.7261, lng: -87.7829 },
  
    // Wyoming
    { name: "Cheyenne", lat: 41.1399, lng: -104.8202 },
    { name: "Casper", lat: 42.8666, lng: -106.3131 },
    { name: "Laramie", lat: 41.3114, lng: -105.5911 },
    { name: "Gillette", lat: 44.2911, lng: -105.2917 },
    { name: "Rock Springs", lat: 41.5860, lng: -109.2029 },
  
    // CANADA //
  
    // Alberta
  { name: "Calgary", lat: 51.0447, lng: -114.0719 },
  { name: "Edmonton", lat: 53.5461, lng: -113.4938 },
  { name: "Red Deer", lat: 52.2681, lng: -113.8116 },
  { name: "Lethbridge", lat: 49.6958, lng: -112.8451 },
  { name: "St. Albert", lat: 53.5444, lng: -113.4909 },
  
  // British Columbia
  { name: "Vancouver", lat: 49.2827, lng: -123.1207 },
  { name: "Surrey", lat: 49.1913, lng: -122.8490 },
  { name: "Burnaby", lat: 49.2827, lng: -122.7934 },
  { name: "Richmond", lat: 49.1666, lng: -123.1330 },
  { name: "Abbotsford", lat: 49.0521, lng: -122.3280 },
  
  // Manitoba
  { name: "Winnipeg", lat: 49.8951, lng: -97.1384 },
  { name: "Brandon", lat: 49.8483, lng: -99.9506 },
  { name: "Steinbach", lat: 49.5309, lng: -96.6937 },
  { name: "Thompson", lat: 55.7435, lng: -97.8558 },
  { name: "Portage la Prairie", lat: 49.9720, lng: -98.2923 },
  
  // New Brunswick
  { name: "Saint John", lat: 45.2725, lng: -66.0767 },
  { name: "Moncton", lat: 46.0878, lng: -64.7787 },
  { name: "Fredericton", lat: 45.9636, lng: -66.6431 },
  { name: "Dieppe", lat: 46.0982, lng: -64.7363 },
  { name: "Miramichi", lat: 47.0286, lng: -65.5014 },
  
  // Newfoundland and Labrador
  // { name: "St. John's", lat: 47.5615, lng: -52.7126 },
  { name: "Mount Pearl", lat: 47.5272, lng: -52.8058 },
  { name: "Corner Brook", lat: 48.9510, lng: -57.9487 },
  { name: "Conception Bay South", lat: 47.5307, lng: -52.9614 },
  { name: "Grand Falls-Windsor", lat: 48.9482, lng: -55.6636 },
  
  // Northwest Territories
  { name: "Yellowknife", lat: 62.4540, lng: -114.3718 },
  { name: "Inuvik", lat: 68.3499, lng: -133.7218 },
  { name: "Hay River", lat: 60.8156, lng: -115.7999 },
  { name: "Fort Smith", lat: 60.0044, lng: -111.8836 },
  { name: "Behchoko", lat: 62.8294, lng: -115.9934 },
  
  // Nova Scotia
  { name: "Halifax", lat: 44.6488, lng: -63.5752 },
  { name: "Cape Breton", lat: 46.2382, lng: -60.1979 },
  { name: "Truro", lat: 45.3654, lng: -63.2861 },
  { name: "Sydney", lat: 46.1367, lng: -60.1946 },
  { name: "Kentville", lat: 45.0713, lng: -64.4988 },
  
  // Nunavut
  { name: "Iqaluit", lat: 63.7467, lng: -68.5170 },
  { name: "Rankin Inlet", lat: 62.8079, lng: -92.0846 },
  { name: "Arviat", lat: 61.1106, lng: -94.0657 },
  { name: "Baker Lake", lat: 64.3174, lng: -96.0167 },
  { name: "Cambridge Bay", lat: 69.1139, lng: -105.0529 },
  
  // Ontario
  { name: "Toronto", lat: 43.6511, lng: -79.3830 },
  { name: "Ottawa", lat: 45.4215, lng: -75.6906 },
  { name: "Mississauga", lat: 43.5890, lng: -79.6441 },
  { name: "Brampton", lat: 43.7315, lng: -79.7624 },
  { name: "Hamilton", lat: 43.2557, lng: -79.8711 },
  
  // Prince Edward Island
  { name: "Charlottetown", lat: 46.2382, lng: -63.1311 },
  { name: "Summerside", lat: 46.3930, lng: -63.7898 },
  { name: "Stratford", lat: 46.2130, lng: -63.0917 },
  { name: "Cornwall", lat: 46.2318, lng: -63.2005 },
  { name: "Montague", lat: 46.1660, lng: -62.6464 },
  
  // Quebec
  { name: "Montreal", lat: 45.5017, lng: -73.5673 },
  { name: "Quebec City", lat: 46.8139, lng: -71.2080 },
  { name: "Laval", lat: 45.5699, lng: -73.6923 },
  { name: "Gatineau", lat: 45.4765, lng: -75.7013 },
  { name: "Longueuil", lat: 45.5313, lng: -73.5185 },
  
  // Saskatchewan
  { name: "Saskatoon", lat: 52.1332, lng: -106.6700 },
  { name: "Regina", lat: 50.4452, lng: -104.6189 },
  { name: "Prince Albert", lat: 53.2001, lng: -105.7678 },
  { name: "Moose Jaw", lat: 50.3913, lng: -105.5344 },
  { name: "Swift Current", lat: 50.2851, lng: -107.7985 },
  
  // Yukon
  { name: "Whitehorse", lat: 60.7212, lng: -135.0568 },
  { name: "Dawson City", lat: 64.0662, lng: -139.4301 },
  { name: "Watson Lake", lat: 60.0630, lng: -128.0198 },
  { name: "Haines Junction", lat: 60.7525, lng: -137.5108 },
  { name: "Carmacks", lat: 62.1064, lng: -136.1862 },
  
  //  UK
  
  // London
  { name: "London", lat: 51.5074, lng: -0.1278 },
  { name: "Birmingham", lat: 52.4862, lng: -1.8904 },
  { name: "Manchester", lat: 53.483, lng: -2.2441 },
  { name: "Glasgow", lat: 55.8642, lng: -4.2518 },
  { name: "Liverpool", lat: 53.4084, lng: -2.9916 },
  { name: "Newcastle upon Tyne", lat: 54.9783, lng: -1.6174 },
  { name: "Sheffield", lat: 53.3811, lng: -1.4701 },
  { name: "Bristol", lat: 51.4545, lng: -2.5879 },
  { name: "Leeds", lat: 53.8013, lng: -1.5486 },
  { name: "Edinburgh", lat: 55.9533, lng: -3.1883 },
  { name: "Leicester", lat: 52.6369, lng: -1.1398 },
  { name: "Coventry", lat: 52.4068, lng: -1.5197 },
  { name: "Belfast", lat: 54.597, lng: -5.9301 },
  { name: "Nottingham", lat: 52.9548, lng: -1.1581 },
  { name: "Plymouth", lat: 50.3755, lng: -4.1427 },
  { name: "Southampton", lat: 50.9097, lng: -1.4044 },
  { name: "Brighton", lat: 50.8225, lng: -0.1372 },
  { name: "Portsmouth", lat: 50.8195, lng: -1.0874 },
  { name: "Reading", lat: 51.4543, lng: -0.9781 },
  { name: "Bournemouth", lat: 50.7192, lng: -1.8808 },
  { name: "Swansea", lat: 51.6214, lng: -3.9436 },
  { name: "York", lat: 53.959, lng: -1.0817 },
  { name: "Oxford", lat: 51.752, lng: -1.2577 },
  { name: "Luton", lat: 51.8787, lng: -0.4205 },
  { name: "Middlesbrough", lat: 54.574, lng: -1.234 },
  
  
  //  WESTERN EUROPE
  
  // Austria
  { name: "Vienna", lat: 48.2082, lng: 16.3738 },
  { name: "Graz", lat: 47.0707, lng: 15.4395 },
  { name: "Linz", lat: 48.3064, lng: 14.2861 },
  { name: "Salzburg", lat: 47.8095, lng: 13.055 },
  { name: "Innsbruck", lat: 47.2692, lng: 11.4041 },
  
  // Belgium
  { name: "Brussels", lat: 50.8503, lng: 4.3517 },
  { name: "Antwerp", lat: 51.2194, lng: 4.4025 },
  { name: "Ghent", lat: 51.0543, lng: 3.7174 },
  { name: "Charleroi", lat: 50.4108, lng: 4.4446 },
  { name: "Liège", lat: 50.6326, lng: 5.5797 },
  
  // France
  { name: "Paris", lat: 48.8566, lng: 2.3522 },
  { name: "Marseille", lat: 43.2965, lng: 5.3698 },
  { name: "Lyon", lat: 45.75, lng: 4.85 },
  { name: "Toulouse", lat: 43.6047, lng: 1.4442 },
  { name: "Nice", lat: 43.7102, lng: 7.262 },
    
  // Germany
  { name: "Berlin", lat: 52.52, lng: 13.405 },
  { name: "Hamburg", lat: 53.5511, lng: 9.9937 },
  { name: "Munich", lat: 48.1351, lng: 11.582 },
  { name: "Cologne", lat: 50.9375, lng: 6.9603 },
  { name: "Frankfurt", lat: 50.1109, lng: 8.6821 },
  
  // Ireland
  { name: "Dublin", lat: 53.3498, lng: -6.2603 },
  { name: "Cork", lat: 51.8969, lng: -8.4863 },
  { name: "Limerick", lat: 52.668, lng: -8.6305 },
  { name: "Galway", lat: 53.2707, lng: -9.0568 },
  { name: "Waterford", lat: 52.2593, lng: -7.1101 },
  
  // Italy
  { name: "Rome", lat: 41.9028, lng: 12.4964 },
  { name: "Milan", lat: 45.4642, lng: 9.19 },
  { name: "Naples", lat: 40.8518, lng: 14.2681 },
  { name: "Turin", lat: 45.0703, lng: 7.6869 },
  { name: "Palermo", lat: 38.1157, lng: 13.3618 },
  
  // Luxembourg
  { name: "Luxembourg City", lat: 49.6116, lng: 6.1319 },
  
  // Netherlands
  { name: "Amsterdam", lat: 52.3676, lng: 4.9041 },
  { name: "Rotterdam", lat: 51.9225, lng: 4.4792 },
  { name: "The Hague", lat: 52.0705, lng: 4.3007 },
  { name: "Utrecht", lat: 52.0907, lng: 5.1214 },
  { name: "Eindhoven", lat: 51.4416, lng: 5.4697 },
  
  // Portugal
  { name: "Lisbon", lat: 38.7223, lng: -9.1393 },
  { name: "Porto", lat: 41.1579, lng: -8.6291 },
  { name: "Vila Nova de Gaia", lat: 41.133, lng: -8.6174 },
  { name: "Amadora", lat: 38.7597, lng: -9.2395 },
  { name: "Braga", lat: 41.5503, lng: -8.4201 },
  
  // Spain
  { name: "Madrid", lat: 40.4168, lng: -3.7038 },
  { name: "Barcelona", lat: 41.3851, lng: 2.1734 },
  { name: "Valencia", lat: 39.4699, lng: -0.3763 },
  { name: "Seville", lat: 37.3886, lng: -5.9823 },
  { name: "Zaragoza", lat: 41.6488, lng: -0.8891 },
  
  // Switzerland
  { name: "Zurich", lat: 47.3769, lng: 8.5417 },
  { name: "Geneva", lat: 46.2044, lng: 6.1432 },
  { name: "Basel", lat: 47.5596, lng: 7.5886 },
  { name: "Lausanne", lat: 46.5197, lng: 6.6323 },
  { name: "Bern", lat: 46.948, lng: 7.4474 },
  
  // United Kingdom
  { name: "London", lat: 51.5074, lng: -0.1278 },
  { name: "Birmingham", lat: 52.4862, lng: -1.8904 },
  { name: "Manchester", lat: 53.4839, lng: -2.2446 },
  { name: "Glasgow", lat: 55.8642, lng: -4.2518 },
  { name: "Leeds", lat: 53.7997, lng: -1.5491 },
  
    // Add more major cities here
  ];
  export default majorCities;
  