// import { forwardRef, useContext, useState } from "react";
import {
  // Accordion,
  Container,
  // Form
} from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faAngleLeft,
//   faAngleRight,
//   faCalendarDay,
// } from "@fortawesome/free-solid-svg-icons";
// import DatePicker from "react-datepicker";
// import { format } from "date-fns";
// import { submitForm } from "../../../../utils/submitForm";
// import { MM_CRM_LOCATION_ID } from "../../../../integrations/Highlevel/constants";
// import LocationContext from "../../../../context/Locations/LocationContext";
// import { useContext, useState } from "react";
// import { ArtistBreakthroughApplication } from "./Step1Application/ArtistApplication";

// const ArtistBreakthroughFAQ = () => {
//   return (
//     <Accordion>
//       <Accordion.Item eventKey="0">
//         <Accordion.Header>
//           What is an Artist Breakthrough Session™?
//         </Accordion.Header>
//         <Accordion.Body>
//           An Artist Breakthrough Session™ is a 30-minute, one-on-one call with
//           a music industry expert. During this call, you will get the
//           opportunity to discuss your music career and get advice on how to take
//           your career to the next level.
//         </Accordion.Body>
//       </Accordion.Item>

//       <Accordion.Item eventKey="1">
//         <Accordion.Header>How much does it cost?</Accordion.Header>
//         <Accordion.Body>
//           The Artist Breakthrough Session™ is completely free. There is no
//           obligation to purchase anything.
//         </Accordion.Body>
//       </Accordion.Item>

//       <Accordion.Item eventKey="2">
//         <Accordion.Header>
//           What happens after I submit my application?
//         </Accordion.Header>
//         <Accordion.Body>
//           Once you submit your application, you will be redirected to a page
//           where you can book a time for your Artist Breakthrough Session™.
//         </Accordion.Body>
//       </Accordion.Item>

//       <Accordion.Item eventKey="3">
//         <Accordion.Header>How will I know if I am selected?</Accordion.Header>
//         <Accordion.Body>
//           You will receive an email with the date and time of your Artist
//           Breakthrough Session™.
//         </Accordion.Body>
//       </Accordion.Item>

//       <Accordion.Item eventKey="4">
//         <Accordion.Header>What if I have more questions?</Accordion.Header>
//         <Accordion.Body>
//           Please email us at{" "}
//           <a
//             className="text-secondary"
//             href="mailto:talent.scouts@modernmusician.me"
//           >
//             talent.scouts@modernmusician.me
//           </a>{" "}
//           and we will be happy to answer any questions you may have.
//         </Accordion.Body>
//       </Accordion.Item>
//     </Accordion>
//   );
// };

const Breakthrough = ({ setCompleted }) => {
  // const {locationData} = useContext(LocationContext);
  // const [faqOpen, setFaqOpen] = useState(false);


  // function that setCompleted for the parent component
  // const setBreakthroughCompleted = () => {
  //   // setIsApplicationCompleted(true);
  //   setCompleted("breakthrough");
  // };

  return (
    <Container
      className="my-4 d-flex justify-content-center flex-column"
      style={{
        backgroundColor: "#fff",
        border: "1px solid #e0e0e0",
        borderRadius: "10px",
        padding: "50px 20px",
        maxWidth: "100vw",
      }}
    >
      <h2 className="text-center mb-4">
        Book an Artist Breakthrough Session™
      </h2>
      <Container style={{ maxWidth: "500px" }}>
        {/* <p
          className="mt-0 text-decoration-underline text-center"
          onClick={() => setFaqOpen(!faqOpen)}
        >
          Click here for FAQ
        </p>
        {faqOpen && <ArtistBreakthroughFAQ />} */}

        {/*         
        {isApplicationCompleted && (
          <>
            <Button
              variant="success"
              onClick={() => {

              }}
            >
              Book Your Breakthrough Session
            </Button>
            <div className="mt-4">
              <iframe
                src="your-iframe-source-here"
                width="100%"
                height="400"
                title="Breakthrough Session"
              ></iframe>
            </div>
          </>
        )} */}
      </Container>
    </Container>
  );
};

export default Breakthrough;
