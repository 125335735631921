import React, { useState, useEffect, useCallback } from "react";
import { Spinner, Alert, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  getCustomerBillingPortalSession,
  getSubscriptionByLocationId,
} from "../../hooks/apiHelper";

const CustomerBilling = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const stripe_customer_id = queryParams.get("stripe_customer_id");
  const crm_location_id = queryParams.get("crm_location_id");

  const handleMultipleSubscriptionsError = (
    locationId,
    subscriptionIds,
    customerId
  ) => {
    const emailSubject = encodeURIComponent(
      `Duplicate Subscriptions: ${locationId}`
    );
    const emailBody = encodeURIComponent(
      `Dear Support,\n\nI have encountered an error with multiple subscriptions on my StreetTeam account.\n\n` +
        `Location ID: ${locationId}\n` +
        `Subscription IDs: ${subscriptionIds.join(", ")}\n` +
        `Customer ID: ${customerId}\n\n` +
        `Please assist me with resolving this issue.\n\nThank you.`
    );
    return (
      <Alert
        className=""
        variant="white"
      >
          <h3 className="mb-3 fw-normal">
            Error: Multiple Account Subscriptions Found
          </h3>
          <h6 className="">
            Please{" "}
            <a
              className="text-secondary"
              href={`mailto:support@modernmusician.me?subject=${emailSubject}&body=${emailBody}`}
            >
              click here
            </a>{" "}
            to contact us and we will take care of this for you.
          </h6>
      </Alert>
    );
  };

  const handleNoSubscriptionsError = (locationId) => {
    const emailSubject = encodeURIComponent(`No Subscription: ${locationId}`);
    const emailBody = encodeURIComponent(
      `Dear Support,\n\nI have encountered an error with no subscription on my StreetTeam account.\n\n` +
        `Location ID: ${locationId}\n` +
        `Please assist me with resolving this issue.\n\nThank you.`
    );
    return (
      <Alert
        className=""
        variant="white"
      >
        <h3 className="mb-3 fw-normal">Error: No Account Subscription Found</h3>
        <h6 className="">
          Please{" "}
          <a
            className="text-secondary"
            href={`mailto:support@modernmusician.me?subject=${emailSubject}&body=${emailBody}`}
          >
            click here
          </a>{" "}
          to contact us and we will take care of this for you.
        </h6>
      </Alert>
    );
  };

  const loadPortal = useCallback(async () => {
    try {
      setIsLoading(true);
      let fetched_stripe_customer_id = null;
      if (!stripe_customer_id && crm_location_id) {
        let locationData = await getSubscriptionByLocationId({
          crmLocationId: crm_location_id,
        });
        if (locationData.multipleSubscriptions) {
          setError(() =>
            handleMultipleSubscriptionsError(
              crm_location_id,
              locationData.subscriptionIds,
              locationData.customerId
            )
          );
          return;
        } else if (locationData.noSubscriptions) {
          setError(() => handleNoSubscriptionsError(crm_location_id));
          return;
        }
        fetched_stripe_customer_id = locationData?.customer_id;
      }
      const results = await getCustomerBillingPortalSession({
        stripeCustomerId: stripe_customer_id ?? fetched_stripe_customer_id,
      });

      if (results?.url) {
        window.location.href = results.url;
      }
    } catch (err) {
      console.error(err);
      window.location.href = "https://app.streetteam.me";
    } finally {
      setIsLoading(false);
    }
  }, [stripe_customer_id, crm_location_id]);

  useEffect(() => {
    loadPortal();
  }, [loadPortal]);

  return (
    <Container className="text-center py-4 my-4 w-50">
      {isLoading && <Spinner animation="border" size="lg" />}
      {error}
    </Container>
  );
};

export default CustomerBilling;
