import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Check if environment is development
      const isDevelopment = process.env.NODE_ENV === "development";

      return (
        <div style={styles.errorContainer}>
          <h1 style={styles.header}>Oops! Something went wrong.</h1>
          <p style={styles.subHeader}>
            We apologize for the inconvenience. Please reach out to{" "}
            <a
              className="text-secondary"
              href="mailto:support@modernmusician.me"
            >
              support@modernmusician.me
            </a>{" "}
            if the problem persists after reloading.
          </p>

          {/* Always show error details in development */}
          {isDevelopment && (
            <div style={styles.details}>
              <strong>Error Details:</strong>
              <br />
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo && (
                <pre>{this.state.errorInfo.componentStack}</pre>
              )}
            </div>
          )}

          {/* <Button
            variant="secondary"
            className="bg-gradient"
            onClick={() => window.location.reload()}
          >
            Reload Page
          </Button> */}
        </div>
      );
    }

    return this.props.children;
  }
}

const styles = {
  errorContainer: {
    textAlign: "center",
    padding: "50px",
    backgroundColor: "#f7f7f7",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    fontSize: "24px",
    color: "#333",
    marginBottom: "20px",
  },
  subHeader: {
    fontSize: "18px",
    color: "#666",
    marginBottom: "20px",
  },
  details: {
    whiteSpace: "pre-wrap",
    textAlign: "left",
    width: "50%",
    margin: "20px auto",
    color: "#999",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    // backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      //   backgroundColor: "#0056b3",
    },
  },
};

export default ErrorBoundary;
