import { useState, useEffect, useRef } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
  Button,
  Form,
  Modal,
} from "react-bootstrap";

// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
import { API_BASE_URL } from "../../../../../utils/constants";
import axios from "axios";
import { MM_CRM_LOCATION_ID } from "../../../../../integrations/Highlevel/constants";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ApplicationReview from "./ApplicationReview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic } from "@fortawesome/free-solid-svg-icons";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AiApplicationSummary = () => {
  //   const [talentScoutSummary, setTalentScoutSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [copyText, setCopyText] = useState("Copy");
  // const [isEditing, setIsEditing] = useState(false);

  const textAreaRef = useRef(null);

  const [summary, setSummary] = useState(null);
  const [formData, setFormData] = useState(null);
  const [showApplicationReview, setShowApplicationReview] = useState(false);

  const query = useQuery();
  const contact_id = query.get("contact_id");
  const [contact, setContact] = useState({
    first_name: "",
    last_name: "",
    id: contact_id,
  });

  console.log("contact_id", contact_id);

  function openInNewWindow(e, url) {
    e.preventDefault();
    window.open(url, "newwindow", "width=1280,height=720");
  }

  async function copyToClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      setCopyText("Copied!");
      setTimeout(() => {
        setCopyText("Copy");
      }, 3000);
    } catch (err) {
      setCopyText("Failed to copy");
    }
  }

  useEffect(() => {
    setIsLoading(true);

    // fetch summary by contact_id using axios GET request to /fields/talent-scout-summary/:contact_id
    let location_id = MM_CRM_LOCATION_ID;
    let url = `${API_BASE_URL}/fields/${location_id}/breakthrough.application/${contact_id}`;
    axios.get(url).then((res) => {
      console.log(`res is`, res?.data);
      if (!res?.data?.breakthrough?.application) {
        toast.error(`No application found for contact: ${contact_id}`);
      }
      setSummary(res?.data?.breakthrough?.application?.summary);
      setFormData(res?.data?.breakthrough?.application?.fields);
      setContact({
        first_name: res?.data?.first_name,
        last_name: res?.data?.last_name,
        id: contact_id,
      });
      setIsLoading(false);
    });
  }, [contact_id]);

  // const handleSave = () => {
  //   // setIsEditing(false);
  //   // update formData in the database
  //   let location_id = MM_CRM_LOCATION_ID;
  //   let url = `${API_BASE_URL}/fields/${location_id}/breakthrough_application/${contact_id}`;
  //   axios
  //     .put(url, {
  //       fields: formData,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     });
  // };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center m-4 flex-column min-vh-100">
          <Spinner animation="border" variant="secondary" className="p-4" />
        </div>
      ) : (
        summary && (
          <Container
            fluid
            className="p-4 bg-light min-vh-100 d-flex justify-content-center"
          >
            <Row style={{ maxWidth: "800px" }}>
              <Col>
                <Card className="p-4">
                  <Card.Body>
                    <Row className="d-flex justify-content-center text-center">
                      <Col>
                        <h1>
                          {" "}
                          {contact?.first_name} {contact?.last_name}{" "}
                        </h1>
                        <h4 className="fw-light">
                          Breakthrough Application Summary
                        </h4>
                        {/* <h6 className="mw-100 fw-light mb-0">
                          {summary?.artist_tier} | {summary?.score}
                          /100{" "}
                        </h6> */}
                        <div className="my-0">
                          <Button
                            variant="link"
                            className="text-secondary my-0"
                            onClick={(e) =>
                              openInNewWindow(
                                e,
                                `/app-review?crm_id=${contact?.id}`
                              )
                            }
                          >
                            View Application Details
                          </Button>
                        </div>
                        <Button
                          variant="secondary"
                          className="my-0"
                          onClick={(e) =>
                            openInNewWindow(e, summary?.song_link)
                          }
                        >
                          <FontAwesomeIcon className="me-2" icon={faMusic} />
                          Listen to Music
                        </Button>
                        {/* <a
                          href={summary?.song_link}
                          onClick={(e) =>
                            openInNewWindow(e, summary?.song_link)
                          }
                          className="text-secondary fw-bold my-1"
                        >
                          Listen to Music
                        </a> */}

                        <hr />
                      </Col>
                    </Row>
                    <div>
                      <p className="mw-100">{summary.overview}</p>
                      <h3>Goals</h3>
                      <ul>
                        {summary.desires.map((desire) => (
                          <li key={desire}>{desire}</li>
                        ))}
                      </ul>
                      <h3>Problems</h3>
                      <ul>
                        {summary.pain_points.map((painPoint) => (
                          <li key={painPoint}>{painPoint}</li>
                        ))}
                      </ul>
                      <h3>Questions</h3>
                      <ul>
                        {summary.objections.map((objection) => (
                          <li key={objection}>{objection}</li>
                        ))}
                      </ul>
                      <h3>Recommendations</h3>
                      <p className="mw-100">{summary.recommendations}</p>
                    </div>
                    <hr />
                    <h3>Artist Solution</h3>
                    <div>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        ref={textAreaRef}
                        defaultValue={summary?.problem_solution_statement}
                        // on change, update the summary.problem_solution_statement
                        onChange={(e) => {
                          setSummary({
                            ...summary,
                            problem_solution_statement: e.target.value,
                          });
                        }}
                      />
                      <Button
                        className="mt-2"
                        onClick={() =>
                          copyToClipboard(summary?.problem_solution_statement)
                        }
                      >
                        {copyText}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )
      )}
      {showApplicationReview && (
        // If using a modal
        <Modal
          size="lg"
          backdrop={true}
          show={showApplicationReview}
          onHide={() => setShowApplicationReview(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Application Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ApplicationReview contact={contact}></ApplicationReview>
          </Modal.Body>
        </Modal>

        // If using a simple toggleable section
        // <div>
        //   {/* Your ApplicationReview details here */}
        // </div>
      )}

      {formData && (
        // <Container
        //   fluid
        //   className="p-4 bg-light min-vh-100 d-flex justify-content-center"
        // >
        //   <Row style={{ maxWidth: "800px" }}>
        //     <Col>
        //       <Card className="p-4">
        //         <Card.Body>
        //           <Row className="d-flex justify-content-center text-center">
        //             <Col>
        //               <h1>
        //                 {" "}
        //                 {contact?.first_name} {contact?.last_name}{" "}
        //               </h1>
        //               <h4 className="fw-light">
        //                 Breakthrough Application Details
        //               </h4>
        //               <div>
        //                 <Button
        //                   variant="link"
        //                   className="text-dark"
        //                   onClick={() => setIsEditing(!isEditing)}
        //                 >
        //                   <FontAwesomeIcon className="me-2" icon={faEdit} />
        //                   Edit Application Details
        //                 </Button>
        //               </div>
        //               <hr />
        //             </Col>
        //           </Row>
        //           <Row className="d-flex justify-content-center text-left">
        //             <Col>
        //               {/* <div>
        //                 <p className="mw-100">
        //                   <strong>What's your name?</strong>{" "}
        //                   <div>{formData.name}</div>
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Briefly introduce yourself</strong>{" "}
        //                   <div>{formData.artist_description}</div>
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Roadblocks:</strong> {formData.roadblocks}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Fanbase Size:</strong> {formData.fanbase_size}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Investable Budget:</strong>{" "}
        //                   {Number(formData.investable_budget).toLocaleString(
        //                     "en-US",
        //                     {
        //                       style: "currency",
        //                       currency: "USD",
        //                       minimumFractionDigits: 0,
        //                       maximumFractionDigits: 0,
        //                     }
        //                   )}
        //                   /m
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Investable Time:</strong>{" "}
        //                   {formData.investable_time} hours/day
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Target Monthly Revenue:</strong>{" "}
        //                   {Number(formData.target_monthly_revenue).toLocaleString(
        //                     "en-US",
        //                     {
        //                       style: "currency",
        //                       currency: "USD",
        //                       minimumFractionDigits: 0,
        //                       maximumFractionDigits: 0,
        //                     }
        //                   )}
        //                   /m{" "}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Current Monthly Revenue:</strong>{" "}
        //                   {Number(formData.monthly_revenue).toLocaleString(
        //                     "en-US",
        //                     {
        //                       style: "currency",
        //                       currency: "USD",
        //                       minimumFractionDigits: 0,
        //                       maximumFractionDigits: 0,
        //                     }
        //                   )}
        //                   /m
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Sustained by Music:</strong>{" "}
        //                   {formData.sustained_by_music}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Annual Goals:</strong> {formData.annual_goals}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Coachable:</strong> {formData.coachable}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Resourceful:</strong> {formData.resourceful}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Belief in Success:</strong> {formData.belief}
        //                 </p>
        //                 <p className="mw-100">
        //                   <strong>Target Start Date:</strong>{" "}
        //                   {formData.start_date}
        //                 </p>
        //               </div> */}
        //               <div>
        //                 {formQuestions.map((question) => {
        //                   // Determine if the question should be hidden
        //                   const shouldHide = question.conditions?.some(
        //                     (condition) =>
        //                       condition.trigger && condition.action === "skip"
        //                   );

        //                   if (!shouldHide) {
        //                     const key = question.key;
        //                     const value = formData[key];
        //                     return (
        //                       <Form.Group key={key} className="mb-3">
        //                         <Form.Label className="fs-6 fw-bold">
        //                           {question.label}
        //                         </Form.Label>

        //                         {isEditing ? (
        //                           <>
        //                             {question.inputType === "text" && (
        //                               <Form.Control
        //                                 type="text"
        //                                 name={key}
        //                                 value={value}
        //                                 onChange={(e) =>
        //                                   setFormData({
        //                                     ...formData,
        //                                     [key]: e.target.value,
        //                                   })
        //                                 }
        //                               />
        //                             )}
        //                             {question.inputType === "email" && (
        //                               <Form.Control
        //                                 type="email"
        //                                 name={key}
        //                                 value={value}
        //                                 onChange={(e) =>
        //                                   setFormData({
        //                                     ...formData,
        //                                     [key]: e.target.value,
        //                                   })
        //                                 }
        //                               />
        //                             )}
        //                             {question.inputType === "url" && (
        //                               <Form.Control
        //                                 type="url"
        //                                 name={key}
        //                                 value={value}
        //                                 onChange={(e) =>
        //                                   setFormData({
        //                                     ...formData,
        //                                     [key]: e.target.value,
        //                                   })
        //                                 }
        //                               />
        //                             )}
        //                             {question.inputType === "textarea" && (
        //                               <Form.Control
        //                                 as="textarea"
        //                                 name={key}
        //                                 rows={question.rows || 3}
        //                                 value={value}
        //                                 onChange={(e) =>
        //                                   setFormData({
        //                                     ...formData,
        //                                     [key]: e.target.value,
        //                                   })
        //                                 }
        //                               />
        //                             )}
        //                             {question.inputType === "radio" &&
        //                               question.options.map((option, index) => (
        //                                 <Form.Check
        //                                   key={index}
        //                                   type="radio"
        //                                   label={option}
        //                                   name={key}
        //                                   value={option}
        //                                   checked={value === option}
        //                                   onChange={(e) =>
        //                                     setFormData({
        //                                       ...formData,
        //                                       [key]: e.target.value,
        //                                     })
        //                                   }
        //                                 />
        //                               ))}
        //                             {question.inputType === "number" && (
        //                               <Form.Control
        //                                 type="number"
        //                                 name={key}
        //                                 value={value}
        //                                 onChange={(e) =>
        //                                   setFormData({
        //                                     ...formData,
        //                                     [key]: e.target.value,
        //                                   })
        //                                 }
        //                               />
        //                             )}
        //                             {question.inputType === "date" && (
        //                               <Form.Control
        //                                 type="date"
        //                                 name={question.key}
        //                                 value={
        //                                   value
        //                                     ? format(
        //                                         parse(
        //                                           value,
        //                                           "MM-dd-yyyy",
        //                                           new Date()
        //                                         ),
        //                                         "yyyy-MM-dd"
        //                                       )
        //                                     : ""
        //                                 }
        //                                 min={format(new Date(), "yyyy-MM-dd")}
        //                                 onChange={(e) => {
        //                                   // Convert the 'yyyy-MM-dd' format back to 'MM-dd-yyyy' when setting the formData
        //                                   const convertedDate = format(
        //                                     parse(
        //                                       e.target.value,
        //                                       "yyyy-MM-dd",
        //                                       new Date()
        //                                     ),
        //                                     "MM-dd-yyyy"
        //                                   );
        //                                   setFormData({
        //                                     ...formData,
        //                                     [question.key]: convertedDate,
        //                                   });
        //                                 }}
        //                                 placeholder="YYYY-MM-DD"
        //                                 title={
        //                                   value
        //                                     ? format(
        //                                         parse(
        //                                           value,
        //                                           "MM-dd-yyyy",
        //                                           new Date()
        //                                         ),
        //                                         "PP"
        //                                       )
        //                                     : ""
        //                                 }
        //                               />
        //                             )}
        //                           </>
        //                         ) : (
        //                           <div>
        //                             {question.format === "currency" ? (
        //                               formatCurrency(value)
        //                             ) : question.inputType === "url" ? (
        //                               <a
        //                                 href={value}
        //                                 className="text-secondary"
        //                                 target="_blank"
        //                                 rel="noopener noreferrer"
        //                               >
        //                                 {value}
        //                               </a>
        //                             ) : (
        //                               value
        //                             )}
        //                           </div>
        //                         )}
        //                       </Form.Group>
        //                     );
        //                   }
        //                 })}
        //               </div>
        //               {isEditing && (
        //                 <Button
        //                   variant="secondary"
        //                   className="mt-2"
        //                   onClick={() => setIsEditing(!isEditing)}
        //                 >
        //                   Save
        //                 </Button>
        //               )}
        //             </Col>
        //           </Row>
        //         </Card.Body>
        //       </Card>
        //     </Col>
        //   </Row>
        // </Container>
        <> </>
      )}
    </>
  );
};

export default AiApplicationSummary;
