import React from "react";
import { Form, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const DomainSetupStep1 = ({
  surveyStep,
  domainOption,
  handleDomainChoice,
  subdomain,
  setSubdomain,
  handleInputBlur,
  customSubdomain,
  setCustomSubdomain,
  customRoot,
  setCustomRoot,
  isValid,
}) => {
  if (surveyStep !== 1) {
    return null;
  }

  return (
    <>
      <Form.Group controlId="domainChoice" className="mb-3">
        <Form.Label>
          <strong>Select Domain Option</strong>
        </Form.Label>
        <Form.Check
          type="radio"
          id="useSubdomain"
          label="Use our subdomain"
          name="domainChoice"
          // defaultValue={"subdomain"}
          onChange={() => handleDomainChoice("subdomain")}
          checked={domainOption === "subdomain"}
          className="mb-2"
        />
        {domainOption === "subdomain" && (
          <div
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <Form.Control
              type="text"
              placeholder="yourchoice"
              onBlur={handleInputBlur}
              value={subdomain}
              onChange={(e) => setSubdomain(e.target.value)}
              className=""
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
            />
            <div
              className="border-end rounded-end"
              style={{ backgroundColor: "#f0f0f0", padding: "7px 8px" }}
            >
              .streetteam.site
            </div>
          </div>
        )}
        <Form.Check
          type="radio"
          id="useCustomSubdomain"
          label="Use a custom domain"
          name="domainChoice"
          onChange={() => handleDomainChoice("custom")}
          checked={domainOption === "custom"}
        />
      </Form.Group>
      {domainOption === "custom" && (
        <div>
          <Alert variant="info">
            To use a custom domain, you will need to complete an advanced setup
            process.
          </Alert>
          <Form.Group controlId="customDomain">
            <Form.Label>
              <strong>Enter Custom Website (eg. music.yourname.com)</strong>
            </Form.Label>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="rounded-start"
                style={{ backgroundColor: "#f0f0f0", padding: "7px 8px" }}
              >
                <span>https://</span>
              </div>
              <Form.Control
                className="rounded-start-0 rounded-end-0"
                type="text"
                placeholder="subdomain"
                value={customSubdomain}
                onBlur={handleInputBlur}
                onChange={(e) => setCustomSubdomain(e.target.value)}
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              />
              <div style={{ padding: "7px 8px" }}>
                <span>.</span>
              </div>
              <Form.Control
                className="rounded-start-0 rounded-end"
                type="text"
                placeholder="yourdomain.com"
                onBlur={handleInputBlur}
                value={customRoot}
                onChange={(e) => setCustomRoot(e.target.value)}
              />
              {isValid === true && (
                <div
                  className="ms-2"
                  style={{
                    width: "20px",
                    height: "20px",
                    flexShrink: 0,
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                    }}
                    className="text-success"
                    icon={faCheckCircle}
                  />
                </div>
              )}
              {isValid === false && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip
                      id="tooltip-right"
                      className="ms-2"
                      // style={{ textAlign: "left" }}
                    >
                      Please enter a valid subdomain and domain. For example,
                      "music.yourdomain.com".
                    </Tooltip>
                  }
                >
                  <div
                    className="ms-2"
                    style={{
                      width: "20px",
                      height: "20px",
                      flexShrink: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                      }}
                      className="text-danger"
                      icon={faTimesCircle}
                    />
                  </div>
                </OverlayTrigger>
              )}
            </div>
            {customSubdomain && customRoot && (
              <p className="ms-0 mt-2">
                Preview:{" "}
                <strong>
                  https://{customSubdomain}.{customRoot}
                </strong>
              </p>
            )}
          </Form.Group>
        </div>
      )}
    </>
  );
};

export default DomainSetupStep1;
