import axios from "axios";
import { getDomain } from "../../hooks/apiHelper";

async function getCustomValueId(api_key,location_id, name) {
  try {
    console.log(`getting customValue for ${name}`);

    const response = await getCustomValues(api_key,location_id);

    console.log("Response received", response.data);

    const customValue = response.data.find(
      (field) => field.name === name
    );

    console.log("Custom value found:", customValue);

    return customValue ? customValue.id : null;
  } catch (error) {
    console.log("Error in getCustomValueId:", error);
  }
}

// console.log(`getting customValue for ${name}`)
const getCustomValues = async (api_key,location_id) => {
  const response = await axios.get(
    `${getDomain()}/custom-values?location_id=${location_id}`,
    { headers: { Authorization: `Bearer ${api_key}` } }
  );
  return response;
};
export { getCustomValues, getCustomValueId };
