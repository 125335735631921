import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import Button from "react-bootstrap/Button";
import { Card, Form, Container, Row } from "react-bootstrap";
import { META_CLIENT_ID, META_REDIRECT_URI } from "../adCreator/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeta } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";
import {
  fetchConnectionData,
  saveConnectionData,
  api,
  // postMetaCallback,
  // getMe,
  // getAdAccounts,
  // getPages,
  // postPageConnection,
} from "./api";
import { API_BASE_URL } from "../../utils/constants";

const MetaMetricsConnection = () => {
  const [adAccountOptions, setAdAccountOptions] = useState([]);
  const [selectedAdAccount, setSelectedAdAccount] = useState(null);
  // const [hasCalledCallback, setHasCalledCallback] = useState(false);
  const [pageOptions, setPageOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // const [isTokenInvalid, setIsTokenInvalid] = useState(false);
  // const [isTokenValid, setIsTokenValid] = useState(false);

  const [metaConnection, setMetaConnection] = useState(() => {
    const storedMetaConnection =
      JSON.parse(localStorage.getItem("metaConnection")) || {};

    return {
      api_key: localStorage.getItem("api_key") || null,
      location_id: localStorage.getItem("location_id") || null,
      connection_name: "meta",
      access_token: storedMetaConnection.access_token || null,
      user_id: storedMetaConnection.user_id || null,
      ad_account_id: storedMetaConnection.ad_account_id || null,
      isComplete: storedMetaConnection.isComplete || false,
      id: storedMetaConnection.id || null,
      page_id: storedMetaConnection.page_id || null,
    };
  });
  // const [prevState, setPrevState] = useState({});

  // const [loading, setLoading] = useState(false);

  const windowUrl = useLocation();
  const searchParams = new URLSearchParams(windowUrl.search);

  const location_id =
    searchParams.get("location_id") ||
    localStorage.getItem("location_id") ||
    "";
  const api_key =
    searchParams.get("api_key") || localStorage.getItem("api_key") || "";

  // Run validateAccessToken function on component mount if access_token exists
  useEffect(() => {
    // Function to validate the access token by sending it to your backend
    const validateAccessToken = async () => {
      try {
        const response = await api.post(
          `/connections/${location_id}/meta/validate`,
          {
            access_token: metaConnection.access_token,
          }
        );

        if (response.status === 200) {
          // console.log("Token is valid", response.data);
        } else {
          handleInvalidToken();
        }
      } catch (error) {
        console.error("Error validating access token:", error);
        handleInvalidToken();
      }
    };
    if (metaConnection?.access_token) {
      // console.log("Validating access token");
      validateAccessToken();
    }
  }, [metaConnection?.access_token, location_id]);

  // A function to handle invalid tokens
  const handleInvalidToken = () => {
    console.error("Invalid or expired token. Redirecting to login.");
    localStorage.removeItem("metaConnection");

    // Clear stored tokens and related data
    setMetaConnection((prevState) => ({
      ...prevState,
      access_token: null,
      user_id: null,
      ad_account_id: null,
      isComplete: false,
      id: null,
      page_id: null,
    }));
  };

  useEffect(() => {
    if (!location_id || !api_key) {
      console.error("No location_id or api_key found");
      return;
    }

    const fetchData = async () => {
      try {
        const data = await fetchConnectionData(location_id);
        setMetaConnection(data);
      } catch (error) {
        console.error("Failed to fetch connection data", error);
        // If the error indicates an invalid or expired token, handle it here.
        if (error.message === "Invalid or expired token") {
          handleInvalidToken();
        }
      }
    };

    fetchData();
  }, [location_id, api_key]);

  useEffect(() => {
    // // console.log("metaConnection has been updated:", metaConnection);
    const previousMetaConnection =
      JSON.parse(localStorage.getItem("metaConnection")) || {};
    // // console.log("previousMetaConnection localStorage:", previousMetaConnection);

    if (metaConnection && metaConnection !== previousMetaConnection) {
      // // console.log(
      //   "setting localStorage with new metaConnection:",
      //   metaConnection
      // );
      localStorage.setItem(
        "metaConnection",
        JSON.stringify({ ...previousMetaConnection, ...metaConnection })
      );
    }
  }, [metaConnection]);

  const handleAuthClick = () => {
    setIsLoading(true); // Enable loading spinner and message

    let scopes = [
      "ads_management",
      "ads_read",
      "business_management",
      "pages_manage_ads",
      "pages_manage_metadata",
      "pages_show_list",
      "pages_messaging",
    ];
    const META_AUTH_URL = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${META_CLIENT_ID}&redirect_uri=${META_REDIRECT_URI}&scope=${scopes.join(
      ","
    )}`;
    // window.open(META_AUTH_URL, "_blank");
    let authWindow = window.open(
      META_AUTH_URL,
      "_blank",
      "width=800,height=600"
    );

    // Polling to check if the new window is closed
    const checkAuthWindowClosed = setInterval(() => {
      if (authWindow.closed) {
        setIsLoading(false); // Disable loading spinner and message
        clearInterval(checkAuthWindowClosed);

        // Check if authentication was successful, possibly by looking for an updated access token
        // If successful, proceed with connection setup
        // Otherwise, show an error
      }
    }, 500);
  };

  useEffect(() => {
    // // console.log("adAccountOptions:", adAccountOptions);
  }, [adAccountOptions]);

  useEffect(() => {
    // Setting location_id and api_key to localStorage
    // Overwrite if they don't match
    if (location_id && localStorage.getItem("location_id") !== location_id) {
      localStorage.setItem("location_id", location_id);
      localStorage.removeItem("metaConnection");

      // Clear stored tokens and related data
      setMetaConnection((prevState) => ({
        ...prevState,
        access_token: null,
        user_id: null,
        ad_account_id: null,
        isComplete: false,
        id: null,
        page_id: null,
      }));
    }
    if (api_key && localStorage.getItem("api_key") !== api_key) {
      localStorage.setItem("api_key", api_key);
    }
  }, [location_id, api_key]);

  useEffect(() => {
    // New function to handle the shared axios post logic
    const performAxiosPost = (code) => {
      axios
        .post(`${API_BASE_URL}/connections/meta/callback`, {
          code,
          location_id: localStorage.getItem("location_id"),
        })
        .then((response) => {
          setMetaConnection((prevState) => ({
            ...prevState,
            access_token: response.data.access_token,
          }));
        })
        .catch((error) => {
          console.error("Error during authentication:", error);
          handleInvalidToken();
        });
    };

    // New method to handle postMessage from auth window
    const handlePostMessage = (event) => {
      const { type, payload } = event.data;

      if (type === "META_AUTH_SUCCESS") {
        performAxiosPost(payload.code);
      } else if (type === "META_AUTH_FAILURE") {
        console.error("Error during Meta authentication:");
        handleInvalidToken();
      }
    };

    // Setup event listener to watch for messages from the auth window
    window.addEventListener("message", handlePostMessage);

    if (metaConnection?.access_token && !metaConnection?.user_id) {
      axios
        .get(`https://graph.facebook.com/v18.0/me`, {
          params: { access_token: metaConnection.access_token },
        })
        .then((response) => {
          // // console.log("User ID fetched:", response.data.id);
          setMetaConnection((prevState) => ({
            ...prevState,
            user_id: response.data.id,
          }));
        })
        .catch((error) => {
          console.error("Error fetching user ID:", error);
          handleInvalidToken();
        });
    }

    if (metaConnection?.user_id && !adAccountOptions.length) {
      axios
        .get(
          `https://graph.facebook.com/v18.0/${metaConnection.user_id}/adaccounts`,
          {
            params: {
              access_token: metaConnection.access_token,
              fields: "id,name", // Add this line
            },
          }
        )
        .then((response) => {
          // // console.log("Fetched ad accounts:", response.data.data);
          setAdAccountOptions(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching ad accounts:", error);
          handleInvalidToken();
        });
    }

    return () => {
      // Cleanup event listener
      window.removeEventListener("message", handlePostMessage);
    };
  }, [metaConnection, adAccountOptions.length]);

  useEffect(() => {
    // console.log("metaConnection?.adAccountId", metaConnection?.ad_account_id);
    if (metaConnection?.ad_account_id) {
      // // console.log("setting selected ad account");
      setSelectedAdAccount(metaConnection.ad_account_id);
    }
  }, [metaConnection?.ad_account_id]);

  // For fetching the Pages once an Ad Account is selected
  useEffect(() => {
    if (metaConnection?.ad_account_id) {
      axios
        .get(`https://graph.facebook.com/v18.0/me/accounts`, {
          params: { access_token: metaConnection.access_token },
        })
        .then((response) => {
          setPageOptions(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching pages:", error);
          handleInvalidToken();
        });
    }
  }, [metaConnection?.ad_account_id, metaConnection?.access_token]);

  // For setting the selected Page if it already exists in metaConnection
  useEffect(() => {
    if (metaConnection?.page_id) {
      // console.log("setting selected page");
      setSelectedPage(metaConnection.page_id);
    }
  }, [metaConnection?.page_id]);

  const handleAdAccountSubmit = (selectedAdAccountId) => {
    if (!selectedAdAccountId) {
      console.error("No ad account selected");
      return;
    }
    setMetaConnection((prevState) => ({
      ...prevState,
      ad_account_id: selectedAdAccountId,
    }));

    // console.log("Ad Account selection successful:");
    setMetaConnection((prevState) => ({
      ...prevState,
      ad_account_id: selectedAdAccountId,
    }));
    localStorage.setItem(
      "metaConnection",
      JSON.stringify({
        ...metaConnection,
        ad_account_id: selectedAdAccountId,
      })
    );
    setSelectedAdAccount(selectedAdAccountId);

    // console.log("selectedAdAccountId", selectedAdAccountId);
  };

  const handlePageSubmit = async (selectedPageId) => {
    if (!selectedPageId) {
      console.error("No page selected");
      return;
    }

    setMetaConnection((prevState) => ({
      ...prevState,
      page_id: selectedPageId,
    }));

    // console.log("selectedPageId", selectedPageId);

    try {
      const response = await saveConnectionData(location_id, {
        ...metaConnection,
        page_id: selectedPageId,
        isComplete: true,
      });

      // console.log("Page selection successful:", response);
      setMetaConnection((prevState) => ({
        ...prevState,
        id: response.connection.id,
        isComplete: true,
        page_id: selectedPageId,
      }));
      localStorage.setItem(
        "metaConnection",
        JSON.stringify({
          ...metaConnection,
          id: response.connection.id,
          isComplete: true,
          page_id: selectedPageId,
        })
      );
      setSelectedPage(selectedPageId);
      toast.success("Meta connection successful!");
    } catch (error) {
      console.error("Error submitting page selection:", error);
      handleInvalidToken();
    }
  };

  return (
    <div className="col-lg-6 mx-auto">
      {isLoading && (
        <div className="spinner-container">
          <div className="spinner"></div>
          <p>Please complete the authentication in the new window.</p>
        </div>
      )}
      {!isLoading && (
        <Container fluid className="px-5 py-3 mt-3">
          <Card className="px-5 py-5">
            <FontAwesomeIcon icon={faMeta} size="3x" />
            <div className="m-2 d-flex flex-column align-items-center justify-content-center">
              <h2 className="my-1">Meta Authentication</h2>
              {metaConnection?.access_token ? (
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <Button
                    className="d-flex justify-content-center my-3"
                    variant="success"
                    disabled
                  >
                    Connected ✓
                  </Button>
                  <div>
                    <div>
                      <Button
                        variant="link"
                        className="text-black p-0 border-0 bg-transparent"
                        onClick={handleAuthClick}
                      >
                        Click here to reconnect
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <Button
                  className="mt-3"
                  variant="primary"
                  onClick={handleAuthClick}
                >
                  Connect to Meta
                </Button>
              )}

              {metaConnection?.access_token && adAccountOptions?.length > 0 && (
                <Container>
                  <Form>
                    <Row className="mt-3">
                      <Form.Label>Select an Ad Account</Form.Label>{" "}
                      {/* Added Label */}
                      <Form.Select
                        value={selectedAdAccount || ""}
                        onChange={(e) => handleAdAccountSubmit(e.target.value)}
                      >
                        <option value="" disabled>
                          Select an Ad Account
                        </option>
                        <option value="" disabled></option>
                        {adAccountOptions.map((adAccount) => (
                          <option key={adAccount.id} value={adAccount.id}>
                            {adAccount.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Row>
                  </Form>
                </Container>
              )}
              {selectedAdAccount && pageOptions?.length > 0 && (
                <Container>
                  <Form>
                    <Row className="mt-3">
                      <Form.Label>Select a Page</Form.Label> {/* Added Label */}
                      <Form.Select
                        value={selectedPage || ""}
                        onChange={(e) => handlePageSubmit(e.target.value)}
                      >
                        {pageOptions.map((page) => (
                          <option key={page.id} value={page.id}>
                            {page.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Row>
                  </Form>
                </Container>
              )}
            </div>
            {metaConnection.isComplete && (
              <div className="d-flex justify-content-center flex-column align-items-center">
                <Button
                  className="mt-3 btn-fluid"
                  variant="primary"
                  onClick={() =>
                    (window.location.href = `${window.location.origin}/funnel-accelerator?location_id=${location_id}&api_key=${api_key}`)
                  }
                >
                  Go to Funnel Accelerator
                </Button>
              </div>
            )}
          </Card>
        </Container>
      )}
    </div>
  );
};

export default MetaMetricsConnection;
