import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Card, InputGroup } from "react-bootstrap";
import "../../App.scss";
import { API_BASE_URL } from "../../utils/constants";


const CampaignPublish = ({
  adCampaignData,
  updateAdCampaignData,
  handleSaveAdCampaign,
}) => {
  const [dailyBudget, setDailyBudget] = useState("");
  const [campaignURL, setCampaignURL] = useState(null);

  const handleBudgetChange = (e) => {
    setDailyBudget(e.target.value);
  };

  const handlePublishClick = async () => {
    if (!dailyBudget) {
      console.error("No budget set");
      return;
    }

    const payload = {
      dailyBudget,
      // add other campaign parameters here
      campaignName: "StreetTeam VTH Campaign",
      adName: "Sample Ad",
      bidAmount: 500,
      messageText: "Sample Message",
      quickReplyOptions: [],
      adCopy: "Sample Ad Copy",
      adImages: ["/path/to/image.jpg"],
      adVideos: ["/path/to/video.mp4"],
      audiences: adCampaignData?.audienceSelect?.targetingAudiences,
      accessToken: adCampaignData?.metaConnection?.accessToken,
      pageId: adCampaignData?.metaConnection?.pageId,
      pixelId: adCampaignData?.metaConnection?.pixelId,
      adAccountId: adCampaignData?.metaConnection?.adAccountId,
    };

    try {
      console.log("Publishing campaign...", payload);
      const response = await axios.post(
        `${API_BASE_URL}/post/campaigns/publish-test`,
        payload
      );

      if (response.status === 200) {
        console.log("Successfully published campaign:", response.data);

        // Extract ID and create Ads Manager URL
        console.log(response.data);
        console.log(response.data.id);
        const campaignID = response.data.id;
        const newCampaignURL = `https://adsmanager.facebook.com/adsmanager/manage/campaigns?act=503279464083870&business_id=1720328888272044&global_scope_id=1720328888272044&nav_entry_point=am_local_scope_selector&attribution_windows=default&column_preset=10162900030517586&date=2023-04-18_2023-04-19%2Cyesterday&insights_date=2023-04-18_2023-04-19%2Cyesterday&filter_set=SEARCH_BY_CAMPAIGN_GROUP_IDS-STRING_SET%1EANY%1E[%22${campaignID}%22]&selected_campaign_ids=${campaignID}`;

        // Update state with the new URL
        setCampaignURL(newCampaignURL);
      }
    } catch (error) {
      console.error("Error during campaign publishing:", error);
    }
  };

  return (
    <Card>
      <div className="m-4">
        <h2>Publish Your Campaign</h2>
        <Form.Group className="mt-3">
          <Form.Label>Set Your Ad Budget (Per Day)</Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder="Enter budget"
              onChange={handleBudgetChange}
            />
          </InputGroup>
        </Form.Group>
        <Button className="mt-4" variant="primary" onClick={handlePublishClick}>
          Publish Campaign
        </Button>
        {campaignURL && (
          <div className="mt-3" >
            <a href={campaignURL} className="text-secondary" target="_blank" rel="noopener noreferrer">
              View Campaign in Ads Manager
            </a>
          </div>
        )}
      </div>
    </Card>
  );
};

export default CampaignPublish;
