export const RowBreak = () => {
  return <div className="w-100 bg-black" style={{ height: 15 }}></div>;
};

export const VerticalBreak = ({ style, color }) => {
  return (
    <div
      style={{ backgroundColor: color || "black", height: "100%", ...style }}
    ></div>
  );
};
