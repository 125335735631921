import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./AdminBillingPortal.css"; // Import the CSS file

const ArtistIdForm = () => {
  const [artistId, setArtistId] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    window.location.href = `https://cloud.streetteam.me/billing-portal?crm_location_id=${artistId}`;
  };

  return (
    <div className="artist-id-form-container">
      <h1>Admin Billing Portal</h1>
      <Form onSubmit={handleSubmit} className="artist-id-form">
        <Form.Group className="mb-3">
          <Form.Label>Artist ID</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Artist ID"
            value={artistId}
            onChange={(e) => setArtistId(e.target.value)}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ArtistIdForm;
