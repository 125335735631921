import { DashSquare, PlusSquare } from "react-bootstrap-icons";
import { FunnelCell } from "./FunnelCell";
import { VerticalBreak } from "./FunnelBreaks";
import React from "react";

export const FunnelRow = ({
  title,
  data,
  cellClasses,
  cellStyles,
  isHeader,
  toggleMetrics,
  showMetrics,
  onClick,
  syncGoals,
}) => {
  const isBoldRow = [
    "Ad Spend",
    "Cost Per Stream",
    "Optin Rate",
    "Sales Rate",
    "Value Per Sale",
  ].includes(title);

  return (
    <div
      style={{
        position: "relative", // Added relative positioning here
        display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
        height: isHeader ? "75px" : "50px",
      }}
    >
      {toggleMetrics && (
        <div
          onClick={onClick} // Use the onClick prop here
          style={{
            position: "absolute", // Absolute positioning for the button
            left: 0, // Positioned to the left
            top: "50%", // Centered vertically
            transform: "translateY(-50%)", // Centered vertically
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          {showMetrics ? (
            <DashSquare size={18} style={{ opacity: 0.2 }} />
          ) : (
            <PlusSquare size={18} style={{ opacity: 0.2 }} />
          )}
        </div>
      )}
      <FunnelCell
        cellClasses={
          cellClasses + " title-font" + (isBoldRow ? " fw-bolder" : "")
        }
        cellStyles={{
          ...cellStyles,
          backgroundColor: isHeader ? "black" : "white",
        }}
        data={title}
        bg="white"
        flex={2.5}
      />
      <VerticalBreak style={{ width: "15px" }} />
      <FunnelCell
        cellClasses={
          cellClasses +
          (isHeader ? " header-font" : "") +
          (isBoldRow ? " fw-bolder" : "")
        }
        cellStyles={cellStyles}
        data={data[0]}
        bg="green"
        bold
      />
      <VerticalBreak style={{ width: "15px" }} />
      {data.slice(1).map((item, index) => (
        <React.Fragment key={index}>
          <FunnelCell
            cellClasses={
              cellClasses +
              (isHeader ? " header-font" : "") +
              (isBoldRow ? " fw-bold" : "")
            }
            cellStyles={cellStyles}
            data={item}
            bg="purple"
            onClick={
              isHeader
                ? () => {
                    syncGoals(item);
                  }
                : undefined
            }
          />
          {index < data.length - 2 && (
            <VerticalBreak style={{ width: "1px" }} color="#331C64" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
