import React, { useState, useEffect } from "react";
import axios from "axios";
// import Button from "react-bootstrap/Button";
import { Card } from "react-bootstrap";
// import Select from "react-select";
import Form from "react-bootstrap/Form";
import Async from "react-select/async";

const AudienceSelect = ({ adCampaignData, updateAdCampaignData }) => {
  // const [audienceType, setAudienceType] = useState(null);
  // const [interests, setInterests] = useState([]);
  // const [suggestions, setSuggestions] = useState([]);
  const [selectedAudiences, setSelectedAudiences] = useState([[], [], []]);
  const [segmentAudiences, setSegmentAudiences] = useState({});
  const [adAccountId, setAdAccountId] = useState(null);

  const API_VERSION = "17.0";
  const ACCESS_TOKEN = JSON.parse(localStorage.getItem("adCampaignData"))
    .metaConnection?.accessToken;
  // console.log(ACCESS_TOKEN);
  const AD_ACCOUNT_ID = "act_1788962381408694";

  const handleInterestChange = (segment, index) => (selectedOptions) => {
    try {
      let newSelectedAudiences = [...selectedAudiences];
      newSelectedAudiences[index - 1] = selectedOptions.map((option) => ({
        id: option.value,
        name: option.label,
      }));

      setSelectedAudiences(newSelectedAudiences);

      setSegmentAudiences((prev) => ({
        ...prev,
        [segment]: selectedOptions.map((option) => ({
          id: option.value,
          name: option.label,
        })),
      }));

      let targetingArray = Object.values(segmentAudiences).flat();

      console.log("targetingArray", targetingArray);

      updateAdCampaignData("audienceSelect", {
        targetingAudiences: targetingArray,
      });
    } catch (error) {
      console.error("Error handling interest change:", error);
    }
  };

  const loadInterestOptions = async (inputValue) => {
    console.log("loadInterestOptions called with:", inputValue);
    try {
      const response = await axios.get(
        `https://graph.facebook.com/v${API_VERSION}/${AD_ACCOUNT_ID}/targetingsearch`,
        {
          params: {
            type: "adinterest",
            q: inputValue,
            access_token: ACCESS_TOKEN,
          },
        }
      );
      console.log(response);
      return response.data.data.map((interest) => ({
        label: interest.name,
        value: interest.id,
      }));
    } catch (error) {
      console.error("Error fetching interests:", error);
      return [];
    }
  };

  // const fetchSuggestions = async (interests) => {
  //   console.log("fetchSuggestions called");
  //   try {
  //     const response = await axios.get(
  //       `https://graph.facebook.com/v${API_VERSION}/${adAccountId}/targetingsuggestions`,
  //       {
  //         params: {
  //           targeting_list: JSON.stringify(
  //             interests.map((interest) => ({
  //               type: "interests",
  //               id: interest.value,
  //             }))
  //           ),
  //           access_token: ACCESS_TOKEN,
  //         },
  //       }
  //     );
  //     console.log(`suggestions response`, response);
  //     setSuggestions(response.data.data);
  //   } catch (error) {
  //     console.error("Error fetching suggestions:", error);
  //   }
  // };

  useEffect(() => {
    const fetchAccountId = async () => {
      try {
        const response = await axios.get(
          `https://graph.facebook.com/v${API_VERSION}/me`,
          {
            params: {
              access_token: ACCESS_TOKEN,
              fields: "adaccounts",
            },
          }
        );
        if (
          response.data.adaccounts &&
          response.data.adaccounts.data.length > 0
        ) {
          setAdAccountId(String(response.data.adaccounts.data[0].id));
          console.log(
            "Ad account ID:",
            String(response.data.adaccounts.data[0].id)
          );
          updateAdCampaignData(
            "adAccountId",
            String(response.data.adaccounts.data[0].id)
          );
        } else {
          console.error("No ad accounts found");
        }
      } catch (error) {
        console.error("Error fetching account ID:", error);
      }
    };

    if (ACCESS_TOKEN && !adAccountId) {
      fetchAccountId();
    }
  }, [ACCESS_TOKEN, updateAdCampaignData, adAccountId]);

  // useEffect(() => {
  //   if (interests.length > 0) {
  //     fetchSuggestions();
  //   }
  // }, [interests]);

  // const handleSubmit = async () => {
  //   if (!audienceType) {
  //     console.error("No audience type selected");
  //     return;
  //   }

  //   const payload = {
  //     type: audienceType,
  //     interests: interests.map((interest) => interest.value),
  //   };

  //   try {
  //     // Make API request to your backend to set the audience
  //     const endpoint =
  //       audienceType === "existing"
  //         ? "/meta/audience/existing"
  //         : "/meta/audience/create";
  //     const response = await axios.post(endpoint, payload);

  //     if (response.status === 200) {
  //       console.log("Successfully set audience:", response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error during audience selection:", error);
  //   }
  // };

  return (
    <Card>
      <div className="m-4">
        <h2>Choose Your Audience</h2>
        {/* <Form.Group>
          <Form.Label>Select Audience Type</Form.Label>
          <Form.Control as="select" onChange={handleAudienceTypeChange}>
            <option value="" disabled selected>
              Select an option
            </option>
            <option value="new">New Fans (Interests & Lookalikes) </option>
          </Form.Control>
        </Form.Group>
        {audienceType === "new" && ( */}
        <Form.Label>Enter 3 of Your Favorite Artists</Form.Label>
        <Form.Group>
          <Async
            isMulti
            cacheOptions
            defaultOptions={true}
            loadOptions={loadInterestOptions}
            onChange={handleInterestChange("audience_1", 1)}
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Async
            isMulti
            cacheOptions
            defaultOptions={true}
            loadOptions={loadInterestOptions}
            onChange={handleInterestChange("audience_2", 2)}
          />
        </Form.Group>
        <Form.Group className="mt-2">
          <Async
            isMulti
            cacheOptions
            defaultOptions={true}
            loadOptions={loadInterestOptions}
            onChange={handleInterestChange("audience_3", 3)}
          />
        </Form.Group>
        {/* )} */}
        {/* {suggestions.length > 0 && (
          <Form.Group>
            <Form.Label>Suggestions</Form.Label>
            {suggestions.map((suggestion) => (
              <div key={suggestion.id}>{suggestion.name}</div>
            ))}
          </Form.Group>
        )} */}
      </div>
    </Card>
  );
};

export default AudienceSelect;
