import axios from "axios";

const TestApiAuth = () => {
  const testAuth = () => {
    axios
      .get("https://colorful-idea-n191w.ampt.app", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
      });
  };

  return (
    <div>
      <button onClick={testAuth}>Test Auth</button>
    </div>
  );
};

export default TestApiAuth;
