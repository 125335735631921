import axios from "axios";
// import { params } from "@ampt/sdk";
import { AxiosResponse } from "axios";
import { API_BASE_URL } from "../../../utils/constants";

export const api = axios.create({
  baseURL: API_BASE_URL,
});

export const SPOTIFY_CLIENT_ID = "e3d73c4d578b49f185a95fb5dbb09385";
export const SPOTIFY_CLIENT_SECRET = "cc6e042fe3d74f7caea541ede2070525";

export const fetchData = async () => {
  try {
    const response = await axios.get(
      "https://jsonplaceholder.typicode.com/todos/1"
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const trackStandardAction = async (
  location_id: string,
  contact_id: string,
  action_type: string,
  properties: any
): Promise<any> => {
  try {
    let action = {
      location: {
        id: location_id,
      },
      contact: {
        id: contact_id,
      },
      ...properties,
    };
    const response: AxiosResponse<any> = await api.post(
      `/actions/${action_type}`,
      action
    );
    return response.data;
  } catch (error) {
    console.error("Error saving connection data:", error);
    throw error;
  }
};


export const trackCustomAction = async (
  { location, contact, action_name, properties }: { location: { id: string }, contact: { id?: string, email?: string }, action_name: string, properties: any }
): Promise<any> => {
  try {
    // Validate that either id or email is provided
    if (!contact.id && !contact.email) {
      throw new Error(`Contact must include either an id or an email in trackCustomAction. Contact: ${JSON.stringify(contact)}`);
    }

    let action = {
      location,
      contact,
      customData: {
        action: action_name,
        ...properties,
      },
    };

    const response: AxiosResponse<any> = await axios.post(`/actions`, action);
    return response.data;
  } catch (error) {
    console.error("Error saving connection data:", error);
    throw error;
  }
};

export const fetchMilestones = async (location_id: string): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await api.get(
      `/milestones/${location_id}`
    );
    console.log("fetchMilestones", response.data);
    return response.data;
  } catch (error) {
    console.error("Error saving connection data:", error);
    throw error;
  }
};
