import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Form,
  Card,
  Alert,
  Container,
  ListGroup,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import { API_BASE_URL } from "../../utils/constants";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCopy } from "@fortawesome/free-solid-svg-icons";

const AccountVerification = () => {
  const [email, setEmail] = useState("");
  const [crmLocationId, setCrmLocationId] = useState("");
  const [accountDetails, setAccountDetails] = useState(null);
  const [error, setError] = useState("");
  const [isMultipleAccounts, setIsMultipleAccounts] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // New state for fetching status
  // const [isCopying, setIsCopying] = useState(false); // New state for copying status
  const [emailAttempts, setEmailAttempts] = useState(0); // New state for email attempts
  const [parentUrlParams, setParentUrlParams] = useState({}); // New state for URL params from parent
  const [uniqueRegistrationCount, setUniqueRegistrationCount] = useState(null);

  const fetchMetrics = async (locationId) => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 30); // set to 30 days ago

    try {
      const response = await axios.get(
        `${API_BASE_URL}/metrics/${locationId}`,
        {
          params: {
            start_date: startDate.toISOString().split("T")[0], // format as YYYY-MM-DD
            end_date: endDate.toISOString().split("T")[0], // format as YYYY-MM-DD
            path: "unique.registration",
          },
        }
      );

      // Assuming the response contains the data in a structure you expect
      console.log("Metrics response:", response.data);
      const data = response.data.metrics;
      const uniqueRegistration = data?.["unique.registration"];

      // Update state with the count
      if (uniqueRegistration) {
        console.log("Unique registration count:", uniqueRegistration);
        setUniqueRegistrationCount(uniqueRegistration);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      // Handle errors as appropriate
    }
  };

  // async function copyToClipboard(text) {
  //   try {
  //     console.log("Copying to clipboard: ", text);
  //     await navigator.clipboard.writeText(text);
  //     setIsCopying(true); // Set copying state to true
  //     setTimeout(() => {
  //       setIsCopying(false); // Set fetching state to false after fetching
  //     }, 3000);
  //   } catch (err) {
  //     setIsCopying(false); // Set fetching state to false after fetching
  //   }
  // }
  const handleAccountResponse = useCallback(
    (data) => {
      console.log("Account data received: ", data);
      setEmail("");
      if (!data || data.length === 0) {
        if (emailAttempts < 1) {
          setError("No account found. Please try a different email address.");
        } else {
          setError(
            "We were still unable to find your account. Please your CRM Location ID directly."
          );
        }
        setEmailAttempts(emailAttempts + 1); // Increment email attempts
      } else if (data.length > 1) {
        setError("");
        setIsMultipleAccounts(true);
        setAccountDetails(data);
      } else {
        setError("");
        setAccountDetails(data[0].value);
      }
      return;
    },
    [emailAttempts]
  );

  const fetchAccountByLocationId = useCallback(
    async (locationId) => {
      console.log(`Fetching account by location ID: ${locationId}`);
      setIsFetching(true); // Set fetching state to true

      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/accounts/lookup?crm_location_id=${locationId}`
        );
        setIsFetching(false); // Set fetching state to false after fetching
        handleAccountResponse(response.data);
      } catch (err) {
        console.error("Error fetching account by location ID: ", err);
        setIsFetching(false); // Set fetching state to false after fetching

        setError("Error fetching account by location ID.");
      }
    },
    [handleAccountResponse]
  );

  useEffect(() => {
    const storedCrmLocationId = localStorage.getItem("crm_location_id");
    if (storedCrmLocationId) {
      console.log(
        `Found CRM Location ID in localStorage: ${storedCrmLocationId}`
      );
      fetchAccountByLocationId(storedCrmLocationId);
    }
  }, [fetchAccountByLocationId]);

  const fetchAccountByEmail = useCallback(
    async (email) => {
      setIsFetching(true);

      console.log(`Fetching account by email: ${email}`);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/accounts/lookup?email=${email}`
        );
        handleAccountResponse(response.data);
        setIsFetching(false); // Set fetching state to false after fetching
      } catch (err) {
        console.error("Error fetching account by email: ", err);
        setError("Error fetching account by email.");
        setIsFetching(false); // Set fetching state to false after fetching
      }
    },
    [handleAccountResponse]
  );
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    console.log(`Email form submitted with: ${email}`);
    fetchAccountByEmail(email);
  };

  const handleAdditionalFormSubmit = (e) => {
    e.preventDefault();
    console.log(`Additional form submitted with: ${e}`);
    // either included email or crmLocationId
    if (crmLocationId) {
      fetchAccountByLocationId(crmLocationId);
    } else if (email) {
      fetchAccountByEmail(email);
    }
  };

  const handleCrmIdSubmit = (e) => {
    e.preventDefault();
    console.log(`CRM ID form submitted with: ${crmLocationId}`);
    fetchAccountByLocationId(crmLocationId);
  };

  const handleConfirmation = (account) => {
    setAccountDetails(account);
    setIsMultipleAccounts(false);
    setIsConfirmed(true); // Set the confirmation state to true
    fetchMetrics(account.id); // Fetch metrics when an account is confirmed
  };

  const selectAccount = (account) => {
    setAccountDetails(account);
    setIsMultipleAccounts(false);
    fetchMetrics(account.id); // Fetch metrics when an account is selected
  };

  useEffect(() => {
    // console.log('useEffect called for "message" event listener');
    window.parent.postMessage({ iframeReady: true }, "*");

    const handleMessage = (event) => {
      const data = event.data; // URL params are in this object
      // console.log("Received data:", data);
      setParentUrlParams(data);
    };

    window.addEventListener(
      "message",
      handleMessage
      // (event) => {
      // console.log("Message received in iframe:", event);
      // console.log("Data received:", event.data);
      //}
    );

    // Cleanup function to remove event listener when the component unmounts
    return () => {
      console.log("Removing event listener");
      window.removeEventListener("message", handleMessage);
    };
  }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount

  // Function to fetch account based on URL parameters
  const fetchAccountBasedOnUrlParams = useCallback(() => {
    if (parentUrlParams.crm_location_id) {
      fetchAccountByLocationId(parentUrlParams.crm_location_id);
    } else if (parentUrlParams.email) {
      fetchAccountByEmail(parentUrlParams.email);
    }
  }, [parentUrlParams, fetchAccountByLocationId, fetchAccountByEmail]);

  useEffect(() => {
    fetchAccountBasedOnUrlParams();
  }, [fetchAccountBasedOnUrlParams]);

  return (
    <Container className="t-5 d-flex justify-content-center">
      <Card style={{ width: "35rem", border: 0 }}>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          {!accountDetails && !error && (
            <Form className="shadow p-4" onSubmit={handleEmailSubmit}>
              <p>
                Please enter the email address you use to log into StreetTeam.
              </p>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isFetching}>
                {isFetching ? (
                  <span>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Loading...
                  </span>
                ) : (
                  "Submit"
                )}
              </Button>
            </Form>
          )}

          {!accountDetails && error && emailAttempts < 2 && (
            <Form className="shadow p-4" onSubmit={handleAdditionalFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter a different email address"
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          )}

          {!accountDetails && error && emailAttempts >= 2 && (
            <Form className="shadow p-4" onSubmit={handleCrmIdSubmit}>
              {/* CRM Location ID Input JSX */}
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  value={crmLocationId}
                  onChange={(e) => setCrmLocationId(e.target.value)}
                  placeholder="Enter your CRM Location ID"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" disabled={isFetching}>
                {isFetching ? "Fetching..." : "Submit"}
              </Button>
            </Form>
          )}

          {isMultipleAccounts && accountDetails && (
            <div className="shadow p-4">
              <h3 className="mt-3">Multiple accounts found</h3>
              <p>
                Please select the account that you would like to associate with
                your Modern Musician account.
              </p>
              <ListGroup className="">
                {accountDetails.map((account, index) => (
                  <ListGroup.Item key={index}>
                    <div className="d-flex justify-content-between">
                      <span>
                        {account.value?.business_name} -{" "}
                        {account.value?.owner?.email}
                      </span>
                      <Button
                        variant="secondary"
                        onClick={() => selectAccount(account.value)}
                      >
                        Select Account
                      </Button>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}

          {accountDetails && !isMultipleAccounts && !isConfirmed && (
            <Row className="shadow p-4 gap-2">
              <h3>Is this your account?</h3>
              <h6>
                {accountDetails.business_name} - {accountDetails.owner?.email}
              </h6>
              {uniqueRegistrationCount !== null && (
                <div>
                  <span className="my-1 fw-semibold">
                    {uniqueRegistrationCount} Fans Subscribed
                  </span>
                  <span> (Past 30 Days) </span>
                </div>
              )}
              <div>
                <Button
                  className="text-secondary p-0 fw-semibold"
                  href={`https://app.streetteam.me/v2/location/${accountDetails.id}/contacts/smart_list/All`}
                  variant="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to view contacts
                </Button>
              </div>
              <hr className="my-2"></hr>
              <div>
                <Button
                  variant="success"
                  onClick={() => handleConfirmation(accountDetails)}
                >
                  Yes it is
                </Button>
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => setAccountDetails(null)}
                >
                  No it's not
                </Button>
              </div>
            </Row>
          )}
          {isConfirmed && (
            <Row className="shadow p-4 align-items-center">
              <h3>Welcome back {accountDetails.owner?.first_name}!</h3>
              <Col className="mt-2">
                <span className="fw-semibold">
                  Please copy your Artist ID below,
                </span>
                <span>
                  {" "}
                  then share in the chat with a Modern Musician team member so
                  we can update your Artist Suite access for you.
                </span>
                <hr></hr>
              </Col>
              {/* <Button
                className="mt-3"
                variant="primary"
                onClick={() => copyToClipboard(accountDetails.id)}
              >
                <FontAwesomeIcon icon={faCopy} className="me-2" size="lg" />
                {isCopying ? "Copied!" : "Copy Artist ID"}
              </Button> */}

              <p className=" mb-0"> Your Artist ID:</p>
              <p className="fw-bolder mt-0">{accountDetails.id}</p>
            </Row>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AccountVerification;
