import { Button, Modal, Form } from "react-bootstrap";

const ConfirmationModal = ({ show, onHide, title, description, onConfirm }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{title || "Are you Sure?"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <h5 className="text-dark">{description}</h5>
        <Form></Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="outline-secondary" onClick={onConfirm}>
          Yes, I'm Sure
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ConfirmationModal;
