import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner, Form } from "react-bootstrap";

const QuickstartModal = ({
  show,
  onHide,
  locationData,
  onCreateCampaign,
  isLoading,
}) => {
  const [quickLaunchSettings, setQuickLaunchSettings] = useState({
    name: "",
    genre: "",
    streamingLink: "",
    communityLink: "",
    starterPackLink: "",
  });

  function quickLaunchInput({ label, key }) {
    return (
      <Form.Group className="mb-2">
        <Form.Label>{label}</Form.Label>
        <Form.Control
          value={quickLaunchSettings[key]}
          onChange={(e) =>
            setQuickLaunchSettings({
              ...quickLaunchSettings,
              [key]: e?.target?.value,
            })
          }
        />
      </Form.Group>
    );
  }

  function handleCreateCampaign() {
    onCreateCampaign(quickLaunchSettings);
  }

  useEffect(() => {
    setQuickLaunchSettings({
      name: locationData?.name,
      genre: (locationData?.custom_values || {})["Artist Genre Primary"] || "",
      streamingLink: locationData?.funnel_links?.fan_magnet_funnel,
      communityLink: locationData?.funnel_links?.free_community,
      starterPackLink: locationData?.funnel_links?.starter_pack,
    });
  }, [locationData]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Welcome to ArtistAI Avatar</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <h5 className="text-dark">
          Tell us about yourself and we will automatically set up a campaign for
          you to try
        </h5>
        <Form>
          {quickLaunchInput({ label: "Artist Name", key: "name" })}
          {quickLaunchInput({ label: "Genre", key: "genre" })}
          {quickLaunchInput({ label: "Streaming Link", key: "streamingLink" })}
          {quickLaunchInput({ label: "Community Link", key: "communityLink" })}
          {quickLaunchInput({
            label: "Starter Pack Link",
            key: "starterPackLink",
          })}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleCreateCampaign}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <FontAwesomeIcon className="me-2" icon={faShareFromSquare} />
              Let's Go
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default QuickstartModal;
