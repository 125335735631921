import { Form, Button, Col, Row } from "react-bootstrap";
import { Draggable } from "react-beautiful-dnd";
import {
  faBars,
  faTrash,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  FRIENDLY_ACTIONS,
  FRIENDLY_TO_STANDARD,
  STANDARD_ACTIONS,
  STANDARD_TO_FRIENDLY,
} from "../../utils";

const CampaignStep = ({
  step,
  links,
  onRemoveStep,
  idx,
  forceOpen,
  onEditStep,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingLink, setIsEditingLink] = useState(false);

  const [actionType, setActionType] = useState("");

  useEffect(() => {
    if (forceOpen) {
      setIsEditing(true);
    }
  }, [forceOpen]);

  useEffect(() => {
    if (STANDARD_ACTIONS.includes(step?.action)) {
      setActionType(step?.action);
    } else {
      setActionType("Custom");
    }
  }, [step]);

  function handleLinkChange(newVal) {
    if (newVal === "add_new") {
      setIsEditingLink(true);
    } else {
      const newLink = links.find((l) => l.id === newVal);
      onEditStep({
        ...step,
        link: {
          ...step?.link,
          redirect_url: newLink.redirect_url,
          id: newLink.id,
        },
      });
    }
  }

  function handleCancel() {
    setIsEditing(false);
    setIsEditingLink(false);
  }

  return (
    <Draggable key={step?.id} draggableId={step?.id} index={idx}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {isEditing ? (
            <Form>
              <div className="mt-3 bg-light-gray rounded p-4" variant="light">
                <Row className="flex-nowrap">
                  <Col xs={12} className="flex-shrink-1 d-flex flex-column">
                    <Form.Group className="mt-2">
                      <Form.Label>Action Type*</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        className="custom-select"
                        value={STANDARD_TO_FRIENDLY[actionType]}
                        onChange={(e) => {
                          setActionType(FRIENDLY_TO_STANDARD[e?.target?.value]);
                          if (e?.target?.value !== "Custom") {
                            onEditStep({
                              ...step,
                              action: FRIENDLY_TO_STANDARD[e?.target?.value],
                            });
                          } else {
                            onEditStep({
                              ...step,
                              action: "",
                            });
                          }
                        }}
                      >
                        {FRIENDLY_ACTIONS?.map((sa) => (
                          <option key={sa} value={sa}>
                            {sa}
                          </option>
                        ))}
                        <option value="Custom">Custom Action</option>
                      </Form.Control>
                      <div
                        className="fw-bold fst-italic mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        Action ID: {step?.action}
                      </div>
                      {actionType === "Custom" && (
                        <>
                          <div className="fw-bold py-4">
                            *Custom Actions are powerful, but they need to be
                            setup correctly.{" "}
                            <a
                              target="_blank"
                              className="text-secondary text-decoration-underline"
                              href="https://share.descript.com/view/ZaCYrQmO4kX"
                              rel="noreferrer"
                            >
                              Learn how to set up Custom Actions
                            </a>
                          </div>
                          <Form.Label className="mt-2">Action Name*</Form.Label>
                          <Form.Control
                            required
                            type="text"
                            value={step?.action}
                            onChange={(e) => {
                              onEditStep({
                                ...step,
                                action: e?.target?.value,
                              });
                            }}
                          />
                        </>
                      )}
                    </Form.Group>
                    <Form.Group className="mt-2">
                      <Form.Label>Link*</Form.Label>
                      {isEditingLink ? (
                        <>
                          <Form.Control
                            type="url"
                            placeholder="Enter a valid URL"
                            value={step?.link?.redirect_url}
                            onChange={(e) => {
                              onEditStep({
                                ...step,
                                link: {
                                  redirect_url: e?.target?.value,
                                },
                              });
                            }}
                            required
                          />
                        </>
                      ) : (
                        <Form.Control
                          as="select"
                          required
                          className="custom-select"
                          value={step?.link?.id}
                          onChange={(e) => handleLinkChange(e?.target?.value)}
                        >
                          {links?.map((link) => (
                            <option key={link.id} value={link.id}>
                              {link.redirect_url}
                            </option>
                          ))}
                          <option value="add_new">Create New Link</option>
                        </Form.Control>
                      )}
                    </Form.Group>
                    <Form.Group className="mt-2">
                      <Form.Label>Prompt*</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        value={step?.prompt}
                        onChange={(e) => {
                          onEditStep({
                            ...step,
                            prompt: e?.target?.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <div className="d-flex flex-row mt-4 w-full justify-content-end">
                      <Button
                        variant="outline-info"
                        className="me-2"
                        onClick={handleCancel}
                      >
                        Close
                      </Button>
                      <Button variant="outline-danger" onClick={onRemoveStep}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          ) : (
            <Form>
              <Form.Group>
                <div className="d-flex justify-content-between align-items-center bg-light-gray rounded mt-2 p-3">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon className="me-1" icon={faBars} />
                    <Form.Label className="mb-0 ms-2">
                      {STANDARD_ACTIONS.includes(step?.action)
                        ? STANDARD_TO_FRIENDLY[step?.action]
                        : step?.action}
                    </Form.Label>
                  </div>
                  <Button onClick={() => setIsEditing(true)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                </div>
              </Form.Group>
            </Form>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default CampaignStep;
