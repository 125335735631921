import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404</h1>
      <h2 style={styles.subHeader}>Page Not Found</h2>
      <p style={styles.text}>
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>
      <Link to="https://app.streetteam.me" style={styles.link}>
        Go to Homepage
      </Link>
    </div>
  );
};

// Styling
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    color: "#333",
    backgroundColor: "#f8f8f7",
    textAlign: "center",
    padding: "20px",
  },
  header: {
    fontSize: "6em",
    fontWeight: "bold",
    marginBottom: "0.5em",
  },
  subHeader: {
    fontSize: "2em",
    marginBottom: "1em",
  },
  text: {
    fontSize: "1.2em",
    marginBottom: "2em",
    maxWidth: "600px",
  },
  link: {
    display: "inline-block",
    padding: "10px 20px",
    fontSize: "1em",
    fontWeight: "bold",
    color: "white",
    // bootstrap primary color for background
    backgroundColor: "hsla(250, 100%, 72%, 1)",
    borderRadius: "5px",
    textDecoration: "none",
  },
};

export default NotFound;
