// ApplicationReview.js

import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Form,
  Button,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
// import { MM_CRM_LOCATION_ID } from "../../../../../integrations/Highlevel/constants";
import { API_BASE_URL } from "../../../../../utils/constants";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "../../../../FunnelAccelerator/utils";
import { format, parse } from "date-fns";
// import { useQuery } from "../../../hooks/useQueryParams";
import { toast } from "react-toastify";
// import { submitForm } from "../../../../../utils/submitForm";
import ContactContext from "../../../../../context/Contacts/ContactContext";
import { submitForm } from "../../../../../utils/submitForm";
import { MM_CRM_LOCATION_ID } from "../../../../../integrations/Highlevel/constants";
import cleanFormData from "../../../../../utils/cleanFormData";
import ArtistApplication from "./ArtistApplication";

function openInNewWindow(e, url) {
  e.preventDefault();
  window.open(url, "newwindow", "width=1280,height=720");
}

const ApplicationReview = ({ testmode, contact }) => {
  // ... component logic here ...

  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  // const [contactData, setContactData] = useState({
  //   first_name: "",
  //   last_name: "",
  // });

  const [contactData, setContactData, fetchingCompleted] =
    useContext(ContactContext);

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    console.log("contactData", contactData);
    console.log("fetchingCompleted", fetchingCompleted);

    if (contactData?.breakthrough?.application?.fields) {
      setFormData(contactData?.breakthrough?.application?.fields);
      // setContactData(contactData);
      setIsLoading(false);
    } else if (fetchingCompleted) {
      setIsLoading(false);
    }
  }, [contactData, setContactData, fetchingCompleted]);

  const genres = [
    "Alternative",
    "Americana",
    "Christian",
    "Classical",
    "Comedy",
    "Country",
    "Dance",
    "Electronic",
    "Folk",
    "Hip-Hop",
    "Indie",
    "Jazz",
    "Latin",
    "Metal",
    "New Age",
    "Pop",
    "Rap",
    "Reggae",
    "R&B",
    "Rock",
  ];

  // Define form questions
  const ARTIST_APPLICATION = [
    {
      key: "name",
      label: "What's your name?",
      inputType: "text",
    },
    {
      key: "email",
      label: "Email?",
      inputType: "email",
    },
    {
      key: "song_link",
      label: "Please share a link to stream one of your songs",
      inputType: "url",
    },
    {
      key: "artist_description",
      label: "Tell me briefly about your music career",
      inputType: "textarea",
      rows: 3,
    },
    {
      key: "fanbase_size",
      label: "What's the current size of your fanbase?",
      inputType: "radio",
      options: [
        "Under 1,000",
        "1,000 to 10,000",
        "10,000 to 100,000",
        "100,000 to 1,000,000",
        "Above 1,000,000",
      ],
    },
    {
      key: "monthly_revenue",
      label: "On average, how much are you making per month from music?",
      inputType: "radio",
      options: [
        "Under $100",
        "$100 to $1,000",
        "$1,000 to $10,000",
        "$10,000 to $100,000",
        "Above $100,000",
      ],
    },
    {
      key: "genre",
      label: "What genre do you make music in?",
      inputType: "radio",
      options: genres,
    },
    {
      key: "sub_genre",
      label: "Which type of rap speaks to you the most?",
      inputType: "radio",
      options: [
        "I vibe with rap that’s about power, street life, and doing whatever it takes to succeed (e.g., 50 Cent, Lil Durk).",
        "I like rap that focuses on self-improvement, social issues, and positive change (e.g., Kendrick Lamar, J. Cole).",
      ],
      conditions: [
        {
          trigger: formData?.genre !== "Hip-Hop" && formData?.genre !== "Rap",
          action: "skip",
        },
      ],
    },
    {
      key: "sustained_by_music",
      label:
        "Are you currently supporting yourself full-time with your music income?",
      inputType: "radio",
      options: ["Yes", "No"],
    },
    {
      key: "target_monthly_revenue",
      label:
        formData?.sustained_by_music === "Yes"
          ? "What's your target monthly revenue with your music?"
          : "How much would you need to make per month to fully replace your existing income?",
      inputType: "number",
      format: "currency",
    },
    {
      key: "annual_goals",
      label:
        "If we were having a discussion a year from today, and you were looking back on the past 12 months, what would have had to happen in both your life and career, for you to be excited about your progress?",
      inputType: "textarea",
      rows: 4,
    },
    {
      key: "roadblocks",
      label:
        "Be 100% honest - What do you think is stopping you from reaching your goals? (The more specific you are here the faster we'll be able to get you clarity during our call together)",
      inputType: "textarea",
      rows: 3,
    },
    {
      key: "coachable",
      label:
        "I'm looking for a few coaching clients to mentor personally with me and my team. If you're a good fit, would you be interested in working together?",
      inputType: "radio",
      options: ["Yes", "No"],
    },
    {
      key: "investable_time",
      label:
        "How many hours do you (or a team member) have available to focus on your music per day?",
      inputType: "radio",
      options: [
        "Less than 1 hour",
        "1 to 2 hours",
        "2 to 5 hours",
        "5 to 10 hours",
        "Above 10 hours",
      ],
    },
    {
      key: "belief",
      label: `If you bring in ${(
        formData?.target_monthly_revenue / 5
      ).toLocaleString()} fans who pay you $5 per month, you'll earn ${formatCurrency(
        formData?.target_monthly_revenue
      )}/m in revenue. With an experienced mentor, do you believe you can achieve this or more?`,
      inputType: "radio",
      options: ["Yes", "No"],
    },
    {
      key: "resourceful",
      label:
        "The minimum investment for 1:1 coaching with Modern Musician is $5k. During our call, if we both decide we'd like to move forward, are you able to do this? (We also have a $1500/mo payment plan available)",
      inputType: "radio",
      options: ["Yes", "No"],
    },
    {
      key: "investable_budget",
      label:
        "What is your maximum available budget per month to invest in growing your music career? (including recording, music videos, promotion, etc.)",
      inputType: "number",
      format: "currency",
      conditions: [
        {
          trigger: formData?.resourceful === "Yes",
          action: "skip",
        },
      ],
    },
    {
      key: "start_date",
      label: "If you are accepted, how soon can you get started?",
      inputType: "date",
    },
    {
      key: "secret_passcode",
      label: "What's the secret passcode?",
      inputType: "text",
    },
  ];

  const generateTalentScoutSummary = async (formData) => {
    let cleanedFormData = cleanFormData(formData);

    // fetch with axios from our api with the formData in the body to endpoint /ai/talent-scout-summary
    console.log("generateTalentScoutSummary with formData:", formData);
    const response = await axios.post(
      `${API_BASE_URL}/ai/talent-scout-summary`,
      cleanedFormData
    );

    console.log("response.data:", response.data);

    return response.data;
  };

  // handleSave function that submitForm()
  const handleSave = async () => {
    let fields = {};
    // add formData to fields object but add app__ to the keys
    Object.keys(formData).forEach((key) => {
      if (key === "name" || key === "email") {
        fields[key] = formData[key];
      }
      fields[`app__${key}`] = formData[key];
    });

    if (formData?.sub_genre?.includes("street")) {
      fields["app__genre"] = "Thug";
    }

    await submitForm({
      location: { id: MM_CRM_LOCATION_ID },
      contact: { id: contactData?.id },
      fields: fields,
    });

    let talentScoutSummary = await generateTalentScoutSummary(fields);
    console.log("talentScoutSummary:", talentScoutSummary);
    setIsEditing(false);
  };

  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center m-4 flex-column min-vh-100">
          <Spinner animation="border" variant="secondary" className="p-4" />
        </div>
      ) : (
        <ArtistApplication />
      )}
    </>
  );
};
export default ApplicationReview;
