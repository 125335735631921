import axios from "axios";
// import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";

const LOCAL_SANDBOX_URL = process.env.REACT_APP_SANDBOX_URL;
// const LOCAL_SANDBOX_URL = process.env.REACT_APP_PROD_URL;

const getDomain = () => {
  return window.location.hostname.includes("localhost")
    ? LOCAL_SANDBOX_URL
    : window.location.origin;
};

const isProduction = window.location.host === "cloud.streetteam.me";
const backendUrl = isProduction
  ? `https://api.modern-musician.link`
  : `https://dev.modern-musician.link`; //this will get migrated to cloud.streetteam.me at some point

const websocketUrl = `wss://${getDomain().split("https://")[1]}`;

const createLink = async (actionName, redirect_url, locationId, api_key) => {
  const newLink = {
    id: uuidv4().replace(/-/g, "").substring(0, 25),
    redirect_url: redirect_url,
    action: actionName,
    type: "action",
    location_id: locationId,
    archived: false,
  };

  try {
    let domain = getDomain();
    const response = await axios.post(
      `${domain}/data/links/templates/${locationId}/${newLink.id}`,
      newLink,
      {
        headers: {
          Authorization: `Bearer ${api_key}`,
        },
      }
    );
    return { status: "success", data: response.data.object };
  } catch (error) {
    console.error(error);
    return { status: "error", error };
  }
};

const updateLink = async (
  linkId,
  actionName,
  redirect_url,
  locationId,
  api_key
) => {
  console.log("updating link", actionName, redirect_url);

  const updatedLink = {
    redirect_url: redirect_url,
    action: actionName,
    type: "action",
    location_id: locationId,
    archived: false,
  };

  try {
    let domain = getDomain();
    await axios.put(
      `${domain}/data/links/templates/${locationId}/${linkId}`,
      updatedLink,
      {
        headers: {
          Authorization: `Bearer ${api_key}`,
        },
      }
    );
    return { status: "success", link: updatedLink };
  } catch (error) {
    console.error(error);
    return { status: "error", error };
  }
};

const saveCampaign = async (
  locationData,
  selectedCampaign,
  campaigns,
  api_key
) => {
  console.log("saving campaign...");

  let domain = getDomain();
  const updatedSteps = selectedCampaign.steps.map((step) => {
    const { id, type, action, link, prompt } = step;
    return { id, type, action, link, prompt };
  });

  const campaignObject = {
    ...selectedCampaign,
    steps: updatedSteps,
  };

  try {
    if (locationData.id) {
      const response = await axios.post(
        `${domain}/data/campaigns/${locationData.id}/${selectedCampaign?.id}`,
        campaignObject,
        {
          headers: {
            Authorization: `Bearer ${api_key}`,
          },
        }
      );
      console.log("saved campaign", response.data);

      const updatedCampaigns = campaigns.map((campaign) =>
        campaign.value.id === campaignObject.id
          ? { ...campaign, value: campaignObject }
          : campaign
      );

      return { status: "success", campaignObject, updatedCampaigns };
    } else {
      return { status: "error", message: "Missing Location ID" };
    }
  } catch (error) {
    console.error(error);
    return { status: "error", error };
  }
};

const deleteCampaign = async (
  locationData,
  selectedCampaign,
  campaigns,
  api_key
) => {
  console.log("deleting campaign...");

  let domain = getDomain();

  let redirect_url = `${domain}/data/campaigns/${locationData.id}/${selectedCampaign?.id}`;
  console.log(`delete redirect_url is ${redirect_url}`);

  try {
    const response = await axios.delete(redirect_url, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
    });
    console.log(response);

    const updatedCampaigns = campaigns.filter(
      (campaign) => campaign.value.id !== selectedCampaign?.id
    );

    return { status: "success", updatedCampaigns };
  } catch (error) {
    console.error(error);
    return { status: "error", error };
  }
};

const fetchCampaignData = async (location_id, api_key) => {
  let domain = getDomain();
  let campaignDataUrl = `${domain}/data/campaigns/${location_id}:*`;
  try {
    const response = await axios.get(campaignDataUrl, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
    });
    return response.data.object.items;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const fetchContactDataByEmail = async (email, location_id) => {
  // console.log("fetching contact data...", email, location_id);
  let domain = getDomain();
  let contactDataUrl = `${domain}/contacts/lookup?email=${email}&location_id=${location_id}`;
  try {
    const response = await axios.get(contactDataUrl);
    // console.log("response.data", response.data);
    return response.data.contact;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchContactData = async (crm_id, location_id) => {
  // console.log("fetching contact data...", email, location_id);
  let domain = getDomain();
  let contactDataUrl = `${domain}/contacts/lookup?crm_id=${crm_id}&location_id=${location_id}`;
  // console.log(`contactDataUrl is ${contactDataUrl}`);
  try {
    const response = await axios.get(contactDataUrl);
    // console.log("response.data", response.data);
    return response.data.contact;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchLocationData = async (location_id, api_key) => {
  let domain = getDomain();
  let locationDataUrl = `${domain}/data/locations/${location_id}`;
  try {
    const response = await axios.get(locationDataUrl, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
      withCredentials: true,
    });
    return response.data.object;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchLocationByEmail = async (email) => {
  let domain = getDomain();
  try {
    let response = await axios.get(
      `${domain}/data/account/lookup?email=${email}`
    );
    return response;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const updateLocationData = async (location_id, api_key, fieldsToUpdate) => {
  let domain = getDomain();
  let updateUrl = `${domain}/data/locations/${location_id}/update`;
  try {
    const response = await axios.post(updateUrl, fieldsToUpdate, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
    });
    // setUserData(response.data.object);
    return response.data.object; // Assuming the updated object is returned
  } catch (error) {
    console.error(error);
    return null;
  }
};

const saveCampaignData = async (location_id, campaignData, api_key) => {
  let domain = getDomain();
  let campaignDataUrl = `${domain}/data/campaigns/${location_id}/${campaignData.id}`;
  try {
    const response = await axios.post(campaignDataUrl, campaignData, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
    });
    return response.data.object;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const saveLinkData = async (location_id, linkData, api_key) => {
  let domain = getDomain();
  let linkDataUrl = `${domain}/data/links/${location_id}/${linkData.id}`;
  try {
    const response = await axios.post(linkDataUrl, linkData, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
    });
    return response.data.object;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchLinkData = async (location_id, api_key) => {
  let domain = getDomain();
  let allLinksUrl = `${domain}/data/links:templates:${location_id}:*`;
  try {
    const response = await axios.get(allLinksUrl, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
    });
    return response.data.object.items.map((item) => item.value);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const fetchAdCampaignData = async (location_id, api_key) => {
  let domain = getDomain();
  let campaignDataUrl = `${domain}/data/ad-campaigns/${location_id}:*`;
  try {
    const response = await axios.get(campaignDataUrl, {
      headers: {
        Authorization: `Bearer ${api_key}`,
      },
    });
    return response.data.object.items;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const saveAdCampaignData = async ({ adCampaignData, location_id, api_key }) => {
  let domain = getDomain();

  try {
    const response = await axios.post(
      `${domain}/data/ad-campaigns/${location_id}/${adCampaignData?.id}`,
      adCampaignData,
      {
        headers: {
          Authorization: `Bearer ${api_key}`,
        },
      }
    );
    return { status: "success", adCampaignData, response: response.data };
  } catch (error) {
    console.error(error);
    return { status: "error", error };
  }
};

const getCustomerBillingPortalSession = async ({ stripeCustomerId }) => {
  let domain = getDomain();

  try {
    const response = await axios.post(`${domain}/customer-billing-portal`, {
      stripeCustomerId,
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

const getSubscriptionByLocationId = async ({ crmLocationId }) => {
  let domain = getDomain();

  try {
    const response = await axios.get(
      `${domain}/subscriptions/location/${crmLocationId}`
    );
    const data = response.data;

    if (data.items) {
      if (data.items.length > 1) {
        // Multiple subscriptions found
        return {
          multipleSubscriptions: true,
          subscriptionIds: data.items.map((item) => item.key.split(":")[1]),
          customerId:
            data.items[0].value.customer_id ?? data.items[1].value.customer_id,
        };
      } else if (data.items.length === 1) {
        // Only one subscription found
        return data.items[0].value;
      } else {
        // No subscriptions found
        return { noSubscriptions: true };
      }
    } else {
      // No subscriptions found
      return { noSubscriptions: true };
    }
  } catch (error) {
    throw new Error(error);
  }
};

// Other API calls like handleCreateCampaign, handleSaveCampaign, handleSaveConfig can be added here

export {
  createLink,
  updateLink,
  saveCampaign,
  deleteCampaign,
  fetchContactDataByEmail,
  fetchContactData,
  fetchCampaignData,
  fetchLocationData,
  saveCampaignData,
  saveLinkData,
  fetchLinkData,
  getDomain,
  fetchLocationByEmail,
  updateLocationData,
  fetchAdCampaignData,
  saveAdCampaignData,
  websocketUrl,
  backendUrl,
  getCustomerBillingPortalSession,
  getSubscriptionByLocationId,
};
