import { faArrowRight, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { generateGradient, lightenColor } from "../../../../utils/colors";

const ActionButton = ({ children, points, type, ...rest }) => {
  //set defaults
  let icon;
  let textColor = "white";
  let color = "#8870FF";
  // Assuming the "children" is a string like "Continue With Facebook", we can extract the platform from it.
  //   const platform = children.split(" ").slice(-1)[0];

  //   switch (platform) {
  //     case "Facebook":
  //       icon = "https://path.to/facebook/icon.svg"; // replace with your Facebook icon path
  //       backgroundColor = "primary"; // Bootstrap color class for blue
  //       textColor = "white";
  //       break;
  //     case "Email":
  //       icon = "https://path.to/email/icon.svg"; // replace with your Email icon path
  //       backgroundColor = "dark"; // Bootstrap color class for dark gray
  //       textColor = "white";
  //       break;
  //     default:
  //       break;
  //   }

  switch (type) {
    case "referral":
      icon = faLink; // replace with your Facebook icon path
      //   backgroundColor = "primary"; // Bootstrap color class for blue
      //   textColor = "white";
      break;
    case "community":
      icon = "https://path.to/community/icon.svg"; // replace with your Email icon path
      //   backgroundColor = "dark"; // Bootstrap color class for dark gray
      //   textColor = "white";
      break;
    case "enter":
      icon = faArrowRight
      color = "#FED93E";
      textColor = 'black';
      break;
    default:
      break;
  }

  return (
    <Button
      className="d-flex align-items-stretch w-100 p-0"
      style={{
        border: `${color} 1px solid`,
        background: `${generateGradient(color)}`,
      }}
      {...rest}
    >
      <div
        className="px-2"
        style={{
          flex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon icon={icon} style={{ color: textColor }} />
      </div>
      <div
        className="py-3"
        style={{
          flex: "6",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          color:textColor,
        }}
      >
        <span>{children}</span>
      </div>
      <div
        style={{
          flex: "2",
          background: `${lightenColor(color, 2)}`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span style={{ color: textColor }}>+{points}</span>
        <small style={{ color: textColor }}>tokens</small>
      </div>
    </Button>
  );
};

export default ActionButton;
