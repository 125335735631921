import React, { useEffect, useRef, useState } from "react";
import {
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Form,
  Container,
  Card,
} from "react-bootstrap";
import "../../App.scss";

const IFrameConfigurator = () => {
  const [iframeParams, setIframeParams] = useState([
    { key: "src", value: "https://localhost:3000/account/verification" }, // default src
    { key: "width", value: "100%" }, // default width
    { key: "height", value: "600px" }, // default height
    { key: "scrolling", value: "auto" },
    // { key: "frameborder", value: "0" }, // default frameborder
    //   { key: "allow", value: "autoplay" },
    //   { key: "allowfullscreen", value: "true" },
    //   { key: "allowpaymentrequest", value: "true" },
    // Add other default parameters as needed
  ]);
  const [generatedIFrame, setGeneratedIFrame] = useState("");
  const [copyButtonText, setCopyButtonText] = useState("Copy");

  function getParentUrlParams() {
    var params = {};
    var queryString = window.location.search.substring(1);
    var vars = queryString.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  const updateParam = (index, key, value) => {
    const newParams = [...iframeParams];
    newParams[index][key] = value;
    setIframeParams(newParams);
  };

  const addParam = () => {
    setIframeParams([...iframeParams, { key: "", value: "" }]);
  };

  const removeParam = (index) => {
    const newParams = [...iframeParams];
    newParams.splice(index, 1);
    setIframeParams(newParams);
  };

  const iframeRef = useRef(null); // Create a ref for the iframe

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopyButtonText("Copied");
    setTimeout(() => setCopyButtonText("Copy"), 3000);
  };

  const generateIFrame = (additionalScript = ``) => {
    const iframeID = "mm-iframe";

    const paramsString = iframeParams
      .map((param) =>
        param.key !== "src"
          ? `${param.key}="${encodeURIComponent(param.value)}"`
          : `src="${param.value}"`
      )
      .join(" ");

    const iframeScript = `
<script>
  function getParentUrlParams() {
    var params = {};
    var queryString = window.location.search.substring(1);
    var vars = queryString.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  function sendMessageToIframe() {
    var iframe = document.getElementById('${iframeID}');
    if (!iframe) {
      console.error('Iframe not found');
      return;
    }
    var urlParams = getParentUrlParams();
    console.log("Sending URL parameters to iframe:", urlParams);
    iframe.contentWindow.postMessage(urlParams, '*');
  }

  // Listener for ready message from iframe
  window.addEventListener("message", (event) => {
    if (event.data.iframeReady) {
      console.log("IFrame is ready - received in parent");
      sendMessageToIframe();
    }
  });

  // Trigger ready check in iframe
  document.getElementById('${iframeID}').onload = function() {
    this.contentWindow.postMessage({ checkIframeReady: true }, '*');
  };
</script>
`;

    const iframeCode = `<iframe id="${iframeID}" ${paramsString} frameborder="0" allow="autoplay"></iframe>${iframeScript}`;
    setGeneratedIFrame(iframeCode);
  };

  useEffect(() => {
    const sendMessageToIframe = () => {
      if (iframeRef.current) {
        const urlParams = getParentUrlParams();
        setTimeout(() => {
          console.log("Sending message to iframe:", urlParams);
          iframeRef.current.contentWindow.postMessage(urlParams, "*");
        }, 500); // Delay sending the message by 500 ms or an appropriate duration
      }
    };
    const handleMessage = (event) => {
      if (event.data.iframeReady) {
        console.log("IFrame is ready - received in parent");
        sendMessageToIframe();
      }
      // ... handle other messages ...
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  // Function to render iframe for preview
  const renderIFrame = () => {
    const iframeAttributes = iframeParams.reduce((attrs, param) => {
      attrs[param.key] = param.value;
      return attrs;
    }, {});

    return (
      <iframe
        ref={iframeRef} // Attach the ref to the iframe
        {...iframeAttributes}
        frameBorder="0"
        allow="autoplay"
        title="iframe-preview"
        onLoad={() => {
          console.log("IFrame has loaded");
          // sendMessageToIframe();
        }}
        style={{
          width: iframeAttributes.width || "100%",
          height: iframeAttributes.height || "600px",
        }}
      ></iframe>
    );
  };

  return (
    <Container fluid className="p-5 my-5">
      <Card
        className="border-0 d-flex"
        style={{
          borderRadius: "0.75rem",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="card-body p-lg-5">
          <h1 className="card-title text-center mb-4">Configure Your iFrame</h1>
          <Form>
            {iframeParams.map((param, index) => (
              <FormGroup key={index} className="mb-3">
                <FormLabel>Parameter {index + 1}</FormLabel>
                <div className="input-group">
                  <FormControl
                    type="text"
                    placeholder="Key"
                    className="form-control"
                    value={param.key}
                    onChange={(e) => updateParam(index, "key", e.target.value)}
                  />
                  <FormControl
                    type="text"
                    placeholder="Value"
                    className="form-control"
                    value={param.value}
                    onChange={(e) =>
                      updateParam(index, "value", e.target.value)
                    }
                  />
                  <Button
                    variant="outline-danger"
                    onClick={() => removeParam(index)}
                    className="ms-2"
                  >
                    Remove
                  </Button>
                </div>
              </FormGroup>
            ))}
            <div className="text-center mt-4">
              <Button variant="primary" onClick={addParam} className="me-2">
                Add Parameter
              </Button>
              <Button variant="dark" onClick={generateIFrame}>
                Generate Embed Snippet
              </Button>
            </div>
          </Form>
        </div>
      </Card>

      {generatedIFrame && (
        <div className="text-center mt-5">
          <h3 className="mb-3">Preview</h3>
          <div className="d-flex justify-content-center">
            <Card
              className="border-0 d-flex justify-content-center align-items-center"
              style={{
                width: "100%",
                // boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              {renderIFrame()}
            </Card>
          </div>
          <button
            onClick={() => copyToClipboard(generatedIFrame)}
            className="btn btn-secondary mt-3"
          >
            {copyButtonText}
          </button>
        </div>
      )}
    </Container>
  );
};

export default IFrameConfigurator;
