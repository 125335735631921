import React, { useCallback, useEffect, useState } from "react";
import BRONZE from "./bronze.png";
import SILVER from "./silver.png";
import GOLD from "./gold.png";
import { drawImageInPerspective } from "../utils.ts";
import { asyncForEach, base64ToFile } from "../../../utils";
import { Spinner } from "react-bootstrap";

//create skewed cube image from file upload
function SkewedCubeImage({imageFile, contentId, relicRarity, setRelicArtworkFile}) {
  const [imageUrl, setImageUrl] = useState(null);
  const [combinedImages, setCombinedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const RELICS = [{rarity:'bronze',image:BRONZE}, {rarity:'silver',image:SILVER}, {rarity:'gold',image:GOLD}];

  useEffect(() => {
    // console.log('imageFile is now',imageFile);
    if (imageFile) {
      handleImageUpload(imageFile);
    }
  }, [imageFile]);

  const handleImageUpload = (file) => {
    // console.log(`file is`,file)
    // const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const performCombination = useCallback(
    async (newSkewedImageData, existingImageData) => {
      if (newSkewedImageData) {
        const existingImage = new Image();
        existingImage.src = existingImageData;

        const skewedImageElement = new Image();
        skewedImageElement.src = newSkewedImageData;

        await Promise.all([
          new Promise((resolve) => {
            existingImage.onload = resolve;
          }),
          new Promise((resolve) => {
            skewedImageElement.onload = resolve;
          }),
        ]);

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the canvas size to the size of the existing image
        canvas.width = existingImage.width;
        canvas.height = existingImage.height;

        // Calculate the position to center the scaled skewed image
        const centerX = (canvas.width - skewedImageElement.width) / 2;
        const centerY = (canvas.height - skewedImageElement.height) / 2;

        // Draw the existing image first
        ctx.drawImage(existingImage, 0, 0);

        // Draw the skewed image scaled and centered on top of the existing image
        ctx.drawImage(
          skewedImageElement,
          centerX,
          centerY,
          skewedImageElement.width,
          skewedImageElement.height
        );

        const combinedDataURL = canvas.toDataURL("image/png");
        return combinedDataURL;
      }
    },
    []
  );

  const combineImages = async (newSkewedImageData) => {
    let newImages = [];
    await asyncForEach(RELICS, async (relic) => { 
      if(relicRarity===relic.rarity){
        const data = await performCombination(newSkewedImageData, relic.image);
        const file = base64ToFile(data,`${contentId}_relic_artwork.png`,`image/png`)
        setRelicArtworkFile(file);
        newImages.push(data);
      }
    });
    setIsLoading(false);
    setCombinedImages(newImages);
  };

  useEffect(() => {
    const skewImage = () => {
      if (imageUrl) {
        setIsLoading(true);
        const canvas = document.createElement("canvas");
        canvas.getContext("2d");
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
          // Define cube parameters
          const cubeWidth = 2400; // Width of the cube
          const cubeHeight = 2400; // Height of the cube

          // Set canvas size to match the cube dimensions
          canvas.width = cubeWidth;
          canvas.height = cubeHeight;
          drawImageInPerspective(
            image,
            canvas,
            //coordinates of the 4 corners of the quadrilateral that the original rectangular image will be transformed onto:
            439,
            680, //top left corner: x, y
            439,
            1662, //bottom left corner: x, y - position it 50px more to the right than the top right corner
            1200,
            513, //top right corner: x, y - position it 50px below the top left corner
            1200,
            1829, //bottom right corner: x,y
            false, //don't flip the original image horizontally
            false //don't flip the original image vertically
          );

          // Set the skewed image as a data URL
          const newSkewedImageData = canvas.toDataURL("image/png");
          combineImages(newSkewedImageData);
        };
      }
    };
    if (imageUrl) {
      skewImage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl,relicRarity]);

  return (
    <>
      {/* <h2>Upload Your artwork</h2> */}
      {/* <input type="file" accept="image/*" onChange={handleImageUpload} /> */}
      {imageUrl && (
        <div className="text-center">
          <h3 className="mb-4">Artwork Image: </h3>
          <img
            className="drop-shadow"
            src={imageUrl}
            alt="Original"
            width="200"
          />
        </div>
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        combinedImages &&
        combinedImages.length > 0 && (
          <div className="text-center">
            {combinedImages.map((i, index) => (
              <div key={index}>
                <h3 className="mt-4">Cube Preview:</h3>
                <img className="drop-shadow" src={i} alt="Skewed" width="200" />
              </div>
            ))}
          </div>
        )
      )}
    </>
  );
}

export default SkewedCubeImage;
