import { useEffect, useRef } from "react";
import { toast } from "react-toastify";

export const useLocationDataCheck = (locationData, timeout = 10000) => {
  const timerRef = useRef(null);

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      if (!locationData?.id) {
        console.error("locationData", locationData);
        toast.error(
          "StreetTeam Account not found. Please try again or reach out to support@modernmusician.me"
        );
      }
    }, timeout);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [locationData, timeout]);

  useEffect(() => {
    if (locationData?.id) {
      // Cancel the timeout as soon as the location data is found
      clearTimeout(timerRef.current);
      // console.log("Location data is now available", locationData);
    }
  }, [locationData]);
};
