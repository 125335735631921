import { faArrowRight, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { generateGradient } from "../../../../utils/colors";

const HubButton = ({ children, text, points, type, ...rest }) => {
  let icon;

  let color = "gold";
  let textColor = "white";
  
  switch (type) {
    case "tokens":
      icon = faTrophy;
      break;
    case "enter":
      icon = faArrowRight
      color = "#FED93E";
      textColor = 'black';
      break;
    default:
      break;
  }

  return (
    <Button
      className="d-flex align-items-stretch w-100 p-0"
      style={{
        border: `${color} 1px solid`,
        background: `${generateGradient(color)}`,
      }}
      {...rest}
    >
      <div
        className="px-2"
        style={{
          flex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon icon={icon} style={{ color: textColor }} />
      </div>
      <div
        className="py-3"
        style={{
          flex: "6",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
        }}
      >
        <span className={`text-${textColor}`}>{children}</span>
      </div>
      {/* spacer div to align with point buttons */}
      <div
        style={{
          flex: "2",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <span style={{ color: "white" }}>+{points}</span>
        <small style={{ color: "white" }}>tokens</small> */}
      </div>
    </Button>
  );
};

export default HubButton;
