import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";


import MetaConnection from "./MetaConnection";
import MomentUpload from "./MomentUpload";
import AudienceSelect from "./AudienceSelect";
import CampaignPublish from "./CampaignPublish";
import { Container, Button, Row, Col } from "react-bootstrap";
import "../../App.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  fetchAdCampaignData,
  // fetchLocationData,
  saveAdCampaignData,
} from "../../hooks/apiHelper";
// import logo from "../../logo.png";
// import get from "lodash/get";
// import SuccessModal from "./SuccessModal";

const AdCreator = () => {
  const windowUrl = useLocation();
  const searchParams = new URLSearchParams(windowUrl.search);
  // retrieve location id and api key from local storage

  const localAdCampaignData = JSON.parse(
    localStorage.getItem("adCampaignData")
  );

  const locationId =
    searchParams.get("location_id") || localAdCampaignData?.locationId || "";
  const apiKey =
    searchParams.get("api_key") || localAdCampaignData?.apiKey || "";

  // console.log("storedAdCampaign", localAdCampaignData, locationId, apiKey);

  const [step, setStep] = useState(1);
  // const [loading, setLoading] = useState(false);
  // const [locationData, setLocationData] = useState(null);
  // const [showModal, setShowModal] = useState(false);

  const [adCampaignData, setAdCampaignData] = useState({
    locationId,
    apiKey,
    status: "draft", // Status of the campaign (draft, published, etc.)
    metaConnection: {
      isComplete: false,
      pageId: null, // Page ID of the Facebook page connected
      accessToken: null, // Access token acquired after successful authentication
    },
    momentUpload: {
      isComplete: false, // To track if the track upload is complete
      momentId: null, // Details of the track uploaded (like title, artist, etc.)
      audioFile: {
        originalFileName: null,
        filename: null,
        bucket: null,
        path: null,
        metadata: null,
      },
      artworkFile: {
        originalFileName: null,
        filename: null,
        bucket: null,
        path: null,
        metadata: null,
      },
      videoFile: {
        originalFileName: null,
        filename: null,
        bucket: null,
        path: null,
        metadata: null,
      },
    },
    audienceSelect: {
      isComplete: false, // To track if the audience selection is complete
      targetingAudiences: [], // Array of targeting audiences selected
      targetDemographics: {}, // Details of the target audience like age group, interests, etc.
      adBudget: null, // The budget set for the campaign
    },
    campaignPublish: {
      isComplete: false, // To track if the campaign has been published
      adCreative: {}, // Details of the ad creative like title, description, image, etc.
      businessId: null, // Business ID of the Facebook Business Manager
      publishStatus: false, // To track if the campaign has been published
    },
  });

  // Function to update adCampaignData based on the step and new data
  const updateAdCampaignData = (step, data) => {
    console.log("updateAdCampaignData", step, data);
    setAdCampaignData((prevState) => ({
      ...prevState,
      [step]: {
        ...prevState[step],
        ...data,
      },
    }));
  };

  function showSuccessModal(step) {
        // setShowModal(true);
    // alert(`Step ${step} completed successfully!`);
    // Here, instead of an alert, you would use your modal or animation library to show a success message.
  }

    // const closeSuccessModal = () => {
    //   setShowModal(false);
    // };

  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const nextStep = () => {
    setStep(step + 1);
    // setShowModal(false);
  };


  // useEffect(() => {
  //   console.log("useEffect localStorage", locationId, apiKey);
  //   let localAdCampaignData = JSON.parse(
  //     localStorage.getItem("adCampaignData")
  //   ); // Retrieve locationId from local storage
  //   console.log("localAdCampaignData", localAdCampaignData);
  //   async function storeLocalData() {
  //     try {
  //       console.log("setting local storage");
  //       localStorage.setItem(
  //         "adCampaignData",
  //         JSON.stringify({ locationId, apiKey })
  //       );
  //     } catch (error) {
  //       console.error("Error loading data:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  //   if (
  //     locationId &&
  //     apiKey &&
  //     (!localAdCampaignData?.locationId || !localAdCampaignData?.apiKey)
  //   ) {
  //     storeLocalData();
  //   } else {
  //   }
  // }, [locationId, apiKey]);

  useEffect(() => {
    // localStorage.setItem("adCampaignData", JSON.stringify(adCampaignData));
    console.log("useEffect loadData", locationId, apiKey);
    async function loadData() {
      try {
        // setLoading(true);

        // const fetchedLocationData = await fetchLocationData(locationId, apiKey);
        // setLocationData(fetchedLocationData);

        const fetchedAdCampaignData = await fetchAdCampaignData(
          locationId,
          apiKey
        );
        if (fetchedAdCampaignData?.length > 0) {
          setAdCampaignData(fetchedAdCampaignData);
          if (!fetchedAdCampaignData?.metaConnection?.pageData) {
            setStep(1);
          } else if (!fetchedAdCampaignData?.momentUpload?.isComplete) {
            setStep(2);
          } else if (!fetchedAdCampaignData?.audienceSelect?.isComplete) {
            setStep(3);
          } else if (fetchedAdCampaignData?.campaignPublish?.isComplete) {
            setStep(4);
          } else {
            setStep(5);
          }
        }
        // if (!fetchedAdCampaignData?.length > 0 && localAdCampaignData) {
        //   console.log("localAdCampaignData because no fetched", localAdCampaignData);
        //   setAdCampaignData(localAdCampaignData);
        // }
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        // setLoading(false);
      }
    }

    if (locationId && apiKey) {
      loadData();
    } else {
      window.alert("Please provide a location ID and API key.");
    }
  }, [locationId, apiKey]);

  useEffect(() => {
    prevState.current = adCampaignData;
  }, [adCampaignData]);
  useEffect(() => {
    let newLocal = JSON.parse(localStorage.getItem("adCampaignData"));
    console.log("Previous localStorage in adCampaignData:", newLocal);
    console.log("new storage in adCampaignData", adCampaignData);
    // Additional log to trace the previous state and the current state

    const hasStateChanged = (path) => {
      console.log("current state", adCampaignData);
      console.log("previous state", newLocal);
      // console.log("Current state at path:", get(adCampaignData, path)); // If we ever use this component, we need to replace lodash get with a custom function
      // console.log("Previous state at path:", get(newLocal, path));
      // return get(adCampaignData, path) !== get(newLocal, path);
    };

if (
  adCampaignData?.metaConnection?.isComplete &&
  hasStateChanged("metaConnection.isComplete")
) {
  showSuccessModal(1);
  // setStep(2);
} else if (
  adCampaignData?.momentUpload?.isComplete &&
  hasStateChanged("momentUpload.isComplete")
) {
  showSuccessModal(2);
  // setStep(3);
} else if (
  adCampaignData?.audienceSelect?.isComplete &&
  hasStateChanged("audienceSelect.isComplete")
) {
  showSuccessModal(3);
  // setStep(4);
} else if (
  adCampaignData?.campaignPublish?.isComplete &&
  hasStateChanged("campaignPublish.isComplete")
) {
  showSuccessModal(4);
  // setStep(5);
} else {
  // showSuccessModal(4);
  // setStep(5);
}


    if (
      !adCampaignData?.metaConnection?.accessToken &&
      newLocal?.metaConnection?.accessToken
    ) {
      console.log("setting adCampaignData from local storage");
      setAdCampaignData(newLocal);
    } else if (
      adCampaignData &&
      (!newLocal || JSON.stringify(newLocal) !== JSON.stringify(adCampaignData))
    ) {
      // console.log(adCampaignData === newLocal);
      // console.log("adCampaignData", adCampaignData);
      // console.log("newLocal", newLocal);
      // let result = compareObjects(adCampaignData, newLocal);
      // console.log(result);
      console.log("setting local storage from adCampaignData");
      localStorage.setItem("adCampaignData", JSON.stringify(adCampaignData));
    } else {
      console.log("no change");
    }

    prevState.current = adCampaignData;
  }, [adCampaignData]);

  const prevState = useRef();

  const handleSaveAdCampaign = async () => {
    const result = await saveAdCampaignData({
      adCampaignData: adCampaignData,
      locationId,
      apiKey,
    });

    if (result.status === "success") {
      setAdCampaignData(result.adCampaignData);
    }
  };

  return (
    <Container fluid className="px-5 py-3 mt-3">
      <div className="d-flex justify-content-start align-items-center">
        {/* <img src={logo} alt="StreetTeam Logo" className="logo-ad-creator m-1" /> */}
        <h2 className="mb-0">Launch StreetTeam Campaign</h2>
        {/* Additional Controls can be added here similar to your example page */}
      </div>
      <hr />

      {step === 1 && (
        <Row>
          <Col>
            <MetaConnection
              authData={adCampaignData?.metaConnection}
              updateAdCampaignData={updateAdCampaignData}
            />
          </Col>
        </Row>
      )}
      {step === 2 && (
        <Row>
          <Col>
            <MomentUpload
              adCampaignData={adCampaignData}
              updateAdCampaignData={updateAdCampaignData}
            />
          </Col>
        </Row>
      )}
      {step === 3 && (
        <Row>
          <Col>
            <AudienceSelect
              audienceData={adCampaignData}
              updateAdCampaignData={updateAdCampaignData}
            />
          </Col>
        </Row>
      )}
      {step === 4 && (
        <Row>
          <Col>
            <CampaignPublish
              adCampaignData={adCampaignData}
              updateAdCampaignData={updateAdCampaignData}
              handleSaveAdCampaign={handleSaveAdCampaign}
            />
          </Col>
        </Row>
      )}
      {adCampaignData?.metaConnection?.pageId && (
        <div className="mt-4 d-flex justify-content-center align-items-center">
          {step > 1 && (
            <Button
              variant="outline-dark w-100 mr-2 py-3"
              onClick={previousStep}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </Button>
          )}
          {step < 4 && (
            <Button variant="outline-dark w-100 py-3" onClick={nextStep}>
              Next <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          )}
        </div>
      )}
      {/* {showModal && <SuccessModal closeModal={nextStep} />} */}
    </Container>
  );
};

export default AdCreator;
