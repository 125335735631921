import React from 'react';
import { Form } from 'react-bootstrap';

const ErrorMessage = ({ children }) => (
  <Form.Text className="text-danger">
    {children}
  </Form.Text>
);

export default ErrorMessage;
