import axios from "axios";
import { API_BASE_URL } from "../../../../../utils/constants";

// create ARTIST_TIERS constant with { name: "Emerging Artist", value: 97 } and so on
const ARTIST_TIERS = [
  {
    name: "Emerging Artist",
    mrr: 97,
    ascension_milestone: 100,
    number: 1,
  },
  {
    name: "Rising Artist",
    mrr: 297,
    ascension_milestone: 1000,
    number: 2,
  },
  { name: "Gold Artist", mrr: 997, ascension_milestone: 10000, number: 3 },
  {
    name: "Platinum Artist",
    mrr: 2997,
    ascension_milestone: 100000,
    number: 4,
  },
  {
    name: "Diamond Artist",
    mrr: 9997,
    ascension_milestone: 1000000,
    number: 5,
  },
];

function parseMinimumNumberFromString(str) {
  // This regular expression finds the first group of digits,
  // possibly separated by commas, and preceded by a $ sign.
  const regex = /\$([0-9,]+)/;
  let match = str.match(regex);

  // Remove the $ sign and commas, then convert to a number
  return match ? parseInt(match[1].replace(/[$,]/g, ""), 10) : null;
}

function calculateArtistTier(
  resourceful,
  investable_budget,
  monthly_revenue_options
) {
  let monthly_revenue = parseMinimumNumberFromString(monthly_revenue_options);

  console.log(
    `Calculating artist tier with resourceful: ${resourceful}, investable_budget: ${investable_budget}, monthly_revenue: ${monthly_revenue}`
  );
  if (resourceful === "No") {
    // Logic based on investable budget
    console.log(
      "Resourceful is No, determining tier based on investable budget"
    );
    return assignTierBasedOnBudget(investable_budget);
  } else {
    // Logic based on monthly revenue with a minimum tier of Gold Artist
    console.log(
      "Resourceful is Yes, determining tier based on monthly revenue with a minimum tier of Gold Artist"
    );

    let minimumTier = ARTIST_TIERS[2]; // Gold Artist
    if (monthly_revenue < minimumTier.ascension_milestone) {
      console.log(
        `Monthly revenue is less than ${minimumTier.ascension_milestone}, assigning to ${minimumTier.name}`
      );
      return minimumTier;
    } else {
      console.log(
        `Monthly revenue is greater than ${minimumTier.ascension_milestone}, assigning to tier based on revenue`
      );
      return assignTierBasedOnRevenue(monthly_revenue);
    }
  }
}

function assignTierBasedOnBudget(budget) {
  console.log(`Assigning tier based on budget: ${budget}`);
  if (budget < 300) {
    console.log(`Assigned to:`, ARTIST_TIERS[0]);
    return ARTIST_TIERS[0]; // Emerging Artist
  }
  if (budget < 1000) {
    console.log(`Assigned to:`, ARTIST_TIERS[1]);
    return ARTIST_TIERS[1]; // Rising Artist
  }
  if (budget < 3000) {
    console.log(`Assigned to:`, ARTIST_TIERS[2]);
    return ARTIST_TIERS[2]; // Gold Artist
  }
  if (budget < 10000) {
    console.log(`Assigned to:`, ARTIST_TIERS[3]);
    return ARTIST_TIERS[3]; // Platinum Artist
  }
  console.log(`Assigned to:`, ARTIST_TIERS[4]);
  return ARTIST_TIERS[4]; // Diamond Artist
}

function assignTierBasedOnRevenue(revenue) {
  console.log(`Assigning tier based on revenue: ${revenue}`);
  if (revenue < 100) {
    console.log(`Assigned to:`, ARTIST_TIERS[0]);
    return ARTIST_TIERS[0]; // Emerging Artist
  }
  if (revenue < 1000) {
    console.log(`Assigned to:`, ARTIST_TIERS[1]);
    return ARTIST_TIERS[1]; // Rising Artist
  }
  if (revenue < 10000) {
    console.log(`Assigned to:`, ARTIST_TIERS[2]);
    return ARTIST_TIERS[2]; // Gold Artist
  }
  if (revenue < 100000) {
    console.log(`Assigned to:`, ARTIST_TIERS[3]);
    return ARTIST_TIERS[3]; // Platinum Artist
  }
  console.log(`Assigned to:`, ARTIST_TIERS[4]);
  return ARTIST_TIERS[4]; // Diamond Artist
}

// function calculateResponsivenessScore(
//   artist_description,
//   roadblocks,
//   annual_goals
// ) {
//   // Simple word count approach
//   let descriptionLength = wordCount(artist_description);
//   let roadblocksLength = wordCount(roadblocks);
//   let goalsLength = wordCount(annual_goals);

//   // Assume a scoring system where length corresponds to a score
//   let score = (descriptionLength + roadblocksLength + goalsLength) / 3; // Average word count

//   // Scale score to a range of 0 to 1 (or 0% to 100%)
//   return Math.min(score / 1000, 1); // Assuming 1000 words is the maximum for full score
// }

// function wordCount(text) {
//   return text.trim().split(/\s+/).length;
// }

// async function getQualitativeScore(application) {
//     // Placeholder function that would interface with the AI summarizer
//     // You would convert the summarizer's output into a numeric score
//     // let summarizerOutput = await generateTalentScoutSummary(application);
//     let summarizerOutput = 0;
//     let score = convertSummarizerOutputToScore(summarizerOutput);

//     // For now, let's return a dummy value
//     return score;
// }

// function convertSummarizerOutputToScore(output) {
//     // Logic to convert AI summarizer's output to a score
//     // You might need to determine how you will quantify the summarizer's output

//     // This is a placeholder, actual implementation will depend on the AI's output
//     return 0.5; // Example fixed score
// }

async function calculateOpportunityValue(application) {
  console.log(
    "Starting calculateOpportunityValue with application:",
    application
  );

  let opportunity = await axios.post(
    `${API_BASE_URL}/api/opportunities/calculate-value`,
    application
  );

  return opportunity.data;

  // let tier = calculateArtistTier(
  //   application.resourceful,
  //   application.investable_budget,
  //   application.monthly_revenue
  // );

  // let totalDealValue = tier.mrr * 10; // Constant multiplier for deal value
  // console.log(`Total Deal Value calculated: ${totalDealValue}`);

  // // let responsivenessScore = calculateResponsivenessScore(
  // //     application.artist_description,
  // //     application.roadblocks,
  // //     application.annual_goals
  // // );
  // // console.log(`Responsiveness Score calculated: ${responsivenessScore}`);

  // // let qualitativeScore = await getQualitativeScore(application); // Needs to be defined based on AI summarizer output
  // // console.log(`Qualitative Score calculated: ${qualitativeScore}`);

  // let predictedConversionRate = calculateConversionRate(application);
  // console.log(
  //   `Predicted Conversion Rate calculated: ${predictedConversionRate}`
  // );

  // let opportunityValue = totalDealValue * predictedConversionRate;
  // console.log(`Opportunity Value calculated: ${opportunityValue}`);

  // opportunityValue = Math.round(opportunityValue);

  // let result = {
  //   tier,
  //   total_deal_value: totalDealValue,
  //   predicted_conversion_rate: predictedConversionRate,
  //   value: opportunityValue,
  // };

  // return result;
}

// HANDLING ALL OF THIS ON BACKEND NOW

// function calculateConversionRate(formData) {
//   console.log("Starting conversion rate calculation", formData);

//   // Define weights for each factor
//   const weights = {
//     responsiveness: 0.6,
//     timeCommitment: 0.1,
//     readiness: 0.3,
//   };

//   console.log("Weights for factors:", weights);

//   // Score each factor
//   const responsivenessScore = calculateResponsivenessScore(
//     formData.artist_description,
//     formData.roadblocks,
//     formData.annual_goals
//   );
//   const timeCommitmentScore = scoreInvestableTime(formData.investable_time);
//   const readinessScore = scoreStartDate(formData.start_date);
//   // const investmentWillingnessScore = scoreInvestmentWillingness(formData.investable_budget);

//   console.log(`Responsiveness Score: ${responsivenessScore}`);
//   console.log(`Time Commitment Score: ${timeCommitmentScore}`);
//   console.log(`Readiness Score: ${readinessScore}`);
//   // console.log(`Investment Willingness Score: ${investmentWillingnessScore}`);

//   const scores = {
//     responsivenessScore: responsivenessScore * weights.responsiveness,
//     timeCommitmentScore: timeCommitmentScore * weights.timeCommitment,
//     readinessScore: readinessScore * weights.readiness,
//     // investmentWillingnessScore: investmentWillingnessScore * weights.investmentWillingness,
//   };

//   console.log("Scores after applying weights:", scores);

//   // Combine scores
//   const totalScore = Object.values(scores).reduce(
//     (acc, score) => acc + score,
//     0
//   );

//   console.log(`Total Score before normalization: ${totalScore}`);

//   // Normalize score to get a percentage
//   let conversionRate =
//     totalScore /
//     Object.values(weights).reduce((acc, weight) => acc + weight, 0);

//   console.log(`Normalized Conversion Rate: ${conversionRate}`);

//   // Adjust for coachable
//   if (formData.coachable === "No") {
//     conversionRate = 0;
//     console.log("coachable is 'No', setting conversion rate to 0");
//   }

//   // round to 2 decimal places
//   conversionRate = Math.round(conversionRate * 100) / 100;

//   console.log(`Final Conversion Rate: ${conversionRate}`);
//   return conversionRate;
// }

// // Define scoring functions for each factor
// function scoreFanbaseSize(fanbaseSize) {
//     const options = {
//         "Under 1,000": 0.1,
//         "1,000 to 10,000": 0.3,
//         "10,000 to 100,000": 0.5,
//         "100,000 to 1,000,000": 0.7,
//         "Above 1,000,000": 1,
//     };
//     return options[fanbaseSize] || 0;
// }

// function scoreMonthlyRevenue(monthlyRevenue) {
//     const options = {
//         "Under $100": 0.1,
//         "$100 to $1,000": 0.3,
//         "$1,000 to $10,000": 0.5,
//         "$10,000 to $100,000": 0.7,
//         "Above $100,000": 1,
//     };
//     return options[monthlyRevenue] || 0;
// }

// function scoreInvestableTime(investableTime) {
//   const options = {
//     "Less than 1 hour": 0.1,
//     "1 to 2 hours": 0.3,
//     "2 to 5 hours": 0.5,
//     "5 to 10 hours": 0.7,
//     "Above 10 hours": 1,
//   };
//   return options[investableTime] || 0;
// }

// function scoreStartDate(startDate) {
//   // The sooner the start date, the higher the score
//   const currentDate = new Date();
//   const start = new Date(startDate);
//   const diff = start - currentDate;
//   const daysUntilStart = diff / (1000 * 60 * 60 * 24);
//   if (daysUntilStart < 2) return 1; // Starting within a month
//   if (daysUntilStart < 7) return 65; // Starting within a month
//   if (daysUntilStart < 30) return 0.35; // Starting within three months
//   if (daysUntilStart < 90) return 0.2; // Starting within three months
//   return 0.05; // Starting later than three months
// }

// function scoreInvestmentWillingness(investableBudget) {
//     if (investableBudget >= 2000) return 1;
//     if (investableBudget >= 1000) return 0.5;
//     return 0.1;
// }

// const generateTalentScoutSummary = async (formData) => {
//   // fetch with axios from our api with the formData in the body to endpoint /ai/talent-scout-summary

//   const response = await axios.post(
//     `${API_BASE_URL}/ai/talent-scout-summary`,
//     formData
//   );

//   console.log("response.data:", response.data);

//   return response.data;
// };

// export multiple functions like this
export {
  // generateTalentScoutSummary,
  calculateArtistTier,
  // calculateConversionRate,
  calculateOpportunityValue,
};

// export default calculateOpportunityValue;
