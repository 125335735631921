import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Row } from "react-bootstrap";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import { useContext, useState } from "react";
import LocationContext from "../../../../context/Locations/LocationContext";

// ... (your other imports and code)

// Helper function to get the current count based on category
export function getMetricCurrentCount(metrics_count, category, formatType) {
  let rawValue;
  switch (category) {
    case "streams":
      rawValue = metrics_count?.unique?.content ?? 0;
      break;
    case "fans":
      rawValue = metrics_count?.unique?.registration ?? 0;
      break;
    case "sales":
      rawValue = metrics_count?.value?.total ?? 0;
      break;
    default:
      rawValue = 0;
  }

  let formattedValue = formatMetric(rawValue, formatType);

  return { rawValue, formattedValue };
}

export function formatMetric(value, formatType) {
  switch (formatType) {
    case "integer":
      return value.toLocaleString(); // Formats to integer with commas
    case "currency":
      return `$${value.toLocaleString()}`; // Formats to currency
    default:
      return value;
  }
}

function Milestones({ milestoneCards, milestoneData, ...otherProps }) {
  const [copied, setCopied] = useState({});

  let {locationData} = useContext(LocationContext);

  // // Helper function to get the highest achieved milestone
  // function highestAchievedMilestone(milestones) {
  //   const achievedMilestones = milestones.filter(
  //     (milestone) => milestone.achieved
  //   );
  //   return achievedMilestones.length
  //     ? achievedMilestones[achievedMilestones.length - 1].name
  //     : "None";
  // }

  function handleButtonClick(actionValue, funnelLink, currentCount, index) {
    if (currentCount) {
      // Copy funnelLink to clipboard
      navigator.clipboard.writeText(
        funnelLink ?? "error: missing link - contact support@modernmusician.me"
      );
      setCopied({ ...copied, [index]: true });
      setTimeout(() => {
        setCopied({ ...copied, [index]: false });
      }, 1000); // Clear after 3 seconds
    } else {
      // Execute existing logic
      if (otherProps.setActive) {
        otherProps.setActive(actionValue);
      }
    }
  }

  // Update milestoneCards
  milestoneCards = milestoneCards.map((card) => {
    let funnelLinks = {
      streams: locationData?.funnel_links?.streaming,
      fans: locationData?.funnel_links?.community,
      sales: locationData?.funnel_links?.starter_pack,
    };

    const metricCount = getMetricCurrentCount(
      milestoneData.metrics_count,
      card.category,
      card.formatType
    );

    let currentCount = metricCount?.rawValue;
    let formattedCount = metricCount?.formattedValue;

    // const highestMilestone = highestAchievedMilestone(
    //   milestoneData.milestones.unique.content
    // ); // Assuming 'streams' maps to 'content'

    return {
      ...card,
      title: `${formattedCount}`,
      description: `${card.title}`,
      // actionValue: card.actionValue,
      funnelLink: funnelLinks[card.category],
      currentCount,
    };
  });

  return (
    <Row className="mt-4 text-center">
      {milestoneCards.map((card, index) => (
        <Col md={card.col} key={index}>
          <Card className="mb-4">
            <Card.Header>
              <FontAwesomeIcon icon={card.icon} size="2x" />
            </Card.Header>
            <Card.Body>
              <Card.Title className="fs-1">{card.title}</Card.Title>
              <Card.Text className="fs-6 fw-bold mt-0">
                {card.description}
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                className="bg-gradient"
                onClick={() =>
                  handleButtonClick(
                    card.actionValue,
                    card.funnelLink,
                    card.currentCount,
                    index
                  )
                }
                variant={
                  card?.variant === "launch" && card.currentCount > 0
                    ? "success"
                    : "primary"
                }
                value={card.actionValue}
              >
                {card?.variant === "launch" && card.currentCount > 0 && (
                  <FontAwesomeIcon className="me-2" icon={faCheck} />
                )}

                {card.currentCount ? (
                  <>
                    <FontAwesomeIcon className="me-2" icon={faCopy} />
                    {copied[index] ? `Copied!` : `Copy Link`}
                  </>
                ) : (
                  card.actionText
                )}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default Milestones;
