import axios, { AxiosProgressEvent } from "axios";
import { getDomain } from "../hooks/apiHelper";
import { renameFile } from ".";

export const uploadFile = async (fileData:File,locationId?: string, fileName?: string, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>{
    // const apiUrl = backendUrl;
    const apiUrl = getDomain();
    let fileToUpload = fileData;
    if(fileName){
      fileToUpload = renameFile(fileData,fileName)
    }
    const finalFileName = fileToUpload?.name;
    const fileType = fileToUpload?.type;
    //hit the backend API to get the signed URL
    // const signedUrlJSON = await fetch(
      // `${apiUrl}/signed-upload-url?fileName=${fileName}&fileType=${fileType}&location_id=${locationId ? locationId : 'new_media'}`,
      // {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
    //   }
    // );
    const signedUrlJSON = await fetch(`${apiUrl}/tracks/upload-url?fileName=${finalFileName}&fileType=${fileType}&location_id=${locationId ? locationId : 'new_media'}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    const signedUrlData = await signedUrlJSON.json();
    const signedUrl = signedUrlData?.data;
    const result = await axios.request({
      method: "PUT", 
      url: signedUrl, 
      data: fileToUpload, 
      onUploadProgress
    });
    const publicUrl=signedUrl?.split('?')[0]
    let response = {...result, publicUrl}
    return response;
  }