export const FUNNEL_DEFINITION = [
  {
    title: "Amount Spent",
    field: "amountSpent",
    type: "marketing",
  },
  {
    title: "Cost Per Registrant",
    field: "costPerRegistrant",
    type: "calculation",
    numerator: "Amount Spent",
    denominator: "Masterclass Registered",
  },
  {
    title: "Client Rate",
    field: "clientRate",
    type: "calculation",
    numerator: "Program Purchased",
    denominator: "Masterclass Registered",
  },
  {
    title: "Masterclass Clicked",
    field: "masterclassClicked",
    type: "action",
  },
  {
    title: "Registration Rate",
    field: "registrationRate",
    type: "calculation",
    numerator: "Masterclass Registered",
    denominator: "Masterclass Clicked",
  },
  {
    title: "Masterclass Registered",
    field: "masterclassRegistered",
    type: "action",
  },
  {
    title: "Attendance Rate",
    field: "attendanceRate",
    type: "calculation",
    numerator: "Masterclass Attended",
    denominator: "Masterclass Registered",
  },
  {
    title: "Masterclass Attended",
    field: "masterclassAttended",
    type: "action",
  },
  {
    title: "Application Rate",
    field: "applicationRate",
    type: "calculation",
    numerator: "Application Submitted",
    denominator: "Masterclass Attended",
  },
  {
    title: "Application Submitted",
    field: "applicationSubmitted",
    type: "action",
  },
  {
    title: "Activation Rate",
    field: "applicationActivatedRate",
    type: "calculation",
    numerator: "Application Activated",
    denominator: "Application Submitted",
  },
  {
    title: "Application Activated",
    field: "applicationActivated",
    type: "action",
  },
  {
    title: "Qualification Rate",
    field: "applicationQualifiedRate",
    type: "calculation",
    numerator: "Application Qualified",
    denominator: "Application Activated",
  },
  {
    title: "Application Qualified",
    field: "applicationQualified",
    type: "action",
  },
  {
    title: "Breakthrough Booked Rate",
    field: "breakthroughBookedRate",
    type: "calculation",
    numerator: "Breakthrough Booked",
    denominator: "Application Qualified",
  },
  {
    title: "Breakthrough Booked",
    field: "breakthroughBooked",
    type: "action",
  },
  {
    title: "Breakthrough Completed Rate",
    field: "breakthroughCompletedRate",
    type: "calculation",
    numerator: "Breakthrough Completed",
    denominator: "Breakthrough Booked",
  },
  {
    title: "Breakthrough Completed",
    field: "breakthroughCompleted",
    type: "action",
  },
  {
    title: "Momentum Booked Rate",
    field: "MomentumBookedRate",
    type: "calculation",
    numerator: "Momentum Booked",
    denominator: "Breakthrough Completed",
  },
  {
    title: "Momentum Booked",
    field: "MomentumBooked",
    type: "action",
  },
  {
    title: "Momentum Completed Rate",
    field: "MomentumCompletedRate",
    type: "calculation",
    numerator: "Momentum Completed",
    denominator: "Momentum Booked",
  },
  {
    title: "Momentum Completed",
    field: "MomentumCompleted",
    type: "action",
  },
  {
    title: "Program Purchased Rate",
    field: "programPurchasedRate",
    type: "calculation",
    numerator: "Program Purchased",
    denominator: "Momentum Completed",
  },
  {
    title: "Program Purchased",
    field: "programPurchased",
    type: "action",
  },
  {
    title: "Trial Rate",
    field: "trialRate",
    type: "calculation",
    numerator: "StreetTeam Trial Started",
    denominator: "Masterclass Registered",
  },
  {
    title: "StreetTeam Trial Started",
    field: "streetTeamTrialStarted",
    type: "action",
  },
  {
    title: "Subscription Rate",
    field: "subscriptionRate",
    type: "calculation",
    numerator: "StreetTeam Subscribed",
    denominator: "StreetTeam Trial Started",
  },
  {
    title: "StreetTeam Subscribed",
    field: "streetTeamSubscribed",
    type: "action",
  },
  {
    title: "Retention Rate",
    field: "retentionRate",
    type: "calculation",
    numerator: "StreetTeam Retained",
    denominator: "StreetTeam Subscribed",
  },
  {
    title: "StreetTeam Retained",
    field: "streetTeamRetained",
    type: "action",
  },
  {
    title: "Mastermind Purchased",
    field: "mastermindPurchased",
    type: "action",
  },
  {
    title: "Mastermind Retained",
    field: "mastermindRetained",
    type: "action",
  },
];
