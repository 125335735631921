// api.js

import axios from "axios";
import { getDomain } from "../../hooks/apiHelper";

const DOMAIN = getDomain();

export async function updateLocationData(locationId, updatedData) {
  const response = await axios.put(
    `${DOMAIN}/data/locations/${locationId}`,
    updatedData,
    { headers: { "Content-Type": "application/json" } }
  );
  return response.data;
}
export async function sendArtistAIAvatarMessage(messageData) {
  const response = await axios.post(
    `${DOMAIN}/test/artist-ai-engage`,
    messageData,
    { headers: { "Content-Type": "application/json" }, timeout: 28500 }
  );

  return response.data;
}
