import axios from "axios";
import { API_BASE_URL } from "../../../utils/constants";

// export const fetchContactMapData = async (location_id) => {
//   try {
//     console.log(`fetching contact map data for location_id: ${location_id}`);
//     const response = await axios.get(
//       `${API_BASE_URL}/contacts/map?location_id=${location_id}`
//     );
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     return null;
//   }
// };


export const fetchContactMapData = async (location_id, lastKey, limit = 50) => {
  try {
    let response;
    console.log(`fetching contact map data for location_id: ${location_id}`);
    if (!lastKey) {
      response = await axios.post(`${API_BASE_URL}/contacts/map?location_id=${location_id}&limit=${limit}`);
      console.log('Initial response:', response.data);
      console.log('Fetching next set of items...');
    }
    else {
      //       response = await axios.get(`${API_BASE_URL}/contacts/map?location_id=${location_id}&limit=${limit}&start=${response.data.lastKey}`);
      response = await axios.post(`${API_BASE_URL}/contacts/map?location_id=${location_id}&limit=${limit}`, { start: lastKey });
      // response = await axios({method: 'get',
      // url: `${API_BASE_URL}/contacts/map?location_id=${location_id}&limit=${limit}&start=${response.data.lastKey}`,
      // data: {start: response.data.lastKey}});
      console.log('Next response:', response);
    }

    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};


// export const fetchContactMapData = async (location_id) => {
//   try {
//     console.log(`fetching contact map data for location_id: ${location_id}`);
//     return await debouncedFetchData(location_id, 1000); // Adjust the delay (in milliseconds) as needed
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     return null;
//   }
// };

// const debouncedFetchData = async (location_id, delay) => {
//   return new Promise((resolve, reject) => {
//     let timeoutId;

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/contacts/map?location_id=${location_id}`
//         );
//         resolve(response.data);
//       } catch (error) {
//         reject(error);
//       }
//     };

//     if (timeoutId) {
//       clearTimeout(timeoutId);
//     }

//     timeoutId = setTimeout(fetchData, delay);
//   });
// };



// export const addTagsToContacts = async ({ location, contacts, tags }) => {
//   try {
//     console.log(`Adding tags to contacts for location: ${location.id}`);

//     // Construct the payload
//     const payload = {
//       location,
//       contacts,
//       tags,
//     };

//     // Perform the POST request to the endpoint
//     const response = await axios.post(
//       `${API_BASE_URL}/hooks/contacts/add-tags`,
//       payload
//     );

//     // Log and return the response data
//     console.log("Tags successfully added to contacts:", response.data);
//     return response.data;
//   } catch (error) {
//     console.error("Error adding tags to contacts:", error);
//     return null;
//   }
// };



const RATE_LIMIT_DELAY = 100; // 10 seconds in milliseconds


export const addTagsToContacts = async ({ location, contacts, tags }) => {
  try {
    console.log(`Adding tags to contacts for location: ${location.id}`);
    // Iterate over contacts and add tags to each one
    for (const contact of contacts) {
      // Construct the payload for the current contact
      const payload = {
        location,
        contacts: [contact], // Send only one contact at a time
        tags,
      };

      // Perform the POST request to the endpoint
      const response = await axios.post(
        `${API_BASE_URL}/hooks/contacts/add-tags`,
        payload
      );

      // Log and return the response data
      console.log("Tags successfully added to contacts:", response.data);

      // Introduce a delay before the next API request
      await sleep(RATE_LIMIT_DELAY);
    }

    return "Tags added to all contacts successfully";
  } catch (error) {
    console.error("Error adding tags to contacts:", error);
    return null;
  }
};

// Function to create a delay using setTimeout
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}