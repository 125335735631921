import axios from "axios";
import { getDomain } from "../hooks/apiHelper";

let API_DOMAIN = getDomain();

const verifySession = async () => {
  try {
    const response = await axios.get(`${API_DOMAIN}/verify-session`, {
      withCredentials: true, // Ensures cookies are sent along with the request
    });

    if (response.status === 200) {
      // Assuming the backend sends a response in the format { auth: true } or { auth: false }
      const isAuth = response.data.auth;
    //   console.log("Authentication status:", isAuth);

      // Handle authentication status
      if (isAuth) {
        return true;
      } else {
        return false;
      }
    } else {
      console.error("Unexpected response status:", response.status);
      // Handle unexpected response status
    }
  } catch (error) {
    console.error("Error during session verification:", error);
    // Handle error case
  }
};

export { verifySession };
