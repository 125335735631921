import React, { useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { Card, Form, ProgressBar, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import { API_BASE_URL } from "../../utils/constants";

export const ToggleFileDisplay = ({ name, file, accept, handleFileChange, clearFile }) => {
  return file?.originalFileName ? (
    <div
      className="d-flex w-100 justify-content-between align-items-center gap-2 rounded-2"
      style={{ backgroundColor: "#f8f9fa", padding: "10px 15px" }}
    >
      <span className="me-2">{file.originalFileName}</span>
      <FontAwesomeIcon
        size="lg"
        icon={faTimes}
        onClick={() => clearFile(name)}
      />
    </div>
  ) : (
    <Form.Control type="file" accept={accept} name={name} onChange={handleFileChange} />
  );
};

const MomentUpload = ({ adCampaignData, updateAdCampaignData }) => {
  let momentUpload = adCampaignData?.momentUpload;
  console.log(momentUpload, "momentData");
  const [formData, setFormData] = useState({
    title: momentUpload?.audioFile?.metadata?.title || "",
    audioFile: momentUpload?.audioFile || null,
    artworkFile: momentUpload?.artworkFile || null,
    videoFile: momentUpload?.videoFile || null,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleModalClose = () => {
    setLoading(false);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });
  };

  const clearFile = (name) => {
    setFormData({ ...formData, [name]: null });
  };

  const chunkFileIntoPieces = (file, CHUNK_SIZE) => {
    const arrayOfFileChunks = [];
    if (file.size > CHUNK_SIZE) {
      for (let i = 0; i < Math.ceil(file.size / CHUNK_SIZE); i++) {
        const start = i * CHUNK_SIZE;
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        arrayOfFileChunks.push({ name: file.name, value: chunk });
      }
    } else {
      arrayOfFileChunks.push({ name: file.name, value: file });
    }
    return arrayOfFileChunks;
  };
  const uploadChunksOfFile = async (fileChunks, fileType, CHUNK_SIZE) => {
    try {
      if (fileChunks[0].name) {
        for (let i = 0; i < fileChunks.length; i++) {
          setUploadProgress(((i + 1) / fileChunks.length) * 100);
          console.log(uploadProgress);

          const uploadData = new FormData();
          uploadData.append("title", formData.title);
          uploadData.append("location_id", adCampaignData?.locationId);
          uploadData.append("chunk_index", i);
          uploadData.append("chunk_count", fileChunks.length);
          uploadData.append("file_name", fileChunks[i].name);
          uploadData.append(
            fileType,
            fileChunks[i].value,
            `${fileChunks[i].name}-chunk-${i}`
          );

          console.log("Form data prepared", { ...formData });

          const response = await axios.post(
            `${API_BASE_URL}/meta/moments/upload/chunk`,
            uploadData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          console.log("Response received", response.status, response.data);
        }
      }

      return true; // Return true if all chunks uploaded successfully
    } catch (error) {
      console.error("Error during file chunk upload:", error);
      setMessage("Error during music upload.");

      return false; // Return false if any chunk fails to upload
    }
  };

  const combineChunks = async (fileName, chunkCount) => {
    try {
      console.log("Initiating chunks combination");

      const response = await axios.post(
        `${API_BASE_URL}/meta/moments/combine`,
        {
          title: formData.title,
          location_id: adCampaignData?.locationId,
          file_name: fileName,
          chunk_count: chunkCount,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Combine response received", response.status, response.data);
      return response.data.object;
    } catch (error) {
      console.error("Error during chunks combination:", error);
      setMessage("Error during chunks combination.");
      return error.response.data;
    }
  };

  const handleUploadClick = async () => {
    console.log("Upload initiated");

    if (!formData.audioFile) {
      console.error("No file selected");
      setMessage("No audio file selected. Please select a file to upload.");
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      const CHUNK_SIZE = 3 * 1024 * 1024; // 1MB chunks, adjust as necessary

      console.log("Starting to chunk files");
      const audioFileChunks = chunkFileIntoPieces(
        formData.audioFile,
        CHUNK_SIZE
      );
      const artworkFileChunks = formData.artworkFile
        ? chunkFileIntoPieces(formData.artworkFile, CHUNK_SIZE)
        : null;
      const videoFileChunks = formData.videoFile
        ? chunkFileIntoPieces(formData.videoFile, CHUNK_SIZE)
        : null;
      console.log("File chunking completed");

      console.log("Initiating audio file upload");
      const audioSuccess = audioFileChunks
        ? await uploadChunksOfFile(audioFileChunks, "audioFile", CHUNK_SIZE)
        : true;
      console.log("Audio file upload status:", audioSuccess);

      console.log("Initiating artwork file upload");

      console.log(artworkFileChunks);
      const artworkSuccess = artworkFileChunks
        ? await uploadChunksOfFile(artworkFileChunks, "artworkFile", CHUNK_SIZE)
        : true;
      console.log("Artwork file upload status:", artworkSuccess);

      console.log("Initiating video file upload");
      const videoSuccess = videoFileChunks
        ? await uploadChunksOfFile(videoFileChunks, "videoFile", CHUNK_SIZE)
        : true;
      console.log("Video file upload status:", videoSuccess);

      if (audioSuccess && artworkSuccess && videoSuccess) {
        console.log(
          "All files uploaded successfully, initiating chunks combination"
        );

        const combineAudioData = !formData.audioFile.originalFileName
          ? await combineChunks(formData.audioFile.name, audioFileChunks.length)
          : null;
        const combineArtworkData =
          formData.artworkFile?.name && !formData.artworkFile.originalFileName
            ? await combineChunks(
                formData.artworkFile.name,
                artworkFileChunks.length
              )
            : null;
        const combineVideoData =
          formData.videoFile?.name && !formData.videoFile.originalFileName
            ? await combineChunks(
                formData.videoFile.name,
                videoFileChunks.length
              )
            : null;

        console.log("combined files", combineAudioData, combineArtworkData, combineVideoData);
        
        if (combineAudioData || combineArtworkData || combineVideoData) {
          console.log("Chunks combination completed");

          updateAdCampaignData("momentUpload", {
            isComplete: true,
            momentId: combineAudioData?.metadata?.moment_id ?? formData?.momentId ?? null,
            audioFile: combineAudioData ? combineAudioData : formData?.audioFile ?? null,
            artworkFile: combineArtworkData ? combineArtworkData : formData?.artworkFile ?? null,
            videoFile: combineVideoData ? combineVideoData : formData?.videoFile ?? null,
          });

          setMessage(
            `Successfully uploaded: ${formData?.title}`
          );
        } else {
          console.error("An error occurred during chunks combination");
          setMessage("An error occurred during chunks combination");
        }
      } else {
        console.error("An error occurred during upload");
        setMessage("An error occurred during upload");
      }
    } catch (error) { console.error(error) }
    finally {
      console.log("Upload process completed");
      setLoading(false);
    }
  };

  return (
    <Card>
      <div className="m-4">
        <h2>Submit Your Moment</h2>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Enter the title"
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Audio File</Form.Label>
            <ToggleFileDisplay
              name="audioFile"
              file={formData.audioFile}
              handleFileChange={handleFileChange}
              clearFile={clearFile}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Artwork File (optional)</Form.Label>
            <ToggleFileDisplay
              name="artworkFile"
              file={formData.artworkFile}
              handleFileChange={handleFileChange}
              clearFile={clearFile}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Video File (optional)</Form.Label>
            <ToggleFileDisplay
              name="videoFile"
              file={formData.videoFile}
              handleFileChange={handleFileChange}
              clearFile={clearFile}
            />
          </Form.Group>
          <Button
            variant="primary"
            onClick={handleUploadClick}
            disabled={loading}
          >
            <FontAwesomeIcon icon={faUpload} className="me-2" />
            {loading ? "Uploading..." : "Upload"}
          </Button>
        </Form>
        {message && <div className="mt-3">{message}</div>}
        {uploadProgress > 0 && uploadProgress < 100 && (
          <Modal show={loading} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>File Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {uploadProgress > 0 && uploadProgress < 100 && (
                  <div>
                    <ProgressBar
                      animated
                      variant="secondary"
                      now={uploadProgress}
                      label={`${Math.round(uploadProgress)}%`}
                    />
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </Card>
  );
};

export default MomentUpload;
