import React, { useState, useEffect, useMemo } from "react";
import ContactContext from "./ContactContext"; // Ensure you have this context created
import { useLocation, useNavigate } from "react-router-dom";
import {
  fetchContactData,
  fetchContactDataByEmail,
} from "../../hooks/apiHelper";
import { MM_CRM_LOCATION_ID } from "../../integrations/Highlevel/constants";

// Function to extract the location ID from the URL path
export const getLocationIdFromPath = (path) => {
  const segments = path.split("/");
  const index = segments.findIndex((segment) => segment === "location");
  return index !== -1 && segments.length > index + 1
    ? segments[index + 1]
    : null;
};

function ContactProvider({ children }) {
  const [contactData, setContactData] = useState({});
  const [error, setError] = useState(null);
  const [fetchingCompleted, setFetchingCompleted] = useState(false); // New state variable

  const location = useLocation();
  const navigate = useNavigate();

  // Extract the location ID
  const pathLocationId = getLocationIdFromPath(window.location.pathname);
  // console.log(`pathLocationId is`, pathLocationId);

  // Parse the URL to get the CRM ID
  const parsedData = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const localStorageData = JSON.parse(localStorage.getItem("mm_ud"));

    let params = {
      crmIdFromURL: queryParams.get("crm_id"),
      emailFromURL: queryParams.get("email"),
      locationIdFromURL: queryParams.get("location_id") ?? pathLocationId,
      localStorageData,
    };

    console.log(`params are`, params);

    if (!params.locationIdFromURL) {
      params.locationIdFromURL = MM_CRM_LOCATION_ID;
    }

    // console.log(`params are`, params);

    return params;
  }, [location.search, pathLocationId]);

  useEffect(() => {
    const fetchData = async () => {
      const { crmIdFromURL, emailFromURL, localStorageData, locationIdFromURL } = parsedData;

      //   console.log(`crmIdFromURL is`, crmIdFromURL);
      try {
        // Fetch contact data if crm_id is present
        let fetchedContactData;
        if (crmIdFromURL) {
          fetchedContactData = await fetchContactData(
            crmIdFromURL,
            locationIdFromURL
          );
        } else if (!crmIdFromURL && emailFromURL) {
          fetchedContactData = await fetchContactDataByEmail(
            emailFromURL,
            locationIdFromURL
          );
        }

        if (fetchedContactData) {
          setContactData(fetchedContactData);
          localStorage.setItem("mm_ud", JSON.stringify(fetchedContactData));

          // Remove URL parameters
          const newQueryParams = new URLSearchParams(location.search);
          newQueryParams.delete("crm_id");
          newQueryParams.delete("email");
          navigate(location.pathname + "?" + newQueryParams.toString(), {
            replace: true,
          });
          setFetchingCompleted(true); // Set to true once fetching is complete
        } else if (!crmIdFromURL && !emailFromURL && localStorageData) {
          setContactData(localStorageData);
          setFetchingCompleted(true); // Set to true once fetching is complete
          //   console.log(`localStorageData is`, localStorageData);
        } else {
          setError("Failed to fetch contact data.");
          setFetchingCompleted(true); // Set to true once fetching is complete
        }

        // ... other logic if needed
      } catch (err) {
        setError("An error occurred while fetching data: " + err.message);
        setFetchingCompleted(true); // Set to true once fetching is complete
      }
    };

    fetchData();
  }, [parsedData, location.search, location.pathname, navigate]);

  //   console.log(`fetchingCompleted is`, fetchingCompleted);

  return (
    <ContactContext.Provider
      value={[contactData, setContactData, fetchingCompleted, error]}
    >
      {children}
    </ContactContext.Provider>
  );
}

export default ContactProvider;
