import { useState, useRef, useEffect } from "react";
import { Form, Button, ListGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faSync,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "../../App.scss";
import Linkify from "react-linkify";
import { sendArtistAIAvatarMessage } from "../../api/streetTeam/locations";

const ChatPreview = ({
  location_id,
  selectedCampaign,
  steps,
  setSteps,
  locationData,
}) => {
  const [isTrashIconVisible, setIsTrashIconVisible] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [followUpLoading, setFollowUpLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [conversationData, setConversationData] = useState({
    token_usage: {
      total_tokens: 0,
      completion_tokens: 0,
      prompt_tokens: 0,
      tokens_cost: 0,
      user_cost: 0,
      message_cost: 0,
    },
    messages: [],
  });

  const messagesEndRef = useRef(null);

  function roundToFirstNonZero(num) {
    // handle zero case
    if (num === 0) return 0;

    const factor = Math.floor(Math.log10(Math.abs(num)));
    return Number(
      (Math.round(num * Math.pow(10, -factor)) * Math.pow(10, factor)).toFixed(
        Math.max(0, -factor)
      )
    );
  }

  const Typing = () => (
    <div className="typing">
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </div>
  );

  const handleRemoveMessage = (index) => {
    const updatedChatHistory = chatHistory;
    updatedChatHistory.splice(index, 1);
    setChatHistory(updatedChatHistory);
  };

  async function sendMessage(isFollowUp) {
    console.log("sendMessage function initiated", { isFollowUp, message });

    try {
      if (!message && !isFollowUp) {
        return;
      }

      let messageData = null;
      console.log("Processing a new message");

      let tags = steps
        .filter((step) => step.completed === true)
        .map((step) => `action - ${step.action}`)
        .join(", ");

      let formattedChatHistory = chatHistory.map((msg) => ({
        role: msg.role,
        content: msg.message,
      }));

      let userMessage = null;
      if (isFollowUp) {
        setFollowUpLoading(true);

        userMessage = {
          role: "user",
          message: "",
          id: new Date().getTime().toString(),
        };
        // setChatHistory([...chatHistory, userMessage]);

        messageData = {
          location_id: location_id, // assuming you have the location_id
          first_name: locationData?.owner?.first_name, // replace with actual user first name
          message: null,
          tags: tags,
          campaign: selectedCampaign,
          conversation: {
            messages: formattedChatHistory,
            token_usage: conversationData.token_usage, // Add token_usage to the data sent to backend
          },
        };
      } else {
        userMessage = {
          role: "user",
          message: message,
          id: new Date().getTime().toString(),
        };

        messageData = {
          location_id: location_id, // assuming you have the location_id
          first_name: locationData?.owner?.first_name, // replace with actual user first name
          message: message,
          tags: tags,
          campaign: selectedCampaign,
          conversation: {
            messages: formattedChatHistory,
            token_usage: conversationData.token_usage, // Add token_usage to the data sent to backend
          },
        };
      }
      setMessage("");
      setChatHistory([...chatHistory, userMessage]);

      console.log("Message data prepared", { messageData });

      setTimeout(() => setIsTyping(true), 1350);
      console.log("Sending message to backend");
      const response = await sendArtistAIAvatarMessage(messageData);
      console.log("Response received from backend", { response });

      const assistantMessages = response?.conversationData?.messages?.filter(
        (msg) => msg.role === "assistant"
      );
      const mostRecentAiMessage =
        assistantMessages[assistantMessages.length - 1];

      if (response?.conversationData?.messages) {
        const aiMessage = {
          role: "assistant",
          message: mostRecentAiMessage.content,
          id: (new Date().getTime() + 1).toString(),
        };
        setChatHistory((prevChatHistory) => [...prevChatHistory, aiMessage]);
        setConversationData(response.conversationData);
        setIsTyping(false);
        setFollowUpLoading(false);
      }
      // setLocationData(response.locationData);
    } catch (error) {
      console.error("Error in sendMessage:", error);

      // add timeout to prevent infinite typing loop
      setTimeout(() => {
        const errorMessage = {
          role: "assistant",
          message:
            error?.response?.data ??
            "Something went wrong. Try refreshing your page, and if error continues, reach out to support@modernmusician.me",
          id: new Date().getTime().toString(),
        };
        setChatHistory((prevChatHistory) => [...prevChatHistory, errorMessage]);
        setIsTyping(false);
      }, 1350);
    } finally {
      console.log("sendMessage function completed");
      setFollowUpLoading(false);
      setIsTyping(false);
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  }
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  const handleMessageOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(chatHistory);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setChatHistory(items);
  };

  function handleRefreshConversation() {
    setChatHistory([]);
    setSteps((st) => st.map((s) =>
      ({ ...s, completed: false })
    ));
    setConversationData({
      token_usage: {
        total_tokens: 0,
        completion_tokens: 0,
        prompt_tokens: 0,
        tokens_cost: 0,
        user_cost: 0,
        message_cost: 0,
      },
      messages: [],
    });
  }

  return (
    <div>
      <div className="d-flex flex-row mb-4 align-items-center">
        <div
          className="d-flex align-items-center justify-content-center fw-bold"
          style={{
            backgroundColor: "#36206e",
            borderRadius: "100%",
            width: 40,
            height: 40,
            color: "white",
            marginRight: 10,
          }}
        >
          3
        </div>
        <h5
          className="text-dark py-2 d-flex"
          style={{ flex: 3, marginBottom: 0 }}
        >
          Preview your Avatar
        </h5>
      </div>
      <div className="engage-chat-widget-container">
        <DragDropContext onDragEnd={handleMessageOnDragEnd}>
          <Droppable droppableId="chatHistory">
            {(provided) => (
              <ListGroup
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ marginBottom: "1rem" }}
                className="chat-conversation"
              >
                {chatHistory.map((messageObj, index) => {
                  if (messageObj.message === "") return null;
                  return (
                    <Draggable
                      key={messageObj.id}
                      draggableId={messageObj.id}
                      index={index}
                    >
                      {(provided) => (
                        <ListGroup.Item
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`chat-message-item ${messageObj.role}`}
                        >
                          <div
                            {...provided.dragHandleProps}
                            className={`chat-bubble ${messageObj.role}`}
                            onMouseEnter={() => setIsTrashIconVisible(index)}
                            onMouseLeave={() => setIsTrashIconVisible(false)}
                          >
                            {isTrashIconVisible === index && (
                              <Button
                                variant="link"
                                className="remove-message-button"
                                onClick={() => handleRemoveMessage(index)}
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left:
                                    messageObj.role === "assistant"
                                      ? "-35px"
                                      : "auto",
                                  right:
                                    messageObj.role === "user"
                                      ? "-35px"
                                      : "auto",
                                  transform: "translateY(-50%)",
                                  opacity: 0.8,
                                  zIndex: 1,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-danger"
                                />
                              </Button>
                            )}
                            <Linkify
                              componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                              ) => (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={decoratedHref}
                                  key={key}
                                  style={{ color: "#007AFF" }}
                                >
                                  {decoratedText}
                                </a>
                              )}
                            >
                              {messageObj.message}
                            </Linkify>
                          </div>
                        </ListGroup.Item>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
                {isTyping && (
                  <div className="typing__placeholder">
                    <Typing />
                  </div>
                )}
                <div ref={messagesEndRef} />
              </ListGroup>
            )}
          </Droppable>
        </DragDropContext>
        <Form onSubmit={(e) => e.preventDefault()} className="chat-form">
          <Form.Group className="d-flex">
            <Form.Control
              type="text"
              value={message}
              onChange={(e) => setMessage(e?.target?.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              className="chat-input"
            />
            <Button className="chat-button" onClick={sendMessage}>
              <FontAwesomeIcon icon={faPaperPlane} />
            </Button>
            <Button className="chat-button" onClick={handleRefreshConversation}>
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
              variant="primary"
              className="chat-button"
              onClick={() => sendMessage(true)}
              disabled={followUpLoading}
            >
              {followUpLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <>
                  <FontAwesomeIcon icon={faPlus} />1
                </>
              )}
            </Button>
          </Form.Group>
        </Form>
      </div>
      {(locationData?.billing?.credits_remaining ||
        locationData?.billing?.credits_remaining === 0) && (
          <p
            className=""
            style={{ color: "lightgray", paddingLeft: "0", marginLeft: "0" }}
          >
            $
            {Number(
              Math.round(locationData?.billing?.credits_remaining) / 100
            ).toFixed(2)}{" "}
            remaining
            {conversationData?.token_usage?.user_cost > 0 &&
              ` • $${roundToFirstNonZero(
                conversationData.token_usage.user_cost /
                conversationData.messages.length
              )} cost per message`}
          </p>
        )}
    </div>
  );
};

export default ChatPreview;
