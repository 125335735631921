import React, { useState } from 'react';
import { FormGroup, FormLabel, FormControl, Button, Form } from 'react-bootstrap';
import '../../App.scss';

const GPTIFrameConfigurator = () => {
  const [params, setParams] = useState({
    system_prompt: "",
    assistant_name: "",
    width: "100",
    height: "800",
    logo: "https://assets.cdn.filesafe.space/40w74OIRtBJaXkcwkVNQ/media/645d03f255bb6290e4a06abc.png",
    model: "power"
  });

  const [generatedIFrame, setGeneratedIFrame] = useState("");
  const [copyButtonText, setCopyButtonText] = useState("Copy");
  const [logoInputValue, setLogoInputValue] = useState("");


  //const domain = "http://localhost:3000";
  const domain = "https://cloud.streetteam.me";

  const updateParams = (key, value) => {
    setParams({ ...params, [key]: value });
  };
  const generateIFrame = () => {
    const iframeCode = `<iframe src="${domain}/widgets/chat?${new URLSearchParams(
      params
    ).toString()}" width="${params.width}%" height="${
      params.height
    }px" frameborder="0" allow="autoplay"></iframe>`;
    setGeneratedIFrame(iframeCode);
    copyToClipboard(iframeCode);
  };

  const copyToClipboard = (str) => {
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopyButtonText("Copied");
    setTimeout(() => setCopyButtonText("Copy"), 3000);
  };

  return (
    <div>
      <div className="iframe-configurator">
        <h1 className="mb-4 mt-4">Configure AI GPT</h1>
        <Form>
          <FormGroup className="mb-4">
            <FormLabel>System Prompt</FormLabel>
            <FormControl
              as="textarea"
              style={{ height: "125px" }}
              maxLength={350}
              value={params.system_prompt}
              onChange={(e) => updateParams("system_prompt", e.target.value)}
            />
          </FormGroup>

          <FormGroup className="mb-4">
            <FormLabel>Assistant Name</FormLabel>
            <FormControl
              type="text"
              value={params.assistant_name}
              onChange={(e) => updateParams("assistant_name", e.target.value)}
            />
          </FormGroup>

          <FormGroup className="mb-4">
            <FormLabel>Logo URL (Optional)</FormLabel>
            <FormControl
              type="text"
              value={logoInputValue}
              onChange={(e) => {
                setLogoInputValue(e.target.value);
                updateParams(
                  "logo",
                  e.target.value ||
                    "https://assets.cdn.filesafe.space/40w74OIRtBJaXkcwkVNQ/media/645d03f255bb6290e4a06abc.png"
                );
              }}
            />
          </FormGroup>

          <FormGroup className="mb-4">
            <FormLabel>Width (%)</FormLabel>
            <FormControl
              type="range"
              min="1"
              max="100"
              value={params.width}
              onChange={(e) => updateParams("width", e.target.value)}
            />
            <div>{params.width}%</div>
          </FormGroup>

          <FormGroup className="mb-4">
            <FormLabel>Height (px)</FormLabel>
            <FormControl
              type="number"
              value={params.height}
              onChange={(e) => updateParams("height", e.target.value)}
            />
          </FormGroup>

          <FormGroup className="mb-4">
            <FormLabel>Model</FormLabel>
            <Form.Check
              type="radio"
              label="Power"
              checked={params.model === "power"}
              onChange={() => updateParams("model", "power")}
            />
            <Form.Check
              type="radio"
              label="Turbo"
              checked={params.model === "turbo"}
              onChange={() => updateParams("model", "turbo")}
            />
          </FormGroup>

          <Button onClick={generateIFrame} className="mb-3">
            Generate Embed Snippet
          </Button>
        </Form>

        {generatedIFrame && (
          <div className="generated-iframe mb-3">
            <div className="iframe-code-container">
              <pre>
                <code>{generatedIFrame}</code>
              </pre>
            </div>
            <Button
              className="copy-btn mb-3"
              onClick={() => copyToClipboard(generatedIFrame)}
            >
              {copyButtonText}
            </Button>
          </div>
        )}
      </div>
      {generatedIFrame && (
        <div
          className="iframe-preview"
          style={{
            paddingBottom: "40px",
            margin: "auto",
          }}
        >
          <h3 className="mb-3">Preview</h3>
          <div
            className="iframe-container"
            style={{
              margin: "0 auto",
              padding: "20px",
              border: "1px solid #ccc",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <iframe
              title="iframe-preview"
              src={`${domain}/widgets/chat?${new URLSearchParams(
                params
              ).toString()}`}
              width={`${params.width}%`}
              height={`${params.height}px`}
              frameBorder="0"
              allow="autoplay"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default GPTIFrameConfigurator;

         
