import axios, { AxiosProgressEvent } from "axios"
import { getDomain } from "../../hooks/apiHelper";

// interface track {
//   locationId: string;
//   songId: string;
//   audioUrl? : string;
//   artworkUrl?: string;
//   metadata?: object;
// }

interface content {
    id: string; //prefixed with `${type}_`
    creator: creator; //composite key like `creator_${location_id}`
    type: 'audio' | 'image' | 'video';
    title?: string;
    category?: string;
    subtype: string;
    url: string;
}

interface creator {
  id: string, //composite key like `creator_${location_id}`
  name?: string,
  email?: string,
}

interface track {
  id: string; //prefixed with track_
  locationId?: string;
  title: string;
  category?: string;
  creator: creator; 
	audioId: string; // id prefixed with audio_
	imageId?: string; // id prefixed with image_
	videoId?: string; // id prefixed with video_
}

interface relic {
  id: string; //prefixed with 'relic_',
  locationId?: string;
  title?: string;
  description?: string;
  category?: string;
  type: 'moment' | 'song' | 'album';
  unlocks?: Array<string>; // Array of content IDs that this relic unlocks
  edition_size?: number;
  rarity: 'bronze' | 'silver' | 'gold' | 'platinum' | 'diamond';
  creator: creator; 
  blockchain_id?: string; // Null until minted
  minted?: string // Null until minted
	audioId?: string
	imageId?: string
  videoId?: string
}

interface formData {
  audioContent?: content;
  imageContent?: content;
  videoContent?: content;
  relic?: relic;
  track?: track;
}

export const postContentData = async (contentData: formData , onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) =>{
    const apiUrl = getDomain();
    //post the data
    const response = await axios.request({
      method: "POST", 
      url: `${apiUrl}/content/data`, 
      data: contentData, 
      onUploadProgress
    });
    return response;
}


export function drawImageInPerspective(
    srcImg: HTMLImageElement,
    targetCanvas: HTMLCanvasElement,
    //Define where on the canvas the image should be drawn:
    //coordinates of the 4 corners of the quadrilateral that the original rectangular image will be transformed onto:
    topLeftX: number,
    topLeftY: number,
    bottomLeftX: number,
    bottomLeftY: number,
    topRightX: number,
    topRightY: number,
    bottomRightX: number,
    bottomRightY: number,
    //optionally flip the original image horizontally or vertically *before* transforming the original rectangular image to the custom quadrilateral:
    flipHorizontally: number,
    flipVertically: number,
  ) {
    var srcWidth = srcImg.naturalWidth;
    var srcHeight = srcImg.naturalHeight;
    var targetMarginX = Math.min(topLeftX, bottomLeftX, topRightX, bottomRightX);
    var targetMarginY = Math.min(topLeftY, bottomLeftY, topRightY, bottomRightY);
  
    var targetTopWidth = topRightX - topLeftX;
    var targetTopOffset = topLeftX - targetMarginX;
    var targetBottomWidth = bottomRightX - bottomLeftX;
    var targetBottomOffset = bottomLeftX - targetMarginX;
  
    var targetLeftHeight = bottomLeftY - topLeftY;
    var targetLeftOffset = topLeftY - targetMarginY;
    var targetRightHeight = bottomRightY - topRightY;
    var targetRightOffset = topRightY - targetMarginY;
  
    var tmpWidth = Math.max(
      targetTopWidth + targetTopOffset,
      targetBottomWidth + targetBottomOffset
    );
    var tmpHeight = Math.max(
      targetLeftHeight + targetLeftOffset,
      targetRightHeight + targetRightOffset
    );
  
    var tmpCanvas = document.createElement("canvas");
    tmpCanvas.width = tmpWidth;
    tmpCanvas.height = tmpHeight;
    var tmpContext = tmpCanvas.getContext("2d");
  
    tmpContext?.translate(
      flipHorizontally ? tmpWidth : 0,
      flipVertically ? tmpHeight : 0
    );
    tmpContext?.scale(
      (flipHorizontally ? -1 : 1) * (tmpWidth / srcWidth),
      (flipVertically ? -1 : 1) * (tmpHeight / srcHeight)
    );
  
    tmpContext?.drawImage(srcImg, 0, 0);
  
    var tmpMap = tmpContext?.getImageData(0, 0, tmpWidth, tmpHeight);
    var tmpImgData = tmpMap?.data;
  
    var targetContext = targetCanvas.getContext("2d");
    var targetMap = targetContext?.getImageData(
      targetMarginX,
      targetMarginY,
      tmpWidth,
      tmpHeight
    );
    var targetImgData = targetMap?.data;
  
    var targetX, targetY, tmpPoint, targetPoint;
  
    for (var tmpY = 0; tmpY < tmpHeight; tmpY++) {
      for (var tmpX = 0; tmpX < tmpWidth; tmpX++) {
        //Index in the context.getImageData(...).data array.
        //This array is a one-dimensional array which reserves 4 values for each pixel [red,green,blue,alpha) stores all points in a single dimension, pixel after pixel, row after row:
        tmpPoint = (tmpY * tmpWidth + tmpX) * 4;
  
        //calculate the coordinates of the point on the skewed image.
        //
        //Take the X coordinate of the original point and translate it onto target (skewed) coordinate:
        //Calculate how big a % of srcWidth (unskewed x) tmpX is, then get the average this % of (skewed) targetTopWidth and targetBottomWidth, weighting the two using the point's Y coordinate, and taking the skewed offset into consideration (how far topLeft and bottomLeft of the transformation trapezium are from 0).
        targetX =
          (targetTopOffset + (targetTopWidth * tmpX) / tmpWidth) *
            (1 - tmpY / tmpHeight) +
          (targetBottomOffset + (targetBottomWidth * tmpX) / tmpWidth) *
            (tmpY / tmpHeight);
        targetX = Math.round(targetX);
  
        //Take the Y coordinate of the original point and translate it onto target (skewed) coordinate:
        targetY =
          (targetLeftOffset + (targetLeftHeight * tmpY) / tmpHeight) *
            (1 - tmpX / tmpWidth) +
          (targetRightOffset + (targetRightHeight * tmpY) / tmpHeight) *
            (tmpX / tmpWidth);
        targetY = Math.round(targetY);
  
        targetPoint = (targetY * tmpWidth + targetX) * 4;
        if(targetImgData && tmpImgData){
          targetImgData[targetPoint] = tmpImgData[tmpPoint]; //red
          targetImgData[targetPoint + 1] = tmpImgData[tmpPoint + 1]; //green
          targetImgData[targetPoint + 2] = tmpImgData[tmpPoint + 2]; //blue
          targetImgData[targetPoint + 3] = tmpImgData[tmpPoint + 3]; //alpha
        }
      }
    }
    if(targetContext && targetMap){
      targetContext.putImageData(targetMap, targetMarginX, targetMarginY);
    }
}
  