import { React, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";

const NewRequestModal = ({ show, handleClose, handleSubmit }) => {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [tokens, setTokens] = useState(100);

  const handleFormSubmit = () => {
    handleSubmit({ title, details, tokens });
    setTitle("");
    setDetails("");
    setTokens(0);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Short, descriptive title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Any additional details..."
              value={details}
              onChange={(e) => setDetails(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Tokens</Form.Label>
            <Form.Control
              type="number"
              placeholder="Number of tokens to submit"
              value={tokens}
              onChange={(e) => setTokens(e.target.value)}
              step={50}
              min={50}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="secondary" onClick={handleClose}>
          Close
        </Button> */}
        <Button variant="primary" onClick={handleFormSubmit}>
          Submit Request
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewRequestModal;
