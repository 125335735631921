export async function asyncForEach<T>(
  arr: T[],
  callback: (a: T, i: number, arr: T[]) => Promise<any>
) {
  await Promise.all(
    arr.map(async (item, i) => {
      await callback(item, i, arr);
    })
  );
}


export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
     const reader = new FileReader();
     reader.readAsDataURL(file);
     reader.onload = () => resolve(reader.result);
     reader.onerror = (error) => reject(error);
});

export function base64ToFile(base64String: string, filename: string, fileType: string) {
  const byteString = atob(base64String.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([ab], { type: fileType }); // Replace 'image/png' with the actual MIME type of your image
  const file = new File([blob], filename, { type: fileType }); // Replace 'filename.png' with your desired filename
  
  return file;
}


export function isObjectEmpty(obj: Object) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false; // The object has at least one own property
    }
  }
  return true; // The object is empty
}

export function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, { type: originalFile.type });
}


export const STANDARD_ACTIONS = [
  "Content Viewed",
  "Registration Completed",
  "Survey Submitted",
  "Appointment Booked",
  "Order Completed",
  "Subscription Started",
];
export const FRIENDLY_ACTIONS = [
  "Music Streamed",
  "Fan Subscribed",
  "Survey Submitted",
  "Appointment Booked",
  "Relic Purchased",
  "Subscription Started",
];

export const FRIENDLY_TO_STANDARD = {
  "Music Streamed": "Content Viewed",
  "Fan Subscribed": "Registration Completed",
  "Survey Submitted": "Survey Submitted",
  "Appointment Booked": "Appointment Booked",
  "Relic Purchased": "Order Completed",
  "Subscription Started": "Subscription Started",
  Custom: "Custom",
};

export const STANDARD_TO_FRIENDLY = {
  "Content Viewed": "Music Streamed",
  "Registration Completed": "Fan Subscribed",
  "Survey Submitted": "Survey Submitted",
  "Appointment Booked": "Appointment Booked",
  "Order Completed": "Relic Purchased",
  "Subscription Started": "Subscription Started",
  Custom: "Custom",
};
