import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import { useState } from "react";

function SubmitRelics() {

  const [relicList, setRelicList] = useState([{ relic: "" }]);

  /*const handleRelicChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...relicList];
    list[index][name] = value;
    setRelicList(list);
  };*/

  const handleRelicRemove = (index) => {
    const list = [...relicList];
    list.splice(index, 1);
    setRelicList(list);
  };

  const handleRelicAdd = () => {
    setRelicList([...relicList, { relic: "" }]);
  };

  /**
   * Here's the data we need to capture
   * 
   * Location ID
    Creator Name
    Relic Title
    Relic Description
    Music File URL
    Artwork File URL
    NFT Submission Notes
    Initial Value
   */

  return (
    <Container>
      <Card>
        <Card.Body>
          <Form>
            <Row>
              <Card.Title> Relic 2 </Card.Title>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId={"relic-type-1"}>
                  <Form.Label>Relic Type</Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option>Select a Music Relic Type</option>
                    <option value="1">Moment</option>
                    <option value="2">Song</option>
                    <option value="3">Album</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId={"location-id-1"}>
                  <Form.Label>Location ID</Form.Label>
                  <Form.Control type="text" placeholder="Location ID" />
                </Form.Group>
                <Form.Group className="mb-3" controlId={"artist-name-1"}>
                  <Form.Label>Creator Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Artist Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control type="text" placeholder="Enter title of Relic" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" placeholder="Enter description of Relic" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="initial-value">
                  <Form.Label>Moment Initial Value</Form.Label>
                  <Form.Control as="currency" placeholder="Enter initial value of Relic" />
                </Form.Group>
                <Form.Group controlId="formFileArtwork" className="mb-3">
                  <Form.Label>Artwork File</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
                <Form.Group controlId="formFileAudio" className="mb-3">
                  <Form.Label>Audio File</Form.Label>
                  <Form.Control type="file" />
                </Form.Group>
              </Col>
            </Row>
            <div className="form-field">
              {relicList.map((singleRelic, index) => (
                <div key={index} className="relics">
                  <div className="first-division">
                    {relicList.length - 1 === index && relicList.length < 2 && (
                      <div className="d-grid gap-2">
                        <Button onClick={handleRelicAdd} variant="secondary" className="text-white" size="md">
                          Add an Additional Relic
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="second-division">
                    {relicList.length !== 1 && (
                      <Row>
                        <hr className="mt-3"></hr>
                        <Card.Title> Relic {index + 2} </Card.Title>
                        <Col>
                          <Form.Group className="mb-3" controlId={"relic-type-" + index}>
                            <Form.Label>Relic Type</Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Select a Music Relic Type</option>
                              <option value="1">Moment</option>
                              <option value="2">Song</option>
                              <option value="3">Album</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId={"artist-name-" + index}>
                            <Form.Label>Artist Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter Artist Name" />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId={"title-" + index}>
                            <Form.Label>Title</Form.Label>
                            <Form.Control type="text" placeholder="Enter title of Relic" />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId={"description-" + index}>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" placeholder="Enter description of Relic" />
                          </Form.Group>
                          <Form.Group controlId={"formFileArtwork-" + index} className="mb-3">
                            <Form.Label>Artwork File</Form.Label>
                            <Form.Control type="file" />
                          </Form.Group>
                          <Form.Group controlId={"formFileAudio-" + index} className="mb-3">
                            <Form.Label>Audio File</Form.Label>
                            <Form.Control type="file" />
                          </Form.Group>
                          <Button variant="secondary" className="text-white" onClick={() => handleRelicRemove(index)}> Remove </Button>
                        </Col>
                      </Row>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className="d-grid gap-2 mt-2">
              <Button variant="primary" type="submit" size="md">
                Submit Relics
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default SubmitRelics;