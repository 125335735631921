// import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Navbar,
  Modal,
  Form,
  Dropdown,
  Spinner,
} from "react-bootstrap";

// Add additional props for user's name and available token count
const Header = ({
  onShowModal,
  handleLogin,
  onLogout,
  user,
  loading,
  pageValues,
}) => {
  // State to manage the visibility of the login modal
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [email, setEmail] = useState("");
  const [showJokeModal, setShowJokeModal] = useState(false);

  // Handlers to open and close the login modal
  const handleShowLoginModal = () => setShowLoginModal(true);
  const handleCloseLoginModal = () => setShowLoginModal(false);

  // const handleShowJokeModal = () => setShowJokeModal(true);
  const handleCloseJokeModal = () => setShowJokeModal(false);

  const handleLogoutClick = () => {
    onLogout();
    handleCloseLoginModal(); // If you want to close the modal upon logging out
  };

  // // Function to handle the reload tokens click, which triggers the joke modal
  // const handleReloadTokensClick = () => {
  //   // Here you might include logic to check if it's appropriate to show the joke, etc.
  //   handleShowJokeModal();
  // };

  // useEffect to set login modal to true if user is not logged in
  useEffect(() => {
    if (!user.id && !showLoginModal) {
      handleShowLoginModal();
    } else if (user.id) {
      handleCloseLoginModal();
    }
  }, [user.id, showLoginModal]);

  console.log(`pageValues are`, pageValues);
  // console.log(`user is`, user);

  return (
    <div className="ic-header-full">
      <Navbar expand="lg" variant="light" bg="light">
        <Navbar.Brand href="#">
          <img
            src="https://cloud.streetteam.me/static/media/logo.ad07c3cb.png"
            alt="Artist Logo"
            style={{ width: "50px", height: "50px" }}
          />
          <span style={{ marginLeft: "10px", fontWeight: "800" }}>
            SoundBoard
          </span>
        </Navbar.Brand>
        <Button style={{ marginRight: "20px" }} onClick={onShowModal}>
          Make Request
        </Button>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar" className="justify-content-end nav-menu">
          <Navbar.Text>
            {user ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="holographic-token me-2">
                  <span style={{ fontWeight: "800" }}>
                    {user?.tokens?.toLocaleString()}{" "}
                  </span>
                  tokens
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="p-0"
                    style={{
                      background: "none",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    <img
                      src={user.profile_picture}
                      alt="User Avatar"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu align="end">
                    {/* <Dropdown.Item
                      onClick={() =>
                        (window.location.href =
                          "https://buy.stripe.com/dR6eY7eCi8bJ5Fu6oC")
                      }
                    >
                      Reload Tokens
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() =>
                        window.open(
                          `${pageValues?.funnel_links?.profile}?email=${user.email}&contact_id=${user.id}`,
                          "_blank"
                        )
                      }
                    >
                      Update Profile
                    </Dropdown.Item>

                    <Dropdown.Item onClick={handleLogoutClick}>
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <Button onClick={handleShowLoginModal}>Login</Button>
            )}
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
      <hr style={{ margin: 0 }} />

      {/* Login Modal */}
      <Modal show={showLoginModal} onHide={handleCloseLoginModal} centered>
        <Modal.Header>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleLogin(e, email)}>
            <Form.Group controlId="formUserEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleCloseLoginModal}>
            Close
          </Button> */}
          <Button
            disabled={loading}
            variant="primary"
            onClick={(e) => handleLogin(e, email)}
          >
            {loading ? (
              <Spinner as="span" animation="border" size="sm" />
            ) : (
              "Login"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showJokeModal} onHide={handleCloseJokeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reload Tokens</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter amount to reload:</p>
          <Form.Control
            type="text"
            readOnly
            defaultValue="$1,000,000.00 USD"
            className="text-left joke-input"
          />
          <div className="mt-3 text-muted" style={{ fontSize: "0.8em" }}>
            All sales are final. No refunds.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() =>
              window.open("https://buy.stripe.com/dR6eY7eCi8bJ5Fu6oC")
            }
          >
            Pay Now
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Header;
