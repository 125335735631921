import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios"; // Make sure axios is imported
// import { SANDBOX_DOMAIN } from "../pages/adCreator/config";
import { Card, Container, Spinner } from "react-bootstrap";

const AuthCallback = () => {
  // const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    //const state = urlParams.get("state"); // In a real application, validate this

    if (!window.opener) {
      return;
    }
    if (code) {
      // Sending the code to the original component via postMessage
      window.opener.postMessage(
        {
          type: "META_AUTH_SUCCESS",
          payload: { code },
        },
        "*" // In a real-world application, you should specify the origin
      );

      // Close the popup window and navigate user in the main window
      window.close();
    } else {
      window.opener.postMessage(
        {
          type: "META_AUTH_FAILURE",
          payload: { error: "Failed to get the code from URL" },
        },
        "*" // In a real-world application, you should specify the origin
      );

      // Close the popup window
      window.close();
    }
  }, []);

  return (
    <Container
      fluid
      className="px-5 py-5 flex-column align-items-center"
      style={{
        minWidth: "400px",
        // maxWidth: "1400px",
        margin: "0 auto",
      }}
    >
      {" "}
      <Card className="text-center py-5 d-flex justify-content-center">
        <h1>Authenticating...</h1>
        <div className="w-100 mt-3">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </Card>
    </Container>
  );
};

export default AuthCallback;
