import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Button, Col, Form } from "react-bootstrap";
import CampaignStep from "./CampaignStep";
import { STANDARD_ACTIONS, STANDARD_TO_FRIENDLY } from "../../utils";

const { v4: uuidv4 } = require("uuid");

const CampaignSettings = ({
  steps,
  setSelectedCampaign,
  setSteps,
  links,
  shouldHighlightStepId,
  setHasEditedAnything,
}) => {
  const [forceOpenStepId, setForceOpenStepId] = useState(null);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(steps);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSteps(items);
    setHasEditedAnything(true);
  };

  const handleAddStep = () => {
    const newId = uuidv4().replace(/-/g, "").substring(0, 25);
    const newStep = {
      id: newId,
      type: "",
      action: "",
      link: links[0] || {},
      prompt: "",
      editing: true,
      completed: false,
    };
    const updatedSteps = [newStep, ...steps];
    setSteps(updatedSteps);
    setSelectedCampaign((prevCampaignData) => ({
      ...prevCampaignData,
      steps: updatedSteps,
    }));
    setForceOpenStepId(newId);
    setHasEditedAnything(true);
  };

  const handleRemoveStep = (index) => {
    const updatedSteps = steps;
    updatedSteps.splice(index, 1);
    setSteps(updatedSteps);
    setSelectedCampaign((prevCampaignData) => ({
      ...prevCampaignData,
      steps: updatedSteps,
    }));
    setHasEditedAnything(true);
  };

  const handleEditStep = (updatedStep, index) => {
    let updatedSteps = steps;
    updatedSteps[index] = updatedStep;

    setSteps(updatedSteps);
    setSelectedCampaign((prevCampaignData) => ({
      ...prevCampaignData,
      steps: updatedSteps,
    }));
    setHasEditedAnything(true);
  };

  // useEffect(() => {
  //   // Checking if there are any steps with undefined editing or completed properties
  //   const hasUndefinedProps = steps.some(
  //     (step) => step.editing === undefined || step.completed === undefined
  //   );

  //   // Only updating state if there are steps with undefined properties to avoid infinite loop
  //   if (hasUndefinedProps) {
  //     const updatedSteps = steps.map((step) => ({
  //       ...step,
  //       editing: step.editing === undefined ? false : step.editing,
  //       completed: step.completed === undefined ? false : step.completed,
  //     }));
  //     setSteps(updatedSteps);
  //   }
  // }, [steps, setSteps]);

  return (
    <>
      <div className="d-flex flex-row mb-4 align-items-center">
        <div
          className="d-flex align-items-center justify-content-center fw-bold"
          style={{
            backgroundColor: "#36206e",
            borderRadius: "100%",
            width: 40,
            height: 40,
            color: "white",
            marginRight: 10,
          }}
        >
          2
        </div>
        <h5
          className="text-dark py-2 d-flex"
          style={{ flex: 3, marginBottom: 0 }}
        >
          Adjust your conversion steps
        </h5>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="steps">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {steps.map((step, index) => (
                <CampaignStep
                  key={step.id}
                  step={step}
                  onEditStep={(updatedStep) =>
                    handleEditStep(updatedStep, index)
                  }
                  onRemoveStep={() => handleRemoveStep(index)}
                  links={links}
                  idx={index}
                  forceOpen={
                    step.id === forceOpenStepId ||
                    shouldHighlightStepId === step?.id
                  }
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Col xs={12} className="mt-2">
        <Button
          variant="outline-info"
          className=" w-100"
          onClick={handleAddStep}
        >
          <FontAwesomeIcon className="me-1" icon={faPlus} />
          Add Step
        </Button>
      </Col>
      <h5 className="mb-3 mt-5">Simulate Next Steps</h5>
      <p className="mb-3">
        This allows you to simulate how your bot will respond when you have
        achieved different goals with a new fan.
      </p>
      {steps.map((step, index) => (
        <Form.Check
          type="checkbox"
          label={
            STANDARD_ACTIONS.includes(step?.action)
              ? STANDARD_TO_FRIENDLY[step.action]
              : step?.action
          }
          key={index}
          checked={step.completed}
          onChange={() => {
            setSteps((st) =>
              st.map((s, i) =>
                i === index ? { ...s, completed: !s.completed } : s
              )
            );
          }}
        />
      ))}
    </>
  );
};

export default CampaignSettings;
