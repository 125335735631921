import React from "react";
import { Offcanvas, Button } from "react-bootstrap";
import AvatarImage from "./AvatarImage";

const RequestSidebar = ({
  request,
  show,
  onHide,
  handleUpvote,
  isAdmin,
  handleRefund,
}) => {
  if (!request) return null;

  return (
    <Offcanvas className="p-1" show={show} onHide={onHide} placement="end">
      <Offcanvas.Header
        className="pb-1"
        closeButton
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3
            className="request-title"
            style={{
              display: "inline-block",
              marginRight: "10px",
            }}
          >
            {request.title}
          </h3>
          {/* <div
            className="holographic-token title-token ms-1 mt-1"
            style={{ display: "inline-block" }}
          >
            <span style={{ fontWeight: "800" }}>{request.tokens} </span>
            tokens
          </div> */}
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="pt-1">
        <p className="request-description">{request.description}</p>
        {request.poster && (
          <div style={{ marginBottom: "15px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <AvatarImage
                src={request.poster.profile_picture}
                alt="poster avatar"
                className="avatar-img"
                style={{ marginRight: "10px" }}
              />
              <strong style={{ marginRight: "10px" }}>
                {request.poster.name}
              </strong>
              <div className="holographic-token ms-1">
                <span style={{ fontWeight: "800" }}>
                  {request?.poster?.tokens?.toLocaleString()}{" "}
                </span>
                tokens
              </div>
            </div>
            <div>{new Date(request.poster.datetime).toLocaleDateString()}</div>
          </div>
        )}
        <Button
          className="upvote-button"
          onClick={() => handleUpvote(request.id)}
        >
          Upvote
        </Button>
        {request.contributors && request.contributors.length > 0 && (
          <div className="contributors-section">
            {request.contributors.map((contributor, index) => (
              <div key={index} className="contributor-comment mt-1 mb-2">
                <div className="my-1">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      //   marginBottom: "10px",
                    }}
                  >
                    <AvatarImage
                      src={contributor.profile_picture}
                      alt="poster avatar"
                      className="avatar-img"
                      style={{ marginRight: "10px" }}
                    />
                    <strong style={{ marginRight: "10px" }}>
                      {contributor.name}
                    </strong>
                    <div className="holographic-token ms-1">
                      <span style={{ fontWeight: "800" }}>
                        {contributor?.tokens?.toLocaleString()}{" "}
                      </span>
                      tokens
                    </div>
                  </div>
                  <p className="my-1">{contributor.comment}</p>
                  <small>
                    {new Date(contributor.datetime).toLocaleString("en-US")}
                  </small>
                  {/* <hr /> */}
                </div>
              </div>
            ))}
          </div>
        )}
      </Offcanvas.Body>
      {/* Conditionally render the Refund Request button */}
      {isAdmin && (
        <Button
          variant="danger"
          style={{
            position: "absolute",
            bottom: "10px",
            right: "10px",
          }}
          onClick={() => handleRefund(request)}
        >
          Refund Request
        </Button>
      )}
    </Offcanvas>
  );
};

export default RequestSidebar;
