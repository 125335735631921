// Utility function to clean formData
function cleanFormData(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] === null || data[key] === "") {
      delete data[key];
    }
  });
  return data;
}

export default cleanFormData;
