import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Spinner, Button, Form } from "react-bootstrap";
import {
  faRedo,
  faSave,
  faShareFromSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../App.scss";
import ChatPreview from "./ChatPreview";
import CampaignSettings from "./CampaignSettings";
import {
  createLink,
  deleteCampaign,
  fetchCampaignData,
  fetchLinkData,
  fetchLocationData,
  saveCampaign,
  updateLink,
} from "../../hooks/apiHelper";
import PublishModal from "./PublishModal";
import AdvancedSettings from "./AdvancedSettings";
import QuickstartModal from "./QuickstartModal";
import { asyncForEach } from "../../utils";
import ConfirmationModal from "../../components/ConfirmationModal";
const { v4: uuidv4 } = require("uuid");

const ArtistAI = () => {
  const windowUrl = useLocation();
  const searchParams = new URLSearchParams(windowUrl.search);
  const location_id = searchParams.get("location_id") || "";
  const api_key = searchParams.get("api_key") || "";

  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasEditedAnything, setHasEditedAnything] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showQuickstartModal, setShowQuickstartModal] = useState(false);
  const [shouldHighlightStepId, setShouldHighlightStepId] = useState(null);

  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [steps, setSteps] = useState([]);
  const [links, setLinks] = useState([]);
  const [locationData, setLocationData] = useState(null);
  const [artistAiConfig, setArtistAiConfig] = useState({
    gpt_model: "",
    character_count: "",
    custom_system_prompt: "",
    text_transform: "",
    follow_up_prompt: "",
  });

  const handleCreateLink = useCallback(
    async (actionName, redirect_url, locationId) => {
      const result = await createLink(
        actionName,
        redirect_url,
        locationId,
        api_key
      );
      if (result.status === "success") {
        return result.data;
      }
    },
    [api_key]
  );

  useEffect(() => {
    if (location_id && api_key) {
      loadData();
    } else {
      window.alert("Please provide a location ID and API key.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location_id, api_key]);

  async function loadData() {
    try {
      setHasEditedAnything(false);
      setLoading(true);
      const locationData = await fetchLocationData(location_id, api_key);
      setLocationData(locationData);

      const campaignsData = await fetchCampaignData(location_id, api_key);
      setCampaigns(campaignsData);

      if (campaignsData.length === 0 && locationData) {
        setShowQuickstartModal(true);
      } else if (campaignsData.length > 0) {
        const campaignToSet =
          (
            campaignsData.find((c) => c?.value?.id === selectedCampaign?.id) ||
            {}
          ).value || campaignsData[0]?.value;
        if (campaignToSet) {
          setSelectedCampaign(campaignToSet);
          setSteps(campaignToSet.steps);
          setArtistAiConfig(campaignToSet.artist_ai);
        }
      }

      const linkData = await fetchLinkData(location_id, api_key);
      let createdLinks = linkData || [];

      const createLinkIfNeeded = async (type, url) => {
        if (
          url &&
          (!linkData || !linkData.find((l) => l.redirect_url === url))
        ) {
          const newLink = await handleCreateLink(type, url, location_id);
          createdLinks.push(newLink);
        }
      };

      if (locationData?.funnel_links) {
        await createLinkIfNeeded(
          "Content Viewed",
          locationData.funnel_links.fan_magnet_funnel
        );
        await createLinkIfNeeded(
          "Registration Completed",
          locationData.funnel_links.free_community
        );
        await createLinkIfNeeded(
          "Order Completed",
          locationData.funnel_links.starter_pack
        );
      }

      setLinks(createdLinks);
    } finally {
      setLoading(false);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function handleCreateCampaign(quickLaunchData) {
    setIsCreateLoading(true);
    let locationFunnelLinks = {
      content:
        quickLaunchData?.streamingLink ||
        locationData?.funnel_links?.fan_magnet_funnel,
      registration:
        quickLaunchData?.communityLink ||
        locationData?.funnel_links?.free_community,
      survey: locationData?.funnel_links?.intune_survey,
      appointment: locationData?.funnel_links?.scheduling_catch_up,
      purchase:
        quickLaunchData?.starterPackLink ||
        locationData?.funnel_links?.starter_pack,
      subscription: locationData?.funnel_links?.inner_circle_tiers,
    };

    // Define a mapping from action types to human-readable descriptions
    const actionNames = {
      content: "Content Viewed",
      registration: "Registration Completed",
      survey: "Survey Submitted",
      appointment: "Appointment Booked",
      purchase: "Order Completed",
      subscription: "Subscription Started",
    };

    // Define a mapping from human-readable descriptions back to action types
    const reverseActionNames = {
      "Content Viewed": "content",
      "Registration Completed": "registration",
      "Survey Submitted": "survey",
      "Appointment Booked": "appointment",
      "Order Completed": "purchase",
      "Subscription Started": "subscription",
    };

    // Use the links from our database
    const defaultActionLinks = links;
    const actionLinks = defaultActionLinks?.reduce((acc, curr) => {
      const actionDescription = curr.action.replace("Action - ", ""); // Assuming the action is 'Action - actionType'
      const actionType = reverseActionNames[actionDescription];
      if (actionType) {
        return {
          ...acc,
          [actionType]: {
            action: curr.action,
            redirect_url: curr.redirect_url,
            id: curr.id,
          },
        };
      }
      return acc;
    }, {});
    const actionTypes = Object.keys(locationFunnelLinks);

    await asyncForEach(actionTypes, async (actionType) => {
      if (!actionLinks?.[actionType] && locationFunnelLinks?.[actionType]) {
        // If not, create the link
        let actionName = `${actionNames[actionType]}`; // Get the action name from the mapping
        let newLink = await handleCreateLink(
          actionName,
          locationFunnelLinks[actionType],
          locationData.id
        );
        // Check if newLink is actually defined
        if (newLink) {
          // Then add the new link to actionLinks
          actionLinks[actionType] = {
            action: newLink.action,
            redirect_url: newLink.redirect_url,
            id: newLink.id,
          };
        } else {
          console.error(
            `Failed to create link for action ${actionType}: ${actionName}`
          );
        }
      }
    });

    let genre = quickLaunchData?.genre || locationData?.genre || "";
    let subgenres = [];
    if (locationData?.subgenres) {
      // map the subgenres to the first 2 that aren't the same as the genre
      subgenres = locationData?.subgenres
        .filter((subgenre) => subgenre !== genre)
        .slice(0, 2);
    }

    // Construct the genre string
    let genreString = [genre, ...subgenres].filter(Boolean).join("/");

    // Basic Campaign
    const newCampaign = {
      steps: [
        {
          id: uuidv4().replace(/-/g, "").substring(0, 25),
          link: actionLinks?.content ?? {},
          action: "Content Viewed",
          prompt:
            "listen to a song here (make sure to let me know what you think!)",
        },
        {
          id: uuidv4().replace(/-/g, "").substring(0, 25),
          link: actionLinks?.registration ?? {},
          action: "Registration Completed",
          prompt:
            "join your exclusive online community for early music access, livestreams and more completely free here",
        },
        {
          id: uuidv4().replace(/-/g, "").substring(0, 25),
          link: actionLinks?.purchase ?? {},
          action: "Order Completed",
          prompt:
            "grab a Starter Pack here (it's free for you - you'd just cover the shipping & handling)",
        },
      ],
      id: uuidv4().replace(/-/g, "").substring(0, 25),
      name: `New Campaign ${new Date().toDateString()}`,
      type: "vth",
      artist_ai: {
        text_transform: "Capitalize",
        custom_system_prompt: `You are ${
          quickLaunchData?.name || locationData?.name
        }, ${genreString} musician.`,
        gpt_model: "gpt-4-turbo",
        character_count: 75,
        follow_up_prompt: "Send a gentle follow-up reminder.",
      },
    };

    const linksArray = Object.keys(actionLinks).map((key) => {
      return {
        archived: false,
        action: actionLinks[key].action,
        redirect_url: actionLinks[key].redirect_url,
        id: actionLinks[key].id,
        location_id: location_id,
        type: "action",
      };
    });

    try {
      setSelectedCampaign(newCampaign);
      setSteps(newCampaign.steps);
      setArtistAiConfig(newCampaign.artist_ai);
      setCampaigns([...campaigns, { value: newCampaign }]);
      await saveCampaign(locationData, newCampaign, campaigns, api_key);
      setShowQuickstartModal(false);
      setLinks(linksArray); // set the links state based on quickstart data
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreateLoading(false);
    }
  }

  async function handleSaveCampaign() {
    setIsSaving(true);
    const stepsToSave = await saveAllSteps();

    const campaignToSave = {
      ...selectedCampaign,
      steps: stepsToSave,
      artist_ai: artistAiConfig,
    };
    setSelectedCampaign(campaignToSave);

    const result = await saveCampaign(
      locationData,
      campaignToSave,
      campaigns,
      api_key
    );
    if (result.status === "success") {
      setSelectedCampaign(result.campaignObject);
      setCampaigns(result.updatedCampaigns);
      setHasEditedAnything(false);
    } else if (result.message) {
      window.alert(result.message);
    }
    setIsSaving(false);
  }

  const handleDeleteCampaign = async () => {
    const result = await deleteCampaign(
      locationData,
      selectedCampaign,
      campaigns,
      api_key
    );
    if (result.status === "success") {
      setCampaigns(result.updatedCampaigns);
      const campaignToView = result.updatedCampaigns[0]?.value;
      setSelectedCampaign(campaignToView);
      setSteps(campaignToView?.steps || []);
      setArtistAiConfig(campaignToView?.artist_ai);
    }
  };

  const saveAllSteps = async () => {
    let stepsToSave = steps;

    await asyncForEach(steps, async (step, index) => {
      if (step?.link?.id) {
        const result = await updateLink(
          step.link.id,
          step.action,
          step.link?.redirect_url,
          locationData.id,
          api_key
        );
        if (result.status === "success") {
          const currentLinks = links;
          setLinks(
            currentLinks?.map((link) =>
              link.id === step?.link?.id
                ? {
                    ...link,
                    action: step?.action,
                    redirect_url: step?.link?.redirect_url,
                  }
                : link
            )
          );
        }
      } else if (!step?.link?.id) {
        // If the link doesn't have an id, then create it in Highlevel and in the app
        const newLink = await handleCreateLink(
          step.action,
          step.link.redirect_url,
          locationData.id
        );
        step.link = newLink;
      }

      stepsToSave[index] = step;
    });

    setSteps(stepsToSave);

    return stepsToSave;
  };

  async function handleSelectCampaign(newCampaignId) {
    if (newCampaignId === "add_new") {
      await handleCreateCampaign(locationData);
    } else {
      const campaignToSelect = campaigns.find(
        (c) => c?.value?.id === newCampaignId
      )?.value;
      setSelectedCampaign(campaignToSelect);
      setSteps(campaignToSelect.steps);
      setArtistAiConfig(campaignToSelect.artist_ai);
    }
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <Container fluid className="px-5 py-3">
      <div className="d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row">
        <h2>ArtistAI Avatar</h2>
        <div className="d-flex flex-column flex-lg-row justify-content-end align-items-start align-items-lg-end">
          <Form.Group>
            <h5>Select a Campaign</h5>
            <Form.Control
              className="custom-select"
              as="select"
              value={selectedCampaign?.id}
              onChange={(e) => handleSelectCampaign(e?.target?.value)}
            >
              {campaigns.map((campaign) => (
                <option key={campaign.value.id} value={campaign.value.id}>
                  {campaign.value.name}
                </option>
              ))}
              <option value="add_new">Create New Campaign</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="ms-lg-4 my-2 my-lg-0">
            <h5>Edit Campaign Name</h5>
            <Form.Control
              type="text"
              value={selectedCampaign?.name || ""}
              onChange={(e) => {
                setHasEditedAnything(true);
                setSelectedCampaign((prevCampaignData) => ({
                  ...prevCampaignData,
                  name: e?.target?.value,
                }));
              }}
              placeholder="Add name here..."
            />
          </Form.Group>
          <Button
            variant="outline-danger"
            className="my-2 my-lg-0 mx-lg-2"
            onClick={() => setShowConfirmationModal(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          {hasEditedAnything && (
            <>
              <Button
                onClick={() => loadData()}
                className="me-lg-2"
                variant="transparent"
              >
                <FontAwesomeIcon className="me-1" icon={faRedo} />
                Discard Changes
              </Button>
              <Button onClick={() => handleSaveCampaign()} className="me-lg-2">
                {isSaving ? (
                  <Spinner animation="border" role="status" size="sm"></Spinner>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faSave} />
                  </>
                )}
              </Button>
            </>
          )}

          <Button variant="secondary" onClick={() => setShowPublishModal(true)}>
            <FontAwesomeIcon className="me-1" icon={faShareFromSquare} />
            Publish
          </Button>
        </div>
      </div>
      <hr />

      <PublishModal
        show={showPublishModal}
        onHide={() => setShowPublishModal(false)}
        campaigns={campaigns}
        onUpdateLocationData={(newLocation) => setLocationData(newLocation)}
        locationData={locationData}
        api_key={api_key}
      />
      <QuickstartModal
        show={showQuickstartModal}
        onHide={() => setShowQuickstartModal(false)}
        locationData={locationData}
        onCreateCampaign={(data) => handleCreateCampaign(data)}
        isLoading={isCreateLoading}
      />
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title="Are you sure you want to delete your campaign?"
        description="You will lose all saved work and will not be able to recover this campaign if you continue"
        onConfirm={() => {
          handleDeleteCampaign();
          setShowConfirmationModal(false);
        }}
      />
      <Row className="h-100">
        <Col md={4} className="border-right">
          <AdvancedSettings
            steps={steps}
            locationData={locationData}
            artistAiConfig={artistAiConfig}
            onUpdateConfig={(key, newValue) => {
              setHasEditedAnything(true);
              setArtistAiConfig({ ...artistAiConfig, [key]: newValue });
            }}
            onHighlightStep={(stepId) => setShouldHighlightStepId(stepId)}
          />
        </Col>
        <Col md={3}>
          <CampaignSettings
            links={links}
            steps={steps}
            setSteps={setSteps}
            setHasEditedAnything={setHasEditedAnything}
            setSelectedCampaign={setSelectedCampaign}
            shouldHighlightStepId={shouldHighlightStepId}
          />
        </Col>
        <Col md={5} className="border-left">
          <ChatPreview
            location_id={location_id}
            selectedCampaign={selectedCampaign}
            steps={steps}
            setSteps={setSteps}
            locationData={locationData}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ArtistAI;
