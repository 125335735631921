// Constants

const LOCAL_SANDBOX_URL = process.env.REACT_APP_SANDBOX_URL;

const ONE_DAY = 24 * 60 * 60 * 1000;
const TIME_FRAMES = [
  { days: 7, label: "7 Days" },
  { days: 14, label: "14 Days" },
  { days: 30, label: "30 Days" },
  { days: 90, label: "90 Days" },
];

export { LOCAL_SANDBOX_URL, ONE_DAY, TIME_FRAMES };

