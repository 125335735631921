export const isValidSubdomain = (value: any) => {
  console.log("value is", value);
  // The pattern requires at least one '.' to ensure it's a subdomain and not just a domain
  const pattern =
    /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+(?!-)[A-Za-z0-9-]{1,63}(?<!-)\.[A-Za-z]{2,6}$/;
  let result = pattern.test(value);
  console.log(`result is`, result);
  return result;
};

export const isValidPath = (path: any) => {
  const pattern = /^\/[A-Za-z0-9-_]+$/; // This ensures the path starts with a / followed by alphanumeric characters, hyphens, or underscores.
  return pattern.test(path);
};
