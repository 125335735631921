import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const UpvoteModal = ({ show, onHide, onUpvote }) => {
  const [tokenAmount, setTokenAmount] = useState(100);
  const [comment, setComment] = useState(null);

  const handleUpvote = () => {
    onUpvote(tokenAmount, comment);
    onHide(); // Hide modal after upvote
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upvote with Tokens</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formTokenAmount">
            <Form.Label>Token Amount</Form.Label>
            <Form.Control
              type="number"
              value={tokenAmount}
              onChange={(e) => setTokenAmount(Number(e.target.value))}
              min={0}
              step={50}
            />
          </Form.Group>
          <Form.Group controlId="upvoteComment">
            <Form.Label className="mt-2">Comment</Form.Label>
            <Form.Control
              as={"textarea"}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={3}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleUpvote}>
          Upvote
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpvoteModal;
