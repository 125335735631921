import { Card, Row, Col } from "react-bootstrap";
import AvatarImage from "./AvatarImage";
import { Draggable, Droppable } from "react-beautiful-dnd";

const Roadmap = ({ requests, upvote, onSelect, isAdmin }) => {
  console.log("isAdmin", isAdmin);
  return (
    <Row>
      {["Requested", "In Progress", "Completed"].map((status) => (
        <Droppable droppableId={status} key={status} isDropDisabled={!isAdmin}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ width: "33.33333%" }}
            >
              <Col style={{ marginTop: "20px" }}>
                <Card>
                  <Card.Header
                    style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)" }}
                  >
                    <h4 className="mt-2">{status}</h4>
                  </Card.Header>
                </Card>
                {/* Added a conditional style for the transition */}
                <div
                  style={{
                    transition: snapshot.isDraggingOver
                      ? "margin-top 0.2s ease"
                      : "none",
                  }}
                >
                  {(requests[status] || []).map((request, index) => (
                    <Draggable
                      key={request.id}
                      draggableId={request.id.toString()}
                      index={index}
                      isDropDisabled={!isAdmin}
                      isDragDisabled={!isAdmin}
                    >
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Card
                            className="my-2"
                            style={{
                              boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
                              overflow: "hidden",
                              cursor: "pointer",
                              // This will ensure that the dragged card doesn't jump up to the top, overlapping the header
                              marginTop: index === 0 ? "20px" : "10px",
                            }}
                          >
                            <Card.Body onClick={() => onSelect(request)}>
                              <Card.Title className="mb-1">
                                {request.title}
                              </Card.Title>
                              <div className="mt-1">
                                <AvatarImage
                                  className="avatar-img"
                                  src={request.poster.profile_picture}
                                  alt="poster avatar"
                                />
                                <span className="ms-1">
                                  {request.poster.name}
                                </span>
                              </div>
                              <div className="holographic-token mt-2">
                                <span style={{ fontWeight: "800" }}>
                                  {request?.tokens?.toLocaleString()}{" "}
                                </span>
                                tokens
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              </Col>
            </div>
          )}
        </Droppable>
      ))}
    </Row>
  );
};

export default Roadmap;
