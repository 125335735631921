const zipCodeData = [
    { lng: -174.19628, lat: 52.227555, postalCode: "99547" },
    { lng: -176.63675, lat: 51.87957, postalCode: "99546" },
    { lng: -169.55958, lat: 56.605546, postalCode: "99591" },
    { lng: -169.19469, lat: 52.785684, postalCode: "99638" },
    { lng: -166.52287, lat: 53.872017, postalCode: "99685" },
    { lng: -170.27203, lat: 57.130894, postalCode: "99660" },
    { lng: -166.5485, lat: 53.883729, postalCode: "99692" },
    { lng: -170.7716, lat: -7.209975, postalCode: "96799" },
    { lng: -158.03543, lat: 21.501722, postalCode: "96786" },
    { lng: -156.33158, lat: 20.865115, postalCode: "96768" },
    { lng: -157.92556, lat: 21.6485, postalCode: "96762" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96847" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96837" },
    { lng: -155.21012, lat: 19.447717, postalCode: "96785" },
    { lng: -155.78664, lat: 20.222668, postalCode: "96755" },
    { lng: -155.76114, lat: 19.101978, postalCode: "96737" },
    { lng: -155.25963, lat: 19.983584, postalCode: "96764" },
    { lng: -157.74772, lat: 21.439867, postalCode: "96863" },
    { lng: -155.2086, lat: 19.946758, postalCode: "96780" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96836" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96849" },
    { lng: -156.45769, lat: 20.771418, postalCode: "96753" },
    { lng: -157.87926, lat: 21.501854, postalCode: "96744" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96839" },
    { lng: -157.74529, lat: 21.3949, postalCode: "96734" },
    { lng: -155.10649, lat: 19.800318, postalCode: "96781" },
    { lng: -155.845329, lat: 19.278298, postalCode: "96726" },
    { lng: -159.7187, lat: 21.997746, postalCode: "96796" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96843" },
    { lng: -158.01389, lat: 21.403487, postalCode: "96797" },
    { lng: -156.34031, lat: 20.724391, postalCode: "96790" },
    { lng: -159.37746, lat: 21.98081, postalCode: "96766" },
    { lng: -155.88338, lat: 19.307543, postalCode: "96704" },
    { lng: -158.06446, lat: 21.4603, postalCode: "96759" },
    { lng: -155.18112, lat: 19.942301, postalCode: "96773" },
    { lng: -158.08174, lat: 21.3017, postalCode: "96862" },
    { lng: -155.243925, lat: 19.44707, postalCode: "96385" },
    { lng: -159.4896, lat: 22.208401, postalCode: "96714" },
    { lng: -157.80575, lat: 21.41345, postalCode: "96477" },
    { lng: -158.08587, lat: 21.345535, postalCode: "96707" },
    { lng: -156.46579, lat: 20.883951, postalCode: "96732" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96807" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96858" },
    { lng: -159.31765, lat: 22.148133, postalCode: "96703" },
    { lng: -155.78634, lat: 19.947774, postalCode: "96738" },
    { lng: -155.31153, lat: 19.996769, postalCode: "96774" },
    { lng: -157.95903, lat: 21.68762, postalCode: "96731" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96842" },
    { lng: -155.15249, lat: 19.857045, postalCode: "96710" },
    { lng: -156.646667, lat: 20.866638, postalCode: "96733" },
    { lng: -159.31283, lat: 22.111837, postalCode: "96751" },
    { lng: -159.4776, lat: 21.999721, postalCode: "96741" },
    { lng: -157.08588, lat: 21.168775, postalCode: "96729" },
    { lng: -159.512392, lat: 21.979991, postalCode: "96715" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96898" },
    { lng: -155.37514, lat: 20.022513, postalCode: "96776" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96812" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96838" },
    { lng: -158.02499, lat: 21.338055, postalCode: "96706" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96845" },
    { lng: -155.238743, lat: 19.433152, postalCode: "96885" },
    { lng: -157.03622, lat: 21.156174, postalCode: "96757" },
    { lng: -155.26648, lat: 19.435739, postalCode: "96718" },
    { lng: -159.49025, lat: 22.223635, postalCode: "96722" },
    { lng: -159.499342, lat: 21.930922, postalCode: "96765" },
    { lng: -157.70599, lat: 21.33743, postalCode: "96795" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96828" },
    { lng: -155.84459, lat: 20.241109, postalCode: "96719" },
    { lng: -157.85108, lat: 21.554467, postalCode: "96730" },
    { lng: -159.71766, lat: 21.97313, postalCode: "96752" },
    { lng: -159.40787, lat: 22.212733, postalCode: "96754" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96811" },
    { lng: -157.88165, lat: 21.3452, postalCode: "96819" },
    { lng: -156.646667, lat: 20.866638, postalCode: "96767" },
    { lng: -159.65981, lat: 21.946244, postalCode: "96769" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96827" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96848" },
    { lng: -157.84777, lat: 21.300167, postalCode: "96814" },
    { lng: -157.69721, lat: 21.2946, postalCode: "96825" },
    { lng: -156.646667, lat: 20.866638, postalCode: "96784" },
    { lng: -157.1977, lat: 21.15058, postalCode: "96770" },
    { lng: -155.05686, lat: 19.5604, postalCode: "96760" },
    { lng: -157.9669, lat: 21.408333, postalCode: "96782" },
    { lng: -159.62428, lat: 21.920292, postalCode: "96747" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96861" },
    { lng: -155.11305, lat: 19.516132, postalCode: "96771" },
    { lng: -155.51679, lat: 20.089087, postalCode: "96727" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96835" },
    { lng: -157.85913, lat: 21.3307, postalCode: "96817" },
    { lng: -155.97588, lat: 19.675386, postalCode: "96740" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96804" },
    { lng: -154.9266, lat: 19.494625, postalCode: "96778" },
    { lng: -155.49217, lat: 19.226749, postalCode: "96777" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96809" },
    { lng: -155.438015, lat: 19.593456, postalCode: "96775" },
    { lng: -158.07448, lat: 21.630633, postalCode: "96712" },
    { lng: -156.38202, lat: 20.906999, postalCode: "96779" },
    { lng: -157.75456, lat: 21.287967, postalCode: "96821" },
    { lng: -155.58738, lat: 19.110687, postalCode: "96772" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96810" },
    { lng: -164.701172, lat: 23.573339, postalCode: "96709" },
    { lng: -156.9818, lat: 21.191665, postalCode: "96742" },
    { lng: -155.438015, lat: 19.593456, postalCode: "96745" },
    { lng: -159.59407, lat: 21.917153, postalCode: "96716" },
    { lng: -157.996233, lat: 21.703, postalCode: "96371" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96844" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96820" },
    { lng: -156.9741, lat: 21.111246, postalCode: "96748" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96830" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96802" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96853" },
    { lng: -155.798594, lat: 19.112099, postalCode: "96373" },
    { lng: -156.03893, lat: 20.739217, postalCode: "96713" },
    { lng: -158.13489, lat: 21.572131, postalCode: "96791" },
    { lng: -157.93091, lat: 21.389167, postalCode: "96701" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96857" },
    { lng: -157.83066, lat: 21.2951, postalCode: "96826" },
    { lng: -156.50497, lat: 20.889294, postalCode: "96793" },
    { lng: -157.82195, lat: 21.31275, postalCode: "96822" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96824" },
    { lng: -155.9215, lat: 19.526845, postalCode: "96750" },
    { lng: -159.58222, lat: 21.910767, postalCode: "96705" },
    { lng: -156.325923, lat: 20.855864, postalCode: "96788" },
    { lng: -155.12172, lat: 19.866007, postalCode: "96728" },
    { lng: -157.96316, lat: 21.36425, postalCode: "96818" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96846" },
    { lng: -155.09842, lat: 19.713562, postalCode: "96720" },
    { lng: -155.10548, lat: 19.843259, postalCode: "96783" },
    { lng: -156.27315, lat: 20.899634, postalCode: "96708" },
    { lng: -157.90949, lat: 21.60625, postalCode: "96717" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96801" },
    { lng: -155.438015, lat: 19.593456, postalCode: "96721" },
    { lng: -158.17092, lat: 21.434246, postalCode: "96792" },
    { lng: -155.7478, lat: 19.410727, postalCode: "96725" },
    { lng: -155.438015, lat: 19.593456, postalCode: "96739" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96806" },
    { lng: -156.65765, lat: 20.913335, postalCode: "96761" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96823" },
    { lng: -156.9274, lat: 20.823607, postalCode: "96763" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96841" },
    { lng: -155.01121, lat: 19.625495, postalCode: "96749" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96805" },
    { lng: -158.0116, lat: 21.459206, postalCode: "96789" },
    { lng: -157.85615, lat: 21.313033, postalCode: "96813" },
    { lng: -155.69999, lat: 20.040191, postalCode: "96743" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96840" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96860" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96803" },
    { lng: -159.48044, lat: 21.910013, postalCode: "96756" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96859" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96850" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96854" },
    { lng: -159.34463, lat: 22.077472, postalCode: "96746" },
    { lng: -157.79925, lat: 21.284, postalCode: "96816" },
    { lng: -157.82199, lat: 21.2782, postalCode: "96815" },
    { lng: -168.021815, lat: 24.859832, postalCode: "96808" },
    { lng: -166.09619, lat: 61.52898, postalCode: "99604" },
    { lng: -149.74633, lat: 61.204466, postalCode: "99504" },
    { lng: -151.06473, lat: 60.784804, postalCode: "99635" },
    { lng: -131.46633, lat: 55.875767, postalCode: "99950" },
    { lng: -165.53972, lat: 61.783788, postalCode: "99662" },
    { lng: -156.88828, lat: 66.908604, postalCode: "99751" },
    { lng: -150.50318, lat: 65.021337, postalCode: "99756" },
    { lng: -162.43712, lat: 60.898967, postalCode: "99641" },
    { lng: -158.88072, lat: 61.570981, postalCode: "99557" },
    { lng: -134.57222, lat: 57.48765, postalCode: "99820" },
    { lng: -148.02197, lat: 64.842326, postalCode: "99725" },
    { lng: -162.8834, lat: 61.93605, postalCode: "99650" },
    { lng: -149.77311, lat: 61.101142, postalCode: "99516" },
    { lng: -166.51023, lat: 65.342744, postalCode: "99785" },
    { lng: -151.3934, lat: 60.23458, postalCode: "99568" },
    { lng: -149.62708, lat: 60.911003, postalCode: "99605" },
    { lng: -149.808426, lat: 61.203954, postalCode: "99512" },
    { lng: -145.52768, lat: 68.121828, postalCode: "99722" },
    { lng: -141.87489, lat: 64.070738, postalCode: "99732" },
    { lng: -161.59999, lat: 59.125873, postalCode: "99589" },
    { lng: -146.898195, lat: 64.874591, postalCode: "99716" },
    { lng: -149.88335, lat: 61.156565, postalCode: "99518" },
    { lng: -160.50353, lat: 59.101934, postalCode: "99678" },
    { lng: -152.46085, lat: 57.929664, postalCode: "99644" },
    { lng: -149.88617, lat: 61.214877, postalCode: "99513" },
    { lng: -150.57485, lat: 61.402544, postalCode: "99695" },
    { lng: -170.4087, lat: 63.686038, postalCode: "99769" },
    { lng: -154.25703, lat: 66.044094, postalCode: "99745" },
    { lng: -160.76294, lat: 63.882081, postalCode: "99684" },
    { lng: -153.40755, lat: 57.564289, postalCode: "99697" },
    { lng: -157.01746, lat: 58.71163, postalCode: "99670" },
    { lng: -149.89098, lat: 61.556311, postalCode: "99652" },
    { lng: -139.71052, lat: 59.538728, postalCode: "99689" },
    { lng: -132.55624, lat: 55.677232, postalCode: "99919" },
    { lng: -143.62436, lat: 70.13346, postalCode: "99747" },
    { lng: -151.54194, lat: 59.992821, postalCode: "99639" },
    { lng: -151.51587, lat: 61.898872, postalCode: "99667" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99523" },
    { lng: -161.4219, lat: 60.810509, postalCode: "99621" },
    { lng: -157.34841, lat: 58.217621, postalCode: "99579" },
    { lng: -149.87828, lat: 61.216799, postalCode: "99501" },
    { lng: -161.88006, lat: 60.766603, postalCode: "99559" },
    { lng: -161.36476, lat: 61.785228, postalCode: "99657" },
    { lng: -157.15202, lat: 61.683931, postalCode: "99668" },
    { lng: -147.856443, lat: 64.947462, postalCode: "99708" },
    { lng: -158.44407, lat: 59.068709, postalCode: "99576" },
    { lng: -145.40256, lat: 60.474512, postalCode: "99574" },
    { lng: -149.82865, lat: 61.154834, postalCode: "99507" },
    { lng: -133.02648, lat: 55.456449, postalCode: "99921" },
    { lng: -130.0545, lat: 55.941442, postalCode: "99923" },
    { lng: -134.197786, lat: 58.38348, postalCode: "99811" },
    { lng: -148.83396, lat: 63.657441, postalCode: "99755" },
    { lng: -165.57029, lat: 61.527953, postalCode: "99563" },
    { lng: -160.96325, lat: 61.102912, postalCode: "99679" },
    { lng: -158.53502, lat: 56.307535, postalCode: "99565" },
    { lng: -147.535549, lat: 64.948648, postalCode: "99710" },
    { lng: -165.10883, lat: 64.532616, postalCode: "99762" },
    { lng: -135.20777, lat: 57.777622, postalCode: "99841" },
    { lng: -149.66336, lat: 61.261518, postalCode: "99505" },
    { lng: -152.6559, lat: 66.557586, postalCode: "99720" },
    { lng: -162.66587, lat: 60.343879, postalCode: "99680" },
    { lng: -147.405134, lat: 64.699839, postalCode: "99707" },
    { lng: -146.91648, lat: 64.464105, postalCode: "99714" },
    { lng: -131.56883, lat: 55.123897, postalCode: "99926" },
    { lng: -156.95331, lat: 58.745436, postalCode: "99633" },
    { lng: -149.42644, lat: 61.413717, postalCode: "99567" },
    { lng: -149.51689, lat: 61.310222, postalCode: "99577" },
    { lng: -149.41271, lat: 60.151652, postalCode: "99664" },
    { lng: -133.07316, lat: 55.555164, postalCode: "99925" },
    { lng: -143.35699, lat: 63.376206, postalCode: "99776" },
    { lng: -132.33822, lat: 56.409507, postalCode: "99929" },
    { lng: -131.67537, lat: 55.395359, postalCode: "99928" },
    { lng: -150.74033, lat: 60.532394, postalCode: "99672" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99521" },
    { lng: -162.634, lat: 55.315003, postalCode: "99571" },
    { lng: -155.48769, lat: 64.737306, postalCode: "99768" },
    { lng: -160.43016, lat: 66.97172, postalCode: "99749" },
    { lng: -163.00861, lat: 69.741023, postalCode: "99759" },
    { lng: -154.36255, lat: 57.578081, postalCode: "99608" },
    { lng: -149.89341, lat: 61.19026, postalCode: "99503" },
    { lng: -135.40874, lat: 58.411303, postalCode: "99850" },
    { lng: -161.42393, lat: 60.88981, postalCode: "99551" },
    { lng: -156.38713, lat: 65.701858, postalCode: "99746" },
    { lng: -152.51142, lat: 57.74663, postalCode: "99615" },
    { lng: -151.02746, lat: 70.215197, postalCode: "99789" },
    { lng: -142.5323, lat: 63.135294, postalCode: "99779" },
    { lng: -164.65455, lat: 62.683391, postalCode: "99554" },
    { lng: -146.68013, lat: 60.863764, postalCode: "99677" },
    { lng: -160.06675, lat: 62.90813, postalCode: "99590" },
    { lng: -156.64007, lat: 58.687446, postalCode: "99613" },
    { lng: -132.827948, lat: 56.53595, postalCode: "99836" },
    { lng: -149.99002, lat: 61.391728, postalCode: "99687" },
    { lng: -154.55708, lat: 60.102331, postalCode: "99653" },
    { lng: -149.39156, lat: 65.152583, postalCode: "99758" },
    { lng: -149.87542, lat: 62.298254, postalCode: "99676" },
    { lng: -147.6265, lat: 64.827164, postalCode: "99703" },
    { lng: -149.78153, lat: 60.484146, postalCode: "99572" },
    { lng: -141.19257, lat: 64.778099, postalCode: "99738" },
    { lng: -162.58544, lat: 66.897192, postalCode: "99752" },
    { lng: -157.41805, lat: 70.477663, postalCode: "99791" },
    { lng: -151.14169, lat: 61.067777, postalCode: "99682" },
    { lng: -149.93438, lat: 61.188276, postalCode: "99517" },
    { lng: -158.74638, lat: 56.252156, postalCode: "99548" },
    { lng: -150.07792, lat: 61.796774, postalCode: "99688" },
    { lng: -151.28948, lat: 60.318451, postalCode: "99610" },
    { lng: -157.31368, lat: 59.452339, postalCode: "99636" },
    { lng: -147.02121, lat: 64.661627, postalCode: "99702" },
    { lng: -145.58592, lat: 62.12042, postalCode: "99588" },
    { lng: -164.81976, lat: 60.527618, postalCode: "99690" },
    { lng: -166.35474, lat: 65.250432, postalCode: "99778" },
    { lng: -154.00286, lat: 57.529161, postalCode: "99624" },
    { lng: -163.16752, lat: 59.870295, postalCode: "99622" },
    { lng: -165.88176, lat: 54.098693, postalCode: "99553" },
    { lng: -132.25002, lat: 55.742045, postalCode: "99903" },
    { lng: -159.77341, lat: 62.198048, postalCode: "99602" },
    { lng: -157.39907, lat: 57.508178, postalCode: "99649" },
    { lng: -151.23355, lat: 60.587598, postalCode: "99611" },
    { lng: -158.55254, lat: 58.83256, postalCode: "99569" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99519" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99524" },
    { lng: -163.42011, lat: 54.849999, postalCode: "99583" },
    { lng: -149.878418, lat: 61.144568, postalCode: "99510" },
    { lng: -149.77461, lat: 61.224384, postalCode: "99506" },
    { lng: -145.2308, lat: 61.832347, postalCode: "99573" },
    { lng: -154.85008, lat: 59.971286, postalCode: "99640" },
    { lng: -155.797078, lat: 58.268704, postalCode: "99619" },
    { lng: -165.10529, lat: 60.532936, postalCode: "99637" },
    { lng: -147.87406, lat: 64.854937, postalCode: "99709" },
    { lng: -160.01772, lat: 66.597998, postalCode: "99770" },
    { lng: -151.4493, lat: 59.665074, postalCode: "99603" },
    { lng: -153.30438, lat: 57.19926, postalCode: "99643" },
    { lng: -131.6762, lat: 55.368644, postalCode: "99901" },
    { lng: -161.81529, lat: 59.00689, postalCode: "99651" },
    { lng: -152.08258, lat: 65.172029, postalCode: "99777" },
    { lng: -164.29917, lat: 62.729019, postalCode: "99581" },
    { lng: -135.6122, lat: 59.279037, postalCode: "99827" },
    { lng: -147.38884, lat: 64.826445, postalCode: "99711" },
    { lng: -149.17778, lat: 64.289856, postalCode: "99704" },
    { lng: -134.617383, lat: 58.377074, postalCode: "99803" },
    { lng: -141.82852, lat: 62.980725, postalCode: "99764" },
    { lng: -147.39743, lat: 66.359719, postalCode: "99724" },
    { lng: -163.1937, lat: 62.048263, postalCode: "99658" },
    { lng: -154.10833, lat: 59.781241, postalCode: "99647" },
    { lng: -162.03841, lat: 63.477448, postalCode: "99659" },
    { lng: -161.22577, lat: 60.911865, postalCode: "99552" },
    { lng: -149.4583, lat: 60.973454, postalCode: "99540" },
    { lng: -162.3124, lat: 55.063736, postalCode: "99612" },
    { lng: -157.14287, lat: 66.887401, postalCode: "99773" },
    { lng: -151.025085, lat: 65.226375, postalCode: "99584" },
    { lng: -134.700348, lat: 58.449413, postalCode: "99821" },
    { lng: -158.73204, lat: 64.324887, postalCode: "99748" },
    { lng: -151.02091, lat: 60.489536, postalCode: "99669" },
    { lng: -171.70088, lat: 63.776098, postalCode: "99742" },
    { lng: -157.70439, lat: 64.881745, postalCode: "99754" },
    { lng: -163.72393, lat: 62.090075, postalCode: "99632" },
    { lng: -147.937747, lat: 64.852055, postalCode: "99706" },
    { lng: -158.12888, lat: 61.859821, postalCode: "99575" },
    { lng: -152.30201, lat: 63.893902, postalCode: "99757" },
    { lng: -164.97188, lat: 62.495675, postalCode: "99666" },
    { lng: -134.39781, lat: 58.276192, postalCode: "99824" },
    { lng: -156.84802, lat: 59.116273, postalCode: "99625" },
    { lng: -136.22032, lat: 57.959709, postalCode: "99832" },
    { lng: -154.41957, lat: 62.999213, postalCode: "99691" },
    { lng: -161.16785, lat: 64.343258, postalCode: "99771" },
    { lng: -145.28231, lat: 66.566652, postalCode: "99740" },
    { lng: -146.27598, lat: 64.856055, postalCode: "99790" },
    { lng: -132.82018, lat: 56.009124, postalCode: "99918" },
    { lng: -161.12225, lat: 65.979144, postalCode: "99727" },
    { lng: -150.1636, lat: 65.507883, postalCode: "99767" },
    { lng: -145.4623, lat: 63.978355, postalCode: "99737" },
    { lng: -157.3437, lat: 61.810587, postalCode: "99656" },
    { lng: -159.15591, lat: 55.91076, postalCode: "99648" },
    { lng: -144.14233, lat: 61.555701, postalCode: "99566" },
    { lng: -149.8144, lat: 61.203953, postalCode: "99508" },
    { lng: -162.71822, lat: 66.075713, postalCode: "99736" },
    { lng: -160.21336, lat: 62.632905, postalCode: "99558" },
    { lng: -164.54522, lat: 67.733313, postalCode: "99750" },
    { lng: -159.53113, lat: 62.655998, postalCode: "99665" },
    { lng: -149.10712, lat: 64.010218, postalCode: "99743" },
    { lng: -146.2357, lat: 61.128176, postalCode: "99686" },
    { lng: -166.76291, lat: 68.347052, postalCode: "99766" },
    { lng: -142.93704, lat: 63.341767, postalCode: "99780" },
    { lng: -133.9323, lat: 56.975011, postalCode: "99830" },
    { lng: -162.08481, lat: 61.878073, postalCode: "99585" },
    { lng: -163.76142, lat: 62.945434, postalCode: "99620" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99514" },
    { lng: -150.37044, lat: 62.415294, postalCode: "99683" },
    { lng: -132.97354, lat: 56.784206, postalCode: "99833" },
    { lng: -157.86225, lat: 67.083658, postalCode: "99786" },
    { lng: -149.10303, lat: 66.005296, postalCode: "99774" },
    { lng: -160.37019, lat: 61.518175, postalCode: "99626" },
    { lng: -146.41736, lat: 67.015569, postalCode: "99781" },
    { lng: -151.73566, lat: 59.798363, postalCode: "99556" },
    { lng: -160.02481, lat: 70.640902, postalCode: "99782" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99509" },
    { lng: -149.880393, lat: 61.187188, postalCode: "99599" },
    { lng: -144.71982, lat: 65.551022, postalCode: "99730" },
    { lng: -161.03664, lat: 66.83713, postalCode: "99763" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99522" },
    { lng: -135.73088, lat: 58.434052, postalCode: "99826" },
    { lng: -160.30165, lat: 61.535511, postalCode: "99607" },
    { lng: -158.62458, lat: 59.269535, postalCode: "99555" },
    { lng: -154.8124, lat: 59.748806, postalCode: "99606" },
    { lng: -149.95932, lat: 61.153693, postalCode: "99502" },
    { lng: -149.04109, lat: 61.598203, postalCode: "99645" },
    { lng: -161.15597, lat: 64.932225, postalCode: "99753" },
    { lng: -162.5196, lat: 60.873178, postalCode: "99609" },
    { lng: -149.0838, lat: 60.952049, postalCode: "99587" },
    { lng: -133.60689, lat: 56.337957, postalCode: "99927" },
    { lng: -152.88568, lat: 57.867089, postalCode: "99550" },
    { lng: -151.71513, lat: 59.422698, postalCode: "99663" },
    { lng: -144.06388, lat: 65.796306, postalCode: "99733" },
    { lng: -157.4838, lat: 59.360233, postalCode: "99580" },
    { lng: -160.48813, lat: 55.311735, postalCode: "99661" },
    { lng: -134.54749, lat: 58.358599, postalCode: "99801" },
    { lng: -149.40247, lat: 60.499602, postalCode: "99631" },
    { lng: -162.27718, lat: 64.620513, postalCode: "99739" },
    { lng: -162.27463, lat: 63.511893, postalCode: "99671" },
    { lng: -156.89456, lat: 64.739082, postalCode: "99741" },
    { lng: -150.719924, lat: 60.516935, postalCode: "99699" },
    { lng: -164.04392, lat: 59.937619, postalCode: "99614" },
    { lng: -148.69632, lat: 61.751348, postalCode: "99674" },
    { lng: -144.49059, lat: 62.667589, postalCode: "99586" },
    { lng: -135.3227, lat: 57.064376, postalCode: "99835" },
    { lng: -149.88852, lat: 61.122943, postalCode: "99515" },
    { lng: -147.72045, lat: 64.83507, postalCode: "99701" },
    { lng: -136.27833, lat: 58.068878, postalCode: "99825" },
    { lng: -147.3649, lat: 64.777233, postalCode: "99705" },
    { lng: -158.4044, lat: 56.298297, postalCode: "99564" },
    { lng: -147.82681, lat: 64.860846, postalCode: "99775" },
    { lng: -148.54211, lat: 60.639257, postalCode: "99693" },
    { lng: -149.094699, lat: 61.586896, postalCode: "99646" },
    { lng: -148.39873, lat: 63.255516, postalCode: "99729" },
    { lng: -135.43154, lat: 58.109608, postalCode: "99829" },
    { lng: -165.73202, lat: 66.259417, postalCode: "99772" },
    { lng: -161.90079, lat: 59.754016, postalCode: "99655" },
    { lng: -164.25283, lat: 60.143735, postalCode: "99561" },
    { lng: -159.0558, lat: 58.981087, postalCode: "99628" },
    { lng: -135.31355, lat: 59.465159, postalCode: "99840" },
    { lng: -158.11178, lat: 64.730035, postalCode: "99765" },
    { lng: -156.10264, lat: 62.969642, postalCode: "99675" },
    { lng: -151.50508, lat: 66.917381, postalCode: "99726" },
    { lng: -153.993988, lat: 66.693255, postalCode: "99734" },
    { lng: -149.48821, lat: 61.580187, postalCode: "99654" },
    { lng: -147.49573, lat: 64.926041, postalCode: "99712" },
    { lng: -134.58969, lat: 58.405657, postalCode: "99802" },
    { lng: -168.08928, lat: 65.612116, postalCode: "99783" },
    { lng: -132.82545, lat: 55.209339, postalCode: "99922" },
    { lng: -149.07674, lat: 64.560888, postalCode: "99760" },
    { lng: -162.03799, lat: 60.214239, postalCode: "99578" },
    { lng: -151.72854, lat: 68.148216, postalCode: "99721" },
    { lng: -165.25645, lat: 60.582127, postalCode: "99681" },
    { lng: -163.44257, lat: 64.684323, postalCode: "99784" },
    { lng: -149.800476, lat: 61.068324, postalCode: "99511" },
    { lng: -149.19265, lat: 64.349263, postalCode: "99744" },
    { lng: -166.28601, lat: 60.364246, postalCode: "99630" },
    { lng: -162.97508, lat: 67.572031, postalCode: "99761" },
    { lng: -149.79507, lat: 61.629635, postalCode: "99694" },
    { lng: -158.62832, lat: 56.946256, postalCode: "99549" },
    { lng: -143.72717, lat: 66.653222, postalCode: "99788" },
    { lng: -155.57265, lat: 62.94914, postalCode: "99627" },
    { lng: -149.440311, lat: 61.108864, postalCode: "99520" },
    { lng: -156.74891, lat: 71.299525, postalCode: "99723" },
    { lng: -161.9909, lat: 60.695049, postalCode: "99634" },
    { lng: -122.6943, lat: 38.017617, postalCode: "94938" },
    { lng: -114.62664, lat: 34.808718, postalCode: "92363" },
    { lng: -122.40008, lat: 41.736424, postalCode: "96064" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94285" },
    { lng: -122.39348, lat: 37.732797, postalCode: "94124" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91507" },
    { lng: -117.1974, lat: 33.70602, postalCode: "92586" },
    { lng: -117.63618, lat: 35.366197, postalCode: "93528" },
    { lng: -121.82077, lat: 37.369473, postalCode: "95127" },
    { lng: -116.41246, lat: 34.161795, postalCode: "92284" },
    { lng: -120.74287, lat: 38.545656, postalCode: "95675" },
    { lng: -119.38925, lat: 35.305512, postalCode: "93276" },
    { lng: -117.44695, lat: 33.998751, postalCode: "92509" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90251" },
    { lng: -117.24833, lat: 33.957957, postalCode: "92557" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92899" },
    { lng: -117.81828, lat: 33.762117, postalCode: "92705" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91309" },
    { lng: -121.49021, lat: 41.056097, postalCode: "96028" },
    { lng: -121.46597, lat: 36.581329, postalCode: "93925" },
    { lng: -120.650351, lat: 37.186872, postalCode: "95341" },
    { lng: -118.23944, lat: 34.061611, postalCode: "90012" },
    { lng: -117.07077, lat: 33.106862, postalCode: "92025" },
    { lng: -120.72614, lat: 37.38025, postalCode: "95334" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94246" },
    { lng: -117.37074, lat: 33.991181, postalCode: "92501" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94099" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91497" },
    { lng: -121.37809, lat: 38.587805, postalCode: "95864" },
    { lng: -122.25605, lat: 38.170372, postalCode: "94503" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92072" },
    { lng: -120.66525, lat: 37.420143, postalCode: "95312" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94522" },
    { lng: -118.50781, lat: 35.132673, postalCode: "93561" },
    { lng: -119.01935, lat: 35.381408, postalCode: "93301" },
    { lng: -118.32364, lat: 34.169663, postalCode: "91506" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92685" },
    { lng: -124.075673, lat: 40.878317, postalCode: "95522" },
    { lng: -122.2859, lat: 37.865026, postalCode: "94702" },
    { lng: -122.128, lat: 37.703427, postalCode: "94578" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90078" },
    { lng: -121.96066, lat: 37.39324, postalCode: "95054" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94978" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94125" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92090" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94279" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94570" },
    { lng: -121.05649, lat: 37.413659, postalCode: "95313" },
    { lng: -121.9846, lat: 37.346241, postalCode: "95051" },
    { lng: -118.17568, lat: 34.08166, postalCode: "90032" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91372" },
    { lng: -118.395107, lat: 33.845064, postalCode: "90276" },
    { lng: -120.61094, lat: 37.400193, postalCode: "95388" },
    { lng: -120.937052, lat: 37.60396, postalCode: "95390" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92552" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94159" },
    { lng: -122.213, lat: 37.374421, postalCode: "94028" },
    { lng: -114.652517, lat: 33.59869, postalCode: "92226" },
    { lng: -120.45458, lat: 38.146124, postalCode: "95247" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95137" },
    { lng: -118.06821, lat: 33.933551, postalCode: "90670" },
    { lng: -117.89559, lat: 33.923463, postalCode: "92821" },
    { lng: -119.31968, lat: 38.21053, postalCode: "93517" },
    { lng: -124.21494, lat: 40.272963, postalCode: "95558" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94138" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91797" },
    { lng: -118.958777, lat: 36.734051, postalCode: "93633" },
    { lng: -121.32282, lat: 38.132618, postalCode: "95242" },
    { lng: -123.79166, lat: 39.311858, postalCode: "95460" },
    { lng: -118.84655, lat: 35.161923, postalCode: "93203" },
    { lng: -120.9141, lat: 37.5596, postalCode: "95328" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94160" },
    { lng: -120.90294, lat: 41.190742, postalCode: "96006" },
    { lng: -118.34015, lat: 34.048411, postalCode: "90019" },
    { lng: -123.53267, lat: 40.413386, postalCode: "95526" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93762" },
    { lng: -118.38752, lat: 33.784099, postalCode: "90274" },
    { lng: -118.00243, lat: 33.673468, postalCode: "92648" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91408" },
    { lng: -119.76358, lat: 36.825582, postalCode: "93710" },
    { lng: -121.75446, lat: 37.299474, postalCode: "95135" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93755" },
    { lng: -123.79238, lat: 39.727052, postalCode: "95488" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94524" },
    { lng: -118.32993, lat: 34.201254, postalCode: "91504" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94808" },
    { lng: -122.04577, lat: 37.592184, postalCode: "94587" },
    { lng: -119.000391, lat: 34.819232, postalCode: "93325" },
    { lng: -117.92985, lat: 33.869114, postalCode: "92832" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95196" },
    { lng: -118.19225, lat: 33.769018, postalCode: "90802" },
    { lng: -118.12901, lat: 33.942289, postalCode: "90241" },
    { lng: -119.52006, lat: 37.232047, postalCode: "93643" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95269" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95170" },
    { lng: -122.57667, lat: 39.535446, postalCode: "95939" },
    { lng: -121.9192, lat: 38.277661, postalCode: "94535" },
    { lng: -118.86296, lat: 36.431565, postalCode: "93271" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90009" },
    { lng: -117.71973, lat: 34.110009, postalCode: "91711" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91226" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94557" },
    { lng: -120.23924, lat: 41.688163, postalCode: "96115" },
    { lng: -116.51887, lat: 33.798669, postalCode: "92264" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91187" },
    { lng: -117.03049, lat: 33.142706, postalCode: "92027" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93199" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90507" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92375" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90612" },
    { lng: -122.2255, lat: 40.562648, postalCode: "96073" },
    { lng: -121.88627, lat: 37.348541, postalCode: "95112" },
    { lng: -121.3237, lat: 38.004172, postalCode: "95207" },
    { lng: -122.48014, lat: 39.861821, postalCode: "96029" },
    { lng: -119.976707, lat: 38.934409, postalCode: "96157" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91210" },
    { lng: -117.216451, lat: 32.733507, postalCode: "92133" },
    { lng: -117.31995, lat: 34.061931, postalCode: "92324" },
    { lng: -120.99955, lat: 37.626067, postalCode: "95351" },
    { lng: -118.1865, lat: 34.045161, postalCode: "90063" },
    { lng: -118.43643, lat: 34.063209, postalCode: "90024" },
    { lng: -121.71162, lat: 39.413477, postalCode: "95917" },
    { lng: -121.31354, lat: 39.441356, postalCode: "95972" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91191" },
    { lng: -117.01273, lat: 32.761801, postalCode: "91941" },
    { lng: -123.72444, lat: 41.161505, postalCode: "95546" },
    { lng: -120.42843, lat: 41.268746, postalCode: "96116" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95194" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92811" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95172" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94258" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95013" },
    { lng: -121.83501, lat: 40.504853, postalCode: "96088" },
    { lng: -123.66468, lat: 38.996389, postalCode: "95459" },
    { lng: -116.82962, lat: 34.244448, postalCode: "92386" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92074" },
    { lng: -122.63707, lat: 40.472993, postalCode: "96047" },
    { lng: -118.54472, lat: 34.164255, postalCode: "91356" },
    { lng: -117.25038, lat: 33.692876, postalCode: "92587" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92702" },
    { lng: -122.04572, lat: 39.254875, postalCode: "95932" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92825" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92169" },
    { lng: -122.96832, lat: 41.414707, postalCode: "96027" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91371" },
    { lng: -118.44167, lat: 33.957012, postalCode: "90293" },
    { lng: -121.17225, lat: 38.817508, postalCode: "95650" },
    { lng: -121.132928, lat: 36.35433, postalCode: "93902" },
    { lng: -122.74976, lat: 38.480494, postalCode: "95403" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91903" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94031" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95671" },
    { lng: -120.95775, lat: 38.192911, postalCode: "95254" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92369" },
    { lng: -118.37099, lat: 33.986212, postalCode: "90056" },
    { lng: -120.964275, lat: 39.167276, postalCode: "95712" },
    { lng: -117.99596, lat: 33.803565, postalCode: "90680" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92709" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91333" },
    { lng: -122.16605, lat: 37.397823, postalCode: "94304" },
    { lng: -119.75496, lat: 34.44005, postalCode: "93105" },
    { lng: -118.28813, lat: 33.870466, postalCode: "90248" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92049" },
    { lng: -116.71187, lat: 32.616539, postalCode: "91917" },
    { lng: -122.65043, lat: 37.903396, postalCode: "94970" },
    { lng: -118.11337, lat: 33.847265, postalCode: "90713" },
    { lng: -121.831299, lat: 40.036998, postalCode: "95771" },
    { lng: -117.769442, lat: 33.640302, postalCode: "90624" },
    { lng: -119.24027, lat: 36.358928, postalCode: "93292" },
    { lng: -116.95413, lat: 32.662563, postalCode: "91914" },
    { lng: -117.69938, lat: 34.07041, postalCode: "91763" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92415" },
    { lng: -120.88947, lat: 35.459347, postalCode: "93430" },
    { lng: -123.07218, lat: 41.734832, postalCode: "96085" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91413" },
    { lng: -117.47884, lat: 33.771548, postalCode: "92883" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90313" },
    { lng: -118.42843, lat: 34.03457, postalCode: "90064" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91310" },
    { lng: -119.24273, lat: 36.565252, postalCode: "93647" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91341" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94309" },
    { lng: -120.27185, lat: 35.636185, postalCode: "93461" },
    { lng: -120.042996, lat: 38.844909, postalCode: "96155" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93421" },
    { lng: -118.382212, lat: 34.175205, postalCode: "91671" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95860" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95611" },
    { lng: -122.02488, lat: 37.856683, postalCode: "94507" },
    { lng: -121.99929, lat: 38.744062, postalCode: "95627" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94252" },
    { lng: -118.44527, lat: 34.045006, postalCode: "90025" },
    { lng: -121.45433, lat: 38.727451, postalCode: "95626" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95297" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92366" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94942" },
    { lng: -122.50991, lat: 37.52489, postalCode: "94038" },
    { lng: -120.05956, lat: 38.823996, postalCode: "95721" },
    { lng: -119.2249, lat: 34.268736, postalCode: "93003" },
    { lng: -121.28541, lat: 37.816876, postalCode: "95330" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90733" },
    { lng: -120.63645, lat: 35.818054, postalCode: "93451" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92166" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94165" },
    { lng: -118.3252, lat: 33.960263, postalCode: "90305" },
    { lng: -121.34361, lat: 38.66152, postalCode: "95841" },
    { lng: -121.8544, lat: 37.34964, postalCode: "95116" },
    { lng: -121.49468, lat: 39.491076, postalCode: "95966" },
    { lng: -117.59167, lat: 33.866555, postalCode: "92882" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92521" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93744" },
    { lng: -116.408249, lat: 33.660374, postalCode: "92261" },
    { lng: -118.27824, lat: 35.614134, postalCode: "93283" },
    { lng: -118.00509, lat: 33.725167, postalCode: "92647" },
    { lng: -121.665205, lat: 37.972622, postalCode: "94548" },
    { lng: -115.23936, lat: 35.00224, postalCode: "92332" },
    { lng: -118.22857, lat: 34.189605, postalCode: "91208" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95853" },
    { lng: -117.40937, lat: 33.926315, postalCode: "92504" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94120" },
    { lng: -121.49623, lat: 38.616891, postalCode: "95833" },
    { lng: -118.23104, lat: 34.211494, postalCode: "91020" },
    { lng: -124.08052, lat: 40.814595, postalCode: "95502" },
    { lng: -121.63997, lat: 36.709717, postalCode: "93906" },
    { lng: -118.51489, lat: 34.377782, postalCode: "91321" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93002" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91896" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91184" },
    { lng: -118.16141, lat: 35.04535, postalCode: "93501" },
    { lng: -120.83468, lat: 35.313222, postalCode: "93402" },
    { lng: -122.1047, lat: 37.10548, postalCode: "95007" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92546" },
    { lng: -116.906215, lat: 34.201638, postalCode: "92312" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94537" },
    { lng: -118.2975, lat: 33.891265, postalCode: "90247" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92572" },
    { lng: -121.95213, lat: 37.75735, postalCode: "94583" },
    { lng: -121.96672, lat: 37.838744, postalCode: "94528" },
    { lng: -117.234701, lat: 33.198032, postalCode: "92002" },
    { lng: -122.78548, lat: 38.939725, postalCode: "95451" },
    { lng: -118.35868, lat: 33.955913, postalCode: "90301" },
    { lng: -120.12855, lat: 36.40263, postalCode: "93624" },
    { lng: -117.93406, lat: 33.785166, postalCode: "92840" },
    { lng: -117.769442, lat: 33.640302, postalCode: "90622" },
    { lng: -122.54134, lat: 37.898058, postalCode: "94941" },
    { lng: -118.41183, lat: 34.056709, postalCode: "90067" },
    { lng: -121.44553, lat: 38.611854, postalCode: "95815" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90398" },
    { lng: -121.96907, lat: 38.678663, postalCode: "95653" },
    { lng: -120.75324, lat: 38.359772, postalCode: "95642" },
    { lng: -120.43806, lat: 39.663242, postalCode: "96124" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90842" },
    { lng: -122.43736, lat: 37.786129, postalCode: "94115" },
    { lng: -122.31207, lat: 41.317771, postalCode: "96067" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94820" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91131" },
    { lng: -120.55116, lat: 35.119678, postalCode: "93420" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94205" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91126" },
    { lng: -117.60351, lat: 33.639994, postalCode: "92688" },
    { lng: -118.54099, lat: 34.200104, postalCode: "91335" },
    { lng: -122.32588, lat: 37.534736, postalCode: "94497" },
    { lng: -120.937052, lat: 37.60396, postalCode: "95353" },
    { lng: -118.26596, lat: 34.07851, postalCode: "90026" },
    { lng: -118.19233, lat: 34.114558, postalCode: "90042" },
    { lng: -118.33255, lat: 33.934164, postalCode: "90303" },
    { lng: -117.29722, lat: 34.141659, postalCode: "92405" },
    { lng: -121.04788, lat: 35.987276, postalCode: "93932" },
    { lng: -119.70242, lat: 35.364749, postalCode: "93251" },
    { lng: -122.33718, lat: 37.203092, postalCode: "94060" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94575" },
    { lng: -117.10499, lat: 34.205626, postalCode: "92382" },
    { lng: -122.56884, lat: 37.981623, postalCode: "94960" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90748" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93596" },
    { lng: -118.47528, lat: 34.067409, postalCode: "90049" },
    { lng: -119.18583, lat: 35.39068, postalCode: "93312" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94229" },
    { lng: -119.70823, lat: 36.311439, postalCode: "93202" },
    { lng: -121.259009, lat: 39.585648, postalCode: "95940" },
    { lng: -118.902899, lat: 36.472713, postalCode: "93237" },
    { lng: -117.62752, lat: 34.35876, postalCode: "92397" },
    { lng: -117.765939, lat: 33.66985, postalCode: "92619" },
    { lng: -117.99477, lat: 33.941363, postalCode: "90603" },
    { lng: -122.11264, lat: 40.732501, postalCode: "96008" },
    { lng: -118.07775, lat: 33.840965, postalCode: "90715" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93775" },
    { lng: -119.7553, lat: 36.741199, postalCode: "93702" },
    { lng: -118.298662, lat: 33.786594, postalCode: "93599" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94023" },
    { lng: -124.145734, lat: 41.892473, postalCode: "95538" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90099" },
    { lng: -121.3081, lat: 38.695252, postalCode: "95621" },
    { lng: -118.03384, lat: 33.967562, postalCode: "90602" },
    { lng: -117.32122, lat: 33.890313, postalCode: "92508" },
    { lng: -122.524012, lat: 37.970726, postalCode: "94991" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93771" },
    { lng: -118.03362, lat: 33.945413, postalCode: "90605" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92147" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94280" },
    { lng: -120.02809, lat: 39.241961, postalCode: "96143" },
    { lng: -122.20563, lat: 39.139543, postalCode: "95987" },
    { lng: -120.88578, lat: 38.79829, postalCode: "95613" },
    { lng: -121.21763, lat: 39.381883, postalCode: "95935" },
    { lng: -121.64655, lat: 36.624606, postalCode: "93962" },
    { lng: -120.12859, lat: 40.283378, postalCode: "96136" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94064" },
    { lng: -117.97756, lat: 34.691946, postalCode: "93535" },
    { lng: -119.23371, lat: 35.774967, postalCode: "93215" },
    { lng: -120.7067, lat: 39.277658, postalCode: "95715" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91614" },
    { lng: -123.08125, lat: 38.955142, postalCode: "95449" },
    { lng: -120.46544, lat: 38.075897, postalCode: "95251" },
    { lng: -118.18951, lat: 34.497245, postalCode: "93510" },
    { lng: -121.89165, lat: 41.963883, postalCode: "96023" },
    { lng: -118.43222, lat: 34.153628, postalCode: "91423" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93556" },
    { lng: -118.24845, lat: 33.948315, postalCode: "90002" },
    { lng: -118.73425, lat: 36.596251, postalCode: "93262" },
    { lng: -122.88357, lat: 38.180029, postalCode: "94940" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95193" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91611" },
    { lng: -119.07317, lat: 34.367474, postalCode: "93060" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95031" },
    { lng: -116.46513, lat: 32.664307, postalCode: "91906" },
    { lng: -116.45012, lat: 34.31039, postalCode: "92285" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91023" },
    { lng: -124.1513, lat: 40.757091, postalCode: "95503" },
    { lng: -122.02503, lat: 37.69768, postalCode: "94552" },
    { lng: -121.03551, lat: 35.508282, postalCode: "93435" },
    { lng: -117.16871, lat: 32.804972, postalCode: "92111" },
    { lng: -118.45961, lat: 34.264635, postalCode: "91345" },
    { lng: -117.82592, lat: 33.680408, postalCode: "92614" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91009" },
    { lng: -122.224813, lat: 38.867147, postalCode: "95679" },
    { lng: -118.2161, lat: 34.07871, postalCode: "90031" },
    { lng: -117.29812, lat: 33.784051, postalCode: "92570" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91123" },
    { lng: -123.23026, lat: 39.825781, postalCode: "95428" },
    { lng: -121.349, lat: 38.687718, postalCode: "95842" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95192" },
    { lng: -117.769442, lat: 33.640302, postalCode: "90633" },
    { lng: -118.91524, lat: 36.780156, postalCode: "93628" },
    { lng: -122.253582, lat: 37.866825, postalCode: "94720" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93385" },
    { lng: -120.5937, lat: 38.60766, postalCode: "95684" },
    { lng: -121.55711, lat: 40.929301, postalCode: "96016" },
    { lng: -119.07769, lat: 37.765218, postalCode: "93529" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95873" },
    { lng: -115.967051, lat: 34.839964, postalCode: "91785" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94211" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94409" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92684" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92513" },
    { lng: -117.8325, lat: 33.610717, postalCode: "92657" },
    { lng: -118.39986, lat: 34.165706, postalCode: "91607" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94277" },
    { lng: -118.168691, lat: 33.801783, postalCode: "90755" },
    { lng: -122.63914, lat: 38.007639, postalCode: "94973" },
    { lng: -117.95629, lat: 33.708618, postalCode: "92708" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93714" },
    { lng: -123.56149, lat: 40.89197, postalCode: "95563" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92184" },
    { lng: -120.37815, lat: 40.169038, postalCode: "96121" },
    { lng: -122.07317, lat: 37.951672, postalCode: "94523" },
    { lng: -117.88694, lat: 34.085809, postalCode: "91723" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90224" },
    { lng: -121.81145, lat: 37.305714, postalCode: "95121" },
    { lng: -117.88139, lat: 33.765916, postalCode: "92706" },
    { lng: -123.037996, lat: 38.464487, postalCode: "95486" },
    { lng: -120.550021, lat: 38.463282, postalCode: "95654" },
    { lng: -120.27032, lat: 34.75465, postalCode: "93440" },
    { lng: -117.24211, lat: 33.923243, postalCode: "92553" },
    { lng: -121.92567, lat: 37.714179, postalCode: "94568" },
    { lng: -122.11785, lat: 37.895695, postalCode: "94549" },
    { lng: -117.25264, lat: 33.568617, postalCode: "92562" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94850" },
    { lng: -117.39432, lat: 34.064062, postalCode: "92316" },
    { lng: -117.002054, lat: 32.701452, postalCode: "91927" },
    { lng: -123.77315, lat: 40.196116, postalCode: "95559" },
    { lng: -121.306, lat: 38.154118, postalCode: "95258" },
    { lng: -120.4471, lat: 34.639682, postalCode: "93436" },
    { lng: -116.60671, lat: 32.620782, postalCode: "91963" },
    { lng: -118.34366, lat: 34.166906, postalCode: "91505" },
    { lng: -119.52873, lat: 36.61365, postalCode: "93648" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94966" },
    { lng: -119.425371, lat: 36.357151, postalCode: "93227" },
    { lng: -122.41018, lat: 37.801878, postalCode: "94133" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90411" },
    { lng: -118.33629, lat: 34.88923, postalCode: "93560" },
    { lng: -119.12029, lat: 37.973204, postalCode: "93541" },
    { lng: -117.79113, lat: 33.850515, postalCode: "92807" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93382" },
    { lng: -122.23396, lat: 37.463911, postalCode: "94061" },
    { lng: -120.28909, lat: 36.641742, postalCode: "93668" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94177" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95171" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90506" },
    { lng: -118.37563, lat: 34.224089, postalCode: "91352" },
    { lng: -117.6669, lat: 33.685479, postalCode: "92610" },
    { lng: -118.0863, lat: 33.72975, postalCode: "90743" },
    { lng: -117.05057, lat: 32.556264, postalCode: "92173" },
    { lng: -121.52805, lat: 39.904478, postalCode: "95978" },
    { lng: -115.50475, lat: 32.98975, postalCode: "92227" },
    { lng: -118.85608, lat: 34.14348, postalCode: "91361" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90665" },
    { lng: -122.08717, lat: 37.353741, postalCode: "94024" },
    { lng: -116.95195, lat: 32.638072, postalCode: "91915" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93778" },
    { lng: -120.64014, lat: 37.029613, postalCode: "93620" },
    { lng: -118.43769, lat: 35.784367, postalCode: "93238" },
    { lng: -118.12986, lat: 33.761117, postalCode: "90803" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90294" },
    { lng: -122.204573, lat: 37.727739, postalCode: "94614" },
    { lng: -121.2231, lat: 38.682803, postalCode: "95662" },
    { lng: -117.01027, lat: 33.261748, postalCode: "92082" },
    { lng: -121.59048, lat: 39.888074, postalCode: "95954" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92039" },
    { lng: -120.35776, lat: 40.446122, postalCode: "96117" },
    { lng: -116.22627, lat: 35.848556, postalCode: "92389" },
    { lng: -116.17025, lat: 32.624424, postalCode: "91934" },
    { lng: -121.23263, lat: 40.289788, postalCode: "96020" },
    { lng: -123.05495, lat: 38.449265, postalCode: "95430" },
    { lng: -119.83592, lat: 36.534599, postalCode: "93609" },
    { lng: -117.97304, lat: 33.818165, postalCode: "92804" },
    { lng: -118.45458, lat: 33.976373, postalCode: "90292" },
    { lng: -116.98973, lat: 32.642486, postalCode: "91913" },
    { lng: -121.17565, lat: 38.859227, postalCode: "95663" },
    { lng: -119.02225, lat: 35.344209, postalCode: "93304" },
    { lng: -119.08589, lat: 36.201983, postalCode: "93247" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95042" },
    { lng: -119.05258, lat: 34.231754, postalCode: "93010" },
    { lng: -117.769442, lat: 33.640302, postalCode: "90721" },
    { lng: -119.967631, lat: 38.935245, postalCode: "96156" },
    { lng: -120.50574, lat: 37.127525, postalCode: "95317" },
    { lng: -118.4922, lat: 34.01491, postalCode: "90401" },
    { lng: -121.2904, lat: 36.300819, postalCode: "93927" },
    { lng: -121.12546, lat: 37.742895, postalCode: "95366" },
    { lng: -123.55081, lat: 39.173321, postalCode: "95463" },
    { lng: -121.35852, lat: 39.425854, postalCode: "95914" },
    { lng: -120.818771, lat: 38.692808, postalCode: "95517" },
    { lng: -121.92102, lat: 36.619065, postalCode: "93950" },
    { lng: -117.45213, lat: 34.088911, postalCode: "92335" },
    { lng: -119.74097, lat: 36.658599, postalCode: "93725" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90853" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93412" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94977" },
    { lng: -122.12426, lat: 37.839426, postalCode: "94556" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92138" },
    { lng: -117.17441, lat: 32.682727, postalCode: "92118" },
    { lng: -123.80469, lat: 39.365356, postalCode: "95420" },
    { lng: -120.42806, lat: 34.87455, postalCode: "93455" },
    { lng: -118.45858, lat: 34.178305, postalCode: "91411" },
    { lng: -118.298662, lat: 33.786594, postalCode: "93539" },
    { lng: -122.18385, lat: 37.451688, postalCode: "94025" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91909" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92159" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93034" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90295" },
    { lng: -120.36328, lat: 38.186102, postalCode: "95233" },
    { lng: -118.23615, lat: 37.583838, postalCode: "93512" },
    { lng: -122.03701, lat: 38.269582, postalCode: "94533" },
    { lng: -122.24369, lat: 37.843726, postalCode: "94618" },
    { lng: -124.15098, lat: 41.949739, postalCode: "95567" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91337" },
    { lng: -121.44874, lat: 38.688069, postalCode: "95673" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91041" },
    { lng: -122.15517, lat: 38.071769, postalCode: "94510" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91388" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95867" },
    { lng: -121.84939, lat: 37.269343, postalCode: "95136" },
    { lng: -121.94518, lat: 38.931025, postalCode: "95937" },
    { lng: -121.1152, lat: 40.187554, postalCode: "95923" },
    { lng: -119.49352, lat: 35.889586, postalCode: "93201" },
    { lng: -119.74696, lat: 34.956385, postalCode: "93254" },
    { lng: -120.87339, lat: 35.964022, postalCode: "93450" },
    { lng: -122.92071, lat: 39.051698, postalCode: "95453" },
    { lng: -120.440467, lat: 37.807938, postalCode: "95309" },
    { lng: -121.63798, lat: 36.133401, postalCode: "93920" },
    { lng: -117.29851, lat: 33.240926, postalCode: "92057" },
    { lng: -117.87453, lat: 33.634821, postalCode: "92660" },
    { lng: -117.3247, lat: 33.696543, postalCode: "92532" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92177" },
    { lng: -118.0509, lat: 34.164806, postalCode: "91024" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91908" },
    { lng: -120.664132, lat: 40.446063, postalCode: "96053" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92172" },
    { lng: -122.51427, lat: 40.608728, postalCode: "96087" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92863" },
    { lng: -121.16094, lat: 41.285897, postalCode: "96054" },
    { lng: -121.28737, lat: 38.558073, postalCode: "95655" },
    { lng: -119.01483, lat: 35.842485, postalCode: "93218" },
    { lng: -121.68611, lat: 36.447533, postalCode: "93924" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90079" },
    { lng: -120.98043, lat: 37.644481, postalCode: "95354" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95108" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93062" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92885" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91346" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93780" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94627" },
    { lng: -121.26853, lat: 39.51642, postalCode: "95941" },
    { lng: -117.0607, lat: 32.608799, postalCode: "91911" },
    { lng: -118.47432, lat: 34.026959, postalCode: "90404" },
    { lng: -118.40535, lat: 33.88898, postalCode: "90266" },
    { lng: -117.07166, lat: 32.562106, postalCode: "92134" },
    { lng: -117.993561, lat: 33.792124, postalCode: "92680" },
    { lng: -122.54111, lat: 38.06317, postalCode: "94949" },
    { lng: -121.40726, lat: 38.594205, postalCode: "95825" },
    { lng: -117.89313, lat: 35.734418, postalCode: "93527" },
    { lng: -122.64688, lat: 41.441194, postalCode: "96034" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90652" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94151" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90659" },
    { lng: -117.14116, lat: 32.914346, postalCode: "92126" },
    { lng: -123.88348, lat: 40.907437, postalCode: "95525" },
    { lng: -120.53386, lat: 38.749713, postalCode: "95726" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94287" },
    { lng: -118.18642, lat: 33.988813, postalCode: "90270" },
    { lng: -124.07519, lat: 40.867469, postalCode: "95521" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91185" },
    { lng: -120.77519, lat: 37.428227, postalCode: "95315" },
    { lng: -124.13112, lat: 41.081726, postalCode: "95570" },
    { lng: -122.69537, lat: 38.347174, postalCode: "94928" },
    { lng: -124.06059, lat: 40.459978, postalCode: "95565" },
    { lng: -121.24213, lat: 38.038906, postalCode: "95212" },
    { lng: -121.89438, lat: 37.436451, postalCode: "95035" },
    { lng: -117.85817, lat: 34.088771, postalCode: "91724" },
    { lng: -122.021, lat: 40.705474, postalCode: "96069" },
    { lng: -118.38322, lat: 33.828815, postalCode: "90277" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90651" },
    { lng: -119.13779, lat: 36.774951, postalCode: "93621" },
    { lng: -121.00179, lat: 37.797806, postalCode: "95320" },
    { lng: -116.42249, lat: 33.762944, postalCode: "92270" },
    { lng: -120.58318, lat: 38.356552, postalCode: "95232" },
    { lng: -120.53353, lat: 38.634753, postalCode: "95636" },
    { lng: -121.21965, lat: 35.664386, postalCode: "93452" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90088" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94976" },
    { lng: -122.4597, lat: 37.734964, postalCode: "94127" },
    { lng: -122.47943, lat: 39.331444, postalCode: "95979" },
    { lng: -120.82071, lat: 38.689067, postalCode: "95619" },
    { lng: -122.5355, lat: 37.936151, postalCode: "94939" },
    { lng: -118.15182, lat: 33.783467, postalCode: "90804" },
    { lng: -122.01437, lat: 37.984271, postalCode: "94519" },
    { lng: -118.14482, lat: 33.771167, postalCode: "90814" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91066" },
    { lng: -121.49125, lat: 38.580255, postalCode: "95814" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94142" },
    { lng: -116.73864, lat: 32.823102, postalCode: "91901" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92018" },
    { lng: -118.34926, lat: 34.07036, postalCode: "90036" },
    { lng: -120.82329, lat: 37.959439, postalCode: "95230" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90102" },
    { lng: -121.29722, lat: 38.025086, postalCode: "95210" },
    { lng: -121.743632, lat: 37.216184, postalCode: "95153" },
    { lng: -121.91202, lat: 40.63446, postalCode: "96096" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91799" },
    { lng: -121.913632, lat: 37.456807, postalCode: "95036" },
    { lng: -122.43512, lat: 37.758434, postalCode: "94114" },
    { lng: -119.83321, lat: 36.691036, postalCode: "93706" },
    { lng: -117.21143, lat: 32.856447, postalCode: "92122" },
    { lng: -119.62201, lat: 36.566779, postalCode: "93662" },
    { lng: -118.46396, lat: 34.152513, postalCode: "91403" },
    { lng: -118.24753, lat: 34.232003, postalCode: "91214" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91313" },
    { lng: -120.67607, lat: 37.46994, postalCode: "95303" },
    { lng: -118.44724, lat: 34.199704, postalCode: "91405" },
    { lng: -122.19944, lat: 38.830508, postalCode: "95637" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92589" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92581" },
    { lng: -117.19966, lat: 32.823865, postalCode: "92117" },
    { lng: -122.26213, lat: 38.031271, postalCode: "94572" },
    { lng: -117.17563, lat: 34.254199, postalCode: "92321" },
    { lng: -119.263225, lat: 35.89151, postalCode: "93217" },
    { lng: -116.861506, lat: 34.262243, postalCode: "92427" },
    { lng: -118.37376, lat: 34.073759, postalCode: "90048" },
    { lng: -119.38254, lat: 36.981035, postalCode: "93667" },
    { lng: -118.49376, lat: 34.201491, postalCode: "91406" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91482" },
    { lng: -122.2234, lat: 38.05282, postalCode: "94525" },
    { lng: -118.87993, lat: 34.851405, postalCode: "93243" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93791" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90311" },
    { lng: -122.516, lat: 37.924242, postalCode: "94925" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94042" },
    { lng: -121.497349, lat: 36.986148, postalCode: "95021" },
    { lng: -120.10081, lat: 36.722616, postalCode: "93630" },
    { lng: -117.127166, lat: 34.21392, postalCode: "92402" },
    { lng: -122.12378, lat: 40.024337, postalCode: "96090" },
    { lng: -121.74189, lat: 38.681254, postalCode: "95776" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93776" },
    { lng: -117.879342, lat: 33.870714, postalCode: "92670" },
    { lng: -116.76834, lat: 33.911404, postalCode: "92230" },
    { lng: -121.58923, lat: 39.558586, postalCode: "95965" },
    { lng: -122.10297, lat: 41.263103, postalCode: "96057" },
    { lng: -119.53571, lat: 36.72845, postalCode: "93657" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90702" },
    { lng: -118.08239, lat: 33.759283, postalCode: "90740" },
    { lng: -123.47671, lat: 41.455226, postalCode: "95568" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91046" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92424" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92414" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93792" },
    { lng: -122.78972, lat: 39.090421, postalCode: "95458" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93764" },
    { lng: -122.36966, lat: 37.819423, postalCode: "94130" },
    { lng: -117.2726, lat: 33.891084, postalCode: "92518" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92052" },
    { lng: -119.11383, lat: 36.304696, postalCode: "93221" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91769" },
    { lng: -117.93835, lat: 34.068059, postalCode: "91790" },
    { lng: -118.17385, lat: 33.972814, postalCode: "90201" },
    { lng: -119.25307, lat: 34.456957, postalCode: "93023" },
    { lng: -123.32757, lat: 38.717819, postalCode: "95412" },
    { lng: -117.716885, lat: 33.601673, postalCode: "92654" },
    { lng: -117.90807, lat: 33.835665, postalCode: "92805" },
    { lng: -121.80944, lat: 38.697238, postalCode: "95695" },
    { lng: -121.65497, lat: 36.665879, postalCode: "93901" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91772" },
    { lng: -122.561689, lat: 38.022322, postalCode: "94957" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94230" },
    { lng: -123.33547, lat: 39.761199, postalCode: "95429" },
    { lng: -120.28989, lat: 41.924974, postalCode: "97635" },
    { lng: -121.52061, lat: 38.664824, postalCode: "95835" },
    { lng: -122.27917, lat: 37.894625, postalCode: "94707" },
    { lng: -121.05675, lat: 38.018456, postalCode: "95236" },
    { lng: -117.73948, lat: 33.659639, postalCode: "92618" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91749" },
    { lng: -118.29198, lat: 33.833416, postalCode: "90502" },
    { lng: -116.8471, lat: 33.308217, postalCode: "92060" },
    { lng: -119.00896, lat: 35.487491, postalCode: "93308" },
    { lng: -121.88975, lat: 40.876184, postalCode: "96065" },
    { lng: -117.22046, lat: 33.889035, postalCode: "92551" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90089" },
    { lng: -122.83666, lat: 38.393314, postalCode: "95472" },
    { lng: -118.24878, lat: 33.972914, postalCode: "90001" },
    { lng: -123.00084, lat: 38.509737, postalCode: "95446" },
    { lng: -122.23952, lat: 37.734379, postalCode: "94502" },
    { lng: -121.73354, lat: 38.851198, postalCode: "95645" },
    { lng: -119.42982, lat: 37.077273, postalCode: "93602" },
    { lng: -120.63432, lat: 39.598792, postalCode: "96125" },
    { lng: -121.95316, lat: 36.9761, postalCode: "95010" },
    { lng: -121.617894, lat: 37.225787, postalCode: "95038" },
    { lng: -119.21908, lat: 36.387879, postalCode: "93235" },
    { lng: -122.90636, lat: 38.495857, postalCode: "95436" },
    { lng: -118.27687, lat: 34.061911, postalCode: "90057" },
    { lng: -122.513093, lat: 38.313864, postalCode: "95433" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91221" },
    { lng: -116.91234, lat: 32.8201, postalCode: "92021" },
    { lng: -122.44276, lat: 37.770937, postalCode: "94117" },
    { lng: -117.91506, lat: 33.647028, postalCode: "92627" },
    { lng: -120.27864, lat: 37.100417, postalCode: "93610" },
    { lng: -117.86823, lat: 33.600172, postalCode: "92625" },
    { lng: -117.77932, lat: 34.06521, postalCode: "91768" },
    { lng: -122.27172, lat: 37.809427, postalCode: "94612" },
    { lng: -114.61905, lat: 33.628333, postalCode: "92225" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94162" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91795" },
    { lng: -122.95704, lat: 38.710228, postalCode: "95441" },
    { lng: -122.27582, lat: 37.862526, postalCode: "94703" },
    { lng: -122.24352, lat: 37.793177, postalCode: "94606" },
    { lng: -119.53472, lat: 37.284459, postalCode: "93669" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94157" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91110" },
    { lng: -120.34876, lat: 34.900809, postalCode: "93454" },
    { lng: -121.89811, lat: 38.782731, postalCode: "95698" },
    { lng: -122.218, lat: 39.760968, postalCode: "95963" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90239" },
    { lng: -122.21211, lat: 37.799727, postalCode: "94602" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90093" },
    { lng: -115.69162, lat: 32.792631, postalCode: "92273" },
    { lng: -121.97521, lat: 37.427439, postalCode: "95002" },
    { lng: -119.7065, lat: 34.420163, postalCode: "93101" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92385" },
    { lng: -121.99433, lat: 37.562218, postalCode: "94536" },
    { lng: -122.25784, lat: 38.005122, postalCode: "94547" },
    { lng: -122.31101, lat: 40.45453, postalCode: "96007" },
    { lng: -121.74712, lat: 39.496465, postalCode: "95974" },
    { lng: -122.568442, lat: 38.117269, postalCode: "94998" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92153" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94011" },
    { lng: -124.16102, lat: 40.793739, postalCode: "95501" },
    { lng: -121.07321, lat: 39.469943, postalCode: "95922" },
    { lng: -118.40843, lat: 33.921313, postalCode: "90245" },
    { lng: -120.85196, lat: 37.48858, postalCode: "95380" },
    { lng: -121.34715, lat: 38.476196, postalCode: "95829" },
    { lng: -118.3813, lat: 34.090975, postalCode: "90069" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94405" },
    { lng: -122.3957, lat: 37.789228, postalCode: "94105" },
    { lng: -121.9178, lat: 37.326341, postalCode: "95126" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91118" },
    { lng: -120.0847, lat: 36.209815, postalCode: "93234" },
    { lng: -120.0661, lat: 34.626931, postalCode: "93460" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94204" },
    { lng: -117.81814, lat: 34.103341, postalCode: "91773" },
    { lng: -119.16113, lat: 34.166933, postalCode: "93033" },
    { lng: -119.00404, lat: 34.815266, postalCode: "93225" },
    { lng: -120.85733, lat: 38.145601, postalCode: "95252" },
    { lng: -122.4019, lat: 37.791728, postalCode: "94104" },
    { lng: -122.16496, lat: 37.427039, postalCode: "94305" },
    { lng: -117.06686, lat: 32.638654, postalCode: "91910" },
    { lng: -122.989975, lat: 38.463088, postalCode: "95416" },
    { lng: -122.49265, lat: 37.778729, postalCode: "94121" },
    { lng: -118.20186, lat: 33.924642, postalCode: "90262" },
    { lng: -118.2559, lat: 34.052709, postalCode: "90071" },
    { lng: -120.36374, lat: 40.800556, postalCode: "96123" },
    { lng: -119.349639, lat: 36.841665, postalCode: "93649" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91409" },
    { lng: -120.65439, lat: 36.961248, postalCode: "93665" },
    { lng: -120.97109, lat: 38.641158, postalCode: "95682" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92834" },
    { lng: -122.40793, lat: 37.792678, postalCode: "94108" },
    { lng: -117.48812, lat: 33.921765, postalCode: "92505" },
    { lng: -122.39577, lat: 37.766529, postalCode: "94107" },
    { lng: -117.218616, lat: 36.626033, postalCode: "93542" },
    { lng: -121.908324, lat: 38.34896, postalCode: "95696" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92836" },
    { lng: -121.568427, lat: 38.232898, postalCode: "95680" },
    { lng: -117.16246, lat: 32.719601, postalCode: "92101" },
    { lng: -115.58942, lat: 32.853471, postalCode: "92251" },
    { lng: -121.79152, lat: 39.624259, postalCode: "95938" },
    { lng: -114.71352, lat: 33.387467, postalCode: "92266" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90610" },
    { lng: -117.02456, lat: 32.663803, postalCode: "91902" },
    { lng: -124.03075, lat: 41.51823, postalCode: "95548" },
    { lng: -118.38717, lat: 37.282192, postalCode: "93514" },
    { lng: -117.34946, lat: 33.675893, postalCode: "92530" },
    { lng: -116.84058, lat: 34.909491, postalCode: "92398" },
    { lng: -117.95159, lat: 33.934513, postalCode: "90631" },
    { lng: -121.05986, lat: 36.21106, postalCode: "93930" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91715" },
    { lng: -118.90082, lat: 34.396646, postalCode: "93015" },
    { lng: -118.204, lat: 33.893265, postalCode: "90221" },
    { lng: -121.85671, lat: 37.213624, postalCode: "95120" },
    { lng: -121.10036, lat: 38.492811, postalCode: "95683" },
    { lng: -116.29137, lat: 34.175926, postalCode: "92252" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94268" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95851" },
    { lng: -120.13213, lat: 38.805745, postalCode: "95735" },
    { lng: -117.85438, lat: 34.018361, postalCode: "91789" },
    { lng: -123.76087, lat: 39.43695, postalCode: "95437" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93793" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94244" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94059" },
    { lng: -117.82919, lat: 33.811566, postalCode: "92867" },
    { lng: -123.47525, lat: 40.639108, postalCode: "96046" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91951" },
    { lng: -119.30164, lat: 34.311203, postalCode: "93001" },
    { lng: -116.97401, lat: 33.73542, postalCode: "92543" },
    { lng: -119.94215, lat: 34.443746, postalCode: "93117" },
    { lng: -115.65796, lat: 34.548138, postalCode: "92304" },
    { lng: -122.42246, lat: 40.939342, postalCode: "96051" },
    { lng: -120.04541, lat: 37.840248, postalCode: "95321" },
    { lng: -122.72234, lat: 38.835146, postalCode: "95426" },
    { lng: -118.22663, lat: 34.107559, postalCode: "90065" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94083" },
    { lng: -120.77442, lat: 40.06686, postalCode: "95983" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92186" },
    { lng: -120.3437, lat: 37.995238, postalCode: "95370" },
    { lng: -122.07846, lat: 37.38949, postalCode: "94041" },
    { lng: -117.0186, lat: 32.785, postalCode: "91942" },
    { lng: -120.160271, lat: 39.320012, postalCode: "96163" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93773" },
    { lng: -118.61992, lat: 34.176238, postalCode: "91367" },
    { lng: -119.999032, lat: 38.927149, postalCode: "96152" },
    { lng: -122.71541, lat: 41.004651, postalCode: "96091" },
    { lng: -117.87143, lat: 33.719118, postalCode: "92707" },
    { lng: -117.100486, lat: 32.889139, postalCode: "92145" },
    { lng: -122.90644, lat: 38.245851, postalCode: "94971" },
    { lng: -122.41519, lat: 40.56816, postalCode: "96001" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94282" },
    { lng: -117.46052, lat: 33.91355, postalCode: "92503" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95894" },
    { lng: -117.75305, lat: 34.045584, postalCode: "91766" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90087" },
    { lng: -119.894727, lat: 36.138861, postalCode: "93231" },
    { lng: -118.24532, lat: 34.165373, postalCode: "91207" },
    { lng: -122.599, lat: 38.911393, postalCode: "95457" },
    { lng: -122.26655, lat: 37.499175, postalCode: "94070" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92202" },
    { lng: -121.72075, lat: 38.156909, postalCode: "94571" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90397" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92393" },
    { lng: -116.98983, lat: 32.84681, postalCode: "92071" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92403" },
    { lng: -118.28737, lat: 34.002063, postalCode: "90037" },
    { lng: -118.45077, lat: 34.101007, postalCode: "90077" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90267" },
    { lng: -121.02076, lat: 39.202725, postalCode: "95945" },
    { lng: -120.49446, lat: 35.032408, postalCode: "93444" },
    { lng: -122.96676, lat: 38.345572, postalCode: "94922" },
    { lng: -122.311628, lat: 40.709783, postalCode: "96049" },
    { lng: -123.24091, lat: 41.86235, postalCode: "96086" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94261" },
    { lng: -122.66389, lat: 38.012082, postalCode: "94963" },
    { lng: -122.30027, lat: 38.337243, postalCode: "94558" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91756" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94088" },
    { lng: -118.0951, lat: 34.089059, postalCode: "91776" },
    { lng: -118.83704, lat: 34.190804, postalCode: "91362" },
    { lng: -119.41664, lat: 35.37825, postalCode: "93206" },
    { lng: -118.16231, lat: 33.899915, postalCode: "90723" },
    { lng: -117.20011, lat: 33.289265, postalCode: "92003" },
    { lng: -118.40191, lat: 34.060859, postalCode: "90212" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90409" },
    { lng: -121.73655, lat: 38.549256, postalCode: "95616" },
    { lng: -119.93651, lat: 36.45185, postalCode: "93656" },
    { lng: -119.59309, lat: 36.657266, postalCode: "93616" },
    { lng: -122.74563, lat: 38.440111, postalCode: "95401" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92112" },
    { lng: -120.79533, lat: 38.724682, postalCode: "95667" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94912" },
    { lng: -121.66472, lat: 36.754058, postalCode: "93907" },
    { lng: -117.7025, lat: 33.531938, postalCode: "92677" },
    { lng: -122.05967, lat: 37.057708, postalCode: "95018" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93014" },
    { lng: -118.1276, lat: 33.888165, postalCode: "90706" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91125" },
    { lng: -118.3949, lat: 33.960041, postalCode: "90045" },
    { lng: -119.69703, lat: 36.753177, postalCode: "93727" },
    { lng: -118.40835, lat: 33.368642, postalCode: "90704" },
    { lng: -121.89877, lat: 37.335545, postalCode: "95110" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95011" },
    { lng: -120.78557, lat: 38.930207, postalCode: "95634" },
    { lng: -121.949418, lat: 37.05297, postalCode: "95067" },
    { lng: -118.40145, lat: 33.997179, postalCode: "90230" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91305" },
    { lng: -120.57996, lat: 36.800027, postalCode: "93622" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93707" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90075" },
    { lng: -122.95666, lat: 41.53101, postalCode: "96037" },
    { lng: -118.30848, lat: 34.058911, postalCode: "90005" },
    { lng: -116.17656, lat: 33.679872, postalCode: "92236" },
    { lng: -117.54068, lat: 33.8808, postalCode: "92879" },
    { lng: -121.68033, lat: 39.109272, postalCode: "95993" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92198" },
    { lng: -118.62538, lat: 34.218654, postalCode: "91304" },
    { lng: -117.63563, lat: 33.743168, postalCode: "92676" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94974" },
    { lng: -118.42821, lat: 34.481172, postalCode: "91350" },
    { lng: -118.96329, lat: 35.324767, postalCode: "93307" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95009" },
    { lng: -122.271251, lat: 38.098737, postalCode: "95592" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95298" },
    { lng: -120.59421, lat: 38.473857, postalCode: "95689" },
    { lng: -122.54589, lat: 38.019022, postalCode: "94903" },
    { lng: -120.10057, lat: 35.930766, postalCode: "93204" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91771" },
    { lng: -123.20187, lat: 41.228397, postalCode: "96031" },
    { lng: -118.29158, lat: 33.953814, postalCode: "90044" },
    { lng: -122.07934, lat: 37.702063, postalCode: "94546" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94623" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92712" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94140" },
    { lng: -122.87626, lat: 38.614088, postalCode: "95448" },
    { lng: -117.84569, lat: 33.785091, postalCode: "92866" },
    { lng: -119.30892, lat: 36.623699, postalCode: "93646" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94207" },
    { lng: -118.246691, lat: 34.900151, postalCode: "93564" },
    { lng: -118.24778, lat: 33.927471, postalCode: "90059" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93581" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91802" },
    { lng: -117.68737, lat: 34.015963, postalCode: "91710" },
    { lng: -118.48925, lat: 34.155625, postalCode: "91436" },
    { lng: -122.2144, lat: 37.483504, postalCode: "94063" },
    { lng: -118.08549, lat: 34.56548, postalCode: "93550" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91121" },
    { lng: -122.473404, lat: 37.510085, postalCode: "94018" },
    { lng: -115.57746, lat: 32.783359, postalCode: "92243" },
    { lng: -117.76932, lat: 33.73297, postalCode: "92602" },
    { lng: -117.26298, lat: 34.14856, postalCode: "92404" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95865" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92531" },
    { lng: -120.45243, lat: 34.956344, postalCode: "93458" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92837" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90847" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93140" },
    { lng: -118.48416, lat: 35.714118, postalCode: "93285" },
    { lng: -119.207364, lat: 34.162124, postalCode: "93043" },
    { lng: -120.67685, lat: 40.474951, postalCode: "96130" },
    { lng: -122.08524, lat: 37.084347, postalCode: "95005" },
    { lng: -118.24182, lat: 34.029313, postalCode: "90021" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95267" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90084" },
    { lng: -121.56507, lat: 38.240477, postalCode: "95690" },
    { lng: -121.07971, lat: 38.909605, postalCode: "95603" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93381" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92781" },
    { lng: -120.42801, lat: 36.701905, postalCode: "93640" },
    { lng: -120.84556, lat: 38.639643, postalCode: "95623" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94406" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94071" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91376" },
    { lng: -117.069361, lat: 33.714421, postalCode: "92396" },
    { lng: -122.28898, lat: 37.806427, postalCode: "94607" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93118" },
    { lng: -118.23666, lat: 33.890566, postalCode: "90220" },
    { lng: -123.07856, lat: 39.383027, postalCode: "95469" },
    { lng: -119.83198, lat: 36.831723, postalCode: "93711" },
    { lng: -122.36628, lat: 37.941424, postalCode: "94801" },
    { lng: -122.15702, lat: 37.722279, postalCode: "94577" },
    { lng: -122.02398, lat: 37.378341, postalCode: "94086" },
    { lng: -118.30998, lat: 33.958464, postalCode: "90047" },
    { lng: -117.81771, lat: 33.650813, postalCode: "92612" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91410" },
    { lng: -114.62591, lat: 32.786759, postalCode: "92283" },
    { lng: -122.125, lat: 38.706727, postalCode: "95607" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93790" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90076" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90509" },
    { lng: -120.64952, lat: 38.40097, postalCode: "95665" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92856" },
    { lng: -124.19929, lat: 40.79348, postalCode: "95564" },
    { lng: -118.11296, lat: 34.122658, postalCode: "91108" },
    { lng: -117.42049, lat: 33.735688, postalCode: "91719" },
    { lng: -118.20231, lat: 34.023039, postalCode: "90023" },
    { lng: -118.27335, lat: 37.1363, postalCode: "93513" },
    { lng: -120.636399, lat: 35.221085, postalCode: "93409" },
    { lng: -120.79015, lat: 38.394464, postalCode: "95685" },
    { lng: -119.32025, lat: 36.059363, postalCode: "93272" },
    { lng: -118.31367, lat: 33.828766, postalCode: "90501" },
    { lng: -121.30735, lat: 37.954823, postalCode: "95203" },
    { lng: -116.38023, lat: 33.821318, postalCode: "92276" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94016" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94288" },
    { lng: -116.42826, lat: 32.875267, postalCode: "91948" },
    { lng: -121.99976, lat: 37.307742, postalCode: "95129" },
    { lng: -121.73011, lat: 37.65778, postalCode: "94550" },
    { lng: -121.132928, lat: 36.35433, postalCode: "93942" },
    { lng: -117.59457, lat: 33.673979, postalCode: "92678" },
    { lng: -122.40441, lat: 38.658204, postalCode: "94567" },
    { lng: -117.2975, lat: 34.106965, postalCode: "92410" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93093" },
    { lng: -122.3408, lat: 37.926509, postalCode: "94804" },
    { lng: -122.47541, lat: 37.892476, postalCode: "94920" },
    { lng: -117.85446, lat: 33.883314, postalCode: "92870" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90174" },
    { lng: -122.07461, lat: 37.40679, postalCode: "94043" },
    { lng: -122.101917, lat: 38.245844, postalCode: "94534" },
    { lng: -121.65145, lat: 36.619954, postalCode: "93908" },
    { lng: -118.2943, lat: 34.048351, postalCode: "90006" },
    { lng: -117.229119, lat: 32.871846, postalCode: "92161" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93740" },
    { lng: -124.08998, lat: 40.281369, postalCode: "95545" },
    { lng: -118.300826, lat: 34.490004, postalCode: "91390" },
    { lng: -117.89932, lat: 34.020711, postalCode: "91792" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94666" },
    { lng: -122.9392, lat: 38.335515, postalCode: "94972" },
    { lng: -118.05606, lat: 34.101608, postalCode: "91780" },
    { lng: -117.481, lat: 34.046992, postalCode: "92337" },
    { lng: -119.70826, lat: 36.813456, postalCode: "93612" },
    { lng: -121.56581, lat: 37.016943, postalCode: "95020" },
    { lng: -118.419181, lat: 34.421537, postalCode: "91387" },
    { lng: -121.20021, lat: 39.207828, postalCode: "95946" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94035" },
    { lng: -117.11778, lat: 33.819818, postalCode: "92567" },
    { lng: -117.73861, lat: 34.080143, postalCode: "91767" },
    { lng: -121.16166, lat: 38.317665, postalCode: "95638" },
    { lng: -120.021342, lat: 38.184096, postalCode: "95375" },
    { lng: -117.00046, lat: 33.796153, postalCode: "92582" },
    { lng: -118.39968, lat: 33.866314, postalCode: "90254" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94290" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93759" },
    { lng: -119.51261, lat: 37.364625, postalCode: "93604" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91979" },
    { lng: -120.45436, lat: 38.110355, postalCode: "95229" },
    { lng: -123.05869, lat: 38.347701, postalCode: "94923" },
    { lng: -120.01902, lat: 36.802534, postalCode: "93606" },
    { lng: -121.70381, lat: 37.932123, postalCode: "94513" },
    { lng: -122.41085, lat: 37.719581, postalCode: "94134" },
    { lng: -120.92213, lat: 38.817357, postalCode: "95651" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93099" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91610" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93709" },
    { lng: -120.64542, lat: 35.664837, postalCode: "93447" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93130" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93794" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92607" },
    { lng: -117.52082, lat: 34.229288, postalCode: "92358" },
    { lng: -119.06497, lat: 35.340759, postalCode: "93309" },
    { lng: -121.723919, lat: 37.920852, postalCode: "94531" },
    { lng: -117.73856, lat: 33.883223, postalCode: "92887" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94271" },
    { lng: -117.20264, lat: 33.016492, postalCode: "92067" },
    { lng: -120.46555, lat: 37.521832, postalCode: "95369" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95102" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94154" },
    { lng: -119.01864, lat: 36.697311, postalCode: "93641" },
    { lng: -119.26362, lat: 35.87368, postalCode: "93219" },
    { lng: -116.055617, lat: 33.752886, postalCode: "91760" },
    { lng: -120.64145, lat: 39.26599, postalCode: "95724" },
    { lng: -119.995024, lat: 38.90392, postalCode: "96151" },
    { lng: -117.93975, lat: 34.029561, postalCode: "91744" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91225" },
    { lng: -117.68065, lat: 35.599177, postalCode: "93555" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92178" },
    { lng: -117.37239, lat: 33.943615, postalCode: "92506" },
    { lng: -117.9693, lat: 33.666269, postalCode: "92646" },
    { lng: -120.617808, lat: 37.378234, postalCode: "95344" },
    { lng: -121.961532, lat: 38.619545, postalCode: "95617" },
    { lng: -121.4417, lat: 38.641223, postalCode: "95838" },
    { lng: -118.55038, lat: 34.240353, postalCode: "91324" },
    { lng: -118.4011, lat: 34.208142, postalCode: "91605" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94172" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94407" },
    { lng: -117.19222, lat: 34.447959, postalCode: "92308" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94156" },
    { lng: -117.94904, lat: 34.533321, postalCode: "93543" },
    { lng: -119.64498, lat: 37.463177, postalCode: "93623" },
    { lng: -121.0023, lat: 38.71203, postalCode: "95672" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92697" },
    { lng: -121.37492, lat: 38.550098, postalCode: "95826" },
    { lng: -121.29795, lat: 38.902873, postalCode: "95648" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95190" },
    { lng: -117.88289, lat: 36.495772, postalCode: "93530" },
    { lng: -122.28316, lat: 37.834724, postalCode: "94608" },
    { lng: -122.458582, lat: 37.76313, postalCode: "94143" },
    { lng: -120.937052, lat: 37.60396, postalCode: "95397" },
    { lng: -122.3539, lat: 38.509569, postalCode: "94581" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92292" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94135" },
    { lng: -121.89072, lat: 37.257643, postalCode: "95118" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92616" },
    { lng: -120.82172, lat: 39.033312, postalCode: "95631" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94298" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95241" },
    { lng: -118.14291, lat: 33.92018, postalCode: "90242" },
    { lng: -120.84756, lat: 37.776528, postalCode: "95361" },
    { lng: -121.23505, lat: 38.200193, postalCode: "95220" },
    { lng: -123.87189, lat: 40.691578, postalCode: "95549" },
    { lng: -118.21195, lat: 34.050411, postalCode: "90033" },
    { lng: -119.02059, lat: 34.287679, postalCode: "93066" },
    { lng: -117.769442, lat: 33.640302, postalCode: "90632" },
    { lng: -122.33544, lat: 40.360583, postalCode: "96022" },
    { lng: -122.56316, lat: 38.117065, postalCode: "94945" },
    { lng: -118.0399, lat: 33.817481, postalCode: "90630" },
    { lng: -122.36526, lat: 37.317278, postalCode: "94074" },
    { lng: -122.184276, lat: 37.32477, postalCode: "95022" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95056" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94262" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95115" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94278" },
    { lng: -118.92268, lat: 35.396411, postalCode: "93306" },
    { lng: -122.44241, lat: 37.720931, postalCode: "94112" },
    { lng: -117.12056, lat: 33.52775, postalCode: "92591" },
    { lng: -119.118982, lat: 36.393615, postalCode: "93279" },
    { lng: -117.89743, lat: 34.063645, postalCode: "91791" },
    { lng: -122.13199, lat: 37.678531, postalCode: "94580" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93448" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91393" },
    { lng: -122.01675, lat: 39.738889, postalCode: "95951" },
    { lng: -122.16305, lat: 37.763028, postalCode: "94605" },
    { lng: -118.11358, lat: 34.014212, postalCode: "90640" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91392" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92420" },
    { lng: -119.7207, lat: 37.206505, postalCode: "93614" },
    { lng: -121.84775, lat: 37.405989, postalCode: "95132" },
    { lng: -121.53735, lat: 39.15973, postalCode: "95901" },
    { lng: -118.28453, lat: 34.256952, postalCode: "91042" },
    { lng: -119.26495, lat: 35.490517, postalCode: "93263" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92150" },
    { lng: -120.2561, lat: 37.46336, postalCode: "95325" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95173" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90053" },
    { lng: -117.33646, lat: 33.978965, postalCode: "92507" },
    { lng: -117.20933, lat: 33.479467, postalCode: "92590" },
    { lng: -116.97334, lat: 32.7991, postalCode: "92020" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94012" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94137" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93483" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92030" },
    { lng: -117.33999, lat: 34.399992, postalCode: "92345" },
    { lng: -119.05698, lat: 35.19766, postalCode: "93313" },
    { lng: -121.18367, lat: 38.607756, postalCode: "95742" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93007" },
    { lng: -122.46167, lat: 37.79984, postalCode: "94129" },
    { lng: -121.08762, lat: 39.584801, postalCode: "95981" },
    { lng: -121.33629, lat: 38.768829, postalCode: "95747" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91734" },
    { lng: -122.87751, lat: 38.099205, postalCode: "94937" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90097" },
    { lng: -121.44099, lat: 38.568855, postalCode: "95819" },
    { lng: -117.16837, lat: 33.922823, postalCode: "92555" },
    { lng: -121.84678, lat: 40.437443, postalCode: "96059" },
    { lng: -122.52666, lat: 41.604392, postalCode: "96038" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94649" },
    { lng: -120.42421, lat: 37.694235, postalCode: "95329" },
    { lng: -118.55252, lat: 34.445313, postalCode: "91354" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95161" },
    { lng: -121.02187, lat: 37.704138, postalCode: "95356" },
    { lng: -120.58072, lat: 38.087437, postalCode: "95222" },
    { lng: -121.9461, lat: 36.59081, postalCode: "93953" },
    { lng: -119.54587, lat: 36.506063, postalCode: "93631" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94307" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93443" },
    { lng: -118.31887, lat: 34.261221, postalCode: "91040" },
    { lng: -119.11699, lat: 35.801627, postalCode: "93261" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90185" },
    { lng: -120.16325, lat: 39.093271, postalCode: "96141" },
    { lng: -117.17128, lat: 33.144908, postalCode: "92069" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95763" },
    { lng: -118.63298, lat: 35.846291, postalCode: "93260" },
    { lng: -123.3999, lat: 38.654705, postalCode: "95480" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90307" },
    { lng: -121.98807, lat: 40.871548, postalCode: "96084" },
    { lng: -116.38634, lat: 33.904371, postalCode: "92241" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94166" },
    { lng: -122.59962, lat: 37.98779, postalCode: "94930" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90508" },
    { lng: -119.80297, lat: 34.443529, postalCode: "93111" },
    { lng: -118.1391, lat: 34.146607, postalCode: "91101" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91395" },
    { lng: -119.52202, lat: 36.12763, postalCode: "93282" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95026" },
    { lng: -119.98729, lat: 36.991476, postalCode: "93638" },
    { lng: -116.9759, lat: 33.945265, postalCode: "92223" },
    { lng: -117.86248, lat: 33.747017, postalCode: "92701" },
    { lng: -122.290204, lat: 39.169245, postalCode: "95931" },
    { lng: -122.4378, lat: 37.741797, postalCode: "94131" },
    { lng: -120.78346, lat: 39.217668, postalCode: "95701" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93729" },
    { lng: -122.59809, lat: 38.098221, postalCode: "94947" },
    { lng: -117.30614, lat: 34.634147, postalCode: "92368" },
    { lng: -123.43126, lat: 41.82087, postalCode: "96039" },
    { lng: -122.20009, lat: 37.458615, postalCode: "94027" },
    { lng: -118.42904, lat: 34.307877, postalCode: "91342" },
    { lng: -117.91473, lat: 35.139327, postalCode: "93505" },
    { lng: -116.29649, lat: 33.675373, postalCode: "92253" },
    { lng: -121.98311, lat: 37.289642, postalCode: "95130" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91012" },
    { lng: -120.6056, lat: 37.35115, postalCode: "95301" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94170" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92728" },
    { lng: -122.989975, lat: 38.463088, postalCode: "95487" },
    { lng: -121.973771, lat: 36.9634, postalCode: "95063" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91615" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93116" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92055" },
    { lng: -119.56093, lat: 35.202535, postalCode: "93224" },
    { lng: -121.25039, lat: 38.12463, postalCode: "95240" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93387" },
    { lng: -116.56703, lat: 33.921502, postalCode: "92258" },
    { lng: -117.84467, lat: 34.596742, postalCode: "93591" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95191" },
    { lng: -119.31287, lat: 35.957119, postalCode: "93256" },
    { lng: -120.248818, lat: 36.648474, postalCode: "93688" },
    { lng: -118.7607, lat: 34.139536, postalCode: "91301" },
    { lng: -121.98523, lat: 37.010322, postalCode: "95065" },
    { lng: -118.1339, lat: 33.851215, postalCode: "90714" },
    { lng: -118.11329, lat: 33.823316, postalCode: "90808" },
    { lng: -117.24434, lat: 32.7409, postalCode: "92107" },
    { lng: -122.989975, lat: 38.463088, postalCode: "94955" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92593" },
    { lng: -120.650351, lat: 37.186872, postalCode: "95343" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92190" },
    { lng: -121.75722, lat: 37.187349, postalCode: "95151" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94624" },
    { lng: -118.03942, lat: 34.076642, postalCode: "91731" },
    { lng: -122.97967, lat: 38.522499, postalCode: "95471" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92615" },
    { lng: -118.15577, lat: 34.112958, postalCode: "91030" },
    { lng: -121.32515, lat: 38.566506, postalCode: "95827" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90233" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95743" },
    { lng: -120.17303, lat: 39.862832, postalCode: "96105" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94155" },
    { lng: -118.18495, lat: 34.606146, postalCode: "93551" },
    { lng: -116.055617, lat: 33.752886, postalCode: "91720" },
    { lng: -120.1014, lat: 38.119056, postalCode: "95335" },
    { lng: -120.769493, lat: 35.428241, postalCode: "93423" },
    { lng: -118.37246, lat: 34.168206, postalCode: "91601" },
    { lng: -116.95091, lat: 32.732539, postalCode: "91978" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92817" },
    { lng: -120.021212, lat: 39.392752, postalCode: "96111" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92859" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94249" },
    { lng: -119.67529, lat: 37.699662, postalCode: "95389" },
    { lng: -117.18987, lat: 33.66035, postalCode: "92584" },
    { lng: -116.62459, lat: 32.890384, postalCode: "91916" },
    { lng: -121.77224, lat: 36.922902, postalCode: "95076" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91102" },
    { lng: -122.55944, lat: 38.07392, postalCode: "94915" },
    { lng: -122.34206, lat: 40.641389, postalCode: "96003" },
    { lng: -122.29208, lat: 37.997509, postalCode: "94564" },
    { lng: -118.43308, lat: 34.176505, postalCode: "91401" },
    { lng: -122.08883, lat: 37.674431, postalCode: "94541" },
    { lng: -117.03767, lat: 33.984551, postalCode: "92320" },
    { lng: -118.313405, lat: 33.769667, postalCode: "90879" },
    { lng: -122.13679, lat: 37.151813, postalCode: "95006" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91616" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93302" },
    { lng: -118.334, lat: 33.987463, postalCode: "90043" },
    { lng: -121.957778, lat: 37.953542, postalCode: "94527" },
    { lng: -119.81305, lat: 37.673629, postalCode: "95318" },
    { lng: -117.21514, lat: 33.822068, postalCode: "92571" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92605" },
    { lng: -117.121913, lat: 32.68342, postalCode: "92136" },
    { lng: -120.62265, lat: 40.921124, postalCode: "96132" },
    { lng: -117.1251, lat: 32.961014, postalCode: "92129" },
    { lng: -116.03343, lat: 34.165696, postalCode: "92277" },
    { lng: -120.55401, lat: 38.077, postalCode: "95221" },
    { lng: -116.20964, lat: 34.360235, postalCode: "92278" },
    { lng: -121.926315, lat: 36.543261, postalCode: "93922" },
    { lng: -119.007265, lat: 36.033126, postalCode: "93258" },
    { lng: -120.375945, lat: 37.805371, postalCode: "95347" },
    { lng: -117.85704, lat: 34.140207, postalCode: "91741" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93383" },
    { lng: -120.70305, lat: 35.277189, postalCode: "93405" },
    { lng: -122.49949, lat: 37.855527, postalCode: "94965" },
    { lng: -120.24835, lat: 39.663256, postalCode: "96118" },
    { lng: -121.89244, lat: 37.68973, postalCode: "94588" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92814" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94660" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91327" },
    { lng: -117.66681, lat: 33.611272, postalCode: "92691" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95852" },
    { lng: -122.21009, lat: 39.633265, postalCode: "95913" },
    { lng: -122.075125, lat: 37.007399, postalCode: "95061" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91426" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91050" },
    { lng: -119.38887, lat: 36.538017, postalCode: "93618" },
    { lng: -117.8578, lat: 35.044352, postalCode: "93523" },
    { lng: -118.39429, lat: 34.143856, postalCode: "91604" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90711" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94408" },
    { lng: -119.4303, lat: 35.162776, postalCode: "93268" },
    { lng: -120.906593, lat: 37.637531, postalCode: "95319" },
    { lng: -122.27672, lat: 37.271753, postalCode: "94021" },
    { lng: -120.405095, lat: 39.772064, postalCode: "96129" },
    { lng: -117.20077, lat: 32.766482, postalCode: "92110" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94106" },
    { lng: -116.31497, lat: 32.672239, postalCode: "91905" },
    { lng: -118.76204, lat: 36.150892, postalCode: "93265" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94098" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92329" },
    { lng: -120.87972, lat: 37.400932, postalCode: "95324" },
    { lng: -122.33133, lat: 41.109487, postalCode: "96017" },
    { lng: -121.274795, lat: 36.761702, postalCode: "95075" },
    { lng: -123.64423, lat: 39.670239, postalCode: "95417" },
    { lng: -122.16555, lat: 40.039257, postalCode: "96035" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90052" },
    { lng: -117.05633, lat: 32.574398, postalCode: "92154" },
    { lng: -118.46745, lat: 35.576134, postalCode: "93205" },
    { lng: -116.923, lat: 34.087114, postalCode: "92339" },
    { lng: -121.95072, lat: 37.007916, postalCode: "95073" },
    { lng: -116.54344, lat: 33.842984, postalCode: "92262" },
    { lng: -121.43738, lat: 41.874847, postalCode: "96134" },
    { lng: -118.44322, lat: 34.223753, postalCode: "91402" },
    { lng: -122.47725, lat: 38.553087, postalCode: "94576" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94297" },
    { lng: -117.99404, lat: 33.875714, postalCode: "90621" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92162" },
    { lng: -122.387808, lat: 40.704337, postalCode: "96099" },
    { lng: -120.97426, lat: 38.880294, postalCode: "95614" },
    { lng: -123.54765, lat: 39.240872, postalCode: "95427" },
    { lng: -120.50154, lat: 37.342056, postalCode: "95348" },
    { lng: -116.90328, lat: 34.241295, postalCode: "92315" },
    { lng: -118.60061, lat: 34.158214, postalCode: "91364" },
    { lng: -118.02619, lat: 33.782916, postalCode: "92845" },
    { lng: -117.89885, lat: 34.487552, postalCode: "93553" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91329" },
    { lng: -117.25967, lat: 32.961812, postalCode: "92014" },
    { lng: -123.65806, lat: 40.945039, postalCode: "95573" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93718" },
    { lng: -119.67379, lat: 37.146284, postalCode: "93645" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94240" },
    { lng: -118.819365, lat: 36.126621, postalCode: "93278" },
    { lng: -115.40124, lat: 33.830029, postalCode: "92239" },
    { lng: -121.14555, lat: 37.477477, postalCode: "95363" },
    { lng: -117.26159, lat: 32.838761, postalCode: "92037" },
    { lng: -118.30755, lat: 34.07711, postalCode: "90004" },
    { lng: -119.60084, lat: 34.421895, postalCode: "93067" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95154" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93782" },
    { lng: -117.07054, lat: 32.998855, postalCode: "92128" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92192" },
    { lng: -122.20773, lat: 39.499754, postalCode: "95988" },
    { lng: -118.30065, lat: 33.797317, postalCode: "90710" },
    { lng: -118.05216, lat: 34.126957, postalCode: "91007" },
    { lng: -121.01891, lat: 39.005098, postalCode: "95722" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95899" },
    { lng: -121.36059, lat: 38.421068, postalCode: "95624" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92149" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93717" },
    { lng: -119.22189, lat: 34.179304, postalCode: "93035" },
    { lng: -121.38006, lat: 36.862243, postalCode: "95023" },
    { lng: -117.63411, lat: 34.44654, postalCode: "92372" },
    { lng: -120.64145, lat: 39.26599, postalCode: "95924" },
    { lng: -120.86983, lat: 37.048385, postalCode: "93635" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91416" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93016" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95103" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95813" },
    { lng: -117.0951, lat: 32.741256, postalCode: "92105" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91609" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92735" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90030" },
    { lng: -121.0981, lat: 39.13255, postalCode: "95949" },
    { lng: -122.715654, lat: 38.459575, postalCode: "95406" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93094" },
    { lng: -118.36076, lat: 33.936163, postalCode: "90304" },
    { lng: -121.54496, lat: 38.569193, postalCode: "95691" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94643" },
    { lng: -121.26529, lat: 38.654803, postalCode: "95628" },
    { lng: -116.055617, lat: 33.752886, postalCode: "91718" },
    { lng: -118.50047, lat: 34.277648, postalCode: "91344" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92877" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90070" },
    { lng: -123.18307, lat: 40.524496, postalCode: "96041" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92187" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91365" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92023" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91308" },
    { lng: -120.707891, lat: 38.54723, postalCode: "95656" },
    { lng: -118.29158, lat: 34.17013, postalCode: "91201" },
    { lng: -119.48495, lat: 36.455501, postalCode: "93673" },
    { lng: -117.57847, lat: 34.147849, postalCode: "91737" },
    { lng: -119.863722, lat: 34.421795, postalCode: "93107" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95887" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91496" },
    { lng: -118.58704, lat: 34.384663, postalCode: "91381" },
    { lng: -117.27816, lat: 33.026742, postalCode: "92007" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92799" },
    { lng: -120.83816, lat: 39.204434, postalCode: "95714" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91793" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91912" },
    { lng: -122.04603, lat: 36.980556, postalCode: "95060" },
    { lng: -116.535887, lat: 33.761076, postalCode: "92263" },
    { lng: -119.2866, lat: 36.506019, postalCode: "93615" },
    { lng: -119.94865, lat: 37.576262, postalCode: "95345" },
    { lng: -122.80785, lat: 38.54486, postalCode: "95492" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93438" },
    { lng: -118.60206, lat: 34.198204, postalCode: "91303" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90081" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94167" },
    { lng: -122.25855, lat: 37.868575, postalCode: "94704" },
    { lng: -116.93842, lat: 34.268344, postalCode: "92333" },
    { lng: -118.26994, lat: 34.166567, postalCode: "91202" },
    { lng: -124.06711, lat: 40.822884, postalCode: "95524" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90662" },
    { lng: -121.75105, lat: 36.768856, postalCode: "95012" },
    { lng: -123.01469, lat: 38.471368, postalCode: "95462" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93407" },
    { lng: -119.10642, lat: 36.431121, postalCode: "93286" },
    { lng: -121.19828, lat: 41.102891, postalCode: "96068" },
    { lng: -119.88878, lat: 36.801603, postalCode: "93722" },
    { lng: -121.50466, lat: 38.642805, postalCode: "95834" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94254" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93774" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94247" },
    { lng: -122.96067, lat: 38.424554, postalCode: "95419" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91188" },
    { lng: -121.95216, lat: 39.44223, postalCode: "95920" },
    { lng: -121.71328, lat: 37.992124, postalCode: "94561" },
    { lng: -119.49014, lat: 38.475853, postalCode: "96107" },
    { lng: -121.741809, lat: 37.178745, postalCode: "95141" },
    { lng: -118.200957, lat: 33.804309, postalCode: "90801" },
    { lng: -118.18362, lat: 33.865916, postalCode: "90805" },
    { lng: -123.64617, lat: 39.103897, postalCode: "95432" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92350" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92340" },
    { lng: -117.25327, lat: 34.050764, postalCode: "92354" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92096" },
    { lng: -120.99703, lat: 38.995732, postalCode: "95703" },
    { lng: -122.40027, lat: 37.798228, postalCode: "94111" },
    { lng: -123.71376, lat: 39.216157, postalCode: "95410" },
    { lng: -117.200412, lat: 32.74344, postalCode: "92140" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93044" },
    { lng: -116.90948, lat: 33.708843, postalCode: "92544" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93715" },
    { lng: -122.63523, lat: 38.956206, postalCode: "95422" },
    { lng: -121.81888, lat: 39.720955, postalCode: "95928" },
    { lng: -120.97814, lat: 39.01004, postalCode: "95736" },
    { lng: -117.89325, lat: 33.878981, postalCode: "92831" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91412" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90096" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92196" },
    { lng: -118.51636, lat: 34.168753, postalCode: "91316" },
    { lng: -120.65786, lat: 37.942532, postalCode: "95228" },
    { lng: -117.24343, lat: 32.791246, postalCode: "92109" },
    { lng: -121.24839, lat: 36.015388, postalCode: "93928" },
    { lng: -117.20605, lat: 33.380359, postalCode: "92028" },
    { lng: -122.186573, lat: 37.781098, postalCode: "94613" },
    { lng: -119.80349, lat: 36.312075, postalCode: "93245" },
    { lng: -122.08787, lat: 37.380207, postalCode: "94040" },
    { lng: -118.28905, lat: 33.732368, postalCode: "90731" },
    { lng: -118.40612, lat: 34.088808, postalCode: "90210" },
    { lng: -120.53421, lat: 34.836252, postalCode: "93457" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93024" },
    { lng: -118.009, lat: 33.929163, postalCode: "90604" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93403" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92038" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93741" },
    { lng: -121.14213, lat: 38.87672, postalCode: "95658" },
    { lng: -122.60952, lat: 38.597907, postalCode: "94515" },
    { lng: -116.918724, lat: 32.572602, postalCode: "92179" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90661" },
    { lng: -122.48578, lat: 37.743381, postalCode: "94116" },
    { lng: -121.327214, lat: 38.650335, postalCode: "95609" },
    { lng: -122.66423, lat: 38.971518, postalCode: "95424" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92165" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93779" },
    { lng: -118.224785, lat: 35.068161, postalCode: "93502" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91363" },
    { lng: -115.967051, lat: 34.839964, postalCode: "91798" },
    { lng: -122.30038, lat: 37.806677, postalCode: "94615" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94239" },
    { lng: -123.16221, lat: 39.131856, postalCode: "95481" },
    { lng: -122.989975, lat: 38.463088, postalCode: "94953" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91735" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93524" },
    { lng: -121.55053, lat: 39.089929, postalCode: "95961" },
    { lng: -116.55559, lat: 32.852086, postalCode: "91931" },
    { lng: -117.39873, lat: 34.151243, postalCode: "92377" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94232" },
    { lng: -119.20552, lat: 36.300574, postalCode: "93223" },
    { lng: -121.132928, lat: 36.35433, postalCode: "93943" },
    { lng: -120.95064, lat: 37.584282, postalCode: "95307" },
    { lng: -122.45035, lat: 37.699182, postalCode: "94014" },
    { lng: -120.65196, lat: 35.262548, postalCode: "93401" },
    { lng: -118.3379, lat: 34.122058, postalCode: "90068" },
    { lng: -122.32553, lat: 37.941824, postalCode: "94805" },
    { lng: -121.03232, lat: 37.187494, postalCode: "95322" },
    { lng: -121.38193, lat: 39.661519, postalCode: "95916" },
    { lng: -117.12001, lat: 32.697402, postalCode: "92113" },
    { lng: -117.07449, lat: 34.241477, postalCode: "92341" },
    { lng: -122.02725, lat: 38.97159, postalCode: "95912" },
    { lng: -122.22638, lat: 37.085986, postalCode: "95017" },
    { lng: -115.967051, lat: 34.839964, postalCode: "93592" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90050" },
    { lng: -122.05076, lat: 37.657381, postalCode: "94542" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93747" },
    { lng: -118.29455, lat: 34.090259, postalCode: "90029" },
    { lng: -118.32683, lat: 34.728857, postalCode: "93536" },
    { lng: -116.53109, lat: 33.02757, postalCode: "92036" },
    { lng: -121.44032, lat: 38.228976, postalCode: "95686" },
    { lng: -121.332, lat: 41.114424, postalCode: "96056" },
    { lng: -122.21035, lat: 38.099119, postalCode: "94591" },
    { lng: -117.698173, lat: 33.573176, postalCode: "92635" },
    { lng: -122.30266, lat: 37.539686, postalCode: "94403" },
    { lng: -118.65255, lat: 34.478797, postalCode: "91384" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91396" },
    { lng: -121.53059, lat: 38.494971, postalCode: "95831" },
    { lng: -115.9565, lat: 33.289347, postalCode: "92275" },
    { lng: -123.41309, lat: 40.390478, postalCode: "95552" },
    { lng: -117.11558, lat: 33.087868, postalCode: "92029" },
    { lng: -124.07286, lat: 40.963482, postalCode: "95519" },
    { lng: -118.99213, lat: 34.218756, postalCode: "93012" },
    { lng: -121.24702, lat: 37.539768, postalCode: "95387" },
    { lng: -121.61026, lat: 39.750422, postalCode: "95969" },
    { lng: -117.77192, lat: 33.535325, postalCode: "92651" },
    { lng: -118.31481, lat: 34.062709, postalCode: "90010" },
    { lng: -121.91847, lat: 41.027553, postalCode: "96011" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92093" },
    { lng: -121.49643, lat: 38.464667, postalCode: "95832" },
    { lng: -118.64797, lat: 34.197737, postalCode: "91307" },
    { lng: -122.42188, lat: 37.792778, postalCode: "94109" },
    { lng: -119.91961, lat: 35.689717, postalCode: "93249" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92176" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94299" },
    { lng: -120.664132, lat: 40.446063, postalCode: "96127" },
    { lng: -115.6194, lat: 33.037061, postalCode: "92281" },
    { lng: -117.09272, lat: 32.670903, postalCode: "91950" },
    { lng: -121.26543, lat: 39.453457, postalCode: "95919" },
    { lng: -119.92527, lat: 37.505484, postalCode: "95338" },
    { lng: -121.52805, lat: 38.592155, postalCode: "95605" },
    { lng: -117.076176, lat: 32.775088, postalCode: "92182" },
    { lng: -118.28842, lat: 34.111208, postalCode: "90027" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92092" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94144" },
    { lng: -118.04614, lat: 33.720017, postalCode: "92649" },
    { lng: -118.298662, lat: 33.786594, postalCode: "93586" },
    { lng: -120.16872, lat: 38.079632, postalCode: "95346" },
    { lng: -124.21697, lat: 40.725444, postalCode: "95537" },
    { lng: -119.894727, lat: 36.138861, postalCode: "93232" },
    { lng: -122.29026, lat: 37.516687, postalCode: "94002" },
    { lng: -122.71044, lat: 38.328614, postalCode: "94931" },
    { lng: -117.15605, lat: 33.122029, postalCode: "92078" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93011" },
    { lng: -117.9731, lat: 33.765516, postalCode: "92844" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94235" },
    { lng: -124.15721, lat: 41.852985, postalCode: "95532" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90749" },
    { lng: -121.19013, lat: 38.743811, postalCode: "95746" },
    { lng: -122.279739, lat: 40.385673, postalCode: "96222" },
    { lng: -121.63845, lat: 37.388718, postalCode: "95140" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94529" },
    { lng: -121.298794, lat: 37.672196, postalCode: "95201" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92862" },
    { lng: -121.949418, lat: 37.05297, postalCode: "95077" },
    { lng: -122.21486, lat: 38.857328, postalCode: "95606" },
    { lng: -117.07031, lat: 32.7603, postalCode: "92115" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92846" },
    { lng: -120.86895, lat: 38.480569, postalCode: "95669" },
    { lng: -117.90698, lat: 34.097708, postalCode: "91722" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92152" },
    { lng: -121.91172, lat: 37.923523, postalCode: "94517" },
    { lng: -119.837072, lat: 34.432896, postalCode: "93106" },
    { lng: -116.24688, lat: 33.75307, postalCode: "92203" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94175" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93190" },
    { lng: -123.54411, lat: 41.300573, postalCode: "95556" },
    { lng: -122.36332, lat: 37.574936, postalCode: "94010" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91747" },
    { lng: -121.37967, lat: 38.997612, postalCode: "95681" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93121" },
    { lng: -121.3698, lat: 38.716874, postalCode: "95843" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93745" },
    { lng: -119.16626, lat: 34.850984, postalCode: "93222" },
    { lng: -117.191848, lat: 32.751575, postalCode: "92199" },
    { lng: -118.19776, lat: 34.211171, postalCode: "91011" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94284" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93160" },
    { lng: -122.00838, lat: 37.406289, postalCode: "94089" },
    { lng: -120.859391, lat: 35.372861, postalCode: "93492" },
    { lng: -122.03602, lat: 37.350091, postalCode: "94087" },
    { lng: -122.01507, lat: 37.057575, postalCode: "95066" },
    { lng: -117.11706, lat: 33.749656, postalCode: "92548" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94273" },
    { lng: -120.241412, lat: 37.986377, postalCode: "95373" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92160" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93390" },
    { lng: -118.04395, lat: 33.849327, postalCode: "90623" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94807" },
    { lng: -124.11637, lat: 40.494813, postalCode: "95562" },
    { lng: -122.24571, lat: 38.105219, postalCode: "94590" },
    { lng: -118.38271, lat: 34.066009, postalCode: "90211" },
    { lng: -116.235, lat: 33.715271, postalCode: "92201" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94302" },
    { lng: -119.92498, lat: 37.236305, postalCode: "93653" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91503" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92413" },
    { lng: -123.60216, lat: 40.169518, postalCode: "95511" },
    { lng: -118.93956, lat: 34.175038, postalCode: "91320" },
    { lng: -119.67526, lat: 36.6265, postalCode: "93625" },
    { lng: -123.80654, lat: 40.0985, postalCode: "95542" },
    { lng: -122.24074, lat: 40.183837, postalCode: "96080" },
    { lng: -119.32637, lat: 36.311, postalCode: "93277" },
    { lng: -121.13191, lat: 39.563522, postalCode: "95930" },
    { lng: -121.98647, lat: 37.811676, postalCode: "94526" },
    { lng: -118.56214, lat: 34.406519, postalCode: "91355" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95158" },
    { lng: -119.50748, lat: 37.004865, postalCode: "93651" },
    { lng: -118.00184, lat: 34.140806, postalCode: "91016" },
    { lng: -118.70752, lat: 34.035087, postalCode: "90263" },
    { lng: -121.79092, lat: 37.232244, postalCode: "95119" },
    { lng: -123.75568, lat: 39.268774, postalCode: "95456" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91109" },
    { lng: -120.536032, lat: 39.106287, postalCode: "95604" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91077" },
    { lng: -117.23, lat: 34.245607, postalCode: "92317" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91222" },
    { lng: -117.58734, lat: 34.104555, postalCode: "91730" },
    { lng: -118.319, lat: 33.899265, postalCode: "90249" },
    { lng: -118.98647, lat: 35.387058, postalCode: "93305" },
    { lng: -121.42399, lat: 37.728417, postalCode: "95376" },
    { lng: -123.46646, lat: 40.202464, postalCode: "95595" },
    { lng: -116.350003, lat: 34.180251, postalCode: "92286" },
    { lng: -122.25937, lat: 37.769528, postalCode: "94501" },
    { lng: -118.07218, lat: 33.794028, postalCode: "90720" },
    { lng: -119.26372, lat: 37.191503, postalCode: "93605" },
    { lng: -123.74471, lat: 39.96266, postalCode: "95587" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90609" },
    { lng: -117.77444, lat: 34.493392, postalCode: "93544" },
    { lng: -120.88299, lat: 37.322619, postalCode: "95374" },
    { lng: -121.82664, lat: 37.284042, postalCode: "95111" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91944" },
    { lng: -121.71843, lat: 38.909351, postalCode: "95676" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90296" },
    { lng: -121.1362, lat: 41.119727, postalCode: "96009" },
    { lng: -117.59002, lat: 36.269728, postalCode: "93522" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95055" },
    { lng: -117.90623, lat: 33.724167, postalCode: "92704" },
    { lng: -121.92916, lat: 37.515014, postalCode: "94539" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95106" },
    { lng: -118.76893, lat: 34.182875, postalCode: "91377" },
    { lng: -121.44378, lat: 38.518356, postalCode: "95824" },
    { lng: -121.99192, lat: 38.380528, postalCode: "95688" },
    { lng: -119.18829, lat: 34.154072, postalCode: "93041" },
    { lng: -122.28003, lat: 41.221077, postalCode: "96025" },
    { lng: -118.199401, lat: 33.767817, postalCode: "90833" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95044" },
    { lng: -121.60207, lat: 38.700244, postalCode: "95837" },
    { lng: -121.19064, lat: 37.955474, postalCode: "95215" },
    { lng: -122.4301, lat: 37.652857, postalCode: "94080" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94283" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91328" },
    { lng: -118.07179, lat: 33.718167, postalCode: "90742" },
    { lng: -117.9355, lat: 33.763033, postalCode: "92843" },
    { lng: -117.73864, lat: 33.858151, postalCode: "92808" },
    { lng: -117.0217, lat: 33.736743, postalCode: "92545" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94237" },
    { lng: -120.7141, lat: 37.6194, postalCode: "95323" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93384" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95840" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93760" },
    { lng: -121.801215, lat: 39.731406, postalCode: "95929" },
    { lng: -120.15338, lat: 36.600172, postalCode: "93660" },
    { lng: -117.58831, lat: 34.066655, postalCode: "91743" },
    { lng: -117.03701, lat: 32.973645, postalCode: "92064" },
    { lng: -120.79166, lat: 39.340422, postalCode: "95986" },
    { lng: -121.59415, lat: 40.044865, postalCode: "95942" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94289" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91186" },
    { lng: -115.51245, lat: 32.726931, postalCode: "92249" },
    { lng: -120.8516, lat: 37.523901, postalCode: "95382" },
    { lng: -122.334778, lat: 40.686639, postalCode: "96079" },
    { lng: -118.298662, lat: 33.786594, postalCode: "93590" },
    { lng: -122.1944, lat: 40.735153, postalCode: "96070" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94152" },
    { lng: -121.132928, lat: 36.35433, postalCode: "93915" },
    { lng: -120.67463, lat: 38.748112, postalCode: "95709" },
    { lng: -121.90502, lat: 38.017917, postalCode: "94565" },
    { lng: -117.89966, lat: 33.601822, postalCode: "92661" },
    { lng: -117.12537, lat: 32.714992, postalCode: "92102" },
    { lng: -117.37617, lat: 34.109511, postalCode: "92376" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92864" },
    { lng: -121.2548, lat: 39.355688, postalCode: "95962" },
    { lng: -116.56565, lat: 34.203249, postalCode: "92268" },
    { lng: -116.54244, lat: 33.209313, postalCode: "92066" },
    { lng: -119.82042, lat: 36.177361, postalCode: "93266" },
    { lng: -118.14161, lat: 34.048207, postalCode: "91754" },
    { lng: -121.05181, lat: 37.622898, postalCode: "95358" },
    { lng: -122.427333, lat: 40.657893, postalCode: "96089" },
    { lng: -120.650351, lat: 37.186872, postalCode: "93661" },
    { lng: -117.52759, lat: 34.001846, postalCode: "91752" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90101" },
    { lng: -122.07143, lat: 37.879757, postalCode: "94595" },
    { lng: -118.39168, lat: 34.021611, postalCode: "90232" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92171" },
    { lng: -121.27125, lat: 38.696912, postalCode: "95610" },
    { lng: -121.46827, lat: 38.571661, postalCode: "95816" },
    { lng: -120.14789, lat: 41.90749, postalCode: "96112" },
    { lng: -121.06047, lat: 39.93935, postalCode: "95956" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90310" },
    { lng: -117.32646, lat: 33.158137, postalCode: "92008" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94234" },
    { lng: -120.834958, lat: 39.912222, postalCode: "95915" },
    { lng: -120.98093, lat: 35.834919, postalCode: "93426" },
    { lng: -117.38202, lat: 35.764433, postalCode: "93562" },
    { lng: -120.95566, lat: 37.673513, postalCode: "95355" },
    { lng: -120.485154, lat: 38.325626, postalCode: "95248" },
    { lng: -121.51487, lat: 38.367276, postalCode: "95639" },
    { lng: -117.20722, lat: 32.899996, postalCode: "92121" },
    { lng: -121.34733, lat: 39.282171, postalCode: "95918" },
    { lng: -119.80097, lat: 36.794748, postalCode: "93704" },
    { lng: -121.06879, lat: 38.684324, postalCode: "95762" },
    { lng: -122.06101, lat: 37.633732, postalCode: "94544" },
    { lng: -123.277143, lat: 39.213324, postalCode: "95418" },
    { lng: -118.0375, lat: 34.561207, postalCode: "93552" },
    { lng: -123.97132, lat: 40.489925, postalCode: "95528" },
    { lng: -121.80207, lat: 37.991571, postalCode: "94509" },
    { lng: -121.43316, lat: 40.310324, postalCode: "96061" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91804" },
    { lng: -116.87593, lat: 34.172474, postalCode: "92305" },
    { lng: -121.132928, lat: 36.35433, postalCode: "93912" },
    { lng: -119.1343, lat: 34.032383, postalCode: "91359" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94551" },
    { lng: -118.44513, lat: 34.424794, postalCode: "91351" },
    { lng: -118.07367, lat: 36.589514, postalCode: "93545" },
    { lng: -122.03746, lat: 39.941595, postalCode: "96092" },
    { lng: -122.15421, lat: 37.68858, postalCode: "94579" },
    { lng: -120.49157, lat: 37.867243, postalCode: "95327" },
    { lng: -122.30426, lat: 37.917774, postalCode: "94530" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91383" },
    { lng: -118.20743, lat: 36.851729, postalCode: "93526" },
    { lng: -119.18327, lat: 36.713181, postalCode: "93675" },
    { lng: -122.32773, lat: 37.551586, postalCode: "94402" },
    { lng: -121.43497, lat: 39.030716, postalCode: "95692" },
    { lng: -117.60221, lat: 33.903803, postalCode: "92880" },
    { lng: -121.3147, lat: 37.974273, postalCode: "95204" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92599" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95296" },
    { lng: -117.98224, lat: 33.786915, postalCode: "92841" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92058" },
    { lng: -121.61538, lat: 36.681161, postalCode: "93905" },
    { lng: -120.65401, lat: 35.150886, postalCode: "93449" },
    { lng: -122.57972, lat: 39.878018, postalCode: "96074" },
    { lng: -117.406293, lat: 34.587473, postalCode: "92031" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94661" },
    { lng: -117.10694, lat: 33.022384, postalCode: "92127" },
    { lng: -120.98366, lat: 39.957324, postalCode: "95971" },
    { lng: -121.01249, lat: 37.671778, postalCode: "95350" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90060" },
    { lng: -116.95125, lat: 33.788491, postalCode: "92583" },
    { lng: -117.64093, lat: 33.615462, postalCode: "92692" },
    { lng: -118.49022, lat: 34.032159, postalCode: "90403" },
    { lng: -117.24461, lat: 33.179587, postalCode: "92083" },
    { lng: -121.203656, lat: 38.114669, postalCode: "95253" },
    { lng: -118.91325, lat: 35.256873, postalCode: "93241" },
    { lng: -120.00992, lat: 38.908741, postalCode: "96150" },
    { lng: -122.51484, lat: 37.972423, postalCode: "94901" },
    { lng: -123.55762, lat: 38.813417, postalCode: "95445" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91031" },
    { lng: -120.08301, lat: 35.323098, postalCode: "93453" },
    { lng: -120.111465, lat: 34.674436, postalCode: "93464" },
    { lng: -123.85855, lat: 40.131417, postalCode: "95560" },
    { lng: -121.52848, lat: 36.829052, postalCode: "95045" },
    { lng: -114.892368, lat: 33.53163, postalCode: "92271" },
    { lng: -117.36077, lat: 33.202463, postalCode: "92054" },
    { lng: -121.961532, lat: 38.619545, postalCode: "95798" },
    { lng: -115.99404, lat: 33.543836, postalCode: "92254" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93150" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91334" },
    { lng: -123.26509, lat: 38.916201, postalCode: "95494" },
    { lng: -117.83252, lat: 33.918965, postalCode: "92823" },
    { lng: -121.26401, lat: 37.962873, postalCode: "95205" },
    { lng: -118.11848, lat: 33.776094, postalCode: "90822" },
    { lng: -122.24199, lat: 37.812377, postalCode: "94610" },
    { lng: -123.83488, lat: 40.766645, postalCode: "95550" },
    { lng: -119.00457, lat: 36.417743, postalCode: "93244" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94161" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92195" },
    { lng: -120.88512, lat: 39.545514, postalCode: "95944" },
    { lng: -118.35096, lat: 33.810848, postalCode: "90505" },
    { lng: -120.82881, lat: 38.839773, postalCode: "95633" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90082" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92163" },
    { lng: -117.35321, lat: 34.489466, postalCode: "92392" },
    { lng: -120.94516, lat: 38.346132, postalCode: "95640" },
    { lng: -122.15071, lat: 37.443688, postalCode: "94301" },
    { lng: -116.69452, lat: 35.262763, postalCode: "92310" },
    { lng: -121.83065, lat: 39.070323, postalCode: "95957" },
    { lng: -120.570634, lat: 37.367315, postalCode: "95342" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94310" },
    { lng: -114.17251, lat: 34.286985, postalCode: "92267" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94126" },
    { lng: -121.3025, lat: 37.922024, postalCode: "95206" },
    { lng: -121.85784, lat: 37.37354, postalCode: "95133" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94208" },
    { lng: -119.765, lat: 36.767299, postalCode: "93703" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91021" },
    { lng: -119.85236, lat: 38.76467, postalCode: "96120" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92842" },
    { lng: -119.79832, lat: 36.841454, postalCode: "93650" },
    { lng: -121.45996, lat: 38.551106, postalCode: "95817" },
    { lng: -122.70201, lat: 37.907675, postalCode: "94924" },
    { lng: -117.16715, lat: 32.746018, postalCode: "92103" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94169" },
    { lng: -117.12246, lat: 32.76235, postalCode: "92116" },
    { lng: -121.389613, lat: 38.666653, postalCode: "95652" },
    { lng: -121.81348, lat: 38.427208, postalCode: "95620" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92502" },
    { lng: -120.25829, lat: 37.991855, postalCode: "95372" },
    { lng: -117.53392, lat: 34.453465, postalCode: "92371" },
    { lng: -121.84259, lat: 39.745973, postalCode: "95926" },
    { lng: -121.23708, lat: 38.382367, postalCode: "95693" },
    { lng: -120.28875, lat: 38.282255, postalCode: "95223" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90209" },
    { lng: -122.77226, lat: 38.494643, postalCode: "95439" },
    { lng: -118.08339, lat: 33.906914, postalCode: "90650" },
    { lng: -118.59933, lat: 34.101447, postalCode: "90290" },
    { lng: -117.35773, lat: 34.55965, postalCode: "92394" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92416" },
    { lng: -121.97709, lat: 37.957772, postalCode: "94521" },
    { lng: -117.764637, lat: 33.648871, postalCode: "92603" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90848" },
    { lng: -117.78852, lat: 33.68762, postalCode: "92604" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92167" },
    { lng: -123.37285, lat: 39.451662, postalCode: "95490" },
    { lng: -121.05361, lat: 38.205817, postalCode: "95227" },
    { lng: -118.150352, lat: 33.824918, postalCode: "90846" },
    { lng: -117.90863, lat: 33.677224, postalCode: "92626" },
    { lng: -120.34876, lat: 38.208553, postalCode: "95224" },
    { lng: -121.28933, lat: 38.752434, postalCode: "95678" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93406" },
    { lng: -117.29075, lat: 34.105079, postalCode: "92401" },
    { lng: -118.30954, lat: 34.177606, postalCode: "91502" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92838" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91508" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95071" },
    { lng: -116.86379, lat: 34.866837, postalCode: "92327" },
    { lng: -117.23274, lat: 34.048544, postalCode: "92318" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94250" },
    { lng: -122.95916, lat: 38.246611, postalCode: "94929" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92515" },
    { lng: -123.50186, lat: 39.089273, postalCode: "95466" },
    { lng: -120.11304, lat: 40.030098, postalCode: "96109" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91522" },
    { lng: -119.1769, lat: 34.21707, postalCode: "93030" },
    { lng: -120.72583, lat: 35.18554, postalCode: "93424" },
    { lng: -121.15783, lat: 38.672127, postalCode: "95630" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95150" },
    { lng: -118.86279, lat: 37.621587, postalCode: "93546" },
    { lng: -120.0188, lat: 38.87529, postalCode: "96154" },
    { lng: -120.27116, lat: 37.245423, postalCode: "95333" },
    { lng: -121.58245, lat: 40.371043, postalCode: "96063" },
    { lng: -122.39534, lat: 37.621964, postalCode: "94128" },
    { lng: -121.285257, lat: 37.953473, postalCode: "95290" },
    { lng: -118.710724, lat: 34.258203, postalCode: "93064" },
    { lng: -118.36841, lat: 34.152476, postalCode: "91602" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90312" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91003" },
    { lng: -118.23624, lat: 33.910716, postalCode: "90222" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91987" },
    { lng: -122.03988, lat: 37.986321, postalCode: "94520" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94604" },
    { lng: -117.66356, lat: 34.138575, postalCode: "91784" },
    { lng: -121.83246, lat: 37.244426, postalCode: "95123" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90408" },
    { lng: -122.21792, lat: 37.776835, postalCode: "94601" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91382" },
    { lng: -119.49874, lat: 38.651141, postalCode: "96133" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92022" },
    { lng: -120.8384, lat: 39.567001, postalCode: "95936" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94295" },
    { lng: -118.36241, lat: 34.098908, postalCode: "90046" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93712" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93772" },
    { lng: -118.50121, lat: 34.036759, postalCode: "90402" },
    { lng: -116.82004, lat: 33.485978, postalCode: "92536" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93303" },
    { lng: -116.49139, lat: 32.773317, postalCode: "91962" },
    { lng: -117.07986, lat: 33.653216, postalCode: "92596" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91976" },
    { lng: -121.2651, lat: 38.498156, postalCode: "95830" },
    { lng: -119.75917, lat: 36.865587, postalCode: "93720" },
    { lng: -121.337404, lat: 39.918503, postalCode: "95980" },
    { lng: -117.66563, lat: 34.057561, postalCode: "91762" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91043" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91841" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90809" },
    { lng: -120.64145, lat: 39.26599, postalCode: "96162" },
    { lng: -120.84496, lat: 39.465229, postalCode: "95910" },
    { lng: -120.58114, lat: 39.743835, postalCode: "96106" },
    { lng: -120.47474, lat: 37.294648, postalCode: "95340" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94296" },
    { lng: -120.15817, lat: 36.914819, postalCode: "93637" },
    { lng: -120.83844, lat: 35.373047, postalCode: "93442" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93061" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94620" },
    { lng: -122.02618, lat: 37.91878, postalCode: "94598" },
    { lng: -117.3176, lat: 34.12051, postalCode: "92411" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92564" },
    { lng: -121.9881, lat: 37.160012, postalCode: "95033" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92013" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92194" },
    { lng: -122.51753, lat: 38.347913, postalCode: "95431" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95142" },
    { lng: -121.54853, lat: 38.333568, postalCode: "95615" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91523" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90055" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92143" },
    { lng: -122.54626, lat: 40.607134, postalCode: "96095" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95160" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92168" },
    { lng: -120.22805, lat: 39.339574, postalCode: "96161" },
    { lng: -120.3778, lat: 41.736074, postalCode: "96108" },
    { lng: -117.80407, lat: 33.888062, postalCode: "92886" },
    { lng: -122.28671, lat: 38.291164, postalCode: "94559" },
    { lng: -122.52468, lat: 38.367948, postalCode: "95442" },
    { lng: -121.42557, lat: 36.518179, postalCode: "93926" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91788" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91618" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94039" },
    { lng: -121.447131, lat: 37.72374, postalCode: "95377" },
    { lng: -121.87149, lat: 37.658898, postalCode: "94566" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94659" },
    { lng: -116.90487, lat: 32.788736, postalCode: "92019" },
    { lng: -118.25948, lat: 34.135259, postalCode: "91204" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90083" },
    { lng: -118.09035, lat: 34.114041, postalCode: "91775" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90095" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90231" },
    { lng: -118.1858, lat: 33.801767, postalCode: "90806" },
    { lng: -118.33065, lat: 33.870414, postalCode: "90504" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90308" },
    { lng: -121.681345, lat: 39.020133, postalCode: "95992" },
    { lng: -121.28805, lat: 37.958723, postalCode: "95202" },
    { lng: -121.84195, lat: 38.159894, postalCode: "94512" },
    { lng: -116.50804, lat: 33.952303, postalCode: "92240" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90510" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93020" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92418" },
    { lng: -118.39804, lat: 34.03056, postalCode: "90034" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91385" },
    { lng: -122.03362, lat: 37.535586, postalCode: "94560" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91990" },
    { lng: -117.13595, lat: 32.803799, postalCode: "92123" },
    { lng: -122.26253, lat: 37.444879, postalCode: "94062" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94136" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92235" },
    { lng: -118.11987, lat: 33.958363, postalCode: "90240" },
    { lng: -121.49201, lat: 38.51394, postalCode: "95822" },
    { lng: -121.32702, lat: 38.628371, postalCode: "95608" },
    { lng: -119.146228, lat: 34.356248, postalCode: "93009" },
    { lng: -117.77097, lat: 34.117047, postalCode: "91750" },
    { lng: -116.55878, lat: 33.632049, postalCode: "92561" },
    { lng: -122.81535, lat: 41.32211, postalCode: "96014" },
    { lng: -122.05858, lat: 37.905924, postalCode: "94596" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95053" },
    { lng: -120.78463, lat: 37.552329, postalCode: "95316" },
    { lng: -120.12438, lat: 39.037696, postalCode: "96142" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90072" },
    { lng: -116.26597, lat: 33.184028, postalCode: "92004" },
    { lng: -115.967051, lat: 34.839964, postalCode: "91758" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95759" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91380" },
    { lng: -118.776902, lat: 36.26699, postalCode: "93275" },
    { lng: -120.41411, lat: 38.529753, postalCode: "95666" },
    { lng: -117.03776, lat: 34.033564, postalCode: "92399" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94150" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92658" },
    { lng: -122.36824, lat: 40.682393, postalCode: "96019" },
    { lng: -119.90272, lat: 36.600348, postalCode: "93652" },
    { lng: -116.06504, lat: 35.27295, postalCode: "92309" },
    { lng: -121.95333, lat: 38.34401, postalCode: "95687" },
    { lng: -121.4024, lat: 38.484747, postalCode: "95828" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91404" },
    { lng: -123.16785, lat: 38.562843, postalCode: "95421" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91407" },
    { lng: -118.35034, lat: 34.139397, postalCode: "91608" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94269" },
    { lng: -122.64479, lat: 41.749249, postalCode: "96097" },
    { lng: -122.02506, lat: 37.954122, postalCode: "94518" },
    { lng: -122.42363, lat: 37.625908, postalCode: "94066" },
    { lng: -120.89487, lat: 38.183288, postalCode: "95225" },
    { lng: -122.29055, lat: 38.115015, postalCode: "94592" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91510" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95159" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90637" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91115" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93888" },
    { lng: -120.18597, lat: 40.158794, postalCode: "96113" },
    { lng: -115.48277, lat: 32.683491, postalCode: "92231" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94171" },
    { lng: -118.54748, lat: 35.381552, postalCode: "93518" },
    { lng: -122.41915, lat: 37.779329, postalCode: "94102" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92068" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94597" },
    { lng: -119.21336, lat: 35.670747, postalCode: "93250" },
    { lng: -116.46473, lat: 33.807761, postalCode: "92234" },
    { lng: -120.05437, lat: 39.243877, postalCode: "96148" },
    { lng: -119.41514, lat: 35.040362, postalCode: "93252" },
    { lng: -122.10418, lat: 37.635582, postalCode: "94545" },
    { lng: -118.199401, lat: 33.767817, postalCode: "90835" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90306" },
    { lng: -118.22937, lat: 34.156674, postalCode: "91206" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95155" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94274" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90410" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92522" },
    { lng: -117.885359, lat: 35.187133, postalCode: "93504" },
    { lng: -123.80079, lat: 41.888557, postalCode: "95543" },
    { lng: -120.9061, lat: 37.667196, postalCode: "95357" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94147" },
    { lng: -120.62632, lat: 38.300688, postalCode: "95245" },
    { lng: -122.29371, lat: 37.889125, postalCode: "94706" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92857" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91499" },
    { lng: -120.61327, lat: 35.097599, postalCode: "93445" },
    { lng: -116.63644, lat: 34.896333, postalCode: "92365" },
    { lng: -121.48166, lat: 38.961976, postalCode: "95674" },
    { lng: -117.17305, lat: 34.034714, postalCode: "92373" },
    { lng: -117.87362, lat: 33.835465, postalCode: "92806" },
    { lng: -121.88987, lat: 37.38631, postalCode: "95131" },
    { lng: -121.37656, lat: 38.676103, postalCode: "95660" },
    { lng: -122.48209, lat: 37.682583, postalCode: "94015" },
    { lng: -117.7633, lat: 33.714889, postalCode: "92620" },
    { lng: -117.05241, lat: 32.677286, postalCode: "92139" },
    { lng: -121.684124, lat: 39.805255, postalCode: "95927" },
    { lng: -118.3285, lat: 34.089459, postalCode: "90038" },
    { lng: -118.66464, lat: 34.133513, postalCode: "91302" },
    { lng: -122.47058, lat: 38.277147, postalCode: "95476" },
    { lng: -122.204141, lat: 40.12517, postalCode: "96078" },
    { lng: -122.48229, lat: 37.613806, postalCode: "94044" },
    { lng: -118.27591, lat: 33.923365, postalCode: "90061" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92051" },
    { lng: -120.26765, lat: 38.771209, postalCode: "95720" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93032" },
    { lng: -115.284581, lat: 33.026203, postalCode: "92232" },
    { lng: -119.71753, lat: 36.43465, postalCode: "93242" },
    { lng: -119.33907, lat: 36.545749, postalCode: "93666" },
    { lng: -118.115698, lat: 33.784266, postalCode: "90840" },
    { lng: -120.95144, lat: 39.299405, postalCode: "95959" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95052" },
    { lng: -117.28654, lat: 34.242567, postalCode: "92325" },
    { lng: -122.66097, lat: 38.314531, postalCode: "94951" },
    { lng: -121.36637, lat: 39.125997, postalCode: "95903" },
    { lng: -118.08912, lat: 33.989013, postalCode: "90660" },
    { lng: -116.34148, lat: 33.70129, postalCode: "92210" },
    { lng: -119.511704, lat: 37.036529, postalCode: "93613" },
    { lng: -119.68412, lat: 34.428191, postalCode: "93103" },
    { lng: -118.035049, lat: 35.177369, postalCode: "93519" },
    { lng: -121.308008, lat: 37.981073, postalCode: "95211" },
    { lng: -119.16784, lat: 34.278812, postalCode: "93004" },
    { lng: -118.03562, lat: 34.002333, postalCode: "90601" },
    { lng: -120.64058, lat: 39.779486, postalCode: "96103" },
    { lng: -117.20073, lat: 34.12716, postalCode: "92346" },
    { lng: -121.51661, lat: 38.8323, postalCode: "95668" },
    { lng: -118.11824, lat: 33.795049, postalCode: "90815" },
    { lng: -120.86341, lat: 38.229515, postalCode: "95226" },
    { lng: -122.68886, lat: 38.012489, postalCode: "94933" },
    { lng: -120.51775, lat: 40.295652, postalCode: "96114" },
    { lng: -122.47958, lat: 37.724231, postalCode: "94132" },
    { lng: -121.71129, lat: 39.35217, postalCode: "95948" },
    { lng: -122.92335, lat: 40.606673, postalCode: "96024" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90406" },
    { lng: -122.989975, lat: 38.463088, postalCode: "94926" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94267" },
    { lng: -118.60663, lat: 36.062748, postalCode: "93208" },
    { lng: -121.9212, lat: 36.555248, postalCode: "93921" },
    { lng: -117.99392, lat: 33.751418, postalCode: "92683" },
    { lng: -122.017956, lat: 37.38894, postalCode: "94085" },
    { lng: -121.79141, lat: 37.330496, postalCode: "95148" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92556" },
    { lng: -121.91202, lat: 37.804064, postalCode: "94506" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92191" },
    { lng: -115.967051, lat: 34.839964, postalCode: "93558" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94516" },
    { lng: -122.41545, lat: 37.74873, postalCode: "94110" },
    { lng: -122.65445, lat: 39.037173, postalCode: "95423" },
    { lng: -122.05414, lat: 38.231364, postalCode: "94585" },
    { lng: -121.26784, lat: 37.609781, postalCode: "95385" },
    { lng: -118.87134, lat: 34.292898, postalCode: "93021" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94286" },
    { lng: -121.88281, lat: 37.593883, postalCode: "94586" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91947" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94259" },
    { lng: -118.00969, lat: 33.841014, postalCode: "90620" },
    { lng: -117.5924, lat: 34.131659, postalCode: "91701" },
    { lng: -122.61121, lat: 40.736679, postalCode: "96033" },
    { lng: -117.96943, lat: 34.000578, postalCode: "91745" },
    { lng: -122.658097, lat: 38.267466, postalCode: "94999" },
    { lng: -116.32317, lat: 33.759409, postalCode: "92211" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92803" },
    { lng: -122.0569, lat: 37.050798, postalCode: "95041" },
    { lng: -120.8451, lat: 39.177026, postalCode: "95717" },
    { lng: -120.46947, lat: 38.421689, postalCode: "95255" },
    { lng: -120.93471, lat: 39.092062, postalCode: "95713" },
    { lng: -122.45084, lat: 38.568307, postalCode: "94508" },
    { lng: -121.908915, lat: 36.606225, postalCode: "93944" },
    { lng: -121.832156, lat: 38.751038, postalCode: "95697" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91117" },
    { lng: -122.74082, lat: 39.03276, postalCode: "95443" },
    { lng: -119.894727, lat: 36.138861, postalCode: "93246" },
    { lng: -116.1982, lat: 34.720285, postalCode: "92338" },
    { lng: -117.564, lat: 33.555323, postalCode: "92693" },
    { lng: -120.679713, lat: 38.195804, postalCode: "95749" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92334" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94256" },
    { lng: -121.95446, lat: 37.278843, postalCode: "95008" },
    { lng: -118.01701, lat: 35.666835, postalCode: "93255" },
    { lng: -121.60812, lat: 37.847704, postalCode: "94514" },
    { lng: -117.0542, lat: 32.706954, postalCode: "92114" },
    { lng: -119.03742, lat: 35.94901, postalCode: "93270" },
    { lng: -122.12412, lat: 37.37714, postalCode: "94022" },
    { lng: -118.30153, lat: 34.186755, postalCode: "91501" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94206" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90103" },
    { lng: -121.89154, lat: 37.333941, postalCode: "95113" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93380" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91921" },
    { lng: -122.98347, lat: 38.409993, postalCode: "95465" },
    { lng: -122.43836, lat: 37.801028, postalCode: "94123" },
    { lng: -122.69345, lat: 38.243734, postalCode: "94952" },
    { lng: -118.59338, lat: 35.241311, postalCode: "93531" },
    { lng: -122.18969, lat: 37.753978, postalCode: "94621" },
    { lng: -116.87897, lat: 33.920803, postalCode: "92220" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95741" },
    { lng: -120.09201, lat: 34.709973, postalCode: "93441" },
    { lng: -120.845706, lat: 38.437001, postalCode: "95699" },
    { lng: -118.87348, lat: 34.208837, postalCode: "91360" },
    { lng: -119.09411, lat: 36.141864, postalCode: "93267" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91025" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94101" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90608" },
    { lng: -119.1343, lat: 34.032383, postalCode: "91358" },
    { lng: -120.2297, lat: 37.957978, postalCode: "95379" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90607" },
    { lng: -117.31174, lat: 34.030714, postalCode: "92313" },
    { lng: -118.276, lat: 33.962714, postalCode: "90003" },
    { lng: -123.77902, lat: 40.2959, postalCode: "95554" },
    { lng: -122.6422, lat: 38.460594, postalCode: "95409" },
    { lng: -124.08412, lat: 40.546202, postalCode: "95547" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90734" },
    { lng: -121.60048, lat: 37.088746, postalCode: "95046" },
    { lng: -117.21918, lat: 32.946776, postalCode: "92130" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91394" },
    { lng: -122.2981, lat: 37.869976, postalCode: "94710" },
    { lng: -119.52308, lat: 34.402194, postalCode: "93013" },
    { lng: -121.94214, lat: 41.790562, postalCode: "96058" },
    { lng: -114.35823, lat: 34.137179, postalCode: "92242" },
    { lng: -119.78872, lat: 36.749444, postalCode: "93701" },
    { lng: -122.81988, lat: 38.081566, postalCode: "94956" },
    { lng: -118.15284, lat: 33.997492, postalCode: "90040" },
    { lng: -118.02662, lat: 34.134057, postalCode: "91006" },
    { lng: -120.90406, lat: 38.919588, postalCode: "95635" },
    { lng: -121.76465, lat: 39.16855, postalCode: "95982" },
    { lng: -119.33655, lat: 36.351009, postalCode: "93291" },
    { lng: -117.5246, lat: 34.127819, postalCode: "91739" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92878" },
    { lng: -118.08062, lat: 33.867215, postalCode: "90701" },
    { lng: -121.83461, lat: 36.615813, postalCode: "93955" },
    { lng: -115.692714, lat: 32.79475, postalCode: "92244" },
    { lng: -123.69266, lat: 40.325767, postalCode: "95514" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90671" },
    { lng: -116.71061, lat: 33.166768, postalCode: "92070" },
    { lng: -115.61583, lat: 33.330929, postalCode: "92257" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90407" },
    { lng: -121.8944, lat: 36.979333, postalCode: "95003" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92170" },
    { lng: -119.6451, lat: 36.327063, postalCode: "93230" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94146" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91224" },
    { lng: -117.15362, lat: 33.571185, postalCode: "92563" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91129" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93031" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94096" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93005" },
    { lng: -119.68496, lat: 37.024461, postalCode: "93626" },
    { lng: -119.89619, lat: 38.275328, postalCode: "95364" },
    { lng: -120.39216, lat: 38.060956, postalCode: "95310" },
    { lng: -123.71685, lat: 39.860285, postalCode: "95585" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90213" },
    { lng: -114.561905, lat: 32.782242, postalCode: "92222" },
    { lng: -117.02431, lat: 32.80225, postalCode: "92119" },
    { lng: -118.53374, lat: 34.050505, postalCode: "90272" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91114" },
    { lng: -121.57819, lat: 38.383366, postalCode: "95612" },
    { lng: -121.9534, lat: 37.241193, postalCode: "95032" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95234" },
    { lng: -121.34378, lat: 38.035499, postalCode: "95209" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94141" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92816" },
    { lng: -121.803777, lat: 39.556251, postalCode: "95958" },
    { lng: -120.86419, lat: 37.59471, postalCode: "95326" },
    { lng: -124.05351, lat: 41.296122, postalCode: "95555" },
    { lng: -120.53467, lat: 34.73489, postalCode: "93437" },
    { lng: -118.356908, lat: 33.944714, postalCode: "90204" },
    { lng: -122.33873, lat: 37.972623, postalCode: "94806" },
    { lng: -121.43673, lat: 38.406432, postalCode: "95758" },
    { lng: -117.69475, lat: 34.394253, postalCode: "93563" },
    { lng: -118.43083, lat: 34.002011, postalCode: "90066" },
    { lng: -117.87494, lat: 33.787266, postalCode: "92868" },
    { lng: -121.63082, lat: 36.885056, postalCode: "95004" },
    { lng: -117.623131, lat: 33.438428, postalCode: "92674" },
    { lng: -116.99612, lat: 32.721145, postalCode: "91977" },
    { lng: -120.676, lat: 35.474913, postalCode: "93422" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92698" },
    { lng: -120.25764, lat: 37.824554, postalCode: "95305" },
    { lng: -122.399101, lat: 38.437887, postalCode: "94562" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94209" },
    { lng: -118.25193, lat: 34.042912, postalCode: "90014" },
    { lng: -120.72428, lat: 35.54464, postalCode: "93465" },
    { lng: -118.1468, lat: 33.851965, postalCode: "90712" },
    { lng: -122.93982, lat: 40.734483, postalCode: "96093" },
    { lng: -117.09688, lat: 34.083512, postalCode: "92359" },
    { lng: -122.94566, lat: 40.366349, postalCode: "96076" },
    { lng: -117.79975, lat: 33.792687, postalCode: "92869" },
    { lng: -122.414189, lat: 38.466508, postalCode: "94573" },
    { lng: -118.25633, lat: 33.857416, postalCode: "90746" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92812" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94543" },
    { lng: -116.67362, lat: 33.55055, postalCode: "92539" },
    { lng: -119.56754, lat: 37.405699, postalCode: "93644" },
    { lng: -118.01383, lat: 34.070659, postalCode: "91732" },
    { lng: -121.97757, lat: 38.537434, postalCode: "95694" },
    { lng: -118.8844, lat: 35.702936, postalCode: "93287" },
    { lng: -118.07364, lat: 33.828565, postalCode: "90716" },
    { lng: -118.26434, lat: 34.052561, postalCode: "90017" },
    { lng: -121.89436, lat: 36.517261, postalCode: "93923" },
    { lng: -122.989975, lat: 38.463088, postalCode: "95408" },
    { lng: -117.81673, lat: 34.004172, postalCode: "91765" },
    { lng: -123.45661, lat: 40.877334, postalCode: "95527" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91189" },
    { lng: -121.28975, lat: 39.19322, postalCode: "95977" },
    { lng: -118.69607, lat: 34.279343, postalCode: "93063" },
    { lng: -123.65746, lat: 38.898385, postalCode: "95468" },
    { lng: -122.40135, lat: 37.600735, postalCode: "94030" },
    { lng: -121.08928, lat: 37.708727, postalCode: "95368" },
    { lng: -118.22605, lat: 34.000219, postalCode: "90058" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90054" },
    { lng: -117.90433, lat: 33.747067, postalCode: "92703" },
    { lng: -120.13838, lat: 34.608938, postalCode: "93463" },
    { lng: -121.78299, lat: 36.812905, postalCode: "95039" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92158" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94029" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92033" },
    { lng: -120.88311, lat: 40.144573, postalCode: "95947" },
    { lng: -122.84653, lat: 39.12558, postalCode: "95464" },
    { lng: -119.76788, lat: 34.439579, postalCode: "93110" },
    { lng: -118.08475, lat: 34.066143, postalCode: "91770" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93844" },
    { lng: -124.25781, lat: 40.561448, postalCode: "95536" },
    { lng: -116.27037, lat: 35.947562, postalCode: "92384" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93761" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92391" },
    { lng: -120.14607, lat: 39.177383, postalCode: "96145" },
    { lng: -115.967051, lat: 34.839964, postalCode: "91729" },
    { lng: -117.50803, lat: 34.641695, postalCode: "92301" },
    { lng: -118.33705, lat: 34.009754, postalCode: "90008" },
    { lng: -117.69966, lat: 35.409108, postalCode: "93554" },
    { lng: -123.22064, lat: 39.285782, postalCode: "95470" },
    { lng: -117.92594, lat: 33.806965, postalCode: "92802" },
    { lng: -120.15123, lat: 37.74614, postalCode: "95311" },
    { lng: -122.29673, lat: 37.860576, postalCode: "94701" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92412" },
    { lng: -117.70327, lat: 33.475302, postalCode: "92629" },
    { lng: -122.505846, lat: 38.325851, postalCode: "95473" },
    { lng: -122.997, lat: 38.795668, postalCode: "95425" },
    { lng: -118.04834, lat: 34.05051, postalCode: "91733" },
    { lng: -121.60428, lat: 38.161756, postalCode: "95641" },
    { lng: -121.95131, lat: 37.347791, postalCode: "95050" },
    { lng: -120.03012, lat: 39.523693, postalCode: "89439" },
    { lng: -122.573745, lat: 38.148858, postalCode: "94948" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94045" },
    { lng: -121.93555, lat: 37.316983, postalCode: "95128" },
    { lng: -122.03231, lat: 39.605289, postalCode: "95943" },
    { lng: -120.550021, lat: 38.463282, postalCode: "95644" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93765" },
    { lng: -116.96499, lat: 33.311881, postalCode: "92061" },
    { lng: -118.6821, lat: 35.87137, postalCode: "93207" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91353" },
    { lng: -121.961532, lat: 38.619545, postalCode: "95799" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91612" },
    { lng: -118.12715, lat: 34.142557, postalCode: "91106" },
    { lng: -121.02427, lat: 38.811695, postalCode: "95664" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90074" },
    { lng: -119.178814, lat: 34.22942, postalCode: "93036" },
    { lng: -120.10669, lat: 36.523726, postalCode: "93627" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94294" },
    { lng: -121.572986, lat: 39.723898, postalCode: "95967" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91116" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92871" },
    { lng: -118.21495, lat: 33.816217, postalCode: "90810" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91946" },
    { lng: -117.72474, lat: 33.581533, postalCode: "92656" },
    { lng: -123.984232, lat: 40.881383, postalCode: "95518" },
    { lng: -118.19197, lat: 33.781011, postalCode: "90813" },
    { lng: -121.705327, lat: 37.189396, postalCode: "94090" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91386" },
    { lng: -121.226782, lat: 37.904777, postalCode: "95213" },
    { lng: -122.81414, lat: 40.743423, postalCode: "96052" },
    { lng: -118.45592, lat: 34.676708, postalCode: "93532" },
    { lng: -117.12774, lat: 32.742892, postalCode: "92104" },
    { lng: -121.54983, lat: 38.707236, postalCode: "95836" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94914" },
    { lng: -117.72305, lat: 33.975267, postalCode: "91709" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91933" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92519" },
    { lng: -117.886731, lat: 33.611198, postalCode: "92659" },
    { lng: -117.65776, lat: 34.101659, postalCode: "91786" },
    { lng: -120.30865, lat: 36.194736, postalCode: "93210" },
    { lng: -117.55978, lat: 33.925885, postalCode: "92860" },
    { lng: -122.251078, lat: 37.562385, postalCode: "94420" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92652" },
    { lng: -119.23304, lat: 37.237216, postalCode: "93634" },
    { lng: -121.89649, lat: 37.295092, postalCode: "95125" },
    { lng: -119.92502, lat: 38.033541, postalCode: "95314" },
    { lng: -117.96569, lat: 34.137707, postalCode: "91010" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93786" },
    { lng: -122.264, lat: 37.834926, postalCode: "94609" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91017" },
    { lng: -122.0509, lat: 37.571581, postalCode: "94555" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90223" },
    { lng: -117.84739, lat: 33.8318, postalCode: "92865" },
    { lng: -120.73812, lat: 37.645632, postalCode: "95386" },
    { lng: -119.82747, lat: 36.786948, postalCode: "93705" },
    { lng: -122.17332, lat: 37.738379, postalCode: "94603" },
    { lng: -121.77972, lat: 36.935552, postalCode: "95019" },
    { lng: -121.87946, lat: 39.793465, postalCode: "95973" },
    { lng: -120.48359, lat: 39.801047, postalCode: "96122" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94308" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92690" },
    { lng: -120.08627, lat: 39.223151, postalCode: "96140" },
    { lng: -121.05272, lat: 40.290007, postalCode: "96137" },
    { lng: -123.34016, lat: 40.723792, postalCode: "96010" },
    { lng: -121.9679, lat: 37.527237, postalCode: "94538" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95015" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95109" },
    { lng: -119.5548, lat: 36.085342, postalCode: "93212" },
    { lng: -118.38531, lat: 34.05266, postalCode: "90035" },
    { lng: -118.199401, lat: 33.767817, postalCode: "90831" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92357" },
    { lng: -117.28232, lat: 33.195354, postalCode: "92056" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95152" },
    { lng: -117.6086, lat: 34.036085, postalCode: "91761" },
    { lng: -121.79724, lat: 36.678155, postalCode: "93933" },
    { lng: -117.82127, lat: 33.741651, postalCode: "92780" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91603" },
    { lng: -120.45741, lat: 38.377741, postalCode: "95257" },
    { lng: -120.70418, lat: 35.640589, postalCode: "93446" },
    { lng: -121.05989, lat: 40.017767, postalCode: "95984" },
    { lng: -122.2668, lat: 37.879625, postalCode: "94709" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95156" },
    { lng: -121.4418, lat: 40.646417, postalCode: "96071" },
    { lng: -118.38836, lat: 34.185698, postalCode: "91606" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94253" },
    { lng: -121.84025, lat: 40.338253, postalCode: "96075" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94662" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92822" },
    { lng: -117.165665, lat: 32.671602, postalCode: "92155" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95866" },
    { lng: -121.555406, lat: 38.380456, postalCode: "94203" },
    { lng: -114.62411, lat: 34.143497, postalCode: "92280" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94293" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91182" },
    { lng: -122.90474, lat: 39.172397, postalCode: "95485" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92516" },
    { lng: -122.6069, lat: 38.243299, postalCode: "94954" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90086" },
    { lng: -121.0314, lat: 37.312014, postalCode: "95360" },
    { lng: -118.018247, lat: 33.905813, postalCode: "90639" },
    { lng: -117.98464, lat: 34.046889, postalCode: "91746" },
    { lng: -117.11708, lat: 32.579555, postalCode: "91932" },
    { lng: -122.49029, lat: 37.943365, postalCode: "94964" },
    { lng: -118.25897, lat: 33.784018, postalCode: "90744" },
    { lng: -117.16888, lat: 34.063264, postalCode: "92374" },
    { lng: -117.69108, lat: 33.640223, postalCode: "92630" },
    { lng: -117.35826, lat: 34.198189, postalCode: "92407" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94979" },
    { lng: -122.38544, lat: 41.460403, postalCode: "96094" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91357" },
    { lng: -117.03636, lat: 34.894181, postalCode: "92311" },
    { lng: -117.66106, lat: 34.231581, postalCode: "91759" },
    { lng: -116.69355, lat: 33.337152, postalCode: "92086" },
    { lng: -121.23578, lat: 37.782332, postalCode: "95337" },
    { lng: -119.30082, lat: 34.401466, postalCode: "93022" },
    { lng: -120.38102, lat: 38.256839, postalCode: "95250" },
    { lng: -116.82606, lat: 32.718186, postalCode: "91935" },
    { lng: -117.80588, lat: 33.695576, postalCode: "92606" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93750" },
    { lng: -121.2631, lat: 38.817993, postalCode: "95765" },
    { lng: -120.05516, lat: 38.688329, postalCode: "95646" },
    { lng: -118.1949, lat: 33.944264, postalCode: "90280" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91716" },
    { lng: -117.63463, lat: 33.570763, postalCode: "92694" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92175" },
    { lng: -120.09682, lat: 37.432168, postalCode: "95306" },
    { lng: -117.0337, lat: 32.733451, postalCode: "91945" },
    { lng: -120.38428, lat: 36.523482, postalCode: "93608" },
    { lng: -119.998419, lat: 38.898142, postalCode: "96158" },
    { lng: -120.32285, lat: 37.297403, postalCode: "95365" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95157" },
    { lng: -122.21393, lat: 39.918271, postalCode: "96021" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94236" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92423" },
    { lng: -118.3078, lat: 34.003213, postalCode: "90062" },
    { lng: -117.11136, lat: 33.181543, postalCode: "92026" },
    { lng: -115.51208, lat: 33.131764, postalCode: "92233" },
    { lng: -122.740988, lat: 38.068036, postalCode: "94913" },
    { lng: -116.63034, lat: 32.588485, postalCode: "91980" },
    { lng: -119.01595, lat: 36.055121, postalCode: "93257" },
    { lng: -116.90601, lat: 34.481229, postalCode: "92356" },
    { lng: -118.46531, lat: 33.992411, postalCode: "90291" },
    { lng: -120.93973, lat: 40.074042, postalCode: "95934" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93716" },
    { lng: -124.22153, lat: 40.659753, postalCode: "95551" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94540" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91526" },
    { lng: -117.17729, lat: 34.538622, postalCode: "92307" },
    { lng: -121.07736, lat: 35.563246, postalCode: "93428" },
    { lng: -120.21804, lat: 39.180983, postalCode: "96146" },
    { lng: -122.02487, lat: 39.415041, postalCode: "95970" },
    { lng: -122.50819, lat: 37.539839, postalCode: "94037" },
    { lng: -121.98396, lat: 37.228594, postalCode: "95030" },
    { lng: -121.92312, lat: 37.256743, postalCode: "95124" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92164" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93410" },
    { lng: -121.14855, lat: 38.162818, postalCode: "95237" },
    { lng: -120.4065, lat: 40.34863, postalCode: "96128" },
    { lng: -117.33444, lat: 34.761696, postalCode: "92342" },
    { lng: -118.54581, lat: 34.276154, postalCode: "91326" },
    { lng: -121.20936, lat: 39.466279, postalCode: "95925" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92174" },
    { lng: -118.2658, lat: 34.152757, postalCode: "91203" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90832" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91051" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94153" },
    { lng: -118.810069, lat: 35.357018, postalCode: "93220" },
    { lng: -121.23825, lat: 38.789165, postalCode: "95677" },
    { lng: -122.710598, lat: 38.440061, postalCode: "95402" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94257" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92517" },
    { lng: -117.2608, lat: 33.614385, postalCode: "92595" },
    { lng: -119.2644, lat: 37.103957, postalCode: "93664" },
    { lng: -117.66467, lat: 33.460727, postalCode: "92624" },
    { lng: -122.96649, lat: 39.19607, postalCode: "95493" },
    { lng: -120.64285, lat: 38.192823, postalCode: "95249" },
    { lng: -122.92043, lat: 40.850776, postalCode: "96048" },
    { lng: -124.16703, lat: 41.782681, postalCode: "95531" },
    { lng: -121.572986, lat: 39.723898, postalCode: "95976" },
    { lng: -117.07221, lat: 32.794449, postalCode: "92120" },
    { lng: -120.23002, lat: 38.055888, postalCode: "95383" },
    { lng: -117.830788, lat: 33.686519, postalCode: "92623" },
    { lng: -121.63852, lat: 38.040395, postalCode: "94511" },
    { lng: -115.49467, lat: 35.46019, postalCode: "92364" },
    { lng: -118.25868, lat: 34.007063, postalCode: "90011" },
    { lng: -118.36425, lat: 33.758216, postalCode: "90275" },
    { lng: -121.49285, lat: 38.556576, postalCode: "95818" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93724" },
    { lng: -117.21994, lat: 34.956205, postalCode: "92347" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95164" },
    { lng: -119.81022, lat: 36.758598, postalCode: "93728" },
    { lng: -119.945035, lat: 37.160622, postalCode: "93639" },
    { lng: -117.26855, lat: 33.053092, postalCode: "92024" },
    { lng: -122.54668, lat: 38.414061, postalCode: "95452" },
    { lng: -118.243, lat: 34.138514, postalCode: "91205" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95208" },
    { lng: -117.45818, lat: 34.125147, postalCode: "92336" },
    { lng: -118.45299, lat: 35.617148, postalCode: "93240" },
    { lng: -119.899405, lat: 36.589797, postalCode: "93607" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93120" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90844" },
    { lng: -121.8981, lat: 39.070128, postalCode: "95950" },
    { lng: -119.848555, lat: 34.262834, postalCode: "93102" },
    { lng: -121.01515, lat: 36.504586, postalCode: "95043" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90091" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91521" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90845" },
    { lng: -118.80768, lat: 34.051056, postalCode: "90265" },
    { lng: -122.989975, lat: 38.463088, postalCode: "94927" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92650" },
    { lng: -117.08438, lat: 32.918035, postalCode: "92131" },
    { lng: -117.95381, lat: 33.844814, postalCode: "92801" },
    { lng: -118.00966, lat: 33.90213, postalCode: "90638" },
    { lng: -118.12392, lat: 34.169356, postalCode: "91104" },
    { lng: -117.6222, lat: 34.077327, postalCode: "91764" },
    { lng: -120.58771, lat: 41.439363, postalCode: "96101" },
    { lng: -118.476436, lat: 35.60345, postalCode: "93540" },
    { lng: -122.25074, lat: 38.152068, postalCode: "94589" },
    { lng: -121.55043, lat: 39.434644, postalCode: "95968" },
    { lng: -121.76514, lat: 37.226194, postalCode: "95139" },
    { lng: -121.96162, lat: 37.311042, postalCode: "95117" },
    { lng: -122.989975, lat: 38.463088, postalCode: "94975" },
    { lng: -115.35369, lat: 32.800259, postalCode: "92250" },
    { lng: -120.94365, lat: 37.731272, postalCode: "95367" },
    { lng: -121.40022, lat: 38.004922, postalCode: "95219" },
    { lng: -117.809881, lat: 33.711552, postalCode: "92710" },
    { lng: -116.90477, lat: 32.873849, postalCode: "92040" },
    { lng: -118.77177, lat: 34.265003, postalCode: "93065" },
    { lng: -122.18615, lat: 37.790027, postalCode: "94619" },
    { lng: -120.90316, lat: 41.425912, postalCode: "96015" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92142" },
    { lng: -117.98569, lat: 33.745166, postalCode: "92655" },
    { lng: -115.95998, lat: 32.722049, postalCode: "92259" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92046" },
    { lng: -120.35662, lat: 39.560357, postalCode: "96126" },
    { lng: -121.94461, lat: 37.412539, postalCode: "95134" },
    { lng: -122.27032, lat: 37.554335, postalCode: "94404" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94139" },
    { lng: -117.20296, lat: 35.945641, postalCode: "92328" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94263" },
    { lng: -117.71295, lat: 33.602823, postalCode: "92653" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93386" },
    { lng: -119.78473, lat: 36.733193, postalCode: "93721" },
    { lng: -121.2531, lat: 38.731976, postalCode: "95661" },
    { lng: -118.159, lat: 34.166706, postalCode: "91103" },
    { lng: -123.92367, lat: 40.322683, postalCode: "95571" },
    { lng: -121.56398, lat: 38.868919, postalCode: "95659" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94164" },
    { lng: -117.78618, lat: 33.739571, postalCode: "92782" },
    { lng: -122.12749, lat: 37.449888, postalCode: "94303" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93777" },
    { lng: -121.99086, lat: 36.9707, postalCode: "95062" },
    { lng: -120.186383, lat: 39.3781, postalCode: "96160" },
    { lng: -117.89705, lat: 33.985012, postalCode: "91748" },
    { lng: -120.5318, lat: 34.842293, postalCode: "93429" },
    { lng: -118.35355, lat: 33.888364, postalCode: "90260" },
    { lng: -117.96966, lat: 34.087108, postalCode: "91706" },
    { lng: -118.32745, lat: 34.098859, postalCode: "90028" },
    { lng: -118.73908, lat: 34.424363, postalCode: "93040" },
    { lng: -118.37689, lat: 33.895363, postalCode: "90261" },
    { lng: -118.35092, lat: 33.914614, postalCode: "90250" },
    { lng: -121.47668, lat: 40.788512, postalCode: "96040" },
    { lng: -116.72562, lat: 33.750795, postalCode: "92549" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91124" },
    { lng: -118.2829, lat: 34.026448, postalCode: "90007" },
    { lng: -122.20206, lat: 39.283329, postalCode: "95955" },
    { lng: -123.87105, lat: 40.35983, postalCode: "95569" },
    { lng: -123.38621, lat: 39.026811, postalCode: "95415" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92255" },
    { lng: -121.83359, lat: 37.329641, postalCode: "95122" },
    { lng: -122.24602, lat: 37.533538, postalCode: "94065" },
    { lng: -121.08227, lat: 39.396152, postalCode: "95960" },
    { lng: -122.8689, lat: 38.434663, postalCode: "95444" },
    { lng: -121.66211, lat: 37.137595, postalCode: "95037" },
    { lng: -119.1343, lat: 34.032383, postalCode: "91319" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94119" },
    { lng: -122.26372, lat: 37.894625, postalCode: "94708" },
    { lng: -120.50328, lat: 38.26127, postalCode: "95246" },
    { lng: -118.37177, lat: 33.871214, postalCode: "90278" },
    { lng: -122.859625, lat: 39.00686, postalCode: "95435" },
    { lng: -119.37143, lat: 35.605045, postalCode: "93280" },
    { lng: -121.949418, lat: 37.05297, postalCode: "95001" },
    { lng: -118.35587, lat: 33.840264, postalCode: "90503" },
    { lng: -119.26984, lat: 35.771876, postalCode: "93216" },
    { lng: -117.21311, lat: 32.993541, postalCode: "92091" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92326" },
    { lng: -122.46158, lat: 37.782029, postalCode: "94118" },
    { lng: -121.44561, lat: 38.477508, postalCode: "95823" },
    { lng: -116.8513, lat: 33.035844, postalCode: "92065" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93708" },
    { lng: -123.22938, lat: 38.506229, postalCode: "95450" },
    { lng: -120.52472, lat: 41.021344, postalCode: "96119" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90888" },
    { lng: -117.8548, lat: 34.119245, postalCode: "91740" },
    { lng: -122.18994, lat: 38.04337, postalCode: "94569" },
    { lng: -121.88667, lat: 36.586193, postalCode: "93940" },
    { lng: -121.955399, lat: 37.777208, postalCode: "94802" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91470" },
    { lng: -121.09142, lat: 38.976837, postalCode: "95602" },
    { lng: -117.21474, lat: 33.213309, postalCode: "92084" },
    { lng: -116.055617, lat: 33.752886, postalCode: "92514" },
    { lng: -122.25084, lat: 37.856926, postalCode: "94705" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91330" },
    { lng: -122.69317, lat: 38.457611, postalCode: "95404" },
    { lng: -117.65866, lat: 33.500843, postalCode: "92675" },
    { lng: -124.14367, lat: 40.583488, postalCode: "95540" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90309" },
    { lng: -120.13826, lat: 41.527226, postalCode: "96104" },
    { lng: -119.1343, lat: 34.032383, postalCode: "93006" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95304" },
    { lng: -120.196818, lat: 35.027682, postalCode: "93456" },
    { lng: -117.119744, lat: 32.85377, postalCode: "92137" },
    { lng: -118.11823, lat: 34.05471, postalCode: "91755" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93642" },
    { lng: -122.319655, lat: 37.803927, postalCode: "94625" },
    { lng: -116.64506, lat: 33.941487, postalCode: "92282" },
    { lng: -118.14206, lat: 34.192102, postalCode: "91001" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92711" },
    { lng: -123.47625, lat: 38.725628, postalCode: "95497" },
    { lng: -118.30863, lat: 34.06646, postalCode: "90020" },
    { lng: -122.271652, lat: 37.807827, postalCode: "94617" },
    { lng: -118.35558, lat: 33.975263, postalCode: "90302" },
    { lng: -118.26516, lat: 34.038993, postalCode: "90015" },
    { lng: -117.17432, lat: 33.742936, postalCode: "92585" },
    { lng: -121.14539, lat: 39.224179, postalCode: "95975" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94291" },
    { lng: -122.32044, lat: 37.570385, postalCode: "94401" },
    { lng: -121.77536, lat: 37.255915, postalCode: "95138" },
    { lng: -117.07236, lat: 33.369249, postalCode: "92059" },
    { lng: -118.57735, lat: 34.209407, postalCode: "91306" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92079" },
    { lng: -119.259352, lat: 36.471833, postalCode: "93670" },
    { lng: -122.67866, lat: 38.050454, postalCode: "94946" },
    { lng: -117.13785, lat: 32.7736, postalCode: "92108" },
    { lng: -116.38496, lat: 33.715672, postalCode: "92260" },
    { lng: -122.7275, lat: 38.404991, postalCode: "95407" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91175" },
    { lng: -122.29413, lat: 37.969673, postalCode: "94803" },
    { lng: -119.72069, lat: 34.40323, postalCode: "93109" },
    { lng: -122.02305, lat: 37.270543, postalCode: "95070" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94245" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91322" },
    { lng: -121.68682, lat: 38.546306, postalCode: "95618" },
    { lng: -122.33279, lat: 40.539302, postalCode: "96002" },
    { lng: -118.15581, lat: 34.023662, postalCode: "90022" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91714" },
    { lng: -119.66107, lat: 36.840057, postalCode: "93611" },
    { lng: -121.921498, lat: 37.680181, postalCode: "94712" },
    { lng: -120.12897, lat: 41.251918, postalCode: "96110" },
    { lng: -122.303136, lat: 37.819314, postalCode: "94626" },
    { lng: -117.61401, lat: 33.427078, postalCode: "92672" },
    { lng: -118.9601, lat: 36.678073, postalCode: "93603" },
    { lng: -124.02379, lat: 40.033316, postalCode: "95589" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92378" },
    { lng: -118.43821, lat: 34.284935, postalCode: "91340" },
    { lng: -121.27978, lat: 37.882742, postalCode: "95231" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90051" },
    { lng: -122.06026, lat: 37.000199, postalCode: "95064" },
    { lng: -122.54284, lat: 37.950069, postalCode: "94904" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92406" },
    { lng: -121.69439, lat: 39.25209, postalCode: "95953" },
    { lng: -117.90309, lat: 34.128907, postalCode: "91702" },
    { lng: -120.937052, lat: 37.60396, postalCode: "95381" },
    { lng: -118.466755, lat: 35.717406, postalCode: "93825" },
    { lng: -117.53581, lat: 33.839516, postalCode: "92881" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91312" },
    { lng: -118.199401, lat: 33.767817, postalCode: "90834" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91399" },
    { lng: -122.48478, lat: 37.75838, postalCode: "94122" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92085" },
    { lng: -117.67264, lat: 35.030694, postalCode: "93516" },
    { lng: -119.63184, lat: 34.433687, postalCode: "93108" },
    { lng: -118.253825, lat: 33.867138, postalCode: "90747" },
    { lng: -117.93087, lat: 33.619221, postalCode: "92663" },
    { lng: -118.20765, lat: 34.137557, postalCode: "90041" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93389" },
    { lng: -122.9206, lat: 41.831947, postalCode: "96050" },
    { lng: -118.06778, lat: 33.978312, postalCode: "90606" },
    { lng: -117.19132, lat: 34.25629, postalCode: "92352" },
    { lng: -121.6196, lat: 39.114138, postalCode: "95991" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95812" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92628" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92088" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94248" },
    { lng: -121.444429, lat: 38.377411, postalCode: "94243" },
    { lng: -117.26744, lat: 33.097841, postalCode: "92009" },
    { lng: -115.967051, lat: 34.839964, postalCode: "92322" },
    { lng: -118.31611, lat: 33.793384, postalCode: "90717" },
    { lng: -121.29723, lat: 38.267544, postalCode: "95632" },
    { lng: -122.10148, lat: 40.577799, postalCode: "96062" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91617" },
    { lng: -116.85442, lat: 34.255388, postalCode: "92314" },
    { lng: -118.25832, lat: 34.110425, postalCode: "90039" },
    { lng: -118.08974, lat: 34.151066, postalCode: "91107" },
    { lng: -120.00772, lat: 36.015318, postalCode: "93239" },
    { lng: -118.3183, lat: 34.029112, postalCode: "90018" },
    { lng: -118.2668, lat: 33.823765, postalCode: "90745" },
    { lng: -121.28247, lat: 38.605355, postalCode: "95670" },
    { lng: -122.18296, lat: 37.878625, postalCode: "94563" },
    { lng: -117.97084, lat: 36.228484, postalCode: "93549" },
    { lng: -118.59426, lat: 34.259052, postalCode: "91311" },
    { lng: -115.915905, lat: 33.521993, postalCode: "92554" },
    { lng: -121.38181, lat: 38.623304, postalCode: "95821" },
    { lng: -118.31123, lat: 33.736718, postalCode: "90732" },
    { lng: -117.62414, lat: 33.462927, postalCode: "92673" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92850" },
    { lng: -122.52253, lat: 41.925752, postalCode: "96044" },
    { lng: -121.44663, lat: 38.535795, postalCode: "95820" },
    { lng: -119.112432, lat: 34.113389, postalCode: "93042" },
    { lng: -117.58518, lat: 33.639227, postalCode: "92679" },
    { lng: -119.639658, lat: 36.746375, postalCode: "93784" },
    { lng: -122.09793, lat: 40.041399, postalCode: "96055" },
    { lng: -120.8275, lat: 38.426602, postalCode: "95601" },
    { lng: -118.72424, lat: 35.727428, postalCode: "93226" },
    { lng: -118.48224, lat: 34.239503, postalCode: "91343" },
    { lng: -119.72439, lat: 37.388698, postalCode: "93601" },
    { lng: -122.22457, lat: 37.28842, postalCode: "94020" },
    { lng: -122.87744, lat: 41.615475, postalCode: "96032" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94145" },
    { lng: -122.04779, lat: 37.317909, postalCode: "95014" },
    { lng: -117.27357, lat: 34.080662, postalCode: "92408" },
    { lng: -118.35255, lat: 34.029711, postalCode: "90016" },
    { lng: -120.69292, lat: 38.527556, postalCode: "95629" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95101" },
    { lng: -117.23079, lat: 32.7259, postalCode: "92106" },
    { lng: -118.18235, lat: 33.828366, postalCode: "90807" },
    { lng: -122.44323, lat: 37.47584, postalCode: "94019" },
    { lng: -122.77762, lat: 38.054433, postalCode: "94950" },
    { lng: -121.253872, lat: 37.889849, postalCode: "95378" },
    { lng: -122.41087, lat: 37.772329, postalCode: "94103" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91899" },
    { lng: -119.17399, lat: 35.200467, postalCode: "93311" },
    { lng: -116.846046, lat: 33.016928, postalCode: "91943" },
    { lng: -118.494107, lat: 34.010328, postalCode: "90421" },
    { lng: -122.61069, lat: 38.791139, postalCode: "95461" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90073" },
    { lng: -118.1462, lat: 34.072959, postalCode: "91803" },
    { lng: -117.81623, lat: 33.812662, postalCode: "92861" },
    { lng: -123.20088, lat: 39.154946, postalCode: "95482" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91495" },
    { lng: -120.455345, lat: 35.347065, postalCode: "93408" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92135" },
    { lng: -117.218616, lat: 36.626033, postalCode: "93515" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90707" },
    { lng: -118.51766, lat: 34.235055, postalCode: "91325" },
    { lng: -119.43761, lat: 36.608675, postalCode: "93654" },
    { lng: -120.937052, lat: 37.60396, postalCode: "95352" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90080" },
    { lng: -122.45695, lat: 38.511241, postalCode: "94574" },
    { lng: -122.40422, lat: 37.682882, postalCode: "94005" },
    { lng: -118.15015, lat: 34.698696, postalCode: "93534" },
    { lng: -120.41039, lat: 39.311156, postalCode: "95728" },
    { lng: -120.61952, lat: 35.120934, postalCode: "93433" },
    { lng: -122.11501, lat: 37.998912, postalCode: "94553" },
    { lng: -121.21416, lat: 37.812815, postalCode: "95336" },
    { lng: -123.86447, lat: 40.210793, postalCode: "95553" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90202" },
    { lng: -118.127, lat: 34.090409, postalCode: "91801" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94067" },
    { lng: -120.58014, lat: 34.958896, postalCode: "93434" },
    { lng: -118.905173, lat: 35.294405, postalCode: "93388" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91778" },
    { lng: -121.32617, lat: 36.41176, postalCode: "93960" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94163" },
    { lng: -118.42406, lat: 34.254751, postalCode: "91331" },
    { lng: -121.90799, lat: 38.35011, postalCode: "95625" },
    { lng: -115.39727, lat: 35.187237, postalCode: "92323" },
    { lng: -118.16329, lat: 34.137857, postalCode: "91105" },
    { lng: -116.55951, lat: 34.066626, postalCode: "92256" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94003" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94168" },
    { lng: -118.47286, lat: 34.009276, postalCode: "90405" },
    { lng: -120.79861, lat: 40.02349, postalCode: "96135" },
    { lng: -118.298662, lat: 33.786594, postalCode: "91209" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90264" },
    { lng: -123.5223, lat: 39.745588, postalCode: "95454" },
    { lng: -117.09844, lat: 32.822815, postalCode: "92124" },
    { lng: -120.48392, lat: 35.495242, postalCode: "93432" },
    { lng: -120.2051, lat: 34.613014, postalCode: "93427" },
    { lng: -118.298662, lat: 33.786594, postalCode: "93584" },
    { lng: -118.298662, lat: 33.786594, postalCode: "90094" },
    { lng: -115.967051, lat: 34.839964, postalCode: "91708" },
    { lng: -118.06754, lat: 33.868314, postalCode: "90703" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92193" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94026" },
    { lng: -118.238683, lat: 33.783038, postalCode: "90774" },
    { lng: -116.846046, lat: 33.016928, postalCode: "92197" },
    { lng: -117.07657, lat: 33.495343, postalCode: "92592" },
    { lng: -120.91784, lat: 36.126393, postalCode: "93954" },
    { lng: -122.334825, lat: 37.381144, postalCode: "94017" },
    { lng: -122.36228, lat: 38.403011, postalCode: "94599" },
    { lng: -122.22289, lat: 37.827126, postalCode: "94611" },
    { lng: -118.21773, lat: 33.978404, postalCode: "90255" },
    { lng: -122.67419, lat: 38.438161, postalCode: "95405" },
    { lng: -123.944021, lat: 40.733552, postalCode: "95534" },
    { lng: -117.769442, lat: 33.640302, postalCode: "92815" },
    { lng: -117.26087, lat: 32.997543, postalCode: "92075" },
    { lng: -119.76293, lat: 36.795249, postalCode: "93726" },
    { lng: -122.13273, lat: 37.419389, postalCode: "94306" },
    { lng: -117.91224, lat: 33.903164, postalCode: "92835" },
    { lng: -117.9637, lat: 33.874314, postalCode: "92833" },
    { lng: -121.444429, lat: 38.377411, postalCode: "95857" },
    { lng: -121.411909, lat: 36.85443, postalCode: "95024" },
    { lng: -119.34994, lat: 36.201451, postalCode: "93274" },
    { lng: -121.68452, lat: 40.926289, postalCode: "96013" },
    { lng: -121.705327, lat: 37.189396, postalCode: "95114" },
    { lng: -116.0688, lat: 33.466287, postalCode: "92274" },
    { lng: -118.24255, lat: 34.044662, postalCode: "90013" },
    { lng: -122.727802, lat: 37.784827, postalCode: "94188" },
    { lng: -116.41475, lat: 48.575219, postalCode: "83847" },
    { lng: -116.62363, lat: 46.216165, postalCode: "83555" },
    { lng: -116.545493, lat: 48.36875, postalCode: "83862" },
    { lng: -116.15905, lat: 48.119781, postalCode: "83811" },
    { lng: -116.99158, lat: 47.091551, postalCode: "99128" },
    { lng: -116.82431, lat: 46.871275, postalCode: "83857" },
    { lng: -116.57917, lat: 47.456917, postalCode: "83842" },
    { lng: -116.57392, lat: 47.274904, postalCode: "83861" },
    { lng: -115.88759, lat: 47.533448, postalCode: "83873" },
    { lng: -116.545493, lat: 48.36875, postalCode: "83825" },
    { lng: -116.48168, lat: 46.223309, postalCode: "83523" },
    { lng: -116.3187, lat: 46.505902, postalCode: "83520" },
    { lng: -116.5571, lat: 48.205853, postalCode: "83860" },
    { lng: -116.54146, lat: 46.635799, postalCode: "83537" },
    { lng: -116.43823, lat: 46.460115, postalCode: "83545" },
    { lng: -116.7757, lat: 47.66655, postalCode: "83814" },
    { lng: -116.13626, lat: 48.720842, postalCode: "83845" },
    { lng: -116.91914, lat: 46.552921, postalCode: "83832" },
    { lng: -116.11848, lat: 47.542783, postalCode: "83837" },
    { lng: -116.42113, lat: 47.161756, postalCode: "83866" },
    { lng: -116.90904, lat: 48.315799, postalCode: "83856" },
    { lng: -116.13146, lat: 45.923869, postalCode: "83530" },
    { lng: -116.18156, lat: 47.542989, postalCode: "83868" },
    { lng: -116.41051, lat: 48.770783, postalCode: "83805" },
    { lng: -116.88379, lat: 47.322203, postalCode: "83851" },
    { lng: -116.94417, lat: 47.123919, postalCode: "83824" },
    { lng: -116.74907, lat: 47.773595, postalCode: "83835" },
    { lng: -116.46535, lat: 47.532742, postalCode: "83810" },
    { lng: -116.78649, lat: 46.39708, postalCode: "83540" },
    { lng: -115.80199, lat: 47.173432, postalCode: "83802" },
    { lng: -116.94564, lat: 47.720306, postalCode: "83854" },
    { lng: -116.56168, lat: 47.982523, postalCode: "83803" },
    { lng: -116.5854, lat: 48.055621, postalCode: "83809" },
    { lng: -116.98484, lat: 46.388753, postalCode: "83501" },
    { lng: -115.72485, lat: 46.122654, postalCode: "83539" },
    { lng: -116.74475, lat: 47.498424, postalCode: "83833" },
    { lng: -116.96316, lat: 46.861905, postalCode: "83872" },
    { lng: -116.545493, lat: 48.36875, postalCode: "83888" },
    { lng: -116.684553, lat: 46.836284, postalCode: "83844" },
    { lng: -115.557407, lat: 45.889314, postalCode: "83531" },
    { lng: -115.25273, lat: 45.920694, postalCode: "83525" },
    { lng: -115.9769, lat: 46.093893, postalCode: "83552" },
    { lng: -116.40471, lat: 46.154136, postalCode: "83526" },
    { lng: -117.02483, lat: 48.168577, postalCode: "83822" },
    { lng: -116.26936, lat: 45.717554, postalCode: "83554" },
    { lng: -116.71663, lat: 46.973846, postalCode: "83834" },
    { lng: -116.625594, lat: 48.363251, postalCode: "83865" },
    { lng: -116.03726, lat: 46.224145, postalCode: "83536" },
    { lng: -116.53305, lat: 48.30476, postalCode: "83852" },
    { lng: -116.63906, lat: 46.363204, postalCode: "83524" },
    { lng: -116.38568, lat: 46.041157, postalCode: "83522" },
    { lng: -115.82276, lat: 46.525642, postalCode: "83546" },
    { lng: -116.666843, lat: 46.375238, postalCode: "83534" },
    { lng: -116.52939, lat: 46.332945, postalCode: "83548" },
    { lng: -116.98833, lat: 48.021497, postalCode: "83804" },
    { lng: -116.682718, lat: 47.678798, postalCode: "83877" },
    { lng: -116.53078, lat: 48.953737, postalCode: "83853" },
    { lng: -115.90101, lat: 46.368447, postalCode: "83553" },
    { lng: -116.515276, lat: 48.314378, postalCode: "83840" },
    { lng: -116.25403, lat: 46.106803, postalCode: "83533" },
    { lng: -116.91671, lat: 46.95597, postalCode: "83855" },
    { lng: -116.74693, lat: 46.747944, postalCode: "83871" },
    { lng: -116.26693, lat: 46.999999, postalCode: "83812" },
    { lng: -116.86895, lat: 47.97618, postalCode: "83869" },
    { lng: -116.01495, lat: 47.517239, postalCode: "83849" },
    { lng: -116.54841, lat: 46.79844, postalCode: "83823" },
    { lng: -116.98067, lat: 46.727636, postalCode: "83843" },
    { lng: -116.64794, lat: 48.108672, postalCode: "83813" },
    { lng: -115.9555, lat: 47.495306, postalCode: "83867" },
    { lng: -116.1865, lat: 48.974293, postalCode: "83826" },
    { lng: -116.89426, lat: 48.649023, postalCode: "83848" },
    { lng: -116.20071, lat: 46.485334, postalCode: "83544" },
    { lng: -116.24128, lat: 47.512203, postalCode: "83850" },
    { lng: -116.83491, lat: 48.582545, postalCode: "83821" },
    { lng: -116.90486, lat: 47.446701, postalCode: "83876" },
    { lng: -116.682718, lat: 47.678798, postalCode: "83816" },
    { lng: -116.89647, lat: 47.155827, postalCode: "83870" },
    { lng: -116.76728, lat: 48.161421, postalCode: "83841" },
    { lng: -116.20774, lat: 47.566703, postalCode: "83839" },
    { lng: -116.7202, lat: 46.542771, postalCode: "83535" },
    { lng: -116.27423, lat: 48.229101, postalCode: "83836" },
    { lng: -116.804544, lat: 46.400349, postalCode: "83551" },
    { lng: -116.24922, lat: 46.256652, postalCode: "83543" },
    { lng: -116.39726, lat: 46.868617, postalCode: "83806" },
    { lng: -116.38764, lat: 47.10625, postalCode: "83830" },
    { lng: -116.17964, lat: 46.779767, postalCode: "83827" },
    { lng: -116.55101, lat: 48.362033, postalCode: "83864" },
    { lng: -116.301, lat: 45.548413, postalCode: "83542" },
    { lng: -116.12134, lat: 47.269078, postalCode: "83808" },
    { lng: -116.46896, lat: 46.52808, postalCode: "83541" },
    { lng: -116.8783, lat: 47.818931, postalCode: "83858" },
    { lng: -116.78897, lat: 47.718227, postalCode: "83815" },
    { lng: -115.78377, lat: 47.466971, postalCode: "83846" },
    { lng: -116.66605, lat: 47.943718, postalCode: "83801" },
    { lng: -115.83204, lat: 47.626188, postalCode: "83874" },
    { lng: -116.02944, lat: 39.717627, postalCode: "89316" },
    { lng: -115.43542, lat: 41.905845, postalCode: "89826" },
    { lng: -119.93964, lat: 39.083284, postalCode: "89413" },
    { lng: -118.70342, lat: 38.505507, postalCode: "89415" },
    { lng: -115.148644, lat: 36.281388, postalCode: "89084" },
    { lng: -119.35634, lat: 39.835829, postalCode: "89424" },
    { lng: -115.08872, lat: 36.087623, postalCode: "89120" },
    { lng: -115.39948, lat: 36.101538, postalCode: "89124" },
    { lng: -115.64745, lat: 40.644201, postalCode: "89815" },
    { lng: -114.6447, lat: 35.013978, postalCode: "89028" },
    { lng: -114.9208, lat: 39.282855, postalCode: "89301" },
    { lng: -114.824507, lat: 39.332636, postalCode: "89315" },
    { lng: -117.64225, lat: 37.751386, postalCode: "89047" },
    { lng: -119.356505, lat: 40.652505, postalCode: "89412" },
    { lng: -116.598559, lat: 37.583805, postalCode: "89045" },
    { lng: -115.132695, lat: 36.28094, postalCode: "89086" },
    { lng: -118.174506, lat: 41.263287, postalCode: "89438" },
    { lng: -115.26791, lat: 36.167731, postalCode: "89145" },
    { lng: -115.40266, lat: 41.252236, postalCode: "89823" },
    { lng: -115.16292, lat: 36.184219, postalCode: "89106" },
    { lng: -119.74536, lat: 39.147877, postalCode: "89701" },
    { lng: -115.14796, lat: 36.126038, postalCode: "89109" },
    { lng: -119.89808, lat: 39.523088, postalCode: "89523" },
    { lng: -118.67848, lat: 38.966064, postalCode: "89427" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89180" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89137" },
    { lng: -115.22493, lat: 36.141119, postalCode: "89146" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89595" },
    { lng: -119.75875, lat: 39.135503, postalCode: "89702" },
    { lng: -119.293722, lat: 39.631922, postalCode: "89513" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89564" },
    { lng: -119.71313, lat: 39.87107, postalCode: "89510" },
    { lng: -118.343592, lat: 39.537979, postalCode: "89496" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89016" },
    { lng: -119.11544, lat: 38.94675, postalCode: "89447" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89006" },
    { lng: -115.33257, lat: 40.453542, postalCode: "89833" },
    { lng: -115.12318, lat: 36.206419, postalCode: "89030" },
    { lng: -114.66488, lat: 36.674946, postalCode: "89025" },
    { lng: -116.05489, lat: 41.796309, postalCode: "89831" },
    { lng: -119.946371, lat: 39.256357, postalCode: "89450" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89432" },
    { lng: -114.885945, lat: 39.342631, postalCode: "89319" },
    { lng: -115.21682, lat: 36.171219, postalCode: "89107" },
    { lng: -115.03358, lat: 36.201612, postalCode: "89156" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89070" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89053" },
    { lng: -114.819717, lat: 36.322484, postalCode: "88901" },
    { lng: -119.906109, lat: 39.543941, postalCode: "89533" },
    { lng: -115.0903, lat: 36.121972, postalCode: "89121" },
    { lng: -115.13905, lat: 36.088922, postalCode: "89119" },
    { lng: -115.0778, lat: 36.045811, postalCode: "89014" },
    { lng: -114.972061, lat: 35.927901, postalCode: "88905" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89026" },
    { lng: -115.674671, lat: 36.573377, postalCode: "89081" },
    { lng: -117.31507, lat: 37.571495, postalCode: "89013" },
    { lng: -114.62875, lat: 35.138674, postalCode: "89029" },
    { lng: -114.87299, lat: 35.28299, postalCode: "89039" },
    { lng: -119.83072, lat: 39.496243, postalCode: "89509" },
    { lng: -115.151937, lat: 36.018981, postalCode: "89132" },
    { lng: -115.21849, lat: 36.113211, postalCode: "89103" },
    { lng: -115.529596, lat: 41.059414, postalCode: "89802" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89126" },
    { lng: -119.70003, lat: 39.544183, postalCode: "89434" },
    { lng: -118.19513, lat: 38.271173, postalCode: "89422" },
    { lng: -115.28676, lat: 37.592182, postalCode: "89017" },
    { lng: -118.11473, lat: 40.700131, postalCode: "89418" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89009" },
    { lng: -115.32331, lat: 36.131341, postalCode: "89135" },
    { lng: -114.77624, lat: 39.4163, postalCode: "89318" },
    { lng: -116.12251, lat: 41.328852, postalCode: "89834" },
    { lng: -119.8373, lat: 39.53636, postalCode: "89503" },
    { lng: -119.357959, lat: 40.651432, postalCode: "89912" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89185" },
    { lng: -115.22728, lat: 36.257531, postalCode: "89130" },
    { lng: -115.28656, lat: 36.266266, postalCode: "89149" },
    { lng: -115.25788, lat: 36.326315, postalCode: "89143" },
    { lng: -115.134488, lat: 36.284511, postalCode: "89033" },
    { lng: -119.64083, lat: 39.262835, postalCode: "89428" },
    { lng: -119.72735, lat: 39.395387, postalCode: "89521" },
    { lng: -115.31207, lat: 36.204548, postalCode: "89134" },
    { lng: -116.11426, lat: 40.715963, postalCode: "89822" },
    { lng: -117.31706, lat: 40.926644, postalCode: "89414" },
    { lng: -114.96787, lat: 36.030927, postalCode: "89015" },
    { lng: -119.87918, lat: 39.659801, postalCode: "89506" },
    { lng: -115.75016, lat: 40.883028, postalCode: "89801" },
    { lng: -115.24685, lat: 36.294596, postalCode: "89131" },
    { lng: -115.2103, lat: 36.084361, postalCode: "89118" },
    { lng: -115.22363, lat: 36.205718, postalCode: "89108" },
    { lng: -118.08592, lat: 37.751707, postalCode: "89010" },
    { lng: -119.35093, lat: 40.555197, postalCode: "89405" },
    { lng: -119.77497, lat: 39.499862, postalCode: "89502" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89153" },
    { lng: -117.72501, lat: 41.953624, postalCode: "89421" },
    { lng: -115.086999, lat: 36.038181, postalCode: "89074" },
    { lng: -115.32557, lat: 36.181142, postalCode: "89144" },
    { lng: -116.51585, lat: 40.450972, postalCode: "89821" },
    { lng: -119.95204, lat: 39.245257, postalCode: "89451" },
    { lng: -118.842431, lat: 39.525628, postalCode: "89407" },
    { lng: -114.08749, lat: 36.804651, postalCode: "89027" },
    { lng: -119.69753, lat: 38.878641, postalCode: "89410" },
    { lng: -119.776409, lat: 39.167833, postalCode: "89721" },
    { lng: -115.05795, lat: 36.172744, postalCode: "89110" },
    { lng: -114.83354, lat: 35.968615, postalCode: "89005" },
    { lng: -115.27343, lat: 36.113059, postalCode: "89147" },
    { lng: -118.71295, lat: 41.826461, postalCode: "89404" },
    { lng: -117.78194, lat: 38.872139, postalCode: "89409" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89158" },
    { lng: -115.05438, lat: 36.017578, postalCode: "89012" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89504" },
    { lng: -115.28485, lat: 36.049095, postalCode: "89148" },
    { lng: -114.45478, lat: 36.540495, postalCode: "89040" },
    { lng: -119.816374, lat: 39.54231, postalCode: "89507" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89155" },
    { lng: -115.4113, lat: 41.029538, postalCode: "89824" },
    { lng: -119.24618, lat: 39.367838, postalCode: "89429" },
    { lng: -116.598559, lat: 37.583805, postalCode: "89023" },
    { lng: -119.971133, lat: 39.239457, postalCode: "89402" },
    { lng: -119.94762, lat: 39.006963, postalCode: "89448" },
    { lng: -119.79896, lat: 39.091507, postalCode: "89705" },
    { lng: -119.30527, lat: 39.659593, postalCode: "89442" },
    { lng: -119.79382, lat: 39.164966, postalCode: "89703" },
    { lng: -115.14848, lat: 36.026532, postalCode: "89123" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89570" },
    { lng: -115.06881, lat: 36.233655, postalCode: "89115" },
    { lng: -117.08692, lat: 40.489736, postalCode: "89820" },
    { lng: -116.92779, lat: 38.307931, postalCode: "89049" },
    { lng: -119.75557, lat: 39.009564, postalCode: "89423" },
    { lng: -119.75445, lat: 39.542711, postalCode: "89431" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89163" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89520" },
    { lng: -115.26039, lat: 36.067495, postalCode: "89113" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89515" },
    { lng: -115.16139, lat: 36.256479, postalCode: "89031" },
    { lng: -119.776409, lat: 39.167833, postalCode: "89714" },
    { lng: -115.18358, lat: 36.145303, postalCode: "89102" },
    { lng: -115.79241, lat: 40.81488, postalCode: "89803" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89152" },
    { lng: -115.44828, lat: 40.79727, postalCode: "89828" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89036" },
    { lng: -119.956585, lat: 39.259103, postalCode: "89452" },
    { lng: -119.763147, lat: 38.903324, postalCode: "89460" },
    { lng: -114.14713, lat: 40.854751, postalCode: "89883" },
    { lng: -119.77467, lat: 39.59218, postalCode: "89433" },
    { lng: -119.922973, lat: 38.971319, postalCode: "89779" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89150" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89170" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89116" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89138" },
    { lng: -115.101508, lat: 36.011339, postalCode: "89114" },
    { lng: -118.39475, lat: 40.21672, postalCode: "89419" },
    { lng: -114.92514, lat: 36.121607, postalCode: "89011" },
    { lng: -117.760165, lat: 41.076358, postalCode: "89446" },
    { lng: -115.265529, lat: 36.223528, postalCode: "89125" },
    { lng: -115.70749, lat: 38.944921, postalCode: "89314" },
    { lng: -119.70585, lat: 39.624698, postalCode: "89436" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89159" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89199" },
    { lng: -117.0789, lat: 38.738647, postalCode: "89022" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89111" },
    { lng: -116.038857, lat: 36.264185, postalCode: "89060" },
    { lng: -119.31697, lat: 38.784128, postalCode: "89430" },
    { lng: -119.34585, lat: 38.738712, postalCode: "89444" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89164" },
    { lng: -117.95538, lat: 41.656001, postalCode: "89425" },
    { lng: -115.265, lat: 36.193501, postalCode: "89128" },
    { lng: -118.21818, lat: 38.555308, postalCode: "89420" },
    { lng: -118.69306, lat: 39.461072, postalCode: "89406" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89599" },
    { lng: -114.13298, lat: 36.766887, postalCode: "89007" },
    { lng: -115.02628, lat: 38.835421, postalCode: "89317" },
    { lng: -115.04592, lat: 36.10524, postalCode: "89122" },
    { lng: -114.38399, lat: 37.785734, postalCode: "89042" },
    { lng: -117.94402, lat: 41.02951, postalCode: "89445" },
    { lng: -119.81367, lat: 39.52616, postalCode: "89501" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89133" },
    { lng: -115.025559, lat: 36.157764, postalCode: "89112" },
    { lng: -115.19588, lat: 35.998778, postalCode: "89141" },
    { lng: -119.72732, lat: 39.200035, postalCode: "89706" },
    { lng: -115.28205, lat: 36.140974, postalCode: "89117" },
    { lng: -119.776409, lat: 39.167833, postalCode: "89711" },
    { lng: -115.23128, lat: 36.370315, postalCode: "89191" },
    { lng: -115.20342, lat: 36.031873, postalCode: "89139" },
    { lng: -119.835275, lat: 39.52241, postalCode: "89505" },
    { lng: -115.322782, lat: 36.129519, postalCode: "89136" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89154" },
    { lng: -119.7908, lat: 39.27521, postalCode: "89704" },
    { lng: -115.67024, lat: 36.573712, postalCode: "89018" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89193" },
    { lng: -119.7606, lat: 39.403178, postalCode: "89511" },
    { lng: -119.17374, lat: 39.617711, postalCode: "89408" },
    { lng: -119.79664, lat: 39.54641, postalCode: "89512" },
    { lng: -115.10857, lat: 36.151108, postalCode: "89104" },
    { lng: -114.47184, lat: 36.616546, postalCode: "89021" },
    { lng: -115.31502, lat: 37.381946, postalCode: "89001" },
    { lng: -115.12785, lat: 36.17022, postalCode: "89101" },
    { lng: -115.1763, lat: 36.224218, postalCode: "89032" },
    { lng: -116.84011, lat: 37.027453, postalCode: "89003" },
    { lng: -114.45374, lat: 37.488849, postalCode: "89008" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89435" },
    { lng: -115.04767, lat: 36.148417, postalCode: "89142" },
    { lng: -117.40232, lat: 41.596722, postalCode: "89426" },
    { lng: -117.01152, lat: 39.748753, postalCode: "89310" },
    { lng: -119.83288, lat: 39.012361, postalCode: "89411" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89151" },
    { lng: -119.776409, lat: 39.167833, postalCode: "89712" },
    { lng: -119.586934, lat: 40.541218, postalCode: "89557" },
    { lng: -114.21077, lat: 41.247573, postalCode: "89830" },
    { lng: -114.91149, lat: 35.525338, postalCode: "89046" },
    { lng: -114.12592, lat: 38.941805, postalCode: "89311" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89173" },
    { lng: -115.28461, lat: 36.231139, postalCode: "89129" },
    { lng: -115.57819, lat: 35.801952, postalCode: "89019" },
    { lng: -119.65351, lat: 39.298192, postalCode: "89440" },
    { lng: -115.40483, lat: 36.046247, postalCode: "89004" },
    { lng: -119.776409, lat: 39.167833, postalCode: "89713" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89127" },
    { lng: -115.09895, lat: 35.979863, postalCode: "89052" },
    { lng: -119.776409, lat: 39.167833, postalCode: "89710" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89160" },
    { lng: -116.46173, lat: 36.52513, postalCode: "89020" },
    { lng: -116.00994, lat: 36.218341, postalCode: "89048" },
    { lng: -119.91364, lat: 38.968383, postalCode: "89449" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89195" },
    { lng: -114.44545, lat: 41.890872, postalCode: "89825" },
    { lng: -116.17725, lat: 41.951703, postalCode: "89832" },
    { lng: -119.51494, lat: 39.25468, postalCode: "89403" },
    { lng: -114.972061, lat: 35.927901, postalCode: "89177" },
    { lng: -114.86098, lat: 41.208288, postalCode: "89835" },
    { lng: -116.004795, lat: 36.655797, postalCode: "89041" },
    { lng: -114.40318, lat: 38.122239, postalCode: "89043" },
    { lng: -115.938992, lat: 36.119299, postalCode: "89061" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97255" },
    { lng: -123.14894, lat: 42.790958, postalCode: "97410" },
    { lng: -123.3235, lat: 45.744404, postalCode: "97144" },
    { lng: -122.75701, lat: 43.958389, postalCode: "97438" },
    { lng: -124.05039, lat: 44.649252, postalCode: "97365" },
    { lng: -123.9737, lat: 45.101518, postalCode: "97149" },
    { lng: -118.95122, lat: 44.408646, postalCode: "97845" },
    { lng: -122.08703, lat: 44.727388, postalCode: "97342" },
    { lng: -122.39736, lat: 44.754247, postalCode: "97346" },
    { lng: -123.38603, lat: 44.181193, postalCode: "97419" },
    { lng: -123.11304, lat: 45.2455, postalCode: "97127" },
    { lng: -117.2181, lat: 44.798064, postalCode: "97870" },
    { lng: -118.61882, lat: 43.036496, postalCode: "97721" },
    { lng: -122.04524, lat: 42.686498, postalCode: "97626" },
    { lng: -118.2229, lat: 44.744446, postalCode: "97877" },
    { lng: -122.50488, lat: 45.539473, postalCode: "97230" },
    { lng: -123.70193, lat: 44.164325, postalCode: "97430" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97292" },
    { lng: -123.62349, lat: 44.369264, postalCode: "97324" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97282" },
    { lng: -123.324, lat: 42.676455, postalCode: "97497" },
    { lng: -119.21099, lat: 45.786324, postalCode: "97875" },
    { lng: -121.01016, lat: 43.331581, postalCode: "97735" },
    { lng: -122.86404, lat: 45.821358, postalCode: "97053" },
    { lng: -118.28859, lat: 45.800879, postalCode: "97886" },
    { lng: -122.893292, lat: 44.634439, postalCode: "97335" },
    { lng: -122.95745, lat: 45.491024, postalCode: "97123" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97271" },
    { lng: -119.54241, lat: 44.474491, postalCode: "97825" },
    { lng: -121.32302, lat: 44.814466, postalCode: "97761" },
    { lng: -118.45927, lat: 43.40878, postalCode: "97732" },
    { lng: -122.97186, lat: 45.215261, postalCode: "97137" },
    { lng: -123.08639, lat: 42.432422, postalCode: "97525" },
    { lng: -122.95714, lat: 46.054948, postalCode: "97048" },
    { lng: -122.87944, lat: 42.313498, postalCode: "97501" },
    { lng: -124.05729, lat: 44.821446, postalCode: "97341" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97242" },
    { lng: -123.96804, lat: 45.461852, postalCode: "97134" },
    { lng: -122.54822, lat: 42.530663, postalCode: "97522" },
    { lng: -123.43709, lat: 44.578526, postalCode: "97370" },
    { lng: -122.56555, lat: 45.10799, postalCode: "97038" },
    { lng: -118.36952, lat: 45.938511, postalCode: "97862" },
    { lng: -124.39911, lat: 43.113799, postalCode: "97411" },
    { lng: -120.75969, lat: 45.479419, postalCode: "97039" },
    { lng: -123.13604, lat: 44.101227, postalCode: "97404" },
    { lng: -124.231068, lat: 43.246918, postalCode: "97407" },
    { lng: -123.206, lat: 45.862577, postalCode: "97064" },
    { lng: -122.90709, lat: 44.070824, postalCode: "97478" },
    { lng: -122.80683, lat: 45.46059, postalCode: "97008" },
    { lng: -123.25498, lat: 42.934754, postalCode: "97417" },
    { lng: -124.03929, lat: 43.905878, postalCode: "97493" },
    { lng: -123.5078, lat: 42.542134, postalCode: "97532" },
    { lng: -122.72945, lat: 45.49759, postalCode: "97221" },
    { lng: -120.67244, lat: 44.904051, postalCode: "97001" },
    { lng: -122.68573, lat: 45.52054, postalCode: "97205" },
    { lng: -123.08275, lat: 44.06092, postalCode: "97401" },
    { lng: -123.114725, lat: 45.548616, postalCode: "97298" },
    { lng: -124.08307, lat: 44.009099, postalCode: "97439" },
    { lng: -122.6811, lat: 45.40765, postalCode: "97034" },
    { lng: -122.68458, lat: 45.52889, postalCode: "97209" },
    { lng: -123.25977, lat: 45.622824, postalCode: "97117" },
    { lng: -117.82092, lat: 44.796517, postalCode: "97814" },
    { lng: -122.56087, lat: 45.515674, postalCode: "97216" },
    { lng: -123.95827, lat: 45.819042, postalCode: "97102" },
    { lng: -123.27314, lat: 43.397697, postalCode: "97479" },
    { lng: -124.05401, lat: 44.567998, postalCode: "97366" },
    { lng: -123.05562, lat: 44.03858, postalCode: "97403" },
    { lng: -123.89141, lat: 45.257176, postalCode: "97112" },
    { lng: -121.53865, lat: 43.692251, postalCode: "97739" },
    { lng: -122.711117, lat: 45.480324, postalCode: "97207" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97240" },
    { lng: -123.81524, lat: 45.926112, postalCode: "97138" },
    { lng: -122.6367, lat: 44.131706, postalCode: "97489" },
    { lng: -123.36508, lat: 44.333016, postalCode: "97456" },
    { lng: -123.76213, lat: 44.643659, postalCode: "97343" },
    { lng: -122.9485, lat: 44.807739, postalCode: "97392" },
    { lng: -123.10916, lat: 44.46448, postalCode: "97377" },
    { lng: -123.348568, lat: 43.276877, postalCode: "97795" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97251" },
    { lng: -123.27906, lat: 44.565648, postalCode: "97331" },
    { lng: -123.7417, lat: 46.155007, postalCode: "97103" },
    { lng: -123.445033, lat: 44.49893, postalCode: "97339" },
    { lng: -123.38399, lat: 44.023225, postalCode: "97487" },
    { lng: -117.96742, lat: 45.048141, postalCode: "97867" },
    { lng: -117.26823, lat: 45.547098, postalCode: "97828" },
    { lng: -123.91004, lat: 45.561139, postalCode: "97118" },
    { lng: -121.95765, lat: 45.333824, postalCode: "97067" },
    { lng: -121.40926, lat: 45.636848, postalCode: "97040" },
    { lng: -118.95076, lat: 44.335525, postalCode: "97820" },
    { lng: -118.45808, lat: 45.683276, postalCode: "97821" },
    { lng: -122.68781, lat: 45.49894, postalCode: "97201" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97290" },
    { lng: -122.90363, lat: 45.10649, postalCode: "97026" },
    { lng: -122.6755, lat: 45.51854, postalCode: "97204" },
    { lng: -121.81778, lat: 42.129926, postalCode: "97634" },
    { lng: -123.30325, lat: 44.789252, postalCode: "97361" },
    { lng: -122.52859, lat: 45.416785, postalCode: "97015" },
    { lng: -120.42459, lat: 42.199446, postalCode: "97630" },
    { lng: -121.17922, lat: 45.430024, postalCode: "97021" },
    { lng: -121.03751, lat: 44.229401, postalCode: "97753" },
    { lng: -122.44553, lat: 45.542239, postalCode: "97024" },
    { lng: -122.47727, lat: 44.75067, postalCode: "97360" },
    { lng: -121.64724, lat: 44.46723, postalCode: "97730" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97256" },
    { lng: -123.60245, lat: 42.139958, postalCode: "97523" },
    { lng: -122.81992, lat: 43.892577, postalCode: "97431" },
    { lng: -122.79749, lat: 45.234609, postalCode: "97002" },
    { lng: -122.693796, lat: 45.488031, postalCode: "97239" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97259" },
    { lng: -122.625257, lat: 45.484854, postalCode: "97226" },
    { lng: -122.90416, lat: 45.946314, postalCode: "97054" },
    { lng: -123.66311, lat: 45.060436, postalCode: "97347" },
    { lng: -122.81805, lat: 42.270808, postalCode: "97535" },
    { lng: -122.70932, lat: 43.864671, postalCode: "97452" },
    { lng: -123.08033, lat: 44.970181, postalCode: "97304" },
    { lng: -121.30891, lat: 44.020483, postalCode: "97702" },
    { lng: -123.05129, lat: 44.906492, postalCode: "97302" },
    { lng: -119.22894, lat: 45.720228, postalCode: "97826" },
    { lng: -122.7541, lat: 42.713888, postalCode: "97541" },
    { lng: -123.04526, lat: 42.224913, postalCode: "97530" },
    { lng: -122.61833, lat: 45.442634, postalCode: "97222" },
    { lng: -122.60089, lat: 45.514241, postalCode: "97215" },
    { lng: -124.37576, lat: 42.915024, postalCode: "97450" },
    { lng: -117.88306, lat: 45.564451, postalCode: "97827" },
    { lng: -117.17791, lat: 45.336407, postalCode: "97846" },
    { lng: -123.892016, lat: 44.659979, postalCode: "97372" },
    { lng: -123.82026, lat: 45.187837, postalCode: "97122" },
    { lng: -123.49286, lat: 45.140178, postalCode: "97396" },
    { lng: -122.59272, lat: 45.385642, postalCode: "97027" },
    { lng: -123.33749, lat: 42.421567, postalCode: "97257" },
    { lng: -123.18191, lat: 43.745797, postalCode: "97428" },
    { lng: -118.947769, lat: 44.477794, postalCode: "97831" },
    { lng: -122.85376, lat: 45.352086, postalCode: "97140" },
    { lng: -122.68208, lat: 45.247004, postalCode: "97013" },
    { lng: -123.95999, lat: 45.201302, postalCode: "97135" },
    { lng: -123.6864, lat: 43.054446, postalCode: "97416" },
    { lng: -119.86512, lat: 42.543512, postalCode: "97637" },
    { lng: -123.2322, lat: 43.042614, postalCode: "97457" },
    { lng: -123.2978, lat: 42.155392, postalCode: "97544" },
    { lng: -122.64353, lat: 45.54424, postalCode: "97212" },
    { lng: -124.27265, lat: 42.098285, postalCode: "97415" },
    { lng: -123.12811, lat: 45.644591, postalCode: "97106" },
    { lng: -122.72856, lat: 44.391863, postalCode: "97386" },
    { lng: -124.04512, lat: 44.505655, postalCode: "97376" },
    { lng: -120.97637, lat: 42.334534, postalCode: "97622" },
    { lng: -122.850808, lat: 45.354615, postalCode: "97180" },
    { lng: -119.14087, lat: 44.947425, postalCode: "97872" },
    { lng: -118.74565, lat: 45.866696, postalCode: "97835" },
    { lng: -124.47596, lat: 42.746529, postalCode: "97465" },
    { lng: -118.79339, lat: 44.705882, postalCode: "97817" },
    { lng: -122.56027, lat: 45.546205, postalCode: "97220" },
    { lng: -122.59418, lat: 45.596678, postalCode: "97254" },
    { lng: -123.0242, lat: 45.65749, postalCode: "97133" },
    { lng: -123.22834, lat: 45.100504, postalCode: "97101" },
    { lng: -123.38578, lat: 42.771195, postalCode: "97442" },
    { lng: -123.3624, lat: 42.405162, postalCode: "97527" },
    { lng: -122.64635, lat: 45.56544, postalCode: "97211" },
    { lng: -123.114725, lat: 45.548616, postalCode: "97281" },
    { lng: -121.70298, lat: 42.169323, postalCode: "97603" },
    { lng: -122.85826, lat: 45.517695, postalCode: "97006" },
    { lng: -122.95801, lat: 44.995029, postalCode: "97305" },
    { lng: -122.457024, lat: 44.984465, postalCode: "97308" },
    { lng: -123.96437, lat: 46.187903, postalCode: "97121" },
    { lng: -123.55647, lat: 43.098515, postalCode: "97481" },
    { lng: -122.91541, lat: 43.960764, postalCode: "97455" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97286" },
    { lng: -123.87221, lat: 44.018992, postalCode: "97453" },
    { lng: -123.13043, lat: 45.541009, postalCode: "97116" },
    { lng: -120.75257, lat: 44.738812, postalCode: "97711" },
    { lng: -120.206698, lat: 45.438822, postalCode: "97861" },
    { lng: -123.06467, lat: 42.971648, postalCode: "97429" },
    { lng: -124.127089, lat: 43.138755, postalCode: "97468" },
    { lng: -120.94952, lat: 44.001834, postalCode: "97709" },
    { lng: -122.99879, lat: 43.863845, postalCode: "97440" },
    { lng: -119.21005, lat: 43.471016, postalCode: "97738" },
    { lng: -123.9483, lat: 45.438112, postalCode: "97143" },
    { lng: -122.61772, lat: 42.169751, postalCode: "97520" },
    { lng: -123.16804, lat: 44.062231, postalCode: "97402" },
    { lng: -117.48787, lat: 44.578393, postalCode: "97905" },
    { lng: -123.114725, lat: 45.548616, postalCode: "97291" },
    { lng: -123.98589, lat: 44.997061, postalCode: "97364" },
    { lng: -122.77127, lat: 45.373991, postalCode: "97062" },
    { lng: -119.05385, lat: 42.727146, postalCode: "97736" },
    { lng: -118.08197, lat: 45.32627, postalCode: "97850" },
    { lng: -123.03036, lat: 43.911542, postalCode: "97426" },
    { lng: -123.8141, lat: 43.669352, postalCode: "97473" },
    { lng: -122.94605, lat: 45.545841, postalCode: "97124" },
    { lng: -123.028607, lat: 44.943841, postalCode: "97311" },
    { lng: -119.41539, lat: 44.811965, postalCode: "97864" },
    { lng: -118.20623, lat: 44.471344, postalCode: "97884" },
    { lng: -122.60034, lat: 44.407794, postalCode: "97345" },
    { lng: -122.80193, lat: 45.183167, postalCode: "97032" },
    { lng: -122.92065, lat: 45.778892, postalCode: "97056" },
    { lng: -122.74031, lat: 42.484283, postalCode: "97524" },
    { lng: -122.39802, lat: 45.181819, postalCode: "97017" },
    { lng: -122.83547, lat: 42.334496, postalCode: "97504" },
    { lng: -118.06076, lat: 44.499533, postalCode: "97837" },
    { lng: -123.19334, lat: 45.287751, postalCode: "97111" },
    { lng: -123.05089, lat: 45.515181, postalCode: "97113" },
    { lng: -122.4749, lat: 45.255392, postalCode: "97004" },
    { lng: -122.99879, lat: 43.863845, postalCode: "97482" },
    { lng: -123.91023, lat: 44.738154, postalCode: "97380" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97283" },
    { lng: -122.51777, lat: 43.756923, postalCode: "97492" },
    { lng: -119.64344, lat: 45.512792, postalCode: "97839" },
    { lng: -121.23304, lat: 45.262423, postalCode: "97063" },
    { lng: -118.69325, lat: 44.441241, postalCode: "97869" },
    { lng: -121.88447, lat: 42.282428, postalCode: "97601" },
    { lng: -123.06447, lat: 44.122004, postalCode: "97408" },
    { lng: -120.69234, lat: 45.585405, postalCode: "97065" },
    { lng: -121.12506, lat: 44.64079, postalCode: "97741" },
    { lng: -122.01227, lat: 45.371086, postalCode: "97011" },
    { lng: -122.259861, lat: 45.173284, postalCode: "97269" },
    { lng: -122.92154, lat: 42.387399, postalCode: "97502" },
    { lng: -122.59987, lat: 45.56099, postalCode: "97218" },
    { lng: -119.53938, lat: 45.343509, postalCode: "97836" },
    { lng: -124.06328, lat: 44.758819, postalCode: "97369" },
    { lng: -121.20181, lat: 44.384155, postalCode: "97760" },
    { lng: -122.53703, lat: 44.794701, postalCode: "97358" },
    { lng: -118.47434, lat: 43.834833, postalCode: "97904" },
    { lng: -122.82465, lat: 45.651279, postalCode: "97231" },
    { lng: -120.73221, lat: 45.693219, postalCode: "97050" },
    { lng: -123.535027, lat: 42.396421, postalCode: "97528" },
    { lng: -124.00262, lat: 43.68516, postalCode: "97467" },
    { lng: -123.22518, lat: 45.662196, postalCode: "97125" },
    { lng: -122.39156, lat: 45.529551, postalCode: "97060" },
    { lng: -123.01769, lat: 44.061057, postalCode: "97477" },
    { lng: -122.78475, lat: 43.333008, postalCode: "97447" },
    { lng: -117.44227, lat: 45.464512, postalCode: "97857" },
    { lng: -123.994219, lat: 44.885, postalCode: "97388" },
    { lng: -122.101103, lat: 45.582981, postalCode: "97010" },
    { lng: -121.46569, lat: 43.84783, postalCode: "97707" },
    { lng: -121.19173, lat: 44.272796, postalCode: "97756" },
    { lng: -120.51518, lat: 43.785416, postalCode: "97712" },
    { lng: -122.99879, lat: 43.863845, postalCode: "97472" },
    { lng: -122.59658, lat: 45.004218, postalCode: "97375" },
    { lng: -122.564824, lat: 45.532165, postalCode: "97208" },
    { lng: -119.76236, lat: 45.816575, postalCode: "97818" },
    { lng: -123.91753, lat: 46.136322, postalCode: "97146" },
    { lng: -122.972, lat: 45.310304, postalCode: "97132" },
    { lng: -124.04868, lat: 44.424255, postalCode: "97394" },
    { lng: -122.78441, lat: 45.406292, postalCode: "97224" },
    { lng: -121.65255, lat: 43.521016, postalCode: "97737" },
    { lng: -123.8842, lat: 45.688054, postalCode: "97147" },
    { lng: -123.333177, lat: 43.32843, postalCode: "97494" },
    { lng: -122.618719, lat: 44.790267, postalCode: "97384" },
    { lng: -123.26993, lat: 46.078338, postalCode: "97016" },
    { lng: -123.33162, lat: 42.4631, postalCode: "97526" },
    { lng: -118.96382, lat: 43.600795, postalCode: "97720" },
    { lng: -122.81728, lat: 45.540967, postalCode: "97229" },
    { lng: -118.01193, lat: 45.52535, postalCode: "97876" },
    { lng: -122.457024, lat: 44.984465, postalCode: "97373" },
    { lng: -121.41706, lat: 42.026248, postalCode: "97632" },
    { lng: -123.91182, lat: 45.009935, postalCode: "97368" },
    { lng: -124.20994, lat: 43.434529, postalCode: "97459" },
    { lng: -122.3809, lat: 45.428663, postalCode: "97009" },
    { lng: -119.14737, lat: 44.421744, postalCode: "97865" },
    { lng: -122.89532, lat: 42.879291, postalCode: "97484" },
    { lng: -121.088513, lat: 45.263301, postalCode: "97057" },
    { lng: -122.8593, lat: 44.838001, postalCode: "97325" },
    { lng: -123.88468, lat: 45.534203, postalCode: "97107" },
    { lng: -123.54778, lat: 42.259678, postalCode: "97538" },
    { lng: -117.13838, lat: 44.919974, postalCode: "97834" },
    { lng: -124.21086, lat: 43.357357, postalCode: "97420" },
    { lng: -122.14591, lat: 44.178746, postalCode: "97413" },
    { lng: -122.98694, lat: 44.926644, postalCode: "97301" },
    { lng: -123.12026, lat: 44.278808, postalCode: "97446" },
    { lng: -117.430085, lat: 45.487784, postalCode: "97587" },
    { lng: -117.83083, lat: 45.201699, postalCode: "97883" },
    { lng: -121.81297, lat: 42.606472, postalCode: "97624" },
    { lng: -121.29769, lat: 44.091256, postalCode: "97701" },
    { lng: -119.28423, lat: 45.846442, postalCode: "97838" },
    { lng: -122.21021, lat: 45.375143, postalCode: "97055" },
    { lng: -122.986105, lat: 44.927141, postalCode: "97310" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97228" },
    { lng: -123.061329, lat: 44.62205, postalCode: "97322" },
    { lng: -116.79861, lat: 45.479993, postalCode: "97842" },
    { lng: -123.88233, lat: 45.715015, postalCode: "97131" },
    { lng: -119.33008, lat: 45.915993, postalCode: "97882" },
    { lng: -120.94952, lat: 44.001834, postalCode: "97708" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97293" },
    { lng: -122.928208, lat: 45.546914, postalCode: "97104" },
    { lng: -122.35469, lat: 44.400432, postalCode: "97329" },
    { lng: -123.20747, lat: 45.210104, postalCode: "97128" },
    { lng: -122.8169, lat: 44.227201, postalCode: "97454" },
    { lng: -123.58719, lat: 43.647537, postalCode: "97436" },
    { lng: -121.860525, lat: 42.577154, postalCode: "97264" },
    { lng: -123.2752, lat: 44.608694, postalCode: "97330" },
    { lng: -123.46265, lat: 44.099562, postalCode: "97461" },
    { lng: -121.92768, lat: 43.472276, postalCode: "97425" },
    { lng: -118.46578, lat: 45.719133, postalCode: "97810" },
    { lng: -121.73063, lat: 43.437958, postalCode: "97733" },
    { lng: -123.12153, lat: 44.381732, postalCode: "97348" },
    { lng: -122.23283, lat: 45.514515, postalCode: "97019" },
    { lng: -123.93449, lat: 45.620062, postalCode: "97136" },
    { lng: -122.88828, lat: 43.740859, postalCode: "97434" },
    { lng: -123.93471, lat: 45.720836, postalCode: "97130" },
    { lng: -122.83989, lat: 45.224154, postalCode: "97020" },
    { lng: -122.789257, lat: 45.451976, postalCode: "97078" },
    { lng: -123.22502, lat: 45.352399, postalCode: "97148" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97238" },
    { lng: -119.88141, lat: 42.227477, postalCode: "97620" },
    { lng: -123.81722, lat: 44.745464, postalCode: "97357" },
    { lng: -122.50997, lat: 45.474722, postalCode: "97236" },
    { lng: -123.80389, lat: 45.459109, postalCode: "97141" },
    { lng: -118.76751, lat: 45.402303, postalCode: "97868" },
    { lng: -122.60007, lat: 45.482341, postalCode: "97206" },
    { lng: -124.388576, lat: 42.609252, postalCode: "97491" },
    { lng: -123.05368, lat: 44.858446, postalCode: "97306" },
    { lng: -118.5582, lat: 42.130152, postalCode: "97710" },
    { lng: -119.57602, lat: 44.675475, postalCode: "97848" },
    { lng: -123.266648, lat: 44.127631, postalCode: "97409" },
    { lng: -122.78012, lat: 45.003489, postalCode: "97381" },
    { lng: -121.21084, lat: 44.498302, postalCode: "97734" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97272" },
    { lng: -120.65084, lat: 43.256842, postalCode: "97641" },
    { lng: -123.237656, lat: 44.855049, postalCode: "97631" },
    { lng: -117.73478, lat: 44.489628, postalCode: "97819" },
    { lng: -123.83519, lat: 44.127179, postalCode: "97480" },
    { lng: -122.40856, lat: 45.481342, postalCode: "97080" },
    { lng: -122.457024, lat: 44.984465, postalCode: "97307" },
    { lng: -122.8132, lat: 45.891952, postalCode: "97018" },
    { lng: -122.64443, lat: 45.361991, postalCode: "97068" },
    { lng: -122.85512, lat: 44.527304, postalCode: "97355" },
    { lng: -123.26602, lat: 43.593788, postalCode: "97499" },
    { lng: -124.127089, lat: 43.138755, postalCode: "97460" },
    { lng: -122.6458, lat: 45.51379, postalCode: "97214" },
    { lng: -122.259861, lat: 45.173284, postalCode: "97268" },
    { lng: -122.51485, lat: 42.780226, postalCode: "97536" },
    { lng: -121.18944, lat: 45.594257, postalCode: "97058" },
    { lng: -123.2294, lat: 44.204189, postalCode: "97448" },
    { lng: -123.35138, lat: 43.227287, postalCode: "97470" },
    { lng: -124.35756, lat: 42.813437, postalCode: "97476" },
    { lng: -122.6446, lat: 45.52874, postalCode: "97232" },
    { lng: -124.16396, lat: 43.187219, postalCode: "97423" },
    { lng: -123.62496, lat: 44.637701, postalCode: "97326" },
    { lng: -122.70611, lat: 45.45867, postalCode: "97219" },
    { lng: -118.9767, lat: 44.138142, postalCode: "97873" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97299" },
    { lng: -123.24938, lat: 43.80135, postalCode: "97451" },
    { lng: -123.02553, lat: 44.736025, postalCode: "97352" },
    { lng: -122.76988, lat: 45.497856, postalCode: "97225" },
    { lng: -124.05435, lat: 42.625928, postalCode: "97406" },
    { lng: -123.43658, lat: 43.094626, postalCode: "97496" },
    { lng: -121.10814, lat: 45.093643, postalCode: "97037" },
    { lng: -122.78933, lat: 42.227162, postalCode: "97540" },
    { lng: -119.54235, lat: 43.547855, postalCode: "97758" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97296" },
    { lng: -123.53031, lat: 43.38382, postalCode: "97486" },
    { lng: -122.77266, lat: 45.308105, postalCode: "97070" },
    { lng: -123.09787, lat: 44.538801, postalCode: "97389" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97253" },
    { lng: -121.00466, lat: 43.189402, postalCode: "97638" },
    { lng: -123.05667, lat: 43.77182, postalCode: "97424" },
    { lng: -122.7645, lat: 44.850245, postalCode: "97385" },
    { lng: -123.418068, lat: 43.113502, postalCode: "97432" },
    { lng: -120.75682, lat: 44.238306, postalCode: "97754" },
    { lng: -118.68196, lat: 42.965788, postalCode: "97722" },
    { lng: -121.78441, lat: 45.305113, postalCode: "97028" },
    { lng: -124.1716, lat: 42.977978, postalCode: "97414" },
    { lng: -121.55984, lat: 42.330162, postalCode: "97625" },
    { lng: -122.33554, lat: 45.349244, postalCode: "97022" },
    { lng: -123.50865, lat: 42.365473, postalCode: "97543" },
    { lng: -122.7095, lat: 45.534839, postalCode: "97210" },
    { lng: -122.58464, lat: 45.344246, postalCode: "97045" },
    { lng: -117.9624, lat: 45.463939, postalCode: "97841" },
    { lng: -121.536658, lat: 45.643738, postalCode: "97044" },
    { lng: -122.61476, lat: 45.408882, postalCode: "97267" },
    { lng: -123.08114, lat: 45.211339, postalCode: "97114" },
    { lng: -123.65551, lat: 42.208018, postalCode: "97531" },
    { lng: -123.91515, lat: 44.626888, postalCode: "97391" },
    { lng: -123.01631, lat: 45.272559, postalCode: "97115" },
    { lng: -123.327323, lat: 42.308007, postalCode: "97533" },
    { lng: -123.1515, lat: 42.528269, postalCode: "97537" },
    { lng: -122.80359, lat: 45.49106, postalCode: "97005" },
    { lng: -123.038116, lat: 44.936357, postalCode: "97312" },
    { lng: -122.42255, lat: 44.112618, postalCode: "97488" },
    { lng: -119.11886, lat: 44.743532, postalCode: "97856" },
    { lng: -123.95909, lat: 45.868839, postalCode: "97145" },
    { lng: -123.006606, lat: 44.965541, postalCode: "97314" },
    { lng: -123.17165, lat: 45.43957, postalCode: "97119" },
    { lng: -120.17139, lat: 45.232817, postalCode: "97823" },
    { lng: -119.87071, lat: 45.493783, postalCode: "97843" },
    { lng: -121.59252, lat: 45.503224, postalCode: "97041" },
    { lng: -124.00655, lat: 44.950573, postalCode: "97367" },
    { lng: -122.27443, lat: 43.023645, postalCode: "97604" },
    { lng: -121.43464, lat: 42.440695, postalCode: "97639" },
    { lng: -124.05682, lat: 43.075356, postalCode: "97458" },
    { lng: -118.944, lat: 45.085144, postalCode: "97880" },
    { lng: -119.82803, lat: 44.741727, postalCode: "97874" },
    { lng: -124.1557, lat: 43.58119, postalCode: "97449" },
    { lng: -123.12203, lat: 44.004396, postalCode: "97405" },
    { lng: -123.18673, lat: 44.849012, postalCode: "97351" },
    { lng: -123.44807, lat: 44.865209, postalCode: "97344" },
    { lng: -121.88616, lat: 43.189824, postalCode: "97731" },
    { lng: -122.457024, lat: 44.984465, postalCode: "97359" },
    { lng: -122.84521, lat: 45.144415, postalCode: "97071" },
    { lng: -122.56233, lat: 45.478641, postalCode: "97266" },
    { lng: -122.68665, lat: 45.574439, postalCode: "97217" },
    { lng: -123.39902, lat: 42.935498, postalCode: "97469" },
    { lng: -116.8565, lat: 44.973266, postalCode: "97840" },
    { lng: -123.114725, lat: 45.548616, postalCode: "97077" },
    { lng: -122.79827, lat: 43.685176, postalCode: "97427" },
    { lng: -122.49985, lat: 45.513242, postalCode: "97233" },
    { lng: -121.89435, lat: 45.655523, postalCode: "97014" },
    { lng: -123.63206, lat: 44.004968, postalCode: "97490" },
    { lng: -122.85602, lat: 45.469079, postalCode: "97007" },
    { lng: -122.67497, lat: 45.543831, postalCode: "97227" },
    { lng: -122.28464, lat: 45.267354, postalCode: "97023" },
    { lng: -118.07919, lat: 45.986775, postalCode: "99362" },
    { lng: -121.92558, lat: 45.369468, postalCode: "97049" },
    { lng: -124.0557, lat: 44.326381, postalCode: "97498" },
    { lng: -123.53776, lat: 44.196459, postalCode: "97412" },
    { lng: -119.069624, lat: 43.019785, postalCode: "97740" },
    { lng: -121.584833, lat: 42.804981, postalCode: "97602" },
    { lng: -124.12028, lat: 43.760271, postalCode: "97441" },
    { lng: -120.77072, lat: 42.961806, postalCode: "97640" },
    { lng: -123.03325, lat: 43.242312, postalCode: "97443" },
    { lng: -122.457024, lat: 44.984465, postalCode: "97309" },
    { lng: -120.30118, lat: 44.119652, postalCode: "97752" },
    { lng: -117.83718, lat: 45.331655, postalCode: "97824" },
    { lng: -118.4966, lat: 45.81667, postalCode: "97813" },
    { lng: -123.0896, lat: 44.635395, postalCode: "97321" },
    { lng: -122.44717, lat: 43.747181, postalCode: "97463" },
    { lng: -124.39036, lat: 42.451203, postalCode: "97444" },
    { lng: -123.33432, lat: 44.920815, postalCode: "97338" },
    { lng: -122.259861, lat: 45.173284, postalCode: "97036" },
    { lng: -123.36727, lat: 43.443648, postalCode: "97462" },
    { lng: -120.14934, lat: 44.978549, postalCode: "97830" },
    { lng: -120.59745, lat: 45.195235, postalCode: "97033" },
    { lng: -122.87201, lat: 42.471919, postalCode: "97503" },
    { lng: -122.72492, lat: 45.41387, postalCode: "97035" },
    { lng: -117.98289, lat: 44.930122, postalCode: "97833" },
    { lng: -123.20234, lat: 45.729085, postalCode: "97109" },
    { lng: -122.07246, lat: 44.696507, postalCode: "97350" },
    { lng: -123.7031, lat: 42.055397, postalCode: "97534" },
    { lng: -119.79377, lat: 44.113537, postalCode: "97751" },
    { lng: -122.97168, lat: 44.390881, postalCode: "97327" },
    { lng: -117.52645, lat: 45.568843, postalCode: "97885" },
    { lng: -123.114725, lat: 45.548616, postalCode: "97076" },
    { lng: -118.43996, lat: 45.529778, postalCode: "97859" },
    { lng: -122.4323, lat: 45.507752, postalCode: "97030" },
    { lng: -121.58306, lat: 42.025618, postalCode: "97633" },
    { lng: -123.01992, lat: 44.984008, postalCode: "97303" },
    { lng: -122.78224, lat: 45.43884, postalCode: "97223" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97280" },
    { lng: -120.55234, lat: 42.693563, postalCode: "97636" },
    { lng: -122.998756, lat: 44.984941, postalCode: "97313" },
    { lng: -120.7798, lat: 45.335199, postalCode: "97029" },
    { lng: -123.83344, lat: 44.309872, postalCode: "97390" },
    { lng: -122.84824, lat: 45.862489, postalCode: "97051" },
    { lng: -123.35279, lat: 43.703855, postalCode: "97435" },
    { lng: -121.54897, lat: 44.322689, postalCode: "97759" },
    { lng: -118.789, lat: 45.665146, postalCode: "97801" },
    { lng: -122.81342, lat: 42.612422, postalCode: "97539" },
    { lng: -119.51737, lat: 45.886463, postalCode: "97844" },
    { lng: -117.30995, lat: 44.410083, postalCode: "97907" },
    { lng: -123.325617, lat: 43.333141, postalCode: "97495" },
    { lng: -124.07835, lat: 42.873137, postalCode: "97466" },
    { lng: -122.54321, lat: 45.21307, postalCode: "97042" },
    { lng: -122.79115, lat: 45.067719, postalCode: "97362" },
    { lng: -122.81401, lat: 44.702164, postalCode: "97374" },
    { lng: -121.23055, lat: 42.476579, postalCode: "97621" },
    { lng: -123.997304, lat: 44.996054, postalCode: "97567" },
    { lng: -120.17301, lat: 45.57852, postalCode: "97812" },
    { lng: -124.51056, lat: 42.739889, postalCode: "97464" },
    { lng: -122.850752, lat: 44.359301, postalCode: "97336" },
    { lng: -120.11346, lat: 44.611941, postalCode: "97750" },
    { lng: -123.20681, lat: 44.964115, postalCode: "97371" },
    { lng: -122.7601, lat: 44.798379, postalCode: "97383" },
    { lng: -123.40787, lat: 45.089532, postalCode: "97378" },
    { lng: -121.5368, lat: 45.673147, postalCode: "97031" },
    { lng: -122.73875, lat: 45.589689, postalCode: "97203" },
    { lng: -123.6908, lat: 45.284763, postalCode: "97108" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97258" },
    { lng: -123.38787, lat: 44.099732, postalCode: "97437" },
    { lng: -123.2791, lat: 44.52789, postalCode: "97333" },
    { lng: -123.114725, lat: 45.548616, postalCode: "97075" },
    { lng: -122.59979, lat: 45.53704, postalCode: "97213" },
    { lng: -122.374776, lat: 45.580557, postalCode: "97294" },
    { lng: -123.961, lat: 45.894287, postalCode: "97110" },
    { lng: -122.64055, lat: 45.481791, postalCode: "97202" },
    { lng: -121.33346, lat: 42.202475, postalCode: "97623" },
    { lng: -121.98101, lat: 42.086887, postalCode: "97627" },
    { lng: -121.9887, lat: 48.525461, postalCode: "98255" },
    { lng: -122.05288, lat: 48.203856, postalCode: "98223" },
    { lng: -117.97348, lat: 48.733324, postalCode: "99126" },
    { lng: -122.66712, lat: 45.681271, postalCode: "98665" },
    { lng: -117.08628, lat: 47.656171, postalCode: "99019" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98413" },
    { lng: -123.02536, lat: 46.734909, postalCode: "98544" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98111" },
    { lng: -122.52996, lat: 47.203946, postalCode: "98467" },
    { lng: -124.62202, lat: 47.905815, postalCode: "98350" },
    { lng: -122.37096, lat: 47.803668, postalCode: "98020" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98352" },
    { lng: -122.57987, lat: 47.080682, postalCode: "98433" },
    { lng: -117.73437, lat: 48.269784, postalCode: "99109" },
    { lng: -122.09356, lat: 47.137872, postalCode: "98389" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99252" },
    { lng: -122.78382, lat: 47.974586, postalCode: "98325" },
    { lng: -122.40547, lat: 45.60916, postalCode: "98607" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98460" },
    { lng: -122.702393, lat: 46.978118, postalCode: "98507" },
    { lng: -122.23308, lat: 48.461672, postalCode: "98235" },
    { lng: -122.73714, lat: 48.961443, postalCode: "98230" },
    { lng: -122.19644, lat: 47.388461, postalCode: "98031" },
    { lng: -117.18648, lat: 46.732705, postalCode: "99163" },
    { lng: -121.02979, lat: 47.33394, postalCode: "98940" },
    { lng: -119.75474, lat: 48.559477, postalCode: "98819" },
    { lng: -122.48209, lat: 48.185511, postalCode: "98282" },
    { lng: -122.90699, lat: 46.580105, postalCode: "98565" },
    { lng: -122.9827, lat: 46.40521, postalCode: "98593" },
    { lng: -122.92868, lat: 47.705616, postalCode: "98320" },
    { lng: -117.86164, lat: 47.693129, postalCode: "99029" },
    { lng: -122.1526, lat: 47.562975, postalCode: "98006" },
    { lng: -122.51962, lat: 47.807359, postalCode: "98346" },
    { lng: -117.29236, lat: 48.295497, postalCode: "99180" },
    { lng: -122.43971, lat: 47.247671, postalCode: "98402" },
    { lng: -122.7222, lat: 47.378121, postalCode: "98329" },
    { lng: -122.77537, lat: 47.200168, postalCode: "98351" },
    { lng: -123.08647, lat: 46.814438, postalCode: "98579" },
    { lng: -123.76903, lat: 46.514625, postalCode: "98561" },
    { lng: -122.35196, lat: 48.414576, postalCode: "98273" },
    { lng: -121.91915, lat: 47.193729, postalCode: "98022" },
    { lng: -122.99802, lat: 46.969793, postalCode: "98512" },
    { lng: -123.10538, lat: 47.224044, postalCode: "98584" },
    { lng: -122.21614, lat: 48.093129, postalCode: "98271" },
    { lng: -122.74407, lat: 46.532624, postalCode: "98542" },
    { lng: -122.18372, lat: 47.510739, postalCode: "98056" },
    { lng: -119.860117, lat: 47.079243, postalCode: "98824" },
    { lng: -118.70628, lat: 47.756616, postalCode: "99185" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98171" },
    { lng: -122.8987, lat: 46.308371, postalCode: "98611" },
    { lng: -123.90189, lat: 47.355226, postalCode: "98552" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98418" },
    { lng: -122.14104, lat: 47.610935, postalCode: "98007" },
    { lng: -119.28455, lat: 47.133606, postalCode: "98837" },
    { lng: -123.43097, lat: 47.26332, postalCode: "98560" },
    { lng: -121.93734, lat: 47.860245, postalCode: "98272" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98455" },
    { lng: -117.79563, lat: 48.030848, postalCode: "99173" },
    { lng: -122.57947, lat: 45.62104, postalCode: "98664" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98140" },
    { lng: -123.76903, lat: 46.514625, postalCode: "98637" },
    { lng: -120.694658, lat: 46.56451, postalCode: "98920" },
    { lng: -123.81031, lat: 47.489864, postalCode: "98575" },
    { lng: -123.890317, lat: 47.387674, postalCode: "98566" },
    { lng: -120.35944, lat: 47.782158, postalCode: "98822" },
    { lng: -118.2979, lat: 48.207661, postalCode: "99138" },
    { lng: -117.15116, lat: 47.648693, postalCode: "99016" },
    { lng: -121.47523, lat: 45.786727, postalCode: "98672" },
    { lng: -122.29237, lat: 46.884733, postalCode: "98328" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98145" },
    { lng: -122.1887, lat: 47.196364, postalCode: "98390" },
    { lng: -122.48053, lat: 47.208987, postalCode: "98409" },
    { lng: -123.2798, lat: 46.637787, postalCode: "98539" },
    { lng: -122.100892, lat: 47.140655, postalCode: "98485" },
    { lng: -122.33438, lat: 47.61067, postalCode: "98101" },
    { lng: -119.99, lat: 46.342102, postalCode: "98944" },
    { lng: -117.37274, lat: 47.844289, postalCode: "99005" },
    { lng: -118.182764, lat: 48.84269, postalCode: "99107" },
    { lng: -122.033685, lat: 47.584879, postalCode: "98075" },
    { lng: -122.09819, lat: 47.138345, postalCode: "98385" },
    { lng: -118.399551, lat: 47.60894, postalCode: "99144" },
    { lng: -122.832799, lat: 47.266017, postalCode: "98395" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98471" },
    { lng: -117.29833, lat: 48.858518, postalCode: "99153" },
    { lng: -121.39603, lat: 47.397811, postalCode: "98068" },
    { lng: -119.901931, lat: 46.731046, postalCode: "99309" },
    { lng: -120.26699, lat: 46.418933, postalCode: "98953" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98185" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98083" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98492" },
    { lng: -118.69498, lat: 48.080515, postalCode: "99140" },
    { lng: -119.09462, lat: 46.270249, postalCode: "99301" },
    { lng: -117.11319, lat: 47.463725, postalCode: "99030" },
    { lng: -122.53345, lat: 46.588026, postalCode: "98533" },
    { lng: -122.61807, lat: 48.852655, postalCode: "98248" },
    { lng: -123.02078, lat: 47.433993, postalCode: "98588" },
    { lng: -120.1843, lat: 46.345166, postalCode: "98932" },
    { lng: -122.28348, lat: 47.848237, postalCode: "98037" },
    { lng: -118.87987, lat: 46.191158, postalCode: "99323" },
    { lng: -119.0672, lat: 46.970666, postalCode: "98857" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98161" },
    { lng: -117.43185, lat: 47.663945, postalCode: "99201" },
    { lng: -122.649953, lat: 47.626717, postalCode: "98393" },
    { lng: -120.33462, lat: 46.35653, postalCode: "98948" },
    { lng: -122.85123, lat: 46.797382, postalCode: "98530" },
    { lng: -122.41196, lat: 47.148269, postalCode: "98445" },
    { lng: -117.25912, lat: 47.646994, postalCode: "99206" },
    { lng: -123.298384, lat: 46.571323, postalCode: "98522" },
    { lng: -122.69055, lat: 47.667257, postalCode: "98383" },
    { lng: -122.702393, lat: 46.978118, postalCode: "98540" },
    { lng: -122.45879, lat: 47.263971, postalCode: "98403" },
    { lng: -120.62421, lat: 46.406875, postalCode: "98933" },
    { lng: -122.80083, lat: 46.433226, postalCode: "98591" },
    { lng: -122.418495, lat: 47.308061, postalCode: "98442" },
    { lng: -123.40628, lat: 48.097144, postalCode: "98362" },
    { lng: -122.33068, lat: 47.443756, postalCode: "98148" },
    { lng: -122.32095, lat: 48.900256, postalCode: "98247" },
    { lng: -122.65346, lat: 46.593526, postalCode: "98570" },
    { lng: -122.3027, lat: 47.491327, postalCode: "98168" },
    { lng: -123.06888, lat: 47.521614, postalCode: "98555" },
    { lng: -120.694658, lat: 46.56451, postalCode: "98939" },
    { lng: -122.66335, lat: 47.470971, postalCode: "98367" },
    { lng: -118.63676, lat: 46.912194, postalCode: "99341" },
    { lng: -122.499139, lat: 47.51037, postalCode: "98386" },
    { lng: -120.97097, lat: 46.71207, postalCode: "98937" },
    { lng: -122.7757, lat: 46.318482, postalCode: "98645" },
    { lng: -119.17443, lat: 46.843397, postalCode: "99344" },
    { lng: -118.95248, lat: 47.998468, postalCode: "99124" },
    { lng: -117.68812, lat: 47.583754, postalCode: "99022" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98464" },
    { lng: -119.160173, lat: 46.216706, postalCode: "99536" },
    { lng: -122.38803, lat: 47.538887, postalCode: "98136" },
    { lng: -117.644337, lat: 46.838747, postalCode: "99333" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98477" },
    { lng: -120.47681, lat: 48.634796, postalCode: "98833" },
    { lng: -122.36978, lat: 47.740886, postalCode: "98177" },
    { lng: -122.65093, lat: 48.303774, postalCode: "98277" },
    { lng: -123.872972, lat: 48.18315, postalCode: "98324" },
    { lng: -119.12107, lat: 46.581454, postalCode: "99343" },
    { lng: -123.02057, lat: 48.612763, postalCode: "98243" },
    { lng: -122.37678, lat: 47.202874, postalCode: "98443" },
    { lng: -123.21175, lat: 47.446725, postalCode: "98548" },
    { lng: -123.2739, lat: 47.054419, postalCode: "98557" },
    { lng: -122.38961, lat: 48.222046, postalCode: "98292" },
    { lng: -118.829153, lat: 46.828931, postalCode: "99327" },
    { lng: -122.25064, lat: 47.90296, postalCode: "98204" },
    { lng: -122.88566, lat: 46.150433, postalCode: "98626" },
    { lng: -122.52294, lat: 47.12562, postalCode: "98439" },
    { lng: -122.11451, lat: 47.363382, postalCode: "98042" },
    { lng: -117.96015, lat: 46.669207, postalCode: "99136" },
    { lng: -122.21602, lat: 47.304221, postalCode: "98002" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98035" },
    { lng: -121.83053, lat: 47.530639, postalCode: "98065" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99228" },
    { lng: -118.70235, lat: 47.338736, postalCode: "99159" },
    { lng: -122.34675, lat: 47.630648, postalCode: "98109" },
    { lng: -122.35688, lat: 47.501753, postalCode: "98146" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98114" },
    { lng: -117.4124, lat: 47.751674, postalCode: "99218" },
    { lng: -117.644337, lat: 46.838747, postalCode: "99127" },
    { lng: -118.18938, lat: 47.671143, postalCode: "99122" },
    { lng: -118.13247, lat: 46.866805, postalCode: "99105" },
    { lng: -119.3827, lat: 47.646132, postalCode: "99115" },
    { lng: -119.67175, lat: 46.922079, postalCode: "99357" },
    { lng: -124.30909, lat: 48.066605, postalCode: "98305" },
    { lng: -117.49326, lat: 47.632573, postalCode: "99224" },
    { lng: -119.16369, lat: 46.210957, postalCode: "99336" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98493" },
    { lng: -122.98043, lat: 46.156082, postalCode: "98632" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98129" },
    { lng: -122.53051, lat: 47.74878, postalCode: "98342" },
    { lng: -119.28312, lat: 47.417461, postalCode: "98853" },
    { lng: -118.95554, lat: 47.721863, postalCode: "99103" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99220" },
    { lng: -121.28904, lat: 46.018228, postalCode: "98619" },
    { lng: -122.63493, lat: 47.629717, postalCode: "98311" },
    { lng: -122.21792, lat: 47.943468, postalCode: "98203" },
    { lng: -118.2897, lat: 48.92816, postalCode: "99146" },
    { lng: -120.53102, lat: 46.595248, postalCode: "98902" },
    { lng: -119.45276, lat: 48.528793, postalCode: "98849" },
    { lng: -120.24261, lat: 48.488543, postalCode: "98862" },
    { lng: -122.43597, lat: 48.759079, postalCode: "98226" },
    { lng: -120.694658, lat: 46.56451, postalCode: "98904" },
    { lng: -118.72647, lat: 48.657684, postalCode: "99166" },
    { lng: -122.57894, lat: 45.671806, postalCode: "98662" },
    { lng: -122.702393, lat: 46.978118, postalCode: "98508" },
    { lng: -120.60457, lat: 47.552462, postalCode: "98847" },
    { lng: -122.78376, lat: 47.085046, postalCode: "98516" },
    { lng: -122.8326, lat: 46.857968, postalCode: "98589" },
    { lng: -118.11361, lat: 48.174649, postalCode: "99137" },
    { lng: -117.7293, lat: 46.941628, postalCode: "99125" },
    { lng: -122.520347, lat: 45.801586, postalCode: "98667" },
    { lng: -119.90423, lat: 47.63205, postalCode: "98858" },
    { lng: -122.44856, lat: 48.030555, postalCode: "98260" },
    { lng: -121.24849, lat: 47.3042, postalCode: "98925" },
    { lng: -123.85214, lat: 46.560786, postalCode: "98586" },
    { lng: -122.53272, lat: 47.230402, postalCode: "98466" },
    { lng: -122.33366, lat: 48.486892, postalCode: "98233" },
    { lng: -118.8823, lat: 46.675704, postalCode: "99326" },
    { lng: -122.71293, lat: 47.564437, postalCode: "98312" },
    { lng: -119.094271, lat: 46.234957, postalCode: "99302" },
    { lng: -122.058644, lat: 48.534462, postalCode: "98263" },
    { lng: -121.988548, lat: 48.814753, postalCode: "98231" },
    { lng: -122.28394, lat: 47.808816, postalCode: "98036" },
    { lng: -122.49518, lat: 47.26302, postalCode: "98406" },
    { lng: -122.192276, lat: 47.369098, postalCode: "98030" },
    { lng: -117.59149, lat: 47.642922, postalCode: "99001" },
    { lng: -122.39392, lat: 47.57487, postalCode: "98116" },
    { lng: -120.19929, lat: 47.333821, postalCode: "98828" },
    { lng: -122.30539, lat: 47.790969, postalCode: "98043" },
    { lng: -122.7599, lat: 48.036882, postalCode: "98339" },
    { lng: -122.57159, lat: 47.43025, postalCode: "98359" },
    { lng: -117.37899, lat: 47.656692, postalCode: "99202" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98124" },
    { lng: -122.335734, lat: 48.834208, postalCode: "98276" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99256" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98138" },
    { lng: -117.7671, lat: 48.658512, postalCode: "99114" },
    { lng: -122.96643, lat: 48.575362, postalCode: "98286" },
    { lng: -121.417034, lat: 48.558127, postalCode: "98246" },
    { lng: -119.06531, lat: 47.907923, postalCode: "99123" },
    { lng: -120.993126, lat: 47.221837, postalCode: "98991" },
    { lng: -121.48418, lat: 48.509814, postalCode: "98283" },
    { lng: -122.92935, lat: 47.321442, postalCode: "98546" },
    { lng: -122.520347, lat: 45.801586, postalCode: "98622" },
    { lng: -122.66054, lat: 46.352771, postalCode: "98649" },
    { lng: -122.71309, lat: 47.720777, postalCode: "98315" },
    { lng: -122.482076, lat: 47.264577, postalCode: "98416" },
    { lng: -117.4111, lat: 48.736168, postalCode: "99139" },
    { lng: -120.5614, lat: 47.541723, postalCode: "98821" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98054" },
    { lng: -122.80359, lat: 47.027082, postalCode: "98503" },
    { lng: -122.35384, lat: 47.763669, postalCode: "98113" },
    { lng: -117.43979, lat: 47.69399, postalCode: "99205" },
    { lng: -122.10205, lat: 47.758786, postalCode: "98072" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98057" },
    { lng: -118.93303, lat: 48.810894, postalCode: "98859" },
    { lng: -117.39106, lat: 48.858688, postalCode: "99152" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98181" },
    { lng: -120.12934, lat: 47.915227, postalCode: "98831" },
    { lng: -118.03661, lat: 47.863857, postalCode: "99040" },
    { lng: -122.50433, lat: 47.28392, postalCode: "98407" },
    { lng: -122.56719, lat: 46.893352, postalCode: "98597" },
    { lng: -121.19784, lat: 45.726451, postalCode: "98635" },
    { lng: -122.45723, lat: 48.943476, postalCode: "98264" },
    { lng: -117.31689, lat: 48.504786, postalCode: "99119" },
    { lng: -120.573967, lat: 46.628757, postalCode: "98907" },
    { lng: -124.25814, lat: 47.32251, postalCode: "98587" },
    { lng: -122.70284, lat: 45.928662, postalCode: "98674" },
    { lng: -122.46599, lat: 47.248953, postalCode: "98405" },
    { lng: -119.85868, lat: 47.18227, postalCode: "98848" },
    { lng: -124.05022, lat: 46.365773, postalCode: "98631" },
    { lng: -122.92262, lat: 46.493247, postalCode: "98596" },
    { lng: -117.24891, lat: 48.041663, postalCode: "99009" },
    { lng: -122.55585, lat: 46.955863, postalCode: "98558" },
    { lng: -122.23233, lat: 47.569271, postalCode: "98040" },
    { lng: -121.91265, lat: 47.648232, postalCode: "98014" },
    { lng: -122.32253, lat: 47.63287, postalCode: "98102" },
    { lng: -122.97702, lat: 47.071484, postalCode: "98502" },
    { lng: -122.353089, lat: 47.185462, postalCode: "98731" },
    { lng: -122.66658, lat: 48.699642, postalCode: "98262" },
    { lng: -122.33357, lat: 47.60632, postalCode: "98154" },
    { lng: -117.43916, lat: 47.740491, postalCode: "99208" },
    { lng: -122.567631, lat: 47.151297, postalCode: "98438" },
    { lng: -122.62554, lat: 47.702342, postalCode: "98345" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99219" },
    { lng: -117.30668, lat: 47.668926, postalCode: "99212" },
    { lng: -122.41213, lat: 47.250681, postalCode: "98421" },
    { lng: -124.16027, lat: 47.118566, postalCode: "98535" },
    { lng: -121.66644, lat: 47.851713, postalCode: "98251" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98415" },
    { lng: -122.702393, lat: 46.978118, postalCode: "98599" },
    { lng: -123.63765, lat: 48.102836, postalCode: "98363" },
    { lng: -122.30081, lat: 47.58577, postalCode: "98144" },
    { lng: -121.97167, lat: 45.650041, postalCode: "98639" },
    { lng: -122.62371, lat: 47.58675, postalCode: "98310" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98398" },
    { lng: -119.47909, lat: 46.263399, postalCode: "99320" },
    { lng: -117.19597, lat: 46.983768, postalCode: "99130" },
    { lng: -117.57306, lat: 48.012084, postalCode: "99110" },
    { lng: -122.34166, lat: 47.671346, postalCode: "98103" },
    { lng: -120.794978, lat: 46.637538, postalCode: "98909" },
    { lng: -122.16328, lat: 47.297285, postalCode: "98092" },
    { lng: -118.10045, lat: 48.690209, postalCode: "99141" },
    { lng: -121.682271, lat: 48.037258, postalCode: "98259" },
    { lng: -122.19797, lat: 47.893451, postalCode: "98208" },
    { lng: -124.08111, lat: 46.781978, postalCode: "98547" },
    { lng: -124.64076, lat: 48.35306, postalCode: "98357" },
    { lng: -122.87249, lat: 47.069339, postalCode: "98506" },
    { lng: -122.0348, lat: 46.711036, postalCode: "98355" },
    { lng: -122.17858, lat: 46.761546, postalCode: "98330" },
    { lng: -122.01479, lat: 47.317802, postalCode: "98010" },
    { lng: -117.16984, lat: 48.159752, postalCode: "99156" },
    { lng: -122.12547, lat: 48.841442, postalCode: "98244" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98160" },
    { lng: -122.12138, lat: 47.677471, postalCode: "98052" },
    { lng: -120.46721, lat: 46.457731, postalCode: "98951" },
    { lng: -118.11029, lat: 48.406582, postalCode: "99167" },
    { lng: -122.79736, lat: 48.100255, postalCode: "98368" },
    { lng: -117.93432, lat: 47.50505, postalCode: "99008" },
    { lng: -122.284785, lat: 47.859918, postalCode: "98206" },
    { lng: -122.30308, lat: 47.716648, postalCode: "98125" },
    { lng: -122.24432, lat: 48.371026, postalCode: "98274" },
    { lng: -117.41047, lat: 47.117965, postalCode: "99176" },
    { lng: -122.729304, lat: 47.687971, postalCode: "98378" },
    { lng: -122.045347, lat: 47.625364, postalCode: "98074" },
    { lng: -121.14615, lat: 45.859065, postalCode: "98670" },
    { lng: -119.74507, lat: 46.208571, postalCode: "99350" },
    { lng: -120.73348, lat: 48.494944, postalCode: "98852" },
    { lng: -121.41445, lat: 47.658047, postalCode: "98288" },
    { lng: -122.41375, lat: 48.559548, postalCode: "98232" },
    { lng: -123.6907, lat: 46.899189, postalCode: "98537" },
    { lng: -122.20222, lat: 47.986274, postalCode: "98201" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98064" },
    { lng: -122.20308, lat: 47.618371, postalCode: "98004" },
    { lng: -118.95862, lat: 47.995847, postalCode: "99116" },
    { lng: -122.34578, lat: 47.61487, postalCode: "98121" },
    { lng: -122.03553, lat: 47.381989, postalCode: "98038" },
    { lng: -117.35892, lat: 47.614744, postalCode: "99223" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99299" },
    { lng: -122.520347, lat: 45.801586, postalCode: "98687" },
    { lng: -119.00126, lat: 48.17266, postalCode: "99155" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98151" },
    { lng: -120.13051, lat: 48.359904, postalCode: "98856" },
    { lng: -122.422431, lat: 47.136544, postalCode: "98448" },
    { lng: -119.93088, lat: 46.834349, postalCode: "99321" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98062" },
    { lng: -122.27496, lat: 47.543348, postalCode: "98118" },
    { lng: -119.98939, lat: 46.880413, postalCode: "98950" },
    { lng: -122.1934, lat: 47.67903, postalCode: "98033" },
    { lng: -124.20789, lat: 47.254009, postalCode: "98562" },
    { lng: -117.57295, lat: 46.44754, postalCode: "99347" },
    { lng: -122.539637, lat: 47.521803, postalCode: "98384" },
    { lng: -118.06417, lat: 48.664206, postalCode: "99151" },
    { lng: -121.88657, lat: 47.359414, postalCode: "98051" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98195" },
    { lng: -121.16835, lat: 45.632051, postalCode: "98617" },
    { lng: -117.08133, lat: 47.222328, postalCode: "99033" },
    { lng: -122.37589, lat: 47.1309, postalCode: "98446" },
    { lng: -121.47607, lat: 47.763755, postalCode: "98224" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98150" },
    { lng: -120.73874, lat: 47.728186, postalCode: "98826" },
    { lng: -124.41188, lat: 48.181608, postalCode: "98326" },
    { lng: -117.63938, lat: 47.11653, postalCode: "99171" },
    { lng: -122.88417, lat: 47.445292, postalCode: "98528" },
    { lng: -123.60332, lat: 47.034615, postalCode: "98563" },
    { lng: -117.47364, lat: 47.227436, postalCode: "99149" },
    { lng: -120.71232, lat: 47.087352, postalCode: "98946" },
    { lng: -122.6199, lat: 47.244053, postalCode: "98333" },
    { lng: -123.02636, lat: 48.701835, postalCode: "98297" },
    { lng: -118.82892, lat: 46.776386, postalCode: "99332" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98348" },
    { lng: -120.09326, lat: 47.745842, postalCode: "98843" },
    { lng: -121.988548, lat: 48.814753, postalCode: "98227" },
    { lng: -122.31577, lat: 47.196272, postalCode: "98371" },
    { lng: -122.4485, lat: 47.153571, postalCode: "98444" },
    { lng: -122.54535, lat: 47.553289, postalCode: "98353" },
    { lng: -122.62193, lat: 45.8768, postalCode: "98629" },
    { lng: -117.94318, lat: 48.305861, postalCode: "99101" },
    { lng: -117.4959, lat: 47.563191, postalCode: "99020" },
    { lng: -122.8388, lat: 47.374343, postalCode: "98524" },
    { lng: -119.03268, lat: 47.290624, postalCode: "98832" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99260" },
    { lng: -117.06934, lat: 47.751434, postalCode: "99025" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98191" },
    { lng: -123.91732, lat: 47.026913, postalCode: "98550" },
    { lng: -122.29411, lat: 46.06517, postalCode: "98616" },
    { lng: -119.73538, lat: 48.267188, postalCode: "98829" },
    { lng: -124.14241, lat: 47.179475, postalCode: "98536" },
    { lng: -122.63256, lat: 48.931679, postalCode: "98240" },
    { lng: -122.45624, lat: 46.001752, postalCode: "98603" },
    { lng: -122.05255, lat: 47.155649, postalCode: "98321" },
    { lng: -119.00713, lat: 47.947097, postalCode: "99133" },
    { lng: -120.25742, lat: 47.436898, postalCode: "98802" },
    { lng: -122.315, lat: 47.397521, postalCode: "98198" },
    { lng: -117.22306, lat: 47.81108, postalCode: "99021" },
    { lng: -122.702393, lat: 46.978118, postalCode: "98504" },
    { lng: -118.38258, lat: 47.460312, postalCode: "99134" },
    { lng: -118.49343, lat: 47.757764, postalCode: "99117" },
    { lng: -120.09871, lat: 46.364164, postalCode: "98938" },
    { lng: -121.85094, lat: 46.525156, postalCode: "98377" },
    { lng: -119.57129, lat: 47.297368, postalCode: "98823" },
    { lng: -122.69584, lat: 47.16438, postalCode: "98303" },
    { lng: -119.911288, lat: 46.68039, postalCode: "99319" },
    { lng: -122.68473, lat: 45.709555, postalCode: "98685" },
    { lng: -122.444335, lat: 47.253671, postalCode: "98401" },
    { lng: -122.40089, lat: 47.962134, postalCode: "98236" },
    { lng: -122.68794, lat: 48.058323, postalCode: "98358" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98009" },
    { lng: -118.67264, lat: 46.059286, postalCode: "99360" },
    { lng: -123.30578, lat: 46.569107, postalCode: "98572" },
    { lng: -117.37449, lat: 47.432691, postalCode: "99031" },
    { lng: -121.76849, lat: 47.870275, postalCode: "98293" },
    { lng: -122.79718, lat: 46.015873, postalCode: "98625" },
    { lng: -119.4624, lat: 47.400024, postalCode: "98851" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98397" },
    { lng: -122.24664, lat: 47.497971, postalCode: "98178" },
    { lng: -122.91972, lat: 48.672312, postalCode: "98245" },
    { lng: -123.07403, lat: 47.33179, postalCode: "98592" },
    { lng: -117.85558, lat: 47.197445, postalCode: "99017" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99215" },
    { lng: -122.78309, lat: 47.336226, postalCode: "98394" },
    { lng: -119.98921, lat: 47.79955, postalCode: "98817" },
    { lng: -123.228797, lat: 46.843337, postalCode: "98468" },
    { lng: -122.46821, lat: 47.416198, postalCode: "98070" },
    { lng: -118.51457, lat: 48.977629, postalCode: "99121" },
    { lng: -118.399551, lat: 47.60894, postalCode: "99147" },
    { lng: -122.28112, lat: 45.594465, postalCode: "98671" },
    { lng: -122.20146, lat: 48.971298, postalCode: "98295" },
    { lng: -122.435478, lat: 48.725417, postalCode: "98229" },
    { lng: -121.61674, lat: 46.580048, postalCode: "98361" },
    { lng: -117.644337, lat: 46.838747, postalCode: "99165" },
    { lng: -117.91313, lat: 46.792982, postalCode: "99143" },
    { lng: -122.32855, lat: 47.60252, postalCode: "98104" },
    { lng: -119.35457, lat: 46.297139, postalCode: "99353" },
    { lng: -122.61976, lat: 48.495234, postalCode: "98221" },
    { lng: -123.05308, lat: 46.373347, postalCode: "98581" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99214" },
    { lng: -120.02966, lat: 46.177732, postalCode: "98935" },
    { lng: -120.95503, lat: 47.186592, postalCode: "98943" },
    { lng: -117.19993, lat: 47.643346, postalCode: "99037" },
    { lng: -123.09916, lat: 48.079979, postalCode: "98382" },
    { lng: -122.1155, lat: 47.609222, postalCode: "98008" },
    { lng: -120.7144, lat: 46.377579, postalCode: "98952" },
    { lng: -117.472694, lat: 47.951939, postalCode: "99066" },
    { lng: -122.02921, lat: 47.522828, postalCode: "98027" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98082" },
    { lng: -123.81923, lat: 46.972965, postalCode: "98520" },
    { lng: -120.71558, lat: 46.662006, postalCode: "98923" },
    { lng: -119.39066, lat: 48.403174, postalCode: "98841" },
    { lng: -121.50689, lat: 47.84138, postalCode: "98256" },
    { lng: -122.81372, lat: 48.63205, postalCode: "98279" },
    { lng: -122.34943, lat: 47.458821, postalCode: "98166" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98041" },
    { lng: -122.48754, lat: 46.954282, postalCode: "98580" },
    { lng: -122.702393, lat: 46.978118, postalCode: "98509" },
    { lng: -118.59088, lat: 48.810879, postalCode: "99150" },
    { lng: -122.57489, lat: 47.910628, postalCode: "98340" },
    { lng: -122.37468, lat: 47.66747, postalCode: "98107" },
    { lng: -122.66723, lat: 48.204162, postalCode: "98239" },
    { lng: -122.59122, lat: 47.854503, postalCode: "98364" },
    { lng: -122.33651, lat: 47.821568, postalCode: "98026" },
    { lng: -122.31864, lat: 47.147862, postalCode: "98373" },
    { lng: -123.05047, lat: 48.99201, postalCode: "98281" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98130" },
    { lng: -118.15325, lat: 46.138753, postalCode: "99329" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98494" },
    { lng: -122.1588, lat: 48.061269, postalCode: "98270" },
    { lng: -122.52818, lat: 47.247735, postalCode: "98465" },
    { lng: -122.33441, lat: 47.57867, postalCode: "98134" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98060" },
    { lng: -122.88271, lat: 48.481655, postalCode: "98261" },
    { lng: -117.10575, lat: 46.905132, postalCode: "99161" },
    { lng: -122.31737, lat: 47.248208, postalCode: "98354" },
    { lng: -117.282483, lat: 47.656726, postalCode: "99213" },
    { lng: -122.64326, lat: 47.088719, postalCode: "98327" },
    { lng: -122.222642, lat: 46.989916, postalCode: "98344" },
    { lng: -121.487682, lat: 45.807631, postalCode: "98623" },
    { lng: -121.82242, lat: 48.070852, postalCode: "98252" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99211" },
    { lng: -117.12007, lat: 47.703698, postalCode: "99027" },
    { lng: -122.729304, lat: 47.687971, postalCode: "98322" },
    { lng: -122.85233, lat: 47.826111, postalCode: "98376" },
    { lng: -122.60011, lat: 47.364321, postalCode: "98332" },
    { lng: -120.52364, lat: 47.009726, postalCode: "98926" },
    { lng: -122.26608, lat: 47.303722, postalCode: "98001" },
    { lng: -122.43726, lat: 45.935656, postalCode: "98601" },
    { lng: -122.655862, lat: 48.310127, postalCode: "98278" },
    { lng: -122.39221, lat: 45.843674, postalCode: "98675" },
    { lng: -117.99082, lat: 47.273264, postalCode: "99032" },
    { lng: -118.15884, lat: 46.209202, postalCode: "99361" },
    { lng: -117.18352, lat: 47.554592, postalCode: "99023" },
    { lng: -120.25547, lat: 45.963054, postalCode: "99322" },
    { lng: -122.51035, lat: 45.604075, postalCode: "98683" },
    { lng: -124.05517, lat: 46.332077, postalCode: "98644" },
    { lng: -122.864838, lat: 46.071482, postalCode: "98609" },
    { lng: -123.46828, lat: 46.296658, postalCode: "98647" },
    { lng: -122.663954, lat: 45.678557, postalCode: "98655" },
    { lng: -123.34624, lat: 46.208318, postalCode: "98612" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98481" },
    { lng: -118.09326, lat: 48.290089, postalCode: "99131" },
    { lng: -124.37144, lat: 48.283826, postalCode: "98381" },
    { lng: -122.02065, lat: 47.572401, postalCode: "98029" },
    { lng: -122.35443, lat: 47.53282, postalCode: "98106" },
    { lng: -121.682271, lat: 48.037258, postalCode: "98207" },
    { lng: -117.08202, lat: 46.516794, postalCode: "99179" },
    { lng: -122.01544, lat: 46.75523, postalCode: "98304" },
    { lng: -121.286091, lat: 45.694098, postalCode: "98653" },
    { lng: -121.47838, lat: 45.719294, postalCode: "98605" },
    { lng: -120.61762, lat: 46.605303, postalCode: "98908" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99251" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98071" },
    { lng: -117.244981, lat: 47.329704, postalCode: "99039" },
    { lng: -120.48966, lat: 47.522546, postalCode: "98815" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99015" },
    { lng: -117.38829, lat: 47.693741, postalCode: "99207" },
    { lng: -123.25649, lat: 46.84066, postalCode: "98568" },
    { lng: -119.28137, lat: 46.279657, postalCode: "99352" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98447" },
    { lng: -121.91147, lat: 45.801513, postalCode: "98610" },
    { lng: -123.67708, lat: 46.668744, postalCode: "98577" },
    { lng: -119.10456, lat: 46.169982, postalCode: "99337" },
    { lng: -117.82325, lat: 47.889348, postalCode: "99013" },
    { lng: -120.56997, lat: 46.726395, postalCode: "98942" },
    { lng: -120.315141, lat: 46.421218, postalCode: "98921" },
    { lng: -122.50476, lat: 48.393063, postalCode: "98257" },
    { lng: -122.26012, lat: 47.140045, postalCode: "98374" },
    { lng: -124.15607, lat: 46.983877, postalCode: "98569" },
    { lng: -117.4092, lat: 46.875136, postalCode: "99111" },
    { lng: -122.50844, lat: 47.16632, postalCode: "98499" },
    { lng: -122.33523, lat: 47.604718, postalCode: "98174" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98013" },
    { lng: -119.23982, lat: 46.192356, postalCode: "99338" },
    { lng: -121.978279, lat: 47.741268, postalCode: "98319" },
    { lng: -119.9395, lat: 48.03925, postalCode: "98846" },
    { lng: -117.595751, lat: 47.55988, postalCode: "99014" },
    { lng: -121.682271, lat: 48.037258, postalCode: "98287" },
    { lng: -120.11338, lat: 47.360902, postalCode: "98850" },
    { lng: -122.55751, lat: 47.735065, postalCode: "98392" },
    { lng: -118.43136, lat: 47.107228, postalCode: "99169" },
    { lng: -117.37365, lat: 47.240334, postalCode: "99170" },
    { lng: -120.37126, lat: 47.737028, postalCode: "98811" },
    { lng: -120.99286, lat: 47.224876, postalCode: "98941" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98158" },
    { lng: -122.34461, lat: 47.733852, postalCode: "98133" },
    { lng: -122.113791, lat: 47.311041, postalCode: "98093" },
    { lng: -122.76897, lat: 47.268551, postalCode: "98349" },
    { lng: -122.91828, lat: 48.594255, postalCode: "98280" },
    { lng: -122.140193, lat: 47.617634, postalCode: "98067" },
    { lng: -117.2491, lat: 46.086657, postalCode: "99401" },
    { lng: -117.67344, lat: 47.595572, postalCode: "99011" },
    { lng: -122.19702, lat: 47.71828, postalCode: "98034" },
    { lng: -122.291, lat: 47.028423, postalCode: "98338" },
    { lng: -122.11068, lat: 47.846083, postalCode: "98296" },
    { lng: -122.576199, lat: 47.680763, postalCode: "98061" },
    { lng: -122.62846, lat: 45.63873, postalCode: "98661" },
    { lng: -118.55652, lat: 46.648121, postalCode: "99335" },
    { lng: -122.95142, lat: 46.728383, postalCode: "98531" },
    { lng: -122.39758, lat: 47.64767, postalCode: "98199" },
    { lng: -121.88638, lat: 47.565642, postalCode: "98024" },
    { lng: -120.33322, lat: 46.540842, postalCode: "98936" },
    { lng: -122.22021, lat: 47.790653, postalCode: "98021" },
    { lng: -122.37838, lat: 47.685919, postalCode: "98117" },
    { lng: -122.63419, lat: 45.712623, postalCode: "98686" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98497" },
    { lng: -119.06888, lat: 46.467186, postalCode: "99330" },
    { lng: -117.18568, lat: 47.383651, postalCode: "99012" },
    { lng: -117.81647, lat: 48.897876, postalCode: "99157" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98430" },
    { lng: -123.32595, lat: 46.959091, postalCode: "98559" },
    { lng: -123.1016, lat: 46.483547, postalCode: "98538" },
    { lng: -121.5395, lat: 45.981582, postalCode: "98650" },
    { lng: -122.30122, lat: 47.68382, postalCode: "98115" },
    { lng: -122.57616, lat: 46.528282, postalCode: "98585" },
    { lng: -119.54481, lat: 47.880616, postalCode: "98830" },
    { lng: -123.07563, lat: 48.55113, postalCode: "98250" },
    { lng: -122.63041, lat: 47.743302, postalCode: "98370" },
    { lng: -122.16858, lat: 47.611021, postalCode: "98005" },
    { lng: -122.32247, lat: 47.112477, postalCode: "98375" },
    { lng: -117.08313, lat: 46.400365, postalCode: "99403" },
    { lng: -119.8857, lat: 46.726526, postalCode: "99349" },
    { lng: -121.56075, lat: 48.265483, postalCode: "98241" },
    { lng: -122.51319, lat: 45.67309, postalCode: "98682" },
    { lng: -122.30118, lat: 47.66377, postalCode: "98105" },
    { lng: -122.51789, lat: 45.791345, postalCode: "98604" },
    { lng: -117.59325, lat: 47.801194, postalCode: "99026" },
    { lng: -118.21339, lat: 48.003202, postalCode: "99129" },
    { lng: -123.54788, lat: 46.561375, postalCode: "98554" },
    { lng: -121.91332, lat: 45.669604, postalCode: "98648" },
    { lng: -122.21005, lat: 47.467422, postalCode: "98055" },
    { lng: -119.75203, lat: 48.112268, postalCode: "98812" },
    { lng: -119.57656, lat: 48.343356, postalCode: "98840" },
    { lng: -121.29141, lat: 45.844123, postalCode: "98602" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98412" },
    { lng: -120.75703, lat: 45.846697, postalCode: "98620" },
    { lng: -123.40713, lat: 47.03575, postalCode: "98541" },
    { lng: -119.38169, lat: 48.9332, postalCode: "98844" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99210" },
    { lng: -122.34517, lat: 48.337796, postalCode: "98238" },
    { lng: -122.69804, lat: 45.795825, postalCode: "98642" },
    { lng: -122.976187, lat: 47.065404, postalCode: "98505" },
    { lng: -124.04801, lat: 46.501781, postalCode: "98640" },
    { lng: -117.793734, lat: 47.646148, postalCode: "99209" },
    { lng: -117.176954, lat: 47.079345, postalCode: "99104" },
    { lng: -117.40636, lat: 47.629091, postalCode: "99203" },
    { lng: -118.3799, lat: 47.376884, postalCode: "99154" },
    { lng: -123.93844, lat: 46.282397, postalCode: "98614" },
    { lng: -122.29752, lat: 47.629653, postalCode: "98112" },
    { lng: -122.27472, lat: 47.450321, postalCode: "98188" },
    { lng: -123.98121, lat: 46.737458, postalCode: "98590" },
    { lng: -124.02626, lat: 46.554, postalCode: "98641" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98184" },
    { lng: -118.38466, lat: 46.044936, postalCode: "99324" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98450" },
    { lng: -122.52023, lat: 45.627459, postalCode: "98684" },
    { lng: -122.20457, lat: 47.84311, postalCode: "98012" },
    { lng: -122.57129, lat: 48.085852, postalCode: "98253" },
    { lng: -117.33776, lat: 47.707542, postalCode: "99217" },
    { lng: -117.6013, lat: 48.102048, postalCode: "99148" },
    { lng: -122.44498, lat: 47.209021, postalCode: "98408" },
    { lng: -119.08897, lat: 47.641983, postalCode: "99135" },
    { lng: -122.63479, lat: 47.569167, postalCode: "98337" },
    { lng: -117.25463, lat: 47.136249, postalCode: "99158" },
    { lng: -123.01599, lat: 46.630721, postalCode: "98532" },
    { lng: -117.29378, lat: 47.921687, postalCode: "99003" },
    { lng: -121.988548, lat: 48.814753, postalCode: "98228" },
    { lng: -122.60562, lat: 47.301252, postalCode: "98335" },
    { lng: -122.29514, lat: 46.562676, postalCode: "98356" },
    { lng: -117.644337, lat: 46.838747, postalCode: "99164" },
    { lng: -122.67573, lat: 46.871178, postalCode: "98576" },
    { lng: -117.25208, lat: 46.793604, postalCode: "99102" },
    { lng: -118.88468, lat: 46.059013, postalCode: "99363" },
    { lng: -122.69416, lat: 47.907561, postalCode: "98365" },
    { lng: -124.02968, lat: 46.312541, postalCode: "98624" },
    { lng: -122.27131, lat: 47.198591, postalCode: "98372" },
    { lng: -117.96887, lat: 46.319087, postalCode: "99328" },
    { lng: -121.241591, lat: 46.915735, postalCode: "98929" },
    { lng: -122.53508, lat: 47.65526, postalCode: "98110" },
    { lng: -122.75285, lat: 47.00581, postalCode: "98513" },
    { lng: -122.36178, lat: 47.309021, postalCode: "98023" },
    { lng: -119.32169, lat: 48.689509, postalCode: "98855" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98015" },
    { lng: -120.41354, lat: 47.481133, postalCode: "98836" },
    { lng: -122.06327, lat: 48.028355, postalCode: "98258" },
    { lng: -119.67687, lat: 45.922025, postalCode: "99345" },
    { lng: -118.20557, lat: 48.86407, postalCode: "99160" },
    { lng: -122.16545, lat: 48.518653, postalCode: "98284" },
    { lng: -122.14169, lat: 47.493031, postalCode: "98059" },
    { lng: -119.13921, lat: 47.458634, postalCode: "98860" },
    { lng: -119.82091, lat: 47.466573, postalCode: "98845" },
    { lng: -120.02851, lat: 48.138528, postalCode: "98834" },
    { lng: -122.247881, lat: 48.853213, postalCode: "98266" },
    { lng: -121.682271, lat: 48.037258, postalCode: "98046" },
    { lng: -119.90614, lat: 46.260129, postalCode: "98930" },
    { lng: -117.27054, lat: 47.52507, postalCode: "99036" },
    { lng: -122.03976, lat: 47.930902, postalCode: "98290" },
    { lng: -122.14584, lat: 47.448322, postalCode: "98058" },
    { lng: -122.3748, lat: 47.54687, postalCode: "98126" },
    { lng: -122.69009, lat: 45.652255, postalCode: "98660" },
    { lng: -122.48668, lat: 46.50494, postalCode: "98564" },
    { lng: -121.78899, lat: 47.86527, postalCode: "98294" },
    { lng: -122.80317, lat: 48.586563, postalCode: "98222" },
    { lng: -122.30406, lat: 47.61157, postalCode: "98122" },
    { lng: -119.35637, lat: 45.960734, postalCode: "99346" },
    { lng: -117.48145, lat: 47.966471, postalCode: "99006" },
    { lng: -120.96163, lat: 45.722786, postalCode: "98613" },
    { lng: -120.32881, lat: 47.425497, postalCode: "98801" },
    { lng: -122.64915, lat: 47.559317, postalCode: "98314" },
    { lng: -121.951, lat: 47.035525, postalCode: "98323" },
    { lng: -120.08379, lat: 47.880774, postalCode: "98816" },
    { lng: -122.6005, lat: 47.164965, postalCode: "98388" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98431" },
    { lng: -122.31188, lat: 47.311072, postalCode: "98003" },
    { lng: -119.68939, lat: 48.885618, postalCode: "98827" },
    { lng: -118.19393, lat: 46.523206, postalCode: "99359" },
    { lng: -119.66129, lat: 47.999618, postalCode: "98813" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98411" },
    { lng: -120.41782, lat: 46.984565, postalCode: "98934" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98190" },
    { lng: -122.14691, lat: 47.039607, postalCode: "98360" },
    { lng: -122.12723, lat: 47.993436, postalCode: "98205" },
    { lng: -123.76506, lat: 46.370075, postalCode: "98638" },
    { lng: -121.57558, lat: 45.730656, postalCode: "98651" },
    { lng: -122.907649, lat: 46.985519, postalCode: "98500" },
    { lng: -117.12916, lat: 46.230508, postalCode: "99402" },
    { lng: -120.46481, lat: 46.616199, postalCode: "98901" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98063" },
    { lng: -122.33186, lat: 47.606139, postalCode: "98164" },
    { lng: -122.520347, lat: 45.801586, postalCode: "98668" },
    { lng: -122.8819, lat: 47.014718, postalCode: "98501" },
    { lng: -118.2869, lat: 46.782783, postalCode: "99371" },
    { lng: -124.02938, lat: 47.463965, postalCode: "98526" },
    { lng: -122.113223, lat: 47.066193, postalCode: "98434" },
    { lng: -123.95463, lat: 46.630051, postalCode: "98527" },
    { lng: -121.88496, lat: 47.430704, postalCode: "98025" },
    { lng: -120.91733, lat: 47.19377, postalCode: "98922" },
    { lng: -122.0451, lat: 47.106024, postalCode: "98396" },
    { lng: -121.73254, lat: 47.483057, postalCode: "98045" },
    { lng: -118.64475, lat: 48.890892, postalCode: "99118" },
    { lng: -121.96222, lat: 47.7353, postalCode: "98019" },
    { lng: -117.644337, lat: 46.838747, postalCode: "99174" },
    { lng: -124.1974, lat: 47.216861, postalCode: "98571" },
    { lng: -120.62097, lat: 46.550348, postalCode: "98903" },
    { lng: -122.31514, lat: 47.546188, postalCode: "98108" },
    { lng: -122.36694, lat: 47.63877, postalCode: "98119" },
    { lng: -122.2328, lat: 47.626571, postalCode: "98039" },
    { lng: -122.30293, lat: 47.916148, postalCode: "98275" },
    { lng: -120.12065, lat: 48.231421, postalCode: "98814" },
    { lng: -121.43163, lat: 48.499159, postalCode: "98267" },
    { lng: -122.24736, lat: 47.75287, postalCode: "98028" },
    { lng: -122.89456, lat: 47.596122, postalCode: "98380" },
    { lng: -117.74582, lat: 47.858074, postalCode: "99034" },
    { lng: -122.050108, lat: 47.909511, postalCode: "98291" },
    { lng: -122.55877, lat: 47.16062, postalCode: "98498" },
    { lng: -121.09804, lat: 45.82786, postalCode: "98628" },
    { lng: -122.39835, lat: 47.296007, postalCode: "98422" },
    { lng: -122.30373, lat: 47.753019, postalCode: "98155" },
    { lng: -121.70018, lat: 48.497601, postalCode: "98237" },
    { lng: -122.59905, lat: 47.53587, postalCode: "98366" },
    { lng: -122.20257, lat: 47.751471, postalCode: "98011" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98073" },
    { lng: -120.75713, lat: 46.707735, postalCode: "98947" },
    { lng: -120.94997, lat: 45.661878, postalCode: "98673" },
    { lng: -123.483596, lat: 47.002178, postalCode: "98583" },
    { lng: -122.62556, lat: 46.513653, postalCode: "98582" },
    { lng: -121.931195, lat: 47.536015, postalCode: "98050" },
    { lng: -117.21699, lat: 47.665819, postalCode: "99216" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98131" },
    { lng: -122.66162, lat: 45.647667, postalCode: "98663" },
    { lng: -124.10735, lat: 46.887187, postalCode: "98595" },
    { lng: -123.872972, lat: 48.18315, postalCode: "98343" },
    { lng: -122.48508, lat: 48.747578, postalCode: "98225" },
    { lng: -117.74564, lat: 48.144634, postalCode: "99181" },
    { lng: -122.11766, lat: 46.53619, postalCode: "98336" },
    { lng: -123.017043, lat: 46.902376, postalCode: "98556" },
    { lng: -122.24463, lat: 47.26619, postalCode: "98047" },
    { lng: -117.60575, lat: 47.466974, postalCode: "99004" },
    { lng: -123.62473, lat: 46.302905, postalCode: "98643" },
    { lng: -122.520347, lat: 45.801586, postalCode: "98666" },
    { lng: -117.43174, lat: 47.648941, postalCode: "99204" },
    { lng: -122.25932, lat: 47.388254, postalCode: "98032" },
    { lng: -122.35916, lat: 47.242186, postalCode: "98424" },
    { lng: -122.48034, lat: 45.73451, postalCode: "98606" },
    { lng: -120.520211, lat: 47.905787, postalCode: "98807" },
    { lng: -117.13639, lat: 47.277842, postalCode: "99018" },
    { lng: -120.28372, lat: 45.816528, postalCode: "99356" },
    { lng: -122.1977, lat: 48.681582, postalCode: "98220" },
    { lng: -122.4156, lat: 47.212421, postalCode: "98404" },
    { lng: -124.35888, lat: 47.838423, postalCode: "98331" },
    { lng: -123.58307, lat: 46.348706, postalCode: "98621" },
    { lng: -122.40517, lat: 47.077796, postalCode: "98387" },
    { lng: -122.03318, lat: 47.640972, postalCode: "98053" },
    { lng: -122.54222, lat: 48.029176, postalCode: "98249" },
    { lng: -117.431742, lat: 47.653568, postalCode: "99258" },
    { lng: -118.44876, lat: 46.408852, postalCode: "99348" },
    { lng: -121.803388, lat: 47.432251, postalCode: "98132" },
    { lng: -117.16133, lat: 46.577614, postalCode: "99113" },
    { lng: -111.33413, lat: 31.579152, postalCode: "85601" },
    { lng: -109.878211, lat: 32.30069, postalCode: "85644" },
    { lng: -112.12023, lat: 33.598841, postalCode: "85029" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85733" },
    { lng: -110.7804, lat: 32.995762, postalCode: "85235" },
    { lng: -110.55929, lat: 34.563994, postalCode: "85928" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85077" },
    { lng: -112.17699, lat: 33.568143, postalCode: "85302" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85728" },
    { lng: -110.92497, lat: 32.128649, postalCode: "85706" },
    { lng: -110.92533, lat: 32.242275, postalCode: "85716" },
    { lng: -111.93712, lat: 31.998543, postalCode: "85634" },
    { lng: -111.62931, lat: 34.9372, postalCode: "86017" },
    { lng: -111.49474, lat: 35.675689, postalCode: "86016" },
    { lng: -110.26032, lat: 34.270743, postalCode: "85934" },
    { lng: -113.5935, lat: 34.681394, postalCode: "85360" },
    { lng: -114.619445, lat: 35.095848, postalCode: "86439" },
    { lng: -113.9537, lat: 33.946064, postalCode: "85325" },
    { lng: -111.57673, lat: 32.712323, postalCode: "85231" },
    { lng: -111.85444, lat: 36.618595, postalCode: "86036" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85341" },
    { lng: -114.71199, lat: 32.58193, postalCode: "85350" },
    { lng: -111.92962, lat: 33.338333, postalCode: "85284" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85377" },
    { lng: -110.98926, lat: 31.488579, postalCode: "85648" },
    { lng: -110.04581, lat: 32.036365, postalCode: "85609" },
    { lng: -113.642712, lat: 35.605301, postalCode: "86405" },
    { lng: -112.28931, lat: 33.66156, postalCode: "85373" },
    { lng: -112.17614, lat: 33.701173, postalCode: "85310" },
    { lng: -110.949996, lat: 32.233761, postalCode: "85721" },
    { lng: -110.97527, lat: 31.939718, postalCode: "85629" },
    { lng: -110.50131, lat: 35.777227, postalCode: "86043" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85285" },
    { lng: -112.39773, lat: 34.706724, postalCode: "86313" },
    { lng: -110.88511, lat: 32.216326, postalCode: "85711" },
    { lng: -112.10262, lat: 33.50689, postalCode: "85015" },
    { lng: -110.288704, lat: 35.285746, postalCode: "86029" },
    { lng: -109.64646, lat: 31.724946, postalCode: "85610" },
    { lng: -111.49949, lat: 32.712136, postalCode: "85241" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85266" },
    { lng: -110.98534, lat: 32.260316, postalCode: "85705" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85062" },
    { lng: -109.33634, lat: 36.88092, postalCode: "86514" },
    { lng: -111.98603, lat: 33.498076, postalCode: "85018" },
    { lng: -110.03633, lat: 34.560896, postalCode: "85937" },
    { lng: -112.09992, lat: 33.921493, postalCode: "85087" },
    { lng: -111.92733, lat: 33.426885, postalCode: "85281" },
    { lng: -112.44726, lat: 34.589477, postalCode: "86301" },
    { lng: -110.70794, lat: 31.504439, postalCode: "85624" },
    { lng: -112.63753, lat: 34.70062, postalCode: "86305" },
    { lng: -114.78056, lat: 32.532458, postalCode: "85336" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85372" },
    { lng: -111.858805, lat: 33.222982, postalCode: "85240" },
    { lng: -109.919794, lat: 31.385182, postalCode: "85620" },
    { lng: -112.25234, lat: 34.518883, postalCode: "86329" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85036" },
    { lng: -112.69667, lat: 34.286193, postalCode: "85362" },
    { lng: -111.68022, lat: 32.749664, postalCode: "85223" },
    { lng: -110.94791, lat: 32.240625, postalCode: "85719" },
    { lng: -109.611545, lat: 31.357528, postalCode: "85626" },
    { lng: -113.85421, lat: 32.744976, postalCode: "85347" },
    { lng: -110.203073, lat: 34.266588, postalCode: "85912" },
    { lng: -113.642712, lat: 35.605301, postalCode: "86411" },
    { lng: -111.72256, lat: 35.279872, postalCode: "86001" },
    { lng: -112.46252, lat: 34.910591, postalCode: "86334" },
    { lng: -112.0521, lat: 33.630497, postalCode: "85022" },
    { lng: -112.0703, lat: 33.505251, postalCode: "85012" },
    { lng: -112.45931, lat: 34.765452, postalCode: "86323" },
    { lng: -111.84701, lat: 33.432177, postalCode: "85201" },
    { lng: -110.21803, lat: 31.898039, postalCode: "85630" },
    { lng: -111.88699, lat: 33.606004, postalCode: "85260" },
    { lng: -114.239557, lat: 33.66688, postalCode: "85359" },
    { lng: -111.0209, lat: 32.242724, postalCode: "85745" },
    { lng: -112.9307, lat: 34.177939, postalCode: "85332" },
    { lng: -109.19785, lat: 33.836157, postalCode: "85920" },
    { lng: -109.59931, lat: 36.662243, postalCode: "86545" },
    { lng: -110.14698, lat: 34.936261, postalCode: "86025" },
    { lng: -114.591728, lat: 34.849946, postalCode: "86446" },
    { lng: -111.42979, lat: 33.013502, postalCode: "85291" },
    { lng: -111.95416, lat: 33.617504, postalCode: "85254" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85752" },
    { lng: -109.8958, lat: 31.406229, postalCode: "85603" },
    { lng: -112.30263, lat: 33.588225, postalCode: "85363" },
    { lng: -110.96487, lat: 34.093715, postalCode: "85554" },
    { lng: -111.64535, lat: 33.35035, postalCode: "85212" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85269" },
    { lng: -111.594435, lat: 33.423596, postalCode: "85200" },
    { lng: -114.68562, lat: 33.326956, postalCode: "85328" },
    { lng: -109.76487, lat: 32.816055, postalCode: "85552" },
    { lng: -114.4663, lat: 34.782526, postalCode: "86436" },
    { lng: -114.27861, lat: 34.469945, postalCode: "86406" },
    { lng: -112.24838, lat: 33.689869, postalCode: "85382" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85038" },
    { lng: -111.8735, lat: 33.328951, postalCode: "85224" },
    { lng: -109.58336, lat: 33.801744, postalCode: "85927" },
    { lng: -111.52617, lat: 32.967829, postalCode: "85228" },
    { lng: -109.2093, lat: 35.610285, postalCode: "86511" },
    { lng: -114.07783, lat: 35.950442, postalCode: "86444" },
    { lng: -112.22791, lat: 33.60864, postalCode: "85381" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85046" },
    { lng: -112.052427, lat: 35.630842, postalCode: "86002" },
    { lng: -109.69052, lat: 32.800222, postalCode: "85546" },
    { lng: -114.49095, lat: 33.631915, postalCode: "85334" },
    { lng: -111.83, lat: 35.235694, postalCode: "86015" },
    { lng: -114.24036, lat: 35.190896, postalCode: "86413" },
    { lng: -112.17531, lat: 33.594069, postalCode: "85304" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85311" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85216" },
    { lng: -110.11686, lat: 33.37214, postalCode: "85550" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85722" },
    { lng: -114.581228, lat: 35.00429, postalCode: "86427" },
    { lng: -114.631172, lat: 32.609959, postalCode: "85366" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85318" },
    { lng: -114.01042, lat: 36.789432, postalCode: "89024" },
    { lng: -113.45703, lat: 32.921712, postalCode: "85333" },
    { lng: -109.22291, lat: 34.094597, postalCode: "85938" },
    { lng: -111.29398, lat: 31.979764, postalCode: "85736" },
    { lng: -111.8004, lat: 33.237229, postalCode: "85249" },
    { lng: -109.24946, lat: 33.681485, postalCode: "85922" },
    { lng: -110.20181, lat: 36.110934, postalCode: "86510" },
    { lng: -114.30203, lat: 35.607935, postalCode: "86441" },
    { lng: -110.18617, lat: 35.765669, postalCode: "86034" },
    { lng: -114.64644, lat: 32.704499, postalCode: "85364" },
    { lng: -109.54223, lat: 35.213287, postalCode: "86502" },
    { lng: -111.28775, lat: 34.257457, postalCode: "85547" },
    { lng: -114.58524, lat: 35.013495, postalCode: "86426" },
    { lng: -114.179228, lat: 35.210634, postalCode: "86443" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85725" },
    { lng: -111.88321, lat: 33.697268, postalCode: "85255" },
    { lng: -112.36474, lat: 33.683352, postalCode: "85375" },
    { lng: -113.976313, lat: 32.693448, postalCode: "85352" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85064" },
    { lng: -110.945346, lat: 32.202726, postalCode: "85726" },
    { lng: -111.4284, lat: 36.726377, postalCode: "86040" },
    { lng: -111.5472, lat: 36.182818, postalCode: "86020" },
    { lng: -110.00633, lat: 36.045603, postalCode: "86520" },
    { lng: -111.837345, lat: 33.466313, postalCode: "85211" },
    { lng: -110.859106, lat: 32.071764, postalCode: "85777" },
    { lng: -112.12285, lat: 33.514092, postalCode: "85017" },
    { lng: -114.33694, lat: 34.483582, postalCode: "86403" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85061" },
    { lng: -112.491528, lat: 34.574921, postalCode: "86302" },
    { lng: -110.60293, lat: 32.597198, postalCode: "85631" },
    { lng: -112.49976, lat: 33.939162, postalCode: "85342" },
    { lng: -110.23013, lat: 31.429017, postalCode: "85615" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85271" },
    { lng: -112.56941, lat: 33.354418, postalCode: "85326" },
    { lng: -110.18425, lat: 36.608485, postalCode: "86033" },
    { lng: -111.96463, lat: 33.689558, postalCode: "85054" },
    { lng: -109.754263, lat: 31.880077, postalCode: "85671" },
    { lng: -112.027663, lat: 33.381234, postalCode: "85042" },
    { lng: -109.7723, lat: 31.911838, postalCode: "85625" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85312" },
    { lng: -109.91803, lat: 32.963028, postalCode: "85535" },
    { lng: -110.89189, lat: 32.196846, postalCode: "85708" },
    { lng: -109.06495, lat: 35.672842, postalCode: "86515" },
    { lng: -111.10495, lat: 36.556111, postalCode: "86053" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85274" },
    { lng: -110.99972, lat: 35.335107, postalCode: "86035" },
    { lng: -112.13361, lat: 33.559783, postalCode: "85051" },
    { lng: -112.32364, lat: 33.596823, postalCode: "85335" },
    { lng: -109.40254, lat: 34.558663, postalCode: "85936" },
    { lng: -112.3501, lat: 34.201191, postalCode: "86343" },
    { lng: -109.9952, lat: 34.162407, postalCode: "85929" },
    { lng: -112.06339, lat: 33.831866, postalCode: "85086" },
    { lng: -109.81342, lat: 32.054694, postalCode: "85606" },
    { lng: -112.32176, lat: 34.621743, postalCode: "86314" },
    { lng: -112.25225, lat: 33.529373, postalCode: "85305" },
    { lng: -111.82628, lat: 33.596995, postalCode: "85259" },
    { lng: -112.07945, lat: 33.77776, postalCode: "85085" },
    { lng: -110.81268, lat: 33.421919, postalCode: "85502" },
    { lng: -111.0416, lat: 31.586392, postalCode: "85640" },
    { lng: -112.28299, lat: 33.426423, postalCode: "85353" },
    { lng: -111.06828, lat: 36.061184, postalCode: "86045" },
    { lng: -114.30462, lat: 34.100704, postalCode: "85344" },
    { lng: -112.22189, lat: 33.528166, postalCode: "85303" },
    { lng: -112.307777, lat: 34.668291, postalCode: "86312" },
    { lng: -110.53128, lat: 35.258309, postalCode: "86047" },
    { lng: -112.492365, lat: 34.907296, postalCode: "86344" },
    { lng: -110.55639, lat: 34.102913, postalCode: "85911" },
    { lng: -110.82864, lat: 32.247175, postalCode: "85715" },
    { lng: -110.5201, lat: 36.00059, postalCode: "86039" },
    { lng: -114.32546, lat: 34.546899, postalCode: "86404" },
    { lng: -111.70835, lat: 33.305783, postalCode: "85236" },
    { lng: -110.944343, lat: 32.240571, postalCode: "85724" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85358" },
    { lng: -111.90545, lat: 33.126134, postalCode: "85221" },
    { lng: -111.75103, lat: 34.653475, postalCode: "86335" },
    { lng: -109.73709, lat: 34.04421, postalCode: "85930" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85214" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85261" },
    { lng: -109.2841, lat: 33.122043, postalCode: "85533" },
    { lng: -114.074901, lat: 32.751632, postalCode: "85369" },
    { lng: -114.52671, lat: 32.700018, postalCode: "85365" },
    { lng: -110.63925, lat: 32.70874, postalCode: "85618" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85071" },
    { lng: -109.32904, lat: 33.044523, postalCode: "85540" },
    { lng: -110.875093, lat: 32.169577, postalCode: "85707" },
    { lng: -110.03334, lat: 33.849439, postalCode: "85941" },
    { lng: -111.05582, lat: 32.385588, postalCode: "85742" },
    { lng: -114.13546, lat: 32.676125, postalCode: "85356" },
    { lng: -112.1767, lat: 33.534128, postalCode: "85301" },
    { lng: -109.17516, lat: 36.272262, postalCode: "86556" },
    { lng: -112.39773, lat: 34.706724, postalCode: "86330" },
    { lng: -112.13122, lat: 33.629911, postalCode: "85053" },
    { lng: -111.17135, lat: 32.417221, postalCode: "85654" },
    { lng: -113.183806, lat: 34.572995, postalCode: "86361" },
    { lng: -114.784135, lat: 32.491745, postalCode: "85439" },
    { lng: -110.3182, lat: 32.010164, postalCode: "85602" },
    { lng: -109.17551, lat: 35.958014, postalCode: "86549" },
    { lng: -109.22194, lat: 35.368902, postalCode: "86506" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85717" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85098" },
    { lng: -110.52048, lat: 34.392829, postalCode: "85933" },
    { lng: -113.642712, lat: 35.605301, postalCode: "86445" },
    { lng: -111.478975, lat: 33.393398, postalCode: "85217" },
    { lng: -114.59734, lat: 35.097719, postalCode: "86442" },
    { lng: -110.932803, lat: 31.853334, postalCode: "85622" },
    { lng: -109.34532, lat: 34.109777, postalCode: "85925" },
    { lng: -110.06684, lat: 31.714525, postalCode: "85638" },
    { lng: -111.80833, lat: 33.348593, postalCode: "85233" },
    { lng: -112.09281, lat: 33.559437, postalCode: "85021" },
    { lng: -111.75093, lat: 33.36344, postalCode: "85234" },
    { lng: -111.686171, lat: 33.212186, postalCode: "85227" },
    { lng: -111.93253, lat: 35.197566, postalCode: "86018" },
    { lng: -112.10088, lat: 34.10865, postalCode: "85324" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85002" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85720" },
    { lng: -111.44851, lat: 35.253002, postalCode: "86004" },
    { lng: -112.0008, lat: 33.466393, postalCode: "85008" },
    { lng: -112.05519, lat: 33.563663, postalCode: "85020" },
    { lng: -111.888824, lat: 33.321316, postalCode: "85244" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85070" },
    { lng: -112.78694, lat: 32.331853, postalCode: "85321" },
    { lng: -110.09181, lat: 34.442667, postalCode: "85939" },
    { lng: -110.897966, lat: 32.200813, postalCode: "85709" },
    { lng: -109.11471, lat: 32.731748, postalCode: "85534" },
    { lng: -111.67554, lat: 33.617643, postalCode: "85264" },
    { lng: -111.11015, lat: 33.28591, postalCode: "85273" },
    { lng: -113.19837, lat: 33.945183, postalCode: "85320" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85289" },
    { lng: -110.92282, lat: 32.31346, postalCode: "85718" },
    { lng: -111.37672, lat: 32.623115, postalCode: "85245" },
    { lng: -110.77201, lat: 32.625543, postalCode: "85623" },
    { lng: -111.868432, lat: 33.499529, postalCode: "85252" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85078" },
    { lng: -111.418233, lat: 33.349996, postalCode: "85218" },
    { lng: -111.934865, lat: 33.428511, postalCode: "85287" },
    { lng: -111.76764, lat: 34.782453, postalCode: "86351" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85740" },
    { lng: -109.41297, lat: 35.952827, postalCode: "86540" },
    { lng: -112.09341, lat: 33.638271, postalCode: "85023" },
    { lng: -111.758483, lat: 33.427135, postalCode: "85123" },
    { lng: -110.9696, lat: 32.216743, postalCode: "85701" },
    { lng: -110.24699, lat: 31.492003, postalCode: "85650" },
    { lng: -110.97202, lat: 33.09977, postalCode: "85237" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85703" },
    { lng: -111.28137, lat: 34.243578, postalCode: "85541" },
    { lng: -111.83509, lat: 33.491302, postalCode: "85256" },
    { lng: -109.07847, lat: 35.325261, postalCode: "86508" },
    { lng: -111.113178, lat: 32.420055, postalCode: "85652" },
    { lng: -110.909305, lat: 31.531998, postalCode: "85662" },
    { lng: -110.909305, lat: 31.531998, postalCode: "85628" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85063" },
    { lng: -112.04875, lat: 33.466392, postalCode: "85006" },
    { lng: -112.052427, lat: 35.630842, postalCode: "86011" },
    { lng: -112.246311, lat: 33.580089, postalCode: "85395" },
    { lng: -112.39773, lat: 34.706724, postalCode: "86340" },
    { lng: -109.754263, lat: 31.880077, postalCode: "85670" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85343" },
    { lng: -112.47107, lat: 36.844685, postalCode: "86022" },
    { lng: -109.91503, lat: 34.121008, postalCode: "85935" },
    { lng: -114.574004, lat: 35.109303, postalCode: "86422" },
    { lng: -109.46196, lat: 32.314118, postalCode: "85605" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85026" },
    { lng: -111.6935, lat: 33.479868, postalCode: "85215" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85076" },
    { lng: -110.76851, lat: 32.216926, postalCode: "85748" },
    { lng: -109.29689, lat: 36.423229, postalCode: "86507" },
    { lng: -111.8756, lat: 33.381097, postalCode: "85202" },
    { lng: -111.46337, lat: 33.402969, postalCode: "85219" },
    { lng: -111.04599, lat: 32.126223, postalCode: "85746" },
    { lng: -111.989364, lat: 34.704667, postalCode: "86366" },
    { lng: -112.09812, lat: 33.29974, postalCode: "85045" },
    { lng: -112.44792, lat: 33.569705, postalCode: "85355" },
    { lng: -112.00416, lat: 33.62414, postalCode: "85032" },
    { lng: -114.390171, lat: 34.031791, postalCode: "85371" },
    { lng: -112.00815, lat: 33.585037, postalCode: "85028" },
    { lng: -114.49451, lat: 35.256802, postalCode: "86430" },
    { lng: -110.66869, lat: 31.733433, postalCode: "85637" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85065" },
    { lng: -112.23657, lat: 35.378832, postalCode: "86046" },
    { lng: -112.38482, lat: 33.430922, postalCode: "85338" },
    { lng: -113.95225, lat: 35.25925, postalCode: "86401" },
    { lng: -112.1767, lat: 33.624546, postalCode: "85306" },
    { lng: -109.61956, lat: 36.165779, postalCode: "86503" },
    { lng: -109.86488, lat: 36.798893, postalCode: "86535" },
    { lng: -112.1417, lat: 33.511992, postalCode: "85019" },
    { lng: -111.26232, lat: 32.454488, postalCode: "85653" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85066" },
    { lng: -112.0281, lat: 33.437772, postalCode: "85034" },
    { lng: -111.71686, lat: 33.426932, postalCode: "85205" },
    { lng: -111.64302, lat: 33.393967, postalCode: "85208" },
    { lng: -110.09228, lat: 35.48041, postalCode: "86031" },
    { lng: -112.04717, lat: 33.675564, postalCode: "85024" },
    { lng: -110.71225, lat: 32.084775, postalCode: "85732" },
    { lng: -110.72198, lat: 32.945838, postalCode: "85292" },
    { lng: -111.00904, lat: 31.857504, postalCode: "85614" },
    { lng: -112.05927, lat: 33.850506, postalCode: "85286" },
    { lng: -112.23039, lat: 36.499283, postalCode: "86052" },
    { lng: -109.23407, lat: 31.569536, postalCode: "85608" },
    { lng: -110.280111, lat: 31.668685, postalCode: "85636" },
    { lng: -112.29588, lat: 33.431242, postalCode: "85329" },
    { lng: -111.326045, lat: 32.983653, postalCode: "85279" },
    { lng: -109.98719, lat: 33.02874, postalCode: "85536" },
    { lng: -111.46865, lat: 34.394226, postalCode: "85544" },
    { lng: -111.67915, lat: 33.722594, postalCode: "85263" },
    { lng: -113.16874, lat: 34.581245, postalCode: "86231" },
    { lng: -109.767893, lat: 32.850901, postalCode: "85522" },
    { lng: -112.07057, lat: 33.451093, postalCode: "85004" },
    { lng: -112.45399, lat: 34.515939, postalCode: "86303" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85380" },
    { lng: -109.79267, lat: 32.872122, postalCode: "85531" },
    { lng: -112.244827, lat: 33.700837, postalCode: "85383" },
    { lng: -110.75681, lat: 33.53457, postalCode: "85501" },
    { lng: -111.99271, lat: 33.683603, postalCode: "85050" },
    { lng: -112.11505, lat: 32.989261, postalCode: "85239" },
    { lng: -112.052427, lat: 35.630842, postalCode: "86003" },
    { lng: -111.72452, lat: 33.400306, postalCode: "85206" },
    { lng: -109.19635, lat: 32.048448, postalCode: "85632" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85072" },
    { lng: -110.935337, lat: 32.065082, postalCode: "85734" },
    { lng: -112.76952, lat: 33.961122, postalCode: "85390" },
    { lng: -110.65072, lat: 36.630103, postalCode: "86054" },
    { lng: -112.1875, lat: 33.472492, postalCode: "85035" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85005" },
    { lng: -110.2123, lat: 36.990184, postalCode: "84536" },
    { lng: -112.9791, lat: 36.985868, postalCode: "86021" },
    { lng: -111.759944, lat: 34.780464, postalCode: "86551" },
    { lng: -112.23984, lat: 33.573602, postalCode: "85345" },
    { lng: -112.38502, lat: 33.643508, postalCode: "85374" },
    { lng: -112.088337, lat: 33.876452, postalCode: "85807" },
    { lng: -111.96301, lat: 33.789204, postalCode: "85331" },
    { lng: -109.55035, lat: 31.382775, postalCode: "85607" },
    { lng: -109.22149, lat: 33.927014, postalCode: "85932" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85723" },
    { lng: -114.063651, lat: 35.263249, postalCode: "86402" },
    { lng: -111.92079, lat: 33.494152, postalCode: "85251" },
    { lng: -111.80666, lat: 34.876638, postalCode: "86336" },
    { lng: -112.16752, lat: 33.352795, postalCode: "85339" },
    { lng: -113.66065, lat: 35.386906, postalCode: "86437" },
    { lng: -112.722998, lat: 33.973301, postalCode: "85330" },
    { lng: -111.886707, lat: 33.820609, postalCode: "85317" },
    { lng: -112.7946, lat: 32.971684, postalCode: "85337" },
    { lng: -109.52295, lat: 35.237487, postalCode: "86028" },
    { lng: -109.32309, lat: 35.15632, postalCode: "86512" },
    { lng: -111.86355, lat: 33.239097, postalCode: "85248" },
    { lng: -114.14175, lat: 34.805546, postalCode: "86438" },
    { lng: -112.11429, lat: 33.698731, postalCode: "85027" },
    { lng: -109.752196, lat: 32.797009, postalCode: "85548" },
    { lng: -109.45613, lat: 36.494915, postalCode: "86547" },
    { lng: -110.75682, lat: 32.441496, postalCode: "85619" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85290" },
    { lng: -114.40884, lat: 32.654701, postalCode: "85367" },
    { lng: -111.76494, lat: 31.773676, postalCode: "85639" },
    { lng: -109.11872, lat: 36.59559, postalCode: "86544" },
    { lng: -112.140771, lat: 33.909138, postalCode: "85089" },
    { lng: -113.5121, lat: 35.482227, postalCode: "86434" },
    { lng: -111.23968, lat: 32.13918, postalCode: "85735" },
    { lng: -111.90944, lat: 33.521433, postalCode: "85250" },
    { lng: -109.17878, lat: 35.771067, postalCode: "86504" },
    { lng: -109.754263, lat: 31.880077, postalCode: "85655" },
    { lng: -110.86804, lat: 36.507554, postalCode: "86044" },
    { lng: -114.38928, lat: 35.030577, postalCode: "86433" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85387" },
    { lng: -110.9087, lat: 32.521096, postalCode: "85739" },
    { lng: -112.40158, lat: 33.616888, postalCode: "85379" },
    { lng: -114.77866, lat: 32.494054, postalCode: "85349" },
    { lng: -109.96697, lat: 34.778649, postalCode: "85942" },
    { lng: -109.7114, lat: 34.448733, postalCode: "85924" },
    { lng: -111.97564, lat: 32.852769, postalCode: "85272" },
    { lng: -111.92873, lat: 33.394067, postalCode: "85282" },
    { lng: -109.67514, lat: 34.268984, postalCode: "85940" },
    { lng: -109.88461, lat: 32.356537, postalCode: "85643" },
    { lng: -110.77573, lat: 32.109302, postalCode: "85747" },
    { lng: -110.035185, lat: 34.298092, postalCode: "85902" },
    { lng: -110.714678, lat: 32.161972, postalCode: "85751" },
    { lng: -109.67608, lat: 31.536321, postalCode: "85617" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85069" },
    { lng: -112.02477, lat: 33.302098, postalCode: "85048" },
    { lng: -111.326045, lat: 32.983653, postalCode: "85278" },
    { lng: -112.06498, lat: 35.862292, postalCode: "86023" },
    { lng: -112.01002, lat: 34.723788, postalCode: "86326" },
    { lng: -112.17825, lat: 33.657722, postalCode: "85308" },
    { lng: -112.28316, lat: 33.60642, postalCode: "85351" },
    { lng: -111.64338, lat: 33.436767, postalCode: "85207" },
    { lng: -109.65344, lat: 35.665344, postalCode: "86505" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85299" },
    { lng: -113.11473, lat: 34.543627, postalCode: "86321" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85376" },
    { lng: -110.61562, lat: 36.115339, postalCode: "86030" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85060" },
    { lng: -112.78934, lat: 33.274422, postalCode: "85322" },
    { lng: -112.17108, lat: 33.493496, postalCode: "85031" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85702" },
    { lng: -112.13612, lat: 34.339938, postalCode: "86333" },
    { lng: -109.84995, lat: 32.90375, postalCode: "85543" },
    { lng: -111.99246, lat: 33.333591, postalCode: "85044" },
    { lng: -111.31629, lat: 32.961615, postalCode: "85232" },
    { lng: -112.12378, lat: 33.45635, postalCode: "85009" },
    { lng: -110.98801, lat: 32.32764, postalCode: "85704" },
    { lng: -110.96354, lat: 32.417144, postalCode: "85737" },
    { lng: -111.95645, lat: 33.544596, postalCode: "85253" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85313" },
    { lng: -113.05774, lat: 35.334394, postalCode: "86337" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85073" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85267" },
    { lng: -111.787154, lat: 34.641736, postalCode: "86342" },
    { lng: -113.53441, lat: 33.843519, postalCode: "85357" },
    { lng: -112.02857, lat: 33.393323, postalCode: "85040" },
    { lng: -114.21626, lat: 35.413284, postalCode: "86431" },
    { lng: -111.06352, lat: 31.623432, postalCode: "85646" },
    { lng: -112.40523, lat: 33.50835, postalCode: "85340" },
    { lng: -112.03235, lat: 33.503742, postalCode: "85016" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85275" },
    { lng: -110.5231, lat: 33.262553, postalCode: "85542" },
    { lng: -110.83591, lat: 34.393887, postalCode: "85931" },
    { lng: -110.30004, lat: 31.541437, postalCode: "85613" },
    { lng: -112.25539, lat: 33.493006, postalCode: "85037" },
    { lng: -112.21405, lat: 33.496091, postalCode: "85033" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85039" },
    { lng: -110.9264, lat: 31.37737, postalCode: "85621" },
    { lng: -110.03676, lat: 34.271427, postalCode: "85901" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85385" },
    { lng: -112.20644, lat: 34.533237, postalCode: "86327" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85246" },
    { lng: -112.20009, lat: 33.435405, postalCode: "85043" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85775" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85055" },
    { lng: -111.8708, lat: 33.798581, postalCode: "85262" },
    { lng: -111.78945, lat: 32.742949, postalCode: "85230" },
    { lng: -111.30102, lat: 33.830708, postalCode: "85553" },
    { lng: -112.59778, lat: 33.739904, postalCode: "85361" },
    { lng: -111.931298, lat: 33.401395, postalCode: "85280" },
    { lng: -111.09908, lat: 31.718671, postalCode: "85645" },
    { lng: -114.54696, lat: 35.172854, postalCode: "86429" },
    { lng: -110.36628, lat: 35.835642, postalCode: "86042" },
    { lng: -113.01652, lat: 33.452432, postalCode: "85354" },
    { lng: -112.06438, lat: 34.767841, postalCode: "86324" },
    { lng: -112.10076, lat: 33.386995, postalCode: "85041" },
    { lng: -112.351835, lat: 33.703967, postalCode: "85001" },
    { lng: -111.93155, lat: 33.365951, postalCode: "85283" },
    { lng: -111.72852, lat: 33.604811, postalCode: "85268" },
    { lng: -111.889286, lat: 33.672973, postalCode: "85288" },
    { lng: -111.84129, lat: 33.391233, postalCode: "85210" },
    { lng: -110.51857, lat: 31.588594, postalCode: "85611" },
    { lng: -111.91741, lat: 33.467347, postalCode: "85257" },
    { lng: -113.62376, lat: 33.730443, postalCode: "85348" },
    { lng: -112.08483, lat: 33.50711, postalCode: "85013" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85079" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85068" },
    { lng: -111.5762, lat: 33.416083, postalCode: "85220" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85738" },
    { lng: -111.76788, lat: 34.776648, postalCode: "86341" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85074" },
    { lng: -112.51013, lat: 36.198652, postalCode: "86435" },
    { lng: -113.94024, lat: 36.903232, postalCode: "86432" },
    { lng: -111.61087, lat: 33.227266, postalCode: "85242" },
    { lng: -111.806, lat: 33.437493, postalCode: "85203" },
    { lng: -110.708174, lat: 32.088034, postalCode: "85731" },
    { lng: -111.75128, lat: 33.107581, postalCode: "85247" },
    { lng: -110.42509, lat: 35.101925, postalCode: "86032" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85378" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85754" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85011" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85067" },
    { lng: -111.84375, lat: 34.556478, postalCode: "86322" },
    { lng: -112.6261, lat: 34.426513, postalCode: "86332" },
    { lng: -112.49066, lat: 34.59668, postalCode: "86304" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85010" },
    { lng: -111.723635, lat: 33.422621, postalCode: "85025" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85099" },
    { lng: -110.95525, lat: 32.170277, postalCode: "85714" },
    { lng: -110.90026, lat: 33.383937, postalCode: "85539" },
    { lng: -111.89337, lat: 33.566635, postalCode: "85258" },
    { lng: -111.714171, lat: 33.272322, postalCode: "85297" },
    { lng: -112.30607, lat: 33.529314, postalCode: "85307" },
    { lng: -109.08296, lat: 35.923348, postalCode: "87328" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85327" },
    { lng: -111.78594, lat: 33.400127, postalCode: "85204" },
    { lng: -110.34743, lat: 31.684819, postalCode: "85616" },
    { lng: -111.463026, lat: 34.908287, postalCode: "86038" },
    { lng: -111.01062, lat: 33.624008, postalCode: "85545" },
    { lng: -109.696449, lat: 32.842769, postalCode: "85551" },
    { lng: -111.728572, lat: 34.907188, postalCode: "86339" },
    { lng: -112.37688, lat: 33.533985, postalCode: "85309" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85075" },
    { lng: -111.14888, lat: 32.335122, postalCode: "85743" },
    { lng: -112.14586, lat: 34.74428, postalCode: "86331" },
    { lng: -114.23325, lat: 33.654343, postalCode: "85346" },
    { lng: -110.74419, lat: 32.299275, postalCode: "85749" },
    { lng: -111.890713, lat: 31.970131, postalCode: "85744" },
    { lng: -113.843241, lat: 35.397172, postalCode: "86412" },
    { lng: -110.84384, lat: 32.292078, postalCode: "85750" },
    { lng: -111.93177, lat: 33.312208, postalCode: "85226" },
    { lng: -111.04259, lat: 32.338126, postalCode: "85741" },
    { lng: -112.56465, lat: 35.178163, postalCode: "86320" },
    { lng: -110.8878, lat: 32.249551, postalCode: "85712" },
    { lng: -111.83064, lat: 33.316349, postalCode: "85225" },
    { lng: -110.81032, lat: 32.179227, postalCode: "85730" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85080" },
    { lng: -112.70556, lat: 34.557983, postalCode: "86338" },
    { lng: -114.57354, lat: 34.888406, postalCode: "86440" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85097" },
    { lng: -111.90168, lat: 34.718836, postalCode: "86325" },
    { lng: -111.76078, lat: 33.319722, postalCode: "85296" },
    { lng: -110.82559, lat: 32.213291, postalCode: "85710" },
    { lng: -111.76892, lat: 33.434192, postalCode: "85213" },
    { lng: -112.32401, lat: 33.454441, postalCode: "85323" },
    { lng: -110.09917, lat: 32.09881, postalCode: "85627" },
    { lng: -112.09024, lat: 33.449995, postalCode: "85007" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85277" },
    { lng: -110.67442, lat: 32.00196, postalCode: "85641" },
    { lng: -111.16676, lat: 34.690846, postalCode: "86024" },
    { lng: -111.73499, lat: 32.878138, postalCode: "85222" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85082" },
    { lng: -112.05711, lat: 33.511325, postalCode: "85014" },
    { lng: -110.22921, lat: 33.053797, postalCode: "85530" },
    { lng: -110.9757, lat: 32.194909, postalCode: "85713" },
    { lng: -109.6007, lat: 36.374317, postalCode: "86538" },
    { lng: -112.07838, lat: 33.451143, postalCode: "85003" },
    { lng: -112.18717, lat: 33.276539, postalCode: "85030" },
    { lng: -110.814893, lat: 33.415409, postalCode: "85532" },
    { lng: -112.115853, lat: 34.748879, postalCode: "86631" },
    { lng: -110.24288, lat: 31.562546, postalCode: "85635" },
    { lng: -109.86356, lat: 34.074253, postalCode: "85926" },
    { lng: -111.49066, lat: 31.632739, postalCode: "85633" },
    { lng: -110.29487, lat: 34.364618, postalCode: "85923" },
    { lng: -117.138414, lat: 32.643703, postalCode: "92132" },
    { lng: -117.89243, lat: 33.606271, postalCode: "92662" },
    { lng: -105.30102, lat: 39.653899, postalCode: "80457" },
    { lng: -104.992842, lat: 39.74739, postalCode: "80256" },
    { lng: -104.76208, lat: 40.746862, postalCode: "80648" },
    { lng: -108.29691, lat: 37.363564, postalCode: "81328" },
    { lng: -104.938391, lat: 39.731038, postalCode: "80262" },
    { lng: -106.7751, lat: 39.622314, postalCode: "81631" },
    { lng: -105.01233, lat: 40.762264, postalCode: "80549" },
    { lng: -106.02648, lat: 37.101134, postalCode: "81129" },
    { lng: -104.96473, lat: 39.679437, postalCode: "80210" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80309" },
    { lng: -108.023924, lat: 39.430535, postalCode: "81636" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80321" },
    { lng: -104.62567, lat: 38.281052, postalCode: "81003" },
    { lng: -105.06251, lat: 39.745526, postalCode: "80214" },
    { lng: -105.10441, lat: 39.744437, postalCode: "80215" },
    { lng: -104.876649, lat: 39.693573, postalCode: "80247" },
    { lng: -106.92691, lat: 40.348242, postalCode: "80477" },
    { lng: -104.77723, lat: 39.623896, postalCode: "80015" },
    { lng: -107.32107, lat: 39.533318, postalCode: "81601" },
    { lng: -105.15053, lat: 39.963322, postalCode: "80027" },
    { lng: -104.73975, lat: 38.888394, postalCode: "80917" },
    { lng: -102.30451, lat: 40.582954, postalCode: "80734" },
    { lng: -106.860746, lat: 40.619661, postalCode: "80488" },
    { lng: -104.22705, lat: 40.877461, postalCode: "80729" },
    { lng: -104.885116, lat: 40.464092, postalCode: "80551" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80328" },
    { lng: -105.163377, lat: 40.38636, postalCode: "80539" },
    { lng: -104.773083, lat: 39.669637, postalCode: "80000" },
    { lng: -105.74027, lat: 39.310793, postalCode: "80456" },
    { lng: -107.18886, lat: 40.929768, postalCode: "81653" },
    { lng: -107.025486, lat: 39.361605, postalCode: "81260" },
    { lng: -102.16663, lat: 40.681557, postalCode: "80721" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80940" },
    { lng: -105.18591, lat: 39.833442, postalCode: "80007" },
    { lng: -104.89892, lat: 37.909758, postalCode: "81069" },
    { lng: -104.96698, lat: 39.706535, postalCode: "80209" },
    { lng: -104.93485, lat: 38.853663, postalCode: "80829" },
    { lng: -104.701177, lat: 38.819195, postalCode: "80914" },
    { lng: -105.63616, lat: 38.783844, postalCode: "80820" },
    { lng: -105.18746, lat: 38.927344, postalCode: "80814" },
    { lng: -105.01123, lat: 40.502779, postalCode: "80528" },
    { lng: -105.50805, lat: 39.964486, postalCode: "80466" },
    { lng: -105.79431, lat: 39.24344, postalCode: "80432" },
    { lng: -107.84945, lat: 37.295888, postalCode: "81301" },
    { lng: -104.91348, lat: 39.688437, postalCode: "80224" },
    { lng: -105.3868, lat: 40.027672, postalCode: "80308" },
    { lng: -102.42882, lat: 37.377938, postalCode: "81087" },
    { lng: -105.12263, lat: 39.81431, postalCode: "80004" },
    { lng: -105.90161, lat: 37.144944, postalCode: "81141" },
    { lng: -102.75387, lat: 39.36479, postalCode: "80861" },
    { lng: -107.292081, lat: 38.457513, postalCode: "81247" },
    { lng: -103.82915, lat: 40.270801, postalCode: "80705" },
    { lng: -107.64223, lat: 37.099348, postalCode: "81137" },
    { lng: -105.61523, lat: 39.766277, postalCode: "80436" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80943" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80960" },
    { lng: -105.54778, lat: 37.134458, postalCode: "81152" },
    { lng: -104.77158, lat: 38.813044, postalCode: "80910" },
    { lng: -104.37807, lat: 38.089719, postalCode: "81022" },
    { lng: -104.98696, lat: 39.744086, postalCode: "80290" },
    { lng: -104.28784, lat: 40.095142, postalCode: "80652" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80154" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80912" },
    { lng: -105.275045, lat: 40.177043, postalCode: "80544" },
    { lng: -104.98306, lat: 39.731286, postalCode: "80203" },
    { lng: -104.75847, lat: 40.637244, postalCode: "80650" },
    { lng: -106.92849, lat: 40.266778, postalCode: "80467" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80257" },
    { lng: -105.602959, lat: 39.439493, postalCode: "80475" },
    { lng: -104.02409, lat: 39.378712, postalCode: "80101" },
    { lng: -105.36876, lat: 39.45658, postalCode: "80470" },
    { lng: -105.384694, lat: 40.102219, postalCode: "80306" },
    { lng: -104.987577, lat: 39.743934, postalCode: "80274" },
    { lng: -104.49916, lat: 40.078242, postalCode: "80643" },
    { lng: -104.82093, lat: 39.636562, postalCode: "80115" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80263" },
    { lng: -108.16033, lat: 39.102266, postalCode: "81643" },
    { lng: -106.14029, lat: 39.532506, postalCode: "80443" },
    { lng: -105.48044, lat: 40.10613, postalCode: "80481" },
    { lng: -106.937134, lat: 39.225729, postalCode: "81516" },
    { lng: -108.45204, lat: 37.515311, postalCode: "81323" },
    { lng: -108.58155, lat: 38.833235, postalCode: "81527" },
    { lng: -102.5296, lat: 37.136682, postalCode: "81029" },
    { lng: -105.22883, lat: 39.989135, postalCode: "80303" },
    { lng: -105.71302, lat: 39.762835, postalCode: "80438" },
    { lng: -105.10033, lat: 40.171484, postalCode: "80501" },
    { lng: -104.95943, lat: 39.859585, postalCode: "80229" },
    { lng: -105.53422, lat: 39.804776, postalCode: "80427" },
    { lng: -107.26093, lat: 40.48806, postalCode: "81639" },
    { lng: -103.51113, lat: 38.107901, postalCode: "81030" },
    { lng: -105.29673, lat: 40.020885, postalCode: "80302" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80935" },
    { lng: -105.05404, lat: 39.340969, postalCode: "80135" },
    { lng: -104.407918, lat: 39.80797, postalCode: "80035" },
    { lng: -104.95805, lat: 39.607386, postalCode: "80121" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80947" },
    { lng: -105.69706, lat: 37.945768, postalCode: "81131" },
    { lng: -107.51756, lat: 40.298366, postalCode: "81638" },
    { lng: -104.96214, lat: 39.784622, postalCode: "80216" },
    { lng: -105.223945, lat: 39.522014, postalCode: "80034" },
    { lng: -105.03483, lat: 39.698137, postalCode: "80219" },
    { lng: -104.5151, lat: 40.481168, postalCode: "80624" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80044" },
    { lng: -106.24457, lat: 40.621621, postalCode: "80480" },
    { lng: -104.7241, lat: 39.355957, postalCode: "80116" },
    { lng: -106.92438, lat: 40.854473, postalCode: "80428" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80950" },
    { lng: -104.7485, lat: 39.595115, postalCode: "80016" },
    { lng: -105.02767, lat: 40.051051, postalCode: "80516" },
    { lng: -105.32256, lat: 39.827903, postalCode: "80403" },
    { lng: -105.46846, lat: 39.031989, postalCode: "80827" },
    { lng: -106.95776, lat: 38.464132, postalCode: "81230" },
    { lng: -104.89644, lat: 39.543478, postalCode: "80124" },
    { lng: -105.12044, lat: 39.69709, postalCode: "80225" },
    { lng: -104.66762, lat: 40.61798, postalCode: "80610" },
    { lng: -107.86975, lat: 37.860092, postalCode: "81426" },
    { lng: -104.52912, lat: 38.243266, postalCode: "81006" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80944" },
    { lng: -107.59315, lat: 38.636112, postalCode: "81415" },
    { lng: -104.9544, lat: 39.903043, postalCode: "80233" },
    { lng: -104.80456, lat: 37.621071, postalCode: "81089" },
    { lng: -102.69795, lat: 39.633953, postalCode: "80822" },
    { lng: -104.994708, lat: 39.347863, postalCode: "80163" },
    { lng: -104.7232, lat: 38.746807, postalCode: "80911" },
    { lng: -105.0535, lat: 40.532354, postalCode: "80525" },
    { lng: -107.8625, lat: 38.436409, postalCode: "81401" },
    { lng: -104.407918, lat: 39.80797, postalCode: "80036" },
    { lng: -104.807081, lat: 38.942365, postalCode: "80902" },
    { lng: -104.991334, lat: 39.746239, postalCode: "80259" },
    { lng: -106.03468, lat: 38.533464, postalCode: "81201" },
    { lng: -105.40415, lat: 40.878334, postalCode: "80536" },
    { lng: -105.011786, lat: 38.939371, postalCode: "80933" },
    { lng: -105.34164, lat: 37.175339, postalCode: "81126" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81011" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80217" },
    { lng: -104.95609, lat: 38.069421, postalCode: "81023" },
    { lng: -105.00829, lat: 39.9091, postalCode: "80234" },
    { lng: -104.45068, lat: 38.629157, postalCode: "80928" },
    { lng: -108.93781, lat: 39.244427, postalCode: "81525" },
    { lng: -104.99999, lat: 39.699156, postalCode: "80223" },
    { lng: -107.34452, lat: 37.218131, postalCode: "81127" },
    { lng: -105.41818, lat: 40.412496, postalCode: "80515" },
    { lng: -108.50164, lat: 38.29545, postalCode: "81424" },
    { lng: -106.256615, lat: 39.030747, postalCode: "81228" },
    { lng: -104.95243, lat: 39.731237, postalCode: "80206" },
    { lng: -106.47812, lat: 38.410975, postalCode: "81248" },
    { lng: -105.51722, lat: 40.370415, postalCode: "80517" },
    { lng: -108.76266, lat: 40.334542, postalCode: "81610" },
    { lng: -102.95628, lat: 40.215063, postalCode: "80743" },
    { lng: -102.88573, lat: 39.315812, postalCode: "80834" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80970" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80244" },
    { lng: -103.27669, lat: 40.825947, postalCode: "80745" },
    { lng: -105.476577, lat: 38.477563, postalCode: "81246" },
    { lng: -102.54595, lat: 40.86845, postalCode: "80749" },
    { lng: -105.10494, lat: 40.586282, postalCode: "80521" },
    { lng: -103.89534, lat: 39.089871, postalCode: "80830" },
    { lng: -107.92158, lat: 40.042797, postalCode: "81641" },
    { lng: -104.79723, lat: 40.090367, postalCode: "80621" },
    { lng: -105.01772, lat: 40.160138, postalCode: "80504" },
    { lng: -108.39974, lat: 40.594344, postalCode: "81640" },
    { lng: -104.83034, lat: 39.788236, postalCode: "80239" },
    { lng: -108.88769, lat: 37.178946, postalCode: "81334" },
    { lng: -104.91851, lat: 39.844685, postalCode: "80024" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80252" },
    { lng: -105.14558, lat: 39.688278, postalCode: "80228" },
    { lng: -104.47809, lat: 37.191882, postalCode: "81082" },
    { lng: -105.33762, lat: 40.253033, postalCode: "80540" },
    { lng: -106.49424, lat: 40.436948, postalCode: "80430" },
    { lng: -106.63444, lat: 39.342438, postalCode: "81642" },
    { lng: -105.223945, lat: 39.522014, postalCode: "80001" },
    { lng: -104.987535, lat: 39.727293, postalCode: "80273" },
    { lng: -104.523155, lat: 37.121029, postalCode: "81074" },
    { lng: -106.11862, lat: 40.101485, postalCode: "80451" },
    { lng: -104.994708, lat: 39.347863, postalCode: "80131" },
    { lng: -104.76602, lat: 38.916226, postalCode: "80918" },
    { lng: -102.3726, lat: 40.888124, postalCode: "80744" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80251" },
    { lng: -104.96808, lat: 39.544549, postalCode: "80126" },
    { lng: -106.039412, lat: 39.527772, postalCode: "80924" },
    { lng: -105.8686, lat: 40.00167, postalCode: "80478" },
    { lng: -106.39774, lat: 37.731981, postalCode: "81132" },
    { lng: -104.00612, lat: 40.36593, postalCode: "80653" },
    { lng: -104.66016, lat: 37.400041, postalCode: "81020" },
    { lng: -108.72071, lat: 39.159971, postalCode: "81521" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80841" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80238" },
    { lng: -106.31399, lat: 39.231776, postalCode: "80461" },
    { lng: -108.58311, lat: 39.059221, postalCode: "81503" },
    { lng: -105.14752, lat: 38.36154, postalCode: "81221" },
    { lng: -105.83471, lat: 37.471802, postalCode: "81101" },
    { lng: -105.02111, lat: 38.439499, postalCode: "81240" },
    { lng: -107.59026, lat: 37.329743, postalCode: "81122" },
    { lng: -104.885409, lat: 39.453833, postalCode: "80108" },
    { lng: -105.98648, lat: 37.171583, postalCode: "81148" },
    { lng: -104.858227, lat: 37.951881, postalCode: "81079" },
    { lng: -103.69416, lat: 39.273148, postalCode: "80828" },
    { lng: -104.83755, lat: 39.044969, postalCode: "80921" },
    { lng: -105.08866, lat: 39.690387, postalCode: "80232" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80934" },
    { lng: -102.61726, lat: 37.409787, postalCode: "81073" },
    { lng: -105.223945, lat: 39.522014, postalCode: "80437" },
    { lng: -104.87298, lat: 38.652352, postalCode: "80926" },
    { lng: -108.2586, lat: 38.082231, postalCode: "81423" },
    { lng: -105.06708, lat: 39.479365, postalCode: "80125" },
    { lng: -104.07361, lat: 40.196296, postalCode: "80654" },
    { lng: -104.99747, lat: 40.234505, postalCode: "80542" },
    { lng: -108.08219, lat: 38.733901, postalCode: "81416" },
    { lng: -106.62831, lat: 38.778749, postalCode: "81210" },
    { lng: -104.77878, lat: 40.28319, postalCode: "80623" },
    { lng: -104.358375, lat: 37.279584, postalCode: "81046" },
    { lng: -104.73823, lat: 38.808934, postalCode: "80916" },
    { lng: -104.90983, lat: 39.206652, postalCode: "80118" },
    { lng: -104.97188, lat: 37.193451, postalCode: "81091" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81010" },
    { lng: -104.61964, lat: 38.370203, postalCode: "81008" },
    { lng: -105.429395, lat: 37.197731, postalCode: "81138" },
    { lng: -104.70607, lat: 39.523171, postalCode: "80138" },
    { lng: -105.58024, lat: 37.419263, postalCode: "81123" },
    { lng: -104.95834, lat: 39.582604, postalCode: "80122" },
    { lng: -105.43325, lat: 40.497758, postalCode: "80532" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80937" },
    { lng: -106.14476, lat: 38.327695, postalCode: "81155" },
    { lng: -105.10036, lat: 39.774341, postalCode: "80033" },
    { lng: -105.280009, lat: 40.540565, postalCode: "80527" },
    { lng: -104.74329, lat: 38.333601, postalCode: "81007" },
    { lng: -105.17388, lat: 40.67069, postalCode: "80535" },
    { lng: -108.92455, lat: 37.775543, postalCode: "81324" },
    { lng: -108.34069, lat: 39.378823, postalCode: "81630" },
    { lng: -104.26915, lat: 40.581437, postalCode: "80611" },
    { lng: -107.88874, lat: 37.932874, postalCode: "81435" },
    { lng: -105.08918, lat: 39.71222, postalCode: "80226" },
    { lng: -106.51534, lat: 39.63298, postalCode: "81620" },
    { lng: -105.06439, lat: 39.825357, postalCode: "80003" },
    { lng: -107.325336, lat: 39.511742, postalCode: "81602" },
    { lng: -105.01363, lat: 38.940813, postalCode: "80819" },
    { lng: -102.36417, lat: 39.940858, postalCode: "80755" },
    { lng: -105.12429, lat: 38.719345, postalCode: "80860" },
    { lng: -105.39844, lat: 40.094785, postalCode: "80455" },
    { lng: -108.78311, lat: 37.508184, postalCode: "81335" },
    { lng: -104.91945, lat: 39.759386, postalCode: "80207" },
    { lng: -107.935945, lat: 37.357249, postalCode: "81302" },
    { lng: -105.52421, lat: 40.223935, postalCode: "80510" },
    { lng: -103.16965, lat: 38.45313, postalCode: "81045" },
    { lng: -105.57057, lat: 39.908923, postalCode: "80474" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80997" },
    { lng: -106.94528, lat: 39.214104, postalCode: "81615" },
    { lng: -105.10036, lat: 40.002156, postalCode: "80026" },
    { lng: -106.97082, lat: 39.379423, postalCode: "81621" },
    { lng: -104.991511, lat: 39.747179, postalCode: "80266" },
    { lng: -107.885975, lat: 38.485093, postalCode: "81402" },
    { lng: -104.60811, lat: 39.749664, postalCode: "80137" },
    { lng: -104.17321, lat: 38.688075, postalCode: "80864" },
    { lng: -102.78823, lat: 40.888254, postalCode: "80726" },
    { lng: -105.08431, lat: 39.648328, postalCode: "80235" },
    { lng: -106.95429, lat: 37.705276, postalCode: "81130" },
    { lng: -105.06335, lat: 38.996004, postalCode: "80863" },
    { lng: -104.69627, lat: 38.674013, postalCode: "80817" },
    { lng: -104.58696, lat: 39.397242, postalCode: "80107" },
    { lng: -104.8621, lat: 39.739387, postalCode: "80010" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80270" },
    { lng: -107.67438, lat: 38.016388, postalCode: "81427" },
    { lng: -108.78893, lat: 39.238633, postalCode: "81524" },
    { lng: -106.097284, lat: 38.471892, postalCode: "81242" },
    { lng: -106.8191, lat: 39.196857, postalCode: "81611" },
    { lng: -106.08325, lat: 37.086403, postalCode: "81120" },
    { lng: -108.0098, lat: 38.605432, postalCode: "81425" },
    { lng: -107.780778, lat: 38.781745, postalCode: "81420" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80962" },
    { lng: -104.54313, lat: 38.96634, postalCode: "80831" },
    { lng: -105.04143, lat: 39.866785, postalCode: "80031" },
    { lng: -104.02959, lat: 37.513166, postalCode: "81059" },
    { lng: -105.63525, lat: 40.824993, postalCode: "80545" },
    { lng: -107.939831, lat: 38.942927, postalCode: "81421" },
    { lng: -105.7258, lat: 39.694915, postalCode: "80444" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80329" },
    { lng: -102.1746, lat: 38.834807, postalCode: "80802" },
    { lng: -106.96556, lat: 38.865307, postalCode: "81224" },
    { lng: -107.15542, lat: 38.249778, postalCode: "81243" },
    { lng: -106.20137, lat: 40.455817, postalCode: "80473" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81013" },
    { lng: -103.48045, lat: 39.020889, postalCode: "80821" },
    { lng: -105.22472, lat: 39.361089, postalCode: "80425" },
    { lng: -105.09953, lat: 39.881608, postalCode: "80021" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80255" },
    { lng: -104.407918, lat: 39.80797, postalCode: "80614" },
    { lng: -107.96085, lat: 38.842439, postalCode: "81418" },
    { lng: -104.31497, lat: 40.500919, postalCode: "80633" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80307" },
    { lng: -108.481354, lat: 39.017876, postalCode: "81502" },
    { lng: -103.54469, lat: 38.709972, postalCode: "80823" },
    { lng: -104.07125, lat: 39.034273, postalCode: "80832" },
    { lng: -104.89627, lat: 39.721763, postalCode: "80230" },
    { lng: -104.7791, lat: 39.659105, postalCode: "80013" },
    { lng: -104.88139, lat: 39.610431, postalCode: "80111" },
    { lng: -104.81869, lat: 38.873692, postalCode: "80907" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80046" },
    { lng: -106.33104, lat: 40.92301, postalCode: "80434" },
    { lng: -104.69175, lat: 40.421845, postalCode: "80631" },
    { lng: -105.05106, lat: 40.606962, postalCode: "80524" },
    { lng: -105.00805, lat: 39.868635, postalCode: "80260" },
    { lng: -108.77528, lat: 40.011447, postalCode: "81648" },
    { lng: -106.93018, lat: 39.885527, postalCode: "80426" },
    { lng: -106.32403, lat: 39.451885, postalCode: "81649" },
    { lng: -105.057005, lat: 40.642907, postalCode: "80522" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80314" },
    { lng: -104.90227, lat: 40.47997, postalCode: "80550" },
    { lng: -106.74302, lat: 39.912186, postalCode: "80463" },
    { lng: -105.45798, lat: 38.130741, postalCode: "81252" },
    { lng: -104.99076, lat: 39.646847, postalCode: "80110" },
    { lng: -105.223945, lat: 39.522014, postalCode: "80402" },
    { lng: -108.92858, lat: 38.318109, postalCode: "81411" },
    { lng: -105.072049, lat: 39.862286, postalCode: "80061" },
    { lng: -104.629665, lat: 39.943831, postalCode: "80603" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80165" },
    { lng: -104.73461, lat: 37.315263, postalCode: "81042" },
    { lng: -105.53192, lat: 38.357344, postalCode: "81223" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80161" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80038" },
    { lng: -103.96314, lat: 38.694617, postalCode: "80833" },
    { lng: -104.50403, lat: 38.811434, postalCode: "80930" },
    { lng: -105.3675, lat: 37.127132, postalCode: "81153" },
    { lng: -105.03736, lat: 39.830936, postalCode: "80030" },
    { lng: -105.18642, lat: 38.353064, postalCode: "81244" },
    { lng: -105.04089, lat: 39.652454, postalCode: "80236" },
    { lng: -102.19677, lat: 38.060575, postalCode: "81047" },
    { lng: -107.986174, lat: 38.788697, postalCode: "81414" },
    { lng: -104.77856, lat: 38.851493, postalCode: "80909" },
    { lng: -103.51203, lat: 40.340456, postalCode: "80733" },
    { lng: -104.88288, lat: 39.579454, postalCode: "80112" },
    { lng: -102.88325, lat: 38.136132, postalCode: "81057" },
    { lng: -105.76738, lat: 38.374462, postalCode: "81222" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81002" },
    { lng: -105.01966, lat: 39.734686, postalCode: "80204" },
    { lng: -104.6555, lat: 38.758252, postalCode: "80925" },
    { lng: -104.613617, lat: 37.887346, postalCode: "81019" },
    { lng: -107.77322, lat: 38.131449, postalCode: "81432" },
    { lng: -106.54811, lat: 38.618553, postalCode: "81239" },
    { lng: -104.99008, lat: 39.746286, postalCode: "80293" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80047" },
    { lng: -105.08726, lat: 40.435645, postalCode: "80538" },
    { lng: -108.02585, lat: 37.713158, postalCode: "81332" },
    { lng: -104.57029, lat: 38.282872, postalCode: "81001" },
    { lng: -108.15728, lat: 37.131282, postalCode: "81326" },
    { lng: -105.11896, lat: 39.843304, postalCode: "80005" },
    { lng: -104.744145, lat: 39.51474, postalCode: "80139" },
    { lng: -106.0065, lat: 39.22106, postalCode: "80440" },
    { lng: -106.57267, lat: 39.871619, postalCode: "80423" },
    { lng: -103.92275, lat: 38.193937, postalCode: "81062" },
    { lng: -105.50875, lat: 39.813744, postalCode: "80422" },
    { lng: -103.48219, lat: 39.386933, postalCode: "80818" },
    { lng: -105.05454, lat: 39.93404, postalCode: "80020" },
    { lng: -103.65736, lat: 40.686028, postalCode: "80754" },
    { lng: -105.372014, lat: 40.529278, postalCode: "80541" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80248" },
    { lng: -102.69005, lat: 40.169249, postalCode: "80759" },
    { lng: -104.985036, lat: 39.737929, postalCode: "80261" },
    { lng: -104.96678, lat: 39.758986, postalCode: "80205" },
    { lng: -104.72289, lat: 39.784036, postalCode: "80019" },
    { lng: -106.375292, lat: 37.615741, postalCode: "81135" },
    { lng: -105.459269, lat: 39.947386, postalCode: "80471" },
    { lng: -103.67101, lat: 38.26144, postalCode: "81076" },
    { lng: -104.98948, lat: 39.749436, postalCode: "80294" },
    { lng: -105.476, lat: 39.460306, postalCode: "80421" },
    { lng: -102.75161, lat: 38.17924, postalCode: "81092" },
    { lng: -106.08966, lat: 37.773899, postalCode: "81125" },
    { lng: -105.81264, lat: 37.779885, postalCode: "81136" },
    { lng: -105.09191, lat: 39.668576, postalCode: "80227" },
    { lng: -104.838361, lat: 39.746736, postalCode: "80045" },
    { lng: -104.84063, lat: 38.828692, postalCode: "80509" },
    { lng: -106.37077, lat: 39.634566, postalCode: "81657" },
    { lng: -108.367, lat: 39.1013, postalCode: "81526" },
    { lng: -105.009739, lat: 39.539556, postalCode: "80129" },
    { lng: -104.75498, lat: 40.407853, postalCode: "80634" },
    { lng: -104.81654, lat: 38.834282, postalCode: "80903" },
    { lng: -105.07393, lat: 39.616114, postalCode: "80123" },
    { lng: -106.18801, lat: 38.720234, postalCode: "81236" },
    { lng: -104.83956, lat: 39.698387, postalCode: "80012" },
    { lng: -102.37913, lat: 38.846982, postalCode: "80810" },
    { lng: -106.22626, lat: 38.120209, postalCode: "81149" },
    { lng: -103.02115, lat: 38.922655, postalCode: "80862" },
    { lng: -104.992842, lat: 39.74739, postalCode: "80292" },
    { lng: -105.28863, lat: 39.92926, postalCode: "80025" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81012" },
    { lng: -105.95502, lat: 40.142434, postalCode: "80446" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80310" },
    { lng: -106.941907, lat: 39.212626, postalCode: "81613" },
    { lng: -102.80439, lat: 38.467553, postalCode: "81036" },
    { lng: -105.569245, lat: 40.628112, postalCode: "80511" },
    { lng: -104.5635, lat: 39.148692, postalCode: "80106" },
    { lng: -105.11576, lat: 40.539432, postalCode: "80526" },
    { lng: -106.32916, lat: 39.096394, postalCode: "81251" },
    { lng: -105.232695, lat: 40.181515, postalCode: "80533" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80932" },
    { lng: -105.1226, lat: 38.387091, postalCode: "81226" },
    { lng: -103.07103, lat: 39.348989, postalCode: "80815" },
    { lng: -104.69575, lat: 39.035216, postalCode: "80908" },
    { lng: -106.602838, lat: 38.590788, postalCode: "81237" },
    { lng: -107.11287, lat: 37.241179, postalCode: "81147" },
    { lng: -105.223945, lat: 39.522014, postalCode: "80006" },
    { lng: -108.83162, lat: 38.027131, postalCode: "81325" },
    { lng: -105.476577, lat: 38.477563, postalCode: "81215" },
    { lng: -107.783276, lat: 40.667669, postalCode: "81626" },
    { lng: -105.31806, lat: 37.730256, postalCode: "81066" },
    { lng: -102.95462, lat: 37.315497, postalCode: "81064" },
    { lng: -104.03445, lat: 38.002861, postalCode: "81039" },
    { lng: -105.20072, lat: 37.777997, postalCode: "81040" },
    { lng: -104.89947, lat: 39.380857, postalCode: "80109" },
    { lng: -104.407918, lat: 39.80797, postalCode: "80037" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81009" },
    { lng: -104.17162, lat: 39.746563, postalCode: "80103" },
    { lng: -104.94931, lat: 39.929566, postalCode: "80241" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80279" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80160" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80936" },
    { lng: -108.398991, lat: 40.377031, postalCode: "81633" },
    { lng: -107.02552, lat: 39.690233, postalCode: "81637" },
    { lng: -104.88338, lat: 39.681687, postalCode: "80231" },
    { lng: -108.76998, lat: 37.590205, postalCode: "81331" },
    { lng: -102.24035, lat: 39.709824, postalCode: "80735" },
    { lng: -104.407918, lat: 39.80797, postalCode: "80040" },
    { lng: -104.70357, lat: 40.378019, postalCode: "80620" },
    { lng: -105.79916, lat: 38.993175, postalCode: "80449" },
    { lng: -108.50424, lat: 37.237814, postalCode: "81330" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80322" },
    { lng: -108.00332, lat: 38.08677, postalCode: "81430" },
    { lng: -107.29953, lat: 37.961233, postalCode: "81235" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80977" },
    { lng: -105.18121, lat: 39.620748, postalCode: "80465" },
    { lng: -105.464832, lat: 40.45553, postalCode: "80523" },
    { lng: -107.73704, lat: 38.819199, postalCode: "81419" },
    { lng: -103.54001, lat: 37.995879, postalCode: "81050" },
    { lng: -102.21858, lat: 40.916929, postalCode: "80737" },
    { lng: -105.26071, lat: 39.631254, postalCode: "80454" },
    { lng: -103.18362, lat: 40.124199, postalCode: "80720" },
    { lng: -106.311697, lat: 39.225758, postalCode: "80429" },
    { lng: -104.31497, lat: 40.500919, postalCode: "80638" },
    { lng: -105.85054, lat: 38.442267, postalCode: "81233" },
    { lng: -104.78439, lat: 39.695269, postalCode: "80017" },
    { lng: -103.38353, lat: 39.724528, postalCode: "80740" },
    { lng: -104.908921, lat: 39.958987, postalCode: "80602" },
    { lng: -103.21574, lat: 40.633845, postalCode: "80751" },
    { lng: -104.500735, lat: 40.536158, postalCode: "80622" },
    { lng: -103.779959, lat: 38.317851, postalCode: "81034" },
    { lng: -104.76387, lat: 38.743871, postalCode: "80913" },
    { lng: -104.82492, lat: 38.791242, postalCode: "80906" },
    { lng: -102.84813, lat: 40.620805, postalCode: "80728" },
    { lng: -108.60355, lat: 37.344985, postalCode: "81321" },
    { lng: -103.26151, lat: 40.53158, postalCode: "80722" },
    { lng: -104.986336, lat: 39.745486, postalCode: "80295" },
    { lng: -107.42271, lat: 37.039131, postalCode: "81121" },
    { lng: -108.969823, lat: 38.371101, postalCode: "81429" },
    { lng: -104.23282, lat: 38.243711, postalCode: "81025" },
    { lng: -108.68079, lat: 38.296229, postalCode: "81422" },
    { lng: -105.881779, lat: 37.476026, postalCode: "81102" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80151" },
    { lng: -104.85394, lat: 40.332926, postalCode: "80543" },
    { lng: -104.305265, lat: 40.975104, postalCode: "80732" },
    { lng: -102.39192, lat: 37.567773, postalCode: "81084" },
    { lng: -104.987535, lat: 39.832432, postalCode: "80296" },
    { lng: -105.106881, lat: 38.358421, postalCode: "81290" },
    { lng: -107.97404, lat: 38.79053, postalCode: "81410" },
    { lng: -104.92766, lat: 39.669237, postalCode: "80222" },
    { lng: -104.7166, lat: 39.689244, postalCode: "80018" },
    { lng: -102.26776, lat: 37.347009, postalCode: "81090" },
    { lng: -106.05178, lat: 37.274108, postalCode: "81140" },
    { lng: -102.21952, lat: 38.119932, postalCode: "81043" },
    { lng: -106.87521, lat: 40.502772, postalCode: "80487" },
    { lng: -102.78889, lat: 38.765737, postalCode: "80825" },
    { lng: -102.21336, lat: 40.064734, postalCode: "80758" },
    { lng: -105.13052, lat: 39.599755, postalCode: "80127" },
    { lng: -104.71063, lat: 37.229888, postalCode: "81024" },
    { lng: -105.00658, lat: 39.599687, postalCode: "80120" },
    { lng: -106.497314, lat: 39.624171, postalCode: "81658" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80208" },
    { lng: -107.093153, lat: 37.270438, postalCode: "81157" },
    { lng: -105.223945, lat: 39.522014, postalCode: "80419" },
    { lng: -104.98563, lat: 39.742486, postalCode: "80264" },
    { lng: -105.56054, lat: 39.737369, postalCode: "80452" },
    { lng: -104.91477, lat: 40.335398, postalCode: "80534" },
    { lng: -104.9039, lat: 39.836586, postalCode: "80022" },
    { lng: -104.431, lat: 39.760573, postalCode: "80102" },
    { lng: -105.569245, lat: 40.628112, postalCode: "80553" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80150" },
    { lng: -103.10362, lat: 39.727493, postalCode: "80801" },
    { lng: -103.76209, lat: 38.253191, postalCode: "81063" },
    { lng: -106.06889, lat: 39.293921, postalCode: "80420" },
    { lng: -105.37293, lat: 37.375698, postalCode: "81133" },
    { lng: -106.94494, lat: 40.218412, postalCode: "80469" },
    { lng: -104.93113, lat: 39.705318, postalCode: "80246" },
    { lng: -104.99699, lat: 39.751586, postalCode: "80202" },
    { lng: -104.85962, lat: 39.385141, postalCode: "80104" },
    { lng: -106.03747, lat: 39.478893, postalCode: "80424" },
    { lng: -106.90681, lat: 40.149432, postalCode: "80483" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80941" },
    { lng: -104.85255, lat: 38.925451, postalCode: "80919" },
    { lng: -106.882774, lat: 39.223416, postalCode: "81612" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80271" },
    { lng: -103.604851, lat: 39.041435, postalCode: "80826" },
    { lng: -107.58799, lat: 38.877695, postalCode: "81428" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80995" },
    { lng: -104.78031, lat: 39.508608, postalCode: "80134" },
    { lng: -104.850544, lat: 40.524987, postalCode: "80546" },
    { lng: -105.84688, lat: 40.23832, postalCode: "80447" },
    { lng: -103.47108, lat: 37.242629, postalCode: "81049" },
    { lng: -103.87807, lat: 40.603869, postalCode: "80742" },
    { lng: -102.58831, lat: 40.593911, postalCode: "80731" },
    { lng: -102.33025, lat: 37.962678, postalCode: "81041" },
    { lng: -103.41815, lat: 40.508131, postalCode: "80741" },
    { lng: -106.6148, lat: 39.620748, postalCode: "81632" },
    { lng: -105.04688, lat: 39.770336, postalCode: "80212" },
    { lng: -105.78359, lat: 39.915508, postalCode: "80482" },
    { lng: -104.32017, lat: 38.977375, postalCode: "80808" },
    { lng: -107.92669, lat: 38.941926, postalCode: "81413" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80250" },
    { lng: -105.01973, lat: 39.767536, postalCode: "80211" },
    { lng: -107.856609, lat: 37.28211, postalCode: "81310" },
    { lng: -105.01123, lat: 39.816536, postalCode: "80221" },
    { lng: -103.86848, lat: 38.107593, postalCode: "81058" },
    { lng: -105.08431, lat: 40.294423, postalCode: "80513" },
    { lng: -106.16205, lat: 39.967717, postalCode: "80468" },
    { lng: -103.24869, lat: 39.317411, postalCode: "80804" },
    { lng: -104.08793, lat: 39.172831, postalCode: "80835" },
    { lng: -106.940201, lat: 38.702309, postalCode: "81231" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80243" },
    { lng: -106.8557, lat: 40.041288, postalCode: "80479" },
    { lng: -105.17365, lat: 40.156035, postalCode: "80503" },
    { lng: -108.54679, lat: 39.106039, postalCode: "81506" },
    { lng: -104.906942, lat: 39.716675, postalCode: "80280" },
    { lng: -104.66972, lat: 38.235865, postalCode: "81005" },
    { lng: -102.472203, lat: 40.610554, postalCode: "80746" },
    { lng: -106.108002, lat: 39.641146, postalCode: "80497" },
    { lng: -106.14664, lat: 38.867111, postalCode: "81211" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81014" },
    { lng: -105.07615, lat: 38.186991, postalCode: "81253" },
    { lng: -104.62943, lat: 40.060539, postalCode: "80642" },
    { lng: -108.00498, lat: 39.139927, postalCode: "81646" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80155" },
    { lng: -108.78271, lat: 37.696224, postalCode: "81320" },
    { lng: -104.91678, lat: 39.734387, postalCode: "80220" },
    { lng: -107.93414, lat: 39.220166, postalCode: "81624" },
    { lng: -102.44357, lat: 39.302035, postalCode: "80805" },
    { lng: -104.70145, lat: 38.897344, postalCode: "80922" },
    { lng: -104.70172, lat: 40.399281, postalCode: "80639" },
    { lng: -109.01087, lat: 38.715101, postalCode: "81522" },
    { lng: -104.90493, lat: 39.113371, postalCode: "80133" },
    { lng: -105.19337, lat: 39.735745, postalCode: "80401" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80265" },
    { lng: -104.800547, lat: 40.405094, postalCode: "80630" },
    { lng: -105.64876, lat: 40.700258, postalCode: "80512" },
    { lng: -104.30997, lat: 39.776934, postalCode: "80136" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80254" },
    { lng: -107.66873, lat: 39.508481, postalCode: "81652" },
    { lng: -108.85126, lat: 38.958934, postalCode: "81523" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80299" },
    { lng: -102.61855, lat: 38.08759, postalCode: "81052" },
    { lng: -104.88339, lat: 39.885202, postalCode: "80640" },
    { lng: -105.642451, lat: 38.285533, postalCode: "81232" },
    { lng: -107.1882, lat: 39.335504, postalCode: "81623" },
    { lng: -108.125194, lat: 37.092605, postalCode: "81329" },
    { lng: -104.80905, lat: 39.739737, postalCode: "80011" },
    { lng: -106.75491, lat: 37.086533, postalCode: "81128" },
    { lng: -103.60936, lat: 40.382063, postalCode: "80750" },
    { lng: -103.85543, lat: 38.193627, postalCode: "81033" },
    { lng: -104.82407, lat: 39.973251, postalCode: "80601" },
    { lng: -104.8684, lat: 39.098692, postalCode: "80132" },
    { lng: -104.70892, lat: 38.858644, postalCode: "80915" },
    { lng: -108.43921, lat: 38.153484, postalCode: "81431" },
    { lng: -105.23659, lat: 39.085706, postalCode: "80866" },
    { lng: -103.37824, lat: 38.414313, postalCode: "81021" },
    { lng: -105.72873, lat: 39.459109, postalCode: "80448" },
    { lng: -104.71181, lat: 40.529241, postalCode: "80615" },
    { lng: -105.252586, lat: 39.979691, postalCode: "80305" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80942" },
    { lng: -102.52694, lat: 39.650653, postalCode: "80824" },
    { lng: -107.54974, lat: 39.567059, postalCode: "81647" },
    { lng: -104.83421, lat: 39.665637, postalCode: "80014" },
    { lng: -104.42847, lat: 39.382543, postalCode: "80117" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80946" },
    { lng: -103.11162, lat: 40.964518, postalCode: "80747" },
    { lng: -104.93576, lat: 40.104211, postalCode: "80530" },
    { lng: -105.223945, lat: 39.522014, postalCode: "80162" },
    { lng: -104.70542, lat: 40.48243, postalCode: "80646" },
    { lng: -105.83438, lat: 39.949217, postalCode: "80442" },
    { lng: -107.89755, lat: 39.603225, postalCode: "81650" },
    { lng: -105.73155, lat: 39.695974, postalCode: "80476" },
    { lng: -105.87705, lat: 40.932966, postalCode: "82063" },
    { lng: -103.072469, lat: 37.955547, postalCode: "81038" },
    { lng: -107.653131, lat: 37.222311, postalCode: "81303" },
    { lng: -105.1497, lat: 38.75604, postalCode: "80813" },
    { lng: -102.48265, lat: 40.086896, postalCode: "80727" },
    { lng: -105.97937, lat: 39.607479, postalCode: "80435" },
    { lng: -108.44444, lat: 39.085981, postalCode: "81520" },
    { lng: -104.87448, lat: 40.886476, postalCode: "80612" },
    { lng: -104.73913, lat: 39.793686, postalCode: "80249" },
    { lng: -108.49019, lat: 39.085481, postalCode: "81504" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80323" },
    { lng: -107.4166, lat: 38.321598, postalCode: "81220" },
    { lng: -103.60824, lat: 40.23045, postalCode: "80723" },
    { lng: -104.01594, lat: 39.631741, postalCode: "80105" },
    { lng: -108.60506, lat: 39.112514, postalCode: "81505" },
    { lng: -106.52037, lat: 38.607617, postalCode: "81241" },
    { lng: -106.63177, lat: 37.671046, postalCode: "81154" },
    { lng: -105.02943, lat: 37.499829, postalCode: "81055" },
    { lng: -104.90406, lat: 39.643637, postalCode: "80237" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80028" },
    { lng: -104.68704, lat: 40.332131, postalCode: "80645" },
    { lng: -104.76701, lat: 38.952366, postalCode: "80920" },
    { lng: -105.244245, lat: 39.668426, postalCode: "80453" },
    { lng: -104.856808, lat: 39.726303, postalCode: "80201" },
    { lng: -107.089239, lat: 39.39787, postalCode: "81628" },
    { lng: -104.86481, lat: 38.850558, postalCode: "80904" },
    { lng: -105.373507, lat: 40.087835, postalCode: "80502" },
    { lng: -104.6331, lat: 38.197344, postalCode: "81004" },
    { lng: -104.762899, lat: 40.376626, postalCode: "80632" },
    { lng: -102.24844, lat: 38.44672, postalCode: "81071" },
    { lng: -104.14826, lat: 37.096255, postalCode: "81081" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80945" },
    { lng: -105.91579, lat: 38.142453, postalCode: "81143" },
    { lng: -102.88722, lat: 39.648325, postalCode: "80812" },
    { lng: -106.37859, lat: 39.459323, postalCode: "81645" },
    { lng: -104.65178, lat: 38.831149, postalCode: "80929" },
    { lng: -108.11571, lat: 39.493283, postalCode: "81635" },
    { lng: -104.51964, lat: 40.363777, postalCode: "80644" },
    { lng: -105.30711, lat: 38.876897, postalCode: "80816" },
    { lng: -102.59745, lat: 39.29782, postalCode: "80836" },
    { lng: -104.83632, lat: 38.837542, postalCode: "80905" },
    { lng: -108.65188, lat: 37.534825, postalCode: "81327" },
    { lng: -104.93581, lat: 40.083475, postalCode: "80514" },
    { lng: -107.35526, lat: 38.994185, postalCode: "81434" },
    { lng: -104.15685, lat: 40.310272, postalCode: "80649" },
    { lng: -104.93637, lat: 40.115501, postalCode: "80520" },
    { lng: -103.62827, lat: 38.015189, postalCode: "81077" },
    { lng: -102.25094, lat: 39.3124, postalCode: "80807" },
    { lng: -106.13744, lat: 39.722417, postalCode: "80498" },
    { lng: -105.7477, lat: 37.23354, postalCode: "81151" },
    { lng: -107.62223, lat: 37.868294, postalCode: "81433" },
    { lng: -104.987577, lat: 39.74394, postalCode: "80281" },
    { lng: -104.84267, lat: 40.228533, postalCode: "80651" },
    { lng: -106.42854, lat: 40.14711, postalCode: "80459" },
    { lng: -104.84248, lat: 39.008109, postalCode: "80840" },
    { lng: -104.98083, lat: 40.528966, postalCode: "80547" },
    { lng: -107.60818, lat: 40.566113, postalCode: "81625" },
    { lng: -104.987577, lat: 39.74394, postalCode: "80291" },
    { lng: -105.27938, lat: 40.039784, postalCode: "80304" },
    { lng: -103.72231, lat: 38.042083, postalCode: "81067" },
    { lng: -102.94569, lat: 38.039941, postalCode: "81044" },
    { lng: -103.04649, lat: 40.798956, postalCode: "80736" },
    { lng: -105.10349, lat: 40.383636, postalCode: "80537" },
    { lng: -104.562027, lat: 38.82469, postalCode: "80949" },
    { lng: -108.55, lat: 39.072831, postalCode: "81501" },
    { lng: -104.552299, lat: 38.128626, postalCode: "81015" },
    { lng: -103.61156, lat: 39.836887, postalCode: "80757" },
    { lng: -103.80625, lat: 40.226357, postalCode: "80701" },
    { lng: -103.18631, lat: 38.037435, postalCode: "81054" },
    { lng: -106.97888, lat: 39.254607, postalCode: "81654" },
    { lng: -104.97133, lat: 39.731237, postalCode: "80218" },
    { lng: -106.62705, lat: 39.731182, postalCode: "81655" },
    { lng: -106.12085, lat: 37.291966, postalCode: "81124" },
    { lng: -106.14946, lat: 37.569501, postalCode: "81144" },
    { lng: -105.07882, lat: 39.576838, postalCode: "80128" },
    { lng: -105.36812, lat: 39.642572, postalCode: "80439" },
    { lng: -104.407918, lat: 39.80797, postalCode: "80042" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80041" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80166" },
    { lng: -105.31198, lat: 39.514738, postalCode: "80433" },
    { lng: -106.81878, lat: 39.30179, postalCode: "81656" },
    { lng: -104.408349, lat: 39.738752, postalCode: "80275" },
    { lng: -105.0981, lat: 39.795006, postalCode: "80002" },
    { lng: -105.27973, lat: 38.464212, postalCode: "81212" },
    { lng: -104.92152, lat: 39.541571, postalCode: "80130" },
    { lng: -104.98731, lat: 38.911701, postalCode: "80809" },
    { lng: -105.530023, lat: 37.092195, postalCode: "81134" },
    { lng: -103.84302, lat: 37.095478, postalCode: "81027" },
    { lng: -104.60923, lat: 38.953003, postalCode: "80931" },
    { lng: -106.243422, lat: 38.545757, postalCode: "81227" },
    { lng: -104.857828, lat: 38.861469, postalCode: "80901" },
    { lng: -105.73101, lat: 37.620038, postalCode: "81146" },
    { lng: -105.21928, lat: 40.044385, postalCode: "80301" },
    { lng: -106.96755, lat: 38.901649, postalCode: "81225" },
    { lng: -116.90117, lat: 43.653878, postalCode: "83676" },
    { lng: -116.62405, lat: 44.426905, postalCode: "83645" },
    { lng: -113.79857, lat: 42.496588, postalCode: "83318" },
    { lng: -116.5143, lat: 43.888285, postalCode: "83617" },
    { lng: -111.32682, lat: 44.496112, postalCode: "83433" },
    { lng: -114.9251, lat: 42.811892, postalCode: "83332" },
    { lng: -116.81389, lat: 43.96929, postalCode: "83655" },
    { lng: -116.16367, lat: 43.595763, postalCode: "83712" },
    { lng: -114.25865, lat: 43.39918, postalCode: "83313" },
    { lng: -116.73825, lat: 43.675651, postalCode: "83607" },
    { lng: -116.91129, lat: 44.002031, postalCode: "83619" },
    { lng: -116.31264, lat: 44.017756, postalCode: "83670" },
    { lng: -116.39642, lat: 43.609434, postalCode: "83642" },
    { lng: -114.12157, lat: 42.444159, postalCode: "83344" },
    { lng: -112.54642, lat: 43.964437, postalCode: "83435" },
    { lng: -116.272921, lat: 43.603768, postalCode: "83701" },
    { lng: -113.816735, lat: 44.968059, postalCode: "83229" },
    { lng: -111.217381, lat: 42.177359, postalCode: "83233" },
    { lng: -115.997118, lat: 43.38469, postalCode: "83707" },
    { lng: -115.62345, lat: 43.208029, postalCode: "83647" },
    { lng: -116.1889, lat: 43.933688, postalCode: "83629" },
    { lng: -111.42742, lat: 42.021829, postalCode: "83287" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83756" },
    { lng: -116.620774, lat: 43.70713, postalCode: "83652" },
    { lng: -114.299, lat: 42.482046, postalCode: "83334" },
    { lng: -112.442253, lat: 43.222648, postalCode: "83256" },
    { lng: -112.84484, lat: 42.542266, postalCode: "83271" },
    { lng: -115.02366, lat: 42.096094, postalCode: "83302" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83730" },
    { lng: -113.67277, lat: 44.817505, postalCode: "83465" },
    { lng: -112.01477, lat: 43.694827, postalCode: "83431" },
    { lng: -111.33201, lat: 43.450364, postalCode: "83449" },
    { lng: -116.05992, lat: 44.699961, postalCode: "83615" },
    { lng: -116.49155, lat: 43.697658, postalCode: "83669" },
    { lng: -114.5981, lat: 44.20719, postalCode: "83227" },
    { lng: -111.70208, lat: 43.957025, postalCode: "83445" },
    { lng: -116.29949, lat: 44.279564, postalCode: "83657" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83744" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83680" },
    { lng: -114.31565, lat: 45.234391, postalCode: "83469" },
    { lng: -116.28116, lat: 43.606453, postalCode: "83788" },
    { lng: -114.61919, lat: 42.565542, postalCode: "83328" },
    { lng: -113.9625, lat: 45.593774, postalCode: "83463" },
    { lng: -113.80293, lat: 44.486997, postalCode: "83253" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83723" },
    { lng: -116.34335, lat: 45.077791, postalCode: "83654" },
    { lng: -113.64135, lat: 42.08685, postalCode: "83312" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83708" },
    { lng: -111.22042, lat: 43.027278, postalCode: "83285" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83799" },
    { lng: -111.691047, lat: 43.776095, postalCode: "83460" },
    { lng: -116.97103, lat: 43.909576, postalCode: "97913" },
    { lng: -116.20756, lat: 43.627734, postalCode: "83702" },
    { lng: -114.71025, lat: 42.76079, postalCode: "83355" },
    { lng: -111.596651, lat: 42.719102, postalCode: "83230" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83721" },
    { lng: -113.90306, lat: 45.101697, postalCode: "83467" },
    { lng: -113.45086, lat: 43.774709, postalCode: "83255" },
    { lng: -111.35333, lat: 42.483479, postalCode: "83239" },
    { lng: -115.13085, lat: 43.801588, postalCode: "83601" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83729" },
    { lng: -115.87903, lat: 43.85128, postalCode: "83631" },
    { lng: -112.54626, lat: 42.514471, postalCode: "83212" },
    { lng: -111.64522, lat: 43.890719, postalCode: "83451" },
    { lng: -111.39651, lat: 42.22771, postalCode: "83261" },
    { lng: -114.519536, lat: 42.456273, postalCode: "83303" },
    { lng: -112.07391, lat: 43.497685, postalCode: "83402" },
    { lng: -114.25578, lat: 42.59465, postalCode: "83325" },
    { lng: -113.7864, lat: 42.562839, postalCode: "83336" },
    { lng: -116.93469, lat: 43.790319, postalCode: "83660" },
    { lng: -112.01889, lat: 42.616477, postalCode: "83246" },
    { lng: -114.93551, lat: 44.248203, postalCode: "83278" },
    { lng: -111.14842, lat: 43.636332, postalCode: "83455" },
    { lng: -111.09002, lat: 42.324873, postalCode: "83238" },
    { lng: -111.98136, lat: 43.528891, postalCode: "83401" },
    { lng: -112.81316, lat: 43.442618, postalCode: "83215" },
    { lng: -111.691047, lat: 43.776095, postalCode: "83441" },
    { lng: -111.55527, lat: 44.004709, postalCode: "83421" },
    { lng: -112.50329, lat: 42.847727, postalCode: "83204" },
    { lng: -114.90993, lat: 42.371508, postalCode: "83321" },
    { lng: -113.89078, lat: 43.356224, postalCode: "83320" },
    { lng: -116.48879, lat: 44.605095, postalCode: "83643" },
    { lng: -115.48797, lat: 44.08857, postalCode: "83637" },
    { lng: -114.70605, lat: 42.944346, postalCode: "83330" },
    { lng: -116.93326, lat: 44.310781, postalCode: "83672" },
    { lng: -112.43182, lat: 42.96866, postalCode: "83202" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83715" },
    { lng: -112.91931, lat: 42.75159, postalCode: "83211" },
    { lng: -116.43395, lat: 44.878097, postalCode: "83620" },
    { lng: -116.58697, lat: 43.583454, postalCode: "83651" },
    { lng: -111.37641, lat: 42.339083, postalCode: "83220" },
    { lng: -111.67297, lat: 42.344123, postalCode: "83283" },
    { lng: -111.3922, lat: 42.127915, postalCode: "83272" },
    { lng: -116.8008, lat: 43.725546, postalCode: "83656" },
    { lng: -116.19903, lat: 43.593523, postalCode: "83706" },
    { lng: -113.8869, lat: 42.261085, postalCode: "83346" },
    { lng: -116.36346, lat: 43.705551, postalCode: "83616" },
    { lng: -111.21512, lat: 43.882935, postalCode: "83424" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83732" },
    { lng: -116.33059, lat: 43.638314, postalCode: "83713" },
    { lng: -115.17427, lat: 42.969692, postalCode: "83633" },
    { lng: -116.55344, lat: 44.829994, postalCode: "83612" },
    { lng: -111.782152, lat: 43.323306, postalCode: "83405" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83735" },
    { lng: -115.453583, lat: 44.68367, postalCode: "83635" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83717" },
    { lng: -113.29547, lat: 44.525291, postalCode: "83464" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83757" },
    { lng: -114.05941, lat: 42.599276, postalCode: "83335" },
    { lng: -116.46584, lat: 43.135267, postalCode: "83650" },
    { lng: -114.77559, lat: 43.365909, postalCode: "83327" },
    { lng: -116.2252, lat: 43.583139, postalCode: "83705" },
    { lng: -112.20206, lat: 44.397984, postalCode: "83446" },
    { lng: -114.96964, lat: 43.377947, postalCode: "83322" },
    { lng: -116.26507, lat: 43.641774, postalCode: "83714" },
    { lng: -112.59511, lat: 43.115708, postalCode: "83262" },
    { lng: -111.40853, lat: 42.188641, postalCode: "83223" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83719" },
    { lng: -111.83881, lat: 43.403744, postalCode: "83427" },
    { lng: -112.68229, lat: 43.074319, postalCode: "83277" },
    { lng: -111.957274, lat: 43.593567, postalCode: "83454" },
    { lng: -116.25707, lat: 43.668396, postalCode: "83703" },
    { lng: -111.7509, lat: 42.525021, postalCode: "83241" },
    { lng: -112.42474, lat: 43.857435, postalCode: "83450" },
    { lng: -115.85201, lat: 43.06997, postalCode: "83648" },
    { lng: -116.28716, lat: 43.63123, postalCode: "83704" },
    { lng: -112.410283, lat: 42.80616, postalCode: "83205" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83728" },
    { lng: -112.39454, lat: 43.211238, postalCode: "83221" },
    { lng: -115.04088, lat: 43.312973, postalCode: "83337" },
    { lng: -116.9484, lat: 43.598319, postalCode: "83628" },
    { lng: -114.31153, lat: 43.527659, postalCode: "83333" },
    { lng: -114.46394, lat: 42.710124, postalCode: "83338" },
    { lng: -116.646209, lat: 43.896275, postalCode: "83636" },
    { lng: -112.313836, lat: 42.639474, postalCode: "83209" },
    { lng: -111.32637, lat: 42.327996, postalCode: "83254" },
    { lng: -115.9354, lat: 44.103121, postalCode: "83622" },
    { lng: -112.21484, lat: 42.659404, postalCode: "83250" },
    { lng: -115.55397, lat: 45.01781, postalCode: "83677" },
    { lng: -116.752963, lat: 43.585149, postalCode: "83653" },
    { lng: -114.18191, lat: 43.661373, postalCode: "83353" },
    { lng: -116.82367, lat: 43.670142, postalCode: "83626" },
    { lng: -111.98144, lat: 42.055593, postalCode: "83286" },
    { lng: -114.07866, lat: 45.486818, postalCode: "83466" },
    { lng: -111.82369, lat: 42.165399, postalCode: "83263" },
    { lng: -113.31639, lat: 43.65183, postalCode: "83213" },
    { lng: -116.39311, lat: 43.463644, postalCode: "83634" },
    { lng: -114.06444, lat: 43.304395, postalCode: "83348" },
    { lng: -115.88714, lat: 42.87616, postalCode: "83604" },
    { lng: -114.47509, lat: 42.530288, postalCode: "83301" },
    { lng: -111.11484, lat: 43.726688, postalCode: "83422" },
    { lng: -114.40997, lat: 43.036666, postalCode: "83352" },
    { lng: -116.13976, lat: 44.066434, postalCode: "83602" },
    { lng: -114.81886, lat: 42.596953, postalCode: "83316" },
    { lng: -111.333376, lat: 43.421132, postalCode: "83403" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83726" },
    { lng: -116.57761, lat: 43.529812, postalCode: "83686" },
    { lng: -114.37441, lat: 42.509557, postalCode: "83341" },
    { lng: -116.07225, lat: 43.004691, postalCode: "83624" },
    { lng: -116.04991, lat: 44.854428, postalCode: "83638" },
    { lng: -111.99508, lat: 43.759605, postalCode: "83434" },
    { lng: -111.97605, lat: 42.129892, postalCode: "83232" },
    { lng: -112.01425, lat: 43.465998, postalCode: "83404" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83731" },
    { lng: -111.36631, lat: 44.490674, postalCode: "83429" },
    { lng: -116.798923, lat: 43.724917, postalCode: "83606" },
    { lng: -111.60171, lat: 44.319199, postalCode: "83438" },
    { lng: -116.84062, lat: 43.534319, postalCode: "83639" },
    { lng: -116.29527, lat: 43.572671, postalCode: "83709" },
    { lng: -111.80942, lat: 43.80991, postalCode: "83440" },
    { lng: -111.60171, lat: 44.319199, postalCode: "83447" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83720" },
    { lng: -112.11647, lat: 42.43627, postalCode: "83234" },
    { lng: -111.87407, lat: 43.67511, postalCode: "83442" },
    { lng: -113.68966, lat: 44.926709, postalCode: "83468" },
    { lng: -111.70879, lat: 43.860023, postalCode: "83448" },
    { lng: -111.782152, lat: 43.323306, postalCode: "83415" },
    { lng: -112.64211, lat: 42.217418, postalCode: "83243" },
    { lng: -116.35695, lat: 45.291282, postalCode: "83547" },
    { lng: -111.95925, lat: 43.458004, postalCode: "83406" },
    { lng: -116.4388, lat: 44.554347, postalCode: "83632" },
    { lng: -116.67755, lat: 43.658834, postalCode: "83605" },
    { lng: -111.49617, lat: 43.879722, postalCode: "83436" },
    { lng: -113.35296, lat: 42.232525, postalCode: "83342" },
    { lng: -116.53781, lat: 43.590212, postalCode: "83687" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83711" },
    { lng: -112.16356, lat: 43.317823, postalCode: "83218" },
    { lng: -115.32724, lat: 42.965519, postalCode: "83623" },
    { lng: -116.36431, lat: 45.405792, postalCode: "83549" },
    { lng: -112.11712, lat: 43.366017, postalCode: "83274" },
    { lng: -115.69194, lat: 45.273532, postalCode: "83671" },
    { lng: -112.23971, lat: 42.539952, postalCode: "83214" },
    { lng: -116.02721, lat: 43.601772, postalCode: "83716" },
    { lng: -114.95287, lat: 42.937947, postalCode: "83314" },
    { lng: -112.48638, lat: 43.040466, postalCode: "83203" },
    { lng: -113.04362, lat: 43.854175, postalCode: "83244" },
    { lng: -114.33214, lat: 43.681156, postalCode: "83354" },
    { lng: -111.0932, lat: 43.001889, postalCode: "83120" },
    { lng: -111.16768, lat: 43.81958, postalCode: "83452" },
    { lng: -114.5198, lat: 43.750744, postalCode: "83340" },
    { lng: -112.43968, lat: 42.883214, postalCode: "83201" },
    { lng: -115.9729, lat: 44.476882, postalCode: "83611" },
    { lng: -112.32902, lat: 42.192579, postalCode: "83252" },
    { lng: -111.40548, lat: 44.060947, postalCode: "83420" },
    { lng: -112.313836, lat: 42.639474, postalCode: "83206" },
    { lng: -114.2441, lat: 42.883302, postalCode: "83324" },
    { lng: -116.60253, lat: 43.720076, postalCode: "83644" },
    { lng: -112.83218, lat: 42.97228, postalCode: "83210" },
    { lng: -113.8584, lat: 45.338175, postalCode: "83462" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83722" },
    { lng: -116.89433, lat: 44.079947, postalCode: "83661" },
    { lng: -111.23417, lat: 43.369671, postalCode: "83428" },
    { lng: -112.15002, lat: 43.722695, postalCode: "83444" },
    { lng: -115.98055, lat: 43.948003, postalCode: "83666" },
    { lng: -113.41808, lat: 42.510364, postalCode: "83323" },
    { lng: -112.34258, lat: 44.164718, postalCode: "83423" },
    { lng: -111.59911, lat: 43.570104, postalCode: "83443" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83727" },
    { lng: -116.8863, lat: 42.749306, postalCode: "97910" },
    { lng: -111.79977, lat: 42.021688, postalCode: "83237" },
    { lng: -116.195159, lat: 43.619477, postalCode: "83724" },
    { lng: -116.790284, lat: 43.602661, postalCode: "83630" },
    { lng: -112.12765, lat: 43.287888, postalCode: "83236" },
    { lng: -116.57555, lat: 43.370358, postalCode: "83641" },
    { lng: -113.99217, lat: 44.633935, postalCode: "83235" },
    { lng: -112.23219, lat: 43.951754, postalCode: "83425" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83725" },
    { lng: -111.87207, lat: 42.768812, postalCode: "83217" },
    { lng: -116.69, lat: 44.647796, postalCode: "83610" },
    { lng: -114.18795, lat: 43.078896, postalCode: "83349" },
    { lng: -112.01429, lat: 42.213131, postalCode: "83228" },
    { lng: -114.18803, lat: 44.53742, postalCode: "83226" },
    { lng: -113.74907, lat: 43.969772, postalCode: "83251" },
    { lng: -113.59744, lat: 42.390977, postalCode: "83311" },
    { lng: -116.243984, lat: 43.459855, postalCode: "83733" },
    { lng: -113.509762, lat: 42.772955, postalCode: "83343" },
    { lng: -113.86281, lat: 42.71554, postalCode: "83347" },
    { lng: -113.62976, lat: 42.671054, postalCode: "83350" },
    { lng: -111.96206, lat: 42.305798, postalCode: "83281" },
    { lng: -112.23562, lat: 42.79471, postalCode: "83245" },
    { lng: -111.57731, lat: 42.693133, postalCode: "83276" },
    { lng: -115.49697, lat: 42.939818, postalCode: "83627" },
    { lng: -101.80781, lat: 38.022874, postalCode: "67878" },
    { lng: -101.75422, lat: 39.332837, postalCode: "67735" },
    { lng: -101.50937, lat: 38.912898, postalCode: "67761" },
    { lng: -101.7871, lat: 38.478369, postalCode: "67879" },
    { lng: -102.00483, lat: 39.358034, postalCode: "67741" },
    { lng: -101.99084, lat: 38.930464, postalCode: "67762" },
    { lng: -101.73575, lat: 38.865428, postalCode: "67758" },
    { lng: -102.00832, lat: 38.043992, postalCode: "67836" },
    { lng: -101.51227, lat: 39.356075, postalCode: "67733" },
    { lng: -113.64027, lat: 46.900409, postalCode: "59823" },
    { lng: -114.74914, lat: 48.68464, postalCode: "59933" },
    { lng: -111.56253, lat: 46.186442, postalCode: "59643" },
    { lng: -114.23052, lat: 48.438331, postalCode: "59912" },
    { lng: -109.54126, lat: 45.708186, postalCode: "59069" },
    { lng: -111.82545, lat: 47.547517, postalCode: "59443" },
    { lng: -105.630461, lat: 45.391078, postalCode: "59345" },
    { lng: -104.56769, lat: 48.77466, postalCode: "59254" },
    { lng: -113.42635, lat: 47.015705, postalCode: "59836" },
    { lng: -111.77961, lat: 47.895071, postalCode: "59433" },
    { lng: -108.29474, lat: 45.862261, postalCode: "59037" },
    { lng: -114.256123, lat: 48.240382, postalCode: "59904" },
    { lng: -114.37322, lat: 47.923911, postalCode: "59929" },
    { lng: -105.16175, lat: 48.136011, postalCode: "59255" },
    { lng: -114.44619, lat: 47.107612, postalCode: "59846" },
    { lng: -114.39536, lat: 47.829886, postalCode: "59915" },
    { lng: -110.477384, lat: 45.33333, postalCode: "59082" },
    { lng: -112.04193, lat: 46.588803, postalCode: "59601" },
    { lng: -108.5742, lat: 45.778852, postalCode: "59102" },
    { lng: -112.637705, lat: 45.905345, postalCode: "59707" },
    { lng: -108.3527, lat: 46.001031, postalCode: "59079" },
    { lng: -113.09214, lat: 47.044698, postalCode: "59854" },
    { lng: -106.609665, lat: 48.330241, postalCode: "59273" },
    { lng: -106.46104, lat: 48.006744, postalCode: "59223" },
    { lng: -114.049824, lat: 47.116034, postalCode: "59806" },
    { lng: -104.35503, lat: 48.695994, postalCode: "59211" },
    { lng: -112.77156, lat: 46.025762, postalCode: "59748" },
    { lng: -108.95391, lat: 45.846359, postalCode: "59057" },
    { lng: -106.99199, lat: 46.017965, postalCode: "59312" },
    { lng: -104.25492, lat: 48.519946, postalCode: "59219" },
    { lng: -108.281168, lat: 47.173641, postalCode: "59084" },
    { lng: -107.6318, lat: 46.095217, postalCode: "59024" },
    { lng: -115.23463, lat: 47.710209, postalCode: "59873" },
    { lng: -114.22296, lat: 46.336533, postalCode: "59841" },
    { lng: -114.07478, lat: 45.905748, postalCode: "59827" },
    { lng: -108.53775, lat: 46.53932, postalCode: "59072" },
    { lng: -108.73813, lat: 48.843446, postalCode: "59529" },
    { lng: -107.87318, lat: 48.817553, postalCode: "59537" },
    { lng: -111.239305, lat: 47.619998, postalCode: "59406" },
    { lng: -109.28416, lat: 48.031253, postalCode: "59535" },
    { lng: -111.92471, lat: 48.979905, postalCode: "59484" },
    { lng: -110.06828, lat: 48.141017, postalCode: "59520" },
    { lng: -111.19888, lat: 45.417898, postalCode: "59730" },
    { lng: -108.37109, lat: 48.148715, postalCode: "59524" },
    { lng: -113.20424, lat: 48.424793, postalCode: "59434" },
    { lng: -109.32217, lat: 45.241761, postalCode: "59068" },
    { lng: -109.86994, lat: 46.435446, postalCode: "59036" },
    { lng: -111.70821, lat: 47.223693, postalCode: "59421" },
    { lng: -111.735925, lat: 45.349274, postalCode: "59795" },
    { lng: -110.98801, lat: 47.834943, postalCode: "59420" },
    { lng: -111.190888, lat: 45.652577, postalCode: "59817" },
    { lng: -104.19036, lat: 48.1154, postalCode: "59212" },
    { lng: -114.80804, lat: 48.080862, postalCode: "59925" },
    { lng: -110.02055, lat: 47.383325, postalCode: "59424" },
    { lng: -104.48487, lat: 48.503927, postalCode: "59247" },
    { lng: -111.01063, lat: 46.615309, postalCode: "59645" },
    { lng: -113.958686, lat: 46.910342, postalCode: "59807" },
    { lng: -114.10566, lat: 46.854974, postalCode: "59804" },
    { lng: -109.68953, lat: 48.555536, postalCode: "59500" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59112" },
    { lng: -107.40301, lat: 48.544159, postalCode: "59261" },
    { lng: -113.62908, lat: 46.716741, postalCode: "59825" },
    { lng: -107.42566, lat: 45.521423, postalCode: "59031" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59108" },
    { lng: -111.123775, lat: 45.72465, postalCode: "59771" },
    { lng: -106.33547, lat: 48.13336, postalCode: "59248" },
    { lng: -112.7369, lat: 46.392658, postalCode: "59722" },
    { lng: -108.73073, lat: 45.242325, postalCode: "59014" },
    { lng: -106.609665, lat: 48.330241, postalCode: "59240" },
    { lng: -107.93409, lat: 45.395656, postalCode: "59075" },
    { lng: -112.330214, lat: 47.184233, postalCode: "59626" },
    { lng: -110.09673, lat: 46.883052, postalCode: "59452" },
    { lng: -112.97934, lat: 46.878117, postalCode: "59843" },
    { lng: -109.9578, lat: 48.321712, postalCode: "59521" },
    { lng: -113.29281, lat: 46.522136, postalCode: "59837" },
    { lng: -110.64869, lat: 47.83055, postalCode: "59442" },
    { lng: -112.81115, lat: 48.309341, postalCode: "59448" },
    { lng: -104.19232, lat: 46.969132, postalCode: "59353" },
    { lng: -112.51279, lat: 45.996957, postalCode: "59701" },
    { lng: -114.04819, lat: 46.313969, postalCode: "59828" },
    { lng: -104.94413, lat: 48.280119, postalCode: "59245" },
    { lng: -110.32495, lat: 48.733088, postalCode: "59525" },
    { lng: -113.0698, lat: 48.556986, postalCode: "59417" },
    { lng: -107.44215, lat: 47.426998, postalCode: "59318" },
    { lng: -110.57208, lat: 47.530558, postalCode: "59450" },
    { lng: -106.33328, lat: 48.599534, postalCode: "59244" },
    { lng: -111.96711, lat: 47.459112, postalCode: "59477" },
    { lng: -107.80028, lat: 47.05992, postalCode: "59058" },
    { lng: -114.101443, lat: 47.594957, postalCode: "59863" },
    { lng: -111.91516, lat: 48.881178, postalCode: "59482" },
    { lng: -112.59075, lat: 46.650216, postalCode: "59713" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59114" },
    { lng: -110.44229, lat: 47.268612, postalCode: "59447" },
    { lng: -111.13517, lat: 47.409591, postalCode: "59472" },
    { lng: -111.34499, lat: 47.514307, postalCode: "59404" },
    { lng: -108.49163, lat: 45.353002, postalCode: "59066" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59116" },
    { lng: -111.96872, lat: 48.190331, postalCode: "59425" },
    { lng: -106.68186, lat: 46.077231, postalCode: "59327" },
    { lng: -111.66825, lat: 48.037351, postalCode: "59416" },
    { lng: -110.47512, lat: 48.008655, postalCode: "59460" },
    { lng: -111.86233, lat: 45.849728, postalCode: "59721" },
    { lng: -105.62183, lat: 46.579645, postalCode: "59338" },
    { lng: -114.049824, lat: 47.116034, postalCode: "59812" },
    { lng: -109.35624, lat: 47.468715, postalCode: "59451" },
    { lng: -106.65505, lat: 45.915952, postalCode: "59323" },
    { lng: -106.99199, lat: 46.017965, postalCode: "59004" },
    { lng: -114.14786, lat: 46.214883, postalCode: "59840" },
    { lng: -109.03637, lat: 46.418078, postalCode: "59046" },
    { lng: -106.21374, lat: 46.271872, postalCode: "59333" },
    { lng: -112.48018, lat: 47.503261, postalCode: "59410" },
    { lng: -104.13403, lat: 47.900376, postalCode: "59221" },
    { lng: -104.25299, lat: 46.357253, postalCode: "59313" },
    { lng: -112.330214, lat: 47.184233, postalCode: "59620" },
    { lng: -110.75099, lat: 45.336151, postalCode: "59065" },
    { lng: -111.27408, lat: 47.509157, postalCode: "59401" },
    { lng: -111.30771, lat: 48.281911, postalCode: "59456" },
    { lng: -110.1846, lat: 47.661952, postalCode: "59446" },
    { lng: -108.395473, lat: 46.444224, postalCode: "59073" },
    { lng: -110.26076, lat: 47.104848, postalCode: "59479" },
    { lng: -112.637705, lat: 45.905345, postalCode: "59703" },
    { lng: -113.22547, lat: 46.695594, postalCode: "59832" },
    { lng: -115.27461, lat: 48.875701, postalCode: "59930" },
    { lng: -111.26909, lat: 47.476152, postalCode: "59405" },
    { lng: -114.07435, lat: 46.642043, postalCode: "59833" },
    { lng: -108.75601, lat: 45.665424, postalCode: "59044" },
    { lng: -109.98238, lat: 45.006307, postalCode: "59081" },
    { lng: -113.88505, lat: 48.605472, postalCode: "59921" },
    { lng: -113.98182, lat: 48.36435, postalCode: "59926" },
    { lng: -112.637705, lat: 45.905345, postalCode: "59702" },
    { lng: -110.9878, lat: 45.707153, postalCode: "59715" },
    { lng: -104.47482, lat: 45.797477, postalCode: "59324" },
    { lng: -108.84397, lat: 47.377169, postalCode: "59471" },
    { lng: -105.19476, lat: 47.253136, postalCode: "59339" },
    { lng: -108.0903, lat: 46.455469, postalCode: "59059" },
    { lng: -114.20624, lat: 45.836508, postalCode: "59829" },
    { lng: -110.53834, lat: 45.674463, postalCode: "59047" },
    { lng: -111.137, lat: 45.680811, postalCode: "59718" },
    { lng: -112.14074, lat: 46.376532, postalCode: "59638" },
    { lng: -106.58858, lat: 45.351247, postalCode: "59012" },
    { lng: -114.07639, lat: 46.92275, postalCode: "59808" },
    { lng: -111.650954, lat: 46.45447, postalCode: "59647" },
    { lng: -111.76367, lat: 45.462512, postalCode: "59740" },
    { lng: -114.90532, lat: 48.611903, postalCode: "59934" },
    { lng: -108.289304, lat: 46.506905, postalCode: "59000" },
    { lng: -109.26308, lat: 47.248497, postalCode: "59418" },
    { lng: -113.126262, lat: 48.65417, postalCode: "59473" },
    { lng: -112.98902, lat: 46.141686, postalCode: "59711" },
    { lng: -104.44433, lat: 48.317939, postalCode: "59226" },
    { lng: -108.46819, lat: 45.836121, postalCode: "59105" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59104" },
    { lng: -114.11853, lat: 47.597618, postalCode: "59855" },
    { lng: -106.69221, lat: 45.090049, postalCode: "59025" },
    { lng: -105.71211, lat: 45.942409, postalCode: "59351" },
    { lng: -114.12898, lat: 47.540256, postalCode: "59864" },
    { lng: -110.60771, lat: 45.145353, postalCode: "59030" },
    { lng: -111.24083, lat: 48.635319, postalCode: "59444" },
    { lng: -112.09073, lat: 47.035168, postalCode: "59648" },
    { lng: -111.16737, lat: 48.962291, postalCode: "59545" },
    { lng: -109.01285, lat: 48.607955, postalCode: "59547" },
    { lng: -113.35533, lat: 45.26938, postalCode: "59736" },
    { lng: -113.88196, lat: 46.871508, postalCode: "59851" },
    { lng: -111.88987, lat: 45.659738, postalCode: "59747" },
    { lng: -110.725, lat: 47.261238, postalCode: "59469" },
    { lng: -108.80868, lat: 47.041005, postalCode: "59032" },
    { lng: -104.94036, lat: 46.429205, postalCode: "59336" },
    { lng: -104.88114, lat: 48.132347, postalCode: "59213" },
    { lng: -112.1695, lat: 45.851349, postalCode: "59759" },
    { lng: -107.6368, lat: 46.69244, postalCode: "59039" },
    { lng: -107.61505, lat: 48.751881, postalCode: "59544" },
    { lng: -114.143531, lat: 48.189424, postalCode: "59902" },
    { lng: -111.62521, lat: 44.99257, postalCode: "59720" },
    { lng: -105.84727, lat: 47.274624, postalCode: "59214" },
    { lng: -114.25074, lat: 47.049623, postalCode: "59834" },
    { lng: -109.50168, lat: 45.343978, postalCode: "59071" },
    { lng: -105.16316, lat: 48.897816, postalCode: "59276" },
    { lng: -109.430106, lat: 47.065531, postalCode: "59489" },
    { lng: -105.65893, lat: 48.126066, postalCode: "59201" },
    { lng: -105.85564, lat: 48.730966, postalCode: "59253" },
    { lng: -112.30548, lat: 45.681898, postalCode: "59751" },
    { lng: -109.48065, lat: 47.054233, postalCode: "59457" },
    { lng: -115.81962, lat: 48.017227, postalCode: "59853" },
    { lng: -107.07438, lat: 46.2644, postalCode: "59076" },
    { lng: -112.34456, lat: 46.231547, postalCode: "59631" },
    { lng: -106.63862, lat: 45.585984, postalCode: "59043" },
    { lng: -115.3286, lat: 48.309374, postalCode: "59923" },
    { lng: -114.040777, lat: 46.060169, postalCode: "59835" },
    { lng: -109.28883, lat: 45.951002, postalCode: "59067" },
    { lng: -109.91891, lat: 45.883409, postalCode: "59011" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59115" },
    { lng: -110.90128, lat: 45.627982, postalCode: "59719" },
    { lng: -105.15836, lat: 48.739987, postalCode: "59222" },
    { lng: -114.49865, lat: 48.034766, postalCode: "59920" },
    { lng: -114.799, lat: 47.387824, postalCode: "59856" },
    { lng: -112.76555, lat: 46.201464, postalCode: "59756" },
    { lng: -109.91488, lat: 47.089167, postalCode: "59462" },
    { lng: -104.96702, lat: 48.750625, postalCode: "59257" },
    { lng: -106.99199, lat: 46.017965, postalCode: "59083" },
    { lng: -106.58157, lat: 47.049949, postalCode: "59322" },
    { lng: -111.745326, lat: 48.643651, postalCode: "59400" },
    { lng: -114.00439, lat: 46.790915, postalCode: "59803" },
    { lng: -114.24184, lat: 48.005656, postalCode: "59922" },
    { lng: -111.099869, lat: 44.658614, postalCode: "59798" },
    { lng: -107.44633, lat: 45.076746, postalCode: "59089" },
    { lng: -106.33169, lat: 45.486308, postalCode: "59003" },
    { lng: -112.03006, lat: 47.624913, postalCode: "59436" },
    { lng: -111.59357, lat: 47.429273, postalCode: "59485" },
    { lng: -111.064676, lat: 45.636149, postalCode: "59772" },
    { lng: -114.84507, lat: 47.113918, postalCode: "59872" },
    { lng: -112.10446, lat: 46.620549, postalCode: "59636" },
    { lng: -111.60477, lat: 47.577613, postalCode: "59487" },
    { lng: -108.0969, lat: 45.947154, postalCode: "59006" },
    { lng: -111.8821, lat: 46.578943, postalCode: "59635" },
    { lng: -106.95207, lat: 47.349999, postalCode: "59337" },
    { lng: -106.39641, lat: 46.632549, postalCode: "59347" },
    { lng: -112.041346, lat: 46.601832, postalCode: "59625" },
    { lng: -109.11927, lat: 45.464156, postalCode: "59013" },
    { lng: -112.30254, lat: 48.093269, postalCode: "59467" },
    { lng: -108.393388, lat: 45.825204, postalCode: "59107" },
    { lng: -104.51429, lat: 48.132161, postalCode: "59218" },
    { lng: -112.70073, lat: 45.149805, postalCode: "59724" },
    { lng: -111.97141, lat: 48.744885, postalCode: "59454" },
    { lng: -111.96895, lat: 46.696501, postalCode: "59602" },
    { lng: -110.90128, lat: 45.627982, postalCode: "59717" },
    { lng: -111.27845, lat: 47.526385, postalCode: "59414" },
    { lng: -105.45829, lat: 48.809655, postalCode: "59263" },
    { lng: -111.195906, lat: 47.510209, postalCode: "59402" },
    { lng: -109.64134, lat: 45.373162, postalCode: "59028" },
    { lng: -105.8743, lat: 47.520661, postalCode: "59215" },
    { lng: -111.53143, lat: 45.899881, postalCode: "59752" },
    { lng: -114.05215, lat: 47.169203, postalCode: "59821" },
    { lng: -111.63998, lat: 47.69094, postalCode: "59468" },
    { lng: -112.12007, lat: 45.125532, postalCode: "59710" },
    { lng: -112.99552, lat: 45.757767, postalCode: "59762" },
    { lng: -110.50499, lat: 46.491523, postalCode: "59053" },
    { lng: -106.13017, lat: 45.154425, postalCode: "59062" },
    { lng: -104.539066, lat: 45.567085, postalCode: "59319" },
    { lng: -112.4186, lat: 45.513742, postalCode: "59754" },
    { lng: -110.86564, lat: 47.351391, postalCode: "59412" },
    { lng: -112.43358, lat: 46.481128, postalCode: "59728" },
    { lng: -109.90084, lat: 45.058868, postalCode: "59020" },
    { lng: -109.96291, lat: 47.316304, postalCode: "59430" },
    { lng: -114.35326, lat: 46.752798, postalCode: "59847" },
    { lng: -115.60569, lat: 47.844268, postalCode: "59874" },
    { lng: -111.00381, lat: 48.487928, postalCode: "59522" },
    { lng: -112.040173, lat: 46.590083, postalCode: "59623" },
    { lng: -109.2671, lat: 45.602343, postalCode: "59019" },
    { lng: -109.14649, lat: 45.159808, postalCode: "59007" },
    { lng: -109.468299, lat: 47.055715, postalCode: "59445" },
    { lng: -114.84363, lat: 48.75853, postalCode: "59918" },
    { lng: -112.30023, lat: 46.748706, postalCode: "59640" },
    { lng: -114.03182, lat: 46.53135, postalCode: "59870" },
    { lng: -114.63534, lat: 47.740524, postalCode: "59845" },
    { lng: -113.95551, lat: 46.896821, postalCode: "59802" },
    { lng: -108.66944, lat: 45.777818, postalCode: "59106" },
    { lng: -109.64119, lat: 46.678858, postalCode: "59453" },
    { lng: -110.14681, lat: 46.501026, postalCode: "59085" },
    { lng: -104.75677, lat: 45.386374, postalCode: "59332" },
    { lng: -108.69955, lat: 48.023133, postalCode: "59527" },
    { lng: -107.63987, lat: 45.780451, postalCode: "59034" },
    { lng: -109.48875, lat: 45.522248, postalCode: "59001" },
    { lng: -112.97866, lat: 46.606751, postalCode: "59733" },
    { lng: -114.19568, lat: 47.911242, postalCode: "59931" },
    { lng: -111.634364, lat: 46.206922, postalCode: "59641" },
    { lng: -109.17158, lat: 45.352866, postalCode: "59070" },
    { lng: -104.7741, lat: 48.893223, postalCode: "59252" },
    { lng: -115.347934, lat: 47.388827, postalCode: "59830" },
    { lng: -104.539066, lat: 45.567085, postalCode: "59316" },
    { lng: -111.97496, lat: 46.479732, postalCode: "59634" },
    { lng: -112.31498, lat: 48.336146, postalCode: "59486" },
    { lng: -110.90128, lat: 45.627982, postalCode: "59773" },
    { lng: -112.47009, lat: 48.680309, postalCode: "59427" },
    { lng: -112.1097, lat: 45.528809, postalCode: "59749" },
    { lng: -113.17025, lat: 45.534274, postalCode: "59746" },
    { lng: -108.68933, lat: 45.932174, postalCode: "59002" },
    { lng: -106.07899, lat: 48.717859, postalCode: "59260" },
    { lng: -114.2778, lat: 47.868708, postalCode: "59914" },
    { lng: -107.2284, lat: 45.949226, postalCode: "59010" },
    { lng: -111.341975, lat: 47.258392, postalCode: "59403" },
    { lng: -109.06992, lat: 45.103044, postalCode: "59008" },
    { lng: -109.83681, lat: 45.45236, postalCode: "59061" },
    { lng: -111.18723, lat: 44.727561, postalCode: "59758" },
    { lng: -111.16349, lat: 47.188396, postalCode: "59480" },
    { lng: -114.49692, lat: 46.971146, postalCode: "59820" },
    { lng: -110.71696, lat: 46.926021, postalCode: "59465" },
    { lng: -106.47675, lat: 48.860359, postalCode: "59250" },
    { lng: -109.17406, lat: 48.485856, postalCode: "59523" },
    { lng: -110.8269, lat: 48.675352, postalCode: "59531" },
    { lng: -107.09765, lat: 48.39962, postalCode: "59241" },
    { lng: -105.95632, lat: 45.365698, postalCode: "59348" },
    { lng: -115.96844, lat: 48.03036, postalCode: "59844" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59111" },
    { lng: -107.45873, lat: 45.272302, postalCode: "59050" },
    { lng: -109.49616, lat: 46.383158, postalCode: "59078" },
    { lng: -104.61326, lat: 48.591968, postalCode: "59258" },
    { lng: -106.69558, lat: 48.15381, postalCode: "59230" },
    { lng: -114.16094, lat: 47.696131, postalCode: "59860" },
    { lng: -104.36846, lat: 47.463172, postalCode: "59262" },
    { lng: -105.04391, lat: 46.810255, postalCode: "59326" },
    { lng: -110.67384, lat: 48.631693, postalCode: "59530" },
    { lng: -113.62889, lat: 48.249467, postalCode: "59916" },
    { lng: -112.32535, lat: 48.007556, postalCode: "59419" },
    { lng: -109.27608, lat: 46.367286, postalCode: "59074" },
    { lng: -114.37296, lat: 47.291275, postalCode: "59831" },
    { lng: -105.96719, lat: 48.202722, postalCode: "59225" },
    { lng: -104.38569, lat: 45.189905, postalCode: "59311" },
    { lng: -106.97057, lat: 45.457795, postalCode: "59016" },
    { lng: -110.05667, lat: 45.550177, postalCode: "59052" },
    { lng: -111.79735, lat: 47.465072, postalCode: "59483" },
    { lng: -114.8171, lat: 47.452071, postalCode: "59859" },
    { lng: -112.062393, lat: 46.610002, postalCode: "59624" },
    { lng: -108.72208, lat: 48.483212, postalCode: "59526" },
    { lng: -110.79404, lat: 46.24656, postalCode: "59642" },
    { lng: -110.41769, lat: 48.555089, postalCode: "59528" },
    { lng: -109.09551, lat: 46.906403, postalCode: "59441" },
    { lng: -110.84279, lat: 47.057303, postalCode: "59463" },
    { lng: -115.45113, lat: 47.417172, postalCode: "59867" },
    { lng: -104.20951, lat: 48.887447, postalCode: "59275" },
    { lng: -104.63734, lat: 47.71754, postalCode: "59243" },
    { lng: -112.6757, lat: 45.631031, postalCode: "59743" },
    { lng: -110.8895, lat: 45.310206, postalCode: "59027" },
    { lng: -104.86652, lat: 47.441629, postalCode: "59315" },
    { lng: -114.70843, lat: 48.595224, postalCode: "59927" },
    { lng: -114.42793, lat: 48.863964, postalCode: "59928" },
    { lng: -112.77595, lat: 45.794048, postalCode: "59727" },
    { lng: -111.78292, lat: 45.688676, postalCode: "59735" },
    { lng: -110.077, lat: 48.597362, postalCode: "59532" },
    { lng: -105.345182, lat: 46.860997, postalCode: "59341" },
    { lng: -110.61105, lat: 45.894359, postalCode: "59018" },
    { lng: -113.72687, lat: 47.500385, postalCode: "59826" },
    { lng: -111.18037, lat: 45.85291, postalCode: "59714" },
    { lng: -106.53357, lat: 48.404667, postalCode: "59231" },
    { lng: -108.44428, lat: 48.824171, postalCode: "59542" },
    { lng: -114.32202, lat: 48.201414, postalCode: "59901" },
    { lng: -104.61063, lat: 46.43208, postalCode: "59344" },
    { lng: -105.47959, lat: 45.666174, postalCode: "59343" },
    { lng: -113.84407, lat: 45.887807, postalCode: "59871" },
    { lng: -114.43069, lat: 48.409454, postalCode: "59937" },
    { lng: -105.34448, lat: 45.385223, postalCode: "59317" },
    { lng: -108.29005, lat: 47.000478, postalCode: "59087" },
    { lng: -114.30638, lat: 47.802143, postalCode: "59910" },
    { lng: -114.18711, lat: 46.39449, postalCode: "59875" },
    { lng: -109.89746, lat: 46.13263, postalCode: "59055" },
    { lng: -104.72683, lat: 47.107345, postalCode: "59330" },
    { lng: -113.43734, lat: 48.871429, postalCode: "59411" },
    { lng: -112.25568, lat: 44.712015, postalCode: "59739" },
    { lng: -113.92599, lat: 48.497774, postalCode: "59936" },
    { lng: -107.52697, lat: 47.130621, postalCode: "59077" },
    { lng: -109.77529, lat: 45.734139, postalCode: "59033" },
    { lng: -104.45228, lat: 46.127895, postalCode: "59354" },
    { lng: -109.78365, lat: 48.584562, postalCode: "59501" },
    { lng: -108.76441, lat: 45.430445, postalCode: "59026" },
    { lng: -105.46339, lat: 46.897901, postalCode: "59349" },
    { lng: -107.22415, lat: 46.208375, postalCode: "59038" },
    { lng: -107.92181, lat: 45.903794, postalCode: "59064" },
    { lng: -112.35083, lat: 47.840047, postalCode: "59422" },
    { lng: -108.0755, lat: 46.059495, postalCode: "59088" },
    { lng: -111.33138, lat: 45.810687, postalCode: "59741" },
    { lng: -112.70073, lat: 45.149805, postalCode: "59732" },
    { lng: -112.80722, lat: 46.565168, postalCode: "59731" },
    { lng: -112.62547, lat: 46.973643, postalCode: "59639" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59103" },
    { lng: -105.35001, lat: 47.859083, postalCode: "59274" },
    { lng: -108.83134, lat: 46.096447, postalCode: "59015" },
    { lng: -113.96215, lat: 47.952716, postalCode: "59911" },
    { lng: -107.9138, lat: 46.56503, postalCode: "59054" },
    { lng: -113.61624, lat: 45.595349, postalCode: "59761" },
    { lng: -107.98299, lat: 45.224859, postalCode: "59035" },
    { lng: -104.552352, lat: 48.694565, postalCode: "59256" },
    { lng: -114.01229, lat: 46.855423, postalCode: "59801" },
    { lng: -114.17894, lat: 47.425832, postalCode: "59824" },
    { lng: -111.634532, lat: 45.782744, postalCode: "59760" },
    { lng: -113.83078, lat: 48.185481, postalCode: "59919" },
    { lng: -111.60577, lat: 45.587974, postalCode: "59745" },
    { lng: -113.36273, lat: 46.293656, postalCode: "59858" },
    { lng: -111.32651, lat: 45.268367, postalCode: "59716" },
    { lng: -112.6987, lat: 45.946694, postalCode: "59750" },
    { lng: -114.67899, lat: 47.689156, postalCode: "59848" },
    { lng: -115.13788, lat: 47.3245, postalCode: "59866" },
    { lng: -108.89916, lat: 45.391138, postalCode: "59029" },
    { lng: -114.01396, lat: 48.428564, postalCode: "59913" },
    { lng: -107.80711, lat: 48.235694, postalCode: "59538" },
    { lng: -109.7023, lat: 46.956343, postalCode: "59464" },
    { lng: -112.3409, lat: 46.818147, postalCode: "59633" },
    { lng: -111.59529, lat: 48.842195, postalCode: "59466" },
    { lng: -111.731852, lat: 48.609072, postalCode: "59435" },
    { lng: -104.20547, lat: 47.683129, postalCode: "59270" },
    { lng: -105.04227, lat: 47.777938, postalCode: "59259" },
    { lng: -111.70176, lat: 45.30767, postalCode: "59729" },
    { lng: -115.862, lat: 48.671585, postalCode: "59935" },
    { lng: -113.52551, lat: 47.222677, postalCode: "59868" },
    { lng: -108.48754, lat: 45.737525, postalCode: "59101" },
    { lng: -107.37805, lat: 45.613564, postalCode: "59022" },
    { lng: -112.77411, lat: 45.146179, postalCode: "59725" },
    { lng: -110.54993, lat: 48.525132, postalCode: "59540" },
    { lng: -111.76863, lat: 48.479975, postalCode: "59474" },
    { lng: -108.54135, lat: 47.896703, postalCode: "59546" },
    { lng: -108.194508, lat: 45.978288, postalCode: "59117" },
    { lng: -111.15265, lat: 47.696452, postalCode: "59440" },
    { lng: -104.25806, lat: 47.577478, postalCode: "59217" },
    { lng: -114.429648, lat: 48.22372, postalCode: "59903" },
    { lng: -105.37586, lat: 45.078881, postalCode: "59314" },
    { lng: -111.968877, lat: 46.6672, postalCode: "59604" },
    { lng: -111.94237, lat: 45.296409, postalCode: "59755" },
    { lng: -114.04402, lat: 47.317264, postalCode: "59865" },
    { lng: -110.57954, lat: 46.075387, postalCode: "59086" },
    { lng: -111.44665, lat: 46.394352, postalCode: "59644" },
    { lng: -111.731852, lat: 48.609072, postalCode: "59461" },
    { lng: -105.81164, lat: 46.343483, postalCode: "59301" },
    { lng: -114.99727, lat: 48.840305, postalCode: "59917" },
    { lng: -108.96, lat: 45.622948, postalCode: "59063" },
    { lng: -112.61804, lat: 48.177132, postalCode: "59432" },
    { lng: -104.70869, lat: 48.40892, postalCode: "59242" },
    { lng: -114.22384, lat: 48.073924, postalCode: "59932" },
    { lng: -115.49151, lat: 47.365882, postalCode: "59842" },
    { lng: -108.92119, lat: 45.505519, postalCode: "59041" },
    { lng: -112.06748, lat: 46.205729, postalCode: "59632" },
    { lng: -101.17563, lat: 46.258711, postalCode: "58569" },
    { lng: -103.56872, lat: 46.92752, postalCode: "58645" },
    { lng: -103.886, lat: 47.83202, postalCode: "58838" },
    { lng: -102.56782, lat: 46.860364, postalCode: "58630" },
    { lng: -102.663913, lat: 46.820185, postalCode: "58602" },
    { lng: -102.11476, lat: 46.034096, postalCode: "57638" },
    { lng: -101.9254, lat: 47.281173, postalCode: "58580" },
    { lng: -103.93203, lat: 46.2715, postalCode: "58643" },
    { lng: -103.03364, lat: 46.808668, postalCode: "58655" },
    { lng: -102.0077, lat: 46.346028, postalCode: "58562" },
    { lng: -102.101218, lat: 45.984316, postalCode: "57683" },
    { lng: -102.48549, lat: 46.666229, postalCode: "58641" },
    { lng: -102.28356, lat: 46.891017, postalCode: "58652" },
    { lng: -103.68539, lat: 46.329565, postalCode: "58651" },
    { lng: -103.09161, lat: 46.172025, postalCode: "58653" },
    { lng: -102.60353, lat: 46.043733, postalCode: "58639" },
    { lng: -102.08106, lat: 47.396933, postalCode: "58541" },
    { lng: -102.92304, lat: 46.140764, postalCode: "58649" },
    { lng: -102.622139, lat: 47.402085, postalCode: "58644" },
    { lng: -101.34592, lat: 46.291855, postalCode: "58564" },
    { lng: -103.21389, lat: 46.922727, postalCode: "58622" },
    { lng: -102.8735, lat: 46.511555, postalCode: "58647" },
    { lng: -101.61207, lat: 47.290543, postalCode: "58545" },
    { lng: -102.82216, lat: 47.151337, postalCode: "58642" },
    { lng: -102.60398, lat: 46.405798, postalCode: "58650" },
    { lng: -102.8041, lat: 46.878057, postalCode: "58601" },
    { lng: -102.82766, lat: 47.411564, postalCode: "58640" },
    { lng: -103.78322, lat: 46.85992, postalCode: "58654" },
    { lng: -102.203219, lat: 47.304598, postalCode: "58673" },
    { lng: -102.28758, lat: 46.379559, postalCode: "58646" },
    { lng: -103.31682, lat: 46.486096, postalCode: "58620" },
    { lng: -103.23327, lat: 47.219032, postalCode: "58627" },
    { lng: -102.46219, lat: 46.975615, postalCode: "58656" },
    { lng: -101.82853, lat: 46.408171, postalCode: "58533" },
    { lng: -102.18789, lat: 47.252561, postalCode: "58625" },
    { lng: -101.37642, lat: 47.281472, postalCode: "58571" },
    { lng: -101.60406, lat: 46.320916, postalCode: "58529" },
    { lng: -102.34345, lat: 47.371929, postalCode: "58636" },
    { lng: -102.61222, lat: 47.346329, postalCode: "58626" },
    { lng: -103.36899, lat: 47.393108, postalCode: "58634" },
    { lng: -103.94995, lat: 46.713628, postalCode: "58632" },
    { lng: -103.93051, lat: 47.077257, postalCode: "58621" },
    { lng: -103.40453, lat: 46.182799, postalCode: "58623" },
    { lng: -101.69819, lat: 41.115512, postalCode: "69153" },
    { lng: -101.54055, lat: 40.097723, postalCode: "69021" },
    { lng: -103.95935, lat: 41.986025, postalCode: "69358" },
    { lng: -103.51728, lat: 41.781984, postalCode: "69355" },
    { lng: -101.98221, lat: 40.734776, postalCode: "69168" },
    { lng: -102.42833, lat: 42.21773, postalCode: "69351" },
    { lng: -103.17741, lat: 42.369213, postalCode: "69348" },
    { lng: -101.73705, lat: 41.926792, postalCode: "69350" },
    { lng: -102.19792, lat: 42.23242, postalCode: "69340" },
    { lng: -101.36663, lat: 42.838967, postalCode: "69211" },
    { lng: -103.10517, lat: 41.660814, postalCode: "69336" },
    { lng: -103.416351, lat: 41.746944, postalCode: "69353" },
    { lng: -101.67576, lat: 40.530405, postalCode: "69033" },
    { lng: -103.65998, lat: 41.803503, postalCode: "69341" },
    { lng: -102.51068, lat: 42.963117, postalCode: "69365" },
    { lng: -103.47126, lat: 41.207381, postalCode: "69133" },
    { lng: -101.68816, lat: 41.569138, postalCode: "69121" },
    { lng: -102.40905, lat: 41.104806, postalCode: "69129" },
    { lng: -101.56145, lat: 41.92305, postalCode: "69366" },
    { lng: -101.60141, lat: 41.250772, postalCode: "69144" },
    { lng: -102.97377, lat: 41.318554, postalCode: "69141" },
    { lng: -101.40758, lat: 40.142872, postalCode: "69037" },
    { lng: -101.09984, lat: 42.686576, postalCode: "69219" },
    { lng: -101.37877, lat: 41.154635, postalCode: "69155" },
    { lng: -103.47009, lat: 41.863357, postalCode: "69356" },
    { lng: -102.65443, lat: 42.580176, postalCode: "69347" },
    { lng: -100.92827, lat: 42.939915, postalCode: "69216" },
    { lng: -101.53601, lat: 40.429297, postalCode: "69027" },
    { lng: -102.331762, lat: 41.615307, postalCode: "69190" },
    { lng: -102.55973, lat: 41.521238, postalCode: "69148" },
    { lng: -102.85851, lat: 41.583579, postalCode: "69125" },
    { lng: -102.00083, lat: 42.567701, postalCode: "69343" },
    { lng: -103.78815, lat: 41.940693, postalCode: "69357" },
    { lng: -101.7201, lat: 40.133487, postalCode: "69041" },
    { lng: -101.89092, lat: 41.091667, postalCode: "69127" },
    { lng: -103.28325, lat: 41.233268, postalCode: "69156" },
    { lng: -101.5378, lat: 40.845229, postalCode: "69150" },
    { lng: -103.2562, lat: 42.755969, postalCode: "69367" },
    { lng: -103.01949, lat: 41.162214, postalCode: "69162" },
    { lng: -102.08926, lat: 41.058171, postalCode: "69122" },
    { lng: -103.9024, lat: 41.173278, postalCode: "69128" },
    { lng: -101.77851, lat: 41.296221, postalCode: "69146" },
    { lng: -101.09299, lat: 42.088889, postalCode: "69152" },
    { lng: -104.04977, lat: 41.992583, postalCode: "69349" },
    { lng: -103.39841, lat: 42.672974, postalCode: "69339" },
    { lng: -102.35025, lat: 41.402127, postalCode: "69154" },
    { lng: -103.31277, lat: 41.777948, postalCode: "69334" },
    { lng: -102.63874, lat: 41.168155, postalCode: "69149" },
    { lng: -102.48573, lat: 42.737843, postalCode: "69360" },
    { lng: -101.96041, lat: 40.032262, postalCode: "69030" },
    { lng: -103.70726, lat: 41.851012, postalCode: "69363" },
    { lng: -101.38031, lat: 40.847471, postalCode: "69134" },
    { lng: -103.65241, lat: 41.885553, postalCode: "69361" },
    { lng: -102.995925, lat: 41.220549, postalCode: "69160" },
    { lng: -103.6608, lat: 41.24058, postalCode: "69145" },
    { lng: -101.88349, lat: 40.489063, postalCode: "69023" },
    { lng: -102.05512, lat: 41.376624, postalCode: "69147" },
    { lng: -103.83101, lat: 42.624764, postalCode: "69346" },
    { lng: -103.00219, lat: 42.808213, postalCode: "69337" },
    { lng: -102.96652, lat: 41.823881, postalCode: "69331" },
    { lng: -101.98548, lat: 41.861313, postalCode: "69333" },
    { lng: -104.00078, lat: 41.181106, postalCode: "82082" },
    { lng: -101.65674, lat: 42.848109, postalCode: "69218" },
    { lng: -103.00711, lat: 41.406407, postalCode: "69131" },
    { lng: -103.72421, lat: 41.549736, postalCode: "69345" },
    { lng: -101.35561, lat: 40.484407, postalCode: "69045" },
    { lng: -101.75824, lat: 40.850141, postalCode: "69140" },
    { lng: -103.99465, lat: 41.849619, postalCode: "69352" },
    { lng: -102.84937, lat: 42.13928, postalCode: "69301" },
    { lng: -103.21227, lat: 42.485817, postalCode: "69354" },
    { lng: -102.18247, lat: 41.996022, postalCode: "69335" },
    { lng: -105.74841, lat: 35.759271, postalCode: "87535" },
    { lng: -106.52067, lat: 35.100796, postalCode: "87112" },
    { lng: -105.26233, lat: 35.345618, postalCode: "87569" },
    { lng: -105.915713, lat: 35.787442, postalCode: "87574" },
    { lng: -104.38083, lat: 32.795467, postalCode: "88210" },
    { lng: -108.14505, lat: 36.785816, postalCode: "87402" },
    { lng: -105.60728, lat: 36.546068, postalCode: "87580" },
    { lng: -106.80866, lat: 35.527064, postalCode: "87053" },
    { lng: -108.10736, lat: 32.774869, postalCode: "88023" },
    { lng: -108.84857, lat: 35.534253, postalCode: "87301" },
    { lng: -106.57771, lat: 31.799253, postalCode: "88063" },
    { lng: -108.06925, lat: 36.774472, postalCode: "87499" },
    { lng: -104.82618, lat: 36.358816, postalCode: "87729" },
    { lng: -107.65365, lat: 34.900203, postalCode: "87034" },
    { lng: -108.91977, lat: 33.879513, postalCode: "87824" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87198" },
    { lng: -103.56942, lat: 36.896078, postalCode: "88419" },
    { lng: -105.6422, lat: 35.622261, postalCode: "87552" },
    { lng: -105.47306, lat: 32.783249, postalCode: "88354" },
    { lng: -108.81515, lat: 31.861247, postalCode: "88020" },
    { lng: -103.10002, lat: 33.843464, postalCode: "88113" },
    { lng: -106.23407, lat: 36.387967, postalCode: "87530" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87174" },
    { lng: -104.431788, lat: 33.303825, postalCode: "88202" },
    { lng: -103.505541, lat: 36.369783, postalCode: "88429" },
    { lng: -106.07948, lat: 32.841331, postalCode: "88330" },
    { lng: -106.615936, lat: 31.840649, postalCode: "88018" },
    { lng: -108.80023, lat: 36.748026, postalCode: "87420" },
    { lng: -107.45148, lat: 35.047954, postalCode: "87007" },
    { lng: -105.73072, lat: 33.520074, postalCode: "88341" },
    { lng: -106.5443, lat: 32.013822, postalCode: "88021" },
    { lng: -103.22216, lat: 36.387827, postalCode: "88415" },
    { lng: -105.51307, lat: 34.750736, postalCode: "88321" },
    { lng: -107.90777, lat: 35.122129, postalCode: "87020" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87192" },
    { lng: -103.81162, lat: 34.770332, postalCode: "88427" },
    { lng: -104.3334, lat: 33.244151, postalCode: "88230" },
    { lng: -106.07952, lat: 36.074403, postalCode: "87566" },
    { lng: -107.09858, lat: 36.343007, postalCode: "87029" },
    { lng: -107.24109, lat: 34.999815, postalCode: "87026" },
    { lng: -105.981847, lat: 35.521181, postalCode: "87592" },
    { lng: -107.893691, lat: 35.192539, postalCode: "87021" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87140" },
    { lng: -108.63575, lat: 33.401692, postalCode: "88039" },
    { lng: -106.55102, lat: 35.053147, postalCode: "87116" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87158" },
    { lng: -106.52223, lat: 35.135303, postalCode: "87111" },
    { lng: -106.37296, lat: 35.136495, postalCode: "87008" },
    { lng: -106.13395, lat: 36.537146, postalCode: "87581" },
    { lng: -103.709525, lat: 33.883159, postalCode: "88122" },
    { lng: -104.88576, lat: 32.735982, postalCode: "88250" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87180" },
    { lng: -104.65018, lat: 36.02239, postalCode: "87752" },
    { lng: -106.44361, lat: 35.303843, postalCode: "87043" },
    { lng: -105.58602, lat: 36.735033, postalCode: "87556" },
    { lng: -107.13794, lat: 32.699873, postalCode: "87941" },
    { lng: -104.55089, lat: 33.485178, postalCode: "88201" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87153" },
    { lng: -103.23598, lat: 32.439762, postalCode: "88231" },
    { lng: -105.26783, lat: 33.285158, postalCode: "88336" },
    { lng: -104.47567, lat: 35.313786, postalCode: "88421" },
    { lng: -103.90051, lat: 35.80364, postalCode: "87733" },
    { lng: -106.15202, lat: 35.409522, postalCode: "87010" },
    { lng: -103.96571, lat: 36.353757, postalCode: "88422" },
    { lng: -108.54595, lat: 35.463071, postalCode: "87316" },
    { lng: -106.33885, lat: 35.648976, postalCode: "87083" },
    { lng: -106.61689, lat: 32.068299, postalCode: "88024" },
    { lng: -108.07182, lat: 35.20912, postalCode: "87005" },
    { lng: -103.972, lat: 32.079363, postalCode: "88263" },
    { lng: -105.15426, lat: 35.203048, postalCode: "87711" },
    { lng: -103.16138, lat: 33.922767, postalCode: "88132" },
    { lng: -103.98784, lat: 32.27936, postalCode: "88256" },
    { lng: -105.63599, lat: 33.420542, postalCode: "88312" },
    { lng: -106.54291, lat: 31.789109, postalCode: "79922" },
    { lng: -105.41628, lat: 36.962335, postalCode: "87512" },
    { lng: -107.35393, lat: 33.095526, postalCode: "87942" },
    { lng: -106.546128, lat: 35.054947, postalCode: "87115" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87119" },
    { lng: -106.32728, lat: 35.584234, postalCode: "87041" },
    { lng: -107.22065, lat: 33.208473, postalCode: "87935" },
    { lng: -108.15795, lat: 32.781205, postalCode: "88022" },
    { lng: -106.9757, lat: 36.818905, postalCode: "87528" },
    { lng: -108.81277, lat: 35.295558, postalCode: "87326" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87181" },
    { lng: -106.075722, lat: 35.606283, postalCode: "87506" },
    { lng: -105.68663, lat: 32.629637, postalCode: "88350" },
    { lng: -106.68516, lat: 32.036669, postalCode: "88027" },
    { lng: -108.90414, lat: 35.486269, postalCode: "87319" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87190" },
    { lng: -108.79265, lat: 35.680001, postalCode: "87375" },
    { lng: -107.32655, lat: 32.807462, postalCode: "87930" },
    { lng: -105.903255, lat: 36.005591, postalCode: "87523" },
    { lng: -107.98853, lat: 35.408295, postalCode: "87045" },
    { lng: -106.54091, lat: 36.64863, postalCode: "87575" },
    { lng: -103.18444, lat: 35.174099, postalCode: "88411" },
    { lng: -104.679611, lat: 35.456288, postalCode: "88441" },
    { lng: -105.99524, lat: 35.001195, postalCode: "87035" },
    { lng: -104.98272, lat: 35.173408, postalCode: "87724" },
    { lng: -108.63899, lat: 34.330192, postalCode: "87829" },
    { lng: -108.3645, lat: 31.928038, postalCode: "88040" },
    { lng: -107.52505, lat: 36.901026, postalCode: "87419" },
    { lng: -105.36135, lat: 32.663082, postalCode: "88344" },
    { lng: -106.50645, lat: 35.067447, postalCode: "87123" },
    { lng: -108.19993, lat: 35.495133, postalCode: "87323" },
    { lng: -104.33708, lat: 33.060827, postalCode: "88232" },
    { lng: -107.74042, lat: 32.239815, postalCode: "88031" },
    { lng: -105.71543, lat: 36.191998, postalCode: "87553" },
    { lng: -103.39045, lat: 35.348463, postalCode: "88426" },
    { lng: -108.73956, lat: 32.350444, postalCode: "88045" },
    { lng: -106.17472, lat: 36.094021, postalCode: "87537" },
    { lng: -105.66637, lat: 33.350032, postalCode: "88345" },
    { lng: -108.79107, lat: 36.272472, postalCode: "87455" },
    { lng: -108.66387, lat: 33.638186, postalCode: "87830" },
    { lng: -103.34255, lat: 34.166231, postalCode: "88130" },
    { lng: -107.59761, lat: 32.834372, postalCode: "88042" },
    { lng: -106.92814, lat: 34.150585, postalCode: "87823" },
    { lng: -105.56045, lat: 36.160484, postalCode: "87579" },
    { lng: -105.5808, lat: 36.414942, postalCode: "87571" },
    { lng: -106.60285, lat: 34.046275, postalCode: "87832" },
    { lng: -106.93052, lat: 32.204171, postalCode: "88004" },
    { lng: -106.29536, lat: 35.86849, postalCode: "87544" },
    { lng: -103.391269, lat: 34.628472, postalCode: "88102" },
    { lng: -105.68568, lat: 32.943661, postalCode: "88317" },
    { lng: -106.69301, lat: 34.906197, postalCode: "87022" },
    { lng: -106.02058, lat: 33.098649, postalCode: "88352" },
    { lng: -108.176693, lat: 35.480924, postalCode: "87302" },
    { lng: -103.145119, lat: 33.717607, postalCode: "88123" },
    { lng: -108.30257, lat: 32.707876, postalCode: "88065" },
    { lng: -106.55322, lat: 35.32151, postalCode: "87004" },
    { lng: -103.71399, lat: 32.878526, postalCode: "88264" },
    { lng: -108.45001, lat: 36.654783, postalCode: "87416" },
    { lng: -104.328113, lat: 32.753571, postalCode: "88211" },
    { lng: -107.71282, lat: 32.223023, postalCode: "88030" },
    { lng: -106.044394, lat: 35.632858, postalCode: "87585" },
    { lng: -108.6655, lat: 36.002113, postalCode: "87325" },
    { lng: -108.62606, lat: 36.877607, postalCode: "87421" },
    { lng: -105.78702, lat: 36.036721, postalCode: "87578" },
    { lng: -108.41304, lat: 34.999427, postalCode: "87321" },
    { lng: -103.902091, lat: 35.804116, postalCode: "87746" },
    { lng: -103.22589, lat: 34.432846, postalCode: "88101" },
    { lng: -105.01755, lat: 33.480197, postalCode: "88351" },
    { lng: -104.7305, lat: 34.321787, postalCode: "88136" },
    { lng: -107.48121, lat: 36.681819, postalCode: "87412" },
    { lng: -103.96633, lat: 34.429099, postalCode: "88134" },
    { lng: -103.86353, lat: 36.004279, postalCode: "87743" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87195" },
    { lng: -106.64792, lat: 35.083946, postalCode: "87102" },
    { lng: -106.66112, lat: 32.152569, postalCode: "88048" },
    { lng: -106.123698, lat: 35.439937, postalCode: "87502" },
    { lng: -105.754453, lat: 35.469938, postalCode: "87508" },
    { lng: -107.69183, lat: 33.252863, postalCode: "87943" },
    { lng: -105.50586, lat: 36.546104, postalCode: "87514" },
    { lng: -106.68375, lat: 34.83436, postalCode: "87042" },
    { lng: -105.57658, lat: 35.249513, postalCode: "87560" },
    { lng: -104.29134, lat: 32.408311, postalCode: "88220" },
    { lng: -106.18489, lat: 36.17634, postalCode: "87548" },
    { lng: -103.12311, lat: 36.137255, postalCode: "88436" },
    { lng: -105.81338, lat: 32.902131, postalCode: "88325" },
    { lng: -103.32392, lat: 33.526887, postalCode: "88114" },
    { lng: -107.36322, lat: 32.969955, postalCode: "87931" },
    { lng: -103.1837, lat: 33.628946, postalCode: "88125" },
    { lng: -106.46786, lat: 36.082439, postalCode: "87516" },
    { lng: -106.93722, lat: 33.953237, postalCode: "87801" },
    { lng: -105.938768, lat: 32.980058, postalCode: "88332" },
    { lng: -106.63846, lat: 32.123655, postalCode: "88072" },
    { lng: -106.28742, lat: 35.179121, postalCode: "87047" },
    { lng: -108.51792, lat: 35.598798, postalCode: "87311" },
    { lng: -105.23043, lat: 36.188161, postalCode: "87722" },
    { lng: -105.981847, lat: 35.521181, postalCode: "87594" },
    { lng: -106.58232, lat: 35.073343, postalCode: "87108" },
    { lng: -103.14592, lat: 32.787805, postalCode: "88242" },
    { lng: -106.935844, lat: 35.71742, postalCode: "87046" },
    { lng: -105.40542, lat: 32.923979, postalCode: "88339" },
    { lng: -105.981847, lat: 35.521181, postalCode: "87503" },
    { lng: -107.96667, lat: 36.837421, postalCode: "87410" },
    { lng: -105.612595, lat: 32.695546, postalCode: "88311" },
    { lng: -106.792993, lat: 32.336251, postalCode: "88085" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87187" },
    { lng: -104.293691, lat: 32.342033, postalCode: "88221" },
    { lng: -106.63695, lat: 36.700526, postalCode: "87551" },
    { lng: -106.71763, lat: 31.879712, postalCode: "88008" },
    { lng: -106.87734, lat: 34.326229, postalCode: "87831" },
    { lng: -104.45187, lat: 32.954421, postalCode: "88253" },
    { lng: -103.326445, lat: 34.401452, postalCode: "88103" },
    { lng: -105.17633, lat: 35.974115, postalCode: "87736" },
    { lng: -106.62093, lat: 35.822802, postalCode: "87025" },
    { lng: -105.3833, lat: 36.045092, postalCode: "87723" },
    { lng: -105.14392, lat: 34.585906, postalCode: "88353" },
    { lng: -107.5052, lat: 35.166618, postalCode: "87014" },
    { lng: -103.21973, lat: 35.644244, postalCode: "88430" },
    { lng: -107.36816, lat: 34.139451, postalCode: "87825" },
    { lng: -106.8053, lat: 34.34886, postalCode: "87028" },
    { lng: -107.260088, lat: 33.127764, postalCode: "87910" },
    { lng: -107.43337, lat: 33.377012, postalCode: "87939" },
    { lng: -107.28652, lat: 32.791076, postalCode: "87933" },
    { lng: -105.981847, lat: 35.521181, postalCode: "87509" },
    { lng: -105.63855, lat: 36.756688, postalCode: "87519" },
    { lng: -105.37302, lat: 36.14795, postalCode: "87713" },
    { lng: -106.75512, lat: 36.618928, postalCode: "87533" },
    { lng: -106.70306, lat: 32.312506, postalCode: "88011" },
    { lng: -108.176693, lat: 35.480924, postalCode: "87365" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87103" },
    { lng: -105.67671, lat: 35.498227, postalCode: "87562" },
    { lng: -103.15193, lat: 34.857544, postalCode: "88112" },
    { lng: -105.88218, lat: 33.710312, postalCode: "88301" },
    { lng: -106.67336, lat: 35.196446, postalCode: "87114" },
    { lng: -106.76078, lat: 32.300193, postalCode: "88001" },
    { lng: -106.40596, lat: 36.2996, postalCode: "87510" },
    { lng: -103.40619, lat: 34.865507, postalCode: "88120" },
    { lng: -103.77676, lat: 35.103769, postalCode: "88401" },
    { lng: -107.49441, lat: 36.203634, postalCode: "87018" },
    { lng: -104.692463, lat: 36.606924, postalCode: "87749" },
    { lng: -105.5809, lat: 36.959631, postalCode: "87524" },
    { lng: -105.81143, lat: 36.333259, postalCode: "87517" },
    { lng: -106.35022, lat: 35.509184, postalCode: "87052" },
    { lng: -105.98866, lat: 36.14773, postalCode: "87582" },
    { lng: -108.61359, lat: 34.737855, postalCode: "87315" },
    { lng: -108.93389, lat: 31.766132, postalCode: "88056" },
    { lng: -107.18741, lat: 33.148245, postalCode: "87901" },
    { lng: -108.27933, lat: 34.888629, postalCode: "87357" },
    { lng: -106.46884, lat: 32.355099, postalCode: "88002" },
    { lng: -103.46686, lat: 34.475607, postalCode: "88133" },
    { lng: -108.45374, lat: 35.453355, postalCode: "87347" },
    { lng: -104.2151, lat: 35.157683, postalCode: "88431" },
    { lng: -105.53021, lat: 33.492448, postalCode: "88323" },
    { lng: -106.02523, lat: 36.362617, postalCode: "87549" },
    { lng: -103.329901, lat: 34.6705, postalCode: "88100" },
    { lng: -106.27612, lat: 34.500604, postalCode: "87036" },
    { lng: -106.67238, lat: 35.101727, postalCode: "87104" },
    { lng: -108.30206, lat: 32.729758, postalCode: "88061" },
    { lng: -105.11586, lat: 36.074211, postalCode: "87735" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87191" },
    { lng: -103.74791, lat: 36.734124, postalCode: "88418" },
    { lng: -107.02161, lat: 32.620805, postalCode: "87940" },
    { lng: -107.73529, lat: 31.823645, postalCode: "88029" },
    { lng: -106.243911, lat: 35.108573, postalCode: "87197" },
    { lng: -105.95532, lat: 34.43283, postalCode: "87063" },
    { lng: -105.84161, lat: 36.1566, postalCode: "87527" },
    { lng: -108.32473, lat: 35.442033, postalCode: "87312" },
    { lng: -108.21816, lat: 32.872349, postalCode: "88053" },
    { lng: -106.61747, lat: 35.078246, postalCode: "87106" },
    { lng: -105.87995, lat: 36.248813, postalCode: "87531" },
    { lng: -108.15359, lat: 32.775681, postalCode: "88026" },
    { lng: -106.644831, lat: 35.199592, postalCode: "87101" },
    { lng: -108.09508, lat: 36.813748, postalCode: "87415" },
    { lng: -105.35812, lat: 33.479136, postalCode: "88338" },
    { lng: -105.97201, lat: 35.00993, postalCode: "87070" },
    { lng: -106.68695, lat: 35.031329, postalCode: "87105" },
    { lng: -108.11625, lat: 32.673398, postalCode: "88043" },
    { lng: -105.50337, lat: 35.454443, postalCode: "87565" },
    { lng: -103.63843, lat: 33.455761, postalCode: "88213" },
    { lng: -108.15934, lat: 36.927309, postalCode: "87418" },
    { lng: -103.14925, lat: 36.710694, postalCode: "88437" },
    { lng: -103.62567, lat: 34.528601, postalCode: "88124" },
    { lng: -107.36564, lat: 35.132004, postalCode: "87040" },
    { lng: -108.44042, lat: 32.961804, postalCode: "88038" },
    { lng: -107.906536, lat: 32.693536, postalCode: "88034" },
    { lng: -108.94007, lat: 33.091874, postalCode: "88051" },
    { lng: -108.137931, lat: 32.728228, postalCode: "88062" },
    { lng: -103.99329, lat: 36.740696, postalCode: "88414" },
    { lng: -105.33759, lat: 35.276415, postalCode: "87583" },
    { lng: -105.60547, lat: 36.468402, postalCode: "87529" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87176" },
    { lng: -107.10461, lat: 32.19563, postalCode: "88033" },
    { lng: -103.87575, lat: 33.79692, postalCode: "88116" },
    { lng: -108.65253, lat: 35.533134, postalCode: "87322" },
    { lng: -106.81463, lat: 32.347752, postalCode: "88005" },
    { lng: -103.30857, lat: 32.590107, postalCode: "88265" },
    { lng: -103.42377, lat: 33.270355, postalCode: "88267" },
    { lng: -106.800976, lat: 32.393919, postalCode: "88032" },
    { lng: -106.65317, lat: 35.700027, postalCode: "87044" },
    { lng: -106.12269, lat: 34.775863, postalCode: "87016" },
    { lng: -105.693322, lat: 33.330502, postalCode: "88355" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87154" },
    { lng: -106.6977, lat: 32.417871, postalCode: "88012" },
    { lng: -105.39831, lat: 36.006137, postalCode: "87715" },
    { lng: -105.01333, lat: 36.59956, postalCode: "87714" },
    { lng: -108.19225, lat: 36.625357, postalCode: "87401" },
    { lng: -107.97807, lat: 32.726051, postalCode: "88041" },
    { lng: -106.03282, lat: 35.994909, postalCode: "87567" },
    { lng: -106.60256, lat: 32.437435, postalCode: "88052" },
    { lng: -108.327898, lat: 32.53667, postalCode: "88028" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87125" },
    { lng: -104.98925, lat: 35.880398, postalCode: "87753" },
    { lng: -105.9267, lat: 36.631809, postalCode: "87577" },
    { lng: -106.6895, lat: 34.864034, postalCode: "87068" },
    { lng: -107.40227, lat: 35.869051, postalCode: "87013" },
    { lng: -105.18498, lat: 35.881516, postalCode: "87712" },
    { lng: -105.66493, lat: 36.129822, postalCode: "87543" },
    { lng: -103.44623, lat: 32.901887, postalCode: "88260" },
    { lng: -106.06145, lat: 36.422525, postalCode: "87539" },
    { lng: -103.216516, lat: 32.7475, postalCode: "88241" },
    { lng: -105.880597, lat: 34.650925, postalCode: "87009" },
    { lng: -108.70302, lat: 33.034435, postalCode: "88025" },
    { lng: -106.73362, lat: 34.740708, postalCode: "87060" },
    { lng: -103.26808, lat: 32.188493, postalCode: "88252" },
    { lng: -106.64073, lat: 35.134094, postalCode: "87107" },
    { lng: -108.14528, lat: 34.387137, postalCode: "87827" },
    { lng: -106.54779, lat: 36.111907, postalCode: "87064" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87194" },
    { lng: -105.41406, lat: 35.711505, postalCode: "87731" },
    { lng: -104.18276, lat: 35.387857, postalCode: "88416" },
    { lng: -104.52049, lat: 36.565525, postalCode: "87728" },
    { lng: -108.57046, lat: 35.766353, postalCode: "87310" },
    { lng: -105.85196, lat: 33.158737, postalCode: "88314" },
    { lng: -106.62141, lat: 35.229614, postalCode: "87048" },
    { lng: -108.72493, lat: 35.06947, postalCode: "87327" },
    { lng: -105.56694, lat: 33.342426, postalCode: "88346" },
    { lng: -106.079686, lat: 32.405061, postalCode: "88342" },
    { lng: -105.99002, lat: 32.806612, postalCode: "88315" },
    { lng: -106.7592, lat: 34.598268, postalCode: "87023" },
    { lng: -105.42596, lat: 35.856035, postalCode: "87742" },
    { lng: -106.04254, lat: 36.097705, postalCode: "87511" },
    { lng: -107.27535, lat: 32.760034, postalCode: "87936" },
    { lng: -106.92146, lat: 34.201613, postalCode: "87828" },
    { lng: -106.01328, lat: 34.862981, postalCode: "87032" },
    { lng: -108.81089, lat: 36.475414, postalCode: "87461" },
    { lng: -106.830111, lat: 32.356091, postalCode: "88007" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87201" },
    { lng: -103.62143, lat: 36.458919, postalCode: "88424" },
    { lng: -106.71469, lat: 35.290647, postalCode: "87124" },
    { lng: -105.64442, lat: 36.610705, postalCode: "87564" },
    { lng: -105.65799, lat: 35.795217, postalCode: "87573" },
    { lng: -106.59427, lat: 35.051847, postalCode: "87118" },
    { lng: -105.08142, lat: 35.594103, postalCode: "87701" },
    { lng: -103.06869, lat: 34.511478, postalCode: "88135" },
    { lng: -107.16806, lat: 32.603957, postalCode: "87937" },
    { lng: -106.571978, lat: 35.056747, postalCode: "87117" },
    { lng: -106.55118, lat: 36.874229, postalCode: "87520" },
    { lng: -105.36046, lat: 33.415814, postalCode: "88348" },
    { lng: -106.70851, lat: 35.146144, postalCode: "87120" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87131" },
    { lng: -106.40996, lat: 36.476265, postalCode: "87515" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87184" },
    { lng: -105.97561, lat: 32.839921, postalCode: "88310" },
    { lng: -103.27741, lat: 33.793145, postalCode: "88126" },
    { lng: -104.528038, lat: 33.361658, postalCode: "88603" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87193" },
    { lng: -103.97475, lat: 34.706943, postalCode: "88121" },
    { lng: -106.914575, lat: 32.488841, postalCode: "88054" },
    { lng: -106.295255, lat: 35.863858, postalCode: "87545" },
    { lng: -105.781129, lat: 32.8672, postalCode: "88349" },
    { lng: -107.00449, lat: 36.151633, postalCode: "87027" },
    { lng: -104.36153, lat: 32.188634, postalCode: "88268" },
    { lng: -104.24242, lat: 34.346673, postalCode: "88119" },
    { lng: -108.10225, lat: 35.761481, postalCode: "87313" },
    { lng: -106.57927, lat: 35.106396, postalCode: "87110" },
    { lng: -108.78661, lat: 35.376892, postalCode: "87305" },
    { lng: -106.54918, lat: 36.522263, postalCode: "87518" },
    { lng: -105.73266, lat: 33.157278, postalCode: "88340" },
    { lng: -108.57547, lat: 33.871414, postalCode: "87820" },
    { lng: -103.433804, lat: 32.785417, postalCode: "88244" },
    { lng: -106.59384, lat: 35.179635, postalCode: "87113" },
    { lng: -106.71991, lat: 32.213198, postalCode: "88047" },
    { lng: -103.96139, lat: 32.74209, postalCode: "88255" },
    { lng: -106.31044, lat: 35.017604, postalCode: "87059" },
    { lng: -107.28747, lat: 33.116336, postalCode: "87945" },
    { lng: -107.87701, lat: 35.15105, postalCode: "87051" },
    { lng: -105.952788, lat: 35.615216, postalCode: "87507" },
    { lng: -108.14114, lat: 33.029102, postalCode: "88049" },
    { lng: -104.81362, lat: 34.862953, postalCode: "88435" },
    { lng: -104.39211, lat: 36.366344, postalCode: "87747" },
    { lng: -104.524894, lat: 33.363439, postalCode: "88203" },
    { lng: -106.42793, lat: 35.388701, postalCode: "87001" },
    { lng: -104.679611, lat: 35.456288, postalCode: "87538" },
    { lng: -103.35216, lat: 33.148388, postalCode: "88262" },
    { lng: -108.8731, lat: 35.799367, postalCode: "87320" },
    { lng: -106.73752, lat: 35.624111, postalCode: "87024" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87199" },
    { lng: -103.34027, lat: 35.098357, postalCode: "88434" },
    { lng: -106.56662, lat: 35.155811, postalCode: "87109" },
    { lng: -105.62924, lat: 36.504515, postalCode: "87576" },
    { lng: -106.19155, lat: 35.075777, postalCode: "87015" },
    { lng: -106.76581, lat: 36.187444, postalCode: "87017" },
    { lng: -104.85007, lat: 35.798216, postalCode: "87750" },
    { lng: -106.400486, lat: 32.031665, postalCode: "88081" },
    { lng: -105.880597, lat: 34.650925, postalCode: "87057" },
    { lng: -105.48489, lat: 33.429637, postalCode: "88324" },
    { lng: -106.645081, lat: 35.295331, postalCode: "87144" },
    { lng: -106.52021, lat: 35.18076, postalCode: "87122" },
    { lng: -105.60437, lat: 32.789854, postalCode: "88347" },
    { lng: -105.50602, lat: 36.575944, postalCode: "87525" },
    { lng: -105.91598, lat: 35.230514, postalCode: "87056" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87185" },
    { lng: -103.61576, lat: 34.215025, postalCode: "88118" },
    { lng: -107.44137, lat: 35.05463, postalCode: "87038" },
    { lng: -106.805867, lat: 32.267993, postalCode: "88046" },
    { lng: -105.91873, lat: 35.434943, postalCode: "87540" },
    { lng: -106.6583, lat: 36.195444, postalCode: "87012" },
    { lng: -103.778184, lat: 34.916697, postalCode: "88433" },
    { lng: -104.45128, lat: 32.65886, postalCode: "88254" },
    { lng: -103.16616, lat: 32.70778, postalCode: "88240" },
    { lng: -106.36553, lat: 34.783472, postalCode: "87061" },
    { lng: -106.7541, lat: 32.279944, postalCode: "88003" },
    { lng: -106.7648, lat: 34.646779, postalCode: "87002" },
    { lng: -103.20194, lat: 35.890633, postalCode: "88410" },
    { lng: -105.67963, lat: 36.377149, postalCode: "87557" },
    { lng: -107.63451, lat: 35.087772, postalCode: "87049" },
    { lng: -106.08036, lat: 35.990548, postalCode: "87532" },
    { lng: -109.031853, lat: 35.902329, postalCode: "87378" },
    { lng: -106.007499, lat: 35.893597, postalCode: "87504" },
    { lng: -105.3955, lat: 36.705987, postalCode: "87558" },
    { lng: -105.29165, lat: 36.595247, postalCode: "87718" },
    { lng: -107.87907, lat: 36.579374, postalCode: "87413" },
    { lng: -105.01969, lat: 33.267631, postalCode: "88343" },
    { lng: -105.91512, lat: 33.001397, postalCode: "88337" },
    { lng: -108.53985, lat: 31.859178, postalCode: "88009" },
    { lng: -106.672872, lat: 35.044339, postalCode: "87196" },
    { lng: -108.15086, lat: 32.815317, postalCode: "88036" },
    { lng: -103.35459, lat: 33.931311, postalCode: "88115" },
    { lng: -108.05889, lat: 33.934215, postalCode: "87821" },
    { lng: -105.94533, lat: 35.997094, postalCode: "87522" },
    { lng: -106.71879, lat: 34.460462, postalCode: "87062" },
    { lng: -108.76657, lat: 35.597972, postalCode: "87317" },
    { lng: -105.10727, lat: 36.197152, postalCode: "87734" },
    { lng: -105.97926, lat: 35.616662, postalCode: "87505" },
    { lng: -104.679611, lat: 35.456288, postalCode: "88439" },
    { lng: -105.67244, lat: 36.534541, postalCode: "87513" },
    { lng: -106.72283, lat: 32.149321, postalCode: "88044" },
    { lng: -104.23902, lat: 36.132502, postalCode: "87730" },
    { lng: -106.74258, lat: 35.051247, postalCode: "87121" },
    { lng: -108.71665, lat: 36.119846, postalCode: "87364" },
    { lng: -106.578513, lat: 36.465416, postalCode: "87554" },
    { lng: -105.2258, lat: 35.751778, postalCode: "87745" },
    { lng: -104.38693, lat: 36.78767, postalCode: "87740" },
    { lng: -106.34845, lat: 35.612925, postalCode: "87072" },
    { lng: -105.34137, lat: 35.971938, postalCode: "87732" },
    { lng: -107.80037, lat: 36.17798, postalCode: "87037" },
    { lng: -105.99936, lat: 35.755312, postalCode: "87501" },
    { lng: -106.03034, lat: 34.032895, postalCode: "87011" },
    { lng: -106.80927, lat: 34.511223, postalCode: "87006" },
    { lng: -106.70536, lat: 34.786133, postalCode: "87031" },
    { lng: -105.33238, lat: 34.156361, postalCode: "88318" },
    { lng: -105.74651, lat: 36.126615, postalCode: "87521" },
    { lng: -106.786259, lat: 32.305193, postalCode: "88006" },
    { lng: -106.820075, lat: 32.41815, postalCode: "88058" },
    { lng: -108.3674, lat: 36.763088, postalCode: "87417" },
    { lng: -108.90341, lat: 32.802474, postalCode: "88055" },
    { lng: -105.24877, lat: 36.363506, postalCode: "87710" },
    { lng: -105.41326, lat: 33.722955, postalCode: "88316" },
    { lng: -104.41778, lat: 35.014908, postalCode: "88417" },
    { lng: -116.99148, lat: 44.057074, postalCode: "97914" },
    { lng: -117.7115, lat: 44.032437, postalCode: "97920" },
    { lng: -117.45677, lat: 44.207973, postalCode: "97909" },
    { lng: -117.33282, lat: 44.029403, postalCode: "97918" },
    { lng: -117.62629, lat: 44.187379, postalCode: "97903" },
    { lng: -118.07394, lat: 43.722896, postalCode: "97911" },
    { lng: -117.07192, lat: 43.661247, postalCode: "97901" },
    { lng: -117.57157, lat: 43.875636, postalCode: "97906" },
    { lng: -117.497988, lat: 42.915641, postalCode: "97902" },
    { lng: -118.098586, lat: 43.509919, postalCode: "97917" },
    { lng: -117.9161, lat: 44.30411, postalCode: "97908" },
    { lng: -101.94355, lat: 43.654636, postalCode: "57750" },
    { lng: -103.08527, lat: 44.144225, postalCode: "57706" },
    { lng: -103.22927, lat: 43.178182, postalCode: "57763" },
    { lng: -102.78887, lat: 44.133058, postalCode: "57761" },
    { lng: -103.86974, lat: 44.492688, postalCode: "57799" },
    { lng: -102.07873, lat: 44.042842, postalCode: "57775" },
    { lng: -102.25384, lat: 45.690446, postalCode: "57653" },
    { lng: -102.49502, lat: 43.24449, postalCode: "57756" },
    { lng: -103.33998, lat: 44.236634, postalCode: "57769" },
    { lng: -103.07698, lat: 43.796169, postalCode: "57744" },
    { lng: -102.38093, lat: 43.331433, postalCode: "57772" },
    { lng: -103.87853, lat: 44.48837, postalCode: "57783" },
    { lng: -103.46597, lat: 44.409616, postalCode: "57785" },
    { lng: -100.879214, lat: 45.098685, postalCode: "57630" },
    { lng: -101.63974, lat: 45.103367, postalCode: "57623" },
    { lng: -102.77414, lat: 43.257154, postalCode: "57764" },
    { lng: -100.51908, lat: 45.68838, postalCode: "57658" },
    { lng: -103.23035, lat: 43.364717, postalCode: "57766" },
    { lng: -103.53733, lat: 44.197255, postalCode: "57759" },
    { lng: -103.01489, lat: 45.849098, postalCode: "57650" },
    { lng: -101.52963, lat: 43.829457, postalCode: "57543" },
    { lng: -102.57677, lat: 44.560996, postalCode: "57737" },
    { lng: -101.71251, lat: 44.036966, postalCode: "57567" },
    { lng: -102.47618, lat: 44.067091, postalCode: "57791" },
    { lng: -101.19002, lat: 44.159861, postalCode: "57552" },
    { lng: -101.18538, lat: 44.926725, postalCode: "57625" },
    { lng: -102.58976, lat: 44.110334, postalCode: "57767" },
    { lng: -102.78168, lat: 45.033915, postalCode: "57758" },
    { lng: -100.43382, lat: 44.326157, postalCode: "57532" },
    { lng: -103.317149, lat: 44.076106, postalCode: "57709" },
    { lng: -102.41628, lat: 43.726261, postalCode: "57780" },
    { lng: -101.154581, lat: 45.708402, postalCode: "57659" },
    { lng: -102.70446, lat: 45.768967, postalCode: "57640" },
    { lng: -100.85342, lat: 45.198748, postalCode: "57661" },
    { lng: -102.76873, lat: 44.619045, postalCode: "57787" },
    { lng: -102.45764, lat: 44.735001, postalCode: "57777" },
    { lng: -101.67309, lat: 43.552017, postalCode: "57577" },
    { lng: -102.41764, lat: 44.939762, postalCode: "57765" },
    { lng: -101.26996, lat: 43.839641, postalCode: "57521" },
    { lng: -101.354116, lat: 45.924062, postalCode: "57671" },
    { lng: -103.21335, lat: 44.085288, postalCode: "57701" },
    { lng: -101.98721, lat: 44.59203, postalCode: "57748" },
    { lng: -103.527757, lat: 43.239655, postalCode: "57782" },
    { lng: -101.42207, lat: 45.03794, postalCode: "57636" },
    { lng: -101.0562, lat: 45.354993, postalCode: "57656" },
    { lng: -102.41683, lat: 44.562488, postalCode: "57792" },
    { lng: -103.41721, lat: 43.892254, postalCode: "57751" },
    { lng: -103.284511, lat: 44.090788, postalCode: "57700" },
    { lng: -103.13061, lat: 44.05164, postalCode: "57703" },
    { lng: -103.5051, lat: 43.401616, postalCode: "57747" },
    { lng: -102.02661, lat: 44.947869, postalCode: "57626" },
    { lng: -100.88176, lat: 45.51978, postalCode: "57657" },
    { lng: -101.568656, lat: 44.992051, postalCode: "57629" },
    { lng: -101.318, lat: 45.869314, postalCode: "57641" },
    { lng: -102.23778, lat: 44.018548, postalCode: "57790" },
    { lng: -103.62797, lat: 43.965949, postalCode: "57745" },
    { lng: -103.33329, lat: 44.173449, postalCode: "57718" },
    { lng: -101.65737, lat: 43.223447, postalCode: "57551" },
    { lng: -100.56202, lat: 45.125932, postalCode: "57652" },
    { lng: -101.48462, lat: 45.831561, postalCode: "57660" },
    { lng: -101.63569, lat: 44.478744, postalCode: "57553" },
    { lng: -100.879214, lat: 45.098685, postalCode: "57647" },
    { lng: -102.5039, lat: 45.454454, postalCode: "57620" },
    { lng: -103.24356, lat: 43.682883, postalCode: "57738" },
    { lng: -103.8513, lat: 44.079794, postalCode: "57778" },
    { lng: -101.07093, lat: 45.764511, postalCode: "57621" },
    { lng: -101.154581, lat: 45.708402, postalCode: "57639" },
    { lng: -101.85821, lat: 45.814856, postalCode: "57634" },
    { lng: -103.70735, lat: 43.156481, postalCode: "57774" },
    { lng: -102.85815, lat: 45.511837, postalCode: "57649" },
    { lng: -103.70916, lat: 44.338702, postalCode: "57732" },
    { lng: -101.7111, lat: 45.884074, postalCode: "57645" },
    { lng: -103.63631, lat: 43.789695, postalCode: "57730" },
    { lng: -103.75465, lat: 44.578116, postalCode: "57779" },
    { lng: -103.58054, lat: 45.547869, postalCode: "57720" },
    { lng: -102.17671, lat: 43.389365, postalCode: "57752" },
    { lng: -102.41529, lat: 44.338785, postalCode: "57736" },
    { lng: -101.53458, lat: 44.617992, postalCode: "57622" },
    { lng: -100.77282, lat: 45.796863, postalCode: "57642" },
    { lng: -102.236971, lat: 43.994049, postalCode: "57796" },
    { lng: -102.09713, lat: 44.308909, postalCode: "57729" },
    { lng: -101.154581, lat: 45.708402, postalCode: "57643" },
    { lng: -102.13021, lat: 45.402823, postalCode: "57644" },
    { lng: -101.92918, lat: 43.31492, postalCode: "57714" },
    { lng: -103.83118, lat: 44.312922, postalCode: "57754" },
    { lng: -103.92593, lat: 45.616269, postalCode: "57724" },
    { lng: -102.57323, lat: 43.046493, postalCode: "57770" },
    { lng: -103.90273, lat: 43.424499, postalCode: "57735" },
    { lng: -103.455428, lat: 44.40932, postalCode: "57741" },
    { lng: -101.44983, lat: 45.448377, postalCode: "57633" },
    { lng: -101.12947, lat: 44.545795, postalCode: "57537" },
    { lng: -102.8122, lat: 43.940637, postalCode: "57725" },
    { lng: -102.98607, lat: 43.473186, postalCode: "57722" },
    { lng: -103.420131, lat: 44.271094, postalCode: "57708" },
    { lng: -101.3747, lat: 43.126441, postalCode: "57574" },
    { lng: -103.68084, lat: 44.65371, postalCode: "57742" },
    { lng: -103.14501, lat: 45.414346, postalCode: "57651" },
    { lng: -103.29412, lat: 45.869257, postalCode: "57755" },
    { lng: -101.20322, lat: 45.55964, postalCode: "57628" },
    { lng: -100.45819, lat: 45.472087, postalCode: "57601" },
    { lng: -103.32109, lat: 44.854326, postalCode: "57760" },
    { lng: -103.06315, lat: 44.122693, postalCode: "57719" },
    { lng: -102.16756, lat: 43.134526, postalCode: "57716" },
    { lng: -103.493668, lat: 45.578941, postalCode: "57776" },
    { lng: -103.62933, lat: 44.472475, postalCode: "57793" },
    { lng: -103.34302, lat: 44.052788, postalCode: "57702" },
    { lng: -103.83095, lat: 44.768396, postalCode: "57717" },
    { lng: -101.51685, lat: 43.501449, postalCode: "57547" },
    { lng: -103.59186, lat: 44.688572, postalCode: "57762" },
    { lng: -103.36866, lat: 44.60302, postalCode: "57788" },
    { lng: -101.077412, lat: 45.426656, postalCode: "57565" },
    { lng: -103.59517, lat: 43.609557, postalCode: "57773" },
    { lng: -102.36897, lat: 43.138184, postalCode: "57794" },
    { lng: -106.29828, lat: 31.766355, postalCode: "79936" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79992" },
    { lng: -106.09325, lat: 31.852355, postalCode: "79938" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88560" },
    { lng: -106.382757, lat: 31.799275, postalCode: "79978" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79960" },
    { lng: -105.30882, lat: 31.820395, postalCode: "79847" },
    { lng: -103.597991, lat: 32.624796, postalCode: "79841" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88586" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79955" },
    { lng: -106.461405, lat: 31.821439, postalCode: "79920" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88555" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88548" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88531" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79994" },
    { lng: -106.36957, lat: 31.743038, postalCode: "79915" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79988" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79980" },
    { lng: -106.4945, lat: 31.775458, postalCode: "79902" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88543" },
    { lng: -106.2133, lat: 31.570185, postalCode: "79836" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79986" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79940" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79945" },
    { lng: -106.48011, lat: 31.759558, postalCode: "79901" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88538" },
    { lng: -106.41827, lat: 31.901737, postalCode: "79924" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88542" },
    { lng: -106.36353, lat: 31.782408, postalCode: "79925" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79911" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88584" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79989" },
    { lng: -105.34084, lat: 31.182009, postalCode: "79851" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88566" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79951" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88557" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88535" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88521" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79953" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88525" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79976" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79987" },
    { lng: -106.151727, lat: 31.509199, postalCode: "79858" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79985" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88546" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88581" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79929" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79942" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88567" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79947" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88578" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88571" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88585" },
    { lng: -106.572393, lat: 31.93728, postalCode: "79913" },
    { lng: -106.59982, lat: 31.865696, postalCode: "79932" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88576" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88515" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88530" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88513" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79958" },
    { lng: -106.43081, lat: 31.809263, postalCode: "79906" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88523" },
    { lng: -105.6653, lat: 31.270689, postalCode: "79839" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88570" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79990" },
    { lng: -106.43047, lat: 31.768758, postalCode: "79905" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88565" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88572" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79996" },
    { lng: -106.197528, lat: 31.674736, postalCode: "79928" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88532" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88568" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88516" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88541" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88583" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79941" },
    { lng: -106.60469, lat: 31.977553, postalCode: "79821" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88580" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88536" },
    { lng: -106.42402, lat: 31.943633, postalCode: "79934" },
    { lng: -106.59577, lat: 31.932926, postalCode: "79835" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88533" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79944" },
    { lng: -106.504843, lat: 31.770458, postalCode: "79968" },
    { lng: -106.09049, lat: 31.447738, postalCode: "79853" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88540" },
    { lng: -106.287923, lat: 31.744353, postalCode: "79916" },
    { lng: -106.32501, lat: 31.912449, postalCode: "79908" },
    { lng: -106.54487, lat: 31.848055, postalCode: "79912" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88589" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79984" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79914" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88526" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79950" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88514" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88587" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79926" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88545" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88510" },
    { lng: -106.44181, lat: 31.852156, postalCode: "79904" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79931" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88547" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79977" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88577" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79982" },
    { lng: -105.19353, lat: 31.937024, postalCode: "79837" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88588" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88517" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79917" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79991" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88539" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88559" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79952" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88582" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88574" },
    { lng: -106.2672, lat: 31.577344, postalCode: "79849" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79993" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88563" },
    { lng: -106.390656, lat: 31.831782, postalCode: "79918" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79997" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79949" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88595" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88554" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79948" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88518" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88579" },
    { lng: -106.33705, lat: 31.784541, postalCode: "79935" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79973" },
    { lng: -106.20788, lat: 31.684338, postalCode: "79927" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79998" },
    { lng: -106.45758, lat: 31.803457, postalCode: "79930" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88561" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88573" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79974" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88550" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79961" },
    { lng: -106.32749, lat: 31.70831, postalCode: "79907" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88569" },
    { lng: -106.44583, lat: 31.786221, postalCode: "79903" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88511" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88534" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79975" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79966" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88553" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88529" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79943" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79923" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79910" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88520" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88549" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88512" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79983" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79999" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79937" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88556" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88528" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88519" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88590" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88524" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88527" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88575" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79995" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88544" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88562" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79946" },
    { lng: -106.15381, lat: 31.490587, postalCode: "79838" },
    { lng: -106.299987, lat: 31.694842, postalCode: "88558" },
    { lng: -106.299987, lat: 31.694842, postalCode: "79954" },
    { lng: -111.8158, lat: 40.625621, postalCode: "84121" },
    { lng: -111.505365, lat: 40.66797, postalCode: "84068" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84122" },
    { lng: -111.64439, lat: 40.226302, postalCode: "84606" },
    { lng: -110.87184, lat: 39.350034, postalCode: "84518" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84126" },
    { lng: -112.115984, lat: 40.963547, postalCode: "84089" },
    { lng: -113.627239, lat: 37.692401, postalCode: "84756" },
    { lng: -112.08849, lat: 37.576034, postalCode: "84718" },
    { lng: -112.05826, lat: 38.707639, postalCode: "84711" },
    { lng: -111.888121, lat: 40.892995, postalCode: "84016" },
    { lng: -111.88938, lat: 40.718113, postalCode: "84115" },
    { lng: -113.8136, lat: 37.179347, postalCode: "84733" },
    { lng: -111.94275, lat: 41.492466, postalCode: "84324" },
    { lng: -111.97354, lat: 38.070706, postalCode: "84712" },
    { lng: -110.78678, lat: 39.578473, postalCode: "84501" },
    { lng: -111.54921, lat: 38.338787, postalCode: "84715" },
    { lng: -111.762115, lat: 40.768996, postalCode: "84158" },
    { lng: -112.05093, lat: 41.173226, postalCode: "84067" },
    { lng: -112.40819, lat: 37.777031, postalCode: "84759" },
    { lng: -111.67126, lat: 39.458682, postalCode: "84667" },
    { lng: -109.82903, lat: 40.294693, postalCode: "84026" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84142" },
    { lng: -111.93942, lat: 40.725946, postalCode: "84199" },
    { lng: -111.9271, lat: 41.086894, postalCode: "84040" },
    { lng: -111.85657, lat: 40.706246, postalCode: "84106" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84139" },
    { lng: -111.9675, lat: 41.165551, postalCode: "84405" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84150" },
    { lng: -111.948474, lat: 41.195593, postalCode: "84408" },
    { lng: -113.3695, lat: 37.656843, postalCode: "84721" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84131" },
    { lng: -111.92835, lat: 38.917381, postalCode: "84620" },
    { lng: -112.31666, lat: 38.951694, postalCode: "84631" },
    { lng: -111.825, lat: 41.682506, postalCode: "84326" },
    { lng: -111.92226, lat: 40.659514, postalCode: "84123" },
    { lng: -112.93517, lat: 37.800295, postalCode: "84772" },
    { lng: -111.50264, lat: 40.996895, postalCode: "84033" },
    { lng: -111.58299, lat: 39.526133, postalCode: "84646" },
    { lng: -110.58819, lat: 39.661026, postalCode: "84539" },
    { lng: -112.32322, lat: 41.909031, postalCode: "84331" },
    { lng: -111.42865, lat: 39.701506, postalCode: "84629" },
    { lng: -110.32673, lat: 40.45679, postalCode: "84002" },
    { lng: -111.84775, lat: 39.050838, postalCode: "84621" },
    { lng: -112.23014, lat: 38.238084, postalCode: "84740" },
    { lng: -110.560697, lat: 39.640779, postalCode: "84527" },
    { lng: -111.956721, lat: 41.255285, postalCode: "84412" },
    { lng: -111.956721, lat: 41.255285, postalCode: "84409" },
    { lng: -113.33562, lat: 37.237225, postalCode: "84746" },
    { lng: -111.69904, lat: 39.111512, postalCode: "84643" },
    { lng: -111.70449, lat: 41.288679, postalCode: "84317" },
    { lng: -111.00605, lat: 39.222858, postalCode: "84513" },
    { lng: -112.11431, lat: 37.162224, postalCode: "84741" },
    { lng: -113.94372, lat: 40.105506, postalCode: "84034" },
    { lng: -111.97889, lat: 40.594222, postalCode: "84088" },
    { lng: -113.203633, lat: 37.522753, postalCode: "84742" },
    { lng: -111.973275, lat: 41.944253, postalCode: "84308" },
    { lng: -110.160534, lat: 38.995483, postalCode: "84252" },
    { lng: -110.95449, lat: 40.156858, postalCode: "84027" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84157" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84171" },
    { lng: -111.62844, lat: 39.633383, postalCode: "84632" },
    { lng: -112.20857, lat: 37.638578, postalCode: "84764" },
    { lng: -111.94018, lat: 40.790309, postalCode: "84116" },
    { lng: -112.07946, lat: 37.650853, postalCode: "84776" },
    { lng: -111.7162, lat: 40.338552, postalCode: "84042" },
    { lng: -111.43798, lat: 39.545778, postalCode: "84647" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84185" },
    { lng: -110.35905, lat: 38.983076, postalCode: "84525" },
    { lng: -111.96366, lat: 41.314075, postalCode: "84414" },
    { lng: -112.83945, lat: 37.886163, postalCode: "84761" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84190" },
    { lng: -113.6718, lat: 37.798268, postalCode: "84714" },
    { lng: -110.27969, lat: 40.395531, postalCode: "84001" },
    { lng: -109.70579, lat: 40.176006, postalCode: "84063" },
    { lng: -109.44491, lat: 39.210907, postalCode: "84540" },
    { lng: -112.01151, lat: 41.118815, postalCode: "84056" },
    { lng: -112.93204, lat: 38.217377, postalCode: "84752" },
    { lng: -111.85302, lat: 40.03148, postalCode: "84655" },
    { lng: -109.81677, lat: 40.401201, postalCode: "84039" },
    { lng: -111.956811, lat: 40.676839, postalCode: "84184" },
    { lng: -110.34199, lat: 39.569596, postalCode: "84520" },
    { lng: -111.57601, lat: 39.230151, postalCode: "84642" },
    { lng: -111.86371, lat: 40.759246, postalCode: "84102" },
    { lng: -111.99144, lat: 37.565311, postalCode: "84736" },
    { lng: -111.80902, lat: 40.558748, postalCode: "84092" },
    { lng: -111.83364, lat: 40.763322, postalCode: "84113" },
    { lng: -112.79836, lat: 37.261273, postalCode: "84755" },
    { lng: -111.94431, lat: 39.47178, postalCode: "84639" },
    { lng: -111.83805, lat: 40.76784, postalCode: "84112" },
    { lng: -111.98346, lat: 41.224911, postalCode: "84401" },
    { lng: -111.99026, lat: 41.867966, postalCode: "84327" },
    { lng: -111.87682, lat: 40.927244, postalCode: "84014" },
    { lng: -111.66273, lat: 40.728579, postalCode: "84152" },
    { lng: -112.14671, lat: 41.791414, postalCode: "84334" },
    { lng: -111.86044, lat: 40.737132, postalCode: "84105" },
    { lng: -112.45965, lat: 41.773176, postalCode: "84316" },
    { lng: -111.88229, lat: 39.692424, postalCode: "84648" },
    { lng: -113.7085, lat: 38.970536, postalCode: "84728" },
    { lng: -111.97566, lat: 40.627668, postalCode: "84084" },
    { lng: -111.53153, lat: 41.084479, postalCode: "84018" },
    { lng: -111.67612, lat: 38.41744, postalCode: "84747" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84189" },
    { lng: -109.11245, lat: 37.198216, postalCode: "84510" },
    { lng: -112.13957, lat: 41.875803, postalCode: "84330" },
    { lng: -111.99738, lat: 41.832776, postalCode: "84304" },
    { lng: -112.08397, lat: 38.765929, postalCode: "84701" },
    { lng: -111.72117, lat: 40.025007, postalCode: "84651" },
    { lng: -111.83448, lat: 40.594948, postalCode: "84093" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84125" },
    { lng: -111.88847, lat: 40.988347, postalCode: "84025" },
    { lng: -113.44562, lat: 41.689359, postalCode: "84329" },
    { lng: -111.67485, lat: 40.301444, postalCode: "84097" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84135" },
    { lng: -111.783783, lat: 41.684253, postalCode: "84323" },
    { lng: -109.87476, lat: 40.446132, postalCode: "84076" },
    { lng: -111.37752, lat: 40.88097, postalCode: "84017" },
    { lng: -110.9778, lat: 39.343592, postalCode: "84528" },
    { lng: -112.55601, lat: 37.61127, postalCode: "84735" },
    { lng: -112.64419, lat: 37.26947, postalCode: "84758" },
    { lng: -109.505593, lat: 40.443106, postalCode: "84008" },
    { lng: -112.05538, lat: 41.368481, postalCode: "84340" },
    { lng: -111.0022, lat: 38.340186, postalCode: "84775" },
    { lng: -112.09859, lat: 40.288953, postalCode: "84013" },
    { lng: -111.94607, lat: 41.935384, postalCode: "84338" },
    { lng: -112.14804, lat: 39.240818, postalCode: "84656" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84091" },
    { lng: -112.978418, lat: 37.004364, postalCode: "84784" },
    { lng: -111.33259, lat: 40.511413, postalCode: "84032" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84114" },
    { lng: -110.03772, lat: 37.655431, postalCode: "84533" },
    { lng: -111.890819, lat: 40.767946, postalCode: "84144" },
    { lng: -111.90581, lat: 40.843012, postalCode: "84054" },
    { lng: -113.66976, lat: 37.359887, postalCode: "84782" },
    { lng: -111.740853, lat: 39.133489, postalCode: "84665" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84145" },
    { lng: -111.92716, lat: 41.641328, postalCode: "84339" },
    { lng: -111.16518, lat: 39.36696, postalCode: "84537" },
    { lng: -111.98379, lat: 38.764683, postalCode: "84730" },
    { lng: -113.66144, lat: 37.287005, postalCode: "84783" },
    { lng: -111.49837, lat: 40.518775, postalCode: "84049" },
    { lng: -112.17088, lat: 37.6153, postalCode: "84717" },
    { lng: -111.204, lat: 40.80725, postalCode: "84055" },
    { lng: -111.88394, lat: 40.659997, postalCode: "84107" },
    { lng: -112.70637, lat: 38.811791, postalCode: "84637" },
    { lng: -113.56365, lat: 41.307779, postalCode: "84313" },
    { lng: -111.8096, lat: 41.701304, postalCode: "84332" },
    { lng: -112.04427, lat: 41.126476, postalCode: "84015" },
    { lng: -109.547839, lat: 40.160179, postalCode: "84030" },
    { lng: -112.27113, lat: 39.532384, postalCode: "84638" },
    { lng: -110.46935, lat: 40.352343, postalCode: "84073" },
    { lng: -112.82508, lat: 38.218885, postalCode: "84731" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84110" },
    { lng: -112.1281, lat: 40.563896, postalCode: "84006" },
    { lng: -109.49392, lat: 38.572778, postalCode: "84532" },
    { lng: -111.89623, lat: 39.949142, postalCode: "84633" },
    { lng: -111.35283, lat: 40.732935, postalCode: "84061" },
    { lng: -111.80511, lat: 39.114649, postalCode: "84622" },
    { lng: -111.88029, lat: 38.510659, postalCode: "84744" },
    { lng: -111.956721, lat: 41.255285, postalCode: "84402" },
    { lng: -111.965867, lat: 41.238547, postalCode: "84407" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84147" },
    { lng: -111.71141, lat: 41.059771, postalCode: "84050" },
    { lng: -112.5433, lat: 39.349079, postalCode: "84624" },
    { lng: -111.42743, lat: 40.959532, postalCode: "84024" },
    { lng: -112.063983, lat: 41.346348, postalCode: "84346" },
    { lng: -112.066211, lat: 41.142472, postalCode: "84915" },
    { lng: -111.92215, lat: 38.974463, postalCode: "84652" },
    { lng: -111.65408, lat: 40.10637, postalCode: "84660" },
    { lng: -111.53674, lat: 40.726899, postalCode: "84098" },
    { lng: -111.94077, lat: 40.750628, postalCode: "84104" },
    { lng: -111.889319, lat: 40.769046, postalCode: "84133" },
    { lng: -111.80904, lat: 41.780998, postalCode: "84341" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84127" },
    { lng: -111.16584, lat: 41.656963, postalCode: "84064" },
    { lng: -112.61182, lat: 37.351773, postalCode: "84729" },
    { lng: -111.20141, lat: 40.625883, postalCode: "84036" },
    { lng: -111.99591, lat: 40.650496, postalCode: "84118" },
    { lng: -113.25581, lat: 37.219954, postalCode: "84745" },
    { lng: -111.6841, lat: 37.76737, postalCode: "84726" },
    { lng: -111.87294, lat: 40.514843, postalCode: "84020" },
    { lng: -111.85402, lat: 39.859432, postalCode: "84645" },
    { lng: -111.837567, lat: 40.756796, postalCode: "84148" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84090" },
    { lng: -111.956721, lat: 41.255285, postalCode: "84244" },
    { lng: -112.53409, lat: 37.474045, postalCode: "84710" },
    { lng: -111.71067, lat: 40.278086, postalCode: "84058" },
    { lng: -110.66366, lat: 38.289429, postalCode: "84734" },
    { lng: -113.88673, lat: 37.873645, postalCode: "84753" },
    { lng: -110.02525, lat: 40.440294, postalCode: "84053" },
    { lng: -111.626081, lat: 40.203908, postalCode: "84603" },
    { lng: -113.49671, lat: 37.144146, postalCode: "84780" },
    { lng: -112.71064, lat: 37.48474, postalCode: "84762" },
    { lng: -113.013922, lat: 37.186249, postalCode: "84767" },
    { lng: -112.04004, lat: 40.694746, postalCode: "84128" },
    { lng: -113.76108, lat: 37.533546, postalCode: "84725" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84137" },
    { lng: -111.73333, lat: 40.38584, postalCode: "84062" },
    { lng: -111.898438, lat: 40.858481, postalCode: "84910" },
    { lng: -111.70561, lat: 40.311854, postalCode: "84057" },
    { lng: -111.93004, lat: 41.032893, postalCode: "84037" },
    { lng: -110.46066, lat: 40.169399, postalCode: "84021" },
    { lng: -112.32369, lat: 39.370295, postalCode: "84649" },
    { lng: -111.85897, lat: 41.833371, postalCode: "84335" },
    { lng: -112.45813, lat: 40.366554, postalCode: "84069" },
    { lng: -111.88704, lat: 40.614997, postalCode: "84047" },
    { lng: -112.14995, lat: 39.855141, postalCode: "84628" },
    { lng: -113.67582, lat: 37.164561, postalCode: "84738" },
    { lng: -110.46651, lat: 40.535823, postalCode: "84031" },
    { lng: -112.22327, lat: 41.688721, postalCode: "84337" },
    { lng: -112.06279, lat: 41.086069, postalCode: "84075" },
    { lng: -111.99899, lat: 40.688246, postalCode: "84120" },
    { lng: -111.56665, lat: 40.123394, postalCode: "84664" },
    { lng: -111.86587, lat: 41.968475, postalCode: "84320" },
    { lng: -111.13617, lat: 39.109059, postalCode: "84523" },
    { lng: -111.05489, lat: 39.088224, postalCode: "84516" },
    { lng: -112.10675, lat: 41.172593, postalCode: "84315" },
    { lng: -111.59577, lat: 40.168205, postalCode: "84663" },
    { lng: -111.81123, lat: 40.744646, postalCode: "84108" },
    { lng: -111.84549, lat: 41.630767, postalCode: "84319" },
    { lng: -111.81535, lat: 41.801888, postalCode: "84318" },
    { lng: -111.943, lat: 38.834889, postalCode: "84657" },
    { lng: -113.042525, lat: 37.160144, postalCode: "84763" },
    { lng: -113.16557, lat: 37.75164, postalCode: "84720" },
    { lng: -112.6926, lat: 38.35906, postalCode: "84713" },
    { lng: -111.53072, lat: 38.28419, postalCode: "84773" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84165" },
    { lng: -114.0093, lat: 40.773372, postalCode: "84083" },
    { lng: -111.47818, lat: 39.461825, postalCode: "84662" },
    { lng: -112.12561, lat: 41.615276, postalCode: "84301" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84134" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84130" },
    { lng: -112.007924, lat: 41.244261, postalCode: "84201" },
    { lng: -113.51806, lat: 37.391737, postalCode: "84781" },
    { lng: -112.70012, lat: 40.212316, postalCode: "84022" },
    { lng: -111.95239, lat: 40.696596, postalCode: "84119" },
    { lng: -112.30373, lat: 41.535714, postalCode: "84307" },
    { lng: -112.91032, lat: 41.792609, postalCode: "84336" },
    { lng: -112.09427, lat: 41.644833, postalCode: "84314" },
    { lng: -109.93383, lat: 37.183505, postalCode: "84531" },
    { lng: -111.40328, lat: 41.91021, postalCode: "84028" },
    { lng: -112.33819, lat: 40.336367, postalCode: "84071" },
    { lng: -111.93087, lat: 40.771345, postalCode: "84140" },
    { lng: -110.78956, lat: 39.367253, postalCode: "84521" },
    { lng: -111.90909, lat: 40.888711, postalCode: "84087" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84153" },
    { lng: -112.0433, lat: 41.938629, postalCode: "84305" },
    { lng: -112.2208, lat: 38.439428, postalCode: "84750" },
    { lng: -111.96327, lat: 40.503746, postalCode: "84065" },
    { lng: -111.838541, lat: 40.772743, postalCode: "84132" },
    { lng: -113.476182, lat: 37.309231, postalCode: "84771" },
    { lng: -112.31673, lat: 40.105195, postalCode: "84080" },
    { lng: -111.90077, lat: 40.770248, postalCode: "84180" },
    { lng: -109.15445, lat: 38.236886, postalCode: "84530" },
    { lng: -109.547839, lat: 40.160179, postalCode: "84079" },
    { lng: -111.49362, lat: 40.648541, postalCode: "84060" },
    { lng: -111.89875, lat: 40.754746, postalCode: "84101" },
    { lng: -111.77838, lat: 39.272741, postalCode: "84630" },
    { lng: -111.9101, lat: 38.446294, postalCode: "84732" },
    { lng: -109.74719, lat: 40.978642, postalCode: "84046" },
    { lng: -111.3702, lat: 41.797569, postalCode: "84038" },
    { lng: -110.70128, lat: 40.362986, postalCode: "84072" },
    { lng: -112.29455, lat: 40.560528, postalCode: "84074" },
    { lng: -111.74828, lat: 40.671614, postalCode: "84138" },
    { lng: -111.87491, lat: 40.777746, postalCode: "84103" },
    { lng: -111.94608, lat: 41.188093, postalCode: "84403" },
    { lng: -112.62826, lat: 39.291167, postalCode: "84650" },
    { lng: -113.10279, lat: 37.294867, postalCode: "84779" },
    { lng: -112.115984, lat: 40.963547, postalCode: "84011" },
    { lng: -112.2823, lat: 39.099629, postalCode: "84636" },
    { lng: -112.04736, lat: 41.508605, postalCode: "84302" },
    { lng: -111.83797, lat: 40.662797, postalCode: "84117" },
    { lng: -111.96088, lat: 40.561598, postalCode: "84095" },
    { lng: -111.22578, lat: 41.376622, postalCode: "84086" },
    { lng: -111.6491, lat: 40.280454, postalCode: "84604" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84141" },
    { lng: -111.8222, lat: 40.680064, postalCode: "84124" },
    { lng: -112.15829, lat: 41.76645, postalCode: "84312" },
    { lng: -112.08846, lat: 41.714101, postalCode: "84309" },
    { lng: -113.64916, lat: 37.136937, postalCode: "84765" },
    { lng: -111.41809, lat: 40.371817, postalCode: "84082" },
    { lng: -111.85047, lat: 39.193513, postalCode: "84634" },
    { lng: -111.72893, lat: 38.915722, postalCode: "84654" },
    { lng: -109.52773, lat: 37.158935, postalCode: "84512" },
    { lng: -112.68799, lat: 37.911834, postalCode: "84760" },
    { lng: -111.868898, lat: 41.717612, postalCode: "84821" },
    { lng: -111.79449, lat: 40.394235, postalCode: "84003" },
    { lng: -113.27491, lat: 37.250749, postalCode: "84774" },
    { lng: -111.17706, lat: 38.889362, postalCode: "84522" },
    { lng: -112.25563, lat: 38.180655, postalCode: "84723" },
    { lng: -112.79181, lat: 39.314609, postalCode: "84635" },
    { lng: -113.476182, lat: 37.309231, postalCode: "84791" },
    { lng: -111.79102, lat: 41.508503, postalCode: "84328" },
    { lng: -111.5911, lat: 38.386384, postalCode: "84749" },
    { lng: -109.28709, lat: 37.237899, postalCode: "84534" },
    { lng: -111.89052, lat: 40.366648, postalCode: "84043" },
    { lng: -110.01689, lat: 40.328012, postalCode: "84066" },
    { lng: -112.6698, lat: 40.689007, postalCode: "84029" },
    { lng: -112.36913, lat: 39.522214, postalCode: "84640" },
    { lng: -109.25255, lat: 40.443203, postalCode: "84035" },
    { lng: -111.88361, lat: 40.7547, postalCode: "84111" },
    { lng: -111.57686, lat: 39.465357, postalCode: "84623" },
    { lng: -113.5987, lat: 37.118352, postalCode: "84770" },
    { lng: -111.86217, lat: 40.569598, postalCode: "84094" },
    { lng: -113.1063, lat: 38.348238, postalCode: "84751" },
    { lng: -109.54223, lat: 40.514262, postalCode: "84078" },
    { lng: -112.11338, lat: 41.81571, postalCode: "84311" },
    { lng: -111.900832, lat: 40.786886, postalCode: "84143" },
    { lng: -110.38597, lat: 40.42204, postalCode: "84051" },
    { lng: -111.68006, lat: 40.230954, postalCode: "84601" },
    { lng: -111.732476, lat: 40.356343, postalCode: "84602" },
    { lng: -111.81676, lat: 41.919806, postalCode: "84333" },
    { lng: -110.80479, lat: 39.6866, postalCode: "84529" },
    { lng: -111.76279, lat: 40.465161, postalCode: "84004" },
    { lng: -113.2821, lat: 37.391483, postalCode: "84757" },
    { lng: -110.19776, lat: 40.349644, postalCode: "84007" },
    { lng: -111.99559, lat: 39.92519, postalCode: "84626" },
    { lng: -111.88908, lat: 38.618547, postalCode: "84754" },
    { lng: -111.896571, lat: 41.641189, postalCode: "84322" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84151" },
    { lng: -111.79973, lat: 40.705747, postalCode: "84109" },
    { lng: -113.62554, lat: 37.416058, postalCode: "84722" },
    { lng: -111.81304, lat: 41.364638, postalCode: "84310" },
    { lng: -111.87249, lat: 40.876312, postalCode: "84010" },
    { lng: -111.78042, lat: 41.730637, postalCode: "84321" },
    { lng: -109.46245, lat: 37.562925, postalCode: "84511" },
    { lng: -112.08318, lat: 40.700162, postalCode: "84044" },
    { lng: -112.81081, lat: 37.663896, postalCode: "84719" },
    { lng: -109.46319, lat: 40.881951, postalCode: "84023" },
    { lng: -110.98561, lat: 39.694641, postalCode: "84526" },
    { lng: -111.88821, lat: 40.581595, postalCode: "84070" },
    { lng: -111.591641, lat: 38.399138, postalCode: "84947" },
    { lng: -109.91425, lat: 40.529949, postalCode: "84085" },
    { lng: -111.908297, lat: 40.668068, postalCode: "84170" },
    { lng: -109.30629, lat: 37.886087, postalCode: "84535" },
    { lng: -109.615008, lat: 38.999877, postalCode: "84515" },
    { lng: -111.09991, lat: 37.94512, postalCode: "84716" },
    { lng: -111.97114, lat: 41.721715, postalCode: "84325" },
    { lng: -110.72865, lat: 39.543105, postalCode: "84542" },
    { lng: -112.23422, lat: 38.620071, postalCode: "84739" },
    { lng: -111.5783, lat: 39.353561, postalCode: "84627" },
    { lng: -113.24183, lat: 37.130927, postalCode: "84737" },
    { lng: -112.35173, lat: 38.568765, postalCode: "84766" },
    { lng: -113.55568, lat: 37.075039, postalCode: "84790" },
    { lng: -111.536036, lat: 40.176975, postalCode: "84605" },
    { lng: -111.66405, lat: 40.043907, postalCode: "84653" },
    { lng: -111.97625, lat: 41.07221, postalCode: "84041" },
    { lng: -111.887869, lat: 40.768546, postalCode: "84136" },
    { lng: -112.18455, lat: 38.230935, postalCode: "84743" },
    { lng: -112.01932, lat: 41.270319, postalCode: "84404" },
    { lng: -112.07009, lat: 41.804848, postalCode: "84306" },
    { lng: -112.14565, lat: 38.686793, postalCode: "84724" },
    { lng: -111.956721, lat: 41.255285, postalCode: "84415" },
    { lng: -111.536036, lat: 40.176975, postalCode: "84059" },
    { lng: -110.14823, lat: 40.065834, postalCode: "84052" },
    { lng: -113.032141, lat: 39.06433, postalCode: "84644" },
    { lng: -110.27732, lat: 41.767699, postalCode: "83124" },
    { lng: -109.92672, lat: 43.081275, postalCode: "82925" },
    { lng: -107.119867, lat: 41.778225, postalCode: "82354" },
    { lng: -110.90607, lat: 41.267546, postalCode: "82930" },
    { lng: -105.3549, lat: 41.105595, postalCode: "82052" },
    { lng: -108.963931, lat: 41.631408, postalCode: "82902" },
    { lng: -108.53339, lat: 42.86682, postalCode: "82515" },
    { lng: -104.568128, lat: 43.840033, postalCode: "82715" },
    { lng: -109.82274, lat: 41.587387, postalCode: "82929" },
    { lng: -109.22044, lat: 41.702635, postalCode: "82943" },
    { lng: -107.73911, lat: 42.180259, postalCode: "82322" },
    { lng: -105.58626, lat: 41.344843, postalCode: "82072" },
    { lng: -110.91808, lat: 42.607888, postalCode: "83126" },
    { lng: -110.43728, lat: 41.299766, postalCode: "82933" },
    { lng: -109.70709, lat: 43.557529, postalCode: "82513" },
    { lng: -108.58917, lat: 44.909434, postalCode: "82421" },
    { lng: -109.16304, lat: 41.594542, postalCode: "82901" },
    { lng: -106.807047, lat: 42.966192, postalCode: "82605" },
    { lng: -104.88279, lat: 41.275932, postalCode: "82009" },
    { lng: -110.92196, lat: 42.681301, postalCode: "83110" },
    { lng: -104.22425, lat: 44.776684, postalCode: "82710" },
    { lng: -105.624667, lat: 41.307065, postalCode: "82073" },
    { lng: -104.94156, lat: 42.792664, postalCode: "82224" },
    { lng: -104.71043, lat: 44.062053, postalCode: "82730" },
    { lng: -106.23963, lat: 44.74323, postalCode: "82837" },
    { lng: -104.38969, lat: 41.979994, postalCode: "82243" },
    { lng: -106.75888, lat: 41.475612, postalCode: "82331" },
    { lng: -104.448288, lat: 42.757317, postalCode: "82226" },
    { lng: -105.37303, lat: 42.935874, postalCode: "82633" },
    { lng: -110.89421, lat: 44.890668, postalCode: "82190" },
    { lng: -110.99847, lat: 42.683338, postalCode: "83119" },
    { lng: -105.23842, lat: 44.221574, postalCode: "82727" },
    { lng: -106.37103, lat: 42.842615, postalCode: "82644" },
    { lng: -104.71135, lat: 42.382338, postalCode: "82215" },
    { lng: -107.21937, lat: 44.788759, postalCode: "82844" },
    { lng: -110.40687, lat: 41.890875, postalCode: "83101" },
    { lng: -108.15555, lat: 43.232318, postalCode: "82649" },
    { lng: -104.18805, lat: 41.425769, postalCode: "82050" },
    { lng: -108.44195, lat: 44.436009, postalCode: "82411" },
    { lng: -107.14202, lat: 44.889239, postalCode: "82839" },
    { lng: -106.66284, lat: 41.159654, postalCode: "82325" },
    { lng: -107.67034, lat: 44.632199, postalCode: "82441" },
    { lng: -110.18889, lat: 42.926318, postalCode: "83115" },
    { lng: -104.666365, lat: 41.327351, postalCode: "82006" },
    { lng: -104.661244, lat: 41.219107, postalCode: "82003" },
    { lng: -105.19216, lat: 41.051097, postalCode: "82059" },
    { lng: -108.60194, lat: 42.981091, postalCode: "82510" },
    { lng: -104.92334, lat: 44.453553, postalCode: "82721" },
    { lng: -104.53453, lat: 42.321548, postalCode: "82212" },
    { lng: -106.08209, lat: 44.681337, postalCode: "82831" },
    { lng: -109.97361, lat: 41.610852, postalCode: "82934" },
    { lng: -110.38625, lat: 43.1856, postalCode: "82922" },
    { lng: -110.694002, lat: 43.443851, postalCode: "83025" },
    { lng: -104.767846, lat: 44.525891, postalCode: "82713" },
    { lng: -108.18385, lat: 43.808024, postalCode: "82430" },
    { lng: -104.35784, lat: 43.034269, postalCode: "82225" },
    { lng: -109.21806, lat: 43.428698, postalCode: "82512" },
    { lng: -105.74228, lat: 44.876211, postalCode: "82725" },
    { lng: -107.63702, lat: 44.255087, postalCode: "82428" },
    { lng: -106.15952, lat: 42.828639, postalCode: "82636" },
    { lng: -108.81963, lat: 41.689968, postalCode: "82942" },
    { lng: -105.05069, lat: 42.038694, postalCode: "82201" },
    { lng: -106.2421, lat: 43.410459, postalCode: "82635" },
    { lng: -106.78063, lat: 41.804594, postalCode: "82335" },
    { lng: -105.65207, lat: 41.601379, postalCode: "82051" },
    { lng: -110.919995, lat: 41.261943, postalCode: "82931" },
    { lng: -106.32113, lat: 42.196315, postalCode: "82329" },
    { lng: -106.870963, lat: 44.497609, postalCode: "82840" },
    { lng: -106.14153, lat: 43.551448, postalCode: "82640" },
    { lng: -110.93247, lat: 43.129462, postalCode: "83128" },
    { lng: -109.63919, lat: 41.659162, postalCode: "82935" },
    { lng: -108.92886, lat: 42.971973, postalCode: "82514" },
    { lng: -107.38903, lat: 44.947602, postalCode: "82838" },
    { lng: -106.999195, lat: 41.71694, postalCode: "82615" },
    { lng: -104.35907, lat: 41.209041, postalCode: "82053" },
    { lng: -104.45138, lat: 44.706167, postalCode: "82711" },
    { lng: -106.65945, lat: 43.602698, postalCode: "82639" },
    { lng: -104.97591, lat: 42.489239, postalCode: "82213" },
    { lng: -108.03102, lat: 44.500759, postalCode: "82426" },
    { lng: -104.34107, lat: 41.064212, postalCode: "82054" },
    { lng: -104.666365, lat: 41.327351, postalCode: "82010" },
    { lng: -104.44387, lat: 43.738237, postalCode: "82701" },
    { lng: -105.52661, lat: 44.356533, postalCode: "82716" },
    { lng: -107.71062, lat: 41.347337, postalCode: "82321" },
    { lng: -108.64076, lat: 44.979667, postalCode: "82423" },
    { lng: -105.99071, lat: 41.724691, postalCode: "82083" },
    { lng: -110.97876, lat: 44.04215, postalCode: "83013" },
    { lng: -109.48709, lat: 41.181959, postalCode: "82938" },
    { lng: -107.7646, lat: 42.444748, postalCode: "82310" },
    { lng: -110.57908, lat: 43.639867, postalCode: "83011" },
    { lng: -104.19228, lat: 42.119328, postalCode: "82240" },
    { lng: -105.063337, lat: 42.787675, postalCode: "82229" },
    { lng: -109.61933, lat: 42.75073, postalCode: "82923" },
    { lng: -107.9263, lat: 44.24825, postalCode: "82432" },
    { lng: -106.96782, lat: 44.801249, postalCode: "82801" },
    { lng: -108.86789, lat: 44.789953, postalCode: "82435" },
    { lng: -104.75034, lat: 42.785056, postalCode: "82227" },
    { lng: -110.75714, lat: 43.468383, postalCode: "83001" },
    { lng: -106.67638, lat: 44.301141, postalCode: "82834" },
    { lng: -107.12523, lat: 42.65723, postalCode: "82620" },
    { lng: -104.61635, lat: 43.149226, postalCode: "82222" },
    { lng: -109.40903, lat: 42.026986, postalCode: "82932" },
    { lng: -107.23106, lat: 41.330666, postalCode: "82332" },
    { lng: -107.10625, lat: 44.615453, postalCode: "82833" },
    { lng: -104.80582, lat: 41.101731, postalCode: "82007" },
    { lng: -108.34879, lat: 43.013826, postalCode: "82501" },
    { lng: -104.80208, lat: 41.141281, postalCode: "82001" },
    { lng: -108.86538, lat: 44.718546, postalCode: "82440" },
    { lng: -104.666365, lat: 41.327351, postalCode: "82008" },
    { lng: -105.46188, lat: 41.024827, postalCode: "82084" },
    { lng: -104.37708, lat: 42.121155, postalCode: "82223" },
    { lng: -110.53868, lat: 41.779266, postalCode: "83116" },
    { lng: -110.98262, lat: 42.96067, postalCode: "83127" },
    { lng: -105.91078, lat: 42.796442, postalCode: "82637" },
    { lng: -106.438937, lat: 42.878665, postalCode: "82504" },
    { lng: -105.35878, lat: 43.715486, postalCode: "82732" },
    { lng: -106.76367, lat: 44.602518, postalCode: "82832" },
    { lng: -110.85188, lat: 42.039281, postalCode: "83114" },
    { lng: -106.807047, lat: 42.966192, postalCode: "82646" },
    { lng: -106.35696, lat: 42.889576, postalCode: "82602" },
    { lng: -105.19881, lat: 41.422733, postalCode: "82061" },
    { lng: -104.48095, lat: 41.210012, postalCode: "82060" },
    { lng: -108.53415, lat: 44.794477, postalCode: "82412" },
    { lng: -106.27476, lat: 43.363864, postalCode: "82643" },
    { lng: -104.45121, lat: 43.992349, postalCode: "82723" },
    { lng: -108.31942, lat: 43.694009, postalCode: "82443" },
    { lng: -105.75364, lat: 41.232815, postalCode: "82070" },
    { lng: -105.24695, lat: 44.799642, postalCode: "82731" },
    { lng: -106.807047, lat: 42.966192, postalCode: "82638" },
    { lng: -108.89349, lat: 43.14687, postalCode: "82516" },
    { lng: -110.53743, lat: 41.814385, postalCode: "83121" },
    { lng: -104.862947, lat: 41.139057, postalCode: "82005" },
    { lng: -104.82923, lat: 41.742212, postalCode: "82210" },
    { lng: -104.12853, lat: 44.447058, postalCode: "82712" },
    { lng: -110.83215, lat: 43.701492, postalCode: "83012" },
    { lng: -104.11451, lat: 41.865935, postalCode: "82218" },
    { lng: -108.97139, lat: 41.761851, postalCode: "82945" },
    { lng: -108.56038, lat: 43.312211, postalCode: "82523" },
    { lng: -104.666365, lat: 41.327351, postalCode: "82002" },
    { lng: -106.27255, lat: 42.844915, postalCode: "82609" },
    { lng: -104.39118, lat: 41.552531, postalCode: "82081" },
    { lng: -106.53986, lat: 42.791242, postalCode: "82604" },
    { lng: -105.485243, lat: 42.894249, postalCode: "82631" },
    { lng: -106.42404, lat: 44.770838, postalCode: "82835" },
    { lng: -105.52445, lat: 43.939968, postalCode: "82718" },
    { lng: -107.68195, lat: 43.228052, postalCode: "82642" },
    { lng: -107.117226, lat: 41.780239, postalCode: "82334" },
    { lng: -108.92718, lat: 44.20528, postalCode: "82433" },
    { lng: -110.11482, lat: 42.557035, postalCode: "83113" },
    { lng: -111.01917, lat: 43.062245, postalCode: "83118" },
    { lng: -104.371298, lat: 44.408744, postalCode: "82779" },
    { lng: -106.43594, lat: 41.620507, postalCode: "82324" },
    { lng: -106.29799, lat: 42.896822, postalCode: "82601" },
    { lng: -110.786527, lat: 43.50537, postalCode: "83002" },
    { lng: -104.2877, lat: 41.646277, postalCode: "82221" },
    { lng: -105.38624, lat: 44.374954, postalCode: "82717" },
    { lng: -109.85694, lat: 42.874606, postalCode: "82941" },
    { lng: -108.32833, lat: 44.791974, postalCode: "82431" },
    { lng: -109.20826, lat: 44.566114, postalCode: "82414" },
    { lng: -110.33778, lat: 41.246498, postalCode: "82939" },
    { lng: -104.72952, lat: 44.81103, postalCode: "82720" },
    { lng: -104.398, lat: 44.376421, postalCode: "82729" },
    { lng: -106.807047, lat: 42.966192, postalCode: "82648" },
    { lng: -108.45634, lat: 44.929728, postalCode: "82420" },
    { lng: -106.9081, lat: 44.576978, postalCode: "82842" },
    { lng: -107.36569, lat: 43.885863, postalCode: "82442" },
    { lng: -104.4242, lat: 42.507335, postalCode: "82219" },
    { lng: -106.66324, lat: 44.725332, postalCode: "82845" },
    { lng: -107.97365, lat: 43.993124, postalCode: "82401" },
    { lng: -108.16076, lat: 41.765078, postalCode: "82336" },
    { lng: -111.0048, lat: 42.801664, postalCode: "83111" },
    { lng: -104.12045, lat: 42.706475, postalCode: "82242" },
    { lng: -104.3721, lat: 41.907756, postalCode: "82244" },
    { lng: -104.68992, lat: 44.553925, postalCode: "82714" },
    { lng: -107.29475, lat: 41.971581, postalCode: "82301" },
    { lng: -104.24488, lat: 41.76916, postalCode: "82217" },
    { lng: -110.92859, lat: 42.877387, postalCode: "83112" },
    { lng: -108.3809, lat: 44.498455, postalCode: "82422" },
    { lng: -107.53257, lat: 41.034747, postalCode: "82323" },
    { lng: -108.454106, lat: 43.76976, postalCode: "82427" },
    { lng: -110.86418, lat: 43.520413, postalCode: "83014" },
    { lng: -105.797388, lat: 41.715061, postalCode: "82071" },
    { lng: -105.616339, lat: 41.327892, postalCode: "82703" },
    { lng: -105.66184, lat: 42.22772, postalCode: "82058" },
    { lng: -107.48172, lat: 44.787512, postalCode: "82836" },
    { lng: -108.04905, lat: 44.369517, postalCode: "82410" },
    { lng: -108.57191, lat: 42.998588, postalCode: "82524" },
    { lng: -110.36624, lat: 41.399751, postalCode: "82937" },
    { lng: -110.22391, lat: 41.081086, postalCode: "82936" },
    { lng: -110.24195, lat: 42.243979, postalCode: "83123" },
    { lng: -109.415501, lat: 44.463071, postalCode: "82450" },
    { lng: -108.63154, lat: 42.729832, postalCode: "82520" },
    { lng: -110.93182, lat: 42.803357, postalCode: "83122" },
    { lng: -106.17649, lat: 41.33144, postalCode: "82055" },
    { lng: -104.76034, lat: 42.27774, postalCode: "82214" },
    { lng: -106.60352, lat: 42.041677, postalCode: "82327" },
    { lng: -108.28605, lat: 44.396661, postalCode: "82434" },
    { lng: -106.807047, lat: 42.966192, postalCode: "82630" },
    { lng: -110.53733, lat: 41.10401, postalCode: "82944" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35253" },
    { lng: -87.044687, lat: 31.466945, postalCode: "36429" },
    { lng: -86.74586, lat: 33.437667, postalCode: "35243" },
    { lng: -86.31547, lat: 33.506012, postalCode: "35054" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35295" },
    { lng: -87.90302, lat: 33.972007, postalCode: "35563" },
    { lng: -87.92911, lat: 33.641227, postalCode: "35545" },
    { lng: -87.16587, lat: 33.754767, postalCode: "35550" },
    { lng: -86.28912, lat: 33.606663, postalCode: "35125" },
    { lng: -87.03809, lat: 32.429066, postalCode: "36701" },
    { lng: -86.89524, lat: 33.506456, postalCode: "35218" },
    { lng: -85.603458, lat: 31.407872, postalCode: "36361" },
    { lng: -87.60177, lat: 32.703529, postalCode: "36744" },
    { lng: -85.65125, lat: 34.5852, postalCode: "35989" },
    { lng: -87.12515, lat: 33.22584, postalCode: "35074" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35298" },
    { lng: -86.666272, lat: 32.507893, postalCode: "36068" },
    { lng: -85.61277, lat: 34.237989, postalCode: "35959" },
    { lng: -87.10173, lat: 31.129008, postalCode: "36426" },
    { lng: -86.15741, lat: 31.985456, postalCode: "36036" },
    { lng: -86.31068, lat: 32.377509, postalCode: "36104" },
    { lng: -86.30504, lat: 32.30234, postalCode: "36105" },
    { lng: -85.85634, lat: 34.279268, postalCode: "35961" },
    { lng: -85.37812, lat: 31.645125, postalCode: "36317" },
    { lng: -87.55222, lat: 31.990494, postalCode: "36769" },
    { lng: -86.70207, lat: 31.03183, postalCode: "36483" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36689" },
    { lng: -85.36865, lat: 33.159848, postalCode: "36274" },
    { lng: -88.06349, lat: 31.529488, postalCode: "36569" },
    { lng: -86.81569, lat: 33.82343, postalCode: "35180" },
    { lng: -87.65265, lat: 34.516472, postalCode: "35654" },
    { lng: -87.11884, lat: 34.581591, postalCode: "35673" },
    { lng: -86.92449, lat: 34.139198, postalCode: "35057" },
    { lng: -86.22634, lat: 34.752369, postalCode: "35774" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36130" },
    { lng: -85.958001, lat: 32.990191, postalCode: "35011" },
    { lng: -87.50611, lat: 33.271585, postalCode: "35406" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35299" },
    { lng: -86.27258, lat: 34.815033, postalCode: "35751" },
    { lng: -85.221739, lat: 32.890759, postalCode: "36383" },
    { lng: -86.928542, lat: 33.445808, postalCode: "35288" },
    { lng: -88.16845, lat: 30.728488, postalCode: "36618" },
    { lng: -86.8097, lat: 33.519055, postalCode: "35203" },
    { lng: -88.17081, lat: 32.304709, postalCode: "36922" },
    { lng: -87.57551, lat: 32.079894, postalCode: "36722" },
    { lng: -87.91874, lat: 30.471767, postalCode: "36564" },
    { lng: -86.13237, lat: 34.155318, postalCode: "35956" },
    { lng: -86.48932, lat: 34.720813, postalCode: "35741" },
    { lng: -85.349003, lat: 32.578185, postalCode: "36865" },
    { lng: -87.956884, lat: 32.835692, postalCode: "35491" },
    { lng: -85.837828, lat: 33.762195, postalCode: "36257" },
    { lng: -86.30083, lat: 31.913456, postalCode: "36041" },
    { lng: -87.293888, lat: 33.901993, postalCode: "35502" },
    { lng: -87.88368, lat: 33.299935, postalCode: "35466" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35282" },
    { lng: -87.45746, lat: 33.97173, postalCode: "35578" },
    { lng: -86.53875, lat: 33.080971, postalCode: "35143" },
    { lng: -86.931818, lat: 33.64672, postalCode: "35041" },
    { lng: -88.200738, lat: 32.004227, postalCode: "36906" },
    { lng: -86.257817, lat: 32.356988, postalCode: "36101" },
    { lng: -86.8216, lat: 32.282192, postalCode: "36785" },
    { lng: -86.54879, lat: 31.356774, postalCode: "36476" },
    { lng: -87.7549, lat: 30.540664, postalCode: "36576" },
    { lng: -87.796011, lat: 30.427641, postalCode: "36555" },
    { lng: -87.18344, lat: 34.059527, postalCode: "35541" },
    { lng: -86.89176, lat: 34.340484, postalCode: "35622" },
    { lng: -85.71409, lat: 33.473789, postalCode: "36258" },
    { lng: -85.67642, lat: 34.788306, postalCode: "35966" },
    { lng: -86.585312, lat: 34.728366, postalCode: "35804" },
    { lng: -86.47451, lat: 32.682598, postalCode: "36051" },
    { lng: -88.30045, lat: 31.779344, postalCode: "36919" },
    { lng: -86.55929, lat: 34.666041, postalCode: "35802" },
    { lng: -87.753091, lat: 30.620944, postalCode: "36531" },
    { lng: -86.3188, lat: 31.579451, postalCode: "36009" },
    { lng: -86.49437, lat: 32.070433, postalCode: "36047" },
    { lng: -88.01367, lat: 33.939291, postalCode: "35544" },
    { lng: -87.72688, lat: 31.786539, postalCode: "36446" },
    { lng: -86.34347, lat: 31.042071, postalCode: "36442" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36131" },
    { lng: -86.69684, lat: 33.409582, postalCode: "35242" },
    { lng: -86.74405, lat: 32.96512, postalCode: "35085" },
    { lng: -87.27103, lat: 33.701484, postalCode: "35580" },
    { lng: -88.00103, lat: 30.930065, postalCode: "36505" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36057" },
    { lng: -86.64355, lat: 32.834501, postalCode: "35045" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36628" },
    { lng: -87.77893, lat: 32.069338, postalCode: "36736" },
    { lng: -85.47202, lat: 33.423088, postalCode: "36270" },
    { lng: -86.30285, lat: 33.561597, postalCode: "35128" },
    { lng: -87.706859, lat: 34.869964, postalCode: "35631" },
    { lng: -88.41557, lat: 31.375241, postalCode: "36539" },
    { lng: -87.79793, lat: 33.012557, postalCode: "35469" },
    { lng: -88.0321, lat: 32.772835, postalCode: "35443" },
    { lng: -86.30475, lat: 34.350835, postalCode: "35976" },
    { lng: -85.79562, lat: 31.174249, postalCode: "36313" },
    { lng: -87.43974, lat: 33.068806, postalCode: "35456" },
    { lng: -86.91034, lat: 32.639376, postalCode: "36758" },
    { lng: -86.8784, lat: 34.625704, postalCode: "35649" },
    { lng: -87.629926, lat: 30.265325, postalCode: "36547" },
    { lng: -86.58546, lat: 33.623485, postalCode: "35173" },
    { lng: -85.92772, lat: 34.457657, postalCode: "35971" },
    { lng: -85.64621, lat: 32.799998, postalCode: "36850" },
    { lng: -86.98313, lat: 32.213369, postalCode: "36775" },
    { lng: -86.95736, lat: 33.462541, postalCode: "35061" },
    { lng: -86.57087, lat: 31.984289, postalCode: "36032" },
    { lng: -86.14911, lat: 32.07955, postalCode: "36069" },
    { lng: -87.13867, lat: 32.963486, postalCode: "35042" },
    { lng: -86.89046, lat: 32.917983, postalCode: "36792" },
    { lng: -86.25523, lat: 33.987653, postalCode: "35972" },
    { lng: -87.01546, lat: 33.529224, postalCode: "35118" },
    { lng: -85.33465, lat: 32.556296, postalCode: "36804" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36125" },
    { lng: -86.25105, lat: 33.185782, postalCode: "35150" },
    { lng: -87.37169, lat: 33.673236, postalCode: "35579" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35814" },
    { lng: -87.08699, lat: 33.823683, postalCode: "35584" },
    { lng: -87.519156, lat: 32.454371, postalCode: "36876" },
    { lng: -86.87897, lat: 32.54896, postalCode: "36749" },
    { lng: -86.95969, lat: 33.592585, postalCode: "35005" },
    { lng: -86.77574, lat: 33.640823, postalCode: "35119" },
    { lng: -85.837828, lat: 33.762195, postalCode: "36204" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35230" },
    { lng: -87.645, lat: 31.756797, postalCode: "36436" },
    { lng: -85.82425, lat: 31.678393, postalCode: "36010" },
    { lng: -87.7142, lat: 34.70017, postalCode: "35674" },
    { lng: -86.77335, lat: 33.524254, postalCode: "35222" },
    { lng: -85.509902, lat: 33.706595, postalCode: "36261" },
    { lng: -88.09609, lat: 30.717539, postalCode: "36617" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35283" },
    { lng: -85.69542, lat: 32.427987, postalCode: "36083" },
    { lng: -86.444833, lat: 31.260882, postalCode: "36038" },
    { lng: -86.11516, lat: 34.501178, postalCode: "35755" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35015" },
    { lng: -85.77037, lat: 33.824496, postalCode: "36265" },
    { lng: -87.124708, lat: 32.419259, postalCode: "36702" },
    { lng: -88.0996, lat: 33.752857, postalCode: "35592" },
    { lng: -87.99588, lat: 31.161966, postalCode: "36513" },
    { lng: -86.9809, lat: 34.930354, postalCode: "35620" },
    { lng: -86.33936, lat: 33.016558, postalCode: "35183" },
    { lng: -86.99911, lat: 32.838805, postalCode: "36793" },
    { lng: -87.17423, lat: 32.701812, postalCode: "36779" },
    { lng: -87.497165, lat: 31.015817, postalCode: "36504" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36622" },
    { lng: -87.73186, lat: 33.909001, postalCode: "35559" },
    { lng: -87.50401, lat: 31.514262, postalCode: "36470" },
    { lng: -87.63936, lat: 30.5822, postalCode: "36567" },
    { lng: -87.04756, lat: 33.753474, postalCode: "35148" },
    { lng: -86.80405, lat: 34.813577, postalCode: "35742" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35048" },
    { lng: -88.211103, lat: 30.601892, postalCode: "36614" },
    { lng: -87.78077, lat: 34.519453, postalCode: "35653" },
    { lng: -87.57065, lat: 34.049426, postalCode: "35575" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35237" },
    { lng: -86.338572, lat: 33.256886, postalCode: "35032" },
    { lng: -88.12485, lat: 32.558949, postalCode: "35470" },
    { lng: -86.25484, lat: 31.280048, postalCode: "36467" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36118" },
    { lng: -86.8057, lat: 33.497055, postalCode: "35205" },
    { lng: -88.05965, lat: 32.209381, postalCode: "36916" },
    { lng: -87.80211, lat: 33.274081, postalCode: "35458" },
    { lng: -86.04682, lat: 33.089342, postalCode: "35072" },
    { lng: -86.319824, lat: 34.34962, postalCode: "35964" },
    { lng: -86.48699, lat: 31.279043, postalCode: "36420" },
    { lng: -86.78726, lat: 33.317471, postalCode: "35124" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35813" },
    { lng: -86.0286, lat: 33.79933, postalCode: "36271" },
    { lng: -86.04782, lat: 31.091359, postalCode: "36477" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36675" },
    { lng: -86.82506, lat: 34.167893, postalCode: "35055" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36123" },
    { lng: -86.55506, lat: 33.530698, postalCode: "35094" },
    { lng: -85.54403, lat: 31.096836, postalCode: "36375" },
    { lng: -85.27681, lat: 31.243717, postalCode: "36376" },
    { lng: -88.34902, lat: 31.970871, postalCode: "36915" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36141" },
    { lng: -87.578576, lat: 32.700467, postalCode: "36755" },
    { lng: -86.23149, lat: 34.842944, postalCode: "35766" },
    { lng: -86.71868, lat: 32.907616, postalCode: "35171" },
    { lng: -86.20067, lat: 33.614465, postalCode: "35135" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36641" },
    { lng: -86.35582, lat: 33.268471, postalCode: "35044" },
    { lng: -86.98786, lat: 34.595332, postalCode: "35601" },
    { lng: -88.18697, lat: 30.965694, postalCode: "36521" },
    { lng: -86.08387, lat: 34.712327, postalCode: "35768" },
    { lng: -86.20726, lat: 34.929269, postalCode: "35745" },
    { lng: -88.10835, lat: 33.581135, postalCode: "35576" },
    { lng: -87.06771, lat: 34.041061, postalCode: "35053" },
    { lng: -88.02086, lat: 33.399471, postalCode: "35481" },
    { lng: -85.21314, lat: 32.871306, postalCode: "36863" },
    { lng: -87.32416, lat: 31.509039, postalCode: "36460" },
    { lng: -86.62464, lat: 34.73969, postalCode: "35816" },
    { lng: -86.781162, lat: 33.206302, postalCode: "35137" },
    { lng: -86.50249, lat: 33.606379, postalCode: "35004" },
    { lng: -86.981703, lat: 32.006129, postalCode: "36741" },
    { lng: -85.84503, lat: 33.243964, postalCode: "36251" },
    { lng: -86.801904, lat: 33.456412, postalCode: "35201" },
    { lng: -87.64417, lat: 31.021335, postalCode: "36562" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35893" },
    { lng: -86.24963, lat: 32.404754, postalCode: "36115" },
    { lng: -86.59814, lat: 34.198738, postalCode: "35083" },
    { lng: -87.339329, lat: 31.429472, postalCode: "36439" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35896" },
    { lng: -86.95141, lat: 33.405559, postalCode: "35020" },
    { lng: -88.00384, lat: 34.149717, postalCode: "35570" },
    { lng: -87.341108, lat: 31.522093, postalCode: "36461" },
    { lng: -86.81577, lat: 33.356981, postalCode: "35244" },
    { lng: -85.83166, lat: 33.719701, postalCode: "36206" },
    { lng: -87.706371, lat: 30.42216, postalCode: "36536" },
    { lng: -86.39852, lat: 34.541285, postalCode: "35760" },
    { lng: -86.83534, lat: 33.519855, postalCode: "35204" },
    { lng: -85.48718, lat: 31.319083, postalCode: "36350" },
    { lng: -88.31069, lat: 32.80728, postalCode: "35459" },
    { lng: -86.433655, lat: 31.045685, postalCode: "36422" },
    { lng: -85.92825, lat: 34.359766, postalCode: "35963" },
    { lng: -86.80602, lat: 33.538504, postalCode: "35234" },
    { lng: -85.91553, lat: 34.610055, postalCode: "35744" },
    { lng: -87.61717, lat: 32.962981, postalCode: "35474" },
    { lng: -86.5126, lat: 33.4776, postalCode: "35176" },
    { lng: -85.69859, lat: 32.551474, postalCode: "36866" },
    { lng: -87.23957, lat: 31.023139, postalCode: "36441" },
    { lng: -86.80644, lat: 34.043589, postalCode: "35077" },
    { lng: -87.408362, lat: 31.514659, postalCode: "36458" },
    { lng: -87.97695, lat: 33.586897, postalCode: "35574" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36140" },
    { lng: -86.19282, lat: 34.313014, postalCode: "35951" },
    { lng: -87.99791, lat: 31.373999, postalCode: "36581" },
    { lng: -87.25911, lat: 32.008444, postalCode: "36726" },
    { lng: -85.37813, lat: 33.740158, postalCode: "36269" },
    { lng: -86.14489, lat: 31.756986, postalCode: "36035" },
    { lng: -86.918818, lat: 33.635848, postalCode: "35039" },
    { lng: -87.96332, lat: 32.249718, postalCode: "36763" },
    { lng: -85.76744, lat: 34.460537, postalCode: "35968" },
    { lng: -85.54722, lat: 31.31201, postalCode: "36371" },
    { lng: -86.26946, lat: 31.737407, postalCode: "36049" },
    { lng: -87.46772, lat: 31.63857, postalCode: "36444" },
    { lng: -86.97203, lat: 33.712204, postalCode: "35139" },
    { lng: -86.61584, lat: 33.201789, postalCode: "35051" },
    { lng: -87.26226, lat: 32.048079, postalCode: "36753" },
    { lng: -85.86482, lat: 31.172641, postalCode: "36316" },
    { lng: -87.49657, lat: 32.440868, postalCode: "36786" },
    { lng: -87.25156, lat: 33.197284, postalCode: "35490" },
    { lng: -86.47069, lat: 34.624533, postalCode: "35763" },
    { lng: -86.2822, lat: 32.8697, postalCode: "35136" },
    { lng: -85.349003, lat: 32.578185, postalCode: "36802" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36177" },
    { lng: -86.0514, lat: 32.042813, postalCode: "36065" },
    { lng: -86.97783, lat: 32.419846, postalCode: "36703" },
    { lng: -86.07271, lat: 31.428389, postalCode: "36323" },
    { lng: -85.9136, lat: 32.553036, postalCode: "36078" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35895" },
    { lng: -85.94917, lat: 31.16782, postalCode: "36318" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35291" },
    { lng: -86.27007, lat: 32.426372, postalCode: "36110" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36671" },
    { lng: -86.27316, lat: 32.338989, postalCode: "36111" },
    { lng: -85.75742, lat: 31.013308, postalCode: "36314" },
    { lng: -86.24231, lat: 33.825516, postalCode: "35953" },
    { lng: -85.99282, lat: 34.016747, postalCode: "35901" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35286" },
    { lng: -87.49461, lat: 33.217168, postalCode: "35404" },
    { lng: -85.717302, lat: 34.452575, postalCode: "35969" },
    { lng: -86.7562, lat: 34.223732, postalCode: "35058" },
    { lng: -87.345345, lat: 31.529531, postalCode: "36462" },
    { lng: -86.73402, lat: 33.488355, postalCode: "35223" },
    { lng: -87.50776, lat: 34.904208, postalCode: "35645" },
    { lng: -85.83763, lat: 34.500878, postalCode: "35986" },
    { lng: -86.102782, lat: 33.420206, postalCode: "35161" },
    { lng: -85.61564, lat: 34.831242, postalCode: "35979" },
    { lng: -86.80939, lat: 33.522505, postalCode: "35263" },
    { lng: -88.04416, lat: 30.693389, postalCode: "36602" },
    { lng: -86.48237, lat: 33.681899, postalCode: "35112" },
    { lng: -86.638819, lat: 32.859262, postalCode: "35026" },
    { lng: -87.17442, lat: 31.737883, postalCode: "36425" },
    { lng: -85.81991, lat: 33.754308, postalCode: "36277" },
    { lng: -86.92273, lat: 33.454262, postalCode: "35228" },
    { lng: -88.26553, lat: 30.76635, postalCode: "36575" },
    { lng: -85.83571, lat: 33.591301, postalCode: "36203" },
    { lng: -86.65507, lat: 33.609779, postalCode: "35235" },
    { lng: -85.88969, lat: 33.766057, postalCode: "36250" },
    { lng: -85.31205, lat: 32.297792, postalCode: "36858" },
    { lng: -85.529661, lat: 31.138076, postalCode: "36395" },
    { lng: -86.23363, lat: 34.664895, postalCode: "35776" },
    { lng: -87.88312, lat: 30.620594, postalCode: "36526" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35225" },
    { lng: -87.585902, lat: 33.307222, postalCode: "35487" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36120" },
    { lng: -85.559235, lat: 31.121244, postalCode: "36349" },
    { lng: -88.06676, lat: 30.68164, postalCode: "36604" },
    { lng: -86.449262, lat: 33.922162, postalCode: "35013" },
    { lng: -86.323949, lat: 31.74589, postalCode: "36062" },
    { lng: -88.26781, lat: 31.463383, postalCode: "36518" },
    { lng: -86.94986, lat: 34.67373, postalCode: "35671" },
    { lng: -87.11732, lat: 34.967939, postalCode: "35647" },
    { lng: -87.0361, lat: 33.731278, postalCode: "35062" },
    { lng: -86.54218, lat: 33.397441, postalCode: "35147" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35294" },
    { lng: -86.45052, lat: 33.781133, postalCode: "35146" },
    { lng: -86.74877, lat: 34.011027, postalCode: "35070" },
    { lng: -86.6647, lat: 34.623075, postalCode: "35808" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36124" },
    { lng: -88.16865, lat: 30.533744, postalCode: "36582" },
    { lng: -87.446, lat: 33.807412, postalCode: "35587" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35293" },
    { lng: -88.25764, lat: 32.915891, postalCode: "35477" },
    { lng: -85.4858, lat: 32.602434, postalCode: "36849" },
    { lng: -85.36139, lat: 33.469709, postalCode: "36263" },
    { lng: -86.27905, lat: 32.378138, postalCode: "36107" },
    { lng: -87.600815, lat: 34.093409, postalCode: "35577" },
    { lng: -85.2429, lat: 31.178688, postalCode: "36312" },
    { lng: -87.00192, lat: 33.805216, postalCode: "35063" },
    { lng: -87.67217, lat: 33.249332, postalCode: "35452" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35231" },
    { lng: -86.44607, lat: 31.860078, postalCode: "36042" },
    { lng: -87.58945, lat: 33.260567, postalCode: "35473" },
    { lng: -85.837828, lat: 33.762195, postalCode: "36253" },
    { lng: -87.80317, lat: 31.126122, postalCode: "36579" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35285" },
    { lng: -86.52962, lat: 32.488113, postalCode: "36067" },
    { lng: -86.36882, lat: 34.179277, postalCode: "35980" },
    { lng: -86.63056, lat: 33.896526, postalCode: "35097" },
    { lng: -85.47282, lat: 32.577135, postalCode: "36830" },
    { lng: -86.87111, lat: 33.665823, postalCode: "35117" },
    { lng: -86.287316, lat: 32.346158, postalCode: "36103" },
    { lng: -86.57316, lat: 34.72879, postalCode: "35801" },
    { lng: -86.734317, lat: 31.642713, postalCode: "36015" },
    { lng: -88.2679, lat: 32.487726, postalCode: "36925" },
    { lng: -85.92314, lat: 31.852825, postalCode: "36081" },
    { lng: -86.790389, lat: 33.462856, postalCode: "35229" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35807" },
    { lng: -86.56137, lat: 34.863236, postalCode: "35759" },
    { lng: -87.524264, lat: 33.92169, postalCode: "35573" },
    { lng: -85.47402, lat: 31.214832, postalCode: "36305" },
    { lng: -88.15462, lat: 34.061266, postalCode: "35552" },
    { lng: -88.02603, lat: 34.950648, postalCode: "35677" },
    { lng: -88.35079, lat: 30.828988, postalCode: "36587" },
    { lng: -87.90187, lat: 32.114999, postalCode: "36782" },
    { lng: -87.77773, lat: 31.704736, postalCode: "36451" },
    { lng: -85.93993, lat: 33.942031, postalCode: "35905" },
    { lng: -86.66295, lat: 33.317093, postalCode: "35043" },
    { lng: -85.803427, lat: 32.800155, postalCode: "36023" },
    { lng: -85.60472, lat: 34.141067, postalCode: "35960" },
    { lng: -86.8295, lat: 33.406409, postalCode: "35226" },
    { lng: -86.36037, lat: 32.353188, postalCode: "36108" },
    { lng: -88.10474, lat: 30.699539, postalCode: "36607" },
    { lng: -85.93281, lat: 32.214818, postalCode: "36029" },
    { lng: -86.93189, lat: 33.517706, postalCode: "35224" },
    { lng: -85.75558, lat: 31.859531, postalCode: "36005" },
    { lng: -86.71367, lat: 34.90267, postalCode: "35773" },
    { lng: -87.76592, lat: 30.875697, postalCode: "36507" },
    { lng: -86.12079, lat: 33.605233, postalCode: "35096" },
    { lng: -85.0752, lat: 32.484671, postalCode: "36870" },
    { lng: -87.27857, lat: 32.420138, postalCode: "36759" },
    { lng: -86.74996, lat: 33.107572, postalCode: "35040" },
    { lng: -88.15397, lat: 30.630441, postalCode: "36693" },
    { lng: -87.53543, lat: 32.594818, postalCode: "36765" },
    { lng: -85.36312, lat: 33.535491, postalCode: "36273" },
    { lng: -86.77355, lat: 31.54668, postalCode: "36456" },
    { lng: -88.23793, lat: 30.479171, postalCode: "36544" },
    { lng: -87.78949, lat: 33.925454, postalCode: "35594" },
    { lng: -88.02433, lat: 31.38122, postalCode: "36585" },
    { lng: -87.966575, lat: 34.757152, postalCode: "35662" },
    { lng: -88.269865, lat: 30.49434, postalCode: "36568" },
    { lng: -86.61977, lat: 34.520438, postalCode: "35754" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35254" },
    { lng: -86.8206, lat: 33.67933, postalCode: "35071" },
    { lng: -87.33889, lat: 32.665966, postalCode: "36756" },
    { lng: -85.94756, lat: 33.51179, postalCode: "36268" },
    { lng: -88.101463, lat: 30.253557, postalCode: "36508" },
    { lng: -88.24671, lat: 30.401384, postalCode: "36509" },
    { lng: -86.06098, lat: 32.941708, postalCode: "35089" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35762" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36119" },
    { lng: -85.73077, lat: 34.943871, postalCode: "35740" },
    { lng: -87.39317, lat: 34.146713, postalCode: "35553" },
    { lng: -86.572711, lat: 32.501649, postalCode: "36008" },
    { lng: -85.349003, lat: 32.578185, postalCode: "36831" },
    { lng: -85.70998, lat: 32.145437, postalCode: "36089" },
    { lng: -85.008178, lat: 32.229846, postalCode: "36859" },
    { lng: -88.09386, lat: 30.831636, postalCode: "36571" },
    { lng: -87.77379, lat: 34.898897, postalCode: "35633" },
    { lng: -86.60591, lat: 31.8007, postalCode: "36037" },
    { lng: -86.7607, lat: 33.922179, postalCode: "35079" },
    { lng: -85.7001, lat: 31.096994, postalCode: "36344" },
    { lng: -86.016, lat: 32.697561, postalCode: "36024" },
    { lng: -87.41166, lat: 34.64248, postalCode: "35672" },
    { lng: -86.80871, lat: 33.232422, postalCode: "35007" },
    { lng: -85.57418, lat: 33.142612, postalCode: "36276" },
    { lng: -87.69317, lat: 34.759738, postalCode: "35660" },
    { lng: -86.683891, lat: 33.28241, postalCode: "35144" },
    { lng: -86.42746, lat: 32.459435, postalCode: "36066" },
    { lng: -87.73326, lat: 32.871514, postalCode: "35441" },
    { lng: -85.11348, lat: 32.315128, postalCode: "36875" },
    { lng: -86.27889, lat: 34.500216, postalCode: "35747" },
    { lng: -85.94729, lat: 34.745259, postalCode: "35752" },
    { lng: -87.52303, lat: 33.149246, postalCode: "35405" },
    { lng: -87.63373, lat: 31.614138, postalCode: "36482" },
    { lng: -85.699116, lat: 33.657349, postalCode: "36254" },
    { lng: -87.453224, lat: 33.306606, postalCode: "35468" },
    { lng: -88.32468, lat: 32.227905, postalCode: "36912" },
    { lng: -88.05414, lat: 30.857435, postalCode: "36572" },
    { lng: -87.05075, lat: 34.120006, postalCode: "35098" },
    { lng: -88.103184, lat: 30.701142, postalCode: "36601" },
    { lng: -86.22835, lat: 34.261878, postalCode: "35950" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36621" },
    { lng: -87.054284, lat: 32.01998, postalCode: "36778" },
    { lng: -86.16443, lat: 32.373714, postalCode: "36117" },
    { lng: -86.74896, lat: 33.542004, postalCode: "35212" },
    { lng: -87.73883, lat: 31.463968, postalCode: "36501" },
    { lng: -88.03007, lat: 31.088463, postalCode: "36560" },
    { lng: -85.13116, lat: 31.087556, postalCode: "36343" },
    { lng: -86.645592, lat: 34.733898, postalCode: "35899" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35767" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36625" },
    { lng: -85.40605, lat: 31.249042, postalCode: "36303" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36640" },
    { lng: -88.01381, lat: 31.613745, postalCode: "36570" },
    { lng: -88.23657, lat: 30.646415, postalCode: "36695" },
    { lng: -86.32225, lat: 34.057496, postalCode: "35952" },
    { lng: -87.78506, lat: 31.375467, postalCode: "36515" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36102" },
    { lng: -87.704179, lat: 34.536839, postalCode: "35659" },
    { lng: -85.2094, lat: 31.599256, postalCode: "36310" },
    { lng: -85.65353, lat: 34.717362, postalCode: "35981" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35236" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36132" },
    { lng: -86.75164, lat: 34.776638, postalCode: "35757" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36633" },
    { lng: -85.89512, lat: 33.862349, postalCode: "36279" },
    { lng: -86.38995, lat: 34.710942, postalCode: "35748" },
    { lng: -87.24886, lat: 31.612646, postalCode: "36471" },
    { lng: -88.11819, lat: 30.250825, postalCode: "36528" },
    { lng: -85.55457, lat: 33.613541, postalCode: "36264" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35219" },
    { lng: -86.8965, lat: 33.450267, postalCode: "35221" },
    { lng: -88.28508, lat: 31.891177, postalCode: "36908" },
    { lng: -86.4084, lat: 33.593115, postalCode: "35052" },
    { lng: -85.355931, lat: 31.156018, postalCode: "36304" },
    { lng: -86.78489, lat: 33.420558, postalCode: "35216" },
    { lng: -85.55322, lat: 32.733511, postalCode: "36879" },
    { lng: -85.31561, lat: 31.051843, postalCode: "36320" },
    { lng: -85.55787, lat: 31.692576, postalCode: "36017" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35255" },
    { lng: -87.9986, lat: 34.748498, postalCode: "35616" },
    { lng: -87.453224, lat: 33.306606, postalCode: "35482" },
    { lng: -86.15644, lat: 32.505741, postalCode: "36093" },
    { lng: -85.166028, lat: 31.826055, postalCode: "36072" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35809" },
    { lng: -87.75299, lat: 30.6316, postalCode: "36551" },
    { lng: -88.02806, lat: 30.886317, postalCode: "36525" },
    { lng: -87.39972, lat: 32.291429, postalCode: "36773" },
    { lng: -86.78543, lat: 33.74137, postalCode: "35116" },
    { lng: -86.37618, lat: 31.474803, postalCode: "36028" },
    { lng: -87.41631, lat: 31.429994, postalCode: "36445" },
    { lng: -86.33073, lat: 32.496067, postalCode: "36020" },
    { lng: -87.75003, lat: 32.454007, postalCode: "36742" },
    { lng: -88.126656, lat: 30.671048, postalCode: "36616" },
    { lng: -85.75895, lat: 33.651673, postalCode: "36207" },
    { lng: -87.93837, lat: 32.931143, postalCode: "35462" },
    { lng: -86.50614, lat: 33.241272, postalCode: "35186" },
    { lng: -87.88559, lat: 30.512458, postalCode: "36532" },
    { lng: -87.28018, lat: 32.08846, postalCode: "36723" },
    { lng: -86.1728, lat: 34.203621, postalCode: "35957" },
    { lng: -87.22474, lat: 31.410205, postalCode: "36475" },
    { lng: -85.87941, lat: 31.043454, postalCode: "36340" },
    { lng: -85.78527, lat: 33.711314, postalCode: "36205" },
    { lng: -87.62705, lat: 34.759947, postalCode: "35661" },
    { lng: -87.373241, lat: 34.145939, postalCode: "35551" },
    { lng: -86.17492, lat: 33.721107, postalCode: "35131" },
    { lng: -85.62908, lat: 31.447119, postalCode: "36360" },
    { lng: -85.9237, lat: 32.399994, postalCode: "36075" },
    { lng: -87.11584, lat: 33.640629, postalCode: "35130" },
    { lng: -85.17623, lat: 32.777662, postalCode: "36854" },
    { lng: -88.20879, lat: 31.35209, postalCode: "36583" },
    { lng: -87.02767, lat: 34.784028, postalCode: "35611" },
    { lng: -87.56159, lat: 30.377657, postalCode: "36530" },
    { lng: -86.72163, lat: 33.568573, postalCode: "35206" },
    { lng: -85.28076, lat: 32.745844, postalCode: "36852" },
    { lng: -86.51111, lat: 32.759543, postalCode: "36091" },
    { lng: -86.86441, lat: 31.847964, postalCode: "36030" },
    { lng: -85.58908, lat: 31.776734, postalCode: "36048" },
    { lng: -88.13117, lat: 33.864591, postalCode: "35586" },
    { lng: -87.94612, lat: 34.341907, postalCode: "35571" },
    { lng: -86.63505, lat: 34.29254, postalCode: "35019" },
    { lng: -86.23672, lat: 32.31324, postalCode: "36116" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36670" },
    { lng: -86.89199, lat: 33.558772, postalCode: "35214" },
    { lng: -87.338369, lat: 33.135876, postalCode: "35449" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35260" },
    { lng: -85.11621, lat: 32.16827, postalCode: "36871" },
    { lng: -85.738032, lat: 32.40926, postalCode: "36087" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35277" },
    { lng: -86.34182, lat: 32.025367, postalCode: "36046" },
    { lng: -87.34313, lat: 31.821448, postalCode: "36481" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35246" },
    { lng: -86.61339, lat: 34.77624, postalCode: "35810" },
    { lng: -86.33358, lat: 32.538878, postalCode: "36025" },
    { lng: -87.092112, lat: 33.706068, postalCode: "35308" },
    { lng: -86.7997, lat: 33.509623, postalCode: "35233" },
    { lng: -86.73964, lat: 34.715065, postalCode: "35758" },
    { lng: -87.48875, lat: 34.476048, postalCode: "35651" },
    { lng: -86.92158, lat: 34.256106, postalCode: "35179" },
    { lng: -86.66963, lat: 33.546051, postalCode: "35210" },
    { lng: -87.345345, lat: 31.529531, postalCode: "36449" },
    { lng: -86.97331, lat: 33.487675, postalCode: "35127" },
    { lng: -86.43641, lat: 33.668341, postalCode: "35120" },
    { lng: -87.45675, lat: 32.147166, postalCode: "36728" },
    { lng: -88.1648, lat: 32.811241, postalCode: "35464" },
    { lng: -87.93971, lat: 30.668757, postalCode: "36577" },
    { lng: -87.68988, lat: 33.081289, postalCode: "35463" },
    { lng: -88.08089, lat: 31.234589, postalCode: "36553" },
    { lng: -85.18539, lat: 32.587751, postalCode: "36874" },
    { lng: -87.83382, lat: 33.102981, postalCode: "35480" },
    { lng: -85.79646, lat: 34.56838, postalCode: "35988" },
    { lng: -87.02889, lat: 33.952939, postalCode: "35033" },
    { lng: -86.35065, lat: 31.011435, postalCode: "36455" },
    { lng: -86.71847, lat: 34.334972, postalCode: "35621" },
    { lng: -86.80159, lat: 34.630103, postalCode: "35756" },
    { lng: -87.898611, lat: 32.414036, postalCode: "36745" },
    { lng: -88.2633, lat: 31.0753, postalCode: "36522" },
    { lng: -86.36692, lat: 32.492335, postalCode: "36054" },
    { lng: -86.29429, lat: 32.691057, postalCode: "36080" },
    { lng: -85.1146, lat: 31.52151, postalCode: "36373" },
    { lng: -87.956884, lat: 32.835692, postalCode: "35448" },
    { lng: -85.020604, lat: 32.464614, postalCode: "36868" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36626" },
    { lng: -85.803434, lat: 33.984062, postalCode: "35902" },
    { lng: -88.1577, lat: 30.795637, postalCode: "36613" },
    { lng: -87.030479, lat: 34.774907, postalCode: "35612" },
    { lng: -87.27802, lat: 34.496476, postalCode: "35650" },
    { lng: -86.20523, lat: 33.252906, postalCode: "35149" },
    { lng: -85.80484, lat: 34.695645, postalCode: "35765" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36660" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35281" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36631" },
    { lng: -86.7525, lat: 34.481885, postalCode: "35670" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36691" },
    { lng: -86.256658, lat: 33.706786, postalCode: "35182" },
    { lng: -86.66052, lat: 33.728568, postalCode: "35126" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35278" },
    { lng: -86.3925, lat: 33.414831, postalCode: "35178" },
    { lng: -88.173652, lat: 30.696589, postalCode: "36688" },
    { lng: -88.15471, lat: 32.220752, postalCode: "36910" },
    { lng: -86.06154, lat: 33.943964, postalCode: "35906" },
    { lng: -87.345345, lat: 31.529531, postalCode: "36431" },
    { lng: -86.76737, lat: 31.655458, postalCode: "36033" },
    { lng: -86.855772, lat: 33.08696, postalCode: "35715" },
    { lng: -88.03148, lat: 31.910694, postalCode: "36727" },
    { lng: -87.6801, lat: 32.140637, postalCode: "36754" },
    { lng: -85.14456, lat: 31.312599, postalCode: "36319" },
    { lng: -85.97048, lat: 34.830058, postalCode: "35746" },
    { lng: -85.93102, lat: 31.567171, postalCode: "36346" },
    { lng: -85.349003, lat: 32.578185, postalCode: "36872" },
    { lng: -87.030479, lat: 34.774907, postalCode: "35615" },
    { lng: -87.65194, lat: 33.903299, postalCode: "35554" },
    { lng: -88.15261, lat: 32.716468, postalCode: "35460" },
    { lng: -86.036, lat: 34.297717, postalCode: "35962" },
    { lng: -87.4311, lat: 31.874887, postalCode: "36435" },
    { lng: -86.38617, lat: 31.708837, postalCode: "36071" },
    { lng: -87.202136, lat: 33.804411, postalCode: "35038" },
    { lng: -87.60731, lat: 34.888897, postalCode: "35634" },
    { lng: -88.131, lat: 32.451618, postalCode: "36901" },
    { lng: -85.98279, lat: 34.546397, postalCode: "35771" },
    { lng: -87.6118, lat: 34.225425, postalCode: "35565" },
    { lng: -86.89623, lat: 34.808165, postalCode: "35613" },
    { lng: -87.71277, lat: 34.343017, postalCode: "35581" },
    { lng: -86.66877, lat: 32.182677, postalCode: "36040" },
    { lng: -86.68317, lat: 34.748066, postalCode: "35806" },
    { lng: -88.253278, lat: 30.487953, postalCode: "36590" },
    { lng: -87.453224, lat: 33.306606, postalCode: "35485" },
    { lng: -85.73274, lat: 34.637589, postalCode: "35978" },
    { lng: -86.88115, lat: 33.494545, postalCode: "35208" },
    { lng: -86.543692, lat: 33.335629, postalCode: "35185" },
    { lng: -86.0098, lat: 34.361787, postalCode: "35974" },
    { lng: -85.92669, lat: 32.903432, postalCode: "35010" },
    { lng: -87.58819, lat: 33.227736, postalCode: "35476" },
    { lng: -85.45227, lat: 33.761052, postalCode: "36262" },
    { lng: -86.91684, lat: 33.476908, postalCode: "35064" },
    { lng: -87.45664, lat: 30.393983, postalCode: "36549" },
    { lng: -88.14188, lat: 31.646087, postalCode: "36538" },
    { lng: -85.72548, lat: 33.185338, postalCode: "36255" },
    { lng: -87.795275, lat: 32.268329, postalCode: "36764" },
    { lng: -86.30438, lat: 34.066644, postalCode: "35990" },
    { lng: -86.95252, lat: 33.346817, postalCode: "35022" },
    { lng: -86.85878, lat: 33.479306, postalCode: "35211" },
    { lng: -84.98389, lat: 32.282085, postalCode: "36856" },
    { lng: -85.71699, lat: 32.419862, postalCode: "36088" },
    { lng: -86.27161, lat: 32.354888, postalCode: "36106" },
    { lng: -86.33414, lat: 33.137515, postalCode: "35151" },
    { lng: -87.59607, lat: 31.320897, postalCode: "36480" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35289" },
    { lng: -88.18653, lat: 33.095505, postalCode: "35442" },
    { lng: -85.69097, lat: 31.589226, postalCode: "36311" },
    { lng: -87.793794, lat: 33.272662, postalCode: "35486" },
    { lng: -87.7417, lat: 32.739091, postalCode: "36776" },
    { lng: -86.879328, lat: 33.08104, postalCode: "35187" },
    { lng: -88.08505, lat: 30.764938, postalCode: "36611" },
    { lng: -87.27632, lat: 33.828412, postalCode: "35501" },
    { lng: -85.41029, lat: 32.237339, postalCode: "36860" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35897" },
    { lng: -88.16255, lat: 30.662735, postalCode: "36609" },
    { lng: -87.58251, lat: 33.628719, postalCode: "35546" },
    { lng: -87.706859, lat: 34.869964, postalCode: "35617" },
    { lng: -87.84004, lat: 32.629096, postalCode: "36740" },
    { lng: -87.461537, lat: 31.216132, postalCode: "36543" },
    { lng: -86.24408, lat: 33.9117, postalCode: "35987" },
    { lng: -88.36252, lat: 32.403275, postalCode: "36907" },
    { lng: -86.895226, lat: 34.196448, postalCode: "35056" },
    { lng: -86.74161, lat: 33.507637, postalCode: "35213" },
    { lng: -87.26526, lat: 34.16678, postalCode: "35572" },
    { lng: -86.08904, lat: 34.043263, postalCode: "35954" },
    { lng: -87.38262, lat: 34.966009, postalCode: "35648" },
    { lng: -87.074497, lat: 31.947005, postalCode: "36766" },
    { lng: -85.161497, lat: 32.286092, postalCode: "36851" },
    { lng: -85.5751, lat: 34.557735, postalCode: "35984" },
    { lng: -85.90364, lat: 31.297817, postalCode: "36331" },
    { lng: -86.44272, lat: 34.902137, postalCode: "35761" },
    { lng: -85.15675, lat: 31.141962, postalCode: "36370" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35261" },
    { lng: -85.9175, lat: 31.406536, postalCode: "36351" },
    { lng: -85.72111, lat: 31.284499, postalCode: "36322" },
    { lng: -85.48581, lat: 31.569287, postalCode: "36374" },
    { lng: -85.53991, lat: 32.589553, postalCode: "36832" },
    { lng: -85.42905, lat: 32.903922, postalCode: "36862" },
    { lng: -87.237372, lat: 33.679503, postalCode: "35560" },
    { lng: -85.81979, lat: 32.28032, postalCode: "36039" },
    { lng: -86.88411, lat: 33.280625, postalCode: "35080" },
    { lng: -88.38071, lat: 31.202048, postalCode: "36584" },
    { lng: -87.30978, lat: 34.665033, postalCode: "35618" },
    { lng: -87.23168, lat: 32.29165, postalCode: "36767" },
    { lng: -86.345719, lat: 32.374287, postalCode: "36112" },
    { lng: -87.20394, lat: 34.648768, postalCode: "35643" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35249" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35894" },
    { lng: -86.8496, lat: 32.789726, postalCode: "36750" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36644" },
    { lng: -86.95524, lat: 32.080532, postalCode: "36761" },
    { lng: -88.22647, lat: 32.097363, postalCode: "36904" },
    { lng: -88.32722, lat: 30.487783, postalCode: "36541" },
    { lng: -85.431517, lat: 32.522567, postalCode: "36803" },
    { lng: -87.71292, lat: 30.485351, postalCode: "36580" },
    { lng: -87.72784, lat: 30.262276, postalCode: "36542" },
    { lng: -88.10205, lat: 34.803864, postalCode: "38852" },
    { lng: -87.860613, lat: 30.484131, postalCode: "36533" },
    { lng: -86.39798, lat: 32.22381, postalCode: "36043" },
    { lng: -88.34737, lat: 31.629578, postalCode: "36558" },
    { lng: -85.71078, lat: 34.429472, postalCode: "35967" },
    { lng: -86.5338, lat: 34.111425, postalCode: "35031" },
    { lng: -85.84528, lat: 34.877531, postalCode: "35772" },
    { lng: -86.49278, lat: 34.323715, postalCode: "35016" },
    { lng: -85.36241, lat: 31.453069, postalCode: "36353" },
    { lng: -86.13578, lat: 31.637696, postalCode: "36034" },
    { lng: -88.26036, lat: 31.309194, postalCode: "36529" },
    { lng: -87.435279, lat: 31.361214, postalCode: "36457" },
    { lng: -85.04512, lat: 32.433065, postalCode: "36869" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35259" },
    { lng: -87.39889, lat: 33.170357, postalCode: "35453" },
    { lng: -87.81486, lat: 30.744745, postalCode: "36578" },
    { lng: -86.51841, lat: 34.426844, postalCode: "35175" },
    { lng: -87.26404, lat: 31.091784, postalCode: "36427" },
    { lng: -86.58988, lat: 34.948321, postalCode: "35750" },
    { lng: -87.77013, lat: 31.936003, postalCode: "36784" },
    { lng: -87.77187, lat: 33.301003, postalCode: "35457" },
    { lng: -86.80672, lat: 33.77109, postalCode: "35091" },
    { lng: -86.56504, lat: 32.894351, postalCode: "35046" },
    { lng: -86.82993, lat: 34.495365, postalCode: "35699" },
    { lng: -88.18466, lat: 30.383724, postalCode: "36523" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35279" },
    { lng: -88.12041, lat: 34.448328, postalCode: "35582" },
    { lng: -86.76003, lat: 34.815817, postalCode: "35749" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35290" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35292" },
    { lng: -85.301373, lat: 31.202473, postalCode: "36321" },
    { lng: -87.10148, lat: 33.279946, postalCode: "35111" },
    { lng: -86.95732, lat: 34.539072, postalCode: "35603" },
    { lng: -87.55005, lat: 31.833493, postalCode: "36751" },
    { lng: -87.12915, lat: 34.404908, postalCode: "35619" },
    { lng: -85.964713, lat: 31.816474, postalCode: "36082" },
    { lng: -86.8738, lat: 33.114972, postalCode: "35115" },
    { lng: -85.61491, lat: 33.948018, postalCode: "36272" },
    { lng: -86.69506, lat: 33.636102, postalCode: "35215" },
    { lng: -86.853075, lat: 33.372861, postalCode: "35266" },
    { lng: -86.10908, lat: 33.415449, postalCode: "35160" },
    { lng: -88.19701, lat: 30.69044, postalCode: "36608" },
    { lng: -86.58323, lat: 34.303718, postalCode: "35087" },
    { lng: -88.06933, lat: 31.790834, postalCode: "36524" },
    { lng: -87.57913, lat: 33.388654, postalCode: "35475" },
    { lng: -85.3149, lat: 31.381263, postalCode: "36345" },
    { lng: -87.69616, lat: 30.396876, postalCode: "36535" },
    { lng: -86.71537, lat: 33.823416, postalCode: "35172" },
    { lng: -88.06745, lat: 34.369264, postalCode: "35593" },
    { lng: -87.90216, lat: 30.564701, postalCode: "36559" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35296" },
    { lng: -85.69962, lat: 33.032076, postalCode: "36256" },
    { lng: -87.26226, lat: 32.048079, postalCode: "36721" },
    { lng: -87.13548, lat: 33.118259, postalCode: "35184" },
    { lng: -86.776926, lat: 33.425892, postalCode: "35240" },
    { lng: -86.64395, lat: 32.322266, postalCode: "36752" },
    { lng: -88.14732, lat: 33.402134, postalCode: "35471" },
    { lng: -87.20327, lat: 31.306814, postalCode: "36473" },
    { lng: -87.29957, lat: 33.907546, postalCode: "35503" },
    { lng: -86.31816, lat: 34.694662, postalCode: "35764" },
    { lng: -87.706859, lat: 34.869964, postalCode: "35632" },
    { lng: -87.67155, lat: 33.703682, postalCode: "35542" },
    { lng: -85.55172, lat: 34.333048, postalCode: "35973" },
    { lng: -86.91956, lat: 33.63815, postalCode: "35036" },
    { lng: -86.7143, lat: 32.65279, postalCode: "36006" },
    { lng: -87.7594, lat: 34.043457, postalCode: "35548" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35021" },
    { lng: -87.49715, lat: 31.090528, postalCode: "36502" },
    { lng: -86.5954, lat: 33.963435, postalCode: "35049" },
    { lng: -86.80814, lat: 33.468306, postalCode: "35209" },
    { lng: -85.46827, lat: 32.011153, postalCode: "36053" },
    { lng: -86.09585, lat: 32.318234, postalCode: "36064" },
    { lng: -86.53163, lat: 34.78108, postalCode: "35811" },
    { lng: -87.9626, lat: 31.495691, postalCode: "36548" },
    { lng: -88.22013, lat: 33.421827, postalCode: "35461" },
    { lng: -87.53577, lat: 34.660322, postalCode: "35646" },
    { lng: -88.062248, lat: 30.64109, postalCode: "36615" },
    { lng: -85.552143, lat: 33.977829, postalCode: "36275" },
    { lng: -88.200738, lat: 32.004227, postalCode: "36913" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35898" },
    { lng: -87.18975, lat: 33.311014, postalCode: "35440" },
    { lng: -86.95505, lat: 31.901412, postalCode: "36768" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35232" },
    { lng: -87.62729, lat: 32.41786, postalCode: "36738" },
    { lng: -86.81897, lat: 33.560652, postalCode: "35207" },
    { lng: -86.931818, lat: 33.64672, postalCode: "35703" },
    { lng: -87.29937, lat: 34.843309, postalCode: "35652" },
    { lng: -87.64373, lat: 31.453836, postalCode: "36540" },
    { lng: -88.08222, lat: 30.641191, postalCode: "36605" },
    { lng: -86.44085, lat: 33.337092, postalCode: "35078" },
    { lng: -86.73987, lat: 34.654126, postalCode: "35824" },
    { lng: -86.39497, lat: 32.612619, postalCode: "36022" },
    { lng: -86.06464, lat: 34.606124, postalCode: "35769" },
    { lng: -85.63784, lat: 34.921827, postalCode: "35958" },
    { lng: -87.78812, lat: 32.297441, postalCode: "36748" },
    { lng: -87.89723, lat: 30.684873, postalCode: "36527" },
    { lng: -86.61691, lat: 34.711291, postalCode: "35805" },
    { lng: -85.704649, lat: 32.092808, postalCode: "36061" },
    { lng: -85.509, lat: 33.338853, postalCode: "36278" },
    { lng: -86.94027, lat: 34.442184, postalCode: "35640" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36142" },
    { lng: -85.92496, lat: 33.996698, postalCode: "35903" },
    { lng: -86.82993, lat: 34.495365, postalCode: "35609" },
    { lng: -87.52728, lat: 33.87798, postalCode: "35549" },
    { lng: -87.72171, lat: 34.247898, postalCode: "35543" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36652" },
    { lng: -87.75054, lat: 33.207547, postalCode: "35446" },
    { lng: -87.56198, lat: 30.291503, postalCode: "36561" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35280" },
    { lng: -88.16866, lat: 33.254197, postalCode: "35447" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35287" },
    { lng: -85.39917, lat: 32.660497, postalCode: "36801" },
    { lng: -87.24921, lat: 34.944113, postalCode: "35610" },
    { lng: -88.231726, lat: 30.81901, postalCode: "36663" },
    { lng: -85.355931, lat: 31.156018, postalCode: "36302" },
    { lng: -85.17362, lat: 31.876168, postalCode: "36027" },
    { lng: -87.840875, lat: 31.58842, postalCode: "36586" },
    { lng: -86.68801, lat: 32.439967, postalCode: "36003" },
    { lng: -87.03109, lat: 31.291038, postalCode: "36432" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35202" },
    { lng: -87.83441, lat: 34.262751, postalCode: "35564" },
    { lng: -87.66253, lat: 34.817537, postalCode: "35630" },
    { lng: -85.09212, lat: 32.545138, postalCode: "36877" },
    { lng: -87.453224, lat: 33.306606, postalCode: "35407" },
    { lng: -87.60214, lat: 32.263473, postalCode: "36783" },
    { lng: -85.36501, lat: 33.366136, postalCode: "36280" },
    { lng: -85.99551, lat: 33.593189, postalCode: "36260" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35297" },
    { lng: -86.34434, lat: 32.359588, postalCode: "36113" },
    { lng: -87.3052, lat: 33.276411, postalCode: "35444" },
    { lng: -86.0353, lat: 34.032097, postalCode: "35904" },
    { lng: -85.94159, lat: 33.200704, postalCode: "36267" },
    { lng: -86.86443, lat: 33.225377, postalCode: "35114" },
    { lng: -86.2772, lat: 33.35596, postalCode: "35014" },
    { lng: -88.00336, lat: 31.940191, postalCode: "36762" },
    { lng: -86.96885, lat: 33.640865, postalCode: "35073" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36630" },
    { lng: -87.17232, lat: 34.223057, postalCode: "35540" },
    { lng: -85.72073, lat: 33.328958, postalCode: "36266" },
    { lng: -86.24511, lat: 32.386088, postalCode: "36109" },
    { lng: -86.253867, lat: 32.403987, postalCode: "36114" },
    { lng: -86.92968, lat: 33.559455, postalCode: "35060" },
    { lng: -86.90004, lat: 32.731866, postalCode: "36790" },
    { lng: -86.621931, lat: 33.158454, postalCode: "35050" },
    { lng: -88.08201, lat: 30.737405, postalCode: "36610" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35245" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36512" },
    { lng: -87.36217, lat: 32.170653, postalCode: "36720" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35812" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36135" },
    { lng: -85.76816, lat: 34.189256, postalCode: "35983" },
    { lng: -85.43298, lat: 31.843675, postalCode: "36016" },
    { lng: -88.18661, lat: 31.96958, postalCode: "36921" },
    { lng: -87.453224, lat: 33.306606, postalCode: "35402" },
    { lng: -86.15169, lat: 31.203287, postalCode: "36453" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36133" },
    { lng: -88.11116, lat: 30.753155, postalCode: "36612" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35220" },
    { lng: -86.96271, lat: 31.461019, postalCode: "36401" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36134" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36191" },
    { lng: -86.21902, lat: 32.592889, postalCode: "36092" },
    { lng: -87.76099, lat: 31.242801, postalCode: "36550" },
    { lng: -85.992, lat: 31.780847, postalCode: "36079" },
    { lng: -86.81352, lat: 34.972309, postalCode: "35739" },
    { lng: -87.4686, lat: 30.511685, postalCode: "36574" },
    { lng: -86.45813, lat: 33.944897, postalCode: "35121" },
    { lng: -85.01943, lat: 32.492304, postalCode: "36867" },
    { lng: -87.088142, lat: 34.606216, postalCode: "35602" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36623" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35181" },
    { lng: -87.80695, lat: 34.428048, postalCode: "35585" },
    { lng: -87.06231, lat: 34.860179, postalCode: "35614" },
    { lng: -87.152068, lat: 31.128242, postalCode: "36503" },
    { lng: -85.60789, lat: 31.262955, postalCode: "36352" },
    { lng: -88.05341, lat: 30.688457, postalCode: "36603" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36690" },
    { lng: -86.06661, lat: 32.171705, postalCode: "36052" },
    { lng: -87.0193, lat: 33.443039, postalCode: "35023" },
    { lng: -86.87347, lat: 33.406869, postalCode: "35142" },
    { lng: -86.522913, lat: 34.733964, postalCode: "35815" },
    { lng: -85.4045, lat: 31.180076, postalCode: "36301" },
    { lng: -85.70509, lat: 31.352676, postalCode: "36362" },
    { lng: -88.10249, lat: 30.674057, postalCode: "36606" },
    { lng: -85.99653, lat: 32.295289, postalCode: "36013" },
    { lng: -86.1698, lat: 33.116909, postalCode: "35082" },
    { lng: -86.54293, lat: 34.603017, postalCode: "35803" },
    { lng: -88.18552, lat: 30.586808, postalCode: "36619" },
    { lng: -87.88346, lat: 31.508231, postalCode: "36545" },
    { lng: -87.52749, lat: 33.205073, postalCode: "35403" },
    { lng: -86.95117, lat: 33.041166, postalCode: "35035" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35238" },
    { lng: -86.06169, lat: 34.431683, postalCode: "35975" },
    { lng: -87.73468, lat: 30.312316, postalCode: "36511" },
    { lng: -86.208528, lat: 32.233377, postalCode: "36121" },
    { lng: -86.60703, lat: 33.827521, postalCode: "35133" },
    { lng: -87.21488, lat: 32.915182, postalCode: "35034" },
    { lng: -86.0477, lat: 32.776549, postalCode: "36026" },
    { lng: -87.96228, lat: 31.213801, postalCode: "36556" },
    { lng: -86.929208, lat: 33.544622, postalCode: "35123" },
    { lng: -87.2125, lat: 33.883566, postalCode: "35504" },
    { lng: -87.044687, lat: 31.466945, postalCode: "36454" },
    { lng: -87.23957, lat: 33.451714, postalCode: "35006" },
    { lng: -86.70576, lat: 34.550375, postalCode: "35775" },
    { lng: -87.83026, lat: 33.681768, postalCode: "35555" },
    { lng: -86.61397, lat: 31.416059, postalCode: "36474" },
    { lng: -85.73589, lat: 32.251957, postalCode: "36031" },
    { lng: -88.177975, lat: 30.658865, postalCode: "36685" },
    { lng: -87.56843, lat: 33.193818, postalCode: "35401" },
    { lng: -87.84521, lat: 32.495484, postalCode: "36732" },
    { lng: -87.16252, lat: 33.195743, postalCode: "35188" },
    { lng: -86.02665, lat: 33.906415, postalCode: "35907" },
    { lng: -86.76708, lat: 33.590003, postalCode: "35217" },
    { lng: -85.837828, lat: 33.762195, postalCode: "36202" },
    { lng: -85.30102, lat: 33.046756, postalCode: "36855" },
    { lng: -86.132981, lat: 32.587631, postalCode: "36045" },
    { lng: -85.84588, lat: 32.862333, postalCode: "36861" },
    { lng: -87.453224, lat: 33.306606, postalCode: "35478" },
    { lng: -85.85178, lat: 33.658803, postalCode: "36201" },
    { lng: -86.81518, lat: 33.611297, postalCode: "35068" },
    { lng: -85.84117, lat: 31.32579, postalCode: "36330" },
    { lng: -85.79955, lat: 32.787441, postalCode: "36853" },
    { lng: -93.61788, lat: 34.679542, postalCode: "71969" },
    { lng: -92.22148, lat: 35.102318, postalCode: "72173" },
    { lng: -90.20277, lat: 36.393325, postalCode: "72454" },
    { lng: -91.54989, lat: 35.321261, postalCode: "72010" },
    { lng: -92.26865, lat: 34.690671, postalCode: "72206" },
    { lng: -91.534303, lat: 35.734617, postalCode: "72503" },
    { lng: -93.22996, lat: 33.242571, postalCode: "71754" },
    { lng: -91.10435, lat: 35.569503, postalCode: "72005" },
    { lng: -94.234, lat: 35.057119, postalCode: "72944" },
    { lng: -94.41065, lat: 35.72446, postalCode: "72948" },
    { lng: -94.43534, lat: 35.864399, postalCode: "72749" },
    { lng: -94.32246, lat: 35.951426, postalCode: "72753" },
    { lng: -92.01479, lat: 34.604268, postalCode: "72083" },
    { lng: -90.20234, lat: 35.200501, postalCode: "72364" },
    { lng: -93.70591, lat: 34.373399, postalCode: "71935" },
    { lng: -92.82756, lat: 35.567694, postalCode: "72080" },
    { lng: -92.17913, lat: 34.777238, postalCode: "72117" },
    { lng: -93.67372, lat: 33.102083, postalCode: "71826" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72219" },
    { lng: -92.081022, lat: 33.608542, postalCode: "71691" },
    { lng: -90.755195, lat: 34.769714, postalCode: "72352" },
    { lng: -92.27987, lat: 34.745692, postalCode: "72201" },
    { lng: -90.78048, lat: 35.010683, postalCode: "72335" },
    { lng: -93.294126, lat: 33.70082, postalCode: "71864" },
    { lng: -94.50146, lat: 36.027493, postalCode: "72769" },
    { lng: -91.259428, lat: 35.179148, postalCode: "72189" },
    { lng: -92.59562, lat: 34.564734, postalCode: "72015" },
    { lng: -91.804608, lat: 36.374704, postalCode: "72557" },
    { lng: -93.3744, lat: 36.492129, postalCode: "72660" },
    { lng: -90.74383, lat: 36.21405, postalCode: "72425" },
    { lng: -94.2486, lat: 36.096998, postalCode: "72704" },
    { lng: -94.24384, lat: 33.780463, postalCode: "71820" },
    { lng: -90.79589, lat: 35.927057, postalCode: "72416" },
    { lng: -93.25496, lat: 35.014918, postalCode: "72853" },
    { lng: -94.13624, lat: 34.907335, postalCode: "72958" },
    { lng: -91.832539, lat: 34.277534, postalCode: "71612" },
    { lng: -93.23591, lat: 34.663502, postalCode: "71956" },
    { lng: -93.28528, lat: 35.380528, postalCode: "72847" },
    { lng: -93.92692, lat: 33.859173, postalCode: "71851" },
    { lng: -94.03786, lat: 34.152505, postalCode: "71833" },
    { lng: -94.318648, lat: 35.494412, postalCode: "72957" },
    { lng: -91.930535, lat: 35.581607, postalCode: "72546" },
    { lng: -91.32515, lat: 34.283347, postalCode: "72042" },
    { lng: -92.75858, lat: 35.156491, postalCode: "72110" },
    { lng: -92.90795, lat: 35.261843, postalCode: "72823" },
    { lng: -91.92632, lat: 35.929689, postalCode: "72540" },
    { lng: -93.7512, lat: 33.865305, postalCode: "71855" },
    { lng: -92.390766, lat: 34.883484, postalCode: "72203" },
    { lng: -90.99827, lat: 36.042319, postalCode: "72433" },
    { lng: -90.516585, lat: 36.047718, postalCode: "72452" },
    { lng: -91.75019, lat: 36.225645, postalCode: "72512" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72215" },
    { lng: -94.31088, lat: 35.562293, postalCode: "72952" },
    { lng: -93.33704, lat: 34.072187, postalCode: "71962" },
    { lng: -94.25261, lat: 36.03667, postalCode: "72730" },
    { lng: -91.14873, lat: 34.584347, postalCode: "72069" },
    { lng: -90.71725, lat: 35.555062, postalCode: "72432" },
    { lng: -90.81547, lat: 35.061156, postalCode: "72322" },
    { lng: -94.23551, lat: 36.458041, postalCode: "72714" },
    { lng: -90.22848, lat: 35.375704, postalCode: "72384" },
    { lng: -92.07792, lat: 34.689349, postalCode: "72142" },
    { lng: -91.52399, lat: 34.955641, postalCode: "72040" },
    { lng: -90.414113, lat: 35.531429, postalCode: "72345" },
    { lng: -91.20318, lat: 35.731301, postalCode: "72473" },
    { lng: -90.75676, lat: 36.362896, postalCode: "72462" },
    { lng: -91.07618, lat: 35.650914, postalCode: "72431" },
    { lng: -91.70461, lat: 36.033231, postalCode: "72577" },
    { lng: -92.29362, lat: 36.193278, postalCode: "72658" },
    { lng: -91.30412, lat: 35.640464, postalCode: "72075" },
    { lng: -93.25985, lat: 35.955198, postalCode: "72666" },
    { lng: -93.63751, lat: 33.920355, postalCode: "71847" },
    { lng: -94.07106, lat: 36.40137, postalCode: "72711" },
    { lng: -91.13632, lat: 34.28965, postalCode: "72048" },
    { lng: -94.15357, lat: 35.649503, postalCode: "72946" },
    { lng: -92.66488, lat: 35.51921, postalCode: "72141" },
    { lng: -93.8354, lat: 35.829657, postalCode: "72721" },
    { lng: -90.14514, lat: 35.512756, postalCode: "72350" },
    { lng: -92.32243, lat: 35.814005, postalCode: "72051" },
    { lng: -93.35469, lat: 35.299773, postalCode: "72835" },
    { lng: -90.97694, lat: 34.189902, postalCode: "72367" },
    { lng: -92.1854, lat: 34.443452, postalCode: "72132" },
    { lng: -92.37364, lat: 36.004445, postalCode: "72617" },
    { lng: -92.90485, lat: 33.325889, postalCode: "71758" },
    { lng: -94.3691, lat: 34.496472, postalCode: "71945" },
    { lng: -93.42594, lat: 35.108311, postalCode: "72824" },
    { lng: -92.02327, lat: 35.489516, postalCode: "72543" },
    { lng: -91.70488, lat: 34.110042, postalCode: "71644" },
    { lng: -92.12962, lat: 36.245162, postalCode: "72528" },
    { lng: -90.57059, lat: 35.92007, postalCode: "72417" },
    { lng: -94.30853, lat: 35.332908, postalCode: "72923" },
    { lng: -94.1082, lat: 36.182407, postalCode: "72764" },
    { lng: -93.189406, lat: 34.055486, postalCode: "71721" },
    { lng: -92.318651, lat: 34.661442, postalCode: "72259" },
    { lng: -94.14717, lat: 33.961768, postalCode: "71846" },
    { lng: -92.07641, lat: 35.141743, postalCode: "72045" },
    { lng: -94.23816, lat: 35.191046, postalCode: "72913" },
    { lng: -93.85202, lat: 33.271824, postalCode: "71837" },
    { lng: -93.40111, lat: 36.327014, postalCode: "72638" },
    { lng: -94.14867, lat: 33.741986, postalCode: "71865" },
    { lng: -92.42483, lat: 34.969848, postalCode: "72106" },
    { lng: -94.46394, lat: 36.3389, postalCode: "72722" },
    { lng: -94.0371, lat: 35.312232, postalCode: "72933" },
    { lng: -90.70524, lat: 36.460317, postalCode: "72470" },
    { lng: -92.18501, lat: 33.050688, postalCode: "71747" },
    { lng: -93.71273, lat: 33.761309, postalCode: "71862" },
    { lng: -91.99286, lat: 35.555144, postalCode: "72581" },
    { lng: -93.927748, lat: 36.486424, postalCode: "72733" },
    { lng: -90.94537, lat: 35.867472, postalCode: "72427" },
    { lng: -92.27505, lat: 35.311481, postalCode: "72061" },
    { lng: -93.4851, lat: 35.490105, postalCode: "72830" },
    { lng: -90.46421, lat: 35.266812, postalCode: "72331" },
    { lng: -91.67048, lat: 35.233947, postalCode: "72082" },
    { lng: -90.772419, lat: 35.296539, postalCode: "72385" },
    { lng: -90.04314, lat: 35.855743, postalCode: "72426" },
    { lng: -93.68605, lat: 36.1161, postalCode: "72740" },
    { lng: -91.46586, lat: 36.085113, postalCode: "72569" },
    { lng: -92.681408, lat: 35.916744, postalCode: "72636" },
    { lng: -93.20045, lat: 33.362351, postalCode: "71752" },
    { lng: -93.113308, lat: 35.314678, postalCode: "72811" },
    { lng: -91.26055, lat: 35.995653, postalCode: "72440" },
    { lng: -91.15872, lat: 36.21601, postalCode: "72434" },
    { lng: -93.8805, lat: 35.312316, postalCode: "72951" },
    { lng: -92.968531, lat: 34.513682, postalCode: "71914" },
    { lng: -91.62566, lat: 35.561834, postalCode: "72568" },
    { lng: -91.98585, lat: 34.434204, postalCode: "72168" },
    { lng: -94.13465, lat: 33.675597, postalCode: "71822" },
    { lng: -92.31954, lat: 33.346473, postalCode: "71651" },
    { lng: -91.77681, lat: 34.351954, postalCode: "72175" },
    { lng: -91.7544, lat: 35.873117, postalCode: "72526" },
    { lng: -94.21567, lat: 34.588479, postalCode: "71953" },
    { lng: -89.89214, lat: 35.777085, postalCode: "72358" },
    { lng: -92.54349, lat: 34.330808, postalCode: "72129" },
    { lng: -91.732829, lat: 33.590601, postalCode: "71657" },
    { lng: -94.219977, lat: 35.99403, postalCode: "72766" },
    { lng: -92.26477, lat: 33.580919, postalCode: "71631" },
    { lng: -94.11371, lat: 36.247575, postalCode: "72745" },
    { lng: -93.49293, lat: 33.35996, postalCode: "71860" },
    { lng: -93.59486, lat: 33.657969, postalCode: "71801" },
    { lng: -91.55866, lat: 33.059255, postalCode: "71676" },
    { lng: -91.76272, lat: 33.62086, postalCode: "71655" },
    { lng: -93.12978, lat: 36.083655, postalCode: "72648" },
    { lng: -91.46945, lat: 34.745671, postalCode: "72041" },
    { lng: -92.20204, lat: 35.402236, postalCode: "72131" },
    { lng: -92.021291, lat: 35.535238, postalCode: "72545" },
    { lng: -92.6754, lat: 35.284619, postalCode: "72156" },
    { lng: -93.099403, lat: 34.581374, postalCode: "71951" },
    { lng: -92.56519, lat: 34.058224, postalCode: "71725" },
    { lng: -93.61675, lat: 35.437519, postalCode: "72840" },
    { lng: -93.189406, lat: 34.055486, postalCode: "71999" },
    { lng: -92.284718, lat: 34.827174, postalCode: "72199" },
    { lng: -93.01966, lat: 33.686264, postalCode: "71726" },
    { lng: -91.259428, lat: 35.179148, postalCode: "72059" },
    { lng: -93.71734, lat: 33.312764, postalCode: "71839" },
    { lng: -91.92244, lat: 35.008529, postalCode: "72176" },
    { lng: -93.42614, lat: 34.033062, postalCode: "71922" },
    { lng: -90.04742, lat: 35.585875, postalCode: "72395" },
    { lng: -93.13364, lat: 33.704074, postalCode: "71722" },
    { lng: -92.08101, lat: 33.608004, postalCode: "71671" },
    { lng: -90.76901, lat: 34.554466, postalCode: "72355" },
    { lng: -94.35083, lat: 34.302177, postalCode: "71973" },
    { lng: -92.89278, lat: 34.437118, postalCode: "72105" },
    { lng: -91.27239, lat: 33.763205, postalCode: "71666" },
    { lng: -92.81449, lat: 36.483329, postalCode: "65733" },
    { lng: -90.17029, lat: 35.83821, postalCode: "72442" },
    { lng: -91.37245, lat: 36.279796, postalCode: "72482" },
    { lng: -92.071284, lat: 34.157876, postalCode: "71613" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72078" },
    { lng: -94.152899, lat: 35.928186, postalCode: "72737" },
    { lng: -91.98711, lat: 36.400178, postalCode: "72583" },
    { lng: -94.11998, lat: 35.478787, postalCode: "72935" },
    { lng: -92.76449, lat: 33.067477, postalCode: "71749" },
    { lng: -92.57876, lat: 35.365909, postalCode: "72027" },
    { lng: -92.47898, lat: 33.521575, postalCode: "71744" },
    { lng: -92.4036, lat: 35.339128, postalCode: "72039" },
    { lng: -90.37402, lat: 35.823762, postalCode: "72414" },
    { lng: -94.58854, lat: 36.381787, postalCode: "72747" },
    { lng: -93.35706, lat: 35.376739, postalCode: "72845" },
    { lng: -91.54633, lat: 33.323463, postalCode: "71658" },
    { lng: -91.72234, lat: 36.327307, postalCode: "72539" },
    { lng: -93.854484, lat: 33.316578, postalCode: "71840" },
    { lng: -93.13425, lat: 35.906856, postalCode: "72683" },
    { lng: -94.50208, lat: 36.179905, postalCode: "72761" },
    { lng: -92.95354, lat: 35.517382, postalCode: "72843" },
    { lng: -93.70593, lat: 34.88007, postalCode: "72838" },
    { lng: -90.69114, lat: 36.128323, postalCode: "72412" },
    { lng: -92.32589, lat: 35.619538, postalCode: "72153" },
    { lng: -91.41876, lat: 33.737729, postalCode: "71670" },
    { lng: -91.92355, lat: 36.204582, postalCode: "72565" },
    { lng: -94.05225, lat: 35.528854, postalCode: "72947" },
    { lng: -93.099403, lat: 34.581374, postalCode: "71903" },
    { lng: -94.27583, lat: 35.668554, postalCode: "72934" },
    { lng: -91.84428, lat: 35.648717, postalCode: "72523" },
    { lng: -91.35603, lat: 35.251768, postalCode: "72006" },
    { lng: -91.7679, lat: 34.252213, postalCode: "72004" },
    { lng: -92.23701, lat: 36.428401, postalCode: "72537" },
    { lng: -91.97037, lat: 33.125032, postalCode: "71635" },
    { lng: -91.7353, lat: 36.388724, postalCode: "72520" },
    { lng: -89.86945, lat: 35.629555, postalCode: "38041" },
    { lng: -91.93607, lat: 33.592313, postalCode: "71675" },
    { lng: -93.099403, lat: 34.581374, postalCode: "71902" },
    { lng: -90.17801, lat: 35.443275, postalCode: "72338" },
    { lng: -92.34988, lat: 33.123334, postalCode: "71765" },
    { lng: -90.64636, lat: 34.485028, postalCode: "72342" },
    { lng: -91.15428, lat: 34.383661, postalCode: "72140" },
    { lng: -94.46137, lat: 36.269478, postalCode: "72734" },
    { lng: -92.02036, lat: 36.170391, postalCode: "72585" },
    { lng: -92.622857, lat: 34.597345, postalCode: "72018" },
    { lng: -91.00582, lat: 34.805649, postalCode: "72368" },
    { lng: -92.19513, lat: 34.600398, postalCode: "72183" },
    { lng: -92.354915, lat: 35.105306, postalCode: "72033" },
    { lng: -92.20206, lat: 36.388751, postalCode: "72544" },
    { lng: -94.19661, lat: 35.896307, postalCode: "72774" },
    { lng: -92.48834, lat: 33.741892, postalCode: "71766" },
    { lng: -93.232298, lat: 35.924868, postalCode: "72670" },
    { lng: -94.23816, lat: 35.191046, postalCode: "72917" },
    { lng: -91.0141, lat: 36.304912, postalCode: "72455" },
    { lng: -91.25449, lat: 35.011873, postalCode: "72036" },
    { lng: -93.83656, lat: 34.29627, postalCode: "71952" },
    { lng: -90.5875, lat: 35.292009, postalCode: "72373" },
    { lng: -91.10958, lat: 34.726737, postalCode: "72108" },
    { lng: -94.35535, lat: 34.24238, postalCode: "71944" },
    { lng: -92.91702, lat: 36.461305, postalCode: "72630" },
    { lng: -93.189406, lat: 34.055486, postalCode: "71920" },
    { lng: -92.12244, lat: 34.879419, postalCode: "72076" },
    { lng: -93.03315, lat: 34.727093, postalCode: "71949" },
    { lng: -92.64911, lat: 33.218456, postalCode: "71730" },
    { lng: -93.58986, lat: 33.326251, postalCode: "71845" },
    { lng: -91.45128, lat: 35.819494, postalCode: "72522" },
    { lng: -90.028429, lat: 35.712249, postalCode: "72313" },
    { lng: -91.27133, lat: 33.351501, postalCode: "71653" },
    { lng: -90.66941, lat: 36.28117, postalCode: "72453" },
    { lng: -92.16056, lat: 35.624351, postalCode: "72044" },
    { lng: -92.41345, lat: 34.749723, postalCode: "72211" },
    { lng: -92.48025, lat: 36.389591, postalCode: "72651" },
    { lng: -93.51733, lat: 34.082845, postalCode: "71940" },
    { lng: -91.36875, lat: 34.109348, postalCode: "72055" },
    { lng: -92.647817, lat: 33.97547, postalCode: "71748" },
    { lng: -91.15181, lat: 36.065739, postalCode: "72458" },
    { lng: -92.69301, lat: 36.489216, postalCode: "65761" },
    { lng: -94.39103, lat: 35.406185, postalCode: "72904" },
    { lng: -93.5993, lat: 34.320028, postalCode: "71943" },
    { lng: -93.032099, lat: 36.308349, postalCode: "72615" },
    { lng: -91.70833, lat: 35.169853, postalCode: "72068" },
    { lng: -92.5856, lat: 36.380815, postalCode: "72619" },
    { lng: -92.925, lat: 36.424286, postalCode: "72644" },
    { lng: -92.89576, lat: 36.150276, postalCode: "72633" },
    { lng: -91.65148, lat: 35.319143, postalCode: "72081" },
    { lng: -90.77793, lat: 34.764904, postalCode: "72360" },
    { lng: -92.48355, lat: 35.586064, postalCode: "72031" },
    { lng: -92.39227, lat: 33.525889, postalCode: "71745" },
    { lng: -94.079724, lat: 34.882558, postalCode: "72924" },
    { lng: -98.20799, lat: 29.542778, postalCode: "78081" },
    { lng: -94.23308, lat: 36.362525, postalCode: "72712" },
    { lng: -90.83237, lat: 35.229779, postalCode: "72396" },
    { lng: -91.19271, lat: 34.867846, postalCode: "72021" },
    { lng: -91.15045, lat: 36.120567, postalCode: "72415" },
    { lng: -91.27997, lat: 36.20619, postalCode: "72459" },
    { lng: -91.30334, lat: 35.836565, postalCode: "72524" },
    { lng: -92.65463, lat: 34.17618, postalCode: "72084" },
    { lng: -92.53279, lat: 36.276949, postalCode: "72626" },
    { lng: -93.112368, lat: 35.329457, postalCode: "72822" },
    { lng: -90.671802, lat: 36.041332, postalCode: "72474" },
    { lng: -90.703915, lat: 35.830541, postalCode: "72403" },
    { lng: -90.57906, lat: 36.315305, postalCode: "72435" },
    { lng: -92.11722, lat: 34.908539, postalCode: "72099" },
    { lng: -93.189406, lat: 34.055486, postalCode: "71998" },
    { lng: -94.31606, lat: 36.472567, postalCode: "72715" },
    { lng: -91.24235, lat: 33.873022, postalCode: "71674" },
    { lng: -90.81054, lat: 36.323353, postalCode: "72413" },
    { lng: -93.45822, lat: 34.257603, postalCode: "71921" },
    { lng: -93.17973, lat: 35.199221, postalCode: "72834" },
    { lng: -90.46399, lat: 35.079258, postalCode: "72346" },
    { lng: -92.37117, lat: 34.771024, postalCode: "72227" },
    { lng: -93.56324, lat: 36.350821, postalCode: "72616" },
    { lng: -89.972762, lat: 35.852046, postalCode: "72310" },
    { lng: -93.77678, lat: 34.866017, postalCode: "72841" },
    { lng: -90.525077, lat: 36.11635, postalCode: "72451" },
    { lng: -91.25206, lat: 33.283901, postalCode: "71649" },
    { lng: -92.39319, lat: 34.31985, postalCode: "72150" },
    { lng: -90.8989, lat: 35.111687, postalCode: "72326" },
    { lng: -92.24764, lat: 34.799541, postalCode: "72116" },
    { lng: -93.76005, lat: 34.251107, postalCode: "71950" },
    { lng: -93.294126, lat: 33.70082, postalCode: "71828" },
    { lng: -91.59919, lat: 36.088921, postalCode: "72532" },
    { lng: -93.18468, lat: 33.091077, postalCode: "71740" },
    { lng: -91.40953, lat: 34.403216, postalCode: "72003" },
    { lng: -92.55142, lat: 34.880442, postalCode: "72135" },
    { lng: -92.65974, lat: 34.635041, postalCode: "72158" },
    { lng: -93.24096, lat: 34.424114, postalCode: "71964" },
    { lng: -92.48605, lat: 36.021569, postalCode: "72639" },
    { lng: -91.88291, lat: 35.819261, postalCode: "72567" },
    { lng: -92.71047, lat: 35.023369, postalCode: "72070" },
    { lng: -90.72317, lat: 35.013693, postalCode: "72359" },
    { lng: -93.44323, lat: 35.289176, postalCode: "72851" },
    { lng: -94.153512, lat: 36.172541, postalCode: "72765" },
    { lng: -90.748876, lat: 36.06805, postalCode: "72439" },
    { lng: -90.3249, lat: 35.624419, postalCode: "72354" },
    { lng: -92.7549, lat: 34.774077, postalCode: "72122" },
    { lng: -94.23816, lat: 35.191046, postalCode: "72906" },
    { lng: -93.94723, lat: 35.297936, postalCode: "72928" },
    { lng: -92.34242, lat: 34.771291, postalCode: "72207" },
    { lng: -92.39981, lat: 34.854746, postalCode: "72113" },
    { lng: -94.04509, lat: 35.426502, postalCode: "72930" },
    { lng: -93.43692, lat: 33.378452, postalCode: "71827" },
    { lng: -89.86859, lat: 35.637993, postalCode: "38063" },
    { lng: -90.88239, lat: 36.422163, postalCode: "72444" },
    { lng: -90.44153, lat: 34.935197, postalCode: "72348" },
    { lng: -91.50875, lat: 35.36083, postalCode: "72139" },
    { lng: -92.14897, lat: 33.384421, postalCode: "71647" },
    { lng: -91.68913, lat: 35.733939, postalCode: "72527" },
    { lng: -92.67942, lat: 35.423473, postalCode: "72030" },
    { lng: -94.41283, lat: 35.301623, postalCode: "72908" },
    { lng: -94.17279, lat: 35.348089, postalCode: "72941" },
    { lng: -93.01941, lat: 34.506487, postalCode: "71901" },
    { lng: -91.75644, lat: 36.155897, postalCode: "72536" },
    { lng: -93.88694, lat: 34.229742, postalCode: "71959" },
    { lng: -92.86841, lat: 34.965699, postalCode: "72126" },
    { lng: -93.17279, lat: 35.110131, postalCode: "72829" },
    { lng: -90.726069, lat: 35.100867, postalCode: "72336" },
    { lng: -91.3892, lat: 34.640886, postalCode: "72134" },
    { lng: -93.58735, lat: 35.111397, postalCode: "72842" },
    { lng: -92.30229, lat: 34.813269, postalCode: "72118" },
    { lng: -91.7639, lat: 35.710614, postalCode: "72550" },
    { lng: -93.747, lat: 35.44477, postalCode: "72821" },
    { lng: -90.02631, lat: 35.696385, postalCode: "72370" },
    { lng: -94.37789, lat: 35.265119, postalCode: "72916" },
    { lng: -90.95873, lat: 35.49293, postalCode: "72429" },
    { lng: -93.05585, lat: 34.111837, postalCode: "71923" },
    { lng: -93.28269, lat: 33.551439, postalCode: "71858" },
    { lng: -92.75237, lat: 36.136628, postalCode: "72682" },
    { lng: -90.22683, lat: 35.941576, postalCode: "72438" },
    { lng: -93.7476, lat: 36.392122, postalCode: "72632" },
    { lng: -91.98078, lat: 34.352844, postalCode: "72152" },
    { lng: -92.33251, lat: 33.914079, postalCode: "71652" },
    { lng: -92.26463, lat: 34.763924, postalCode: "72114" },
    { lng: -90.67579, lat: 35.843125, postalCode: "72467" },
    { lng: -91.5784, lat: 34.004537, postalCode: "71643" },
    { lng: -91.83957, lat: 33.945654, postalCode: "71667" },
    { lng: -92.31641, lat: 34.14741, postalCode: "72057" },
    { lng: -90.94429, lat: 36.068015, postalCode: "72476" },
    { lng: -94.46911, lat: 36.484548, postalCode: "72768" },
    { lng: -92.43421, lat: 33.834201, postalCode: "71742" },
    { lng: -93.58361, lat: 34.571277, postalCode: "71957" },
    { lng: -91.0194, lat: 34.044682, postalCode: "72379" },
    { lng: -92.54648, lat: 35.145915, postalCode: "72107" },
    { lng: -93.559162, lat: 33.765584, postalCode: "71802" },
    { lng: -93.67368, lat: 35.435619, postalCode: "72832" },
    { lng: -90.76307, lat: 35.387053, postalCode: "72324" },
    { lng: -92.26358, lat: 35.598238, postalCode: "72088" },
    { lng: -89.966819, lat: 35.694942, postalCode: "72391" },
    { lng: -90.96503, lat: 34.981162, postalCode: "72372" },
    { lng: -91.29347, lat: 34.697068, postalCode: "72029" },
    { lng: -91.74043, lat: 35.588979, postalCode: "72534" },
    { lng: -90.32581, lat: 35.755308, postalCode: "72419" },
    { lng: -93.73383, lat: 35.415616, postalCode: "72820" },
    { lng: -91.259428, lat: 35.179148, postalCode: "72071" },
    { lng: -92.81368, lat: 33.578096, postalCode: "71701" },
    { lng: -92.77395, lat: 36.021564, postalCode: "72675" },
    { lng: -93.94296, lat: 35.970492, postalCode: "72727" },
    { lng: -93.80443, lat: 35.184949, postalCode: "72943" },
    { lng: -91.80836, lat: 36.058198, postalCode: "72573" },
    { lng: -93.95261, lat: 33.09863, postalCode: "71834" },
    { lng: -92.36279, lat: 35.452494, postalCode: "72013" },
    { lng: -94.39462, lat: 35.15875, postalCode: "72937" },
    { lng: -92.63984, lat: 34.32012, postalCode: "72128" },
    { lng: -91.59288, lat: 34.781079, postalCode: "72064" },
    { lng: -91.26187, lat: 35.633263, postalCode: "72043" },
    { lng: -94.38408, lat: 33.865609, postalCode: "71866" },
    { lng: -92.21175, lat: 34.528857, postalCode: "72180" },
    { lng: -92.127207, lat: 35.91896, postalCode: "72657" },
    { lng: -92.22764, lat: 34.708292, postalCode: "72053" },
    { lng: -91.86188, lat: 33.375479, postalCode: "71642" },
    { lng: -89.966819, lat: 35.694942, postalCode: "72319" },
    { lng: -91.82757, lat: 36.136468, postalCode: "72584" },
    { lng: -91.1314, lat: 35.821708, postalCode: "72471" },
    { lng: -94.41371, lat: 35.373791, postalCode: "72901" },
    { lng: -93.19533, lat: 34.285033, postalCode: "71929" },
    { lng: -91.57169, lat: 34.165734, postalCode: "72133" },
    { lng: -90.14377, lat: 36.458348, postalCode: "72464" },
    { lng: -93.31176, lat: 33.34937, postalCode: "71770" },
    { lng: -91.63835, lat: 35.78108, postalCode: "72501" },
    { lng: -93.122554, lat: 36.355213, postalCode: "72602" },
    { lng: -93.00689, lat: 35.043093, postalCode: "72025" },
    { lng: -92.08691, lat: 36.186879, postalCode: "72566" },
    { lng: -92.68844, lat: 36.255295, postalCode: "72677" },
    { lng: -94.36914, lat: 35.357041, postalCode: "72903" },
    { lng: -93.73715, lat: 35.82635, postalCode: "72760" },
    { lng: -92.06141, lat: 35.341123, postalCode: "72137" },
    { lng: -93.47918, lat: 35.992386, postalCode: "72742" },
    { lng: -91.24695, lat: 35.586065, postalCode: "72112" },
    { lng: -93.52793, lat: 35.358493, postalCode: "72863" },
    { lng: -92.48429, lat: 36.297345, postalCode: "72635" },
    { lng: -91.4657, lat: 33.749668, postalCode: "71677" },
    { lng: -90.84231, lat: 34.554559, postalCode: "72374" },
    { lng: -94.242884, lat: 36.14998, postalCode: "72770" },
    { lng: -93.0179, lat: 35.927363, postalCode: "72655" },
    { lng: -91.944673, lat: 36.064329, postalCode: "72516" },
    { lng: -92.57635, lat: 36.263145, postalCode: "72634" },
    { lng: -93.097627, lat: 34.010316, postalCode: "71728" },
    { lng: -94.115649, lat: 36.371981, postalCode: "72757" },
    { lng: -93.699392, lat: 36.431111, postalCode: "72613" },
    { lng: -91.732829, lat: 33.590601, postalCode: "71650" },
    { lng: -91.20577, lat: 33.608935, postalCode: "71630" },
    { lng: -93.234494, lat: 33.236156, postalCode: "71769" },
    { lng: -94.23816, lat: 35.191046, postalCode: "72918" },
    { lng: -90.49913, lat: 36.205251, postalCode: "72436" },
    { lng: -93.88706, lat: 36.155775, postalCode: "72738" },
    { lng: -92.3355, lat: 34.752391, postalCode: "72205" },
    { lng: -92.43401, lat: 35.075467, postalCode: "72032" },
    { lng: -91.02349, lat: 35.972767, postalCode: "72445" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72225" },
    { lng: -90.41892, lat: 35.534421, postalCode: "72365" },
    { lng: -92.0417, lat: 36.293095, postalCode: "72515" },
    { lng: -93.31302, lat: 36.272469, postalCode: "72611" },
    { lng: -91.74635, lat: 34.776306, postalCode: "72024" },
    { lng: -94.42735, lat: 35.954697, postalCode: "72744" },
    { lng: -92.7496, lat: 35.320423, postalCode: "72063" },
    { lng: -92.03537, lat: 34.967043, postalCode: "72023" },
    { lng: -92.52891, lat: 33.198024, postalCode: "71750" },
    { lng: -93.55764, lat: 35.689689, postalCode: "72852" },
    { lng: -93.69401, lat: 34.118589, postalCode: "71958" },
    { lng: -89.93751, lat: 35.818518, postalCode: "72321" },
    { lng: -93.42258, lat: 35.686766, postalCode: "72854" },
    { lng: -92.50223, lat: 34.644387, postalCode: "72002" },
    { lng: -92.88803, lat: 36.087461, postalCode: "72669" },
    { lng: -94.35097, lat: 35.090489, postalCode: "72945" },
    { lng: -93.36595, lat: 33.806886, postalCode: "71857" },
    { lng: -92.54556, lat: 35.755793, postalCode: "72629" },
    { lng: -93.64583, lat: 35.934679, postalCode: "72776" },
    { lng: -92.79432, lat: 35.056424, postalCode: "72125" },
    { lng: -92.06612, lat: 35.638247, postalCode: "72130" },
    { lng: -93.10863, lat: 35.460782, postalCode: "72837" },
    { lng: -91.90636, lat: 35.095774, postalCode: "72012" },
    { lng: -93.56071, lat: 35.824911, postalCode: "72752" },
    { lng: -92.90985, lat: 35.747154, postalCode: "72686" },
    { lng: -91.92159, lat: 34.53785, postalCode: "72046" },
    { lng: -92.2399, lat: 34.687292, postalCode: "72164" },
    { lng: -92.76948, lat: 36.427511, postalCode: "72668" },
    { lng: -91.79176, lat: 33.224814, postalCode: "71646" },
    { lng: -92.66479, lat: 35.896883, postalCode: "72650" },
    { lng: -91.73139, lat: 35.247376, postalCode: "72149" },
    { lng: -92.35781, lat: 36.329026, postalCode: "72653" },
    { lng: -94.1937, lat: 36.186059, postalCode: "72762" },
    { lng: -90.36077, lat: 35.473821, postalCode: "72386" },
    { lng: -94.23816, lat: 35.191046, postalCode: "72905" },
    { lng: -92.558295, lat: 34.640273, postalCode: "72559" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72124" },
    { lng: -93.002367, lat: 34.223546, postalCode: "71942" },
    { lng: -92.80484, lat: 33.91855, postalCode: "71763" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72295" },
    { lng: -94.29398, lat: 36.36157, postalCode: "72719" },
    { lng: -92.259781, lat: 34.80165, postalCode: "72119" },
    { lng: -92.53817, lat: 36.378546, postalCode: "72642" },
    { lng: -92.49472, lat: 34.606079, postalCode: "72022" },
    { lng: -90.76129, lat: 34.903767, postalCode: "72341" },
    { lng: -93.4195, lat: 35.044021, postalCode: "72833" },
    { lng: -91.80062, lat: 34.154012, postalCode: "71659" },
    { lng: -93.80828, lat: 33.638783, postalCode: "71838" },
    { lng: -91.38236, lat: 33.628723, postalCode: "71654" },
    { lng: -91.47982, lat: 33.236401, postalCode: "71663" },
    { lng: -91.15291, lat: 35.224432, postalCode: "72101" },
    { lng: -92.354915, lat: 35.105306, postalCode: "72035" },
    { lng: -93.66463, lat: 34.542995, postalCode: "71966" },
    { lng: -94.23816, lat: 35.191046, postalCode: "72914" },
    { lng: -92.17314, lat: 36.317264, postalCode: "72531" },
    { lng: -90.68443, lat: 35.844795, postalCode: "72401" },
    { lng: -92.18447, lat: 33.736301, postalCode: "71660" },
    { lng: -91.58451, lat: 35.091184, postalCode: "72060" },
    { lng: -90.85597, lat: 34.305697, postalCode: "72333" },
    { lng: -93.71111, lat: 34.832089, postalCode: "72827" },
    { lng: -91.30213, lat: 34.505369, postalCode: "72026" },
    { lng: -94.01151, lat: 33.582879, postalCode: "71853" },
    { lng: -90.77134, lat: 35.328336, postalCode: "72387" },
    { lng: -90.677331, lat: 35.357184, postalCode: "72314" },
    { lng: -91.22927, lat: 35.391629, postalCode: "72169" },
    { lng: -92.372635, lat: 36.23638, postalCode: "72659" },
    { lng: -90.44247, lat: 35.82847, postalCode: "72437" },
    { lng: -90.89575, lat: 35.62608, postalCode: "72479" },
    { lng: -93.27737, lat: 35.839126, postalCode: "72628" },
    { lng: -93.4063, lat: 34.397266, postalCode: "71933" },
    { lng: -91.26107, lat: 33.118147, postalCode: "71640" },
    { lng: -93.90077, lat: 34.779465, postalCode: "72950" },
    { lng: -90.772419, lat: 35.296539, postalCode: "72397" },
    { lng: -93.29708, lat: 35.651525, postalCode: "72839" },
    { lng: -90.97793, lat: 34.131527, postalCode: "72328" },
    { lng: -89.966819, lat: 35.694942, postalCode: "72316" },
    { lng: -91.96619, lat: 36.126021, postalCode: "72517" },
    { lng: -90.30302, lat: 36.438645, postalCode: "72456" },
    { lng: -93.40287, lat: 34.949945, postalCode: "72860" },
    { lng: -92.46989, lat: 34.523858, postalCode: "72011" },
    { lng: -93.20427, lat: 33.249608, postalCode: "71753" },
    { lng: -92.82678, lat: 35.721896, postalCode: "72679" },
    { lng: -93.13682, lat: 35.278429, postalCode: "72801" },
    { lng: -92.842649, lat: 33.589009, postalCode: "71711" },
    { lng: -93.91747, lat: 33.740704, postalCode: "71859" },
    { lng: -94.05784, lat: 34.734204, postalCode: "72926" },
    { lng: -94.14622, lat: 36.306616, postalCode: "72758" },
    { lng: -91.875767, lat: 34.541185, postalCode: "72037" },
    { lng: -93.97531, lat: 35.105966, postalCode: "72927" },
    { lng: -91.86797, lat: 35.758314, postalCode: "72555" },
    { lng: -92.140701, lat: 34.89522, postalCode: "72098" },
    { lng: -94.47662, lat: 35.797242, postalCode: "72729" },
    { lng: -92.21721, lat: 35.220931, postalCode: "72047" },
    { lng: -93.73748, lat: 34.655204, postalCode: "71965" },
    { lng: -92.96277, lat: 36.062264, postalCode: "72685" },
    { lng: -93.65615, lat: 35.161856, postalCode: "72826" },
    { lng: -90.569, lat: 35.747274, postalCode: "72411" },
    { lng: -92.61975, lat: 35.155206, postalCode: "72127" },
    { lng: -93.72139, lat: 35.293281, postalCode: "72855" },
    { lng: -92.11109, lat: 36.427702, postalCode: "72538" },
    { lng: -94.45226, lat: 36.412233, postalCode: "72736" },
    { lng: -91.598917, lat: 35.691868, postalCode: "72575" },
    { lng: -93.96045, lat: 36.434311, postalCode: "72732" },
    { lng: -90.27537, lat: 35.411831, postalCode: "72339" },
    { lng: -92.37573, lat: 35.236993, postalCode: "72058" },
    { lng: -93.23783, lat: 35.979619, postalCode: "72641" },
    { lng: -94.200221, lat: 34.459413, postalCode: "71946" },
    { lng: -92.45297, lat: 35.163915, postalCode: "72181" },
    { lng: -91.203248, lat: 35.723939, postalCode: "72423" },
    { lng: -91.98841, lat: 34.215648, postalCode: "71601" },
    { lng: -93.81819, lat: 35.549469, postalCode: "72949" },
    { lng: -92.64664, lat: 33.733334, postalCode: "71720" },
    { lng: -94.23816, lat: 35.191046, postalCode: "72919" },
    { lng: -93.33057, lat: 34.865448, postalCode: "72857" },
    { lng: -92.69011, lat: 36.242942, postalCode: "72687" },
    { lng: -92.20272, lat: 34.401717, postalCode: "72079" },
    { lng: -90.97073, lat: 35.786094, postalCode: "72421" },
    { lng: -91.59744, lat: 36.302469, postalCode: "72525" },
    { lng: -93.03947, lat: 35.228264, postalCode: "72858" },
    { lng: -93.10909, lat: 35.308848, postalCode: "72802" },
    { lng: -91.5553, lat: 36.445205, postalCode: "72554" },
    { lng: -94.3049, lat: 34.163393, postalCode: "71841" },
    { lng: -91.28209, lat: 36.051063, postalCode: "72466" },
    { lng: -90.73187, lat: 36.384555, postalCode: "72424" },
    { lng: -92.40807, lat: 34.783505, postalCode: "72212" },
    { lng: -89.966819, lat: 35.694942, postalCode: "72381" },
    { lng: -90.99703, lat: 35.396138, postalCode: "72347" },
    { lng: -92.8146, lat: 34.364093, postalCode: "72104" },
    { lng: -94.33047, lat: 36.437635, postalCode: "72739" },
    { lng: -93.4857, lat: 34.669401, postalCode: "71970" },
    { lng: -94.219977, lat: 35.99403, postalCode: "72702" },
    { lng: -93.06263, lat: 33.4125, postalCode: "71764" },
    { lng: -90.88366, lat: 34.358049, postalCode: "72389" },
    { lng: -94.03479, lat: 34.299569, postalCode: "71971" },
    { lng: -90.94433, lat: 34.550982, postalCode: "72366" },
    { lng: -91.66201, lat: 34.396301, postalCode: "72073" },
    { lng: -91.25768, lat: 35.892646, postalCode: "72572" },
    { lng: -94.07141, lat: 36.342235, postalCode: "72756" },
    { lng: -93.25662, lat: 34.522254, postalCode: "71968" },
    { lng: -91.47922, lat: 33.525266, postalCode: "71638" },
    { lng: -93.08805, lat: 34.47056, postalCode: "71913" },
    { lng: -92.059863, lat: 34.429059, postalCode: "72182" },
    { lng: -91.732556, lat: 35.277393, postalCode: "72145" },
    { lng: -93.82391, lat: 34.606137, postalCode: "71961" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72115" },
    { lng: -92.09592, lat: 34.261359, postalCode: "71602" },
    { lng: -93.13855, lat: 33.913838, postalCode: "71743" },
    { lng: -93.966645, lat: 36.087551, postalCode: "72735" },
    { lng: -90.39082, lat: 36.179815, postalCode: "72443" },
    { lng: -90.00576, lat: 35.621318, postalCode: "72329" },
    { lng: -94.19716, lat: 34.381056, postalCode: "71972" },
    { lng: -94.4219, lat: 35.852946, postalCode: "72717" },
    { lng: -91.50041, lat: 33.900515, postalCode: "71639" },
    { lng: -91.06249, lat: 36.092999, postalCode: "72457" },
    { lng: -91.77722, lat: 35.144271, postalCode: "72052" },
    { lng: -90.31096, lat: 35.103643, postalCode: "72332" },
    { lng: -94.13334, lat: 36.460576, postalCode: "72751" },
    { lng: -94.409114, lat: 35.38619, postalCode: "72902" },
    { lng: -90.52742, lat: 35.623029, postalCode: "72472" },
    { lng: -89.91767, lat: 35.925991, postalCode: "72315" },
    { lng: -92.01106, lat: 35.230036, postalCode: "72136" },
    { lng: -91.955115, lat: 34.261465, postalCode: "71611" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72221" },
    { lng: -92.9811, lat: 34.657742, postalCode: "71909" },
    { lng: -92.77116, lat: 33.397936, postalCode: "71751" },
    { lng: -93.09345, lat: 36.236984, postalCode: "72601" },
    { lng: -90.0974, lat: 35.673312, postalCode: "72351" },
    { lng: -92.47087, lat: 34.71542, postalCode: "72210" },
    { lng: -93.47283, lat: 33.108043, postalCode: "71861" },
    { lng: -91.02712, lat: 34.935463, postalCode: "72340" },
    { lng: -93.85214, lat: 33.985613, postalCode: "71852" },
    { lng: -92.444426, lat: 35.074617, postalCode: "72034" },
    { lng: -90.89796, lat: 34.719877, postalCode: "72311" },
    { lng: -91.81237, lat: 33.592575, postalCode: "71656" },
    { lng: -94.254573, lat: 36.210013, postalCode: "72728" },
    { lng: -92.29736, lat: 36.484192, postalCode: "72623" },
    { lng: -92.28925, lat: 34.804791, postalCode: "72019" },
    { lng: -90.28187, lat: 36.260606, postalCode: "72461" },
    { lng: -92.16638, lat: 36.132092, postalCode: "72519" },
    { lng: -91.22327, lat: 36.322642, postalCode: "72460" },
    { lng: -93.294126, lat: 33.70082, postalCode: "71844" },
    { lng: -90.31884, lat: 35.102775, postalCode: "72376" },
    { lng: -92.52891, lat: 33.198024, postalCode: "71768" },
    { lng: -91.46138, lat: 34.576099, postalCode: "72170" },
    { lng: -94.0972, lat: 34.537164, postalCode: "71932" },
    { lng: -93.048397, lat: 34.532516, postalCode: "71907" },
    { lng: -91.81901, lat: 35.432905, postalCode: "72121" },
    { lng: -94.22806, lat: 36.267484, postalCode: "72718" },
    { lng: -91.55966, lat: 35.643961, postalCode: "72571" },
    { lng: -92.38169, lat: 34.598551, postalCode: "72103" },
    { lng: -93.19473, lat: 36.427675, postalCode: "72662" },
    { lng: -91.12029, lat: 35.04369, postalCode: "72074" },
    { lng: -92.57586, lat: 35.800025, postalCode: "72645" },
    { lng: -92.8236, lat: 34.581202, postalCode: "72087" },
    { lng: -91.45052, lat: 35.594246, postalCode: "72165" },
    { lng: -91.09866, lat: 34.929287, postalCode: "72392" },
    { lng: -92.62183, lat: 34.993579, postalCode: "72016" },
    { lng: -94.11592, lat: 36.107078, postalCode: "72703" },
    { lng: -92.35557, lat: 34.674192, postalCode: "72209" },
    { lng: -91.53103, lat: 33.133126, postalCode: "71661" },
    { lng: -91.053, lat: 36.464979, postalCode: "72478" },
    { lng: -92.14575, lat: 33.941582, postalCode: "71665" },
    { lng: -91.8275, lat: 35.362742, postalCode: "72085" },
    { lng: -93.01818, lat: 36.000804, postalCode: "72640" },
    { lng: -90.20386, lat: 35.601699, postalCode: "72330" },
    { lng: -90.652887, lat: 35.80881, postalCode: "72402" },
    { lng: -92.65974, lat: 34.635041, postalCode: "72089" },
    { lng: -91.63703, lat: 36.24564, postalCode: "72513" },
    { lng: -91.98417, lat: 34.998573, postalCode: "72007" },
    { lng: -92.21997, lat: 34.855962, postalCode: "72120" },
    { lng: -91.05167, lat: 34.498519, postalCode: "72383" },
    { lng: -91.76948, lat: 35.959567, postalCode: "72561" },
    { lng: -90.65869, lat: 34.546274, postalCode: "72390" },
    { lng: -91.83303, lat: 36.359511, postalCode: "72576" },
    { lng: -92.34019, lat: 34.729159, postalCode: "72204" },
    { lng: -94.20897, lat: 35.48891, postalCode: "72921" },
    { lng: -90.93254, lat: 35.564737, postalCode: "72475" },
    { lng: -92.1649, lat: 35.564065, postalCode: "72067" },
    { lng: -94.13376, lat: 36.040467, postalCode: "72701" },
    { lng: -94.11367, lat: 35.797792, postalCode: "72959" },
    { lng: -92.170174, lat: 34.830158, postalCode: "72217" },
    { lng: -93.807007, lat: 33.793524, postalCode: "71831" },
    { lng: -91.92696, lat: 36.035962, postalCode: "72556" },
    { lng: -92.95971, lat: 34.254277, postalCode: "71941" },
    { lng: -92.04495, lat: 34.189398, postalCode: "71603" },
    { lng: -92.29117, lat: 35.880462, postalCode: "72680" },
    { lng: -93.1238, lat: 33.832537, postalCode: "71772" },
    { lng: -94.13294, lat: 33.822226, postalCode: "71823" },
    { lng: -91.90949, lat: 34.7964, postalCode: "72086" },
    { lng: -92.46821, lat: 34.796399, postalCode: "72223" },
    { lng: -92.545572, lat: 35.57727, postalCode: "72028" },
    { lng: -93.36042, lat: 35.470638, postalCode: "72846" },
    { lng: -91.42605, lat: 35.725631, postalCode: "72562" },
    { lng: -90.74506, lat: 35.781707, postalCode: "72404" },
    { lng: -93.35157, lat: 36.0717, postalCode: "72624" },
    { lng: -90.379616, lat: 36.189695, postalCode: "72448" },
    { lng: -94.33597, lat: 34.038226, postalCode: "71832" },
    { lng: -91.10723, lat: 35.429694, postalCode: "72014" },
    { lng: -91.8863, lat: 36.479925, postalCode: "72578" },
    { lng: -92.53866, lat: 33.326096, postalCode: "71724" },
    { lng: -93.054388, lat: 35.423102, postalCode: "72812" },
    { lng: -91.40408, lat: 34.864607, postalCode: "72017" },
    { lng: -94.30834, lat: 33.927425, postalCode: "71842" },
    { lng: -92.32249, lat: 35.927802, postalCode: "72663" },
    { lng: -92.55715, lat: 35.290158, postalCode: "72157" },
    { lng: -91.23819, lat: 35.256759, postalCode: "72123" },
    { lng: -93.77474, lat: 36.443322, postalCode: "72631" },
    { lng: -92.52891, lat: 33.198024, postalCode: "71731" },
    { lng: -91.26907, lat: 34.438327, postalCode: "72038" },
    { lng: -92.88762, lat: 35.058503, postalCode: "72001" },
    { lng: -94.26995, lat: 35.105477, postalCode: "72940" },
    { lng: -94.34581, lat: 35.456536, postalCode: "72956" },
    { lng: -91.47502, lat: 35.785607, postalCode: "72579" },
    { lng: -92.84044, lat: 36.264469, postalCode: "72672" },
    { lng: -90.63521, lat: 35.05102, postalCode: "72394" },
    { lng: -91.48573, lat: 36.303556, postalCode: "72542" },
    { lng: -94.24376, lat: 35.200061, postalCode: "72936" },
    { lng: -91.9451, lat: 35.615841, postalCode: "72530" },
    { lng: -90.56479, lat: 36.410344, postalCode: "72422" },
    { lng: -91.07664, lat: 35.930405, postalCode: "72410" },
    { lng: -92.2303, lat: 35.965754, postalCode: "72533" },
    { lng: -92.41591, lat: 35.883787, postalCode: "72610" },
    { lng: -91.699077, lat: 33.978738, postalCode: "71678" },
    { lng: -91.87084, lat: 35.505158, postalCode: "72179" },
    { lng: -92.11108, lat: 35.863114, postalCode: "72560" },
    { lng: -92.372635, lat: 36.23638, postalCode: "72654" },
    { lng: -91.24828, lat: 34.061917, postalCode: "72166" },
    { lng: -91.214054, lat: 33.818318, postalCode: "71662" },
    { lng: -92.27765, lat: 34.739224, postalCode: "72202" },
    { lng: -91.606367, lat: 35.203213, postalCode: "72178" },
    { lng: -93.8651, lat: 36.019883, postalCode: "72773" },
    { lng: -92.29743, lat: 34.52109, postalCode: "72065" },
    { lng: -91.76502, lat: 34.516932, postalCode: "72072" },
    { lng: -90.78022, lat: 34.459854, postalCode: "72369" },
    { lng: -90.34153, lat: 35.679593, postalCode: "72377" },
    { lng: -91.53548, lat: 35.956342, postalCode: "72521" },
    { lng: -90.22249, lat: 35.744651, postalCode: "72428" },
    { lng: -91.82508, lat: 35.125583, postalCode: "72102" },
    { lng: -91.46353, lat: 35.461798, postalCode: "72020" },
    { lng: -94.38751, lat: 33.731908, postalCode: "71836" },
    { lng: -92.60271, lat: 36.459703, postalCode: "72661" },
    { lng: -91.573785, lat: 34.785525, postalCode: "72066" },
    { lng: -90.19067, lat: 36.334551, postalCode: "72430" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72222" },
    { lng: -91.33713, lat: 35.961971, postalCode: "72469" },
    { lng: -90.285543, lat: 35.137366, postalCode: "72303" },
    { lng: -91.55777, lat: 36.294732, postalCode: "72529" },
    { lng: -92.235668, lat: 34.817834, postalCode: "72216" },
    { lng: -94.39398, lat: 34.398483, postalCode: "71937" },
    { lng: -92.392487, lat: 34.751918, postalCode: "72190" },
    { lng: -94.44989, lat: 35.594579, postalCode: "72955" },
    { lng: -93.46779, lat: 33.70485, postalCode: "71835" },
    { lng: -94.219977, lat: 35.99403, postalCode: "72741" },
    { lng: -90.896784, lat: 36.417305, postalCode: "72477" },
    { lng: -92.66087, lat: 34.433276, postalCode: "72167" },
    { lng: -93.57136, lat: 33.867903, postalCode: "71825" },
    { lng: -92.7379, lat: 33.350296, postalCode: "71762" },
    { lng: -90.253781, lat: 35.286354, postalCode: "72325" },
    { lng: -91.44076, lat: 35.623263, postalCode: "72564" },
    { lng: -93.53009, lat: 34.929705, postalCode: "72828" },
    { lng: -90.81515, lat: 36.167303, postalCode: "72449" },
    { lng: -90.17636, lat: 35.150009, postalCode: "72301" },
    { lng: -93.72298, lat: 34.480095, postalCode: "71960" },
    { lng: -91.48003, lat: 35.698168, postalCode: "72553" },
    { lng: -92.228248, lat: 34.762141, postalCode: "72214" },
    { lng: -92.543915, lat: 33.535994, postalCode: "71767" },
    { lng: -94.36684, lat: 35.026991, postalCode: "72938" },
    { lng: -91.00403, lat: 34.297754, postalCode: "72353" },
    { lng: -90.54588, lat: 34.764633, postalCode: "72320" },
    { lng: -90.33123, lat: 35.232817, postalCode: "72327" },
    { lng: -90.895421, lat: 35.964317, postalCode: "72465" },
    { lng: -90.5102, lat: 36.065711, postalCode: "72450" },
    { lng: -94.37313, lat: 35.604409, postalCode: "72932" },
    { lng: -92.13033, lat: 35.227869, postalCode: "72111" },
    { lng: -93.57336, lat: 35.306084, postalCode: "72865" },
    { lng: -93.956801, lat: 36.299507, postalCode: "72716" },
    { lng: -90.34016, lat: 35.910391, postalCode: "72447" },
    { lng: -91.73776, lat: 35.239022, postalCode: "72143" },
    { lng: -93.099403, lat: 34.581374, postalCode: "71910" },
    { lng: -93.04185, lat: 35.794196, postalCode: "72856" },
    { lng: -92.189397, lat: 34.80189, postalCode: "72231" },
    { lng: -92.66095, lat: 33.323051, postalCode: "71759" },
    { lng: -90.38816, lat: 36.437727, postalCode: "72441" },
    { lng: -93.99236, lat: 33.428618, postalCode: "71854" },
    { lng: -91.82045, lat: 36.226478, postalCode: "72587" },
    { lng: -90.767879, lat: 34.546696, postalCode: "72312" },
    { lng: -91.53854, lat: 34.479852, postalCode: "72160" },
    { lng: -85.2206, lat: 30.804698, postalCode: "32446" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32576" },
    { lng: -87.277307, lat: 30.356287, postalCode: "32559" },
    { lng: -86.594479, lat: 30.661155, postalCode: "32540" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32516" },
    { lng: -87.21485, lat: 30.527195, postalCode: "32514" },
    { lng: -86.14665, lat: 30.747244, postalCode: "32433" },
    { lng: -85.67495, lat: 30.838337, postalCode: "32425" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32589" },
    { lng: -85.11142, lat: 30.860528, postalCode: "32443" },
    { lng: -85.25712, lat: 30.759353, postalCode: "32447" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32574" },
    { lng: -87.04972, lat: 30.659159, postalCode: "32572" },
    { lng: -85.23283, lat: 30.675635, postalCode: "32448" },
    { lng: -87.259592, lat: 30.557064, postalCode: "32513" },
    { lng: -85.38288, lat: 30.955359, postalCode: "32426" },
    { lng: -85.65413, lat: 30.330553, postalCode: "32409" },
    { lng: -85.16919, lat: 30.961401, postalCode: "32445" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32593" },
    { lng: -87.031496, lat: 30.598564, postalCode: "32530" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32594" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32521" },
    { lng: -86.58533, lat: 30.760184, postalCode: "32536" },
    { lng: -86.03889, lat: 30.716494, postalCode: "32422" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32596" },
    { lng: -87.47861, lat: 30.878449, postalCode: "32568" },
    { lng: -87.20727, lat: 30.447997, postalCode: "32503" },
    { lng: -85.5775, lat: 30.636228, postalCode: "32428" },
    { lng: -87.09013, lat: 30.375469, postalCode: "32561" },
    { lng: -85.58981, lat: 30.631345, postalCode: "32463" },
    { lng: -86.77075, lat: 30.717437, postalCode: "32564" },
    { lng: -87.31979, lat: 30.600993, postalCode: "32533" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32581" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32524" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32595" },
    { lng: -85.64577, lat: 30.239849, postalCode: "32444" },
    { lng: -87.33451, lat: 30.360887, postalCode: "32507" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32590" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32575" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32598" },
    { lng: -87.34872, lat: 30.743945, postalCode: "32577" },
    { lng: -87.18937, lat: 30.48787, postalCode: "32504" },
    { lng: -86.594479, lat: 30.661155, postalCode: "32549" },
    { lng: -86.51088, lat: 30.757479, postalCode: "32539" },
    { lng: -85.96035, lat: 30.657214, postalCode: "32455" },
    { lng: -85.90413, lat: 30.252616, postalCode: "32413" },
    { lng: -85.81279, lat: 30.196771, postalCode: "32407" },
    { lng: -85.65297, lat: 30.161901, postalCode: "32401" },
    { lng: -87.04972, lat: 30.659159, postalCode: "32562" },
    { lng: -86.311093, lat: 30.970853, postalCode: "32538" },
    { lng: -86.1675, lat: 30.482838, postalCode: "32439" },
    { lng: -87.275693, lat: 30.529739, postalCode: "32597" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32522" },
    { lng: -86.4338, lat: 30.391795, postalCode: "32541" },
    { lng: -87.225691, lat: 30.474288, postalCode: "32502" },
    { lng: -85.806, lat: 30.821603, postalCode: "32427" },
    { lng: -87.340262, lat: 30.464324, postalCode: "32509" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32511" },
    { lng: -85.88079, lat: 30.443152, postalCode: "32437" },
    { lng: -85.53832, lat: 30.778182, postalCode: "32478" },
    { lng: -85.242169, lat: 29.902484, postalCode: "32457" },
    { lng: -85.39371, lat: 30.779196, postalCode: "32431" },
    { lng: -86.18759, lat: 30.361248, postalCode: "32459" },
    { lng: -86.49702, lat: 30.507047, postalCode: "32580" },
    { lng: -86.594479, lat: 30.661155, postalCode: "32588" },
    { lng: -85.41673, lat: 30.488955, postalCode: "32438" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32573" },
    { lng: -86.12111, lat: 30.603777, postalCode: "32435" },
    { lng: -87.299129, lat: 30.394327, postalCode: "32512" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32582" },
    { lng: -85.0779, lat: 30.713868, postalCode: "32432" },
    { lng: -85.01619, lat: 30.698517, postalCode: "32442" },
    { lng: -87.30604, lat: 30.969066, postalCode: "32535" },
    { lng: -87.292755, lat: 30.409264, postalCode: "32546" },
    { lng: -86.89025, lat: 30.435069, postalCode: "32566" },
    { lng: -85.52171, lat: 30.935306, postalCode: "32440" },
    { lng: -87.22305, lat: 30.420997, postalCode: "32501" },
    { lng: -85.76367, lat: 30.154621, postalCode: "32408" },
    { lng: -87.25472, lat: 30.441994, postalCode: "32505" },
    { lng: -87.31996, lat: 30.479002, postalCode: "32526" },
    { lng: -85.22822, lat: 30.045471, postalCode: "32465" },
    { lng: -85.56392, lat: 30.188585, postalCode: "32404" },
    { lng: -87.203543, lat: 30.412377, postalCode: "32520" },
    { lng: -86.57331, lat: 30.444975, postalCode: "32579" },
    { lng: -85.93988, lat: 30.892936, postalCode: "32464" },
    { lng: -87.27734, lat: 30.526345, postalCode: "32534" },
    { lng: -86.61992, lat: 30.414932, postalCode: "32548" },
    { lng: -85.60975, lat: 30.068188, postalCode: "32403" },
    { lng: -85.21892, lat: 30.283088, postalCode: "32449" },
    { lng: -85.82357, lat: 30.563809, postalCode: "32462" },
    { lng: -85.05644, lat: 30.959504, postalCode: "32423" },
    { lng: -85.916988, lat: 30.243832, postalCode: "32417" },
    { lng: -87.13267, lat: 30.902015, postalCode: "32565" },
    { lng: -86.359508, lat: 30.381934, postalCode: "32550" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32591" },
    { lng: -85.198431, lat: 30.110171, postalCode: "32436" },
    { lng: -85.24381, lat: 30.438819, postalCode: "32430" },
    { lng: -85.942389, lat: 30.252531, postalCode: "32461" },
    { lng: -84.94563, lat: 30.735645, postalCode: "32460" },
    { lng: -85.52891, lat: 30.373845, postalCode: "32466" },
    { lng: -87.292918, lat: 30.581825, postalCode: "32560" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32523" },
    { lng: -87.01098, lat: 30.70969, postalCode: "32570" },
    { lng: -86.342683, lat: 30.762486, postalCode: "32434" },
    { lng: -85.768022, lat: 30.849972, postalCode: "32452" },
    { lng: -85.41461, lat: 29.945008, postalCode: "32410" },
    { lng: -86.64643, lat: 30.464755, postalCode: "32544" },
    { lng: -87.29035, lat: 30.355477, postalCode: "32508" },
    { lng: -86.12111, lat: 30.603777, postalCode: "32454" },
    { lng: -87.31919, lat: 30.408807, postalCode: "32506" },
    { lng: -86.655208, lat: 30.741965, postalCode: "32537" },
    { lng: -86.68607, lat: 30.8752, postalCode: "32531" },
    { lng: -86.43749, lat: 30.945123, postalCode: "32567" },
    { lng: -87.04972, lat: 30.659159, postalCode: "32563" },
    { lng: -86.62113, lat: 30.447248, postalCode: "32547" },
    { lng: -85.66458, lat: 30.195424, postalCode: "32405" },
    { lng: -86.55081, lat: 30.457156, postalCode: "32542" },
    { lng: -87.275772, lat: 30.61428, postalCode: "32592" },
    { lng: -87.16724, lat: 30.627637, postalCode: "32571" },
    { lng: -87.00178, lat: 30.58149, postalCode: "32583" },
    { lng: -85.07299, lat: 30.42007, postalCode: "32424" },
    { lng: -85.18229, lat: 30.5403, postalCode: "32421" },
    { lng: -85.37542, lat: 30.641154, postalCode: "32420" },
    { lng: -91.60941, lat: 42.440131, postalCode: "50654" },
    { lng: -93.6648, lat: 41.949947, postalCode: "50134" },
    { lng: -90.77717, lat: 41.73542, postalCode: "52745" },
    { lng: -91.43307, lat: 41.476534, postalCode: "52755" },
    { lng: -93.52078, lat: 43.418877, postalCode: "50450" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50304" },
    { lng: -91.58262, lat: 42.042648, postalCode: "52302" },
    { lng: -92.408679, lat: 40.745286, postalCode: "52538" },
    { lng: -91.5075, lat: 42.198823, postalCode: "52214" },
    { lng: -96.53712, lat: 42.725792, postalCode: "51062" },
    { lng: -91.31035, lat: 41.473955, postalCode: "52766" },
    { lng: -96.24606, lat: 42.221763, postalCode: "51055" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52409" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52499" },
    { lng: -93.79945, lat: 40.857503, postalCode: "50262" },
    { lng: -94.29539, lat: 42.269537, postalCode: "50543" },
    { lng: -96.35291, lat: 42.466292, postalCode: "51106" },
    { lng: -95.69205, lat: 41.142148, postalCode: "51554" },
    { lng: -95.62735, lat: 42.821249, postalCode: "51037" },
    { lng: -95.73092, lat: 41.039679, postalCode: "51534" },
    { lng: -93.98159, lat: 42.846831, postalCode: "50577" },
    { lng: -92.40092, lat: 43.468995, postalCode: "52134" },
    { lng: -91.33866, lat: 40.637694, postalCode: "52627" },
    { lng: -91.88645, lat: 42.774435, postalCode: "50655" },
    { lng: -93.250567, lat: 42.383133, postalCode: "50259" },
    { lng: -93.67356, lat: 41.587039, postalCode: "50312" },
    { lng: -93.1683, lat: 41.185045, postalCode: "50057" },
    { lng: -93.80387, lat: 42.579038, postalCode: "50599" },
    { lng: -92.40188, lat: 42.080758, postalCode: "52217" },
    { lng: -93.94309, lat: 41.223389, postalCode: "50222" },
    { lng: -94.54041, lat: 42.27586, postalCode: "51453" },
    { lng: -95.574593, lat: 42.130428, postalCode: "51460" },
    { lng: -91.81242, lat: 40.87657, postalCode: "52651" },
    { lng: -92.46513, lat: 42.377311, postalCode: "50643" },
    { lng: -92.93572, lat: 41.120845, postalCode: "50150" },
    { lng: -91.3677, lat: 41.265956, postalCode: "52738" },
    { lng: -90.96492, lat: 42.631604, postalCode: "52053" },
    { lng: -91.10572, lat: 42.559621, postalCode: "52065" },
    { lng: -94.42583, lat: 43.225165, postalCode: "50539" },
    { lng: -91.89268, lat: 42.470191, postalCode: "50644" },
    { lng: -96.08298, lat: 42.250507, postalCode: "51026" },
    { lng: -92.26841, lat: 41.463624, postalCode: "50136" },
    { lng: -92.414422, lat: 43.117425, postalCode: "50661" },
    { lng: -91.88379, lat: 42.093056, postalCode: "52332" },
    { lng: -94.92781, lat: 41.741295, postalCode: "50025" },
    { lng: -92.06066, lat: 41.358606, postalCode: "52231" },
    { lng: -96.30802, lat: 43.352016, postalCode: "51230" },
    { lng: -91.97807, lat: 43.308213, postalCode: "52165" },
    { lng: -95.643435, lat: 43.415305, postalCode: "51330" },
    { lng: -91.44152, lat: 41.126832, postalCode: "52659" },
    { lng: -92.22521, lat: 40.919465, postalCode: "52554" },
    { lng: -93.95498, lat: 41.039527, postalCode: "50174" },
    { lng: -92.88759, lat: 43.148134, postalCode: "50471" },
    { lng: -92.30407, lat: 40.995466, postalCode: "52530" },
    { lng: -93.2024, lat: 42.135419, postalCode: "50239" },
    { lng: -95.25334, lat: 42.907563, postalCode: "51033" },
    { lng: -92.80277, lat: 42.74806, postalCode: "50602" },
    { lng: -94.43912, lat: 41.853498, postalCode: "50026" },
    { lng: -93.79728, lat: 41.304096, postalCode: "50240" },
    { lng: -94.08143, lat: 40.70332, postalCode: "50133" },
    { lng: -90.95702, lat: 41.983923, postalCode: "52323" },
    { lng: -95.58941, lat: 40.998667, postalCode: "51551" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50306" },
    { lng: -91.57544, lat: 40.542869, postalCode: "52619" },
    { lng: -91.65943, lat: 42.845037, postalCode: "52169" },
    { lng: -91.58676, lat: 41.688215, postalCode: "52241" },
    { lng: -93.66189, lat: 41.542789, postalCode: "50321" },
    { lng: -92.90573, lat: 41.607887, postalCode: "50137" },
    { lng: -92.09326, lat: 41.527681, postalCode: "52316" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50347" },
    { lng: -94.10961, lat: 41.846679, postalCode: "50220" },
    { lng: -92.92738, lat: 40.616347, postalCode: "52549" },
    { lng: -95.35379, lat: 42.019336, postalCode: "51442" },
    { lng: -94.04333, lat: 43.242739, postalCode: "50480" },
    { lng: -91.12842, lat: 42.490907, postalCode: "52040" },
    { lng: -95.22603, lat: 41.006437, postalCode: "51566" },
    { lng: -92.19383, lat: 42.325098, postalCode: "50651" },
    { lng: -90.81319, lat: 42.273028, postalCode: "52032" },
    { lng: -93.3836, lat: 43.287532, postalCode: "50444" },
    { lng: -90.61672, lat: 41.513182, postalCode: "52802" },
    { lng: -91.87587, lat: 41.732868, postalCode: "52236" },
    { lng: -90.53879, lat: 42.36059, postalCode: "52071" },
    { lng: -93.04467, lat: 41.702148, postalCode: "50208" },
    { lng: -93.82056, lat: 42.464579, postalCode: "50595" },
    { lng: -96.40292, lat: 42.524145, postalCode: "51104" },
    { lng: -90.47705, lat: 41.963196, postalCode: "52731" },
    { lng: -90.60303, lat: 41.574581, postalCode: "52806" },
    { lng: -93.0135, lat: 42.834044, postalCode: "50605" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50360" },
    { lng: -94.99323, lat: 42.43142, postalCode: "50583" },
    { lng: -91.5526, lat: 43.104035, postalCode: "52162" },
    { lng: -92.8988, lat: 42.606337, postalCode: "50604" },
    { lng: -96.36139, lat: 42.554944, postalCode: "51108" },
    { lng: -95.54919, lat: 42.754004, postalCode: "51012" },
    { lng: -96.11875, lat: 42.027176, postalCode: "51040" },
    { lng: -90.32975, lat: 41.96264, postalCode: "52727" },
    { lng: -93.07297, lat: 43.329416, postalCode: "50440" },
    { lng: -92.56458, lat: 40.837112, postalCode: "52552" },
    { lng: -93.1813, lat: 41.89347, postalCode: "50234" },
    { lng: -94.88642, lat: 42.279474, postalCode: "51433" },
    { lng: -91.7792, lat: 42.193727, postalCode: "52213" },
    { lng: -95.61476, lat: 43.086954, postalCode: "51245" },
    { lng: -95.86092, lat: 41.911376, postalCode: "51558" },
    { lng: -95.587197, lat: 41.332943, postalCode: "51593" },
    { lng: -91.00372, lat: 42.134893, postalCode: "52321" },
    { lng: -93.85169, lat: 40.734072, postalCode: "50067" },
    { lng: -95.60679, lat: 40.76261, postalCode: "51652" },
    { lng: -96.18713, lat: 42.969595, postalCode: "51036" },
    { lng: -91.43864, lat: 40.547399, postalCode: "52639" },
    { lng: -93.77547, lat: 41.381146, postalCode: "50229" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50331" },
    { lng: -95.91398, lat: 41.292647, postalCode: "51510" },
    { lng: -95.47513, lat: 43.320149, postalCode: "51349" },
    { lng: -91.09397, lat: 42.115943, postalCode: "52212" },
    { lng: -90.86944, lat: 41.720483, postalCode: "52765" },
    { lng: -92.90646, lat: 42.042506, postalCode: "50158" },
    { lng: -91.35217, lat: 42.018441, postalCode: "52305" },
    { lng: -91.94243, lat: 41.736497, postalCode: "52334" },
    { lng: -95.23551, lat: 42.315531, postalCode: "51458" },
    { lng: -92.56155, lat: 41.7388, postalCode: "50157" },
    { lng: -93.62645, lat: 42.930305, postalCode: "50439" },
    { lng: -95.7903, lat: 42.793354, postalCode: "51035" },
    { lng: -95.46787, lat: 43.183038, postalCode: "51346" },
    { lng: -91.90704, lat: 41.900443, postalCode: "52318" },
    { lng: -94.10732, lat: 42.346859, postalCode: "50521" },
    { lng: -94.00842, lat: 41.53106, postalCode: "50069" },
    { lng: -94.52139, lat: 41.514342, postalCode: "50048" },
    { lng: -95.93448, lat: 43.242958, postalCode: "51244" },
    { lng: -92.64331, lat: 43.450496, postalCode: "50455" },
    { lng: -91.599974, lat: 41.642657, postalCode: "52243" },
    { lng: -90.67008, lat: 41.836317, postalCode: "52751" },
    { lng: -92.77702, lat: 43.441028, postalCode: "50476" },
    { lng: -93.02537, lat: 40.948022, postalCode: "52569" },
    { lng: -93.46641, lat: 41.644716, postalCode: "50009" },
    { lng: -91.68819, lat: 41.947335, postalCode: "52404" },
    { lng: -94.18994, lat: 41.210955, postalCode: "50155" },
    { lng: -94.33102, lat: 40.618619, postalCode: "50860" },
    { lng: -93.31527, lat: 40.756632, postalCode: "50060" },
    { lng: -91.14736, lat: 42.003337, postalCode: "52320" },
    { lng: -91.27531, lat: 42.106972, postalCode: "52205" },
    { lng: -91.18541, lat: 42.662381, postalCode: "52035" },
    { lng: -95.20256, lat: 41.470851, postalCode: "51577" },
    { lng: -92.15955, lat: 41.0255, postalCode: "52533" },
    { lng: -93.68225, lat: 41.470726, postalCode: "50211" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50393" },
    { lng: -93.23591, lat: 41.682808, postalCode: "50054" },
    { lng: -90.754327, lat: 41.509085, postalCode: "52706" },
    { lng: -94.21237, lat: 41.827287, postalCode: "50066" },
    { lng: -92.64823, lat: 41.445018, postalCode: "50207" },
    { lng: -95.87353, lat: 41.695375, postalCode: "51550" },
    { lng: -91.93352, lat: 40.620468, postalCode: "52573" },
    { lng: -92.34104, lat: 41.575609, postalCode: "52222" },
    { lng: -95.73876, lat: 43.406831, postalCode: "51249" },
    { lng: -96.32412, lat: 43.192619, postalCode: "51247" },
    { lng: -90.8469, lat: 41.594283, postalCode: "52769" },
    { lng: -91.33459, lat: 40.865859, postalCode: "52623" },
    { lng: -92.49948, lat: 41.14579, postalCode: "52566" },
    { lng: -94.68307, lat: 42.55602, postalCode: "50575" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50367" },
    { lng: -94.07635, lat: 40.99355, postalCode: "50254" },
    { lng: -93.48318, lat: 42.916174, postalCode: "50457" },
    { lng: -93.90483, lat: 41.519724, postalCode: "50038" },
    { lng: -93.94509, lat: 41.490848, postalCode: "50261" },
    { lng: -92.96436, lat: 40.797559, postalCode: "52574" },
    { lng: -93.25821, lat: 41.08869, postalCode: "50272" },
    { lng: -93.20037, lat: 43.153969, postalCode: "50401" },
    { lng: -91.77187, lat: 43.345879, postalCode: "52101" },
    { lng: -94.17666, lat: 42.259002, postalCode: "50544" },
    { lng: -92.81015, lat: 42.226183, postalCode: "50609" },
    { lng: -92.13113, lat: 40.873769, postalCode: "52588" },
    { lng: -95.43137, lat: 42.710831, postalCode: "51005" },
    { lng: -91.54755, lat: 41.320371, postalCode: "52201" },
    { lng: -93.88068, lat: 41.348815, postalCode: "50218" },
    { lng: -93.46171, lat: 41.704455, postalCode: "50035" },
    { lng: -92.79458, lat: 41.573516, postalCode: "50153" },
    { lng: -93.73157, lat: 41.308451, postalCode: "50241" },
    { lng: -90.218704, lat: 41.999113, postalCode: "52771" },
    { lng: -94.22602, lat: 43.0739, postalCode: "50511" },
    { lng: -91.40001, lat: 40.409641, postalCode: "52632" },
    { lng: -95.18071, lat: 42.814277, postalCode: "50576" },
    { lng: -94.94569, lat: 43.432156, postalCode: "51363" },
    { lng: -93.6468, lat: 43.363881, postalCode: "50453" },
    { lng: -94.12488, lat: 41.468077, postalCode: "50072" },
    { lng: -92.26657, lat: 42.407588, postalCode: "50706" },
    { lng: -95.38295, lat: 41.029684, postalCode: "51533" },
    { lng: -93.52595, lat: 41.792118, postalCode: "50073" },
    { lng: -92.80848, lat: 42.891377, postalCode: "50636" },
    { lng: -92.39722, lat: 42.275046, postalCode: "50612" },
    { lng: -95.644264, lat: 41.77, postalCode: "51547" },
    { lng: -93.14743, lat: 41.820644, postalCode: "50028" },
    { lng: -93.90657, lat: 42.280223, postalCode: "50249" },
    { lng: -93.20838, lat: 43.289041, postalCode: "50456" },
    { lng: -94.90006, lat: 42.658595, postalCode: "50593" },
    { lng: -90.92851, lat: 42.451496, postalCode: "52045" },
    { lng: -91.33664, lat: 40.741062, postalCode: "52624" },
    { lng: -95.70185, lat: 42.0477, postalCode: "51060" },
    { lng: -93.73523, lat: 42.733001, postalCode: "50526" },
    { lng: -96.48967, lat: 42.819093, postalCode: "51001" },
    { lng: -91.72385, lat: 40.655336, postalCode: "52626" },
    { lng: -92.45984, lat: 42.741016, postalCode: "50677" },
    { lng: -91.87512, lat: 41.808301, postalCode: "52203" },
    { lng: -96.05563, lat: 42.474905, postalCode: "51039" },
    { lng: -92.1509, lat: 42.145175, postalCode: "52229" },
    { lng: -95.31637, lat: 43.437775, postalCode: "51347" },
    { lng: -91.94764, lat: 43.141414, postalCode: "52144" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50380" },
    { lng: -95.66736, lat: 40.622159, postalCode: "51640" },
    { lng: -93.04963, lat: 40.792046, postalCode: "52581" },
    { lng: -93.38182, lat: 41.536205, postalCode: "50237" },
    { lng: -94.97547, lat: 42.005206, postalCode: "51444" },
    { lng: -95.45818, lat: 41.952624, postalCode: "51520" },
    { lng: -92.3541, lat: 41.403794, postalCode: "50268" },
    { lng: -92.178366, lat: 41.336267, postalCode: "50255" },
    { lng: -94.58979, lat: 42.638478, postalCode: "50571" },
    { lng: -92.46546, lat: 43.191256, postalCode: "50603" },
    { lng: -90.48975, lat: 41.551883, postalCode: "52722" },
    { lng: -91.91522, lat: 41.790988, postalCode: "52307" },
    { lng: -91.8051, lat: 40.697875, postalCode: "52620" },
    { lng: -91.29172, lat: 40.980185, postalCode: "52660" },
    { lng: -94.41955, lat: 42.802568, postalCode: "50520" },
    { lng: -91.27859, lat: 41.09739, postalCode: "52640" },
    { lng: -93.38463, lat: 43.1375, postalCode: "50428" },
    { lng: -94.53628, lat: 42.52503, postalCode: "50563" },
    { lng: -93.49076, lat: 41.06058, postalCode: "50151" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50364" },
    { lng: -94.0443, lat: 42.045766, postalCode: "50212" },
    { lng: -91.84325, lat: 42.349458, postalCode: "52329" },
    { lng: -91.6572, lat: 42.612267, postalCode: "50650" },
    { lng: -95.09307, lat: 42.256867, postalCode: "51466" },
    { lng: -95.11568, lat: 40.616696, postalCode: "51637" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50947" },
    { lng: -90.804116, lat: 42.484861, postalCode: "52004" },
    { lng: -93.35327, lat: 41.661112, postalCode: "50169" },
    { lng: -91.66882, lat: 41.141336, postalCode: "52654" },
    { lng: -93.99814, lat: 42.456733, postalCode: "50532" },
    { lng: -94.7643, lat: 40.780854, postalCode: "50848" },
    { lng: -95.12861, lat: 43.364496, postalCode: "51331" },
    { lng: -90.6341, lat: 42.28911, postalCode: "52054" },
    { lng: -91.36209, lat: 41.909156, postalCode: "52253" },
    { lng: -96.4293, lat: 42.505387, postalCode: "51103" },
    { lng: -91.43684, lat: 41.929178, postalCode: "52314" },
    { lng: -93.927086, lat: 42.266846, postalCode: "50294" },
    { lng: -95.16337, lat: 43.33158, postalCode: "51351" },
    { lng: -93.260879, lat: 43.081604, postalCode: "50402" },
    { lng: -95.71117, lat: 41.743335, postalCode: "51579" },
    { lng: -95.13004, lat: 41.140956, postalCode: "51532" },
    { lng: -92.309475, lat: 42.469768, postalCode: "50614" },
    { lng: -91.225836, lat: 42.191979, postalCode: "52252" },
    { lng: -92.05255, lat: 43.068101, postalCode: "52171" },
    { lng: -94.84192, lat: 42.58816, postalCode: "50540" },
    { lng: -91.61238, lat: 41.755771, postalCode: "52317" },
    { lng: -96.51603, lat: 42.916772, postalCode: "51011" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50381" },
    { lng: -94.03802, lat: 41.332618, postalCode: "50273" },
    { lng: -91.54664, lat: 41.103879, postalCode: "52652" },
    { lng: -94.90323, lat: 41.534416, postalCode: "50042" },
    { lng: -90.35986, lat: 41.609382, postalCode: "52753" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50391" },
    { lng: -95.00487, lat: 42.186982, postalCode: "51436" },
    { lng: -92.33182, lat: 41.640716, postalCode: "52221" },
    { lng: -93.61636, lat: 41.637638, postalCode: "50313" },
    { lng: -95.0716, lat: 41.596716, postalCode: "51531" },
    { lng: -93.61904, lat: 41.545089, postalCode: "50315" },
    { lng: -93.26916, lat: 41.780089, postalCode: "50168" },
    { lng: -95.20173, lat: 41.785808, postalCode: "51446" },
    { lng: -94.09044, lat: 43.379814, postalCode: "50451" },
    { lng: -90.519519, lat: 41.880619, postalCode: "52733" },
    { lng: -90.99759, lat: 42.29025, postalCode: "52033" },
    { lng: -93.70988, lat: 40.89222, postalCode: "50264" },
    { lng: -92.0662, lat: 40.649744, postalCode: "52542" },
    { lng: -96.00036, lat: 43.332554, postalCode: "51237" },
    { lng: -91.14794, lat: 41.888096, postalCode: "52337" },
    { lng: -91.689862, lat: 43.463765, postalCode: "52149" },
    { lng: -94.07546, lat: 40.852515, postalCode: "50074" },
    { lng: -94.1494, lat: 42.417893, postalCode: "50569" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50318" },
    { lng: -93.71865, lat: 41.674757, postalCode: "50131" },
    { lng: -93.45343, lat: 42.303222, postalCode: "50230" },
    { lng: -90.44392, lat: 41.744689, postalCode: "52758" },
    { lng: -91.74754, lat: 40.823716, postalCode: "52630" },
    { lng: -95.94724, lat: 42.23092, postalCode: "51056" },
    { lng: -93.80106, lat: 40.623677, postalCode: "50065" },
    { lng: -92.10263, lat: 43.382021, postalCode: "52136" },
    { lng: -93.41399, lat: 41.283703, postalCode: "50166" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50305" },
    { lng: -95.39897, lat: 41.137694, postalCode: "51541" },
    { lng: -91.54143, lat: 41.662116, postalCode: "52242" },
    { lng: -93.77734, lat: 43.385425, postalCode: "50478" },
    { lng: -94.488618, lat: 42.339118, postalCode: "50587" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50302" },
    { lng: -90.71627, lat: 42.286954, postalCode: "52079" },
    { lng: -94.86786, lat: 42.066399, postalCode: "51401" },
    { lng: -92.51977, lat: 41.218744, postalCode: "52543" },
    { lng: -96.087523, lat: 42.345024, postalCode: "51015" },
    { lng: -92.3841, lat: 41.472819, postalCode: "50104" },
    { lng: -91.11911, lat: 40.814207, postalCode: "52601" },
    { lng: -94.6931, lat: 43.115156, postalCode: "50536" },
    { lng: -95.156205, lat: 41.030256, postalCode: "51591" },
    { lng: -95.7976, lat: 40.741715, postalCode: "51648" },
    { lng: -94.08042, lat: 41.137674, postalCode: "50149" },
    { lng: -91.96896, lat: 41.995899, postalCode: "52315" },
    { lng: -93.29673, lat: 42.158866, postalCode: "50278" },
    { lng: -92.82115, lat: 43.290536, postalCode: "50461" },
    { lng: -95.91104, lat: 42.29688, postalCode: "51044" },
    { lng: -92.93875, lat: 41.313748, postalCode: "50119" },
    { lng: -93.16827, lat: 42.010415, postalCode: "50247" },
    { lng: -92.54755, lat: 43.376582, postalCode: "50466" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50335" },
    { lng: -93.785502, lat: 41.594543, postalCode: "50398" },
    { lng: -94.27107, lat: 40.905544, postalCode: "50861" },
    { lng: -93.525253, lat: 43.418395, postalCode: "50405" },
    { lng: -93.71405, lat: 41.782755, postalCode: "50226" },
    { lng: -91.00644, lat: 41.604559, postalCode: "52778" },
    { lng: -92.26368, lat: 42.807994, postalCode: "50676" },
    { lng: -92.97056, lat: 41.557079, postalCode: "50232" },
    { lng: -96.01511, lat: 43.19528, postalCode: "51234" },
    { lng: -91.30746, lat: 43.48058, postalCode: "52160" },
    { lng: -93.06747, lat: 42.410168, postalCode: "50672" },
    { lng: -94.61632, lat: 43.465883, postalCode: "50531" },
    { lng: -91.55738, lat: 42.815385, postalCode: "52077" },
    { lng: -92.473, lat: 42.646089, postalCode: "50647" },
    { lng: -92.21396, lat: 42.417252, postalCode: "50634" },
    { lng: -94.41373, lat: 42.279123, postalCode: "50538" },
    { lng: -95.87447, lat: 41.251631, postalCode: "51501" },
    { lng: -94.38448, lat: 41.070905, postalCode: "50801" },
    { lng: -93.32768, lat: 42.97779, postalCode: "50477" },
    { lng: -92.79802, lat: 42.43442, postalCode: "50642" },
    { lng: -96.32512, lat: 42.971195, postalCode: "51027" },
    { lng: -93.37056, lat: 42.735305, postalCode: "50431" },
    { lng: -92.77525, lat: 42.00533, postalCode: "50142" },
    { lng: -94.22517, lat: 42.720313, postalCode: "50548" },
    { lng: -92.68082, lat: 41.273646, postalCode: "52534" },
    { lng: -92.09695, lat: 41.130669, postalCode: "52580" },
    { lng: -91.777472, lat: 42.287191, postalCode: "52406" },
    { lng: -92.6867, lat: 40.833045, postalCode: "52594" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50981" },
    { lng: -95.61045, lat: 42.316288, postalCode: "51006" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50394" },
    { lng: -94.10845, lat: 42.947984, postalCode: "50560" },
    { lng: -91.89429, lat: 43.498082, postalCode: "55954" },
    { lng: -91.12294, lat: 42.398112, postalCode: "52078" },
    { lng: -90.75329, lat: 42.50963, postalCode: "52002" },
    { lng: -93.80235, lat: 41.625788, postalCode: "50323" },
    { lng: -92.33578, lat: 42.475886, postalCode: "50702" },
    { lng: -95.62675, lat: 41.307465, postalCode: "51548" },
    { lng: -94.36129, lat: 42.603391, postalCode: "50524" },
    { lng: -94.52311, lat: 40.909175, postalCode: "50851" },
    { lng: -91.11376, lat: 42.765031, postalCode: "52052" },
    { lng: -95.02441, lat: 42.09809, postalCode: "51430" },
    { lng: -94.41573, lat: 41.20657, postalCode: "50858" },
    { lng: -94.21948, lat: 42.036623, postalCode: "50107" },
    { lng: -95.13802, lat: 43.38762, postalCode: "51355" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50340" },
    { lng: -91.96126, lat: 40.747997, postalCode: "52565" },
    { lng: -94.87537, lat: 40.944133, postalCode: "50857" },
    { lng: -95.28976, lat: 42.498879, postalCode: "51053" },
    { lng: -91.39965, lat: 40.920995, postalCode: "52645" },
    { lng: -92.20768, lat: 42.963377, postalCode: "50630" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50936" },
    { lng: -95.79062, lat: 41.2544, postalCode: "51503" },
    { lng: -95.00245, lat: 42.855822, postalCode: "50565" },
    { lng: -94.31526, lat: 43.204803, postalCode: "50559" },
    { lng: -92.19669, lat: 42.005883, postalCode: "52249" },
    { lng: -91.53338, lat: 43.442183, postalCode: "52140" },
    { lng: -92.76056, lat: 43.157144, postalCode: "50435" },
    { lng: -96.317951, lat: 42.368406, postalCode: "51102" },
    { lng: -92.28491, lat: 41.17196, postalCode: "52563" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52408" },
    { lng: -92.162, lat: 40.679958, postalCode: "52570" },
    { lng: -94.78449, lat: 42.126994, postalCode: "51452" },
    { lng: -91.75175, lat: 40.991682, postalCode: "52635" },
    { lng: -93.63237, lat: 41.602989, postalCode: "50314" },
    { lng: -91.692312, lat: 40.977395, postalCode: "52642" },
    { lng: -92.08549, lat: 41.911228, postalCode: "52209" },
    { lng: -90.56223, lat: 41.538582, postalCode: "52803" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50301" },
    { lng: -93.00738, lat: 42.261478, postalCode: "50269" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50940" },
    { lng: -92.72123, lat: 41.736434, postalCode: "50112" },
    { lng: -93.84553, lat: 41.204733, postalCode: "50257" },
    { lng: -91.25078, lat: 42.738079, postalCode: "52048" },
    { lng: -93.08574, lat: 43.206593, postalCode: "50467" },
    { lng: -95.36419, lat: 40.599361, postalCode: "51647" },
    { lng: -96.178616, lat: 42.837641, postalCode: "51057" },
    { lng: -94.54121, lat: 43.291538, postalCode: "50578" },
    { lng: -95.85556, lat: 42.571699, postalCode: "51048" },
    { lng: -91.66287, lat: 42.073765, postalCode: "52328" },
    { lng: -93.56507, lat: 42.325266, postalCode: "50075" },
    { lng: -91.92847, lat: 43.069086, postalCode: "52166" },
    { lng: -93.45694, lat: 43.343138, postalCode: "50446" },
    { lng: -92.09219, lat: 43.21661, postalCode: "52163" },
    { lng: -93.70867, lat: 42.393155, postalCode: "50132" },
    { lng: -95.09722, lat: 40.979256, postalCode: "51573" },
    { lng: -91.25428, lat: 41.900204, postalCode: "52306" },
    { lng: -92.95464, lat: 41.88053, postalCode: "50141" },
    { lng: -92.13296, lat: 40.798007, postalCode: "52551" },
    { lng: -91.71229, lat: 42.459398, postalCode: "50682" },
    { lng: -94.17339, lat: 42.872099, postalCode: "50558" },
    { lng: -93.96138, lat: 42.985613, postalCode: "50430" },
    { lng: -91.18417, lat: 43.042838, postalCode: "52158" },
    { lng: -91.13674, lat: 41.008896, postalCode: "52637" },
    { lng: -91.81038, lat: 42.643265, postalCode: "50671" },
    { lng: -92.58225, lat: 41.95277, postalCode: "52339" },
    { lng: -92.19199, lat: 41.741215, postalCode: "52251" },
    { lng: -92.91579, lat: 42.805321, postalCode: "50611" },
    { lng: -91.81527, lat: 41.356493, postalCode: "52359" },
    { lng: -94.92775, lat: 41.910011, postalCode: "51463" },
    { lng: -92.87985, lat: 42.966003, postalCode: "50653" },
    { lng: -92.10136, lat: 42.259239, postalCode: "52313" },
    { lng: -94.75806, lat: 41.445635, postalCode: "50020" },
    { lng: -90.75849, lat: 41.617234, postalCode: "52773" },
    { lng: -94.22578, lat: 43.295639, postalCode: "50517" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50368" },
    { lng: -95.61754, lat: 41.856963, postalCode: "51529" },
    { lng: -95.49415, lat: 41.725547, postalCode: "51562" },
    { lng: -96.37617, lat: 42.406357, postalCode: "51111" },
    { lng: -95.86981, lat: 42.974759, postalCode: "51022" },
    { lng: -93.60475, lat: 41.804875, postalCode: "50007" },
    { lng: -92.70755, lat: 42.190943, postalCode: "50635" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50980" },
    { lng: -94.33601, lat: 40.814077, postalCode: "50845" },
    { lng: -91.01429, lat: 41.069373, postalCode: "52646" },
    { lng: -91.53532, lat: 41.55464, postalCode: "52235" },
    { lng: -91.69743, lat: 41.300042, postalCode: "52353" },
    { lng: -91.12994, lat: 41.355531, postalCode: "52749" },
    { lng: -93.07833, lat: 42.232454, postalCode: "50258" },
    { lng: -93.60253, lat: 42.237408, postalCode: "50231" },
    { lng: -95.1807, lat: 42.646924, postalCode: "50588" },
    { lng: -94.53114, lat: 42.842111, postalCode: "50581" },
    { lng: -91.70969, lat: 41.503882, postalCode: "52247" },
    { lng: -90.68576, lat: 42.460604, postalCode: "52003" },
    { lng: -96.03218, lat: 41.812797, postalCode: "51545" },
    { lng: -93.58292, lat: 43.002473, postalCode: "50449" },
    { lng: -92.43864, lat: 41.214824, postalCode: "52561" },
    { lng: -95.49821, lat: 41.927255, postalCode: "51528" },
    { lng: -94.71141, lat: 42.096445, postalCode: "51443" },
    { lng: -92.61893, lat: 41.286619, postalCode: "52595" },
    { lng: -95.44193, lat: 41.595686, postalCode: "51574" },
    { lng: -91.26586, lat: 41.573818, postalCode: "52776" },
    { lng: -92.05579, lat: 41.449599, postalCode: "52335" },
    { lng: -92.39359, lat: 41.914489, postalCode: "52215" },
    { lng: -92.28275, lat: 42.477887, postalCode: "50707" },
    { lng: -96.1663, lat: 43.430275, postalCode: "51246" },
    { lng: -94.69246, lat: 42.184035, postalCode: "51451" },
    { lng: -90.83793, lat: 42.088117, postalCode: "52207" },
    { lng: -94.35401, lat: 42.523953, postalCode: "50518" },
    { lng: -93.31162, lat: 42.30884, postalCode: "50122" },
    { lng: -95.36514, lat: 40.750107, postalCode: "51601" },
    { lng: -91.385047, lat: 42.091217, postalCode: "52350" },
    { lng: -94.02502, lat: 42.587704, postalCode: "50594" },
    { lng: -94.64762, lat: 40.80692, postalCode: "50862" },
    { lng: -93.92795, lat: 41.855315, postalCode: "50276" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52498" },
    { lng: -95.4558, lat: 42.996361, postalCode: "51058" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50950" },
    { lng: -90.606284, lat: 41.613034, postalCode: "52809" },
    { lng: -94.0518, lat: 42.26594, postalCode: "50530" },
    { lng: -94.23112, lat: 42.114858, postalCode: "50064" },
    { lng: -94.87032, lat: 41.592497, postalCode: "50076" },
    { lng: -92.66183, lat: 42.463161, postalCode: "50624" },
    { lng: -95.42761, lat: 41.77364, postalCode: "51530" },
    { lng: -91.78792, lat: 41.912296, postalCode: "52228" },
    { lng: -91.5666, lat: 41.89239, postalCode: "52227" },
    { lng: -93.41494, lat: 41.363615, postalCode: "50001" },
    { lng: -90.8869, lat: 42.109395, postalCode: "52309" },
    { lng: -92.673819, lat: 42.343304, postalCode: "50657" },
    { lng: -91.18897, lat: 41.277239, postalCode: "52752" },
    { lng: -91.82082, lat: 41.153462, postalCode: "52540" },
    { lng: -93.00519, lat: 43.147661, postalCode: "50458" },
    { lng: -92.41741, lat: 41.023872, postalCode: "52501" },
    { lng: -95.71945, lat: 42.576775, postalCode: "51061" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50359" },
    { lng: -91.85354, lat: 41.478896, postalCode: "52356" },
    { lng: -92.91126, lat: 41.411394, postalCode: "50219" },
    { lng: -93.78537, lat: 41.491203, postalCode: "50061" },
    { lng: -91.478305, lat: 40.758742, postalCode: "52657" },
    { lng: -92.84446, lat: 40.86655, postalCode: "52571" },
    { lng: -94.27644, lat: 42.884723, postalCode: "50519" },
    { lng: -96.30291, lat: 42.714106, postalCode: "51038" },
    { lng: -90.91015, lat: 41.603249, postalCode: "52747" },
    { lng: -91.19335, lat: 42.221176, postalCode: "52310" },
    { lng: -95.3188, lat: 42.687676, postalCode: "51002" },
    { lng: -91.61409, lat: 41.982201, postalCode: "52403" },
    { lng: -92.532032, lat: 41.685742, postalCode: "50177" },
    { lng: -95.80303, lat: 41.022849, postalCode: "51561" },
    { lng: -91.23415, lat: 40.707554, postalCode: "52658" },
    { lng: -91.56058, lat: 41.650916, postalCode: "52246" },
    { lng: -91.52019, lat: 41.649867, postalCode: "52240" },
    { lng: -94.70587, lat: 42.836636, postalCode: "50546" },
    { lng: -92.09319, lat: 42.453206, postalCode: "50648" },
    { lng: -94.55679, lat: 41.307029, postalCode: "50846" },
    { lng: -94.4065, lat: 41.504978, postalCode: "50164" },
    { lng: -95.10424, lat: 41.313817, postalCode: "51544" },
    { lng: -95.99657, lat: 41.739005, postalCode: "51557" },
    { lng: -95.18303, lat: 42.728665, postalCode: "50592" },
    { lng: -92.53946, lat: 41.581309, postalCode: "50171" },
    { lng: -91.92182, lat: 42.682886, postalCode: "50662" },
    { lng: -94.50771, lat: 42.16372, postalCode: "50050" },
    { lng: -93.66671, lat: 43.46592, postalCode: "50473" },
    { lng: -92.88621, lat: 41.202354, postalCode: "50044" },
    { lng: -90.35223, lat: 41.801711, postalCode: "52757" },
    { lng: -92.64227, lat: 41.151537, postalCode: "52553" },
    { lng: -93.01693, lat: 43.414156, postalCode: "50426" },
    { lng: -91.9818, lat: 41.90889, postalCode: "52354" },
    { lng: -93.00357, lat: 42.116477, postalCode: "50005" },
    { lng: -96.085, lat: 41.9134, postalCode: "51523" },
    { lng: -91.3618, lat: 42.678463, postalCode: "52042" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50303" },
    { lng: -95.30928, lat: 42.204515, postalCode: "51448" },
    { lng: -93.37907, lat: 40.688451, postalCode: "50008" },
    { lng: -95.43752, lat: 43.407616, postalCode: "51345" },
    { lng: -93.04392, lat: 42.922595, postalCode: "50433" },
    { lng: -93.22082, lat: 42.88516, postalCode: "50475" },
    { lng: -91.68102, lat: 42.044409, postalCode: "52233" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50307" },
    { lng: -91.95, lat: 41.017736, postalCode: "52556" },
    { lng: -93.60966, lat: 42.02933, postalCode: "50010" },
    { lng: -95.06939, lat: 41.913633, postalCode: "51455" },
    { lng: -96.2687, lat: 42.809286, postalCode: "51008" },
    { lng: -92.788967, lat: 43.356439, postalCode: "50481" },
    { lng: -95.9662, lat: 42.036864, postalCode: "51059" },
    { lng: -95.59597, lat: 43.30288, postalCode: "51350" },
    { lng: -90.519519, lat: 41.880619, postalCode: "52736" },
    { lng: -95.00965, lat: 43.133063, postalCode: "51333" },
    { lng: -91.16405, lat: 43.195154, postalCode: "52146" },
    { lng: -91.61691, lat: 40.848485, postalCode: "52649" },
    { lng: -90.606284, lat: 41.613034, postalCode: "52808" },
    { lng: -93.60711, lat: 40.801638, postalCode: "50103" },
    { lng: -95.149305, lat: 40.738309, postalCode: "51603" },
    { lng: -94.81524, lat: 43.401328, postalCode: "51334" },
    { lng: -94.74138, lat: 43.239934, postalCode: "51342" },
    { lng: -93.640818, lat: 42.023535, postalCode: "50013" },
    { lng: -93.0975, lat: 42.357549, postalCode: "50627" },
    { lng: -90.816, lat: 42.431983, postalCode: "52068" },
    { lng: -93.87437, lat: 42.074515, postalCode: "50036" },
    { lng: -92.91099, lat: 42.662282, postalCode: "50649" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50330" },
    { lng: -91.04567, lat: 41.888182, postalCode: "52216" },
    { lng: -92.69186, lat: 42.263223, postalCode: "50652" },
    { lng: -94.96903, lat: 43.303488, postalCode: "51364" },
    { lng: -91.25499, lat: 40.828014, postalCode: "52638" },
    { lng: -93.64187, lat: 42.490439, postalCode: "50034" },
    { lng: -91.415648, lat: 40.594704, postalCode: "52648" },
    { lng: -96.28232, lat: 42.307882, postalCode: "51052" },
    { lng: -91.843639, lat: 42.862275, postalCode: "50664" },
    { lng: -93.603967, lat: 41.592089, postalCode: "50319" },
    { lng: -93.30502, lat: 40.847504, postalCode: "50165" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52497" },
    { lng: -93.099205, lat: 41.334549, postalCode: "50198" },
    { lng: -92.16575, lat: 42.576054, postalCode: "50626" },
    { lng: -94.84924, lat: 41.671777, postalCode: "50117" },
    { lng: -95.49852, lat: 41.020978, postalCode: "51540" },
    { lng: -91.86755, lat: 41.993799, postalCode: "52206" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50333" },
    { lng: -94.05829, lat: 42.358466, postalCode: "50557" },
    { lng: -96.19039, lat: 43.075142, postalCode: "51250" },
    { lng: -93.55338, lat: 42.7402, postalCode: "50470" },
    { lng: -96.44319, lat: 43.451905, postalCode: "51241" },
    { lng: -93.50761, lat: 42.653654, postalCode: "50071" },
    { lng: -90.82532, lat: 42.640962, postalCode: "52073" },
    { lng: -94.52602, lat: 41.690084, postalCode: "50115" },
    { lng: -95.65409, lat: 43.189668, postalCode: "51248" },
    { lng: -95.55529, lat: 42.496494, postalCode: "51025" },
    { lng: -96.16648, lat: 42.137272, postalCode: "51063" },
    { lng: -92.00735, lat: 42.778924, postalCode: "50681" },
    { lng: -91.47271, lat: 40.719775, postalCode: "52656" },
    { lng: -92.21802, lat: 42.526943, postalCode: "50623" },
    { lng: -92.29288, lat: 41.715992, postalCode: "52347" },
    { lng: -90.37338, lat: 41.677679, postalCode: "52768" },
    { lng: -95.64792, lat: 41.455142, postalCode: "51559" },
    { lng: -95.44924, lat: 42.172338, postalCode: "51461" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50329" },
    { lng: -93.7935, lat: 41.67822, postalCode: "50111" },
    { lng: -95.14466, lat: 43.150381, postalCode: "51301" },
    { lng: -91.72605, lat: 42.112232, postalCode: "52341" },
    { lng: -94.70471, lat: 43.393113, postalCode: "51344" },
    { lng: -91.45842, lat: 43.051922, postalCode: "52156" },
    { lng: -95.10125, lat: 42.527637, postalCode: "50567" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50336" },
    { lng: -93.82106, lat: 41.967091, postalCode: "50152" },
    { lng: -95.135861, lat: 41.233503, postalCode: "51515" },
    { lng: -93.39301, lat: 42.939528, postalCode: "50479" },
    { lng: -92.78015, lat: 42.361095, postalCode: "50638" },
    { lng: -92.69484, lat: 41.56512, postalCode: "50242" },
    { lng: -93.58072, lat: 41.537059, postalCode: "50320" },
    { lng: -95.71998, lat: 42.276668, postalCode: "51019" },
    { lng: -93.71388, lat: 41.193148, postalCode: "50210" },
    { lng: -92.17863, lat: 41.459739, postalCode: "52355" },
    { lng: -91.17849, lat: 40.830534, postalCode: "52655" },
    { lng: -94.18256, lat: 42.499242, postalCode: "50501" },
    { lng: -93.61033, lat: 43.102343, postalCode: "50438" },
    { lng: -93.8041, lat: 43.104076, postalCode: "50423" },
    { lng: -93.505751, lat: 41.587818, postalCode: "50327" },
    { lng: -90.57503, lat: 41.522832, postalCode: "52801" },
    { lng: -96.39562, lat: 42.493559, postalCode: "51101" },
    { lng: -91.56899, lat: 41.483058, postalCode: "52327" },
    { lng: -91.81803, lat: 42.983744, postalCode: "52175" },
    { lng: -96.45816, lat: 43.30957, postalCode: "51240" },
    { lng: -94.78269, lat: 42.973868, postalCode: "50527" },
    { lng: -95.00852, lat: 41.410484, postalCode: "50022" },
    { lng: -92.24785, lat: 40.852256, postalCode: "52560" },
    { lng: -91.73262, lat: 42.616399, postalCode: "50607" },
    { lng: -92.08308, lat: 42.010556, postalCode: "52346" },
    { lng: -93.94113, lat: 42.764886, postalCode: "50542" },
    { lng: -90.95239, lat: 42.682337, postalCode: "52066" },
    { lng: -92.32815, lat: 41.809975, postalCode: "52232" },
    { lng: -91.35552, lat: 42.959691, postalCode: "52047" },
    { lng: -95.16395, lat: 42.453686, postalCode: "50535" },
    { lng: -91.13361, lat: 41.750868, postalCode: "52772" },
    { lng: -92.73565, lat: 40.777917, postalCode: "52593" },
    { lng: -95.85888, lat: 43.184994, postalCode: "51201" },
    { lng: -95.91108, lat: 42.075717, postalCode: "51010" },
    { lng: -94.37939, lat: 42.017677, postalCode: "50129" },
    { lng: -92.50295, lat: 42.855231, postalCode: "50666" },
    { lng: -91.87908, lat: 42.222388, postalCode: "52345" },
    { lng: -91.15931, lat: 41.578393, postalCode: "52720" },
    { lng: -90.86042, lat: 42.558403, postalCode: "52039" },
    { lng: -93.42826, lat: 42.594986, postalCode: "50227" },
    { lng: -95.42637, lat: 40.884496, postalCode: "51645" },
    { lng: -92.11188, lat: 42.846877, postalCode: "50674" },
    { lng: -95.90556, lat: 41.816224, postalCode: "51564" },
    { lng: -91.599974, lat: 41.642657, postalCode: "52319" },
    { lng: -95.73297, lat: 43.091483, postalCode: "51231" },
    { lng: -95.61155, lat: 41.231146, postalCode: "51575" },
    { lng: -92.46149, lat: 43.031136, postalCode: "50645" },
    { lng: -93.26217, lat: 42.515426, postalCode: "50126" },
    { lng: -91.00932, lat: 42.442226, postalCode: "52046" },
    { lng: -93.79219, lat: 41.360357, postalCode: "50033" },
    { lng: -92.24888, lat: 41.26457, postalCode: "52562" },
    { lng: -91.98027, lat: 41.194129, postalCode: "52585" },
    { lng: -90.59624, lat: 41.907575, postalCode: "52774" },
    { lng: -93.62004, lat: 41.585939, postalCode: "50309" },
    { lng: -90.86097, lat: 41.843587, postalCode: "52777" },
    { lng: -95.95294, lat: 42.20486, postalCode: "51051" },
    { lng: -92.60686, lat: 42.329448, postalCode: "50669" },
    { lng: -93.7385, lat: 42.738313, postalCode: "50525" },
    { lng: -96.38003, lat: 42.505046, postalCode: "51105" },
    { lng: -95.12714, lat: 43.003251, postalCode: "51343" },
    { lng: -93.2459, lat: 42.632923, postalCode: "50041" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50015" },
    { lng: -93.40134, lat: 41.86625, postalCode: "50161" },
    { lng: -91.37925, lat: 43.061204, postalCode: "52159" },
    { lng: -93.40082, lat: 42.50767, postalCode: "50006" },
    { lng: -94.47619, lat: 40.621398, postalCode: "50836" },
    { lng: -95.27482, lat: 43.057948, postalCode: "51357" },
    { lng: -91.77461, lat: 42.290421, postalCode: "52352" },
    { lng: -93.627368, lat: 41.587839, postalCode: "50392" },
    { lng: -92.32418, lat: 42.513636, postalCode: "50703" },
    { lng: -95.79752, lat: 41.269884, postalCode: "51502" },
    { lng: -92.7984, lat: 41.026796, postalCode: "52531" },
    { lng: -93.78093, lat: 41.60787, postalCode: "50325" },
    { lng: -95.14315, lat: 42.907223, postalCode: "50585" },
    { lng: -91.19866, lat: 42.886672, postalCode: "52049" },
    { lng: -94.20185, lat: 42.720708, postalCode: "50529" },
    { lng: -94.45652, lat: 42.455148, postalCode: "50552" },
    { lng: -96.23855, lat: 42.609657, postalCode: "51024" },
    { lng: -95.11291, lat: 41.470605, postalCode: "51552" },
    { lng: -90.808767, lat: 41.462494, postalCode: "52759" },
    { lng: -93.54644, lat: 42.486287, postalCode: "50271" },
    { lng: -95.65772, lat: 42.976116, postalCode: "51046" },
    { lng: -91.63859, lat: 42.158477, postalCode: "52202" },
    { lng: -93.36927, lat: 41.182964, postalCode: "50139" },
    { lng: -96.22202, lat: 43.291936, postalCode: "51235" },
    { lng: -93.94282, lat: 43.392145, postalCode: "50424" },
    { lng: -95.46823, lat: 42.332677, postalCode: "51445" },
    { lng: -92.96979, lat: 41.940175, postalCode: "50120" },
    { lng: -91.57393, lat: 40.675175, postalCode: "52625" },
    { lng: -92.309475, lat: 42.469768, postalCode: "50799" },
    { lng: -90.21539, lat: 41.861633, postalCode: "52732" },
    { lng: -95.151095, lat: 43.082426, postalCode: "51340" },
    { lng: -94.34518, lat: 41.919495, postalCode: "50059" },
    { lng: -94.66372, lat: 42.949884, postalCode: "50562" },
    { lng: -91.49604, lat: 42.338694, postalCode: "52330" },
    { lng: -91.765621, lat: 43.47865, postalCode: "52131" },
    { lng: -93.00381, lat: 42.187863, postalCode: "50148" },
    { lng: -94.47223, lat: 43.398596, postalCode: "50514" },
    { lng: -93.29857, lat: 41.02891, postalCode: "50049" },
    { lng: -91.07654, lat: 42.60483, postalCode: "52056" },
    { lng: -91.70835, lat: 41.980283, postalCode: "52405" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52407" },
    { lng: -91.95204, lat: 43.203977, postalCode: "52168" },
    { lng: -93.94852, lat: 41.687847, postalCode: "50063" },
    { lng: -94.19272, lat: 40.857182, postalCode: "50863" },
    { lng: -93.60475, lat: 41.725288, postalCode: "50021" },
    { lng: -93.67508, lat: 41.871283, postalCode: "50244" },
    { lng: -95.03657, lat: 43.014514, postalCode: "51341" },
    { lng: -93.7952, lat: 42.281552, postalCode: "50246" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50361" },
    { lng: -93.10226, lat: 41.307595, postalCode: "50138" },
    { lng: -92.15576, lat: 43.1101, postalCode: "52154" },
    { lng: -93.20192, lat: 42.994932, postalCode: "50469" },
    { lng: -91.76183, lat: 41.6896, postalCode: "52322" },
    { lng: -92.865, lat: 41.93746, postalCode: "50078" },
    { lng: -90.68748, lat: 41.712643, postalCode: "52746" },
    { lng: -94.25943, lat: 42.17191, postalCode: "50217" },
    { lng: -93.57506, lat: 41.354068, postalCode: "50125" },
    { lng: -93.0738, lat: 41.456325, postalCode: "50214" },
    { lng: -90.40762, lat: 42.051619, postalCode: "52069" },
    { lng: -93.39783, lat: 42.24489, postalCode: "50102" },
    { lng: -92.53473, lat: 42.953461, postalCode: "50658" },
    { lng: -95.01145, lat: 42.951621, postalCode: "51366" },
    { lng: -92.0019, lat: 42.32437, postalCode: "52210" },
    { lng: -90.67352, lat: 42.087769, postalCode: "52060" },
    { lng: -91.53273, lat: 41.209047, postalCode: "52621" },
    { lng: -91.34228, lat: 42.473959, postalCode: "52036" },
    { lng: -92.1148, lat: 41.201922, postalCode: "52576" },
    { lng: -95.10711, lat: 42.072073, postalCode: "51467" },
    { lng: -92.68369, lat: 40.679322, postalCode: "52572" },
    { lng: -92.309475, lat: 42.469768, postalCode: "50704" },
    { lng: -93.5311, lat: 41.89949, postalCode: "50046" },
    { lng: -94.09837, lat: 43.482104, postalCode: "56027" },
    { lng: -94.07424, lat: 42.818996, postalCode: "50545" },
    { lng: -93.94857, lat: 40.630919, postalCode: "50140" },
    { lng: -92.65534, lat: 41.281669, postalCode: "52577" },
    { lng: -91.24208, lat: 42.346132, postalCode: "52237" },
    { lng: -95.79109, lat: 42.165283, postalCode: "51034" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50338" },
    { lng: -92.70805, lat: 42.079127, postalCode: "50632" },
    { lng: -94.45293, lat: 42.71032, postalCode: "50541" },
    { lng: -93.24015, lat: 43.444448, postalCode: "50459" },
    { lng: -91.67149, lat: 42.752057, postalCode: "50606" },
    { lng: -94.461622, lat: 41.039762, postalCode: "50842" },
    { lng: -93.648465, lat: 42.023557, postalCode: "50012" },
    { lng: -92.34521, lat: 41.330967, postalCode: "52550" },
    { lng: -93.66741, lat: 42.033235, postalCode: "50014" },
    { lng: -93.73568, lat: 41.570916, postalCode: "50265" },
    { lng: -95.88832, lat: 43.443704, postalCode: "51243" },
    { lng: -93.59829, lat: 41.606339, postalCode: "50316" },
    { lng: -95.62225, lat: 41.123653, postalCode: "51571" },
    { lng: -94.47102, lat: 40.796387, postalCode: "50840" },
    { lng: -93.43553, lat: 43.260087, postalCode: "50434" },
    { lng: -93.04954, lat: 42.563333, postalCode: "50601" },
    { lng: -90.6794, lat: 42.515252, postalCode: "52001" },
    { lng: -94.98414, lat: 41.142185, postalCode: "50847" },
    { lng: -91.252459, lat: 42.862739, postalCode: "52055" },
    { lng: -92.063603, lat: 41.686351, postalCode: "52204" },
    { lng: -95.13871, lat: 41.232047, postalCode: "51535" },
    { lng: -91.54559, lat: 41.133346, postalCode: "52647" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50362" },
    { lng: -95.08334, lat: 41.6485, postalCode: "51543" },
    { lng: -91.65231, lat: 42.021016, postalCode: "52402" },
    { lng: -94.624, lat: 42.878058, postalCode: "50573" },
    { lng: -93.54829, lat: 41.606939, postalCode: "50317" },
    { lng: -96.4709, lat: 43.005927, postalCode: "51023" },
    { lng: -93.67403, lat: 41.625988, postalCode: "50310" },
    { lng: -93.52778, lat: 40.60243, postalCode: "50147" },
    { lng: -93.4484, lat: 42.026021, postalCode: "50201" },
    { lng: -90.25171, lat: 41.978834, postalCode: "52701" },
    { lng: -94.04659, lat: 41.750745, postalCode: "50167" },
    { lng: -94.9015, lat: 41.242612, postalCode: "50843" },
    { lng: -91.99748, lat: 41.728994, postalCode: "52220" },
    { lng: -94.29535, lat: 42.778791, postalCode: "50582" },
    { lng: -95.21254, lat: 40.588924, postalCode: "51630" },
    { lng: -96.1881, lat: 42.403236, postalCode: "51007" },
    { lng: -96.05654, lat: 42.820632, postalCode: "51045" },
    { lng: -93.65232, lat: 42.302683, postalCode: "50130" },
    { lng: -93.59844, lat: 41.893335, postalCode: "50124" },
    { lng: -93.59181, lat: 41.01619, postalCode: "50275" },
    { lng: -94.29779, lat: 42.36534, postalCode: "50523" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50396" },
    { lng: -90.53286, lat: 41.732418, postalCode: "52756" },
    { lng: -95.90651, lat: 41.557887, postalCode: "51555" },
    { lng: -93.2007, lat: 42.250902, postalCode: "50206" },
    { lng: -95.61438, lat: 42.626822, postalCode: "51049" },
    { lng: -92.07173, lat: 40.946765, postalCode: "52567" },
    { lng: -92.62879, lat: 40.947787, postalCode: "52536" },
    { lng: -92.59746, lat: 42.710399, postalCode: "50670" },
    { lng: -91.39992, lat: 41.038005, postalCode: "52644" },
    { lng: -95.00006, lat: 42.612476, postalCode: "50568" },
    { lng: -91.88949, lat: 42.839782, postalCode: "52164" },
    { lng: -94.23785, lat: 41.488275, postalCode: "50070" },
    { lng: -93.13215, lat: 42.671214, postalCode: "50633" },
    { lng: -95.156217, lat: 40.737446, postalCode: "51656" },
    { lng: -94.03587, lat: 41.612628, postalCode: "50003" },
    { lng: -90.61964, lat: 41.546931, postalCode: "52804" },
    { lng: -92.744821, lat: 43.110765, postalCode: "50620" },
    { lng: -91.33428, lat: 41.680696, postalCode: "52358" },
    { lng: -94.68365, lat: 42.724938, postalCode: "50574" },
    { lng: -92.7293, lat: 43.38301, postalCode: "50454" },
    { lng: -93.53916, lat: 41.666513, postalCode: "50032" },
    { lng: -95.56494, lat: 40.679816, postalCode: "51650" },
    { lng: -91.83532, lat: 41.876767, postalCode: "52351" },
    { lng: -95.68777, lat: 41.388244, postalCode: "51576" },
    { lng: -95.27007, lat: 40.822954, postalCode: "51638" },
    { lng: -94.86276, lat: 41.394625, postalCode: "50274" },
    { lng: -91.60716, lat: 40.784287, postalCode: "52631" },
    { lng: -90.3107, lat: 42.092925, postalCode: "52064" },
    { lng: -94.143566, lat: 42.038746, postalCode: "50031" },
    { lng: -92.78183, lat: 42.576102, postalCode: "50665" },
    { lng: -95.99636, lat: 42.983011, postalCode: "51003" },
    { lng: -90.52951, lat: 41.825848, postalCode: "52742" },
    { lng: -91.44072, lat: 42.0626, postalCode: "52336" },
    { lng: -94.01728, lat: 42.162453, postalCode: "50223" },
    { lng: -91.94174, lat: 41.341027, postalCode: "52248" },
    { lng: -93.6187, lat: 42.842616, postalCode: "50421" },
    { lng: -90.96586, lat: 41.749701, postalCode: "52721" },
    { lng: -95.03061, lat: 42.302091, postalCode: "51450" },
    { lng: -94.32731, lat: 41.500086, postalCode: "50250" },
    { lng: -93.81486, lat: 41.759988, postalCode: "50109" },
    { lng: -94.6884, lat: 41.87131, postalCode: "50058" },
    { lng: -96.02872, lat: 41.64243, postalCode: "51556" },
    { lng: -92.80631, lat: 41.324195, postalCode: "50143" },
    { lng: -93.64504, lat: 42.110624, postalCode: "50105" },
    { lng: -91.17035, lat: 41.171534, postalCode: "52653" },
    { lng: -92.32323, lat: 43.072094, postalCode: "50659" },
    { lng: -93.79925, lat: 41.877415, postalCode: "50156" },
    { lng: -91.65912, lat: 41.97545, postalCode: "52401" },
    { lng: -95.34343, lat: 42.934345, postalCode: "51047" },
    { lng: -92.45012, lat: 41.753372, postalCode: "52211" },
    { lng: -94.21128, lat: 41.029408, postalCode: "50830" },
    { lng: -94.56409, lat: 42.022139, postalCode: "51462" },
    { lng: -94.20931, lat: 41.604233, postalCode: "50233" },
    { lng: -92.93841, lat: 43.402708, postalCode: "50472" },
    { lng: -93.49432, lat: 42.168363, postalCode: "50236" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50363" },
    { lng: -93.21383, lat: 41.777174, postalCode: "50127" },
    { lng: -92.85941, lat: 41.5714, postalCode: "50251" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50328" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50339" },
    { lng: -95.47445, lat: 40.707376, postalCode: "51639" },
    { lng: -92.66584, lat: 42.788967, postalCode: "50619" },
    { lng: -91.40882, lat: 42.855022, postalCode: "52043" },
    { lng: -95.35615, lat: 42.345965, postalCode: "51431" },
    { lng: -94.42424, lat: 41.308843, postalCode: "50849" },
    { lng: -93.59867, lat: 42.186442, postalCode: "50248" },
    { lng: -90.75572, lat: 41.823151, postalCode: "52729" },
    { lng: -93.77004, lat: 41.031309, postalCode: "50213" },
    { lng: -95.54106, lat: 42.876589, postalCode: "51029" },
    { lng: -91.91805, lat: 41.597014, postalCode: "52325" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50332" },
    { lng: -94.10552, lat: 42.174905, postalCode: "50040" },
    { lng: -95.29692, lat: 41.638566, postalCode: "51537" },
    { lng: -92.71138, lat: 42.527131, postalCode: "50673" },
    { lng: -92.9751, lat: 42.754361, postalCode: "50625" },
    { lng: -94.35418, lat: 41.779131, postalCode: "50277" },
    { lng: -95.21698, lat: 40.671163, postalCode: "51636" },
    { lng: -96.309509, lat: 42.896225, postalCode: "51017" },
    { lng: -95.9482, lat: 42.786134, postalCode: "51050" },
    { lng: -92.57177, lat: 42.02524, postalCode: "52342" },
    { lng: -95.98619, lat: 42.596358, postalCode: "51028" },
    { lng: -95.3958, lat: 41.719352, postalCode: "51578" },
    { lng: -93.68076, lat: 41.601189, postalCode: "50311" },
    { lng: -93.39718, lat: 42.168307, postalCode: "50154" },
    { lng: -91.24671, lat: 42.006556, postalCode: "52312" },
    { lng: -95.67001, lat: 40.886487, postalCode: "51653" },
    { lng: -91.50849, lat: 41.809993, postalCode: "52333" },
    { lng: -92.2727, lat: 41.887989, postalCode: "52208" },
    { lng: -92.30485, lat: 42.162873, postalCode: "52224" },
    { lng: -92.22915, lat: 42.693119, postalCode: "50668" },
    { lng: -93.48058, lat: 41.491089, postalCode: "50047" },
    { lng: -92.19641, lat: 41.328837, postalCode: "52591" },
    { lng: -94.13904, lat: 42.624944, postalCode: "50516" },
    { lng: -91.72511, lat: 42.049941, postalCode: "52411" },
    { lng: -92.46875, lat: 41.339202, postalCode: "52586" },
    { lng: -91.54024, lat: 42.291075, postalCode: "52218" },
    { lng: -94.944563, lat: 41.916027, postalCode: "51496" },
    { lng: -95.7969, lat: 43.306169, postalCode: "51232" },
    { lng: -92.15933, lat: 41.572665, postalCode: "52308" },
    { lng: -93.7909, lat: 43.222661, postalCode: "50432" },
    { lng: -93.46844, lat: 42.811536, postalCode: "50420" },
    { lng: -95.39748, lat: 41.320647, postalCode: "51560" },
    { lng: -92.30477, lat: 42.882349, postalCode: "50631" },
    { lng: -94.90006, lat: 43.133294, postalCode: "51358" },
    { lng: -95.87321, lat: 41.362096, postalCode: "51526" },
    { lng: -91.96821, lat: 41.016566, postalCode: "52557" },
    { lng: -95.570082, lat: 42.9552, postalCode: "51009" },
    { lng: -93.09976, lat: 43.248524, postalCode: "50464" },
    { lng: -90.94316, lat: 41.86521, postalCode: "52255" },
    { lng: -93.222587, lat: 42.834852, postalCode: "50427" },
    { lng: -95.10892, lat: 43.428983, postalCode: "51360" },
    { lng: -92.38059, lat: 41.990295, postalCode: "52348" },
    { lng: -90.41181, lat: 41.926933, postalCode: "52750" },
    { lng: -94.20565, lat: 41.931753, postalCode: "50235" },
    { lng: -94.85188, lat: 43.023296, postalCode: "50515" },
    { lng: -93.80821, lat: 42.926804, postalCode: "50447" },
    { lng: -95.58503, lat: 41.568945, postalCode: "51563" },
    { lng: -96.47647, lat: 42.528033, postalCode: "51109" },
    { lng: -94.74784, lat: 43.309573, postalCode: "51365" },
    { lng: -95.4044, lat: 41.231387, postalCode: "51525" },
    { lng: -92.72237, lat: 43.220043, postalCode: "50460" },
    { lng: -92.32398, lat: 42.011889, postalCode: "52225" },
    { lng: -91.29941, lat: 43.212565, postalCode: "52170" },
    { lng: -91.55268, lat: 42.585197, postalCode: "52038" },
    { lng: -92.66719, lat: 43.072978, postalCode: "50616" },
    { lng: -91.47755, lat: 43.265791, postalCode: "52172" },
    { lng: -93.27056, lat: 41.381537, postalCode: "50225" },
    { lng: -96.06156, lat: 43.015685, postalCode: "51041" },
    { lng: -91.16545, lat: 40.952014, postalCode: "52650" },
    { lng: -94.26066, lat: 41.668493, postalCode: "50146" },
    { lng: -91.66528, lat: 43.119538, postalCode: "52133" },
    { lng: -94.01444, lat: 41.830156, postalCode: "50039" },
    { lng: -91.20377, lat: 43.019203, postalCode: "52157" },
    { lng: -95.22158, lat: 41.885538, postalCode: "51454" },
    { lng: -94.99017, lat: 40.960481, postalCode: "50864" },
    { lng: -93.1281, lat: 40.673728, postalCode: "52590" },
    { lng: -95.90784, lat: 42.378152, postalCode: "51004" },
    { lng: -93.29691, lat: 41.880967, postalCode: "50055" },
    { lng: -95.44357, lat: 41.182061, postalCode: "51549" },
    { lng: -94.71131, lat: 40.671295, postalCode: "50833" },
    { lng: -92.25004, lat: 41.178396, postalCode: "52568" },
    { lng: -94.19697, lat: 43.448529, postalCode: "50556" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52410" },
    { lng: -94.85098, lat: 42.417882, postalCode: "50561" },
    { lng: -94.05446, lat: 42.684486, postalCode: "50591" },
    { lng: -90.53924, lat: 41.565433, postalCode: "52807" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50395" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50369" },
    { lng: -94.97033, lat: 42.768165, postalCode: "50510" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50350" },
    { lng: -93.74273, lat: 40.736802, postalCode: "50144" },
    { lng: -92.88994, lat: 40.730159, postalCode: "52544" },
    { lng: -91.51505, lat: 42.697689, postalCode: "52076" },
    { lng: -95.80529, lat: 42.471378, postalCode: "51016" },
    { lng: -92.17163, lat: 41.913959, postalCode: "52257" },
    { lng: -94.59728, lat: 41.053745, postalCode: "50859" },
    { lng: -93.173193, lat: 41.242194, postalCode: "50163" },
    { lng: -93.14222, lat: 42.13644, postalCode: "50051" },
    { lng: -92.52863, lat: 41.085573, postalCode: "52548" },
    { lng: -90.4557, lat: 42.124141, postalCode: "52074" },
    { lng: -95.34363, lat: 41.828495, postalCode: "51527" },
    { lng: -95.46501, lat: 41.52279, postalCode: "51570" },
    { lng: -95.78134, lat: 41.64252, postalCode: "51546" },
    { lng: -90.8085, lat: 41.950398, postalCode: "52254" },
    { lng: -94.7321, lat: 42.475817, postalCode: "50551" },
    { lng: -93.22573, lat: 43.349766, postalCode: "50448" },
    { lng: -93.31782, lat: 41.449489, postalCode: "50252" },
    { lng: -94.41061, lat: 43.077787, postalCode: "50598" },
    { lng: -93.91817, lat: 43.484445, postalCode: "50465" },
    { lng: -93.31293, lat: 42.011477, postalCode: "50056" },
    { lng: -95.76463, lat: 40.836473, postalCode: "51654" },
    { lng: -95.53675, lat: 43.413538, postalCode: "51354" },
    { lng: -90.4191, lat: 41.56823, postalCode: "52767" },
    { lng: -90.7392, lat: 41.992936, postalCode: "52226" },
    { lng: -93.45659, lat: 40.937432, postalCode: "50068" },
    { lng: -91.97867, lat: 40.86112, postalCode: "52535" },
    { lng: -94.68361, lat: 41.237084, postalCode: "50837" },
    { lng: -93.21518, lat: 42.743106, postalCode: "50441" },
    { lng: -93.61472, lat: 42.682176, postalCode: "50101" },
    { lng: -90.73252, lat: 41.456515, postalCode: "52728" },
    { lng: -95.36782, lat: 41.388872, postalCode: "51536" },
    { lng: -91.66064, lat: 43.00106, postalCode: "52135" },
    { lng: -94.3214, lat: 42.437122, postalCode: "50566" },
    { lng: -92.06988, lat: 42.640202, postalCode: "50629" },
    { lng: -95.54687, lat: 40.865993, postalCode: "51649" },
    { lng: -91.57195, lat: 40.974026, postalCode: "52641" },
    { lng: -95.90691, lat: 43.495384, postalCode: "56129" },
    { lng: -95.5461, lat: 41.425925, postalCode: "51553" },
    { lng: -91.34528, lat: 42.603334, postalCode: "52050" },
    { lng: -93.50069, lat: 41.203946, postalCode: "50145" },
    { lng: -91.45231, lat: 42.484297, postalCode: "52057" },
    { lng: -93.14736, lat: 40.801913, postalCode: "52583" },
    { lng: -95.57938, lat: 42.08255, postalCode: "51439" },
    { lng: -95.149305, lat: 40.738309, postalCode: "51602" },
    { lng: -96.34194, lat: 42.38556, postalCode: "51054" },
    { lng: -91.08369, lat: 41.57371, postalCode: "52760" },
    { lng: -91.42313, lat: 42.235999, postalCode: "52219" },
    { lng: -92.89481, lat: 41.736358, postalCode: "50135" },
    { lng: -93.931686, lat: 42.036552, postalCode: "50037" },
    { lng: -93.86151, lat: 41.601516, postalCode: "50263" },
    { lng: -94.9812, lat: 41.84115, postalCode: "50110" },
    { lng: -92.94775, lat: 41.17969, postalCode: "50116" },
    { lng: -94.55588, lat: 41.845871, postalCode: "50029" },
    { lng: -90.28869, lat: 41.780515, postalCode: "52730" },
    { lng: -92.01394, lat: 41.651571, postalCode: "52361" },
    { lng: -96.17167, lat: 42.79728, postalCode: "51031" },
    { lng: -93.99988, lat: 43.104175, postalCode: "50483" },
    { lng: -91.36693, lat: 41.378166, postalCode: "52739" },
    { lng: -93.465172, lat: 42.035998, postalCode: "50011" },
    { lng: -94.33067, lat: 40.69705, postalCode: "50835" },
    { lng: -94.54767, lat: 43.117331, postalCode: "50528" },
    { lng: -91.661901, lat: 41.572682, postalCode: "52244" },
    { lng: -91.8005, lat: 42.065474, postalCode: "52324" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50334" },
    { lng: -91.37449, lat: 41.259329, postalCode: "52737" },
    { lng: -95.84004, lat: 41.427994, postalCode: "51542" },
    { lng: -94.81453, lat: 41.913691, postalCode: "51440" },
    { lng: -91.32445, lat: 42.748558, postalCode: "52044" },
    { lng: -92.21818, lat: 42.468387, postalCode: "50667" },
    { lng: -93.099205, lat: 41.334549, postalCode: "50197" },
    { lng: -92.4828, lat: 42.193577, postalCode: "50675" },
    { lng: -90.56866, lat: 41.659064, postalCode: "52748" },
    { lng: -96.33334, lat: 43.43813, postalCode: "51242" },
    { lng: -94.77195, lat: 41.247145, postalCode: "50853" },
    { lng: -92.07684, lat: 41.790216, postalCode: "52301" },
    { lng: -93.48259, lat: 43.124246, postalCode: "50482" },
    { lng: -93.10517, lat: 41.528347, postalCode: "50170" },
    { lng: -93.78287, lat: 41.581181, postalCode: "50266" },
    { lng: -91.68552, lat: 41.840184, postalCode: "52338" },
    { lng: -92.45609, lat: 42.527184, postalCode: "50613" },
    { lng: -91.66955, lat: 41.710231, postalCode: "52340" },
    { lng: -93.23904, lat: 41.23022, postalCode: "50062" },
    { lng: -95.78317, lat: 41.987483, postalCode: "51572" },
    { lng: -90.47987, lat: 42.161997, postalCode: "52075" },
    { lng: -92.711, lat: 41.980296, postalCode: "50173" },
    { lng: -90.63216, lat: 41.967074, postalCode: "52037" },
    { lng: -93.24161, lat: 41.584485, postalCode: "50228" },
    { lng: -94.84275, lat: 42.844055, postalCode: "50554" },
    { lng: -92.95988, lat: 42.58243, postalCode: "50608" },
    { lng: -91.89585, lat: 43.194235, postalCode: "52132" },
    { lng: -93.36842, lat: 42.77929, postalCode: "50452" },
    { lng: -95.33881, lat: 41.48079, postalCode: "51521" },
    { lng: -92.01595, lat: 42.172481, postalCode: "52349" },
    { lng: -95.52275, lat: 41.650114, postalCode: "51565" },
    { lng: -93.92137, lat: 43.236648, postalCode: "50484" },
    { lng: -91.79794, lat: 42.834286, postalCode: "52142" },
    { lng: -90.47115, lat: 42.250445, postalCode: "52031" },
    { lng: -91.62785, lat: 42.935873, postalCode: "52141" },
    { lng: -93.95414, lat: 40.834902, postalCode: "50108" },
    { lng: -93.49863, lat: 40.854525, postalCode: "50123" },
    { lng: -91.95858, lat: 42.960176, postalCode: "52147" },
    { lng: -92.83592, lat: 40.648624, postalCode: "52555" },
    { lng: -94.73571, lat: 42.266805, postalCode: "51449" },
    { lng: -95.135602, lat: 41.911924, postalCode: "51432" },
    { lng: -95.20261, lat: 42.072884, postalCode: "51465" },
    { lng: -96.15012, lat: 43.192342, postalCode: "51239" },
    { lng: -90.804116, lat: 42.484861, postalCode: "52099" },
    { lng: -94.63294, lat: 42.395906, postalCode: "50579" },
    { lng: -92.62664, lat: 42.568395, postalCode: "50660" },
    { lng: -95.89855, lat: 43.072732, postalCode: "51238" },
    { lng: -95.024969, lat: 40.65747, postalCode: "51651" },
    { lng: -93.65625, lat: 43.259165, postalCode: "50436" },
    { lng: -91.74913, lat: 43.130438, postalCode: "52161" },
    { lng: -92.33825, lat: 42.671132, postalCode: "50622" },
    { lng: -92.36382, lat: 42.473536, postalCode: "50701" },
    { lng: -95.71139, lat: 42.759208, postalCode: "51014" },
    { lng: -95.4187, lat: 42.511431, postalCode: "51020" },
    { lng: -91.38723, lat: 42.927724, postalCode: "52072" },
    { lng: -92.91453, lat: 42.451112, postalCode: "50680" },
    { lng: -91.25436, lat: 43.358764, postalCode: "52151" },
    { lng: -92.41457, lat: 43.26232, postalCode: "50628" },
    { lng: -94.36488, lat: 41.701873, postalCode: "50216" },
    { lng: -94.219334, lat: 40.931394, postalCode: "50831" },
    { lng: -94.76142, lat: 41.002701, postalCode: "50841" },
    { lng: -94.29981, lat: 41.855913, postalCode: "50128" },
    { lng: -93.45615, lat: 40.639737, postalCode: "50052" },
    { lng: -94.31496, lat: 43.392985, postalCode: "50590" },
    { lng: -91.75917, lat: 42.394662, postalCode: "52326" },
    { lng: -92.46899, lat: 41.472488, postalCode: "50027" },
    { lng: -94.63292, lat: 42.040772, postalCode: "51459" },
    { lng: -93.74093, lat: 41.373193, postalCode: "50160" },
    { lng: -96.210839, lat: 42.411155, postalCode: "51107" },
    { lng: -94.64805, lat: 41.507565, postalCode: "50002" },
    { lng: -94.19512, lat: 43.192938, postalCode: "50522" },
    { lng: -92.28859, lat: 43.419137, postalCode: "52155" },
    { lng: -94.88772, lat: 40.732886, postalCode: "51646" },
    { lng: -92.95458, lat: 43.048973, postalCode: "50468" },
    { lng: -91.24664, lat: 41.349983, postalCode: "52754" },
    { lng: -96.18789, lat: 42.497015, postalCode: "51030" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50308" },
    { lng: -92.24541, lat: 40.66442, postalCode: "52584" },
    { lng: -90.77596, lat: 41.498603, postalCode: "52726" },
    { lng: -92.903, lat: 41.277693, postalCode: "50256" },
    { lng: -93.07114, lat: 41.939663, postalCode: "50162" },
    { lng: -91.599215, lat: 42.079415, postalCode: "52344" },
    { lng: -92.79387, lat: 41.87955, postalCode: "50106" },
    { lng: -90.20261, lat: 42.071239, postalCode: "52070" },
    { lng: -90.59176, lat: 42.154059, postalCode: "52030" },
    { lng: -95.00942, lat: 40.615698, postalCode: "51631" },
    { lng: -93.7238, lat: 41.630521, postalCode: "50322" },
    { lng: -95.31854, lat: 43.193363, postalCode: "51338" },
    { lng: -91.33937, lat: 42.424914, postalCode: "52223" },
    { lng: -94.4351, lat: 42.402475, postalCode: "50586" },
    { lng: -94.823088, lat: 41.048733, postalCode: "50839" },
    { lng: -92.90748, lat: 42.238432, postalCode: "50621" },
    { lng: -92.570891, lat: 41.961726, postalCode: "52239" },
    { lng: -94.46472, lat: 42.97944, postalCode: "50597" },
    { lng: -91.26333, lat: 42.491416, postalCode: "52041" },
    { lng: -93.90684, lat: 42.659143, postalCode: "50533" },
    { lng: -95.19538, lat: 41.7196, postalCode: "51447" },
    { lng: -90.606284, lat: 41.613034, postalCode: "52805" },
    { lng: -91.00591, lat: 42.054649, postalCode: "52362" },
    { lng: -93.69541, lat: 41.864393, postalCode: "50243" },
    { lng: -95.67654, lat: 42.462159, postalCode: "51018" },
    { lng: -95.31482, lat: 42.113042, postalCode: "51441" },
    { lng: -93.39078, lat: 41.457014, postalCode: "50118" },
    { lng: -94.22607, lat: 40.700115, postalCode: "50854" },
    { lng: -92.44441, lat: 40.73045, postalCode: "52537" },
    { lng: -95.03595, lat: 40.738639, postalCode: "51632" },
    { lng: -94.37557, lat: 42.899575, postalCode: "50570" },
    { lng: -93.572173, lat: 41.672687, postalCode: "50397" },
    { lng: -91.50834, lat: 41.662083, postalCode: "52245" },
    { lng: -93.375544, lat: 42.417866, postalCode: "50043" },
    { lng: -93.18373, lat: 40.962051, postalCode: "50238" },
    { lng: -91.91229, lat: 42.605576, postalCode: "50641" },
    { lng: -89.59908, lat: 40.643846, postalCode: "61610" },
    { lng: -90.58945, lat: 42.483971, postalCode: "61025" },
    { lng: -89.10978, lat: 38.961799, postalCode: "62471" },
    { lng: -88.10553, lat: 41.50798, postalCode: "60436" },
    { lng: -89.52648, lat: 39.359529, postalCode: "62538" },
    { lng: -88.03349, lat: 39.529566, postalCode: "61912" },
    { lng: -88.6656, lat: 39.194021, postalCode: "62461" },
    { lng: -89.39613, lat: 41.830896, postalCode: "61057" },
    { lng: -90.1649, lat: 39.155608, postalCode: "62030" },
    { lng: -87.7546, lat: 41.715082, postalCode: "60453" },
    { lng: -89.009201, lat: 37.80308, postalCode: "62841" },
    { lng: -89.64999, lat: 39.80095, postalCode: "62701" },
    { lng: -89.54402, lat: 39.580159, postalCode: "62558" },
    { lng: -88.58911, lat: 38.513119, postalCode: "62850" },
    { lng: -88.2783, lat: 41.292011, postalCode: "60416" },
    { lng: -88.11405, lat: 40.448736, postalCode: "60957" },
    { lng: -88.6692, lat: 38.423947, postalCode: "62851" },
    { lng: -89.38486, lat: 41.113829, postalCode: "61537" },
    { lng: -90.56956, lat: 41.4903, postalCode: "61201" },
    { lng: -90.55222, lat: 40.134957, postalCode: "62681" },
    { lng: -89.54537, lat: 38.034281, postalCode: "62238" },
    { lng: -87.891214, lat: 41.968029, postalCode: "60666" },
    { lng: -90.50615, lat: 41.303101, postalCode: "61281" },
    { lng: -89.75177, lat: 39.588902, postalCode: "62615" },
    { lng: -87.64461, lat: 41.882082, postalCode: "60661" },
    { lng: -88.87463, lat: 39.042992, postalCode: "62458" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60094" },
    { lng: -87.81235, lat: 41.871331, postalCode: "60130" },
    { lng: -88.12264, lat: 37.914363, postalCode: "62867" },
    { lng: -90.05078, lat: 38.55615, postalCode: "62223" },
    { lng: -88.21676, lat: 42.194946, postalCode: "60021" },
    { lng: -89.70427, lat: 40.200537, postalCode: "62664" },
    { lng: -90.15854, lat: 41.464473, postalCode: "61254" },
    { lng: -90.52901, lat: 40.781504, postalCode: "61417" },
    { lng: -88.17296, lat: 40.243692, postalCode: "61878" },
    { lng: -89.32028, lat: 40.341123, postalCode: "61721" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60690" },
    { lng: -89.3968, lat: 42.139244, postalCode: "61047" },
    { lng: -89.51229, lat: 39.71984, postalCode: "62563" },
    { lng: -87.72373, lat: 42.077178, postalCode: "60091" },
    { lng: -89.66878, lat: 37.845454, postalCode: "62280" },
    { lng: -88.72247, lat: 40.020978, postalCode: "61830" },
    { lng: -90.43698, lat: 39.564501, postalCode: "62610" },
    { lng: -89.69452, lat: 41.759473, postalCode: "61071" },
    { lng: -87.61401, lat: 40.293159, postalCode: "61811" },
    { lng: -87.92935, lat: 42.428187, postalCode: "60083" },
    { lng: -90.1628, lat: 38.564451, postalCode: "62206" },
    { lng: -88.62237, lat: 40.434885, postalCode: "61722" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60114" },
    { lng: -88.44456, lat: 37.822259, postalCode: "62930" },
    { lng: -90.08306, lat: 38.905998, postalCode: "62018" },
    { lng: -88.16484, lat: 38.361654, postalCode: "62833" },
    { lng: -89.15864, lat: 40.321811, postalCode: "61754" },
    { lng: -88.05606, lat: 41.970628, postalCode: "60157" },
    { lng: -87.812064, lat: 42.116377, postalCode: "60082" },
    { lng: -87.9576, lat: 40.028716, postalCode: "61849" },
    { lng: -87.821359, lat: 41.882081, postalCode: "60330" },
    { lng: -89.37836, lat: 39.861289, postalCode: "62515" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60009" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62763" },
    { lng: -87.73198, lat: 41.730533, postalCode: "60456" },
    { lng: -88.953015, lat: 40.461431, postalCode: "61709" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60095" },
    { lng: -89.39224, lat: 39.66363, postalCode: "62531" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62715" },
    { lng: -88.975507, lat: 38.977111, postalCode: "62247" },
    { lng: -89.98785, lat: 38.214515, postalCode: "62278" },
    { lng: -87.73189, lat: 41.675861, postalCode: "60803" },
    { lng: -87.94105, lat: 40.937573, postalCode: "60927" },
    { lng: -88.54416, lat: 37.844963, postalCode: "62977" },
    { lng: -87.62504, lat: 41.904667, postalCode: "60611" },
    { lng: -90.75393, lat: 41.010666, postalCode: "61453" },
    { lng: -88.43996, lat: 41.631342, postalCode: "60560" },
    { lng: -87.95798, lat: 41.779533, postalCode: "60514" },
    { lng: -90.32941, lat: 40.234249, postalCode: "61501" },
    { lng: -88.02508, lat: 42.07506, postalCode: "60008" },
    { lng: -88.40749, lat: 41.370642, postalCode: "60450" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60125" },
    { lng: -87.66766, lat: 40.465869, postalCode: "60942" },
    { lng: -89.26975, lat: 41.363772, postalCode: "61359" },
    { lng: -87.99847, lat: 42.005978, postalCode: "60007" },
    { lng: -87.6664, lat: 39.229885, postalCode: "62477" },
    { lng: -90.19253, lat: 38.526792, postalCode: "62239" },
    { lng: -90.60351, lat: 40.324365, postalCode: "61440" },
    { lng: -88.08658, lat: 38.72456, postalCode: "62450" },
    { lng: -88.35254, lat: 41.766029, postalCode: "60506" },
    { lng: -89.03829, lat: 39.530502, postalCode: "62510" },
    { lng: -90.06922, lat: 41.738551, postalCode: "61251" },
    { lng: -88.196102, lat: 40.139946, postalCode: "61824" },
    { lng: -88.09934, lat: 41.64273, postalCode: "60446" },
    { lng: -88.25053, lat: 40.562833, postalCode: "60952" },
    { lng: -88.72052, lat: 40.450325, postalCode: "61737" },
    { lng: -89.40987, lat: 38.889355, postalCode: "62246" },
    { lng: -89.03707, lat: 38.469244, postalCode: "62893" },
    { lng: -87.62898, lat: 40.133786, postalCode: "61832" },
    { lng: -88.96715, lat: 40.027071, postalCode: "61756" },
    { lng: -89.64068, lat: 39.442669, postalCode: "62533" },
    { lng: -87.83018, lat: 42.48067, postalCode: "60096" },
    { lng: -89.51773, lat: 38.271313, postalCode: "62268" },
    { lng: -89.97461, lat: 38.976011, postalCode: "62021" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62796" },
    { lng: -89.24688, lat: 38.011729, postalCode: "62832" },
    { lng: -89.603081, lat: 40.731165, postalCode: "61633" },
    { lng: -88.01172, lat: 42.263623, postalCode: "60060" },
    { lng: -91.21286, lat: 40.028595, postalCode: "62359" },
    { lng: -89.99604, lat: 38.399148, postalCode: "62285" },
    { lng: -88.981511, lat: 39.853981, postalCode: "62524" },
    { lng: -87.7105, lat: 41.809299, postalCode: "60632" },
    { lng: -90.39307, lat: 39.770936, postalCode: "62628" },
    { lng: -87.6699, lat: 41.850532, postalCode: "60608" },
    { lng: -88.85739, lat: 40.882858, postalCode: "61740" },
    { lng: -89.42243, lat: 37.223589, postalCode: "62990" },
    { lng: -89.68326, lat: 39.982768, postalCode: "62613" },
    { lng: -89.609951, lat: 40.554538, postalCode: "61558" },
    { lng: -89.05385, lat: 37.8974, postalCode: "62999" },
    { lng: -89.563646, lat: 40.709837, postalCode: "61639" },
    { lng: -89.5905, lat: 37.983687, postalCode: "62997" },
    { lng: -88.47128, lat: 37.954727, postalCode: "62817" },
    { lng: -88.49744, lat: 40.10311, postalCode: "61884" },
    { lng: -88.29167, lat: 41.473553, postalCode: "60447" },
    { lng: -91.25561, lat: 39.813094, postalCode: "62360" },
    { lng: -87.57092, lat: 39.452478, postalCode: "62423" },
    { lng: -89.16532, lat: 37.08751, postalCode: "62963" },
    { lng: -90.35597, lat: 41.518317, postalCode: "61236" },
    { lng: -89.67869, lat: 38.478, postalCode: "62266" },
    { lng: -89.76133, lat: 38.967324, postalCode: "62058" },
    { lng: -87.87778, lat: 41.737616, postalCode: "60480" },
    { lng: -89.983521, lat: 38.439983, postalCode: "62224" },
    { lng: -87.79917, lat: 41.331114, postalCode: "60468" },
    { lng: -88.43939, lat: 39.950391, postalCode: "61851" },
    { lng: -87.7048, lat: 41.945681, postalCode: "60618" },
    { lng: -87.68767, lat: 41.472934, postalCode: "60466" },
    { lng: -89.04697, lat: 38.085829, postalCode: "62884" },
    { lng: -89.73627, lat: 38.260108, postalCode: "62257" },
    { lng: -89.98255, lat: 42.1009, postalCode: "61053" },
    { lng: -89.48442, lat: 39.146809, postalCode: "62049" },
    { lng: -90.22322, lat: 41.766003, postalCode: "61230" },
    { lng: -89.47652, lat: 42.298218, postalCode: "61067" },
    { lng: -89.60972, lat: 40.699137, postalCode: "61606" },
    { lng: -88.8498, lat: 40.395487, postalCode: "61736" },
    { lng: -89.02456, lat: 42.054546, postalCode: "61049" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61656" },
    { lng: -88.64744, lat: 38.661358, postalCode: "62899" },
    { lng: -89.11128, lat: 37.884066, postalCode: "62983" },
    { lng: -89.87508, lat: 41.97068, postalCode: "61014" },
    { lng: -90.63108, lat: 39.469982, postalCode: "62361" },
    { lng: -90.36117, lat: 41.28595, postalCode: "61262" },
    { lng: -90.615975, lat: 41.554901, postalCode: "61204" },
    { lng: -89.67662, lat: 41.310471, postalCode: "61314" },
    { lng: -88.6906, lat: 42.10028, postalCode: "60135" },
    { lng: -89.75117, lat: 38.596948, postalCode: "62289" },
    { lng: -90.159059, lat: 38.6163, postalCode: "62202" },
    { lng: -89.78855, lat: 39.011612, postalCode: "62088" },
    { lng: -88.08256, lat: 41.94954, postalCode: "60108" },
    { lng: -90.8842, lat: 39.983573, postalCode: "62375" },
    { lng: -87.68154, lat: 41.656592, postalCode: "60406" },
    { lng: -87.86556, lat: 42.169325, postalCode: "60015" },
    { lng: -88.98068, lat: 39.693983, postalCode: "62544" },
    { lng: -89.646555, lat: 39.778044, postalCode: "62718" },
    { lng: -88.59915, lat: 41.908736, postalCode: "60182" },
    { lng: -87.61269, lat: 41.819833, postalCode: "60653" },
    { lng: -88.97892, lat: 37.921795, postalCode: "62874" },
    { lng: -87.73695, lat: 41.503468, postalCode: "60443" },
    { lng: -90.60587, lat: 41.260255, postalCode: "61263" },
    { lng: -90.14654, lat: 39.188838, postalCode: "62063" },
    { lng: -88.452481, lat: 42.324761, postalCode: "60001" },
    { lng: -89.60864, lat: 40.677987, postalCode: "61602" },
    { lng: -89.9572, lat: 38.726548, postalCode: "62062" },
    { lng: -89.1257, lat: 38.523736, postalCode: "62801" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62756" },
    { lng: -89.65721, lat: 39.179875, postalCode: "62056" },
    { lng: -88.3028, lat: 42.464639, postalCode: "60071" },
    { lng: -88.95588, lat: 39.385344, postalCode: "62571" },
    { lng: -88.27977, lat: 39.907919, postalCode: "61863" },
    { lng: -89.8288, lat: 39.127717, postalCode: "62033" },
    { lng: -89.39095, lat: 39.465887, postalCode: "62556" },
    { lng: -89.63404, lat: 42.475891, postalCode: "61060" },
    { lng: -89.66363, lat: 39.757896, postalCode: "62707" },
    { lng: -88.17657, lat: 42.023977, postalCode: "60107" },
    { lng: -88.041875, lat: 42.198674, postalCode: "60049" },
    { lng: -89.01704, lat: 41.830628, postalCode: "60553" },
    { lng: -88.45037, lat: 39.32205, postalCode: "62447" },
    { lng: -88.864303, lat: 40.519236, postalCode: "61791" },
    { lng: -89.52972, lat: 39.85973, postalCode: "62561" },
    { lng: -88.59921, lat: 41.56253, postalCode: "60537" },
    { lng: -89.25682, lat: 41.037822, postalCode: "61375" },
    { lng: -89.76033, lat: 41.068691, postalCode: "61491" },
    { lng: -90.36618, lat: 39.817322, postalCode: "62631" },
    { lng: -87.95117, lat: 41.956479, postalCode: "60106" },
    { lng: -90.76454, lat: 40.777241, postalCode: "61478" },
    { lng: -90.17174, lat: 40.642927, postalCode: "61432" },
    { lng: -88.16188, lat: 41.794885, postalCode: "60563" },
    { lng: -87.97813, lat: 41.880429, postalCode: "60181" },
    { lng: -88.89634, lat: 38.149474, postalCode: "62846" },
    { lng: -89.08485, lat: 42.447856, postalCode: "61072" },
    { lng: -87.6124, lat: 40.175463, postalCode: "61834" },
    { lng: -87.75764, lat: 42.033313, postalCode: "60077" },
    { lng: -88.74145, lat: 37.548762, postalCode: "62972" },
    { lng: -89.254203, lat: 37.340918, postalCode: "62961" },
    { lng: -88.120182, lat: 37.97622, postalCode: "62834" },
    { lng: -87.716, lat: 42.088128, postalCode: "60043" },
    { lng: -88.42487, lat: 40.107548, postalCode: "61875" },
    { lng: -88.67912, lat: 37.635792, postalCode: "62987" },
    { lng: -88.7923, lat: 40.63872, postalCode: "61753" },
    { lng: -90.87565, lat: 41.228561, postalCode: "61260" },
    { lng: -90.57994, lat: 41.067549, postalCode: "61412" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60697" },
    { lng: -89.13269, lat: 39.716269, postalCode: "62513" },
    { lng: -87.84753, lat: 41.822681, postalCode: "60513" },
    { lng: -88.08957, lat: 41.05113, postalCode: "60941" },
    { lng: -88.08536, lat: 41.789079, postalCode: "60532" },
    { lng: -88.8255, lat: 39.167788, postalCode: "62414" },
    { lng: -88.22307, lat: 41.262178, postalCode: "60408" },
    { lng: -89.92848, lat: 41.247536, postalCode: "61443" },
    { lng: -89.81607, lat: 42.475078, postalCode: "61089" },
    { lng: -87.71188, lat: 41.7446, postalCode: "60652" },
    { lng: -91.16278, lat: 40.034398, postalCode: "62325" },
    { lng: -87.59259, lat: 40.613942, postalCode: "60967" },
    { lng: -88.2733, lat: 40.108631, postalCode: "61821" },
    { lng: -89.47449, lat: 42.213851, postalCode: "61039" },
    { lng: -88.97932, lat: 40.963663, postalCode: "61321" },
    { lng: -87.92958, lat: 42.131526, postalCode: "60090" },
    { lng: -87.86784, lat: 41.114132, postalCode: "60901" },
    { lng: -90.13459, lat: 38.153322, postalCode: "62279" },
    { lng: -88.6021, lat: 39.582494, postalCode: "61951" },
    { lng: -90.75759, lat: 40.867285, postalCode: "61447" },
    { lng: -87.85284, lat: 42.312372, postalCode: "60088" },
    { lng: -87.80112, lat: 40.465251, postalCode: "60932" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60691" },
    { lng: -91.12851, lat: 40.513153, postalCode: "62318" },
    { lng: -88.52981, lat: 41.00148, postalCode: "60460" },
    { lng: -89.43131, lat: 41.198061, postalCode: "61560" },
    { lng: -88.04864, lat: 41.75253, postalCode: "60517" },
    { lng: -88.90842, lat: 38.322558, postalCode: "62864" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62726" },
    { lng: -88.30526, lat: 40.310244, postalCode: "61840" },
    { lng: -88.18012, lat: 40.779834, postalCode: "60959" },
    { lng: -87.63138, lat: 39.97412, postalCode: "61846" },
    { lng: -88.02753, lat: 41.598235, postalCode: "60441" },
    { lng: -90.64691, lat: 40.579737, postalCode: "61438" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60105" },
    { lng: -90.65724, lat: 40.70595, postalCode: "61473" },
    { lng: -88.260692, lat: 42.35344, postalCode: "60054" },
    { lng: -90.17486, lat: 41.286797, postalCode: "61238" },
    { lng: -87.86187, lat: 42.361271, postalCode: "60085" },
    { lng: -90.735, lat: 41.406276, postalCode: "61284" },
    { lng: -89.87721, lat: 40.214759, postalCode: "62633" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60199" },
    { lng: -89.90929, lat: 40.340202, postalCode: "61567" },
    { lng: -88.97711, lat: 40.627948, postalCode: "61748" },
    { lng: -88.30797, lat: 41.024846, postalCode: "60920" },
    { lng: -89.12523, lat: 41.261853, postalCode: "61316" },
    { lng: -90.81819, lat: 40.18275, postalCode: "62344" },
    { lng: -89.7401, lat: 40.776845, postalCode: "61528" },
    { lng: -90.08023, lat: 38.868136, postalCode: "62024" },
    { lng: -89.84131, lat: 40.816162, postalCode: "61517" },
    { lng: -87.86239, lat: 41.144256, postalCode: "60915" },
    { lng: -87.83542, lat: 41.623532, postalCode: "60462" },
    { lng: -88.23737, lat: 40.987824, postalCode: "60919" },
    { lng: -90.53937, lat: 39.740558, postalCode: "62621" },
    { lng: -88.9325, lat: 42.376423, postalCode: "61011" },
    { lng: -90.34541, lat: 39.192501, postalCode: "62054" },
    { lng: -87.54819, lat: 41.565685, postalCode: "60438" },
    { lng: -87.84208, lat: 40.11594, postalCode: "61857" },
    { lng: -88.76394, lat: 37.582136, postalCode: "62967" },
    { lng: -90.97066, lat: 40.567399, postalCode: "61450" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60006" },
    { lng: -89.17123, lat: 37.272477, postalCode: "62992" },
    { lng: -89.18633, lat: 42.112207, postalCode: "61084" },
    { lng: -87.83875, lat: 41.501178, postalCode: "60423" },
    { lng: -88.86601, lat: 37.540479, postalCode: "62991" },
    { lng: -89.30394, lat: 42.436585, postalCode: "61024" },
    { lng: -88.66404, lat: 38.913064, postalCode: "62426" },
    { lng: -88.83666, lat: 41.129482, postalCode: "61364" },
    { lng: -89.22926, lat: 38.127516, postalCode: "62888" },
    { lng: -88.898663, lat: 40.564323, postalCode: "61766" },
    { lng: -89.57904, lat: 40.712887, postalCode: "61603" },
    { lng: -87.87017, lat: 39.797661, postalCode: "61932" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62713" },
    { lng: -89.18832, lat: 40.05232, postalCode: "62518" },
    { lng: -89.983521, lat: 38.439983, postalCode: "62222" },
    { lng: -89.84183, lat: 38.178395, postalCode: "62217" },
    { lng: -88.10761, lat: 40.6756, postalCode: "60968" },
    { lng: -89.21607, lat: 39.476356, postalCode: "62555" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61079" },
    { lng: -88.77464, lat: 38.594744, postalCode: "62849" },
    { lng: -87.97688, lat: 41.962979, postalCode: "60191" },
    { lng: -89.59541, lat: 39.312686, postalCode: "62560" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60680" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62709" },
    { lng: -89.67478, lat: 38.752017, postalCode: "62249" },
    { lng: -89.37787, lat: 39.081889, postalCode: "62017" },
    { lng: -90.38482, lat: 41.357236, postalCode: "61273" },
    { lng: -88.82227, lat: 39.719012, postalCode: "61925" },
    { lng: -89.84608, lat: 38.932347, postalCode: "62097" },
    { lng: -88.16099, lat: 37.495812, postalCode: "62919" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61130" },
    { lng: -88.84755, lat: 42.257229, postalCode: "61008" },
    { lng: -90.02794, lat: 40.552853, postalCode: "61520" },
    { lng: -89.96382, lat: 40.104623, postalCode: "62623" },
    { lng: -88.24769, lat: 42.348406, postalCode: "60050" },
    { lng: -88.63052, lat: 41.645393, postalCode: "60548" },
    { lng: -88.74998, lat: 41.490329, postalCode: "60549" },
    { lng: -90.30458, lat: 40.337555, postalCode: "61441" },
    { lng: -88.361251, lat: 40.113139, postalCode: "61826" },
    { lng: -88.10904, lat: 41.858279, postalCode: "60187" },
    { lng: -89.24331, lat: 39.771541, postalCode: "62547" },
    { lng: -88.939637, lat: 40.488468, postalCode: "61799" },
    { lng: -87.75589, lat: 40.912916, postalCode: "60951" },
    { lng: -90.02866, lat: 40.796752, postalCode: "61572" },
    { lng: -89.40925, lat: 42.440374, postalCode: "61019" },
    { lng: -88.03692, lat: 38.588301, postalCode: "62452" },
    { lng: -88.58186, lat: 37.140282, postalCode: "62910" },
    { lng: -89.06707, lat: 39.940934, postalCode: "62573" },
    { lng: -88.3332, lat: 41.823478, postalCode: "60539" },
    { lng: -89.12184, lat: 37.782057, postalCode: "62915" },
    { lng: -88.98238, lat: 41.190024, postalCode: "61332" },
    { lng: -90.25362, lat: 42.473737, postalCode: "61075" },
    { lng: -88.05698, lat: 41.511644, postalCode: "60433" },
    { lng: -87.69912, lat: 41.696283, postalCode: "60655" },
    { lng: -87.83052, lat: 40.039632, postalCode: "61841" },
    { lng: -88.14579, lat: 41.764779, postalCode: "60540" },
    { lng: -88.22944, lat: 38.247883, postalCode: "62820" },
    { lng: -90.39766, lat: 40.802633, postalCode: "61410" },
    { lng: -88.32393, lat: 42.034776, postalCode: "60123" },
    { lng: -90.08422, lat: 41.025484, postalCode: "61485" },
    { lng: -90.14609, lat: 38.62855, postalCode: "62201" },
    { lng: -88.32931, lat: 41.809387, postalCode: "60542" },
    { lng: -89.08118, lat: 37.804876, postalCode: "62921" },
    { lng: -88.96939, lat: 40.459584, postalCode: "61704" },
    { lng: -88.64655, lat: 41.776884, postalCode: "60520" },
    { lng: -91.302633, lat: 39.96008, postalCode: "62305" },
    { lng: -89.627948, lat: 40.742537, postalCode: "61613" },
    { lng: -88.142657, lat: 38.073931, postalCode: "62845" },
    { lng: -88.48012, lat: 38.806292, postalCode: "62858" },
    { lng: -88.52659, lat: 41.231718, postalCode: "60479" },
    { lng: -89.68346, lat: 38.214035, postalCode: "62292" },
    { lng: -88.06412, lat: 41.147958, postalCode: "60913" },
    { lng: -88.74854, lat: 41.011259, postalCode: "61319" },
    { lng: -89.02756, lat: 42.484835, postalCode: "61080" },
    { lng: -89.44719, lat: 39.430427, postalCode: "62546" },
    { lng: -88.30274, lat: 42.405464, postalCode: "60072" },
    { lng: -89.25489, lat: 37.540571, postalCode: "62920" },
    { lng: -89.82842, lat: 37.921816, postalCode: "62233" },
    { lng: -89.76802, lat: 40.935608, postalCode: "61559" },
    { lng: -87.78899, lat: 41.893941, postalCode: "60302" },
    { lng: -88.75932, lat: 39.272317, postalCode: "62444" },
    { lng: -87.66464, lat: 41.556734, postalCode: "60430" },
    { lng: -87.85125, lat: 41.250408, postalCode: "60950" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62719" },
    { lng: -90.62842, lat: 39.235652, postalCode: "62065" },
    { lng: -90.02362, lat: 42.350108, postalCode: "61085" },
    { lng: -88.954174, lat: 40.477735, postalCode: "61710" },
    { lng: -90.44563, lat: 38.985138, postalCode: "62037" },
    { lng: -88.15174, lat: 39.989282, postalCode: "61864" },
    { lng: -88.83922, lat: 37.613705, postalCode: "62922" },
    { lng: -89.05595, lat: 42.213439, postalCode: "61109" },
    { lng: -87.95169, lat: 42.290922, postalCode: "60048" },
    { lng: -90.22212, lat: 40.859964, postalCode: "61436" },
    { lng: -89.37069, lat: 41.289819, postalCode: "61315" },
    { lng: -89.66723, lat: 38.002188, postalCode: "62288" },
    { lng: -89.33485, lat: 37.901441, postalCode: "62994" },
    { lng: -88.62883, lat: 38.95232, postalCode: "62443" },
    { lng: -87.68963, lat: 41.045785, postalCode: "60964" },
    { lng: -89.06906, lat: 37.963264, postalCode: "62865" },
    { lng: -89.13627, lat: 40.600045, postalCode: "61725" },
    { lng: -89.47618, lat: 37.727109, postalCode: "62940" },
    { lng: -88.08704, lat: 41.980761, postalCode: "60172" },
    { lng: -89.21239, lat: 41.380259, postalCode: "61329" },
    { lng: -88.70948, lat: 40.740106, postalCode: "61726" },
    { lng: -90.26153, lat: 39.698311, postalCode: "62651" },
    { lng: -88.42783, lat: 41.089453, postalCode: "60420" },
    { lng: -88.13528, lat: 39.25287, postalCode: "62428" },
    { lng: -90.54835, lat: 40.972589, postalCode: "61435" },
    { lng: -88.62727, lat: 40.56944, postalCode: "61728" },
    { lng: -90.16063, lat: 41.117342, postalCode: "61414" },
    { lng: -87.73629, lat: 39.92804, postalCode: "61850" },
    { lng: -88.144475, lat: 41.980896, postalCode: "60133" },
    { lng: -89.83062, lat: 42.381064, postalCode: "61048" },
    { lng: -90.11431, lat: 38.720938, postalCode: "62040" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62662" },
    { lng: -87.67563, lat: 39.113033, postalCode: "62433" },
    { lng: -88.53552, lat: 41.529433, postalCode: "60541" },
    { lng: -87.62874, lat: 41.882937, postalCode: "60602" },
    { lng: -88.10828, lat: 42.067809, postalCode: "60195" },
    { lng: -89.1297, lat: 42.25517, postalCode: "61102" },
    { lng: -87.68424, lat: 41.539384, postalCode: "60422" },
    { lng: -90.1938, lat: 40.776079, postalCode: "61458" },
    { lng: -89.3617, lat: 37.618822, postalCode: "62975" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62777" },
    { lng: -89.94561, lat: 42.455036, postalCode: "61059" },
    { lng: -91.26301, lat: 40.587769, postalCode: "62358" },
    { lng: -90.6681, lat: 40.456986, postalCode: "61455" },
    { lng: -88.92096, lat: 38.084617, postalCode: "62897" },
    { lng: -89.186317, lat: 37.277483, postalCode: "62792" },
    { lng: -88.15575, lat: 40.307065, postalCode: "61866" },
    { lng: -88.981511, lat: 39.853981, postalCode: "62525" },
    { lng: -90.36871, lat: 40.948073, postalCode: "61401" },
    { lng: -90.5979, lat: 41.30589, postalCode: "61276" },
    { lng: -89.8757, lat: 39.280289, postalCode: "62626" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60599" },
    { lng: -88.88278, lat: 40.990965, postalCode: "61333" },
    { lng: -87.70376, lat: 40.059068, postalCode: "61817" },
    { lng: -89.20884, lat: 38.22669, postalCode: "62831" },
    { lng: -89.10696, lat: 41.543149, postalCode: "61342" },
    { lng: -89.786723, lat: 40.031115, postalCode: "62659" },
    { lng: -88.223655, lat: 42.079336, postalCode: "60179" },
    { lng: -89.71842, lat: 41.513879, postalCode: "61346" },
    { lng: -89.10462, lat: 39.279357, postalCode: "62553" },
    { lng: -88.19452, lat: 41.82552, postalCode: "60555" },
    { lng: -88.7909, lat: 38.281485, postalCode: "62872" },
    { lng: -89.74886, lat: 38.884434, postalCode: "62001" },
    { lng: -87.5557, lat: 41.719973, postalCode: "60617" },
    { lng: -90.214412, lat: 40.932207, postalCode: "61402" },
    { lng: -89.569207, lat: 40.765301, postalCode: "61632" },
    { lng: -89.73555, lat: 42.15834, postalCode: "61078" },
    { lng: -89.651987, lat: 40.640002, postalCode: "61641" },
    { lng: -88.19454, lat: 38.365545, postalCode: "62843" },
    { lng: -87.65984, lat: 38.738226, postalCode: "62439" },
    { lng: -88.420178, lat: 41.936977, postalCode: "60147" },
    { lng: -87.69589, lat: 39.396463, postalCode: "62441" },
    { lng: -88.343237, lat: 39.809913, postalCode: "61593" },
    { lng: -87.65282, lat: 41.814283, postalCode: "60609" },
    { lng: -89.05291, lat: 37.973175, postalCode: "62822" },
    { lng: -88.69466, lat: 37.458878, postalCode: "62985" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62794" },
    { lng: -89.25106, lat: 41.265597, postalCode: "61340" },
    { lng: -89.48336, lat: 41.036199, postalCode: "61565" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60663" },
    { lng: -87.92289, lat: 39.556054, postalCode: "61933" },
    { lng: -89.415831, lat: 37.758646, postalCode: "62902" },
    { lng: -90.135645, lat: 38.30426, postalCode: "62256" },
    { lng: -89.1632, lat: 37.152714, postalCode: "62996" },
    { lng: -89.34153, lat: 37.159227, postalCode: "62969" },
    { lng: -89.592597, lat: 40.689637, postalCode: "61634" },
    { lng: -89.6807, lat: 38.520285, postalCode: "62265" },
    { lng: -89.65687, lat: 40.40943, postalCode: "61534" },
    { lng: -87.79562, lat: 41.887981, postalCode: "60301" },
    { lng: -89.34371, lat: 41.707588, postalCode: "61310" },
    { lng: -87.84158, lat: 42.01183, postalCode: "60068" },
    { lng: -88.17257, lat: 41.977477, postalCode: "60103" },
    { lng: -88.73257, lat: 38.041978, postalCode: "62860" },
    { lng: -87.60444, lat: 41.744834, postalCode: "60619" },
    { lng: -87.75016, lat: 42.104127, postalCode: "60093" },
    { lng: -89.054, lat: 38.859515, postalCode: "62885" },
    { lng: -88.07693, lat: 40.004053, postalCode: "61877" },
    { lng: -87.86922, lat: 39.32463, postalCode: "62442" },
    { lng: -89.92236, lat: 41.412944, postalCode: "61234" },
    { lng: -88.95361, lat: 38.623789, postalCode: "62881" },
    { lng: -87.84862, lat: 42.451371, postalCode: "60099" },
    { lng: -89.50654, lat: 40.914377, postalCode: "61523" },
    { lng: -89.94259, lat: 40.953365, postalCode: "61451" },
    { lng: -90.03125, lat: 39.891861, postalCode: "62612" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61105" },
    { lng: -87.610053, lat: 42.322814, postalCode: "60011" },
    { lng: -88.95184, lat: 37.239385, postalCode: "62941" },
    { lng: -90.84253, lat: 39.696523, postalCode: "62357" },
    { lng: -88.41296, lat: 41.706034, postalCode: "60512" },
    { lng: -89.611146, lat: 40.796887, postalCode: "61638" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60499" },
    { lng: -87.96041, lat: 41.837716, postalCode: "60523" },
    { lng: -87.839627, lat: 38.98981, postalCode: "62464" },
    { lng: -90.29556, lat: 40.617275, postalCode: "61431" },
    { lng: -90.24422, lat: 40.688615, postalCode: "61544" },
    { lng: -88.16553, lat: 37.712847, postalCode: "62984" },
    { lng: -88.75367, lat: 40.119483, postalCode: "61882" },
    { lng: -89.804293, lat: 41.902977, postalCode: "61017" },
    { lng: -90.09533, lat: 38.833898, postalCode: "62048" },
    { lng: -90.56334, lat: 40.496817, postalCode: "61416" },
    { lng: -88.428, lat: 39.01077, postalCode: "62424" },
    { lng: -88.22167, lat: 42.441869, postalCode: "60081" },
    { lng: -89.28004, lat: 40.628105, postalCode: "61742" },
    { lng: -88.7821, lat: 39.529092, postalCode: "62534" },
    { lng: -87.64569, lat: 40.096186, postalCode: "61833" },
    { lng: -88.7591, lat: 38.857185, postalCode: "62838" },
    { lng: -87.785286, lat: 41.335445, postalCode: "60648" },
    { lng: -89.97361, lat: 41.098764, postalCode: "61449" },
    { lng: -89.46031, lat: 40.613788, postalCode: "61550" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62723" },
    { lng: -87.69618, lat: 40.624025, postalCode: "60953" },
    { lng: -89.96998, lat: 40.095009, postalCode: "62673" },
    { lng: -90.08365, lat: 39.348487, postalCode: "62649" },
    { lng: -88.45609, lat: 39.125573, postalCode: "62467" },
    { lng: -88.04917, lat: 42.10979, postalCode: "60067" },
    { lng: -87.68828, lat: 42.030327, postalCode: "60202" },
    { lng: -89.23432, lat: 41.435197, postalCode: "61312" },
    { lng: -89.47436, lat: 40.291512, postalCode: "62635" },
    { lng: -87.88582, lat: 41.536782, postalCode: "60448" },
    { lng: -87.92717, lat: 42.188074, postalCode: "60069" },
    { lng: -90.24769, lat: 39.576392, postalCode: "62668" },
    { lng: -88.3404, lat: 39.183055, postalCode: "62445" },
    { lng: -91.1106, lat: 39.876235, postalCode: "62347" },
    { lng: -88.0252, lat: 38.93148, postalCode: "62459" },
    { lng: -89.43404, lat: 38.605187, postalCode: "62219" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60681" },
    { lng: -90.09885, lat: 38.095119, postalCode: "62277" },
    { lng: -88.084208, lat: 41.52543, postalCode: "60434" },
    { lng: -89.56053, lat: 40.186852, postalCode: "62671" },
    { lng: -90.58714, lat: 41.466752, postalCode: "61202" },
    { lng: -87.62722, lat: 39.90241, postalCode: "61870" },
    { lng: -87.64464, lat: 41.244016, postalCode: "60940" },
    { lng: -88.69, lat: 41.645998, postalCode: "60552" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61628" },
    { lng: -87.6187, lat: 41.860019, postalCode: "60605" },
    { lng: -90.82654, lat: 40.734056, postalCode: "61460" },
    { lng: -88.9516, lat: 41.792378, postalCode: "60530" },
    { lng: -91.168221, lat: 40.571353, postalCode: "62329" },
    { lng: -87.81014, lat: 39.805101, postalCode: "61940" },
    { lng: -89.72066, lat: 40.087711, postalCode: "62642" },
    { lng: -87.70378, lat: 41.991381, postalCode: "60659" },
    { lng: -87.96883, lat: 41.407474, postalCode: "60442" },
    { lng: -89.36746, lat: 40.149927, postalCode: "62656" },
    { lng: -87.83769, lat: 39.138761, postalCode: "62413" },
    { lng: -88.00464, lat: 38.994595, postalCode: "62480" },
    { lng: -89.44078, lat: 41.543342, postalCode: "61349" },
    { lng: -87.6101, lat: 41.511307, postalCode: "60411" },
    { lng: -89.44123, lat: 40.247835, postalCode: "62643" },
    { lng: -91.25029, lat: 40.396983, postalCode: "62334" },
    { lng: -88.967551, lat: 38.16542, postalCode: "62866" },
    { lng: -89.00839, lat: 42.306666, postalCode: "61114" },
    { lng: -88.25922, lat: 42.037176, postalCode: "60120" },
    { lng: -90.6159, lat: 39.128014, postalCode: "62047" },
    { lng: -87.60215, lat: 41.801525, postalCode: "60615" },
    { lng: -87.69274, lat: 41.51829, postalCode: "60461" },
    { lng: -90.3405, lat: 38.997296, postalCode: "62022" },
    { lng: -90.74246, lat: 40.147818, postalCode: "62319" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60667" },
    { lng: -89.60258, lat: 38.517125, postalCode: "62215" },
    { lng: -88.28284, lat: 37.511139, postalCode: "62931" },
    { lng: -87.63538, lat: 41.888627, postalCode: "60654" },
    { lng: -87.8163, lat: 41.97508, postalCode: "60656" },
    { lng: -89.08355, lat: 37.766029, postalCode: "62918" },
    { lng: -90.54252, lat: 39.103735, postalCode: "62031" },
    { lng: -88.86332, lat: 39.93706, postalCode: "62554" },
    { lng: -89.04186, lat: 42.352539, postalCode: "61115" },
    { lng: -87.72312, lat: 39.175832, postalCode: "62478" },
    { lng: -87.70192, lat: 40.306282, postalCode: "61848" },
    { lng: -89.62, lat: 40.936244, postalCode: "61526" },
    { lng: -90.93608, lat: 40.957929, postalCode: "61469" },
    { lng: -90.29334, lat: 41.292977, postalCode: "61233" },
    { lng: -87.83865, lat: 41.858028, postalCode: "60141" },
    { lng: -89.15936, lat: 39.965691, postalCode: "62543" },
    { lng: -88.99803, lat: 40.51032, postalCode: "61790" },
    { lng: -87.71791, lat: 42.096428, postalCode: "60693" },
    { lng: -89.11908, lat: 38.164136, postalCode: "62883" },
    { lng: -90.05501, lat: 40.489747, postalCode: "61563" },
    { lng: -89.2107, lat: 40.426186, postalCode: "61774" },
    { lng: -89.44025, lat: 42.046139, postalCode: "61054" },
    { lng: -88.26994, lat: 40.465744, postalCode: "60933" },
    { lng: -88.24268, lat: 41.778379, postalCode: "60519" },
    { lng: -87.81825, lat: 40.572516, postalCode: "60926" },
    { lng: -89.94305, lat: 38.733748, postalCode: "62026" },
    { lng: -88.15231, lat: 42.160791, postalCode: "60010" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60566" },
    { lng: -88.593764, lat: 39.148234, postalCode: "62407" },
    { lng: -87.55365, lat: 41.655423, postalCode: "60633" },
    { lng: -87.91691, lat: 39.706131, postalCode: "61917" },
    { lng: -88.11524, lat: 40.375602, postalCode: "60949" },
    { lng: -90.15411, lat: 38.6807, postalCode: "62060" },
    { lng: -90.99193, lat: 39.510835, postalCode: "62370" },
    { lng: -88.24322, lat: 40.114931, postalCode: "61820" },
    { lng: -88.87171, lat: 39.777116, postalCode: "62549" },
    { lng: -87.99744, lat: 39.802798, postalCode: "61942" },
    { lng: -87.7599, lat: 41.844382, postalCode: "60804" },
    { lng: -88.95928, lat: 39.925806, postalCode: "62535" },
    { lng: -90.50153, lat: 40.401282, postalCode: "61411" },
    { lng: -90.08116, lat: 39.609647, postalCode: "62638" },
    { lng: -87.7738, lat: 41.787982, postalCode: "60638" },
    { lng: -88.92968, lat: 37.724504, postalCode: "62959" },
    { lng: -89.79161, lat: 38.303147, postalCode: "62255" },
    { lng: -88.56, lat: 39.123807, postalCode: "62401" },
    { lng: -88.31849, lat: 42.17315, postalCode: "60102" },
    { lng: -88.74726, lat: 42.401104, postalCode: "61012" },
    { lng: -88.45985, lat: 39.704391, postalCode: "61911" },
    { lng: -89.03347, lat: 42.280019, postalCode: "61107" },
    { lng: -89.56986, lat: 41.69339, postalCode: "61042" },
    { lng: -90.57639, lat: 41.426583, postalCode: "61264" },
    { lng: -90.27637, lat: 41.183299, postalCode: "61490" },
    { lng: -87.59865, lat: 41.626839, postalCode: "60419" },
    { lng: -88.24393, lat: 37.796784, postalCode: "62979" },
    { lng: -88.53537, lat: 38.374398, postalCode: "62886" },
    { lng: -90.541585, lat: 41.520333, postalCode: "61299" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62764" },
    { lng: -89.55998, lat: 38.377193, postalCode: "62214" },
    { lng: -89.12574, lat: 42.292221, postalCode: "61101" },
    { lng: -89.89138, lat: 40.691022, postalCode: "61569" },
    { lng: -88.91864, lat: 38.735571, postalCode: "62807" },
    { lng: -90.343228, lat: 41.585883, postalCode: "61278" },
    { lng: -88.07823, lat: 39.801562, postalCode: "61941" },
    { lng: -87.66502, lat: 38.869453, postalCode: "62427" },
    { lng: -90.22941, lat: 39.730234, postalCode: "62650" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60065" },
    { lng: -90.77809, lat: 39.440654, postalCode: "62355" },
    { lng: -89.5973, lat: 38.780229, postalCode: "62273" },
    { lng: -87.99624, lat: 40.706634, postalCode: "60955" },
    { lng: -87.83557, lat: 41.746432, postalCode: "60458" },
    { lng: -89.43066, lat: 37.305331, postalCode: "62957" },
    { lng: -88.78895, lat: 41.620636, postalCode: "60531" },
    { lng: -88.3121, lat: 38.219997, postalCode: "62862" },
    { lng: -87.61289, lat: 41.544584, postalCode: "60425" },
    { lng: -89.99885, lat: 40.392048, postalCode: "61543" },
    { lng: -89.961825, lat: 38.299507, postalCode: "62248" },
    { lng: -89.00751, lat: 42.110548, postalCode: "61052" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61643" },
    { lng: -88.84665, lat: 42.364394, postalCode: "61065" },
    { lng: -89.69902, lat: 39.676558, postalCode: "62629" },
    { lng: -89.46289, lat: 41.504603, postalCode: "61328" },
    { lng: -88.57375, lat: 40.0334, postalCode: "61856" },
    { lng: -88.72633, lat: 39.889596, postalCode: "61818" },
    { lng: -89.44294, lat: 40.701037, postalCode: "61571" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62786" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60572" },
    { lng: -88.3495, lat: 42.384504, postalCode: "60097" },
    { lng: -88.61034, lat: 42.242199, postalCode: "60152" },
    { lng: -91.17125, lat: 40.467994, postalCode: "62336" },
    { lng: -88.07891, lat: 41.920228, postalCode: "60139" },
    { lng: -89.313593, lat: 42.045123, postalCode: "61043" },
    { lng: -90.42469, lat: 40.368358, postalCode: "61482" },
    { lng: -89.82436, lat: 42.111421, postalCode: "61046" },
    { lng: -89.88669, lat: 39.735761, postalCode: "62670" },
    { lng: -87.76252, lat: 42.130976, postalCode: "60022" },
    { lng: -90.00059, lat: 39.14665, postalCode: "62685" },
    { lng: -90.00104, lat: 38.53895, postalCode: "62226" },
    { lng: -88.14732, lat: 41.109101, postalCode: "60969" },
    { lng: -90.54502, lat: 39.430595, postalCode: "62050" },
    { lng: -90.35566, lat: 41.02572, postalCode: "61439" },
    { lng: -88.97586, lat: 42.245596, postalCode: "61112" },
    { lng: -89.38796, lat: 38.078231, postalCode: "62274" },
    { lng: -88.47715, lat: 39.223922, postalCode: "62462" },
    { lng: -89.30296, lat: 38.460331, postalCode: "62803" },
    { lng: -90.852226, lat: 41.336752, postalCode: "61237" },
    { lng: -87.6665, lat: 41.973181, postalCode: "60640" },
    { lng: -88.84734, lat: 41.35263, postalCode: "61350" },
    { lng: -87.84013, lat: 41.92583, postalCode: "60171" },
    { lng: -87.82318, lat: 41.812331, postalCode: "60534" },
    { lng: -87.93384, lat: 39.909736, postalCode: "61810" },
    { lng: -88.25218, lat: 41.760067, postalCode: "60504" },
    { lng: -89.09369, lat: 42.101928, postalCode: "61020" },
    { lng: -89.39583, lat: 41.435327, postalCode: "61323" },
    { lng: -90.87779, lat: 39.450931, postalCode: "62366" },
    { lng: -90.46107, lat: 39.628929, postalCode: "62694" },
    { lng: -89.07794, lat: 41.466102, postalCode: "61372" },
    { lng: -87.89201, lat: 41.598255, postalCode: "60467" },
    { lng: -89.50721, lat: 37.871546, postalCode: "62907" },
    { lng: -88.26498, lat: 37.703552, postalCode: "62954" },
    { lng: -89.21243, lat: 41.4276, postalCode: "61317" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60672" },
    { lng: -89.59324, lat: 39.053344, postalCode: "62091" },
    { lng: -87.93621, lat: 42.065427, postalCode: "60056" },
    { lng: -88.84223, lat: 41.998231, postalCode: "60111" },
    { lng: -87.70256, lat: 41.971614, postalCode: "60625" },
    { lng: -90.74134, lat: 39.983824, postalCode: "62353" },
    { lng: -90.615975, lat: 41.554901, postalCode: "61266" },
    { lng: -90.87087, lat: 40.555678, postalCode: "61420" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60189" },
    { lng: -90.85925, lat: 40.854865, postalCode: "61418" },
    { lng: -88.21636, lat: 38.48679, postalCode: "62446" },
    { lng: -88.31664, lat: 42.265643, postalCode: "60012" },
    { lng: -89.01261, lat: 37.980455, postalCode: "62819" },
    { lng: -90.54614, lat: 39.241734, postalCode: "62027" },
    { lng: -89.86649, lat: 41.09638, postalCode: "61483" },
    { lng: -87.89627, lat: 41.91823, postalCode: "60164" },
    { lng: -90.39047, lat: 41.497667, postalCode: "61239" },
    { lng: -90.30177, lat: 41.697188, postalCode: "61242" },
    { lng: -89.88059, lat: 39.847253, postalCode: "62677" },
    { lng: -89.40909, lat: 39.205747, postalCode: "62051" },
    { lng: -88.86505, lat: 39.251624, postalCode: "62422" },
    { lng: -87.70814, lat: 41.642933, postalCode: "60472" },
    { lng: -90.31978, lat: 41.599642, postalCode: "61275" },
    { lng: -88.28118, lat: 39.393267, postalCode: "62440" },
    { lng: -89.19057, lat: 40.528515, postalCode: "61732" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60671" },
    { lng: -90.352444, lat: 39.979614, postalCode: "62622" },
    { lng: -90.200747, lat: 39.858933, postalCode: "62660" },
    { lng: -90.50006, lat: 41.490333, postalCode: "61265" },
    { lng: -90.36813, lat: 41.189123, postalCode: "61413" },
    { lng: -90.651349, lat: 39.564228, postalCode: "62652" },
    { lng: -89.03886, lat: 41.21076, postalCode: "61370" },
    { lng: -88.13688, lat: 41.918578, postalCode: "60188" },
    { lng: -89.15191, lat: 39.838505, postalCode: "62551" },
    { lng: -87.80716, lat: 40.306774, postalCode: "61865" },
    { lng: -90.14606, lat: 38.325969, postalCode: "62298" },
    { lng: -87.743454, lat: 41.818682, postalCode: "60650" },
    { lng: -89.58242, lat: 42.119619, postalCode: "61030" },
    { lng: -89.04738, lat: 38.614141, postalCode: "62870" },
    { lng: -88.93959, lat: 37.279433, postalCode: "62956" },
    { lng: -88.6899, lat: 41.921121, postalCode: "60112" },
    { lng: -87.76815, lat: 38.720425, postalCode: "62417" },
    { lng: -90.74617, lat: 39.781349, postalCode: "62362" },
    { lng: -88.5296, lat: 40.213607, postalCode: "61854" },
    { lng: -87.81091, lat: 41.99623, postalCode: "60631" },
    { lng: -87.75415, lat: 41.921431, postalCode: "60639" },
    { lng: -89.40841, lat: 40.784412, postalCode: "61548" },
    { lng: -89.506695, lat: 40.874269, postalCode: "61562" },
    { lng: -88.87747, lat: 38.911662, postalCode: "62857" },
    { lng: -87.72913, lat: 38.528202, postalCode: "62410" },
    { lng: -89.24091, lat: 37.940448, postalCode: "62927" },
    { lng: -87.87642, lat: 41.882924, postalCode: "60104" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61653" },
    { lng: -87.60159, lat: 40.876303, postalCode: "60931" },
    { lng: -90.51766, lat: 40.550883, postalCode: "61422" },
    { lng: -87.82587, lat: 39.907331, postalCode: "61876" },
    { lng: -89.17587, lat: 37.612173, postalCode: "62958" },
    { lng: -87.91052, lat: 41.886794, postalCode: "60163" },
    { lng: -87.716074, lat: 40.966993, postalCode: "60956" },
    { lng: -89.653399, lat: 39.79885, postalCode: "62706" },
    { lng: -90.73137, lat: 41.202461, postalCode: "61231" },
    { lng: -87.58356, lat: 40.827011, postalCode: "60945" },
    { lng: -88.62439, lat: 38.190536, postalCode: "62828" },
    { lng: -90.355505, lat: 40.553989, postalCode: "61457" },
    { lng: -88.37184, lat: 37.716434, postalCode: "62934" },
    { lng: -88.38326, lat: 41.944577, postalCode: "60175" },
    { lng: -91.15278, lat: 40.630407, postalCode: "62330" },
    { lng: -87.79095, lat: 41.836981, postalCode: "60402" },
    { lng: -89.899569, lat: 38.013132, postalCode: "62259" },
    { lng: -87.86309, lat: 42.286222, postalCode: "60044" },
    { lng: -88.93138, lat: 38.192726, postalCode: "62816" },
    { lng: -88.62995, lat: 39.724744, postalCode: "61937" },
    { lng: -90.64246, lat: 39.322422, postalCode: "62053" },
    { lng: -89.93996, lat: 38.532311, postalCode: "62221" },
    { lng: -88.03308, lat: 40.598963, postalCode: "60918" },
    { lng: -90.67372, lat: 39.079147, postalCode: "62006" },
    { lng: -88.57626, lat: 41.91842, postalCode: "60151" },
    { lng: -88.15583, lat: 38.087838, postalCode: "62821" },
    { lng: -89.47408, lat: 40.513656, postalCode: "61568" },
    { lng: -88.95637, lat: 39.878041, postalCode: "62526" },
    { lng: -87.62408, lat: 41.350484, postalCode: "60401" },
    { lng: -87.66475, lat: 40.543697, postalCode: "60973" },
    { lng: -88.73274, lat: 40.537652, postalCode: "61730" },
    { lng: -89.05465, lat: 39.759905, postalCode: "62514" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60598" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60694" },
    { lng: -88.5036, lat: 42.086218, postalCode: "60140" },
    { lng: -87.82825, lat: 41.723782, postalCode: "60457" },
    { lng: -90.71359, lat: 39.236358, postalCode: "62045" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62767" },
    { lng: -88.73381, lat: 37.342538, postalCode: "62943" },
    { lng: -87.80678, lat: 41.742432, postalCode: "60455" },
    { lng: -88.24338, lat: 42.217523, postalCode: "60013" },
    { lng: -90.32442, lat: 39.114762, postalCode: "62052" },
    { lng: -90.01499, lat: 39.429687, postalCode: "62674" },
    { lng: -90.66923, lat: 39.817702, postalCode: "62323" },
    { lng: -89.576628, lat: 40.743266, postalCode: "61616" },
    { lng: -87.72199, lat: 41.879365, postalCode: "60624" },
    { lng: -90.82607, lat: 40.696535, postalCode: "61471" },
    { lng: -87.58163, lat: 40.231345, postalCode: "61814" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60701" },
    { lng: -89.509113, lat: 40.87671, postalCode: "61652" },
    { lng: -89.5286, lat: 38.439574, postalCode: "62271" },
    { lng: -88.18649, lat: 41.04699, postalCode: "60917" },
    { lng: -87.8704, lat: 41.829831, postalCode: "60526" },
    { lng: -88.25646, lat: 39.273828, postalCode: "62468" },
    { lng: -88.3708, lat: 40.112616, postalCode: "61815" },
    { lng: -90.17052, lat: 38.67065, postalCode: "62090" },
    { lng: -88.43884, lat: 42.316121, postalCode: "60098" },
    { lng: -87.672553, lat: 41.867532, postalCode: "60692" },
    { lng: -89.17366, lat: 41.350864, postalCode: "61320" },
    { lng: -87.81, lat: 41.784832, postalCode: "60501" },
    { lng: -90.43908, lat: 40.661165, postalCode: "61415" },
    { lng: -90.42314, lat: 40.005505, postalCode: "62618" },
    { lng: -87.74055, lat: 41.901485, postalCode: "60651" },
    { lng: -91.37791, lat: 40.180236, postalCode: "62348" },
    { lng: -89.09581, lat: 38.751756, postalCode: "62875" },
    { lng: -89.20166, lat: 38.647775, postalCode: "62283" },
    { lng: -90.57421, lat: 38.91384, postalCode: "62036" },
    { lng: -87.907066, lat: 42.024278, postalCode: "60019" },
    { lng: -90.13748, lat: 40.39309, postalCode: "61542" },
    { lng: -87.81563, lat: 42.032379, postalCode: "60714" },
    { lng: -90.41788, lat: 41.518733, postalCode: "61244" },
    { lng: -88.85664, lat: 40.122884, postalCode: "61750" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61125" },
    { lng: -89.650381, lat: 38.227604, postalCode: "62270" },
    { lng: -89.35051, lat: 39.969864, postalCode: "62541" },
    { lng: -90.31576, lat: 40.500643, postalCode: "61477" },
    { lng: -90.30833, lat: 38.299904, postalCode: "62295" },
    { lng: -89.88852, lat: 38.318253, postalCode: "62264" },
    { lng: -90.11832, lat: 41.200711, postalCode: "61419" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60186" },
    { lng: -89.8297, lat: 40.577778, postalCode: "61533" },
    { lng: -87.85477, lat: 41.857931, postalCode: "60155" },
    { lng: -88.54208, lat: 37.725834, postalCode: "62946" },
    { lng: -88.2532, lat: 39.981936, postalCode: "61880" },
    { lng: -90.27031, lat: 41.372408, postalCode: "61274" },
    { lng: -89.54255, lat: 41.905844, postalCode: "61091" },
    { lng: -89.44945, lat: 39.588585, postalCode: "62570" },
    { lng: -89.84805, lat: 39.669508, postalCode: "62661" },
    { lng: -88.0821, lat: 40.526477, postalCode: "60948" },
    { lng: -88.74922, lat: 37.913984, postalCode: "62890" },
    { lng: -88.15261, lat: 41.875228, postalCode: "60190" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60677" },
    { lng: -88.29315, lat: 40.126854, postalCode: "61822" },
    { lng: -91.0368, lat: 39.704649, postalCode: "62312" },
    { lng: -90.06937, lat: 39.260146, postalCode: "62630" },
    { lng: -88.28891, lat: 42.101502, postalCode: "60118" },
    { lng: -88.69727, lat: 41.336202, postalCode: "61341" },
    { lng: -88.11971, lat: 39.685822, postalCode: "61930" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60527" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60669" },
    { lng: -90.07575, lat: 38.598175, postalCode: "62203" },
    { lng: -89.54888, lat: 38.812744, postalCode: "62275" },
    { lng: -89.28092, lat: 39.114972, postalCode: "62032" },
    { lng: -90.34046, lat: 41.483484, postalCode: "61241" },
    { lng: -90.23039, lat: 39.261499, postalCode: "62081" },
    { lng: -87.7885, lat: 41.87355, postalCode: "60304" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60168" },
    { lng: -89.71168, lat: 38.122112, postalCode: "62286" },
    { lng: -87.703639, lat: 38.710336, postalCode: "62415" },
    { lng: -89.90389, lat: 38.425949, postalCode: "62243" },
    { lng: -88.33357, lat: 38.101667, postalCode: "62835" },
    { lng: -87.86464, lat: 38.351613, postalCode: "62852" },
    { lng: -89.97654, lat: 38.50046, postalCode: "62220" },
    { lng: -89.19246, lat: 37.707763, postalCode: "62901" },
    { lng: -88.19634, lat: 41.707118, postalCode: "60564" },
    { lng: -89.65194, lat: 40.49271, postalCode: "61564" },
    { lng: -88.01982, lat: 39.122158, postalCode: "62481" },
    { lng: -91.0123, lat: 41.223984, postalCode: "61272" },
    { lng: -89.10348, lat: 39.136284, postalCode: "62080" },
    { lng: -88.14328, lat: 42.263181, postalCode: "60084" },
    { lng: -87.9939, lat: 38.452172, postalCode: "62815" },
    { lng: -90.64466, lat: 40.918849, postalCode: "61462" },
    { lng: -89.63633, lat: 40.709237, postalCode: "61604" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60664" },
    { lng: -90.7612, lat: 40.581413, postalCode: "61475" },
    { lng: -88.420178, lat: 41.936977, postalCode: "60183" },
    { lng: -87.81421, lat: 42.205724, postalCode: "60040" },
    { lng: -87.84303, lat: 42.124576, postalCode: "60062" },
    { lng: -87.63292, lat: 41.649717, postalCode: "60827" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60665" },
    { lng: -90.80998, lat: 41.095037, postalCode: "61476" },
    { lng: -90.91347, lat: 40.727428, postalCode: "61480" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62757" },
    { lng: -87.92996, lat: 41.772915, postalCode: "60521" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60675" },
    { lng: -87.77869, lat: 41.702482, postalCode: "60415" },
    { lng: -90.00698, lat: 40.147371, postalCode: "62655" },
    { lng: -89.46228, lat: 42.407384, postalCode: "61070" },
    { lng: -87.85152, lat: 38.732726, postalCode: "62466" },
    { lng: -88.10719, lat: 42.037108, postalCode: "60194" },
    { lng: -87.59909, lat: 41.597472, postalCode: "60473" },
    { lng: -90.40384, lat: 41.254936, postalCode: "61468" },
    { lng: -91.36598, lat: 40.531825, postalCode: "62354" },
    { lng: -89.10964, lat: 37.199769, postalCode: "62970" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61651" },
    { lng: -88.53431, lat: 38.090004, postalCode: "62859" },
    { lng: -89.06891, lat: 41.935139, postalCode: "61068" },
    { lng: -91.05746, lat: 40.232385, postalCode: "62316" },
    { lng: -89.421584, lat: 39.261791, postalCode: "62076" },
    { lng: -89.78797, lat: 40.442037, postalCode: "61546" },
    { lng: -90.09632, lat: 40.466397, postalCode: "61519" },
    { lng: -88.98815, lat: 41.690735, postalCode: "61353" },
    { lng: -89.26889, lat: 41.638122, postalCode: "61367" },
    { lng: -89.84111, lat: 41.394429, postalCode: "61344" },
    { lng: -90.76896, lat: 39.328077, postalCode: "62070" },
    { lng: -88.548695, lat: 37.801972, postalCode: "62965" },
    { lng: -88.50469, lat: 37.999757, postalCode: "62829" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62739" },
    { lng: -87.89058, lat: 42.047178, postalCode: "60016" },
    { lng: -88.00222, lat: 41.931573, postalCode: "60101" },
    { lng: -89.33971, lat: 37.771806, postalCode: "62966" },
    { lng: -87.90101, lat: 41.872997, postalCode: "60162" },
    { lng: -89.46415, lat: 39.017877, postalCode: "62019" },
    { lng: -89.35449, lat: 41.543813, postalCode: "61374" },
    { lng: -90.55791, lat: 39.818631, postalCode: "62665" },
    { lng: -88.02196, lat: 41.972328, postalCode: "60143" },
    { lng: -89.16184, lat: 38.436236, postalCode: "62848" },
    { lng: -87.80536, lat: 41.078981, postalCode: "60910" },
    { lng: -89.04226, lat: 38.01492, postalCode: "62891" },
    { lng: -90.38717, lat: 41.105401, postalCode: "61472" },
    { lng: -88.33066, lat: 42.226623, postalCode: "60014" },
    { lng: -89.27691, lat: 41.53364, postalCode: "61330" },
    { lng: -90.80333, lat: 40.421857, postalCode: "62326" },
    { lng: -88.9543, lat: 42.222221, postalCode: "61016" },
    { lng: -89.45643, lat: 39.824457, postalCode: "62520" },
    { lng: -89.19356, lat: 38.282464, postalCode: "62876" },
    { lng: -89.33462, lat: 37.571235, postalCode: "62905" },
    { lng: -87.66854, lat: 41.776633, postalCode: "60636" },
    { lng: -88.13861, lat: 39.783794, postalCode: "61919" },
    { lng: -88.40325, lat: 41.243419, postalCode: "60444" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60117" },
    { lng: -89.06852, lat: 37.996241, postalCode: "62825" },
    { lng: -90.45631, lat: 41.200936, postalCode: "61465" },
    { lng: -88.52571, lat: 37.374723, postalCode: "62938" },
    { lng: -89.536369, lat: 39.848201, postalCode: "62716" },
    { lng: -91.37415, lat: 39.929597, postalCode: "62301" },
    { lng: -87.85202, lat: 42.326072, postalCode: "60064" },
    { lng: -89.84638, lat: 38.889879, postalCode: "62046" },
    { lng: -88.36942, lat: 42.099479, postalCode: "60136" },
    { lng: -89.73423, lat: 39.077557, postalCode: "62069" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60685" },
    { lng: -89.923215, lat: 39.128248, postalCode: "62676" },
    { lng: -89.11324, lat: 39.867151, postalCode: "62537" },
    { lng: -88.29699, lat: 41.756129, postalCode: "60505" },
    { lng: -88.35875, lat: 38.764802, postalCode: "62879" },
    { lng: -88.34937, lat: 40.966528, postalCode: "60934" },
    { lng: -90.9284, lat: 41.369036, postalCode: "61259" },
    { lng: -88.07462, lat: 41.703097, postalCode: "60440" },
    { lng: -90.29705, lat: 41.030951, postalCode: "61488" },
    { lng: -89.94935, lat: 39.039382, postalCode: "62014" },
    { lng: -90.95426, lat: 40.151799, postalCode: "62346" },
    { lng: -88.52606, lat: 42.229437, postalCode: "60180" },
    { lng: -88.63491, lat: 39.354837, postalCode: "62465" },
    { lng: -88.97442, lat: 40.32122, postalCode: "61745" },
    { lng: -87.62325, lat: 41.886456, postalCode: "60601" },
    { lng: -87.86014, lat: 39.646359, postalCode: "61949" },
    { lng: -88.58448, lat: 38.34239, postalCode: "62895" },
    { lng: -89.91216, lat: 41.522044, postalCode: "61258" },
    { lng: -87.99407, lat: 38.625864, postalCode: "62419" },
    { lng: -88.17881, lat: 38.974018, postalCode: "62448" },
    { lng: -89.03266, lat: 40.893593, postalCode: "61760" },
    { lng: -89.76891, lat: 40.558329, postalCode: "61539" },
    { lng: -88.10284, lat: 41.429681, postalCode: "60421" },
    { lng: -88.014072, lat: 42.097976, postalCode: "60038" },
    { lng: -89.21207, lat: 41.111077, postalCode: "61336" },
    { lng: -89.9396, lat: 39.583983, postalCode: "62692" },
    { lng: -87.81922, lat: 42.07672, postalCode: "60025" },
    { lng: -89.667939, lat: 42.355422, postalCode: "61076" },
    { lng: -89.93366, lat: 39.944264, postalCode: "62688" },
    { lng: -88.68292, lat: 41.527585, postalCode: "60551" },
    { lng: -89.20847, lat: 41.332908, postalCode: "61362" },
    { lng: -88.91932, lat: 41.599139, postalCode: "60518" },
    { lng: -89.54053, lat: 40.590353, postalCode: "61535" },
    { lng: -87.97291, lat: 38.378706, postalCode: "62818" },
    { lng: -89.325581, lat: 37.152775, postalCode: "62913" },
    { lng: -88.73912, lat: 39.062913, postalCode: "62411" },
    { lng: -89.38453, lat: 38.346578, postalCode: "62263" },
    { lng: -88.21246, lat: 40.109647, postalCode: "61801" },
    { lng: -89.70575, lat: 41.118359, postalCode: "61426" },
    { lng: -90.09248, lat: 41.978758, postalCode: "61285" },
    { lng: -88.2601, lat: 39.190088, postalCode: "62436" },
    { lng: -90.27171, lat: 40.910079, postalCode: "61448" },
    { lng: -88.6087, lat: 39.806718, postalCode: "61929" },
    { lng: -88.05859, lat: 42.199957, postalCode: "60047" },
    { lng: -88.39957, lat: 40.885397, postalCode: "61769" },
    { lng: -89.79432, lat: 38.481674, postalCode: "62258" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62766" },
    { lng: -89.8028, lat: 39.436584, postalCode: "62640" },
    { lng: -88.87876, lat: 39.317235, postalCode: "62438" },
    { lng: -89.480688, lat: 40.300508, postalCode: "62605" },
    { lng: -89.926068, lat: 39.260862, postalCode: "62683" },
    { lng: -90.47345, lat: 40.614899, postalCode: "61470" },
    { lng: -89.0149, lat: 41.37061, postalCode: "61373" },
    { lng: -89.22353, lat: 40.619306, postalCode: "61729" },
    { lng: -89.38586, lat: 41.030697, postalCode: "61540" },
    { lng: -90.16313, lat: 40.167991, postalCode: "62617" },
    { lng: -89.64379, lat: 39.819, postalCode: "62702" },
    { lng: -89.85425, lat: 39.068901, postalCode: "62093" },
    { lng: -89.62737, lat: 40.678037, postalCode: "61605" },
    { lng: -88.37124, lat: 38.460323, postalCode: "62842" },
    { lng: -89.72628, lat: 40.619174, postalCode: "61547" },
    { lng: -89.33547, lat: 37.097546, postalCode: "62962" },
    { lng: -87.63014, lat: 41.880446, postalCode: "60603" },
    { lng: -90.22147, lat: 39.362285, postalCode: "62044" },
    { lng: -89.91207, lat: 38.589849, postalCode: "62269" },
    { lng: -90.48002, lat: 39.477337, postalCode: "62078" },
    { lng: -89.650232, lat: 40.56071, postalCode: "61555" },
    { lng: -88.33505, lat: 38.835884, postalCode: "62434" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60454" },
    { lng: -87.80956, lat: 42.181875, postalCode: "60035" },
    { lng: -88.6494, lat: 41.169068, postalCode: "60470" },
    { lng: -90.08536, lat: 38.8494, postalCode: "62084" },
    { lng: -89.2128, lat: 41.982993, postalCode: "61015" },
    { lng: -87.97778, lat: 41.795358, postalCode: "60559" },
    { lng: -89.595147, lat: 40.699937, postalCode: "61636" },
    { lng: -88.07169, lat: 38.375576, postalCode: "62806" },
    { lng: -87.79286, lat: 42.056529, postalCode: "60029" },
    { lng: -88.8232, lat: 38.455562, postalCode: "62889" },
    { lng: -88.34267, lat: 38.268959, postalCode: "62809" },
    { lng: -88.81261, lat: 39.869841, postalCode: "62552" },
    { lng: -89.80775, lat: 38.049616, postalCode: "62297" },
    { lng: -88.29184, lat: 39.793493, postalCode: "61953" },
    { lng: -89.541017, lat: 39.852361, postalCode: "62761" },
    { lng: -89.23151, lat: 37.907619, postalCode: "62932" },
    { lng: -87.97138, lat: 40.192015, postalCode: "61871" },
    { lng: -87.84378, lat: 41.664582, postalCode: "60464" },
    { lng: -89.10358, lat: 40.091729, postalCode: "61749" },
    { lng: -88.23794, lat: 41.105629, postalCode: "60961" },
    { lng: -90.31205, lat: 40.94399, postalCode: "61430" },
    { lng: -89.21039, lat: 37.214012, postalCode: "62976" },
    { lng: -87.684506, lat: 42.058629, postalCode: "60208" },
    { lng: -89.96623, lat: 41.819003, postalCode: "61270" },
    { lng: -88.43238, lat: 41.935616, postalCode: "60568" },
    { lng: -88.35735, lat: 38.374627, postalCode: "62837" },
    { lng: -90.72045, lat: 39.71345, postalCode: "62340" },
    { lng: -87.88919, lat: 40.27363, postalCode: "61812" },
    { lng: -89.761171, lat: 42.332038, postalCode: "61027" },
    { lng: -88.74344, lat: 42.255617, postalCode: "61038" },
    { lng: -87.99681, lat: 39.4505, postalCode: "62474" },
    { lng: -88.29306, lat: 40.757447, postalCode: "60921" },
    { lng: -89.9551, lat: 38.819297, postalCode: "62025" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62736" },
    { lng: -88.88016, lat: 37.422815, postalCode: "62995" },
    { lng: -89.07602, lat: 42.25537, postalCode: "61104" },
    { lng: -87.90949, lat: 38.384799, postalCode: "62811" },
    { lng: -87.95553, lat: 40.296282, postalCode: "61862" },
    { lng: -89.89007, lat: 38.011729, postalCode: "62241" },
    { lng: -88.93037, lat: 37.894496, postalCode: "62896" },
    { lng: -90.09418, lat: 38.860447, postalCode: "62095" },
    { lng: -89.67982, lat: 39.7778, postalCode: "62704" },
    { lng: -91.25754, lat: 39.98152, postalCode: "62338" },
    { lng: -88.12824, lat: 41.728738, postalCode: "60565" },
    { lng: -89.80769, lat: 39.397248, postalCode: "62672" },
    { lng: -89.46592, lat: 40.02489, postalCode: "62634" },
    { lng: -91.20372, lat: 40.160393, postalCode: "62349" },
    { lng: -88.072522, lat: 42.056376, postalCode: "60196" },
    { lng: -89.59799, lat: 40.755343, postalCode: "61614" },
    { lng: -87.63369, lat: 41.470084, postalCode: "60475" },
    { lng: -88.75786, lat: 40.345004, postalCode: "61752" },
    { lng: -89.2134, lat: 39.11204, postalCode: "62011" },
    { lng: -89.77739, lat: 38.706847, postalCode: "62281" },
    { lng: -89.85029, lat: 40.008726, postalCode: "62675" },
    { lng: -90.58121, lat: 38.968323, postalCode: "62013" },
    { lng: -88.425931, lat: 42.025776, postalCode: "60170" },
    { lng: -88.76647, lat: 42.102014, postalCode: "60145" },
    { lng: -87.79654, lat: 41.944454, postalCode: "60634" },
    { lng: -90.66845, lat: 40.249363, postalCode: "61452" },
    { lng: -87.63685, lat: 40.040115, postalCode: "61883" },
    { lng: -89.29128, lat: 38.761214, postalCode: "62253" },
    { lng: -90.78642, lat: 39.606349, postalCode: "62363" },
    { lng: -90.2272, lat: 38.200113, postalCode: "62244" },
    { lng: -88.25503, lat: 41.230524, postalCode: "60407" },
    { lng: -88.864303, lat: 40.519236, postalCode: "61702" },
    { lng: -89.02573, lat: 42.326227, postalCode: "61111" },
    { lng: -88.06285, lat: 41.869779, postalCode: "60137" },
    { lng: -90.64813, lat: 39.563599, postalCode: "62352" },
    { lng: -89.60101, lat: 40.29916, postalCode: "62682" },
    { lng: -87.9667, lat: 40.883641, postalCode: "60911" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60197" },
    { lng: -87.63126, lat: 41.8474, postalCode: "60616" },
    { lng: -89.05943, lat: 41.292401, postalCode: "61348" },
    { lng: -89.2073, lat: 40.139482, postalCode: "62512" },
    { lng: -88.51546, lat: 40.741158, postalCode: "61739" },
    { lng: -87.6877, lat: 41.880682, postalCode: "60612" },
    { lng: -88.48855, lat: 38.667752, postalCode: "62839" },
    { lng: -89.32248, lat: 38.887748, postalCode: "62284" },
    { lng: -87.610053, lat: 42.322814, postalCode: "60075" },
    { lng: -89.08246, lat: 42.303365, postalCode: "61103" },
    { lng: -90.12858, lat: 42.099635, postalCode: "61074" },
    { lng: -89.91237, lat: 42.320303, postalCode: "61044" },
    { lng: -87.88046, lat: 41.90158, postalCode: "60165" },
    { lng: -88.01334, lat: 41.761463, postalCode: "60516" },
    { lng: -89.04364, lat: 40.986256, postalCode: "61358" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62746" },
    { lng: -87.69958, lat: 39.608494, postalCode: "61944" },
    { lng: -88.981511, lat: 39.853981, postalCode: "62527" },
    { lng: -90.293302, lat: 39.376153, postalCode: "62098" },
    { lng: -90.40655, lat: 39.433041, postalCode: "62092" },
    { lng: -88.39925, lat: 37.567779, postalCode: "62947" },
    { lng: -89.63015, lat: 41.073398, postalCode: "61424" },
    { lng: -89.2487, lat: 39.855984, postalCode: "62539" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60683" },
    { lng: -89.42755, lat: 39.591537, postalCode: "62517" },
    { lng: -88.238292, lat: 37.500757, postalCode: "62955" },
    { lng: -88.98629, lat: 40.515485, postalCode: "61761" },
    { lng: -88.15018, lat: 39.858506, postalCode: "61956" },
    { lng: -88.19022, lat: 41.52493, postalCode: "60431" },
    { lng: -89.63382, lat: 40.567088, postalCode: "61554" },
    { lng: -88.92907, lat: 39.828586, postalCode: "62521" },
    { lng: -88.82807, lat: 41.234167, postalCode: "61325" },
    { lng: -89.68226, lat: 38.964162, postalCode: "62074" },
    { lng: -89.76182, lat: 41.788761, postalCode: "61037" },
    { lng: -88.36923, lat: 38.185822, postalCode: "62887" },
    { lng: -88.34815, lat: 37.426076, postalCode: "62982" },
    { lng: -88.21399, lat: 42.065827, postalCode: "60192" },
    { lng: -89.65936, lat: 39.642083, postalCode: "62536" },
    { lng: -87.65282, lat: 41.740483, postalCode: "60620" },
    { lng: -87.72398, lat: 41.561134, postalCode: "60478" },
    { lng: -89.67147, lat: 40.642049, postalCode: "61607" },
    { lng: -91.07407, lat: 40.020397, postalCode: "62320" },
    { lng: -90.13819, lat: 39.038752, postalCode: "62012" },
    { lng: -89.32778, lat: 40.615875, postalCode: "61733" },
    { lng: -87.71155, lat: 41.777482, postalCode: "60629" },
    { lng: -88.27227, lat: 42.121188, postalCode: "60110" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62769" },
    { lng: -89.63382, lat: 42.375078, postalCode: "61013" },
    { lng: -90.03901, lat: 41.172197, postalCode: "61434" },
    { lng: -90.89509, lat: 39.756139, postalCode: "62314" },
    { lng: -87.7175, lat: 42.048879, postalCode: "60203" },
    { lng: -88.37421, lat: 39.474889, postalCode: "61938" },
    { lng: -87.81718, lat: 41.893031, postalCode: "60305" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60138" },
    { lng: -88.09995, lat: 42.46617, postalCode: "60002" },
    { lng: -89.60516, lat: 38.607817, postalCode: "62216" },
    { lng: -88.04814, lat: 42.051927, postalCode: "60173" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61106" },
    { lng: -89.29547, lat: 40.920631, postalCode: "61570" },
    { lng: -88.56139, lat: 39.918042, postalCode: "61813" },
    { lng: -90.143114, lat: 39.852164, postalCode: "62616" },
    { lng: -87.90005, lat: 39.006483, postalCode: "62449" },
    { lng: -88.28676, lat: 40.873, postalCode: "60929" },
    { lng: -88.72896, lat: 38.362934, postalCode: "62814" },
    { lng: -88.42891, lat: 42.171973, postalCode: "60142" },
    { lng: -88.134469, lat: 41.878878, postalCode: "60127" },
    { lng: -87.92816, lat: 42.105576, postalCode: "60070" },
    { lng: -87.87605, lat: 41.788536, postalCode: "60525" },
    { lng: -87.66325, lat: 39.786334, postalCode: "61924" },
    { lng: -90.20392, lat: 41.590161, postalCode: "61257" },
    { lng: -87.99863, lat: 39.299447, postalCode: "62420" },
    { lng: -87.65432, lat: 41.922682, postalCode: "60614" },
    { lng: -89.58805, lat: 39.901588, postalCode: "62684" },
    { lng: -89.27713, lat: 41.747471, postalCode: "61331" },
    { lng: -87.82977, lat: 41.699432, postalCode: "60465" },
    { lng: -87.54638, lat: 41.614188, postalCode: "60409" },
    { lng: -88.74617, lat: 41.924127, postalCode: "60115" },
    { lng: -90.46726, lat: 41.100353, postalCode: "61466" },
    { lng: -91.21256, lat: 39.97863, postalCode: "62306" },
    { lng: -89.60081, lat: 41.546706, postalCode: "61376" },
    { lng: -87.62818, lat: 41.877589, postalCode: "60604" },
    { lng: -88.18667, lat: 40.625486, postalCode: "60962" },
    { lng: -89.27153, lat: 40.718228, postalCode: "61530" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60132" },
    { lng: -88.304994, lat: 42.067101, postalCode: "60122" },
    { lng: -90.14057, lat: 40.051603, postalCode: "62627" },
    { lng: -88.30954, lat: 41.174117, postalCode: "60424" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60198" },
    { lng: -88.40295, lat: 40.205916, postalCode: "61853" },
    { lng: -89.88891, lat: 38.725498, postalCode: "62294" },
    { lng: -90.57923, lat: 41.195087, postalCode: "61486" },
    { lng: -87.65852, lat: 41.940832, postalCode: "60657" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62776" },
    { lng: -89.61645, lat: 38.006386, postalCode: "62272" },
    { lng: -88.372434, lat: 37.726738, postalCode: "62394" },
    { lng: -91.33182, lat: 40.402015, postalCode: "62341" },
    { lng: -88.951205, lat: 41.964197, postalCode: "60149" },
    { lng: -89.8029, lat: 39.092526, postalCode: "62009" },
    { lng: -89.03866, lat: 38.212746, postalCode: "62894" },
    { lng: -90.05163, lat: 40.296415, postalCode: "62644" },
    { lng: -89.083056, lat: 37.311333, postalCode: "62973" },
    { lng: -88.62966, lat: 40.139336, postalCode: "61839" },
    { lng: -87.750216, lat: 39.679882, postalCode: "61955" },
    { lng: -89.14346, lat: 37.835684, postalCode: "62949" },
    { lng: -90.17778, lat: 42.298773, postalCode: "61028" },
    { lng: -88.86268, lat: 41.042878, postalCode: "61311" },
    { lng: -89.5631, lat: 38.554515, postalCode: "62245" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61650" },
    { lng: -89.5228, lat: 39.031623, postalCode: "62077" },
    { lng: -87.82876, lat: 40.748087, postalCode: "60939" },
    { lng: -88.5144, lat: 39.45274, postalCode: "61928" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61126" },
    { lng: -89.63612, lat: 39.7715, postalCode: "62703" },
    { lng: -88.348484, lat: 42.185733, postalCode: "60156" },
    { lng: -88.07537, lat: 39.901988, postalCode: "61852" },
    { lng: -87.74576, lat: 41.946431, postalCode: "60641" },
    { lng: -88.98873, lat: 39.228594, postalCode: "62431" },
    { lng: -90.2216, lat: 38.947841, postalCode: "62035" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61110" },
    { lng: -90.161761, lat: 40.449305, postalCode: "61433" },
    { lng: -88.31569, lat: 39.038732, postalCode: "62479" },
    { lng: -89.65554, lat: 40.849036, postalCode: "61525" },
    { lng: -87.86525, lat: 42.404272, postalCode: "60087" },
    { lng: -88.49, lat: 40.607615, postalCode: "61731" },
    { lng: -87.82163, lat: 41.836131, postalCode: "60546" },
    { lng: -91.26558, lat: 40.094396, postalCode: "62351" },
    { lng: -88.420178, lat: 41.936977, postalCode: "60507" },
    { lng: -89.79442, lat: 41.280262, postalCode: "61345" },
    { lng: -87.7495, lat: 39.004728, postalCode: "62454" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60204" },
    { lng: -88.61431, lat: 42.424338, postalCode: "60033" },
    { lng: -87.95334, lat: 38.755492, postalCode: "62421" },
    { lng: -88.33275, lat: 39.35239, postalCode: "62469" },
    { lng: -87.96393, lat: 42.167638, postalCode: "60089" },
    { lng: -88.20074, lat: 42.277691, postalCode: "60042" },
    { lng: -88.30498, lat: 41.919808, postalCode: "60174" },
    { lng: -89.78042, lat: 40.690205, postalCode: "61536" },
    { lng: -89.93036, lat: 41.612038, postalCode: "61277" },
    { lng: -88.52604, lat: 40.336763, postalCode: "61724" },
    { lng: -89.15783, lat: 37.363981, postalCode: "62926" },
    { lng: -89.47636, lat: 41.843702, postalCode: "61021" },
    { lng: -90.02786, lat: 39.754308, postalCode: "62601" },
    { lng: -90.12675, lat: 38.6151, postalCode: "62205" },
    { lng: -89.35828, lat: 42.236813, postalCode: "61077" },
    { lng: -89.82872, lat: 39.213822, postalCode: "62043" },
    { lng: -89.67132, lat: 39.370091, postalCode: "62572" },
    { lng: -90.00785, lat: 37.988745, postalCode: "62261" },
    { lng: -90.01888, lat: 40.929497, postalCode: "61489" },
    { lng: -90.15909, lat: 38.906065, postalCode: "62002" },
    { lng: -91.38474, lat: 40.09625, postalCode: "62376" },
    { lng: -87.73309, lat: 41.631683, postalCode: "60445" },
    { lng: -88.39026, lat: 40.653799, postalCode: "61775" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60161" },
    { lng: -90.96542, lat: 40.854247, postalCode: "61437" },
    { lng: -87.75531, lat: 41.422555, postalCode: "60449" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61630" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60567" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62722" },
    { lng: -89.2205, lat: 41.168946, postalCode: "61335" },
    { lng: -88.05462, lat: 42.410687, postalCode: "60046" },
    { lng: -89.32451, lat: 41.252101, postalCode: "61327" },
    { lng: -90.93288, lat: 40.223837, postalCode: "62311" },
    { lng: -89.70537, lat: 41.813776, postalCode: "61081" },
    { lng: -89.56654, lat: 40.817587, postalCode: "61552" },
    { lng: -89.021881, lat: 41.499089, postalCode: "61371" },
    { lng: -88.81584, lat: 39.984292, postalCode: "62501" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61654" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62765" },
    { lng: -89.56055, lat: 37.75858, postalCode: "62950" },
    { lng: -89.85054, lat: 38.544298, postalCode: "62225" },
    { lng: -87.96779, lat: 42.229856, postalCode: "60061" },
    { lng: -90.12098, lat: 42.472458, postalCode: "61001" },
    { lng: -89.20174, lat: 37.119739, postalCode: "62964" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60687" },
    { lng: -87.62255, lat: 41.695434, postalCode: "60628" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61518" },
    { lng: -90.05894, lat: 38.828347, postalCode: "62087" },
    { lng: -87.6404, lat: 41.776983, postalCode: "60621" },
    { lng: -89.65849, lat: 41.174037, postalCode: "61421" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62721" },
    { lng: -89.21536, lat: 41.865034, postalCode: "61006" },
    { lng: -90.41674, lat: 39.301561, postalCode: "62016" },
    { lng: -88.96776, lat: 40.145501, postalCode: "61727" },
    { lng: -89.23943, lat: 40.975736, postalCode: "61541" },
    { lng: -87.65114, lat: 41.875882, postalCode: "60607" },
    { lng: -87.86093, lat: 42.238087, postalCode: "60045" },
    { lng: -89.23655, lat: 41.264159, postalCode: "61326" },
    { lng: -90.08613, lat: 41.657803, postalCode: "61250" },
    { lng: -90.01241, lat: 38.91973, postalCode: "62067" },
    { lng: -89.06088, lat: 40.399689, postalCode: "61772" },
    { lng: -89.02992, lat: 37.477639, postalCode: "62912" },
    { lng: -88.876792, lat: 37.44725, postalCode: "62909" },
    { lng: -88.3731, lat: 40.463079, postalCode: "60936" },
    { lng: -88.5101, lat: 40.553838, postalCode: "61720" },
    { lng: -88.55792, lat: 41.168761, postalCode: "60437" },
    { lng: -87.96475, lat: 41.509231, postalCode: "60451" },
    { lng: -87.89234, lat: 42.008429, postalCode: "60018" },
    { lng: -88.82814, lat: 38.768452, postalCode: "62854" },
    { lng: -89.49298, lat: 39.130404, postalCode: "62089" },
    { lng: -87.79148, lat: 41.663548, postalCode: "60463" },
    { lng: -88.38028, lat: 40.584937, postalCode: "61773" },
    { lng: -89.13579, lat: 40.738973, postalCode: "61771" },
    { lng: -87.93517, lat: 42.375821, postalCode: "60031" },
    { lng: -89.415831, lat: 37.758646, postalCode: "62971" },
    { lng: -87.66927, lat: 41.900332, postalCode: "60622" },
    { lng: -90.35383, lat: 38.958848, postalCode: "62028" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61612" },
    { lng: -87.80805, lat: 41.922681, postalCode: "60707" },
    { lng: -89.15231, lat: 40.992084, postalCode: "61369" },
    { lng: -89.058643, lat: 37.803216, postalCode: "62648" },
    { lng: -90.50529, lat: 40.889288, postalCode: "61423" },
    { lng: -89.19419, lat: 40.795911, postalCode: "61561" },
    { lng: -89.41265, lat: 39.58755, postalCode: "62540" },
    { lng: -88.03433, lat: 42.338955, postalCode: "60030" },
    { lng: -87.72134, lat: 41.483844, postalCode: "60471" },
    { lng: -90.70671, lat: 41.31653, postalCode: "61279" },
    { lng: -89.78428, lat: 39.110422, postalCode: "62023" },
    { lng: -91.05902, lat: 40.778519, postalCode: "61425" },
    { lng: -88.91954, lat: 38.516025, postalCode: "62853" },
    { lng: -88.88791, lat: 40.740729, postalCode: "61744" },
    { lng: -91.22914, lat: 39.709855, postalCode: "62343" },
    { lng: -90.16643, lat: 38.6565, postalCode: "62059" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60678" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61131" },
    { lng: -87.75869, lat: 41.971044, postalCode: "60630" },
    { lng: -88.34177, lat: 41.684896, postalCode: "60543" },
    { lng: -88.473594, lat: 38.757302, postalCode: "62847" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60128" },
    { lng: -89.285172, lat: 40.21927, postalCode: "61751" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60159" },
    { lng: -87.610053, lat: 42.322814, postalCode: "60092" },
    { lng: -89.08366, lat: 38.804938, postalCode: "62892" },
    { lng: -88.71835, lat: 39.800512, postalCode: "61936" },
    { lng: -89.76667, lat: 39.503383, postalCode: "62690" },
    { lng: -90.02166, lat: 40.68847, postalCode: "61531" },
    { lng: -89.59084, lat: 41.989386, postalCode: "61064" },
    { lng: -90.31077, lat: 42.270426, postalCode: "61041" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60668" },
    { lng: -90.94976, lat: 39.988988, postalCode: "62324" },
    { lng: -90.428, lat: 41.439784, postalCode: "61240" },
    { lng: -90.42833, lat: 40.292388, postalCode: "61484" },
    { lng: -88.15303, lat: 42.372721, postalCode: "60041" },
    { lng: -89.29692, lat: 39.548928, postalCode: "62568" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61635" },
    { lng: -90.01763, lat: 38.636749, postalCode: "62232" },
    { lng: -87.9874, lat: 40.823527, postalCode: "60930" },
    { lng: -90.37946, lat: 40.740729, postalCode: "61474" },
    { lng: -89.18982, lat: 39.638951, postalCode: "62567" },
    { lng: -89.32013, lat: 40.431577, postalCode: "61759" },
    { lng: -91.09115, lat: 39.599949, postalCode: "62356" },
    { lng: -89.01776, lat: 42.254087, postalCode: "61108" },
    { lng: -88.17268, lat: 41.17569, postalCode: "60935" },
    { lng: -87.679408, lat: 42.04973, postalCode: "60209" },
    { lng: -89.37082, lat: 40.873236, postalCode: "61545" },
    { lng: -87.78882, lat: 42.041999, postalCode: "60053" },
    { lng: -88.60033, lat: 39.434148, postalCode: "61957" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62720" },
    { lng: -89.5305, lat: 38.620018, postalCode: "62230" },
    { lng: -88.17348, lat: 39.496547, postalCode: "61920" },
    { lng: -88.96635, lat: 40.240627, postalCode: "61777" },
    { lng: -89.66298, lat: 41.610151, postalCode: "61243" },
    { lng: -88.97084, lat: 42.034502, postalCode: "60129" },
    { lng: -87.94181, lat: 41.89103, postalCode: "60126" },
    { lng: -88.03291, lat: 38.160972, postalCode: "62827" },
    { lng: -88.89365, lat: 38.875494, postalCode: "62880" },
    { lng: -88.55317, lat: 41.598631, postalCode: "60536" },
    { lng: -88.76933, lat: 41.438531, postalCode: "60557" },
    { lng: -88.31957, lat: 41.885728, postalCode: "60134" },
    { lng: -87.6117, lat: 39.006924, postalCode: "62451" },
    { lng: -89.92116, lat: 41.719782, postalCode: "61261" },
    { lng: -89.617657, lat: 40.785653, postalCode: "61640" },
    { lng: -89.29707, lat: 39.993254, postalCode: "62548" },
    { lng: -88.63376, lat: 40.884053, postalCode: "61764" },
    { lng: -87.73545, lat: 42.005331, postalCode: "60712" },
    { lng: -89.11878, lat: 40.850381, postalCode: "61516" },
    { lng: -89.31217, lat: 37.42084, postalCode: "62952" },
    { lng: -89.170527, lat: 42.325364, postalCode: "61132" },
    { lng: -87.888971, lat: 41.146464, postalCode: "60944" },
    { lng: -89.95565, lat: 40.780415, postalCode: "61529" },
    { lng: -89.17901, lat: 41.25645, postalCode: "61363" },
    { lng: -87.85474, lat: 40.766096, postalCode: "60928" },
    { lng: -87.69684, lat: 42.056391, postalCode: "60201" },
    { lng: -88.09675, lat: 42.01299, postalCode: "60193" },
    { lng: -88.34792, lat: 39.59477, postalCode: "61931" },
    { lng: -89.66966, lat: 41.4547, postalCode: "61338" },
    { lng: -88.01827, lat: 41.80348, postalCode: "60515" },
    { lng: -89.00559, lat: 39.617542, postalCode: "62550" },
    { lng: -88.56383, lat: 39.012089, postalCode: "62473" },
    { lng: -89.25099, lat: 42.271385, postalCode: "61088" },
    { lng: -87.73137, lat: 40.710295, postalCode: "60974" },
    { lng: -91.35197, lat: 40.240004, postalCode: "62373" },
    { lng: -88.36389, lat: 39.945328, postalCode: "61872" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60674" },
    { lng: -88.09647, lat: 42.366253, postalCode: "60073" },
    { lng: -87.60839, lat: 41.570384, postalCode: "60476" },
    { lng: -87.894366, lat: 42.028779, postalCode: "60017" },
    { lng: -90.17764, lat: 40.499297, postalCode: "61427" },
    { lng: -89.99143, lat: 39.487357, postalCode: "62667" },
    { lng: -90.01821, lat: 41.409646, postalCode: "61235" },
    { lng: -88.01922, lat: 40.310063, postalCode: "61847" },
    { lng: -87.6603, lat: 40.372447, postalCode: "60963" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60670" },
    { lng: -88.452481, lat: 42.324761, postalCode: "60051" },
    { lng: -89.41267, lat: 41.770952, postalCode: "61324" },
    { lng: -90.142329, lat: 39.177478, postalCode: "62003" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60412" },
    { lng: -88.45183, lat: 41.779939, postalCode: "60554" },
    { lng: -89.718401, lat: 40.744179, postalCode: "61655" },
    { lng: -88.477739, lat: 40.810131, postalCode: "61901" },
    { lng: -89.28271, lat: 37.240381, postalCode: "62988" },
    { lng: -90.03285, lat: 40.490965, postalCode: "61524" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60026" },
    { lng: -87.74051, lat: 40.784447, postalCode: "60970" },
    { lng: -90.6289, lat: 39.884796, postalCode: "62378" },
    { lng: -88.29114, lat: 37.877628, postalCode: "62871" },
    { lng: -89.11963, lat: 38.60823, postalCode: "62882" },
    { lng: -88.77539, lat: 41.768498, postalCode: "60556" },
    { lng: -88.73327, lat: 37.18308, postalCode: "62960" },
    { lng: -88.43125, lat: 42.468318, postalCode: "60034" },
    { lng: -89.39012, lat: 39.77386, postalCode: "62545" },
    { lng: -89.72708, lat: 42.4447, postalCode: "61050" },
    { lng: -89.18974, lat: 37.025325, postalCode: "62914" },
    { lng: -89.57044, lat: 38.996375, postalCode: "62086" },
    { lng: -89.33932, lat: 42.00663, postalCode: "61061" },
    { lng: -87.65993, lat: 41.696433, postalCode: "60643" },
    { lng: -88.3126, lat: 42.04133, postalCode: "60121" },
    { lng: -91.14564, lat: 39.700245, postalCode: "62345" },
    { lng: -88.20502, lat: 41.891978, postalCode: "60185" },
    { lng: -89.15601, lat: 41.722744, postalCode: "61378" },
    { lng: -89.588747, lat: 40.692037, postalCode: "61629" },
    { lng: -88.05466, lat: 38.868463, postalCode: "62475" },
    { lng: -88.7778, lat: 40.877451, postalCode: "61743" },
    { lng: -88.14203, lat: 41.684752, postalCode: "60490" },
    { lng: -89.99407, lat: 38.679282, postalCode: "62234" },
    { lng: -89.415831, lat: 37.758646, postalCode: "62903" },
    { lng: -88.196102, lat: 40.139946, postalCode: "61825" },
    { lng: -89.74444, lat: 41.400837, postalCode: "61361" },
    { lng: -90.92743, lat: 41.092081, postalCode: "61442" },
    { lng: -87.6376, lat: 41.882582, postalCode: "60606" },
    { lng: -90.161761, lat: 40.449305, postalCode: "61553" },
    { lng: -90.13336, lat: 39.115848, postalCode: "62079" },
    { lng: -90.429294, lat: 40.288318, postalCode: "61584" },
    { lng: -88.96438, lat: 41.93171, postalCode: "60113" },
    { lng: -88.61017, lat: 41.320345, postalCode: "61360" },
    { lng: -88.67859, lat: 39.919693, postalCode: "61855" },
    { lng: -87.718, lat: 41.850232, postalCode: "60623" },
    { lng: -89.68831, lat: 39.909732, postalCode: "62625" },
    { lng: -88.53697, lat: 41.665654, postalCode: "60545" },
    { lng: -88.00583, lat: 38.527107, postalCode: "62476" },
    { lng: -89.08815, lat: 41.144329, postalCode: "61334" },
    { lng: -88.96134, lat: 39.018987, postalCode: "62418" },
    { lng: -87.97723, lat: 42.108428, postalCode: "60004" },
    { lng: -87.98464, lat: 42.069327, postalCode: "60005" },
    { lng: -91.16751, lat: 39.795818, postalCode: "62365" },
    { lng: -88.561428, lat: 37.333591, postalCode: "62944" },
    { lng: -87.66938, lat: 42.009731, postalCode: "60626" },
    { lng: -90.24177, lat: 41.069414, postalCode: "61467" },
    { lng: -88.1464, lat: 41.298186, postalCode: "60481" },
    { lng: -89.02583, lat: 37.774517, postalCode: "62933" },
    { lng: -87.88177, lat: 40.565374, postalCode: "60924" },
    { lng: -88.96888, lat: 38.441538, postalCode: "62830" },
    { lng: -87.80389, lat: 41.5738, postalCode: "60477" },
    { lng: -89.00407, lat: 42.419097, postalCode: "61073" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60679" },
    { lng: -88.80153, lat: 39.409126, postalCode: "62565" },
    { lng: -90.33999, lat: 39.481469, postalCode: "62082" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62705" },
    { lng: -89.59789, lat: 38.188624, postalCode: "62237" },
    { lng: -89.54493, lat: 40.683387, postalCode: "61611" },
    { lng: -88.88293, lat: 41.933061, postalCode: "60150" },
    { lng: -88.40479, lat: 40.756972, postalCode: "61741" },
    { lng: -90.14524, lat: 41.863049, postalCode: "61252" },
    { lng: -88.82344, lat: 37.780592, postalCode: "62974" },
    { lng: -88.04663, lat: 38.032574, postalCode: "62861" },
    { lng: -89.73278, lat: 39.346468, postalCode: "62511" },
    { lng: -89.20301, lat: 37.82526, postalCode: "62924" },
    { lng: -89.34453, lat: 42.307665, postalCode: "61063" },
    { lng: -87.6371, lat: 41.898582, postalCode: "60610" },
    { lng: -89.80626, lat: 39.078047, postalCode: "62085" },
    { lng: -89.04788, lat: 41.057649, postalCode: "61377" },
    { lng: -88.01806, lat: 39.655063, postalCode: "61943" },
    { lng: -91.40638, lat: 40.314446, postalCode: "62379" },
    { lng: -88.68704, lat: 41.990505, postalCode: "60178" },
    { lng: -89.34345, lat: 40.533634, postalCode: "61755" },
    { lng: -90.163909, lat: 38.6516, postalCode: "62071" },
    { lng: -89.616648, lat: 40.696287, postalCode: "61625" },
    { lng: -87.86891, lat: 41.170442, postalCode: "60914" },
    { lng: -89.589847, lat: 40.693137, postalCode: "61601" },
    { lng: -88.99592, lat: 40.477286, postalCode: "61701" },
    { lng: -87.65542, lat: 38.596277, postalCode: "62460" },
    { lng: -87.7321, lat: 42.03618, postalCode: "60076" },
    { lng: -88.88696, lat: 40.574936, postalCode: "61776" },
    { lng: -88.02546, lat: 42.143819, postalCode: "60074" },
    { lng: -88.25023, lat: 40.061694, postalCode: "61874" },
    { lng: -87.75356, lat: 41.605633, postalCode: "60452" },
    { lng: -87.98929, lat: 40.767194, postalCode: "60938" },
    { lng: -89.44315, lat: 41.372528, postalCode: "61356" },
    { lng: -88.54865, lat: 41.75537, postalCode: "60511" },
    { lng: -89.03978, lat: 40.731662, postalCode: "61738" },
    { lng: -90.39738, lat: 42.400744, postalCode: "61036" },
    { lng: -88.23716, lat: 38.696562, postalCode: "62868" },
    { lng: -88.452481, lat: 42.324761, postalCode: "60039" },
    { lng: -87.80808, lat: 42.21196, postalCode: "60037" },
    { lng: -88.224669, lat: 40.10593, postalCode: "61803" },
    { lng: -88.941665, lat: 37.994296, postalCode: "62840" },
    { lng: -88.54829, lat: 42.052112, postalCode: "60109" },
    { lng: -88.11963, lat: 41.54338, postalCode: "60435" },
    { lng: -89.56744, lat: 40.088774, postalCode: "62666" },
    { lng: -89.54209, lat: 39.956803, postalCode: "62693" },
    { lng: -89.2864, lat: 39.299771, postalCode: "62075" },
    { lng: -88.244487, lat: 39.374287, postalCode: "62435" },
    { lng: -89.348163, lat: 40.928331, postalCode: "61576" },
    { lng: -87.6629, lat: 41.953256, postalCode: "60613" },
    { lng: -90.00227, lat: 38.596199, postalCode: "62208" },
    { lng: -88.014072, lat: 42.097976, postalCode: "60055" },
    { lng: -88.58141, lat: 37.501552, postalCode: "62928" },
    { lng: -88.52548, lat: 40.430727, postalCode: "61770" },
    { lng: -88.73999, lat: 38.218349, postalCode: "62810" },
    { lng: -88.00203, lat: 39.91613, postalCode: "61816" },
    { lng: -88.62403, lat: 39.2807, postalCode: "62463" },
    { lng: -88.276047, lat: 42.442519, postalCode: "60080" },
    { lng: -89.54251, lat: 40.369256, postalCode: "61734" },
    { lng: -89.67691, lat: 38.611798, postalCode: "62293" },
    { lng: -89.40159, lat: 39.936817, postalCode: "62519" },
    { lng: -89.78689, lat: 39.350726, postalCode: "62686" },
    { lng: -89.50492, lat: 41.279825, postalCode: "61368" },
    { lng: -89.43987, lat: 37.508233, postalCode: "62998" },
    { lng: -88.97972, lat: 39.777583, postalCode: "62532" },
    { lng: -87.84455, lat: 41.880281, postalCode: "60153" },
    { lng: -87.85978, lat: 41.89988, postalCode: "60160" },
    { lng: -89.60321, lat: 41.369056, postalCode: "61379" },
    { lng: -88.00353, lat: 38.257064, postalCode: "62844" },
    { lng: -89.08974, lat: 41.347117, postalCode: "61301" },
    { lng: -87.610053, lat: 42.322814, postalCode: "60079" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62791" },
    { lng: -89.55501, lat: 37.933269, postalCode: "62916" },
    { lng: -88.49714, lat: 38.578119, postalCode: "62878" },
    { lng: -87.888971, lat: 41.146464, postalCode: "60902" },
    { lng: -89.21408, lat: 37.457806, postalCode: "62906" },
    { lng: -90.88699, lat: 40.304799, postalCode: "62367" },
    { lng: -88.92407, lat: 38.000245, postalCode: "62812" },
    { lng: -90.10008, lat: 38.631383, postalCode: "62204" },
    { lng: -90.615975, lat: 41.554901, postalCode: "61206" },
    { lng: -90.20775, lat: 38.442906, postalCode: "62236" },
    { lng: -89.77538, lat: 38.78956, postalCode: "62061" },
    { lng: -88.74383, lat: 39.639487, postalCode: "61914" },
    { lng: -89.55466, lat: 42.403305, postalCode: "61018" },
    { lng: -87.776595, lat: 42.43335, postalCode: "60086" },
    { lng: -88.46429, lat: 41.876506, postalCode: "60119" },
    { lng: -89.08194, lat: 39.3928, postalCode: "62557" },
    { lng: -88.84304, lat: 37.20812, postalCode: "62953" },
    { lng: -88.45309, lat: 39.809929, postalCode: "61913" },
    { lng: -88.30284, lat: 39.676634, postalCode: "61910" },
    { lng: -89.04867, lat: 38.335675, postalCode: "62898" },
    { lng: -87.61173, lat: 41.439034, postalCode: "60417" },
    { lng: -88.95327, lat: 39.844086, postalCode: "62523" },
    { lng: -87.60544, lat: 41.779384, postalCode: "60637" },
    { lng: -90.043, lat: 38.912297, postalCode: "62010" },
    { lng: -90.21218, lat: 39.952909, postalCode: "62691" },
    { lng: -91.11577, lat: 40.414128, postalCode: "62321" },
    { lng: -89.35236, lat: 39.249272, postalCode: "62094" },
    { lng: -87.87468, lat: 41.93548, postalCode: "60131" },
    { lng: -88.32972, lat: 41.721886, postalCode: "60538" },
    { lng: -89.55969, lat: 39.212833, postalCode: "62015" },
    { lng: -87.79265, lat: 41.688827, postalCode: "60482" },
    { lng: -87.61611, lat: 40.968579, postalCode: "60912" },
    { lng: -90.13276, lat: 38.59035, postalCode: "62207" },
    { lng: -89.46426, lat: 38.535097, postalCode: "62218" },
    { lng: -88.30995, lat: 41.845228, postalCode: "60510" },
    { lng: -87.57072, lat: 41.761734, postalCode: "60649" },
    { lng: -88.20898, lat: 41.439518, postalCode: "60410" },
    { lng: -89.00569, lat: 39.840482, postalCode: "62522" },
    { lng: -88.941665, lat: 37.994296, postalCode: "62856" },
    { lng: -87.7601, lat: 41.995331, postalCode: "60646" },
    { lng: -91.205805, lat: 40.416145, postalCode: "62310" },
    { lng: -88.88076, lat: 37.308412, postalCode: "62908" },
    { lng: -89.27132, lat: 38.931719, postalCode: "62262" },
    { lng: -89.27351, lat: 37.150263, postalCode: "62993" },
    { lng: -88.35141, lat: 38.689663, postalCode: "62824" },
    { lng: -90.22101, lat: 39.627307, postalCode: "62695" },
    { lng: -88.09218, lat: 38.830182, postalCode: "62425" },
    { lng: -89.26294, lat: 38.540238, postalCode: "62250" },
    { lng: -87.89016, lat: 40.439688, postalCode: "60960" },
    { lng: -89.569207, lat: 40.765301, postalCode: "61644" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60673" },
    { lng: -87.96347, lat: 40.149486, postalCode: "61859" },
    { lng: -89.599529, lat: 41.797256, postalCode: "61058" },
    { lng: -88.84075, lat: 40.52835, postalCode: "61758" },
    { lng: -89.291549, lat: 38.601768, postalCode: "62252" },
    { lng: -89.31176, lat: 41.84036, postalCode: "61031" },
    { lng: -87.86871, lat: 40.126299, postalCode: "61844" },
    { lng: -89.713921, lat: 38.363228, postalCode: "62282" },
    { lng: -88.30419, lat: 37.971434, postalCode: "62869" },
    { lng: -87.74657, lat: 40.121098, postalCode: "61858" },
    { lng: -89.26637, lat: 42.131245, postalCode: "61010" },
    { lng: -88.92647, lat: 37.820724, postalCode: "62951" },
    { lng: -91.05207, lat: 40.685436, postalCode: "61454" },
    { lng: -88.65279, lat: 40.251945, postalCode: "61842" },
    { lng: -87.63579, lat: 41.147918, postalCode: "60954" },
    { lng: -89.63208, lat: 42.300918, postalCode: "61032" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60597" },
    { lng: -88.66836, lat: 41.064946, postalCode: "61313" },
    { lng: -88.79575, lat: 40.193547, postalCode: "61735" },
    { lng: -88.17649, lat: 40.121648, postalCode: "61802" },
    { lng: -88.4135, lat: 40.376141, postalCode: "61845" },
    { lng: -89.59662, lat: 42.199457, postalCode: "61007" },
    { lng: -89.75896, lat: 41.965904, postalCode: "61051" },
    { lng: -87.90103, lat: 41.805531, postalCode: "60558" },
    { lng: -88.05592, lat: 41.533631, postalCode: "60432" },
    { lng: -89.6448, lat: 40.764368, postalCode: "61615" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62762" },
    { lng: -87.70249, lat: 41.719933, postalCode: "60805" },
    { lng: -88.86858, lat: 41.769322, postalCode: "60550" },
    { lng: -90.71957, lat: 41.440852, postalCode: "61232" },
    { lng: -90.21617, lat: 38.535325, postalCode: "62240" },
    { lng: -88.03401, lat: 40.124202, postalCode: "61873" },
    { lng: -89.25013, lat: 40.256893, postalCode: "61723" },
    { lng: -87.67857, lat: 41.574034, postalCode: "60429" },
    { lng: -89.589797, lat: 40.702487, postalCode: "61637" },
    { lng: -89.586356, lat: 39.806089, postalCode: "62708" },
    { lng: -91.19727, lat: 40.233504, postalCode: "62380" },
    { lng: -89.48077, lat: 37.629601, postalCode: "62942" },
    { lng: -87.87193, lat: 41.95683, postalCode: "60176" },
    { lng: -88.17822, lat: 42.409445, postalCode: "60020" },
    { lng: -87.818672, lat: 38.403424, postalCode: "62855" },
    { lng: -89.36622, lat: 41.427282, postalCode: "61337" },
    { lng: -87.78418, lat: 40.2159, postalCode: "61831" },
    { lng: -87.66264, lat: 41.609078, postalCode: "60426" },
    { lng: -88.941665, lat: 37.994296, postalCode: "62805" },
    { lng: -88.25176, lat: 41.953427, postalCode: "60184" },
    { lng: -89.19993, lat: 39.354674, postalCode: "62083" },
    { lng: -89.761, lat: 39.539071, postalCode: "62689" },
    { lng: -89.03177, lat: 37.34, postalCode: "62923" },
    { lng: -88.01723, lat: 41.875429, postalCode: "60148" },
    { lng: -89.606017, lat: 39.749457, postalCode: "62781" },
    { lng: -88.14862, lat: 39.139262, postalCode: "62432" },
    { lng: -89.77628, lat: 41.605825, postalCode: "61283" },
    { lng: -89.02702, lat: 37.804014, postalCode: "62948" },
    { lng: -87.57423, lat: 40.762507, postalCode: "60966" },
    { lng: -91.02632, lat: 40.112726, postalCode: "62339" },
    { lng: -89.3086, lat: 41.321814, postalCode: "61322" },
    { lng: -90.46831, lat: 40.04195, postalCode: "62639" },
    { lng: -89.128871, lat: 40.582108, postalCode: "61675" },
    { lng: -89.65429, lat: 40.999497, postalCode: "61479" },
    { lng: -89.93485, lat: 38.092615, postalCode: "62242" },
    { lng: -87.88351, lat: 41.851257, postalCode: "60154" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60522" },
    { lng: -88.21304, lat: 40.908942, postalCode: "60946" },
    { lng: -89.83571, lat: 42.250987, postalCode: "61062" },
    { lng: -90.39332, lat: 39.883346, postalCode: "62611" },
    { lng: -89.65467, lat: 39.568077, postalCode: "62530" },
    { lng: -89.98979, lat: 42.491388, postalCode: "61087" },
    { lng: -89.82389, lat: 40.35756, postalCode: "61532" },
    { lng: -90.095368, lat: 40.466273, postalCode: "61819" },
    { lng: -88.82763, lat: 38.096551, postalCode: "62836" },
    { lng: -91.20483, lat: 40.319528, postalCode: "62313" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60078" },
    { lng: -90.09831, lat: 38.463718, postalCode: "62260" },
    { lng: -89.19145, lat: 38.319427, postalCode: "62808" },
    { lng: -89.36368, lat: 38.623384, postalCode: "62231" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60116" },
    { lng: -89.1299, lat: 41.330789, postalCode: "61354" },
    { lng: -90.41808, lat: 40.503305, postalCode: "61459" },
    { lng: -90.8834, lat: 40.403553, postalCode: "62374" },
    { lng: -87.81077, lat: 38.415676, postalCode: "62863" },
    { lng: -90.32677, lat: 39.544196, postalCode: "62663" },
    { lng: -90.41404, lat: 41.501234, postalCode: "61282" },
    { lng: -90.12141, lat: 40.952695, postalCode: "61428" },
    { lng: -87.98258, lat: 41.676028, postalCode: "60439" },
    { lng: -87.90649, lat: 41.005603, postalCode: "60922" },
    { lng: -88.96661, lat: 37.558235, postalCode: "62939" },
    { lng: -88.19736, lat: 41.607624, postalCode: "60544" },
    { lng: -87.69634, lat: 42.008956, postalCode: "60645" },
    { lng: -91.00612, lat: 41.413372, postalCode: "52761" },
    { lng: -90.35255, lat: 40.153557, postalCode: "62624" },
    { lng: -88.43531, lat: 38.51684, postalCode: "62823" },
    { lng: -88.87665, lat: 42.100571, postalCode: "60146" },
    { lng: -88.3742, lat: 40.313921, postalCode: "61843" },
    { lng: -89.81317, lat: 38.601648, postalCode: "62254" },
    { lng: -88.420178, lat: 41.936977, postalCode: "60144" },
    { lng: -87.75671, lat: 41.881331, postalCode: "60644" },
    { lng: -87.68694, lat: 41.628034, postalCode: "60469" },
    { lng: -89.97442, lat: 38.75592, postalCode: "62034" },
    { lng: -89.07807, lat: 41.710715, postalCode: "61318" },
    { lng: -89.19244, lat: 38.388876, postalCode: "62877" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60684" },
    { lng: -87.6667, lat: 41.990631, postalCode: "60660" },
    { lng: -88.088716, lat: 41.839679, postalCode: "60570" },
    { lng: -88.30609, lat: 41.99215, postalCode: "60177" },
    { lng: -89.1087, lat: 40.242378, postalCode: "61778" },
    { lng: -90.40987, lat: 41.554666, postalCode: "61256" },
    { lng: -88.62298, lat: 37.836537, postalCode: "62935" },
    { lng: -87.70085, lat: 41.921126, postalCode: "60647" },
    { lng: -87.98108, lat: 41.745623, postalCode: "60561" },
    { lng: -88.27494, lat: 41.173313, postalCode: "60474" },
    { lng: -89.42614, lat: 40.422027, postalCode: "61747" },
    { lng: -87.77022, lat: 41.744082, postalCode: "60459" },
    { lng: -88.65284, lat: 37.692923, postalCode: "62917" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60303" },
    { lng: -87.81686, lat: 41.96418, postalCode: "60706" },
    { lng: -87.68732, lat: 41.811929, postalCode: "60699" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47732" },
    { lng: -87.319937, lat: 41.590686, postalCode: "46401" },
    { lng: -87.46084, lat: 41.639735, postalCode: "46312" },
    { lng: -87.42458, lat: 41.527235, postalCode: "46319" },
    { lng: -87.42319, lat: 38.215226, postalCode: "47647" },
    { lng: -87.26403, lat: 41.533786, postalCode: "46342" },
    { lng: -87.24265, lat: 41.179017, postalCode: "46310" },
    { lng: -86.70041, lat: 41.526628, postalCode: "46345" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47739" },
    { lng: -87.37998, lat: 37.958873, postalCode: "47630" },
    { lng: -87.50574, lat: 41.610035, postalCode: "46320" },
    { lng: -87.372779, lat: 41.461543, postalCode: "46325" },
    { lng: -87.50015, lat: 41.679185, postalCode: "46394" },
    { lng: -87.57478, lat: 38.34841, postalCode: "47670" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47703" },
    { lng: -86.87244, lat: 41.316401, postalCode: "46348" },
    { lng: -87.44607, lat: 40.77759, postalCode: "47951" },
    { lng: -87.50143, lat: 41.551457, postalCode: "46321" },
    { lng: -87.26447, lat: 41.570086, postalCode: "46405" },
    { lng: -86.91068, lat: 41.550192, postalCode: "46391" },
    { lng: -87.13684, lat: 40.962747, postalCode: "47978" },
    { lng: -87.527249, lat: 38.110995, postalCode: "47729" },
    { lng: -87.597316, lat: 37.977492, postalCode: "47744" },
    { lng: -87.58462, lat: 38.411923, postalCode: "47666" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47702" },
    { lng: -87.42886, lat: 38.170645, postalCode: "47613" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47716" },
    { lng: -86.95436, lat: 37.965531, postalCode: "47615" },
    { lng: -87.35899, lat: 40.8685, postalCode: "47922" },
    { lng: -87.4519, lat: 41.492085, postalCode: "46375" },
    { lng: -87.716121, lat: 38.187432, postalCode: "47672" },
    { lng: -87.09771, lat: 37.883451, postalCode: "47635" },
    { lng: -86.709883, lat: 41.498992, postalCode: "46352" },
    { lng: -87.28615, lat: 40.773474, postalCode: "47948" },
    { lng: -87.78055, lat: 38.081681, postalCode: "47638" },
    { lng: -87.05883, lat: 41.616263, postalCode: "46304" },
    { lng: -87.51022, lat: 41.491377, postalCode: "46311" },
    { lng: -86.99567, lat: 38.118547, postalCode: "47552" },
    { lng: -87.37714, lat: 38.278452, postalCode: "47683" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47734" },
    { lng: -87.26579, lat: 38.05732, postalCode: "47601" },
    { lng: -87.249886, lat: 37.903557, postalCode: "47617" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47724" },
    { lng: -86.83649, lat: 38.112396, postalCode: "47536" },
    { lng: -86.71983, lat: 41.605749, postalCode: "46350" },
    { lng: -86.917205, lat: 38.16644, postalCode: "47556" },
    { lng: -87.27081, lat: 41.359415, postalCode: "46355" },
    { lng: -87.245214, lat: 38.06242, postalCode: "47629" },
    { lng: -87.04073, lat: 38.099889, postalCode: "47537" },
    { lng: -87.372779, lat: 41.461543, postalCode: "46411" },
    { lng: -87.14259, lat: 38.132339, postalCode: "47637" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47618" },
    { lng: -87.17829, lat: 41.510333, postalCode: "46393" },
    { lng: -87.43686, lat: 41.111996, postalCode: "46349" },
    { lng: -87.39205, lat: 38.25312, postalCode: "47654" },
    { lng: -87.47506, lat: 37.975476, postalCode: "47715" },
    { lng: -87.05408, lat: 38.022045, postalCode: "47611" },
    { lng: -87.44275, lat: 38.343295, postalCode: "47649" },
    { lng: -87.3581, lat: 41.542536, postalCode: "46408" },
    { lng: -87.91918, lat: 38.117974, postalCode: "47631" },
    { lng: -87.05359, lat: 41.186662, postalCode: "46392" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47701" },
    { lng: -87.076094, lat: 41.490545, postalCode: "46384" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47731" },
    { lng: -86.53889, lat: 41.598294, postalCode: "46365" },
    { lng: -87.600791, lat: 37.978035, postalCode: "47721" },
    { lng: -87.26193, lat: 41.074738, postalCode: "47943" },
    { lng: -86.80978, lat: 38.160168, postalCode: "47577" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47730" },
    { lng: -87.46426, lat: 41.176325, postalCode: "46376" },
    { lng: -87.45528, lat: 41.587718, postalCode: "46323" },
    { lng: -87.57349, lat: 37.974642, postalCode: "47708" },
    { lng: -87.34298, lat: 41.418873, postalCode: "46307" },
    { lng: -86.77328, lat: 41.479916, postalCode: "46382" },
    { lng: -87.34024, lat: 41.487369, postalCode: "46410" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47728" },
    { lng: -86.79698, lat: 38.012451, postalCode: "47588" },
    { lng: -87.43781, lat: 41.167119, postalCode: "46379" },
    { lng: -86.88131, lat: 41.420078, postalCode: "46390" },
    { lng: -86.99889, lat: 38.170876, postalCode: "47523" },
    { lng: -86.91993, lat: 38.115573, postalCode: "47579" },
    { lng: -86.968614, lat: 41.194816, postalCode: "46380" },
    { lng: -87.25695, lat: 41.604654, postalCode: "46403" },
    { lng: -87.19167, lat: 37.93889, postalCode: "47634" },
    { lng: -87.92435, lat: 38.218814, postalCode: "47616" },
    { lng: -87.62723, lat: 38.045951, postalCode: "47720" },
    { lng: -86.76103, lat: 41.397147, postalCode: "46340" },
    { lng: -87.31132, lat: 38.199662, postalCode: "47619" },
    { lng: -86.82196, lat: 38.056909, postalCode: "47531" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47705" },
    { lng: -87.39041, lat: 38.047188, postalCode: "47610" },
    { lng: -87.626938, lat: 37.975102, postalCode: "47412" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47735" },
    { lng: -87.44849, lat: 41.36959, postalCode: "46303" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47740" },
    { lng: -87.56035, lat: 38.240456, postalCode: "47648" },
    { lng: -87.57621, lat: 37.999542, postalCode: "47710" },
    { lng: -87.52112, lat: 38.096771, postalCode: "47725" },
    { lng: -86.60481, lat: 41.677087, postalCode: "46371" },
    { lng: -87.33142, lat: 41.170584, postalCode: "46381" },
    { lng: -87.37271, lat: 41.588636, postalCode: "46404" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47741" },
    { lng: -87.03165, lat: 41.47339, postalCode: "46383" },
    { lng: -87.54178, lat: 38.000442, postalCode: "47711" },
    { lng: -87.44715, lat: 40.960029, postalCode: "47963" },
    { lng: -87.372779, lat: 41.461543, postalCode: "46308" },
    { lng: -87.692037, lat: 38.27312, postalCode: "47667" },
    { lng: -86.9797, lat: 41.687414, postalCode: "46301" },
    { lng: -86.92258, lat: 38.077016, postalCode: "47550" },
    { lng: -86.87902, lat: 41.707539, postalCode: "46360" },
    { lng: -87.47404, lat: 41.44862, postalCode: "46373" },
    { lng: -87.34695, lat: 41.191665, postalCode: "46377" },
    { lng: -87.322049, lat: 41.143358, postalCode: "46372" },
    { lng: -87.63664, lat: 37.966292, postalCode: "47712" },
    { lng: -87.88918, lat: 37.940511, postalCode: "47620" },
    { lng: -87.50391, lat: 41.583535, postalCode: "46324" },
    { lng: -87.20898, lat: 41.32374, postalCode: "46341" },
    { lng: -87.50911, lat: 41.634385, postalCode: "46327" },
    { lng: -87.538915, lat: 38.031942, postalCode: "47727" },
    { lng: -87.130431, lat: 41.354186, postalCode: "46302" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47736" },
    { lng: -87.29837, lat: 40.952608, postalCode: "47964" },
    { lng: -87.33646, lat: 41.580469, postalCode: "46407" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47747" },
    { lng: -87.18154, lat: 41.58327, postalCode: "46368" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47706" },
    { lng: -87.7024, lat: 38.190517, postalCode: "47612" },
    { lng: -87.11269, lat: 41.484652, postalCode: "46385" },
    { lng: -87.40962, lat: 41.589135, postalCode: "46406" },
    { lng: -87.170527, lat: 41.428873, postalCode: "46386" },
    { lng: -87.542014, lat: 37.970192, postalCode: "47722" },
    { lng: -86.915077, lat: 41.70354, postalCode: "46361" },
    { lng: -87.45806, lat: 41.549851, postalCode: "46322" },
    { lng: -87.78113, lat: 38.168191, postalCode: "47633" },
    { lng: -87.01522, lat: 41.305178, postalCode: "46347" },
    { lng: -87.505512, lat: 37.962292, postalCode: "47750" },
    { lng: -87.33681, lat: 41.601086, postalCode: "46402" },
    { lng: -86.6925, lat: 41.479757, postalCode: "46346" },
    { lng: -87.163636, lat: 38.130034, postalCode: "47614" },
    { lng: -87.72954, lat: 38.287411, postalCode: "47665" },
    { lng: -87.5039, lat: 38.484278, postalCode: "47640" },
    { lng: -87.15306, lat: 40.763755, postalCode: "47977" },
    { lng: -87.5658, lat: 38.187983, postalCode: "47639" },
    { lng: -87.41353, lat: 41.269789, postalCode: "46356" },
    { lng: -87.55796, lat: 37.962542, postalCode: "47713" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47733" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47704" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47719" },
    { lng: -87.32716, lat: 41.544736, postalCode: "46409" },
    { lng: -87.574963, lat: 37.997128, postalCode: "47737" },
    { lng: -87.34145, lat: 38.323439, postalCode: "47660" },
    { lng: -87.52468, lat: 37.959092, postalCode: "47714" },
    { lng: -99.72471, lat: 39.891365, postalCode: "67622" },
    { lng: -99.43134, lat: 37.918313, postalCode: "67547" },
    { lng: -100.3861, lat: 39.356622, postalCode: "67740" },
    { lng: -95.17473, lat: 38.088951, postalCode: "66039" },
    { lng: -97.12005, lat: 38.689794, postalCode: "67451" },
    { lng: -97.05274, lat: 38.596837, postalCode: "67475" },
    { lng: -94.852061, lat: 38.658068, postalCode: "66036" },
    { lng: -97.287134, lat: 37.542182, postalCode: "67236" },
    { lng: -95.6979, lat: 39.0161, postalCode: "66611" },
    { lng: -97.21973, lat: 38.94112, postalCode: "67410" },
    { lng: -95.72898, lat: 37.216856, postalCode: "67301" },
    { lng: -94.90453, lat: 39.065703, postalCode: "66012" },
    { lng: -99.26298, lat: 38.445884, postalCode: "67575" },
    { lng: -95.71088, lat: 39.058177, postalCode: "66606" },
    { lng: -95.743835, lat: 37.559151, postalCode: "66727" },
    { lng: -98.04227, lat: 39.936773, postalCode: "66970" },
    { lng: -99.1815, lat: 38.704467, postalCode: "67660" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66282" },
    { lng: -97.51114, lat: 38.891137, postalCode: "67470" },
    { lng: -99.1437, lat: 39.644576, postalCode: "67644" },
    { lng: -100.62302, lat: 38.591591, postalCode: "67850" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66222" },
    { lng: -95.00432, lat: 37.364275, postalCode: "66753" },
    { lng: -98.3031, lat: 39.503154, postalCode: "67446" },
    { lng: -97.46797, lat: 37.775593, postalCode: "67101" },
    { lng: -100.75208, lat: 37.23521, postalCode: "67859" },
    { lng: -96.95611, lat: 38.473549, postalCode: "66858" },
    { lng: -94.64136, lat: 38.925159, postalCode: "66211" },
    { lng: -100.93476, lat: 38.48461, postalCode: "67871" },
    { lng: -99.56002, lat: 39.823946, postalCode: "67664" },
    { lng: -101.20603, lat: 38.942421, postalCode: "67764" },
    { lng: -96.53372, lat: 37.660135, postalCode: "67012" },
    { lng: -99.23229, lat: 37.876262, postalCode: "67552" },
    { lng: -98.56205, lat: 38.347872, postalCode: "67526" },
    { lng: -97.944818, lat: 38.362379, postalCode: "67419" },
    { lng: -96.03394, lat: 39.655741, postalCode: "66417" },
    { lng: -100.20732, lat: 39.907372, postalCode: "67653" },
    { lng: -98.78495, lat: 38.363151, postalCode: "67530" },
    { lng: -94.70414, lat: 37.408511, postalCode: "66762" },
    { lng: -97.02831, lat: 37.067387, postalCode: "67005" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66642" },
    { lng: -97.171098, lat: 37.774174, postalCode: "67228" },
    { lng: -96.28476, lat: 39.694315, postalCode: "66544" },
    { lng: -98.70904, lat: 38.86913, postalCode: "67626" },
    { lng: -100.46085, lat: 38.882722, postalCode: "67736" },
    { lng: -95.233817, lat: 39.773145, postalCode: "66081" },
    { lng: -99.88021, lat: 37.805301, postalCode: "67882" },
    { lng: -99.52231, lat: 38.589291, postalCode: "67556" },
    { lng: -98.1392, lat: 38.527035, postalCode: "67444" },
    { lng: -100.43776, lat: 38.514924, postalCode: "67839" },
    { lng: -96.9568, lat: 38.807138, postalCode: "67492" },
    { lng: -94.704133, lat: 37.16944, postalCode: "66728" },
    { lng: -95.32501, lat: 39.214825, postalCode: "66066" },
    { lng: -95.24608, lat: 39.328743, postalCode: "66097" },
    { lng: -99.91904, lat: 39.609655, postalCode: "67645" },
    { lng: -95.82627, lat: 38.427161, postalCode: "66856" },
    { lng: -99.73853, lat: 38.134219, postalCode: "67849" },
    { lng: -98.37544, lat: 37.088979, postalCode: "67061" },
    { lng: -95.55745, lat: 38.935058, postalCode: "66409" },
    { lng: -97.43368, lat: 38.142541, postalCode: "67062" },
    { lng: -97.55569, lat: 37.26475, postalCode: "67103" },
    { lng: -98.83859, lat: 37.316336, postalCode: "67071" },
    { lng: -99.68699, lat: 38.400425, postalCode: "67516" },
    { lng: -98.37397, lat: 38.360414, postalCode: "67524" },
    { lng: -94.95144, lat: 37.66749, postalCode: "66746" },
    { lng: -96.98906, lat: 39.968115, postalCode: "66946" },
    { lng: -100.35656, lat: 39.116982, postalCode: "67751" },
    { lng: -101.5325, lat: 37.974098, postalCode: "67857" },
    { lng: -97.67719, lat: 38.367298, postalCode: "67460" },
    { lng: -95.71842, lat: 37.327608, postalCode: "67363" },
    { lng: -95.55336, lat: 39.020437, postalCode: "66542" },
    { lng: -97.171372, lat: 37.639491, postalCode: "67232" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66647" },
    { lng: -94.82712, lat: 37.616393, postalCode: "66734" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66285" },
    { lng: -99.692418, lat: 37.513348, postalCode: "67858" },
    { lng: -95.56255, lat: 37.900385, postalCode: "66761" },
    { lng: -94.71231, lat: 38.865825, postalCode: "66221" },
    { lng: -97.59811, lat: 38.674468, postalCode: "67416" },
    { lng: -101.3533, lat: 39.424782, postalCode: "67732" },
    { lng: -97.10839, lat: 37.818158, postalCode: "67017" },
    { lng: -97.39924, lat: 38.950725, postalCode: "67480" },
    { lng: -97.31917, lat: 37.667175, postalCode: "67211" },
    { lng: -95.2668, lat: 38.398831, postalCode: "66080" },
    { lng: -95.95263, lat: 39.143168, postalCode: "66533" },
    { lng: -97.12421, lat: 37.573226, postalCode: "67133" },
    { lng: -97.11391, lat: 38.894257, postalCode: "67441" },
    { lng: -98.41429, lat: 37.253964, postalCode: "67138" },
    { lng: -101.36332, lat: 39.792736, postalCode: "67745" },
    { lng: -95.79016, lat: 38.981849, postalCode: "66610" },
    { lng: -95.68096, lat: 39.747135, postalCode: "66527" },
    { lng: -95.683, lat: 37.420016, postalCode: "66757" },
    { lng: -99.10017, lat: 37.947324, postalCode: "67519" },
    { lng: -98.07736, lat: 39.440546, postalCode: "67420" },
    { lng: -95.27417, lat: 39.720911, postalCode: "66017" },
    { lng: -94.67528, lat: 38.992255, postalCode: "66204" },
    { lng: -96.83653, lat: 39.01585, postalCode: "66441" },
    { lng: -97.871147, lat: 37.94119, postalCode: "67585" },
    { lng: -95.44709, lat: 38.612869, postalCode: "66076" },
    { lng: -98.49342, lat: 37.037218, postalCode: "67070" },
    { lng: -96.26985, lat: 39.877691, postalCode: "66403" },
    { lng: -94.82117, lat: 38.964728, postalCode: "66220" },
    { lng: -97.42125, lat: 39.934395, postalCode: "66960" },
    { lng: -97.258997, lat: 37.651974, postalCode: "67201" },
    { lng: -94.94259, lat: 39.145077, postalCode: "66007" },
    { lng: -97.62031, lat: 37.538257, postalCode: "67149" },
    { lng: -97.06111, lat: 39.815522, postalCode: "66968" },
    { lng: -101.1109, lat: 39.810204, postalCode: "67730" },
    { lng: -97.12193, lat: 37.963712, postalCode: "67154" },
    { lng: -95.93171, lat: 37.279439, postalCode: "67344" },
    { lng: -97.33059, lat: 37.853935, postalCode: "67147" },
    { lng: -97.87357, lat: 37.298982, postalCode: "67036" },
    { lng: -99.90598, lat: 38.660733, postalCode: "67572" },
    { lng: -95.43138, lat: 39.025994, postalCode: "66050" },
    { lng: -97.31611, lat: 38.376681, postalCode: "67073" },
    { lng: -95.50973, lat: 38.21086, postalCode: "66093" },
    { lng: -94.87824, lat: 39.756944, postalCode: "66024" },
    { lng: -95.31219, lat: 38.920649, postalCode: "66047" },
    { lng: -96.16635, lat: 38.126728, postalCode: "66860" },
    { lng: -99.68559, lat: 39.360896, postalCode: "67625" },
    { lng: -97.1049, lat: 38.175115, postalCode: "66866" },
    { lng: -94.79019, lat: 39.076554, postalCode: "66111" },
    { lng: -96.83152, lat: 37.454925, postalCode: "67008" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66652" },
    { lng: -96.92129, lat: 38.677306, postalCode: "67449" },
    { lng: -99.04195, lat: 38.442368, postalCode: "67511" },
    { lng: -94.78107, lat: 39.021629, postalCode: "66217" },
    { lng: -96.43889, lat: 39.199103, postalCode: "66635" },
    { lng: -96.57856, lat: 37.813869, postalCode: "67132" },
    { lng: -94.85558, lat: 38.889784, postalCode: "66061" },
    { lng: -97.650902, lat: 39.827894, postalCode: "66961" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67275" },
    { lng: -95.41003, lat: 37.200877, postalCode: "67354" },
    { lng: -96.32433, lat: 37.854192, postalCode: "67045" },
    { lng: -97.40005, lat: 38.657542, postalCode: "67448" },
    { lng: -94.84372, lat: 37.000262, postalCode: "66778" },
    { lng: -95.66869, lat: 38.988508, postalCode: "66609" },
    { lng: -94.89202, lat: 37.840828, postalCode: "66769" },
    { lng: -95.70325, lat: 39.936121, postalCode: "66515" },
    { lng: -96.06113, lat: 39.317301, postalCode: "66422" },
    { lng: -97.42398, lat: 37.626676, postalCode: "67215" },
    { lng: -97.74843, lat: 38.122234, postalCode: "67522" },
    { lng: -94.83362, lat: 38.736692, postalCode: "66083" },
    { lng: -97.87011, lat: 37.076529, postalCode: "67018" },
    { lng: -95.65056, lat: 39.013988, postalCode: "66605" },
    { lng: -98.085924, lat: 37.953219, postalCode: "67504" },
    { lng: -97.648402, lat: 38.391722, postalCode: "67476" },
    { lng: -96.78463, lat: 37.314208, postalCode: "67019" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66699" },
    { lng: -95.34005, lat: 39.959033, postalCode: "66094" },
    { lng: -95.62641, lat: 38.382581, postalCode: "66871" },
    { lng: -95.88115, lat: 39.067174, postalCode: "66615" },
    { lng: -99.45494, lat: 39.799148, postalCode: "67670" },
    { lng: -100.2073, lat: 37.401983, postalCode: "67844" },
    { lng: -98.50294, lat: 39.32071, postalCode: "67485" },
    { lng: -97.005915, lat: 37.872226, postalCode: "67411" },
    { lng: -95.89505, lat: 38.078496, postalCode: "66852" },
    { lng: -97.76394, lat: 39.776903, postalCode: "66966" },
    { lng: -98.89486, lat: 37.784193, postalCode: "67021" },
    { lng: -101.33268, lat: 37.569601, postalCode: "67880" },
    { lng: -97.73798, lat: 39.26795, postalCode: "67436" },
    { lng: -95.24755, lat: 37.594166, postalCode: "66733" },
    { lng: -100.32811, lat: 37.84899, postalCode: "67835" },
    { lng: -96.07025, lat: 37.376701, postalCode: "67352" },
    { lng: -96.190914, lat: 37.125692, postalCode: "67435" },
    { lng: -96.29473, lat: 38.990708, postalCode: "66401" },
    { lng: -97.26295, lat: 39.594163, postalCode: "66937" },
    { lng: -96.63791, lat: 39.656045, postalCode: "66411" },
    { lng: -97.93976, lat: 38.027185, postalCode: "67505" },
    { lng: -94.64895, lat: 37.644301, postalCode: "66711" },
    { lng: -95.45681, lat: 37.666078, postalCode: "66720" },
    { lng: -100.78687, lat: 39.893743, postalCode: "67739" },
    { lng: -94.71256, lat: 38.01707, postalCode: "66738" },
    { lng: -98.75394, lat: 37.642738, postalCode: "67124" },
    { lng: -101.91157, lat: 37.585473, postalCode: "67862" },
    { lng: -101.2722, lat: 37.995238, postalCode: "67860" },
    { lng: -97.62881, lat: 39.824657, postalCode: "66935" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66634" },
    { lng: -98.41303, lat: 37.967166, postalCode: "67581" },
    { lng: -100.83926, lat: 39.11329, postalCode: "67748" },
    { lng: -94.63248, lat: 38.900535, postalCode: "66209" },
    { lng: -97.67462, lat: 37.831684, postalCode: "67108" },
    { lng: -95.98397, lat: 38.810071, postalCode: "66431" },
    { lng: -95.93926, lat: 37.568611, postalCode: "66759" },
    { lng: -101.54448, lat: 39.758864, postalCode: "67731" },
    { lng: -95.71698, lat: 39.04045, postalCode: "66604" },
    { lng: -95.10414, lat: 38.698415, postalCode: "66092" },
    { lng: -96.07105, lat: 37.64248, postalCode: "67047" },
    { lng: -96.99851, lat: 37.513371, postalCode: "67039" },
    { lng: -96.506769, lat: 39.43982, postalCode: "66426" },
    { lng: -94.654599, lat: 37.57189, postalCode: "66356" },
    { lng: -97.1552, lat: 39.341513, postalCode: "67432" },
    { lng: -97.92699, lat: 37.625625, postalCode: "67111" },
    { lng: -99.18943, lat: 38.573848, postalCode: "67520" },
    { lng: -97.4372, lat: 37.67129, postalCode: "67209" },
    { lng: -95.356, lat: 37.059559, postalCode: "67342" },
    { lng: -95.25834, lat: 37.339913, postalCode: "67357" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66637" },
    { lng: -101.12571, lat: 38.039676, postalCode: "67838" },
    { lng: -95.00349, lat: 38.94575, postalCode: "66019" },
    { lng: -96.96494, lat: 38.556941, postalCode: "66859" },
    { lng: -94.62156, lat: 37.344142, postalCode: "66760" },
    { lng: -97.34785, lat: 37.552425, postalCode: "67060" },
    { lng: -98.92213, lat: 39.761524, postalCode: "66932" },
    { lng: -96.129009, lat: 39.718697, postalCode: "66145" },
    { lng: -97.23166, lat: 37.703247, postalCode: "67206" },
    { lng: -98.03916, lat: 37.297898, postalCode: "67058" },
    { lng: -94.70758, lat: 38.899634, postalCode: "66213" },
    { lng: -97.31997, lat: 37.765919, postalCode: "67219" },
    { lng: -97.00329, lat: 37.42198, postalCode: "67131" },
    { lng: -99.3193, lat: 38.560733, postalCode: "67548" },
    { lng: -96.29965, lat: 37.337559, postalCode: "67353" },
    { lng: -98.10291, lat: 37.953609, postalCode: "67566" },
    { lng: -94.89859, lat: 39.258624, postalCode: "66043" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67278" },
    { lng: -98.91517, lat: 39.118835, postalCode: "67658" },
    { lng: -97.79101, lat: 37.898787, postalCode: "67543" },
    { lng: -95.23992, lat: 39.47305, postalCode: "66016" },
    { lng: -98.97856, lat: 38.262021, postalCode: "67567" },
    { lng: -96.56932, lat: 39.184986, postalCode: "66502" },
    { lng: -99.12757, lat: 38.179418, postalCode: "67550" },
    { lng: -97.01477, lat: 37.804776, postalCode: "67144" },
    { lng: -94.749538, lat: 39.096551, postalCode: "66117" },
    { lng: -94.96465, lat: 38.489733, postalCode: "66064" },
    { lng: -100.16811, lat: 38.427837, postalCode: "67518" },
    { lng: -97.53242, lat: 39.933522, postalCode: "66959" },
    { lng: -96.522806, lat: 39.783753, postalCode: "66555" },
    { lng: -96.26594, lat: 37.479165, postalCode: "67349" },
    { lng: -94.62223, lat: 38.959256, postalCode: "66206" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67256" },
    { lng: -97.929, lat: 38.094945, postalCode: "67502" },
    { lng: -96.75924, lat: 37.043519, postalCode: "67102" },
    { lng: -95.91616, lat: 37.021498, postalCode: "67333" },
    { lng: -95.6938, lat: 38.946977, postalCode: "66619" },
    { lng: -94.74929, lat: 37.026091, postalCode: "66713" },
    { lng: -97.13434, lat: 39.618962, postalCode: "66962" },
    { lng: -97.4959, lat: 37.692778, postalCode: "67235" },
    { lng: -96.58981, lat: 38.877563, postalCode: "66849" },
    { lng: -95.61031, lat: 38.647459, postalCode: "66543" },
    { lng: -94.92613, lat: 37.284559, postalCode: "66782" },
    { lng: -95.72954, lat: 39.626301, postalCode: "66516" },
    { lng: -97.30119, lat: 39.841886, postalCode: "66944" },
    { lng: -98.96837, lat: 39.647706, postalCode: "67628" },
    { lng: -94.82166, lat: 38.141903, postalCode: "66056" },
    { lng: -94.82665, lat: 37.347065, postalCode: "66724" },
    { lng: -95.18806, lat: 37.799021, postalCode: "66732" },
    { lng: -95.346697, lat: 37.923162, postalCode: "66742" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66620" },
    { lng: -95.1287, lat: 39.423964, postalCode: "66077" },
    { lng: -96.31279, lat: 39.220965, postalCode: "66547" },
    { lng: -98.58668, lat: 39.835476, postalCode: "66952" },
    { lng: -98.22479, lat: 37.992099, postalCode: "67510" },
    { lng: -94.86987, lat: 38.972197, postalCode: "66227" },
    { lng: -98.02957, lat: 39.633913, postalCode: "66963" },
    { lng: -95.94736, lat: 39.267478, postalCode: "66418" },
    { lng: -96.09053, lat: 37.074399, postalCode: "67360" },
    { lng: -99.77662, lat: 37.196075, postalCode: "67831" },
    { lng: -97.642827, lat: 38.702546, postalCode: "67479" },
    { lng: -94.62453, lat: 39.059254, postalCode: "66103" },
    { lng: -99.31688, lat: 39.212784, postalCode: "67663" },
    { lng: -97.33362, lat: 37.686992, postalCode: "67202" },
    { lng: -96.18665, lat: 39.207942, postalCode: "66407" },
    { lng: -95.12869, lat: 39.79684, postalCode: "66087" },
    { lng: -95.85898, lat: 39.136616, postalCode: "66539" },
    { lng: -94.70402, lat: 37.522439, postalCode: "66735" },
    { lng: -96.50104, lat: 38.415712, postalCode: "66869" },
    { lng: -101.04485, lat: 39.394701, postalCode: "67701" },
    { lng: -97.10432, lat: 37.395382, postalCode: "67146" },
    { lng: -97.66554, lat: 37.251663, postalCode: "67105" },
    { lng: -95.21058, lat: 38.789719, postalCode: "66006" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66686" },
    { lng: -95.54874, lat: 39.094823, postalCode: "66429" },
    { lng: -94.749538, lat: 39.096551, postalCode: "66160" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66279" },
    { lng: -94.75259, lat: 37.300262, postalCode: "66781" },
    { lng: -97.54135, lat: 37.787206, postalCode: "67030" },
    { lng: -95.45781, lat: 39.339975, postalCode: "66088" },
    { lng: -97.89977, lat: 38.367098, postalCode: "67491" },
    { lng: -98.44972, lat: 38.817788, postalCode: "67490" },
    { lng: -99.07886, lat: 38.868064, postalCode: "67674" },
    { lng: -95.40614, lat: 38.067424, postalCode: "66015" },
    { lng: -95.15952, lat: 37.500157, postalCode: "66771" },
    { lng: -97.81023, lat: 39.931181, postalCode: "66964" },
    { lng: -97.36562, lat: 37.065497, postalCode: "67140" },
    { lng: -99.216051, lat: 38.927648, postalCode: "67627" },
    { lng: -98.79561, lat: 39.794538, postalCode: "66967" },
    { lng: -95.917186, lat: 38.964532, postalCode: "66420" },
    { lng: -95.96831, lat: 39.949989, postalCode: "66408" },
    { lng: -95.9426, lat: 37.103604, postalCode: "67347" },
    { lng: -94.76614, lat: 39.117009, postalCode: "66112" },
    { lng: -94.729782, lat: 37.59292, postalCode: "66721" },
    { lng: -97.01768, lat: 38.359189, postalCode: "66861" },
    { lng: -95.60496, lat: 39.377827, postalCode: "66419" },
    { lng: -99.32852, lat: 39.799834, postalCode: "67661" },
    { lng: -95.06283, lat: 39.01201, postalCode: "66052" },
    { lng: -98.39643, lat: 37.655003, postalCode: "67035" },
    { lng: -98.41672, lat: 38.278487, postalCode: "67573" },
    { lng: -100.34464, lat: 37.21313, postalCode: "67864" },
    { lng: -97.54588, lat: 39.433975, postalCode: "67417" },
    { lng: -98.79762, lat: 39.166845, postalCode: "67673" },
    { lng: -96.63963, lat: 39.852696, postalCode: "66508" },
    { lng: -97.42917, lat: 37.738348, postalCode: "67205" },
    { lng: -97.9286, lat: 38.813761, postalCode: "67425" },
    { lng: -95.92512, lat: 39.671207, postalCode: "66428" },
    { lng: -97.462993, lat: 39.43042, postalCode: "67938" },
    { lng: -97.205419, lat: 37.78335, postalCode: "67055" },
    { lng: -96.43346, lat: 39.21939, postalCode: "66535" },
    { lng: -98.7059, lat: 39.409385, postalCode: "67473" },
    { lng: -99.53467, lat: 39.947283, postalCode: "67647" },
    { lng: -98.31957, lat: 38.238827, postalCode: "67512" },
    { lng: -97.26652, lat: 37.638946, postalCode: "67210" },
    { lng: -101.23341, lat: 39.406853, postalCode: "67743" },
    { lng: -94.77581, lat: 38.860511, postalCode: "66062" },
    { lng: -99.32559, lat: 38.881082, postalCode: "67601" },
    { lng: -95.84417, lat: 38.918102, postalCode: "66402" },
    { lng: -97.323, lat: 38.051679, postalCode: "67114" },
    { lng: -97.92382, lat: 38.034292, postalCode: "67501" },
    { lng: -96.51545, lat: 38.14969, postalCode: "66862" },
    { lng: -100.29331, lat: 39.676204, postalCode: "67643" },
    { lng: -95.76057, lat: 39.015374, postalCode: "66614" },
    { lng: -97.62602, lat: 37.779021, postalCode: "67001" },
    { lng: -96.22087, lat: 37.107867, postalCode: "67361" },
    { lng: -99.74865, lat: 38.618706, postalCode: "67521" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66636" },
    { lng: -95.41505, lat: 39.681813, postalCode: "66424" },
    { lng: -95.00622, lat: 38.762545, postalCode: "66021" },
    { lng: -95.30389, lat: 37.180641, postalCode: "67330" },
    { lng: -96.48447, lat: 37.104057, postalCode: "67024" },
    { lng: -95.97685, lat: 38.532132, postalCode: "66868" },
    { lng: -95.12925, lat: 38.368325, postalCode: "66033" },
    { lng: -94.97218, lat: 37.848943, postalCode: "66779" },
    { lng: -95.81925, lat: 39.654548, postalCode: "66550" },
    { lng: -97.27953, lat: 37.670092, postalCode: "67218" },
    { lng: -98.96044, lat: 37.363445, postalCode: "67143" },
    { lng: -96.14204, lat: 39.697296, postalCode: "66415" },
    { lng: -95.52321, lat: 39.548048, postalCode: "66058" },
    { lng: -97.499, lat: 37.73674, postalCode: "67223" },
    { lng: -94.977587, lat: 37.332679, postalCode: "66752" },
    { lng: -97.67008, lat: 38.008128, postalCode: "67020" },
    { lng: -97.9986, lat: 39.791338, postalCode: "66942" },
    { lng: -101.00775, lat: 37.496762, postalCode: "67870" },
    { lng: -101.39536, lat: 38.503037, postalCode: "67861" },
    { lng: -98.59126, lat: 37.976752, postalCode: "67578" },
    { lng: -94.86078, lat: 37.506616, postalCode: "66743" },
    { lng: -95.69832, lat: 37.705016, postalCode: "66717" },
    { lng: -97.3249, lat: 39.966106, postalCode: "66955" },
    { lng: -95.48417, lat: 37.478331, postalCode: "66776" },
    { lng: -99.03432, lat: 38.878181, postalCode: "67640" },
    { lng: -95.22513, lat: 38.932303, postalCode: "66046" },
    { lng: -96.47367, lat: 38.862376, postalCode: "66834" },
    { lng: -94.97681, lat: 38.961396, postalCode: "66018" },
    { lng: -94.84844, lat: 39.029641, postalCode: "66226" },
    { lng: -95.63079, lat: 38.503219, postalCode: "66510" },
    { lng: -95.64804, lat: 39.044056, postalCode: "66607" },
    { lng: -96.57004, lat: 39.964921, postalCode: "66518" },
    { lng: -98.79487, lat: 38.014216, postalCode: "67576" },
    { lng: -97.98231, lat: 37.784834, postalCode: "67570" },
    { lng: -97.410025, lat: 39.248278, postalCode: "67472" },
    { lng: -96.41726, lat: 39.877735, postalCode: "66406" },
    { lng: -96.04833, lat: 39.840496, postalCode: "66538" },
    { lng: -94.8929, lat: 38.017205, postalCode: "66754" },
    { lng: -98.470661, lat: 39.339801, postalCode: "67495" },
    { lng: -97.23289, lat: 37.669803, postalCode: "67207" },
    { lng: -98.70224, lat: 39.579664, postalCode: "67474" },
    { lng: -95.681454, lat: 37.424622, postalCode: "66747" },
    { lng: -97.5139, lat: 38.007112, postalCode: "67056" },
    { lng: -95.67363, lat: 38.602873, postalCode: "66451" },
    { lng: -97.25509, lat: 38.13467, postalCode: "67151" },
    { lng: -96.20048, lat: 38.241502, postalCode: "66865" },
    { lng: -96.99137, lat: 39.475173, postalCode: "67447" },
    { lng: -100.02632, lat: 37.755267, postalCode: "67801" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66051" },
    { lng: -96.89128, lat: 38.064497, postalCode: "66840" },
    { lng: -95.9413, lat: 39.865907, postalCode: "66522" },
    { lng: -96.88156, lat: 39.888945, postalCode: "66945" },
    { lng: -96.77794, lat: 39.921671, postalCode: "66412" },
    { lng: -96.7426, lat: 37.150816, postalCode: "67038" },
    { lng: -94.68121, lat: 38.602219, postalCode: "66053" },
    { lng: -96.84635, lat: 39.303014, postalCode: "66531" },
    { lng: -99.89944, lat: 39.015522, postalCode: "67672" },
    { lng: -98.03857, lat: 37.144088, postalCode: "67003" },
    { lng: -99.93288, lat: 39.822766, postalCode: "67654" },
    { lng: -96.90611, lat: 39.149612, postalCode: "66514" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66653" },
    { lng: -96.3636, lat: 39.977047, postalCode: "66541" },
    { lng: -97.58943, lat: 39.03407, postalCode: "67422" },
    { lng: -95.68963, lat: 38.82517, postalCode: "66414" },
    { lng: -97.491566, lat: 37.62812, postalCode: "67227" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66063" },
    { lng: -98.55591, lat: 39.50357, postalCode: "67437" },
    { lng: -98.68324, lat: 37.494728, postalCode: "67134" },
    { lng: -94.73705, lat: 38.35152, postalCode: "66040" },
    { lng: -98.95861, lat: 37.934689, postalCode: "67557" },
    { lng: -98.04615, lat: 39.18871, postalCode: "67418" },
    { lng: -99.04018, lat: 39.202987, postalCode: "67651" },
    { lng: -97.30594, lat: 39.171661, postalCode: "67458" },
    { lng: -98.00626, lat: 38.40198, postalCode: "67457" },
    { lng: -97.5739, lat: 37.651845, postalCode: "67052" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66658" },
    { lng: -96.22324, lat: 37.647292, postalCode: "67137" },
    { lng: -97.27114, lat: 38.499781, postalCode: "67438" },
    { lng: -97.34635, lat: 38.073865, postalCode: "67117" },
    { lng: -98.24779, lat: 37.233697, postalCode: "67009" },
    { lng: -98.44356, lat: 39.827165, postalCode: "66941" },
    { lng: -97.22525, lat: 39.459244, postalCode: "67468" },
    { lng: -94.71498, lat: 38.961756, postalCode: "66214" },
    { lng: -94.70583, lat: 39.020405, postalCode: "66203" },
    { lng: -95.25997, lat: 38.278069, postalCode: "66032" },
    { lng: -95.13678, lat: 37.182538, postalCode: "67356" },
    { lng: -94.749538, lat: 39.096551, postalCode: "66110" },
    { lng: -96.40529, lat: 37.629754, postalCode: "67122" },
    { lng: -101.04488, lat: 39.071265, postalCode: "67747" },
    { lng: -101.83681, lat: 37.23606, postalCode: "67953" },
    { lng: -96.98153, lat: 37.68066, postalCode: "67010" },
    { lng: -95.14826, lat: 37.691117, postalCode: "66775" },
    { lng: -97.75943, lat: 37.447829, postalCode: "67106" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66692" },
    { lng: -97.16491, lat: 39.883769, postalCode: "66958" },
    { lng: -100.67849, lat: 37.881899, postalCode: "67868" },
    { lng: -99.32062, lat: 38.654948, postalCode: "67553" },
    { lng: -95.43452, lat: 37.802718, postalCode: "66748" },
    { lng: -99.29989, lat: 37.590146, postalCode: "67054" },
    { lng: -98.76647, lat: 38.562172, postalCode: "67544" },
    { lng: -100.82503, lat: 37.976008, postalCode: "67846" },
    { lng: -99.75144, lat: 38.989828, postalCode: "67656" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66638" },
    { lng: -94.62568, lat: 39.118054, postalCode: "66101" },
    { lng: -96.57872, lat: 39.194065, postalCode: "66506" },
    { lng: -97.34817, lat: 38.246972, postalCode: "67053" },
    { lng: -95.52869, lat: 38.775185, postalCode: "66524" },
    { lng: -97.51828, lat: 37.886437, postalCode: "67016" },
    { lng: -94.63097, lat: 39.030172, postalCode: "66205" },
    { lng: -98.69182, lat: 39.120158, postalCode: "67649" },
    { lng: -95.53567, lat: 39.659471, postalCode: "66439" },
    { lng: -97.64627, lat: 39.568803, postalCode: "66901" },
    { lng: -95.30399, lat: 38.97583, postalCode: "66049" },
    { lng: -98.8672, lat: 38.866551, postalCode: "67665" },
    { lng: -97.67156, lat: 38.590743, postalCode: "67456" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66624" },
    { lng: -97.22812, lat: 37.474542, postalCode: "67110" },
    { lng: -94.749538, lat: 39.096551, postalCode: "66119" },
    { lng: -95.409339, lat: 39.814081, postalCode: "66352" },
    { lng: -99.98755, lat: 39.301053, postalCode: "67659" },
    { lng: -94.77251, lat: 37.302792, postalCode: "66481" },
    { lng: -95.58247, lat: 37.135671, postalCode: "67351" },
    { lng: -98.10612, lat: 37.618339, postalCode: "67068" },
    { lng: -97.70794, lat: 39.131275, postalCode: "67467" },
    { lng: -94.70142, lat: 38.184882, postalCode: "66075" },
    { lng: -95.61814, lat: 37.041838, postalCode: "67337" },
    { lng: -100.92813, lat: 37.067979, postalCode: "67901" },
    { lng: -97.83261, lat: 38.998387, postalCode: "67484" },
    { lng: -96.64914, lat: 39.241083, postalCode: "66503" },
    { lng: -99.30712, lat: 39.652794, postalCode: "67639" },
    { lng: -99.32578, lat: 39.436461, postalCode: "67669" },
    { lng: -96.242059, lat: 37.888276, postalCode: "66855" },
    { lng: -96.08821, lat: 38.614057, postalCode: "66830" },
    { lng: -96.87232, lat: 39.675346, postalCode: "66933" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66626" },
    { lng: -97.33449, lat: 37.568951, postalCode: "67233" },
    { lng: -94.899201, lat: 38.824863, postalCode: "66031" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66621" },
    { lng: -99.44681, lat: 38.41473, postalCode: "67559" },
    { lng: -94.77155, lat: 38.963798, postalCode: "66219" },
    { lng: -98.75308, lat: 37.746879, postalCode: "67066" },
    { lng: -98.95757, lat: 39.461403, postalCode: "67623" },
    { lng: -95.85396, lat: 39.516144, postalCode: "66416" },
    { lng: -97.19239, lat: 37.104798, postalCode: "67051" },
    { lng: -95.2947, lat: 37.918044, postalCode: "66751" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66276" },
    { lng: -95.40163, lat: 39.097559, postalCode: "66073" },
    { lng: -100.938049, lat: 37.021641, postalCode: "67905" },
    { lng: -95.06279, lat: 37.603419, postalCode: "66780" },
    { lng: -98.20222, lat: 38.19784, postalCode: "67579" },
    { lng: -97.49191, lat: 39.718857, postalCode: "66930" },
    { lng: -94.67203, lat: 39.137003, postalCode: "66104" },
    { lng: -95.63808, lat: 39.065249, postalCode: "66616" },
    { lng: -98.17577, lat: 39.034792, postalCode: "67455" },
    { lng: -95.98917, lat: 38.368683, postalCode: "66864" },
    { lng: -97.11321, lat: 37.693585, postalCode: "67002" },
    { lng: -101.82167, lat: 39.773872, postalCode: "67756" },
    { lng: -99.96723, lat: 37.394383, postalCode: "67865" },
    { lng: -97.650446, lat: 38.78359, postalCode: "67402" },
    { lng: -95.19892, lat: 39.201274, postalCode: "66054" },
    { lng: -95.08112, lat: 39.112379, postalCode: "66086" },
    { lng: -95.88076, lat: 38.767905, postalCode: "66413" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66667" },
    { lng: -97.23115, lat: 37.750904, postalCode: "67226" },
    { lng: -96.78484, lat: 38.536957, postalCode: "66838" },
    { lng: -99.13229, lat: 39.792611, postalCode: "67621" },
    { lng: -96.59975, lat: 39.417531, postalCode: "66520" },
    { lng: -96.73809, lat: 38.79144, postalCode: "66872" },
    { lng: -98.03131, lat: 37.436662, postalCode: "67128" },
    { lng: -95.37039, lat: 37.464733, postalCode: "66740" },
    { lng: -96.16385, lat: 38.672825, postalCode: "66833" },
    { lng: -98.38455, lat: 39.229204, postalCode: "67452" },
    { lng: -100.993268, lat: 37.989353, postalCode: "67852" },
    { lng: -95.69171, lat: 39.254902, postalCode: "66440" },
    { lng: -94.65864, lat: 37.726754, postalCode: "66741" },
    { lng: -95.68048, lat: 39.040333, postalCode: "66612" },
    { lng: -97.80321, lat: 38.224457, postalCode: "67546" },
    { lng: -99.72578, lat: 37.845029, postalCode: "67876" },
    { lng: -96.7069, lat: 37.686034, postalCode: "67074" },
    { lng: -98.93543, lat: 37.469499, postalCode: "67028" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66250" },
    { lng: -98.23619, lat: 37.5731, postalCode: "67069" },
    { lng: -97.47176, lat: 39.342965, postalCode: "67466" },
    { lng: -94.66594, lat: 38.860552, postalCode: "66223" },
    { lng: -97.78765, lat: 37.647611, postalCode: "67025" },
    { lng: -100.08518, lat: 39.359896, postalCode: "67650" },
    { lng: -98.29451, lat: 39.908065, postalCode: "66936" },
    { lng: -97.31751, lat: 37.619858, postalCode: "67216" },
    { lng: -95.504561, lat: 37.050156, postalCode: "67339" },
    { lng: -97.31777, lat: 37.705384, postalCode: "67214" },
    { lng: -94.63125, lat: 39.084954, postalCode: "66105" },
    { lng: -99.96701, lat: 38.080626, postalCode: "67854" },
    { lng: -98.39638, lat: 38.50978, postalCode: "67427" },
    { lng: -95.63373, lat: 39.130354, postalCode: "66617" },
    { lng: -96.12775, lat: 38.8421, postalCode: "66423" },
    { lng: -95.18541, lat: 39.703642, postalCode: "66008" },
    { lng: -100.22704, lat: 37.629346, postalCode: "67841" },
    { lng: -97.52827, lat: 38.193313, postalCode: "67107" },
    { lng: -100.67514, lat: 37.554849, postalCode: "67837" },
    { lng: -100.58189, lat: 37.257151, postalCode: "67869" },
    { lng: -97.04513, lat: 39.180886, postalCode: "67487" },
    { lng: -94.6957, lat: 38.733271, postalCode: "66013" },
    { lng: -95.73245, lat: 37.628076, postalCode: "66714" },
    { lng: -97.68539, lat: 37.663743, postalCode: "67050" },
    { lng: -96.66349, lat: 38.018802, postalCode: "66842" },
    { lng: -98.54262, lat: 37.461079, postalCode: "67065" },
    { lng: -100.46228, lat: 37.586422, postalCode: "67867" },
    { lng: -96.92825, lat: 38.228452, postalCode: "66851" },
    { lng: -97.40544, lat: 39.594485, postalCode: "66938" },
    { lng: -98.67631, lat: 37.041728, postalCode: "67057" },
    { lng: -95.33705, lat: 38.185196, postalCode: "66091" },
    { lng: -94.68872, lat: 39.066732, postalCode: "66106" },
    { lng: -97.44917, lat: 39.81884, postalCode: "66940" },
    { lng: -95.780662, lat: 38.988075, postalCode: "66601" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67259" },
    { lng: -95.2227, lat: 37.051587, postalCode: "67332" },
    { lng: -100.17544, lat: 39.707176, postalCode: "67629" },
    { lng: -96.25853, lat: 38.513771, postalCode: "66835" },
    { lng: -98.13731, lat: 38.703443, postalCode: "67454" },
    { lng: -96.49331, lat: 38.649811, postalCode: "66846" },
    { lng: -96.01065, lat: 37.053112, postalCode: "67355" },
    { lng: -99.60749, lat: 39.336054, postalCode: "67632" },
    { lng: -99.59094, lat: 39.65573, postalCode: "67646" },
    { lng: -94.61329, lat: 39.105854, postalCode: "66118" },
    { lng: -96.00967, lat: 37.925128, postalCode: "66870" },
    { lng: -95.54722, lat: 37.278991, postalCode: "67335" },
    { lng: -97.41247, lat: 37.268972, postalCode: "67152" },
    { lng: -96.43876, lat: 39.689409, postalCode: "66427" },
    { lng: -100.08722, lat: 39.028141, postalCode: "67631" },
    { lng: -99.2477, lat: 38.070818, postalCode: "67529" },
    { lng: -99.60135, lat: 38.933306, postalCode: "67637" },
    { lng: -99.03877, lat: 39.792636, postalCode: "66951" },
    { lng: -99.92622, lat: 38.443723, postalCode: "67560" },
    { lng: -96.69377, lat: 38.395027, postalCode: "66850" },
    { lng: -100.49796, lat: 37.842421, postalCode: "67853" },
    { lng: -94.68189, lat: 38.959606, postalCode: "66212" },
    { lng: -96.21271, lat: 38.000062, postalCode: "66853" },
    { lng: -94.93177, lat: 39.348031, postalCode: "66027" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66286" },
    { lng: -95.7313, lat: 37.873778, postalCode: "66783" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66629" },
    { lng: -95.26646, lat: 38.49159, postalCode: "66078" },
    { lng: -97.783181, lat: 39.798339, postalCode: "66969" },
    { lng: -98.84528, lat: 39.640967, postalCode: "67638" },
    { lng: -101.88893, lat: 37.011254, postalCode: "67950" },
    { lng: -98.56309, lat: 38.555566, postalCode: "67525" },
    { lng: -97.49452, lat: 37.518342, postalCode: "67026" },
    { lng: -95.31425, lat: 39.589832, postalCode: "66041" },
    { lng: -100.2322, lat: 39.038252, postalCode: "67752" },
    { lng: -98.20722, lat: 39.790935, postalCode: "66956" },
    { lng: -95.72861, lat: 39.811818, postalCode: "66425" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67251" },
    { lng: -97.262371, lat: 37.751267, postalCode: "67222" },
    { lng: -96.0343, lat: 39.076048, postalCode: "66507" },
    { lng: -95.75535, lat: 39.337531, postalCode: "66509" },
    { lng: -98.43104, lat: 38.61616, postalCode: "67450" },
    { lng: -99.09672, lat: 37.658761, postalCode: "67059" },
    { lng: -95.82289, lat: 39.889544, postalCode: "66534" },
    { lng: -96.77229, lat: 38.224172, postalCode: "66843" },
    { lng: -97.01425, lat: 38.959448, postalCode: "67431" },
    { lng: -98.64732, lat: 38.15141, postalCode: "67545" },
    { lng: -100.47735, lat: 39.117535, postalCode: "67737" },
    { lng: -97.93323, lat: 39.384449, postalCode: "67478" },
    { lng: -96.70935, lat: 39.113103, postalCode: "66517" },
    { lng: -97.82634, lat: 39.367111, postalCode: "67445" },
    { lng: -95.39695, lat: 37.926666, postalCode: "66749" },
    { lng: -99.33385, lat: 37.259332, postalCode: "67029" },
    { lng: -94.9265, lat: 38.813839, postalCode: "66030" },
    { lng: -95.63461, lat: 38.095388, postalCode: "66857" },
    { lng: -98.21584, lat: 37.468889, postalCode: "67142" },
    { lng: -95.97495, lat: 39.502211, postalCode: "66540" },
    { lng: -97.27841, lat: 37.745561, postalCode: "67220" },
    { lng: -96.21101, lat: 39.483435, postalCode: "66521" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66283" },
    { lng: -97.89512, lat: 38.590489, postalCode: "67464" },
    { lng: -99.47608, lat: 37.581777, postalCode: "67109" },
    { lng: -97.35763, lat: 37.754443, postalCode: "67204" },
    { lng: -94.64691, lat: 38.957396, postalCode: "66207" },
    { lng: -94.69088, lat: 37.455819, postalCode: "66763" },
    { lng: -97.44004, lat: 37.921185, postalCode: "67135" },
    { lng: -95.73302, lat: 39.126429, postalCode: "66618" },
    { lng: -95.56976, lat: 38.005429, postalCode: "66758" },
    { lng: -95.32966, lat: 39.415907, postalCode: "66060" },
    { lng: -96.663865, lat: 38.682093, postalCode: "66873" },
    { lng: -97.65338, lat: 37.400431, postalCode: "67031" },
    { lng: -99.53906, lat: 38.446509, postalCode: "67513" },
    { lng: -98.41625, lat: 37.442074, postalCode: "67112" },
    { lng: -94.87713, lat: 37.17473, postalCode: "66725" },
    { lng: -96.65629, lat: 37.327404, postalCode: "67023" },
    { lng: -99.09119, lat: 39.450984, postalCode: "67675" },
    { lng: -101.68074, lat: 37.557733, postalCode: "67855" },
    { lng: -95.93877, lat: 37.777886, postalCode: "66777" },
    { lng: -96.31402, lat: 39.251831, postalCode: "66450" },
    { lng: -96.19019, lat: 37.37477, postalCode: "67345" },
    { lng: -97.84511, lat: 39.609023, postalCode: "66948" },
    { lng: -97.60782, lat: 37.062453, postalCode: "67022" },
    { lng: -97.25167, lat: 37.557434, postalCode: "67037" },
    { lng: -97.36312, lat: 37.62421, postalCode: "67217" },
    { lng: -95.72799, lat: 38.771467, postalCode: "66537" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67257" },
    { lng: -101.22087, lat: 38.551037, postalCode: "67863" },
    { lng: -97.36237, lat: 37.703932, postalCode: "67203" },
    { lng: -95.23202, lat: 38.983551, postalCode: "66044" },
    { lng: -97.36204, lat: 37.669067, postalCode: "67213" },
    { lng: -97.3804, lat: 37.459433, postalCode: "67120" },
    { lng: -94.98253, lat: 38.328516, postalCode: "66072" },
    { lng: -96.07148, lat: 39.200733, postalCode: "66536" },
    { lng: -100.89755, lat: 39.488765, postalCode: "67734" },
    { lng: -99.40331, lat: 38.193997, postalCode: "67574" },
    { lng: -97.76478, lat: 37.270346, postalCode: "67004" },
    { lng: -96.50361, lat: 39.853477, postalCode: "66438" },
    { lng: -98.93228, lat: 38.526757, postalCode: "67564" },
    { lng: -96.59795, lat: 37.561112, postalCode: "67072" },
    { lng: -95.66824, lat: 39.077066, postalCode: "66608" },
    { lng: -96.76806, lat: 39.472188, postalCode: "66554" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66622" },
    { lng: -97.410154, lat: 37.556692, postalCode: "67231" },
    { lng: -99.63435, lat: 37.523175, postalCode: "67834" },
    { lng: -96.100176, lat: 37.097273, postalCode: "67630" },
    { lng: -97.10565, lat: 39.689909, postalCode: "66953" },
    { lng: -96.80662, lat: 39.074628, postalCode: "66442" },
    { lng: -94.70622, lat: 37.547763, postalCode: "66712" },
    { lng: -96.85876, lat: 39.383205, postalCode: "66449" },
    { lng: -96.257561, lat: 39.471738, postalCode: "66551" },
    { lng: -94.66658, lat: 39.023105, postalCode: "66202" },
    { lng: -95.07063, lat: 37.916575, postalCode: "66716" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66683" },
    { lng: -96.23893, lat: 39.053241, postalCode: "66501" },
    { lng: -98.2025, lat: 38.356474, postalCode: "67554" },
    { lng: -95.7132, lat: 39.466971, postalCode: "66436" },
    { lng: -96.675295, lat: 39.304937, postalCode: "66505" },
    { lng: -95.54333, lat: 38.60969, postalCode: "66528" },
    { lng: -97.41789, lat: 38.369576, postalCode: "67428" },
    { lng: -97.435986, lat: 39.188023, postalCode: "67488" },
    { lng: -95.18816, lat: 37.75459, postalCode: "66772" },
    { lng: -100.84391, lat: 37.525821, postalCode: "67877" },
    { lng: -100.45129, lat: 39.610945, postalCode: "67635" },
    { lng: -100.415419, lat: 38.140691, postalCode: "67856" },
    { lng: -94.63102, lat: 38.999505, postalCode: "66208" },
    { lng: -98.31657, lat: 38.006382, postalCode: "67568" },
    { lng: -97.21489, lat: 38.347058, postalCode: "67063" },
    { lng: -98.43768, lat: 39.520894, postalCode: "67430" },
    { lng: -96.19736, lat: 38.410549, postalCode: "66801" },
    { lng: -97.15947, lat: 38.538684, postalCode: "67483" },
    { lng: -95.39413, lat: 39.521275, postalCode: "66023" },
    { lng: -97.53229, lat: 38.372202, postalCode: "67443" },
    { lng: -95.6202, lat: 39.585484, postalCode: "66552" },
    { lng: -97.28114, lat: 37.701997, postalCode: "67208" },
    { lng: -94.69495, lat: 38.066976, postalCode: "66767" },
    { lng: -97.788817, lat: 39.588426, postalCode: "66998" },
    { lng: -94.73924, lat: 39.009485, postalCode: "66216" },
    { lng: -94.63283, lat: 37.554735, postalCode: "66756" },
    { lng: -99.56069, lat: 37.861674, postalCode: "67563" },
    { lng: -95.67547, lat: 39.0562, postalCode: "66603" },
    { lng: -95.55387, lat: 39.201628, postalCode: "66512" },
    { lng: -96.97885, lat: 37.256575, postalCode: "67156" },
    { lng: -95.06455, lat: 38.917032, postalCode: "66025" },
    { lng: -99.15047, lat: 38.861194, postalCode: "67671" },
    { lng: -94.679486, lat: 39.007755, postalCode: "66201" },
    { lng: -95.1232, lat: 38.542788, postalCode: "66079" },
    { lng: -99.8456, lat: 39.360338, postalCode: "67642" },
    { lng: -95.70982, lat: 37.058083, postalCode: "67340" },
    { lng: -95.734523, lat: 39.914389, postalCode: "66151" },
    { lng: -99.74869, lat: 37.549041, postalCode: "67842" },
    { lng: -94.71538, lat: 37.074648, postalCode: "66770" },
    { lng: -101.349, lat: 37.185269, postalCode: "67951" },
    { lng: -98.45617, lat: 37.804591, postalCode: "67583" },
    { lng: -94.8126, lat: 39.011673, postalCode: "66218" },
    { lng: -97.1706, lat: 37.683392, postalCode: "67230" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67276" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66251" },
    { lng: -94.65462, lat: 37.091398, postalCode: "66739" },
    { lng: -98.22729, lat: 38.744056, postalCode: "67439" },
    { lng: -94.85125, lat: 38.404804, postalCode: "66026" },
    { lng: -94.73389, lat: 37.823295, postalCode: "66701" },
    { lng: -94.74145, lat: 38.961006, postalCode: "66215" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66675" },
    { lng: -99.61488, lat: 39.22742, postalCode: "67657" },
    { lng: -96.17845, lat: 37.026172, postalCode: "67334" },
    { lng: -98.18519, lat: 39.652106, postalCode: "66949" },
    { lng: -97.12711, lat: 38.054115, postalCode: "67041" },
    { lng: -97.61553, lat: 38.827875, postalCode: "67401" },
    { lng: -98.30304, lat: 37.422585, postalCode: "67159" },
    { lng: -98.40165, lat: 39.023406, postalCode: "67481" },
    { lng: -97.27309, lat: 37.795395, postalCode: "67067" },
    { lng: -100.59954, lat: 39.534756, postalCode: "67757" },
    { lng: -97.01423, lat: 37.946174, postalCode: "67123" },
    { lng: -99.33117, lat: 38.711024, postalCode: "67667" },
    { lng: -96.07111, lat: 37.831349, postalCode: "66863" },
    { lng: -97.29152, lat: 37.390809, postalCode: "67013" },
    { lng: -99.54314, lat: 38.200452, postalCode: "67523" },
    { lng: -95.41692, lat: 37.347626, postalCode: "67341" },
    { lng: -97.4343, lat: 37.700632, postalCode: "67212" },
    { lng: -95.71995, lat: 38.895185, postalCode: "66546" },
    { lng: -101.61909, lat: 37.163412, postalCode: "67954" },
    { lng: -99.14364, lat: 37.34323, postalCode: "67155" },
    { lng: -94.66643, lat: 39.112204, postalCode: "66102" },
    { lng: -95.08365, lat: 37.047431, postalCode: "67336" },
    { lng: -98.55613, lat: 39.059815, postalCode: "67648" },
    { lng: -96.07717, lat: 39.496479, postalCode: "66432" },
    { lng: -95.44342, lat: 38.455968, postalCode: "66095" },
    { lng: -96.4027, lat: 39.427973, postalCode: "66549" },
    { lng: -100.64563, lat: 39.042757, postalCode: "67738" },
    { lng: -97.26626, lat: 37.628158, postalCode: "67221" },
    { lng: -94.93555, lat: 39.298776, postalCode: "66048" },
    { lng: -95.9686, lat: 38.281776, postalCode: "66854" },
    { lng: -95.27355, lat: 38.614986, postalCode: "66067" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67260" },
    { lng: -95.36776, lat: 39.8202, postalCode: "66532" },
    { lng: -99.598374, lat: 39.684032, postalCode: "67666" },
    { lng: -96.84294, lat: 37.830417, postalCode: "67042" },
    { lng: -95.972744, lat: 39.964236, postalCode: "66804" },
    { lng: -98.18757, lat: 37.876541, postalCode: "67514" },
    { lng: -95.0154, lat: 38.102749, postalCode: "66010" },
    { lng: -95.65671, lat: 37.551596, postalCode: "66710" },
    { lng: -96.15801, lat: 39.06583, postalCode: "66526" },
    { lng: -97.90386, lat: 39.837438, postalCode: "66939" },
    { lng: -95.788948, lat: 39.672989, postalCode: "66650" },
    { lng: -100.53872, lat: 39.832075, postalCode: "67749" },
    { lng: -98.33675, lat: 38.57504, postalCode: "67459" },
    { lng: -95.09067, lat: 38.442448, postalCode: "66042" },
    { lng: -99.98903, lat: 37.040749, postalCode: "67840" },
    { lng: -95.2569, lat: 39.85881, postalCode: "66035" },
    { lng: -95.74293, lat: 38.211108, postalCode: "66839" },
    { lng: -94.95873, lat: 39.774356, postalCode: "66090" },
    { lng: -94.86706, lat: 38.574804, postalCode: "66071" },
    { lng: -96.43368, lat: 37.326384, postalCode: "67346" },
    { lng: -98.60271, lat: 37.286936, postalCode: "67104" },
    { lng: -99.50499, lat: 37.196316, postalCode: "67127" },
    { lng: -95.275623, lat: 38.952526, postalCode: "66045" },
    { lng: -94.98779, lat: 38.226434, postalCode: "66014" },
    { lng: -95.16768, lat: 37.933658, postalCode: "66755" },
    { lng: -95.44963, lat: 39.201834, postalCode: "66070" },
    { lng: -94.723271, lat: 39.073539, postalCode: "66113" },
    { lng: -97.97921, lat: 38.980027, postalCode: "67423" },
    { lng: -100.74875, lat: 39.40544, postalCode: "67753" },
    { lng: -97.480419, lat: 37.693588, postalCode: "67277" },
    { lng: -95.82167, lat: 37.035742, postalCode: "67364" },
    { lng: -100.93866, lat: 39.857038, postalCode: "67744" },
    { lng: -95.83132, lat: 38.611619, postalCode: "66523" },
    { lng: -96.76189, lat: 39.691428, postalCode: "66548" },
    { lng: -97.75005, lat: 38.660286, postalCode: "67442" },
    { lng: -98.524566, lat: 39.296417, postalCode: "67845" },
    { lng: -96.96583, lat: 39.688224, postalCode: "66943" },
    { lng: -94.61878, lat: 39.141419, postalCode: "66115" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66625" },
    { lng: -100.05532, lat: 38.674455, postalCode: "67515" },
    { lng: -95.84307, lat: 37.539672, postalCode: "66736" },
    { lng: -98.20814, lat: 37.026317, postalCode: "67150" },
    { lng: -94.82738, lat: 37.27522, postalCode: "66773" },
    { lng: -97.85182, lat: 37.460764, postalCode: "67118" },
    { lng: -95.769657, lat: 39.042939, postalCode: "66628" },
    { lng: -97.18161, lat: 37.253486, postalCode: "67119" },
    { lng: -99.04206, lat: 38.587486, postalCode: "67565" },
    { lng: -98.60426, lat: 38.834958, postalCode: "67634" },
    { lng: -97.84835, lat: 37.173305, postalCode: "67049" },
    { lng: -100.19251, lat: 38.669493, postalCode: "67584" },
    { lng: -95.13472, lat: 39.553786, postalCode: "66002" },
    { lng: -94.831991, lat: 38.899901, postalCode: "66225" },
    { lng: -97.152533, lat: 37.519257, postalCode: "67033" },
    { lng: -94.70473, lat: 38.924739, postalCode: "66210" },
    { lng: -94.80033, lat: 39.156275, postalCode: "66109" },
    { lng: -95.54497, lat: 39.857155, postalCode: "66434" },
    { lng: -94.62127, lat: 38.862007, postalCode: "66224" },
    { lng: -94.65527, lat: 38.798648, postalCode: "66085" },
    { lng: -96.52969, lat: 38.344157, postalCode: "66845" },
    { lng: -98.08847, lat: 38.137119, postalCode: "67561" },
    { lng: -101.18541, lat: 37.30755, postalCode: "67952" },
    { lng: -101.01535, lat: 38.089121, postalCode: "67851" },
    { lng: -96.17343, lat: 39.891751, postalCode: "66404" },
    { lng: -95.11389, lat: 39.331919, postalCode: "66020" },
    { lng: -97.25917, lat: 39.027261, postalCode: "67482" },
    { lng: -88.86061, lat: 37.107447, postalCode: "42053" },
    { lng: -88.82074, lat: 36.578907, postalCode: "42085" },
    { lng: -87.59662, lat: 37.143686, postalCode: "42453" },
    { lng: -86.86454, lat: 37.666515, postalCode: "42378" },
    { lng: -86.52846, lat: 37.600179, postalCode: "40119" },
    { lng: -86.40623, lat: 37.647315, postalCode: "40178" },
    { lng: -86.06474, lat: 37.0896, postalCode: "42160" },
    { lng: -87.86409, lat: 36.846471, postalCode: "42211" },
    { lng: -86.88171, lat: 36.860612, postalCode: "42276" },
    { lng: -87.406866, lat: 37.615062, postalCode: "42322" },
    { lng: -85.88175, lat: 37.381189, postalCode: "42713" },
    { lng: -88.40884, lat: 37.217493, postalCode: "42081" },
    { lng: -86.75487, lat: 37.710929, postalCode: "42368" },
    { lng: -85.22988, lat: 36.98182, postalCode: "42741" },
    { lng: -86.67216, lat: 37.197491, postalCode: "42209" },
    { lng: -85.71774, lat: 36.86322, postalCode: "42166" },
    { lng: -86.27499, lat: 37.273726, postalCode: "42285" },
    { lng: -87.112823, lat: 37.745491, postalCode: "42334" },
    { lng: -85.71597, lat: 37.415382, postalCode: "42757" },
    { lng: -87.52635, lat: 37.600857, postalCode: "42455" },
    { lng: -86.99535, lat: 37.86975, postalCode: "42355" },
    { lng: -87.48219, lat: 37.04413, postalCode: "42217" },
    { lng: -87.112823, lat: 37.745491, postalCode: "42304" },
    { lng: -87.72406, lat: 37.784577, postalCode: "42400" },
    { lng: -86.9063, lat: 37.471839, postalCode: "42347" },
    { lng: -85.51436, lat: 37.234802, postalCode: "42743" },
    { lng: -85.73469, lat: 37.199656, postalCode: "42746" },
    { lng: -86.450786, lat: 36.961988, postalCode: "42109" },
    { lng: -86.67216, lat: 37.197491, postalCode: "42288" },
    { lng: -86.1813, lat: 36.767969, postalCode: "42164" },
    { lng: -87.08714, lat: 37.758568, postalCode: "42303" },
    { lng: -87.51498, lat: 37.275896, postalCode: "42410" },
    { lng: -86.49884, lat: 37.413211, postalCode: "42721" },
    { lng: -88.24594, lat: 36.731901, postalCode: "42036" },
    { lng: -86.76297, lat: 37.882498, postalCode: "42348" },
    { lng: -86.31377, lat: 37.88108, postalCode: "40146" },
    { lng: -86.25187, lat: 37.016847, postalCode: "42159" },
    { lng: -88.84412, lat: 36.911107, postalCode: "42035" },
    { lng: -88.183598, lat: 37.157866, postalCode: "42037" },
    { lng: -88.65687, lat: 37.066543, postalCode: "42001" },
    { lng: -88.89132, lat: 36.532059, postalCode: "42041" },
    { lng: -87.09887, lat: 36.953559, postalCode: "42280" },
    { lng: -85.09981, lat: 36.946642, postalCode: "42629" },
    { lng: -85.27561, lat: 37.123196, postalCode: "42728" },
    { lng: -86.58202, lat: 36.718074, postalCode: "42134" },
    { lng: -88.77469, lat: 36.885527, postalCode: "42061" },
    { lng: -89.19789, lat: 36.558598, postalCode: "42050" },
    { lng: -87.46986, lat: 37.238785, postalCode: "42440" },
    { lng: -86.44751, lat: 36.930527, postalCode: "42104" },
    { lng: -86.26557, lat: 37.293315, postalCode: "42207" },
    { lng: -88.97559, lat: 36.678217, postalCode: "42031" },
    { lng: -87.05761, lat: 37.177839, postalCode: "42321" },
    { lng: -88.24498, lat: 37.433695, postalCode: "42084" },
    { lng: -85.96139, lat: 36.721319, postalCode: "42133" },
    { lng: -86.16448, lat: 37.429456, postalCode: "42726" },
    { lng: -86.581957, lat: 36.758166, postalCode: "42135" },
    { lng: -87.42789, lat: 36.664334, postalCode: "42262" },
    { lng: -88.14097, lat: 37.058356, postalCode: "42055" },
    { lng: -87.6579, lat: 36.847924, postalCode: "42232" },
    { lng: -87.01403, lat: 37.198074, postalCode: "42326" },
    { lng: -86.394012, lat: 36.983441, postalCode: "42128" },
    { lng: -86.343941, lat: 37.471943, postalCode: "42755" },
    { lng: -88.995991, lat: 36.863147, postalCode: "42070" },
    { lng: -87.09113, lat: 37.598406, postalCode: "42376" },
    { lng: -86.663225, lat: 36.869206, postalCode: "42283" },
    { lng: -85.73482, lat: 37.066581, postalCode: "42154" },
    { lng: -85.957797, lat: 36.946326, postalCode: "42142" },
    { lng: -88.63778, lat: 36.92516, postalCode: "42027" },
    { lng: -86.509271, lat: 37.248326, postalCode: "42252" },
    { lng: -87.29396, lat: 37.240117, postalCode: "42344" },
    { lng: -88.98347, lat: 36.881954, postalCode: "42023" },
    { lng: -88.74845, lat: 36.627427, postalCode: "42088" },
    { lng: -85.66945, lat: 37.143908, postalCode: "42214" },
    { lng: -86.52913, lat: 37.311676, postalCode: "42287" },
    { lng: -88.94252, lat: 37.149003, postalCode: "42022" },
    { lng: -86.804803, lat: 37.229645, postalCode: "42267" },
    { lng: -88.56359, lat: 36.574074, postalCode: "42079" },
    { lng: -87.18089, lat: 36.673268, postalCode: "42234" },
    { lng: -85.649583, lat: 36.857666, postalCode: "42124" },
    { lng: -87.00657, lat: 37.150125, postalCode: "42324" },
    { lng: -85.39553, lat: 36.954911, postalCode: "42715" },
    { lng: -86.48345, lat: 37.777083, postalCode: "40143" },
    { lng: -88.59046, lat: 37.044233, postalCode: "42003" },
    { lng: -86.46145, lat: 37.010643, postalCode: "42101" },
    { lng: -88.06837, lat: 36.540753, postalCode: "42076" },
    { lng: -86.67988, lat: 37.219254, postalCode: "42261" },
    { lng: -86.92838, lat: 37.729714, postalCode: "42366" },
    { lng: -88.71247, lat: 37.085511, postalCode: "42002" },
    { lng: -87.90199, lat: 37.67416, postalCode: "42437" },
    { lng: -87.18333, lat: 37.166299, postalCode: "42345" },
    { lng: -87.26807, lat: 37.333802, postalCode: "42325" },
    { lng: -87.68273, lat: 36.983293, postalCode: "42215" },
    { lng: -87.65307, lat: 37.36327, postalCode: "42441" },
    { lng: -86.670181, lat: 37.279564, postalCode: "42201" },
    { lng: -88.061294, lat: 37.220735, postalCode: "42011" },
    { lng: -87.496529, lat: 36.898071, postalCode: "42221" },
    { lng: -87.932773, lat: 37.493997, postalCode: "42460" },
    { lng: -87.50378, lat: 37.330791, postalCode: "42431" },
    { lng: -88.48043, lat: 37.054715, postalCode: "42058" },
    { lng: -85.55275, lat: 36.63667, postalCode: "42151" },
    { lng: -87.93391, lat: 37.448198, postalCode: "42403" },
    { lng: -87.65633, lat: 36.657405, postalCode: "42254" },
    { lng: -86.85365, lat: 36.681441, postalCode: "42202" },
    { lng: -86.12987, lat: 37.304661, postalCode: "42729" },
    { lng: -86.394012, lat: 36.983441, postalCode: "42235" },
    { lng: -85.94633, lat: 37.131203, postalCode: "42127" },
    { lng: -85.44177, lat: 37.113189, postalCode: "42761" },
    { lng: -87.87429, lat: 37.12596, postalCode: "42445" },
    { lng: -88.652009, lat: 36.723872, postalCode: "42063" },
    { lng: -87.112823, lat: 37.745491, postalCode: "42377" },
    { lng: -85.55526, lat: 36.8395, postalCode: "42731" },
    { lng: -86.45078, lat: 37.61966, postalCode: "40152" },
    { lng: -87.11162, lat: 37.502836, postalCode: "42352" },
    { lng: -88.26527, lat: 36.765893, postalCode: "42048" },
    { lng: -87.02003, lat: 36.752802, postalCode: "42265" },
    { lng: -85.816472, lat: 37.098753, postalCode: "42152" },
    { lng: -85.58534, lat: 36.988613, postalCode: "42129" },
    { lng: -86.63448, lat: 37.816771, postalCode: "40111" },
    { lng: -88.5005, lat: 36.614839, postalCode: "42040" },
    { lng: -86.29013, lat: 37.215448, postalCode: "42210" },
    { lng: -87.00175, lat: 37.114594, postalCode: "42365" },
    { lng: -86.43079, lat: 37.969766, postalCode: "40171" },
    { lng: -87.112823, lat: 37.745491, postalCode: "42375" },
    { lng: -88.079607, lat: 37.336533, postalCode: "42033" },
    { lng: -86.39972, lat: 37.750101, postalCode: "40144" },
    { lng: -85.31056, lat: 36.898169, postalCode: "42711" },
    { lng: -85.39971, lat: 36.765985, postalCode: "42717" },
    { lng: -87.112823, lat: 37.745491, postalCode: "42302" },
    { lng: -87.323841, lat: 37.693229, postalCode: "42356" },
    { lng: -86.30731, lat: 37.479797, postalCode: "42754" },
    { lng: -86.94284, lat: 37.020366, postalCode: "42256" },
    { lng: -86.76565, lat: 37.349023, postalCode: "42333" },
    { lng: -87.68413, lat: 37.183465, postalCode: "42408" },
    { lng: -86.299361, lat: 37.237211, postalCode: "42257" },
    { lng: -87.37005, lat: 37.859415, postalCode: "42451" },
    { lng: -87.07105, lat: 36.70996, postalCode: "42204" },
    { lng: -86.6846, lat: 37.517388, postalCode: "42361" },
    { lng: -88.97616, lat: 37.08716, postalCode: "42056" },
    { lng: -86.99685, lat: 37.337926, postalCode: "42369" },
    { lng: -87.56055, lat: 37.825094, postalCode: "42420" },
    { lng: -87.46188, lat: 36.654943, postalCode: "42223" },
    { lng: -86.9746, lat: 37.094139, postalCode: "42339" },
    { lng: -86.17181, lat: 37.046968, postalCode: "42171" },
    { lng: -87.49975, lat: 37.514424, postalCode: "42456" },
    { lng: -87.01349, lat: 37.227223, postalCode: "42337" },
    { lng: -85.13539, lat: 36.72024, postalCode: "42602" },
    { lng: -87.76586, lat: 37.801347, postalCode: "42457" },
    { lng: -87.37879, lat: 37.168568, postalCode: "42464" },
    { lng: -86.74121, lat: 37.448681, postalCode: "42370" },
    { lng: -88.092285, lat: 36.6033, postalCode: "42046" },
    { lng: -86.34869, lat: 37.934279, postalCode: "40176" },
    { lng: -87.09071, lat: 37.251296, postalCode: "42332" },
    { lng: -87.34305, lat: 36.780906, postalCode: "42266" },
    { lng: -88.5104, lat: 36.922652, postalCode: "42082" },
    { lng: -85.06142, lat: 37.05401, postalCode: "42642" },
    { lng: -86.38247, lat: 36.969761, postalCode: "42103" },
    { lng: -85.01043, lat: 36.7703, postalCode: "42603" },
    { lng: -87.76428, lat: 37.396445, postalCode: "42450" },
    { lng: -88.83224, lat: 36.800353, postalCode: "42039" },
    { lng: -86.23001, lat: 37.675627, postalCode: "40140" },
    { lng: -87.73173, lat: 37.743264, postalCode: "42406" },
    { lng: -87.18526, lat: 36.887716, postalCode: "42220" },
    { lng: -85.462435, lat: 36.819839, postalCode: "42786" },
    { lng: -85.88288, lat: 37.176738, postalCode: "42749" },
    { lng: -88.7597, lat: 37.089948, postalCode: "42086" },
    { lng: -88.24498, lat: 37.031683, postalCode: "42045" },
    { lng: -86.87625, lat: 37.196636, postalCode: "42273" },
    { lng: -87.54291, lat: 37.674669, postalCode: "42452" },
    { lng: -87.59935, lat: 36.705024, postalCode: "42236" },
    { lng: -86.19818, lat: 37.274689, postalCode: "42259" },
    { lng: -87.81891, lat: 37.729871, postalCode: "42462" },
    { lng: -86.71983, lat: 36.881837, postalCode: "42206" },
    { lng: -87.54828, lat: 37.458645, postalCode: "42436" },
    { lng: -86.885846, lat: 37.157967, postalCode: "42251" },
    { lng: -86.767822, lat: 37.170909, postalCode: "42219" },
    { lng: -86.387046, lat: 36.922292, postalCode: "42102" },
    { lng: -87.219328, lat: 36.929243, postalCode: "42203" },
    { lng: -87.03433, lat: 37.410099, postalCode: "42328" },
    { lng: -87.8394, lat: 37.481719, postalCode: "42404" },
    { lng: -86.804814, lat: 37.827235, postalCode: "42364" },
    { lng: -88.24568, lat: 37.282124, postalCode: "42078" },
    { lng: -87.17107, lat: 37.456942, postalCode: "42350" },
    { lng: -89.0324, lat: 36.96575, postalCode: "42087" },
    { lng: -87.99632, lat: 37.558719, postalCode: "42459" },
    { lng: -87.12992, lat: 37.299591, postalCode: "42330" },
    { lng: -87.03677, lat: 37.172261, postalCode: "42323" },
    { lng: -87.14514, lat: 37.33976, postalCode: "42374" },
    { lng: -86.35202, lat: 36.86316, postalCode: "42122" },
    { lng: -88.82817, lat: 36.968906, postalCode: "42060" },
    { lng: -86.89243, lat: 37.918157, postalCode: "42351" },
    { lng: -88.997, lat: 36.796741, postalCode: "42021" },
    { lng: -88.75871, lat: 36.921462, postalCode: "42069" },
    { lng: -87.29293, lat: 37.508047, postalCode: "42371" },
    { lng: -87.60124, lat: 37.778911, postalCode: "42402" },
    { lng: -86.394012, lat: 36.983441, postalCode: "42270" },
    { lng: -86.39204, lat: 37.670928, postalCode: "40164" },
    { lng: -88.32543, lat: 36.511681, postalCode: "42049" },
    { lng: -86.77254, lat: 37.559872, postalCode: "42338" },
    { lng: -87.41866, lat: 37.860142, postalCode: "42458" },
    { lng: -85.20271, lat: 37.242258, postalCode: "42753" },
    { lng: -85.995572, lat: 36.840503, postalCode: "42156" },
    { lng: -87.599052, lat: 37.807173, postalCode: "42419" },
    { lng: -87.28063, lat: 37.41277, postalCode: "42372" },
    { lng: -87.152148, lat: 37.004492, postalCode: "42216" },
    { lng: -85.50481, lat: 36.824961, postalCode: "42759" },
    { lng: -89.04326, lat: 37.071352, postalCode: "42024" },
    { lng: -87.47617, lat: 36.866845, postalCode: "42240" },
    { lng: -86.00112, lat: 36.820727, postalCode: "42123" },
    { lng: -86.184088, lat: 36.782131, postalCode: "42150" },
    { lng: -88.28273, lat: 36.97877, postalCode: "42044" },
    { lng: -89.50472, lat: 36.513386, postalCode: "38079" },
    { lng: -88.41026, lat: 37.2948, postalCode: "42047" },
    { lng: -87.495, lat: 37.425687, postalCode: "42413" },
    { lng: -85.775282, lat: 36.91297, postalCode: "42130" },
    { lng: -88.29191, lat: 37.150978, postalCode: "42083" },
    { lng: -88.03613, lat: 37.051386, postalCode: "42038" },
    { lng: -85.67567, lat: 36.712179, postalCode: "42167" },
    { lng: -86.9258, lat: 37.3799, postalCode: "42354" },
    { lng: -85.80866, lat: 36.655006, postalCode: "42140" },
    { lng: -87.496529, lat: 36.898071, postalCode: "42241" },
    { lng: -86.25964, lat: 36.661883, postalCode: "42120" },
    { lng: -85.50199, lat: 37.098391, postalCode: "42742" },
    { lng: -87.29499, lat: 37.575772, postalCode: "42327" },
    { lng: -87.91614, lat: 37.771573, postalCode: "42461" },
    { lng: -85.81772, lat: 36.778567, postalCode: "42157" },
    { lng: -86.62507, lat: 36.931416, postalCode: "42274" },
    { lng: -86.66952, lat: 37.433703, postalCode: "42349" },
    { lng: -85.29683, lat: 37.045089, postalCode: "42735" },
    { lng: -87.17419, lat: 37.753347, postalCode: "42301" },
    { lng: -88.63769, lat: 36.852344, postalCode: "42051" },
    { lng: -87.8631, lat: 37.488169, postalCode: "42463" },
    { lng: -88.33515, lat: 36.854812, postalCode: "42025" },
    { lng: -88.06997, lat: 37.331602, postalCode: "42064" },
    { lng: -87.46702, lat: 37.178627, postalCode: "42442" },
    { lng: -86.231194, lat: 37.788538, postalCode: "40106" },
    { lng: -86.40531, lat: 37.443289, postalCode: "42762" },
    { lng: -87.69701, lat: 37.52165, postalCode: "42409" },
    { lng: -88.63449, lat: 36.733761, postalCode: "42066" },
    { lng: -85.90806, lat: 36.82928, postalCode: "42131" },
    { lng: -86.88364, lat: 37.377266, postalCode: "42320" },
    { lng: -87.63786, lat: 37.641689, postalCode: "42444" },
    { lng: -88.01438, lat: 37.216778, postalCode: "42411" },
    { lng: -85.91232, lat: 36.970776, postalCode: "42141" },
    { lng: -85.72639, lat: 37.271372, postalCode: "42722" },
    { lng: -86.21654, lat: 37.753533, postalCode: "40115" },
    { lng: -88.29836, lat: 36.697107, postalCode: "42020" },
    { lng: -86.3173, lat: 37.652706, postalCode: "40145" },
    { lng: -88.44499, lat: 36.716315, postalCode: "42054" },
    { lng: -88.33829, lat: 37.24122, postalCode: "42028" },
    { lng: -86.04006, lat: 36.665767, postalCode: "42153" },
    { lng: -86.44062, lat: 37.223167, postalCode: "42275" },
    { lng: -87.26971, lat: 36.727378, postalCode: "42286" },
    { lng: -88.28746, lat: 36.617443, postalCode: "42071" },
    { lng: -86.49255, lat: 37.965908, postalCode: "40170" },
    { lng: -87.154898, lat: 37.242948, postalCode: "42367" },
    { lng: -86.414768, lat: 37.802295, postalCode: "40153" },
    { lng: -85.90786, lat: 37.291869, postalCode: "42765" },
    { lng: -85.64536, lat: 37.349798, postalCode: "42782" },
    { lng: -89.10667, lat: 36.755242, postalCode: "42032" },
    { lng: -88.37269, lat: 37.013505, postalCode: "42029" },
    { lng: -86.69303, lat: 37.655016, postalCode: "42343" },
    { lng: -86.554, lat: 36.832502, postalCode: "42170" },
    { lng: -86.143188, lat: 37.09425, postalCode: "42163" },
    { lng: -85.370926, lat: 37.121145, postalCode: "42720" },
    { lng: -92.649298, lat: 32.525825, postalCode: "71272" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71120" },
    { lng: -92.85932, lat: 31.671252, postalCode: "71454" },
    { lng: -91.74361, lat: 32.770852, postalCode: "71261" },
    { lng: -90.30479, lat: 29.435146, postalCode: "70354" },
    { lng: -92.526927, lat: 31.203393, postalCode: "71359" },
    { lng: -92.98414, lat: 32.122435, postalCode: "71070" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70009" },
    { lng: -91.691899, lat: 32.138935, postalCode: "71249" },
    { lng: -92.0433, lat: 30.327199, postalCode: "70520" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70146" },
    { lng: -89.878719, lat: 29.877893, postalCode: "70093" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70185" },
    { lng: -93.326527, lat: 30.264232, postalCode: "70664" },
    { lng: -92.26431, lat: 30.234581, postalCode: "70578" },
    { lng: -90.10197, lat: 29.927305, postalCode: "70115" },
    { lng: -93.27047, lat: 30.251665, postalCode: "70669" },
    { lng: -90.10411, lat: 29.951705, postalCode: "70125" },
    { lng: -90.06903, lat: 29.878578, postalCode: "70058" },
    { lng: -90.1012, lat: 29.652752, postalCode: "70067" },
    { lng: -92.159354, lat: 32.490777, postalCode: "71294" },
    { lng: -93.88028, lat: 32.765739, postalCode: "71004" },
    { lng: -90.958219, lat: 30.550219, postalCode: "70786" },
    { lng: -90.17737, lat: 29.91534, postalCode: "70094" },
    { lng: -91.07576, lat: 30.030819, postalCode: "70341" },
    { lng: -93.68912, lat: 32.913383, postalCode: "71064" },
    { lng: -90.61738, lat: 30.058276, postalCode: "70051" },
    { lng: -92.927989, lat: 31.749699, postalCode: "71471" },
    { lng: -91.74246, lat: 30.514159, postalCode: "70750" },
    { lng: -93.40907, lat: 29.778234, postalCode: "70631" },
    { lng: -91.84786, lat: 30.336379, postalCode: "70521" },
    { lng: -90.513522, lat: 30.08677, postalCode: "70000" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70167" },
    { lng: -90.05335, lat: 29.915355, postalCode: "70053" },
    { lng: -92.95173, lat: 32.331964, postalCode: "71008" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70004" },
    { lng: -90.24378, lat: 29.275485, postalCode: "70357" },
    { lng: -90.46202, lat: 29.670214, postalCode: "70355" },
    { lng: -92.526927, lat: 31.203393, postalCode: "71365" },
    { lng: -92.79465, lat: 31.452874, postalCode: "71447" },
    { lng: -92.72596, lat: 30.082515, postalCode: "70549" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70183" },
    { lng: -93.13957, lat: 30.472103, postalCode: "70657" },
    { lng: -93.42301, lat: 31.215039, postalCode: "71403" },
    { lng: -91.398944, lat: 32.82388, postalCode: "71242" },
    { lng: -93.02735, lat: 30.518748, postalCode: "70658" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70011" },
    { lng: -90.03036, lat: 29.970404, postalCode: "70117" },
    { lng: -93.97534, lat: 32.679068, postalCode: "71060" },
    { lng: -90.83425, lat: 29.718856, postalCode: "70395" },
    { lng: -90.546138, lat: 30.071155, postalCode: "70048" },
    { lng: -92.58483, lat: 31.592547, postalCode: "71481" },
    { lng: -91.47351, lat: 30.962103, postalCode: "70787" },
    { lng: -92.34017, lat: 31.823038, postalCode: "71479" },
    { lng: -93.079055, lat: 31.747563, postalCode: "71497" },
    { lng: -92.16211, lat: 30.198258, postalCode: "70529" },
    { lng: -91.4343, lat: 31.796575, postalCode: "71375" },
    { lng: -89.941408, lat: 30.591363, postalCode: "70428" },
    { lng: -93.29586, lat: 31.693192, postalCode: "71469" },
    { lng: -92.62099, lat: 31.004922, postalCode: "71433" },
    { lng: -90.945836, lat: 30.295446, postalCode: "70728" },
    { lng: -90.079501, lat: 29.987528, postalCode: "70199" },
    { lng: -91.15362, lat: 30.287104, postalCode: "70780" },
    { lng: -92.15702, lat: 31.060792, postalCode: "71341" },
    { lng: -89.96118, lat: 29.946404, postalCode: "70043" },
    { lng: -90.06264, lat: 30.006353, postalCode: "70122" },
    { lng: -89.88582, lat: 30.534785, postalCode: "70464" },
    { lng: -90.483189, lat: 30.091158, postalCode: "70069" },
    { lng: -89.35898, lat: 29.277457, postalCode: "70091" },
    { lng: -92.15107, lat: 31.95047, postalCode: "71441" },
    { lng: -93.50366, lat: 32.31718, postalCode: "71051" },
    { lng: -89.7883, lat: 29.529091, postalCode: "70083" },
    { lng: -92.000019, lat: 30.156506, postalCode: "70509" },
    { lng: -93.250705, lat: 32.576948, postalCode: "71058" },
    { lng: -91.4107, lat: 32.887552, postalCode: "71263" },
    { lng: -92.50144, lat: 31.163538, postalCode: "71485" },
    { lng: -90.01211, lat: 29.227769, postalCode: "70358" },
    { lng: -90.67464, lat: 30.535477, postalCode: "70744" },
    { lng: -89.90398, lat: 30.658471, postalCode: "70463" },
    { lng: -90.869481, lat: 30.204707, postalCode: "70707" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70170" },
    { lng: -93.3636, lat: 30.245205, postalCode: "70663" },
    { lng: -89.87774, lat: 30.762908, postalCode: "70427" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70898" },
    { lng: -91.6325, lat: 30.548421, postalCode: "70756" },
    { lng: -92.79996, lat: 31.118135, postalCode: "71438" },
    { lng: -92.89842, lat: 31.541077, postalCode: "71416" },
    { lng: -93.097958, lat: 31.612444, postalCode: "71428" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70010" },
    { lng: -90.07757, lat: 29.956804, postalCode: "70112" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70177" },
    { lng: -92.80633, lat: 32.513699, postalCode: "71275" },
    { lng: -93.54869, lat: 31.514631, postalCode: "71449" },
    { lng: -92.572039, lat: 30.97759, postalCode: "71436" },
    { lng: -90.55987, lat: 30.059516, postalCode: "70084" },
    { lng: -90.25133, lat: 30.022853, postalCode: "70065" },
    { lng: -90.109823, lat: 29.940138, postalCode: "70155" },
    { lng: -90.29969, lat: 29.942703, postalCode: "70031" },
    { lng: -93.27071, lat: 30.835881, postalCode: "70634" },
    { lng: -94.00923, lat: 32.420611, postalCode: "71007" },
    { lng: -90.38595, lat: 29.981674, postalCode: "70078" },
    { lng: -90.30638, lat: 30.520782, postalCode: "70455" },
    { lng: -91.61612, lat: 30.605673, postalCode: "70732" },
    { lng: -91.72874, lat: 32.156791, postalCode: "71295" },
    { lng: -91.00104, lat: 30.103905, postalCode: "70346" },
    { lng: -91.46569, lat: 29.761665, postalCode: "70540" },
    { lng: -93.8043, lat: 32.397255, postalCode: "71118" },
    { lng: -91.11541, lat: 30.810733, postalCode: "70730" },
    { lng: -91.91001, lat: 31.05305, postalCode: "71355" },
    { lng: -93.99733, lat: 32.978828, postalCode: "71069" },
    { lng: -93.14977, lat: 30.239906, postalCode: "70615" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70174" },
    { lng: -91.973736, lat: 30.475194, postalCode: "70551" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70153" },
    { lng: -90.16902, lat: 29.984096, postalCode: "70001" },
    { lng: -92.32228, lat: 30.996931, postalCode: "71325" },
    { lng: -92.086077, lat: 30.57445, postalCode: "70571" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70884" },
    { lng: -91.90578, lat: 29.907136, postalCode: "70513" },
    { lng: -90.50206, lat: 30.923938, postalCode: "70444" },
    { lng: -89.364034, lat: 29.285081, postalCode: "70081" },
    { lng: -93.200936, lat: 31.111979, postalCode: "70642" },
    { lng: -91.97998, lat: 31.769334, postalCode: "71401" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70186" },
    { lng: -91.7722, lat: 32.350427, postalCode: "71218" },
    { lng: -91.91702, lat: 30.411741, postalCode: "70512" },
    { lng: -90.49218, lat: 29.620122, postalCode: "70374" },
    { lng: -92.5677, lat: 30.261809, postalCode: "70537" },
    { lng: -89.881256, lat: 30.42551, postalCode: "70459" },
    { lng: -91.33786, lat: 30.633678, postalCode: "70736" },
    { lng: -93.5082, lat: 31.810803, postalCode: "71065" },
    { lng: -91.42148, lat: 30.399271, postalCode: "70740" },
    { lng: -89.881256, lat: 30.42551, postalCode: "70470" },
    { lng: -93.66472, lat: 30.338939, postalCode: "70661" },
    { lng: -90.1898, lat: 30.616777, postalCode: "70437" },
    { lng: -91.95294, lat: 30.945975, postalCode: "71362" },
    { lng: -91.67672, lat: 30.878083, postalCode: "70747" },
    { lng: -93.22745, lat: 31.138723, postalCode: "71446" },
    { lng: -91.57852, lat: 31.755985, postalCode: "71326" },
    { lng: -92.394536, lat: 31.415125, postalCode: "71405" },
    { lng: -90.35689, lat: 30.623502, postalCode: "70446" },
    { lng: -92.176655, lat: 30.032434, postalCode: "70511" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70139" },
    { lng: -93.6991, lat: 32.697056, postalCode: "71006" },
    { lng: -92.398384, lat: 31.139651, postalCode: "71315" },
    { lng: -92.13152, lat: 30.101305, postalCode: "70555" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70883" },
    { lng: -93.78, lat: 32.449286, postalCode: "71108" },
    { lng: -90.05999, lat: 30.367119, postalCode: "70448" },
    { lng: -93.28194, lat: 30.921274, postalCode: "70659" },
    { lng: -91.07975, lat: 30.216906, postalCode: "70721" },
    { lng: -91.691899, lat: 32.138935, postalCode: "71324" },
    { lng: -90.10947, lat: 30.006003, postalCode: "70124" },
    { lng: -93.200936, lat: 31.111979, postalCode: "71475" },
    { lng: -92.13951, lat: 32.026445, postalCode: "71415" },
    { lng: -90.07953, lat: 30.53978, postalCode: "70435" },
    { lng: -92.30363, lat: 30.365586, postalCode: "70516" },
    { lng: -91.52561, lat: 29.759314, postalCode: "70538" },
    { lng: -90.422504, lat: 30.505061, postalCode: "70404" },
    { lng: -92.03811, lat: 29.941157, postalCode: "70533" },
    { lng: -93.46457, lat: 31.492622, postalCode: "71426" },
    { lng: -92.50515, lat: 31.302432, postalCode: "71303" },
    { lng: -91.1497, lat: 30.411391, postalCode: "70808" },
    { lng: -92.446135, lat: 31.075638, postalCode: "71306" },
    { lng: -92.159354, lat: 32.490777, postalCode: "71240" },
    { lng: -92.66375, lat: 30.242533, postalCode: "70546" },
    { lng: -93.367949, lat: 32.062391, postalCode: "71036" },
    { lng: -92.65296, lat: 32.829213, postalCode: "71222" },
    { lng: -93.97188, lat: 32.748354, postalCode: "71061" },
    { lng: -93.361238, lat: 31.1019, postalCode: "71443" },
    { lng: -91.05456, lat: 29.990967, postalCode: "70391" },
    { lng: -92.49164, lat: 32.533389, postalCode: "71227" },
    { lng: -91.04812, lat: 30.537938, postalCode: "70818" },
    { lng: -90.00103, lat: 30.482725, postalCode: "70420" },
    { lng: -90.12419, lat: 29.715795, postalCode: "70036" },
    { lng: -90.69689, lat: 29.384672, postalCode: "70353" },
    { lng: -93.02395, lat: 31.252873, postalCode: "71474" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70160" },
    { lng: -92.16421, lat: 32.024884, postalCode: "71435" },
    { lng: -93.155616, lat: 32.25043, postalCode: "71076" },
    { lng: -93.55133, lat: 32.569798, postalCode: "71037" },
    { lng: -91.46181, lat: 30.609979, postalCode: "70762" },
    { lng: -90.57297, lat: 30.386031, postalCode: "70462" },
    { lng: -91.78754, lat: 32.457301, postalCode: "71269" },
    { lng: -92.54417, lat: 30.005408, postalCode: "70542" },
    { lng: -93.44422, lat: 31.398236, postalCode: "71429" },
    { lng: -91.89085, lat: 30.717386, postalCode: "71358" },
    { lng: -91.216458, lat: 32.341594, postalCode: "71233" },
    { lng: -91.75288, lat: 30.698425, postalCode: "71353" },
    { lng: -93.101086, lat: 32.366002, postalCode: "71080" },
    { lng: -93.17508, lat: 31.494144, postalCode: "71468" },
    { lng: -92.3207, lat: 29.984184, postalCode: "70548" },
    { lng: -91.20531, lat: 32.660116, postalCode: "71286" },
    { lng: -92.159354, lat: 32.490777, postalCode: "71281" },
    { lng: -91.59119, lat: 30.547187, postalCode: "70717" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70175" },
    { lng: -92.88735, lat: 31.378139, postalCode: "71427" },
    { lng: -91.71294, lat: 30.076638, postalCode: "70552" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70179" },
    { lng: -91.46806, lat: 30.439483, postalCode: "70772" },
    { lng: -93.41547, lat: 31.77112, postalCode: "71450" },
    { lng: -93.73671, lat: 32.484232, postalCode: "71104" },
    { lng: -92.83199, lat: 30.246368, postalCode: "70591" },
    { lng: -90.51254, lat: 30.875453, postalCode: "70465" },
    { lng: -91.67737, lat: 32.919093, postalCode: "71223" },
    { lng: -93.621234, lat: 31.448907, postalCode: "71460" },
    { lng: -89.881256, lat: 30.42551, postalCode: "70469" },
    { lng: -90.869481, lat: 30.204707, postalCode: "70738" },
    { lng: -92.98075, lat: 31.612618, postalCode: "71452" },
    { lng: -90.865568, lat: 29.382117, postalCode: "70361" },
    { lng: -90.99489, lat: 30.642585, postalCode: "70770" },
    { lng: -93.29549, lat: 32.429157, postalCode: "71039" },
    { lng: -90.868876, lat: 30.201004, postalCode: "70718" },
    { lng: -91.47664, lat: 32.597244, postalCode: "71237" },
    { lng: -89.94043, lat: 29.749706, postalCode: "70040" },
    { lng: -93.326527, lat: 30.264232, postalCode: "70606" },
    { lng: -90.16245, lat: 30.011517, postalCode: "70002" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70187" },
    { lng: -93.05705, lat: 32.518748, postalCode: "71028" },
    { lng: -93.21762, lat: 31.072209, postalCode: "71459" },
    { lng: -90.06403, lat: 29.968054, postalCode: "70116" },
    { lng: -90.480403, lat: 30.513982, postalCode: "70402" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71137" },
    { lng: -92.9059, lat: 32.01716, postalCode: "71031" },
    { lng: -90.55223, lat: 29.697038, postalCode: "70375" },
    { lng: -92.23657, lat: 31.110171, postalCode: "71330" },
    { lng: -92.72027, lat: 32.341112, postalCode: "71268" },
    { lng: -92.04319, lat: 30.98676, postalCode: "71327" },
    { lng: -92.94724, lat: 31.440908, postalCode: "71434" },
    { lng: -91.22812, lat: 30.826285, postalCode: "70748" },
    { lng: -91.82382, lat: 31.765818, postalCode: "71340" },
    { lng: -93.37575, lat: 29.995358, postalCode: "70645" },
    { lng: -90.843449, lat: 30.337495, postalCode: "70727" },
    { lng: -93.608968, lat: 32.62762, postalCode: "71171" },
    { lng: -91.07895, lat: 30.972292, postalCode: "70761" },
    { lng: -92.67179, lat: 32.683644, postalCode: "71235" },
    { lng: -91.70531, lat: 30.822847, postalCode: "70715" },
    { lng: -93.203923, lat: 32.34205, postalCode: "71095" },
    { lng: -91.98682, lat: 29.945707, postalCode: "70528" },
    { lng: -90.07195, lat: 29.938005, postalCode: "70130" },
    { lng: -93.326527, lat: 30.264232, postalCode: "70609" },
    { lng: -93.10383, lat: 32.204949, postalCode: "71016" },
    { lng: -90.869481, lat: 30.204707, postalCode: "70376" },
    { lng: -92.79442, lat: 30.86741, postalCode: "70638" },
    { lng: -91.48698, lat: 31.540784, postalCode: "71373" },
    { lng: -92.19259, lat: 31.861406, postalCode: "71465" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70178" },
    { lng: -91.194863, lat: 30.520111, postalCode: "70813" },
    { lng: -90.81809, lat: 29.797776, postalCode: "70301" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71156" },
    { lng: -90.502848, lat: 29.458689, postalCode: "70302" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71154" },
    { lng: -91.17657, lat: 32.397286, postalCode: "71282" },
    { lng: -91.17305, lat: 30.44629, postalCode: "70802" },
    { lng: -93.79469, lat: 32.278422, postalCode: "71078" },
    { lng: -93.02552, lat: 31.817257, postalCode: "71414" },
    { lng: -91.19813, lat: 29.931838, postalCode: "70339" },
    { lng: -91.99993, lat: 30.103107, postalCode: "70592" },
    { lng: -90.5538, lat: 30.033974, postalCode: "70049" },
    { lng: -92.3529, lat: 32.641967, postalCode: "71234" },
    { lng: -91.7126, lat: 30.917437, postalCode: "70753" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70184" },
    { lng: -90.08747, lat: 29.974504, postalCode: "70119" },
    { lng: -91.51382, lat: 31.667978, postalCode: "71334" },
    { lng: -93.8877, lat: 32.42067, postalCode: "71129" },
    { lng: -89.93089, lat: 30.613004, postalCode: "70431" },
    { lng: -93.8191, lat: 32.191147, postalCode: "71030" },
    { lng: -90.6097, lat: 29.715302, postalCode: "70394" },
    { lng: -90.92758, lat: 30.608471, postalCode: "70706" },
    { lng: -92.05447, lat: 31.067379, postalCode: "71350" },
    { lng: -89.97736, lat: 30.033475, postalCode: "70127" },
    { lng: -90.070922, lat: 29.922905, postalCode: "70158" },
    { lng: -89.88511, lat: 30.063059, postalCode: "70129" },
    { lng: -91.50809, lat: 32.438621, postalCode: "71232" },
    { lng: -90.69956, lat: 30.04401, postalCode: "70071" },
    { lng: -90.75228, lat: 30.447866, postalCode: "70754" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71133" },
    { lng: -91.31686, lat: 32.995821, postalCode: "71253" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70837" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70073" },
    { lng: -90.07532, lat: 29.95006, postalCode: "70163" },
    { lng: -92.526927, lat: 31.203393, postalCode: "71448" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70894" },
    { lng: -91.02586, lat: 29.995157, postalCode: "70393" },
    { lng: -92.91837, lat: 30.366126, postalCode: "70640" },
    { lng: -91.26324, lat: 30.473536, postalCode: "70767" },
    { lng: -91.70832, lat: 31.938233, postalCode: "71378" },
    { lng: -92.46015, lat: 31.294532, postalCode: "71301" },
    { lng: -93.40802, lat: 32.803257, postalCode: "71018" },
    { lng: -91.08035, lat: 30.403341, postalCode: "70809" },
    { lng: -91.39852, lat: 30.680083, postalCode: "70783" },
    { lng: -93.30398, lat: 32.176112, postalCode: "71034" },
    { lng: -89.82225, lat: 29.86089, postalCode: "70085" },
    { lng: -92.52474, lat: 31.050239, postalCode: "71430" },
    { lng: -92.98065, lat: 30.685067, postalCode: "70637" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70189" },
    { lng: -92.58568, lat: 30.47461, postalCode: "70515" },
    { lng: -92.32115, lat: 30.566122, postalCode: "70524" },
    { lng: -92.06564, lat: 30.835875, postalCode: "71356" },
    { lng: -93.69008, lat: 32.234006, postalCode: "71027" },
    { lng: -91.6331, lat: 29.739932, postalCode: "70562" },
    { lng: -92.075615, lat: 32.496764, postalCode: "71208" },
    { lng: -89.59696, lat: 29.384033, postalCode: "70050" },
    { lng: -92.095109, lat: 30.20812, postalCode: "70596" },
    { lng: -91.36026, lat: 30.261384, postalCode: "70765" },
    { lng: -91.03815, lat: 30.425791, postalCode: "70816" },
    { lng: -90.2469, lat: 29.991051, postalCode: "70062" },
    { lng: -93.89254, lat: 32.983957, postalCode: "71044" },
    { lng: -91.990319, lat: 30.117148, postalCode: "70952" },
    { lng: -93.41221, lat: 30.44431, postalCode: "70633" },
    { lng: -92.48404, lat: 31.527479, postalCode: "71407" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70162" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71130" },
    { lng: -91.77941, lat: 31.596272, postalCode: "71363" },
    { lng: -92.02759, lat: 30.2786, postalCode: "70507" },
    { lng: -93.48365, lat: 31.920574, postalCode: "71063" },
    { lng: -90.08105, lat: 30.400949, postalCode: "70471" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70835" },
    { lng: -90.95909, lat: 30.130584, postalCode: "70725" },
    { lng: -91.91043, lat: 31.899409, postalCode: "71425" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70195" },
    { lng: -91.41468, lat: 30.622391, postalCode: "70749" },
    { lng: -90.802759, lat: 30.027914, postalCode: "70792" },
    { lng: -91.97899, lat: 31.095889, postalCode: "71329" },
    { lng: -89.81176, lat: 30.298048, postalCode: "70460" },
    { lng: -91.54679, lat: 29.841827, postalCode: "70514" },
    { lng: -92.01866, lat: 30.21385, postalCode: "70504" },
    { lng: -90.80054, lat: 30.307865, postalCode: "70733" },
    { lng: -91.358149, lat: 30.917453, postalCode: "70784" },
    { lng: -91.856326, lat: 32.49191, postalCode: "71279" },
    { lng: -89.881256, lat: 30.42551, postalCode: "70457" },
    { lng: -91.566273, lat: 30.7513, postalCode: "70751" },
    { lng: -92.1835, lat: 30.517709, postalCode: "70550" },
    { lng: -91.24121, lat: 32.090387, postalCode: "71357" },
    { lng: -92.30628, lat: 30.710639, postalCode: "70586" },
    { lng: -91.911387, lat: 30.079692, postalCode: "70519" },
    { lng: -91.18633, lat: 30.44884, postalCode: "70801" },
    { lng: -93.87995, lat: 32.890777, postalCode: "71043" },
    { lng: -90.037115, lat: 30.83503, postalCode: "70429" },
    { lng: -92.10755, lat: 32.519393, postalCode: "71201" },
    { lng: -93.71997, lat: 31.672295, postalCode: "71462" },
    { lng: -92.9062, lat: 32.57761, postalCode: "71001" },
    { lng: -92.91992, lat: 30.107308, postalCode: "70646" },
    { lng: -93.913834, lat: 32.117539, postalCode: "71050" },
    { lng: -92.41968, lat: 30.781744, postalCode: "70576" },
    { lng: -91.24853, lat: 30.270694, postalCode: "70764" },
    { lng: -91.102808, lat: 30.433083, postalCode: "70848" },
    { lng: -91.59294, lat: 30.949066, postalCode: "70712" },
    { lng: -93.608059, lat: 32.048905, postalCode: "71053" },
    { lng: -90.77619, lat: 30.019278, postalCode: "70743" },
    { lng: -91.53701, lat: 30.934986, postalCode: "70782" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70033" },
    { lng: -92.301795, lat: 30.228409, postalCode: "70527" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70165" },
    { lng: -89.76917, lat: 30.401481, postalCode: "70452" },
    { lng: -92.08283, lat: 32.700356, postalCode: "71280" },
    { lng: -91.26121, lat: 30.350306, postalCode: "70710" },
    { lng: -91.843528, lat: 32.823863, postalCode: "71221" },
    { lng: -90.85714, lat: 30.013027, postalCode: "70086" },
    { lng: -89.872599, lat: 29.61829, postalCode: "70046" },
    { lng: -90.07828, lat: 30.375852, postalCode: "70440" },
    { lng: -92.01974, lat: 30.163368, postalCode: "70508" },
    { lng: -91.86725, lat: 31.586986, postalCode: "71343" },
    { lng: -91.00875, lat: 30.211172, postalCode: "70734" },
    { lng: -91.20759, lat: 32.806899, postalCode: "71254" },
    { lng: -93.89064, lat: 32.487601, postalCode: "71119" },
    { lng: -93.104943, lat: 31.120174, postalCode: "71444" },
    { lng: -90.03072, lat: 29.894261, postalCode: "70056" },
    { lng: -92.57935, lat: 32.957393, postalCode: "71277" },
    { lng: -92.98886, lat: 31.660742, postalCode: "71456" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70176" },
    { lng: -90.52556, lat: 29.428462, postalCode: "70377" },
    { lng: -90.452775, lat: 30.432586, postalCode: "70494" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71149" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71134" },
    { lng: -91.41898, lat: 30.546033, postalCode: "70729" },
    { lng: -92.159354, lat: 32.490777, postalCode: "71210" },
    { lng: -91.92715, lat: 31.024614, postalCode: "71339" },
    { lng: -91.51619, lat: 30.483791, postalCode: "70757" },
    { lng: -92.026969, lat: 30.319799, postalCode: "70502" },
    { lng: -91.5301, lat: 29.883266, postalCode: "70523" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71135" },
    { lng: -91.133905, lat: 30.386267, postalCode: "70804" },
    { lng: -92.07786, lat: 30.394648, postalCode: "70584" },
    { lng: -91.90678, lat: 32.814969, postalCode: "71220" },
    { lng: -92.419836, lat: 31.36923, postalCode: "71361" },
    { lng: -92.72489, lat: 32.272141, postalCode: "71247" },
    { lng: -91.06862, lat: 30.484756, postalCode: "70814" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71164" },
    { lng: -90.068022, lat: 30.030902, postalCode: "70148" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71166" },
    { lng: -92.80471, lat: 32.937726, postalCode: "71079" },
    { lng: -90.944418, lat: 29.696385, postalCode: "70352" },
    { lng: -91.171456, lat: 30.412991, postalCode: "70893" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70096" },
    { lng: -93.83918, lat: 32.828541, postalCode: "71029" },
    { lng: -92.01877, lat: 30.202251, postalCode: "70505" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70150" },
    { lng: -92.42652, lat: 30.63, postalCode: "70554" },
    { lng: -91.1593, lat: 30.153959, postalCode: "70788" },
    { lng: -90.10933, lat: 29.869283, postalCode: "70072" },
    { lng: -89.98982, lat: 29.917005, postalCode: "70131" },
    { lng: -92.42469, lat: 31.261716, postalCode: "71302" },
    { lng: -93.39035, lat: 32.487485, postalCode: "71023" },
    { lng: -92.88732, lat: 29.77124, postalCode: "70643" },
    { lng: -93.38882, lat: 31.330255, postalCode: "71439" },
    { lng: -92.565884, lat: 31.951166, postalCode: "71440" },
    { lng: -92.89081, lat: 30.710561, postalCode: "70654" },
    { lng: -90.038021, lat: 29.930433, postalCode: "70144" },
    { lng: -89.477464, lat: 29.380066, postalCode: "70042" },
    { lng: -92.6445, lat: 31.915828, postalCode: "71483" },
    { lng: -90.93955, lat: 30.31144, postalCode: "70769" },
    { lng: -90.51064, lat: 30.769493, postalCode: "70456" },
    { lng: -91.6698, lat: 29.910582, postalCode: "70544" },
    { lng: -89.951441, lat: 29.96765, postalCode: "70044" },
    { lng: -92.67485, lat: 31.332031, postalCode: "71409" },
    { lng: -90.125666, lat: 30.492753, postalCode: "70434" },
    { lng: -90.84644, lat: 30.063433, postalCode: "70723" },
    { lng: -91.64475, lat: 32.037425, postalCode: "71336" },
    { lng: -93.326527, lat: 30.264232, postalCode: "70629" },
    { lng: -89.89726, lat: 29.902193, postalCode: "70092" },
    { lng: -90.50196, lat: 30.560995, postalCode: "70466" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71153" },
    { lng: -93.00582, lat: 30.811926, postalCode: "70662" },
    { lng: -93.8305, lat: 32.56443, postalCode: "71107" },
    { lng: -90.73674, lat: 30.03318, postalCode: "70763" },
    { lng: -90.570722, lat: 29.700243, postalCode: "70594" },
    { lng: -92.096266, lat: 30.285602, postalCode: "70369" },
    { lng: -93.08273, lat: 32.129489, postalCode: "71002" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70063" },
    { lng: -92.159354, lat: 32.490777, postalCode: "71213" },
    { lng: -93.45992, lat: 32.994597, postalCode: "71075" },
    { lng: -90.68915, lat: 30.053907, postalCode: "70052" },
    { lng: -91.09672, lat: 30.925815, postalCode: "70789" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71162" },
    { lng: -91.58029, lat: 30.720494, postalCode: "70759" },
    { lng: -93.97599, lat: 32.849008, postalCode: "71082" },
    { lng: -92.41578, lat: 30.492895, postalCode: "70535" },
    { lng: -92.70152, lat: 32.957004, postalCode: "71256" },
    { lng: -91.89546, lat: 32.678312, postalCode: "71229" },
    { lng: -91.31347, lat: 29.69828, postalCode: "70392" },
    { lng: -92.072683, lat: 32.528593, postalCode: "71212" },
    { lng: -93.19632, lat: 30.129908, postalCode: "70607" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70704" },
    { lng: -90.52108, lat: 30.723034, postalCode: "70422" },
    { lng: -90.64073, lat: 30.055429, postalCode: "70076" },
    { lng: -92.37921, lat: 30.213767, postalCode: "70526" },
    { lng: -93.74861, lat: 32.505352, postalCode: "71101" },
    { lng: -90.16037, lat: 29.961875, postalCode: "70121" },
    { lng: -91.78463, lat: 31.950754, postalCode: "71243" },
    { lng: -93.95402, lat: 32.168532, postalCode: "71046" },
    { lng: -92.72641, lat: 31.200922, postalCode: "71466" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70141" },
    { lng: -92.23581, lat: 32.885372, postalCode: "71260" },
    { lng: -90.47254, lat: 30.517866, postalCode: "70401" },
    { lng: -92.890325, lat: 31.66581, postalCode: "71545" },
    { lng: -89.95638, lat: 30.05031, postalCode: "70128" },
    { lng: -92.42562, lat: 30.675449, postalCode: "70580" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70060" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71165" },
    { lng: -90.98014, lat: 30.585687, postalCode: "70739" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70152" },
    { lng: -93.98356, lat: 31.991863, postalCode: "71049" },
    { lng: -91.14763, lat: 30.584787, postalCode: "70714" },
    { lng: -93.80022, lat: 32.474453, postalCode: "71109" },
    { lng: -93.5026, lat: 32.596886, postalCode: "71067" },
    { lng: -92.14872, lat: 29.943573, postalCode: "70510" },
    { lng: -92.69763, lat: 32.229884, postalCode: "71251" },
    { lng: -91.259347, lat: 29.694629, postalCode: "70381" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70143" },
    { lng: -93.03224, lat: 30.077002, postalCode: "70630" },
    { lng: -93.3102, lat: 32.941481, postalCode: "71072" },
    { lng: -90.74688, lat: 30.867259, postalCode: "70441" },
    { lng: -93.7133, lat: 32.457903, postalCode: "71105" },
    { lng: -92.71794, lat: 32.526277, postalCode: "71245" },
    { lng: -93.11344, lat: 32.954851, postalCode: "71038" },
    { lng: -91.67463, lat: 31.85889, postalCode: "71368" },
    { lng: -93.2109, lat: 30.32797, postalCode: "70611" },
    { lng: -93.079055, lat: 31.747563, postalCode: "71458" },
    { lng: -92.73951, lat: 30.24711, postalCode: "70581" },
    { lng: -93.326527, lat: 30.264232, postalCode: "70616" },
    { lng: -91.17865, lat: 30.534088, postalCode: "70807" },
    { lng: -92.159354, lat: 32.490777, postalCode: "71207" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70831" },
    { lng: -90.20536, lat: 29.952605, postalCode: "70123" },
    { lng: -89.78139, lat: 29.572751, postalCode: "70082" },
    { lng: -91.36361, lat: 30.613588, postalCode: "70773" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70895" },
    { lng: -91.8003, lat: 30.148473, postalCode: "70582" },
    { lng: -90.9636, lat: 29.829135, postalCode: "70372" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70164" },
    { lng: -92.57614, lat: 30.188258, postalCode: "70556" },
    { lng: -89.815993, lat: 30.067646, postalCode: "70182" },
    { lng: -92.64696, lat: 32.52476, postalCode: "71270" },
    { lng: -90.97455, lat: 29.658394, postalCode: "70356" },
    { lng: -90.35142, lat: 29.907162, postalCode: "70070" },
    { lng: -91.33252, lat: 30.587305, postalCode: "70720" },
    { lng: -90.08408, lat: 29.943505, postalCode: "70113" },
    { lng: -89.92089, lat: 29.935089, postalCode: "70075" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70159" },
    { lng: -92.06755, lat: 32.130124, postalCode: "71418" },
    { lng: -92.694679, lat: 30.484853, postalCode: "70832" },
    { lng: -91.1188, lat: 30.504989, postalCode: "70812" },
    { lng: -92.08881, lat: 32.456862, postalCode: "71202" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70896" },
    { lng: -91.082468, lat: 30.433837, postalCode: "70827" },
    { lng: -91.220844, lat: 29.688425, postalCode: "70340" },
    { lng: -92.19546, lat: 31.365331, postalCode: "71328" },
    { lng: -93.27979, lat: 31.114793, postalCode: "71461" },
    { lng: -93.42412, lat: 30.154028, postalCode: "70665" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71136" },
    { lng: -90.6903, lat: 29.560581, postalCode: "70363" },
    { lng: -91.11541, lat: 32.582205, postalCode: "71276" },
    { lng: -91.01659, lat: 30.46534, postalCode: "70819" },
    { lng: -90.18519, lat: 30.424049, postalCode: "70447" },
    { lng: -92.04384, lat: 32.547067, postalCode: "71203" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70156" },
    { lng: -93.70836, lat: 32.552315, postalCode: "71111" },
    { lng: -91.95251, lat: 30.134801, postalCode: "70518" },
    { lng: -92.05912, lat: 30.211901, postalCode: "70506" },
    { lng: -91.45776, lat: 32.714211, postalCode: "71266" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70054" },
    { lng: -91.77031, lat: 31.252471, postalCode: "71316" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70145" },
    { lng: -91.86169, lat: 30.301254, postalCode: "70517" },
    { lng: -89.820725, lat: 30.90169, postalCode: "70467" },
    { lng: -91.93006, lat: 30.549089, postalCode: "70577" },
    { lng: -90.12347, lat: 29.952305, postalCode: "70118" },
    { lng: -92.6815, lat: 31.5254, postalCode: "71417" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70151" },
    { lng: -93.608968, lat: 32.62762, postalCode: "71113" },
    { lng: -90.30965, lat: 29.962601, postalCode: "70087" },
    { lng: -92.3126, lat: 30.895994, postalCode: "71367" },
    { lng: -91.71527, lat: 32.308243, postalCode: "71219" },
    { lng: -93.33008, lat: 32.304429, postalCode: "71068" },
    { lng: -92.51166, lat: 30.142775, postalCode: "70559" },
    { lng: -91.02187, lat: 29.91599, postalCode: "70390" },
    { lng: -90.921, lat: 30.823174, postalCode: "70722" },
    { lng: -92.22751, lat: 31.695553, postalCode: "71371" },
    { lng: -90.12066, lat: 30.844465, postalCode: "70438" },
    { lng: -93.55827, lat: 30.685274, postalCode: "70653" },
    { lng: -92.12872, lat: 31.654792, postalCode: "71342" },
    { lng: -93.30297, lat: 32.535107, postalCode: "71073" },
    { lng: -92.06338, lat: 30.101464, postalCode: "70558" },
    { lng: -93.74542, lat: 32.426104, postalCode: "71106" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70822" },
    { lng: -93.00386, lat: 30.243213, postalCode: "70647" },
    { lng: -89.93118, lat: 30.327126, postalCode: "70445" },
    { lng: -90.81947, lat: 30.212699, postalCode: "70774" },
    { lng: -93.03176, lat: 32.78288, postalCode: "71040" },
    { lng: -92.10099, lat: 30.244311, postalCode: "70583" },
    { lng: -92.45283, lat: 32.277816, postalCode: "71226" },
    { lng: -92.74582, lat: 30.620456, postalCode: "70655" },
    { lng: -90.19128, lat: 30.013985, postalCode: "70006" },
    { lng: -93.09805, lat: 31.903462, postalCode: "71411" },
    { lng: -93.42135, lat: 30.586251, postalCode: "70660" },
    { lng: -90.99921, lat: 30.387642, postalCode: "70817" },
    { lng: -92.65224, lat: 30.806617, postalCode: "71463" },
    { lng: -91.48607, lat: 30.561065, postalCode: "70781" },
    { lng: -92.46576, lat: 30.182784, postalCode: "70534" },
    { lng: -90.41389, lat: 30.007089, postalCode: "70079" },
    { lng: -90.4762, lat: 30.484785, postalCode: "70403" },
    { lng: -93.19719, lat: 31.874172, postalCode: "71066" },
    { lng: -90.154156, lat: 30.847346, postalCode: "70499" },
    { lng: -91.08631, lat: 30.388292, postalCode: "70836" },
    { lng: -93.76761, lat: 32.490777, postalCode: "71103" },
    { lng: -92.80459, lat: 31.206148, postalCode: "71472" },
    { lng: -92.94802, lat: 30.937089, postalCode: "70656" },
    { lng: -91.37308, lat: 30.857355, postalCode: "70775" },
    { lng: -93.31928, lat: 32.050033, postalCode: "71019" },
    { lng: -92.09521, lat: 30.530965, postalCode: "70570" },
    { lng: -93.0363, lat: 29.836547, postalCode: "70632" },
    { lng: -93.691972, lat: 32.044529, postalCode: "71059" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70055" },
    { lng: -89.38007, lat: 29.307982, postalCode: "70038" },
    { lng: -90.48708, lat: 29.999471, postalCode: "70066" },
    { lng: -92.94408, lat: 30.799893, postalCode: "70644" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70823" },
    { lng: -92.85992, lat: 30.494379, postalCode: "70648" },
    { lng: -90.51026, lat: 30.793858, postalCode: "70436" },
    { lng: -91.78314, lat: 30.022348, postalCode: "70563" },
    { lng: -93.2561, lat: 30.599065, postalCode: "70652" },
    { lng: -90.2138, lat: 29.997168, postalCode: "70003" },
    { lng: -93.24905, lat: 30.165907, postalCode: "70605" },
    { lng: -91.13806, lat: 30.44495, postalCode: "70806" },
    { lng: -92.08056, lat: 31.140727, postalCode: "71351" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71161" },
    { lng: -93.367949, lat: 32.062391, postalCode: "71025" },
    { lng: -93.66397, lat: 31.597407, postalCode: "71486" },
    { lng: -92.158982, lat: 29.866543, postalCode: "70575" },
    { lng: -93.326527, lat: 30.264232, postalCode: "70612" },
    { lng: -91.76914, lat: 31.381317, postalCode: "71354" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70190" },
    { lng: -91.84073, lat: 29.975453, postalCode: "70560" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71151" },
    { lng: -90.7475, lat: 29.593377, postalCode: "70360" },
    { lng: -92.08955, lat: 30.930026, postalCode: "71333" },
    { lng: -91.17065, lat: 30.373865, postalCode: "70820" },
    { lng: -92.107422, lat: 30.350849, postalCode: "70384" },
    { lng: -90.59881, lat: 29.553952, postalCode: "70343" },
    { lng: -89.86251, lat: 30.932125, postalCode: "70426" },
    { lng: -92.1599, lat: 32.526944, postalCode: "71291" },
    { lng: -92.159354, lat: 32.490777, postalCode: "71211" },
    { lng: -92.54954, lat: 31.563093, postalCode: "71423" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70833" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71102" },
    { lng: -91.13185, lat: 30.525121, postalCode: "70811" },
    { lng: -90.078312, lat: 29.95612, postalCode: "70140" },
    { lng: -90.405312, lat: 30.612371, postalCode: "70421" },
    { lng: -90.48375, lat: 30.546528, postalCode: "70451" },
    { lng: -92.69691, lat: 30.477275, postalCode: "70532" },
    { lng: -93.44959, lat: 32.969535, postalCode: "71021" },
    { lng: -93.71835, lat: 31.80442, postalCode: "71419" },
    { lng: -93.99683, lat: 32.453029, postalCode: "71033" },
    { lng: -90.48516, lat: 30.073941, postalCode: "70068" },
    { lng: -93.89317, lat: 32.579853, postalCode: "71009" },
    { lng: -92.85568, lat: 32.830135, postalCode: "71048" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70181" },
    { lng: -90.84035, lat: 30.521962, postalCode: "70785" },
    { lng: -90.71178, lat: 29.979997, postalCode: "70090" },
    { lng: -92.37657, lat: 32.764289, postalCode: "71241" },
    { lng: -91.79917, lat: 32.601149, postalCode: "71264" },
    { lng: -91.09595, lat: 30.264611, postalCode: "70776" },
    { lng: -92.07345, lat: 31.21968, postalCode: "71331" },
    { lng: -91.812552, lat: 29.909643, postalCode: "70569" },
    { lng: -93.62938, lat: 32.350987, postalCode: "71115" },
    { lng: -90.26252, lat: 30.944302, postalCode: "70450" },
    { lng: -90.58546, lat: 30.508318, postalCode: "70711" },
    { lng: -90.77604, lat: 30.702471, postalCode: "70453" },
    { lng: -92.4006, lat: 31.352105, postalCode: "71360" },
    { lng: -93.6704, lat: 32.478752, postalCode: "71112" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70059" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71163" },
    { lng: -92.04753, lat: 30.419571, postalCode: "70541" },
    { lng: -89.51381, lat: 29.351783, postalCode: "70041" },
    { lng: -90.72701, lat: 30.272335, postalCode: "70449" },
    { lng: -91.72501, lat: 31.1741, postalCode: "71320" },
    { lng: -90.03667, lat: 29.938155, postalCode: "70114" },
    { lng: -91.39994, lat: 30.587852, postalCode: "70752" },
    { lng: -92.77985, lat: 31.963299, postalCode: "71410" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70172" },
    { lng: -92.16809, lat: 30.920328, postalCode: "71322" },
    { lng: -92.18276, lat: 32.456034, postalCode: "71292" },
    { lng: -93.61918, lat: 32.477994, postalCode: "71110" },
    { lng: -92.04706, lat: 30.185867, postalCode: "70503" },
    { lng: -90.77238, lat: 29.696636, postalCode: "70359" },
    { lng: -90.61939, lat: 29.385126, postalCode: "70344" },
    { lng: -90.13398, lat: 29.999453, postalCode: "70005" },
    { lng: -91.9727, lat: 30.728695, postalCode: "71345" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71148" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70149" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70154" },
    { lng: -92.51199, lat: 30.237007, postalCode: "70531" },
    { lng: -91.78713, lat: 31.615767, postalCode: "71377" },
    { lng: -92.604193, lat: 31.399855, postalCode: "71477" },
    { lng: -90.34043, lat: 29.523897, postalCode: "70345" },
    { lng: -92.00959, lat: 30.2334, postalCode: "70501" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70826" },
    { lng: -90.816914, lat: 29.803282, postalCode: "70310" },
    { lng: -93.68585, lat: 32.031067, postalCode: "71052" },
    { lng: -93.20496, lat: 30.226399, postalCode: "70601" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70825" },
    { lng: -90.01972, lat: 30.016002, postalCode: "70126" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70157" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70892" },
    { lng: -92.6977, lat: 31.168617, postalCode: "71424" },
    { lng: -93.87089, lat: 32.309864, postalCode: "71047" },
    { lng: -93.78584, lat: 32.110359, postalCode: "71032" },
    { lng: -91.09041, lat: 30.361609, postalCode: "70810" },
    { lng: -91.25549, lat: 30.384402, postalCode: "70719" },
    { lng: -90.72076, lat: 29.626988, postalCode: "70364" },
    { lng: -90.37563, lat: 29.969128, postalCode: "70047" },
    { lng: -91.186834, lat: 30.405002, postalCode: "70803" },
    { lng: -91.080373, lat: 30.51589, postalCode: "70879" },
    { lng: -92.97318, lat: 31.371519, postalCode: "71455" },
    { lng: -92.63979, lat: 32.08115, postalCode: "71422" },
    { lng: -92.075583, lat: 32.527693, postalCode: "71209" },
    { lng: -90.10491, lat: 30.470793, postalCode: "70433" },
    { lng: -92.21624, lat: 31.247945, postalCode: "71323" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71138" },
    { lng: -91.691899, lat: 32.138935, postalCode: "71230" },
    { lng: -92.43442, lat: 32.100491, postalCode: "71473" },
    { lng: -89.77587, lat: 30.269565, postalCode: "70458" },
    { lng: -91.31265, lat: 31.924224, postalCode: "71366" },
    { lng: -92.75474, lat: 31.755314, postalCode: "71404" },
    { lng: -92.91527, lat: 30.235892, postalCode: "70650" },
    { lng: -91.439679, lat: 29.75226, postalCode: "70522" },
    { lng: -90.156143, lat: 29.960375, postalCode: "70021" },
    { lng: -89.99902, lat: 29.958802, postalCode: "70032" },
    { lng: -91.216458, lat: 32.341594, postalCode: "71284" },
    { lng: -92.39418, lat: 31.529155, postalCode: "71467" },
    { lng: -93.49998, lat: 30.994164, postalCode: "70639" },
    { lng: -92.619593, lat: 31.30473, postalCode: "71309" },
    { lng: -93.75256, lat: 32.607556, postalCode: "71152" },
    { lng: -90.44769, lat: 29.810193, postalCode: "70030" },
    { lng: -92.05479, lat: 30.653805, postalCode: "70589" },
    { lng: -92.677545, lat: 31.258681, postalCode: "71431" },
    { lng: -93.09021, lat: 31.751287, postalCode: "71457" },
    { lng: -92.21772, lat: 30.414588, postalCode: "70525" },
    { lng: -91.82242, lat: 30.966506, postalCode: "71369" },
    { lng: -92.40532, lat: 30.871853, postalCode: "70585" },
    { lng: -91.15612, lat: 30.485639, postalCode: "70805" },
    { lng: -91.14015, lat: 30.655578, postalCode: "70791" },
    { lng: -93.326527, lat: 30.264232, postalCode: "70602" },
    { lng: -90.85707, lat: 30.182068, postalCode: "70778" },
    { lng: -92.095109, lat: 30.20812, postalCode: "70598" },
    { lng: -93.608968, lat: 32.62762, postalCode: "71172" },
    { lng: -92.29141, lat: 31.861708, postalCode: "71480" },
    { lng: -93.59815, lat: 30.183689, postalCode: "70668" },
    { lng: -91.55075, lat: 30.568308, postalCode: "70755" },
    { lng: -91.044723, lat: 30.461252, postalCode: "70821" },
    { lng: -92.34923, lat: 32.507942, postalCode: "71225" },
    { lng: -90.51744, lat: 30.628735, postalCode: "70443" },
    { lng: -90.92827, lat: 30.468526, postalCode: "70726" },
    { lng: -93.28751, lat: 32.631009, postalCode: "71055" },
    { lng: -90.00616, lat: 29.822707, postalCode: "70037" },
    { lng: -91.06358, lat: 30.45514, postalCode: "70815" },
    { lng: -92.49549, lat: 30.328683, postalCode: "70543" },
    { lng: -92.095109, lat: 30.20812, postalCode: "70593" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70166" },
    { lng: -93.289988, lat: 31.110298, postalCode: "71496" },
    { lng: -91.2054, lat: 30.590238, postalCode: "70874" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70142" },
    { lng: -92.40455, lat: 31.114462, postalCode: "71346" },
    { lng: -92.526927, lat: 31.203393, postalCode: "71348" },
    { lng: -90.32332, lat: 30.686968, postalCode: "70442" },
    { lng: -90.44546, lat: 29.535139, postalCode: "70373" },
    { lng: -93.45295, lat: 32.924798, postalCode: "71071" },
    { lng: -90.41728, lat: 30.427599, postalCode: "70454" },
    { lng: -93.16075, lat: 32.342739, postalCode: "71045" },
    { lng: -92.37517, lat: 32.361562, postalCode: "71238" },
    { lng: -90.38655, lat: 29.900075, postalCode: "70039" },
    { lng: -90.131145, lat: 30.496278, postalCode: "70432" },
    { lng: -91.44032, lat: 30.700337, postalCode: "70760" },
    { lng: -90.0901, lat: 29.677893, postalCode: "70064" },
    { lng: -90.41012, lat: 29.967081, postalCode: "70057" },
    { lng: -90.82604, lat: 29.352141, postalCode: "70397" },
    { lng: -93.51746, lat: 31.733049, postalCode: "71406" },
    { lng: -92.96199, lat: 30.518729, postalCode: "70651" },
    { lng: -91.80804, lat: 32.286179, postalCode: "71259" },
    { lng: -93.01709, lat: 32.642811, postalCode: "71003" },
    { lng: -90.92251, lat: 30.229237, postalCode: "70737" },
    { lng: -91.10915, lat: 30.722133, postalCode: "70777" },
    { lng: -89.73049, lat: 30.266707, postalCode: "70461" },
    { lng: -90.596159, lat: 29.865202, postalCode: "70371" },
    { lng: -92.648384, lat: 32.606535, postalCode: "71273" },
    { lng: -91.36026, lat: 30.261384, postalCode: "70716" },
    { lng: -90.43063, lat: 29.878373, postalCode: "70080" },
    { lng: -91.61945, lat: 32.978295, postalCode: "71250" },
    { lng: -91.22938, lat: 29.692515, postalCode: "70342" },
    { lng: -89.882564, lat: 30.032997, postalCode: "70161" },
    { lng: -92.526927, lat: 31.203393, postalCode: "71307" },
    { lng: -92.38927, lat: 31.759474, postalCode: "71432" },
    { lng: -91.18252, lat: 29.694806, postalCode: "70380" },
    { lng: -93.21326, lat: 32.502528, postalCode: "71024" },
    { lng: -87.78294, lat: 46.108829, postalCode: "49877" },
    { lng: -87.37748, lat: 45.685664, postalCode: "49896" },
    { lng: -87.81615, lat: 45.994849, postalCode: "49831" },
    { lng: -88.59396, lat: 46.056611, postalCode: "49927" },
    { lng: -87.50316, lat: 45.59232, postalCode: "49812" },
    { lng: -90.05138, lat: 46.480948, postalCode: "49911" },
    { lng: -89.91876, lat: 46.466645, postalCode: "49968" },
    { lng: -88.70073, lat: 46.108022, postalCode: "49935" },
    { lng: -87.63114, lat: 45.314014, postalCode: "49893" },
    { lng: -88.45043, lat: 46.234686, postalCode: "49903" },
    { lng: -89.99603, lat: 46.470746, postalCode: "49959" },
    { lng: -87.351342, lat: 45.692178, postalCode: "49845" },
    { lng: -87.99607, lat: 46.085875, postalCode: "49881" },
    { lng: -87.50716, lat: 45.889823, postalCode: "49873" },
    { lng: -87.50966, lat: 45.693128, postalCode: "49874" },
    { lng: -87.55411, lat: 45.610678, postalCode: "49863" },
    { lng: -88.37681, lat: 46.045407, postalCode: "49902" },
    { lng: -87.37679, lat: 45.435313, postalCode: "49813" },
    { lng: -89.26594, lat: 46.258959, postalCode: "49969" },
    { lng: -87.99717, lat: 45.87178, postalCode: "49801" },
    { lng: -87.90824, lat: 45.792399, postalCode: "49870" },
    { lng: -87.99141, lat: 45.80007, postalCode: "49876" },
    { lng: -88.375199, lat: 46.045288, postalCode: "49990" },
    { lng: -88.35677, lat: 46.12624, postalCode: "49920" },
    { lng: -87.77089, lat: 45.947706, postalCode: "49834" },
    { lng: -87.6281, lat: 45.417184, postalCode: "49887" },
    { lng: -87.50457, lat: 45.695551, postalCode: "49886" },
    { lng: -87.63243, lat: 45.376503, postalCode: "49848" },
    { lng: -88.08323, lat: 45.800687, postalCode: "49802" },
    { lng: -87.60897, lat: 45.1431, postalCode: "49858" },
    { lng: -87.78838, lat: 45.755894, postalCode: "49892" },
    { lng: -87.61054, lat: 45.709804, postalCode: "49847" },
    { lng: -88.04546, lat: 46.186913, postalCode: "49815" },
    { lng: -87.61977, lat: 45.521574, postalCode: "49821" },
    { lng: -87.81759, lat: 45.781167, postalCode: "49852" },
    { lng: -89.66518, lat: 46.420235, postalCode: "49947" },
    { lng: -87.34617, lat: 45.770006, postalCode: "49807" },
    { lng: -90.15732, lat: 46.47351, postalCode: "49938" },
    { lng: -88.62738, lat: 46.063428, postalCode: "49915" },
    { lng: -88.62806, lat: 46.081472, postalCode: "49964" },
    { lng: -92.75616, lat: 44.068344, postalCode: "55955" },
    { lng: -92.41983, lat: 46.534005, postalCode: "55797" },
    { lng: -93.38142, lat: 48.579947, postalCode: "56649" },
    { lng: -93.16415, lat: 44.884265, postalCode: "55150" },
    { lng: -92.23909, lat: 47.624206, postalCode: "55732" },
    { lng: -96.34149, lat: 46.459359, postalCode: "56579" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55581" },
    { lng: -93.84467, lat: 44.955136, postalCode: "55388" },
    { lng: -92.14213, lat: 43.975269, postalCode: "55929" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55571" },
    { lng: -93.66458, lat: 46.507241, postalCode: "56431" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55393" },
    { lng: -93.25424, lat: 44.934915, postalCode: "55407" },
    { lng: -92.44913, lat: 46.908416, postalCode: "55779" },
    { lng: -94.37742, lat: 44.431776, postalCode: "56054" },
    { lng: -96.23607, lat: 46.988379, postalCode: "56552" },
    { lng: -93.34215, lat: 44.946664, postalCode: "55416" },
    { lng: -96.29033, lat: 47.886448, postalCode: "56750" },
    { lng: -93.13437, lat: 44.732105, postalCode: "55068" },
    { lng: -96.25132, lat: 43.887295, postalCode: "56177" },
    { lng: -93.4869, lat: 44.880435, postalCode: "55346" },
    { lng: -93.34045, lat: 44.904414, postalCode: "55424" },
    { lng: -96.506156, lat: 46.890034, postalCode: "56561" },
    { lng: -96.06453, lat: 45.532754, postalCode: "56207" },
    { lng: -93.26881, lat: 47.116453, postalCode: "55793" },
    { lng: -93.78514, lat: 44.849912, postalCode: "55387" },
    { lng: -93.29635, lat: 45.186232, postalCode: "55448" },
    { lng: -92.07319, lat: 46.809438, postalCode: "55812" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55484" },
    { lng: -93.2632, lat: 46.974237, postalCode: "55752" },
    { lng: -92.282467, lat: 44.340826, postalCode: "55968" },
    { lng: -95.45375, lat: 46.31855, postalCode: "56551" },
    { lng: -93.22082, lat: 44.938615, postalCode: "55406" },
    { lng: -92.28314, lat: 44.135053, postalCode: "55932" },
    { lng: -94.738827, lat: 44.302893, postalCode: "56084" },
    { lng: -95.7032, lat: 43.725227, postalCode: "56165" },
    { lng: -94.29254, lat: 46.166054, postalCode: "56449" },
    { lng: -93.99978, lat: 46.483523, postalCode: "56455" },
    { lng: -96.00539, lat: 45.228278, postalCode: "56208" },
    { lng: -95.12732, lat: 44.540923, postalCode: "56283" },
    { lng: -95.97713, lat: 47.808866, postalCode: "56715" },
    { lng: -93.0828, lat: 45.076708, postalCode: "55127" },
    { lng: -94.24098, lat: 47.617774, postalCode: "56681" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55486" },
    { lng: -96.26954, lat: 47.557644, postalCode: "56540" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55569" },
    { lng: -94.38604, lat: 44.896255, postalCode: "55350" },
    { lng: -91.39015, lat: 43.910929, postalCode: "55925" },
    { lng: -93.80077, lat: 44.304948, postalCode: "56017" },
    { lng: -96.10974, lat: 46.861527, postalCode: "56554" },
    { lng: -93.083438, lat: 48.081686, postalCode: "55772" },
    { lng: -93.29452, lat: 45.897003, postalCode: "55051" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55560" },
    { lng: -92.04934, lat: 44.273406, postalCode: "55945" },
    { lng: -91.66464, lat: 47.077723, postalCode: "55616" },
    { lng: -93.47988, lat: 45.039312, postalCode: "55446" },
    { lng: -92.12329, lat: 43.646355, postalCode: "55965" },
    { lng: -92.96929, lat: 43.743278, postalCode: "55950" },
    { lng: -94.32974, lat: 45.582653, postalCode: "56374" },
    { lng: -96.45916, lat: 45.568463, postalCode: "56240" },
    { lng: -93.03528, lat: 44.844833, postalCode: "55076" },
    { lng: -93.1353, lat: 45.085643, postalCode: "55126" },
    { lng: -93.418, lat: 45.247509, postalCode: "55303" },
    { lng: -91.57534, lat: 43.769776, postalCode: "55943" },
    { lng: -93.57848, lat: 44.546463, postalCode: "56071" },
    { lng: -93.51986, lat: 46.114445, postalCode: "56386" },
    { lng: -91.65004, lat: 43.57279, postalCode: "55974" },
    { lng: -92.40766, lat: 46.463827, postalCode: "55749" },
    { lng: -93.94631, lat: 47.663689, postalCode: "56680" },
    { lng: -92.61519, lat: 48.262021, postalCode: "55725" },
    { lng: -92.48477, lat: 46.649697, postalCode: "55718" },
    { lng: -93.33357, lat: 43.764506, postalCode: "56016" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55591" },
    { lng: -91.98342, lat: 46.86242, postalCode: "55804" },
    { lng: -93.01845, lat: 45.010632, postalCode: "55109" },
    { lng: -96.31772, lat: 46.040316, postalCode: "56565" },
    { lng: -93.63247, lat: 45.760191, postalCode: "56353" },
    { lng: -96.39022, lat: 43.854184, postalCode: "56144" },
    { lng: -94.11303, lat: 45.547283, postalCode: "56304" },
    { lng: -96.80408, lat: 47.651391, postalCode: "56523" },
    { lng: -95.75167, lat: 48.817919, postalCode: "56751" },
    { lng: -92.63067, lat: 47.405699, postalCode: "55751" },
    { lng: -95.82013, lat: 48.128712, postalCode: "56725" },
    { lng: -94.01341, lat: 43.782104, postalCode: "56023" },
    { lng: -95.98835, lat: 44.319682, postalCode: "56169" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55458" },
    { lng: -96.32473, lat: 44.020814, postalCode: "56164" },
    { lng: -95.62371, lat: 44.260625, postalCode: "56175" },
    { lng: -95.06222, lat: 46.252854, postalCode: "56437" },
    { lng: -94.069828, lat: 44.056047, postalCode: "56002" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55487" },
    { lng: -93.37021, lat: 44.875997, postalCode: "55439" },
    { lng: -94.68641, lat: 45.168724, postalCode: "56243" },
    { lng: -92.53706, lat: 44.152623, postalCode: "55960" },
    { lng: -94.91254, lat: 47.750406, postalCode: "56667" },
    { lng: -91.29057, lat: 43.664102, postalCode: "55919" },
    { lng: -96.01767, lat: 45.697239, postalCode: "56235" },
    { lng: -93.65619, lat: 44.736595, postalCode: "55315" },
    { lng: -94.31424, lat: 44.277333, postalCode: "56021" },
    { lng: -95.14567, lat: 46.32091, postalCode: "56453" },
    { lng: -93.30982, lat: 44.829564, postalCode: "55431" },
    { lng: -95.55201, lat: 45.578335, postalCode: "56381" },
    { lng: -95.42422, lat: 45.242264, postalCode: "56271" },
    { lng: -93.90417, lat: 45.695411, postalCode: "56329" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55579" },
    { lng: -94.8845, lat: 44.761856, postalCode: "55310" },
    { lng: -95.713452, lat: 46.412413, postalCode: "56538" },
    { lng: -92.26493, lat: 43.714071, postalCode: "55990" },
    { lng: -95.14362, lat: 47.871135, postalCode: "56671" },
    { lng: -94.58834, lat: 47.373207, postalCode: "56633" },
    { lng: -93.42013, lat: 45.677366, postalCode: "55017" },
    { lng: -94.77432, lat: 43.556667, postalCode: "56127" },
    { lng: -94.67303, lat: 45.67539, postalCode: "56331" },
    { lng: -94.18016, lat: 43.764936, postalCode: "56098" },
    { lng: -93.37056, lat: 44.903864, postalCode: "55436" },
    { lng: -92.442797, lat: 47.640367, postalCode: "55777" },
    { lng: -96.24301, lat: 43.521947, postalCode: "56173" },
    { lng: -94.43584, lat: 43.735594, postalCode: "56075" },
    { lng: -92.442797, lat: 47.640367, postalCode: "55816" },
    { lng: -91.90736, lat: 43.569417, postalCode: "55922" },
    { lng: -94.85864, lat: 45.601429, postalCode: "56335" },
    { lng: -90.63836, lat: 47.757804, postalCode: "55612" },
    { lng: -94.11692, lat: 45.246631, postalCode: "55302" },
    { lng: -92.202829, lat: 46.837172, postalCode: "55184" },
    { lng: -93.90534, lat: 43.764111, postalCode: "56025" },
    { lng: -93.24327, lat: 44.969465, postalCode: "55454" },
    { lng: -94.593338, lat: 45.52886, postalCode: "56395" },
    { lng: -92.9911, lat: 45.272117, postalCode: "55025" },
    { lng: -94.83877, lat: 44.049908, postalCode: "56022" },
    { lng: -93.00616, lat: 44.957315, postalCode: "55119" },
    { lng: -95.34591, lat: 43.826163, postalCode: "56137" },
    { lng: -93.99282, lat: 44.337276, postalCode: "56082" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55572" },
    { lng: -97.13949, lat: 48.954448, postalCode: "56755" },
    { lng: -94.75466, lat: 45.628804, postalCode: "56356" },
    { lng: -93.19871, lat: 44.804548, postalCode: "55122" },
    { lng: -94.0599, lat: 45.387625, postalCode: "55320" },
    { lng: -94.55748, lat: 47.997931, postalCode: "56650" },
    { lng: -95.72642, lat: 45.63381, postalCode: "56323" },
    { lng: -95.77444, lat: 43.884657, postalCode: "56141" },
    { lng: -93.49143, lat: 43.796535, postalCode: "56042" },
    { lng: -93.46031, lat: 44.831413, postalCode: "55347" },
    { lng: -93.84788, lat: 45.946528, postalCode: "56388" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55589" },
    { lng: -94.8735, lat: 44.541889, postalCode: "55333" },
    { lng: -95.88297, lat: 45.902998, postalCode: "56311" },
    { lng: -93.3195, lat: 45.163848, postalCode: "55433" },
    { lng: -94.84003, lat: 49.242285, postalCode: "56741" },
    { lng: -96.40019, lat: 46.014161, postalCode: "56583" },
    { lng: -92.98548, lat: 44.607553, postalCode: "55031" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55552" },
    { lng: -92.7699, lat: 46.44429, postalCode: "55767" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55177" },
    { lng: -96.18168, lat: 44.092188, postalCode: "56139" },
    { lng: -90.95917, lat: 47.651374, postalCode: "55613" },
    { lng: -94.67563, lat: 44.933861, postalCode: "56228" },
    { lng: -94.6196, lat: 46.194584, postalCode: "56443" },
    { lng: -94.208731, lat: 46.061307, postalCode: "56317" },
    { lng: -94.85013, lat: 46.970017, postalCode: "56467" },
    { lng: -93.18959, lat: 43.762109, postalCode: "56045" },
    { lng: -93.133317, lat: 46.626514, postalCode: "55778" },
    { lng: -93.27053, lat: 44.975565, postalCode: "55402" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55568" },
    { lng: -96.24825, lat: 47.245894, postalCode: "56584" },
    { lng: -96.1897, lat: 45.556847, postalCode: "56221" },
    { lng: -94.62373, lat: 45.904129, postalCode: "56382" },
    { lng: -92.46452, lat: 43.6122, postalCode: "55961" },
    { lng: -93.48443, lat: 44.915413, postalCode: "55345" },
    { lng: -94.02951, lat: 47.036967, postalCode: "56672" },
    { lng: -93.66369, lat: 45.15546, postalCode: "55341" },
    { lng: -96.24725, lat: 47.087171, postalCode: "56585" },
    { lng: -93.73102, lat: 45.732493, postalCode: "56330" },
    { lng: -92.442797, lat: 47.640367, postalCode: "55701" },
    { lng: -93.7439, lat: 44.906512, postalCode: "55375" },
    { lng: -94.53347, lat: 47.099774, postalCode: "56484" },
    { lng: -94.76247, lat: 45.819474, postalCode: "56336" },
    { lng: -94.07175, lat: 45.064272, postalCode: "55349" },
    { lng: -93.43346, lat: 44.953763, postalCode: "55305" },
    { lng: -95.00447, lat: 43.625811, postalCode: "56143" },
    { lng: -94.05846, lat: 44.881515, postalCode: "55354" },
    { lng: -96.56301, lat: 46.884481, postalCode: "56547" },
    { lng: -94.95748, lat: 46.434002, postalCode: "56481" },
    { lng: -94.251926, lat: 46.498749, postalCode: "56459" },
    { lng: -94.900729, lat: 46.605266, postalCode: "56478" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55144" },
    { lng: -96.36802, lat: 43.907841, postalCode: "56140" },
    { lng: -94.45856, lat: 43.646916, postalCode: "56031" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55553" },
    { lng: -96.44919, lat: 48.232438, postalCode: "56760" },
    { lng: -92.48612, lat: 46.727077, postalCode: "55720" },
    { lng: -95.38106, lat: 46.534993, postalCode: "56567" },
    { lng: -93.80774, lat: 45.301337, postalCode: "55362" },
    { lng: -95.997757, lat: 46.732051, postalCode: "56508" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55485" },
    { lng: -92.41336, lat: 44.24431, postalCode: "55991" },
    { lng: -96.19573, lat: 45.036047, postalCode: "56256" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55594" },
    { lng: -93.5103, lat: 44.067673, postalCode: "56093" },
    { lng: -94.66271, lat: 45.498506, postalCode: "56376" },
    { lng: -92.17048, lat: 44.164431, postalCode: "55964" },
    { lng: -92.90241, lat: 44.896938, postalCode: "55129" },
    { lng: -94.37183, lat: 45.994029, postalCode: "56345" },
    { lng: -91.20122, lat: 47.502703, postalCode: "55603" },
    { lng: -93.28909, lat: 44.732569, postalCode: "55306" },
    { lng: -96.66702, lat: 46.565562, postalCode: "56594" },
    { lng: -93.36916, lat: 43.652042, postalCode: "56007" },
    { lng: -91.84932, lat: 43.959148, postalCode: "55952" },
    { lng: -95.7711, lat: 45.27133, postalCode: "56231" },
    { lng: -93.095518, lat: 44.952165, postalCode: "55155" },
    { lng: -92.412343, lat: 47.249626, postalCode: "55766" },
    { lng: -96.63715, lat: 46.754039, postalCode: "56580" },
    { lng: -93.49262, lat: 45.003363, postalCode: "55447" },
    { lng: -93.921429, lat: 47.087782, postalCode: "56631" },
    { lng: -94.605226, lat: 44.325833, postalCode: "56030" },
    { lng: -96.03701, lat: 47.666426, postalCode: "56535" },
    { lng: -92.40533, lat: 47.4741, postalCode: "55741" },
    { lng: -94.71718, lat: 44.529702, postalCode: "55332" },
    { lng: -92.18242, lat: 46.821489, postalCode: "55811" },
    { lng: -92.76706, lat: 46.22572, postalCode: "55704" },
    { lng: -97.09566, lat: 48.909857, postalCode: "56731" },
    { lng: -94.19251, lat: 45.568516, postalCode: "56303" },
    { lng: -95.07071, lat: 46.145445, postalCode: "56446" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55170" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55188" },
    { lng: -93.6133, lat: 44.657463, postalCode: "55352" },
    { lng: -95.28014, lat: 44.227021, postalCode: "56152" },
    { lng: -92.36434, lat: 46.708371, postalCode: "55733" },
    { lng: -94.29986, lat: 46.602684, postalCode: "56472" },
    { lng: -93.15222, lat: 44.649263, postalCode: "55024" },
    { lng: -95.42809, lat: 47.67265, postalCode: "56634" },
    { lng: -92.718004, lat: 46.074687, postalCode: "55067" },
    { lng: -93.42139, lat: 44.704646, postalCode: "55372" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55599" },
    { lng: -92.0706, lat: 47.517269, postalCode: "55750" },
    { lng: -93.07651, lat: 47.39654, postalCode: "55753" },
    { lng: -92.894239, lat: 45.513447, postalCode: "55002" },
    { lng: -95.6128, lat: 43.971169, postalCode: "56114" },
    { lng: -96.32641, lat: 45.245844, postalCode: "56276" },
    { lng: -94.22403, lat: 43.95885, postalCode: "56090" },
    { lng: -96.06798, lat: 46.282084, postalCode: "56537" },
    { lng: -93.16623, lat: 44.459969, postalCode: "55057" },
    { lng: -94.52868, lat: 45.104473, postalCode: "55355" },
    { lng: -95.82794, lat: 45.024344, postalCode: "56295" },
    { lng: -94.63849, lat: 46.362398, postalCode: "56466" },
    { lng: -96.92156, lat: 48.634961, postalCode: "56733" },
    { lng: -94.33862, lat: 45.470635, postalCode: "56369" },
    { lng: -94.72593, lat: 43.771341, postalCode: "56176" },
    { lng: -92.57812, lat: 43.715085, postalCode: "55936" },
    { lng: -95.20682, lat: 44.783251, postalCode: "56284" },
    { lng: -93.26096, lat: 46.274325, postalCode: "56350" },
    { lng: -97.11882, lat: 48.233942, postalCode: "56744" },
    { lng: -93.69492, lat: 43.930363, postalCode: "56091" },
    { lng: -93.53178, lat: 43.899778, postalCode: "56072" },
    { lng: -93.27302, lat: 44.770297, postalCode: "55337" },
    { lng: -93.10026, lat: 44.903165, postalCode: "55118" },
    { lng: -94.67459, lat: 45.85303, postalCode: "56318" },
    { lng: -96.95434, lat: 48.787016, postalCode: "56728" },
    { lng: -92.74026, lat: 47.511214, postalCode: "55758" },
    { lng: -93.47612, lat: 45.217054, postalCode: "55327" },
    { lng: -93.23269, lat: 44.415567, postalCode: "55019" },
    { lng: -93.29012, lat: 44.902414, postalCode: "55419" },
    { lng: -95.41337, lat: 47.436583, postalCode: "56621" },
    { lng: -95.678375, lat: 46.933961, postalCode: "56591" },
    { lng: -96.30822, lat: 44.268332, postalCode: "56149" },
    { lng: -95.36174, lat: 46.895156, postalCode: "56570" },
    { lng: -94.88998, lat: 44.11754, postalCode: "56019" },
    { lng: -95.74148, lat: 48.445999, postalCode: "56724" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55365" },
    { lng: -92.96466, lat: 43.519177, postalCode: "55953" },
    { lng: -93.58117, lat: 45.317152, postalCode: "55330" },
    { lng: -95.81104, lat: 45.825031, postalCode: "56339" },
    { lng: -96.12408, lat: 44.274909, postalCode: "56178" },
    { lng: -94.26434, lat: 45.840565, postalCode: "56373" },
    { lng: -96.506156, lat: 46.890034, postalCode: "56563" },
    { lng: -91.78525, lat: 46.94939, postalCode: "55609" },
    { lng: -92.983726, lat: 45.021016, postalCode: "55083" },
    { lng: -93.94081, lat: 46.850438, postalCode: "56662" },
    { lng: -94.21773, lat: 44.542729, postalCode: "55334" },
    { lng: -92.49459, lat: 44.048931, postalCode: "55901" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55563" },
    { lng: -95.7066, lat: 44.985313, postalCode: "56265" },
    { lng: -93.15536, lat: 45.011215, postalCode: "55113" },
    { lng: -93.12261, lat: 44.964115, postalCode: "55103" },
    { lng: -95.5305, lat: 46.246571, postalCode: "56588" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55175" },
    { lng: -92.442797, lat: 47.640367, postalCode: "55814" },
    { lng: -95.23965, lat: 45.92084, postalCode: "56355" },
    { lng: -96.89632, lat: 48.574128, postalCode: "56720" },
    { lng: -93.52912, lat: 44.963063, postalCode: "55391" },
    { lng: -96.41157, lat: 44.512114, postalCode: "56136" },
    { lng: -95.93973, lat: 43.626278, postalCode: "56110" },
    { lng: -95.5942, lat: 45.968739, postalCode: "56315" },
    { lng: -92.37242, lat: 43.686356, postalCode: "55975" },
    { lng: -95.70975, lat: 46.284496, postalCode: "56515" },
    { lng: -94.99624, lat: 44.766441, postalCode: "56277" },
    { lng: -95.21433, lat: 45.702767, postalCode: "56385" },
    { lng: -94.05231, lat: 44.017609, postalCode: "56037" },
    { lng: -91.36126, lat: 43.756567, postalCode: "55941" },
    { lng: -95.69675, lat: 46.467527, postalCode: "56576" },
    { lng: -96.63392, lat: 48.732852, postalCode: "56734" },
    { lng: -92.77783, lat: 47.49307, postalCode: "55713" },
    { lng: -94.01337, lat: 44.670908, postalCode: "55338" },
    { lng: -92.49849, lat: 43.862105, postalCode: "55976" },
    { lng: -91.77304, lat: 44.025446, postalCode: "55988" },
    { lng: -92.894239, lat: 45.513447, postalCode: "55078" },
    { lng: -95.30198, lat: 45.18553, postalCode: "56252" },
    { lng: -93.28124, lat: 44.877164, postalCode: "55423" },
    { lng: -92.001934, lat: 47.005566, postalCode: "55801" },
    { lng: -94.4846, lat: 46.368605, postalCode: "56473" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55189" },
    { lng: -95.90465, lat: 46.169964, postalCode: "56324" },
    { lng: -93.57873, lat: 45.455085, postalCode: "55398" },
    { lng: -93.899486, lat: 47.572516, postalCode: "56366" },
    { lng: -95.78157, lat: 45.488399, postalCode: "56244" },
    { lng: -95.10486, lat: 45.884771, postalCode: "56360" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55169" },
    { lng: -95.54999, lat: 44.417337, postalCode: "56263" },
    { lng: -93.42892, lat: 45.046701, postalCode: "55442" },
    { lng: -93.56339, lat: 43.766208, postalCode: "56032" },
    { lng: -93.64059, lat: 47.757041, postalCode: "55728" },
    { lng: -92.49166, lat: 43.991846, postalCode: "55902" },
    { lng: -93.72583, lat: 44.393899, postalCode: "56057" },
    { lng: -95.11397, lat: 47.508863, postalCode: "56678" },
    { lng: -93.85017, lat: 45.426203, postalCode: "55308" },
    { lng: -95.32545, lat: 43.724852, postalCode: "56161" },
    { lng: -93.58773, lat: 47.667203, postalCode: "56628" },
    { lng: -94.593338, lat: 45.52886, postalCode: "56399" },
    { lng: -92.84315, lat: 46.317812, postalCode: "55795" },
    { lng: -93.8155, lat: 46.244818, postalCode: "56450" },
    { lng: -92.96594, lat: 46.723028, postalCode: "55798" },
    { lng: -94.68589, lat: 47.143679, postalCode: "56436" },
    { lng: -96.9986, lat: 48.192532, postalCode: "56710" },
    { lng: -92.99596, lat: 45.161267, postalCode: "55038" },
    { lng: -92.82581, lat: 45.054551, postalCode: "55082" },
    { lng: -95.69895, lat: 47.855588, postalCode: "56684" },
    { lng: -93.05481, lat: 47.672765, postalCode: "55781" },
    { lng: -93.34155, lat: 45.064714, postalCode: "55429" },
    { lng: -95.88214, lat: 47.656231, postalCode: "56556" },
    { lng: -94.44973, lat: 45.624705, postalCode: "56310" },
    { lng: -93.89064, lat: 46.438976, postalCode: "56444" },
    { lng: -96.53492, lat: 47.55171, postalCode: "56517" },
    { lng: -96.40032, lat: 45.335958, postalCode: "56278" },
    { lng: -95.59604, lat: 43.623255, postalCode: "56187" },
    { lng: -96.07063, lat: 44.028354, postalCode: "56186" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55582" },
    { lng: -92.82748, lat: 46.379971, postalCode: "55783" },
    { lng: -94.74357, lat: 43.660847, postalCode: "56171" },
    { lng: -93.25007, lat: 45.050434, postalCode: "55421" },
    { lng: -93.85441, lat: 45.175558, postalCode: "55313" },
    { lng: -96.78265, lat: 47.36461, postalCode: "56548" },
    { lng: -94.32528, lat: 45.328802, postalCode: "55353" },
    { lng: -93.77839, lat: 44.768762, postalCode: "55322" },
    { lng: -94.40368, lat: 45.057766, postalCode: "55324" },
    { lng: -91.75117, lat: 43.80784, postalCode: "55971" },
    { lng: -96.07014, lat: 48.565391, postalCode: "56759" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55394" },
    { lng: -94.05089, lat: 47.64542, postalCode: "56659" },
    { lng: -93.43183, lat: 45.120764, postalCode: "55369" },
    { lng: -94.593338, lat: 45.52886, postalCode: "56396" },
    { lng: -93.43112, lat: 47.285247, postalCode: "55722" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55585" },
    { lng: -92.90939, lat: 44.999023, postalCode: "55042" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55478" },
    { lng: -95.22132, lat: 47.702771, postalCode: "56652" },
    { lng: -95.36594, lat: 44.974538, postalCode: "56222" },
    { lng: -95.559, lat: 46.428432, postalCode: "56571" },
    { lng: -93.62929, lat: 47.578093, postalCode: "56657" },
    { lng: -95.80539, lat: 46.080287, postalCode: "56309" },
    { lng: -93.80726, lat: 48.186112, postalCode: "56627" },
    { lng: -94.70512, lat: 46.977161, postalCode: "56433" },
    { lng: -93.25336, lat: 45.095674, postalCode: "55432" },
    { lng: -96.16888, lat: 45.290863, postalCode: "56227" },
    { lng: -95.66842, lat: 46.920986, postalCode: "56578" },
    { lng: -93.28557, lat: 44.971765, postalCode: "55403" },
    { lng: -93.52556, lat: 44.776138, postalCode: "55379" },
    { lng: -93.15922, lat: 44.953665, postalCode: "55104" },
    { lng: -95.38554, lat: 43.536338, postalCode: "56167" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55586" },
    { lng: -93.42694, lat: 43.563452, postalCode: "56089" },
    { lng: -93.92956, lat: 45.470068, postalCode: "55319" },
    { lng: -94.18186, lat: 44.029058, postalCode: "56034" },
    { lng: -92.80495, lat: 48.098627, postalCode: "55771" },
    { lng: -91.95965, lat: 43.70844, postalCode: "55949" },
    { lng: -95.47515, lat: 48.32162, postalCode: "56727" },
    { lng: -91.33919, lat: 43.829382, postalCode: "55947" },
    { lng: -93.93298, lat: 47.756467, postalCode: "56688" },
    { lng: -95.52504, lat: 45.728732, postalCode: "56349" },
    { lng: -93.30461, lat: 45.106065, postalCode: "55444" },
    { lng: -91.65106, lat: 44.033867, postalCode: "55987" },
    { lng: -92.442797, lat: 47.640367, postalCode: "55791" },
    { lng: -93.201107, lat: 45.642235, postalCode: "55029" },
    { lng: -95.43173, lat: 44.616243, postalCode: "56237" },
    { lng: -93.92085, lat: 45.046143, postalCode: "55363" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55480" },
    { lng: -96.83465, lat: 48.478075, postalCode: "56757" },
    { lng: -95.96704, lat: 45.990556, postalCode: "56531" },
    { lng: -93.11918, lat: 43.671266, postalCode: "56076" },
    { lng: -93.27434, lat: 45.335128, postalCode: "55011" },
    { lng: -91.48667, lat: 43.630012, postalCode: "55921" },
    { lng: -92.70852, lat: 43.731945, postalCode: "55926" },
    { lng: -96.58055, lat: 47.761982, postalCode: "56716" },
    { lng: -93.28362, lat: 44.924115, postalCode: "55409" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55323" },
    { lng: -93.71255, lat: 44.102188, postalCode: "56048" },
    { lng: -95.75803, lat: 44.007631, postalCode: "56172" },
    { lng: -92.41048, lat: 47.172574, postalCode: "55724" },
    { lng: -96.74309, lat: 47.10033, postalCode: "56546" },
    { lng: -93.95354, lat: 44.555899, postalCode: "56044" },
    { lng: -96.04823, lat: 44.932368, postalCode: "56232" },
    { lng: -92.20091, lat: 47.310374, postalCode: "55763" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55145" },
    { lng: -92.75843, lat: 47.086392, postalCode: "55765" },
    { lng: -93.39305, lat: 44.247889, postalCode: "55087" },
    { lng: -95.04504, lat: 45.118846, postalCode: "56201" },
    { lng: -94.84475, lat: 47.260013, postalCode: "56461" },
    { lng: -92.1606, lat: 43.84113, postalCode: "55923" },
    { lng: -93.200671, lat: 44.882838, postalCode: "55111" },
    { lng: -93.21987, lat: 43.550747, postalCode: "56036" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55592" },
    { lng: -93.398727, lat: 44.848263, postalCode: "55348" },
    { lng: -92.6388, lat: 44.024835, postalCode: "55920" },
    { lng: -93.23924, lat: 44.172277, postalCode: "55049" },
    { lng: -94.11071, lat: 46.527729, postalCode: "56465" },
    { lng: -95.549899, lat: 44.053323, postalCode: "56125" },
    { lng: -93.17489, lat: 44.982515, postalCode: "55108" },
    { lng: -94.10728, lat: 44.613278, postalCode: "55307" },
    { lng: -96.812921, lat: 48.771938, postalCode: "56740" },
    { lng: -93.24927, lat: 44.997665, postalCode: "55413" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55472" },
    { lng: -92.7799, lat: 43.797677, postalCode: "55973" },
    { lng: -92.442797, lat: 47.640367, postalCode: "55815" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55165" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55564" },
    { lng: -95.21378, lat: 44.317104, postalCode: "56294" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55479" },
    { lng: -93.42557, lat: 44.867013, postalCode: "55344" },
    { lng: -94.20649, lat: 45.526066, postalCode: "56301" },
    { lng: -93.24381, lat: 45.566735, postalCode: "55008" },
    { lng: -91.84612, lat: 44.079021, postalCode: "55969" },
    { lng: -94.49346, lat: 44.724926, postalCode: "55385" },
    { lng: -94.62877, lat: 43.535662, postalCode: "56121" },
    { lng: -94.00289, lat: 45.244744, postalCode: "55358" },
    { lng: -96.2303, lat: 43.653947, postalCode: "56156" },
    { lng: -95.18517, lat: 44.057605, postalCode: "56145" },
    { lng: -94.04244, lat: 44.184909, postalCode: "56003" },
    { lng: -92.442797, lat: 47.640367, postalCode: "55747" },
    { lng: -96.2921, lat: 44.725437, postalCode: "56220" },
    { lng: -92.72645, lat: 43.564175, postalCode: "55909" },
    { lng: -95.68104, lat: 46.011931, postalCode: "56326" },
    { lng: -96.558886, lat: 45.559291, postalCode: "56210" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55595" },
    { lng: -93.57071, lat: 44.320314, postalCode: "56052" },
    { lng: -93.81923, lat: 44.204121, postalCode: "56063" },
    { lng: -93.48757, lat: 45.102133, postalCode: "55311" },
    { lng: -93.01146, lat: 45.081266, postalCode: "55110" },
    { lng: -93.73548, lat: 45.087513, postalCode: "55373" },
    { lng: -94.46149, lat: 44.308395, postalCode: "56073" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55590" },
    { lng: -94.643922, lat: 45.49343, postalCode: "56302" },
    { lng: -96.02739, lat: 47.915369, postalCode: "56748" },
    { lng: -92.88864, lat: 45.365164, postalCode: "55013" },
    { lng: -92.53637, lat: 44.539036, postalCode: "55066" },
    { lng: -92.44427, lat: 44.064733, postalCode: "55906" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55561" },
    { lng: -95.9092, lat: 45.584124, postalCode: "56267" },
    { lng: -96.44694, lat: 45.459383, postalCode: "56225" },
    { lng: -93.23584, lat: 45.395494, postalCode: "55005" },
    { lng: -93.92866, lat: 43.931415, postalCode: "56065" },
    { lng: -92.59795, lat: 46.374991, postalCode: "55756" },
    { lng: -90.54659, lat: 47.939037, postalCode: "55604" },
    { lng: -94.23883, lat: 44.128212, postalCode: "56055" },
    { lng: -94.80503, lat: 46.380664, postalCode: "56479" },
    { lng: -93.23003, lat: 44.980379, postalCode: "55414" },
    { lng: -96.469194, lat: 47.325074, postalCode: "56541" },
    { lng: -94.18176, lat: 45.745266, postalCode: "56367" },
    { lng: -93.38212, lat: 44.8257, postalCode: "55438" },
    { lng: -96.40019, lat: 46.130213, postalCode: "56522" },
    { lng: -96.47578, lat: 48.565296, postalCode: "56732" },
    { lng: -93.38998, lat: 47.372262, postalCode: "55709" },
    { lng: -94.43935, lat: 43.930032, postalCode: "56060" },
    { lng: -95.75998, lat: 44.212536, postalCode: "56132" },
    { lng: -95.61543, lat: 46.235125, postalCode: "56524" },
    { lng: -93.83267, lat: 47.611885, postalCode: "56637" },
    { lng: -93.02829, lat: 45.403157, postalCode: "55079" },
    { lng: -96.53933, lat: 47.050391, postalCode: "56536" },
    { lng: -93.66491, lat: 47.247648, postalCode: "55721" },
    { lng: -96.06591, lat: 43.750164, postalCode: "56147" },
    { lng: -93.94205, lat: 46.735697, postalCode: "56447" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55182" },
    { lng: -93.354267, lat: 44.571056, postalCode: "55054" },
    { lng: -92.65082, lat: 46.870672, postalCode: "55711" },
    { lng: -93.38452, lat: 45.170714, postalCode: "55316" },
    { lng: -96.052227, lat: 44.238659, postalCode: "56130" },
    { lng: -95.25532, lat: 46.467905, postalCode: "56518" },
    { lng: -96.22202, lat: 43.783098, postalCode: "56134" },
    { lng: -92.780293, lat: 47.494762, postalCode: "55715" },
    { lng: -92.87749, lat: 43.82009, postalCode: "55982" },
    { lng: -92.04994, lat: 43.55123, postalCode: "55939" },
    { lng: -92.63972, lat: 46.956644, postalCode: "55702" },
    { lng: -92.99254, lat: 44.835366, postalCode: "55071" },
    { lng: -95.33465, lat: 44.59231, postalCode: "56214" },
    { lng: -95.84166, lat: 47.093608, postalCode: "56569" },
    { lng: -95.22335, lat: 45.344013, postalCode: "56289" },
    { lng: -93.60042, lat: 46.956138, postalCode: "55748" },
    { lng: -95.79661, lat: 46.528138, postalCode: "56528" },
    { lng: -96.15831, lat: 46.405807, postalCode: "56533" },
    { lng: -93.14296, lat: 48.443118, postalCode: "56669" },
    { lng: -92.66189, lat: 47.655096, postalCode: "55710" },
    { lng: -93.06893, lat: 44.825391, postalCode: "55077" },
    { lng: -93.56367, lat: 48.366833, postalCode: "56653" },
    { lng: -94.208731, lat: 46.061307, postalCode: "56344" },
    { lng: -95.99585, lat: 46.863375, postalCode: "56511" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55187" },
    { lng: -93.106593, lat: 44.990915, postalCode: "55164" },
    { lng: -96.2396, lat: 47.363986, postalCode: "56545" },
    { lng: -93.68716, lat: 44.207288, postalCode: "56028" },
    { lng: -94.91976, lat: 45.133103, postalCode: "56251" },
    { lng: -93.50758, lat: 47.852874, postalCode: "56639" },
    { lng: -93.81676, lat: 43.589604, postalCode: "56014" },
    { lng: -93.3722, lat: 44.761796, postalCode: "55378" },
    { lng: -92.86903, lat: 44.026272, postalCode: "55927" },
    { lng: -93.98055, lat: 46.528094, postalCode: "56441" },
    { lng: -95.265379, lat: 47.239049, postalCode: "56460" },
    { lng: -94.229311, lat: 44.673454, postalCode: "55366" },
    { lng: -92.34084, lat: 47.533314, postalCode: "55708" },
    { lng: -94.61716, lat: 43.672105, postalCode: "56181" },
    { lng: -94.50471, lat: 46.823666, postalCode: "56435" },
    { lng: -93.91666, lat: 44.756883, postalCode: "55368" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55567" },
    { lng: -93.93826, lat: 43.579548, postalCode: "56033" },
    { lng: -95.8996, lat: 45.299531, postalCode: "56249" },
    { lng: -95.59378, lat: 43.861532, postalCode: "56131" },
    { lng: -94.99921, lat: 45.327429, postalCode: "56273" },
    { lng: -93.67618, lat: 45.20382, postalCode: "55376" },
    { lng: -93.10666, lat: 44.989065, postalCode: "55117" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55440" },
    { lng: -93.90513, lat: 44.449976, postalCode: "56058" },
    { lng: -93.082793, lat: 44.942656, postalCode: "55146" },
    { lng: -94.633724, lat: 45.910946, postalCode: "56432" },
    { lng: -96.79448, lat: 48.342579, postalCode: "56713" },
    { lng: -92.409999, lat: 47.512426, postalCode: "55761" },
    { lng: -91.95965, lat: 44.128262, postalCode: "55910" },
    { lng: -95.31644, lat: 46.383585, postalCode: "56527" },
    { lng: -92.95703, lat: 45.054666, postalCode: "55090" },
    { lng: -93.21439, lat: 45.651767, postalCode: "55080" },
    { lng: -96.06973, lat: 48.436061, postalCode: "56737" },
    { lng: -93.30337, lat: 45.027715, postalCode: "55412" },
    { lng: -92.65482, lat: 44.196932, postalCode: "55963" },
    { lng: -94.38716, lat: 46.718997, postalCode: "56474" },
    { lng: -94.60782, lat: 44.751001, postalCode: "55314" },
    { lng: -93.755536, lat: 48.27888, postalCode: "56658" },
    { lng: -96.06011, lat: 43.642511, postalCode: "56158" },
    { lng: -93.69319, lat: 45.002212, postalCode: "55359" },
    { lng: -92.687947, lat: 46.471964, postalCode: "55676" },
    { lng: -95.98766, lat: 47.4514, postalCode: "56516" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55383" },
    { lng: -95.66742, lat: 45.795887, postalCode: "56343" },
    { lng: -93.58278, lat: 44.901368, postalCode: "55331" },
    { lng: -92.63312, lat: 44.408846, postalCode: "55027" },
    { lng: -93.2531, lat: 45.164248, postalCode: "55434" },
    { lng: -92.29074, lat: 44.244633, postalCode: "55957" },
    { lng: -95.73784, lat: 47.571567, postalCode: "56542" },
    { lng: -92.80597, lat: 45.41766, postalCode: "55012" },
    { lng: -96.23, lat: 44.476959, postalCode: "56142" },
    { lng: -94.95278, lat: 46.129933, postalCode: "56440" },
    { lng: -92.89357, lat: 44.157166, postalCode: "55985" },
    { lng: -94.04714, lat: 44.787651, postalCode: "55370" },
    { lng: -92.46078, lat: 47.058166, postalCode: "55717" },
    { lng: -96.1285, lat: 43.89387, postalCode: "56128" },
    { lng: -92.7278, lat: 45.381784, postalCode: "55074" },
    { lng: -96.24441, lat: 45.897971, postalCode: "56274" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55570" },
    { lng: -93.65053, lat: 45.088013, postalCode: "55357" },
    { lng: -93.14463, lat: 45.153516, postalCode: "55014" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55470" },
    { lng: -95.08213, lat: 45.799542, postalCode: "56389" },
    { lng: -93.37702, lat: 45.059997, postalCode: "55428" },
    { lng: -92.04204, lat: 44.374261, postalCode: "55981" },
    { lng: -92.87637, lat: 47.509833, postalCode: "55719" },
    { lng: -93.20001, lat: 45.168287, postalCode: "55449" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55566" },
    { lng: -92.96843, lat: 45.690784, postalCode: "55069" },
    { lng: -93.5203, lat: 46.702947, postalCode: "56469" },
    { lng: -93.20624, lat: 44.743963, postalCode: "55124" },
    { lng: -92.12718, lat: 46.770773, postalCode: "55806" },
    { lng: -94.93168, lat: 47.854118, postalCode: "56670" },
    { lng: -93.54487, lat: 44.866236, postalCode: "55317" },
    { lng: -95.59321, lat: 44.084381, postalCode: "56123" },
    { lng: -94.419941, lat: 45.578278, postalCode: "56321" },
    { lng: -96.0663, lat: 46.608461, postalCode: "56572" },
    { lng: -92.23977, lat: 47.813694, postalCode: "55782" },
    { lng: -94.245, lat: 47.340327, postalCode: "56626" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55573" },
    { lng: -92.9651, lat: 44.268145, postalCode: "55946" },
    { lng: -95.70555, lat: 44.609098, postalCode: "56229" },
    { lng: -92.66548, lat: 47.502878, postalCode: "55768" },
    { lng: -93.77764, lat: 44.007228, postalCode: "56078" },
    { lng: -93.20693, lat: 47.30797, postalCode: "55775" },
    { lng: -94.57143, lat: 44.070204, postalCode: "56056" },
    { lng: -93.28732, lat: 44.29478, postalCode: "55021" },
    { lng: -92.178277, lat: 44.301714, postalCode: "55978" },
    { lng: -96.1793, lat: 48.118625, postalCode: "56701" },
    { lng: -93.26179, lat: 44.963365, postalCode: "55404" },
    { lng: -92.2948, lat: 44.42278, postalCode: "55041" },
    { lng: -93.04462, lat: 46.250308, postalCode: "55735" },
    { lng: -92.97816, lat: 45.509818, postalCode: "55056" },
    { lng: -93.979766, lat: 45.315823, postalCode: "55380" },
    { lng: -96.506156, lat: 46.890034, postalCode: "56562" },
    { lng: -91.79943, lat: 47.930468, postalCode: "55796" },
    { lng: -96.1539, lat: 47.702366, postalCode: "56736" },
    { lng: -94.19821, lat: 45.299358, postalCode: "55382" },
    { lng: -94.593338, lat: 45.52886, postalCode: "56372" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55554" },
    { lng: -94.18727, lat: 47.821937, postalCode: "56661" },
    { lng: -93.5884, lat: 44.234274, postalCode: "56096" },
    { lng: -94.97236, lat: 45.729898, postalCode: "56378" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55575" },
    { lng: -95.62935, lat: 46.742349, postalCode: "56544" },
    { lng: -93.29823, lat: 44.999165, postalCode: "55411" },
    { lng: -93.815434, lat: 45.540181, postalCode: "55377" },
    { lng: -94.93781, lat: 46.376819, postalCode: "56434" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55562" },
    { lng: -93.57644, lat: 45.576431, postalCode: "55371" },
    { lng: -93.84832, lat: 47.382872, postalCode: "56636" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55168" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55557" },
    { lng: -96.33238, lat: 46.290371, postalCode: "56543" },
    { lng: -95.69334, lat: 45.409218, postalCode: "56226" },
    { lng: -95.61749, lat: 47.257131, postalCode: "56566" },
    { lng: -94.57067, lat: 45.80679, postalCode: "56384" },
    { lng: -91.87839, lat: 47.309443, postalCode: "55602" },
    { lng: -93.12049, lat: 44.935315, postalCode: "55102" },
    { lng: -93.14538, lat: 44.873398, postalCode: "55120" },
    { lng: -96.32534, lat: 48.348745, postalCode: "56738" },
    { lng: -93.99388, lat: 44.15644, postalCode: "56001" },
    { lng: -92.2276, lat: 46.674141, postalCode: "55808" },
    { lng: -96.32855, lat: 45.140784, postalCode: "56212" },
    { lng: -95.391968, lat: 46.821648, postalCode: "56593" },
    { lng: -91.29977, lat: 47.258584, postalCode: "55601" },
    { lng: -93.81718, lat: 43.848573, postalCode: "56068" },
    { lng: -92.53371, lat: 47.527383, postalCode: "55792" },
    { lng: -95.64614, lat: 47.465509, postalCode: "56651" },
    { lng: -94.72447, lat: 44.74323, postalCode: "55342" },
    { lng: -94.22429, lat: 45.550378, postalCode: "56387" },
    { lng: -93.11852, lat: 45.871294, postalCode: "55036" },
    { lng: -94.593338, lat: 45.52886, postalCode: "56398" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55555" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55166" },
    { lng: -95.678375, lat: 46.933961, postalCode: "56577" },
    { lng: -94.91902, lat: 44.417533, postalCode: "56266" },
    { lng: -96.51487, lat: 47.158015, postalCode: "56519" },
    { lng: -93.97836, lat: 45.066811, postalCode: "55390" },
    { lng: -93.06834, lat: 45.329115, postalCode: "55092" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55584" },
    { lng: -96.30781, lat: 46.864977, postalCode: "56549" },
    { lng: -91.91712, lat: 47.913365, postalCode: "55731" },
    { lng: -95.03571, lat: 49.342144, postalCode: "56711" },
    { lng: -95.83042, lat: 46.64246, postalCode: "56587" },
    { lng: -93.33988, lat: 45.108581, postalCode: "55443" },
    { lng: -93.23662, lat: 44.905365, postalCode: "55417" },
    { lng: -94.593338, lat: 45.52886, postalCode: "56393" },
    { lng: -93.1044, lat: 45.955831, postalCode: "55007" },
    { lng: -93.66261, lat: 45.237867, postalCode: "55301" },
    { lng: -95.678375, lat: 46.933961, postalCode: "56502" },
    { lng: -94.20965, lat: 47.954759, postalCode: "56660" },
    { lng: -95.56737, lat: 44.794723, postalCode: "56241" },
    { lng: -96.6879, lat: 45.593033, postalCode: "56211" },
    { lng: -94.51934, lat: 48.153759, postalCode: "56685" },
    { lng: -96.72953, lat: 47.197932, postalCode: "56574" },
    { lng: -95.92771, lat: 44.396043, postalCode: "56157" },
    { lng: -96.39608, lat: 45.664974, postalCode: "56236" },
    { lng: -95.25784, lat: 44.403815, postalCode: "56293" },
    { lng: -95.6602, lat: 48.609478, postalCode: "56761" },
    { lng: -95.09659, lat: 46.770167, postalCode: "56464" },
    { lng: -93.31947, lat: 44.911564, postalCode: "55410" },
    { lng: -93.53072, lat: 43.613686, postalCode: "56020" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55161" },
    { lng: -92.86434, lat: 43.743087, postalCode: "55918" },
    { lng: -92.68321, lat: 45.424065, postalCode: "55084" },
    { lng: -96.06801, lat: 44.598116, postalCode: "56291" },
    { lng: -96.51915, lat: 47.322444, postalCode: "56510" },
    { lng: -96.54888, lat: 46.27324, postalCode: "56520" },
    { lng: -95.50754, lat: 45.950306, postalCode: "56332" },
    { lng: -94.17684, lat: 48.551313, postalCode: "56629" },
    { lng: -90.81276, lat: 47.677189, postalCode: "55615" },
    { lng: -93.01627, lat: 44.035716, postalCode: "55924" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55559" },
    { lng: -94.98313, lat: 44.239384, postalCode: "56087" },
    { lng: -93.40857, lat: 44.921599, postalCode: "55343" },
    { lng: -95.33754, lat: 46.156758, postalCode: "56361" },
    { lng: -94.94661, lat: 45.562459, postalCode: "56325" },
    { lng: -95.06581, lat: 46.974221, postalCode: "56470" },
    { lng: -93.16554, lat: 44.934465, postalCode: "55105" },
    { lng: -95.09004, lat: 48.730013, postalCode: "56673" },
    { lng: -92.77077, lat: 47.854987, postalCode: "55723" },
    { lng: -95.91403, lat: 44.015184, postalCode: "56151" },
    { lng: -92.24109, lat: 47.45225, postalCode: "55705" },
    { lng: -95.64403, lat: 47.771933, postalCode: "56646" },
    { lng: -94.16289, lat: 46.352758, postalCode: "56401" },
    { lng: -95.05923, lat: 44.945988, postalCode: "56216" },
    { lng: -93.921429, lat: 47.087782, postalCode: "55730" },
    { lng: -95.65028, lat: 44.690608, postalCode: "56245" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55468" },
    { lng: -93.26713, lat: 43.819798, postalCode: "56035" },
    { lng: -92.53501, lat: 44.272368, postalCode: "55956" },
    { lng: -96.252794, lat: 43.674883, postalCode: "56146" },
    { lng: -94.31758, lat: 45.091115, postalCode: "55325" },
    { lng: -95.28168, lat: 46.063391, postalCode: "56354" },
    { lng: -96.5018, lat: 48.472648, postalCode: "56758" },
    { lng: -94.79069, lat: 45.128657, postalCode: "56209" },
    { lng: -91.68187, lat: 44.019989, postalCode: "55942" },
    { lng: -93.95806, lat: 44.28491, postalCode: "56050" },
    { lng: -94.19561, lat: 44.331757, postalCode: "56074" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55597" },
    { lng: -93.71308, lat: 43.535709, postalCode: "56051" },
    { lng: -94.83013, lat: 45.34373, postalCode: "56246" },
    { lng: -91.46635, lat: 43.511822, postalCode: "55931" },
    { lng: -91.25765, lat: 47.314743, postalCode: "55614" },
    { lng: -93.62868, lat: 44.936423, postalCode: "55384" },
    { lng: -93.9153, lat: 46.035241, postalCode: "56338" },
    { lng: -95.86913, lat: 45.113285, postalCode: "56262" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55583" },
    { lng: -93.26654, lat: 44.669564, postalCode: "55044" },
    { lng: -93.05001, lat: 44.967565, postalCode: "55106" },
    { lng: -95.84846, lat: 47.899103, postalCode: "56742" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55488" },
    { lng: -96.20637, lat: 48.698749, postalCode: "56726" },
    { lng: -93.113155, lat: 44.741484, postalCode: "55608" },
    { lng: -92.69235, lat: 46.670691, postalCode: "55780" },
    { lng: -95.8183, lat: 44.761504, postalCode: "56223" },
    { lng: -93.19335, lat: 45.076365, postalCode: "55112" },
    { lng: -91.91856, lat: 47.688083, postalCode: "55706" },
    { lng: -92.93739, lat: 44.830966, postalCode: "55016" },
    { lng: -95.01222, lat: 46.630023, postalCode: "56477" },
    { lng: -95.9021, lat: 44.227802, postalCode: "56115" },
    { lng: -93.13241, lat: 46.679313, postalCode: "55787" },
    { lng: -94.70519, lat: 45.389395, postalCode: "56362" },
    { lng: -96.74693, lat: 46.659932, postalCode: "56525" },
    { lng: -92.65902, lat: 43.550714, postalCode: "55977" },
    { lng: -96.00853, lat: 47.530842, postalCode: "56592" },
    { lng: -95.87672, lat: 47.330624, postalCode: "56557" },
    { lng: -92.12889, lat: 43.74676, postalCode: "55935" },
    { lng: -92.16969, lat: 46.738841, postalCode: "55807" },
    { lng: -94.45647, lat: 45.749908, postalCode: "56340" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55483" },
    { lng: -93.20847, lat: 44.882365, postalCode: "55450" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55459" },
    { lng: -94.228897, lat: 43.738397, postalCode: "56047" },
    { lng: -92.3247, lat: 47.825059, postalCode: "55790" },
    { lng: -92.75013, lat: 44.035512, postalCode: "55944" },
    { lng: -92.83551, lat: 45.387281, postalCode: "55045" },
    { lng: -93.08397, lat: 44.964852, postalCode: "55101" },
    { lng: -95.35104, lat: 45.609555, postalCode: "56334" },
    { lng: -93.34422, lat: 44.822764, postalCode: "55437" },
    { lng: -91.74422, lat: 44.093056, postalCode: "55959" },
    { lng: -95.30674, lat: 44.031366, postalCode: "56174" },
    { lng: -92.80894, lat: 43.885028, postalCode: "55940" },
    { lng: -94.52597, lat: 45.448703, postalCode: "56368" },
    { lng: -93.646503, lat: 45.697362, postalCode: "56363" },
    { lng: -92.77001, lat: 44.9316, postalCode: "55043" },
    { lng: -93.58388, lat: 44.985463, postalCode: "55356" },
    { lng: -93.55598, lat: 43.667207, postalCode: "56009" },
    { lng: -95.06695, lat: 44.375214, postalCode: "56224" },
    { lng: -94.822154, lat: 47.625699, postalCode: "56619" },
    { lng: -95.21527, lat: 47.506665, postalCode: "56676" },
    { lng: -92.65485, lat: 47.296271, postalCode: "55738" },
    { lng: -93.274017, lat: 43.955103, postalCode: "56046" },
    { lng: -95.13275, lat: 43.870469, postalCode: "56101" },
    { lng: -93.17459, lat: 44.913815, postalCode: "55116" },
    { lng: -94.83088, lat: 46.149276, postalCode: "56438" },
    { lng: -94.13838, lat: 45.608178, postalCode: "56379" },
    { lng: -93.19581, lat: 44.964815, postalCode: "55114" },
    { lng: -95.5078, lat: 47.755904, postalCode: "56644" },
    { lng: -92.76689, lat: 47.764569, postalCode: "55703" },
    { lng: -95.02938, lat: 43.936146, postalCode: "56118" },
    { lng: -93.24304, lat: 45.019315, postalCode: "55418" },
    { lng: -96.13895, lat: 45.800145, postalCode: "56248" },
    { lng: -94.54953, lat: 48.663939, postalCode: "56623" },
    { lng: -94.20634, lat: 43.899907, postalCode: "56010" },
    { lng: -93.33487, lat: 44.875614, postalCode: "55435" },
    { lng: -96.81833, lat: 45.606998, postalCode: "56219" },
    { lng: -96.42891, lat: 46.653912, postalCode: "56514" },
    { lng: -92.68312, lat: 44.297157, postalCode: "55992" },
    { lng: -93.74294, lat: 45.358004, postalCode: "55309" },
    { lng: -89.73735, lat: 47.967442, postalCode: "55605" },
    { lng: -92.87081, lat: 46.667284, postalCode: "55726" },
    { lng: -93.6105, lat: 44.809954, postalCode: "55318" },
    { lng: -94.73426, lat: 44.29717, postalCode: "56085" },
    { lng: -92.24355, lat: 46.753414, postalCode: "55810" },
    { lng: -93.2917, lat: 43.884985, postalCode: "56026" },
    { lng: -96.16532, lat: 44.633159, postalCode: "56280" },
    { lng: -96.054791, lat: 44.716494, postalCode: "56286" },
    { lng: -93.89981, lat: 44.909362, postalCode: "55360" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55565" },
    { lng: -95.32579, lat: 44.475845, postalCode: "56287" },
    { lng: -92.50425, lat: 43.786802, postalCode: "55967" },
    { lng: -96.3866, lat: 43.621142, postalCode: "56116" },
    { lng: -96.74918, lat: 48.071847, postalCode: "56712" },
    { lng: -93.71837, lat: 43.731479, postalCode: "56097" },
    { lng: -93.28391, lat: 46.67746, postalCode: "55760" },
    { lng: -93.42421, lat: 45.001997, postalCode: "55441" },
    { lng: -94.05525, lat: 44.960829, postalCode: "55395" },
    { lng: -95.19501, lat: 43.649239, postalCode: "56150" },
    { lng: -94.4211, lat: 44.045998, postalCode: "56062" },
    { lng: -94.61868, lat: 47.666705, postalCode: "56647" },
    { lng: -92.62697, lat: 46.533977, postalCode: "55707" },
    { lng: -96.17095, lat: 44.398668, postalCode: "56113" },
    { lng: -93.00187, lat: 44.871166, postalCode: "55055" },
    { lng: -94.63345, lat: 43.986563, postalCode: "56081" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55556" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55551" },
    { lng: -95.541618, lat: 45.830998, postalCode: "56341" },
    { lng: -93.27947, lat: 45.470239, postalCode: "55040" },
    { lng: -94.641872, lat: 46.862332, postalCode: "56430" },
    { lng: -94.99344, lat: 47.501402, postalCode: "56687" },
    { lng: -96.54727, lat: 46.723274, postalCode: "56513" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55474" },
    { lng: -93.28742, lat: 44.947515, postalCode: "55408" },
    { lng: -93.27137, lat: 44.984615, postalCode: "55401" },
    { lng: -91.52953, lat: 47.664633, postalCode: "55607" },
    { lng: -93.15353, lat: 44.544465, postalCode: "55010" },
    { lng: -94.28917, lat: 46.501623, postalCode: "56468" },
    { lng: -93.14431, lat: 44.844965, postalCode: "55121" },
    { lng: -92.09568, lat: 46.798339, postalCode: "55805" },
    { lng: -93.76906, lat: 44.616113, postalCode: "56011" },
    { lng: -93.42243, lat: 44.464394, postalCode: "55046" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55191" },
    { lng: -92.51616, lat: 43.525845, postalCode: "55951" },
    { lng: -96.83308, lat: 47.826487, postalCode: "56723" },
    { lng: -94.42539, lat: 45.814436, postalCode: "56314" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55598" },
    { lng: -95.51436, lat: 48.869494, postalCode: "56756" },
    { lng: -92.86147, lat: 44.726517, postalCode: "55033" },
    { lng: -94.4646, lat: 46.963355, postalCode: "56452" },
    { lng: -95.80946, lat: 43.608503, postalCode: "56168" },
    { lng: -94.43811, lat: 45.291986, postalCode: "55389" },
    { lng: -93.29771, lat: 48.603143, postalCode: "56668" },
    { lng: -93.546333, lat: 45.281604, postalCode: "55530" },
    { lng: -95.95855, lat: 43.89707, postalCode: "56122" },
    { lng: -92.33216, lat: 44.523212, postalCode: "55026" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55587" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55392" },
    { lng: -93.880245, lat: 44.989512, postalCode: "55588" },
    { lng: -92.10105, lat: 46.972432, postalCode: "55803" },
    { lng: -92.97908, lat: 43.671988, postalCode: "55912" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55578" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55460" },
    { lng: -93.2449, lat: 44.846765, postalCode: "55425" },
    { lng: -93.23588, lat: 44.971965, postalCode: "55455" },
    { lng: -92.81649, lat: 45.195606, postalCode: "55047" },
    { lng: -95.17748, lat: 45.200641, postalCode: "56279" },
    { lng: -93.45161, lat: 43.723693, postalCode: "56064" },
    { lng: -93.084687, lat: 46.147161, postalCode: "55272" },
    { lng: -95.89576, lat: 44.515475, postalCode: "56239" },
    { lng: -94.88831, lat: 44.959237, postalCode: "56253" },
    { lng: -93.13179, lat: 47.169979, postalCode: "55742" },
    { lng: -95.48031, lat: 44.943885, postalCode: "56260" },
    { lng: -92.95813, lat: 45.824236, postalCode: "55063" },
    { lng: -93.33965, lat: 45.014764, postalCode: "55422" },
    { lng: -93.04546, lat: 44.887966, postalCode: "55075" },
    { lng: -93.05543, lat: 44.338132, postalCode: "55053" },
    { lng: -93.44162, lat: 45.831767, postalCode: "56358" },
    { lng: -95.55911, lat: 46.607429, postalCode: "56573" },
    { lng: -92.95762, lat: 45.060048, postalCode: "55115" },
    { lng: -92.83001, lat: 45.275771, postalCode: "55073" },
    { lng: -92.42937, lat: 43.988708, postalCode: "55904" },
    { lng: -95.38287, lat: 45.88645, postalCode: "56308" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55190" },
    { lng: -94.71889, lat: 47.690018, postalCode: "56683" },
    { lng: -93.1645, lat: 47.406731, postalCode: "55769" },
    { lng: -92.81904, lat: 44.903133, postalCode: "55001" },
    { lng: -92.540929, lat: 43.996613, postalCode: "55903" },
    { lng: -95.523182, lat: 43.820313, postalCode: "56126" },
    { lng: -93.52599, lat: 47.243062, postalCode: "55744" },
    { lng: -94.87105, lat: 47.504496, postalCode: "56601" },
    { lng: -94.23203, lat: 47.243893, postalCode: "56641" },
    { lng: -93.11924, lat: 45.842603, postalCode: "55030" },
    { lng: -96.76456, lat: 47.271427, postalCode: "56550" },
    { lng: -96.62429, lat: 46.434718, postalCode: "56553" },
    { lng: -94.8582, lat: 45.973572, postalCode: "56347" },
    { lng: -94.20959, lat: 45.622966, postalCode: "56377" },
    { lng: -94.21508, lat: 46.975876, postalCode: "56655" },
    { lng: -95.747559, lat: 48.769244, postalCode: "56682" },
    { lng: -94.52834, lat: 45.953535, postalCode: "56328" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55593" },
    { lng: -93.66038, lat: 44.933712, postalCode: "55364" },
    { lng: -93.51768, lat: 43.516115, postalCode: "56029" },
    { lng: -95.55359, lat: 44.6367, postalCode: "56297" },
    { lng: -94.93058, lat: 43.942515, postalCode: "56159" },
    { lng: -94.87594, lat: 48.018746, postalCode: "56666" },
    { lng: -93.29977, lat: 45.059265, postalCode: "55430" },
    { lng: -92.51331, lat: 47.4211, postalCode: "55734" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55171" },
    { lng: -93.57104, lat: 45.197646, postalCode: "55374" },
    { lng: -93.755536, lat: 48.27888, postalCode: "56679" },
    { lng: -95.77281, lat: 44.446885, postalCode: "56258" },
    { lng: -95.3633, lat: 44.2127, postalCode: "56166" },
    { lng: -94.08461, lat: 45.996396, postalCode: "56364" },
    { lng: -92.24856, lat: 44.006609, postalCode: "55934" },
    { lng: -94.17294, lat: 44.773886, postalCode: "55336" },
    { lng: -94.53092, lat: 44.527707, postalCode: "55335" },
    { lng: -94.79571, lat: 43.957826, postalCode: "56120" },
    { lng: -95.43451, lat: 47.042394, postalCode: "56575" },
    { lng: -94.5238, lat: 44.149047, postalCode: "56041" },
    { lng: -93.30077, lat: 47.319362, postalCode: "55764" },
    { lng: -93.36011, lat: 44.571365, postalCode: "55020" },
    { lng: -93.37005, lat: 45.395912, postalCode: "55070" },
    { lng: -96.59592, lat: 48.655487, postalCode: "56729" },
    { lng: -93.79591, lat: 45.032489, postalCode: "55328" },
    { lng: -94.10686, lat: 46.67066, postalCode: "56442" },
    { lng: -93.27657, lat: 44.837964, postalCode: "55420" },
    { lng: -94.05629, lat: 45.691714, postalCode: "56333" },
    { lng: -92.71377, lat: 46.131798, postalCode: "55072" },
    { lng: -94.27017, lat: 46.347175, postalCode: "56425" },
    { lng: -93.56982, lat: 44.429956, postalCode: "56069" },
    { lng: -93.27581, lat: 47.32211, postalCode: "55716" },
    { lng: -96.40968, lat: 44.986761, postalCode: "56257" },
    { lng: -92.79349, lat: 46.008128, postalCode: "55037" },
    { lng: -95.27033, lat: 45.990781, postalCode: "56319" },
    { lng: -95.09671, lat: 44.79129, postalCode: "56230" },
    { lng: -94.81417, lat: 45.659491, postalCode: "56352" },
    { lng: -93.30273, lat: 44.971798, postalCode: "55405" },
    { lng: -93.07546, lat: 43.88481, postalCode: "55917" },
    { lng: -93.01179, lat: 45.586488, postalCode: "55032" },
    { lng: -93.80256, lat: 45.699995, postalCode: "56357" },
    { lng: -94.42511, lat: 45.462728, postalCode: "56320" },
    { lng: -92.78039, lat: 45.017767, postalCode: "55003" },
    { lng: -91.8453, lat: 43.762272, postalCode: "55962" },
    { lng: -94.63552, lat: 45.432316, postalCode: "56371" },
    { lng: -92.92803, lat: 46.512349, postalCode: "55757" },
    { lng: -96.75367, lat: 46.861413, postalCode: "56560" },
    { lng: -93.85663, lat: 44.080713, postalCode: "56080" },
    { lng: -96.01013, lat: 46.470638, postalCode: "56534" },
    { lng: -95.94459, lat: 43.763442, postalCode: "56155" },
    { lng: -94.07246, lat: 46.747211, postalCode: "56448" },
    { lng: -96.47706, lat: 45.823795, postalCode: "56296" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55558" },
    { lng: -92.70293, lat: 44.586242, postalCode: "55089" },
    { lng: -94.91418, lat: 48.786139, postalCode: "56686" },
    { lng: -93.22716, lat: 44.07393, postalCode: "55060" },
    { lng: -95.46946, lat: 43.702735, postalCode: "56119" },
    { lng: -94.430518, lat: 46.829136, postalCode: "56135" },
    { lng: -95.8518, lat: 46.809965, postalCode: "56501" },
    { lng: -94.8715, lat: 43.645226, postalCode: "56111" },
    { lng: -92.96532, lat: 44.985792, postalCode: "55128" },
    { lng: -95.74517, lat: 47.174775, postalCode: "56589" },
    { lng: -93.00089, lat: 44.422975, postalCode: "55018" },
    { lng: -96.0186, lat: 43.834517, postalCode: "56153" },
    { lng: -94.36811, lat: 44.536882, postalCode: "55396" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55576" },
    { lng: -95.14289, lat: 46.447659, postalCode: "56482" },
    { lng: -95.46821, lat: 44.249331, postalCode: "56180" },
    { lng: -95.42013, lat: 44.397402, postalCode: "56255" },
    { lng: -93.55343, lat: 45.785883, postalCode: "56313" },
    { lng: -93.70721, lat: 46.878205, postalCode: "55785" },
    { lng: -93.56455, lat: 45.07343, postalCode: "55340" },
    { lng: -95.45228, lat: 44.501023, postalCode: "56292" },
    { lng: -93.38487, lat: 47.314189, postalCode: "55786" },
    { lng: -92.09206, lat: 46.774593, postalCode: "55802" },
    { lng: -94.18748, lat: 45.085142, postalCode: "55321" },
    { lng: -90.01705, lat: 47.940908, postalCode: "55606" },
    { lng: -93.13378, lat: 44.804048, postalCode: "55123" },
    { lng: -94.50864, lat: 46.099545, postalCode: "56475" },
    { lng: -92.94234, lat: 44.921982, postalCode: "55125" },
    { lng: -94.10129, lat: 43.630763, postalCode: "56013" },
    { lng: -94.5319, lat: 47.722624, postalCode: "56630" },
    { lng: -94.77124, lat: 43.847252, postalCode: "56160" },
    { lng: -92.90614, lat: 46.929033, postalCode: "55736" },
    { lng: -96.66654, lat: 47.970507, postalCode: "56722" },
    { lng: -93.08876, lat: 44.928315, postalCode: "55107" },
    { lng: -93.37795, lat: 44.952064, postalCode: "55426" },
    { lng: -93.592735, lat: 44.940212, postalCode: "55361" },
    { lng: -95.22418, lat: 45.028945, postalCode: "56282" },
    { lng: -96.68027, lat: 48.892011, postalCode: "56735" },
    { lng: -93.19781, lat: 45.722, postalCode: "55006" },
    { lng: -95.41116, lat: 44.043567, postalCode: "56183" },
    { lng: -94.44806, lat: 47.464399, postalCode: "56663" },
    { lng: -94.33651, lat: 46.650706, postalCode: "56456" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55133" },
    { lng: -94.18802, lat: 44.904987, postalCode: "55381" },
    { lng: -93.43992, lat: 44.232811, postalCode: "55052" },
    { lng: -93.38223, lat: 45.006764, postalCode: "55427" },
    { lng: -94.97109, lat: 47.206754, postalCode: "56458" },
    { lng: -95.57247, lat: 45.317872, postalCode: "56215" },
    { lng: -96.07363, lat: 44.17521, postalCode: "56170" },
    { lng: -93.02959, lat: 44.534866, postalCode: "55065" },
    { lng: -92.06276, lat: 43.977555, postalCode: "55972" },
    { lng: -94.33922, lat: 44.723259, postalCode: "55312" },
    { lng: -96.98774, lat: 47.956458, postalCode: "56721" },
    { lng: -92.89402, lat: 44.493575, postalCode: "55009" },
    { lng: -94.27609, lat: 45.702598, postalCode: "56375" },
    { lng: -94.96697, lat: 45.460931, postalCode: "56312" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55574" },
    { lng: -92.83756, lat: 43.599507, postalCode: "55970" },
    { lng: -93.38713, lat: 44.541055, postalCode: "55088" },
    { lng: -93.105869, lat: 45.005902, postalCode: "55172" },
    { lng: -92.57886, lat: 46.275431, postalCode: "55712" },
    { lng: -96.70242, lat: 46.878062, postalCode: "56529" },
    { lng: -95.82563, lat: 43.783453, postalCode: "56185" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55577" },
    { lng: -94.33773, lat: 43.680519, postalCode: "56039" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55399" },
    { lng: -93.45729, lat: 46.212757, postalCode: "56342" },
    { lng: -95.35045, lat: 44.79624, postalCode: "56285" },
    { lng: -95.13636, lat: 45.510591, postalCode: "56316" },
    { lng: -94.98994, lat: 44.552749, postalCode: "56270" },
    { lng: -93.83554, lat: 48.527572, postalCode: "56654" },
    { lng: -93.92368, lat: 44.801162, postalCode: "55397" },
    { lng: -92.96823, lat: 44.674317, postalCode: "55085" },
    { lng: -96.36669, lat: 43.529153, postalCode: "56138" },
    { lng: -96.78313, lat: 47.53525, postalCode: "56568" },
    { lng: -93.66341, lat: 44.858595, postalCode: "55386" },
    { lng: -93.97154, lat: 44.886776, postalCode: "55367" },
    { lng: -95.69138, lat: 43.513198, postalCode: "56117" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55473" },
    { lng: -93.9577, lat: 44.730662, postalCode: "55339" },
    { lng: -92.466826, lat: 44.022513, postalCode: "55905" },
    { lng: -93.28652, lat: 45.254715, postalCode: "55304" },
    { lng: -94.95001, lat: 45.238936, postalCode: "56288" },
    { lng: -93.921429, lat: 47.087782, postalCode: "55745" },
    { lng: -92.94228, lat: 47.413817, postalCode: "55746" },
    { lng: -95.60302, lat: 45.778773, postalCode: "56327" },
    { lng: -93.86918, lat: 44.153696, postalCode: "56024" },
    { lng: -93.66983, lat: 46.073505, postalCode: "56359" },
    { lng: -93.766524, lat: 44.805487, postalCode: "55550" },
    { lng: -95.98153, lat: 44.58701, postalCode: "56264" },
    { lng: -94.56778, lat: 45.321197, postalCode: "55329" },
    { lng: -91.95325, lat: 43.93422, postalCode: "55979" },
    { lng: -93.20456, lat: 47.061875, postalCode: "55784" },
    { lng: -94.58257, lat: 45.632455, postalCode: "56307" },
    { lng: -93.888099, lat: 45.200875, postalCode: "55580" },
    { lng: -96.78723, lat: 47.457626, postalCode: "56581" },
    { lng: -95.137497, lat: 46.439079, postalCode: "56483" },
    { lng: -96.20491, lat: 48.011365, postalCode: "56754" },
    { lng: -95.84679, lat: 46.330985, postalCode: "56586" },
    { lng: -95.17628, lat: 44.945831, postalCode: "56281" },
    { lng: -95.94042, lat: 44.838823, postalCode: "56218" },
    { lng: -95.87008, lat: 47.015728, postalCode: "56521" },
    { lng: -94.593338, lat: 45.52886, postalCode: "56397" },
    { lng: -94.68446, lat: 43.843264, postalCode: "56162" },
    { lng: -94.069828, lat: 44.056047, postalCode: "56006" },
    { lng: -95.13348, lat: 44.21838, postalCode: "56083" },
    { lng: -92.79816, lat: 44.285672, postalCode: "55983" },
    { lng: -92.70596, lat: 43.65099, postalCode: "55933" },
    { lng: -93.47188, lat: 45.015914, postalCode: "55596" },
    { lng: -93.224, lat: 43.65048, postalCode: "56043" },
    { lng: -93.25941, lat: 44.973415, postalCode: "55415" },
    { lng: -96.13988, lat: 46.033624, postalCode: "56590" },
    { lng: -94.4436, lat: 43.834331, postalCode: "56088" },
    { lng: -93.3789, lat: 45.112742, postalCode: "55445" },
    { lng: -96.74207, lat: 48.195512, postalCode: "56762" },
    { lng: -93.515456, lat: 46.631748, postalCode: "56643" },
    { lng: -95.33831, lat: 48.890886, postalCode: "56763" },
    { lng: -96.01816, lat: 48.795471, postalCode: "56714" },
    { lng: -93.34364, lat: 36.54602, postalCode: "65611" },
    { lng: -93.31489, lat: 40.115577, postalCode: "64646" },
    { lng: -93.55485, lat: 38.732758, postalCode: "65305" },
    { lng: -92.81278, lat: 39.024196, postalCode: "65250" },
    { lng: -94.4509, lat: 36.61041, postalCode: "64847" },
    { lng: -90.12915, lat: 36.808827, postalCode: "63936" },
    { lng: -90.93137, lat: 39.354092, postalCode: "63336" },
    { lng: -93.0784, lat: 37.122896, postalCode: "65742" },
    { lng: -89.78369, lat: 36.992718, postalCode: "63735" },
    { lng: -94.408998, lat: 39.282869, postalCode: "64160" },
    { lng: -95.09514, lat: 39.986725, postalCode: "64473" },
    { lng: -90.84892, lat: 37.313752, postalCode: "63665" },
    { lng: -95.082241, lat: 40.287996, postalCode: "64478" },
    { lng: -94.14225, lat: 38.99535, postalCode: "64075" },
    { lng: -93.46006, lat: 39.542571, postalCode: "64682" },
    { lng: -90.40966, lat: 38.582702, postalCode: "63122" },
    { lng: -94.53985, lat: 39.107304, postalCode: "64124" },
    { lng: -89.222096, lat: 36.916675, postalCode: "63832" },
    { lng: -91.31284, lat: 38.862422, postalCode: "63351" },
    { lng: -93.78328, lat: 36.935443, postalCode: "65769" },
    { lng: -94.0304, lat: 40.266201, postalCode: "64424" },
    { lng: -91.64872, lat: 39.249616, postalCode: "63352" },
    { lng: -93.95233, lat: 36.530992, postalCode: "65745" },
    { lng: -93.31297, lat: 37.168435, postalCode: "65807" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63198" },
    { lng: -90.21801, lat: 36.772841, postalCode: "63940" },
    { lng: -93.11823, lat: 40.367584, postalCode: "63560" },
    { lng: -90.21362, lat: 38.610701, postalCode: "63104" },
    { lng: -93.23552, lat: 36.600309, postalCode: "65672" },
    { lng: -93.70745, lat: 37.339631, postalCode: "65646" },
    { lng: -93.30305, lat: 37.913567, postalCode: "65668" },
    { lng: -92.74503, lat: 39.893011, postalCode: "63539" },
    { lng: -89.52687, lat: 37.21183, postalCode: "63780" },
    { lng: -90.94433, lat: 38.814432, postalCode: "63348" },
    { lng: -90.42152, lat: 37.068349, postalCode: "63944" },
    { lng: -90.21041, lat: 38.787849, postalCode: "63138" },
    { lng: -91.71817, lat: 40.415683, postalCode: "63445" },
    { lng: -90.33662, lat: 38.8016, postalCode: "63031" },
    { lng: -91.54342, lat: 37.464282, postalCode: "65501" },
    { lng: -94.50955, lat: 38.164781, postalCode: "64745" },
    { lng: -90.26154, lat: 38.73835, postalCode: "63136" },
    { lng: -94.51483, lat: 39.74226, postalCode: "64490" },
    { lng: -92.08435, lat: 38.125349, postalCode: "65443" },
    { lng: -93.00082, lat: 36.897807, postalCode: "65629" },
    { lng: -93.97143, lat: 40.036453, postalCode: "64647" },
    { lng: -92.96214, lat: 39.226389, postalCode: "65330" },
    { lng: -94.8358, lat: 39.94728, postalCode: "64485" },
    { lng: -91.91063, lat: 40.354422, postalCode: "63474" },
    { lng: -93.30007, lat: 39.78583, postalCode: "64659" },
    { lng: -89.54065, lat: 37.291432, postalCode: "63703" },
    { lng: -93.59877, lat: 37.793322, postalCode: "65674" },
    { lng: -91.391848, lat: 38.062095, postalCode: "65432" },
    { lng: -93.10129, lat: 37.630577, postalCode: "65622" },
    { lng: -91.82976, lat: 37.246525, postalCode: "65589" },
    { lng: -94.54614, lat: 39.474988, postalCode: "64492" },
    { lng: -89.80068, lat: 37.555405, postalCode: "63747" },
    { lng: -94.28956, lat: 36.571849, postalCode: "64856" },
    { lng: -90.74342, lat: 38.129238, postalCode: "63030" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64170" },
    { lng: -92.705545, lat: 37.859361, postalCode: "65602" },
    { lng: -92.30033, lat: 39.00234, postalCode: "65202" },
    { lng: -94.42691, lat: 36.734769, postalCode: "64843" },
    { lng: -94.6186, lat: 40.43702, postalCode: "64475" },
    { lng: -90.850785, lat: 36.66101, postalCode: "63931" },
    { lng: -90.20949, lat: 38.6628, postalCode: "63107" },
    { lng: -93.44334, lat: 37.802535, postalCode: "65650" },
    { lng: -92.93715, lat: 36.935499, postalCode: "65720" },
    { lng: -90.61878, lat: 38.575835, postalCode: "63040" },
    { lng: -90.89305, lat: 37.790928, postalCode: "63622" },
    { lng: -93.29923, lat: 37.206624, postalCode: "65806" },
    { lng: -92.36887, lat: 38.925204, postalCode: "65203" },
    { lng: -91.54631, lat: 39.793879, postalCode: "63461" },
    { lng: -90.52687, lat: 36.637085, postalCode: "63945" },
    { lng: -89.91359, lat: 37.101789, postalCode: "63730" },
    { lng: -91.19197, lat: 37.780711, postalCode: "65456" },
    { lng: -93.14236, lat: 38.203621, postalCode: "65326" },
    { lng: -91.8776, lat: 38.674735, postalCode: "65059" },
    { lng: -93.48502, lat: 38.059763, postalCode: "65735" },
    { lng: -93.781478, lat: 38.024133, postalCode: "64789" },
    { lng: -90.37687, lat: 38.645802, postalCode: "63124" },
    { lng: -90.81792, lat: 38.369435, postalCode: "63072" },
    { lng: -92.66935, lat: 38.130807, postalCode: "65065" },
    { lng: -91.49134, lat: 36.95653, postalCode: "65438" },
    { lng: -91.24469, lat: 38.558836, postalCode: "63068" },
    { lng: -90.85922, lat: 38.808884, postalCode: "63385" },
    { lng: -94.44024, lat: 40.402118, postalCode: "64499" },
    { lng: -90.70519, lat: 38.280328, postalCode: "63023" },
    { lng: -94.57311, lat: 39.103704, postalCode: "64106" },
    { lng: -91.29375, lat: 38.091816, postalCode: "65535" },
    { lng: -93.25234, lat: 40.542119, postalCode: "64672" },
    { lng: -90.24009, lat: 38.676851, postalCode: "63115" },
    { lng: -94.62241, lat: 39.174903, postalCode: "64150" },
    { lng: -94.09563, lat: 39.780045, postalCode: "64649" },
    { lng: -91.75234, lat: 37.767937, postalCode: "65540" },
    { lng: -90.13128, lat: 36.956662, postalCode: "63960" },
    { lng: -94.15285, lat: 40.390465, postalCode: "64467" },
    { lng: -89.31308, lat: 36.915335, postalCode: "63834" },
    { lng: -92.69655, lat: 38.543408, postalCode: "65050" },
    { lng: -94.71439, lat: 39.281602, postalCode: "64153" },
    { lng: -90.42335, lat: 38.757318, postalCode: "63044" },
    { lng: -93.71063, lat: 36.918018, postalCode: "65605" },
    { lng: -92.02352, lat: 38.287405, postalCode: "65001" },
    { lng: -90.25174, lat: 38.559302, postalCode: "63111" },
    { lng: -93.23128, lat: 39.647537, postalCode: "64681" },
    { lng: -92.48693, lat: 38.407056, postalCode: "65064" },
    { lng: -91.912383, lat: 40.114109, postalCode: "63477" },
    { lng: -93.02965, lat: 36.739739, postalCode: "65759" },
    { lng: -92.24825, lat: 39.742551, postalCode: "63437" },
    { lng: -95.63121, lat: 40.497797, postalCode: "64496" },
    { lng: -90.44667, lat: 38.024849, postalCode: "63087" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64185" },
    { lng: -91.14089, lat: 38.200244, postalCode: "63080" },
    { lng: -90.38829, lat: 37.20739, postalCode: "63934" },
    { lng: -94.85161, lat: 39.343225, postalCode: "64168" },
    { lng: -94.408998, lat: 39.282869, postalCode: "64144" },
    { lng: -93.04083, lat: 38.99158, postalCode: "65347" },
    { lng: -91.90722, lat: 38.575173, postalCode: "65016" },
    { lng: -91.26828, lat: 37.817055, postalCode: "65446" },
    { lng: -92.101845, lat: 37.995489, postalCode: "65495" },
    { lng: -92.4949, lat: 37.073473, postalCode: "65702" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65105" },
    { lng: -91.8983, lat: 39.0159, postalCode: "65231" },
    { lng: -90.2278, lat: 38.594901, postalCode: "63118" },
    { lng: -94.67399, lat: 40.351697, postalCode: "64479" },
    { lng: -93.68661, lat: 39.192552, postalCode: "64022" },
    { lng: -95.23256, lat: 40.148933, postalCode: "64470" },
    { lng: -94.43498, lat: 37.241939, postalCode: "64857" },
    { lng: -93.31817, lat: 37.85245, postalCode: "65724" },
    { lng: -89.88904, lat: 37.247942, postalCode: "63760" },
    { lng: -94.22601, lat: 39.340332, postalCode: "64024" },
    { lng: -93.5483, lat: 39.48743, postalCode: "64622" },
    { lng: -92.77286, lat: 36.79589, postalCode: "65701" },
    { lng: -92.72531, lat: 40.228235, postalCode: "63559" },
    { lng: -90.64229, lat: 38.053566, postalCode: "63626" },
    { lng: -90.89093, lat: 38.590155, postalCode: "63332" },
    { lng: -94.4966, lat: 39.051455, postalCode: "64129" },
    { lng: -91.74251, lat: 39.814354, postalCode: "63463" },
    { lng: -94.52788, lat: 36.898694, postalCode: "64858" },
    { lng: -92.57057, lat: 39.75496, postalCode: "63532" },
    { lng: -90.65623, lat: 38.038731, postalCode: "63674" },
    { lng: -93.22876, lat: 39.382255, postalCode: "64639" },
    { lng: -89.85836, lat: 37.437077, postalCode: "63766" },
    { lng: -90.25435, lat: 38.646201, postalCode: "63108" },
    { lng: -92.66205, lat: 36.681335, postalCode: "65762" },
    { lng: -90.159327, lat: 36.018996, postalCode: "63588" },
    { lng: -91.32983, lat: 39.03288, postalCode: "63333" },
    { lng: -94.76577, lat: 39.527667, postalCode: "64439" },
    { lng: -90.63284, lat: 37.614822, postalCode: "63650" },
    { lng: -94.66893, lat: 40.266289, postalCode: "64432" },
    { lng: -94.73379, lat: 40.264258, postalCode: "64434" },
    { lng: -90.367713, lat: 37.675442, postalCode: "63651" },
    { lng: -93.343673, lat: 37.25807, postalCode: "65890" },
    { lng: -92.43502, lat: 38.142363, postalCode: "65083" },
    { lng: -92.41023, lat: 39.580455, postalCode: "65260" },
    { lng: -94.34502, lat: 39.150754, postalCode: "64058" },
    { lng: -93.31817, lat: 37.212639, postalCode: "65802" },
    { lng: -91.1704, lat: 37.615344, postalCode: "65440" },
    { lng: -93.5895, lat: 38.97688, postalCode: "64020" },
    { lng: -89.84896, lat: 36.079378, postalCode: "63877" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63024" },
    { lng: -90.42316, lat: 38.337458, postalCode: "63012" },
    { lng: -93.32101, lat: 38.901528, postalCode: "65333" },
    { lng: -91.87408, lat: 36.719145, postalCode: "65775" },
    { lng: -92.10602, lat: 36.679277, postalCode: "65790" },
    { lng: -92.88033, lat: 38.067095, postalCode: "65787" },
    { lng: -94.40285, lat: 38.908657, postalCode: "64081" },
    { lng: -92.12685, lat: 37.737264, postalCode: "65473" },
    { lng: -89.82401, lat: 37.265867, postalCode: "63785" },
    { lng: -94.10161, lat: 36.799869, postalCode: "64842" },
    { lng: -90.04866, lat: 36.237128, postalCode: "63857" },
    { lng: -90.34637, lat: 37.97861, postalCode: "63036" },
    { lng: -89.779248, lat: 37.04613, postalCode: "63772" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63195" },
    { lng: -94.15484, lat: 40.527055, postalCode: "64458" },
    { lng: -90.46088, lat: 38.502854, postalCode: "63026" },
    { lng: -94.60411, lat: 39.093854, postalCode: "64102" },
    { lng: -93.792393, lat: 39.591141, postalCode: "64629" },
    { lng: -94.152072, lat: 36.828416, postalCode: "64853" },
    { lng: -94.5929, lat: 39.61222, postalCode: "64454" },
    { lng: -93.90647, lat: 36.749457, postalCode: "65623" },
    { lng: -91.48862, lat: 39.310099, postalCode: "63382" },
    { lng: -93.59815, lat: 37.270684, postalCode: "65604" },
    { lng: -92.26753, lat: 36.606892, postalCode: "65760" },
    { lng: -92.96, lat: 38.975799, postalCode: "65322" },
    { lng: -91.01577, lat: 39.239548, postalCode: "63344" },
    { lng: -93.58793, lat: 36.587559, postalCode: "65747" },
    { lng: -90.472495, lat: 38.194104, postalCode: "63047" },
    { lng: -92.94664, lat: 39.070941, postalCode: "65320" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64193" },
    { lng: -93.343673, lat: 37.25807, postalCode: "65814" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64188" },
    { lng: -91.51803, lat: 40.044294, postalCode: "63448" },
    { lng: -93.8359, lat: 39.049517, postalCode: "64071" },
    { lng: -92.349574, lat: 39.044719, postalCode: "65205" },
    { lng: -93.53629, lat: 37.218244, postalCode: "65612" },
    { lng: -90.18702, lat: 38.6352, postalCode: "63102" },
    { lng: -92.9428, lat: 37.968547, postalCode: "65786" },
    { lng: -91.9947, lat: 40.497304, postalCode: "63432" },
    { lng: -92.72414, lat: 40.496773, postalCode: "63551" },
    { lng: -94.59439, lat: 39.056505, postalCode: "64111" },
    { lng: -93.01608, lat: 36.660582, postalCode: "65680" },
    { lng: -94.85161, lat: 39.343225, postalCode: "64190" },
    { lng: -94.44019, lat: 37.149458, postalCode: "64835" },
    { lng: -94.15315, lat: 38.895469, postalCode: "64070" },
    { lng: -94.338133, lat: 37.849889, postalCode: "64765" },
    { lng: -94.4061, lat: 39.053255, postalCode: "64055" },
    { lng: -92.50741, lat: 37.302013, postalCode: "65667" },
    { lng: -94.331993, lat: 38.251879, postalCode: "64777" },
    { lng: -90.1887, lat: 37.146953, postalCode: "63750" },
    { lng: -89.67548, lat: 36.174029, postalCode: "63830" },
    { lng: -94.85161, lat: 39.343225, postalCode: "64195" },
    { lng: -91.96806, lat: 39.906312, postalCode: "63434" },
    { lng: -93.95502, lat: 37.103672, postalCode: "65756" },
    { lng: -94.44382, lat: 37.256327, postalCode: "64849" },
    { lng: -90.30186, lat: 38.679684, postalCode: "63133" },
    { lng: -94.26207, lat: 38.776782, postalCode: "64080" },
    { lng: -90.72121, lat: 38.786335, postalCode: "63366" },
    { lng: -94.5455, lat: 40.218842, postalCode: "64489" },
    { lng: -91.47293, lat: 38.659798, postalCode: "65041" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63196" },
    { lng: -91.67142, lat: 37.782433, postalCode: "65532" },
    { lng: -90.50687, lat: 38.808583, postalCode: "63301" },
    { lng: -93.80738, lat: 37.101742, postalCode: "65712" },
    { lng: -93.46794, lat: 39.093236, postalCode: "65321" },
    { lng: -93.46715, lat: 36.561455, postalCode: "65681" },
    { lng: -92.11831, lat: 38.632055, postalCode: "65043" },
    { lng: -94.14693, lat: 39.868966, postalCode: "64689" },
    { lng: -91.64049, lat: 38.607257, postalCode: "65061" },
    { lng: -92.25872, lat: 38.259655, postalCode: "65075" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64142" },
    { lng: -93.5614, lat: 37.707827, postalCode: "65640" },
    { lng: -91.085533, lat: 38.274398, postalCode: "63079" },
    { lng: -90.89199, lat: 38.952724, postalCode: "63362" },
    { lng: -90.25221, lat: 36.58479, postalCode: "63961" },
    { lng: -93.16513, lat: 37.751804, postalCode: "65685" },
    { lng: -90.41689, lat: 36.759357, postalCode: "63901" },
    { lng: -92.89942, lat: 36.734544, postalCode: "65614" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64198" },
    { lng: -91.3682, lat: 38.898823, postalCode: "63350" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64184" },
    { lng: -94.18355, lat: 39.15207, postalCode: "64088" },
    { lng: -93.28199, lat: 36.531361, postalCode: "65739" },
    { lng: -89.99144, lat: 36.124101, postalCode: "63849" },
    { lng: -92.58195, lat: 36.733415, postalCode: "65715" },
    { lng: -90.61694, lat: 37.520078, postalCode: "63621" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64189" },
    { lng: -91.07518, lat: 39.449012, postalCode: "63353" },
    { lng: -90.32178, lat: 38.73965, postalCode: "63140" },
    { lng: -94.87498, lat: 40.341716, postalCode: "64468" },
    { lng: -93.67718, lat: 39.671985, postalCode: "64656" },
    { lng: -92.9168, lat: 38.675515, postalCode: "65354" },
    { lng: -92.76311, lat: 37.412437, postalCode: "65713" },
    { lng: -94.85573, lat: 40.115496, postalCode: "64427" },
    { lng: -91.83904, lat: 37.693916, postalCode: "65462" },
    { lng: -92.17323, lat: 37.545213, postalCode: "65552" },
    { lng: -91.96543, lat: 37.624096, postalCode: "65436" },
    { lng: -89.87346, lat: 36.27471, postalCode: "63827" },
    { lng: -91.92629, lat: 38.341076, postalCode: "65035" },
    { lng: -94.02134, lat: 36.956471, postalCode: "65723" },
    { lng: -94.55673, lat: 37.825966, postalCode: "64741" },
    { lng: -94.46556, lat: 39.107845, postalCode: "64053" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63197" },
    { lng: -92.09833, lat: 37.990962, postalCode: "65459" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63045" },
    { lng: -90.03097, lat: 36.442124, postalCode: "63847" },
    { lng: -90.30599, lat: 37.54834, postalCode: "63645" },
    { lng: -93.343673, lat: 37.25807, postalCode: "65808" },
    { lng: -93.64371, lat: 36.557301, postalCode: "65658" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63156" },
    { lng: -90.2895, lat: 38.524066, postalCode: "63125" },
    { lng: -93.00865, lat: 39.869353, postalCode: "64677" },
    { lng: -93.241839, lat: 36.616515, postalCode: "65726" },
    { lng: -93.68518, lat: 38.585719, postalCode: "64761" },
    { lng: -94.49832, lat: 38.928206, postalCode: "64134" },
    { lng: -94.56785, lat: 39.827889, postalCode: "64430" },
    { lng: -93.38237, lat: 37.939779, postalCode: "65779" },
    { lng: -91.97203, lat: 37.241589, postalCode: "65564" },
    { lng: -92.53414, lat: 36.757025, postalCode: "65773" },
    { lng: -95.08755, lat: 40.17606, postalCode: "64466" },
    { lng: -94.51296, lat: 39.281486, postalCode: "64156" },
    { lng: -94.33216, lat: 40.247423, postalCode: "64402" },
    { lng: -93.86635, lat: 38.763144, postalCode: "64019" },
    { lng: -91.34909, lat: 39.151911, postalCode: "63359" },
    { lng: -91.20688, lat: 38.126316, postalCode: "65441" },
    { lng: -90.66508, lat: 38.578703, postalCode: "63038" },
    { lng: -92.32949, lat: 36.695557, postalCode: "65784" },
    { lng: -90.30258, lat: 38.74785, postalCode: "63135" },
    { lng: -90.845047, lat: 37.505462, postalCode: "63646" },
    { lng: -94.07822, lat: 39.378901, postalCode: "64084" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64191" },
    { lng: -90.37005, lat: 38.7785, postalCode: "63042" },
    { lng: -90.52696, lat: 38.473921, postalCode: "63049" },
    { lng: -92.55356, lat: 39.442539, postalCode: "65259" },
    { lng: -94.44115, lat: 39.108704, postalCode: "64054" },
    { lng: -93.77349, lat: 40.448528, postalCode: "64632" },
    { lng: -91.02348, lat: 38.535499, postalCode: "63090" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63022" },
    { lng: -89.81419, lat: 37.522361, postalCode: "63743" },
    { lng: -94.62462, lat: 39.335365, postalCode: "64164" },
    { lng: -94.36474, lat: 38.98861, postalCode: "64064" },
    { lng: -93.57069, lat: 37.537425, postalCode: "65601" },
    { lng: -94.68236, lat: 40.162731, postalCode: "64457" },
    { lng: -92.25329, lat: 38.568287, postalCode: "65109" },
    { lng: -94.21671, lat: 40.130633, postalCode: "64657" },
    { lng: -93.98011, lat: 36.690297, postalCode: "65647" },
    { lng: -94.50225, lat: 37.692776, postalCode: "64728" },
    { lng: -94.40868, lat: 38.959277, postalCode: "64139" },
    { lng: -93.25182, lat: 38.825456, postalCode: "65334" },
    { lng: -90.15155, lat: 37.91259, postalCode: "63670" },
    { lng: -94.60271, lat: 36.530266, postalCode: "64863" },
    { lng: -93.99079, lat: 40.481133, postalCode: "64442" },
    { lng: -94.22863, lat: 38.668901, postalCode: "64743" },
    { lng: -91.30687, lat: 36.58466, postalCode: "65690" },
    { lng: -91.3246, lat: 38.412764, postalCode: "63037" },
    { lng: -93.21227, lat: 36.773538, postalCode: "65771" },
    { lng: -90.75875, lat: 39.069965, postalCode: "63347" },
    { lng: -89.44651, lat: 37.157131, postalCode: "63742" },
    { lng: -92.20242, lat: 40.011886, postalCode: "63460" },
    { lng: -92.22005, lat: 39.460422, postalCode: "65263" },
    { lng: -90.96827, lat: 37.538148, postalCode: "63625" },
    { lng: -92.92916, lat: 39.668998, postalCode: "64658" },
    { lng: -91.426693, lat: 37.858464, postalCode: "65586" },
    { lng: -92.438629, lat: 38.223649, postalCode: "65031" },
    { lng: -91.79134, lat: 37.260073, postalCode: "65468" },
    { lng: -92.102153, lat: 38.903318, postalCode: "65217" },
    { lng: -93.33085, lat: 38.503623, postalCode: "65335" },
    { lng: -94.35231, lat: 39.91125, postalCode: "64469" },
    { lng: -92.49693, lat: 38.084663, postalCode: "65017" },
    { lng: -90.83398, lat: 38.499489, postalCode: "63039" },
    { lng: -92.01104, lat: 40.14036, postalCode: "63446" },
    { lng: -92.74979, lat: 39.728897, postalCode: "63558" },
    { lng: -92.09857, lat: 37.132577, postalCode: "65689" },
    { lng: -92.09651, lat: 37.465392, postalCode: "65570" },
    { lng: -95.33869, lat: 40.135643, postalCode: "64437" },
    { lng: -94.05116, lat: 37.011487, postalCode: "64873" },
    { lng: -92.09108, lat: 37.508599, postalCode: "65557" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63150" },
    { lng: -94.15805, lat: 36.871373, postalCode: "64866" },
    { lng: -93.23617, lat: 37.586048, postalCode: "65663" },
    { lng: -92.2971, lat: 37.47449, postalCode: "65543" },
    { lng: -94.5645, lat: 38.081874, postalCode: "64752" },
    { lng: -91.75007, lat: 39.168227, postalCode: "65232" },
    { lng: -90.2941, lat: 38.586052, postalCode: "63109" },
    { lng: -94.53424, lat: 37.906353, postalCode: "64778" },
    { lng: -89.22208, lat: 36.911648, postalCode: "63882" },
    { lng: -90.652057, lat: 38.280943, postalCode: "63066" },
    { lng: -93.8395, lat: 40.154895, postalCode: "64642" },
    { lng: -94.78814, lat: 39.356596, postalCode: "64079" },
    { lng: -91.2336, lat: 38.995145, postalCode: "63381" },
    { lng: -94.2987, lat: 39.016612, postalCode: "64015" },
    { lng: -92.01979, lat: 40.361121, postalCode: "63543" },
    { lng: -91.62432, lat: 38.31417, postalCode: "65014" },
    { lng: -90.27247, lat: 37.124019, postalCode: "63951" },
    { lng: -92.14536, lat: 39.650093, postalCode: "63450" },
    { lng: -90.63155, lat: 38.646981, postalCode: "63005" },
    { lng: -94.07705, lat: 38.980527, postalCode: "64011" },
    { lng: -94.85162, lat: 40.192991, postalCode: "64423" },
    { lng: -93.62076, lat: 39.616309, postalCode: "64638" },
    { lng: -94.01643, lat: 37.621338, postalCode: "64756" },
    { lng: -91.95135, lat: 38.483274, postalCode: "65054" },
    { lng: -93.43738, lat: 37.015404, postalCode: "65631" },
    { lng: -89.65428, lat: 37.413716, postalCode: "63755" },
    { lng: -93.84181, lat: 37.220289, postalCode: "65707" },
    { lng: -91.58325, lat: 40.396289, postalCode: "63472" },
    { lng: -92.68007, lat: 39.952779, postalCode: "63538" },
    { lng: -94.600647, lat: 39.781173, postalCode: "64930" },
    { lng: -90.872987, lat: 38.581299, postalCode: "63302" },
    { lng: -91.68647, lat: 39.430693, postalCode: "63462" },
    { lng: -94.90957, lat: 39.585185, postalCode: "64440" },
    { lng: -91.80921, lat: 38.78615, postalCode: "65077" },
    { lng: -93.09683, lat: 39.57756, postalCode: "64660" },
    { lng: -94.69991, lat: 39.216635, postalCode: "64152" },
    { lng: -93.988865, lat: 39.96054, postalCode: "64654" },
    { lng: -89.68851, lat: 36.436042, postalCode: "63873" },
    { lng: -92.442341, lat: 36.651607, postalCode: "65741" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64194" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65103" },
    { lng: -90.73892, lat: 38.482353, postalCode: "63069" },
    { lng: -89.36142, lat: 36.74432, postalCode: "63845" },
    { lng: -91.7742, lat: 38.10675, postalCode: "65580" },
    { lng: -91.270502, lat: 39.650045, postalCode: "63467" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65108" },
    { lng: -93.37889, lat: 39.180956, postalCode: "65339" },
    { lng: -89.89562, lat: 36.449337, postalCode: "63848" },
    { lng: -92.89513, lat: 36.614975, postalCode: "65744" },
    { lng: -92.610941, lat: 38.674914, postalCode: "65042" },
    { lng: -93.53989, lat: 36.901929, postalCode: "65633" },
    { lng: -93.11417, lat: 38.55338, postalCode: "65345" },
    { lng: -89.95595, lat: 37.082526, postalCode: "63738" },
    { lng: -93.794818, lat: 40.30922, postalCode: "64665" },
    { lng: -94.4591, lat: 39.013905, postalCode: "64133" },
    { lng: -90.48271, lat: 36.944333, postalCode: "63967" },
    { lng: -89.811616, lat: 37.734776, postalCode: "63746" },
    { lng: -89.7817, lat: 36.822129, postalCode: "63850" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63180" },
    { lng: -93.50573, lat: 36.725025, postalCode: "65624" },
    { lng: -93.2519, lat: 37.161299, postalCode: "65804" },
    { lng: -91.99901, lat: 39.199784, postalCode: "65285" },
    { lng: -93.86963, lat: 39.176165, postalCode: "64067" },
    { lng: -92.17819, lat: 36.753498, postalCode: "65637" },
    { lng: -90.83766, lat: 37.478054, postalCode: "63654" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64172" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63177" },
    { lng: -89.87038, lat: 36.058612, postalCode: "63853" },
    { lng: -92.01662, lat: 39.677131, postalCode: "63468" },
    { lng: -92.19521, lat: 39.922732, postalCode: "63451" },
    { lng: -91.76344, lat: 38.886456, postalCode: "63388" },
    { lng: -91.13438, lat: 37.716972, postalCode: "65566" },
    { lng: -94.342802, lat: 36.632573, postalCode: "64868" },
    { lng: -90.25527, lat: 38.051824, postalCode: "63627" },
    { lng: -94.4603, lat: 39.571712, postalCode: "64477" },
    { lng: -89.6911, lat: 36.990215, postalCode: "63784" },
    { lng: -91.25495, lat: 37.919808, postalCode: "65565" },
    { lng: -94.84286, lat: 39.812344, postalCode: "64505" },
    { lng: -90.2507, lat: 37.19068, postalCode: "63947" },
    { lng: -92.6855, lat: 38.058473, postalCode: "65052" },
    { lng: -93.16252, lat: 37.391275, postalCode: "65648" },
    { lng: -92.19165, lat: 37.820367, postalCode: "65583" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63001" },
    { lng: -92.53612, lat: 39.286571, postalCode: "65257" },
    { lng: -90.77513, lat: 38.262811, postalCode: "63041" },
    { lng: -89.68706, lat: 36.754415, postalCode: "63828" },
    { lng: -90.34964, lat: 38.619152, postalCode: "63144" },
    { lng: -91.88978, lat: 36.857128, postalCode: "65789" },
    { lng: -94.48079, lat: 37.260443, postalCode: "64855" },
    { lng: -94.345591, lat: 37.501451, postalCode: "64766" },
    { lng: -90.996216, lat: 38.605967, postalCode: "63342" },
    { lng: -89.52535, lat: 37.00384, postalCode: "63824" },
    { lng: -90.84698, lat: 38.535934, postalCode: "63055" },
    { lng: -90.40862, lat: 38.279016, postalCode: "63070" },
    { lng: -92.48206, lat: 38.77066, postalCode: "65046" },
    { lng: -91.81695, lat: 38.477953, postalCode: "65051" },
    { lng: -91.986785, lat: 36.629135, postalCode: "65776" },
    { lng: -90.21415, lat: 38.631451, postalCode: "63103" },
    { lng: -94.34126, lat: 38.641509, postalCode: "64701" },
    { lng: -94.87354, lat: 39.707694, postalCode: "64504" },
    { lng: -91.1603, lat: 38.415995, postalCode: "63013" },
    { lng: -89.856609, lat: 37.73473, postalCode: "63375" },
    { lng: -94.32392, lat: 38.92811, postalCode: "64086" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64141" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64199" },
    { lng: -90.41404, lat: 37.779206, postalCode: "63640" },
    { lng: -94.59415, lat: 36.833365, postalCode: "64865" },
    { lng: -94.857364, lat: 39.676333, postalCode: "64508" },
    { lng: -94.17063, lat: 39.443609, postalCode: "64062" },
    { lng: -90.883751, lat: 39.25597, postalCode: "63371" },
    { lng: -93.17676, lat: 39.785731, postalCode: "64651" },
    { lng: -94.9786, lat: 40.041181, postalCode: "64449" },
    { lng: -94.34213, lat: 38.843576, postalCode: "64034" },
    { lng: -93.21541, lat: 37.021086, postalCode: "65721" },
    { lng: -93.16074, lat: 39.958661, postalCode: "64674" },
    { lng: -93.62914, lat: 38.459369, postalCode: "65323" },
    { lng: -94.10004, lat: 38.005777, postalCode: "64783" },
    { lng: -90.40339, lat: 38.385265, postalCode: "63052" },
    { lng: -93.7365, lat: 38.250989, postalCode: "64740" },
    { lng: -92.03371, lat: 39.820466, postalCode: "63469" },
    { lng: -89.32516, lat: 36.824596, postalCode: "63820" },
    { lng: -90.37921, lat: 38.552303, postalCode: "63126" },
    { lng: -92.867804, lat: 38.196429, postalCode: "65038" },
    { lng: -94.338957, lat: 36.902332, postalCode: "64864" },
    { lng: -91.9766, lat: 38.634516, postalCode: "65080" },
    { lng: -92.25917, lat: 36.919476, postalCode: "65768" },
    { lng: -94.9968, lat: 40.527919, postalCode: "64431" },
    { lng: -92.64152, lat: 40.569026, postalCode: "63535" },
    { lng: -89.70949, lat: 37.593256, postalCode: "63770" },
    { lng: -94.44972, lat: 36.658583, postalCode: "64831" },
    { lng: -94.47948, lat: 39.898708, postalCode: "64422" },
    { lng: -93.377455, lat: 39.315544, postalCode: "64687" },
    { lng: -94.57635, lat: 37.499513, postalCode: "64769" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63179" },
    { lng: -92.79811, lat: 38.642614, postalCode: "65081" },
    { lng: -93.37642, lat: 39.808311, postalCode: "64688" },
    { lng: -90.21905, lat: 38.692861, postalCode: "63147" },
    { lng: -90.63595, lat: 38.352825, postalCode: "63016" },
    { lng: -91.02078, lat: 39.184054, postalCode: "63387" },
    { lng: -92.46408, lat: 39.911723, postalCode: "63530" },
    { lng: -90.82317, lat: 39.257008, postalCode: "63330" },
    { lng: -93.281672, lat: 38.724577, postalCode: "65302" },
    { lng: -92.88684, lat: 39.963355, postalCode: "63557" },
    { lng: -91.7066, lat: 40.143679, postalCode: "63457" },
    { lng: -91.71697, lat: 36.996408, postalCode: "65548" },
    { lng: -89.93679, lat: 36.91732, postalCode: "63825" },
    { lng: -90.18886, lat: 37.416023, postalCode: "63655" },
    { lng: -90.407013, lat: 36.712451, postalCode: "63962" },
    { lng: -94.60288, lat: 39.981974, postalCode: "64494" },
    { lng: -89.67823, lat: 37.076536, postalCode: "63771" },
    { lng: -93.62758, lat: 37.193688, postalCode: "65664" },
    { lng: -92.99396, lat: 39.370265, postalCode: "65246" },
    { lng: -90.00544, lat: 36.37451, postalCode: "63852" },
    { lng: -90.32636, lat: 38.628402, postalCode: "63117" },
    { lng: -89.80605, lat: 36.349977, postalCode: "63879" },
    { lng: -93.61158, lat: 37.618579, postalCode: "65649" },
    { lng: -89.549, lat: 37.189843, postalCode: "63758" },
    { lng: -93.61839, lat: 37.007586, postalCode: "65705" },
    { lng: -90.88421, lat: 38.46292, postalCode: "63089" },
    { lng: -93.4275, lat: 38.780854, postalCode: "65337" },
    { lng: -90.57059, lat: 38.405287, postalCode: "63051" },
    { lng: -94.46712, lat: 38.872285, postalCode: "64149" },
    { lng: -93.842468, lat: 37.736871, postalCode: "65607" },
    { lng: -93.54803, lat: 39.790132, postalCode: "64601" },
    { lng: -90.09694, lat: 36.509916, postalCode: "63933" },
    { lng: -94.57314, lat: 39.14912, postalCode: "64116" },
    { lng: -91.33928, lat: 38.654011, postalCode: "63014" },
    { lng: -91.74108, lat: 38.279787, postalCode: "65013" },
    { lng: -90.53903, lat: 38.762715, postalCode: "63303" },
    { lng: -93.33102, lat: 40.385432, postalCode: "64667" },
    { lng: -93.82877, lat: 37.343226, postalCode: "65752" },
    { lng: -90.53117, lat: 38.576253, postalCode: "63021" },
    { lng: -91.69953, lat: 39.952258, postalCode: "63438" },
    { lng: -89.84072, lat: 36.180226, postalCode: "63826" },
    { lng: -93.73225, lat: 38.137769, postalCode: "64763" },
    { lng: -92.52631, lat: 40.335091, postalCode: "63546" },
    { lng: -90.83168, lat: 38.137345, postalCode: "63071" },
    { lng: -91.70972, lat: 36.801103, postalCode: "65788" },
    { lng: -93.63663, lat: 39.116219, postalCode: "64021" },
    { lng: -91.49431, lat: 37.85017, postalCode: "65449" },
    { lng: -94.41181, lat: 37.081103, postalCode: "64841" },
    { lng: -90.44689, lat: 38.688585, postalCode: "63146" },
    { lng: -90.10392, lat: 37.115431, postalCode: "63787" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64180" },
    { lng: -89.525934, lat: 37.005572, postalCode: "63804" },
    { lng: -92.102153, lat: 38.903318, postalCode: "65218" },
    { lng: -91.76321, lat: 37.622778, postalCode: "65541" },
    { lng: -90.68117, lat: 36.647754, postalCode: "63939" },
    { lng: -94.80941, lat: 39.787394, postalCode: "64506" },
    { lng: -90.51428, lat: 37.73411, postalCode: "63637" },
    { lng: -89.811616, lat: 37.734776, postalCode: "63776" },
    { lng: -94.5856, lat: 37.292467, postalCode: "64832" },
    { lng: -94.293888, lat: 39.314899, postalCode: "64073" },
    { lng: -90.4077, lat: 38.187767, postalCode: "63028" },
    { lng: -93.52526, lat: 37.042293, postalCode: "65610" },
    { lng: -94.49898, lat: 40.057066, postalCode: "64463" },
    { lng: -91.32296, lat: 39.484069, postalCode: "63441" },
    { lng: -93.47407, lat: 37.124302, postalCode: "65738" },
    { lng: -91.4163, lat: 38.092097, postalCode: "65453" },
    { lng: -92.610941, lat: 38.674914, postalCode: "65055" },
    { lng: -94.08876, lat: 39.900508, postalCode: "64620" },
    { lng: -92.58794, lat: 37.465808, postalCode: "65662" },
    { lng: -94.335453, lat: 37.206219, postalCode: "64803" },
    { lng: -94.00043, lat: 37.172786, postalCode: "64848" },
    { lng: -92.99077, lat: 38.708894, postalCode: "65348" },
    { lng: -93.216187, lat: 36.617892, postalCode: "65673" },
    { lng: -90.55209, lat: 38.601403, postalCode: "63011" },
    { lng: -89.92735, lat: 37.527341, postalCode: "63781" },
    { lng: -92.52188, lat: 38.884926, postalCode: "65287" },
    { lng: -89.6073, lat: 36.521724, postalCode: "63866" },
    { lng: -94.84243, lat: 40.447373, postalCode: "64476" },
    { lng: -94.0858, lat: 39.093759, postalCode: "64074" },
    { lng: -93.67676, lat: 38.039239, postalCode: "64776" },
    { lng: -94.830159, lat: 39.283928, postalCode: "64028" },
    { lng: -91.017274, lat: 38.527729, postalCode: "63073" },
    { lng: -91.024337, lat: 37.327153, postalCode: "63666" },
    { lng: -91.1528, lat: 36.918139, postalCode: "63941" },
    { lng: -92.67262, lat: 38.192972, postalCode: "65049" },
    { lng: -94.41447, lat: 39.09888, postalCode: "64050" },
    { lng: -90.55426, lat: 38.120303, postalCode: "63020" },
    { lng: -94.404415, lat: 39.892645, postalCode: "64447" },
    { lng: -92.90929, lat: 37.330958, postalCode: "65706" },
    { lng: -94.31032, lat: 37.326648, postalCode: "64755" },
    { lng: -94.57953, lat: 38.671517, postalCode: "64734" },
    { lng: -94.56643, lat: 39.065954, postalCode: "64109" },
    { lng: -93.18783, lat: 39.10518, postalCode: "65340" },
    { lng: -90.32525, lat: 38.549452, postalCode: "63123" },
    { lng: -90.26609, lat: 36.512944, postalCode: "63938" },
    { lng: -91.81864, lat: 39.369471, postalCode: "65282" },
    { lng: -90.29776, lat: 36.041645, postalCode: "63829" },
    { lng: -89.81679, lat: 36.053078, postalCode: "63839" },
    { lng: -91.14919, lat: 38.96624, postalCode: "63349" },
    { lng: -92.72167, lat: 38.361276, postalCode: "65011" },
    { lng: -92.39311, lat: 38.630396, postalCode: "65023" },
    { lng: -91.337506, lat: 37.153294, postalCode: "65546" },
    { lng: -91.85812, lat: 39.788052, postalCode: "63439" },
    { lng: -94.53968, lat: 39.089704, postalCode: "64127" },
    { lng: -94.69403, lat: 39.850535, postalCode: "64436" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63199" },
    { lng: -94.26399, lat: 37.665823, postalCode: "64784" },
    { lng: -91.77368, lat: 40.246729, postalCode: "63473" },
    { lng: -90.62045, lat: 38.778579, postalCode: "63376" },
    { lng: -92.06553, lat: 39.972337, postalCode: "63464" },
    { lng: -92.7831, lat: 37.84762, postalCode: "65463" },
    { lng: -90.08675, lat: 37.332487, postalCode: "63751" },
    { lng: -89.509405, lat: 37.350624, postalCode: "63702" },
    { lng: -91.8584, lat: 39.677082, postalCode: "63443" },
    { lng: -94.12545, lat: 37.086771, postalCode: "64862" },
    { lng: -89.6032, lat: 37.584014, postalCode: "63732" },
    { lng: -92.36456, lat: 39.278949, postalCode: "65243" },
    { lng: -92.00546, lat: 38.364291, postalCode: "65048" },
    { lng: -93.91742, lat: 39.575445, postalCode: "64637" },
    { lng: -94.71818, lat: 40.061613, postalCode: "64480" },
    { lng: -94.52503, lat: 38.883548, postalCode: "64030" },
    { lng: -91.91627, lat: 37.191845, postalCode: "65464" },
    { lng: -90.32348, lat: 38.663951, postalCode: "63130" },
    { lng: -92.55728, lat: 40.410297, postalCode: "63561" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64121" },
    { lng: -94.5365, lat: 39.066754, postalCode: "64128" },
    { lng: -90.875127, lat: 38.716287, postalCode: "63365" },
    { lng: -90.98649, lat: 38.991916, postalCode: "63379" },
    { lng: -94.57545, lat: 39.317974, postalCode: "64165" },
    { lng: -94.12773, lat: 37.943914, postalCode: "64750" },
    { lng: -94.89833, lat: 39.445387, postalCode: "64098" },
    { lng: -94.47256, lat: 38.969806, postalCode: "64138" },
    { lng: -94.805673, lat: 39.228875, postalCode: "64092" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63160" },
    { lng: -92.84612, lat: 39.235444, postalCode: "65254" },
    { lng: -90.408223, lat: 38.341918, postalCode: "63057" },
    { lng: -92.102153, lat: 38.903318, postalCode: "65211" },
    { lng: -89.83489, lat: 36.611902, postalCode: "63870" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63151" },
    { lng: -91.00798, lat: 37.210461, postalCode: "63638" },
    { lng: -89.69768, lat: 37.24237, postalCode: "63745" },
    { lng: -91.5367, lat: 39.501697, postalCode: "63436" },
    { lng: -93.49803, lat: 37.914579, postalCode: "65774" },
    { lng: -95.18778, lat: 39.982831, postalCode: "64451" },
    { lng: -93.98973, lat: 38.277754, postalCode: "64770" },
    { lng: -93.40072, lat: 37.616822, postalCode: "65613" },
    { lng: -90.536784, lat: 38.750949, postalCode: "63338" },
    { lng: -94.47794, lat: 39.230259, postalCode: "64158" },
    { lng: -94.387956, lat: 39.080369, postalCode: "64051" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63166" },
    { lng: -94.5237, lat: 38.953942, postalCode: "64192" },
    { lng: -91.91078, lat: 40.109412, postalCode: "63447" },
    { lng: -94.392885, lat: 38.966641, postalCode: "64065" },
    { lng: -94.59641, lat: 38.966056, postalCode: "64114" },
    { lng: -94.55686, lat: 38.266148, postalCode: "64722" },
    { lng: -92.24395, lat: 38.784123, postalCode: "65010" },
    { lng: -90.3902, lat: 38.261863, postalCode: "63048" },
    { lng: -89.76337, lat: 37.509022, postalCode: "63769" },
    { lng: -90.32161, lat: 38.469953, postalCode: "63129" },
    { lng: -93.30696, lat: 36.923067, postalCode: "65669" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64944" },
    { lng: -93.10971, lat: 37.281619, postalCode: "65757" },
    { lng: -93.80721, lat: 37.981824, postalCode: "64781" },
    { lng: -90.29174, lat: 38.610901, postalCode: "63139" },
    { lng: -90.64526, lat: 37.624572, postalCode: "63663" },
    { lng: -90.165758, lat: 36.313319, postalCode: "63875" },
    { lng: -94.54555, lat: 38.849841, postalCode: "64147" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64148" },
    { lng: -94.05456, lat: 36.760347, postalCode: "64874" },
    { lng: -92.166, lat: 38.280617, postalCode: "65058" },
    { lng: -92.25803, lat: 36.545211, postalCode: "65766" },
    { lng: -92.43342, lat: 36.606281, postalCode: "65655" },
    { lng: -92.43221, lat: 38.217129, postalCode: "65082" },
    { lng: -92.33043, lat: 38.937608, postalCode: "65212" },
    { lng: -93.34371, lat: 36.87478, postalCode: "65728" },
    { lng: -90.25992, lat: 38.691901, postalCode: "63120" },
    { lng: -92.78857, lat: 37.157055, postalCode: "65746" },
    { lng: -89.69113, lat: 36.845481, postalCode: "63868" },
    { lng: -90.0292, lat: 37.101911, postalCode: "63782" },
    { lng: -92.75899, lat: 38.156071, postalCode: "65079" },
    { lng: -90.37463, lat: 38.675784, postalCode: "63132" },
    { lng: -94.4705, lat: 39.283535, postalCode: "64157" },
    { lng: -92.58341, lat: 40.183335, postalCode: "63501" },
    { lng: -90.49157, lat: 38.553253, postalCode: "63088" },
    { lng: -92.26091, lat: 37.95348, postalCode: "65452" },
    { lng: -90.29609, lat: 38.706301, postalCode: "63121" },
    { lng: -89.97623, lat: 36.450404, postalCode: "63837" },
    { lng: -92.83345, lat: 37.494752, postalCode: "65632" },
    { lng: -92.38351, lat: 36.755168, postalCode: "65618" },
    { lng: -93.91488, lat: 36.91816, postalCode: "65708" },
    { lng: -93.343673, lat: 37.25807, postalCode: "65801" },
    { lng: -90.00768, lat: 36.662399, postalCode: "63822" },
    { lng: -93.3395, lat: 39.470242, postalCode: "64623" },
    { lng: -94.63021, lat: 39.215652, postalCode: "64151" },
    { lng: -94.408998, lat: 39.282869, postalCode: "64087" },
    { lng: -90.08539, lat: 36.048254, postalCode: "63855" },
    { lng: -91.69826, lat: 38.751905, postalCode: "65067" },
    { lng: -94.13971, lat: 39.22471, postalCode: "64077" },
    { lng: -94.35087, lat: 39.069165, postalCode: "64057" },
    { lng: -93.96495, lat: 39.120155, postalCode: "64097" },
    { lng: -93.80694, lat: 39.977935, postalCode: "64648" },
    { lng: -94.59537, lat: 39.037605, postalCode: "64112" },
    { lng: -90.36109, lat: 38.702651, postalCode: "63114" },
    { lng: -91.56266, lat: 39.081392, postalCode: "63384" },
    { lng: -91.08017, lat: 39.110908, postalCode: "63377" },
    { lng: -94.62989, lat: 39.493351, postalCode: "64444" },
    { lng: -94.57248, lat: 39.213082, postalCode: "64118" },
    { lng: -92.18031, lat: 40.469438, postalCode: "63555" },
    { lng: -92.81139, lat: 38.782638, postalCode: "65237" },
    { lng: -92.04337, lat: 37.68574, postalCode: "65461" },
    { lng: -94.35764, lat: 38.493639, postalCode: "64725" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63164" },
    { lng: -90.20636, lat: 38.644451, postalCode: "63106" },
    { lng: -92.80488, lat: 39.461414, postalCode: "65281" },
    { lng: -94.34512, lat: 38.40388, postalCode: "64720" },
    { lng: -93.30942, lat: 36.850549, postalCode: "65754" },
    { lng: -93.23231, lat: 38.702964, postalCode: "65301" },
    { lng: -93.86589, lat: 37.557203, postalCode: "65603" },
    { lng: -94.49948, lat: 39.092304, postalCode: "64126" },
    { lng: -92.1474, lat: 40.453333, postalCode: "63442" },
    { lng: -93.53825, lat: 39.214193, postalCode: "64096" },
    { lng: -94.32317, lat: 38.259528, postalCode: "64730" },
    { lng: -94.24731, lat: 36.913607, postalCode: "64844" },
    { lng: -90.40024, lat: 38.53987, postalCode: "63127" },
    { lng: -90.031801, lat: 36.267961, postalCode: "63859" },
    { lng: -89.639595, lat: 37.500656, postalCode: "63779" },
    { lng: -91.30515, lat: 36.996441, postalCode: "65588" },
    { lng: -90.24397, lat: 38.656701, postalCode: "63113" },
    { lng: -92.46675, lat: 38.485815, postalCode: "65074" },
    { lng: -92.9641, lat: 40.031642, postalCode: "63566" },
    { lng: -92.162456, lat: 37.298292, postalCode: "65684" },
    { lng: -93.45736, lat: 39.931666, postalCode: "64635" },
    { lng: -89.5659, lat: 37.32564, postalCode: "63701" },
    { lng: -94.53158, lat: 39.165253, postalCode: "64117" },
    { lng: -94.72215, lat: 39.627397, postalCode: "64401" },
    { lng: -90.98902, lat: 38.336093, postalCode: "63077" },
    { lng: -93.20442, lat: 39.506878, postalCode: "65286" },
    { lng: -92.70579, lat: 39.29161, postalCode: "65230" },
    { lng: -93.61143, lat: 40.07823, postalCode: "64683" },
    { lng: -91.94655, lat: 38.175146, postalCode: "65582" },
    { lng: -90.8615, lat: 36.624115, postalCode: "63935" },
    { lng: -93.53531, lat: 39.10223, postalCode: "64001" },
    { lng: -90.32888, lat: 38.645484, postalCode: "63105" },
    { lng: -92.30285, lat: 40.145396, postalCode: "63547" },
    { lng: -90.75026, lat: 36.936583, postalCode: "63937" },
    { lng: -92.05671, lat: 37.829068, postalCode: "65457" },
    { lng: -89.87601, lat: 37.716811, postalCode: "63775" },
    { lng: -94.59666, lat: 38.896407, postalCode: "64145" },
    { lng: -94.14484, lat: 36.712525, postalCode: "64861" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63178" },
    { lng: -93.85105, lat: 36.681589, postalCode: "65625" },
    { lng: -95.06768, lat: 40.44627, postalCode: "64428" },
    { lng: -91.03468, lat: 38.797388, postalCode: "63390" },
    { lng: -93.95373, lat: 38.98915, postalCode: "64076" },
    { lng: -94.13247, lat: 39.134657, postalCode: "64066" },
    { lng: -92.58331, lat: 38.14008, postalCode: "65047" },
    { lng: -93.16335, lat: 36.713014, postalCode: "65740" },
    { lng: -94.4669, lat: 39.164953, postalCode: "64161" },
    { lng: -94.423294, lat: 40.478118, postalCode: "64420" },
    { lng: -94.30633, lat: 39.535467, postalCode: "64465" },
    { lng: -92.877874, lat: 37.277247, postalCode: "65636" },
    { lng: -93.56712, lat: 38.76699, postalCode: "65336" },
    { lng: -94.57551, lat: 38.363183, postalCode: "64723" },
    { lng: -94.47465, lat: 37.155108, postalCode: "64870" },
    { lng: -92.08132, lat: 38.723957, postalCode: "65063" },
    { lng: -93.39425, lat: 40.165088, postalCode: "64641" },
    { lng: -90.74276, lat: 38.401406, postalCode: "63015" },
    { lng: -90.61875, lat: 36.585908, postalCode: "63953" },
    { lng: -91.98054, lat: 37.926116, postalCode: "65529" },
    { lng: -94.58296, lat: 39.084554, postalCode: "64108" },
    { lng: -90.28434, lat: 38.661351, postalCode: "63112" },
    { lng: -93.19234, lat: 39.884096, postalCode: "64653" },
    { lng: -94.44943, lat: 39.076405, postalCode: "64052" },
    { lng: -92.97298, lat: 40.472841, postalCode: "63565" },
    { lng: -89.97423, lat: 36.569283, postalCode: "63863" },
    { lng: -92.36284, lat: 40.486993, postalCode: "63536" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64183" },
    { lng: -91.86679, lat: 39.539711, postalCode: "65283" },
    { lng: -95.34418, lat: 40.554988, postalCode: "64498" },
    { lng: -90.536784, lat: 38.750949, postalCode: "63346" },
    { lng: -94.60066, lat: 39.103037, postalCode: "64101" },
    { lng: -92.39099, lat: 37.532445, postalCode: "65470" },
    { lng: -92.102153, lat: 38.903318, postalCode: "65299" },
    { lng: -93.11007, lat: 36.603318, postalCode: "65679" },
    { lng: -93.35686, lat: 39.610631, postalCode: "64643" },
    { lng: -91.39332, lat: 38.387446, postalCode: "63091" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64187" },
    { lng: -94.35075, lat: 37.838563, postalCode: "64772" },
    { lng: -89.822514, lat: 36.267825, postalCode: "63871" },
    { lng: -92.4927, lat: 39.626821, postalCode: "65247" },
    { lng: -93.80512, lat: 39.757364, postalCode: "64625" },
    { lng: -90.77656, lat: 37.611019, postalCode: "63656" },
    { lng: -93.90776, lat: 40.358817, postalCode: "64481" },
    { lng: -94.29621, lat: 39.237332, postalCode: "64072" },
    { lng: -94.03213, lat: 39.203641, postalCode: "64017" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63167" },
    { lng: -93.49861, lat: 36.930861, postalCode: "65675" },
    { lng: -94.02233, lat: 38.171591, postalCode: "64724" },
    { lng: -92.94999, lat: 37.020827, postalCode: "65620" },
    { lng: -91.09159, lat: 38.647611, postalCode: "63357" },
    { lng: -95.02142, lat: 40.187914, postalCode: "64455" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63163" },
    { lng: -91.72967, lat: 39.645523, postalCode: "63456" },
    { lng: -93.19156, lat: 38.451021, postalCode: "65325" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65102" },
    { lng: -91.22652, lat: 38.404826, postalCode: "63056" },
    { lng: -91.777182, lat: 37.876191, postalCode: "65409" },
    { lng: -91.65954, lat: 38.493511, postalCode: "65062" },
    { lng: -92.6068, lat: 38.798727, postalCode: "65068" },
    { lng: -93.93112, lat: 38.526134, postalCode: "64726" },
    { lng: -92.27609, lat: 37.176376, postalCode: "65711" },
    { lng: -93.01139, lat: 36.569847, postalCode: "65627" },
    { lng: -90.8136, lat: 39.151543, postalCode: "63343" },
    { lng: -91.65064, lat: 37.184223, postalCode: "65571" },
    { lng: -93.98136, lat: 40.109791, postalCode: "64636" },
    { lng: -92.85127, lat: 40.244242, postalCode: "63544" },
    { lng: -93.80653, lat: 37.703022, postalCode: "65785" },
    { lng: -93.43981, lat: 40.025913, postalCode: "64652" },
    { lng: -89.206329, lat: 36.779519, postalCode: "63881" },
    { lng: -94.45103, lat: 38.803528, postalCode: "64083" },
    { lng: -92.67253, lat: 38.662989, postalCode: "65025" },
    { lng: -90.71264, lat: 37.913799, postalCode: "63660" },
    { lng: -94.335453, lat: 37.206219, postalCode: "64869" },
    { lng: -91.188586, lat: 38.770187, postalCode: "63378" },
    { lng: -94.59471, lat: 39.013638, postalCode: "64113" },
    { lng: -92.13905, lat: 38.535897, postalCode: "65101" },
    { lng: -94.68406, lat: 40.242954, postalCode: "64433" },
    { lng: -93.79569, lat: 39.581772, postalCode: "64624" },
    { lng: -93.29015, lat: 37.257053, postalCode: "65803" },
    { lng: -91.685103, lat: 40.431201, postalCode: "63466" },
    { lng: -92.135741, lat: 37.827415, postalCode: "65584" },
    { lng: -90.78525, lat: 38.796601, postalCode: "63367" },
    { lng: -92.46878, lat: 39.747736, postalCode: "63552" },
    { lng: -91.78576, lat: 37.37815, postalCode: "65555" },
    { lng: -90.38551, lat: 38.727184, postalCode: "63074" },
    { lng: -92.63582, lat: 39.7418, postalCode: "63534" },
    { lng: -92.29136, lat: 39.208685, postalCode: "65284" },
    { lng: -90.48646, lat: 36.559031, postalCode: "63954" },
    { lng: -92.99055, lat: 38.151604, postalCode: "65324" },
    { lng: -92.35461, lat: 38.543762, postalCode: "65053" },
    { lng: -90.26307, lat: 38.580552, postalCode: "63116" },
    { lng: -94.32419, lat: 37.002574, postalCode: "64840" },
    { lng: -94.51124, lat: 37.047161, postalCode: "64804" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64196" },
    { lng: -91.36574, lat: 37.165828, postalCode: "65466" },
    { lng: -93.96947, lat: 39.311978, postalCode: "64085" },
    { lng: -94.41763, lat: 39.246179, postalCode: "64068" },
    { lng: -93.96162, lat: 37.411943, postalCode: "65682" },
    { lng: -94.53932, lat: 38.929356, postalCode: "64137" },
    { lng: -92.29003, lat: 38.926838, postalCode: "65201" },
    { lng: -90.114179, lat: 36.101239, postalCode: "63872" },
    { lng: -94.49465, lat: 38.616741, postalCode: "64746" },
    { lng: -90.68542, lat: 37.326021, postalCode: "63675" },
    { lng: -90.85642, lat: 38.247062, postalCode: "63060" },
    { lng: -94.57286, lat: 39.033505, postalCode: "64110" },
    { lng: -93.235798, lat: 36.660981, postalCode: "65615" },
    { lng: -93.20199, lat: 37.167282, postalCode: "65809" },
    { lng: -92.82494, lat: 38.232201, postalCode: "65037" },
    { lng: -90.52353, lat: 37.199631, postalCode: "63956" },
    { lng: -94.335453, lat: 37.206219, postalCode: "64802" },
    { lng: -93.2482, lat: 37.746461, postalCode: "65659" },
    { lng: -94.22416, lat: 37.747201, postalCode: "64767" },
    { lng: -92.30496, lat: 38.10273, postalCode: "65486" },
    { lng: -90.514875, lat: 38.252726, postalCode: "63065" },
    { lng: -94.00494, lat: 38.429061, postalCode: "64788" },
    { lng: -93.25995, lat: 40.448227, postalCode: "64655" },
    { lng: -94.64152, lat: 39.917997, postalCode: "64459" },
    { lng: -89.74861, lat: 36.235881, postalCode: "63851" },
    { lng: -92.178057, lat: 38.599175, postalCode: "65022" },
    { lng: -92.07367, lat: 37.365975, postalCode: "65444" },
    { lng: -91.88404, lat: 39.179316, postalCode: "65265" },
    { lng: -95.3661, lat: 40.452497, postalCode: "64491" },
    { lng: -93.38497, lat: 37.134829, postalCode: "65619" },
    { lng: -91.73595, lat: 39.98803, postalCode: "63440" },
    { lng: -89.57338, lat: 36.67088, postalCode: "63860" },
    { lng: -91.87248, lat: 40.489269, postalCode: "63453" },
    { lng: -91.53556, lat: 38.973583, postalCode: "63361" },
    { lng: -94.09057, lat: 37.371365, postalCode: "64748" },
    { lng: -90.53918, lat: 38.647023, postalCode: "63017" },
    { lng: -92.68811, lat: 39.015654, postalCode: "65274" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63153" },
    { lng: -94.370275, lat: 38.964518, postalCode: "64013" },
    { lng: -92.43041, lat: 39.522573, postalCode: "65239" },
    { lng: -94.20783, lat: 39.118608, postalCode: "64016" },
    { lng: -92.67946, lat: 39.487134, postalCode: "65244" },
    { lng: -89.61644, lat: 37.671971, postalCode: "63748" },
    { lng: -91.64615, lat: 36.602467, postalCode: "65692" },
    { lng: -91.29933, lat: 36.531635, postalCode: "65778" },
    { lng: -92.69113, lat: 40.411635, postalCode: "63567" },
    { lng: -90.45363, lat: 38.662302, postalCode: "63141" },
    { lng: -92.98277, lat: 40.256611, postalCode: "63545" },
    { lng: -91.78052, lat: 38.633533, postalCode: "65024" },
    { lng: -92.07913, lat: 40.303327, postalCode: "63563" },
    { lng: -94.18376, lat: 38.575037, postalCode: "64747" },
    { lng: -90.407013, lat: 36.712451, postalCode: "63902" },
    { lng: -90.23706, lat: 38.876781, postalCode: "63386" },
    { lng: -92.39699, lat: 40.181653, postalCode: "63533" },
    { lng: -93.17023, lat: 40.037077, postalCode: "64630" },
    { lng: -94.31164, lat: 37.168985, postalCode: "64836" },
    { lng: -94.58868, lat: 39.104204, postalCode: "64105" },
    { lng: -91.95796, lat: 36.996755, postalCode: "65793" },
    { lng: -94.39016, lat: 36.86429, postalCode: "64850" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63155" },
    { lng: -93.49429, lat: 38.972563, postalCode: "65327" },
    { lng: -90.61304, lat: 38.494203, postalCode: "63025" },
    { lng: -92.41231, lat: 37.093694, postalCode: "65717" },
    { lng: -94.53201, lat: 38.810856, postalCode: "64012" },
    { lng: -91.56669, lat: 39.267199, postalCode: "63345" },
    { lng: -94.67635, lat: 39.31835, postalCode: "64163" },
    { lng: -90.444953, lat: 37.119566, postalCode: "63632" },
    { lng: -93.11171, lat: 36.706492, postalCode: "65653" },
    { lng: -93.70554, lat: 37.508827, postalCode: "65635" },
    { lng: -92.50308, lat: 40.018881, postalCode: "63549" },
    { lng: -94.09708, lat: 38.765972, postalCode: "64061" },
    { lng: -92.31857, lat: 38.35222, postalCode: "65040" },
    { lng: -91.95471, lat: 37.316088, postalCode: "65483" },
    { lng: -94.25767, lat: 39.017559, postalCode: "64014" },
    { lng: -90.444953, lat: 37.119566, postalCode: "63950" },
    { lng: -89.81877, lat: 36.507559, postalCode: "63878" },
    { lng: -94.48655, lat: 39.3173, postalCode: "64167" },
    { lng: -91.2243, lat: 39.085749, postalCode: "63370" },
    { lng: -91.49636, lat: 39.925941, postalCode: "63471" },
    { lng: -92.13198, lat: 39.516668, postalCode: "65258" },
    { lng: -94.56078, lat: 38.505786, postalCode: "64742" },
    { lng: -91.37684, lat: 39.334216, postalCode: "63339" },
    { lng: -94.38471, lat: 39.771525, postalCode: "64474" },
    { lng: -90.17023, lat: 36.131733, postalCode: "63876" },
    { lng: -89.6774, lat: 37.605626, postalCode: "63783" },
    { lng: -94.52287, lat: 39.113604, postalCode: "64123" },
    { lng: -91.1097, lat: 37.672965, postalCode: "65439" },
    { lng: -90.33904, lat: 38.738217, postalCode: "63134" },
    { lng: -93.73519, lat: 38.772117, postalCode: "64093" },
    { lng: -89.643941, lat: 37.366166, postalCode: "63705" },
    { lng: -90.60432, lat: 37.292529, postalCode: "63636" },
    { lng: -94.3578, lat: 39.365318, postalCode: "64060" },
    { lng: -90.81359, lat: 36.828561, postalCode: "63943" },
    { lng: -94.65253, lat: 39.742355, postalCode: "64443" },
    { lng: -94.01825, lat: 36.619334, postalCode: "65772" },
    { lng: -93.43392, lat: 36.63101, postalCode: "65686" },
    { lng: -93.82164, lat: 39.29596, postalCode: "64035" },
    { lng: -93.94164, lat: 36.795164, postalCode: "65734" },
    { lng: -90.80694, lat: 38.257711, postalCode: "63061" },
    { lng: -94.83935, lat: 40.03722, postalCode: "64483" },
    { lng: -91.851732, lat: 36.741969, postalCode: "65575" },
    { lng: -94.00271, lat: 37.853003, postalCode: "64744" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65104" },
    { lng: -91.51423, lat: 37.630896, postalCode: "65560" },
    { lng: -90.78593, lat: 37.753408, postalCode: "63631" },
    { lng: -90.23343, lat: 36.048279, postalCode: "63821" },
    { lng: -91.02077, lat: 37.442703, postalCode: "63633" },
    { lng: -92.14776, lat: 36.527558, postalCode: "65609" },
    { lng: -94.50169, lat: 37.09416, postalCode: "64801" },
    { lng: -91.67592, lat: 39.09551, postalCode: "65264" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63157" },
    { lng: -90.77801, lat: 38.935776, postalCode: "63369" },
    { lng: -92.4344, lat: 39.420353, postalCode: "65270" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65110" },
    { lng: -89.98417, lat: 37.833644, postalCode: "63673" },
    { lng: -94.35335, lat: 39.44609, postalCode: "64048" },
    { lng: -91.697596, lat: 36.648817, postalCode: "65688" },
    { lng: -92.97655, lat: 38.609313, postalCode: "65329" },
    { lng: -93.09353, lat: 38.671012, postalCode: "65350" },
    { lng: -92.93735, lat: 38.849336, postalCode: "65276" },
    { lng: -94.2761, lat: 37.507321, postalCode: "64759" },
    { lng: -93.76042, lat: 38.364214, postalCode: "64735" },
    { lng: -94.50755, lat: 39.322849, postalCode: "64166" },
    { lng: -91.22224, lat: 37.416192, postalCode: "63629" },
    { lng: -91.40084, lat: 39.695798, postalCode: "63401" },
    { lng: -92.102153, lat: 38.903318, postalCode: "65216" },
    { lng: -93.04973, lat: 39.792042, postalCode: "64628" },
    { lng: -92.9155, lat: 39.500486, postalCode: "65261" },
    { lng: -94.56774, lat: 39.389339, postalCode: "64089" },
    { lng: -94.156, lat: 36.618869, postalCode: "65730" },
    { lng: -90.68325, lat: 37.827126, postalCode: "63648" },
    { lng: -90.30884, lat: 37.072189, postalCode: "63963" },
    { lng: -93.12274, lat: 36.655356, postalCode: "65731" },
    { lng: -94.62495, lat: 40.530798, postalCode: "64486" },
    { lng: -91.63745, lat: 39.93264, postalCode: "63454" },
    { lng: -93.48123, lat: 39.355424, postalCode: "64633" },
    { lng: -93.96536, lat: 39.912973, postalCode: "64640" },
    { lng: -94.34846, lat: 39.115776, postalCode: "64056" },
    { lng: -89.63377, lat: 36.588424, postalCode: "63862" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63099" },
    { lng: -92.02791, lat: 38.417602, postalCode: "65085" },
    { lng: -90.38515, lat: 38.42727, postalCode: "63010" },
    { lng: -90.99852, lat: 36.985902, postalCode: "63965" },
    { lng: -91.18155, lat: 39.549602, postalCode: "63433" },
    { lng: -90.44451, lat: 38.725331, postalCode: "63043" },
    { lng: -92.27211, lat: 37.330625, postalCode: "65660" },
    { lng: -92.214372, lat: 37.309426, postalCode: "65433" },
    { lng: -94.47579, lat: 36.547736, postalCode: "64854" },
    { lng: -89.68379, lat: 37.182556, postalCode: "63740" },
    { lng: -93.343673, lat: 37.25807, postalCode: "65765" },
    { lng: -93.73134, lat: 36.536324, postalCode: "65641" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65106" },
    { lng: -92.3956, lat: 38.351597, postalCode: "65032" },
    { lng: -92.73932, lat: 38.935305, postalCode: "65233" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63158" },
    { lng: -89.61264, lat: 37.043378, postalCode: "63767" },
    { lng: -91.79502, lat: 40.101973, postalCode: "63452" },
    { lng: -91.18265, lat: 39.318552, postalCode: "63334" },
    { lng: -94.07515, lat: 38.054666, postalCode: "64780" },
    { lng: -91.337506, lat: 37.153294, postalCode: "65573" },
    { lng: -90.63962, lat: 37.740163, postalCode: "63624" },
    { lng: -89.98127, lat: 37.309447, postalCode: "63764" },
    { lng: -94.21156, lat: 39.012404, postalCode: "64029" },
    { lng: -91.17, lat: 38.818852, postalCode: "63383" },
    { lng: -90.26182, lat: 38.622601, postalCode: "63110" },
    { lng: -91.50598, lat: 40.381613, postalCode: "63430" },
    { lng: -93.555456, lat: 39.689033, postalCode: "64600" },
    { lng: -94.28975, lat: 40.386181, postalCode: "64441" },
    { lng: -89.69889, lat: 37.30925, postalCode: "63752" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65107" },
    { lng: -93.03846, lat: 37.509611, postalCode: "65644" },
    { lng: -93.00809, lat: 36.839078, postalCode: "65657" },
    { lng: -94.55543, lat: 37.174256, postalCode: "64834" },
    { lng: -90.16705, lat: 37.052849, postalCode: "63763" },
    { lng: -90.35168, lat: 38.590652, postalCode: "63119" },
    { lng: -90.21864, lat: 38.748183, postalCode: "63137" },
    { lng: -93.25668, lat: 36.64417, postalCode: "65616" },
    { lng: -93.58237, lat: 40.391564, postalCode: "64673" },
    { lng: -93.26634, lat: 38.34463, postalCode: "65338" },
    { lng: -93.34884, lat: 37.436062, postalCode: "65617" },
    { lng: -93.82496, lat: 38.581879, postalCode: "64733" },
    { lng: -93.41123, lat: 38.61917, postalCode: "65332" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64173" },
    { lng: -90.02477, lat: 37.513967, postalCode: "63662" },
    { lng: -90.5666, lat: 38.259946, postalCode: "63050" },
    { lng: -93.22589, lat: 39.310853, postalCode: "65344" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64171" },
    { lng: -94.73858, lat: 39.446161, postalCode: "64018" },
    { lng: -94.84504, lat: 39.766144, postalCode: "64501" },
    { lng: -93.54965, lat: 40.522402, postalCode: "64661" },
    { lng: -89.55184, lat: 36.751798, postalCode: "63867" },
    { lng: -94.8073, lat: 40.521372, postalCode: "64461" },
    { lng: -93.9882, lat: 38.712465, postalCode: "64040" },
    { lng: -91.77105, lat: 37.94384, postalCode: "65401" },
    { lng: -94.40163, lat: 40.183995, postalCode: "64438" },
    { lng: -91.817936, lat: 37.963208, postalCode: "65402" },
    { lng: -92.67027, lat: 39.148876, postalCode: "65248" },
    { lng: -95.41748, lat: 40.321432, postalCode: "64446" },
    { lng: -94.92726, lat: 39.90444, postalCode: "64421" },
    { lng: -92.33081, lat: 36.851944, postalCode: "65638" },
    { lng: -92.5161, lat: 37.840129, postalCode: "65567" },
    { lng: -92.40512, lat: 37.828549, postalCode: "65556" },
    { lng: -90.74557, lat: 38.020743, postalCode: "63630" },
    { lng: -91.57242, lat: 38.748204, postalCode: "65069" },
    { lng: -94.22947, lat: 36.73901, postalCode: "64867" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63188" },
    { lng: -93.30563, lat: 37.045593, postalCode: "65714" },
    { lng: -91.57389, lat: 40.178309, postalCode: "63435" },
    { lng: -90.6596, lat: 37.383001, postalCode: "63620" },
    { lng: -90.65332, lat: 38.72926, postalCode: "63304" },
    { lng: -92.94184, lat: 37.61082, postalCode: "65590" },
    { lng: -93.478696, lat: 39.630482, postalCode: "64621" },
    { lng: -92.94888, lat: 37.145969, postalCode: "65652" },
    { lng: -93.42709, lat: 37.487034, postalCode: "65710" },
    { lng: -92.78269, lat: 38.032648, postalCode: "65020" },
    { lng: -91.95808, lat: 38.852436, postalCode: "65251" },
    { lng: -94.46049, lat: 38.706077, postalCode: "64078" },
    { lng: -95.09462, lat: 40.30191, postalCode: "64487" },
    { lng: -93.69201, lat: 39.344835, postalCode: "64668" },
    { lng: -95.12039, lat: 40.527275, postalCode: "64445" },
    { lng: -92.00144, lat: 39.463743, postalCode: "65275" },
    { lng: -91.52391, lat: 36.543362, postalCode: "65791" },
    { lng: -93.40798, lat: 38.982222, postalCode: "65351" },
    { lng: -92.66205, lat: 36.921199, postalCode: "65608" },
    { lng: -93.11175, lat: 39.436768, postalCode: "65236" },
    { lng: -89.79907, lat: 37.360527, postalCode: "63739" },
    { lng: -90.86797, lat: 37.91496, postalCode: "63664" },
    { lng: -93.343673, lat: 37.25807, postalCode: "65817" },
    { lng: -90.88172, lat: 37.682669, postalCode: "63623" },
    { lng: -94.16048, lat: 37.146249, postalCode: "64859" },
    { lng: -94.53234, lat: 39.125304, postalCode: "64120" },
    { lng: -94.80299, lat: 39.748827, postalCode: "64507" },
    { lng: -93.31461, lat: 37.120301, postalCode: "65810" },
    { lng: -93.26457, lat: 37.43378, postalCode: "65725" },
    { lng: -94.21822, lat: 37.903772, postalCode: "64790" },
    { lng: -89.73149, lat: 36.642019, postalCode: "63833" },
    { lng: -93.65335, lat: 37.899548, postalCode: "64738" },
    { lng: -94.42087, lat: 37.236623, postalCode: "64830" },
    { lng: -90.53, lat: 37.844197, postalCode: "63601" },
    { lng: -90.514875, lat: 38.252726, postalCode: "63053" },
    { lng: -93.17456, lat: 37.844753, postalCode: "65767" },
    { lng: -92.23452, lat: 39.104503, postalCode: "65255" },
    { lng: -89.748102, lat: 36.213318, postalCode: "63840" },
    { lng: -89.77451, lat: 37.094857, postalCode: "63774" },
    { lng: -92.28363, lat: 37.689335, postalCode: "65534" },
    { lng: -91.68075, lat: 40.526542, postalCode: "63465" },
    { lng: -92.76541, lat: 37.582527, postalCode: "65722" },
    { lng: -93.06252, lat: 39.221658, postalCode: "65349" },
    { lng: -92.59117, lat: 37.97957, postalCode: "65591" },
    { lng: -91.61179, lat: 38.011872, postalCode: "65559" },
    { lng: -94.05158, lat: 39.541896, postalCode: "64671" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63032" },
    { lng: -92.58395, lat: 38.311355, postalCode: "65026" },
    { lng: -94.405903, lat: 36.739448, postalCode: "64810" },
    { lng: -94.12946, lat: 37.195692, postalCode: "64833" },
    { lng: -94.39967, lat: 39.01543, postalCode: "64136" },
    { lng: -93.07282, lat: 39.657285, postalCode: "64676" },
    { lng: -90.31822, lat: 38.612452, postalCode: "63143" },
    { lng: -90.43643, lat: 38.618582, postalCode: "63131" },
    { lng: -91.87351, lat: 37.489718, postalCode: "65542" },
    { lng: -93.9294, lat: 39.214233, postalCode: "64036" },
    { lng: -91.93811, lat: 37.862197, postalCode: "65550" },
    { lng: -92.14245, lat: 36.610463, postalCode: "65626" },
    { lng: -93.54065, lat: 37.410221, postalCode: "65770" },
    { lng: -92.80171, lat: 38.561436, postalCode: "65034" },
    { lng: -92.51071, lat: 40.524127, postalCode: "63548" },
    { lng: -92.71511, lat: 38.248494, postalCode: "65072" },
    { lng: -90.28578, lat: 38.79505, postalCode: "63033" },
    { lng: -93.17887, lat: 37.93345, postalCode: "65732" },
    { lng: -92.62563, lat: 36.802445, postalCode: "65755" },
    { lng: -90.191972, lat: 37.89872, postalCode: "63661" },
    { lng: -93.343673, lat: 37.25807, postalCode: "65805" },
    { lng: -93.431928, lat: 39.410971, postalCode: "64680" },
    { lng: -93.195, lat: 38.015379, postalCode: "65634" },
    { lng: -94.57323, lat: 38.899843, postalCode: "64146" },
    { lng: -93.99009, lat: 39.731689, postalCode: "64644" },
    { lng: -90.65619, lat: 37.028626, postalCode: "63952" },
    { lng: -92.13684, lat: 39.212252, postalCode: "65240" },
    { lng: -92.442341, lat: 36.651607, postalCode: "65666" },
    { lng: -93.07945, lat: 37.000766, postalCode: "65753" },
    { lng: -90.69685, lat: 37.151397, postalCode: "63957" },
    { lng: -94.38241, lat: 38.086255, postalCode: "64779" },
    { lng: -94.19587, lat: 40.264749, postalCode: "64471" },
    { lng: -94.04897, lat: 39.637805, postalCode: "64650" },
    { lng: -92.92425, lat: 37.715074, postalCode: "65783" },
    { lng: -94.51816, lat: 39.202654, postalCode: "64119" },
    { lng: -93.33273, lat: 40.299574, postalCode: "64645" },
    { lng: -90.68582, lat: 36.604837, postalCode: "63955" },
    { lng: -92.23595, lat: 40.281881, postalCode: "63531" },
    { lng: -93.001374, lat: 39.235169, postalCode: "65530" },
    { lng: -94.12713, lat: 40.051163, postalCode: "64670" },
    { lng: -90.23594, lat: 36.687259, postalCode: "63932" },
    { lng: -91.38258, lat: 39.584889, postalCode: "63459" },
    { lng: -91.99255, lat: 39.98354, postalCode: "63458" },
    { lng: -91.92948, lat: 38.959191, postalCode: "65262" },
    { lng: -90.42887, lat: 37.248929, postalCode: "63964" },
    { lng: -93.00645, lat: 38.364491, postalCode: "65078" },
    { lng: -93.51705, lat: 38.525725, postalCode: "65360" },
    { lng: -93.73366, lat: 39.058604, postalCode: "64037" },
    { lng: -93.27802, lat: 37.789907, postalCode: "65727" },
    { lng: -90.58935, lat: 37.863193, postalCode: "63653" },
    { lng: -95.03076, lat: 39.544889, postalCode: "64484" },
    { lng: -90.5614, lat: 37.929597, postalCode: "63628" },
    { lng: -93.56742, lat: 40.230884, postalCode: "64679" },
    { lng: -94.31921, lat: 39.634086, postalCode: "64493" },
    { lng: -92.56443, lat: 38.624862, postalCode: "65018" },
    { lng: -89.54435, lat: 36.59793, postalCode: "63869" },
    { lng: -93.62835, lat: 39.743, postalCode: "64686" },
    { lng: -90.78593, lat: 38.68879, postalCode: "63341" },
    { lng: -90.77209, lat: 39.00006, postalCode: "63389" },
    { lng: -92.59234, lat: 36.525433, postalCode: "65729" },
    { lng: -91.00353, lat: 38.434806, postalCode: "63084" },
    { lng: -94.63353, lat: 39.261679, postalCode: "64154" },
    { lng: -94.22274, lat: 39.933989, postalCode: "64497" },
    { lng: -89.81622, lat: 36.818652, postalCode: "63846" },
    { lng: -94.45313, lat: 37.766787, postalCode: "64771" },
    { lng: -94.42222, lat: 37.946913, postalCode: "64751" },
    { lng: -93.8743, lat: 40.528127, postalCode: "64426" },
    { lng: -92.60818, lat: 40.513638, postalCode: "63541" },
    { lng: -92.1671, lat: 40.164349, postalCode: "63537" },
    { lng: -91.58968, lat: 38.675775, postalCode: "65036" },
    { lng: -90.38215, lat: 38.226997, postalCode: "63019" },
    { lng: -94.551, lat: 38.991406, postalCode: "64132" },
    { lng: -92.41251, lat: 39.342316, postalCode: "65278" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64179" },
    { lng: -90.0261, lat: 36.330522, postalCode: "63880" },
    { lng: -94.857364, lat: 39.676333, postalCode: "64502" },
    { lng: -94.83237, lat: 39.741794, postalCode: "64503" },
    { lng: -93.36354, lat: 38.242861, postalCode: "65355" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63169" },
    { lng: -89.650005, lat: 36.603844, postalCode: "63838" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63171" },
    { lng: -92.320783, lat: 38.953238, postalCode: "65215" },
    { lng: -90.29051, lat: 38.832863, postalCode: "63034" },
    { lng: -90.28499, lat: 36.947396, postalCode: "63966" },
    { lng: -94.551058, lat: 40.024499, postalCode: "64464" },
    { lng: -92.53946, lat: 39.014478, postalCode: "65279" },
    { lng: -92.19491, lat: 38.36528, postalCode: "65076" },
    { lng: -94.23378, lat: 39.743859, postalCode: "64429" },
    { lng: -94.49541, lat: 39.104887, postalCode: "64125" },
    { lng: -89.739429, lat: 37.197279, postalCode: "63744" },
    { lng: -91.63376, lat: 37.353209, postalCode: "65479" },
    { lng: -95.53297, lat: 40.437038, postalCode: "64482" },
    { lng: -89.58355, lat: 36.891163, postalCode: "63801" },
    { lng: -90.243462, lat: 38.6531, postalCode: "63182" },
    { lng: -94.57344, lat: 39.276828, postalCode: "64155" },
    { lng: -94.44985, lat: 40.33816, postalCode: "64453" },
    { lng: -93.12043, lat: 40.184189, postalCode: "63556" },
    { lng: -89.81874, lat: 36.550379, postalCode: "63874" },
    { lng: -91.98863, lat: 36.524268, postalCode: "65777" },
    { lng: -90.37113, lat: 38.500734, postalCode: "63128" },
    { lng: -92.98423, lat: 37.827158, postalCode: "65764" },
    { lng: -89.97428, lat: 36.784453, postalCode: "63841" },
    { lng: -93.37153, lat: 36.693007, postalCode: "65737" },
    { lng: -94.36397, lat: 38.913857, postalCode: "64063" },
    { lng: -90.3683, lat: 38.935496, postalCode: "63373" },
    { lng: -93.897434, lat: 37.021147, postalCode: "65654" },
    { lng: -91.49522, lat: 38.33895, postalCode: "65066" },
    { lng: -93.48394, lat: 36.771898, postalCode: "65656" },
    { lng: -89.44945, lat: 36.905584, postalCode: "63823" },
    { lng: -94.09323, lat: 38.506491, postalCode: "64739" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63006" },
    { lng: -92.217391, lat: 37.81186, postalCode: "65572" },
    { lng: -92.45914, lat: 39.137047, postalCode: "65256" },
    { lng: -93.83934, lat: 37.439717, postalCode: "65661" },
    { lng: -94.57656, lat: 38.972473, postalCode: "64131" },
    { lng: -92.41809, lat: 40.096431, postalCode: "63540" },
    { lng: -92.22218, lat: 37.805756, postalCode: "65587" },
    { lng: -92.62804, lat: 36.569901, postalCode: "65676" },
    { lng: -92.88165, lat: 39.79373, postalCode: "64631" },
    { lng: -92.3224, lat: 39.753475, postalCode: "63431" },
    { lng: -93.259586, lat: 37.181498, postalCode: "65899" },
    { lng: -92.249342, lat: 38.530921, postalCode: "65111" },
    { lng: -92.84968, lat: 38.428609, postalCode: "65084" },
    { lng: -94.16376, lat: 38.760865, postalCode: "64090" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64999" },
    { lng: -92.5821, lat: 37.128682, postalCode: "65704" },
    { lng: -94.356728, lat: 39.035038, postalCode: "64197" },
    { lng: -94.38993, lat: 40.496954, postalCode: "64456" },
    { lng: -94.51496, lat: 37.555366, postalCode: "64762" },
    { lng: -89.652912, lat: 37.663253, postalCode: "63737" },
    { lng: -94.3957, lat: 38.850243, postalCode: "64082" },
    { lng: -90.193, lat: 38.631551, postalCode: "63101" },
    { lng: -93.4179, lat: 37.340995, postalCode: "65781" },
    { lng: -90.427118, lat: 38.638318, postalCode: "63145" },
    { lng: -94.54507, lat: 39.034838, postalCode: "64130" },
    { lng: -92.28803, lat: 38.692923, postalCode: "65039" },
    { lng: -94.408998, lat: 39.282869, postalCode: "64069" },
    { lng: -91.36286, lat: 36.679742, postalCode: "65606" },
    { lng: -89.53997, lat: 37.093104, postalCode: "63736" },
    { lng: -93.20341, lat: 36.829356, postalCode: "65630" },
    { lng: -94.81076, lat: 39.599578, postalCode: "64448" },
    { lng: -92.20608, lat: 37.366219, postalCode: "65484" },
    { lng: -90.13105, lat: 37.212218, postalCode: "63753" },
    { lng: -93.71189, lat: 39.743993, postalCode: "64664" },
    { lng: -91.44852, lat: 38.881628, postalCode: "63363" },
    { lng: -92.64733, lat: 37.678528, postalCode: "65536" },
    { lng: -93.539707, lat: 37.476695, postalCode: "65645" },
    { lng: -93.295137, lat: 37.180349, postalCode: "65898" },
    { lng: -91.73545, lat: 39.213975, postalCode: "65280" },
    { lng: -91.05402, lat: 36.546354, postalCode: "63942" },
    { lng: -90.995399, lat: 33.272695, postalCode: "38780" },
    { lng: -90.50127, lat: 33.165359, postalCode: "39038" },
    { lng: -89.517069, lat: 32.470606, postalCode: "39087" },
    { lng: -89.068448, lat: 30.415795, postalCode: "39506" },
    { lng: -89.01461, lat: 32.314553, postalCode: "39332" },
    { lng: -90.49719, lat: 33.44504, postalCode: "38761" },
    { lng: -89.84256, lat: 31.948527, postalCode: "39114" },
    { lng: -90.60286, lat: 32.295098, postalCode: "39066" },
    { lng: -88.49952, lat: 32.678923, postalCode: "39352" },
    { lng: -89.91568, lat: 34.236973, postalCode: "38620" },
    { lng: -90.59442, lat: 34.196126, postalCode: "38614" },
    { lng: -90.79251, lat: 32.977692, postalCode: "38721" },
    { lng: -89.673392, lat: 30.666936, postalCode: "39457" },
    { lng: -90.61749, lat: 32.105647, postalCode: "39175" },
    { lng: -89.33214, lat: 34.926974, postalCode: "38642" },
    { lng: -89.30503, lat: 31.496211, postalCode: "39459" },
    { lng: -90.82835, lat: 32.641205, postalCode: "39177" },
    { lng: -89.06039, lat: 34.259164, postalCode: "38160" },
    { lng: -90.392603, lat: 31.928751, postalCode: "39077" },
    { lng: -88.85572, lat: 34.937606, postalCode: "38683" },
    { lng: -88.64686, lat: 33.619058, postalCode: "39773" },
    { lng: -90.16933, lat: 32.334738, postalCode: "39216" },
    { lng: -88.83866, lat: 32.170432, postalCode: "39330" },
    { lng: -91.08403, lat: 31.487982, postalCode: "39661" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39232" },
    { lng: -89.33776, lat: 34.643828, postalCode: "38659" },
    { lng: -88.767127, lat: 34.234527, postalCode: "38802" },
    { lng: -89.66611, lat: 30.52798, postalCode: "39466" },
    { lng: -89.44452, lat: 34.171462, postalCode: "38949" },
    { lng: -91.35511, lat: 31.120979, postalCode: "39669" },
    { lng: -89.053844, lat: 33.11415, postalCode: "39357" },
    { lng: -89.758302, lat: 33.689476, postalCode: "38926" },
    { lng: -88.83873, lat: 34.359456, postalCode: "38869" },
    { lng: -90.779, lat: 32.524981, postalCode: "39156" },
    { lng: -88.86736, lat: 32.553716, postalCode: "39325" },
    { lng: -89.30528, lat: 31.726898, postalCode: "39480" },
    { lng: -88.35574, lat: 33.463004, postalCode: "39702" },
    { lng: -88.753976, lat: 33.659271, postalCode: "39754" },
    { lng: -89.484963, lat: 34.74504, postalCode: "38634" },
    { lng: -89.56525, lat: 31.651487, postalCode: "39428" },
    { lng: -88.72248, lat: 31.176281, postalCode: "39461" },
    { lng: -90.9695, lat: 33.850803, postalCode: "38769" },
    { lng: -88.80915, lat: 31.072789, postalCode: "39456" },
    { lng: -88.60817, lat: 34.57971, postalCode: "38880" },
    { lng: -89.93012, lat: 33.910734, postalCode: "38953" },
    { lng: -90.45228, lat: 31.292262, postalCode: "39666" },
    { lng: -89.92279, lat: 34.943945, postalCode: "38672" },
    { lng: -90.18159, lat: 33.753716, postalCode: "38950" },
    { lng: -88.9671, lat: 33.904246, postalCode: "38851" },
    { lng: -90.44989, lat: 31.186981, postalCode: "39635" },
    { lng: -88.922, lat: 31.931474, postalCode: "39366" },
    { lng: -90.33746, lat: 33.63788, postalCode: "38952" },
    { lng: -89.2279, lat: 31.358823, postalCode: "39465" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39215" },
    { lng: -90.39157, lat: 32.667612, postalCode: "39048" },
    { lng: -91.07723, lat: 31.078189, postalCode: "39631" },
    { lng: -90.957291, lat: 33.269118, postalCode: "38782" },
    { lng: -90.32859, lat: 32.119511, postalCode: "39170" },
    { lng: -89.90517, lat: 34.418338, postalCode: "38666" },
    { lng: -90.21951, lat: 32.832943, postalCode: "39039" },
    { lng: -90.50275, lat: 34.060111, postalCode: "38739" },
    { lng: -89.00425, lat: 34.236312, postalCode: "38863" },
    { lng: -90.39034, lat: 34.015686, postalCode: "38963" },
    { lng: -88.24591, lat: 33.885239, postalCode: "38844" },
    { lng: -89.2469, lat: 31.857399, postalCode: "39481" },
    { lng: -88.63051, lat: 34.511249, postalCode: "38824" },
    { lng: -89.90638, lat: 34.181071, postalCode: "38658" },
    { lng: -90.188462, lat: 33.929206, postalCode: "38958" },
    { lng: -90.864199, lat: 32.348565, postalCode: "39182" },
    { lng: -90.26331, lat: 32.234627, postalCode: "39212" },
    { lng: -89.438829, lat: 30.409245, postalCode: "39956" },
    { lng: -90.85634, lat: 33.145438, postalCode: "38748" },
    { lng: -89.334234, lat: 33.648956, postalCode: "39737" },
    { lng: -90.76317, lat: 31.668442, postalCode: "39668" },
    { lng: -90.00583, lat: 31.590797, postalCode: "39663" },
    { lng: -90.87184, lat: 32.292761, postalCode: "39180" },
    { lng: -89.80301, lat: 33.77132, postalCode: "38901" },
    { lng: -89.41628, lat: 33.312695, postalCode: "39745" },
    { lng: -90.36723, lat: 34.687733, postalCode: "38676" },
    { lng: -88.57746, lat: 34.774486, postalCode: "38865" },
    { lng: -89.68374, lat: 33.025946, postalCode: "39107" },
    { lng: -88.93293, lat: 30.462388, postalCode: "39532" },
    { lng: -88.87957, lat: 33.67341, postalCode: "39741" },
    { lng: -89.03949, lat: 33.779165, postalCode: "39776" },
    { lng: -90.26954, lat: 32.350688, postalCode: "39060" },
    { lng: -90.58503, lat: 32.984428, postalCode: "39097" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39058" },
    { lng: -89.89824, lat: 32.940348, postalCode: "39079" },
    { lng: -88.60228, lat: 33.243098, postalCode: "39739" },
    { lng: -88.72967, lat: 34.122101, postalCode: "38868" },
    { lng: -88.68079, lat: 34.360547, postalCode: "38866" },
    { lng: -88.88917, lat: 30.399148, postalCode: "39530" },
    { lng: -90.84485, lat: 32.386653, postalCode: "39183" },
    { lng: -89.72655, lat: 31.490798, postalCode: "39421" },
    { lng: -89.77926, lat: 31.891839, postalCode: "39112" },
    { lng: -89.38349, lat: 30.289646, postalCode: "39576" },
    { lng: -89.87039, lat: 32.503522, postalCode: "39161" },
    { lng: -89.992257, lat: 32.319905, postalCode: "39298" },
    { lng: -89.13829, lat: 34.536153, postalCode: "38650" },
    { lng: -88.46783, lat: 33.988235, postalCode: "38821" },
    { lng: -90.819547, lat: 33.624024, postalCode: "38733" },
    { lng: -90.79859, lat: 31.148177, postalCode: "39645" },
    { lng: -88.22647, lat: 34.428899, postalCode: "38847" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39289" },
    { lng: -89.33038, lat: 33.169445, postalCode: "39108" },
    { lng: -88.641888, lat: 32.431958, postalCode: "39302" },
    { lng: -88.50312, lat: 30.522009, postalCode: "39562" },
    { lng: -89.557751, lat: 30.563724, postalCode: "39463" },
    { lng: -90.214865, lat: 32.252147, postalCode: "39219" },
    { lng: -90.307122, lat: 32.540084, postalCode: "39871" },
    { lng: -90.43327, lat: 31.225598, postalCode: "39648" },
    { lng: -90.78544, lat: 34.033462, postalCode: "38740" },
    { lng: -88.634482, lat: 30.441072, postalCode: "39552" },
    { lng: -89.02557, lat: 34.04281, postalCode: "38850" },
    { lng: -90.97713, lat: 32.811181, postalCode: "39159" },
    { lng: -90.2717, lat: 34.360101, postalCode: "38623" },
    { lng: -90.73281, lat: 34.127564, postalCode: "38720" },
    { lng: -90.506861, lat: 31.245067, postalCode: "39660" },
    { lng: -90.16072, lat: 32.398142, postalCode: "39174" },
    { lng: -90.67792, lat: 31.328058, postalCode: "39664" },
    { lng: -89.37926, lat: 31.325437, postalCode: "39402" },
    { lng: -89.66509, lat: 32.313881, postalCode: "39117" },
    { lng: -89.3028, lat: 33.577688, postalCode: "39744" },
    { lng: -88.704829, lat: 31.593682, postalCode: "39324" },
    { lng: -90.490661, lat: 31.533354, postalCode: "39602" },
    { lng: -90.92611, lat: 32.804592, postalCode: "39054" },
    { lng: -90.27846, lat: 34.179708, postalCode: "38643" },
    { lng: -90.3527, lat: 33.359299, postalCode: "38946" },
    { lng: -89.39765, lat: 34.115816, postalCode: "38913" },
    { lng: -89.18351, lat: 33.879148, postalCode: "38878" },
    { lng: -90.48669, lat: 34.237759, postalCode: "38645" },
    { lng: -88.65092, lat: 30.400599, postalCode: "39553" },
    { lng: -88.89784, lat: 34.884396, postalCode: "38674" },
    { lng: -90.94339, lat: 32.984306, postalCode: "38745" },
    { lng: -89.33891, lat: 32.568112, postalCode: "39359" },
    { lng: -90.20776, lat: 31.643751, postalCode: "39665" },
    { lng: -89.294772, lat: 31.172142, postalCode: "39406" },
    { lng: -90.10215, lat: 33.875369, postalCode: "38920" },
    { lng: -90.572408, lat: 34.196894, postalCode: "38514" },
    { lng: -90.52395, lat: 33.865068, postalCode: "38737" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39286" },
    { lng: -89.49561, lat: 31.035015, postalCode: "39455" },
    { lng: -90.36285, lat: 32.762751, postalCode: "39173" },
    { lng: -90.13134, lat: 32.420814, postalCode: "39157" },
    { lng: -90.994597, lat: 33.425792, postalCode: "38702" },
    { lng: -90.45124, lat: 34.48647, postalCode: "38626" },
    { lng: -90.63213, lat: 34.22795, postalCode: "38630" },
    { lng: -88.84028, lat: 32.873605, postalCode: "39354" },
    { lng: -89.0821, lat: 32.957794, postalCode: "39346" },
    { lng: -90.29479, lat: 34.818548, postalCode: "38664" },
    { lng: -90.85022, lat: 33.260748, postalCode: "38722" },
    { lng: -89.93514, lat: 32.237742, postalCode: "39042" },
    { lng: -90.45709, lat: 32.230592, postalCode: "39154" },
    { lng: -88.74923, lat: 31.863493, postalCode: "39360" },
    { lng: -90.08221, lat: 33.114291, postalCode: "39095" },
    { lng: -88.79107, lat: 33.453349, postalCode: "39762" },
    { lng: -88.75665, lat: 34.23615, postalCode: "38801" },
    { lng: -89.60493, lat: 30.249994, postalCode: "39572" },
    { lng: -89.88781, lat: 34.514853, postalCode: "38619" },
    { lng: -88.5469, lat: 30.362203, postalCode: "39567" },
    { lng: -89.02849, lat: 33.109635, postalCode: "39339" },
    { lng: -89.11703, lat: 32.444821, postalCode: "39327" },
    { lng: -89.84901, lat: 33.523907, postalCode: "38943" },
    { lng: -89.26169, lat: 32.307815, postalCode: "39336" },
    { lng: -89.61445, lat: 34.549354, postalCode: "38685" },
    { lng: -89.375281, lat: 33.743871, postalCode: "38955" },
    { lng: -89.33827, lat: 32.330717, postalCode: "39092" },
    { lng: -89.92185, lat: 33.517885, postalCode: "38947" },
    { lng: -90.106756, lat: 34.686272, postalCode: "38602" },
    { lng: -89.57295, lat: 34.878143, postalCode: "38661" },
    { lng: -91.404404, lat: 31.470602, postalCode: "39122" },
    { lng: -88.955351, lat: 33.906827, postalCode: "38875" },
    { lng: -90.053958, lat: 33.65497, postalCode: "38912" },
    { lng: -89.2448, lat: 34.248527, postalCode: "38874" },
    { lng: -88.82383, lat: 33.450215, postalCode: "39759" },
    { lng: -90.01917, lat: 34.811777, postalCode: "38632" },
    { lng: -88.652279, lat: 32.401233, postalCode: "39303" },
    { lng: -90.17688, lat: 32.313595, postalCode: "39202" },
    { lng: -89.25982, lat: 34.975571, postalCode: "38647" },
    { lng: -89.29464, lat: 31.436305, postalCode: "39436" },
    { lng: -89.13632, lat: 33.54549, postalCode: "39752" },
    { lng: -89.03624, lat: 32.154808, postalCode: "39356" },
    { lng: -88.58797, lat: 34.082553, postalCode: "38858" },
    { lng: -90.30001, lat: 33.857538, postalCode: "38928" },
    { lng: -91.04781, lat: 33.229713, postalCode: "38723" },
    { lng: -88.899207, lat: 30.444267, postalCode: "39540" },
    { lng: -88.43297, lat: 33.581599, postalCode: "39705" },
    { lng: -90.51367, lat: 33.555234, postalCode: "38778" },
    { lng: -90.73549, lat: 33.887043, postalCode: "38762" },
    { lng: -88.91223, lat: 31.176725, postalCode: "39423" },
    { lng: -89.55099, lat: 31.881155, postalCode: "39116" },
    { lng: -88.36794, lat: 34.530559, postalCode: "38859" },
    { lng: -90.5035, lat: 34.362729, postalCode: "38617" },
    { lng: -90.17457, lat: 31.363848, postalCode: "39641" },
    { lng: -88.60093, lat: 31.162188, postalCode: "39451" },
    { lng: -90.1213, lat: 31.544331, postalCode: "39654" },
    { lng: -89.040622, lat: 31.682254, postalCode: "39442" },
    { lng: -89.50644, lat: 32.785139, postalCode: "39051" },
    { lng: -88.91687, lat: 32.315069, postalCode: "39323" },
    { lng: -89.98466, lat: 32.39705, postalCode: "39047" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39282" },
    { lng: -89.80491, lat: 32.335063, postalCode: "39145" },
    { lng: -88.23179, lat: 34.22634, postalCode: "38876" },
    { lng: -88.2092, lat: 34.513163, postalCode: "38827" },
    { lng: -89.43471, lat: 30.429334, postalCode: "39556" },
    { lng: -89.46704, lat: 33.127907, postalCode: "39067" },
    { lng: -88.77852, lat: 34.187638, postalCode: "38803" },
    { lng: -90.17182, lat: 32.365794, postalCode: "39206" },
    { lng: -88.99516, lat: 34.879287, postalCode: "38629" },
    { lng: -89.032791, lat: 34.171543, postalCode: "38820" },
    { lng: -88.23581, lat: 34.652269, postalCode: "38873" },
    { lng: -89.64228, lat: 33.936248, postalCode: "38922" },
    { lng: -89.13047, lat: 32.588341, postalCode: "39365" },
    { lng: -90.33224, lat: 32.347295, postalCode: "39056" },
    { lng: -90.16213, lat: 32.230408, postalCode: "39218" },
    { lng: -88.42703, lat: 33.743856, postalCode: "39746" },
    { lng: -89.4648, lat: 31.543388, postalCode: "39479" },
    { lng: -88.78589, lat: 32.134826, postalCode: "39363" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39236" },
    { lng: -90.292717, lat: 34.291611, postalCode: "38628" },
    { lng: -88.519743, lat: 30.71325, postalCode: "39555" },
    { lng: -90.19353, lat: 34.904881, postalCode: "38641" },
    { lng: -90.54366, lat: 33.922146, postalCode: "38738" },
    { lng: -89.56435, lat: 30.810936, postalCode: "39470" },
    { lng: -89.625197, lat: 34.164116, postalCode: "38695" },
    { lng: -89.1393, lat: 31.701492, postalCode: "39440" },
    { lng: -89.068448, lat: 30.415795, postalCode: "39533" },
    { lng: -88.832163, lat: 33.459924, postalCode: "39760" },
    { lng: -89.49821, lat: 30.403156, postalCode: "39529" },
    { lng: -88.634482, lat: 30.441072, postalCode: "39569" },
    { lng: -88.52856, lat: 34.928325, postalCode: "38834" },
    { lng: -88.6822, lat: 34.272374, postalCode: "38804" },
    { lng: -90.49849, lat: 33.65327, postalCode: "38736" },
    { lng: -90.58586, lat: 33.231628, postalCode: "38754" },
    { lng: -88.33097, lat: 33.69048, postalCode: "39704" },
    { lng: -89.27038, lat: 30.371623, postalCode: "39571" },
    { lng: -89.75296, lat: 33.002813, postalCode: "39160" },
    { lng: -88.62721, lat: 33.313523, postalCode: "39743" },
    { lng: -88.39868, lat: 34.851034, postalCode: "38846" },
    { lng: -89.9742, lat: 32.01965, postalCode: "39044" },
    { lng: -89.41533, lat: 31.816868, postalCode: "39168" },
    { lng: -89.20431, lat: 34.425389, postalCode: "38627" },
    { lng: -89.11656, lat: 32.013235, postalCode: "39460" },
    { lng: -88.92629, lat: 34.739023, postalCode: "38663" },
    { lng: -90.4588, lat: 31.028259, postalCode: "39657" },
    { lng: -89.97586, lat: 32.877746, postalCode: "39146" },
    { lng: -88.640881, lat: 33.483814, postalCode: "39753" },
    { lng: -90.88397, lat: 33.031698, postalCode: "38763" },
    { lng: -89.88636, lat: 33.973722, postalCode: "38961" },
    { lng: -90.87715, lat: 34.036412, postalCode: "38746" },
    { lng: -89.294772, lat: 31.172142, postalCode: "39407" },
    { lng: -89.64082, lat: 34.870804, postalCode: "38679" },
    { lng: -88.52234, lat: 32.420314, postalCode: "39364" },
    { lng: -89.20147, lat: 34.628795, postalCode: "38633" },
    { lng: -89.97629, lat: 34.893254, postalCode: "38651" },
    { lng: -88.76008, lat: 33.976464, postalCode: "38860" },
    { lng: -89.03762, lat: 32.026165, postalCode: "39348" },
    { lng: -90.10964, lat: 32.125628, postalCode: "39073" },
    { lng: -90.52646, lat: 33.965488, postalCode: "38768" },
    { lng: -90.76402, lat: 33.701986, postalCode: "38730" },
    { lng: -89.82958, lat: 32.791408, postalCode: "39045" },
    { lng: -90.44966, lat: 34.31465, postalCode: "38639" },
    { lng: -90.1805, lat: 34.578092, postalCode: "38665" },
    { lng: -90.21172, lat: 33.309896, postalCode: "38924" },
    { lng: -88.91588, lat: 32.042598, postalCode: "39347" },
    { lng: -90.12046, lat: 33.974998, postalCode: "38921" },
    { lng: -89.42078, lat: 32.601899, postalCode: "39189" },
    { lng: -88.521772, lat: 34.920451, postalCode: "38835" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39250" },
    { lng: -89.459526, lat: 30.239124, postalCode: "39558" },
    { lng: -89.04494, lat: 31.215709, postalCode: "39462" },
    { lng: -90.090683, lat: 32.642448, postalCode: "39130" },
    { lng: -91.12921, lat: 31.296257, postalCode: "39633" },
    { lng: -90.13765, lat: 34.172051, postalCode: "38622" },
    { lng: -91.05349, lat: 31.707145, postalCode: "39069" },
    { lng: -90.77527, lat: 33.613093, postalCode: "38773" },
    { lng: -91.088255, lat: 33.085379, postalCode: "38731" },
    { lng: -88.29002, lat: 33.924023, postalCode: "38848" },
    { lng: -90.1297, lat: 32.370544, postalCode: "39211" },
    { lng: -90.85561, lat: 33.796844, postalCode: "38764" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39271" },
    { lng: -90.71753, lat: 34.18658, postalCode: "38669" },
    { lng: -90.19915, lat: 32.308695, postalCode: "39203" },
    { lng: -88.36577, lat: 34.065563, postalCode: "38870" },
    { lng: -89.49821, lat: 30.403156, postalCode: "39522" },
    { lng: -90.34434, lat: 33.934418, postalCode: "38966" },
    { lng: -90.58537, lat: 33.344368, postalCode: "38753" },
    { lng: -90.27443, lat: 34.261893, postalCode: "38646" },
    { lng: -88.726474, lat: 32.337389, postalCode: "39309" },
    { lng: -89.20057, lat: 34.324644, postalCode: "38871" },
    { lng: -89.99793, lat: 34.96848, postalCode: "38671" },
    { lng: -88.898807, lat: 33.987089, postalCode: "38877" },
    { lng: -88.66152, lat: 32.578591, postalCode: "39326" },
    { lng: -91.0742, lat: 33.569415, postalCode: "38772" },
    { lng: -89.93268, lat: 31.458217, postalCode: "39656" },
    { lng: -90.45029, lat: 31.574319, postalCode: "39601" },
    { lng: -90.60068, lat: 33.078256, postalCode: "39115" },
    { lng: -88.43746, lat: 33.492107, postalCode: "39701" },
    { lng: -88.55463, lat: 33.833689, postalCode: "39730" },
    { lng: -89.20879, lat: 34.134456, postalCode: "38864" },
    { lng: -88.72404, lat: 33.786542, postalCode: "39756" },
    { lng: -90.79058, lat: 31.983753, postalCode: "39086" },
    { lng: -90.22778, lat: 32.284829, postalCode: "39204" },
    { lng: -89.068448, lat: 30.415795, postalCode: "39505" },
    { lng: -88.85881, lat: 31.355717, postalCode: "39476" },
    { lng: -89.17912, lat: 30.94336, postalCode: "39577" },
    { lng: -89.58558, lat: 31.376358, postalCode: "39482" },
    { lng: -89.821941, lat: 33.787157, postalCode: "38960" },
    { lng: -90.97911, lat: 31.970308, postalCode: "39150" },
    { lng: -89.068448, lat: 30.415795, postalCode: "39535" },
    { lng: -89.602572, lat: 30.258204, postalCode: "39579" },
    { lng: -90.46706, lat: 31.059186, postalCode: "39632" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39272" },
    { lng: -89.79264, lat: 31.255242, postalCode: "39429" },
    { lng: -89.27527, lat: 33.606148, postalCode: "39771" },
    { lng: -89.43745, lat: 33.859554, postalCode: "38914" },
    { lng: -90.7836, lat: 34.13452, postalCode: "38767" },
    { lng: -90.196909, lat: 33.650681, postalCode: "38945" },
    { lng: -90.35668, lat: 33.972212, postalCode: "38957" },
    { lng: -89.94635, lat: 34.607602, postalCode: "38668" },
    { lng: -90.01821, lat: 34.135098, postalCode: "38927" },
    { lng: -90.71329, lat: 33.749149, postalCode: "38732" },
    { lng: -89.03379, lat: 31.788517, postalCode: "39477" },
    { lng: -91.07877, lat: 31.825143, postalCode: "39096" },
    { lng: -90.13324, lat: 31.946357, postalCode: "39082" },
    { lng: -89.65851, lat: 31.757725, postalCode: "39119" },
    { lng: -89.794687, lat: 33.824113, postalCode: "38902" },
    { lng: -88.7494, lat: 32.358891, postalCode: "39307" },
    { lng: -89.12788, lat: 32.776368, postalCode: "39350" },
    { lng: -89.799625, lat: 32.313127, postalCode: "39288" },
    { lng: -89.87579, lat: 33.102777, postalCode: "39063" },
    { lng: -89.15818, lat: 34.839417, postalCode: "38603" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39205" },
    { lng: -88.38409, lat: 34.265985, postalCode: "38843" },
    { lng: -89.494585, lat: 32.492533, postalCode: "39080" },
    { lng: -89.10381, lat: 30.461786, postalCode: "39503" },
    { lng: -89.28431, lat: 32.486525, postalCode: "39057" },
    { lng: -90.21318, lat: 33.417028, postalCode: "38954" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39284" },
    { lng: -88.52514, lat: 30.410999, postalCode: "39563" },
    { lng: -88.81668, lat: 34.306239, postalCode: "38826" },
    { lng: -89.483865, lat: 34.358201, postalCode: "38675" },
    { lng: -90.403987, lat: 31.17494, postalCode: "39649" },
    { lng: -90.12315, lat: 34.961576, postalCode: "38680" },
    { lng: -90.17663, lat: 33.528734, postalCode: "38930" },
    { lng: -88.96219, lat: 30.405831, postalCode: "39531" },
    { lng: -89.02908, lat: 34.344618, postalCode: "38841" },
    { lng: -89.27645, lat: 31.969813, postalCode: "39422" },
    { lng: -91.05187, lat: 33.380388, postalCode: "38701" },
    { lng: -88.53326, lat: 31.574589, postalCode: "39322" },
    { lng: -88.31031, lat: 33.615277, postalCode: "39766" },
    { lng: -89.12119, lat: 31.706345, postalCode: "39940" },
    { lng: -89.89986, lat: 31.985048, postalCode: "39062" },
    { lng: -91.35434, lat: 31.535545, postalCode: "39120" },
    { lng: -89.991991, lat: 34.875277, postalCode: "38686" },
    { lng: -90.10708, lat: 32.280746, postalCode: "39208" },
    { lng: -89.23675, lat: 32.108768, postalCode: "39338" },
    { lng: -90.918503, lat: 33.253401, postalCode: "38704" },
    { lng: -90.3633, lat: 31.985052, postalCode: "39059" },
    { lng: -90.84522, lat: 31.463533, postalCode: "39630" },
    { lng: -90.42665, lat: 33.304241, postalCode: "38959" },
    { lng: -88.57968, lat: 33.230508, postalCode: "39731" },
    { lng: -90.12856, lat: 31.131018, postalCode: "39667" },
    { lng: -89.73155, lat: 33.481491, postalCode: "38967" },
    { lng: -90.11552, lat: 32.484979, postalCode: "39110" },
    { lng: -89.46705, lat: 33.463558, postalCode: "39767" },
    { lng: -89.987491, lat: 32.269041, postalCode: "39043" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39225" },
    { lng: -88.49376, lat: 34.325456, postalCode: "38855" },
    { lng: -90.31338, lat: 32.560845, postalCode: "39071" },
    { lng: -88.87292, lat: 34.413143, postalCode: "38828" },
    { lng: -89.54023, lat: 34.962714, postalCode: "38649" },
    { lng: -88.64745, lat: 33.415448, postalCode: "39736" },
    { lng: -89.075482, lat: 31.727664, postalCode: "39441" },
    { lng: -88.57464, lat: 32.985613, postalCode: "39361" },
    { lng: -89.53721, lat: 34.366302, postalCode: "38677" },
    { lng: -91.404404, lat: 31.470602, postalCode: "39165" },
    { lng: -91.04272, lat: 32.905763, postalCode: "39113" },
    { lng: -88.52901, lat: 30.36298, postalCode: "39581" },
    { lng: -89.62882, lat: 34.148775, postalCode: "38965" },
    { lng: -89.97045, lat: 31.738817, postalCode: "39140" },
    { lng: -88.58869, lat: 30.873353, postalCode: "39452" },
    { lng: -88.634482, lat: 30.441072, postalCode: "39568" },
    { lng: -89.49821, lat: 30.403156, postalCode: "39521" },
    { lng: -89.99776, lat: 33.368238, postalCode: "38923" },
    { lng: -88.76018, lat: 30.566715, postalCode: "39565" },
    { lng: -91.02984, lat: 33.442801, postalCode: "38703" },
    { lng: -89.32063, lat: 33.321345, postalCode: "39772" },
    { lng: -90.08908, lat: 32.806703, postalCode: "39179" },
    { lng: -89.068448, lat: 30.415795, postalCode: "39502" },
    { lng: -90.16305, lat: 33.90263, postalCode: "38962" },
    { lng: -89.57038, lat: 32.220895, postalCode: "39152" },
    { lng: -90.39667, lat: 31.714881, postalCode: "39191" },
    { lng: -90.07263, lat: 32.234775, postalCode: "39193" },
    { lng: -90.02263, lat: 31.231448, postalCode: "39643" },
    { lng: -90.45281, lat: 31.441613, postalCode: "39629" },
    { lng: -90.32393, lat: 33.763479, postalCode: "38944" },
    { lng: -88.9596, lat: 33.608062, postalCode: "39755" },
    { lng: -89.83579, lat: 31.49616, postalCode: "39427" },
    { lng: -89.49748, lat: 32.035356, postalCode: "39153" },
    { lng: -89.963, lat: 34.309659, postalCode: "38606" },
    { lng: -89.03998, lat: 30.397044, postalCode: "39507" },
    { lng: -88.63454, lat: 34.179513, postalCode: "38862" },
    { lng: -90.65824, lat: 33.45139, postalCode: "38751" },
    { lng: -90.607457, lat: 33.628715, postalCode: "38749" },
    { lng: -88.9952, lat: 32.527955, postalCode: "39337" },
    { lng: -90.01041, lat: 32.618475, postalCode: "39046" },
    { lng: -88.32784, lat: 34.835384, postalCode: "38833" },
    { lng: -89.3324, lat: 32.671826, postalCode: "39109" },
    { lng: -89.068448, lat: 30.415795, postalCode: "39534" },
    { lng: -89.16346, lat: 30.356147, postalCode: "39560" },
    { lng: -89.56318, lat: 33.038079, postalCode: "39090" },
    { lng: -88.58159, lat: 33.115598, postalCode: "39341" },
    { lng: -90.9791, lat: 33.780092, postalCode: "38726" },
    { lng: -88.57745, lat: 34.280996, postalCode: "38857" },
    { lng: -89.294772, lat: 31.172142, postalCode: "39404" },
    { lng: -88.77565, lat: 30.403286, postalCode: "39564" },
    { lng: -90.910404, lat: 33.409614, postalCode: "38776" },
    { lng: -90.18328, lat: 32.292396, postalCode: "39201" },
    { lng: -89.21431, lat: 31.588327, postalCode: "39437" },
    { lng: -90.061508, lat: 32.096124, postalCode: "39167" },
    { lng: -89.54482, lat: 33.748508, postalCode: "38929" },
    { lng: -88.531, lat: 31.415048, postalCode: "39362" },
    { lng: -89.02625, lat: 34.659973, postalCode: "38610" },
    { lng: -91.0273, lat: 33.628285, postalCode: "38725" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39235" },
    { lng: -88.955351, lat: 33.906827, postalCode: "38854" },
    { lng: -90.634, lat: 34.365436, postalCode: "38631" },
    { lng: -89.52414, lat: 32.753923, postalCode: "39171" },
    { lng: -90.9959, lat: 31.206278, postalCode: "39638" },
    { lng: -88.45205, lat: 34.481995, postalCode: "38856" },
    { lng: -90.26002, lat: 31.40227, postalCode: "39662" },
    { lng: -88.460083, lat: 33.51633, postalCode: "39710" },
    { lng: -88.65623, lat: 31.688901, postalCode: "39367" },
    { lng: -88.646016, lat: 32.420847, postalCode: "39304" },
    { lng: -89.72086, lat: 31.880229, postalCode: "39111" },
    { lng: -89.99117, lat: 32.057964, postalCode: "39148" },
    { lng: -89.47412, lat: 34.489297, postalCode: "38601" },
    { lng: -91.05179, lat: 33.028345, postalCode: "38744" },
    { lng: -88.99973, lat: 34.489085, postalCode: "38652" },
    { lng: -89.99349, lat: 33.742886, postalCode: "38940" },
    { lng: -90.17798, lat: 31.869093, postalCode: "39078" },
    { lng: -90.47024, lat: 32.39336, postalCode: "39041" },
    { lng: -88.6596, lat: 32.339004, postalCode: "39301" },
    { lng: -90.64376, lat: 32.629834, postalCode: "39162" },
    { lng: -89.36375, lat: 34.005229, postalCode: "38915" },
    { lng: -89.11948, lat: 30.600244, postalCode: "39574" },
    { lng: -88.25935, lat: 34.552421, postalCode: "38838" },
    { lng: -90.208088, lat: 32.297396, postalCode: "39217" },
    { lng: -89.36958, lat: 30.382544, postalCode: "39525" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39207" },
    { lng: -90.72406, lat: 33.822471, postalCode: "38759" },
    { lng: -90.27615, lat: 34.417393, postalCode: "38670" },
    { lng: -89.70928, lat: 32.56195, postalCode: "39098" },
    { lng: -89.17616, lat: 33.371974, postalCode: "39735" },
    { lng: -90.84298, lat: 33.075557, postalCode: "39061" },
    { lng: -91.404404, lat: 31.470602, postalCode: "39121" },
    { lng: -90.04093, lat: 34.959171, postalCode: "38637" },
    { lng: -90.3243, lat: 33.492412, postalCode: "38941" },
    { lng: -89.46552, lat: 32.379693, postalCode: "39074" },
    { lng: -89.15395, lat: 30.704034, postalCode: "39561" },
    { lng: -88.53751, lat: 34.653448, postalCode: "38829" },
    { lng: -89.07609, lat: 33.596326, postalCode: "39750" },
    { lng: -90.371546, lat: 33.969302, postalCode: "38597" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39283" },
    { lng: -89.50735, lat: 34.345016, postalCode: "38655" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39072" },
    { lng: -89.40705, lat: 30.304327, postalCode: "39520" },
    { lng: -90.42995, lat: 31.841281, postalCode: "39083" },
    { lng: -89.11948, lat: 31.719947, postalCode: "39443" },
    { lng: -90.37392, lat: 34.275423, postalCode: "38609" },
    { lng: -91.050948, lat: 31.739958, postalCode: "39081" },
    { lng: -90.80341, lat: 31.834496, postalCode: "39144" },
    { lng: -90.2106, lat: 32.356489, postalCode: "39213" },
    { lng: -89.90761, lat: 31.218509, postalCode: "39483" },
    { lng: -89.83743, lat: 34.954106, postalCode: "38654" },
    { lng: -90.4497, lat: 32.855811, postalCode: "39194" },
    { lng: -88.72639, lat: 32.535472, postalCode: "39320" },
    { lng: -89.32561, lat: 33.839389, postalCode: "38916" },
    { lng: -88.67217, lat: 34.443919, postalCode: "38849" },
    { lng: -88.67069, lat: 32.057969, postalCode: "39355" },
    { lng: -89.99076, lat: 31.852006, postalCode: "39149" },
    { lng: -90.53427, lat: 33.732774, postalCode: "38771" },
    { lng: -89.89819, lat: 34.082634, postalCode: "38948" },
    { lng: -91.404404, lat: 31.470602, postalCode: "39190" },
    { lng: -90.40856, lat: 32.639989, postalCode: "39040" },
    { lng: -89.733, lat: 33.317882, postalCode: "39176" },
    { lng: -89.76284, lat: 33.194928, postalCode: "39192" },
    { lng: -90.87866, lat: 33.407212, postalCode: "38756" },
    { lng: -89.036193, lat: 33.344664, postalCode: "39758" },
    { lng: -88.616297, lat: 32.336743, postalCode: "39342" },
    { lng: -89.86086, lat: 31.605946, postalCode: "39474" },
    { lng: -90.89208, lat: 31.461337, postalCode: "39653" },
    { lng: -90.51041, lat: 34.095627, postalCode: "38758" },
    { lng: -89.46092, lat: 34.762615, postalCode: "38635" },
    { lng: -89.09626, lat: 30.380597, postalCode: "39501" },
    { lng: -88.634482, lat: 30.441072, postalCode: "39595" },
    { lng: -88.70918, lat: 32.439945, postalCode: "39305" },
    { lng: -89.00323, lat: 31.86883, postalCode: "39439" },
    { lng: -90.88093, lat: 33.063312, postalCode: "38765" },
    { lng: -88.462173, lat: 33.869763, postalCode: "38825" },
    { lng: -88.51801, lat: 32.517145, postalCode: "39335" },
    { lng: -89.65929, lat: 30.641521, postalCode: "39426" },
    { lng: -90.25709, lat: 32.325512, postalCode: "39209" },
    { lng: -89.776, lat: 32.08065, postalCode: "39151" },
    { lng: -90.47061, lat: 34.449792, postalCode: "38644" },
    { lng: -89.01361, lat: 33.333797, postalCode: "39769" },
    { lng: -90.090683, lat: 32.642448, postalCode: "39163" },
    { lng: -89.382415, lat: 31.356642, postalCode: "39403" },
    { lng: -89.6311, lat: 32.595179, postalCode: "39094" },
    { lng: -89.30308, lat: 33.968398, postalCode: "38951" },
    { lng: -90.17902, lat: 32.325012, postalCode: "39210" },
    { lng: -89.303214, lat: 33.856165, postalCode: "38839" },
    { lng: -90.50034, lat: 33.062845, postalCode: "39166" },
    { lng: -89.85553, lat: 31.054236, postalCode: "39478" },
    { lng: -90.39276, lat: 34.087461, postalCode: "38964" },
    { lng: -90.27065, lat: 33.136434, postalCode: "39169" },
    { lng: -89.2799, lat: 31.285508, postalCode: "39401" },
    { lng: -89.14483, lat: 32.317298, postalCode: "39345" },
    { lng: -88.83252, lat: 34.597573, postalCode: "38625" },
    { lng: -90.864199, lat: 32.348565, postalCode: "39181" },
    { lng: -89.601, lat: 34.279282, postalCode: "38673" },
    { lng: -88.48707, lat: 32.838485, postalCode: "39358" },
    { lng: -90.73846, lat: 32.753234, postalCode: "39088" },
    { lng: -88.69959, lat: 32.744445, postalCode: "39328" },
    { lng: -88.30989, lat: 33.752213, postalCode: "39740" },
    { lng: -88.634482, lat: 30.441072, postalCode: "39566" },
    { lng: -89.56261, lat: 33.413657, postalCode: "39747" },
    { lng: -89.95004, lat: 34.699588, postalCode: "38618" },
    { lng: -89.828798, lat: 34.695853, postalCode: "38638" },
    { lng: -89.09164, lat: 31.059327, postalCode: "39425" },
    { lng: -90.76417, lat: 33.947701, postalCode: "38774" },
    { lng: -90.18859, lat: 32.300996, postalCode: "39269" },
    { lng: -90.27757, lat: 33.523355, postalCode: "38935" },
    { lng: -90.69788, lat: 31.509919, postalCode: "39647" },
    { lng: -89.09325, lat: 30.710795, postalCode: "39573" },
    { lng: -89.41323, lat: 31.165903, postalCode: "39475" },
    { lng: -89.68014, lat: 34.862416, postalCode: "38611" },
    { lng: -90.99667, lat: 33.454082, postalCode: "38760" },
    { lng: -89.65893, lat: 33.645396, postalCode: "38925" },
    { lng: -90.446702, lat: 31.612287, postalCode: "39603" },
    { lng: -89.9363, lat: 33.534708, postalCode: "38917" },
    { lng: -89.05842, lat: 31.487531, postalCode: "39464" },
    { lng: -88.72093, lat: 34.188301, postalCode: "38879" },
    { lng: -90.737149, lat: 33.887774, postalCode: "38781" },
    { lng: -89.05064, lat: 33.702318, postalCode: "39751" },
    { lng: -88.283998, lat: 33.484147, postalCode: "39703" },
    { lng: -90.397157, lat: 32.311287, postalCode: "39296" },
    { lng: -90.45287, lat: 31.117097, postalCode: "39652" },
    { lng: -90.17154, lat: 34.45965, postalCode: "38621" },
    { lng: -90.090683, lat: 32.642448, postalCode: "39158" },
    { lng: -97.63549, lat: 46.098941, postalCode: "58032" },
    { lng: -101.37668, lat: 47.494559, postalCode: "58565" },
    { lng: -99.56198, lat: 46.278442, postalCode: "58495" },
    { lng: -99.211615, lat: 47.799663, postalCode: "58371" },
    { lng: -101.70175, lat: 48.466756, postalCode: "58725" },
    { lng: -100.90891, lat: 46.812148, postalCode: "58554" },
    { lng: -96.823, lat: 46.581114, postalCode: "58015" },
    { lng: -98.699553, lat: 46.913344, postalCode: "58405" },
    { lng: -99.61665, lat: 48.636915, postalCode: "58353" },
    { lng: -101.04618, lat: 47.302682, postalCode: "58577" },
    { lng: -97.44741, lat: 48.714076, postalCode: "58236" },
    { lng: -102.08301, lat: 48.719925, postalCode: "58746" },
    { lng: -99.07241, lat: 47.839733, postalCode: "58374" },
    { lng: -97.69767, lat: 48.303376, postalCode: "58273" },
    { lng: -98.33378, lat: 48.303578, postalCode: "58345" },
    { lng: -98.25077, lat: 47.092093, postalCode: "58479" },
    { lng: -101.49673, lat: 47.869756, postalCode: "58735" },
    { lng: -99.31052, lat: 47.153361, postalCode: "58496" },
    { lng: -97.05327, lat: 47.389215, postalCode: "58045" },
    { lng: -98.2122, lat: 47.31361, postalCode: "58448" },
    { lng: -97.80427, lat: 47.475797, postalCode: "58230" },
    { lng: -99.52317, lat: 47.955217, postalCode: "58348" },
    { lng: -98.375536, lat: 46.892518, postalCode: "58432" },
    { lng: -99.27476, lat: 46.052308, postalCode: "58413" },
    { lng: -100.00991, lat: 48.273263, postalCode: "58368" },
    { lng: -99.77766, lat: 48.058376, postalCode: "58332" },
    { lng: -100.82468, lat: 48.269326, postalCode: "58741" },
    { lng: -98.12379, lat: 48.052404, postalCode: "58259" },
    { lng: -98.11584, lat: 46.090506, postalCode: "58474" },
    { lng: -98.5889, lat: 47.583586, postalCode: "58464" },
    { lng: -98.13662, lat: 47.201159, postalCode: "58429" },
    { lng: -97.29392, lat: 47.490878, postalCode: "58257" },
    { lng: -98.97328, lat: 47.189716, postalCode: "58476" },
    { lng: -97.48511, lat: 47.631, postalCode: "58240" },
    { lng: -103.32304, lat: 48.889528, postalCode: "58730" },
    { lng: -97.08401, lat: 47.938898, postalCode: "58203" },
    { lng: -101.53884, lat: 48.676056, postalCode: "58764" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58126" },
    { lng: -101.15787, lat: 48.686244, postalCode: "58760" },
    { lng: -100.76841, lat: 47.971295, postalCode: "58792" },
    { lng: -101.28912, lat: 48.94424, postalCode: "58711" },
    { lng: -97.66385, lat: 48.515762, postalCode: "58243" },
    { lng: -97.45571, lat: 47.020312, postalCode: "58007" },
    { lng: -98.87272, lat: 48.696001, postalCode: "58352" },
    { lng: -97.85092, lat: 46.232799, postalCode: "58069" },
    { lng: -100.87466, lat: 47.543706, postalCode: "58575" },
    { lng: -98.05162, lat: 48.622954, postalCode: "58260" },
    { lng: -100.29283, lat: 46.488312, postalCode: "58544" },
    { lng: -98.56277, lat: 48.088486, postalCode: "58327" },
    { lng: -96.88178, lat: 46.27338, postalCode: "58061" },
    { lng: -100.41403, lat: 48.864955, postalCode: "58318" },
    { lng: -97.4149, lat: 48.782885, postalCode: "58238" },
    { lng: -98.23678, lat: 48.497876, postalCode: "58229" },
    { lng: -98.87752, lat: 48.122688, postalCode: "58301" },
    { lng: -100.78131, lat: 46.82126, postalCode: "58505" },
    { lng: -97.49066, lat: 47.930777, postalCode: "58214" },
    { lng: -98.48404, lat: 46.362812, postalCode: "58415" },
    { lng: -101.60981, lat: 48.171782, postalCode: "58733" },
    { lng: -103.15693, lat: 48.64055, postalCode: "58795" },
    { lng: -98.66921, lat: 48.950929, postalCode: "58239" },
    { lng: -97.57406, lat: 45.979819, postalCode: "58043" },
    { lng: -100.5398, lat: 47.229815, postalCode: "58477" },
    { lng: -103.39021, lat: 48.278037, postalCode: "58843" },
    { lng: -98.43993, lat: 47.154922, postalCode: "58492" },
    { lng: -99.93796, lat: 46.467732, postalCode: "58549" },
    { lng: -101.83368, lat: 46.759443, postalCode: "58631" },
    { lng: -103.71711, lat: 48.567291, postalCode: "58856" },
    { lng: -102.60078, lat: 48.973939, postalCode: "58772" },
    { lng: -96.66927, lat: 46.043951, postalCode: "58030" },
    { lng: -102.94589, lat: 48.388509, postalCode: "58852" },
    { lng: -100.520063, lat: 46.980475, postalCode: "58507" },
    { lng: -101.15667, lat: 47.446392, postalCode: "58576" },
    { lng: -97.52498, lat: 48.228707, postalCode: "58233" },
    { lng: -97.99703, lat: 47.694, postalCode: "58212" },
    { lng: -97.82544, lat: 48.214268, postalCode: "58231" },
    { lng: -97.85041, lat: 48.000075, postalCode: "58266" },
    { lng: -98.51502, lat: 47.836237, postalCode: "58380" },
    { lng: -97.35424, lat: 48.008825, postalCode: "58258" },
    { lng: -97.60401, lat: 47.151891, postalCode: "58064" },
    { lng: -98.56496, lat: 46.770483, postalCode: "58497" },
    { lng: -100.48694, lat: 47.483877, postalCode: "58463" },
    { lng: -99.58558, lat: 48.855873, postalCode: "58367" },
    { lng: -97.30774, lat: 48.204374, postalCode: "58213" },
    { lng: -98.6171, lat: 48.855352, postalCode: "58281" },
    { lng: -96.90503, lat: 46.875367, postalCode: "58078" },
    { lng: -99.40469, lat: 47.435936, postalCode: "58486" },
    { lng: -102.76645, lat: 48.298407, postalCode: "58794" },
    { lng: -98.31033, lat: 48.811921, postalCode: "58249" },
    { lng: -98.86913, lat: 47.994379, postalCode: "58370" },
    { lng: -98.89748, lat: 46.57995, postalCode: "58454" },
    { lng: -100.93017, lat: 46.421762, postalCode: "58570" },
    { lng: -98.960032, lat: 46.97882, postalCode: "58402" },
    { lng: -96.73165, lat: 46.448462, postalCode: "58001" },
    { lng: -96.608142, lat: 46.271839, postalCode: "58076" },
    { lng: -99.76388, lat: 48.837507, postalCode: "58316" },
    { lng: -101.31942, lat: 48.277024, postalCode: "58703" },
    { lng: -97.95766, lat: 48.173113, postalCode: "58224" },
    { lng: -99.84416, lat: 47.767862, postalCode: "58341" },
    { lng: -97.07941, lat: 46.729689, postalCode: "58021" },
    { lng: -101.81935, lat: 48.76715, postalCode: "58787" },
    { lng: -100.98409, lat: 48.422289, postalCode: "58731" },
    { lng: -98.230415, lat: 47.456477, postalCode: "58452" },
    { lng: -97.67678, lat: 46.43383, postalCode: "58054" },
    { lng: -101.22156, lat: 48.485288, postalCode: "58740" },
    { lng: -100.53101, lat: 46.813571, postalCode: "58558" },
    { lng: -102.13973, lat: 47.927133, postalCode: "58770" },
    { lng: -100.80621, lat: 48.567655, postalCode: "58789" },
    { lng: -99.16654, lat: 48.272135, postalCode: "58325" },
    { lng: -97.13477, lat: 46.282064, postalCode: "58081" },
    { lng: -97.67144, lat: 46.767912, postalCode: "58031" },
    { lng: -97.352437, lat: 46.775027, postalCode: "58014" },
    { lng: -100.23833, lat: 46.257466, postalCode: "58552" },
    { lng: -99.61847, lat: 46.0327, postalCode: "58489" },
    { lng: -98.48352, lat: 46.522016, postalCode: "58431" },
    { lng: -100.69257, lat: 47.785232, postalCode: "58723" },
    { lng: -100.72662, lat: 47.429223, postalCode: "58559" },
    { lng: -99.9205, lat: 46.862714, postalCode: "58482" },
    { lng: -97.22272, lat: 48.593133, postalCode: "58225" },
    { lng: -101.451285, lat: 48.336898, postalCode: "58702" },
    { lng: -97.75338, lat: 47.293363, postalCode: "58046" },
    { lng: -99.8055, lat: 48.937584, postalCode: "58369" },
    { lng: -97.44507, lat: 46.253432, postalCode: "58060" },
    { lng: -97.66923, lat: 46.214772, postalCode: "58040" },
    { lng: -98.75318, lat: 47.290251, postalCode: "58455" },
    { lng: -101.4391, lat: 46.909229, postalCode: "58563" },
    { lng: -97.02012, lat: 46.634764, postalCode: "58051" },
    { lng: -100.03576, lat: 46.05082, postalCode: "58542" },
    { lng: -97.59021, lat: 48.956342, postalCode: "58265" },
    { lng: -100.10694, lat: 46.86485, postalCode: "58532" },
    { lng: -96.8887, lat: 47.472415, postalCode: "58219" },
    { lng: -100.09497, lat: 46.596608, postalCode: "58524" },
    { lng: -99.91768, lat: 48.653781, postalCode: "58366" },
    { lng: -100.28185, lat: 46.677294, postalCode: "58560" },
    { lng: -97.90612, lat: 48.527038, postalCode: "58227" },
    { lng: -102.27362, lat: 48.811975, postalCode: "58721" },
    { lng: -100.51007, lat: 47.808989, postalCode: "58747" },
    { lng: -99.67497, lat: 48.470278, postalCode: "58385" },
    { lng: -100.96154, lat: 48.671806, postalCode: "58762" },
    { lng: -103.51215, lat: 48.894491, postalCode: "58833" },
    { lng: -96.91067, lat: 46.052141, postalCode: "58041" },
    { lng: -98.06074, lat: 46.368093, postalCode: "58490" },
    { lng: -101.304214, lat: 47.612579, postalCode: "58888" },
    { lng: -99.09558, lat: 48.223375, postalCode: "58362" },
    { lng: -98.20015, lat: 46.654622, postalCode: "58461" },
    { lng: -97.8179, lat: 47.608142, postalCode: "58277" },
    { lng: -97.38881, lat: 47.118212, postalCode: "58029" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58121" },
    { lng: -101.52739, lat: 47.646187, postalCode: "58540" },
    { lng: -100.71786, lat: 47.03541, postalCode: "58521" },
    { lng: -98.72826, lat: 46.906983, postalCode: "58401" },
    { lng: -99.02831, lat: 47.968523, postalCode: "58335" },
    { lng: -96.89354, lat: 46.437401, postalCode: "58018" },
    { lng: -96.81602, lat: 46.860864, postalCode: "58103" },
    { lng: -98.58545, lat: 47.227781, postalCode: "58426" },
    { lng: -97.27924, lat: 48.943042, postalCode: "58271" },
    { lng: -102.4235, lat: 48.366981, postalCode: "58784" },
    { lng: -97.80989, lat: 46.059066, postalCode: "58017" },
    { lng: -98.02374, lat: 46.934311, postalCode: "58072" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58123" },
    { lng: -100.27173, lat: 47.581276, postalCode: "58430" },
    { lng: -100.47477, lat: 48.363285, postalCode: "58788" },
    { lng: -96.80016, lat: 46.900348, postalCode: "58102" },
    { lng: -97.121, lat: 47.67933, postalCode: "58275" },
    { lng: -101.72643, lat: 48.944324, postalCode: "58782" },
    { lng: -97.394446, lat: 47.933481, postalCode: "58206" },
    { lng: -100.63867, lat: 46.249361, postalCode: "58528" },
    { lng: -98.34567, lat: 47.555709, postalCode: "58416" },
    { lng: -97.6003, lat: 46.630659, postalCode: "58027" },
    { lng: -98.17672, lat: 48.695247, postalCode: "58269" },
    { lng: -101.96379, lat: 48.506446, postalCode: "58734" },
    { lng: -97.05906, lat: 47.505171, postalCode: "58223" },
    { lng: -98.29376, lat: 46.367889, postalCode: "58458" },
    { lng: -100.26587, lat: 48.609924, postalCode: "58384" },
    { lng: -98.83181, lat: 46.014472, postalCode: "58439" },
    { lng: -97.47238, lat: 48.084335, postalCode: "58235" },
    { lng: -99.42744, lat: 48.742513, postalCode: "58363" },
    { lng: -97.81827, lat: 46.947243, postalCode: "58063" },
    { lng: -99.59878, lat: 48.3223, postalCode: "58386" },
    { lng: -99.84212, lat: 47.49417, postalCode: "58423" },
    { lng: -102.65064, lat: 48.574749, postalCode: "58773" },
    { lng: -97.28711, lat: 48.288316, postalCode: "58261" },
    { lng: -100.30355, lat: 46.885406, postalCode: "58572" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58124" },
    { lng: -101.19002, lat: 47.575888, postalCode: "58531" },
    { lng: -101.28579, lat: 48.19983, postalCode: "58701" },
    { lng: -99.61938, lat: 47.652764, postalCode: "58438" },
    { lng: -99.26643, lat: 48.84741, postalCode: "58365" },
    { lng: -100.93332, lat: 46.59174, postalCode: "58566" },
    { lng: -101.69363, lat: 48.986763, postalCode: "58982" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58122" },
    { lng: -97.99298, lat: 48.013283, postalCode: "58272" },
    { lng: -97.67455, lat: 47.942137, postalCode: "58251" },
    { lng: -102.54126, lat: 47.983034, postalCode: "58763" },
    { lng: -97.394446, lat: 47.933481, postalCode: "58208" },
    { lng: -101.04936, lat: 48.871815, postalCode: "58793" },
    { lng: -99.13151, lat: 46.310458, postalCode: "58440" },
    { lng: -103.22565, lat: 47.835713, postalCode: "58854" },
    { lng: -102.0497, lat: 46.877355, postalCode: "58638" },
    { lng: -97.07228, lat: 47.920679, postalCode: "58202" },
    { lng: -101.81878, lat: 48.327365, postalCode: "58718" },
    { lng: -97.12241, lat: 46.839654, postalCode: "58059" },
    { lng: -97.394446, lat: 47.933481, postalCode: "58207" },
    { lng: -97.81842, lat: 46.669107, postalCode: "58062" },
    { lng: -100.63594, lat: 48.552669, postalCode: "58713" },
    { lng: -101.11174, lat: 48.271451, postalCode: "58785" },
    { lng: -100.13717, lat: 47.480027, postalCode: "58444" },
    { lng: -99.39898, lat: 46.631342, postalCode: "58483" },
    { lng: -98.72145, lat: 46.366866, postalCode: "58433" },
    { lng: -101.53747, lat: 46.657221, postalCode: "58520" },
    { lng: -98.63842, lat: 47.435273, postalCode: "58443" },
    { lng: -97.63321, lat: 48.144073, postalCode: "58244" },
    { lng: -98.13417, lat: 47.446297, postalCode: "58425" },
    { lng: -101.26056, lat: 47.855591, postalCode: "58759" },
    { lng: -102.23239, lat: 48.330437, postalCode: "58769" },
    { lng: -100.75381, lat: 46.768617, postalCode: "58504" },
    { lng: -98.641, lat: 48.530196, postalCode: "58338" },
    { lng: -97.35564, lat: 47.882652, postalCode: "58228" },
    { lng: -98.16357, lat: 47.765244, postalCode: "58254" },
    { lng: -97.49872, lat: 46.072821, postalCode: "58067" },
    { lng: -100.93425, lat: 48.053192, postalCode: "58790" },
    { lng: -98.35241, lat: 48.999101, postalCode: "58255" },
    { lng: -97.69893, lat: 48.710208, postalCode: "58241" },
    { lng: -96.96482, lat: 47.15096, postalCode: "58036" },
    { lng: -98.36827, lat: 48.591846, postalCode: "58355" },
    { lng: -99.99415, lat: 47.177348, postalCode: "58488" },
    { lng: -99.32181, lat: 46.309847, postalCode: "58460" },
    { lng: -98.80594, lat: 47.54777, postalCode: "58445" },
    { lng: -97.43763, lat: 46.564908, postalCode: "58068" },
    { lng: -101.08118, lat: 47.82781, postalCode: "58716" },
    { lng: -101.4194, lat: 48.611257, postalCode: "58750" },
    { lng: -97.426, lat: 47.25098, postalCode: "58035" },
    { lng: -99.9372, lat: 47.456626, postalCode: "58451" },
    { lng: -97.4096, lat: 48.415911, postalCode: "58237" },
    { lng: -97.28313, lat: 46.650902, postalCode: "58052" },
    { lng: -99.566, lat: 47.149515, postalCode: "58475" },
    { lng: -97.21831, lat: 47.198818, postalCode: "58048" },
    { lng: -97.37255, lat: 47.943847, postalCode: "58204" },
    { lng: -96.95043, lat: 47.057098, postalCode: "58005" },
    { lng: -96.98251, lat: 46.167324, postalCode: "58058" },
    { lng: -101.301179, lat: 48.245267, postalCode: "58707" },
    { lng: -98.58044, lat: 46.067077, postalCode: "58436" },
    { lng: -102.62331, lat: 48.24767, postalCode: "58776" },
    { lng: -98.44052, lat: 47.399657, postalCode: "58484" },
    { lng: -100.53158, lat: 47.985515, postalCode: "58712" },
    { lng: -102.58745, lat: 47.702563, postalCode: "58757" },
    { lng: -97.394446, lat: 47.933481, postalCode: "58205" },
    { lng: -100.681855, lat: 46.8887, postalCode: "58502" },
    { lng: -99.427769, lat: 48.900063, postalCode: "58339" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58107" },
    { lng: -96.99819, lat: 46.304176, postalCode: "58008" },
    { lng: -97.67862, lat: 48.62293, postalCode: "58222" },
    { lng: -96.8728, lat: 46.724294, postalCode: "58047" },
    { lng: -96.8148, lat: 46.82352, postalCode: "58109" },
    { lng: -101.81113, lat: 47.962814, postalCode: "58756" },
    { lng: -100.23677, lat: 47.87756, postalCode: "58710" },
    { lng: -99.74552, lat: 47.135224, postalCode: "58478" },
    { lng: -103.46564, lat: 47.73943, postalCode: "58835" },
    { lng: -101.18309, lat: 47.133382, postalCode: "58530" },
    { lng: -98.25936, lat: 46.92853, postalCode: "58480" },
    { lng: -100.27279, lat: 47.141711, postalCode: "58494" },
    { lng: -97.07503, lat: 47.592267, postalCode: "58218" },
    { lng: -103.924225, lat: 48.843926, postalCode: "58832" },
    { lng: -99.83606, lat: 46.014164, postalCode: "58581" },
    { lng: -100.6606, lat: 48.687522, postalCode: "58748" },
    { lng: -99.02227, lat: 48.947387, postalCode: "58372" },
    { lng: -100.1644, lat: 48.091057, postalCode: "58359" },
    { lng: -100.520063, lat: 46.980475, postalCode: "58506" },
    { lng: -103.65916, lat: 47.826449, postalCode: "58831" },
    { lng: -99.11415, lat: 48.65545, postalCode: "58331" },
    { lng: -100.94635, lat: 46.155222, postalCode: "58568" },
    { lng: -103.614824, lat: 48.168836, postalCode: "58802" },
    { lng: -98.90525, lat: 48.849591, postalCode: "58323" },
    { lng: -100.93423, lat: 47.783028, postalCode: "58778" },
    { lng: -97.25077, lat: 47.331202, postalCode: "58009" },
    { lng: -100.76402, lat: 48.900962, postalCode: "58783" },
    { lng: -99.08527, lat: 47.450049, postalCode: "58421" },
    { lng: -97.46372, lat: 47.34623, postalCode: "58016" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58125" },
    { lng: -96.967371, lat: 46.9414, postalCode: "58105" },
    { lng: -97.54484, lat: 46.920174, postalCode: "58011" },
    { lng: -98.195722, lat: 46.650741, postalCode: "58065" },
    { lng: -96.93756, lat: 46.533488, postalCode: "58077" },
    { lng: -99.22269, lat: 47.931081, postalCode: "58357" },
    { lng: -99.29718, lat: 48.100632, postalCode: "58351" },
    { lng: -101.75965, lat: 47.229528, postalCode: "58523" },
    { lng: -103.75867, lat: 48.894636, postalCode: "58844" },
    { lng: -98.85843, lat: 47.073601, postalCode: "58420" },
    { lng: -103.46708, lat: 48.586475, postalCode: "58830" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58108" },
    { lng: -99.63946, lat: 47.42157, postalCode: "58418" },
    { lng: -100.72114, lat: 46.062798, postalCode: "58538" },
    { lng: -99.1616, lat: 46.596847, postalCode: "58442" },
    { lng: -101.274388, lat: 48.226422, postalCode: "58000" },
    { lng: -97.86695, lat: 48.689123, postalCode: "58262" },
    { lng: -99.71689, lat: 46.477491, postalCode: "58561" },
    { lng: -98.38912, lat: 46.163828, postalCode: "58441" },
    { lng: -97.35562, lat: 46.076891, postalCode: "58013" },
    { lng: -98.31437, lat: 48.204745, postalCode: "58321" },
    { lng: -100.630803, lat: 46.084899, postalCode: "58358" },
    { lng: -98.79973, lat: 48.318106, postalCode: "58382" },
    { lng: -101.86084, lat: 47.811566, postalCode: "58779" },
    { lng: -100.0384, lat: 48.165299, postalCode: "58313" },
    { lng: -97.25029, lat: 47.028291, postalCode: "58004" },
    { lng: -97.24848, lat: 46.451477, postalCode: "58057" },
    { lng: -99.37707, lat: 48.628607, postalCode: "58317" },
    { lng: -97.68708, lat: 46.930679, postalCode: "58071" },
    { lng: -98.52492, lat: 48.427738, postalCode: "58330" },
    { lng: -96.80909, lat: 46.153098, postalCode: "58039" },
    { lng: -97.05896, lat: 47.899217, postalCode: "58201" },
    { lng: -98.36911, lat: 46.611528, postalCode: "58466" },
    { lng: -98.61192, lat: 48.637939, postalCode: "58311" },
    { lng: -101.24737, lat: 46.501898, postalCode: "58535" },
    { lng: -102.90911, lat: 47.8845, postalCode: "58847" },
    { lng: -99.10553, lat: 47.669608, postalCode: "58356" },
    { lng: -100.78026, lat: 46.861794, postalCode: "58503" },
    { lng: -97.59279, lat: 47.76354, postalCode: "58267" },
    { lng: -100.61537, lat: 48.102064, postalCode: "58744" },
    { lng: -103.71908, lat: 48.20496, postalCode: "58801" },
    { lng: -99.70744, lat: 48.311156, postalCode: "58343" },
    { lng: -100.38123, lat: 47.916124, postalCode: "58736" },
    { lng: -98.93292, lat: 46.270766, postalCode: "58456" },
    { lng: -98.82459, lat: 47.934575, postalCode: "58379" },
    { lng: -100.03636, lat: 48.855835, postalCode: "58329" },
    { lng: -103.03833, lat: 48.857983, postalCode: "58765" },
    { lng: -101.55153, lat: 48.770684, postalCode: "58761" },
    { lng: -102.40401, lat: 48.917722, postalCode: "58737" },
    { lng: -99.2076, lat: 48.485321, postalCode: "58324" },
    { lng: -96.917614, lat: 46.283291, postalCode: "58074" },
    { lng: -101.84406, lat: 47.710089, postalCode: "58775" },
    { lng: -98.4288, lat: 46.947101, postalCode: "58481" },
    { lng: -99.42675, lat: 48.286717, postalCode: "58346" },
    { lng: -100.24342, lat: 46.094998, postalCode: "58573" },
    { lng: -98.984266, lat: 48.10066, postalCode: "58300" },
    { lng: -100.76, lat: 47.178883, postalCode: "58579" },
    { lng: -97.18939, lat: 46.912684, postalCode: "58012" },
    { lng: -99.11315, lat: 46.888946, postalCode: "58424" },
    { lng: -98.74895, lat: 47.840223, postalCode: "58381" },
    { lng: -97.18994, lat: 48.083218, postalCode: "58256" },
    { lng: -97.69887, lat: 48.797042, postalCode: "58220" },
    { lng: -101.297161, lat: 48.234184, postalCode: "58705" },
    { lng: -101.09637, lat: 48.037844, postalCode: "58781" },
    { lng: -102.80485, lat: 48.844129, postalCode: "58727" },
    { lng: -97.35616, lat: 46.883504, postalCode: "58079" },
    { lng: -101.47331, lat: 48.251187, postalCode: "58722" },
    { lng: -103.19462, lat: 48.326813, postalCode: "58849" },
    { lng: -97.18736, lat: 46.067238, postalCode: "58053" },
    { lng: -99.60568, lat: 46.856925, postalCode: "58487" },
    { lng: -103.84342, lat: 48.069085, postalCode: "58853" },
    { lng: -101.9763, lat: 48.073998, postalCode: "58771" },
    { lng: -98.33502, lat: 48.021136, postalCode: "58344" },
    { lng: -99.65457, lat: 47.580254, postalCode: "58333" },
    { lng: -97.214587, lat: 46.859694, postalCode: "58002" },
    { lng: -98.63923, lat: 46.638134, postalCode: "58472" },
    { lng: -96.98522, lat: 47.251489, postalCode: "58038" },
    { lng: -97.2147, lat: 47.10167, postalCode: "58006" },
    { lng: -100.40137, lat: 46.814568, postalCode: "58553" },
    { lng: -98.33975, lat: 47.75436, postalCode: "58361" },
    { lng: -97.98732, lat: 46.657292, postalCode: "58049" },
    { lng: -96.68494, lat: 46.28232, postalCode: "58075" },
    { lng: -97.11074, lat: 47.772417, postalCode: "58278" },
    { lng: -97.504912, lat: 48.528451, postalCode: "58293" },
    { lng: -102.93658, lat: 48.632655, postalCode: "58755" },
    { lng: -102.57917, lat: 48.845965, postalCode: "58752" },
    { lng: -99.33125, lat: 46.867794, postalCode: "58467" },
    { lng: -99.42568, lat: 47.609264, postalCode: "58422" },
    { lng: -96.9327, lat: 46.984696, postalCode: "58042" },
    { lng: -97.91961, lat: 46.449141, postalCode: "58033" },
    { lng: -97.93864, lat: 47.252754, postalCode: "58056" },
    { lng: -101.3274, lat: 48.41647, postalCode: "58704" },
    { lng: -98.00082, lat: 48.288615, postalCode: "58250" },
    { lng: -100.7723, lat: 46.83962, postalCode: "58501" },
    { lng: -98.11173, lat: 48.391059, postalCode: "58210" },
    { lng: -99.667737, lat: 47.587372, postalCode: "58337" },
    { lng: -97.46343, lat: 47.491179, postalCode: "58274" },
    { lng: -100.10165, lat: 47.781908, postalCode: "58758" },
    { lng: -99.664406, lat: 47.663508, postalCode: "58319" },
    { lng: -99.404705, lat: 48.28641, postalCode: "58320" },
    { lng: -97.78025, lat: 48.39642, postalCode: "58270" },
    { lng: -99.63453, lat: 48.617378, postalCode: "58310" },
    { lng: -97.4489, lat: 48.883906, postalCode: "58216" },
    { lng: -97.92312, lat: 48.915739, postalCode: "58282" },
    { lng: -99.74913, lat: 46.868175, postalCode: "58428" },
    { lng: -97.229718, lat: 46.934596, postalCode: "58106" },
    { lng: -103.93903, lat: 48.629988, postalCode: "58845" },
    { lng: -98.87311, lat: 48.478826, postalCode: "58377" },
    { lng: -96.839, lat: 46.812118, postalCode: "58104" },
    { lng: -101.00165, lat: 48.250987, postalCode: "58768" },
    { lng: -97.44823, lat: 48.628551, postalCode: "58276" },
    { lng: -99.05046, lat: 41.6477, postalCode: "68837" },
    { lng: -98.64132, lat: 41.76983, postalCode: "68637" },
    { lng: -98.69726, lat: 41.09121, postalCode: "68820" },
    { lng: -100.95654, lat: 40.560493, postalCode: "69032" },
    { lng: -98.65405, lat: 40.465821, postalCode: "68950" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68119" },
    { lng: -98.52983, lat: 40.718287, postalCode: "68868" },
    { lng: -98.01432, lat: 42.337964, postalCode: "68720" },
    { lng: -96.18017, lat: 41.240036, postalCode: "68130" },
    { lng: -98.0246, lat: 41.708281, postalCode: "68620" },
    { lng: -97.6865, lat: 41.707169, postalCode: "68644" },
    { lng: -97.250378, lat: 42.609687, postalCode: "68749" },
    { lng: -97.01583, lat: 41.083432, postalCode: "68635" },
    { lng: -97.58296, lat: 40.744844, postalCode: "68401" },
    { lng: -96.02777, lat: 41.463418, postalCode: "68023" },
    { lng: -98.57308, lat: 41.125399, postalCode: "68831" },
    { lng: -95.91589, lat: 40.999245, postalCode: "68048" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68172" },
    { lng: -97.3009, lat: 42.145982, postalCode: "68740" },
    { lng: -98.13311, lat: 42.009216, postalCode: "68636" },
    { lng: -96.92796, lat: 42.660607, postalCode: "68757" },
    { lng: -100.27285, lat: 41.931243, postalCode: "69142" },
    { lng: -99.88206, lat: 42.530382, postalCode: "69210" },
    { lng: -96.01864, lat: 40.394814, postalCode: "68378" },
    { lng: -98.43446, lat: 40.284783, postalCode: "68930" },
    { lng: -99.47144, lat: 40.370382, postalCode: "68923" },
    { lng: -100.03595, lat: 40.34435, postalCode: "68948" },
    { lng: -96.48475, lat: 42.326727, postalCode: "68030" },
    { lng: -98.82619, lat: 41.121028, postalCode: "68871" },
    { lng: -95.58631, lat: 40.097217, postalCode: "68355" },
    { lng: -99.6325, lat: 41.013936, postalCode: "68834" },
    { lng: -97.09057, lat: 42.437732, postalCode: "68745" },
    { lng: -96.50073, lat: 40.680749, postalCode: "68317" },
    { lng: -100.44338, lat: 40.049447, postalCode: "69026" },
    { lng: -97.4751, lat: 42.402076, postalCode: "68747" },
    { lng: -96.05318, lat: 41.183458, postalCode: "68128" },
    { lng: -98.50213, lat: 40.872457, postalCode: "68802" },
    { lng: -97.42863, lat: 40.163122, postalCode: "68362" },
    { lng: -97.54895, lat: 40.154291, postalCode: "68370" },
    { lng: -97.601348, lat: 41.916476, postalCode: "68702" },
    { lng: -96.40254, lat: 40.327774, postalCode: "68332" },
    { lng: -96.04484, lat: 41.151899, postalCode: "68046" },
    { lng: -98.68298, lat: 42.843338, postalCode: "68777" },
    { lng: -99.11839, lat: 40.713265, postalCode: "68845" },
    { lng: -99.77117, lat: 41.146805, postalCode: "68860" },
    { lng: -96.93931, lat: 40.806723, postalCode: "68423" },
    { lng: -97.17016, lat: 42.175272, postalCode: "68790" },
    { lng: -97.60921, lat: 41.504301, postalCode: "68647" },
    { lng: -99.14622, lat: 40.085446, postalCode: "68960" },
    { lng: -97.72408, lat: 40.627475, postalCode: "68365" },
    { lng: -98.38794, lat: 40.763643, postalCode: "68832" },
    { lng: -96.61679, lat: 41.309443, postalCode: "68018" },
    { lng: -99.7648, lat: 40.310905, postalCode: "68936" },
    { lng: -96.50536, lat: 42.053783, postalCode: "68055" },
    { lng: -95.43297, lat: 40.056628, postalCode: "68431" },
    { lng: -98.88267, lat: 41.014592, postalCode: "68869" },
    { lng: -96.47719, lat: 41.22091, postalCode: "68041" },
    { lng: -96.84839, lat: 40.918054, postalCode: "68402" },
    { lng: -99.49058, lat: 41.826783, postalCode: "68879" },
    { lng: -97.39671, lat: 41.03937, postalCode: "68367" },
    { lng: -97.26389, lat: 40.327793, postalCode: "68338" },
    { lng: -100.05391, lat: 40.656225, postalCode: "69028" },
    { lng: -97.12748, lat: 41.70879, postalCode: "68629" },
    { lng: -96.48868, lat: 42.232805, postalCode: "68071" },
    { lng: -97.67271, lat: 42.651113, postalCode: "68718" },
    { lng: -99.069318, lat: 40.686053, postalCode: "68848" },
    { lng: -96.50454, lat: 41.733433, postalCode: "68063" },
    { lng: -97.89355, lat: 40.894366, postalCode: "68843" },
    { lng: -96.12108, lat: 41.204631, postalCode: "68137" },
    { lng: -98.24319, lat: 41.632602, postalCode: "68655" },
    { lng: -96.24729, lat: 40.912199, postalCode: "68407" },
    { lng: -98.22358, lat: 42.395137, postalCode: "68764" },
    { lng: -96.3393, lat: 42.110959, postalCode: "68039" },
    { lng: -98.24659, lat: 40.275161, postalCode: "68957" },
    { lng: -97.89427, lat: 40.230878, postalCode: "68964" },
    { lng: -96.40309, lat: 40.242528, postalCode: "68380" },
    { lng: -96.7559, lat: 40.617147, postalCode: "68404" },
    { lng: -99.12336, lat: 41.158146, postalCode: "68852" },
    { lng: -97.01367, lat: 41.73427, postalCode: "68641" },
    { lng: -96.66049, lat: 40.14601, postalCode: "68318" },
    { lng: -96.67747, lat: 42.187938, postalCode: "68062" },
    { lng: -96.54833, lat: 40.298643, postalCode: "68357" },
    { lng: -95.93427, lat: 41.292321, postalCode: "68110" },
    { lng: -97.14372, lat: 42.749481, postalCode: "68792" },
    { lng: -96.823133, lat: 40.865142, postalCode: "68501" },
    { lng: -96.617443, lat: 41.567907, postalCode: "68026" },
    { lng: -99.12531, lat: 41.820617, postalCode: "68823" },
    { lng: -99.52687, lat: 40.752213, postalCode: "68863" },
    { lng: -98.07011, lat: 42.776379, postalCode: "68760" },
    { lng: -97.43684, lat: 40.652298, postalCode: "68351" },
    { lng: -97.87578, lat: 42.62177, postalCode: "68724" },
    { lng: -96.96036, lat: 41.391914, postalCode: "68036" },
    { lng: -98.17004, lat: 41.031618, postalCode: "68827" },
    { lng: -95.63981, lat: 40.240436, postalCode: "68437" },
    { lng: -95.96383, lat: 41.264418, postalCode: "68131" },
    { lng: -98.61248, lat: 41.336945, postalCode: "68835" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68175" },
    { lng: -97.60056, lat: 40.516034, postalCode: "68361" },
    { lng: -95.9362, lat: 41.260566, postalCode: "68102" },
    { lng: -98.77798, lat: 42.182794, postalCode: "68725" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68176" },
    { lng: -100.26756, lat: 42.63002, postalCode: "69221" },
    { lng: -96.64599, lat: 40.785557, postalCode: "68506" },
    { lng: -96.37773, lat: 40.450262, postalCode: "68443" },
    { lng: -97.49723, lat: 41.690401, postalCode: "68642" },
    { lng: -100.11077, lat: 40.106363, postalCode: "69046" },
    { lng: -96.11027, lat: 41.296198, postalCode: "68164" },
    { lng: -97.68109, lat: 40.29141, postalCode: "68326" },
    { lng: -96.02796, lat: 40.666814, postalCode: "68346" },
    { lng: -99.074058, lat: 40.849654, postalCode: "68849" },
    { lng: -96.62914, lat: 40.85115, postalCode: "68507" },
    { lng: -97.85433, lat: 40.603945, postalCode: "68979" },
    { lng: -96.48276, lat: 42.148163, postalCode: "68067" },
    { lng: -98.28892, lat: 40.459158, postalCode: "68941" },
    { lng: -97.61926, lat: 40.015959, postalCode: "68327" },
    { lng: -97.77856, lat: 40.763846, postalCode: "68371" },
    { lng: -100.37754, lat: 40.519197, postalCode: "69042" },
    { lng: -96.70362, lat: 40.481866, postalCode: "68331" },
    { lng: -97.13139, lat: 40.632861, postalCode: "68343" },
    { lng: -96.69574, lat: 40.7448, postalCode: "68512" },
    { lng: -99.50047, lat: 40.507276, postalCode: "68958" },
    { lng: -96.688799, lat: 40.784451, postalCode: "68572" },
    { lng: -96.14188, lat: 40.87142, postalCode: "68463" },
    { lng: -96.82364, lat: 40.969816, postalCode: "68428" },
    { lng: -96.35497, lat: 40.135672, postalCode: "68323" },
    { lng: -98.12755, lat: 41.172932, postalCode: "68816" },
    { lng: -96.62564, lat: 40.82655, postalCode: "68505" },
    { lng: -97.03057, lat: 40.05097, postalCode: "68440" },
    { lng: -97.84085, lat: 42.028202, postalCode: "68781" },
    { lng: -96.090546, lat: 41.092703, postalCode: "68056" },
    { lng: -98.47449, lat: 41.230069, postalCode: "68873" },
    { lng: -96.38189, lat: 41.068476, postalCode: "68003" },
    { lng: -96.48869, lat: 41.434796, postalCode: "68025" },
    { lng: -99.35516, lat: 41.44271, postalCode: "68881" },
    { lng: -99.2839, lat: 40.887707, postalCode: "68812" },
    { lng: -99.05694, lat: 40.306853, postalCode: "68947" },
    { lng: -97.60967, lat: 41.014534, postalCode: "68316" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68182" },
    { lng: -95.67683, lat: 40.32194, postalCode: "68414" },
    { lng: -97.54836, lat: 40.328889, postalCode: "68322" },
    { lng: -100.746912, lat: 41.046447, postalCode: "69103" },
    { lng: -96.66579, lat: 40.679251, postalCode: "68430" },
    { lng: -99.37512, lat: 40.708697, postalCode: "68836" },
    { lng: -96.64953, lat: 41.064961, postalCode: "68017" },
    { lng: -97.58391, lat: 40.409283, postalCode: "68444" },
    { lng: -96.6226, lat: 41.199626, postalCode: "68066" },
    { lng: -96.55172, lat: 41.62307, postalCode: "68031" },
    { lng: -96.74604, lat: 40.264193, postalCode: "68310" },
    { lng: -100.03899, lat: 42.61416, postalCode: "69214" },
    { lng: -96.73951, lat: 41.285973, postalCode: "68040" },
    { lng: -96.84511, lat: 40.473614, postalCode: "68328" },
    { lng: -96.78794, lat: 41.704483, postalCode: "68664" },
    { lng: -97.45367, lat: 40.401484, postalCode: "68416" },
    { lng: -96.20759, lat: 41.396848, postalCode: "68068" },
    { lng: -96.662138, lat: 40.940125, postalCode: "68514" },
    { lng: -98.74701, lat: 40.786865, postalCode: "68876" },
    { lng: -98.00225, lat: 41.12561, postalCode: "68826" },
    { lng: -97.70788, lat: 41.995961, postalCode: "68752" },
    { lng: -99.41055, lat: 41.639578, postalCode: "68874" },
    { lng: -96.84586, lat: 41.077766, postalCode: "68065" },
    { lng: -96.40258, lat: 41.249536, postalCode: "68073" },
    { lng: -96.2123, lat: 41.465023, postalCode: "68034" },
    { lng: -97.00012, lat: 41.177056, postalCode: "68626" },
    { lng: -96.38842, lat: 40.874192, postalCode: "68304" },
    { lng: -99.86334, lat: 40.627711, postalCode: "68937" },
    { lng: -97.24781, lat: 41.691648, postalCode: "68643" },
    { lng: -98.30306, lat: 40.084405, postalCode: "68942" },
    { lng: -99.81717, lat: 41.976053, postalCode: "68821" },
    { lng: -96.52581, lat: 40.924298, postalCode: "68462" },
    { lng: -98.12001, lat: 42.620877, postalCode: "68783" },
    { lng: -96.62313, lat: 42.009508, postalCode: "68004" },
    { lng: -96.00077, lat: 41.344394, postalCode: "68152" },
    { lng: -96.64233, lat: 40.211775, postalCode: "68374" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68145" },
    { lng: -96.09516, lat: 40.728493, postalCode: "68417" },
    { lng: -98.38749, lat: 40.916866, postalCode: "68803" },
    { lng: -98.00493, lat: 40.83605, postalCode: "68818" },
    { lng: -97.9023, lat: 42.469145, postalCode: "68729" },
    { lng: -98.04549, lat: 41.861111, postalCode: "68652" },
    { lng: -95.99058, lat: 41.182198, postalCode: "68157" },
    { lng: -96.29776, lat: 40.549923, postalCode: "68324" },
    { lng: -98.66952, lat: 40.142355, postalCode: "68952" },
    { lng: -95.946368, lat: 41.264333, postalCode: "68278" },
    { lng: -96.5315, lat: 41.134234, postalCode: "68033" },
    { lng: -98.49117, lat: 41.517347, postalCode: "68842" },
    { lng: -98.98515, lat: 42.214277, postalCode: "68711" },
    { lng: -98.5978, lat: 40.807789, postalCode: "68883" },
    { lng: -98.85921, lat: 42.923088, postalCode: "68722" },
    { lng: -96.18201, lat: 40.656899, postalCode: "68446" },
    { lng: -96.07452, lat: 41.108443, postalCode: "68054" },
    { lng: -96.13394, lat: 41.175935, postalCode: "68138" },
    { lng: -97.3801, lat: 42.750269, postalCode: "68736" },
    { lng: -97.03437, lat: 41.009984, postalCode: "68314" },
    { lng: -99.24938, lat: 41.556172, postalCode: "68828" },
    { lng: -95.83868, lat: 40.11127, postalCode: "68337" },
    { lng: -96.37407, lat: 41.761217, postalCode: "68019" },
    { lng: -96.72103, lat: 42.437174, postalCode: "68785" },
    { lng: -96.74974, lat: 40.790601, postalCode: "68522" },
    { lng: -98.94136, lat: 42.573338, postalCode: "68713" },
    { lng: -99.04756, lat: 41.067301, postalCode: "68844" },
    { lng: -95.96434, lat: 41.294547, postalCode: "68111" },
    { lng: -98.191178, lat: 42.811418, postalCode: "68782" },
    { lng: -98.42285, lat: 42.189511, postalCode: "68735" },
    { lng: -98.38523, lat: 41.693462, postalCode: "68665" },
    { lng: -95.84422, lat: 40.376356, postalCode: "68305" },
    { lng: -100.3151, lat: 42.1088, postalCode: "69157" },
    { lng: -98.23217, lat: 40.60418, postalCode: "68954" },
    { lng: -96.15352, lat: 41.294069, postalCode: "68116" },
    { lng: -98.60417, lat: 40.99858, postalCode: "68824" },
    { lng: -97.45212, lat: 41.836046, postalCode: "68748" },
    { lng: -98.53981, lat: 40.595599, postalCode: "68955" },
    { lng: -96.182295, lat: 41.53827, postalCode: "68009" },
    { lng: -96.64986, lat: 41.386373, postalCode: "68015" },
    { lng: -96.05073, lat: 41.235064, postalCode: "68124" },
    { lng: -95.89714, lat: 40.820408, postalCode: "68455" },
    { lng: -96.44999, lat: 41.53608, postalCode: "68044" },
    { lng: -97.20373, lat: 42.281323, postalCode: "68723" },
    { lng: -100.25556, lat: 42.940698, postalCode: "69220" },
    { lng: -96.91229, lat: 40.389849, postalCode: "68341" },
    { lng: -99.11132, lat: 40.982236, postalCode: "68866" },
    { lng: -98.60341, lat: 42.516792, postalCode: "68763" },
    { lng: -101.23172, lat: 40.383691, postalCode: "69031" },
    { lng: -96.4362, lat: 40.805678, postalCode: "68347" },
    { lng: -99.62187, lat: 40.247784, postalCode: "68967" },
    { lng: -96.688799, lat: 40.784451, postalCode: "68509" },
    { lng: -98.88958, lat: 40.287173, postalCode: "68981" },
    { lng: -99.95439, lat: 41.288656, postalCode: "68825" },
    { lng: -97.3522, lat: 40.716216, postalCode: "68330" },
    { lng: -99.25626, lat: 40.699986, postalCode: "68861" },
    { lng: -98.78434, lat: 40.568436, postalCode: "68945" },
    { lng: -96.00315, lat: 40.833188, postalCode: "68413" },
    { lng: -96.11505, lat: 41.235248, postalCode: "68144" },
    { lng: -97.47265, lat: 40.025484, postalCode: "68375" },
    { lng: -96.24565, lat: 41.274881, postalCode: "68022" },
    { lng: -98.52808, lat: 40.090341, postalCode: "68970" },
    { lng: -99.18638, lat: 40.338567, postalCode: "68982" },
    { lng: -96.71396, lat: 40.385662, postalCode: "68422" },
    { lng: -96.43555, lat: 40.974167, postalCode: "68366" },
    { lng: -96.04798, lat: 41.264531, postalCode: "68114" },
    { lng: -97.58041, lat: 40.633747, postalCode: "68354" },
    { lng: -96.89662, lat: 41.706209, postalCode: "68633" },
    { lng: -96.26545, lat: 41.988034, postalCode: "68020" },
    { lng: -100.74359, lat: 42.07739, postalCode: "69161" },
    { lng: -96.503281, lat: 41.61023, postalCode: "68072" },
    { lng: -96.65563, lat: 40.8442, postalCode: "68504" },
    { lng: -99.70826, lat: 42.519402, postalCode: "69217" },
    { lng: -97.41741, lat: 42.026209, postalCode: "68701" },
    { lng: -96.14841, lat: 40.992426, postalCode: "68037" },
    { lng: -100.35262, lat: 40.646065, postalCode: "69039" },
    { lng: -97.03303, lat: 42.221382, postalCode: "68787" },
    { lng: -95.87023, lat: 40.661439, postalCode: "68410" },
    { lng: -97.76081, lat: 40.910954, postalCode: "68319" },
    { lng: -97.39726, lat: 40.501404, postalCode: "68406" },
    { lng: -96.83506, lat: 41.309115, postalCode: "68050" },
    { lng: -97.00106, lat: 40.298729, postalCode: "68424" },
    { lng: -98.67681, lat: 40.642616, postalCode: "68956" },
    { lng: -97.41634, lat: 41.184669, postalCode: "68662" },
    { lng: -100.78789, lat: 42.914711, postalCode: "69212" },
    { lng: -97.94955, lat: 40.605538, postalCode: "68975" },
    { lng: -96.65634, lat: 40.807106, postalCode: "68510" },
    { lng: -98.40272, lat: 42.41927, postalCode: "68766" },
    { lng: -100.50729, lat: 40.618751, postalCode: "69025" },
    { lng: -96.59122, lat: 40.740833, postalCode: "68526" },
    { lng: -98.67027, lat: 41.217633, postalCode: "68838" },
    { lng: -98.78701, lat: 41.251967, postalCode: "68817" },
    { lng: -96.12833, lat: 41.25973, postalCode: "68010" },
    { lng: -98.96733, lat: 41.286479, postalCode: "68853" },
    { lng: -97.992289, lat: 41.12044, postalCode: "68226" },
    { lng: -96.9295, lat: 42.000682, postalCode: "68791" },
    { lng: -97.56993, lat: 41.21361, postalCode: "68651" },
    { lng: -99.38123, lat: 41.296709, postalCode: "68814" },
    { lng: -100.98254, lat: 41.560479, postalCode: "69167" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68183" },
    { lng: -95.95924, lat: 41.334947, postalCode: "68112" },
    { lng: -100.63052, lat: 40.225039, postalCode: "69001" },
    { lng: -96.49444, lat: 41.830827, postalCode: "68045" },
    { lng: -97.89516, lat: 41.068227, postalCode: "68846" },
    { lng: -97.11206, lat: 40.909197, postalCode: "68434" },
    { lng: -96.43904, lat: 42.424303, postalCode: "68731" },
    { lng: -97.12638, lat: 41.259891, postalCode: "68632" },
    { lng: -97.70019, lat: 41.306574, postalCode: "68663" },
    { lng: -97.26849, lat: 40.781788, postalCode: "68313" },
    { lng: -97.30554, lat: 41.106694, postalCode: "68667" },
    { lng: -100.54009, lat: 41.020868, postalCode: "69151" },
    { lng: -97.79839, lat: 41.769497, postalCode: "68758" },
    { lng: -96.24529, lat: 41.003188, postalCode: "68058" },
    { lng: -95.69982, lat: 40.152937, postalCode: "68457" },
    { lng: -96.93521, lat: 41.546604, postalCode: "68659" },
    { lng: -97.35456, lat: 41.692408, postalCode: "68631" },
    { lng: -96.3845, lat: 40.702467, postalCode: "68418" },
    { lng: -100.85376, lat: 40.242582, postalCode: "69024" },
    { lng: -97.32084, lat: 40.898329, postalCode: "68456" },
    { lng: -96.17269, lat: 41.36753, postalCode: "68007" },
    { lng: -97.07633, lat: 40.061702, postalCode: "68350" },
    { lng: -99.23875, lat: 40.113307, postalCode: "68971" },
    { lng: -97.21583, lat: 41.955429, postalCode: "68779" },
    { lng: -98.78853, lat: 40.083133, postalCode: "68972" },
    { lng: -98.00297, lat: 41.019927, postalCode: "68854" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68103" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68155" },
    { lng: -96.3396, lat: 41.328149, postalCode: "68064" },
    { lng: -97.54527, lat: 42.200994, postalCode: "68767" },
    { lng: -97.9101, lat: 40.023871, postalCode: "68943" },
    { lng: -99.59463, lat: 42.231317, postalCode: "68772" },
    { lng: -96.17229, lat: 40.50482, postalCode: "68329" },
    { lng: -96.05009, lat: 41.203029, postalCode: "68127" },
    { lng: -96.16547, lat: 40.918749, postalCode: "68403" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68109" },
    { lng: -97.51543, lat: 42.774493, postalCode: "68730" },
    { lng: -97.33094, lat: 40.412117, postalCode: "68453" },
    { lng: -96.4838, lat: 40.081107, postalCode: "68381" },
    { lng: -96.85153, lat: 42.450185, postalCode: "68710" },
    { lng: -97.02445, lat: 40.20808, postalCode: "68377" },
    { lng: -96.81761, lat: 40.052388, postalCode: "68415" },
    { lng: -101.01311, lat: 40.160252, postalCode: "69044" },
    { lng: -96.64566, lat: 40.749224, postalCode: "68516" },
    { lng: -96.17862, lat: 41.203791, postalCode: "68135" },
    { lng: -97.28305, lat: 40.630445, postalCode: "68359" },
    { lng: -96.78598, lat: 40.816765, postalCode: "68528" },
    { lng: -95.99675, lat: 41.208364, postalCode: "68117" },
    { lng: -96.34446, lat: 41.47502, postalCode: "68002" },
    { lng: -99.1444, lat: 41.420141, postalCode: "68815" },
    { lng: -96.688799, lat: 40.784451, postalCode: "68544" },
    { lng: -96.96331, lat: 42.379899, postalCode: "68728" },
    { lng: -101.23375, lat: 40.150605, postalCode: "69043" },
    { lng: -97.60196, lat: 41.993408, postalCode: "68715" },
    { lng: -96.25535, lat: 41.118694, postalCode: "68028" },
    { lng: -97.29532, lat: 41.192785, postalCode: "68658" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68178" },
    { lng: -96.2097, lat: 40.373895, postalCode: "68450" },
    { lng: -99.52938, lat: 42.53961, postalCode: "68714" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68198" },
    { lng: -96.964345, lat: 41.344868, postalCode: "68032" },
    { lng: -95.69665, lat: 40.400172, postalCode: "68321" },
    { lng: -100.15245, lat: 41.824292, postalCode: "68833" },
    { lng: -97.20374, lat: 40.151358, postalCode: "68352" },
    { lng: -100.21881, lat: 40.756879, postalCode: "69029" },
    { lng: -96.29537, lat: 40.837204, postalCode: "68349" },
    { lng: -96.02099, lat: 40.034277, postalCode: "68345" },
    { lng: -100.65778, lat: 40.638541, postalCode: "69038" },
    { lng: -96.8585, lat: 40.796876, postalCode: "68532" },
    { lng: -100.73442, lat: 40.770008, postalCode: "69170" },
    { lng: -96.76178, lat: 40.740529, postalCode: "68523" },
    { lng: -96.59023, lat: 40.798632, postalCode: "68520" },
    { lng: -97.22235, lat: 41.08293, postalCode: "68669" },
    { lng: -98.07828, lat: 40.03686, postalCode: "68978" },
    { lng: -96.17108, lat: 41.263194, postalCode: "68118" },
    { lng: -96.688799, lat: 40.784451, postalCode: "68542" },
    { lng: -96.3806, lat: 40.56985, postalCode: "68344" },
    { lng: -98.21846, lat: 42.163976, postalCode: "68726" },
    { lng: -95.96383, lat: 41.240854, postalCode: "68105" },
    { lng: -96.11422, lat: 41.264952, postalCode: "68154" },
    { lng: -96.70744, lat: 40.814191, postalCode: "68508" },
    { lng: -96.05181, lat: 41.296748, postalCode: "68134" },
    { lng: -95.95126, lat: 41.117337, postalCode: "68123" },
    { lng: -97.06804, lat: 41.999564, postalCode: "68768" },
    { lng: -95.99897, lat: 41.242313, postalCode: "68106" },
    { lng: -95.90796, lat: 41.146848, postalCode: "68005" },
    { lng: -99.33514, lat: 41.168004, postalCode: "68855" },
    { lng: -99.35398, lat: 40.142462, postalCode: "68920" },
    { lng: -97.2052, lat: 40.996127, postalCode: "68439" },
    { lng: -99.82976, lat: 41.726833, postalCode: "68813" },
    { lng: -96.15968, lat: 40.106605, postalCode: "68420" },
    { lng: -96.67552, lat: 40.823602, postalCode: "68503" },
    { lng: -96.77567, lat: 41.484782, postalCode: "68649" },
    { lng: -100.96318, lat: 40.808779, postalCode: "69132" },
    { lng: -100.070257, lat: 40.892469, postalCode: "69171" },
    { lng: -95.9426, lat: 40.172733, postalCode: "68376" },
    { lng: -96.708, lat: 41.653831, postalCode: "68057" },
    { lng: -97.09357, lat: 40.400494, postalCode: "68445" },
    { lng: -99.89562, lat: 40.318156, postalCode: "68922" },
    { lng: -96.8338, lat: 41.944906, postalCode: "68716" },
    { lng: -97.55717, lat: 42.50198, postalCode: "68786" },
    { lng: -96.692843, lat: 40.820645, postalCode: "68588" },
    { lng: -97.81152, lat: 40.29735, postalCode: "68335" },
    { lng: -97.87696, lat: 40.126094, postalCode: "68974" },
    { lng: -98.20719, lat: 40.895553, postalCode: "68865" },
    { lng: -96.24072, lat: 40.224384, postalCode: "68441" },
    { lng: -96.45359, lat: 41.948587, postalCode: "68038" },
    { lng: -97.380646, lat: 40.059775, postalCode: "68429" },
    { lng: -99.63058, lat: 40.092127, postalCode: "68977" },
    { lng: -98.17449, lat: 41.552082, postalCode: "68627" },
    { lng: -99.48582, lat: 40.970793, postalCode: "68878" },
    { lng: -99.97619, lat: 40.099617, postalCode: "68946" },
    { lng: -98.51413, lat: 41.909737, postalCode: "68622" },
    { lng: -99.15387, lat: 40.817442, postalCode: "68870" },
    { lng: -98.397248, lat: 40.589594, postalCode: "68902" },
    { lng: -96.4187, lat: 42.467095, postalCode: "68776" },
    { lng: -96.52637, lat: 40.472055, postalCode: "68301" },
    { lng: -98.15079, lat: 40.34357, postalCode: "68934" },
    { lng: -96.25866, lat: 41.665663, postalCode: "68029" },
    { lng: -97.35618, lat: 42.377338, postalCode: "68771" },
    { lng: -97.20746, lat: 40.410478, postalCode: "68464" },
    { lng: -96.21577, lat: 41.783203, postalCode: "68061" },
    { lng: -100.22265, lat: 41.398865, postalCode: "69120" },
    { lng: -96.72569, lat: 41.841032, postalCode: "68788" },
    { lng: -99.8661, lat: 42.880251, postalCode: "68778" },
    { lng: -96.18662, lat: 41.177298, postalCode: "68136" },
    { lng: -95.77517, lat: 40.229899, postalCode: "68442" },
    { lng: -96.57326, lat: 40.048634, postalCode: "68309" },
    { lng: -99.97697, lat: 40.868727, postalCode: "69130" },
    { lng: -96.65361, lat: 40.087289, postalCode: "68466" },
    { lng: -98.93307, lat: 41.597942, postalCode: "68862" },
    { lng: -95.90649, lat: 41.127805, postalCode: "68113" },
    { lng: -98.56946, lat: 42.845614, postalCode: "68719" },
    { lng: -98.59331, lat: 40.320042, postalCode: "68928" },
    { lng: -99.74003, lat: 40.576873, postalCode: "68976" },
    { lng: -99.13746, lat: 42.591725, postalCode: "68780" },
    { lng: -98.47051, lat: 40.860494, postalCode: "68810" },
    { lng: -97.05841, lat: 40.772835, postalCode: "68405" },
    { lng: -97.75199, lat: 41.44996, postalCode: "68640" },
    { lng: -99.7424, lat: 40.786048, postalCode: "68850" },
    { lng: -96.73093, lat: 42.112028, postalCode: "68047" },
    { lng: -96.13044, lat: 40.289859, postalCode: "68348" },
    { lng: -97.61574, lat: 42.35077, postalCode: "68765" },
    { lng: -95.99741, lat: 41.26565, postalCode: "68132" },
    { lng: -98.792699, lat: 40.478879, postalCode: "68963" },
    { lng: -97.73815, lat: 40.43034, postalCode: "68436" },
    { lng: -96.28913, lat: 40.698235, postalCode: "68454" },
    { lng: -98.04812, lat: 40.523823, postalCode: "68933" },
    { lng: -97.27699, lat: 42.821095, postalCode: "68774" },
    { lng: -97.78036, lat: 41.068368, postalCode: "68654" },
    { lng: -100.17857, lat: 40.317358, postalCode: "69022" },
    { lng: -97.859489, lat: 40.607095, postalCode: "68797" },
    { lng: -97.58884, lat: 40.865237, postalCode: "68467" },
    { lng: -97.80618, lat: 42.331689, postalCode: "68769" },
    { lng: -96.93428, lat: 40.105725, postalCode: "68342" },
    { lng: -100.52739, lat: 41.918663, postalCode: "69166" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68180" },
    { lng: -97.20918, lat: 42.408389, postalCode: "68717" },
    { lng: -96.96787, lat: 40.95178, postalCode: "68360" },
    { lng: -100.64716, lat: 42.716711, postalCode: "69201" },
    { lng: -99.44391, lat: 42.855832, postalCode: "68753" },
    { lng: -95.95539, lat: 41.205198, postalCode: "68107" },
    { lng: -98.86414, lat: 40.754582, postalCode: "68840" },
    { lng: -96.73644, lat: 42.288154, postalCode: "68733" },
    { lng: -97.49439, lat: 41.388893, postalCode: "68634" },
    { lng: -98.79953, lat: 42.475789, postalCode: "68734" },
    { lng: -96.63929, lat: 41.482181, postalCode: "68621" },
    { lng: -96.71298, lat: 40.855645, postalCode: "68521" },
    { lng: -96.96099, lat: 40.620175, postalCode: "68333" },
    { lng: -99.8225, lat: 40.106595, postalCode: "68926" },
    { lng: -96.13068, lat: 40.789284, postalCode: "68307" },
    { lng: -97.45072, lat: 40.897974, postalCode: "68460" },
    { lng: -98.79933, lat: 41.48443, postalCode: "68859" },
    { lng: -99.728097, lat: 41.393902, postalCode: "68880" },
    { lng: -96.78914, lat: 40.563073, postalCode: "68368" },
    { lng: -97.22019, lat: 40.832663, postalCode: "68364" },
    { lng: -95.93353, lat: 41.240562, postalCode: "68108" },
    { lng: -99.63075, lat: 41.400263, postalCode: "68822" },
    { lng: -99.31812, lat: 42.700145, postalCode: "68759" },
    { lng: -96.94965, lat: 41.334744, postalCode: "68001" },
    { lng: -96.84873, lat: 40.739508, postalCode: "68339" },
    { lng: -98.12019, lat: 42.367457, postalCode: "68773" },
    { lng: -101.17982, lat: 40.820868, postalCode: "69169" },
    { lng: -99.81791, lat: 41.488642, postalCode: "68856" },
    { lng: -96.30195, lat: 41.255649, postalCode: "68069" },
    { lng: -95.73152, lat: 40.48863, postalCode: "68421" },
    { lng: -96.04609, lat: 41.348081, postalCode: "68122" },
    { lng: -97.96363, lat: 40.365387, postalCode: "68935" },
    { lng: -96.00547, lat: 41.144085, postalCode: "68133" },
    { lng: -98.02352, lat: 42.150892, postalCode: "68756" },
    { lng: -96.58297, lat: 42.367879, postalCode: "68741" },
    { lng: -96.926042, lat: 42.481711, postalCode: "68751" },
    { lng: -97.96188, lat: 42.049818, postalCode: "68761" },
    { lng: -96.78066, lat: 41.400937, postalCode: "68648" },
    { lng: -96.61495, lat: 40.92185, postalCode: "68517" },
    { lng: -96.688799, lat: 40.784451, postalCode: "68583" },
    { lng: -98.43946, lat: 40.431821, postalCode: "68925" },
    { lng: -96.60568, lat: 40.533257, postalCode: "68358" },
    { lng: -98.1094, lat: 41.441371, postalCode: "68623" },
    { lng: -100.15719, lat: 40.952436, postalCode: "69138" },
    { lng: -96.08978, lat: 40.188015, postalCode: "68447" },
    { lng: -96.43168, lat: 41.095604, postalCode: "68042" },
    { lng: -100.77502, lat: 41.129363, postalCode: "69101" },
    { lng: -98.09442, lat: 40.635514, postalCode: "68944" },
    { lng: -97.41164, lat: 40.247793, postalCode: "68303" },
    { lng: -98.10957, lat: 40.425264, postalCode: "68938" },
    { lng: -96.720143, lat: 40.900801, postalCode: "68531" },
    { lng: -96.14965, lat: 41.538439, postalCode: "68008" },
    { lng: -96.10608, lat: 41.365763, postalCode: "68142" },
    { lng: -98.68461, lat: 41.523574, postalCode: "68875" },
    { lng: -98.38131, lat: 40.589299, postalCode: "68901" },
    { lng: -96.10884, lat: 41.03664, postalCode: "68016" },
    { lng: -96.60668, lat: 42.460155, postalCode: "68743" },
    { lng: -101.11224, lat: 40.358455, postalCode: "69040" },
    { lng: -99.61498, lat: 40.534445, postalCode: "68927" },
    { lng: -99.05499, lat: 40.709895, postalCode: "68847" },
    { lng: -98.31587, lat: 41.097169, postalCode: "68872" },
    { lng: -100.42375, lat: 40.236212, postalCode: "69034" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68179" },
    { lng: -96.49182, lat: 40.239986, postalCode: "68458" },
    { lng: -97.06915, lat: 41.472784, postalCode: "68661" },
    { lng: -96.15635, lat: 41.074548, postalCode: "68059" },
    { lng: -98.92412, lat: 40.491484, postalCode: "68959" },
    { lng: -99.24602, lat: 40.532115, postalCode: "68940" },
    { lng: -98.4231, lat: 41.413885, postalCode: "68882" },
    { lng: -98.55892, lat: 40.459642, postalCode: "68973" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68139" },
    { lng: -99.404864, lat: 40.176513, postalCode: "68969" },
    { lng: -100.2201, lat: 42.211636, postalCode: "69135" },
    { lng: -97.67446, lat: 42.272213, postalCode: "68737" },
    { lng: -95.74181, lat: 40.060469, postalCode: "68433" },
    { lng: -97.84507, lat: 40.394354, postalCode: "68452" },
    { lng: -95.96011, lat: 40.487422, postalCode: "68320" },
    { lng: -99.11348, lat: 40.493457, postalCode: "68924" },
    { lng: -98.16372, lat: 40.769633, postalCode: "68841" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68181" },
    { lng: -97.601337, lat: 42.264356, postalCode: "68738" },
    { lng: -99.37311, lat: 40.434479, postalCode: "68949" },
    { lng: -99.46708, lat: 40.145771, postalCode: "68966" },
    { lng: -100.34398, lat: 41.036035, postalCode: "69123" },
    { lng: -100.3015, lat: 40.241652, postalCode: "69020" },
    { lng: -96.745622, lat: 40.626661, postalCode: "68438" },
    { lng: -99.37818, lat: 40.966548, postalCode: "68858" },
    { lng: -97.96381, lat: 41.375063, postalCode: "68638" },
    { lng: -96.53044, lat: 40.764867, postalCode: "68461" },
    { lng: -96.99849, lat: 40.487481, postalCode: "68465" },
    { lng: -96.42899, lat: 41.330124, postalCode: "68035" },
    { lng: -97.86898, lat: 41.574309, postalCode: "68660" },
    { lng: -98.73884, lat: 40.303479, postalCode: "68932" },
    { lng: -96.53061, lat: 40.849249, postalCode: "68527" },
    { lng: -97.541795, lat: 41.53808, postalCode: "68602" },
    { lng: -96.086904, lat: 40.653589, postalCode: "68382" },
    { lng: -98.33087, lat: 40.922826, postalCode: "68801" },
    { lng: -96.76399, lat: 42.563297, postalCode: "68770" },
    { lng: -98.43592, lat: 42.787371, postalCode: "68746" },
    { lng: -95.95592, lat: 41.179338, postalCode: "68147" },
    { lng: -97.60295, lat: 41.113813, postalCode: "68666" },
    { lng: -95.93611, lat: 40.915826, postalCode: "68409" },
    { lng: -99.4839, lat: 41.348094, postalCode: "68819" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68120" },
    { lng: -96.50816, lat: 40.596093, postalCode: "68419" },
    { lng: -96.78359, lat: 41.200338, postalCode: "68070" },
    { lng: -98.22475, lat: 41.244441, postalCode: "68864" },
    { lng: -100.28026, lat: 40.056031, postalCode: "69036" },
    { lng: -96.67667, lat: 40.965773, postalCode: "68336" },
    { lng: -96.9594, lat: 41.28216, postalCode: "68014" },
    { lng: -97.83118, lat: 41.212516, postalCode: "68628" },
    { lng: -96.0006, lat: 41.292445, postalCode: "68104" },
    { lng: -97.76979, lat: 40.042191, postalCode: "68325" },
    { lng: -96.69589, lat: 40.789051, postalCode: "68502" },
    { lng: -98.51848, lat: 42.376443, postalCode: "68742" },
    { lng: -97.99902, lat: 42.534339, postalCode: "68789" },
    { lng: -96.02353, lat: 40.537185, postalCode: "68448" },
    { lng: -96.171104, lat: 41.291736, postalCode: "68101" },
    { lng: -96.62295, lat: 40.612736, postalCode: "68372" },
    { lng: -99.11638, lat: 42.946169, postalCode: "68755" },
    { lng: -101.03117, lat: 41.186837, postalCode: "69143" },
    { lng: -100.53006, lat: 41.437215, postalCode: "69163" },
    { lng: -97.73636, lat: 40.135651, postalCode: "68340" },
    { lng: -101.17899, lat: 41.194109, postalCode: "69165" },
    { lng: -97.23485, lat: 41.342529, postalCode: "68624" },
    { lng: -96.87191, lat: 42.242742, postalCode: "68784" },
    { lng: -97.25267, lat: 42.624108, postalCode: "68739" },
    { lng: -98.94389, lat: 40.103792, postalCode: "68939" },
    { lng: -97.5574, lat: 40.248038, postalCode: "68315" },
    { lng: -97.48974, lat: 41.545453, postalCode: "68653" },
    { lng: -96.779796, lat: 40.879752, postalCode: "68529" },
    { lng: -97.37192, lat: 41.437838, postalCode: "68601" },
    { lng: -97.20526, lat: 42.509494, postalCode: "68727" },
    { lng: -98.03859, lat: 40.19484, postalCode: "68961" },
    { lng: -99.04457, lat: 40.121789, postalCode: "68929" },
    { lng: -96.98116, lat: 42.459805, postalCode: "68732" },
    { lng: -98.26496, lat: 40.674132, postalCode: "68980" },
    { lng: -96.79586, lat: 40.859321, postalCode: "68524" },
    { lng: -94.76307, lat: 35.690088, postalCode: "74931" },
    { lng: -95.63631, lat: 36.793303, postalCode: "74027" },
    { lng: -97.137693, lat: 36.695368, postalCode: "74602" },
    { lng: -96.76333, lat: 35.710256, postalCode: "74026" },
    { lng: -97.65419, lat: 34.462809, postalCode: "73491" },
    { lng: -99.88029, lat: 35.250612, postalCode: "73645" },
    { lng: -96.609139, lat: 35.162228, postalCode: "74818" },
    { lng: -95.720708, lat: 34.947259, postalCode: "74502" },
    { lng: -97.25088, lat: 35.975057, postalCode: "73027" },
    { lng: -95.33691, lat: 35.719962, postalCode: "74403" },
    { lng: -95.96615, lat: 36.099044, postalCode: "74105" },
    { lng: -96.39471, lat: 35.54247, postalCode: "74833" },
    { lng: -95.94157, lat: 34.491359, postalCode: "74569" },
    { lng: -97.64801, lat: 34.023916, postalCode: "73561" },
    { lng: -97.50586, lat: 35.47596, postalCode: "73104" },
    { lng: -94.58622, lat: 34.866897, postalCode: "74937" },
    { lng: -94.46967, lat: 35.241727, postalCode: "74902" },
    { lng: -98.588383, lat: 36.058133, postalCode: "73770" },
    { lng: -94.97185, lat: 35.923658, postalCode: "74464" },
    { lng: -96.91672, lat: 36.214419, postalCode: "74032" },
    { lng: -99.680146, lat: 35.26946, postalCode: "73656" },
    { lng: -98.70098, lat: 34.330239, postalCode: "73553" },
    { lng: -95.61192, lat: 36.327143, postalCode: "74017" },
    { lng: -95.62824, lat: 35.172538, postalCode: "74425" },
    { lng: -98.37108, lat: 36.751276, postalCode: "73728" },
    { lng: -97.39721, lat: 35.459478, postalCode: "73110" },
    { lng: -95.040008, lat: 35.900074, postalCode: "74465" },
    { lng: -95.70731, lat: 35.384048, postalCode: "74461" },
    { lng: -94.75729, lat: 34.669198, postalCode: "74949" },
    { lng: -95.85244, lat: 36.102112, postalCode: "74146" },
    { lng: -96.03435, lat: 36.160341, postalCode: "74127" },
    { lng: -99.05182, lat: 34.254376, postalCode: "73530" },
    { lng: -97.46924, lat: 35.576357, postalCode: "73131" },
    { lng: -97.53569, lat: 35.338716, postalCode: "73170" },
    { lng: -96.26936, lat: 34.137095, postalCode: "74729" },
    { lng: -97.42814, lat: 33.877679, postalCode: "73441" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74158" },
    { lng: -98.457646, lat: 34.630879, postalCode: "73558" },
    { lng: -98.4001, lat: 34.621373, postalCode: "73507" },
    { lng: -98.49057, lat: 36.49556, postalCode: "73716" },
    { lng: -95.82105, lat: 36.114797, postalCode: "74134" },
    { lng: -97.93807, lat: 34.790635, postalCode: "73082" },
    { lng: -98.73519, lat: 35.062348, postalCode: "73062" },
    { lng: -95.76125, lat: 34.722059, postalCode: "74528" },
    { lng: -97.57152, lat: 35.612359, postalCode: "73134" },
    { lng: -95.513856, lat: 35.963567, postalCode: "74466" },
    { lng: -95.99717, lat: 35.712025, postalCode: "74456" },
    { lng: -95.39008, lat: 35.119128, postalCode: "74561" },
    { lng: -98.72275, lat: 34.239154, postalCode: "73546" },
    { lng: -97.44201, lat: 35.440311, postalCode: "73115" },
    { lng: -97.4055, lat: 35.568867, postalCode: "73151" },
    { lng: -97.68182, lat: 34.329621, postalCode: "73442" },
    { lng: -97.43249, lat: 35.40017, postalCode: "73135" },
    { lng: -97.936166, lat: 33.897998, postalCode: "76356" },
    { lng: -95.17303, lat: 36.631673, postalCode: "74301" },
    { lng: -94.67008, lat: 35.172259, postalCode: "74951" },
    { lng: -96.5794, lat: 36.084211, postalCode: "74052" },
    { lng: -98.35764, lat: 35.631117, postalCode: "73040" },
    { lng: -99.47006, lat: 35.215997, postalCode: "73627" },
    { lng: -96.02963, lat: 33.997865, postalCode: "74723" },
    { lng: -95.95847, lat: 36.239642, postalCode: "74130" },
    { lng: -97.83368, lat: 36.56225, postalCode: "73753" },
    { lng: -95.47832, lat: 34.877067, postalCode: "74545" },
    { lng: -94.50476, lat: 35.413049, postalCode: "74954" },
    { lng: -95.23355, lat: 35.799294, postalCode: "74434" },
    { lng: -99.440839, lat: 34.598197, postalCode: "73523" },
    { lng: -95.78957, lat: 35.152295, postalCode: "74442" },
    { lng: -98.94987, lat: 35.283812, postalCode: "73632" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73163" },
    { lng: -96.57768, lat: 35.910408, postalCode: "74068" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74187" },
    { lng: -96.07119, lat: 36.057536, postalCode: "74131" },
    { lng: -97.88304, lat: 36.105884, postalCode: "73742" },
    { lng: -96.3549, lat: 33.769093, postalCode: "74747" },
    { lng: -97.56931, lat: 36.69588, postalCode: "74643" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73153" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73193" },
    { lng: -95.12924, lat: 36.412084, postalCode: "74367" },
    { lng: -97.48672, lat: 35.212681, postalCode: "73072" },
    { lng: -95.28635, lat: 35.355394, postalCode: "74455" },
    { lng: -97.39901, lat: 34.625144, postalCode: "73433" },
    { lng: -99.29047, lat: 36.543405, postalCode: "73802" },
    { lng: -99.52012, lat: 34.855652, postalCode: "73554" },
    { lng: -94.72912, lat: 36.947372, postalCode: "74363" },
    { lng: -96.06237, lat: 35.94876, postalCode: "74041" },
    { lng: -98.56181, lat: 34.465332, postalCode: "73540" },
    { lng: -95.94657, lat: 36.126894, postalCode: "74114" },
    { lng: -95.804231, lat: 36.063095, postalCode: "74102" },
    { lng: -97.85582, lat: 35.152892, postalCode: "73004" },
    { lng: -96.191767, lat: 33.921979, postalCode: "74702" },
    { lng: -96.09233, lat: 34.858091, postalCode: "74570" },
    { lng: -98.96532, lat: 34.637179, postalCode: "73566" },
    { lng: -96.093682, lat: 36.019575, postalCode: "74067" },
    { lng: -97.782595, lat: 36.379072, postalCode: "73706" },
    { lng: -95.04869, lat: 36.394258, postalCode: "74366" },
    { lng: -96.04744, lat: 35.755522, postalCode: "74421" },
    { lng: -95.36797, lat: 34.590491, postalCode: "74536" },
    { lng: -97.47889, lat: 35.504109, postalCode: "73111" },
    { lng: -98.99491, lat: 36.033979, postalCode: "73667" },
    { lng: -98.17995, lat: 35.867757, postalCode: "73764" },
    { lng: -97.5597, lat: 36.311257, postalCode: "73730" },
    { lng: -97.93581, lat: 34.008894, postalCode: "73565" },
    { lng: -97.53499, lat: 35.482309, postalCode: "73106" },
    { lng: -97.54783, lat: 34.4836, postalCode: "73488" },
    { lng: -97.01061, lat: 35.815581, postalCode: "74832" },
    { lng: -94.79763, lat: 34.402346, postalCode: "74724" },
    { lng: -99.1237, lat: 36.223999, postalCode: "73853" },
    { lng: -101.91782, lat: 36.56879, postalCode: "73949" },
    { lng: -98.11946, lat: 34.932493, postalCode: "73017" },
    { lng: -96.751093, lat: 34.244011, postalCode: "73445" },
    { lng: -95.88361, lat: 35.951402, postalCode: "74008" },
    { lng: -99.90944, lat: 34.688567, postalCode: "73550" },
    { lng: -98.75386, lat: 34.62256, postalCode: "73552" },
    { lng: -96.38612, lat: 34.009209, postalCode: "74701" },
    { lng: -98.87586, lat: 35.045196, postalCode: "73041" },
    { lng: -95.90822, lat: 35.203729, postalCode: "74845" },
    { lng: -97.43987, lat: 36.06773, postalCode: "73063" },
    { lng: -94.647526, lat: 36.597953, postalCode: "74334" },
    { lng: -95.67366, lat: 34.391572, postalCode: "74557" },
    { lng: -97.74814, lat: 35.506204, postalCode: "73099" },
    { lng: -95.07793, lat: 34.038794, postalCode: "74764" },
    { lng: -96.13412, lat: 35.369606, postalCode: "74880" },
    { lng: -96.81667, lat: 34.395266, postalCode: "74856" },
    { lng: -95.52647, lat: 35.867529, postalCode: "74454" },
    { lng: -97.26645, lat: 35.465363, postalCode: "73020" },
    { lng: -99.74534, lat: 34.681369, postalCode: "73544" },
    { lng: -96.53926, lat: 35.805114, postalCode: "74028" },
    { lng: -95.93922, lat: 36.059495, postalCode: "74136" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74147" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73185" },
    { lng: -94.98545, lat: 35.127447, postalCode: "74944" },
    { lng: -98.52463, lat: 36.281915, postalCode: "73737" },
    { lng: -97.60045, lat: 34.839562, postalCode: "73052" },
    { lng: -98.65816, lat: 36.692876, postalCode: "73746" },
    { lng: -99.18162, lat: 35.26727, postalCode: "73641" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73198" },
    { lng: -98.23377, lat: 34.273576, postalCode: "73568" },
    { lng: -96.19634, lat: 34.217672, postalCode: "74533" },
    { lng: -97.852223, lat: 34.48532, postalCode: "73536" },
    { lng: -99.6728, lat: 35.603809, postalCode: "73628" },
    { lng: -96.8355, lat: 35.062235, postalCode: "74866" },
    { lng: -95.09627, lat: 35.566657, postalCode: "74435" },
    { lng: -99.0517, lat: 35.167368, postalCode: "73661" },
    { lng: -96.44073, lat: 33.920767, postalCode: "74730" },
    { lng: -94.94148, lat: 34.454046, postalCode: "74722" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73137" },
    { lng: -96.75726, lat: 34.240761, postalCode: "73455" },
    { lng: -95.95414, lat: 36.146143, postalCode: "74104" },
    { lng: -97.55731, lat: 35.386411, postalCode: "73159" },
    { lng: -98.16808, lat: 36.371779, postalCode: "73760" },
    { lng: -96.326053, lat: 35.901277, postalCode: "74046" },
    { lng: -96.48051, lat: 35.49596, postalCode: "74829" },
    { lng: -97.56496, lat: 35.579488, postalCode: "73120" },
    { lng: -98.62492, lat: 34.613786, postalCode: "73527" },
    { lng: -94.65023, lat: 35.815628, postalCode: "74960" },
    { lng: -95.57413, lat: 34.84294, postalCode: "74547" },
    { lng: -98.357423, lat: 35.203206, postalCode: "73094" },
    { lng: -95.49447, lat: 35.519193, postalCode: "74459" },
    { lng: -97.962, lat: 36.844573, postalCode: "73771" },
    { lng: -95.73199, lat: 36.188136, postalCode: "74015" },
    { lng: -98.28297, lat: 36.949448, postalCode: "73719" },
    { lng: -95.02428, lat: 36.523163, postalCode: "74349" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73167" },
    { lng: -96.61818, lat: 34.597595, postalCode: "74842" },
    { lng: -99.28001, lat: 35.394, postalCode: "73626" },
    { lng: -95.96577, lat: 36.909076, postalCode: "74022" },
    { lng: -97.62721, lat: 35.441759, postalCode: "73128" },
    { lng: -94.59266, lat: 35.998632, postalCode: "74965" },
    { lng: -98.04193, lat: 36.285664, postalCode: "73735" },
    { lng: -98.11234, lat: 36.007004, postalCode: "73756" },
    { lng: -97.64135, lat: 35.388023, postalCode: "73169" },
    { lng: -98.16905, lat: 34.748538, postalCode: "73567" },
    { lng: -99.39383, lat: 34.751924, postalCode: "73556" },
    { lng: -98.09063, lat: 36.389599, postalCode: "73754" },
    { lng: -94.62105, lat: 35.247209, postalCode: "74959" },
    { lng: -97.68299, lat: 36.239024, postalCode: "73733" },
    { lng: -94.61829, lat: 35.052793, postalCode: "74953" },
    { lng: -97.44451, lat: 35.208566, postalCode: "73019" },
    { lng: -97.48837, lat: 35.089882, postalCode: "73093" },
    { lng: -97.52496, lat: 35.43366, postalCode: "73109" },
    { lng: -98.46255, lat: 34.613973, postalCode: "73505" },
    { lng: -94.8089, lat: 35.595915, postalCode: "74945" },
    { lng: -95.8847, lat: 36.096296, postalCode: "74145" },
    { lng: -99.77153, lat: 36.614425, postalCode: "73851" },
    { lng: -95.75122, lat: 35.520519, postalCode: "74438" },
    { lng: -95.51443, lat: 36.145085, postalCode: "74036" },
    { lng: -95.98241, lat: 36.012562, postalCode: "74037" },
    { lng: -94.79595, lat: 36.19, postalCode: "74347" },
    { lng: -97.43096, lat: 34.149455, postalCode: "73463" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74153" },
    { lng: -97.56051, lat: 35.42306, postalCode: "73119" },
    { lng: -96.38379, lat: 36.417205, postalCode: "74035" },
    { lng: -96.39018, lat: 33.83464, postalCode: "74720" },
    { lng: -97.76238, lat: 35.654827, postalCode: "73078" },
    { lng: -96.33356, lat: 34.949547, postalCode: "74827" },
    { lng: -95.24701, lat: 35.259201, postalCode: "74472" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73199" },
    { lng: -96.09067, lat: 35.104311, postalCode: "74850" },
    { lng: -97.427464, lat: 35.518509, postalCode: "73140" },
    { lng: -95.92475, lat: 36.101245, postalCode: "74135" },
    { lng: -97.60908, lat: 35.240232, postalCode: "73065" },
    { lng: -97.20505, lat: 36.473704, postalCode: "74651" },
    { lng: -97.99784, lat: 34.16127, postalCode: "73573" },
    { lng: -97.51819, lat: 35.489709, postalCode: "73103" },
    { lng: -96.38778, lat: 35.088636, postalCode: "74848" },
    { lng: -97.202331, lat: 35.535989, postalCode: "73123" },
    { lng: -96.70231, lat: 36.10865, postalCode: "74085" },
    { lng: -96.37159, lat: 36.111943, postalCode: "74044" },
    { lng: -97.562817, lat: 35.491608, postalCode: "73101" },
    { lng: -94.8558, lat: 34.689854, postalCode: "74577" },
    { lng: -94.89649, lat: 34.032184, postalCode: "74737" },
    { lng: -94.83278, lat: 36.981521, postalCode: "74360" },
    { lng: -97.852223, lat: 34.48532, postalCode: "73012" },
    { lng: -95.27642, lat: 34.646758, postalCode: "74574" },
    { lng: -95.97747, lat: 36.151143, postalCode: "74120" },
    { lng: -98.38577, lat: 35.730049, postalCode: "73043" },
    { lng: -99.77425, lat: 35.819529, postalCode: "73638" },
    { lng: -97.16322, lat: 34.634693, postalCode: "73098" },
    { lng: -96.73885, lat: 34.648631, postalCode: "74843" },
    { lng: -96.90609, lat: 33.975243, postalCode: "73440" },
    { lng: -98.03095, lat: 36.685205, postalCode: "73761" },
    { lng: -97.90335, lat: 36.273144, postalCode: "73773" },
    { lng: -96.49278, lat: 34.416915, postalCode: "74530" },
    { lng: -96.31853, lat: 36.542944, postalCode: "74084" },
    { lng: -98.189412, lat: 36.248359, postalCode: "73371" },
    { lng: -97.525467, lat: 35.523758, postalCode: "73154" },
    { lng: -98.75502, lat: 35.739994, postalCode: "73669" },
    { lng: -97.62801, lat: 35.027, postalCode: "73031" },
    { lng: -96.30798, lat: 35.44116, postalCode: "74859" },
    { lng: -97.85026, lat: 36.411133, postalCode: "73701" },
    { lng: -98.37791, lat: 34.478901, postalCode: "73543" },
    { lng: -97.52713, lat: 35.388461, postalCode: "73139" },
    { lng: -97.61334, lat: 36.585776, postalCode: "74640" },
    { lng: -94.44274, lat: 35.347792, postalCode: "74901" },
    { lng: -100.47888, lat: 36.710457, postalCode: "73932" },
    { lng: -99.26866, lat: 36.140186, postalCode: "73859" },
    { lng: -95.85194, lat: 36.146577, postalCode: "74128" },
    { lng: -96.77143, lat: 36.358645, postalCode: "74058" },
    { lng: -97.55044, lat: 34.647208, postalCode: "73434" },
    { lng: -94.55407, lat: 34.138965, postalCode: "74734" },
    { lng: -101.06337, lat: 36.95279, postalCode: "73951" },
    { lng: -95.605964, lat: 36.343579, postalCode: "74018" },
    { lng: -97.28809, lat: 36.308588, postalCode: "73077" },
    { lng: -95.11846, lat: 34.127277, postalCode: "74755" },
    { lng: -96.71234, lat: 33.938714, postalCode: "73439" },
    { lng: -96.935924, lat: 35.306375, postalCode: "74807" },
    { lng: -97.6133, lat: 35.520506, postalCode: "73122" },
    { lng: -97.63934, lat: 35.506456, postalCode: "73008" },
    { lng: -94.60436, lat: 33.784733, postalCode: "74740" },
    { lng: -98.96701, lat: 34.410061, postalCode: "73542" },
    { lng: -94.56575, lat: 35.431711, postalCode: "74948" },
    { lng: -95.957771, lat: 36.048045, postalCode: "74177" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74186" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74170" },
    { lng: -96.75751, lat: 35.973286, postalCode: "74023" },
    { lng: -95.222792, lat: 36.292495, postalCode: "74353" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74182" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74169" },
    { lng: -97.88883, lat: 36.200563, postalCode: "73720" },
    { lng: -97.397509, lat: 35.187611, postalCode: "73070" },
    { lng: -95.72048, lat: 34.926507, postalCode: "74554" },
    { lng: -96.93162, lat: 35.375188, postalCode: "74804" },
    { lng: -95.92953, lat: 36.025405, postalCode: "74137" },
    { lng: -97.49701, lat: 35.395362, postalCode: "73149" },
    { lng: -99.21043, lat: 35.167499, postalCode: "73664" },
    { lng: -95.222792, lat: 36.292495, postalCode: "74362" },
    { lng: -102.2005, lat: 36.844919, postalCode: "73947" },
    { lng: -95.25701, lat: 34.052225, postalCode: "74735" },
    { lng: -97.65189, lat: 35.397259, postalCode: "73097" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73156" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73113" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73144" },
    { lng: -98.27451, lat: 36.557263, postalCode: "73741" },
    { lng: -96.987458, lat: 36.093665, postalCode: "74077" },
    { lng: -97.72414, lat: 36.832446, postalCode: "73759" },
    { lng: -97.055141, lat: 36.072372, postalCode: "74076" },
    { lng: -94.87609, lat: 35.26773, postalCode: "74941" },
    { lng: -98.89818, lat: 36.253795, postalCode: "73838" },
    { lng: -94.98141, lat: 34.898768, postalCode: "74942" },
    { lng: -96.78325, lat: 35.29689, postalCode: "74840" },
    { lng: -98.70827, lat: 35.934444, postalCode: "73658" },
    { lng: -94.96737, lat: 35.742956, postalCode: "74451" },
    { lng: -95.98512, lat: 36.184692, postalCode: "74106" },
    { lng: -95.01088, lat: 33.98471, postalCode: "74750" },
    { lng: -95.80694, lat: 36.045907, postalCode: "74012" },
    { lng: -99.00641, lat: 35.399588, postalCode: "73622" },
    { lng: -97.25495, lat: 35.941421, postalCode: "73050" },
    { lng: -95.04967, lat: 36.46898, postalCode: "74350" },
    { lng: -95.03625, lat: 34.729815, postalCode: "74571" },
    { lng: -95.957721, lat: 36.054345, postalCode: "74171" },
    { lng: -98.91683, lat: 36.13196, postalCode: "73663" },
    { lng: -95.08613, lat: 35.077265, postalCode: "74943" },
    { lng: -95.66668, lat: 35.124644, postalCode: "74430" },
    { lng: -97.8621, lat: 33.916049, postalCode: "73569" },
    { lng: -96.43358, lat: 34.252682, postalCode: "73432" },
    { lng: -97.06278, lat: 34.891218, postalCode: "74831" },
    { lng: -96.56969, lat: 35.514019, postalCode: "74860" },
    { lng: -98.5008, lat: 34.72775, postalCode: "73557" },
    { lng: -95.4894, lat: 33.917165, postalCode: "74738" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74121" },
    { lng: -98.33352, lat: 36.116401, postalCode: "73763" },
    { lng: -96.74013, lat: 36.500388, postalCode: "74650" },
    { lng: -98.55543, lat: 35.303547, postalCode: "73033" },
    { lng: -97.33996, lat: 35.407113, postalCode: "73150" },
    { lng: -99.1744, lat: 35.45544, postalCode: "73647" },
    { lng: -97.73626, lat: 35.388059, postalCode: "73064" },
    { lng: -96.16523, lat: 36.139385, postalCode: "74063" },
    { lng: -94.98452, lat: 36.48721, postalCode: "74340" },
    { lng: -95.31925, lat: 36.167467, postalCode: "74337" },
    { lng: -94.81453, lat: 34.943609, postalCode: "74966" },
    { lng: -95.960472, lat: 36.302391, postalCode: "74156" },
    { lng: -97.55824, lat: 35.447126, postalCode: "73108" },
    { lng: -99.55555, lat: 34.649815, postalCode: "73532" },
    { lng: -97.32739, lat: 35.685368, postalCode: "73007" },
    { lng: -97.62988, lat: 36.153239, postalCode: "73056" },
    { lng: -95.84645, lat: 35.616464, postalCode: "74445" },
    { lng: -98.11312, lat: 34.239192, postalCode: "73548" },
    { lng: -95.15436, lat: 35.966254, postalCode: "74441" },
    { lng: -96.5464, lat: 33.918732, postalCode: "74753" },
    { lng: -94.96494, lat: 33.917419, postalCode: "74736" },
    { lng: -95.68608, lat: 35.815497, postalCode: "74436" },
    { lng: -96.43356, lat: 34.488403, postalCode: "74535" },
    { lng: -99.09374, lat: 35.018385, postalCode: "73651" },
    { lng: -96.02752, lat: 36.051893, postalCode: "74132" },
    { lng: -97.852223, lat: 34.48532, postalCode: "73575" },
    { lng: -97.78458, lat: 35.279882, postalCode: "73089" },
    { lng: -95.95038, lat: 35.447961, postalCode: "74437" },
    { lng: -97.30452, lat: 34.913215, postalCode: "73095" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74141" },
    { lng: -97.33875, lat: 36.939016, postalCode: "74632" },
    { lng: -99.65314, lat: 35.321815, postalCode: "73662" },
    { lng: -97.44686, lat: 35.683768, postalCode: "73034" },
    { lng: -96.32123, lat: 36.764056, postalCode: "74056" },
    { lng: -95.61423, lat: 36.857305, postalCode: "74042" },
    { lng: -99.40838, lat: 36.433665, postalCode: "73801" },
    { lng: -95.97544, lat: 36.60234, postalCode: "74051" },
    { lng: -97.00798, lat: 36.704703, postalCode: "74604" },
    { lng: -97.19507, lat: 35.351612, postalCode: "74857" },
    { lng: -97.43017, lat: 34.363418, postalCode: "73437" },
    { lng: -95.1037, lat: 34.948771, postalCode: "74563" },
    { lng: -98.66403, lat: 35.34525, postalCode: "73021" },
    { lng: -97.00904, lat: 36.323723, postalCode: "73061" },
    { lng: -97.47909, lat: 35.623805, postalCode: "73013" },
    { lng: -97.46209, lat: 34.481151, postalCode: "73487" },
    { lng: -97.10167, lat: 36.707393, postalCode: "74601" },
    { lng: -97.18976, lat: 35.662619, postalCode: "73054" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74150" },
    { lng: -98.58222, lat: 36.648048, postalCode: "73731" },
    { lng: -98.14506, lat: 35.587497, postalCode: "73014" },
    { lng: -96.86109, lat: 34.225742, postalCode: "73447" },
    { lng: -97.62787, lat: 35.416826, postalCode: "73179" },
    { lng: -97.881959, lat: 35.031247, postalCode: "73023" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73164" },
    { lng: -98.38697, lat: 34.894964, postalCode: "73006" },
    { lng: -94.76673, lat: 36.599689, postalCode: "74344" },
    { lng: -94.79752, lat: 35.184593, postalCode: "74930" },
    { lng: -94.63333, lat: 34.956272, postalCode: "74940" },
    { lng: -95.96199, lat: 34.249448, postalCode: "74555" },
    { lng: -97.52036, lat: 35.47156, postalCode: "73102" },
    { lng: -97.29276, lat: 35.039068, postalCode: "73051" },
    { lng: -97.68754, lat: 35.805831, postalCode: "73016" },
    { lng: -97.93552, lat: 35.394725, postalCode: "73090" },
    { lng: -96.55756, lat: 34.630936, postalCode: "74871" },
    { lng: -97.53106, lat: 35.515008, postalCode: "73118" },
    { lng: -98.457646, lat: 34.630879, postalCode: "73506" },
    { lng: -95.798809, lat: 35.918277, postalCode: "74043" },
    { lng: -96.35556, lat: 34.612564, postalCode: "74534" },
    { lng: -99.64674, lat: 36.403804, postalCode: "73840" },
    { lng: -98.457646, lat: 34.630879, postalCode: "73502" },
    { lng: -96.36831, lat: 36.276739, postalCode: "74054" },
    { lng: -95.868667, lat: 36.039147, postalCode: "74101" },
    { lng: -97.8868, lat: 35.978156, postalCode: "73734" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73148" },
    { lng: -96.06384, lat: 36.487394, postalCode: "74001" },
    { lng: -97.32434, lat: 35.491226, postalCode: "73066" },
    { lng: -99.87143, lat: 35.458562, postalCode: "73666" },
    { lng: -98.41223, lat: 35.372736, postalCode: "73053" },
    { lng: -102.33198, lat: 36.531076, postalCode: "79051" },
    { lng: -95.90865, lat: 36.533868, postalCode: "74061" },
    { lng: -96.03144, lat: 36.102042, postalCode: "74107" },
    { lng: -96.146, lat: 36.549109, postalCode: "74002" },
    { lng: -95.09814, lat: 34.660204, postalCode: "74521" },
    { lng: -96.22915, lat: 35.931488, postalCode: "74039" },
    { lng: -97.36956, lat: 33.925768, postalCode: "73430" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74184" },
    { lng: -96.1194, lat: 35.996582, postalCode: "74066" },
    { lng: -94.55278, lat: 34.37769, postalCode: "74963" },
    { lng: -96.57617, lat: 33.869195, postalCode: "74731" },
    { lng: -99.00873, lat: 35.591351, postalCode: "73620" },
    { lng: -95.57691, lat: 34.855223, postalCode: "74546" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73196" },
    { lng: -97.621769, lat: 35.517456, postalCode: "73172" },
    { lng: -95.57854, lat: 35.280145, postalCode: "74432" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74155" },
    { lng: -98.357423, lat: 35.203206, postalCode: "73001" },
    { lng: -97.44693, lat: 35.50356, postalCode: "73121" },
    { lng: -98.3455, lat: 34.359739, postalCode: "73572" },
    { lng: -97.97809, lat: 34.372622, postalCode: "73529" },
    { lng: -97.248074, lat: 34.288884, postalCode: "73403" },
    { lng: -94.72974, lat: 36.603587, postalCode: "74345" },
    { lng: -95.88514, lat: 36.235961, postalCode: "74117" },
    { lng: -95.79958, lat: 36.146897, postalCode: "74108" },
    { lng: -100.22748, lat: 36.903089, postalCode: "73847" },
    { lng: -95.71825, lat: 34.540822, postalCode: "74540" },
    { lng: -95.25512, lat: 36.434328, postalCode: "74330" },
    { lng: -97.06078, lat: 36.904817, postalCode: "74647" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73194" },
    { lng: -99.19074, lat: 35.675986, postalCode: "73625" },
    { lng: -95.9576, lat: 35.51833, postalCode: "74460" },
    { lng: -97.42481, lat: 34.828663, postalCode: "73057" },
    { lng: -99.24851, lat: 35.006152, postalCode: "73655" },
    { lng: -96.77625, lat: 35.129805, postalCode: "74854" },
    { lng: -95.30914, lat: 35.493581, postalCode: "74469" },
    { lng: -98.38429, lat: 36.920802, postalCode: "73722" },
    { lng: -97.69316, lat: 36.347664, postalCode: "73736" },
    { lng: -100.76553, lat: 36.601946, postalCode: "73931" },
    { lng: -99.88181, lat: 36.863262, postalCode: "73855" },
    { lng: -96.17244, lat: 33.888549, postalCode: "74721" },
    { lng: -98.47567, lat: 36.580451, postalCode: "73726" },
    { lng: -94.45263, lat: 35.41483, postalCode: "74946" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73197" },
    { lng: -95.70851, lat: 34.038263, postalCode: "74759" },
    { lng: -97.36406, lat: 34.499735, postalCode: "73444" },
    { lng: -97.52544, lat: 35.568806, postalCode: "73114" },
    { lng: -95.90485, lat: 34.708098, postalCode: "74553" },
    { lng: -102.54473, lat: 36.740652, postalCode: "73933" },
    { lng: -97.16132, lat: 35.491184, postalCode: "73045" },
    { lng: -97.0984, lat: 35.4122, postalCode: "74851" },
    { lng: -95.30693, lat: 34.263342, postalCode: "74562" },
    { lng: -97.93843, lat: 34.243494, postalCode: "73520" },
    { lng: -96.754575, lat: 34.955971, postalCode: "74894" },
    { lng: -98.43667, lat: 35.137863, postalCode: "73038" },
    { lng: -95.56851, lat: 35.61546, postalCode: "74468" },
    { lng: -94.808868, lat: 34.061674, postalCode: "74752" },
    { lng: -97.465436, lat: 35.666481, postalCode: "73083" },
    { lng: -96.98606, lat: 34.49284, postalCode: "73086" },
    { lng: -99.38849, lat: 35.646142, postalCode: "73650" },
    { lng: -97.95185, lat: 34.502833, postalCode: "73533" },
    { lng: -98.96369, lat: 34.50847, postalCode: "73555" },
    { lng: -97.92836, lat: 36.402666, postalCode: "73703" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74013" },
    { lng: -96.43012, lat: 34.367986, postalCode: "73461" },
    { lng: -96.00205, lat: 35.853188, postalCode: "74047" },
    { lng: -97.55801, lat: 34.447003, postalCode: "73425" },
    { lng: -96.11276, lat: 34.347553, postalCode: "74525" },
    { lng: -96.398424, lat: 36.665794, postalCode: "74009" },
    { lng: -95.15158, lat: 35.490036, postalCode: "74470" },
    { lng: -99.13004, lat: 36.456875, postalCode: "73852" },
    { lng: -96.46385, lat: 35.348331, postalCode: "74837" },
    { lng: -96.05434, lat: 35.262169, postalCode: "74839" },
    { lng: -96.29927, lat: 33.77061, postalCode: "74741" },
    { lng: -99.88179, lat: 36.673357, postalCode: "73848" },
    { lng: -94.93507, lat: 34.536633, postalCode: "74549" },
    { lng: -99.89028, lat: 36.226355, postalCode: "73858" },
    { lng: -96.58776, lat: 35.987421, postalCode: "74030" },
    { lng: -98.80897, lat: 35.370775, postalCode: "73024" },
    { lng: -97.45366, lat: 36.821862, postalCode: "74646" },
    { lng: -96.23084, lat: 34.553763, postalCode: "74538" },
    { lng: -97.55974, lat: 36.455282, postalCode: "73738" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73147" },
    { lng: -99.99228, lat: 35.219721, postalCode: "73668" },
    { lng: -96.86091, lat: 36.807276, postalCode: "74641" },
    { lng: -98.27411, lat: 36.383753, postalCode: "73768" },
    { lng: -94.85945, lat: 36.20822, postalCode: "74368" },
    { lng: -98.86623, lat: 36.56306, postalCode: "73860" },
    { lng: -95.306916, lat: 35.764223, postalCode: "74402" },
    { lng: -95.80542, lat: 35.992283, postalCode: "74011" },
    { lng: -97.04258, lat: 35.968675, postalCode: "74059" },
    { lng: -95.98674, lat: 36.236691, postalCode: "74126" },
    { lng: -94.92621, lat: 36.420495, postalCode: "74342" },
    { lng: -95.36911, lat: 34.023191, postalCode: "74756" },
    { lng: -99.15211, lat: 34.645375, postalCode: "73549" },
    { lng: -97.29289, lat: 36.800787, postalCode: "74631" },
    { lng: -99.5897, lat: 36.851116, postalCode: "73834" },
    { lng: -94.68768, lat: 36.777702, postalCode: "74370" },
    { lng: -97.23455, lat: 35.821107, postalCode: "73058" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74183" },
    { lng: -98.43533, lat: 34.188182, postalCode: "73562" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73190" },
    { lng: -95.99508, lat: 36.156892, postalCode: "74103" },
    { lng: -97.28198, lat: 35.22704, postalCode: "73026" },
    { lng: -96.54409, lat: 33.990978, postalCode: "73449" },
    { lng: -97.99385, lat: 36.563732, postalCode: "73743" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73152" },
    { lng: -96.68338, lat: 36.241861, postalCode: "74045" },
    { lng: -95.20104, lat: 34.01528, postalCode: "74761" },
    { lng: -95.40711, lat: 35.739681, postalCode: "74401" },
    { lng: -95.8305, lat: 36.278298, postalCode: "74055" },
    { lng: -95.23098, lat: 35.127495, postalCode: "74552" },
    { lng: -99.383928, lat: 34.723795, postalCode: "73522" },
    { lng: -97.63765, lat: 35.579254, postalCode: "73162" },
    { lng: -95.48434, lat: 35.59824, postalCode: "74450" },
    { lng: -96.68761, lat: 34.780243, postalCode: "74820" },
    { lng: -95.86763, lat: 36.126446, postalCode: "74129" },
    { lng: -95.8397, lat: 36.369251, postalCode: "74021" },
    { lng: -94.51763, lat: 34.991767, postalCode: "74947" },
    { lng: -98.23984, lat: 35.050671, postalCode: "73005" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73157" },
    { lng: -96.69529, lat: 36.577618, postalCode: "74637" },
    { lng: -95.8613, lat: 34.026768, postalCode: "74727" },
    { lng: -96.26802, lat: 35.778763, postalCode: "74071" },
    { lng: -94.54108, lat: 35.138765, postalCode: "74932" },
    { lng: -95.78336, lat: 36.935821, postalCode: "74083" },
    { lng: -97.973331, lat: 34.518944, postalCode: "73534" },
    { lng: -95.51651, lat: 34.010943, postalCode: "74743" },
    { lng: -101.25618, lat: 36.832751, postalCode: "73945" },
    { lng: -94.877031, lat: 36.83408, postalCode: "74355" },
    { lng: -95.3611, lat: 35.961019, postalCode: "74467" },
    { lng: -98.62902, lat: 35.826514, postalCode: "73646" },
    { lng: -94.87142, lat: 36.880746, postalCode: "74354" },
    { lng: -99.12375, lat: 34.500298, postalCode: "73570" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74189" },
    { lng: -95.69073, lat: 34.902286, postalCode: "74522" },
    { lng: -94.787091, lat: 36.415938, postalCode: "74359" },
    { lng: -94.78345, lat: 36.444215, postalCode: "74346" },
    { lng: -94.78647, lat: 35.467031, postalCode: "74955" },
    { lng: -94.87134, lat: 36.932957, postalCode: "74339" },
    { lng: -97.248074, lat: 34.288884, postalCode: "73402" },
    { lng: -95.43604, lat: 34.373618, postalCode: "74543" },
    { lng: -97.750009, lat: 35.489527, postalCode: "73085" },
    { lng: -97.49267, lat: 35.431361, postalCode: "73129" },
    { lng: -97.57284, lat: 35.517857, postalCode: "73112" },
    { lng: -96.03074, lat: 34.665422, postalCode: "74576" },
    { lng: -98.39414, lat: 34.608348, postalCode: "73501" },
    { lng: -95.57108, lat: 36.957786, postalCode: "74072" },
    { lng: -94.96628, lat: 35.543155, postalCode: "74962" },
    { lng: -95.88417, lat: 36.04309, postalCode: "74133" },
    { lng: -97.39314, lat: 35.498461, postalCode: "73141" },
    { lng: -98.14881, lat: 36.224663, postalCode: "73718" },
    { lng: -95.29859, lat: 34.886021, postalCode: "74578" },
    { lng: -98.65892, lat: 34.425098, postalCode: "73528" },
    { lng: -95.71144, lat: 36.437088, postalCode: "74053" },
    { lng: -96.669321, lat: 34.735301, postalCode: "74821" },
    { lng: -95.30295, lat: 36.294174, postalCode: "74361" },
    { lng: -95.513856, lat: 35.963567, postalCode: "74477" },
    { lng: -94.85161, lat: 36.975692, postalCode: "74335" },
    { lng: -99.3952, lat: 34.976173, postalCode: "73547" },
    { lng: -97.9436, lat: 34.909551, postalCode: "73067" },
    { lng: -97.4843, lat: 34.354547, postalCode: "73435" },
    { lng: -99.30835, lat: 34.776114, postalCode: "73526" },
    { lng: -97.50121, lat: 34.43664, postalCode: "73481" },
    { lng: -102.92339, lat: 36.922263, postalCode: "73946" },
    { lng: -102.78868, lat: 36.563865, postalCode: "73937" },
    { lng: -95.66931, lat: 35.645974, postalCode: "74422" },
    { lng: -98.71259, lat: 35.527593, postalCode: "73096" },
    { lng: -96.81397, lat: 34.618331, postalCode: "74865" },
    { lng: -95.41013, lat: 34.454702, postalCode: "74567" },
    { lng: -96.58838, lat: 34.878209, postalCode: "74844" },
    { lng: -97.22846, lat: 34.741677, postalCode: "73075" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74157" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73177" },
    { lng: -95.54393, lat: 35.443872, postalCode: "74426" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74193" },
    { lng: -98.19821, lat: 34.809495, postalCode: "73541" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73143" },
    { lng: -95.54788, lat: 35.76342, postalCode: "74463" },
    { lng: -96.24114, lat: 35.235118, postalCode: "74853" },
    { lng: -97.83063, lat: 36.662715, postalCode: "73766" },
    { lng: -99.31787, lat: 34.638773, postalCode: "73521" },
    { lng: -96.65915, lat: 35.249655, postalCode: "74868" },
    { lng: -99.58042, lat: 35.04655, postalCode: "73673" },
    { lng: -97.62121, lat: 34.165558, postalCode: "73456" },
    { lng: -97.39844, lat: 35.433093, postalCode: "73145" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73155" },
    { lng: -94.83154, lat: 33.883578, postalCode: "74745" },
    { lng: -97.93235, lat: 34.637595, postalCode: "73055" },
    { lng: -95.720708, lat: 34.947259, postalCode: "74529" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73146" },
    { lng: -96.93544, lat: 35.327341, postalCode: "74801" },
    { lng: -96.89689, lat: 35.507952, postalCode: "74855" },
    { lng: -98.32899, lat: 36.221983, postalCode: "73747" },
    { lng: -97.07631, lat: 36.105571, postalCode: "74074" },
    { lng: -99.712, lat: 36.122573, postalCode: "73832" },
    { lng: -96.69141, lat: 35.490727, postalCode: "74864" },
    { lng: -96.68124, lat: 34.253452, postalCode: "73460" },
    { lng: -98.04938, lat: 36.949144, postalCode: "73758" },
    { lng: -96.03998, lat: 36.381305, postalCode: "74070" },
    { lng: -98.19738, lat: 34.907566, postalCode: "73029" },
    { lng: -95.6992, lat: 36.527631, postalCode: "74080" },
    { lng: -97.033905, lat: 34.293948, postalCode: "73436" },
    { lng: -99.680146, lat: 35.26946, postalCode: "73648" },
    { lng: -97.35183, lat: 35.459512, postalCode: "73130" },
    { lng: -95.92404, lat: 36.733398, postalCode: "74006" },
    { lng: -101.1204, lat: 36.605467, postalCode: "73944" },
    { lng: -98.2484, lat: 35.218293, postalCode: "73042" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74148" },
    { lng: -99.23866, lat: 35.986173, postalCode: "73835" },
    { lng: -95.95424, lat: 36.181143, postalCode: "74110" },
    { lng: -95.10556, lat: 36.296196, postalCode: "74365" },
    { lng: -97.11031, lat: 33.9401, postalCode: "73448" },
    { lng: -99.90119, lat: 35.851512, postalCode: "73642" },
    { lng: -97.326151, lat: 35.61534, postalCode: "73136" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74192" },
    { lng: -98.69707, lat: 36.804875, postalCode: "73717" },
    { lng: -97.94723, lat: 35.040526, postalCode: "73018" },
    { lng: -96.38183, lat: 35.843486, postalCode: "74010" },
    { lng: -95.18582, lat: 35.668132, postalCode: "74423" },
    { lng: -99.17705, lat: 35.355453, postalCode: "73624" },
    { lng: -96.53394, lat: 34.948211, postalCode: "74867" },
    { lng: -95.571646, lat: 36.336457, postalCode: "74031" },
    { lng: -98.43772, lat: 35.870276, postalCode: "73772" },
    { lng: -96.73934, lat: 36.694585, postalCode: "74633" },
    { lng: -97.99188, lat: 35.618487, postalCode: "73022" },
    { lng: -97.29596, lat: 35.572909, postalCode: "73049" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73180" },
    { lng: -94.96682, lat: 36.053001, postalCode: "74444" },
    { lng: -96.54837, lat: 34.273995, postalCode: "73450" },
    { lng: -95.75709, lat: 34.944399, postalCode: "74501" },
    { lng: -94.70368, lat: 35.392525, postalCode: "74936" },
    { lng: -96.91873, lat: 35.012407, postalCode: "74826" },
    { lng: -96.56882, lat: 36.232452, postalCode: "74034" },
    { lng: -96.88961, lat: 35.702159, postalCode: "74834" },
    { lng: -96.959601, lat: 35.365621, postalCode: "74802" },
    { lng: -99.86736, lat: 34.924584, postalCode: "73571" },
    { lng: -95.16801, lat: 36.148065, postalCode: "74352" },
    { lng: -97.66381, lat: 35.118392, postalCode: "73010" },
    { lng: -98.32451, lat: 35.463837, postalCode: "73047" },
    { lng: -95.98872, lat: 36.142743, postalCode: "74119" },
    { lng: -97.45245, lat: 36.267701, postalCode: "73757" },
    { lng: -96.74851, lat: 34.092658, postalCode: "73446" },
    { lng: -98.171022, lat: 34.752406, postalCode: "73367" },
    { lng: -97.28894, lat: 34.175234, postalCode: "73443" },
    { lng: -96.56083, lat: 36.164934, postalCode: "74038" },
    { lng: -96.5066, lat: 35.166644, postalCode: "74884" },
    { lng: -96.20366, lat: 34.471798, postalCode: "74556" },
    { lng: -97.782595, lat: 36.379072, postalCode: "73702" },
    { lng: -97.36747, lat: 35.338689, postalCode: "73165" },
    { lng: -97.93743, lat: 35.856216, postalCode: "73750" },
    { lng: -95.881381, lat: 36.450115, postalCode: "74082" },
    { lng: -97.032304, lat: 36.68125, postalCode: "74064" },
    { lng: -97.51795, lat: 36.810451, postalCode: "74636" },
    { lng: -97.32967, lat: 36.665613, postalCode: "74653" },
    { lng: -94.72058, lat: 35.122751, postalCode: "74956" },
    { lng: -99.84131, lat: 36.41954, postalCode: "73843" },
    { lng: -96.66342, lat: 35.744531, postalCode: "74079" },
    { lng: -97.74453, lat: 34.936221, postalCode: "73002" },
    { lng: -95.94597, lat: 35.458946, postalCode: "74431" },
    { lng: -100.61244, lat: 36.914169, postalCode: "73938" },
    { lng: -98.93351, lat: 35.70364, postalCode: "73639" },
    { lng: -99.28051, lat: 34.457367, postalCode: "73539" },
    { lng: -95.64874, lat: 35.550269, postalCode: "74428" },
    { lng: -97.782595, lat: 36.379072, postalCode: "73705" },
    { lng: -95.91129, lat: 36.180144, postalCode: "74115" },
    { lng: -97.64479, lat: 35.355645, postalCode: "73173" },
    { lng: -97.34173, lat: 35.52106, postalCode: "73084" },
    { lng: -96.68054, lat: 36.830328, postalCode: "74652" },
    { lng: -98.54418, lat: 35.471566, postalCode: "73048" },
    { lng: -95.8242, lat: 36.169454, postalCode: "74116" },
    { lng: -99.57425, lat: 36.557966, postalCode: "73841" },
    { lng: -98.01237, lat: 35.313109, postalCode: "73059" },
    { lng: -98.35804, lat: 35.292476, postalCode: "73009" },
    { lng: -99.91743, lat: 35.610122, postalCode: "73660" },
    { lng: -96.661745, lat: 34.772557, postalCode: "74520" },
    { lng: -100.89584, lat: 36.852475, postalCode: "73950" },
    { lng: -94.69267, lat: 36.260756, postalCode: "74328" },
    { lng: -99.32679, lat: 36.283175, postalCode: "73857" },
    { lng: -97.2718, lat: 34.831083, postalCode: "73074" },
    { lng: -97.07043, lat: 35.694734, postalCode: "74881" },
    { lng: -94.88039, lat: 36.915805, postalCode: "74358" },
    { lng: -98.46808, lat: 34.69727, postalCode: "73503" },
    { lng: -97.12655, lat: 34.184742, postalCode: "73401" },
    { lng: -96.5029, lat: 33.84836, postalCode: "74733" },
    { lng: -95.513856, lat: 35.963567, postalCode: "74458" },
    { lng: -94.92552, lat: 35.700818, postalCode: "74427" },
    { lng: -96.47772, lat: 34.163069, postalCode: "74748" },
    { lng: -97.48895, lat: 34.238486, postalCode: "73438" },
    { lng: -95.78885, lat: 34.66625, postalCode: "74560" },
    { lng: -95.44592, lat: 36.546415, postalCode: "74016" },
    { lng: -98.97568, lat: 34.834172, postalCode: "73564" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73178" },
    { lng: -95.1316, lat: 34.497876, postalCode: "74558" },
    { lng: -98.1728, lat: 36.702485, postalCode: "73749" },
    { lng: -97.106166, lat: 36.796349, postalCode: "74603" },
    { lng: -94.90421, lat: 34.951143, postalCode: "74935" },
    { lng: -97.23958, lat: 34.337522, postalCode: "73458" },
    { lng: -97.06156, lat: 35.012317, postalCode: "74878" },
    { lng: -96.00803, lat: 35.951244, postalCode: "74033" },
    { lng: -101.07462, lat: 36.756145, postalCode: "73901" },
    { lng: -96.2674, lat: 36.24973, postalCode: "74060" },
    { lng: -97.05784, lat: 36.149394, postalCode: "74075" },
    { lng: -96.23929, lat: 34.932628, postalCode: "74531" },
    { lng: -95.221099, lat: 34.869457, postalCode: "74559" },
    { lng: -97.71386, lat: 34.868514, postalCode: "73011" },
    { lng: -97.95534, lat: 35.519046, postalCode: "73036" },
    { lng: -98.04144, lat: 36.481331, postalCode: "73727" },
    { lng: -97.48596, lat: 35.34377, postalCode: "73160" },
    { lng: -98.27805, lat: 34.747379, postalCode: "73538" },
    { lng: -97.42394, lat: 35.225702, postalCode: "73071" },
    { lng: -96.15629, lat: 33.98526, postalCode: "74726" },
    { lng: -98.9029, lat: 35.848743, postalCode: "73659" },
    { lng: -95.12064, lat: 34.199682, postalCode: "74754" },
    { lng: -94.95546, lat: 34.148691, postalCode: "74766" },
    { lng: -97.300429, lat: 34.680871, postalCode: "73476" },
    { lng: -98.58643, lat: 35.113271, postalCode: "73015" },
    { lng: -96.083477, lat: 36.077221, postalCode: "74159" },
    { lng: -96.9767, lat: 35.231608, postalCode: "74873" },
    { lng: -98.29013, lat: 35.966488, postalCode: "73744" },
    { lng: -94.87402, lat: 35.837827, postalCode: "74471" },
    { lng: -97.6423, lat: 35.481507, postalCode: "73127" },
    { lng: -98.43861, lat: 36.404703, postalCode: "73729" },
    { lng: -99.67861, lat: 34.49515, postalCode: "73537" },
    { lng: -95.70957, lat: 36.053246, postalCode: "74014" },
    { lng: -97.97217, lat: 35.713606, postalCode: "73762" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73124" },
    { lng: -100.06947, lat: 36.853151, postalCode: "73844" },
    { lng: -95.61603, lat: 34.229335, postalCode: "74523" },
    { lng: -97.50211, lat: 35.509659, postalCode: "73105" },
    { lng: -95.31717, lat: 35.852293, postalCode: "74446" },
    { lng: -97.39646, lat: 35.017656, postalCode: "73080" },
    { lng: -94.67157, lat: 34.503213, postalCode: "74957" },
    { lng: -96.6696, lat: 35.147566, postalCode: "74830" },
    { lng: -94.6335, lat: 36.114272, postalCode: "74964" },
    { lng: -99.41658, lat: 35.405935, postalCode: "73644" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74149" },
    { lng: -99.18304, lat: 36.803902, postalCode: "73842" },
    { lng: -97.05041, lat: 34.400476, postalCode: "73032" },
    { lng: -96.86735, lat: 35.883914, postalCode: "74824" },
    { lng: -97.57336, lat: 35.484558, postalCode: "73107" },
    { lng: -96.44005, lat: 36.169806, postalCode: "74081" },
    { lng: -96.95179, lat: 35.876736, postalCode: "74875" },
    { lng: -96.73899, lat: 34.969783, postalCode: "74849" },
    { lng: -95.62751, lat: 36.690587, postalCode: "74048" },
    { lng: -94.84022, lat: 36.747928, postalCode: "74343" },
    { lng: -98.57918, lat: 36.14001, postalCode: "73755" },
    { lng: -96.7777, lat: 35.601113, postalCode: "74869" },
    { lng: -97.55637, lat: 35.544864, postalCode: "73116" },
    { lng: -96.39586, lat: 36.260645, postalCode: "74020" },
    { lng: -98.13462, lat: 36.521836, postalCode: "73739" },
    { lng: -101.738, lat: 36.691413, postalCode: "73939" },
    { lng: -98.96917, lat: 35.508248, postalCode: "73601" },
    { lng: -95.992323, lat: 36.154342, postalCode: "74172" },
    { lng: -96.4108, lat: 34.817511, postalCode: "74825" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74194" },
    { lng: -96.23571, lat: 35.232465, postalCode: "74883" },
    { lng: -94.63761, lat: 35.398035, postalCode: "74968" },
    { lng: -97.14753, lat: 34.485896, postalCode: "73030" },
    { lng: -94.7768, lat: 36.053046, postalCode: "74457" },
    { lng: -95.90841, lat: 36.148444, postalCode: "74112" },
    { lng: -95.6377, lat: 35.94785, postalCode: "74429" },
    { lng: -97.63071, lat: 35.96375, postalCode: "73028" },
    { lng: -95.894042, lat: 36.711386, postalCode: "74004" },
    { lng: -97.13848, lat: 33.794716, postalCode: "73459" },
    { lng: -96.039727, lat: 34.4187, postalCode: "74542" },
    { lng: -95.9734, lat: 35.628612, postalCode: "74447" },
    { lng: -95.894042, lat: 36.711386, postalCode: "74005" },
    { lng: -94.873005, lat: 36.932944, postalCode: "74399" },
    { lng: -97.63042, lat: 35.605785, postalCode: "73142" },
    { lng: -98.64291, lat: 36.06141, postalCode: "73724" },
    { lng: -97.00869, lat: 35.123383, postalCode: "74852" },
    { lng: -96.90773, lat: 36.024668, postalCode: "74062" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73189" },
    { lng: -98.01195, lat: 35.210855, postalCode: "73079" },
    { lng: -98.86104, lat: 34.325802, postalCode: "73551" },
    { lng: -97.43176, lat: 35.857303, postalCode: "73044" },
    { lng: -97.5331, lat: 35.681402, postalCode: "73003" },
    { lng: -96.00614, lat: 36.741589, postalCode: "74003" },
    { lng: -97.36279, lat: 36.151316, postalCode: "73073" },
    { lng: -95.07782, lat: 36.802232, postalCode: "74333" },
    { lng: -95.37358, lat: 34.146827, postalCode: "74760" },
    { lng: -96.97738, lat: 34.793533, postalCode: "74872" },
    { lng: -96.0619, lat: 36.074141, postalCode: "74050" },
    { lng: -97.45629, lat: 35.228649, postalCode: "73069" },
    { lng: -99.45164, lat: 34.571329, postalCode: "73560" },
    { lng: -95.83967, lat: 34.831398, postalCode: "74565" },
    { lng: -98.54891, lat: 34.209826, postalCode: "73531" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73184" },
    { lng: -97.407537, lat: 35.551409, postalCode: "73126" },
    { lng: -96.987458, lat: 36.093665, postalCode: "74078" },
    { lng: -95.16328, lat: 35.272967, postalCode: "74462" },
    { lng: -97.521816, lat: 35.465418, postalCode: "73125" },
    { lng: -97.32689, lat: 35.138421, postalCode: "73068" },
    { lng: -99.36845, lat: 35.837115, postalCode: "73654" },
    { lng: -94.96707, lat: 36.197948, postalCode: "74364" },
    { lng: -97.24379, lat: 34.033426, postalCode: "73453" },
    { lng: -96.43083, lat: 34.626083, postalCode: "74572" },
    { lng: -95.22924, lat: 36.51509, postalCode: "74332" },
    { lng: -94.65008, lat: 34.712466, postalCode: "74939" },
    { lng: -101.48851, lat: 36.739447, postalCode: "73942" },
    { lng: -97.14483, lat: 36.554757, postalCode: "74644" },
    { lng: -97.43304, lat: 36.52791, postalCode: "74630" },
    { lng: -96.02081, lat: 36.306323, postalCode: "74073" },
    { lng: -97.63571, lat: 35.552755, postalCode: "73132" },
    { lng: -96.63092, lat: 34.451503, postalCode: "74836" },
    { lng: -95.30205, lat: 35.269955, postalCode: "74440" },
    { lng: -95.15249, lat: 36.897777, postalCode: "74369" },
    { lng: -97.47411, lat: 35.476457, postalCode: "73117" },
    { lng: -98.96184, lat: 34.696985, postalCode: "73559" },
    { lng: -94.796399, lat: 36.422876, postalCode: "74336" },
    { lng: -94.93424, lat: 36.618326, postalCode: "74331" },
    { lng: -94.80269, lat: 34.142978, postalCode: "74728" },
    { lng: -94.67798, lat: 36.227439, postalCode: "74338" },
    { lng: -95.92617, lat: 36.808722, postalCode: "74029" },
    { lng: -95.11169, lat: 36.10862, postalCode: "74452" },
    { lng: -98.08247, lat: 35.109275, postalCode: "73092" },
    { lng: -96.029725, lat: 36.139826, postalCode: "74152" },
    { lng: -97.76274, lat: 45.612148, postalCode: "57454" },
    { lng: -96.96107, lat: 43.797629, postalCode: "57018" },
    { lng: -98.65671, lat: 45.151166, postalCode: "57465" },
    { lng: -97.53946, lat: 43.214133, postalCode: "57045" },
    { lng: -96.67784, lat: 44.287058, postalCode: "57002" },
    { lng: -97.47891, lat: 45.084154, postalCode: "57272" },
    { lng: -96.67817, lat: 43.424257, postalCode: "57032" },
    { lng: -98.08876, lat: 44.863792, postalCode: "57436" },
    { lng: -100.04843, lat: 45.486222, postalCode: "57472" },
    { lng: -96.79467, lat: 45.104925, postalCode: "57264" },
    { lng: -97.40958, lat: 42.888538, postalCode: "57078" },
    { lng: -96.69785, lat: 42.728534, postalCode: "57025" },
    { lng: -96.49955, lat: 42.524771, postalCode: "57049" },
    { lng: -97.10544, lat: 45.278474, postalCode: "57266" },
    { lng: -98.14355, lat: 45.032244, postalCode: "57477" },
    { lng: -99.02218, lat: 45.43694, postalCode: "57451" },
    { lng: -96.69548, lat: 44.790157, postalCode: "57226" },
    { lng: -97.38526, lat: 43.73161, postalCode: "57058" },
    { lng: -96.7906, lat: 44.305418, postalCode: "57006" },
    { lng: -97.54435, lat: 45.536715, postalCode: "57261" },
    { lng: -97.01425, lat: 44.723825, postalCode: "57223" },
    { lng: -98.20936, lat: 44.377801, postalCode: "57350" },
    { lng: -99.01332, lat: 44.070421, postalCode: "57341" },
    { lng: -96.790705, lat: 44.369696, postalCode: "57007" },
    { lng: -97.98222, lat: 44.492158, postalCode: "57386" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57118" },
    { lng: -99.1345, lat: 45.06623, postalCode: "57438" },
    { lng: -96.57657, lat: 42.585305, postalCode: "57038" },
    { lng: -97.3198, lat: 45.489637, postalCode: "57239" },
    { lng: -98.35489, lat: 43.302262, postalCode: "57313" },
    { lng: -96.91759, lat: 44.459833, postalCode: "57220" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57192" },
    { lng: -97.08438, lat: 42.846853, postalCode: "57044" },
    { lng: -97.94616, lat: 43.220164, postalCode: "57376" },
    { lng: -99.16628, lat: 43.070066, postalCode: "57538" },
    { lng: -98.704197, lat: 45.632378, postalCode: "57841" },
    { lng: -99.84288, lat: 45.253428, postalCode: "57450" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57197" },
    { lng: -98.37219, lat: 44.625453, postalCode: "57348" },
    { lng: -99.42096, lat: 43.229236, postalCode: "57533" },
    { lng: -97.79178, lat: 44.008344, postalCode: "57337" },
    { lng: -96.5922, lat: 44.652381, postalCode: "57218" },
    { lng: -97.86353, lat: 42.991775, postalCode: "57066" },
    { lng: -98.86967, lat: 44.899458, postalCode: "57470" },
    { lng: -96.95951, lat: 44.102243, postalCode: "57057" },
    { lng: -98.65019, lat: 45.26493, postalCode: "57460" },
    { lng: -97.68489, lat: 44.623706, postalCode: "57278" },
    { lng: -97.2884, lat: 42.945468, postalCode: "57046" },
    { lng: -98.04565, lat: 44.305089, postalCode: "57324" },
    { lng: -97.57442, lat: 44.374289, postalCode: "57231" },
    { lng: -99.48135, lat: 45.213867, postalCode: "57466" },
    { lng: -97.92723, lat: 45.755146, postalCode: "57421" },
    { lng: -96.73032, lat: 43.668009, postalCode: "57055" },
    { lng: -96.76115, lat: 43.327859, postalCode: "57077" },
    { lng: -99.44519, lat: 44.601978, postalCode: "57345" },
    { lng: -101.05768, lat: 43.321116, postalCode: "57566" },
    { lng: -98.71147, lat: 44.428879, postalCode: "57381" },
    { lng: -99.76851, lat: 45.041882, postalCode: "57455" },
    { lng: -98.03185, lat: 43.711862, postalCode: "57301" },
    { lng: -97.63714, lat: 43.565083, postalCode: "57332" },
    { lng: -98.27899, lat: 45.287179, postalCode: "57474" },
    { lng: -98.70407, lat: 43.69412, postalCode: "57383" },
    { lng: -100.09434, lat: 45.700753, postalCode: "57646" },
    { lng: -96.99369, lat: 43.268424, postalCode: "57021" },
    { lng: -98.29838, lat: 43.045048, postalCode: "57380" },
    { lng: -98.51408, lat: 43.144738, postalCode: "57356" },
    { lng: -97.31065, lat: 45.053712, postalCode: "57235" },
    { lng: -96.86695, lat: 43.723468, postalCode: "57041" },
    { lng: -100.74257, lat: 43.889878, postalCode: "57559" },
    { lng: -99.50178, lat: 45.020366, postalCode: "57473" },
    { lng: -97.35251, lat: 44.775741, postalCode: "57242" },
    { lng: -97.756924, lat: 43.334069, postalCode: "57354" },
    { lng: -98.77372, lat: 45.425378, postalCode: "57462" },
    { lng: -96.9563, lat: 43.111838, postalCode: "57014" },
    { lng: -98.63556, lat: 44.051324, postalCode: "57382" },
    { lng: -99.81844, lat: 43.026834, postalCode: "57578" },
    { lng: -100.38483, lat: 43.557963, postalCode: "57585" },
    { lng: -97.13077, lat: 43.194321, postalCode: "57070" },
    { lng: -99.7528, lat: 43.630273, postalCode: "57534" },
    { lng: -96.80101, lat: 43.577696, postalCode: "57107" },
    { lng: -96.74702, lat: 43.726266, postalCode: "57003" },
    { lng: -96.5024, lat: 45.285323, postalCode: "57216" },
    { lng: -98.92417, lat: 45.160833, postalCode: "57435" },
    { lng: -97.23974, lat: 44.416191, postalCode: "57244" },
    { lng: -97.73978, lat: 45.827391, postalCode: "57430" },
    { lng: -98.04587, lat: 43.006529, postalCode: "57315" },
    { lng: -99.131736, lat: 43.71725, postalCode: "57326" },
    { lng: -100.529517, lat: 44.475844, postalCode: "57557" },
    { lng: -96.55366, lat: 45.013469, postalCode: "57259" },
    { lng: -97.78645, lat: 45.278469, postalCode: "57219" },
    { lng: -96.65524, lat: 43.917178, postalCode: "57065" },
    { lng: -98.16163, lat: 43.261271, postalCode: "57330" },
    { lng: -96.51959, lat: 43.134318, postalCode: "57034" },
    { lng: -100.64693, lat: 43.233874, postalCode: "57555" },
    { lng: -98.3734, lat: 44.195319, postalCode: "57312" },
    { lng: -99.62537, lat: 45.223419, postalCode: "57475" },
    { lng: -100.12424, lat: 45.327593, postalCode: "57420" },
    { lng: -100.28391, lat: 45.592102, postalCode: "57631" },
    { lng: -96.81757, lat: 43.672525, postalCode: "57020" },
    { lng: -96.95926, lat: 43.89916, postalCode: "57016" },
    { lng: -97.94713, lat: 43.391523, postalCode: "57366" },
    { lng: -97.76631, lat: 43.645927, postalCode: "57311" },
    { lng: -98.13416, lat: 45.458312, postalCode: "57445" },
    { lng: -99.11828, lat: 44.84796, postalCode: "57467" },
    { lng: -100.05209, lat: 45.007901, postalCode: "57442" },
    { lng: -98.30102, lat: 45.499572, postalCode: "57427" },
    { lng: -98.083721, lat: 45.32904, postalCode: "57439" },
    { lng: -97.24905, lat: 43.104527, postalCode: "57037" },
    { lng: -97.2048, lat: 42.989201, postalCode: "57072" },
    { lng: -96.84498, lat: 43.450592, postalCode: "57064" },
    { lng: -96.6092, lat: 45.215805, postalCode: "57252" },
    { lng: -97.15011, lat: 45.859112, postalCode: "57224" },
    { lng: -99.77535, lat: 44.461748, postalCode: "57536" },
    { lng: -97.03365, lat: 45.128333, postalCode: "57263" },
    { lng: -97.0545, lat: 43.005861, postalCode: "57073" },
    { lng: -98.47279, lat: 44.414842, postalCode: "57384" },
    { lng: -97.688, lat: 45.085704, postalCode: "57217" },
    { lng: -97.21584, lat: 44.695445, postalCode: "57241" },
    { lng: -98.319296, lat: 45.163452, postalCode: "57429" },
    { lng: -98.51539, lat: 45.834246, postalCode: "57441" },
    { lng: -97.58996, lat: 44.971494, postalCode: "57236" },
    { lng: -98.97227, lat: 43.104344, postalCode: "57317" },
    { lng: -101.00351, lat: 43.908211, postalCode: "57562" },
    { lng: -98.01846, lat: 45.674523, postalCode: "57432" },
    { lng: -96.91988, lat: 45.859799, postalCode: "57255" },
    { lng: -96.79091, lat: 43.069991, postalCode: "57004" },
    { lng: -99.28191, lat: 43.190783, postalCode: "57523" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57189" },
    { lng: -97.18354, lat: 44.578302, postalCode: "57248" },
    { lng: -99.93186, lat: 43.615797, postalCode: "57541" },
    { lng: -97.21919, lat: 43.50934, postalCode: "57047" },
    { lng: -97.13198, lat: 43.414257, postalCode: "57053" },
    { lng: -100.19302, lat: 43.483177, postalCode: "57526" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57188" },
    { lng: -96.87487, lat: 43.350093, postalCode: "57039" },
    { lng: -98.125687, lat: 47.889186, postalCode: "58234" },
    { lng: -98.2555, lat: 43.69156, postalCode: "57363" },
    { lng: -98.427173, lat: 43.136365, postalCode: "57357" },
    { lng: -97.35081, lat: 44.355088, postalCode: "57249" },
    { lng: -99.15407, lat: 45.893818, postalCode: "57457" },
    { lng: -98.200789, lat: 44.798968, postalCode: "57840" },
    { lng: -96.9274, lat: 45.268329, postalCode: "57251" },
    { lng: -97.45467, lat: 43.548586, postalCode: "57319" },
    { lng: -96.806422, lat: 43.383541, postalCode: "57309" },
    { lng: -99.45466, lat: 44.246147, postalCode: "57346" },
    { lng: -98.74998, lat: 43.037683, postalCode: "57335" },
    { lng: -96.839235, lat: 45.151477, postalCode: "57253" },
    { lng: -96.97587, lat: 43.410132, postalCode: "57015" },
    { lng: -97.93431, lat: 42.861759, postalCode: "57062" },
    { lng: -97.04528, lat: 45.667565, postalCode: "57262" },
    { lng: -100.09039, lat: 43.528885, postalCode: "57584" },
    { lng: -99.54871, lat: 44.057302, postalCode: "57548" },
    { lng: -97.11346, lat: 44.916657, postalCode: "57201" },
    { lng: -98.44031, lat: 43.438138, postalCode: "57328" },
    { lng: -97.37321, lat: 45.83907, postalCode: "57270" },
    { lng: -96.68409, lat: 44.573144, postalCode: "57268" },
    { lng: -100.38868, lat: 43.360304, postalCode: "57563" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57196" },
    { lng: -96.5166, lat: 44.252315, postalCode: "57026" },
    { lng: -97.47022, lat: 43.335663, postalCode: "57029" },
    { lng: -96.7185, lat: 45.871636, postalCode: "57260" },
    { lng: -97.74525, lat: 43.133482, postalCode: "57059" },
    { lng: -98.50795, lat: 45.727807, postalCode: "57426" },
    { lng: -96.46401, lat: 44.156514, postalCode: "57074" },
    { lng: -96.558738, lat: 43.521559, postalCode: "57056" },
    { lng: -98.61919, lat: 43.449261, postalCode: "57344" },
    { lng: -98.94361, lat: 45.743673, postalCode: "57456" },
    { lng: -97.56056, lat: 44.025045, postalCode: "57349" },
    { lng: -97.36611, lat: 43.994948, postalCode: "57076" },
    { lng: -98.16211, lat: 43.002628, postalCode: "57329" },
    { lng: -96.81166, lat: 44.001123, postalCode: "57017" },
    { lng: -98.279548, lat: 44.414319, postalCode: "57399" },
    { lng: -97.42038, lat: 44.494263, postalCode: "57233" },
    { lng: -98.00076, lat: 44.046328, postalCode: "57314" },
    { lng: -97.18884, lat: 42.886177, postalCode: "57031" },
    { lng: -97.54052, lat: 45.316779, postalCode: "57274" },
    { lng: -97.54046, lat: 43.87045, postalCode: "57321" },
    { lng: -98.47215, lat: 43.733471, postalCode: "57368" },
    { lng: -96.85949, lat: 44.865062, postalCode: "57238" },
    { lng: -99.88459, lat: 45.549059, postalCode: "57452" },
    { lng: -97.188372, lat: 44.977634, postalCode: "57202" },
    { lng: -99.99188, lat: 44.500958, postalCode: "57522" },
    { lng: -96.82332, lat: 45.391241, postalCode: "57279" },
    { lng: -96.64746, lat: 45.377288, postalCode: "57227" },
    { lng: -98.61569, lat: 44.713517, postalCode: "57476" },
    { lng: -96.868464, lat: 43.521895, postalCode: "57115" },
    { lng: -97.36164, lat: 45.631637, postalCode: "57232" },
    { lng: -99.15712, lat: 43.854809, postalCode: "57370" },
    { lng: -96.72258, lat: 43.488472, postalCode: "57108" },
    { lng: -97.68587, lat: 42.944651, postalCode: "57063" },
    { lng: -96.65717, lat: 43.997522, postalCode: "57024" },
    { lng: -97.00957, lat: 44.159515, postalCode: "57050" },
    { lng: -99.03397, lat: 44.523028, postalCode: "57362" },
    { lng: -98.4172, lat: 43.007052, postalCode: "57361" },
    { lng: -97.19567, lat: 45.217342, postalCode: "57256" },
    { lng: -96.499259, lat: 42.505818, postalCode: "57949" },
    { lng: -96.91932, lat: 44.889964, postalCode: "57245" },
    { lng: -96.79342, lat: 45.227773, postalCode: "57269" },
    { lng: -97.20668, lat: 44.484691, postalCode: "57214" },
    { lng: -98.27191, lat: 44.857069, postalCode: "57440" },
    { lng: -97.15149, lat: 43.281047, postalCode: "57036" },
    { lng: -97.92375, lat: 44.889927, postalCode: "57258" },
    { lng: -98.42497, lat: 45.152388, postalCode: "57461" },
    { lng: -98.49646, lat: 45.46932, postalCode: "57401" },
    { lng: -99.97143, lat: 43.289115, postalCode: "57580" },
    { lng: -98.53232, lat: 43.062919, postalCode: "57367" },
    { lng: -98.28766, lat: 44.05448, postalCode: "57385" },
    { lng: -97.23971, lat: 44.129029, postalCode: "57054" },
    { lng: -100.90791, lat: 43.232817, postalCode: "57570" },
    { lng: -98.352014, lat: 45.589567, postalCode: "57402" },
    { lng: -98.04772, lat: 43.481227, postalCode: "57331" },
    { lng: -99.5615, lat: 43.263007, postalCode: "57529" },
    { lng: -96.65801, lat: 43.544375, postalCode: "57110" },
    { lng: -97.97146, lat: 44.638862, postalCode: "57322" },
    { lng: -97.607194, lat: 43.73319, postalCode: "57347" },
    { lng: -100.52716, lat: 43.990243, postalCode: "57531" },
    { lng: -99.67824, lat: 45.459575, postalCode: "57428" },
    { lng: -96.63848, lat: 42.974216, postalCode: "57001" },
    { lng: -99.44937, lat: 45.593008, postalCode: "57448" },
    { lng: -98.21765, lat: 45.726524, postalCode: "57449" },
    { lng: -98.42594, lat: 44.070966, postalCode: "57358" },
    { lng: -96.60442, lat: 43.294672, postalCode: "57013" },
    { lng: -97.60039, lat: 43.738472, postalCode: "57374" },
    { lng: -98.62274, lat: 45.663385, postalCode: "57481" },
    { lng: -97.82982, lat: 44.391844, postalCode: "57353" },
    { lng: -96.90648, lat: 44.587419, postalCode: "57234" },
    { lng: -98.14931, lat: 43.896226, postalCode: "57359" },
    { lng: -97.46092, lat: 44.588318, postalCode: "57221" },
    { lng: -100.77982, lat: 43.579476, postalCode: "57579" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57117" },
    { lng: -97.07195, lat: 43.643112, postalCode: "57035" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57195" },
    { lng: -96.54332, lat: 43.735778, postalCode: "57030" },
    { lng: -97.34057, lat: 44.219545, postalCode: "57051" },
    { lng: -96.94651, lat: 42.812664, postalCode: "57069" },
    { lng: -98.46429, lat: 45.321875, postalCode: "57479" },
    { lng: -98.31005, lat: 45.625285, postalCode: "57433" },
    { lng: -97.50123, lat: 44.736853, postalCode: "57271" },
    { lng: -100.29145, lat: 44.425356, postalCode: "57501" },
    { lng: -99.43287, lat: 44.074578, postalCode: "57339" },
    { lng: -99.257022, lat: 45.470676, postalCode: "57411" },
    { lng: -100.04014, lat: 44.68577, postalCode: "57564" },
    { lng: -96.5007, lat: 44.797788, postalCode: "57237" },
    { lng: -98.317734, lat: 45.697865, postalCode: "57443" },
    { lng: -97.99713, lat: 43.549862, postalCode: "57334" },
    { lng: -100.94904, lat: 43.111157, postalCode: "57572" },
    { lng: -98.5409, lat: 44.864263, postalCode: "57469" },
    { lng: -97.25757, lat: 43.608442, postalCode: "57012" },
    { lng: -96.95463, lat: 43.614888, postalCode: "57033" },
    { lng: -97.29026, lat: 43.42258, postalCode: "57043" },
    { lng: -98.52872, lat: 44.276539, postalCode: "57379" },
    { lng: -98.928401, lat: 44.556082, postalCode: "57673" },
    { lng: -98.89387, lat: 43.435193, postalCode: "57369" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57198" },
    { lng: -97.48749, lat: 43.012455, postalCode: "57067" },
    { lng: -101.16474, lat: 43.493132, postalCode: "57560" },
    { lng: -96.7324, lat: 43.560826, postalCode: "57104" },
    { lng: -97.42649, lat: 45.725677, postalCode: "57247" },
    { lng: -98.8941, lat: 44.514512, postalCode: "57373" },
    { lng: -96.69063, lat: 43.546358, postalCode: "57101" },
    { lng: -99.3053, lat: 45.42443, postalCode: "57471" },
    { lng: -100.11167, lat: 44.830961, postalCode: "57520" },
    { lng: -96.58896, lat: 44.047857, postalCode: "57028" },
    { lng: -97.18706, lat: 43.734095, postalCode: "57048" },
    { lng: -96.51982, lat: 43.192357, postalCode: "57027" },
    { lng: -100.2916, lat: 43.934133, postalCode: "57576" },
    { lng: -98.08986, lat: 45.175381, postalCode: "57434" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57193" },
    { lng: -98.69907, lat: 43.257067, postalCode: "57342" },
    { lng: -96.48692, lat: 43.582689, postalCode: "57068" },
    { lng: -99.62497, lat: 45.796151, postalCode: "57437" },
    { lng: -100.0569, lat: 43.893368, postalCode: "57568" },
    { lng: -96.63173, lat: 44.428052, postalCode: "57276" },
    { lng: -97.358948, lat: 44.543593, postalCode: "57211" },
    { lng: -97.74574, lat: 44.877579, postalCode: "57225" },
    { lng: -96.69527, lat: 43.537075, postalCode: "57103" },
    { lng: -99.76123, lat: 43.205207, postalCode: "57528" },
    { lng: -98.96882, lat: 43.754325, postalCode: "57355" },
    { lng: -97.78571, lat: 45.493324, postalCode: "57468" },
    { lng: -96.93911, lat: 44.278584, postalCode: "57071" },
    { lng: -98.61012, lat: 43.429115, postalCode: "57364" },
    { lng: -96.66287, lat: 45.048009, postalCode: "57246" },
    { lng: -99.22041, lat: 44.430739, postalCode: "57371" },
    { lng: -100.28995, lat: 45.896895, postalCode: "57648" },
    { lng: -98.46986, lat: 43.556293, postalCode: "57375" },
    { lng: -96.8163, lat: 42.819124, postalCode: "57010" },
    { lng: -97.471509, lat: 44.34715, postalCode: "57230" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57109" },
    { lng: -99.61724, lat: 44.466602, postalCode: "57540" },
    { lng: -97.7152, lat: 44.157714, postalCode: "57323" },
    { lng: -99.38782, lat: 43.808858, postalCode: "57365" },
    { lng: -99.57459, lat: 43.839997, postalCode: "57569" },
    { lng: -97.7082, lat: 43.29183, postalCode: "57052" },
    { lng: -97.92959, lat: 45.418297, postalCode: "57422" },
    { lng: -96.79134, lat: 43.674582, postalCode: "57194" },
    { lng: -97.390268, lat: 42.867556, postalCode: "57079" },
    { lng: -100.1001, lat: 45.834848, postalCode: "57632" },
    { lng: -97.13878, lat: 44.377534, postalCode: "57212" },
    { lng: -97.04171, lat: 44.245062, postalCode: "57061" },
    { lng: -97.43149, lat: 44.917623, postalCode: "57243" },
    { lng: -97.58708, lat: 43.060656, postalCode: "57040" },
    { lng: -99.09765, lat: 43.085296, postalCode: "57571" },
    { lng: -98.52375, lat: 45.008745, postalCode: "57424" },
    { lng: -96.96892, lat: 43.993676, postalCode: "57075" },
    { lng: -96.58362, lat: 43.590014, postalCode: "57005" },
    { lng: -99.48175, lat: 43.573247, postalCode: "57542" },
    { lng: -96.51511, lat: 44.551141, postalCode: "57213" },
    { lng: -97.134, lat: 43.995586, postalCode: "57042" },
    { lng: -99.81529, lat: 43.908053, postalCode: "57544" },
    { lng: -96.78889, lat: 45.014788, postalCode: "57265" },
    { lng: -97.02057, lat: 45.478644, postalCode: "57257" },
    { lng: -99.29864, lat: 43.781193, postalCode: "57325" },
    { lng: -97.2924, lat: 45.369654, postalCode: "57273" },
    { lng: -96.70684, lat: 43.833265, postalCode: "57022" },
    { lng: -96.80375, lat: 43.514392, postalCode: "57106" },
    { lng: -98.18814, lat: 45.864171, postalCode: "57446" },
    { lng: -97.8416, lat: 43.772732, postalCode: "57340" },
    { lng: -96.73297, lat: 43.525226, postalCode: "57105" },
    { lng: -86.64782, lat: 36.055115, postalCode: "37013" },
    { lng: -88.23261, lat: 35.381331, postalCode: "38370" },
    { lng: -85.67736, lat: 35.467486, postalCode: "37305" },
    { lng: -89.443779, lat: 36.046851, postalCode: "38056" },
    { lng: -86.450042, lat: 35.510825, postalCode: "37162" },
    { lng: -88.59327, lat: 35.165045, postalCode: "38375" },
    { lng: -87.54263, lat: 36.503792, postalCode: "37191" },
    { lng: -87.0285, lat: 36.393153, postalCode: "37146" },
    { lng: -86.39629, lat: 35.616935, postalCode: "37020" },
    { lng: -88.50539, lat: 35.462376, postalCode: "38347" },
    { lng: -87.31927, lat: 35.727189, postalCode: "38454" },
    { lng: -86.61173, lat: 36.311047, postalCode: "37075" },
    { lng: -87.37522, lat: 35.259613, postalCode: "38464" },
    { lng: -85.162272, lat: 36.007405, postalCode: "38578" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38088" },
    { lng: -86.98993, lat: 35.474034, postalCode: "38451" },
    { lng: -85.91072, lat: 36.140473, postalCode: "38567" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37239" },
    { lng: -86.304922, lat: 36.156231, postalCode: "37071" },
    { lng: -88.90845, lat: 35.074302, postalCode: "38052" },
    { lng: -85.61818, lat: 35.065007, postalCode: "37347" },
    { lng: -89.032672, lat: 35.748414, postalCode: "38336" },
    { lng: -88.41385, lat: 35.995802, postalCode: "38344" },
    { lng: -89.5034, lat: 35.214462, postalCode: "38060" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38197" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38027" },
    { lng: -86.35134, lat: 35.702877, postalCode: "37037" },
    { lng: -88.42611, lat: 35.051008, postalCode: "38357" },
    { lng: -89.233227, lat: 36.458295, postalCode: "38271" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38145" },
    { lng: -88.35903, lat: 36.444446, postalCode: "38251" },
    { lng: -85.64636, lat: 36.127733, postalCode: "38544" },
    { lng: -85.51921, lat: 35.833271, postalCode: "38559" },
    { lng: -86.21484, lat: 35.357522, postalCode: "37388" },
    { lng: -89.5329, lat: 35.076213, postalCode: "38066" },
    { lng: -85.619169, lat: 35.773417, postalCode: "38550" },
    { lng: -89.131915, lat: 35.969336, postalCode: "38331" },
    { lng: -86.420958, lat: 35.859565, postalCode: "37133" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38159" },
    { lng: -85.7555, lat: 36.259907, postalCode: "38564" },
    { lng: -88.27379, lat: 35.041335, postalCode: "38326" },
    { lng: -86.55056, lat: 35.751016, postalCode: "37153" },
    { lng: -89.17888, lat: 36.358158, postalCode: "38260" },
    { lng: -86.74437, lat: 36.140857, postalCode: "37210" },
    { lng: -88.66672, lat: 36.488409, postalCode: "38226" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37234" },
    { lng: -88.73125, lat: 35.4832, postalCode: "38366" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38187" },
    { lng: -90.02224, lat: 35.167515, postalCode: "38107" },
    { lng: -88.83586, lat: 36.236377, postalCode: "38255" },
    { lng: -86.56268, lat: 36.01333, postalCode: "37086" },
    { lng: -88.12309, lat: 36.330953, postalCode: "38256" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37235" },
    { lng: -85.17161, lat: 35.635097, postalCode: "37367" },
    { lng: -86.78927, lat: 36.152607, postalCode: "37203" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37227" },
    { lng: -89.23476, lat: 35.049339, postalCode: "38046" },
    { lng: -90.00463, lat: 35.133825, postalCode: "38104" },
    { lng: -84.99517, lat: 36.260904, postalCode: "38565" },
    { lng: -86.3648, lat: 35.860152, postalCode: "37130" },
    { lng: -86.04135, lat: 36.389736, postalCode: "37057" },
    { lng: -89.97479, lat: 35.148605, postalCode: "38112" },
    { lng: -85.81114, lat: 36.543183, postalCode: "37150" },
    { lng: -85.90866, lat: 36.377253, postalCode: "37145" },
    { lng: -87.31395, lat: 35.882639, postalCode: "37098" },
    { lng: -87.1106, lat: 36.084917, postalCode: "37082" },
    { lng: -88.90218, lat: 35.825299, postalCode: "38343" },
    { lng: -86.420958, lat: 35.859565, postalCode: "37063" },
    { lng: -86.72594, lat: 36.213004, postalCode: "37216" },
    { lng: -85.45113, lat: 35.943026, postalCode: "38583" },
    { lng: -85.094842, lat: 36.04347, postalCode: "38571" },
    { lng: -85.454779, lat: 36.141824, postalCode: "38503" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37242" },
    { lng: -89.04069, lat: 35.468337, postalCode: "38392" },
    { lng: -85.53107, lat: 35.013853, postalCode: "37340" },
    { lng: -86.69047, lat: 35.045279, postalCode: "38488" },
    { lng: -85.61165, lat: 35.869657, postalCode: "38587" },
    { lng: -85.88908, lat: 36.236398, postalCode: "38560" },
    { lng: -89.437684, lat: 36.158668, postalCode: "38007" },
    { lng: -88.5322, lat: 35.844195, postalCode: "38321" },
    { lng: -87.26409, lat: 35.336135, postalCode: "38456" },
    { lng: -85.0902, lat: 35.861811, postalCode: "38572" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37224" },
    { lng: -88.846372, lat: 35.870849, postalCode: "38338" },
    { lng: -87.49418, lat: 35.76714, postalCode: "37033" },
    { lng: -86.94674, lat: 36.065032, postalCode: "37221" },
    { lng: -88.645577, lat: 34.99687, postalCode: "38393" },
    { lng: -89.16485, lat: 35.875396, postalCode: "38021" },
    { lng: -86.7792, lat: 35.461359, postalCode: "37091" },
    { lng: -87.4039, lat: 36.371609, postalCode: "37052" },
    { lng: -86.27141, lat: 35.052678, postalCode: "37345" },
    { lng: -86.69226, lat: 36.60983, postalCode: "37141" },
    { lng: -86.19391, lat: 35.800108, postalCode: "37149" },
    { lng: -88.14836, lat: 36.458738, postalCode: "38222" },
    { lng: -90.047966, lat: 35.132219, postalCode: "38026" },
    { lng: -86.34445, lat: 35.791021, postalCode: "37127" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38055" },
    { lng: -87.01246, lat: 35.204983, postalCode: "38478" },
    { lng: -85.18397, lat: 35.443503, postalCode: "37338" },
    { lng: -87.458058, lat: 36.283543, postalCode: "37165" },
    { lng: -89.01731, lat: 35.794403, postalCode: "38337" },
    { lng: -87.21761, lat: 36.117433, postalCode: "37187" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38193" },
    { lng: -85.15007, lat: 36.572543, postalCode: "38549" },
    { lng: -89.84892, lat: 35.082936, postalCode: "38119" },
    { lng: -88.36434, lat: 35.37046, postalCode: "38359" },
    { lng: -89.99788, lat: 35.073545, postalCode: "38132" },
    { lng: -86.82522, lat: 36.098408, postalCode: "37215" },
    { lng: -88.27401, lat: 35.878702, postalCode: "38387" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37236" },
    { lng: -85.78987, lat: 36.163234, postalCode: "38548" },
    { lng: -85.74373, lat: 36.09651, postalCode: "38582" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37237" },
    { lng: -89.353821, lat: 36.379051, postalCode: "38254" },
    { lng: -88.1184, lat: 35.66088, postalCode: "38363" },
    { lng: -89.111653, lat: 36.135364, postalCode: "38389" },
    { lng: -89.41989, lat: 35.879497, postalCode: "38040" },
    { lng: -85.93221, lat: 35.199411, postalCode: "37838" },
    { lng: -85.92851, lat: 35.993983, postalCode: "37059" },
    { lng: -89.43406, lat: 35.152913, postalCode: "38076" },
    { lng: -89.18203, lat: 36.198652, postalCode: "38259" },
    { lng: -89.15508, lat: 35.35001, postalCode: "38075" },
    { lng: -86.82052, lat: 36.27062, postalCode: "37189" },
    { lng: -87.4128, lat: 36.310027, postalCode: "37051" },
    { lng: -85.777633, lat: 35.678368, postalCode: "37378" },
    { lng: -89.24555, lat: 35.908286, postalCode: "38034" },
    { lng: -85.66854, lat: 36.228911, postalCode: "38545" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38083" },
    { lng: -86.64441, lat: 35.73843, postalCode: "37060" },
    { lng: -88.614501, lat: 36.003208, postalCode: "38528" },
    { lng: -89.2609, lat: 35.590558, postalCode: "38012" },
    { lng: -85.777633, lat: 35.678368, postalCode: "37111" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38142" },
    { lng: -89.90389, lat: 35.112929, postalCode: "38117" },
    { lng: -86.86892, lat: 36.513979, postalCode: "37172" },
    { lng: -86.51355, lat: 36.576137, postalCode: "37148" },
    { lng: -89.18599, lat: 35.057728, postalCode: "38039" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37229" },
    { lng: -89.32907, lat: 35.275036, postalCode: "38068" },
    { lng: -86.07482, lat: 35.497637, postalCode: "37389" },
    { lng: -89.984484, lat: 35.12738, postalCode: "38113" },
    { lng: -86.66616, lat: 36.103408, postalCode: "37217" },
    { lng: -90.0356, lat: 35.15275, postalCode: "38105" },
    { lng: -85.48755, lat: 34.994156, postalCode: "37396" },
    { lng: -87.50215, lat: 35.03492, postalCode: "38481" },
    { lng: -85.16208, lat: 36.400338, postalCode: "38543" },
    { lng: -85.77679, lat: 35.429018, postalCode: "37301" },
    { lng: -88.10137, lat: 36.050286, postalCode: "38320" },
    { lng: -86.980116, lat: 36.056164, postalCode: "37222" },
    { lng: -86.489019, lat: 35.471531, postalCode: "37161" },
    { lng: -88.84191, lat: 36.353928, postalCode: "38237" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38177" },
    { lng: -88.841225, lat: 35.612405, postalCode: "38303" },
    { lng: -89.99403, lat: 35.063236, postalCode: "38131" },
    { lng: -88.66966, lat: 35.98269, postalCode: "38220" },
    { lng: -88.04885, lat: 36.254423, postalCode: "38221" },
    { lng: -88.95209, lat: 35.35452, postalCode: "38381" },
    { lng: -87.79018, lat: 36.574224, postalCode: "37023" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38029" },
    { lng: -86.18393, lat: 35.929221, postalCode: "37118" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38130" },
    { lng: -88.841225, lat: 35.612405, postalCode: "38308" },
    { lng: -85.82911, lat: 35.238756, postalCode: "37356" },
    { lng: -85.787, lat: 35.687286, postalCode: "37110" },
    { lng: -86.80057, lat: 36.133251, postalCode: "37212" },
    { lng: -86.10976, lat: 35.699599, postalCode: "37026" },
    { lng: -88.38425, lat: 35.241412, postalCode: "38310" },
    { lng: -89.40664, lat: 35.839785, postalCode: "38073" },
    { lng: -88.01666, lat: 35.270793, postalCode: "38475" },
    { lng: -89.33228, lat: 36.339084, postalCode: "38232" },
    { lng: -88.81209, lat: 36.067294, postalCode: "38316" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38190" },
    { lng: -86.921416, lat: 35.895085, postalCode: "37065" },
    { lng: -87.86841, lat: 36.633539, postalCode: "37028" },
    { lng: -89.94754, lat: 35.489296, postalCode: "38023" },
    { lng: -85.62361, lat: 35.373152, postalCode: "37339" },
    { lng: -89.48802, lat: 35.288624, postalCode: "38010" },
    { lng: -87.33058, lat: 35.606697, postalCode: "38461" },
    { lng: -86.86207, lat: 36.113558, postalCode: "37205" },
    { lng: -89.86291, lat: 35.057311, postalCode: "38115" },
    { lng: -88.81959, lat: 35.60758, postalCode: "38301" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38151" },
    { lng: -88.40388, lat: 35.411713, postalCode: "38332" },
    { lng: -85.08484, lat: 36.29017, postalCode: "38589" },
    { lng: -86.4548, lat: 36.388997, postalCode: "37066" },
    { lng: -89.53988, lat: 35.391363, postalCode: "38049" },
    { lng: -88.63929, lat: 35.867764, postalCode: "38348" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37232" },
    { lng: -87.34142, lat: 36.528448, postalCode: "37040" },
    { lng: -88.30407, lat: 35.432389, postalCode: "38371" },
    { lng: -86.4421, lat: 35.1149, postalCode: "37348" },
    { lng: -86.66941, lat: 36.467624, postalCode: "37188" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38167" },
    { lng: -86.827254, lat: 36.340304, postalCode: "37202" },
    { lng: -88.77185, lat: 35.795399, postalCode: "38355" },
    { lng: -85.48904, lat: 36.547412, postalCode: "38551" },
    { lng: -85.72381, lat: 36.456113, postalCode: "38588" },
    { lng: -87.21926, lat: 35.734499, postalCode: "38487" },
    { lng: -85.41146, lat: 35.69653, postalCode: "38585" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37245" },
    { lng: -86.0324, lat: 35.234156, postalCode: "37324" },
    { lng: -86.62308, lat: 36.243698, postalCode: "37138" },
    { lng: -87.77263, lat: 35.187869, postalCode: "38450" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38110" },
    { lng: -85.92275, lat: 35.596484, postalCode: "37357" },
    { lng: -86.67907, lat: 36.548569, postalCode: "37049" },
    { lng: -85.70288, lat: 35.361678, postalCode: "37313" },
    { lng: -87.04478, lat: 35.376463, postalCode: "38472" },
    { lng: -88.49515, lat: 36.395616, postalCode: "38224" },
    { lng: -89.37347, lat: 35.055545, postalCode: "38057" },
    { lng: -87.49893, lat: 36.421075, postalCode: "37142" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38166" },
    { lng: -88.805599, lat: 36.030588, postalCode: "38346" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38014" },
    { lng: -86.784498, lat: 36.167606, postalCode: "37243" },
    { lng: -86.23954, lat: 36.579247, postalCode: "37186" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37070" },
    { lng: -85.84696, lat: 36.104271, postalCode: "38569" },
    { lng: -86.00868, lat: 36.141741, postalCode: "38547" },
    { lng: -86.81023, lat: 36.427758, postalCode: "37073" },
    { lng: -89.72344, lat: 35.474694, postalCode: "38011" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38183" },
    { lng: -88.18946, lat: 35.180947, postalCode: "38372" },
    { lng: -86.3, lat: 36.501049, postalCode: "37022" },
    { lng: -86.66189, lat: 35.856372, postalCode: "37014" },
    { lng: -87.14128, lat: 35.976983, postalCode: "37062" },
    { lng: -88.80988, lat: 35.211663, postalCode: "38044" },
    { lng: -89.7615, lat: 35.087468, postalCode: "38139" },
    { lng: -89.78249, lat: 35.157681, postalCode: "38018" },
    { lng: -86.35264, lat: 35.276795, postalCode: "37352" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38165" },
    { lng: -88.39343, lat: 35.867454, postalCode: "38324" },
    { lng: -86.31061, lat: 36.147433, postalCode: "37090" },
    { lng: -85.9832, lat: 36.182334, postalCode: "38563" },
    { lng: -89.818781, lat: 35.414319, postalCode: "38071" },
    { lng: -86.0317, lat: 36.335403, postalCode: "37151" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38168" },
    { lng: -87.97188, lat: 35.401188, postalCode: "38425" },
    { lng: -86.26045, lat: 35.433962, postalCode: "37360" },
    { lng: -85.903522, lat: 35.180503, postalCode: "37383" },
    { lng: -89.243405, lat: 35.942019, postalCode: "38070" },
    { lng: -86.77266, lat: 36.069659, postalCode: "37220" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37241" },
    { lng: -85.20787, lat: 36.323957, postalCode: "38542" },
    { lng: -84.93393, lat: 36.409385, postalCode: "38556" },
    { lng: -86.00538, lat: 35.168862, postalCode: "37318" },
    { lng: -86.72272, lat: 35.7557, postalCode: "37046" },
    { lng: -89.35699, lat: 35.459089, postalCode: "38069" },
    { lng: -86.621095, lat: 36.304701, postalCode: "37077" },
    { lng: -88.685, lat: 35.764637, postalCode: "38378" },
    { lng: -87.02542, lat: 36.522355, postalCode: "37032" },
    { lng: -86.8825, lat: 36.170556, postalCode: "37011" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38146" },
    { lng: -87.28938, lat: 36.047742, postalCode: "37029" },
    { lng: -89.02951, lat: 35.090194, postalCode: "38067" },
    { lng: -86.6686, lat: 35.940383, postalCode: "37135" },
    { lng: -88.52289, lat: 35.047877, postalCode: "38339" },
    { lng: -87.80171, lat: 35.066147, postalCode: "38452" },
    { lng: -85.454779, lat: 36.141824, postalCode: "38505" },
    { lng: -87.91309, lat: 35.083644, postalCode: "38471" },
    { lng: -86.07482, lat: 35.497637, postalCode: "37382" },
    { lng: -88.26959, lat: 35.960377, postalCode: "38318" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37116" },
    { lng: -85.00757, lat: 36.209271, postalCode: "38553" },
    { lng: -85.32525, lat: 36.270655, postalCode: "38580" },
    { lng: -87.67179, lat: 35.863362, postalCode: "37140" },
    { lng: -86.76679, lat: 36.396821, postalCode: "37152" },
    { lng: -89.89189, lat: 35.114416, postalCode: "38157" },
    { lng: -87.80286, lat: 35.769743, postalCode: "37097" },
    { lng: -89.84701, lat: 35.016803, postalCode: "38141" },
    { lng: -89.60112, lat: 35.980206, postalCode: "38030" },
    { lng: -88.990028, lat: 35.213371, postalCode: "38074" },
    { lng: -88.78683, lat: 35.73006, postalCode: "38362" },
    { lng: -87.75739, lat: 35.343716, postalCode: "38485" },
    { lng: -87.77676, lat: 36.323799, postalCode: "37178" },
    { lng: -88.42518, lat: 36.210847, postalCode: "38231" },
    { lng: -86.4455, lat: 35.813319, postalCode: "37128" },
    { lng: -86.63337, lat: 35.301331, postalCode: "37144" },
    { lng: -87.2572, lat: 36.506407, postalCode: "37043" },
    { lng: -86.57433, lat: 35.136177, postalCode: "37334" },
    { lng: -86.30112, lat: 36.377228, postalCode: "37031" },
    { lng: -89.522851, lat: 36.344296, postalCode: "38077" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38137" },
    { lng: -88.61825, lat: 36.016599, postalCode: "38258" },
    { lng: -88.36148, lat: 35.502859, postalCode: "38368" },
    { lng: -89.80329, lat: 35.035466, postalCode: "38125" },
    { lng: -86.01497, lat: 36.537994, postalCode: "37083" },
    { lng: -88.80653, lat: 35.693101, postalCode: "38305" },
    { lng: -86.80252, lat: 36.174806, postalCode: "37208" },
    { lng: -87.29104, lat: 35.025423, postalCode: "38457" },
    { lng: -87.377197, lat: 36.477428, postalCode: "37155" },
    { lng: -88.76867, lat: 36.154281, postalCode: "38230" },
    { lng: -88.29757, lat: 35.311586, postalCode: "38361" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37244" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38148" },
    { lng: -86.789998, lat: 36.158606, postalCode: "37246" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38173" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38175" },
    { lng: -88.40535, lat: 35.658409, postalCode: "38351" },
    { lng: -88.21648, lat: 35.667861, postalCode: "38328" },
    { lng: -89.94363, lat: 35.10935, postalCode: "38111" },
    { lng: -87.068172, lat: 35.629413, postalCode: "38402" },
    { lng: -89.96028, lat: 35.174165, postalCode: "38108" },
    { lng: -86.42425, lat: 35.896645, postalCode: "37129" },
    { lng: -86.08414, lat: 35.500068, postalCode: "37355" },
    { lng: -88.87895, lat: 36.483302, postalCode: "38257" },
    { lng: -87.40831, lat: 36.065779, postalCode: "37055" },
    { lng: -88.66118, lat: 35.414454, postalCode: "38340" },
    { lng: -88.25144, lat: 36.038551, postalCode: "38317" },
    { lng: -86.420958, lat: 35.859565, postalCode: "37131" },
    { lng: -86.77626, lat: 36.112058, postalCode: "37204" },
    { lng: -86.78172, lat: 36.166806, postalCode: "37219" },
    { lng: -89.25364, lat: 36.253678, postalCode: "38240" },
    { lng: -88.76628, lat: 35.916722, postalCode: "38358" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38184" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38124" },
    { lng: -86.304922, lat: 36.156231, postalCode: "37121" },
    { lng: -89.71198, lat: 35.272386, postalCode: "38002" },
    { lng: -87.30571, lat: 36.354736, postalCode: "37171" },
    { lng: -88.51041, lat: 35.576402, postalCode: "38345" },
    { lng: -88.28916, lat: 36.075068, postalCode: "38342" },
    { lng: -87.16259, lat: 35.08957, postalCode: "38460" },
    { lng: -90.07523, lat: 35.050286, postalCode: "38109" },
    { lng: -87.9564, lat: 36.022057, postalCode: "37134" },
    { lng: -87.4056, lat: 36.58068, postalCode: "37042" },
    { lng: -87.49831, lat: 36.237999, postalCode: "37181" },
    { lng: -85.65757, lat: 36.351803, postalCode: "38562" },
    { lng: -89.22386, lat: 35.815535, postalCode: "38050" },
    { lng: -85.32585, lat: 36.376289, postalCode: "38570" },
    { lng: -88.52246, lat: 36.133065, postalCode: "38201" },
    { lng: -89.776835, lat: 35.177475, postalCode: "38016" },
    { lng: -88.98134, lat: 36.12836, postalCode: "38369" },
    { lng: -86.60111, lat: 36.180507, postalCode: "37076" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38161" },
    { lng: -89.80845, lat: 35.451159, postalCode: "38058" },
    { lng: -87.63158, lat: 36.373626, postalCode: "37050" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37238" },
    { lng: -84.73614, lat: 36.392571, postalCode: "38504" },
    { lng: -89.41497, lat: 35.82036, postalCode: "38037" },
    { lng: -87.41974, lat: 35.071804, postalCode: "38469" },
    { lng: -86.81188, lat: 35.128317, postalCode: "38453" },
    { lng: -87.33748, lat: 35.43506, postalCode: "38483" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38163" },
    { lng: -89.86559, lat: 35.193046, postalCode: "38134" },
    { lng: -86.52231, lat: 35.968513, postalCode: "37167" },
    { lng: -88.11912, lat: 35.436584, postalCode: "38311" },
    { lng: -86.57353, lat: 35.621614, postalCode: "37180" },
    { lng: -87.353008, lat: 36.531398, postalCode: "37044" },
    { lng: -87.54905, lat: 35.535397, postalCode: "38462" },
    { lng: -85.52054, lat: 36.1832, postalCode: "38501" },
    { lng: -87.64489, lat: 36.069712, postalCode: "37101" },
    { lng: -88.36661, lat: 35.843868, postalCode: "38390" },
    { lng: -89.870636, lat: 35.334132, postalCode: "38054" },
    { lng: -86.79121, lat: 36.007373, postalCode: "37027" },
    { lng: -89.14016, lat: 35.796157, postalCode: "38001" },
    { lng: -86.05538, lat: 35.813335, postalCode: "37190" },
    { lng: -89.08457, lat: 35.696656, postalCode: "38006" },
    { lng: -89.62287, lat: 36.063947, postalCode: "38047" },
    { lng: -86.8406, lat: 35.330683, postalCode: "37047" },
    { lng: -90.04017, lat: 35.103019, postalCode: "38106" },
    { lng: -85.74039, lat: 35.275367, postalCode: "37387" },
    { lng: -86.7952, lat: 35.921811, postalCode: "37067" },
    { lng: -88.64275, lat: 35.260888, postalCode: "38315" },
    { lng: -88.98751, lat: 35.25543, postalCode: "38008" },
    { lng: -86.91144, lat: 35.73062, postalCode: "37174" },
    { lng: -85.65072, lat: 36.555932, postalCode: "38575" },
    { lng: -87.377197, lat: 36.477428, postalCode: "37041" },
    { lng: -85.97669, lat: 36.000953, postalCode: "37095" },
    { lng: -88.6869, lat: 36.305305, postalCode: "38225" },
    { lng: -86.89653, lat: 35.97706, postalCode: "37069" },
    { lng: -89.64201, lat: 35.560622, postalCode: "38019" },
    { lng: -86.098338, lat: 35.175522, postalCode: "37372" },
    { lng: -85.92217, lat: 35.060787, postalCode: "37376" },
    { lng: -89.48641, lat: 36.224429, postalCode: "38080" },
    { lng: -88.60614, lat: 36.223573, postalCode: "38229" },
    { lng: -87.68198, lat: 36.301089, postalCode: "37061" },
    { lng: -88.09896, lat: 35.866499, postalCode: "38341" },
    { lng: -87.28991, lat: 35.943598, postalCode: "37025" },
    { lng: -85.16204, lat: 36.26742, postalCode: "38554" },
    { lng: -88.826923, lat: 35.35604, postalCode: "38377" },
    { lng: -88.841225, lat: 35.612405, postalCode: "38302" },
    { lng: -89.00984, lat: 35.560669, postalCode: "38391" },
    { lng: -86.09259, lat: 35.951494, postalCode: "37016" },
    { lng: -85.79907, lat: 36.214724, postalCode: "38552" },
    { lng: -86.89919, lat: 35.893823, postalCode: "37064" },
    { lng: -87.22342, lat: 35.52478, postalCode: "38474" },
    { lng: -89.86562, lat: 35.124818, postalCode: "38120" },
    { lng: -85.63943, lat: 35.751554, postalCode: "38581" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37248" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38174" },
    { lng: -89.0377, lat: 36.307957, postalCode: "38253" },
    { lng: -86.81014, lat: 35.188143, postalCode: "38459" },
    { lng: -89.990415, lat: 35.169255, postalCode: "37501" },
    { lng: -88.35061, lat: 35.782093, postalCode: "38388" },
    { lng: -84.87955, lat: 35.78168, postalCode: "37337" },
    { lng: -84.86226, lat: 35.933213, postalCode: "37723" },
    { lng: -86.01978, lat: 36.076635, postalCode: "37012" },
    { lng: -89.414113, lat: 35.199265, postalCode: "38043" },
    { lng: -87.87486, lat: 35.61432, postalCode: "37096" },
    { lng: -89.481362, lat: 35.15066, postalCode: "38048" },
    { lng: -89.232185, lat: 35.290428, postalCode: "38045" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38143" },
    { lng: -87.89028, lat: 36.316042, postalCode: "37175" },
    { lng: -88.57656, lat: 35.992987, postalCode: "38235" },
    { lng: -88.60089, lat: 35.357639, postalCode: "38334" },
    { lng: -89.443779, lat: 36.046851, postalCode: "38025" },
    { lng: -88.225601, lat: 35.038284, postalCode: "38365" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38150" },
    { lng: -87.2243, lat: 35.839045, postalCode: "38476" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38147" },
    { lng: -89.0727, lat: 36.426933, postalCode: "38261" },
    { lng: -84.91132, lat: 36.008521, postalCode: "38558" },
    { lng: -85.80108, lat: 35.922583, postalCode: "37166" },
    { lng: -88.95035, lat: 35.973431, postalCode: "38382" },
    { lng: -86.74176, lat: 36.180806, postalCode: "37206" },
    { lng: -88.53275, lat: 35.52953, postalCode: "38352" },
    { lng: -89.92315, lat: 35.155589, postalCode: "38122" },
    { lng: -88.76534, lat: 35.067152, postalCode: "38061" },
    { lng: -86.29596, lat: 35.950979, postalCode: "37085" },
    { lng: -86.30889, lat: 35.508541, postalCode: "37183" },
    { lng: -86.15305, lat: 36.407653, postalCode: "37074" },
    { lng: -86.420958, lat: 35.859565, postalCode: "37132" },
    { lng: -87.65472, lat: 35.679617, postalCode: "37147" },
    { lng: -86.14045, lat: 35.192829, postalCode: "37398" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37230" },
    { lng: -86.1669, lat: 35.642797, postalCode: "37018" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37240" },
    { lng: -90.04359, lat: 35.126469, postalCode: "38126" },
    { lng: -86.59963, lat: 36.491753, postalCode: "37048" },
    { lng: -88.260367, lat: 36.310995, postalCode: "38223" },
    { lng: -85.91048, lat: 35.193891, postalCode: "37375" },
    { lng: -89.38613, lat: 36.038042, postalCode: "38024" },
    { lng: -89.85214, lat: 35.232085, postalCode: "38135" },
    { lng: -86.36085, lat: 35.018508, postalCode: "37328" },
    { lng: -86.80078, lat: 36.190205, postalCode: "37228" },
    { lng: -90.0534, lat: 35.146131, postalCode: "38103" },
    { lng: -87.16763, lat: 35.026969, postalCode: "38473" },
    { lng: -86.13715, lat: 36.096166, postalCode: "37184" },
    { lng: -88.26817, lat: 36.167573, postalCode: "38236" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38182" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38136" },
    { lng: -87.50471, lat: 35.88662, postalCode: "37137" },
    { lng: -89.80806, lat: 35.422035, postalCode: "38004" },
    { lng: -90.01128, lat: 35.03319, postalCode: "38116" },
    { lng: -87.04467, lat: 36.121471, postalCode: "37143" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37249" },
    { lng: -88.30456, lat: 35.225753, postalCode: "38327" },
    { lng: -87.1517, lat: 35.77692, postalCode: "38482" },
    { lng: -86.304922, lat: 36.156231, postalCode: "37136" },
    { lng: -86.41866, lat: 35.063253, postalCode: "37335" },
    { lng: -86.84324, lat: 35.043961, postalCode: "38449" },
    { lng: -87.06949, lat: 36.299293, postalCode: "37015" },
    { lng: -88.59234, lat: 36.427351, postalCode: "38241" },
    { lng: -85.45701, lat: 36.414282, postalCode: "38568" },
    { lng: -86.420958, lat: 35.859565, postalCode: "37089" },
    { lng: -86.907565, lat: 35.874553, postalCode: "37068" },
    { lng: -86.72584, lat: 36.079259, postalCode: "37211" },
    { lng: -85.05926, lat: 35.929314, postalCode: "38555" },
    { lng: -85.454779, lat: 36.141824, postalCode: "38502" },
    { lng: -85.64149, lat: 35.19174, postalCode: "37374" },
    { lng: -85.46238, lat: 36.177831, postalCode: "38506" },
    { lng: -87.29179, lat: 36.225389, postalCode: "37036" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38152" },
    { lng: -89.92588, lat: 35.219882, postalCode: "38128" },
    { lng: -87.62171, lat: 35.069017, postalCode: "38463" },
    { lng: -87.745118, lat: 35.148316, postalCode: "38350" },
    { lng: -89.6215, lat: 35.320487, postalCode: "38036" },
    { lng: -86.85215, lat: 36.206755, postalCode: "37218" },
    { lng: -90.00646, lat: 35.223796, postalCode: "38127" },
    { lng: -89.67417, lat: 35.057224, postalCode: "38017" },
    { lng: -89.62203, lat: 35.203968, postalCode: "38028" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37250" },
    { lng: -86.49732, lat: 36.195016, postalCode: "37122" },
    { lng: -87.97808, lat: 36.114049, postalCode: "38333" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38140" },
    { lng: -87.77219, lat: 35.924976, postalCode: "37078" },
    { lng: -86.8559, lat: 36.151324, postalCode: "37209" },
    { lng: -84.99647, lat: 36.571669, postalCode: "38577" },
    { lng: -88.41878, lat: 35.159237, postalCode: "38379" },
    { lng: -86.304922, lat: 36.156231, postalCode: "37088" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38186" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38181" },
    { lng: -88.12345, lat: 35.546839, postalCode: "38329" },
    { lng: -88.950206, lat: 35.544159, postalCode: "38384" },
    { lng: -86.77317, lat: 36.215535, postalCode: "37207" },
    { lng: -85.36148, lat: 36.525275, postalCode: "38541" },
    { lng: -89.92426, lat: 35.048455, postalCode: "38118" },
    { lng: -85.20656, lat: 36.480151, postalCode: "38573" },
    { lng: -89.98543, lat: 35.101525, postalCode: "38114" },
    { lng: -88.02646, lat: 35.77928, postalCode: "38380" },
    { lng: -88.841225, lat: 35.612405, postalCode: "38314" },
    { lng: -85.55772, lat: 35.817417, postalCode: "38579" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38194" },
    { lng: -85.38755, lat: 35.399109, postalCode: "37327" },
    { lng: -89.847782, lat: 35.050667, postalCode: "38101" },
    { lng: -87.54145, lat: 35.164749, postalCode: "38486" },
    { lng: -86.66869, lat: 36.166956, postalCode: "37214" },
    { lng: -87.85877, lat: 36.477136, postalCode: "37058" },
    { lng: -86.07482, lat: 35.497637, postalCode: "37349" },
    { lng: -85.012389, lat: 35.988457, postalCode: "38557" },
    { lng: -89.149881, lat: 36.354384, postalCode: "38281" },
    { lng: -85.85456, lat: 35.304582, postalCode: "37366" },
    { lng: -89.25419, lat: 36.114872, postalCode: "38059" },
    { lng: -89.03087, lat: 36.201487, postalCode: "38233" },
    { lng: -86.90949, lat: 36.329551, postalCode: "37080" },
    { lng: -86.907565, lat: 35.874553, postalCode: "37024" },
    { lng: -87.03565, lat: 35.619784, postalCode: "38401" },
    { lng: -89.1536, lat: 35.149952, postalCode: "38042" },
    { lng: -87.10026, lat: 36.569635, postalCode: "37010" },
    { lng: -88.738132, lat: 36.282357, postalCode: "38238" },
    { lng: -86.29271, lat: 36.240331, postalCode: "37087" },
    { lng: -89.80518, lat: 35.208709, postalCode: "38133" },
    { lng: -86.2035, lat: 35.106467, postalCode: "37306" },
    { lng: -87.29218, lat: 35.135003, postalCode: "38468" },
    { lng: -88.88248, lat: 35.431381, postalCode: "38356" },
    { lng: -85.94893, lat: 36.264959, postalCode: "37030" },
    { lng: -87.352971, lat: 36.146949, postalCode: "37056" },
    { lng: -85.47929, lat: 35.187308, postalCode: "37937" },
    { lng: -87.81626, lat: 36.085376, postalCode: "37185" },
    { lng: -86.90734, lat: 35.79867, postalCode: "37179" },
    { lng: -86.77738, lat: 36.164556, postalCode: "37201" },
    { lng: -87.66331, lat: 36.48677, postalCode: "37079" },
    { lng: -84.792927, lat: 35.87543, postalCode: "37842" },
    { lng: -86.6865, lat: 35.639491, postalCode: "37034" },
    { lng: -88.32891, lat: 36.300311, postalCode: "38242" },
    { lng: -89.01767, lat: 36.073575, postalCode: "38330" },
    { lng: -85.86169, lat: 35.538116, postalCode: "37394" },
    { lng: -86.98456, lat: 35.040578, postalCode: "38477" },
    { lng: -86.45974, lat: 35.47066, postalCode: "37160" },
    { lng: -86.71879, lat: 36.35465, postalCode: "37072" },
    { lng: -85.49733, lat: 35.20297, postalCode: "37397" },
    { lng: -89.80677, lat: 35.088885, postalCode: "38138" },
    { lng: -86.40563, lat: 35.203361, postalCode: "37359" },
    { lng: -87.13175, lat: 36.388731, postalCode: "37035" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38195" },
    { lng: -85.55501, lat: 35.375156, postalCode: "37365" },
    { lng: -85.96367, lat: 35.39156, postalCode: "37342" },
    { lng: -89.82387, lat: 35.551277, postalCode: "38015" },
    { lng: -88.60533, lat: 35.062793, postalCode: "38367" },
    { lng: -88.62354, lat: 35.601914, postalCode: "38313" },
    { lng: -88.34742, lat: 35.130384, postalCode: "38376" },
    { lng: -86.12247, lat: 35.280936, postalCode: "37330" },
    { lng: -89.971538, lat: 35.201738, postalCode: "38188" },
    { lng: -86.70566, lat: 36.264069, postalCode: "37115" },
    { lng: -86.539028, lat: 36.631981, postalCode: "37119" },
    { lng: -85.72513, lat: 35.045886, postalCode: "37380" },
    { lng: -86.76777, lat: 36.167179, postalCode: "37213" },
    { lng: -89.90668, lat: 35.347965, postalCode: "38053" },
    { lng: -85.23805, lat: 36.133983, postalCode: "38574" },
    { lng: -88.24152, lat: 35.522263, postalCode: "38374" },
    { lng: -86.895305, lat: 35.05392, postalCode: "38455" },
    { lng: -86.785248, lat: 36.186605, postalCode: "37247" },
    { lng: -86.70969, lat: 35.390308, postalCode: "37019" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75323" },
    { lng: -97.63519, lat: 26.111261, postalCode: "78586" },
    { lng: -95.25951, lat: 29.849424, postalCode: "77078" },
    { lng: -96.06999, lat: 32.896295, postalCode: "75474" },
    { lng: -96.74038, lat: 33.024721, postalCode: "75075" },
    { lng: -97.02525, lat: 33.12635, postalCode: "75065" },
    { lng: -101.32582, lat: 34.444761, postalCode: "79257" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78718" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75357" },
    { lng: -97.82968, lat: 28.101323, postalCode: "78368" },
    { lng: -96.83987, lat: 32.716065, postalCode: "75224" },
    { lng: -97.00771, lat: 33.020118, postalCode: "75067" },
    { lng: -97.9269, lat: 30.702882, postalCode: "78642" },
    { lng: -96.30377, lat: 32.574299, postalCode: "75142" },
    { lng: -96.10346, lat: 30.892212, postalCode: "77872" },
    { lng: -96.90003, lat: 32.046241, postalCode: "76666" },
    { lng: -95.63213, lat: 29.573345, postalCode: "77479" },
    { lng: -101.39752, lat: 35.335288, postalCode: "79068" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77522" },
    { lng: -102.48829, lat: 33.45798, postalCode: "79372" },
    { lng: -94.800238, lat: 29.330501, postalCode: "77592" },
    { lng: -97.4016, lat: 27.829732, postalCode: "78402" },
    { lng: -97.73328, lat: 30.349455, postalCode: "78757" },
    { lng: -97.179773, lat: 31.503892, postalCode: "76683" },
    { lng: -95.3671, lat: 32.136787, postalCode: "75757" },
    { lng: -97.93937, lat: 28.535247, postalCode: "78125" },
    { lng: -94.00797, lat: 30.142953, postalCode: "77662" },
    { lng: -96.96733, lat: 32.846645, postalCode: "75062" },
    { lng: -99.83827, lat: 28.524292, postalCode: "78834" },
    { lng: -96.11289, lat: 33.15023, postalCode: "75401" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75310" },
    { lng: -98.10707, lat: 32.810275, postalCode: "76067" },
    { lng: -95.28631, lat: 29.827315, postalCode: "77028" },
    { lng: -98.190922, lat: 26.319405, postalCode: "78540" },
    { lng: -103.15737, lat: 31.753101, postalCode: "79789" },
    { lng: -98.42529, lat: 31.989197, postalCode: "76468" },
    { lng: -95.32698, lat: 30.289893, postalCode: "77306" },
    { lng: -101.45811, lat: 32.723865, postalCode: "79738" },
    { lng: -95.07826, lat: 31.090648, postalCode: "75845" },
    { lng: -95.93312, lat: 31.134863, postalCode: "75850" },
    { lng: -94.800238, lat: 29.330501, postalCode: "77549" },
    { lng: -101.28147, lat: 32.38737, postalCode: "79511" },
    { lng: -98.60869, lat: 29.518413, postalCode: "78240" },
    { lng: -97.75404, lat: 28.169309, postalCode: "78391" },
    { lng: -96.70922, lat: 32.873926, postalCode: "75238" },
    { lng: -95.57243, lat: 29.85873, postalCode: "77041" },
    { lng: -97.039741, lat: 29.665518, postalCode: "78961" },
    { lng: -94.61948, lat: 32.998759, postalCode: "75656" },
    { lng: -97.153868, lat: 28.997449, postalCode: "77989" },
    { lng: -98.60618, lat: 31.906683, postalCode: "76442" },
    { lng: -100.20763, lat: 34.858194, postalCode: "79095" },
    { lng: -97.71879, lat: 30.265158, postalCode: "78702" },
    { lng: -96.591092, lat: 30.178439, postalCode: "78835" },
    { lng: -97.62333, lat: 30.442202, postalCode: "78766" },
    { lng: -97.30681, lat: 32.535841, postalCode: "76028" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77271" },
    { lng: -96.28923, lat: 32.747747, postalCode: "75160" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77233" },
    { lng: -95.66319, lat: 29.152396, postalCode: "77486" },
    { lng: -96.38502, lat: 32.466891, postalCode: "75158" },
    { lng: -97.40948, lat: 30.574821, postalCode: "76574" },
    { lng: -94.13542, lat: 32.930895, postalCode: "75555" },
    { lng: -98.732187, lat: 29.325602, postalCode: "78054" },
    { lng: -96.80672, lat: 32.745831, postalCode: "75203" },
    { lng: -95.43893, lat: 30.18771, postalCode: "77385" },
    { lng: -97.0529, lat: 33.075138, postalCode: "75077" },
    { lng: -96.900331, lat: 29.347975, postalCode: "77985" },
    { lng: -95.58329, lat: 29.478032, postalCode: "77481" },
    { lng: -101.87223, lat: 30.703917, postalCode: "79781" },
    { lng: -98.84676, lat: 29.19085, postalCode: "78059" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78720" },
    { lng: -99.49991, lat: 27.514845, postalCode: "78040" },
    { lng: -97.108113, lat: 31.987167, postalCode: "76650" },
    { lng: -98.18011, lat: 26.344128, postalCode: "78539" },
    { lng: -100.75517, lat: 33.654512, postalCode: "79229" },
    { lng: -98.30582, lat: 29.50198, postalCode: "78109" },
    { lng: -99.54107, lat: 30.646698, postalCode: "76854" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77227" },
    { lng: -95.39923, lat: 29.752528, postalCode: "77019" },
    { lng: -96.73688, lat: 32.912225, postalCode: "75243" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78780" },
    { lng: -98.10753, lat: 30.24108, postalCode: "78620" },
    { lng: -96.27068, lat: 31.625567, postalCode: "75860" },
    { lng: -97.81966, lat: 29.777564, postalCode: "78670" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77242" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79178" },
    { lng: -97.59288, lat: 27.955312, postalCode: "78370" },
    { lng: -96.241147, lat: 29.298532, postalCode: "77462" },
    { lng: -97.37548, lat: 32.623807, postalCode: "76123" },
    { lng: -102.354346, lat: 31.765163, postalCode: "79760" },
    { lng: -95.756462, lat: 29.525461, postalCode: "77497" },
    { lng: -95.78335, lat: 29.135066, postalCode: "77463" },
    { lng: -97.69803, lat: 32.964932, postalCode: "76082" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78788" },
    { lng: -96.21454, lat: 29.064542, postalCode: "77432" },
    { lng: -95.46315, lat: 31.505916, postalCode: "75844" },
    { lng: -95.661964, lat: 31.794191, postalCode: "75882" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77202" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79174" },
    { lng: -96.52359, lat: 30.368922, postalCode: "77879" },
    { lng: -96.85985, lat: 32.895376, postalCode: "75229" },
    { lng: -97.76717, lat: 31.027003, postalCode: "76542" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78270" },
    { lng: -94.70063, lat: 32.396207, postalCode: "75603" },
    { lng: -95.54977, lat: 30.714476, postalCode: "77341" },
    { lng: -96.9902, lat: 28.532052, postalCode: "77973" },
    { lng: -98.746365, lat: 26.510436, postalCode: "78585" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75392" },
    { lng: -99.82203, lat: 32.417269, postalCode: "79607" },
    { lng: -101.10183, lat: 35.235628, postalCode: "79039" },
    { lng: -95.23106, lat: 29.66223, postalCode: "77587" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77501" },
    { lng: -95.3227, lat: 29.725961, postalCode: "77023" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79164" },
    { lng: -96.12045, lat: 33.774197, postalCode: "75447" },
    { lng: -98.67201, lat: 30.98609, postalCode: "76832" },
    { lng: -94.55839, lat: 32.507527, postalCode: "75650" },
    { lng: -94.33671, lat: 30.372553, postalCode: "77625" },
    { lng: -97.83125, lat: 30.359935, postalCode: "78730" },
    { lng: -94.85741, lat: 31.981458, postalCode: "75667" },
    { lng: -96.27386, lat: 33.573304, postalCode: "75439" },
    { lng: -95.90886, lat: 33.237722, postalCode: "75429" },
    { lng: -94.77725, lat: 31.195633, postalCode: "75941" },
    { lng: -97.3201, lat: 31.669085, postalCode: "76633" },
    { lng: -96.048665, lat: 33.218505, postalCode: "75403" },
    { lng: -100.13583, lat: 32.88019, postalCode: "79520" },
    { lng: -95.45332, lat: 29.429256, postalCode: "77583" },
    { lng: -95.13238, lat: 29.57493, postalCode: "77062" },
    { lng: -97.463213, lat: 27.777, postalCode: "78466" },
    { lng: -95.18118, lat: 29.778526, postalCode: "77015" },
    { lng: -95.24289, lat: 29.78146, postalCode: "77013" },
    { lng: -97.42728, lat: 29.950887, postalCode: "78662" },
    { lng: -94.71766, lat: 32.00923, postalCode: "75680" },
    { lng: -100.258156, lat: 34.074854, postalCode: "79224" },
    { lng: -96.87633, lat: 32.666984, postalCode: "75237" },
    { lng: -102.11087, lat: 34.938094, postalCode: "79091" },
    { lng: -98.5235, lat: 29.423932, postalCode: "78207" },
    { lng: -101.48031, lat: 35.844445, postalCode: "79083" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79457" },
    { lng: -97.896285, lat: 30.222007, postalCode: "78767" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78288" },
    { lng: -97.463213, lat: 27.777, postalCode: "78463" },
    { lng: -96.42153, lat: 33.048275, postalCode: "75173" },
    { lng: -104.69511, lat: 31.099326, postalCode: "79855" },
    { lng: -97.22336, lat: 31.279614, postalCode: "76524" },
    { lng: -97.29077, lat: 29.697311, postalCode: "78959" },
    { lng: -97.39219, lat: 33.378039, postalCode: "76267" },
    { lng: -98.47969, lat: 29.707273, postalCode: "78260" },
    { lng: -97.13514, lat: 32.752048, postalCode: "76012" },
    { lng: -96.8233, lat: 31.549368, postalCode: "76664" },
    { lng: -97.39036, lat: 32.220267, postalCode: "76093" },
    { lng: -93.79964, lat: 30.182584, postalCode: "77632" },
    { lng: -97.30099, lat: 32.778479, postalCode: "76111" },
    { lng: -96.99356, lat: 28.806417, postalCode: "77901" },
    { lng: -102.81775, lat: 33.688713, postalCode: "79346" },
    { lng: -97.45167, lat: 32.823629, postalCode: "76135" },
    { lng: -97.090604, lat: 28.954273, postalCode: "77976" },
    { lng: -100.96041, lat: 35.533093, postalCode: "79065" },
    { lng: -100.40597, lat: 34.391847, postalCode: "79259" },
    { lng: -96.25435, lat: 31.889748, postalCode: "75859" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77274" },
    { lng: -95.55009, lat: 30.434486, postalCode: "77318" },
    { lng: -96.74918, lat: 32.792879, postalCode: "75223" },
    { lng: -96.40959, lat: 30.896204, postalCode: "77882" },
    { lng: -96.61211, lat: 31.304634, postalCode: "76653" },
    { lng: -98.06964, lat: 28.972553, postalCode: "78113" },
    { lng: -97.54457, lat: 26.023905, postalCode: "78575" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77280" },
    { lng: -94.7312, lat: 30.907239, postalCode: "75934" },
    { lng: -101.05209, lat: 34.362997, postalCode: "79255" },
    { lng: -96.44131, lat: 31.049092, postalCode: "77856" },
    { lng: -96.24782, lat: 33.385224, postalCode: "75452" },
    { lng: -100.61635, lat: 35.263424, postalCode: "79057" },
    { lng: -99.2959, lat: 31.722813, postalCode: "76878" },
    { lng: -94.09556, lat: 32.472069, postalCode: "75692" },
    { lng: -95.72124, lat: 33.052712, postalCode: "75420" },
    { lng: -98.46669, lat: 29.397934, postalCode: "78210" },
    { lng: -94.94597, lat: 30.595651, postalCode: "77335" },
    { lng: -95.79436, lat: 32.68309, postalCode: "75127" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77241" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75371" },
    { lng: -95.28446, lat: 29.66028, postalCode: "77061" },
    { lng: -95.9357, lat: 28.945269, postalCode: "77414" },
    { lng: -97.08498, lat: 32.723382, postalCode: "76010" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76094" },
    { lng: -96.6808, lat: 30.993313, postalCode: "77837" },
    { lng: -95.41405, lat: 29.735529, postalCode: "77098" },
    { lng: -98.99944, lat: 27.44333, postalCode: "78369" },
    { lng: -101.8316, lat: 33.578935, postalCode: "79401" },
    { lng: -96.80198, lat: 32.78133, postalCode: "75270" },
    { lng: -96.46831, lat: 29.575183, postalCode: "77412" },
    { lng: -94.57688, lat: 32.03719, postalCode: "75669" },
    { lng: -98.68404, lat: 32.214932, postalCode: "76454" },
    { lng: -97.38491, lat: 31.29697, postalCode: "76557" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77249" },
    { lng: -103.014646, lat: 31.566763, postalCode: "79757" },
    { lng: -101.4532, lat: 32.21649, postalCode: "79720" },
    { lng: -97.444155, lat: 28.835825, postalCode: "77993" },
    { lng: -94.40817, lat: 31.368872, postalCode: "75944" },
    { lng: -97.15099, lat: 31.520296, postalCode: "76711" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77256" },
    { lng: -94.72481, lat: 33.02583, postalCode: "75638" },
    { lng: -95.52309, lat: 29.816866, postalCode: "77080" },
    { lng: -98.55194, lat: 32.298841, postalCode: "76445" },
    { lng: -101.60467, lat: 33.870404, postalCode: "79250" },
    { lng: -98.39837, lat: 30.286655, postalCode: "78636" },
    { lng: -97.70355, lat: 27.342872, postalCode: "78364" },
    { lng: -98.35434, lat: 29.475598, postalCode: "78244" },
    { lng: -98.224206, lat: 26.409709, postalCode: "78505" },
    { lng: -97.28566, lat: 32.86814, postalCode: "76137" },
    { lng: -95.27906, lat: 29.719778, postalCode: "77012" },
    { lng: -99.100089, lat: 30.720372, postalCode: "76820" },
    { lng: -98.51795, lat: 29.193828, postalCode: "78264" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79430" },
    { lng: -97.18558, lat: 31.584132, postalCode: "76708" },
    { lng: -103.22091, lat: 30.12169, postalCode: "79842" },
    { lng: -99.69298, lat: 32.882154, postalCode: "79503" },
    { lng: -99.32589, lat: 29.833279, postalCode: "78055" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77413" },
    { lng: -98.42624, lat: 29.698045, postalCode: "78261" },
    { lng: -96.943868, lat: 29.896219, postalCode: "78952" },
    { lng: -98.05568, lat: 26.165352, postalCode: "78537" },
    { lng: -98.39908, lat: 29.448379, postalCode: "78219" },
    { lng: -96.32632, lat: 32.142925, postalCode: "75153" },
    { lng: -96.42982, lat: 31.050207, postalCode: "77870" },
    { lng: -97.203166, lat: 31.553646, postalCode: "76654" },
    { lng: -95.309789, lat: 29.813142, postalCode: "77001" },
    { lng: -98.17476, lat: 32.617128, postalCode: "76472" },
    { lng: -96.69338, lat: 33.818635, postalCode: "75076" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75364" },
    { lng: -95.55779, lat: 32.906074, postalCode: "75497" },
    { lng: -95.07327, lat: 29.920121, postalCode: "77532" },
    { lng: -98.20151, lat: 29.432262, postalCode: "78152" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79493" },
    { lng: -97.21458, lat: 32.645257, postalCode: "76060" },
    { lng: -96.904315, lat: 33.169075, postalCode: "75806" },
    { lng: -95.39644, lat: 30.848603, postalCode: "77367" },
    { lng: -96.60759, lat: 32.767329, postalCode: "75149" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78691" },
    { lng: -96.48147, lat: 32.226257, postalCode: "75155" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75380" },
    { lng: -103.21085, lat: 29.321321, postalCode: "79834" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75367" },
    { lng: -100.21048, lat: 32.766833, postalCode: "79534" },
    { lng: -99.214088, lat: 34.146356, postalCode: "76385" },
    { lng: -100.31313, lat: 35.866528, postalCode: "79014" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75393" },
    { lng: -97.12263, lat: 32.015496, postalCode: "76645" },
    { lng: -97.56317, lat: 29.928764, postalCode: "78616" },
    { lng: -98.81591, lat: 29.312661, postalCode: "78039" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78774" },
    { lng: -96.46299, lat: 33.362227, postalCode: "75485" },
    { lng: -101.84984, lat: 33.570035, postalCode: "79405" },
    { lng: -102.16413, lat: 33.823216, postalCode: "79313" },
    { lng: -94.342725, lat: 32.653731, postalCode: "75694" },
    { lng: -94.2467, lat: 30.090135, postalCode: "77713" },
    { lng: -97.78572, lat: 27.60549, postalCode: "78343" },
    { lng: -97.25123, lat: 32.869384, postalCode: "76148" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76101" },
    { lng: -98.50406, lat: 29.649797, postalCode: "78258" },
    { lng: -101.30313, lat: 34.071514, postalCode: "79258" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77216" },
    { lng: -98.184762, lat: 33.811665, postalCode: "76352" },
    { lng: -96.591535, lat: 30.359245, postalCode: "77863" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78772" },
    { lng: -96.67247, lat: 30.517099, postalCode: "77836" },
    { lng: -96.96601, lat: 32.045029, postalCode: "76628" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75373" },
    { lng: -97.12201, lat: 31.571266, postalCode: "76704" },
    { lng: -95.11734, lat: 29.601776, postalCode: "77059" },
    { lng: -97.879743, lat: 27.889868, postalCode: "78460" },
    { lng: -99.5202, lat: 34.261752, postalCode: "79225" },
    { lng: -98.41805, lat: 32.493233, postalCode: "76463" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77291" },
    { lng: -97.41864, lat: 32.122106, postalCode: "76627" },
    { lng: -96.31278, lat: 32.960512, postalCode: "75189" },
    { lng: -94.190147, lat: 30.111843, postalCode: "77726" },
    { lng: -98.19084, lat: 31.485242, postalCode: "76525" },
    { lng: -95.86383, lat: 31.829314, postalCode: "75861" },
    { lng: -101.3655, lat: 32.110298, postalCode: "79733" },
    { lng: -94.9577, lat: 32.727128, postalCode: "75644" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77220" },
    { lng: -100.09291, lat: 35.629034, postalCode: "79003" },
    { lng: -94.129733, lat: 29.847569, postalCode: "77725" },
    { lng: -100.449, lat: 32.249241, postalCode: "79535" },
    { lng: -98.70484, lat: 29.412338, postalCode: "78245" },
    { lng: -99.76665, lat: 29.756509, postalCode: "78873" },
    { lng: -96.16922, lat: 30.281022, postalCode: "77880" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77289" },
    { lng: -99.73484, lat: 32.475074, postalCode: "79698" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78781" },
    { lng: -100.5669, lat: 31.890079, postalCode: "76945" },
    { lng: -97.13849, lat: 32.851678, postalCode: "76021" },
    { lng: -97.01149, lat: 30.646291, postalCode: "76567" },
    { lng: -97.85877, lat: 30.316223, postalCode: "78716" },
    { lng: -102.56767, lat: 32.345871, postalCode: "79714" },
    { lng: -96.93466, lat: 31.861594, postalCode: "76676" },
    { lng: -100.63288, lat: 34.874151, postalCode: "79237" },
    { lng: -97.776017, lat: 28.393116, postalCode: "78104" },
    { lng: -101.81949, lat: 33.199867, postalCode: "79373" },
    { lng: -97.14593, lat: 32.831228, postalCode: "76022" },
    { lng: -97.87284, lat: 30.17207, postalCode: "78739" },
    { lng: -95.60507, lat: 32.306045, postalCode: "75756" },
    { lng: -95.38927, lat: 30.238563, postalCode: "77302" },
    { lng: -97.62863, lat: 30.294148, postalCode: "78724" },
    { lng: -102.29568, lat: 33.153528, postalCode: "79316" },
    { lng: -98.16479, lat: 27.217893, postalCode: "78355" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76191" },
    { lng: -98.66784, lat: 29.510204, postalCode: "78250" },
    { lng: -94.90585, lat: 33.530829, postalCode: "75550" },
    { lng: -98.21846, lat: 29.960139, postalCode: "78623" },
    { lng: -97.83503, lat: 30.439053, postalCode: "78726" },
    { lng: -97.67711, lat: 30.163458, postalCode: "78719" },
    { lng: -94.55859, lat: 32.854406, postalCode: "75630" },
    { lng: -99.17874, lat: 32.103546, postalCode: "76443" },
    { lng: -95.71738, lat: 32.661299, postalCode: "75140" },
    { lng: -97.7365, lat: 29.425729, postalCode: "78122" },
    { lng: -99.0559, lat: 29.944906, postalCode: "78010" },
    { lng: -101.95391, lat: 35.244819, postalCode: "79124" },
    { lng: -101.37638, lat: 33.245276, postalCode: "79356" },
    { lng: -96.569879, lat: 33.19359, postalCode: "75026" },
    { lng: -93.86086, lat: 31.511711, postalCode: "75959" },
    { lng: -96.86306, lat: 32.867977, postalCode: "75220" },
    { lng: -94.05585, lat: 33.142532, postalCode: "75556" },
    { lng: -99.476792, lat: 27.565464, postalCode: "78042" },
    { lng: -99.48894, lat: 29.634216, postalCode: "78884" },
    { lng: -95.93931, lat: 29.483573, postalCode: "77417" },
    { lng: -94.94358, lat: 32.531814, postalCode: "75647" },
    { lng: -97.27102, lat: 32.630268, postalCode: "76140" },
    { lng: -99.23388, lat: 28.728991, postalCode: "78017" },
    { lng: -95.36663, lat: 33.17329, postalCode: "75478" },
    { lng: -96.70389, lat: 29.838528, postalCode: "78938" },
    { lng: -97.715123, lat: 30.351953, postalCode: "78710" },
    { lng: -98.42781, lat: 29.627332, postalCode: "78259" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77281" },
    { lng: -98.61282, lat: 29.569934, postalCode: "78249" },
    { lng: -94.81869, lat: 30.519883, postalCode: "77326" },
    { lng: -96.73506, lat: 33.054671, postalCode: "75023" },
    { lng: -98.40135, lat: 29.898176, postalCode: "78070" },
    { lng: -96.89163, lat: 32.960374, postalCode: "75006" },
    { lng: -94.24291, lat: 31.943273, postalCode: "75974" },
    { lng: -97.882815, lat: 28.944864, postalCode: "78111" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77258" },
    { lng: -101.30313, lat: 34.071514, postalCode: "79221" },
    { lng: -95.465083, lat: 29.183991, postalCode: "77516" },
    { lng: -95.53514, lat: 29.70053, postalCode: "77036" },
    { lng: -97.80932, lat: 33.197313, postalCode: "76426" },
    { lng: -97.16941, lat: 33.236827, postalCode: "76207" },
    { lng: -96.766109, lat: 33.104374, postalCode: "76025" },
    { lng: -97.62496, lat: 30.166225, postalCode: "78617" },
    { lng: -100.9153, lat: 32.747707, postalCode: "79516" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77337" },
    { lng: -96.46101, lat: 30.68416, postalCode: "77807" },
    { lng: -98.49049, lat: 29.363335, postalCode: "78214" },
    { lng: -95.29467, lat: 32.325214, postalCode: "75701" },
    { lng: -102.35059, lat: 31.389079, postalCode: "79731" },
    { lng: -97.203166, lat: 31.553646, postalCode: "76703" },
    { lng: -97.84469, lat: 30.250761, postalCode: "78735" },
    { lng: -97.42165, lat: 32.772063, postalCode: "76127" },
    { lng: -98.52492, lat: 29.387734, postalCode: "78225" },
    { lng: -95.585464, lat: 33.168606, postalCode: "75483" },
    { lng: -95.53215, lat: 30.024749, postalCode: "77379" },
    { lng: -100.47198, lat: 34.216509, postalCode: "79223" },
    { lng: -97.3318, lat: 29.846862, postalCode: "78953" },
    { lng: -98.44588, lat: 30.663209, postalCode: "78639" },
    { lng: -95.10714, lat: 30.045495, postalCode: "77336" },
    { lng: -97.463213, lat: 27.777, postalCode: "78468" },
    { lng: -97.08821, lat: 31.609834, postalCode: "76705" },
    { lng: -97.32987, lat: 32.75388, postalCode: "76102" },
    { lng: -97.26992, lat: 32.724831, postalCode: "76105" },
    { lng: -94.761103, lat: 31.362315, postalCode: "75902" },
    { lng: -95.21751, lat: 32.226082, postalCode: "75791" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77224" },
    { lng: -97.116282, lat: 33.20743, postalCode: "75029" },
    { lng: -95.483489, lat: 29.472186, postalCode: "77889" },
    { lng: -98.290394, lat: 29.449332, postalCode: "78262" },
    { lng: -95.14402, lat: 33.815996, postalCode: "75412" },
    { lng: -93.903108, lat: 30.054793, postalCode: "77631" },
    { lng: -95.00317, lat: 29.339307, postalCode: "77563" },
    { lng: -99.77096, lat: 32.432975, postalCode: "79605" },
    { lng: -97.51618, lat: 25.928274, postalCode: "78520" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77236" },
    { lng: -94.25152, lat: 32.941359, postalCode: "75562" },
    { lng: -95.60102, lat: 33.143752, postalCode: "75482" },
    { lng: -96.97377, lat: 32.111683, postalCode: "76670" },
    { lng: -97.463213, lat: 27.777, postalCode: "78478" },
    { lng: -97.07945, lat: 31.783019, postalCode: "76691" },
    { lng: -100.04142, lat: 33.151135, postalCode: "79540" },
    { lng: -94.340437, lat: 33.393447, postalCode: "75507" },
    { lng: -94.928795, lat: 32.301898, postalCode: "75666" },
    { lng: -98.79242, lat: 30.904481, postalCode: "76885" },
    { lng: -95.7301, lat: 29.825908, postalCode: "77449" },
    { lng: -100.68959, lat: 31.39577, postalCode: "76902" },
    { lng: -94.94939, lat: 31.506076, postalCode: "75976" },
    { lng: -99.68057, lat: 31.801928, postalCode: "76882" },
    { lng: -95.63493, lat: 32.533765, postalCode: "75790" },
    { lng: -100.28401, lat: 34.38587, postalCode: "79201" },
    { lng: -95.66597, lat: 29.982746, postalCode: "77429" },
    { lng: -103.188993, lat: 31.459448, postalCode: "79779" },
    { lng: -97.52948, lat: 26.259032, postalCode: "78583" },
    { lng: -98.060419, lat: 29.619401, postalCode: "78136" },
    { lng: -97.80383, lat: 30.438933, postalCode: "78750" },
    { lng: -96.56134, lat: 29.712052, postalCode: "78934" },
    { lng: -100.033, lat: 31.611317, postalCode: "76875" },
    { lng: -94.391148, lat: 31.902986, postalCode: "75957" },
    { lng: -97.72338, lat: 30.674349, postalCode: "78628" },
    { lng: -98.52524, lat: 29.589826, postalCode: "78248" },
    { lng: -100.61443, lat: 33.773871, postalCode: "79232" },
    { lng: -95.106637, lat: 29.569927, postalCode: "77508" },
    { lng: -95.154008, lat: 31.806615, postalCode: "75875" },
    { lng: -101.92669, lat: 33.565926, postalCode: "79408" },
    { lng: -95.48532, lat: 30.007886, postalCode: "77068" },
    { lng: -100.48058, lat: 28.716242, postalCode: "78852" },
    { lng: -95.47125, lat: 33.253911, postalCode: "75437" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77231" },
    { lng: -98.55468, lat: 29.392167, postalCode: "78226" },
    { lng: -97.81591, lat: 28.615464, postalCode: "78146" },
    { lng: -100.47217, lat: 31.344515, postalCode: "76904" },
    { lng: -98.43411, lat: 30.096855, postalCode: "78606" },
    { lng: -97.429952, lat: 26.561287, postalCode: "78561" },
    { lng: -94.60291, lat: 30.249734, postalCode: "77519" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77268" },
    { lng: -102.18977, lat: 35.151101, postalCode: "79098" },
    { lng: -98.37248, lat: 31.833414, postalCode: "76455" },
    { lng: -96.77439, lat: 32.669383, postalCode: "75241" },
    { lng: -95.08971, lat: 32.364281, postalCode: "75705" },
    { lng: -96.07144, lat: 29.632221, postalCode: "77485" },
    { lng: -101.817485, lat: 35.050003, postalCode: "79114" },
    { lng: -96.22273, lat: 28.735638, postalCode: "77465" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75222" },
    { lng: -96.03725, lat: 31.453732, postalCode: "75831" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77298" },
    { lng: -94.863311, lat: 32.690553, postalCode: "75645" },
    { lng: -102.01155, lat: 36.044769, postalCode: "79013" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77254" },
    { lng: -100.46635, lat: 32.87144, postalCode: "79546" },
    { lng: -98.55962, lat: 26.247488, postalCode: "78565" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77844" },
    { lng: -96.67582, lat: 32.72238, postalCode: "75217" },
    { lng: -94.54519, lat: 32.6941, postalCode: "75651" },
    { lng: -97.16435, lat: 32.659277, postalCode: "76017" },
    { lng: -95.16761, lat: 31.79364, postalCode: "75785" },
    { lng: -102.14673, lat: 32.931786, postalCode: "79377" },
    { lng: -97.20757, lat: 28.103283, postalCode: "78340" },
    { lng: -97.95854, lat: 30.19025, postalCode: "78737" },
    { lng: -95.59002, lat: 29.700898, postalCode: "77072" },
    { lng: -95.8144, lat: 30.065152, postalCode: "77447" },
    { lng: -98.72148, lat: 33.760814, postalCode: "76366" },
    { lng: -96.22212, lat: 31.476671, postalCode: "75838" },
    { lng: -96.32126, lat: 31.83272, postalCode: "75848" },
    { lng: -95.661964, lat: 31.794191, postalCode: "75832" },
    { lng: -96.84436, lat: 32.998786, postalCode: "75287" },
    { lng: -96.664632, lat: 33.351238, postalCode: "75097" },
    { lng: -94.74353, lat: 32.557684, postalCode: "75605" },
    { lng: -95.31828, lat: 33.507909, postalCode: "75435" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78297" },
    { lng: -102.61953, lat: 33.600017, postalCode: "79379" },
    { lng: -103.52515, lat: 31.388404, postalCode: "79772" },
    { lng: -95.45065, lat: 29.952354, postalCode: "77067" },
    { lng: -95.30405, lat: 30.735628, postalCode: "77359" },
    { lng: -101.54672, lat: 36.004239, postalCode: "79062" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77273" },
    { lng: -97.65549, lat: 30.514401, postalCode: "78664" },
    { lng: -94.52629, lat: 31.834379, postalCode: "75946" },
    { lng: -99.14462, lat: 31.714347, postalCode: "76823" },
    { lng: -97.38491, lat: 32.573816, postalCode: "76036" },
    { lng: -96.32079, lat: 29.575458, postalCode: "77434" },
    { lng: -97.31086, lat: 31.093215, postalCode: "76501" },
    { lng: -95.38178, lat: 29.858525, postalCode: "77076" },
    { lng: -97.68776, lat: 26.206602, postalCode: "78550" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77272" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77315" },
    { lng: -98.56584, lat: 29.422583, postalCode: "78237" },
    { lng: -95.957945, lat: 30.607534, postalCode: "77875" },
    { lng: -98.4639, lat: 28.958803, postalCode: "78064" },
    { lng: -98.120104, lat: 29.687579, postalCode: "78134" },
    { lng: -97.799309, lat: 31.390196, postalCode: "76597" },
    { lng: -97.69688, lat: 33.824724, postalCode: "76255" },
    { lng: -97.582152, lat: 31.295568, postalCode: "76526" },
    { lng: -95.66391, lat: 29.839155, postalCode: "77084" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75339" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78293" },
    { lng: -102.15822, lat: 33.91843, postalCode: "79369" },
    { lng: -95.27605, lat: 29.362879, postalCode: "77512" },
    { lng: -100.74939, lat: 35.201105, postalCode: "79002" },
    { lng: -99.85837, lat: 31.333614, postalCode: "76855" },
    { lng: -94.94935, lat: 32.26958, postalCode: "75684" },
    { lng: -97.50971, lat: 27.806753, postalCode: "78409" },
    { lng: -95.46826, lat: 30.055195, postalCode: "77388" },
    { lng: -94.31589, lat: 33.328596, postalCode: "75567" },
    { lng: -98.89162, lat: 29.466279, postalCode: "78066" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77842" },
    { lng: -97.203166, lat: 31.553646, postalCode: "76715" },
    { lng: -102.41478, lat: 33.609208, postalCode: "79336" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75264" },
    { lng: -100.33123, lat: 36.441178, postalCode: "79024" },
    { lng: -100.38106, lat: 32.739964, postalCode: "79543" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77215" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79172" },
    { lng: -99.70865, lat: 32.043108, postalCode: "79519" },
    { lng: -99.062, lat: 34.081642, postalCode: "76364" },
    { lng: -97.13046, lat: 29.254632, postalCode: "77995" },
    { lng: -96.526669, lat: 30.341403, postalCode: "77839" },
    { lng: -96.88966, lat: 28.846861, postalCode: "77988" },
    { lng: -94.68886, lat: 31.934633, postalCode: "75681" },
    { lng: -97.74038, lat: 30.188377, postalCode: "78744" },
    { lng: -94.75227, lat: 33.183401, postalCode: "75571" },
    { lng: -95.52546, lat: 33.404332, postalCode: "75450" },
    { lng: -95.62444, lat: 29.627737, postalCode: "77478" },
    { lng: -97.68651, lat: 30.306507, postalCode: "78723" },
    { lng: -96.8745, lat: 29.909764, postalCode: "78945" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78769" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76198" },
    { lng: -95.40217, lat: 30.368543, postalCode: "77303" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78287" },
    { lng: -97.10206, lat: 33.024778, postalCode: "75022" },
    { lng: -97.127027, lat: 33.246457, postalCode: "76203" },
    { lng: -94.53657, lat: 33.328878, postalCode: "75574" },
    { lng: -95.383202, lat: 30.290638, postalCode: "77305" },
    { lng: -101.851144, lat: 33.592235, postalCode: "79402" },
    { lng: -98.11528, lat: 29.351202, postalCode: "78121" },
    { lng: -96.934998, lat: 32.86372, postalCode: "75059" },
    { lng: -96.45923, lat: 29.424483, postalCode: "77442" },
    { lng: -97.492017, lat: 31.036287, postalCode: "76505" },
    { lng: -96.15852, lat: 31.058563, postalCode: "77871" },
    { lng: -96.08527, lat: 29.520049, postalCode: "77435" },
    { lng: -102.64729, lat: 31.983989, postalCode: "79741" },
    { lng: -97.463213, lat: 27.777, postalCode: "78467" },
    { lng: -104.386683, lat: 29.943719, postalCode: "79850" },
    { lng: -97.76087, lat: 30.246309, postalCode: "78704" },
    { lng: -96.91265, lat: 32.658384, postalCode: "75116" },
    { lng: -97.97507, lat: 30.453776, postalCode: "78645" },
    { lng: -99.347156, lat: 31.217152, postalCode: "76836" },
    { lng: -96.94129, lat: 32.876474, postalCode: "75039" },
    { lng: -96.78863, lat: 32.968123, postalCode: "75248" },
    { lng: -97.76167, lat: 29.292655, postalCode: "78140" },
    { lng: -97.463213, lat: 27.777, postalCode: "78473" },
    { lng: -98.36511, lat: 30.538867, postalCode: "78657" },
    { lng: -100.42452, lat: 29.313559, postalCode: "78832" },
    { lng: -95.4355, lat: 32.810076, postalCode: "75783" },
    { lng: -94.7994, lat: 30.998982, postalCode: "75939" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76244" },
    { lng: -95.34357, lat: 28.975247, postalCode: "77541" },
    { lng: -98.53215, lat: 33.892447, postalCode: "76309" },
    { lng: -97.77987, lat: 32.77516, postalCode: "76086" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78206" },
    { lng: -101.96324, lat: 31.880341, postalCode: "79706" },
    { lng: -95.81737, lat: 33.31215, postalCode: "75448" },
    { lng: -96.64311, lat: 32.075079, postalCode: "75102" },
    { lng: -97.98869, lat: 30.340111, postalCode: "78738" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77237" },
    { lng: -97.10376, lat: 33.16156, postalCode: "76205" },
    { lng: -97.18944, lat: 33.358871, postalCode: "76266" },
    { lng: -97.463213, lat: 27.777, postalCode: "78477" },
    { lng: -97.463213, lat: 27.777, postalCode: "78403" },
    { lng: -102.35398, lat: 31.890374, postalCode: "79762" },
    { lng: -95.23906, lat: 29.737178, postalCode: "77547" },
    { lng: -97.78256, lat: 28.52604, postalCode: "78142" },
    { lng: -98.86043, lat: 33.224562, postalCode: "76372" },
    { lng: -97.82298, lat: 31.771383, postalCode: "76637" },
    { lng: -96.44339, lat: 32.629178, postalCode: "75114" },
    { lng: -97.764271, lat: 26.188911, postalCode: "78522" },
    { lng: -95.51772, lat: 29.601141, postalCode: "77489" },
    { lng: -96.2774, lat: 29.197701, postalCode: "77437" },
    { lng: -96.89776, lat: 31.74658, postalCode: "76673" },
    { lng: -99.2821, lat: 28.265415, postalCode: "78001" },
    { lng: -96.35096, lat: 33.171899, postalCode: "75442" },
    { lng: -94.1705, lat: 30.635615, postalCode: "77660" },
    { lng: -95.48361, lat: 29.70828, postalCode: "77081" },
    { lng: -96.45446, lat: 32.93382, postalCode: "75087" },
    { lng: -98.49127, lat: 33.970725, postalCode: "76311" },
    { lng: -99.795167, lat: 32.428796, postalCode: "79604" },
    { lng: -98.27924, lat: 28.633209, postalCode: "78075" },
    { lng: -95.47294, lat: 29.979063, postalCode: "77014" },
    { lng: -98.5528, lat: 28.85458, postalCode: "78026" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77572" },
    { lng: -95.92241, lat: 29.249812, postalCode: "77420" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77257" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75326" },
    { lng: -96.794496, lat: 32.946069, postalCode: "75254" },
    { lng: -94.9689, lat: 31.883205, postalCode: "75784" },
    { lng: -98.7959, lat: 32.398681, postalCode: "76448" },
    { lng: -103.556598, lat: 31.383297, postalCode: "79770" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75359" },
    { lng: -95.55919, lat: 29.923638, postalCode: "77064" },
    { lng: -98.687748, lat: 34.023386, postalCode: "76369" },
    { lng: -95.17832, lat: 30.170751, postalCode: "77357" },
    { lng: -99.463668, lat: 27.492996, postalCode: "78093" },
    { lng: -95.46821, lat: 30.143485, postalCode: "77380" },
    { lng: -98.48719, lat: 33.646685, postalCode: "76379" },
    { lng: -96.74872, lat: 32.825628, postalCode: "75214" },
    { lng: -95.48695, lat: 29.621746, postalCode: "77085" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78275" },
    { lng: -95.43131, lat: 29.733181, postalCode: "77046" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78711" },
    { lng: -99.92215, lat: 31.472654, postalCode: "76866" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78243" },
    { lng: -98.77741, lat: 31.685153, postalCode: "76890" },
    { lng: -94.951647, lat: 29.487774, postalCode: "77439" },
    { lng: -98.35073, lat: 32.07881, postalCode: "76446" },
    { lng: -96.75892, lat: 32.590063, postalCode: "75146" },
    { lng: -98.64891, lat: 26.270824, postalCode: "78548" },
    { lng: -95.06517, lat: 32.254414, postalCode: "75750" },
    { lng: -97.08385, lat: 31.884263, postalCode: "76621" },
    { lng: -99.4289, lat: 28.062471, postalCode: "78019" },
    { lng: -95.68573, lat: 33.377005, postalCode: "75432" },
    { lng: -96.080973, lat: 33.056265, postalCode: "75404" },
    { lng: -94.18056, lat: 30.390569, postalCode: "77656" },
    { lng: -96.43601, lat: 33.022051, postalCode: "75166" },
    { lng: -96.79524, lat: 32.836094, postalCode: "75205" },
    { lng: -100.34896, lat: 31.515684, postalCode: "76905" },
    { lng: -99.00605, lat: 31.73003, postalCode: "76801" },
    { lng: -95.85303, lat: 31.579572, postalCode: "75855" },
    { lng: -96.77035, lat: 32.76103, postalCode: "75215" },
    { lng: -100.21246, lat: 34.006473, postalCode: "79248" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75315" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75250" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79167" },
    { lng: -94.961797, lat: 29.495537, postalCode: "77999" },
    { lng: -94.37468, lat: 33.189862, postalCode: "75560" },
    { lng: -94.40775, lat: 31.903614, postalCode: "75975" },
    { lng: -95.45981, lat: 29.596666, postalCode: "77053" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76147" },
    { lng: -96.11068, lat: 32.314256, postalCode: "75147" },
    { lng: -95.582906, lat: 33.65973, postalCode: "75425" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77266" },
    { lng: -99.79886, lat: 32.360362, postalCode: "79606" },
    { lng: -96.524899, lat: 29.60466, postalCode: "77460" },
    { lng: -99.203985, lat: 26.945418, postalCode: "78564" },
    { lng: -94.69544, lat: 32.723482, postalCode: "75640" },
    { lng: -96.524899, lat: 29.60466, postalCode: "78951" },
    { lng: -95.51718, lat: 29.647637, postalCode: "77071" },
    { lng: -94.96809, lat: 32.97708, postalCode: "75686" },
    { lng: -97.4289, lat: 27.727083, postalCode: "78415" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77288" },
    { lng: -97.92547, lat: 26.301385, postalCode: "78562" },
    { lng: -96.78543, lat: 32.938774, postalCode: "75240" },
    { lng: -99.93471, lat: 31.965685, postalCode: "79567" },
    { lng: -97.71691, lat: 33.122273, postalCode: "76073" },
    { lng: -100.53964, lat: 34.715551, postalCode: "79245" },
    { lng: -98.46239, lat: 29.414983, postalCode: "78203" },
    { lng: -97.463213, lat: 27.777, postalCode: "78470" },
    { lng: -95.59919, lat: 32.075703, postalCode: "75782" },
    { lng: -96.44612, lat: 32.078228, postalCode: "75110" },
    { lng: -99.17091, lat: 30.973049, postalCode: "76887" },
    { lng: -97.116282, lat: 33.20743, postalCode: "75027" },
    { lng: -99.64257, lat: 33.336436, postalCode: "76388" },
    { lng: -97.2506, lat: 32.92704, postalCode: "76248" },
    { lng: -102.3595, lat: 35.890235, postalCode: "79044" },
    { lng: -95.6314, lat: 29.722704, postalCode: "77082" },
    { lng: -98.67402, lat: 32.497298, postalCode: "76470" },
    { lng: -97.71659, lat: 30.289307, postalCode: "78722" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77411" },
    { lng: -95.5618, lat: 29.802473, postalCode: "77043" },
    { lng: -96.79859, lat: 33.07707, postalCode: "75024" },
    { lng: -95.005317, lat: 29.469835, postalCode: "77537" },
    { lng: -101.16687, lat: 35.432364, postalCode: "79097" },
    { lng: -98.14834, lat: 31.696154, postalCode: "76531" },
    { lng: -102.32282, lat: 33.894823, postalCode: "79339" },
    { lng: -99.34384, lat: 29.671077, postalCode: "78883" },
    { lng: -97.492017, lat: 31.036287, postalCode: "76508" },
    { lng: -97.97615, lat: 31.071352, postalCode: "76539" },
    { lng: -97.64097, lat: 31.897381, postalCode: "76644" },
    { lng: -97.84169, lat: 26.078355, postalCode: "78592" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75313" },
    { lng: -99.36781, lat: 27.198571, postalCode: "78067" },
    { lng: -97.463213, lat: 27.777, postalCode: "78475" },
    { lng: -99.45996, lat: 27.435814, postalCode: "78046" },
    { lng: -97.40019, lat: 27.795805, postalCode: "78401" },
    { lng: -99.347156, lat: 31.217152, postalCode: "76867" },
    { lng: -98.41833, lat: 29.411583, postalCode: "78220" },
    { lng: -98.33271, lat: 32.722689, postalCode: "76484" },
    { lng: -97.380884, lat: 27.723611, postalCode: "78419" },
    { lng: -95.96882, lat: 29.602015, postalCode: "77464" },
    { lng: -98.05424, lat: 29.273819, postalCode: "78161" },
    { lng: -102.89658, lat: 31.568459, postalCode: "79756" },
    { lng: -95.99945, lat: 29.818871, postalCode: "77466" },
    { lng: -97.90477, lat: 32.842085, postalCode: "76088" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79163" },
    { lng: -97.07998, lat: 27.818477, postalCode: "78373" },
    { lng: -95.25748, lat: 30.102209, postalCode: "77365" },
    { lng: -101.86114, lat: 35.157403, postalCode: "79110" },
    { lng: -103.556598, lat: 31.383297, postalCode: "79786" },
    { lng: -96.91632, lat: 33.469076, postalCode: "76271" },
    { lng: -96.61962, lat: 32.331239, postalCode: "75119" },
    { lng: -95.05298, lat: 29.81953, postalCode: "77562" },
    { lng: -96.40258, lat: 30.183651, postalCode: "77833" },
    { lng: -99.18511, lat: 32.57665, postalCode: "76464" },
    { lng: -96.429154, lat: 31.930849, postalCode: "76618" },
    { lng: -100.15822, lat: 32.504094, postalCode: "79561" },
    { lng: -100.1175, lat: 30.480128, postalCode: "76874" },
    { lng: -98.6099, lat: 29.646618, postalCode: "78257" },
    { lng: -96.49664, lat: 33.14572, postalCode: "75407" },
    { lng: -95.46605, lat: 33.027493, postalCode: "75431" },
    { lng: -96.86644, lat: 29.369619, postalCode: "77964" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78295" },
    { lng: -102.45367, lat: 31.86577, postalCode: "79764" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78150" },
    { lng: -96.49614, lat: 31.376261, postalCode: "76687" },
    { lng: -97.65552, lat: 31.068459, postalCode: "76548" },
    { lng: -98.24639, lat: 26.105725, postalCode: "78557" },
    { lng: -96.95499, lat: 32.80268, postalCode: "75060" },
    { lng: -102.03317, lat: 32.369835, postalCode: "79783" },
    { lng: -96.11889, lat: 31.134863, postalCode: "77855" },
    { lng: -95.54762, lat: 29.564347, postalCode: "77459" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75396" },
    { lng: -101.22477, lat: 35.655035, postalCode: "79080" },
    { lng: -94.12607, lat: 30.085112, postalCode: "77702" },
    { lng: -95.45028, lat: 29.879213, postalCode: "77088" },
    { lng: -98.57124, lat: 30.002501, postalCode: "78027" },
    { lng: -102.099276, lat: 33.044702, postalCode: "79368" },
    { lng: -97.463213, lat: 27.777, postalCode: "78465" },
    { lng: -97.40099, lat: 32.781329, postalCode: "76114" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75014" },
    { lng: -98.082694, lat: 27.750684, postalCode: "78331" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79182" },
    { lng: -104.22045, lat: 30.217129, postalCode: "79843" },
    { lng: -95.48123, lat: 29.674336, postalCode: "77096" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76130" },
    { lng: -101.06523, lat: 32.584546, postalCode: "79527" },
    { lng: -96.95358, lat: 30.2984, postalCode: "78948" },
    { lng: -97.8751, lat: 30.329704, postalCode: "78733" },
    { lng: -97.494649, lat: 29.447211, postalCode: "78604" },
    { lng: -97.14403, lat: 32.634203, postalCode: "76001" },
    { lng: -96.84612, lat: 32.828128, postalCode: "75235" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75395" },
    { lng: -96.37639, lat: 30.678097, postalCode: "77803" },
    { lng: -97.21818, lat: 32.855666, postalCode: "76180" },
    { lng: -97.486509, lat: 30.450088, postalCode: "78715" },
    { lng: -96.57345, lat: 33.277317, postalCode: "75454" },
    { lng: -95.20749, lat: 32.292394, postalCode: "75707" },
    { lng: -94.88864, lat: 30.044219, postalCode: "77535" },
    { lng: -97.13061, lat: 33.226598, postalCode: "76201" },
    { lng: -95.56286, lat: 32.730279, postalCode: "75444" },
    { lng: -95.13802, lat: 30.619313, postalCode: "77331" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75360" },
    { lng: -97.53029, lat: 33.701692, postalCode: "76265" },
    { lng: -95.87967, lat: 32.906194, postalCode: "75472" },
    { lng: -95.89195, lat: 29.177817, postalCode: "77468" },
    { lng: -98.53788, lat: 30.217838, postalCode: "78635" },
    { lng: -102.28644, lat: 31.910706, postalCode: "79765" },
    { lng: -94.72677, lat: 30.154264, postalCode: "77561" },
    { lng: -96.29882, lat: 30.571905, postalCode: "77845" },
    { lng: -100.10134, lat: 31.377623, postalCode: "76937" },
    { lng: -96.91786, lat: 33.705904, postalCode: "76273" },
    { lng: -96.21035, lat: 32.348007, postalCode: "75143" },
    { lng: -96.53811, lat: 32.628858, postalCode: "75159" },
    { lng: -97.80031, lat: 26.344765, postalCode: "78594" },
    { lng: -96.10123, lat: 29.799608, postalCode: "77473" },
    { lng: -98.55799, lat: 32.122804, postalCode: "76444" },
    { lng: -96.81034, lat: 31.190291, postalCode: "76680" },
    { lng: -99.73847, lat: 29.628266, postalCode: "78879" },
    { lng: -97.94177, lat: 30.245558, postalCode: "78736" },
    { lng: -97.02091, lat: 31.35797, postalCode: "76685" },
    { lng: -98.48413, lat: 33.332336, postalCode: "76460" },
    { lng: -97.64633, lat: 32.546387, postalCode: "76035" },
    { lng: -96.00312, lat: 32.310343, postalCode: "75124" },
    { lng: -97.100624, lat: 28.925513, postalCode: "77902" },
    { lng: -100.57389, lat: 33.238378, postalCode: "79528" },
    { lng: -96.48373, lat: 33.521578, postalCode: "75489" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79105" },
    { lng: -94.60338, lat: 31.626011, postalCode: "75961" },
    { lng: -99.55361, lat: 29.785042, postalCode: "78885" },
    { lng: -95.7102, lat: 32.33383, postalCode: "75778" },
    { lng: -102.32177, lat: 31.749504, postalCode: "79766" },
    { lng: -95.36531, lat: 29.755578, postalCode: "77002" },
    { lng: -97.05965, lat: 33.033214, postalCode: "75028" },
    { lng: -95.13467, lat: 29.539581, postalCode: "77598" },
    { lng: -98.583016, lat: 26.749896, postalCode: "78591" },
    { lng: -94.78066, lat: 33.563837, postalCode: "75554" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76129" },
    { lng: -97.29676, lat: 33.187644, postalCode: "76259" },
    { lng: -95.30886, lat: 29.857007, postalCode: "77016" },
    { lng: -96.900331, lat: 29.347975, postalCode: "77987" },
    { lng: -102.33103, lat: 33.733418, postalCode: "79380" },
    { lng: -94.11736, lat: 30.112312, postalCode: "77703" },
    { lng: -99.51447, lat: 33.481208, postalCode: "76363" },
    { lng: -98.03875, lat: 30.103644, postalCode: "78619" },
    { lng: -101.65054, lat: 33.437291, postalCode: "79364" },
    { lng: -94.43631, lat: 30.520625, postalCode: "77663" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75260" },
    { lng: -97.88575, lat: 33.54007, postalCode: "76230" },
    { lng: -93.95575, lat: 29.879796, postalCode: "77640" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77205" },
    { lng: -97.40165, lat: 27.687792, postalCode: "78413" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75017" },
    { lng: -98.96537, lat: 32.105459, postalCode: "76471" },
    { lng: -95.63918, lat: 30.194267, postalCode: "77354" },
    { lng: -101.66373, lat: 32.388962, postalCode: "79748" },
    { lng: -98.74853, lat: 32.428128, postalCode: "76466" },
    { lng: -97.08724, lat: 32.65392, postalCode: "76018" },
    { lng: -98.14978, lat: 28.260148, postalCode: "78022" },
    { lng: -97.60992, lat: 30.231583, postalCode: "78725" },
    { lng: -94.66678, lat: 30.023932, postalCode: "77582" },
    { lng: -97.89459, lat: 30.382724, postalCode: "78732" },
    { lng: -97.21957, lat: 32.744032, postalCode: "76112" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77217" },
    { lng: -100.97272, lat: 36.317403, postalCode: "79033" },
    { lng: -98.61251, lat: 29.389767, postalCode: "78236" },
    { lng: -98.36775, lat: 29.552128, postalCode: "78233" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76197" },
    { lng: -95.14632, lat: 29.650492, postalCode: "77505" },
    { lng: -103.12722, lat: 31.535153, postalCode: "79777" },
    { lng: -100.95344, lat: 32.760229, postalCode: "79549" },
    { lng: -102.01948, lat: 33.701024, postalCode: "79363" },
    { lng: -97.68665, lat: 30.272926, postalCode: "78721" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77226" },
    { lng: -102.16008, lat: 32.021056, postalCode: "79707" },
    { lng: -93.90117, lat: 29.732092, postalCode: "77655" },
    { lng: -103.79377, lat: 31.306912, postalCode: "79785" },
    { lng: -95.39916, lat: 29.933367, postalCode: "77060" },
    { lng: -95.53337, lat: 29.878345, postalCode: "77040" },
    { lng: -94.944391, lat: 29.22051, postalCode: "77552" },
    { lng: -96.61514, lat: 31.949312, postalCode: "76679" },
    { lng: -97.13833, lat: 31.551955, postalCode: "76701" },
    { lng: -97.799309, lat: 31.390196, postalCode: "76598" },
    { lng: -101.20597, lat: 32.883354, postalCode: "79517" },
    { lng: -96.79944, lat: 33.240766, postalCode: "75078" },
    { lng: -97.2609, lat: 32.524393, postalCode: "76097" },
    { lng: -96.54697, lat: 32.923924, postalCode: "75089" },
    { lng: -99.47679, lat: 31.547652, postalCode: "76845" },
    { lng: -97.98512, lat: 26.162609, postalCode: "78596" },
    { lng: -98.88062, lat: 29.356455, postalCode: "78009" },
    { lng: -94.354476, lat: 30.792674, postalCode: "75990" },
    { lng: -96.66188, lat: 32.960375, postalCode: "75044" },
    { lng: -94.41954, lat: 33.465282, postalCode: "75570" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79165" },
    { lng: -95.31347, lat: 32.264365, postalCode: "75703" },
    { lng: -97.52946, lat: 31.430323, postalCode: "76561" },
    { lng: -94.46427, lat: 32.237924, postalCode: "75631" },
    { lng: -95.03486, lat: 29.576638, postalCode: "77586" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75262" },
    { lng: -93.75338, lat: 31.332569, postalCode: "75948" },
    { lng: -101.999047, lat: 35.245398, postalCode: "79116" },
    { lng: -99.63167, lat: 32.011087, postalCode: "79538" },
    { lng: -94.85568, lat: 32.104159, postalCode: "75654" },
    { lng: -102.29761, lat: 34.209814, postalCode: "79082" },
    { lng: -96.93171, lat: 30.182175, postalCode: "78942" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77210" },
    { lng: -95.77833, lat: 30.353346, postalCode: "77333" },
    { lng: -98.30109, lat: 30.767327, postalCode: "78611" },
    { lng: -97.17019, lat: 26.117636, postalCode: "78597" },
    { lng: -96.7832, lat: 32.221421, postalCode: "76623" },
    { lng: -100.21999, lat: 35.662917, postalCode: "79011" },
    { lng: -96.76129, lat: 33.327927, postalCode: "75009" },
    { lng: -97.8438, lat: 30.12848, postalCode: "78652" },
    { lng: -96.74093, lat: 32.969523, postalCode: "75080" },
    { lng: -96.60363, lat: 33.195073, postalCode: "75069" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76095" },
    { lng: -99.86203, lat: 33.308203, postalCode: "79544" },
    { lng: -96.84723, lat: 28.390776, postalCode: "77950" },
    { lng: -97.71727, lat: 33.660084, postalCode: "76251" },
    { lng: -101.898, lat: 34.281443, postalCode: "79032" },
    { lng: -100.88116, lat: 29.404267, postalCode: "78840" },
    { lng: -99.77713, lat: 34.297126, postalCode: "79252" },
    { lng: -93.96038, lat: 29.980863, postalCode: "77651" },
    { lng: -98.52519, lat: 29.466781, postalCode: "78201" },
    { lng: -102.52625, lat: 34.068183, postalCode: "79371" },
    { lng: -98.73632, lat: 31.561427, postalCode: "76864" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75320" },
    { lng: -99.50744, lat: 28.555795, postalCode: "78830" },
    { lng: -101.93239, lat: 33.513337, postalCode: "79424" },
    { lng: -97.32054, lat: 27.884565, postalCode: "78374" },
    { lng: -102.8564, lat: 31.34197, postalCode: "79742" },
    { lng: -95.289903, lat: 32.411237, postalCode: "75799" },
    { lng: -94.639022, lat: 31.534162, postalCode: "75958" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78651" },
    { lng: -96.89773, lat: 33.00542, postalCode: "75007" },
    { lng: -100.6895, lat: 33.361766, postalCode: "79518" },
    { lng: -97.13636, lat: 33.627942, postalCode: "76240" },
    { lng: -96.95317, lat: 32.639661, postalCode: "75249" },
    { lng: -95.21789, lat: 29.63643, postalCode: "77034" },
    { lng: -97.08498, lat: 32.93195, postalCode: "76051" },
    { lng: -95.747, lat: 29.584172, postalCode: "77469" },
    { lng: -97.492017, lat: 31.036287, postalCode: "76564" },
    { lng: -98.93928, lat: 31.747887, postalCode: "76802" },
    { lng: -101.38794, lat: 33.667836, postalCode: "79357" },
    { lng: -100.28655, lat: 31.887436, postalCode: "76933" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75389" },
    { lng: -102.56265, lat: 32.922387, postalCode: "79359" },
    { lng: -95.39772, lat: 29.683865, postalCode: "77054" },
    { lng: -96.48721, lat: 31.669823, postalCode: "76667" },
    { lng: -98.36902, lat: 29.221682, postalCode: "78112" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77869" },
    { lng: -100.90662, lat: 34.971719, postalCode: "79226" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77805" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78299" },
    { lng: -93.74329, lat: 30.293684, postalCode: "77614" },
    { lng: -97.98997, lat: 31.506687, postalCode: "76566" },
    { lng: -96.302743, lat: 32.599614, postalCode: "75118" },
    { lng: -93.99986, lat: 29.974013, postalCode: "77627" },
    { lng: -102.86992, lat: 33.16997, postalCode: "79355" },
    { lng: -98.17653, lat: 29.749204, postalCode: "78132" },
    { lng: -95.1201, lat: 29.687657, postalCode: "77536" },
    { lng: -98.595422, lat: 33.036724, postalCode: "76420" },
    { lng: -96.439495, lat: 32.455407, postalCode: "75157" },
    { lng: -94.493543, lat: 32.102729, postalCode: "75637" },
    { lng: -97.78894, lat: 32.424695, postalCode: "76048" },
    { lng: -96.06979, lat: 30.096649, postalCode: "77445" },
    { lng: -99.45514, lat: 31.847973, postalCode: "76834" },
    { lng: -99.711665, lat: 32.466474, postalCode: "79699" },
    { lng: -95.46752, lat: 30.545212, postalCode: "77358" },
    { lng: -96.787725, lat: 32.814728, postalCode: "75221" },
    { lng: -96.6967, lat: 32.267846, postalCode: "75101" },
    { lng: -101.18632, lat: 31.148007, postalCode: "76930" },
    { lng: -95.5021, lat: 30.17873, postalCode: "77381" },
    { lng: -94.389476, lat: 32.522659, postalCode: "75671" },
    { lng: -94.800238, lat: 29.330501, postalCode: "77555" },
    { lng: -93.94399, lat: 31.234368, postalCode: "75968" },
    { lng: -95.785482, lat: 32.223828, postalCode: "75752" },
    { lng: -96.32641, lat: 30.614647, postalCode: "77840" },
    { lng: -96.59314, lat: 29.699797, postalCode: "78943" },
    { lng: -102.60242, lat: 34.39174, postalCode: "79053" },
    { lng: -97.5888, lat: 28.53232, postalCode: "78107" },
    { lng: -97.88196, lat: 32.001365, postalCode: "76649" },
    { lng: -97.203166, lat: 31.553646, postalCode: "76797" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76199" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76121" },
    { lng: -96.81992, lat: 32.78643, postalCode: "75207" },
    { lng: -101.357838, lat: 34.965329, postalCode: "79094" },
    { lng: -95.24475, lat: 29.41148, postalCode: "77511" },
    { lng: -97.3935, lat: 30.460373, postalCode: "78615" },
    { lng: -95.177587, lat: 29.676829, postalCode: "77052" },
    { lng: -97.02761, lat: 32.307456, postalCode: "76064" },
    { lng: -97.542263, lat: 26.190402, postalCode: "78568" },
    { lng: -96.16671, lat: 33.43515, postalCode: "75413" },
    { lng: -97.25024, lat: 26.080434, postalCode: "78578" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75394" },
    { lng: -98.46279, lat: 29.428132, postalCode: "78202" },
    { lng: -95.2609, lat: 33.670159, postalCode: "75436" },
    { lng: -102.852, lat: 30.877528, postalCode: "79735" },
    { lng: -95.50309, lat: 33.801663, postalCode: "75473" },
    { lng: -98.071902, lat: 28.421814, postalCode: "78350" },
    { lng: -96.7649, lat: 33.621928, postalCode: "76268" },
    { lng: -98.67004, lat: 29.466061, postalCode: "78251" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75258" },
    { lng: -95.44541, lat: 29.16866, postalCode: "77515" },
    { lng: -97.37808, lat: 32.699565, postalCode: "76109" },
    { lng: -95.26043, lat: 29.760794, postalCode: "77029" },
    { lng: -102.362709, lat: 33.593213, postalCode: "79338" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77843" },
    { lng: -102.542944, lat: 31.869142, postalCode: "79768" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76161" },
    { lng: -99.21863, lat: 26.9052, postalCode: "78076" },
    { lng: -96.52727, lat: 28.853152, postalCode: "77971" },
    { lng: -99.29649, lat: 34.142094, postalCode: "76384" },
    { lng: -99.09213, lat: 31.77419, postalCode: "76804" },
    { lng: -98.499077, lat: 33.931024, postalCode: "76304" },
    { lng: -96.16474, lat: 29.776375, postalCode: "77474" },
    { lng: -95.158517, lat: 29.612816, postalCode: "77209" },
    { lng: -96.78185, lat: 30.186858, postalCode: "78946" },
    { lng: -94.94455, lat: 29.221289, postalCode: "77554" },
    { lng: -99.62583, lat: 31.230813, postalCode: "76858" },
    { lng: -101.72921, lat: 34.541042, postalCode: "79088" },
    { lng: -97.493616, lat: 31.049621, postalCode: "76533" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78289" },
    { lng: -101.86923, lat: 33.496603, postalCode: "79423" },
    { lng: -99.99968, lat: 29.664428, postalCode: "78833" },
    { lng: -95.43586, lat: 29.852975, postalCode: "77091" },
    { lng: -95.4737, lat: 31.398448, postalCode: "75849" },
    { lng: -99.33534, lat: 29.334701, postalCode: "78850" },
    { lng: -95.58071, lat: 29.011241, postalCode: "77422" },
    { lng: -95.59527, lat: 30.78128, postalCode: "77344" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76182" },
    { lng: -97.357099, lat: 31.085833, postalCode: "76540" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79171" },
    { lng: -100.592015, lat: 31.250101, postalCode: "76939" },
    { lng: -101.84543, lat: 35.197852, postalCode: "79102" },
    { lng: -101.09236, lat: 33.943005, postalCode: "79231" },
    { lng: -95.3567, lat: 33.423626, postalCode: "75434" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77287" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77222" },
    { lng: -99.60862, lat: 32.80699, postalCode: "79533" },
    { lng: -97.46214, lat: 27.593816, postalCode: "78347" },
    { lng: -98.224206, lat: 26.409709, postalCode: "78573" },
    { lng: -99.17492, lat: 31.314477, postalCode: "76872" },
    { lng: -100.27501, lat: 35.227015, postalCode: "79079" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75015" },
    { lng: -99.62754, lat: 29.297821, postalCode: "78870" },
    { lng: -97.94668, lat: 29.876944, postalCode: "78666" },
    { lng: -98.087157, lat: 29.738502, postalCode: "78135" },
    { lng: -101.88353, lat: 35.204652, postalCode: "79106" },
    { lng: -94.848063, lat: 32.570051, postalCode: "75608" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78785" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75047" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75106" },
    { lng: -95.90131, lat: 31.27575, postalCode: "75833" },
    { lng: -93.68535, lat: 30.680344, postalCode: "75928" },
    { lng: -95.64632, lat: 32.132651, postalCode: "75770" },
    { lng: -97.89968, lat: 33.009411, postalCode: "76487" },
    { lng: -94.16169, lat: 31.514959, postalCode: "75972" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78280" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79180" },
    { lng: -96.44129, lat: 32.867103, postalCode: "75032" },
    { lng: -95.10105, lat: 32.137225, postalCode: "75789" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79490" },
    { lng: -103.39895, lat: 31.461562, postalCode: "79719" },
    { lng: -101.54716, lat: 35.71177, postalCode: "79078" },
    { lng: -98.48114, lat: 29.438032, postalCode: "78215" },
    { lng: -96.98524, lat: 32.872386, postalCode: "75038" },
    { lng: -104.27058, lat: 29.597409, postalCode: "79845" },
    { lng: -102.42315, lat: 31.817344, postalCode: "79763" },
    { lng: -97.623, lat: 30.450122, postalCode: "78660" },
    { lng: -96.81813, lat: 31.416468, postalCode: "76675" },
    { lng: -93.903108, lat: 30.054793, postalCode: "77670" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78783" },
    { lng: -95.09184, lat: 31.369811, postalCode: "75858" },
    { lng: -98.38142, lat: 27.683506, postalCode: "78384" },
    { lng: -95.47393, lat: 31.315837, postalCode: "75835" },
    { lng: -96.39598, lat: 33.494285, postalCode: "75491" },
    { lng: -94.40497, lat: 30.819095, postalCode: "75942" },
    { lng: -98.18619, lat: 26.201284, postalCode: "78577" },
    { lng: -98.07786, lat: 29.072412, postalCode: "78147" },
    { lng: -97.0269, lat: 29.930184, postalCode: "78963" },
    { lng: -95.42821, lat: 29.717529, postalCode: "77005" },
    { lng: -101.24517, lat: 30.620199, postalCode: "76943" },
    { lng: -98.57166, lat: 29.499454, postalCode: "78229" },
    { lng: -97.18458, lat: 30.611416, postalCode: "76577" },
    { lng: -94.055214, lat: 33.424125, postalCode: "75502" },
    { lng: -94.895098, lat: 32.386976, postalCode: "75663" },
    { lng: -98.91084, lat: 29.972473, postalCode: "78013" },
    { lng: -94.98779, lat: 29.504389, postalCode: "77518" },
    { lng: -98.38244, lat: 33.165414, postalCode: "76427" },
    { lng: -96.88253, lat: 32.736931, postalCode: "75211" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79189" },
    { lng: -97.30991, lat: 30.120443, postalCode: "78602" },
    { lng: -100.93381, lat: 31.330315, postalCode: "76941" },
    { lng: -94.129733, lat: 29.847569, postalCode: "77641" },
    { lng: -98.70173, lat: 29.335577, postalCode: "78252" },
    { lng: -96.3324, lat: 33.435618, postalCode: "75490" },
    { lng: -95.26785, lat: 29.951621, postalCode: "77396" },
    { lng: -98.60091, lat: 33.081863, postalCode: "76450" },
    { lng: -99.19045, lat: 29.143045, postalCode: "78886" },
    { lng: -96.41788, lat: 33.079946, postalCode: "75121" },
    { lng: -96.694818, lat: 28.525453, postalCode: "77972" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77212" },
    { lng: -97.32002, lat: 32.730265, postalCode: "76104" },
    { lng: -96.86701, lat: 32.244026, postalCode: "76041" },
    { lng: -100.31228, lat: 32.106949, postalCode: "79506" },
    { lng: -94.08984, lat: 33.414708, postalCode: "75501" },
    { lng: -101.41617, lat: 35.062127, postalCode: "79019" },
    { lng: -96.952091, lat: 29.444297, postalCode: "77965" },
    { lng: -97.45739, lat: 29.698718, postalCode: "78632" },
    { lng: -98.54064, lat: 33.859798, postalCode: "76308" },
    { lng: -100.928864, lat: 29.346518, postalCode: "78841" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77262" },
    { lng: -101.85846, lat: 33.549135, postalCode: "79412" },
    { lng: -94.17675, lat: 31.242362, postalCode: "75929" },
    { lng: -96.22474, lat: 32.120271, postalCode: "75144" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77204" },
    { lng: -97.492017, lat: 31.036287, postalCode: "76547" },
    { lng: -97.76277, lat: 30.290907, postalCode: "78703" },
    { lng: -94.70376, lat: 29.843158, postalCode: "77597" },
    { lng: -100.35199, lat: 33.652122, postalCode: "79236" },
    { lng: -98.97431, lat: 27.467237, postalCode: "78371" },
    { lng: -94.31256, lat: 32.145212, postalCode: "75633" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77248" },
    { lng: -96.85882, lat: 32.599286, postalCode: "75115" },
    { lng: -95.37798, lat: 29.825176, postalCode: "77022" },
    { lng: -95.22276, lat: 32.40331, postalCode: "75708" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78755" },
    { lng: -98.4568, lat: 29.48618, postalCode: "78209" },
    { lng: -98.48916, lat: 29.072689, postalCode: "78050" },
    { lng: -100.41072, lat: 31.088429, postalCode: "76935" },
    { lng: -94.5321, lat: 31.226939, postalCode: "75949" },
    { lng: -95.661964, lat: 31.794191, postalCode: "75779" },
    { lng: -100.98464, lat: 33.787306, postalCode: "79243" },
    { lng: -96.59106, lat: 33.632806, postalCode: "75090" },
    { lng: -95.50703, lat: 30.33146, postalCode: "77304" },
    { lng: -95.25478, lat: 29.687829, postalCode: "77017" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76096" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78268" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75350" },
    { lng: -97.93959, lat: 32.387648, postalCode: "76476" },
    { lng: -96.50081, lat: 29.975083, postalCode: "78944" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78761" },
    { lng: -94.76295, lat: 31.339891, postalCode: "75904" },
    { lng: -99.84811, lat: 33.565259, postalCode: "79505" },
    { lng: -97.84756, lat: 29.996916, postalCode: "78640" },
    { lng: -99.481919, lat: 27.363738, postalCode: "78044" },
    { lng: -99.06605, lat: 29.718152, postalCode: "78003" },
    { lng: -98.486871, lat: 29.55302, postalCode: "78126" },
    { lng: -94.70513, lat: 32.809766, postalCode: "75683" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78285" },
    { lng: -97.94159, lat: 33.817816, postalCode: "76261" },
    { lng: -96.98672, lat: 32.484094, postalCode: "76065" },
    { lng: -95.81202, lat: 33.60355, postalCode: "75470" },
    { lng: -97.579207, lat: 27.740225, postalCode: "78472" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77377" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77207" },
    { lng: -96.83938, lat: 33.143792, postalCode: "75034" },
    { lng: -97.0146, lat: 28.737085, postalCode: "77905" },
    { lng: -96.535191, lat: 32.922499, postalCode: "75245" },
    { lng: -95.64978, lat: 29.691714, postalCode: "77083" },
    { lng: -95.306528, lat: 32.347549, postalCode: "75710" },
    { lng: -97.957483, lat: 32.632495, postalCode: "76439" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79168" },
    { lng: -98.936021, lat: 31.763882, postalCode: "76803" },
    { lng: -96.51529, lat: 31.510726, postalCode: "76642" },
    { lng: -94.34908, lat: 32.768011, postalCode: "75657" },
    { lng: -95.13088, lat: 32.476905, postalCode: "75792" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75085" },
    { lng: -95.59527, lat: 30.78128, postalCode: "77348" },
    { lng: -96.08977, lat: 33.082411, postalCode: "75402" },
    { lng: -97.463213, lat: 27.777, postalCode: "78427" },
    { lng: -96.78177, lat: 33.130086, postalCode: "75035" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75388" },
    { lng: -97.02346, lat: 32.675604, postalCode: "75052" },
    { lng: -102.19598, lat: 31.156902, postalCode: "79752" },
    { lng: -97.60044, lat: 29.269305, postalCode: "78159" },
    { lng: -95.82011, lat: 29.810279, postalCode: "77493" },
    { lng: -96.86945, lat: 32.78238, postalCode: "75212" },
    { lng: -99.8165, lat: 29.252882, postalCode: "78801" },
    { lng: -97.602552, lat: 30.656817, postalCode: "78680" },
    { lng: -96.561846, lat: 32.740332, postalCode: "75185" },
    { lng: -94.87815, lat: 31.436494, postalCode: "75969" },
    { lng: -96.24043, lat: 31.222211, postalCode: "77865" },
    { lng: -94.07429, lat: 32.950857, postalCode: "75565" },
    { lng: -101.88881, lat: 33.547735, postalCode: "79413" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77299" },
    { lng: -96.83864, lat: 32.751464, postalCode: "75208" },
    { lng: -97.85828, lat: 30.216108, postalCode: "78749" },
    { lng: -101.895894, lat: 34.96539, postalCode: "79016" },
    { lng: -93.859903, lat: 30.063101, postalCode: "77639" },
    { lng: -96.88018, lat: 32.175783, postalCode: "76651" },
    { lng: -98.71951, lat: 28.795637, postalCode: "78011" },
    { lng: -98.552942, lat: 28.919999, postalCode: "78062" },
    { lng: -101.88156, lat: 33.857895, postalCode: "79311" },
    { lng: -99.58416, lat: 28.883474, postalCode: "78829" },
    { lng: -97.77433, lat: 26.870238, postalCode: "78338" },
    { lng: -94.363, lat: 31.154673, postalCode: "75980" },
    { lng: -101.98329, lat: 33.567134, postalCode: "79407" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78269" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76113" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77235" },
    { lng: -98.012331, lat: 27.659473, postalCode: "78333" },
    { lng: -102.031726, lat: 31.869259, postalCode: "79711" },
    { lng: -98.80845, lat: 28.52856, postalCode: "78021" },
    { lng: -95.42619, lat: 29.825476, postalCode: "77018" },
    { lng: -96.7904, lat: 32.862876, postalCode: "75225" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79170" },
    { lng: -97.16036, lat: 30.010487, postalCode: "78957" },
    { lng: -94.50853, lat: 33.157897, postalCode: "75566" },
    { lng: -96.09055, lat: 29.307347, postalCode: "77488" },
    { lng: -95.820719, lat: 29.173701, postalCode: "77431" },
    { lng: -97.77217, lat: 33.446897, postalCode: "76270" },
    { lng: -98.90353, lat: 29.54825, postalCode: "78056" },
    { lng: -97.84178, lat: 29.838163, postalCode: "78655" },
    { lng: -97.11807, lat: 31.514267, postalCode: "76706" },
    { lng: -94.84359, lat: 33.236743, postalCode: "75558" },
    { lng: -98.13294, lat: 30.022492, postalCode: "78676" },
    { lng: -94.58376, lat: 30.942336, postalCode: "75936" },
    { lng: -98.92106, lat: 33.445341, postalCode: "76370" },
    { lng: -101.14146, lat: 33.04993, postalCode: "79330" },
    { lng: -100.16168, lat: 36.397469, postalCode: "79034" },
    { lng: -94.12531, lat: 33.441774, postalCode: "75569" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75390" },
    { lng: -101.81946, lat: 35.228302, postalCode: "79107" },
    { lng: -97.734728, lat: 31.470598, postalCode: "76599" },
    { lng: -101.73528, lat: 32.520297, postalCode: "79713" },
    { lng: -95.02996, lat: 29.541285, postalCode: "77565" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75363" },
    { lng: -96.51907, lat: 33.356094, postalCode: "75409" },
    { lng: -96.65901, lat: 32.993405, postalCode: "75082" },
    { lng: -97.44503, lat: 27.729855, postalCode: "78417" },
    { lng: -94.2648, lat: 33.480224, postalCode: "75561" },
    { lng: -95.91804, lat: 29.685917, postalCode: "77441" },
    { lng: -100.44105, lat: 35.534558, postalCode: "79061" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77277" },
    { lng: -93.92694, lat: 29.921564, postalCode: "77642" },
    { lng: -98.75976, lat: 29.461353, postalCode: "78253" },
    { lng: -96.6909, lat: 30.141287, postalCode: "78932" },
    { lng: -97.932083, lat: 32.341365, postalCode: "76467" },
    { lng: -97.75687, lat: 30.494623, postalCode: "78717" },
    { lng: -99.52617, lat: 30.847791, postalCode: "76848" },
    { lng: -97.485039, lat: 30.916863, postalCode: "78571" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78286" },
    { lng: -94.2076, lat: 30.564151, postalCode: "77616" },
    { lng: -97.47211, lat: 25.969007, postalCode: "78526" },
    { lng: -96.80817, lat: 31.84121, postalCode: "76648" },
    { lng: -96.974119, lat: 28.794935, postalCode: "77903" },
    { lng: -102.84661, lat: 32.976623, postalCode: "79323" },
    { lng: -98.01942, lat: 32.95802, postalCode: "76490" },
    { lng: -98.40889, lat: 29.581304, postalCode: "78247" },
    { lng: -102.031726, lat: 31.869259, postalCode: "79710" },
    { lng: -95.28687, lat: 29.896156, postalCode: "77050" },
    { lng: -97.42738, lat: 26.558995, postalCode: "78598" },
    { lng: -95.70925, lat: 30.180626, postalCode: "77353" },
    { lng: -98.73083, lat: 27.17199, postalCode: "78361" },
    { lng: -95.44138, lat: 29.917814, postalCode: "77038" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78296" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77297" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77391" },
    { lng: -101.80606, lat: 35.100501, postalCode: "79118" },
    { lng: -95.60594, lat: 33.86751, postalCode: "75411" },
    { lng: -99.15152, lat: 30.042529, postalCode: "78028" },
    { lng: -95.61882, lat: 30.073775, postalCode: "77375" },
    { lng: -95.49131, lat: 29.745129, postalCode: "77057" },
    { lng: -99.63839, lat: 33.463284, postalCode: "76371" },
    { lng: -98.31978, lat: 29.358153, postalCode: "78263" },
    { lng: -99.90025, lat: 32.230821, postalCode: "79562" },
    { lng: -97.108546, lat: 33.225523, postalCode: "76202" },
    { lng: -98.95072, lat: 30.776619, postalCode: "76831" },
    { lng: -95.76203, lat: 33.734091, postalCode: "75486" },
    { lng: -97.40221, lat: 28.685027, postalCode: "77963" },
    { lng: -100.6961, lat: 35.724847, postalCode: "79059" },
    { lng: -99.41669, lat: 34.345924, postalCode: "79247" },
    { lng: -97.35499, lat: 31.975949, postalCode: "76692" },
    { lng: -95.332598, lat: 29.744341, postalCode: "77292" },
    { lng: -99.76064, lat: 28.442081, postalCode: "78827" },
    { lng: -100.68959, lat: 31.39577, postalCode: "76908" },
    { lng: -98.03379, lat: 32.517753, postalCode: "76462" },
    { lng: -98.56442, lat: 27.363706, postalCode: "78376" },
    { lng: -100.17202, lat: 30.065334, postalCode: "78880" },
    { lng: -97.494649, lat: 29.447211, postalCode: "78677" },
    { lng: -94.856052, lat: 30.856814, postalCode: "77350" },
    { lng: -97.82511, lat: 27.17914, postalCode: "78385" },
    { lng: -101.30072, lat: 33.941748, postalCode: "79235" },
    { lng: -102.28814, lat: 33.344598, postalCode: "79345" },
    { lng: -96.88598, lat: 28.459757, postalCode: "77990" },
    { lng: -97.0963, lat: 32.632349, postalCode: "76002" },
    { lng: -102.78117, lat: 33.895013, postalCode: "79324" },
    { lng: -96.0007, lat: 29.447947, postalCode: "77451" },
    { lng: -97.11997, lat: 31.547466, postalCode: "76798" },
    { lng: -98.81271, lat: 27.43254, postalCode: "78344" },
    { lng: -103.69271, lat: 30.349136, postalCode: "79831" },
    { lng: -97.48642, lat: 31.073329, postalCode: "76513" },
    { lng: -95.40319, lat: 29.772627, postalCode: "77007" },
    { lng: -101.81073, lat: 35.184253, postalCode: "79103" },
    { lng: -94.783493, lat: 32.51121, postalCode: "75607" },
    { lng: -96.36015, lat: 30.637348, postalCode: "77801" },
    { lng: -102.08618, lat: 32.029022, postalCode: "79705" },
    { lng: -97.70571, lat: 30.332506, postalCode: "78752" },
    { lng: -97.15014, lat: 32.157903, postalCode: "76055" },
    { lng: -99.08975, lat: 30.92753, postalCode: "76842" },
    { lng: -98.92225, lat: 32.761058, postalCode: "76424" },
    { lng: -97.16178, lat: 31.393062, postalCode: "76655" },
    { lng: -94.239351, lat: 32.55397, postalCode: "75688" },
    { lng: -95.39191, lat: 32.31265, postalCode: "75709" },
    { lng: -99.90601, lat: 31.201871, postalCode: "76837" },
    { lng: -93.867932, lat: 29.962144, postalCode: "77643" },
    { lng: -98.36186, lat: 31.650023, postalCode: "76565" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78292" },
    { lng: -102.67355, lat: 33.861914, postalCode: "79320" },
    { lng: -95.41859, lat: 32.207845, postalCode: "75762" },
    { lng: -95.33395, lat: 29.79437, postalCode: "77026" },
    { lng: -101.95574, lat: 30.075997, postalCode: "78851" },
    { lng: -96.227967, lat: 29.375762, postalCode: "77436" },
    { lng: -97.04389, lat: 31.185062, postalCode: "76656" },
    { lng: -95.63182, lat: 32.793512, postalCode: "75410" },
    { lng: -96.81412, lat: 32.812462, postalCode: "75219" },
    { lng: -94.58468, lat: 29.860106, postalCode: "77560" },
    { lng: -96.80492, lat: 33.03505, postalCode: "75093" },
    { lng: -94.37287, lat: 32.019931, postalCode: "75643" },
    { lng: -98.639793, lat: 26.316452, postalCode: "78587" },
    { lng: -95.138794, lat: 31.105786, postalCode: "75834" },
    { lng: -97.78275, lat: 29.112202, postalCode: "78116" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78265" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78241" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78298" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78789" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75265" },
    { lng: -97.22762, lat: 31.337382, postalCode: "76630" },
    { lng: -97.95028, lat: 30.378675, postalCode: "78734" },
    { lng: -98.459792, lat: 33.897073, postalCode: "76303" },
    { lng: -100.9153, lat: 32.747707, postalCode: "79550" },
    { lng: -97.86808, lat: 27.496472, postalCode: "78363" },
    { lng: -102.25186, lat: 35.718644, postalCode: "79018" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77265" },
    { lng: -97.088337, lat: 33.143186, postalCode: "76210" },
    { lng: -100.76721, lat: 34.896396, postalCode: "79240" },
    { lng: -97.72924, lat: 31.117874, postalCode: "76541" },
    { lng: -94.1773, lat: 33.118611, postalCode: "75551" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77279" },
    { lng: -97.37032, lat: 27.977641, postalCode: "78390" },
    { lng: -98.630919, lat: 31.477095, postalCode: "76880" },
    { lng: -95.46982, lat: 32.674657, postalCode: "75773" },
    { lng: -97.00715, lat: 28.873664, postalCode: "77904" },
    { lng: -97.42739, lat: 25.918758, postalCode: "78521" },
    { lng: -96.62639, lat: 32.920574, postalCode: "75040" },
    { lng: -97.40081, lat: 32.343115, postalCode: "76031" },
    { lng: -94.37958, lat: 32.547424, postalCode: "75670" },
    { lng: -98.03375, lat: 26.305113, postalCode: "78558" },
    { lng: -95.41273, lat: 30.008745, postalCode: "77073" },
    { lng: -98.342798, lat: 32.221433, postalCode: "76461" },
    { lng: -95.53225, lat: 31.627557, postalCode: "75839" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79160" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77245" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79499" },
    { lng: -94.210541, lat: 32.386275, postalCode: "75642" },
    { lng: -97.09988, lat: 32.434322, postalCode: "76084" },
    { lng: -101.07207, lat: 32.358785, postalCode: "79565" },
    { lng: -101.40666, lat: 35.665899, postalCode: "79007" },
    { lng: -98.71911, lat: 30.460464, postalCode: "78675" },
    { lng: -94.8631, lat: 31.817889, postalCode: "75760" },
    { lng: -96.54327, lat: 31.752538, postalCode: "76686" },
    { lng: -99.54774, lat: 31.753561, postalCode: "76884" },
    { lng: -98.42793, lat: 29.33906, postalCode: "78235" },
    { lng: -98.61509, lat: 29.479874, postalCode: "78238" },
    { lng: -95.72219, lat: 29.884175, postalCode: "77433" },
    { lng: -102.14757, lat: 35.459732, postalCode: "79010" },
    { lng: -97.17527, lat: 32.762631, postalCode: "76120" },
    { lng: -98.67235, lat: 29.200169, postalCode: "78069" },
    { lng: -97.43465, lat: 27.752356, postalCode: "78416" },
    { lng: -97.11662, lat: 30.210685, postalCode: "78659" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79491" },
    { lng: -95.82334, lat: 29.401461, postalCode: "77461" },
    { lng: -99.27685, lat: 30.102854, postalCode: "78025" },
    { lng: -95.65815, lat: 33.432974, postalCode: "75441" },
    { lng: -99.70246, lat: 33.147497, postalCode: "79521" },
    { lng: -96.38095, lat: 32.941014, postalCode: "75132" },
    { lng: -97.843336, lat: 30.468583, postalCode: "78713" },
    { lng: -101.803412, lat: 35.196352, postalCode: "79120" },
    { lng: -97.79575, lat: 30.207559, postalCode: "78745" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79175" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75277" },
    { lng: -97.602552, lat: 30.656817, postalCode: "78683" },
    { lng: -99.00718, lat: 26.493058, postalCode: "78584" },
    { lng: -97.646143, lat: 30.673597, postalCode: "78627" },
    { lng: -98.231166, lat: 26.200001, postalCode: "78301" },
    { lng: -99.11818, lat: 34.147838, postalCode: "76373" },
    { lng: -95.81104, lat: 29.760833, postalCode: "77494" },
    { lng: -94.712, lat: 32.474499, postalCode: "75602" },
    { lng: -100.227509, lat: 29.956952, postalCode: "76883" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77275" },
    { lng: -97.644352, lat: 31.351386, postalCode: "76558" },
    { lng: -97.116282, lat: 33.20743, postalCode: "75008" },
    { lng: -99.21676, lat: 28.43544, postalCode: "78014" },
    { lng: -96.44885, lat: 31.787449, postalCode: "76693" },
    { lng: -96.64601, lat: 32.881525, postalCode: "75041" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79185" },
    { lng: -95.993482, lat: 31.313816, postalCode: "77850" },
    { lng: -95.92499, lat: 30.068888, postalCode: "77484" },
    { lng: -98.05788, lat: 33.024726, postalCode: "76486" },
    { lng: -94.80916, lat: 30.903688, postalCode: "75960" },
    { lng: -98.23308, lat: 29.574127, postalCode: "78108" },
    { lng: -97.188437, lat: 27.912454, postalCode: "78335" },
    { lng: -101.22866, lat: 33.653787, postalCode: "79322" },
    { lng: -97.13485, lat: 32.57701, postalCode: "76063" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77240" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76192" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75123" },
    { lng: -101.82634, lat: 33.549785, postalCode: "79404" },
    { lng: -101.76769, lat: 34.356732, postalCode: "79052" },
    { lng: -96.14826, lat: 28.887522, postalCode: "77440" },
    { lng: -99.72107, lat: 32.40769, postalCode: "79602" },
    { lng: -98.96012, lat: 31.292292, postalCode: "76871" },
    { lng: -97.33358, lat: 32.649855, postalCode: "76134" },
    { lng: -101.94086, lat: 32.714521, postalCode: "79331" },
    { lng: -97.46457, lat: 33.41798, postalCode: "76246" },
    { lng: -97.35225, lat: 27.709309, postalCode: "78412" },
    { lng: -101.91065, lat: 33.330983, postalCode: "79383" },
    { lng: -97.112743, lat: 33.23552, postalCode: "76209" },
    { lng: -96.947689, lat: 32.127507, postalCode: "76607" },
    { lng: -98.78317, lat: 31.963873, postalCode: "76474" },
    { lng: -94.783493, lat: 32.51121, postalCode: "75660" },
    { lng: -96.77662, lat: 32.783978, postalCode: "75226" },
    { lng: -97.75321, lat: 26.195591, postalCode: "78552" },
    { lng: -97.13837, lat: 30.851275, postalCode: "76518" },
    { lng: -95.39168, lat: 33.323467, postalCode: "75481" },
    { lng: -101.78641, lat: 35.296948, postalCode: "79108" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77402" },
    { lng: -98.88389, lat: 30.279267, postalCode: "78624" },
    { lng: -100.87132, lat: 34.408268, postalCode: "79261" },
    { lng: -97.7113, lat: 26.39381, postalCode: "78569" },
    { lng: -96.73436, lat: 33.453628, postalCode: "75058" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75266" },
    { lng: -100.956013, lat: 35.533384, postalCode: "79066" },
    { lng: -98.58881, lat: 34.085289, postalCode: "76354" },
    { lng: -97.23563, lat: 30.283941, postalCode: "78650" },
    { lng: -93.61936, lat: 31.067439, postalCode: "75932" },
    { lng: -97.45352, lat: 29.510439, postalCode: "78629" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76004" },
    { lng: -97.396, lat: 30.883556, postalCode: "76534" },
    { lng: -98.52618, lat: 31.460782, postalCode: "76844" },
    { lng: -96.19287, lat: 29.346878, postalCode: "77443" },
    { lng: -101.9212, lat: 34.971029, postalCode: "79015" },
    { lng: -95.52269, lat: 29.734379, postalCode: "77063" },
    { lng: -98.19145, lat: 33.761291, postalCode: "76365" },
    { lng: -98.31176, lat: 26.244013, postalCode: "78574" },
    { lng: -97.35766, lat: 31.09954, postalCode: "76504" },
    { lng: -97.03904, lat: 30.414806, postalCode: "78947" },
    { lng: -97.69331, lat: 28.237359, postalCode: "78389" },
    { lng: -97.19502, lat: 31.453789, postalCode: "76643" },
    { lng: -98.49899, lat: 28.314027, postalCode: "78072" },
    { lng: -99.13392, lat: 26.561861, postalCode: "78545" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75372" },
    { lng: -97.31406, lat: 32.949819, postalCode: "76177" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76099" },
    { lng: -96.83751, lat: 32.96129, postalCode: "75001" },
    { lng: -99.0416, lat: 29.047934, postalCode: "78057" },
    { lng: -97.40774, lat: 32.461964, postalCode: "76058" },
    { lng: -95.02689, lat: 29.468772, postalCode: "77539" },
    { lng: -100.1998, lat: 32.684029, postalCode: "79560" },
    { lng: -97.73856, lat: 30.292424, postalCode: "78705" },
    { lng: -94.42374, lat: 30.031927, postalCode: "77629" },
    { lng: -99.7639, lat: 32.128112, postalCode: "79530" },
    { lng: -96.937574, lat: 31.254242, postalCode: "76677" },
    { lng: -96.80502, lat: 32.77988, postalCode: "75202" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76164" },
    { lng: -97.3275, lat: 32.39286, postalCode: "76059" },
    { lng: -102.21997, lat: 34.92765, postalCode: "79025" },
    { lng: -93.74869, lat: 30.831678, postalCode: "75966" },
    { lng: -96.7812, lat: 32.620385, postalCode: "75134" },
    { lng: -100.51097, lat: 36.427031, postalCode: "79005" },
    { lng: -103.63643, lat: 29.441286, postalCode: "79852" },
    { lng: -102.39894, lat: 30.144953, postalCode: "79848" },
    { lng: -99.890737, lat: 32.302132, postalCode: "79697" },
    { lng: -97.79801, lat: 27.807025, postalCode: "78339" },
    { lng: -95.16945, lat: 30.062436, postalCode: "77345" },
    { lng: -95.44103, lat: 29.036879, postalCode: "77566" },
    { lng: -97.74806, lat: 28.426202, postalCode: "78102" },
    { lng: -97.71427, lat: 28.878902, postalCode: "78151" },
    { lng: -96.79941, lat: 32.787629, postalCode: "75201" },
    { lng: -97.30384, lat: 33.08704, postalCode: "76247" },
    { lng: -95.46434, lat: 31.104197, postalCode: "75851" },
    { lng: -95.300142, lat: 31.800846, postalCode: "75772" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77234" },
    { lng: -101.87525, lat: 33.583798, postalCode: "79406" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77290" },
    { lng: -96.96376, lat: 30.784169, postalCode: "76555" },
    { lng: -95.46931, lat: 29.747328, postalCode: "77056" },
    { lng: -96.98656, lat: 32.967341, postalCode: "75019" },
    { lng: -93.90706, lat: 31.735326, postalCode: "75973" },
    { lng: -99.50497, lat: 29.361493, postalCode: "78881" },
    { lng: -101.92914, lat: 35.173704, postalCode: "79121" },
    { lng: -100.89669, lat: 33.478848, postalCode: "79370" },
    { lng: -98.63462, lat: 33.574426, postalCode: "76351" },
    { lng: -98.80286, lat: 31.82741, postalCode: "76432" },
    { lng: -98.154881, lat: 26.319427, postalCode: "78541" },
    { lng: -97.09721, lat: 32.826172, postalCode: "76040" },
    { lng: -95.22251, lat: 29.589831, postalCode: "77089" },
    { lng: -98.68492, lat: 33.958681, postalCode: "76367" },
    { lng: -98.2173, lat: 28.759107, postalCode: "78008" },
    { lng: -96.4698, lat: 30.490162, postalCode: "77878" },
    { lng: -95.49667, lat: 32.251068, postalCode: "75758" },
    { lng: -97.95012, lat: 28.956196, postalCode: "78117" },
    { lng: -97.07988, lat: 29.847433, postalCode: "78949" },
    { lng: -101.373968, lat: 32.27328, postalCode: "79721" },
    { lng: -100.4387, lat: 31.468911, postalCode: "76903" },
    { lng: -97.91666, lat: 31.131076, postalCode: "76522" },
    { lng: -97.26563, lat: 32.745681, postalCode: "76103" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75312" },
    { lng: -97.8022, lat: 30.287739, postalCode: "78746" },
    { lng: -96.794605, lat: 29.487564, postalCode: "77986" },
    { lng: -95.59527, lat: 30.78128, postalCode: "77343" },
    { lng: -100.55397, lat: 32.422985, postalCode: "79545" },
    { lng: -95.49629, lat: 29.798877, postalCode: "77055" },
    { lng: -95.058153, lat: 29.511582, postalCode: "77574" },
    { lng: -94.94389, lat: 29.74877, postalCode: "77520" },
    { lng: -94.16377, lat: 30.092679, postalCode: "77706" },
    { lng: -97.470456, lat: 30.805512, postalCode: "76573" },
    { lng: -97.65485, lat: 32.259829, postalCode: "76070" },
    { lng: -97.77727, lat: 29.760052, postalCode: "78622" },
    { lng: -97.23546, lat: 31.514067, postalCode: "76712" },
    { lng: -96.68102, lat: 33.028921, postalCode: "75074" },
    { lng: -101.51475, lat: 31.807661, postalCode: "79739" },
    { lng: -98.35727, lat: 32.908714, postalCode: "76449" },
    { lng: -94.99963, lat: 30.488028, postalCode: "77371" },
    { lng: -103.56444, lat: 30.011559, postalCode: "79830" },
    { lng: -97.443751, lat: 30.335787, postalCode: "78714" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77253" },
    { lng: -103.00689, lat: 31.569032, postalCode: "79788" },
    { lng: -95.289903, lat: 32.411237, postalCode: "75798" },
    { lng: -96.65684, lat: 32.533551, postalCode: "75125" },
    { lng: -99.69191, lat: 30.070414, postalCode: "78058" },
    { lng: -95.64842, lat: 29.896656, postalCode: "77095" },
    { lng: -101.89695, lat: 34.043076, postalCode: "79041" },
    { lng: -100.76416, lat: 32.627475, postalCode: "79526" },
    { lng: -98.17929, lat: 29.150937, postalCode: "78114" },
    { lng: -94.955432, lat: 32.151755, postalCode: "75687" },
    { lng: -99.85032, lat: 28.950547, postalCode: "78872" },
    { lng: -97.68169, lat: 27.973465, postalCode: "78352" },
    { lng: -101.892846, lat: 35.837775, postalCode: "79058" },
    { lng: -94.81589, lat: 33.035716, postalCode: "75636" },
    { lng: -101.69479, lat: 33.531908, postalCode: "79366" },
    { lng: -95.9653, lat: 28.67494, postalCode: "77457" },
    { lng: -95.33761, lat: 29.633081, postalCode: "77048" },
    { lng: -97.6533, lat: 29.694257, postalCode: "78648" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75294" },
    { lng: -96.17433, lat: 31.346581, postalCode: "75846" },
    { lng: -94.783493, lat: 32.51121, postalCode: "75615" },
    { lng: -98.18119, lat: 34.06722, postalCode: "76357" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79452" },
    { lng: -95.71669, lat: 30.984335, postalCode: "75852" },
    { lng: -97.559807, lat: 30.335398, postalCode: "78763" },
    { lng: -96.79054, lat: 32.901176, postalCode: "75230" },
    { lng: -97.40095, lat: 31.416926, postalCode: "76657" },
    { lng: -95.40951, lat: 29.798777, postalCode: "77008" },
    { lng: -98.12184, lat: 27.368433, postalCode: "78375" },
    { lng: -97.365742, lat: 29.098894, postalCode: "77967" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79181" },
    { lng: -97.90134, lat: 27.782267, postalCode: "78330" },
    { lng: -97.5324, lat: 32.011671, postalCode: "76671" },
    { lng: -95.61255, lat: 29.750897, postalCode: "77077" },
    { lng: -93.81112, lat: 30.580648, postalCode: "75933" },
    { lng: -97.74157, lat: 26.245051, postalCode: "78535" },
    { lng: -96.67399, lat: 32.915625, postalCode: "75042" },
    { lng: -96.74764, lat: 32.874317, postalCode: "75231" },
    { lng: -94.1811, lat: 32.285877, postalCode: "75639" },
    { lng: -94.82683, lat: 29.279937, postalCode: "77551" },
    { lng: -97.116282, lat: 33.20743, postalCode: "76204" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77267" },
    { lng: -100.81731, lat: 33.915528, postalCode: "79256" },
    { lng: -95.67556, lat: 30.152265, postalCode: "77362" },
    { lng: -97.52595, lat: 32.442679, postalCode: "76044" },
    { lng: -95.71344, lat: 29.055954, postalCode: "77480" },
    { lng: -94.83674, lat: 30.453845, postalCode: "77368" },
    { lng: -98.49649, lat: 29.460381, postalCode: "78212" },
    { lng: -95.28488, lat: 30.005691, postalCode: "77338" },
    { lng: -95.5869, lat: 29.668489, postalCode: "77099" },
    { lng: -96.291572, lat: 33.242656, postalCode: "75458" },
    { lng: -98.51923, lat: 33.942496, postalCode: "76306" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75398" },
    { lng: -97.85626, lat: 28.779344, postalCode: "78119" },
    { lng: -98.98395, lat: 30.909853, postalCode: "76869" },
    { lng: -96.70164, lat: 31.881324, postalCode: "76639" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75286" },
    { lng: -98.74504, lat: 29.523294, postalCode: "78254" },
    { lng: -101.83924, lat: 35.206402, postalCode: "79101" },
    { lng: -98.012331, lat: 27.659473, postalCode: "78342" },
    { lng: -95.43474, lat: 29.69023, postalCode: "77025" },
    { lng: -98.404816, lat: 32.38253, postalCode: "76345" },
    { lng: -97.35508, lat: 30.985136, postalCode: "76554" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77228" },
    { lng: -96.74504, lat: 33.086868, postalCode: "75025" },
    { lng: -97.24859, lat: 33.270036, postalCode: "76249" },
    { lng: -95.6529, lat: 30.412229, postalCode: "77356" },
    { lng: -99.90554, lat: 33.196822, postalCode: "79547" },
    { lng: -94.64906, lat: 30.378705, postalCode: "77374" },
    { lng: -98.36161, lat: 29.51613, postalCode: "78239" },
    { lng: -95.60547, lat: 29.927675, postalCode: "77065" },
    { lng: -97.720569, lat: 26.244651, postalCode: "78551" },
    { lng: -95.47644, lat: 29.833326, postalCode: "77092" },
    { lng: -97.13546, lat: 32.691393, postalCode: "76015" },
    { lng: -96.79963, lat: 32.77103, postalCode: "75099" },
    { lng: -100.10582, lat: 32.109191, postalCode: "79566" },
    { lng: -98.72878, lat: 29.288108, postalCode: "78002" },
    { lng: -97.72602, lat: 31.43211, postalCode: "76528" },
    { lng: -96.78952, lat: 32.800333, postalCode: "75204" },
    { lng: -97.59099, lat: 33.068301, postalCode: "76023" },
    { lng: -96.85437, lat: 30.3555, postalCode: "77853" },
    { lng: -98.224206, lat: 26.409709, postalCode: "78599" },
    { lng: -96.187, lat: 32.727366, postalCode: "75161" },
    { lng: -95.33683, lat: 29.909123, postalCode: "77039" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77201" },
    { lng: -95.579561, lat: 31.926836, postalCode: "75802" },
    { lng: -98.09302, lat: 27.737965, postalCode: "78332" },
    { lng: -97.5869, lat: 29.406434, postalCode: "78614" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75285" },
    { lng: -97.203166, lat: 31.553646, postalCode: "76716" },
    { lng: -102.74709, lat: 31.840191, postalCode: "79759" },
    { lng: -97.473, lat: 33.072675, postalCode: "76078" },
    { lng: -99.74194, lat: 33.929907, postalCode: "79227" },
    { lng: -96.96323, lat: 30.851922, postalCode: "76520" },
    { lng: -102.35906, lat: 31.854455, postalCode: "79761" },
    { lng: -98.23579, lat: 26.213105, postalCode: "78501" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77218" },
    { lng: -96.1716, lat: 29.238931, postalCode: "77467" },
    { lng: -94.68667, lat: 31.330567, postalCode: "75901" },
    { lng: -97.59435, lat: 27.846568, postalCode: "78410" },
    { lng: -100.06623, lat: 34.698438, postalCode: "79230" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75046" },
    { lng: -102.071415, lat: 35.216029, postalCode: "79159" },
    { lng: -95.08552, lat: 29.371171, postalCode: "77510" },
    { lng: -98.45014, lat: 26.24901, postalCode: "78576" },
    { lng: -97.69158, lat: 33.360331, postalCode: "76225" },
    { lng: -99.27519, lat: 32.742591, postalCode: "76430" },
    { lng: -96.61634, lat: 32.72203, postalCode: "75180" },
    { lng: -98.30856, lat: 29.641132, postalCode: "78266" },
    { lng: -95.57655, lat: 29.978801, postalCode: "77070" },
    { lng: -98.14606, lat: 29.570379, postalCode: "78124" },
    { lng: -97.90249, lat: 31.629515, postalCode: "76538" },
    { lng: -98.29797, lat: 27.316167, postalCode: "78349" },
    { lng: -98.83268, lat: 32.236449, postalCode: "76435" },
    { lng: -96.31152, lat: 33.065217, postalCode: "75164" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77255" },
    { lng: -95.36735, lat: 29.79326, postalCode: "77009" },
    { lng: -99.81595, lat: 32.943145, postalCode: "79553" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77293" },
    { lng: -99.890737, lat: 32.302132, postalCode: "79608" },
    { lng: -95.6988, lat: 29.770722, postalCode: "77094" },
    { lng: -99.82808, lat: 32.280068, postalCode: "79508" },
    { lng: -97.225324, lat: 32.741685, postalCode: "76003" },
    { lng: -94.932215, lat: 32.25416, postalCode: "75682" },
    { lng: -95.50776, lat: 30.680641, postalCode: "77340" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78283" },
    { lng: -94.129733, lat: 29.847569, postalCode: "77720" },
    { lng: -96.91939, lat: 30.070257, postalCode: "78960" },
    { lng: -95.20216, lat: 29.705678, postalCode: "77506" },
    { lng: -96.2316, lat: 28.872088, postalCode: "77419" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75374" },
    { lng: -98.48931, lat: 29.425932, postalCode: "78205" },
    { lng: -100.65431, lat: 31.603027, postalCode: "76934" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77284" },
    { lng: -100.207642, lat: 34.529678, postalCode: "79222" },
    { lng: -94.869052, lat: 30.817866, postalCode: "77399" },
    { lng: -97.659533, lat: 30.445502, postalCode: "78764" },
    { lng: -97.44409, lat: 32.72228, postalCode: "76116" },
    { lng: -97.463213, lat: 27.777, postalCode: "78469" },
    { lng: -97.37051, lat: 27.672334, postalCode: "78414" },
    { lng: -95.98452, lat: 29.80038, postalCode: "77423" },
    { lng: -98.23082, lat: 26.26273, postalCode: "78504" },
    { lng: -94.69722, lat: 32.920957, postalCode: "75668" },
    { lng: -94.616062, lat: 32.369393, postalCode: "75606" },
    { lng: -102.12132, lat: 34.423414, postalCode: "79043" },
    { lng: -95.82974, lat: 31.930882, postalCode: "75853" },
    { lng: -95.15272, lat: 31.893439, postalCode: "75764" },
    { lng: -97.59923, lat: 30.808903, postalCode: "76537" },
    { lng: -97.20956, lat: 32.408183, postalCode: "76009" },
    { lng: -100.60332, lat: 28.917675, postalCode: "78877" },
    { lng: -98.21058, lat: 32.242816, postalCode: "76401" },
    { lng: -94.90988, lat: 31.654777, postalCode: "75943" },
    { lng: -93.99031, lat: 31.109064, postalCode: "75931" },
    { lng: -94.40604, lat: 30.600866, postalCode: "77664" },
    { lng: -99.82211, lat: 32.616906, postalCode: "79525" },
    { lng: -96.94949, lat: 32.588536, postalCode: "75104" },
    { lng: -93.77408, lat: 30.089757, postalCode: "77630" },
    { lng: -101.49483, lat: 36.27128, postalCode: "79040" },
    { lng: -96.00238, lat: 32.725386, postalCode: "75169" },
    { lng: -97.463213, lat: 27.777, postalCode: "78476" },
    { lng: -97.22264, lat: 32.80533, postalCode: "76118" },
    { lng: -96.9725, lat: 31.978157, postalCode: "76631" },
    { lng: -98.17418, lat: 31.917085, postalCode: "76436" },
    { lng: -98.21578, lat: 26.633158, postalCode: "78563" },
    { lng: -100.1911, lat: 31.58901, postalCode: "76861" },
    { lng: -95.18813, lat: 29.64878, postalCode: "77504" },
    { lng: -96.40324, lat: 29.095463, postalCode: "77455" },
    { lng: -95.193, lat: 33.462828, postalCode: "75417" },
    { lng: -101.76599, lat: 36.057372, postalCode: "79086" },
    { lng: -96.83886, lat: 32.664034, postalCode: "75232" },
    { lng: -100.76388, lat: 34.637427, postalCode: "79239" },
    { lng: -96.89172, lat: 28.642875, postalCode: "77951" },
    { lng: -96.58661, lat: 32.975723, postalCode: "75048" },
    { lng: -101.5205, lat: 33.605628, postalCode: "79343" },
    { lng: -97.96961, lat: 26.344688, postalCode: "78538" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75011" },
    { lng: -101.81691, lat: 32.09023, postalCode: "79782" },
    { lng: -97.20652, lat: 33.62109, postalCode: "76250" },
    { lng: -99.89507, lat: 32.754555, postalCode: "79501" },
    { lng: -98.53946, lat: 29.574677, postalCode: "78231" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76178" },
    { lng: -96.45594, lat: 29.698797, postalCode: "78935" },
    { lng: -98.45361, lat: 30.864936, postalCode: "78672" },
    { lng: -96.83283, lat: 32.397024, postalCode: "75165" },
    { lng: -98.38926, lat: 29.379902, postalCode: "78222" },
    { lng: -97.74177, lat: 30.320206, postalCode: "78756" },
    { lng: -95.661964, lat: 31.794191, postalCode: "75886" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77238" },
    { lng: -98.52203, lat: 29.510562, postalCode: "78213" },
    { lng: -98.93407, lat: 29.641551, postalCode: "78063" },
    { lng: -97.49055, lat: 30.130116, postalCode: "78612" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77251" },
    { lng: -102.47675, lat: 31.019602, postalCode: "79740" },
    { lng: -94.63009, lat: 33.206258, postalCode: "75568" },
    { lng: -96.46038, lat: 32.745373, postalCode: "75126" },
    { lng: -96.627131, lat: 32.913695, postalCode: "75045" },
    { lng: -97.771258, lat: 30.326374, postalCode: "73301" },
    { lng: -94.390045, lat: 29.780948, postalCode: "77661" },
    { lng: -98.02763, lat: 31.944207, postalCode: "76457" },
    { lng: -96.853877, lat: 32.959222, postalCode: "75066" },
    { lng: -97.54276, lat: 32.109599, postalCode: "76652" },
    { lng: -96.407936, lat: 30.158637, postalCode: "77633" },
    { lng: -101.826997, lat: 34.068903, postalCode: "79073" },
    { lng: -94.18997, lat: 31.769105, postalCode: "75935" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75301" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75370" },
    { lng: -95.22445, lat: 32.617073, postalCode: "75765" },
    { lng: -98.10754, lat: 29.699844, postalCode: "78130" },
    { lng: -95.57145, lat: 29.626187, postalCode: "77477" },
    { lng: -94.86062, lat: 32.533965, postalCode: "75693" },
    { lng: -95.55996, lat: 29.741565, postalCode: "77042" },
    { lng: -97.463213, lat: 27.777, postalCode: "78426" },
    { lng: -96.99321, lat: 33.04867, postalCode: "75057" },
    { lng: -97.19282, lat: 32.27442, postalCode: "76050" },
    { lng: -97.40076, lat: 31.086483, postalCode: "76502" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75049" },
    { lng: -94.4045, lat: 29.571106, postalCode: "77623" },
    { lng: -95.45085, lat: 30.313308, postalCode: "77301" },
    { lng: -95.41776, lat: 32.034127, postalCode: "75759" },
    { lng: -96.5637, lat: 33.426635, postalCode: "75495" },
    { lng: -96.09691, lat: 30.752599, postalCode: "77861" },
    { lng: -103.98274, lat: 30.626134, postalCode: "79734" },
    { lng: -100.82488, lat: 34.070936, postalCode: "79244" },
    { lng: -96.89328, lat: 33.030556, postalCode: "75010" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78708" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78773" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78709" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75387" },
    { lng: -102.40636, lat: 34.235871, postalCode: "79031" },
    { lng: -100.69452, lat: 32.047723, postalCode: "76949" },
    { lng: -96.69, lat: 32.77003, postalCode: "75227" },
    { lng: -97.41249, lat: 32.876475, postalCode: "76179" },
    { lng: -97.33579, lat: 32.680333, postalCode: "76115" },
    { lng: -97.47799, lat: 29.195217, postalCode: "77994" },
    { lng: -98.5624, lat: 33.841948, postalCode: "76310" },
    { lng: -102.010895, lat: 33.489623, postalCode: "79464" },
    { lng: -100.44628, lat: 34.975549, postalCode: "79251" },
    { lng: -97.799309, lat: 31.390196, postalCode: "76596" },
    { lng: -97.5133, lat: 28.054995, postalCode: "78387" },
    { lng: -99.09005, lat: 28.888468, postalCode: "78061" },
    { lng: -95.51716, lat: 30.10824, postalCode: "77389" },
    { lng: -95.756462, lat: 29.525461, postalCode: "77487" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78760" },
    { lng: -95.51771, lat: 29.773994, postalCode: "77024" },
    { lng: -97.74103, lat: 30.27127, postalCode: "78701" },
    { lng: -103.653904, lat: 30.363139, postalCode: "79832" },
    { lng: -97.31704, lat: 33.624401, postalCode: "76253" },
    { lng: -101.72506, lat: 34.191002, postalCode: "79072" },
    { lng: -96.26843, lat: 29.983095, postalCode: "77418" },
    { lng: -95.40114, lat: 29.038068, postalCode: "77531" },
    { lng: -95.95578, lat: 33.427504, postalCode: "75449" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77347" },
    { lng: -98.5587, lat: 26.272363, postalCode: "78595" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77206" },
    { lng: -102.35512, lat: 32.020274, postalCode: "79758" },
    { lng: -94.66375, lat: 29.689054, postalCode: "77514" },
    { lng: -99.13604, lat: 31.542459, postalCode: "76827" },
    { lng: -97.602552, lat: 30.656817, postalCode: "78630" },
    { lng: -94.87439, lat: 32.387151, postalCode: "75662" },
    { lng: -98.79093, lat: 29.950969, postalCode: "78074" },
    { lng: -97.95439, lat: 29.237074, postalCode: "78160" },
    { lng: -95.73842, lat: 32.882305, postalCode: "75440" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77881" },
    { lng: -94.2896, lat: 29.870794, postalCode: "77622" },
    { lng: -98.60919, lat: 29.227135, postalCode: "78073" },
    { lng: -100.494912, lat: 31.349348, postalCode: "76886" },
    { lng: -97.16347, lat: 32.502674, postalCode: "76061" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77492" },
    { lng: -96.82316, lat: 28.888476, postalCode: "77968" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76019" },
    { lng: -94.8845, lat: 32.204724, postalCode: "75653" },
    { lng: -96.49617, lat: 29.909257, postalCode: "78950" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78762" },
    { lng: -97.67831, lat: 30.23358, postalCode: "78742" },
    { lng: -97.49124, lat: 32.649476, postalCode: "76126" },
    { lng: -96.71501, lat: 32.098474, postalCode: "76626" },
    { lng: -97.56503, lat: 30.955509, postalCode: "76571" },
    { lng: -94.9814, lat: 33.166739, postalCode: "75455" },
    { lng: -97.38029, lat: 33.661889, postalCode: "76252" },
    { lng: -96.69402, lat: 33.106582, postalCode: "75013" },
    { lng: -97.82923, lat: 26.149453, postalCode: "78559" },
    { lng: -97.08453, lat: 32.858727, postalCode: "76039" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78779" },
    { lng: -96.57391, lat: 30.748833, postalCode: "77867" },
    { lng: -96.70685, lat: 30.796177, postalCode: "77857" },
    { lng: -97.4001, lat: 27.770056, postalCode: "78404" },
    { lng: -94.16846, lat: 32.66797, postalCode: "75661" },
    { lng: -96.4397, lat: 28.865213, postalCode: "77970" },
    { lng: -96.23381, lat: 33.695647, postalCode: "75476" },
    { lng: -95.1742, lat: 29.832668, postalCode: "77049" },
    { lng: -97.69246, lat: 31.113828, postalCode: "76543" },
    { lng: -96.6778, lat: 31.160528, postalCode: "76629" },
    { lng: -100.206705, lat: 31.359844, postalCode: "76957" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75295" },
    { lng: -96.91343, lat: 32.634835, postalCode: "75137" },
    { lng: -98.38421, lat: 30.44858, postalCode: "78663" },
    { lng: -95.41896, lat: 30.128862, postalCode: "77386" },
    { lng: -97.520941, lat: 25.981006, postalCode: "78523" },
    { lng: -100.79269, lat: 29.356379, postalCode: "78843" },
    { lng: -97.24182, lat: 28.694847, postalCode: "77960" },
    { lng: -95.49556, lat: 29.920981, postalCode: "77086" },
    { lng: -97.70392, lat: 30.384204, postalCode: "78758" },
    { lng: -96.22224, lat: 29.553544, postalCode: "77454" },
    { lng: -104.52192, lat: 30.647349, postalCode: "79854" },
    { lng: -101.61413, lat: 35.625603, postalCode: "79036" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76136" },
    { lng: -99.87238, lat: 32.442465, postalCode: "79563" },
    { lng: -97.26479, lat: 32.691033, postalCode: "76119" },
    { lng: -95.304936, lat: 29.711257, postalCode: "77000" },
    { lng: -96.65977, lat: 29.941521, postalCode: "78940" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77269" },
    { lng: -99.140974, lat: 30.033226, postalCode: "78029" },
    { lng: -97.21871, lat: 33.000177, postalCode: "76262" },
    { lng: -100.23572, lat: 32.269772, postalCode: "79537" },
    { lng: -95.11108, lat: 30.818886, postalCode: "77360" },
    { lng: -102.031726, lat: 31.869259, postalCode: "79712" },
    { lng: -96.75932, lat: 29.698373, postalCode: "78962" },
    { lng: -97.77198, lat: 32.228184, postalCode: "76043" },
    { lng: -98.316175, lat: 32.759769, postalCode: "76068" },
    { lng: -97.38542, lat: 27.733058, postalCode: "78411" },
    { lng: -94.91759, lat: 29.395283, postalCode: "77590" },
    { lng: -97.33787, lat: 32.706331, postalCode: "76110" },
    { lng: -95.83191, lat: 30.647831, postalCode: "77876" },
    { lng: -99.03622, lat: 33.029698, postalCode: "76491" },
    { lng: -97.54611, lat: 30.540874, postalCode: "78634" },
    { lng: -102.16505, lat: 33.425342, postalCode: "79358" },
    { lng: -97.771258, lat: 30.326374, postalCode: "73344" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78768" },
    { lng: -95.33155, lat: 32.456565, postalCode: "75706" },
    { lng: -99.12665, lat: 29.379516, postalCode: "78861" },
    { lng: -97.51773, lat: 28.988817, postalCode: "78164" },
    { lng: -103.01952, lat: 33.616833, postalCode: "79314" },
    { lng: -98.5665, lat: 29.457281, postalCode: "78228" },
    { lng: -98.50831, lat: 31.651485, postalCode: "76870" },
    { lng: -99.2956, lat: 30.334152, postalCode: "78631" },
    { lng: -95.26982, lat: 29.56218, postalCode: "77581" },
    { lng: -96.78624, lat: 32.044273, postalCode: "76641" },
    { lng: -102.20958, lat: 34.04877, postalCode: "79326" },
    { lng: -96.61113, lat: 33.009681, postalCode: "75094" },
    { lng: -101.69093, lat: 33.701827, postalCode: "79329" },
    { lng: -100.5198, lat: 31.474132, postalCode: "76901" },
    { lng: -102.08831, lat: 35.191525, postalCode: "79012" },
    { lng: -98.55789, lat: 29.903508, postalCode: "78004" },
    { lng: -98.848757, lat: 26.421394, postalCode: "78536" },
    { lng: -95.42126, lat: 32.522902, postalCode: "75771" },
    { lng: -95.3657, lat: 29.728779, postalCode: "77004" },
    { lng: -98.338419, lat: 29.79918, postalCode: "78131" },
    { lng: -98.494595, lat: 33.877746, postalCode: "76307" },
    { lng: -98.01478, lat: 26.44489, postalCode: "78549" },
    { lng: -95.3071, lat: 32.360498, postalCode: "75702" },
    { lng: -94.967456, lat: 33.19045, postalCode: "75456" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78771" },
    { lng: -97.12675, lat: 29.57449, postalCode: "77975" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77252" },
    { lng: -94.97392, lat: 29.366684, postalCode: "77568" },
    { lng: -97.92891, lat: 28.066598, postalCode: "78383" },
    { lng: -102.95319, lat: 33.855245, postalCode: "79344" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78291" },
    { lng: -95.33298, lat: 30.94481, postalCode: "75862" },
    { lng: -101.04673, lat: 36.280101, postalCode: "79093" },
    { lng: -99.49265, lat: 31.404745, postalCode: "76852" },
    { lng: -96.40744, lat: 31.90869, postalCode: "76681" },
    { lng: -96.1818, lat: 33.583772, postalCode: "75418" },
    { lng: -97.43449, lat: 30.715207, postalCode: "76530" },
    { lng: -94.110636, lat: 32.507491, postalCode: "75659" },
    { lng: -94.25971, lat: 33.353249, postalCode: "75573" },
    { lng: -95.89207, lat: 29.035651, postalCode: "77482" },
    { lng: -97.150558, lat: 33.169379, postalCode: "76206" },
    { lng: -98.72932, lat: 29.851666, postalCode: "78006" },
    { lng: -95.15421, lat: 31.355453, postalCode: "75847" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75342" },
    { lng: -95.59527, lat: 30.78128, postalCode: "77349" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79186" },
    { lng: -101.95908, lat: 35.893121, postalCode: "79029" },
    { lng: -98.58398, lat: 29.057172, postalCode: "78065" },
    { lng: -100.82966, lat: 36.336972, postalCode: "79070" },
    { lng: -96.50513, lat: 29.02864, postalCode: "77962" },
    { lng: -96.87859, lat: 31.310649, postalCode: "76661" },
    { lng: -94.935456, lat: 32.1826, postalCode: "75689" },
    { lng: -102.13325, lat: 34.18788, postalCode: "79064" },
    { lng: -96.88957, lat: 33.081421, postalCode: "75056" },
    { lng: -96.01082, lat: 33.566266, postalCode: "75492" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76163" },
    { lng: -95.59527, lat: 30.78128, postalCode: "77342" },
    { lng: -97.42363, lat: 30.798697, postalCode: "76511" },
    { lng: -96.7972, lat: 32.710082, postalCode: "75216" },
    { lng: -102.71795, lat: 35.2195, postalCode: "79001" },
    { lng: -95.164021, lat: 31.782048, postalCode: "75780" },
    { lng: -97.78433, lat: 27.299073, postalCode: "78379" },
    { lng: -101.98029, lat: 30.899378, postalCode: "79744" },
    { lng: -95.289903, lat: 32.411237, postalCode: "75712" },
    { lng: -101.91661, lat: 33.550335, postalCode: "79414" },
    { lng: -98.47938, lat: 30.833543, postalCode: "78607" },
    { lng: -97.17681, lat: 32.858398, postalCode: "76054" },
    { lng: -103.73108, lat: 30.966245, postalCode: "79718" },
    { lng: -101.98105, lat: 35.097488, postalCode: "79119" },
    { lng: -96.88213, lat: 31.928342, postalCode: "76660" },
    { lng: -98.30756, lat: 30.566681, postalCode: "78654" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78765" },
    { lng: -96.82552, lat: 32.845978, postalCode: "75209" },
    { lng: -96.6831, lat: 32.598845, postalCode: "75172" },
    { lng: -99.35055, lat: 32.333638, postalCode: "79504" },
    { lng: -96.51427, lat: 33.742641, postalCode: "75021" },
    { lng: -102.37108, lat: 34.539266, postalCode: "79027" },
    { lng: -96.57867, lat: 33.754053, postalCode: "75020" },
    { lng: -100.25449, lat: 33.151713, postalCode: "79502" },
    { lng: -95.041532, lat: 29.545836, postalCode: "77567" },
    { lng: -95.656779, lat: 31.760418, postalCode: "75803" },
    { lng: -96.5497, lat: 32.897459, postalCode: "75088" },
    { lng: -95.419983, lat: 32.539879, postalCode: "75711" },
    { lng: -97.116282, lat: 33.20743, postalCode: "76299" },
    { lng: -95.30386, lat: 29.686579, postalCode: "77087" },
    { lng: -98.098534, lat: 27.738511, postalCode: "78337" },
    { lng: -94.09637, lat: 33.476844, postalCode: "75503" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75242" },
    { lng: -97.15957, lat: 31.552266, postalCode: "76707" },
    { lng: -97.24787, lat: 28.327234, postalCode: "78377" },
    { lng: -95.39527, lat: 33.041578, postalCode: "75471" },
    { lng: -102.78511, lat: 34.206848, postalCode: "79347" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75016" },
    { lng: -98.5063, lat: 29.403583, postalCode: "78204" },
    { lng: -97.58922, lat: 29.592212, postalCode: "78658" },
    { lng: -96.79088, lat: 32.998132, postalCode: "75252" },
    { lng: -95.64985, lat: 30.329017, postalCode: "77316" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77213" },
    { lng: -102.16126, lat: 33.588639, postalCode: "79367" },
    { lng: -94.68252, lat: 29.43186, postalCode: "77650" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75267" },
    { lng: -97.26563, lat: 31.212196, postalCode: "76579" },
    { lng: -96.77365, lat: 32.791878, postalCode: "75246" },
    { lng: -102.30958, lat: 32.893447, postalCode: "79342" },
    { lng: -95.27709, lat: 29.289673, postalCode: "77577" },
    { lng: -95.21965, lat: 33.151104, postalCode: "75457" },
    { lng: -96.25525, lat: 33.485315, postalCode: "75475" },
    { lng: -94.129733, lat: 29.847569, postalCode: "77710" },
    { lng: -95.49679, lat: 30.233865, postalCode: "77384" },
    { lng: -96.20273, lat: 33.291418, postalCode: "75423" },
    { lng: -96.01862, lat: 32.183649, postalCode: "75148" },
    { lng: -97.59286, lat: 30.674667, postalCode: "78674" },
    { lng: -100.76393, lat: 33.749303, postalCode: "79220" },
    { lng: -95.47692, lat: 29.654108, postalCode: "77035" },
    { lng: -102.13015, lat: 31.984823, postalCode: "79703" },
    { lng: -98.84426, lat: 28.976757, postalCode: "78005" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76185" },
    { lng: -96.99457, lat: 33.274761, postalCode: "76227" },
    { lng: -95.23475, lat: 31.21349, postalCode: "75856" },
    { lng: -100.09843, lat: 30.834326, postalCode: "76841" },
    { lng: -97.55657, lat: 33.263039, postalCode: "76234" },
    { lng: -94.75643, lat: 30.480319, postalCode: "77369" },
    { lng: -94.30391, lat: 30.664543, postalCode: "77624" },
    { lng: -97.735394, lat: 30.285207, postalCode: "78712" },
    { lng: -99.69803, lat: 32.500532, postalCode: "79601" },
    { lng: -99.8296, lat: 30.873091, postalCode: "76859" },
    { lng: -97.354, lat: 32.798429, postalCode: "76106" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75263" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75356" },
    { lng: -97.52516, lat: 31.798454, postalCode: "76634" },
    { lng: -95.20485, lat: 30.756728, postalCode: "77364" },
    { lng: -98.49219, lat: 29.528515, postalCode: "78216" },
    { lng: -96.34056, lat: 30.655348, postalCode: "77802" },
    { lng: -95.50719, lat: 30.805099, postalCode: "77320" },
    { lng: -96.70916, lat: 32.948974, postalCode: "75081" },
    { lng: -97.61607, lat: 28.919157, postalCode: "78141" },
    { lng: -95.60125, lat: 29.773018, postalCode: "77079" },
    { lng: -100.207642, lat: 34.529678, postalCode: "79238" },
    { lng: -95.33797, lat: 29.226396, postalCode: "77534" },
    { lng: -95.16962, lat: 30.001902, postalCode: "77346" },
    { lng: -95.79753, lat: 29.29995, postalCode: "77444" },
    { lng: -95.72292, lat: 28.936476, postalCode: "77415" },
    { lng: -95.93391, lat: 28.83088, postalCode: "77483" },
    { lng: -95.70739, lat: 29.290602, postalCode: "77430" },
    { lng: -96.97349, lat: 32.916865, postalCode: "75063" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78246" },
    { lng: -96.68103, lat: 32.425222, postalCode: "75152" },
    { lng: -95.49694, lat: 29.959439, postalCode: "77066" },
    { lng: -94.07395, lat: 30.316847, postalCode: "77615" },
    { lng: -97.04818, lat: 28.047744, postalCode: "78382" },
    { lng: -96.618047, lat: 30.513118, postalCode: "77852" },
    { lng: -95.138794, lat: 31.105786, postalCode: "75865" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75053" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78279" },
    { lng: -98.040833, lat: 29.54508, postalCode: "78115" },
    { lng: -101.215979, lat: 30.711061, postalCode: "79643" },
    { lng: -97.42599, lat: 27.802404, postalCode: "78407" },
    { lng: -102.44248, lat: 33.037656, postalCode: "79378" },
    { lng: -99.2174, lat: 33.155666, postalCode: "76483" },
    { lng: -96.20408, lat: 30.466986, postalCode: "77866" },
    { lng: -98.43215, lat: 29.352352, postalCode: "78223" },
    { lng: -94.97588, lat: 29.784579, postalCode: "77521" },
    { lng: -98.414188, lat: 27.592468, postalCode: "78341" },
    { lng: -99.49233, lat: 27.542244, postalCode: "78041" },
    { lng: -97.24963, lat: 30.55063, postalCode: "76578" },
    { lng: -97.54845, lat: 32.905693, postalCode: "76020" },
    { lng: -98.22961, lat: 34.011464, postalCode: "76377" },
    { lng: -97.42695, lat: 27.777922, postalCode: "78405" },
    { lng: -100.71285, lat: 32.409433, postalCode: "79532" },
    { lng: -98.62152, lat: 30.230188, postalCode: "78671" },
    { lng: -100.12886, lat: 31.45195, postalCode: "76940" },
    { lng: -97.76708, lat: 32.078488, postalCode: "76690" },
    { lng: -96.37917, lat: 32.241963, postalCode: "75105" },
    { lng: -97.83087, lat: 30.501272, postalCode: "78613" },
    { lng: -102.97676, lat: 36.316305, postalCode: "79087" },
    { lng: -97.06076, lat: 31.287625, postalCode: "76632" },
    { lng: -97.23306, lat: 31.824495, postalCode: "76622" },
    { lng: -96.96229, lat: 31.071723, postalCode: "76570" },
    { lng: -101.40061, lat: 29.933388, postalCode: "78837" },
    { lng: -95.99178, lat: 30.082131, postalCode: "77446" },
    { lng: -95.1259, lat: 32.649482, postalCode: "75755" },
    { lng: -97.79149, lat: 31.084108, postalCode: "76549" },
    { lng: -98.74134, lat: 31.190826, postalCode: "76877" },
    { lng: -97.71419, lat: 30.425652, postalCode: "78727" },
    { lng: -102.696, lat: 31.253704, postalCode: "79743" },
    { lng: -98.57624, lat: 27.939823, postalCode: "78357" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75379" },
    { lng: -97.589147, lat: 30.741495, postalCode: "78673" },
    { lng: -102.62018, lat: 33.203713, postalCode: "79376" },
    { lng: -100.93789, lat: 34.241416, postalCode: "79234" },
    { lng: -99.71817, lat: 29.541648, postalCode: "78838" },
    { lng: -94.55067, lat: 29.981421, postalCode: "77538" },
    { lng: -98.03345, lat: 32.318616, postalCode: "76433" },
    { lng: -99.82014, lat: 33.423156, postalCode: "79529" },
    { lng: -102.41824, lat: 34.854898, postalCode: "79045" },
    { lng: -95.69761, lat: 33.642542, postalCode: "75421" },
    { lng: -97.494649, lat: 29.447211, postalCode: "78603" },
    { lng: -97.70927, lat: 32.28122, postalCode: "76077" },
    { lng: -97.463213, lat: 27.777, postalCode: "78480" },
    { lng: -96.618047, lat: 30.513118, postalCode: "77838" },
    { lng: -99.38274, lat: 27.538658, postalCode: "78043" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79184" },
    { lng: -96.07999, lat: 30.374501, postalCode: "77868" },
    { lng: -94.842939, lat: 29.856137, postalCode: "77580" },
    { lng: -98.61423, lat: 29.353735, postalCode: "78242" },
    { lng: -102.080064, lat: 31.963698, postalCode: "79702" },
    { lng: -94.37459, lat: 31.467506, postalCode: "75937" },
    { lng: -101.88901, lat: 33.570135, postalCode: "79410" },
    { lng: -100.15332, lat: 31.849628, postalCode: "76865" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75354" },
    { lng: -97.18588, lat: 31.535916, postalCode: "76710" },
    { lng: -96.89419, lat: 33.542765, postalCode: "76233" },
    { lng: -98.42076, lat: 33.554607, postalCode: "76389" },
    { lng: -101.94754, lat: 33.591877, postalCode: "79416" },
    { lng: -96.67955, lat: 32.825227, postalCode: "75228" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78787" },
    { lng: -102.506442, lat: 34.743735, postalCode: "79085" },
    { lng: -94.52803, lat: 31.502292, postalCode: "75978" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75275" },
    { lng: -97.16148, lat: 31.541116, postalCode: "76799" },
    { lng: -95.91606, lat: 30.956147, postalCode: "77864" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79409" },
    { lng: -95.50433, lat: 32.072339, postalCode: "75763" },
    { lng: -95.465083, lat: 29.183991, postalCode: "77542" },
    { lng: -97.90929, lat: 30.9306, postalCode: "78608" },
    { lng: -98.25219, lat: 29.883884, postalCode: "78133" },
    { lng: -94.37603, lat: 33.005009, postalCode: "75563" },
    { lng: -103.658391, lat: 30.354251, postalCode: "79870" },
    { lng: -95.34036, lat: 29.543654, postalCode: "77584" },
    { lng: -97.27036, lat: 32.805379, postalCode: "76117" },
    { lng: -96.548597, lat: 33.349316, postalCode: "75408" },
    { lng: -98.00165, lat: 32.715412, postalCode: "76066" },
    { lng: -95.254188, lat: 29.512687, postalCode: "77588" },
    { lng: -99.505138, lat: 27.732094, postalCode: "78049" },
    { lng: -95.48589, lat: 33.638587, postalCode: "75462" },
    { lng: -98.67089, lat: 32.74154, postalCode: "76429" },
    { lng: -97.463213, lat: 27.777, postalCode: "78474" },
    { lng: -97.492017, lat: 31.036287, postalCode: "76546" },
    { lng: -94.76595, lat: 30.066734, postalCode: "77575" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75355" },
    { lng: -97.08602, lat: 31.686152, postalCode: "76640" },
    { lng: -96.383005, lat: 32.03049, postalCode: "75109" },
    { lng: -97.81013, lat: 26.500175, postalCode: "78580" },
    { lng: -96.365174, lat: 33.602194, postalCode: "75499" },
    { lng: -100.53988, lat: 30.860483, postalCode: "76936" },
    { lng: -96.41899, lat: 33.615634, postalCode: "75414" },
    { lng: -95.082239, lat: 31.64775, postalCode: "75825" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75353" },
    { lng: -97.492017, lat: 31.036287, postalCode: "76545" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75138" },
    { lng: -94.35108, lat: 30.916106, postalCode: "75938" },
    { lng: -102.031726, lat: 31.869259, postalCode: "79704" },
    { lng: -101.67875, lat: 35.226552, postalCode: "79111" },
    { lng: -98.686327, lat: 27.071318, postalCode: "78360" },
    { lng: -94.187683, lat: 30.176361, postalCode: "77709" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77325" },
    { lng: -95.12214, lat: 29.786656, postalCode: "77530" },
    { lng: -97.16294, lat: 33.105309, postalCode: "76226" },
    { lng: -98.51781, lat: 33.996515, postalCode: "76305" },
    { lng: -95.33967, lat: 29.862024, postalCode: "77093" },
    { lng: -96.63523, lat: 33.518979, postalCode: "75459" },
    { lng: -102.73458, lat: 32.718232, postalCode: "79360" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75284" },
    { lng: -102.02033, lat: 33.483465, postalCode: "79382" },
    { lng: -96.82325, lat: 32.526123, postalCode: "75154" },
    { lng: -98.64854, lat: 29.743603, postalCode: "78015" },
    { lng: -95.35807, lat: 29.487395, postalCode: "77578" },
    { lng: -101.83116, lat: 34.759887, postalCode: "79042" },
    { lng: -97.475663, lat: 26.125242, postalCode: "78553" },
    { lng: -98.34205, lat: 26.234417, postalCode: "78572" },
    { lng: -97.08706, lat: 32.753672, postalCode: "76011" },
    { lng: -99.84483, lat: 32.151983, postalCode: "79541" },
    { lng: -99.3407, lat: 31.121411, postalCode: "76825" },
    { lng: -104.11628, lat: 29.444333, postalCode: "79846" },
    { lng: -95.81901, lat: 29.549455, postalCode: "77471" },
    { lng: -98.40613, lat: 31.758351, postalCode: "76452" },
    { lng: -98.62493, lat: 29.623533, postalCode: "78256" },
    { lng: -97.4143, lat: 32.670345, postalCode: "76132" },
    { lng: -95.90242, lat: 33.589851, postalCode: "75446" },
    { lng: -102.59146, lat: 33.783555, postalCode: "79353" },
    { lng: -96.473459, lat: 32.062395, postalCode: "75151" },
    { lng: -97.14698, lat: 32.721632, postalCode: "76013" },
    { lng: -94.153941, lat: 30.12355, postalCode: "77704" },
    { lng: -97.805502, lat: 32.779315, postalCode: "76485" },
    { lng: -101.843033, lat: 35.308889, postalCode: "79117" },
    { lng: -97.73519, lat: 29.72057, postalCode: "78661" },
    { lng: -99.77444, lat: 30.478782, postalCode: "76849" },
    { lng: -100.495114, lat: 31.372754, postalCode: "76906" },
    { lng: -95.929015, lat: 30.049881, postalCode: "77874" },
    { lng: -101.81824, lat: 32.255909, postalCode: "79749" },
    { lng: -95.45984, lat: 29.526728, postalCode: "77545" },
    { lng: -95.12768, lat: 29.382307, postalCode: "77517" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77270" },
    { lng: -102.89478, lat: 34.51748, postalCode: "79009" },
    { lng: -98.358613, lat: 30.219829, postalCode: "78665" },
    { lng: -94.80692, lat: 32.523779, postalCode: "75604" },
    { lng: -94.308713, lat: 32.183774, postalCode: "75685" },
    { lng: -97.38424, lat: 32.738481, postalCode: "76107" },
    { lng: -98.97235, lat: 33.963228, postalCode: "76360" },
    { lng: -99.779351, lat: 29.223697, postalCode: "78802" },
    { lng: -98.071902, lat: 28.421814, postalCode: "78060" },
    { lng: -98.25042, lat: 26.172018, postalCode: "78503" },
    { lng: -97.203166, lat: 31.553646, postalCode: "76795" },
    { lng: -100.09235, lat: 36.113986, postalCode: "79046" },
    { lng: -99.70831, lat: 31.445119, postalCode: "76862" },
    { lng: -101.83895, lat: 32.951473, postalCode: "79351" },
    { lng: -95.651058, lat: 28.798156, postalCode: "77404" },
    { lng: -99.53371, lat: 27.648832, postalCode: "78045" },
    { lng: -96.53607, lat: 33.011975, postalCode: "75098" },
    { lng: -99.61238, lat: 28.34884, postalCode: "78836" },
    { lng: -96.07311, lat: 33.595318, postalCode: "75438" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77208" },
    { lng: -96.4417, lat: 28.430193, postalCode: "77982" },
    { lng: -94.03455, lat: 30.941282, postalCode: "75951" },
    { lng: -100.80119, lat: 35.444006, postalCode: "79054" },
    { lng: -95.460797, lat: 33.663213, postalCode: "75461" },
    { lng: -95.63325, lat: 31.779481, postalCode: "75801" },
    { lng: -95.52887, lat: 29.984672, postalCode: "77069" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77223" },
    { lng: -99.800304, lat: 32.944761, postalCode: "79552" },
    { lng: -97.48974, lat: 33.003717, postalCode: "76071" },
    { lng: -95.15798, lat: 29.695028, postalCode: "77503" },
    { lng: -100.35906, lat: 31.716571, postalCode: "76953" },
    { lng: -100.12871, lat: 31.305402, postalCode: "76955" },
    { lng: -100.204928, lat: 34.840485, postalCode: "79077" },
    { lng: -99.23597, lat: 32.023828, postalCode: "76828" },
    { lng: -96.91801, lat: 32.380958, postalCode: "75167" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77383" },
    { lng: -102.60769, lat: 36.090281, postalCode: "79022" },
    { lng: -101.76906, lat: 33.327782, postalCode: "79381" },
    { lng: -95.37293, lat: 33.675155, postalCode: "75416" },
    { lng: -98.08062, lat: 30.427733, postalCode: "78669" },
    { lng: -97.04778, lat: 32.830932, postalCode: "76155" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75187" },
    { lng: -95.75734, lat: 33.460447, postalCode: "75415" },
    { lng: -94.73172, lat: 32.505248, postalCode: "75601" },
    { lng: -101.79486, lat: 35.199652, postalCode: "79104" },
    { lng: -98.37384, lat: 28.453052, postalCode: "78007" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77229" },
    { lng: -100.43729, lat: 34.545031, postalCode: "79233" },
    { lng: -97.03692, lat: 33.610384, postalCode: "76241" },
    { lng: -97.602552, lat: 30.656817, postalCode: "78682" },
    { lng: -99.94686, lat: 33.067644, postalCode: "79548" },
    { lng: -97.6138, lat: 31.081777, postalCode: "76559" },
    { lng: -99.23238, lat: 30.75159, postalCode: "76856" },
    { lng: -95.16723, lat: 30.220237, postalCode: "77372" },
    { lng: -97.563622, lat: 32.895716, postalCode: "76098" },
    { lng: -95.03534, lat: 33.626445, postalCode: "75426" },
    { lng: -94.664127, lat: 31.604573, postalCode: "75963" },
    { lng: -98.40431, lat: 29.49428, postalCode: "78218" },
    { lng: -103.57449, lat: 31.72285, postalCode: "79754" },
    { lng: -97.118754, lat: 31.717293, postalCode: "76684" },
    { lng: -95.44677, lat: 30.439428, postalCode: "77378" },
    { lng: -94.05761, lat: 31.95015, postalCode: "75954" },
    { lng: -99.718725, lat: 33.158381, postalCode: "79571" },
    { lng: -98.1764, lat: 28.492487, postalCode: "78071" },
    { lng: -96.0229, lat: 30.536223, postalCode: "77830" },
    { lng: -95.40466, lat: 29.704584, postalCode: "77030" },
    { lng: -98.07694, lat: 33.586753, postalCode: "76228" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77203" },
    { lng: -96.82348, lat: 33.842878, postalCode: "76245" },
    { lng: -101.0455, lat: 31.818347, postalCode: "76951" },
    { lng: -96.69937, lat: 32.842726, postalCode: "75218" },
    { lng: -97.50214, lat: 31.662435, postalCode: "76689" },
    { lng: -95.37767, lat: 29.616182, postalCode: "77047" },
    { lng: -96.63732, lat: 28.77203, postalCode: "77969" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75382" },
    { lng: -93.93811, lat: 30.670556, postalCode: "75956" },
    { lng: -97.463213, lat: 27.777, postalCode: "78471" },
    { lng: -94.56759, lat: 31.276647, postalCode: "75903" },
    { lng: -102.031726, lat: 31.869259, postalCode: "79708" },
    { lng: -94.89915, lat: 30.712538, postalCode: "77351" },
    { lng: -96.10808, lat: 31.763713, postalCode: "75840" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75391" },
    { lng: -95.55958, lat: 33.660274, postalCode: "75460" },
    { lng: -95.34152, lat: 29.944922, postalCode: "77032" },
    { lng: -93.886646, lat: 30.203996, postalCode: "77626" },
    { lng: -95.93998, lat: 33.146779, postalCode: "75422" },
    { lng: -95.3573, lat: 29.695879, postalCode: "77021" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76007" },
    { lng: -99.51701, lat: 32.337156, postalCode: "79510" },
    { lng: -97.723, lat: 30.310707, postalCode: "78751" },
    { lng: -96.83792, lat: 33.748414, postalCode: "76264" },
    { lng: -98.07031, lat: 27.963592, postalCode: "78372" },
    { lng: -99.28506, lat: 33.594956, postalCode: "76380" },
    { lng: -96.07526, lat: 29.401714, postalCode: "77448" },
    { lng: -101.230032, lat: 29.763171, postalCode: "78847" },
    { lng: -96.014159, lat: 29.170387, postalCode: "77453" },
    { lng: -98.207997, lat: 32.215275, postalCode: "76465" },
    { lng: -102.72658, lat: 34.631714, postalCode: "79035" },
    { lng: -95.36216, lat: 29.754728, postalCode: "77010" },
    { lng: -97.43927, lat: 26.099562, postalCode: "78566" },
    { lng: -99.93695, lat: 31.754011, postalCode: "76821" },
    { lng: -98.19192, lat: 31.06639, postalCode: "76550" },
    { lng: -98.63832, lat: 29.405424, postalCode: "78227" },
    { lng: -97.716, lat: 30.231252, postalCode: "78741" },
    { lng: -94.906515, lat: 32.195927, postalCode: "75658" },
    { lng: -97.421155, lat: 33.487842, postalCode: "76263" },
    { lng: -96.65939, lat: 28.966755, postalCode: "77957" },
    { lng: -95.45132, lat: 30.012711, postalCode: "77090" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75378" },
    { lng: -97.75105, lat: 27.672549, postalCode: "78351" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77221" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77806" },
    { lng: -97.87006, lat: 30.547001, postalCode: "78641" },
    { lng: -96.290358, lat: 30.231333, postalCode: "77834" },
    { lng: -94.577084, lat: 31.287257, postalCode: "75915" },
    { lng: -97.67621, lat: 30.374654, postalCode: "78753" },
    { lng: -96.34474, lat: 33.613097, postalCode: "75479" },
    { lng: -95.756462, lat: 29.525461, postalCode: "77496" },
    { lng: -101.821292, lat: 33.610018, postalCode: "79453" },
    { lng: -98.44199, lat: 29.758619, postalCode: "78163" },
    { lng: -100.08547, lat: 29.782887, postalCode: "78828" },
    { lng: -97.72002, lat: 32.440376, postalCode: "76049" },
    { lng: -101.230032, lat: 29.763171, postalCode: "78871" },
    { lng: -94.52727, lat: 30.304796, postalCode: "77585" },
    { lng: -98.198929, lat: 26.25671, postalCode: "78502" },
    { lng: -97.410254, lat: 32.350907, postalCode: "76033" },
    { lng: -96.39342, lat: 29.773128, postalCode: "78933" },
    { lng: -95.06111, lat: 29.624686, postalCode: "77507" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75386" },
    { lng: -96.25388, lat: 30.132693, postalCode: "77426" },
    { lng: -101.8067, lat: 33.614934, postalCode: "79403" },
    { lng: -98.48517, lat: 32.628147, postalCode: "76475" },
    { lng: -96.91964, lat: 29.680723, postalCode: "78956" },
    { lng: -97.29248, lat: 27.920604, postalCode: "78359" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76162" },
    { lng: -98.69332, lat: 26.445982, postalCode: "78582" },
    { lng: -98.39461, lat: 33.277727, postalCode: "76459" },
    { lng: -102.02668, lat: 33.980231, postalCode: "79021" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78786" },
    { lng: -94.27725, lat: 32.880182, postalCode: "75564" },
    { lng: -97.18691, lat: 29.442934, postalCode: "77984" },
    { lng: -96.615837, lat: 33.002395, postalCode: "75086" },
    { lng: -95.557004, lat: 30.378446, postalCode: "77387" },
    { lng: -94.869052, lat: 30.817866, postalCode: "77332" },
    { lng: -96.71036, lat: 32.644647, postalCode: "75141" },
    { lng: -93.96897, lat: 30.433087, postalCode: "77612" },
    { lng: -96.34404, lat: 28.861398, postalCode: "77961" },
    { lng: -97.64013, lat: 32.690922, postalCode: "76008" },
    { lng: -100.934216, lat: 29.411955, postalCode: "78842" },
    { lng: -98.496, lat: 28.790841, postalCode: "78012" },
    { lng: -96.74732, lat: 32.77103, postalCode: "75210" },
    { lng: -98.00056, lat: 28.650728, postalCode: "78145" },
    { lng: -96.23774, lat: 33.075653, postalCode: "75135" },
    { lng: -98.71204, lat: 32.970869, postalCode: "76481" },
    { lng: -94.39495, lat: 33.476552, postalCode: "75599" },
    { lng: -102.42611, lat: 35.209649, postalCode: "79092" },
    { lng: -101.18783, lat: 36.185112, postalCode: "79081" },
    { lng: -95.83057, lat: 33.434737, postalCode: "75469" },
    { lng: -96.55725, lat: 28.672399, postalCode: "77978" },
    { lng: -97.04066, lat: 28.065865, postalCode: "78358" },
    { lng: -98.03579, lat: 30.760216, postalCode: "78605" },
    { lng: -99.17526, lat: 30.486818, postalCode: "78618" },
    { lng: -95.11339, lat: 32.971224, postalCode: "75451" },
    { lng: -96.61273, lat: 28.82037, postalCode: "77991" },
    { lng: -95.3688, lat: 30.758511, postalCode: "77334" },
    { lng: -95.88, lat: 32.532449, postalCode: "75103" },
    { lng: -98.36399, lat: 32.572618, postalCode: "76453" },
    { lng: -94.638278, lat: 31.649582, postalCode: "75965" },
    { lng: -95.19472, lat: 29.516873, postalCode: "77546" },
    { lng: -98.21887, lat: 29.326804, postalCode: "78101" },
    { lng: -100.27971, lat: 36.232046, postalCode: "79056" },
    { lng: -96.313271, lat: 29.849283, postalCode: "77452" },
    { lng: -97.36611, lat: 30.338279, postalCode: "78621" },
    { lng: -98.21561, lat: 26.894094, postalCode: "78353" },
    { lng: -97.15436, lat: 27.915764, postalCode: "78336" },
    { lng: -96.66388, lat: 31.734571, postalCode: "76635" },
    { lng: -97.463213, lat: 27.777, postalCode: "78461" },
    { lng: -95.46099, lat: 29.70403, postalCode: "77401" },
    { lng: -99.76927, lat: 32.466724, postalCode: "79603" },
    { lng: -97.82904, lat: 26.27109, postalCode: "78593" },
    { lng: -98.69525, lat: 26.321652, postalCode: "78547" },
    { lng: -97.41024, lat: 31.555885, postalCode: "76638" },
    { lng: -97.35592, lat: 32.972998, postalCode: "76052" },
    { lng: -96.95213, lat: 33.385645, postalCode: "76258" },
    { lng: -97.9719, lat: 26.087777, postalCode: "78579" },
    { lng: -95.05495, lat: 31.626263, postalCode: "75925" },
    { lng: -94.79316, lat: 29.298448, postalCode: "77550" },
    { lng: -98.2802, lat: 28.906103, postalCode: "78053" },
    { lng: -96.70325, lat: 28.405373, postalCode: "77983" },
    { lng: -98.99444, lat: 32.39492, postalCode: "76437" },
    { lng: -97.8049, lat: 30.833293, postalCode: "76527" },
    { lng: -96.327044, lat: 30.57258, postalCode: "77841" },
    { lng: -99.81278, lat: 28.68906, postalCode: "78839" },
    { lng: -97.348895, lat: 32.667134, postalCode: "76153" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77225" },
    { lng: -103.08399, lat: 31.847071, postalCode: "79745" },
    { lng: -93.91857, lat: 29.947563, postalCode: "77619" },
    { lng: -94.10358, lat: 30.074112, postalCode: "77701" },
    { lng: -98.933, lat: 29.158899, postalCode: "78016" },
    { lng: -95.919107, lat: 29.50401, postalCode: "77406" },
    { lng: -93.80057, lat: 31.038417, postalCode: "75977" },
    { lng: -93.821948, lat: 31.373062, postalCode: "75947" },
    { lng: -96.638614, lat: 33.225107, postalCode: "75071" },
    { lng: -98.43167, lat: 30.744225, postalCode: "78609" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75037" },
    { lng: -98.95325, lat: 31.917862, postalCode: "76857" },
    { lng: -98.15301, lat: 26.190444, postalCode: "78589" },
    { lng: -95.91892, lat: 30.74577, postalCode: "77831" },
    { lng: -96.07346, lat: 28.964146, postalCode: "77456" },
    { lng: -95.51161, lat: 29.689781, postalCode: "77074" },
    { lng: -94.61355, lat: 33.515778, postalCode: "75559" },
    { lng: -95.15014, lat: 33.346151, postalCode: "75487" },
    { lng: -96.60205, lat: 30.876658, postalCode: "77859" },
    { lng: -95.72382, lat: 33.543006, postalCode: "75477" },
    { lng: -103.03594, lat: 31.17942, postalCode: "79730" },
    { lng: -100.71789, lat: 31.663529, postalCode: "76958" },
    { lng: -98.40241, lat: 31.238292, postalCode: "76853" },
    { lng: -95.38944, lat: 29.741878, postalCode: "77006" },
    { lng: -94.96448, lat: 31.243006, postalCode: "75926" },
    { lng: -96.55886, lat: 32.735812, postalCode: "75181" },
    { lng: -94.39965, lat: 29.820542, postalCode: "77665" },
    { lng: -94.75852, lat: 32.194938, postalCode: "75652" },
    { lng: -95.28299, lat: 32.922912, postalCode: "75494" },
    { lng: -96.63023, lat: 28.604717, postalCode: "77979" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75376" },
    { lng: -100.478373, lat: 28.679006, postalCode: "78853" },
    { lng: -97.26792, lat: 27.633433, postalCode: "78418" },
    { lng: -101.86184, lat: 33.570435, postalCode: "79411" },
    { lng: -95.99738, lat: 29.686804, postalCode: "77476" },
    { lng: -97.48079, lat: 32.762563, postalCode: "76108" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77263" },
    { lng: -103.65509, lat: 31.026706, postalCode: "79780" },
    { lng: -97.711367, lat: 26.250709, postalCode: "78555" },
    { lng: -95.43841, lat: 29.629111, postalCode: "77045" },
    { lng: -95.10265, lat: 29.55283, postalCode: "77058" },
    { lng: -97.99464, lat: 26.298611, postalCode: "78543" },
    { lng: -99.471719, lat: 27.564249, postalCode: "78047" },
    { lng: -98.11969, lat: 26.175021, postalCode: "78516" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76124" },
    { lng: -98.79895, lat: 29.227628, postalCode: "78052" },
    { lng: -97.28798, lat: 29.09649, postalCode: "77954" },
    { lng: -96.2147, lat: 28.939249, postalCode: "77458" },
    { lng: -94.16488, lat: 30.141361, postalCode: "77708" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78778" },
    { lng: -98.4801, lat: 33.85379, postalCode: "76302" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76122" },
    { lng: -96.9614, lat: 32.826729, postalCode: "75061" },
    { lng: -95.661964, lat: 31.794191, postalCode: "75880" },
    { lng: -97.09364, lat: 28.013108, postalCode: "78381" },
    { lng: -96.62447, lat: 33.092846, postalCode: "75002" },
    { lng: -96.95451, lat: 31.661554, postalCode: "76624" },
    { lng: -101.599447, lat: 35.631621, postalCode: "79008" },
    { lng: -95.94594, lat: 32.952736, postalCode: "75453" },
    { lng: -98.74185, lat: 29.61403, postalCode: "78023" },
    { lng: -95.628023, lat: 30.095391, postalCode: "77735" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75083" },
    { lng: -101.83672, lat: 33.751374, postalCode: "79350" },
    { lng: -101.86524, lat: 31.608083, postalCode: "79755" },
    { lng: -102.41085, lat: 34.230527, postalCode: "79301" },
    { lng: -98.47132, lat: 33.916497, postalCode: "76301" },
    { lng: -95.85326, lat: 30.281863, postalCode: "77363" },
    { lng: -95.41373, lat: 32.388631, postalCode: "75704" },
    { lng: -94.13364, lat: 33.210952, postalCode: "75572" },
    { lng: -102.89928, lat: 34.389202, postalCode: "79325" },
    { lng: -97.12259, lat: 33.482548, postalCode: "76272" },
    { lng: -98.15778, lat: 33.200265, postalCode: "76458" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79187" },
    { lng: -94.70503, lat: 31.61099, postalCode: "75964" },
    { lng: -95.53212, lat: 30.214741, postalCode: "77382" },
    { lng: -97.89674, lat: 28.954252, postalCode: "78144" },
    { lng: -97.203166, lat: 31.553646, postalCode: "76714" },
    { lng: -97.71439, lat: 30.518975, postalCode: "78681" },
    { lng: -95.19302, lat: 33.04314, postalCode: "75480" },
    { lng: -99.62601, lat: 31.589203, postalCode: "76888" },
    { lng: -97.29589, lat: 28.897693, postalCode: "77974" },
    { lng: -97.732736, lat: 28.424535, postalCode: "78162" },
    { lng: -96.10935, lat: 32.17548, postalCode: "75163" },
    { lng: -95.74464, lat: 30.158706, postalCode: "77355" },
    { lng: -97.69159, lat: 31.922473, postalCode: "76665" },
    { lng: -94.53692, lat: 32.312616, postalCode: "75691" },
    { lng: -100.68959, lat: 31.39577, postalCode: "76909" },
    { lng: -97.64679, lat: 30.354234, postalCode: "78754" },
    { lng: -97.90102, lat: 28.894382, postalCode: "78118" },
    { lng: -101.87581, lat: 35.171903, postalCode: "79109" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76181" },
    { lng: -97.27284, lat: 32.177765, postalCode: "76636" },
    { lng: -97.08088, lat: 32.778047, postalCode: "76006" },
    { lng: -96.82657, lat: 28.691718, postalCode: "77977" },
    { lng: -97.7638, lat: 30.344305, postalCode: "78731" },
    { lng: -96.67522, lat: 33.212203, postalCode: "75070" },
    { lng: -97.30778, lat: 28.206223, postalCode: "78393" },
    { lng: -95.16782, lat: 29.88277, postalCode: "77044" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77219" },
    { lng: -99.89708, lat: 33.379469, postalCode: "79539" },
    { lng: -96.77497, lat: 32.919104, postalCode: "75251" },
    { lng: -102.521814, lat: 34.061309, postalCode: "79321" },
    { lng: -97.68093, lat: 29.872165, postalCode: "78644" },
    { lng: -96.9179, lat: 31.475763, postalCode: "76682" },
    { lng: -94.65504, lat: 30.159698, postalCode: "77564" },
    { lng: -94.52028, lat: 29.507243, postalCode: "77617" },
    { lng: -98.46987, lat: 29.584698, postalCode: "78232" },
    { lng: -94.91759, lat: 31.825527, postalCode: "75788" },
    { lng: -97.54731, lat: 30.351225, postalCode: "78653" },
    { lng: -94.40671, lat: 30.768601, postalCode: "75979" },
    { lng: -98.41755, lat: 29.543865, postalCode: "78217" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76005" },
    { lng: -97.54944, lat: 33.530807, postalCode: "76239" },
    { lng: -98.003574, lat: 30.054378, postalCode: "78667" },
    { lng: -97.64713, lat: 30.643058, postalCode: "78626" },
    { lng: -95.39817, lat: 33.536414, postalCode: "75468" },
    { lng: -97.06078, lat: 33.198442, postalCode: "76208" },
    { lng: -97.6986, lat: 32.847183, postalCode: "76085" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75346" },
    { lng: -97.76588, lat: 30.451348, postalCode: "78729" },
    { lng: -97.19234, lat: 32.686863, postalCode: "76016" },
    { lng: -96.39502, lat: 33.321604, postalCode: "75424" },
    { lng: -99.37166, lat: 31.51161, postalCode: "76873" },
    { lng: -96.63355, lat: 32.818392, postalCode: "75150" },
    { lng: -97.75743, lat: 30.406169, postalCode: "78759" },
    { lng: -95.91061, lat: 33.25868, postalCode: "75428" },
    { lng: -97.663293, lat: 30.31536, postalCode: "78972" },
    { lng: -96.313271, lat: 29.849283, postalCode: "78931" },
    { lng: -97.693736, lat: 26.041669, postalCode: "78567" },
    { lng: -98.491344, lat: 29.442632, postalCode: "78284" },
    { lng: -97.91232, lat: 26.169728, postalCode: "78570" },
    { lng: -98.45537, lat: 29.457389, postalCode: "78234" },
    { lng: -98.45879, lat: 29.439832, postalCode: "78208" },
    { lng: -96.91746, lat: 32.685533, postalCode: "75236" },
    { lng: -97.00428, lat: 32.728982, postalCode: "75051" },
    { lng: -99.42213, lat: 30.055926, postalCode: "78024" },
    { lng: -100.93689, lat: 32.368824, postalCode: "79512" },
    { lng: -102.62831, lat: 31.730204, postalCode: "79776" },
    { lng: -95.86881, lat: 32.696777, postalCode: "75117" },
    { lng: -96.65185, lat: 29.488245, postalCode: "77475" },
    { lng: -97.14923, lat: 32.885062, postalCode: "76034" },
    { lng: -96.00418, lat: 33.76225, postalCode: "75488" },
    { lng: -96.7712, lat: 32.826128, postalCode: "75206" },
    { lng: -97.181, lat: 32.821378, postalCode: "76053" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77243" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77261" },
    { lng: -96.87928, lat: 32.817978, postalCode: "75247" },
    { lng: -97.22374, lat: 30.932552, postalCode: "76569" },
    { lng: -99.19762, lat: 32.37338, postalCode: "76469" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78278" },
    { lng: -96.85039, lat: 30.702352, postalCode: "76556" },
    { lng: -97.8178, lat: 30.07703, postalCode: "78610" },
    { lng: -98.51817, lat: 31.086845, postalCode: "76824" },
    { lng: -97.1249, lat: 29.719856, postalCode: "78941" },
    { lng: -96.56965, lat: 32.803646, postalCode: "75182" },
    { lng: -96.87222, lat: 32.704398, postalCode: "75233" },
    { lng: -96.57348, lat: 29.520292, postalCode: "77470" },
    { lng: -95.26018, lat: 29.620881, postalCode: "77075" },
    { lng: -98.29936, lat: 29.550223, postalCode: "78148" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77410" },
    { lng: -94.804065, lat: 30.188885, postalCode: "77328" },
    { lng: -96.88322, lat: 32.925975, postalCode: "75234" },
    { lng: -95.289903, lat: 32.411237, postalCode: "75713" },
    { lng: -95.66711, lat: 32.412451, postalCode: "75754" },
    { lng: -102.12349, lat: 34.543766, postalCode: "79063" },
    { lng: -94.30704, lat: 32.513986, postalCode: "75672" },
    { lng: -94.68614, lat: 30.437704, postalCode: "77376" },
    { lng: -96.60211, lat: 32.854893, postalCode: "75043" },
    { lng: -97.535883, lat: 30.946345, postalCode: "76576" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77282" },
    { lng: -97.37765, lat: 32.655401, postalCode: "76133" },
    { lng: -97.971208, lat: 29.611797, postalCode: "78156" },
    { lng: -95.54311, lat: 29.654132, postalCode: "77031" },
    { lng: -95.44364, lat: 29.739029, postalCode: "77027" },
    { lng: -96.534737, lat: 32.91747, postalCode: "75030" },
    { lng: -93.84071, lat: 30.026093, postalCode: "77611" },
    { lng: -95.31836, lat: 29.775927, postalCode: "77020" },
    { lng: -94.64704, lat: 30.112587, postalCode: "77533" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75303" },
    { lng: -97.20712, lat: 27.866143, postalCode: "78362" },
    { lng: -96.05705, lat: 33.350543, postalCode: "75496" },
    { lng: -95.25281, lat: 31.953855, postalCode: "75766" },
    { lng: -94.607432, lat: 31.699494, postalCode: "75962" },
    { lng: -94.57945, lat: 32.382771, postalCode: "75641" },
    { lng: -94.20172, lat: 30.240473, postalCode: "77657" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75381" },
    { lng: -97.14433, lat: 31.547516, postalCode: "76702" },
    { lng: -96.614921, lat: 32.669783, postalCode: "75255" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75397" },
    { lng: -102.02187, lat: 36.28116, postalCode: "79084" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75054" },
    { lng: -95.19966, lat: 29.680079, postalCode: "77502" },
    { lng: -97.96283, lat: 29.56478, postalCode: "78155" },
    { lng: -95.21807, lat: 30.053368, postalCode: "77339" },
    { lng: -97.77219, lat: 29.655639, postalCode: "78638" },
    { lng: -97.74814, lat: 32.703008, postalCode: "76087" },
    { lng: -98.461582, lat: 29.437532, postalCode: "78294" },
    { lng: -97.320306, lat: 31.053986, postalCode: "76503" },
    { lng: -96.95485, lat: 33.173008, postalCode: "75068" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75336" },
    { lng: -95.36871, lat: 29.66543, postalCode: "77051" },
    { lng: -98.50217, lat: 29.326586, postalCode: "78221" },
    { lng: -95.34741, lat: 29.749278, postalCode: "77003" },
    { lng: -102.3858, lat: 34.017448, postalCode: "79312" },
    { lng: -97.771258, lat: 30.326374, postalCode: "78782" },
    { lng: -98.2787, lat: 29.577359, postalCode: "78154" },
    { lng: -97.82265, lat: 30.17202, postalCode: "78748" },
    { lng: -95.38961, lat: 30.056394, postalCode: "77373" },
    { lng: -98.49574, lat: 26.244317, postalCode: "78560" },
    { lng: -97.84412, lat: 29.248705, postalCode: "78143" },
    { lng: -94.79422, lat: 29.328547, postalCode: "77553" },
    { lng: -97.585805, lat: 26.455519, postalCode: "78590" },
    { lng: -101.88057, lat: 33.62386, postalCode: "79415" },
    { lng: -98.66557, lat: 30.721302, postalCode: "78643" },
    { lng: -94.39495, lat: 33.476552, postalCode: "75504" },
    { lng: -95.04514, lat: 30.35469, postalCode: "77327" },
    { lng: -97.76187, lat: 30.132855, postalCode: "78747" },
    { lng: -97.43796, lat: 27.796171, postalCode: "78408" },
    { lng: -96.16969, lat: 28.718954, postalCode: "77428" },
    { lng: -96.65387, lat: 33.649938, postalCode: "75092" },
    { lng: -101.50841, lat: 31.304669, postalCode: "76932" },
    { lng: -94.11401, lat: 30.009609, postalCode: "77705" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76193" },
    { lng: -95.33834, lat: 29.66913, postalCode: "77033" },
    { lng: -96.61373, lat: 30.181677, postalCode: "77835" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77230" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75261" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77491" },
    { lng: -95.8337, lat: 30.56335, postalCode: "77873" },
    { lng: -95.39552, lat: 29.885451, postalCode: "77037" },
    { lng: -97.80918, lat: 31.148447, postalCode: "76544" },
    { lng: -95.661964, lat: 31.794191, postalCode: "75884" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75239" },
    { lng: -100.31647, lat: 28.513787, postalCode: "78860" },
    { lng: -95.08906, lat: 29.502759, postalCode: "77573" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79123" },
    { lng: -95.463474, lat: 30.329016, postalCode: "77393" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76150" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75283" },
    { lng: -101.38934, lat: 34.192619, postalCode: "79241" },
    { lng: -98.46845, lat: 26.720155, postalCode: "78588" },
    { lng: -100.02753, lat: 32.473679, postalCode: "79536" },
    { lng: -95.74751, lat: 29.758799, postalCode: "77450" },
    { lng: -98.54644, lat: 29.362185, postalCode: "78211" },
    { lng: -96.78857, lat: 31.652858, postalCode: "76678" },
    { lng: -96.777626, lat: 32.767268, postalCode: "75368" },
    { lng: -97.8597, lat: 33.293652, postalCode: "76431" },
    { lng: -96.114108, lat: 32.290819, postalCode: "75156" },
    { lng: -94.02062, lat: 31.340382, postalCode: "75930" },
    { lng: -96.341012, lat: 30.65212, postalCode: "77862" },
    { lng: -96.716638, lat: 32.374937, postalCode: "75168" },
    { lng: -94.0715, lat: 33.46238, postalCode: "75505" },
    { lng: -96.67796, lat: 30.057137, postalCode: "78954" },
    { lng: -96.662289, lat: 33.678665, postalCode: "75091" },
    { lng: -96.111103, lat: 33.469719, postalCode: "75443" },
    { lng: -97.14523, lat: 32.946678, postalCode: "76092" },
    { lng: -95.11279, lat: 33.165327, postalCode: "75493" },
    { lng: -97.68609, lat: 27.782255, postalCode: "78380" },
    { lng: -98.348534, lat: 26.232613, postalCode: "78512" },
    { lng: -96.31744, lat: 30.762815, postalCode: "77808" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76196" },
    { lng: -98.55253, lat: 29.539562, postalCode: "78230" },
    { lng: -97.0358, lat: 31.002164, postalCode: "76519" },
    { lng: -97.01216, lat: 32.759922, postalCode: "75050" },
    { lng: -97.67989, lat: 30.451803, postalCode: "78728" },
    { lng: -102.566993, lat: 31.746572, postalCode: "79769" },
    { lng: -96.83616, lat: 32.922624, postalCode: "75244" },
    { lng: -97.08748, lat: 32.694666, postalCode: "76014" },
    { lng: -97.83613, lat: 29.887939, postalCode: "78656" },
    { lng: -97.291484, lat: 32.771419, postalCode: "76195" },
    { lng: -96.60367, lat: 32.679924, postalCode: "75253" },
    { lng: -97.602552, lat: 30.656817, postalCode: "78646" },
    { lng: -98.04346, lat: 29.598103, postalCode: "78123" },
    { lng: -95.111987, lat: 29.506921, postalCode: "77593" },
    { lng: -94.99167, lat: 29.389583, postalCode: "77591" },
    { lng: -95.81773, lat: 33.13307, postalCode: "75433" },
    { lng: -98.53934, lat: 29.333436, postalCode: "78224" },
    { lng: -95.84625, lat: 32.184027, postalCode: "75751" },
    { lng: -100.56983, lat: 30.453299, postalCode: "76950" },
    { lng: -97.34622, lat: 32.873017, postalCode: "76131" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77259" },
    { lng: -101.895089, lat: 35.401475, postalCode: "79166" },
    { lng: -97.548468, lat: 31.351234, postalCode: "76552" },
    { lng: -95.04487, lat: 29.666781, postalCode: "77571" },
    { lng: -98.66812, lat: 29.665822, postalCode: "78255" },
    { lng: -98.207997, lat: 32.215275, postalCode: "76402" },
    { lng: -97.28058, lat: 30.785114, postalCode: "76523" },
    { lng: -95.30726, lat: 29.742378, postalCode: "77011" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77244" },
    { lng: -97.39136, lat: 33.491311, postalCode: "76238" },
    { lng: -100.39814, lat: 32.465621, postalCode: "79556" },
    { lng: -94.41515, lat: 30.150405, postalCode: "77659" },
    { lng: -94.18031, lat: 30.065029, postalCode: "77707" },
    { lng: -100.20106, lat: 35.459147, postalCode: "79096" },
    { lng: -94.35853, lat: 30.043401, postalCode: "77613" },
    { lng: -95.434241, lat: 29.83399, postalCode: "77097" },
    { lng: -101.94317, lat: 31.2244, postalCode: "79778" },
    { lng: -102.08108, lat: 31.995623, postalCode: "79701" },
    { lng: -96.633546, lat: 32.334709, postalCode: "75120" },
    { lng: -97.51253, lat: 27.776304, postalCode: "78406" },
    { lng: -98.75201, lat: 33.364868, postalCode: "76374" },
    { lng: -87.80062, lat: 42.704519, postalCode: "53403" },
    { lng: -87.801108, lat: 44.237283, postalCode: "54206" },
    { lng: -87.79109, lat: 44.19011, postalCode: "54247" },
    { lng: -87.483874, lat: 44.109853, postalCode: "54203" },
    { lng: -91.48885, lat: 44.253423, postalCode: "54612" },
    { lng: -90.329035, lat: 45.222391, postalCode: "54419" },
    { lng: -91.16181, lat: 44.963809, postalCode: "54727" },
    { lng: -89.78012, lat: 43.177861, postalCode: "53560" },
    { lng: -91.67722, lat: 44.13288, postalCode: "54629" },
    { lng: -88.30975, lat: 43.146023, postalCode: "53056" },
    { lng: -91.35255, lat: 46.005082, postalCode: "54843" },
    { lng: -89.69066, lat: 44.787003, postalCode: "54455" },
    { lng: -91.99704, lat: 44.718959, postalCode: "54737" },
    { lng: -91.38877, lat: 44.932711, postalCode: "54729" },
    { lng: -89.83996, lat: 46.122746, postalCode: "54545" },
    { lng: -89.42139, lat: 44.585504, postalCode: "54423" },
    { lng: -88.68846, lat: 44.191271, postalCode: "54947" },
    { lng: -91.04608, lat: 44.400995, postalCode: "54635" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53709" },
    { lng: -88.68929, lat: 45.920035, postalCode: "54542" },
    { lng: -89.528584, lat: 44.509433, postalCode: "54492" },
    { lng: -88.20955, lat: 42.886982, postalCode: "53103" },
    { lng: -90.48625, lat: 42.743948, postalCode: "53818" },
    { lng: -89.51227, lat: 44.220945, postalCode: "54966" },
    { lng: -88.07896, lat: 44.42042, postalCode: "54115" },
    { lng: -91.20833, lat: 44.424662, postalCode: "54760" },
    { lng: -87.675979, lat: 42.727153, postalCode: "53408" },
    { lng: -87.9457, lat: 42.953915, postalCode: "53221" },
    { lng: -88.37783, lat: 44.244753, postalCode: "54915" },
    { lng: -88.111252, lat: 44.42504, postalCode: "54344" },
    { lng: -89.44315, lat: 42.71815, postalCode: "53502" },
    { lng: -90.25072, lat: 46.410758, postalCode: "54550" },
    { lng: -87.79747, lat: 44.358527, postalCode: "54208" },
    { lng: -89.918563, lat: 44.619128, postalCode: "54415" },
    { lng: -92.02914, lat: 45.418325, postalCode: "54805" },
    { lng: -89.02274, lat: 42.99273, postalCode: "53523" },
    { lng: -90.26578, lat: 43.685137, postalCode: "53962" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53778" },
    { lng: -88.33656, lat: 44.268387, postalCode: "54136" },
    { lng: -90.44622, lat: 44.557846, postalCode: "54436" },
    { lng: -91.105, lat: 45.477445, postalCode: "54848" },
    { lng: -90.48248, lat: 44.758265, postalCode: "54446" },
    { lng: -91.23075, lat: 44.293183, postalCode: "54616" },
    { lng: -87.569664, lat: 43.017412, postalCode: "53295" },
    { lng: -91.08195, lat: 43.659389, postalCode: "54621" },
    { lng: -89.73647, lat: 42.985255, postalCode: "53572" },
    { lng: -92.64165, lat: 45.586187, postalCode: "54006" },
    { lng: -88.77595, lat: 43.075784, postalCode: "53038" },
    { lng: -88.775457, lat: 44.269991, postalCode: "54969" },
    { lng: -91.47648, lat: 45.742668, postalCode: "54834" },
    { lng: -90.06026, lat: 44.793747, postalCode: "54484" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53707" },
    { lng: -88.12599, lat: 42.691937, postalCode: "53139" },
    { lng: -90.93012, lat: 44.963528, postalCode: "54768" },
    { lng: -90.89904, lat: 43.543934, postalCode: "54665" },
    { lng: -91.2563, lat: 45.074725, postalCode: "54748" },
    { lng: -88.223131, lat: 44.067942, postalCode: "53062" },
    { lng: -89.90117, lat: 43.008697, postalCode: "53507" },
    { lng: -89.52124, lat: 44.025668, postalCode: "54930" },
    { lng: -89.93827, lat: 43.469055, postalCode: "53961" },
    { lng: -88.75521, lat: 44.394143, postalCode: "54961" },
    { lng: -90.26806, lat: 45.434426, postalCode: "54459" },
    { lng: -88.1504, lat: 45.245131, postalCode: "54114" },
    { lng: -88.6167, lat: 44.333183, postalCode: "54944" },
    { lng: -87.569664, lat: 43.017412, postalCode: "53274" },
    { lng: -89.2253, lat: 43.189953, postalCode: "53590" },
    { lng: -87.88001, lat: 43.40181, postalCode: "53074" },
    { lng: -88.04908, lat: 42.551693, postalCode: "53104" },
    { lng: -92.61729, lat: 44.854636, postalCode: "54022" },
    { lng: -90.76116, lat: 43.454264, postalCode: "54652" },
    { lng: -89.28954, lat: 43.015498, postalCode: "53558" },
    { lng: -89.36002, lat: 44.28018, postalCode: "54909" },
    { lng: -87.83043, lat: 42.56427, postalCode: "53143" },
    { lng: -91.92915, lat: 44.86877, postalCode: "54751" },
    { lng: -90.85562, lat: 43.656393, postalCode: "54667" },
    { lng: -91.21141, lat: 43.797116, postalCode: "54601" },
    { lng: -90.61228, lat: 45.527003, postalCode: "54537" },
    { lng: -87.85827, lat: 42.730807, postalCode: "53406" },
    { lng: -92.5478, lat: 44.970887, postalCode: "54023" },
    { lng: -88.20924, lat: 43.015289, postalCode: "53186" },
    { lng: -89.79403, lat: 44.484001, postalCode: "54475" },
    { lng: -87.98005, lat: 43.758674, postalCode: "53073" },
    { lng: -92.39152, lat: 45.780793, postalCode: "54872" },
    { lng: -88.00891, lat: 42.896145, postalCode: "53132" },
    { lng: -88.37771, lat: 43.002534, postalCode: "53183" },
    { lng: -90.31571, lat: 45.549425, postalCode: "54556" },
    { lng: -88.464873, lat: 44.416326, postalCode: "54951" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53792" },
    { lng: -88.9404, lat: 44.319005, postalCode: "54983" },
    { lng: -90.29794, lat: 45.064903, postalCode: "54480" },
    { lng: -90.45101, lat: 46.495575, postalCode: "54559" },
    { lng: -91.14425, lat: 46.360155, postalCode: "54839" },
    { lng: -90.40218, lat: 45.321034, postalCode: "54490" },
    { lng: -91.17005, lat: 45.155211, postalCode: "54732" },
    { lng: -90.49997, lat: 42.532592, postalCode: "53811" },
    { lng: -89.90731, lat: 45.964667, postalCode: "54538" },
    { lng: -90.70102, lat: 46.273618, postalCode: "54546" },
    { lng: -88.19409, lat: 42.798555, postalCode: "53185" },
    { lng: -89.1405, lat: 43.747501, postalCode: "53947" },
    { lng: -90.07619, lat: 43.302123, postalCode: "53577" },
    { lng: -88.16124, lat: 43.153447, postalCode: "53046" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53710" },
    { lng: -91.19665, lat: 43.68804, postalCode: "54658" },
    { lng: -91.60046, lat: 45.591546, postalCode: "54857" },
    { lng: -91.37996, lat: 46.553351, postalCode: "54847" },
    { lng: -90.40896, lat: 43.610055, postalCode: "54634" },
    { lng: -87.64866, lat: 44.257289, postalCode: "54228" },
    { lng: -92.40153, lat: 45.455304, postalCode: "54810" },
    { lng: -90.79784, lat: 44.95298, postalCode: "54771" },
    { lng: -88.6383, lat: 42.63427, postalCode: "53115" },
    { lng: -87.985595, lat: 44.056094, postalCode: "53245" },
    { lng: -88.32233, lat: 43.699527, postalCode: "53019" },
    { lng: -87.7766, lat: 43.97207, postalCode: "53063" },
    { lng: -92.15619, lat: 45.407855, postalCode: "54889" },
    { lng: -87.957786, lat: 43.040963, postalCode: "53281" },
    { lng: -91.02282, lat: 44.946486, postalCode: "54726" },
    { lng: -88.45746, lat: 44.472424, postalCode: "54106" },
    { lng: -92.21802, lat: 46.036193, postalCode: "54830" },
    { lng: -89.324196, lat: 43.098202, postalCode: "53779" },
    { lng: -88.48273, lat: 44.180085, postalCode: "54956" },
    { lng: -90.93744, lat: 46.69297, postalCode: "54891" },
    { lng: -88.77247, lat: 43.987186, postalCode: "54980" },
    { lng: -87.87769, lat: 45.370117, postalCode: "54177" },
    { lng: -91.64173, lat: 45.312195, postalCode: "54728" },
    { lng: -88.704914, lat: 43.414202, postalCode: "53099" },
    { lng: -87.618716, lat: 43.718294, postalCode: "53026" },
    { lng: -88.95413, lat: 43.978561, postalCode: "54923" },
    { lng: -89.44139, lat: 44.918707, postalCode: "54471" },
    { lng: -89.32342, lat: 43.522529, postalCode: "53954" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53794" },
    { lng: -91.99048, lat: 45.750367, postalCode: "54871" },
    { lng: -87.94343, lat: 42.999364, postalCode: "53215" },
    { lng: -90.79473, lat: 44.434537, postalCode: "54754" },
    { lng: -89.04525, lat: 44.734445, postalCode: "54486" },
    { lng: -87.9103, lat: 43.072062, postalCode: "53212" },
    { lng: -87.77449, lat: 45.057605, postalCode: "54157" },
    { lng: -87.569664, lat: 43.017412, postalCode: "53284" },
    { lng: -86.89946, lat: 45.365984, postalCode: "54246" },
    { lng: -89.02574, lat: 43.727646, postalCode: "53946" },
    { lng: -90.43855, lat: 42.989241, postalCode: "53569" },
    { lng: -88.16811, lat: 45.116325, postalCode: "54161" },
    { lng: -90.69831, lat: 43.136963, postalCode: "53805" },
    { lng: -89.45049, lat: 43.073395, postalCode: "53705" },
    { lng: -87.94735, lat: 43.665399, postalCode: "53093" },
    { lng: -88.70634, lat: 43.231506, postalCode: "53098" },
    { lng: -87.99839, lat: 42.937448, postalCode: "53129" },
    { lng: -91.10944, lat: 46.802909, postalCode: "54827" },
    { lng: -88.464873, lat: 44.416326, postalCode: "54931" },
    { lng: -87.006012, lat: 45.059713, postalCode: "54226" },
    { lng: -88.18026, lat: 43.40328, postalCode: "53095" },
    { lng: -87.80061, lat: 45.207353, postalCode: "54159" },
    { lng: -88.08008, lat: 43.657079, postalCode: "53011" },
    { lng: -88.19443, lat: 44.131149, postalCode: "54129" },
    { lng: -88.062338, lat: 43.433807, postalCode: "53060" },
    { lng: -89.64075, lat: 42.81636, postalCode: "53574" },
    { lng: -92.6819, lat: 45.142151, postalCode: "54025" },
    { lng: -88.4383, lat: 44.267411, postalCode: "54914" },
    { lng: -91.72727, lat: 45.401622, postalCode: "54822" },
    { lng: -90.53973, lat: 44.945107, postalCode: "54460" },
    { lng: -89.12856, lat: 43.693508, postalCode: "53939" },
    { lng: -90.37608, lat: 42.85044, postalCode: "53580" },
    { lng: -92.548, lat: 44.626915, postalCode: "54014" },
    { lng: -89.68877, lat: 45.707456, postalCode: "54529" },
    { lng: -89.20118, lat: 45.28142, postalCode: "54424" },
    { lng: -88.35965, lat: 42.649557, postalCode: "53148" },
    { lng: -91.13603, lat: 45.766112, postalCode: "54862" },
    { lng: -88.61753, lat: 45.30942, postalCode: "54175" },
    { lng: -88.95511, lat: 44.879391, postalCode: "54416" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53708" },
    { lng: -90.32891, lat: 44.844939, postalCode: "54488" },
    { lng: -88.43264, lat: 45.755118, postalCode: "54120" },
    { lng: -89.15942, lat: 42.632325, postalCode: "53542" },
    { lng: -91.285931, lat: 44.726626, postalCode: "54702" },
    { lng: -89.99623, lat: 44.456798, postalCode: "54489" },
    { lng: -89.9026, lat: 42.584521, postalCode: "53587" },
    { lng: -89.67087, lat: 45.345991, postalCode: "54442" },
    { lng: -89.05837, lat: 43.407179, postalCode: "53932" },
    { lng: -90.36783, lat: 42.563769, postalCode: "53803" },
    { lng: -89.79306, lat: 43.589593, postalCode: "53940" },
    { lng: -90.74865, lat: 46.372151, postalCode: "54846" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53777" },
    { lng: -89.20073, lat: 45.381803, postalCode: "54485" },
    { lng: -90.62639, lat: 44.775105, postalCode: "54437" },
    { lng: -88.312, lat: 44.264904, postalCode: "54113" },
    { lng: -88.17458, lat: 45.613142, postalCode: "54119" },
    { lng: -88.04535, lat: 44.788898, postalCode: "54101" },
    { lng: -92.18504, lat: 45.06461, postalCode: "54013" },
    { lng: -90.37609, lat: 43.795422, postalCode: "54638" },
    { lng: -88.34409, lat: 42.872477, postalCode: "53149" },
    { lng: -89.08234, lat: 46.056677, postalCode: "54554" },
    { lng: -91.95129, lat: 46.58521, postalCode: "54874" },
    { lng: -88.24208, lat: 44.65711, postalCode: "54162" },
    { lng: -90.62108, lat: 43.610629, postalCode: "54639" },
    { lng: -88.4018, lat: 44.826617, postalCode: "54111" },
    { lng: -88.13454, lat: 42.515668, postalCode: "53179" },
    { lng: -90.13535, lat: 45.54608, postalCode: "54513" },
    { lng: -92.46651, lat: 44.718955, postalCode: "54011" },
    { lng: -90.85401, lat: 46.856701, postalCode: "54814" },
    { lng: -88.56461, lat: 44.506022, postalCode: "54170" },
    { lng: -88.09478, lat: 43.060872, postalCode: "53005" },
    { lng: -87.95887, lat: 43.394676, postalCode: "53080" },
    { lng: -90.69309, lat: 42.695285, postalCode: "53820" },
    { lng: -88.17626, lat: 44.033215, postalCode: "53014" },
    { lng: -92.53691, lat: 45.122052, postalCode: "54017" },
    { lng: -88.34737, lat: 43.132743, postalCode: "53029" },
    { lng: -90.50935, lat: 45.536545, postalCode: "54515" },
    { lng: -88.28132, lat: 42.662671, postalCode: "53105" },
    { lng: -88.52623, lat: 43.289159, postalCode: "53059" },
    { lng: -90.484132, lat: 45.206757, postalCode: "54434" },
    { lng: -88.66382, lat: 43.000999, postalCode: "53137" },
    { lng: -87.86101, lat: 42.948416, postalCode: "53110" },
    { lng: -89.84489, lat: 43.393526, postalCode: "53951" },
    { lng: -89.87223, lat: 44.620203, postalCode: "54454" },
    { lng: -89.51106, lat: 43.103711, postalCode: "53562" },
    { lng: -90.02605, lat: 42.572413, postalCode: "53541" },
    { lng: -91.35965, lat: 44.087366, postalCode: "54630" },
    { lng: -88.007382, lat: 44.460064, postalCode: "54306" },
    { lng: -90.14404, lat: 42.974296, postalCode: "53533" },
    { lng: -89.4516, lat: 46.058408, postalCode: "54561" },
    { lng: -88.79585, lat: 44.860223, postalCode: "54128" },
    { lng: -88.16446, lat: 44.86802, postalCode: "54154" },
    { lng: -89.29417, lat: 44.064068, postalCode: "54982" },
    { lng: -87.902384, lat: 43.038863, postalCode: "53277" },
    { lng: -88.02823, lat: 43.083261, postalCode: "53222" },
    { lng: -88.079487, lat: 42.547163, postalCode: "53648" },
    { lng: -88.03047, lat: 43.61014, postalCode: "53001" },
    { lng: -89.35853, lat: 43.277409, postalCode: "53571" },
    { lng: -88.644873, lat: 44.06858, postalCode: "54906" },
    { lng: -89.57305, lat: 44.122177, postalCode: "54943" },
    { lng: -87.8992, lat: 42.884347, postalCode: "53154" },
    { lng: -91.806396, lat: 44.311074, postalCode: "54743" },
    { lng: -88.00012, lat: 43.049012, postalCode: "53213" },
    { lng: -92.62088, lat: 45.469339, postalCode: "54024" },
    { lng: -90.61457, lat: 44.553719, postalCode: "54456" },
    { lng: -90.02136, lat: 44.466554, postalCode: "54404" },
    { lng: -87.46502, lat: 44.613604, postalCode: "54201" },
    { lng: -88.17065, lat: 42.550263, postalCode: "53170" },
    { lng: -88.25467, lat: 44.76022, postalCode: "54137" },
    { lng: -91.11676, lat: 44.309131, postalCode: "54659" },
    { lng: -88.464873, lat: 44.416326, postalCode: "54919" },
    { lng: -89.61922, lat: 44.885168, postalCode: "54474" },
    { lng: -88.05059, lat: 44.291766, postalCode: "54126" },
    { lng: -90.28554, lat: 42.92239, postalCode: "53553" },
    { lng: -88.59535, lat: 44.901909, postalCode: "54135" },
    { lng: -88.70686, lat: 44.666988, postalCode: "54933" },
    { lng: -89.74753, lat: 43.483503, postalCode: "53913" },
    { lng: -89.2704, lat: 43.095178, postalCode: "53718" },
    { lng: -89.78761, lat: 44.373468, postalCode: "54494" },
    { lng: -90.45655, lat: 43.1914, postalCode: "53573" },
    { lng: -88.85412, lat: 42.551253, postalCode: "53525" },
    { lng: -91.42678, lat: 45.422669, postalCode: "54895" },
    { lng: -88.45169, lat: 43.3182, postalCode: "53078" },
    { lng: -92.324516, lat: 45.610429, postalCode: "54337" },
    { lng: -90.93859, lat: 44.44202, postalCode: "54611" },
    { lng: -89.07464, lat: 43.172619, postalCode: "53559" },
    { lng: -89.7701, lat: 44.900936, postalCode: "54432" },
    { lng: -90.42574, lat: 42.906219, postalCode: "53554" },
    { lng: -87.38044, lat: 44.844133, postalCode: "54235" },
    { lng: -91.38753, lat: 44.545046, postalCode: "54770" },
    { lng: -88.302997, lat: 43.018696, postalCode: "53064" },
    { lng: -88.03732, lat: 44.640367, postalCode: "54173" },
    { lng: -91.47902, lat: 45.842713, postalCode: "54876" },
    { lng: -92.14053, lat: 44.478326, postalCode: "54759" },
    { lng: -90.63063, lat: 45.034443, postalCode: "54498" },
    { lng: -92.37857, lat: 45.326339, postalCode: "54001" },
    { lng: -91.50521, lat: 44.82961, postalCode: "54703" },
    { lng: -89.11499, lat: 44.331217, postalCode: "54981" },
    { lng: -88.302997, lat: 43.018696, postalCode: "53187" },
    { lng: -90.36605, lat: 44.619487, postalCode: "54420" },
    { lng: -91.84389, lat: 46.127976, postalCode: "54859" },
    { lng: -89.92545, lat: 43.150122, postalCode: "53503" },
    { lng: -87.28234, lat: 45.027668, postalCode: "54209" },
    { lng: -90.63483, lat: 42.98995, postalCode: "53809" },
    { lng: -92.1517, lat: 44.763269, postalCode: "54740" },
    { lng: -88.0547, lat: 45.054842, postalCode: "54112" },
    { lng: -91.6952, lat: 46.626518, postalCode: "54854" },
    { lng: -88.60636, lat: 44.779241, postalCode: "54166" },
    { lng: -88.74978, lat: 42.60027, postalCode: "53114" },
    { lng: -88.51089, lat: 43.623996, postalCode: "53006" },
    { lng: -89.30422, lat: 44.513056, postalCode: "54407" },
    { lng: -91.29643, lat: 46.326236, postalCode: "54832" },
    { lng: -88.88507, lat: 44.732083, postalCode: "54928" },
    { lng: -91.712017, lat: 45.458812, postalCode: "54863" },
    { lng: -89.50531, lat: 43.029497, postalCode: "53719" },
    { lng: -91.71166, lat: 46.354613, postalCode: "54873" },
    { lng: -87.71974, lat: 44.200758, postalCode: "54214" },
    { lng: -87.782818, lat: 42.731224, postalCode: "53407" },
    { lng: -87.64628, lat: 44.753401, postalCode: "54204" },
    { lng: -88.09438, lat: 42.980163, postalCode: "53151" },
    { lng: -87.9749, lat: 43.086711, postalCode: "53216" },
    { lng: -91.09378, lat: 42.922835, postalCode: "53801" },
    { lng: -90.04317, lat: 44.536298, postalCode: "54410" },
    { lng: -89.03101, lat: 44.481372, postalCode: "54962" },
    { lng: -92.44607, lat: 44.608838, postalCode: "54723" },
    { lng: -89.11271, lat: 44.176716, postalCode: "54976" },
    { lng: -87.662878, lat: 42.58098, postalCode: "53141" },
    { lng: -90.91873, lat: 43.869244, postalCode: "54653" },
    { lng: -87.94727, lat: 43.11941, postalCode: "53209" },
    { lng: -87.958409, lat: 43.011264, postalCode: "53201" },
    { lng: -88.232632, lat: 42.574616, postalCode: "53152" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53782" },
    { lng: -87.98331, lat: 44.086144, postalCode: "54207" },
    { lng: -87.89998, lat: 42.985465, postalCode: "53207" },
    { lng: -88.56586, lat: 43.689915, postalCode: "53065" },
    { lng: -92.46971, lat: 45.52624, postalCode: "54858" },
    { lng: -89.33659, lat: 43.240098, postalCode: "53532" },
    { lng: -91.85084, lat: 45.275752, postalCode: "54733" },
    { lng: -90.133932, lat: 43.011323, postalCode: "53535" },
    { lng: -87.599031, lat: 44.132295, postalCode: "54221" },
    { lng: -87.16796, lat: 45.158078, postalCode: "54211" },
    { lng: -88.704914, lat: 43.414202, postalCode: "53917" },
    { lng: -89.39154, lat: 43.037647, postalCode: "53713" },
    { lng: -88.644873, lat: 44.06858, postalCode: "54903" },
    { lng: -90.21811, lat: 46.40553, postalCode: "54534" },
    { lng: -89.28376, lat: 45.03324, postalCode: "54408" },
    { lng: -88.00914, lat: 43.234506, postalCode: "53097" },
    { lng: -90.40776, lat: 43.361048, postalCode: "53581" },
    { lng: -89.404154, lat: 43.39269, postalCode: "53995" },
    { lng: -88.10326, lat: 44.564261, postalCode: "54313" },
    { lng: -90.562005, lat: 44.003084, postalCode: "54662" },
    { lng: -87.805912, lat: 44.459509, postalCode: "54308" },
    { lng: -92.64222, lat: 45.299735, postalCode: "54020" },
    { lng: -88.48262, lat: 42.512403, postalCode: "53195" },
    { lng: -88.007382, lat: 44.460064, postalCode: "54307" },
    { lng: -89.15371, lat: 44.454684, postalCode: "54977" },
    { lng: -88.522984, lat: 43.740559, postalCode: "54936" },
    { lng: -88.7351, lat: 43.633781, postalCode: "53963" },
    { lng: -87.82424, lat: 42.714369, postalCode: "53405" },
    { lng: -92.25937, lat: 44.833746, postalCode: "54767" },
    { lng: -90.40013, lat: 45.716124, postalCode: "54555" },
    { lng: -89.92864, lat: 42.878192, postalCode: "53544" },
    { lng: -87.919135, lat: 43.040813, postalCode: "53293" },
    { lng: -88.007382, lat: 44.460064, postalCode: "54324" },
    { lng: -90.94323, lat: 45.843581, postalCode: "54896" },
    { lng: -88.86721, lat: 43.296299, postalCode: "53579" },
    { lng: -89.55461, lat: 42.865397, postalCode: "53508" },
    { lng: -88.33443, lat: 42.526028, postalCode: "53128" },
    { lng: -89.64609, lat: 45.025111, postalCode: "54417" },
    { lng: -91.72638, lat: 45.517226, postalCode: "54868" },
    { lng: -87.97423, lat: 43.068962, postalCode: "53210" },
    { lng: -89.59309, lat: 43.660412, postalCode: "53920" },
    { lng: -91.05946, lat: 43.217285, postalCode: "54626" },
    { lng: -88.007382, lat: 44.460064, postalCode: "54305" },
    { lng: -88.06044, lat: 43.074583, postalCode: "53263" },
    { lng: -92.465532, lat: 44.734759, postalCode: "54010" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53780" },
    { lng: -87.76689, lat: 43.914168, postalCode: "53015" },
    { lng: -89.80722, lat: 43.908737, postalCode: "53910" },
    { lng: -89.90838, lat: 44.061018, postalCode: "54613" },
    { lng: -90.44778, lat: 46.024995, postalCode: "54514" },
    { lng: -89.04091, lat: 43.340383, postalCode: "53925" },
    { lng: -92.52701, lat: 45.454867, postalCode: "54824" },
    { lng: -89.90771, lat: 44.376507, postalCode: "54495" },
    { lng: -90.340446, lat: 42.522232, postalCode: "53886" },
    { lng: -91.294397, lat: 45.07413, postalCode: "54774" },
    { lng: -91.52601, lat: 44.130528, postalCode: "54625" },
    { lng: -92.29416, lat: 45.870441, postalCode: "54893" },
    { lng: -88.494357, lat: 43.76983, postalCode: "53009" },
    { lng: -89.64037, lat: 42.603462, postalCode: "53566" },
    { lng: -90.05754, lat: 46.183572, postalCode: "54547" },
    { lng: -91.4513, lat: 44.026843, postalCode: "54661" },
    { lng: -88.7226, lat: 42.518695, postalCode: "53585" },
    { lng: -90.967711, lat: 45.882227, postalCode: "54886" },
    { lng: -90.478904, lat: 43.690997, postalCode: "54034" },
    { lng: -88.15899, lat: 44.205239, postalCode: "54123" },
    { lng: -87.675979, lat: 42.727153, postalCode: "53401" },
    { lng: -88.81274, lat: 45.69606, postalCode: "54511" },
    { lng: -92.45652, lat: 45.577963, postalCode: "54853" },
    { lng: -88.464873, lat: 44.416326, postalCode: "54912" },
    { lng: -88.464873, lat: 44.416326, postalCode: "54131" },
    { lng: -89.85736, lat: 42.698173, postalCode: "53504" },
    { lng: -88.11034, lat: 43.151183, postalCode: "53051" },
    { lng: -90.97441, lat: 43.894741, postalCode: "54614" },
    { lng: -91.29437, lat: 45.45273, postalCode: "54819" },
    { lng: -89.22432, lat: 42.926473, postalCode: "53589" },
    { lng: -87.79943, lat: 44.224851, postalCode: "54215" },
    { lng: -89.74163, lat: 44.612879, postalCode: "54443" },
    { lng: -90.07117, lat: 44.057528, postalCode: "54646" },
    { lng: -91.99273, lat: 45.24647, postalCode: "54762" },
    { lng: -92.23721, lat: 44.530201, postalCode: "54769" },
    { lng: -89.18442, lat: 44.797279, postalCode: "54499" },
    { lng: -90.78473, lat: 43.749142, postalCode: "54619" },
    { lng: -88.72588, lat: 44.393405, postalCode: "54061" },
    { lng: -90.33179, lat: 44.764411, postalCode: "54479" },
    { lng: -90.62066, lat: 43.836783, postalCode: "54648" },
    { lng: -90.44812, lat: 42.607138, postalCode: "53807" },
    { lng: -87.78303, lat: 43.740794, postalCode: "53044" },
    { lng: -87.51197, lat: 44.693392, postalCode: "54213" },
    { lng: -88.9006, lat: 44.805167, postalCode: "54978" },
    { lng: -90.4354, lat: 46.309371, postalCode: "54565" },
    { lng: -88.03798, lat: 42.966681, postalCode: "53228" },
    { lng: -89.30897, lat: 43.49534, postalCode: "53969" },
    { lng: -88.915027, lat: 44.461926, postalCode: "54990" },
    { lng: -88.39844, lat: 43.05348, postalCode: "53018" },
    { lng: -90.959, lat: 43.265154, postalCode: "54654" },
    { lng: -87.24154, lat: 45.146473, postalCode: "54212" },
    { lng: -87.87617, lat: 42.601842, postalCode: "53144" },
    { lng: -88.88706, lat: 45.393536, postalCode: "54465" },
    { lng: -91.05429, lat: 43.246161, postalCode: "54640" },
    { lng: -89.48924, lat: 43.691602, postalCode: "53930" },
    { lng: -88.37913, lat: 45.047766, postalCode: "54174" },
    { lng: -91.67526, lat: 45.947509, postalCode: "54875" },
    { lng: -88.84494, lat: 43.849309, postalCode: "54971" },
    { lng: -90.10523, lat: 44.645158, postalCode: "54441" },
    { lng: -89.24268, lat: 42.635236, postalCode: "53576" },
    { lng: -90.29445, lat: 43.95685, postalCode: "54618" },
    { lng: -89.09349, lat: 43.055415, postalCode: "53531" },
    { lng: -90.81846, lat: 45.186932, postalCode: "54433" },
    { lng: -87.903634, lat: 43.038763, postalCode: "53270" },
    { lng: -92.71996, lat: 44.978518, postalCode: "54016" },
    { lng: -92.60246, lat: 45.351585, postalCode: "54009" },
    { lng: -88.05121, lat: 42.941264, postalCode: "53130" },
    { lng: -88.9105, lat: 45.522208, postalCode: "54520" },
    { lng: -88.74381, lat: 44.538848, postalCode: "54922" },
    { lng: -89.45736, lat: 43.765621, postalCode: "53953" },
    { lng: -88.18164, lat: 43.808108, postalCode: "53079" },
    { lng: -88.54214, lat: 42.711105, postalCode: "53121" },
    { lng: -90.98354, lat: 45.477056, postalCode: "54563" },
    { lng: -88.48292, lat: 45.655708, postalCode: "54103" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53702" },
    { lng: -89.24134, lat: 43.442214, postalCode: "53960" },
    { lng: -89.31786, lat: 43.099861, postalCode: "53714" },
    { lng: -90.90828, lat: 43.124756, postalCode: "53826" },
    { lng: -90.76632, lat: 43.388055, postalCode: "54655" },
    { lng: -88.594815, lat: 42.560001, postalCode: "53134" },
    { lng: -90.81506, lat: 46.396053, postalCode: "54855" },
    { lng: -89.83699, lat: 44.935076, postalCode: "54448" },
    { lng: -91.167621, lat: 43.907739, postalCode: "54602" },
    { lng: -89.06787, lat: 43.588986, postalCode: "53935" },
    { lng: -88.27342, lat: 45.873589, postalCode: "54121" },
    { lng: -89.69418, lat: 43.37262, postalCode: "53561" },
    { lng: -87.96996, lat: 45.732689, postalCode: "54151" },
    { lng: -89.39408, lat: 45.64672, postalCode: "54501" },
    { lng: -90.73047, lat: 45.549336, postalCode: "54530" },
    { lng: -88.03717, lat: 42.997647, postalCode: "53227" },
    { lng: -88.264, lat: 42.967394, postalCode: "53189" },
    { lng: -89.83672, lat: 43.031138, postalCode: "53517" },
    { lng: -88.26177, lat: 43.199526, postalCode: "53017" },
    { lng: -87.99094, lat: 43.564477, postalCode: "53075" },
    { lng: -90.791337, lat: 42.859325, postalCode: "53812" },
    { lng: -91.25737, lat: 44.168986, postalCode: "54627" },
    { lng: -90.10983, lat: 42.686745, postalCode: "53530" },
    { lng: -88.27907, lat: 43.33278, postalCode: "53086" },
    { lng: -89.04277, lat: 42.69146, postalCode: "53545" },
    { lng: -87.909584, lat: 43.038513, postalCode: "53268" },
    { lng: -92.13692, lat: 45.811685, postalCode: "54845" },
    { lng: -90.65361, lat: 46.599122, postalCode: "54861" },
    { lng: -88.66261, lat: 43.797693, postalCode: "54974" },
    { lng: -91.43963, lat: 44.80416, postalCode: "54720" },
    { lng: -89.43748, lat: 45.372921, postalCode: "54435" },
    { lng: -90.12791, lat: 44.283542, postalCode: "54413" },
    { lng: -91.94361, lat: 45.850775, postalCode: "54801" },
    { lng: -89.47101, lat: 43.549851, postalCode: "53901" },
    { lng: -87.86211, lat: 43.498943, postalCode: "53004" },
    { lng: -92.37326, lat: 44.96915, postalCode: "54002" },
    { lng: -91.0025, lat: 43.373139, postalCode: "54628" },
    { lng: -88.12444, lat: 42.573081, postalCode: "53168" },
    { lng: -89.072779, lat: 42.669779, postalCode: "53512" },
    { lng: -89.52525, lat: 45.731478, postalCode: "54543" },
    { lng: -88.84813, lat: 42.924942, postalCode: "53538" },
    { lng: -91.48415, lat: 45.101683, postalCode: "54724" },
    { lng: -88.12043, lat: 43.219155, postalCode: "53022" },
    { lng: -89.16252, lat: 44.95258, postalCode: "54414" },
    { lng: -89.51992, lat: 43.072994, postalCode: "53717" },
    { lng: -88.20144, lat: 43.265289, postalCode: "53076" },
    { lng: -89.7701, lat: 44.900936, postalCode: "54402" },
    { lng: -91.2512, lat: 43.978816, postalCode: "54636" },
    { lng: -90.13777, lat: 43.90147, postalCode: "53950" },
    { lng: -88.37822, lat: 44.904959, postalCode: "54124" },
    { lng: -88.52573, lat: 43.877109, postalCode: "54979" },
    { lng: -92.18637, lat: 44.938989, postalCode: "54027" },
    { lng: -90.04308, lat: 43.401354, postalCode: "53943" },
    { lng: -91.11838, lat: 43.036566, postalCode: "53821" },
    { lng: -88.04239, lat: 43.048545, postalCode: "53226" },
    { lng: -88.302997, lat: 43.018696, postalCode: "53008" },
    { lng: -88.53606, lat: 43.395861, postalCode: "53035" },
    { lng: -89.09788, lat: 44.057375, postalCode: "54970" },
    { lng: -89.33596, lat: 44.641554, postalCode: "54473" },
    { lng: -90.9931, lat: 42.818053, postalCode: "53810" },
    { lng: -89.57937, lat: 44.903194, postalCode: "54476" },
    { lng: -87.902384, lat: 43.038863, postalCode: "53278" },
    { lng: -91.86337, lat: 45.39701, postalCode: "54812" },
    { lng: -89.89911, lat: 43.705113, postalCode: "53944" },
    { lng: -88.12464, lat: 42.901235, postalCode: "53150" },
    { lng: -88.90838, lat: 43.082761, postalCode: "53551" },
    { lng: -87.62271, lat: 44.58906, postalCode: "54205" },
    { lng: -89.4171, lat: 43.404191, postalCode: "53955" },
    { lng: -88.27525, lat: 44.173538, postalCode: "54169" },
    { lng: -88.85947, lat: 44.984514, postalCode: "54150" },
    { lng: -88.374455, lat: 42.960098, postalCode: "53127" },
    { lng: -90.93065, lat: 46.136639, postalCode: "54517" },
    { lng: -89.97928, lat: 43.020516, postalCode: "53582" },
    { lng: -88.26895, lat: 44.795823, postalCode: "54127" },
    { lng: -91.921631, lat: 46.525129, postalCode: "54890" },
    { lng: -88.18008, lat: 44.52284, postalCode: "54155" },
    { lng: -90.83048, lat: 43.291992, postalCode: "54631" },
    { lng: -88.58987, lat: 42.879242, postalCode: "53156" },
    { lng: -89.79791, lat: 45.754415, postalCode: "54531" },
    { lng: -89.23917, lat: 44.834167, postalCode: "54427" },
    { lng: -91.848206, lat: 45.423409, postalCode: "54818" },
    { lng: -90.65131, lat: 43.502238, postalCode: "54664" },
    { lng: -89.60323, lat: 43.776098, postalCode: "53952" },
    { lng: -88.01085, lat: 43.85255, postalCode: "53020" },
    { lng: -88.2653, lat: 43.602787, postalCode: "53010" },
    { lng: -87.94293, lat: 42.825711, postalCode: "53108" },
    { lng: -88.99443, lat: 44.13777, postalCode: "54967" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53591" },
    { lng: -89.20998, lat: 42.668944, postalCode: "53537" },
    { lng: -87.64929, lat: 45.092448, postalCode: "54143" },
    { lng: -91.52127, lat: 45.235611, postalCode: "54757" },
    { lng: -90.23909, lat: 43.213963, postalCode: "53556" },
    { lng: -88.358167, lat: 42.540048, postalCode: "53157" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53790" },
    { lng: -91.54526, lat: 45.661506, postalCode: "54817" },
    { lng: -88.04121, lat: 43.11576, postalCode: "53225" },
    { lng: -90.8911, lat: 43.194284, postalCode: "54657" },
    { lng: -90.56934, lat: 43.740893, postalCode: "54651" },
    { lng: -91.67634, lat: 46.476428, postalCode: "54849" },
    { lng: -92.44589, lat: 44.957487, postalCode: "54015" },
    { lng: -89.85943, lat: 42.802663, postalCode: "53516" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53786" },
    { lng: -87.54429, lat: 44.456022, postalCode: "54216" },
    { lng: -88.79719, lat: 43.339823, postalCode: "53557" },
    { lng: -88.00712, lat: 44.728341, postalCode: "54141" },
    { lng: -91.24922, lat: 43.848665, postalCode: "54603" },
    { lng: -91.48065, lat: 44.780427, postalCode: "54701" },
    { lng: -92.13016, lat: 45.319095, postalCode: "54004" },
    { lng: -88.56557, lat: 42.566752, postalCode: "53197" },
    { lng: -88.62873, lat: 43.257364, postalCode: "53047" },
    { lng: -89.862202, lat: 42.649309, postalCode: "53599" },
    { lng: -90.30657, lat: 44.903, postalCode: "54421" },
    { lng: -90.791337, lat: 42.859325, postalCode: "53824" },
    { lng: -88.40959, lat: 44.212448, postalCode: "54952" },
    { lng: -89.07223, lat: 42.841688, postalCode: "53534" },
    { lng: -89.032136, lat: 45.249382, postalCode: "54464" },
    { lng: -87.68919, lat: 44.096194, postalCode: "54220" },
    { lng: -88.18285, lat: 42.511818, postalCode: "53192" },
    { lng: -90.35954, lat: 43.043206, postalCode: "53543" },
    { lng: -89.247809, lat: 44.780171, postalCode: "54429" },
    { lng: -87.9005, lat: 43.046213, postalCode: "53202" },
    { lng: -88.1503, lat: 43.055315, postalCode: "53045" },
    { lng: -89.01137, lat: 43.544491, postalCode: "53956" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53787" },
    { lng: -89.6693, lat: 44.958382, postalCode: "54401" },
    { lng: -89.19684, lat: 43.664224, postalCode: "53926" },
    { lng: -89.57988, lat: 45.811923, postalCode: "54539" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53785" },
    { lng: -87.99794, lat: 43.305412, postalCode: "53012" },
    { lng: -88.48935, lat: 43.108241, postalCode: "53066" },
    { lng: -91.95785, lat: 44.451724, postalCode: "54756" },
    { lng: -89.37364, lat: 42.613107, postalCode: "53520" },
    { lng: -88.43299, lat: 43.502688, postalCode: "53091" },
    { lng: -90.2935, lat: 43.222854, postalCode: "53540" },
    { lng: -87.98908, lat: 43.483263, postalCode: "53021" },
    { lng: -92.17732, lat: 44.624559, postalCode: "54761" },
    { lng: -89.66605, lat: 46.083178, postalCode: "54512" },
    { lng: -87.569664, lat: 43.017412, postalCode: "53237" },
    { lng: -87.99141, lat: 42.969115, postalCode: "53220" },
    { lng: -90.23935, lat: 42.5744, postalCode: "53586" },
    { lng: -89.99183, lat: 43.53414, postalCode: "53959" },
    { lng: -88.369842, lat: 44.731453, postalCode: "54182" },
    { lng: -87.915705, lat: 43.639395, postalCode: "53031" },
    { lng: -89.28591, lat: 46.040996, postalCode: "54519" },
    { lng: -91.04558, lat: 44.15175, postalCode: "54642" },
    { lng: -89.941771, lat: 43.95546, postalCode: "53927" },
    { lng: -91.81795, lat: 46.233591, postalCode: "54838" },
    { lng: -87.662878, lat: 42.58098, postalCode: "53194" },
    { lng: -87.96609, lat: 42.778642, postalCode: "53126" },
    { lng: -88.36432, lat: 43.461969, postalCode: "53002" },
    { lng: -87.95085, lat: 43.223907, postalCode: "53092" },
    { lng: -87.86395, lat: 42.909816, postalCode: "53172" },
    { lng: -87.9131, lat: 44.142939, postalCode: "54230" },
    { lng: -89.34061, lat: 43.792518, postalCode: "53949" },
    { lng: -87.95018, lat: 45.595032, postalCode: "54156" },
    { lng: -88.45828, lat: 42.587613, postalCode: "53147" },
    { lng: -88.47583, lat: 45.312629, postalCode: "54138" },
    { lng: -87.569664, lat: 43.017412, postalCode: "53234" },
    { lng: -89.49412, lat: 43.907282, postalCode: "53964" },
    { lng: -90.89542, lat: 44.550251, postalCode: "54746" },
    { lng: -89.76912, lat: 43.267183, postalCode: "53583" },
    { lng: -92.53507, lat: 45.221875, postalCode: "54026" },
    { lng: -88.25436, lat: 43.80576, postalCode: "53057" },
    { lng: -90.02136, lat: 44.466554, postalCode: "54472" },
    { lng: -87.8859, lat: 43.083012, postalCode: "53211" },
    { lng: -89.13388, lat: 45.121666, postalCode: "54409" },
    { lng: -92.27961, lat: 44.944206, postalCode: "54028" },
    { lng: -91.143254, lat: 46.682796, postalCode: "54816" },
    { lng: -90.95124, lat: 42.734625, postalCode: "53806" },
    { lng: -91.210194, lat: 44.829002, postalCode: "54242" },
    { lng: -88.44594, lat: 43.584926, postalCode: "53048" },
    { lng: -88.00285, lat: 43.924095, postalCode: "53042" },
    { lng: -89.40024, lat: 43.061546, postalCode: "53715" },
    { lng: -90.33146, lat: 45.006072, postalCode: "54425" },
    { lng: -88.62795, lat: 43.446666, postalCode: "53032" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53789" },
    { lng: -90.98854, lat: 44.601345, postalCode: "54741" },
    { lng: -90.80066, lat: 44.277231, postalCode: "54615" },
    { lng: -88.45159, lat: 44.710588, postalCode: "54107" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53744" },
    { lng: -88.65531, lat: 44.101044, postalCode: "54927" },
    { lng: -88.51758, lat: 44.075084, postalCode: "54985" },
    { lng: -92.01923, lat: 45.646145, postalCode: "54813" },
    { lng: -89.338447, lat: 43.048908, postalCode: "53784" },
    { lng: -88.71989, lat: 43.313002, postalCode: "53016" },
    { lng: -90.22247, lat: 46.429932, postalCode: "54525" },
    { lng: -90.89707, lat: 46.577191, postalCode: "54806" },
    { lng: -89.1967, lat: 43.073751, postalCode: "53527" },
    { lng: -88.541721, lat: 42.66749, postalCode: "53138" },
    { lng: -91.68767, lat: 44.584633, postalCode: "54755" },
    { lng: -88.08108, lat: 45.503625, postalCode: "54102" },
    { lng: -88.678863, lat: 44.198944, postalCode: "54957" },
    { lng: -91.90344, lat: 44.946496, postalCode: "54764" },
    { lng: -91.22963, lat: 43.899664, postalCode: "54650" },
    { lng: -91.02348, lat: 43.713575, postalCode: "54623" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53701" },
    { lng: -87.75664, lat: 43.797567, postalCode: "53083" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53793" },
    { lng: -88.56234, lat: 42.546003, postalCode: "53125" },
    { lng: -88.04658, lat: 42.696322, postalCode: "53182" },
    { lng: -89.04291, lat: 42.526464, postalCode: "53511" },
    { lng: -88.19215, lat: 43.52413, postalCode: "53040" },
    { lng: -92.77958, lat: 44.752662, postalCode: "54021" },
    { lng: -90.161478, lat: 43.468211, postalCode: "53942" },
    { lng: -90.141299, lat: 42.976078, postalCode: "53595" },
    { lng: -88.51121, lat: 45.204046, postalCode: "54149" },
    { lng: -91.23317, lat: 45.671767, postalCode: "54835" },
    { lng: -90.16658, lat: 45.297753, postalCode: "54470" },
    { lng: -88.40501, lat: 42.935259, postalCode: "53153" },
    { lng: -88.782526, lat: 42.999481, postalCode: "53449" },
    { lng: -87.903161, lat: 42.642298, postalCode: "53171" },
    { lng: -91.06484, lat: 44.029269, postalCode: "54644" },
    { lng: -91.08847, lat: 43.903949, postalCode: "54669" },
    { lng: -87.87452, lat: 42.971156, postalCode: "53235" },
    { lng: -89.24825, lat: 45.922669, postalCode: "54521" },
    { lng: -88.53911, lat: 43.499518, postalCode: "53050" },
    { lng: -89.285235, lat: 43.15955, postalCode: "53783" },
    { lng: -91.21312, lat: 46.765166, postalCode: "54844" },
    { lng: -87.92456, lat: 44.008575, postalCode: "54232" },
    { lng: -91.1559, lat: 45.253108, postalCode: "54745" },
    { lng: -91.55208, lat: 46.588243, postalCode: "54820" },
    { lng: -88.48704, lat: 43.785391, postalCode: "54937" },
    { lng: -91.12509, lat: 44.699923, postalCode: "54722" },
    { lng: -90.352237, lat: 45.116388, postalCode: "54431" },
    { lng: -88.10117, lat: 44.718335, postalCode: "54171" },
    { lng: -91.30948, lat: 44.377781, postalCode: "54773" },
    { lng: -88.91625, lat: 44.472791, postalCode: "54949" },
    { lng: -92.44657, lat: 44.782907, postalCode: "54003" },
    { lng: -88.09962, lat: 43.949961, postalCode: "53061" },
    { lng: -89.55543, lat: 42.988593, postalCode: "53593" },
    { lng: -87.83406, lat: 43.731685, postalCode: "53085" },
    { lng: -88.17011, lat: 43.322213, postalCode: "53037" },
    { lng: -89.37414, lat: 44.830734, postalCode: "54440" },
    { lng: -88.079983, lat: 42.500141, postalCode: "53102" },
    { lng: -88.06318, lat: 44.499346, postalCode: "54304" },
    { lng: -89.4897, lat: 45.914371, postalCode: "54558" },
    { lng: -87.13196, lat: 45.059668, postalCode: "54202" },
    { lng: -89.96658, lat: 45.63298, postalCode: "54564" },
    { lng: -89.36592, lat: 46.149936, postalCode: "54540" },
    { lng: -91.25765, lat: 45.858431, postalCode: "54828" },
    { lng: -88.09784, lat: 44.1196, postalCode: "54160" },
    { lng: -91.73021, lat: 45.012181, postalCode: "54730" },
    { lng: -87.93373, lat: 43.037313, postalCode: "53233" },
    { lng: -89.88239, lat: 44.260056, postalCode: "54457" },
    { lng: -90.24246, lat: 44.404914, postalCode: "54466" },
    { lng: -89.032136, lat: 45.249382, postalCode: "54444" },
    { lng: -89.55621, lat: 44.524054, postalCode: "54481" },
    { lng: -89.9943, lat: 44.662975, postalCode: "54412" },
    { lng: -88.73279, lat: 42.818747, postalCode: "53190" },
    { lng: -88.59862, lat: 42.532636, postalCode: "53184" },
    { lng: -90.64288, lat: 46.119661, postalCode: "54527" },
    { lng: -90.73209, lat: 46.799835, postalCode: "54850" },
    { lng: -88.04482, lat: 44.530892, postalCode: "54303" },
    { lng: -88.01674, lat: 44.489059, postalCode: "54301" },
    { lng: -90.30486, lat: 44.950905, postalCode: "54405" },
    { lng: -89.44499, lat: 43.036696, postalCode: "53711" },
    { lng: -87.91548, lat: 43.037963, postalCode: "53203" },
    { lng: -90.43289, lat: 44.134587, postalCode: "54666" },
    { lng: -91.8074, lat: 46.587809, postalCode: "54864" },
    { lng: -88.4281, lat: 43.769889, postalCode: "54935" },
    { lng: -87.89935, lat: 44.886571, postalCode: "54153" },
    { lng: -90.842289, lat: 44.087601, postalCode: "54620" },
    { lng: -90.635831, lat: 44.186869, postalCode: "54643" },
    { lng: -90.9287, lat: 43.31493, postalCode: "54645" },
    { lng: -91.89724, lat: 45.084813, postalCode: "54772" },
    { lng: -87.82979, lat: 42.60217, postalCode: "53140" },
    { lng: -88.82547, lat: 42.642661, postalCode: "53505" },
    { lng: -92.08073, lat: 44.949207, postalCode: "54749" },
    { lng: -90.361161, lat: 43.971514, postalCode: "54649" },
    { lng: -89.13955, lat: 43.842646, postalCode: "54968" },
    { lng: -88.15414, lat: 42.973663, postalCode: "53146" },
    { lng: -87.93476, lat: 43.076179, postalCode: "53206" },
    { lng: -87.913934, lat: 43.038663, postalCode: "53259" },
    { lng: -89.54673, lat: 44.307561, postalCode: "54921" },
    { lng: -88.67339, lat: 45.432682, postalCode: "54566" },
    { lng: -90.15726, lat: 43.378638, postalCode: "53937" },
    { lng: -89.73707, lat: 46.221041, postalCode: "54557" },
    { lng: -90.682257, lat: 42.689339, postalCode: "52820" },
    { lng: -88.47085, lat: 42.991622, postalCode: "53118" },
    { lng: -89.26288, lat: 43.192403, postalCode: "53596" },
    { lng: -90.79351, lat: 44.729524, postalCode: "54493" },
    { lng: -90.70167, lat: 42.847591, postalCode: "53813" },
    { lng: -91.83124, lat: 44.248179, postalCode: "54622" },
    { lng: -91.10883, lat: 46.433167, postalCode: "54856" },
    { lng: -91.29172, lat: 44.763678, postalCode: "54742" },
    { lng: -88.25922, lat: 44.293197, postalCode: "54130" },
    { lng: -87.909784, lat: 43.040613, postalCode: "53288" },
    { lng: -88.54699, lat: 43.988616, postalCode: "54902" },
    { lng: -89.310944, lat: 44.490241, postalCode: "54458" },
    { lng: -90.07267, lat: 43.174503, postalCode: "53588" },
    { lng: -88.74259, lat: 45.211554, postalCode: "54491" },
    { lng: -88.97443, lat: 43.841808, postalCode: "54941" },
    { lng: -88.17879, lat: 43.446623, postalCode: "53090" },
    { lng: -87.58613, lat: 44.174245, postalCode: "54241" },
    { lng: -87.72893, lat: 43.736145, postalCode: "53081" },
    { lng: -88.51649, lat: 43.211067, postalCode: "53003" },
    { lng: -88.418643, lat: 43.747687, postalCode: "54035" },
    { lng: -89.34968, lat: 43.121416, postalCode: "53704" },
    { lng: -90.049489, lat: 43.94521, postalCode: "54641" },
    { lng: -92.42153, lat: 45.679878, postalCode: "54837" },
    { lng: -89.769399, lat: 46.187253, postalCode: "54551" },
    { lng: -87.662878, lat: 42.58098, postalCode: "53101" },
    { lng: -90.34311, lat: 45.927783, postalCode: "54552" },
    { lng: -88.302997, lat: 43.018696, postalCode: "53052" },
    { lng: -89.2802, lat: 42.772516, postalCode: "53536" },
    { lng: -87.62239, lat: 44.326784, postalCode: "54240" },
    { lng: -88.0842, lat: 43.050762, postalCode: "53122" },
    { lng: -87.914034, lat: 43.037263, postalCode: "53290" },
    { lng: -89.13762, lat: 43.559975, postalCode: "53923" },
    { lng: -88.59572, lat: 43.015999, postalCode: "53178" },
    { lng: -89.9777, lat: 44.907131, postalCode: "54426" },
    { lng: -89.79624, lat: 42.552731, postalCode: "53522" },
    { lng: -89.38478, lat: 42.929208, postalCode: "53575" },
    { lng: -88.40276, lat: 43.108775, postalCode: "53058" },
    { lng: -88.25278, lat: 45.428789, postalCode: "54104" },
    { lng: -89.17824, lat: 45.516138, postalCode: "54463" },
    { lng: -90.27423, lat: 43.880649, postalCode: "54637" },
    { lng: -87.81553, lat: 44.559995, postalCode: "54229" },
    { lng: -89.40774, lat: 43.074296, postalCode: "53706" },
    { lng: -88.47117, lat: 42.881035, postalCode: "53119" },
    { lng: -88.28802, lat: 43.887372, postalCode: "53049" },
    { lng: -91.92402, lat: 44.613891, postalCode: "54736" },
    { lng: -89.73162, lat: 45.510639, postalCode: "54487" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53725" },
    { lng: -88.88498, lat: 45.138072, postalCode: "54430" },
    { lng: -90.49044, lat: 43.833159, postalCode: "54670" },
    { lng: -87.99436, lat: 43.11096, postalCode: "53218" },
    { lng: -88.31075, lat: 44.51523, postalCode: "54165" },
    { lng: -87.675979, lat: 42.727153, postalCode: "53490" },
    { lng: -87.84597, lat: 43.575272, postalCode: "53013" },
    { lng: -92.30798, lat: 44.615442, postalCode: "54750" },
    { lng: -90.59683, lat: 43.23854, postalCode: "53518" },
    { lng: -88.01273, lat: 43.020363, postalCode: "53214" },
    { lng: -90.85263, lat: 42.996023, postalCode: "53816" },
    { lng: -90.936354, lat: 42.873625, postalCode: "53802" },
    { lng: -88.99528, lat: 42.666761, postalCode: "53546" },
    { lng: -89.37727, lat: 43.078646, postalCode: "53703" },
    { lng: -87.618716, lat: 43.718294, postalCode: "53082" },
    { lng: -90.82292, lat: 43.063729, postalCode: "53827" },
    { lng: -90.274101, lat: 43.412658, postalCode: "53981" },
    { lng: -88.72654, lat: 43.923141, postalCode: "54964" },
    { lng: -88.144386, lat: 42.535968, postalCode: "53109" },
    { lng: -89.10942, lat: 45.815926, postalCode: "54562" },
    { lng: -87.97947, lat: 44.505782, postalCode: "54302" },
    { lng: -87.91692, lat: 42.699169, postalCode: "53177" },
    { lng: -91.04968, lat: 45.36469, postalCode: "54731" },
    { lng: -88.96427, lat: 45.221567, postalCode: "54418" },
    { lng: -90.1745, lat: 42.852038, postalCode: "53565" },
    { lng: -89.0051, lat: 45.392118, postalCode: "54462" },
    { lng: -87.79747, lat: 42.767286, postalCode: "53402" },
    { lng: -92.74248, lat: 45.068411, postalCode: "54082" },
    { lng: -91.22193, lat: 45.767489, postalCode: "54867" },
    { lng: -87.92232, lat: 44.485243, postalCode: "54311" },
    { lng: -87.041244, lat: 45.210664, postalCode: "54310" },
    { lng: -91.47913, lat: 44.38161, postalCode: "54747" },
    { lng: -90.31984, lat: 42.729814, postalCode: "53510" },
    { lng: -91.881754, lat: 45.319786, postalCode: "54744" },
    { lng: -91.77624, lat: 45.612444, postalCode: "54841" },
    { lng: -89.047379, lat: 45.006232, postalCode: "54450" },
    { lng: -92.398168, lat: 45.54142, postalCode: "54353" },
    { lng: -89.85287, lat: 43.967604, postalCode: "53934" },
    { lng: -90.7324, lat: 45.077541, postalCode: "54447" },
    { lng: -88.78585, lat: 43.734566, postalCode: "53919" },
    { lng: -89.30618, lat: 44.421111, postalCode: "54406" },
    { lng: -90.40527, lat: 45.171131, postalCode: "54451" },
    { lng: -88.7627, lat: 44.042594, postalCode: "54963" },
    { lng: -89.78104, lat: 43.645603, postalCode: "53965" },
    { lng: -90.5939, lat: 42.627951, postalCode: "53808" },
    { lng: -88.87123, lat: 44.779838, postalCode: "54948" },
    { lng: -87.569664, lat: 43.017412, postalCode: "53285" },
    { lng: -92.28686, lat: 45.115377, postalCode: "54012" },
    { lng: -88.54021, lat: 42.573162, postalCode: "53191" },
    { lng: -88.06843, lat: 44.943923, postalCode: "54139" },
    { lng: -88.37332, lat: 43.313361, postalCode: "53027" },
    { lng: -89.070448, lat: 42.605454, postalCode: "53501" },
    { lng: -92.09474, lat: 46.684273, postalCode: "54880" },
    { lng: -88.43771, lat: 43.114118, postalCode: "53069" },
    { lng: -88.46717, lat: 44.565437, postalCode: "54152" },
    { lng: -87.87201, lat: 42.529075, postalCode: "53158" },
    { lng: -89.53605, lat: 43.228109, postalCode: "53529" },
    { lng: -89.56074, lat: 43.325153, postalCode: "53555" },
    { lng: -91.77498, lat: 45.714265, postalCode: "54870" },
    { lng: -90.26902, lat: 43.503482, postalCode: "53924" },
    { lng: -90.91222, lat: 42.873162, postalCode: "53804" },
    { lng: -88.90158, lat: 43.566325, postalCode: "53933" },
    { lng: -89.68496, lat: 45.924341, postalCode: "54568" },
    { lng: -89.14912, lat: 43.426936, postalCode: "53928" },
    { lng: -89.735524, lat: 45.337678, postalCode: "54532" },
    { lng: -89.79346, lat: 45.869921, postalCode: "54548" },
    { lng: -92.521938, lat: 45.468941, postalCode: "54851" },
    { lng: -88.07449, lat: 44.179504, postalCode: "54110" },
    { lng: -92.07828, lat: 44.62411, postalCode: "54721" },
    { lng: -88.74673, lat: 44.63605, postalCode: "54929" },
    { lng: -89.146258, lat: 44.336537, postalCode: "54946" },
    { lng: -88.53557, lat: 44.29382, postalCode: "54942" },
    { lng: -88.04032, lat: 43.153865, postalCode: "53224" },
    { lng: -90.26952, lat: 43.680522, postalCode: "53698" },
    { lng: -88.73155, lat: 44.110806, postalCode: "54986" },
    { lng: -88.84058, lat: 43.456814, postalCode: "53916" },
    { lng: -89.22575, lat: 43.959371, postalCode: "54960" },
    { lng: -87.90026, lat: 44.037618, postalCode: "54245" },
    { lng: -88.06893, lat: 43.10836, postalCode: "53007" },
    { lng: -91.85287, lat: 44.362741, postalCode: "54610" },
    { lng: -88.70876, lat: 43.378828, postalCode: "53039" },
    { lng: -89.54399, lat: 44.452277, postalCode: "54467" },
    { lng: -90.33452, lat: 42.970855, postalCode: "53526" },
    { lng: -92.12453, lat: 45.086186, postalCode: "54734" },
    { lng: -92.02641, lat: 45.062111, postalCode: "54725" },
    { lng: -89.32085, lat: 43.065621, postalCode: "53716" },
    { lng: -90.41709, lat: 45.85263, postalCode: "54524" },
    { lng: -92.22901, lat: 45.237727, postalCode: "54005" },
    { lng: -89.49397, lat: 42.567333, postalCode: "53550" },
    { lng: -88.73058, lat: 43.170606, postalCode: "53094" },
    { lng: -88.296914, lat: 42.555695, postalCode: "53159" },
    { lng: -89.006845, lat: 43.535249, postalCode: "53957" },
    { lng: -91.959307, lat: 46.18508, postalCode: "54383" },
    { lng: -90.18152, lat: 44.656686, postalCode: "54449" },
    { lng: -87.04561, lat: 45.271782, postalCode: "54210" },
    { lng: -90.44176, lat: 44.984128, postalCode: "54422" },
    { lng: -89.13837, lat: 45.440199, postalCode: "54428" },
    { lng: -88.53528, lat: 44.043984, postalCode: "54901" },
    { lng: -87.71413, lat: 44.55681, postalCode: "54217" },
    { lng: -90.97564, lat: 42.941786, postalCode: "53817" },
    { lng: -88.268, lat: 43.076953, postalCode: "53072" },
    { lng: -89.37632, lat: 43.076691, postalCode: "53788" },
    { lng: -87.79026, lat: 44.27809, postalCode: "54227" },
    { lng: -89.36681, lat: 43.321005, postalCode: "53911" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53791" },
    { lng: -88.25761, lat: 42.515596, postalCode: "53181" },
    { lng: -88.2396, lat: 43.233282, postalCode: "53033" },
    { lng: -91.69231, lat: 44.872678, postalCode: "54739" },
    { lng: -88.97553, lat: 43.18584, postalCode: "53594" },
    { lng: -92.37018, lat: 45.189667, postalCode: "54007" },
    { lng: -89.40088, lat: 42.839241, postalCode: "53521" },
    { lng: -89.45408, lat: 43.182873, postalCode: "53597" },
    { lng: -91.84824, lat: 46.513247, postalCode: "54842" },
    { lng: -88.31001, lat: 44.286637, postalCode: "54140" },
    { lng: -91.15949, lat: 43.433893, postalCode: "54624" },
    { lng: -88.39445, lat: 44.276986, postalCode: "54911" },
    { lng: -90.80796, lat: 43.96977, postalCode: "54656" },
    { lng: -89.70736, lat: 43.868852, postalCode: "53936" },
    { lng: -91.13997, lat: 46.213138, postalCode: "54821" },
    { lng: -91.071758, lat: 43.900433, postalCode: "54699" },
    { lng: -87.96618, lat: 43.047863, postalCode: "53208" },
    { lng: -90.48416, lat: 43.984412, postalCode: "54660" },
    { lng: -88.89693, lat: 44.669461, postalCode: "54950" },
    { lng: -88.84998, lat: 44.237843, postalCode: "54940" },
    { lng: -88.41179, lat: 42.642223, postalCode: "53176" },
    { lng: -88.6624, lat: 45.55375, postalCode: "54541" },
    { lng: -88.22308, lat: 42.742629, postalCode: "53167" },
    { lng: -87.957786, lat: 43.040963, postalCode: "53280" },
    { lng: -89.52565, lat: 45.995755, postalCode: "54560" },
    { lng: -87.99213, lat: 42.996614, postalCode: "53219" },
    { lng: -87.80534, lat: 42.743169, postalCode: "53404" },
    { lng: -92.05004, lat: 45.552434, postalCode: "54829" },
    { lng: -87.11618, lat: 45.186528, postalCode: "54234" },
    { lng: -90.01829, lat: 45.040345, postalCode: "54411" },
    { lng: -92.21831, lat: 46.449996, postalCode: "54836" },
    { lng: -88.915027, lat: 44.461926, postalCode: "54975" },
    { lng: -88.95595, lat: 42.778497, postalCode: "53563" },
    { lng: -87.98717, lat: 43.163692, postalCode: "53223" },
    { lng: -90.33543, lat: 46.346699, postalCode: "54536" },
    { lng: -88.30106, lat: 44.075128, postalCode: "53088" },
    { lng: -91.699794, lat: 45.142866, postalCode: "54765" },
    { lng: -90.712433, lat: 43.489717, postalCode: "54052" },
    { lng: -88.33667, lat: 45.634252, postalCode: "54125" },
    { lng: -88.40492, lat: 44.322836, postalCode: "54913" },
    { lng: -88.10103, lat: 43.777582, postalCode: "53023" },
    { lng: -90.56703, lat: 42.928048, postalCode: "53825" },
    { lng: -88.86812, lat: 43.740108, postalCode: "53931" },
    { lng: -89.86368, lat: 44.348816, postalCode: "54469" },
    { lng: -92.67182, lat: 45.75118, postalCode: "54840" },
    { lng: -90.13608, lat: 43.579867, postalCode: "53941" },
    { lng: -87.80364, lat: 43.622793, postalCode: "53070" },
    { lng: -90.2853, lat: 43.752732, postalCode: "53929" },
    { lng: -90.85944, lat: 45.500701, postalCode: "54526" },
    { lng: -89.13383, lat: 44.558941, postalCode: "54945" },
    { lng: -89.61455, lat: 42.745346, postalCode: "53570" },
    { lng: -89.016622, lat: 44.617819, postalCode: "54926" },
    { lng: -91.16957, lat: 43.559832, postalCode: "54632" },
    { lng: -89.20231, lat: 44.186455, postalCode: "54984" },
    { lng: -88.60135, lat: 43.345528, postalCode: "53034" },
    { lng: -91.90344, lat: 44.946496, postalCode: "54735" },
    { lng: -89.7438, lat: 43.131939, postalCode: "53515" },
    { lng: -89.34203, lat: 43.21352, postalCode: "53598" },
    { lng: -88.61324, lat: 44.018871, postalCode: "54904" },
    { lng: -89.04218, lat: 44.167646, postalCode: "54965" },
    { lng: -87.94573, lat: 43.32546, postalCode: "53024" },
    { lng: -88.26852, lat: 43.020762, postalCode: "53188" },
    { lng: -91.88073, lat: 45.186997, postalCode: "54763" },
    { lng: -88.40435, lat: 42.797775, postalCode: "53120" },
    { lng: -87.909834, lat: 43.044013, postalCode: "53267" },
    { lng: -87.92625, lat: 43.017414, postalCode: "53204" },
    { lng: -88.22641, lat: 43.14004, postalCode: "53089" },
    { lng: -89.95589, lat: 43.393767, postalCode: "53958" },
    { lng: -88.6258, lat: 43.830749, postalCode: "54932" },
    { lng: -88.71347, lat: 43.518602, postalCode: "53922" },
    { lng: -91.39611, lat: 46.757192, postalCode: "54865" },
    { lng: -88.79321, lat: 42.993905, postalCode: "53549" },
    { lng: -88.84108, lat: 44.00443, postalCode: "54934" },
    { lng: -87.87878, lat: 42.559823, postalCode: "53142" },
    { lng: -89.74928, lat: 43.318318, postalCode: "53578" },
    { lng: -90.28594, lat: 43.15498, postalCode: "53506" },
    { lng: -88.5739, lat: 43.178685, postalCode: "53036" },
    { lng: -88.16205, lat: 44.325856, postalCode: "54180" },
    { lng: -91.87907, lat: 45.967422, postalCode: "54888" },
    { lng: -91.48873, lat: 44.586469, postalCode: "54738" },
    { lng: -89.59209, lat: 44.976118, postalCode: "54403" },
    { lng: -90.24377, lat: 43.648089, postalCode: "53968" },
    { lng: -87.93473, lat: 43.053763, postalCode: "53205" },
    { lng: -91.21713, lat: 44.555876, postalCode: "54758" },
    { lng: -89.64371, lat: 43.116408, postalCode: "53528" },
    { lng: -90.89376, lat: 45.323865, postalCode: "54766" },
    { lng: -92.17646, lat: 45.505963, postalCode: "54826" },
    { lng: -87.90894, lat: 43.14351, postalCode: "53217" },
    { lng: -90.287566, lat: 43.279435, postalCode: "53584" },
    { lng: -89.030111, lat: 42.729359, postalCode: "53547" },
    { lng: -90.0566, lat: 43.783534, postalCode: "53948" },
    { lng: -89.423861, lat: 43.06956, postalCode: "53726" },
    { lng: -89.70469, lat: 45.181311, postalCode: "54452" },
    { lng: -90.484132, lat: 45.206757, postalCode: "54439" },
    { lng: -72.06769, lat: 41.644546, postalCode: "6350" },
    { lng: -72.36419, lat: 41.629818, postalCode: "6231" },
    { lng: -72.52728, lat: 41.288599, postalCode: "6413" },
    { lng: -71.86813, lat: 41.456189, postalCode: "6359" },
    { lng: -73.54506, lat: 41.081396, postalCode: "6905" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6137" },
    { lng: -73.416213, lat: 41.110496, postalCode: "6858" },
    { lng: -72.90409, lat: 41.413114, postalCode: "6518" },
    { lng: -71.913339, lat: 41.744527, postalCode: "6387" },
    { lng: -73.05173, lat: 41.493763, postalCode: "6770" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6454" },
    { lng: -72.93561, lat: 41.304697, postalCode: "6504" },
    { lng: -72.92526, lat: 41.832842, postalCode: "6022" },
    { lng: -73.65866, lat: 41.061296, postalCode: "6831" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6176" },
    { lng: -72.69593, lat: 41.7469, postalCode: "6106" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6034" },
    { lng: -72.14762, lat: 41.347948, postalCode: "6385" },
    { lng: -73.31103, lat: 42.02351, postalCode: "6018" },
    { lng: -73.11787, lat: 41.820274, postalCode: "6790" },
    { lng: -73.0431, lat: 41.555846, postalCode: "6702" },
    { lng: -72.98906, lat: 42.002748, postalCode: "6091" },
    { lng: -72.918846, lat: 41.299647, postalCode: "6540" },
    { lng: -72.44479, lat: 41.506499, postalCode: "6469" },
    { lng: -72.37151, lat: 41.737015, postalCode: "6232" },
    { lng: -72.64688, lat: 41.926997, postalCode: "6096" },
    { lng: -72.45026, lat: 41.909147, postalCode: "6029" },
    { lng: -73.48294, lat: 41.075663, postalCode: "6820" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6725" },
    { lng: -73.01859, lat: 41.961127, postalCode: "6065" },
    { lng: -71.85749, lat: 41.850225, postalCode: "6241" },
    { lng: -72.6954, lat: 41.793299, postalCode: "6112" },
    { lng: -73.53796, lat: 41.049866, postalCode: "6921" },
    { lng: -72.57637, lat: 41.380594, postalCode: "6419" },
    { lng: -72.89174, lat: 41.834247, postalCode: "6019" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6911" },
    { lng: -72.60384, lat: 41.588297, postalCode: "6480" },
    { lng: -72.14439, lat: 41.609066, postalCode: "6254" },
    { lng: -73.47006, lat: 41.425594, postalCode: "6811" },
    { lng: -72.52384, lat: 41.778888, postalCode: "6040" },
    { lng: -72.92798, lat: 41.317197, postalCode: "6511" },
    { lng: -72.564832, lat: 41.794681, postalCode: "6041" },
    { lng: -72.671837, lat: 41.766049, postalCode: "6161" },
    { lng: -73.403763, lat: 42.032012, postalCode: "6079" },
    { lng: -72.690687, lat: 41.767949, postalCode: "6156" },
    { lng: -73.06262, lat: 41.551601, postalCode: "6708" },
    { lng: -72.99861, lat: 41.54903, postalCode: "6705" },
    { lng: -73.24506, lat: 41.836049, postalCode: "6756" },
    { lng: -73.43722, lat: 41.951783, postalCode: "6039" },
    { lng: -73.56804, lat: 41.033347, postalCode: "6870" },
    { lng: -73.01212, lat: 41.352964, postalCode: "6525" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6814" },
    { lng: -72.81644, lat: 41.874064, postalCode: "6070" },
    { lng: -72.04037, lat: 41.601648, postalCode: "6383" },
    { lng: -73.22184, lat: 41.475263, postalCode: "6488" },
    { lng: -72.17684, lat: 41.543559, postalCode: "6334" },
    { lng: -73.332345, lat: 41.828067, postalCode: "6753" },
    { lng: -72.81974, lat: 41.839731, postalCode: "6089" },
    { lng: -73.583569, lat: 41.032647, postalCode: "6876" },
    { lng: -73.22241, lat: 41.336046, postalCode: "6468" },
    { lng: -72.11274, lat: 41.809166, postalCode: "6235" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6123" },
    { lng: -72.306526, lat: 41.799326, postalCode: "6251" },
    { lng: -73.56423, lat: 41.132096, postalCode: "6903" },
    { lng: -72.86431, lat: 41.789698, postalCode: "6001" },
    { lng: -73.514344, lat: 41.051551, postalCode: "6922" },
    { lng: -71.97599, lat: 41.604341, postalCode: "6351" },
    { lng: -73.1813, lat: 41.188746, postalCode: "6608" },
    { lng: -72.693337, lat: 41.766499, postalCode: "6160" },
    { lng: -72.7183, lat: 41.514383, postalCode: "6455" },
    { lng: -72.72839, lat: 41.76255, postalCode: "6119" },
    { lng: -73.20539, lat: 41.554112, postalCode: "6798" },
    { lng: -73.41313, lat: 41.581494, postalCode: "6776" },
    { lng: -72.86055, lat: 41.861297, postalCode: "6092" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6904" },
    { lng: -73.0482, lat: 41.662281, postalCode: "6782" },
    { lng: -72.14671, lat: 41.697765, postalCode: "6280" },
    { lng: -72.91003, lat: 41.864597, postalCode: "6020" },
    { lng: -71.891732, lat: 41.971116, postalCode: "6246" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6493" },
    { lng: -73.363028, lat: 41.933257, postalCode: "6731" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6151" },
    { lng: -72.97889, lat: 41.594785, postalCode: "6716" },
    { lng: -71.818941, lat: 41.844885, postalCode: "6243" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6601" },
    { lng: -72.0728, lat: 41.943199, postalCode: "6282" },
    { lng: -72.414095, lat: 41.421198, postalCode: "6439" },
    { lng: -73.4915, lat: 41.566534, postalCode: "6784" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6146" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6064" },
    { lng: -72.137471, lat: 41.452548, postalCode: "6353" },
    { lng: -72.43669, lat: 41.771265, postalCode: "6043" },
    { lng: -72.93365, lat: 41.682249, postalCode: "6010" },
    { lng: -72.45336, lat: 41.995851, postalCode: "6071" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6147" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6852" },
    { lng: -73.475844, lat: 41.391956, postalCode: "6819" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6914" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6143" },
    { lng: -73.28791, lat: 41.140646, postalCode: "6490" },
    { lng: -72.6632, lat: 41.553672, postalCode: "6457" },
    { lng: -72.56887, lat: 41.660899, postalCode: "6073" },
    { lng: -72.11444, lat: 41.401398, postalCode: "6375" },
    { lng: -72.884953, lat: 41.757616, postalCode: "6087" },
    { lng: -72.66098, lat: 41.660949, postalCode: "6067" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6701" },
    { lng: -73.00492, lat: 41.852712, postalCode: "6057" },
    { lng: -72.83032, lat: 41.725195, postalCode: "6032" },
    { lng: -72.40615, lat: 41.468451, postalCode: "6423" },
    { lng: -72.5078, lat: 41.573008, postalCode: "6424" },
    { lng: -73.43658, lat: 41.124546, postalCode: "6850" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6912" },
    { lng: -73.0792, lat: 41.386584, postalCode: "6483" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6521" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6104" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6501" },
    { lng: -72.20036, lat: 41.577198, postalCode: "6336" },
    { lng: -73.1292, lat: 41.306396, postalCode: "6484" },
    { lng: -72.020137, lat: 41.831565, postalCode: "6233" },
    { lng: -72.29349, lat: 41.702777, postalCode: "6237" },
    { lng: -72.79826, lat: 41.959713, postalCode: "6035" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6860" },
    { lng: -72.93765, lat: 41.357947, postalCode: "6514" },
    { lng: -72.686687, lat: 41.771499, postalCode: "6154" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6920" },
    { lng: -73.21633, lat: 41.163596, postalCode: "6605" },
    { lng: -72.658179, lat: 41.556463, postalCode: "6459" },
    { lng: -73.28842, lat: 41.253946, postalCode: "6612" },
    { lng: -72.10338, lat: 41.352498, postalCode: "6320" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6083" },
    { lng: -71.98252, lat: 41.876565, postalCode: "6259" },
    { lng: -73.19437, lat: 41.986861, postalCode: "6058" },
    { lng: -72.8445, lat: 42.012195, postalCode: "6060" },
    { lng: -73.52128, lat: 41.096846, postalCode: "6907" },
    { lng: -71.99872, lat: 41.971403, postalCode: "6281" },
    { lng: -72.25602, lat: 41.294797, postalCode: "6376" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6535" },
    { lng: -72.09527, lat: 41.90913, postalCode: "6242" },
    { lng: -73.49178, lat: 41.150146, postalCode: "6840" },
    { lng: -73.16649, lat: 41.177646, postalCode: "6607" },
    { lng: -72.56365, lat: 41.987238, postalCode: "6082" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6537" },
    { lng: -73.53688, lat: 41.047996, postalCode: "6902" },
    { lng: -73.33673, lat: 41.875075, postalCode: "6796" },
    { lng: -73.12729, lat: 41.428163, postalCode: "6478" },
    { lng: -71.90634, lat: 41.839051, postalCode: "6263" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6487" },
    { lng: -73.02541, lat: 41.279547, postalCode: "6477" },
    { lng: -72.81973, lat: 41.536048, postalCode: "6451" },
    { lng: -72.54393, lat: 41.909097, postalCode: "6016" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6721" },
    { lng: -72.47213, lat: 41.408775, postalCode: "6412" },
    { lng: -72.66998, lat: 41.612046, postalCode: "6416" },
    { lng: -73.1708, lat: 41.202091, postalCode: "6610" },
    { lng: -73.21268, lat: 41.754255, postalCode: "6759" },
    { lng: -73.13515, lat: 41.175947, postalCode: "6615" },
    { lng: -73.00836, lat: 41.676415, postalCode: "6786" },
    { lng: -72.7908, lat: 41.686949, postalCode: "6053" },
    { lng: -72.17674, lat: 41.895108, postalCode: "6278" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6507" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6140" },
    { lng: -73.45553, lat: 41.729239, postalCode: "6757" },
    { lng: -71.87594, lat: 41.799111, postalCode: "6239" },
    { lng: -73.06453, lat: 41.432936, postalCode: "6403" },
    { lng: -72.40144, lat: 41.683399, postalCode: "6248" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6152" },
    { lng: -73.47692, lat: 41.655998, postalCode: "6755" },
    { lng: -72.63897, lat: 41.985946, postalCode: "6078" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6703" },
    { lng: -72.8898, lat: 41.749832, postalCode: "6085" },
    { lng: -73.31474, lat: 41.398462, postalCode: "6470" },
    { lng: -73.42869, lat: 41.092196, postalCode: "6854" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6749" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6673" },
    { lng: -73.40105, lat: 41.379978, postalCode: "6801" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6167" },
    { lng: -72.87412, lat: 41.276497, postalCode: "6512" },
    { lng: -73.4053, lat: 41.134896, postalCode: "6851" },
    { lng: -72.66654, lat: 41.857303, postalCode: "6095" },
    { lng: -72.69782, lat: 41.536643, postalCode: "6481" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6650" },
    { lng: -73.39918, lat: 41.100496, postalCode: "6855" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6145" },
    { lng: -72.38285, lat: 41.289348, postalCode: "6475" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6132" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6436" },
    { lng: -73.03488, lat: 41.57693, postalCode: "6704" },
    { lng: -72.73357, lat: 42.019907, postalCode: "6093" },
    { lng: -73.08433, lat: 41.323005, postalCode: "6418" },
    { lng: -72.72642, lat: 41.832798, postalCode: "6002" },
    { lng: -72.77975, lat: 41.399873, postalCode: "6472" },
    { lng: -71.97281, lat: 41.360798, postalCode: "6355" },
    { lng: -73.00356, lat: 41.417896, postalCode: "6524" },
    { lng: -72.33137, lat: 41.778651, postalCode: "6238" },
    { lng: -71.89659, lat: 41.989579, postalCode: "6255" },
    { lng: -72.04524, lat: 41.345331, postalCode: "6340" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6888" },
    { lng: -73.252696, lat: 41.219093, postalCode: "6431" },
    { lng: -73.02827, lat: 41.537896, postalCode: "6706" },
    { lng: -73.2629, lat: 41.163445, postalCode: "6430" },
    { lng: -72.7546, lat: 41.75275, postalCode: "6107" },
    { lng: -72.96783, lat: 41.926696, postalCode: "6063" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6726" },
    { lng: -73.20864, lat: 41.639235, postalCode: "6751" },
    { lng: -72.8803, lat: 41.315797, postalCode: "6513" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6533" },
    { lng: -72.44392, lat: 41.345099, postalCode: "6442" },
    { lng: -73.34689, lat: 41.141005, postalCode: "6889" },
    { lng: -72.60807, lat: 41.745349, postalCode: "6118" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6502" },
    { lng: -72.44578, lat: 41.37934, postalCode: "6417" },
    { lng: -73.43819, lat: 41.066196, postalCode: "6853" },
    { lng: -72.85897, lat: 41.378447, postalCode: "6473" },
    { lng: -71.83969, lat: 41.972782, postalCode: "6277" },
    { lng: -72.903746, lat: 41.565697, postalCode: "6467" },
    { lng: -71.91911, lat: 41.684777, postalCode: "6374" },
    { lng: -72.33795, lat: 41.56033, postalCode: "6415" },
    { lng: -72.60547, lat: 41.908464, postalCode: "6088" },
    { lng: -72.11459, lat: 41.457198, postalCode: "6382" },
    { lng: -73.129186, lat: 41.165097, postalCode: "6404" },
    { lng: -72.778391, lat: 41.666049, postalCode: "6050" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6530" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6138" },
    { lng: -73.559118, lat: 41.039147, postalCode: "6910" },
    { lng: -72.46087, lat: 41.289499, postalCode: "6498" },
    { lng: -72.6747, lat: 41.702049, postalCode: "6109" },
    { lng: -72.96902, lat: 41.327997, postalCode: "6515" },
    { lng: -72.06232, lat: 41.533848, postalCode: "6360" },
    { lng: -72.80542, lat: 41.277763, postalCode: "6405" },
    { lng: -73.06927, lat: 41.754872, postalCode: "6791" },
    { lng: -73.20771, lat: 41.209496, postalCode: "6606" },
    { lng: -72.69791, lat: 41.31353, postalCode: "6437" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6045" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6126" },
    { lng: -72.2269, lat: 41.371913, postalCode: "6333" },
    { lng: -72.603033, lat: 41.859248, postalCode: "6028" },
    { lng: -73.08979, lat: 41.677982, postalCode: "6787" },
    { lng: -73.06944, lat: 41.925967, postalCode: "6098" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6142" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6408" },
    { lng: -73.37308, lat: 41.216454, postalCode: "6883" },
    { lng: -72.56076, lat: 41.836665, postalCode: "6074" },
    { lng: -73.45441, lat: 41.390194, postalCode: "6810" },
    { lng: -72.534482, lat: 41.688896, postalCode: "6025" },
    { lng: -73.265744, lat: 41.163879, postalCode: "6824" },
    { lng: -72.05148, lat: 41.566298, postalCode: "6380" },
    { lng: -72.70255, lat: 41.771166, postalCode: "6105" },
    { lng: -72.679387, lat: 41.758849, postalCode: "6115" },
    { lng: -72.60938, lat: 41.298648, postalCode: "6443" },
    { lng: -72.46252, lat: 41.637066, postalCode: "6447" },
    { lng: -72.13964, lat: 41.758713, postalCode: "6256" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6913" },
    { lng: -72.97799, lat: 41.501856, postalCode: "6712" },
    { lng: -72.734271, lat: 41.879637, postalCode: "6006" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6859" },
    { lng: -73.39857, lat: 41.301007, postalCode: "6896" },
    { lng: -72.0029, lat: 41.697123, postalCode: "6331" },
    { lng: -73.187207, lat: 41.386646, postalCode: "6491" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6520" },
    { lng: -72.680386, lat: 41.927447, postalCode: "6199" },
    { lng: -73.189006, lat: 41.179846, postalCode: "6602" },
    { lng: -73.44024, lat: 41.69971, postalCode: "6785" },
    { lng: -72.90489, lat: 41.507049, postalCode: "6410" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6049" },
    { lng: -73.26877, lat: 42.010733, postalCode: "6024" },
    { lng: -71.84876, lat: 41.375701, postalCode: "6379" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6857" },
    { lng: -71.95989, lat: 41.782474, postalCode: "6234" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6011" },
    { lng: -72.07227, lat: 41.426775, postalCode: "6335" },
    { lng: -72.77214, lat: 41.332197, postalCode: "6471" },
    { lng: -72.85242, lat: 41.953596, postalCode: "6090" },
    { lng: -72.490593, lat: 41.976466, postalCode: "6072" },
    { lng: -73.13221, lat: 41.219246, postalCode: "6614" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6494" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6444" },
    { lng: -72.686465, lat: 41.76928, postalCode: "6155" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6531" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6817" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6723" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6153" },
    { lng: -73.5842, lat: 41.034264, postalCode: "6878" },
    { lng: -72.86294, lat: 41.671548, postalCode: "6062" },
    { lng: -72.72718, lat: 41.935501, postalCode: "6026" },
    { lng: -72.76831, lat: 41.907114, postalCode: "6081" },
    { lng: -72.967958, lat: 41.874382, postalCode: "6061" },
    { lng: -73.402714, lat: 41.234395, postalCode: "6993" },
    { lng: -72.92479, lat: 41.306197, postalCode: "6510" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6128" },
    { lng: -73.35178, lat: 41.140896, postalCode: "6880" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6131" },
    { lng: -73.49673, lat: 41.472227, postalCode: "6812" },
    { lng: -73.43657, lat: 41.586368, postalCode: "6748" },
    { lng: -72.26127, lat: 41.806041, postalCode: "6268" },
    { lng: -72.922235, lat: 41.550097, postalCode: "6411" },
    { lng: -72.02528, lat: 41.847302, postalCode: "6230" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6503" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6536" },
    { lng: -71.81845, lat: 41.730575, postalCode: "6377" },
    { lng: -72.94642, lat: 41.758415, postalCode: "6013" },
    { lng: -72.57085, lat: 41.707932, postalCode: "6033" },
    { lng: -73.49178, lat: 41.297911, postalCode: "6877" },
    { lng: -72.25445, lat: 41.485469, postalCode: "6420" },
    { lng: -73.11555, lat: 41.532992, postalCode: "6762" },
    { lng: -71.909019, lat: 41.725694, postalCode: "6332" },
    { lng: -72.628802, lat: 41.948597, postalCode: "6080" },
    { lng: -72.525208, lat: 41.538017, postalCode: "6456" },
    { lng: -72.25025, lat: 41.80743, postalCode: "6269" },
    { lng: -72.93673, lat: 41.295197, postalCode: "6519" },
    { lng: -73.46331, lat: 41.866222, postalCode: "6069" },
    { lng: -72.72017, lat: 41.612748, postalCode: "6023" },
    { lng: -73.23576, lat: 41.189646, postalCode: "6432" },
    { lng: -73.21056, lat: 41.693856, postalCode: "6763" },
    { lng: -73.12099, lat: 41.608196, postalCode: "6795" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6509" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6832" },
    { lng: -73.427915, lat: 41.25553, postalCode: "6829" },
    { lng: -71.949548, lat: 41.388216, postalCode: "6372" },
    { lng: -72.67889, lat: 41.7387, postalCode: "6114" },
    { lng: -71.91317, lat: 41.351307, postalCode: "6378" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6532" },
    { lng: -73.62985, lat: 41.03073, postalCode: "6830" },
    { lng: -72.73374, lat: 41.7313, postalCode: "6110" },
    { lng: -73.20004, lat: 41.178696, postalCode: "6604" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6724" },
    { lng: -72.92017, lat: 41.937513, postalCode: "6059" },
    { lng: -73.222981, lat: 41.739889, postalCode: "6758" },
    { lng: -72.76734, lat: 41.619048, postalCode: "6037" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6879" },
    { lng: -72.09891, lat: 41.693435, postalCode: "6264" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6134" },
    { lng: -71.889199, lat: 41.559873, postalCode: "6337" },
    { lng: -72.090357, lat: 41.39973, postalCode: "6349" },
    { lng: -71.85668, lat: 41.5787, postalCode: "6384" },
    { lng: -73.11835, lat: 42.027797, postalCode: "6021" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6875" },
    { lng: -71.941669, lat: 42.019776, postalCode: "6245" },
    { lng: -73.32437, lat: 41.644096, postalCode: "6794" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6836" },
    { lng: -73.04484, lat: 41.569496, postalCode: "6710" },
    { lng: -73.59508, lat: 41.047627, postalCode: "6807" },
    { lng: -72.57956, lat: 41.468654, postalCode: "6441" },
    { lng: -71.976467, lat: 41.344098, postalCode: "6388" },
    { lng: -73.21227, lat: 41.256129, postalCode: "6611" },
    { lng: -71.87098, lat: 41.71836, postalCode: "6354" },
    { lng: -72.90712, lat: 41.348297, postalCode: "6517" },
    { lng: -73.30816, lat: 41.557711, postalCode: "6783" },
    { lng: -72.87103, lat: 41.612298, postalCode: "6489" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6030" },
    { lng: -72.89696, lat: 41.575847, postalCode: "6479" },
    { lng: -72.50345, lat: 41.45307, postalCode: "6438" },
    { lng: -72.24215, lat: 41.618403, postalCode: "6249" },
    { lng: -72.67378, lat: 41.784794, postalCode: "6120" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6150" },
    { lng: -72.127301, lat: 41.464758, postalCode: "6386" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6127" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6144" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6534" },
    { lng: -71.945253, lat: 41.943902, postalCode: "6267" },
    { lng: -72.27863, lat: 41.979871, postalCode: "6076" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6440" },
    { lng: -71.88434, lat: 41.913816, postalCode: "6260" },
    { lng: -72.39787, lat: 41.351598, postalCode: "6426" },
    { lng: -72.308779, lat: 41.81152, postalCode: "6075" },
    { lng: -73.43758, lat: 41.201446, postalCode: "6897" },
    { lng: -72.257735, lat: 41.991645, postalCode: "6077" },
    { lng: -72.673037, lat: 41.763849, postalCode: "6183" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6928" },
    { lng: -73.146312, lat: 41.896321, postalCode: "6094" },
    { lng: -72.19551, lat: 41.464274, postalCode: "6370" },
    { lng: -73.39982, lat: 41.465811, postalCode: "6804" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6538" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6102" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6881" },
    { lng: -72.16028, lat: 41.670349, postalCode: "6266" },
    { lng: -73.42408, lat: 42.014673, postalCode: "6068" },
    { lng: -73.24774, lat: 41.721024, postalCode: "6750" },
    { lng: -73.52182, lat: 41.069298, postalCode: "6906" },
    { lng: -72.68733, lat: 41.461639, postalCode: "6422" },
    { lng: -73.53903, lat: 41.051996, postalCode: "6901" },
    { lng: -72.78145, lat: 41.532048, postalCode: "6450" },
    { lng: -72.06549, lat: 41.634556, postalCode: "6330" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6925" },
    { lng: -73.28946, lat: 41.633422, postalCode: "6793" },
    { lng: -72.127301, lat: 41.464758, postalCode: "6474" },
    { lng: -72.99145, lat: 41.669898, postalCode: "6781" },
    { lng: -73.238012, lat: 41.191685, postalCode: "6825" },
    { lng: -72.122672, lat: 41.559648, postalCode: "6389" },
    { lng: -72.90922, lat: 42.002345, postalCode: "6027" },
    { lng: -72.96496, lat: 41.272147, postalCode: "6516" },
    { lng: -73.538567, lat: 41.041197, postalCode: "6926" },
    { lng: -71.98233, lat: 41.449482, postalCode: "6339" },
    { lng: -71.981045, lat: 41.984327, postalCode: "6244" },
    { lng: -73.36092, lat: 41.52599, postalCode: "6752" },
    { lng: -73.3296, lat: 41.948694, postalCode: "6031" },
    { lng: -72.73101, lat: 41.688899, postalCode: "6111" },
    { lng: -71.968232, lat: 41.889048, postalCode: "6258" },
    { lng: -72.41604, lat: 41.353453, postalCode: "6409" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6927" },
    { lng: -73.0554, lat: 41.21953, postalCode: "6460" },
    { lng: -73.3435, lat: 41.697487, postalCode: "6777" },
    { lng: -72.900757, lat: 41.390625, postalCode: "6815" },
    { lng: -72.74853, lat: 41.791399, postalCode: "6117" },
    { lng: -72.558055, lat: 41.566668, postalCode: "6414" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6813" },
    { lng: -72.46708, lat: 41.841615, postalCode: "6066" },
    { lng: -72.36806, lat: 41.87688, postalCode: "6084" },
    { lng: -72.21064, lat: 41.319649, postalCode: "6357" },
    { lng: -72.20007, lat: 41.768455, postalCode: "6250" },
    { lng: -73.130503, lat: 41.207146, postalCode: "6497" },
    { lng: -73.24888, lat: 41.411536, postalCode: "6482" },
    { lng: -71.79952, lat: 41.678897, postalCode: "6373" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6180" },
    { lng: -73.420363, lat: 41.111196, postalCode: "6856" },
    { lng: -72.67433, lat: 41.767049, postalCode: "6103" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6508" },
    { lng: -72.06127, lat: 41.767929, postalCode: "6247" },
    { lng: -72.62313, lat: 41.778316, postalCode: "6108" },
    { lng: -72.20934, lat: 41.714867, postalCode: "6226" },
    { lng: -72.80129, lat: 41.659099, postalCode: "6052" },
    { lng: -72.76996, lat: 41.666249, postalCode: "6051" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6129" },
    { lng: -73.08543, lat: 41.592046, postalCode: "6779" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6506" },
    { lng: -73.288247, lat: 41.142754, postalCode: "6890" },
    { lng: -72.779901, lat: 41.305721, postalCode: "6505" },
    { lng: -71.94671, lat: 42.022904, postalCode: "6262" },
    { lng: -72.677099, lat: 41.78007, postalCode: "6101" },
    { lng: -72.81704, lat: 41.457711, postalCode: "6492" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6133" },
    { lng: -73.35774, lat: 41.786035, postalCode: "6754" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6699" },
    { lng: -72.27456, lat: 41.896875, postalCode: "6279" },
    { lng: -73.07446, lat: 41.34269, postalCode: "6401" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6720" },
    { lng: -73.363661, lat: 41.308873, postalCode: "6816" },
    { lng: -72.31008, lat: 41.339421, postalCode: "6371" },
    { lng: -73.495366, lat: 41.147096, postalCode: "6842" },
    { lng: -73.10942, lat: 41.694945, postalCode: "6778" },
    { lng: -71.96621, lat: 41.509586, postalCode: "6365" },
    { lng: -72.718832, lat: 41.791776, postalCode: "6141" },
    { lng: -72.927507, lat: 41.365709, postalCode: "6722" },
    { lng: -72.308779, lat: 41.81152, postalCode: "6265" },
    { lng: -77.02929, lat: 38.933311, postalCode: "20010" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20041" },
    { lng: -77.01254, lat: 38.894075, postalCode: "20534" },
    { lng: -77.004663, lat: 38.887405, postalCode: "20540" },
    { lng: -77.05535, lat: 38.897074, postalCode: "20566" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20337" },
    { lng: -77.01732, lat: 38.907711, postalCode: "20001" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20066" },
    { lng: -77.004663, lat: 38.887405, postalCode: "20557" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20409" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20299" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20222" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20090" },
    { lng: -76.97138, lat: 38.863079, postalCode: "20020" },
    { lng: -77.023592, lat: 38.893662, postalCode: "20576" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20398" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20204" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20505" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20270" },
    { lng: -77.025133, lat: 38.894097, postalCode: "20537" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20413" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20507" },
    { lng: -76.996605, lat: 38.872553, postalCode: "20388" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20070" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20391" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20510" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20289" },
    { lng: -77.047807, lat: 38.89449, postalCode: "20523" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20228" },
    { lng: -77.022098, lat: 38.883962, postalCode: "20411" },
    { lng: -77.020398, lat: 38.918011, postalCode: "20060" },
    { lng: -77.047399, lat: 38.902211, postalCode: "20586" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20043" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20558" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20470" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20088" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20390" },
    { lng: -77.036349, lat: 38.923861, postalCode: "20441" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20373" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20340" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20427" },
    { lng: -77.05723, lat: 38.904253, postalCode: "20418" },
    { lng: -77.022098, lat: 38.895261, postalCode: "20412" },
    { lng: -77.023118, lat: 38.887333, postalCode: "20553" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20260" },
    { lng: -77.040149, lat: 38.899061, postalCode: "20570" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20402" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20067" },
    { lng: -77.017668, lat: 38.895977, postalCode: "20442" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20572" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20468" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20078" },
    { lng: -77.032591, lat: 38.901102, postalCode: "20533" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20393" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20076" },
    { lng: -77.018798, lat: 38.876362, postalCode: "20460" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20403" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20071" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20330" },
    { lng: -77.02858, lat: 38.97808, postalCode: "20012" },
    { lng: -77.03777, lat: 38.919211, postalCode: "20009" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20318" },
    { lng: -76.99447, lat: 38.881762, postalCode: "20003" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20077" },
    { lng: -77.0276, lat: 38.895268, postalCode: "20004" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20229" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20221" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20538" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20215" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20597" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20081" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20407" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20405" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20075" },
    { lng: -77.045219, lat: 38.891995, postalCode: "20551" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20515" },
    { lng: -77.018853, lat: 38.891941, postalCode: "20565" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20578" },
    { lng: -77.025198, lat: 38.884712, postalCode: "20447" },
    { lng: -77.025133, lat: 38.894097, postalCode: "20535" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20307" },
    { lng: -77.039099, lat: 38.900561, postalCode: "20232" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20406" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20223" },
    { lng: -77.036198, lat: 38.89872, postalCode: "20501" },
    { lng: -77.016948, lat: 38.901211, postalCode: "20536" },
    { lng: -77.01424, lat: 38.866924, postalCode: "20319" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20069" },
    { lng: -77.04148, lat: 38.906778, postalCode: "20036" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20226" },
    { lng: -77.02362, lat: 38.880112, postalCode: "20024" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20051" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20029" },
    { lng: -76.99125, lat: 38.937478, postalCode: "20017" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20251" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20046" },
    { lng: -77.022098, lat: 38.883962, postalCode: "20590" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20261" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20419" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20508" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20033" },
    { lng: -77.046645, lat: 38.905317, postalCode: "20063" },
    { lng: -77.020959, lat: 38.901633, postalCode: "20055" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20593" },
    { lng: -77.032601, lat: 38.902414, postalCode: "20524" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20219" },
    { lng: -77.02097, lat: 38.95211, postalCode: "20011" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20080" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20415" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20211" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20061" },
    { lng: -77.043738, lat: 38.902217, postalCode: "20526" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20350" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20525" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20585" },
    { lng: -77.021848, lat: 38.893761, postalCode: "20531" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20099" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20575" },
    { lng: -77.025098, lat: 38.897011, postalCode: "20073" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20039" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20224" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20082" },
    { lng: -77.040319, lat: 38.899399, postalCode: "20435" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20091" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20217" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20220" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20380" },
    { lng: -77.048499, lat: 38.902761, postalCode: "20437" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20013" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20408" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20560" },
    { lng: -77.03088, lat: 38.904461, postalCode: "20005" },
    { lng: -77.047899, lat: 38.90014, postalCode: "20052" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20389" },
    { lng: -77.021068, lat: 38.895927, postalCode: "20436" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20097" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20050" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20068" },
    { lng: -77.041976, lat: 38.899996, postalCode: "20433" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20212" },
    { lng: -77.04195, lat: 38.897567, postalCode: "20006" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20268" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20395" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20310" },
    { lng: -77.03274, lat: 38.887323, postalCode: "20250" },
    { lng: -77.05126, lat: 38.900394, postalCode: "20037" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20230" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20231" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20201" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20206" },
    { lng: -77.02322, lat: 38.822713, postalCode: "20375" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20554" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20410" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20245" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20426" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20254" },
    { lng: -77.01299, lat: 38.847768, postalCode: "20336" },
    { lng: -77.034455, lat: 38.900758, postalCode: "20577" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20241" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20394" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20026" },
    { lng: -77.020998, lat: 38.895911, postalCode: "20049" },
    { lng: -77.07179, lat: 38.913961, postalCode: "20007" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20552" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20444" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20040" },
    { lng: -77.017698, lat: 38.898111, postalCode: "20548" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20306" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20035" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20038" },
    { lng: -77.0176, lat: 38.829932, postalCode: "20332" },
    { lng: -77.044449, lat: 38.897711, postalCode: "20451" },
    { lng: -77.040895, lat: 38.897143, postalCode: "20240" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20202" },
    { lng: -77.004663, lat: 38.887405, postalCode: "20541" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20074" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20057" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20058" },
    { lng: -77.057199, lat: 38.915361, postalCode: "20235" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20314" },
    { lng: -77.048957, lat: 38.893217, postalCode: "20520" },
    { lng: -77.036091, lat: 38.902933, postalCode: "20527" },
    { lng: -76.99097, lat: 38.901811, postalCode: "20002" },
    { lng: -77.00058, lat: 38.836145, postalCode: "20032" },
    { lng: -77.06737, lat: 38.96516, postalCode: "20015" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20543" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20469" },
    { lng: -77.008948, lat: 38.899161, postalCode: "20404" },
    { lng: -77.0319, lat: 38.896599, postalCode: "20045" },
    { lng: -77.031113, lat: 38.889357, postalCode: "20301" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20030" },
    { lng: -77.034554, lat: 38.900559, postalCode: "20571" },
    { lng: -77.014098, lat: 38.891911, postalCode: "20216" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20042" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20214" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20573" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20599" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20277" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20581" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20303" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20370" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20549" },
    { lng: -77.002197, lat: 38.855522, postalCode: "20374" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20056" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20233" },
    { lng: -77.011748, lat: 38.896611, postalCode: "20208" },
    { lng: -77.028948, lat: 38.867812, postalCode: "20242" },
    { lng: -76.93811, lat: 38.891445, postalCode: "20019" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20047" },
    { lng: -77.017291, lat: 38.904486, postalCode: "20532" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20416" },
    { lng: -77.043068, lat: 38.900711, postalCode: "20503" },
    { lng: -77.06039, lat: 38.934866, postalCode: "20008" },
    { lng: -77.046645, lat: 38.905317, postalCode: "20203" },
    { lng: -77.022098, lat: 38.883962, postalCode: "20414" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20244" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20266" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20265" },
    { lng: -76.97523, lat: 38.928761, postalCode: "20018" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20550" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20422" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20580" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20428" },
    { lng: -77.036198, lat: 38.89872, postalCode: "20502" },
    { lng: -77.040149, lat: 38.900011, postalCode: "20555" },
    { lng: -77.036122, lat: 38.894781, postalCode: "20500" },
    { lng: -77.017948, lat: 38.928861, postalCode: "20315" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20423" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20098" },
    { lng: -77.044649, lat: 38.904311, postalCode: "20579" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20521" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20439" },
    { lng: -77.026998, lat: 38.897561, postalCode: "20530" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20262" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20421" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20239" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20539" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20207" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20472" },
    { lng: -77.018372, lat: 38.88494, postalCode: "20594" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20392" },
    { lng: -77.036948, lat: 38.900009, postalCode: "20062" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20544" },
    { lng: -77.004663, lat: 38.887405, postalCode: "20559" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20429" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20372" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20218" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20434" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20591" },
    { lng: -77.048957, lat: 38.893217, postalCode: "20522" },
    { lng: -77.021092, lat: 38.890976, postalCode: "20546" },
    { lng: -77.028348, lat: 38.940761, postalCode: "20542" },
    { lng: -77.037668, lat: 38.899364, postalCode: "20506" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20547" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20053" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20059" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20463" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20401" },
    { lng: -77.040149, lat: 38.898111, postalCode: "20456" },
    { lng: -77.08723, lat: 38.94081, postalCode: "20016" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20213" },
    { lng: -77.028198, lat: 38.883412, postalCode: "20065" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20238" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20210" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20425" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20424" },
    { lng: -77.010298, lat: 38.931161, postalCode: "20317" },
    { lng: -77.027648, lat: 38.903461, postalCode: "20420" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20338" },
    { lng: -77.042753, lat: 38.898618, postalCode: "20431" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20453" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20504" },
    { lng: -76.996348, lat: 38.933161, postalCode: "20064" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20227" },
    { lng: -77.014647, lat: 38.893311, postalCode: "20044" },
    { lng: -77.045349, lat: 38.913911, postalCode: "20440" },
    { lng: -75.448289, lat: 39.166323, postalCode: "19961" },
    { lng: -75.596283, lat: 39.713625, postalCode: "19814" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19895" },
    { lng: -75.41449, lat: 38.922806, postalCode: "19963" },
    { lng: -75.39269, lat: 38.676552, postalCode: "19947" },
    { lng: -75.59204, lat: 39.573744, postalCode: "19706" },
    { lng: -75.59391, lat: 39.29799, postalCode: "19977" },
    { lng: -75.21465, lat: 38.557501, postalCode: "19939" },
    { lng: -75.59003, lat: 39.669219, postalCode: "19720" },
    { lng: -75.65976, lat: 39.456484, postalCode: "19730" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19712" },
    { lng: -75.28665, lat: 38.772648, postalCode: "19968" },
    { lng: -75.60966, lat: 38.818541, postalCode: "19950" },
    { lng: -75.6631, lat: 39.734279, postalCode: "19808" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19894" },
    { lng: -75.68586, lat: 39.784014, postalCode: "19707" },
    { lng: -75.46953, lat: 39.131958, postalCode: "19902" },
    { lng: -75.09677, lat: 38.711512, postalCode: "19971" },
    { lng: -75.61343, lat: 38.916908, postalCode: "19952" },
    { lng: -75.5587, lat: 39.161639, postalCode: "19904" },
    { lng: -75.71796, lat: 39.669211, postalCode: "19713" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19898" },
    { lng: -75.60256, lat: 39.787512, postalCode: "19807" },
    { lng: -75.5669, lat: 38.469655, postalCode: "19940" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19891" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19885" },
    { lng: -75.55304, lat: 38.549721, postalCode: "19956" },
    { lng: -75.590662, lat: 39.570322, postalCode: "19700" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19717" },
    { lng: -75.54833, lat: 39.738563, postalCode: "19801" },
    { lng: -75.60806, lat: 39.721062, postalCode: "19804" },
    { lng: -75.61102, lat: 38.643248, postalCode: "19973" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19887" },
    { lng: -75.56413, lat: 39.758563, postalCode: "19806" },
    { lng: -75.57907, lat: 38.868387, postalCode: "19942" },
    { lng: -75.353387, lat: 38.725562, postalCode: "19969" },
    { lng: -75.2411, lat: 38.601355, postalCode: "19966" },
    { lng: -75.7431, lat: 39.700561, postalCode: "19711" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19890" },
    { lng: -75.06062, lat: 38.536354, postalCode: "19930" },
    { lng: -75.57656, lat: 39.518164, postalCode: "19731" },
    { lng: -75.584848, lat: 39.218448, postalCode: "19936" },
    { lng: -75.16282, lat: 38.746207, postalCode: "19958" },
    { lng: -75.58978, lat: 39.011387, postalCode: "19943" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19716" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19884" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19896" },
    { lng: -75.66549, lat: 39.227313, postalCode: "19955" },
    { lng: -75.65058, lat: 39.555794, postalCode: "19733" },
    { lng: -75.49656, lat: 39.771663, postalCode: "19809" },
    { lng: -75.53401, lat: 39.793962, postalCode: "19803" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19715" },
    { lng: -75.05356, lat: 38.459314, postalCode: "19944" },
    { lng: -75.58881, lat: 39.788562, postalCode: "19710" },
    { lng: -75.69945, lat: 39.598203, postalCode: "19701" },
    { lng: -75.601491, lat: 39.567092, postalCode: "19766" },
    { lng: -75.11175, lat: 38.54597, postalCode: "19967" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19850" },
    { lng: -75.71386, lat: 39.626297, postalCode: "19702" },
    { lng: -75.53312, lat: 39.756213, postalCode: "19802" },
    { lng: -75.6932, lat: 39.479602, postalCode: "19709" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19726" },
    { lng: -75.39889, lat: 38.855872, postalCode: "19960" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19889" },
    { lng: -75.58251, lat: 39.745377, postalCode: "19805" },
    { lng: -75.57057, lat: 39.07027, postalCode: "19980" },
    { lng: -75.46634, lat: 39.037803, postalCode: "19946" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19893" },
    { lng: -75.6807, lat: 39.265066, postalCode: "19938" },
    { lng: -75.51264, lat: 38.909621, postalCode: "19954" },
    { lng: -75.50242, lat: 39.817645, postalCode: "19810" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19897" },
    { lng: -75.18364, lat: 38.511469, postalCode: "19945" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19721" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19886" },
    { lng: -75.448023, lat: 39.10868, postalCode: "19903" },
    { lng: -75.73943, lat: 39.098772, postalCode: "19964" },
    { lng: -75.70428, lat: 39.150822, postalCode: "19953" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19725" },
    { lng: -75.23286, lat: 38.682345, postalCode: "19951" },
    { lng: -75.57433, lat: 39.794496, postalCode: "19732" },
    { lng: -75.690069, lat: 39.577567, postalCode: "19708" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19718" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19714" },
    { lng: -75.05666, lat: 38.542771, postalCode: "19999" },
    { lng: -75.64934, lat: 39.790911, postalCode: "19736" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19880" },
    { lng: -75.46455, lat: 39.800945, postalCode: "19703" },
    { lng: -75.448023, lat: 39.10868, postalCode: "19905" },
    { lng: -75.597047, lat: 39.564499, postalCode: "19892" },
    { lng: -75.15642, lat: 38.463751, postalCode: "19975" },
    { lng: -75.60807, lat: 38.73635, postalCode: "19933" },
    { lng: -75.66801, lat: 39.386601, postalCode: "19734" },
    { lng: -75.49858, lat: 39.06517, postalCode: "19962" },
    { lng: -75.597598, lat: 39.794396, postalCode: "19735" },
    { lng: -75.42595, lat: 38.80136, postalCode: "19941" },
    { lng: -75.58871, lat: 39.094699, postalCode: "19934" },
    { lng: -75.09928, lat: 38.55044, postalCode: "19970" },
    { lng: -75.57185, lat: 39.0461, postalCode: "19979" },
    { lng: -75.6147, lat: 38.570238, postalCode: "19931" },
    { lng: -75.51163, lat: 39.16426, postalCode: "19901" },
    { lng: -75.624617, lat: 39.734792, postalCode: "19899" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32611" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32959" },
    { lng: -82.134741, lat: 28.029627, postalCode: "33564" },
    { lng: -80.14721, lat: 26.668944, postalCode: "33413" },
    { lng: -82.47363, lat: 27.285142, postalCode: "34233" },
    { lng: -84.50595, lat: 30.029899, postalCode: "32358" },
    { lng: -82.724763, lat: 27.891809, postalCode: "34697" },
    { lng: -81.90184, lat: 28.859765, postalCode: "34731" },
    { lng: -81.43029, lat: 26.760476, postalCode: "33935" },
    { lng: -81.600108, lat: 29.50199, postalCode: "32081" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33655" },
    { lng: -81.95625, lat: 28.128494, postalCode: "33809" },
    { lng: -81.42159, lat: 28.301788, postalCode: "34741" },
    { lng: -82.71145, lat: 27.861925, postalCode: "33782" },
    { lng: -81.88324, lat: 27.158612, postalCode: "34268" },
    { lng: -82.30358, lat: 29.896156, postalCode: "32622" },
    { lng: -81.605846, lat: 28.541579, postalCode: "34777" },
    { lng: -81.325842, lat: 28.567928, postalCode: "32813" },
    { lng: -84.97668, lat: 30.36007, postalCode: "32321" },
    { lng: -82.69698, lat: 28.30073, postalCode: "34668" },
    { lng: -82.23092, lat: 26.922089, postalCode: "33981" },
    { lng: -81.28151, lat: 28.683408, postalCode: "32708" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33242" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32889" },
    { lng: -82.62165, lat: 30.155229, postalCode: "32025" },
    { lng: -80.12874, lat: 26.609226, postalCode: "33463" },
    { lng: -82.45241, lat: 27.136731, postalCode: "34275" },
    { lng: -81.44369, lat: 28.576028, postalCode: "32808" },
    { lng: -82.2522, lat: 29.232873, postalCode: "34482" },
    { lng: -82.72965, lat: 29.231843, postalCode: "32639" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32175" },
    { lng: -82.19736, lat: 28.433019, postalCode: "33523" },
    { lng: -81.522998, lat: 27.885359, postalCode: "33859" },
    { lng: -80.21776, lat: 26.21606, postalCode: "33068" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33419" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32239" },
    { lng: -81.48694, lat: 24.663121, postalCode: "33042" },
    { lng: -82.06358, lat: 28.24625, postalCode: "33849" },
    { lng: -81.94968, lat: 26.687462, postalCode: "33909" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32170" },
    { lng: -84.25979, lat: 30.554434, postalCode: "32312" },
    { lng: -80.65425, lat: 26.817786, postalCode: "33476" },
    { lng: -82.82752, lat: 29.598935, postalCode: "32693" },
    { lng: -81.40089, lat: 28.53028, postalCode: "32805" },
    { lng: -81.29464, lat: 29.87131, postalCode: "32084" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33690" },
    { lng: -80.829442, lat: 27.227002, postalCode: "34924" },
    { lng: -81.40308, lat: 30.316641, postalCode: "32266" },
    { lng: -80.27874, lat: 25.832374, postalCode: "33010" },
    { lng: -82.27648, lat: 29.35089, postalCode: "32634" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33736" },
    { lng: -81.61872, lat: 28.021066, postalCode: "33838" },
    { lng: -82.28819, lat: 27.987925, postalCode: "33584" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33231" },
    { lng: -82.481766, lat: 28.324796, postalCode: "34680" },
    { lng: -81.402613, lat: 28.442926, postalCode: "32859" },
    { lng: -81.94861, lat: 26.552895, postalCode: "34133" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33283" },
    { lng: -82.104632, lat: 29.326821, postalCode: "32192" },
    { lng: -86.45335, lat: 30.500901, postalCode: "32578" },
    { lng: -82.00421, lat: 29.788679, postalCode: "32656" },
    { lng: -82.273409, lat: 27.764761, postalCode: "33503" },
    { lng: -82.21004, lat: 29.08091, postalCode: "34476" },
    { lng: -82.58493, lat: 27.575972, postalCode: "34250" },
    { lng: -81.420603, lat: 29.937673, postalCode: "32004" },
    { lng: -82.87062, lat: 29.764553, postalCode: "32619" },
    { lng: -80.79585, lat: 28.392933, postalCode: "32926" },
    { lng: -82.50632, lat: 28.006247, postalCode: "33614" },
    { lng: -81.93052, lat: 26.502677, postalCode: "33908" },
    { lng: -81.10296, lat: 29.284924, postalCode: "32174" },
    { lng: -84.38798, lat: 29.983931, postalCode: "32346" },
    { lng: -82.63751, lat: 27.796454, postalCode: "33704" },
    { lng: -84.346204, lat: 30.479347, postalCode: "32313" },
    { lng: -82.40701, lat: 27.574128, postalCode: "34219" },
    { lng: -81.21645, lat: 29.581683, postalCode: "32137" },
    { lng: -81.52228, lat: 30.296911, postalCode: "32246" },
    { lng: -81.406458, lat: 29.200508, postalCode: "32105" },
    { lng: -82.10394, lat: 29.171187, postalCode: "34471" },
    { lng: -82.12457, lat: 30.267664, postalCode: "32063" },
    { lng: -82.51776, lat: 29.282158, postalCode: "32668" },
    { lng: -81.47043, lat: 30.284511, postalCode: "32224" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32122" },
    { lng: -80.20566, lat: 25.851974, postalCode: "33150" },
    { lng: -82.4566, lat: 28.016846, postalCode: "33604" },
    { lng: -85.692035, lat: 30.234451, postalCode: "32406" },
    { lng: -81.95206, lat: 28.015032, postalCode: "33803" },
    { lng: -82.338911, lat: 27.894362, postalCode: "33659" },
    { lng: -81.63104, lat: 28.555328, postalCode: "34760" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33464" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33257" },
    { lng: -82.75633, lat: 27.883188, postalCode: "33773" },
    { lng: -80.15648, lat: 26.347711, postalCode: "33433" },
    { lng: -83.98882, lat: 30.357761, postalCode: "32361" },
    { lng: -81.32609, lat: 28.493531, postalCode: "32812" },
    { lng: -81.41112, lat: 28.663913, postalCode: "32714" },
    { lng: -81.1105, lat: 26.844989, postalCode: "33471" },
    { lng: -82.48659, lat: 27.365549, postalCode: "34235" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33499" },
    { lng: -80.856373, lat: 28.858941, postalCode: "32769" },
    { lng: -80.05676, lat: 26.619695, postalCode: "33460" },
    { lng: -82.69869, lat: 28.248558, postalCode: "34653" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33497" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33192" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33443" },
    { lng: -82.01033, lat: 28.106787, postalCode: "33810" },
    { lng: -80.14548, lat: 26.309757, postalCode: "33442" },
    { lng: -81.47514, lat: 28.463509, postalCode: "32819" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32794" },
    { lng: -82.176749, lat: 27.906823, postalCode: "33530" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33488" },
    { lng: -80.31775, lat: 25.799962, postalCode: "33122" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33737" },
    { lng: -80.25589, lat: 26.272608, postalCode: "33065" },
    { lng: -80.572557, lat: 27.709049, postalCode: "32971" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33090" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33402" },
    { lng: -80.22077, lat: 26.719596, postalCode: "33411" },
    { lng: -80.16195, lat: 25.700966, postalCode: "33149" },
    { lng: -82.38002, lat: 28.566183, postalCode: "34601" },
    { lng: -80.59472, lat: 27.762118, postalCode: "32948" },
    { lng: -81.909445, lat: 29.008805, postalCode: "32133" },
    { lng: -81.50174, lat: 29.008612, postalCode: "32767" },
    { lng: -82.3244, lat: 29.653195, postalCode: "32601" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32799" },
    { lng: -81.3931, lat: 28.577723, postalCode: "32804" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33863" },
    { lng: -81.389124, lat: 26.14326, postalCode: "34146" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33159" },
    { lng: -82.48872, lat: 29.801949, postalCode: "32615" },
    { lng: -82.47104, lat: 27.400627, postalCode: "34201" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32791" },
    { lng: -80.13085, lat: 25.814225, postalCode: "33140" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32783" },
    { lng: -81.94861, lat: 26.552895, postalCode: "33906" },
    { lng: -84.346204, lat: 30.479347, postalCode: "32314" },
    { lng: -82.53331, lat: 28.746912, postalCode: "34446" },
    { lng: -82.46911, lat: 27.936799, postalCode: "33606" },
    { lng: -80.11379, lat: 26.165212, postalCode: "33306" },
    { lng: -80.092864, lat: 26.665394, postalCode: "33416" },
    { lng: -81.41049, lat: 30.383023, postalCode: "32227" },
    { lng: -82.77834, lat: 28.088443, postalCode: "34681" },
    { lng: -80.08824, lat: 26.839588, postalCode: "33410" },
    { lng: -82.31601, lat: 28.187356, postalCode: "33543" },
    { lng: -82.49831, lat: 29.369207, postalCode: "32696" },
    { lng: -82.60064, lat: 29.817457, postalCode: "32655" },
    { lng: -81.60597, lat: 30.192434, postalCode: "32257" },
    { lng: -80.17941, lat: 25.95872, postalCode: "33179" },
    { lng: -80.2369, lat: 25.812625, postalCode: "33142" },
    { lng: -82.127196, lat: 27.048707, postalCode: "34288" },
    { lng: -81.70165, lat: 30.222683, postalCode: "32212" },
    { lng: -81.09078, lat: 28.511879, postalCode: "32833" },
    { lng: -82.20293, lat: 28.346914, postalCode: "33525" },
    { lng: -84.346204, lat: 30.479347, postalCode: "32316" },
    { lng: -82.38103, lat: 30.005135, postalCode: "32054" },
    { lng: -81.27863, lat: 26.742327, postalCode: "33975" },
    { lng: -81.87932, lat: 30.074926, postalCode: "32068" },
    { lng: -80.06043, lat: 26.711192, postalCode: "33401" },
    { lng: -82.1508, lat: 27.788498, postalCode: "33547" },
    { lng: -81.87009, lat: 26.566053, postalCode: "33907" },
    { lng: -80.27183, lat: 26.179495, postalCode: "33351" },
    { lng: -81.94861, lat: 26.552895, postalCode: "33965" },
    { lng: -80.09118, lat: 26.659294, postalCode: "33406" },
    { lng: -81.650667, lat: 28.545385, postalCode: "34740" },
    { lng: -81.82638, lat: 27.894991, postalCode: "33830" },
    { lng: -81.95251, lat: 26.63075, postalCode: "33990" },
    { lng: -82.49537, lat: 27.963798, postalCode: "33607" },
    { lng: -81.98278, lat: 28.654412, postalCode: "33514" },
    { lng: -80.11803, lat: 26.928035, postalCode: "33458" },
    { lng: -82.0915, lat: 26.536435, postalCode: "33956" },
    { lng: -81.984504, lat: 26.970756, postalCode: "33951" },
    { lng: -81.7186, lat: 26.256551, postalCode: "34119" },
    { lng: -81.5228, lat: 28.895482, postalCode: "32736" },
    { lng: -81.60274, lat: 30.092346, postalCode: "32259" },
    { lng: -81.569764, lat: 28.698542, postalCode: "32768" },
    { lng: -81.768964, lat: 30.105553, postalCode: "32030" },
    { lng: -81.47345, lat: 28.391608, postalCode: "32821" },
    { lng: -80.51382, lat: 25.431506, postalCode: "33034" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33675" },
    { lng: -82.26351, lat: 26.759514, postalCode: "33921" },
    { lng: -82.481766, lat: 28.324796, postalCode: "34674" },
    { lng: -80.572557, lat: 27.709049, postalCode: "32965" },
    { lng: -82.71094, lat: 27.839802, postalCode: "33781" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33114" },
    { lng: -82.52923, lat: 27.407657, postalCode: "34243" },
    { lng: -81.23654, lat: 28.965591, postalCode: "32706" },
    { lng: -81.95802, lat: 28.071564, postalCode: "33805" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33780" },
    { lng: -80.30839, lat: 25.76226, postalCode: "33144" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33197" },
    { lng: -82.45364, lat: 28.99409, postalCode: "34434" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33421" },
    { lng: -80.0585, lat: 26.669744, postalCode: "33405" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33688" },
    { lng: -81.88466, lat: 28.797245, postalCode: "34748" },
    { lng: -81.64433, lat: 29.556365, postalCode: "32189" },
    { lng: -82.78166, lat: 27.979114, postalCode: "33755" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33148" },
    { lng: -80.193619, lat: 25.865523, postalCode: "33153" },
    { lng: -81.70296, lat: 26.60599, postalCode: "33971" },
    { lng: -80.3182, lat: 25.938687, postalCode: "33015" },
    { lng: -84.20923, lat: 30.162414, postalCode: "32355" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32752" },
    { lng: -80.13341, lat: 26.182161, postalCode: "33334" },
    { lng: -82.28661, lat: 26.849473, postalCode: "33946" },
    { lng: -80.17987, lat: 26.094665, postalCode: "33312" },
    { lng: -80.572557, lat: 27.709049, postalCode: "32964" },
    { lng: -80.18753, lat: 25.784326, postalCode: "33132" },
    { lng: -83.13841, lat: 30.581517, postalCode: "32053" },
    { lng: -80.23576, lat: 25.766577, postalCode: "33135" },
    { lng: -80.12211, lat: 26.232009, postalCode: "33060" },
    { lng: -80.12231, lat: 26.018967, postalCode: "33019" },
    { lng: -81.73307, lat: 28.708139, postalCode: "34705" },
    { lng: -81.38213, lat: 30.189934, postalCode: "32082" },
    { lng: -81.56319, lat: 29.174417, postalCode: "32102" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33468" },
    { lng: -81.68034, lat: 28.850617, postalCode: "32726" },
    { lng: -80.4765, lat: 25.596234, postalCode: "33187" },
    { lng: -82.62737, lat: 27.488866, postalCode: "34209" },
    { lng: -82.15623, lat: 29.863141, postalCode: "32044" },
    { lng: -82.380967, lat: 27.167521, postalCode: "34284" },
    { lng: -82.00403, lat: 26.837879, postalCode: "33955" },
    { lng: -82.79321, lat: 27.811626, postalCode: "33708" },
    { lng: -81.38412, lat: 25.856771, postalCode: "34139" },
    { lng: -80.2773, lat: 25.94422, postalCode: "33055" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32214" },
    { lng: -80.092365, lat: 26.233459, postalCode: "33072" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33338" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32231" },
    { lng: -80.59957, lat: 28.172041, postalCode: "32937" },
    { lng: -82.35982, lat: 28.865761, postalCode: "34453" },
    { lng: -82.83937, lat: 27.924748, postalCode: "33786" },
    { lng: -80.30156, lat: 26.751094, postalCode: "33470" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32719" },
    { lng: -80.30069, lat: 25.864773, postalCode: "33012" },
    { lng: -80.36184, lat: 25.702429, postalCode: "33173" },
    { lng: -81.59087, lat: 30.364339, postalCode: "32277" },
    { lng: -81.7566, lat: 24.567593, postalCode: "33040" },
    { lng: -80.68591, lat: 27.958982, postalCode: "32908" },
    { lng: -80.25326, lat: 26.120714, postalCode: "33388" },
    { lng: -80.523463, lat: 27.924577, postalCode: "32941" },
    { lng: -82.82712, lat: 27.853151, postalCode: "33776" },
    { lng: -80.12931, lat: 26.101114, postalCode: "33316" },
    { lng: -81.51284, lat: 29.955409, postalCode: "32092" },
    { lng: -80.23773, lat: 25.850124, postalCode: "33147" },
    { lng: -81.13316, lat: 29.47033, postalCode: "32136" },
    { lng: -81.80502, lat: 30.292631, postalCode: "32221" },
    { lng: -80.51468, lat: 27.645377, postalCode: "32966" },
    { lng: -81.62373, lat: 28.044641, postalCode: "33851" },
    { lng: -82.36123, lat: 28.127973, postalCode: "33647" },
    { lng: -80.19782, lat: 25.779076, postalCode: "33101" },
    { lng: -82.74115, lat: 27.932797, postalCode: "33764" },
    { lng: -82.6449, lat: 27.843052, postalCode: "33702" },
    { lng: -82.50916, lat: 27.92085, postalCode: "33629" },
    { lng: -81.80991, lat: 29.979095, postalCode: "32079" },
    { lng: -82.09805, lat: 29.393366, postalCode: "32113" },
    { lng: -82.51663, lat: 27.267649, postalCode: "34231" },
    { lng: -82.5213, lat: 27.310924, postalCode: "34239" },
    { lng: -81.47863, lat: 28.528789, postalCode: "32835" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33238" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33359" },
    { lng: -82.07371, lat: 28.805331, postalCode: "33521" },
    { lng: -80.13187, lat: 26.121114, postalCode: "33301" },
    { lng: -81.28949, lat: 28.494931, postalCode: "32822" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33092" },
    { lng: -80.11319, lat: 26.279108, postalCode: "33064" },
    { lng: -81.0278, lat: 24.731758, postalCode: "33050" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33081" },
    { lng: -82.44846, lat: 27.091783, postalCode: "34285" },
    { lng: -80.3279, lat: 27.393097, postalCode: "34982" },
    { lng: -83.40303, lat: 30.476181, postalCode: "32340" },
    { lng: -81.74805, lat: 28.545742, postalCode: "34711" },
    { lng: -82.29019, lat: 28.829773, postalCode: "34450" },
    { lng: -81.865, lat: 26.711647, postalCode: "33917" },
    { lng: -81.31772, lat: 28.665125, postalCode: "32707" },
    { lng: -80.20942, lat: 25.832074, postalCode: "33151" },
    { lng: -80.16048, lat: 25.897372, postalCode: "33181" },
    { lng: -81.259332, lat: 27.995287, postalCode: "34742" },
    { lng: -82.11706, lat: 29.937512, postalCode: "32091" },
    { lng: -81.75531, lat: 27.98038, postalCode: "33839" },
    { lng: -81.744852, lat: 29.58203, postalCode: "32149" },
    { lng: -80.27202, lat: 26.062016, postalCode: "33328" },
    { lng: -82.21357, lat: 27.98706, postalCode: "33527" },
    { lng: -82.651184, lat: 30.162772, postalCode: "32056" },
    { lng: -82.11685, lat: 28.013755, postalCode: "33566" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33728" },
    { lng: -80.132569, lat: 26.628272, postalCode: "33465" },
    { lng: -82.34222, lat: 28.26658, postalCode: "33544" },
    { lng: -80.335973, lat: 26.089215, postalCode: "33335" },
    { lng: -80.42692, lat: 25.487988, postalCode: "33033" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33008" },
    { lng: -80.31267, lat: 27.163485, postalCode: "34990" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32774" },
    { lng: -80.20225, lat: 25.777143, postalCode: "33128" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33694" },
    { lng: -80.37248, lat: 27.676376, postalCode: "32963" },
    { lng: -80.57414, lat: 28.103191, postalCode: "32903" },
    { lng: -80.40467, lat: 25.781127, postalCode: "33182" },
    { lng: -81.63094, lat: 26.020283, postalCode: "34114" },
    { lng: -82.52355, lat: 28.07506, postalCode: "33624" },
    { lng: -81.18764, lat: 28.910383, postalCode: "32738" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33674" },
    { lng: -80.23277, lat: 27.248445, postalCode: "34957" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33239" },
    { lng: -82.267706, lat: 28.65503, postalCode: "34636" },
    { lng: -82.31799, lat: 27.847802, postalCode: "33569" },
    { lng: -82.54613, lat: 27.333014, postalCode: "34236" },
    { lng: -80.572557, lat: 27.709049, postalCode: "32978" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33307" },
    { lng: -80.30685, lat: 25.739011, postalCode: "33155" },
    { lng: -81.33352, lat: 29.021782, postalCode: "32720" },
    { lng: -82.60407, lat: 29.823616, postalCode: "32643" },
    { lng: -82.71365, lat: 27.90802, postalCode: "33760" },
    { lng: -80.1262, lat: 26.715596, postalCode: "33417" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33854" },
    { lng: -82.7201, lat: 29.031155, postalCode: "34498" },
    { lng: -81.670264, lat: 28.310679, postalCode: "33897" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32910" },
    { lng: -82.28449, lat: 28.751024, postalCode: "34436" },
    { lng: -80.34859, lat: 25.606583, postalCode: "33157" },
    { lng: -80.22376, lat: 26.113664, postalCode: "33317" },
    { lng: -80.3583, lat: 25.734828, postalCode: "33165" },
    { lng: -83.63082, lat: 30.28163, postalCode: "32357" },
    { lng: -80.24044, lat: 26.795367, postalCode: "33412" },
    { lng: -83.58119, lat: 30.080602, postalCode: "32347" },
    { lng: -82.315058, lat: 27.929731, postalCode: "33501" },
    { lng: -80.129816, lat: 26.289057, postalCode: "33084" },
    { lng: -80.32097, lat: 26.112924, postalCode: "33325" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33680" },
    { lng: -81.420603, lat: 29.937673, postalCode: "32085" },
    { lng: -80.10717, lat: 26.375954, postalCode: "33427" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33280" },
    { lng: -80.49632, lat: 25.526214, postalCode: "33031" },
    { lng: -80.144217, lat: 26.013368, postalCode: "33022" },
    { lng: -80.31641, lat: 25.637132, postalCode: "33158" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33439" },
    { lng: -80.187019, lat: 25.772876, postalCode: "33195" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32241" },
    { lng: -81.389124, lat: 26.14326, postalCode: "34106" },
    { lng: -82.05972, lat: 26.983094, postalCode: "33980" },
    { lng: -80.09443, lat: 26.709575, postalCode: "33409" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33743" },
    { lng: -81.620778, lat: 26.564718, postalCode: "33970" },
    { lng: -81.50319, lat: 28.658395, postalCode: "32703" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32795" },
    { lng: -82.01048, lat: 29.733375, postalCode: "32666" },
    { lng: -81.72758, lat: 28.092512, postalCode: "33850" },
    { lng: -80.09623, lat: 26.381304, postalCode: "33431" },
    { lng: -81.30987, lat: 29.811123, postalCode: "32086" },
    { lng: -80.131967, lat: 25.784526, postalCode: "33119" },
    { lng: -82.45723, lat: 27.956149, postalCode: "33602" },
    { lng: -86.71497, lat: 30.409902, postalCode: "32569" },
    { lng: -81.84418, lat: 26.6435, postalCode: "33916" },
    { lng: -82.54934, lat: 27.275225, postalCode: "34242" },
    { lng: -80.63676, lat: 24.91828, postalCode: "33036" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33448" },
    { lng: -81.568862, lat: 25.100957, postalCode: "33041" },
    { lng: -84.346204, lat: 30.479347, postalCode: "32395" },
    { lng: -81.259332, lat: 27.995287, postalCode: "34745" },
    { lng: -81.23495, lat: 28.985274, postalCode: "32744" },
    { lng: -82.27775, lat: 29.502067, postalCode: "32667" },
    { lng: -81.27927, lat: 29.039757, postalCode: "32724" },
    { lng: -80.89049, lat: 28.707648, postalCode: "32754" },
    { lng: -80.67167, lat: 28.073891, postalCode: "32904" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33243" },
    { lng: -81.44668, lat: 29.787831, postalCode: "32033" },
    { lng: -80.423083, lat: 27.617473, postalCode: "32961" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33256" },
    { lng: -80.07032, lat: 26.802139, postalCode: "33403" },
    { lng: -82.28621, lat: 29.648594, postalCode: "32641" },
    { lng: -82.39008, lat: 29.735832, postalCode: "32653" },
    { lng: -82.71645, lat: 27.980297, postalCode: "33759" },
    { lng: -80.39141, lat: 27.582151, postalCode: "32962" },
    { lng: -82.74549, lat: 28.001394, postalCode: "33763" },
    { lng: -82.37525, lat: 27.94605, postalCode: "33619" },
    { lng: -81.21026, lat: 28.656375, postalCode: "32765" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33677" },
    { lng: -84.346204, lat: 30.479347, postalCode: "32317" },
    { lng: -82.415275, lat: 28.156536, postalCode: "33559" },
    { lng: -80.43995, lat: 25.723173, postalCode: "33185" },
    { lng: -82.02658, lat: 28.991135, postalCode: "34491" },
    { lng: -81.10985, lat: 28.746588, postalCode: "32732" },
    { lng: -80.23264, lat: 25.885739, postalCode: "33167" },
    { lng: -81.8349, lat: 29.084013, postalCode: "32183" },
    { lng: -81.82529, lat: 27.815882, postalCode: "33847" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33474" },
    { lng: -80.27174, lat: 25.860373, postalCode: "33013" },
    { lng: -82.20022, lat: 28.234624, postalCode: "33541" },
    { lng: -80.08976, lat: 26.621145, postalCode: "33461" },
    { lng: -82.2158, lat: 29.425291, postalCode: "32681" },
    { lng: -82.35021, lat: 28.793722, postalCode: "34452" },
    { lng: -81.390498, lat: 28.77368, postalCode: "32745" },
    { lng: -82.422554, lat: 28.505896, postalCode: "34605" },
    { lng: -82.52324, lat: 27.448369, postalCode: "34203" },
    { lng: -81.631589, lat: 28.167211, postalCode: "33836" },
    { lng: -82.152341, lat: 28.182209, postalCode: "33524" },
    { lng: -80.262604, lat: 25.968737, postalCode: "33058" },
    { lng: -80.29589, lat: 26.165424, postalCode: "33345" },
    { lng: -80.69402, lat: 28.21981, postalCode: "32940" },
    { lng: -85.32792, lat: 29.846517, postalCode: "32456" },
    { lng: -80.2058, lat: 25.813808, postalCode: "33127" },
    { lng: -80.9973, lat: 26.714088, postalCode: "33440" },
    { lng: -80.61119, lat: 28.326985, postalCode: "32931" },
    { lng: -80.27034, lat: 25.753927, postalCode: "33134" },
    { lng: -80.13861, lat: 25.941535, postalCode: "33160" },
    { lng: -80.09541, lat: 26.309657, postalCode: "33441" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33447" },
    { lng: -80.44781, lat: 25.457913, postalCode: "33035" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33806" },
    { lng: -82.76055, lat: 30.366085, postalCode: "32096" },
    { lng: -80.81695, lat: 28.564077, postalCode: "32780" },
    { lng: -81.95134, lat: 26.958821, postalCode: "33982" },
    { lng: -82.77965, lat: 29.319173, postalCode: "32683" },
    { lng: -81.81255, lat: 30.230272, postalCode: "32222" },
    { lng: -82.50199, lat: 28.475662, postalCode: "34609" },
    { lng: -81.565319, lat: 24.664649, postalCode: "33044" },
    { lng: -80.27469, lat: 26.110631, postalCode: "33324" },
    { lng: -81.30353, lat: 28.425221, postalCode: "32827" },
    { lng: -82.41314, lat: 28.062961, postalCode: "33620" },
    { lng: -80.260071, lat: 26.129013, postalCode: "33337" },
    { lng: -80.10461, lat: 26.530144, postalCode: "33436" },
    { lng: -81.39628, lat: 27.052583, postalCode: "33960" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34282" },
    { lng: -82.63931, lat: 27.770955, postalCode: "33701" },
    { lng: -81.723417, lat: 28.023115, postalCode: "33888" },
    { lng: -81.6374, lat: 30.294389, postalCode: "32207" },
    { lng: -82.61937, lat: 28.348507, postalCode: "34669" },
    { lng: -81.674147, lat: 28.855517, postalCode: "32727" },
    { lng: -80.41197, lat: 25.733204, postalCode: "33175" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34264" },
    { lng: -81.33992, lat: 28.758133, postalCode: "32746" },
    { lng: -81.357154, lat: 27.798022, postalCode: "33855" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33164" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32716" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33730" },
    { lng: -81.73184, lat: 28.799017, postalCode: "32778" },
    { lng: -80.0994, lat: 26.968795, postalCode: "33469" },
    { lng: -82.66973, lat: 28.209833, postalCode: "34655" },
    { lng: -80.475479, lat: 25.455229, postalCode: "33003" },
    { lng: -81.61052, lat: 29.385225, postalCode: "32139" },
    { lng: -82.00674, lat: 26.634338, postalCode: "33991" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32790" },
    { lng: -81.815115, lat: 29.956068, postalCode: "32067" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32912" },
    { lng: -83.578106, lat: 30.100578, postalCode: "32349" },
    { lng: -82.60593, lat: 28.031088, postalCode: "33635" },
    { lng: -82.156884, lat: 27.08464, postalCode: "34289" },
    { lng: -82.94876, lat: 30.491893, postalCode: "32052" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32815" },
    { lng: -80.312873, lat: 25.795476, postalCode: "33152" },
    { lng: -81.89262, lat: 29.003387, postalCode: "32195" },
    { lng: -81.35515, lat: 27.436826, postalCode: "33876" },
    { lng: -82.7129, lat: 27.499982, postalCode: "34217" },
    { lng: -85.04477, lat: 30.060351, postalCode: "32335" },
    { lng: -82.57646, lat: 27.438719, postalCode: "34207" },
    { lng: -82.66603, lat: 28.372441, postalCode: "34667" },
    { lng: -85.692035, lat: 30.234451, postalCode: "32412" },
    { lng: -82.087458, lat: 29.240728, postalCode: "34477" },
    { lng: -81.41711, lat: 28.704874, postalCode: "32779" },
    { lng: -81.82841, lat: 26.501582, postalCode: "33912" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33740" },
    { lng: -80.650341, lat: 28.067128, postalCode: "32906" },
    { lng: -81.259332, lat: 27.995287, postalCode: "34770" },
    { lng: -81.63073, lat: 30.159091, postalCode: "32223" },
    { lng: -81.35384, lat: 28.703674, postalCode: "32750" },
    { lng: -82.47641, lat: 28.932403, postalCode: "34465" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33784" },
    { lng: -81.75251, lat: 26.121546, postalCode: "34112" },
    { lng: -84.77767, lat: 30.381958, postalCode: "32334" },
    { lng: -80.53518, lat: 28.01514, postalCode: "32951" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32633" },
    { lng: -84.18841, lat: 30.403146, postalCode: "32311" },
    { lng: -80.22016, lat: 26.067966, postalCode: "33314" },
    { lng: -81.34359, lat: 24.675731, postalCode: "33043" },
    { lng: -82.36889, lat: 29.676006, postalCode: "32605" },
    { lng: -80.13907, lat: 25.759474, postalCode: "33109" },
    { lng: -81.97697, lat: 27.884256, postalCode: "33860" },
    { lng: -82.212649, lat: 29.342404, postalCode: "32663" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33550" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32877" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33663" },
    { lng: -80.14126, lat: 25.784276, postalCode: "33139" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32891" },
    { lng: -80.87315, lat: 28.76507, postalCode: "32775" },
    { lng: -84.483258, lat: 30.496047, postalCode: "32342" },
    { lng: -80.25822, lat: 26.162462, postalCode: "33320" },
    { lng: -81.867153, lat: 27.964651, postalCode: "33846" },
    { lng: -81.83134, lat: 26.348608, postalCode: "34134" },
    { lng: -83.1148, lat: 29.630964, postalCode: "32628" },
    { lng: -81.43994, lat: 28.52493, postalCode: "32811" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33662" },
    { lng: -80.08226, lat: 26.529581, postalCode: "33426" },
    { lng: -80.911, lat: 28.982714, postalCode: "32132" },
    { lng: -81.93806, lat: 28.930573, postalCode: "32159" },
    { lng: -81.2456, lat: 28.543248, postalCode: "32825" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32869" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32932" },
    { lng: -80.40773, lat: 25.759184, postalCode: "33184" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32887" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32782" },
    { lng: -81.653642, lat: 28.811078, postalCode: "32756" },
    { lng: -81.5415, lat: 28.084383, postalCode: "33840" },
    { lng: -81.252872, lat: 27.339483, postalCode: "33826" },
    { lng: -82.514615, lat: 28.157704, postalCode: "33558" },
    { lng: -80.29691, lat: 27.291694, postalCode: "34952" },
    { lng: -81.77904, lat: 30.133007, postalCode: "32065" },
    { lng: -82.94481, lat: 30.039096, postalCode: "32071" },
    { lng: -81.48731, lat: 28.178192, postalCode: "34758" },
    { lng: -83.840177, lat: 30.342161, postalCode: "32345" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33233" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32237" },
    { lng: -80.828991, lat: 28.427535, postalCode: "32923" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33075" },
    { lng: -80.08119, lat: 26.924709, postalCode: "33477" },
    { lng: -82.21351, lat: 30.272356, postalCode: "32040" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33077" },
    { lng: -82.3469, lat: 29.653145, postalCode: "32603" },
    { lng: -81.71329, lat: 25.940112, postalCode: "34145" },
    { lng: -82.41676, lat: 27.272873, postalCode: "34241" },
    { lng: -81.505894, lat: 28.385549, postalCode: "32830" },
    { lng: -81.668593, lat: 29.644306, postalCode: "32178" },
    { lng: -80.40234, lat: 25.595983, postalCode: "33177" },
    { lng: -80.21452, lat: 25.94497, postalCode: "33163" },
    { lng: -81.19664, lat: 27.768567, postalCode: "33867" },
    { lng: -81.41182, lat: 28.385906, postalCode: "32837" },
    { lng: -80.52879, lat: 27.929103, postalCode: "32949" },
    { lng: -82.00841, lat: 27.978316, postalCode: "33811" },
    { lng: -83.27604, lat: 29.458513, postalCode: "32648" },
    { lng: -80.18922, lat: 26.023634, postalCode: "33021" },
    { lng: -81.68256, lat: 27.997387, postalCode: "33884" },
    { lng: -82.61782, lat: 28.295714, postalCode: "34654" },
    { lng: -82.55981, lat: 27.549314, postalCode: "34221" },
    { lng: -82.64475, lat: 27.396931, postalCode: "34228" },
    { lng: -82.39658, lat: 29.646189, postalCode: "32607" },
    { lng: -82.608795, lat: 28.916441, postalCode: "34424" },
    { lng: -81.92095, lat: 29.198564, postalCode: "34488" },
    { lng: -82.481766, lat: 28.324796, postalCode: "33593" },
    { lng: -80.12646, lat: 26.137693, postalCode: "33304" },
    { lng: -80.71827, lat: 26.663097, postalCode: "33493" },
    { lng: -81.17653, lat: 28.395979, postalCode: "32832" },
    { lng: -80.31408, lat: 26.156854, postalCode: "33323" },
    { lng: -83.21849, lat: 30.084849, postalCode: "32066" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32129" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33093" },
    { lng: -81.42695, lat: 28.619445, postalCode: "32810" },
    { lng: -81.389124, lat: 26.14326, postalCode: "34140" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33199" },
    { lng: -81.95858, lat: 29.740034, postalCode: "32185" },
    { lng: -80.06517, lat: 26.465051, postalCode: "33483" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33586" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32245" },
    { lng: -82.406476, lat: 28.948753, postalCode: "34445" },
    { lng: -85.692035, lat: 30.234451, postalCode: "32402" },
    { lng: -83.29266, lat: 30.193758, postalCode: "32013" },
    { lng: -80.6691, lat: 28.350373, postalCode: "32952" },
    { lng: -81.61163, lat: 26.588982, postalCode: "33936" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32897" },
    { lng: -81.35244, lat: 28.557579, postalCode: "32803" },
    { lng: -82.808642, lat: 29.622568, postalCode: "32638" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33747" },
    { lng: -82.098418, lat: 26.993904, postalCode: "33949" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32857" },
    { lng: -83.88017, lat: 30.530854, postalCode: "32344" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33188" },
    { lng: -82.41502, lat: 29.681426, postalCode: "32606" },
    { lng: -81.882373, lat: 26.596286, postalCode: "33911" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32173" },
    { lng: -80.4651, lat: 25.745549, postalCode: "33194" },
    { lng: -82.69511, lat: 28.005639, postalCode: "34695" },
    { lng: -81.38814, lat: 28.463482, postalCode: "32809" },
    { lng: -84.6147, lat: 30.573563, postalCode: "32351" },
    { lng: -80.30688, lat: 25.66558, postalCode: "33156" },
    { lng: -82.72818, lat: 28.237582, postalCode: "34652" },
    { lng: -81.0951, lat: 27.938975, postalCode: "34739" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32236" },
    { lng: -81.38719, lat: 25.855534, postalCode: "34101" },
    { lng: -82.6882, lat: 27.750655, postalCode: "33711" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32126" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32232" },
    { lng: -82.61135, lat: 28.052799, postalCode: "33626" },
    { lng: -80.89524, lat: 28.944915, postalCode: "32141" },
    { lng: -80.14697, lat: 27.076516, postalCode: "33455" },
    { lng: -82.181913, lat: 28.238489, postalCode: "33542" },
    { lng: -82.14539, lat: 27.982949, postalCode: "33567" },
    { lng: -81.98364, lat: 30.226463, postalCode: "32234" },
    { lng: -80.07891, lat: 26.460951, postalCode: "33444" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33883" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33459" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33265" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32240" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34206" },
    { lng: -81.93703, lat: 27.432764, postalCode: "33865" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33348" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32627" },
    { lng: -81.48535, lat: 27.475288, postalCode: "33872" },
    { lng: -81.83636, lat: 29.401523, postalCode: "32134" },
    { lng: -80.16699, lat: 26.382408, postalCode: "33434" },
    { lng: -82.0816, lat: 29.12295, postalCode: "34480" },
    { lng: -80.99418, lat: 28.513011, postalCode: "32709" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33116" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33247" },
    { lng: -80.1893, lat: 25.767368, postalCode: "33131" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33454" },
    { lng: -81.95741, lat: 30.421425, postalCode: "32009" },
    { lng: -81.282815, lat: 29.466085, postalCode: "32135" },
    { lng: -82.75339, lat: 28.149691, postalCode: "34689" },
    { lng: -82.40211, lat: 27.434755, postalCode: "34202" },
    { lng: -84.75363, lat: 30.665715, postalCode: "32352" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32230" },
    { lng: -80.27255, lat: 25.720728, postalCode: "33146" },
    { lng: -80.48057, lat: 27.049788, postalCode: "34956" },
    { lng: -82.3665, lat: 26.974858, postalCode: "34223" },
    { lng: -80.87555, lat: 27.39171, postalCode: "34972" },
    { lng: -82.41481, lat: 27.098474, postalCode: "34292" },
    { lng: -81.55686, lat: 29.425693, postalCode: "32112" },
    { lng: -82.709871, lat: 27.499515, postalCode: "34218" },
    { lng: -82.17027, lat: 29.166186, postalCode: "34474" },
    { lng: -80.36247, lat: 27.448688, postalCode: "34947" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34280" },
    { lng: -80.3467, lat: 25.574834, postalCode: "33189" },
    { lng: -80.409024, lat: 27.382196, postalCode: "34948" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33422" },
    { lng: -82.14818, lat: 28.835261, postalCode: "33538" },
    { lng: -82.097163, lat: 29.215153, postalCode: "34489" },
    { lng: -81.52479, lat: 28.543429, postalCode: "34734" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33039" },
    { lng: -81.52551, lat: 30.453405, postalCode: "32226" },
    { lng: -82.7553, lat: 27.906088, postalCode: "33771" },
    { lng: -80.15816, lat: 26.22876, postalCode: "33069" },
    { lng: -81.76663, lat: 26.240637, postalCode: "34109" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33744" },
    { lng: -83.391393, lat: 30.47756, postalCode: "32341" },
    { lng: -81.52824, lat: 27.894622, postalCode: "33853" },
    { lng: -81.26436, lat: 28.491653, postalCode: "32829" },
    { lng: -81.53291, lat: 27.826864, postalCode: "33827" },
    { lng: -82.0321, lat: 28.852564, postalCode: "34785" },
    { lng: -82.01319, lat: 26.681122, postalCode: "33993" },
    { lng: -82.39371, lat: 28.039096, postalCode: "33617" },
    { lng: -81.39844, lat: 30.284641, postalCode: "32250" },
    { lng: -80.21143, lat: 27.195927, postalCode: "34996" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33679" },
    { lng: -82.35965, lat: 27.713353, postalCode: "33573" },
    { lng: -82.59741, lat: 28.470236, postalCode: "34606" },
    { lng: -80.36947, lat: 25.837696, postalCode: "33178" },
    { lng: -82.10092, lat: 29.315961, postalCode: "32617" },
    { lng: -81.61684, lat: 26.711362, postalCode: "33920" },
    { lng: -80.98175, lat: 29.116361, postalCode: "32127" },
    { lng: -82.53084, lat: 28.397791, postalCode: "34610" },
    { lng: -80.29642, lat: 25.702929, postalCode: "33143" },
    { lng: -80.13916, lat: 26.121364, postalCode: "33394" },
    { lng: -83.8239, lat: 30.416739, postalCode: "32336" },
    { lng: -80.095165, lat: 26.196911, postalCode: "33303" },
    { lng: -82.3973, lat: 27.055013, postalCode: "34293" },
    { lng: -80.07437, lat: 26.579714, postalCode: "33462" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33673" },
    { lng: -82.68022, lat: 29.07664, postalCode: "34449" },
    { lng: -81.35898, lat: 28.410872, postalCode: "32824" },
    { lng: -81.504661, lat: 26.464183, postalCode: "34143" },
    { lng: -80.84644, lat: 27.1944, postalCode: "34974" },
    { lng: -80.24962, lat: 25.946754, postalCode: "33056" },
    { lng: -80.16052, lat: 26.877984, postalCode: "33418" },
    { lng: -82.44989, lat: 28.077994, postalCode: "33613" },
    { lng: -82.000005, lat: 26.901981, postalCode: "33938" },
    { lng: -81.94861, lat: 26.552895, postalCode: "33910" },
    { lng: -81.72457, lat: 28.051985, postalCode: "33881" },
    { lng: -80.40885, lat: 26.049367, postalCode: "33332" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33017" },
    { lng: -81.653642, lat: 28.811078, postalCode: "34729" },
    { lng: -82.087458, lat: 29.240728, postalCode: "34483" },
    { lng: -82.724763, lat: 27.891809, postalCode: "34688" },
    { lng: -81.64043, lat: 29.046215, postalCode: "32702" },
    { lng: -80.19046, lat: 25.817325, postalCode: "33137" },
    { lng: -84.88355, lat: 30.184365, postalCode: "32360" },
    { lng: -82.55181, lat: 28.484316, postalCode: "34608" },
    { lng: -82.133869, lat: 28.801617, postalCode: "33528" },
    { lng: -81.282815, lat: 29.466085, postalCode: "32151" },
    { lng: -82.29748, lat: 27.953801, postalCode: "33510" },
    { lng: -81.45095, lat: 29.231366, postalCode: "32180" },
    { lng: -80.224613, lat: 27.242402, postalCode: "34958" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33630" },
    { lng: -80.21276, lat: 25.94267, postalCode: "33169" },
    { lng: -81.732139, lat: 28.029402, postalCode: "33882" },
    { lng: -80.40212, lat: 26.006373, postalCode: "33029" },
    { lng: -82.68039, lat: 27.787904, postalCode: "33713" },
    { lng: -80.53139, lat: 25.006349, postalCode: "33070" },
    { lng: -81.52359, lat: 28.713173, postalCode: "32712" },
    { lng: -81.56882, lat: 30.148209, postalCode: "32258" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33661" },
    { lng: -81.407884, lat: 27.485803, postalCode: "33871" },
    { lng: -82.79594, lat: 27.885344, postalCode: "33778" },
    { lng: -83.02243, lat: 29.178171, postalCode: "32625" },
    { lng: -81.80267, lat: 28.068865, postalCode: "33823" },
    { lng: -82.36289, lat: 28.038324, postalCode: "33637" },
    { lng: -82.46629, lat: 28.142694, postalCode: "33549" },
    { lng: -82.371747, lat: 28.566148, postalCode: "34801" },
    { lng: -83.65112, lat: 30.442203, postalCode: "32331" },
    { lng: -81.985185, lat: 28.020992, postalCode: "33802" },
    { lng: -82.72751, lat: 28.081325, postalCode: "34684" },
    { lng: -81.250236, lat: 28.80722, postalCode: "32772" },
    { lng: -80.06528, lat: 26.782114, postalCode: "33404" },
    { lng: -82.72696, lat: 28.190181, postalCode: "34690" },
    { lng: -84.35533, lat: 30.194939, postalCode: "32327" },
    { lng: -80.49708, lat: 27.724801, postalCode: "32967" },
    { lng: -81.61743, lat: 27.982891, postalCode: "33877" },
    { lng: -80.48266, lat: 27.78783, postalCode: "32958" },
    { lng: -81.48608, lat: 28.583103, postalCode: "32818" },
    { lng: -84.31528, lat: 30.486061, postalCode: "32303" },
    { lng: -80.251988, lat: 26.11842, postalCode: "33318" },
    { lng: -82.43601, lat: 30.210947, postalCode: "32072" },
    { lng: -82.32758, lat: 27.693924, postalCode: "33598" },
    { lng: -80.60014, lat: 28.031689, postalCode: "32905" },
    { lng: -80.06321, lat: 26.524549, postalCode: "33435" },
    { lng: -82.26039, lat: 30.390734, postalCode: "32087" },
    { lng: -82.6396, lat: 30.216073, postalCode: "32055" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32614" },
    { lng: -81.30512, lat: 28.945857, postalCode: "32763" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33568" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33681" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32853" },
    { lng: -82.68389, lat: 27.883118, postalCode: "33762" },
    { lng: -80.70198, lat: 28.396301, postalCode: "32953" },
    { lng: -81.93061, lat: 30.685319, postalCode: "32046" },
    { lng: -80.15004, lat: 25.987069, postalCode: "33009" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32868" },
    { lng: -80.23607, lat: 25.782176, postalCode: "33125" },
    { lng: -80.35919, lat: 25.763044, postalCode: "33174" },
    { lng: -82.29693, lat: 26.925265, postalCode: "34224" },
    { lng: -81.80865, lat: 30.329588, postalCode: "32220" },
    { lng: -81.359902, lat: 25.81528, postalCode: "34168" },
    { lng: -80.21016, lat: 26.251909, postalCode: "33063" },
    { lng: -81.7845, lat: 28.857425, postalCode: "34788" },
    { lng: -80.48687, lat: 25.479438, postalCode: "33030" },
    { lng: -81.9178, lat: 26.698526, postalCode: "33903" },
    { lng: -80.21824, lat: 26.391346, postalCode: "33498" },
    { lng: -80.37697, lat: 26.112366, postalCode: "33326" },
    { lng: -80.20339, lat: 25.768277, postalCode: "33130" },
    { lng: -82.22371, lat: 30.070341, postalCode: "32083" },
    { lng: -82.252917, lat: 28.331186, postalCode: "33578" },
    { lng: -84.02083, lat: 30.478502, postalCode: "32337" },
    { lng: -84.29855, lat: 30.442499, postalCode: "32306" },
    { lng: -81.70621, lat: 28.948549, postalCode: "32784" },
    { lng: -82.52181, lat: 28.632101, postalCode: "34614" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33769" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32125" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33766" },
    { lng: -80.34727, lat: 27.273272, postalCode: "34984" },
    { lng: -82.55734, lat: 28.069755, postalCode: "33625" },
    { lng: -82.396567, lat: 29.629887, postalCode: "32602" },
    { lng: -81.436859, lat: 30.38239, postalCode: "32228" },
    { lng: -82.14859, lat: 26.984486, postalCode: "33948" },
    { lng: -82.112278, lat: 29.187178, postalCode: "34478" },
    { lng: -81.10735, lat: 28.561242, postalCode: "32820" },
    { lng: -82.766173, lat: 28.126307, postalCode: "34869" },
    { lng: -83.42088, lat: 29.900822, postalCode: "32356" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33111" },
    { lng: -81.680806, lat: 30.389438, postalCode: "32201" },
    { lng: -80.1276, lat: 25.881391, postalCode: "33154" },
    { lng: -82.49351, lat: 29.790674, postalCode: "32616" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33686" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32728" },
    { lng: -82.50337, lat: 30.101542, postalCode: "32061" },
    { lng: -80.61987, lat: 28.077494, postalCode: "32901" },
    { lng: -80.40947, lat: 25.662292, postalCode: "33186" },
    { lng: -80.673356, lat: 28.22571, postalCode: "32954" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32860" },
    { lng: -80.46447, lat: 27.273586, postalCode: "34987" },
    { lng: -82.1606, lat: 27.072445, postalCode: "34286" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32899" },
    { lng: -84.32394, lat: 30.446766, postalCode: "32304" },
    { lng: -81.420092, lat: 28.548379, postalCode: "32856" },
    { lng: -82.29837, lat: 28.077843, postalCode: "33592" },
    { lng: -81.91035, lat: 28.735088, postalCode: "34762" },
    { lng: -80.421607, lat: 27.718868, postalCode: "32977" },
    { lng: -81.857683, lat: 26.629875, postalCode: "33961" },
    { lng: -80.22446, lat: 26.148613, postalCode: "33313" },
    { lng: -82.12225, lat: 27.02442, postalCode: "33954" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33583" },
    { lng: -83.32545, lat: 30.594145, postalCode: "32350" },
    { lng: -81.045962, lat: 29.080251, postalCode: "32128" },
    { lng: -80.67729, lat: 28.012241, postalCode: "32907" },
    { lng: -80.454196, lat: 27.110182, postalCode: "34991" },
    { lng: -81.590249, lat: 30.6233, postalCode: "32041" },
    { lng: -81.1411, lat: 27.397067, postalCode: "33857" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32936" },
    { lng: -81.883648, lat: 26.623908, postalCode: "33902" },
    { lng: -81.35594, lat: 28.598677, postalCode: "32789" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33425" },
    { lng: -81.8127, lat: 27.541676, postalCode: "33873" },
    { lng: -82.98732, lat: 30.13762, postalCode: "32062" },
    { lng: -81.58897, lat: 28.326187, postalCode: "34747" },
    { lng: -82.15378, lat: 29.212886, postalCode: "34475" },
    { lng: -82.39845, lat: 29.845122, postalCode: "32658" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33664" },
    { lng: -82.097095, lat: 29.730413, postalCode: "32631" },
    { lng: -82.71764, lat: 27.756505, postalCode: "33707" },
    { lng: -81.72528, lat: 27.485551, postalCode: "33890" },
    { lng: -81.57009, lat: 29.589748, postalCode: "32187" },
    { lng: -80.052663, lat: 26.590609, postalCode: "33452" },
    { lng: -80.07296, lat: 26.750991, postalCode: "33407" },
    { lng: -82.2948, lat: 27.911201, postalCode: "33511" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33082" },
    { lng: -80.64679, lat: 27.950975, postalCode: "32909" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33481" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33355" },
    { lng: -80.15031, lat: 26.522249, postalCode: "33437" },
    { lng: -81.72044, lat: 30.307439, postalCode: "32205" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32739" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32612" },
    { lng: -80.14526, lat: 26.051916, postalCode: "33004" },
    { lng: -81.36291, lat: 25.813786, postalCode: "34138" },
    { lng: -82.772483, lat: 27.839698, postalCode: "33779" },
    { lng: -81.30203, lat: 29.445826, postalCode: "32110" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33234" },
    { lng: -82.777757, lat: 28.061727, postalCode: "34678" },
    { lng: -81.653642, lat: 28.811078, postalCode: "34789" },
    { lng: -82.64349, lat: 27.741306, postalCode: "33705" },
    { lng: -84.6407, lat: 29.912584, postalCode: "32322" },
    { lng: -82.69532, lat: 28.099485, postalCode: "34685" },
    { lng: -82.269323, lat: 28.334752, postalCode: "33574" },
    { lng: -81.505211, lat: 28.050121, postalCode: "33858" },
    { lng: -81.72916, lat: 30.160965, postalCode: "32073" },
    { lng: -82.72596, lat: 28.032827, postalCode: "33761" },
    { lng: -82.481766, lat: 28.324796, postalCode: "34673" },
    { lng: -82.26051, lat: 28.643141, postalCode: "34661" },
    { lng: -80.66279, lat: 26.685125, postalCode: "33430" },
    { lng: -82.64515, lat: 27.874324, postalCode: "33716" },
    { lng: -81.18634, lat: 28.584177, postalCode: "32826" },
    { lng: -82.62795, lat: 27.816977, postalCode: "33703" },
    { lng: -80.2441, lat: 25.730678, postalCode: "33133" },
    { lng: -82.611304, lat: 29.636632, postalCode: "32699" },
    { lng: -80.23851, lat: 26.023567, postalCode: "33024" },
    { lng: -82.438918, lat: 27.085985, postalCode: "34295" },
    { lng: -81.85602, lat: 29.765329, postalCode: "32140" },
    { lng: -80.26564, lat: 26.242559, postalCode: "33071" },
    { lng: -82.007115, lat: 26.871443, postalCode: "33599" },
    { lng: -82.50581, lat: 27.891151, postalCode: "33611" },
    { lng: -81.299521, lat: 28.997288, postalCode: "32721" },
    { lng: -81.59005, lat: 29.668112, postalCode: "32131" },
    { lng: -82.72244, lat: 27.788637, postalCode: "33710" },
    { lng: -81.80743, lat: 29.634649, postalCode: "32147" },
    { lng: -81.653642, lat: 28.811078, postalCode: "34749" },
    { lng: -81.990729, lat: 29.768321, postalCode: "32160" },
    { lng: -80.25225, lat: 27.195291, postalCode: "34994" },
    { lng: -81.831883, lat: 28.578234, postalCode: "34755" },
    { lng: -82.82504, lat: 27.982722, postalCode: "33767" },
    { lng: -81.72509, lat: 30.336038, postalCode: "32254" },
    { lng: -80.34491, lat: 25.981409, postalCode: "33027" },
    { lng: -81.378181, lat: 30.164918, postalCode: "32098" },
    { lng: -82.0309, lat: 29.601599, postalCode: "32640" },
    { lng: -80.95736, lat: 29.016897, postalCode: "32168" },
    { lng: -81.64475, lat: 28.786272, postalCode: "32757" },
    { lng: -80.22122, lat: 26.183628, postalCode: "33319" },
    { lng: -81.430915, lat: 27.855686, postalCode: "33856" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32247" },
    { lng: -80.09594, lat: 26.236792, postalCode: "33062" },
    { lng: -80.134216, lat: 26.253909, postalCode: "33061" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33672" },
    { lng: -81.36796, lat: 28.666249, postalCode: "32701" },
    { lng: -81.59344, lat: 28.536794, postalCode: "34787" },
    { lng: -84.346204, lat: 30.479347, postalCode: "32302" },
    { lng: -84.16049, lat: 30.531677, postalCode: "32308" },
    { lng: -82.00227, lat: 26.579862, postalCode: "33914" },
    { lng: -82.40914, lat: 27.993498, postalCode: "33610" },
    { lng: -81.62098, lat: 30.24354, postalCode: "32217" },
    { lng: -82.66315, lat: 28.429478, postalCode: "34679" },
    { lng: -84.74346, lat: 30.571364, postalCode: "32330" },
    { lng: -81.24515, lat: 28.902426, postalCode: "32725" },
    { lng: -82.10819, lat: 29.459973, postalCode: "32654" },
    { lng: -82.22069, lat: 29.450353, postalCode: "32664" },
    { lng: -81.627324, lat: 29.799631, postalCode: "32007" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32878" },
    { lng: -80.10582, lat: 26.459571, postalCode: "33445" },
    { lng: -81.50669, lat: 28.26074, postalCode: "33848" },
    { lng: -80.87561, lat: 28.998887, postalCode: "32169" },
    { lng: -80.14465, lat: 25.962069, postalCode: "33180" },
    { lng: -80.242671, lat: 25.859273, postalCode: "33047" },
    { lng: -81.59391, lat: 26.634332, postalCode: "33972" },
    { lng: -82.247783, lat: 28.310134, postalCode: "33526" },
    { lng: -82.04106, lat: 26.914393, postalCode: "33950" },
    { lng: -81.67579, lat: 28.597126, postalCode: "34756" },
    { lng: -80.31581, lat: 26.05479, postalCode: "33330" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33587" },
    { lng: -81.063249, lat: 24.723283, postalCode: "33052" },
    { lng: -82.1107, lat: 29.23543, postalCode: "34479" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33631" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33742" },
    { lng: -82.61213, lat: 28.95138, postalCode: "34428" },
    { lng: -81.31568, lat: 26.95925, postalCode: "33944" },
    { lng: -80.17799, lat: 26.253238, postalCode: "33066" },
    { lng: -82.451959, lat: 28.119579, postalCode: "33509" },
    { lng: -81.27246, lat: 28.198441, postalCode: "34772" },
    { lng: -80.14925, lat: 26.016984, postalCode: "33020" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33329" },
    { lng: -80.30476, lat: 25.824725, postalCode: "33166" },
    { lng: -82.16568, lat: 28.21305, postalCode: "33539" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33124" },
    { lng: -80.18622, lat: 25.853184, postalCode: "33138" },
    { lng: -81.92392, lat: 28.041198, postalCode: "33801" },
    { lng: -84.406629, lat: 30.130775, postalCode: "32305" },
    { lng: -81.65651, lat: 30.449096, postalCode: "32218" },
    { lng: -81.423542, lat: 28.368679, postalCode: "34837" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33595" },
    { lng: -82.75211, lat: 27.925755, postalCode: "33733" },
    { lng: -81.893427, lat: 26.659942, postalCode: "33915" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32777" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33845" },
    { lng: -81.568862, lat: 25.100957, postalCode: "33045" },
    { lng: -82.016139, lat: 28.99796, postalCode: "34492" },
    { lng: -84.34906, lat: 30.183372, postalCode: "32326" },
    { lng: -82.59446, lat: 29.640613, postalCode: "32669" },
    { lng: -80.25377, lat: 26.65671, postalCode: "33414" },
    { lng: -81.72002, lat: 26.067538, postalCode: "34113" },
    { lng: -80.06312, lat: 26.840684, postalCode: "33408" },
    { lng: -82.53556, lat: 27.365622, postalCode: "34234" },
    { lng: -82.47836, lat: 27.240571, postalCode: "34238" },
    { lng: -82.24508, lat: 27.911226, postalCode: "33594" },
    { lng: -80.454196, lat: 27.110182, postalCode: "34992" },
    { lng: -81.37446, lat: 28.541879, postalCode: "32801" },
    { lng: -81.76829, lat: 26.187767, postalCode: "34105" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33651" },
    { lng: -80.36151, lat: 25.786634, postalCode: "33172" },
    { lng: -82.572703, lat: 28.867027, postalCode: "34423" },
    { lng: -80.2978, lat: 27.287855, postalCode: "34592" },
    { lng: -80.11226, lat: 26.344955, postalCode: "33486" },
    { lng: -81.258108, lat: 28.613308, postalCode: "32733" },
    { lng: -81.768622, lat: 30.337538, postalCode: "32099" },
    { lng: -82.453041, lat: 27.720111, postalCode: "33571" },
    { lng: -81.754949, lat: 28.017179, postalCode: "33380" },
    { lng: -81.70643, lat: 26.183424, postalCode: "34116" },
    { lng: -80.503746, lat: 27.386818, postalCode: "34988" },
    { lng: -81.46799, lat: 26.03569, postalCode: "34137" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32613" },
    { lng: -81.40625, lat: 28.489758, postalCode: "32839" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33633" },
    { lng: -80.36327, lat: 27.410069, postalCode: "34981" },
    { lng: -80.33644, lat: 27.448016, postalCode: "34950" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34281" },
    { lng: -80.22727, lat: 26.3007, postalCode: "33067" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32890" },
    { lng: -80.79887, lat: 28.47123, postalCode: "32927" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33650" },
    { lng: -80.802656, lat: 27.297631, postalCode: "34973" },
    { lng: -81.89073, lat: 29.071931, postalCode: "32179" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33261" },
    { lng: -82.528488, lat: 27.331628, postalCode: "34278" },
    { lng: -82.32271, lat: 29.124611, postalCode: "34481" },
    { lng: -80.984344, lat: 29.10909, postalCode: "32116" },
    { lng: -81.03602, lat: 29.204009, postalCode: "32114" },
    { lng: -82.52226, lat: 27.869251, postalCode: "33616" },
    { lng: -81.64664, lat: 30.351006, postalCode: "32206" },
    { lng: -81.79852, lat: 26.142938, postalCode: "34102" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33689" },
    { lng: -82.1899, lat: 29.003195, postalCode: "34473" },
    { lng: -82.42643, lat: 29.931204, postalCode: "32697" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32715" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32861" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33097" },
    { lng: -81.73987, lat: 30.268572, postalCode: "32210" },
    { lng: -82.44385, lat: 27.703801, postalCode: "33570" },
    { lng: -81.644387, lat: 25.925446, postalCode: "34154" },
    { lng: -81.104185, lat: 28.550028, postalCode: "32855" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33466" },
    { lng: -81.59586, lat: 26.275676, postalCode: "34120" },
    { lng: -82.45171, lat: 28.048595, postalCode: "33612" },
    { lng: -80.21841, lat: 26.348354, postalCode: "33428" },
    { lng: -82.508694, lat: 28.859286, postalCode: "34460" },
    { lng: -81.571248, lat: 28.505419, postalCode: "34876" },
    { lng: -82.09821, lat: 26.993876, postalCode: "33952" },
    { lng: -80.16509, lat: 26.452473, postalCode: "33446" },
    { lng: -84.26903, lat: 30.431283, postalCode: "32301" },
    { lng: -81.653642, lat: 28.811078, postalCode: "32158" },
    { lng: -84.23331, lat: 30.420146, postalCode: "32399" },
    { lng: -81.94861, lat: 26.552895, postalCode: "33932" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32816" },
    { lng: -84.89247, lat: 29.825252, postalCode: "32328" },
    { lng: -80.206869, lat: 26.013768, postalCode: "33201" },
    { lng: -81.81859, lat: 28.744182, postalCode: "34797" },
    { lng: -82.06126, lat: 28.754347, postalCode: "33585" },
    { lng: -81.344414, lat: 28.773699, postalCode: "32476" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32610" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33623" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33299" },
    { lng: -80.33284, lat: 25.887983, postalCode: "33016" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33011" },
    { lng: -82.82607, lat: 27.885283, postalCode: "33774" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32919" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32198" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32235" },
    { lng: -80.72558, lat: 28.308581, postalCode: "32955" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32781" },
    { lng: -81.30606, lat: 28.607077, postalCode: "32792" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33684" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33741" },
    { lng: -80.23327, lat: 25.753177, postalCode: "33145" },
    { lng: -81.90174, lat: 26.555958, postalCode: "33919" },
    { lng: -80.1356, lat: 26.458152, postalCode: "33484" },
    { lng: -82.353862, lat: 29.681312, postalCode: "32635" },
    { lng: -81.260818, lat: 28.566338, postalCode: "34778" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32718" },
    { lng: -82.397904, lat: 29.573293, postalCode: "32604" },
    { lng: -82.50656, lat: 27.943549, postalCode: "33609" },
    { lng: -82.855334, lat: 29.460221, postalCode: "32644" },
    { lng: -81.389124, lat: 26.14326, postalCode: "34107" },
    { lng: -81.34387, lat: 28.653875, postalCode: "32730" },
    { lng: -82.67737, lat: 28.042799, postalCode: "34677" },
    { lng: -80.1627, lat: 26.404353, postalCode: "33496" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32710" },
    { lng: -82.47648, lat: 27.321313, postalCode: "34232" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32872" },
    { lng: -80.2631, lat: 26.211122, postalCode: "33321" },
    { lng: -81.89038, lat: 28.583191, postalCode: "34753" },
    { lng: -81.275174, lat: 29.838279, postalCode: "32080" },
    { lng: -81.31212, lat: 28.888595, postalCode: "32713" },
    { lng: -81.19645, lat: 28.271939, postalCode: "34771" },
    { lng: -80.36373, lat: 26.056111, postalCode: "33331" },
    { lng: -85.02704, lat: 29.71877, postalCode: "32320" },
    { lng: -83.659371, lat: 29.966454, postalCode: "32348" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32121" },
    { lng: -81.81863, lat: 28.180035, postalCode: "33868" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33738" },
    { lng: -82.4627, lat: 27.984198, postalCode: "33603" },
    { lng: -83.14401, lat: 29.330426, postalCode: "32692" },
    { lng: -82.481766, lat: 28.324796, postalCode: "34656" },
    { lng: -81.95528, lat: 26.582185, postalCode: "33904" },
    { lng: -80.08552, lat: 26.347305, postalCode: "33432" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33266" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32898" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34260" },
    { lng: -80.27284, lat: 26.150863, postalCode: "33322" },
    { lng: -80.163418, lat: 26.092115, postalCode: "33515" },
    { lng: -80.18164, lat: 25.893372, postalCode: "33161" },
    { lng: -81.36054, lat: 28.51483, postalCode: "32806" },
    { lng: -80.29901, lat: 27.472857, postalCode: "34949" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32886" },
    { lng: -81.59442, lat: 30.63928, postalCode: "32097" },
    { lng: -81.94861, lat: 26.552895, postalCode: "33994" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33885" },
    { lng: -81.698422, lat: 30.551558, postalCode: "32035" },
    { lng: -81.5088, lat: 27.757986, postalCode: "33843" },
    { lng: -81.00041, lat: 28.160376, postalCode: "34773" },
    { lng: -80.24945, lat: 25.909039, postalCode: "33054" },
    { lng: -81.77955, lat: 28.711167, postalCode: "34737" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33758" },
    { lng: -82.53601, lat: 28.555346, postalCode: "34613" },
    { lng: -80.85374, lat: 28.868986, postalCode: "32759" },
    { lng: -80.29443, lat: 26.0267, postalCode: "33026" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33660" },
    { lng: -81.92215, lat: 27.965469, postalCode: "33813" },
    { lng: -80.12704, lat: 26.659344, postalCode: "33415" },
    { lng: -82.543519, lat: 27.405429, postalCode: "34270" },
    { lng: -81.46202, lat: 26.581172, postalCode: "33930" },
    { lng: -81.343903, lat: 28.519024, postalCode: "32802" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33424" },
    { lng: -80.40521, lat: 25.524528, postalCode: "33032" },
    { lng: -82.54518, lat: 29.517277, postalCode: "32618" },
    { lng: -81.74812, lat: 26.680407, postalCode: "33905" },
    { lng: -81.020281, lat: 24.72339, postalCode: "33051" },
    { lng: -82.1621, lat: 29.789597, postalCode: "32694" },
    { lng: -82.29591, lat: 29.713911, postalCode: "32609" },
    { lng: -80.27326, lat: 25.990494, postalCode: "33025" },
    { lng: -80.732327, lat: 28.329785, postalCode: "32956" },
    { lng: -82.372125, lat: 27.437698, postalCode: "34211" },
    { lng: -82.91032, lat: 29.928691, postalCode: "32008" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32911" },
    { lng: -80.206919, lat: 26.144263, postalCode: "33310" },
    { lng: -80.45364, lat: 27.750176, postalCode: "32970" },
    { lng: -81.20151, lat: 25.7265, postalCode: "34141" },
    { lng: -81.809939, lat: 27.186146, postalCode: "34265" },
    { lng: -80.69211, lat: 28.137443, postalCode: "32934" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32229" },
    { lng: -82.63372, lat: 27.455543, postalCode: "34210" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32267" },
    { lng: -80.38145, lat: 27.246754, postalCode: "34953" },
    { lng: -81.66331, lat: 29.476035, postalCode: "32193" },
    { lng: -81.79405, lat: 27.7437, postalCode: "33841" },
    { lng: -80.17218, lat: 26.185461, postalCode: "33309" },
    { lng: -84.346204, lat: 30.479347, postalCode: "32315" },
    { lng: -85.692035, lat: 30.234451, postalCode: "32411" },
    { lng: -81.735239, lat: 28.718224, postalCode: "34703" },
    { lng: -82.19101, lat: 26.519415, postalCode: "33924" },
    { lng: -82.52464, lat: 27.855078, postalCode: "33621" },
    { lng: -84.188446, lat: 30.514599, postalCode: "32309" },
    { lng: -81.9849, lat: 28.042948, postalCode: "33815" },
    { lng: -82.50053, lat: 28.997155, postalCode: "34433" },
    { lng: -82.537169, lat: 27.335023, postalCode: "34230" },
    { lng: -81.22328, lat: 28.744752, postalCode: "32762" },
    { lng: -81.52946, lat: 28.574628, postalCode: "34761" },
    { lng: -82.40894, lat: 27.33458, postalCode: "34240" },
    { lng: -81.08642, lat: 29.105506, postalCode: "32124" },
    { lng: -81.05611, lat: 29.316606, postalCode: "32176" },
    { lng: -80.40811, lat: 25.699968, postalCode: "33183" },
    { lng: -82.24417, lat: 27.051075, postalCode: "34287" },
    { lng: -82.14042, lat: 28.663132, postalCode: "33513" },
    { lng: -82.14506, lat: 28.065552, postalCode: "33565" },
    { lng: -80.15422, lat: 26.094385, postalCode: "33315" },
    { lng: -80.12606, lat: 26.153728, postalCode: "33305" },
    { lng: -82.08045, lat: 26.439608, postalCode: "33957" },
    { lng: -80.65077, lat: 28.13926, postalCode: "32935" },
    { lng: -82.54936, lat: 28.005497, postalCode: "33634" },
    { lng: -81.683107, lat: 30.34494, postalCode: "32238" },
    { lng: -80.16865, lat: 26.13883, postalCode: "33311" },
    { lng: -80.20827, lat: 25.846874, postalCode: "33110" },
    { lng: -82.04255, lat: 29.054976, postalCode: "34420" },
    { lng: -82.416515, lat: 28.564167, postalCode: "34611" },
    { lng: -84.66292, lat: 30.616479, postalCode: "32332" },
    { lng: -82.58294, lat: 27.485617, postalCode: "34205" },
    { lng: -81.35408, lat: 28.325715, postalCode: "34743" },
    { lng: -81.52488, lat: 28.401151, postalCode: "32836" },
    { lng: -80.35999, lat: 25.653431, postalCode: "33176" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33482" },
    { lng: -80.241512, lat: 27.17537, postalCode: "34995" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32834" },
    { lng: -80.2731, lat: 26.30116, postalCode: "33076" },
    { lng: -81.42131, lat: 27.483817, postalCode: "33870" },
    { lng: -80.41664, lat: 25.559785, postalCode: "33170" },
    { lng: -82.503117, lat: 28.94159, postalCode: "34426" },
    { lng: -82.26576, lat: 28.51589, postalCode: "34602" },
    { lng: -81.8727, lat: 26.62365, postalCode: "33901" },
    { lng: -81.84051, lat: 30.567684, postalCode: "32011" },
    { lng: -82.61302, lat: 28.516609, postalCode: "34607" },
    { lng: -80.40735, lat: 25.137661, postalCode: "33037" },
    { lng: -82.79307, lat: 27.843349, postalCode: "33772" },
    { lng: -80.3494, lat: 25.56071, postalCode: "33190" },
    { lng: -80.84123, lat: 28.623371, postalCode: "32796" },
    { lng: -80.454196, lat: 27.110182, postalCode: "33475" },
    { lng: -81.586, lat: 30.333022, postalCode: "32211" },
    { lng: -82.84703, lat: 27.887923, postalCode: "33785" },
    { lng: -81.332888, lat: 28.827219, postalCode: "32747" },
    { lng: -81.607007, lat: 26.71613, postalCode: "33918" },
    { lng: -82.77534, lat: 28.025395, postalCode: "34698" },
    { lng: -80.44721, lat: 25.70028, postalCode: "33193" },
    { lng: -82.380967, lat: 27.167521, postalCode: "34276" },
    { lng: -80.21686, lat: 27.136003, postalCode: "34997" },
    { lng: -80.12973, lat: 25.858573, postalCode: "33141" },
    { lng: -80.21032, lat: 25.892185, postalCode: "33168" },
    { lng: -81.37594, lat: 28.308979, postalCode: "34744" },
    { lng: -81.76653, lat: 30.411564, postalCode: "32219" },
    { lng: -82.66572, lat: 27.745355, postalCode: "33712" },
    { lng: -80.572557, lat: 27.709049, postalCode: "32969" },
    { lng: -80.34907, lat: 27.312471, postalCode: "34983" },
    { lng: -80.2029, lat: 25.786326, postalCode: "33136" },
    { lng: -81.28761, lat: 28.803979, postalCode: "32771" },
    { lng: -81.7477, lat: 26.15091, postalCode: "34104" },
    { lng: -81.46862, lat: 28.287883, postalCode: "34746" },
    { lng: -82.49392, lat: 28.071502, postalCode: "33618" },
    { lng: -82.45463, lat: 28.233671, postalCode: "34639" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33775" },
    { lng: -81.95196, lat: 27.710056, postalCode: "33835" },
    { lng: -82.41078, lat: 27.771988, postalCode: "33572" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32814" },
    { lng: -81.27631, lat: 28.769173, postalCode: "32773" },
    { lng: -81.50008, lat: 29.332109, postalCode: "32190" },
    { lng: -80.273961, lat: 26.094025, postalCode: "33352" },
    { lng: -81.39325, lat: 26.398248, postalCode: "34142" },
    { lng: -81.69113, lat: 30.354588, postalCode: "32209" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33245" },
    { lng: -81.653642, lat: 28.811078, postalCode: "34713" },
    { lng: -82.481766, lat: 28.324796, postalCode: "33537" },
    { lng: -82.724763, lat: 27.891809, postalCode: "34682" },
    { lng: -82.23349, lat: 27.396045, postalCode: "34251" },
    { lng: -81.547044, lat: 30.32281, postalCode: "32203" },
    { lng: -80.409024, lat: 27.382196, postalCode: "34954" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33346" },
    { lng: -80.409024, lat: 27.382196, postalCode: "34979" },
    { lng: -82.26125, lat: 26.894448, postalCode: "33947" },
    { lng: -81.809939, lat: 27.186146, postalCode: "34267" },
    { lng: -81.49965, lat: 30.348585, postalCode: "32225" },
    { lng: -80.40294, lat: 27.636828, postalCode: "32960" },
    { lng: -81.67933, lat: 30.392888, postalCode: "32208" },
    { lng: -82.664359, lat: 27.881909, postalCode: "33729" },
    { lng: -81.72195, lat: 29.97289, postalCode: "32043" },
    { lng: -82.453508, lat: 28.478994, postalCode: "34604" },
    { lng: -82.14112, lat: 26.6636, postalCode: "33922" },
    { lng: -81.6088, lat: 29.488583, postalCode: "32181" },
    { lng: -81.53689, lat: 28.804039, postalCode: "32776" },
    { lng: -82.416515, lat: 28.564167, postalCode: "34603" },
    { lng: -84.287749, lat: 30.42565, postalCode: "32307" },
    { lng: -81.28749, lat: 28.24934, postalCode: "34769" },
    { lng: -81.86649, lat: 28.571677, postalCode: "34736" },
    { lng: -80.56986, lat: 27.974173, postalCode: "32950" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33102" },
    { lng: -82.75179, lat: 27.741505, postalCode: "33706" },
    { lng: -82.582035, lat: 27.996097, postalCode: "33601" },
    { lng: -82.43193, lat: 27.963849, postalCode: "33605" },
    { lng: -82.5912, lat: 28.797979, postalCode: "34448" },
    { lng: -81.50932, lat: 27.60047, postalCode: "33825" },
    { lng: -81.69984, lat: 26.542097, postalCode: "33913" },
    { lng: -81.848401, lat: 30.031702, postalCode: "32050" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33420" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32704" },
    { lng: -82.80122, lat: 27.916149, postalCode: "33770" },
    { lng: -81.607612, lat: 28.252198, postalCode: "33896" },
    { lng: -82.3941, lat: 29.611545, postalCode: "32608" },
    { lng: -81.02229, lat: 29.153239, postalCode: "32119" },
    { lng: -81.65101, lat: 30.328539, postalCode: "32202" },
    { lng: -81.48395, lat: 28.094144, postalCode: "34759" },
    { lng: -81.34497, lat: 29.140657, postalCode: "32130" },
    { lng: -84.46259, lat: 30.494878, postalCode: "32343" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32115" },
    { lng: -82.000005, lat: 26.901981, postalCode: "33927" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33732" },
    { lng: -81.24717, lat: 28.591692, postalCode: "32817" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32123" },
    { lng: -82.60452, lat: 29.447069, postalCode: "32621" },
    { lng: -81.67401, lat: 29.666218, postalCode: "32177" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33107" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33074" },
    { lng: -82.75735, lat: 28.19006, postalCode: "34691" },
    { lng: -84.60134, lat: 29.879559, postalCode: "32323" },
    { lng: -81.18404, lat: 28.542132, postalCode: "32828" },
    { lng: -81.88648, lat: 29.640791, postalCode: "32148" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32793" },
    { lng: -82.380967, lat: 27.167521, postalCode: "34277" },
    { lng: -82.22166, lat: 26.59951, postalCode: "33945" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33083" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33302" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33687" },
    { lng: -81.9205, lat: 29.727276, postalCode: "32138" },
    { lng: -80.60368, lat: 26.900359, postalCode: "33438" },
    { lng: -80.41441, lat: 26.120134, postalCode: "33327" },
    { lng: -80.39786, lat: 27.341158, postalCode: "34986" },
    { lng: -81.78726, lat: 26.28828, postalCode: "34110" },
    { lng: -81.00259, lat: 29.210309, postalCode: "32118" },
    { lng: -82.81421, lat: 30.203153, postalCode: "32094" },
    { lng: -80.572557, lat: 27.709049, postalCode: "32957" },
    { lng: -81.987609, lat: 28.945246, postalCode: "32162" },
    { lng: -81.493521, lat: 27.918187, postalCode: "33898" },
    { lng: -82.71425, lat: 29.922221, postalCode: "32038" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32120" },
    { lng: -80.45925, lat: 25.650232, postalCode: "33196" },
    { lng: -82.72529, lat: 30.108128, postalCode: "32024" },
    { lng: -82.07847, lat: 28.921705, postalCode: "34484" },
    { lng: -80.18287, lat: 26.305433, postalCode: "33073" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33731" },
    { lng: -80.4915, lat: 27.431607, postalCode: "34945" },
    { lng: -82.464468, lat: 27.144031, postalCode: "34274" },
    { lng: -82.535, lat: 27.486448, postalCode: "34208" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33255" },
    { lng: -82.0943, lat: 30.061174, postalCode: "32058" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33682" },
    { lng: -81.05316, lat: 29.238606, postalCode: "32117" },
    { lng: -81.54604, lat: 30.206922, postalCode: "32256" },
    { lng: -82.76116, lat: 28.076744, postalCode: "34683" },
    { lng: -82.59054, lat: 28.143377, postalCode: "33556" },
    { lng: -82.508694, lat: 28.859286, postalCode: "34464" },
    { lng: -81.80196, lat: 26.425671, postalCode: "33928" },
    { lng: -81.659017, lat: 30.325739, postalCode: "32276" },
    { lng: -80.51457, lat: 27.877504, postalCode: "32976" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32867" },
    { lng: -82.317993, lat: 26.89679, postalCode: "34334" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33339" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33807" },
    { lng: -81.74554, lat: 27.997276, postalCode: "33880" },
    { lng: -80.21526, lat: 26.925835, postalCode: "33478" },
    { lng: -81.74343, lat: 28.893559, postalCode: "32735" },
    { lng: -81.46122, lat: 29.656806, postalCode: "32145" },
    { lng: -80.03937, lat: 26.715067, postalCode: "33480" },
    { lng: -81.63131, lat: 28.223014, postalCode: "33837" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32902" },
    { lng: -81.55495, lat: 26.1785, postalCode: "34117" },
    { lng: -85.023947, lat: 29.745526, postalCode: "32329" },
    { lng: -81.80825, lat: 26.252711, postalCode: "34108" },
    { lng: -82.73594, lat: 27.530644, postalCode: "34216" },
    { lng: -81.57164, lat: 29.467357, postalCode: "32157" },
    { lng: -82.99026, lat: 29.643244, postalCode: "32680" },
    { lng: -82.39477, lat: 28.932925, postalCode: "34442" },
    { lng: -81.617754, lat: 30.621671, postalCode: "32047" },
    { lng: -82.74482, lat: 27.971362, postalCode: "33765" },
    { lng: -80.60159, lat: 28.235092, postalCode: "32925" },
    { lng: -84.606864, lat: 30.549676, postalCode: "32353" },
    { lng: -80.686159, lat: 28.306726, postalCode: "32924" },
    { lng: -80.34798, lat: 26.018418, postalCode: "33028" },
    { lng: -81.87196, lat: 27.201006, postalCode: "34266" },
    { lng: -81.77391, lat: 26.348523, postalCode: "34135" },
    { lng: -82.2112, lat: 27.012758, postalCode: "33953" },
    { lng: -80.30732, lat: 25.902172, postalCode: "33014" },
    { lng: -81.58359, lat: 30.28374, postalCode: "32216" },
    { lng: -81.35942, lat: 28.628359, postalCode: "32751" },
    { lng: -81.68174, lat: 30.323806, postalCode: "32204" },
    { lng: -82.5009, lat: 27.532098, postalCode: "34222" },
    { lng: -80.79405, lat: 24.841814, postalCode: "33001" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33757" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33622" },
    { lng: -82.51506, lat: 27.336973, postalCode: "34237" },
    { lng: -81.46092, lat: 30.641487, postalCode: "32034" },
    { lng: -81.5362, lat: 28.495982, postalCode: "34786" },
    { lng: -82.337751, lat: 28.130402, postalCode: "33643" },
    { lng: -81.93269, lat: 26.446463, postalCode: "33931" },
    { lng: -82.67612, lat: 27.817323, postalCode: "33714" },
    { lng: -82.78702, lat: 27.944631, postalCode: "33756" },
    { lng: -81.11906, lat: 28.639275, postalCode: "32766" },
    { lng: -82.087458, lat: 29.240728, postalCode: "34430" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34204" },
    { lng: -82.09829, lat: 29.514298, postalCode: "32662" },
    { lng: -82.32167, lat: 28.803207, postalCode: "34451" },
    { lng: -81.951673, lat: 27.955426, postalCode: "33831" },
    { lng: -81.93581, lat: 27.613064, postalCode: "33834" },
    { lng: -81.288424, lat: 27.244668, postalCode: "33862" },
    { lng: -81.5754, lat: 28.716923, postalCode: "32798" },
    { lng: -81.22328, lat: 29.499591, postalCode: "32164" },
    { lng: -82.10556, lat: 29.198704, postalCode: "34470" },
    { lng: -80.17839, lat: 25.929571, postalCode: "33162" },
    { lng: -82.76111, lat: 27.851052, postalCode: "33777" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33340" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33685" },
    { lng: -81.40949, lat: 30.343407, postalCode: "32233" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33296" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33121" },
    { lng: -83.38416, lat: 29.673459, postalCode: "32359" },
    { lng: -80.409024, lat: 27.382196, postalCode: "34985" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33548" },
    { lng: -81.96523, lat: 29.493034, postalCode: "32182" },
    { lng: -82.508694, lat: 28.859286, postalCode: "34487" },
    { lng: -84.41376, lat: 30.607352, postalCode: "32333" },
    { lng: -82.37407, lat: 27.837894, postalCode: "33534" },
    { lng: -80.10846, lat: 26.191111, postalCode: "33308" },
    { lng: -82.28877, lat: 28.331979, postalCode: "33576" },
    { lng: -81.463297, lat: 27.430819, postalCode: "33875" },
    { lng: -80.21318, lat: 25.989119, postalCode: "33023" },
    { lng: -82.49726, lat: 29.131665, postalCode: "34431" },
    { lng: -82.438841, lat: 27.871964, postalCode: "33697" },
    { lng: -81.94861, lat: 26.552895, postalCode: "34136" },
    { lng: -82.23483, lat: 29.364039, postalCode: "32686" },
    { lng: -84.267397, lat: 30.319263, postalCode: "32362" },
    { lng: -80.17921, lat: 26.601937, postalCode: "33467" },
    { lng: -81.91485, lat: 30.23289, postalCode: "32215" },
    { lng: -80.35243, lat: 27.485272, postalCode: "34946" },
    { lng: -81.13666, lat: 28.458149, postalCode: "32831" },
    { lng: -81.389305, lat: 30.287041, postalCode: "32255" },
    { lng: -81.0948, lat: 28.834172, postalCode: "32764" },
    { lng: -80.08841, lat: 26.409953, postalCode: "33487" },
    { lng: -82.00964, lat: 26.999486, postalCode: "33983" },
    { lng: -82.438689, lat: 27.427213, postalCode: "34220" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33804" },
    { lng: -81.74894, lat: 30.222489, postalCode: "32244" },
    { lng: -83.04106, lat: 30.285127, postalCode: "32060" },
    { lng: -82.488413, lat: 27.84343, postalCode: "33608" },
    { lng: -80.20656, lat: 25.757227, postalCode: "33129" },
    { lng: -82.087458, lat: 29.240728, postalCode: "34421" },
    { lng: -82.16868, lat: 28.235313, postalCode: "33540" },
    { lng: -80.40526, lat: 27.53831, postalCode: "34951" },
    { lng: -82.957151, lat: 30.331517, postalCode: "32064" },
    { lng: -80.60501, lat: 28.390932, postalCode: "32920" },
    { lng: -80.29718, lat: 25.777977, postalCode: "33126" },
    { lng: -80.45722, lat: 27.594718, postalCode: "32968" },
    { lng: -81.282815, lat: 29.466085, postalCode: "32142" },
    { lng: -80.35509, lat: 25.895557, postalCode: "33018" },
    { lng: -82.724763, lat: 27.891809, postalCode: "33734" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32893" },
    { lng: -82.68765, lat: 27.469113, postalCode: "34215" },
    { lng: -82.48108, lat: 28.848776, postalCode: "34461" },
    { lng: -80.430269, lat: 26.645895, postalCode: "33429" },
    { lng: -81.33962, lat: 29.923718, postalCode: "32095" },
    { lng: -82.02133, lat: 29.120287, postalCode: "34472" },
    { lng: -80.74196, lat: 28.366284, postalCode: "32922" },
    { lng: -81.61864, lat: 28.002553, postalCode: "33820" },
    { lng: -81.332763, lat: 28.417368, postalCode: "32862" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33269" },
    { lng: -82.57776, lat: 28.005446, postalCode: "33615" },
    { lng: -80.448254, lat: 26.145724, postalCode: "33349" },
    { lng: -81.172169, lat: 29.022729, postalCode: "32722" },
    { lng: -82.122553, lat: 29.968926, postalCode: "32042" },
    { lng: -82.15053, lat: 28.568393, postalCode: "33597" },
    { lng: -81.6235, lat: 28.101527, postalCode: "33844" },
    { lng: -81.80333, lat: 26.191117, postalCode: "34103" },
    { lng: -83.27037, lat: 30.421598, postalCode: "32059" },
    { lng: -81.969048, lat: 29.060711, postalCode: "32111" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32854" },
    { lng: -80.458168, lat: 25.558428, postalCode: "33002" },
    { lng: -82.425512, lat: 27.146963, postalCode: "34272" },
    { lng: -84.34803, lat: 30.400318, postalCode: "32310" },
    { lng: -82.778424, lat: 28.067043, postalCode: "34660" },
    { lng: -82.48539, lat: 27.187829, postalCode: "34229" },
    { lng: -82.86837, lat: 29.453178, postalCode: "32626" },
    { lng: -81.30774, lat: 28.546897, postalCode: "32807" },
    { lng: -82.128584, lat: 28.016971, postalCode: "33563" },
    { lng: -82.72096, lat: 27.68301, postalCode: "33715" },
    { lng: -82.72865, lat: 27.815953, postalCode: "33709" },
    { lng: -82.35195, lat: 29.060092, postalCode: "34432" },
    { lng: -81.38206, lat: 27.293327, postalCode: "33852" },
    { lng: -81.260818, lat: 28.566338, postalCode: "32858" },
    { lng: -82.5961, lat: 28.872108, postalCode: "34429" },
    { lng: -81.420603, lat: 29.937673, postalCode: "32260" },
    { lng: -84.8218, lat: 30.687983, postalCode: "32324" },
    { lng: -82.574264, lat: 28.804911, postalCode: "34447" },
    { lng: -81.653642, lat: 28.811078, postalCode: "34712" },
    { lng: -84.52243, lat: 32.683146, postalCode: "31827" },
    { lng: -83.47738, lat: 34.348822, postalCode: "30547" },
    { lng: -84.30152, lat: 33.401827, postalCode: "30228" },
    { lng: -82.625213, lat: 33.432384, postalCode: "30819" },
    { lng: -84.25427, lat: 33.215454, postalCode: "30224" },
    { lng: -83.70862, lat: 33.319093, postalCode: "31064" },
    { lng: -84.33347, lat: 33.528345, postalCode: "30236" },
    { lng: -84.439127, lat: 33.702657, postalCode: "30334" },
    { lng: -82.56948, lat: 32.549854, postalCode: "31002" },
    { lng: -83.88641, lat: 32.55568, postalCode: "31030" },
    { lng: -84.59706, lat: 34.038949, postalCode: "30144" },
    { lng: -84.24743, lat: 33.693448, postalCode: "30034" },
    { lng: -83.02647, lat: 32.309003, postalCode: "31009" },
    { lng: -84.22644, lat: 31.568073, postalCode: "31707" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30515" },
    { lng: -83.408897, lat: 33.947587, postalCode: "30603" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30023" },
    { lng: -85.233721, lat: 34.333308, postalCode: "30129" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31221" },
    { lng: -83.16666, lat: 33.784316, postalCode: "30667" },
    { lng: -83.23397, lat: 33.083579, postalCode: "31061" },
    { lng: -83.19785, lat: 32.875574, postalCode: "31054" },
    { lng: -83.648552, lat: 32.830389, postalCode: "31207" },
    { lng: -83.78583, lat: 33.054591, postalCode: "31046" },
    { lng: -83.122891, lat: 32.176429, postalCode: "31073" },
    { lng: -82.58299, lat: 31.101953, postalCode: "31550" },
    { lng: -82.97819, lat: 33.994139, postalCode: "30627" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30384" },
    { lng: -82.18481, lat: 31.513511, postalCode: "31518" },
    { lng: -84.56032, lat: 33.744007, postalCode: "30336" },
    { lng: -84.16931, lat: 31.714526, postalCode: "31763" },
    { lng: -85.16973, lat: 33.434562, postalCode: "30170" },
    { lng: -85.25762, lat: 34.010162, postalCode: "30125" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30343" },
    { lng: -84.39839, lat: 34.977037, postalCode: "30555" },
    { lng: -84.33786, lat: 33.768205, postalCode: "30307" },
    { lng: -85.26088, lat: 33.533934, postalCode: "30108" },
    { lng: -84.09678, lat: 31.552483, postalCode: "31705" },
    { lng: -85.05508, lat: 33.987497, postalCode: "30153" },
    { lng: -83.78665, lat: 32.862246, postalCode: "31220" },
    { lng: -84.47633, lat: 33.933002, postalCode: "30067" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30010" },
    { lng: -81.3908, lat: 32.313816, postalCode: "31312" },
    { lng: -83.66519, lat: 31.709742, postalCode: "31714" },
    { lng: -85.15312, lat: 34.253343, postalCode: "30161" },
    { lng: -84.951261, lat: 34.759522, postalCode: "30722" },
    { lng: -84.37931, lat: 34.801665, postalCode: "30522" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30370" },
    { lng: -83.72075, lat: 32.24473, postalCode: "31091" },
    { lng: -84.68784, lat: 33.874553, postalCode: "30127" },
    { lng: -84.735233, lat: 34.981449, postalCode: "30751" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30377" },
    { lng: -83.05632, lat: 32.427633, postalCode: "31019" },
    { lng: -83.68536, lat: 34.167309, postalCode: "30567" },
    { lng: -84.31519, lat: 33.437428, postalCode: "30250" },
    { lng: -83.42329, lat: 34.797611, postalCode: "30581" },
    { lng: -84.32748, lat: 31.689973, postalCode: "31785" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31499" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31208" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30347" },
    { lng: -83.08841, lat: 30.934298, postalCode: "31641" },
    { lng: -84.82536, lat: 33.38779, postalCode: "30263" },
    { lng: -83.569001, lat: 32.810189, postalCode: "31295" },
    { lng: -84.41983, lat: 33.727807, postalCode: "30310" },
    { lng: -83.71124, lat: 34.191571, postalCode: "30575" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30916" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31197" },
    { lng: -82.72979, lat: 32.432854, postalCode: "30454" },
    { lng: -83.58251, lat: 34.112581, postalCode: "30549" },
    { lng: -84.53419, lat: 32.051618, postalCode: "31824" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30302" },
    { lng: -83.638752, lat: 32.839289, postalCode: "31213" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31198" },
    { lng: -83.43902, lat: 33.053288, postalCode: "31033" },
    { lng: -83.56058, lat: 34.258054, postalCode: "30558" },
    { lng: -83.37743, lat: 33.946364, postalCode: "30609" },
    { lng: -83.22096, lat: 32.600622, postalCode: "31003" },
    { lng: -84.10645, lat: 33.351277, postalCode: "30248" },
    { lng: -84.88805, lat: 32.651617, postalCode: "31804" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30398" },
    { lng: -83.34676, lat: 33.527207, postalCode: "30625" },
    { lng: -81.49832, lat: 31.260995, postalCode: "31525" },
    { lng: -80.99193, lat: 32.022019, postalCode: "31410" },
    { lng: -82.30448, lat: 32.177508, postalCode: "30436" },
    { lng: -82.65232, lat: 31.071563, postalCode: "31623" },
    { lng: -81.97826, lat: 32.15499, postalCode: "30414" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30396" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30348" },
    { lng: -84.874123, lat: 32.491005, postalCode: "31914" },
    { lng: -84.26791, lat: 33.740055, postalCode: "30032" },
    { lng: -83.397716, lat: 34.742007, postalCode: "30573" },
    { lng: -82.65655, lat: 33.399555, postalCode: "30828" },
    { lng: -84.34112, lat: 33.998151, postalCode: "30358" },
    { lng: -84.989796, lat: 34.801861, postalCode: "30719" },
    { lng: -84.557181, lat: 33.912473, postalCode: "30111" },
    { lng: -83.00647, lat: 33.260235, postalCode: "31087" },
    { lng: -82.94112, lat: 34.212836, postalCode: "30634" },
    { lng: -81.48778, lat: 31.658252, postalCode: "31323" },
    { lng: -83.96028, lat: 31.959367, postalCode: "31735" },
    { lng: -84.63392, lat: 34.002147, postalCode: "30152" },
    { lng: -83.31318, lat: 34.065588, postalCode: "30646" },
    { lng: -81.09116, lat: 31.988676, postalCode: "31406" },
    { lng: -83.86041, lat: 31.536886, postalCode: "31791" },
    { lng: -83.38487, lat: 34.917466, postalCode: "30562" },
    { lng: -84.40108, lat: 33.555145, postalCode: "30274" },
    { lng: -83.55343, lat: 30.783639, postalCode: "31643" },
    { lng: -84.98895, lat: 33.036715, postalCode: "30241" },
    { lng: -83.34529, lat: 33.925085, postalCode: "30605" },
    { lng: -83.66484, lat: 31.47387, postalCode: "31795" },
    { lng: -85.35064, lat: 34.932348, postalCode: "30725" },
    { lng: -84.13167, lat: 33.817438, postalCode: "30087" },
    { lng: -83.05998, lat: 34.370551, postalCode: "30516" },
    { lng: -84.42804, lat: 32.498727, postalCode: "31058" },
    { lng: -81.77772, lat: 32.447036, postalCode: "30459" },
    { lng: -84.68772, lat: 31.299009, postalCode: "31741" },
    { lng: -84.34087, lat: 33.729506, postalCode: "30316" },
    { lng: -84.3238, lat: 33.793754, postalCode: "30322" },
    { lng: -84.16044, lat: 31.580774, postalCode: "31701" },
    { lng: -85.13779, lat: 32.867469, postalCode: "31833" },
    { lng: -84.23667, lat: 31.209854, postalCode: "31730" },
    { lng: -85.29304, lat: 33.748838, postalCode: "30176" },
    { lng: -84.86733, lat: 33.431181, postalCode: "30275" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30389" },
    { lng: -84.28412, lat: 33.851736, postalCode: "30345" },
    { lng: -85.345544, lat: 34.484483, postalCode: "30754" },
    { lng: -84.83857, lat: 32.154164, postalCode: "31814" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31196" },
    { lng: -84.10042, lat: 32.54748, postalCode: "31076" },
    { lng: -82.71029, lat: 31.297883, postalCode: "31624" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31412" },
    { lng: -81.42676, lat: 31.873145, postalCode: "31309" },
    { lng: -83.34041, lat: 31.439212, postalCode: "31749" },
    { lng: -83.33082, lat: 31.972626, postalCode: "31001" },
    { lng: -81.27064, lat: 31.423776, postalCode: "31327" },
    { lng: -84.42415, lat: 30.88096, postalCode: "31734" },
    { lng: -81.381908, lat: 31.495071, postalCode: "31332" },
    { lng: -84.74967, lat: 33.769636, postalCode: "30134" },
    { lng: -81.49213, lat: 31.166786, postalCode: "31520" },
    { lng: -85.05879, lat: 34.757918, postalCode: "30740" },
    { lng: -82.72612, lat: 33.728754, postalCode: "30673" },
    { lng: -83.459054, lat: 31.271852, postalCode: "31337" },
    { lng: -83.28544, lat: 34.360544, postalCode: "30521" },
    { lng: -82.9201, lat: 34.366948, postalCode: "30643" },
    { lng: -83.76348, lat: 32.185677, postalCode: "31070" },
    { lng: -81.93724, lat: 32.159458, postalCode: "30429" },
    { lng: -84.29407, lat: 33.771705, postalCode: "30030" },
    { lng: -82.5104, lat: 33.527993, postalCode: "30806" },
    { lng: -83.42736, lat: 31.133004, postalCode: "31620" },
    { lng: -81.83011, lat: 31.144635, postalCode: "31566" },
    { lng: -84.69453, lat: 33.527793, postalCode: "30268" },
    { lng: -84.97801, lat: 34.237986, postalCode: "30145" },
    { lng: -84.351273, lat: 33.500697, postalCode: "30298" },
    { lng: -83.03375, lat: 34.193943, postalCode: "30624" },
    { lng: -85.30301, lat: 34.558404, postalCode: "30753" },
    { lng: -81.56912, lat: 30.761081, postalCode: "31558" },
    { lng: -84.54611, lat: 33.580878, postalCode: "30291" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30048" },
    { lng: -81.437074, lat: 31.806832, postalCode: "31310" },
    { lng: -84.37204, lat: 34.344939, postalCode: "30107" },
    { lng: -82.01301, lat: 32.152792, postalCode: "30438" },
    { lng: -84.194812, lat: 31.594782, postalCode: "31702" },
    { lng: -84.252665, lat: 32.085491, postalCode: "31719" },
    { lng: -85.234946, lat: 33.88232, postalCode: "30140" },
    { lng: -83.88442, lat: 34.466226, postalCode: "30564" },
    { lng: -84.165407, lat: 31.564274, postalCode: "31700" },
    { lng: -83.408633, lat: 31.706283, postalCode: "31760" },
    { lng: -84.137, lat: 32.195361, postalCode: "31711" },
    { lng: -83.00524, lat: 30.957421, postalCode: "31649" },
    { lng: -83.3749, lat: 33.948432, postalCode: "30602" },
    { lng: -81.85818, lat: 31.320167, postalCode: "31543" },
    { lng: -82.69332, lat: 31.928525, postalCode: "31549" },
    { lng: -85.286982, lat: 33.744222, postalCode: "30716" },
    { lng: -84.88437, lat: 32.867211, postalCode: "31822" },
    { lng: -82.35198, lat: 31.768849, postalCode: "31513" },
    { lng: -84.64484, lat: 33.776137, postalCode: "30122" },
    { lng: -82.58272, lat: 32.387041, postalCode: "30457" },
    { lng: -81.09258, lat: 32.068658, postalCode: "31401" },
    { lng: -84.54513, lat: 32.57791, postalCode: "31810" },
    { lng: -84.37817, lat: 33.931746, postalCode: "30328" },
    { lng: -84.504081, lat: 33.793006, postalCode: "30881" },
    { lng: -84.556004, lat: 33.932842, postalCode: "30061" },
    { lng: -84.54746, lat: 33.932052, postalCode: "30060" },
    { lng: -83.885902, lat: 30.880968, postalCode: "31799" },
    { lng: -82.37534, lat: 31.205194, postalCode: "31503" },
    { lng: -82.299677, lat: 31.717717, postalCode: "31515" },
    { lng: -84.423648, lat: 33.656824, postalCode: "30320" },
    { lng: -85.23825, lat: 34.281423, postalCode: "30165" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30359" },
    { lng: -84.39361, lat: 32.026125, postalCode: "31780" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30362" },
    { lng: -84.511209, lat: 33.965294, postalCode: "30063" },
    { lng: -81.325815, lat: 31.919866, postalCode: "31424" },
    { lng: -83.75918, lat: 32.652313, postalCode: "31008" },
    { lng: -84.061155, lat: 31.549975, postalCode: "31704" },
    { lng: -83.20286, lat: 31.070627, postalCode: "31645" },
    { lng: -83.22492, lat: 33.087138, postalCode: "31062" },
    { lng: -82.792, lat: 32.146031, postalCode: "30411" },
    { lng: -84.14862, lat: 33.05413, postalCode: "30204" },
    { lng: -84.91737, lat: 33.173516, postalCode: "30230" },
    { lng: -83.89821, lat: 33.576043, postalCode: "30070" },
    { lng: -83.32748, lat: 32.891499, postalCode: "31031" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31126" },
    { lng: -84.97274, lat: 34.113826, postalCode: "30178" },
    { lng: -81.3862, lat: 31.547412, postalCode: "31331" },
    { lng: -83.08857, lat: 33.652489, postalCode: "30669" },
    { lng: -82.72399, lat: 31.062818, postalCode: "31634" },
    { lng: -82.854631, lat: 31.571596, postalCode: "31534" },
    { lng: -85.136227, lat: 33.653355, postalCode: "30109" },
    { lng: -84.32355, lat: 33.827554, postalCode: "30329" },
    { lng: -84.47168, lat: 32.999261, postalCode: "30258" },
    { lng: -83.41617, lat: 34.094345, postalCode: "30565" },
    { lng: -82.37625, lat: 33.200325, postalCode: "30833" },
    { lng: -83.02568, lat: 30.703175, postalCode: "31648" },
    { lng: -84.65511, lat: 33.387534, postalCode: "30277" },
    { lng: -84.38065, lat: 33.771755, postalCode: "30308" },
    { lng: -81.34528, lat: 31.478486, postalCode: "31319" },
    { lng: -84.25037, lat: 31.901066, postalCode: "31787" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31421" },
    { lng: -83.86249, lat: 33.658113, postalCode: "30054" },
    { lng: -83.90937, lat: 31.325004, postalCode: "31744" },
    { lng: -83.708399, lat: 32.87333, postalCode: "31298" },
    { lng: -84.458326, lat: 34.242944, postalCode: "30151" },
    { lng: -84.44977, lat: 33.676258, postalCode: "30344" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30392" },
    { lng: -81.78233, lat: 32.41795, postalCode: "30460" },
    { lng: -83.944336, lat: 32.452926, postalCode: "31507" },
    { lng: -83.79311, lat: 32.091578, postalCode: "31092" },
    { lng: -82.40335, lat: 33.397137, postalCode: "30808" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30375" },
    { lng: -84.49033, lat: 33.61541, postalCode: "30349" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30366" },
    { lng: -81.06724, lat: 32.046143, postalCode: "31404" },
    { lng: -82.59809, lat: 31.844772, postalCode: "31539" },
    { lng: -83.59963, lat: 32.878304, postalCode: "31211" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30369" },
    { lng: -81.12002, lat: 32.075806, postalCode: "31415" },
    { lng: -84.103226, lat: 33.929464, postalCode: "30042" },
    { lng: -85.09612, lat: 33.571176, postalCode: "30118" },
    { lng: -84.58776, lat: 33.121379, postalCode: "30218" },
    { lng: -83.86973, lat: 31.782466, postalCode: "31796" },
    { lng: -84.91725, lat: 33.517698, postalCode: "30185" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30913" },
    { lng: -84.637108, lat: 33.32603, postalCode: "30289" },
    { lng: -84.52238, lat: 32.315915, postalCode: "31803" },
    { lng: -83.50211, lat: 30.935087, postalCode: "31638" },
    { lng: -84.59321, lat: 33.565662, postalCode: "30213" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30085" },
    { lng: -82.88991, lat: 31.67654, postalCode: "31519" },
    { lng: -81.0425, lat: 31.927434, postalCode: "31411" },
    { lng: -83.3404, lat: 32.397039, postalCode: "31014" },
    { lng: -84.23261, lat: 33.9692, postalCode: "30092" },
    { lng: -84.46706, lat: 33.275707, postalCode: "30205" },
    { lng: -84.547082, lat: 33.952451, postalCode: "30090" },
    { lng: -84.845684, lat: 34.244179, postalCode: "30123" },
    { lng: -83.24654, lat: 34.17085, postalCode: "30633" },
    { lng: -83.318647, lat: 30.831442, postalCode: "31607" },
    { lng: -82.71829, lat: 31.631943, postalCode: "31567" },
    { lng: -85.233721, lat: 34.333308, postalCode: "30164" },
    { lng: -84.253089, lat: 31.260443, postalCode: "31739" },
    { lng: -84.07988, lat: 32.329164, postalCode: "31068" },
    { lng: -84.69653, lat: 31.152253, postalCode: "31737" },
    { lng: -81.60975, lat: 31.212705, postalCode: "31523" },
    { lng: -81.3108, lat: 31.906365, postalCode: "31324" },
    { lng: -81.86481, lat: 31.575166, postalCode: "31546" },
    { lng: -84.15678, lat: 31.117271, postalCode: "31779" },
    { lng: -85.18084, lat: 34.175095, postalCode: "30147" },
    { lng: -84.092588, lat: 34.193041, postalCode: "30028" },
    { lng: -84.72284, lat: 30.945016, postalCode: "31725" },
    { lng: -84.92356, lat: 33.718079, postalCode: "30180" },
    { lng: -84.32008, lat: 32.246795, postalCode: "31806" },
    { lng: -83.71061, lat: 33.992538, postalCode: "30680" },
    { lng: -84.3606, lat: 33.849853, postalCode: "30326" },
    { lng: -82.88542, lat: 33.570317, postalCode: "30631" },
    { lng: -82.83342, lat: 31.27291, postalCode: "31642" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31294" },
    { lng: -84.24506, lat: 34.026238, postalCode: "30022" },
    { lng: -82.914974, lat: 32.540073, postalCode: "31040" },
    { lng: -83.05852, lat: 33.46171, postalCode: "30678" },
    { lng: -83.07787, lat: 33.535009, postalCode: "30665" },
    { lng: -83.41232, lat: 33.849286, postalCode: "30677" },
    { lng: -84.75388, lat: 33.277468, postalCode: "30259" },
    { lng: -81.61083, lat: 31.868699, postalCode: "31314" },
    { lng: -82.19784, lat: 31.715778, postalCode: "31563" },
    { lng: -83.94646, lat: 32.742085, postalCode: "31050" },
    { lng: -83.09448, lat: 34.450501, postalCode: "30553" },
    { lng: -83.55992, lat: 34.573072, postalCode: "30535" },
    { lng: -81.381908, lat: 31.495071, postalCode: "31304" },
    { lng: -84.21343, lat: 30.87039, postalCode: "31728" },
    { lng: -81.332211, lat: 31.198914, postalCode: "31561" },
    { lng: -83.38908, lat: 33.944339, postalCode: "30604" },
    { lng: -82.235542, lat: 33.527678, postalCode: "30917" },
    { lng: -81.73166, lat: 32.4906, postalCode: "30461" },
    { lng: -83.503715, lat: 31.44172, postalCode: "31727" },
    { lng: -84.33877, lat: 33.926753, postalCode: "30346" },
    { lng: -84.874123, lat: 32.491005, postalCode: "31999" },
    { lng: -84.70133, lat: 34.434629, postalCode: "30139" },
    { lng: -84.34762, lat: 30.729915, postalCode: "31729" },
    { lng: -84.76332, lat: 32.285535, postalCode: "31805" },
    { lng: -83.364156, lat: 33.559342, postalCode: "30652" },
    { lng: -84.6183, lat: 34.097998, postalCode: "30102" },
    { lng: -83.59971, lat: 31.334062, postalCode: "31775" },
    { lng: -81.880222, lat: 31.578181, postalCode: "31598" },
    { lng: -82.8916, lat: 32.045313, postalCode: "31055" },
    { lng: -84.27215, lat: 33.934536, postalCode: "30360" },
    { lng: -83.48178, lat: 34.846806, postalCode: "30576" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30381" },
    { lng: -85.07227, lat: 34.065792, postalCode: "30104" },
    { lng: -81.32427, lat: 32.390879, postalCode: "31329" },
    { lng: -84.45541, lat: 33.109599, postalCode: "30206" },
    { lng: -84.37203, lat: 33.879003, postalCode: "30342" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30910" },
    { lng: -84.77103, lat: 33.867574, postalCode: "30141" },
    { lng: -83.73816, lat: 30.890906, postalCode: "31720" },
    { lng: -81.23006, lat: 32.293147, postalCode: "31326" },
    { lng: -83.64873, lat: 32.594698, postalCode: "31088" },
    { lng: -83.80726, lat: 32.788484, postalCode: "31052" },
    { lng: -83.69901, lat: 33.786041, postalCode: "30655" },
    { lng: -84.963981, lat: 32.495841, postalCode: "31995" },
    { lng: -83.47325, lat: 31.767986, postalCode: "31783" },
    { lng: -84.75967, lat: 34.822176, postalCode: "30724" },
    { lng: -81.047711, lat: 32.005255, postalCode: "31416" },
    { lng: -84.32976, lat: 30.888809, postalCode: "31797" },
    { lng: -84.33011, lat: 33.58776, postalCode: "30260" },
    { lng: -84.437, lat: 33.786805, postalCode: "30318" },
    { lng: -82.04944, lat: 31.670475, postalCode: "31555" },
    { lng: -83.46842, lat: 34.210423, postalCode: "30529" },
    { lng: -85.23497, lat: 33.658254, postalCode: "30182" },
    { lng: -83.11913, lat: 34.072573, postalCode: "30629" },
    { lng: -82.378, lat: 33.008983, postalCode: "30434" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30086" },
    { lng: -81.61617, lat: 31.828575, postalCode: "31313" },
    { lng: -83.838936, lat: 34.306188, postalCode: "30505" },
    { lng: -82.36787, lat: 32.49126, postalCode: "30448" },
    { lng: -81.80677, lat: 32.423083, postalCode: "30458" },
    { lng: -84.51135, lat: 34.537611, postalCode: "30175" },
    { lng: -82.64828, lat: 33.453039, postalCode: "30807" },
    { lng: -84.90612, lat: 32.37349, postalCode: "31905" },
    { lng: -83.01502, lat: 31.57344, postalCode: "31512" },
    { lng: -84.62593, lat: 33.828061, postalCode: "30106" },
    { lng: -84.450276, lat: 33.696073, postalCode: "30444" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30098" },
    { lng: -82.1214, lat: 32.057356, postalCode: "30453" },
    { lng: -84.01807, lat: 33.860955, postalCode: "30078" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30333" },
    { lng: -82.18933, lat: 33.16249, postalCode: "30816" },
    { lng: -84.07456, lat: 33.891251, postalCode: "39901" },
    { lng: -83.36423, lat: 33.979016, postalCode: "30601" },
    { lng: -83.35161, lat: 34.977198, postalCode: "30537" },
    { lng: -83.91237, lat: 30.883208, postalCode: "31757" },
    { lng: -84.47668, lat: 33.47875, postalCode: "30214" },
    { lng: -84.57062, lat: 33.398992, postalCode: "30269" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31299" },
    { lng: -84.69955, lat: 34.531444, postalCode: "30734" },
    { lng: -83.63554, lat: 33.172786, postalCode: "31038" },
    { lng: -83.801451, lat: 33.717989, postalCode: "30018" },
    { lng: -82.04908, lat: 31.515981, postalCode: "31560" },
    { lng: -83.98913, lat: 32.293293, postalCode: "31063" },
    { lng: -84.07485, lat: 34.052698, postalCode: "30024" },
    { lng: -83.252192, lat: 30.827965, postalCode: "31603" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30325" },
    { lng: -82.3391, lat: 32.605196, postalCode: "30401" },
    { lng: -84.39599, lat: 33.761223, postalCode: "30313" },
    { lng: -83.43578, lat: 34.676584, postalCode: "30580" },
    { lng: -83.38908, lat: 33.944339, postalCode: "30612" },
    { lng: -83.67165, lat: 33.500008, postalCode: "30056" },
    { lng: -84.41583, lat: 34.454934, postalCode: "30143" },
    { lng: -83.72075, lat: 30.966781, postalCode: "31778" },
    { lng: -83.980879, lat: 34.527671, postalCode: "30597" },
    { lng: -83.25093, lat: 31.720029, postalCode: "31750" },
    { lng: -84.80137, lat: 32.634965, postalCode: "31807" },
    { lng: -83.56749, lat: 32.839005, postalCode: "31217" },
    { lng: -83.39135, lat: 31.047996, postalCode: "31627" },
    { lng: -82.52255, lat: 32.309128, postalCode: "30470" },
    { lng: -81.533198, lat: 32.528104, postalCode: "30449" },
    { lng: -83.1848, lat: 32.193953, postalCode: "31023" },
    { lng: -85.170588, lat: 33.157214, postalCode: "30219" },
    { lng: -85.02482, lat: 34.574804, postalCode: "30746" },
    { lng: -83.287753, lat: 30.84848, postalCode: "31698" },
    { lng: -82.35133, lat: 31.220059, postalCode: "31501" },
    { lng: -84.30292, lat: 34.119177, postalCode: "30004" },
    { lng: -84.57176, lat: 34.117565, postalCode: "30189" },
    { lng: -84.389676, lat: 33.752406, postalCode: "30335" },
    { lng: -84.86025, lat: 31.528299, postalCode: "31724" },
    { lng: -82.93375, lat: 32.526359, postalCode: "31021" },
    { lng: -82.70761, lat: 32.835471, postalCode: "31035" },
    { lng: -81.93594, lat: 31.945767, postalCode: "30427" },
    { lng: -84.73232, lat: 34.346142, postalCode: "30171" },
    { lng: -84.11655, lat: 33.872371, postalCode: "30047" },
    { lng: -84.82764, lat: 34.167327, postalCode: "30120" },
    { lng: -83.2673, lat: 33.954047, postalCode: "30683" },
    { lng: -84.35149, lat: 33.786755, postalCode: "30306" },
    { lng: -83.7231, lat: 34.923847, postalCode: "30546" },
    { lng: -83.35432, lat: 33.318579, postalCode: "31024" },
    { lng: -82.13949, lat: 33.551145, postalCode: "30809" },
    { lng: -84.72539, lat: 34.236128, postalCode: "30184" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31195" },
    { lng: -85.47893, lat: 34.553485, postalCode: "30731" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30361" },
    { lng: -81.75813, lat: 31.74991, postalCode: "31316" },
    { lng: -81.79396, lat: 32.969028, postalCode: "30456" },
    { lng: -83.70779, lat: 33.65072, postalCode: "30025" },
    { lng: -82.01018, lat: 33.474136, postalCode: "30904" },
    { lng: -83.876108, lat: 32.989503, postalCode: "31086" },
    { lng: -81.89818, lat: 32.327219, postalCode: "30452" },
    { lng: -82.05534, lat: 32.414401, postalCode: "30439" },
    { lng: -83.587419, lat: 34.130594, postalCode: "30599" },
    { lng: -80.84937, lat: 32.006672, postalCode: "31328" },
    { lng: -84.35779, lat: 33.818304, postalCode: "30324" },
    { lng: -84.73957, lat: 32.557718, postalCode: "31829" },
    { lng: -83.25811, lat: 34.511618, postalCode: "30538" },
    { lng: -84.94642, lat: 34.935105, postalCode: "30710" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30365" },
    { lng: -83.74735, lat: 33.504952, postalCode: "30055" },
    { lng: -83.21803, lat: 31.379665, postalCode: "31622" },
    { lng: -83.19316, lat: 30.965263, postalCode: "31699" },
    { lng: -84.04633, lat: 33.621906, postalCode: "30094" },
    { lng: -83.67795, lat: 32.632111, postalCode: "31028" },
    { lng: -83.05502, lat: 31.621764, postalCode: "31798" },
    { lng: -82.01237, lat: 33.387089, postalCode: "30906" },
    { lng: -83.19624, lat: 30.802411, postalCode: "31606" },
    { lng: -84.77533, lat: 34.200264, postalCode: "30121" },
    { lng: -84.5903, lat: 32.973728, postalCode: "30293" },
    { lng: -84.33611, lat: 33.872053, postalCode: "30319" },
    { lng: -85.030663, lat: 33.571329, postalCode: "30166" },
    { lng: -83.06063, lat: 33.863779, postalCode: "30648" },
    { lng: -82.94624, lat: 33.775127, postalCode: "30660" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30009" },
    { lng: -85.21956, lat: 34.801988, postalCode: "30739" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30379" },
    { lng: -83.01949, lat: 31.375165, postalCode: "31650" },
    { lng: -83.5282, lat: 31.003274, postalCode: "31625" },
    { lng: -84.60173, lat: 31.72952, postalCode: "31786" },
    { lng: -83.21997, lat: 34.041416, postalCode: "30628" },
    { lng: -82.6684, lat: 33.912362, postalCode: "30668" },
    { lng: -82.27322, lat: 31.496025, postalCode: "31551" },
    { lng: -83.38908, lat: 33.944339, postalCode: "30610" },
    { lng: -83.09656, lat: 34.338008, postalCode: "30520" },
    { lng: -82.31013, lat: 33.42359, postalCode: "30814" },
    { lng: -84.60879, lat: 32.862377, postalCode: "31816" },
    { lng: -82.20102, lat: 33.459489, postalCode: "30813" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31402" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31156" },
    { lng: -84.38472, lat: 33.830054, postalCode: "30305" },
    { lng: -83.72045, lat: 31.836577, postalCode: "31712" },
    { lng: -83.72204, lat: 32.88899, postalCode: "31210" },
    { lng: -85.02254, lat: 33.560454, postalCode: "30116" },
    { lng: -83.73515, lat: 31.484091, postalCode: "31789" },
    { lng: -84.42268, lat: 33.758056, postalCode: "30314" },
    { lng: -84.03346, lat: 33.325951, postalCode: "30234" },
    { lng: -84.84463, lat: 33.945735, postalCode: "30132" },
    { lng: -84.744595, lat: 33.68966, postalCode: "30133" },
    { lng: -83.76194, lat: 34.595859, postalCode: "30528" },
    { lng: -84.83809, lat: 33.670405, postalCode: "30187" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30357" },
    { lng: -83.98919, lat: 30.817654, postalCode: "31792" },
    { lng: -84.5788, lat: 33.907336, postalCode: "30008" },
    { lng: -84.105011, lat: 33.959535, postalCode: "30099" },
    { lng: -83.63529, lat: 32.830839, postalCode: "31201" },
    { lng: -84.21942, lat: 32.071641, postalCode: "31709" },
    { lng: -83.288117, lat: 34.120239, postalCode: "30647" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30351" },
    { lng: -84.91926, lat: 34.604648, postalCode: "30735" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31418" },
    { lng: -83.52604, lat: 32.13702, postalCode: "31071" },
    { lng: -84.458326, lat: 34.242944, postalCode: "30142" },
    { lng: -83.800628, lat: 32.946971, postalCode: "31004" },
    { lng: -83.36415, lat: 30.991859, postalCode: "31632" },
    { lng: -82.48797, lat: 32.03096, postalCode: "30473" },
    { lng: -84.557181, lat: 33.912473, postalCode: "30069" },
    { lng: -83.24825, lat: 33.863663, postalCode: "30619" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30903" },
    { lng: -81.101502, lat: 32.008183, postalCode: "31441" },
    { lng: -85.4043, lat: 34.376063, postalCode: "30730" },
    { lng: -82.96091, lat: 32.370333, postalCode: "31075" },
    { lng: -83.43559, lat: 31.183567, postalCode: "31647" },
    { lng: -81.627717, lat: 30.804684, postalCode: "31547" },
    { lng: -84.71191, lat: 32.896166, postalCode: "31830" },
    { lng: -83.64773, lat: 32.509384, postalCode: "31047" },
    { lng: -83.4073, lat: 34.952452, postalCode: "30568" },
    { lng: -84.19386, lat: 33.136567, postalCode: "30257" },
    { lng: -84.51475, lat: 34.027533, postalCode: "30066" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31199" },
    { lng: -84.25092, lat: 32.995865, postalCode: "30285" },
    { lng: -84.8346, lat: 33.892504, postalCode: "30157" },
    { lng: -83.62288, lat: 31.067313, postalCode: "31722" },
    { lng: -83.69126, lat: 30.802821, postalCode: "31629" },
    { lng: -84.92583, lat: 32.53554, postalCode: "31909" },
    { lng: -84.44513, lat: 32.614374, postalCode: "31812" },
    { lng: -84.762161, lat: 34.479066, postalCode: "30703" },
    { lng: -84.71552, lat: 32.698622, postalCode: "31831" },
    { lng: -83.1612, lat: 32.7615, postalCode: "31042" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31139" },
    { lng: -84.07456, lat: 33.891251, postalCode: "31141" },
    { lng: -83.48004, lat: 31.592199, postalCode: "31733" },
    { lng: -84.4843, lat: 34.108027, postalCode: "30188" },
    { lng: -83.58801, lat: 31.176563, postalCode: "31747" },
    { lng: -84.17441, lat: 34.235408, postalCode: "30040" },
    { lng: -84.32905, lat: 34.851171, postalCode: "30513" },
    { lng: -84.12921, lat: 31.3709, postalCode: "31716" },
    { lng: -83.88003, lat: 32.148624, postalCode: "31051" },
    { lng: -82.825, lat: 34.119972, postalCode: "30635" },
    { lng: -83.50035, lat: 34.469971, postalCode: "30511" },
    { lng: -83.06337, lat: 32.003035, postalCode: "31060" },
    { lng: -84.28317, lat: 34.949755, postalCode: "30559" },
    { lng: -82.23715, lat: 32.792831, postalCode: "30441" },
    { lng: -81.79512, lat: 31.003385, postalCode: "31568" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30363" },
    { lng: -83.2182, lat: 33.040103, postalCode: "31034" },
    { lng: -83.47936, lat: 33.588436, postalCode: "30650" },
    { lng: -82.6201, lat: 31.281103, postalCode: "31552" },
    { lng: -85.34001, lat: 34.118206, postalCode: "30124" },
    { lng: -81.12183, lat: 32.043058, postalCode: "31405" },
    { lng: -84.0964, lat: 33.750257, postalCode: "30058" },
    { lng: -82.22278, lat: 32.439708, postalCode: "30464" },
    { lng: -82.08872, lat: 33.32157, postalCode: "30815" },
    { lng: -84.288803, lat: 33.278205, postalCode: "30212" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30911" },
    { lng: -85.00736, lat: 32.638885, postalCode: "31808" },
    { lng: -84.19771, lat: 33.795622, postalCode: "30083" },
    { lng: -83.13302, lat: 30.72289, postalCode: "31636" },
    { lng: -82.41493, lat: 32.220155, postalCode: "30474" },
    { lng: -83.91813, lat: 34.188281, postalCode: "30542" },
    { lng: -82.50985, lat: 32.196136, postalCode: "30410" },
    { lng: -84.90942, lat: 32.479492, postalCode: "31907" },
    { lng: -83.65226, lat: 34.299459, postalCode: "30543" },
    { lng: -84.98499, lat: 32.1342, postalCode: "31821" },
    { lng: -81.17233, lat: 32.162963, postalCode: "31407" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31403" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30914" },
    { lng: -84.15381, lat: 33.676358, postalCode: "30038" },
    { lng: -84.176507, lat: 31.559274, postalCode: "31706" },
    { lng: -83.17539, lat: 34.491829, postalCode: "30557" },
    { lng: -84.07456, lat: 33.891251, postalCode: "31145" },
    { lng: -83.36207, lat: 34.597674, postalCode: "30598" },
    { lng: -84.387276, lat: 33.789255, postalCode: "30367" },
    { lng: -84.31774, lat: 34.032532, postalCode: "30076" },
    { lng: -84.570049, lat: 30.901863, postalCode: "31718" },
    { lng: -82.32636, lat: 33.244829, postalCode: "30818" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30077" },
    { lng: -82.713, lat: 33.224199, postalCode: "30820" },
    { lng: -81.39094, lat: 31.407598, postalCode: "31305" },
    { lng: -83.511055, lat: 33.754235, postalCode: "30623" },
    { lng: -84.4538, lat: 33.646984, postalCode: "30337" },
    { lng: -83.901, lat: 34.346102, postalCode: "30506" },
    { lng: -82.91713, lat: 32.094127, postalCode: "31037" },
    { lng: -84.71025, lat: 31.427845, postalCode: "31713" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30353" },
    { lng: -83.74189, lat: 34.713441, postalCode: "30545" },
    { lng: -83.87218, lat: 31.012042, postalCode: "31738" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30074" },
    { lng: -83.45328, lat: 31.291612, postalCode: "31637" },
    { lng: -81.83478, lat: 32.150061, postalCode: "30423" },
    { lng: -83.15548, lat: 33.892957, postalCode: "30630" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31193" },
    { lng: -81.16915, lat: 32.106705, postalCode: "31408" },
    { lng: -81.33121, lat: 32.129474, postalCode: "31302" },
    { lng: -83.96848, lat: 33.888829, postalCode: "30017" },
    { lng: -85.37183, lat: 34.957134, postalCode: "30750" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30091" },
    { lng: -81.98381, lat: 32.802448, postalCode: "30442" },
    { lng: -82.10699, lat: 32.186824, postalCode: "30421" },
    { lng: -84.47154, lat: 34.680011, postalCode: "30539" },
    { lng: -81.81036, lat: 32.697762, postalCode: "30455" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30371" },
    { lng: -85.00251, lat: 31.113122, postalCode: "31761" },
    { lng: -85.14282, lat: 33.723939, postalCode: "30110" },
    { lng: -81.3802, lat: 31.176516, postalCode: "31522" },
    { lng: -83.12842, lat: 34.270167, postalCode: "30662" },
    { lng: -81.90984, lat: 32.162077, postalCode: "30417" },
    { lng: -84.56087, lat: 33.817738, postalCode: "30126" },
    { lng: -83.89284, lat: 33.251174, postalCode: "30216" },
    { lng: -84.98005, lat: 32.471492, postalCode: "31901" },
    { lng: -82.47988, lat: 32.083879, postalCode: "30412" },
    { lng: -84.00419, lat: 34.875556, postalCode: "30512" },
    { lng: -84.557181, lat: 33.912473, postalCode: "30065" },
    { lng: -83.54118, lat: 34.514215, postalCode: "30531" },
    { lng: -84.289814, lat: 33.345253, postalCode: "30284" },
    { lng: -84.31252, lat: 33.101705, postalCode: "30295" },
    { lng: -84.62522, lat: 31.973541, postalCode: "31832" },
    { lng: -83.96555, lat: 31.177174, postalCode: "31756" },
    { lng: -83.14746, lat: 34.281721, postalCode: "30639" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30387" },
    { lng: -83.74571, lat: 32.335147, postalCode: "31025" },
    { lng: -84.28475, lat: 32.419094, postalCode: "31081" },
    { lng: -84.82543, lat: 33.230734, postalCode: "30220" },
    { lng: -84.46271, lat: 33.40147, postalCode: "30215" },
    { lng: -84.3764, lat: 33.745739, postalCode: "30312" },
    { lng: -84.2461, lat: 32.563544, postalCode: "31006" },
    { lng: -84.07456, lat: 33.891251, postalCode: "31119" },
    { lng: -84.21929, lat: 34.080035, postalCode: "30005" },
    { lng: -84.988536, lat: 34.9298, postalCode: "30756" },
    { lng: -85.172297, lat: 33.99994, postalCode: "30138" },
    { lng: -83.06006, lat: 32.108676, postalCode: "31011" },
    { lng: -81.37637, lat: 31.788811, postalCode: "31320" },
    { lng: -81.63543, lat: 32.754635, postalCode: "30467" },
    { lng: -83.851, lat: 33.581406, postalCode: "30014" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30368" },
    { lng: -84.18258, lat: 32.371371, postalCode: "31041" },
    { lng: -82.95019, lat: 32.860031, postalCode: "31067" },
    { lng: -83.98565, lat: 33.949054, postalCode: "30045" },
    { lng: -84.42981, lat: 34.925622, postalCode: "30541" },
    { lng: -83.877554, lat: 31.205365, postalCode: "31753" },
    { lng: -85.25824, lat: 34.696201, postalCode: "30728" },
    { lng: -84.22036, lat: 33.853853, postalCode: "30084" },
    { lng: -84.13045, lat: 34.025598, postalCode: "30095" },
    { lng: -83.864915, lat: 33.555791, postalCode: "30015" },
    { lng: -83.58491, lat: 33.957713, postalCode: "30666" },
    { lng: -84.37871, lat: 33.49348, postalCode: "30238" },
    { lng: -83.98382, lat: 33.633685, postalCode: "30013" },
    { lng: -84.81218, lat: 32.030297, postalCode: "31815" },
    { lng: -85.49945, lat: 34.793833, postalCode: "30738" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31498" },
    { lng: -82.81018, lat: 33.125097, postalCode: "31094" },
    { lng: -84.92452, lat: 34.368647, postalCode: "30103" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30385" },
    { lng: -85.03241, lat: 31.631333, postalCode: "31751" },
    { lng: -84.32187, lat: 33.015476, postalCode: "30256" },
    { lng: -84.43357, lat: 31.756995, postalCode: "31742" },
    { lng: -82.373889, lat: 32.177513, postalCode: "30475" },
    { lng: -84.26491, lat: 33.772122, postalCode: "30002" },
    { lng: -83.56747, lat: 31.951839, postalCode: "31072" },
    { lng: -84.07456, lat: 33.891251, postalCode: "31146" },
    { lng: -82.02194, lat: 30.860915, postalCode: "31537" },
    { lng: -81.37892, lat: 32.141974, postalCode: "31318" },
    { lng: -84.54711, lat: 30.803291, postalCode: "31752" },
    { lng: -81.97265, lat: 33.461121, postalCode: "30901" },
    { lng: -85.32883, lat: 34.827019, postalCode: "30707" },
    { lng: -84.03811, lat: 32.702635, postalCode: "31078" },
    { lng: -81.24568, lat: 32.110421, postalCode: "31322" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31209" },
    { lng: -82.8375, lat: 32.984529, postalCode: "31082" },
    { lng: -82.81923, lat: 32.046427, postalCode: "31083" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30812" },
    { lng: -82.49502, lat: 32.505913, postalCode: "30447" },
    { lng: -81.532489, lat: 31.241505, postalCode: "31521" },
    { lng: -85.02667, lat: 33.76324, postalCode: "30179" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30301" },
    { lng: -82.73595, lat: 31.718382, postalCode: "31532" },
    { lng: -82.68926, lat: 32.71732, postalCode: "31096" },
    { lng: -84.02897, lat: 31.258794, postalCode: "31784" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31203" },
    { lng: -84.60621, lat: 33.797456, postalCode: "30168" },
    { lng: -84.51726, lat: 31.454628, postalCode: "31762" },
    { lng: -81.62656, lat: 31.066479, postalCode: "31565" },
    { lng: -81.62339, lat: 32.3456, postalCode: "30415" },
    { lng: -83.37027, lat: 32.630784, postalCode: "31044" },
    { lng: -84.874123, lat: 32.491005, postalCode: "31997" },
    { lng: -84.15118, lat: 33.979401, postalCode: "30096" },
    { lng: -83.73541, lat: 32.460791, postalCode: "31069" },
    { lng: -83.42456, lat: 33.775001, postalCode: "30638" },
    { lng: -83.92941, lat: 33.0409, postalCode: "31029" },
    { lng: -84.87703, lat: 30.974364, postalCode: "31745" },
    { lng: -81.48447, lat: 32.172661, postalCode: "31308" },
    { lng: -84.38539, lat: 34.049789, postalCode: "30075" },
    { lng: -84.181547, lat: 32.051235, postalCode: "31710" },
    { lng: -83.692562, lat: 32.338639, postalCode: "31013" },
    { lng: -84.84122, lat: 32.566197, postalCode: "31820" },
    { lng: -83.71899, lat: 33.857423, postalCode: "30656" },
    { lng: -84.744595, lat: 33.68966, postalCode: "30154" },
    { lng: -85.15313, lat: 34.448075, postalCode: "30105" },
    { lng: -84.219594, lat: 31.543065, postalCode: "31782" },
    { lng: -84.68483, lat: 34.052285, postalCode: "30101" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30388" },
    { lng: -84.58724, lat: 33.48012, postalCode: "30290" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30390" },
    { lng: -84.577, lat: 34.332315, postalCode: "30183" },
    { lng: -83.94701, lat: 34.089419, postalCode: "30519" },
    { lng: -81.629062, lat: 32.767634, postalCode: "30424" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30912" },
    { lng: -84.80242, lat: 31.011595, postalCode: "31759" },
    { lng: -84.47131, lat: 33.724907, postalCode: "30311" },
    { lng: -84.464401, lat: 33.288993, postalCode: "30203" },
    { lng: -84.59822, lat: 32.527813, postalCode: "31801" },
    { lng: -82.62928, lat: 31.495983, postalCode: "31554" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31131" },
    { lng: -83.41732, lat: 33.945619, postalCode: "30606" },
    { lng: -83.31718, lat: 34.561584, postalCode: "30577" },
    { lng: -85.048995, lat: 33.04567, postalCode: "30261" },
    { lng: -84.43987, lat: 33.564711, postalCode: "30296" },
    { lng: -83.51083, lat: 31.459666, postalCode: "31794" },
    { lng: -84.69384, lat: 32.803518, postalCode: "31826" },
    { lng: -84.33084, lat: 32.895794, postalCode: "30286" },
    { lng: -84.874123, lat: 32.491005, postalCode: "31998" },
    { lng: -84.814173, lat: 33.361003, postalCode: "30264" },
    { lng: -84.25925, lat: 34.452988, postalCode: "30148" },
    { lng: -83.509934, lat: 34.629391, postalCode: "30596" },
    { lng: -83.4156, lat: 34.882362, postalCode: "30525" },
    { lng: -83.61433, lat: 33.421694, postalCode: "31085" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30036" },
    { lng: -83.44201, lat: 34.763553, postalCode: "30552" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31409" },
    { lng: -85.20291, lat: 33.84627, postalCode: "30113" },
    { lng: -81.42072, lat: 31.06419, postalCode: "31527" },
    { lng: -82.7749, lat: 33.285774, postalCode: "31045" },
    { lng: -83.66005, lat: 31.256287, postalCode: "31771" },
    { lng: -84.25994, lat: 33.791537, postalCode: "30079" },
    { lng: -84.54095, lat: 32.797475, postalCode: "31836" },
    { lng: -84.76444, lat: 31.781815, postalCode: "31740" },
    { lng: -82.5028, lat: 33.490536, postalCode: "30824" },
    { lng: -84.132357, lat: 31.591073, postalCode: "31708" },
    { lng: -84.711863, lat: 34.569015, postalCode: "30732" },
    { lng: -84.43884, lat: 34.194029, postalCode: "30115" },
    { lng: -81.3128, lat: 32.508239, postalCode: "31303" },
    { lng: -84.36704, lat: 33.615409, postalCode: "30297" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30374" },
    { lng: -83.62435, lat: 32.556668, postalCode: "31005" },
    { lng: -82.46303, lat: 33.234802, postalCode: "30823" },
    { lng: -85.03345, lat: 34.409661, postalCode: "30733" },
    { lng: -83.57934, lat: 31.652542, postalCode: "31790" },
    { lng: -83.249754, lat: 31.620866, postalCode: "31769" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30399" },
    { lng: -84.71062, lat: 33.858836, postalCode: "30081" },
    { lng: -83.87003, lat: 34.274355, postalCode: "30504" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30031" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30003" },
    { lng: -83.562922, lat: 34.593092, postalCode: "30544" },
    { lng: -81.63251, lat: 33.032445, postalCode: "30426" },
    { lng: -83.24549, lat: 32.600844, postalCode: "31017" },
    { lng: -83.51552, lat: 33.927792, postalCode: "30622" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31106" },
    { lng: -85.233721, lat: 34.333308, postalCode: "30163" },
    { lng: -83.67119, lat: 32.844872, postalCode: "31204" },
    { lng: -82.4023, lat: 32.872098, postalCode: "30477" },
    { lng: -83.91327, lat: 32.199721, postalCode: "31007" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30029" },
    { lng: -84.092267, lat: 33.962979, postalCode: "30026" },
    { lng: -83.950541, lat: 34.345432, postalCode: "30503" },
    { lng: -84.2098, lat: 34.887912, postalCode: "30560" },
    { lng: -83.516374, lat: 33.801274, postalCode: "30645" },
    { lng: -84.26822, lat: 33.639658, postalCode: "30294" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31107" },
    { lng: -83.89423, lat: 33.823809, postalCode: "30052" },
    { lng: -83.785365, lat: 31.917178, postalCode: "31010" },
    { lng: -82.14435, lat: 33.417282, postalCode: "30905" },
    { lng: -84.75579, lat: 34.124283, postalCode: "30137" },
    { lng: -84.977131, lat: 32.482142, postalCode: "31993" },
    { lng: -82.13812, lat: 31.1747, postalCode: "31542" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31212" },
    { lng: -85.181782, lat: 33.643002, postalCode: "30150" },
    { lng: -84.01804, lat: 34.12115, postalCode: "30518" },
    { lng: -82.82775, lat: 32.89115, postalCode: "31089" },
    { lng: -83.38908, lat: 33.944339, postalCode: "30608" },
    { lng: -84.46716, lat: 34.688716, postalCode: "30540" },
    { lng: -82.80015, lat: 33.560693, postalCode: "30664" },
    { lng: -84.93798, lat: 34.4966, postalCode: "30701" },
    { lng: -83.99718, lat: 34.542098, postalCode: "30533" },
    { lng: -83.16019, lat: 32.564036, postalCode: "31065" },
    { lng: -84.01487, lat: 34.001159, postalCode: "30043" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30364" },
    { lng: -84.019157, lat: 34.903398, postalCode: "30514" },
    { lng: -84.50402, lat: 34.228478, postalCode: "30114" },
    { lng: -85.14342, lat: 34.145931, postalCode: "30173" },
    { lng: -83.439433, lat: 31.172669, postalCode: "31610" },
    { lng: -84.27306, lat: 33.268185, postalCode: "30223" },
    { lng: -82.17048, lat: 32.547214, postalCode: "30471" },
    { lng: -83.97568, lat: 33.294522, postalCode: "30233" },
    { lng: -84.48229, lat: 30.743234, postalCode: "31715" },
    { lng: -85.213817, lat: 34.290508, postalCode: "30162" },
    { lng: -85.0745, lat: 33.029838, postalCode: "30240" },
    { lng: -84.75869, lat: 31.563327, postalCode: "31746" },
    { lng: -84.73756, lat: 33.701554, postalCode: "30135" },
    { lng: -85.52702, lat: 34.895625, postalCode: "30752" },
    { lng: -81.48627, lat: 32.567281, postalCode: "30446" },
    { lng: -84.59849, lat: 31.575228, postalCode: "31766" },
    { lng: -83.39828, lat: 33.962364, postalCode: "30613" },
    { lng: -84.38462, lat: 34.418116, postalCode: "30177" },
    { lng: -83.48517, lat: 32.270314, postalCode: "31036" },
    { lng: -84.37168, lat: 33.169646, postalCode: "30292" },
    { lng: -83.09905, lat: 32.516865, postalCode: "31022" },
    { lng: -83.06671, lat: 32.801223, postalCode: "31090" },
    { lng: -83.43565, lat: 34.013154, postalCode: "30607" },
    { lng: -84.4433, lat: 33.965601, postalCode: "30068" },
    { lng: -84.03707, lat: 33.822204, postalCode: "30039" },
    { lng: -84.298964, lat: 33.265656, postalCode: "30266" },
    { lng: -84.9865, lat: 34.766095, postalCode: "30720" },
    { lng: -84.429296, lat: 33.848196, postalCode: "30304" },
    { lng: -83.67216, lat: 32.79904, postalCode: "31206" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30999" },
    { lng: -83.78882, lat: 30.782963, postalCode: "31626" },
    { lng: -83.82352, lat: 34.306643, postalCode: "30501" },
    { lng: -84.31769, lat: 33.942751, postalCode: "30338" },
    { lng: -83.394574, lat: 31.989039, postalCode: "31084" },
    { lng: -84.47464, lat: 33.99605, postalCode: "30062" },
    { lng: -84.38525, lat: 33.794055, postalCode: "30309" },
    { lng: -84.41972, lat: 33.86427, postalCode: "30327" },
    { lng: -81.880222, lat: 31.578181, postalCode: "31599" },
    { lng: -83.252192, lat: 30.827965, postalCode: "31604" },
    { lng: -83.77808, lat: 31.950899, postalCode: "31015" },
    { lng: -84.58951, lat: 30.884525, postalCode: "31717" },
    { lng: -84.906483, lat: 32.534852, postalCode: "31908" },
    { lng: -83.57597, lat: 33.766057, postalCode: "30641" },
    { lng: -84.32775, lat: 33.97285, postalCode: "30350" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31150" },
    { lng: -84.09764, lat: 34.201503, postalCode: "30041" },
    { lng: -84.351273, lat: 33.500697, postalCode: "30237" },
    { lng: -84.94879, lat: 32.423311, postalCode: "31903" },
    { lng: -81.929165, lat: 33.050487, postalCode: "30811" },
    { lng: -83.27583, lat: 31.586426, postalCode: "31774" },
    { lng: -82.10743, lat: 31.372403, postalCode: "31557" },
    { lng: -84.77502, lat: 34.753129, postalCode: "30705" },
    { lng: -83.65979, lat: 34.391688, postalCode: "30554" },
    { lng: -85.233721, lat: 34.333308, postalCode: "30172" },
    { lng: -84.557181, lat: 33.912473, postalCode: "30007" },
    { lng: -81.20259, lat: 31.986249, postalCode: "31419" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31414" },
    { lng: -82.416543, lat: 31.018954, postalCode: "31502" },
    { lng: -83.88302, lat: 33.981721, postalCode: "30019" },
    { lng: -84.504403, lat: 33.403811, postalCode: "30232" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31422" },
    { lng: -83.77864, lat: 34.097915, postalCode: "30548" },
    { lng: -84.56854, lat: 33.232156, postalCode: "30229" },
    { lng: -81.64211, lat: 32.163245, postalCode: "31321" },
    { lng: -84.53744, lat: 33.859137, postalCode: "30082" },
    { lng: -82.69115, lat: 32.190739, postalCode: "30428" },
    { lng: -84.36269, lat: 31.33349, postalCode: "31770" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30323" },
    { lng: -81.90843, lat: 31.636895, postalCode: "31545" },
    { lng: -83.657207, lat: 32.70045, postalCode: "31297" },
    { lng: -81.68428, lat: 30.793409, postalCode: "31548" },
    { lng: -84.351273, lat: 33.500697, postalCode: "30287" },
    { lng: -85.1416, lat: 34.975939, postalCode: "30726" },
    { lng: -83.48164, lat: 33.804849, postalCode: "30621" },
    { lng: -84.29321, lat: 33.888102, postalCode: "30341" },
    { lng: -82.25779, lat: 31.307234, postalCode: "31516" },
    { lng: -82.86325, lat: 31.464973, postalCode: "31535" },
    { lng: -84.037859, lat: 33.960353, postalCode: "30046" },
    { lng: -85.1062, lat: 33.588486, postalCode: "30117" },
    { lng: -83.395669, lat: 31.046761, postalCode: "31267" },
    { lng: -84.02921, lat: 31.930451, postalCode: "31743" },
    { lng: -82.84153, lat: 31.522042, postalCode: "31533" },
    { lng: -83.77172, lat: 31.165358, postalCode: "31768" },
    { lng: -84.3895, lat: 32.587537, postalCode: "31039" },
    { lng: -83.51879, lat: 34.700311, postalCode: "30523" },
    { lng: -84.432127, lat: 33.707107, postalCode: "30330" },
    { lng: -84.240414, lat: 34.062176, postalCode: "30202" },
    { lng: -81.64534, lat: 31.77789, postalCode: "31333" },
    { lng: -81.67924, lat: 30.915778, postalCode: "31569" },
    { lng: -84.38365, lat: 33.709595, postalCode: "30315" },
    { lng: -84.397976, lat: 33.776305, postalCode: "30332" },
    { lng: -85.0557, lat: 31.169794, postalCode: "31732" },
    { lng: -83.16892, lat: 32.396381, postalCode: "31012" },
    { lng: -84.14812, lat: 32.912207, postalCode: "31097" },
    { lng: -84.69943, lat: 33.201986, postalCode: "30251" },
    { lng: -81.87901, lat: 32.914843, postalCode: "30822" },
    { lng: -83.89115, lat: 34.236556, postalCode: "30566" },
    { lng: -83.246084, lat: 31.205388, postalCode: "31659" },
    { lng: -83.45722, lat: 31.933079, postalCode: "31079" },
    { lng: -83.58913, lat: 34.455729, postalCode: "30510" },
    { lng: -84.17215, lat: 33.455031, postalCode: "30253" },
    { lng: -82.21503, lat: 33.297318, postalCode: "30805" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30356" },
    { lng: -83.83416, lat: 34.022974, postalCode: "30011" },
    { lng: -83.669655, lat: 32.487403, postalCode: "31095" },
    { lng: -82.73454, lat: 33.477946, postalCode: "30821" },
    { lng: -84.248211, lat: 31.56889, postalCode: "31721" },
    { lng: -83.79025, lat: 31.526584, postalCode: "31781" },
    { lng: -81.60109, lat: 31.886944, postalCode: "31315" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30321" },
    { lng: -84.35727, lat: 31.832214, postalCode: "31726" },
    { lng: -82.11842, lat: 32.301639, postalCode: "30420" },
    { lng: -83.77348, lat: 34.253714, postalCode: "30507" },
    { lng: -83.93372, lat: 33.542118, postalCode: "30016" },
    { lng: -84.73593, lat: 33.034761, postalCode: "30222" },
    { lng: -82.86455, lat: 30.96373, postalCode: "31630" },
    { lng: -83.30078, lat: 30.866613, postalCode: "31602" },
    { lng: -83.927785, lat: 30.868187, postalCode: "31758" },
    { lng: -84.25367, lat: 33.899381, postalCode: "30340" },
    { lng: -84.17938, lat: 33.762106, postalCode: "30088" },
    { lng: -82.43899, lat: 33.765566, postalCode: "30817" },
    { lng: -84.08154, lat: 33.464387, postalCode: "30252" },
    { lng: -83.965099, lat: 33.295159, postalCode: "30283" },
    { lng: -82.324475, lat: 32.566594, postalCode: "30466" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30386" },
    { lng: -85.03867, lat: 34.85973, postalCode: "30755" },
    { lng: -83.73524, lat: 33.928949, postalCode: "30620" },
    { lng: -85.32928, lat: 34.489848, postalCode: "30747" },
    { lng: -84.14294, lat: 34.020621, postalCode: "30097" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31205" },
    { lng: -84.516327, lat: 33.625894, postalCode: "30272" },
    { lng: -84.07456, lat: 33.891251, postalCode: "30037" },
    { lng: -83.6513, lat: 32.646236, postalCode: "31099" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30394" },
    { lng: -84.219594, lat: 31.543065, postalCode: "31703" },
    { lng: -82.58135, lat: 33.236721, postalCode: "30810" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30380" },
    { lng: -83.503621, lat: 31.485438, postalCode: "31793" },
    { lng: -84.58017, lat: 33.281102, postalCode: "30276" },
    { lng: -85.25212, lat: 34.948979, postalCode: "30742" },
    { lng: -82.58028, lat: 30.680867, postalCode: "31631" },
    { lng: -84.7113, lat: 34.945576, postalCode: "30708" },
    { lng: -83.25332, lat: 30.92205, postalCode: "31605" },
    { lng: -83.9628, lat: 31.723178, postalCode: "31772" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31296" },
    { lng: -84.756109, lat: 33.351401, postalCode: "30271" },
    { lng: -84.07943, lat: 34.731084, postalCode: "30572" },
    { lng: -81.9944, lat: 33.080965, postalCode: "30830" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31192" },
    { lng: -83.2188, lat: 31.199, postalCode: "31639" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30376" },
    { lng: -81.6098, lat: 31.764663, postalCode: "31301" },
    { lng: -82.090996, lat: 33.386041, postalCode: "30919" },
    { lng: -84.21114, lat: 33.725739, postalCode: "30035" },
    { lng: -84.26906, lat: 33.582877, postalCode: "30273" },
    { lng: -82.08063, lat: 30.518903, postalCode: "31646" },
    { lng: -84.93994, lat: 34.780699, postalCode: "30721" },
    { lng: -81.9242, lat: 32.555659, postalCode: "30450" },
    { lng: -84.874123, lat: 32.491005, postalCode: "31917" },
    { lng: -84.39013, lat: 33.752856, postalCode: "30303" },
    { lng: -83.47201, lat: 34.549681, postalCode: "30563" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30378" },
    { lng: -84.82038, lat: 32.801174, postalCode: "31823" },
    { lng: -83.4905, lat: 32.719907, postalCode: "31020" },
    { lng: -83.07103, lat: 31.055578, postalCode: "31635" },
    { lng: -83.68012, lat: 34.696896, postalCode: "30571" },
    { lng: -84.557181, lat: 33.912473, postalCode: "30006" },
    { lng: -84.2062, lat: 33.940768, postalCode: "30071" },
    { lng: -83.18538, lat: 33.748344, postalCode: "30671" },
    { lng: -84.87908, lat: 31.650442, postalCode: "31736" },
    { lng: -84.47405, lat: 33.844371, postalCode: "31191" },
    { lng: -84.1794, lat: 33.909952, postalCode: "30093" },
    { lng: -83.59829, lat: 32.614742, postalCode: "31098" },
    { lng: -85.182085, lat: 34.280355, postalCode: "30149" },
    { lng: -82.30514, lat: 33.620669, postalCode: "30802" },
    { lng: -83.78604, lat: 34.121974, postalCode: "30517" },
    { lng: -84.97753, lat: 32.513391, postalCode: "31904" },
    { lng: -83.78599, lat: 34.481514, postalCode: "30527" },
    { lng: -84.602, lat: 33.946735, postalCode: "30064" },
    { lng: -81.97244, lat: 31.17326, postalCode: "31553" },
    { lng: -84.00588, lat: 32.799959, postalCode: "31066" },
    { lng: -84.458326, lat: 34.242944, postalCode: "30146" },
    { lng: -84.0615, lat: 30.977162, postalCode: "31773" },
    { lng: -84.20642, lat: 33.791505, postalCode: "30072" },
    { lng: -83.38389, lat: 34.209907, postalCode: "30530" },
    { lng: -82.80959, lat: 32.569122, postalCode: "31027" },
    { lng: -82.115541, lat: 31.412649, postalCode: "31556" },
    { lng: -84.47405, lat: 33.844371, postalCode: "30355" },
    { lng: -83.18058, lat: 31.953407, postalCode: "31077" },
    { lng: -82.44618, lat: 31.551458, postalCode: "31510" },
    { lng: -84.52728, lat: 33.715558, postalCode: "30331" },
    { lng: -82.09491, lat: 33.512541, postalCode: "30907" },
    { lng: -85.14435, lat: 34.916552, postalCode: "30736" },
    { lng: -84.50382, lat: 33.87578, postalCode: "30080" },
    { lng: -84.874123, lat: 32.491005, postalCode: "31994" },
    { lng: -83.61157, lat: 33.614556, postalCode: "30663" },
    { lng: -84.21809, lat: 33.547578, postalCode: "30281" },
    { lng: -81.07156, lat: 31.971394, postalCode: "31420" },
    { lng: -84.10849, lat: 34.416521, postalCode: "30534" },
    { lng: -84.95128, lat: 32.468442, postalCode: "31906" },
    { lng: -84.06866, lat: 31.98309, postalCode: "31764" },
    { lng: -84.66063, lat: 32.109225, postalCode: "31825" },
    { lng: -84.11732, lat: 32.861386, postalCode: "31016" },
    { lng: -84.95803, lat: 32.722333, postalCode: "31811" },
    { lng: -85.073582, lat: 33.618931, postalCode: "30119" },
    { lng: -83.64332, lat: 32.638316, postalCode: "31093" },
    { lng: -84.94739, lat: 31.834324, postalCode: "31767" },
    { lng: -82.52017, lat: 33.163467, postalCode: "30803" },
    { lng: -83.19797, lat: 33.547041, postalCode: "30642" },
    { lng: -82.036, lat: 32.632683, postalCode: "30425" },
    { lng: -83.691315, lat: 32.806707, postalCode: "31202" },
    { lng: -83.86484, lat: 34.954638, postalCode: "30582" },
    { lng: -82.50969, lat: 32.884137, postalCode: "30413" },
    { lng: -81.39979, lat: 32.183211, postalCode: "31307" },
    { lng: -84.76558, lat: 34.93882, postalCode: "30711" },
    { lng: -83.28661, lat: 30.807279, postalCode: "31601" },
    { lng: -84.32753, lat: 33.647275, postalCode: "30288" },
    { lng: -84.94899, lat: 31.334337, postalCode: "31723" },
    { lng: -82.0697, lat: 33.475936, postalCode: "30909" },
    { lng: -83.792379, lat: 31.172696, postalCode: "31776" },
    { lng: -84.71206, lat: 33.400875, postalCode: "30265" },
    { lng: -84.51526, lat: 31.905462, postalCode: "31777" },
    { lng: -85.43232, lat: 34.954188, postalCode: "30757" },
    { lng: -84.05767, lat: 31.082141, postalCode: "31765" },
    { lng: -82.416543, lat: 31.018954, postalCode: "31564" },
    { lng: -84.01732, lat: 33.696714, postalCode: "30012" },
    { lng: -82.51381, lat: 32.69992, postalCode: "31049" },
    { lng: -81.482551, lat: 31.221995, postalCode: "31524" },
    { lng: -84.07482, lat: 33.91967, postalCode: "30044" },
    { lng: -85.27405, lat: 34.96311, postalCode: "30741" },
    { lng: -85.12243, lat: 33.298014, postalCode: "30217" },
    { lng: -84.955843, lat: 32.524252, postalCode: "31902" },
    { lng: -84.28335, lat: 33.811354, postalCode: "30033" },
    { lng: -82.96137, lat: 31.840974, postalCode: "31544" },
    { lng: -83.794858, lat: 34.212993, postalCode: "30502" },
    { lng: -84.39682, lat: 33.658834, postalCode: "30354" },
    { lng: -85.07534, lat: 31.860476, postalCode: "31754" },
    { lng: -84.2387, lat: 33.809604, postalCode: "30021" },
    { lng: -81.998348, lat: 32.050694, postalCode: "30499" },
    { lng: -83.6793, lat: 32.730641, postalCode: "31216" },
    { lng: -82.61607, lat: 32.976548, postalCode: "31018" },
    { lng: -83.93413, lat: 32.446656, postalCode: "31057" },
    { lng: -84.31854, lat: 33.750005, postalCode: "30317" },
    { lng: -84.46615, lat: 33.87507, postalCode: "30339" },
    { lng: -83.55078, lat: 33.003455, postalCode: "31032" },
    { lng: -82.086658, lat: 32.414349, postalCode: "30451" },
    { lng: -82.59171, lat: 32.186824, postalCode: "30445" },
    { lng: -86.21885, lat: 40.134736, postalCode: "46069" },
    { lng: -85.84083, lat: 41.48922, postalCode: "46553" },
    { lng: -87.41107, lat: 39.470986, postalCode: "47809" },
    { lng: -85.52036, lat: 40.035904, postalCode: "47356" },
    { lng: -85.15149, lat: 41.44551, postalCode: "46730" },
    { lng: -85.43636, lat: 40.215398, postalCode: "47304" },
    { lng: -86.10175, lat: 39.805841, postalCode: "46218" },
    { lng: -86.13717, lat: 41.741872, postalCode: "46530" },
    { lng: -87.1754, lat: 39.758142, postalCode: "47872" },
    { lng: -86.25409, lat: 39.795593, postalCode: "46224" },
    { lng: -87.410094, lat: 39.433602, postalCode: "47814" },
    { lng: -87.55539, lat: 38.507039, postalCode: "47524" },
    { lng: -85.864267, lat: 41.239365, postalCode: "46581" },
    { lng: -86.42047, lat: 41.547845, postalCode: "46554" },
    { lng: -86.1918, lat: 40.765286, postalCode: "46961" },
    { lng: -85.896961, lat: 38.298486, postalCode: "47146" },
    { lng: -86.61296, lat: 38.584248, postalCode: "47469" },
    { lng: -86.06805, lat: 38.287227, postalCode: "47114" },
    { lng: -86.26918, lat: 40.427588, postalCode: "46979" },
    { lng: -86.52344, lat: 39.761211, postalCode: "46122" },
    { lng: -86.88781, lat: 40.19705, postalCode: "47955" },
    { lng: -86.53196, lat: 39.65983, postalCode: "46118" },
    { lng: -85.21936, lat: 38.896209, postalCode: "47224" },
    { lng: -86.00872, lat: 39.95645, postalCode: "46038" },
    { lng: -85.29184, lat: 40.708804, postalCode: "46766" },
    { lng: -86.61356, lat: 38.328419, postalCode: "47116" },
    { lng: -85.64367, lat: 38.872926, postalCode: "47227" },
    { lng: -87.410094, lat: 39.433602, postalCode: "47869" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46230" },
    { lng: -84.89067, lat: 39.831061, postalCode: "47374" },
    { lng: -85.32693, lat: 41.238825, postalCode: "46723" },
    { lng: -85.96834, lat: 38.237852, postalCode: "47136" },
    { lng: -86.516419, lat: 39.155801, postalCode: "47048" },
    { lng: -86.545732, lat: 39.012984, postalCode: "47434" },
    { lng: -85.81927, lat: 41.281518, postalCode: "46582" },
    { lng: -85.16711, lat: 41.243248, postalCode: "46748" },
    { lng: -86.573952, lat: 40.584272, postalCode: "46916" },
    { lng: -85.03134, lat: 41.578442, postalCode: "46779" },
    { lng: -86.35313, lat: 38.37482, postalCode: "47140" },
    { lng: -85.21961, lat: 39.291624, postalCode: "47006" },
    { lng: -86.292991, lat: 41.596769, postalCode: "46680" },
    { lng: -86.163826, lat: 39.246268, postalCode: "47488" },
    { lng: -85.732062, lat: 38.286819, postalCode: "47144" },
    { lng: -87.310042, lat: 40.606301, postalCode: "47976" },
    { lng: -86.76381, lat: 40.487219, postalCode: "47924" },
    { lng: -86.61733, lat: 39.96354, postalCode: "46147" },
    { lng: -86.93664, lat: 40.461012, postalCode: "47906" },
    { lng: -87.05507, lat: 39.983709, postalCode: "47916" },
    { lng: -86.44545, lat: 41.361567, postalCode: "46513" },
    { lng: -86.10839, lat: 39.775125, postalCode: "46201" },
    { lng: -87.38396, lat: 40.300013, postalCode: "47993" },
    { lng: -86.73576, lat: 39.961958, postalCode: "47968" },
    { lng: -85.7737, lat: 38.544777, postalCode: "47126" },
    { lng: -85.68403, lat: 41.525634, postalCode: "46543" },
    { lng: -87.46511, lat: 39.508235, postalCode: "47885" },
    { lng: -87.34174, lat: 38.588354, postalCode: "47557" },
    { lng: -87.04867, lat: 40.319429, postalCode: "47992" },
    { lng: -85.60373, lat: 38.543388, postalCode: "47141" },
    { lng: -85.35463, lat: 41.551546, postalCode: "46795" },
    { lng: -85.732062, lat: 38.286819, postalCode: "47199" },
    { lng: -85.81795, lat: 41.22163, postalCode: "46590" },
    { lng: -84.92161, lat: 40.736015, postalCode: "46772" },
    { lng: -85.74168, lat: 41.105868, postalCode: "46566" },
    { lng: -84.99462, lat: 38.77905, postalCode: "47045" },
    { lng: -85.1453, lat: 38.966859, postalCode: "47021" },
    { lng: -86.055202, lat: 38.609733, postalCode: "47169" },
    { lng: -85.7758, lat: 40.996628, postalCode: "46962" },
    { lng: -86.05043, lat: 39.482105, postalCode: "46131" },
    { lng: -87.21957, lat: 40.294316, postalCode: "47918" },
    { lng: -86.196485, lat: 41.688432, postalCode: "46546" },
    { lng: -86.32023, lat: 41.347159, postalCode: "46563" },
    { lng: -86.07877, lat: 41.672698, postalCode: "46561" },
    { lng: -86.03829, lat: 41.040643, postalCode: "46910" },
    { lng: -86.3076, lat: 41.458188, postalCode: "46537" },
    { lng: -86.893614, lat: 40.040014, postalCode: "47939" },
    { lng: -86.57394, lat: 40.950873, postalCode: "46985" },
    { lng: -85.491788, lat: 39.292004, postalCode: "47261" },
    { lng: -85.16794, lat: 41.732206, postalCode: "46776" },
    { lng: -86.27469, lat: 38.962997, postalCode: "47264" },
    { lng: -86.373314, lat: 39.77127, postalCode: "46332" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46251" },
    { lng: -87.30865, lat: 38.867886, postalCode: "47535" },
    { lng: -86.370409, lat: 39.712549, postalCode: "46768" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46275" },
    { lng: -85.539454, lat: 41.532773, postalCode: "46671" },
    { lng: -86.36988, lat: 38.200101, postalCode: "47137" },
    { lng: -85.38114, lat: 40.164393, postalCode: "47302" },
    { lng: -86.87605, lat: 41.083487, postalCode: "47957" },
    { lng: -87.221819, lat: 39.779078, postalCode: "47830" },
    { lng: -86.893614, lat: 40.040014, postalCode: "47934" },
    { lng: -87.34452, lat: 39.46706, postalCode: "47803" },
    { lng: -87.56655, lat: 39.061837, postalCode: "47861" },
    { lng: -86.9845, lat: 39.123925, postalCode: "47471" },
    { lng: -86.230212, lat: 39.196272, postalCode: "47435" },
    { lng: -85.389978, lat: 40.079197, postalCode: "47367" },
    { lng: -85.0692, lat: 41.006281, postalCode: "46816" },
    { lng: -86.24498, lat: 41.701415, postalCode: "46556" },
    { lng: -86.39699, lat: 39.764436, postalCode: "46123" },
    { lng: -87.04626, lat: 38.660051, postalCode: "47558" },
    { lng: -85.76711, lat: 38.603451, postalCode: "47177" },
    { lng: -87.4346, lat: 40.692374, postalCode: "47942" },
    { lng: -86.27329, lat: 41.672543, postalCode: "46629" },
    { lng: -86.18812, lat: 39.514024, postalCode: "46106" },
    { lng: -87.0006, lat: 38.413906, postalCode: "47545" },
    { lng: -86.77019, lat: 41.212184, postalCode: "46366" },
    { lng: -86.28619, lat: 39.89792, postalCode: "46278" },
    { lng: -87.10155, lat: 38.356658, postalCode: "47590" },
    { lng: -87.3793, lat: 38.957093, postalCode: "47838" },
    { lng: -87.30217, lat: 38.477037, postalCode: "47567" },
    { lng: -85.75075, lat: 39.997694, postalCode: "46064" },
    { lng: -87.23587, lat: 39.255569, postalCode: "47858" },
    { lng: -86.15262, lat: 40.041325, postalCode: "46074" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46854" },
    { lng: -86.34952, lat: 40.569059, postalCode: "46998" },
    { lng: -85.80264, lat: 38.749542, postalCode: "47102" },
    { lng: -85.98073, lat: 39.651145, postalCode: "46259" },
    { lng: -86.828402, lat: 39.668441, postalCode: "46170" },
    { lng: -85.78008, lat: 39.370375, postalCode: "47234" },
    { lng: -86.501543, lat: 39.173234, postalCode: "47407" },
    { lng: -87.39996, lat: 39.470986, postalCode: "47807" },
    { lng: -86.6512, lat: 39.298595, postalCode: "47464" },
    { lng: -87.38029, lat: 39.182707, postalCode: "47879" },
    { lng: -87.47013, lat: 39.830942, postalCode: "47847" },
    { lng: -87.46611, lat: 39.946144, postalCode: "47928" },
    { lng: -86.894908, lat: 40.388656, postalCode: "47902" },
    { lng: -86.206925, lat: 41.040988, postalCode: "46922" },
    { lng: -85.23878, lat: 41.054247, postalCode: "46804" },
    { lng: -86.71714, lat: 38.184046, postalCode: "47515" },
    { lng: -87.20987, lat: 38.384501, postalCode: "47598" },
    { lng: -84.848442, lat: 39.757864, postalCode: "47324" },
    { lng: -85.75936, lat: 38.387722, postalCode: "47172" },
    { lng: -86.47439, lat: 38.311323, postalCode: "47118" },
    { lng: -85.319241, lat: 41.536899, postalCode: "46786" },
    { lng: -87.5449, lat: 39.193884, postalCode: "47849" },
    { lng: -86.25043, lat: 41.654547, postalCode: "46613" },
    { lng: -85.63924, lat: 41.386918, postalCode: "46732" },
    { lng: -85.58067, lat: 39.171916, postalCode: "47283" },
    { lng: -85.91774, lat: 38.457573, postalCode: "47106" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46885" },
    { lng: -86.28509, lat: 39.648251, postalCode: "46183" },
    { lng: -86.088914, lat: 39.597444, postalCode: "46145" },
    { lng: -86.12734, lat: 39.601649, postalCode: "46143" },
    { lng: -87.06369, lat: 40.071792, postalCode: "47990" },
    { lng: -85.43806, lat: 39.802917, postalCode: "47337" },
    { lng: -86.12323, lat: 39.866913, postalCode: "46220" },
    { lng: -86.05223, lat: 38.165541, postalCode: "47160" },
    { lng: -86.88963, lat: 40.860496, postalCode: "47959" },
    { lng: -86.50412, lat: 41.708183, postalCode: "46552" },
    { lng: -85.28232, lat: 40.916397, postalCode: "46799" },
    { lng: -85.152604, lat: 40.611419, postalCode: "46778" },
    { lng: -85.69409, lat: 41.685374, postalCode: "46540" },
    { lng: -85.62657, lat: 39.894667, postalCode: "46186" },
    { lng: -85.26017, lat: 39.035173, postalCode: "47042" },
    { lng: -86.1692, lat: 41.450744, postalCode: "46506" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46897" },
    { lng: -86.308673, lat: 39.580105, postalCode: "46518" },
    { lng: -87.35609, lat: 39.783738, postalCode: "47862" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46858" },
    { lng: -86.12107, lat: 41.208493, postalCode: "46570" },
    { lng: -85.84894, lat: 38.802486, postalCode: "47229" },
    { lng: -87.04635, lat: 39.881586, postalCode: "47989" },
    { lng: -86.10749, lat: 40.614644, postalCode: "46959" },
    { lng: -85.00994, lat: 39.802089, postalCode: "47330" },
    { lng: -85.14637, lat: 40.495879, postalCode: "47369" },
    { lng: -86.206925, lat: 41.040988, postalCode: "46945" },
    { lng: -85.86852, lat: 39.28249, postalCode: "47226" },
    { lng: -85.59288, lat: 41.464213, postalCode: "46767" },
    { lng: -87.15089, lat: 40.205056, postalCode: "47969" },
    { lng: -86.26679, lat: 40.41663, postalCode: "46369" },
    { lng: -86.96864, lat: 38.302272, postalCode: "47542" },
    { lng: -86.394248, lat: 40.495181, postalCode: "46915" },
    { lng: -86.56537, lat: 37.934212, postalCode: "47574" },
    { lng: -87.29997, lat: 39.185497, postalCode: "47855" },
    { lng: -86.08875, lat: 39.973731, postalCode: "46033" },
    { lng: -87.410094, lat: 39.433602, postalCode: "47801" },
    { lng: -85.04618, lat: 39.883711, postalCode: "47345" },
    { lng: -86.573952, lat: 40.584272, postalCode: "46977" },
    { lng: -87.06795, lat: 40.170546, postalCode: "47994" },
    { lng: -85.5056, lat: 40.186603, postalCode: "47396" },
    { lng: -85.19406, lat: 38.93049, postalCode: "47017" },
    { lng: -85.95928, lat: 39.542985, postalCode: "46162" },
    { lng: -85.34315, lat: 40.967646, postalCode: "46783" },
    { lng: -86.12978, lat: 38.208144, postalCode: "47112" },
    { lng: -86.74308, lat: 38.924067, postalCode: "47453" },
    { lng: -86.29997, lat: 38.344403, postalCode: "47145" },
    { lng: -86.61127, lat: 41.411513, postalCode: "46532" },
    { lng: -85.5198, lat: 39.904323, postalCode: "47351" },
    { lng: -85.26765, lat: 41.449897, postalCode: "46755" },
    { lng: -86.469044, lat: 40.304473, postalCode: "46046" },
    { lng: -85.28118, lat: 40.550511, postalCode: "47359" },
    { lng: -84.94437, lat: 38.881346, postalCode: "47019" },
    { lng: -86.12948, lat: 39.902039, postalCode: "46240" },
    { lng: -87.475871, lat: 39.371187, postalCode: "47870" },
    { lng: -85.35825, lat: 41.634457, postalCode: "46761" },
    { lng: -86.56779, lat: 39.188246, postalCode: "47404" },
    { lng: -85.14049, lat: 39.126201, postalCode: "47031" },
    { lng: -86.64102, lat: 39.522181, postalCode: "46125" },
    { lng: -85.090669, lat: 41.126298, postalCode: "46865" },
    { lng: -85.64945, lat: 39.661964, postalCode: "46144" },
    { lng: -85.90405, lat: 38.700523, postalCode: "47139" },
    { lng: -85.37602, lat: 40.221103, postalCode: "47303" },
    { lng: -85.81792, lat: 41.725061, postalCode: "46507" },
    { lng: -85.20421, lat: 40.187403, postalCode: "47368" },
    { lng: -84.91113, lat: 39.37427, postalCode: "47016" },
    { lng: -86.28252, lat: 39.960858, postalCode: "46077" },
    { lng: -85.23342, lat: 40.942397, postalCode: "46798" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46898" },
    { lng: -86.70118, lat: 38.316017, postalCode: "47513" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46861" },
    { lng: -85.05941, lat: 41.138599, postalCode: "46835" },
    { lng: -85.48313, lat: 41.166034, postalCode: "46725" },
    { lng: -85.28428, lat: 39.609926, postalCode: "46133" },
    { lng: -85.7457, lat: 40.898561, postalCode: "46990" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46851" },
    { lng: -86.80388, lat: 39.903358, postalCode: "47954" },
    { lng: -86.1833, lat: 39.668795, postalCode: "46217" },
    { lng: -85.38747, lat: 39.07308, postalCode: "47023" },
    { lng: -86.292991, lat: 41.596769, postalCode: "46634" },
    { lng: -85.92481, lat: 39.56578, postalCode: "46110" },
    { lng: -85.442772, lat: 40.162093, postalCode: "47307" },
    { lng: -86.893614, lat: 40.040014, postalCode: "47937" },
    { lng: -84.98664, lat: 41.081548, postalCode: "46774" },
    { lng: -85.398625, lat: 39.931785, postalCode: "47366" },
    { lng: -87.19961, lat: 39.160126, postalCode: "47438" },
    { lng: -86.14677, lat: 40.501576, postalCode: "46901" },
    { lng: -85.82678, lat: 40.504346, postalCode: "46986" },
    { lng: -85.00759, lat: 40.954897, postalCode: "46745" },
    { lng: -87.20462, lat: 39.377883, postalCode: "47846" },
    { lng: -85.13569, lat: 41.334204, postalCode: "46738" },
    { lng: -85.44341, lat: 40.005999, postalCode: "47388" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46868" },
    { lng: -87.13635, lat: 39.813543, postalCode: "47856" },
    { lng: -85.377969, lat: 40.461432, postalCode: "47398" },
    { lng: -85.9456, lat: 39.185341, postalCode: "47201" },
    { lng: -86.15673, lat: 39.782842, postalCode: "46202" },
    { lng: -85.84047, lat: 39.93549, postalCode: "46040" },
    { lng: -85.24984, lat: 40.641626, postalCode: "46781" },
    { lng: -85.57101, lat: 39.740158, postalCode: "46115" },
    { lng: -86.30867, lat: 40.366648, postalCode: "46039" },
    { lng: -86.11894, lat: 40.469625, postalCode: "46903" },
    { lng: -86.61928, lat: 39.996577, postalCode: "46102" },
    { lng: -85.56716, lat: 39.90952, postalCode: "47384" },
    { lng: -86.79169, lat: 39.513388, postalCode: "46120" },
    { lng: -86.11766, lat: 39.742593, postalCode: "46203" },
    { lng: -85.292474, lat: 41.103797, postalCode: "46704" },
    { lng: -85.14481, lat: 41.046848, postalCode: "46807" },
    { lng: -86.65297, lat: 40.597574, postalCode: "46923" },
    { lng: -86.50293, lat: 39.183164, postalCode: "47408" },
    { lng: -85.49272, lat: 39.049336, postalCode: "47223" },
    { lng: -85.726384, lat: 38.897246, postalCode: "47070" },
    { lng: -84.82042, lat: 39.567147, postalCode: "47003" },
    { lng: -87.09905, lat: 38.470473, postalCode: "47564" },
    { lng: -85.83363, lat: 39.680058, postalCode: "46130" },
    { lng: -85.63325, lat: 39.011225, postalCode: "47265" },
    { lng: -86.215482, lat: 40.442117, postalCode: "46995" },
    { lng: -86.64494, lat: 39.852098, postalCode: "46165" },
    { lng: -85.736, lat: 41.411075, postalCode: "46567" },
    { lng: -86.234589, lat: 41.628483, postalCode: "46612" },
    { lng: -86.332995, lat: 39.779741, postalCode: "46243" },
    { lng: -86.26288, lat: 38.648032, postalCode: "47108" },
    { lng: -85.015983, lat: 39.860417, postalCode: "47375" },
    { lng: -87.46293, lat: 39.580862, postalCode: "47863" },
    { lng: -86.52474, lat: 38.896413, postalCode: "47451" },
    { lng: -87.0721, lat: 39.536856, postalCode: "47853" },
    { lng: -85.72871, lat: 40.27046, postalCode: "46063" },
    { lng: -86.961577, lat: 39.037144, postalCode: "47439" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46899" },
    { lng: -85.828846, lat: 40.671369, postalCode: "46984" },
    { lng: -86.563025, lat: 41.046274, postalCode: "46966" },
    { lng: -85.71245, lat: 38.838706, postalCode: "47270" },
    { lng: -86.204238, lat: 40.694298, postalCode: "46967" },
    { lng: -86.23359, lat: 40.870115, postalCode: "46988" },
    { lng: -86.73117, lat: 39.448838, postalCode: "47456" },
    { lng: -87.453249, lat: 39.952253, postalCode: "47288" },
    { lng: -87.20706, lat: 40.512643, postalCode: "47986" },
    { lng: -86.101484, lat: 40.412741, postalCode: "46965" },
    { lng: -87.475747, lat: 39.041245, postalCode: "47864" },
    { lng: -86.26393, lat: 40.947157, postalCode: "46931" },
    { lng: -87.14796, lat: 40.163921, postalCode: "47958" },
    { lng: -85.97379, lat: 41.259773, postalCode: "46502" },
    { lng: -85.61514, lat: 39.805933, postalCode: "46117" },
    { lng: -85.30984, lat: 41.044502, postalCode: "46814" },
    { lng: -87.24536, lat: 39.573663, postalCode: "47851" },
    { lng: -84.85887, lat: 39.129059, postalCode: "47025" },
    { lng: -85.80252, lat: 39.95634, postalCode: "46048" },
    { lng: -87.301367, lat: 39.388638, postalCode: "47871" },
    { lng: -85.60914, lat: 38.982269, postalCode: "47282" },
    { lng: -85.96698, lat: 38.300674, postalCode: "47122" },
    { lng: -85.744328, lat: 40.752777, postalCode: "46692" },
    { lng: -85.38566, lat: 40.058307, postalCode: "47386" },
    { lng: -86.64799, lat: 41.048063, postalCode: "46996" },
    { lng: -85.46546, lat: 39.876764, postalCode: "47344" },
    { lng: -87.11502, lat: 39.625607, postalCode: "47837" },
    { lng: -86.922974, lat: 39.952416, postalCode: "47765" },
    { lng: -85.489198, lat: 40.829196, postalCode: "46713" },
    { lng: -86.501543, lat: 39.173234, postalCode: "47426" },
    { lng: -85.0223, lat: 41.656798, postalCode: "46703" },
    { lng: -85.86356, lat: 39.232235, postalCode: "47203" },
    { lng: -85.80304, lat: 38.684426, postalCode: "47170" },
    { lng: -86.67821, lat: 37.934311, postalCode: "47520" },
    { lng: -85.14929, lat: 39.644794, postalCode: "47331" },
    { lng: -86.23191, lat: 40.593036, postalCode: "46932" },
    { lng: -84.96724, lat: 40.548194, postalCode: "47326" },
    { lng: -86.50183, lat: 40.860779, postalCode: "46978" },
    { lng: -85.82522, lat: 38.30382, postalCode: "47150" },
    { lng: -85.88003, lat: 39.61753, postalCode: "46126" },
    { lng: -85.19242, lat: 39.491509, postalCode: "47024" },
    { lng: -85.84885, lat: 41.567559, postalCode: "46526" },
    { lng: -86.160616, lat: 39.767293, postalCode: "46207" },
    { lng: -86.11397, lat: 39.324091, postalCode: "46164" },
    { lng: -85.396685, lat: 40.227938, postalCode: "47308" },
    { lng: -86.876173, lat: 38.364749, postalCode: "47547" },
    { lng: -86.67137, lat: 40.1915, postalCode: "46035" },
    { lng: -86.22546, lat: 39.897548, postalCode: "46268" },
    { lng: -87.35222, lat: 39.535285, postalCode: "47805" },
    { lng: -86.4016, lat: 41.007625, postalCode: "46939" },
    { lng: -86.172327, lat: 38.096971, postalCode: "47110" },
    { lng: -84.90196, lat: 39.310292, postalCode: "47035" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46853" },
    { lng: -86.09643, lat: 38.410145, postalCode: "47164" },
    { lng: -85.65922, lat: 40.53089, postalCode: "46953" },
    { lng: -85.57179, lat: 40.409668, postalCode: "46930" },
    { lng: -84.92284, lat: 39.622812, postalCode: "47353" },
    { lng: -86.13831, lat: 39.939102, postalCode: "46280" },
    { lng: -84.97922, lat: 40.173141, postalCode: "47394" },
    { lng: -85.896961, lat: 38.298486, postalCode: "47151" },
    { lng: -85.657825, lat: 40.093792, postalCode: "46015" },
    { lng: -86.32493, lat: 39.797622, postalCode: "46234" },
    { lng: -86.22652, lat: 38.350106, postalCode: "47115" },
    { lng: -85.93863, lat: 40.614885, postalCode: "46911" },
    { lng: -85.17871, lat: 40.598916, postalCode: "46759" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46855" },
    { lng: -85.62014, lat: 40.838891, postalCode: "46702" },
    { lng: -86.98612, lat: 39.837161, postalCode: "46175" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46295" },
    { lng: -85.86648, lat: 40.580773, postalCode: "46919" },
    { lng: -85.50548, lat: 40.620755, postalCode: "46991" },
    { lng: -86.26806, lat: 39.351269, postalCode: "46160" },
    { lng: -86.292991, lat: 41.596769, postalCode: "46699" },
    { lng: -85.99829, lat: 41.444183, postalCode: "46550" },
    { lng: -86.89189, lat: 40.671905, postalCode: "47929" },
    { lng: -85.76702, lat: 38.473919, postalCode: "47143" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46211" },
    { lng: -85.39367, lat: 41.722976, postalCode: "46746" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46801" },
    { lng: -86.61059, lat: 40.427919, postalCode: "46065" },
    { lng: -85.16333, lat: 39.918857, postalCode: "47346" },
    { lng: -86.55239, lat: 41.172438, postalCode: "46968" },
    { lng: -85.60213, lat: 39.649096, postalCode: "46104" },
    { lng: -86.46657, lat: 38.210303, postalCode: "47174" },
    { lng: -87.413, lat: 40.277121, postalCode: "47991" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46852" },
    { lng: -84.937926, lat: 40.74532, postalCode: "46780" },
    { lng: -86.016441, lat: 40.780955, postalCode: "46971" },
    { lng: -86.09362, lat: 40.378829, postalCode: "46068" },
    { lng: -86.252506, lat: 41.677397, postalCode: "46626" },
    { lng: -86.87361, lat: 39.652374, postalCode: "46135" },
    { lng: -85.62782, lat: 39.419641, postalCode: "47272" },
    { lng: -86.00948, lat: 39.857639, postalCode: "46216" },
    { lng: -85.22159, lat: 41.148298, postalCode: "46818" },
    { lng: -87.36849, lat: 40.506071, postalCode: "47921" },
    { lng: -86.47781, lat: 40.472125, postalCode: "46920" },
    { lng: -87.2444, lat: 40.520834, postalCode: "47971" },
    { lng: -86.31341, lat: 41.667797, postalCode: "46619" },
    { lng: -86.052285, lat: 40.072462, postalCode: "46061" },
    { lng: -87.524376, lat: 39.714267, postalCode: "47895" },
    { lng: -85.33006, lat: 39.308947, postalCode: "47263" },
    { lng: -86.49743, lat: 39.131576, postalCode: "47401" },
    { lng: -86.48362, lat: 38.533167, postalCode: "47454" },
    { lng: -84.91227, lat: 38.944426, postalCode: "47040" },
    { lng: -85.92906, lat: 40.935304, postalCode: "46974" },
    { lng: -85.14847, lat: 39.773709, postalCode: "47357" },
    { lng: -86.604952, lat: 41.156891, postalCode: "46696" },
    { lng: -85.408221, lat: 40.202293, postalCode: "47306" },
    { lng: -86.13817, lat: 39.824858, postalCode: "46205" },
    { lng: -86.87659, lat: 40.986799, postalCode: "47946" },
    { lng: -85.99239, lat: 38.363632, postalCode: "47124" },
    { lng: -84.94244, lat: 40.660174, postalCode: "46711" },
    { lng: -85.47579, lat: 39.333248, postalCode: "47240" },
    { lng: -87.11557, lat: 39.282127, postalCode: "47841" },
    { lng: -85.02063, lat: 40.285505, postalCode: "47380" },
    { lng: -86.46897, lat: 38.865549, postalCode: "47421" },
    { lng: -84.89154, lat: 41.544911, postalCode: "46742" },
    { lng: -85.16151, lat: 41.094348, postalCode: "46808" },
    { lng: -85.98635, lat: 39.835369, postalCode: "46235" },
    { lng: -85.11114, lat: 41.048148, postalCode: "46806" },
    { lng: -87.06899, lat: 39.406161, postalCode: "47840" },
    { lng: -85.62796, lat: 41.080236, postalCode: "46787" },
    { lng: -85.719659, lat: 40.161733, postalCode: "46014" },
    { lng: -86.5028, lat: 40.290615, postalCode: "46041" },
    { lng: -86.292991, lat: 41.596769, postalCode: "46660" },
    { lng: -86.58477, lat: 38.137325, postalCode: "47514" },
    { lng: -86.4169, lat: 38.978916, postalCode: "47436" },
    { lng: -87.2247, lat: 38.862609, postalCode: "47596" },
    { lng: -85.962761, lat: 38.974458, postalCode: "47228" },
    { lng: -86.05378, lat: 39.83729, postalCode: "46226" },
    { lng: -87.40974, lat: 39.25182, postalCode: "47850" },
    { lng: -85.96915, lat: 41.154441, postalCode: "46508" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46850" },
    { lng: -85.167996, lat: 39.656994, postalCode: "47322" },
    { lng: -87.090363, lat: 39.387544, postalCode: "47845" },
    { lng: -85.01837, lat: 41.441775, postalCode: "46793" },
    { lng: -86.16811, lat: 41.681487, postalCode: "46545" },
    { lng: -86.26464, lat: 39.844024, postalCode: "46254" },
    { lng: -85.73528, lat: 41.035728, postalCode: "46946" },
    { lng: -87.08288, lat: 38.869876, postalCode: "47529" },
    { lng: -85.51553, lat: 39.803168, postalCode: "46148" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46869" },
    { lng: -85.35541, lat: 39.802646, postalCode: "47352" },
    { lng: -85.95074, lat: 40.363776, postalCode: "46076" },
    { lng: -85.6097, lat: 38.678365, postalCode: "47138" },
    { lng: -86.59362, lat: 40.767098, postalCode: "47926" },
    { lng: -85.67672, lat: 40.061092, postalCode: "46013" },
    { lng: -86.88719, lat: 39.235092, postalCode: "47431" },
    { lng: -85.24399, lat: 40.830096, postalCode: "46791" },
    { lng: -86.893614, lat: 40.040014, postalCode: "47935" },
    { lng: -85.30949, lat: 39.1548, postalCode: "47037" },
    { lng: -86.34405, lat: 40.007024, postalCode: "46075" },
    { lng: -85.619906, lat: 39.001301, postalCode: "47262" },
    { lng: -87.50098, lat: 40.469643, postalCode: "47917" },
    { lng: -85.97453, lat: 38.12173, postalCode: "47117" },
    { lng: -86.75982, lat: 40.777036, postalCode: "47960" },
    { lng: -85.704973, lat: 38.437021, postalCode: "47131" },
    { lng: -85.15101, lat: 41.071681, postalCode: "46802" },
    { lng: -86.66424, lat: 40.349135, postalCode: "46058" },
    { lng: -85.3697, lat: 39.92698, postalCode: "47362" },
    { lng: -86.260569, lat: 41.325513, postalCode: "46572" },
    { lng: -87.23646, lat: 40.118561, postalCode: "47987" },
    { lng: -85.49124, lat: 38.548693, postalCode: "47162" },
    { lng: -87.410094, lat: 39.433602, postalCode: "47812" },
    { lng: -85.02486, lat: 41.219699, postalCode: "46765" },
    { lng: -84.94113, lat: 38.813326, postalCode: "47020" },
    { lng: -85.204359, lat: 39.812688, postalCode: "47335" },
    { lng: -85.1739, lat: 39.281429, postalCode: "47033" },
    { lng: -86.86155, lat: 38.22309, postalCode: "47532" },
    { lng: -86.01203, lat: 41.168118, postalCode: "46539" },
    { lng: -85.49061, lat: 40.872261, postalCode: "46750" },
    { lng: -86.66247, lat: 39.355692, postalCode: "47433" },
    { lng: -86.518002, lat: 38.873216, postalCode: "47241" },
    { lng: -86.07212, lat: 38.038838, postalCode: "47135" },
    { lng: -85.94279, lat: 40.482338, postalCode: "46936" },
    { lng: -84.93926, lat: 40.042138, postalCode: "47355" },
    { lng: -87.46156, lat: 39.505935, postalCode: "47876" },
    { lng: -85.49905, lat: 40.387885, postalCode: "46957" },
    { lng: -86.3935, lat: 39.251349, postalCode: "47468" },
    { lng: -86.76703, lat: 40.375691, postalCode: "47941" },
    { lng: -87.16987, lat: 39.039636, postalCode: "47441" },
    { lng: -85.76697, lat: 40.569731, postalCode: "46987" },
    { lng: -86.38161, lat: 40.333772, postalCode: "46057" },
    { lng: -85.30702, lat: 40.852768, postalCode: "46770" },
    { lng: -86.397372, lat: 40.479112, postalCode: "46415" },
    { lng: -86.06339, lat: 40.873913, postalCode: "46926" },
    { lng: -86.50127, lat: 40.507086, postalCode: "46913" },
    { lng: -87.25186, lat: 38.824546, postalCode: "47528" },
    { lng: -86.62922, lat: 38.507726, postalCode: "47432" },
    { lng: -86.09375, lat: 39.718744, postalCode: "46107" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46247" },
    { lng: -85.66831, lat: 38.544512, postalCode: "47163" },
    { lng: -85.732062, lat: 38.286819, postalCode: "47134" },
    { lng: -87.09917, lat: 38.96555, postalCode: "47443" },
    { lng: -85.97356, lat: 39.795025, postalCode: "46229" },
    { lng: -85.77208, lat: 39.523797, postalCode: "46176" },
    { lng: -85.86531, lat: 41.408621, postalCode: "46542" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46867" },
    { lng: -86.81847, lat: 40.41836, postalCode: "47905" },
    { lng: -86.72533, lat: 40.247657, postalCode: "47930" },
    { lng: -85.732062, lat: 38.286819, postalCode: "47133" },
    { lng: -87.03629, lat: 40.758188, postalCode: "47995" },
    { lng: -86.824468, lat: 40.304402, postalCode: "47903" },
    { lng: -87.18939, lat: 39.487119, postalCode: "47881" },
    { lng: -84.83422, lat: 40.212425, postalCode: "47390" },
    { lng: -86.26514, lat: 41.69248, postalCode: "46616" },
    { lng: -86.88975, lat: 40.418585, postalCode: "47901" },
    { lng: -86.22757, lat: 40.292007, postalCode: "46049" },
    { lng: -85.60147, lat: 40.096343, postalCode: "46017" },
    { lng: -86.80492, lat: 39.761986, postalCode: "46105" },
    { lng: -85.56204, lat: 41.359626, postalCode: "46760" },
    { lng: -86.88769, lat: 40.753293, postalCode: "47980" },
    { lng: -86.08543, lat: 39.557799, postalCode: "46184" },
    { lng: -86.50975, lat: 41.356991, postalCode: "46531" },
    { lng: -86.60268, lat: 40.126304, postalCode: "46071" },
    { lng: -85.50656, lat: 40.320458, postalCode: "47342" },
    { lng: -86.052285, lat: 40.072462, postalCode: "46082" },
    { lng: -84.94922, lat: 39.206203, postalCode: "47022" },
    { lng: -87.086882, lat: 39.526913, postalCode: "47857" },
    { lng: -84.93525, lat: 41.267553, postalCode: "46788" },
    { lng: -86.90202, lat: 39.414547, postalCode: "47868" },
    { lng: -86.46592, lat: 40.047966, postalCode: "46052" },
    { lng: -86.22183, lat: 39.719444, postalCode: "46221" },
    { lng: -87.231182, lat: 39.191057, postalCode: "47485" },
    { lng: -87.4655, lat: 40.043359, postalCode: "47974" },
    { lng: -86.57409, lat: 39.121719, postalCode: "47403" },
    { lng: -86.876173, lat: 38.364749, postalCode: "47549" },
    { lng: -86.670987, lat: 38.722153, postalCode: "47437" },
    { lng: -85.45882, lat: 41.462326, postalCode: "46794" },
    { lng: -85.10219, lat: 41.200465, postalCode: "46845" },
    { lng: -86.2395, lat: 39.199357, postalCode: "47448" },
    { lng: -86.04261, lat: 41.538432, postalCode: "46573" },
    { lng: -86.33121, lat: 39.71962, postalCode: "46231" },
    { lng: -86.30357, lat: 41.700776, postalCode: "46628" },
    { lng: -86.68869, lat: 38.845709, postalCode: "47470" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46255" },
    { lng: -87.33149, lat: 39.725201, postalCode: "47860" },
    { lng: -86.501543, lat: 39.173234, postalCode: "47402" },
    { lng: -86.469044, lat: 40.304473, postalCode: "46067" },
    { lng: -85.704973, lat: 38.437021, postalCode: "47190" },
    { lng: -86.61613, lat: 41.282822, postalCode: "46534" },
    { lng: -85.421753, lat: 38.539899, postalCode: "47104" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46209" },
    { lng: -85.89917, lat: 38.958688, postalCode: "47274" },
    { lng: -85.78997, lat: 41.323642, postalCode: "46538" },
    { lng: -85.85778, lat: 41.2281, postalCode: "46580" },
    { lng: -86.006082, lat: 39.858989, postalCode: "46249" },
    { lng: -87.10907, lat: 40.480675, postalCode: "47970" },
    { lng: -85.97041, lat: 39.888225, postalCode: "46236" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46285" },
    { lng: -87.410094, lat: 39.433602, postalCode: "47808" },
    { lng: -85.520201, lat: 39.902534, postalCode: "47317" },
    { lng: -87.32659, lat: 40.617152, postalCode: "47944" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46857" },
    { lng: -86.28575, lat: 39.792993, postalCode: "46214" },
    { lng: -85.88879, lat: 39.0601, postalCode: "47247" },
    { lng: -85.25293, lat: 40.290747, postalCode: "47320" },
    { lng: -86.24391, lat: 41.73104, postalCode: "46637" },
    { lng: -85.13, lat: 40.184743, postalCode: "47340" },
    { lng: -86.98761, lat: 38.619866, postalCode: "47519" },
    { lng: -87.410094, lat: 39.433602, postalCode: "47813" },
    { lng: -86.65353, lat: 40.794442, postalCode: "47950" },
    { lng: -87.449558, lat: 39.080474, postalCode: "47852" },
    { lng: -86.558, lat: 38.127871, postalCode: "47551" },
    { lng: -85.28409, lat: 39.838735, postalCode: "47387" },
    { lng: -85.72035, lat: 38.309105, postalCode: "47130" },
    { lng: -86.36684, lat: 40.754787, postalCode: "46947" },
    { lng: -85.49089, lat: 40.457599, postalCode: "46989" },
    { lng: -86.09283, lat: 38.805185, postalCode: "47281" },
    { lng: -87.04145, lat: 38.241887, postalCode: "47541" },
    { lng: -86.74363, lat: 40.882284, postalCode: "47925" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46862" },
    { lng: -86.51676, lat: 39.173597, postalCode: "47406" },
    { lng: -85.423462, lat: 41.395275, postalCode: "46720" },
    { lng: -86.53591, lat: 39.561003, postalCode: "46157" },
    { lng: -85.77612, lat: 40.221208, postalCode: "46044" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46282" },
    { lng: -85.06509, lat: 41.105691, postalCode: "46815" },
    { lng: -85.17784, lat: 39.816703, postalCode: "47327" },
    { lng: -86.98512, lat: 38.837695, postalCode: "47562" },
    { lng: -87.40667, lat: 39.090479, postalCode: "47882" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46223" },
    { lng: -86.62275, lat: 39.245995, postalCode: "47429" },
    { lng: -86.36991, lat: 39.538087, postalCode: "46111" },
    { lng: -85.96923, lat: 41.646499, postalCode: "46517" },
    { lng: -86.497, lat: 40.542108, postalCode: "46929" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46860" },
    { lng: -85.70397, lat: 41.213756, postalCode: "46562" },
    { lng: -84.9142, lat: 39.963113, postalCode: "47341" },
    { lng: -85.83761, lat: 40.279375, postalCode: "46036" },
    { lng: -86.99353, lat: 39.360169, postalCode: "47833" },
    { lng: -86.018139, lat: 40.418662, postalCode: "46937" },
    { lng: -85.732062, lat: 38.286819, postalCode: "47132" },
    { lng: -86.24943, lat: 40.668348, postalCode: "46994" },
    { lng: -85.1826, lat: 41.023147, postalCode: "46809" },
    { lng: -85.15428, lat: 41.556391, postalCode: "46747" },
    { lng: -86.15903, lat: 39.746993, postalCode: "46225" },
    { lng: -85.199186, lat: 41.583493, postalCode: "46789" },
    { lng: -85.611994, lat: 39.005651, postalCode: "47257" },
    { lng: -84.938878, lat: 40.05085, postalCode: "47395" },
    { lng: -87.19332, lat: 39.125078, postalCode: "47445" },
    { lng: -85.255114, lat: 39.111625, postalCode: "47039" },
    { lng: -87.419569, lat: 39.600636, postalCode: "47880" },
    { lng: -85.37745, lat: 41.493637, postalCode: "46784" },
    { lng: -86.58507, lat: 39.416815, postalCode: "46166" },
    { lng: -86.38739, lat: 39.84952, postalCode: "46112" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46863" },
    { lng: -85.82953, lat: 40.795068, postalCode: "46992" },
    { lng: -86.42163, lat: 41.218388, postalCode: "46511" },
    { lng: -85.148284, lat: 41.018247, postalCode: "46891" },
    { lng: -85.347677, lat: 39.424072, postalCode: "47225" },
    { lng: -84.86603, lat: 40.382778, postalCode: "47381" },
    { lng: -85.858076, lat: 41.597739, postalCode: "46527" },
    { lng: -85.89726, lat: 39.726811, postalCode: "46163" },
    { lng: -85.06601, lat: 38.992471, postalCode: "47018" },
    { lng: -86.77777, lat: 38.473956, postalCode: "47527" },
    { lng: -86.67849, lat: 39.679883, postalCode: "46121" },
    { lng: -85.147437, lat: 39.861314, postalCode: "47370" },
    { lng: -86.15004, lat: 40.289779, postalCode: "46045" },
    { lng: -87.41458, lat: 39.819742, postalCode: "47854" },
    { lng: -87.17504, lat: 39.865058, postalCode: "47859" },
    { lng: -85.90441, lat: 41.054969, postalCode: "46982" },
    { lng: -87.17619, lat: 38.658196, postalCode: "47501" },
    { lng: -86.16098, lat: 39.396833, postalCode: "46181" },
    { lng: -86.6689, lat: 39.085896, postalCode: "47463" },
    { lng: -85.619906, lat: 39.001301, postalCode: "47245" },
    { lng: -87.03271, lat: 39.232338, postalCode: "47427" },
    { lng: -85.13141, lat: 39.428752, postalCode: "47030" },
    { lng: -86.09952, lat: 40.646736, postalCode: "46914" },
    { lng: -87.17529, lat: 39.647929, postalCode: "47836" },
    { lng: -85.34956, lat: 40.339779, postalCode: "47338" },
    { lng: -85.38031, lat: 38.787175, postalCode: "47250" },
    { lng: -87.30983, lat: 39.293977, postalCode: "47866" },
    { lng: -85.2115, lat: 40.38509, postalCode: "47336" },
    { lng: -86.90202, lat: 39.021657, postalCode: "47424" },
    { lng: -87.24589, lat: 39.869467, postalCode: "47832" },
    { lng: -86.50246, lat: 41.159868, postalCode: "46960" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46266" },
    { lng: -85.719659, lat: 40.161733, postalCode: "46018" },
    { lng: -84.86982, lat: 41.129274, postalCode: "46797" },
    { lng: -86.501543, lat: 39.173234, postalCode: "47490" },
    { lng: -86.49379, lat: 41.472858, postalCode: "46574" },
    { lng: -86.54978, lat: 39.885033, postalCode: "46149" },
    { lng: -86.15598, lat: 39.771743, postalCode: "46204" },
    { lng: -87.1913, lat: 38.892783, postalCode: "47578" },
    { lng: -86.35846, lat: 40.196061, postalCode: "46050" },
    { lng: -85.06618, lat: 38.788449, postalCode: "47043" },
    { lng: -86.206925, lat: 41.040988, postalCode: "46912" },
    { lng: -86.475, lat: 40.626913, postalCode: "46917" },
    { lng: -85.0974, lat: 40.793562, postalCode: "46731" },
    { lng: -85.9159, lat: 39.898378, postalCode: "46055" },
    { lng: -85.54205, lat: 40.117139, postalCode: "47334" },
    { lng: -86.21105, lat: 41.671848, postalCode: "46615" },
    { lng: -85.47234, lat: 38.699654, postalCode: "47243" },
    { lng: -86.8507, lat: 38.893458, postalCode: "47522" },
    { lng: -85.43834, lat: 39.614803, postalCode: "46173" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46298" },
    { lng: -86.34441, lat: 38.768275, postalCode: "47467" },
    { lng: -86.74855, lat: 39.110903, postalCode: "47459" },
    { lng: -87.31447, lat: 38.777038, postalCode: "47512" },
    { lng: -85.43218, lat: 40.683261, postalCode: "46792" },
    { lng: -85.6322, lat: 41.209244, postalCode: "46764" },
    { lng: -84.937926, lat: 40.74532, postalCode: "46769" },
    { lng: -84.96936, lat: 40.419489, postalCode: "47371" },
    { lng: -87.44554, lat: 38.864563, postalCode: "47561" },
    { lng: -85.6852, lat: 41.328153, postalCode: "46555" },
    { lng: -85.46554, lat: 39.619923, postalCode: "46146" },
    { lng: -85.94676, lat: 40.284101, postalCode: "46047" },
    { lng: -86.10052, lat: 40.911001, postalCode: "46921" },
    { lng: -86.76265, lat: 40.118998, postalCode: "47940" },
    { lng: -85.66324, lat: 40.58119, postalCode: "46952" },
    { lng: -85.44117, lat: 39.832904, postalCode: "47385" },
    { lng: -86.8768, lat: 40.428735, postalCode: "47904" },
    { lng: -85.64679, lat: 40.340749, postalCode: "46070" },
    { lng: -84.99542, lat: 39.953627, postalCode: "47393" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46274" },
    { lng: -85.43035, lat: 39.743346, postalCode: "46155" },
    { lng: -86.893614, lat: 40.040014, postalCode: "47936" },
    { lng: -85.625718, lat: 39.00249, postalCode: "47625" },
    { lng: -86.83174, lat: 39.842523, postalCode: "46172" },
    { lng: -85.88733, lat: 41.12274, postalCode: "46510" },
    { lng: -86.49319, lat: 38.733355, postalCode: "47446" },
    { lng: -86.37341, lat: 40.736111, postalCode: "46942" },
    { lng: -84.93811, lat: 41.202549, postalCode: "46741" },
    { lng: -84.87284, lat: 40.988714, postalCode: "46773" },
    { lng: -86.95994, lat: 39.525112, postalCode: "46171" },
    { lng: -86.17013, lat: 41.526405, postalCode: "46595" },
    { lng: -86.55097, lat: 38.912898, postalCode: "47420" },
    { lng: -85.768357, lat: 39.856763, postalCode: "46154" },
    { lng: -87.410094, lat: 39.433602, postalCode: "47811" },
    { lng: -85.8013, lat: 39.129171, postalCode: "47232" },
    { lng: -85.12181, lat: 41.150677, postalCode: "46825" },
    { lng: -86.961577, lat: 39.037144, postalCode: "47457" },
    { lng: -86.23083, lat: 38.079482, postalCode: "47142" },
    { lng: -86.1172, lat: 40.952117, postalCode: "46951" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46291" },
    { lng: -85.61239, lat: 39.563815, postalCode: "46150" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46896" },
    { lng: -86.08928, lat: 38.605039, postalCode: "47167" },
    { lng: -85.12061, lat: 41.098248, postalCode: "46805" },
    { lng: -85.08587, lat: 39.240684, postalCode: "47041" },
    { lng: -86.46335, lat: 39.872255, postalCode: "46167" },
    { lng: -86.07185, lat: 40.753091, postalCode: "46970" },
    { lng: -85.43989, lat: 41.361895, postalCode: "46701" },
    { lng: -87.321357, lat: 38.744136, postalCode: "47573" },
    { lng: -86.115257, lat: 38.190314, postalCode: "47107" },
    { lng: -86.01293, lat: 38.49903, postalCode: "47165" },
    { lng: -85.938348, lat: 41.641449, postalCode: "46515" },
    { lng: -86.25214, lat: 39.736844, postalCode: "46241" },
    { lng: -86.515145, lat: 39.173447, postalCode: "47405" },
    { lng: -86.60853, lat: 38.19297, postalCode: "47576" },
    { lng: -86.31273, lat: 39.631344, postalCode: "46113" },
    { lng: -85.765169, lat: 39.821516, postalCode: "46129" },
    { lng: -87.5271, lat: 40.196628, postalCode: "47982" },
    { lng: -87.027571, lat: 40.473159, postalCode: "47962" },
    { lng: -85.24073, lat: 39.382381, postalCode: "47036" },
    { lng: -86.63017, lat: 39.612844, postalCode: "46180" },
    { lng: -87.30088, lat: 38.652821, postalCode: "47597" },
    { lng: -86.25253, lat: 41.671197, postalCode: "46601" },
    { lng: -86.89143, lat: 40.034554, postalCode: "47933" },
    { lng: -85.20909, lat: 40.043267, postalCode: "47354" },
    { lng: -86.056681, lat: 38.897974, postalCode: "47249" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46244" },
    { lng: -84.93094, lat: 39.060204, postalCode: "47001" },
    { lng: -86.03216, lat: 41.291767, postalCode: "46524" },
    { lng: -87.13698, lat: 40.072123, postalCode: "47949" },
    { lng: -85.96044, lat: 41.676899, postalCode: "46516" },
    { lng: -86.77844, lat: 39.290029, postalCode: "47460" },
    { lng: -86.23521, lat: 41.073678, postalCode: "46975" },
    { lng: -86.2795, lat: 41.519839, postalCode: "46536" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46866" },
    { lng: -86.06733, lat: 39.905689, postalCode: "46250" },
    { lng: -84.85575, lat: 41.215338, postalCode: "46743" },
    { lng: -84.83861, lat: 38.855749, postalCode: "47038" },
    { lng: -87.29994, lat: 39.968437, postalCode: "47952" },
    { lng: -84.93704, lat: 40.831351, postalCode: "46733" },
    { lng: -87.24115, lat: 38.248608, postalCode: "47584" },
    { lng: -85.172053, lat: 41.221366, postalCode: "46848" },
    { lng: -86.98346, lat: 40.192176, postalCode: "47967" },
    { lng: -86.81523, lat: 38.313717, postalCode: "47575" },
    { lng: -86.01405, lat: 39.907838, postalCode: "46256" },
    { lng: -86.39589, lat: 39.69775, postalCode: "46168" },
    { lng: -86.8894, lat: 40.605, postalCode: "47923" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46283" },
    { lng: -84.95619, lat: 41.724694, postalCode: "46737" },
    { lng: -87.3977, lat: 39.393237, postalCode: "47802" },
    { lng: -86.93876, lat: 38.408794, postalCode: "47546" },
    { lng: -86.03121, lat: 40.127019, postalCode: "46034" },
    { lng: -86.510386, lat: 39.762448, postalCode: "46114" },
    { lng: -87.266549, lat: 39.492838, postalCode: "47878" },
    { lng: -85.54707, lat: 41.565561, postalCode: "46571" },
    { lng: -85.70234, lat: 39.246458, postalCode: "47244" },
    { lng: -86.77511, lat: 40.285727, postalCode: "47983" },
    { lng: -85.47698, lat: 39.484598, postalCode: "46156" },
    { lng: -86.177059, lat: 41.74486, postalCode: "46305" },
    { lng: -86.20906, lat: 41.708505, postalCode: "46635" },
    { lng: -85.76741, lat: 39.297144, postalCode: "47246" },
    { lng: -86.43438, lat: 38.647931, postalCode: "47452" },
    { lng: -85.885168, lat: 39.192972, postalCode: "47202" },
    { lng: -85.69786, lat: 39.67083, postalCode: "46161" },
    { lng: -85.72135, lat: 40.680911, postalCode: "46940" },
    { lng: -86.18232, lat: 39.898107, postalCode: "46260" },
    { lng: -86.21093, lat: 39.786793, postalCode: "46222" },
    { lng: -86.92076, lat: 39.950948, postalCode: "47965" },
    { lng: -84.8726, lat: 41.424095, postalCode: "46721" },
    { lng: -86.24804, lat: 41.626588, postalCode: "46614" },
    { lng: -86.63699, lat: 38.967187, postalCode: "47462" },
    { lng: -84.89306, lat: 41.317967, postalCode: "46785" },
    { lng: -85.97605, lat: 41.707616, postalCode: "46514" },
    { lng: -85.06057, lat: 39.075691, postalCode: "47032" },
    { lng: -86.283278, lat: 41.733223, postalCode: "46624" },
    { lng: -87.443566, lat: 39.877847, postalCode: "47831" },
    { lng: -86.11894, lat: 40.469625, postalCode: "46904" },
    { lng: -85.04792, lat: 41.352638, postalCode: "46706" },
    { lng: -84.91901, lat: 40.236181, postalCode: "47382" },
    { lng: -86.16262, lat: 39.934949, postalCode: "46290" },
    { lng: -86.18327, lat: 38.846345, postalCode: "47260" },
    { lng: -85.06155, lat: 41.523914, postalCode: "46705" },
    { lng: -86.08634, lat: 39.67859, postalCode: "46237" },
    { lng: -85.041244, lat: 38.762649, postalCode: "47093" },
    { lng: -86.261089, lat: 38.557243, postalCode: "47128" },
    { lng: -86.23576, lat: 41.684504, postalCode: "46617" },
    { lng: -85.7316, lat: 39.085027, postalCode: "47273" },
    { lng: -86.95343, lat: 39.311243, postalCode: "47455" },
    { lng: -85.792501, lat: 40.848927, postalCode: "46980" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46242" },
    { lng: -87.147674, lat: 39.986538, postalCode: "47988" },
    { lng: -87.04871, lat: 39.039348, postalCode: "47465" },
    { lng: -86.24521, lat: 41.224036, postalCode: "46501" },
    { lng: -85.32737, lat: 39.717457, postalCode: "46127" },
    { lng: -86.38446, lat: 40.882776, postalCode: "46950" },
    { lng: -87.12738, lat: 39.52503, postalCode: "47834" },
    { lng: -85.38641, lat: 40.004196, postalCode: "47361" },
    { lng: -86.15416, lat: 39.970241, postalCode: "46032" },
    { lng: -85.267567, lat: 41.665489, postalCode: "46771" },
    { lng: -85.32865, lat: 39.205405, postalCode: "47034" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46253" },
    { lng: -84.99568, lat: 39.43115, postalCode: "47012" },
    { lng: -87.39149, lat: 39.020687, postalCode: "47865" },
    { lng: -86.206925, lat: 41.040988, postalCode: "46935" },
    { lng: -85.673, lat: 40.257082, postalCode: "46001" },
    { lng: -85.14262, lat: 40.993914, postalCode: "46819" },
    { lng: -85.70333, lat: 40.832028, postalCode: "46941" },
    { lng: -85.005402, lat: 40.83204, postalCode: "46782" },
    { lng: -86.292991, lat: 41.596769, postalCode: "46620" },
    { lng: -85.60772, lat: 40.48684, postalCode: "46933" },
    { lng: -85.81902, lat: 41.600649, postalCode: "46528" },
    { lng: -86.16002, lat: 41.650672, postalCode: "46544" },
    { lng: -87.50791, lat: 38.668827, postalCode: "47591" },
    { lng: -86.916215, lat: 40.424923, postalCode: "47907" },
    { lng: -84.96856, lat: 40.599769, postalCode: "46740" },
    { lng: -85.62922, lat: 38.802781, postalCode: "47230" },
    { lng: -85.24367, lat: 41.353742, postalCode: "46710" },
    { lng: -85.22236, lat: 41.28867, postalCode: "46763" },
    { lng: -86.28146, lat: 38.773826, postalCode: "47430" },
    { lng: -86.75256, lat: 39.674879, postalCode: "46128" },
    { lng: -86.75914, lat: 38.345103, postalCode: "47580" },
    { lng: -86.570962, lat: 38.420839, postalCode: "47718" },
    { lng: -86.02304, lat: 40.211166, postalCode: "46031" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46859" },
    { lng: -85.95025, lat: 39.296605, postalCode: "47280" },
    { lng: -85.38494, lat: 40.192293, postalCode: "47305" },
    { lng: -86.89775, lat: 38.702827, postalCode: "47553" },
    { lng: -86.221805, lat: 41.664198, postalCode: "46604" },
    { lng: -85.5097, lat: 38.8937, postalCode: "47231" },
    { lng: -86.37431, lat: 39.588545, postalCode: "46158" },
    { lng: -85.58665, lat: 41.696334, postalCode: "46565" },
    { lng: -87.0031, lat: 38.926709, postalCode: "47449" },
    { lng: -86.01736, lat: 40.17449, postalCode: "46030" },
    { lng: -86.55656, lat: 38.355935, postalCode: "47175" },
    { lng: -86.55613, lat: 38.031757, postalCode: "47525" },
    { lng: -85.84631, lat: 40.065589, postalCode: "46051" },
    { lng: -85.76787, lat: 38.303945, postalCode: "47129" },
    { lng: -85.10706, lat: 41.070498, postalCode: "46803" },
    { lng: -85.9864, lat: 39.366507, postalCode: "46124" },
    { lng: -86.73903, lat: 37.971622, postalCode: "47586" },
    { lng: -86.132837, lat: 39.779492, postalCode: "46277" },
    { lng: -86.04681, lat: 40.284041, postalCode: "46072" },
    { lng: -86.41337, lat: 39.442774, postalCode: "46151" },
    { lng: -86.90009, lat: 41.204744, postalCode: "46374" },
    { lng: -85.78446, lat: 39.799143, postalCode: "46140" },
    { lng: -87.443566, lat: 39.877847, postalCode: "47875" },
    { lng: -87.4228, lat: 38.762261, postalCode: "47516" },
    { lng: -85.02454, lat: 39.685481, postalCode: "47325" },
    { lng: -85.2698, lat: 40.168528, postalCode: "47383" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46864" },
    { lng: -86.15126, lat: 39.62068, postalCode: "46142" },
    { lng: -86.11735, lat: 40.820851, postalCode: "46958" },
    { lng: -87.255079, lat: 40.519221, postalCode: "47671" },
    { lng: -86.09478, lat: 38.312878, postalCode: "47161" },
    { lng: -86.893614, lat: 40.040014, postalCode: "47938" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46856" },
    { lng: -86.12127, lat: 40.450623, postalCode: "46902" },
    { lng: -87.25568, lat: 40.448708, postalCode: "47975" },
    { lng: -85.67065, lat: 39.457143, postalCode: "46182" },
    { lng: -86.77554, lat: 38.663011, postalCode: "47581" },
    { lng: -87.27765, lat: 39.625041, postalCode: "47874" },
    { lng: -84.94334, lat: 39.903236, postalCode: "47392" },
    { lng: -85.36442, lat: 40.449883, postalCode: "47348" },
    { lng: -85.63456, lat: 40.468157, postalCode: "46938" },
    { lng: -86.04889, lat: 39.78001, postalCode: "46219" },
    { lng: -86.46103, lat: 38.284524, postalCode: "47123" },
    { lng: -85.15474, lat: 40.34703, postalCode: "47373" },
    { lng: -85.737, lat: 40.112913, postalCode: "46011" },
    { lng: -85.15936, lat: 40.880397, postalCode: "46777" },
    { lng: -85.070713, lat: 41.093763, postalCode: "46895" },
    { lng: -85.500246, lat: 41.336115, postalCode: "46796" },
    { lng: -87.310042, lat: 40.606301, postalCode: "47984" },
    { lng: -86.1114, lat: 41.301049, postalCode: "46504" },
    { lng: -86.894908, lat: 40.388656, postalCode: "47996" },
    { lng: -86.7225, lat: 40.667985, postalCode: "47997" },
    { lng: -85.11692, lat: 40.043409, postalCode: "47358" },
    { lng: -87.14299, lat: 38.267827, postalCode: "47585" },
    { lng: -85.53195, lat: 38.591735, postalCode: "47147" },
    { lng: -84.87774, lat: 39.288178, postalCode: "47060" },
    { lng: -86.88972, lat: 40.35589, postalCode: "47909" },
    { lng: -86.12973, lat: 39.678495, postalCode: "46227" },
    { lng: -85.07263, lat: 38.848761, postalCode: "47011" },
    { lng: -86.2983, lat: 38.461255, postalCode: "47125" },
    { lng: -87.25714, lat: 39.065547, postalCode: "47848" },
    { lng: -86.18702, lat: 38.435326, postalCode: "47120" },
    { lng: -87.45451, lat: 39.622536, postalCode: "47884" },
    { lng: -85.61733, lat: 39.974904, postalCode: "46056" },
    { lng: -86.501543, lat: 39.173234, postalCode: "47458" },
    { lng: -85.25831, lat: 40.00296, postalCode: "47360" },
    { lng: -87.13975, lat: 38.791938, postalCode: "47568" },
    { lng: -85.99903, lat: 39.732943, postalCode: "46239" },
    { lng: -87.39209, lat: 39.495436, postalCode: "47804" },
    { lng: -85.16565, lat: 40.728816, postalCode: "46714" },
    { lng: -86.1713, lat: 39.820708, postalCode: "46208" },
    { lng: -86.91652, lat: 40.246558, postalCode: "47981" },
    { lng: -86.16656, lat: 38.313345, postalCode: "47166" },
    { lng: -85.617307, lat: 39.037187, postalCode: "47256" },
    { lng: -85.99815, lat: 40.050109, postalCode: "46060" },
    { lng: -86.61409, lat: 39.688673, postalCode: "46103" },
    { lng: -85.10107, lat: 39.97566, postalCode: "47339" },
    { lng: -85.65476, lat: 40.412026, postalCode: "46928" },
    { lng: -86.20448, lat: 39.849474, postalCode: "46228" },
    { lng: -85.726135, lat: 39.152228, postalCode: "47236" },
    { lng: -87.43577, lat: 39.66851, postalCode: "47842" },
    { lng: -85.65443, lat: 38.453813, postalCode: "47111" },
    { lng: -85.65097, lat: 40.132892, postalCode: "46012" },
    { lng: -84.82728, lat: 39.518217, postalCode: "47010" },
    { lng: -86.81628, lat: 40.543522, postalCode: "47920" },
    { lng: -87.4078, lat: 39.885739, postalCode: "47966" },
    { lng: -86.192003, lat: 41.720636, postalCode: "46535" },
    { lng: -86.161336, lat: 39.761293, postalCode: "46206" },
    { lng: -85.83873, lat: 40.974439, postalCode: "46943" },
    { lng: -87.40081, lat: 40.133867, postalCode: "47932" },
    { lng: -86.13561, lat: 38.982358, postalCode: "47235" },
    { lng: -86.04252, lat: 38.876537, postalCode: "47220" },
    { lng: -86.74717, lat: 38.387935, postalCode: "47521" },
    { lng: -85.68452, lat: 40.098641, postalCode: "46016" },
    { lng: -85.89095, lat: 38.345815, postalCode: "47119" },
    { lng: -82.69447, lat: 37.185228, postalCode: "41810" },
    { lng: -84.299699, lat: 37.716443, postalCode: "40404" },
    { lng: -85.9662, lat: 37.895491, postalCode: "40121" },
    { lng: -83.808021, lat: 38.61225, postalCode: "41096" },
    { lng: -85.03498, lat: 38.279797, postalCode: "40003" },
    { lng: -82.43625, lat: 37.514166, postalCode: "41557" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40596" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40523" },
    { lng: -83.67763, lat: 37.643659, postalCode: "41397" },
    { lng: -84.03435, lat: 37.457017, postalCode: "40447" },
    { lng: -82.91535, lat: 37.36778, postalCode: "41817" },
    { lng: -83.52303, lat: 37.933123, postalCode: "40316" },
    { lng: -84.54437, lat: 37.075624, postalCode: "42501" },
    { lng: -83.49464, lat: 37.929101, postalCode: "40387" },
    { lng: -83.33267, lat: 37.31629, postalCode: "41754" },
    { lng: -83.51527, lat: 37.163342, postalCode: "41714" },
    { lng: -84.38559, lat: 38.071981, postalCode: "40516" },
    { lng: -83.87252, lat: 36.897618, postalCode: "40949" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40522" },
    { lng: -82.96607, lat: 36.868363, postalCode: "40843" },
    { lng: -83.44413, lat: 37.891486, postalCode: "41425" },
    { lng: -82.66028, lat: 37.587011, postalCode: "41642" },
    { lng: -85.17111, lat: 38.433076, postalCode: "40058" },
    { lng: -83.51851, lat: 36.842522, postalCode: "40988" },
    { lng: -82.72165, lat: 37.761356, postalCode: "41265" },
    { lng: -82.74606, lat: 37.135518, postalCode: "41837" },
    { lng: -83.08055, lat: 37.205562, postalCode: "41760" },
    { lng: -83.76561, lat: 38.624534, postalCode: "41056" },
    { lng: -85.05564, lat: 37.742806, postalCode: "40040" },
    { lng: -83.51835, lat: 37.716311, postalCode: "41301" },
    { lng: -83.15917, lat: 36.852687, postalCode: "40847" },
    { lng: -83.11739, lat: 38.630683, postalCode: "41166" },
    { lng: -83.386991, lat: 37.331259, postalCode: "40981" },
    { lng: -83.8506, lat: 36.947113, postalCode: "40943" },
    { lng: -83.61901, lat: 37.25398, postalCode: "40972" },
    { lng: -83.718626, lat: 38.3481, postalCode: "41037" },
    { lng: -84.8279, lat: 38.606023, postalCode: "40355" },
    { lng: -85.86375, lat: 38.141719, postalCode: "40258" },
    { lng: -82.87549, lat: 37.334629, postalCode: "41844" },
    { lng: -84.841004, lat: 36.843563, postalCode: "42637" },
    { lng: -84.53353, lat: 38.399525, postalCode: "40370" },
    { lng: -82.99783, lat: 37.784924, postalCode: "41426" },
    { lng: -84.52064, lat: 39.0752, postalCode: "41011" },
    { lng: -84.115617, lat: 37.137615, postalCode: "40746" },
    { lng: -84.77674, lat: 37.303038, postalCode: "42566" },
    { lng: -84.05872, lat: 37.015283, postalCode: "40740" },
    { lng: -82.31477, lat: 37.60512, postalCode: "41519" },
    { lng: -84.60713, lat: 38.064087, postalCode: "40510" },
    { lng: -85.643359, lat: 38.309597, postalCode: "40025" },
    { lng: -84.450912, lat: 39.08359, postalCode: "41705" },
    { lng: -83.2528, lat: 36.858906, postalCode: "40801" },
    { lng: -82.70468, lat: 37.215305, postalCode: "41825" },
    { lng: -82.81775, lat: 37.048238, postalCode: "40826" },
    { lng: -82.85634, lat: 37.617197, postalCode: "41607" },
    { lng: -83.39943, lat: 37.263348, postalCode: "41730" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40588" },
    { lng: -82.9507, lat: 38.019104, postalCode: "41159" },
    { lng: -85.61319, lat: 38.26507, postalCode: "40222" },
    { lng: -83.80508, lat: 37.005242, postalCode: "40999" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40582" },
    { lng: -83.23821, lat: 37.932283, postalCode: "41472" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40225" },
    { lng: -83.28472, lat: 38.262029, postalCode: "41173" },
    { lng: -84.64401, lat: 38.983853, postalCode: "41042" },
    { lng: -84.23646, lat: 38.212471, postalCode: "40361" },
    { lng: -84.94589, lat: 37.305705, postalCode: "42539" },
    { lng: -85.43588, lat: 37.533395, postalCode: "40060" },
    { lng: -85.63299, lat: 38.329718, postalCode: "40027" },
    { lng: -82.78204, lat: 37.392257, postalCode: "41859" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40586" },
    { lng: -85.65733, lat: 38.193769, postalCode: "40218" },
    { lng: -84.77495, lat: 38.6622, postalCode: "41052" },
    { lng: -84.71603, lat: 38.930285, postalCode: "41091" },
    { lng: -82.72503, lat: 37.611434, postalCode: "41601" },
    { lng: -83.04838, lat: 38.688142, postalCode: "41137" },
    { lng: -83.21226, lat: 37.266633, postalCode: "41729" },
    { lng: -83.66257, lat: 36.888893, postalCode: "40997" },
    { lng: -83.32399, lat: 36.91707, postalCode: "40810" },
    { lng: -83.85894, lat: 38.52217, postalCode: "41055" },
    { lng: -83.15186, lat: 37.373384, postalCode: "41712" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40546" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40293" },
    { lng: -82.7264, lat: 37.449076, postalCode: "41647" },
    { lng: -83.16103, lat: 37.997666, postalCode: "41421" },
    { lng: -82.94544, lat: 37.65026, postalCode: "41464" },
    { lng: -83.44991, lat: 37.416002, postalCode: "41377" },
    { lng: -82.52903, lat: 37.866327, postalCode: "41224" },
    { lng: -84.579491, lat: 37.866238, postalCode: "40340" },
    { lng: -85.03388, lat: 38.60395, postalCode: "41098" },
    { lng: -84.56691, lat: 37.984907, postalCode: "40514" },
    { lng: -84.116614, lat: 37.167752, postalCode: "40755" },
    { lng: -83.38301, lat: 37.17621, postalCode: "41749" },
    { lng: -84.81256, lat: 37.431549, postalCode: "40437" },
    { lng: -86.35414, lat: 38.087303, postalCode: "40104" },
    { lng: -85.59169, lat: 38.147232, postalCode: "40291" },
    { lng: -85.33839, lat: 38.495454, postalCode: "40055" },
    { lng: -85.68218, lat: 38.141638, postalCode: "40219" },
    { lng: -82.63174, lat: 37.554631, postalCode: "41605" },
    { lng: -83.6512, lat: 38.286314, postalCode: "41049" },
    { lng: -84.96898, lat: 38.434443, postalCode: "40036" },
    { lng: -82.25408, lat: 37.633524, postalCode: "41569" },
    { lng: -84.51036, lat: 36.762748, postalCode: "42631" },
    { lng: -85.21658, lat: 38.211511, postalCode: "40065" },
    { lng: -86.10413, lat: 37.606543, postalCode: "42732" },
    { lng: -83.44991, lat: 36.753043, postalCode: "40863" },
    { lng: -83.51075, lat: 37.016332, postalCode: "40979" },
    { lng: -82.36125, lat: 37.610331, postalCode: "41564" },
    { lng: -82.8955, lat: 37.245041, postalCode: "41836" },
    { lng: -82.99081, lat: 37.141017, postalCode: "41804" },
    { lng: -85.94452, lat: 37.997957, postalCode: "40177" },
    { lng: -84.2939, lat: 38.397678, postalCode: "41031" },
    { lng: -82.73257, lat: 37.398774, postalCode: "41636" },
    { lng: -85.49036, lat: 37.928563, postalCode: "40013" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40524" },
    { lng: -83.70088, lat: 36.933443, postalCode: "40982" },
    { lng: -85.48714, lat: 38.308109, postalCode: "40056" },
    { lng: -85.81181, lat: 38.24142, postalCode: "40211" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40281" },
    { lng: -82.33349, lat: 37.396574, postalCode: "41540" },
    { lng: -83.54969, lat: 36.726258, postalCode: "40856" },
    { lng: -83.15467, lat: 36.930024, postalCode: "40870" },
    { lng: -85.79998, lat: 38.26632, postalCode: "40212" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40580" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40578" },
    { lng: -85.60987, lat: 37.47534, postalCode: "42716" },
    { lng: -82.81097, lat: 37.514748, postalCode: "41622" },
    { lng: -83.49048, lat: 37.325318, postalCode: "41721" },
    { lng: -84.11678, lat: 36.780333, postalCode: "40754" },
    { lng: -82.80914, lat: 37.86388, postalCode: "41274" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40252" },
    { lng: -83.18381, lat: 38.292177, postalCode: "41164" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40587" },
    { lng: -83.1156, lat: 37.369312, postalCode: "41722" },
    { lng: -84.54902, lat: 38.941953, postalCode: "41051" },
    { lng: -83.6848, lat: 38.057744, postalCode: "40358" },
    { lng: -83.776253, lat: 37.556255, postalCode: "41333" },
    { lng: -82.69699, lat: 37.322234, postalCode: "41667" },
    { lng: -82.95449, lat: 37.21583, postalCode: "41847" },
    { lng: -85.36122, lat: 38.210818, postalCode: "40067" },
    { lng: -82.744663, lat: 38.074113, postalCode: "41160" },
    { lng: -82.68331, lat: 37.935529, postalCode: "41264" },
    { lng: -83.8099, lat: 37.838747, postalCode: "40380" },
    { lng: -82.44835, lat: 37.846064, postalCode: "41203" },
    { lng: -82.69298, lat: 37.351125, postalCode: "41650" },
    { lng: -83.8438, lat: 37.9615, postalCode: "40337" },
    { lng: -82.23523, lat: 37.369612, postalCode: "41548" },
    { lng: -83.25414, lat: 37.295365, postalCode: "41719" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40512" },
    { lng: -82.32797, lat: 37.504315, postalCode: "41539" },
    { lng: -82.865092, lat: 37.771963, postalCode: "41215" },
    { lng: -82.27611, lat: 37.598267, postalCode: "41535" },
    { lng: -84.55458, lat: 36.965123, postalCode: "42519" },
    { lng: -83.95156, lat: 36.681189, postalCode: "40763" },
    { lng: -84.90009, lat: 37.55081, postalCode: "40464" },
    { lng: -85.60042, lat: 38.350915, postalCode: "40059" },
    { lng: -84.115617, lat: 37.137615, postalCode: "40745" },
    { lng: -84.7219, lat: 37.351449, postalCode: "40442" },
    { lng: -84.7757, lat: 36.932769, postalCode: "42632" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40201" },
    { lng: -83.14301, lat: 38.570194, postalCode: "41141" },
    { lng: -83.24129, lat: 36.901802, postalCode: "40865" },
    { lng: -85.4722, lat: 38.491982, postalCode: "40077" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40282" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40585" },
    { lng: -85.86403, lat: 37.704287, postalCode: "42701" },
    { lng: -82.59506, lat: 37.314335, postalCode: "41538" },
    { lng: -85.55574, lat: 38.258941, postalCode: "40223" },
    { lng: -84.205944, lat: 38.219407, postalCode: "40362" },
    { lng: -82.76039, lat: 37.831324, postalCode: "41260" },
    { lng: -84.115617, lat: 37.137615, postalCode: "40742" },
    { lng: -82.83138, lat: 37.386051, postalCode: "41861" },
    { lng: -83.58725, lat: 36.793291, postalCode: "40958" },
    { lng: -82.90494, lat: 38.272476, postalCode: "41146" },
    { lng: -84.045746, lat: 37.134455, postalCode: "40743" },
    { lng: -82.829405, lat: 37.470999, postalCode: "41643" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40577" },
    { lng: -83.7488, lat: 37.32832, postalCode: "40983" },
    { lng: -82.99572, lat: 36.972292, postalCode: "40823" },
    { lng: -85.101879, lat: 38.552133, postalCode: "40075" },
    { lng: -83.268234, lat: 38.176471, postalCode: "40317" },
    { lng: -85.71452, lat: 37.566527, postalCode: "42748" },
    { lng: -83.72186, lat: 36.617181, postalCode: "40965" },
    { lng: -82.39516, lat: 37.300397, postalCode: "41522" },
    { lng: -83.41058, lat: 36.823334, postalCode: "40873" },
    { lng: -83.074592, lat: 37.690636, postalCode: "41444" },
    { lng: -82.464979, lat: 37.314848, postalCode: "41542" },
    { lng: -82.61836, lat: 38.360669, postalCode: "41129" },
    { lng: -82.87543, lat: 37.420362, postalCode: "41839" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40257" },
    { lng: -83.38506, lat: 38.530915, postalCode: "41179" },
    { lng: -83.9771, lat: 37.694764, postalCode: "40336" },
    { lng: -82.87272, lat: 37.587133, postalCode: "41616" },
    { lng: -82.60019, lat: 37.293386, postalCode: "41549" },
    { lng: -84.520501, lat: 38.944663, postalCode: "41012" },
    { lng: -83.66771, lat: 37.430777, postalCode: "41314" },
    { lng: -82.726407, lat: 37.831277, postalCode: "41271" },
    { lng: -85.58424, lat: 38.305636, postalCode: "40241" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40603" },
    { lng: -85.16803, lat: 38.663328, postalCode: "41008" },
    { lng: -84.1508, lat: 36.709853, postalCode: "40769" },
    { lng: -83.233761, lat: 37.914002, postalCode: "41352" },
    { lng: -84.55057, lat: 39.087593, postalCode: "41016" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40584" },
    { lng: -82.77714, lat: 38.298546, postalCode: "41168" },
    { lng: -82.7207, lat: 37.358509, postalCode: "41612" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40224" },
    { lng: -84.05917, lat: 36.817151, postalCode: "40759" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40259" },
    { lng: -82.32708, lat: 37.445906, postalCode: "41554" },
    { lng: -83.0695, lat: 38.68177, postalCode: "41170" },
    { lng: -82.80178, lat: 37.544465, postalCode: "41645" },
    { lng: -82.93216, lat: 37.123583, postalCode: "41845" },
    { lng: -82.62091, lat: 37.855873, postalCode: "41262" },
    { lng: -83.287774, lat: 37.593059, postalCode: "41366" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40575" },
    { lng: -83.36866, lat: 37.797688, postalCode: "41332" },
    { lng: -85.57066, lat: 38.411964, postalCode: "40026" },
    { lng: -83.31253, lat: 37.257714, postalCode: "41723" },
    { lng: -83.074592, lat: 37.690636, postalCode: "41419" },
    { lng: -84.38904, lat: 38.535065, postalCode: "41003" },
    { lng: -82.67347, lat: 37.205961, postalCode: "41835" },
    { lng: -82.54639, lat: 37.216465, postalCode: "41563" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40261" },
    { lng: -83.64971, lat: 37.791388, postalCode: "41360" },
    { lng: -82.90279, lat: 37.933492, postalCode: "41219" },
    { lng: -85.78405, lat: 38.19192, postalCode: "40215" },
    { lng: -84.38652, lat: 38.946893, postalCode: "41001" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40298" },
    { lng: -84.61645, lat: 38.037847, postalCode: "40501" },
    { lng: -83.13361, lat: 37.207333, postalCode: "41751" },
    { lng: -85.976854, lat: 37.723474, postalCode: "40159" },
    { lng: -83.757123, lat: 38.086673, postalCode: "40366" },
    { lng: -83.44978, lat: 37.187088, postalCode: "41766" },
    { lng: -83.37539, lat: 36.840955, postalCode: "40824" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40285" },
    { lng: -82.85098, lat: 38.132937, postalCode: "41180" },
    { lng: -84.61781, lat: 37.250746, postalCode: "42567" },
    { lng: -85.76241, lat: 38.218764, postalCode: "40208" },
    { lng: -84.588, lat: 37.112274, postalCode: "42503" },
    { lng: -85.17208, lat: 38.370336, postalCode: "40019" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40297" },
    { lng: -82.71232, lat: 37.195448, postalCode: "41840" },
    { lng: -83.475446, lat: 37.740503, postalCode: "41313" },
    { lng: -83.074592, lat: 37.690636, postalCode: "41433" },
    { lng: -82.79468, lat: 37.150537, postalCode: "41815" },
    { lng: -82.47961, lat: 37.254188, postalCode: "41512" },
    { lng: -82.28283, lat: 37.553553, postalCode: "41543" },
    { lng: -82.68897, lat: 37.81711, postalCode: "41204" },
    { lng: -84.115617, lat: 37.137615, postalCode: "40747" },
    { lng: -83.133597, lat: 38.433912, postalCode: "41128" },
    { lng: -83.05005, lat: 38.061324, postalCode: "41149" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40533" },
    { lng: -85.739, lat: 38.21632, postalCode: "40217" },
    { lng: -84.5679, lat: 38.628866, postalCode: "41097" },
    { lng: -83.32795, lat: 36.987495, postalCode: "40816" },
    { lng: -84.46109, lat: 38.058477, postalCode: "40505" },
    { lng: -83.74576, lat: 36.867971, postalCode: "40935" },
    { lng: -84.157766, lat: 37.968496, postalCode: "40320" },
    { lng: -85.33889, lat: 38.597624, postalCode: "40006" },
    { lng: -83.074592, lat: 37.690636, postalCode: "41422" },
    { lng: -83.34049, lat: 37.350689, postalCode: "41727" },
    { lng: -84.140847, lat: 37.152074, postalCode: "40724" },
    { lng: -82.57034, lat: 37.775385, postalCode: "41214" },
    { lng: -85.662215, lat: 38.25361, postalCode: "40041" },
    { lng: -83.4399, lat: 37.328336, postalCode: "41745" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40592" },
    { lng: -83.56789, lat: 37.112359, postalCode: "40914" },
    { lng: -83.72023, lat: 36.750028, postalCode: "40977" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40266" },
    { lng: -82.6932, lat: 38.439526, postalCode: "41102" },
    { lng: -84.39156, lat: 39.034293, postalCode: "41085" },
    { lng: -82.73277, lat: 37.362943, postalCode: "41606" },
    { lng: -85.17658, lat: 38.435604, postalCode: "40050" },
    { lng: -82.916586, lat: 38.565211, postalCode: "41156" },
    { lng: -83.08501, lat: 37.115385, postalCode: "41731" },
    { lng: -83.76603, lat: 38.152302, postalCode: "40360" },
    { lng: -84.17023, lat: 37.98223, postalCode: "40391" },
    { lng: -84.115617, lat: 37.137615, postalCode: "40748" },
    { lng: -82.72175, lat: 38.52032, postalCode: "41139" },
    { lng: -82.82744, lat: 37.780917, postalCode: "41222" },
    { lng: -83.63647, lat: 37.711278, postalCode: "41365" },
    { lng: -82.75942, lat: 37.496608, postalCode: "41619" },
    { lng: -86.20383, lat: 37.894075, postalCode: "40142" },
    { lng: -85.3549, lat: 37.372314, postalCode: "42718" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40576" },
    { lng: -85.65602, lat: 37.768921, postalCode: "40107" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40231" },
    { lng: -84.78862, lat: 37.644426, postalCode: "40422" },
    { lng: -82.95634, lat: 37.148006, postalCode: "41832" },
    { lng: -86.00073, lat: 37.756775, postalCode: "40162" },
    { lng: -82.94995, lat: 37.555846, postalCode: "41632" },
    { lng: -85.70254, lat: 38.254671, postalCode: "40206" },
    { lng: -83.993588, lat: 37.416008, postalCode: "40467" },
    { lng: -84.546693, lat: 37.651397, postalCode: "40410" },
    { lng: -83.48003, lat: 36.982545, postalCode: "40874" },
    { lng: -84.47987, lat: 39.103011, postalCode: "41073" },
    { lng: -82.28465, lat: 37.67059, postalCode: "41503" },
    { lng: -85.1628, lat: 37.353227, postalCode: "42733" },
    { lng: -85.02396, lat: 37.180186, postalCode: "42528" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40295" },
    { lng: -82.79564, lat: 37.357624, postalCode: "41862" },
    { lng: -83.62327, lat: 37.382832, postalCode: "41364" },
    { lng: -82.23762, lat: 37.424071, postalCode: "41524" },
    { lng: -83.08652, lat: 37.736751, postalCode: "41465" },
    { lng: -84.405839, lat: 37.40382, postalCode: "40469" },
    { lng: -82.98159, lat: 37.021403, postalCode: "41833" },
    { lng: -83.81722, lat: 37.473347, postalCode: "41386" },
    { lng: -83.09554, lat: 38.089097, postalCode: "41171" },
    { lng: -84.115617, lat: 37.137615, postalCode: "40751" },
    { lng: -86.085259, lat: 37.567134, postalCode: "42783" },
    { lng: -84.09411, lat: 38.677837, postalCode: "41004" },
    { lng: -84.75676, lat: 37.460003, postalCode: "40448" },
    { lng: -82.71906, lat: 37.331465, postalCode: "41669" },
    { lng: -82.55812, lat: 37.389786, postalCode: "41560" },
    { lng: -84.49419, lat: 39.013062, postalCode: "41015" },
    { lng: -83.533691, lat: 38.140258, postalCode: "40319" },
    { lng: -83.24466, lat: 37.640938, postalCode: "41310" },
    { lng: -83.23714, lat: 37.370521, postalCode: "41736" },
    { lng: -83.86286, lat: 38.424954, postalCode: "41039" },
    { lng: -84.33792, lat: 38.796981, postalCode: "41006" },
    { lng: -82.76167, lat: 37.156856, postalCode: "41855" },
    { lng: -83.02313, lat: 37.393894, postalCode: "41772" },
    { lng: -83.92964, lat: 38.061248, postalCode: "40353" },
    { lng: -84.48874, lat: 37.985128, postalCode: "40517" },
    { lng: -83.69224, lat: 37.78422, postalCode: "40376" },
    { lng: -83.233761, lat: 37.914002, postalCode: "41477" },
    { lng: -82.94106, lat: 37.841448, postalCode: "41238" },
    { lng: -82.90522, lat: 36.96222, postalCode: "40855" },
    { lng: -86.11831, lat: 37.973094, postalCode: "40108" },
    { lng: -82.81233, lat: 37.940709, postalCode: "41257" },
    { lng: -85.77624, lat: 38.160936, postalCode: "40214" },
    { lng: -82.15907, lat: 37.506174, postalCode: "41553" },
    { lng: -85.00764, lat: 38.452451, postalCode: "40007" },
    { lng: -83.06497, lat: 36.98586, postalCode: "41819" },
    { lng: -84.28433, lat: 37.745999, postalCode: "40475" },
    { lng: -82.57732, lat: 37.279027, postalCode: "41520" },
    { lng: -82.64062, lat: 38.071237, postalCode: "41230" },
    { lng: -84.36575, lat: 39.022732, postalCode: "41059" },
    { lng: -85.85994, lat: 38.096056, postalCode: "40272" },
    { lng: -82.80852, lat: 37.446983, postalCode: "41666" },
    { lng: -82.70856, lat: 38.538456, postalCode: "41169" },
    { lng: -83.81552, lat: 36.887081, postalCode: "40915" },
    { lng: -83.24326, lat: 37.113248, postalCode: "41764" },
    { lng: -82.73746, lat: 37.916701, postalCode: "41232" },
    { lng: -85.976854, lat: 37.723474, postalCode: "42702" },
    { lng: -85.460504, lat: 38.40464, postalCode: "40032" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40290" },
    { lng: -85.75362, lat: 38.106135, postalCode: "40118" },
    { lng: -84.41611, lat: 36.663153, postalCode: "42635" },
    { lng: -84.88807, lat: 38.709838, postalCode: "41086" },
    { lng: -83.38533, lat: 37.058837, postalCode: "40844" },
    { lng: -85.46164, lat: 37.81109, postalCode: "40004" },
    { lng: -82.95633, lat: 37.387358, postalCode: "41831" },
    { lng: -83.073603, lat: 37.745824, postalCode: "41463" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40595" },
    { lng: -85.21241, lat: 37.905918, postalCode: "40012" },
    { lng: -84.4859, lat: 38.017219, postalCode: "40502" },
    { lng: -82.760975, lat: 37.670986, postalCode: "41668" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40604" },
    { lng: -84.98037, lat: 37.65487, postalCode: "40468" },
    { lng: -85.1869, lat: 38.026767, postalCode: "40046" },
    { lng: -82.97212, lat: 37.333551, postalCode: "41822" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40555" },
    { lng: -84.03936, lat: 37.471602, postalCode: "40488" },
    { lng: -82.83496, lat: 38.251159, postalCode: "41132" },
    { lng: -83.57103, lat: 38.37106, postalCode: "41093" },
    { lng: -82.75036, lat: 37.168682, postalCode: "41838" },
    { lng: -85.355132, lat: 37.579519, postalCode: "40063" },
    { lng: -83.708181, lat: 36.769184, postalCode: "40955" },
    { lng: -83.233761, lat: 37.914002, postalCode: "41451" },
    { lng: -83.13673, lat: 37.158397, postalCode: "41774" },
    { lng: -82.78167, lat: 37.823275, postalCode: "41240" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40232" },
    { lng: -85.27741, lat: 38.400619, postalCode: "40068" },
    { lng: -82.82615, lat: 38.456608, postalCode: "41121" },
    { lng: -83.73183, lat: 37.979941, postalCode: "40346" },
    { lng: -85.38475, lat: 38.407833, postalCode: "40031" },
    { lng: -85.4584, lat: 38.335106, postalCode: "40014" },
    { lng: -84.82841, lat: 36.801922, postalCode: "42633" },
    { lng: -85.8807, lat: 37.52025, postalCode: "42776" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40579" },
    { lng: -83.70348, lat: 37.578547, postalCode: "41311" },
    { lng: -84.28194, lat: 38.910255, postalCode: "41007" },
    { lng: -82.48919, lat: 37.758445, postalCode: "41250" },
    { lng: -82.8409, lat: 37.470216, postalCode: "41630" },
    { lng: -84.071, lat: 37.060576, postalCode: "40744" },
    { lng: -84.62558, lat: 38.87872, postalCode: "41094" },
    { lng: -82.89858, lat: 37.004426, postalCode: "40862" },
    { lng: -85.75298, lat: 38.19187, postalCode: "40209" },
    { lng: -83.61146, lat: 38.087867, postalCode: "40371" },
    { lng: -84.56801, lat: 39.036361, postalCode: "41017" },
    { lng: -83.910276, lat: 38.719573, postalCode: "41062" },
    { lng: -83.122477, lat: 37.300202, postalCode: "41747" },
    { lng: -82.61598, lat: 37.322411, postalCode: "41572" },
    { lng: -85.53657, lat: 38.237155, postalCode: "40243" },
    { lng: -84.602, lat: 38.788439, postalCode: "41030" },
    { lng: -82.69496, lat: 37.601078, postalCode: "41603" },
    { lng: -83.26552, lat: 36.807137, postalCode: "40818" },
    { lng: -84.86935, lat: 38.201649, postalCode: "40601" },
    { lng: -83.29911, lat: 37.426337, postalCode: "41348" },
    { lng: -84.745488, lat: 38.021292, postalCode: "40384" },
    { lng: -83.43129, lat: 36.949181, postalCode: "40840" },
    { lng: -84.31614, lat: 37.345751, postalCode: "40492" },
    { lng: -82.518527, lat: 37.437194, postalCode: "41526" },
    { lng: -84.49336, lat: 38.051727, postalCode: "40508" },
    { lng: -85.44671, lat: 37.756941, postalCode: "40048" },
    { lng: -82.21145, lat: 37.533643, postalCode: "41558" },
    { lng: -82.64774, lat: 37.568942, postalCode: "41663" },
    { lng: -83.09845, lat: 37.204045, postalCode: "41746" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40283" },
    { lng: -84.60229, lat: 39.013755, postalCode: "41018" },
    { lng: -83.125202, lat: 37.970507, postalCode: "41413" },
    { lng: -83.42003, lat: 37.046365, postalCode: "40803" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40287" },
    { lng: -82.86299, lat: 37.832617, postalCode: "41256" },
    { lng: -82.64922, lat: 37.479754, postalCode: "41631" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40590" },
    { lng: -82.4571, lat: 37.416995, postalCode: "41562" },
    { lng: -85.55467, lat: 38.045982, postalCode: "40047" },
    { lng: -84.54259, lat: 38.039322, postalCode: "40504" },
    { lng: -82.39411, lat: 37.810757, postalCode: "41231" },
    { lng: -83.52009, lat: 36.786271, postalCode: "40845" },
    { lng: -84.69614, lat: 38.29617, postalCode: "40379" },
    { lng: -83.25264, lat: 36.981495, postalCode: "40808" },
    { lng: -83.03409, lat: 37.229381, postalCode: "41759" },
    { lng: -84.07839, lat: 37.143828, postalCode: "40741" },
    { lng: -82.74012, lat: 37.73696, postalCode: "41602" },
    { lng: -82.94551, lat: 37.992794, postalCode: "41226" },
    { lng: -85.93888, lat: 37.828459, postalCode: "40160" },
    { lng: -82.63494, lat: 37.509291, postalCode: "41635" },
    { lng: -83.718626, lat: 38.3481, postalCode: "41081" },
    { lng: -84.40954, lat: 38.005199, postalCode: "40509" },
    { lng: -84.40789, lat: 37.59483, postalCode: "40461" },
    { lng: -83.908492, lat: 37.395468, postalCode: "40434" },
    { lng: -84.79832, lat: 37.191504, postalCode: "42516" },
    { lng: -85.37187, lat: 38.702793, postalCode: "40045" },
    { lng: -83.713974, lat: 37.60332, postalCode: "41362" },
    { lng: -82.73688, lat: 37.887278, postalCode: "41254" },
    { lng: -83.731809, lat: 36.608426, postalCode: "40695" },
    { lng: -82.23349, lat: 37.599893, postalCode: "41531" },
    { lng: -84.13026, lat: 37.231717, postalCode: "40729" },
    { lng: -83.41586, lat: 38.156248, postalCode: "40313" },
    { lng: -84.48802, lat: 38.842166, postalCode: "41063" },
    { lng: -85.83137, lat: 38.188886, postalCode: "40216" },
    { lng: -84.6418, lat: 38.691006, postalCode: "41035" },
    { lng: -84.63278, lat: 36.94593, postalCode: "42518" },
    { lng: -83.19061, lat: 37.274854, postalCode: "41701" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40233" },
    { lng: -84.73189, lat: 38.042639, postalCode: "40383" },
    { lng: -85.68326, lat: 38.22292, postalCode: "40205" },
    { lng: -84.68485, lat: 38.815335, postalCode: "41092" },
    { lng: -83.88762, lat: 38.730747, postalCode: "41034" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40581" },
    { lng: -84.53452, lat: 38.007898, postalCode: "40503" },
    { lng: -82.77851, lat: 37.303276, postalCode: "41828" },
    { lng: -85.393204, lat: 37.660251, postalCode: "40049" },
    { lng: -82.78437, lat: 37.789291, postalCode: "41268" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40221" },
    { lng: -85.45004, lat: 37.590699, postalCode: "40062" },
    { lng: -85.447393, lat: 37.7604, postalCode: "40053" },
    { lng: -82.41666, lat: 37.855146, postalCode: "41267" },
    { lng: -83.08919, lat: 37.029744, postalCode: "41735" },
    { lng: -84.49627, lat: 38.047408, postalCode: "40507" },
    { lng: -82.7337, lat: 37.795418, postalCode: "41234" },
    { lng: -83.05265, lat: 36.889259, postalCode: "40927" },
    { lng: -85.38765, lat: 37.9348, postalCode: "40020" },
    { lng: -84.21527, lat: 37.302783, postalCode: "40445" },
    { lng: -84.14595, lat: 36.705162, postalCode: "40730" },
    { lng: -85.198031, lat: 37.360392, postalCode: "42758" },
    { lng: -84.59496, lat: 37.667083, postalCode: "40444" },
    { lng: -82.40573, lat: 37.364548, postalCode: "41559" },
    { lng: -84.41808, lat: 37.374681, postalCode: "40409" },
    { lng: -84.546445, lat: 39.0358, postalCode: "41000" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40544" },
    { lng: -83.98146, lat: 36.930995, postalCode: "40734" },
    { lng: -84.58619, lat: 38.010247, postalCode: "40513" },
    { lng: -82.26665, lat: 37.52976, postalCode: "41555" },
    { lng: -83.206495, lat: 37.320076, postalCode: "41702" },
    { lng: -84.60301, lat: 37.067452, postalCode: "42533" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40591" },
    { lng: -84.81223, lat: 38.742296, postalCode: "41046" },
    { lng: -82.83185, lat: 37.130559, postalCode: "41858" },
    { lng: -83.475446, lat: 37.740503, postalCode: "41342" },
    { lng: -85.92294, lat: 37.59641, postalCode: "42740" },
    { lng: -84.80732, lat: 37.3677, postalCode: "42541" },
    { lng: -83.99683, lat: 38.76189, postalCode: "41002" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40619" },
    { lng: -84.48681, lat: 39.08347, postalCode: "41071" },
    { lng: -83.72497, lat: 38.418131, postalCode: "41041" },
    { lng: -85.7665, lat: 38.248106, postalCode: "40203" },
    { lng: -83.63429, lat: 37.937105, postalCode: "40322" },
    { lng: -83.96651, lat: 38.63785, postalCode: "41044" },
    { lng: -84.55416, lat: 36.710692, postalCode: "42647" },
    { lng: -83.87442, lat: 38.304494, postalCode: "40350" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40253" },
    { lng: -85.0227, lat: 37.561722, postalCode: "40328" },
    { lng: -84.24292, lat: 37.406069, postalCode: "40460" },
    { lng: -82.75876, lat: 37.667872, postalCode: "41653" },
    { lng: -85.10175, lat: 37.477192, postalCode: "40009" },
    { lng: -84.107655, lat: 38.146395, postalCode: "40357" },
    { lng: -85.05007, lat: 38.724439, postalCode: "41045" },
    { lng: -85.50817, lat: 37.585365, postalCode: "40052" },
    { lng: -84.581482, lat: 36.94919, postalCode: "42558" },
    { lng: -83.737014, lat: 37.147687, postalCode: "40944" },
    { lng: -84.68754, lat: 39.0805, postalCode: "41048" },
    { lng: -84.47785, lat: 36.772507, postalCode: "42653" },
    { lng: -82.75013, lat: 37.241284, postalCode: "41812" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40268" },
    { lng: -83.37595, lat: 37.424083, postalCode: "41390" },
    { lng: -84.45127, lat: 39.076011, postalCode: "41075" },
    { lng: -82.6235, lat: 37.192483, postalCode: "41537" },
    { lng: -84.32811, lat: 38.656527, postalCode: "41040" },
    { lng: -84.578552, lat: 38.593226, postalCode: "41054" },
    { lng: -85.212156, lat: 38.197268, postalCode: "40066" },
    { lng: -82.05643, lat: 37.49947, postalCode: "41568" },
    { lng: -83.18256, lat: 37.461944, postalCode: "41317" },
    { lng: -84.44889, lat: 38.773111, postalCode: "41033" },
    { lng: -82.62562, lat: 37.569845, postalCode: "41659" },
    { lng: -83.63388, lat: 36.836208, postalCode: "40902" },
    { lng: -83.85889, lat: 37.369321, postalCode: "40486" },
    { lng: -84.16998, lat: 38.765448, postalCode: "41043" },
    { lng: -82.73508, lat: 38.54912, postalCode: "41183" },
    { lng: -82.30442, lat: 37.672296, postalCode: "41514" },
    { lng: -84.368926, lat: 38.963761, postalCode: "41099" },
    { lng: -85.639231, lat: 37.463961, postalCode: "42764" },
    { lng: -83.91734, lat: 38.201485, postalCode: "40374" },
    { lng: -84.14124, lat: 36.990797, postalCode: "40737" },
    { lng: -84.520501, lat: 38.944663, postalCode: "41053" },
    { lng: -83.704908, lat: 37.398617, postalCode: "41338" },
    { lng: -83.81508, lat: 36.78737, postalCode: "40995" },
    { lng: -82.06488, lat: 37.544224, postalCode: "41547" },
    { lng: -83.02976, lat: 37.33195, postalCode: "41725" },
    { lng: -84.299699, lat: 37.716443, postalCode: "40405" },
    { lng: -84.10685, lat: 36.942035, postalCode: "40701" },
    { lng: -83.11137, lat: 37.348289, postalCode: "41739" },
    { lng: -85.66304, lat: 38.22977, postalCode: "40018" },
    { lng: -82.27793, lat: 37.641773, postalCode: "41527" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40594" },
    { lng: -83.17747, lat: 36.86772, postalCode: "40828" },
    { lng: -83.33711, lat: 36.712383, postalCode: "40867" },
    { lng: -84.546693, lat: 37.651397, postalCode: "40446" },
    { lng: -85.27425, lat: 38.486325, postalCode: "40070" },
    { lng: -84.299699, lat: 37.716443, postalCode: "40476" },
    { lng: -83.81984, lat: 36.943114, postalCode: "40953" },
    { lng: -82.48553, lat: 37.2701, postalCode: "41534" },
    { lng: -82.94073, lat: 37.110216, postalCode: "41848" },
    { lng: -84.592898, lat: 37.111433, postalCode: "42564" },
    { lng: -85.33158, lat: 38.147801, postalCode: "40022" },
    { lng: -83.3187, lat: 37.279297, postalCode: "41778" },
    { lng: -84.81023, lat: 38.467809, postalCode: "40359" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40550" },
    { lng: -82.86011, lat: 37.273854, postalCode: "41843" },
    { lng: -83.737014, lat: 37.147687, postalCode: "40951" },
    { lng: -82.6933, lat: 37.550496, postalCode: "41615" },
    { lng: -85.72626, lat: 38.23862, postalCode: "40204" },
    { lng: -83.02353, lat: 38.71293, postalCode: "41174" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40602" },
    { lng: -85.90164, lat: 37.456599, postalCode: "42784" },
    { lng: -85.62741, lat: 38.137586, postalCode: "40228" },
    { lng: -84.65481, lat: 37.177577, postalCode: "42553" },
    { lng: -82.7598, lat: 37.8583, postalCode: "41263" },
    { lng: -85.330374, lat: 37.337936, postalCode: "42719" },
    { lng: -85.73559, lat: 38.062515, postalCode: "40109" },
    { lng: -83.9343, lat: 37.846536, postalCode: "40312" },
    { lng: -83.62117, lat: 37.502641, postalCode: "41344" },
    { lng: -85.73269, lat: 37.849334, postalCode: "40150" },
    { lng: -85.58099, lat: 37.656291, postalCode: "40051" },
    { lng: -84.70928, lat: 38.153759, postalCode: "40347" },
    { lng: -82.462574, lat: 37.33162, postalCode: "41561" },
    { lng: -83.12461, lat: 36.885169, postalCode: "40849" },
    { lng: -84.96756, lat: 38.666588, postalCode: "41083" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40292" },
    { lng: -82.13471, lat: 37.561944, postalCode: "41528" },
    { lng: -83.9759, lat: 37.29409, postalCode: "40402" },
    { lng: -84.070074, lat: 38.688556, postalCode: "41061" },
    { lng: -83.39768, lat: 37.018413, postalCode: "40858" },
    { lng: -83.04938, lat: 37.348377, postalCode: "41740" },
    { lng: -82.99728, lat: 37.088069, postalCode: "41821" },
    { lng: -82.907615, lat: 38.204459, postalCode: "41181" },
    { lng: -84.09155, lat: 37.43424, postalCode: "40481" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40269" },
    { lng: -85.43238, lat: 38.182772, postalCode: "40023" },
    { lng: -82.3631, lat: 37.357536, postalCode: "41513" },
    { lng: -85.946585, lat: 37.998628, postalCode: "40179" },
    { lng: -82.694757, lat: 38.370285, postalCode: "41114" },
    { lng: -84.85236, lat: 39.062761, postalCode: "41080" },
    { lng: -84.1031, lat: 36.937426, postalCode: "40702" },
    { lng: -83.50599, lat: 37.223635, postalCode: "41762" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40621" },
    { lng: -86.14434, lat: 37.915077, postalCode: "40117" },
    { lng: -84.74158, lat: 39.020466, postalCode: "41005" },
    { lng: -85.685261, lat: 38.246721, postalCode: "40280" },
    { lng: -83.242129, lat: 38.225087, postalCode: "41150" },
    { lng: -84.520501, lat: 38.944663, postalCode: "41019" },
    { lng: -86.03115, lat: 37.55125, postalCode: "42788" },
    { lng: -84.64418, lat: 37.942941, postalCode: "40339" },
    { lng: -83.39265, lat: 37.11181, postalCode: "40868" },
    { lng: -84.508361, lat: 38.032075, postalCode: "40536" },
    { lng: -83.8385, lat: 36.825088, postalCode: "40903" },
    { lng: -84.76904, lat: 37.754255, postalCode: "40310" },
    { lng: -82.72053, lat: 37.511126, postalCode: "41655" },
    { lng: -83.05627, lat: 37.221636, postalCode: "41773" },
    { lng: -82.64532, lat: 38.473961, postalCode: "41101" },
    { lng: -85.69667, lat: 37.997938, postalCode: "40165" },
    { lng: -83.361625, lat: 38.602881, postalCode: "41127" },
    { lng: -83.92535, lat: 36.607234, postalCode: "40940" },
    { lng: -84.89524, lat: 37.144759, postalCode: "42565" },
    { lng: -84.50775, lat: 38.105283, postalCode: "40511" },
    { lng: -82.81931, lat: 37.756007, postalCode: "41216" },
    { lng: -82.38704, lat: 37.625031, postalCode: "41571" },
    { lng: -84.46986, lat: 39.10941, postalCode: "41074" },
    { lng: -82.93047, lat: 37.164562, postalCode: "41826" },
    { lng: -85.55348, lat: 38.190568, postalCode: "40299" },
    { lng: -83.35315, lat: 36.85107, postalCode: "40854" },
    { lng: -84.94075, lat: 38.012479, postalCode: "40342" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40620" },
    { lng: -85.201068, lat: 37.773962, postalCode: "40061" },
    { lng: -84.05145, lat: 38.508638, postalCode: "41064" },
    { lng: -83.83413, lat: 36.966634, postalCode: "40946" },
    { lng: -83.718626, lat: 38.3481, postalCode: "41065" },
    { lng: -83.993588, lat: 37.416008, postalCode: "40421" },
    { lng: -85.62207, lat: 38.214982, postalCode: "40220" },
    { lng: -82.61109, lat: 37.184477, postalCode: "41517" },
    { lng: -84.31614, lat: 37.345751, postalCode: "40473" },
    { lng: -83.829985, lat: 36.871254, postalCode: "40939" },
    { lng: -85.06609, lat: 38.386916, postalCode: "40057" },
    { lng: -82.87484, lat: 37.752473, postalCode: "41228" },
    { lng: -86.00692, lat: 37.826822, postalCode: "40175" },
    { lng: -82.83962, lat: 37.916221, postalCode: "41255" },
    { lng: -85.385382, lat: 37.93388, postalCode: "40080" },
    { lng: -83.64341, lat: 37.611684, postalCode: "41368" },
    { lng: -86.02527, lat: 37.668721, postalCode: "42724" },
    { lng: -82.95015, lat: 36.963608, postalCode: "40807" },
    { lng: -84.61017, lat: 38.484843, postalCode: "41010" },
    { lng: -85.06677, lat: 38.122088, postalCode: "40076" },
    { lng: -84.845767, lat: 37.624659, postalCode: "40423" },
    { lng: -82.16836, lat: 37.59865, postalCode: "41544" },
    { lng: -84.27767, lat: 37.575568, postalCode: "40403" },
    { lng: -82.72934, lat: 37.172969, postalCode: "41849" },
    { lng: -83.30778, lat: 36.872801, postalCode: "40806" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40251" },
    { lng: -84.42143, lat: 36.881682, postalCode: "42634" },
    { lng: -85.65134, lat: 38.257489, postalCode: "40207" },
    { lng: -83.88501, lat: 36.853913, postalCode: "40906" },
    { lng: -84.66069, lat: 37.85743, postalCode: "40390" },
    { lng: -83.90657, lat: 36.72179, postalCode: "40921" },
    { lng: -82.94997, lat: 38.323106, postalCode: "41143" },
    { lng: -84.157766, lat: 37.968496, postalCode: "40392" },
    { lng: -83.2852, lat: 37.167173, postalCode: "41776" },
    { lng: -85.00607, lat: 38.522034, postalCode: "40363" },
    { lng: -83.28137, lat: 37.792272, postalCode: "41408" },
    { lng: -85.99137, lat: 37.937825, postalCode: "40155" },
    { lng: -83.4321, lat: 38.19056, postalCode: "40351" },
    { lng: -84.082894, lat: 37.709721, postalCode: "40495" },
    { lng: -83.60248, lat: 36.737835, postalCode: "40813" },
    { lng: -84.45347, lat: 37.952113, postalCode: "40515" },
    { lng: -85.492459, lat: 38.3386, postalCode: "40081" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40270" },
    { lng: -82.86514, lat: 38.552472, postalCode: "41144" },
    { lng: -82.66944, lat: 37.370358, postalCode: "41604" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40589" },
    { lng: -85.45075, lat: 38.366467, postalCode: "40010" },
    { lng: -85.66979, lat: 38.085601, postalCode: "40229" },
    { lng: -83.34547, lat: 37.533559, postalCode: "41339" },
    { lng: -85.48322, lat: 38.270855, postalCode: "40245" },
    { lng: -83.22479, lat: 37.065439, postalCode: "41777" },
    { lng: -84.05237, lat: 36.888978, postalCode: "40771" },
    { lng: -82.88714, lat: 37.194426, postalCode: "41824" },
    { lng: -84.14578, lat: 38.302495, postalCode: "40348" },
    { lng: -84.507471, lat: 38.028712, postalCode: "40506" },
    { lng: -83.600784, lat: 37.54418, postalCode: "41347" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40593" },
    { lng: -82.939541, lat: 38.338445, postalCode: "41163" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40526" },
    { lng: -83.34647, lat: 37.10014, postalCode: "41775" },
    { lng: -83.233761, lat: 37.914002, postalCode: "41459" },
    { lng: -82.9403, lat: 37.270358, postalCode: "41834" },
    { lng: -85.25148, lat: 37.565894, postalCode: "40033" },
    { lng: -84.55465, lat: 38.221179, postalCode: "40324" },
    { lng: -83.53262, lat: 36.942094, postalCode: "40913" },
    { lng: -83.13904, lat: 37.04877, postalCode: "41763" },
    { lng: -82.5189, lat: 37.478169, postalCode: "41501" },
    { lng: -84.571, lat: 37.883886, postalCode: "40356" },
    { lng: -85.17178, lat: 38.53445, postalCode: "40011" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40296" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40256" },
    { lng: -82.28796, lat: 37.560929, postalCode: "41567" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40294" },
    { lng: -84.81365, lat: 37.029286, postalCode: "42544" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40574" },
    { lng: -84.88657, lat: 37.911825, postalCode: "40372" },
    { lng: -82.550507, lat: 37.481637, postalCode: "41502" },
    { lng: -83.91384, lat: 37.703394, postalCode: "40472" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40250" },
    { lng: -86.38511, lat: 38.028543, postalCode: "40157" },
    { lng: -85.41337, lat: 37.652712, postalCode: "40037" },
    { lng: -85.36427, lat: 38.045406, postalCode: "40071" },
    { lng: -84.11389, lat: 37.724497, postalCode: "40385" },
    { lng: -82.18823, lat: 37.417254, postalCode: "41566" },
    { lng: -84.85812, lat: 37.773923, postalCode: "40330" },
    { lng: -83.27206, lat: 38.365096, postalCode: "41135" },
    { lng: -86.398391, lat: 38.005963, postalCode: "40161" },
    { lng: -83.737014, lat: 37.147687, postalCode: "40932" },
    { lng: -83.090441, lat: 37.30636, postalCode: "41743" },
    { lng: -85.22257, lat: 37.702418, postalCode: "40069" },
    { lng: -84.94918, lat: 37.603345, postalCode: "40452" },
    { lng: -84.633915, lat: 37.17925, postalCode: "42253" },
    { lng: -85.65545, lat: 37.93462, postalCode: "40110" },
    { lng: -83.31856, lat: 36.828285, postalCode: "40831" },
    { lng: -85.78665, lat: 38.23067, postalCode: "40210" },
    { lng: -85.71304, lat: 38.186126, postalCode: "40213" },
    { lng: -85.12572, lat: 37.840066, postalCode: "40078" },
    { lng: -84.47095, lat: 36.673236, postalCode: "42638" },
    { lng: -83.85699, lat: 36.920165, postalCode: "40923" },
    { lng: -83.0727, lat: 38.282767, postalCode: "41142" },
    { lng: -84.747789, lat: 38.962372, postalCode: "41022" },
    { lng: -82.94192, lat: 37.493888, postalCode: "41640" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40622" },
    { lng: -84.874809, lat: 38.234133, postalCode: "40618" },
    { lng: -84.471505, lat: 38.028269, postalCode: "40583" },
    { lng: -82.75937, lat: 37.568873, postalCode: "41649" },
    { lng: -82.73975, lat: 38.076051, postalCode: "41201" },
    { lng: -82.25703, lat: 37.550374, postalCode: "41546" },
    { lng: -84.48753, lat: 37.455879, postalCode: "40419" },
    { lng: -85.75137, lat: 38.252854, postalCode: "40202" },
    { lng: -83.33415, lat: 36.772301, postalCode: "40830" },
    { lng: -82.694757, lat: 38.370285, postalCode: "41105" },
    { lng: -83.2404, lat: 37.383285, postalCode: "41367" },
    { lng: -83.41725, lat: 38.206706, postalCode: "40329" },
    { lng: -83.16117, lat: 36.766134, postalCode: "40820" },
    { lng: -83.074592, lat: 37.690636, postalCode: "41410" },
    { lng: -84.5039, lat: 39.067811, postalCode: "41014" },
    { lng: -83.26432, lat: 37.515013, postalCode: "41307" },
    { lng: -83.55909, lat: 38.546281, postalCode: "41189" },
    { lng: -84.89756, lat: 38.775686, postalCode: "41095" },
    { lng: -84.80855, lat: 37.583748, postalCode: "40440" },
    { lng: -83.76674, lat: 37.164346, postalCode: "40962" },
    { lng: -84.42183, lat: 36.631706, postalCode: "42649" },
    { lng: -84.62099, lat: 37.351221, postalCode: "40489" },
    { lng: -84.33233, lat: 37.36766, postalCode: "40456" },
    { lng: -84.02358, lat: 38.320944, postalCode: "40311" },
    { lng: -84.6735, lat: 37.520836, postalCode: "40484" },
    { lng: -83.737014, lat: 37.147687, postalCode: "40931" },
    { lng: -84.44204, lat: 39.019185, postalCode: "41076" },
    { lng: -82.64374, lat: 37.428801, postalCode: "41660" },
    { lng: -83.704908, lat: 37.398617, postalCode: "41351" },
    { lng: -85.59037, lat: 38.279059, postalCode: "40242" },
    { lng: -83.183446, lat: 36.842977, postalCode: "40964" },
    { lng: -84.427729, lat: 37.09325, postalCode: "42502" },
    { lng: -83.35077, lat: 37.635723, postalCode: "41385" },
    { lng: -82.84781, lat: 38.028476, postalCode: "41124" },
    { lng: -83.22904, lat: 36.781517, postalCode: "40815" },
    { lng: -83.739, lat: 36.855044, postalCode: "40930" },
    { lng: -84.368926, lat: 38.963761, postalCode: "41072" },
    { lng: -83.273609, lat: 37.22539, postalCode: "41713" },
    { lng: -82.75492, lat: 37.465744, postalCode: "41651" },
    { lng: -83.66608, lat: 37.176075, postalCode: "40941" },
    { lng: -83.4645, lat: 36.827233, postalCode: "40819" },
    { lng: -82.9686, lat: 38.676695, postalCode: "41175" },
    { lng: -84.745488, lat: 38.021292, postalCode: "40386" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40255" },
    { lng: -83.48892, lat: 37.043142, postalCode: "40827" },
    { lng: -83.264497, lat: 36.797447, postalCode: "40829" },
    { lng: -85.28257, lat: 37.923734, postalCode: "40008" },
    { lng: -85.676819, lat: 38.188962, postalCode: "40289" },
    { lng: -86.15642, lat: 37.581023, postalCode: "42712" },
    { lng: -82.72417, lat: 37.623492, postalCode: "41621" },
    { lng: -83.76639, lat: 38.016706, postalCode: "40334" },
    { lng: -72.62452, lat: 42.472734, postalCode: "1373" },
    { lng: -70.643092, lat: 41.379836, postalCode: "2573" },
    { lng: -71.51244, lat: 42.486259, postalCode: "1719" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1808" },
    { lng: -71.31024, lat: 41.967949, postalCode: "2763" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1805" },
    { lng: -71.10993, lat: 42.52585, postalCode: "1867" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2403" },
    { lng: -72.03498, lat: 42.563367, postalCode: "1438" },
    { lng: -72.6242, lat: 42.201891, postalCode: "1040" },
    { lng: -71.64376, lat: 42.140741, postalCode: "1534" },
    { lng: -71.01878, lat: 42.02195, postalCode: "2379" },
    { lng: -71.61408, lat: 42.269845, postalCode: "1581" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2059" },
    { lng: -71.27649, lat: 42.313329, postalCode: "2481" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2044" },
    { lng: -72.0467, lat: 42.675441, postalCode: "1475" },
    { lng: -70.86205, lat: 42.059434, postalCode: "2341" },
    { lng: -71.060364, lat: 42.361485, postalCode: "2203" },
    { lng: -72.340486, lat: 42.206092, postalCode: "1009" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1903" },
    { lng: -72.84356, lat: 42.390836, postalCode: "1012" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2207" },
    { lng: -71.68482, lat: 42.418836, postalCode: "1510" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2447" },
    { lng: -70.7513, lat: 42.205038, postalCode: "2066" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2193" },
    { lng: -72.41724, lat: 42.547274, postalCode: "1379" },
    { lng: -72.36518, lat: 42.180563, postalCode: "1080" },
    { lng: -71.067062, lat: 41.756214, postalCode: "2761" },
    { lng: -71.37478, lat: 42.457201, postalCode: "1742" },
    { lng: -70.37048, lat: 41.70631, postalCode: "2668" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2304" },
    { lng: -71.42315, lat: 42.151526, postalCode: "2053" },
    { lng: -71.90651, lat: 42.194793, postalCode: "1542" },
    { lng: -72.633901, lat: 42.406697, postalCode: "1066" },
    { lng: -70.61727, lat: 42.659936, postalCode: "1966" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1961" },
    { lng: -71.22071, lat: 42.601684, postalCode: "1876" },
    { lng: -72.09787, lat: 42.200374, postalCode: "1506" },
    { lng: -71.12593, lat: 42.252198, postalCode: "2136" },
    { lng: -71.01356, lat: 42.602278, postalCode: "1949" },
    { lng: -71.02487, lat: 42.750691, postalCode: "1834" },
    { lng: -71.49085, lat: 42.540052, postalCode: "1460" },
    { lng: -72.81551, lat: 42.45792, postalCode: "1032" },
    { lng: -71.16553, lat: 42.244609, postalCode: "2026" },
    { lng: -71.03459, lat: 42.079399, postalCode: "2401" },
    { lng: -71.33238, lat: 42.630915, postalCode: "1851" },
    { lng: -73.298131, lat: 42.153001, postalCode: "1231" },
    { lng: -70.91019, lat: 41.697015, postalCode: "2743" },
    { lng: -72.78271, lat: 42.415236, postalCode: "1096" },
    { lng: -71.99227, lat: 42.247212, postalCode: "1562" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2241" },
    { lng: -71.15635, lat: 42.419496, postalCode: "2474" },
    { lng: -72.518104, lat: 42.556558, postalCode: "1347" },
    { lng: -73.31423, lat: 42.283216, postalCode: "1262" },
    { lng: -71.25761, lat: 42.331646, postalCode: "2462" },
    { lng: -70.66376, lat: 41.378807, postalCode: "2575" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2104" },
    { lng: -72.62029, lat: 42.07061, postalCode: "1001" },
    { lng: -70.61999, lat: 41.894676, postalCode: "2360" },
    { lng: -71.2911, lat: 42.573592, postalCode: "1862" },
    { lng: -71.64555, lat: 42.559289, postalCode: "1464" },
    { lng: -70.44317, lat: 41.625037, postalCode: "2635" },
    { lng: -70.29568, lat: 41.700327, postalCode: "2630" },
    { lng: -71.04463, lat: 42.347974, postalCode: "2210" },
    { lng: -71.68771, lat: 42.201674, postalCode: "1519" },
    { lng: -70.504349, lat: 41.621779, postalCode: "2549" },
    { lng: -73.343545, lat: 42.279292, postalCode: "1229" },
    { lng: -71.11023, lat: 42.781357, postalCode: "1832" },
    { lng: -70.65956, lat: 41.746424, postalCode: "2558" },
    { lng: -70.847486, lat: 42.018525, postalCode: "2350" },
    { lng: -70.69353, lat: 42.142836, postalCode: "2047" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1546" },
    { lng: -71.286055, lat: 42.075995, postalCode: "2085" },
    { lng: -72.59151, lat: 42.101796, postalCode: "1144" },
    { lng: -70.93734, lat: 42.08235, postalCode: "2382" },
    { lng: -70.80452, lat: 41.968987, postalCode: "2367" },
    { lng: -71.12165, lat: 42.399546, postalCode: "2144" },
    { lng: -72.67915, lat: 42.324232, postalCode: "1062" },
    { lng: -71.06446, lat: 41.792192, postalCode: "2702" },
    { lng: -72.58243, lat: 42.234891, postalCode: "1075" },
    { lng: -71.08518, lat: 42.757767, postalCode: "1835" },
    { lng: -71.67946, lat: 42.473131, postalCode: "1523" },
    { lng: -70.734146, lat: 42.151202, postalCode: "2051" },
    { lng: -71.00844, lat: 42.677869, postalCode: "1921" },
    { lng: -70.66351, lat: 41.381585, postalCode: "2595" },
    { lng: -70.16165, lat: 41.712338, postalCode: "2660" },
    { lng: -71.09835, lat: 42.480145, postalCode: "2180" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2060" },
    { lng: -71.34617, lat: 42.0597, postalCode: "2093" },
    { lng: -72.5798, lat: 42.096644, postalCode: "1105" },
    { lng: -70.97215, lat: 41.979501, postalCode: "2324" },
    { lng: -72.21226, lat: 42.474718, postalCode: "1366" },
    { lng: -71.78846, lat: 42.286543, postalCode: "1605" },
    { lng: -71.132397, lat: 42.023528, postalCode: "2334" },
    { lng: -70.07773, lat: 41.746647, postalCode: "2631" },
    { lng: -71.09908, lat: 42.381929, postalCode: "2143" },
    { lng: -72.079455, lat: 42.109748, postalCode: "1508" },
    { lng: -71.76272, lat: 42.196144, postalCode: "1527" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1853" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2117" },
    { lng: -73.45734, lat: 42.103122, postalCode: "1258" },
    { lng: -72.76966, lat: 42.05283, postalCode: "1077" },
    { lng: -71.250479, lat: 42.356719, postalCode: "2454" },
    { lng: -71.11087, lat: 42.800429, postalCode: "1823" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1615" },
    { lng: -70.519584, lat: 41.793263, postalCode: "2562" },
    { lng: -71.0255, lat: 42.378297, postalCode: "2128" },
    { lng: -71.30336, lat: 42.300096, postalCode: "2482" },
    { lng: -71.51086, lat: 42.423882, postalCode: "1775" },
    { lng: -73.36336, lat: 42.374711, postalCode: "1254" },
    { lng: -72.44057, lat: 42.12531, postalCode: "1095" },
    { lng: -70.38693, lat: 41.632935, postalCode: "2655" },
    { lng: -70.52453, lat: 41.380479, postalCode: "2539" },
    { lng: -70.75145, lat: 41.345578, postalCode: "2535" },
    { lng: -72.70206, lat: 42.353105, postalCode: "1053" },
    { lng: -71.08412, lat: 42.362297, postalCode: "2142" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1152" },
    { lng: -70.71059, lat: 41.751554, postalCode: "2571" },
    { lng: -70.91189, lat: 42.129513, postalCode: "2370" },
    { lng: -71.43754, lat: 42.592086, postalCode: "1886" },
    { lng: -71.303488, lat: 42.641779, postalCode: "1856" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1822" },
    { lng: -73.36407, lat: 42.328535, postalCode: "1266" },
    { lng: -72.6321, lat: 42.388831, postalCode: "1088" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2112" },
    { lng: -71.11309, lat: 42.058507, postalCode: "2356" },
    { lng: -73.36591, lat: 42.102694, postalCode: "1257" },
    { lng: -71.24232, lat: 42.061427, postalCode: "2035" },
    { lng: -71.13234, lat: 41.691369, postalCode: "2723" },
    { lng: -71.04905, lat: 41.934586, postalCode: "2767" },
    { lng: -72.41396, lat: 42.607051, postalCode: "1344" },
    { lng: -72.20259, lat: 42.20468, postalCode: "1083" },
    { lng: -72.57338, lat: 42.130343, postalCode: "1104" },
    { lng: -70.97286, lat: 42.790736, postalCode: "1985" },
    { lng: -71.25375, lat: 42.145237, postalCode: "2081" },
    { lng: -71.17436, lat: 41.684152, postalCode: "2724" },
    { lng: -71.42344, lat: 42.318695, postalCode: "1701" },
    { lng: -70.07938, lat: 42.02889, postalCode: "2652" },
    { lng: -71.787, lat: 42.359842, postalCode: "1583" },
    { lng: -73.254, lat: 42.45384, postalCode: "1201" },
    { lng: -72.60832, lat: 42.375155, postalCode: "1038" },
    { lng: -71.42161, lat: 42.283995, postalCode: "1702" },
    { lng: -72.60098, lat: 42.605524, postalCode: "1301" },
    { lng: -72.50323, lat: 42.377651, postalCode: "1002" },
    { lng: -71.52999, lat: 42.028147, postalCode: "1504" },
    { lng: -71.109646, lat: 42.571633, postalCode: "1889" },
    { lng: -70.076776, lat: 41.800531, postalCode: "2663" },
    { lng: -71.169847, lat: 42.320797, postalCode: "2167" },
    { lng: -70.827245, lat: 42.040708, postalCode: "2327" },
    { lng: -70.277212, lat: 41.701438, postalCode: "2637" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2209" },
    { lng: -71.1598, lat: 42.279432, postalCode: "2132" },
    { lng: -70.81223, lat: 42.241908, postalCode: "2025" },
    { lng: -70.9494, lat: 41.59156, postalCode: "2748" },
    { lng: -71.0522, lat: 42.409916, postalCode: "2149" },
    { lng: -70.86323, lat: 42.497146, postalCode: "1945" },
    { lng: -71.27504, lat: 42.496679, postalCode: "1730" },
    { lng: -71.12141, lat: 42.344747, postalCode: "2446" },
    { lng: -72.593626, lat: 42.459814, postalCode: "1393" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2107" },
    { lng: -72.16999, lat: 42.671012, postalCode: "1368" },
    { lng: -70.706774, lat: 41.959094, postalCode: "2363" },
    { lng: -71.84434, lat: 42.338962, postalCode: "1520" },
    { lng: -71.01037, lat: 42.148351, postalCode: "2343" },
    { lng: -71.05636, lat: 42.365028, postalCode: "2113" },
    { lng: -71.31456, lat: 41.928099, postalCode: "2703" },
    { lng: -71.33086, lat: 41.975495, postalCode: "2760" },
    { lng: -70.308662, lat: 41.799312, postalCode: "2541" },
    { lng: -71.13933, lat: 42.324397, postalCode: "2445" },
    { lng: -72.63599, lat: 42.369562, postalCode: "1059" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2185" },
    { lng: -71.09268, lat: 42.34308, postalCode: "2115" },
    { lng: -70.643092, lat: 41.379836, postalCode: "2552" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2153" },
    { lng: -70.43754, lat: 41.733837, postalCode: "2537" },
    { lng: -72.12393, lat: 42.12515, postalCode: "1518" },
    { lng: -71.1343, lat: 42.166776, postalCode: "2021" },
    { lng: -71.053877, lat: 42.350334, postalCode: "2205" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2305" },
    { lng: -72.51831, lat: 42.254563, postalCode: "1033" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1654" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1842" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2204" },
    { lng: -71.686137, lat: 42.443539, postalCode: "1561" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2495" },
    { lng: -72.67248, lat: 42.070774, postalCode: "1030" },
    { lng: -72.999433, lat: 42.069484, postalCode: "1634" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2177" },
    { lng: -72.87939, lat: 42.626858, postalCode: "1339" },
    { lng: -71.54753, lat: 42.350909, postalCode: "1752" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1807" },
    { lng: -71.184202, lat: 42.647191, postalCode: "5501" },
    { lng: -71.80338, lat: 42.578689, postalCode: "1420" },
    { lng: -73.092852, lat: 42.516145, postalCode: "1227" },
    { lng: -71.07276, lat: 42.338724, postalCode: "2118" },
    { lng: -70.8814, lat: 41.634152, postalCode: "2719" },
    { lng: -71.72887, lat: 42.061437, postalCode: "1516" },
    { lng: -72.03533, lat: 42.070827, postalCode: "1550" },
    { lng: -70.974694, lat: 42.454796, postalCode: "1910" },
    { lng: -70.015545, lat: 41.273949, postalCode: "2564" },
    { lng: -71.08904, lat: 41.602517, postalCode: "2790" },
    { lng: -71.93287, lat: 42.658768, postalCode: "1430" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2331" },
    { lng: -71.99654, lat: 42.477515, postalCode: "1452" },
    { lng: -71.06059, lat: 42.428549, postalCode: "2148" },
    { lng: -70.96579, lat: 42.490421, postalCode: "1904" },
    { lng: -71.05365, lat: 42.356532, postalCode: "2110" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1865" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2168" },
    { lng: -71.30504, lat: 42.655791, postalCode: "1850" },
    { lng: -70.01484, lat: 41.922412, postalCode: "2667" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2405" },
    { lng: -71.22046, lat: 42.311997, postalCode: "2464" },
    { lng: -71.07799, lat: 41.825252, postalCode: "2779" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2254" },
    { lng: -71.2398, lat: 42.388938, postalCode: "2154" },
    { lng: -71.72502, lat: 42.286643, postalCode: "1545" },
    { lng: -72.93871, lat: 42.399054, postalCode: "1098" },
    { lng: -71.50468, lat: 42.295544, postalCode: "1745" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2216" },
    { lng: -73.09278, lat: 42.433661, postalCode: "1235" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1441" },
    { lng: -71.13986, lat: 41.716952, postalCode: "2720" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2137" },
    { lng: -71.60971, lat: 42.17382, postalCode: "1568" },
    { lng: -73.228483, lat: 42.3929, postalCode: "1203" },
    { lng: -70.52456, lat: 41.665605, postalCode: "2542" },
    { lng: -70.046019, lat: 41.277794, postalCode: "2584" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1090" },
    { lng: -71.20822, lat: 42.31883, postalCode: "2461" },
    { lng: -72.5712, lat: 42.050395, postalCode: "1106" },
    { lng: -71.067062, lat: 41.756214, postalCode: "2741" },
    { lng: -71.57798, lat: 42.036396, postalCode: "1529" },
    { lng: -71.08234, lat: 42.34713, postalCode: "2199" },
    { lng: -71.24771, lat: 42.393029, postalCode: "2451" },
    { lng: -70.59764, lat: 41.751841, postalCode: "2532" },
    { lng: -71.53662, lat: 42.125536, postalCode: "1747" },
    { lng: -71.378157, lat: 42.273817, postalCode: "1750" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2211" },
    { lng: -71.243057, lat: 42.346146, postalCode: "2166" },
    { lng: -71.17031, lat: 42.558576, postalCode: "1887" },
    { lng: -70.5614, lat: 41.578992, postalCode: "2536" },
    { lng: -71.184202, lat: 42.647191, postalCode: "5544" },
    { lng: -72.73104, lat: 42.673371, postalCode: "1340" },
    { lng: -72.45649, lat: 42.682285, postalCode: "1360" },
    { lng: -70.85913, lat: 42.12867, postalCode: "2339" },
    { lng: -71.06774, lat: 42.361792, postalCode: "2114" },
    { lng: -71.83776, lat: 42.240526, postalCode: "1603" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2040" },
    { lng: -71.17471, lat: 42.391546, postalCode: "2478" },
    { lng: -70.86153, lat: 42.85048, postalCode: "1952" },
    { lng: -70.931588, lat: 42.022225, postalCode: "2337" },
    { lng: -71.13633, lat: 41.811514, postalCode: "2715" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1806" },
    { lng: -72.16137, lat: 42.054676, postalCode: "1521" },
    { lng: -71.23388, lat: 42.278247, postalCode: "2492" },
    { lng: -72.624164, lat: 42.522178, postalCode: "1369" },
    { lng: -71.02834, lat: 42.527895, postalCode: "1940" },
    { lng: -71.07372, lat: 42.349622, postalCode: "2116" },
    { lng: -70.84007, lat: 42.682965, postalCode: "1938" },
    { lng: -70.61968, lat: 41.56105, postalCode: "2540" },
    { lng: -71.22153, lat: 42.392496, postalCode: "2452" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2206" },
    { lng: -70.90899, lat: 42.716708, postalCode: "1969" },
    { lng: -71.87139, lat: 42.238449, postalCode: "1611" },
    { lng: -71.13164, lat: 42.355147, postalCode: "2134" },
    { lng: -71.08443, lat: 42.370519, postalCode: "2141" },
    { lng: -73.11547, lat: 42.476575, postalCode: "1226" },
    { lng: -72.60316, lat: 42.161492, postalCode: "1013" },
    { lng: -73.2327, lat: 42.299263, postalCode: "1238" },
    { lng: -71.37202, lat: 42.231025, postalCode: "1770" },
    { lng: -72.591633, lat: 42.102894, postalCode: "1115" },
    { lng: -71.24735, lat: 42.345833, postalCode: "2466" },
    { lng: -71.0982, lat: 42.583436, postalCode: "1864" },
    { lng: -72.08021, lat: 42.608903, postalCode: "1436" },
    { lng: -70.8757, lat: 42.560995, postalCode: "1915" },
    { lng: -71.4069, lat: 42.089104, postalCode: "2038" },
    { lng: -71.70523, lat: 42.231444, postalCode: "1536" },
    { lng: -70.77925, lat: 42.631753, postalCode: "1929" },
    { lng: -72.12248, lat: 42.317121, postalCode: "1531" },
    { lng: -70.643868, lat: 42.081825, postalCode: "2020" },
    { lng: -72.802, lat: 42.569498, postalCode: "1338" },
    { lng: -70.13348, lat: 41.661115, postalCode: "2639" },
    { lng: -71.15206, lat: 42.484545, postalCode: "1801" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2361" },
    { lng: -70.308662, lat: 41.799312, postalCode: "2634" },
    { lng: -72.18833, lat: 42.33071, postalCode: "1031" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2178" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2195" },
    { lng: -71.03352, lat: 42.396382, postalCode: "2150" },
    { lng: -70.558023, lat: 41.453321, postalCode: "2551" },
    { lng: -70.956346, lat: 41.619557, postalCode: "2742" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1653" },
    { lng: -70.05427, lat: 41.992804, postalCode: "2666" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1133" },
    { lng: -71.52149, lat: 42.149176, postalCode: "1757" },
    { lng: -71.113582, lat: 41.999346, postalCode: "2735" },
    { lng: -73.010448, lat: 42.356088, postalCode: "1243" },
    { lng: -73.228483, lat: 42.3929, postalCode: "1263" },
    { lng: -71.27875, lat: 42.300596, postalCode: "2181" },
    { lng: -72.72754, lat: 42.603447, postalCode: "1370" },
    { lng: -70.801331, lat: 41.916918, postalCode: "2355" },
    { lng: -70.99593, lat: 42.250739, postalCode: "2169" },
    { lng: -71.05811, lat: 42.316852, postalCode: "2125" },
    { lng: -71.45886, lat: 42.257956, postalCode: "1721" },
    { lng: -72.85153, lat: 42.165931, postalCode: "1071" },
    { lng: -71.53418, lat: 42.220447, postalCode: "1748" },
    { lng: -70.93347, lat: 41.659336, postalCode: "2746" },
    { lng: -71.69382, lat: 42.177462, postalCode: "1560" },
    { lng: -72.59112, lat: 42.103294, postalCode: "1103" },
    { lng: -71.21619, lat: 42.452895, postalCode: "2420" },
    { lng: -71.20008, lat: 42.506362, postalCode: "1803" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2208" },
    { lng: -71.64692, lat: 42.321917, postalCode: "1532" },
    { lng: -71.5888, lat: 42.505928, postalCode: "1451" },
    { lng: -69.962034, lat: 41.784308, postalCode: "2643" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1111" },
    { lng: -72.69259, lat: 42.381475, postalCode: "1039" },
    { lng: -72.33345, lat: 42.485899, postalCode: "1355" },
    { lng: -71.879415, lat: 42.265275, postalCode: "1601" },
    { lng: -72.149388, lat: 42.375998, postalCode: "1074" },
    { lng: -72.42208, lat: 42.486027, postalCode: "1072" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2102" },
    { lng: -71.54474, lat: 42.092006, postalCode: "1756" },
    { lng: -71.75121, lat: 42.131028, postalCode: "1590" },
    { lng: -70.95332, lat: 41.765934, postalCode: "2717" },
    { lng: -71.84482, lat: 42.272359, postalCode: "1602" },
    { lng: -71.0098, lat: 42.462796, postalCode: "1906" },
    { lng: -71.04562, lat: 42.33499, postalCode: "2127" },
    { lng: -70.95227, lat: 42.029083, postalCode: "2333" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2456" },
    { lng: -71.06282, lat: 42.367797, postalCode: "2222" },
    { lng: -70.530852, lat: 41.79197, postalCode: "2567" },
    { lng: -70.638189, lat: 41.603946, postalCode: "2574" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2027" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1538" },
    { lng: -71.42989, lat: 42.38532, postalCode: "1776" },
    { lng: -71.12056, lat: 42.367946, postalCode: "2163" },
    { lng: -71.10288, lat: 41.904888, postalCode: "2780" },
    { lng: -71.09651, lat: 42.332099, postalCode: "2120" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2272" },
    { lng: -72.80494, lat: 42.669255, postalCode: "1346" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2055" },
    { lng: -71.07496, lat: 42.253074, postalCode: "2186" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1470" },
    { lng: -71.026964, lat: 42.370567, postalCode: "2101" },
    { lng: -70.23726, lat: 41.656883, postalCode: "2673" },
    { lng: -72.55606, lat: 42.196666, postalCode: "1022" },
    { lng: -70.94424, lat: 42.642471, postalCode: "1983" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2404" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1899" },
    { lng: -70.92809, lat: 42.427096, postalCode: "1908" },
    { lng: -70.88764, lat: 42.225708, postalCode: "2043" },
    { lng: -70.651567, lat: 42.097219, postalCode: "2065" },
    { lng: -71.5929, lat: 42.665686, postalCode: "1463" },
    { lng: -70.14021, lat: 41.662531, postalCode: "2369" },
    { lng: -70.61706, lat: 41.694132, postalCode: "2559" },
    { lng: -72.32646, lat: 42.176401, postalCode: "1069" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2175" },
    { lng: -71.62992, lat: 42.064978, postalCode: "1569" },
    { lng: -70.51211, lat: 41.683462, postalCode: "2644" },
    { lng: -73.24674, lat: 42.0645, postalCode: "1259" },
    { lng: -69.987203, lat: 41.543967, postalCode: "2654" },
    { lng: -71.1854, lat: 41.969185, postalCode: "2766" },
    { lng: -71.80173, lat: 42.261343, postalCode: "1608" },
    { lng: -71.15125, lat: 41.851557, postalCode: "2764" },
    { lng: -70.560032, lat: 41.417376, postalCode: "2557" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2295" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2475" },
    { lng: -70.97411, lat: 42.464879, postalCode: "1905" },
    { lng: -72.5733, lat: 42.35549, postalCode: "1035" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2479" },
    { lng: -71.15349, lat: 42.348418, postalCode: "2135" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1582" },
    { lng: -70.64881, lat: 41.782915, postalCode: "2538" },
    { lng: -72.49879, lat: 42.479839, postalCode: "1054" },
    { lng: -70.86513, lat: 42.616643, postalCode: "1982" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1703" },
    { lng: -71.67225, lat: 42.114195, postalCode: "1588" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1705" },
    { lng: -70.70105, lat: 42.031371, postalCode: "2332" },
    { lng: -70.616, lat: 41.66566, postalCode: "2534" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2070" },
    { lng: -70.93547, lat: 42.854423, postalCode: "1913" },
    { lng: -70.20467, lat: 41.734713, postalCode: "2641" },
    { lng: -71.50292, lat: 42.671356, postalCode: "1827" },
    { lng: -71.21433, lat: 41.741551, postalCode: "2777" },
    { lng: -73.20394, lat: 42.224905, postalCode: "1264" },
    { lng: -73.09335, lat: 42.314449, postalCode: "1223" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2277" },
    { lng: -72.87942, lat: 42.290209, postalCode: "1050" },
    { lng: -71.17482, lat: 41.721652, postalCode: "2725" },
    { lng: -71.07011, lat: 42.78519, postalCode: "1830" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1509" },
    { lng: -71.55791, lat: 42.389813, postalCode: "1749" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2457" },
    { lng: -70.98291, lat: 42.729437, postalCode: "1833" },
    { lng: -70.2895, lat: 41.654741, postalCode: "2601" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1471" },
    { lng: -70.95973, lat: 41.835094, postalCode: "2347" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2212" },
    { lng: -71.96427, lat: 42.380877, postalCode: "1543" },
    { lng: -71.8657, lat: 42.117527, postalCode: "1540" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2344" },
    { lng: -71.99183, lat: 42.575355, postalCode: "1440" },
    { lng: -71.42721, lat: 42.519739, postalCode: "1718" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2471" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1477" },
    { lng: -71.225799, lat: 42.292547, postalCode: "2194" },
    { lng: -72.63599, lat: 42.369562, postalCode: "1003" },
    { lng: -73.32177, lat: 42.057496, postalCode: "1222" },
    { lng: -71.80793, lat: 42.248493, postalCode: "1610" },
    { lng: -71.73026, lat: 42.338392, postalCode: "1505" },
    { lng: -73.35249, lat: 42.189715, postalCode: "1230" },
    { lng: -72.91813, lat: 42.465041, postalCode: "1026" },
    { lng: -71.36178, lat: 42.166398, postalCode: "2054" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2192" },
    { lng: -72.56302, lat: 42.449978, postalCode: "1375" },
    { lng: -72.317, lat: 42.089269, postalCode: "1057" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2164" },
    { lng: -72.624164, lat: 42.522178, postalCode: "1354" },
    { lng: -73.116127, lat: 42.443726, postalCode: "1270" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2103" },
    { lng: -71.191248, lat: 42.316097, postalCode: "2159" },
    { lng: -70.07419, lat: 41.669219, postalCode: "2646" },
    { lng: -73.313274, lat: 42.286586, postalCode: "1260" },
    { lng: -70.999339, lat: 42.0863, postalCode: "2402" },
    { lng: -71.72051, lat: 42.582839, postalCode: "1462" },
    { lng: -70.80426, lat: 42.065554, postalCode: "2359" },
    { lng: -72.05311, lat: 42.212777, postalCode: "1515" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2217" },
    { lng: -70.8985, lat: 42.516845, postalCode: "1970" },
    { lng: -71.2319, lat: 42.325247, postalCode: "2468" },
    { lng: -71.62412, lat: 42.488666, postalCode: "1467" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1014" },
    { lng: -70.688251, lat: 42.136086, postalCode: "2407" },
    { lng: -70.92648, lat: 42.76087, postalCode: "1922" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2293" },
    { lng: -71.07156, lat: 42.28713, postalCode: "2124" },
    { lng: -73.24599, lat: 42.336557, postalCode: "1242" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2147" },
    { lng: -71.185494, lat: 42.173933, postalCode: "2157" },
    { lng: -70.946743, lat: 42.461246, postalCode: "1901" },
    { lng: -71.15425, lat: 41.685452, postalCode: "2721" },
    { lng: -69.96958, lat: 41.702265, postalCode: "2650" },
    { lng: -71.55722, lat: 42.609887, postalCode: "1450" },
    { lng: -71.585753, lat: 42.286575, postalCode: "1427" },
    { lng: -72.89043, lat: 42.379807, postalCode: "1084" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1936" },
    { lng: -71.14894, lat: 41.754828, postalCode: "2726" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1937" },
    { lng: -72.48857, lat: 42.095344, postalCode: "1128" },
    { lng: -71.86108, lat: 42.047728, postalCode: "1570" },
    { lng: -71.9173, lat: 42.049864, postalCode: "1571" },
    { lng: -72.54438, lat: 42.609425, postalCode: "1376" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2160" },
    { lng: -71.23563, lat: 42.370446, postalCode: "2453" },
    { lng: -71.88989, lat: 42.170846, postalCode: "1537" },
    { lng: -71.14276, lat: 42.451945, postalCode: "1890" },
    { lng: -70.30435, lat: 41.63275, postalCode: "2647" },
    { lng: -71.77459, lat: 42.255393, postalCode: "1604" },
    { lng: -69.97312, lat: 41.777647, postalCode: "2653" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1021" },
    { lng: -70.94322, lat: 42.569756, postalCode: "1923" },
    { lng: -73.11842, lat: 42.619727, postalCode: "1220" },
    { lng: -72.57265, lat: 42.175992, postalCode: "1020" },
    { lng: -71.87418, lat: 42.369658, postalCode: "1522" },
    { lng: -72.50531, lat: 42.153926, postalCode: "1151" },
    { lng: -71.00222, lat: 42.08715, postalCode: "2302" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2284" },
    { lng: -71.316, lat: 42.677772, postalCode: "1826" },
    { lng: -73.1653, lat: 42.551731, postalCode: "1225" },
    { lng: -73.228483, lat: 42.3929, postalCode: "1224" },
    { lng: -72.81116, lat: 42.521131, postalCode: "1330" },
    { lng: -71.25054, lat: 42.54805, postalCode: "1821" },
    { lng: -70.95872, lat: 42.212866, postalCode: "2188" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1866" },
    { lng: -71.81348, lat: 42.271743, postalCode: "1609" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1139" },
    { lng: -71.09225, lat: 42.390846, postalCode: "2145" },
    { lng: -72.90962, lat: 42.694883, postalCode: "1367" },
    { lng: -72.22517, lat: 42.058024, postalCode: "1081" },
    { lng: -71.17935, lat: 42.73004, postalCode: "1844" },
    { lng: -71.45594, lat: 42.430781, postalCode: "1754" },
    { lng: -71.2191, lat: 42.019982, postalCode: "2048" },
    { lng: -72.6061, lat: 42.120193, postalCode: "1107" },
    { lng: -72.329574, lat: 42.192892, postalCode: "1079" },
    { lng: -70.18947, lat: 42.053355, postalCode: "2657" },
    { lng: -70.05679, lat: 41.707332, postalCode: "2645" },
    { lng: -71.32855, lat: 42.111836, postalCode: "2056" },
    { lng: -70.94434, lat: 42.242749, postalCode: "2191" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1614" },
    { lng: -71.28831, lat: 42.358663, postalCode: "2493" },
    { lng: -70.89406, lat: 41.889582, postalCode: "2346" },
    { lng: -70.93752, lat: 41.634754, postalCode: "2740" },
    { lng: -70.99968, lat: 42.209555, postalCode: "2184" },
    { lng: -72.10443, lat: 42.412094, postalCode: "1005" },
    { lng: -71.23154, lat: 42.29813, postalCode: "2494" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2266" },
    { lng: -71.01827, lat: 41.876249, postalCode: "2718" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1525" },
    { lng: -72.52522, lat: 42.534976, postalCode: "1351" },
    { lng: -72.20836, lat: 42.579931, postalCode: "1331" },
    { lng: -71.00525, lat: 42.416247, postalCode: "2151" },
    { lng: -71.16078, lat: 42.691091, postalCode: "1843" },
    { lng: -71.39322, lat: 42.634199, postalCode: "1863" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1813" },
    { lng: -70.90588, lat: 42.474409, postalCode: "1907" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2173" },
    { lng: -70.7656, lat: 41.873697, postalCode: "2330" },
    { lng: -70.4168, lat: 41.667689, postalCode: "2648" },
    { lng: -71.79531, lat: 42.311342, postalCode: "1606" },
    { lng: -73.36236, lat: 42.266874, postalCode: "1236" },
    { lng: -70.873647, lat: 42.642845, postalCode: "1947" },
    { lng: -71.30476, lat: 42.185571, postalCode: "2052" },
    { lng: -71.08455, lat: 42.324511, postalCode: "2119" },
    { lng: -71.21362, lat: 42.153912, postalCode: "2032" },
    { lng: -72.55523, lat: 42.084094, postalCode: "1108" },
    { lng: -72.604983, lat: 42.119943, postalCode: "1199" },
    { lng: -71.91929, lat: 42.307591, postalCode: "1612" },
    { lng: -72.6091, lat: 42.542322, postalCode: "1342" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2348" },
    { lng: -70.308662, lat: 41.799312, postalCode: "2636" },
    { lng: -71.10627, lat: 42.125432, postalCode: "2072" },
    { lng: -72.140846, lat: 42.358201, postalCode: "1094" },
    { lng: -71.801971, lat: 42.293316, postalCode: "1613" },
    { lng: -71.29849, lat: 42.634112, postalCode: "1852" },
    { lng: -70.02495, lat: 41.678628, postalCode: "2659" },
    { lng: -72.986231, lat: 42.642666, postalCode: "1343" },
    { lng: -71.74578, lat: 42.669408, postalCode: "1474" },
    { lng: -70.972786, lat: 41.98725, postalCode: "2325" },
    { lng: -72.64066, lat: 42.112793, postalCode: "1089" },
    { lng: -71.200894, lat: 41.998799, postalCode: "2031" },
    { lng: -70.69172, lat: 42.109763, postalCode: "2050" },
    { lng: -71.06408, lat: 42.357903, postalCode: "2108" },
    { lng: -71.13052, lat: 42.284678, postalCode: "2131" },
    { lng: -71.35084, lat: 42.5286, postalCode: "1741" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2179" },
    { lng: -71.238175, lat: 42.540779, postalCode: "1820" },
    { lng: -72.70403, lat: 42.508313, postalCode: "1341" },
    { lng: -70.94787, lat: 42.119534, postalCode: "2351" },
    { lng: -71.17923, lat: 42.113749, postalCode: "2067" },
    { lng: -71.069344, lat: 41.522877, postalCode: "2781" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1526" },
    { lng: -70.97803, lat: 42.373047, postalCode: "2152" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1517" },
    { lng: -70.99076, lat: 41.637916, postalCode: "2747" },
    { lng: -71.12837, lat: 42.393327, postalCode: "2140" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1116" },
    { lng: -71.10215, lat: 42.346997, postalCode: "2215" },
    { lng: -72.640234, lat: 42.317939, postalCode: "1063" },
    { lng: -72.55031, lat: 42.116277, postalCode: "1109" },
    { lng: -71.07132, lat: 42.501345, postalCode: "1880" },
    { lng: -72.845912, lat: 42.161743, postalCode: "1097" },
    { lng: -71.05417, lat: 42.361477, postalCode: "2109" },
    { lng: -72.32729, lat: 42.667152, postalCode: "1378" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2133" },
    { lng: -71.77333, lat: 42.436875, postalCode: "1564" },
    { lng: -71.06495, lat: 42.378619, postalCode: "2129" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1586" },
    { lng: -71.23553, lat: 42.439295, postalCode: "2421" },
    { lng: -72.55569, lat: 42.678023, postalCode: "1337" },
    { lng: -70.86363, lat: 42.809891, postalCode: "1950" },
    { lng: -71.32392, lat: 42.011848, postalCode: "2762" },
    { lng: -71.20907, lat: 42.352996, postalCode: "2460" },
    { lng: -72.91661, lat: 42.515853, postalCode: "1070" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2283" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2303" },
    { lng: -72.94828, lat: 42.174313, postalCode: "1008" },
    { lng: -72.94464, lat: 42.086224, postalCode: "1034" },
    { lng: -71.05454, lat: 42.296298, postalCode: "2122" },
    { lng: -71.193, lat: 42.321197, postalCode: "2459" },
    { lng: -73.24364, lat: 42.557346, postalCode: "1237" },
    { lng: -71.12803, lat: 42.379146, postalCode: "2138" },
    { lng: -71.4422, lat: 42.477142, postalCode: "1720" },
    { lng: -71.53369, lat: 42.297643, postalCode: "1772" },
    { lng: -71.17761, lat: 42.416745, postalCode: "2476" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2239" },
    { lng: -70.87663, lat: 42.286347, postalCode: "2045" },
    { lng: -71.33348, lat: 42.649758, postalCode: "1854" },
    { lng: -70.86866, lat: 42.780141, postalCode: "1951" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1472" },
    { lng: -73.04204, lat: 42.175896, postalCode: "1029" },
    { lng: -70.76462, lat: 42.580033, postalCode: "1944" },
    { lng: -71.30316, lat: 42.425537, postalCode: "1773" },
    { lng: -70.85395, lat: 41.999851, postalCode: "2338" },
    { lng: -73.228483, lat: 42.3929, postalCode: "1202" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2187" },
    { lng: -69.98921, lat: 41.844087, postalCode: "2642" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1655" },
    { lng: -70.581029, lat: 41.888198, postalCode: "2345" },
    { lng: -71.085137, lat: 41.519104, postalCode: "2791" },
    { lng: -71.08923, lat: 42.180048, postalCode: "2269" },
    { lng: -71.96961, lat: 42.135142, postalCode: "1507" },
    { lng: -70.93264, lat: 41.692806, postalCode: "2745" },
    { lng: -72.5102, lat: 42.066795, postalCode: "1028" },
    { lng: -72.72777, lat: 42.223885, postalCode: "1073" },
    { lng: -72.23904, lat: 42.210777, postalCode: "1092" },
    { lng: -70.91453, lat: 41.610754, postalCode: "2744" },
    { lng: -72.63561, lat: 42.324539, postalCode: "1060" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1784" },
    { lng: -72.75029, lat: 42.133642, postalCode: "1085" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2105" },
    { lng: -70.66088, lat: 41.854063, postalCode: "2366" },
    { lng: -72.16734, lat: 42.242965, postalCode: "1585" },
    { lng: -71.067062, lat: 41.756214, postalCode: "2722" },
    { lng: -70.63036, lat: 41.6403, postalCode: "2556" },
    { lng: -71.35368, lat: 42.290763, postalCode: "1760" },
    { lng: -71.067062, lat: 41.756214, postalCode: "2714" },
    { lng: -71.20166, lat: 42.185974, postalCode: "2062" },
    { lng: -70.19797, lat: 41.670981, postalCode: "2664" },
    { lng: -71.16181, lat: 42.706941, postalCode: "1840" },
    { lng: -70.80087, lat: 41.654776, postalCode: "2739" },
    { lng: -72.36314, lat: 42.594455, postalCode: "1380" },
    { lng: -70.825743, lat: 42.558113, postalCode: "1965" },
    { lng: -71.896868, lat: 42.364807, postalCode: "1580" },
    { lng: -71.91908, lat: 42.238942, postalCode: "1524" },
    { lng: -71.184202, lat: 42.647191, postalCode: "1812" },
    { lng: -71.32343, lat: 41.84345, postalCode: "2771" },
    { lng: -71.02316, lat: 42.284498, postalCode: "2171" },
    { lng: -72.798742, lat: 42.636459, postalCode: "1374" },
    { lng: -72.847964, lat: 42.173428, postalCode: "1086" },
    { lng: -71.01834, lat: 42.267049, postalCode: "2170" },
    { lng: -70.17089, lat: 41.659749, postalCode: "2670" },
    { lng: -70.93111, lat: 42.2112, postalCode: "2189" },
    { lng: -70.73827, lat: 41.993102, postalCode: "2364" },
    { lng: -72.41735, lat: 42.064703, postalCode: "1036" },
    { lng: -71.067062, lat: 41.756214, postalCode: "2783" },
    { lng: -71.11171, lat: 42.309998, postalCode: "2130" },
    { lng: -70.19183, lat: 41.736684, postalCode: "2638" },
    { lng: -73.446234, lat: 42.198648, postalCode: "1252" },
    { lng: -72.68677, lat: 42.27279, postalCode: "1027" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2362" },
    { lng: -70.561051, lat: 41.931602, postalCode: "2381" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2297" },
    { lng: -72.5291, lat: 42.093144, postalCode: "1118" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1138" },
    { lng: -71.61266, lat: 42.437086, postalCode: "1740" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1971" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1704" },
    { lng: -71.067062, lat: 41.756214, postalCode: "2712" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1888" },
    { lng: -73.10145, lat: 42.696064, postalCode: "1247" },
    { lng: -70.8954, lat: 41.429673, postalCode: "2713" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2196" },
    { lng: -71.59136, lat: 42.549821, postalCode: "1432" },
    { lng: -71.79501, lat: 42.229543, postalCode: "1607" },
    { lng: -73.01958, lat: 42.271442, postalCode: "1011" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2156" },
    { lng: -71.087091, lat: 42.064499, postalCode: "2357" },
    { lng: -73.20312, lat: 42.139104, postalCode: "1244" },
    { lng: -70.0396, lat: 41.675799, postalCode: "2661" },
    { lng: -72.411, lat: 42.278424, postalCode: "1007" },
    { lng: -70.31962, lat: 41.636077, postalCode: "2672" },
    { lng: -71.75358, lat: 42.526523, postalCode: "1453" },
    { lng: -72.50525, lat: 42.5737, postalCode: "1349" },
    { lng: -70.62642, lat: 41.805219, postalCode: "2358" },
    { lng: -71.073449, lat: 42.354318, postalCode: "2106" },
    { lng: -70.87618, lat: 42.600518, postalCode: "1984" },
    { lng: -70.8205, lat: 42.15243, postalCode: "2061" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2165" },
    { lng: -72.19524, lat: 42.374202, postalCode: "1037" },
    { lng: -71.122054, lat: 42.771095, postalCode: "1831" },
    { lng: -71.35643, lat: 42.345583, postalCode: "1778" },
    { lng: -72.513183, lat: 42.384494, postalCode: "1004" },
    { lng: -71.43797, lat: 42.196065, postalCode: "1746" },
    { lng: -71.167083, lat: 42.417695, postalCode: "2174" },
    { lng: -70.48866, lat: 41.735645, postalCode: "2563" },
    { lng: -71.10191, lat: 42.029749, postalCode: "2375" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1931" },
    { lng: -72.47068, lat: 42.174893, postalCode: "1056" },
    { lng: -71.25405, lat: 42.331896, postalCode: "2162" },
    { lng: -69.99494, lat: 41.673635, postalCode: "2669" },
    { lng: -71.9021, lat: 42.544622, postalCode: "1473" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1114" },
    { lng: -69.97746, lat: 41.686534, postalCode: "2633" },
    { lng: -71.18809, lat: 42.354727, postalCode: "2458" },
    { lng: -72.06824, lat: 42.557796, postalCode: "1468" },
    { lng: -70.75783, lat: 41.776042, postalCode: "2576" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2349" },
    { lng: -70.701357, lat: 41.970474, postalCode: "2018" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2238" },
    { lng: -71.04912, lat: 42.173417, postalCode: "2368" },
    { lng: -71.10942, lat: 42.419846, postalCode: "2155" },
    { lng: -71.41694, lat: 42.671589, postalCode: "1879" },
    { lng: -71.22677, lat: 42.351046, postalCode: "2465" },
    { lng: -71.04522, lat: 42.121932, postalCode: "2322" },
    { lng: -71.10431, lat: 42.364347, postalCode: "2139" },
    { lng: -72.26067, lat: 42.270611, postalCode: "1082" },
    { lng: -73.20849, lat: 42.180659, postalCode: "1245" },
    { lng: -69.98176, lat: 41.824264, postalCode: "2651" },
    { lng: -71.3544, lat: 42.595491, postalCode: "1824" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1102" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2477" },
    { lng: -70.83848, lat: 41.760783, postalCode: "2770" },
    { lng: -73.22355, lat: 42.689962, postalCode: "1267" },
    { lng: -71.177998, lat: 42.372596, postalCode: "2172" },
    { lng: -72.50932, lat: 42.128442, postalCode: "1119" },
    { lng: -73.27683, lat: 42.36237, postalCode: "1240" },
    { lng: -71.1513, lat: 42.646942, postalCode: "1810" },
    { lng: -72.07823, lat: 42.267274, postalCode: "1535" },
    { lng: -71.06233, lat: 42.459045, postalCode: "2176" },
    { lng: -71.18196, lat: 42.371296, postalCode: "2472" },
    { lng: -73.10205, lat: 42.198403, postalCode: "1253" },
    { lng: -71.188149, lat: 42.353696, postalCode: "2158" },
    { lng: -71.83843, lat: 42.20361, postalCode: "1501" },
    { lng: -70.879123, lat: 42.635443, postalCode: "1885" },
    { lng: -70.608047, lat: 41.67336, postalCode: "2553" },
    { lng: -70.94597, lat: 42.1711, postalCode: "2190" },
    { lng: -71.067062, lat: 41.756214, postalCode: "2768" },
    { lng: -70.67552, lat: 42.624015, postalCode: "1930" },
    { lng: -71.26115, lat: 41.835067, postalCode: "2769" },
    { lng: -72.624164, lat: 42.522178, postalCode: "1302" },
    { lng: -73.12444, lat: 42.088973, postalCode: "1255" },
    { lng: -71.08127, lat: 42.307448, postalCode: "2121" },
    { lng: -73.01123, lat: 42.592506, postalCode: "1256" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2161" },
    { lng: -70.09089, lat: 41.276758, postalCode: "2554" },
    { lng: -70.48754, lat: 41.613743, postalCode: "2649" },
    { lng: -71.62665, lat: 42.380562, postalCode: "1503" },
    { lng: -70.60872, lat: 41.451263, postalCode: "2568" },
    { lng: -71.16667, lat: 42.71159, postalCode: "1841" },
    { lng: -70.11152, lat: 41.665589, postalCode: "2671" },
    { lng: -72.4896, lat: 42.121031, postalCode: "1129" },
    { lng: -72.652511, lat: 42.442673, postalCode: "1093" },
    { lng: -70.308662, lat: 41.799312, postalCode: "2565" },
    { lng: -71.882, lat: 42.451569, postalCode: "1541" },
    { lng: -71.47953, lat: 42.075122, postalCode: "2019" },
    { lng: -71.11171, lat: 42.683841, postalCode: "1845" },
    { lng: -71.69204, lat: 42.658738, postalCode: "1469" },
    { lng: -70.75086, lat: 41.706599, postalCode: "2738" },
    { lng: -71.17314, lat: 42.321997, postalCode: "2467" },
    { lng: -70.66505, lat: 41.526791, postalCode: "2543" },
    { lng: -70.649075, lat: 42.069642, postalCode: "2041" },
    { lng: -69.984123, lat: 41.756694, postalCode: "2662" },
    { lng: -71.27556, lat: 42.459085, postalCode: "1731" },
    { lng: -71.03441, lat: 42.0794, postalCode: "2301" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2123" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1101" },
    { lng: -71.09426, lat: 42.272098, postalCode: "2126" },
    { lng: -70.96146, lat: 42.534045, postalCode: "1960" },
    { lng: -71.28241, lat: 42.23888, postalCode: "2030" },
    { lng: -71.20735, lat: 42.212949, postalCode: "2090" },
    { lng: -71.459405, lat: 42.446396, postalCode: "2258" },
    { lng: -71.00207, lat: 42.834246, postalCode: "1860" },
    { lng: -71.061814, lat: 42.361094, postalCode: "2202" },
    { lng: -71.06101, lat: 42.349838, postalCode: "2111" },
    { lng: -70.34658, lat: 41.658718, postalCode: "2632" },
    { lng: -71.459405, lat: 42.446396, postalCode: "1815" },
    { lng: -72.08584, lat: 42.104581, postalCode: "1566" },
    { lng: -70.94113, lat: 42.469946, postalCode: "1902" },
    { lng: -70.533664, lat: 41.770254, postalCode: "2561" },
    { lng: -72.604842, lat: 42.170731, postalCode: "1041" },
    { lng: -72.97629, lat: 42.712257, postalCode: "1350" },
    { lng: -72.29263, lat: 42.599614, postalCode: "1364" },
    { lng: -71.27239, lat: 42.101406, postalCode: "2071" },
    { lng: -71.088745, lat: 42.308198, postalCode: "2146" },
    { lng: -70.22395, lat: 41.703205, postalCode: "2675" },
    { lng: -72.20552, lat: 42.126038, postalCode: "1010" },
    { lng: -70.919635, lat: 42.338947, postalCode: "2201" },
    { lng: -70.433279, lat: 41.640528, postalCode: "2625" },
    { lng: -72.04413, lat: 42.349769, postalCode: "1068" },
    { lng: -72.63599, lat: 42.369562, postalCode: "1061" },
    { lng: -71.8137, lat: 42.672005, postalCode: "1431" },
    { lng: -75.7389, lat: 38.018482, postalCode: "21838" },
    { lng: -77.74514, lat: 39.697894, postalCode: "21767" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20913" },
    { lng: -76.90645, lat: 38.886812, postalCode: "20743" },
    { lng: -76.64336, lat: 38.269283, postalCode: "20650" },
    { lng: -78.90123, lat: 39.652187, postalCode: "21528" },
    { lng: -76.22398, lat: 39.638039, postalCode: "21034" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21682" },
    { lng: -76.69854, lat: 39.157958, postalCode: "21077" },
    { lng: -75.85175, lat: 39.201203, postalCode: "21668" },
    { lng: -76.781, lat: 38.792281, postalCode: "20772" },
    { lng: -76.7287, lat: 39.376879, postalCode: "21208" },
    { lng: -77.04876, lat: 39.040759, postalCode: "20902" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20773" },
    { lng: -76.67029, lat: 39.310006, postalCode: "21216" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20897" },
    { lng: -76.15464, lat: 39.679246, postalCode: "21918" },
    { lng: -76.62648, lat: 39.405606, postalCode: "21204" },
    { lng: -75.691572, lat: 38.416292, postalCode: "21831" },
    { lng: -77.4333, lat: 39.334864, postalCode: "21717" },
    { lng: -76.54795, lat: 38.844097, postalCode: "20765" },
    { lng: -77.62466, lat: 39.313666, postalCode: "21716" },
    { lng: -76.57478, lat: 39.301157, postalCode: "21205" },
    { lng: -76.59116, lat: 39.288607, postalCode: "21231" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20797" },
    { lng: -77.29984, lat: 39.342096, postalCode: "21754" },
    { lng: -76.39022, lat: 39.574606, postalCode: "21050" },
    { lng: -76.53637, lat: 38.711264, postalCode: "20714" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20697" },
    { lng: -77.18716, lat: 39.168656, postalCode: "20879" },
    { lng: -77.07487, lat: 39.155449, postalCode: "20832" },
    { lng: -78.21691, lat: 39.692614, postalCode: "21750" },
    { lng: -76.092127, lat: 39.034637, postalCode: "21656" },
    { lng: -76.68038, lat: 39.345456, postalCode: "21215" },
    { lng: -76.33865, lat: 38.135718, postalCode: "20628" },
    { lng: -76.80334, lat: 39.101646, postalCode: "20724" },
    { lng: -76.22256, lat: 38.783264, postalCode: "21663" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20915" },
    { lng: -75.18061, lat: 38.431954, postalCode: "21813" },
    { lng: -79.07263, lat: 39.47965, postalCode: "21523" },
    { lng: -76.24757, lat: 39.559374, postalCode: "21028" },
    { lng: -76.86082, lat: 39.276305, postalCode: "21042" },
    { lng: -75.29106, lat: 38.248485, postalCode: "21841" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21279" },
    { lng: -76.4985, lat: 38.260846, postalCode: "20634" },
    { lng: -76.89913, lat: 38.753264, postalCode: "20735" },
    { lng: -77.221984, lat: 39.140324, postalCode: "20899" },
    { lng: -76.64644, lat: 39.286556, postalCode: "21223" },
    { lng: -76.79155, lat: 38.270124, postalCode: "20618" },
    { lng: -79.31377, lat: 39.629925, postalCode: "21520" },
    { lng: -77.24711, lat: 39.484079, postalCode: "21762" },
    { lng: -77.01807, lat: 38.672216, postalCode: "20607" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20775" },
    { lng: -75.6276, lat: 38.388434, postalCode: "21852" },
    { lng: -76.2702, lat: 38.836142, postalCode: "21624" },
    { lng: -76.594942, lat: 38.974203, postalCode: "21411" },
    { lng: -76.594942, lat: 38.974203, postalCode: "21123" },
    { lng: -76.71706, lat: 39.167708, postalCode: "21076" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20907" },
    { lng: -76.45964, lat: 39.470274, postalCode: "21082" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21684" },
    { lng: -76.6878, lat: 39.284856, postalCode: "21229" },
    { lng: -77.01739, lat: 38.58215, postalCode: "20675" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21092" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20709" },
    { lng: -76.623839, lat: 39.293256, postalCode: "21290" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21270" },
    { lng: -76.53652, lat: 38.670858, postalCode: "20732" },
    { lng: -76.21257, lat: 38.954613, postalCode: "21638" },
    { lng: -75.96915, lat: 38.945143, postalCode: "21657" },
    { lng: -75.86943, lat: 39.402919, postalCode: "21913" },
    { lng: -76.71236, lat: 39.70172, postalCode: "21053" },
    { lng: -77.17264, lat: 39.665171, postalCode: "21787" },
    { lng: -76.56859, lat: 39.659671, postalCode: "21161" },
    { lng: -76.83947, lat: 38.458322, postalCode: "20622" },
    { lng: -76.99735, lat: 39.26574, postalCode: "21737" },
    { lng: -77.40763, lat: 39.128854, postalCode: "20837" },
    { lng: -75.888207, lat: 38.092718, postalCode: "21870" },
    { lng: -75.80107, lat: 38.971566, postalCode: "21639" },
    { lng: -76.9858, lat: 38.463289, postalCode: "20611" },
    { lng: -76.99859, lat: 39.14827, postalCode: "20861" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20857" },
    { lng: -77.47049, lat: 39.677316, postalCode: "21780" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21681" },
    { lng: -76.30437, lat: 38.796477, postalCode: "21676" },
    { lng: -75.88812, lat: 38.302152, postalCode: "21814" },
    { lng: -76.98063, lat: 38.528103, postalCode: "20646" },
    { lng: -78.94877, lat: 39.589842, postalCode: "21542" },
    { lng: -76.47229, lat: 38.988506, postalCode: "21402" },
    { lng: -77.08955, lat: 38.451267, postalCode: "20693" },
    { lng: -77.916196, lat: 39.520696, postalCode: "21747" },
    { lng: -78.55826, lat: 39.579649, postalCode: "21555" },
    { lng: -76.95203, lat: 38.939716, postalCode: "20722" },
    { lng: -76.61917, lat: 38.86313, postalCode: "20776" },
    { lng: -76.66177, lat: 39.209529, postalCode: "21090" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20718" },
    { lng: -76.536276, lat: 38.698833, postalCode: "20831" },
    { lng: -75.62088, lat: 38.322867, postalCode: "21826" },
    { lng: -79.0194, lat: 39.535862, postalCode: "21521" },
    { lng: -77.19479, lat: 38.42029, postalCode: "20662" },
    { lng: -78.690593, lat: 39.580691, postalCode: "21504" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21282" },
    { lng: -76.75435, lat: 38.523408, postalCode: "20637" },
    { lng: -77.742116, lat: 39.693944, postalCode: "21741" },
    { lng: -78.77398, lat: 39.646587, postalCode: "21502" },
    { lng: -76.7176, lat: 39.324406, postalCode: "21207" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20790" },
    { lng: -77.916196, lat: 39.520696, postalCode: "21736" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21264" },
    { lng: -77.41699, lat: 39.22086, postalCode: "20842" },
    { lng: -77.03169, lat: 38.501493, postalCode: "20677" },
    { lng: -75.87793, lat: 38.324259, postalCode: "21865" },
    { lng: -75.41162, lat: 38.193683, postalCode: "21863" },
    { lng: -76.730345, lat: 39.412905, postalCode: "21153" },
    { lng: -76.87823, lat: 39.21536, postalCode: "21044" },
    { lng: -76.69229, lat: 38.564649, postalCode: "20608" },
    { lng: -76.2983, lat: 39.473899, postalCode: "21009" },
    { lng: -76.66904, lat: 39.368974, postalCode: "21209" },
    { lng: -75.88459, lat: 38.954731, postalCode: "21660" },
    { lng: -76.4382, lat: 38.2176, postalCode: "20667" },
    { lng: -78.711, lat: 39.564946, postalCode: "21560" },
    { lng: -76.02337, lat: 37.967803, postalCode: "21866" },
    { lng: -76.62613, lat: 39.273107, postalCode: "21230" },
    { lng: -76.50185, lat: 38.179764, postalCode: "20692" },
    { lng: -75.74074, lat: 38.469332, postalCode: "21837" },
    { lng: -77.66014, lat: 39.342991, postalCode: "21758" },
    { lng: -76.49191, lat: 39.508739, postalCode: "21013" },
    { lng: -75.84597, lat: 37.985297, postalCode: "21817" },
    { lng: -77.07745, lat: 39.027259, postalCode: "20895" },
    { lng: -75.76427, lat: 39.122683, postalCode: "21649" },
    { lng: -76.91478, lat: 38.839663, postalCode: "20746" },
    { lng: -76.56399, lat: 39.351857, postalCode: "21214" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21260" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21281" },
    { lng: -75.79242, lat: 38.111688, postalCode: "21867" },
    { lng: -76.50365, lat: 39.33224, postalCode: "21237" },
    { lng: -76.97063, lat: 38.593202, postalCode: "20695" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21685" },
    { lng: -77.066745, lat: 39.15522, postalCode: "20830" },
    { lng: -75.40992, lat: 38.040265, postalCode: "21864" },
    { lng: -78.690593, lat: 39.580691, postalCode: "21503" },
    { lng: -77.24861, lat: 39.354093, postalCode: "21770" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21297" },
    { lng: -75.958683, lat: 38.740562, postalCode: "21609" },
    { lng: -76.82125, lat: 39.566103, postalCode: "21155" },
    { lng: -76.63786, lat: 39.306306, postalCode: "21217" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20813" },
    { lng: -76.592738, lat: 39.297457, postalCode: "21287" },
    { lng: -76.97294, lat: 38.98941, postalCode: "20783" },
    { lng: -76.61203, lat: 39.229507, postalCode: "21225" },
    { lng: -76.78388, lat: 39.419105, postalCode: "21117" },
    { lng: -76.44898, lat: 39.229258, postalCode: "21219" },
    { lng: -77.46667, lat: 39.301647, postalCode: "21710" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20749" },
    { lng: -76.82335, lat: 39.137307, postalCode: "20763" },
    { lng: -77.22752, lat: 39.284185, postalCode: "20872" },
    { lng: -77.392139, lat: 39.469961, postalCode: "21705" },
    { lng: -76.88334, lat: 38.820546, postalCode: "20762" },
    { lng: -76.90381, lat: 38.598185, postalCode: "20602" },
    { lng: -76.703971, lat: 38.276828, postalCode: "20627" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21275" },
    { lng: -76.36964, lat: 38.112419, postalCode: "20680" },
    { lng: -76.326363, lat: 39.539458, postalCode: "21004" },
    { lng: -76.40817, lat: 39.444457, postalCode: "21087" },
    { lng: -75.9243, lat: 39.239132, postalCode: "21628" },
    { lng: -76.09726, lat: 39.215278, postalCode: "21620" },
    { lng: -76.84802, lat: 39.613293, postalCode: "21074" },
    { lng: -76.76438, lat: 39.338156, postalCode: "21244" },
    { lng: -76.10098, lat: 38.344103, postalCode: "21626" },
    { lng: -77.392139, lat: 39.469961, postalCode: "21792" },
    { lng: -76.68408, lat: 39.040159, postalCode: "21054" },
    { lng: -77.1442, lat: 38.97026, postalCode: "20812" },
    { lng: -77.73025, lat: 39.633946, postalCode: "21740" },
    { lng: -76.97834, lat: 39.069108, postalCode: "20904" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20997" },
    { lng: -76.07009, lat: 38.662533, postalCode: "21673" },
    { lng: -76.42383, lat: 38.268636, postalCode: "20670" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21235" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21265" },
    { lng: -76.0623, lat: 39.366193, postalCode: "21610" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21278" },
    { lng: -76.78767, lat: 39.144807, postalCode: "20794" },
    { lng: -75.93147, lat: 38.158615, postalCode: "21821" },
    { lng: -76.743196, lat: 39.532955, postalCode: "21023" },
    { lng: -76.63452, lat: 39.352496, postalCode: "21210" },
    { lng: -76.0782, lat: 38.777863, postalCode: "21601" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20824" },
    { lng: -75.8273, lat: 38.871895, postalCode: "21629" },
    { lng: -76.93005, lat: 38.941428, postalCode: "20710" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20719" },
    { lng: -76.49776, lat: 38.448869, postalCode: "20685" },
    { lng: -76.0972, lat: 39.671671, postalCode: "21917" },
    { lng: -76.49718, lat: 39.454943, postalCode: "21057" },
    { lng: -77.584162, lat: 39.647296, postalCode: "21720" },
    { lng: -77.19995, lat: 39.035028, postalCode: "20854" },
    { lng: -76.4258, lat: 39.521572, postalCode: "21047" },
    { lng: -77.30606, lat: 39.540623, postalCode: "21798" },
    { lng: -76.06229, lat: 38.277014, postalCode: "21672" },
    { lng: -77.38902, lat: 39.423344, postalCode: "21701" },
    { lng: -76.55335, lat: 38.786949, postalCode: "20751" },
    { lng: -75.39747, lat: 38.377713, postalCode: "21850" },
    { lng: -76.84951, lat: 38.262468, postalCode: "20625" },
    { lng: -75.82314, lat: 39.658943, postalCode: "21920" },
    { lng: -76.91539, lat: 38.30126, postalCode: "20645" },
    { lng: -76.6306, lat: 39.164158, postalCode: "21061" },
    { lng: -75.91459, lat: 38.747216, postalCode: "21655" },
    { lng: -76.713149, lat: 39.258226, postalCode: "21250" },
    { lng: -76.44889, lat: 38.234838, postalCode: "20653" },
    { lng: -79.39015, lat: 39.409861, postalCode: "21550" },
    { lng: -76.80685, lat: 38.892456, postalCode: "20774" },
    { lng: -76.9133, lat: 39.044509, postalCode: "20705" },
    { lng: -76.79916, lat: 39.376372, postalCode: "21133" },
    { lng: -78.99073, lat: 39.580942, postalCode: "21539" },
    { lng: -76.941902, lat: 39.236405, postalCode: "21150" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20703" },
    { lng: -75.86418, lat: 38.641666, postalCode: "21643" },
    { lng: -75.71854, lat: 38.539817, postalCode: "21861" },
    { lng: -77.03086, lat: 39.624705, postalCode: "21158" },
    { lng: -77.05917, lat: 39.183221, postalCode: "20833" },
    { lng: -76.981651, lat: 38.509548, postalCode: "20604" },
    { lng: -77.55169, lat: 39.446452, postalCode: "21769" },
    { lng: -76.44898, lat: 39.47044, postalCode: "21051" },
    { lng: -76.05365, lat: 39.335017, postalCode: "21667" },
    { lng: -77.34668, lat: 39.484843, postalCode: "21793" },
    { lng: -76.46568, lat: 38.339176, postalCode: "20688" },
    { lng: -77.049302, lat: 39.534703, postalCode: "21088" },
    { lng: -76.63361, lat: 39.328957, postalCode: "21211" },
    { lng: -76.49055, lat: 38.127843, postalCode: "20674" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20875" },
    { lng: -76.594942, lat: 38.974203, postalCode: "21098" },
    { lng: -77.66056, lat: 39.521507, postalCode: "21713" },
    { lng: -76.34124, lat: 38.942464, postalCode: "21666" },
    { lng: -75.29575, lat: 38.408973, postalCode: "21872" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21268" },
    { lng: -79.416, lat: 39.662458, postalCode: "21531" },
    { lng: -75.9408, lat: 38.920583, postalCode: "21641" },
    { lng: -76.53284, lat: 38.165432, postalCode: "20690" },
    { lng: -76.58205, lat: 38.952412, postalCode: "21140" },
    { lng: -76.199871, lat: 39.487519, postalCode: "21002" },
    { lng: -76.32474, lat: 38.754785, postalCode: "21665" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20717" },
    { lng: -76.11118, lat: 39.554644, postalCode: "21078" },
    { lng: -76.49803, lat: 38.997511, postalCode: "21401" },
    { lng: -77.0115, lat: 39.020359, postalCode: "20901" },
    { lng: -75.97904, lat: 39.572476, postalCode: "21914" },
    { lng: -75.88499, lat: 39.365119, postalCode: "21930" },
    { lng: -79.05968, lat: 39.475166, postalCode: "21540" },
    { lng: -76.683325, lat: 38.359274, postalCode: "20656" },
    { lng: -77.4539, lat: 39.385165, postalCode: "21703" },
    { lng: -77.10185, lat: 38.999759, postalCode: "20814" },
    { lng: -76.08221, lat: 38.286828, postalCode: "21675" },
    { lng: -79.03506, lat: 39.488146, postalCode: "21562" },
    { lng: -75.78177, lat: 38.57526, postalCode: "21659" },
    { lng: -75.80806, lat: 39.311429, postalCode: "21650" },
    { lng: -76.96372, lat: 39.127028, postalCode: "20868" },
    { lng: -75.56365, lat: 38.446894, postalCode: "21875" },
    { lng: -75.94218, lat: 38.589383, postalCode: "21631" },
    { lng: -75.79598, lat: 38.327156, postalCode: "21856" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20848" },
    { lng: -76.54177, lat: 39.385006, postalCode: "21234" },
    { lng: -76.65938, lat: 39.487938, postalCode: "21031" },
    { lng: -76.44642, lat: 39.206108, postalCode: "21052" },
    { lng: -77.69284, lat: 39.460534, postalCode: "21756" },
    { lng: -76.93799, lat: 39.147257, postalCode: "20759" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21139" },
    { lng: -77.81584, lat: 39.600029, postalCode: "21795" },
    { lng: -76.78612, lat: 38.986699, postalCode: "20720" },
    { lng: -77.19564, lat: 39.178737, postalCode: "20886" },
    { lng: -77.29468, lat: 39.564472, postalCode: "21775" },
    { lng: -76.14083, lat: 39.473451, postalCode: "21005" },
    { lng: -76.13939, lat: 38.963566, postalCode: "21658" },
    { lng: -77.34166, lat: 39.610752, postalCode: "21778" },
    { lng: -76.92056, lat: 38.96146, postalCode: "20737" },
    { lng: -76.86008, lat: 38.634306, postalCode: "20601" },
    { lng: -76.28063, lat: 38.949536, postalCode: "21619" },
    { lng: -76.67, lat: 39.543905, postalCode: "21152" },
    { lng: -75.98842, lat: 38.882856, postalCode: "21625" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21233" },
    { lng: -76.90953, lat: 39.506853, postalCode: "21048" },
    { lng: -75.96164, lat: 39.425542, postalCode: "21919" },
    { lng: -76.30203, lat: 38.458374, postalCode: "21669" },
    { lng: -78.95027, lat: 39.63664, postalCode: "21543" },
    { lng: -77.27564, lat: 39.169859, postalCode: "20874" },
    { lng: -76.8505, lat: 39.685569, postalCode: "21102" },
    { lng: -76.580934, lat: 38.971475, postalCode: "21106" },
    { lng: -76.75829, lat: 38.229113, postalCode: "20626" },
    { lng: -77.29125, lat: 39.262598, postalCode: "20871" },
    { lng: -76.45171, lat: 39.307737, postalCode: "21221" },
    { lng: -76.55604, lat: 38.832265, postalCode: "20778" },
    { lng: -75.56931, lat: 38.359332, postalCode: "21804" },
    { lng: -77.68297, lat: 39.658897, postalCode: "21742" },
    { lng: -76.74126, lat: 39.105015, postalCode: "20755" },
    { lng: -75.07153, lat: 38.385457, postalCode: "21842" },
    { lng: -76.4286, lat: 39.07566, postalCode: "21056" },
    { lng: -76.05395, lat: 39.694974, postalCode: "21911" },
    { lng: -76.52937, lat: 38.489163, postalCode: "20676" },
    { lng: -76.40512, lat: 38.151666, postalCode: "20684" },
    { lng: -78.93778, lat: 39.64612, postalCode: "21532" },
    { lng: -77.658015, lat: 39.386893, postalCode: "21715" },
    { lng: -76.58418, lat: 39.399639, postalCode: "21286" },
    { lng: -75.87294, lat: 39.094126, postalCode: "21644" },
    { lng: -75.888207, lat: 38.092718, postalCode: "21890" },
    { lng: -75.90494, lat: 38.268651, postalCode: "21840" },
    { lng: -76.75084, lat: 38.268558, postalCode: "20609" },
    { lng: -77.37849, lat: 39.363415, postalCode: "21704" },
    { lng: -76.81424, lat: 39.472604, postalCode: "21071" },
    { lng: -76.57076, lat: 38.759196, postalCode: "20779" },
    { lng: -76.58812, lat: 39.36225, postalCode: "21239" },
    { lng: -76.8006, lat: 39.254356, postalCode: "21043" },
    { lng: -75.21195, lat: 38.39893, postalCode: "21862" },
    { lng: -77.637152, lat: 39.642537, postalCode: "21721" },
    { lng: -75.45574, lat: 38.363057, postalCode: "21849" },
    { lng: -77.10341, lat: 39.002375, postalCode: "20892" },
    { lng: -77.505098, lat: 39.4203, postalCode: "21714" },
    { lng: -77.49104, lat: 39.710433, postalCode: "21719" },
    { lng: -77.720642, lat: 39.563787, postalCode: "21746" },
    { lng: -76.85319, lat: 39.175004, postalCode: "21046" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21670" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21274" },
    { lng: -78.843432, lat: 39.594043, postalCode: "21505" },
    { lng: -77.916196, lat: 39.520696, postalCode: "21749" },
    { lng: -76.96369, lat: 38.941594, postalCode: "20712" },
    { lng: -77.15867, lat: 39.381295, postalCode: "21771" },
    { lng: -77.08301, lat: 38.639117, postalCode: "20616" },
    { lng: -76.54839, lat: 38.349517, postalCode: "20636" },
    { lng: -76.24057, lat: 38.485297, postalCode: "21648" },
    { lng: -76.58432, lat: 38.535116, postalCode: "20678" },
    { lng: -76.05309, lat: 39.049697, postalCode: "21617" },
    { lng: -77.148283, lat: 38.503876, postalCode: "20643" },
    { lng: -76.965, lat: 39.39667, postalCode: "21784" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21094" },
    { lng: -76.84103, lat: 39.0632, postalCode: "20708" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20791" },
    { lng: -76.7923, lat: 39.127708, postalCode: "20701" },
    { lng: -76.96182, lat: 38.421787, postalCode: "20632" },
    { lng: -76.34957, lat: 39.536057, postalCode: "21014" },
    { lng: -75.6276, lat: 38.388434, postalCode: "21802" },
    { lng: -75.789354, lat: 38.107572, postalCode: "21868" },
    { lng: -76.41236, lat: 39.387307, postalCode: "21162" },
    { lng: -78.92329, lat: 39.509856, postalCode: "21557" },
    { lng: -76.54085, lat: 39.341107, postalCode: "21206" },
    { lng: -76.9356, lat: 38.946746, postalCode: "20781" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21280" },
    { lng: -76.48709, lat: 39.389457, postalCode: "21236" },
    { lng: -76.49892, lat: 39.049377, postalCode: "21012" },
    { lng: -76.54814, lat: 38.416782, postalCode: "20615" },
    { lng: -76.95723, lat: 38.631542, postalCode: "20603" },
    { lng: -78.3986, lat: 39.65643, postalCode: "21766" },
    { lng: -76.884559, lat: 38.343591, postalCode: "20661" },
    { lng: -77.15928, lat: 38.97511, postalCode: "20818" },
    { lng: -76.31864, lat: 39.707377, postalCode: "21160" },
    { lng: -76.9367, lat: 39.198816, postalCode: "21029" },
    { lng: -75.39378, lat: 38.097861, postalCode: "21829" },
    { lng: -77.2686, lat: 39.584616, postalCode: "21757" },
    { lng: -77.03404, lat: 38.999992, postalCode: "20910" },
    { lng: -76.98199, lat: 39.014009, postalCode: "20903" },
    { lng: -76.51863, lat: 39.125776, postalCode: "21122" },
    { lng: -77.18568, lat: 39.13975, postalCode: "20877" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21687" },
    { lng: -76.81544, lat: 38.984107, postalCode: "20769" },
    { lng: -77.1718, lat: 39.093408, postalCode: "20850" },
    { lng: -76.81417, lat: 39.470603, postalCode: "21136" },
    { lng: -76.35578, lat: 39.432273, postalCode: "21085" },
    { lng: -77.0944, lat: 39.087409, postalCode: "20853" },
    { lng: -76.58283, lat: 39.311474, postalCode: "21213" },
    { lng: -77.1232, lat: 39.076608, postalCode: "20851" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20894" },
    { lng: -76.51759, lat: 38.226406, postalCode: "20620" },
    { lng: -76.86571, lat: 39.098475, postalCode: "20707" },
    { lng: -77.24329, lat: 39.191769, postalCode: "20876" },
    { lng: -76.61142, lat: 39.363456, postalCode: "21212" },
    { lng: -76.48358, lat: 38.946462, postalCode: "21403" },
    { lng: -76.59444, lat: 39.162225, postalCode: "21060" },
    { lng: -76.83075, lat: 38.748872, postalCode: "20623" },
    { lng: -76.8579, lat: 39.134062, postalCode: "20723" },
    { lng: -75.999972, lat: 39.541512, postalCode: "21916" },
    { lng: -77.916196, lat: 39.520696, postalCode: "21748" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21241" },
    { lng: -75.9666, lat: 39.328784, postalCode: "21645" },
    { lng: -76.16806, lat: 38.393669, postalCode: "21622" },
    { lng: -76.79404, lat: 38.917506, postalCode: "20721" },
    { lng: -76.88005, lat: 39.00391, postalCode: "20770" },
    { lng: -76.60693, lat: 39.296507, postalCode: "21202" },
    { lng: -77.37781, lat: 39.221975, postalCode: "20838" },
    { lng: -77.049302, lat: 39.534703, postalCode: "21080" },
    { lng: -76.73974, lat: 38.250996, postalCode: "20606" },
    { lng: -77.09263, lat: 39.035276, postalCode: "20896" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20738" },
    { lng: -76.594942, lat: 38.974203, postalCode: "21062" },
    { lng: -76.3444, lat: 38.064947, postalCode: "20687" },
    { lng: -76.24355, lat: 39.476018, postalCode: "21017" },
    { lng: -76.29105, lat: 38.80503, postalCode: "21647" },
    { lng: -76.62114, lat: 39.099957, postalCode: "21108" },
    { lng: -76.385083, lat: 39.509276, postalCode: "21018" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20911" },
    { lng: -77.049302, lat: 39.534703, postalCode: "21764" },
    { lng: -77.02052, lat: 39.280106, postalCode: "21738" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20849" },
    { lng: -76.05077, lat: 39.571003, postalCode: "21903" },
    { lng: -77.66169, lat: 39.431058, postalCode: "21779" },
    { lng: -75.84317, lat: 38.493753, postalCode: "21869" },
    { lng: -76.06542, lat: 39.553014, postalCode: "21902" },
    { lng: -77.41092, lat: 39.604303, postalCode: "21788" },
    { lng: -77.17592, lat: 39.139535, postalCode: "20880" },
    { lng: -76.20146, lat: 38.288409, postalCode: "21634" },
    { lng: -76.59123, lat: 39.575605, postalCode: "21111" },
    { lng: -76.27279, lat: 38.747964, postalCode: "21612" },
    { lng: -76.62118, lat: 39.295306, postalCode: "21201" },
    { lng: -76.41533, lat: 39.337798, postalCode: "21220" },
    { lng: -77.18176, lat: 39.5549, postalCode: "21791" },
    { lng: -76.17804, lat: 38.750959, postalCode: "21653" },
    { lng: -76.93974, lat: 38.822163, postalCode: "20748" },
    { lng: -75.54811, lat: 38.069322, postalCode: "21851" },
    { lng: -76.60434, lat: 38.601648, postalCode: "20639" },
    { lng: -76.54183, lat: 39.02801, postalCode: "21405" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20750" },
    { lng: -76.594942, lat: 38.974203, postalCode: "21412" },
    { lng: -76.72826, lat: 38.427328, postalCode: "20659" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20731" },
    { lng: -77.557, lat: 39.528123, postalCode: "21773" },
    { lng: -76.804699, lat: 39.521276, postalCode: "21020" },
    { lng: -76.67712, lat: 39.241957, postalCode: "21227" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20889" },
    { lng: -76.52231, lat: 38.303103, postalCode: "20619" },
    { lng: -76.95779, lat: 39.173104, postalCode: "20777" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20725" },
    { lng: -76.18066, lat: 39.508177, postalCode: "21001" },
    { lng: -77.41411, lat: 39.1672, postalCode: "20839" },
    { lng: -76.85331, lat: 38.964093, postalCode: "20706" },
    { lng: -76.65069, lat: 39.712453, postalCode: "21105" },
    { lng: -76.982405, lat: 38.98707, postalCode: "20787" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20771" },
    { lng: -77.16779, lat: 38.567465, postalCode: "20658" },
    { lng: -76.56623, lat: 39.211758, postalCode: "21226" },
    { lng: -76.91103, lat: 39.359754, postalCode: "21104" },
    { lng: -75.86239, lat: 39.254014, postalCode: "21651" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21273" },
    { lng: -75.68812, lat: 38.292848, postalCode: "21810" },
    { lng: -77.115045, lat: 39.540072, postalCode: "21176" },
    { lng: -76.42781, lat: 39.696621, postalCode: "21132" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21027" },
    { lng: -76.43328, lat: 38.370044, postalCode: "20657" },
    { lng: -75.888207, lat: 38.092718, postalCode: "21857" },
    { lng: -77.32721, lat: 39.695328, postalCode: "21727" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21289" },
    { lng: -76.651589, lat: 39.142793, postalCode: "21240" },
    { lng: -75.734412, lat: 38.073294, postalCode: "21836" },
    { lng: -77.28676, lat: 39.408627, postalCode: "21774" },
    { lng: -76.45569, lat: 39.610405, postalCode: "21084" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20726" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21688" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21283" },
    { lng: -77.06364, lat: 39.335671, postalCode: "21797" },
    { lng: -76.67236, lat: 38.80884, postalCode: "20711" },
    { lng: -76.74002, lat: 39.278056, postalCode: "21228" },
    { lng: -75.949, lat: 38.609886, postalCode: "21664" },
    { lng: -75.35364, lat: 38.397611, postalCode: "21874" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20918" },
    { lng: -76.65248, lat: 38.931312, postalCode: "21035" },
    { lng: -79.17045, lat: 39.675393, postalCode: "21536" },
    { lng: -77.75052, lat: 39.454582, postalCode: "21782" },
    { lng: -76.180166, lat: 38.758525, postalCode: "21606" },
    { lng: -75.947332, lat: 39.593612, postalCode: "21922" },
    { lng: -75.7936, lat: 39.026033, postalCode: "21636" },
    { lng: -76.04321, lat: 38.17656, postalCode: "21627" },
    { lng: -76.55696, lat: 39.284607, postalCode: "21224" },
    { lng: -76.613325, lat: 39.388799, postalCode: "21252" },
    { lng: -77.08079, lat: 38.97986, postalCode: "20815" },
    { lng: -77.202798, lat: 39.187421, postalCode: "20885" },
    { lng: -76.671742, lat: 39.397861, postalCode: "21022" },
    { lng: -77.290519, lat: 39.569426, postalCode: "21759" },
    { lng: -76.99374, lat: 39.104509, postalCode: "20905" },
    { lng: -76.65117, lat: 38.746123, postalCode: "20754" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20916" },
    { lng: -76.2086, lat: 38.709254, postalCode: "21662" },
    { lng: -75.61214, lat: 38.369675, postalCode: "21801" },
    { lng: -77.53385, lat: 39.275498, postalCode: "21777" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21683" },
    { lng: -76.99034, lat: 38.756347, postalCode: "20744" },
    { lng: -76.64114, lat: 39.477506, postalCode: "21030" },
    { lng: -76.55784, lat: 39.079059, postalCode: "21146" },
    { lng: -78.53893, lat: 39.697548, postalCode: "21530" },
    { lng: -76.533026, lat: 38.444727, postalCode: "20610" },
    { lng: -76.092127, lat: 39.034637, postalCode: "21690" },
    { lng: -76.33501, lat: 38.708795, postalCode: "21671" },
    { lng: -76.14666, lat: 38.690831, postalCode: "21654" },
    { lng: -76.75178, lat: 39.197119, postalCode: "21075" },
    { lng: -76.99782, lat: 39.24367, postalCode: "21036" },
    { lng: -78.843962, lat: 39.572452, postalCode: "21556" },
    { lng: -76.05652, lat: 38.925358, postalCode: "21679" },
    { lng: -76.35166, lat: 39.661905, postalCode: "21154" },
    { lng: -75.96707, lat: 39.123235, postalCode: "21623" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20914" },
    { lng: -77.071952, lat: 39.337804, postalCode: "21765" },
    { lng: -78.78741, lat: 39.698332, postalCode: "21524" },
    { lng: -76.941902, lat: 39.236405, postalCode: "21041" },
    { lng: -76.67864, lat: 38.511147, postalCode: "20612" },
    { lng: -76.84477, lat: 38.55223, postalCode: "20617" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20768" },
    { lng: -75.86144, lat: 39.142967, postalCode: "21607" },
    { lng: -76.82527, lat: 39.207523, postalCode: "21045" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20753" },
    { lng: -75.324057, lat: 38.22309, postalCode: "21843" },
    { lng: -76.82215, lat: 38.676968, postalCode: "20613" },
    { lng: -76.420738, lat: 38.188796, postalCode: "20686" },
    { lng: -77.49474, lat: 39.254538, postalCode: "21790" },
    { lng: -77.13519, lat: 38.996842, postalCode: "20817" },
    { lng: -78.86961, lat: 39.699489, postalCode: "21545" },
    { lng: -75.80203, lat: 39.44474, postalCode: "21912" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21251" },
    { lng: -77.392139, lat: 39.469961, postalCode: "21709" },
    { lng: -77.06433, lat: 39.07737, postalCode: "20906" },
    { lng: -77.760697, lat: 39.56991, postalCode: "21781" },
    { lng: -78.690593, lat: 39.580691, postalCode: "21501" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21203" },
    { lng: -77.03494, lat: 39.15283, postalCode: "20860" },
    { lng: -78.01823, lat: 39.654773, postalCode: "21711" },
    { lng: -76.52469, lat: 38.801516, postalCode: "20733" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20799" },
    { lng: -76.0853, lat: 39.61881, postalCode: "21904" },
    { lng: -77.02397, lat: 39.319271, postalCode: "21723" },
    { lng: -79.20856, lat: 39.484412, postalCode: "21561" },
    { lng: -77.12261, lat: 39.0509, postalCode: "20852" },
    { lng: -76.92493, lat: 38.343729, postalCode: "20664" },
    { lng: -75.94596, lat: 38.540293, postalCode: "21835" },
    { lng: -77.70725, lat: 39.609846, postalCode: "21734" },
    { lng: -75.19082, lat: 38.343166, postalCode: "21811" },
    { lng: -77.55869, lat: 39.372496, postalCode: "21755" },
    { lng: -76.44639, lat: 39.400296, postalCode: "21128" },
    { lng: -75.861191, lat: 38.88927, postalCode: "21686" },
    { lng: -76.950913, lat: 38.969434, postalCode: "20788" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20757" },
    { lng: -79.35999, lat: 39.557364, postalCode: "21541" },
    { lng: -76.72457, lat: 38.93193, postalCode: "20716" },
    { lng: -76.30754, lat: 39.532212, postalCode: "21015" },
    { lng: -76.4534, lat: 38.334336, postalCode: "20629" },
    { lng: -76.22765, lat: 39.137775, postalCode: "21661" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20859" },
    { lng: -75.7115, lat: 38.100727, postalCode: "21871" },
    { lng: -76.80782, lat: 38.330254, postalCode: "20621" },
    { lng: -76.98926, lat: 39.563901, postalCode: "21157" },
    { lng: -77.23548, lat: 39.11755, postalCode: "20878" },
    { lng: -77.14981, lat: 39.131705, postalCode: "20855" },
    { lng: -76.30183, lat: 39.427058, postalCode: "21040" },
    { lng: -76.09118, lat: 38.545285, postalCode: "21613" },
    { lng: -76.99193, lat: 38.80758, postalCode: "20745" },
    { lng: -76.69142, lat: 39.130224, postalCode: "21144" },
    { lng: -75.64771, lat: 38.284622, postalCode: "21822" },
    { lng: -76.5114, lat: 38.839015, postalCode: "20764" },
    { lng: -77.9152, lat: 39.656474, postalCode: "21722" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21055" },
    { lng: -76.61793, lat: 38.694074, postalCode: "20736" },
    { lng: -75.84294, lat: 39.626736, postalCode: "21921" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21263" },
    { lng: -77.5672, lat: 39.655722, postalCode: "21783" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20847" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20741" },
    { lng: -76.5373, lat: 38.921763, postalCode: "21037" },
    { lng: -76.59378, lat: 38.731822, postalCode: "20758" },
    { lng: -75.84155, lat: 39.523096, postalCode: "21915" },
    { lng: -77.7557, lat: 39.549752, postalCode: "21733" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20898" },
    { lng: -77.09715, lat: 39.529834, postalCode: "21776" },
    { lng: -75.95602, lat: 39.593813, postalCode: "21901" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20891" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21298" },
    { lng: -76.27313, lat: 39.344808, postalCode: "21010" },
    { lng: -76.09974, lat: 39.295736, postalCode: "21678" },
    { lng: -77.01708, lat: 39.184019, postalCode: "20862" },
    { lng: -75.70169, lat: 38.205272, postalCode: "21853" },
    { lng: -76.84947, lat: 39.336855, postalCode: "21163" },
    { lng: -75.85332, lat: 39.349536, postalCode: "21635" },
    { lng: -76.63614, lat: 39.435789, postalCode: "21093" },
    { lng: -76.47437, lat: 38.171834, postalCode: "20630" },
    { lng: -76.848093, lat: 38.283551, postalCode: "20682" },
    { lng: -76.620489, lat: 39.284707, postalCode: "21288" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20752" },
    { lng: -76.57112, lat: 39.504706, postalCode: "21131" },
    { lng: -76.58805, lat: 39.04366, postalCode: "21032" },
    { lng: -76.98377, lat: 39.297519, postalCode: "21794" },
    { lng: -76.20106, lat: 39.466859, postalCode: "21130" },
    { lng: -76.39363, lat: 39.439935, postalCode: "21156" },
    { lng: -76.73113, lat: 38.330096, postalCode: "20624" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20704" },
    { lng: -76.152762, lat: 38.795102, postalCode: "21652" },
    { lng: -77.321, lat: 39.194497, postalCode: "20841" },
    { lng: -77.00044, lat: 38.98236, postalCode: "20912" },
    { lng: -76.877743, lat: 38.833563, postalCode: "20742" },
    { lng: -76.02374, lat: 37.991177, postalCode: "21824" },
    { lng: -78.77685, lat: 39.718905, postalCode: "21529" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21285" },
    { lng: -76.889, lat: 38.920111, postalCode: "20785" },
    { lng: -76.607676, lat: 38.312112, postalCode: "20635" },
    { lng: -76.88621, lat: 38.855479, postalCode: "20747" },
    { lng: -76.5082, lat: 39.263162, postalCode: "21222" },
    { lng: -76.18219, lat: 38.510494, postalCode: "21677" },
    { lng: -77.15212, lat: 38.593095, postalCode: "20640" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20827" },
    { lng: -76.58648, lat: 38.668765, postalCode: "20689" },
    { lng: -77.62709, lat: 39.391459, postalCode: "21718" },
    { lng: -76.68561, lat: 39.012134, postalCode: "21114" },
    { lng: -77.11425, lat: 38.958377, postalCode: "20816" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20825" },
    { lng: -79.24494, lat: 39.614779, postalCode: "21522" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20884" },
    { lng: -77.1594, lat: 39.229652, postalCode: "20882" },
    { lng: -76.60602, lat: 39.325956, postalCode: "21218" },
    { lng: -77.442, lat: 39.451934, postalCode: "21702" },
    { lng: -75.77488, lat: 38.705731, postalCode: "21632" },
    { lng: -76.92577, lat: 38.99756, postalCode: "20740" },
    { lng: -76.66001, lat: 39.656737, postalCode: "21120" },
    { lng: -76.8888, lat: 38.950528, postalCode: "20784" },
    { lng: -75.69357, lat: 38.407885, postalCode: "21830" },
    { lng: -75.79992, lat: 39.07733, postalCode: "21640" },
    { lng: -76.74382, lat: 38.979413, postalCode: "20715" },
    { lng: -76.93467, lat: 39.097243, postalCode: "20866" },
    { lng: -76.70867, lat: 39.079902, postalCode: "21113" },
    { lng: -76.594942, lat: 38.974203, postalCode: "21404" },
    { lng: -76.592139, lat: 39.438964, postalCode: "21284" },
    { lng: -77.207617, lat: 39.143979, postalCode: "20908" },
    { lng: -75.6276, lat: 38.388434, postalCode: "21803" },
    { lng: -79.20096, lat: 39.395674, postalCode: "21538" },
    { lng: -76.694102, lat: 38.363994, postalCode: "20660" },
    { lng: -76.96139, lat: 38.96401, postalCode: "20782" },
    { lng: -70.239485, lat: 44.197009, postalCode: "4283" },
    { lng: -69.08184, lat: 44.218159, postalCode: "4843" },
    { lng: -68.00216, lat: 46.679321, postalCode: "4769" },
    { lng: -67.23946, lat: 44.673285, postalCode: "4626" },
    { lng: -70.255783, lat: 44.357987, postalCode: "4266" },
    { lng: -70.6576, lat: 44.966701, postalCode: "4970" },
    { lng: -70.697022, lat: 44.498193, postalCode: "4273" },
    { lng: -69.104234, lat: 44.086989, postalCode: "4842" },
    { lng: -68.85667, lat: 44.499082, postalCode: "4981" },
    { lng: -68.23311, lat: 44.87332, postalCode: "4408" },
    { lng: -70.43726, lat: 44.494624, postalCode: "4290" },
    { lng: -67.98828, lat: 44.428044, postalCode: "4624" },
    { lng: -70.06456, lat: 44.42977, postalCode: "4349" },
    { lng: -68.88847, lat: 43.856129, postalCode: "4851" },
    { lng: -69.79788, lat: 44.286802, postalCode: "4347" },
    { lng: -70.661557, lat: 44.566156, postalCode: "4081" },
    { lng: -70.33319, lat: 44.154921, postalCode: "4258" },
    { lng: -69.40616, lat: 44.774328, postalCode: "4967" },
    { lng: -70.6478, lat: 44.727073, postalCode: "4275" },
    { lng: -69.751913, lat: 44.414056, postalCode: "4332" },
    { lng: -69.32016, lat: 43.764862, postalCode: "4852" },
    { lng: -70.7356, lat: 44.49281, postalCode: "4237" },
    { lng: -70.79952, lat: 44.661894, postalCode: "4216" },
    { lng: -70.31378, lat: 44.423658, postalCode: "4221" },
    { lng: -69.43738, lat: 43.999618, postalCode: "4551" },
    { lng: -68.69084, lat: 44.560479, postalCode: "4472" },
    { lng: -68.32186, lat: 44.278453, postalCode: "4679" },
    { lng: -70.43275, lat: 44.697077, postalCode: "4285" },
    { lng: -68.1204, lat: 46.953804, postalCode: "4762" },
    { lng: -69.64968, lat: 44.949136, postalCode: "4912" },
    { lng: -69.03065, lat: 44.325944, postalCode: "4849" },
    { lng: -70.41037, lat: 43.995965, postalCode: "4724" },
    { lng: -68.78905, lat: 44.721393, postalCode: "4474" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4122" },
    { lng: -69.4971, lat: 44.212785, postalCode: "4348" },
    { lng: -69.99448, lat: 43.730791, postalCode: "4003" },
    { lng: -70.182012, lat: 44.617509, postalCode: "4234" },
    { lng: -70.76786, lat: 44.924121, postalCode: "4964" },
    { lng: -68.44607, lat: 44.161589, postalCode: "4685" },
    { lng: -68.67842, lat: 44.305681, postalCode: "4673" },
    { lng: -70.63265, lat: 43.611238, postalCode: "4042" },
    { lng: -68.78881, lat: 45.184949, postalCode: "4453" },
    { lng: -68.27288, lat: 47.165256, postalCode: "4779" },
    { lng: -69.32571, lat: 45.129817, postalCode: "4479" },
    { lng: -70.63082, lat: 44.386951, postalCode: "4219" },
    { lng: -70.61178, lat: 43.320211, postalCode: "4090" },
    { lng: -69.96163, lat: 43.94674, postalCode: "4086" },
    { lng: -68.57238, lat: 47.209541, postalCode: "4743" },
    { lng: -69.00746, lat: 44.979986, postalCode: "4427" },
    { lng: -68.3521, lat: 44.248615, postalCode: "4653" },
    { lng: -69.985, lat: 44.548458, postalCode: "4360" },
    { lng: -67.70122, lat: 44.556073, postalCode: "4606" },
    { lng: -69.81778, lat: 43.793136, postalCode: "4562" },
    { lng: -70.87599, lat: 44.570532, postalCode: "4261" },
    { lng: -70.239485, lat: 44.197009, postalCode: "4212" },
    { lng: -68.391481, lat: 44.641873, postalCode: "4690" },
    { lng: -70.22985, lat: 43.842797, postalCode: "4097" },
    { lng: -69.62535, lat: 44.084989, postalCode: "4535" },
    { lng: -69.37177, lat: 44.10637, postalCode: "4572" },
    { lng: -68.90997, lat: 45.088295, postalCode: "4410" },
    { lng: -69.90892, lat: 45.380034, postalCode: "4985" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4057" },
    { lng: -68.66405, lat: 44.168214, postalCode: "4681" },
    { lng: -70.80878, lat: 43.773606, postalCode: "4020" },
    { lng: -70.845114, lat: 43.732391, postalCode: "4028" },
    { lng: -67.96531, lat: 46.574691, postalCode: "4787" },
    { lng: -69.47857, lat: 44.096194, postalCode: "4555" },
    { lng: -68.59682, lat: 44.700606, postalCode: "4429" },
    { lng: -67.9643, lat: 46.892918, postalCode: "4751" },
    { lng: -70.83391, lat: 43.871738, postalCode: "4041" },
    { lng: -70.1097, lat: 44.027509, postalCode: "4250" },
    { lng: -68.91965, lat: 44.61165, postalCode: "4438" },
    { lng: -69.12604, lat: 44.554501, postalCode: "4921" },
    { lng: -68.647416, lat: 45.519867, postalCode: "4402" },
    { lng: -68.2277, lat: 44.258201, postalCode: "4646" },
    { lng: -67.495548, lat: 45.002969, postalCode: "4454" },
    { lng: -70.22906, lat: 44.602261, postalCode: "4294" },
    { lng: -69.54335, lat: 44.048714, postalCode: "4553" },
    { lng: -70.60312, lat: 43.959974, postalCode: "4055" },
    { lng: -70.2903, lat: 43.685882, postalCode: "4103" },
    { lng: -68.43626, lat: 44.614885, postalCode: "4605" },
    { lng: -70.19475, lat: 43.665177, postalCode: "4108" },
    { lng: -69.538257, lat: 44.391167, postalCode: "4926" },
    { lng: -69.09812, lat: 44.180867, postalCode: "4856" },
    { lng: -68.647416, lat: 45.519867, postalCode: "4933" },
    { lng: -68.83819, lat: 44.061051, postalCode: "4863" },
    { lng: -69.20629, lat: 44.008875, postalCode: "4857" },
    { lng: -68.7769, lat: 44.214927, postalCode: "4683" },
    { lng: -67.04996, lat: 44.818733, postalCode: "4652" },
    { lng: -69.32744, lat: 44.371109, postalCode: "4949" },
    { lng: -70.604379, lat: 43.365658, postalCode: "4014" },
    { lng: -70.573255, lat: 43.406101, postalCode: "4094" },
    { lng: -67.24243, lat: 45.166045, postalCode: "4619" },
    { lng: -70.868871, lat: 43.658378, postalCode: "4056" },
    { lng: -69.803936, lat: 44.090471, postalCode: "4557" },
    { lng: -69.27048, lat: 44.240765, postalCode: "4862" },
    { lng: -67.94718, lat: 47.14685, postalCode: "4785" },
    { lng: -70.09448, lat: 43.696875, postalCode: "4019" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4775" },
    { lng: -69.80879, lat: 45.704599, postalCode: "4478" },
    { lng: -70.19141, lat: 44.094773, postalCode: "4240" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4123" },
    { lng: -70.273642, lat: 43.576983, postalCode: "4070" },
    { lng: -68.410131, lat: 46.602477, postalCode: "4752" },
    { lng: -69.058062, lat: 45.351184, postalCode: "4415" },
    { lng: -69.61699, lat: 44.464439, postalCode: "4962" },
    { lng: -70.36975, lat: 44.197418, postalCode: "4238" },
    { lng: -69.03527, lat: 45.060941, postalCode: "4422" },
    { lng: -69.680144, lat: 43.88261, postalCode: "4571" },
    { lng: -69.95217, lat: 43.797398, postalCode: "4079" },
    { lng: -68.58953, lat: 45.191812, postalCode: "4475" },
    { lng: -70.22617, lat: 43.604739, postalCode: "4107" },
    { lng: -69.11366, lat: 44.108735, postalCode: "4841" },
    { lng: -68.58156, lat: 44.413056, postalCode: "4614" },
    { lng: -68.92829, lat: 44.500622, postalCode: "4974" },
    { lng: -68.92518, lat: 44.735745, postalCode: "4444" },
    { lng: -68.621, lat: 44.911733, postalCode: "4411" },
    { lng: -68.33931, lat: 47.241717, postalCode: "4772" },
    { lng: -70.661557, lat: 44.566156, postalCode: "4267" },
    { lng: -69.07536, lat: 44.065748, postalCode: "4854" },
    { lng: -69.15608, lat: 43.999827, postalCode: "4859" },
    { lng: -68.42087, lat: 45.264114, postalCode: "4417" },
    { lng: -69.17598, lat: 44.082602, postalCode: "4861" },
    { lng: -70.69607, lat: 43.08512, postalCode: "3905" },
    { lng: -70.13383, lat: 44.400997, postalCode: "4228" },
    { lng: -70.598303, lat: 43.269137, postalCode: "3097" },
    { lng: -70.11254, lat: 44.283844, postalCode: "4263" },
    { lng: -70.84515, lat: 43.553076, postalCode: "4076" },
    { lng: -70.12443, lat: 44.79781, postalCode: "4956" },
    { lng: -70.4769, lat: 43.399494, postalCode: "4046" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4770" },
    { lng: -69.56523, lat: 44.985047, postalCode: "4942" },
    { lng: -70.22651, lat: 44.602705, postalCode: "4225" },
    { lng: -70.6884, lat: 43.490939, postalCode: "4002" },
    { lng: -68.09458, lat: 44.384841, postalCode: "4693" },
    { lng: -69.50529, lat: 43.965125, postalCode: "4539" },
    { lng: -67.3786, lat: 44.753992, postalCode: "4630" },
    { lng: -70.41383, lat: 44.555583, postalCode: "4224" },
    { lng: -68.54781, lat: 44.354477, postalCode: "4615" },
    { lng: -68.51313, lat: 44.414095, postalCode: "4629" },
    { lng: -70.860094, lat: 44.40205, postalCode: "4286" },
    { lng: -68.65693, lat: 44.333099, postalCode: "4676" },
    { lng: -68.29313, lat: 45.333971, postalCode: "4455" },
    { lng: -70.62856, lat: 43.216251, postalCode: "3902" },
    { lng: -69.93363, lat: 44.174052, postalCode: "4350" },
    { lng: -69.55874, lat: 43.863902, postalCode: "4568" },
    { lng: -69.751913, lat: 44.414056, postalCode: "4343" },
    { lng: -70.79348, lat: 43.135435, postalCode: "3903" },
    { lng: -70.27257, lat: 45.587713, postalCode: "4945" },
    { lng: -67.94879, lat: 44.510306, postalCode: "4680" },
    { lng: -69.113626, lat: 44.210119, postalCode: "4840" },
    { lng: -67.39416, lat: 44.632707, postalCode: "4655" },
    { lng: -68.62199, lat: 44.956776, postalCode: "4461" },
    { lng: -69.149559, lat: 44.478694, postalCode: "4850" },
    { lng: -69.75012, lat: 44.07685, postalCode: "4342" },
    { lng: -70.39197, lat: 44.10472, postalCode: "4256" },
    { lng: -69.03431, lat: 45.369216, postalCode: "4414" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4116" },
    { lng: -69.37398, lat: 44.667844, postalCode: "4922" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4744" },
    { lng: -69.523263, lat: 44.021333, postalCode: "4536" },
    { lng: -67.85518, lat: 46.311845, postalCode: "4760" },
    { lng: -70.3856, lat: 43.514864, postalCode: "4064" },
    { lng: -69.148017, lat: 44.032196, postalCode: "4855" },
    { lng: -68.8957, lat: 44.657697, postalCode: "4496" },
    { lng: -69.52679, lat: 44.428911, postalCode: "4358" },
    { lng: -70.891727, lat: 44.180965, postalCode: "4016" },
    { lng: -70.35268, lat: 43.583224, postalCode: "4074" },
    { lng: -69.52041, lat: 43.893848, postalCode: "4558" },
    { lng: -69.55133, lat: 43.949672, postalCode: "4573" },
    { lng: -68.68116, lat: 44.56801, postalCode: "4431" },
    { lng: -70.8175, lat: 44.372129, postalCode: "4217" },
    { lng: -67.86655, lat: 46.512409, postalCode: "4758" },
    { lng: -70.15475, lat: 43.692683, postalCode: "4050" },
    { lng: -68.40092, lat: 44.295719, postalCode: "4674" },
    { lng: -70.285748, lat: 44.023873, postalCode: "4223" },
    { lng: -67.86338, lat: 46.657001, postalCode: "4740" },
    { lng: -69.751913, lat: 44.414056, postalCode: "4918" },
    { lng: -69.30463, lat: 45.792364, postalCode: "4485" },
    { lng: -70.326337, lat: 44.579135, postalCode: "4227" },
    { lng: -69.12214, lat: 44.961913, postalCode: "4435" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4734" },
    { lng: -69.12781, lat: 44.024605, postalCode: "4858" },
    { lng: -67.495548, lat: 45.002969, postalCode: "4491" },
    { lng: -70.10172, lat: 43.843778, postalCode: "4032" },
    { lng: -70.39077, lat: 44.049157, postalCode: "4274" },
    { lng: -70.27272, lat: 43.631549, postalCode: "4106" },
    { lng: -69.53064, lat: 44.659486, postalCode: "4927" },
    { lng: -70.198742, lat: 43.678339, postalCode: "4109" },
    { lng: -68.74801, lat: 45.646916, postalCode: "4462" },
    { lng: -70.56609, lat: 44.53599, postalCode: "4276" },
    { lng: -68.686796, lat: 44.922368, postalCode: "4489" },
    { lng: -70.464839, lat: 43.84649, postalCode: "4104" },
    { lng: -69.9433, lat: 45.249363, postalCode: "4925" },
    { lng: -70.24563, lat: 44.266842, postalCode: "4282" },
    { lng: -70.93189, lat: 43.548123, postalCode: "4001" },
    { lng: -69.81117, lat: 44.624821, postalCode: "4978" },
    { lng: -69.69212, lat: 44.782568, postalCode: "4976" },
    { lng: -70.3671, lat: 44.295264, postalCode: "4220" },
    { lng: -69.02257, lat: 44.445295, postalCode: "4915" },
    { lng: -70.12362, lat: 44.66776, postalCode: "4938" },
    { lng: -67.59063, lat: 45.188555, postalCode: "4668" },
    { lng: -70.14181, lat: 44.194404, postalCode: "4236" },
    { lng: -69.95894, lat: 43.8992, postalCode: "4011" },
    { lng: -70.552183, lat: 43.796053, postalCode: "4075" },
    { lng: -67.59538, lat: 44.663206, postalCode: "4648" },
    { lng: -70.505301, lat: 43.45813, postalCode: "4007" },
    { lng: -67.7395, lat: 44.695493, postalCode: "4623" },
    { lng: -70.07568, lat: 44.119007, postalCode: "4280" },
    { lng: -69.99897, lat: 44.231811, postalCode: "4259" },
    { lng: -70.80275, lat: 43.467037, postalCode: "4083" },
    { lng: -67.14057, lat: 45.076626, postalCode: "4671" },
    { lng: -68.58178, lat: 45.631824, postalCode: "4430" },
    { lng: -70.635702, lat: 43.155465, postalCode: "3911" },
    { lng: -69.84723, lat: 45.07952, postalCode: "4920" },
    { lng: -68.647416, lat: 45.519867, postalCode: "4467" },
    { lng: -67.08697, lat: 44.972969, postalCode: "4667" },
    { lng: -69.15196, lat: 44.417725, postalCode: "4952" },
    { lng: -70.35823, lat: 43.682148, postalCode: "4092" },
    { lng: -70.24143, lat: 44.089173, postalCode: "4210" },
    { lng: -69.96847, lat: 44.048154, postalCode: "4287" },
    { lng: -69.80759, lat: 44.940536, postalCode: "4979" },
    { lng: -69.51368, lat: 45.303916, postalCode: "4464" },
    { lng: -70.47233, lat: 43.917206, postalCode: "4071" },
    { lng: -68.88431, lat: 44.999621, postalCode: "4449" },
    { lng: -69.29725, lat: 45.023476, postalCode: "4930" },
    { lng: -69.64608, lat: 43.907812, postalCode: "4537" },
    { lng: -68.78952, lat: 44.81777, postalCode: "4401" },
    { lng: -70.58366, lat: 44.223589, postalCode: "4268" },
    { lng: -69.33885, lat: 43.983665, postalCode: "4547" },
    { lng: -68.7547, lat: 44.78696, postalCode: "4412" },
    { lng: -68.95101, lat: 45.241618, postalCode: "4463" },
    { lng: -68.28143, lat: 44.291076, postalCode: "4662" },
    { lng: -70.25862, lat: 43.660525, postalCode: "4101" },
    { lng: -68.05875, lat: 45.733543, postalCode: "4497" },
    { lng: -68.90883, lat: 44.305981, postalCode: "4848" },
    { lng: -69.23241, lat: 44.555643, postalCode: "4986" },
    { lng: -69.751913, lat: 44.414056, postalCode: "4935" },
    { lng: -68.79341, lat: 44.405928, postalCode: "4421" },
    { lng: -70.78804, lat: 43.969, postalCode: "4022" },
    { lng: -69.88369, lat: 44.023057, postalCode: "4008" },
    { lng: -70.45871, lat: 43.480704, postalCode: "4005" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4098" },
    { lng: -70.04027, lat: 44.281993, postalCode: "4265" },
    { lng: -68.48987, lat: 45.01895, postalCode: "4423" },
    { lng: -68.391481, lat: 44.641873, postalCode: "4665" },
    { lng: -69.24907, lat: 44.135936, postalCode: "4864" },
    { lng: -70.3533, lat: 43.879576, postalCode: "4039" },
    { lng: -69.44672, lat: 45.031691, postalCode: "4923" },
    { lng: -69.38896, lat: 44.274007, postalCode: "4574" },
    { lng: -69.091111, lat: 44.131005, postalCode: "4846" },
    { lng: -70.56433, lat: 43.761599, postalCode: "4084" },
    { lng: -69.82554, lat: 44.700173, postalCode: "4957" },
    { lng: -69.80122, lat: 44.26043, postalCode: "4344" },
    { lng: -70.075178, lat: 44.622563, postalCode: "4940" },
    { lng: -69.61297, lat: 44.554965, postalCode: "4901" },
    { lng: -69.00587, lat: 44.7896, postalCode: "4419" },
    { lng: -69.121139, lat: 44.192369, postalCode: "4865" },
    { lng: -67.85856, lat: 45.909516, postalCode: "4471" },
    { lng: -67.6025, lat: 44.507801, postalCode: "4611" },
    { lng: -70.90049, lat: 43.938741, postalCode: "4010" },
    { lng: -68.4992, lat: 46.778192, postalCode: "4768" },
    { lng: -67.54727, lat: 44.802637, postalCode: "4654" },
    { lng: -69.96073, lat: 44.46599, postalCode: "4352" },
    { lng: -67.76009, lat: 45.426462, postalCode: "4490" },
    { lng: -69.60427, lat: 44.209878, postalCode: "4353" },
    { lng: -70.26027, lat: 43.736732, postalCode: "4105" },
    { lng: -70.69882, lat: 44.614134, postalCode: "4226" },
    { lng: -67.57808, lat: 44.554662, postalCode: "4649" },
    { lng: -69.93424, lat: 44.78029, postalCode: "4911" },
    { lng: -70.524524, lat: 43.910029, postalCode: "4077" },
    { lng: -68.26204, lat: 44.402596, postalCode: "4609" },
    { lng: -70.50508, lat: 44.235141, postalCode: "4281" },
    { lng: -69.19254, lat: 44.232721, postalCode: "4847" },
    { lng: -70.53351, lat: 44.556984, postalCode: "4257" },
    { lng: -69.523263, lat: 44.021333, postalCode: "4549" },
    { lng: -68.391481, lat: 44.641873, postalCode: "4644" },
    { lng: -70.73201, lat: 43.211674, postalCode: "3910" },
    { lng: -68.49241, lat: 46.024219, postalCode: "4765" },
    { lng: -69.523263, lat: 44.021333, postalCode: "4552" },
    { lng: -70.70757, lat: 44.190761, postalCode: "4088" },
    { lng: -67.38549, lat: 44.971091, postalCode: "4657" },
    { lng: -68.66894, lat: 44.221142, postalCode: "4627" },
    { lng: -68.55956, lat: 44.275325, postalCode: "4616" },
    { lng: -70.120781, lat: 43.820774, postalCode: "4078" },
    { lng: -69.23524, lat: 43.9464, postalCode: "4860" },
    { lng: -68.80933, lat: 44.322421, postalCode: "4642" },
    { lng: -70.68482, lat: 43.823849, postalCode: "4024" },
    { lng: -69.6425, lat: 44.684737, postalCode: "4944" },
    { lng: -70.498513, lat: 44.264111, postalCode: "4271" },
    { lng: -70.54062, lat: 45.325768, postalCode: "4936" },
    { lng: -68.23959, lat: 47.281953, postalCode: "4773" },
    { lng: -68.2849, lat: 44.430435, postalCode: "4672" },
    { lng: -69.5744, lat: 44.300247, postalCode: "4363" },
    { lng: -70.65327, lat: 44.103892, postalCode: "4040" },
    { lng: -68.02915, lat: 44.378868, postalCode: "4613" },
    { lng: -68.5694, lat: 44.798289, postalCode: "4428" },
    { lng: -67.00391, lat: 44.913741, postalCode: "4631" },
    { lng: -70.239485, lat: 44.197009, postalCode: "4288" },
    { lng: -67.601026, lat: 45.185635, postalCode: "4637" },
    { lng: -69.45721, lat: 43.937468, postalCode: "4564" },
    { lng: -69.82947, lat: 44.117232, postalCode: "4357" },
    { lng: -68.147, lat: 46.68619, postalCode: "4757" },
    { lng: -70.54707, lat: 43.38551, postalCode: "4043" },
    { lng: -69.751913, lat: 44.414056, postalCode: "4338" },
    { lng: -68.63343, lat: 45.002798, postalCode: "4469" },
    { lng: -68.33986, lat: 45.864106, postalCode: "4776" },
    { lng: -70.44606, lat: 45.137607, postalCode: "4982" },
    { lng: -70.43911, lat: 43.916912, postalCode: "4031" },
    { lng: -67.82533, lat: 44.599134, postalCode: "4643" },
    { lng: -68.78303, lat: 44.609813, postalCode: "4416" },
    { lng: -70.568041, lat: 44.377031, postalCode: "4278" },
    { lng: -68.61588, lat: 47.042226, postalCode: "4739" },
    { lng: -68.93125, lat: 44.921758, postalCode: "4450" },
    { lng: -69.27327, lat: 44.949001, postalCode: "4928" },
    { lng: -70.67755, lat: 43.887356, postalCode: "4029" },
    { lng: -70.05631, lat: 44.009827, postalCode: "4252" },
    { lng: -67.2324, lat: 44.894562, postalCode: "4628" },
    { lng: -69.76938, lat: 44.560495, postalCode: "4963" },
    { lng: -70.91218, lat: 43.642631, postalCode: "4095" },
    { lng: -70.381639, lat: 45.063384, postalCode: "4262" },
    { lng: -68.42804, lat: 46.64005, postalCode: "4732" },
    { lng: -70.43533, lat: 44.38382, postalCode: "4292" },
    { lng: -69.58903, lat: 43.832642, postalCode: "4544" },
    { lng: -70.23773, lat: 44.688292, postalCode: "4984" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4033" },
    { lng: -67.85012, lat: 46.774757, postalCode: "4742" },
    { lng: -69.48841, lat: 44.036533, postalCode: "4543" },
    { lng: -69.14212, lat: 44.802184, postalCode: "4434" },
    { lng: -68.18185, lat: 44.482607, postalCode: "4677" },
    { lng: -68.33623, lat: 45.518535, postalCode: "4459" },
    { lng: -69.83405, lat: 43.900651, postalCode: "4530" },
    { lng: -68.45173, lat: 45.880436, postalCode: "4777" },
    { lng: -70.70263, lat: 43.733814, postalCode: "4049" },
    { lng: -70.597752, lat: 43.276341, postalCode: "4054" },
    { lng: -68.391481, lat: 44.641873, postalCode: "4656" },
    { lng: -70.84559, lat: 43.29016, postalCode: "3901" },
    { lng: -69.49039, lat: 45.221933, postalCode: "4406" },
    { lng: -70.73611, lat: 43.101538, postalCode: "3904" },
    { lng: -68.71098, lat: 44.286462, postalCode: "4650" },
    { lng: -67.75295, lat: 45.53866, postalCode: "4413" },
    { lng: -70.75245, lat: 43.426792, postalCode: "4073" },
    { lng: -70.21778, lat: 44.822625, postalCode: "4983" },
    { lng: -70.2911, lat: 43.658632, postalCode: "4102" },
    { lng: -70.955693, lat: 43.341381, postalCode: "3688" },
    { lng: -68.32256, lat: 47.345936, postalCode: "4756" },
    { lng: -70.239485, lat: 44.197009, postalCode: "4211" },
    { lng: -70.51377, lat: 43.952597, postalCode: "4015" },
    { lng: -69.21191, lat: 44.767975, postalCode: "4969" },
    { lng: -70.239485, lat: 44.197009, postalCode: "4241" },
    { lng: -69.31337, lat: 44.466012, postalCode: "4941" },
    { lng: -68.52012, lat: 45.638612, postalCode: "4460" },
    { lng: -70.715864, lat: 44.56421, postalCode: "4279" },
    { lng: -69.749812, lat: 43.767018, postalCode: "4546" },
    { lng: -70.22452, lat: 44.408579, postalCode: "4253" },
    { lng: -68.01128, lat: 46.878869, postalCode: "4736" },
    { lng: -69.713178, lat: 44.549225, postalCode: "4903" },
    { lng: -69.63627, lat: 44.603329, postalCode: "4937" },
    { lng: -69.586852, lat: 44.624515, postalCode: "4975" },
    { lng: -69.93521, lat: 44.926344, postalCode: "4958" },
    { lng: -69.36775, lat: 44.845899, postalCode: "4965" },
    { lng: -70.87044, lat: 44.261562, postalCode: "4231" },
    { lng: -68.647416, lat: 45.519867, postalCode: "4782" },
    { lng: -70.19681, lat: 43.760369, postalCode: "4110" },
    { lng: -69.68366, lat: 43.974367, postalCode: "4578" },
    { lng: -68.8781, lat: 44.154189, postalCode: "4853" },
    { lng: -70.17932, lat: 43.801773, postalCode: "4096" },
    { lng: -69.14747, lat: 45.046491, postalCode: "4939" },
    { lng: -69.74325, lat: 43.810548, postalCode: "4548" },
    { lng: -69.1108, lat: 44.891869, postalCode: "4488" },
    { lng: -70.05698, lat: 44.349542, postalCode: "4284" },
    { lng: -70.91719, lat: 43.403349, postalCode: "4027" },
    { lng: -70.93094, lat: 43.833831, postalCode: "4068" },
    { lng: -68.68177, lat: 44.889249, postalCode: "4473" },
    { lng: -68.20823, lat: 47.049301, postalCode: "4783" },
    { lng: -67.824005, lat: 45.961338, postalCode: "4465" },
    { lng: -68.38716, lat: 47.285196, postalCode: "4745" },
    { lng: -67.99166, lat: 44.686731, postalCode: "4622" },
    { lng: -70.41697, lat: 43.810673, postalCode: "4062" },
    { lng: -69.55694, lat: 44.765617, postalCode: "4924" },
    { lng: -69.83348, lat: 44.493133, postalCode: "4917" },
    { lng: -69.96353, lat: 44.376802, postalCode: "4355" },
    { lng: -69.660795, lat: 43.854534, postalCode: "4575" },
    { lng: -70.2669, lat: 45.018122, postalCode: "4947" },
    { lng: -69.4136, lat: 44.384901, postalCode: "4354" },
    { lng: -70.59952, lat: 43.247931, postalCode: "3907" },
    { lng: -70.8983, lat: 43.755002, postalCode: "4047" },
    { lng: -69.63217, lat: 43.85424, postalCode: "4538" },
    { lng: -69.80866, lat: 44.211491, postalCode: "4345" },
    { lng: -70.239485, lat: 44.197009, postalCode: "4243" },
    { lng: -67.86208, lat: 45.679453, postalCode: "4424" },
    { lng: -68.39974, lat: 45.810781, postalCode: "4733" },
    { lng: -68.2929, lat: 46.869762, postalCode: "4766" },
    { lng: -68.22333, lat: 44.528094, postalCode: "4664" },
    { lng: -69.00258, lat: 47.119796, postalCode: "4774" },
    { lng: -69.75722, lat: 44.234914, postalCode: "4346" },
    { lng: -69.751913, lat: 44.414056, postalCode: "4333" },
    { lng: -70.453006, lat: 44.040857, postalCode: "4291" },
    { lng: -68.73576, lat: 44.444707, postalCode: "4476" },
    { lng: -70.818045, lat: 44.209214, postalCode: "4321" },
    { lng: -69.26918, lat: 44.678838, postalCode: "4987" },
    { lng: -67.89417, lat: 46.421799, postalCode: "4735" },
    { lng: -69.804077, lat: 44.09156, postalCode: "4327" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4082" },
    { lng: -70.3598, lat: 43.436049, postalCode: "4006" },
    { lng: -70.46301, lat: 43.697118, postalCode: "4038" },
    { lng: -69.777196, lat: 44.183266, postalCode: "4359" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4112" },
    { lng: -66.990529, lat: 44.904789, postalCode: "4621" },
    { lng: -69.551024, lat: 44.258771, postalCode: "4341" },
    { lng: -70.17893, lat: 43.912616, postalCode: "4069" },
    { lng: -68.20841, lat: 45.548818, postalCode: "4451" },
    { lng: -69.65181, lat: 44.398851, postalCode: "4989" },
    { lng: -69.1882, lat: 44.369557, postalCode: "4973" },
    { lng: -68.34995, lat: 44.124476, postalCode: "4635" },
    { lng: -70.77876, lat: 43.234782, postalCode: "3908" },
    { lng: -70.45171, lat: 43.527322, postalCode: "4072" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4053" },
    { lng: -69.18861, lat: 45.195052, postalCode: "4426" },
    { lng: -68.50085, lat: 44.490635, postalCode: "4684" },
    { lng: -70.72552, lat: 44.059192, postalCode: "4009" },
    { lng: -68.01142, lat: 44.408699, postalCode: "4669" },
    { lng: -70.75038, lat: 43.824885, postalCode: "4091" },
    { lng: -69.51314, lat: 43.863028, postalCode: "4554" },
    { lng: -70.57492, lat: 43.660386, postalCode: "4093" },
    { lng: -70.327049, lat: 44.062672, postalCode: "4230" },
    { lng: -68.37662, lat: 45.484389, postalCode: "4495" },
    { lng: -69.1248, lat: 44.689677, postalCode: "4932" },
    { lng: -70.153049, lat: 44.662822, postalCode: "4992" },
    { lng: -68.37251, lat: 46.497891, postalCode: "4759" },
    { lng: -70.50506, lat: 44.114612, postalCode: "4270" },
    { lng: -67.495548, lat: 45.002969, postalCode: "4492" },
    { lng: -67.495548, lat: 45.002969, postalCode: "4686" },
    { lng: -69.82782, lat: 44.819206, postalCode: "4950" },
    { lng: -68.67025, lat: 45.241091, postalCode: "4448" },
    { lng: -69.37191, lat: 45.245781, postalCode: "4443" },
    { lng: -68.08163, lat: 45.386188, postalCode: "4487" },
    { lng: -68.27004, lat: 46.004523, postalCode: "4747" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4737" },
    { lng: -70.7646, lat: 43.327517, postalCode: "3906" },
    { lng: -69.5223, lat: 45.517693, postalCode: "4441" },
    { lng: -70.26554, lat: 43.798251, postalCode: "4021" },
    { lng: -70.67231, lat: 44.35976, postalCode: "4255" },
    { lng: -67.42484, lat: 45.116525, postalCode: "4694" },
    { lng: -70.65239, lat: 43.159537, postalCode: "3909" },
    { lng: -70.52451, lat: 44.325285, postalCode: "4289" },
    { lng: -70.6793, lat: 43.585861, postalCode: "4030" },
    { lng: -67.1946, lat: 44.975116, postalCode: "4666" },
    { lng: -70.08652, lat: 45.056328, postalCode: "4961" },
    { lng: -70.37235, lat: 44.840352, postalCode: "4966" },
    { lng: -68.58915, lat: 47.163149, postalCode: "4781" },
    { lng: -69.33022, lat: 44.60509, postalCode: "4988" },
    { lng: -67.24789, lat: 44.790082, postalCode: "4691" },
    { lng: -70.11522, lat: 43.736628, postalCode: "4017" },
    { lng: -69.149559, lat: 44.478694, postalCode: "4972" },
    { lng: -70.385703, lat: 43.504889, postalCode: "4063" },
    { lng: -68.74399, lat: 44.363587, postalCode: "4617" },
    { lng: -68.62103, lat: 45.110336, postalCode: "4418" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4013" },
    { lng: -68.51819, lat: 45.247401, postalCode: "4493" },
    { lng: -69.26397, lat: 44.85549, postalCode: "4953" },
    { lng: -69.859541, lat: 43.900856, postalCode: "4567" },
    { lng: -68.67889, lat: 44.951551, postalCode: "4468" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4788" },
    { lng: -70.818318, lat: 43.319125, postalCode: "3091" },
    { lng: -68.37104, lat: 44.247391, postalCode: "4612" },
    { lng: -68.423297, lat: 44.540983, postalCode: "4065" },
    { lng: -68.798047, lat: 44.412968, postalCode: "4420" },
    { lng: -67.87827, lat: 44.512003, postalCode: "4658" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4034" },
    { lng: -69.04542, lat: 44.599607, postalCode: "4951" },
    { lng: -68.16871, lat: 46.78551, postalCode: "4786" },
    { lng: -69.66661, lat: 43.823008, postalCode: "4576" },
    { lng: -68.003376, lat: 46.100442, postalCode: "4761" },
    { lng: -70.87231, lat: 44.172276, postalCode: "4051" },
    { lng: -68.98712, lat: 44.876282, postalCode: "4456" },
    { lng: -70.63812, lat: 43.770432, postalCode: "4085" },
    { lng: -70.29488, lat: 43.957375, postalCode: "4260" },
    { lng: -68.09467, lat: 44.49569, postalCode: "4607" },
    { lng: -68.23232, lat: 46.164163, postalCode: "4780" },
    { lng: -69.523263, lat: 44.021333, postalCode: "4570" },
    { lng: -67.86471, lat: 46.936616, postalCode: "4750" },
    { lng: -70.12291, lat: 43.972505, postalCode: "4222" },
    { lng: -69.49934, lat: 44.861365, postalCode: "4943" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4738" },
    { lng: -70.75497, lat: 43.56066, postalCode: "4087" },
    { lng: -69.62773, lat: 45.479835, postalCode: "4442" },
    { lng: -68.61685, lat: 44.058801, postalCode: "4645" },
    { lng: -70.470703, lat: 44.408078, postalCode: "4124" },
    { lng: -68.23546, lat: 44.617844, postalCode: "4634" },
    { lng: -69.30376, lat: 44.785927, postalCode: "4929" },
    { lng: -70.77178, lat: 43.682791, postalCode: "4048" },
    { lng: -69.863461, lat: 43.773312, postalCode: "4565" },
    { lng: -70.95648, lat: 44.050856, postalCode: "4037" },
    { lng: -70.10453, lat: 44.904101, postalCode: "4954" },
    { lng: -68.47646, lat: 46.416656, postalCode: "4764" },
    { lng: -69.78298, lat: 43.949901, postalCode: "4579" },
    { lng: -69.30463, lat: 45.792364, postalCode: "4481" },
    { lng: -69.4795, lat: 43.885681, postalCode: "4541" },
    { lng: -69.97038, lat: 43.763403, postalCode: "4066" },
    { lng: -68.24075, lat: 44.298106, postalCode: "4675" },
    { lng: -69.43496, lat: 44.513491, postalCode: "4910" },
    { lng: -70.01525, lat: 44.638255, postalCode: "4955" },
    { lng: -68.34317, lat: 44.34629, postalCode: "4660" },
    { lng: -70.604379, lat: 43.365658, postalCode: "4004" },
    { lng: -68.48354, lat: 45.368934, postalCode: "4457" },
    { lng: -69.26819, lat: 43.993356, postalCode: "4563" },
    { lng: -68.25991, lat: 44.248715, postalCode: "4625" },
    { lng: -69.38752, lat: 44.927382, postalCode: "4971" },
    { lng: -68.14277, lat: 47.281126, postalCode: "4746" },
    { lng: -70.21933, lat: 44.516475, postalCode: "4239" },
    { lng: -70.17486, lat: 44.470961, postalCode: "4254" },
    { lng: -68.886826, lat: 46.516166, postalCode: "4741" },
    { lng: -68.28298, lat: 44.524526, postalCode: "4640" },
    { lng: -69.818009, lat: 44.315693, postalCode: "4336" },
    { lng: -69.95997, lat: 44.31842, postalCode: "4364" },
    { lng: -69.63516, lat: 43.973476, postalCode: "4556" },
    { lng: -69.87518, lat: 44.306955, postalCode: "4351" },
    { lng: -70.73033, lat: 43.643195, postalCode: "4061" },
    { lng: -69.76345, lat: 44.344406, postalCode: "4330" },
    { lng: -67.87798, lat: 46.12672, postalCode: "4730" },
    { lng: -68.081184, lat: 44.396951, postalCode: "4600" },
    { lng: -68.11398, lat: 46.104734, postalCode: "4763" },
    { lng: -86.12726, lat: 42.823232, postalCode: "49424" },
    { lng: -83.116567, lat: 42.597323, postalCode: "48085" },
    { lng: -83.41927, lat: 44.447318, postalCode: "48750" },
    { lng: -86.0865, lat: 43.595939, postalCode: "49421" },
    { lng: -83.81849, lat: 44.246004, postalCode: "48770" },
    { lng: -82.79758, lat: 42.739751, postalCode: "48048" },
    { lng: -83.65821, lat: 44.06434, postalCode: "48703" },
    { lng: -83.378, lat: 42.335937, postalCode: "48185" },
    { lng: -82.62325, lat: 42.563129, postalCode: "48028" },
    { lng: -85.041872, lat: 45.625353, postalCode: "49723" },
    { lng: -84.92216, lat: 43.402185, postalCode: "48891" },
    { lng: -85.71981, lat: 44.405334, postalCode: "49668" },
    { lng: -85.69255, lat: 42.272538, postalCode: "49009" },
    { lng: -83.938967, lat: 42.757633, postalCode: "48816" },
    { lng: -85.08986, lat: 44.743834, postalCode: "49646" },
    { lng: -83.4856, lat: 42.370837, postalCode: "48170" },
    { lng: -83.79433, lat: 41.798043, postalCode: "49276" },
    { lng: -83.04255, lat: 42.639777, postalCode: "48317" },
    { lng: -84.6579, lat: 42.507093, postalCode: "48827" },
    { lng: -85.83605, lat: 41.787096, postalCode: "49130" },
    { lng: -85.18987, lat: 41.850631, postalCode: "49028" },
    { lng: -83.37161, lat: 42.396194, postalCode: "48154" },
    { lng: -84.33926, lat: 43.410627, postalCode: "48637" },
    { lng: -83.73723, lat: 44.147192, postalCode: "48765" },
    { lng: -83.97901, lat: 43.851276, postalCode: "48650" },
    { lng: -85.62347, lat: 45.107479, postalCode: "49670" },
    { lng: -85.84023, lat: 43.271422, postalCode: "49303" },
    { lng: -82.83063, lat: 42.582961, postalCode: "48045" },
    { lng: -87.0742, lat: 45.987113, postalCode: "49872" },
    { lng: -85.81962, lat: 43.693086, postalCode: "49312" },
    { lng: -84.41116, lat: 42.464769, postalCode: "49251" },
    { lng: -84.65993, lat: 41.731009, postalCode: "49232" },
    { lng: -84.44738, lat: 42.582902, postalCode: "48854" },
    { lng: -85.27959, lat: 44.829056, postalCode: "49676" },
    { lng: -86.75519, lat: 41.784691, postalCode: "49117" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48068" },
    { lng: -83.36513, lat: 42.365936, postalCode: "48150" },
    { lng: -84.09438, lat: 43.40153, postalCode: "48609" },
    { lng: -83.2612, lat: 42.74628, postalCode: "48360" },
    { lng: -83.99148, lat: 42.918766, postalCode: "48429" },
    { lng: -84.84627, lat: 43.096466, postalCode: "48845" },
    { lng: -84.57106, lat: 42.737701, postalCode: "48915" },
    { lng: -82.93416, lat: 42.650265, postalCode: "48044" },
    { lng: -84.77657, lat: 43.411924, postalCode: "48832" },
    { lng: -84.62698, lat: 42.735535, postalCode: "48917" },
    { lng: -85.65435, lat: 42.933077, postalCode: "49507" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48333" },
    { lng: -86.0795, lat: 42.345815, postalCode: "49027" },
    { lng: -85.52611, lat: 44.561602, postalCode: "49649" },
    { lng: -86.90898, lat: 45.926298, postalCode: "49878" },
    { lng: -84.37643, lat: 42.997636, postalCode: "48866" },
    { lng: -82.89979, lat: 42.497736, postalCode: "48081" },
    { lng: -88.14742, lat: 46.54081, postalCode: "49861" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48112" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48398" },
    { lng: -83.89907, lat: 42.344837, postalCode: "48130" },
    { lng: -83.49878, lat: 45.311011, postalCode: "49777" },
    { lng: -85.26096, lat: 44.654872, postalCode: "49680" },
    { lng: -84.79103, lat: 45.631602, postalCode: "49755" },
    { lng: -85.56635, lat: 42.330089, postalCode: "49004" },
    { lng: -85.95837, lat: 46.655567, postalCode: "49839" },
    { lng: -84.975271, lat: 43.413761, postalCode: "48812" },
    { lng: -86.24912, lat: 42.403593, postalCode: "49090" },
    { lng: -83.14969, lat: 42.426786, postalCode: "48221" },
    { lng: -83.3933, lat: 43.154173, postalCode: "48421" },
    { lng: -86.2668, lat: 43.199364, postalCode: "49441" },
    { lng: -84.612207, lat: 45.028411, postalCode: "49734" },
    { lng: -85.98996, lat: 42.679762, postalCode: "49419" },
    { lng: -83.57071, lat: 42.121345, postalCode: "48191" },
    { lng: -84.21162, lat: 44.288158, postalCode: "48661" },
    { lng: -83.363697, lat: 42.675435, postalCode: "48330" },
    { lng: -85.61773, lat: 42.216541, postalCode: "49024" },
    { lng: -85.33059, lat: 41.960856, postalCode: "49040" },
    { lng: -83.60433, lat: 43.020176, postalCode: "48509" },
    { lng: -82.66522, lat: 42.824095, postalCode: "48004" },
    { lng: -83.3516, lat: 44.663477, postalCode: "48740" },
    { lng: -85.37282, lat: 43.368957, postalCode: "49322" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48260" },
    { lng: -83.16812, lat: 42.482585, postalCode: "48070" },
    { lng: -83.13398, lat: 42.460986, postalCode: "48220" },
    { lng: -85.89022, lat: 41.916613, postalCode: "49095" },
    { lng: -84.256758, lat: 43.637471, postalCode: "48670" },
    { lng: -83.15863, lat: 42.306288, postalCode: "48120" },
    { lng: -84.14652, lat: 43.297082, postalCode: "48655" },
    { lng: -83.70977, lat: 44.54684, postalCode: "48737" },
    { lng: -83.47401, lat: 42.289354, postalCode: "48188" },
    { lng: -84.7503, lat: 42.749774, postalCode: "48837" },
    { lng: -86.12461, lat: 42.575519, postalCode: "49408" },
    { lng: -83.72671, lat: 42.232807, postalCode: "48108" },
    { lng: -84.22291, lat: 42.807435, postalCode: "48872" },
    { lng: -85.26394, lat: 43.893894, postalCode: "49631" },
    { lng: -84.94611, lat: 42.962965, postalCode: "48851" },
    { lng: -85.844378, lat: 42.826676, postalCode: "49427" },
    { lng: -84.48228, lat: 46.250359, postalCode: "49752" },
    { lng: -84.20769, lat: 41.853441, postalCode: "49235" },
    { lng: -83.25454, lat: 42.524134, postalCode: "48025" },
    { lng: -83.36299, lat: 42.427835, postalCode: "48152" },
    { lng: -83.93425, lat: 42.455063, postalCode: "48169" },
    { lng: -85.5189, lat: 43.117214, postalCode: "49341" },
    { lng: -85.09307, lat: 42.239592, postalCode: "49033" },
    { lng: -85.67131, lat: 43.889404, postalCode: "49623" },
    { lng: -84.41672, lat: 43.400958, postalCode: "48662" },
    { lng: -86.715294, lat: 42.001551, postalCode: "49121" },
    { lng: -83.35883, lat: 43.34896, postalCode: "48744" },
    { lng: -83.99306, lat: 44.206123, postalCode: "48756" },
    { lng: -83.02017, lat: 43.952534, postalCode: "48445" },
    { lng: -84.7996, lat: 42.928735, postalCode: "48894" },
    { lng: -83.720306, lat: 42.973627, postalCode: "48553" },
    { lng: -85.480856, lat: 43.071201, postalCode: "49317" },
    { lng: -84.19608, lat: 44.468977, postalCode: "48654" },
    { lng: -86.61984, lat: 41.820452, postalCode: "49128" },
    { lng: -83.3464, lat: 42.458502, postalCode: "48336" },
    { lng: -85.643043, lat: 42.633006, postalCode: "49311" },
    { lng: -85.15802, lat: 43.878924, postalCode: "49679" },
    { lng: -85.33499, lat: 42.239393, postalCode: "49034" },
    { lng: -85.29296, lat: 42.64275, postalCode: "49058" },
    { lng: -83.657355, lat: 43.327048, postalCode: "48769" },
    { lng: -85.98588, lat: 44.884904, postalCode: "49636" },
    { lng: -82.50425, lat: 42.833034, postalCode: "48079" },
    { lng: -85.074665, lat: 42.944929, postalCode: "48887" },
    { lng: -83.056, lat: 42.465886, postalCode: "48091" },
    { lng: -84.32615, lat: 45.998074, postalCode: "49719" },
    { lng: -84.82611, lat: 45.423768, postalCode: "49764" },
    { lng: -85.13845, lat: 42.311089, postalCode: "49014" },
    { lng: -83.58982, lat: 42.66415, postalCode: "48356" },
    { lng: -89.57925, lat: 46.754237, postalCode: "49971" },
    { lng: -83.26128, lat: 42.049534, postalCode: "48179" },
    { lng: -84.555629, lat: 42.723735, postalCode: "48921" },
    { lng: -83.24746, lat: 43.736747, postalCode: "48754" },
    { lng: -83.37223, lat: 42.212372, postalCode: "48174" },
    { lng: -83.52283, lat: 42.747677, postalCode: "48350" },
    { lng: -83.40605, lat: 42.05589, postalCode: "48117" },
    { lng: -85.87438, lat: 42.531244, postalCode: "49010" },
    { lng: -82.94459, lat: 42.603172, postalCode: "48038" },
    { lng: -88.57259, lat: 47.11929, postalCode: "49931" },
    { lng: -83.98443, lat: 43.131102, postalCode: "48460" },
    { lng: -83.911173, lat: 42.603479, postalCode: "48844" },
    { lng: -83.22956, lat: 42.586677, postalCode: "48304" },
    { lng: -84.7255, lat: 45.923952, postalCode: "49781" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48321" },
    { lng: -83.37159, lat: 42.572442, postalCode: "48323" },
    { lng: -83.29585, lat: 42.668532, postalCode: "48340" },
    { lng: -82.81411, lat: 43.131709, postalCode: "48097" },
    { lng: -85.57069, lat: 46.156073, postalCode: "49827" },
    { lng: -84.59305, lat: 42.285987, postalCode: "49269" },
    { lng: -82.8092, lat: 42.786198, postalCode: "48050" },
    { lng: -84.27674, lat: 42.552218, postalCode: "48819" },
    { lng: -87.26197, lat: 45.936883, postalCode: "49818" },
    { lng: -88.52645, lat: 46.795417, postalCode: "49908" },
    { lng: -86.29071, lat: 42.022092, postalCode: "49111" },
    { lng: -83.34887, lat: 43.831509, postalCode: "48720" },
    { lng: -83.38515, lat: 41.940898, postalCode: "48162" },
    { lng: -86.26647, lat: 42.296105, postalCode: "49043" },
    { lng: -83.42227, lat: 43.219132, postalCode: "48464" },
    { lng: -84.551728, lat: 42.728585, postalCode: "48919" },
    { lng: -83.04483, lat: 42.379019, postalCode: "48211" },
    { lng: -85.814072, lat: 42.36175, postalCode: "49062" },
    { lng: -86.38349, lat: 41.830012, postalCode: "49107" },
    { lng: -83.83372, lat: 42.454366, postalCode: "48143" },
    { lng: -83.46411, lat: 45.08583, postalCode: "49707" },
    { lng: -84.949919, lat: 42.204494, postalCode: "49069" },
    { lng: -84.4625, lat: 45.589694, postalCode: "49721" },
    { lng: -83.297593, lat: 42.606088, postalCode: "48007" },
    { lng: -85.308555, lat: 42.595121, postalCode: "49035" },
    { lng: -85.527652, lat: 44.515487, postalCode: "49647" },
    { lng: -85.124, lat: 42.588988, postalCode: "49073" },
    { lng: -83.05826, lat: 42.410037, postalCode: "48212" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49502" },
    { lng: -82.88031, lat: 42.596784, postalCode: "48043" },
    { lng: -87.33355, lat: 46.291233, postalCode: "49833" },
    { lng: -83.48997, lat: 42.593035, postalCode: "48382" },
    { lng: -82.9283, lat: 42.438186, postalCode: "48225" },
    { lng: -88.88179, lat: 47.001469, postalCode: "49965" },
    { lng: -87.614805, lat: 46.604141, postalCode: "49819" },
    { lng: -83.37551, lat: 42.541268, postalCode: "48322" },
    { lng: -85.8079, lat: 44.634131, postalCode: "49643" },
    { lng: -85.5155, lat: 43.906666, postalCode: "49677" },
    { lng: -84.55492, lat: 42.731885, postalCode: "48933" },
    { lng: -85.629101, lat: 42.984226, postalCode: "49501" },
    { lng: -85.63829, lat: 41.958306, postalCode: "49093" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49516" },
    { lng: -85.67316, lat: 44.74136, postalCode: "49684" },
    { lng: -84.16581, lat: 42.845703, postalCode: "48857" },
    { lng: -84.86185, lat: 45.206973, postalCode: "49713" },
    { lng: -83.624431, lat: 43.49442, postalCode: "48736" },
    { lng: -84.56153, lat: 42.764167, postalCode: "48906" },
    { lng: -87.04529, lat: 45.847136, postalCode: "49837" },
    { lng: -83.73126, lat: 43.043627, postalCode: "48504" },
    { lng: -87.58988, lat: 46.440274, postalCode: "49871" },
    { lng: -83.173666, lat: 42.587643, postalCode: "48099" },
    { lng: -89.18126, lat: 46.739853, postalCode: "49960" },
    { lng: -84.8228, lat: 42.580076, postalCode: "48813" },
    { lng: -83.66673, lat: 42.45644, postalCode: "48178" },
    { lng: -84.37762, lat: 42.050744, postalCode: "49281" },
    { lng: -84.24575, lat: 42.164338, postalCode: "49261" },
    { lng: -83.646255, lat: 43.032677, postalCode: "48556" },
    { lng: -86.20597, lat: 42.768619, postalCode: "49434" },
    { lng: -85.31026, lat: 42.763599, postalCode: "49325" },
    { lng: -84.32597, lat: 42.230336, postalCode: "49254" },
    { lng: -85.529858, lat: 42.245412, postalCode: "49019" },
    { lng: -84.46144, lat: 42.395023, postalCode: "49277" },
    { lng: -83.87435, lat: 41.821595, postalCode: "49228" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48272" },
    { lng: -83.87869, lat: 43.583323, postalCode: "48708" },
    { lng: -85.88908, lat: 44.44905, postalCode: "49625" },
    { lng: -83.93267, lat: 43.431375, postalCode: "48607" },
    { lng: -83.83626, lat: 45.413252, postalCode: "49779" },
    { lng: -88.65751, lat: 46.830728, postalCode: "49958" },
    { lng: -83.25049, lat: 42.294987, postalCode: "48124" },
    { lng: -83.35363, lat: 42.5065, postalCode: "48334" },
    { lng: -85.40908, lat: 43.84716, postalCode: "49639" },
    { lng: -85.77879, lat: 42.721597, postalCode: "49323" },
    { lng: -85.9679, lat: 42.371729, postalCode: "49026" },
    { lng: -85.67594, lat: 42.117421, postalCode: "49087" },
    { lng: -84.52702, lat: 42.639235, postalCode: "48842" },
    { lng: -83.09981, lat: 42.421936, postalCode: "48203" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48391" },
    { lng: -83.59684, lat: 42.134658, postalCode: "48190" },
    { lng: -85.493232, lat: 42.323045, postalCode: "49005" },
    { lng: -86.64901, lat: 46.191294, postalCode: "49895" },
    { lng: -82.91582, lat: 42.938385, postalCode: "48002" },
    { lng: -85.832785, lat: 44.544624, postalCode: "49582" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48136" },
    { lng: -85.86094, lat: 43.747881, postalCode: "49309" },
    { lng: -85.59318, lat: 43.078615, postalCode: "49306" },
    { lng: -85.1771, lat: 44.204505, postalCode: "49657" },
    { lng: -82.9635, lat: 43.512287, postalCode: "48472" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49599" },
    { lng: -83.12785, lat: 42.338137, postalCode: "48210" },
    { lng: -84.91289, lat: 42.864338, postalCode: "48875" },
    { lng: -85.46428, lat: 42.005815, postalCode: "49072" },
    { lng: -84.77731, lat: 43.713365, postalCode: "48878" },
    { lng: -84.68228, lat: 43.534764, postalCode: "48883" },
    { lng: -85.529858, lat: 42.245412, postalCode: "49003" },
    { lng: -84.49338, lat: 42.118019, postalCode: "49246" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48916" },
    { lng: -84.1337, lat: 43.518309, postalCode: "48623" },
    { lng: -85.0142, lat: 41.912776, postalCode: "49036" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48918" },
    { lng: -85.68623, lat: 44.516368, postalCode: "49620" },
    { lng: -83.96071, lat: 45.068394, postalCode: "49746" },
    { lng: -82.81674, lat: 43.264205, postalCode: "48466" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48266" },
    { lng: -86.468853, lat: 43.295493, postalCode: "49463" },
    { lng: -85.16591, lat: 46.340461, postalCode: "49748" },
    { lng: -83.688706, lat: 43.034927, postalCode: "48550" },
    { lng: -83.83539, lat: 44.379147, postalCode: "48739" },
    { lng: -83.749418, lat: 43.334958, postalCode: "48787" },
    { lng: -84.06078, lat: 42.445724, postalCode: "48137" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48258" },
    { lng: -88.63936, lat: 47.069257, postalCode: "49963" },
    { lng: -84.97543, lat: 42.09724, postalCode: "49092" },
    { lng: -83.676156, lat: 43.407321, postalCode: "48758" },
    { lng: -83.57793, lat: 41.773203, postalCode: "48182" },
    { lng: -83.91915, lat: 44.54106, postalCode: "48761" },
    { lng: -88.38434, lat: 47.283086, postalCode: "49917" },
    { lng: -83.24571, lat: 42.661129, postalCode: "48326" },
    { lng: -82.99314, lat: 42.368137, postalCode: "48214" },
    { lng: -84.295949, lat: 42.803973, postalCode: "48882" },
    { lng: -83.24902, lat: 42.425236, postalCode: "48219" },
    { lng: -84.18971, lat: 42.284771, postalCode: "49240" },
    { lng: -83.74074, lat: 42.78459, postalCode: "48430" },
    { lng: -85.62761, lat: 42.681969, postalCode: "49348" },
    { lng: -88.17166, lat: 46.877, postalCode: "49962" },
    { lng: -85.98937, lat: 41.907837, postalCode: "49031" },
    { lng: -83.59318, lat: 42.571402, postalCode: "48381" },
    { lng: -83.75725, lat: 42.566097, postalCode: "48114" },
    { lng: -83.03847, lat: 42.727731, postalCode: "48094" },
    { lng: -86.11684, lat: 43.945299, postalCode: "49458" },
    { lng: -86.99621, lat: 46.364395, postalCode: "49826" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48244" },
    { lng: -84.98244, lat: 43.662482, postalCode: "48893" },
    { lng: -85.75597, lat: 44.30192, postalCode: "49638" },
    { lng: -85.82566, lat: 43.34022, postalCode: "49327" },
    { lng: -82.99632, lat: 42.395904, postalCode: "48213" },
    { lng: -82.88809, lat: 42.526965, postalCode: "48082" },
    { lng: -83.75198, lat: 45.994672, postalCode: "49726" },
    { lng: -85.47815, lat: 43.697651, postalCode: "49307" },
    { lng: -83.89507, lat: 43.182851, postalCode: "48457" },
    { lng: -83.58184, lat: 41.876895, postalCode: "48140" },
    { lng: -86.02616, lat: 41.797792, postalCode: "49112" },
    { lng: -82.71234, lat: 43.519997, postalCode: "48427" },
    { lng: -83.3786, lat: 42.276688, postalCode: "48184" },
    { lng: -83.655654, lat: 42.993102, postalCode: "48567" },
    { lng: -85.713857, lat: 44.321979, postalCode: "49368" },
    { lng: -83.25987, lat: 42.277388, postalCode: "48125" },
    { lng: -84.58612, lat: 42.842438, postalCode: "48820" },
    { lng: -87.58245, lat: 46.517192, postalCode: "49866" },
    { lng: -85.23198, lat: 42.010485, postalCode: "49089" },
    { lng: -83.09901, lat: 42.581148, postalCode: "48803" },
    { lng: -83.10474, lat: 42.501385, postalCode: "48071" },
    { lng: -83.98795, lat: 44.718279, postalCode: "48621" },
    { lng: -85.46466, lat: 42.689437, postalCode: "49333" },
    { lng: -89.57618, lat: 46.576653, postalCode: "49910" },
    { lng: -82.95243, lat: 42.378287, postalCode: "48215" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48347" },
    { lng: -83.61267, lat: 43.542822, postalCode: "48733" },
    { lng: -85.98878, lat: 46.460628, postalCode: "49883" },
    { lng: -82.90232, lat: 42.462036, postalCode: "48080" },
    { lng: -85.62247, lat: 42.295005, postalCode: "49006" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49468" },
    { lng: -84.09154, lat: 42.035005, postalCode: "49287" },
    { lng: -84.73826, lat: 42.640719, postalCode: "48876" },
    { lng: -86.37004, lat: 43.607628, postalCode: "49455" },
    { lng: -83.32907, lat: 44.79652, postalCode: "48721" },
    { lng: -84.76379, lat: 42.8288, postalCode: "48822" },
    { lng: -83.11506, lat: 42.556943, postalCode: "48083" },
    { lng: -83.28332, lat: 42.335687, postalCode: "48127" },
    { lng: -82.60984, lat: 43.66139, postalCode: "48434" },
    { lng: -85.840372, lat: 42.731193, postalCode: "49314" },
    { lng: -83.780835, lat: 42.965926, postalCode: "48501" },
    { lng: -83.63297, lat: 42.656281, postalCode: "48357" },
    { lng: -85.51068, lat: 41.924331, postalCode: "49032" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49518" },
    { lng: -85.61256, lat: 42.462935, postalCode: "49080" },
    { lng: -83.67085, lat: 42.972477, postalCode: "48529" },
    { lng: -84.90913, lat: 43.272279, postalCode: "48818" },
    { lng: -86.209904, lat: 42.393586, postalCode: "49063" },
    { lng: -83.02963, lat: 42.826805, postalCode: "48065" },
    { lng: -84.92633, lat: 43.026842, postalCode: "48860" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49560" },
    { lng: -83.22829, lat: 42.499335, postalCode: "48076" },
    { lng: -86.43044, lat: 43.967306, postalCode: "49431" },
    { lng: -85.41696, lat: 44.774285, postalCode: "49690" },
    { lng: -85.950661, lat: 44.933988, postalCode: "49564" },
    { lng: -86.47935, lat: 42.074435, postalCode: "49085" },
    { lng: -83.30197, lat: 42.426285, postalCode: "48240" },
    { lng: -83.67133, lat: 41.965806, postalCode: "48131" },
    { lng: -83.348152, lat: 42.712944, postalCode: "48246" },
    { lng: -85.90002, lat: 42.22514, postalCode: "49079" },
    { lng: -83.21527, lat: 42.544084, postalCode: "48009" },
    { lng: -84.336519, lat: 46.410777, postalCode: "49784" },
    { lng: -83.82612, lat: 42.950128, postalCode: "48473" },
    { lng: -86.04382, lat: 44.643593, postalCode: "49617" },
    { lng: -82.68571, lat: 42.952553, postalCode: "48027" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48913" },
    { lng: -86.23981, lat: 41.828055, postalCode: "49120" },
    { lng: -85.7807, lat: 42.234417, postalCode: "49071" },
    { lng: -85.24478, lat: 43.085313, postalCode: "48809" },
    { lng: -86.21509, lat: 44.494139, postalCode: "49613" },
    { lng: -83.13471, lat: 42.936145, postalCode: "48428" },
    { lng: -84.213755, lat: 42.252847, postalCode: "49204" },
    { lng: -85.04876, lat: 43.523037, postalCode: "49310" },
    { lng: -84.46503, lat: 44.041529, postalCode: "48624" },
    { lng: -83.710756, lat: 43.011277, postalCode: "48555" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49514" },
    { lng: -84.97463, lat: 44.233529, postalCode: "49632" },
    { lng: -84.60141, lat: 45.268255, postalCode: "49799" },
    { lng: -82.57049, lat: 42.948521, postalCode: "48074" },
    { lng: -83.18405, lat: 42.563505, postalCode: "48084" },
    { lng: -82.9257, lat: 43.673699, postalCode: "48475" },
    { lng: -83.17248, lat: 43.410781, postalCode: "48741" },
    { lng: -83.13676, lat: 42.269288, postalCode: "48218" },
    { lng: -83.51766, lat: 42.467945, postalCode: "48374" },
    { lng: -83.47575, lat: 42.33087, postalCode: "48187" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48274" },
    { lng: -84.8376, lat: 41.776287, postalCode: "49255" },
    { lng: -83.41669, lat: 41.904793, postalCode: "48161" },
    { lng: -84.01395, lat: 41.766891, postalCode: "49248" },
    { lng: -84.30074, lat: 45.354677, postalCode: "49792" },
    { lng: -83.82631, lat: 41.991654, postalCode: "49229" },
    { lng: -84.09996, lat: 41.770793, postalCode: "49289" },
    { lng: -84.32485, lat: 46.460895, postalCode: "49783" },
    { lng: -84.84062, lat: 43.403224, postalCode: "48877" },
    { lng: -83.16584, lat: 42.708151, postalCode: "48306" },
    { lng: -85.89914, lat: 44.888566, postalCode: "49664" },
    { lng: -85.14998, lat: 43.426703, postalCode: "48886" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49555" },
    { lng: -84.97282, lat: 44.976915, postalCode: "49611" },
    { lng: -83.62016, lat: 43.111009, postalCode: "48437" },
    { lng: -85.5073, lat: 42.117028, postalCode: "49097" },
    { lng: -85.24516, lat: 45.294169, postalCode: "49720" },
    { lng: -85.03033, lat: 43.404412, postalCode: "48829" },
    { lng: -85.75371, lat: 42.999561, postalCode: "49544" },
    { lng: -83.47314, lat: 42.511766, postalCode: "48377" },
    { lng: -83.98087, lat: 45.992931, postalCode: "49725" },
    { lng: -83.54756, lat: 42.01968, postalCode: "48159" },
    { lng: -84.99025, lat: 45.502308, postalCode: "49740" },
    { lng: -84.73671, lat: 46.204512, postalCode: "49780" },
    { lng: -83.21575, lat: 42.073793, postalCode: "48173" },
    { lng: -83.48516, lat: 44.335904, postalCode: "48730" },
    { lng: -83.28537, lat: 42.722932, postalCode: "48359" },
    { lng: -86.372276, lat: 42.224757, postalCode: "49039" },
    { lng: -82.94348, lat: 42.410487, postalCode: "48224" },
    { lng: -85.3651, lat: 45.000254, postalCode: "49648" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48279" },
    { lng: -86.05421, lat: 43.930995, postalCode: "49402" },
    { lng: -84.66089, lat: 44.484145, postalCode: "48653" },
    { lng: -83.40273, lat: 42.642764, postalCode: "48327" },
    { lng: -85.81527, lat: 41.878312, postalCode: "49061" },
    { lng: -83.13752, lat: 42.488735, postalCode: "48067" },
    { lng: -83.80332, lat: 42.449117, postalCode: "48139" },
    { lng: -84.94869, lat: 42.277837, postalCode: "49068" },
    { lng: -83.71831, lat: 42.291637, postalCode: "48109" },
    { lng: -84.59578, lat: 45.418813, postalCode: "49749" },
    { lng: -82.982757, lat: 42.516851, postalCode: "48088" },
    { lng: -83.28557, lat: 43.85672, postalCode: "48755" },
    { lng: -86.14028, lat: 44.016249, postalCode: "49410" },
    { lng: -85.69759, lat: 44.05505, postalCode: "49656" },
    { lng: -83.83882, lat: 44.746486, postalCode: "48728" },
    { lng: -89.02038, lat: 46.730077, postalCode: "49948" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48267" },
    { lng: -84.6253, lat: 44.683274, postalCode: "49738" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49530" },
    { lng: -83.97973, lat: 43.67579, postalCode: "48631" },
    { lng: -83.44141, lat: 41.811196, postalCode: "48157" },
    { lng: -84.47433, lat: 43.422568, postalCode: "48615" },
    { lng: -83.933337, lat: 43.737798, postalCode: "48707" },
    { lng: -84.04695, lat: 44.825431, postalCode: "48619" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48909" },
    { lng: -84.41579, lat: 43.145475, postalCode: "48807" },
    { lng: -83.36195, lat: 43.239123, postalCode: "48435" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48151" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48901" },
    { lng: -83.95884, lat: 42.816358, postalCode: "48418" },
    { lng: -85.75697, lat: 45.023384, postalCode: "49654" },
    { lng: -82.80345, lat: 42.93281, postalCode: "48041" },
    { lng: -84.15477, lat: 46.064734, postalCode: "49736" },
    { lng: -85.61168, lat: 43.022199, postalCode: "49525" },
    { lng: -83.00241, lat: 44.023181, postalCode: "48467" },
    { lng: -83.26872, lat: 42.320237, postalCode: "48128" },
    { lng: -86.90766, lat: 46.323845, postalCode: "49816" },
    { lng: -84.45633, lat: 46.267303, postalCode: "49788" },
    { lng: -86.55894, lat: 45.769159, postalCode: "49835" },
    { lng: -86.25718, lat: 42.186679, postalCode: "49098" },
    { lng: -83.40875, lat: 42.508809, postalCode: "48331" },
    { lng: -83.14416, lat: 42.838438, postalCode: "48367" },
    { lng: -85.09604, lat: 43.617313, postalCode: "49340" },
    { lng: -83.04844, lat: 42.609789, postalCode: "48314" },
    { lng: -82.94641, lat: 42.466086, postalCode: "48021" },
    { lng: -87.72709, lat: 46.458207, postalCode: "49849" },
    { lng: -84.25886, lat: 42.371819, postalCode: "49259" },
    { lng: -84.46099, lat: 42.821233, postalCode: "48808" },
    { lng: -82.65433, lat: 43.357999, postalCode: "48401" },
    { lng: -86.21149, lat: 44.365757, postalCode: "49675" },
    { lng: -83.33757, lat: 42.611783, postalCode: "48320" },
    { lng: -88.37867, lat: 46.766303, postalCode: "49946" },
    { lng: -84.847417, lat: 43.640574, postalCode: "48804" },
    { lng: -84.87559, lat: 41.943472, postalCode: "49082" },
    { lng: -85.25883, lat: 43.623053, postalCode: "49332" },
    { lng: -85.87861, lat: 42.873302, postalCode: "49426" },
    { lng: -82.69274, lat: 43.812371, postalCode: "48441" },
    { lng: -85.92846, lat: 44.105814, postalCode: "49644" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48086" },
    { lng: -82.79404, lat: 43.013805, postalCode: "48022" },
    { lng: -84.336519, lat: 46.410777, postalCode: "49786" },
    { lng: -83.33073, lat: 43.048777, postalCode: "48446" },
    { lng: -84.651, lat: 42.177223, postalCode: "49237" },
    { lng: -83.68564, lat: 43.462775, postalCode: "48757" },
    { lng: -83.692507, lat: 43.002, postalCode: "48559" },
    { lng: -84.0634, lat: 43.000193, postalCode: "48817" },
    { lng: -83.37464, lat: 42.293787, postalCode: "48186" },
    { lng: -84.186608, lat: 41.79583, postalCode: "49280" },
    { lng: -84.46824, lat: 41.963683, postalCode: "49262" },
    { lng: -83.84369, lat: 44.127342, postalCode: "48766" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48233" },
    { lng: -88.01972, lat: 46.345957, postalCode: "49879" },
    { lng: -83.87572, lat: 44.046475, postalCode: "48749" },
    { lng: -83.5968, lat: 42.800191, postalCode: "48442" },
    { lng: -83.78009, lat: 42.422746, postalCode: "48189" },
    { lng: -83.29078, lat: 42.474234, postalCode: "48034" },
    { lng: -82.92958, lat: 42.68347, postalCode: "48042" },
    { lng: -84.77405, lat: 44.327064, postalCode: "48630" },
    { lng: -84.387753, lat: 43.538252, postalCode: "48641" },
    { lng: -84.336519, lat: 46.410777, postalCode: "49778" },
    { lng: -85.76466, lat: 42.891709, postalCode: "49418" },
    { lng: -85.11754, lat: 43.090038, postalCode: "48865" },
    { lng: -83.54529, lat: 42.657361, postalCode: "48383" },
    { lng: -85.01188, lat: 42.63222, postalCode: "49096" },
    { lng: -83.04585, lat: 42.931178, postalCode: "48003" },
    { lng: -85.72325, lat: 44.975051, postalCode: "49653" },
    { lng: -88.73659, lat: 46.469552, postalCode: "49961" },
    { lng: -83.94468, lat: 42.988946, postalCode: "48449" },
    { lng: -86.11462, lat: 43.733491, postalCode: "49459" },
    { lng: -83.18188, lat: 42.281638, postalCode: "48122" },
    { lng: -84.17896, lat: 42.99605, postalCode: "48867" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48288" },
    { lng: -84.18274, lat: 42.158739, postalCode: "49263" },
    { lng: -85.11843, lat: 44.095357, postalCode: "49665" },
    { lng: -83.69012, lat: 45.248956, postalCode: "49776" },
    { lng: -86.19345, lat: 43.08767, postalCode: "49456" },
    { lng: -84.55243, lat: 42.203838, postalCode: "49283" },
    { lng: -86.12201, lat: 42.308798, postalCode: "49013" },
    { lng: -83.664277, lat: 42.008164, postalCode: "48110" },
    { lng: -83.16357, lat: 42.518635, postalCode: "48073" },
    { lng: -83.57917, lat: 43.370434, postalCode: "48768" },
    { lng: -84.747, lat: 44.462149, postalCode: "48627" },
    { lng: -87.06843, lat: 46.470986, postalCode: "49822" },
    { lng: -82.93851, lat: 42.503285, postalCode: "48066" },
    { lng: -85.78716, lat: 44.872464, postalCode: "49621" },
    { lng: -83.78699, lat: 41.910884, postalCode: "49238" },
    { lng: -84.50765, lat: 43.179346, postalCode: "48806" },
    { lng: -85.96376, lat: 43.200998, postalCode: "49451" },
    { lng: -85.78999, lat: 43.880898, postalCode: "49642" },
    { lng: -84.4792, lat: 42.725884, postalCode: "48824" },
    { lng: -86.26909, lat: 41.949244, postalCode: "49102" },
    { lng: -83.38785, lat: 42.128069, postalCode: "48164" },
    { lng: -83.713124, lat: 42.978995, postalCode: "48551" },
    { lng: -82.67684, lat: 42.773535, postalCode: "48064" },
    { lng: -84.33789, lat: 43.807162, postalCode: "48628" },
    { lng: -83.915592, lat: 42.678059, postalCode: "48855" },
    { lng: -83.72297, lat: 42.266805, postalCode: "48104" },
    { lng: -85.394202, lat: 44.851771, postalCode: "49590" },
    { lng: -83.20549, lat: 43.210744, postalCode: "48461" },
    { lng: -83.16651, lat: 43.053648, postalCode: "48412" },
    { lng: -85.5614, lat: 44.635328, postalCode: "49666" },
    { lng: -82.6874, lat: 43.618879, postalCode: "48465" },
    { lng: -83.08994, lat: 42.348937, postalCode: "48208" },
    { lng: -83.040222, lat: 42.491736, postalCode: "48397" },
    { lng: -86.13348, lat: 43.135994, postalCode: "49415" },
    { lng: -82.98356, lat: 43.214645, postalCode: "48416" },
    { lng: -83.53468, lat: 43.587817, postalCode: "48701" },
    { lng: -84.6032, lat: 43.184467, postalCode: "48874" },
    { lng: -87.08951, lat: 45.751671, postalCode: "49829" },
    { lng: -85.69952, lat: 43.161106, postalCode: "49345" },
    { lng: -85.29136, lat: 42.34381, postalCode: "49016" },
    { lng: -82.89837, lat: 42.587117, postalCode: "48036" },
    { lng: -85.35683, lat: 42.037422, postalCode: "49066" },
    { lng: -85.35242, lat: 42.347289, postalCode: "49012" },
    { lng: -85.04326, lat: 43.355304, postalCode: "48852" },
    { lng: -86.50367, lat: 41.801264, postalCode: "49113" },
    { lng: -83.74123, lat: 43.340413, postalCode: "48734" },
    { lng: -84.36382, lat: 42.121774, postalCode: "49234" },
    { lng: -85.70392, lat: 42.905877, postalCode: "49509" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49355" },
    { lng: -84.09811, lat: 45.433394, postalCode: "49759" },
    { lng: -83.537887, lat: 42.523233, postalCode: "48396" },
    { lng: -85.95005, lat: 43.464943, postalCode: "49412" },
    { lng: -85.5824, lat: 42.588087, postalCode: "49344" },
    { lng: -84.46507, lat: 45.758378, postalCode: "49775" },
    { lng: -84.03166, lat: 42.939379, postalCode: "48476" },
    { lng: -83.28211, lat: 42.644581, postalCode: "48342" },
    { lng: -82.74685, lat: 43.660079, postalCode: "48456" },
    { lng: -83.02605, lat: 42.779239, postalCode: "48095" },
    { lng: -83.20932, lat: 42.820082, postalCode: "48370" },
    { lng: -84.12889, lat: 43.93724, postalCode: "48613" },
    { lng: -82.91471, lat: 42.842554, postalCode: "48005" },
    { lng: -86.40265, lat: 43.551783, postalCode: "49446" },
    { lng: -83.81366, lat: 42.800911, postalCode: "48451" },
    { lng: -82.98068, lat: 42.433703, postalCode: "48205" },
    { lng: -83.49083, lat: 42.194865, postalCode: "48111" },
    { lng: -85.85725, lat: 42.37346, postalCode: "49055" },
    { lng: -83.2264, lat: 42.465275, postalCode: "48075" },
    { lng: -83.19547, lat: 42.427636, postalCode: "48235" },
    { lng: -85.32984, lat: 41.854639, postalCode: "49030" },
    { lng: -85.69733, lat: 42.977925, postalCode: "49504" },
    { lng: -84.600723, lat: 42.944209, postalCode: "48833" },
    { lng: -84.14518, lat: 45.016358, postalCode: "49709" },
    { lng: -85.67384, lat: 43.059661, postalCode: "49321" },
    { lng: -85.20938, lat: 42.929829, postalCode: "48881" },
    { lng: -83.77718, lat: 42.509476, postalCode: "48116" },
    { lng: -84.10866, lat: 41.791959, postalCode: "49279" },
    { lng: -84.75263, lat: 41.843254, postalCode: "49274" },
    { lng: -84.84871, lat: 43.184647, postalCode: "48811" },
    { lng: -84.74219, lat: 43.828266, postalCode: "48617" },
    { lng: -84.02506, lat: 44.080925, postalCode: "48659" },
    { lng: -84.83959, lat: 44.052759, postalCode: "48625" },
    { lng: -84.79415, lat: 43.293977, postalCode: "48889" },
    { lng: -84.558679, lat: 42.732535, postalCode: "48956" },
    { lng: -85.75809, lat: 42.634199, postalCode: "49328" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48254" },
    { lng: -88.6719, lat: 47.034828, postalCode: "49955" },
    { lng: -83.66579, lat: 44.328794, postalCode: "48748" },
    { lng: -83.12781, lat: 42.662293, postalCode: "48307" },
    { lng: -84.45404, lat: 41.832213, postalCode: "49271" },
    { lng: -85.113678, lat: 45.580183, postalCode: "49737" },
    { lng: -85.21448, lat: 44.970267, postalCode: "49615" },
    { lng: -84.43175, lat: 44.963659, postalCode: "49751" },
    { lng: -84.405367, lat: 46.181451, postalCode: "49785" },
    { lng: -85.72148, lat: 46.191702, postalCode: "49820" },
    { lng: -83.93628, lat: 43.985129, postalCode: "48658" },
    { lng: -84.39027, lat: 42.760217, postalCode: "48840" },
    { lng: -85.71737, lat: 46.095949, postalCode: "49838" },
    { lng: -83.63316, lat: 42.223482, postalCode: "48197" },
    { lng: -84.80789, lat: 42.147222, postalCode: "49245" },
    { lng: -85.63686, lat: 42.540492, postalCode: "49070" },
    { lng: -85.43234, lat: 41.822244, postalCode: "49091" },
    { lng: -83.70443, lat: 42.307288, postalCode: "48105" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48277" },
    { lng: -86.45869, lat: 45.921508, postalCode: "49817" },
    { lng: -88.46121, lat: 47.239083, postalCode: "49913" },
    { lng: -83.994328, lat: 43.556574, postalCode: "48710" },
    { lng: -84.67492, lat: 44.989608, postalCode: "49735" },
    { lng: -85.12449, lat: 42.705285, postalCode: "48897" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48123" },
    { lng: -83.579334, lat: 41.807647, postalCode: "48177" },
    { lng: -83.76529, lat: 43.519522, postalCode: "48747" },
    { lng: -84.558679, lat: 42.732535, postalCode: "48930" },
    { lng: -85.488449, lat: 44.789382, postalCode: "49610" },
    { lng: -83.03213, lat: 43.336126, postalCode: "48453" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49356" },
    { lng: -87.533429, lat: 46.07199, postalCode: "49869" },
    { lng: -88.40806, lat: 47.322703, postalCode: "49805" },
    { lng: -85.09728, lat: 42.142445, postalCode: "49029" },
    { lng: -87.07647, lat: 45.778066, postalCode: "49894" },
    { lng: -84.52405, lat: 45.563799, postalCode: "49761" },
    { lng: -83.26481, lat: 42.231788, postalCode: "48180" },
    { lng: -84.21557, lat: 41.746349, postalCode: "49256" },
    { lng: -84.99249, lat: 46.355548, postalCode: "49728" },
    { lng: -85.800884, lat: 43.554311, postalCode: "49413" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49351" },
    { lng: -85.9963, lat: 46.000666, postalCode: "49840" },
    { lng: -83.79463, lat: 42.972244, postalCode: "48554" },
    { lng: -83.5218, lat: 43.029526, postalCode: "48423" },
    { lng: -85.004511, lat: 42.246402, postalCode: "49018" },
    { lng: -84.07918, lat: 43.617796, postalCode: "48611" },
    { lng: -83.23974, lat: 43.944466, postalCode: "48725" },
    { lng: -83.93858, lat: 41.869086, postalCode: "49268" },
    { lng: -84.14497, lat: 44.142282, postalCode: "48610" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48264" },
    { lng: -82.554, lat: 42.631359, postalCode: "48001" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48361" },
    { lng: -85.45022, lat: 42.379689, postalCode: "49083" },
    { lng: -85.88157, lat: 43.895264, postalCode: "49304" },
    { lng: -83.18097, lat: 42.243488, postalCode: "48146" },
    { lng: -83.79486, lat: 43.268788, postalCode: "48415" },
    { lng: -84.47898, lat: 45.355074, postalCode: "49705" },
    { lng: -85.16587, lat: 43.247555, postalCode: "48885" },
    { lng: -83.31414, lat: 42.292582, postalCode: "48141" },
    { lng: -84.66256, lat: 43.377113, postalCode: "48801" },
    { lng: -86.07346, lat: 43.091183, postalCode: "49448" },
    { lng: -85.004511, lat: 42.246402, postalCode: "49020" },
    { lng: -83.43007, lat: 43.737971, postalCode: "48759" },
    { lng: -86.715294, lat: 42.001551, postalCode: "49104" },
    { lng: -85.529858, lat: 42.245412, postalCode: "49077" },
    { lng: -83.11467, lat: 42.307088, postalCode: "48209" },
    { lng: -85.84983, lat: 43.125652, postalCode: "49403" },
    { lng: -84.43815, lat: 44.360384, postalCode: "48656" },
    { lng: -84.64772, lat: 42.645985, postalCode: "48821" },
    { lng: -85.43184, lat: 43.494361, postalCode: "49336" },
    { lng: -86.38557, lat: 42.178035, postalCode: "49084" },
    { lng: -86.30604, lat: 43.708002, postalCode: "49420" },
    { lng: -86.27234, lat: 44.234322, postalCode: "49660" },
    { lng: -82.50811, lat: 42.710445, postalCode: "48039" },
    { lng: -84.56022, lat: 42.443387, postalCode: "49264" },
    { lng: -82.903065, lat: 42.672322, postalCode: "48311" },
    { lng: -84.75151, lat: 42.261257, postalCode: "49224" },
    { lng: -83.50181, lat: 44.821813, postalCode: "48762" },
    { lng: -84.691207, lat: 45.430705, postalCode: "49717" },
    { lng: -88.4818, lat: 47.122751, postalCode: "49922" },
    { lng: -84.43525, lat: 46.33614, postalCode: "49724" },
    { lng: -83.713074, lat: 42.977895, postalCode: "48552" },
    { lng: -84.01217, lat: 43.748816, postalCode: "48634" },
    { lng: -85.12252, lat: 45.1379, postalCode: "49727" },
    { lng: -85.37833, lat: 43.587286, postalCode: "49346" },
    { lng: -85.71069, lat: 42.459989, postalCode: "49078" },
    { lng: -86.2662, lat: 44.100701, postalCode: "49411" },
    { lng: -83.16017, lat: 42.130091, postalCode: "48138" },
    { lng: -85.22294, lat: 42.30244, postalCode: "49015" },
    { lng: -86.015026, lat: 44.30476, postalCode: "49169" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48153" },
    { lng: -84.39902, lat: 42.050628, postalCode: "49282" },
    { lng: -83.0597, lat: 42.511293, postalCode: "48092" },
    { lng: -84.72353, lat: 45.515594, postalCode: "49716" },
    { lng: -83.81298, lat: 43.605861, postalCode: "48732" },
    { lng: -84.22384, lat: 43.416335, postalCode: "48626" },
    { lng: -84.17011, lat: 42.643684, postalCode: "48892" },
    { lng: -83.1918, lat: 42.388786, postalCode: "48227" },
    { lng: -83.63047, lat: 41.754298, postalCode: "48144" },
    { lng: -86.05033, lat: 44.701761, postalCode: "49640" },
    { lng: -86.19014, lat: 43.907681, postalCode: "49405" },
    { lng: -85.82314, lat: 42.904275, postalCode: "49428" },
    { lng: -82.68244, lat: 42.85452, postalCode: "48063" },
    { lng: -85.04948, lat: 43.141649, postalCode: "48834" },
    { lng: -84.646, lat: 44.258616, postalCode: "48651" },
    { lng: -86.67044, lat: 41.848782, postalCode: "49116" },
    { lng: -85.65885, lat: 42.964176, postalCode: "49503" },
    { lng: -82.52595, lat: 42.769956, postalCode: "48054" },
    { lng: -85.55339, lat: 45.739114, postalCode: "49782" },
    { lng: -85.82081, lat: 43.60034, postalCode: "49394" },
    { lng: -83.61155, lat: 42.984777, postalCode: "48519" },
    { lng: -83.02449, lat: 42.352837, postalCode: "48207" },
    { lng: -84.59227, lat: 45.48586, postalCode: "49791" },
    { lng: -83.18412, lat: 42.803277, postalCode: "48366" },
    { lng: -82.903065, lat: 42.672322, postalCode: "48046" },
    { lng: -83.474785, lat: 42.470984, postalCode: "48376" },
    { lng: -84.23956, lat: 45.354463, postalCode: "49765" },
    { lng: -84.33219, lat: 46.165132, postalCode: "49774" },
    { lng: -83.05879, lat: 42.343787, postalCode: "48201" },
    { lng: -85.09913, lat: 46.659548, postalCode: "49768" },
    { lng: -84.72714, lat: 45.773926, postalCode: "49701" },
    { lng: -83.02449, lat: 42.479137, postalCode: "48015" },
    { lng: -84.84263, lat: 42.987795, postalCode: "48873" },
    { lng: -83.28083, lat: 42.546445, postalCode: "48301" },
    { lng: -84.42078, lat: 43.87787, postalCode: "48612" },
    { lng: -84.37785, lat: 43.807728, postalCode: "48620" },
    { lng: -83.536923, lat: 42.940019, postalCode: "48411" },
    { lng: -85.66543, lat: 42.739942, postalCode: "49335" },
    { lng: -83.99444, lat: 44.399711, postalCode: "48635" },
    { lng: -85.11971, lat: 42.06137, postalCode: "49094" },
    { lng: -86.41801, lat: 42.10809, postalCode: "49022" },
    { lng: -84.86774, lat: 45.039542, postalCode: "49730" },
    { lng: -83.58215, lat: 42.247039, postalCode: "48198" },
    { lng: -85.525359, lat: 42.292335, postalCode: "49048" },
    { lng: -84.40913, lat: 42.227536, postalCode: "49203" },
    { lng: -84.19641, lat: 43.862479, postalCode: "48652" },
    { lng: -83.292382, lat: 42.604425, postalCode: "48012" },
    { lng: -83.28711, lat: 42.107026, postalCode: "48134" },
    { lng: -83.29705, lat: 42.5863, postalCode: "48302" },
    { lng: -82.77538, lat: 42.829226, postalCode: "48062" },
    { lng: -89.0028, lat: 46.459705, postalCode: "49967" },
    { lng: -85.5616, lat: 42.888821, postalCode: "49512" },
    { lng: -84.44772, lat: 41.733173, postalCode: "49288" },
    { lng: -85.25628, lat: 43.447625, postalCode: "48850" },
    { lng: -86.27335, lat: 43.520576, postalCode: "49452" },
    { lng: -83.418524, lat: 41.885451, postalCode: "48168" },
    { lng: -83.48945, lat: 42.425506, postalCode: "48167" },
    { lng: -84.07542, lat: 42.666646, postalCode: "48836" },
    { lng: -82.77754, lat: 42.670932, postalCode: "48047" },
    { lng: -88.14768, lat: 47.400603, postalCode: "49950" },
    { lng: -86.22774, lat: 43.198048, postalCode: "49444" },
    { lng: -83.17402, lat: 42.199989, postalCode: "48192" },
    { lng: -83.43109, lat: 42.84391, postalCode: "48462" },
    { lng: -85.63635, lat: 44.993085, postalCode: "49682" },
    { lng: -82.911243, lat: 42.569278, postalCode: "48305" },
    { lng: -88.41589, lat: 47.269659, postalCode: "49942" },
    { lng: -89.09762, lat: 46.780926, postalCode: "49929" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49523" },
    { lng: -84.032612, lat: 43.348535, postalCode: "48608" },
    { lng: -82.945483, lat: 43.558856, postalCode: "48410" },
    { lng: -86.16943, lat: 42.19923, postalCode: "49057" },
    { lng: -83.32459, lat: 44.563997, postalCode: "48738" },
    { lng: -86.18724, lat: 42.668174, postalCode: "49453" },
    { lng: -82.92426, lat: 42.386087, postalCode: "48230" },
    { lng: -84.47143, lat: 42.747922, postalCode: "48823" },
    { lng: -83.53822, lat: 43.274498, postalCode: "48746" },
    { lng: -86.20404, lat: 42.643315, postalCode: "49406" },
    { lng: -83.16143, lat: 43.83014, postalCode: "48731" },
    { lng: -84.4396, lat: 42.048979, postalCode: "49249" },
    { lng: -84.86734, lat: 43.840787, postalCode: "48622" },
    { lng: -83.1552, lat: 42.277738, postalCode: "48217" },
    { lng: -84.66502, lat: 46.411782, postalCode: "49715" },
    { lng: -88.52201, lat: 46.559834, postalCode: "49919" },
    { lng: -84.71727, lat: 44.809476, postalCode: "49733" },
    { lng: -83.39853, lat: 42.465142, postalCode: "48335" },
    { lng: -85.575147, lat: 44.816178, postalCode: "49685" },
    { lng: -89.34434, lat: 46.555946, postalCode: "49925" },
    { lng: -83.04888, lat: 42.331821, postalCode: "48226" },
    { lng: -85.20257, lat: 42.35714, postalCode: "49017" },
    { lng: -83.726, lat: 43.176375, postalCode: "48420" },
    { lng: -85.36212, lat: 45.107683, postalCode: "49627" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48299" },
    { lng: -86.3337, lat: 42.209307, postalCode: "49038" },
    { lng: -84.23568, lat: 43.104499, postalCode: "48841" },
    { lng: -85.617824, lat: 42.171777, postalCode: "49081" },
    { lng: -83.911693, lat: 42.160216, postalCode: "48115" },
    { lng: -83.865674, lat: 41.988026, postalCode: "49275" },
    { lng: -84.610441, lat: 44.682996, postalCode: "49739" },
    { lng: -85.82054, lat: 43.22724, postalCode: "49318" },
    { lng: -84.361116, lat: 46.158139, postalCode: "49974" },
    { lng: -85.54758, lat: 46.434201, postalCode: "49868" },
    { lng: -84.558679, lat: 42.732535, postalCode: "48929" },
    { lng: -84.91649, lat: 44.335387, postalCode: "49667" },
    { lng: -84.966792, lat: 46.387377, postalCode: "49790" },
    { lng: -85.976841, lat: 42.379469, postalCode: "49226" },
    { lng: -84.593491, lat: 41.884795, postalCode: "49258" },
    { lng: -82.903065, lat: 42.672322, postalCode: "48090" },
    { lng: -88.57829, lat: 47.145007, postalCode: "49930" },
    { lng: -82.47973, lat: 42.910198, postalCode: "48040" },
    { lng: -83.93995, lat: 43.262162, postalCode: "48417" },
    { lng: -83.836571, lat: 42.253502, postalCode: "48107" },
    { lng: -84.93861, lat: 43.959478, postalCode: "48633" },
    { lng: -86.66216, lat: 45.840744, postalCode: "49864" },
    { lng: -82.83687, lat: 43.418054, postalCode: "48471" },
    { lng: -83.836571, lat: 42.253502, postalCode: "48113" },
    { lng: -87.614805, lat: 46.604141, postalCode: "49865" },
    { lng: -83.519166, lat: 44.266549, postalCode: "48764" },
    { lng: -88.39491, lat: 47.297841, postalCode: "49901" },
    { lng: -83.84883, lat: 43.072177, postalCode: "48433" },
    { lng: -83.45125, lat: 44.920907, postalCode: "49766" },
    { lng: -83.30243, lat: 42.626947, postalCode: "48341" },
    { lng: -83.14829, lat: 42.250505, postalCode: "48229" },
    { lng: -83.29409, lat: 42.938891, postalCode: "48455" },
    { lng: -86.21484, lat: 44.631, postalCode: "49635" },
    { lng: -82.66859, lat: 42.693915, postalCode: "48023" },
    { lng: -85.04264, lat: 42.447038, postalCode: "49021" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49510" },
    { lng: -83.00446, lat: 42.558151, postalCode: "48312" },
    { lng: -84.9475, lat: 45.269001, postalCode: "49796" },
    { lng: -85.9334, lat: 44.531456, postalCode: "49683" },
    { lng: -87.92354, lat: 46.501074, postalCode: "49814" },
    { lng: -84.41946, lat: 42.702128, postalCode: "48864" },
    { lng: -87.25211, lat: 46.356104, postalCode: "49885" },
    { lng: -84.74589, lat: 43.011103, postalCode: "48835" },
    { lng: -84.387331, lat: 43.647297, postalCode: "48686" },
    { lng: -83.18179, lat: 42.333787, postalCode: "48126" },
    { lng: -83.62916, lat: 42.501401, postalCode: "48165" },
    { lng: -88.43396, lat: 47.170691, postalCode: "49934" },
    { lng: -85.31673, lat: 42.111949, postalCode: "49052" },
    { lng: -85.05287, lat: 46.199197, postalCode: "49793" },
    { lng: -86.48827, lat: 41.944869, postalCode: "49101" },
    { lng: -85.597374, lat: 45.066568, postalCode: "49674" },
    { lng: -82.52701, lat: 43.243891, postalCode: "48450" },
    { lng: -86.59362, lat: 41.890521, postalCode: "49125" },
    { lng: -84.35977, lat: 44.884763, postalCode: "49750" },
    { lng: -86.1955, lat: 43.232615, postalCode: "49442" },
    { lng: -85.54582, lat: 42.927801, postalCode: "49546" },
    { lng: -85.43612, lat: 44.117243, postalCode: "49688" },
    { lng: -83.48421, lat: 42.911611, postalCode: "48438" },
    { lng: -84.558679, lat: 42.732535, postalCode: "48922" },
    { lng: -83.25403, lat: 43.331961, postalCode: "48760" },
    { lng: -83.35133, lat: 42.642302, postalCode: "48328" },
    { lng: -85.57223, lat: 43.764906, postalCode: "49338" },
    { lng: -84.659847, lat: 44.870234, postalCode: "49797" },
    { lng: -83.06154, lat: 42.689632, postalCode: "48316" },
    { lng: -83.69025, lat: 43.116959, postalCode: "48458" },
    { lng: -85.26929, lat: 45.074263, postalCode: "49622" },
    { lng: -83.49818, lat: 41.777302, postalCode: "48133" },
    { lng: -85.00995, lat: 45.215511, postalCode: "49712" },
    { lng: -86.215413, lat: 43.080865, postalCode: "49409" },
    { lng: -88.71833, lat: 47.101109, postalCode: "49905" },
    { lng: -84.64548, lat: 42.018585, postalCode: "49250" },
    { lng: -84.90307, lat: 43.523617, postalCode: "48896" },
    { lng: -88.297959, lat: 47.414054, postalCode: "49924" },
    { lng: -83.10849, lat: 42.375787, postalCode: "48206" },
    { lng: -85.55958, lat: 42.210792, postalCode: "49002" },
    { lng: -85.298079, lat: 44.21551, postalCode: "49157" },
    { lng: -82.48614, lat: 43.088742, postalCode: "48059" },
    { lng: -85.73768, lat: 43.231933, postalCode: "49330" },
    { lng: -84.56913, lat: 42.677335, postalCode: "48911" },
    { lng: -84.503905, lat: 42.054795, postalCode: "49257" },
    { lng: -84.0761, lat: 42.869478, postalCode: "48414" },
    { lng: -84.11987, lat: 43.190239, postalCode: "48616" },
    { lng: -85.84205, lat: 42.144087, postalCode: "49065" },
    { lng: -83.68414, lat: 42.081363, postalCode: "48160" },
    { lng: -83.21722, lat: 42.355437, postalCode: "48228" },
    { lng: -84.60334, lat: 42.107907, postalCode: "49241" },
    { lng: -84.81464, lat: 45.567787, postalCode: "49769" },
    { lng: -84.76598, lat: 41.956743, postalCode: "49227" },
    { lng: -83.01232, lat: 43.89867, postalCode: "48432" },
    { lng: -87.20146, lat: 46.086125, postalCode: "49880" },
    { lng: -83.21027, lat: 42.254788, postalCode: "48101" },
    { lng: -85.39257, lat: 42.504149, postalCode: "49046" },
    { lng: -85.47798, lat: 42.97252, postalCode: "49301" },
    { lng: -85.64785, lat: 43.000076, postalCode: "49505" },
    { lng: -84.86646, lat: 45.416693, postalCode: "49722" },
    { lng: -85.34217, lat: 43.244538, postalCode: "49326" },
    { lng: -85.575147, lat: 44.816178, postalCode: "49696" },
    { lng: -83.65723, lat: 44.993111, postalCode: "49744" },
    { lng: -85.26533, lat: 43.1791, postalCode: "48838" },
    { lng: -83.55805, lat: 44.250144, postalCode: "48763" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48325" },
    { lng: -82.6415, lat: 43.420791, postalCode: "48419" },
    { lng: -85.16304, lat: 43.747406, postalCode: "49305" },
    { lng: -85.30996, lat: 43.696188, postalCode: "49342" },
    { lng: -85.529858, lat: 42.245412, postalCode: "49074" },
    { lng: -82.82558, lat: 43.198014, postalCode: "48454" },
    { lng: -84.387331, lat: 43.647297, postalCode: "48667" },
    { lng: -83.24422, lat: 42.394586, postalCode: "48223" },
    { lng: -88.54077, lat: 47.010896, postalCode: "49916" },
    { lng: -83.53378, lat: 42.532251, postalCode: "48393" },
    { lng: -85.70649, lat: 44.647716, postalCode: "49637" },
    { lng: -85.73515, lat: 43.430588, postalCode: "49337" },
    { lng: -88.32904, lat: 47.17583, postalCode: "49945" },
    { lng: -86.37093, lat: 43.436692, postalCode: "49437" },
    { lng: -84.301497, lat: 45.354266, postalCode: "49732" },
    { lng: -83.911173, lat: 42.603479, postalCode: "48863" },
    { lng: -84.41052, lat: 42.262135, postalCode: "49202" },
    { lng: -84.68397, lat: 43.160914, postalCode: "48871" },
    { lng: -85.99621, lat: 42.829252, postalCode: "49464" },
    { lng: -85.23333, lat: 42.089314, postalCode: "49011" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48332" },
    { lng: -83.612581, lat: 42.755627, postalCode: "48019" },
    { lng: -86.34028, lat: 43.389775, postalCode: "49461" },
    { lng: -85.44877, lat: 44.023714, postalCode: "49655" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48269" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49588" },
    { lng: -85.89754, lat: 43.010337, postalCode: "49430" },
    { lng: -83.18111, lat: 42.662732, postalCode: "48309" },
    { lng: -82.83068, lat: 42.682081, postalCode: "48051" },
    { lng: -86.40276, lat: 43.791699, postalCode: "49449" },
    { lng: -86.08323, lat: 44.592766, postalCode: "49616" },
    { lng: -83.29105, lat: 42.379436, postalCode: "48239" },
    { lng: -83.19812, lat: 42.205289, postalCode: "48195" },
    { lng: -86.54919, lat: 41.875968, postalCode: "49119" },
    { lng: -83.9162, lat: 43.607523, postalCode: "48706" },
    { lng: -84.695963, lat: 44.407339, postalCode: "48563" },
    { lng: -84.27234, lat: 43.605457, postalCode: "48640" },
    { lng: -87.43312, lat: 46.299186, postalCode: "49841" },
    { lng: -83.95779, lat: 42.067837, postalCode: "49236" },
    { lng: -85.25046, lat: 42.841491, postalCode: "48815" },
    { lng: -83.46527, lat: 43.645608, postalCode: "48767" },
    { lng: -83.17874, lat: 43.492259, postalCode: "48729" },
    { lng: -85.007665, lat: 43.113587, postalCode: "48870" },
    { lng: -86.63715, lat: 41.876483, postalCode: "49115" },
    { lng: -84.92575, lat: 42.733673, postalCode: "48861" },
    { lng: -83.47969, lat: 42.549483, postalCode: "48390" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48265" },
    { lng: -86.444751, lat: 42.985596, postalCode: "49422" },
    { lng: -84.10388, lat: 44.654024, postalCode: "48647" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49550" },
    { lng: -85.14305, lat: 44.382889, postalCode: "49651" },
    { lng: -83.89144, lat: 42.870299, postalCode: "48436" },
    { lng: -86.22663, lat: 44.617592, postalCode: "49628" },
    { lng: -86.0122, lat: 44.371587, postalCode: "49645" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48232" },
    { lng: -84.3329, lat: 41.992765, postalCode: "49220" },
    { lng: -86.88541, lat: 46.436663, postalCode: "49806" },
    { lng: -83.27921, lat: 42.823968, postalCode: "48371" },
    { lng: -83.71792, lat: 42.644709, postalCode: "48353" },
    { lng: -85.55107, lat: 42.278891, postalCode: "49001" },
    { lng: -85.66411, lat: 41.847413, postalCode: "49042" },
    { lng: -84.59181, lat: 43.269967, postalCode: "48847" },
    { lng: -84.52497, lat: 42.734434, postalCode: "48912" },
    { lng: -85.332038, lat: 45.523425, postalCode: "49711" },
    { lng: -83.47193, lat: 42.642431, postalCode: "48386" },
    { lng: -83.78387, lat: 42.16489, postalCode: "48176" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49515" },
    { lng: -82.90003, lat: 42.425836, postalCode: "48236" },
    { lng: -84.27972, lat: 41.974999, postalCode: "49253" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48231" },
    { lng: -86.10544, lat: 42.765918, postalCode: "49423" },
    { lng: -84.75589, lat: 44.305812, postalCode: "48629" },
    { lng: -82.903065, lat: 42.672322, postalCode: "48318" },
    { lng: -84.73336, lat: 43.197414, postalCode: "48856" },
    { lng: -86.02479, lat: 44.845213, postalCode: "49630" },
    { lng: -83.692507, lat: 43.002, postalCode: "48531" },
    { lng: -83.93526, lat: 45.282088, postalCode: "49743" },
    { lng: -86.04822, lat: 42.215357, postalCode: "49064" },
    { lng: -83.69728, lat: 43.062076, postalCode: "48505" },
    { lng: -83.87538, lat: 44.442563, postalCode: "48743" },
    { lng: -82.99866, lat: 42.467086, postalCode: "48089" },
    { lng: -85.61899, lat: 42.945727, postalCode: "49506" },
    { lng: -85.04772, lat: 43.21107, postalCode: "48884" },
    { lng: -82.59617, lat: 43.125531, postalCode: "48032" },
    { lng: -86.19013, lat: 43.04117, postalCode: "49417" },
    { lng: -83.07837, lat: 42.375237, postalCode: "48202" },
    { lng: -85.24163, lat: 44.877741, postalCode: "49612" },
    { lng: -82.77514, lat: 43.938529, postalCode: "48468" },
    { lng: -85.67233, lat: 41.792628, postalCode: "49099" },
    { lng: -84.34471, lat: 42.398355, postalCode: "49272" },
    { lng: -85.62856, lat: 42.876661, postalCode: "49508" },
    { lng: -83.79147, lat: 42.280887, postalCode: "48103" },
    { lng: -83.578088, lat: 42.405636, postalCode: "48175" },
    { lng: -84.464775, lat: 42.723784, postalCode: "48825" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48826" },
    { lng: -84.04585, lat: 41.900927, postalCode: "49221" },
    { lng: -85.24228, lat: 42.184303, postalCode: "49051" },
    { lng: -85.47744, lat: 43.405689, postalCode: "49329" },
    { lng: -83.37805, lat: 42.768373, postalCode: "48348" },
    { lng: -89.35119, lat: 46.846455, postalCode: "49953" },
    { lng: -87.86241, lat: 46.650313, postalCode: "49808" },
    { lng: -85.8033, lat: 42.026876, postalCode: "49067" },
    { lng: -85.772312, lat: 43.867421, postalCode: "49652" },
    { lng: -86.18835, lat: 43.360182, postalCode: "49457" },
    { lng: -87.01715, lat: 46.229173, postalCode: "49891" },
    { lng: -84.55244, lat: 41.844894, postalCode: "49266" },
    { lng: -86.468853, lat: 43.295493, postalCode: "49443" },
    { lng: -86.07944, lat: 42.484769, postalCode: "49450" },
    { lng: -85.529858, lat: 42.245412, postalCode: "49041" },
    { lng: -88.60459, lat: 46.53227, postalCode: "49970" },
    { lng: -84.39169, lat: 43.69784, postalCode: "48657" },
    { lng: -86.14141, lat: 42.935483, postalCode: "49460" },
    { lng: -83.2471, lat: 42.783379, postalCode: "48362" },
    { lng: -83.63585, lat: 42.925677, postalCode: "48439" },
    { lng: -85.37836, lat: 44.426913, postalCode: "49663" },
    { lng: -85.85676, lat: 44.725887, postalCode: "49650" },
    { lng: -82.99768, lat: 42.514885, postalCode: "48093" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48805" },
    { lng: -84.62643, lat: 45.856777, postalCode: "49757" },
    { lng: -84.102395, lat: 42.424092, postalCode: "49137" },
    { lng: -84.93774, lat: 45.360111, postalCode: "49770" },
    { lng: -85.550267, lat: 43.031413, postalCode: "49357" },
    { lng: -86.25131, lat: 43.234014, postalCode: "49440" },
    { lng: -84.19374, lat: 42.458785, postalCode: "49285" },
    { lng: -84.666661, lat: 43.389262, postalCode: "48802" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48243" },
    { lng: -86.10895, lat: 43.443787, postalCode: "49425" },
    { lng: -83.06067, lat: 43.038967, postalCode: "48444" },
    { lng: -83.44652, lat: 41.846349, postalCode: "48145" },
    { lng: -85.45029, lat: 41.916374, postalCode: "49075" },
    { lng: -83.69556, lat: 41.886364, postalCode: "49270" },
    { lng: -84.45048, lat: 46.01207, postalCode: "49745" },
    { lng: -84.27844, lat: 42.68669, postalCode: "48895" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48343" },
    { lng: -83.69291, lat: 43.011227, postalCode: "48503" },
    { lng: -85.29284, lat: 46.142357, postalCode: "49762" },
    { lng: -88.879139, lat: 46.421861, postalCode: "49943" },
    { lng: -84.37722, lat: 43.098253, postalCode: "48831" },
    { lng: -82.93255, lat: 43.01185, postalCode: "48014" },
    { lng: -83.131426, lat: 42.638533, postalCode: "48308" },
    { lng: -85.34928, lat: 42.944838, postalCode: "49331" },
    { lng: -85.76662, lat: 43.57219, postalCode: "49349" },
    { lng: -83.975475, lat: 43.467315, postalCode: "48663" },
    { lng: -85.46006, lat: 44.243788, postalCode: "49601" },
    { lng: -85.22931, lat: 44.555245, postalCode: "49633" },
    { lng: -85.78338, lat: 44.115429, postalCode: "49565" },
    { lng: -84.21671, lat: 43.652404, postalCode: "48642" },
    { lng: -83.64688, lat: 43.047826, postalCode: "48506" },
    { lng: -83.70142, lat: 42.973263, postalCode: "48507" },
    { lng: -85.131606, lat: 44.918887, postalCode: "49695" },
    { lng: -85.55091, lat: 42.794914, postalCode: "49316" },
    { lng: -83.29818, lat: 41.988228, postalCode: "48166" },
    { lng: -83.149978, lat: 42.567543, postalCode: "48037" },
    { lng: -84.287698, lat: 44.682965, postalCode: "49758" },
    { lng: -85.9109, lat: 44.209541, postalCode: "49689" },
    { lng: -84.1976, lat: 43.150386, postalCode: "48649" },
    { lng: -84.62624, lat: 41.89958, postalCode: "49242" },
    { lng: -83.69012, lat: 43.014077, postalCode: "48502" },
    { lng: -84.57704, lat: 42.998998, postalCode: "48879" },
    { lng: -83.84636, lat: 43.346632, postalCode: "48722" },
    { lng: -83.7918, lat: 44.99718, postalCode: "49753" },
    { lng: -83.91835, lat: 42.612243, postalCode: "48843" },
    { lng: -83.45865, lat: 42.462734, postalCode: "48375" },
    { lng: -86.33946, lat: 46.042121, postalCode: "49854" },
    { lng: -85.26699, lat: 45.160264, postalCode: "49729" },
    { lng: -86.34979, lat: 41.944691, postalCode: "49103" },
    { lng: -84.3569, lat: 42.884921, postalCode: "48848" },
    { lng: -84.17164, lat: 46.274091, postalCode: "49710" },
    { lng: -83.17889, lat: 43.604819, postalCode: "48726" },
    { lng: -82.99392, lat: 42.662902, postalCode: "48315" },
    { lng: -83.52542, lat: 43.167457, postalCode: "48463" },
    { lng: -88.84928, lat: 46.7596, postalCode: "49952" },
    { lng: -84.847297, lat: 43.564736, postalCode: "48859" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48255" },
    { lng: -83.14254, lat: 42.365337, postalCode: "48204" },
    { lng: -85.83015, lat: 43.032323, postalCode: "49435" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48222" },
    { lng: -86.29048, lat: 44.215959, postalCode: "49634" },
    { lng: -84.838206, lat: 42.596071, postalCode: "48907" },
    { lng: -86.29477, lat: 44.245509, postalCode: "49626" },
    { lng: -83.17281, lat: 42.765582, postalCode: "48363" },
    { lng: -82.91658, lat: 42.753378, postalCode: "48096" },
    { lng: -83.3382, lat: 42.324338, postalCode: "48135" },
    { lng: -85.40671, lat: 44.900873, postalCode: "49629" },
    { lng: -83.4044, lat: 42.720966, postalCode: "48346" },
    { lng: -82.64573, lat: 43.263998, postalCode: "48422" },
    { lng: -84.34907, lat: 41.857385, postalCode: "49247" },
    { lng: -83.14152, lat: 42.396736, postalCode: "48238" },
    { lng: -82.69327, lat: 43.062285, postalCode: "48006" },
    { lng: -82.66522, lat: 42.824095, postalCode: "48061" },
    { lng: -84.60041, lat: 43.422397, postalCode: "48880" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48950" },
    { lng: -83.00181, lat: 43.803691, postalCode: "48413" },
    { lng: -84.77654, lat: 45.431122, postalCode: "49706" },
    { lng: -84.30787, lat: 44.839929, postalCode: "49756" },
    { lng: -84.69278, lat: 43.102399, postalCode: "48853" },
    { lng: -85.35285, lat: 43.3157, postalCode: "49347" },
    { lng: -83.783675, lat: 43.080578, postalCode: "48557" },
    { lng: -84.70868, lat: 42.38806, postalCode: "49284" },
    { lng: -84.77046, lat: 42.034427, postalCode: "49252" },
    { lng: -84.497138, lat: 43.356518, postalCode: "48862" },
    { lng: -86.55586, lat: 41.94263, postalCode: "49106" },
    { lng: -86.0545, lat: 42.395965, postalCode: "49056" },
    { lng: -84.22768, lat: 42.089891, postalCode: "49230" },
    { lng: -83.26005, lat: 43.664593, postalCode: "48735" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48295" },
    { lng: -83.70951, lat: 41.757599, postalCode: "49267" },
    { lng: -83.43887, lat: 44.725546, postalCode: "48742" },
    { lng: -83.66826, lat: 42.586597, postalCode: "48380" },
    { lng: -85.94516, lat: 43.06507, postalCode: "49404" },
    { lng: -85.99417, lat: 42.105719, postalCode: "49045" },
    { lng: -82.59251, lat: 43.03154, postalCode: "48049" },
    { lng: -83.94479, lat: 43.473075, postalCode: "48604" },
    { lng: -84.838206, lat: 42.596071, postalCode: "48908" },
    { lng: -83.18532, lat: 42.497035, postalCode: "48072" },
    { lng: -84.01177, lat: 43.441975, postalCode: "48603" },
    { lng: -87.42223, lat: 46.554402, postalCode: "49855" },
    { lng: -83.97142, lat: 43.423925, postalCode: "48602" },
    { lng: -84.55669, lat: 43.747084, postalCode: "48618" },
    { lng: -82.95044, lat: 42.536402, postalCode: "48026" },
    { lng: -83.49441, lat: 44.580133, postalCode: "48745" },
    { lng: -84.98054, lat: 42.759217, postalCode: "48890" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48268" },
    { lng: -85.03401, lat: 44.905437, postalCode: "49659" },
    { lng: -85.41889, lat: 42.834137, postalCode: "49302" },
    { lng: -86.98096, lat: 46.364487, postalCode: "49825" },
    { lng: -85.72321, lat: 46.301265, postalCode: "49853" },
    { lng: -84.54915, lat: 42.704435, postalCode: "48910" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48924" },
    { lng: -87.88212, lat: 47.467597, postalCode: "49918" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48278" },
    { lng: -84.78306, lat: 43.60616, postalCode: "48858" },
    { lng: -83.836571, lat: 42.253502, postalCode: "48106" },
    { lng: -85.41657, lat: 42.289991, postalCode: "49053" },
    { lng: -84.051827, lat: 43.458776, postalCode: "48605" },
    { lng: -84.60248, lat: 45.169729, postalCode: "49795" },
    { lng: -84.94734, lat: 46.048831, postalCode: "49760" },
    { lng: -83.76098, lat: 43.005477, postalCode: "48532" },
    { lng: -82.90786, lat: 42.554134, postalCode: "48035" },
    { lng: -82.75005, lat: 43.736207, postalCode: "48470" },
    { lng: -85.54811, lat: 43.224155, postalCode: "49319" },
    { lng: -86.444751, lat: 42.985596, postalCode: "49429" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48297" },
    { lng: -85.49536, lat: 43.335474, postalCode: "49339" },
    { lng: -89.15645, lat: 46.46077, postalCode: "49912" },
    { lng: -84.03123, lat: 42.14784, postalCode: "48158" },
    { lng: -85.278303, lat: 43.755335, postalCode: "49320" },
    { lng: -83.92772, lat: 43.460075, postalCode: "48724" },
    { lng: -84.371973, lat: 42.599184, postalCode: "48980" },
    { lng: -85.72542, lat: 42.802932, postalCode: "49315" },
    { lng: -86.2682, lat: 43.278021, postalCode: "49445" },
    { lng: -85.54511, lat: 44.735559, postalCode: "49686" },
    { lng: -84.16796, lat: 42.020199, postalCode: "49265" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48121" },
    { lng: -83.07158, lat: 42.563644, postalCode: "48310" },
    { lng: -84.83662, lat: 43.362879, postalCode: "48830" },
    { lng: -86.37416, lat: 42.036334, postalCode: "49126" },
    { lng: -84.032612, lat: 43.348535, postalCode: "48606" },
    { lng: -86.00977, lat: 44.306548, postalCode: "49619" },
    { lng: -83.09819, lat: 42.459022, postalCode: "48030" },
    { lng: -84.03445, lat: 42.316922, postalCode: "48118" },
    { lng: -83.39974, lat: 42.595233, postalCode: "48324" },
    { lng: -84.29816, lat: 43.25118, postalCode: "48614" },
    { lng: -84.559029, lat: 42.748734, postalCode: "48937" },
    { lng: -83.38967, lat: 42.68828, postalCode: "48329" },
    { lng: -82.99938, lat: 42.598734, postalCode: "48313" },
    { lng: -85.490005, lat: 44.955872, postalCode: "49673" },
    { lng: -83.35297, lat: 42.20649, postalCode: "48242" },
    { lng: -83.40536, lat: 42.952899, postalCode: "48440" },
    { lng: -85.50812, lat: 43.291089, postalCode: "49343" },
    { lng: -84.38842, lat: 42.252268, postalCode: "49201" },
    { lng: -88.57894, lat: 47.093922, postalCode: "49921" },
    { lng: -83.15112, lat: 42.535534, postalCode: "48017" },
    { lng: -85.06679, lat: 42.981932, postalCode: "48846" },
    { lng: -82.44402, lat: 42.978974, postalCode: "48060" },
    { lng: -85.90176, lat: 46.180416, postalCode: "49836" },
    { lng: -85.58573, lat: 42.29714, postalCode: "49007" },
    { lng: -83.18115, lat: 43.314176, postalCode: "48727" },
    { lng: -85.24582, lat: 42.504137, postalCode: "49050" },
    { lng: -84.79969, lat: 45.714093, postalCode: "49718" },
    { lng: -83.311605, lat: 42.7253, postalCode: "48387" },
    { lng: -83.17952, lat: 42.467235, postalCode: "48237" },
    { lng: -84.32652, lat: 41.912292, postalCode: "49278" },
    { lng: -86.28427, lat: 43.946244, postalCode: "49454" },
    { lng: -85.00864, lat: 43.864435, postalCode: "48632" },
    { lng: -86.12625, lat: 44.433903, postalCode: "49614" },
    { lng: -83.3881, lat: 43.490117, postalCode: "48723" },
    { lng: -85.42621, lat: 42.187577, postalCode: "49088" },
    { lng: -83.04422, lat: 42.432686, postalCode: "48234" },
    { lng: -86.1208, lat: 42.004729, postalCode: "49047" },
    { lng: -83.91427, lat: 43.413975, postalCode: "48601" },
    { lng: -85.9318, lat: 42.973667, postalCode: "49401" },
    { lng: -83.93878, lat: 42.012268, postalCode: "49286" },
    { lng: -85.66391, lat: 42.868228, postalCode: "49548" },
    { lng: -85.10506, lat: 43.297366, postalCode: "48888" },
    { lng: -83.63681, lat: 44.708179, postalCode: "48705" },
    { lng: -84.91041, lat: 42.439324, postalCode: "49076" },
    { lng: -83.3863, lat: 42.660091, postalCode: "48303" },
    { lng: -85.61098, lat: 42.26549, postalCode: "49008" },
    { lng: -84.300929, lat: 42.955783, postalCode: "48448" },
    { lng: -83.150823, lat: 42.239933, postalCode: "48275" },
    { lng: -84.197125, lat: 43.612884, postalCode: "48674" },
    { lng: -84.604662, lat: 41.782499, postalCode: "49239" },
    { lng: -85.60119, lat: 44.306885, postalCode: "49618" },
    { lng: -83.15235, lat: 42.599133, postalCode: "48098" },
    { lng: -85.4055, lat: 42.424387, postalCode: "49060" },
    { lng: -86.69237, lat: 41.827718, postalCode: "49129" },
    { lng: -86.46576, lat: 43.678258, postalCode: "49436" },
    { lng: -83.14051, lat: 42.472235, postalCode: "48069" },
    { lng: -85.13565, lat: 42.788151, postalCode: "48849" },
    { lng: -83.07805, lat: 42.328338, postalCode: "48216" },
    { lng: -86.715294, lat: 42.001551, postalCode: "49023" },
    { lng: -84.3494, lat: 42.071196, postalCode: "49233" },
    { lng: -83.61368, lat: 44.827421, postalCode: "49747" },
    { lng: -86.70615, lat: 46.37503, postalCode: "49862" },
    { lng: -86.43315, lat: 46.399545, postalCode: "49884" },
    { lng: -84.27784, lat: 44.616344, postalCode: "48636" },
    { lng: -82.54948, lat: 43.452038, postalCode: "48469" },
    { lng: -86.51351, lat: 42.015092, postalCode: "49127" },
    { lng: -83.06289, lat: 43.50461, postalCode: "48426" },
    { lng: -86.072032, lat: 42.630533, postalCode: "49416" },
    { lng: -83.21446, lat: 42.137071, postalCode: "48183" },
    { lng: -80.82988, lat: 36.227576, postalCode: "28642" },
    { lng: -80.18998, lat: 36.046215, postalCode: "27107" },
    { lng: -83.81695, lat: 35.202178, postalCode: "28901" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28299" },
    { lng: -78.413371, lat: 35.88224, postalCode: "27697" },
    { lng: -77.523102, lat: 35.005079, postalCode: "28522" },
    { lng: -82.63479, lat: 35.712999, postalCode: "28701" },
    { lng: -80.31503, lat: 36.094104, postalCode: "27104" },
    { lng: -79.9849, lat: 35.843856, postalCode: "27370" },
    { lng: -80.851149, lat: 35.065595, postalCode: "28247" },
    { lng: -77.07585, lat: 35.103736, postalCode: "28561" },
    { lng: -80.10517, lat: 35.618434, postalCode: "27239" },
    { lng: -80.4268, lat: 35.267185, postalCode: "28097" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28258" },
    { lng: -77.93998, lat: 36.255897, postalCode: "27844" },
    { lng: -81.51951, lat: 36.290889, postalCode: "28626" },
    { lng: -81.55584, lat: 35.315118, postalCode: "28150" },
    { lng: -81.65456, lat: 35.254223, postalCode: "28017" },
    { lng: -80.38089, lat: 36.235252, postalCode: "27050" },
    { lng: -77.07986, lat: 35.487026, postalCode: "27817" },
    { lng: -81.04028, lat: 35.2406, postalCode: "28012" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28235" },
    { lng: -78.71783, lat: 35.758268, postalCode: "27606" },
    { lng: -77.24791, lat: 36.203511, postalCode: "27872" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27411" },
    { lng: -78.18555, lat: 34.989801, postalCode: "28393" },
    { lng: -79.37498, lat: 36.512448, postalCode: "27315" },
    { lng: -76.1988, lat: 34.984608, postalCode: "28520" },
    { lng: -79.13005, lat: 34.476857, postalCode: "28340" },
    { lng: -78.52241, lat: 35.97154, postalCode: "27587" },
    { lng: -77.86277, lat: 36.531252, postalCode: "27842" },
    { lng: -77.934656, lat: 34.300421, postalCode: "28849" },
    { lng: -78.01897, lat: 35.293245, postalCode: "28333" },
    { lng: -80.355204, lat: 34.995653, postalCode: "28003" },
    { lng: -80.7405, lat: 35.321279, postalCode: "28262" },
    { lng: -76.61637, lat: 36.519826, postalCode: "27926" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28234" },
    { lng: -81.73139, lat: 36.009373, postalCode: "28611" },
    { lng: -81.03564, lat: 36.33942, postalCode: "28685" },
    { lng: -80.87988, lat: 35.840579, postalCode: "28625" },
    { lng: -82.62466, lat: 35.154193, postalCode: "28718" },
    { lng: -78.72076, lat: 34.655921, postalCode: "28332" },
    { lng: -80.86362, lat: 35.409544, postalCode: "28078" },
    { lng: -79.93705, lat: 34.970129, postalCode: "28091" },
    { lng: -83.20772, lat: 35.37273, postalCode: "28779" },
    { lng: -80.91265, lat: 36.03559, postalCode: "28689" },
    { lng: -76.62627, lat: 36.256548, postalCode: "27980" },
    { lng: -78.857651, lat: 36.051155, postalCode: "27715" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27623" },
    { lng: -81.102329, lat: 35.891211, postalCode: "28620" },
    { lng: -79.9842, lat: 36.178137, postalCode: "27310" },
    { lng: -80.477116, lat: 35.682683, postalCode: "28145" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28225" },
    { lng: -81.543706, lat: 35.373884, postalCode: "28042" },
    { lng: -82.18313, lat: 35.336523, postalCode: "28756" },
    { lng: -79.92418, lat: 35.382213, postalCode: "27371" },
    { lng: -79.01225, lat: 35.142321, postalCode: "28307" },
    { lng: -78.71, lat: 35.494384, postalCode: "27501" },
    { lng: -77.483072, lat: 36.356129, postalCode: "27867" },
    { lng: -81.18843, lat: 35.575785, postalCode: "28650" },
    { lng: -81.05152, lat: 35.846848, postalCode: "28678" },
    { lng: -80.54673, lat: 35.665362, postalCode: "28147" },
    { lng: -80.858155, lat: 35.284459, postalCode: "28266" },
    { lng: -79.02939, lat: 35.915083, postalCode: "27514" },
    { lng: -80.1649, lat: 35.463024, postalCode: "28127" },
    { lng: -80.37587, lat: 35.752578, postalCode: "27299" },
    { lng: -80.25524, lat: 35.49326, postalCode: "28137" },
    { lng: -77.92764, lat: 34.554821, postalCode: "28425" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27695" },
    { lng: -82.503611, lat: 35.292714, postalCode: "28793" },
    { lng: -78.06492, lat: 34.25124, postalCode: "28451" },
    { lng: -80.14383, lat: 36.298241, postalCode: "27052" },
    { lng: -80.26171, lat: 36.039398, postalCode: "27127" },
    { lng: -81.778871, lat: 36.209158, postalCode: "28691" },
    { lng: -82.54142, lat: 35.459869, postalCode: "28704" },
    { lng: -78.04965, lat: 34.107077, postalCode: "28479" },
    { lng: -82.28307, lat: 35.464897, postalCode: "28710" },
    { lng: -80.82576, lat: 35.490772, postalCode: "28036" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27627" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28253" },
    { lng: -78.277669, lat: 34.994005, postalCode: "28329" },
    { lng: -78.16439, lat: 35.119076, postalCode: "28341" },
    { lng: -80.81792, lat: 35.054546, postalCode: "28277" },
    { lng: -80.23423, lat: 36.147887, postalCode: "27105" },
    { lng: -79.16415, lat: 35.458804, postalCode: "27330" },
    { lng: -77.404512, lat: 35.526959, postalCode: "28950" },
    { lng: -77.03459, lat: 35.560439, postalCode: "27889" },
    { lng: -78.83969, lat: 35.966431, postalCode: "27703" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27480" },
    { lng: -78.73848, lat: 35.406513, postalCode: "27506" },
    { lng: -81.53117, lat: 36.532301, postalCode: "28643" },
    { lng: -78.67038, lat: 35.554891, postalCode: "27592" },
    { lng: -78.97271, lat: 35.633286, postalCode: "27562" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27098" },
    { lng: -75.47741, lat: 35.661979, postalCode: "27968" },
    { lng: -80.42715, lat: 36.097767, postalCode: "27023" },
    { lng: -80.58746, lat: 36.390363, postalCode: "27007" },
    { lng: -78.06544, lat: 34.671237, postalCode: "28478" },
    { lng: -76.52748, lat: 34.712315, postalCode: "28531" },
    { lng: -79.276577, lat: 35.372577, postalCode: "27331" },
    { lng: -82.75799, lat: 35.188077, postalCode: "28712" },
    { lng: -79.65327, lat: 35.307299, postalCode: "27242" },
    { lng: -78.79702, lat: 35.797882, postalCode: "27513" },
    { lng: -75.97921, lat: 36.433902, postalCode: "27929" },
    { lng: -77.3127, lat: 34.637348, postalCode: "28547" },
    { lng: -80.203546, lat: 35.781848, postalCode: "27374" },
    { lng: -81.1362, lat: 36.506064, postalCode: "28675" },
    { lng: -82.48999, lat: 35.437611, postalCode: "28732" },
    { lng: -78.450754, lat: 35.973108, postalCode: "27588" },
    { lng: -79.75242, lat: 36.107499, postalCode: "27405" },
    { lng: -81.213369, lat: 35.275073, postalCode: "28053" },
    { lng: -80.89228, lat: 35.571827, postalCode: "28117" },
    { lng: -77.20349, lat: 35.531965, postalCode: "27837" },
    { lng: -82.34408, lat: 35.23899, postalCode: "28773" },
    { lng: -82.49673, lat: 35.345487, postalCode: "28791" },
    { lng: -78.44504, lat: 36.528939, postalCode: "27584" },
    { lng: -78.671089, lat: 35.758667, postalCode: "27602" },
    { lng: -83.01052, lat: 35.115467, postalCode: "28774" },
    { lng: -78.40574, lat: 34.788387, postalCode: "28441" },
    { lng: -81.75621, lat: 35.346886, postalCode: "28040" },
    { lng: -82.60353, lat: 35.362016, postalCode: "28742" },
    { lng: -76.632264, lat: 35.156145, postalCode: "28583" },
    { lng: -78.20349, lat: 35.562142, postalCode: "27555" },
    { lng: -83.04111, lat: 35.248885, postalCode: "28783" },
    { lng: -78.58348, lat: 35.814572, postalCode: "27604" },
    { lng: -78.48345, lat: 35.790516, postalCode: "27545" },
    { lng: -78.06056, lat: 35.184516, postalCode: "28365" },
    { lng: -77.09936, lat: 35.091472, postalCode: "28562" },
    { lng: -80.88715, lat: 35.687365, postalCode: "28166" },
    { lng: -80.4342, lat: 35.693339, postalCode: "28159" },
    { lng: -82.07899, lat: 35.849372, postalCode: "28749" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27285" },
    { lng: -81.28723, lat: 35.292581, postalCode: "28016" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28297" },
    { lng: -78.95911, lat: 36.015992, postalCode: "27705" },
    { lng: -79.53503, lat: 35.042172, postalCode: "28347" },
    { lng: -79.38984, lat: 35.613837, postalCode: "27207" },
    { lng: -77.65238, lat: 34.407677, postalCode: "28443" },
    { lng: -79.432734, lat: 35.280335, postalCode: "28388" },
    { lng: -77.7221, lat: 35.030154, postalCode: "28572" },
    { lng: -80.80486, lat: 35.329235, postalCode: "28269" },
    { lng: -81.77819, lat: 36.268944, postalCode: "28692" },
    { lng: -79.02516, lat: 34.309767, postalCode: "28439" },
    { lng: -81.3614, lat: 35.241188, postalCode: "28086" },
    { lng: -81.98377, lat: 35.361333, postalCode: "28139" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27113" },
    { lng: -81.02441, lat: 35.311862, postalCode: "28120" },
    { lng: -80.8924, lat: 35.477583, postalCode: "28031" },
    { lng: -76.57115, lat: 35.304817, postalCode: "28552" },
    { lng: -77.87113, lat: 34.169953, postalCode: "28409" },
    { lng: -76.53869, lat: 34.732744, postalCode: "28528" },
    { lng: -81.51726, lat: 35.693787, postalCode: "28612" },
    { lng: -78.19889, lat: 35.777862, postalCode: "27557" },
    { lng: -81.66601, lat: 35.318573, postalCode: "28089" },
    { lng: -82.528558, lat: 35.620335, postalCode: "28816" },
    { lng: -76.60012, lat: 35.545041, postalCode: "27810" },
    { lng: -77.89903, lat: 34.034336, postalCode: "28428" },
    { lng: -79.7605, lat: 34.937078, postalCode: "28379" },
    { lng: -78.77508, lat: 35.755651, postalCode: "27511" },
    { lng: -78.86892, lat: 36.034561, postalCode: "27704" },
    { lng: -77.70192, lat: 35.593689, postalCode: "27888" },
    { lng: -78.5702, lat: 36.454831, postalCode: "27582" },
    { lng: -79.69566, lat: 35.911979, postalCode: "27233" },
    { lng: -79.08288, lat: 34.902698, postalCode: "28357" },
    { lng: -78.82527, lat: 35.316332, postalCode: "28323" },
    { lng: -78.412864, lat: 33.933388, postalCode: "28459" },
    { lng: -79.72904, lat: 35.089476, postalCode: "28338" },
    { lng: -78.80384, lat: 35.050612, postalCode: "28310" },
    { lng: -79.46496, lat: 35.195417, postalCode: "28374" },
    { lng: -81.0081, lat: 36.445484, postalCode: "28627" },
    { lng: -81.27736, lat: 36.43591, postalCode: "28644" },
    { lng: -79.901349, lat: 35.353048, postalCode: "27391" },
    { lng: -76.60411, lat: 36.440509, postalCode: "27979" },
    { lng: -78.12862, lat: 33.915522, postalCode: "28465" },
    { lng: -79.832161, lat: 35.665973, postalCode: "27205" },
    { lng: -79.77468, lat: 36.071135, postalCode: "27401" },
    { lng: -78.25342, lat: 35.954331, postalCode: "27508" },
    { lng: -79.48073, lat: 36.49076, postalCode: "27311" },
    { lng: -77.463687, lat: 34.664035, postalCode: "28542" },
    { lng: -80.66822, lat: 35.192919, postalCode: "28227" },
    { lng: -79.88291, lat: 36.116854, postalCode: "27410" },
    { lng: -83.14464, lat: 35.256241, postalCode: "28723" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27114" },
    { lng: -78.01392, lat: 34.772249, postalCode: "28464" },
    { lng: -78.70086, lat: 35.807458, postalCode: "27607" },
    { lng: -78.53155, lat: 35.390431, postalCode: "27504" },
    { lng: -79.67075, lat: 36.345919, postalCode: "27320" },
    { lng: -77.42548, lat: 35.370945, postalCode: "28530" },
    { lng: -79.03714, lat: 34.434292, postalCode: "28369" },
    { lng: -77.2083, lat: 35.948738, postalCode: "27840" },
    { lng: -80.79857, lat: 35.170094, postalCode: "28211" },
    { lng: -78.6012, lat: 35.302419, postalCode: "28334" },
    { lng: -80.00628, lat: 36.017647, postalCode: "27265" },
    { lng: -79.40099, lat: 34.927483, postalCode: "28396" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27264" },
    { lng: -80.22195, lat: 36.52235, postalCode: "27022" },
    { lng: -79.77895, lat: 36.022863, postalCode: "27406" },
    { lng: -81.239543, lat: 35.484822, postalCode: "28093" },
    { lng: -78.93744, lat: 34.954709, postalCode: "28348" },
    { lng: -76.89865, lat: 35.759335, postalCode: "27846" },
    { lng: -79.44984, lat: 35.729692, postalCode: "27344" },
    { lng: -82.16858, lat: 36.033454, postalCode: "28705" },
    { lng: -77.06336, lat: 35.110855, postalCode: "28563" },
    { lng: -82.41625, lat: 35.353837, postalCode: "28792" },
    { lng: -79.45745, lat: 34.779227, postalCode: "28352" },
    { lng: -77.43518, lat: 35.056317, postalCode: "28585" },
    { lng: -77.595034, lat: 35.319066, postalCode: "28503" },
    { lng: -83.327411, lat: 35.03107, postalCode: "28775" },
    { lng: -82.73513, lat: 35.661009, postalCode: "28748" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27198" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27650" },
    { lng: -80.64557, lat: 36.12301, postalCode: "27055" },
    { lng: -82.708024, lat: 35.498797, postalCode: "28728" },
    { lng: -83.09538, lat: 35.103251, postalCode: "28717" },
    { lng: -84.09018, lat: 35.091332, postalCode: "28906" },
    { lng: -80.947028, lat: 35.529792, postalCode: "28123" },
    { lng: -82.62357, lat: 35.264303, postalCode: "28766" },
    { lng: -77.96738, lat: 35.993357, postalCode: "27856" },
    { lng: -80.69407, lat: 35.73956, postalCode: "27013" },
    { lng: -77.64461, lat: 35.686549, postalCode: "27829" },
    { lng: -80.39312, lat: 36.027101, postalCode: "27012" },
    { lng: -79.26388, lat: 36.461871, postalCode: "27212" },
    { lng: -78.614, lat: 35.020272, postalCode: "28318" },
    { lng: -78.327491, lat: 36.413473, postalCode: "27556" },
    { lng: -76.27892, lat: 35.452071, postalCode: "27885" },
    { lng: -82.003691, lat: 36.044098, postalCode: "28664" },
    { lng: -81.207166, lat: 36.216247, postalCode: "28674" },
    { lng: -81.52276, lat: 36.197882, postalCode: "28618" },
    { lng: -79.35011, lat: 35.564257, postalCode: "27252" },
    { lng: -77.87251, lat: 34.254235, postalCode: "28405" },
    { lng: -78.60246, lat: 35.679194, postalCode: "27529" },
    { lng: -84.035916, lat: 35.094379, postalCode: "28903" },
    { lng: -83.39032, lat: 35.185018, postalCode: "28734" },
    { lng: -77.45335, lat: 35.818414, postalCode: "27819" },
    { lng: -81.7995, lat: 35.441168, postalCode: "28018" },
    { lng: -78.91932, lat: 34.430348, postalCode: "28438" },
    { lng: -76.75219, lat: 34.729839, postalCode: "28557" },
    { lng: -81.775456, lat: 35.241615, postalCode: "28024" },
    { lng: -79.96062, lat: 35.914548, postalCode: "27263" },
    { lng: -77.44254, lat: 36.366571, postalCode: "27845" },
    { lng: -83.82171, lat: 35.435102, postalCode: "28733" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28283" },
    { lng: -77.78077, lat: 35.935563, postalCode: "27802" },
    { lng: -80.60293, lat: 35.492989, postalCode: "28083" },
    { lng: -79.414859, lat: 35.648194, postalCode: "27213" },
    { lng: -80.27001, lat: 34.969886, postalCode: "28133" },
    { lng: -78.97037, lat: 35.02844, postalCode: "28304" },
    { lng: -77.18438, lat: 36.515084, postalCode: "27877" },
    { lng: -80.85632, lat: 35.13451, postalCode: "28210" },
    { lng: -77.99977, lat: 34.605639, postalCode: "28471" },
    { lng: -77.483072, lat: 36.356129, postalCode: "27838" },
    { lng: -80.56574, lat: 35.400407, postalCode: "28025" },
    { lng: -79.47979, lat: 35.100144, postalCode: "28373" },
    { lng: -79.6808, lat: 36.108536, postalCode: "27301" },
    { lng: -79.08144, lat: 35.912489, postalCode: "27510" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27635" },
    { lng: -82.54285, lat: 35.723848, postalCode: "28787" },
    { lng: -79.97188, lat: 34.819916, postalCode: "28102" },
    { lng: -80.52363, lat: 35.247181, postalCode: "28107" },
    { lng: -81.61009, lat: 36.345453, postalCode: "28684" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28280" },
    { lng: -78.979964, lat: 35.413579, postalCode: "27552" },
    { lng: -77.37117, lat: 34.74167, postalCode: "28543" },
    { lng: -82.18161, lat: 35.248689, postalCode: "28722" },
    { lng: -77.3756, lat: 34.781212, postalCode: "28546" },
    { lng: -81.562609, lat: 35.99726, postalCode: "28661" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27625" },
    { lng: -77.26186, lat: 35.821436, postalCode: "27871" },
    { lng: -80.82665, lat: 35.214693, postalCode: "28204" },
    { lng: -78.07321, lat: 36.085542, postalCode: "27816" },
    { lng: -82.93536, lat: 35.60089, postalCode: "28721" },
    { lng: -77.23117, lat: 36.416472, postalCode: "27820" },
    { lng: -78.45893, lat: 36.104866, postalCode: "27525" },
    { lng: -76.87786, lat: 34.770681, postalCode: "28570" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27156" },
    { lng: -77.391199, lat: 34.692056, postalCode: "28545" },
    { lng: -81.37502, lat: 36.343002, postalCode: "28629" },
    { lng: -81.54476, lat: 35.934783, postalCode: "28645" },
    { lng: -80.32779, lat: 35.513974, postalCode: "28071" },
    { lng: -80.84824, lat: 36.520921, postalCode: "27024" },
    { lng: -81.87177, lat: 36.073202, postalCode: "28646" },
    { lng: -77.9727, lat: 35.552162, postalCode: "27830" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27435" },
    { lng: -76.66595, lat: 35.124491, postalCode: "28556" },
    { lng: -76.87437, lat: 35.321447, postalCode: "27821" },
    { lng: -78.93039, lat: 34.285951, postalCode: "28430" },
    { lng: -79.4698, lat: 36.072336, postalCode: "27215" },
    { lng: -80.396168, lat: 36.032304, postalCode: "27102" },
    { lng: -79.40248, lat: 35.319639, postalCode: "28327" },
    { lng: -82.11072, lat: 35.931539, postalCode: "28765" },
    { lng: -79.97451, lat: 36.383107, postalCode: "27025" },
    { lng: -81.09974, lat: 35.403268, postalCode: "28006" },
    { lng: -82.667132, lat: 35.623732, postalCode: "28802" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27668" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27152" },
    { lng: -79.74935, lat: 36.505996, postalCode: "27288" },
    { lng: -76.49457, lat: 34.7994, postalCode: "28589" },
    { lng: -77.06336, lat: 35.110855, postalCode: "28564" },
    { lng: -81.31911, lat: 35.416189, postalCode: "28033" },
    { lng: -82.520707, lat: 35.483482, postalCode: "28776" },
    { lng: -82.41515, lat: 35.270682, postalCode: "28731" },
    { lng: -79.56026, lat: 34.827291, postalCode: "28351" },
    { lng: -78.70161, lat: 35.847788, postalCode: "27612" },
    { lng: -78.37287, lat: 35.781595, postalCode: "27591" },
    { lng: -77.662614, lat: 35.288558, postalCode: "28502" },
    { lng: -76.86135, lat: 35.085784, postalCode: "28529" },
    { lng: -77.39714, lat: 35.53445, postalCode: "28590" },
    { lng: -77.353092, lat: 35.588523, postalCode: "27835" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27120" },
    { lng: -78.857651, lat: 36.051155, postalCode: "27702" },
    { lng: -81.574711, lat: 35.233125, postalCode: "28151" },
    { lng: -77.7981, lat: 34.21222, postalCode: "28480" },
    { lng: -79.03409, lat: 35.42638, postalCode: "27505" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27675" },
    { lng: -80.655786, lat: 36.551332, postalCode: "27049" },
    { lng: -78.052422, lat: 35.372045, postalCode: "27532" },
    { lng: -78.54929, lat: 35.865441, postalCode: "27616" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28237" },
    { lng: -82.86149, lat: 35.834212, postalCode: "28743" },
    { lng: -77.852599, lat: 34.088079, postalCode: "28410" },
    { lng: -81.47238, lat: 35.726966, postalCode: "28666" },
    { lng: -81.630272, lat: 35.396126, postalCode: "28136" },
    { lng: -82.47156, lat: 35.202275, postalCode: "28790" },
    { lng: -80.86439, lat: 35.970599, postalCode: "28660" },
    { lng: -77.995728, lat: 35.463121, postalCode: "27531" },
    { lng: -81.09942, lat: 35.267234, postalCode: "28098" },
    { lng: -79.013799, lat: 35.173377, postalCode: "28308" },
    { lng: -78.26673, lat: 34.275453, postalCode: "28436" },
    { lng: -77.42096, lat: 34.52072, postalCode: "28460" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27404" },
    { lng: -79.680318, lat: 36.347568, postalCode: "27321" },
    { lng: -78.04176, lat: 34.822407, postalCode: "28458" },
    { lng: -80.07495, lat: 36.236829, postalCode: "27009" },
    { lng: -80.89295, lat: 35.235791, postalCode: "28208" },
    { lng: -82.059494, lat: 35.738725, postalCode: "28737" },
    { lng: -76.5184, lat: 34.753595, postalCode: "28579" },
    { lng: -78.71161, lat: 35.899208, postalCode: "27613" },
    { lng: -78.57014, lat: 36.525791, postalCode: "27507" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28106" },
    { lng: -76.60188, lat: 36.362745, postalCode: "27946" },
    { lng: -81.54162, lat: 35.198687, postalCode: "28038" },
    { lng: -81.189693, lat: 35.284018, postalCode: "28051" },
    { lng: -76.83431, lat: 35.588518, postalCode: "27865" },
    { lng: -81.18303, lat: 35.333668, postalCode: "28034" },
    { lng: -80.3546, lat: 36.293122, postalCode: "27021" },
    { lng: -77.43618, lat: 36.487125, postalCode: "27876" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28231" },
    { lng: -83.79634, lat: 35.345611, postalCode: "28771" },
    { lng: -79.775913, lat: 36.112987, postalCode: "27420" },
    { lng: -82.481257, lat: 35.381677, postalCode: "28760" },
    { lng: -81.901715, lat: 36.065053, postalCode: "28653" },
    { lng: -76.20409, lat: 35.575982, postalCode: "27826" },
    { lng: -82.07366, lat: 35.910932, postalCode: "28777" },
    { lng: -80.45806, lat: 35.58167, postalCode: "28041" },
    { lng: -80.89909, lat: 35.719228, postalCode: "28010" },
    { lng: -81.00762, lat: 35.831037, postalCode: "28699" },
    { lng: -77.904283, lat: 35.715778, postalCode: "27894" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28218" },
    { lng: -76.29809, lat: 36.466396, postalCode: "27976" },
    { lng: -78.839488, lat: 35.808387, postalCode: "27512" },
    { lng: -80.48006, lat: 36.398995, postalCode: "27041" },
    { lng: -76.71853, lat: 35.176466, postalCode: "28515" },
    { lng: -79.041388, lat: 34.471259, postalCode: "28375" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27111" },
    { lng: -78.44868, lat: 33.913508, postalCode: "28469" },
    { lng: -76.4067, lat: 35.849234, postalCode: "27928" },
    { lng: -82.60288, lat: 35.311112, postalCode: "28729" },
    { lng: -76.67274, lat: 35.624117, postalCode: "27860" },
    { lng: -78.83023, lat: 34.312363, postalCode: "28431" },
    { lng: -77.31866, lat: 34.724322, postalCode: "28544" },
    { lng: -82.4945, lat: 35.37094, postalCode: "28758" },
    { lng: -77.1981, lat: 36.490929, postalCode: "27862" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27620" },
    { lng: -78.06759, lat: 35.748755, postalCode: "27880" },
    { lng: -77.37765, lat: 35.799764, postalCode: "27812" },
    { lng: -79.94122, lat: 36.083885, postalCode: "27409" },
    { lng: -75.94115, lat: 36.381161, postalCode: "27923" },
    { lng: -78.9594, lat: 34.804654, postalCode: "28384" },
    { lng: -79.4415, lat: 35.12416, postalCode: "28315" },
    { lng: -78.30615, lat: 36.484233, postalCode: "27553" },
    { lng: -76.49654, lat: 36.319777, postalCode: "27919" },
    { lng: -78.65316, lat: 35.788284, postalCode: "27605" },
    { lng: -77.391199, lat: 34.692056, postalCode: "28541" },
    { lng: -76.88086, lat: 36.339548, postalCode: "27922" },
    { lng: -80.2768, lat: 36.133577, postalCode: "27109" },
    { lng: -80.08603, lat: 35.871, postalCode: "27360" },
    { lng: -77.92463, lat: 35.377069, postalCode: "27534" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28265" },
    { lng: -77.95167, lat: 35.768693, postalCode: "27896" },
    { lng: -78.30135, lat: 35.848039, postalCode: "27597" },
    { lng: -76.13715, lat: 36.497406, postalCode: "27958" },
    { lng: -82.55632, lat: 35.595661, postalCode: "28801" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28254" },
    { lng: -80.44198, lat: 35.614966, postalCode: "28072" },
    { lng: -75.772775, lat: 35.698493, postalCode: "27978" },
    { lng: -81.84888, lat: 36.023359, postalCode: "28662" },
    { lng: -78.13903, lat: 36.378439, postalCode: "27589" },
    { lng: -78.83852, lat: 35.396654, postalCode: "27546" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27094" },
    { lng: -82.01719, lat: 35.709124, postalCode: "28752" },
    { lng: -82.29237, lat: 36.023817, postalCode: "28740" },
    { lng: -78.68964, lat: 35.322849, postalCode: "28339" },
    { lng: -78.92113, lat: 35.905765, postalCode: "27713" },
    { lng: -81.11615, lat: 36.169604, postalCode: "28659" },
    { lng: -83.47397, lat: 35.395491, postalCode: "28713" },
    { lng: -77.77076, lat: 35.935125, postalCode: "27801" },
    { lng: -81.695292, lat: 35.750657, postalCode: "28680" },
    { lng: -81.42942, lat: 35.545918, postalCode: "28168" },
    { lng: -76.44111, lat: 34.842394, postalCode: "28581" },
    { lng: -75.9518, lat: 36.513626, postalCode: "27950" },
    { lng: -78.10641, lat: 35.797988, postalCode: "27807" },
    { lng: -78.64667, lat: 35.808451, postalCode: "27608" },
    { lng: -79.806735, lat: 36.066129, postalCode: "27412" },
    { lng: -78.60317, lat: 35.939255, postalCode: "27614" },
    { lng: -77.513357, lat: 35.584685, postalCode: "27811" },
    { lng: -77.81173, lat: 35.598504, postalCode: "27883" },
    { lng: -81.06694, lat: 35.689806, postalCode: "28609" },
    { lng: -81.5371, lat: 36.474746, postalCode: "28693" },
    { lng: -82.43186, lat: 35.219796, postalCode: "28784" },
    { lng: -78.89731, lat: 35.997193, postalCode: "27701" },
    { lng: -81.74404, lat: 36.321009, postalCode: "28698" },
    { lng: -80.81872, lat: 36.115196, postalCode: "27020" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27110" },
    { lng: -78.63203, lat: 35.891726, postalCode: "27615" },
    { lng: -75.84497, lat: 36.435092, postalCode: "27927" },
    { lng: -81.12847, lat: 35.229565, postalCode: "28056" },
    { lng: -76.86007, lat: 36.440867, postalCode: "27935" },
    { lng: -79.09459, lat: 36.079726, postalCode: "27278" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28255" },
    { lng: -76.03822, lat: 36.233836, postalCode: "27974" },
    { lng: -79.0894, lat: 35.631434, postalCode: "27559" },
    { lng: -77.852599, lat: 34.088079, postalCode: "28406" },
    { lng: -77.32292, lat: 35.193213, postalCode: "28523" },
    { lng: -77.01509, lat: 35.119674, postalCode: "28560" },
    { lng: -76.00736, lat: 35.514202, postalCode: "27824" },
    { lng: -77.4559, lat: 34.755669, postalCode: "28540" },
    { lng: -78.10474, lat: 35.152512, postalCode: "28325" },
    { lng: -79.18802, lat: 34.69004, postalCode: "28372" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27419" },
    { lng: -79.60491, lat: 35.177643, postalCode: "27281" },
    { lng: -79.57555, lat: 35.447663, postalCode: "27325" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28240" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28287" },
    { lng: -78.1638, lat: 35.458912, postalCode: "27569" },
    { lng: -75.97255, lat: 35.113265, postalCode: "27960" },
    { lng: -77.58718, lat: 35.481503, postalCode: "28554" },
    { lng: -82.185, lat: 35.451314, postalCode: "28746" },
    { lng: -80.275963, lat: 35.882943, postalCode: "27361" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27117" },
    { lng: -77.37896, lat: 35.626653, postalCode: "27834" },
    { lng: -77.28809, lat: 36.281144, postalCode: "27869" },
    { lng: -81.62728, lat: 35.518637, postalCode: "28020" },
    { lng: -81.56804, lat: 35.740368, postalCode: "28690" },
    { lng: -83.11146, lat: 35.175847, postalCode: "28736" },
    { lng: -76.75097, lat: 36.410008, postalCode: "27938" },
    { lng: -82.52307, lat: 35.54179, postalCode: "28803" },
    { lng: -80.42959, lat: 35.536561, postalCode: "28138" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27261" },
    { lng: -79.72363, lat: 35.173481, postalCode: "28367" },
    { lng: -81.287181, lat: 35.679876, postalCode: "28603" },
    { lng: -77.392609, lat: 35.580444, postalCode: "27833" },
    { lng: -78.21676, lat: 36.450603, postalCode: "27563" },
    { lng: -81.884521, lat: 35.938422, postalCode: "28641" },
    { lng: -79.773094, lat: 36.392135, postalCode: "27323" },
    { lng: -79.129791, lat: 35.783476, postalCode: "27228" },
    { lng: -77.88697, lat: 34.449782, postalCode: "28457" },
    { lng: -78.5759, lat: 33.905566, postalCode: "28467" },
    { lng: -79.54609, lat: 34.938451, postalCode: "28363" },
    { lng: -82.494187, lat: 35.350962, postalCode: "28971" },
    { lng: -76.88972, lat: 34.884421, postalCode: "28532" },
    { lng: -77.68887, lat: 36.502541, postalCode: "27832" },
    { lng: -78.48214, lat: 34.07367, postalCode: "28420" },
    { lng: -79.23013, lat: 34.992085, postalCode: "28376" },
    { lng: -76.08293, lat: 36.384779, postalCode: "27973" },
    { lng: -75.67686, lat: 36.022242, postalCode: "27948" },
    { lng: -83.085, lat: 35.518138, postalCode: "28751" },
    { lng: -78.80234, lat: 35.575302, postalCode: "27526" },
    { lng: -82.55992, lat: 35.635844, postalCode: "28804" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27661" },
    { lng: -82.96774, lat: 35.527982, postalCode: "28745" },
    { lng: -80.85375, lat: 35.178543, postalCode: "28209" },
    { lng: -76.89242, lat: 34.689821, postalCode: "28575" },
    { lng: -78.10539, lat: 34.430433, postalCode: "28435" },
    { lng: -82.84253, lat: 35.139812, postalCode: "28772" },
    { lng: -76.16595, lat: 36.344333, postalCode: "27921" },
    { lng: -77.75929, lat: 34.826715, postalCode: "28521" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27611" },
    { lng: -78.51224, lat: 33.885203, postalCode: "28468" },
    { lng: -80.15214, lat: 35.228831, postalCode: "28128" },
    { lng: -83.58395, lat: 35.412166, postalCode: "28702" },
    { lng: -80.73061, lat: 34.929433, postalCode: "28173" },
    { lng: -83.009416, lat: 35.569047, postalCode: "28785" },
    { lng: -80.61167, lat: 35.543639, postalCode: "28088" },
    { lng: -79.389631, lat: 36.046677, postalCode: "27220" },
    { lng: -80.11171, lat: 35.40846, postalCode: "28009" },
    { lng: -79.55518, lat: 36.478349, postalCode: "27326" },
    { lng: -80.70809, lat: 36.399366, postalCode: "27031" },
    { lng: -79.17848, lat: 35.756091, postalCode: "27312" },
    { lng: -82.528558, lat: 35.620335, postalCode: "28810" },
    { lng: -78.34446, lat: 35.506459, postalCode: "27577" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27438" },
    { lng: -79.80389, lat: 35.817411, postalCode: "27317" },
    { lng: -80.947028, lat: 35.529792, postalCode: "28688" },
    { lng: -81.66255, lat: 36.21277, postalCode: "28607" },
    { lng: -80.21272, lat: 35.043566, postalCode: "28135" },
    { lng: -80.081269, lat: 35.875344, postalCode: "27630" },
    { lng: -77.98298, lat: 35.491814, postalCode: "27863" },
    { lng: -79.12124, lat: 34.368246, postalCode: "28362" },
    { lng: -78.74102, lat: 35.145952, postalCode: "28395" },
    { lng: -78.43861, lat: 36.215193, postalCode: "27544" },
    { lng: -76.213284, lat: 36.285388, postalCode: "27906" },
    { lng: -77.59066, lat: 35.598204, postalCode: "27828" },
    { lng: -79.78487, lat: 35.414095, postalCode: "27356" },
    { lng: -80.79221, lat: 35.222406, postalCode: "28205" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27150" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28244" },
    { lng: -78.782556, lat: 35.681429, postalCode: "27539" },
    { lng: -81.07238, lat: 35.377576, postalCode: "28164" },
    { lng: -78.63263, lat: 35.844753, postalCode: "27609" },
    { lng: -79.55521, lat: 35.79386, postalCode: "27355" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28261" },
    { lng: -80.84586, lat: 36.283497, postalCode: "28621" },
    { lng: -79.09331, lat: 36.495772, postalCode: "27343" },
    { lng: -80.716495, lat: 35.276794, postalCode: "28126" },
    { lng: -78.23438, lat: 34.595158, postalCode: "28447" },
    { lng: -81.90629, lat: 35.666254, postalCode: "28761" },
    { lng: -81.02213, lat: 35.119012, postalCode: "28278" },
    { lng: -80.62098, lat: 35.105208, postalCode: "28079" },
    { lng: -76.23692, lat: 36.293192, postalCode: "27909" },
    { lng: -81.817998, lat: 35.721174, postalCode: "28628" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28260" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27417" },
    { lng: -78.88293, lat: 36.238956, postalCode: "27572" },
    { lng: -79.32836, lat: 34.735024, postalCode: "28364" },
    { lng: -82.99607, lat: 35.511094, postalCode: "28786" },
    { lng: -79.452352, lat: 35.216222, postalCode: "28370" },
    { lng: -76.69263, lat: 35.040757, postalCode: "28571" },
    { lng: -80.64447, lat: 35.499521, postalCode: "28081" },
    { lng: -78.30961, lat: 33.974251, postalCode: "28462" },
    { lng: -78.58643, lat: 34.647611, postalCode: "28337" },
    { lng: -82.165546, lat: 35.640648, postalCode: "28767" },
    { lng: -75.87867, lat: 36.273428, postalCode: "27965" },
    { lng: -80.23777, lat: 36.297049, postalCode: "27019" },
    { lng: -79.69001, lat: 34.879186, postalCode: "28345" },
    { lng: -81.95039, lat: 35.48902, postalCode: "28167" },
    { lng: -83.233302, lat: 35.328533, postalCode: "28788" },
    { lng: -76.373908, lat: 36.198539, postalCode: "27930" },
    { lng: -80.898668, lat: 35.462187, postalCode: "28070" },
    { lng: -82.503226, lat: 35.322276, postalCode: "28727" },
    { lng: -78.970224, lat: 36.416628, postalCode: "27574" },
    { lng: -78.80645, lat: 34.742752, postalCode: "28392" },
    { lng: -80.59377, lat: 35.787743, postalCode: "27054" },
    { lng: -79.50839, lat: 36.152742, postalCode: "27244" },
    { lng: -76.660435, lat: 35.191279, postalCode: "28587" },
    { lng: -77.97788, lat: 34.738793, postalCode: "28466" },
    { lng: -76.98612, lat: 36.293352, postalCode: "27910" },
    { lng: -82.241744, lat: 35.436893, postalCode: "28720" },
    { lng: -80.68424, lat: 35.067285, postalCode: "28104" },
    { lng: -80.8981, lat: 35.765719, postalCode: "28677" },
    { lng: -80.275963, lat: 35.882943, postalCode: "27373" },
    { lng: -78.45805, lat: 35.921662, postalCode: "27571" },
    { lng: -80.62336, lat: 36.49376, postalCode: "27030" },
    { lng: -82.532081, lat: 35.486156, postalCode: "28808" },
    { lng: -78.84124, lat: 35.042389, postalCode: "28301" },
    { lng: -82.21712, lat: 35.221428, postalCode: "28782" },
    { lng: -77.91989, lat: 35.715315, postalCode: "27893" },
    { lng: -82.492664, lat: 35.664752, postalCode: "28814" },
    { lng: -79.10931, lat: 35.912786, postalCode: "27516" },
    { lng: -75.86047, lat: 36.189366, postalCode: "27947" },
    { lng: -79.16879, lat: 36.207994, postalCode: "27231" },
    { lng: -80.7613, lat: 35.113906, postalCode: "28270" },
    { lng: -79.18649, lat: 36.082138, postalCode: "27243" },
    { lng: -79.10586, lat: 35.35149, postalCode: "28368" },
    { lng: -78.13755, lat: 35.606392, postalCode: "27542" },
    { lng: -83.30887, lat: 35.418018, postalCode: "28789" },
    { lng: -78.51432, lat: 34.945305, postalCode: "28382" },
    { lng: -75.85014, lat: 35.795867, postalCode: "27953" },
    { lng: -79.14419, lat: 35.401023, postalCode: "27332" },
    { lng: -75.87361, lat: 36.239666, postalCode: "27939" },
    { lng: -78.70773, lat: 34.312063, postalCode: "28472" },
    { lng: -78.908828, lat: 35.034307, postalCode: "28302" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27656" },
    { lng: -79.90244, lat: 35.808395, postalCode: "27350" },
    { lng: -76.899976, lat: 34.903793, postalCode: "28533" },
    { lng: -76.79019, lat: 35.308035, postalCode: "27806" },
    { lng: -82.92258, lat: 35.146004, postalCode: "28747" },
    { lng: -82.388325, lat: 35.295403, postalCode: "28724" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27115" },
    { lng: -78.92179, lat: 35.003712, postalCode: "28306" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27628" },
    { lng: -77.67144, lat: 36.448592, postalCode: "27870" },
    { lng: -79.54591, lat: 35.559258, postalCode: "27208" },
    { lng: -81.42635, lat: 36.557892, postalCode: "28631" },
    { lng: -79.54383, lat: 35.248989, postalCode: "27376" },
    { lng: -82.41834, lat: 35.280702, postalCode: "28726" },
    { lng: -78.97375, lat: 36.414739, postalCode: "27573" },
    { lng: -78.97499, lat: 35.211913, postalCode: "28390" },
    { lng: -83.62998, lat: 35.241747, postalCode: "28781" },
    { lng: -77.364499, lat: 36.064879, postalCode: "27859" },
    { lng: -80.4417, lat: 36.331502, postalCode: "27043" },
    { lng: -81.23443, lat: 35.653437, postalCode: "28658" },
    { lng: -82.52043, lat: 35.866763, postalCode: "28754" },
    { lng: -79.28751, lat: 35.562184, postalCode: "27256" },
    { lng: -77.22214, lat: 34.879658, postalCode: "28555" },
    { lng: -77.51319, lat: 36.52177, postalCode: "27866" },
    { lng: -80.464515, lat: 35.708966, postalCode: "27144" },
    { lng: -77.502602, lat: 36.235198, postalCode: "27887" },
    { lng: -78.02336, lat: 35.640324, postalCode: "27851" },
    { lng: -80.91951, lat: 36.394123, postalCode: "28683" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27634" },
    { lng: -81.14279, lat: 35.725163, postalCode: "28610" },
    { lng: -81.68783, lat: 36.133083, postalCode: "28605" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28232" },
    { lng: -80.44593, lat: 35.677889, postalCode: "28039" },
    { lng: -77.21501, lat: 35.000858, postalCode: "28573" },
    { lng: -83.08029, lat: 35.419931, postalCode: "28707" },
    { lng: -77.78636, lat: 36.032405, postalCode: "27809" },
    { lng: -81.07978, lat: 35.256379, postalCode: "28101" },
    { lng: -77.56469, lat: 35.260895, postalCode: "28501" },
    { lng: -81.20268, lat: 35.404772, postalCode: "28077" },
    { lng: -82.50652, lat: 35.290454, postalCode: "28739" },
    { lng: -79.12266, lat: 34.851865, postalCode: "28386" },
    { lng: -78.92313, lat: 36.087738, postalCode: "27712" },
    { lng: -82.37542, lat: 35.526686, postalCode: "28730" },
    { lng: -81.48192, lat: 35.840553, postalCode: "28638" },
    { lng: -80.19588, lat: 36.429179, postalCode: "27016" },
    { lng: -79.246841, lat: 35.02363, postalCode: "28361" },
    { lng: -80.7164, lat: 35.116851, postalCode: "28105" },
    { lng: -75.98097, lat: 36.388493, postalCode: "27917" },
    { lng: -75.47152, lat: 35.56179, postalCode: "27982" },
    { lng: -80.73303, lat: 35.305552, postalCode: "28223" },
    { lng: -80.89034, lat: 35.086017, postalCode: "28134" },
    { lng: -79.59239, lat: 34.767875, postalCode: "28343" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27416" },
    { lng: -81.37303, lat: 36.195632, postalCode: "28665" },
    { lng: -80.41228, lat: 35.407499, postalCode: "28124" },
    { lng: -80.28811, lat: 35.485703, postalCode: "28109" },
    { lng: -79.80644, lat: 36.156707, postalCode: "27455" },
    { lng: -77.91346, lat: 34.152021, postalCode: "28412" },
    { lng: -79.88921, lat: 36.209976, postalCode: "27358" },
    { lng: -80.541088, lat: 35.346285, postalCode: "28026" },
    { lng: -80.35635, lat: 34.99545, postalCode: "28103" },
    { lng: -76.255312, lat: 36.285026, postalCode: "27907" },
    { lng: -77.88452, lat: 34.221512, postalCode: "28403" },
    { lng: -77.64106, lat: 35.795738, postalCode: "27864" },
    { lng: -79.59483, lat: 36.050652, postalCode: "27377" },
    { lng: -81.713416, lat: 36.221334, postalCode: "28608" },
    { lng: -78.857651, lat: 36.051155, postalCode: "27710" },
    { lng: -77.91092, lat: 33.99207, postalCode: "28449" },
    { lng: -77.27835, lat: 35.908558, postalCode: "27841" },
    { lng: -77.792177, lat: 34.212707, postalCode: "28408" },
    { lng: -79.767341, lat: 34.994152, postalCode: "28380" },
    { lng: -77.57157, lat: 34.888736, postalCode: "28574" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27518" },
    { lng: -80.57143, lat: 35.920477, postalCode: "27028" },
    { lng: -80.84419, lat: 35.227192, postalCode: "28202" },
    { lng: -79.19809, lat: 36.285009, postalCode: "27314" },
    { lng: -81.33012, lat: 35.759932, postalCode: "28601" },
    { lng: -78.24953, lat: 36.062239, postalCode: "27549" },
    { lng: -77.01025, lat: 34.667473, postalCode: "28594" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28221" },
    { lng: -77.20441, lat: 36.332552, postalCode: "27897" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28285" },
    { lng: -76.73206, lat: 35.842107, postalCode: "27962" },
    { lng: -81.79475, lat: 35.279371, postalCode: "28019" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27690" },
    { lng: -80.52694, lat: 36.198622, postalCode: "27018" },
    { lng: -78.61595, lat: 36.340501, postalCode: "27565" },
    { lng: -80.558743, lat: 35.011204, postalCode: "28111" },
    { lng: -78.89411, lat: 35.134301, postalCode: "28311" },
    { lng: -80.6633, lat: 35.335294, postalCode: "28075" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27099" },
    { lng: -78.829548, lat: 35.76812, postalCode: "27519" },
    { lng: -80.34752, lat: 36.473951, postalCode: "27053" },
    { lng: -77.280169, lat: 35.567478, postalCode: "27879" },
    { lng: -78.72657, lat: 36.195036, postalCode: "27581" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27429" },
    { lng: -77.030502, lat: 34.662495, postalCode: "28599" },
    { lng: -80.02232, lat: 34.858713, postalCode: "28119" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27498" },
    { lng: -78.699417, lat: 34.290716, postalCode: "28424" },
    { lng: -76.38149, lat: 34.868975, postalCode: "28577" },
    { lng: -79.99741, lat: 35.227919, postalCode: "27306" },
    { lng: -79.82919, lat: 35.686122, postalCode: "27203" },
    { lng: -80.30391, lat: 36.232354, postalCode: "27045" },
    { lng: -78.82187, lat: 34.12483, postalCode: "28463" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27108" },
    { lng: -76.44693, lat: 35.481914, postalCode: "27875" },
    { lng: -78.98986, lat: 34.620874, postalCode: "28358" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27676" },
    { lng: -79.00848, lat: 35.060087, postalCode: "28314" },
    { lng: -78.690428, lat: 35.015586, postalCode: "28397" },
    { lng: -78.00893, lat: 35.384344, postalCode: "27530" },
    { lng: -78.3878, lat: 33.9666, postalCode: "28470" },
    { lng: -80.23174, lat: 35.775789, postalCode: "27292" },
    { lng: -80.947028, lat: 35.529792, postalCode: "28687" },
    { lng: -81.84672, lat: 36.166515, postalCode: "28604" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28256" },
    { lng: -80.5824, lat: 36.305687, postalCode: "27047" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28243" },
    { lng: -78.83866, lat: 35.846363, postalCode: "27560" },
    { lng: -78.857651, lat: 36.051155, postalCode: "27711" },
    { lng: -81.12368, lat: 35.454565, postalCode: "28080" },
    { lng: -77.01668, lat: 36.507288, postalCode: "27818" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27155" },
    { lng: -79.79467, lat: 35.280008, postalCode: "27229" },
    { lng: -78.79613, lat: 35.237338, postalCode: "28356" },
    { lng: -79.107692, lat: 36.05251, postalCode: "27599" },
    { lng: -79.357399, lat: 36.021389, postalCode: "27359" },
    { lng: -79.21215, lat: 34.828798, postalCode: "28377" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28289" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28242" },
    { lng: -80.845843, lat: 35.225242, postalCode: "28296" },
    { lng: -79.87112, lat: 36.025379, postalCode: "27407" },
    { lng: -81.47928, lat: 35.189591, postalCode: "28073" },
    { lng: -82.83859, lat: 35.289152, postalCode: "28708" },
    { lng: -77.21536, lat: 36.184037, postalCode: "27847" },
    { lng: -81.41878, lat: 35.718432, postalCode: "28637" },
    { lng: -75.47473, lat: 35.538351, postalCode: "27972" },
    { lng: -78.32406, lat: 34.686496, postalCode: "28444" },
    { lng: -78.51678, lat: 34.319538, postalCode: "28450" },
    { lng: -79.020849, lat: 35.892359, postalCode: "27517" },
    { lng: -81.43023, lat: 35.777241, postalCode: "28667" },
    { lng: -80.46645, lat: 35.679639, postalCode: "28144" },
    { lng: -81.71625, lat: 35.742752, postalCode: "28655" },
    { lng: -79.63723, lat: 35.711954, postalCode: "27316" },
    { lng: -82.40531, lat: 35.60243, postalCode: "28778" },
    { lng: -79.63132, lat: 36.075975, postalCode: "27342" },
    { lng: -83.25321, lat: 35.36852, postalCode: "28725" },
    { lng: -81.07753, lat: 35.239702, postalCode: "28032" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27151" },
    { lng: -79.99345, lat: 35.950436, postalCode: "27260" },
    { lng: -80.56002, lat: 35.031947, postalCode: "28110" },
    { lng: -81.42262, lat: 36.110425, postalCode: "28624" },
    { lng: -77.8601, lat: 35.809514, postalCode: "27822" },
    { lng: -78.05955, lat: 34.888483, postalCode: "28453" },
    { lng: -80.63823, lat: 35.405636, postalCode: "28027" },
    { lng: -81.13146, lat: 36.315077, postalCode: "28635" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28272" },
    { lng: -81.94286, lat: 36.049348, postalCode: "28657" },
    { lng: -78.78102, lat: 34.552642, postalCode: "28320" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27427" },
    { lng: -81.21466, lat: 35.720227, postalCode: "28613" },
    { lng: -79.014425, lat: 34.607669, postalCode: "28359" },
    { lng: -76.4275, lat: 36.157292, postalCode: "27944" },
    { lng: -76.76737, lat: 36.289302, postalCode: "27942" },
    { lng: -77.392609, lat: 35.580444, postalCode: "27836" },
    { lng: -79.31355, lat: 35.24356, postalCode: "28350" },
    { lng: -83.295244, lat: 35.117299, postalCode: "28744" },
    { lng: -78.915788, lat: 35.150896, postalCode: "28331" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27413" },
    { lng: -81.15312, lat: 35.263287, postalCode: "28054" },
    { lng: -81.65141, lat: 36.455882, postalCode: "28615" },
    { lng: -80.08543, lat: 34.985423, postalCode: "28170" },
    { lng: -79.37815, lat: 36.031837, postalCode: "27253" },
    { lng: -78.69122, lat: 36.111937, postalCode: "27522" },
    { lng: -79.56809, lat: 35.884276, postalCode: "27298" },
    { lng: -79.93573, lat: 35.998221, postalCode: "27282" },
    { lng: -78.552388, lat: 35.817497, postalCode: "27629" },
    { lng: -75.81974, lat: 36.099065, postalCode: "27941" },
    { lng: -78.75882, lat: 36.138298, postalCode: "27509" },
    { lng: -78.882395, lat: 35.429849, postalCode: "27543" },
    { lng: -83.29698, lat: 35.50359, postalCode: "28719" },
    { lng: -83.76683, lat: 35.052591, postalCode: "28904" },
    { lng: -77.33836, lat: 35.579216, postalCode: "27858" },
    { lng: -80.33123, lat: 35.232542, postalCode: "28129" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27116" },
    { lng: -80.283647, lat: 35.660548, postalCode: "27351" },
    { lng: -78.953, lat: 35.084163, postalCode: "28303" },
    { lng: -78.857651, lat: 36.051155, postalCode: "27709" },
    { lng: -80.96111, lat: 35.276639, postalCode: "28214" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28201" },
    { lng: -81.165972, lat: 36.214395, postalCode: "28559" },
    { lng: -80.86182, lat: 36.326001, postalCode: "28676" },
    { lng: -81.92506, lat: 35.360536, postalCode: "28160" },
    { lng: -75.80319, lat: 36.093211, postalCode: "27964" },
    { lng: -80.02239, lat: 35.964586, postalCode: "27262" },
    { lng: -77.27014, lat: 35.700448, postalCode: "27884" },
    { lng: -76.46994, lat: 36.217959, postalCode: "27985" },
    { lng: -81.24094, lat: 35.473447, postalCode: "28092" },
    { lng: -82.21395, lat: 35.909707, postalCode: "28755" },
    { lng: -79.71018, lat: 35.53332, postalCode: "27341" },
    { lng: -80.28961, lat: 35.856896, postalCode: "27295" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27624" },
    { lng: -76.57584, lat: 35.892486, postalCode: "27970" },
    { lng: -79.485643, lat: 36.031836, postalCode: "27201" },
    { lng: -78.63274, lat: 35.774451, postalCode: "27601" },
    { lng: -77.926691, lat: 35.719923, postalCode: "27895" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28224" },
    { lng: -77.80572, lat: 34.285519, postalCode: "28411" },
    { lng: -77.314288, lat: 35.819037, postalCode: "27861" },
    { lng: -78.6941, lat: 35.016373, postalCode: "28391" },
    { lng: -76.702125, lat: 36.424152, postalCode: "27969" },
    { lng: -77.764819, lat: 34.924076, postalCode: "28818" },
    { lng: -79.333836, lat: 36.403393, postalCode: "27397" },
    { lng: -75.55787, lat: 35.263128, postalCode: "27920" },
    { lng: -79.82302, lat: 34.913233, postalCode: "28330" },
    { lng: -75.82653, lat: 36.13655, postalCode: "27966" },
    { lng: -77.69186, lat: 35.44451, postalCode: "28580" },
    { lng: -80.30733, lat: 36.066545, postalCode: "27103" },
    { lng: -78.90369, lat: 35.056963, postalCode: "28305" },
    { lng: -79.479743, lat: 36.047523, postalCode: "27216" },
    { lng: -80.275963, lat: 35.882943, postalCode: "27294" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28281" },
    { lng: -77.65343, lat: 34.676719, postalCode: "28454" },
    { lng: -81.492983, lat: 36.414156, postalCode: "28672" },
    { lng: -77.83054, lat: 35.867224, postalCode: "27878" },
    { lng: -79.42344, lat: 35.897155, postalCode: "27349" },
    { lng: -78.04505, lat: 36.474898, postalCode: "27551" },
    { lng: -76.77855, lat: 35.47031, postalCode: "27808" },
    { lng: -76.64783, lat: 34.769532, postalCode: "28516" },
    { lng: -78.387374, lat: 36.33705, postalCode: "27537" },
    { lng: -76.33033, lat: 34.887266, postalCode: "28511" },
    { lng: -79.197058, lat: 34.595364, postalCode: "28319" },
    { lng: -83.94882, lat: 35.02851, postalCode: "28902" },
    { lng: -82.31665, lat: 35.605146, postalCode: "28711" },
    { lng: -80.82752, lat: 35.197643, postalCode: "28207" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28288" },
    { lng: -78.64362, lat: 34.491177, postalCode: "28433" },
    { lng: -77.20701, lat: 34.694262, postalCode: "28539" },
    { lng: -81.27076, lat: 36.241685, postalCode: "28651" },
    { lng: -75.67961, lat: 35.913938, postalCode: "27954" },
    { lng: -82.85103, lat: 35.503301, postalCode: "28716" },
    { lng: -80.93481, lat: 36.204458, postalCode: "28670" },
    { lng: -81.57867, lat: 35.258879, postalCode: "28152" },
    { lng: -78.65734, lat: 35.716105, postalCode: "27603" },
    { lng: -75.50417, lat: 35.354252, postalCode: "27915" },
    { lng: -78.24044, lat: 36.441215, postalCode: "27570" },
    { lng: -82.449228, lat: 35.299726, postalCode: "28972" },
    { lng: -79.90522, lat: 36.47266, postalCode: "27048" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27658" },
    { lng: -79.08338, lat: 36.264894, postalCode: "27541" },
    { lng: -78.42116, lat: 35.4152, postalCode: "27524" },
    { lng: -79.107692, lat: 36.05251, postalCode: "27515" },
    { lng: -75.61144, lat: 35.923805, postalCode: "27959" },
    { lng: -78.19035, lat: 34.006479, postalCode: "28422" },
    { lng: -80.16679, lat: 36.174243, postalCode: "27051" },
    { lng: -81.42409, lat: 36.410186, postalCode: "28640" },
    { lng: -76.46317, lat: 34.794527, postalCode: "28524" },
    { lng: -82.34855, lat: 35.478887, postalCode: "28735" },
    { lng: -80.87903, lat: 35.286967, postalCode: "28216" },
    { lng: -79.783404, lat: 35.451411, postalCode: "27247" },
    { lng: -78.9999, lat: 34.912113, postalCode: "28371" },
    { lng: -81.045963, lat: 36.238438, postalCode: "28656" },
    { lng: -78.857651, lat: 36.051155, postalCode: "27717" },
    { lng: -78.360723, lat: 35.590731, postalCode: "27593" },
    { lng: -79.33107, lat: 36.387904, postalCode: "27379" },
    { lng: -76.632264, lat: 35.156145, postalCode: "28509" },
    { lng: -77.93311, lat: 35.633996, postalCode: "27813" },
    { lng: -77.40241, lat: 35.999591, postalCode: "27843" },
    { lng: -80.36487, lat: 36.182718, postalCode: "27040" },
    { lng: -77.73, lat: 36.10607, postalCode: "27891" },
    { lng: -77.585471, lat: 35.915927, postalCode: "27881" },
    { lng: -80.5382, lat: 34.936388, postalCode: "28112" },
    { lng: -80.71056, lat: 35.664696, postalCode: "28125" },
    { lng: -76.51632, lat: 34.725663, postalCode: "28553" },
    { lng: -81.934265, lat: 36.086466, postalCode: "28652" },
    { lng: -77.911635, lat: 36.054832, postalCode: "27868" },
    { lng: -76.22037, lat: 35.883885, postalCode: "27925" },
    { lng: -81.885081, lat: 35.224743, postalCode: "28074" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28222" },
    { lng: -79.15153, lat: 35.278125, postalCode: "28326" },
    { lng: -79.34421, lat: 36.064176, postalCode: "27258" },
    { lng: -79.791901, lat: 36.106711, postalCode: "27402" },
    { lng: -82.30008, lat: 35.891276, postalCode: "28714" },
    { lng: -76.83458, lat: 36.192231, postalCode: "27924" },
    { lng: -79.97548, lat: 36.252154, postalCode: "27357" },
    { lng: -79.69835, lat: 35.761433, postalCode: "27248" },
    { lng: -78.348111, lat: 34.83661, postalCode: "28446" },
    { lng: -77.94761, lat: 34.977225, postalCode: "28349" },
    { lng: -80.08726, lat: 36.119887, postalCode: "27284" },
    { lng: -81.42911, lat: 35.362026, postalCode: "28169" },
    { lng: -81.17462, lat: 36.136991, postalCode: "28697" },
    { lng: -75.72772, lat: 36.115899, postalCode: "27949" },
    { lng: -81.53545, lat: 35.753767, postalCode: "28671" },
    { lng: -77.69208, lat: 35.119845, postalCode: "28525" },
    { lng: -78.86462, lat: 35.73663, postalCode: "27502" },
    { lng: -80.43115, lat: 35.614422, postalCode: "28146" },
    { lng: -81.3534, lat: 35.70701, postalCode: "28602" },
    { lng: -79.81425, lat: 36.101385, postalCode: "27408" },
    { lng: -81.47784, lat: 36.375468, postalCode: "28694" },
    { lng: -77.19032, lat: 36.083011, postalCode: "27849" },
    { lng: -78.63141, lat: 35.851463, postalCode: "27619" },
    { lng: -78.29701, lat: 34.464635, postalCode: "28448" },
    { lng: -78.93602, lat: 35.963994, postalCode: "27707" },
    { lng: -80.99639, lat: 36.229983, postalCode: "28669" },
    { lng: -80.831693, lat: 35.187943, postalCode: "28274" },
    { lng: -78.70765, lat: 34.786138, postalCode: "28399" },
    { lng: -79.280088, lat: 35.267373, postalCode: "28894" },
    { lng: -76.78706, lat: 34.698274, postalCode: "28512" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28241" },
    { lng: -79.63191, lat: 35.936904, postalCode: "27283" },
    { lng: -82.67373, lat: 35.272331, postalCode: "28768" },
    { lng: -78.6514, lat: 35.191142, postalCode: "28342" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28284" },
    { lng: -76.03362, lat: 36.419422, postalCode: "27956" },
    { lng: -78.93735, lat: 35.993143, postalCode: "27706" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27626" },
    { lng: -79.329664, lat: 35.948814, postalCode: "27340" },
    { lng: -75.68792, lat: 35.218342, postalCode: "27943" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28236" },
    { lng: -78.738904, lat: 35.898538, postalCode: "27561" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28290" },
    { lng: -78.18584, lat: 34.524003, postalCode: "28421" },
    { lng: -82.597992, lat: 35.564734, postalCode: "28800" },
    { lng: -78.28251, lat: 34.36338, postalCode: "28456" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28229" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27699" },
    { lng: -81.189693, lat: 35.284018, postalCode: "28055" },
    { lng: -82.18229, lat: 35.625414, postalCode: "28762" },
    { lng: -81.43478, lat: 35.811919, postalCode: "28630" },
    { lng: -78.110931, lat: 36.37367, postalCode: "27594" },
    { lng: -76.93188, lat: 35.384013, postalCode: "27814" },
    { lng: -77.77649, lat: 35.65363, postalCode: "27873" },
    { lng: -80.09267, lat: 36.488575, postalCode: "27046" },
    { lng: -80.74742, lat: 35.189544, postalCode: "28212" },
    { lng: -78.36593, lat: 35.237142, postalCode: "28366" },
    { lng: -77.084958, lat: 35.866711, postalCode: "27825" },
    { lng: -82.502644, lat: 35.50042, postalCode: "28813" },
    { lng: -77.41983, lat: 36.128851, postalCode: "27874" },
    { lng: -82.30048, lat: 35.618596, postalCode: "28770" },
    { lng: -79.27404, lat: 34.571076, postalCode: "28383" },
    { lng: -77.82238, lat: 35.11493, postalCode: "28508" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28230" },
    { lng: -81.56223, lat: 35.450548, postalCode: "28090" },
    { lng: -78.46244, lat: 34.461167, postalCode: "28434" },
    { lng: -75.63954, lat: 35.845046, postalCode: "27981" },
    { lng: -77.90383, lat: 36.451785, postalCode: "27850" },
    { lng: -76.81297, lat: 35.000658, postalCode: "28510" },
    { lng: -76.76046, lat: 36.067244, postalCode: "27957" },
    { lng: -78.07127, lat: 33.926255, postalCode: "28404" },
    { lng: -78.39425, lat: 36.340681, postalCode: "27536" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28130" },
    { lng: -76.78039, lat: 36.501152, postalCode: "27937" },
    { lng: -79.97458, lat: 36.421448, postalCode: "27027" },
    { lng: -76.94686, lat: 36.234231, postalCode: "27967" },
    { lng: -76.55591, lat: 35.259895, postalCode: "28537" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27199" },
    { lng: -78.75997, lat: 34.17074, postalCode: "28432" },
    { lng: -77.82694, lat: 35.973773, postalCode: "27804" },
    { lng: -81.30497, lat: 36.058853, postalCode: "28606" },
    { lng: -78.5565, lat: 33.997345, postalCode: "28452" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27499" },
    { lng: -76.97842, lat: 34.673805, postalCode: "28954" },
    { lng: -81.39368, lat: 35.383935, postalCode: "28021" },
    { lng: -79.479016, lat: 35.492077, postalCode: "27259" },
    { lng: -83.94231, lat: 35.152197, postalCode: "28905" },
    { lng: -78.63648, lat: 35.171062, postalCode: "28344" },
    { lng: -80.05135, lat: 36.335676, postalCode: "27042" },
    { lng: -80.44132, lat: 35.954679, postalCode: "27006" },
    { lng: -78.33424, lat: 35.000056, postalCode: "28328" },
    { lng: -82.528558, lat: 35.620335, postalCode: "28815" },
    { lng: -81.92693, lat: 36.024252, postalCode: "28616" },
    { lng: -81.16056, lat: 36.073489, postalCode: "28654" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28228" },
    { lng: -76.62077, lat: 36.093252, postalCode: "27932" },
    { lng: -80.640383, lat: 34.916253, postalCode: "28108" },
    { lng: -78.052422, lat: 35.372045, postalCode: "27533" },
    { lng: -80.96141, lat: 35.585074, postalCode: "28682" },
    { lng: -80.97109, lat: 36.523031, postalCode: "28623" },
    { lng: -81.00733, lat: 36.38846, postalCode: "28668" },
    { lng: -83.21173, lat: 35.06252, postalCode: "28741" },
    { lng: -79.76839, lat: 35.354158, postalCode: "27209" },
    { lng: -80.59116, lat: 35.567189, postalCode: "28023" },
    { lng: -80.80258, lat: 35.584157, postalCode: "28115" },
    { lng: -76.9349, lat: 36.015391, postalCode: "27983" },
    { lng: -77.60825, lat: 36.421387, postalCode: "27890" },
    { lng: -80.338662, lat: 36.182975, postalCode: "27010" },
    { lng: -78.4489, lat: 35.635275, postalCode: "27520" },
    { lng: -82.41471, lat: 35.756437, postalCode: "28709" },
    { lng: -79.773094, lat: 36.392135, postalCode: "27375" },
    { lng: -79.06282, lat: 34.659575, postalCode: "28360" },
    { lng: -80.82139, lat: 35.107804, postalCode: "28226" },
    { lng: -80.19879, lat: 35.351477, postalCode: "28001" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27622" },
    { lng: -77.57821, lat: 36.310967, postalCode: "27839" },
    { lng: -80.44305, lat: 34.981605, postalCode: "28174" },
    { lng: -77.77153, lat: 34.911417, postalCode: "28518" },
    { lng: -77.10287, lat: 36.432993, postalCode: "27855" },
    { lng: -77.62481, lat: 35.264739, postalCode: "28504" },
    { lng: -78.87419, lat: 36.158344, postalCode: "27503" },
    { lng: -79.482423, lat: 34.781768, postalCode: "28353" },
    { lng: -77.901408, lat: 34.340518, postalCode: "28402" },
    { lng: -77.78042, lat: 35.320045, postalCode: "28551" },
    { lng: -79.50496, lat: 36.185712, postalCode: "27202" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27495" },
    { lng: -77.08093, lat: 35.824061, postalCode: "27892" },
    { lng: -78.92398, lat: 36.028685, postalCode: "27708" },
    { lng: -76.92641, lat: 36.386605, postalCode: "27986" },
    { lng: -82.6062, lat: 35.575877, postalCode: "28806" },
    { lng: -77.87444, lat: 35.206329, postalCode: "28578" },
    { lng: -81.75656, lat: 35.243585, postalCode: "28114" },
    { lng: -82.41453, lat: 35.29118, postalCode: "28796" },
    { lng: -78.857651, lat: 36.051155, postalCode: "27722" },
    { lng: -81.2142, lat: 35.248787, postalCode: "28052" },
    { lng: -78.6608, lat: 34.11045, postalCode: "28455" },
    { lng: -79.879711, lat: 35.802758, postalCode: "27230" },
    { lng: -78.11872, lat: 35.94178, postalCode: "27882" },
    { lng: -80.4163, lat: 35.245048, postalCode: "28163" },
    { lng: -78.83486, lat: 35.643545, postalCode: "27540" },
    { lng: -78.08407, lat: 34.993016, postalCode: "28398" },
    { lng: -77.41061, lat: 35.238886, postalCode: "28526" },
    { lng: -78.713346, lat: 36.101529, postalCode: "27564" },
    { lng: -79.69839, lat: 36.205771, postalCode: "27214" },
    { lng: -77.83689, lat: 35.924465, postalCode: "27803" },
    { lng: -77.64342, lat: 35.746654, postalCode: "27852" },
    { lng: -81.539847, lat: 35.937564, postalCode: "28633" },
    { lng: -78.44921, lat: 36.000487, postalCode: "27596" },
    { lng: -79.75441, lat: 35.937595, postalCode: "27313" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27523" },
    { lng: -80.842543, lat: 35.227542, postalCode: "28246" },
    { lng: -77.386748, lat: 36.363144, postalCode: "27854" },
    { lng: -80.275963, lat: 35.882943, postalCode: "27293" },
    { lng: -77.57834, lat: 36.458825, postalCode: "27831" },
    { lng: -81.86722, lat: 35.317602, postalCode: "28043" },
    { lng: -77.27013, lat: 35.948539, postalCode: "27857" },
    { lng: -82.236198, lat: 35.236179, postalCode: "28750" },
    { lng: -83.42225, lat: 35.050529, postalCode: "28763" },
    { lng: -80.85539, lat: 35.208992, postalCode: "28203" },
    { lng: -81.95958, lat: 36.17893, postalCode: "28622" },
    { lng: -77.94134, lat: 34.235219, postalCode: "28401" },
    { lng: -80.93954, lat: 35.124032, postalCode: "28273" },
    { lng: -78.842868, lat: 35.039726, postalCode: "28309" },
    { lng: -81.07441, lat: 35.928486, postalCode: "28636" },
    { lng: -79.165314, lat: 35.467567, postalCode: "28355" },
    { lng: -80.55724, lat: 35.812635, postalCode: "27014" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27640" },
    { lng: -77.01969, lat: 35.120742, postalCode: "28519" },
    { lng: -77.10614, lat: 34.69771, postalCode: "28584" },
    { lng: -80.72164, lat: 35.244468, postalCode: "28215" },
    { lng: -79.24449, lat: 35.218131, postalCode: "28394" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27130" },
    { lng: -81.2111, lat: 36.329636, postalCode: "28649" },
    { lng: -79.04705, lat: 34.858715, postalCode: "28378" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27621" },
    { lng: -75.618, lat: 35.24554, postalCode: "27936" },
    { lng: -80.10965, lat: 35.104652, postalCode: "28007" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27425" },
    { lng: -77.90554, lat: 34.332126, postalCode: "28429" },
    { lng: -82.68447, lat: 35.852912, postalCode: "28753" },
    { lng: -77.01504, lat: 35.244257, postalCode: "28527" },
    { lng: -78.59534, lat: 34.323483, postalCode: "28442" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28275" },
    { lng: -81.2083, lat: 35.914275, postalCode: "28681" },
    { lng: -77.852599, lat: 34.088079, postalCode: "28407" },
    { lng: -80.99326, lat: 35.589022, postalCode: "28673" },
    { lng: -83.002841, lat: 35.468264, postalCode: "28738" },
    { lng: -80.541088, lat: 35.346285, postalCode: "28082" },
    { lng: -77.30888, lat: 36.518839, postalCode: "27853" },
    { lng: -78.37743, lat: 34.319905, postalCode: "28423" },
    { lng: -82.70449, lat: 35.529895, postalCode: "28715" },
    { lng: -80.72296, lat: 36.367258, postalCode: "27017" },
    { lng: -80.77085, lat: 35.97991, postalCode: "28634" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28286" },
    { lng: -80.21918, lat: 36.107037, postalCode: "27101" },
    { lng: -78.5861, lat: 35.767829, postalCode: "27610" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28220" },
    { lng: -77.4161, lat: 35.463012, postalCode: "28513" },
    { lng: -80.20728, lat: 36.027482, postalCode: "27157" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28219" },
    { lng: -81.00412, lat: 35.501448, postalCode: "28037" },
    { lng: -79.82206, lat: 36.064485, postalCode: "27403" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27636" },
    { lng: -80.01009, lat: 36.092076, postalCode: "27235" },
    { lng: -77.15546, lat: 35.302937, postalCode: "28586" },
    { lng: -80.75678, lat: 35.280464, postalCode: "28213" },
    { lng: -81.83002, lat: 36.255467, postalCode: "28679" },
    { lng: -78.110931, lat: 36.37367, postalCode: "27586" },
    { lng: -80.0244, lat: 36.080707, postalCode: "27415" },
    { lng: -79.720556, lat: 35.788286, postalCode: "27204" },
    { lng: -80.108188, lat: 35.264179, postalCode: "28002" },
    { lng: -77.10397, lat: 36.223682, postalCode: "27805" },
    { lng: -77.15405, lat: 34.756033, postalCode: "28582" },
    { lng: -79.773094, lat: 36.392135, postalCode: "27322" },
    { lng: -78.26479, lat: 35.557966, postalCode: "27576" },
    { lng: -77.5108, lat: 35.69872, postalCode: "27827" },
    { lng: -75.89888, lat: 36.314447, postalCode: "27916" },
    { lng: -80.3137, lat: 36.141395, postalCode: "27106" },
    { lng: -78.05743, lat: 33.963111, postalCode: "28461" },
    { lng: -80.825368, lat: 35.489411, postalCode: "28233" },
    { lng: -81.675545, lat: 35.779182, postalCode: "28647" },
    { lng: -79.14221, lat: 36.415102, postalCode: "27291" },
    { lng: -83.90161, lat: 34.994752, postalCode: "28909" },
    { lng: -77.54056, lat: 35.905299, postalCode: "27886" },
    { lng: -78.24273, lat: 35.511864, postalCode: "27568" },
    { lng: -78.50025, lat: 35.053627, postalCode: "28385" },
    { lng: -78.625265, lat: 35.797692, postalCode: "27698" },
    { lng: -81.60359, lat: 35.75779, postalCode: "28619" },
    { lng: -82.50007, lat: 35.599363, postalCode: "28805" },
    { lng: -79.39084, lat: 35.180394, postalCode: "28387" },
    { lng: -80.89731, lat: 35.172319, postalCode: "28217" },
    { lng: -78.743197, lat: 35.906016, postalCode: "27617" },
    { lng: -79.773094, lat: 36.392135, postalCode: "27289" },
    { lng: -80.82748, lat: 35.248292, postalCode: "28206" },
    { lng: -81.3195, lat: 36.541861, postalCode: "28663" },
    { lng: -78.93552, lat: 36.291896, postalCode: "27583" },
    { lng: -78.625362, lat: 35.350774, postalCode: "28335" },
    { lng: -77.55978, lat: 34.47722, postalCode: "28445" },
    { lng: -80.70955, lat: 36.218385, postalCode: "27011" },
    { lng: -81.77804, lat: 35.256252, postalCode: "28076" },
    { lng: -77.71859, lat: 36.190589, postalCode: "27823" },
    { lng: -79.21379, lat: 36.532166, postalCode: "27305" },
    { lng: -78.66964, lat: 35.408375, postalCode: "27521" },
    { lng: -79.41135, lat: 36.132737, postalCode: "27217" },
    { lng: -79.165314, lat: 35.467567, postalCode: "27237" },
    { lng: -80.844743, lat: 35.224242, postalCode: "28282" },
    { lng: -82.29968, lat: 35.646373, postalCode: "28757" },
    { lng: -80.804151, lat: 35.26002, postalCode: "28250" },
    { lng: -79.27142, lat: 36.111449, postalCode: "27302" },
    { lng: -77.58452, lat: 35.424381, postalCode: "28538" },
    { lng: -79.56352, lat: 36.125747, postalCode: "27249" },
    { lng: -81.39224, lat: 36.488377, postalCode: "28617" },
    { lng: -71.387387, lat: 44.695648, postalCode: "3575" },
    { lng: -71.74199, lat: 43.388571, postalCode: "3268" },
    { lng: -71.387387, lat: 44.695648, postalCode: "3589" },
    { lng: -71.96422, lat: 43.568328, postalCode: "3240" },
    { lng: -72.139036, lat: 43.827375, postalCode: "3678" },
    { lng: -71.736518, lat: 43.466172, postalCode: "3231" },
    { lng: -72.097366, lat: 43.581769, postalCode: "3747" },
    { lng: -71.24335, lat: 44.608332, postalCode: "3588" },
    { lng: -71.44828, lat: 42.967684, postalCode: "3103" },
    { lng: -71.028427, lat: 43.326734, postalCode: "3822" },
    { lng: -70.95076, lat: 43.03833, postalCode: "3856" },
    { lng: -72.13297, lat: 43.823864, postalCode: "3768" },
    { lng: -71.840883, lat: 43.967542, postalCode: "3232" },
    { lng: -71.02823, lat: 43.448064, postalCode: "3887" },
    { lng: -71.653939, lat: 42.952124, postalCode: "3108" },
    { lng: -71.85063, lat: 42.81949, postalCode: "3084" },
    { lng: -71.444752, lat: 42.92703, postalCode: "3803" },
    { lng: -71.47352, lat: 43.459555, postalCode: "3220" },
    { lng: -72.13525, lat: 43.304058, postalCode: "3752" },
    { lng: -71.43842, lat: 43.557153, postalCode: "3246" },
    { lng: -72.30353, lat: 43.53357, postalCode: "3781" },
    { lng: -72.2507, lat: 43.719756, postalCode: "3755" },
    { lng: -72.05658, lat: 44.037125, postalCode: "3765" },
    { lng: -70.77684, lat: 43.070188, postalCode: "3801" },
    { lng: -71.43654, lat: 43.152469, postalCode: "3275" },
    { lng: -71.027303, lat: 43.41176, postalCode: "3866" },
    { lng: -72.28616, lat: 42.946418, postalCode: "3431" },
    { lng: -71.98612, lat: 44.029684, postalCode: "3780" },
    { lng: -71.519728, lat: 43.496372, postalCode: "3289" },
    { lng: -71.00006, lat: 43.721239, postalCode: "3882" },
    { lng: -72.29978, lat: 42.870578, postalCode: "3446" },
    { lng: -71.73815, lat: 43.521669, postalCode: "3243" },
    { lng: -70.72353, lat: 43.068338, postalCode: "3854" },
    { lng: -70.88666, lat: 42.923946, postalCode: "3844" },
    { lng: -71.03405, lat: 43.564008, postalCode: "3872" },
    { lng: -70.98935, lat: 43.640621, postalCode: "3830" },
    { lng: -72.41902, lat: 43.075411, postalCode: "3608" },
    { lng: -71.12296, lat: 42.890357, postalCode: "3826" },
    { lng: -72.14365, lat: 43.025227, postalCode: "3457" },
    { lng: -71.028427, lat: 43.326734, postalCode: "3821" },
    { lng: -70.96931, lat: 43.500126, postalCode: "3852" },
    { lng: -71.86645, lat: 42.935709, postalCode: "3047" },
    { lng: -71.04716, lat: 43.907045, postalCode: "3832" },
    { lng: -72.32575, lat: 43.142384, postalCode: "3602" },
    { lng: -71.445841, lat: 43.524872, postalCode: "3299" },
    { lng: -71.18267, lat: 43.868287, postalCode: "3875" },
    { lng: -70.98459, lat: 42.91574, postalCode: "3827" },
    { lng: -72.04409, lat: 43.976427, postalCode: "3779" },
    { lng: -70.82514, lat: 42.93569, postalCode: "3842" },
    { lng: -72.00004, lat: 43.418863, postalCode: "3257" },
    { lng: -71.28776, lat: 43.478917, postalCode: "3810" },
    { lng: -71.54434, lat: 43.346137, postalCode: "3224" },
    { lng: -71.86706, lat: 42.753726, postalCode: "3071" },
    { lng: -71.66034, lat: 43.444456, postalCode: "3235" },
    { lng: -71.35141, lat: 43.000345, postalCode: "3032" },
    { lng: -71.04356, lat: 43.208136, postalCode: "3825" },
    { lng: -71.32104, lat: 43.828696, postalCode: "3883" },
    { lng: -72.24403, lat: 43.642945, postalCode: "3766" },
    { lng: -71.3215, lat: 43.30368, postalCode: "3263" },
    { lng: -71.653939, lat: 42.952124, postalCode: "3105" },
    { lng: -71.37517, lat: 43.42975, postalCode: "3237" },
    { lng: -71.34507, lat: 43.908738, postalCode: "3897" },
    { lng: -71.45379, lat: 42.85059, postalCode: "3052" },
    { lng: -72.07185, lat: 43.437503, postalCode: "3751" },
    { lng: -71.59395, lat: 44.719769, postalCode: "3590" },
    { lng: -71.63832, lat: 43.283796, postalCode: "3303" },
    { lng: -70.90192, lat: 43.015756, postalCode: "3885" },
    { lng: -71.345714, lat: 45.085717, postalCode: "3552" },
    { lng: -72.150882, lat: 42.74513, postalCode: "3477" },
    { lng: -71.537145, lat: 43.468562, postalCode: "3252" },
    { lng: -71.17701, lat: 43.257919, postalCode: "3884" },
    { lng: -72.243666, lat: 43.38807, postalCode: "3733" },
    { lng: -72.26149, lat: 43.055293, postalCode: "3448" },
    { lng: -71.92108, lat: 43.119417, postalCode: "3244" },
    { lng: -72.17618, lat: 42.822155, postalCode: "3465" },
    { lng: -71.013202, lat: 43.005895, postalCode: "3802" },
    { lng: -71.06236, lat: 42.923643, postalCode: "3848" },
    { lng: -72.30594, lat: 43.649781, postalCode: "3784" },
    { lng: -71.28614, lat: 43.642398, postalCode: "3853" },
    { lng: -70.84714, lat: 43.038279, postalCode: "3840" },
    { lng: -71.154866, lat: 43.001762, postalCode: "3004" },
    { lng: -71.78401, lat: 43.444702, postalCode: "3216" },
    { lng: -71.840883, lat: 43.967542, postalCode: "3293" },
    { lng: -71.41349, lat: 42.972584, postalCode: "3109" },
    { lng: -70.85466, lat: 42.88439, postalCode: "3874" },
    { lng: -71.15892, lat: 43.966958, postalCode: "3818" },
    { lng: -72.20056, lat: 43.701003, postalCode: "3750" },
    { lng: -71.65161, lat: 43.887682, postalCode: "3223" },
    { lng: -71.57023, lat: 43.037495, postalCode: "3045" },
    { lng: -71.95133, lat: 42.884919, postalCode: "3458" },
    { lng: -71.91765, lat: 43.874357, postalCode: "3282" },
    { lng: -71.70711, lat: 43.204192, postalCode: "3229" },
    { lng: -71.662928, lat: 43.310279, postalCode: "3302" },
    { lng: -72.33893, lat: 42.775629, postalCode: "3470" },
    { lng: -71.5876, lat: 43.523175, postalCode: "3269" },
    { lng: -70.99816, lat: 43.304163, postalCode: "3867" },
    { lng: -71.46431, lat: 42.749988, postalCode: "3030" },
    { lng: -71.44766, lat: 44.893888, postalCode: "3576" },
    { lng: -71.75006, lat: 43.615799, postalCode: "3222" },
    { lng: -71.32971, lat: 43.435913, postalCode: "3837" },
    { lng: -71.44716, lat: 43.006033, postalCode: "3104" },
    { lng: -70.87549, lat: 43.253783, postalCode: "3878" },
    { lng: -71.87371, lat: 44.225864, postalCode: "3585" },
    { lng: -70.771896, lat: 42.980639, postalCode: "3871" },
    { lng: -71.47306, lat: 42.774171, postalCode: "3064" },
    { lng: -71.517151, lat: 43.2134, postalCode: "3305" },
    { lng: -71.0627, lat: 44.041089, postalCode: "3813" },
    { lng: -71.11081, lat: 43.115874, postalCode: "3290" },
    { lng: -71.04002, lat: 42.861915, postalCode: "3859" },
    { lng: -71.28404, lat: 43.685171, postalCode: "3816" },
    { lng: -71.69959, lat: 44.200124, postalCode: "3580" },
    { lng: -71.39475, lat: 43.880005, postalCode: "3259" },
    { lng: -72.12954, lat: 43.516383, postalCode: "3753" },
    { lng: -72.39026, lat: 43.249468, postalCode: "3603" },
    { lng: -72.148784, lat: 43.28616, postalCode: "3572" },
    { lng: -71.78177, lat: 42.830184, postalCode: "3086" },
    { lng: -70.83207, lat: 43.221234, postalCode: "3869" },
    { lng: -71.04807, lat: 42.871322, postalCode: "3858" },
    { lng: -71.194586, lat: 43.811782, postalCode: "3890" },
    { lng: -71.257726, lat: 43.883871, postalCode: "3847" },
    { lng: -71.58044, lat: 42.739909, postalCode: "3049" },
    { lng: -71.4868, lat: 42.990684, postalCode: "3102" },
    { lng: -71.19552, lat: 43.596233, postalCode: "3894" },
    { lng: -71.1392, lat: 43.179984, postalCode: "3291" },
    { lng: -71.12926, lat: 43.767878, postalCode: "3814" },
    { lng: -71.50488, lat: 43.644559, postalCode: "3253" },
    { lng: -71.4534, lat: 43.31773, postalCode: "3307" },
    { lng: -72.26894, lat: 43.195329, postalCode: "3607" },
    { lng: -71.5785, lat: 43.446729, postalCode: "3276" },
    { lng: -71.50336, lat: 43.951304, postalCode: "3215" },
    { lng: -72.243029, lat: 42.947098, postalCode: "3604" },
    { lng: -71.445452, lat: 43.588972, postalCode: "3247" },
    { lng: -71.013202, lat: 43.005895, postalCode: "3040" },
    { lng: -71.98913, lat: 42.975105, postalCode: "3449" },
    { lng: -71.3078, lat: 43.048372, postalCode: "3034" },
    { lng: -71.23453, lat: 42.967823, postalCode: "3036" },
    { lng: -71.51993, lat: 42.942551, postalCode: "3110" },
    { lng: -71.14628, lat: 43.443682, postalCode: "3855" },
    { lng: -72.02654, lat: 43.32582, postalCode: "3255" },
    { lng: -71.62778, lat: 43.705061, postalCode: "3217" },
    { lng: -72.4352, lat: 42.791069, postalCode: "3441" },
    { lng: -71.68057, lat: 42.899434, postalCode: "3057" },
    { lng: -71.013202, lat: 43.005895, postalCode: "212" },
    { lng: -71.88853, lat: 43.938224, postalCode: "3279" },
    { lng: -71.653939, lat: 42.952124, postalCode: "3061" },
    { lng: -72.41522, lat: 42.913966, postalCode: "3462" },
    { lng: -71.77591, lat: 42.747024, postalCode: "3048" },
    { lng: -71.013202, lat: 43.005895, postalCode: "211" },
    { lng: -71.013202, lat: 43.005895, postalCode: "3843" },
    { lng: -71.82537, lat: 43.283838, postalCode: "3278" },
    { lng: -71.20354, lat: 44.116958, postalCode: "3838" },
    { lng: -71.53774, lat: 43.213705, postalCode: "3301" },
    { lng: -72.42512, lat: 42.971069, postalCode: "3467" },
    { lng: -71.30019, lat: 42.886486, postalCode: "3038" },
    { lng: -71.46376, lat: 42.989984, postalCode: "3101" },
    { lng: -72.49518, lat: 42.797633, postalCode: "3451" },
    { lng: -71.95405, lat: 43.256053, postalCode: "3221" },
    { lng: -71.29878, lat: 42.805194, postalCode: "3087" },
    { lng: -72.16219, lat: 42.932177, postalCode: "3455" },
    { lng: -72.243029, lat: 42.947098, postalCode: "3435" },
    { lng: -71.15394, lat: 44.789296, postalCode: "3579" },
    { lng: -71.9943, lat: 44.08541, postalCode: "3774" },
    { lng: -71.62248, lat: 44.378771, postalCode: "3598" },
    { lng: -71.93648, lat: 43.423484, postalCode: "3233" },
    { lng: -71.10266, lat: 43.672636, postalCode: "3864" },
    { lng: -71.9255, lat: 43.356838, postalCode: "3260" },
    { lng: -71.87952, lat: 43.806365, postalCode: "3266" },
    { lng: -72.01034, lat: 42.749835, postalCode: "3461" },
    { lng: -70.96035, lat: 43.128085, postalCode: "3824" },
    { lng: -71.013202, lat: 43.005895, postalCode: "215" },
    { lng: -72.4483, lat: 43.142254, postalCode: "3609" },
    { lng: -71.46492, lat: 42.748888, postalCode: "3060" },
    { lng: -72.01333, lat: 43.659172, postalCode: "3741" },
    { lng: -71.301681, lat: 43.707657, postalCode: "3850" },
    { lng: -71.39299, lat: 43.712184, postalCode: "3254" },
    { lng: -71.79751, lat: 44.313606, postalCode: "3561" },
    { lng: -71.49652, lat: 43.721094, postalCode: "3226" },
    { lng: -71.87281, lat: 43.506405, postalCode: "3230" },
    { lng: -71.90457, lat: 43.430018, postalCode: "3287" },
    { lng: -71.06217, lat: 43.395688, postalCode: "3835" },
    { lng: -71.69414, lat: 42.978267, postalCode: "3070" },
    { lng: -71.64503, lat: 43.753837, postalCode: "3264" },
    { lng: -71.387387, lat: 44.695648, postalCode: "3597" },
    { lng: -71.18252, lat: 42.880837, postalCode: "3841" },
    { lng: -72.125562, lat: 43.365618, postalCode: "3754" },
    { lng: -71.6082, lat: 42.854886, postalCode: "3031" },
    { lng: -71.22069, lat: 43.878112, postalCode: "3817" },
    { lng: -71.840883, lat: 43.967542, postalCode: "3238" },
    { lng: -71.42079, lat: 42.759821, postalCode: "3051" },
    { lng: -72.193966, lat: 43.364504, postalCode: "3746" },
    { lng: -72.34152, lat: 43.374562, postalCode: "3743" },
    { lng: -71.10317, lat: 43.256254, postalCode: "3815" },
    { lng: -71.66605, lat: 42.742587, postalCode: "3033" },
    { lng: -71.2079, lat: 43.216633, postalCode: "3261" },
    { lng: -71.96306, lat: 44.133215, postalCode: "3785" },
    { lng: -72.47163, lat: 42.871815, postalCode: "3443" },
    { lng: -71.22819, lat: 43.354005, postalCode: "3225" },
    { lng: -71.013202, lat: 43.005895, postalCode: "3041" },
    { lng: -71.29305, lat: 43.338347, postalCode: "3218" },
    { lng: -71.30915, lat: 43.89715, postalCode: "3386" },
    { lng: -71.16246, lat: 42.836245, postalCode: "3811" },
    { lng: -71.29729, lat: 44.081906, postalCode: "3812" },
    { lng: -71.66743, lat: 42.831686, postalCode: "3055" },
    { lng: -71.840883, lat: 43.967542, postalCode: "3274" },
    { lng: -72.09755, lat: 43.173046, postalCode: "3280" },
    { lng: -72.0959, lat: 43.078658, postalCode: "3464" },
    { lng: -72.00547, lat: 42.921791, postalCode: "3438" },
    { lng: -72.01296, lat: 43.501755, postalCode: "3284" },
    { lng: -71.72329, lat: 43.080986, postalCode: "3281" },
    { lng: -72.06043, lat: 42.941175, postalCode: "3450" },
    { lng: -72.21382, lat: 43.125523, postalCode: "3456" },
    { lng: -71.398521, lat: 43.555878, postalCode: "3249" },
    { lng: -71.387387, lat: 44.695648, postalCode: "3587" },
    { lng: -71.0803, lat: 43.814201, postalCode: "3836" },
    { lng: -71.32359, lat: 42.734182, postalCode: "3076" },
    { lng: -71.64372, lat: 43.60768, postalCode: "3256" },
    { lng: -72.08541, lat: 43.579573, postalCode: "3749" },
    { lng: -71.92709, lat: 43.317257, postalCode: "3273" },
    { lng: -72.17556, lat: 43.231807, postalCode: "3605" },
    { lng: -71.46436, lat: 44.376866, postalCode: "3583" },
    { lng: -72.33337, lat: 43.476337, postalCode: "3745" },
    { lng: -72.31509, lat: 42.87341, postalCode: "3469" },
    { lng: -71.69225, lat: 44.28177, postalCode: "3574" },
    { lng: -71.013202, lat: 43.005895, postalCode: "3073" },
    { lng: -71.30724, lat: 45.085547, postalCode: "3592" },
    { lng: -71.16273, lat: 44.386557, postalCode: "3581" },
    { lng: -71.218894, lat: 43.589157, postalCode: "3896" },
    { lng: -72.135352, lat: 43.630276, postalCode: "3648" },
    { lng: -71.013202, lat: 43.005895, postalCode: "3804" },
    { lng: -71.81007, lat: 42.976901, postalCode: "3043" },
    { lng: -71.653939, lat: 42.952124, postalCode: "3111" },
    { lng: -71.19598, lat: 43.031491, postalCode: "3077" },
    { lng: -71.21316, lat: 42.792456, postalCode: "3079" },
    { lng: -72.12058, lat: 43.614847, postalCode: "3748" },
    { lng: -71.3611, lat: 43.228594, postalCode: "3234" },
    { lng: -71.49238, lat: 42.724822, postalCode: "3062" },
    { lng: -71.092797, lat: 43.822389, postalCode: "3638" },
    { lng: -72.09028, lat: 43.381133, postalCode: "3782" },
    { lng: -70.76517, lat: 43.000003, postalCode: "3870" },
    { lng: -72.03418, lat: 42.827096, postalCode: "3452" },
    { lng: -71.78452, lat: 42.902259, postalCode: "3082" },
    { lng: -71.18089, lat: 44.166268, postalCode: "3846" },
    { lng: -71.13865, lat: 44.086488, postalCode: "3845" },
    { lng: -71.12034, lat: 42.927682, postalCode: "3819" },
    { lng: -71.38205, lat: 42.880481, postalCode: "3053" },
    { lng: -71.933396, lat: 42.890804, postalCode: "3468" },
    { lng: -72.14838, lat: 42.754744, postalCode: "3447" },
    { lng: -70.930684, lat: 43.285282, postalCode: "3805" },
    { lng: -71.840883, lat: 43.967542, postalCode: "3756" },
    { lng: -71.881017, lat: 43.01428, postalCode: "3342" },
    { lng: -71.013202, lat: 43.005895, postalCode: "213" },
    { lng: -72.19383, lat: 43.009728, postalCode: "3445" },
    { lng: -70.83055, lat: 42.97778, postalCode: "3862" },
    { lng: -72.28072, lat: 43.525462, postalCode: "3770" },
    { lng: -71.950759, lat: 42.798995, postalCode: "3058" },
    { lng: -71.013202, lat: 43.005895, postalCode: "214" },
    { lng: -71.07095, lat: 43.045076, postalCode: "3042" },
    { lng: -72.18321, lat: 43.358339, postalCode: "3773" },
    { lng: -71.91635, lat: 43.005429, postalCode: "3442" },
    { lng: -70.94336, lat: 43.072738, postalCode: "3857" },
    { lng: -72.07831, lat: 43.908925, postalCode: "3777" },
    { lng: -71.13084, lat: 44.035684, postalCode: "3860" },
    { lng: -71.95068, lat: 43.060424, postalCode: "3440" },
    { lng: -72.120468, lat: 43.786267, postalCode: "3769" },
    { lng: -70.97665, lat: 42.973783, postalCode: "3833" },
    { lng: -71.53576, lat: 43.144614, postalCode: "3304" },
    { lng: -71.68701, lat: 44.0285, postalCode: "3262" },
    { lng: -71.19092, lat: 44.463337, postalCode: "3570" },
    { lng: -71.22377, lat: 43.478364, postalCode: "3809" },
    { lng: -71.140537, lat: 42.906495, postalCode: "3891" },
    { lng: -71.44412, lat: 43.058785, postalCode: "3106" },
    { lng: -70.96311, lat: 43.340581, postalCode: "3868" },
    { lng: -71.81722, lat: 43.173469, postalCode: "3242" },
    { lng: -71.604406, lat: 43.737831, postalCode: "3245" },
    { lng: -72.51629, lat: 42.899338, postalCode: "3466" },
    { lng: -71.51746, lat: 44.271516, postalCode: "3595" },
    { lng: -71.662928, lat: 43.310279, postalCode: "3272" },
    { lng: -71.011369, lat: 43.422942, postalCode: "3851" },
    { lng: -71.550864, lat: 43.203614, postalCode: "3270" },
    { lng: -71.25988, lat: 43.857079, postalCode: "3886" },
    { lng: -71.09445, lat: 42.837189, postalCode: "3865" },
    { lng: -72.30269, lat: 43.242719, postalCode: "3601" },
    { lng: -71.653939, lat: 42.952124, postalCode: "3107" },
    { lng: -71.18444, lat: 42.929588, postalCode: "3873" },
    { lng: -71.48147, lat: 44.610605, postalCode: "3582" },
    { lng: -71.948852, lat: 44.114163, postalCode: "3740" },
    { lng: -71.854211, lat: 44.101208, postalCode: "3578" },
    { lng: -71.45126, lat: 43.802936, postalCode: "3227" },
    { lng: -71.14311, lat: 43.930805, postalCode: "3849" },
    { lng: -70.88787, lat: 43.190984, postalCode: "3820" },
    { lng: -71.51047, lat: 42.770738, postalCode: "3063" },
    { lng: -71.80642, lat: 43.713794, postalCode: "3241" },
    { lng: -72.06334, lat: 42.899874, postalCode: "3444" },
    { lng: -71.602132, lat: 43.102146, postalCode: "3046" },
    { lng: -71.54999, lat: 44.483756, postalCode: "3584" },
    { lng: -71.397599, lat: 43.255881, postalCode: "3258" },
    { lng: -71.2488, lat: 43.141746, postalCode: "3037" },
    { lng: -71.12524, lat: 42.986119, postalCode: "3044" },
    { lng: -71.51216, lat: 42.858053, postalCode: "3054" },
    { lng: -71.013202, lat: 43.005895, postalCode: "210" },
    { lng: -71.445841, lat: 43.524872, postalCode: "3298" },
    { lng: -70.98552, lat: 43.267581, postalCode: "3839" },
    { lng: -72.02789, lat: 44.281722, postalCode: "3771" },
    { lng: -72.088709, lat: 43.444147, postalCode: "3757" },
    { lng: -71.6606, lat: 44.051981, postalCode: "3251" },
    { lng: -74.4917, lat: 39.527903, postalCode: "8241" },
    { lng: -74.06582, lat: 40.733751, postalCode: "7306" },
    { lng: -75.046926, lat: 40.737213, postalCode: "8808" },
    { lng: -74.07047, lat: 40.947107, postalCode: "7652" },
    { lng: -74.92319, lat: 38.963652, postalCode: "8204" },
    { lng: -74.68799, lat: 40.786549, postalCode: "7930" },
    { lng: -74.075485, lat: 40.73276, postalCode: "7303" },
    { lng: -74.58893, lat: 40.062215, postalCode: "8562" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7507" },
    { lng: -74.03739, lat: 40.927579, postalCode: "7661" },
    { lng: -74.24928, lat: 40.000759, postalCode: "8739" },
    { lng: -74.90934, lat: 40.783175, postalCode: "7865" },
    { lng: -74.76481, lat: 40.219158, postalCode: "8608" },
    { lng: -74.80041, lat: 39.275059, postalCode: "8270" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8677" },
    { lng: -74.90089, lat: 40.886474, postalCode: "7838" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8541" },
    { lng: -74.075485, lat: 40.73276, postalCode: "7309" },
    { lng: -73.99879, lat: 40.923748, postalCode: "7621" },
    { lng: -74.420545, lat: 40.520254, postalCode: "8899" },
    { lng: -74.63687, lat: 39.961921, postalCode: "8064" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7511" },
    { lng: -74.38017, lat: 39.401801, postalCode: "8203" },
    { lng: -74.04247, lat: 41.007389, postalCode: "7642" },
    { lng: -73.99479, lat: 40.845742, postalCode: "7650" },
    { lng: -74.25938, lat: 40.553971, postalCode: "7077" },
    { lng: -75.15321, lat: 39.816063, postalCode: "8097" },
    { lng: -74.816619, lat: 39.056521, postalCode: "8214" },
    { lng: -74.059, lat: 40.841668, postalCode: "7074" },
    { lng: -74.578269, lat: 40.867331, postalCode: "7963" },
    { lng: -74.06279, lat: 40.90503, postalCode: "7607" },
    { lng: -75.06683, lat: 39.25682, postalCode: "8349" },
    { lng: -74.7581, lat: 39.478771, postalCode: "8330" },
    { lng: -74.43459, lat: 40.675112, postalCode: "7922" },
    { lng: -74.47378, lat: 40.22142, postalCode: "8555" },
    { lng: -74.70684, lat: 40.079952, postalCode: "8022" },
    { lng: -74.42481, lat: 40.620102, postalCode: "7060" },
    { lng: -74.06003, lat: 40.081165, postalCode: "8742" },
    { lng: -74.57267, lat: 40.813281, postalCode: "7970" },
    { lng: -73.96166, lat: 40.971956, postalCode: "7624" },
    { lng: -74.819318, lat: 40.810362, postalCode: "7870" },
    { lng: -74.64922, lat: 40.357439, postalCode: "8540" },
    { lng: -74.58006, lat: 40.242009, postalCode: "8561" },
    { lng: -74.65943, lat: 40.354424, postalCode: "8542" },
    { lng: -75.24215, lat: 39.445164, postalCode: "8302" },
    { lng: -74.04059, lat: 40.33517, postalCode: "7739" },
    { lng: -74.8185, lat: 39.865272, postalCode: "8055" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8871" },
    { lng: -74.06889, lat: 40.301979, postalCode: "7724" },
    { lng: -74.16831, lat: 40.767702, postalCode: "7104" },
    { lng: -74.578269, lat: 40.867331, postalCode: "7806" },
    { lng: -74.03379, lat: 40.953126, postalCode: "7649" },
    { lng: -74.96994, lat: 39.464582, postalCode: "8361" },
    { lng: -74.03483, lat: 40.407958, postalCode: "7716" },
    { lng: -74.816619, lat: 39.056521, postalCode: "8219" },
    { lng: -74.11671, lat: 40.933943, postalCode: "7410" },
    { lng: -74.083231, lat: 40.948054, postalCode: "7498" },
    { lng: -74.09616, lat: 41.054568, postalCode: "7458" },
    { lng: -75.05678, lat: 39.784614, postalCode: "8012" },
    { lng: -74.20589, lat: 40.770933, postalCode: "7017" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7197" },
    { lng: -74.8047, lat: 40.11791, postalCode: "8518" },
    { lng: -74.578269, lat: 40.867331, postalCode: "7845" },
    { lng: -74.26112, lat: 40.316876, postalCode: "7746" },
    { lng: -75.43943, lat: 39.549912, postalCode: "8079" },
    { lng: -74.08689, lat: 40.415902, postalCode: "7718" },
    { lng: -74.21297, lat: 39.848035, postalCode: "8731" },
    { lng: -74.61775, lat: 40.003861, postalCode: "8640" },
    { lng: -74.25541, lat: 40.78865, postalCode: "7052" },
    { lng: -75.10402, lat: 40.587606, postalCode: "8848" },
    { lng: -74.41516, lat: 40.912798, postalCode: "7005" },
    { lng: -74.24774, lat: 40.569504, postalCode: "7064" },
    { lng: -75.11513, lat: 39.936179, postalCode: "8103" },
    { lng: -74.97546, lat: 39.274714, postalCode: "8316" },
    { lng: -74.0302, lat: 40.767751, postalCode: "7087" },
    { lng: -74.78295, lat: 40.788599, postalCode: "7853" },
    { lng: -74.938931, lat: 40.565493, postalCode: "8868" },
    { lng: -74.30325, lat: 40.517855, postalCode: "8832" },
    { lng: -75.0618, lat: 39.618222, postalCode: "8322" },
    { lng: -74.917711, lat: 41.125646, postalCode: "7881" },
    { lng: -74.944756, lat: 40.739382, postalCode: "7831" },
    { lng: -74.988619, lat: 40.419965, postalCode: "8556" },
    { lng: -74.299692, lat: 40.665651, postalCode: "7902" },
    { lng: -74.60902, lat: 40.292358, postalCode: "8550" },
    { lng: -74.63557, lat: 41.035384, postalCode: "7871" },
    { lng: -74.65699, lat: 39.197394, postalCode: "8248" },
    { lng: -74.502457, lat: 39.471263, postalCode: "8205" },
    { lng: -74.86762, lat: 40.010461, postalCode: "8073" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7019" },
    { lng: -74.37404, lat: 41.173445, postalCode: "7421" },
    { lng: -74.85853, lat: 39.562719, postalCode: "8346" },
    { lng: -74.608557, lat: 39.509208, postalCode: "8213" },
    { lng: -74.740597, lat: 40.619412, postalCode: "8888" },
    { lng: -75.2669, lat: 39.685445, postalCode: "8039" },
    { lng: -74.14238, lat: 41.058819, postalCode: "7446" },
    { lng: -74.31023, lat: 40.990032, postalCode: "7457" },
    { lng: -74.14286, lat: 40.99312, postalCode: "7432" },
    { lng: -74.89118, lat: 40.624538, postalCode: "8801" },
    { lng: -75.11836, lat: 39.891113, postalCode: "8030" },
    { lng: -74.21405, lat: 40.886628, postalCode: "7424" },
    { lng: -74.21692, lat: 40.652302, postalCode: "7202" },
    { lng: -74.678956, lat: 41.12831, postalCode: "7855" },
    { lng: -74.03294, lat: 40.744851, postalCode: "7030" },
    { lng: -74.38533, lat: 40.641852, postalCode: "7023" },
    { lng: -74.653, lat: 40.652301, postalCode: "7921" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7530" },
    { lng: -74.32251, lat: 40.11597, postalCode: "8527" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7543" },
    { lng: -74.30477, lat: 40.821799, postalCode: "7068" },
    { lng: -74.02278, lat: 40.856749, postalCode: "7660" },
    { lng: -74.47843, lat: 40.872866, postalCode: "7878" },
    { lng: -75.0923, lat: 39.884263, postalCode: "8059" },
    { lng: -74.39329, lat: 40.39159, postalCode: "8884" },
    { lng: -74.30934, lat: 40.620336, postalCode: "7066" },
    { lng: -75.15464, lat: 39.28341, postalCode: "8345" },
    { lng: -74.17784, lat: 40.925778, postalCode: "7522" },
    { lng: -74.96511, lat: 40.435878, postalCode: "8559" },
    { lng: -74.08843, lat: 40.701302, postalCode: "7305" },
    { lng: -74.70438, lat: 39.20877, postalCode: "8230" },
    { lng: -75.04019, lat: 39.895213, postalCode: "8033" },
    { lng: -74.93616, lat: 39.022256, postalCode: "8251" },
    { lng: -74.57387, lat: 39.348616, postalCode: "8221" },
    { lng: -74.80666, lat: 39.967013, postalCode: "8048" },
    { lng: -74.3183, lat: 40.571504, postalCode: "8830" },
    { lng: -74.99464, lat: 39.952212, postalCode: "8052" },
    { lng: -74.07253, lat: 40.716452, postalCode: "7304" },
    { lng: -74.15877, lat: 40.8196, postalCode: "7110" },
    { lng: -74.50187, lat: 40.574552, postalCode: "8846" },
    { lng: -74.38179, lat: 40.445006, postalCode: "8882" },
    { lng: -74.08754, lat: 40.847638, postalCode: "7075" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8905" },
    { lng: -74.52976, lat: 40.553803, postalCode: "8880" },
    { lng: -74.10286, lat: 40.14386, postalCode: "8720" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8695" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8922" },
    { lng: -74.179, lat: 40.423298, postalCode: "7730" },
    { lng: -74.14321, lat: 40.923648, postalCode: "7514" },
    { lng: -74.59529, lat: 40.782767, postalCode: "7945" },
    { lng: -74.65918, lat: 40.225409, postalCode: "8690" },
    { lng: -74.48688, lat: 40.134892, postalCode: "8514" },
    { lng: -74.65125, lat: 40.14821, postalCode: "8515" },
    { lng: -75.095025, lat: 39.273214, postalCode: "8315" },
    { lng: -74.73624, lat: 40.681694, postalCode: "8858" },
    { lng: -74.191058, lat: 39.787966, postalCode: "8756" },
    { lng: -74.93392, lat: 39.807695, postalCode: "8091" },
    { lng: -74.5008, lat: 40.633652, postalCode: "7059" },
    { lng: -74.571785, lat: 40.800353, postalCode: "7926" },
    { lng: -74.170091, lat: 40.718772, postalCode: "7115" },
    { lng: -74.30491, lat: 40.017117, postalCode: "8733" },
    { lng: -74.52675, lat: 39.314766, postalCode: "8403" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8877" },
    { lng: -75.01887, lat: 39.492265, postalCode: "8360" },
    { lng: -73.99576, lat: 40.369145, postalCode: "7760" },
    { lng: -74.11064, lat: 40.828922, postalCode: "7070" },
    { lng: -74.71323, lat: 39.978635, postalCode: "8011" },
    { lng: -74.48559, lat: 40.79215, postalCode: "7960" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7538" },
    { lng: -73.96044, lat: 40.955603, postalCode: "7627" },
    { lng: -74.13254, lat: 40.791895, postalCode: "7031" },
    { lng: -74.99035, lat: 39.741385, postalCode: "8081" },
    { lng: -74.09707, lat: 40.830158, postalCode: "7073" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7509" },
    { lng: -74.61414, lat: 40.963959, postalCode: "7849" },
    { lng: -74.0069, lat: 40.236916, postalCode: "7711" },
    { lng: -74.15829, lat: 40.930398, postalCode: "7524" },
    { lng: -74.43302, lat: 40.336508, postalCode: "8831" },
    { lng: -74.72465, lat: 40.713675, postalCode: "7979" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7182" },
    { lng: -74.678956, lat: 41.12831, postalCode: "7839" },
    { lng: -75.162903, lat: 39.701526, postalCode: "8025" },
    { lng: -74.22376, lat: 39.784513, postalCode: "8758" },
    { lng: -74.1069, lat: 40.427561, postalCode: "7758" },
    { lng: -74.53246, lat: 40.262809, postalCode: "8520" },
    { lng: -74.58916, lat: 40.04492, postalCode: "8641" },
    { lng: -74.59364, lat: 40.207034, postalCode: "8691" },
    { lng: -75.03197, lat: 39.866513, postalCode: "8045" },
    { lng: -74.10981, lat: 40.087794, postalCode: "8724" },
    { lng: -75.2211, lat: 39.800958, postalCode: "8020" },
    { lng: -74.14263, lat: 40.879699, postalCode: "7011" },
    { lng: -74.52627, lat: 40.306286, postalCode: "8512" },
    { lng: -74.949409, lat: 40.563654, postalCode: "8870" },
    { lng: -74.69588, lat: 40.240558, postalCode: "8619" },
    { lng: -74.25296, lat: 39.975967, postalCode: "8757" },
    { lng: -74.58512, lat: 40.33371, postalCode: "8536" },
    { lng: -74.50661, lat: 39.329416, postalCode: "8402" },
    { lng: -74.35601, lat: 40.680301, postalCode: "7092" },
    { lng: -74.25881, lat: 40.74765, postalCode: "7079" },
    { lng: -75.0687, lat: 39.840463, postalCode: "8029" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8862" },
    { lng: -74.24227, lat: 40.832449, postalCode: "7044" },
    { lng: -74.725079, lat: 39.862433, postalCode: "8076" },
    { lng: -75.0543, lat: 39.572449, postalCode: "8328" },
    { lng: -73.99097, lat: 40.297932, postalCode: "7740" },
    { lng: -74.443695, lat: 40.641207, postalCode: "7069" },
    { lng: -74.96875, lat: 40.677404, postalCode: "8827" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7193" },
    { lng: -74.45489, lat: 41.180137, postalCode: "7422" },
    { lng: -74.3633, lat: 40.714501, postalCode: "7901" },
    { lng: -74.696498, lat: 39.16076, postalCode: "8283" },
    { lng: -74.91292, lat: 40.639546, postalCode: "8809" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7191" },
    { lng: -75.06752, lat: 40.895451, postalCode: "7833" },
    { lng: -74.96243, lat: 40.96035, postalCode: "7825" },
    { lng: -74.47079, lat: 40.683934, postalCode: "7933" },
    { lng: -73.96482, lat: 40.92169, postalCode: "7670" },
    { lng: -74.70726, lat: 40.410155, postalCode: "8558" },
    { lng: -74.36949, lat: 39.943541, postalCode: "8759" },
    { lng: -74.719502, lat: 39.574175, postalCode: "8017" },
    { lng: -74.30343, lat: 40.461851, postalCode: "8859" },
    { lng: -74.48704, lat: 40.886149, postalCode: "7834" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8603" },
    { lng: -75.16843, lat: 39.553504, postalCode: "8318" },
    { lng: -75.50618, lat: 39.481009, postalCode: "8038" },
    { lng: -74.72103, lat: 39.575273, postalCode: "8217" },
    { lng: -74.949409, lat: 40.563654, postalCode: "8834" },
    { lng: -74.23207, lat: 40.725651, postalCode: "7111" },
    { lng: -74.72688, lat: 39.092928, postalCode: "8202" },
    { lng: -74.665549, lat: 40.728195, postalCode: "7997" },
    { lng: -74.9541, lat: 40.029361, postalCode: "8075" },
    { lng: -75.06599, lat: 39.87913, postalCode: "8035" },
    { lng: -74.938259, lat: 39.80237, postalCode: "8101" },
    { lng: -74.06, lat: 40.7885, postalCode: "7094" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8818" },
    { lng: -74.13718, lat: 40.763051, postalCode: "7032" },
    { lng: -75.20496, lat: 39.807684, postalCode: "8061" },
    { lng: -74.66266, lat: 39.961183, postalCode: "8068" },
    { lng: -75.15202, lat: 39.799347, postalCode: "8090" },
    { lng: -74.00082, lat: 40.816737, postalCode: "7022" },
    { lng: -74.475936, lat: 40.162275, postalCode: "8526" },
    { lng: -74.03143, lat: 40.232713, postalCode: "7712" },
    { lng: -74.35781, lat: 40.575503, postalCode: "8820" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7754" },
    { lng: -74.83054, lat: 40.861748, postalCode: "7840" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7715" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7188" },
    { lng: -74.28316, lat: 40.557104, postalCode: "7095" },
    { lng: -74.90416, lat: 39.88488, postalCode: "8053" },
    { lng: -74.09474, lat: 40.904131, postalCode: "7663" },
    { lng: -74.29378, lat: 40.879049, postalCode: "7004" },
    { lng: -74.45631, lat: 40.739333, postalCode: "7935" },
    { lng: -74.22898, lat: 40.855854, postalCode: "7009" },
    { lng: -74.36742, lat: 40.906152, postalCode: "7045" },
    { lng: -74.01353, lat: 40.202113, postalCode: "7720" },
    { lng: -74.59667, lat: 39.398172, postalCode: "8234" },
    { lng: -74.57094, lat: 41.156187, postalCode: "7419" },
    { lng: -74.40225, lat: 40.698268, postalCode: "7974" },
    { lng: -75.08616, lat: 39.949812, postalCode: "8105" },
    { lng: -74.871981, lat: 41.043858, postalCode: "7875" },
    { lng: -74.816619, lat: 39.056521, postalCode: "8218" },
    { lng: -74.7421, lat: 40.224441, postalCode: "8609" },
    { lng: -75.28187, lat: 39.824159, postalCode: "8027" },
    { lng: -74.299692, lat: 40.665651, postalCode: "7061" },
    { lng: -75.027671, lat: 39.271264, postalCode: "8362" },
    { lng: -74.075391, lat: 40.73235, postalCode: "7399" },
    { lng: -74.01688, lat: 40.191809, postalCode: "7717" },
    { lng: -73.97958, lat: 40.989357, postalCode: "7640" },
    { lng: -74.075485, lat: 40.73276, postalCode: "7308" },
    { lng: -74.00399, lat: 40.832421, postalCode: "7657" },
    { lng: -74.578872, lat: 40.536069, postalCode: "8890" },
    { lng: -74.608557, lat: 39.509208, postalCode: "8231" },
    { lng: -74.083231, lat: 40.948054, postalCode: "7653" },
    { lng: -74.84463, lat: 40.06834, postalCode: "8016" },
    { lng: -75.01371, lat: 39.829014, postalCode: "8084" },
    { lng: -74.58784, lat: 41.11736, postalCode: "7416" },
    { lng: -74.41609, lat: 40.75875, postalCode: "7940" },
    { lng: -74.01115, lat: 40.890964, postalCode: "7666" },
    { lng: -74.95927, lat: 39.647598, postalCode: "8094" },
    { lng: -74.578269, lat: 40.867331, postalCode: "7999" },
    { lng: -74.586151, lat: 40.655399, postalCode: "7938" },
    { lng: -74.15627, lat: 40.957498, postalCode: "7506" },
    { lng: -74.42648, lat: 40.821482, postalCode: "7981" },
    { lng: -74.466214, lat: 39.588665, postalCode: "8227" },
    { lng: -74.454547, lat: 40.631652, postalCode: "7969" },
    { lng: -74.83044, lat: 39.985662, postalCode: "8036" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7199" },
    { lng: -74.96526, lat: 38.936896, postalCode: "8212" },
    { lng: -74.76682, lat: 40.612851, postalCode: "8889" },
    { lng: -75.17761, lat: 39.785785, postalCode: "8051" },
    { lng: -74.5071, lat: 41.029555, postalCode: "7438" },
    { lng: -74.19618, lat: 39.931068, postalCode: "8722" },
    { lng: -74.479547, lat: 40.482029, postalCode: "8907" },
    { lng: -74.05765, lat: 40.120261, postalCode: "8736" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7765" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7544" },
    { lng: -74.20847, lat: 41.010433, postalCode: "7417" },
    { lng: -74.71053, lat: 40.917054, postalCode: "7874" },
    { lng: -74.07967, lat: 39.920268, postalCode: "8752" },
    { lng: -74.34567, lat: 40.649202, postalCode: "7090" },
    { lng: -74.82295, lat: 40.264708, postalCode: "8628" },
    { lng: -74.788108, lat: 40.955934, postalCode: "7879" },
    { lng: -74.31341, lat: 40.591653, postalCode: "7067" },
    { lng: -74.72472, lat: 40.51057, postalCode: "8853" },
    { lng: -74.87926, lat: 39.437265, postalCode: "8340" },
    { lng: -74.27629, lat: 40.241036, postalCode: "7728" },
    { lng: -74.45121, lat: 40.821149, postalCode: "7927" },
    { lng: -74.833469, lat: 39.50208, postalCode: "8342" },
    { lng: -74.51363, lat: 40.372881, postalCode: "8810" },
    { lng: -74.87045, lat: 39.490774, postalCode: "8350" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7189" },
    { lng: -74.05987, lat: 40.853723, postalCode: "7608" },
    { lng: -74.18286, lat: 40.945198, postalCode: "7508" },
    { lng: -75.02496, lat: 39.842613, postalCode: "8083" },
    { lng: -74.96128, lat: 39.851969, postalCode: "8043" },
    { lng: -74.554334, lat: 39.487717, postalCode: "8240" },
    { lng: -74.88257, lat: 40.029011, postalCode: "8046" },
    { lng: -75.3574, lat: 39.402763, postalCode: "8323" },
    { lng: -75.298235, lat: 39.458714, postalCode: "8253" },
    { lng: -74.23603, lat: 40.7683, postalCode: "7050" },
    { lng: -74.16147, lat: 41.077102, postalCode: "7430" },
    { lng: -74.081074, lat: 39.907488, postalCode: "8792" },
    { lng: -74.4171, lat: 40.860149, postalCode: "7054" },
    { lng: -74.984628, lat: 40.919658, postalCode: "7844" },
    { lng: -74.48287, lat: 40.453131, postalCode: "8902" },
    { lng: -75.51923, lat: 39.645377, postalCode: "8070" },
    { lng: -74.26821, lat: 40.728901, postalCode: "7040" },
    { lng: -74.00153, lat: 40.250104, postalCode: "7723" },
    { lng: -75.34421, lat: 39.803646, postalCode: "8014" },
    { lng: -74.04052, lat: 41.048997, postalCode: "7645" },
    { lng: -74.04118, lat: 40.699226, postalCode: "10004" },
    { lng: -74.22834, lat: 40.673102, postalCode: "7208" },
    { lng: -74.26933, lat: 40.695401, postalCode: "7083" },
    { lng: -75.082022, lat: 39.499765, postalCode: "8347" },
    { lng: -74.20218, lat: 40.844199, postalCode: "7043" },
    { lng: -74.075485, lat: 40.73276, postalCode: "7097" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7194" },
    { lng: -75.41367, lat: 39.546172, postalCode: "8072" },
    { lng: -74.76204, lat: 39.051289, postalCode: "8247" },
    { lng: -74.652811, lat: 40.349206, postalCode: "8544" },
    { lng: -74.06315, lat: 40.329128, postalCode: "7702" },
    { lng: -74.578269, lat: 40.867331, postalCode: "7802" },
    { lng: -74.11718, lat: 40.410213, postalCode: "7748" },
    { lng: -74.608557, lat: 39.509208, postalCode: "8404" },
    { lng: -74.82706, lat: 39.406518, postalCode: "8317" },
    { lng: -74.16773, lat: 39.934318, postalCode: "8741" },
    { lng: -74.15767, lat: 40.205367, postalCode: "7727" },
    { lng: -74.37493, lat: 40.637152, postalCode: "7076" },
    { lng: -74.3474, lat: 40.461605, postalCode: "8872" },
    { lng: -74.45064, lat: 40.228497, postalCode: "8535" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8543" },
    { lng: -75.13009, lat: 39.730964, postalCode: "8071" },
    { lng: -74.55211, lat: 39.370083, postalCode: "8225" },
    { lng: -74.9385, lat: 39.524179, postalCode: "8326" },
    { lng: -74.48688, lat: 41.232344, postalCode: "7418" },
    { lng: -74.48223, lat: 40.842399, postalCode: "7950" },
    { lng: -74.59222, lat: 40.538903, postalCode: "8835" },
    { lng: -74.79747, lat: 39.991562, postalCode: "8060" },
    { lng: -74.26012, lat: 41.096095, postalCode: "7456" },
    { lng: -74.80199, lat: 41.0714, postalCode: "7860" },
    { lng: -74.10239, lat: 41.001695, postalCode: "7423" },
    { lng: -74.07506, lat: 40.86289, postalCode: "7604" },
    { lng: -74.26058, lat: 40.653502, postalCode: "7203" },
    { lng: -74.7805, lat: 40.238258, postalCode: "8618" },
    { lng: -74.32351, lat: 40.650802, postalCode: "7027" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7198" },
    { lng: -74.50624, lat: 40.926798, postalCode: "7866" },
    { lng: -74.94972, lat: 39.517539, postalCode: "8341" },
    { lng: -74.1199, lat: 40.905639, postalCode: "7407" },
    { lng: -74.63781, lat: 40.696601, postalCode: "7931" },
    { lng: -75.32687, lat: 39.753088, postalCode: "8085" },
    { lng: -74.831105, lat: 40.576372, postalCode: "8885" },
    { lng: -75.12933, lat: 39.475565, postalCode: "8352" },
    { lng: -74.03372, lat: 40.153024, postalCode: "7762" },
    { lng: -74.12576, lat: 40.961109, postalCode: "7452" },
    { lng: -73.93064, lat: 40.952814, postalCode: "7620" },
    { lng: -74.43677, lat: 41.045897, postalCode: "7435" },
    { lng: -75.1802, lat: 39.867158, postalCode: "8063" },
    { lng: -74.67432, lat: 40.72265, postalCode: "7934" },
    { lng: -74.87264, lat: 39.769357, postalCode: "8004" },
    { lng: -74.29913, lat: 40.724168, postalCode: "7041" },
    { lng: -74.17326, lat: 40.735257, postalCode: "7102" },
    { lng: -74.71778, lat: 40.132784, postalCode: "8505" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7763" },
    { lng: -74.21576, lat: 40.81315, postalCode: "7042" },
    { lng: -74.34625, lat: 40.92241, postalCode: "7082" },
    { lng: -74.20815, lat: 40.146597, postalCode: "7731" },
    { lng: -74.87515, lat: 39.017837, postalCode: "8242" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7015" },
    { lng: -74.99687, lat: 39.285464, postalCode: "8329" },
    { lng: -74.32134, lat: 40.788733, postalCode: "7039" },
    { lng: -74.90342, lat: 39.949446, postalCode: "8054" },
    { lng: -74.96224, lat: 40.583035, postalCode: "8867" },
    { lng: -74.96899, lat: 39.833847, postalCode: "8026" },
    { lng: -74.23019, lat: 40.899873, postalCode: "7571" },
    { lng: -74.36573, lat: 39.59899, postalCode: "8087" },
    { lng: -74.75904, lat: 41.177457, postalCode: "7826" },
    { lng: -75.05424, lat: 39.868013, postalCode: "8007" },
    { lng: -75.0767, lat: 39.850013, postalCode: "8078" },
    { lng: -74.32216, lat: 40.701801, postalCode: "7081" },
    { lng: -74.578269, lat: 40.867331, postalCode: "7983" },
    { lng: -73.9758, lat: 40.893343, postalCode: "7631" },
    { lng: -74.650023, lat: 40.502477, postalCode: "8844" },
    { lng: -74.39754, lat: 40.516104, postalCode: "8817" },
    { lng: -74.822, lat: 38.987556, postalCode: "8260" },
    { lng: -75.09162, lat: 39.867013, postalCode: "8031" },
    { lng: -74.13669, lat: 40.834049, postalCode: "7014" },
    { lng: -74.0488, lat: 40.888678, postalCode: "7601" },
    { lng: -74.28673, lat: 39.658781, postalCode: "8092" },
    { lng: -74.65769, lat: 40.876349, postalCode: "7852" },
    { lng: -74.08466, lat: 40.836555, postalCode: "7072" },
    { lng: -74.3587, lat: 40.543354, postalCode: "8840" },
    { lng: -75.0609, lat: 39.781264, postalCode: "8032" },
    { lng: -74.57785, lat: 40.164556, postalCode: "8501" },
    { lng: -74.01671, lat: 40.289985, postalCode: "7764" },
    { lng: -75.0321, lat: 40.661172, postalCode: "8802" },
    { lng: -74.00916, lat: 40.211982, postalCode: "7756" },
    { lng: -74.65251, lat: 40.855549, postalCode: "7876" },
    { lng: -73.96504, lat: 40.942074, postalCode: "7626" },
    { lng: -74.17194, lat: 40.384832, postalCode: "7733" },
    { lng: -74.9365, lat: 39.779582, postalCode: "8009" },
    { lng: -74.027285, lat: 40.402277, postalCode: "7752" },
    { lng: -74.725079, lat: 39.862433, postalCode: "8370" },
    { lng: -74.66915, lat: 40.17296, postalCode: "8620" },
    { lng: -74.16037, lat: 39.902851, postalCode: "8721" },
    { lng: -74.39009, lat: 40.77835, postalCode: "7932" },
    { lng: -74.29813, lat: 40.964548, postalCode: "7444" },
    { lng: -74.9489, lat: 39.968962, postalCode: "8057" },
    { lng: -75.11181, lat: 40.694938, postalCode: "8886" },
    { lng: -75.15643, lat: 39.633964, postalCode: "8343" },
    { lng: -74.44022, lat: 40.892021, postalCode: "7046" },
    { lng: -74.8503, lat: 39.722622, postalCode: "8089" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7710" },
    { lng: -74.15141, lat: 39.974584, postalCode: "8753" },
    { lng: -74.57753, lat: 40.72344, postalCode: "7924" },
    { lng: -75.118, lat: 39.949579, postalCode: "8102" },
    { lng: -74.19158, lat: 40.653189, postalCode: "7206" },
    { lng: -74.62009, lat: 40.88038, postalCode: "7847" },
    { lng: -74.6993, lat: 41.139545, postalCode: "7822" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7777" },
    { lng: -74.608557, lat: 39.509208, postalCode: "8220" },
    { lng: -74.63559, lat: 40.919498, postalCode: "7856" },
    { lng: -75.03239, lat: 40.509998, postalCode: "8825" },
    { lng: -74.32749, lat: 40.73915, postalCode: "7078" },
    { lng: -74.23734, lat: 40.435961, postalCode: "7721" },
    { lng: -74.08265, lat: 40.877999, postalCode: "7644" },
    { lng: -74.083231, lat: 40.948054, postalCode: "7451" },
    { lng: -74.1879, lat: 40.760667, postalCode: "7107" },
    { lng: -74.2014, lat: 40.722751, postalCode: "7108" },
    { lng: -73.98659, lat: 40.958573, postalCode: "7641" },
    { lng: -74.93184, lat: 40.375039, postalCode: "8530" },
    { lng: -74.45628, lat: 39.595957, postalCode: "8224" },
    { lng: -75.13284, lat: 39.861413, postalCode: "8093" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7477" },
    { lng: -75.13221, lat: 39.830313, postalCode: "8096" },
    { lng: -74.27987, lat: 40.578996, postalCode: "7001" },
    { lng: -74.21303, lat: 40.711651, postalCode: "7112" },
    { lng: -74.49171, lat: 40.672968, postalCode: "7980" },
    { lng: -74.72951, lat: 40.276782, postalCode: "8648" },
    { lng: -74.678956, lat: 41.12831, postalCode: "7890" },
    { lng: -74.65657, lat: 40.707505, postalCode: "7977" },
    { lng: -74.48192, lat: 39.342299, postalCode: "8406" },
    { lng: -74.608557, lat: 39.509208, postalCode: "8405" },
    { lng: -74.578269, lat: 40.867331, postalCode: "7962" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7799" },
    { lng: -74.10925, lat: 40.852931, postalCode: "7057" },
    { lng: -74.69865, lat: 39.145755, postalCode: "8243" },
    { lng: -74.99574, lat: 39.810485, postalCode: "8021" },
    { lng: -75.48932, lat: 39.683577, postalCode: "8023" },
    { lng: -74.21763, lat: 40.903348, postalCode: "7512" },
    { lng: -74.01239, lat: 40.318115, postalCode: "7757" },
    { lng: -74.55352, lat: 40.423055, postalCode: "8824" },
    { lng: -74.599801, lat: 40.566553, postalCode: "8896" },
    { lng: -75.3631, lat: 39.559426, postalCode: "8001" },
    { lng: -75.46906, lat: 39.712593, postalCode: "8069" },
    { lng: -74.599801, lat: 40.566553, postalCode: "7939" },
    { lng: -74.05579, lat: 40.025258, postalCode: "8738" },
    { lng: -74.52011, lat: 40.672823, postalCode: "7946" },
    { lng: -74.01963, lat: 40.7946, postalCode: "7047" },
    { lng: -74.04112, lat: 40.850621, postalCode: "7643" },
    { lng: -75.00955, lat: 39.562763, postalCode: "8344" },
    { lng: -75.41262, lat: 39.740353, postalCode: "8067" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8645" },
    { lng: -74.04123, lat: 40.133754, postalCode: "8750" },
    { lng: -75.02626, lat: 40.00278, postalCode: "8065" },
    { lng: -74.22856, lat: 40.695552, postalCode: "7205" },
    { lng: -75.29761, lat: 39.458114, postalCode: "8353" },
    { lng: -74.13298, lat: 40.444407, postalCode: "7734" },
    { lng: -74.23767, lat: 40.411102, postalCode: "7747" },
    { lng: -74.985914, lat: 40.84335, postalCode: "7829" },
    { lng: -74.54329, lat: 40.389428, postalCode: "8852" },
    { lng: -74.816619, lat: 39.056521, postalCode: "8250" },
    { lng: -74.442797, lat: 40.77975, postalCode: "7961" },
    { lng: -75.06401, lat: 39.915263, postalCode: "8108" },
    { lng: -74.2094, lat: 40.082782, postalCode: "8701" },
    { lng: -74.6806, lat: 40.038339, postalCode: "8041" },
    { lng: -74.55565, lat: 40.439412, postalCode: "8823" },
    { lng: -74.0402, lat: 40.731901, postalCode: "7310" },
    { lng: -74.72005, lat: 40.199859, postalCode: "8610" },
    { lng: -74.18306, lat: 40.300226, postalCode: "7722" },
    { lng: -74.5089, lat: 41.193708, postalCode: "7462" },
    { lng: -74.07703, lat: 40.352953, postalCode: "7701" },
    { lng: -74.77705, lat: 39.625291, postalCode: "8037" },
    { lng: -74.02769, lat: 40.873876, postalCode: "7603" },
    { lng: -74.083231, lat: 40.948054, postalCode: "7602" },
    { lng: -73.97334, lat: 40.830733, postalCode: "7020" },
    { lng: -74.816619, lat: 39.056521, postalCode: "8246" },
    { lng: -74.5354, lat: 39.777314, postalCode: "8019" },
    { lng: -74.23006, lat: 40.742251, postalCode: "7106" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7474" },
    { lng: -75.25304, lat: 39.785219, postalCode: "8056" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8650" },
    { lng: -74.29575, lat: 40.946748, postalCode: "7440" },
    { lng: -74.94016, lat: 39.219301, postalCode: "8314" },
    { lng: -74.299692, lat: 40.665651, postalCode: "7091" },
    { lng: -74.81456, lat: 39.376099, postalCode: "8319" },
    { lng: -74.28099, lat: 40.607103, postalCode: "7065" },
    { lng: -74.14271, lat: 40.912148, postalCode: "7504" },
    { lng: -74.81723, lat: 39.176208, postalCode: "8245" },
    { lng: -74.48433, lat: 40.075185, postalCode: "8533" },
    { lng: -73.94849, lat: 41.009414, postalCode: "7647" },
    { lng: -74.07797, lat: 40.906553, postalCode: "7662" },
    { lng: -74.16863, lat: 40.912198, postalCode: "7501" },
    { lng: -74.061302, lat: 40.989508, postalCode: "7676" },
    { lng: -74.3298, lat: 40.397507, postalCode: "8857" },
    { lng: -74.26595, lat: 40.361014, postalCode: "7751" },
    { lng: -74.4257, lat: 40.500254, postalCode: "8904" },
    { lng: -75.17326, lat: 39.716194, postalCode: "8074" },
    { lng: -74.17102, lat: 40.868699, postalCode: "7013" },
    { lng: -74.74298, lat: 40.966308, postalCode: "7821" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7510" },
    { lng: -74.89684, lat: 39.522686, postalCode: "8310" },
    { lng: -74.55462, lat: 40.889166, postalCode: "7801" },
    { lng: -74.39736, lat: 40.7332, postalCode: "7928" },
    { lng: -74.14944, lat: 40.906598, postalCode: "7513" },
    { lng: -74.68545, lat: 40.518578, postalCode: "8821" },
    { lng: -73.99342, lat: 40.945173, postalCode: "7628" },
    { lng: -73.98949, lat: 40.82154, postalCode: "7010" },
    { lng: -74.25339, lat: 40.633953, postalCode: "7036" },
    { lng: -74.24928, lat: 40.000759, postalCode: "8754" },
    { lng: -74.1671, lat: 40.998583, postalCode: "7481" },
    { lng: -74.40504, lat: 40.434239, postalCode: "8816" },
    { lng: -74.99951, lat: 39.908613, postalCode: "8034" },
    { lng: -74.69036, lat: 41.094159, postalCode: "7848" },
    { lng: -74.03738, lat: 40.360581, postalCode: "7704" },
    { lng: -74.28091, lat: 40.827499, postalCode: "7021" },
    { lng: -75.02266, lat: 39.932279, postalCode: "8002" },
    { lng: -74.938259, lat: 39.80237, postalCode: "8018" },
    { lng: -74.12111, lat: 40.808416, postalCode: "7071" },
    { lng: -74.47166, lat: 40.594502, postalCode: "8812" },
    { lng: -75.04585, lat: 40.82383, postalCode: "7823" },
    { lng: -74.50914, lat: 39.457517, postalCode: "8201" },
    { lng: -75.07212, lat: 39.892213, postalCode: "8106" },
    { lng: -74.41955, lat: 40.37594, postalCode: "8828" },
    { lng: -74.02176, lat: 40.263575, postalCode: "7755" },
    { lng: -75.08736, lat: 39.655964, postalCode: "8312" },
    { lng: -74.19889, lat: 40.669502, postalCode: "7201" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8606" },
    { lng: -74.15206, lat: 40.725801, postalCode: "7105" },
    { lng: -74.75643, lat: 40.206709, postalCode: "8625" },
    { lng: -74.16144, lat: 40.7935, postalCode: "7109" },
    { lng: -74.99617, lat: 40.753753, postalCode: "7882" },
    { lng: -74.57039, lat: 40.6789, postalCode: "7920" },
    { lng: -74.18895, lat: 40.803, postalCode: "7003" },
    { lng: -75.08959, lat: 40.652484, postalCode: "8804" },
    { lng: -74.962071, lat: 39.779179, postalCode: "8099" },
    { lng: -74.35893, lat: 40.99313, postalCode: "7405" },
    { lng: -74.949409, lat: 40.563654, postalCode: "8803" },
    { lng: -74.12231, lat: 41.013109, postalCode: "7463" },
    { lng: -75.17465, lat: 39.241686, postalCode: "8334" },
    { lng: -74.5999, lat: 39.319901, postalCode: "8244" },
    { lng: -74.03291, lat: 40.99904, postalCode: "7675" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8989" },
    { lng: -74.299692, lat: 40.665651, postalCode: "7207" },
    { lng: -74.83658, lat: 39.081754, postalCode: "8210" },
    { lng: -74.0188, lat: 40.936211, postalCode: "7646" },
    { lng: -74.91404, lat: 40.058211, postalCode: "8010" },
    { lng: -74.52081, lat: 41.091254, postalCode: "7460" },
    { lng: -74.02725, lat: 40.97514, postalCode: "7630" },
    { lng: -74.65749, lat: 40.908198, postalCode: "7850" },
    { lng: -75.18159, lat: 39.849156, postalCode: "8086" },
    { lng: -74.38112, lat: 40.881149, postalCode: "7034" },
    { lng: -74.21897, lat: 40.75555, postalCode: "7018" },
    { lng: -74.27669, lat: 40.469606, postalCode: "8879" },
    { lng: -74.48851, lat: 40.733739, postalCode: "7976" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8666" },
    { lng: -74.04065, lat: 41.034672, postalCode: "7656" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8570" },
    { lng: -74.585653, lat: 40.733863, postalCode: "7952" },
    { lng: -74.12059, lat: 40.337374, postalCode: "7738" },
    { lng: -74.86043, lat: 39.656976, postalCode: "8095" },
    { lng: -74.19374, lat: 40.439714, postalCode: "7735" },
    { lng: -74.66656, lat: 40.018503, postalCode: "8042" },
    { lng: -74.17883, lat: 40.707553, postalCode: "7114" },
    { lng: -74.44612, lat: 40.604252, postalCode: "7063" },
    { lng: -75.11951, lat: 39.700008, postalCode: "8028" },
    { lng: -74.678956, lat: 41.12831, postalCode: "7837" },
    { lng: -74.04703, lat: 40.721602, postalCode: "7302" },
    { lng: -74.30371, lat: 40.656302, postalCode: "7016" },
    { lng: -73.95173, lat: 40.883749, postalCode: "7632" },
    { lng: -75.22901, lat: 39.530314, postalCode: "8313" },
    { lng: -73.98551, lat: 40.862685, postalCode: "7605" },
    { lng: -75.21987, lat: 39.716939, postalCode: "8062" },
    { lng: -74.49617, lat: 40.940301, postalCode: "7842" },
    { lng: -74.34213, lat: 40.294244, postalCode: "7726" },
    { lng: -74.62171, lat: 39.548049, postalCode: "8215" },
    { lng: -74.73875, lat: 40.878198, postalCode: "7828" },
    { lng: -75.10976, lat: 39.918663, postalCode: "8104" },
    { lng: -74.15509, lat: 40.745951, postalCode: "7029" },
    { lng: -74.580886, lat: 41.162053, postalCode: "7819" },
    { lng: -73.9904, lat: 40.404474, postalCode: "7732" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7184" },
    { lng: -74.573051, lat: 40.664351, postalCode: "7290" },
    { lng: -74.37047, lat: 41.102569, postalCode: "7480" },
    { lng: -74.24928, lat: 40.302718, postalCode: "7709" },
    { lng: -74.13488, lat: 39.925301, postalCode: "8740" },
    { lng: -74.639597, lat: 40.642491, postalCode: "7978" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8906" },
    { lng: -74.04643, lat: 40.863699, postalCode: "7606" },
    { lng: -74.61538, lat: 40.377173, postalCode: "8528" },
    { lng: -74.032506, lat: 40.729234, postalCode: "7301" },
    { lng: -74.58246, lat: 40.913298, postalCode: "7885" },
    { lng: -74.51796, lat: 41.181052, postalCode: "7428" },
    { lng: -74.78364, lat: 40.32315, postalCode: "8534" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7007" },
    { lng: -74.0759, lat: 39.948518, postalCode: "8751" },
    { lng: -74.5972, lat: 41.080066, postalCode: "7439" },
    { lng: -74.25004, lat: 40.943608, postalCode: "7470" },
    { lng: -74.73334, lat: 40.219358, postalCode: "8629" },
    { lng: -74.041234, lat: 40.951348, postalCode: "7610" },
    { lng: -74.23727, lat: 41.028884, postalCode: "7436" },
    { lng: -74.52212, lat: 39.394616, postalCode: "8232" },
    { lng: -74.85319, lat: 40.515645, postalCode: "8822" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8602" },
    { lng: -74.12899, lat: 40.857384, postalCode: "7055" },
    { lng: -74.55611, lat: 40.050128, postalCode: "8511" },
    { lng: -74.5748, lat: 40.847649, postalCode: "7869" },
    { lng: -74.1939, lat: 40.918948, postalCode: "7502" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8855" },
    { lng: -74.77977, lat: 40.113883, postalCode: "8554" },
    { lng: -74.28807, lat: 40.99948, postalCode: "7442" },
    { lng: -74.66147, lat: 40.937206, postalCode: "7843" },
    { lng: -74.16624, lat: 39.862864, postalCode: "8734" },
    { lng: -74.059762, lat: 41.025101, postalCode: "7677" },
    { lng: -75.05024, lat: 39.949979, postalCode: "8109" },
    { lng: -73.97986, lat: 40.332877, postalCode: "7750" },
    { lng: -74.59381, lat: 39.265371, postalCode: "8226" },
    { lng: -74.850759, lat: 41.10289, postalCode: "7877" },
    { lng: -73.97501, lat: 40.849879, postalCode: "7024" },
    { lng: -74.6374, lat: 40.571302, postalCode: "8869" },
    { lng: -74.06058, lat: 40.416772, postalCode: "7737" },
    { lng: -74.5606, lat: 39.960596, postalCode: "8015" },
    { lng: -74.04694, lat: 40.175357, postalCode: "7719" },
    { lng: -74.19507, lat: 40.738501, postalCode: "7103" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8604" },
    { lng: -74.22509, lat: 40.736101, postalCode: "7101" },
    { lng: -75.06193, lat: 40.92976, postalCode: "7832" },
    { lng: -74.99805, lat: 39.997379, postalCode: "8077" },
    { lng: -75.23773, lat: 39.833156, postalCode: "8066" },
    { lng: -74.0486, lat: 40.309121, postalCode: "7703" },
    { lng: -74.60098, lat: 40.878785, postalCode: "7803" },
    { lng: -74.97767, lat: 39.254836, postalCode: "8327" },
    { lng: -74.91405, lat: 40.716332, postalCode: "8826" },
    { lng: -74.22997, lat: 40.582504, postalCode: "7008" },
    { lng: -74.63592, lat: 40.545853, postalCode: "8876" },
    { lng: -74.06768, lat: 39.982101, postalCode: "8735" },
    { lng: -74.64986, lat: 39.264412, postalCode: "8223" },
    { lng: -74.075485, lat: 40.73276, postalCode: "7096" },
    { lng: -74.897002, lat: 40.864844, postalCode: "7880" },
    { lng: -74.50723, lat: 40.505253, postalCode: "8873" },
    { lng: -74.36474, lat: 40.820012, postalCode: "7936" },
    { lng: -74.99001, lat: 39.218484, postalCode: "8324" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8607" },
    { lng: -74.869538, lat: 40.863567, postalCode: "7841" },
    { lng: -75.10637, lat: 39.74953, postalCode: "8080" },
    { lng: -74.974344, lat: 39.258089, postalCode: "8237" },
    { lng: -74.201489, lat: 40.875949, postalCode: "7427" },
    { lng: -74.85749, lat: 39.037404, postalCode: "8252" },
    { lng: -75.17225, lat: 39.239649, postalCode: "8321" },
    { lng: -74.884741, lat: 40.436394, postalCode: "8851" },
    { lng: -74.41401, lat: 40.580703, postalCode: "7080" },
    { lng: -74.70301, lat: 40.897398, postalCode: "7857" },
    { lng: -74.88301, lat: 41.172968, postalCode: "7851" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7051" },
    { lng: -74.11438, lat: 40.983997, postalCode: "7450" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8988" },
    { lng: -75.05681, lat: 39.966812, postalCode: "8110" },
    { lng: -74.40383, lat: 40.631252, postalCode: "7062" },
    { lng: -74.2005, lat: 39.614596, postalCode: "8008" },
    { lng: -74.14689, lat: 39.942616, postalCode: "8732" },
    { lng: -74.10807, lat: 40.879858, postalCode: "7026" },
    { lng: -74.075391, lat: 40.73235, postalCode: "7311" },
    { lng: -74.711731, lat: 40.580918, postalCode: "8875" },
    { lng: -74.17129, lat: 40.916165, postalCode: "7505" },
    { lng: -74.29376, lat: 40.923233, postalCode: "7035" },
    { lng: -74.96802, lat: 40.815107, postalCode: "7863" },
    { lng: -74.304793, lat: 41.011428, postalCode: "7533" },
    { lng: -74.87979, lat: 40.966266, postalCode: "7846" },
    { lng: -74.33021, lat: 41.014497, postalCode: "7403" },
    { lng: -74.84979, lat: 40.449171, postalCode: "8551" },
    { lng: -74.85947, lat: 40.31049, postalCode: "8560" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8605" },
    { lng: -74.11074, lat: 39.75196, postalCode: "8006" },
    { lng: -74.022351, lat: 40.769796, postalCode: "7086" },
    { lng: -74.75953, lat: 40.249908, postalCode: "8638" },
    { lng: -75.03923, lat: 39.379382, postalCode: "8332" },
    { lng: -74.60203, lat: 41.229754, postalCode: "7461" },
    { lng: -74.581948, lat: 41.152239, postalCode: "7919" },
    { lng: -74.949409, lat: 40.563654, postalCode: "8557" },
    { lng: -74.25933, lat: 39.707575, postalCode: "8050" },
    { lng: -74.28416, lat: 40.718401, postalCode: "7088" },
    { lng: -75.32396, lat: 39.637838, postalCode: "8098" },
    { lng: -74.29742, lat: 41.026747, postalCode: "7420" },
    { lng: -74.43903, lat: 39.364966, postalCode: "8401" },
    { lng: -74.2908, lat: 40.674652, postalCode: "7033" },
    { lng: -74.46094, lat: 40.555355, postalCode: "8854" },
    { lng: -74.75136, lat: 40.206959, postalCode: "8611" },
    { lng: -74.63592, lat: 40.460137, postalCode: "8502" },
    { lng: -74.01223, lat: 40.7895, postalCode: "7093" },
    { lng: -75.08618, lat: 39.908163, postalCode: "8107" },
    { lng: -74.31104, lat: 40.535304, postalCode: "8863" },
    { lng: -74.083231, lat: 40.948054, postalCode: "7495" },
    { lng: -74.42134, lat: 40.18238, postalCode: "8510" },
    { lng: -74.79632, lat: 40.525361, postalCode: "8887" },
    { lng: -74.13409, lat: 41.031505, postalCode: "7401" },
    { lng: -74.43938, lat: 40.451105, postalCode: "8850" },
    { lng: -74.27708, lat: 40.520105, postalCode: "8861" },
    { lng: -74.15731, lat: 40.896798, postalCode: "7503" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8601" },
    { lng: -74.54034, lat: 40.567953, postalCode: "8805" },
    { lng: -74.81866, lat: 40.723778, postalCode: "7830" },
    { lng: -74.27398, lat: 39.759581, postalCode: "8005" },
    { lng: -73.95586, lat: 40.994442, postalCode: "7648" },
    { lng: -74.78112, lat: 40.386902, postalCode: "8525" },
    { lng: -74.075485, lat: 40.73276, postalCode: "7099" },
    { lng: -74.63895, lat: 40.400406, postalCode: "8553" },
    { lng: -74.97889, lat: 39.314084, postalCode: "8348" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7192" },
    { lng: -75.03671, lat: 39.85478, postalCode: "8049" },
    { lng: -74.70134, lat: 40.84405, postalCode: "7836" },
    { lng: -75.221681, lat: 39.379906, postalCode: "8320" },
    { lng: -74.23032, lat: 40.010092, postalCode: "8755" },
    { lng: -74.083231, lat: 40.948054, postalCode: "7688" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8646" },
    { lng: -74.62253, lat: 40.588097, postalCode: "8807" },
    { lng: -75.17243, lat: 40.689123, postalCode: "8865" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7175" },
    { lng: -74.26768, lat: 40.665552, postalCode: "7204" },
    { lng: -74.76058, lat: 41.300062, postalCode: "7827" },
    { lng: -74.417344, lat: 40.430006, postalCode: "8933" },
    { lng: -74.28236, lat: 41.052958, postalCode: "7465" },
    { lng: -74.06226, lat: 40.108433, postalCode: "8730" },
    { lng: -74.05261, lat: 40.208945, postalCode: "7753" },
    { lng: -74.70528, lat: 39.871699, postalCode: "8088" },
    { lng: -74.89241, lat: 40.668594, postalCode: "8829" },
    { lng: -74.668753, lat: 40.425369, postalCode: "8504" },
    { lng: -74.250942, lat: 40.436865, postalCode: "8878" },
    { lng: -74.27917, lat: 40.848999, postalCode: "7006" },
    { lng: -74.2047, lat: 40.80495, postalCode: "7028" },
    { lng: -74.712018, lat: 40.280531, postalCode: "8647" },
    { lng: -74.33844, lat: 40.529355, postalCode: "8837" },
    { lng: -74.82345, lat: 40.643314, postalCode: "8833" },
    { lng: -74.34331, lat: 40.871299, postalCode: "7058" },
    { lng: -74.123, lat: 40.042479, postalCode: "8723" },
    { lng: -74.9719, lat: 39.879713, postalCode: "8003" },
    { lng: -74.55174, lat: 40.595552, postalCode: "8836" },
    { lng: -74.245241, lat: 40.79185, postalCode: "7195" },
    { lng: -74.04943, lat: 40.748001, postalCode: "7307" },
    { lng: -74.44775, lat: 40.488304, postalCode: "8901" },
    { lng: -74.11768, lat: 40.666552, postalCode: "7002" },
    { lng: -74.849661, lat: 40.869648, postalCode: "7820" },
    { lng: -74.445098, lat: 40.513854, postalCode: "8903" },
    { lng: -75.18577, lat: 39.337205, postalCode: "8311" },
    { lng: -74.1579, lat: 40.847922, postalCode: "7012" },
    { lng: -73.724257, lat: 41.333398, postalCode: "10540" },
    { lng: -78.67147, lat: 42.692304, postalCode: "14170" },
    { lng: -74.058015, lat: 42.833261, postalCode: "12345" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10629" },
    { lng: -78.13384, lat: 42.319654, postalCode: "14711" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11252" },
    { lng: -73.58219, lat: 44.711769, postalCode: "12962" },
    { lng: -79.07622, lat: 42.0385, postalCode: "14738" },
    { lng: -73.84476, lat: 40.675534, postalCode: "11417" },
    { lng: -74.50137, lat: 42.368009, postalCode: "12434" },
    { lng: -72.637078, lat: 40.922326, postalCode: "501" },
    { lng: -76.159312, lat: 42.431068, postalCode: "13784" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11025" },
    { lng: -74.5698, lat: 41.708062, postalCode: "12789" },
    { lng: -74.37833, lat: 41.674288, postalCode: "12420" },
    { lng: -78.48378, lat: 43.199665, postalCode: "14105" },
    { lng: -75.616793, lat: 42.954541, postalCode: "13465" },
    { lng: -75.47796, lat: 43.239274, postalCode: "13599" },
    { lng: -75.59457, lat: 42.967906, postalCode: "13409" },
    { lng: -73.92002, lat: 42.820909, postalCode: "12308" },
    { lng: -73.71886, lat: 40.972667, postalCode: "10528" },
    { lng: -73.88755, lat: 40.81321, postalCode: "10474" },
    { lng: -73.978, lat: 43.292593, postalCode: "12835" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14644" },
    { lng: -72.26831, lat: 40.937683, postalCode: "11962" },
    { lng: -73.39593, lat: 42.552127, postalCode: "12168" },
    { lng: -73.935418, lat: 42.098698, postalCode: "12453" },
    { lng: -74.11016, lat: 42.037356, postalCode: "12498" },
    { lng: -73.6514, lat: 42.305793, postalCode: "12075" },
    { lng: -73.639291, lat: 43.47181, postalCode: "12820" },
    { lng: -73.94824, lat: 40.680398, postalCode: "11216" },
    { lng: -73.51745, lat: 44.088275, postalCode: "12956" },
    { lng: -73.68848, lat: 43.174566, postalCode: "12831" },
    { lng: -77.157465, lat: 43.033002, postalCode: "14537" },
    { lng: -73.77687, lat: 41.120025, postalCode: "10594" },
    { lng: -76.704722, lat: 43.002267, postalCode: "13117" },
    { lng: -76.35211, lat: 42.39534, postalCode: "14881" },
    { lng: -74.78034, lat: 42.364673, postalCode: "13739" },
    { lng: -73.96831, lat: 41.656805, postalCode: "12547" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11051" },
    { lng: -73.11839, lat: 40.855402, postalCode: "11755" },
    { lng: -74.05574, lat: 42.621933, postalCode: "12059" },
    { lng: -73.55527, lat: 40.7143, postalCode: "11554" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13251" },
    { lng: -76.72562, lat: 42.366682, postalCode: "14805" },
    { lng: -73.750314, lat: 42.647079, postalCode: "12246" },
    { lng: -73.78131, lat: 44.171625, postalCode: "12943" },
    { lng: -74.982056, lat: 42.505122, postalCode: "13747" },
    { lng: -78.32283, lat: 42.750175, postalCode: "14167" },
    { lng: -74.15626, lat: 41.478722, postalCode: "12575" },
    { lng: -75.740848, lat: 43.042044, postalCode: "13043" },
    { lng: -76.153442, lat: 43.457798, postalCode: "13107" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10559" },
    { lng: -74.3116, lat: 42.784091, postalCode: "12066" },
    { lng: -74.979811, lat: 44.828685, postalCode: "13678" },
    { lng: -73.74127, lat: 40.719981, postalCode: "11428" },
    { lng: -75.511301, lat: 43.164312, postalCode: "13441" },
    { lng: -75.32106, lat: 42.832184, postalCode: "13314" },
    { lng: -77.627674, lat: 43.127513, postalCode: "14627" },
    { lng: -77.89514, lat: 42.473556, postalCode: "14884" },
    { lng: -73.94287, lat: 41.149245, postalCode: "10920" },
    { lng: -75.95725, lat: 44.194126, postalCode: "13656" },
    { lng: -77.60377, lat: 43.132474, postalCode: "14620" },
    { lng: -76.15709, lat: 42.396659, postalCode: "13835" },
    { lng: -73.84702, lat: 40.89095, postalCode: "10466" },
    { lng: -74.12096, lat: 41.35805, postalCode: "10930" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11388" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11745" },
    { lng: -76.42578, lat: 42.643214, postalCode: "13092" },
    { lng: -78.86338, lat: 43.048156, postalCode: "14120" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10261" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10581" },
    { lng: -79.05552, lat: 42.372077, postalCode: "14138" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12250" },
    { lng: -73.99653, lat: 40.750742, postalCode: "10001" },
    { lng: -73.58694, lat: 40.679413, postalCode: "11575" },
    { lng: -75.150183, lat: 43.305156, postalCode: "13435" },
    { lng: -79.57301, lat: 42.327029, postalCode: "14787" },
    { lng: -78.30011, lat: 42.483508, postalCode: "14065" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10212" },
    { lng: -77.55638, lat: 43.21543, postalCode: "14622" },
    { lng: -73.95019, lat: 40.583803, postalCode: "11235" },
    { lng: -75.28214, lat: 41.985839, postalCode: "13783" },
    { lng: -78.184813, lat: 42.998052, postalCode: "14021" },
    { lng: -76.440833, lat: 42.97446, postalCode: "13119" },
    { lng: -76.71263, lat: 42.265328, postalCode: "14824" },
    { lng: -72.9552, lat: 40.886267, postalCode: "11953" },
    { lng: -73.851898, lat: 42.521038, postalCode: "12161" },
    { lng: -74.37495, lat: 42.896974, postalCode: "12072" },
    { lng: -74.96875, lat: 44.823502, postalCode: "13667" },
    { lng: -74.11759, lat: 42.862695, postalCode: "12137" },
    { lng: -74.84656, lat: 41.53481, postalCode: "12743" },
    { lng: -76.93132, lat: 42.451153, postalCode: "14878" },
    { lng: -74.80223, lat: 43.222171, postalCode: "13454" },
    { lng: -74.07288, lat: 42.320081, postalCode: "12405" },
    { lng: -75.65482, lat: 42.83917, postalCode: "13334" },
    { lng: -73.87513, lat: 41.024855, postalCode: "10503" },
    { lng: -76.42589, lat: 43.025843, postalCode: "13060" },
    { lng: -75.22284, lat: 43.114519, postalCode: "13502" },
    { lng: -73.47194, lat: 44.195201, postalCode: "12993" },
    { lng: -74.48703, lat: 44.972087, postalCode: "12937" },
    { lng: -74.41457, lat: 42.565907, postalCode: "12071" },
    { lng: -74.23034, lat: 42.157937, postalCode: "12450" },
    { lng: -75.79275, lat: 42.056312, postalCode: "13795" },
    { lng: -73.41317, lat: 40.6851, postalCode: "11701" },
    { lng: -73.21416, lat: 40.734212, postalCode: "11751" },
    { lng: -74.92185, lat: 42.538589, postalCode: "12116" },
    { lng: -73.45414, lat: 43.858226, postalCode: "12883" },
    { lng: -73.56922, lat: 42.693228, postalCode: "12140" },
    { lng: -73.861217, lat: 41.300561, postalCode: "10517" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14261" },
    { lng: -77.606774, lat: 43.161613, postalCode: "14603" },
    { lng: -73.89142, lat: 40.917281, postalCode: "10705" },
    { lng: -74.87283, lat: 43.822824, postalCode: "13331" },
    { lng: -74.10967, lat: 41.998199, postalCode: "12491" },
    { lng: -73.44645, lat: 40.865731, postalCode: "11724" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10008" },
    { lng: -73.25607, lat: 40.729098, postalCode: "11706" },
    { lng: -74.20686, lat: 41.974857, postalCode: "12481" },
    { lng: -73.65778, lat: 42.442592, postalCode: "12130" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11551" },
    { lng: -74.13715, lat: 40.631127, postalCode: "10302" },
    { lng: -79.02426, lat: 43.169055, postalCode: "14092" },
    { lng: -74.52436, lat: 42.696926, postalCode: "12043" },
    { lng: -73.65515, lat: 40.603159, postalCode: "11558" },
    { lng: -76.66998, lat: 42.758088, postalCode: "13026" },
    { lng: -74.6217, lat: 41.676139, postalCode: "12784" },
    { lng: -75.39563, lat: 42.304177, postalCode: "13838" },
    { lng: -78.999471, lat: 42.509271, postalCode: "14168" },
    { lng: -75.679351, lat: 42.968619, postalCode: "13134" },
    { lng: -74.3337, lat: 41.38928, postalCode: "10924" },
    { lng: -75.97209, lat: 42.717801, postalCode: "13158" },
    { lng: -74.14187, lat: 41.801696, postalCode: "12440" },
    { lng: -75.84463, lat: 42.261923, postalCode: "13746" },
    { lng: -73.66221, lat: 42.785887, postalCode: "12182" },
    { lng: -73.9965, lat: 41.713682, postalCode: "12528" },
    { lng: -75.33434, lat: 42.457398, postalCode: "13776" },
    { lng: -74.04978, lat: 42.515818, postalCode: "12193" },
    { lng: -77.80347, lat: 43.122386, postalCode: "14514" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12228" },
    { lng: -73.80414, lat: 41.746469, postalCode: "12569" },
    { lng: -79.41091, lat: 42.342529, postalCode: "14704" },
    { lng: -75.208242, lat: 42.509167, postalCode: "13792" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11022" },
    { lng: -73.7148, lat: 40.756983, postalCode: "11005" },
    { lng: -74.72901, lat: 41.720235, postalCode: "12742" },
    { lng: -73.705975, lat: 41.013696, postalCode: "10650" },
    { lng: -73.74539, lat: 41.656933, postalCode: "12540" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10117" },
    { lng: -73.47852, lat: 43.583211, postalCode: "12819" },
    { lng: -75.14571, lat: 44.758378, postalCode: "13660" },
    { lng: -74.58404, lat: 42.774077, postalCode: "13459" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12224" },
    { lng: -73.85828, lat: 40.69087, postalCode: "11421" },
    { lng: -73.69018, lat: 41.236931, postalCode: "10507" },
    { lng: -76.27901, lat: 42.483348, postalCode: "13053" },
    { lng: -74.007868, lat: 42.017632, postalCode: "12475" },
    { lng: -73.84321, lat: 40.658188, postalCode: "11414" },
    { lng: -73.979908, lat: 40.760943, postalCode: "10104" },
    { lng: -73.67816, lat: 43.330471, postalCode: "12804" },
    { lng: -76.18642, lat: 42.7707, postalCode: "13141" },
    { lng: -78.87634, lat: 42.651776, postalCode: "14057" },
    { lng: -75.3146, lat: 42.343576, postalCode: "13849" },
    { lng: -73.80922, lat: 40.784453, postalCode: "11357" },
    { lng: -77.81809, lat: 43.189862, postalCode: "14559" },
    { lng: -73.48051, lat: 42.638579, postalCode: "12153" },
    { lng: -78.64583, lat: 42.130224, postalCode: "14748" },
    { lng: -76.9766, lat: 42.286649, postalCode: "14812" },
    { lng: -75.27211, lat: 43.029669, postalCode: "13319" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11353" },
    { lng: -73.70539, lat: 42.915904, postalCode: "12118" },
    { lng: -75.757938, lat: 44.440171, postalCode: "13623" },
    { lng: -73.03436, lat: 40.74897, postalCode: "11715" },
    { lng: -76.77926, lat: 42.341316, postalCode: "14869" },
    { lng: -77.33068, lat: 43.08786, postalCode: "14502" },
    { lng: -79.69185, lat: 42.254906, postalCode: "14775" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14647" },
    { lng: -77.35979, lat: 42.192615, postalCode: "14820" },
    { lng: -73.71355, lat: 44.589583, postalCode: "12985" },
    { lng: -76.15797, lat: 42.950501, postalCode: "13120" },
    { lng: -75.74261, lat: 42.859332, postalCode: "13061" },
    { lng: -74.354699, lat: 41.387822, postalCode: "10959" },
    { lng: -74.16063, lat: 42.061841, postalCode: "12409" },
    { lng: -73.9949, lat: 41.145495, postalCode: "10956" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10258" },
    { lng: -74.386635, lat: 42.223407, postalCode: "12452" },
    { lng: -75.480342, lat: 43.818705, postalCode: "13627" },
    { lng: -74.755373, lat: 41.719016, postalCode: "12781" },
    { lng: -73.88433, lat: 40.750146, postalCode: "11372" },
    { lng: -74.32809, lat: 44.953782, postalCode: "12926" },
    { lng: -73.70793, lat: 42.773777, postalCode: "12047" },
    { lng: -77.16948, lat: 43.160635, postalCode: "14505" },
    { lng: -73.81116, lat: 43.719612, postalCode: "12860" },
    { lng: -77.91499, lat: 42.780121, postalCode: "14481" },
    { lng: -73.65694, lat: 44.879986, postalCode: "12910" },
    { lng: -75.36494, lat: 42.922823, postalCode: "13480" },
    { lng: -73.99876, lat: 42.034256, postalCode: "12456" },
    { lng: -74.89418, lat: 42.187835, postalCode: "13752" },
    { lng: -73.8179, lat: 42.713328, postalCode: "12205" },
    { lng: -72.91611, lat: 40.832858, postalCode: "11980" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10551" },
    { lng: -73.73126, lat: 40.61579, postalCode: "11559" },
    { lng: -73.51287, lat: 44.025573, postalCode: "12960" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11773" },
    { lng: -75.75188, lat: 42.763823, postalCode: "13072" },
    { lng: -74.99617, lat: 44.161058, postalCode: "13690" },
    { lng: -76.34099, lat: 42.213965, postalCode: "13743" },
    { lng: -75.17053, lat: 42.862696, postalCode: "13491" },
    { lng: -73.67343, lat: 42.593047, postalCode: "12061" },
    { lng: -75.08718, lat: 41.838672, postalCode: "12741" },
    { lng: -78.047214, lat: 42.558227, postalCode: "14484" },
    { lng: -78.63497, lat: 43.019908, postalCode: "14032" },
    { lng: -78.49087, lat: 42.562386, postalCode: "14030" },
    { lng: -73.991889, lat: 40.74964, postalCode: "10121" },
    { lng: -73.94154, lat: 40.840686, postalCode: "10032" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14646" },
    { lng: -73.742691, lat: 40.607574, postalCode: "11599" },
    { lng: -74.15946, lat: 42.000093, postalCode: "12433" },
    { lng: -73.96811, lat: 40.798502, postalCode: "10025" },
    { lng: -73.899871, lat: 42.425733, postalCode: "12807" },
    { lng: -73.67241, lat: 41.519085, postalCode: "12531" },
    { lng: -78.05135, lat: 42.63529, postalCode: "14427" },
    { lng: -75.544322, lat: 42.839697, postalCode: "13396" },
    { lng: -73.55792, lat: 41.782781, postalCode: "12592" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11775" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12244" },
    { lng: -79.3902, lat: 42.326844, postalCode: "14784" },
    { lng: -74.16239, lat: 40.629448, postalCode: "10303" },
    { lng: -74.011895, lat: 40.714231, postalCode: "10286" },
    { lng: -73.94118, lat: 40.599256, postalCode: "11229" },
    { lng: -78.27816, lat: 43.077433, postalCode: "14125" },
    { lng: -76.54154, lat: 42.677418, postalCode: "13071" },
    { lng: -73.96317, lat: 42.862193, postalCode: "12302" },
    { lng: -74.060826, lat: 41.160945, postalCode: "10911" },
    { lng: -73.8908, lat: 41.300813, postalCode: "10567" },
    { lng: -72.60506, lat: 40.825411, postalCode: "11959" },
    { lng: -73.77719, lat: 42.669645, postalCode: "12206" },
    { lng: -74.55675, lat: 41.926119, postalCode: "12725" },
    { lng: -73.73352, lat: 40.798839, postalCode: "11023" },
    { lng: -78.27257, lat: 41.999435, postalCode: "14721" },
    { lng: -73.82774, lat: 42.353773, postalCode: "12051" },
    { lng: -76.982149, lat: 43.222735, postalCode: "14413" },
    { lng: -73.50057, lat: 40.815215, postalCode: "11791" },
    { lng: -78.00056, lat: 42.876222, postalCode: "14525" },
    { lng: -78.0973, lat: 42.456047, postalCode: "14735" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12220" },
    { lng: -76.71545, lat: 43.235909, postalCode: "13143" },
    { lng: -77.02486, lat: 42.964535, postalCode: "14532" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11564" },
    { lng: -77.86458, lat: 43.002612, postalCode: "14511" },
    { lng: -73.97709, lat: 40.786387, postalCode: "10024" },
    { lng: -79.37689, lat: 42.170787, postalCode: "14712" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12260" },
    { lng: -73.75962, lat: 40.60002, postalCode: "11691" },
    { lng: -74.46772, lat: 42.624507, postalCode: "12187" },
    { lng: -78.807283, lat: 42.357543, postalCode: "14766" },
    { lng: -74.61427, lat: 41.845381, postalCode: "12765" },
    { lng: -73.91949, lat: 40.743796, postalCode: "11104" },
    { lng: -74.64717, lat: 42.943602, postalCode: "13339" },
    { lng: -73.90377, lat: 40.742869, postalCode: "11377" },
    { lng: -74.318354, lat: 41.182344, postalCode: "10979" },
    { lng: -77.72465, lat: 43.127195, postalCode: "14624" },
    { lng: -72.18564, lat: 40.995384, postalCode: "11937" },
    { lng: -76.00138, lat: 43.28547, postalCode: "13044" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10161" },
    { lng: -73.59417, lat: 40.881957, postalCode: "11560" },
    { lng: -75.97818, lat: 42.847248, postalCode: "13063" },
    { lng: -73.99175, lat: 41.334557, postalCode: "10922" },
    { lng: -78.366991, lat: 42.489129, postalCode: "14133" },
    { lng: -74.0133, lat: 41.100182, postalCode: "10954" },
    { lng: -74.43366, lat: 43.865351, postalCode: "12812" },
    { lng: -75.76895, lat: 44.323952, postalCode: "13679" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11549" },
    { lng: -73.37017, lat: 44.990606, postalCode: "12979" },
    { lng: -74.29778, lat: 41.211794, postalCode: "10925" },
    { lng: -73.96257, lat: 40.680145, postalCode: "11238" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14653" },
    { lng: -75.26202, lat: 43.005669, postalCode: "13456" },
    { lng: -75.27252, lat: 43.689564, postalCode: "13312" },
    { lng: -73.60613, lat: 42.522197, postalCode: "12123" },
    { lng: -73.69309, lat: 40.833246, postalCode: "11050" },
    { lng: -74.57574, lat: 42.130111, postalCode: "12406" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11120" },
    { lng: -75.25536, lat: 42.532969, postalCode: "13808" },
    { lng: -73.44521, lat: 42.932315, postalCode: "12028" },
    { lng: -73.88271, lat: 41.930983, postalCode: "12572" },
    { lng: -78.88515, lat: 42.896407, postalCode: "14201" },
    { lng: -76.509567, lat: 43.163364, postalCode: "13024" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12262" },
    { lng: -75.49024, lat: 44.683636, postalCode: "13669" },
    { lng: -73.91628, lat: 40.805968, postalCode: "10454" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10273" },
    { lng: -73.530601, lat: 44.08267, postalCode: "12998" },
    { lng: -78.23769, lat: 42.279786, postalCode: "14714" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12247" },
    { lng: -74.16565, lat: 44.915973, postalCode: "12917" },
    { lng: -79.08404, lat: 42.594627, postalCode: "14061" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10015" },
    { lng: -73.79347, lat: 41.573253, postalCode: "12533" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10079" },
    { lng: -78.89694, lat: 42.94722, postalCode: "14207" },
    { lng: -73.6095, lat: 40.654112, postalCode: "11510" },
    { lng: -77.715406, lat: 42.834813, postalCode: "14417" },
    { lng: -73.055, lat: 40.74485, postalCode: "11705" },
    { lng: -76.05711, lat: 44.015332, postalCode: "13634" },
    { lng: -77.79548, lat: 43.289787, postalCode: "14468" },
    { lng: -73.98285, lat: 40.776099, postalCode: "10023" },
    { lng: -74.99109, lat: 41.677243, postalCode: "12752" },
    { lng: -73.55475, lat: 40.666575, postalCode: "11566" },
    { lng: -79.04567, lat: 42.633375, postalCode: "14006" },
    { lng: -77.99413, lat: 42.339925, postalCode: "14709" },
    { lng: -76.84694, lat: 42.218224, postalCode: "14872" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14645" },
    { lng: -73.743714, lat: 41.759905, postalCode: "12506" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10557" },
    { lng: -73.64056, lat: 40.725257, postalCode: "11530" },
    { lng: -73.93619, lat: 42.769009, postalCode: "12303" },
    { lng: -75.260259, lat: 43.087169, postalCode: "13505" },
    { lng: -75.60493, lat: 43.346517, postalCode: "13471" },
    { lng: -73.41146, lat: 40.867498, postalCode: "11743" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12055" },
    { lng: -73.80986, lat: 40.700068, postalCode: "11435" },
    { lng: -76.47874, lat: 43.07446, postalCode: "13080" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14660" },
    { lng: -75.0615, lat: 42.992323, postalCode: "13357" },
    { lng: -73.04254, lat: 40.869274, postalCode: "11784" },
    { lng: -78.70394, lat: 43.025217, postalCode: "14051" },
    { lng: -75.9619, lat: 43.099547, postalCode: "13082" },
    { lng: -74.57435, lat: 42.86749, postalCode: "13317" },
    { lng: -73.51156, lat: 44.819829, postalCode: "12992" },
    { lng: -76.06983, lat: 42.453663, postalCode: "13803" },
    { lng: -73.61741, lat: 40.702271, postalCode: "11550" },
    { lng: -79.030785, lat: 42.57075, postalCode: "14027" },
    { lng: -75.195695, lat: 42.590419, postalCode: "13415" },
    { lng: -73.07485, lat: 40.799706, postalCode: "11741" },
    { lng: -75.76484, lat: 43.086439, postalCode: "13032" },
    { lng: -73.90362, lat: 40.640527, postalCode: "11236" },
    { lng: -72.65327, lat: 40.926595, postalCode: "11901" },
    { lng: -77.3361, lat: 42.351281, postalCode: "14810" },
    { lng: -77.58341, lat: 42.674773, postalCode: "14560" },
    { lng: -73.73329, lat: 42.683727, postalCode: "12204" },
    { lng: -79.17185, lat: 42.230692, postalCode: "14740" },
    { lng: -78.80282, lat: 42.249436, postalCode: "14755" },
    { lng: -73.97775, lat: 40.759235, postalCode: "10111" },
    { lng: -73.0386, lat: 40.83469, postalCode: "11738" },
    { lng: -75.87116, lat: 43.059992, postalCode: "13037" },
    { lng: -73.53675, lat: 40.786544, postalCode: "11753" },
    { lng: -72.10411, lat: 40.985301, postalCode: "11930" },
    { lng: -75.727699, lat: 42.214705, postalCode: "13848" },
    { lng: -73.77038, lat: 43.080399, postalCode: "12866" },
    { lng: -73.89906, lat: 42.698231, postalCode: "12084" },
    { lng: -73.97293, lat: 41.360336, postalCode: "10928" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12232" },
    { lng: -74.59109, lat: 41.784477, postalCode: "12788" },
    { lng: -77.62401, lat: 43.155568, postalCode: "14608" },
    { lng: -75.79671, lat: 44.216969, postalCode: "13691" },
    { lng: -78.52655, lat: 42.393206, postalCode: "14101" },
    { lng: -78.38961, lat: 42.974083, postalCode: "14036" },
    { lng: -78.231338, lat: 43.381027, postalCode: "14429" },
    { lng: -74.24801, lat: 42.080232, postalCode: "12495" },
    { lng: -76.11992, lat: 44.0958, postalCode: "13622" },
    { lng: -78.08887, lat: 42.829405, postalCode: "14591" },
    { lng: -75.43424, lat: 42.81488, postalCode: "13355" },
    { lng: -74.37513, lat: 42.691779, postalCode: "12092" },
    { lng: -73.59633, lat: 43.088919, postalCode: "12871" },
    { lng: -74.12514, lat: 42.199479, postalCode: "12485" },
    { lng: -76.152508, lat: 43.75965, postalCode: "13636" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10114" },
    { lng: -77.78904, lat: 42.255694, postalCode: "14802" },
    { lng: -73.33007, lat: 40.760698, postalCode: "11729" },
    { lng: -73.64792, lat: 44.129094, postalCode: "12964" },
    { lng: -73.91214, lat: 40.853017, postalCode: "10453" },
    { lng: -73.4674, lat: 43.229054, postalCode: "12809" },
    { lng: -77.364812, lat: 42.374218, postalCode: "14856" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11571" },
    { lng: -73.510676, lat: 42.494981, postalCode: "12024" },
    { lng: -74.62813, lat: 42.496939, postalCode: "12093" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11043" },
    { lng: -73.9856, lat: 40.667508, postalCode: "11215" },
    { lng: -74.62985, lat: 41.705649, postalCode: "12779" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11243" },
    { lng: -73.67562, lat: 40.701702, postalCode: "11010" },
    { lng: -76.91606, lat: 42.374828, postalCode: "14891" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10276" },
    { lng: -74.03728, lat: 42.430684, postalCode: "12083" },
    { lng: -74.01342, lat: 40.707904, postalCode: "10006" },
    { lng: -74.43951, lat: 41.449364, postalCode: "10940" },
    { lng: -73.78934, lat: 44.25462, postalCode: "12942" },
    { lng: -76.10465, lat: 43.714545, postalCode: "13661" },
    { lng: -75.728357, lat: 44.014698, postalCode: "13643" },
    { lng: -77.773919, lat: 42.729839, postalCode: "14556" },
    { lng: -74.354699, lat: 41.387822, postalCode: "12555" },
    { lng: -73.64825, lat: 43.312104, postalCode: "12801" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12223" },
    { lng: -73.63792, lat: 40.663935, postalCode: "11570" },
    { lng: -73.31796, lat: 40.865998, postalCode: "11731" },
    { lng: -72.70421, lat: 40.803923, postalCode: "11960" },
    { lng: -73.99256, lat: 44.421547, postalCode: "12913" },
    { lng: -73.932509, lat: 43.675377, postalCode: "12862" },
    { lng: -73.986467, lat: 40.748998, postalCode: "10118" },
    { lng: -73.92154, lat: 41.363063, postalCode: "10524" },
    { lng: -76.750888, lat: 42.147285, postalCode: "14902" },
    { lng: -76.02313, lat: 42.335102, postalCode: "13797" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12241" },
    { lng: -74.91413, lat: 42.703091, postalCode: "13326" },
    { lng: -73.3489, lat: 42.681107, postalCode: "12022" },
    { lng: -78.784908, lat: 42.75495, postalCode: "14273" },
    { lng: -75.757217, lat: 42.595426, postalCode: "13578" },
    { lng: -78.231338, lat: 43.381027, postalCode: "14452" },
    { lng: -76.125914, lat: 43.778046, postalCode: "13611" },
    { lng: -73.73514, lat: 40.662538, postalCode: "11422" },
    { lng: -72.36729, lat: 41.103838, postalCode: "11944" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14240" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14650" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12236" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10047" },
    { lng: -73.90507, lat: 42.779026, postalCode: "12304" },
    { lng: -73.44151, lat: 40.725968, postalCode: "11735" },
    { lng: -74.11785, lat: 40.569394, postalCode: "10306" },
    { lng: -72.74646, lat: 40.933429, postalCode: "11933" },
    { lng: -73.82363, lat: 40.958689, postalCode: "10707" },
    { lng: -78.91498, lat: 42.593148, postalCode: "14111" },
    { lng: -74.059104, lat: 41.464073, postalCode: "12584" },
    { lng: -77.49846, lat: 42.484672, postalCode: "14826" },
    { lng: -77.519784, lat: 42.737181, postalCode: "14771" },
    { lng: -78.75813, lat: 42.929891, postalCode: "14225" },
    { lng: -77.60375, lat: 43.156963, postalCode: "14604" },
    { lng: -72.42041, lat: 41.059209, postalCode: "11971" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10249" },
    { lng: -79.47523, lat: 42.372864, postalCode: "14769" },
    { lng: -76.19217, lat: 42.591109, postalCode: "13045" },
    { lng: -73.98345, lat: 43.569961, postalCode: "12843" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11251" },
    { lng: -73.98473, lat: 40.618944, postalCode: "11204" },
    { lng: -78.059374, lat: 42.073739, postalCode: "14707" },
    { lng: -79.323502, lat: 42.352416, postalCode: "14752" },
    { lng: -76.0552, lat: 43.309645, postalCode: "13167" },
    { lng: -73.702443, lat: 42.513906, postalCode: "12162" },
    { lng: -73.86602, lat: 41.012729, postalCode: "10522" },
    { lng: -76.14501, lat: 43.010916, postalCode: "13205" },
    { lng: -77.63794, lat: 43.182163, postalCode: "14613" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12256" },
    { lng: -76.31339, lat: 43.235916, postalCode: "13135" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11708" },
    { lng: -73.59825, lat: 41.570328, postalCode: "12564" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12230" },
    { lng: -76.2177, lat: 42.996804, postalCode: "13215" },
    { lng: -78.14489, lat: 42.076186, postalCode: "14715" },
    { lng: -73.97401, lat: 40.756099, postalCode: "10171" },
    { lng: -73.99629, lat: 40.632449, postalCode: "11219" },
    { lng: -79.74933, lat: 42.130247, postalCode: "14736" },
    { lng: -78.168055, lat: 42.114385, postalCode: "14774" },
    { lng: -75.64797, lat: 44.585561, postalCode: "13664" },
    { lng: -73.89922, lat: 41.854465, postalCode: "12580" },
    { lng: -74.060826, lat: 41.160945, postalCode: "10995" },
    { lng: -76.39345, lat: 42.591961, postalCode: "13073" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10101" },
    { lng: -74.24346, lat: 41.451074, postalCode: "10916" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11044" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11555" },
    { lng: -73.97957, lat: 41.905004, postalCode: "12466" },
    { lng: -79.16539, lat: 42.535276, postalCode: "14136" },
    { lng: -73.94559, lat: 40.726302, postalCode: "11222" },
    { lng: -74.146836, lat: 40.564393, postalCode: "10313" },
    { lng: -77.73372, lat: 42.899233, postalCode: "14414" },
    { lng: -75.305811, lat: 43.114669, postalCode: "13942" },
    { lng: -74.255646, lat: 41.464867, postalCode: "10914" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11053" },
    { lng: -77.68103, lat: 42.706781, postalCode: "14435" },
    { lng: -76.73437, lat: 42.177039, postalCode: "14816" },
    { lng: -75.90529, lat: 43.421597, postalCode: "13493" },
    { lng: -73.98942, lat: 42.793326, postalCode: "12306" },
    { lng: -78.8118, lat: 42.934757, postalCode: "14215" },
    { lng: -76.036582, lat: 42.402816, postalCode: "13794" },
    { lng: -74.09432, lat: 40.631775, postalCode: "10301" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12214" },
    { lng: -72.89533, lat: 40.94993, postalCode: "11786" },
    { lng: -75.712551, lat: 43.18852, postalCode: "13162" },
    { lng: -74.56972, lat: 43.40276, postalCode: "12139" },
    { lng: -73.46912, lat: 40.813801, postalCode: "11797" },
    { lng: -74.78184, lat: 42.152105, postalCode: "13731" },
    { lng: -74.60276, lat: 41.475235, postalCode: "12729" },
    { lng: -75.45531, lat: 43.217069, postalCode: "13440" },
    { lng: -74.44937, lat: 42.529042, postalCode: "12194" },
    { lng: -75.99068, lat: 44.318866, postalCode: "13640" },
    { lng: -79.03925, lat: 43.096555, postalCode: "14301" },
    { lng: -73.8377, lat: 41.390356, postalCode: "10579" },
    { lng: -73.50464, lat: 43.414017, postalCode: "12827" },
    { lng: -73.71182, lat: 42.220962, postalCode: "12513" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11595" },
    { lng: -77.71275, lat: 42.664904, postalCode: "14545" },
    { lng: -73.32549, lat: 40.687649, postalCode: "11702" },
    { lng: -76.54077, lat: 42.564191, postalCode: "14882" },
    { lng: -73.90856, lat: 40.829676, postalCode: "10456" },
    { lng: -77.93937, lat: 43.214261, postalCode: "14420" },
    { lng: -73.04036, lat: 40.810606, postalCode: "11742" },
    { lng: -79.48396, lat: 42.226149, postalCode: "14757" },
    { lng: -77.883511, lat: 42.875685, postalCode: "14592" },
    { lng: -74.67315, lat: 42.546034, postalCode: "12036" },
    { lng: -73.87248, lat: 40.672666, postalCode: "11208" },
    { lng: -76.86246, lat: 42.521501, postalCode: "14841" },
    { lng: -73.58409, lat: 41.212954, postalCode: "10576" },
    { lng: -74.53721, lat: 44.63091, postalCode: "12980" },
    { lng: -74.4065, lat: 42.142121, postalCode: "12480" },
    { lng: -76.05256, lat: 42.113974, postalCode: "13760" },
    { lng: -72.45341, lat: 41.03687, postalCode: "11958" },
    { lng: -73.62987, lat: 43.495335, postalCode: "12844" },
    { lng: -74.87693, lat: 44.937212, postalCode: "13662" },
    { lng: -75.35861, lat: 43.338878, postalCode: "13486" },
    { lng: -76.04936, lat: 43.807654, postalCode: "13605" },
    { lng: -73.67298, lat: 40.675249, postalCode: "11565" },
    { lng: -74.09083, lat: 42.767853, postalCode: "12056" },
    { lng: -73.58549, lat: 43.253125, postalCode: "12828" },
    { lng: -74.24159, lat: 42.442592, postalCode: "12469" },
    { lng: -76.84258, lat: 42.352725, postalCode: "14865" },
    { lng: -79.08905, lat: 42.154315, postalCode: "14747" },
    { lng: -74.74379, lat: 43.11545, postalCode: "13329" },
    { lng: -74.32764, lat: 41.61134, postalCode: "12566" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11099" },
    { lng: -74.288565, lat: 41.323172, postalCode: "10981" },
    { lng: -73.86072, lat: 40.900629, postalCode: "10470" },
    { lng: -74.09475, lat: 41.751566, postalCode: "12561" },
    { lng: -75.29944, lat: 44.494708, postalCode: "13630" },
    { lng: -76.17822, lat: 42.222777, postalCode: "13811" },
    { lng: -74.354699, lat: 41.387822, postalCode: "10943" },
    { lng: -74.40784, lat: 44.812315, postalCode: "12966" },
    { lng: -73.978877, lat: 40.75557, postalCode: "10176" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11244" },
    { lng: -74.827587, lat: 41.900542, postalCode: "12756" },
    { lng: -77.5919, lat: 42.964593, postalCode: "14472" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12257" },
    { lng: -75.82012, lat: 42.639288, postalCode: "13124" },
    { lng: -73.815204, lat: 44.145678, postalCode: "12872" },
    { lng: -73.33114, lat: 40.905647, postalCode: "11768" },
    { lng: -74.24387, lat: 40.508274, postalCode: "10307" },
    { lng: -73.73647, lat: 42.479967, postalCode: "12156" },
    { lng: -73.79282, lat: 44.329473, postalCode: "12987" },
    { lng: -76.38271, lat: 42.484414, postalCode: "13062" },
    { lng: -73.26614, lat: 40.720746, postalCode: "11718" },
    { lng: -73.8635, lat: 41.039005, postalCode: "10533" },
    { lng: -76.60338, lat: 42.354851, postalCode: "14867" },
    { lng: -73.474809, lat: 42.479384, postalCode: "12195" },
    { lng: -75.65761, lat: 42.650584, postalCode: "13832" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10072" },
    { lng: -73.50412, lat: 42.41956, postalCode: "12060" },
    { lng: -76.82964, lat: 42.699637, postalCode: "14521" },
    { lng: -76.79217, lat: 42.091924, postalCode: "14901" },
    { lng: -75.90226, lat: 42.077258, postalCode: "13903" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10125" },
    { lng: -73.96215, lat: 41.047996, postalCode: "10962" },
    { lng: -74.00532, lat: 40.734718, postalCode: "10014" },
    { lng: -76.48643, lat: 42.234865, postalCode: "14883" },
    { lng: -73.47948, lat: 40.779911, postalCode: "11803" },
    { lng: -74.03995, lat: 41.87916, postalCode: "12411" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14265" },
    { lng: -75.37897, lat: 44.414762, postalCode: "13681" },
    { lng: -73.84721, lat: 40.57649, postalCode: "11694" },
    { lng: -76.38844, lat: 42.298203, postalCode: "13864" },
    { lng: -73.97955, lat: 40.754302, postalCode: "10173" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10545" },
    { lng: -76.70818, lat: 42.912846, postalCode: "13034" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10149" },
    { lng: -75.103186, lat: 43.606444, postalCode: "13388" },
    { lng: -78.8413, lat: 42.734051, postalCode: "14075" },
    { lng: -77.65301, lat: 42.809908, postalCode: "14487" },
    { lng: -73.97447, lat: 40.755494, postalCode: "10172" },
    { lng: -77.19305, lat: 42.330602, postalCode: "14879" },
    { lng: -74.14257, lat: 42.981745, postalCode: "12086" },
    { lng: -74.97261, lat: 42.606209, postalCode: "13807" },
    { lng: -74.1893, lat: 42.073259, postalCode: "12448" },
    { lng: -74.59577, lat: 42.414519, postalCode: "12167" },
    { lng: -73.58587, lat: 42.469991, postalCode: "12115" },
    { lng: -78.231338, lat: 43.381027, postalCode: "14508" },
    { lng: -75.072951, lat: 44.496564, postalCode: "13699" },
    { lng: -76.400376, lat: 42.039512, postalCode: "13840" },
    { lng: -73.84152, lat: 41.011324, postalCode: "10502" },
    { lng: -73.743714, lat: 41.759905, postalCode: "12602" },
    { lng: -74.40063, lat: 42.297725, postalCode: "12468" },
    { lng: -77.87334, lat: 43.079181, postalCode: "14428" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12238" },
    { lng: -74.07602, lat: 41.846938, postalCode: "12472" },
    { lng: -73.99337, lat: 40.755101, postalCode: "10018" },
    { lng: -73.56201, lat: 42.112895, postalCode: "12516" },
    { lng: -74.263312, lat: 42.945701, postalCode: "12069" },
    { lng: -74.65618, lat: 41.780299, postalCode: "12759" },
    { lng: -75.528221, lat: 42.603574, postalCode: "13814" },
    { lng: -73.31852, lat: 43.11595, postalCode: "12873" },
    { lng: -78.72101, lat: 42.382899, postalCode: "14729" },
    { lng: -77.2434, lat: 42.97342, postalCode: "14548" },
    { lng: -74.423606, lat: 42.910206, postalCode: "12016" },
    { lng: -76.149045, lat: 42.718259, postalCode: "13149" },
    { lng: -73.35829, lat: 40.719249, postalCode: "11704" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12235" },
    { lng: -79.43873, lat: 42.390802, postalCode: "14716" },
    { lng: -76.56767, lat: 43.317683, postalCode: "13074" },
    { lng: -73.76493, lat: 41.289939, postalCode: "10501" },
    { lng: -74.66315, lat: 41.374734, postalCode: "12771" },
    { lng: -76.09505, lat: 43.171707, postalCode: "13039" },
    { lng: -73.97759, lat: 40.74618, postalCode: "10016" },
    { lng: -74.15932, lat: 42.240343, postalCode: "12424" },
    { lng: -73.93907, lat: 40.745341, postalCode: "11101" },
    { lng: -74.15692, lat: 42.610306, postalCode: "12023" },
    { lng: -75.32389, lat: 44.593588, postalCode: "13680" },
    { lng: -73.90891, lat: 40.560167, postalCode: "11697" },
    { lng: -78.72634, lat: 43.264406, postalCode: "14108" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14205" },
    { lng: -77.47054, lat: 43.010908, postalCode: "14453" },
    { lng: -73.52307, lat: 44.563323, postalCode: "12972" },
    { lng: -72.70113, lat: 40.8204, postalCode: "11972" },
    { lng: -73.97685, lat: 42.085634, postalCode: "12477" },
    { lng: -75.29566, lat: 43.102469, postalCode: "13417" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10094" },
    { lng: -73.66663, lat: 41.997807, postalCode: "12567" },
    { lng: -76.105201, lat: 42.677155, postalCode: "13056" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10259" },
    { lng: -74.60896, lat: 41.732505, postalCode: "12733" },
    { lng: -77.65194, lat: 43.204695, postalCode: "14615" },
    { lng: -76.467233, lat: 42.445074, postalCode: "14852" },
    { lng: -78.95167, lat: 42.160787, postalCode: "14772" },
    { lng: -76.75716, lat: 43.09986, postalCode: "13146" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11853" },
    { lng: -74.550346, lat: 41.314931, postalCode: "10988" },
    { lng: -73.51881, lat: 43.750773, postalCode: "12836" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10572" },
    { lng: -73.613803, lat: 41.308218, postalCode: "10957" },
    { lng: -76.07493, lat: 42.973991, postalCode: "13078" },
    { lng: -73.55727, lat: 41.852959, postalCode: "12501" },
    { lng: -73.97495, lat: 40.754862, postalCode: "10167" },
    { lng: -73.52323, lat: 40.763355, postalCode: "11801" },
    { lng: -77.6012, lat: 43.167563, postalCode: "14605" },
    { lng: -73.8503, lat: 40.683301, postalCode: "11416" },
    { lng: -76.2382, lat: 43.468092, postalCode: "13114" },
    { lng: -73.81143, lat: 40.953849, postalCode: "10709" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11249" },
    { lng: -76.482365, lat: 43.397671, postalCode: "13115" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12245" },
    { lng: -74.27687, lat: 41.961316, postalCode: "12494" },
    { lng: -75.660462, lat: 42.854631, postalCode: "13484" },
    { lng: -73.59306, lat: 40.703502, postalCode: "11553" },
    { lng: -77.24024, lat: 42.75606, postalCode: "14544" },
    { lng: -75.996773, lat: 43.562757, postalCode: "13426" },
    { lng: -75.432486, lat: 43.136033, postalCode: "13504" },
    { lng: -74.07207, lat: 41.831082, postalCode: "12486" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11802" },
    { lng: -76.292723, lat: 42.554215, postalCode: "13102" },
    { lng: -74.75013, lat: 41.734913, postalCode: "12734" },
    { lng: -75.10019, lat: 43.046471, postalCode: "13340" },
    { lng: -72.92884, lat: 40.954093, postalCode: "11778" },
    { lng: -73.63808, lat: 40.747188, postalCode: "11501" },
    { lng: -76.765591, lat: 43.134425, postalCode: "13154" },
    { lng: -76.22912, lat: 43.262012, postalCode: "13132" },
    { lng: -73.8604, lat: 43.006309, postalCode: "12020" },
    { lng: -74.94475, lat: 41.94836, postalCode: "12776" },
    { lng: -74.060826, lat: 41.160945, postalCode: "10982" },
    { lng: -75.47796, lat: 43.239274, postalCode: "13449" },
    { lng: -76.24023, lat: 44.064999, postalCode: "13693" },
    { lng: -75.38008, lat: 42.533274, postalCode: "13843" },
    { lng: -73.79502, lat: 40.726137, postalCode: "11366" },
    { lng: -73.90816, lat: 40.774686, postalCode: "11105" },
    { lng: -75.71159, lat: 44.160785, postalCode: "13673" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10268" },
    { lng: -75.77739, lat: 42.504765, postalCode: "13801" },
    { lng: -74.660413, lat: 44.746861, postalCode: "12949" },
    { lng: -78.475474, lat: 42.524691, postalCode: "14173" },
    { lng: -73.87925, lat: 40.716028, postalCode: "11379" },
    { lng: -73.85862, lat: 40.842173, postalCode: "10462" },
    { lng: -76.99231, lat: 43.076715, postalCode: "14489" },
    { lng: -78.25412, lat: 43.34653, postalCode: "14571" },
    { lng: -77.87663, lat: 42.692608, postalCode: "14510" },
    { lng: -73.95118, lat: 41.025897, postalCode: "10983" },
    { lng: -74.97593, lat: 41.694153, postalCode: "12726" },
    { lng: -74.957099, lat: 43.460951, postalCode: "13472" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12240" },
    { lng: -76.02837, lat: 42.075874, postalCode: "13850" },
    { lng: -75.480342, lat: 43.818705, postalCode: "13631" },
    { lng: -79.41091, lat: 42.342529, postalCode: "14703" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12239" },
    { lng: -73.75802, lat: 43.835376, postalCode: "12870" },
    { lng: -75.17201, lat: 44.866754, postalCode: "13694" },
    { lng: -77.18019, lat: 42.453547, postalCode: "14840" },
    { lng: -73.68976, lat: 42.80535, postalCode: "12188" },
    { lng: -74.061301, lat: 41.507368, postalCode: "12557" },
    { lng: -73.3526, lat: 43.200351, postalCode: "12865" },
    { lng: -78.6337, lat: 42.220602, postalCode: "14741" },
    { lng: -77.97556, lat: 42.170951, postalCode: "14880" },
    { lng: -74.05526, lat: 43.068203, postalCode: "12074" },
    { lng: -77.45364, lat: 43.220991, postalCode: "14580" },
    { lng: -73.96375, lat: 40.810906, postalCode: "10115" },
    { lng: -73.96427, lat: 40.622994, postalCode: "11230" },
    { lng: -75.34281, lat: 43.158258, postalCode: "13424" },
    { lng: -78.95914, lat: 43.011823, postalCode: "14072" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11695" },
    { lng: -74.87957, lat: 41.807694, postalCode: "12791" },
    { lng: -73.8442, lat: 42.002691, postalCode: "12571" },
    { lng: -76.13214, lat: 43.653475, postalCode: "13145" },
    { lng: -75.49047, lat: 43.570028, postalCode: "13325" },
    { lng: -78.40069, lat: 42.582693, postalCode: "14009" },
    { lng: -74.01157, lat: 41.231408, postalCode: "10980" },
    { lng: -74.296985, lat: 42.95002, postalCode: "12177" },
    { lng: -75.767772, lat: 43.951666, postalCode: "13688" },
    { lng: -74.26631, lat: 43.761451, postalCode: "12842" },
    { lng: -73.7576, lat: 41.319026, postalCode: "10527" },
    { lng: -73.93002, lat: 43.067531, postalCode: "12863" },
    { lng: -75.230195, lat: 44.465307, postalCode: "13562" },
    { lng: -74.317958, lat: 44.552725, postalCode: "12915" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12111" },
    { lng: -75.12063, lat: 42.488953, postalCode: "13861" },
    { lng: -74.95034, lat: 41.858278, postalCode: "12724" },
    { lng: -74.03747, lat: 43.841369, postalCode: "12851" },
    { lng: -73.582317, lat: 44.847709, postalCode: "12929" },
    { lng: -75.47889, lat: 42.964774, postalCode: "13425" },
    { lng: -73.98907, lat: 40.695286, postalCode: "11201" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11026" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10702" },
    { lng: -73.87151, lat: 42.646978, postalCode: "12159" },
    { lng: -75.84853, lat: 43.252084, postalCode: "13042" },
    { lng: -77.628857, lat: 42.535363, postalCode: "14529" },
    { lng: -73.757399, lat: 42.142068, postalCode: "12541" },
    { lng: -74.35392, lat: 41.757766, postalCode: "12489" },
    { lng: -74.58836, lat: 41.614218, postalCode: "12775" },
    { lng: -73.75846, lat: 42.22948, postalCode: "12534" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11536" },
    { lng: -74.42061, lat: 41.727588, postalCode: "12428" },
    { lng: -72.30514, lat: 40.939075, postalCode: "11932" },
    { lng: -75.616793, lat: 42.954541, postalCode: "13364" },
    { lng: -75.29542, lat: 43.220075, postalCode: "13469" },
    { lng: -73.80208, lat: 41.331173, postalCode: "10535" },
    { lng: -74.99219, lat: 42.18942, postalCode: "13782" },
    { lng: -76.05009, lat: 42.255624, postalCode: "13802" },
    { lng: -73.46289, lat: 40.68085, postalCode: "11758" },
    { lng: -73.8609, lat: 40.724945, postalCode: "11374" },
    { lng: -76.10295, lat: 43.936173, postalCode: "13685" },
    { lng: -77.54848, lat: 42.901162, postalCode: "14585" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11593" },
    { lng: -74.179485, lat: 40.605245, postalCode: "10311" },
    { lng: -73.46734, lat: 44.530527, postalCode: "12911" },
    { lng: -75.98315, lat: 44.005613, postalCode: "13615" },
    { lng: -78.65519, lat: 42.904258, postalCode: "14086" },
    { lng: -74.02471, lat: 42.361838, postalCode: "12431" },
    { lng: -77.29466, lat: 42.85297, postalCode: "14424" },
    { lng: -73.72915, lat: 40.589538, postalCode: "11509" },
    { lng: -73.97268, lat: 40.763742, postalCode: "10153" },
    { lng: -78.94734, lat: 42.174736, postalCode: "14730" },
    { lng: -74.11963, lat: 41.433856, postalCode: "12577" },
    { lng: -77.43392, lat: 42.419988, postalCode: "14809" },
    { lng: -75.76265, lat: 42.662648, postalCode: "13155" },
    { lng: -74.51434, lat: 44.830736, postalCode: "12916" },
    { lng: -74.961146, lat: 41.729581, postalCode: "12750" },
    { lng: -77.88795, lat: 42.846509, postalCode: "14533" },
    { lng: -73.81773, lat: 42.413742, postalCode: "12192" },
    { lng: -73.75158, lat: 41.01002, postalCode: "10605" },
    { lng: -73.83709, lat: 40.907607, postalCode: "10550" },
    { lng: -73.78147, lat: 41.803384, postalCode: "12578" },
    { lng: -74.912493, lat: 44.140791, postalCode: "13695" },
    { lng: -76.83926, lat: 42.271938, postalCode: "14864" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14639" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14010" },
    { lng: -75.09464, lat: 42.609579, postalCode: "13810" },
    { lng: -73.36221, lat: 40.862565, postalCode: "11740" },
    { lng: -78.23754, lat: 42.385744, postalCode: "14777" },
    { lng: -73.978507, lat: 40.751378, postalCode: "10178" },
    { lng: -74.69748, lat: 41.653071, postalCode: "12701" },
    { lng: -73.64006, lat: 40.785642, postalCode: "11577" },
    { lng: -72.98375, lat: 40.820786, postalCode: "11763" },
    { lng: -77.94686, lat: 42.094855, postalCode: "14895" },
    { lng: -73.96732, lat: 40.759015, postalCode: "10022" },
    { lng: -73.926165, lat: 41.234256, postalCode: "10521" },
    { lng: -74.23471, lat: 41.811322, postalCode: "12404" },
    { lng: -74.125718, lat: 41.386195, postalCode: "10910" },
    { lng: -73.84086, lat: 42.614979, postalCode: "12054" },
    { lng: -72.34189, lat: 41.128923, postalCode: "11939" },
    { lng: -74.37431, lat: 43.515611, postalCode: "12164" },
    { lng: -74.438129, lat: 42.592287, postalCode: "12073" },
    { lng: -73.8729, lat: 40.992084, postalCode: "10706" },
    { lng: -74.06053, lat: 44.519178, postalCode: "12989" },
    { lng: -74.12354, lat: 41.322344, postalCode: "10917" },
    { lng: -74.01009, lat: 40.703042, postalCode: "10041" },
    { lng: -75.41944, lat: 43.106801, postalCode: "13490" },
    { lng: -73.69253, lat: 40.639534, postalCode: "11557" },
    { lng: -73.97824, lat: 41.816628, postalCode: "12429" },
    { lng: -77.51114, lat: 43.143663, postalCode: "14625" },
    { lng: -74.4557, lat: 42.114646, postalCode: "12410" },
    { lng: -73.9758, lat: 40.749435, postalCode: "10158" },
    { lng: -73.97229, lat: 40.598142, postalCode: "11223" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14276" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10108" },
    { lng: -75.14144, lat: 42.728271, postalCode: "13315" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13217" },
    { lng: -73.80053, lat: 42.654978, postalCode: "12208" },
    { lng: -73.91693, lat: 43.638462, postalCode: "12886" },
    { lng: -73.988421, lat: 40.748181, postalCode: "10098" },
    { lng: -77.735079, lat: 43.257819, postalCode: "14515" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11240" },
    { lng: -78.83741, lat: 42.941407, postalCode: "14214" },
    { lng: -73.80001, lat: 44.83218, postalCode: "12935" },
    { lng: -73.78934, lat: 41.296874, postalCode: "10598" },
    { lng: -74.00058, lat: 40.750308, postalCode: "10199" },
    { lng: -79.03736, lat: 43.086355, postalCode: "14303" },
    { lng: -78.68944, lat: 42.516248, postalCode: "14141" },
    { lng: -74.76529, lat: 42.680906, postalCode: "13488" },
    { lng: -73.86828, lat: 42.842133, postalCode: "12148" },
    { lng: -73.99611, lat: 40.598994, postalCode: "11214" },
    { lng: -73.15868, lat: 40.884394, postalCode: "11780" },
    { lng: -74.82634, lat: 41.736523, postalCode: "12783" },
    { lng: -73.87804, lat: 40.736076, postalCode: "11373" },
    { lng: -73.40668, lat: 44.373007, postalCode: "12996" },
    { lng: -74.30134, lat: 42.668825, postalCode: "12157" },
    { lng: -77.61519, lat: 43.155863, postalCode: "14614" },
    { lng: -73.85838, lat: 40.747106, postalCode: "11368" },
    { lng: -73.346409, lat: 42.926121, postalCode: "12133" },
    { lng: -73.91858, lat: 40.676938, postalCode: "11233" },
    { lng: -74.22711, lat: 42.302596, postalCode: "12439" },
    { lng: -74.24657, lat: 42.326918, postalCode: "12496" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11815" },
    { lng: -73.71327, lat: 42.534631, postalCode: "12033" },
    { lng: -78.7297, lat: 42.168508, postalCode: "14779" },
    { lng: -75.5688, lat: 42.88821, postalCode: "13310" },
    { lng: -77.56272, lat: 43.175113, postalCode: "14609" },
    { lng: -75.480342, lat: 43.818705, postalCode: "13404" },
    { lng: -74.122951, lat: 41.358899, postalCode: "10730" },
    { lng: -75.77839, lat: 44.003136, postalCode: "13612" },
    { lng: -77.5078, lat: 42.135945, postalCode: "14855" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10558" },
    { lng: -75.06915, lat: 44.850439, postalCode: "13621" },
    { lng: -76.12717, lat: 42.802031, postalCode: "13159" },
    { lng: -73.62609, lat: 40.864757, postalCode: "11542" },
    { lng: -78.83139, lat: 42.866708, postalCode: "14210" },
    { lng: -73.850143, lat: 40.686903, postalCode: "11431" },
    { lng: -74.28753, lat: 44.454639, postalCode: "12970" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10211" },
    { lng: -76.92063, lat: 42.515233, postalCode: "14857" },
    { lng: -73.52292, lat: 40.868882, postalCode: "11771" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11819" },
    { lng: -73.91091, lat: 40.762968, postalCode: "11103" },
    { lng: -73.97231, lat: 40.752159, postalCode: "10017" },
    { lng: -73.63335, lat: 42.559697, postalCode: "12063" },
    { lng: -74.95289, lat: 44.651265, postalCode: "13676" },
    { lng: -73.78797, lat: 40.946805, postalCode: "10804" },
    { lng: -77.20491, lat: 42.235058, postalCode: "14821" },
    { lng: -74.01389, lat: 41.442589, postalCode: "12520" },
    { lng: -77.011839, lat: 42.931873, postalCode: "14518" },
    { lng: -74.35064, lat: 42.722477, postalCode: "12035" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11749" },
    { lng: -74.56669, lat: 43.97643, postalCode: "12847" },
    { lng: -75.46279, lat: 44.055436, postalCode: "13665" },
    { lng: -78.59804, lat: 42.770859, postalCode: "14052" },
    { lng: -75.184913, lat: 42.704333, postalCode: "13482" },
    { lng: -77.79265, lat: 42.157534, postalCode: "14806" },
    { lng: -79.59738, lat: 42.160982, postalCode: "14781" },
    { lng: -76.57176, lat: 43.182054, postalCode: "13033" },
    { lng: -73.82973, lat: 41.330178, postalCode: "10588" },
    { lng: -73.70766, lat: 43.41928, postalCode: "12845" },
    { lng: -73.04966, lat: 40.911082, postalCode: "11776" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14602" },
    { lng: -78.53931, lat: 42.716919, postalCode: "14139" },
    { lng: -78.19279, lat: 42.996507, postalCode: "14020" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10184" },
    { lng: -78.585646, lat: 42.767333, postalCode: "14169" },
    { lng: -73.371611, lat: 42.646742, postalCode: "12040" },
    { lng: -73.82541, lat: 40.878522, postalCode: "10475" },
    { lng: -77.70626, lat: 43.214613, postalCode: "14626" },
    { lng: -73.3555, lat: 42.888816, postalCode: "12090" },
    { lng: -76.509567, lat: 43.163364, postalCode: "13022" },
    { lng: -75.192865, lat: 44.533125, postalCode: "13666" },
    { lng: -77.18498, lat: 43.24816, postalCode: "14589" },
    { lng: -73.18073, lat: 40.72921, postalCode: "11730" },
    { lng: -75.54012, lat: 42.820826, postalCode: "13346" },
    { lng: -73.70352, lat: 41.129908, postalCode: "10504" },
    { lng: -73.963279, lat: 44.881962, postalCode: "12933" },
    { lng: -73.77808, lat: 41.024107, postalCode: "10606" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10046" },
    { lng: -73.97457, lat: 43.683067, postalCode: "12853" },
    { lng: -75.93103, lat: 44.328698, postalCode: "13607" },
    { lng: -79.03785, lat: 42.251075, postalCode: "14726" },
    { lng: -74.77476, lat: 43.744906, postalCode: "13360" },
    { lng: -75.50293, lat: 44.469527, postalCode: "13633" },
    { lng: -73.61306, lat: 41.32797, postalCode: "10560" },
    { lng: -73.98676, lat: 41.271543, postalCode: "10986" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10274" },
    { lng: -78.52955, lat: 43.337798, postalCode: "14012" },
    { lng: -74.99121, lat: 42.852372, postalCode: "13439" },
    { lng: -73.72577, lat: 40.787068, postalCode: "11021" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10292" },
    { lng: -75.881404, lat: 43.436919, postalCode: "13453" },
    { lng: -73.79363, lat: 42.932326, postalCode: "12151" },
    { lng: -73.4075, lat: 42.474028, postalCode: "12125" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14638" },
    { lng: -73.6845, lat: 41.444833, postalCode: "10512" },
    { lng: -78.13481, lat: 42.903676, postalCode: "14054" },
    { lng: -78.20368, lat: 42.020028, postalCode: "14754" },
    { lng: -77.687643, lat: 42.855409, postalCode: "14558" },
    { lng: -73.46132, lat: 44.041901, postalCode: "12974" },
    { lng: -73.83099, lat: 40.939049, postalCode: "10708" },
    { lng: -77.773919, lat: 42.729839, postalCode: "14488" },
    { lng: -73.65515, lat: 43.582318, postalCode: "12814" },
    { lng: -76.096357, lat: 43.339851, postalCode: "13103" },
    { lng: -76.87027, lat: 42.911377, postalCode: "13165" },
    { lng: -76.315044, lat: 43.483379, postalCode: "13121" },
    { lng: -73.82911, lat: 43.329851, postalCode: "12846" },
    { lng: -74.53847, lat: 41.475197, postalCode: "10963" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14683" },
    { lng: -75.33559, lat: 43.484783, postalCode: "13309" },
    { lng: -72.637078, lat: 40.922326, postalCode: "544" },
    { lng: -73.70346, lat: 40.673, postalCode: "11580" },
    { lng: -75.192865, lat: 44.533125, postalCode: "13683" },
    { lng: -78.68548, lat: 42.941025, postalCode: "14026" },
    { lng: -77.68215, lat: 43.040271, postalCode: "14586" },
    { lng: -72.34407, lat: 40.919693, postalCode: "11976" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14649" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13218" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11535" },
    { lng: -73.795361, lat: 40.948274, postalCode: "10802" },
    { lng: -73.75943, lat: 42.377829, postalCode: "12173" },
    { lng: -73.99019, lat: 40.759511, postalCode: "10036" },
    { lng: -75.31757, lat: 43.580184, postalCode: "13433" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12229" },
    { lng: -73.81978, lat: 40.596959, postalCode: "11693" },
    { lng: -73.69888, lat: 41.89187, postalCode: "12581" },
    { lng: -75.872839, lat: 42.173775, postalCode: "13745" },
    { lng: -73.99172, lat: 40.576589, postalCode: "11224" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10150" },
    { lng: -73.776701, lat: 40.79278, postalCode: "11359" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12249" },
    { lng: -73.455519, lat: 43.246117, postalCode: "12805" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10080" },
    { lng: -73.42757, lat: 42.399159, postalCode: "12029" },
    { lng: -75.88783, lat: 42.142575, postalCode: "13901" },
    { lng: -75.082041, lat: 44.673438, postalCode: "13677" },
    { lng: -73.50177, lat: 43.095447, postalCode: "12834" },
    { lng: -75.75964, lat: 42.336771, postalCode: "13778" },
    { lng: -76.23488, lat: 43.081324, postalCode: "13209" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11386" },
    { lng: -73.524554, lat: 43.100123, postalCode: "12848" },
    { lng: -75.69227, lat: 44.444242, postalCode: "13646" },
    { lng: -78.830681, lat: 43.32684, postalCode: "14109" },
    { lng: -75.8876, lat: 42.105375, postalCode: "13902" },
    { lng: -74.354699, lat: 41.387822, postalCode: "10997" },
    { lng: -74.317958, lat: 44.552725, postalCode: "12976" },
    { lng: -74.9846, lat: 43.038149, postalCode: "13350" },
    { lng: -73.58384, lat: 44.061849, postalCode: "12961" },
    { lng: -73.992171, lat: 40.751757, postalCode: "10122" },
    { lng: -78.726661, lat: 43.330443, postalCode: "14126" },
    { lng: -78.14128, lat: 43.341512, postalCode: "14477" },
    { lng: -73.56209, lat: 41.646584, postalCode: "12594" },
    { lng: -78.06371, lat: 43.079217, postalCode: "14422" },
    { lng: -73.77785, lat: 41.083848, postalCode: "10595" },
    { lng: -73.318113, lat: 42.866666, postalCode: "12089" },
    { lng: -76.204, lat: 42.306877, postalCode: "13736" },
    { lng: -72.53731, lat: 40.994649, postalCode: "11952" },
    { lng: -74.2619, lat: 42.001351, postalCode: "12412" },
    { lng: -73.9788, lat: 41.376109, postalCode: "10996" },
    { lng: -75.11339, lat: 42.018923, postalCode: "13756" },
    { lng: -76.156696, lat: 43.050966, postalCode: "13252" },
    { lng: -74.947, lat: 43.137624, postalCode: "13406" },
    { lng: -73.84735, lat: 40.868643, postalCode: "10469" },
    { lng: -73.95427, lat: 40.71209, postalCode: "11211" },
    { lng: -73.65004, lat: 40.769942, postalCode: "11507" },
    { lng: -76.94713, lat: 42.595985, postalCode: "14842" },
    { lng: -76.5563, lat: 43.056292, postalCode: "13166" },
    { lng: -79.03769, lat: 43.115355, postalCode: "14305" },
    { lng: -73.70726, lat: 42.388394, postalCode: "12106" },
    { lng: -74.0178, lat: 40.707467, postalCode: "10280" },
    { lng: -76.381946, lat: 42.065842, postalCode: "13845" },
    { lng: -75.10493, lat: 41.901849, postalCode: "12760" },
    { lng: -74.35837, lat: 41.259896, postalCode: "10990" },
    { lng: -73.63643, lat: 43.288818, postalCode: "12803" },
    { lng: -73.91928, lat: 41.288258, postalCode: "10566" },
    { lng: -75.16086, lat: 42.333285, postalCode: "13775" },
    { lng: -73.93359, lat: 40.802395, postalCode: "10035" },
    { lng: -75.35815, lat: 42.215297, postalCode: "13804" },
    { lng: -74.83536, lat: 42.587708, postalCode: "12155" },
    { lng: -74.06421, lat: 41.681096, postalCode: "12515" },
    { lng: -74.004495, lat: 40.658825, postalCode: "11286" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10257" },
    { lng: -73.50767, lat: 43.685475, postalCode: "12874" },
    { lng: -73.73028, lat: 44.3919, postalCode: "12941" },
    { lng: -79.32439, lat: 42.098657, postalCode: "14750" },
    { lng: -74.38473, lat: 43.014523, postalCode: "12095" },
    { lng: -73.69002, lat: 40.976579, postalCode: "10580" },
    { lng: -75.83037, lat: 42.938816, postalCode: "13035" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10602" },
    { lng: -78.63328, lat: 43.318768, postalCode: "14008" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11794" },
    { lng: -73.95553, lat: 40.645256, postalCode: "11226" },
    { lng: -74.70189, lat: 42.445257, postalCode: "13786" },
    { lng: -77.45849, lat: 43.145342, postalCode: "14526" },
    { lng: -74.72258, lat: 42.376254, postalCode: "13842" },
    { lng: -76.0167, lat: 43.647881, postalCode: "13083" },
    { lng: -73.67326, lat: 41.010376, postalCode: "10573" },
    { lng: -73.91328, lat: 40.662191, postalCode: "11212" },
    { lng: -74.72401, lat: 42.610314, postalCode: "12197" },
    { lng: -78.24818, lat: 42.579516, postalCode: "14024" },
    { lng: -76.95775, lat: 42.684588, postalCode: "14441" },
    { lng: -72.57996, lat: 40.943323, postalCode: "11947" },
    { lng: -75.89884, lat: 42.437964, postalCode: "13863" },
    { lng: -72.79719, lat: 40.797772, postalCode: "11934" },
    { lng: -73.989254, lat: 41.907604, postalCode: "12417" },
    { lng: -78.3931, lat: 43.338755, postalCode: "14098" },
    { lng: -76.071767, lat: 42.155346, postalCode: "13766" },
    { lng: -73.95509, lat: 40.662892, postalCode: "11225" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10116" },
    { lng: -76.156103, lat: 42.706981, postalCode: "13087" },
    { lng: -77.28515, lat: 43.134783, postalCode: "14568" },
    { lng: -74.96051, lat: 42.970099, postalCode: "13407" },
    { lng: -78.43114, lat: 42.335628, postalCode: "14737" },
    { lng: -74.555957, lat: 44.234289, postalCode: "12973" },
    { lng: -77.28857, lat: 42.104389, postalCode: "14801" },
    { lng: -79.006231, lat: 42.298328, postalCode: "14751" },
    { lng: -73.79209, lat: 40.738983, postalCode: "11365" },
    { lng: -78.27803, lat: 42.844962, postalCode: "14011" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14260" },
    { lng: -73.18326, lat: 40.754386, postalCode: "11752" },
    { lng: -76.19365, lat: 43.105917, postalCode: "13088" },
    { lng: -73.91922, lat: 41.701443, postalCode: "12601" },
    { lng: -79.41091, lat: 42.342529, postalCode: "14758" },
    { lng: -79.415421, lat: 42.4511, postalCode: "14166" },
    { lng: -74.99385, lat: 41.588604, postalCode: "12764" },
    { lng: -74.003246, lat: 41.949795, postalCode: "12432" },
    { lng: -73.45781, lat: 40.765378, postalCode: "11804" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10156" },
    { lng: -73.90155, lat: 41.779541, postalCode: "12538" },
    { lng: -74.68912, lat: 42.227987, postalCode: "12459" },
    { lng: -75.231158, lat: 43.101869, postalCode: "13503" },
    { lng: -73.61619, lat: 42.631625, postalCode: "12196" },
    { lng: -75.0697, lat: 44.210178, postalCode: "13670" },
    { lng: -76.139646, lat: 43.037716, postalCode: "13244" },
    { lng: -78.84234, lat: 42.972207, postalCode: "14223" },
    { lng: -73.973533, lat: 44.842975, postalCode: "12755" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14642" },
    { lng: -74.19214, lat: 41.156095, postalCode: "10974" },
    { lng: -73.743714, lat: 41.759905, postalCode: "12510" },
    { lng: -73.9789, lat: 40.752354, postalCode: "10165" },
    { lng: -73.51122, lat: 40.676649, postalCode: "11793" },
    { lng: -75.74488, lat: 42.206745, postalCode: "13761" },
    { lng: -73.58022, lat: 44.958106, postalCode: "12958" },
    { lng: -75.22681, lat: 43.087669, postalCode: "13501" },
    { lng: -75.31182, lat: 43.11627, postalCode: "13492" },
    { lng: -74.64261, lat: 42.151449, postalCode: "12455" },
    { lng: -75.85521, lat: 44.030831, postalCode: "13616" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12201" },
    { lng: -76.66687, lat: 42.177019, postalCode: "14838" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11242" },
    { lng: -73.60119, lat: 40.787368, postalCode: "11568" },
    { lng: -73.81196, lat: 44.617642, postalCode: "12981" },
    { lng: -74.00012, lat: 40.741012, postalCode: "10011" },
    { lng: -73.9216, lat: 40.618561, postalCode: "11234" },
    { lng: -75.24214, lat: 42.694896, postalCode: "13335" },
    { lng: -73.71404, lat: 40.633103, postalCode: "11598" },
    { lng: -73.9258, lat: 41.089746, postalCode: "10960" },
    { lng: -73.97606, lat: 40.754463, postalCode: "10169" },
    { lng: -73.7958, lat: 41.103664, postalCode: "10532" },
    { lng: -74.44694, lat: 42.770985, postalCode: "12031" },
    { lng: -73.71819, lat: 40.771082, postalCode: "11020" },
    { lng: -74.925617, lat: 42.183045, postalCode: "12438" },
    { lng: -73.98024, lat: 40.758667, postalCode: "10020" },
    { lng: -76.84745, lat: 42.590355, postalCode: "14860" },
    { lng: -74.15332, lat: 43.07681, postalCode: "12025" },
    { lng: -79.417353, lat: 42.19815, postalCode: "14756" },
    { lng: -77.64905, lat: 43.232312, postalCode: "14616" },
    { lng: -75.85216, lat: 42.607076, postalCode: "13136" },
    { lng: -74.81731, lat: 42.69983, postalCode: "13450" },
    { lng: -75.57702, lat: 43.141727, postalCode: "13478" },
    { lng: -73.74094, lat: 40.812887, postalCode: "11024" },
    { lng: -74.98906, lat: 44.752355, postalCode: "13668" },
    { lng: -74.04033, lat: 41.83962, postalCode: "12471" },
    { lng: -73.77597, lat: 41.167455, postalCode: "10514" },
    { lng: -74.900195, lat: 42.424859, postalCode: "13751" },
    { lng: -74.354699, lat: 41.387822, postalCode: "12551" },
    { lng: -75.46905, lat: 43.794492, postalCode: "13367" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10124" },
    { lng: -76.2127, lat: 43.82212, postalCode: "13650" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14651" },
    { lng: -75.66314, lat: 42.923334, postalCode: "13408" },
    { lng: -73.92462, lat: 40.771286, postalCode: "11102" },
    { lng: -75.0694, lat: 44.353607, postalCode: "13684" },
    { lng: -73.9029, lat: 42.853043, postalCode: "12008" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10082" },
    { lng: -78.19335, lat: 42.37074, postalCode: "14717" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14151" },
    { lng: -74.185395, lat: 42.731771, postalCode: "12141" },
    { lng: -73.66405, lat: 42.426901, postalCode: "12184" },
    { lng: -78.221332, lat: 42.12267, postalCode: "14786" },
    { lng: -73.57792, lat: 42.045941, postalCode: "12503" },
    { lng: -78.68756, lat: 43.160857, postalCode: "14094" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11052" },
    { lng: -75.25705, lat: 43.261526, postalCode: "13354" },
    { lng: -73.92132, lat: 42.005984, postalCode: "12507" },
    { lng: -73.97783, lat: 40.68209, postalCode: "11217" },
    { lng: -73.74833, lat: 42.653979, postalCode: "12207" },
    { lng: -77.877876, lat: 42.834327, postalCode: "14539" },
    { lng: -75.4224, lat: 42.9843, postalCode: "13328" },
    { lng: -74.52915, lat: 41.85304, postalCode: "12740" },
    { lng: -73.90749, lat: 40.881086, postalCode: "10463" },
    { lng: -73.37536, lat: 40.890598, postalCode: "11721" },
    { lng: -76.062425, lat: 42.823968, postalCode: "13020" },
    { lng: -74.8134, lat: 42.473691, postalCode: "13750" },
    { lng: -78.9348, lat: 42.453234, postalCode: "14070" },
    { lng: -73.90271, lat: 40.724096, postalCode: "11378" },
    { lng: -75.17968, lat: 41.965435, postalCode: "13774" },
    { lng: -73.89327, lat: 40.825432, postalCode: "10459" },
    { lng: -73.89151, lat: 43.484741, postalCode: "12810" },
    { lng: -76.66641, lat: 42.832007, postalCode: "13160" },
    { lng: -73.68935, lat: 40.796752, postalCode: "11030" },
    { lng: -78.73356, lat: 42.977456, postalCode: "14221" },
    { lng: -77.55904, lat: 43.116713, postalCode: "14618" },
    { lng: -73.67895, lat: 40.742901, postalCode: "11040" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10571" },
    { lng: -78.88235, lat: 42.535917, postalCode: "14091" },
    { lng: -73.63801, lat: 42.685311, postalCode: "12198" },
    { lng: -73.95173, lat: 41.915068, postalCode: "12574" },
    { lng: -72.57502, lat: 40.941363, postalCode: "11970" },
    { lng: -73.73441, lat: 44.466008, postalCode: "12912" },
    { lng: -75.65578, lat: 42.197905, postalCode: "13787" },
    { lng: -76.09312, lat: 44.230421, postalCode: "13624" },
    { lng: -74.00857, lat: 40.713062, postalCode: "10279" },
    { lng: -76.85353, lat: 42.75147, postalCode: "14541" },
    { lng: -74.01168, lat: 40.617563, postalCode: "11228" },
    { lng: -73.761204, lat: 44.664956, postalCode: "12978" },
    { lng: -73.97608, lat: 40.752859, postalCode: "10170" },
    { lng: -75.52172, lat: 43.453661, postalCode: "13489" },
    { lng: -76.123795, lat: 42.567313, postalCode: "13738" },
    { lng: -75.48119, lat: 42.426583, postalCode: "13780" },
    { lng: -73.7727, lat: 40.674888, postalCode: "11434" },
    { lng: -78.6474, lat: 42.426278, postalCode: "14171" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10138" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10185" },
    { lng: -73.24517, lat: 40.783954, postalCode: "11717" },
    { lng: -75.55149, lat: 42.163814, postalCode: "13813" },
    { lng: -74.87533, lat: 41.545318, postalCode: "12732" },
    { lng: -76.17536, lat: 43.048966, postalCode: "13204" },
    { lng: -77.11824, lat: 42.589281, postalCode: "14478" },
    { lng: -75.82694, lat: 44.091494, postalCode: "13637" },
    { lng: -76.14994, lat: 43.045066, postalCode: "13202" },
    { lng: -73.94351, lat: 40.818151, postalCode: "10030" },
    { lng: -73.57536, lat: 40.756287, postalCode: "11590" },
    { lng: -79.11347, lat: 42.229098, postalCode: "14732" },
    { lng: -73.82973, lat: 40.706903, postalCode: "11415" },
    { lng: -73.73477, lat: 42.343986, postalCode: "12174" },
    { lng: -75.29084, lat: 43.932489, postalCode: "13327" },
    { lng: -73.95039, lat: 40.781894, postalCode: "10128" },
    { lng: -73.79335, lat: 42.474135, postalCode: "12045" },
    { lng: -73.409243, lat: 44.526437, postalCode: "12975" },
    { lng: -76.33811, lat: 42.979467, postalCode: "13108" },
    { lng: -74.058015, lat: 42.833261, postalCode: "12325" },
    { lng: -75.50381, lat: 43.035057, postalCode: "13477" },
    { lng: -73.84366, lat: 40.785462, postalCode: "11356" },
    { lng: -74.66524, lat: 43.180412, postalCode: "13470" },
    { lng: -73.446834, lat: 42.772624, postalCode: "12082" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11352" },
    { lng: -72.8368, lat: 40.953261, postalCode: "11792" },
    { lng: -73.81658, lat: 41.057089, postalCode: "10523" },
    { lng: -73.77933, lat: 40.897783, postalCode: "10805" },
    { lng: -75.18605, lat: 42.417489, postalCode: "13825" },
    { lng: -74.57566, lat: 41.518125, postalCode: "12785" },
    { lng: -74.14059, lat: 42.456127, postalCode: "12120" },
    { lng: -73.92234, lat: 40.838745, postalCode: "10452" },
    { lng: -74.55282, lat: 42.559096, postalCode: "12175" },
    { lng: -77.2223, lat: 43.063858, postalCode: "14522" },
    { lng: -75.93107, lat: 43.279405, postalCode: "13028" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14267" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11109" },
    { lng: -75.731391, lat: 42.712735, postalCode: "13129" },
    { lng: -73.76454, lat: 41.032489, postalCode: "10601" },
    { lng: -77.99501, lat: 42.542056, postalCode: "14846" },
    { lng: -77.54587, lat: 43.142259, postalCode: "14610" },
    { lng: -73.66703, lat: 40.639575, postalCode: "11518" },
    { lng: -78.32302, lat: 42.443406, postalCode: "14060" },
    { lng: -73.760132, lat: 41.372581, postalCode: "10542" },
    { lng: -74.008687, lat: 40.70859, postalCode: "10045" },
    { lng: -76.05538, lat: 43.074266, postalCode: "13057" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11381" },
    { lng: -73.99843, lat: 41.993639, postalCode: "12449" },
    { lng: -73.85118, lat: 41.314676, postalCode: "10547" },
    { lng: -74.72244, lat: 41.429425, postalCode: "12780" },
    { lng: -75.798319, lat: 43.885519, postalCode: "13628" },
    { lng: -73.79568, lat: 40.592939, postalCode: "11692" },
    { lng: -73.989426, lat: 40.750629, postalCode: "10120" },
    { lng: -74.34369, lat: 42.757815, postalCode: "12160" },
    { lng: -73.66667, lat: 44.692862, postalCode: "12918" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10625" },
    { lng: -73.4801, lat: 44.483465, postalCode: "12944" },
    { lng: -75.81709, lat: 43.572876, postalCode: "13437" },
    { lng: -76.31946, lat: 43.088699, postalCode: "13164" },
    { lng: -75.93241, lat: 42.560795, postalCode: "13040" },
    { lng: -73.91978, lat: 42.350557, postalCode: "12058" },
    { lng: -73.81701, lat: 42.279649, postalCode: "12015" },
    { lng: -75.70576, lat: 44.54141, postalCode: "13614" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10269" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11499" },
    { lng: -76.6729, lat: 43.337718, postalCode: "13156" },
    { lng: -75.32353, lat: 42.766057, postalCode: "13485" },
    { lng: -75.83772, lat: 42.419896, postalCode: "13841" },
    { lng: -73.97516, lat: 40.751656, postalCode: "10174" },
    { lng: -77.68132, lat: 43.168062, postalCode: "14606" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11931" },
    { lng: -73.69776, lat: 43.50964, postalCode: "12824" },
    { lng: -75.25569, lat: 42.87896, postalCode: "13313" },
    { lng: -75.95021, lat: 42.328284, postalCode: "13862" },
    { lng: -75.14604, lat: 42.540359, postalCode: "13796" },
    { lng: -73.79324, lat: 40.714144, postalCode: "11432" },
    { lng: -78.8915, lat: 42.916057, postalCode: "14213" },
    { lng: -74.1106, lat: 41.664239, postalCode: "12548" },
    { lng: -75.72942, lat: 43.859025, postalCode: "13626" },
    { lng: -74.014958, lat: 40.714643, postalCode: "10281" },
    { lng: -75.22228, lat: 44.436004, postalCode: "13652" },
    { lng: -76.628454, lat: 42.742057, postalCode: "13139" },
    { lng: -74.30413, lat: 41.790385, postalCode: "12446" },
    { lng: -76.18566, lat: 42.680693, postalCode: "13077" },
    { lng: -73.88096, lat: 42.800276, postalCode: "12309" },
    { lng: -74.01433, lat: 40.717179, postalCode: "10282" },
    { lng: -74.456913, lat: 43.667618, postalCode: "12864" },
    { lng: -73.39609, lat: 40.680892, postalCode: "11726" },
    { lng: -73.53382, lat: 40.673438, postalCode: "11710" },
    { lng: -76.35407, lat: 42.368, postalCode: "14817" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14270" },
    { lng: -77.70625, lat: 42.837786, postalCode: "14480" },
    { lng: -73.60664, lat: 42.925765, postalCode: "12154" },
    { lng: -74.40875, lat: 43.525388, postalCode: "12108" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11736" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14673" },
    { lng: -73.86199, lat: 42.123977, postalCode: "12526" },
    { lng: -77.05664, lat: 42.664079, postalCode: "14527" },
    { lng: -79.238997, lat: 42.488983, postalCode: "14135" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10164" },
    { lng: -78.19509, lat: 42.426885, postalCode: "14744" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11041" },
    { lng: -77.855883, lat: 43.195261, postalCode: "14410" },
    { lng: -72.55024, lat: 40.961916, postalCode: "11948" },
    { lng: -77.097713, lat: 42.474143, postalCode: "14893" },
    { lng: -73.9371, lat: 40.826181, postalCode: "10039" },
    { lng: -73.44639, lat: 44.683468, postalCode: "12903" },
    { lng: -73.936281, lat: 41.582437, postalCode: "12537" },
    { lng: -74.00365, lat: 40.709677, postalCode: "10038" },
    { lng: -73.35236, lat: 42.959229, postalCode: "12057" },
    { lng: -77.30097, lat: 43.227921, postalCode: "14519" },
    { lng: -73.92528, lat: 42.561226, postalCode: "12067" },
    { lng: -73.97223, lat: 40.758611, postalCode: "10152" },
    { lng: -73.96174, lat: 43.426652, postalCode: "12878" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10096" },
    { lng: -75.5134, lat: 43.079233, postalCode: "13476" },
    { lng: -75.08534, lat: 43.21574, postalCode: "13431" },
    { lng: -75.39938, lat: 42.852808, postalCode: "13418" },
    { lng: -78.153849, lat: 42.543108, postalCode: "14130" },
    { lng: -78.85923, lat: 42.949707, postalCode: "14216" },
    { lng: -73.909234, lat: 42.035434, postalCode: "12504" },
    { lng: -78.17657, lat: 42.829129, postalCode: "14039" },
    { lng: -74.24778, lat: 42.970827, postalCode: "12070" },
    { lng: -72.00708, lat: 41.261936, postalCode: "6390" },
    { lng: -75.60331, lat: 44.243007, postalCode: "13608" },
    { lng: -78.75557, lat: 42.836858, postalCode: "14224" },
    { lng: -73.95471, lat: 40.802853, postalCode: "10026" },
    { lng: -74.05452, lat: 42.876756, postalCode: "12150" },
    { lng: -73.69482, lat: 42.743289, postalCode: "12183" },
    { lng: -73.525561, lat: 42.71144, postalCode: "12179" },
    { lng: -73.79269, lat: 41.199502, postalCode: "10546" },
    { lng: -77.346, lat: 42.985007, postalCode: "14425" },
    { lng: -76.78366, lat: 42.924166, postalCode: "13148" },
    { lng: -72.98837, lat: 40.946506, postalCode: "11764" },
    { lng: -76.706717, lat: 42.451569, postalCode: "14863" },
    { lng: -73.98205, lat: 40.739022, postalCode: "10010" },
    { lng: -74.10605, lat: 41.852449, postalCode: "12419" },
    { lng: -74.98164, lat: 42.743186, postalCode: "13337" },
    { lng: -73.987, lat: 40.71704, postalCode: "10002" },
    { lng: -73.74762, lat: 40.618206, postalCode: "11096" },
    { lng: -73.59556, lat: 41.41362, postalCode: "10509" },
    { lng: -73.77856, lat: 42.080964, postalCode: "12523" },
    { lng: -74.72675, lat: 43.392434, postalCode: "13353" },
    { lng: -73.988421, lat: 40.748181, postalCode: "10095" },
    { lng: -77.42796, lat: 42.20928, postalCode: "14819" },
    { lng: -74.85751, lat: 41.649008, postalCode: "12786" },
    { lng: -78.87967, lat: 42.100539, postalCode: "14783" },
    { lng: -74.406393, lat: 42.973468, postalCode: "12825" },
    { lng: -74.01734, lat: 41.063329, postalCode: "10965" },
    { lng: -74.982271, lat: 43.026416, postalCode: "13550" },
    { lng: -79.02562, lat: 43.247054, postalCode: "14174" },
    { lng: -73.62777, lat: 41.195778, postalCode: "10506" },
    { lng: -75.41093, lat: 42.392104, postalCode: "13809" },
    { lng: -73.93616, lat: 40.813385, postalCode: "10037" },
    { lng: -78.87436, lat: 42.329183, postalCode: "14719" },
    { lng: -75.00691, lat: 42.086268, postalCode: "13755" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10275" },
    { lng: -77.64685, lat: 43.136363, postalCode: "14619" },
    { lng: -78.05768, lat: 42.016108, postalCode: "14708" },
    { lng: -73.743714, lat: 41.759905, postalCode: "12511" },
    { lng: -73.29152, lat: 43.433107, postalCode: "12849" },
    { lng: -79.09002, lat: 42.573552, postalCode: "14081" },
    { lng: -73.98935, lat: 40.732509, postalCode: "10003" },
    { lng: -74.328805, lat: 41.555634, postalCode: "10915" },
    { lng: -75.96763, lat: 42.987329, postalCode: "13104" },
    { lng: -73.1187, lat: 40.818826, postalCode: "11779" },
    { lng: -74.102319, lat: 42.19551, postalCode: "12436" },
    { lng: -73.59542, lat: 44.212623, postalCode: "12932" },
    { lng: -77.2298, lat: 42.970621, postalCode: "14504" },
    { lng: -73.7856, lat: 42.640813, postalCode: "12209" },
    { lng: -74.436159, lat: 41.651453, postalCode: "12769" },
    { lng: -73.75367, lat: 41.372419, postalCode: "10541" },
    { lng: -75.74488, lat: 42.206745, postalCode: "13762" },
    { lng: -79.40865, lat: 42.098227, postalCode: "14710" },
    { lng: -72.9733, lat: 40.955586, postalCode: "11789" },
    { lng: -73.75615, lat: 40.933089, postalCode: "10538" },
    { lng: -73.73322, lat: 40.759686, postalCode: "11362" },
    { lng: -77.51181, lat: 43.071131, postalCode: "14534" },
    { lng: -77.99024, lat: 43.067831, postalCode: "14416" },
    { lng: -73.9223, lat: 40.819729, postalCode: "10451" },
    { lng: -74.973218, lat: 44.608735, postalCode: "13647" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10163" },
    { lng: -74.03584, lat: 41.434476, postalCode: "12518" },
    { lng: -75.48794, lat: 44.563604, postalCode: "13654" },
    { lng: -73.49405, lat: 42.74891, postalCode: "12052" },
    { lng: -74.92211, lat: 41.774223, postalCode: "12748" },
    { lng: -76.80393, lat: 42.072024, postalCode: "14904" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13250" },
    { lng: -73.88881, lat: 40.864166, postalCode: "10458" },
    { lng: -74.75464, lat: 42.274238, postalCode: "13740" },
    { lng: -73.673862, lat: 42.738678, postalCode: "12181" },
    { lng: -74.15098, lat: 40.551353, postalCode: "10308" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12237" },
    { lng: -75.271559, lat: 43.053769, postalCode: "13479" },
    { lng: -76.13619, lat: 43.559926, postalCode: "13142" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10087" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11380" },
    { lng: -77.58983, lat: 42.787276, postalCode: "14466" },
    { lng: -74.03148, lat: 42.698692, postalCode: "12009" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14280" },
    { lng: -77.82312, lat: 42.321173, postalCode: "14804" },
    { lng: -74.345684, lat: 41.878801, postalCode: "12588" },
    { lng: -76.87557, lat: 43.07351, postalCode: "14433" },
    { lng: -73.80635, lat: 41.025114, postalCode: "10530" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13089" },
    { lng: -78.873479, lat: 42.885568, postalCode: "14264" },
    { lng: -74.008154, lat: 40.706925, postalCode: "10270" },
    { lng: -73.72587, lat: 42.63738, postalCode: "12144" },
    { lng: -74.97449, lat: 43.317064, postalCode: "13324" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11241" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11583" },
    { lng: -73.93496, lat: 40.848764, postalCode: "10033" },
    { lng: -72.33159, lat: 41.060843, postalCode: "11964" },
    { lng: -79.19126, lat: 42.128469, postalCode: "14733" },
    { lng: -73.9856, lat: 40.765714, postalCode: "10019" },
    { lng: -75.62459, lat: 42.428818, postalCode: "13830" },
    { lng: -73.97033, lat: 43.092312, postalCode: "12850" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10179" },
    { lng: -73.61867, lat: 42.851106, postalCode: "12121" },
    { lng: -78.81049, lat: 42.880105, postalCode: "14206" },
    { lng: -73.71079, lat: 40.651733, postalCode: "11581" },
    { lng: -73.98415, lat: 41.603333, postalCode: "12542" },
    { lng: -73.66906, lat: 41.3252, postalCode: "10578" },
    { lng: -73.79621, lat: 42.600551, postalCode: "12077" },
    { lng: -73.591652, lat: 43.088397, postalCode: "12884" },
    { lng: -75.0588, lat: 42.708284, postalCode: "13348" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10102" },
    { lng: -75.44551, lat: 42.076427, postalCode: "13754" },
    { lng: -73.687001, lat: 42.210991, postalCode: "12530" },
    { lng: -73.95499, lat: 40.812665, postalCode: "10027" },
    { lng: -73.53478, lat: 40.848766, postalCode: "11732" },
    { lng: -73.89579, lat: 41.59466, postalCode: "12590" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11002" },
    { lng: -74.116812, lat: 42.660046, postalCode: "12107" },
    { lng: -72.94663, lat: 40.771447, postalCode: "11713" },
    { lng: -78.667599, lat: 42.807159, postalCode: "14140" },
    { lng: -73.6642, lat: 42.086628, postalCode: "12502" },
    { lng: -74.016529, lat: 40.709387, postalCode: "10250" },
    { lng: -73.94575, lat: 40.791586, postalCode: "10029" },
    { lng: -73.815204, lat: 44.145678, postalCode: "12977" },
    { lng: -74.37451, lat: 41.580793, postalCode: "10985" },
    { lng: -74.64537, lat: 43.021223, postalCode: "13452" },
    { lng: -73.89285, lat: 40.703069, postalCode: "11385" },
    { lng: -73.66199, lat: 41.34598, postalCode: "10519" },
    { lng: -74.23905, lat: 42.038539, postalCode: "12457" },
    { lng: -73.629296, lat: 42.466061, postalCode: "12132" },
    { lng: -78.7006, lat: 42.904958, postalCode: "14043" },
    { lng: -79.294921, lat: 42.081657, postalCode: "14702" },
    { lng: -75.74488, lat: 42.206745, postalCode: "13749" },
    { lng: -73.978534, lat: 40.762808, postalCode: "10105" },
    { lng: -73.354596, lat: 43.618466, postalCode: "12877" },
    { lng: -73.38557, lat: 43.039226, postalCode: "12816" },
    { lng: -72.52063, lat: 40.875897, postalCode: "11946" },
    { lng: -77.14416, lat: 42.965372, postalCode: "14432" },
    { lng: -77.668326, lat: 42.855029, postalCode: "14458" },
    { lng: -74.66996, lat: 41.752458, postalCode: "12747" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14233" },
    { lng: -78.8187, lat: 42.845758, postalCode: "14220" },
    { lng: -74.76539, lat: 44.224185, postalCode: "12922" },
    { lng: -74.596249, lat: 44.776061, postalCode: "12697" },
    { lng: -74.430063, lat: 41.881485, postalCode: "12782" },
    { lng: -77.287611, lat: 42.808127, postalCode: "14547" },
    { lng: -76.00695, lat: 43.077067, postalCode: "13116" },
    { lng: -76.12653, lat: 43.037166, postalCode: "13210" },
    { lng: -76.83686, lat: 42.087824, postalCode: "14905" },
    { lng: -75.91199, lat: 43.976735, postalCode: "13601" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14269" },
    { lng: -76.00793, lat: 43.871728, postalCode: "13606" },
    { lng: -74.083045, lat: 41.409284, postalCode: "10953" },
    { lng: -73.733532, lat: 42.285749, postalCode: "12172" },
    { lng: -73.20777, lat: 40.853343, postalCode: "11787" },
    { lng: -77.67433, lat: 42.98723, postalCode: "14543" },
    { lng: -73.93571, lat: 42.804076, postalCode: "12307" },
    { lng: -77.27447, lat: 42.702921, postalCode: "14507" },
    { lng: -77.03599, lat: 43.226024, postalCode: "14551" },
    { lng: -78.83368, lat: 43.307288, postalCode: "14172" },
    { lng: -76.28318, lat: 42.891595, postalCode: "13110" },
    { lng: -73.85721, lat: 41.080196, postalCode: "10591" },
    { lng: -74.15692, lat: 42.406464, postalCode: "12460" },
    { lng: -76.9997, lat: 42.502293, postalCode: "14837" },
    { lng: -79.43348, lat: 42.258967, postalCode: "14728" },
    { lng: -73.74644, lat: 40.772636, postalCode: "11363" },
    { lng: -73.7162, lat: 41.721384, postalCode: "12585" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10265" },
    { lng: -75.24718, lat: 42.367765, postalCode: "13859" },
    { lng: -74.23418, lat: 42.222061, postalCode: "12442" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12242" },
    { lng: -74.01227, lat: 40.712086, postalCode: "10048" },
    { lng: -73.28001, lat: 40.841522, postalCode: "11725" },
    { lng: -74.55536, lat: 42.923583, postalCode: "13428" },
    { lng: -75.030592, lat: 41.762944, postalCode: "12745" },
    { lng: -77.60183, lat: 42.879208, postalCode: "14485" },
    { lng: -74.07556, lat: 41.116162, postalCode: "10952" },
    { lng: -76.1032, lat: 43.041866, postalCode: "13224" },
    { lng: -76.10724, lat: 43.069866, postalCode: "13206" },
    { lng: -73.67306, lat: 40.656974, postalCode: "11563" },
    { lng: -74.755373, lat: 41.719016, postalCode: "12767" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10633" },
    { lng: -73.46617, lat: 44.693271, postalCode: "12901" },
    { lng: -72.40714, lat: 40.904348, postalCode: "11968" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11256" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13221" },
    { lng: -75.66879, lat: 44.308475, postalCode: "13671" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12288" },
    { lng: -73.77672, lat: 41.053182, postalCode: "10603" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12225" },
    { lng: -77.42331, lat: 42.900966, postalCode: "14443" },
    { lng: -74.80882, lat: 42.843048, postalCode: "13333" },
    { lng: -77.045288, lat: 43.348369, postalCode: "14520" },
    { lng: -74.797524, lat: 41.736963, postalCode: "12793" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10242" },
    { lng: -75.7763, lat: 42.185196, postalCode: "13833" },
    { lng: -74.16619, lat: 41.850294, postalCode: "12484" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11054" },
    { lng: -73.66701, lat: 41.294561, postalCode: "10526" },
    { lng: -74.1755, lat: 41.563701, postalCode: "12586" },
    { lng: -73.85893, lat: 40.818514, postalCode: "10473" },
    { lng: -73.53494, lat: 42.62742, postalCode: "12018" },
    { lng: -73.81545, lat: 42.539822, postalCode: "12158" },
    { lng: -77.43483, lat: 43.091714, postalCode: "14450" },
    { lng: -74.92847, lat: 44.537321, postalCode: "13625" },
    { lng: -73.06222, lat: 40.946103, postalCode: "11777" },
    { lng: -74.12682, lat: 41.125695, postalCode: "10901" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11245" },
    { lng: -73.94288, lat: 40.702348, postalCode: "11206" },
    { lng: -75.93329, lat: 42.121443, postalCode: "13905" },
    { lng: -75.480342, lat: 43.818705, postalCode: "13305" },
    { lng: -76.28757, lat: 43.039916, postalCode: "13031" },
    { lng: -73.81782, lat: 43.638919, postalCode: "12817" },
    { lng: -74.02388, lat: 42.284773, postalCode: "12470" },
    { lng: -73.97267, lat: 40.757986, postalCode: "10154" },
    { lng: -73.96045, lat: 40.76842, postalCode: "10021" },
    { lng: -78.82303, lat: 42.894758, postalCode: "14212" },
    { lng: -77.97053, lat: 42.979887, postalCode: "14482" },
    { lng: -78.830681, lat: 43.32684, postalCode: "14107" },
    { lng: -73.869454, lat: 40.828279, postalCode: "10422" },
    { lng: -74.89998, lat: 44.69563, postalCode: "13696" },
    { lng: -73.99207, lat: 41.855761, postalCode: "12487" },
    { lng: -75.3483, lat: 43.637104, postalCode: "13368" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10610" },
    { lng: -73.78903, lat: 40.857017, postalCode: "10464" },
    { lng: -74.0295, lat: 40.62327, postalCode: "11209" },
    { lng: -77.50263, lat: 42.999201, postalCode: "14506" },
    { lng: -75.86633, lat: 42.728977, postalCode: "13052" },
    { lng: -74.37907, lat: 41.525781, postalCode: "10919" },
    { lng: -76.40638, lat: 42.063671, postalCode: "13734" },
    { lng: -75.07355, lat: 43.506153, postalCode: "13338" },
    { lng: -79.3371, lat: 42.425683, postalCode: "14063" },
    { lng: -78.30659, lat: 42.047063, postalCode: "14770" },
    { lng: -73.58039, lat: 40.593996, postalCode: "11569" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11055" },
    { lng: -74.35844, lat: 41.478028, postalCode: "10941" },
    { lng: -77.79269, lat: 42.034218, postalCode: "14897" },
    { lng: -75.28767, lat: 43.067169, postalCode: "13413" },
    { lng: -73.990537, lat: 40.751489, postalCode: "10123" },
    { lng: -75.56997, lat: 42.752447, postalCode: "13332" },
    { lng: -73.08009, lat: 40.868946, postalCode: "11720" },
    { lng: -76.61441, lat: 43.258952, postalCode: "13111" },
    { lng: -77.71394, lat: 42.410387, postalCode: "14807" },
    { lng: -73.68075, lat: 41.785109, postalCode: "12545" },
    { lng: -76.41226, lat: 42.755698, postalCode: "13118" },
    { lng: -74.17899, lat: 42.940644, postalCode: "12010" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11484" },
    { lng: -76.38963, lat: 42.897167, postalCode: "13152" },
    { lng: -74.00395, lat: 40.715721, postalCode: "10278" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14231" },
    { lng: -73.96836, lat: 41.196044, postalCode: "10927" },
    { lng: -75.02808, lat: 41.769427, postalCode: "12723" },
    { lng: -73.89861, lat: 40.846745, postalCode: "10457" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11202" },
    { lng: -77.75302, lat: 42.681361, postalCode: "14462" },
    { lng: -78.61484, lat: 42.981558, postalCode: "14031" },
    { lng: -73.58834, lat: 41.289162, postalCode: "10597" },
    { lng: -74.061201, lat: 43.62422, postalCode: "12811" },
    { lng: -73.75948, lat: 40.697188, postalCode: "11412" },
    { lng: -74.016306, lat: 40.71533, postalCode: "10285" },
    { lng: -74.47758, lat: 41.391172, postalCode: "10973" },
    { lng: -74.19531, lat: 41.323547, postalCode: "10950" },
    { lng: -76.94805, lat: 42.150834, postalCode: "14814" },
    { lng: -73.742268, lat: 41.328737, postalCode: "10587" },
    { lng: -73.847176, lat: 41.643599, postalCode: "12509" },
    { lng: -73.11376, lat: 40.770042, postalCode: "11716" },
    { lng: -73.52011, lat: 41.961002, postalCode: "12546" },
    { lng: -78.50414, lat: 43.024559, postalCode: "14001" },
    { lng: -78.8779, lat: 42.886357, postalCode: "14202" },
    { lng: -78.984391, lat: 42.408583, postalCode: "14041" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14652" },
    { lng: -73.40543, lat: 40.791593, postalCode: "11747" },
    { lng: -74.80674, lat: 41.676676, postalCode: "12762" },
    { lng: -77.142027, lat: 43.283582, postalCode: "14538" },
    { lng: -73.90875, lat: 42.403719, postalCode: "12042" },
    { lng: -75.47528, lat: 42.292692, postalCode: "13733" },
    { lng: -74.14049, lat: 41.299334, postalCode: "10926" },
    { lng: -79.16528, lat: 42.450845, postalCode: "14062" },
    { lng: -78.51652, lat: 42.898709, postalCode: "14004" },
    { lng: -73.70913, lat: 42.729278, postalCode: "12189" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10043" },
    { lng: -73.9905, lat: 40.775477, postalCode: "10069" },
    { lng: -73.732996, lat: 41.119008, postalCode: "10592" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11750" },
    { lng: -75.59912, lat: 43.070808, postalCode: "13461" },
    { lng: -73.67709, lat: 41.614667, postalCode: "12570" },
    { lng: -73.566328, lat: 42.59362, postalCode: "12081" },
    { lng: -74.00858, lat: 40.706019, postalCode: "10005" },
    { lng: -76.47992, lat: 43.449201, postalCode: "13126" },
    { lng: -73.79687, lat: 40.674146, postalCode: "11436" },
    { lng: -74.755373, lat: 41.719016, postalCode: "12722" },
    { lng: -73.82496, lat: 40.767969, postalCode: "11354" },
    { lng: -77.60368, lat: 43.185163, postalCode: "14621" },
    { lng: -73.78445, lat: 42.853676, postalCode: "12065" },
    { lng: -75.75793, lat: 44.03803, postalCode: "13602" },
    { lng: -74.67374, lat: 44.98184, postalCode: "13655" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10133" },
    { lng: -76.608222, lat: 42.50632, postalCode: "14854" },
    { lng: -74.01599, lat: 42.174411, postalCode: "12463" },
    { lng: -75.47796, lat: 43.239274, postalCode: "13442" },
    { lng: -73.92817, lat: 41.028647, postalCode: "10976" },
    { lng: -75.14417, lat: 44.58249, postalCode: "13617" },
    { lng: -74.59011, lat: 42.285644, postalCode: "12474" },
    { lng: -75.91871, lat: 42.250715, postalCode: "13744" },
    { lng: -73.75894, lat: 42.656679, postalCode: "12210" },
    { lng: -78.76241, lat: 43.02534, postalCode: "14068" },
    { lng: -77.9075, lat: 42.589984, postalCode: "14517" },
    { lng: -74.75386, lat: 44.864624, postalCode: "13613" },
    { lng: -76.505435, lat: 42.460721, postalCode: "14851" },
    { lng: -74.00161, lat: 41.204544, postalCode: "10923" },
    { lng: -76.36558, lat: 42.024157, postalCode: "13812" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14601" },
    { lng: -76.14733, lat: 42.059433, postalCode: "13732" },
    { lng: -73.96845, lat: 41.805576, postalCode: "12493" },
    { lng: -74.98117, lat: 43.178528, postalCode: "13416" },
    { lng: -76.860779, lat: 43.144795, postalCode: "14542" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12226" },
    { lng: -78.75294, lat: 42.759917, postalCode: "14127" },
    { lng: -76.72405, lat: 42.614518, postalCode: "14847" },
    { lng: -73.94552, lat: 40.627946, postalCode: "11210" },
    { lng: -78.55601, lat: 42.838643, postalCode: "14102" },
    { lng: -76.69317, lat: 42.074742, postalCode: "14861" },
    { lng: -77.58962, lat: 43.149863, postalCode: "14607" },
    { lng: -75.67053, lat: 42.603506, postalCode: "13844" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14430" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14263" },
    { lng: -74.40025, lat: 41.802898, postalCode: "12458" },
    { lng: -76.17411, lat: 43.075066, postalCode: "13290" },
    { lng: -73.83365, lat: 40.698485, postalCode: "11418" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10109" },
    { lng: -73.73045, lat: 41.204027, postalCode: "10549" },
    { lng: -76.84497, lat: 42.129274, postalCode: "14903" },
    { lng: -76.12823, lat: 43.123415, postalCode: "13220" },
    { lng: -75.82785, lat: 43.399851, postalCode: "13483" },
    { lng: -73.1296, lat: 40.736011, postalCode: "11769" },
    { lng: -73.43636, lat: 44.965887, postalCode: "12919" },
    { lng: -76.13733, lat: 43.061116, postalCode: "13203" },
    { lng: -74.2161, lat: 41.484988, postalCode: "12543" },
    { lng: -73.86937, lat: 40.872265, postalCode: "10467" },
    { lng: -75.61724, lat: 43.981245, postalCode: "13619" },
    { lng: -73.87919, lat: 40.645848, postalCode: "11239" },
    { lng: -75.24946, lat: 42.937739, postalCode: "13318" },
    { lng: -75.63584, lat: 42.070228, postalCode: "13865" },
    { lng: -74.54593, lat: 41.334034, postalCode: "10998" },
    { lng: -74.41571, lat: 41.384738, postalCode: "10958" },
    { lng: -78.38746, lat: 43.217155, postalCode: "14103" },
    { lng: -73.5735, lat: 43.316622, postalCode: "12839" },
    { lng: -75.914556, lat: 44.207681, postalCode: "13641" },
    { lng: -74.68843, lat: 44.698253, postalCode: "12965" },
    { lng: -78.8699, lat: 42.880107, postalCode: "14203" },
    { lng: -73.87051, lat: 40.762854, postalCode: "11369" },
    { lng: -74.96218, lat: 42.536475, postalCode: "13834" },
    { lng: -74.345684, lat: 41.878801, postalCode: "12441" },
    { lng: -75.15821, lat: 43.349682, postalCode: "13438" },
    { lng: -73.97595, lat: 40.644552, postalCode: "11218" },
    { lng: -73.65394, lat: 40.6949, postalCode: "11552" },
    { lng: -73.749244, lat: 41.33431, postalCode: "10505" },
    { lng: -74.412119, lat: 41.507236, postalCode: "10946" },
    { lng: -74.706763, lat: 44.921678, postalCode: "13649" },
    { lng: -73.82983, lat: 42.678112, postalCode: "12203" },
    { lng: -74.21304, lat: 40.529749, postalCode: "10309" },
    { lng: -74.15461, lat: 41.615036, postalCode: "12589" },
    { lng: -73.92, lat: 40.867653, postalCode: "10034" },
    { lng: -74.05975, lat: 41.46359, postalCode: "12553" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10260" },
    { lng: -78.33745, lat: 42.672162, postalCode: "14113" },
    { lng: -73.64401, lat: 40.828143, postalCode: "11547" },
    { lng: -73.36801, lat: 42.760049, postalCode: "12138" },
    { lng: -73.99834, lat: 40.72596, postalCode: "10012" },
    { lng: -75.17793, lat: 42.64743, postalCode: "13342" },
    { lng: -75.117319, lat: 43.328187, postalCode: "13352" },
    { lng: -73.936671, lat: 42.482455, postalCode: "12007" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13225" },
    { lng: -73.54775, lat: 42.204681, postalCode: "12529" },
    { lng: -78.849861, lat: 42.49064, postalCode: "14035" },
    { lng: -74.6595, lat: 42.615422, postalCode: "12064" },
    { lng: -74.02259, lat: 41.932315, postalCode: "12401" },
    { lng: -73.40767, lat: 43.178983, postalCode: "12823" },
    { lng: -76.57045, lat: 42.774714, postalCode: "13147" },
    { lng: -73.20967, lat: 40.819266, postalCode: "11788" },
    { lng: -78.01907, lat: 42.238994, postalCode: "14813" },
    { lng: -73.65156, lat: 40.793839, postalCode: "11576" },
    { lng: -73.980811, lat: 40.753987, postalCode: "10110" },
    { lng: -74.424533, lat: 41.663895, postalCode: "12483" },
    { lng: -74.5716, lat: 44.833996, postalCode: "12957" },
    { lng: -73.81752, lat: 40.825727, postalCode: "10465" },
    { lng: -75.22603, lat: 42.969624, postalCode: "13322" },
    { lng: -73.69234, lat: 41.331587, postalCode: "10589" },
    { lng: -74.86479, lat: 42.843002, postalCode: "13468" },
    { lng: -74.058015, lat: 42.833261, postalCode: "12301" },
    { lng: -76.07635, lat: 43.038099, postalCode: "13214" },
    { lng: -79.26035, lat: 42.265234, postalCode: "14782" },
    { lng: -73.57852, lat: 42.346758, postalCode: "12037" },
    { lng: -73.68023, lat: 44.951443, postalCode: "12959" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12233" },
    { lng: -78.06929, lat: 42.54523, postalCode: "14536" },
    { lng: -75.39115, lat: 43.036802, postalCode: "13341" },
    { lng: -73.55713, lat: 40.907277, postalCode: "11709" },
    { lng: -75.672508, lat: 42.637549, postalCode: "13444" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13201" },
    { lng: -73.19924, lat: 40.784874, postalCode: "11722" },
    { lng: -75.03878, lat: 41.848244, postalCode: "12736" },
    { lng: -78.24581, lat: 42.906765, postalCode: "14005" },
    { lng: -76.054313, lat: 43.73345, postalCode: "13674" },
    { lng: -77.83528, lat: 42.937589, postalCode: "14423" },
    { lng: -73.88689, lat: 41.337335, postalCode: "10537" },
    { lng: -76.41482, lat: 43.105771, postalCode: "13112" },
    { lng: -74.52154, lat: 42.183262, postalCode: "12430" },
    { lng: -74.17628, lat: 40.543117, postalCode: "10312" },
    { lng: -73.76802, lat: 42.74964, postalCode: "12110" },
    { lng: -73.83311, lat: 41.146297, postalCode: "10510" },
    { lng: -74.473639, lat: 42.136293, postalCode: "12465" },
    { lng: -73.93304, lat: 40.649059, postalCode: "11203" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11556" },
    { lng: -73.88393, lat: 42.060034, postalCode: "12583" },
    { lng: -75.96057, lat: 43.164297, postalCode: "13030" },
    { lng: -78.44075, lat: 42.676913, postalCode: "14083" },
    { lng: -73.07198, lat: 40.739869, postalCode: "11782" },
    { lng: -72.57794, lat: 40.84867, postalCode: "11942" },
    { lng: -76.44698, lat: 43.157653, postalCode: "13137" },
    { lng: -74.40616, lat: 42.425405, postalCode: "12076" },
    { lng: -74.01043, lat: 40.708669, postalCode: "10271" },
    { lng: -77.4509, lat: 42.868609, postalCode: "14469" },
    { lng: -74.45667, lat: 42.831683, postalCode: "12166" },
    { lng: -73.76727, lat: 41.874183, postalCode: "12514" },
    { lng: -77.287611, lat: 42.808127, postalCode: "14461" },
    { lng: -78.318828, lat: 43.241502, postalCode: "14479" },
    { lng: -78.51587, lat: 42.10143, postalCode: "14706" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12227" },
    { lng: -77.5632, lat: 42.041973, postalCode: "14885" },
    { lng: -74.354699, lat: 41.387822, postalCode: "12552" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11248" },
    { lng: -77.16963, lat: 42.527075, postalCode: "14874" },
    { lng: -73.93459, lat: 40.670937, postalCode: "11213" },
    { lng: -75.81845, lat: 44.030204, postalCode: "13603" },
    { lng: -72.87181, lat: 40.794317, postalCode: "11967" },
    { lng: -73.76061, lat: 42.640129, postalCode: "12202" },
    { lng: -73.88176, lat: 40.943525, postalCode: "10701" },
    { lng: -76.22068, lat: 43.152017, postalCode: "13090" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10159" },
    { lng: -74.10546, lat: 43.722082, postalCode: "12856" },
    { lng: -76.6689, lat: 42.51432, postalCode: "14886" },
    { lng: -74.81213, lat: 44.755383, postalCode: "13697" },
    { lng: -78.474444, lat: 42.947259, postalCode: "14038" },
    { lng: -73.83819, lat: 40.842917, postalCode: "10461" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10055" },
    { lng: -72.84554, lat: 40.763634, postalCode: "11951" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11855" },
    { lng: -75.74488, lat: 42.206745, postalCode: "13851" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10130" },
    { lng: -73.89052, lat: 40.763015, postalCode: "11370" },
    { lng: -78.73768, lat: 42.627312, postalCode: "14025" },
    { lng: -73.982728, lat: 40.766429, postalCode: "10107" },
    { lng: -73.60493, lat: 41.269072, postalCode: "10518" },
    { lng: -75.4688, lat: 43.368235, postalCode: "13303" },
    { lng: -75.142771, lat: 43.524904, postalCode: "13494" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10131" },
    { lng: -78.85072, lat: 42.914307, postalCode: "14208" },
    { lng: -75.639586, lat: 42.112127, postalCode: "13826" },
    { lng: -74.84542, lat: 42.870799, postalCode: "13475" },
    { lng: -73.191789, lat: 40.810246, postalCode: "11760" },
    { lng: -78.312171, lat: 42.991173, postalCode: "14056" },
    { lng: -73.94893, lat: 40.769334, postalCode: "10162" },
    { lng: -76.01194, lat: 42.254154, postalCode: "13777" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11737" },
    { lng: -74.91667, lat: 42.302716, postalCode: "13753" },
    { lng: -74.345684, lat: 41.878801, postalCode: "12402" },
    { lng: -74.81186, lat: 41.48397, postalCode: "12737" },
    { lng: -73.79716, lat: 40.98845, postalCode: "10583" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10272" },
    { lng: -78.79494, lat: 43.023347, postalCode: "14228" },
    { lng: -74.521638, lat: 41.818926, postalCode: "12710" },
    { lng: -73.82216, lat: 40.688688, postalCode: "11419" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10129" },
    { lng: -73.82145, lat: 40.728614, postalCode: "11367" },
    { lng: -74.00424, lat: 40.655481, postalCode: "11232" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11690" },
    { lng: -77.949818, lat: 42.915297, postalCode: "14486" },
    { lng: -74.84685, lat: 43.053176, postalCode: "13365" },
    { lng: -76.55882, lat: 42.922273, postalCode: "13021" },
    { lng: -75.68087, lat: 43.236303, postalCode: "13308" },
    { lng: -73.81443, lat: 40.673138, postalCode: "11420" },
    { lng: -76.451147, lat: 42.991112, postalCode: "13153" },
    { lng: -73.6476, lat: 42.24862, postalCode: "12565" },
    { lng: -73.66465, lat: 42.731628, postalCode: "12180" },
    { lng: -74.66771, lat: 43.807314, postalCode: "13436" },
    { lng: -75.480342, lat: 43.818705, postalCode: "13345" },
    { lng: -73.788241, lat: 42.444198, postalCode: "12124" },
    { lng: -77.137642, lat: 43.235369, postalCode: "14449" },
    { lng: -75.50677, lat: 43.311975, postalCode: "13363" },
    { lng: -78.80047, lat: 42.968057, postalCode: "14226" },
    { lng: -79.01069, lat: 42.469165, postalCode: "14129" },
    { lng: -75.43828, lat: 43.654278, postalCode: "13473" },
    { lng: -73.73574, lat: 40.693538, postalCode: "11411" },
    { lng: -73.96763, lat: 42.393192, postalCode: "12176" },
    { lng: -77.93008, lat: 42.523195, postalCode: "14836" },
    { lng: -77.7836, lat: 43.030313, postalCode: "14546" },
    { lng: -73.81941, lat: 40.749463, postalCode: "11355" },
    { lng: -77.64162, lat: 43.147563, postalCode: "14611" },
    { lng: -78.830681, lat: 43.32684, postalCode: "14302" },
    { lng: -73.632673, lat: 40.621753, postalCode: "11592" },
    { lng: -73.97403, lat: 42.543326, postalCode: "12041" },
    { lng: -74.91372, lat: 42.411895, postalCode: "13757" },
    { lng: -76.878456, lat: 42.160717, postalCode: "14844" },
    { lng: -75.91128, lat: 42.870698, postalCode: "13051" },
    { lng: -74.00526, lat: 40.720666, postalCode: "10013" },
    { lng: -73.01317, lat: 40.929242, postalCode: "11766" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10160" },
    { lng: -78.46076, lat: 42.814667, postalCode: "14037" },
    { lng: -73.810373, lat: 42.716774, postalCode: "12212" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11597" },
    { lng: -78.98151, lat: 42.694598, postalCode: "14047" },
    { lng: -73.97772, lat: 41.207594, postalCode: "10993" },
    { lng: -73.48357, lat: 43.95037, postalCode: "12928" },
    { lng: -73.8555, lat: 43.128878, postalCode: "12833" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12248" },
    { lng: -73.84582, lat: 40.720535, postalCode: "11375" },
    { lng: -78.65425, lat: 42.286037, postalCode: "14731" },
    { lng: -73.51739, lat: 40.724883, postalCode: "11756" },
    { lng: -77.11844, lat: 42.173481, postalCode: "14870" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10213" },
    { lng: -74.925617, lat: 42.183045, postalCode: "13837" },
    { lng: -76.58618, lat: 42.212809, postalCode: "14889" },
    { lng: -74.510918, lat: 41.365286, postalCode: "10933" },
    { lng: -74.925617, lat: 42.183045, postalCode: "13847" },
    { lng: -76.40029, lat: 43.32347, postalCode: "13069" },
    { lng: -74.067014, lat: 42.039355, postalCode: "12447" },
    { lng: -73.43225, lat: 44.88821, postalCode: "12921" },
    { lng: -76.061552, lat: 44.139501, postalCode: "13632" },
    { lng: -76.18002, lat: 43.1835, postalCode: "13041" },
    { lng: -74.11551, lat: 40.63212, postalCode: "10310" },
    { lng: -73.89424, lat: 40.670874, postalCode: "11207" },
    { lng: -73.71123, lat: 43.703484, postalCode: "12815" },
    { lng: -73.79612, lat: 40.760522, postalCode: "11358" },
    { lng: -72.72356, lat: 40.824658, postalCode: "11941" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14272" },
    { lng: -73.88543, lat: 41.215541, postalCode: "10520" },
    { lng: -77.77733, lat: 42.798159, postalCode: "14454" },
    { lng: -78.42744, lat: 42.076574, postalCode: "14760" },
    { lng: -78.992658, lat: 42.691742, postalCode: "14112" },
    { lng: -74.7359, lat: 42.772775, postalCode: "13320" },
    { lng: -73.98702, lat: 44.787489, postalCode: "12955" },
    { lng: -74.2901, lat: 43.43305, postalCode: "12190" },
    { lng: -78.8835, lat: 42.498791, postalCode: "14034" },
    { lng: -74.34767, lat: 42.209414, postalCode: "12492" },
    { lng: -75.4988, lat: 42.90777, postalCode: "13402" },
    { lng: -74.18349, lat: 42.280261, postalCode: "12454" },
    { lng: -73.94337, lat: 42.814476, postalCode: "12305" },
    { lng: -73.51463, lat: 42.144585, postalCode: "12517" },
    { lng: -73.968036, lat: 40.761104, postalCode: "10155" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13260" },
    { lng: -74.32629, lat: 42.081269, postalCode: "12464" },
    { lng: -73.80953, lat: 41.041191, postalCode: "10607" },
    { lng: -76.07789, lat: 42.593363, postalCode: "13101" },
    { lng: -73.587, lat: 40.653935, postalCode: "11520" },
    { lng: -74.92694, lat: 41.480326, postalCode: "12719" },
    { lng: -74.27527, lat: 42.260634, postalCode: "12444" },
    { lng: -78.8631, lat: 43.001907, postalCode: "14150" },
    { lng: -76.83274, lat: 42.444243, postalCode: "14818" },
    { lng: -75.31502, lat: 42.626177, postalCode: "13411" },
    { lng: -72.91566, lat: 40.780058, postalCode: "11719" },
    { lng: -77.49687, lat: 42.933105, postalCode: "14475" },
    { lng: -74.00721, lat: 40.714754, postalCode: "10007" },
    { lng: -73.8972, lat: 41.537232, postalCode: "12524" },
    { lng: -73.96529, lat: 40.694214, postalCode: "11205" },
    { lng: -78.07934, lat: 42.977828, postalCode: "14143" },
    { lng: -76.02621, lat: 44.289833, postalCode: "13692" },
    { lng: -75.74488, lat: 42.206745, postalCode: "13763" },
    { lng: -76.13688, lat: 43.098917, postalCode: "13211" },
    { lng: -79.450523, lat: 42.187468, postalCode: "14722" },
    { lng: -77.20117, lat: 42.605481, postalCode: "14418" },
    { lng: -74.49646, lat: 43.158567, postalCode: "12032" },
    { lng: -73.667962, lat: 42.259623, postalCode: "12544" },
    { lng: -76.00876, lat: 43.028516, postalCode: "13066" },
    { lng: -78.82448, lat: 42.821008, postalCode: "14218" },
    { lng: -72.87824, lat: 40.89609, postalCode: "11961" },
    { lng: -73.91878, lat: 41.040623, postalCode: "10968" },
    { lng: -78.377974, lat: 42.062125, postalCode: "14788" },
    { lng: -75.52612, lat: 42.541733, postalCode: "13815" },
    { lng: -75.21942, lat: 43.419132, postalCode: "13301" },
    { lng: -74.10443, lat: 42.378679, postalCode: "12423" },
    { lng: -73.65036, lat: 42.176216, postalCode: "12521" },
    { lng: -73.69379, lat: 40.760128, postalCode: "11042" },
    { lng: -72.24505, lat: 40.947419, postalCode: "11975" },
    { lng: -73.748555, lat: 42.317154, postalCode: "12050" },
    { lng: -76.180886, lat: 43.870842, postalCode: "13651" },
    { lng: -74.16178, lat: 41.428269, postalCode: "10992" },
    { lng: -78.82717, lat: 42.790708, postalCode: "14219" },
    { lng: -76.99394, lat: 42.853039, postalCode: "14456" },
    { lng: -73.57293, lat: 41.483689, postalCode: "12563" },
    { lng: -78.02581, lat: 43.339304, postalCode: "14476" },
    { lng: -73.75994, lat: 40.746718, postalCode: "11364" },
    { lng: -74.31458, lat: 42.558318, postalCode: "12122" },
    { lng: -76.925846, lat: 42.429721, postalCode: "14876" },
    { lng: -75.387088, lat: 43.090027, postalCode: "13321" },
    { lng: -73.838035, lat: 42.692909, postalCode: "12222" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11405" },
    { lng: -75.35448, lat: 42.916235, postalCode: "13455" },
    { lng: -76.16406, lat: 43.020116, postalCode: "13207" },
    { lng: -74.84599, lat: 41.80854, postalCode: "12787" },
    { lng: -73.815204, lat: 44.145678, postalCode: "12997" },
    { lng: -73.70956, lat: 40.742944, postalCode: "11004" },
    { lng: -73.64452, lat: 40.846388, postalCode: "11579" },
    { lng: -73.40197, lat: 43.461692, postalCode: "12821" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12234" },
    { lng: -74.52273, lat: 41.690795, postalCode: "12763" },
    { lng: -77.58406, lat: 42.250187, postalCode: "14823" },
    { lng: -73.55608, lat: 40.88889, postalCode: "11765" },
    { lng: -78.47303, lat: 42.474779, postalCode: "14042" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11027" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10157" },
    { lng: -73.32257, lat: 40.733398, postalCode: "11703" },
    { lng: -73.44559, lat: 42.57612, postalCode: "12169" },
    { lng: -73.9541, lat: 40.776777, postalCode: "10028" },
    { lng: -75.61558, lat: 42.687757, postalCode: "13464" },
    { lng: -73.76824, lat: 40.714261, postalCode: "11423" },
    { lng: -73.95946, lat: 42.638481, postalCode: "12186" },
    { lng: -73.87509, lat: 40.772072, postalCode: "11371" },
    { lng: -77.71925, lat: 42.576771, postalCode: "14437" },
    { lng: -73.764296, lat: 42.724978, postalCode: "12128" },
    { lng: -74.611711, lat: 42.937863, postalCode: "13410" },
    { lng: -76.872442, lat: 42.683451, postalCode: "14588" },
    { lng: -74.00254, lat: 40.6788, postalCode: "11231" },
    { lng: -73.6374, lat: 40.636286, postalCode: "11572" },
    { lng: -78.9372, lat: 42.718308, postalCode: "14085" },
    { lng: -74.35931, lat: 41.332519, postalCode: "10921" },
    { lng: -73.97979, lat: 40.759291, postalCode: "10112" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10197" },
    { lng: -72.31074, lat: 40.99628, postalCode: "11963" },
    { lng: -74.08991, lat: 40.60787, postalCode: "10304" },
    { lng: -74.03078, lat: 42.260709, postalCode: "12473" },
    { lng: -73.48631, lat: 40.740662, postalCode: "11714" },
    { lng: -77.63705, lat: 42.129806, postalCode: "14839" },
    { lng: -75.37656, lat: 43.049536, postalCode: "13323" },
    { lng: -73.78643, lat: 40.915845, postalCode: "10801" },
    { lng: -77.39457, lat: 42.628515, postalCode: "14512" },
    { lng: -75.721931, lat: 42.583518, postalCode: "13758" },
    { lng: -77.08762, lat: 42.383304, postalCode: "14815" },
    { lng: -73.77392, lat: 40.763177, postalCode: "11361" },
    { lng: -76.8252, lat: 43.234753, postalCode: "14590" },
    { lng: -75.13664, lat: 44.268403, postalCode: "13639" },
    { lng: -76.090424, lat: 44.035703, postalCode: "13657" },
    { lng: -77.04793, lat: 42.140881, postalCode: "14830" },
    { lng: -73.82614, lat: 40.924195, postalCode: "10552" },
    { lng: -73.12748, lat: 40.906174, postalCode: "11790" },
    { lng: -77.68455, lat: 42.060154, postalCode: "14877" },
    { lng: -73.96545, lat: 44.948894, postalCode: "12923" },
    { lng: -73.41569, lat: 43.74531, postalCode: "12861" },
    { lng: -76.62939, lat: 42.667102, postalCode: "13081" },
    { lng: -73.76206, lat: 43.758733, postalCode: "12808" },
    { lng: -75.26149, lat: 44.314998, postalCode: "13635" },
    { lng: -77.57637, lat: 42.569876, postalCode: "14572" },
    { lng: -72.75673, lat: 40.806287, postalCode: "11940" },
    { lng: -74.52106, lat: 41.725808, postalCode: "12435" },
    { lng: -74.29143, lat: 42.105611, postalCode: "12416" },
    { lng: -73.91824, lat: 44.715159, postalCode: "12952" },
    { lng: -73.72852, lat: 40.627386, postalCode: "11516" },
    { lng: -75.480342, lat: 43.818705, postalCode: "13610" },
    { lng: -78.64246, lat: 42.834159, postalCode: "14059" },
    { lng: -74.60211, lat: 44.927856, postalCode: "12914" },
    { lng: -75.776918, lat: 43.236407, postalCode: "13123" },
    { lng: -76.91443, lat: 43.197378, postalCode: "14516" },
    { lng: -76.10983, lat: 42.883503, postalCode: "13084" },
    { lng: -74.15306, lat: 42.167622, postalCode: "12427" },
    { lng: -73.712415, lat: 43.12943, postalCode: "12830" },
    { lng: -76.200448, lat: 43.431311, postalCode: "13064" },
    { lng: -73.70223, lat: 40.700529, postalCode: "11003" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11805" },
    { lng: -77.09424, lat: 43.052126, postalCode: "14513" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11254" },
    { lng: -73.905271, lat: 42.694777, postalCode: "12085" },
    { lng: -74.54558, lat: 42.249541, postalCode: "12421" },
    { lng: -74.07805, lat: 41.641207, postalCode: "12568" },
    { lng: -75.84263, lat: 44.277365, postalCode: "13675" },
    { lng: -73.26044, lat: 43.500423, postalCode: "12837" },
    { lng: -75.96686, lat: 42.125725, postalCode: "13790" },
    { lng: -74.01574, lat: 40.641436, postalCode: "11220" },
    { lng: -73.66576, lat: 42.975154, postalCode: "12170" },
    { lng: -73.958727, lat: 41.254823, postalCode: "10596" },
    { lng: -73.40591, lat: 44.259479, postalCode: "12936" },
    { lng: -76.22072, lat: 43.041566, postalCode: "13219" },
    { lng: -77.42022, lat: 42.980323, postalCode: "14564" },
    { lng: -73.74782, lat: 40.728235, postalCode: "11427" },
    { lng: -78.14308, lat: 42.192384, postalCode: "14739" },
    { lng: -73.72745, lat: 41.549525, postalCode: "12582" },
    { lng: -73.856116, lat: 41.773053, postalCode: "12938" },
    { lng: -73.92656, lat: 42.475609, postalCode: "12046" },
    { lng: -73.79567, lat: 40.650519, postalCode: "11430" },
    { lng: -73.7346, lat: 40.952892, postalCode: "10543" },
    { lng: -78.71564, lat: 43.326155, postalCode: "14028" },
    { lng: -71.9506, lat: 41.03831, postalCode: "11954" },
    { lng: -73.38318, lat: 43.547822, postalCode: "12887" },
    { lng: -73.48959, lat: 40.679752, postalCode: "11783" },
    { lng: -79.24459, lat: 42.09179, postalCode: "14701" },
    { lng: -74.90438, lat: 41.66023, postalCode: "12720" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12252" },
    { lng: -73.94917, lat: 40.762174, postalCode: "10044" },
    { lng: -73.976794, lat: 40.751933, postalCode: "10168" },
    { lng: -78.140343, lat: 42.4777, postalCode: "14745" },
    { lng: -76.29288, lat: 44.114183, postalCode: "13618" },
    { lng: -74.37883, lat: 42.953913, postalCode: "12068" },
    { lng: -75.27716, lat: 43.110619, postalCode: "13495" },
    { lng: -75.724015, lat: 42.216968, postalCode: "13133" },
    { lng: -74.7857, lat: 41.920201, postalCode: "12758" },
    { lng: -73.973971, lat: 40.763419, postalCode: "10151" },
    { lng: -74.484249, lat: 41.485227, postalCode: "10932" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10126" },
    { lng: -73.88036, lat: 40.84095, postalCode: "10460" },
    { lng: -77.50488, lat: 42.762059, postalCode: "14471" },
    { lng: -73.91993, lat: 40.703355, postalCode: "11237" },
    { lng: -73.97785, lat: 40.760915, postalCode: "10103" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14694" },
    { lng: -73.88689, lat: 43.182144, postalCode: "12859" },
    { lng: -74.89149, lat: 42.902922, postalCode: "13361" },
    { lng: -73.94455, lat: 41.119788, postalCode: "10989" },
    { lng: -75.28123, lat: 44.744462, postalCode: "13658" },
    { lng: -73.8612, lat: 40.920273, postalCode: "10704" },
    { lng: -78.830681, lat: 43.32684, postalCode: "14095" },
    { lng: -73.90771, lat: 40.815559, postalCode: "10455" },
    { lng: -73.94033, lat: 41.260283, postalCode: "10511" },
    { lng: -77.47257, lat: 42.560553, postalCode: "14808" },
    { lng: -75.44761, lat: 43.90707, postalCode: "13620" },
    { lng: -76.15144, lat: 43.299689, postalCode: "13036" },
    { lng: -74.04504, lat: 41.116795, postalCode: "10977" },
    { lng: -73.50948, lat: 43.642332, postalCode: "12841" },
    { lng: -73.744555, lat: 44.706561, postalCode: "12924" },
    { lng: -74.72961, lat: 41.864326, postalCode: "12768" },
    { lng: -73.97609, lat: 40.755345, postalCode: "10177" },
    { lng: -76.14871, lat: 43.354706, postalCode: "13076" },
    { lng: -77.66183, lat: 43.256462, postalCode: "14612" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10099" },
    { lng: -78.16153, lat: 42.619549, postalCode: "14066" },
    { lng: -73.790822, lat: 40.722008, postalCode: "11439" },
    { lng: -76.13966, lat: 43.225081, postalCode: "13029" },
    { lng: -73.980438, lat: 40.765243, postalCode: "10106" },
    { lng: -74.25801, lat: 44.360566, postalCode: "12945" },
    { lng: -75.64678, lat: 43.079635, postalCode: "13421" },
    { lng: -73.94879, lat: 40.826201, postalCode: "10031" },
    { lng: -76.621747, lat: 43.089111, postalCode: "13113" },
    { lng: -74.71252, lat: 41.562141, postalCode: "12777" },
    { lng: -73.40651, lat: 43.345875, postalCode: "12838" },
    { lng: -74.925617, lat: 42.183045, postalCode: "13806" },
    { lng: -75.72307, lat: 43.209147, postalCode: "13157" },
    { lng: -73.84506, lat: 40.96555, postalCode: "10710" },
    { lng: -75.14627, lat: 42.182237, postalCode: "13856" },
    { lng: -74.317958, lat: 44.552725, postalCode: "12939" },
    { lng: -73.78734, lat: 40.696538, postalCode: "11433" },
    { lng: -75.048502, lat: 42.775805, postalCode: "13457" },
    { lng: -73.85172, lat: 43.248892, postalCode: "12822" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11424" },
    { lng: -74.93164, lat: 41.522825, postalCode: "12792" },
    { lng: -73.3001, lat: 40.713459, postalCode: "11795" },
    { lng: -72.84664, lat: 40.80481, postalCode: "11950" },
    { lng: -78.8745, lat: 42.968707, postalCode: "14217" },
    { lng: -74.3436, lat: 43.069562, postalCode: "12078" },
    { lng: -73.815204, lat: 44.145678, postalCode: "12879" },
    { lng: -73.986415, lat: 40.679845, postalCode: "11517" },
    { lng: -76.200448, lat: 43.431311, postalCode: "13093" },
    { lng: -73.641076, lat: 42.243991, postalCode: "12593" },
    { lng: -76.197701, lat: 43.02143, postalCode: "13261" },
    { lng: -76.75307, lat: 42.01359, postalCode: "14894" },
    { lng: -78.15151, lat: 43.091886, postalCode: "14058" },
    { lng: -73.65812, lat: 43.888754, postalCode: "12858" },
    { lng: -75.89354, lat: 43.743998, postalCode: "13659" },
    { lng: -74.06797, lat: 41.92674, postalCode: "12443" },
    { lng: -72.35093, lat: 41.07815, postalCode: "11965" },
    { lng: -74.64558, lat: 41.436732, postalCode: "12746" },
    { lng: -77.13413, lat: 42.03128, postalCode: "14858" },
    { lng: -73.16115, lat: 40.643951, postalCode: "11770" },
    { lng: -73.00213, lat: 40.770898, postalCode: "11772" },
    { lng: -74.45715, lat: 42.482123, postalCode: "12131" },
    { lng: -74.8272, lat: 41.6896, postalCode: "12749" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10113" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10132" },
    { lng: -73.88107, lat: 41.67086, postalCode: "12603" },
    { lng: -74.008, lat: 42.307982, postalCode: "12413" },
    { lng: -77.59721, lat: 43.223213, postalCode: "14617" },
    { lng: -73.84741, lat: 41.176837, postalCode: "10562" },
    { lng: -75.85223, lat: 42.110927, postalCode: "13904" },
    { lng: -78.52113, lat: 42.534854, postalCode: "14134" },
    { lng: -74.18764, lat: 41.691524, postalCode: "12525" },
    { lng: -74.04072, lat: 41.521593, postalCode: "12550" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10060" },
    { lng: -73.979782, lat: 40.754305, postalCode: "10175" },
    { lng: -73.72147, lat: 43.996303, postalCode: "12855" },
    { lng: -74.2772, lat: 44.813419, postalCode: "12953" },
    { lng: -73.668274, lat: 40.665202, postalCode: "11562" },
    { lng: -77.60843, lat: 43.048663, postalCode: "14467" },
    { lng: -75.76113, lat: 44.021094, postalCode: "13638" },
    { lng: -73.840908, lat: 40.851549, postalCode: "10499" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11707" },
    { lng: -76.750888, lat: 42.147285, postalCode: "14925" },
    { lng: -74.15136, lat: 42.357579, postalCode: "12418" },
    { lng: -73.96198, lat: 41.505993, postalCode: "12508" },
    { lng: -74.15321, lat: 43.972307, postalCode: "12852" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10081" },
    { lng: -74.98161, lat: 41.657225, postalCode: "12727" },
    { lng: -74.252254, lat: 44.805681, postalCode: "12995" },
    { lng: -76.35283, lat: 42.505075, postalCode: "13068" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11425" },
    { lng: -78.56868, lat: 43.20258, postalCode: "14067" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11774" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11247" },
    { lng: -73.24258, lat: 40.888497, postalCode: "11754" },
    { lng: -73.99289, lat: 40.750824, postalCode: "10119" },
    { lng: -76.80197, lat: 42.822662, postalCode: "13065" },
    { lng: -74.48935, lat: 41.295568, postalCode: "10969" },
    { lng: -73.61328, lat: 40.750132, postalCode: "11514" },
    { lng: -76.66055, lat: 43.049797, postalCode: "13140" },
    { lng: -73.37442, lat: 40.690049, postalCode: "11757" },
    { lng: -74.06054, lat: 44.901543, postalCode: "12920" },
    { lng: -75.05192, lat: 42.469761, postalCode: "13820" },
    { lng: -78.057252, lat: 43.041606, postalCode: "14557" },
    { lng: -76.25874, lat: 42.120274, postalCode: "13827" },
    { lng: -77.6352, lat: 43.087613, postalCode: "14623" },
    { lng: -79.1364, lat: 42.316386, postalCode: "14723" },
    { lng: -73.45257, lat: 42.323565, postalCode: "12017" },
    { lng: -78.016139, lat: 42.260012, postalCode: "14776" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11390" },
    { lng: -73.5404, lat: 44.322102, postalCode: "12950" },
    { lng: -73.94006, lat: 42.281117, postalCode: "12451" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11696" },
    { lng: -73.90355, lat: 42.912175, postalCode: "12027" },
    { lng: -78.90884, lat: 43.237855, postalCode: "14131" },
    { lng: -77.063876, lat: 42.796578, postalCode: "14463" },
    { lng: -73.58921, lat: 41.738853, postalCode: "12522" },
    { lng: -74.53485, lat: 44.731571, postalCode: "12930" },
    { lng: -74.14513, lat: 40.606337, postalCode: "10314" },
    { lng: -73.49006, lat: 42.899334, postalCode: "12094" },
    { lng: -76.33901, lat: 43.165134, postalCode: "13027" },
    { lng: -76.49685, lat: 42.449454, postalCode: "14850" },
    { lng: -78.00361, lat: 42.724075, postalCode: "14530" },
    { lng: -73.00501, lat: 40.884895, postalCode: "11727" },
    { lng: -76.097288, lat: 42.080494, postalCode: "13737" },
    { lng: -74.85053, lat: 44.220971, postalCode: "12927" },
    { lng: -73.34095, lat: 43.452517, postalCode: "12854" },
    { lng: -75.53429, lat: 42.232991, postalCode: "13730" },
    { lng: -75.99069, lat: 43.560754, postalCode: "13144" },
    { lng: -73.82956, lat: 42.46915, postalCode: "12143" },
    { lng: -75.1659, lat: 43.242724, postalCode: "13304" },
    { lng: -76.13931, lat: 43.128534, postalCode: "13212" },
    { lng: -79.412233, lat: 42.155693, postalCode: "14785" },
    { lng: -73.53703, lat: 42.884779, postalCode: "12185" },
    { lng: -78.744053, lat: 42.938258, postalCode: "14241" },
    { lng: -73.80527, lat: 43.538744, postalCode: "12885" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11854" },
    { lng: -73.92637, lat: 40.691234, postalCode: "11221" },
    { lng: -77.045288, lat: 43.348369, postalCode: "14563" },
    { lng: -73.66517, lat: 40.588154, postalCode: "11561" },
    { lng: -73.8799, lat: 40.956546, postalCode: "10703" },
    { lng: -75.25901, lat: 42.236762, postalCode: "13839" },
    { lng: -75.45537, lat: 44.319897, postalCode: "13642" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14643" },
    { lng: -74.24724, lat: 41.912356, postalCode: "12461" },
    { lng: -74.812, lat: 41.65974, postalCode: "12778" },
    { lng: -74.16718, lat: 41.126395, postalCode: "10931" },
    { lng: -77.84125, lat: 42.442261, postalCode: "14822" },
    { lng: -73.89916, lat: 40.867107, postalCode: "10468" },
    { lng: -74.925617, lat: 42.183045, postalCode: "13860" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11739" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11969" },
    { lng: -77.65536, lat: 42.331062, postalCode: "14843" },
    { lng: -78.887094, lat: 42.768413, postalCode: "14110" },
    { lng: -73.55599, lat: 42.437594, postalCode: "12136" },
    { lng: -73.715016, lat: 40.75945, postalCode: "11302" },
    { lng: -74.50677, lat: 41.592087, postalCode: "12790" },
    { lng: -74.33926, lat: 42.321453, postalCode: "12407" },
    { lng: -74.86633, lat: 44.501065, postalCode: "13687" },
    { lng: -79.042477, lat: 43.199494, postalCode: "14144" },
    { lng: -73.96016, lat: 41.062779, postalCode: "10913" },
    { lng: -78.04393, lat: 43.221689, postalCode: "14470" },
    { lng: -74.16843, lat: 44.324736, postalCode: "12983" },
    { lng: -78.96249, lat: 43.091106, postalCode: "14304" },
    { lng: -75.74261, lat: 43.377083, postalCode: "13316" },
    { lng: -78.68472, lat: 42.655098, postalCode: "14033" },
    { lng: -78.8779, lat: 42.917657, postalCode: "14222" },
    { lng: -79.51361, lat: 42.043339, postalCode: "14767" },
    { lng: -77.29673, lat: 42.525335, postalCode: "14873" },
    { lng: -73.76924, lat: 42.705345, postalCode: "12211" },
    { lng: -73.37553, lat: 40.821698, postalCode: "11746" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10196" },
    { lng: -74.130201, lat: 42.6464, postalCode: "12103" },
    { lng: -74.572, lat: 42.63119, postalCode: "12149" },
    { lng: -76.026459, lat: 42.89274, postalCode: "13138" },
    { lng: -78.43429, lat: 42.720756, postalCode: "14145" },
    { lng: -73.86657, lat: 42.916343, postalCode: "12019" },
    { lng: -76.483703, lat: 42.447373, postalCode: "14853" },
    { lng: -75.518569, lat: 42.980334, postalCode: "13362" },
    { lng: -73.14303, lat: 40.842962, postalCode: "11767" },
    { lng: -77.02311, lat: 42.757486, postalCode: "14415" },
    { lng: -77.607049, lat: 43.158013, postalCode: "14064" },
    { lng: -77.1321, lat: 42.815171, postalCode: "14561" },
    { lng: -73.73903, lat: 40.708833, postalCode: "11429" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11594" },
    { lng: -75.192865, lat: 44.533125, postalCode: "13645" },
    { lng: -73.95729, lat: 42.270559, postalCode: "12482" },
    { lng: -78.022364, lat: 42.692874, postalCode: "14549" },
    { lng: -73.93994, lat: 43.795443, postalCode: "12857" },
    { lng: -73.74451, lat: 41.045387, postalCode: "10604" },
    { lng: -74.354699, lat: 41.387822, postalCode: "10912" },
    { lng: -73.976238, lat: 40.754591, postalCode: "10166" },
    { lng: -74.7361, lat: 41.798476, postalCode: "12754" },
    { lng: -72.79736, lat: 40.855861, postalCode: "11949" },
    { lng: -79.30958, lat: 42.120512, postalCode: "14742" },
    { lng: -78.2023, lat: 43.244034, postalCode: "14411" },
    { lng: -73.89584, lat: 44.865972, postalCode: "12934" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11531" },
    { lng: -74.20164, lat: 42.390291, postalCode: "12422" },
    { lng: -77.56683, lat: 42.145265, postalCode: "14831" },
    { lng: -73.44681, lat: 40.68505, postalCode: "11762" },
    { lng: -73.31153, lat: 43.376197, postalCode: "12832" },
    { lng: -73.94147, lat: 41.248359, postalCode: "10548" },
    { lng: -73.80717, lat: 40.902221, postalCode: "10803" },
    { lng: -74.17141, lat: 41.246428, postalCode: "10975" },
    { lng: -73.820321, lat: 40.997751, postalCode: "10586" },
    { lng: -76.53404, lat: 42.023442, postalCode: "14892" },
    { lng: -74.4265, lat: 41.564426, postalCode: "12721" },
    { lng: -75.68091, lat: 43.165514, postalCode: "13054" },
    { lng: -73.70475, lat: 40.7232, postalCode: "11001" },
    { lng: -78.684684, lat: 42.270112, postalCode: "14778" },
    { lng: -74.84003, lat: 41.44515, postalCode: "12770" },
    { lng: -75.29253, lat: 44.168224, postalCode: "13648" },
    { lng: -74.66452, lat: 42.368357, postalCode: "13788" },
    { lng: -72.637078, lat: 40.922326, postalCode: "11973" },
    { lng: -72.82092, lat: 40.805789, postalCode: "11955" },
    { lng: -73.8215, lat: 40.909288, postalCode: "10553" },
    { lng: -78.407, lat: 42.203691, postalCode: "14743" },
    { lng: -75.28435, lat: 43.162119, postalCode: "13403" },
    { lng: -73.870779, lat: 40.651378, postalCode: "11451" },
    { lng: -72.67835, lat: 40.81819, postalCode: "11977" },
    { lng: -76.83639, lat: 42.181584, postalCode: "14845" },
    { lng: -73.10666, lat: 40.932053, postalCode: "11733" },
    { lng: -78.38309, lat: 42.661838, postalCode: "14082" },
    { lng: -73.86845, lat: 40.830409, postalCode: "10472" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12243" },
    { lng: -74.66213, lat: 44.784144, postalCode: "12967" },
    { lng: -72.63449, lat: 40.816142, postalCode: "11978" },
    { lng: -73.934577, lat: 42.108689, postalCode: "12490" },
    { lng: -72.47505, lat: 40.991836, postalCode: "11956" },
    { lng: -74.46299, lat: 44.220344, postalCode: "12986" },
    { lng: -77.42392, lat: 42.065376, postalCode: "14898" },
    { lng: -78.15948, lat: 42.739447, postalCode: "14569" },
    { lng: -79.30101, lat: 42.343265, postalCode: "14718" },
    { lng: -74.60322, lat: 41.437427, postalCode: "12739" },
    { lng: -75.46547, lat: 42.680051, postalCode: "13460" },
    { lng: -77.77793, lat: 42.258793, postalCode: "14803" },
    { lng: -74.94498, lat: 43.707331, postalCode: "13420" },
    { lng: -73.743714, lat: 41.759905, postalCode: "12604" },
    { lng: -73.968183, lat: 41.552793, postalCode: "12512" },
    { lng: -76.14747, lat: 43.074566, postalCode: "13208" },
    { lng: -78.61789, lat: 42.035792, postalCode: "14753" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10203" },
    { lng: -78.09375, lat: 42.669499, postalCode: "14550" },
    { lng: -73.92585, lat: 41.011464, postalCode: "10964" },
    { lng: -73.50207, lat: 42.525531, postalCode: "12062" },
    { lng: -73.92853, lat: 40.858704, postalCode: "10040" },
    { lng: -75.05323, lat: 42.344248, postalCode: "13846" },
    { lng: -76.11416, lat: 43.415157, postalCode: "13131" },
    { lng: -73.97864, lat: 40.727093, postalCode: "10009" },
    { lng: -73.9314, lat: 41.440695, postalCode: "10516" },
    { lng: -77.48709, lat: 43.111663, postalCode: "14445" },
    { lng: -74.657, lat: 41.679152, postalCode: "12751" },
    { lng: -74.15997, lat: 42.518386, postalCode: "12147" },
    { lng: -78.28649, lat: 42.213216, postalCode: "14727" },
    { lng: -75.90347, lat: 43.842808, postalCode: "13682" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12231" },
    { lng: -73.62561, lat: 40.813187, postalCode: "11548" },
    { lng: -76.90521, lat: 42.048032, postalCode: "14871" },
    { lng: -77.026768, lat: 42.399371, postalCode: "14887" },
    { lng: -75.97716, lat: 43.499843, postalCode: "13302" },
    { lng: -74.97995, lat: 41.815316, postalCode: "12766" },
    { lng: -79.27912, lat: 42.105907, postalCode: "14720" },
    { lng: -73.68512, lat: 41.265478, postalCode: "10536" },
    { lng: -74.26072, lat: 41.35648, postalCode: "10918" },
    { lng: -73.51802, lat: 42.317959, postalCode: "12165" },
    { lng: -78.64273, lat: 42.601635, postalCode: "14069" },
    { lng: -74.07503, lat: 40.599021, postalCode: "10305" },
    { lng: -76.9876, lat: 43.269332, postalCode: "14555" },
    { lng: -78.86612, lat: 42.913257, postalCode: "14209" },
    { lng: -73.78005, lat: 40.780386, postalCode: "11360" },
    { lng: -73.78922, lat: 41.132367, postalCode: "10570" },
    { lng: -78.61829, lat: 42.554206, postalCode: "14055" },
    { lng: -75.701639, lat: 43.078461, postalCode: "13163" },
    { lng: -75.34971, lat: 43.743932, postalCode: "13343" },
    { lng: -74.21069, lat: 41.197123, postalCode: "10987" },
    { lng: -74.09679, lat: 44.711622, postalCode: "12969" },
    { lng: -74.18601, lat: 42.749499, postalCode: "12053" },
    { lng: -73.10081, lat: 40.731954, postalCode: "11796" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11582" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10256" },
    { lng: -75.81219, lat: 42.0553, postalCode: "13748" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12261" },
    { lng: -72.27511, lat: 41.151826, postalCode: "11957" },
    { lng: -73.831698, lat: 40.781703, postalCode: "11351" },
    { lng: -76.202658, lat: 43.775216, postalCode: "13698" },
    { lng: -73.96996, lat: 41.097529, postalCode: "10994" },
    { lng: -73.93147, lat: 40.762012, postalCode: "11106" },
    { lng: -74.04523, lat: 41.192394, postalCode: "10970" },
    { lng: -73.970812, lat: 42.614852, postalCode: "12255" },
    { lng: -78.40345, lat: 43.074578, postalCode: "14013" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10090" },
    { lng: -79.66612, lat: 42.066681, postalCode: "14724" },
    { lng: -73.60415, lat: 40.823957, postalCode: "11545" },
    { lng: -78.184813, lat: 42.998052, postalCode: "14003" },
    { lng: -78.247413, lat: 42.474283, postalCode: "14029" },
    { lng: -78.88297, lat: 43.140056, postalCode: "14132" },
    { lng: -73.54076, lat: 41.256639, postalCode: "10590" },
    { lng: -74.14822, lat: 43.21892, postalCode: "12134" },
    { lng: -78.53494, lat: 42.640351, postalCode: "14080" },
    { lng: -77.91991, lat: 43.319027, postalCode: "14464" },
    { lng: -72.4723, lat: 41.012868, postalCode: "11935" },
    { lng: -78.74466, lat: 42.881372, postalCode: "14227" },
    { lng: -73.64259, lat: 40.758283, postalCode: "11596" },
    { lng: -74.23466, lat: 43.146907, postalCode: "12117" },
    { lng: -73.945032, lat: 40.645099, postalCode: "11255" },
    { lng: -73.71588, lat: 41.037203, postalCode: "10577" },
    { lng: -73.90347, lat: 40.900819, postalCode: "10471" },
    { lng: -73.933259, lat: 41.520249, postalCode: "12527" },
    { lng: -78.86152, lat: 42.884008, postalCode: "14204" },
    { lng: -74.57064, lat: 41.648888, postalCode: "12738" },
    { lng: -73.36516, lat: 40.752098, postalCode: "11798" },
    { lng: -73.95985, lat: 44.292147, postalCode: "12946" },
    { lng: -73.977182, lat: 40.780751, postalCode: "10277" },
    { lng: -77.141357, lat: 42.178527, postalCode: "14827" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14664" },
    { lng: -74.02105, lat: 41.208327, postalCode: "10984" },
    { lng: -75.688223, lat: 43.267243, postalCode: "13401" },
    { lng: -76.62459, lat: 42.043018, postalCode: "14825" },
    { lng: -73.90452, lat: 42.223002, postalCode: "12414" },
    { lng: -73.75141, lat: 40.670138, postalCode: "11413" },
    { lng: -73.601772, lat: 40.754757, postalCode: "11588" },
    { lng: -78.37872, lat: 42.891116, postalCode: "14040" },
    { lng: -76.54806, lat: 42.116615, postalCode: "14859" },
    { lng: -73.89271, lat: 42.434883, postalCode: "12087" },
    { lng: -77.684264, lat: 43.286024, postalCode: "14692" },
    { lng: -73.72108, lat: 40.732239, postalCode: "11426" },
    { lng: -79.32805, lat: 42.479471, postalCode: "14048" },
    { lng: -75.84778, lat: 42.837642, postalCode: "13122" },
    { lng: -78.82355, lat: 42.907358, postalCode: "14211" },
    { lng: -74.74685, lat: 44.4987, postalCode: "13672" },
    { lng: -74.24712, lat: 41.520396, postalCode: "12549" },
    { lng: -84.73533, lat: 41.18312, postalCode: "45813" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43682" },
    { lng: -82.62314, lat: 39.387214, postalCode: "43152" },
    { lng: -84.24898, lat: 39.359268, postalCode: "45034" },
    { lng: -81.58536, lat: 41.043496, postalCode: "44322" },
    { lng: -80.77271, lat: 41.146334, postalCode: "44440" },
    { lng: -84.24755, lat: 38.957385, postalCode: "45157" },
    { lng: -83.5619, lat: 41.603797, postalCode: "43460" },
    { lng: -84.10262, lat: 39.765259, postalCode: "45431" },
    { lng: -83.365404, lat: 41.090712, postalCode: "44801" },
    { lng: -81.84506, lat: 39.077704, postalCode: "45743" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43199" },
    { lng: -83.24454, lat: 40.730593, postalCode: "43323" },
    { lng: -84.54428, lat: 39.849859, postalCode: "45338" },
    { lng: -84.39512, lat: 39.205109, postalCode: "45236" },
    { lng: -82.99772, lat: 41.00811, postalCode: "44818" },
    { lng: -82.98178, lat: 40.146873, postalCode: "43240" },
    { lng: -82.39503, lat: 39.376504, postalCode: "45654" },
    { lng: -81.47113, lat: 39.649854, postalCode: "45727" },
    { lng: -84.79165, lat: 39.998881, postalCode: "45332" },
    { lng: -83.64576, lat: 41.037325, postalCode: "45840" },
    { lng: -83.53853, lat: 40.413252, postalCode: "43358" },
    { lng: -81.34146, lat: 40.373604, postalCode: "44683" },
    { lng: -81.854005, lat: 41.316784, postalCode: "44149" },
    { lng: -81.44232, lat: 40.038503, postalCode: "43768" },
    { lng: -83.5802, lat: 39.162917, postalCode: "45133" },
    { lng: -81.28517, lat: 39.991056, postalCode: "43773" },
    { lng: -84.43574, lat: 41.586794, postalCode: "43570" },
    { lng: -84.47991, lat: 39.197109, postalCode: "45216" },
    { lng: -81.368284, lat: 40.811809, postalCode: "44735" },
    { lng: -80.73083, lat: 41.159574, postalCode: "44437" },
    { lng: -82.89063, lat: 39.944231, postalCode: "43227" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45401" },
    { lng: -83.76396, lat: 41.577428, postalCode: "43542" },
    { lng: -83.48845, lat: 40.973064, postalCode: "45890" },
    { lng: -83.64464, lat: 41.14062, postalCode: "45889" },
    { lng: -81.02187, lat: 40.686028, postalCode: "44607" },
    { lng: -81.968787, lat: 40.398274, postalCode: "43805" },
    { lng: -81.54119, lat: 41.319704, postalCode: "44067" },
    { lng: -81.80036, lat: 41.480881, postalCode: "44107" },
    { lng: -82.54555, lat: 39.843864, postalCode: "43157" },
    { lng: -80.74545, lat: 41.099008, postalCode: "44515" },
    { lng: -81.962539, lat: 39.961236, postalCode: "43702" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45268" },
    { lng: -84.48651, lat: 39.986011, postalCode: "45358" },
    { lng: -83.93098, lat: 40.713499, postalCode: "45850" },
    { lng: -82.909984, lat: 40.668497, postalCode: "43335" },
    { lng: -83.43643, lat: 39.892676, postalCode: "43140" },
    { lng: -80.54145, lat: 41.046606, postalCode: "44436" },
    { lng: -83.944909, lat: 39.918936, postalCode: "45319" },
    { lng: -80.88588, lat: 41.24168, postalCode: "44430" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45071" },
    { lng: -82.81378, lat: 39.204151, postalCode: "45673" },
    { lng: -81.520588, lat: 41.075955, postalCode: "44328" },
    { lng: -83.90215, lat: 38.87752, postalCode: "45121" },
    { lng: -81.249027, lat: 41.910676, postalCode: "44061" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45004" },
    { lng: -80.88695, lat: 39.759825, postalCode: "43915" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44237" },
    { lng: -82.465777, lat: 40.406113, postalCode: "43048" },
    { lng: -82.1691, lat: 41.459218, postalCode: "44052" },
    { lng: -81.240732, lat: 39.654386, postalCode: "43789" },
    { lng: -82.99675, lat: 40.173636, postalCode: "43035" },
    { lng: -83.8356, lat: 40.958861, postalCode: "45868" },
    { lng: -82.21842, lat: 39.950857, postalCode: "43740" },
    { lng: -84.6477, lat: 40.215278, postalCode: "45303" },
    { lng: -84.45707, lat: 39.228859, postalCode: "45215" },
    { lng: -82.23419, lat: 41.386533, postalCode: "44001" },
    { lng: -84.40163, lat: 39.904603, postalCode: "45354" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43681" },
    { lng: -82.87312, lat: 40.490756, postalCode: "43315" },
    { lng: -84.38348, lat: 39.15201, postalCode: "45227" },
    { lng: -82.85759, lat: 40.792804, postalCode: "44856" },
    { lng: -84.18763, lat: 40.464571, postalCode: "45306" },
    { lng: -84.33877, lat: 40.931687, postalCode: "45876" },
    { lng: -81.67125, lat: 41.493501, postalCode: "44115" },
    { lng: -81.63929, lat: 40.655909, postalCode: "44689" },
    { lng: -81.95726, lat: 40.800086, postalCode: "44691" },
    { lng: -81.68623, lat: 40.787827, postalCode: "44618" },
    { lng: -82.92824, lat: 39.958564, postalCode: "43209" },
    { lng: -83.082262, lat: 39.991729, postalCode: "43704" },
    { lng: -83.90705, lat: 40.507868, postalCode: "43331" },
    { lng: -81.36029, lat: 40.827659, postalCode: "44714" },
    { lng: -84.09306, lat: 39.74035, postalCode: "45432" },
    { lng: -80.93363, lat: 40.469171, postalCode: "43903" },
    { lng: -82.4959, lat: 39.364574, postalCode: "45622" },
    { lng: -81.31633, lat: 40.410604, postalCode: "44621" },
    { lng: -81.64879, lat: 40.503332, postalCode: "44681" },
    { lng: -82.05315, lat: 41.275919, postalCode: "44044" },
    { lng: -81.54071, lat: 40.722891, postalCode: "44662" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43653" },
    { lng: -81.44256, lat: 39.930047, postalCode: "43780" },
    { lng: -84.10429, lat: 40.144131, postalCode: "45326" },
    { lng: -84.38348, lat: 40.338583, postalCode: "45845" },
    { lng: -84.53067, lat: 39.104127, postalCode: "45203" },
    { lng: -82.56749, lat: 40.766056, postalCode: "44906" },
    { lng: -80.97601, lat: 40.099277, postalCode: "43972" },
    { lng: -82.96046, lat: 38.793335, postalCode: "45630" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43291" },
    { lng: -84.02364, lat: 39.880826, postalCode: "45341" },
    { lng: -82.86563, lat: 39.924213, postalCode: "43232" },
    { lng: -83.17383, lat: 39.501913, postalCode: "43115" },
    { lng: -81.57129, lat: 41.123588, postalCode: "44313" },
    { lng: -82.32798, lat: 39.482096, postalCode: "43127" },
    { lng: -81.256, lat: 39.551002, postalCode: "45789" },
    { lng: -84.229799, lat: 40.03997, postalCode: "45374" },
    { lng: -83.139341, lat: 40.56941, postalCode: "43307" },
    { lng: -84.194389, lat: 39.734409, postalCode: "45479" },
    { lng: -81.84575, lat: 41.475297, postalCode: "44116" },
    { lng: -81.73657, lat: 40.018953, postalCode: "43762" },
    { lng: -83.20937, lat: 40.531614, postalCode: "43322" },
    { lng: -84.44711, lat: 41.673094, postalCode: "43501" },
    { lng: -83.32313, lat: 41.390878, postalCode: "43431" },
    { lng: -80.54601, lat: 40.838456, postalCode: "44413" },
    { lng: -83.18305, lat: 40.470755, postalCode: "43342" },
    { lng: -80.84292, lat: 40.105279, postalCode: "43905" },
    { lng: -81.003619, lat: 40.521924, postalCode: "44631" },
    { lng: -80.802854, lat: 41.017082, postalCode: "44501" },
    { lng: -84.15319, lat: 39.082894, postalCode: "45103" },
    { lng: -81.63222, lat: 41.233722, postalCode: "44286" },
    { lng: -83.63856, lat: 40.790676, postalCode: "45836" },
    { lng: -82.69744, lat: 41.601343, postalCode: "43438" },
    { lng: -82.55774, lat: 38.438617, postalCode: "45680" },
    { lng: -81.782532, lat: 40.622284, postalCode: "44660" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44191" },
    { lng: -83.95045, lat: 39.184985, postalCode: "45118" },
    { lng: -82.94232, lat: 40.952927, postalCode: "44825" },
    { lng: -82.76098, lat: 39.651028, postalCode: "43102" },
    { lng: -84.013674, lat: 41.419878, postalCode: "43510" },
    { lng: -82.00492, lat: 39.949377, postalCode: "43701" },
    { lng: -83.95416, lat: 40.123455, postalCode: "43072" },
    { lng: -81.6753, lat: 41.50988, postalCode: "44114" },
    { lng: -84.26309, lat: 40.438744, postalCode: "45336" },
    { lng: -82.87708, lat: 41.069216, postalCode: "44807" },
    { lng: -82.11317, lat: 40.596018, postalCode: "44661" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44198" },
    { lng: -84.08219, lat: 40.0659, postalCode: "45312" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45274" },
    { lng: -81.61948, lat: 41.144661, postalCode: "44333" },
    { lng: -83.75693, lat: 38.671655, postalCode: "45101" },
    { lng: -82.797, lat: 40.720869, postalCode: "44833" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44238" },
    { lng: -81.70889, lat: 39.273206, postalCode: "45742" },
    { lng: -83.02856, lat: 39.958664, postalCode: "43222" },
    { lng: -81.009999, lat: 39.717086, postalCode: "43752" },
    { lng: -80.64962, lat: 41.100296, postalCode: "44503" },
    { lng: -81.19164, lat: 39.628144, postalCode: "45734" },
    { lng: -83.2253, lat: 41.425328, postalCode: "43442" },
    { lng: -81.8164, lat: 39.419647, postalCode: "45713" },
    { lng: -84.112031, lat: 41.096421, postalCode: "45848" },
    { lng: -83.06986, lat: 40.047273, postalCode: "43220" },
    { lng: -84.52245, lat: 40.669102, postalCode: "45862" },
    { lng: -82.22755, lat: 38.825478, postalCode: "45631" },
    { lng: -80.56995, lat: 41.759503, postalCode: "44082" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45254" },
    { lng: -83.29921, lat: 40.82732, postalCode: "43351" },
    { lng: -83.92528, lat: 40.436172, postalCode: "43333" },
    { lng: -83.12173, lat: 40.589747, postalCode: "43302" },
    { lng: -83.58093, lat: 39.721965, postalCode: "43153" },
    { lng: -84.2051, lat: 39.889356, postalCode: "45377" },
    { lng: -82.65598, lat: 40.888988, postalCode: "44875" },
    { lng: -80.88913, lat: 40.008421, postalCode: "43928" },
    { lng: -84.08192, lat: 39.710787, postalCode: "45430" },
    { lng: -82.61865, lat: 38.67484, postalCode: "45659" },
    { lng: -82.7457, lat: 39.466041, postalCode: "43101" },
    { lng: -81.52209, lat: 41.472881, postalCode: "44122" },
    { lng: -81.61835, lat: 41.307688, postalCode: "44141" },
    { lng: -80.61034, lat: 40.787331, postalCode: "44455" },
    { lng: -81.58211, lat: 41.472352, postalCode: "44120" },
    { lng: -82.71083, lat: 41.42646, postalCode: "44870" },
    { lng: -82.66944, lat: 40.151979, postalCode: "43031" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45234" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45437" },
    { lng: -83.97584, lat: 39.293634, postalCode: "45107" },
    { lng: -82.76457, lat: 39.282411, postalCode: "45647" },
    { lng: -82.02167, lat: 41.450538, postalCode: "44011" },
    { lng: -80.970135, lat: 40.011338, postalCode: "43985" },
    { lng: -81.60793, lat: 41.414403, postalCode: "44125" },
    { lng: -84.2025, lat: 38.8702, postalCode: "45153" },
    { lng: -80.76013, lat: 39.964553, postalCode: "43947" },
    { lng: -81.46278, lat: 41.073606, postalCode: "44305" },
    { lng: -80.88695, lat: 40.18182, postalCode: "43974" },
    { lng: -80.53069, lat: 40.910669, postalCode: "44454" },
    { lng: -82.21421, lat: 39.202517, postalCode: "45710" },
    { lng: -84.39308, lat: 40.550005, postalCode: "45885" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45253" },
    { lng: -82.82656, lat: 39.605605, postalCode: "43154" },
    { lng: -84.37873, lat: 39.628806, postalCode: "45327" },
    { lng: -80.761269, lat: 41.317424, postalCode: "44482" },
    { lng: -80.64962, lat: 41.866806, postalCode: "44048" },
    { lng: -81.41066, lat: 40.888408, postalCode: "44720" },
    { lng: -82.36543, lat: 39.137773, postalCode: "45695" },
    { lng: -81.34404, lat: 41.249405, postalCode: "44241" },
    { lng: -83.17658, lat: 41.12071, postalCode: "44883" },
    { lng: -80.6686, lat: 41.676362, postalCode: "44032" },
    { lng: -82.482659, lat: 40.095148, postalCode: "43098" },
    { lng: -83.97563, lat: 41.450082, postalCode: "43532" },
    { lng: -83.8231, lat: 40.696862, postalCode: "45812" },
    { lng: -84.00778, lat: 41.585627, postalCode: "43515" },
    { lng: -84.48822, lat: 39.903161, postalCode: "45378" },
    { lng: -81.368284, lat: 40.811809, postalCode: "44712" },
    { lng: -84.30808, lat: 39.161061, postalCode: "45174" },
    { lng: -80.70141, lat: 40.709282, postalCode: "44492" },
    { lng: -83.41054, lat: 41.164209, postalCode: "44830" },
    { lng: -83.54825, lat: 39.34446, postalCode: "45135" },
    { lng: -84.16413, lat: 39.60828, postalCode: "45458" },
    { lng: -82.03333, lat: 41.161459, postalCode: "44253" },
    { lng: -83.24578, lat: 41.277969, postalCode: "43407" },
    { lng: -83.32103, lat: 40.430758, postalCode: "43344" },
    { lng: -84.36539, lat: 41.29037, postalCode: "43512" },
    { lng: -81.744573, lat: 39.197888, postalCode: "45739" },
    { lng: -83.52774, lat: 41.658109, postalCode: "43604" },
    { lng: -82.30228, lat: 39.710863, postalCode: "43748" },
    { lng: -81.59662, lat: 40.277376, postalCode: "43832" },
    { lng: -81.003385, lat: 41.783323, postalCode: "44088" },
    { lng: -84.51313, lat: 41.380047, postalCode: "43549" },
    { lng: -83.47587, lat: 39.732474, postalCode: "43151" },
    { lng: -84.15574, lat: 41.5615, postalCode: "43567" },
    { lng: -84.60527, lat: 39.154159, postalCode: "45211" },
    { lng: -83.28912, lat: 39.939528, postalCode: "43162" },
    { lng: -81.249027, lat: 41.910676, postalCode: "44097" },
    { lng: -83.139341, lat: 40.56941, postalCode: "43306" },
    { lng: -83.28641, lat: 39.228796, postalCode: "45612" },
    { lng: -81.75288, lat: 40.357857, postalCode: "43824" },
    { lng: -81.3564, lat: 40.799358, postalCode: "44704" },
    { lng: -82.71076, lat: 39.473379, postalCode: "43135" },
    { lng: -82.248369, lat: 39.740299, postalCode: "43761" },
    { lng: -81.73488, lat: 41.437018, postalCode: "44144" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45099" },
    { lng: -83.94417, lat: 40.593532, postalCode: "45896" },
    { lng: -82.91606, lat: 40.109513, postalCode: "43081" },
    { lng: -81.36641, lat: 39.995987, postalCode: "43778" },
    { lng: -82.69054, lat: 40.235446, postalCode: "43013" },
    { lng: -83.09269, lat: 40.845335, postalCode: "44860" },
    { lng: -81.21163, lat: 40.571372, postalCode: "44620" },
    { lng: -81.86556, lat: 40.090643, postalCode: "43802" },
    { lng: -81.55252, lat: 40.793924, postalCode: "44647" },
    { lng: -84.24995, lat: 39.168818, postalCode: "45150" },
    { lng: -82.19823, lat: 39.717824, postalCode: "43764" },
    { lng: -83.78542, lat: 38.985368, postalCode: "45171" },
    { lng: -82.26175, lat: 40.773052, postalCode: "44838" },
    { lng: -84.63574, lat: 40.094141, postalCode: "45331" },
    { lng: -82.588928, lat: 41.141541, postalCode: "44888" },
    { lng: -84.08979, lat: 40.72255, postalCode: "45804" },
    { lng: -84.03021, lat: 41.315943, postalCode: "43535" },
    { lng: -83.01881, lat: 40.053063, postalCode: "43214" },
    { lng: -81.3388, lat: 41.146862, postalCode: "44243" },
    { lng: -82.04314, lat: 40.33503, postalCode: "43844" },
    { lng: -81.728416, lat: 40.551575, postalCode: "44687" },
    { lng: -82.028, lat: 39.092281, postalCode: "45769" },
    { lng: -81.65816, lat: 41.391753, postalCode: "44131" },
    { lng: -84.24213, lat: 40.156514, postalCode: "45356" },
    { lng: -82.49882, lat: 39.958869, postalCode: "43025" },
    { lng: -81.16699, lat: 41.337187, postalCode: "44234" },
    { lng: -84.16931, lat: 39.644609, postalCode: "45459" },
    { lng: -80.57923, lat: 41.934916, postalCode: "44030" },
    { lng: -82.52995, lat: 40.949737, postalCode: "44878" },
    { lng: -83.78249, lat: 41.190401, postalCode: "43529" },
    { lng: -80.7642, lat: 40.757793, postalCode: "44432" },
    { lng: -81.542965, lat: 39.923616, postalCode: "43733" },
    { lng: -83.59783, lat: 41.65, postalCode: "43607" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43234" },
    { lng: -81.929185, lat: 41.132725, postalCode: "44282" },
    { lng: -81.26003, lat: 40.67926, postalCode: "44688" },
    { lng: -84.40249, lat: 39.343757, postalCode: "45069" },
    { lng: -84.5175, lat: 39.264791, postalCode: "45218" },
    { lng: -82.68029, lat: 40.304121, postalCode: "43011" },
    { lng: -84.3087, lat: 39.550241, postalCode: "45005" },
    { lng: -81.68961, lat: 40.964111, postalCode: "44230" },
    { lng: -81.80009, lat: 39.536415, postalCode: "43787" },
    { lng: -83.49795, lat: 41.57725, postalCode: "43465" },
    { lng: -81.720675, lat: 40.200382, postalCode: "43836" },
    { lng: -83.30724, lat: 41.63678, postalCode: "43412" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45250" },
    { lng: -80.86289, lat: 41.758952, postalCode: "44010" },
    { lng: -84.06783, lat: 40.900986, postalCode: "45830" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45025" },
    { lng: -81.08187, lat: 40.568568, postalCode: "44615" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45277" },
    { lng: -84.73138, lat: 40.929839, postalCode: "45832" },
    { lng: -82.8004, lat: 38.729816, postalCode: "45694" },
    { lng: -84.15595, lat: 39.687459, postalCode: "45429" },
    { lng: -81.72309, lat: 40.449531, postalCode: "43804" },
    { lng: -84.42307, lat: 39.685116, postalCode: "45325" },
    { lng: -84.55053, lat: 39.367106, postalCode: "45015" },
    { lng: -81.78174, lat: 41.459399, postalCode: "44111" },
    { lng: -82.10874, lat: 41.369846, postalCode: "44035" },
    { lng: -82.11492, lat: 39.855474, postalCode: "43791" },
    { lng: -82.14178, lat: 39.855355, postalCode: "43738" },
    { lng: -81.50041, lat: 41.608298, postalCode: "44132" },
    { lng: -84.03271, lat: 40.294031, postalCode: "45353" },
    { lng: -84.08817, lat: 39.276492, postalCode: "45162" },
    { lng: -80.94874, lat: 40.723803, postalCode: "44427" },
    { lng: -83.74936, lat: 39.038055, postalCode: "45155" },
    { lng: -83.15216, lat: 40.400799, postalCode: "43066" },
    { lng: -81.275824, lat: 39.711168, postalCode: "43786" },
    { lng: -80.72596, lat: 40.211142, postalCode: "43943" },
    { lng: -83.569359, lat: 41.720684, postalCode: "43601" },
    { lng: -80.9766, lat: 39.890564, postalCode: "43902" },
    { lng: -81.55483, lat: 39.789241, postalCode: "43717" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43226" },
    { lng: -82.38004, lat: 38.615111, postalCode: "45678" },
    { lng: -81.02632, lat: 40.922608, postalCode: "44672" },
    { lng: -82.89589, lat: 39.852363, postalCode: "43125" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43270" },
    { lng: -80.81606, lat: 40.027491, postalCode: "43940" },
    { lng: -84.57771, lat: 41.230816, postalCode: "45821" },
    { lng: -82.92509, lat: 41.404156, postalCode: "43464" },
    { lng: -84.58232, lat: 40.542778, postalCode: "45822" },
    { lng: -82.51869, lat: 40.766589, postalCode: "44903" },
    { lng: -81.36566, lat: 40.784158, postalCode: "44707" },
    { lng: -80.70251, lat: 40.369467, postalCode: "43953" },
    { lng: -84.27867, lat: 39.755758, postalCode: "45427" },
    { lng: -84.65042, lat: 39.160159, postalCode: "45248" },
    { lng: -81.94337, lat: 39.429095, postalCode: "45711" },
    { lng: -81.151604, lat: 39.859373, postalCode: "43757" },
    { lng: -82.54811, lat: 39.117212, postalCode: "45692" },
    { lng: -83.31764, lat: 39.086218, postalCode: "45646" },
    { lng: -82.661938, lat: 40.614239, postalCode: "43349" },
    { lng: -80.896714, lat: 40.828584, postalCode: "44493" },
    { lng: -83.62835, lat: 41.318285, postalCode: "43451" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44190" },
    { lng: -83.848414, lat: 39.074262, postalCode: "45110" },
    { lng: -82.00032, lat: 40.965423, postalCode: "44214" },
    { lng: -82.96158, lat: 40.642604, postalCode: "43314" },
    { lng: -83.13983, lat: 40.089811, postalCode: "43016" },
    { lng: -81.368284, lat: 40.811809, postalCode: "44711" },
    { lng: -81.85738, lat: 41.441697, postalCode: "44126" },
    { lng: -81.97282, lat: 38.999757, postalCode: "45779" },
    { lng: -83.558044, lat: 38.782131, postalCode: "45553" },
    { lng: -82.96969, lat: 39.897695, postalCode: "43207" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43265" },
    { lng: -83.99136, lat: 41.112413, postalCode: "45856" },
    { lng: -81.28018, lat: 39.471663, postalCode: "45773" },
    { lng: -81.102502, lat: 40.29649, postalCode: "43984" },
    { lng: -81.58475, lat: 40.65151, postalCode: "44608" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44192" },
    { lng: -84.47602, lat: 40.740456, postalCode: "45838" },
    { lng: -81.24133, lat: 40.506928, postalCode: "44675" },
    { lng: -81.50745, lat: 41.081788, postalCode: "44304" },
    { lng: -82.96587, lat: 39.957265, postalCode: "43205" },
    { lng: -81.09908, lat: 39.742066, postalCode: "43793" },
    { lng: -81.02595, lat: 40.992991, postalCode: "44449" },
    { lng: -84.60849, lat: 39.112169, postalCode: "45238" },
    { lng: -80.62043, lat: 41.122524, postalCode: "44505" },
    { lng: -83.60464, lat: 41.252306, postalCode: "43437" },
    { lng: -83.36565, lat: 41.455129, postalCode: "43469" },
    { lng: -82.51118, lat: 40.759156, postalCode: "44902" },
    { lng: -82.31994, lat: 40.66496, postalCode: "44864" },
    { lng: -83.069296, lat: 40.616604, postalCode: "43301" },
    { lng: -80.94645, lat: 41.802618, postalCode: "44041" },
    { lng: -84.47938, lat: 40.328535, postalCode: "45388" },
    { lng: -84.32227, lat: 39.066112, postalCode: "45255" },
    { lng: -82.36653, lat: 40.173402, postalCode: "43071" },
    { lng: -84.49514, lat: 39.166948, postalCode: "45217" },
    { lng: -80.78094, lat: 40.212067, postalCode: "43917" },
    { lng: -81.40527, lat: 40.397916, postalCode: "44682" },
    { lng: -81.2707, lat: 40.769763, postalCode: "44730" },
    { lng: -80.99519, lat: 40.184401, postalCode: "43981" },
    { lng: -81.33145, lat: 40.886297, postalCode: "44721" },
    { lng: -84.19381, lat: 39.820807, postalCode: "45414" },
    { lng: -83.846199, lat: 40.573227, postalCode: "43346" },
    { lng: -82.98115, lat: 39.818249, postalCode: "43137" },
    { lng: -83.23348, lat: 41.244095, postalCode: "44815" },
    { lng: -84.43086, lat: 39.15406, postalCode: "45209" },
    { lng: -84.000532, lat: 41.341081, postalCode: "43523" },
    { lng: -84.05113, lat: 40.372607, postalCode: "45340" },
    { lng: -84.45798, lat: 39.188859, postalCode: "45237" },
    { lng: -81.52709, lat: 41.389371, postalCode: "44146" },
    { lng: -82.2697, lat: 38.630633, postalCode: "45623" },
    { lng: -84.09805, lat: 38.821032, postalCode: "45120" },
    { lng: -81.73368, lat: 41.037664, postalCode: "44281" },
    { lng: -83.26409, lat: 39.555409, postalCode: "43145" },
    { lng: -80.63426, lat: 40.371185, postalCode: "43952" },
    { lng: -80.65562, lat: 41.074508, postalCode: "44507" },
    { lng: -83.81063, lat: 41.517402, postalCode: "43571" },
    { lng: -83.648996, lat: 41.39235, postalCode: "43414" },
    { lng: -84.53734, lat: 39.204959, postalCode: "45224" },
    { lng: -83.64985, lat: 41.240054, postalCode: "43413" },
    { lng: -84.18927, lat: 39.725705, postalCode: "45409" },
    { lng: -83.69515, lat: 41.28447, postalCode: "43462" },
    { lng: -84.36014, lat: 39.18466, postalCode: "45243" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45267" },
    { lng: -81.91206, lat: 38.978044, postalCode: "45771" },
    { lng: -80.60358, lat: 41.304391, postalCode: "44418" },
    { lng: -81.196615, lat: 41.170006, postalCode: "44242" },
    { lng: -83.65813, lat: 39.840367, postalCode: "45368" },
    { lng: -83.25597, lat: 41.553383, postalCode: "43432" },
    { lng: -80.88595, lat: 40.679857, postalCode: "43962" },
    { lng: -83.06007, lat: 39.768502, postalCode: "43116" },
    { lng: -83.54335, lat: 38.795145, postalCode: "45693" },
    { lng: -81.17197, lat: 39.989191, postalCode: "43713" },
    { lng: -81.485315, lat: 39.816891, postalCode: "43799" },
    { lng: -83.007626, lat: 40.135711, postalCode: "43236" },
    { lng: -84.46683, lat: 41.012, postalCode: "45849" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44177" },
    { lng: -84.11831, lat: 39.222264, postalCode: "45122" },
    { lng: -83.21026, lat: 41.532215, postalCode: "43458" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45269" },
    { lng: -84.2734, lat: 39.716161, postalCode: "45418" },
    { lng: -84.03808, lat: 39.719092, postalCode: "45434" },
    { lng: -81.89613, lat: 39.484626, postalCode: "43728" },
    { lng: -81.85967, lat: 40.865194, postalCode: "44677" },
    { lng: -84.17549, lat: 41.190188, postalCode: "43548" },
    { lng: -83.79265, lat: 41.00433, postalCode: "45816" },
    { lng: -82.13584, lat: 40.657511, postalCode: "44638" },
    { lng: -82.875027, lat: 40.868645, postalCode: "44881" },
    { lng: -81.52726, lat: 41.570344, postalCode: "44117" },
    { lng: -80.69768, lat: 40.170721, postalCode: "43963" },
    { lng: -83.5104, lat: 41.642549, postalCode: "43605" },
    { lng: -80.65551, lat: 41.428487, postalCode: "44417" },
    { lng: -82.48254, lat: 40.385636, postalCode: "43050" },
    { lng: -83.78457, lat: 40.691866, postalCode: "45859" },
    { lng: -82.804677, lat: 41.683477, postalCode: "43446" },
    { lng: -84.7079, lat: 39.137818, postalCode: "45001" },
    { lng: -83.04293, lat: 41.51751, postalCode: "43439" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44193" },
    { lng: -84.619129, lat: 40.135426, postalCode: "45350" },
    { lng: -81.90422, lat: 40.180136, postalCode: "43811" },
    { lng: -84.67173, lat: 39.117065, postalCode: "45233" },
    { lng: -82.35457, lat: 38.468003, postalCode: "45669" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44396" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45263" },
    { lng: -82.45326, lat: 38.915013, postalCode: "45685" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45343" },
    { lng: -83.57736, lat: 40.513261, postalCode: "43345" },
    { lng: -83.77254, lat: 38.850094, postalCode: "45168" },
    { lng: -80.6294, lat: 40.523054, postalCode: "43961" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44195" },
    { lng: -82.51459, lat: 39.630003, postalCode: "43155" },
    { lng: -83.5876, lat: 39.593121, postalCode: "43142" },
    { lng: -83.99496, lat: 38.914118, postalCode: "45130" },
    { lng: -80.69134, lat: 41.069158, postalCode: "44511" },
    { lng: -80.57733, lat: 41.166743, postalCode: "44425" },
    { lng: -81.56067, lat: 41.412653, postalCode: "44137" },
    { lng: -82.25279, lat: 39.588854, postalCode: "43766" },
    { lng: -83.92245, lat: 39.046589, postalCode: "45154" },
    { lng: -81.43773, lat: 40.850683, postalCode: "44718" },
    { lng: -84.60213, lat: 41.030911, postalCode: "45851" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45043" },
    { lng: -80.96688, lat: 41.539985, postalCode: "44099" },
    { lng: -82.756, lat: 39.904288, postalCode: "43147" },
    { lng: -83.26825, lat: 40.103133, postalCode: "43064" },
    { lng: -84.1386, lat: 38.792686, postalCode: "45112" },
    { lng: -84.007023, lat: 39.145698, postalCode: "45145" },
    { lng: -84.078313, lat: 39.18779, postalCode: "45158" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44189" },
    { lng: -81.62724, lat: 40.840256, postalCode: "44666" },
    { lng: -82.755455, lat: 38.794115, postalCode: "45677" },
    { lng: -81.5981, lat: 40.936655, postalCode: "44216" },
    { lng: -80.6356, lat: 40.26212, postalCode: "43913" },
    { lng: -82.85436, lat: 40.954399, postalCode: "44854" },
    { lng: -84.27311, lat: 39.075329, postalCode: "45245" },
    { lng: -80.91009, lat: 40.75105, postalCode: "44423" },
    { lng: -83.87108, lat: 41.491702, postalCode: "43547" },
    { lng: -81.529139, lat: 41.052455, postalCode: "44317" },
    { lng: -81.33683, lat: 41.526301, postalCode: "44026" },
    { lng: -83.5577, lat: 41.706048, postalCode: "43612" },
    { lng: -83.73047, lat: 41.626651, postalCode: "43528" },
    { lng: -81.88561, lat: 39.341224, postalCode: "45778" },
    { lng: -84.631322, lat: 41.39066, postalCode: "43520" },
    { lng: -82.20658, lat: 41.431875, postalCode: "44053" },
    { lng: -80.60039, lat: 40.917556, postalCode: "44443" },
    { lng: -82.18376, lat: 39.966135, postalCode: "43746" },
    { lng: -84.396619, lat: 41.422158, postalCode: "43519" },
    { lng: -80.802854, lat: 41.017082, postalCode: "44599" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43268" },
    { lng: -81.65174, lat: 40.600062, postalCode: "44624" },
    { lng: -81.24579, lat: 41.469908, postalCode: "44065" },
    { lng: -84.51439, lat: 39.11226, postalCode: "45210" },
    { lng: -81.47087, lat: 41.603581, postalCode: "44092" },
    { lng: -82.14312, lat: 40.527648, postalCode: "44628" },
    { lng: -82.83218, lat: 39.916574, postalCode: "43109" },
    { lng: -84.48041, lat: 40.755619, postalCode: "45894" },
    { lng: -82.34436, lat: 40.27453, postalCode: "43037" },
    { lng: -83.97343, lat: 40.832363, postalCode: "45808" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45454" },
    { lng: -83.54935, lat: 41.646649, postalCode: "43602" },
    { lng: -80.75848, lat: 40.864494, postalCode: "44431" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45481" },
    { lng: -81.55914, lat: 41.040155, postalCode: "44314" },
    { lng: -82.18021, lat: 39.462005, postalCode: "45716" },
    { lng: -82.511369, lat: 40.850833, postalCode: "44901" },
    { lng: -84.35202, lat: 40.053483, postalCode: "45359" },
    { lng: -84.24715, lat: 39.753108, postalCode: "45417" },
    { lng: -83.83786, lat: 40.102185, postalCode: "43083" },
    { lng: -83.67037, lat: 41.6522, postalCode: "43615" },
    { lng: -82.48061, lat: 41.227648, postalCode: "44826" },
    { lng: -82.28507, lat: 39.905157, postalCode: "43739" },
    { lng: -82.50791, lat: 39.816577, postalCode: "43148" },
    { lng: -81.841079, lat: 41.127594, postalCode: "44258" },
    { lng: -82.77273, lat: 40.555366, postalCode: "43338" },
    { lng: -81.86761, lat: 40.270198, postalCode: "43812" },
    { lng: -83.81821, lat: 40.774039, postalCode: "45810" },
    { lng: -81.78854, lat: 39.363901, postalCode: "45724" },
    { lng: -83.78216, lat: 40.954985, postalCode: "45881" },
    { lng: -80.955313, lat: 41.730146, postalCode: "44005" },
    { lng: -81.71764, lat: 40.909535, postalCode: "44645" },
    { lng: -84.18324, lat: 40.844554, postalCode: "45809" },
    { lng: -84.62514, lat: 39.282824, postalCode: "45252" },
    { lng: -83.15, lat: 41.240649, postalCode: "44861" },
    { lng: -84.30999, lat: 39.877745, postalCode: "45322" },
    { lng: -84.18848, lat: 39.757758, postalCode: "45402" },
    { lng: -80.90465, lat: 40.352364, postalCode: "43976" },
    { lng: -81.00679, lat: 40.028171, postalCode: "43718" },
    { lng: -83.30333, lat: 41.333956, postalCode: "43435" },
    { lng: -81.102502, lat: 40.29649, postalCode: "43989" },
    { lng: -83.71609, lat: 41.667361, postalCode: "43617" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44185" },
    { lng: -82.46415, lat: 39.267049, postalCode: "45651" },
    { lng: -82.07808, lat: 39.814025, postalCode: "43777" },
    { lng: -83.07655, lat: 40.02063, postalCode: "43221" },
    { lng: -84.509268, lat: 39.262158, postalCode: "45299" },
    { lng: -81.48175, lat: 41.550318, postalCode: "44143" },
    { lng: -83.52488, lat: 40.145952, postalCode: "43084" },
    { lng: -82.50097, lat: 41.020433, postalCode: "44837" },
    { lng: -84.3152, lat: 39.344258, postalCode: "45040" },
    { lng: -82.95824, lat: 40.328773, postalCode: "43032" },
    { lng: -84.22011, lat: 39.760058, postalCode: "45407" },
    { lng: -81.433818, lat: 39.922656, postalCode: "43708" },
    { lng: -84.49314, lat: 40.112545, postalCode: "45328" },
    { lng: -81.53098, lat: 40.59712, postalCode: "44680" },
    { lng: -82.34322, lat: 40.017096, postalCode: "43056" },
    { lng: -82.3648, lat: 41.407845, postalCode: "44089" },
    { lng: -82.39623, lat: 39.282728, postalCode: "45698" },
    { lng: -81.77945, lat: 41.378051, postalCode: "44130" },
    { lng: -84.55277, lat: 39.330356, postalCode: "45014" },
    { lng: -82.256185, lat: 38.86833, postalCode: "45643" },
    { lng: -81.81945, lat: 39.22241, postalCode: "45723" },
    { lng: -84.279306, lat: 41.325764, postalCode: "43530" },
    { lng: -81.16037, lat: 40.798758, postalCode: "44669" },
    { lng: -84.30617, lat: 41.009008, postalCode: "45827" },
    { lng: -83.647583, lat: 39.416747, postalCode: "45138" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45448" },
    { lng: -82.22225, lat: 39.608984, postalCode: "43782" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43287" },
    { lng: -81.47281, lat: 41.513752, postalCode: "44124" },
    { lng: -80.61956, lat: 40.842848, postalCode: "44445" },
    { lng: -81.93146, lat: 41.029639, postalCode: "44251" },
    { lng: -84.36582, lat: 39.441008, postalCode: "45050" },
    { lng: -80.83514, lat: 41.535437, postalCode: "44076" },
    { lng: -83.29759, lat: 38.664532, postalCode: "45684" },
    { lng: -81.44439, lat: 40.477187, postalCode: "44663" },
    { lng: -82.010132, lat: 39.368235, postalCode: "45717" },
    { lng: -83.5827, lat: 40.293691, postalCode: "43336" },
    { lng: -83.123, lat: 39.954363, postalCode: "43228" },
    { lng: -83.78052, lat: 40.518081, postalCode: "43310" },
    { lng: -82.47932, lat: 39.93398, postalCode: "43008" },
    { lng: -81.02104, lat: 39.841646, postalCode: "43716" },
    { lng: -84.51295, lat: 39.173409, postalCode: "45232" },
    { lng: -80.814948, lat: 40.122351, postalCode: "43916" },
    { lng: -81.782872, lat: 40.507088, postalCode: "44617" },
    { lng: -81.424552, lat: 40.895721, postalCode: "44767" },
    { lng: -83.17085, lat: 39.810093, postalCode: "43126" },
    { lng: -82.08208, lat: 39.006757, postalCode: "45760" },
    { lng: -81.60905, lat: 41.53715, postalCode: "44108" },
    { lng: -80.62364, lat: 40.465183, postalCode: "43964" },
    { lng: -83.57108, lat: 38.95352, postalCode: "45679" },
    { lng: -82.8052, lat: 39.998073, postalCode: "43004" },
    { lng: -82.52073, lat: 40.606221, postalCode: "44813" },
    { lng: -82.02563, lat: 40.687973, postalCode: "44676" },
    { lng: -81.44635, lat: 40.041068, postalCode: "43755" },
    { lng: -81.57558, lat: 41.534101, postalCode: "44112" },
    { lng: -84.7905, lat: 39.575126, postalCode: "45003" },
    { lng: -84.14754, lat: 41.253169, postalCode: "43527" },
    { lng: -80.57469, lat: 40.641545, postalCode: "43920" },
    { lng: -84.12227, lat: 39.840308, postalCode: "45424" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45221" },
    { lng: -83.78205, lat: 39.94831, postalCode: "45503" },
    { lng: -81.14287, lat: 41.767394, postalCode: "44081" },
    { lng: -82.41237, lat: 40.561377, postalCode: "44822" },
    { lng: -84.26087, lat: 40.623695, postalCode: "45819" },
    { lng: -83.60577, lat: 40.642266, postalCode: "43326" },
    { lng: -82.532057, lat: 40.772874, postalCode: "44999" },
    { lng: -82.10234, lat: 41.101909, postalCode: "44275" },
    { lng: -84.08093, lat: 38.956484, postalCode: "45106" },
    { lng: -84.77789, lat: 39.155158, postalCode: "45052" },
    { lng: -84.7317, lat: 41.072614, postalCode: "45880" },
    { lng: -82.220469, lat: 38.967813, postalCode: "45641" },
    { lng: -83.28985, lat: 41.596682, postalCode: "43445" },
    { lng: -81.70227, lat: 40.694887, postalCode: "44659" },
    { lng: -82.858402, lat: 38.955485, postalCode: "45683" },
    { lng: -83.04268, lat: 39.988114, postalCode: "43212" },
    { lng: -84.65726, lat: 40.780889, postalCode: "45874" },
    { lng: -81.42516, lat: 40.961206, postalCode: "44685" },
    { lng: -83.43171, lat: 41.269279, postalCode: "43457" },
    { lng: -82.482659, lat: 40.095148, postalCode: "43093" },
    { lng: -82.58207, lat: 40.678208, postalCode: "44904" },
    { lng: -82.69077, lat: 39.208053, postalCode: "45672" },
    { lng: -82.12856, lat: 39.4001, postalCode: "45719" },
    { lng: -80.81836, lat: 40.067767, postalCode: "43909" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45020" },
    { lng: -82.40483, lat: 41.098478, postalCode: "44851" },
    { lng: -84.02323, lat: 39.711173, postalCode: "45301" },
    { lng: -82.87084, lat: 41.503759, postalCode: "43433" },
    { lng: -82.87855, lat: 40.039963, postalCode: "43230" },
    { lng: -84.67216, lat: 39.888717, postalCode: "45321" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45012" },
    { lng: -81.93057, lat: 40.630191, postalCode: "44633" },
    { lng: -82.70953, lat: 39.803993, postalCode: "43112" },
    { lng: -83.05954, lat: 38.743244, postalCode: "45663" },
    { lng: -81.873607, lat: 40.351271, postalCode: "43828" },
    { lng: -82.82831, lat: 40.46242, postalCode: "43321" },
    { lng: -84.03567, lat: 41.229001, postalCode: "43524" },
    { lng: -83.00117, lat: 39.990764, postalCode: "43201" },
    { lng: -81.11641, lat: 40.917908, postalCode: "44601" },
    { lng: -81.73475, lat: 41.233479, postalCode: "44233" },
    { lng: -80.65481, lat: 41.123757, postalCode: "44504" },
    { lng: -84.64363, lat: 39.209208, postalCode: "45247" },
    { lng: -80.78137, lat: 40.269741, postalCode: "43948" },
    { lng: -81.44679, lat: 41.248795, postalCode: "44236" },
    { lng: -84.48899, lat: 39.152093, postalCode: "45229" },
    { lng: -83.89827, lat: 41.211032, postalCode: "43516" },
    { lng: -84.00874, lat: 38.880632, postalCode: "45119" },
    { lng: -83.921517, lat: 40.498069, postalCode: "43381" },
    { lng: -83.1469, lat: 39.786202, postalCode: "43146" },
    { lng: -81.243498, lat: 40.547688, postalCode: "44639" },
    { lng: -83.82917, lat: 41.26256, postalCode: "43511" },
    { lng: -81.14404, lat: 41.442863, postalCode: "44021" },
    { lng: -83.79697, lat: 39.744577, postalCode: "45314" },
    { lng: -81.50135, lat: 41.321189, postalCode: "44056" },
    { lng: -84.16523, lat: 39.713309, postalCode: "45419" },
    { lng: -84.31129, lat: 40.491725, postalCode: "45871" },
    { lng: -84.01992, lat: 39.939925, postalCode: "45344" },
    { lng: -83.48322, lat: 41.607416, postalCode: "43619" },
    { lng: -81.00415, lat: 40.258447, postalCode: "43907" },
    { lng: -80.61549, lat: 41.016159, postalCode: "44514" },
    { lng: -84.75333, lat: 41.572443, postalCode: "43518" },
    { lng: -82.447024, lat: 39.7631, postalCode: "43163" },
    { lng: -84.53052, lat: 39.730506, postalCode: "45381" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45441" },
    { lng: -82.589042, lat: 41.261375, postalCode: "44858" },
    { lng: -81.17453, lat: 40.68357, postalCode: "44644" },
    { lng: -82.82535, lat: 38.653909, postalCode: "45629" },
    { lng: -83.25664, lat: 39.308063, postalCode: "45681" },
    { lng: -82.077068, lat: 41.401494, postalCode: "44036" },
    { lng: -83.28361, lat: 41.477061, postalCode: "43416" },
    { lng: -81.7358, lat: 41.473451, postalCode: "44102" },
    { lng: -82.40801, lat: 39.530972, postalCode: "43138" },
    { lng: -80.83872, lat: 40.624307, postalCode: "43945" },
    { lng: -84.33389, lat: 39.119161, postalCode: "45244" },
    { lng: -81.55179, lat: 41.441153, postalCode: "44128" },
    { lng: -82.647824, lat: 41.49181, postalCode: "44871" },
    { lng: -81.85646, lat: 41.139849, postalCode: "44256" },
    { lng: -82.92589, lat: 40.002514, postalCode: "43219" },
    { lng: -82.53744, lat: 38.557052, postalCode: "45645" },
    { lng: -84.0855, lat: 39.640059, postalCode: "45305" },
    { lng: -82.40054, lat: 40.079115, postalCode: "43055" },
    { lng: -81.44614, lat: 41.653796, postalCode: "44095" },
    { lng: -82.63678, lat: 39.034226, postalCode: "45640" },
    { lng: -82.68356, lat: 40.479649, postalCode: "43317" },
    { lng: -83.91041, lat: 40.314099, postalCode: "43318" },
    { lng: -80.6549, lat: 40.951859, postalCode: "44452" },
    { lng: -82.97228, lat: 39.33046, postalCode: "45601" },
    { lng: -84.22688, lat: 39.557096, postalCode: "45066" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45490" },
    { lng: -83.66884, lat: 40.327984, postalCode: "43360" },
    { lng: -81.92658, lat: 41.484193, postalCode: "44140" },
    { lng: -81.51241, lat: 41.1486, postalCode: "44223" },
    { lng: -84.24728, lat: 39.317735, postalCode: "45039" },
    { lng: -84.64828, lat: 39.31235, postalCode: "45061" },
    { lng: -83.607858, lat: 39.198813, postalCode: "45165" },
    { lng: -82.51975, lat: 40.733243, postalCode: "44907" },
    { lng: -84.16342, lat: 39.783258, postalCode: "45404" },
    { lng: -80.94093, lat: 40.022806, postalCode: "43967" },
    { lng: -82.09986, lat: 39.431016, postalCode: "45761" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43279" },
    { lng: -84.67557, lat: 39.629735, postalCode: "45311" },
    { lng: -80.9552, lat: 41.294453, postalCode: "44470" },
    { lng: -84.16797, lat: 40.520289, postalCode: "45884" },
    { lng: -82.2246, lat: 40.639849, postalCode: "44842" },
    { lng: -82.94285, lat: 39.598236, postalCode: "43113" },
    { lng: -83.57236, lat: 39.651917, postalCode: "43128" },
    { lng: -82.91278, lat: 40.14852, postalCode: "43082" },
    { lng: -81.52512, lat: 39.741629, postalCode: "43724" },
    { lng: -81.512338, lat: 41.096205, postalCode: "44309" },
    { lng: -80.86635, lat: 41.659739, postalCode: "44084" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44186" },
    { lng: -84.15974, lat: 40.284855, postalCode: "45365" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43603" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43697" },
    { lng: -83.49204, lat: 40.547039, postalCode: "43340" },
    { lng: -82.61105, lat: 39.111094, postalCode: "45621" },
    { lng: -82.58332, lat: 38.879935, postalCode: "45656" },
    { lng: -82.41013, lat: 39.909915, postalCode: "43076" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45073" },
    { lng: -83.18299, lat: 40.05991, postalCode: "43002" },
    { lng: -81.4359, lat: 41.020256, postalCode: "44250" },
    { lng: -81.62216, lat: 41.450602, postalCode: "44105" },
    { lng: -81.82055, lat: 41.40035, postalCode: "44142" },
    { lng: -80.93763, lat: 40.11761, postalCode: "43927" },
    { lng: -84.40459, lat: 40.233304, postalCode: "45363" },
    { lng: -83.55218, lat: 41.665849, postalCode: "43620" },
    { lng: -84.111701, lat: 41.326664, postalCode: "43550" },
    { lng: -81.62702, lat: 41.484001, postalCode: "44104" },
    { lng: -82.41638, lat: 38.766082, postalCode: "45658" },
    { lng: -81.73575, lat: 41.397701, postalCode: "44129" },
    { lng: -84.04649, lat: 41.020852, postalCode: "45875" },
    { lng: -81.77006, lat: 40.835059, postalCode: "44667" },
    { lng: -82.13689, lat: 41.435642, postalCode: "44055" },
    { lng: -81.33635, lat: 41.478469, postalCode: "44072" },
    { lng: -83.78579, lat: 39.91086, postalCode: "45505" },
    { lng: -82.25642, lat: 39.946713, postalCode: "43721" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45258" },
    { lng: -82.18712, lat: 40.801432, postalCode: "44840" },
    { lng: -84.300232, lat: 39.213739, postalCode: "45147" },
    { lng: -83.93512, lat: 39.867259, postalCode: "45323" },
    { lng: -83.84944, lat: 41.427352, postalCode: "43522" },
    { lng: -80.95723, lat: 40.901738, postalCode: "44619" },
    { lng: -81.44365, lat: 41.388387, postalCode: "44139" },
    { lng: -81.59859, lat: 40.712109, postalCode: "44613" },
    { lng: -82.747853, lat: 39.46557, postalCode: "45633" },
    { lng: -83.65288, lat: 40.834233, postalCode: "45897" },
    { lng: -84.34143, lat: 40.008648, postalCode: "45339" },
    { lng: -83.40878, lat: 38.746506, postalCode: "45650" },
    { lng: -84.730275, lat: 41.523945, postalCode: "43505" },
    { lng: -81.42142, lat: 40.810931, postalCode: "44708" },
    { lng: -81.53309, lat: 41.526151, postalCode: "44121" },
    { lng: -83.12713, lat: 40.109478, postalCode: "43017" },
    { lng: -83.88014, lat: 39.712772, postalCode: "45384" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44210" },
    { lng: -83.06968, lat: 40.295925, postalCode: "43015" },
    { lng: -81.57273, lat: 41.562781, postalCode: "44110" },
    { lng: -81.84927, lat: 40.671036, postalCode: "44627" },
    { lng: -82.910552, lat: 41.037511, postalCode: "44803" },
    { lng: -83.44266, lat: 40.179696, postalCode: "43045" },
    { lng: -83.612574, lat: 38.749779, postalCode: "45105" },
    { lng: -84.39256, lat: 39.743766, postalCode: "45345" },
    { lng: -82.16947, lat: 40.471139, postalCode: "43006" },
    { lng: -82.97091, lat: 40.815179, postalCode: "44820" },
    { lng: -83.72363, lat: 39.580198, postalCode: "45307" },
    { lng: -84.20009, lat: 39.758058, postalCode: "45422" },
    { lng: -81.52357, lat: 41.602498, postalCode: "44123" },
    { lng: -82.92948, lat: 41.512855, postalCode: "43452" },
    { lng: -80.64563, lat: 41.086279, postalCode: "44502" },
    { lng: -82.78885, lat: 40.90261, postalCode: "44887" },
    { lng: -84.50988, lat: 39.12721, postalCode: "45219" },
    { lng: -83.04558, lat: 39.935263, postalCode: "43223" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44334" },
    { lng: -82.11496, lat: 41.028815, postalCode: "44235" },
    { lng: -84.52941, lat: 39.284806, postalCode: "45240" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43299" },
    { lng: -84.17288, lat: 40.56914, postalCode: "45895" },
    { lng: -81.25508, lat: 39.397077, postalCode: "45768" },
    { lng: -81.3359, lat: 40.569177, postalCode: "44656" },
    { lng: -84.5453, lat: 39.242608, postalCode: "45231" },
    { lng: -81.42168, lat: 40.612253, postalCode: "44697" },
    { lng: -84.08653, lat: 40.601985, postalCode: "45888" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45262" },
    { lng: -84.57438, lat: 39.11071, postalCode: "45205" },
    { lng: -83.419606, lat: 40.813023, postalCode: "43330" },
    { lng: -84.19578, lat: 40.40476, postalCode: "45302" },
    { lng: -83.605, lat: 41.703899, postalCode: "43613" },
    { lng: -82.08743, lat: 40.635332, postalCode: "44611" },
    { lng: -82.87413, lat: 39.966814, postalCode: "43213" },
    { lng: -81.48791, lat: 40.537509, postalCode: "44622" },
    { lng: -81.173873, lat: 40.874558, postalCode: "44650" },
    { lng: -81.53625, lat: 41.103205, postalCode: "44303" },
    { lng: -81.32748, lat: 41.392171, postalCode: "44023" },
    { lng: -81.368284, lat: 40.811809, postalCode: "44648" },
    { lng: -82.01637, lat: 40.130754, postalCode: "43821" },
    { lng: -82.18985, lat: 40.906577, postalCode: "44866" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45413" },
    { lng: -83.19363, lat: 39.390231, postalCode: "45628" },
    { lng: -84.34178, lat: 40.841409, postalCode: "45833" },
    { lng: -81.36518, lat: 41.043123, postalCode: "44260" },
    { lng: -83.83774, lat: 40.460862, postalCode: "43324" },
    { lng: -81.27506, lat: 40.189812, postalCode: "43973" },
    { lng: -80.81921, lat: 41.449052, postalCode: "44450" },
    { lng: -81.51906, lat: 41.063055, postalCode: "44311" },
    { lng: -84.6169, lat: 39.562415, postalCode: "45064" },
    { lng: -81.42692, lat: 41.093156, postalCode: "44278" },
    { lng: -80.889206, lat: 40.46947, postalCode: "43970" },
    { lng: -82.71455, lat: 38.816072, postalCode: "45682" },
    { lng: -81.34253, lat: 40.821858, postalCode: "44705" },
    { lng: -82.13542, lat: 41.259352, postalCode: "44050" },
    { lng: -82.09914, lat: 41.48054, postalCode: "44054" },
    { lng: -81.74213, lat: 40.268019, postalCode: "43845" },
    { lng: -81.90358, lat: 41.069151, postalCode: "44215" },
    { lng: -80.693, lat: 41.160758, postalCode: "44420" },
    { lng: -84.42201, lat: 39.981075, postalCode: "45337" },
    { lng: -81.40088, lat: 40.398664, postalCode: "44692" },
    { lng: -84.515245, lat: 39.451806, postalCode: "45055" },
    { lng: -81.297727, lat: 41.010907, postalCode: "44265" },
    { lng: -82.38091, lat: 38.881743, postalCode: "45674" },
    { lng: -81.60883, lat: 41.507751, postalCode: "44106" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43272" },
    { lng: -81.12031, lat: 39.524674, postalCode: "45767" },
    { lng: -80.59243, lat: 41.078058, postalCode: "44405" },
    { lng: -84.13453, lat: 39.723909, postalCode: "45420" },
    { lng: -80.54967, lat: 40.776151, postalCode: "44441" },
    { lng: -84.08458, lat: 40.759451, postalCode: "45801" },
    { lng: -82.244, lat: 39.07348, postalCode: "45741" },
    { lng: -84.54112, lat: 39.13741, postalCode: "45225" },
    { lng: -82.61225, lat: 40.091576, postalCode: "43001" },
    { lng: -81.484686, lat: 41.067506, postalCode: "44316" },
    { lng: -82.67743, lat: 40.99388, postalCode: "44865" },
    { lng: -84.76337, lat: 39.177002, postalCode: "45033" },
    { lng: -80.761269, lat: 41.317424, postalCode: "44488" },
    { lng: -82.482659, lat: 40.095148, postalCode: "43058" },
    { lng: -83.76633, lat: 40.112531, postalCode: "43078" },
    { lng: -80.79979, lat: 40.176109, postalCode: "43939" },
    { lng: -82.36547, lat: 40.963743, postalCode: "44874" },
    { lng: -82.12173, lat: 39.851515, postalCode: "43735" },
    { lng: -83.34842, lat: 39.172214, postalCode: "45624" },
    { lng: -82.6737, lat: 38.535109, postalCode: "45638" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45026" },
    { lng: -81.677906, lat: 40.561693, postalCode: "44684" },
    { lng: -81.400134, lat: 40.929507, postalCode: "44630" },
    { lng: -83.3415, lat: 40.136336, postalCode: "43077" },
    { lng: -81.00795, lat: 40.758473, postalCode: "44625" },
    { lng: -83.158991, lat: 39.280388, postalCode: "45617" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45475" },
    { lng: -80.78926, lat: 40.076176, postalCode: "43934" },
    { lng: -81.3809, lat: 40.809108, postalCode: "44703" },
    { lng: -84.65393, lat: 39.739353, postalCode: "45320" },
    { lng: -83.58904, lat: 41.540724, postalCode: "43551" },
    { lng: -83.66538, lat: 40.470605, postalCode: "43347" },
    { lng: -80.99304, lat: 40.898618, postalCode: "44609" },
    { lng: -83.93422, lat: 41.368743, postalCode: "43534" },
    { lng: -84.34125, lat: 39.853674, postalCode: "45315" },
    { lng: -80.82023, lat: 39.861984, postalCode: "43942" },
    { lng: -82.61216, lat: 39.712887, postalCode: "43130" },
    { lng: -81.51919, lat: 41.043955, postalCode: "44301" },
    { lng: -84.51986, lat: 39.14426, postalCode: "45220" },
    { lng: -83.22944, lat: 40.687548, postalCode: "43337" },
    { lng: -83.96044, lat: 40.217799, postalCode: "43070" },
    { lng: -84.60321, lat: 39.409188, postalCode: "45013" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45944" },
    { lng: -83.13569, lat: 41.53446, postalCode: "43449" },
    { lng: -84.14468, lat: 40.739517, postalCode: "45805" },
    { lng: -82.96772, lat: 40.039914, postalCode: "43224" },
    { lng: -81.55114, lat: 41.234388, postalCode: "44264" },
    { lng: -84.49597, lat: 40.339356, postalCode: "45351" },
    { lng: -83.50215, lat: 41.28953, postalCode: "43466" },
    { lng: -81.35302, lat: 41.148756, postalCode: "44240" },
    { lng: -83.56172, lat: 40.223286, postalCode: "43060" },
    { lng: -83.82609, lat: 39.796992, postalCode: "45316" },
    { lng: -81.2396, lat: 41.716094, postalCode: "44077" },
    { lng: -81.0763, lat: 40.009383, postalCode: "43719" },
    { lng: -82.19224, lat: 39.888751, postalCode: "43760" },
    { lng: -80.761269, lat: 41.317424, postalCode: "44487" },
    { lng: -82.754034, lat: 39.996516, postalCode: "43073" },
    { lng: -84.02446, lat: 40.170605, postalCode: "45317" },
    { lng: -83.672, lat: 41.576992, postalCode: "43537" },
    { lng: -84.2651, lat: 39.258653, postalCode: "45140" },
    { lng: -82.97127, lat: 40.012714, postalCode: "43211" },
    { lng: -82.46212, lat: 38.592851, postalCode: "45696" },
    { lng: -84.56377, lat: 41.137873, postalCode: "45879" },
    { lng: -84.57871, lat: 40.874092, postalCode: "45891" },
    { lng: -83.57313, lat: 41.63105, postalCode: "43609" },
    { lng: -84.43426, lat: 39.13786, postalCode: "45208" },
    { lng: -81.282125, lat: 41.742693, postalCode: "44045" },
    { lng: -81.070017, lat: 41.159706, postalCode: "44285" },
    { lng: -83.64363, lat: 41.707649, postalCode: "43623" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44372" },
    { lng: -84.77001, lat: 39.257936, postalCode: "45030" },
    { lng: -81.249027, lat: 41.910676, postalCode: "44096" },
    { lng: -81.80467, lat: 41.432149, postalCode: "44135" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45201" },
    { lng: -82.16324, lat: 40.161484, postalCode: "43822" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43086" },
    { lng: -83.29505, lat: 41.057873, postalCode: "44853" },
    { lng: -82.35543, lat: 40.351781, postalCode: "43022" },
    { lng: -84.58733, lat: 39.258124, postalCode: "45251" },
    { lng: -83.6064, lat: 41.671761, postalCode: "43606" },
    { lng: -82.88443, lat: 40.583666, postalCode: "43320" },
    { lng: -81.699683, lat: 40.80066, postalCode: "44168" },
    { lng: -81.22911, lat: 41.286755, postalCode: "44255" },
    { lng: -84.53709, lat: 39.990909, postalCode: "45304" },
    { lng: -81.70342, lat: 41.448951, postalCode: "44109" },
    { lng: -84.23841, lat: 39.784108, postalCode: "45406" },
    { lng: -81.51026, lat: 41.076425, postalCode: "44325" },
    { lng: -81.23143, lat: 41.159224, postalCode: "44266" },
    { lng: -82.3047, lat: 41.267051, postalCode: "44049" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45235" },
    { lng: -84.08602, lat: 40.831339, postalCode: "45820" },
    { lng: -84.45548, lat: 40.892251, postalCode: "45863" },
    { lng: -84.25804, lat: 39.805207, postalCode: "45416" },
    { lng: -83.75816, lat: 40.365923, postalCode: "43311" },
    { lng: -80.55862, lat: 40.966908, postalCode: "44442" },
    { lng: -83.89259, lat: 40.886803, postalCode: "45817" },
    { lng: -83.37752, lat: 38.987596, postalCode: "45660" },
    { lng: -84.29093, lat: 39.195516, postalCode: "45111" },
    { lng: -84.47383, lat: 39.288558, postalCode: "45246" },
    { lng: -83.949934, lat: 40.758211, postalCode: "45854" },
    { lng: -80.76929, lat: 40.566375, postalCode: "43930" },
    { lng: -81.7981, lat: 40.561587, postalCode: "44610" },
    { lng: -82.67652, lat: 39.994001, postalCode: "43062" },
    { lng: -84.37211, lat: 40.391924, postalCode: "45865" },
    { lng: -84.40709, lat: 41.491112, postalCode: "43557" },
    { lng: -84.414484, lat: 39.963492, postalCode: "45361" },
    { lng: -84.62255, lat: 39.903258, postalCode: "45382" },
    { lng: -83.49003, lat: 41.703379, postalCode: "43611" },
    { lng: -80.802854, lat: 41.017082, postalCode: "44598" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45296" },
    { lng: -84.51744, lat: 40.400409, postalCode: "45860" },
    { lng: -84.41881, lat: 39.180893, postalCode: "45213" },
    { lng: -83.98239, lat: 39.397066, postalCode: "45113" },
    { lng: -82.74991, lat: 40.795521, postalCode: "44827" },
    { lng: -80.76333, lat: 40.899731, postalCode: "44490" },
    { lng: -81.77938, lat: 40.971727, postalCode: "44270" },
    { lng: -82.24031, lat: 41.147116, postalCode: "44090" },
    { lng: -83.83446, lat: 41.697701, postalCode: "43504" },
    { lng: -81.38548, lat: 40.836508, postalCode: "44709" },
    { lng: -82.47612, lat: 40.773556, postalCode: "44905" },
    { lng: -81.86259, lat: 41.36995, postalCode: "44017" },
    { lng: -80.704245, lat: 40.76304, postalCode: "44415" },
    { lng: -81.64922, lat: 39.85261, postalCode: "43732" },
    { lng: -84.05898, lat: 39.813743, postalCode: "45433" },
    { lng: -81.28215, lat: 40.283493, postalCode: "44699" },
    { lng: -82.29337, lat: 38.919179, postalCode: "45614" },
    { lng: -84.50774, lat: 39.10441, postalCode: "45202" },
    { lng: -83.621787, lat: 38.892198, postalCode: "45618" },
    { lng: -84.21176, lat: 38.810072, postalCode: "45156" },
    { lng: -81.463238, lat: 41.027977, postalCode: "44315" },
    { lng: -83.61925, lat: 38.690566, postalCode: "45144" },
    { lng: -83.74024, lat: 41.419152, postalCode: "43565" },
    { lng: -83.0009, lat: 39.123071, postalCode: "45690" },
    { lng: -82.33622, lat: 41.019566, postalCode: "44859" },
    { lng: -84.612173, lat: 41.163632, postalCode: "45829" },
    { lng: -80.891369, lat: 39.676317, postalCode: "43931" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45298" },
    { lng: -83.416306, lat: 40.340633, postalCode: "43007" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44399" },
    { lng: -80.802854, lat: 41.017082, postalCode: "44422" },
    { lng: -81.58374, lat: 40.406106, postalCode: "43840" },
    { lng: -83.14762, lat: 40.021665, postalCode: "43026" },
    { lng: -82.59613, lat: 39.960661, postalCode: "43033" },
    { lng: -84.02586, lat: 39.600367, postalCode: "45370" },
    { lng: -82.56998, lat: 40.493191, postalCode: "43019" },
    { lng: -84.254241, lat: 41.438281, postalCode: "43355" },
    { lng: -82.07959, lat: 39.475889, postalCode: "45740" },
    { lng: -83.62142, lat: 40.000364, postalCode: "43010" },
    { lng: -82.87777, lat: 39.459353, postalCode: "45644" },
    { lng: -80.66843, lat: 40.621521, postalCode: "43968" },
    { lng: -83.13144, lat: 40.815261, postalCode: "44849" },
    { lng: -81.83724, lat: 39.143542, postalCode: "45772" },
    { lng: -80.73327, lat: 40.103877, postalCode: "43935" },
    { lng: -84.33611, lat: 40.260111, postalCode: "45333" },
    { lng: -84.02525, lat: 40.055737, postalCode: "45389" },
    { lng: -80.970135, lat: 40.011338, postalCode: "43759" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43655" },
    { lng: -84.55885, lat: 41.298409, postalCode: "43556" },
    { lng: -82.09325, lat: 39.617263, postalCode: "43730" },
    { lng: -82.17789, lat: 40.3479, postalCode: "43843" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45222" },
    { lng: -81.49039, lat: 41.046055, postalCode: "44306" },
    { lng: -81.371185, lat: 40.782408, postalCode: "44701" },
    { lng: -84.49453, lat: 41.635979, postalCode: "43531" },
    { lng: -83.39748, lat: 39.343559, postalCode: "45123" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43284" },
    { lng: -80.76343, lat: 40.01558, postalCode: "43906" },
    { lng: -84.232422, lat: 39.104387, postalCode: "45125" },
    { lng: -83.7102, lat: 41.706383, postalCode: "43560" },
    { lng: -80.89033, lat: 39.938218, postalCode: "43933" },
    { lng: -83.73685, lat: 41.501902, postalCode: "43566" },
    { lng: -84.06299, lat: 39.44415, postalCode: "45054" },
    { lng: -84.01972, lat: 39.808909, postalCode: "45324" },
    { lng: -83.22028, lat: 38.866354, postalCode: "45657" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43216" },
    { lng: -81.82651, lat: 40.951253, postalCode: "44276" },
    { lng: -83.55996, lat: 41.676299, postalCode: "43610" },
    { lng: -81.50101, lat: 40.805131, postalCode: "44646" },
    { lng: -84.1331, lat: 40.668448, postalCode: "45806" },
    { lng: -82.797419, lat: 41.532003, postalCode: "43400" },
    { lng: -81.51813, lat: 41.081755, postalCode: "44308" },
    { lng: -81.19784, lat: 41.09464, postalCode: "44272" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44398" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43218" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45273" },
    { lng: -83.96705, lat: 38.789585, postalCode: "45131" },
    { lng: -83.95347, lat: 40.555807, postalCode: "45870" },
    { lng: -82.94294, lat: 38.762824, postalCode: "45662" },
    { lng: -83.3828, lat: 40.585905, postalCode: "43332" },
    { lng: -83.199472, lat: 39.773025, postalCode: "43117" },
    { lng: -80.76475, lat: 41.226957, postalCode: "44484" },
    { lng: -81.33378, lat: 41.688162, postalCode: "44060" },
    { lng: -83.67053, lat: 41.18394, postalCode: "45872" },
    { lng: -84.58332, lat: 41.088144, postalCode: "45855" },
    { lng: -83.89239, lat: 39.797227, postalCode: "45387" },
    { lng: -84.39098, lat: 41.107804, postalCode: "45873" },
    { lng: -83.8128, lat: 39.926617, postalCode: "45502" },
    { lng: -81.05753, lat: 41.674336, postalCode: "44086" },
    { lng: -84.67806, lat: 40.682303, postalCode: "45882" },
    { lng: -84.35509, lat: 40.711394, postalCode: "45887" },
    { lng: -82.96925, lat: 41.304387, postalCode: "43410" },
    { lng: -81.734329, lat: 41.09924, postalCode: "44274" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45264" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45423" },
    { lng: -84.41948, lat: 41.090714, postalCode: "45861" },
    { lng: -82.50017, lat: 39.171012, postalCode: "45634" },
    { lng: -83.83243, lat: 39.910109, postalCode: "45506" },
    { lng: -83.433, lat: 41.5678, postalCode: "43447" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45482" },
    { lng: -83.88554, lat: 40.469231, postalCode: "43348" },
    { lng: -83.93725, lat: 41.702622, postalCode: "43540" },
    { lng: -81.674717, lat: 40.683695, postalCode: "44331" },
    { lng: -80.643646, lat: 41.103858, postalCode: "44555" },
    { lng: -81.54584, lat: 41.586749, postalCode: "44119" },
    { lng: -84.15294, lat: 39.762708, postalCode: "45403" },
    { lng: -82.86059, lat: 41.234403, postalCode: "44828" },
    { lng: -82.69952, lat: 40.394773, postalCode: "43350" },
    { lng: -81.35014, lat: 40.565209, postalCode: "44678" },
    { lng: -84.395196, lat: 41.425554, postalCode: "43579" },
    { lng: -81.57022, lat: 41.083155, postalCode: "44320" },
    { lng: -83.26289, lat: 40.353133, postalCode: "43036" },
    { lng: -84.78591, lat: 39.352687, postalCode: "45053" },
    { lng: -81.05025, lat: 41.024608, postalCode: "44411" },
    { lng: -81.488516, lat: 40.440407, postalCode: "44679" },
    { lng: -82.94842, lat: 39.720751, postalCode: "43103" },
    { lng: -83.73199, lat: 40.881217, postalCode: "45841" },
    { lng: -84.35433, lat: 40.123474, postalCode: "45318" },
    { lng: -84.33049, lat: 39.278295, postalCode: "45249" },
    { lng: -84.75912, lat: 40.409441, postalCode: "45846" },
    { lng: -81.66209, lat: 39.40032, postalCode: "45712" },
    { lng: -83.80463, lat: 41.106245, postalCode: "45858" },
    { lng: -82.9688, lat: 39.9718, postalCode: "43203" },
    { lng: -83.33988, lat: 41.602898, postalCode: "43468" },
    { lng: -81.36885, lat: 40.641188, postalCode: "44671" },
    { lng: -83.40267, lat: 39.624916, postalCode: "43106" },
    { lng: -80.59512, lat: 41.051255, postalCode: "44471" },
    { lng: -84.30196, lat: 39.802606, postalCode: "45426" },
    { lng: -83.97325, lat: 40.308916, postalCode: "43343" },
    { lng: -80.66467, lat: 41.032675, postalCode: "44512" },
    { lng: -80.979435, lat: 40.843563, postalCode: "44665" },
    { lng: -83.46681, lat: 40.355958, postalCode: "43067" },
    { lng: -84.36042, lat: 39.242559, postalCode: "45242" },
    { lng: -83.80743, lat: 39.213112, postalCode: "45142" },
    { lng: -82.13589, lat: 38.943285, postalCode: "45620" },
    { lng: -83.08415, lat: 40.155515, postalCode: "43065" },
    { lng: -80.62652, lat: 41.095658, postalCode: "44506" },
    { lng: -81.6953, lat: 40.616646, postalCode: "44690" },
    { lng: -82.2586, lat: 39.32502, postalCode: "45766" },
    { lng: -84.33335, lat: 39.958117, postalCode: "45383" },
    { lng: -81.92865, lat: 41.454439, postalCode: "44145" },
    { lng: -83.86432, lat: 39.362778, postalCode: "45114" },
    { lng: -82.98085, lat: 39.944564, postalCode: "43206" },
    { lng: -83.806053, lat: 41.597362, postalCode: "43538" },
    { lng: -84.22716, lat: 41.531159, postalCode: "43553" },
    { lng: -84.46983, lat: 39.142246, postalCode: "45207" },
    { lng: -81.14682, lat: 40.983308, postalCode: "44640" },
    { lng: -84.55562, lat: 39.588878, postalCode: "45070" },
    { lng: -84.21132, lat: 39.371242, postalCode: "45065" },
    { lng: -81.39929, lat: 41.625886, postalCode: "44094" },
    { lng: -84.6073, lat: 40.9871, postalCode: "45886" },
    { lng: -84.22175, lat: 39.440152, postalCode: "45036" },
    { lng: -84.52749, lat: 39.647494, postalCode: "45330" },
    { lng: -81.599648, lat: 41.523401, postalCode: "44101" },
    { lng: -80.85756, lat: 41.205342, postalCode: "44481" },
    { lng: -80.96214, lat: 40.623637, postalCode: "44651" },
    { lng: -84.77422, lat: 40.799531, postalCode: "45899" },
    { lng: -81.3731, lat: 40.433496, postalCode: "44653" },
    { lng: -83.11217, lat: 41.35307, postalCode: "43420" },
    { lng: -82.60887, lat: 41.100202, postalCode: "44855" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43271" },
    { lng: -84.218308, lat: 40.333611, postalCode: "45367" },
    { lng: -82.09728, lat: 39.322847, postalCode: "45701" },
    { lng: -83.88767, lat: 39.290475, postalCode: "45148" },
    { lng: -81.06617, lat: 41.372452, postalCode: "44080" },
    { lng: -84.17685, lat: 39.954239, postalCode: "45371" },
    { lng: -80.66265, lat: 41.236248, postalCode: "44473" },
    { lng: -84.15893, lat: 39.748309, postalCode: "45410" },
    { lng: -82.532057, lat: 40.772874, postalCode: "44862" },
    { lng: -83.02148, lat: 40.003681, postalCode: "43210" },
    { lng: -82.89437, lat: 40.202298, postalCode: "43021" },
    { lng: -82.75881, lat: 41.533186, postalCode: "43440" },
    { lng: -80.9853, lat: 41.100329, postalCode: "44429" },
    { lng: -82.430017, lat: 39.968846, postalCode: "43030" },
    { lng: -83.45714, lat: 40.863848, postalCode: "43359" },
    { lng: -82.84296, lat: 41.257163, postalCode: "44811" },
    { lng: -84.62292, lat: 41.609365, postalCode: "43543" },
    { lng: -83.65795, lat: 41.388519, postalCode: "43402" },
    { lng: -82.21935, lat: 41.2863, postalCode: "44074" },
    { lng: -83.00431, lat: 39.965131, postalCode: "43215" },
    { lng: -82.41455, lat: 39.778306, postalCode: "43150" },
    { lng: -81.81724, lat: 39.873402, postalCode: "43727" },
    { lng: -81.50986, lat: 39.533021, postalCode: "45744" },
    { lng: -83.52862, lat: 40.782854, postalCode: "45843" },
    { lng: -82.36215, lat: 38.99754, postalCode: "45686" },
    { lng: -82.29166, lat: 39.801679, postalCode: "43783" },
    { lng: -82.82462, lat: 40.080252, postalCode: "43054" },
    { lng: -83.085764, lat: 39.072829, postalCode: "45687" },
    { lng: -82.0046, lat: 41.389546, postalCode: "44039" },
    { lng: -84.54783, lat: 40.488771, postalCode: "45866" },
    { lng: -80.942716, lat: 41.459359, postalCode: "44439" },
    { lng: -81.43368, lat: 39.803205, postalCode: "43779" },
    { lng: -81.91737, lat: 39.621481, postalCode: "43758" },
    { lng: -81.842313, lat: 39.168349, postalCode: "45783" },
    { lng: -81.42778, lat: 40.853958, postalCode: "44760" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45018" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44199" },
    { lng: -81.32814, lat: 40.96289, postalCode: "44632" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43659" },
    { lng: -81.20232, lat: 40.147075, postalCode: "43983" },
    { lng: -84.417891, lat: 39.070561, postalCode: "45228" },
    { lng: -83.862877, lat: 38.946921, postalCode: "45275" },
    { lng: -81.83197, lat: 41.312752, postalCode: "44136" },
    { lng: -80.66811, lat: 41.120507, postalCode: "44510" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45412" },
    { lng: -81.368284, lat: 40.811809, postalCode: "44799" },
    { lng: -84.39324, lat: 39.276271, postalCode: "45241" },
    { lng: -83.8075, lat: 38.763305, postalCode: "45167" },
    { lng: -81.312416, lat: 41.169816, postalCode: "44211" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44188" },
    { lng: -84.55382, lat: 39.480856, postalCode: "45062" },
    { lng: -81.53973, lat: 41.089405, postalCode: "44302" },
    { lng: -81.8775, lat: 41.022387, postalCode: "44273" },
    { lng: -81.61616, lat: 41.017522, postalCode: "44203" },
    { lng: -84.65016, lat: 40.332705, postalCode: "45348" },
    { lng: -81.11291, lat: 40.147388, postalCode: "43977" },
    { lng: -81.472701, lat: 41.172713, postalCode: "44326" },
    { lng: -81.55434, lat: 41.499202, postalCode: "44118" },
    { lng: -84.28717, lat: 40.914909, postalCode: "45844" },
    { lng: -84.49935, lat: 40.231735, postalCode: "45380" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43260" },
    { lng: -81.52953, lat: 40.990922, postalCode: "44319" },
    { lng: -84.38731, lat: 40.452556, postalCode: "45869" },
    { lng: -83.57223, lat: 40.052907, postalCode: "43044" },
    { lng: -81.50091, lat: 41.107655, postalCode: "44310" },
    { lng: -83.109783, lat: 41.024204, postalCode: "44845" },
    { lng: -80.685034, lat: 41.904594, postalCode: "44068" },
    { lng: -83.44868, lat: 40.108356, postalCode: "43029" },
    { lng: -81.51947, lat: 39.917012, postalCode: "43722" },
    { lng: -81.90624, lat: 40.976892, postalCode: "44217" },
    { lng: -83.20426, lat: 40.285343, postalCode: "43061" },
    { lng: -80.802854, lat: 41.017082, postalCode: "44416" },
    { lng: -82.96046, lat: 38.793335, postalCode: "45699" },
    { lng: -83.05827, lat: 41.249374, postalCode: "44836" },
    { lng: -84.4545, lat: 39.160693, postalCode: "45212" },
    { lng: -83.47664, lat: 41.4031, postalCode: "43450" },
    { lng: -81.64287, lat: 41.513801, postalCode: "44103" },
    { lng: -82.12114, lat: 40.957955, postalCode: "44287" },
    { lng: -80.802854, lat: 41.017082, postalCode: "44513" },
    { lng: -82.739875, lat: 39.882017, postalCode: "43187" },
    { lng: -80.87487, lat: 41.385826, postalCode: "44402" },
    { lng: -84.22362, lat: 39.741558, postalCode: "45408" },
    { lng: -84.11606, lat: 39.350319, postalCode: "45152" },
    { lng: -81.79548, lat: 39.675911, postalCode: "43756" },
    { lng: -83.62611, lat: 41.60135, postalCode: "43614" },
    { lng: -83.05382, lat: 39.047577, postalCode: "45642" },
    { lng: -84.73783, lat: 39.180358, postalCode: "45002" },
    { lng: -82.21779, lat: 41.038842, postalCode: "44880" },
    { lng: -84.78292, lat: 40.20451, postalCode: "45390" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45435" },
    { lng: -81.67659, lat: 41.321827, postalCode: "44147" },
    { lng: -81.37669, lat: 39.5103, postalCode: "45788" },
    { lng: -82.0063, lat: 41.501595, postalCode: "44012" },
    { lng: -82.15977, lat: 40.063886, postalCode: "43830" },
    { lng: -83.7039, lat: 38.815056, postalCode: "45115" },
    { lng: -81.38102, lat: 40.695377, postalCode: "44626" },
    { lng: -80.86736, lat: 41.090089, postalCode: "44451" },
    { lng: -81.65009, lat: 41.469951, postalCode: "44127" },
    { lng: -82.96124, lat: 40.40994, postalCode: "43003" },
    { lng: -84.55459, lat: 41.471569, postalCode: "43506" },
    { lng: -83.87384, lat: 41.585948, postalCode: "43558" },
    { lng: -82.13406, lat: 39.370636, postalCode: "45780" },
    { lng: -84.54259, lat: 39.161859, postalCode: "45223" },
    { lng: -83.54226, lat: 41.193747, postalCode: "44817" },
    { lng: -81.36352, lat: 41.320612, postalCode: "44202" },
    { lng: -82.97326, lat: 40.085313, postalCode: "43229" },
    { lng: -80.584681, lat: 41.309096, postalCode: "44424" },
    { lng: -81.34495, lat: 39.611675, postalCode: "45745" },
    { lng: -84.643096, lat: 39.09646, postalCode: "45051" },
    { lng: -83.78346, lat: 39.553045, postalCode: "45164" },
    { lng: -83.05567, lat: 40.099204, postalCode: "43235" },
    { lng: -84.29732, lat: 41.66143, postalCode: "43521" },
    { lng: -84.39324, lat: 39.534907, postalCode: "45042" },
    { lng: -82.01828, lat: 39.191685, postalCode: "45776" },
    { lng: -84.41774, lat: 39.844538, postalCode: "45309" },
    { lng: -84.16573, lat: 40.7953, postalCode: "45807" },
    { lng: -84.52271, lat: 39.404856, postalCode: "45011" },
    { lng: -82.80569, lat: 41.393507, postalCode: "44824" },
    { lng: -82.532715, lat: 38.536685, postalCode: "45675" },
    { lng: -83.619892, lat: 39.999367, postalCode: "45010" },
    { lng: -84.26016, lat: 39.837256, postalCode: "45415" },
    { lng: -82.822697, lat: 41.715332, postalCode: "43436" },
    { lng: -83.508531, lat: 41.509051, postalCode: "43463" },
    { lng: -82.31514, lat: 40.867016, postalCode: "44805" },
    { lng: -80.83579, lat: 41.6128, postalCode: "44085" },
    { lng: -80.761269, lat: 41.317424, postalCode: "44486" },
    { lng: -84.77788, lat: 39.876255, postalCode: "45347" },
    { lng: -81.30882, lat: 40.646529, postalCode: "44643" },
    { lng: -83.84446, lat: 39.463476, postalCode: "45177" },
    { lng: -81.88179, lat: 39.806956, postalCode: "43720" },
    { lng: -82.41177, lat: 40.701792, postalCode: "44843" },
    { lng: -82.5568, lat: 41.388534, postalCode: "44839" },
    { lng: -83.83702, lat: 39.941827, postalCode: "45504" },
    { lng: -84.73356, lat: 41.441679, postalCode: "43517" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43652" },
    { lng: -81.54108, lat: 39.968985, postalCode: "43723" },
    { lng: -82.60185, lat: 41.236357, postalCode: "44857" },
    { lng: -83.43802, lat: 41.328037, postalCode: "43406" },
    { lng: -82.26422, lat: 40.470806, postalCode: "43014" },
    { lng: -80.87017, lat: 40.223285, postalCode: "43901" },
    { lng: -83.37072, lat: 41.523309, postalCode: "43430" },
    { lng: -81.106797, lat: 39.884291, postalCode: "43741" },
    { lng: -81.54591, lat: 41.069605, postalCode: "44307" },
    { lng: -82.72435, lat: 41.073672, postalCode: "44890" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43251" },
    { lng: -80.877264, lat: 40.130167, postalCode: "43937" },
    { lng: -83.53257, lat: 41.678248, postalCode: "43608" },
    { lng: -80.835767, lat: 40.263948, postalCode: "43941" },
    { lng: -81.93433, lat: 41.316001, postalCode: "44028" },
    { lng: -81.70125, lat: 41.484688, postalCode: "44113" },
    { lng: -84.35214, lat: 39.137761, postalCode: "45127" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45270" },
    { lng: -81.26298, lat: 40.843685, postalCode: "44641" },
    { lng: -80.76396, lat: 41.029666, postalCode: "44406" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44179" },
    { lng: -82.09349, lat: 39.742354, postalCode: "43731" },
    { lng: -82.22975, lat: 39.450463, postalCode: "45764" },
    { lng: -84.178939, lat: 39.740509, postalCode: "45469" },
    { lng: -80.69471, lat: 41.106249, postalCode: "44509" },
    { lng: -83.2966, lat: 41.249535, postalCode: "44841" },
    { lng: -84.7407, lat: 39.498751, postalCode: "45056" },
    { lng: -84.138566, lat: 40.781785, postalCode: "45802" },
    { lng: -83.74587, lat: 39.62434, postalCode: "45335" },
    { lng: -84.6325, lat: 40.413734, postalCode: "45883" },
    { lng: -83.82923, lat: 41.301347, postalCode: "43541" },
    { lng: -80.68652, lat: 40.884299, postalCode: "44408" },
    { lng: -82.57704, lat: 39.545786, postalCode: "43149" },
    { lng: -84.11029, lat: 39.674192, postalCode: "45440" },
    { lng: -82.561556, lat: 40.132821, postalCode: "43027" },
    { lng: -82.281695, lat: 40.920617, postalCode: "44848" },
    { lng: -82.94384, lat: 39.824831, postalCode: "43217" },
    { lng: -80.71859, lat: 40.561512, postalCode: "43932" },
    { lng: -84.21688, lat: 39.695702, postalCode: "45439" },
    { lng: -84.63891, lat: 40.28577, postalCode: "45362" },
    { lng: -81.41052, lat: 41.532584, postalCode: "44040" },
    { lng: -82.16773, lat: 39.089885, postalCode: "45775" },
    { lng: -80.80773, lat: 41.252748, postalCode: "44483" },
    { lng: -81.03666, lat: 41.462346, postalCode: "44062" },
    { lng: -81.39232, lat: 41.43932, postalCode: "44022" },
    { lng: -80.62344, lat: 40.510729, postalCode: "43926" },
    { lng: -82.60611, lat: 41.306205, postalCode: "44846" },
    { lng: -82.80872, lat: 39.854413, postalCode: "43110" },
    { lng: -81.40593, lat: 40.776858, postalCode: "44706" },
    { lng: -84.2099, lat: 39.023067, postalCode: "45102" },
    { lng: -82.70909, lat: 41.238863, postalCode: "44847" },
    { lng: -81.37499, lat: 40.798859, postalCode: "44702" },
    { lng: -84.21111, lat: 39.788458, postalCode: "45405" },
    { lng: -82.872954, lat: 40.60796, postalCode: "43325" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44178" },
    { lng: -81.92977, lat: 39.262298, postalCode: "45735" },
    { lng: -82.44553, lat: 38.448563, postalCode: "45619" },
    { lng: -83.648996, lat: 41.39235, postalCode: "43403" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44184" },
    { lng: -80.99193, lat: 40.374942, postalCode: "43986" },
    { lng: -83.56924, lat: 40.307923, postalCode: "43319" },
    { lng: -81.45532, lat: 39.627217, postalCode: "45746" },
    { lng: -81.70507, lat: 41.393852, postalCode: "44134" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45271" },
    { lng: -81.10432, lat: 40.405788, postalCode: "43988" },
    { lng: -83.94049, lat: 39.990945, postalCode: "45349" },
    { lng: -80.859104, lat: 40.012984, postalCode: "43960" },
    { lng: -84.20027, lat: 40.983471, postalCode: "45853" },
    { lng: -82.3572, lat: 39.460218, postalCode: "43158" },
    { lng: -82.68056, lat: 41.034683, postalCode: "44850" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44222" },
    { lng: -82.7961, lat: 39.956384, postalCode: "43068" },
    { lng: -83.60235, lat: 39.942716, postalCode: "45369" },
    { lng: -80.955545, lat: 41.837043, postalCode: "44073" },
    { lng: -81.58327, lat: 40.886688, postalCode: "44614" },
    { lng: -82.68307, lat: 39.956994, postalCode: "43018" },
    { lng: -81.62932, lat: 39.58849, postalCode: "45715" },
    { lng: -81.921443, lat: 39.085629, postalCode: "45720" },
    { lng: -84.04389, lat: 40.446467, postalCode: "45334" },
    { lng: -80.81953, lat: 40.361891, postalCode: "43910" },
    { lng: -80.70789, lat: 40.152615, postalCode: "43971" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45470" },
    { lng: -84.64678, lat: 40.480128, postalCode: "45828" },
    { lng: -81.05515, lat: 41.61391, postalCode: "44064" },
    { lng: -84.259292, lat: 39.746656, postalCode: "45428" },
    { lng: -84.63748, lat: 41.299247, postalCode: "43536" },
    { lng: -81.53483, lat: 39.895548, postalCode: "43772" },
    { lng: -83.9514, lat: 40.945888, postalCode: "45877" },
    { lng: -82.94064, lat: 40.076042, postalCode: "43231" },
    { lng: -81.43744, lat: 41.174256, postalCode: "44224" },
    { lng: -81.44373, lat: 39.426551, postalCode: "45750" },
    { lng: -81.06021, lat: 41.80063, postalCode: "44057" },
    { lng: -83.24361, lat: 38.947693, postalCode: "45671" },
    { lng: -80.74507, lat: 41.728451, postalCode: "44047" },
    { lng: -81.33548, lat: 39.799978, postalCode: "43788" },
    { lng: -83.677197, lat: 39.480543, postalCode: "45166" },
    { lng: -82.77917, lat: 39.554697, postalCode: "43156" },
    { lng: -81.554854, lat: 39.827556, postalCode: "43711" },
    { lng: -84.70421, lat: 39.213282, postalCode: "45041" },
    { lng: -81.65092, lat: 41.102587, postalCode: "44321" },
    { lng: -84.20629, lat: 40.036525, postalCode: "45373" },
    { lng: -81.00605, lat: 41.099351, postalCode: "44412" },
    { lng: -84.27074, lat: 39.631525, postalCode: "45342" },
    { lng: -84.00579, lat: 39.499635, postalCode: "45032" },
    { lng: -83.54525, lat: 41.657099, postalCode: "43624" },
    { lng: -83.36391, lat: 40.255973, postalCode: "43040" },
    { lng: -83.00894, lat: 40.018814, postalCode: "43202" },
    { lng: -82.381351, lat: 39.118117, postalCode: "45670" },
    { lng: -81.4351, lat: 40.359172, postalCode: "44629" },
    { lng: -81.09148, lat: 40.733459, postalCode: "44657" },
    { lng: -80.76692, lat: 40.43273, postalCode: "43944" },
    { lng: -84.09097, lat: 40.330423, postalCode: "45360" },
    { lng: -83.532883, lat: 41.654649, postalCode: "43660" },
    { lng: -84.06923, lat: 39.525562, postalCode: "45068" },
    { lng: -81.68122, lat: 39.389346, postalCode: "45784" },
    { lng: -82.81278, lat: 41.671574, postalCode: "43456" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44194" },
    { lng: -81.47928, lat: 41.141456, postalCode: "44221" },
    { lng: -82.84927, lat: 39.015223, postalCode: "45613" },
    { lng: -81.418943, lat: 40.784646, postalCode: "44750" },
    { lng: -81.111175, lat: 41.53335, postalCode: "44033" },
    { lng: -82.98836, lat: 38.911803, postalCode: "45648" },
    { lng: -81.461987, lat: 40.932506, postalCode: "44232" },
    { lng: -83.011389, lat: 39.969036, postalCode: "43266" },
    { lng: -81.19054, lat: 40.76291, postalCode: "44670" },
    { lng: -81.525556, lat: 41.071603, postalCode: "44397" },
    { lng: -84.575746, lat: 39.440956, postalCode: "45023" },
    { lng: -82.828817, lat: 38.592446, postalCode: "45636" },
    { lng: -83.28776, lat: 39.710455, postalCode: "43143" },
    { lng: -84.619129, lat: 40.135426, postalCode: "45329" },
    { lng: -83.05047, lat: 40.461613, postalCode: "43356" },
    { lng: -81.0573, lat: 40.832092, postalCode: "44634" },
    { lng: -81.74204, lat: 41.313758, postalCode: "44133" },
    { lng: -80.86015, lat: 40.450257, postalCode: "43925" },
    { lng: -82.80802, lat: 39.802655, postalCode: "43136" },
    { lng: -83.1667, lat: 39.939871, postalCode: "43119" },
    { lng: -83.3192, lat: 38.789223, postalCode: "45616" },
    { lng: -81.5958, lat: 39.297622, postalCode: "45714" },
    { lng: -83.83325, lat: 40.013871, postalCode: "45372" },
    { lng: -81.326195, lat: 40.895057, postalCode: "44652" },
    { lng: -81.643638, lat: 40.357237, postalCode: "43803" },
    { lng: -81.01773, lat: 40.109149, postalCode: "43951" },
    { lng: -84.38865, lat: 39.078368, postalCode: "45230" },
    { lng: -83.646581, lat: 40.204914, postalCode: "43047" },
    { lng: -83.11298, lat: 39.590417, postalCode: "43164" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43657" },
    { lng: -82.84096, lat: 38.877777, postalCode: "45653" },
    { lng: -83.658937, lat: 41.653788, postalCode: "43699" },
    { lng: -84.57299, lat: 39.207558, postalCode: "45239" },
    { lng: -83.41412, lat: 41.053889, postalCode: "44802" },
    { lng: -81.43787, lat: 41.138806, postalCode: "44262" },
    { lng: -82.35278, lat: 41.331398, postalCode: "44816" },
    { lng: -82.010132, lat: 39.368235, postalCode: "45777" },
    { lng: -83.48948, lat: 41.245763, postalCode: "43467" },
    { lng: -80.75297, lat: 41.186257, postalCode: "44446" },
    { lng: -83.648996, lat: 41.39235, postalCode: "43552" },
    { lng: -82.01079, lat: 40.140472, postalCode: "43842" },
    { lng: -84.53575, lat: 39.12056, postalCode: "45214" },
    { lng: -81.91436, lat: 41.415097, postalCode: "44070" },
    { lng: -81.500579, lat: 39.994467, postalCode: "43750" },
    { lng: -84.739682, lat: 39.147991, postalCode: "45000" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43667" },
    { lng: -83.28328, lat: 41.132988, postalCode: "44809" },
    { lng: -83.70007, lat: 40.768846, postalCode: "45835" },
    { lng: -80.58862, lat: 41.443358, postalCode: "44428" },
    { lng: -83.08231, lat: 39.958496, postalCode: "43204" },
    { lng: -84.07558, lat: 41.696808, postalCode: "43533" },
    { lng: -84.56428, lat: 39.093345, postalCode: "45204" },
    { lng: -81.19473, lat: 41.574171, postalCode: "44024" },
    { lng: -83.38068, lat: 40.954293, postalCode: "43316" },
    { lng: -82.52134, lat: 40.070985, postalCode: "43023" },
    { lng: -80.96964, lat: 41.188981, postalCode: "44444" },
    { lng: -82.28218, lat: 40.285043, postalCode: "43005" },
    { lng: -83.363, lat: 41.565049, postalCode: "43408" },
    { lng: -83.26087, lat: 40.990406, postalCode: "44844" },
    { lng: -81.11139, lat: 39.862128, postalCode: "43747" },
    { lng: -80.64172, lat: 40.318569, postalCode: "43938" },
    { lng: -84.42526, lat: 39.114911, postalCode: "45226" },
    { lng: -84.255, lat: 41.437905, postalCode: "43555" },
    { lng: -83.941281, lat: 41.153855, postalCode: "45815" },
    { lng: -81.20037, lat: 41.020057, postalCode: "44201" },
    { lng: -84.38393, lat: 39.482957, postalCode: "45044" },
    { lng: -81.5936, lat: 39.420916, postalCode: "45729" },
    { lng: -84.53822, lat: 39.166759, postalCode: "45999" },
    { lng: -81.45442, lat: 40.643724, postalCode: "44612" },
    { lng: -84.148028, lat: 40.882077, postalCode: "45893" },
    { lng: -80.96327, lat: 41.369527, postalCode: "44491" },
    { lng: -81.82836, lat: 41.244051, postalCode: "44212" },
    { lng: -82.07788, lat: 39.483798, postalCode: "45782" },
    { lng: -80.86094, lat: 40.898658, postalCode: "44460" },
    { lng: -80.77447, lat: 40.074284, postalCode: "43912" },
    { lng: -83.52652, lat: 41.117339, postalCode: "44804" },
    { lng: -82.61356, lat: 39.860353, postalCode: "43105" },
    { lng: -83.07989, lat: 39.88628, postalCode: "43123" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43635" },
    { lng: -80.53175, lat: 41.22832, postalCode: "44438" },
    { lng: -84.12511, lat: 41.388278, postalCode: "43545" },
    { lng: -84.25371, lat: 41.103903, postalCode: "45831" },
    { lng: -80.96151, lat: 39.646462, postalCode: "43946" },
    { lng: -80.533572, lat: 41.32519, postalCode: "44453" },
    { lng: -83.47735, lat: 41.455078, postalCode: "43443" },
    { lng: -82.99802, lat: 41.140139, postalCode: "44867" },
    { lng: -82.31485, lat: 40.42065, postalCode: "43028" },
    { lng: -83.14177, lat: 40.953556, postalCode: "44882" },
    { lng: -83.497155, lat: 41.678167, postalCode: "43656" },
    { lng: -81.08063, lat: 41.541756, postalCode: "44046" },
    { lng: -83.59926, lat: 39.344299, postalCode: "45132" },
    { lng: -83.497155, lat: 41.678167, postalCode: "43666" },
    { lng: -82.0779, lat: 39.506243, postalCode: "45732" },
    { lng: -84.46429, lat: 39.482307, postalCode: "45067" },
    { lng: -84.712099, lat: 39.325805, postalCode: "45063" },
    { lng: -81.43944, lat: 41.034156, postalCode: "44312" },
    { lng: -81.44512, lat: 39.430738, postalCode: "45787" },
    { lng: -81.49751, lat: 40.314481, postalCode: "43837" },
    { lng: -80.95058, lat: 41.031141, postalCode: "44401" },
    { lng: -83.46933, lat: 41.646398, postalCode: "43616" },
    { lng: -82.38848, lat: 41.24323, postalCode: "44889" },
    { lng: -81.368284, lat: 40.811809, postalCode: "44798" },
    { lng: -82.84501, lat: 40.270926, postalCode: "43074" },
    { lng: -83.06459, lat: 39.046079, postalCode: "45661" },
    { lng: -84.633484, lat: 40.428177, postalCode: "45826" },
    { lng: -83.650714, lat: 40.993263, postalCode: "45839" },
    { lng: -81.742811, lat: 40.738387, postalCode: "44636" },
    { lng: -82.42775, lat: 40.242377, postalCode: "43080" },
    { lng: -81.87856, lat: 40.542339, postalCode: "44654" },
    { lng: -80.5622, lat: 41.611348, postalCode: "44003" },
    { lng: -82.52163, lat: 38.734463, postalCode: "45688" },
    { lng: -83.43905, lat: 39.533002, postalCode: "43160" },
    { lng: -80.84356, lat: 41.237856, postalCode: "44485" },
    { lng: -83.39972, lat: 41.676897, postalCode: "43618" },
    { lng: -84.30866, lat: 41.529231, postalCode: "43502" },
    { lng: -83.38713, lat: 39.074266, postalCode: "45172" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44197" },
    { lng: -82.1665, lat: 39.513028, postalCode: "43144" },
    { lng: -81.59243, lat: 40.025859, postalCode: "43725" },
    { lng: -83.02076, lat: 40.097796, postalCode: "43085" },
    { lng: -80.88733, lat: 40.518045, postalCode: "43908" },
    { lng: -83.64338, lat: 40.892253, postalCode: "45814" },
    { lng: -83.813228, lat: 39.927059, postalCode: "45501" },
    { lng: -80.536, lat: 41.340211, postalCode: "44404" },
    { lng: -81.80629, lat: 39.990948, postalCode: "43767" },
    { lng: -82.24434, lat: 39.501342, postalCode: "43111" },
    { lng: -83.360645, lat: 40.306924, postalCode: "43041" },
    { lng: -81.255793, lat: 40.064971, postalCode: "43736" },
    { lng: -84.13927, lat: 39.121637, postalCode: "45160" },
    { lng: -81.18752, lat: 40.308212, postalCode: "44693" },
    { lng: -83.92144, lat: 39.682836, postalCode: "45385" },
    { lng: -81.53999, lat: 41.128705, postalCode: "44393" },
    { lng: -80.7264, lat: 41.333312, postalCode: "44410" },
    { lng: -81.18764, lat: 40.433246, postalCode: "44695" },
    { lng: -84.24201, lat: 39.664226, postalCode: "45449" },
    { lng: -84.74496, lat: 41.30569, postalCode: "43526" },
    { lng: -81.66109, lat: 39.526685, postalCode: "45786" },
    { lng: -81.8019, lat: 38.988699, postalCode: "45770" },
    { lng: -84.30075, lat: 41.054934, postalCode: "45837" },
    { lng: -84.268593, lat: 39.750471, postalCode: "45463" },
    { lng: -83.79841, lat: 39.32006, postalCode: "45146" },
    { lng: -81.93264, lat: 39.842048, postalCode: "43771" },
    { lng: -80.94455, lat: 39.768134, postalCode: "43914" },
    { lng: -83.64579, lat: 40.175413, postalCode: "43009" },
    { lng: -84.131541, lat: 41.103776, postalCode: "45864" },
    { lng: -81.41672, lat: 40.791108, postalCode: "44710" },
    { lng: -84.74442, lat: 40.050308, postalCode: "45352" },
    { lng: -84.48832, lat: 39.127527, postalCode: "45206" },
    { lng: -83.68731, lat: 39.326987, postalCode: "45159" },
    { lng: -82.53696, lat: 39.900063, postalCode: "43046" },
    { lng: -82.01209, lat: 40.485312, postalCode: "44637" },
    { lng: -83.620233, lat: 41.645699, postalCode: "43434" },
    { lng: -82.4692, lat: 41.323499, postalCode: "44814" },
    { lng: -81.44441, lat: 41.314655, postalCode: "44087" },
    { lng: -84.4433, lat: 40.128652, postalCode: "45308" },
    { lng: -83.78953, lat: 41.349205, postalCode: "43569" },
    { lng: -81.80457, lat: 40.739199, postalCode: "44606" },
    { lng: -81.07101, lat: 41.242864, postalCode: "44288" },
    { lng: -80.90826, lat: 40.086725, postalCode: "43950" },
    { lng: -84.77607, lat: 40.74011, postalCode: "45898" },
    { lng: -84.70471, lat: 39.971929, postalCode: "45346" },
    { lng: -80.79178, lat: 41.871212, postalCode: "44004" },
    { lng: -81.24357, lat: 39.76607, postalCode: "43754" },
    { lng: -83.54591, lat: 40.903089, postalCode: "45867" },
    { lng: -82.0137, lat: 41.039394, postalCode: "44254" },
    { lng: -83.67195, lat: 38.939364, postalCode: "45697" },
    { lng: -84.6448, lat: 40.352158, postalCode: "45310" },
    { lng: -83.751, lat: 40.254824, postalCode: "43357" },
    { lng: -81.672797, lat: 41.685744, postalCode: "44181" },
    { lng: -84.56225, lat: 41.65973, postalCode: "43554" },
    { lng: -82.010132, lat: 39.368235, postalCode: "45781" },
    { lng: -82.329486, lat: 40.44652, postalCode: "43628" },
    { lng: -82.80783, lat: 40.399648, postalCode: "43334" },
    { lng: -80.839519, lat: 40.360979, postalCode: "43966" },
    { lng: -81.44512, lat: 39.430738, postalCode: "45721" },
    { lng: -82.41937, lat: 39.703126, postalCode: "43107" },
    { lng: -81.91507, lat: 41.373949, postalCode: "44138" },
    { lng: -81.92477, lat: 41.230745, postalCode: "44280" },
    { lng: -81.90909, lat: 39.876997, postalCode: "43734" },
    { lng: -80.58346, lat: 41.239987, postalCode: "44403" },
    { lng: -84.02906, lat: 39.081436, postalCode: "45176" },
    { lng: -83.07498, lat: 38.833053, postalCode: "45652" },
    { lng: -83.45938, lat: 41.536132, postalCode: "43441" },
    { lng: -83.43943, lat: 41.686778, postalCode: "43654" },
    { lng: -81.470771, lat: 39.65828, postalCode: "45757" },
    { lng: -83.6505, lat: 39.507106, postalCode: "45169" },
    { lng: -81.537215, lat: 39.764709, postalCode: "43924" },
    { lng: -81.08057, lat: 41.295898, postalCode: "44231" },
    { lng: -83.31254, lat: 40.587559, postalCode: "43341" },
    { lng: -83.497155, lat: 41.678167, postalCode: "43661" },
    { lng: -81.55736, lat: 40.157301, postalCode: "43749" },
    { lng: -80.58076, lat: 41.52563, postalCode: "44093" },
    { lng: -83.70725, lat: 41.466185, postalCode: "43525" },
    { lng: -79.92642, lat: 40.196694, postalCode: "15063" },
    { lng: -74.84611, lat: 40.175504, postalCode: "19030" },
    { lng: -76.511942, lat: 40.238207, postalCode: "17015" },
    { lng: -75.70091, lat: 41.784395, postalCode: "18823" },
    { lng: -78.20652, lat: 40.924438, postalCode: "16840" },
    { lng: -79.66646, lat: 40.333339, postalCode: "15665" },
    { lng: -78.915567, lat: 40.267975, postalCode: "15959" },
    { lng: -77.19201, lat: 40.114682, postalCode: "17065" },
    { lng: -75.55484, lat: 41.000937, postalCode: "18210" },
    { lng: -77.42658, lat: 41.146432, postalCode: "17745" },
    { lng: -76.335631, lat: 40.065096, postalCode: "17604" },
    { lng: -79.24188, lat: 40.8739, postalCode: "16222" },
    { lng: -78.702224, lat: 40.484487, postalCode: "15922" },
    { lng: -78.659, lat: 41.902256, postalCode: "16725" },
    { lng: -76.03747, lat: 40.771111, postalCode: "17982" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18761" },
    { lng: -79.86744, lat: 40.518701, postalCode: "15238" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15262" },
    { lng: -77.42137, lat: 40.508563, postalCode: "17082" },
    { lng: -77.1099, lat: 40.003442, postalCode: "17372" },
    { lng: -75.30893, lat: 39.899812, postalCode: "19043" },
    { lng: -79.06772, lat: 40.112153, postalCode: "15520" },
    { lng: -79.41616, lat: 40.006624, postalCode: "15469" },
    { lng: -75.09995, lat: 41.010333, postalCode: "18356" },
    { lng: -80.15552, lat: 42.100307, postalCode: "16505" },
    { lng: -77.08212, lat: 40.233164, postalCode: "17072" },
    { lng: -80.03364, lat: 40.518368, postalCode: "15229" },
    { lng: -76.29564, lat: 40.418864, postalCode: "19544" },
    { lng: -79.718512, lat: 40.091655, postalCode: "15493" },
    { lng: -79.95183, lat: 39.968503, postalCode: "15433" },
    { lng: -79.67839, lat: 40.244753, postalCode: "15663" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19485" },
    { lng: -79.22093, lat: 39.935728, postalCode: "15557" },
    { lng: -75.16977, lat: 39.995412, postalCode: "19132" },
    { lng: -75.75964, lat: 39.997469, postalCode: "19372" },
    { lng: -76.71175, lat: 41.702935, postalCode: "16926" },
    { lng: -79.1497, lat: 40.558928, postalCode: "15754" },
    { lng: -75.83588, lat: 40.203142, postalCode: "19523" },
    { lng: -76.73877, lat: 41.208834, postalCode: "17756" },
    { lng: -79.37196, lat: 41.199396, postalCode: "16214" },
    { lng: -76.52757, lat: 40.002058, postalCode: "17368" },
    { lng: -75.074252, lat: 40.309942, postalCode: "18912" },
    { lng: -80.38408, lat: 40.341487, postalCode: "15004" },
    { lng: -76.9139, lat: 40.965348, postalCode: "17837" },
    { lng: -78.63027, lat: 40.188405, postalCode: "15521" },
    { lng: -80.23494, lat: 40.663816, postalCode: "15027" },
    { lng: -79.14264, lat: 40.529621, postalCode: "15748" },
    { lng: -80.05761, lat: 41.517339, postalCode: "16314" },
    { lng: -78.28886, lat: 40.960106, postalCode: "16876" },
    { lng: -79.93733, lat: 40.702289, postalCode: "16059" },
    { lng: -75.40251, lat: 39.916078, postalCode: "19063" },
    { lng: -80.27779, lat: 39.956204, postalCode: "15364" },
    { lng: -78.71987, lat: 41.254038, postalCode: "15823" },
    { lng: -76.27211, lat: 41.2044, postalCode: "18622" },
    { lng: -78.915147, lat: 40.325374, postalCode: "15907" },
    { lng: -76.106902, lat: 40.036659, postalCode: "17534" },
    { lng: -75.10439, lat: 40.060304, postalCode: "19012" },
    { lng: -75.06658, lat: 40.037711, postalCode: "19149" },
    { lng: -76.19352, lat: 40.614771, postalCode: "17972" },
    { lng: -79.8145, lat: 40.306819, postalCode: "15135" },
    { lng: -75.36926, lat: 40.645665, postalCode: "18017" },
    { lng: -77.723716, lat: 40.495979, postalCode: "17054" },
    { lng: -79.84791, lat: 40.341919, postalCode: "15132" },
    { lng: -79.75147, lat: 40.796809, postalCode: "16023" },
    { lng: -79.80192, lat: 40.297336, postalCode: "15028" },
    { lng: -77.57241, lat: 39.766281, postalCode: "17268" },
    { lng: -78.15342, lat: 40.080393, postalCode: "16691" },
    { lng: -79.14574, lat: 40.372103, postalCode: "15923" },
    { lng: -80.13033, lat: 40.821535, postalCode: "16037" },
    { lng: -75.73931, lat: 40.273179, postalCode: "19518" },
    { lng: -76.53935, lat: 41.239382, postalCode: "17774" },
    { lng: -75.3473, lat: 40.038477, postalCode: "19085" },
    { lng: -76.32496, lat: 39.902788, postalCode: "17565" },
    { lng: -79.97811, lat: 40.808934, postalCode: "16053" },
    { lng: -75.66158, lat: 40.80116, postalCode: "18030" },
    { lng: -74.92544, lat: 41.408589, postalCode: "18458" },
    { lng: -75.30509, lat: 40.077736, postalCode: "19428" },
    { lng: -80.15129, lat: 41.875896, postalCode: "16412" },
    { lng: -76.850061, lat: 40.292664, postalCode: "17106" },
    { lng: -75.12406, lat: 40.072293, postalCode: "19027" },
    { lng: -75.23884, lat: 41.231091, postalCode: "18357" },
    { lng: -75.43501, lat: 40.913651, postalCode: "18331" },
    { lng: -77.06305, lat: 39.874531, postalCode: "17350" },
    { lng: -77.02628, lat: 40.093968, postalCode: "17019" },
    { lng: -75.93577, lat: 40.348092, postalCode: "19601" },
    { lng: -79.19266, lat: 40.202095, postalCode: "15655" },
    { lng: -76.42806, lat: 40.040697, postalCode: "17554" },
    { lng: -79.69532, lat: 40.195407, postalCode: "15695" },
    { lng: -74.93912, lat: 40.246802, postalCode: "18940" },
    { lng: -80.13571, lat: 40.513778, postalCode: "15225" },
    { lng: -79.44164, lat: 41.316316, postalCode: "16334" },
    { lng: -79.15992, lat: 40.999588, postalCode: "15770" },
    { lng: -80.14448, lat: 41.992528, postalCode: "16426" },
    { lng: -75.9179, lat: 41.830406, postalCode: "18801" },
    { lng: -79.32665, lat: 40.087243, postalCode: "15646" },
    { lng: -77.0108, lat: 41.256736, postalCode: "17701" },
    { lng: -75.376985, lat: 40.34698, postalCode: "18971" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19092" },
    { lng: -75.57535, lat: 40.760403, postalCode: "18088" },
    { lng: -79.439801, lat: 40.360223, postalCode: "15619" },
    { lng: -78.9393, lat: 41.732284, postalCode: "16333" },
    { lng: -77.40369, lat: 40.365242, postalCode: "17047" },
    { lng: -78.94183, lat: 39.937504, postalCode: "15530" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15242" },
    { lng: -75.16183, lat: 40.466205, postalCode: "18942" },
    { lng: -79.61815, lat: 39.968368, postalCode: "15431" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19171" },
    { lng: -76.94251, lat: 40.28807, postalCode: "17025" },
    { lng: -76.687826, lat: 39.972985, postalCode: "17415" },
    { lng: -79.89611, lat: 40.075617, postalCode: "15466" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15123" },
    { lng: -78.5406, lat: 40.554187, postalCode: "16613" },
    { lng: -76.5206, lat: 39.971506, postalCode: "17317" },
    { lng: -77.9779, lat: 40.338058, postalCode: "17052" },
    { lng: -78.21678, lat: 40.893028, postalCode: "16866" },
    { lng: -80.308376, lat: 40.989662, postalCode: "16108" },
    { lng: -78.1668, lat: 40.954583, postalCode: "16860" },
    { lng: -79.89709, lat: 40.455168, postalCode: "15208" },
    { lng: -75.39613, lat: 41.579472, postalCode: "18472" },
    { lng: -80.21184, lat: 40.378997, postalCode: "15082" },
    { lng: -75.52526, lat: 39.928663, postalCode: "19319" },
    { lng: -76.00325, lat: 41.197908, postalCode: "18634" },
    { lng: -78.5454, lat: 41.883626, postalCode: "16744" },
    { lng: -80.19917, lat: 40.251054, postalCode: "15363" },
    { lng: -75.858731, lat: 40.248684, postalCode: "19524" },
    { lng: -79.8064, lat: 40.555627, postalCode: "15049" },
    { lng: -78.63685, lat: 40.003724, postalCode: "15550" },
    { lng: -79.34538, lat: 40.518067, postalCode: "15725" },
    { lng: -75.60728, lat: 40.624592, postalCode: "18069" },
    { lng: -76.16682, lat: 40.226093, postalCode: "17578" },
    { lng: -77.53768, lat: 41.74103, postalCode: "16921" },
    { lng: -79.39428, lat: 41.680467, postalCode: "16351" },
    { lng: -79.71589, lat: 39.916995, postalCode: "15472" },
    { lng: -75.10278, lat: 40.328645, postalCode: "19058" },
    { lng: -77.92889, lat: 39.793293, postalCode: "17236" },
    { lng: -76.52578, lat: 41.983801, postalCode: "18840" },
    { lng: -75.35586, lat: 40.211896, postalCode: "18979" },
    { lng: -79.40272, lat: 41.581586, postalCode: "16321" },
    { lng: -75.46199, lat: 41.653139, postalCode: "18421" },
    { lng: -79.98948, lat: 40.409219, postalCode: "15210" },
    { lng: -80.48584, lat: 39.792529, postalCode: "15310" },
    { lng: -76.681186, lat: 41.254974, postalCode: "17786" },
    { lng: -76.25348, lat: 39.811308, postalCode: "17518" },
    { lng: -75.34589, lat: 40.32635, postalCode: "18969" },
    { lng: -75.14707, lat: 40.08786, postalCode: "19095" },
    { lng: -75.80215, lat: 39.962256, postalCode: "19358" },
    { lng: -75.48431, lat: 40.375182, postalCode: "18076" },
    { lng: -74.93734, lat: 40.10386, postalCode: "19020" },
    { lng: -75.1839, lat: 40.011562, postalCode: "19129" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19399" },
    { lng: -78.5578, lat: 40.792727, postalCode: "16656" },
    { lng: -77.78689, lat: 40.78267, postalCode: "16827" },
    { lng: -79.73879, lat: 40.358962, postalCode: "15615" },
    { lng: -78.5877, lat: 40.121392, postalCode: "15554" },
    { lng: -76.43928, lat: 40.769941, postalCode: "17840" },
    { lng: -78.94295, lat: 40.47535, postalCode: "15961" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17608" },
    { lng: -75.01149, lat: 41.497511, postalCode: "18435" },
    { lng: -78.43301, lat: 40.34068, postalCode: "16637" },
    { lng: -79.730975, lat: 41.407066, postalCode: "16302" },
    { lng: -77.77637, lat: 40.004885, postalCode: "17294" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19452" },
    { lng: -76.893751, lat: 40.294396, postalCode: "17121" },
    { lng: -78.72133, lat: 40.600375, postalCode: "15722" },
    { lng: -78.19085, lat: 41.004198, postalCode: "16858" },
    { lng: -77.60029, lat: 41.059172, postalCode: "16826" },
    { lng: -76.07455, lat: 41.175582, postalCode: "18617" },
    { lng: -75.75965, lat: 41.082188, postalCode: "18661" },
    { lng: -75.66924, lat: 40.824403, postalCode: "18244" },
    { lng: -75.88569, lat: 41.242081, postalCode: "18703" },
    { lng: -79.74234, lat: 40.608403, postalCode: "15014" },
    { lng: -79.58296, lat: 40.7532, postalCode: "16212" },
    { lng: -79.129445, lat: 40.640031, postalCode: "15758" },
    { lng: -79.31915, lat: 40.779271, postalCode: "16249" },
    { lng: -78.30853, lat: 40.943755, postalCode: "16878" },
    { lng: -80.05506, lat: 41.047157, postalCode: "16057" },
    { lng: -78.5839, lat: 40.128089, postalCode: "15539" },
    { lng: -77.03435, lat: 41.43664, postalCode: "17771" },
    { lng: -79.23317, lat: 40.416334, postalCode: "15779" },
    { lng: -80.23208, lat: 40.682566, postalCode: "15042" },
    { lng: -78.39121, lat: 40.50217, postalCode: "16602" },
    { lng: -77.640461, lat: 40.105495, postalCode: "17232" },
    { lng: -79.54788, lat: 41.700333, postalCode: "16420" },
    { lng: -80.3098, lat: 41.979, postalCode: "16417" },
    { lng: -79.49612, lat: 40.351619, postalCode: "15635" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18762" },
    { lng: -77.91555, lat: 40.896662, postalCode: "16844" },
    { lng: -75.83474, lat: 40.625739, postalCode: "19529" },
    { lng: -76.67506, lat: 41.052616, postalCode: "17884" },
    { lng: -75.61821, lat: 41.427199, postalCode: "18512" },
    { lng: -79.34078, lat: 41.391383, postalCode: "16361" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19407" },
    { lng: -78.83719, lat: 41.012739, postalCode: "15863" },
    { lng: -76.17619, lat: 40.174994, postalCode: "17522" },
    { lng: -78.80734, lat: 41.628726, postalCode: "16735" },
    { lng: -78.526274, lat: 40.521778, postalCode: "16629" },
    { lng: -78.70932, lat: 40.670162, postalCode: "16646" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19178" },
    { lng: -75.30274, lat: 40.835128, postalCode: "18091" },
    { lng: -80.40715, lat: 40.114204, postalCode: "15323" },
    { lng: -76.62615, lat: 40.839093, postalCode: "17860" },
    { lng: -75.335384, lat: 41.422497, postalCode: "18427" },
    { lng: -79.856751, lat: 39.9783, postalCode: "15471" },
    { lng: -78.34748, lat: 40.282346, postalCode: "16631" },
    { lng: -79.04608, lat: 40.460191, postalCode: "15920" },
    { lng: -78.98991, lat: 40.827315, postalCode: "15771" },
    { lng: -77.670626, lat: 40.112652, postalCode: "17251" },
    { lng: -78.53541, lat: 41.976763, postalCode: "16727" },
    { lng: -75.84811, lat: 40.857175, postalCode: "18240" },
    { lng: -79.96653, lat: 39.94821, postalCode: "15325" },
    { lng: -79.97929, lat: 40.342869, postalCode: "15236" },
    { lng: -75.88285, lat: 39.964795, postalCode: "19367" },
    { lng: -79.71321, lat: 40.261878, postalCode: "15637" },
    { lng: -80.27332, lat: 39.880073, postalCode: "15359" },
    { lng: -79.31703, lat: 41.860809, postalCode: "16371" },
    { lng: -78.35854, lat: 41.328011, postalCode: "15821" },
    { lng: -78.96306, lat: 41.00232, postalCode: "15711" },
    { lng: -75.57241, lat: 41.944334, postalCode: "18847" },
    { lng: -79.9325, lat: 40.454018, postalCode: "15232" },
    { lng: -76.00719, lat: 40.486726, postalCode: "19516" },
    { lng: -75.152176, lat: 40.339307, postalCode: "18903" },
    { lng: -77.46457, lat: 40.53526, postalCode: "17058" },
    { lng: -76.56268, lat: 39.92741, postalCode: "17366" },
    { lng: -78.32445, lat: 41.876559, postalCode: "16750" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15279" },
    { lng: -77.77254, lat: 40.458618, postalCode: "17051" },
    { lng: -75.39631, lat: 41.118226, postalCode: "18346" },
    { lng: -79.6478, lat: 41.321298, postalCode: "16319" },
    { lng: -77.345514, lat: 40.478716, postalCode: "17056" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18916" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19455" },
    { lng: -77.31213, lat: 40.851747, postalCode: "17885" },
    { lng: -79.99484, lat: 39.988369, postalCode: "15348" },
    { lng: -79.71324, lat: 40.325902, postalCode: "15642" },
    { lng: -75.26722, lat: 40.681531, postalCode: "18045" },
    { lng: -77.68562, lat: 41.678935, postalCode: "16922" },
    { lng: -76.93084, lat: 40.236139, postalCode: "17011" },
    { lng: -79.69296, lat: 39.876251, postalCode: "15445" },
    { lng: -79.012306, lat: 40.020764, postalCode: "15549" },
    { lng: -75.91411, lat: 39.872123, postalCode: "19330" },
    { lng: -75.963055, lat: 40.388442, postalCode: "19603" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19397" },
    { lng: -80.07316, lat: 40.08708, postalCode: "15360" },
    { lng: -79.87436, lat: 41.199578, postalCode: "16372" },
    { lng: -79.29689, lat: 40.570149, postalCode: "15756" },
    { lng: -79.76338, lat: 40.062849, postalCode: "15482" },
    { lng: -77.027926, lat: 40.07501, postalCode: "17323" },
    { lng: -80.50229, lat: 40.101254, postalCode: "15376" },
    { lng: -79.843, lat: 41.87487, postalCode: "16438" },
    { lng: -79.97336, lat: 40.061044, postalCode: "15427" },
    { lng: -80.06008, lat: 40.455118, postalCode: "15204" },
    { lng: -80.2073, lat: 41.443244, postalCode: "16151" },
    { lng: -79.45737, lat: 40.243195, postalCode: "15676" },
    { lng: -79.67632, lat: 40.332063, postalCode: "15692" },
    { lng: -75.42174, lat: 40.044858, postalCode: "19333" },
    { lng: -78.20534, lat: 40.335188, postalCode: "16638" },
    { lng: -76.24373, lat: 40.456862, postalCode: "19550" },
    { lng: -80.22181, lat: 40.593917, postalCode: "15003" },
    { lng: -75.348026, lat: 40.986265, postalCode: "18352" },
    { lng: -79.65217, lat: 39.932084, postalCode: "15416" },
    { lng: -80.0704, lat: 40.502918, postalCode: "15202" },
    { lng: -75.18585, lat: 41.414421, postalCode: "18464" },
    { lng: -76.85922, lat: 40.258972, postalCode: "17104" },
    { lng: -75.64508, lat: 41.40918, postalCode: "18510" },
    { lng: -76.8113, lat: 40.602475, postalCode: "17005" },
    { lng: -75.45705, lat: 41.010299, postalCode: "18160" },
    { lng: -80.37424, lat: 41.403462, postalCode: "16125" },
    { lng: -75.38378, lat: 40.146069, postalCode: "19403" },
    { lng: -76.19149, lat: 40.055467, postalCode: "17505" },
    { lng: -78.64104, lat: 40.408989, postalCode: "15925" },
    { lng: -78.10643, lat: 41.131415, postalCode: "16845" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15091" },
    { lng: -75.87434, lat: 40.574033, postalCode: "19534" },
    { lng: -75.26477, lat: 40.754064, postalCode: "18083" },
    { lng: -75.25805, lat: 40.014541, postalCode: "19072" },
    { lng: -75.274708, lat: 41.615989, postalCode: "18401" },
    { lng: -76.76764, lat: 39.962821, postalCode: "17404" },
    { lng: -77.08184, lat: 40.978059, postalCode: "17880" },
    { lng: -78.44419, lat: 41.801302, postalCode: "16749" },
    { lng: -77.2496, lat: 39.982466, postalCode: "17306" },
    { lng: -79.453751, lat: 41.202325, postalCode: "16220" },
    { lng: -79.83613, lat: 39.737104, postalCode: "15451" },
    { lng: -75.40103, lat: 40.508855, postalCode: "18036" },
    { lng: -79.53488, lat: 40.76172, postalCode: "16228" },
    { lng: -78.245581, lat: 39.88745, postalCode: "17269" },
    { lng: -76.09565, lat: 40.99118, postalCode: "18249" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19366" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19370" },
    { lng: -75.024055, lat: 41.340936, postalCode: "18373" },
    { lng: -75.27816, lat: 40.157526, postalCode: "19422" },
    { lng: -78.938653, lat: 40.101106, postalCode: "15548" },
    { lng: -75.97458, lat: 39.781938, postalCode: "19363" },
    { lng: -79.808899, lat: 41.04719, postalCode: "16048" },
    { lng: -77.05294, lat: 40.928666, postalCode: "17844" },
    { lng: -79.27869, lat: 41.361473, postalCode: "16233" },
    { lng: -79.86681, lat: 39.987842, postalCode: "15413" },
    { lng: -76.39923, lat: 41.11533, postalCode: "17859" },
    { lng: -76.64405, lat: 39.8989, postalCode: "17313" },
    { lng: -75.28002, lat: 40.042444, postalCode: "19035" },
    { lng: -75.435539, lat: 40.290357, postalCode: "18958" },
    { lng: -78.23675, lat: 39.798516, postalCode: "17267" },
    { lng: -76.00488, lat: 40.900995, postalCode: "18231" },
    { lng: -77.54813, lat: 40.735551, postalCode: "17063" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18016" },
    { lng: -79.3194, lat: 40.356055, postalCode: "15671" },
    { lng: -75.680797, lat: 40.084337, postalCode: "19925" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19409" },
    { lng: -78.25309, lat: 41.517738, postalCode: "15834" },
    { lng: -80.34064, lat: 42.019394, postalCode: "16423" },
    { lng: -76.08882, lat: 41.257193, postalCode: "18621" },
    { lng: -80.44277, lat: 41.981289, postalCode: "16411" },
    { lng: -80.2261, lat: 40.558477, postalCode: "15046" },
    { lng: -78.44099, lat: 40.770522, postalCode: "16616" },
    { lng: -79.52342, lat: 40.780619, postalCode: "16236" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19484" },
    { lng: -79.7311, lat: 39.89822, postalCode: "15401" },
    { lng: -77.87089, lat: 41.93668, postalCode: "16923" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19371" },
    { lng: -77.7993, lat: 39.851731, postalCode: "17270" },
    { lng: -80.0222, lat: 41.452879, postalCode: "16311" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19161" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15270" },
    { lng: -77.79943, lat: 40.110585, postalCode: "17271" },
    { lng: -77.55239, lat: 39.840491, postalCode: "17237" },
    { lng: -75.47123, lat: 40.657089, postalCode: "18032" },
    { lng: -79.653388, lat: 40.322965, postalCode: "15643" },
    { lng: -79.13944, lat: 41.496544, postalCode: "16239" },
    { lng: -80.19444, lat: 40.821528, postalCode: "16123" },
    { lng: -76.51986, lat: 40.592705, postalCode: "17957" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19177" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18105" },
    { lng: -75.740942, lat: 40.569818, postalCode: "19538" },
    { lng: -75.00155, lat: 40.064257, postalCode: "19114" },
    { lng: -76.3155, lat: 40.072246, postalCode: "17601" },
    { lng: -79.74467, lat: 40.386968, postalCode: "15085" },
    { lng: -78.76347, lat: 41.791063, postalCode: "16751" },
    { lng: -77.5774, lat: 40.595359, postalCode: "17044" },
    { lng: -75.25783, lat: 40.272177, postalCode: "18915" },
    { lng: -75.024055, lat: 41.340936, postalCode: "18371" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16538" },
    { lng: -77.56552, lat: 40.637663, postalCode: "17009" },
    { lng: -75.274708, lat: 41.615989, postalCode: "18454" },
    { lng: -80.23, lat: 41.097672, postalCode: "16156" },
    { lng: -79.94612, lat: 40.463463, postalCode: "15224" },
    { lng: -77.83649, lat: 40.39213, postalCode: "17075" },
    { lng: -75.7846, lat: 41.708207, postalCode: "18824" },
    { lng: -76.97727, lat: 39.795301, postalCode: "17331" },
    { lng: -76.871209, lat: 40.249845, postalCode: "17122" },
    { lng: -75.58537, lat: 40.820558, postalCode: "18071" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15095" },
    { lng: -79.29719, lat: 41.007679, postalCode: "16245" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18910" },
    { lng: -79.86448, lat: 40.079169, postalCode: "15477" },
    { lng: -79.46886, lat: 40.362739, postalCode: "15624" },
    { lng: -79.258458, lat: 40.046023, postalCode: "15502" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15277" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16541" },
    { lng: -76.81871, lat: 41.072139, postalCode: "17749" },
    { lng: -76.24143, lat: 40.602141, postalCode: "17933" },
    { lng: -77.14153, lat: 41.664117, postalCode: "16911" },
    { lng: -75.48357, lat: 40.042252, postalCode: "19301" },
    { lng: -75.87254, lat: 41.236331, postalCode: "18702" },
    { lng: -76.8727, lat: 40.48358, postalCode: "17032" },
    { lng: -76.91245, lat: 41.047295, postalCode: "17856" },
    { lng: -75.53909, lat: 41.057574, postalCode: "18610" },
    { lng: -79.40846, lat: 40.253086, postalCode: "15693" },
    { lng: -76.41582, lat: 40.091196, postalCode: "17538" },
    { lng: -80.11534, lat: 40.351802, postalCode: "15017" },
    { lng: -80.49404, lat: 41.211606, postalCode: "16121" },
    { lng: -78.50472, lat: 41.293237, postalCode: "15827" },
    { lng: -77.36332, lat: 41.125166, postalCode: "17748" },
    { lng: -76.229623, lat: 40.722587, postalCode: "17966" },
    { lng: -77.47605, lat: 40.891594, postalCode: "16854" },
    { lng: -75.21334, lat: 40.107743, postalCode: "19031" },
    { lng: -79.52492, lat: 40.150143, postalCode: "15666" },
    { lng: -80.308376, lat: 40.989662, postalCode: "16107" },
    { lng: -79.9157, lat: 40.497701, postalCode: "15215" },
    { lng: -76.03193, lat: 41.350053, postalCode: "18654" },
    { lng: -77.57259, lat: 40.147267, postalCode: "17240" },
    { lng: -80.18827, lat: 40.042851, postalCode: "15311" },
    { lng: -76.876437, lat: 40.297031, postalCode: "17107" },
    { lng: -75.57372, lat: 40.339207, postalCode: "19472" },
    { lng: -75.1112, lat: 40.889911, postalCode: "18343" },
    { lng: -79.57266, lat: 40.409992, postalCode: "15626" },
    { lng: -75.37525, lat: 40.092941, postalCode: "19406" },
    { lng: -78.8636, lat: 40.816706, postalCode: "15742" },
    { lng: -77.52006, lat: 39.897287, postalCode: "17222" },
    { lng: -76.667267, lat: 40.87897, postalCode: "17877" },
    { lng: -78.61379, lat: 41.734498, postalCode: "16740" },
    { lng: -79.439801, lat: 40.360223, postalCode: "15606" },
    { lng: -78.78201, lat: 40.358541, postalCode: "15956" },
    { lng: -75.23221, lat: 39.898985, postalCode: "19153" },
    { lng: -75.74602, lat: 40.094621, postalCode: "19343" },
    { lng: -79.264322, lat: 41.811682, postalCode: "16366" },
    { lng: -75.88959, lat: 41.244781, postalCode: "18766" },
    { lng: -76.42501, lat: 40.358015, postalCode: "17046" },
    { lng: -75.14589, lat: 39.951062, postalCode: "19106" },
    { lng: -78.77164, lat: 40.298625, postalCode: "15952" },
    { lng: -78.52097, lat: 40.753504, postalCode: "16627" },
    { lng: -75.23453, lat: 39.922612, postalCode: "19142" },
    { lng: -77.99529, lat: 39.942589, postalCode: "17233" },
    { lng: -77.74923, lat: 39.792122, postalCode: "17225" },
    { lng: -79.94934, lat: 41.61792, postalCode: "16327" },
    { lng: -75.99195, lat: 40.008814, postalCode: "17527" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15263" },
    { lng: -79.99019, lat: 40.015501, postalCode: "15368" },
    { lng: -75.73144, lat: 40.317896, postalCode: "19519" },
    { lng: -76.6434, lat: 40.402578, postalCode: "17028" },
    { lng: -79.65217, lat: 39.932084, postalCode: "15465" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19441" },
    { lng: -75.85774, lat: 41.449135, postalCode: "18615" },
    { lng: -80.01831, lat: 40.369619, postalCode: "15234" },
    { lng: -78.04939, lat: 41.370716, postalCode: "15861" },
    { lng: -77.02267, lat: 41.222128, postalCode: "17702" },
    { lng: -79.651602, lat: 40.755638, postalCode: "16299" },
    { lng: -75.71227, lat: 39.858137, postalCode: "19348" },
    { lng: -75.167622, lat: 39.951112, postalCode: "19192" },
    { lng: -76.02438, lat: 40.479447, postalCode: "19541" },
    { lng: -75.984901, lat: 40.407173, postalCode: "19548" },
    { lng: -77.292578, lat: 40.406766, postalCode: "17071" },
    { lng: -76.05513, lat: 40.759814, postalCode: "17952" },
    { lng: -80.36453, lat: 40.93156, postalCode: "16160" },
    { lng: -79.38845, lat: 40.299646, postalCode: "15650" },
    { lng: -78.85799, lat: 40.632966, postalCode: "15710" },
    { lng: -75.29371, lat: 39.902045, postalCode: "19036" },
    { lng: -76.924996, lat: 40.21604, postalCode: "17001" },
    { lng: -75.27865, lat: 41.154329, postalCode: "18326" },
    { lng: -78.17987, lat: 41.379949, postalCode: "15832" },
    { lng: -77.00629, lat: 40.694161, postalCode: "17853" },
    { lng: -79.96986, lat: 41.87504, postalCode: "16427" },
    { lng: -75.768437, lat: 40.260992, postalCode: "19542" },
    { lng: -75.89791, lat: 41.015634, postalCode: "18224" },
    { lng: -74.95917, lat: 41.241889, postalCode: "18328" },
    { lng: -79.58414, lat: 40.024138, postalCode: "15425" },
    { lng: -75.559975, lat: 40.079629, postalCode: "19432" },
    { lng: -79.922, lat: 39.869675, postalCode: "15476" },
    { lng: -75.48659, lat: 40.388263, postalCode: "18073" },
    { lng: -80.50182, lat: 40.582316, postalCode: "15043" },
    { lng: -78.92978, lat: 40.23741, postalCode: "15928" },
    { lng: -75.17222, lat: 39.968262, postalCode: "19130" },
    { lng: -80.12246, lat: 40.304019, postalCode: "15055" },
    { lng: -75.637626, lat: 41.401881, postalCode: "18522" },
    { lng: -75.65864, lat: 40.744055, postalCode: "18079" },
    { lng: -75.19044, lat: 39.895677, postalCode: "19112" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18991" },
    { lng: -77.31285, lat: 41.86292, postalCode: "16935" },
    { lng: -76.56417, lat: 40.784453, postalCode: "17872" },
    { lng: -78.22785, lat: 40.221352, postalCode: "16678" },
    { lng: -80.27353, lat: 40.030707, postalCode: "15329" },
    { lng: -76.94339, lat: 40.761179, postalCode: "17827" },
    { lng: -75.984901, lat: 40.407173, postalCode: "19517" },
    { lng: -75.60137, lat: 41.058352, postalCode: "18624" },
    { lng: -75.25102, lat: 41.60032, postalCode: "18431" },
    { lng: -79.30426, lat: 40.038201, postalCode: "15622" },
    { lng: -78.375, lat: 40.789079, postalCode: "16663" },
    { lng: -79.95304, lat: 40.207526, postalCode: "15067" },
    { lng: -76.16898, lat: 40.939035, postalCode: "18241" },
    { lng: -76.73831, lat: 41.104021, postalCode: "17772" },
    { lng: -75.602401, lat: 39.94545, postalCode: "19383" },
    { lng: -80.29472, lat: 40.73998, postalCode: "15066" },
    { lng: -79.4463, lat: 40.500852, postalCode: "15681" },
    { lng: -75.405987, lat: 39.934047, postalCode: "19016" },
    { lng: -75.38761, lat: 39.864929, postalCode: "19015" },
    { lng: -75.66603, lat: 41.39208, postalCode: "18505" },
    { lng: -76.32397, lat: 40.670753, postalCode: "17923" },
    { lng: -79.35113, lat: 39.751296, postalCode: "15411" },
    { lng: -76.7048, lat: 40.270367, postalCode: "17036" },
    { lng: -79.59033, lat: 40.136883, postalCode: "15612" },
    { lng: -77.06939, lat: 41.811139, postalCode: "16933" },
    { lng: -75.15803, lat: 39.919812, postalCode: "19148" },
    { lng: -77.84147, lat: 40.130231, postalCode: "17239" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17605" },
    { lng: -75.6982, lat: 40.913283, postalCode: "18229" },
    { lng: -76.72921, lat: 40.017597, postalCode: "17318" },
    { lng: -79.27416, lat: 41.020621, postalCode: "16230" },
    { lng: -79.69044, lat: 41.439658, postalCode: "16301" },
    { lng: -75.26271, lat: 40.183763, postalCode: "19437" },
    { lng: -80.08369, lat: 40.635147, postalCode: "15015" },
    { lng: -78.97017, lat: 40.954059, postalCode: "15767" },
    { lng: -76.20054, lat: 40.373285, postalCode: "19567" },
    { lng: -80.26324, lat: 40.454423, postalCode: "15126" },
    { lng: -80.26042, lat: 41.329301, postalCode: "16124" },
    { lng: -77.023601, lat: 41.33275, postalCode: "17703" },
    { lng: -76.92332, lat: 40.385623, postalCode: "17018" },
    { lng: -76.12655, lat: 40.234392, postalCode: "17517" },
    { lng: -75.3191, lat: 41.095437, postalCode: "18370" },
    { lng: -77.023601, lat: 41.33275, postalCode: "17769" },
    { lng: -75.98937, lat: 40.435509, postalCode: "19533" },
    { lng: -76.38074, lat: 41.476652, postalCode: "18632" },
    { lng: -75.294125, lat: 41.145778, postalCode: "18342" },
    { lng: -80.4957, lat: 41.200389, postalCode: "16161" },
    { lng: -76.94413, lat: 41.951518, postalCode: "16936" },
    { lng: -75.5044, lat: 40.671538, postalCode: "18037" },
    { lng: -79.2727, lat: 40.796406, postalCode: "16244" },
    { lng: -75.31101, lat: 40.361206, postalCode: "18960" },
    { lng: -79.11801, lat: 41.773848, postalCode: "16313" },
    { lng: -76.96991, lat: 40.454175, postalCode: "17069" },
    { lng: -75.63481, lat: 40.253496, postalCode: "19464" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15047" },
    { lng: -75.97596, lat: 41.345579, postalCode: "18612" },
    { lng: -79.13245, lat: 39.746182, postalCode: "15562" },
    { lng: -76.43982, lat: 41.013364, postalCode: "17815" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15261" },
    { lng: -79.57544, lat: 40.560849, postalCode: "15613" },
    { lng: -78.86765, lat: 40.804018, postalCode: "15741" },
    { lng: -75.596231, lat: 41.46488, postalCode: "18448" },
    { lng: -80.009135, lat: 40.055216, postalCode: "15355" },
    { lng: -77.87139, lat: 40.242121, postalCode: "17243" },
    { lng: -79.89008, lat: 40.350519, postalCode: "15034" },
    { lng: -75.990392, lat: 40.969691, postalCode: "18202" },
    { lng: -76.13916, lat: 40.941585, postalCode: "18256" },
    { lng: -77.37768, lat: 39.759356, postalCode: "17320" },
    { lng: -79.78956, lat: 39.89615, postalCode: "15484" },
    { lng: -79.08397, lat: 40.916407, postalCode: "15744" },
    { lng: -76.6434, lat: 40.277923, postalCode: "17033" },
    { lng: -79.89418, lat: 40.366177, postalCode: "15122" },
    { lng: -78.4423, lat: 39.825578, postalCode: "15535" },
    { lng: -79.92758, lat: 40.921145, postalCode: "16017" },
    { lng: -76.12911, lat: 40.453886, postalCode: "19506" },
    { lng: -78.3527, lat: 40.8376, postalCode: "16620" },
    { lng: -76.021037, lat: 41.316584, postalCode: "18627" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18769" },
    { lng: -76.40849, lat: 39.80954, postalCode: "17302" },
    { lng: -80.23526, lat: 42.035523, postalCode: "16415" },
    { lng: -78.42919, lat: 41.283012, postalCode: "15868" },
    { lng: -75.82447, lat: 41.393881, postalCode: "18653" },
    { lng: -75.08018, lat: 40.057661, postalCode: "19111" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18763" },
    { lng: -79.850366, lat: 39.980787, postalCode: "15415" },
    { lng: -75.236436, lat: 41.071213, postalCode: "18320" },
    { lng: -80.05491, lat: 39.92757, postalCode: "15344" },
    { lng: -75.94878, lat: 41.244281, postalCode: "18651" },
    { lng: -77.41026, lat: 40.086522, postalCode: "17266" },
    { lng: -75.74391, lat: 41.977241, postalCode: "18821" },
    { lng: -79.90635, lat: 40.395336, postalCode: "15120" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19193" },
    { lng: -75.20374, lat: 40.13477, postalCode: "19034" },
    { lng: -75.357555, lat: 40.192881, postalCode: "19490" },
    { lng: -78.37938, lat: 41.747898, postalCode: "16724" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19160" },
    { lng: -76.08031, lat: 40.102296, postalCode: "17557" },
    { lng: -75.91011, lat: 40.822234, postalCode: "18218" },
    { lng: -79.32695, lat: 41.127485, postalCode: "16234" },
    { lng: -78.07012, lat: 40.662087, postalCode: "16683" },
    { lng: -80.07695, lat: 40.471618, postalCode: "15136" },
    { lng: -77.75115, lat: 41.869812, postalCode: "16948" },
    { lng: -79.01806, lat: 40.414654, postalCode: "15954" },
    { lng: -79.54534, lat: 41.94208, postalCode: "16405" },
    { lng: -77.32269, lat: 40.918517, postalCode: "16882" },
    { lng: -79.785349, lat: 39.963622, postalCode: "15449" },
    { lng: -77.01905, lat: 39.805693, postalCode: "17344" },
    { lng: -79.76526, lat: 40.431034, postalCode: "15146" },
    { lng: -78.30514, lat: 40.98169, postalCode: "16825" },
    { lng: -77.31123, lat: 41.989863, postalCode: "16920" },
    { lng: -77.53613, lat: 41.010409, postalCode: "16848" },
    { lng: -76.19887, lat: 40.560225, postalCode: "17979" },
    { lng: -74.95183, lat: 41.31514, postalCode: "18377" },
    { lng: -75.8265, lat: 40.945334, postalCode: "18255" },
    { lng: -77.023601, lat: 41.33275, postalCode: "17705" },
    { lng: -79.33317, lat: 40.801244, postalCode: "16263" },
    { lng: -80.27408, lat: 40.27758, postalCode: "15378" },
    { lng: -76.19007, lat: 40.096346, postalCode: "17540" },
    { lng: -77.35299, lat: 40.966199, postalCode: "16872" },
    { lng: -75.8674, lat: 40.826908, postalCode: "18250" },
    { lng: -77.8617, lat: 40.152486, postalCode: "17255" },
    { lng: -75.15654, lat: 40.055861, postalCode: "19138" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18044" },
    { lng: -79.92585, lat: 39.877676, postalCode: "15351" },
    { lng: -76.521266, lat: 41.77197, postalCode: "17735" },
    { lng: -76.27989, lat: 40.672834, postalCode: "17944" },
    { lng: -80.257855, lat: 42.030201, postalCode: "16512" },
    { lng: -75.30861, lat: 39.978728, postalCode: "19083" },
    { lng: -80.04218, lat: 39.976531, postalCode: "15322" },
    { lng: -76.34824, lat: 40.763568, postalCode: "17921" },
    { lng: -79.19534, lat: 40.472335, postalCode: "15716" },
    { lng: -78.06337, lat: 39.81231, postalCode: "17212" },
    { lng: -75.693335, lat: 41.486527, postalCode: "18410" },
    { lng: -79.67427, lat: 40.267005, postalCode: "15625" },
    { lng: -75.18858, lat: 40.053511, postalCode: "19119" },
    { lng: -75.637626, lat: 41.401881, postalCode: "18540" },
    { lng: -77.345514, lat: 40.478716, postalCode: "17014" },
    { lng: -79.93647, lat: 41.335938, postalCode: "16342" },
    { lng: -75.814984, lat: 40.613868, postalCode: "19557" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19101" },
    { lng: -75.35881, lat: 40.906654, postalCode: "18353" },
    { lng: -75.58669, lat: 41.48188, postalCode: "18452" },
    { lng: -79.50254, lat: 41.039668, postalCode: "16248" },
    { lng: -79.60693, lat: 40.324588, postalCode: "15634" },
    { lng: -76.49366, lat: 40.769908, postalCode: "17825" },
    { lng: -77.03405, lat: 40.401114, postalCode: "17020" },
    { lng: -79.264322, lat: 41.811682, postalCode: "16367" },
    { lng: -76.25805, lat: 40.809063, postalCode: "17946" },
    { lng: -80.14529, lat: 40.352897, postalCode: "15064" },
    { lng: -75.78759, lat: 39.768218, postalCode: "19350" },
    { lng: -75.16497, lat: 40.346926, postalCode: "18923" },
    { lng: -76.96659, lat: 40.333185, postalCode: "17053" },
    { lng: -76.19361, lat: 41.184223, postalCode: "18655" },
    { lng: -79.51724, lat: 41.369406, postalCode: "16364" },
    { lng: -79.65217, lat: 39.932084, postalCode: "15467" },
    { lng: -76.67105, lat: 40.780982, postalCode: "17881" },
    { lng: -75.75698, lat: 40.480194, postalCode: "19536" },
    { lng: -80.34189, lat: 40.889241, postalCode: "16157" },
    { lng: -79.16106, lat: 40.70673, postalCode: "15723" },
    { lng: -78.80577, lat: 41.241861, postalCode: "15824" },
    { lng: -75.23003, lat: 39.961812, postalCode: "19139" },
    { lng: -79.478, lat: 41.972884, postalCode: "16402" },
    { lng: -78.95052, lat: 40.212192, postalCode: "15935" },
    { lng: -78.53545, lat: 40.235, postalCode: "16655" },
    { lng: -79.83113, lat: 40.013397, postalCode: "15442" },
    { lng: -78.83252, lat: 40.596681, postalCode: "15762" },
    { lng: -76.23554, lat: 40.696252, postalCode: "17974" },
    { lng: -76.09224, lat: 39.981916, postalCode: "17562" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17573" },
    { lng: -75.73829, lat: 41.369679, postalCode: "18518" },
    { lng: -79.89261, lat: 40.063977, postalCode: "15419" },
    { lng: -78.36046, lat: 40.827635, postalCode: "16651" },
    { lng: -76.487732, lat: 40.041911, postalCode: "17511" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19185" },
    { lng: -79.264322, lat: 41.811682, postalCode: "16369" },
    { lng: -76.73557, lat: 40.201341, postalCode: "17057" },
    { lng: -76.521266, lat: 41.77197, postalCode: "16910" },
    { lng: -78.89325, lat: 40.625962, postalCode: "15761" },
    { lng: -76.55565, lat: 41.702433, postalCode: "18832" },
    { lng: -80.02313, lat: 40.065384, postalCode: "15313" },
    { lng: -78.024, lat: 41.0448, postalCode: "16859" },
    { lng: -76.62209, lat: 40.581422, postalCode: "17098" },
    { lng: -75.94148, lat: 41.305822, postalCode: "18708" },
    { lng: -80.10054, lat: 40.726206, postalCode: "16006" },
    { lng: -78.11298, lat: 41.004152, postalCode: "16839" },
    { lng: -76.86217, lat: 40.274389, postalCode: "17103" },
    { lng: -75.48697, lat: 40.693818, postalCode: "18067" },
    { lng: -75.801009, lat: 40.335593, postalCode: "19535" },
    { lng: -77.10806, lat: 39.748187, postalCode: "17340" },
    { lng: -80.333192, lat: 41.119992, postalCode: "16172" },
    { lng: -78.54272, lat: 41.4376, postalCode: "15857" },
    { lng: -75.33958, lat: 39.862712, postalCode: "19022" },
    { lng: -76.51296, lat: 40.434998, postalCode: "17038" },
    { lng: -75.37444, lat: 39.84697, postalCode: "19013" },
    { lng: -80.37687, lat: 41.883648, postalCode: "16401" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19451" },
    { lng: -75.38569, lat: 40.270424, postalCode: "19438" },
    { lng: -76.95432, lat: 41.505366, postalCode: "17763" },
    { lng: -79.92758, lat: 40.921145, postalCode: "16003" },
    { lng: -80.14799, lat: 40.953141, postalCode: "16051" },
    { lng: -75.75144, lat: 41.184986, postalCode: "18602" },
    { lng: -75.448579, lat: 40.302945, postalCode: "18957" },
    { lng: -78.45919, lat: 40.271645, postalCode: "16682" },
    { lng: -80.46845, lat: 39.874975, postalCode: "15380" },
    { lng: -80.06125, lat: 40.898789, postalCode: "16052" },
    { lng: -79.27004, lat: 41.821327, postalCode: "16329" },
    { lng: -75.70596, lat: 40.828682, postalCode: "18235" },
    { lng: -75.95528, lat: 41.964875, postalCode: "18812" },
    { lng: -75.37542, lat: 40.601261, postalCode: "18015" },
    { lng: -78.42113, lat: 39.742192, postalCode: "17211" },
    { lng: -75.32185, lat: 40.922005, postalCode: "18354" },
    { lng: -76.38158, lat: 40.763048, postalCode: "17943" },
    { lng: -77.93356, lat: 41.034029, postalCode: "16874" },
    { lng: -76.93743, lat: 40.550027, postalCode: "17061" },
    { lng: -77.39337, lat: 40.436607, postalCode: "17037" },
    { lng: -75.19004, lat: 40.863124, postalCode: "18013" },
    { lng: -79.95766, lat: 40.631011, postalCode: "15044" },
    { lng: -77.718629, lat: 39.724825, postalCode: "17263" },
    { lng: -75.22311, lat: 40.026626, postalCode: "19127" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18109" },
    { lng: -78.694043, lat: 40.319561, postalCode: "15921" },
    { lng: -77.23037, lat: 40.739459, postalCode: "17812" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19170" },
    { lng: -76.69584, lat: 40.067559, postalCode: "17347" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15231" },
    { lng: -76.13179, lat: 41.555944, postalCode: "18629" },
    { lng: -78.56703, lat: 41.713565, postalCode: "16733" },
    { lng: -76.73738, lat: 40.720016, postalCode: "17823" },
    { lng: -79.99612, lat: 39.942802, postalCode: "15357" },
    { lng: -78.12821, lat: 39.847933, postalCode: "17238" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15290" },
    { lng: -79.04089, lat: 39.963306, postalCode: "15510" },
    { lng: -75.074832, lat: 40.416516, postalCode: "18950" },
    { lng: -78.962877, lat: 41.141079, postalCode: "15740" },
    { lng: -76.15367, lat: 40.003405, postalCode: "17572" },
    { lng: -80.49576, lat: 41.00745, postalCode: "16132" },
    { lng: -76.4814, lat: 40.658667, postalCode: "17938" },
    { lng: -80.40861, lat: 40.363006, postalCode: "15054" },
    { lng: -80.15214, lat: 41.637502, postalCode: "16335" },
    { lng: -79.6819, lat: 40.034355, postalCode: "15486" },
    { lng: -80.179475, lat: 40.44952, postalCode: "15275" },
    { lng: -75.04288, lat: 40.335974, postalCode: "18928" },
    { lng: -78.39317, lat: 41.957479, postalCode: "16731" },
    { lng: -78.79067, lat: 40.543153, postalCode: "15927" },
    { lng: -78.98412, lat: 40.592854, postalCode: "15765" },
    { lng: -77.34934, lat: 40.714443, postalCode: "17841" },
    { lng: -80.22367, lat: 41.443741, postalCode: "16130" },
    { lng: -78.47615, lat: 40.687083, postalCode: "16639" },
    { lng: -79.87846, lat: 39.964486, postalCode: "15475" },
    { lng: -79.84091, lat: 41.098857, postalCode: "16040" },
    { lng: -78.702224, lat: 40.484487, postalCode: "15915" },
    { lng: -76.07042, lat: 41.786507, postalCode: "18828" },
    { lng: -78.71981, lat: 40.294347, postalCode: "15930" },
    { lng: -75.40078, lat: 40.747742, postalCode: "18014" },
    { lng: -75.337234, lat: 39.88211, postalCode: "19039" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18931" },
    { lng: -77.14224, lat: 41.924013, postalCode: "16946" },
    { lng: -79.3633, lat: 41.626321, postalCode: "16322" },
    { lng: -76.40602, lat: 40.273968, postalCode: "17016" },
    { lng: -79.28293, lat: 41.221629, postalCode: "16258" },
    { lng: -76.880884, lat: 40.261516, postalCode: "17129" },
    { lng: -79.84994, lat: 40.186047, postalCode: "15087" },
    { lng: -75.87002, lat: 40.327741, postalCode: "19606" },
    { lng: -79.16422, lat: 40.677609, postalCode: "15739" },
    { lng: -77.52998, lat: 40.92744, postalCode: "16852" },
    { lng: -78.08406, lat: 40.092488, postalCode: "16689" },
    { lng: -75.72679, lat: 41.738688, postalCode: "18826" },
    { lng: -75.77244, lat: 39.825643, postalCode: "19311" },
    { lng: -79.86027, lat: 41.526679, postalCode: "16317" },
    { lng: -75.43058, lat: 41.075315, postalCode: "18334" },
    { lng: -79.90832, lat: 39.918848, postalCode: "15410" },
    { lng: -80.0004, lat: 42.126956, postalCode: "16510" },
    { lng: -78.10583, lat: 40.561651, postalCode: "16611" },
    { lng: -79.846, lat: 40.100345, postalCode: "15412" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18774" },
    { lng: -77.98905, lat: 41.800278, postalCode: "16915" },
    { lng: -76.67748, lat: 39.765405, postalCode: "17361" },
    { lng: -76.438413, lat: 41.020062, postalCode: "17839" },
    { lng: -77.242805, lat: 40.136687, postalCode: "17012" },
    { lng: -76.20494, lat: 40.909697, postalCode: "17985" },
    { lng: -75.56795, lat: 41.550591, postalCode: "18433" },
    { lng: -78.2221, lat: 40.456531, postalCode: "16693" },
    { lng: -75.50845, lat: 40.410224, postalCode: "18041" },
    { lng: -76.703553, lat: 40.079591, postalCode: "17346" },
    { lng: -79.84392, lat: 40.877703, postalCode: "16029" },
    { lng: -80.42771, lat: 41.330028, postalCode: "16154" },
    { lng: -79.0308, lat: 41.08434, postalCode: "15847" },
    { lng: -76.15056, lat: 39.872437, postalCode: "17566" },
    { lng: -75.18259, lat: 39.922262, postalCode: "19145" },
    { lng: -77.31604, lat: 41.186038, postalCode: "17721" },
    { lng: -77.20537, lat: 40.801614, postalCode: "17882" },
    { lng: -80.05916, lat: 40.617016, postalCode: "15090" },
    { lng: -78.50411, lat: 41.254727, postalCode: "15841" },
    { lng: -75.26939, lat: 39.903312, postalCode: "19079" },
    { lng: -75.17106, lat: 40.07226, postalCode: "19150" },
    { lng: -76.17626, lat: 41.067712, postalCode: "18601" },
    { lng: -80.34539, lat: 41.038205, postalCode: "16105" },
    { lng: -79.88725, lat: 40.424336, postalCode: "15218" },
    { lng: -76.33328, lat: 40.03263, postalCode: "17603" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15130" },
    { lng: -80.065793, lat: 41.666057, postalCode: "16328" },
    { lng: -75.34474, lat: 41.255644, postalCode: "18460" },
    { lng: -75.60049, lat: 40.549119, postalCode: "18087" },
    { lng: -78.72404, lat: 40.890039, postalCode: "15757" },
    { lng: -75.57621, lat: 40.176858, postalCode: "19475" },
    { lng: -80.18692, lat: 40.420319, postalCode: "15071" },
    { lng: -75.04966, lat: 40.023611, postalCode: "19135" },
    { lng: -80.179139, lat: 40.214896, postalCode: "15036" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15268" },
    { lng: -75.0154, lat: 40.117413, postalCode: "19116" },
    { lng: -75.617739, lat: 40.146549, postalCode: "19442" },
    { lng: -75.58449, lat: 40.204252, postalCode: "19457" },
    { lng: -80.27534, lat: 40.857663, postalCode: "16117" },
    { lng: -76.116445, lat: 40.209941, postalCode: "17567" },
    { lng: -75.453631, lat: 40.133355, postalCode: "19456" },
    { lng: -78.86612, lat: 40.289442, postalCode: "15904" },
    { lng: -80.01655, lat: 40.394002, postalCode: "15226" },
    { lng: -79.309668, lat: 41.935848, postalCode: "16312" },
    { lng: -78.12121, lat: 40.972358, postalCode: "16849" },
    { lng: -75.47097, lat: 40.257069, postalCode: "19473" },
    { lng: -78.73335, lat: 40.628368, postalCode: "15773" },
    { lng: -75.52104, lat: 40.601996, postalCode: "18104" },
    { lng: -79.83426, lat: 40.498218, postalCode: "15147" },
    { lng: -76.20421, lat: 40.820862, postalCode: "17976" },
    { lng: -77.200131, lat: 40.015656, postalCode: "17337" },
    { lng: -79.66317, lat: 41.097836, postalCode: "16049" },
    { lng: -77.71034, lat: 40.228687, postalCode: "17219" },
    { lng: -80.05187, lat: 42.110156, postalCode: "16504" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15266" },
    { lng: -77.023601, lat: 41.33275, postalCode: "17723" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15134" },
    { lng: -76.24243, lat: 41.600392, postalCode: "18846" },
    { lng: -77.70883, lat: 40.153677, postalCode: "17262" },
    { lng: -76.782323, lat: 40.389865, postalCode: "17128" },
    { lng: -79.83422, lat: 40.732393, postalCode: "16056" },
    { lng: -76.51679, lat: 41.142069, postalCode: "17846" },
    { lng: -76.72086, lat: 40.592398, postalCode: "17048" },
    { lng: -76.90271, lat: 40.696064, postalCode: "17864" },
    { lng: -75.04617, lat: 41.471736, postalCode: "18457" },
    { lng: -75.94215, lat: 40.327792, postalCode: "19611" },
    { lng: -79.23196, lat: 40.776212, postalCode: "16250" },
    { lng: -75.30847, lat: 39.884562, postalCode: "19076" },
    { lng: -79.65224, lat: 41.919028, postalCode: "16407" },
    { lng: -77.58878, lat: 39.969722, postalCode: "17254" },
    { lng: -76.883734, lat: 40.267518, postalCode: "17123" },
    { lng: -78.89205, lat: 40.536405, postalCode: "15957" },
    { lng: -80.065793, lat: 41.666057, postalCode: "16432" },
    { lng: -75.04837, lat: 40.059611, postalCode: "19152" },
    { lng: -80.07364, lat: 40.329619, postalCode: "15241" },
    { lng: -75.71102, lat: 41.597115, postalCode: "18420" },
    { lng: -75.52671, lat: 41.344794, postalCode: "18444" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18039" },
    { lng: -75.81997, lat: 40.447093, postalCode: "19522" },
    { lng: -77.90039, lat: 40.079326, postalCode: "17215" },
    { lng: -75.544822, lat: 39.932821, postalCode: "19395" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18098" },
    { lng: -75.4582, lat: 40.276511, postalCode: "19478" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15260" },
    { lng: -76.95078, lat: 41.592461, postalCode: "17765" },
    { lng: -77.52164, lat: 41.895838, postalCode: "16950" },
    { lng: -75.743272, lat: 40.058173, postalCode: "19354" },
    { lng: -76.735116, lat: 39.775037, postalCode: "17332" },
    { lng: -79.439801, lat: 40.360223, postalCode: "15069" },
    { lng: -77.213493, lat: 39.8948, postalCode: "17326" },
    { lng: -76.04649, lat: 40.000723, postalCode: "17535" },
    { lng: -76.49446, lat: 41.421281, postalCode: "18626" },
    { lng: -76.67785, lat: 39.976898, postalCode: "17402" },
    { lng: -76.5442, lat: 41.349559, postalCode: "17758" },
    { lng: -78.37197, lat: 40.217753, postalCode: "16695" },
    { lng: -75.41501, lat: 40.540398, postalCode: "18034" },
    { lng: -75.13458, lat: 40.240295, postalCode: "18976" },
    { lng: -80.33492, lat: 41.135063, postalCode: "16142" },
    { lng: -76.26024, lat: 40.690455, postalCode: "17954" },
    { lng: -75.71535, lat: 41.391279, postalCode: "18517" },
    { lng: -80.35051, lat: 39.82644, postalCode: "15341" },
    { lng: -75.98173, lat: 40.989783, postalCode: "18239" },
    { lng: -80.09304, lat: 40.661431, postalCode: "15086" },
    { lng: -75.1447, lat: 40.035778, postalCode: "19141" },
    { lng: -79.04707, lat: 41.345663, postalCode: "15860" },
    { lng: -80.03359, lat: 40.695658, postalCode: "16046" },
    { lng: -77.875191, lat: 40.904645, postalCode: "16835" },
    { lng: -79.17407, lat: 40.500385, postalCode: "15731" },
    { lng: -75.2436, lat: 40.324823, postalCode: "18927" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19381" },
    { lng: -75.917477, lat: 40.378451, postalCode: "19685" },
    { lng: -75.96683, lat: 40.250592, postalCode: "19540" },
    { lng: -76.89053, lat: 40.272839, postalCode: "17102" },
    { lng: -76.17781, lat: 39.9656, postalCode: "17579" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19346" },
    { lng: -75.71228, lat: 41.35838, postalCode: "18507" },
    { lng: -80.49896, lat: 41.230106, postalCode: "16146" },
    { lng: -78.861308, lat: 40.49963, postalCode: "15960" },
    { lng: -75.50109, lat: 39.897677, postalCode: "19342" },
    { lng: -78.26071, lat: 40.807219, postalCode: "16677" },
    { lng: -76.5997, lat: 40.706213, postalCode: "17836" },
    { lng: -75.2493, lat: 40.003762, postalCode: "19066" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15257" },
    { lng: -78.6651, lat: 40.384718, postalCode: "15946" },
    { lng: -75.62979, lat: 41.663906, postalCode: "18441" },
    { lng: -79.785885, lat: 40.098721, postalCode: "15973" },
    { lng: -76.69939, lat: 39.756761, postalCode: "17355" },
    { lng: -75.66588, lat: 41.41018, postalCode: "18503" },
    { lng: -75.13718, lat: 41.694366, postalCode: "18469" },
    { lng: -75.58893, lat: 40.569288, postalCode: "18106" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16522" },
    { lng: -78.37421, lat: 40.439272, postalCode: "16648" },
    { lng: -75.7553, lat: 39.830992, postalCode: "19374" },
    { lng: -75.64431, lat: 41.803936, postalCode: "18820" },
    { lng: -76.27269, lat: 40.488558, postalCode: "19507" },
    { lng: -77.242805, lat: 40.136687, postalCode: "17091" },
    { lng: -79.65393, lat: 41.160944, postalCode: "16054" },
    { lng: -75.480887, lat: 40.088156, postalCode: "19853" },
    { lng: -78.675548, lat: 40.671181, postalCode: "16675" },
    { lng: -75.33562, lat: 39.929511, postalCode: "19064" },
    { lng: -75.0887, lat: 40.211241, postalCode: "18974" },
    { lng: -77.49516, lat: 41.108454, postalCode: "17751" },
    { lng: -79.68406, lat: 40.217118, postalCode: "15698" },
    { lng: -80.45319, lat: 41.174066, postalCode: "16159" },
    { lng: -76.789624, lat: 39.900579, postalCode: "17371" },
    { lng: -78.1723, lat: 40.341859, postalCode: "16657" },
    { lng: -79.56708, lat: 40.612649, postalCode: "15690" },
    { lng: -79.91321, lat: 40.136537, postalCode: "15022" },
    { lng: -78.43838, lat: 41.026239, postalCode: "16830" },
    { lng: -77.57953, lat: 40.641204, postalCode: "17099" },
    { lng: -79.64061, lat: 40.330326, postalCode: "15675" },
    { lng: -75.26517, lat: 39.916262, postalCode: "19023" },
    { lng: -75.15409, lat: 39.936562, postalCode: "19147" },
    { lng: -79.42231, lat: 41.826878, postalCode: "16340" },
    { lng: -79.59396, lat: 40.103791, postalCode: "15683" },
    { lng: -76.24974, lat: 40.308675, postalCode: "17073" },
    { lng: -76.80298, lat: 40.264389, postalCode: "17111" },
    { lng: -75.25091, lat: 40.201932, postalCode: "19436" },
    { lng: -75.386136, lat: 39.921061, postalCode: "19065" },
    { lng: -76.55479, lat: 40.584748, postalCode: "17980" },
    { lng: -79.61645, lat: 40.366748, postalCode: "15623" },
    { lng: -75.08769, lat: 40.017362, postalCode: "19124" },
    { lng: -80.07248, lat: 39.935562, postalCode: "15346" },
    { lng: -80.04848, lat: 40.789672, postalCode: "16033" },
    { lng: -77.464257, lat: 40.019915, postalCode: "17275" },
    { lng: -76.38889, lat: 41.501249, postalCode: "18614" },
    { lng: -79.67948, lat: 40.451664, postalCode: "15668" },
    { lng: -76.84165, lat: 39.81667, postalCode: "17311" },
    { lng: -77.32608, lat: 39.871147, postalCode: "17343" },
    { lng: -75.445589, lat: 39.894161, postalCode: "19052" },
    { lng: -79.52297, lat: 40.355049, postalCode: "15633" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18981" },
    { lng: -75.067622, lat: 40.349443, postalCode: "18934" },
    { lng: -79.3902, lat: 41.493473, postalCode: "16353" },
    { lng: -76.009125, lat: 40.48586, postalCode: "19156" },
    { lng: -76.66707, lat: 40.095995, postalCode: "17502" },
    { lng: -80.11374, lat: 40.700423, postalCode: "16066" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15127" },
    { lng: -75.1605, lat: 39.959662, postalCode: "19108" },
    { lng: -77.83124, lat: 40.510176, postalCode: "17002" },
    { lng: -80.35888, lat: 41.746056, postalCode: "16406" },
    { lng: -76.835439, lat: 40.834408, postalCode: "17831" },
    { lng: -76.24258, lat: 40.794162, postalCode: "17949" },
    { lng: -76.260725, lat: 40.233892, postalCode: "17533" },
    { lng: -76.5806, lat: 40.280149, postalCode: "17010" },
    { lng: -80.01269, lat: 40.428369, postalCode: "15211" },
    { lng: -75.99548, lat: 40.897038, postalCode: "18237" },
    { lng: -79.51047, lat: 41.860822, postalCode: "16436" },
    { lng: -77.218368, lat: 41.186545, postalCode: "17720" },
    { lng: -76.89927, lat: 39.82457, postalCode: "17354" },
    { lng: -75.56937, lat: 40.330348, postalCode: "19435" },
    { lng: -79.899044, lat: 40.476524, postalCode: "15246" },
    { lng: -76.86849, lat: 40.22004, postalCode: "17070" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19194" },
    { lng: -76.801693, lat: 40.308638, postalCode: "17108" },
    { lng: -76.377114, lat: 41.016832, postalCode: "18715" },
    { lng: -75.09357, lat: 40.256648, postalCode: "18929" },
    { lng: -76.86828, lat: 41.017937, postalCode: "17886" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19488" },
    { lng: -80.08174, lat: 41.169987, postalCode: "16127" },
    { lng: -80.20977, lat: 40.565367, postalCode: "15056" },
    { lng: -77.25975, lat: 41.209389, postalCode: "17740" },
    { lng: -78.422403, lat: 40.989115, postalCode: "16698" },
    { lng: -75.12383, lat: 41.743598, postalCode: "18415" },
    { lng: -75.621058, lat: 40.585561, postalCode: "18175" },
    { lng: -79.86138, lat: 40.176375, postalCode: "15033" },
    { lng: -76.59778, lat: 39.90035, postalCode: "17356" },
    { lng: -78.04002, lat: 40.274021, postalCode: "16623" },
    { lng: -79.60071, lat: 40.201398, postalCode: "15639" },
    { lng: -75.40884, lat: 41.515012, postalCode: "18459" },
    { lng: -79.80792, lat: 40.384223, postalCode: "15035" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17577" },
    { lng: -80.08437, lat: 42.122957, postalCode: "16501" },
    { lng: -76.42344, lat: 40.31977, postalCode: "17042" },
    { lng: -78.46167, lat: 40.841761, postalCode: "16661" },
    { lng: -78.99023, lat: 40.053525, postalCode: "15541" },
    { lng: -79.02664, lat: 40.986389, postalCode: "15764" },
    { lng: -78.84235, lat: 39.863486, postalCode: "15538" },
    { lng: -79.87191, lat: 40.438035, postalCode: "15221" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19424" },
    { lng: -78.61075, lat: 41.337926, postalCode: "15846" },
    { lng: -78.16804, lat: 40.994188, postalCode: "16847" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16444" },
    { lng: -75.94189, lat: 40.924427, postalCode: "18230" },
    { lng: -75.63425, lat: 40.040505, postalCode: "19341" },
    { lng: -76.52753, lat: 40.406144, postalCode: "17077" },
    { lng: -79.04221, lat: 40.771687, postalCode: "15759" },
    { lng: -75.84569, lat: 41.268231, postalCode: "18705" },
    { lng: -75.59722, lat: 41.64872, postalCode: "18413" },
    { lng: -76.45298, lat: 41.762543, postalCode: "18848" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15258" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15189" },
    { lng: -76.68112, lat: 39.753369, postalCode: "17349" },
    { lng: -77.7892, lat: 40.942533, postalCode: "16853" },
    { lng: -77.60545, lat: 41.092339, postalCode: "16822" },
    { lng: -75.88034, lat: 40.444786, postalCode: "19510" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18935" },
    { lng: -80.10273, lat: 40.019657, postalCode: "15345" },
    { lng: -76.71173, lat: 41.280066, postalCode: "17762" },
    { lng: -76.1278, lat: 40.731739, postalCode: "17959" },
    { lng: -79.141174, lat: 41.027511, postalCode: "15874" },
    { lng: -79.9969, lat: 39.809756, postalCode: "15334" },
    { lng: -76.882854, lat: 40.266221, postalCode: "17125" },
    { lng: -76.88279, lat: 40.261839, postalCode: "17101" },
    { lng: -80.308376, lat: 40.989662, postalCode: "16103" },
    { lng: -80.44199, lat: 40.264217, postalCode: "15312" },
    { lng: -75.96098, lat: 40.700548, postalCode: "17960" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15253" },
    { lng: -76.7808, lat: 40.343261, postalCode: "17112" },
    { lng: -77.911776, lat: 40.726405, postalCode: "16868" },
    { lng: -78.91752, lat: 40.877189, postalCode: "15772" },
    { lng: -75.88198, lat: 40.832477, postalCode: "18232" },
    { lng: -79.00688, lat: 40.717399, postalCode: "15734" },
    { lng: -76.1841, lat: 40.492053, postalCode: "19559" },
    { lng: -80.03721, lat: 40.739587, postalCode: "16024" },
    { lng: -78.4745, lat: 40.945702, postalCode: "16837" },
    { lng: -78.62593, lat: 41.082722, postalCode: "15856" },
    { lng: -75.28817, lat: 40.556976, postalCode: "18081" },
    { lng: -75.51958, lat: 40.465994, postalCode: "18092" },
    { lng: -77.0616, lat: 41.676922, postalCode: "16912" },
    { lng: -75.10949, lat: 40.09811, postalCode: "19046" },
    { lng: -75.26953, lat: 40.346017, postalCode: "18962" },
    { lng: -79.53852, lat: 41.560056, postalCode: "16341" },
    { lng: -80.02604, lat: 40.455919, postalCode: "15233" },
    { lng: -79.78095, lat: 40.61357, postalCode: "15084" },
    { lng: -79.43125, lat: 41.362104, postalCode: "16326" },
    { lng: -80.02045, lat: 40.105887, postalCode: "15331" },
    { lng: -76.19111, lat: 40.718586, postalCode: "17970" },
    { lng: -78.24353, lat: 40.666444, postalCode: "16686" },
    { lng: -75.18067, lat: 39.938512, postalCode: "19146" },
    { lng: -78.76419, lat: 40.491089, postalCode: "15948" },
    { lng: -75.062204, lat: 40.271104, postalCode: "18946" },
    { lng: -78.24245, lat: 39.989224, postalCode: "15533" },
    { lng: -75.5154, lat: 40.316397, postalCode: "18074" },
    { lng: -76.85763, lat: 40.002696, postalCode: "17315" },
    { lng: -78.31987, lat: 40.306709, postalCode: "16662" },
    { lng: -80.46266, lat: 41.096535, postalCode: "16143" },
    { lng: -78.80196, lat: 40.280875, postalCode: "15934" },
    { lng: -75.14503, lat: 40.012212, postalCode: "19140" },
    { lng: -80.46312, lat: 41.266185, postalCode: "16150" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19495" },
    { lng: -76.6963, lat: 41.448101, postalCode: "18619" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18765" },
    { lng: -77.75122, lat: 41.993777, postalCode: "16941" },
    { lng: -76.06397, lat: 41.098371, postalCode: "18660" },
    { lng: -76.53547, lat: 40.848885, postalCode: "17824" },
    { lng: -78.02273, lat: 40.070337, postalCode: "17229" },
    { lng: -76.02508, lat: 39.919072, postalCode: "17509" },
    { lng: -79.78223, lat: 40.543053, postalCode: "15144" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15283" },
    { lng: -79.95487, lat: 40.443269, postalCode: "15213" },
    { lng: -78.719019, lat: 40.380188, postalCode: "15962" },
    { lng: -79.92474, lat: 40.251586, postalCode: "15038" },
    { lng: -79.42598, lat: 40.077289, postalCode: "15490" },
    { lng: -80.05254, lat: 40.261394, postalCode: "15367" },
    { lng: -80.38291, lat: 40.970991, postalCode: "16102" },
    { lng: -77.31915, lat: 41.736343, postalCode: "16901" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19494" },
    { lng: -75.405987, lat: 39.934047, postalCode: "19037" },
    { lng: -77.12245, lat: 40.14789, postalCode: "17007" },
    { lng: -77.78665, lat: 41.493592, postalCode: "17729" },
    { lng: -76.60854, lat: 41.782906, postalCode: "18814" },
    { lng: -78.77215, lat: 39.983808, postalCode: "15553" },
    { lng: -79.686626, lat: 40.294021, postalCode: "15691" },
    { lng: -79.34332, lat: 40.694906, postalCode: "15736" },
    { lng: -75.30184, lat: 39.949962, postalCode: "19026" },
    { lng: -77.03834, lat: 40.799235, postalCode: "17842" },
    { lng: -80.38568, lat: 41.817144, postalCode: "16435" },
    { lng: -76.77124, lat: 40.856105, postalCode: "17801" },
    { lng: -80.31618, lat: 41.661068, postalCode: "16422" },
    { lng: -76.0785, lat: 40.645057, postalCode: "17961" },
    { lng: -76.30735, lat: 41.196076, postalCode: "18611" },
    { lng: -79.33007, lat: 41.977253, postalCode: "16350" },
    { lng: -76.26401, lat: 40.375621, postalCode: "17087" },
    { lng: -79.16146, lat: 40.626307, postalCode: "15701" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15265" },
    { lng: -76.62897, lat: 40.677386, postalCode: "17941" },
    { lng: -76.17415, lat: 41.837903, postalCode: "18829" },
    { lng: -75.53601, lat: 40.723681, postalCode: "18059" },
    { lng: -76.978448, lat: 40.167091, postalCode: "17008" },
    { lng: -79.88684, lat: 41.684627, postalCode: "16360" },
    { lng: -76.880021, lat: 40.26179, postalCode: "17126" },
    { lng: -80.06351, lat: 42.127807, postalCode: "16503" },
    { lng: -79.11389, lat: 41.013546, postalCode: "15776" },
    { lng: -75.3261, lat: 40.585649, postalCode: "18055" },
    { lng: -76.86904, lat: 40.138959, postalCode: "17339" },
    { lng: -76.707997, lat: 39.853644, postalCode: "17342" },
    { lng: -75.453735, lat: 40.092269, postalCode: "19481" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15264" },
    { lng: -75.01422, lat: 40.28083, postalCode: "18980" },
    { lng: -79.787096, lat: 41.785249, postalCode: "16414" },
    { lng: -80.378093, lat: 40.16113, postalCode: "15365" },
    { lng: -76.44339, lat: 39.747204, postalCode: "17321" },
    { lng: -75.04118, lat: 40.09261, postalCode: "19115" },
    { lng: -79.69298, lat: 41.622203, postalCode: "16354" },
    { lng: -78.00567, lat: 40.210859, postalCode: "17253" },
    { lng: -76.21665, lat: 40.125278, postalCode: "17508" },
    { lng: -76.503339, lat: 40.649109, postalCode: "17932" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16565" },
    { lng: -80.4241, lat: 41.280779, postalCode: "16113" },
    { lng: -75.34499, lat: 41.938498, postalCode: "18461" },
    { lng: -79.84251, lat: 40.140248, postalCode: "15012" },
    { lng: -75.14054, lat: 39.992862, postalCode: "19133" },
    { lng: -79.323745, lat: 40.55027, postalCode: "15727" },
    { lng: -79.599126, lat: 39.845088, postalCode: "15421" },
    { lng: -75.97024, lat: 39.9451, postalCode: "19310" },
    { lng: -79.79791, lat: 41.135335, postalCode: "16030" },
    { lng: -79.77947, lat: 40.583218, postalCode: "15030" },
    { lng: -76.83647, lat: 41.010754, postalCode: "17847" },
    { lng: -75.374155, lat: 40.320036, postalCode: "18918" },
    { lng: -76.72398, lat: 40.067348, postalCode: "17345" },
    { lng: -75.3088, lat: 40.012661, postalCode: "19041" },
    { lng: -76.82304, lat: 41.649813, postalCode: "17724" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19489" },
    { lng: -77.91154, lat: 40.453498, postalCode: "17060" },
    { lng: -79.00013, lat: 39.805436, postalCode: "15552" },
    { lng: -79.13928, lat: 41.027404, postalCode: "15784" },
    { lng: -77.84993, lat: 40.792011, postalCode: "16801" },
    { lng: -79.92758, lat: 40.921145, postalCode: "16016" },
    { lng: -77.002296, lat: 40.249543, postalCode: "17050" },
    { lng: -77.00557, lat: 41.117517, postalCode: "17810" },
    { lng: -79.554054, lat: 40.588257, postalCode: "15163" },
    { lng: -77.28278, lat: 41.229164, postalCode: "17741" },
    { lng: -75.708281, lat: 40.784095, postalCode: "18212" },
    { lng: -77.42424, lat: 40.908997, postalCode: "16820" },
    { lng: -78.9622, lat: 40.98415, postalCode: "15733" },
    { lng: -75.89369, lat: 41.28328, postalCode: "18709" },
    { lng: -78.7433, lat: 40.381332, postalCode: "15958" },
    { lng: -79.89869, lat: 40.01933, postalCode: "15417" },
    { lng: -80.39778, lat: 39.748534, postalCode: "15352" },
    { lng: -75.70315, lat: 40.502511, postalCode: "19562" },
    { lng: -79.86413, lat: 40.117368, postalCode: "15432" },
    { lng: -75.49224, lat: 40.651751, postalCode: "18052" },
    { lng: -76.24495, lat: 40.679479, postalCode: "17951" },
    { lng: -76.68758, lat: 40.708236, postalCode: "17867" },
    { lng: -79.453751, lat: 41.202325, postalCode: "16257" },
    { lng: -78.78764, lat: 40.813203, postalCode: "15721" },
    { lng: -75.310731, lat: 39.895362, postalCode: "19098" },
    { lng: -79.451575, lat: 40.847829, postalCode: "15682" },
    { lng: -79.19553, lat: 41.108591, postalCode: "15864" },
    { lng: -75.750203, lat: 39.901177, postalCode: "19375" },
    { lng: -78.10103, lat: 40.40537, postalCode: "16647" },
    { lng: -77.31228, lat: 40.616926, postalCode: "17076" },
    { lng: -79.75012, lat: 41.410693, postalCode: "16343" },
    { lng: -78.37324, lat: 40.998733, postalCode: "16855" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16563" },
    { lng: -75.232407, lat: 40.185934, postalCode: "19477" },
    { lng: -78.50206, lat: 41.925564, postalCode: "16745" },
    { lng: -79.73165, lat: 40.22615, postalCode: "15640" },
    { lng: -79.439801, lat: 40.360223, postalCode: "15621" },
    { lng: -76.90382, lat: 41.271098, postalCode: "17754" },
    { lng: -78.39897, lat: 40.328843, postalCode: "16673" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15244" },
    { lng: -76.20682, lat: 41.038241, postalCode: "18635" },
    { lng: -77.19808, lat: 40.88358, postalCode: "17835" },
    { lng: -75.9194, lat: 41.133482, postalCode: "18707" },
    { lng: -75.49795, lat: 40.898654, postalCode: "18333" },
    { lng: -77.5843, lat: 40.4305, postalCode: "17035" },
    { lng: -79.768901, lat: 39.976316, postalCode: "15488" },
    { lng: -77.90623, lat: 39.957564, postalCode: "17224" },
    { lng: -77.40671, lat: 41.357921, postalCode: "17739" },
    { lng: -79.453751, lat: 41.202325, postalCode: "16058" },
    { lng: -80.28776, lat: 40.603966, postalCode: "15001" },
    { lng: -75.1745, lat: 39.981062, postalCode: "19121" },
    { lng: -75.28088, lat: 39.893212, postalCode: "19032" },
    { lng: -75.44613, lat: 40.343587, postalCode: "18054" },
    { lng: -74.91203, lat: 40.173563, postalCode: "19047" },
    { lng: -76.78563, lat: 40.208591, postalCode: "17034" },
    { lng: -75.5961, lat: 39.851803, postalCode: "19317" },
    { lng: -75.516529, lat: 39.882274, postalCode: "19331" },
    { lng: -77.08184, lat: 40.978059, postalCode: "17883" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16475" },
    { lng: -79.82769, lat: 40.586408, postalCode: "15075" },
    { lng: -75.31938, lat: 40.478742, postalCode: "18955" },
    { lng: -76.875207, lat: 40.278498, postalCode: "17105" },
    { lng: -76.11934, lat: 41.803273, postalCode: "18839" },
    { lng: -78.41772, lat: 40.174807, postalCode: "16664" },
    { lng: -78.900533, lat: 40.206435, postalCode: "15953" },
    { lng: -74.83617, lat: 40.149009, postalCode: "19055" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19162" },
    { lng: -77.40826, lat: 40.172412, postalCode: "17241" },
    { lng: -75.32489, lat: 39.906312, postalCode: "19070" },
    { lng: -80.42585, lat: 41.999881, postalCode: "16430" },
    { lng: -77.857889, lat: 39.859432, postalCode: "17231" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18710" },
    { lng: -80.31845, lat: 40.290375, postalCode: "15340" },
    { lng: -76.50919, lat: 40.109445, postalCode: "17552" },
    { lng: -79.34803, lat: 39.817148, postalCode: "15424" },
    { lng: -78.4841, lat: 41.96192, postalCode: "16729" },
    { lng: -79.86119, lat: 40.818663, postalCode: "16002" },
    { lng: -75.49765, lat: 40.912421, postalCode: "18058" },
    { lng: -75.29479, lat: 40.278294, postalCode: "19440" },
    { lng: -80.21327, lat: 39.748302, postalCode: "15362" },
    { lng: -78.93802, lat: 40.302374, postalCode: "15905" },
    { lng: -76.46387, lat: 40.80436, postalCode: "17832" },
    { lng: -79.34079, lat: 40.321426, postalCode: "15620" },
    { lng: -76.94145, lat: 41.796032, postalCode: "16932" },
    { lng: -75.9921, lat: 40.324694, postalCode: "19609" },
    { lng: -76.50129, lat: 39.761007, postalCode: "17352" },
    { lng: -75.462796, lat: 40.276386, postalCode: "19743" },
    { lng: -80.2364, lat: 41.234591, postalCode: "16137" },
    { lng: -79.37922, lat: 40.954782, postalCode: "16223" },
    { lng: -78.665057, lat: 41.415878, postalCode: "15822" },
    { lng: -75.568502, lat: 40.538843, postalCode: "18046" },
    { lng: -80.2834, lat: 41.518256, postalCode: "16111" },
    { lng: -75.25256, lat: 39.975929, postalCode: "19151" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19244" },
    { lng: -79.58704, lat: 40.090582, postalCode: "15631" },
    { lng: -80.157553, lat: 41.659552, postalCode: "16388" },
    { lng: -79.66014, lat: 39.923495, postalCode: "15456" },
    { lng: -78.53394, lat: 40.87713, postalCode: "16861" },
    { lng: -77.22904, lat: 39.827337, postalCode: "17325" },
    { lng: -80.20599, lat: 40.641066, postalCode: "15005" },
    { lng: -75.98463, lat: 40.545014, postalCode: "19526" },
    { lng: -75.12642, lat: 40.535227, postalCode: "18972" },
    { lng: -80.32747, lat: 40.992023, postalCode: "16101" },
    { lng: -75.22829, lat: 40.223216, postalCode: "18936" },
    { lng: -79.58172, lat: 40.238134, postalCode: "15697" },
    { lng: -79.9339, lat: 40.007062, postalCode: "15429" },
    { lng: -77.4631, lat: 41.051654, postalCode: "17750" },
    { lng: -80.46851, lat: 40.875943, postalCode: "16120" },
    { lng: -80.16222, lat: 40.26819, postalCode: "15317" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15274" },
    { lng: -75.01568, lat: 40.262783, postalCode: "18956" },
    { lng: -74.98228, lat: 40.148759, postalCode: "19053" },
    { lng: -75.50841, lat: 41.839349, postalCode: "18465" },
    { lng: -77.08211, lat: 41.318934, postalCode: "17728" },
    { lng: -74.89912, lat: 40.093443, postalCode: "19021" },
    { lng: -79.54126, lat: 40.298765, postalCode: "15601" },
    { lng: -79.52466, lat: 40.196455, postalCode: "15685" },
    { lng: -75.56605, lat: 41.471937, postalCode: "18434" },
    { lng: -78.98999, lat: 40.674994, postalCode: "15728" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19187" },
    { lng: -79.70044, lat: 41.195768, postalCode: "16373" },
    { lng: -79.99313, lat: 40.447036, postalCode: "15222" },
    { lng: -75.81587, lat: 41.337585, postalCode: "18643" },
    { lng: -79.97458, lat: 40.492618, postalCode: "15209" },
    { lng: -76.37371, lat: 40.777606, postalCode: "17945" },
    { lng: -79.129445, lat: 40.640031, postalCode: "16211" },
    { lng: -78.46381, lat: 41.001993, postalCode: "16843" },
    { lng: -77.07752, lat: 40.762675, postalCode: "17843" },
    { lng: -77.13755, lat: 41.557848, postalCode: "16930" },
    { lng: -75.26353, lat: 39.874179, postalCode: "19113" },
    { lng: -79.04693, lat: 41.764498, postalCode: "16352" },
    { lng: -79.93303, lat: 40.652311, postalCode: "15007" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18767" },
    { lng: -76.26615, lat: 41.719942, postalCode: "18853" },
    { lng: -76.47131, lat: 40.792365, postalCode: "17834" },
    { lng: -76.12636, lat: 40.90757, postalCode: "18242" },
    { lng: -75.20657, lat: 40.282406, postalCode: "18914" },
    { lng: -80.06927, lat: 42.074056, postalCode: "16509" },
    { lng: -79.08486, lat: 41.040903, postalCode: "15730" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16546" },
    { lng: -75.36086, lat: 41.880371, postalCode: "18455" },
    { lng: -78.78711, lat: 41.023829, postalCode: "15866" },
    { lng: -80.21445, lat: 40.241854, postalCode: "15342" },
    { lng: -79.40911, lat: 41.569732, postalCode: "16370" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16534" },
    { lng: -77.46288, lat: 41.084417, postalCode: "17767" },
    { lng: -79.72574, lat: 40.344319, postalCode: "15647" },
    { lng: -75.76372, lat: 40.385143, postalCode: "19547" },
    { lng: -75.405987, lat: 39.934047, postalCode: "19339" },
    { lng: -76.08556, lat: 40.727464, postalCode: "17953" },
    { lng: -79.90541, lat: 40.011146, postalCode: "15444" },
    { lng: -75.71134, lat: 41.485845, postalCode: "18411" },
    { lng: -79.83394, lat: 40.61517, postalCode: "15076" },
    { lng: -79.47547, lat: 40.333489, postalCode: "15662" },
    { lng: -77.79678, lat: 41.351129, postalCode: "17764" },
    { lng: -75.15936, lat: 41.052199, postalCode: "18301" },
    { lng: -76.7872, lat: 40.157382, postalCode: "17319" },
    { lng: -78.09348, lat: 39.995374, postalCode: "17228" },
    { lng: -79.23651, lat: 40.175368, postalCode: "15677" },
    { lng: -79.3461, lat: 41.007757, postalCode: "16242" },
    { lng: -74.88565, lat: 41.320904, postalCode: "18337" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16553" },
    { lng: -79.24611, lat: 40.446361, postalCode: "15717" },
    { lng: -79.90196, lat: 39.847077, postalCode: "15461" },
    { lng: -79.77196, lat: 40.245378, postalCode: "15660" },
    { lng: -76.50508, lat: 41.949723, postalCode: "18810" },
    { lng: -75.36259, lat: 41.074757, postalCode: "18355" },
    { lng: -76.238766, lat: 40.043523, postalCode: "17528" },
    { lng: -75.32764, lat: 40.02371, postalCode: "19010" },
    { lng: -78.26845, lat: 40.132345, postalCode: "16650" },
    { lng: -76.57779, lat: 40.072108, postalCode: "17550" },
    { lng: -76.24934, lat: 41.061356, postalCode: "18603" },
    { lng: -76.30717, lat: 41.028617, postalCode: "18631" },
    { lng: -79.04992, lat: 40.91719, postalCode: "15780" },
    { lng: -77.01677, lat: 41.677577, postalCode: "16939" },
    { lng: -76.10833, lat: 40.513794, postalCode: "19554" },
    { lng: -80.23609, lat: 40.574634, postalCode: "15081" },
    { lng: -79.82547, lat: 42.029506, postalCode: "16442" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19184" },
    { lng: -75.40069, lat: 40.058808, postalCode: "19087" },
    { lng: -75.88984, lat: 39.985257, postalCode: "19369" },
    { lng: -76.87893, lat: 41.109525, postalCode: "17730" },
    { lng: -75.24702, lat: 41.429411, postalCode: "18438" },
    { lng: -76.0227, lat: 40.141301, postalCode: "17519" },
    { lng: -79.61922, lat: 40.97717, postalCode: "16028" },
    { lng: -75.413127, lat: 40.156555, postalCode: "19408" },
    { lng: -78.58059, lat: 41.816833, postalCode: "16726" },
    { lng: -75.36083, lat: 39.974378, postalCode: "19008" },
    { lng: -75.951709, lat: 40.254667, postalCode: "19546" },
    { lng: -77.18464, lat: 40.332785, postalCode: "17090" },
    { lng: -74.99786, lat: 41.141582, postalCode: "18324" },
    { lng: -80.09014, lat: 40.404119, postalCode: "15106" },
    { lng: -76.04473, lat: 41.909808, postalCode: "18818" },
    { lng: -76.604896, lat: 40.967437, postalCode: "17822" },
    { lng: -75.64919, lat: 40.552042, postalCode: "18031" },
    { lng: -76.28733, lat: 39.972349, postalCode: "17583" },
    { lng: -80.44474, lat: 39.976027, postalCode: "15377" },
    { lng: -79.92758, lat: 40.921145, postalCode: "16021" },
    { lng: -75.33843, lat: 40.123708, postalCode: "19401" },
    { lng: -78.7936, lat: 40.662676, postalCode: "15714" },
    { lng: -79.22064, lat: 39.798343, postalCode: "15540" },
    { lng: -76.885967, lat: 40.26746, postalCode: "17124" },
    { lng: -76.88449, lat: 41.173254, postalCode: "17752" },
    { lng: -77.66139, lat: 39.931123, postalCode: "17201" },
    { lng: -76.11639, lat: 41.957125, postalCode: "18830" },
    { lng: -79.087198, lat: 40.091429, postalCode: "15555" },
    { lng: -79.8646, lat: 40.332502, postalCode: "15133" },
    { lng: -78.55617, lat: 40.487413, postalCode: "16641" },
    { lng: -76.28315, lat: 40.793258, postalCode: "17935" },
    { lng: -75.06716, lat: 40.139539, postalCode: "19009" },
    { lng: -80.12409, lat: 41.274408, postalCode: "16133" },
    { lng: -75.20246, lat: 40.37327, postalCode: "18917" },
    { lng: -78.28335, lat: 40.85258, postalCode: "16666" },
    { lng: -78.66391, lat: 40.058077, postalCode: "15559" },
    { lng: -79.14313, lat: 40.26106, postalCode: "15638" },
    { lng: -79.185048, lat: 40.482783, postalCode: "15750" },
    { lng: -77.671754, lat: 40.049118, postalCode: "17246" },
    { lng: -76.664574, lat: 40.889632, postalCode: "17828" },
    { lng: -75.3405, lat: 40.103208, postalCode: "19405" },
    { lng: -75.53855, lat: 40.749545, postalCode: "18035" },
    { lng: -77.2917, lat: 39.930756, postalCode: "17307" },
    { lng: -75.24344, lat: 41.205738, postalCode: "18325" },
    { lng: -76.328081, lat: 40.024047, postalCode: "17504" },
    { lng: -75.88314, lat: 41.8237, postalCode: "18843" },
    { lng: -75.91205, lat: 40.355092, postalCode: "19604" },
    { lng: -76.44796, lat: 39.871433, postalCode: "17309" },
    { lng: -79.264322, lat: 41.811682, postalCode: "16368" },
    { lng: -79.09583, lat: 41.954896, postalCode: "16345" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15251" },
    { lng: -79.92569, lat: 40.430919, postalCode: "15217" },
    { lng: -78.5885, lat: 40.464754, postalCode: "16630" },
    { lng: -80.18346, lat: 41.402438, postalCode: "16114" },
    { lng: -75.91493, lat: 40.931187, postalCode: "18216" },
    { lng: -79.98393, lat: 39.883336, postalCode: "15320" },
    { lng: -75.357308, lat: 40.043399, postalCode: "19089" },
    { lng: -75.12854, lat: 40.317473, postalCode: "18901" },
    { lng: -75.51015, lat: 41.583517, postalCode: "18407" },
    { lng: -79.85575, lat: 40.13848, postalCode: "15072" },
    { lng: -75.611647, lat: 40.696738, postalCode: "18065" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19496" },
    { lng: -79.453751, lat: 41.202325, postalCode: "16221" },
    { lng: -74.81812, lat: 40.214661, postalCode: "19067" },
    { lng: -78.06665, lat: 40.336064, postalCode: "16622" },
    { lng: -79.7102, lat: 41.009219, postalCode: "16041" },
    { lng: -76.424297, lat: 41.042106, postalCode: "17858" },
    { lng: -80.13343, lat: 40.783001, postalCode: "16063" },
    { lng: -76.882745, lat: 40.265689, postalCode: "17120" },
    { lng: -76.238729, lat: 39.988548, postalCode: "17537" },
    { lng: -77.14962, lat: 40.5565, postalCode: "17062" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16532" },
    { lng: -79.0846, lat: 40.529238, postalCode: "15720" },
    { lng: -75.45915, lat: 40.595097, postalCode: "18103" },
    { lng: -80.03864, lat: 40.324535, postalCode: "15102" },
    { lng: -76.1487, lat: 41.653217, postalCode: "18623" },
    { lng: -75.26453, lat: 39.939609, postalCode: "19050" },
    { lng: -75.29039, lat: 41.012235, postalCode: "18321" },
    { lng: -78.07926, lat: 40.273559, postalCode: "16685" },
    { lng: -79.89491, lat: 40.272901, postalCode: "15088" },
    { lng: -75.11116, lat: 39.991712, postalCode: "19134" },
    { lng: -76.80346, lat: 40.64043, postalCode: "17080" },
    { lng: -76.55122, lat: 39.848832, postalCode: "17322" },
    { lng: -79.75483, lat: 40.211895, postalCode: "15089" },
    { lng: -76.53498, lat: 40.347757, postalCode: "17003" },
    { lng: -75.71389, lat: 40.02342, postalCode: "19335" },
    { lng: -79.59124, lat: 39.72974, postalCode: "15440" },
    { lng: -78.2344, lat: 41.104737, postalCode: "16836" },
    { lng: -75.10381, lat: 41.598906, postalCode: "18405" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19450" },
    { lng: -79.451575, lat: 40.847829, postalCode: "15630" },
    { lng: -75.49822, lat: 40.533597, postalCode: "18049" },
    { lng: -77.28825, lat: 40.202404, postalCode: "17081" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16531" },
    { lng: -77.62577, lat: 40.551431, postalCode: "17029" },
    { lng: -75.04129, lat: 40.376499, postalCode: "18913" },
    { lng: -78.41481, lat: 40.684811, postalCode: "16619" },
    { lng: -79.93414, lat: 40.399355, postalCode: "15207" },
    { lng: -76.87189, lat: 41.07552, postalCode: "17887" },
    { lng: -79.58961, lat: 40.631729, postalCode: "15641" },
    { lng: -75.29717, lat: 39.888312, postalCode: "19074" },
    { lng: -78.50032, lat: 40.798959, postalCode: "16645" },
    { lng: -78.84, lat: 41.619029, postalCode: "16734" },
    { lng: -76.02559, lat: 40.580744, postalCode: "19549" },
    { lng: -75.27624, lat: 39.992345, postalCode: "19096" },
    { lng: -76.506639, lat: 39.946739, postalCode: "17312" },
    { lng: -76.3147, lat: 40.389502, postalCode: "17067" },
    { lng: -79.90816, lat: 41.10936, postalCode: "16020" },
    { lng: -77.11862, lat: 40.675983, postalCode: "17086" },
    { lng: -80.14541, lat: 42.072606, postalCode: "16506" },
    { lng: -79.82339, lat: 40.408218, postalCode: "15145" },
    { lng: -79.82675, lat: 41.252823, postalCode: "16374" },
    { lng: -75.499046, lat: 40.013158, postalCode: "19353" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15096" },
    { lng: -77.07521, lat: 41.739613, postalCode: "16917" },
    { lng: -78.14362, lat: 41.800699, postalCode: "16746" },
    { lng: -78.873296, lat: 40.356723, postalCode: "15945" },
    { lng: -75.594, lat: 40.311246, postalCode: "19525" },
    { lng: -75.163572, lat: 39.950212, postalCode: "19110" },
    { lng: -76.305378, lat: 40.110201, postalCode: "17606" },
    { lng: -76.35906, lat: 41.781522, postalCode: "18854" },
    { lng: -76.59359, lat: 39.765741, postalCode: "17363" },
    { lng: -77.79113, lat: 40.035958, postalCode: "17265" },
    { lng: -75.32755, lat: 39.890462, postalCode: "19033" },
    { lng: -75.984901, lat: 40.407173, postalCode: "19640" },
    { lng: -76.34017, lat: 40.802567, postalCode: "17927" },
    { lng: -78.72684, lat: 41.031691, postalCode: "15848" },
    { lng: -76.99827, lat: 40.215939, postalCode: "17055" },
    { lng: -75.23309, lat: 40.012862, postalCode: "19004" },
    { lng: -79.65576, lat: 40.359353, postalCode: "15636" },
    { lng: -75.706485, lat: 41.403529, postalCode: "18515" },
    { lng: -78.81868, lat: 41.049353, postalCode: "15865" },
    { lng: -75.45726, lat: 40.969985, postalCode: "18330" },
    { lng: -75.77536, lat: 41.951797, postalCode: "18822" },
    { lng: -78.422403, lat: 40.989115, postalCode: "16821" },
    { lng: -80.50611, lat: 41.074943, postalCode: "16155" },
    { lng: -76.2139, lat: 40.68514, postalCode: "17901" },
    { lng: -75.59506, lat: 41.822157, postalCode: "18825" },
    { lng: -79.77879, lat: 39.987054, postalCode: "15480" },
    { lng: -75.357678, lat: 40.043201, postalCode: "19080" },
    { lng: -79.59465, lat: 39.793095, postalCode: "15437" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19181" },
    { lng: -79.86798, lat: 39.958894, postalCode: "15420" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16554" },
    { lng: -75.26026, lat: 40.98314, postalCode: "18360" },
    { lng: -78.99646, lat: 40.964542, postalCode: "15781" },
    { lng: -76.597187, lat: 40.008647, postalCode: "17405" },
    { lng: -74.85548, lat: 40.108943, postalCode: "19007" },
    { lng: -77.72092, lat: 40.195365, postalCode: "17220" },
    { lng: -80.42414, lat: 40.240402, postalCode: "15379" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19493" },
    { lng: -78.7063, lat: 40.324295, postalCode: "15955" },
    { lng: -76.232229, lat: 39.9469, postalCode: "17568" },
    { lng: -79.83296, lat: 42.195488, postalCode: "16428" },
    { lng: -77.65046, lat: 40.354433, postalCode: "17021" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16533" },
    { lng: -80.00756, lat: 40.289752, postalCode: "15129" },
    { lng: -80.1145, lat: 41.774575, postalCode: "16440" },
    { lng: -80.28881, lat: 40.677132, postalCode: "15061" },
    { lng: -79.30172, lat: 40.339547, postalCode: "15627" },
    { lng: -76.06652, lat: 40.811198, postalCode: "18214" },
    { lng: -79.20159, lat: 41.344067, postalCode: "16217" },
    { lng: -79.451575, lat: 40.847829, postalCode: "16261" },
    { lng: -76.94481, lat: 40.052872, postalCode: "17365" },
    { lng: -80.17983, lat: 39.892235, postalCode: "15370" },
    { lng: -76.99402, lat: 40.586914, postalCode: "17045" },
    { lng: -79.95226, lat: 39.754889, postalCode: "15327" },
    { lng: -75.12565, lat: 39.978162, postalCode: "19125" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15276" },
    { lng: -80.22872, lat: 40.214331, postalCode: "15347" },
    { lng: -75.73826, lat: 41.537933, postalCode: "18414" },
    { lng: -75.973747, lat: 40.954484, postalCode: "18021" },
    { lng: -79.64333, lat: 39.998299, postalCode: "15455" },
    { lng: -75.13793, lat: 40.055411, postalCode: "19126" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19347" },
    { lng: -77.8739, lat: 40.373421, postalCode: "17066" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18025" },
    { lng: -75.35501, lat: 41.118465, postalCode: "18344" },
    { lng: -75.86638, lat: 40.394884, postalCode: "19650" },
    { lng: -75.17203, lat: 40.034111, postalCode: "19144" },
    { lng: -75.34542, lat: 39.874612, postalCode: "19094" },
    { lng: -78.6098, lat: 40.578804, postalCode: "16624" },
    { lng: -79.65886, lat: 40.060912, postalCode: "15428" },
    { lng: -75.77566, lat: 40.535102, postalCode: "19530" },
    { lng: -75.22486, lat: 39.981112, postalCode: "19131" },
    { lng: -79.42425, lat: 40.138405, postalCode: "15610" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15254" },
    { lng: -76.737545, lat: 40.608245, postalCode: "17030" },
    { lng: -80.41364, lat: 40.383884, postalCode: "15021" },
    { lng: -75.811789, lat: 39.852406, postalCode: "19318" },
    { lng: -79.83787, lat: 40.093982, postalCode: "15438" },
    { lng: -79.06371, lat: 40.159054, postalCode: "15547" },
    { lng: -75.36779, lat: 41.19012, postalCode: "18466" },
    { lng: -77.658267, lat: 40.771122, postalCode: "16815" },
    { lng: -75.55717, lat: 41.144565, postalCode: "18347" },
    { lng: -76.78511, lat: 41.950785, postalCode: "16925" },
    { lng: -75.32272, lat: 39.877262, postalCode: "19078" },
    { lng: -79.96704, lat: 41.445871, postalCode: "16362" },
    { lng: -76.258399, lat: 40.300505, postalCode: "17039" },
    { lng: -76.86138, lat: 40.812047, postalCode: "17870" },
    { lng: -76.33154, lat: 41.170314, postalCode: "17878" },
    { lng: -77.81584, lat: 40.809461, postalCode: "16851" },
    { lng: -75.49121, lat: 41.251286, postalCode: "18424" },
    { lng: -78.23205, lat: 40.160858, postalCode: "16633" },
    { lng: -79.93869, lat: 39.945514, postalCode: "15447" },
    { lng: -78.84402, lat: 40.474488, postalCode: "15943" },
    { lng: -75.15853, lat: 39.952112, postalCode: "19107" },
    { lng: -76.229623, lat: 40.722587, postalCode: "17942" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15272" },
    { lng: -75.85208, lat: 41.31943, postalCode: "18644" },
    { lng: -77.17854, lat: 40.768922, postalCode: "17813" },
    { lng: -75.26856, lat: 39.958512, postalCode: "19082" },
    { lng: -79.29524, lat: 41.045243, postalCode: "16224" },
    { lng: -79.13757, lat: 40.906164, postalCode: "15763" },
    { lng: -75.142522, lat: 40.38805, postalCode: "18949" },
    { lng: -79.439801, lat: 40.360223, postalCode: "15616" },
    { lng: -75.74189, lat: 41.333035, postalCode: "18641" },
    { lng: -80.2735, lat: 40.708166, postalCode: "15074" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18001" },
    { lng: -76.521266, lat: 41.77197, postalCode: "18815" },
    { lng: -75.4793, lat: 40.607747, postalCode: "18102" },
    { lng: -74.92461, lat: 40.173509, postalCode: "19048" },
    { lng: -76.76403, lat: 39.84987, postalCode: "17360" },
    { lng: -75.14262, lat: 40.982028, postalCode: "18327" },
    { lng: -77.581092, lat: 39.799481, postalCode: "17247" },
    { lng: -76.058391, lat: 41.014808, postalCode: "18247" },
    { lng: -78.52762, lat: 40.173012, postalCode: "16667" },
    { lng: -79.68946, lat: 41.471398, postalCode: "16344" },
    { lng: -80.29601, lat: 41.607955, postalCode: "16316" },
    { lng: -76.78947, lat: 40.899361, postalCode: "17857" },
    { lng: -75.39395, lat: 41.426197, postalCode: "18436" },
    { lng: -79.90179, lat: 40.292974, postalCode: "15025" },
    { lng: -75.100804, lat: 40.431382, postalCode: "18945" },
    { lng: -78.91515, lat: 40.156538, postalCode: "15936" },
    { lng: -78.27125, lat: 41.809546, postalCode: "16743" },
    { lng: -79.94493, lat: 40.238657, postalCode: "15020" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19196" },
    { lng: -79.85174, lat: 40.081257, postalCode: "15483" },
    { lng: -77.49843, lat: 41.936663, postalCode: "16918" },
    { lng: -77.29872, lat: 41.038555, postalCode: "17747" },
    { lng: -78.421434, lat: 40.262311, postalCode: "16614" },
    { lng: -75.426247, lat: 40.665597, postalCode: "18002" },
    { lng: -75.54488, lat: 40.734807, postalCode: "18086" },
    { lng: -74.744, lat: 41.411678, postalCode: "18340" },
    { lng: -78.045, lat: 40.801396, postalCode: "16870" },
    { lng: -77.77387, lat: 40.91255, postalCode: "16823" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19093" },
    { lng: -76.36018, lat: 39.994798, postalCode: "17551" },
    { lng: -75.70754, lat: 40.707516, postalCode: "18053" },
    { lng: -80.10024, lat: 42.110757, postalCode: "16502" },
    { lng: -78.93008, lat: 40.710407, postalCode: "15729" },
    { lng: -78.52204, lat: 39.998096, postalCode: "15522" },
    { lng: -79.66042, lat: 40.26974, postalCode: "15617" },
    { lng: -75.33611, lat: 41.866271, postalCode: "18437" },
    { lng: -79.83242, lat: 40.569723, postalCode: "15024" },
    { lng: -77.47633, lat: 40.847255, postalCode: "16832" },
    { lng: -79.8851, lat: 41.005075, postalCode: "16061" },
    { lng: -76.11711, lat: 40.89707, postalCode: "18248" },
    { lng: -78.88994, lat: 41.095493, postalCode: "15851" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18050" },
    { lng: -77.24667, lat: 41.978436, postalCode: "16940" },
    { lng: -75.91836, lat: 41.695782, postalCode: "18844" },
    { lng: -80.49346, lat: 40.976839, postalCode: "16112" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18099" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17503" },
    { lng: -79.9632, lat: 41.152347, postalCode: "16038" },
    { lng: -79.63584, lat: 40.17462, postalCode: "15679" },
    { lng: -75.583861, lat: 40.633542, postalCode: "18096" },
    { lng: -78.702224, lat: 40.484487, postalCode: "16699" },
    { lng: -75.62113, lat: 40.739576, postalCode: "18080" },
    { lng: -77.50622, lat: 40.053308, postalCode: "17257" },
    { lng: -79.69946, lat: 39.955933, postalCode: "15489" },
    { lng: -79.617187, lat: 40.310772, postalCode: "15664" },
    { lng: -76.936046, lat: 40.269738, postalCode: "17089" },
    { lng: -79.81105, lat: 40.341469, postalCode: "15131" },
    { lng: -78.66543, lat: 40.801942, postalCode: "15753" },
    { lng: -79.13528, lat: 40.854736, postalCode: "16256" },
    { lng: -76.521266, lat: 41.77197, postalCode: "16945" },
    { lng: -75.34838, lat: 40.445203, postalCode: "18951" },
    { lng: -79.96033, lat: 40.570915, postalCode: "15101" },
    { lng: -75.22082, lat: 40.681798, postalCode: "18042" },
    { lng: -80.01686, lat: 42.156157, postalCode: "16511" },
    { lng: -79.833585, lat: 39.882521, postalCode: "15463" },
    { lng: -76.82579, lat: 40.23299, postalCode: "17113" },
    { lng: -78.51947, lat: 40.612642, postalCode: "16636" },
    { lng: -76.82841, lat: 40.290439, postalCode: "17109" },
    { lng: -75.34392, lat: 40.241004, postalCode: "19443" },
    { lng: -75.96466, lat: 40.494943, postalCode: "19555" },
    { lng: -80.48002, lat: 41.942171, postalCode: "16443" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17564" },
    { lng: -78.61924, lat: 40.42026, postalCode: "15938" },
    { lng: -80.15431, lat: 40.301838, postalCode: "15339" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17699" },
    { lng: -77.60264, lat: 40.67845, postalCode: "17084" },
    { lng: -76.38129, lat: 40.558488, postalCode: "17963" },
    { lng: -79.87475, lat: 40.079185, postalCode: "15434" },
    { lng: -75.455408, lat: 40.080323, postalCode: "19482" },
    { lng: -75.95565, lat: 40.302492, postalCode: "19607" },
    { lng: -79.80038, lat: 40.237582, postalCode: "15083" },
    { lng: -79.61469, lat: 40.329238, postalCode: "15644" },
    { lng: -78.665057, lat: 41.415878, postalCode: "16728" },
    { lng: -78.95812, lat: 40.700574, postalCode: "15777" },
    { lng: -75.163722, lat: 39.949612, postalCode: "19109" },
    { lng: -78.77125, lat: 40.642174, postalCode: "15775" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15032" },
    { lng: -76.052379, lat: 40.117326, postalCode: "17506" },
    { lng: -75.325, lat: 40.305176, postalCode: "18964" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19105" },
    { lng: -80.46177, lat: 41.506801, postalCode: "16134" },
    { lng: -75.72077, lat: 41.853082, postalCode: "18834" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18911" },
    { lng: -75.13254, lat: 40.999857, postalCode: "18341" },
    { lng: -80.14613, lat: 41.732434, postalCode: "16433" },
    { lng: -77.31191, lat: 41.564177, postalCode: "16938" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18223" },
    { lng: -79.55779, lat: 40.593301, postalCode: "15673" },
    { lng: -79.84669, lat: 39.936535, postalCode: "15468" },
    { lng: -79.61837, lat: 40.430632, postalCode: "15632" },
    { lng: -76.016047, lat: 40.196641, postalCode: "17507" },
    { lng: -79.61381, lat: 40.63071, postalCode: "15656" },
    { lng: -78.303614, lat: 40.636691, postalCode: "16684" },
    { lng: -76.28548, lat: 39.859296, postalCode: "17532" },
    { lng: -75.00831, lat: 40.184745, postalCode: "18966" },
    { lng: -79.49244, lat: 40.219344, postalCode: "15689" },
    { lng: -76.45568, lat: 39.993398, postalCode: "17582" },
    { lng: -75.49158, lat: 40.786431, postalCode: "18038" },
    { lng: -76.81316, lat: 40.560414, postalCode: "17023" },
    { lng: -76.56393, lat: 40.791328, postalCode: "17866" },
    { lng: -79.36465, lat: 40.279151, postalCode: "15696" },
    { lng: -78.775076, lat: 40.693633, postalCode: "15738" },
    { lng: -79.45122, lat: 41.255691, postalCode: "16254" },
    { lng: -75.79296, lat: 41.568872, postalCode: "18419" },
    { lng: -79.98542, lat: 41.93958, postalCode: "16441" },
    { lng: -79.34352, lat: 40.95557, postalCode: "16253" },
    { lng: -79.14759, lat: 40.763402, postalCode: "15747" },
    { lng: -78.74648, lat: 41.125563, postalCode: "15801" },
    { lng: -80.40181, lat: 40.853014, postalCode: "16141" },
    { lng: -79.15521, lat: 41.853225, postalCode: "16365" },
    { lng: -76.882889, lat: 40.270222, postalCode: "17130" },
    { lng: -79.91863, lat: 40.468968, postalCode: "15206" },
    { lng: -79.83794, lat: 40.399436, postalCode: "15112" },
    { lng: -75.89259, lat: 40.167618, postalCode: "19543" },
    { lng: -75.37109, lat: 41.75098, postalCode: "18453" },
    { lng: -75.84233, lat: 39.820841, postalCode: "19390" },
    { lng: -76.08756, lat: 40.154498, postalCode: "17549" },
    { lng: -80.44364, lat: 40.802863, postalCode: "16115" },
    { lng: -78.65347, lat: 41.587333, postalCode: "15870" },
    { lng: -75.89424, lat: 41.747232, postalCode: "18816" },
    { lng: -77.0582, lat: 40.860555, postalCode: "17862" },
    { lng: -75.80831, lat: 41.753339, postalCode: "18813" },
    { lng: -77.96226, lat: 41.053528, postalCode: "16829" },
    { lng: -79.95164, lat: 40.503768, postalCode: "15223" },
    { lng: -77.22858, lat: 40.030925, postalCode: "17324" },
    { lng: -76.213075, lat: 40.116846, postalCode: "17580" },
    { lng: -78.90069, lat: 40.309087, postalCode: "15902" },
    { lng: -80.09147, lat: 42.097856, postalCode: "16508" },
    { lng: -75.80669, lat: 40.157078, postalCode: "19520" },
    { lng: -78.20555, lat: 40.163172, postalCode: "16679" },
    { lng: -75.22121, lat: 40.576989, postalCode: "18077" },
    { lng: -76.37738, lat: 41.241255, postalCode: "17814" },
    { lng: -77.218, lat: 39.969711, postalCode: "17304" },
    { lng: -75.137186, lat: 40.792804, postalCode: "18043" },
    { lng: -76.02422, lat: 40.312842, postalCode: "19608" },
    { lng: -77.292578, lat: 40.406766, postalCode: "17031" },
    { lng: -75.59729, lat: 39.940043, postalCode: "19382" },
    { lng: -80.031787, lat: 40.110068, postalCode: "15366" },
    { lng: -75.64124, lat: 40.089905, postalCode: "19425" },
    { lng: -79.858471, lat: 39.950123, postalCode: "15435" },
    { lng: -79.9981, lat: 40.05604, postalCode: "15358" },
    { lng: -79.939473, lat: 40.420019, postalCode: "15298" },
    { lng: -75.44873, lat: 40.185637, postalCode: "19426" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19487" },
    { lng: -75.82275, lat: 39.978456, postalCode: "19320" },
    { lng: -80.0115, lat: 40.479469, postalCode: "15214" },
    { lng: -77.96308, lat: 40.062255, postalCode: "17223" },
    { lng: -80.23629, lat: 40.357286, postalCode: "15057" },
    { lng: -79.49619, lat: 40.2071, postalCode: "15674" },
    { lng: -78.776137, lat: 40.338615, postalCode: "15951" },
    { lng: -79.43967, lat: 40.408295, postalCode: "15670" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15252" },
    { lng: -76.21546, lat: 39.915209, postalCode: "17560" },
    { lng: -75.405987, lat: 39.934047, postalCode: "19088" },
    { lng: -76.521266, lat: 41.77197, postalCode: "17743" },
    { lng: -75.24731, lat: 40.223524, postalCode: "19454" },
    { lng: -76.9018, lat: 39.92813, postalCode: "17364" },
    { lng: -75.12957, lat: 39.990562, postalCode: "19175" },
    { lng: -75.274708, lat: 41.615989, postalCode: "18449" },
    { lng: -78.50652, lat: 40.25915, postalCode: "16670" },
    { lng: -79.75186, lat: 39.821336, postalCode: "15436" },
    { lng: -79.37128, lat: 41.378962, postalCode: "16332" },
    { lng: -77.300085, lat: 39.923644, postalCode: "17303" },
    { lng: -75.7857, lat: 39.726143, postalCode: "19360" },
    { lng: -75.16558, lat: 39.952962, postalCode: "19102" },
    { lng: -76.42443, lat: 40.45253, postalCode: "17026" },
    { lng: -75.7393, lat: 40.48773, postalCode: "19511" },
    { lng: -76.297582, lat: 40.0185, postalCode: "17607" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19188" },
    { lng: -75.52341, lat: 40.126271, postalCode: "19460" },
    { lng: -77.52474, lat: 39.736395, postalCode: "17250" },
    { lng: -79.73603, lat: 40.713078, postalCode: "16055" },
    { lng: -76.05041, lat: 40.157565, postalCode: "17581" },
    { lng: -76.13741, lat: 40.357034, postalCode: "19551" },
    { lng: -79.72506, lat: 40.868261, postalCode: "16034" },
    { lng: -79.95726, lat: 40.471468, postalCode: "15201" },
    { lng: -76.13737, lat: 40.813869, postalCode: "17948" },
    { lng: -75.9375, lat: 40.390943, postalCode: "19605" },
    { lng: -78.43508, lat: 40.393223, postalCode: "16665" },
    { lng: -75.456719, lat: 40.152871, postalCode: "19420" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15255" },
    { lng: -75.637626, lat: 41.401881, postalCode: "18577" },
    { lng: -76.64059, lat: 40.632279, postalCode: "17978" },
    { lng: -75.88438, lat: 41.243181, postalCode: "18701" },
    { lng: -80.50393, lat: 41.098098, postalCode: "16140" },
    { lng: -76.9963, lat: 40.153656, postalCode: "17027" },
    { lng: -76.03662, lat: 41.37649, postalCode: "18618" },
    { lng: -76.434285, lat: 40.064746, postalCode: "17575" },
    { lng: -77.023601, lat: 41.33275, postalCode: "17722" },
    { lng: -76.59646, lat: 40.002547, postalCode: "17406" },
    { lng: -75.14336, lat: 39.977662, postalCode: "19122" },
    { lng: -80.06363, lat: 40.10012, postalCode: "15324" },
    { lng: -75.29156, lat: 40.237206, postalCode: "19446" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19255" },
    { lng: -79.46514, lat: 39.757928, postalCode: "15459" },
    { lng: -78.720134, lat: 40.388921, postalCode: "15965" },
    { lng: -78.56335, lat: 41.201892, postalCode: "15849" },
    { lng: -77.75273, lat: 41.105537, postalCode: "16864" },
    { lng: -75.18632, lat: 40.11461, postalCode: "19075" },
    { lng: -75.12695, lat: 40.127907, postalCode: "19001" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15285" },
    { lng: -74.88576, lat: 40.151509, postalCode: "19056" },
    { lng: -79.61611, lat: 40.231003, postalCode: "15672" },
    { lng: -75.44063, lat: 39.832762, postalCode: "19061" },
    { lng: -75.869653, lat: 40.526803, postalCode: "19564" },
    { lng: -80.08895, lat: 41.335391, postalCode: "16153" },
    { lng: -77.3794, lat: 39.876675, postalCode: "17353" },
    { lng: -77.35306, lat: 41.961001, postalCode: "16942" },
    { lng: -80.37143, lat: 41.549671, postalCode: "16131" },
    { lng: -80.45797, lat: 41.232543, postalCode: "16148" },
    { lng: -76.39579, lat: 40.632681, postalCode: "17981" },
    { lng: -80.37501, lat: 41.508647, postalCode: "16110" },
    { lng: -80.37399, lat: 39.943552, postalCode: "15337" },
    { lng: -80.386344, lat: 40.602535, postalCode: "15077" },
    { lng: -79.65608, lat: 40.711528, postalCode: "16229" },
    { lng: -75.63332, lat: 40.138889, postalCode: "19421" },
    { lng: -80.08452, lat: 42.132707, postalCode: "16507" },
    { lng: -76.57145, lat: 40.064862, postalCode: "17547" },
    { lng: -76.06929, lat: 40.839186, postalCode: "18220" },
    { lng: -75.74693, lat: 41.64181, postalCode: "18446" },
    { lng: -75.405987, lat: 39.934047, postalCode: "19028" },
    { lng: -76.74785, lat: 39.780323, postalCode: "17327" },
    { lng: -76.18303, lat: 41.797851, postalCode: "18845" },
    { lng: -80.26376, lat: 39.743424, postalCode: "15316" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15278" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19183" },
    { lng: -76.54562, lat: 40.643634, postalCode: "17983" },
    { lng: -75.23826, lat: 41.404263, postalCode: "18451" },
    { lng: -75.21158, lat: 40.169608, postalCode: "19002" },
    { lng: -75.11842, lat: 40.426391, postalCode: "18947" },
    { lng: -76.33944, lat: 40.750943, postalCode: "17936" },
    { lng: -75.53236, lat: 40.200347, postalCode: "19468" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19376" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18010" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18764" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18768" },
    { lng: -76.35153, lat: 40.098696, postalCode: "17520" },
    { lng: -76.68525, lat: 40.573636, postalCode: "17097" },
    { lng: -80.0448, lat: 40.372802, postalCode: "15228" },
    { lng: -75.22718, lat: 39.944162, postalCode: "19143" },
    { lng: -79.92758, lat: 40.921145, postalCode: "16039" },
    { lng: -79.52058, lat: 40.885328, postalCode: "16210" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19173" },
    { lng: -75.96009, lat: 40.99303, postalCode: "18234" },
    { lng: -75.20349, lat: 41.800226, postalCode: "18417" },
    { lng: -79.13701, lat: 40.401076, postalCode: "15949" },
    { lng: -75.14386, lat: 40.184758, postalCode: "19044" },
    { lng: -80.43671, lat: 41.63471, postalCode: "16424" },
    { lng: -80.3299, lat: 40.695281, postalCode: "15009" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19357" },
    { lng: -79.25388, lat: 39.886916, postalCode: "15551" },
    { lng: -77.59404, lat: 41.84992, postalCode: "16943" },
    { lng: -79.451575, lat: 40.847829, postalCode: "16216" },
    { lng: -77.13731, lat: 41.973095, postalCode: "16929" },
    { lng: -77.00863, lat: 39.965131, postalCode: "17316" },
    { lng: -75.00379, lat: 40.214652, postalCode: "18954" },
    { lng: -75.27069, lat: 41.337195, postalCode: "18426" },
    { lng: -76.84924, lat: 41.096505, postalCode: "17777" },
    { lng: -79.35134, lat: 41.323424, postalCode: "16235" },
    { lng: -75.33971, lat: 41.606185, postalCode: "18456" },
    { lng: -79.439801, lat: 40.360223, postalCode: "15605" },
    { lng: -76.9287, lat: 40.310088, postalCode: "17093" },
    { lng: -79.03116, lat: 41.676904, postalCode: "16347" },
    { lng: -75.728759, lat: 40.186707, postalCode: "19470" },
    { lng: -80.12451, lat: 40.379549, postalCode: "15142" },
    { lng: -75.405987, lat: 39.934047, postalCode: "19340" },
    { lng: -75.547966, lat: 41.375602, postalCode: "18416" },
    { lng: -79.81017, lat: 40.380219, postalCode: "15137" },
    { lng: -79.97826, lat: 40.426211, postalCode: "15203" },
    { lng: -75.89076, lat: 41.274748, postalCode: "18704" },
    { lng: -75.58992, lat: 40.404892, postalCode: "19504" },
    { lng: -78.10266, lat: 40.196898, postalCode: "16674" },
    { lng: -79.31493, lat: 40.134658, postalCode: "15687" },
    { lng: -76.05963, lat: 40.988834, postalCode: "18219" },
    { lng: -78.94801, lat: 40.099003, postalCode: "15563" },
    { lng: -76.63449, lat: 40.985594, postalCode: "17821" },
    { lng: -75.29694, lat: 40.000861, postalCode: "19003" },
    { lng: -75.20779, lat: 41.525207, postalCode: "18473" },
    { lng: -79.20053, lat: 40.965989, postalCode: "15778" },
    { lng: -75.61626, lat: 40.675741, postalCode: "18078" },
    { lng: -79.98067, lat: 40.443819, postalCode: "15219" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18773" },
    { lng: -79.09236, lat: 40.134685, postalCode: "15544" },
    { lng: -78.69622, lat: 40.757085, postalCode: "16692" },
    { lng: -78.25489, lat: 40.160825, postalCode: "16672" },
    { lng: -78.13583, lat: 40.167018, postalCode: "16694" },
    { lng: -74.99319, lat: 40.354735, postalCode: "18938" },
    { lng: -78.91839, lat: 40.621037, postalCode: "15745" },
    { lng: -77.725013, lat: 40.225168, postalCode: "17217" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19197" },
    { lng: -77.77414, lat: 40.243335, postalCode: "17213" },
    { lng: -78.81978, lat: 40.228222, postalCode: "15963" },
    { lng: -78.43559, lat: 40.792825, postalCode: "16681" },
    { lng: -79.6864, lat: 41.378236, postalCode: "16346" },
    { lng: -76.41898, lat: 40.797899, postalCode: "17851" },
    { lng: -76.63313, lat: 41.875402, postalCode: "18817" },
    { lng: -75.1067, lat: 40.178076, postalCode: "19040" },
    { lng: -79.86439, lat: 40.404743, postalCode: "15104" },
    { lng: -79.48116, lat: 40.528929, postalCode: "15618" },
    { lng: -76.846449, lat: 40.30864, postalCode: "17140" },
    { lng: -79.06935, lat: 39.863543, postalCode: "15542" },
    { lng: -78.83563, lat: 40.402861, postalCode: "15942" },
    { lng: -77.86281, lat: 40.806956, postalCode: "16802" },
    { lng: -75.17406, lat: 39.952162, postalCode: "19103" },
    { lng: -76.80513, lat: 40.690647, postalCode: "17830" },
    { lng: -79.65604, lat: 40.3044, postalCode: "15611" },
    { lng: -79.85095, lat: 40.369969, postalCode: "15110" },
    { lng: -80.211335, lat: 39.870635, postalCode: "15354" },
    { lng: -75.26213, lat: 41.093813, postalCode: "18332" },
    { lng: -78.36456, lat: 40.003997, postalCode: "15537" },
    { lng: -75.565, lat: 40.030536, postalCode: "19345" },
    { lng: -77.023601, lat: 41.33275, postalCode: "17727" },
    { lng: -75.47022, lat: 40.602847, postalCode: "18101" },
    { lng: -75.82947, lat: 40.749289, postalCode: "18211" },
    { lng: -79.0772, lat: 40.371165, postalCode: "15944" },
    { lng: -76.78654, lat: 40.9921, postalCode: "17865" },
    { lng: -76.37737, lat: 40.818443, postalCode: "17888" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19483" },
    { lng: -79.22805, lat: 41.41229, postalCode: "16260" },
    { lng: -76.059, lat: 39.750999, postalCode: "19362" },
    { lng: -78.463679, lat: 40.717845, postalCode: "16644" },
    { lng: -76.85724, lat: 40.964676, postalCode: "17850" },
    { lng: -77.62702, lat: 39.771372, postalCode: "17272" },
    { lng: -75.95006, lat: 41.535917, postalCode: "18657" },
    { lng: -80.3069, lat: 39.961767, postalCode: "15353" },
    { lng: -78.48646, lat: 40.290914, postalCode: "16625" },
    { lng: -75.98932, lat: 41.030933, postalCode: "18222" },
    { lng: -75.22349, lat: 40.730182, postalCode: "18040" },
    { lng: -80.01226, lat: 40.127951, postalCode: "15314" },
    { lng: -79.77643, lat: 40.405768, postalCode: "15140" },
    { lng: -77.52681, lat: 40.325136, postalCode: "17006" },
    { lng: -77.19987, lat: 40.203458, postalCode: "17013" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18953" },
    { lng: -79.67366, lat: 41.141525, postalCode: "16036" },
    { lng: -78.07011, lat: 41.613244, postalCode: "16720" },
    { lng: -79.090158, lat: 40.096406, postalCode: "15561" },
    { lng: -79.52989, lat: 39.854144, postalCode: "15470" },
    { lng: -75.62213, lat: 40.496375, postalCode: "18011" },
    { lng: -79.38106, lat: 40.108585, postalCode: "15628" },
    { lng: -77.47038, lat: 39.736626, postalCode: "17214" },
    { lng: -77.81001, lat: 39.913537, postalCode: "17252" },
    { lng: -75.1216, lat: 40.145859, postalCode: "19090" },
    { lng: -75.10278, lat: 40.328645, postalCode: "19049" },
    { lng: -75.09773, lat: 40.920622, postalCode: "18351" },
    { lng: -77.966977, lat: 40.402523, postalCode: "16654" },
    { lng: -76.30865, lat: 41.417231, postalCode: "18628" },
    { lng: -77.372827, lat: 41.187424, postalCode: "17779" },
    { lng: -76.07007, lat: 41.402035, postalCode: "18636" },
    { lng: -75.37006, lat: 39.888913, postalCode: "19086" },
    { lng: -76.91202, lat: 40.884501, postalCode: "17889" },
    { lng: -77.24149, lat: 40.64686, postalCode: "17049" },
    { lng: -75.76279, lat: 41.348939, postalCode: "18642" },
    { lng: -75.13473, lat: 41.427945, postalCode: "18428" },
    { lng: -79.9409, lat: 42.169106, postalCode: "16421" },
    { lng: -79.83921, lat: 41.407366, postalCode: "16323" },
    { lng: -79.18023, lat: 40.793823, postalCode: "16246" },
    { lng: -75.150011, lat: 39.947321, postalCode: "19172" },
    { lng: -79.72904, lat: 40.287869, postalCode: "15678" },
    { lng: -79.9113, lat: 40.877664, postalCode: "16001" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15286" },
    { lng: -79.9102, lat: 39.804674, postalCode: "15460" },
    { lng: -80.44529, lat: 40.557889, postalCode: "15050" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16514" },
    { lng: -79.51228, lat: 40.747985, postalCode: "16226" },
    { lng: -76.29673, lat: 40.299051, postalCode: "17088" },
    { lng: -75.688005, lat: 40.098032, postalCode: "19480" },
    { lng: -78.40082, lat: 40.526319, postalCode: "16601" },
    { lng: -79.557, lat: 41.125693, postalCode: "16213" },
    { lng: -78.422403, lat: 40.989115, postalCode: "16850" },
    { lng: -76.687826, lat: 39.972985, postalCode: "17333" },
    { lng: -76.60502, lat: 40.63898, postalCode: "17968" },
    { lng: -75.301918, lat: 40.203291, postalCode: "19486" },
    { lng: -79.85848, lat: 40.565907, postalCode: "15051" },
    { lng: -78.56081, lat: 40.711195, postalCode: "16640" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19099" },
    { lng: -76.03218, lat: 40.85315, postalCode: "18245" },
    { lng: -79.97516, lat: 40.377869, postalCode: "15227" },
    { lng: -74.70553, lat: 41.368893, postalCode: "18336" },
    { lng: -75.14764, lat: 39.964012, postalCode: "19123" },
    { lng: -79.98315, lat: 39.760868, postalCode: "15315" },
    { lng: -75.91163, lat: 40.407243, postalCode: "19560" },
    { lng: -79.45161, lat: 40.90764, postalCode: "16259" },
    { lng: -76.08117, lat: 41.001956, postalCode: "18251" },
    { lng: -80.00537, lat: 40.010135, postalCode: "15333" },
    { lng: -76.87648, lat: 40.644085, postalCode: "17017" },
    { lng: -76.27575, lat: 40.032664, postalCode: "17602" },
    { lng: -76.05765, lat: 40.241588, postalCode: "19501" },
    { lng: -79.83277, lat: 40.459518, postalCode: "15235" },
    { lng: -79.992678, lat: 40.260141, postalCode: "15336" },
    { lng: -75.20772, lat: 40.07236, postalCode: "19118" },
    { lng: -77.15522, lat: 40.900777, postalCode: "17829" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19191" },
    { lng: -76.85248, lat: 39.759126, postalCode: "17329" },
    { lng: -76.16405, lat: 40.698814, postalCode: "17965" },
    { lng: -75.64726, lat: 41.42818, postalCode: "18509" },
    { lng: -80.04453, lat: 40.430822, postalCode: "15220" },
    { lng: -80.02524, lat: 41.79637, postalCode: "16403" },
    { lng: -80.177979, lat: 40.332173, postalCode: "15817" },
    { lng: -75.32938, lat: 40.656498, postalCode: "18020" },
    { lng: -76.6084, lat: 41.232059, postalCode: "17742" },
    { lng: -75.70629, lat: 41.527134, postalCode: "18471" },
    { lng: -75.92047, lat: 39.961094, postalCode: "19365" },
    { lng: -75.4, lat: 40.923539, postalCode: "18322" },
    { lng: -79.451575, lat: 40.847829, postalCode: "16215" },
    { lng: -75.50218, lat: 40.137922, postalCode: "19453" },
    { lng: -77.36866, lat: 40.582815, postalCode: "17059" },
    { lng: -75.46037, lat: 41.116737, postalCode: "18350" },
    { lng: -76.687826, lat: 39.972985, postalCode: "17358" },
    { lng: -74.87885, lat: 40.289939, postalCode: "18977" },
    { lng: -76.53769, lat: 40.220907, postalCode: "17041" },
    { lng: -78.94132, lat: 40.348973, postalCode: "15906" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18963" },
    { lng: -76.13678, lat: 40.711162, postalCode: "17930" },
    { lng: -75.44986, lat: 40.037958, postalCode: "19312" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16530" },
    { lng: -79.453751, lat: 41.202325, postalCode: "16375" },
    { lng: -75.59332, lat: 39.983108, postalCode: "19380" },
    { lng: -76.33997, lat: 40.818731, postalCode: "17920" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18690" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15281" },
    { lng: -75.62617, lat: 40.375925, postalCode: "19505" },
    { lng: -76.52368, lat: 40.710899, postalCode: "17964" },
    { lng: -79.68296, lat: 41.803172, postalCode: "16434" },
    { lng: -79.28315, lat: 40.541589, postalCode: "15752" },
    { lng: -79.56196, lat: 40.598412, postalCode: "15629" },
    { lng: -77.64581, lat: 40.929263, postalCode: "16856" },
    { lng: -79.04944, lat: 40.14376, postalCode: "15546" },
    { lng: -79.78908, lat: 41.727069, postalCode: "16404" },
    { lng: -75.454043, lat: 40.327782, postalCode: "18084" },
    { lng: -76.29687, lat: 41.918757, postalCode: "18837" },
    { lng: -78.76286, lat: 39.776675, postalCode: "15545" },
    { lng: -75.56515, lat: 40.517304, postalCode: "18062" },
    { lng: -79.635842, lat: 39.771324, postalCode: "15439" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19155" },
    { lng: -78.844105, lat: 39.729896, postalCode: "15564" },
    { lng: -75.62478, lat: 41.46213, postalCode: "18519" },
    { lng: -77.760172, lat: 40.972229, postalCode: "16804" },
    { lng: -75.55229, lat: 40.451369, postalCode: "18056" },
    { lng: -76.49011, lat: 40.037682, postalCode: "17512" },
    { lng: -79.42092, lat: 40.608969, postalCode: "15686" },
    { lng: -75.098468, lat: 40.39473, postalCode: "18926" },
    { lng: -79.35524, lat: 40.601789, postalCode: "15783" },
    { lng: -79.13567, lat: 41.34149, postalCode: "15828" },
    { lng: -79.49922, lat: 40.809467, postalCode: "16201" },
    { lng: -79.92758, lat: 40.921145, postalCode: "16018" },
    { lng: -76.13162, lat: 40.594185, postalCode: "17922" },
    { lng: -76.43823, lat: 40.277603, postalCode: "17083" },
    { lng: -80.45756, lat: 41.025132, postalCode: "16116" },
    { lng: -75.07404, lat: 40.000262, postalCode: "19137" },
    { lng: -75.83584, lat: 40.058565, postalCode: "19316" },
    { lng: -78.44934, lat: 40.419572, postalCode: "16635" },
    { lng: -76.204, lat: 40.156472, postalCode: "17501" },
    { lng: -76.18859, lat: 40.965379, postalCode: "18246" },
    { lng: -75.25335, lat: 40.740769, postalCode: "18085" },
    { lng: -76.5912, lat: 40.304156, postalCode: "17078" },
    { lng: -76.34643, lat: 40.204393, postalCode: "17521" },
    { lng: -79.1105, lat: 40.02847, postalCode: "15501" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18003" },
    { lng: -78.98782, lat: 40.208983, postalCode: "15937" },
    { lng: -75.58836, lat: 40.401044, postalCode: "19503" },
    { lng: -79.3328, lat: 40.657804, postalCode: "15774" },
    { lng: -76.747251, lat: 41.241656, postalCode: "17757" },
    { lng: -75.90569, lat: 41.004017, postalCode: "18221" },
    { lng: -75.5314, lat: 39.899315, postalCode: "19373" },
    { lng: -79.74272, lat: 40.134792, postalCode: "15448" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16544" },
    { lng: -75.36218, lat: 41.286968, postalCode: "18445" },
    { lng: -76.08921, lat: 40.327974, postalCode: "19565" },
    { lng: -75.61752, lat: 41.746871, postalCode: "18842" },
    { lng: -80.1656, lat: 40.347826, postalCode: "15031" },
    { lng: -78.33361, lat: 40.601566, postalCode: "16617" },
    { lng: -75.77942, lat: 41.317242, postalCode: "18640" },
    { lng: -79.96943, lat: 40.00666, postalCode: "15450" },
    { lng: -80.062136, lat: 41.562679, postalCode: "16355" },
    { lng: -80.03227, lat: 40.552768, postalCode: "15237" },
    { lng: -75.2557, lat: 40.087297, postalCode: "19444" },
    { lng: -79.57486, lat: 41.306317, postalCode: "16331" },
    { lng: -77.68536, lat: 41.404439, postalCode: "17760" },
    { lng: -80.07987, lat: 41.385019, postalCode: "16145" },
    { lng: -75.38831, lat: 40.625444, postalCode: "18018" },
    { lng: -75.405987, lat: 39.934047, postalCode: "19091" },
    { lng: -78.65448, lat: 41.943791, postalCode: "16701" },
    { lng: -75.96767, lat: 40.126611, postalCode: "17555" },
    { lng: -79.88057, lat: 40.630894, postalCode: "15006" },
    { lng: -78.37709, lat: 40.16478, postalCode: "16659" },
    { lng: -79.52164, lat: 40.786362, postalCode: "16238" },
    { lng: -78.60663, lat: 41.850657, postalCode: "16732" },
    { lng: -75.4326, lat: 39.864761, postalCode: "19014" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15250" },
    { lng: -78.16186, lat: 41.940288, postalCode: "16748" },
    { lng: -75.637626, lat: 41.401881, postalCode: "18501" },
    { lng: -75.96874, lat: 40.98227, postalCode: "18225" },
    { lng: -76.71328, lat: 39.952198, postalCode: "17403" },
    { lng: -78.74776, lat: 41.420778, postalCode: "15853" },
    { lng: -79.96303, lat: 40.533806, postalCode: "15116" },
    { lng: -75.98519, lat: 40.958434, postalCode: "18201" },
    { lng: -80.03566, lat: 40.400319, postalCode: "15216" },
    { lng: -77.213493, lat: 39.8948, postalCode: "17375" },
    { lng: -80.36087, lat: 40.379884, postalCode: "15053" },
    { lng: -76.96291, lat: 40.802232, postalCode: "17833" },
    { lng: -78.42256, lat: 41.811926, postalCode: "16730" },
    { lng: -80.25185, lat: 40.170663, postalCode: "15301" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19182" },
    { lng: -79.35901, lat: 40.319956, postalCode: "15661" },
    { lng: -76.16896, lat: 41.928722, postalCode: "18851" },
    { lng: -76.80388, lat: 41.759353, postalCode: "16947" },
    { lng: -75.358575, lat: 40.308116, postalCode: "18924" },
    { lng: -78.08561, lat: 40.721787, postalCode: "16877" },
    { lng: -75.419966, lat: 40.185071, postalCode: "19430" },
    { lng: -79.91911, lat: 40.082073, postalCode: "15423" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19351" },
    { lng: -77.661354, lat: 40.171667, postalCode: "17210" },
    { lng: -77.67187, lat: 41.958059, postalCode: "16927" },
    { lng: -75.29257, lat: 40.115959, postalCode: "19462" },
    { lng: -77.966977, lat: 40.402523, postalCode: "16660" },
    { lng: -79.0868, lat: 39.742872, postalCode: "15558" },
    { lng: -75.57864, lat: 40.53484, postalCode: "18060" },
    { lng: -76.76352, lat: 40.120806, postalCode: "17370" },
    { lng: -79.59265, lat: 40.172199, postalCode: "15688" },
    { lng: -77.81148, lat: 40.0724, postalCode: "17221" },
    { lng: -75.53221, lat: 40.042957, postalCode: "19355" },
    { lng: -75.748055, lat: 39.983153, postalCode: "19398" },
    { lng: -75.10278, lat: 40.328645, postalCode: "19059" },
    { lng: -75.8254, lat: 40.267165, postalCode: "19508" },
    { lng: -79.51475, lat: 40.463572, postalCode: "15684" },
    { lng: -75.208269, lat: 41.050764, postalCode: "18335" },
    { lng: -79.73005, lat: 41.057142, postalCode: "16022" },
    { lng: -76.33655, lat: 39.746811, postalCode: "17314" },
    { lng: -75.58203, lat: 41.963293, postalCode: "18827" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16550" },
    { lng: -76.847194, lat: 40.298988, postalCode: "17177" },
    { lng: -76.1749, lat: 39.767658, postalCode: "17563" },
    { lng: -79.88869, lat: 40.326919, postalCode: "15045" },
    { lng: -75.689965, lat: 41.350303, postalCode: "18502" },
    { lng: -75.306102, lat: 40.650883, postalCode: "18024" },
    { lng: -77.89067, lat: 40.298547, postalCode: "17260" },
    { lng: -79.0629, lat: 40.173782, postalCode: "15531" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19179" },
    { lng: -79.85946, lat: 40.258438, postalCode: "15037" },
    { lng: -77.98067, lat: 40.190713, postalCode: "17264" },
    { lng: -75.16047, lat: 40.149164, postalCode: "19025" },
    { lng: -78.81129, lat: 41.160521, postalCode: "15840" },
    { lng: -75.104872, lat: 40.540232, postalCode: "18672" },
    { lng: -75.85458, lat: 41.509364, postalCode: "18625" },
    { lng: -76.60763, lat: 40.160793, postalCode: "17022" },
    { lng: -76.07373, lat: 39.840243, postalCode: "17536" },
    { lng: -77.73883, lat: 40.597698, postalCode: "17004" },
    { lng: -77.618532, lat: 41.220635, postalCode: "17773" },
    { lng: -79.129445, lat: 40.640031, postalCode: "15751" },
    { lng: -77.431745, lat: 41.122741, postalCode: "17726" },
    { lng: -76.56991, lat: 40.129494, postalCode: "17570" },
    { lng: -76.06899, lat: 40.750666, postalCode: "17925" },
    { lng: -76.29946, lat: 40.164049, postalCode: "17543" },
    { lng: -80.2596, lat: 40.32826, postalCode: "15361" },
    { lng: -76.82241, lat: 40.845896, postalCode: "17876" },
    { lng: -78.80478, lat: 40.123213, postalCode: "15924" },
    { lng: -79.75423, lat: 41.028275, postalCode: "16050" },
    { lng: -75.8944, lat: 39.783675, postalCode: "19352" },
    { lng: -77.96449, lat: 40.627047, postalCode: "16669" },
    { lng: -80.32428, lat: 40.834903, postalCode: "16136" },
    { lng: -75.22104, lat: 40.038944, postalCode: "19128" },
    { lng: -76.6237, lat: 41.511462, postalCode: "18616" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16558" },
    { lng: -80.10014, lat: 40.178679, postalCode: "15330" },
    { lng: -75.27298, lat: 41.198247, postalCode: "18323" },
    { lng: -75.5417, lat: 41.722929, postalCode: "18470" },
    { lng: -78.877331, lat: 40.767854, postalCode: "15746" },
    { lng: -77.08455, lat: 40.770921, postalCode: "17861" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19404" },
    { lng: -75.19957, lat: 39.961612, postalCode: "19104" },
    { lng: -79.2235, lat: 41.035602, postalCode: "16240" },
    { lng: -76.4123, lat: 40.168294, postalCode: "17545" },
    { lng: -78.87784, lat: 40.969945, postalCode: "15715" },
    { lng: -76.76982, lat: 41.86401, postalCode: "16914" },
    { lng: -75.97705, lat: 40.792315, postalCode: "18252" },
    { lng: -75.34658, lat: 39.897562, postalCode: "19081" },
    { lng: -77.12137, lat: 41.218259, postalCode: "17759" },
    { lng: -76.09645, lat: 40.264733, postalCode: "17569" },
    { lng: -80.07999, lat: 39.741865, postalCode: "15349" },
    { lng: -79.83762, lat: 40.519518, postalCode: "15139" },
    { lng: -75.486, lat: 40.281036, postalCode: "19492" },
    { lng: -75.53852, lat: 40.431982, postalCode: "18070" },
    { lng: -76.60496, lat: 41.899294, postalCode: "18831" },
    { lng: -75.66187, lat: 41.439329, postalCode: "18508" },
    { lng: -76.88528, lat: 40.300738, postalCode: "17110" },
    { lng: -75.29156, lat: 39.866912, postalCode: "19029" },
    { lng: -77.15154, lat: 41.239046, postalCode: "17744" },
    { lng: -76.63116, lat: 40.956916, postalCode: "17868" },
    { lng: -76.03178, lat: 41.653604, postalCode: "18630" },
    { lng: -80.33625, lat: 40.417545, postalCode: "15019" },
    { lng: -76.19316, lat: 41.310649, postalCode: "18656" },
    { lng: -75.767583, lat: 41.551585, postalCode: "18440" },
    { lng: -78.410035, lat: 40.50177, postalCode: "16603" },
    { lng: -78.4013, lat: 40.800186, postalCode: "16671" },
    { lng: -79.33009, lat: 39.816608, postalCode: "15485" },
    { lng: -79.38538, lat: 40.061931, postalCode: "15462" },
    { lng: -75.43221, lat: 41.8914, postalCode: "18462" },
    { lng: -78.90589, lat: 40.017182, postalCode: "15560" },
    { lng: -77.356642, lat: 39.890605, postalCode: "17310" },
    { lng: -75.29639, lat: 39.922862, postalCode: "19018" },
    { lng: -79.66089, lat: 40.040653, postalCode: "15430" },
    { lng: -78.48998, lat: 40.914286, postalCode: "16863" },
    { lng: -78.87674, lat: 40.36344, postalCode: "15909" },
    { lng: -80.0137, lat: 40.818096, postalCode: "16027" },
    { lng: -77.57458, lat: 40.833229, postalCode: "16875" },
    { lng: -76.1964, lat: 40.038648, postalCode: "17576" },
    { lng: -75.25572, lat: 40.289372, postalCode: "18932" },
    { lng: -80.07289, lat: 40.436618, postalCode: "15205" },
    { lng: -76.58391, lat: 41.414864, postalCode: "17731" },
    { lng: -79.00063, lat: 40.467684, postalCode: "15929" },
    { lng: -75.21189, lat: 40.537142, postalCode: "18930" },
    { lng: -77.69775, lat: 39.857218, postalCode: "17235" },
    { lng: -79.19369, lat: 41.173044, postalCode: "15829" },
    { lng: -79.74278, lat: 40.482655, postalCode: "15239" },
    { lng: -75.88488, lat: 40.078045, postalCode: "19344" },
    { lng: -79.21224, lat: 40.721516, postalCode: "15732" },
    { lng: -77.674851, lat: 39.783391, postalCode: "17256" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15230" },
    { lng: -75.880146, lat: 41.272248, postalCode: "18711" },
    { lng: -75.966146, lat: 41.001683, postalCode: "18243" },
    { lng: -76.72887, lat: 39.962463, postalCode: "17401" },
    { lng: -79.72566, lat: 40.628522, postalCode: "15065" },
    { lng: -79.86378, lat: 39.863666, postalCode: "15454" },
    { lng: -75.68225, lat: 40.497369, postalCode: "19539" },
    { lng: -75.59136, lat: 40.811206, postalCode: "18012" },
    { lng: -80.2924, lat: 40.368154, postalCode: "15060" },
    { lng: -75.00683, lat: 41.419116, postalCode: "18425" },
    { lng: -78.752415, lat: 40.607635, postalCode: "15737" },
    { lng: -75.06115, lat: 40.129509, postalCode: "19006" },
    { lng: -77.90043, lat: 40.242927, postalCode: "17249" },
    { lng: -75.466949, lat: 39.883911, postalCode: "19017" },
    { lng: -78.84707, lat: 40.786561, postalCode: "15712" },
    { lng: -75.60486, lat: 41.47785, postalCode: "18447" },
    { lng: -78.81509, lat: 40.097937, postalCode: "15926" },
    { lng: -79.49522, lat: 40.521015, postalCode: "15680" },
    { lng: -76.19448, lat: 40.630557, postalCode: "17929" },
    { lng: -79.59137, lat: 40.923894, postalCode: "16218" },
    { lng: -77.64014, lat: 41.335148, postalCode: "17738" },
    { lng: -80.15554, lat: 40.561091, postalCode: "15143" },
    { lng: -79.129445, lat: 40.640031, postalCode: "15705" },
    { lng: -79.44777, lat: 41.81426, postalCode: "16416" },
    { lng: -79.80286, lat: 40.394268, postalCode: "15148" },
    { lng: -79.22882, lat: 40.247351, postalCode: "15658" },
    { lng: -75.18589, lat: 40.781918, postalCode: "18063" },
    { lng: -77.17437, lat: 40.879182, postalCode: "17845" },
    { lng: -75.36453, lat: 41.100236, postalCode: "18349" },
    { lng: -75.72689, lat: 40.653037, postalCode: "18066" },
    { lng: -80.0712, lat: 40.372669, postalCode: "15243" },
    { lng: -76.71206, lat: 39.882516, postalCode: "17407" },
    { lng: -79.46865, lat: 41.115411, postalCode: "16255" },
    { lng: -77.23385, lat: 40.574018, postalCode: "17094" },
    { lng: -75.12118, lat: 40.033944, postalCode: "19120" },
    { lng: -76.98359, lat: 39.891548, postalCode: "17301" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18921" },
    { lng: -77.65433, lat: 40.076657, postalCode: "17244" },
    { lng: -75.009401, lat: 40.268981, postalCode: "18943" },
    { lng: -78.79936, lat: 40.652164, postalCode: "15760" },
    { lng: -76.46516, lat: 41.5932, postalCode: "18833" },
    { lng: -79.06479, lat: 41.160579, postalCode: "15825" },
    { lng: -75.16829, lat: 40.10346, postalCode: "19038" },
    { lng: -79.75428, lat: 40.947245, postalCode: "16025" },
    { lng: -79.43272, lat: 39.926509, postalCode: "15464" },
    { lng: -77.92124, lat: 41.290924, postalCode: "17778" },
    { lng: -80.42534, lat: 40.665001, postalCode: "15052" },
    { lng: -78.15005, lat: 40.962566, postalCode: "16879" },
    { lng: -75.50111, lat: 41.759285, postalCode: "18430" },
    { lng: -77.42457, lat: 41.954314, postalCode: "16928" },
    { lng: -75.38625, lat: 40.412771, postalCode: "18970" },
    { lng: -79.06594, lat: 39.766617, postalCode: "15532" },
    { lng: -75.54909, lat: 41.493381, postalCode: "18403" },
    { lng: -74.82066, lat: 40.166859, postalCode: "19054" },
    { lng: -80.20022, lat: 40.292528, postalCode: "15350" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18933" },
    { lng: -75.34132, lat: 41.825884, postalCode: "18439" },
    { lng: -75.96359, lat: 40.914664, postalCode: "18254" },
    { lng: -75.91781, lat: 41.209869, postalCode: "18706" },
    { lng: -77.89936, lat: 40.802661, postalCode: "16803" },
    { lng: -78.62296, lat: 40.977104, postalCode: "16838" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18922" },
    { lng: -78.17565, lat: 40.205819, postalCode: "16634" },
    { lng: -79.037983, lat: 40.003476, postalCode: "15565" },
    { lng: -78.621286, lat: 41.304782, postalCode: "15831" },
    { lng: -74.85821, lat: 40.142059, postalCode: "19057" },
    { lng: -79.74226, lat: 40.571108, postalCode: "15068" },
    { lng: -76.26963, lat: 39.963866, postalCode: "17584" },
    { lng: -77.29933, lat: 40.333508, postalCode: "17040" },
    { lng: -74.97719, lat: 40.09146, postalCode: "19154" },
    { lng: -79.93793, lat: 39.809161, postalCode: "15338" },
    { lng: -75.91962, lat: 40.329992, postalCode: "19602" },
    { lng: -80.00429, lat: 41.106327, postalCode: "16035" },
    { lng: -77.66909, lat: 41.021843, postalCode: "16841" },
    { lng: -79.55397, lat: 41.230562, postalCode: "16232" },
    { lng: -80.33572, lat: 40.763899, postalCode: "15010" },
    { lng: -78.63816, lat: 40.63949, postalCode: "16668" },
    { lng: -77.30162, lat: 40.413024, postalCode: "17024" },
    { lng: -79.88277, lat: 40.154271, postalCode: "15062" },
    { lng: -76.74643, lat: 41.544194, postalCode: "17768" },
    { lng: -79.39475, lat: 40.028267, postalCode: "15446" },
    { lng: -80.01144, lat: 40.460669, postalCode: "15212" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15240" },
    { lng: -75.07901, lat: 40.500115, postalCode: "18920" },
    { lng: -75.364973, lat: 40.21735, postalCode: "19423" },
    { lng: -79.81247, lat: 39.791113, postalCode: "15478" },
    { lng: -78.422403, lat: 40.989115, postalCode: "16873" },
    { lng: -75.471156, lat: 40.693376, postalCode: "18068" },
    { lng: -75.4052, lat: 40.222343, postalCode: "19474" },
    { lng: -75.41265, lat: 39.98451, postalCode: "19073" },
    { lng: -75.02644, lat: 40.041111, postalCode: "19136" },
    { lng: -75.06283, lat: 40.282168, postalCode: "18925" },
    { lng: -76.211427, lat: 40.048398, postalCode: "17585" },
    { lng: -78.91485, lat: 40.325374, postalCode: "15901" },
    { lng: -78.81275, lat: 40.734783, postalCode: "15724" },
    { lng: -79.72573, lat: 40.148576, postalCode: "15479" },
    { lng: -76.87182, lat: 39.853966, postalCode: "17362" },
    { lng: -79.453751, lat: 41.202325, postalCode: "16225" },
    { lng: -75.10278, lat: 40.328645, postalCode: "18968" },
    { lng: -76.21615, lat: 40.859702, postalCode: "17967" },
    { lng: -80.47218, lat: 40.649081, postalCode: "15059" },
    { lng: -77.17959, lat: 40.409544, postalCode: "17068" },
    { lng: -77.99417, lat: 40.498403, postalCode: "16652" },
    { lng: -75.97099, lat: 40.335023, postalCode: "19610" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15267" },
    { lng: -75.63082, lat: 40.340392, postalCode: "19545" },
    { lng: -77.71978, lat: 41.969218, postalCode: "16937" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15259" },
    { lng: -75.11552, lat: 41.656499, postalCode: "18443" },
    { lng: -79.65268, lat: 40.839519, postalCode: "16262" },
    { lng: -75.6879, lat: 41.414979, postalCode: "18504" },
    { lng: -78.55176, lat: 40.948433, postalCode: "16833" },
    { lng: -80.024817, lat: 40.434436, postalCode: "15282" },
    { lng: -78.1104, lat: 41.033239, postalCode: "16834" },
    { lng: -75.058245, lat: 40.384588, postalCode: "18638" },
    { lng: -76.5021, lat: 41.849392, postalCode: "18850" },
    { lng: -80.35953, lat: 40.508376, postalCode: "15026" },
    { lng: -78.04399, lat: 41.177156, postalCode: "16871" },
    { lng: -78.13, lat: 40.211299, postalCode: "16621" },
    { lng: -78.20512, lat: 39.951222, postalCode: "15536" },
    { lng: -75.579884, lat: 40.57983, postalCode: "18195" },
    { lng: -78.68839, lat: 41.833762, postalCode: "16738" },
    { lng: -78.62996, lat: 40.506799, postalCode: "15940" },
    { lng: -77.13907, lat: 40.482257, postalCode: "17074" },
    { lng: -76.11175, lat: 40.0348, postalCode: "17529" },
    { lng: -76.23232, lat: 40.782361, postalCode: "17931" },
    { lng: -79.25965, lat: 40.56265, postalCode: "15713" },
    { lng: -75.25926, lat: 40.379029, postalCode: "18944" },
    { lng: -77.6849, lat: 40.828679, postalCode: "16828" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16413" },
    { lng: -79.77089, lat: 40.120876, postalCode: "15492" },
    { lng: -76.985, lat: 40.880509, postalCode: "17855" },
    { lng: -78.68278, lat: 41.497219, postalCode: "15845" },
    { lng: -79.79325, lat: 40.266358, postalCode: "15018" },
    { lng: -79.90126, lat: 39.74419, postalCode: "15474" },
    { lng: -76.21547, lat: 40.79872, postalCode: "17934" },
    { lng: -76.46847, lat: 40.24793, postalCode: "17064" },
    { lng: -75.31865, lat: 41.048423, postalCode: "18372" },
    { lng: -77.760172, lat: 40.972229, postalCode: "16805" },
    { lng: -78.41085, lat: 40.75212, postalCode: "16680" },
    { lng: -78.74591, lat: 40.498793, postalCode: "15931" },
    { lng: -77.488633, lat: 39.833095, postalCode: "17261" },
    { lng: -78.70159, lat: 39.904209, postalCode: "15534" },
    { lng: -76.36066, lat: 39.938255, postalCode: "17516" },
    { lng: -80.18745, lat: 40.322872, postalCode: "15321" },
    { lng: -79.75697, lat: 40.080727, postalCode: "15473" },
    { lng: -79.99436, lat: 40.250299, postalCode: "15332" },
    { lng: -76.69048, lat: 41.268721, postalCode: "17737" },
    { lng: -75.11787, lat: 40.001811, postalCode: "19019" },
    { lng: -80.32884, lat: 41.922138, postalCode: "16410" },
    { lng: -75.6632, lat: 40.338497, postalCode: "19512" },
    { lng: -77.38615, lat: 41.36958, postalCode: "17776" },
    { lng: -79.8618, lat: 39.879185, postalCode: "15458" },
    { lng: -75.30798, lat: 41.033755, postalCode: "18348" },
    { lng: -77.96941, lat: 40.722897, postalCode: "16865" },
    { lng: -80.38943, lat: 40.357926, postalCode: "15078" },
    { lng: -75.637626, lat: 41.401881, postalCode: "18514" },
    { lng: -75.755807, lat: 40.318615, postalCode: "19815" },
    { lng: -78.32112, lat: 41.018168, postalCode: "16881" },
    { lng: -75.65222, lat: 40.593153, postalCode: "18051" },
    { lng: -76.89807, lat: 40.247189, postalCode: "17043" },
    { lng: -75.66107, lat: 40.206057, postalCode: "19465" },
    { lng: -80.064915, lat: 42.182748, postalCode: "16515" },
    { lng: -75.35586, lat: 40.211896, postalCode: "19429" },
    { lng: -75.25776, lat: 40.857829, postalCode: "18072" },
    { lng: -80.18464, lat: 40.500665, postalCode: "15108" },
    { lng: -76.385727, lat: 40.277076, postalCode: "17085" },
    { lng: -79.88768, lat: 39.927312, postalCode: "15443" },
    { lng: -75.31632, lat: 40.746849, postalCode: "18064" },
    { lng: -76.43888, lat: 40.916126, postalCode: "17820" },
    { lng: -79.91314, lat: 40.85062, postalCode: "16045" },
    { lng: -76.880884, lat: 40.261516, postalCode: "17127" },
    { lng: -75.885303, lat: 40.4389, postalCode: "19612" },
    { lng: -75.31601, lat: 41.376953, postalCode: "18463" },
    { lng: -79.81957, lat: 39.979032, postalCode: "15422" },
    { lng: -71.16548, lat: 41.518038, postalCode: "2837" },
    { lng: -71.557732, lat: 41.682455, postalCode: "2887" },
    { lng: -71.774023, lat: 41.519922, postalCode: "2873" },
    { lng: -71.58358, lat: 41.171389, postalCode: "2807" },
    { lng: -71.26866, lat: 41.6842, postalCode: "2809" },
    { lng: -71.41306, lat: 41.8208, postalCode: "2903" },
    { lng: -71.397699, lat: 41.826737, postalCode: "2912" },
    { lng: -71.600848, lat: 41.982417, postalCode: "2826" },
    { lng: -71.457592, lat: 41.487831, postalCode: "2789" },
    { lng: -71.28658, lat: 41.511739, postalCode: "2842" },
    { lng: -71.70335, lat: 41.896002, postalCode: "2814" },
    { lng: -71.32031, lat: 41.746834, postalCode: "2806" },
    { lng: -71.50722, lat: 42.001731, postalCode: "2895" },
    { lng: -71.31262, lat: 41.488002, postalCode: "2840" },
    { lng: -71.563057, lat: 42.000248, postalCode: "2824" },
    { lng: -71.286834, lat: 41.707119, postalCode: "2872" },
    { lng: -71.3911, lat: 41.706814, postalCode: "2889" },
    { lng: -71.72021, lat: 41.785151, postalCode: "2825" },
    { lng: -71.41181, lat: 41.949099, postalCode: "2864" },
    { lng: -71.3718, lat: 41.520312, postalCode: "2835" },
    { lng: -71.57482, lat: 41.73865, postalCode: "2831" },
    { lng: -71.440352, lat: 41.841499, postalCode: "2918" },
    { lng: -71.39427, lat: 41.83635, postalCode: "2906" },
    { lng: -71.40431, lat: 41.7883, postalCode: "2905" },
    { lng: -71.546756, lat: 41.7312, postalCode: "2823" },
    { lng: -71.55188, lat: 41.988166, postalCode: "2896" },
    { lng: -71.42449, lat: 41.79855, postalCode: "2907" },
    { lng: -71.414451, lat: 41.82275, postalCode: "2901" },
    { lng: -71.66408, lat: 41.46603, postalCode: "2812" },
    { lng: -71.40982, lat: 41.74805, postalCode: "2888" },
    { lng: -71.576307, lat: 41.998398, postalCode: "2876" },
    { lng: -71.43672, lat: 41.776867, postalCode: "2910" },
    { lng: -71.36834, lat: 41.8124, postalCode: "2914" },
    { lng: -71.52689, lat: 41.481001, postalCode: "2881" },
    { lng: -71.60547, lat: 41.563937, postalCode: "2822" },
    { lng: -71.64969, lat: 41.976659, postalCode: "2830" },
    { lng: -71.44775, lat: 41.8191, postalCode: "2909" },
    { lng: -71.57462, lat: 41.69105, postalCode: "2816" },
    { lng: -71.35391, lat: 41.84325, postalCode: "2916" },
    { lng: -71.26099, lat: 41.603929, postalCode: "2871" },
    { lng: -71.5539, lat: 41.870799, postalCode: "2828" },
    { lng: -71.43023, lat: 41.905666, postalCode: "2865" },
    { lng: -71.67009, lat: 41.777861, postalCode: "2815" },
    { lng: -71.51646, lat: 41.894499, postalCode: "2917" },
    { lng: -71.72311, lat: 41.964896, postalCode: "2859" },
    { lng: -71.3926, lat: 41.889863, postalCode: "2863" },
    { lng: -71.45119, lat: 41.705478, postalCode: "2886" },
    { lng: -71.64003, lat: 41.948108, postalCode: "2839" },
    { lng: -71.72737, lat: 41.511414, postalCode: "2832" },
    { lng: -71.453674, lat: 41.817398, postalCode: "2029" },
    { lng: -71.454, lat: 41.589701, postalCode: "2852" },
    { lng: -71.46485, lat: 41.7715, postalCode: "2920" },
    { lng: -71.47503, lat: 41.968698, postalCode: "2838" },
    { lng: -71.529854, lat: 41.5289, postalCode: "2877" },
    { lng: -71.62145, lat: 41.448844, postalCode: "2836" },
    { lng: -71.35564, lat: 41.7695, postalCode: "2915" },
    { lng: -71.424851, lat: 41.81835, postalCode: "2902" },
    { lng: -71.46352, lat: 41.432452, postalCode: "2882" },
    { lng: -71.58465, lat: 41.50155, postalCode: "2892" },
    { lng: -71.64661, lat: 41.96186, postalCode: "2858" },
    { lng: -71.80387, lat: 41.354881, postalCode: "2891" },
    { lng: -71.46815, lat: 41.649733, postalCode: "2818" },
    { lng: -71.35583, lat: 41.881632, postalCode: "2861" },
    { lng: -71.462053, lat: 41.954098, postalCode: "2802" },
    { lng: -71.69556, lat: 41.444078, postalCode: "2894" },
    { lng: -71.63877, lat: 41.447642, postalCode: "2875" },
    { lng: -71.43802, lat: 41.839296, postalCode: "2908" },
    { lng: -71.6257, lat: 41.83902, postalCode: "2857" },
    { lng: -71.589357, lat: 41.879298, postalCode: "2829" },
    { lng: -71.77722, lat: 41.470822, postalCode: "2833" },
    { lng: -71.27049, lat: 41.726184, postalCode: "2885" },
    { lng: -71.49468, lat: 41.825132, postalCode: "2919" },
    { lng: -71.64393, lat: 41.375317, postalCode: "2883" },
    { lng: -71.74472, lat: 41.695419, postalCode: "2827" },
    { lng: -71.18724, lat: 41.629413, postalCode: "2878" },
    { lng: -71.66813, lat: 41.385256, postalCode: "2813" },
    { lng: -71.64393, lat: 41.375317, postalCode: "2880" },
    { lng: -71.47354, lat: 41.853699, postalCode: "2911" },
    { lng: -71.51857, lat: 41.70375, postalCode: "2893" },
    { lng: -71.67023, lat: 41.625874, postalCode: "2817" },
    { lng: -71.39007, lat: 41.872099, postalCode: "2860" },
    { lng: -71.50972, lat: 41.76435, postalCode: "2921" },
    { lng: -71.52322, lat: 41.423601, postalCode: "2879" },
    { lng: -71.43142, lat: 41.859199, postalCode: "2904" },
    { lng: -71.64393, lat: 41.375317, postalCode: "2854" },
    { lng: -71.369099, lat: 41.86125, postalCode: "2862" },
    { lng: -71.711713, lat: 41.830469, postalCode: "2805" },
    { lng: -71.74785, lat: 41.402009, postalCode: "2808" },
    { lng: -71.284066, lat: 41.530131, postalCode: "2801" },
    { lng: -71.78101, lat: 41.430068, postalCode: "2804" },
    { lng: -71.45692, lat: 41.515135, postalCode: "2874" },
    { lng: -71.299004, lat: 41.498977, postalCode: "2841" },
    { lng: -71.558518, lat: 41.871766, postalCode: "2940" },
    { lng: -71.68484, lat: 41.51495, postalCode: "2898" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29402" },
    { lng: -82.75838, lat: 34.766305, postalCode: "29667" },
    { lng: -81.02886, lat: 34.022921, postalCode: "29202" },
    { lng: -81.098735, lat: 34.373596, postalCode: "29176" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29416" },
    { lng: -81.234811, lat: 34.126535, postalCode: "29002" },
    { lng: -81.25047, lat: 33.991362, postalCode: "29072" },
    { lng: -80.65134, lat: 33.354403, postalCode: "29018" },
    { lng: -80.97461, lat: 32.493936, postalCode: "29936" },
    { lng: -81.09219, lat: 33.830405, postalCode: "29053" },
    { lng: -78.67824, lat: 33.823495, postalCode: "29582" },
    { lng: -79.80977, lat: 34.195705, postalCode: "29501" },
    { lng: -81.90924, lat: 34.660137, postalCode: "29335" },
    { lng: -79.76429, lat: 33.927936, postalCode: "29591" },
    { lng: -80.31682, lat: 32.542343, postalCode: "29438" },
    { lng: -82.95878, lat: 34.888111, postalCode: "29676" },
    { lng: -81.89705, lat: 33.498386, postalCode: "29822" },
    { lng: -81.35898, lat: 34.428673, postalCode: "29015" },
    { lng: -82.163078, lat: 34.178813, postalCode: "29647" },
    { lng: -80.35738, lat: 33.916418, postalCode: "29150" },
    { lng: -80.39935, lat: 34.769259, postalCode: "29728" },
    { lng: -82.76668, lat: 34.641851, postalCode: "29670" },
    { lng: -79.36142, lat: 33.939996, postalCode: "29546" },
    { lng: -81.111258, lat: 32.881971, postalCode: "29942" },
    { lng: -81.36031, lat: 33.226172, postalCode: "29812" },
    { lng: -78.9008, lat: 34.046247, postalCode: "29569" },
    { lng: -81.17208, lat: 32.67553, postalCode: "29921" },
    { lng: -79.6607, lat: 32.97251, postalCode: "29429" },
    { lng: -79.87045, lat: 34.492154, postalCode: "29593" },
    { lng: -80.22778, lat: 32.788784, postalCode: "29470" },
    { lng: -80.735245, lat: 32.443974, postalCode: "29903" },
    { lng: -79.53847, lat: 34.571839, postalCode: "29525" },
    { lng: -79.83177, lat: 34.743074, postalCode: "29596" },
    { lng: -80.09371, lat: 34.720306, postalCode: "29709" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29616" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29216" },
    { lng: -79.45893, lat: 34.340797, postalCode: "29565" },
    { lng: -82.74032, lat: 34.464572, postalCode: "29626" },
    { lng: -81.33069, lat: 33.010041, postalCode: "29810" },
    { lng: -80.208109, lat: 33.688373, postalCode: "29143" },
    { lng: -80.31958, lat: 33.03271, postalCode: "29472" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29636" },
    { lng: -81.08218, lat: 34.369441, postalCode: "29180" },
    { lng: -79.78991, lat: 33.174292, postalCode: "29430" },
    { lng: -80.07629, lat: 34.183978, postalCode: "29069" },
    { lng: -82.08325, lat: 34.323605, postalCode: "29384" },
    { lng: -80.17774, lat: 32.651864, postalCode: "29487" },
    { lng: -81.24215, lat: 32.866195, postalCode: "29923" },
    { lng: -79.1148, lat: 33.801049, postalCode: "29527" },
    { lng: -81.62497, lat: 35.03653, postalCode: "29340" },
    { lng: -79.904182, lat: 33.164201, postalCode: "29476" },
    { lng: -83.04391, lat: 34.898387, postalCode: "29686" },
    { lng: -82.09312, lat: 34.954729, postalCode: "29385" },
    { lng: -81.81743, lat: 33.530409, postalCode: "29851" },
    { lng: -80.26083, lat: 34.213582, postalCode: "29010" },
    { lng: -79.94482, lat: 34.115691, postalCode: "29161" },
    { lng: -81.61561, lat: 33.650812, postalCode: "29805" },
    { lng: -80.98653, lat: 34.200042, postalCode: "29016" },
    { lng: -81.96445, lat: 33.59107, postalCode: "29860" },
    { lng: -82.30074, lat: 33.911717, postalCode: "29535" },
    { lng: -80.84282, lat: 33.03378, postalCode: "29493" },
    { lng: -81.49201, lat: 33.080604, postalCode: "29836" },
    { lng: -80.03063, lat: 33.192514, postalCode: "29410" },
    { lng: -80.07363, lat: 32.981952, postalCode: "29423" },
    { lng: -82.19013, lat: 33.980222, postalCode: "29848" },
    { lng: -81.8214, lat: 33.337781, postalCode: "29831" },
    { lng: -81.21429, lat: 34.720597, postalCode: "29706" },
    { lng: -82.105503, lat: 35.111404, postalCode: "29305" },
    { lng: -79.47272, lat: 34.283207, postalCode: "29592" },
    { lng: -80.06061, lat: 32.897903, postalCode: "29404" },
    { lng: -81.28947, lat: 33.506999, postalCode: "29146" },
    { lng: -81.12334, lat: 33.445032, postalCode: "29113" },
    { lng: -78.74796, lat: 33.915705, postalCode: "29568" },
    { lng: -80.565343, lat: 32.424353, postalCode: "29901" },
    { lng: -78.919205, lat: 33.935814, postalCode: "29528" },
    { lng: -81.40335, lat: 35.041829, postalCode: "29743" },
    { lng: -78.79097, lat: 33.765136, postalCode: "29572" },
    { lng: -82.56215, lat: 34.095246, postalCode: "29628" },
    { lng: -82.433132, lat: 34.924876, postalCode: "29613" },
    { lng: -79.55308, lat: 34.671428, postalCode: "29570" },
    { lng: -79.40387, lat: 33.71239, postalCode: "29554" },
    { lng: -80.76005, lat: 34.729073, postalCode: "29720" },
    { lng: -80.13052, lat: 33.845399, postalCode: "29051" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29240" },
    { lng: -81.9308, lat: 34.925353, postalCode: "29306" },
    { lng: -81.00008, lat: 34.027955, postalCode: "29204" },
    { lng: -80.46458, lat: 32.745396, postalCode: "29452" },
    { lng: -80.0675, lat: 34.007854, postalCode: "29080" },
    { lng: -79.95409, lat: 32.73727, postalCode: "29412" },
    { lng: -82.24512, lat: 34.762693, postalCode: "29681" },
    { lng: -81.99252, lat: 34.957898, postalCode: "29320" },
    { lng: -83.088332, lat: 34.764076, postalCode: "29675" },
    { lng: -79.85551, lat: 33.575913, postalCode: "29590" },
    { lng: -82.4023, lat: 34.325575, postalCode: "29639" },
    { lng: -79.42049, lat: 34.525937, postalCode: "29567" },
    { lng: -82.14541, lat: 34.96589, postalCode: "29365" },
    { lng: -82.86993, lat: 34.527385, postalCode: "29689" },
    { lng: -82.23672, lat: 34.286915, postalCode: "29653" },
    { lng: -80.91937, lat: 32.934459, postalCode: "29929" },
    { lng: -81.00536, lat: 34.692002, postalCode: "29729" },
    { lng: -82.43224, lat: 35.018511, postalCode: "29690" },
    { lng: -79.88688, lat: 33.78144, postalCode: "29518" },
    { lng: -79.71722, lat: 34.201299, postalCode: "29506" },
    { lng: -82.30432, lat: 34.774657, postalCode: "29662" },
    { lng: -82.0483, lat: 34.758703, postalCode: "29388" },
    { lng: -82.51411, lat: 33.997908, postalCode: "29840" },
    { lng: -81.22185, lat: 33.047132, postalCode: "29846" },
    { lng: -80.05353, lat: 32.821238, postalCode: "29414" },
    { lng: -81.599891, lat: 33.53773, postalCode: "29861" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29606" },
    { lng: -81.2447, lat: 32.669734, postalCode: "29939" },
    { lng: -81.07847, lat: 32.277216, postalCode: "29927" },
    { lng: -81.59772, lat: 33.831118, postalCode: "29105" },
    { lng: -82.56459, lat: 34.789884, postalCode: "29642" },
    { lng: -81.84119, lat: 33.566349, postalCode: "29829" },
    { lng: -81.14318, lat: 33.321406, postalCode: "29042" },
    { lng: -81.10624, lat: 34.041646, postalCode: "29210" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29214" },
    { lng: -80.07822, lat: 33.418106, postalCode: "29468" },
    { lng: -81.97413, lat: 35.033903, postalCode: "29316" },
    { lng: -80.88513, lat: 33.548282, postalCode: "29118" },
    { lng: -79.19325, lat: 34.002167, postalCode: "29544" },
    { lng: -82.94599, lat: 34.736907, postalCode: "29672" },
    { lng: -79.99205, lat: 33.634675, postalCode: "29056" },
    { lng: -81.16602, lat: 32.928131, postalCode: "29911" },
    { lng: -81.68632, lat: 33.415481, postalCode: "29809" },
    { lng: -81.85264, lat: 33.51344, postalCode: "29834" },
    { lng: -81.87919, lat: 34.926075, postalCode: "29302" },
    { lng: -79.93219, lat: 33.9413, postalCode: "29114" },
    { lng: -82.07523, lat: 35.061431, postalCode: "29349" },
    { lng: -81.832999, lat: 35.001748, postalCode: "29329" },
    { lng: -83.27236, lat: 34.774914, postalCode: "29658" },
    { lng: -82.319983, lat: 33.83562, postalCode: "29899" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29218" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29224" },
    { lng: -80.09247, lat: 32.93064, postalCode: "29420" },
    { lng: -81.87043, lat: 34.188584, postalCode: "29037" },
    { lng: -80.36129, lat: 34.422793, postalCode: "29009" },
    { lng: -79.784721, lat: 34.200994, postalCode: "29502" },
    { lng: -79.91504, lat: 33.284887, postalCode: "29431" },
    { lng: -80.642097, lat: 32.447677, postalCode: "29907" },
    { lng: -81.25987, lat: 33.771358, postalCode: "29123" },
    { lng: -80.18599, lat: 33.034586, postalCode: "29483" },
    { lng: -79.693294, lat: 34.041582, postalCode: "29504" },
    { lng: -80.11319, lat: 34.296064, postalCode: "29079" },
    { lng: -80.08297, lat: 33.252941, postalCode: "29469" },
    { lng: -80.797486, lat: 33.442019, postalCode: "29117" },
    { lng: -79.75793, lat: 34.070033, postalCode: "29541" },
    { lng: -82.371519, lat: 34.837666, postalCode: "29603" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29336" },
    { lng: -80.226694, lat: 33.00234, postalCode: "29484" },
    { lng: -79.01692, lat: 33.850218, postalCode: "29526" },
    { lng: -80.689041, lat: 32.340119, postalCode: "29905" },
    { lng: -81.178712, lat: 34.992612, postalCode: "29733" },
    { lng: -81.599891, lat: 33.53773, postalCode: "29804" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29409" },
    { lng: -81.74846, lat: 34.900682, postalCode: "29372" },
    { lng: -82.23851, lat: 34.404535, postalCode: "29692" },
    { lng: -82.44234, lat: 34.840717, postalCode: "29611" },
    { lng: -80.45186, lat: 33.237382, postalCode: "29448" },
    { lng: -82.62509, lat: 34.523657, postalCode: "29621" },
    { lng: -80.4548, lat: 33.71469, postalCode: "29125" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29457" },
    { lng: -82.15474, lat: 34.169781, postalCode: "29646" },
    { lng: -81.32649, lat: 34.242848, postalCode: "29122" },
    { lng: -80.01039, lat: 33.164225, postalCode: "29461" },
    { lng: -79.011696, lat: 33.694492, postalCode: "29599" },
    { lng: -82.13111, lat: 34.601311, postalCode: "29645" },
    { lng: -80.32069, lat: 33.399511, postalCode: "29048" },
    { lng: -80.74325, lat: 32.226713, postalCode: "29926" },
    { lng: -81.85468, lat: 34.783908, postalCode: "29374" },
    { lng: -83.16336, lat: 34.855841, postalCode: "29664" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29465" },
    { lng: -78.999862, lat: 33.694757, postalCode: "29588" },
    { lng: -80.81601, lat: 33.37731, postalCode: "29133" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29413" },
    { lng: -81.60777, lat: 34.502577, postalCode: "29178" },
    { lng: -79.8846, lat: 32.91583, postalCode: "29492" },
    { lng: -81.04345, lat: 33.094711, postalCode: "29081" },
    { lng: -81.09763, lat: 33.629017, postalCode: "29112" },
    { lng: -81.8106, lat: 34.415818, postalCode: "29351" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29610" },
    { lng: -80.75144, lat: 32.159476, postalCode: "29928" },
    { lng: -81.465, lat: 33.931279, postalCode: "29070" },
    { lng: -81.87128, lat: 33.503438, postalCode: "29816" },
    { lng: -78.971795, lat: 33.785803, postalCode: "29597" },
    { lng: -81.24794, lat: 32.744565, postalCode: "29918" },
    { lng: -82.19732, lat: 34.681279, postalCode: "29644" },
    { lng: -80.32025, lat: 33.948928, postalCode: "29153" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29422" },
    { lng: -79.58188, lat: 34.645047, postalCode: "29594" },
    { lng: -80.42251, lat: 33.332666, postalCode: "29059" },
    { lng: -82.41245, lat: 34.189812, postalCode: "29620" },
    { lng: -82.76263, lat: 34.612144, postalCode: "29656" },
    { lng: -80.02347, lat: 32.991185, postalCode: "29445" },
    { lng: -79.74037, lat: 33.985431, postalCode: "29530" },
    { lng: -80.677001, lat: 32.552652, postalCode: "29914" },
    { lng: -81.30864, lat: 32.818844, postalCode: "29932" },
    { lng: -80.19733, lat: 34.721265, postalCode: "29741" },
    { lng: -80.05911, lat: 32.879529, postalCode: "29418" },
    { lng: -79.98218, lat: 32.856634, postalCode: "29405" },
    { lng: -81.178712, lat: 34.992612, postalCode: "29734" },
    { lng: -82.68861, lat: 34.779483, postalCode: "29657" },
    { lng: -81.03519, lat: 33.998454, postalCode: "29201" },
    { lng: -82.32238, lat: 34.935636, postalCode: "29687" },
    { lng: -79.4657, lat: 33.10594, postalCode: "29458" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29415" },
    { lng: -80.57602, lat: 33.187906, postalCode: "29477" },
    { lng: -81.61641, lat: 34.282877, postalCode: "29108" },
    { lng: -81.39136, lat: 34.870127, postalCode: "29742" },
    { lng: -78.96696, lat: 34.164187, postalCode: "29545" },
    { lng: -82.6455, lat: 34.478139, postalCode: "29624" },
    { lng: -81.13542, lat: 34.552119, postalCode: "29014" },
    { lng: -79.3594, lat: 34.028989, postalCode: "29519" },
    { lng: -80.21281, lat: 33.647611, postalCode: "29102" },
    { lng: -80.2136, lat: 34.000732, postalCode: "29104" },
    { lng: -79.87402, lat: 33.493553, postalCode: "29564" },
    { lng: -79.9355, lat: 32.779126, postalCode: "29401" },
    { lng: -81.71368, lat: 33.569885, postalCode: "29801" },
    { lng: -81.248345, lat: 33.925183, postalCode: "29228" },
    { lng: -80.132418, lat: 34.365898, postalCode: "29551" },
    { lng: -82.395594, lat: 34.800718, postalCode: "29602" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29319" },
    { lng: -81.86761, lat: 34.470115, postalCode: "29325" },
    { lng: -81.062623, lat: 34.107483, postalCode: "29230" },
    { lng: -80.81066, lat: 33.105834, postalCode: "29481" },
    { lng: -79.57018, lat: 33.983639, postalCode: "29583" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29220" },
    { lng: -82.99703, lat: 34.505005, postalCode: "29643" },
    { lng: -80.60138, lat: 34.443733, postalCode: "29175" },
    { lng: -80.70107, lat: 34.588329, postalCode: "29058" },
    { lng: -82.63812, lat: 34.299215, postalCode: "29655" },
    { lng: -81.66903, lat: 34.828217, postalCode: "29353" },
    { lng: -81.77579, lat: 34.029635, postalCode: "29138" },
    { lng: -82.39289, lat: 34.798035, postalCode: "29605" },
    { lng: -82.22117, lat: 34.937356, postalCode: "29651" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29292" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29227" },
    { lng: -82.141, lat: 35.109207, postalCode: "29322" },
    { lng: -81.55245, lat: 33.872503, postalCode: "29006" },
    { lng: -81.21854, lat: 33.917812, postalCode: "29073" },
    { lng: -80.687134, lat: 32.206894, postalCode: "29982" },
    { lng: -81.10998, lat: 32.868065, postalCode: "29924" },
    { lng: -81.32226, lat: 33.593634, postalCode: "29137" },
    { lng: -81.82785, lat: 33.523241, postalCode: "29828" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29386" },
    { lng: -83.06882, lat: 34.769994, postalCode: "29691" },
    { lng: -82.00328, lat: 35.136333, postalCode: "29338" },
    { lng: -80.01013, lat: 33.88759, postalCode: "29162" },
    { lng: -80.98409, lat: 33.372063, postalCode: "29038" },
    { lng: -79.36512, lat: 34.413907, postalCode: "29536" },
    { lng: -79.8097, lat: 33.046869, postalCode: "29450" },
    { lng: -82.71136, lat: 34.912476, postalCode: "29671" },
    { lng: -80.95448, lat: 33.040507, postalCode: "29082" },
    { lng: -82.78407, lat: 34.733515, postalCode: "29630" },
    { lng: -81.79179, lat: 33.824496, postalCode: "29832" },
    { lng: -80.67195, lat: 32.89989, postalCode: "29488" },
    { lng: -81.184627, lat: 32.929902, postalCode: "29913" },
    { lng: -81.12298, lat: 33.532767, postalCode: "29107" },
    { lng: -80.37434, lat: 33.899856, postalCode: "29151" },
    { lng: -81.46124, lat: 34.788053, postalCode: "29364" },
    { lng: -80.74802, lat: 33.839441, postalCode: "29052" },
    { lng: -80.81448, lat: 34.1699, postalCode: "29045" },
    { lng: -80.82739, lat: 32.586005, postalCode: "29941" },
    { lng: -81.856745, lat: 34.638315, postalCode: "29331" },
    { lng: -79.14985, lat: 33.985128, postalCode: "29511" },
    { lng: -82.835449, lat: 34.438087, postalCode: "29623" },
    { lng: -80.54405, lat: 32.939642, postalCode: "29474" },
    { lng: -80.83348, lat: 32.681058, postalCode: "29945" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29217" },
    { lng: -81.0125, lat: 34.914611, postalCode: "29730" },
    { lng: -81.530165, lat: 33.905722, postalCode: "29066" },
    { lng: -80.208109, lat: 33.688373, postalCode: "29041" },
    { lng: -81.3823, lat: 33.947764, postalCode: "29054" },
    { lng: -80.62279, lat: 33.086324, postalCode: "29447" },
    { lng: -80.92235, lat: 34.843469, postalCode: "29704" },
    { lng: -81.19686, lat: 34.120487, postalCode: "29063" },
    { lng: -81.06618, lat: 33.964753, postalCode: "29033" },
    { lng: -79.32638, lat: 33.38934, postalCode: "29440" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29219" },
    { lng: -80.631208, lat: 33.061376, postalCode: "29433" },
    { lng: -80.781721, lat: 34.699412, postalCode: "29721" },
    { lng: -81.30068, lat: 34.295632, postalCode: "29065" },
    { lng: -80.79563, lat: 33.675533, postalCode: "29135" },
    { lng: -81.40845, lat: 33.659078, postalCode: "29164" },
    { lng: -82.09514, lat: 34.935828, postalCode: "29377" },
    { lng: -80.12165, lat: 32.993571, postalCode: "29456" },
    { lng: -80.79844, lat: 34.450789, postalCode: "29074" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29612" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29226" },
    { lng: -81.87994, lat: 33.443562, postalCode: "29842" },
    { lng: -79.93701, lat: 32.783076, postalCode: "29424" },
    { lng: -80.94972, lat: 32.72765, postalCode: "29916" },
    { lng: -81.60496, lat: 34.714045, postalCode: "29379" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29215" },
    { lng: -80.48117, lat: 34.349725, postalCode: "29032" },
    { lng: -82.710126, lat: 34.847372, postalCode: "29633" },
    { lng: -81.880871, lat: 34.457522, postalCode: "29235" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29250" },
    { lng: -80.96024, lat: 34.037557, postalCode: "29206" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29290" },
    { lng: -80.50487, lat: 33.881986, postalCode: "29168" },
    { lng: -80.17627, lat: 32.983408, postalCode: "29485" },
    { lng: -81.863874, lat: 34.916625, postalCode: "29304" },
    { lng: -80.54447, lat: 34.094472, postalCode: "29128" },
    { lng: -81.81628, lat: 33.619604, postalCode: "29850" },
    { lng: -82.13322, lat: 34.915071, postalCode: "29334" },
    { lng: -80.56786, lat: 34.020978, postalCode: "29062" },
    { lng: -82.15614, lat: 34.225872, postalCode: "29649" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29698" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29147" },
    { lng: -80.19009, lat: 33.345833, postalCode: "29436" },
    { lng: -82.40578, lat: 34.848301, postalCode: "29601" },
    { lng: -81.30861, lat: 33.630125, postalCode: "29124" },
    { lng: -81.599891, lat: 33.53773, postalCode: "29808" },
    { lng: -80.50903, lat: 33.462378, postalCode: "29142" },
    { lng: -80.59142, lat: 34.278935, postalCode: "29020" },
    { lng: -82.39364, lat: 34.889217, postalCode: "29609" },
    { lng: -81.02492, lat: 34.066271, postalCode: "29203" },
    { lng: -82.83794, lat: 34.833113, postalCode: "29682" },
    { lng: -81.08256, lat: 33.902216, postalCode: "29172" },
    { lng: -80.88762, lat: 34.328826, postalCode: "29130" },
    { lng: -81.24035, lat: 32.593295, postalCode: "29922" },
    { lng: -82.62565, lat: 35.084282, postalCode: "29635" },
    { lng: -79.85899, lat: 32.813518, postalCode: "29464" },
    { lng: -80.43046, lat: 33.434712, postalCode: "29163" },
    { lng: -80.922338, lat: 34.096716, postalCode: "29211" },
    { lng: -82.4565, lat: 34.723173, postalCode: "29673" },
    { lng: -79.476859, lat: 34.488255, postalCode: "29573" },
    { lng: -80.80248, lat: 33.229581, postalCode: "29432" },
    { lng: -81.977324, lat: 35.044719, postalCode: "29346" },
    { lng: -81.990216, lat: 34.949241, postalCode: "29368" },
    { lng: -80.208361, lat: 33.989924, postalCode: "29103" },
    { lng: -79.76496, lat: 32.798138, postalCode: "29451" },
    { lng: -81.19429, lat: 35.109114, postalCode: "29710" },
    { lng: -80.94144, lat: 34.021442, postalCode: "29207" },
    { lng: -82.710126, lat: 34.847372, postalCode: "29632" },
    { lng: -81.18294, lat: 33.190068, postalCode: "29843" },
    { lng: -80.2506, lat: 34.461353, postalCode: "29101" },
    { lng: -81.69232, lat: 35.10425, postalCode: "29341" },
    { lng: -81.95036, lat: 33.819096, postalCode: "29824" },
    { lng: -81.906376, lat: 34.968472, postalCode: "29333" },
    { lng: -79.56773, lat: 33.653454, postalCode: "29580" },
    { lng: -79.432546, lat: 34.336439, postalCode: "29561" },
    { lng: -80.54807, lat: 34.545825, postalCode: "29067" },
    { lng: -79.7542, lat: 33.859815, postalCode: "29560" },
    { lng: -81.08956, lat: 33.990952, postalCode: "29169" },
    { lng: -81.178712, lat: 34.992612, postalCode: "29731" },
    { lng: -78.999561, lat: 33.738887, postalCode: "29578" },
    { lng: -82.90985, lat: 34.725935, postalCode: "29665" },
    { lng: -82.8484, lat: 34.95424, postalCode: "29685" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29260" },
    { lng: -82.362585, lat: 34.872423, postalCode: "29614" },
    { lng: -81.126913, lat: 32.788773, postalCode: "29933" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29225" },
    { lng: -81.70946, lat: 33.486049, postalCode: "29803" },
    { lng: -80.93286, lat: 34.084069, postalCode: "29223" },
    { lng: -81.98276, lat: 34.270774, postalCode: "29332" },
    { lng: -81.4496, lat: 34.603967, postalCode: "29031" },
    { lng: -81.70607, lat: 33.90529, postalCode: "29166" },
    { lng: -81.21176, lat: 35.005981, postalCode: "29745" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29390" },
    { lng: -80.43433, lat: 33.899155, postalCode: "29154" },
    { lng: -81.772738, lat: 34.853013, postalCode: "29378" },
    { lng: -80.87343, lat: 34.091966, postalCode: "29233" },
    { lng: -82.59365, lat: 34.849655, postalCode: "29640" },
    { lng: -79.59233, lat: 33.45053, postalCode: "29510" },
    { lng: -79.18651, lat: 34.345437, postalCode: "29563" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29608" },
    { lng: -80.661027, lat: 32.390605, postalCode: "29938" },
    { lng: -80.824603, lat: 33.49593, postalCode: "29116" },
    { lng: -81.652395, lat: 34.996314, postalCode: "29342" },
    { lng: -79.89135, lat: 34.388055, postalCode: "29540" },
    { lng: -79.91737, lat: 33.391941, postalCode: "29479" },
    { lng: -79.25775, lat: 34.194685, postalCode: "29574" },
    { lng: -81.22788, lat: 34.861633, postalCode: "29726" },
    { lng: -81.14142, lat: 33.943251, postalCode: "29170" },
    { lng: -80.161, lat: 34.107967, postalCode: "29046" },
    { lng: -82.21285, lat: 33.7802, postalCode: "29844" },
    { lng: -80.851836, lat: 34.973866, postalCode: "29744" },
    { lng: -79.31738, lat: 34.095976, postalCode: "29589" },
    { lng: -81.43049, lat: 34.963208, postalCode: "29717" },
    { lng: -81.9768, lat: 34.367183, postalCode: "29370" },
    { lng: -82.710126, lat: 34.847372, postalCode: "29634" },
    { lng: -80.26799, lat: 34.712457, postalCode: "29727" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29318" },
    { lng: -79.94711, lat: 32.786176, postalCode: "29425" },
    { lng: -81.95665, lat: 34.980636, postalCode: "29303" },
    { lng: -82.49425, lat: 35.031252, postalCode: "29683" },
    { lng: -82.30548, lat: 33.91509, postalCode: "29835" },
    { lng: -82.71615, lat: 34.532638, postalCode: "29625" },
    { lng: -82.71772, lat: 34.376689, postalCode: "29684" },
    { lng: -79.61472, lat: 33.235105, postalCode: "29453" },
    { lng: -81.178712, lat: 34.992612, postalCode: "29703" },
    { lng: -80.84535, lat: 33.920721, postalCode: "29061" },
    { lng: -80.67505, lat: 32.421594, postalCode: "29902" },
    { lng: -80.08256, lat: 34.386728, postalCode: "29550" },
    { lng: -81.74321, lat: 34.925281, postalCode: "29373" },
    { lng: -81.78674, lat: 34.338289, postalCode: "29355" },
    { lng: -80.24773, lat: 32.727638, postalCode: "29494" },
    { lng: -81.85729, lat: 34.982424, postalCode: "29307" },
    { lng: -78.89604, lat: 33.698101, postalCode: "29577" },
    { lng: -82.44077, lat: 34.646501, postalCode: "29669" },
    { lng: -81.94945, lat: 33.517435, postalCode: "29841" },
    { lng: -80.97792, lat: 34.795665, postalCode: "29712" },
    { lng: -80.99826, lat: 33.990555, postalCode: "29205" },
    { lng: -81.482658, lat: 33.291666, postalCode: "29813" },
    { lng: -82.304658, lat: 34.218582, postalCode: "29695" },
    { lng: -80.74845, lat: 32.445712, postalCode: "29906" },
    { lng: -80.90972, lat: 34.569908, postalCode: "29055" },
    { lng: -81.39016, lat: 34.194914, postalCode: "29075" },
    { lng: -81.098735, lat: 34.373596, postalCode: "29106" },
    { lng: -82.23492, lat: 34.074234, postalCode: "29819" },
    { lng: -81.4218, lat: 34.305501, postalCode: "29126" },
    { lng: -80.70157, lat: 33.911025, postalCode: "29044" },
    { lng: -81.10567, lat: 32.458459, postalCode: "29943" },
    { lng: -82.74935, lat: 34.589662, postalCode: "29677" },
    { lng: -81.81026, lat: 35.043505, postalCode: "29330" },
    { lng: -82.22045, lat: 35.15617, postalCode: "29356" },
    { lng: -82.63239, lat: 34.210243, postalCode: "29659" },
    { lng: -80.35877, lat: 34.631305, postalCode: "29718" },
    { lng: -82.38755, lat: 34.45451, postalCode: "29654" },
    { lng: -80.005, lat: 32.794841, postalCode: "29407" },
    { lng: -78.64282, lat: 33.873017, postalCode: "29566" },
    { lng: -80.989142, lat: 32.488929, postalCode: "29912" },
    { lng: -81.33822, lat: 34.128998, postalCode: "29036" },
    { lng: -81.59072, lat: 33.723519, postalCode: "29802" },
    { lng: -80.99427, lat: 35.040969, postalCode: "29708" },
    { lng: -81.65577, lat: 33.804368, postalCode: "29129" },
    { lng: -79.32815, lat: 34.483711, postalCode: "29547" },
    { lng: -80.58127, lat: 33.52803, postalCode: "29047" },
    { lng: -80.661027, lat: 32.390605, postalCode: "29904" },
    { lng: -82.23442, lat: 33.84674, postalCode: "29845" },
    { lng: -81.03203, lat: 33.272026, postalCode: "29003" },
    { lng: -80.87404, lat: 32.241315, postalCode: "29910" },
    { lng: -79.94813, lat: 32.799326, postalCode: "29403" },
    { lng: -79.1301, lat: 33.467343, postalCode: "29585" },
    { lng: -79.352291, lat: 34.425629, postalCode: "29542" },
    { lng: -80.90083, lat: 33.425547, postalCode: "29039" },
    { lng: -82.50551, lat: 34.622494, postalCode: "29697" },
    { lng: -79.27039, lat: 34.289399, postalCode: "29543" },
    { lng: -82.34099, lat: 34.825592, postalCode: "29607" },
    { lng: -81.88553, lat: 35.131971, postalCode: "29323" },
    { lng: -81.248345, lat: 33.925183, postalCode: "29071" },
    { lng: -82.11066, lat: 34.862432, postalCode: "29375" },
    { lng: -81.83271, lat: 34.99056, postalCode: "29324" },
    { lng: -82.28385, lat: 34.709869, postalCode: "29680" },
    { lng: -80.04196, lat: 33.795243, postalCode: "29111" },
    { lng: -80.06109, lat: 34.585217, postalCode: "29584" },
    { lng: -80.17278, lat: 33.76993, postalCode: "29001" },
    { lng: -79.79148, lat: 32.87602, postalCode: "29466" },
    { lng: -82.52726, lat: 35.048312, postalCode: "29661" },
    { lng: -81.248345, lat: 33.925183, postalCode: "29171" },
    { lng: -81.511, lat: 34.14854, postalCode: "29127" },
    { lng: -79.001441, lat: 33.595996, postalCode: "29756" },
    { lng: -82.168139, lat: 34.21241, postalCode: "29648" },
    { lng: -82.447, lat: 34.897829, postalCode: "29617" },
    { lng: -81.0509, lat: 34.969427, postalCode: "29732" },
    { lng: -78.98126, lat: 33.627763, postalCode: "29575" },
    { lng: -80.38489, lat: 32.790622, postalCode: "29426" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29222" },
    { lng: -80.70776, lat: 34.204467, postalCode: "29078" },
    { lng: -81.71187, lat: 34.715641, postalCode: "29321" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29391" },
    { lng: -81.00994, lat: 34.774347, postalCode: "29724" },
    { lng: -79.6437, lat: 34.467072, postalCode: "29516" },
    { lng: -81.12453, lat: 32.602042, postalCode: "29934" },
    { lng: -82.653067, lat: 34.813542, postalCode: "29641" },
    { lng: -81.49413, lat: 35.118338, postalCode: "29702" },
    { lng: -80.41593, lat: 33.144807, postalCode: "29437" },
    { lng: -81.42936, lat: 33.405948, postalCode: "29853" },
    { lng: -79.92315, lat: 34.68862, postalCode: "29520" },
    { lng: -82.00203, lat: 34.14606, postalCode: "29666" },
    { lng: -80.970766, lat: 34.005992, postalCode: "29221" },
    { lng: -79.74311, lat: 34.142119, postalCode: "29505" },
    { lng: -81.98682, lat: 34.940921, postalCode: "29301" },
    { lng: -79.85797, lat: 33.119898, postalCode: "29434" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29417" },
    { lng: -79.650627, lat: 34.062999, postalCode: "29503" },
    { lng: -80.47925, lat: 32.976399, postalCode: "29435" },
    { lng: -79.93224, lat: 32.662451, postalCode: "29439" },
    { lng: -82.25178, lat: 34.911469, postalCode: "29650" },
    { lng: -80.93844, lat: 33.968011, postalCode: "29209" },
    { lng: -80.702594, lat: 32.538432, postalCode: "29931" },
    { lng: -82.94557, lat: 34.660345, postalCode: "29678" },
    { lng: -80.44405, lat: 34.029407, postalCode: "29040" },
    { lng: -79.45494, lat: 33.830612, postalCode: "29555" },
    { lng: -79.85773, lat: 32.84885, postalCode: "29419" },
    { lng: -80.46534, lat: 33.972863, postalCode: "29152" },
    { lng: -82.33427, lat: 34.370703, postalCode: "29638" },
    { lng: -80.787899, lat: 32.156011, postalCode: "29915" },
    { lng: -81.17611, lat: 34.075611, postalCode: "29212" },
    { lng: -82.782868, lat: 34.491988, postalCode: "29622" },
    { lng: -83.11495, lat: 34.633763, postalCode: "29693" },
    { lng: -78.963155, lat: 33.621094, postalCode: "29587" },
    { lng: -81.75318, lat: 34.215881, postalCode: "29145" },
    { lng: -81.85192, lat: 33.695638, postalCode: "29847" },
    { lng: -82.82176, lat: 34.681255, postalCode: "29631" },
    { lng: -81.019913, lat: 33.993705, postalCode: "29208" },
    { lng: -80.90157, lat: 34.702249, postalCode: "29714" },
    { lng: -80.5503, lat: 32.371697, postalCode: "29920" },
    { lng: -82.37061, lat: 35.068914, postalCode: "29688" },
    { lng: -79.11348, lat: 34.19138, postalCode: "29581" },
    { lng: -82.31739, lat: 34.866801, postalCode: "29615" },
    { lng: -80.8564, lat: 33.487767, postalCode: "29115" },
    { lng: -80.81529, lat: 32.977614, postalCode: "29475" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29604" },
    { lng: -82.02271, lat: 34.503167, postalCode: "29360" },
    { lng: -79.165102, lat: 34.342221, postalCode: "29363" },
    { lng: -78.739531, lat: 33.798844, postalCode: "29549" },
    { lng: -81.96902, lat: 34.888237, postalCode: "29348" },
    { lng: -79.78693, lat: 33.671678, postalCode: "29556" },
    { lng: -80.33969, lat: 33.570595, postalCode: "29148" },
    { lng: -81.95444, lat: 34.856283, postalCode: "29376" },
    { lng: -78.97824, lat: 33.729439, postalCode: "29579" },
    { lng: -80.915532, lat: 32.312621, postalCode: "29909" },
    { lng: -78.919205, lat: 33.935814, postalCode: "29598" },
    { lng: -81.07563, lat: 33.734171, postalCode: "29160" },
    { lng: -82.13638, lat: 33.643924, postalCode: "29821" },
    { lng: -79.03226, lat: 33.568115, postalCode: "29576" },
    { lng: -80.56074, lat: 32.683083, postalCode: "29446" },
    { lng: -81.05236, lat: 32.822292, postalCode: "29944" },
    { lng: -81.24052, lat: 32.963048, postalCode: "29827" },
    { lng: -80.799698, lat: 32.21319, postalCode: "29925" },
    { lng: -79.83791, lat: 32.763456, postalCode: "29482" },
    { lng: -79.69151, lat: 34.657694, postalCode: "29512" },
    { lng: -81.51935, lat: 33.475916, postalCode: "29856" },
    { lng: -81.37854, lat: 33.380314, postalCode: "29826" },
    { lng: -80.658932, lat: 34.767269, postalCode: "29722" },
    { lng: -80.74987, lat: 32.566537, postalCode: "29940" },
    { lng: -81.27281, lat: 34.143934, postalCode: "29177" },
    { lng: -80.88783, lat: 34.139511, postalCode: "29229" },
    { lng: -81.599198, lat: 33.507335, postalCode: "29839" },
    { lng: -82.939668, lat: 34.607898, postalCode: "29679" },
    { lng: -80.08507, lat: 32.717109, postalCode: "29455" },
    { lng: -79.396, lat: 34.164094, postalCode: "29571" },
    { lng: -79.226361, lat: 33.403693, postalCode: "29442" },
    { lng: -80.91644, lat: 35.008416, postalCode: "29715" },
    { lng: -82.19251, lat: 33.733657, postalCode: "29838" },
    { lng: -81.12724, lat: 34.309957, postalCode: "29132" },
    { lng: -80.67158, lat: 33.572356, postalCode: "29030" },
    { lng: -81.28416, lat: 33.363785, postalCode: "29817" },
    { lng: -80.0228, lat: 32.918757, postalCode: "29406" },
    { lng: -80.969035, lat: 35.062815, postalCode: "29716" },
    { lng: -81.2209, lat: 33.092403, postalCode: "29849" },
    { lng: -79.8761, lat: 34.291269, postalCode: "29532" },
    { lng: -79.053838, lat: 33.55016, postalCode: "29875" },
    { lng: -82.49053, lat: 34.517231, postalCode: "29627" },
    { lng: -80.69009, lat: 32.382453, postalCode: "29935" },
    { lng: -83.02066, lat: 34.77272, postalCode: "29696" },
    { lng: -80.65637, lat: 33.198286, postalCode: "29471" },
    { lng: -82.02018, lat: 34.865445, postalCode: "29369" },
    { lng: -82.453779, lat: 34.849745, postalCode: "29652" },
    { lng: -80.26738, lat: 32.715745, postalCode: "29449" },
    { lng: -82.74704, lat: 36.218863, postalCode: "37616" },
    { lng: -82.17558, lat: 36.269157, postalCode: "37658" },
    { lng: -83.953554, lat: 35.9029, postalCode: "37930" },
    { lng: -84.58403, lat: 35.548316, postalCode: "37826" },
    { lng: -84.82836, lat: 35.114482, postalCode: "37323" },
    { lng: -84.86489, lat: 35.219532, postalCode: "37312" },
    { lng: -83.93271, lat: 35.955732, postalCode: "37916" },
    { lng: -83.05351, lat: 36.013728, postalCode: "37843" },
    { lng: -83.91964, lat: 35.964032, postalCode: "37902" },
    { lng: -85.21158, lat: 35.167987, postalCode: "37343" },
    { lng: -82.96804, lat: 36.413437, postalCode: "37857" },
    { lng: -83.750258, lat: 35.935134, postalCode: "37998" },
    { lng: -83.97413, lat: 35.973731, postalCode: "37921" },
    { lng: -84.27044, lat: 35.992691, postalCode: "37830" },
    { lng: -84.99288, lat: 35.357001, postalCode: "37308" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37999" },
    { lng: -83.7413, lat: 36.086398, postalCode: "37806" },
    { lng: -84.3859, lat: 35.008596, postalCode: "37317" },
    { lng: -82.96185, lat: 36.190417, postalCode: "37818" },
    { lng: -82.33344, lat: 36.430799, postalCode: "37686" },
    { lng: -82.85446, lat: 36.102325, postalCode: "37743" },
    { lng: -82.3386, lat: 36.331006, postalCode: "37601" },
    { lng: -84.23281, lat: 35.800329, postalCode: "37772" },
    { lng: -84.76927, lat: 35.275808, postalCode: "37310" },
    { lng: -84.53015, lat: 35.325991, postalCode: "37331" },
    { lng: -85.209082, lat: 35.221092, postalCode: "37424" },
    { lng: -85.2988, lat: 35.046173, postalCode: "37403" },
    { lng: -83.106554, lat: 35.947688, postalCode: "37822" },
    { lng: -84.36195, lat: 35.098017, postalCode: "37391" },
    { lng: -84.66792, lat: 36.133166, postalCode: "37770" },
    { lng: -82.501212, lat: 36.271729, postalCode: "37602" },
    { lng: -84.928921, lat: 35.61722, postalCode: "37395" },
    { lng: -84.88723, lat: 35.146362, postalCode: "37311" },
    { lng: -84.641623, lat: 35.441376, postalCode: "37371" },
    { lng: -83.03714, lat: 36.531811, postalCode: "37731" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37996" },
    { lng: -83.56389, lat: 36.089568, postalCode: "37820" },
    { lng: -82.051765, lat: 36.436637, postalCode: "37644" },
    { lng: -83.80705, lat: 36.029987, postalCode: "37924" },
    { lng: -85.23748, lat: 34.996674, postalCode: "37412" },
    { lng: -83.884804, lat: 36.032334, postalCode: "37901" },
    { lng: -84.42137, lat: 36.351978, postalCode: "37756" },
    { lng: -83.678621, lat: 36.466398, postalCode: "37773" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37928" },
    { lng: -85.01322, lat: 35.017404, postalCode: "37302" },
    { lng: -83.81392, lat: 36.557884, postalCode: "37851" },
    { lng: -83.94202, lat: 36.567165, postalCode: "37715" },
    { lng: -85.06005, lat: 35.087881, postalCode: "37363" },
    { lng: -83.90149, lat: 35.97125, postalCode: "37915" },
    { lng: -83.22818, lat: 36.521297, postalCode: "37869" },
    { lng: -83.22676, lat: 36.352009, postalCode: "37811" },
    { lng: -84.27728, lat: 35.345237, postalCode: "37385" },
    { lng: -84.42541, lat: 36.029022, postalCode: "37719" },
    { lng: -85.28673, lat: 35.000825, postalCode: "37407" },
    { lng: -83.30799, lat: 36.225565, postalCode: "37814" },
    { lng: -82.57249, lat: 36.583233, postalCode: "37665" },
    { lng: -84.861885, lat: 35.17272, postalCode: "37320" },
    { lng: -83.636479, lat: 36.566257, postalCode: "37707" },
    { lng: -83.89042, lat: 35.923517, postalCode: "37920" },
    { lng: -85.25014, lat: 35.065723, postalCode: "37406" },
    { lng: -83.65241, lat: 36.417235, postalCode: "37825" },
    { lng: -83.51516, lat: 36.243526, postalCode: "37861" },
    { lng: -83.75801, lat: 35.680116, postalCode: "37882" },
    { lng: -83.27474, lat: 36.186008, postalCode: "37813" },
    { lng: -82.22998, lat: 36.469605, postalCode: "37618" },
    { lng: -83.48694, lat: 35.860157, postalCode: "37876" },
    { lng: -85.31004, lat: 35.030907, postalCode: "37408" },
    { lng: -84.60898, lat: 36.327029, postalCode: "37732" },
    { lng: -82.383792, lat: 36.315795, postalCode: "37605" },
    { lng: -82.16869, lat: 36.561341, postalCode: "37620" },
    { lng: -83.68053, lat: 36.24404, postalCode: "37848" },
    { lng: -84.17277, lat: 36.326509, postalCode: "37757" },
    { lng: -82.26446, lat: 36.504158, postalCode: "37699" },
    { lng: -81.75509, lat: 36.580229, postalCode: "37680" },
    { lng: -83.32206, lat: 36.320262, postalCode: "37708" },
    { lng: -84.18206, lat: 36.547121, postalCode: "37819" },
    { lng: -82.26446, lat: 36.504158, postalCode: "37621" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37933" },
    { lng: -85.2333, lat: 35.023824, postalCode: "37411" },
    { lng: -83.97503, lat: 36.553851, postalCode: "37730" },
    { lng: -83.93452, lat: 35.834126, postalCode: "37853" },
    { lng: -83.13987, lat: 36.277627, postalCode: "37891" },
    { lng: -82.6744, lat: 36.21503, postalCode: "37641" },
    { lng: -83.24441, lat: 35.820011, postalCode: "37722" },
    { lng: -84.303121, lat: 35.246689, postalCode: "37314" },
    { lng: -83.05028, lat: 36.572764, postalCode: "37765" },
    { lng: -83.47156, lat: 35.733627, postalCode: "37738" },
    { lng: -84.31707, lat: 35.14864, postalCode: "37333" },
    { lng: -81.97775, lat: 36.335618, postalCode: "37640" },
    { lng: -84.82777, lat: 35.68801, postalCode: "37381" },
    { lng: -82.36984, lat: 36.302479, postalCode: "37614" },
    { lng: -83.933815, lat: 35.728283, postalCode: "37802" },
    { lng: -85.209082, lat: 35.221092, postalCode: "37384" },
    { lng: -84.03142, lat: 36.138832, postalCode: "37754" },
    { lng: -83.275211, lat: 36.213293, postalCode: "37815" },
    { lng: -84.67782, lat: 35.684788, postalCode: "37880" },
    { lng: -84.72951, lat: 34.995039, postalCode: "37316" },
    { lng: -82.18843, lat: 36.355251, postalCode: "37643" },
    { lng: -82.65855, lat: 36.560966, postalCode: "37645" },
    { lng: -84.61975, lat: 35.441378, postalCode: "37303" },
    { lng: -84.27781, lat: 36.432891, postalCode: "37847" },
    { lng: -83.67602, lat: 36.161001, postalCode: "37709" },
    { lng: -84.58812, lat: 35.245578, postalCode: "37325" },
    { lng: -83.93138, lat: 35.63373, postalCode: "37878" },
    { lng: -84.07017, lat: 36.190257, postalCode: "37828" },
    { lng: -84.00384, lat: 35.922881, postalCode: "37919" },
    { lng: -84.861885, lat: 35.17272, postalCode: "37364" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37927" },
    { lng: -85.206426, lat: 35.017818, postalCode: "37401" },
    { lng: -83.795492, lat: 35.922421, postalCode: "37929" },
    { lng: -85.2749, lat: 35.030474, postalCode: "37404" },
    { lng: -82.44593, lat: 36.405338, postalCode: "37615" },
    { lng: -82.55708, lat: 36.254547, postalCode: "37690" },
    { lng: -83.19321, lat: 36.248645, postalCode: "37860" },
    { lng: -84.05015, lat: 35.709071, postalCode: "37801" },
    { lng: -83.09599, lat: 36.188878, postalCode: "37810" },
    { lng: -84.195796, lat: 36.105971, postalCode: "37831" },
    { lng: -84.04192, lat: 36.044507, postalCode: "37849" },
    { lng: -83.01575, lat: 35.900308, postalCode: "37727" },
    { lng: -84.49446, lat: 35.191719, postalCode: "37369" },
    { lng: -85.31407, lat: 35.002342, postalCode: "37410" },
    { lng: -85.31199, lat: 35.046324, postalCode: "37402" },
    { lng: -85.34865, lat: 34.998375, postalCode: "37350" },
    { lng: -83.950384, lat: 35.972146, postalCode: "37951" },
    { lng: -83.48325, lat: 36.109404, postalCode: "37760" },
    { lng: -85.28466, lat: 35.116285, postalCode: "37415" },
    { lng: -85.09827, lat: 35.19154, postalCode: "37341" },
    { lng: -84.50562, lat: 35.839274, postalCode: "37763" },
    { lng: -83.10835, lat: 35.822918, postalCode: "37753" },
    { lng: -85.26415, lat: 35.104789, postalCode: "37351" },
    { lng: -82.4273, lat: 36.128301, postalCode: "37650" },
    { lng: -85.33092, lat: 35.001842, postalCode: "37409" },
    { lng: -85.15939, lat: 35.028574, postalCode: "37421" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37997" },
    { lng: -84.11925, lat: 35.760657, postalCode: "37737" },
    { lng: -85.209082, lat: 35.221092, postalCode: "37499" },
    { lng: -83.93785, lat: 36.113552, postalCode: "37938" },
    { lng: -84.44598, lat: 36.09122, postalCode: "37845" },
    { lng: -83.82637, lat: 36.355257, postalCode: "37866" },
    { lng: -83.20116, lat: 35.963276, postalCode: "37821" },
    { lng: -83.67934, lat: 36.04051, postalCode: "37871" },
    { lng: -82.49704, lat: 36.293805, postalCode: "37659" },
    { lng: -83.75046, lat: 36.20423, postalCode: "37779" },
    { lng: -83.5605, lat: 35.792313, postalCode: "37863" },
    { lng: -83.97962, lat: 36.008048, postalCode: "37912" },
    { lng: -83.61374, lat: 35.97555, postalCode: "37764" },
    { lng: -81.75553, lat: 36.367169, postalCode: "37691" },
    { lng: -83.98412, lat: 35.693358, postalCode: "37803" },
    { lng: -83.8546, lat: 36.45491, postalCode: "37870" },
    { lng: -84.98535, lat: 35.114036, postalCode: "37353" },
    { lng: -83.84981, lat: 35.988528, postalCode: "37914" },
    { lng: -84.1933, lat: 35.543355, postalCode: "37885" },
    { lng: -84.74359, lat: 35.302952, postalCode: "37309" },
    { lng: -85.209082, lat: 35.221092, postalCode: "37414" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37940" },
    { lng: -83.591129, lat: 36.449963, postalCode: "37824" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37990" },
    { lng: -83.29024, lat: 36.094622, postalCode: "37890" },
    { lng: -84.08164, lat: 36.388766, postalCode: "37766" },
    { lng: -84.17255, lat: 35.919695, postalCode: "37932" },
    { lng: -84.66549, lat: 36.262842, postalCode: "37872" },
    { lng: -85.209082, lat: 35.221092, postalCode: "37450" },
    { lng: -82.51162, lat: 36.522968, postalCode: "37664" },
    { lng: -84.73957, lat: 35.043475, postalCode: "37362" },
    { lng: -83.93591, lat: 35.781607, postalCode: "37804" },
    { lng: -84.38284, lat: 35.030319, postalCode: "37326" },
    { lng: -84.14984, lat: 36.221786, postalCode: "37769" },
    { lng: -84.63913, lat: 35.172376, postalCode: "37307" },
    { lng: -82.0975, lat: 36.192236, postalCode: "37687" },
    { lng: -82.73948, lat: 36.182289, postalCode: "37744" },
    { lng: -82.62095, lat: 36.246547, postalCode: "37681" },
    { lng: -83.05737, lat: 36.274715, postalCode: "37711" },
    { lng: -84.41935, lat: 36.563797, postalCode: "37892" },
    { lng: -84.70139, lat: 35.108559, postalCode: "37361" },
    { lng: -85.138046, lat: 35.345216, postalCode: "37304" },
    { lng: -84.94125, lat: 35.585467, postalCode: "37332" },
    { lng: -83.39791, lat: 35.996454, postalCode: "37725" },
    { lng: -83.556134, lat: 35.880902, postalCode: "37868" },
    { lng: -83.0211, lat: 36.162502, postalCode: "37809" },
    { lng: -84.70392, lat: 35.363968, postalCode: "37370" },
    { lng: -82.49765, lat: 36.468953, postalCode: "37663" },
    { lng: -84.12608, lat: 35.875925, postalCode: "37922" },
    { lng: -85.33332, lat: 35.159778, postalCode: "37377" },
    { lng: -83.67499, lat: 36.566416, postalCode: "37724" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37939" },
    { lng: -85.209082, lat: 35.221092, postalCode: "37422" },
    { lng: -84.517287, lat: 36.383425, postalCode: "37893" },
    { lng: -83.40946, lat: 36.154381, postalCode: "37877" },
    { lng: -84.29301, lat: 35.815524, postalCode: "37771" },
    { lng: -84.35738, lat: 35.506259, postalCode: "37354" },
    { lng: -84.22104, lat: 36.290972, postalCode: "37714" },
    { lng: -85.18152, lat: 35.089805, postalCode: "37416" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37995" },
    { lng: -83.647254, lat: 36.580283, postalCode: "37867" },
    { lng: -83.91859, lat: 35.991982, postalCode: "37917" },
    { lng: -83.82558, lat: 36.251265, postalCode: "37807" },
    { lng: -82.83526, lat: 36.51276, postalCode: "37873" },
    { lng: -82.37229, lat: 36.534729, postalCode: "37617" },
    { lng: -83.92511, lat: 36.046598, postalCode: "37918" },
    { lng: -82.278, lat: 36.376371, postalCode: "37694" },
    { lng: -84.01196, lat: 35.835374, postalCode: "37777" },
    { lng: -84.713666, lat: 36.351914, postalCode: "37733" },
    { lng: -82.3802, lat: 36.312633, postalCode: "37604" },
    { lng: -82.31214, lat: 36.20493, postalCode: "37692" },
    { lng: -84.01131, lat: 36.217896, postalCode: "37705" },
    { lng: -82.305359, lat: 36.296007, postalCode: "37682" },
    { lng: -84.45838, lat: 35.679462, postalCode: "37846" },
    { lng: -82.37338, lat: 36.309201, postalCode: "37684" },
    { lng: -85.10329, lat: 35.391643, postalCode: "37373" },
    { lng: -84.1817, lat: 35.66806, postalCode: "37742" },
    { lng: -84.93042, lat: 35.308786, postalCode: "37336" },
    { lng: -84.1182, lat: 35.976063, postalCode: "37931" },
    { lng: -84.838, lat: 36.186086, postalCode: "37726" },
    { lng: -82.55408, lat: 36.547133, postalCode: "37660" },
    { lng: -84.28968, lat: 36.15231, postalCode: "37710" },
    { lng: -84.80741, lat: 35.503723, postalCode: "37322" },
    { lng: -83.97956, lat: 35.784708, postalCode: "37701" },
    { lng: -84.02712, lat: 36.510252, postalCode: "37729" },
    { lng: -83.716729, lat: 31.138011, postalCode: "31788" },
    { lng: -84.51808, lat: 36.49787, postalCode: "37841" },
    { lng: -84.36685, lat: 36.045663, postalCode: "37840" },
    { lng: -84.33069, lat: 35.725969, postalCode: "37774" },
    { lng: -83.275211, lat: 36.213293, postalCode: "37816" },
    { lng: -85.01032, lat: 35.495894, postalCode: "37321" },
    { lng: -83.613849, lat: 35.854211, postalCode: "37864" },
    { lng: -83.8066, lat: 36.141925, postalCode: "37721" },
    { lng: -84.13698, lat: 36.578257, postalCode: "37762" },
    { lng: -84.60149, lat: 35.98291, postalCode: "37829" },
    { lng: -82.26446, lat: 36.504158, postalCode: "37669" },
    { lng: -81.91718, lat: 36.529827, postalCode: "37688" },
    { lng: -82.26446, lat: 36.504158, postalCode: "37625" },
    { lng: -85.16227, lat: 35.269694, postalCode: "37379" },
    { lng: -84.024627, lat: 35.906808, postalCode: "37913" },
    { lng: -84.47473, lat: 35.41142, postalCode: "37329" },
    { lng: -83.34113, lat: 36.407028, postalCode: "37881" },
    { lng: -84.14656, lat: 36.099283, postalCode: "37716" },
    { lng: -84.46613, lat: 35.598659, postalCode: "37874" },
    { lng: -83.74476, lat: 35.853932, postalCode: "37865" },
    { lng: -81.81906, lat: 36.451041, postalCode: "37683" },
    { lng: -83.59197, lat: 35.818653, postalCode: "37862" },
    { lng: -85.057359, lat: 35.047891, postalCode: "37315" },
    { lng: -84.6022, lat: 36.335832, postalCode: "37852" },
    { lng: -82.529816, lat: 36.530517, postalCode: "37662" },
    { lng: -84.167208, lat: 36.110028, postalCode: "37717" },
    { lng: -83.81988, lat: 35.734481, postalCode: "37886" },
    { lng: -84.70042, lat: 35.861763, postalCode: "37854" },
    { lng: -83.52357, lat: 36.465808, postalCode: "37879" },
    { lng: -82.81406, lat: 36.229928, postalCode: "37745" },
    { lng: -85.31903, lat: 35.081323, postalCode: "37405" },
    { lng: -84.07543, lat: 35.928997, postalCode: "37923" },
    { lng: -84.56863, lat: 36.091018, postalCode: "37887" },
    { lng: -83.61278, lat: 36.581713, postalCode: "37752" },
    { lng: -83.275211, lat: 36.213293, postalCode: "37778" },
    { lng: -82.56056, lat: 36.011662, postalCode: "37657" },
    { lng: -83.13505, lat: 36.081083, postalCode: "37713" },
    { lng: -84.53288, lat: 36.434757, postalCode: "37755" },
    { lng: -83.96218, lat: 35.990142, postalCode: "37950" },
    { lng: -82.71292, lat: 36.534489, postalCode: "37642" },
    { lng: -85.37276, lat: 35.031674, postalCode: "37419" },
    { lng: -83.60658, lat: 36.310299, postalCode: "37888" },
    { lng: -82.62705, lat: 36.4017, postalCode: "37656" },
    { lng: -84.53135, lat: 35.930801, postalCode: "37748" },
    { lng: -84.01554, lat: 35.947846, postalCode: "37909" },
    { lng: -76.445567, lat: 37.235892, postalCode: "23090" },
    { lng: -79.86351, lat: 37.802424, postalCode: "24457" },
    { lng: -78.55359, lat: 37.310203, postalCode: "23960" },
    { lng: -78.48125, lat: 38.944011, postalCode: "22644" },
    { lng: -76.2596, lat: 36.880297, postalCode: "23509" },
    { lng: -82.27942, lat: 37.297387, postalCode: "24607" },
    { lng: -80.92683, lat: 36.649548, postalCode: "24333" },
    { lng: -81.16554, lat: 36.626454, postalCode: "24348" },
    { lng: -78.50696, lat: 38.567459, postalCode: "22851" },
    { lng: -77.32339, lat: 37.543595, postalCode: "23075" },
    { lng: -78.945519, lat: 36.611317, postalCode: "24535" },
    { lng: -80.2167, lat: 37.040036, postalCode: "24072" },
    { lng: -77.60878, lat: 38.964054, postalCode: "20105" },
    { lng: -78.76019, lat: 36.864697, postalCode: "24534" },
    { lng: -77.233103, lat: 38.96451, postalCode: "22067" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23541" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23286" },
    { lng: -77.31072, lat: 36.688788, postalCode: "23844" },
    { lng: -77.02508, lat: 37.64375, postalCode: "23086" },
    { lng: -80.60492, lat: 36.801489, postalCode: "24325" },
    { lng: -81.89068, lat: 37.136584, postalCode: "24639" },
    { lng: -75.86913, lat: 37.273986, postalCode: "23482" },
    { lng: -78.63806, lat: 36.631531, postalCode: "24529" },
    { lng: -76.007162, lat: 37.268263, postalCode: "23810" },
    { lng: -77.288755, lat: 38.831813, postalCode: "20172" },
    { lng: -76.36571, lat: 37.3946, postalCode: "23021" },
    { lng: -76.38992, lat: 37.072658, postalCode: "23670" },
    { lng: -79.69241, lat: 37.024494, postalCode: "24176" },
    { lng: -82.02317, lat: 37.409883, postalCode: "24620" },
    { lng: -82.35244, lat: 37.008665, postalCode: "24272" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22217" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22246" },
    { lng: -77.07965, lat: 38.735114, postalCode: "22036" },
    { lng: -79.44072, lat: 38.443961, postalCode: "24433" },
    { lng: -77.92431, lat: 37.801783, postalCode: "23065" },
    { lng: -81.82771, lat: 36.773659, postalCode: "24327" },
    { lng: -77.39295, lat: 39.011909, postalCode: "20164" },
    { lng: -77.57365, lat: 38.69835, postalCode: "20181" },
    { lng: -77.070733, lat: 38.801342, postalCode: "22331" },
    { lng: -77.20821, lat: 38.803813, postalCode: "22151" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24040" },
    { lng: -79.45193, lat: 36.836822, postalCode: "24531" },
    { lng: -77.38701, lat: 39.044855, postalCode: "20165" },
    { lng: -76.532492, lat: 37.363201, postalCode: "23183" },
    { lng: -77.466897, lat: 38.721912, postalCode: "22135" },
    { lng: -78.05877, lat: 38.324175, postalCode: "22733" },
    { lng: -76.142407, lat: 36.843948, postalCode: "23468" },
    { lng: -77.431825, lat: 37.541106, postalCode: "23201" },
    { lng: -78.03061, lat: 38.638635, postalCode: "22746" },
    { lng: -77.8962, lat: 38.442247, postalCode: "22741" },
    { lng: -77.64239, lat: 39.261906, postalCode: "20180" },
    { lng: -77.466897, lat: 38.721912, postalCode: "20168" },
    { lng: -77.0593, lat: 38.774863, postalCode: "22307" },
    { lng: -76.43298, lat: 37.456363, postalCode: "23128" },
    { lng: -77.52632, lat: 37.661647, postalCode: "23060" },
    { lng: -79.79043, lat: 37.373117, postalCode: "24064" },
    { lng: -77.88467, lat: 36.576549, postalCode: "23857" },
    { lng: -82.1623, lat: 36.968491, postalCode: "24225" },
    { lng: -77.408163, lat: 38.171755, postalCode: "22446" },
    { lng: -77.71289, lat: 36.776189, postalCode: "23856" },
    { lng: -77.542957, lat: 38.508611, postalCode: "22545" },
    { lng: -77.87192, lat: 39.080229, postalCode: "20135" },
    { lng: -81.38993, lat: 37.28526, postalCode: "24606" },
    { lng: -77.43964, lat: 38.82571, postalCode: "20121" },
    { lng: -79.15354, lat: 37.996542, postalCode: "24440" },
    { lng: -77.392784, lat: 37.204765, postalCode: "23806" },
    { lng: -77.41971, lat: 36.928972, postalCode: "23882" },
    { lng: -78.28737, lat: 38.393351, postalCode: "22727" },
    { lng: -77.09005, lat: 38.815762, postalCode: "22334" },
    { lng: -76.30065, lat: 36.886447, postalCode: "23508" },
    { lng: -76.30136, lat: 37.357742, postalCode: "23163" },
    { lng: -77.432252, lat: 38.830912, postalCode: "22121" },
    { lng: -77.09611, lat: 38.069014, postalCode: "22436" },
    { lng: -76.63263, lat: 37.858952, postalCode: "22460" },
    { lng: -76.447551, lat: 37.598391, postalCode: "23031" },
    { lng: -77.98948, lat: 38.905106, postalCode: "22643" },
    { lng: -76.38316, lat: 37.005814, postalCode: "23661" },
    { lng: -78.47627, lat: 38.150083, postalCode: "22936" },
    { lng: -78.247806, lat: 39.004774, postalCode: "22649" },
    { lng: -77.17261, lat: 38.271077, postalCode: "22481" },
    { lng: -81.40497, lat: 36.870607, postalCode: "24311" },
    { lng: -76.518831, lat: 37.312159, postalCode: "23131" },
    { lng: -78.4531, lat: 38.823987, postalCode: "22652" },
    { lng: -77.09005, lat: 38.815762, postalCode: "22313" },
    { lng: -78.30975, lat: 39.345906, postalCode: "22625" },
    { lng: -77.489666, lat: 38.299613, postalCode: "22402" },
    { lng: -77.052336, lat: 38.850879, postalCode: "22242" },
    { lng: -78.5348, lat: 38.026342, postalCode: "22903" },
    { lng: -77.88379, lat: 38.296355, postalCode: "22542" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23285" },
    { lng: -75.502306, lat: 37.943726, postalCode: "23483" },
    { lng: -77.28288, lat: 36.828831, postalCode: "23897" },
    { lng: -77.229043, lat: 38.920165, postalCode: "22109" },
    { lng: -78.26353, lat: 37.959376, postalCode: "22974" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24002" },
    { lng: -75.86132, lat: 37.522269, postalCode: "23350" },
    { lng: -76.680504, lat: 38.118186, postalCode: "22524" },
    { lng: -77.48831, lat: 37.580346, postalCode: "23230" },
    { lng: -81.59154, lat: 36.59565, postalCode: "24292" },
    { lng: -77.32722, lat: 38.582319, postalCode: "22026" },
    { lng: -76.27126, lat: 36.805717, postalCode: "23324" },
    { lng: -79.81478, lat: 38.103122, postalCode: "24484" },
    { lng: -78.878493, lat: 38.058102, postalCode: "22880" },
    { lng: -81.67617, lat: 36.937195, postalCode: "24316" },
    { lng: -80.76311, lat: 36.580074, postalCode: "24351" },
    { lng: -80.395698, lat: 37.174227, postalCode: "24063" },
    { lng: -76.51554, lat: 37.732696, postalCode: "22503" },
    { lng: -77.03578, lat: 37.438569, postalCode: "23140" },
    { lng: -79.84752, lat: 37.805268, postalCode: "24474" },
    { lng: -79.322363, lat: 38.091705, postalCode: "24411" },
    { lng: -77.06664, lat: 37.545276, postalCode: "23124" },
    { lng: -76.29271, lat: 36.870197, postalCode: "23517" },
    { lng: -75.51107, lat: 37.892445, postalCode: "23303" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23519" },
    { lng: -76.55901, lat: 37.223152, postalCode: "23694" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22321" },
    { lng: -78.49082, lat: 37.529284, postalCode: "23936" },
    { lng: -81.50326, lat: 37.113004, postalCode: "24651" },
    { lng: -78.66417, lat: 38.652274, postalCode: "22844" },
    { lng: -76.59872, lat: 37.648536, postalCode: "23175" },
    { lng: -77.09512, lat: 38.885337, postalCode: "22201" },
    { lng: -76.44118, lat: 37.490365, postalCode: "23050" },
    { lng: -80.067062, lat: 37.288384, postalCode: "24157" },
    { lng: -77.75728, lat: 38.5593, postalCode: "22712" },
    { lng: -78.62102, lat: 36.780021, postalCode: "23964" },
    { lng: -78.11767, lat: 36.925375, postalCode: "23944" },
    { lng: -81.968712, lat: 36.761613, postalCode: "24203" },
    { lng: -77.09627, lat: 38.860247, postalCode: "22204" },
    { lng: -77.56349, lat: 37.055849, postalCode: "23841" },
    { lng: -77.4533, lat: 37.406204, postalCode: "23237" },
    { lng: -79.95681, lat: 36.70487, postalCode: "24089" },
    { lng: -83.34736, lat: 36.65364, postalCode: "24281" },
    { lng: -77.09005, lat: 38.815762, postalCode: "22336" },
    { lng: -81.6578, lat: 37.177549, postalCode: "24601" },
    { lng: -79.31787, lat: 36.971021, postalCode: "24557" },
    { lng: -76.746769, lat: 37.310451, postalCode: "23187" },
    { lng: -78.29215, lat: 36.930088, postalCode: "23952" },
    { lng: -78.07923, lat: 38.231526, postalCode: "22960" },
    { lng: -79.30501, lat: 37.486603, postalCode: "24536" },
    { lng: -78.23622, lat: 38.312548, postalCode: "22709" },
    { lng: -78.28937, lat: 38.303414, postalCode: "22738" },
    { lng: -77.05854, lat: 38.819863, postalCode: "22301" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22227" },
    { lng: -79.74911, lat: 37.068743, postalCode: "24184" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22037" },
    { lng: -82.28089, lat: 37.001752, postalCode: "24237" },
    { lng: -79.786151, lat: 37.555083, postalCode: "24050" },
    { lng: -76.239417, lat: 36.777015, postalCode: "23326" },
    { lng: -76.70563, lat: 37.825956, postalCode: "22548" },
    { lng: -77.56391, lat: 38.858613, postalCode: "20143" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22082" },
    { lng: -75.953608, lat: 37.271104, postalCode: "23429" },
    { lng: -79.933206, lat: 37.278439, postalCode: "24022" },
    { lng: -76.23848, lat: 36.814897, postalCode: "23325" },
    { lng: -77.0743, lat: 38.104829, postalCode: "22509" },
    { lng: -76.22905, lat: 36.687543, postalCode: "23322" },
    { lng: -77.40085, lat: 38.931479, postalCode: "20171" },
    { lng: -79.57766, lat: 38.197757, postalCode: "24487" },
    { lng: -78.267848, lat: 38.428509, postalCode: "22953" },
    { lng: -76.687745, lat: 36.898915, postalCode: "23431" },
    { lng: -78.057832, lat: 37.116094, postalCode: "23955" },
    { lng: -79.81681, lat: 37.671394, postalCode: "24085" },
    { lng: -77.31684, lat: 38.760747, postalCode: "22039" },
    { lng: -77.288755, lat: 38.831813, postalCode: "20195" },
    { lng: -77.30833, lat: 38.042346, postalCode: "22427" },
    { lng: -78.96433, lat: 37.343394, postalCode: "24538" },
    { lng: -76.27354, lat: 36.830936, postalCode: "23523" },
    { lng: -75.41634, lat: 37.999025, postalCode: "23356" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23551" },
    { lng: -78.56162, lat: 37.855103, postalCode: "22946" },
    { lng: -78.95712, lat: 37.969657, postalCode: "22952" },
    { lng: -77.531996, lat: 36.721471, postalCode: "23870" },
    { lng: -79.95049, lat: 37.341428, postalCode: "24019" },
    { lng: -77.0731, lat: 37.342306, postalCode: "23030" },
    { lng: -75.60347, lat: 37.790415, postalCode: "23412" },
    { lng: -79.28268, lat: 37.237928, postalCode: "24550" },
    { lng: -77.45264, lat: 37.772187, postalCode: "23162" },
    { lng: -76.93945, lat: 36.668255, postalCode: "23851" },
    { lng: -79.87046, lat: 37.512912, postalCode: "24090" },
    { lng: -76.79025, lat: 38.121793, postalCode: "22442" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23530" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23261" },
    { lng: -80.62751, lat: 37.210996, postalCode: "24132" },
    { lng: -76.41524, lat: 36.826239, postalCode: "23321" },
    { lng: -82.79538, lat: 36.70122, postalCode: "24244" },
    { lng: -80.57325, lat: 37.11979, postalCode: "24141" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23292" },
    { lng: -78.79447, lat: 37.644171, postalCode: "24581" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22160" },
    { lng: -77.688338, lat: 39.098479, postalCode: "20160" },
    { lng: -77.82379, lat: 37.712894, postalCode: "23102" },
    { lng: -76.38232, lat: 36.867248, postalCode: "23703" },
    { lng: -77.74358, lat: 37.169066, postalCode: "23850" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22096" },
    { lng: -79.21525, lat: 37.362109, postalCode: "24502" },
    { lng: -76.06139, lat: 36.762459, postalCode: "23456" },
    { lng: -79.42701, lat: 38.178513, postalCode: "24432" },
    { lng: -77.05555, lat: 37.837177, postalCode: "23148" },
    { lng: -78.90273, lat: 37.905649, postalCode: "22958" },
    { lng: -76.78066, lat: 37.571544, postalCode: "23110" },
    { lng: -78.20713, lat: 39.07814, postalCode: "22655" },
    { lng: -80.99526, lat: 36.809933, postalCode: "24350" },
    { lng: -77.1642, lat: 38.277837, postalCode: "22485" },
    { lng: -80.41897, lat: 37.011934, postalCode: "24149" },
    { lng: -77.80274, lat: 38.52916, postalCode: "22734" },
    { lng: -79.47658, lat: 37.794344, postalCode: "24450" },
    { lng: -77.70952, lat: 38.587503, postalCode: "22728" },
    { lng: -77.30657, lat: 38.678451, postalCode: "22192" },
    { lng: -78.0165, lat: 38.373645, postalCode: "22729" },
    { lng: -81.968712, lat: 36.761613, postalCode: "24209" },
    { lng: -81.2001, lat: 36.729236, postalCode: "24326" },
    { lng: -79.47217, lat: 37.010279, postalCode: "24139" },
    { lng: -76.38992, lat: 37.072658, postalCode: "23630" },
    { lng: -76.29373, lat: 37.056778, postalCode: "23664" },
    { lng: -79.64194, lat: 36.586545, postalCode: "24069" },
    { lng: -78.64175, lat: 37.715293, postalCode: "24562" },
    { lng: -80.19485, lat: 37.361962, postalCode: "24070" },
    { lng: -76.21796, lat: 36.916497, postalCode: "23518" },
    { lng: -77.74996, lat: 38.953454, postalCode: "20118" },
    { lng: -82.79025, lat: 36.953228, postalCode: "24285" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22159" },
    { lng: -82.027408, lat: 37.287992, postalCode: "24658" },
    { lng: -78.09647, lat: 37.90198, postalCode: "23084" },
    { lng: -77.052035, lat: 38.854522, postalCode: "22244" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22122" },
    { lng: -77.86809, lat: 38.007186, postalCode: "23117" },
    { lng: -80.7728, lat: 37.053297, postalCode: "24301" },
    { lng: -80.4332, lat: 36.580514, postalCode: "24076" },
    { lng: -82.12413, lat: 37.107474, postalCode: "24239" },
    { lng: -77.219354, lat: 38.807462, postalCode: "22161" },
    { lng: -79.11686, lat: 37.268024, postalCode: "24588" },
    { lng: -78.64088, lat: 37.935994, postalCode: "22959" },
    { lng: -80.08111, lat: 37.010772, postalCode: "24067" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23291" },
    { lng: -76.531577, lat: 37.285231, postalCode: "23814" },
    { lng: -76.78764, lat: 37.790946, postalCode: "22437" },
    { lng: -81.80607, lat: 37.098588, postalCode: "24641" },
    { lng: -76.68218, lat: 38.006793, postalCode: "22529" },
    { lng: -75.73237, lat: 37.549044, postalCode: "23423" },
    { lng: -78.2553, lat: 37.522085, postalCode: "23040" },
    { lng: -77.944868, lat: 37.345032, postalCode: "23105" },
    { lng: -77.460814, lat: 38.417273, postalCode: "22555" },
    { lng: -78.63263, lat: 37.074876, postalCode: "23923" },
    { lng: -76.03592, lat: 36.600219, postalCode: "23457" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22009" },
    { lng: -75.3678, lat: 37.934673, postalCode: "23336" },
    { lng: -79.66552, lat: 37.357587, postalCode: "24174" },
    { lng: -78.69461, lat: 38.772409, postalCode: "22842" },
    { lng: -77.288755, lat: 38.831813, postalCode: "20192" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24007" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22156" },
    { lng: -79.98256, lat: 37.290886, postalCode: "24017" },
    { lng: -78.46048, lat: 36.806422, postalCode: "23924" },
    { lng: -77.954397, lat: 36.80878, postalCode: "23873" },
    { lng: -80.80847, lat: 37.331818, postalCode: "24124" },
    { lng: -77.561266, lat: 37.389721, postalCode: "23297" },
    { lng: -77.85964, lat: 38.343691, postalCode: "22433" },
    { lng: -75.53554, lat: 37.870022, postalCode: "23302" },
    { lng: -79.73922, lat: 37.21556, postalCode: "24095" },
    { lng: -76.31316, lat: 37.475132, postalCode: "23076" },
    { lng: -77.64165, lat: 37.51338, postalCode: "23113" },
    { lng: -79.53046, lat: 37.107479, postalCode: "24104" },
    { lng: -81.65827, lat: 36.776534, postalCode: "24319" },
    { lng: -79.08939, lat: 37.897702, postalCode: "24464" },
    { lng: -78.61263, lat: 37.79922, postalCode: "22937" },
    { lng: -78.168643, lat: 39.167603, postalCode: "22604" },
    { lng: -79.84575, lat: 38.054111, postalCode: "24412" },
    { lng: -79.939905, lat: 37.269168, postalCode: "24043" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22047" },
    { lng: -77.06845, lat: 38.878215, postalCode: "22211" },
    { lng: -77.662072, lat: 39.133855, postalCode: "20159" },
    { lng: -78.61997, lat: 38.49696, postalCode: "22849" },
    { lng: -79.88027, lat: 36.980013, postalCode: "24151" },
    { lng: -76.27848, lat: 37.854233, postalCode: "22539" },
    { lng: -78.62592, lat: 37.164981, postalCode: "23934" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22223" },
    { lng: -76.082079, lat: 36.786753, postalCode: "23453" },
    { lng: -77.73717, lat: 38.735202, postalCode: "20187" },
    { lng: -77.460814, lat: 38.417273, postalCode: "22403" },
    { lng: -81.78967, lat: 37.214471, postalCode: "24622" },
    { lng: -81.84119, lat: 37.091572, postalCode: "24612" },
    { lng: -78.78547, lat: 36.759749, postalCode: "24589" },
    { lng: -78.15309, lat: 38.549388, postalCode: "22713" },
    { lng: -79.789799, lat: 37.310494, postalCode: "24178" },
    { lng: -77.54775, lat: 37.633316, postalCode: "23294" },
    { lng: -79.48004, lat: 37.997778, postalCode: "24439" },
    { lng: -75.84523, lat: 37.452186, postalCode: "23413" },
    { lng: -78.01408, lat: 36.554164, postalCode: "23845" },
    { lng: -82.05775, lat: 37.296213, postalCode: "24614" },
    { lng: -77.87243, lat: 38.918124, postalCode: "20140" },
    { lng: -79.161046, lat: 37.381692, postalCode: "24506" },
    { lng: -82.18087, lat: 36.606582, postalCode: "24201" },
    { lng: -76.58677, lat: 37.144943, postalCode: "23604" },
    { lng: -79.94572, lat: 36.734651, postalCode: "24168" },
    { lng: -78.36545, lat: 38.76592, postalCode: "22650" },
    { lng: -81.43411, lat: 36.684567, postalCode: "24378" },
    { lng: -78.72662, lat: 38.000289, postalCode: "22924" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24008" },
    { lng: -77.46101, lat: 37.505147, postalCode: "23224" },
    { lng: -77.374292, lat: 38.385704, postalCode: "22430" },
    { lng: -76.49899, lat: 36.906805, postalCode: "23433" },
    { lng: -79.33402, lat: 38.270442, postalCode: "24485" },
    { lng: -76.409946, lat: 37.713175, postalCode: "22528" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24034" },
    { lng: -76.49446, lat: 37.078196, postalCode: "23606" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23275" },
    { lng: -77.06418, lat: 38.836779, postalCode: "22305" },
    { lng: -77.10281, lat: 38.883358, postalCode: "22226" },
    { lng: -75.822769, lat: 37.528076, postalCode: "23351" },
    { lng: -77.888082, lat: 37.733844, postalCode: "23160" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22229" },
    { lng: -76.154882, lat: 36.843073, postalCode: "23642" },
    { lng: -77.74903, lat: 38.983902, postalCode: "20117" },
    { lng: -78.23285, lat: 36.987595, postalCode: "23974" },
    { lng: -76.783317, lat: 37.223482, postalCode: "23081" },
    { lng: -79.36209, lat: 38.085244, postalCode: "24430" },
    { lng: -77.61965, lat: 37.622465, postalCode: "23233" },
    { lng: -78.141239, lat: 38.853733, postalCode: "22623" },
    { lng: -77.43297, lat: 38.317636, postalCode: "22405" },
    { lng: -77.072679, lat: 38.803142, postalCode: "22332" },
    { lng: -76.601616, lat: 37.432335, postalCode: "23003" },
    { lng: -78.88153, lat: 38.426786, postalCode: "22801" },
    { lng: -77.31536, lat: 37.605641, postalCode: "23111" },
    { lng: -76.527425, lat: 37.294975, postalCode: "23472" },
    { lng: -78.99245, lat: 37.725074, postalCode: "22964" },
    { lng: -76.41057, lat: 37.293165, postalCode: "23001" },
    { lng: -79.63611, lat: 38.374628, postalCode: "24465" },
    { lng: -76.38992, lat: 37.072658, postalCode: "23631" },
    { lng: -77.29102, lat: 38.896361, postalCode: "22181" },
    { lng: -77.67295, lat: 38.630238, postalCode: "20138" },
    { lng: -75.72178, lat: 37.92358, postalCode: "23427" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22234" },
    { lng: -81.74825, lat: 36.893445, postalCode: "24370" },
    { lng: -76.31637, lat: 37.031474, postalCode: "23663" },
    { lng: -76.4609, lat: 37.533471, postalCode: "23071" },
    { lng: -77.49851, lat: 37.51918, postalCode: "23225" },
    { lng: -76.36167, lat: 37.383629, postalCode: "23056" },
    { lng: -77.96916, lat: 37.787745, postalCode: "23153" },
    { lng: -79.3981, lat: 37.361512, postalCode: "24556" },
    { lng: -76.72961, lat: 37.763552, postalCode: "22504" },
    { lng: -80.55227, lat: 37.427111, postalCode: "24094" },
    { lng: -81.84774, lat: 36.763059, postalCode: "24361" },
    { lng: -76.29784, lat: 37.50519, postalCode: "23064" },
    { lng: -75.791519, lat: 37.616183, postalCode: "23422" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24028" },
    { lng: -77.91125, lat: 36.87229, postalCode: "23821" },
    { lng: -80.79309, lat: 36.973028, postalCode: "24324" },
    { lng: -77.58854, lat: 36.584448, postalCode: "23879" },
    { lng: -78.80409, lat: 38.310311, postalCode: "24471" },
    { lng: -77.234454, lat: 38.873861, postalCode: "22081" },
    { lng: -78.93618, lat: 37.582488, postalCode: "24533" },
    { lng: -79.790895, lat: 37.799435, postalCode: "24444" },
    { lng: -78.855172, lat: 38.528361, postalCode: "22833" },
    { lng: -77.053599, lat: 38.861462, postalCode: "22222" },
    { lng: -77.18562, lat: 38.778243, postalCode: "22150" },
    { lng: -79.52839, lat: 37.339184, postalCode: "24523" },
    { lng: -77.47694, lat: 38.752269, postalCode: "20110" },
    { lng: -78.38899, lat: 37.673369, postalCode: "23004" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22218" },
    { lng: -79.31644, lat: 37.929347, postalCode: "24415" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23249" },
    { lng: -75.616754, lat: 37.916218, postalCode: "23399" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22225" },
    { lng: -76.36034, lat: 37.64424, postalCode: "22578" },
    { lng: -81.37541, lat: 36.790265, postalCode: "24375" },
    { lng: -81.49787, lat: 37.155621, postalCode: "24630" },
    { lng: -78.18321, lat: 38.414359, postalCode: "22715" },
    { lng: -77.743423, lat: 39.057952, postalCode: "20131" },
    { lng: -78.45621, lat: 38.665615, postalCode: "22835" },
    { lng: -82.42645, lat: 36.658518, postalCode: "24258" },
    { lng: -80.0715, lat: 36.901203, postalCode: "24088" },
    { lng: -82.41823, lat: 36.741128, postalCode: "24271" },
    { lng: -82.59473, lat: 36.759614, postalCode: "24250" },
    { lng: -78.89239, lat: 37.780882, postalCode: "22949" },
    { lng: -77.99591, lat: 36.732859, postalCode: "23920" },
    { lng: -82.78974, lat: 36.919476, postalCode: "24216" },
    { lng: -79.54418, lat: 38.322663, postalCode: "24458" },
    { lng: -78.92423, lat: 38.290722, postalCode: "24486" },
    { lng: -77.35726, lat: 38.557527, postalCode: "22172" },
    { lng: -78.4632, lat: 37.241732, postalCode: "23943" },
    { lng: -76.278531, lat: 36.7085, postalCode: "23328" },
    { lng: -79.94155, lat: 37.270637, postalCode: "24011" },
    { lng: -77.16615, lat: 38.71299, postalCode: "22060" },
    { lng: -76.28444, lat: 36.913147, postalCode: "23505" },
    { lng: -81.91973, lat: 37.077291, postalCode: "24649" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24038" },
    { lng: -81.3367, lat: 37.222548, postalCode: "24605" },
    { lng: -76.02862, lat: 36.838398, postalCode: "23454" },
    { lng: -77.64378, lat: 38.207275, postalCode: "22553" },
    { lng: -77.466897, lat: 38.721912, postalCode: "20156" },
    { lng: -77.26648, lat: 38.22172, postalCode: "22538" },
    { lng: -77.533263, lat: 38.968321, postalCode: "20107" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23514" },
    { lng: -75.47192, lat: 37.931216, postalCode: "23337" },
    { lng: -77.87487, lat: 38.997904, postalCode: "20184" },
    { lng: -79.92645, lat: 37.267137, postalCode: "24013" },
    { lng: -77.92817, lat: 36.628802, postalCode: "23893" },
    { lng: -77.355902, lat: 38.014536, postalCode: "22428" },
    { lng: -76.50694, lat: 37.593059, postalCode: "23092" },
    { lng: -83.48322, lat: 36.62928, postalCode: "24248" },
    { lng: -76.43651, lat: 37.019453, postalCode: "23605" },
    { lng: -83.05467, lat: 36.805965, postalCode: "24282" },
    { lng: -76.508402, lat: 37.418242, postalCode: "23155" },
    { lng: -79.01957, lat: 38.725738, postalCode: "22820" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22106" },
    { lng: -77.18781, lat: 38.864669, postalCode: "22042" },
    { lng: -77.97857, lat: 39.156378, postalCode: "22611" },
    { lng: -76.37543, lat: 37.514774, postalCode: "23035" },
    { lng: -77.416103, lat: 37.531296, postalCode: "23289" },
    { lng: -77.77111, lat: 37.698646, postalCode: "23129" },
    { lng: -77.702576, lat: 39.152187, postalCode: "20134" },
    { lng: -76.31369, lat: 36.830748, postalCode: "23704" },
    { lng: -75.53045, lat: 37.973042, postalCode: "23415" },
    { lng: -77.75907, lat: 38.889848, postalCode: "20198" },
    { lng: -78.01898, lat: 38.814086, postalCode: "22639" },
    { lng: -77.449952, lat: 38.996242, postalCode: "20103" },
    { lng: -78.30107, lat: 37.129269, postalCode: "23942" },
    { lng: -79.90595, lat: 37.427597, postalCode: "24175" },
    { lng: -78.485066, lat: 38.040101, postalCode: "22910" },
    { lng: -82.27792, lat: 36.870051, postalCode: "24224" },
    { lng: -77.879855, lat: 38.992978, postalCode: "20185" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24032" },
    { lng: -81.00535, lat: 36.721913, postalCode: "24330" },
    { lng: -80.689067, lat: 37.069206, postalCode: "24126" },
    { lng: -82.027408, lat: 37.287992, postalCode: "24647" },
    { lng: -77.288755, lat: 38.831813, postalCode: "20193" },
    { lng: -75.67108, lat: 37.713561, postalCode: "23301" },
    { lng: -81.09439, lat: 36.947446, postalCode: "24382" },
    { lng: -77.47077, lat: 38.301829, postalCode: "22401" },
    { lng: -79.865168, lat: 36.679571, postalCode: "24115" },
    { lng: -82.5666, lat: 36.617348, postalCode: "24290" },
    { lng: -78.3259, lat: 38.167465, postalCode: "22923" },
    { lng: -77.45739, lat: 37.549396, postalCode: "23220" },
    { lng: -82.44936, lat: 37.153509, postalCode: "24228" },
    { lng: -80.13542, lat: 36.625817, postalCode: "24082" },
    { lng: -77.9255, lat: 38.918435, postalCode: "20144" },
    { lng: -78.4011, lat: 38.980286, postalCode: "22626" },
    { lng: -75.877052, lat: 37.534336, postalCode: "23398" },
    { lng: -82.34369, lat: 37.147222, postalCode: "24226" },
    { lng: -79.062858, lat: 38.159275, postalCode: "24402" },
    { lng: -78.12176, lat: 36.567521, postalCode: "23919" },
    { lng: -76.509615, lat: 37.750261, postalCode: "22513" },
    { lng: -77.72798, lat: 39.146479, postalCode: "20132" },
    { lng: -77.09005, lat: 38.815762, postalCode: "22333" },
    { lng: -79.940655, lat: 37.268618, postalCode: "24045" },
    { lng: -78.475018, lat: 38.330132, postalCode: "22965" },
    { lng: -78.11902, lat: 37.653694, postalCode: "23027" },
    { lng: -77.48844, lat: 37.557626, postalCode: "23221" },
    { lng: -77.48896, lat: 37.621745, postalCode: "23228" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22199" },
    { lng: -79.75335, lat: 37.014685, postalCode: "24092" },
    { lng: -78.16767, lat: 38.725801, postalCode: "22747" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24035" },
    { lng: -83.15587, lat: 36.687716, postalCode: "24263" },
    { lng: -76.337715, lat: 37.020596, postalCode: "23668" },
    { lng: -76.28453, lat: 37.501358, postalCode: "23066" },
    { lng: -79.05412, lat: 37.142894, postalCode: "24554" },
    { lng: -77.44796, lat: 38.685117, postalCode: "20112" },
    { lng: -77.416103, lat: 37.531296, postalCode: "23058" },
    { lng: -79.76536, lat: 37.180713, postalCode: "24101" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22034" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22118" },
    { lng: -82.1645, lat: 37.371119, postalCode: "24603" },
    { lng: -79.70604, lat: 36.677075, postalCode: "24054" },
    { lng: -80.03589, lat: 36.590431, postalCode: "24165" },
    { lng: -78.12417, lat: 39.045019, postalCode: "22663" },
    { lng: -77.94907, lat: 39.008532, postalCode: "20130" },
    { lng: -78.97416, lat: 38.104457, postalCode: "22939" },
    { lng: -75.90262, lat: 37.479588, postalCode: "23354" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23293" },
    { lng: -76.09333, lat: 36.839209, postalCode: "23452" },
    { lng: -76.303417, lat: 36.935579, postalCode: "23511" },
    { lng: -75.86182, lat: 37.560093, postalCode: "23306" },
    { lng: -78.70088, lat: 38.704494, postalCode: "22847" },
    { lng: -77.5559, lat: 38.271028, postalCode: "22407" },
    { lng: -77.860127, lat: 38.853723, postalCode: "20116" },
    { lng: -82.49147, lat: 36.844539, postalCode: "24245" },
    { lng: -76.60916, lat: 37.5787, postalCode: "23149" },
    { lng: -79.53939, lat: 36.985629, postalCode: "24161" },
    { lng: -77.234428, lat: 38.871469, postalCode: "22116" },
    { lng: -75.94203, lat: 37.353286, postalCode: "23347" },
    { lng: -76.23909, lat: 36.888147, postalCode: "23513" },
    { lng: -76.82637, lat: 37.064244, postalCode: "23846" },
    { lng: -78.14009, lat: 37.758949, postalCode: "23038" },
    { lng: -77.11667, lat: 38.813013, postalCode: "22304" },
    { lng: -78.267848, lat: 38.428509, postalCode: "22721" },
    { lng: -77.21893, lat: 36.724498, postalCode: "23829" },
    { lng: -82.93593, lat: 36.851769, postalCode: "24265" },
    { lng: -77.12125, lat: 38.782013, postalCode: "22310" },
    { lng: -77.6675, lat: 37.066833, postalCode: "23840" },
    { lng: -77.44404, lat: 38.095772, postalCode: "22580" },
    { lng: -76.278531, lat: 36.7085, postalCode: "23327" },
    { lng: -77.98995, lat: 38.588409, postalCode: "22737" },
    { lng: -76.75725, lat: 37.957869, postalCode: "22572" },
    { lng: -78.466716, lat: 38.665568, postalCode: "22875" },
    { lng: -76.37304, lat: 37.422209, postalCode: "23025" },
    { lng: -76.30803, lat: 36.846562, postalCode: "23708" },
    { lng: -79.09364, lat: 37.461272, postalCode: "24572" },
    { lng: -76.96545, lat: 37.227291, postalCode: "23899" },
    { lng: -78.90667, lat: 38.358334, postalCode: "22841" },
    { lng: -79.178506, lat: 37.400944, postalCode: "24505" },
    { lng: -80.42127, lat: 37.232027, postalCode: "24060" },
    { lng: -78.56548, lat: 38.254105, postalCode: "22935" },
    { lng: -80.418396, lat: 37.154804, postalCode: "24068" },
    { lng: -77.645224, lat: 39.085309, postalCode: "20149" },
    { lng: -76.208521, lat: 36.895911, postalCode: "23501" },
    { lng: -76.31275, lat: 37.807268, postalCode: "22579" },
    { lng: -77.005, lat: 38.041706, postalCode: "22438" },
    { lng: -78.04612, lat: 37.995103, postalCode: "23093" },
    { lng: -75.926877, lat: 37.307411, postalCode: "23419" },
    { lng: -76.87751, lat: 37.826347, postalCode: "23115" },
    { lng: -83.0635, lat: 36.733931, postalCode: "24218" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22216" },
    { lng: -79.28647, lat: 36.595244, postalCode: "24586" },
    { lng: -77.645224, lat: 39.085309, postalCode: "20199" },
    { lng: -77.16396, lat: 38.894457, postalCode: "22213" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23520" },
    { lng: -77.647495, lat: 37.072361, postalCode: "23822" },
    { lng: -78.963004, lat: 38.103819, postalCode: "22393" },
    { lng: -76.70129, lat: 36.578692, postalCode: "23438" },
    { lng: -75.55559, lat: 37.848467, postalCode: "23407" },
    { lng: -79.38497, lat: 38.345202, postalCode: "24442" },
    { lng: -78.66558, lat: 37.264896, postalCode: "23958" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24024" },
    { lng: -77.225139, lat: 38.009438, postalCode: "22552" },
    { lng: -77.662559, lat: 38.184716, postalCode: "22412" },
    { lng: -80.02705, lat: 37.772399, postalCode: "24426" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22103" },
    { lng: -82.59599, lat: 37.126639, postalCode: "24274" },
    { lng: -77.19777, lat: 38.901311, postalCode: "22043" },
    { lng: -77.361633, lat: 38.855715, postalCode: "22035" },
    { lng: -78.41009, lat: 38.233717, postalCode: "22968" },
    { lng: -76.39294, lat: 37.551549, postalCode: "23070" },
    { lng: -77.1537, lat: 38.817362, postalCode: "22312" },
    { lng: -76.012628, lat: 36.795669, postalCode: "23467" },
    { lng: -76.508402, lat: 37.418242, postalCode: "23154" },
    { lng: -76.27028, lat: 36.859362, postalCode: "23504" },
    { lng: -78.66151, lat: 36.589704, postalCode: "24580" },
    { lng: -80.52791, lat: 36.708515, postalCode: "24352" },
    { lng: -76.40511, lat: 37.852316, postalCode: "22456" },
    { lng: -78.51586, lat: 38.881558, postalCode: "22664" },
    { lng: -77.17261, lat: 38.271077, postalCode: "22544" },
    { lng: -78.81694, lat: 37.197072, postalCode: "23963" },
    { lng: -77.43266, lat: 38.460395, postalCode: "22554" },
    { lng: -80.73601, lat: 37.291252, postalCode: "24134" },
    { lng: -79.141332, lat: 38.179715, postalCode: "24469" },
    { lng: -77.02404, lat: 36.822024, postalCode: "23878" },
    { lng: -77.33963, lat: 37.753904, postalCode: "23069" },
    { lng: -82.29759, lat: 37.213189, postalCode: "24256" },
    { lng: -75.816851, lat: 37.529341, postalCode: "23380" },
    { lng: -77.89777, lat: 38.630345, postalCode: "22724" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23272" },
    { lng: -76.29947, lat: 36.864047, postalCode: "23507" },
    { lng: -77.54676, lat: 38.387261, postalCode: "22406" },
    { lng: -76.81384, lat: 37.478184, postalCode: "23011" },
    { lng: -82.37613, lat: 37.103304, postalCode: "24269" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24033" },
    { lng: -77.34604, lat: 37.343005, postalCode: "23836" },
    { lng: -78.24197, lat: 38.452043, postalCode: "22722" },
    { lng: -81.17641, lat: 37.183901, postalCode: "24314" },
    { lng: -77.17553, lat: 37.790757, postalCode: "23009" },
    { lng: -77.2566, lat: 38.922478, postalCode: "22182" },
    { lng: -76.636722, lat: 37.950511, postalCode: "22472" },
    { lng: -77.110817, lat: 38.87972, postalCode: "22230" },
    { lng: -77.3462, lat: 38.959673, postalCode: "20190" },
    { lng: -78.36485, lat: 39.00127, postalCode: "22657" },
    { lng: -79.141332, lat: 38.179715, postalCode: "24476" },
    { lng: -77.44998, lat: 38.851221, postalCode: "20120" },
    { lng: -77.66186, lat: 39.137667, postalCode: "20158" },
    { lng: -80.406172, lat: 37.210663, postalCode: "24023" },
    { lng: -79.12597, lat: 37.400275, postalCode: "24504" },
    { lng: -78.77203, lat: 37.312972, postalCode: "23939" },
    { lng: -77.83918, lat: 37.127935, postalCode: "23894" },
    { lng: -79.913875, lat: 37.006912, postalCode: "24146" },
    { lng: -77.22196, lat: 38.938664, postalCode: "22102" },
    { lng: -77.39237, lat: 38.797424, postalCode: "20124" },
    { lng: -78.78207, lat: 36.592597, postalCode: "24598" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23270" },
    { lng: -79.109789, lat: 37.242503, postalCode: "24512" },
    { lng: -77.34658, lat: 38.977362, postalCode: "20194" },
    { lng: -78.91071, lat: 37.320709, postalCode: "24593" },
    { lng: -79.02459, lat: 37.840892, postalCode: "22976" },
    { lng: -77.38642, lat: 38.874879, postalCode: "22033" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24005" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23290" },
    { lng: -75.877143, lat: 37.424497, postalCode: "23408" },
    { lng: -78.33721, lat: 38.509556, postalCode: "22743" },
    { lng: -75.59681, lat: 37.952168, postalCode: "23488" },
    { lng: -77.17261, lat: 38.271077, postalCode: "22526" },
    { lng: -80.85658, lat: 37.377877, postalCode: "24093" },
    { lng: -80.80411, lat: 36.909335, postalCode: "24313" },
    { lng: -77.253913, lat: 36.945667, postalCode: "23884" },
    { lng: -76.4196, lat: 37.098317, postalCode: "23665" },
    { lng: -79.10979, lat: 38.064112, postalCode: "24463" },
    { lng: -82.58685, lat: 36.642027, postalCode: "24251" },
    { lng: -81.563406, lat: 37.13549, postalCode: "24640" },
    { lng: -76.34415, lat: 37.037946, postalCode: "23669" },
    { lng: -76.56901, lat: 36.99497, postalCode: "23304" },
    { lng: -77.17261, lat: 38.271077, postalCode: "22451" },
    { lng: -79.11629, lat: 38.337507, postalCode: "22843" },
    { lng: -76.3457, lat: 36.835215, postalCode: "23707" },
    { lng: -76.404783, lat: 37.28641, postalCode: "23107" },
    { lng: -79.406303, lat: 37.333936, postalCode: "24570" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22095" },
    { lng: -78.09992, lat: 39.230131, postalCode: "22656" },
    { lng: -76.49749, lat: 37.762848, postalCode: "22507" },
    { lng: -76.34327, lat: 37.550168, postalCode: "23043" },
    { lng: -77.40085, lat: 37.544746, postalCode: "23223" },
    { lng: -75.45296, lat: 37.95715, postalCode: "23395" },
    { lng: -76.89025, lat: 36.907991, postalCode: "23866" },
    { lng: -76.57178, lat: 37.193712, postalCode: "23603" },
    { lng: -77.14586, lat: 37.902954, postalCode: "23126" },
    { lng: -77.27888, lat: 38.788646, postalCode: "22015" },
    { lng: -76.76155, lat: 38.129822, postalCode: "22520" },
    { lng: -76.1426, lat: 36.889797, postalCode: "23455" },
    { lng: -78.448214, lat: 38.024474, postalCode: "22909" },
    { lng: -76.74342, lat: 37.14051, postalCode: "23883" },
    { lng: -76.71722, lat: 37.270994, postalCode: "23186" },
    { lng: -78.27559, lat: 37.284087, postalCode: "23966" },
    { lng: -77.42815, lat: 37.539694, postalCode: "23298" },
    { lng: -76.45948, lat: 37.053346, postalCode: "23601" },
    { lng: -75.969003, lat: 37.188217, postalCode: "23443" },
    { lng: -78.32925, lat: 38.025346, postalCode: "22947" },
    { lng: -77.0026, lat: 37.181764, postalCode: "23881" },
    { lng: -76.83018, lat: 37.559878, postalCode: "23181" },
    { lng: -76.026053, lat: 36.837598, postalCode: "23460" },
    { lng: -77.81269, lat: 36.722176, postalCode: "23868" },
    { lng: -79.865168, lat: 36.679571, postalCode: "24114" },
    { lng: -76.00098, lat: 36.864788, postalCode: "23451" },
    { lng: -79.35877, lat: 36.725028, postalCode: "24527" },
    { lng: -80.12562, lat: 37.146244, postalCode: "24059" },
    { lng: -79.28175, lat: 38.042173, postalCode: "24459" },
    { lng: -76.66735, lat: 37.748645, postalCode: "23079" },
    { lng: -76.60933, lat: 37.792761, postalCode: "22523" },
    { lng: -79.26279, lat: 36.726236, postalCode: "24566" },
    { lng: -79.178506, lat: 37.400944, postalCode: "24515" },
    { lng: -77.4363, lat: 38.889111, postalCode: "20151" },
    { lng: -79.35038, lat: 37.74078, postalCode: "24416" },
    { lng: -76.508402, lat: 37.418242, postalCode: "23017" },
    { lng: -76.15581, lat: 36.847388, postalCode: "23458" },
    { lng: -77.56121, lat: 36.68627, postalCode: "23847" },
    { lng: -80.562913, lat: 37.122583, postalCode: "24143" },
    { lng: -76.12036, lat: 36.844004, postalCode: "23450" },
    { lng: -81.89766, lat: 37.250363, postalCode: "24634" },
    { lng: -79.559157, lat: 38.385967, postalCode: "24468" },
    { lng: -81.0571, lat: 37.107412, postalCode: "24315" },
    { lng: -76.56435, lat: 37.996825, postalCode: "22435" },
    { lng: -77.051569, lat: 38.86045, postalCode: "22243" },
    { lng: -76.38089, lat: 37.131237, postalCode: "23662" },
    { lng: -77.645224, lat: 39.085309, postalCode: "20104" },
    { lng: -79.786151, lat: 37.555083, postalCode: "24438" },
    { lng: -81.53963, lat: 37.201022, postalCode: "24604" },
    { lng: -77.29359, lat: 37.287869, postalCode: "23860" },
    { lng: -76.54812, lat: 37.151315, postalCode: "23608" },
    { lng: -78.17287, lat: 36.733874, postalCode: "23970" },
    { lng: -79.1237, lat: 36.782331, postalCode: "24597" },
    { lng: -82.96464, lat: 36.627827, postalCode: "24221" },
    { lng: -77.46111, lat: 38.236023, postalCode: "22408" },
    { lng: -75.70044, lat: 37.711442, postalCode: "23441" },
    { lng: -80.67384, lat: 37.107273, postalCode: "24084" },
    { lng: -76.63179, lat: 37.710586, postalCode: "23180" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23269" },
    { lng: -77.80709, lat: 37.643406, postalCode: "23039" },
    { lng: -77.820293, lat: 38.765619, postalCode: "20188" },
    { lng: -77.12749, lat: 38.903697, postalCode: "22207" },
    { lng: -79.79002, lat: 37.797214, postalCode: "24448" },
    { lng: -78.618912, lat: 38.179859, postalCode: "22987" },
    { lng: -76.84517, lat: 36.740632, postalCode: "23315" },
    { lng: -78.248333, lat: 36.948129, postalCode: "23941" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22119" },
    { lng: -76.52959, lat: 37.414991, postalCode: "23061" },
    { lng: -78.48134, lat: 37.806508, postalCode: "24590" },
    { lng: -80.26895, lat: 36.806528, postalCode: "24185" },
    { lng: -76.27989, lat: 37.342721, postalCode: "23125" },
    { lng: -76.49661, lat: 37.257466, postalCode: "23062" },
    { lng: -79.99093, lat: 36.838473, postalCode: "24102" },
    { lng: -78.197, lat: 38.125214, postalCode: "22942" },
    { lng: -76.41038, lat: 37.66871, postalCode: "22480" },
    { lng: -75.66642, lat: 37.635838, postalCode: "23404" },
    { lng: -77.42504, lat: 37.573595, postalCode: "23222" },
    { lng: -76.44242, lat: 37.680124, postalCode: "22576" },
    { lng: -79.02733, lat: 38.01473, postalCode: "24477" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22185" },
    { lng: -77.392784, lat: 37.204765, postalCode: "23804" },
    { lng: -76.29028, lat: 36.852547, postalCode: "23510" },
    { lng: -76.51278, lat: 37.227904, postalCode: "23690" },
    { lng: -78.73452, lat: 38.387721, postalCode: "22840" },
    { lng: -77.977432, lat: 38.741639, postalCode: "20128" },
    { lng: -77.56917, lat: 38.7475, postalCode: "20136" },
    { lng: -76.40711, lat: 37.050946, postalCode: "23666" },
    { lng: -78.93251, lat: 37.69474, postalCode: "22922" },
    { lng: -77.32787, lat: 38.846212, postalCode: "22030" },
    { lng: -77.18217, lat: 38.164219, postalCode: "22535" },
    { lng: -79.00334, lat: 37.790887, postalCode: "22954" },
    { lng: -81.549231, lat: 36.826371, postalCode: "24373" },
    { lng: -77.286287, lat: 36.909845, postalCode: "23891" },
    { lng: -82.00845, lat: 36.728639, postalCode: "24210" },
    { lng: -77.559298, lat: 38.015899, postalCode: "22501" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23274" },
    { lng: -78.59304, lat: 38.197583, postalCode: "22940" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22120" },
    { lng: -77.32402, lat: 38.88619, postalCode: "22124" },
    { lng: -77.034752, lat: 37.37956, postalCode: "23147" },
    { lng: -79.29041, lat: 37.871126, postalCode: "24435" },
    { lng: -77.27759, lat: 36.582188, postalCode: "23828" },
    { lng: -77.26167, lat: 38.859675, postalCode: "22031" },
    { lng: -77.288755, lat: 38.831813, postalCode: "20153" },
    { lng: -76.524805, lat: 37.195924, postalCode: "23609" },
    { lng: -77.43442, lat: 39.004276, postalCode: "20166" },
    { lng: -77.892964, lat: 37.553314, postalCode: "23101" },
    { lng: -77.56565, lat: 37.522514, postalCode: "23235" },
    { lng: -75.555142, lat: 37.923682, postalCode: "23396" },
    { lng: -81.99859, lat: 37.02264, postalCode: "24260" },
    { lng: -77.408359, lat: 37.520187, postalCode: "23232" },
    { lng: -76.70024, lat: 37.256301, postalCode: "23185" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23512" },
    { lng: -76.97442, lat: 38.22653, postalCode: "22443" },
    { lng: -77.888082, lat: 37.733844, postalCode: "23238" },
    { lng: -79.91425, lat: 36.720217, postalCode: "24078" },
    { lng: -78.18604, lat: 38.365699, postalCode: "22730" },
    { lng: -77.585708, lat: 38.700866, postalCode: "20182" },
    { lng: -77.23535, lat: 38.745164, postalCode: "22153" },
    { lng: -77.75239, lat: 36.912369, postalCode: "23889" },
    { lng: -77.64947, lat: 38.860551, postalCode: "20169" },
    { lng: -77.20122, lat: 38.695915, postalCode: "22079" },
    { lng: -77.47733, lat: 37.95969, postalCode: "22546" },
    { lng: -77.416103, lat: 37.531296, postalCode: "23242" },
    { lng: -78.81488, lat: 38.788499, postalCode: "22845" },
    { lng: -80.067062, lat: 37.288384, postalCode: "24156" },
    { lng: -77.442066, lat: 39.002125, postalCode: "20101" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23241" },
    { lng: -77.46836, lat: 37.637622, postalCode: "23280" },
    { lng: -77.11455, lat: 38.874979, postalCode: "22203" },
    { lng: -78.75894, lat: 38.039928, postalCode: "22943" },
    { lng: -82.94428, lat: 36.780481, postalCode: "24243" },
    { lng: -76.012628, lat: 36.795669, postalCode: "23466" },
    { lng: -76.73554, lat: 37.642543, postalCode: "23108" },
    { lng: -79.410983, lat: 36.592679, postalCode: "24543" },
    { lng: -77.288755, lat: 38.831813, postalCode: "20122" },
    { lng: -79.39906, lat: 37.912977, postalCode: "24473" },
    { lng: -77.051788, lat: 38.856623, postalCode: "22240" },
    { lng: -82.027408, lat: 37.287992, postalCode: "24618" },
    { lng: -79.16132, lat: 37.390209, postalCode: "24501" },
    { lng: -79.94541, lat: 37.357149, postalCode: "24020" },
    { lng: -78.066373, lat: 39.2543, postalCode: "22622" },
    { lng: -76.578109, lat: 37.75028, postalCode: "22517" },
    { lng: -80.17302, lat: 37.505033, postalCode: "24127" },
    { lng: -76.508402, lat: 37.418242, postalCode: "23018" },
    { lng: -76.92155, lat: 37.079298, postalCode: "23839" },
    { lng: -77.14081, lat: 38.849136, postalCode: "22041" },
    { lng: -80.32048, lat: 37.048647, postalCode: "24138" },
    { lng: -78.84222, lat: 38.167914, postalCode: "24431" },
    { lng: -77.888082, lat: 37.733844, postalCode: "23054" },
    { lng: -77.7057, lat: 38.810629, postalCode: "20137" },
    { lng: -81.53045, lat: 36.828526, postalCode: "24354" },
    { lng: -77.56682, lat: 37.191579, postalCode: "23885" },
    { lng: -81.11128, lat: 36.820139, postalCode: "24322" },
    { lng: -81.31688, lat: 37.273973, postalCode: "24613" },
    { lng: -80.53102, lat: 36.618824, postalCode: "24053" },
    { lng: -77.23131, lat: 38.775716, postalCode: "22152" },
    { lng: -77.3469, lat: 38.934709, postalCode: "20191" },
    { lng: -77.046699, lat: 38.804413, postalCode: "22320" },
    { lng: -77.40438, lat: 37.265403, postalCode: "23834" },
    { lng: -77.82067, lat: 38.493501, postalCode: "22718" },
    { lng: -79.21508, lat: 37.819652, postalCode: "24483" },
    { lng: -80.2335, lat: 37.218668, postalCode: "24087" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24030" },
    { lng: -78.42042, lat: 38.07735, postalCode: "22911" },
    { lng: -78.06051, lat: 38.932178, postalCode: "22642" },
    { lng: -80.067062, lat: 37.288384, postalCode: "24155" },
    { lng: -78.16653, lat: 39.179076, postalCode: "22601" },
    { lng: -79.39812, lat: 37.528701, postalCode: "24526" },
    { lng: -78.11341, lat: 38.627135, postalCode: "22716" },
    { lng: -77.30165, lat: 39.003809, postalCode: "22066" },
    { lng: -78.77182, lat: 37.094313, postalCode: "23959" },
    { lng: -77.80682, lat: 36.848712, postalCode: "23843" },
    { lng: -76.43591, lat: 37.112915, postalCode: "23693" },
    { lng: -76.56063, lat: 36.871628, postalCode: "23432" },
    { lng: -77.76824, lat: 37.961187, postalCode: "23024" },
    { lng: -75.55339, lat: 37.9268, postalCode: "23416" },
    { lng: -76.2547, lat: 36.942947, postalCode: "23503" },
    { lng: -77.5838, lat: 37.405699, postalCode: "23832" },
    { lng: -76.36811, lat: 36.810448, postalCode: "23701" },
    { lng: -77.99031, lat: 38.683116, postalCode: "20106" },
    { lng: -77.416103, lat: 37.531296, postalCode: "23255" },
    { lng: -76.550274, lat: 38.067325, postalCode: "22577" },
    { lng: -76.355165, lat: 36.868553, postalCode: "23709" },
    { lng: -77.66932, lat: 37.197024, postalCode: "23833" },
    { lng: -79.410983, lat: 36.592679, postalCode: "24544" },
    { lng: -76.27785, lat: 37.405038, postalCode: "23130" },
    { lng: -75.71051, lat: 37.685447, postalCode: "23418" },
    { lng: -80.2538, lat: 37.562902, postalCode: "24131" },
    { lng: -75.97104, lat: 37.26252, postalCode: "23310" },
    { lng: -78.95205, lat: 38.637545, postalCode: "22830" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24009" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22210" },
    { lng: -78.62058, lat: 37.581656, postalCode: "23921" },
    { lng: -78.24, lat: 38.636896, postalCode: "22740" },
    { lng: -76.81298, lat: 37.854791, postalCode: "22454" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24004" },
    { lng: -78.99553, lat: 38.19958, postalCode: "24482" },
    { lng: -80.41975, lat: 36.720001, postalCode: "24120" },
    { lng: -78.91187, lat: 36.861612, postalCode: "24539" },
    { lng: -81.94252, lat: 36.954936, postalCode: "24280" },
    { lng: -78.78234, lat: 38.453741, postalCode: "22832" },
    { lng: -79.08763, lat: 37.616606, postalCode: "24521" },
    { lng: -76.012628, lat: 36.795669, postalCode: "23479" },
    { lng: -76.57924, lat: 38.046358, postalCode: "22488" },
    { lng: -75.79947, lat: 37.589068, postalCode: "23420" },
    { lng: -76.530276, lat: 37.298919, postalCode: "23104" },
    { lng: -76.79025, lat: 38.121793, postalCode: "22558" },
    { lng: -76.46089, lat: 37.400287, postalCode: "23178" },
    { lng: -79.178506, lat: 37.400944, postalCode: "24514" },
    { lng: -76.1527, lat: 36.916397, postalCode: "23521" },
    { lng: -78.2809, lat: 39.023035, postalCode: "22645" },
    { lng: -78.18057, lat: 38.629058, postalCode: "22749" },
    { lng: -77.15328, lat: 38.859645, postalCode: "22044" },
    { lng: -77.774665, lat: 39.130665, postalCode: "20142" },
    { lng: -78.0948, lat: 38.765004, postalCode: "22627" },
    { lng: -77.645224, lat: 39.085309, postalCode: "20102" },
    { lng: -78.73668, lat: 37.017706, postalCode: "23962" },
    { lng: -76.564516, lat: 36.996881, postalCode: "23424" },
    { lng: -77.30259, lat: 37.235949, postalCode: "23875" },
    { lng: -80.65995, lat: 36.958829, postalCode: "24347" },
    { lng: -76.665251, lat: 36.746101, postalCode: "23439" },
    { lng: -79.93549, lat: 37.236753, postalCode: "24014" },
    { lng: -77.08973, lat: 38.843466, postalCode: "22206" },
    { lng: -77.39475, lat: 37.01775, postalCode: "23830" },
    { lng: -82.25643, lat: 37.138489, postalCode: "24220" },
    { lng: -78.37823, lat: 37.097705, postalCode: "23954" },
    { lng: -80.55031, lat: 37.138427, postalCode: "24142" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23273" },
    { lng: -77.20851, lat: 36.602472, postalCode: "23827" },
    { lng: -76.47393, lat: 37.288999, postalCode: "23072" },
    { lng: -77.59557, lat: 38.505945, postalCode: "22739" },
    { lng: -78.485066, lat: 38.040101, postalCode: "22908" },
    { lng: -76.81744, lat: 37.403872, postalCode: "23168" },
    { lng: -75.64895, lat: 37.780966, postalCode: "23421" },
    { lng: -81.10659, lat: 37.261609, postalCode: "24366" },
    { lng: -77.28678, lat: 37.517804, postalCode: "23150" },
    { lng: -76.420763, lat: 37.665713, postalCode: "22486" },
    { lng: -76.63973, lat: 36.978861, postalCode: "23430" },
    { lng: -77.61364, lat: 37.328232, postalCode: "23838" },
    { lng: -81.76922, lat: 37.055512, postalCode: "24609" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23515" },
    { lng: -77.23281, lat: 37.126708, postalCode: "23842" },
    { lng: -76.82858, lat: 37.638259, postalCode: "23091" },
    { lng: -76.75793, lat: 37.322936, postalCode: "23188" },
    { lng: -75.8315, lat: 37.661513, postalCode: "23389" },
    { lng: -82.77056, lat: 36.851953, postalCode: "24219" },
    { lng: -78.60964, lat: 38.828628, postalCode: "22824" },
    { lng: -78.30837, lat: 37.871021, postalCode: "22963" },
    { lng: -80.608056, lat: 37.096403, postalCode: "24129" },
    { lng: -80.52024, lat: 36.853525, postalCode: "24380" },
    { lng: -80.73083, lat: 37.240021, postalCode: "24167" },
    { lng: -77.12064, lat: 38.837312, postalCode: "22311" },
    { lng: -78.47803, lat: 38.00847, postalCode: "22902" },
    { lng: -78.19026, lat: 38.221405, postalCode: "22957" },
    { lng: -82.737469, lat: 36.864429, postalCode: "24246" },
    { lng: -82.12193, lat: 37.198005, postalCode: "24656" },
    { lng: -79.95528, lat: 37.116716, postalCode: "24065" },
    { lng: -80.35806, lat: 36.724064, postalCode: "24177" },
    { lng: -78.86564, lat: 38.544652, postalCode: "22834" },
    { lng: -77.64361, lat: 38.474222, postalCode: "22720" },
    { lng: -77.38527, lat: 38.977109, postalCode: "20170" },
    { lng: -82.344881, lat: 37.130943, postalCode: "24289" },
    { lng: -80.82602, lat: 36.714663, postalCode: "24381" },
    { lng: -77.56271, lat: 37.593546, postalCode: "23229" },
    { lng: -77.63866, lat: 38.639283, postalCode: "20119" },
    { lng: -82.63138, lat: 36.942047, postalCode: "24273" },
    { lng: -79.17339, lat: 38.252683, postalCode: "24421" },
    { lng: -80.41222, lat: 37.136013, postalCode: "24073" },
    { lng: -77.99793, lat: 37.69536, postalCode: "23063" },
    { lng: -79.22828, lat: 37.936577, postalCode: "24472" },
    { lng: -79.07625, lat: 38.146216, postalCode: "24401" },
    { lng: -77.49064, lat: 39.041978, postalCode: "20147" },
    { lng: -80.67102, lat: 36.609475, postalCode: "24317" },
    { lng: -76.45876, lat: 37.599217, postalCode: "23169" },
    { lng: -79.2923, lat: 37.071568, postalCode: "24563" },
    { lng: -79.26133, lat: 37.576208, postalCode: "24574" },
    { lng: -76.38992, lat: 37.072658, postalCode: "23681" },
    { lng: -80.395698, lat: 37.174227, postalCode: "24062" },
    { lng: -76.293436, lat: 37.90938, postalCode: "22530" },
    { lng: -76.38992, lat: 37.072658, postalCode: "23653" },
    { lng: -77.54519, lat: 39.138978, postalCode: "20176" },
    { lng: -77.72841, lat: 37.639354, postalCode: "23103" },
    { lng: -79.45652, lat: 37.641788, postalCode: "24555" },
    { lng: -78.891422, lat: 38.384681, postalCode: "22848" },
    { lng: -78.485066, lat: 38.040101, postalCode: "22906" },
    { lng: -78.94563, lat: 38.212067, postalCode: "24437" },
    { lng: -78.43921, lat: 38.949013, postalCode: "22660" },
    { lng: -77.58235, lat: 37.475798, postalCode: "23236" },
    { lng: -78.71934, lat: 37.638783, postalCode: "24599" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23218" },
    { lng: -76.46521, lat: 36.84882, postalCode: "23435" },
    { lng: -81.19791, lat: 36.813383, postalCode: "24374" },
    { lng: -77.451155, lat: 37.549446, postalCode: "23284" },
    { lng: -77.30199, lat: 38.852849, postalCode: "22038" },
    { lng: -76.82272, lat: 36.642645, postalCode: "23437" },
    { lng: -78.50149, lat: 38.072402, postalCode: "22901" },
    { lng: -76.90656, lat: 37.916172, postalCode: "22560" },
    { lng: -77.171752, lat: 38.884177, postalCode: "22040" },
    { lng: -77.95893, lat: 37.338511, postalCode: "23002" },
    { lng: -76.2904, lat: 37.451894, postalCode: "23119" },
    { lng: -76.79025, lat: 38.121793, postalCode: "22581" },
    { lng: -77.416103, lat: 37.531296, postalCode: "23288" },
    { lng: -81.34697, lat: 37.305821, postalCode: "24635" },
    { lng: -78.2117, lat: 37.191053, postalCode: "23922" },
    { lng: -75.701449, lat: 37.738248, postalCode: "23345" },
    { lng: -81.257795, lat: 36.682782, postalCode: "24379" },
    { lng: -78.92206, lat: 38.553225, postalCode: "22850" },
    { lng: -78.13578, lat: 38.808303, postalCode: "22640" },
    { lng: -79.48517, lat: 36.710985, postalCode: "24549" },
    { lng: -75.864607, lat: 37.577292, postalCode: "23341" },
    { lng: -77.8145, lat: 38.707135, postalCode: "20186" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24027" },
    { lng: -78.47377, lat: 39.04494, postalCode: "22654" },
    { lng: -79.1968, lat: 36.635902, postalCode: "24594" },
    { lng: -79.20923, lat: 37.440641, postalCode: "24503" },
    { lng: -76.51281, lat: 36.894736, postalCode: "23436" },
    { lng: -75.61896, lat: 37.828492, postalCode: "23308" },
    { lng: -80.62194, lat: 37.287326, postalCode: "24086" },
    { lng: -76.17767, lat: 36.799284, postalCode: "23464" },
    { lng: -75.62995, lat: 37.859953, postalCode: "23409" },
    { lng: -79.133546, lat: 37.245798, postalCode: "24513" },
    { lng: -79.87353, lat: 36.595224, postalCode: "24148" },
    { lng: -77.98876, lat: 37.070975, postalCode: "23824" },
    { lng: -78.3102, lat: 36.615944, postalCode: "23917" },
    { lng: -78.69417, lat: 37.792343, postalCode: "22969" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23278" },
    { lng: -78.21596, lat: 38.214023, postalCode: "22972" },
    { lng: -78.79701, lat: 37.987812, postalCode: "22920" },
    { lng: -77.64868, lat: 37.427988, postalCode: "23112" },
    { lng: -77.39008, lat: 37.174217, postalCode: "23805" },
    { lng: -76.636154, lat: 37.482039, postalCode: "23190" },
    { lng: -78.17517, lat: 38.477063, postalCode: "22735" },
    { lng: -81.86739, lat: 37.226416, postalCode: "24657" },
    { lng: -82.33154, lat: 36.943316, postalCode: "24283" },
    { lng: -78.62907, lat: 38.404364, postalCode: "22827" },
    { lng: -77.052296, lat: 38.851773, postalCode: "22245" },
    { lng: -79.8524, lat: 37.276218, postalCode: "24179" },
    { lng: -79.37324, lat: 37.133274, postalCode: "24571" },
    { lng: -79.97816, lat: 37.258787, postalCode: "24015" },
    { lng: -77.63118, lat: 39.191333, postalCode: "20197" },
    { lng: -76.508402, lat: 37.418242, postalCode: "23191" },
    { lng: -78.26151, lat: 38.345179, postalCode: "22731" },
    { lng: -79.65065, lat: 38.015153, postalCode: "24460" },
    { lng: -83.04178, lat: 36.756705, postalCode: "24277" },
    { lng: -80.70387, lat: 36.659171, postalCode: "24328" },
    { lng: -77.82402, lat: 36.951449, postalCode: "23876" },
    { lng: -76.68104, lat: 37.674655, postalCode: "23032" },
    { lng: -78.27368, lat: 38.822506, postalCode: "22610" },
    { lng: -76.169215, lat: 36.851243, postalCode: "23465" },
    { lng: -77.77328, lat: 39.119917, postalCode: "20141" },
    { lng: -75.952409, lat: 37.201937, postalCode: "23313" },
    { lng: -78.25117, lat: 38.527816, postalCode: "22719" },
    { lng: -79.1278, lat: 37.041512, postalCode: "24569" },
    { lng: -78.95941, lat: 38.258308, postalCode: "24467" },
    { lng: -75.76272, lat: 37.70973, postalCode: "23417" },
    { lng: -76.331815, lat: 37.019346, postalCode: "23667" },
    { lng: -76.965091, lat: 36.694658, postalCode: "23620" },
    { lng: -77.44915, lat: 38.769697, postalCode: "20111" },
    { lng: -77.62404, lat: 38.803732, postalCode: "20155" },
    { lng: -77.34845, lat: 37.241173, postalCode: "23801" },
    { lng: -77.581351, lat: 38.39933, postalCode: "22471" },
    { lng: -77.17261, lat: 38.271077, postalCode: "22547" },
    { lng: -78.71396, lat: 38.116217, postalCode: "22932" },
    { lng: -79.00071, lat: 38.398049, postalCode: "22812" },
    { lng: -81.54797, lat: 37.000956, postalCode: "24377" },
    { lng: -77.25337, lat: 38.894711, postalCode: "22180" },
    { lng: -78.89839, lat: 38.076547, postalCode: "22980" },
    { lng: -81.563406, lat: 37.13549, postalCode: "24608" },
    { lng: -79.04328, lat: 38.584516, postalCode: "22831" },
    { lng: -77.645224, lat: 39.085309, postalCode: "20146" },
    { lng: -78.39617, lat: 39.076317, postalCode: "22641" },
    { lng: -77.47011, lat: 37.855002, postalCode: "23047" },
    { lng: -75.810482, lat: 37.518917, postalCode: "23486" },
    { lng: -78.86302, lat: 38.477752, postalCode: "22802" },
    { lng: -78.77149, lat: 38.81913, postalCode: "22810" },
    { lng: -80.14868, lat: 37.043459, postalCode: "24079" },
    { lng: -78.11865, lat: 38.292221, postalCode: "22989" },
    { lng: -78.80585, lat: 37.374688, postalCode: "24522" },
    { lng: -78.137019, lat: 38.691448, postalCode: "22002" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23506" },
    { lng: -77.15284, lat: 38.757924, postalCode: "22315" },
    { lng: -77.07132, lat: 36.740306, postalCode: "23837" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22184" },
    { lng: -81.99735, lat: 37.14445, postalCode: "24646" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23276" },
    { lng: -80.13511, lat: 36.677978, postalCode: "24133" },
    { lng: -78.17499, lat: 38.926615, postalCode: "22630" },
    { lng: -79.21998, lat: 38.151767, postalCode: "24479" },
    { lng: -75.97768, lat: 36.795328, postalCode: "23461" },
    { lng: -77.76987, lat: 38.326624, postalCode: "22508" },
    { lng: -77.21387, lat: 38.830345, postalCode: "22003" },
    { lng: -77.05139, lat: 38.806163, postalCode: "22314" },
    { lng: -77.26786, lat: 38.63275, postalCode: "22191" },
    { lng: -78.2729, lat: 38.476275, postalCode: "22711" },
    { lng: -79.398748, lat: 36.579313, postalCode: "24542" },
    { lng: -78.3774, lat: 38.331957, postalCode: "22723" },
    { lng: -80.62267, lat: 37.31803, postalCode: "24136" },
    { lng: -76.95055, lat: 37.777938, postalCode: "23023" },
    { lng: -76.23972, lat: 36.931166, postalCode: "23529" },
    { lng: -75.87767, lat: 37.434719, postalCode: "23307" },
    { lng: -78.426788, lat: 38.65845, postalCode: "22865" },
    { lng: -78.28794, lat: 36.694531, postalCode: "23915" },
    { lng: -76.34389, lat: 37.733327, postalCode: "22482" },
    { lng: -76.35637, lat: 37.860549, postalCode: "22432" },
    { lng: -79.95321, lat: 37.272186, postalCode: "24016" },
    { lng: -77.466897, lat: 38.721912, postalCode: "22194" },
    { lng: -75.59686, lat: 37.876357, postalCode: "23359" },
    { lng: -80.02477, lat: 37.229786, postalCode: "24018" },
    { lng: -81.96207, lat: 36.657812, postalCode: "24211" },
    { lng: -76.746769, lat: 37.310451, postalCode: "23127" },
    { lng: -77.44775, lat: 37.606778, postalCode: "23227" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24036" },
    { lng: -77.03273, lat: 38.340124, postalCode: "22448" },
    { lng: -78.51218, lat: 36.702863, postalCode: "23968" },
    { lng: -78.03752, lat: 39.069682, postalCode: "22646" },
    { lng: -82.46657, lat: 36.952634, postalCode: "24230" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24006" },
    { lng: -75.67692, lat: 37.93359, postalCode: "23426" },
    { lng: -76.98127, lat: 36.963354, postalCode: "23888" },
    { lng: -77.645224, lat: 39.085309, postalCode: "20163" },
    { lng: -76.26844, lat: 37.426154, postalCode: "23045" },
    { lng: -77.51709, lat: 37.581696, postalCode: "23226" },
    { lng: -79.2357, lat: 37.9651, postalCode: "24475" },
    { lng: -75.53645, lat: 37.887538, postalCode: "23442" },
    { lng: -78.59064, lat: 36.848612, postalCode: "23976" },
    { lng: -77.489913, lat: 38.298305, postalCode: "22404" },
    { lng: -75.99404, lat: 37.847652, postalCode: "23440" },
    { lng: -80.68706, lat: 36.750353, postalCode: "24343" },
    { lng: -76.24511, lat: 36.761698, postalCode: "23320" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24044" },
    { lng: -78.00646, lat: 36.909504, postalCode: "23938" },
    { lng: -77.09819, lat: 37.005989, postalCode: "23890" },
    { lng: -76.92919, lat: 37.701245, postalCode: "23161" },
    { lng: -77.425452, lat: 37.561962, postalCode: "23999" },
    { lng: -76.33636, lat: 37.492529, postalCode: "23068" },
    { lng: -77.74687, lat: 36.993498, postalCode: "23872" },
    { lng: -78.46396, lat: 38.301191, postalCode: "22973" },
    { lng: -81.563406, lat: 37.13549, postalCode: "24619" },
    { lng: -80.59761, lat: 37.189876, postalCode: "24058" },
    { lng: -79.50659, lat: 37.597531, postalCode: "24579" },
    { lng: -77.518865, lat: 38.137216, postalCode: "22565" },
    { lng: -77.4658, lat: 37.457341, postalCode: "23234" },
    { lng: -77.13999, lat: 38.88275, postalCode: "22205" },
    { lng: -77.26044, lat: 38.683295, postalCode: "22125" },
    { lng: -79.29977, lat: 37.366744, postalCode: "24551" },
    { lng: -78.485066, lat: 38.040101, postalCode: "22905" },
    { lng: -78.485066, lat: 38.040101, postalCode: "22907" },
    { lng: -79.89283, lat: 37.970734, postalCode: "24445" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23260" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22092" },
    { lng: -81.28162, lat: 36.889364, postalCode: "24368" },
    { lng: -78.90765, lat: 37.079385, postalCode: "24528" },
    { lng: -77.08666, lat: 38.757214, postalCode: "22306" },
    { lng: -78.213304, lat: 38.947215, postalCode: "22651" },
    { lng: -79.90303, lat: 37.369313, postalCode: "24077" },
    { lng: -76.72389, lat: 37.501267, postalCode: "23156" },
    { lng: -78.20098, lat: 38.310141, postalCode: "22732" },
    { lng: -77.36304, lat: 37.482896, postalCode: "23231" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24010" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22214" },
    { lng: -76.3358, lat: 36.757815, postalCode: "23323" },
    { lng: -76.30724, lat: 37.43704, postalCode: "23109" },
    { lng: -77.47496, lat: 36.811276, postalCode: "23867" },
    { lng: -76.64055, lat: 38.093956, postalCode: "22469" },
    { lng: -82.59626, lat: 37.10297, postalCode: "24279" },
    { lng: -76.32386, lat: 36.803981, postalCode: "23702" },
    { lng: -79.70789, lat: 37.413397, postalCode: "24122" },
    { lng: -77.05701, lat: 38.728464, postalCode: "22308" },
    { lng: -76.32039, lat: 37.002997, postalCode: "23651" },
    { lng: -79.141332, lat: 38.179715, postalCode: "24407" },
    { lng: -78.63611, lat: 36.913228, postalCode: "23967" },
    { lng: -78.883933, lat: 36.801515, postalCode: "24585" },
    { lng: -77.888082, lat: 37.733844, postalCode: "23014" },
    { lng: -78.79742, lat: 38.371616, postalCode: "22846" },
    { lng: -76.85843, lat: 37.711368, postalCode: "23085" },
    { lng: -79.39998, lat: 36.622638, postalCode: "24540" },
    { lng: -77.68111, lat: 38.388311, postalCode: "22736" },
    { lng: -78.23472, lat: 37.782109, postalCode: "23055" },
    { lng: -80.07821, lat: 37.288807, postalCode: "24153" },
    { lng: -79.68004, lat: 37.532991, postalCode: "24066" },
    { lng: -78.2868, lat: 37.663039, postalCode: "23123" },
    { lng: -77.10734, lat: 38.725214, postalCode: "22309" },
    { lng: -82.11083, lat: 36.876177, postalCode: "24266" },
    { lng: -82.18137, lat: 36.645419, postalCode: "24202" },
    { lng: -81.70519, lat: 37.076801, postalCode: "24637" },
    { lng: -77.5038, lat: 38.90371, postalCode: "20152" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24037" },
    { lng: -77.332916, lat: 37.507498, postalCode: "23250" },
    { lng: -78.08496, lat: 39.26582, postalCode: "22624" },
    { lng: -78.93426, lat: 36.774635, postalCode: "24558" },
    { lng: -77.645224, lat: 39.085309, postalCode: "20167" },
    { lng: -79.27409, lat: 37.127543, postalCode: "24517" },
    { lng: -79.64786, lat: 37.145705, postalCode: "24121" },
    { lng: -78.08098, lat: 36.678507, postalCode: "23950" },
    { lng: -79.865168, lat: 36.679571, postalCode: "24113" },
    { lng: -81.76732, lat: 36.624632, postalCode: "24236" },
    { lng: -77.51294, lat: 38.790202, postalCode: "20109" },
    { lng: -77.21976, lat: 38.893921, postalCode: "22027" },
    { lng: -77.996956, lat: 37.940788, postalCode: "23170" },
    { lng: -80.351482, lat: 37.179115, postalCode: "24061" },
    { lng: -76.012628, lat: 36.795669, postalCode: "23471" },
    { lng: -76.42135, lat: 37.191262, postalCode: "23696" },
    { lng: -78.98961, lat: 37.79151, postalCode: "22967" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24003" },
    { lng: -77.36778, lat: 37.662559, postalCode: "23116" },
    { lng: -81.97936, lat: 37.204272, postalCode: "24624" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22183" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23240" },
    { lng: -78.19396, lat: 39.267257, postalCode: "22603" },
    { lng: -76.524805, lat: 37.195924, postalCode: "23612" },
    { lng: -78.485066, lat: 38.040101, postalCode: "22904" },
    { lng: -77.288755, lat: 38.831813, postalCode: "20196" },
    { lng: -77.466897, lat: 38.721912, postalCode: "22195" },
    { lng: -77.449443, lat: 38.770864, postalCode: "20113" },
    { lng: -77.08204, lat: 38.829512, postalCode: "22302" },
    { lng: -80.85731, lat: 36.837699, postalCode: "24312" },
    { lng: -79.45027, lat: 36.581085, postalCode: "24541" },
    { lng: -76.41631, lat: 37.571789, postalCode: "23176" },
    { lng: -77.29047, lat: 37.988674, postalCode: "22514" },
    { lng: -78.80777, lat: 37.541726, postalCode: "24553" },
    { lng: -76.99901, lat: 37.728152, postalCode: "23177" },
    { lng: -77.43536, lat: 37.541046, postalCode: "23219" },
    { lng: -77.43959, lat: 37.350999, postalCode: "23831" },
    { lng: -77.18098, lat: 38.886311, postalCode: "22046" },
    { lng: -78.874173, lat: 38.440898, postalCode: "22807" },
    { lng: -77.608014, lat: 39.072918, postalCode: "20178" },
    { lng: -77.33372, lat: 38.503139, postalCode: "22134" },
    { lng: -78.77546, lat: 37.85583, postalCode: "22938" },
    { lng: -82.18593, lat: 37.308652, postalCode: "24628" },
    { lng: -75.76512, lat: 37.61686, postalCode: "23401" },
    { lng: -77.08033, lat: 38.036866, postalCode: "22476" },
    { lng: -76.012628, lat: 36.795669, postalCode: "23463" },
    { lng: -78.8242, lat: 38.255747, postalCode: "24441" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23173" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24048" },
    { lng: -77.34469, lat: 38.646375, postalCode: "22193" },
    { lng: -75.93117, lat: 37.425567, postalCode: "23405" },
    { lng: -76.90719, lat: 37.435153, postalCode: "23089" },
    { lng: -77.89529, lat: 38.512989, postalCode: "22714" },
    { lng: -78.26865, lat: 37.728067, postalCode: "23022" },
    { lng: -79.63536, lat: 36.947422, postalCode: "24137" },
    { lng: -77.10269, lat: 36.610636, postalCode: "23874" },
    { lng: -80.395698, lat: 37.174227, postalCode: "24111" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22212" },
    { lng: -79.939228, lat: 37.271687, postalCode: "24042" },
    { lng: -79.61864, lat: 36.807043, postalCode: "24530" },
    { lng: -77.487162, lat: 38.744685, postalCode: "20108" },
    { lng: -75.96909, lat: 37.284061, postalCode: "23316" },
    { lng: -77.288755, lat: 38.831813, postalCode: "22158" },
    { lng: -77.20342, lat: 37.704693, postalCode: "23106" },
    { lng: -82.00538, lat: 37.223011, postalCode: "24631" },
    { lng: -79.92767, lat: 37.305769, postalCode: "24012" },
    { lng: -79.786151, lat: 37.555083, postalCode: "24130" },
    { lng: -81.7742, lat: 36.772376, postalCode: "24340" },
    { lng: -77.7813, lat: 37.422868, postalCode: "23120" },
    { lng: -81.1992, lat: 36.88091, postalCode: "24323" },
    { lng: -79.86879, lat: 36.696264, postalCode: "24112" },
    { lng: -76.15177, lat: 36.839648, postalCode: "23462" },
    { lng: -82.79644, lat: 36.921924, postalCode: "24215" },
    { lng: -77.61471, lat: 39.149032, postalCode: "20129" },
    { lng: -77.99875, lat: 38.459521, postalCode: "22701" },
    { lng: -77.29293, lat: 38.821753, postalCode: "22032" },
    { lng: -79.05268, lat: 36.561396, postalCode: "24520" },
    { lng: -76.56076, lat: 37.257932, postalCode: "23691" },
    { lng: -80.92897, lat: 36.933981, postalCode: "24360" },
    { lng: -76.46751, lat: 37.178417, postalCode: "23692" },
    { lng: -79.133546, lat: 37.245798, postalCode: "24576" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24029" },
    { lng: -77.58025, lat: 39.080037, postalCode: "20175" },
    { lng: -77.1677, lat: 38.93276, postalCode: "22101" },
    { lng: -78.11349, lat: 37.303726, postalCode: "23083" },
    { lng: -79.79619, lat: 37.821088, postalCode: "24422" },
    { lng: -78.99039, lat: 38.436873, postalCode: "22821" },
    { lng: -75.583227, lat: 37.816896, postalCode: "23414" },
    { lng: -77.47465, lat: 37.754254, postalCode: "23005" },
    { lng: -78.595831, lat: 38.065383, postalCode: "22945" },
    { lng: -78.40144, lat: 37.307946, postalCode: "23901" },
    { lng: -77.15127, lat: 37.506369, postalCode: "23141" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22219" },
    { lng: -79.55269, lat: 37.650711, postalCode: "24578" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24025" },
    { lng: -77.91258, lat: 38.233223, postalCode: "22567" },
    { lng: -79.91761, lat: 37.407301, postalCode: "24083" },
    { lng: -76.4195, lat: 36.987151, postalCode: "23607" },
    { lng: -77.68952, lat: 37.813901, postalCode: "23192" },
    { lng: -76.29577, lat: 37.366838, postalCode: "23138" },
    { lng: -77.53651, lat: 37.686152, postalCode: "23059" },
    { lng: -80.5936, lat: 36.901524, postalCode: "24105" },
    { lng: -75.73996, lat: 37.64682, postalCode: "23410" },
    { lng: -78.03693, lat: 39.061499, postalCode: "22620" },
    { lng: -78.4643, lat: 37.029877, postalCode: "23947" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24026" },
    { lng: -77.43063, lat: 37.21695, postalCode: "23803" },
    { lng: -76.355165, lat: 36.868553, postalCode: "23705" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24031" },
    { lng: -78.96672, lat: 38.797789, postalCode: "22811" },
    { lng: -75.67974, lat: 37.754497, postalCode: "23357" },
    { lng: -81.38945, lat: 36.982193, postalCode: "24318" },
    { lng: -77.82652, lat: 36.563755, postalCode: "23887" },
    { lng: -76.615358, lat: 37.947224, postalCode: "22570" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23282" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22241" },
    { lng: -78.80513, lat: 38.619654, postalCode: "22815" },
    { lng: -77.493157, lat: 37.524246, postalCode: "23279" },
    { lng: -76.48985, lat: 37.991772, postalCode: "22511" },
    { lng: -77.07453, lat: 38.893241, postalCode: "22209" },
    { lng: -76.59656, lat: 36.735893, postalCode: "23434" },
    { lng: -78.380509, lat: 38.372652, postalCode: "22748" },
    { lng: -82.56963, lat: 36.979817, postalCode: "24293" },
    { lng: -77.88174, lat: 38.855848, postalCode: "20115" },
    { lng: -77.66795, lat: 38.08157, postalCode: "22534" },
    { lng: -78.54018, lat: 36.61665, postalCode: "23927" },
    { lng: -79.19748, lat: 36.842686, postalCode: "24565" },
    { lng: -77.461213, lat: 38.468432, postalCode: "22463" },
    { lng: -81.37604, lat: 36.597486, postalCode: "24363" },
    { lng: -78.24758, lat: 39.158386, postalCode: "22602" },
    { lng: -82.26093, lat: 36.720381, postalCode: "24270" },
    { lng: -77.69002, lat: 37.725675, postalCode: "23146" },
    { lng: -80.23909, lat: 36.655575, postalCode: "24171" },
    { lng: -80.30871, lat: 36.910051, postalCode: "24091" },
    { lng: -76.73506, lat: 36.828408, postalCode: "23487" },
    { lng: -77.989878, lat: 37.825208, postalCode: "23067" },
    { lng: -78.80233, lat: 37.74747, postalCode: "22971" },
    { lng: -77.645224, lat: 39.085309, postalCode: "22093" },
    { lng: -76.36307, lat: 37.892668, postalCode: "22473" },
    { lng: -76.83731, lat: 36.836423, postalCode: "23898" },
    { lng: -80.67726, lat: 37.351691, postalCode: "24150" },
    { lng: -77.64296, lat: 37.931011, postalCode: "23015" },
    { lng: -79.08249, lat: 37.556248, postalCode: "24595" },
    { lng: -78.92008, lat: 36.701855, postalCode: "24592" },
    { lng: -82.027408, lat: 37.287992, postalCode: "24627" },
    { lng: -77.11295, lat: 38.880811, postalCode: "22215" },
    { lng: -78.288465, lat: 39.236944, postalCode: "22638" },
    { lng: -76.02058, lat: 36.926647, postalCode: "23459" },
    { lng: -80.978273, lat: 36.718307, postalCode: "24338" },
    { lng: -78.11747, lat: 38.349795, postalCode: "22948" },
    { lng: -76.687745, lat: 36.898915, postalCode: "23397" },
    { lng: -76.5183, lat: 37.115698, postalCode: "23602" },
    { lng: -75.86849, lat: 37.635833, postalCode: "23358" },
    { lng: -81.64412, lat: 37.170062, postalCode: "24602" },
    { lng: -77.05628, lat: 38.856795, postalCode: "22202" },
    { lng: -78.70343, lat: 37.890917, postalCode: "22931" },
    { lng: -78.394944, lat: 37.301574, postalCode: "23909" },
    { lng: -78.267848, lat: 38.428509, postalCode: "22725" },
    { lng: -75.69088, lat: 37.605335, postalCode: "23480" },
    { lng: -77.70361, lat: 38.651558, postalCode: "20139" },
    { lng: -78.11236, lat: 37.173493, postalCode: "23930" },
    { lng: -78.53403, lat: 36.958048, postalCode: "23937" },
    { lng: -80.47021, lat: 37.325758, postalCode: "24128" },
    { lng: -78.98017, lat: 36.940538, postalCode: "24577" },
    { lng: -81.970764, lat: 36.690942, postalCode: "24212" },
    { lng: -77.329674, lat: 38.112139, postalCode: "22606" },
    { lng: -76.5378, lat: 36.940626, postalCode: "23314" },
    { lng: -77.82208, lat: 38.411845, postalCode: "22726" },
    { lng: -77.7009, lat: 38.456916, postalCode: "22742" },
    { lng: -77.90048, lat: 37.55563, postalCode: "23139" },
    { lng: -82.18978, lat: 37.082275, postalCode: "24217" },
    { lng: -76.5298, lat: 37.285188, postalCode: "23184" },
    { lng: -79.95786, lat: 37.274175, postalCode: "24001" },
    { lng: -80.26233, lat: 37.14374, postalCode: "24162" },
    { lng: -77.666863, lat: 39.158095, postalCode: "20177" },
    { lng: -78.35444, lat: 39.247386, postalCode: "22637" },
    { lng: -77.07964, lat: 38.792863, postalCode: "22303" },
    { lng: -76.524805, lat: 37.195924, postalCode: "23628" },
    { lng: -76.21233, lat: 36.855247, postalCode: "23502" },
    { lng: -77.52276, lat: 39.020637, postalCode: "20148" },
    { lng: -79.58361, lat: 38.521373, postalCode: "24413" },
    { lng: -77.528308, lat: 37.567896, postalCode: "23266" },
    { lng: -78.76258, lat: 38.641556, postalCode: "22853" },
    { lng: -80.81734, lat: 37.38389, postalCode: "24147" },
    { lng: -80.00161, lat: 36.757893, postalCode: "24055" },
    { lng: -72.44382, lat: 44.33865, postalCode: "5666" },
    { lng: -73.082525, lat: 44.442117, postalCode: "5405" },
    { lng: -72.82257, lat: 44.636283, postalCode: "5464" },
    { lng: -72.62328, lat: 42.849957, postalCode: "5301" },
    { lng: -72.422, lat: 44.083457, postalCode: "5675" },
    { lng: -72.87237, lat: 42.952974, postalCode: "5356" },
    { lng: -72.29347, lat: 44.026521, postalCode: "5039" },
    { lng: -72.66916, lat: 43.954353, postalCode: "5060" },
    { lng: -72.04372, lat: 44.653046, postalCode: "5867" },
    { lng: -73.19659, lat: 44.541902, postalCode: "5446" },
    { lng: -72.78992, lat: 44.885689, postalCode: "5450" },
    { lng: -72.70416, lat: 43.416827, postalCode: "5149" },
    { lng: -72.14245, lat: 44.168111, postalCode: "5069" },
    { lng: -73.050271, lat: 44.50835, postalCode: "5451" },
    { lng: -72.22669, lat: 44.254302, postalCode: "5046" },
    { lng: -72.4739, lat: 44.551056, postalCode: "5680" },
    { lng: -73.04215, lat: 43.574326, postalCode: "5777" },
    { lng: -73.17941, lat: 44.451941, postalCode: "5403" },
    { lng: -73.21395, lat: 42.776384, postalCode: "5261" },
    { lng: -73.17873, lat: 42.882231, postalCode: "5201" },
    { lng: -72.49397, lat: 43.380411, postalCode: "5151" },
    { lng: -73.22318, lat: 44.388556, postalCode: "5482" },
    { lng: -72.31915, lat: 44.116731, postalCode: "5086" },
    { lng: -72.79134, lat: 43.303613, postalCode: "5161" },
    { lng: -72.66316, lat: 44.970417, postalCode: "5476" },
    { lng: -73.10703, lat: 43.914026, postalCode: "5769" },
    { lng: -72.2123, lat: 44.411321, postalCode: "5873" },
    { lng: -73.082525, lat: 44.442117, postalCode: "5402" },
    { lng: -71.930077, lat: 44.468494, postalCode: "5838" },
    { lng: -72.779614, lat: 44.352339, postalCode: "5626" },
    { lng: -72.10346, lat: 44.311706, postalCode: "5821" },
    { lng: -72.46276, lat: 43.989583, postalCode: "5038" },
    { lng: -72.38308, lat: 44.733624, postalCode: "5820" },
    { lng: -72.96213, lat: 44.801734, postalCode: "5455" },
    { lng: -72.720362, lat: 42.99467, postalCode: "5304" },
    { lng: -72.818121, lat: 43.592039, postalCode: "5745" },
    { lng: -71.7162, lat: 44.458803, postalCode: "5906" },
    { lng: -72.26819, lat: 44.529915, postalCode: "5836" },
    { lng: -72.8642, lat: 43.507854, postalCode: "5738" },
    { lng: -72.13946, lat: 44.062646, postalCode: "5085" },
    { lng: -73.07155, lat: 43.162074, postalCode: "5254" },
    { lng: -72.655892, lat: 44.156554, postalCode: "5603" },
    { lng: -72.7925, lat: 43.103191, postalCode: "5343" },
    { lng: -73.28437, lat: 44.12753, postalCode: "5491" },
    { lng: -72.50247, lat: 44.175744, postalCode: "5670" },
    { lng: -72.2345, lat: 44.128666, postalCode: "5076" },
    { lng: -72.80609, lat: 42.781648, postalCode: "5342" },
    { lng: -72.66643, lat: 43.04943, postalCode: "5353" },
    { lng: -72.47756, lat: 43.625216, postalCode: "5073" },
    { lng: -72.88507, lat: 43.441404, postalCode: "5742" },
    { lng: -72.895849, lat: 44.824855, postalCode: "5481" },
    { lng: -72.355539, lat: 43.672103, postalCode: "5047" },
    { lng: -72.62408, lat: 42.957233, postalCode: "5357" },
    { lng: -72.39941, lat: 43.566105, postalCode: "5048" },
    { lng: -72.39364, lat: 43.890244, postalCode: "5072" },
    { lng: -72.48743, lat: 43.461468, postalCode: "5037" },
    { lng: -72.54272, lat: 43.687515, postalCode: "5067" },
    { lng: -73.291868, lat: 43.687677, postalCode: "5731" },
    { lng: -73.00758, lat: 43.243449, postalCode: "5253" },
    { lng: -72.96146, lat: 42.779542, postalCode: "5350" },
    { lng: -72.66443, lat: 44.141056, postalCode: "5663" },
    { lng: -72.21275, lat: 44.070771, postalCode: "5040" },
    { lng: -72.42943, lat: 43.649153, postalCode: "5059" },
    { lng: -72.26637, lat: 43.910089, postalCode: "5083" },
    { lng: -73.09254, lat: 44.332217, postalCode: "5461" },
    { lng: -72.09061, lat: 44.135828, postalCode: "5081" },
    { lng: -72.70225, lat: 44.475387, postalCode: "5672" },
    { lng: -72.86457, lat: 42.886921, postalCode: "5363" },
    { lng: -72.408, lat: 44.902837, postalCode: "5868" },
    { lng: -72.08037, lat: 44.213499, postalCode: "5042" },
    { lng: -72.135804, lat: 44.46239, postalCode: "5050" },
    { lng: -72.112619, lat: 44.91454, postalCode: "5892" },
    { lng: -72.597057, lat: 44.67307, postalCode: "5665" },
    { lng: -72.09864, lat: 44.945925, postalCode: "5829" },
    { lng: -72.95936, lat: 44.392443, postalCode: "5477" },
    { lng: -71.92705, lat: 44.588911, postalCode: "5832" },
    { lng: -71.919518, lat: 44.382103, postalCode: "5848" },
    { lng: -72.44328, lat: 44.403899, postalCode: "5650" },
    { lng: -72.885253, lat: 44.504656, postalCode: "5490" },
    { lng: -72.403244, lat: 43.716375, postalCode: "5080" },
    { lng: -72.51888, lat: 43.061436, postalCode: "5364" },
    { lng: -72.86962, lat: 42.782281, postalCode: "5361" },
    { lng: -72.27125, lat: 43.83178, postalCode: "5075" },
    { lng: -72.55088, lat: 44.383559, postalCode: "5682" },
    { lng: -72.533124, lat: 43.441399, postalCode: "5124" },
    { lng: -71.86394, lat: 44.643171, postalCode: "5837" },
    { lng: -72.90151, lat: 44.645649, postalCode: "5444" },
    { lng: -72.135369, lat: 44.95282, postalCode: "5928" },
    { lng: -72.45025, lat: 44.799008, postalCode: "5847" },
    { lng: -73.00087, lat: 43.719898, postalCode: "5763" },
    { lng: -72.5536, lat: 43.485715, postalCode: "5062" },
    { lng: -72.85816, lat: 44.106922, postalCode: "5674" },
    { lng: -72.13245, lat: 44.632918, postalCode: "5866" },
    { lng: -73.09917, lat: 44.44524, postalCode: "5495" },
    { lng: -72.91347, lat: 43.15601, postalCode: "5340" },
    { lng: -72.61831, lat: 43.26449, postalCode: "5143" },
    { lng: -72.79419, lat: 43.659839, postalCode: "5751" },
    { lng: -73.01602, lat: 43.341177, postalCode: "5739" },
    { lng: -72.5731, lat: 43.928369, postalCode: "5061" },
    { lng: -73.091416, lat: 43.97153, postalCode: "5740" },
    { lng: -72.83445, lat: 43.416666, postalCode: "5730" },
    { lng: -73.05261, lat: 43.172699, postalCode: "5255" },
    { lng: -72.720362, lat: 42.99467, postalCode: "5303" },
    { lng: -72.434398, lat: 44.627698, postalCode: "5286" },
    { lng: -73.23504, lat: 44.309241, postalCode: "5445" },
    { lng: -73.17405, lat: 43.106492, postalCode: "5250" },
    { lng: -72.71366, lat: 43.785433, postalCode: "5746" },
    { lng: -73.11308, lat: 43.266886, postalCode: "5251" },
    { lng: -72.30054, lat: 44.944231, postalCode: "5857" },
    { lng: -72.71362, lat: 44.375144, postalCode: "5677" },
    { lng: -72.821555, lat: 43.476669, postalCode: "5958" },
    { lng: -72.53208, lat: 43.808623, postalCode: "5068" },
    { lng: -73.23612, lat: 43.365073, postalCode: "5775" },
    { lng: -73.18259, lat: 44.494999, postalCode: "5404" },
    { lng: -72.77081, lat: 44.348486, postalCode: "5676" },
    { lng: -73.20284, lat: 43.644208, postalCode: "5732" },
    { lng: -72.83927, lat: 43.999811, postalCode: "5747" },
    { lng: -72.59686, lat: 44.265633, postalCode: "5602" },
    { lng: -72.24333, lat: 44.574196, postalCode: "5842" },
    { lng: -72.49886, lat: 43.714887, postalCode: "5053" },
    { lng: -72.59971, lat: 44.610931, postalCode: "5655" },
    { lng: -72.50844, lat: 43.139961, postalCode: "5154" },
    { lng: -72.79694, lat: 42.952754, postalCode: "5341" },
    { lng: -72.58798, lat: 44.874895, postalCode: "5471" },
    { lng: -73.187699, lat: 43.761499, postalCode: "5233" },
    { lng: -71.59275, lat: 44.972508, postalCode: "5903" },
    { lng: -73.2869, lat: 43.801501, postalCode: "5760" },
    { lng: -72.63738, lat: 43.439302, postalCode: "5153" },
    { lng: -72.16376, lat: 44.750509, postalCode: "5822" },
    { lng: -72.96168, lat: 43.553904, postalCode: "5759" },
    { lng: -73.00979, lat: 44.608283, postalCode: "5494" },
    { lng: -72.718759, lat: 44.442001, postalCode: "5662" },
    { lng: -72.22275, lat: 43.815621, postalCode: "5074" },
    { lng: -73.16118, lat: 43.640503, postalCode: "5735" },
    { lng: -73.33081, lat: 44.866344, postalCode: "5463" },
    { lng: -73.082525, lat: 44.442117, postalCode: "5453" },
    { lng: -72.20655, lat: 43.919887, postalCode: "5045" },
    { lng: -72.303696, lat: 44.951093, postalCode: "5852" },
    { lng: -72.183633, lat: 43.898246, postalCode: "5801" },
    { lng: -72.65777, lat: 43.003271, postalCode: "5345" },
    { lng: -72.4848, lat: 43.897183, postalCode: "5077" },
    { lng: -73.12099, lat: 44.930163, postalCode: "5488" },
    { lng: -72.585018, lat: 44.259518, postalCode: "5609" },
    { lng: -72.18798, lat: 43.852619, postalCode: "5054" },
    { lng: -72.71702, lat: 42.77448, postalCode: "5358" },
    { lng: -71.81134, lat: 44.959647, postalCode: "5907" },
    { lng: -71.50628, lat: 45.008398, postalCode: "5902" },
    { lng: -72.7585, lat: 44.708468, postalCode: "5492" },
    { lng: -72.47675, lat: 44.140716, postalCode: "5654" },
    { lng: -72.3887, lat: 44.647068, postalCode: "5826" },
    { lng: -72.41782, lat: 44.963802, postalCode: "5859" },
    { lng: -72.88309, lat: 43.24283, postalCode: "5152" },
    { lng: -72.74073, lat: 42.851149, postalCode: "5344" },
    { lng: -72.51725, lat: 43.02419, postalCode: "5346" },
    { lng: -72.15534, lat: 43.994064, postalCode: "5033" },
    { lng: -73.03056, lat: 44.941617, postalCode: "5459" },
    { lng: -73.0155, lat: 43.981681, postalCode: "5766" },
    { lng: -72.588407, lat: 43.592039, postalCode: "5009" },
    { lng: -73.165092, lat: 44.49518, postalCode: "5439" },
    { lng: -72.04647, lat: 44.865723, postalCode: "5872" },
    { lng: -72.963154, lat: 44.905573, postalCode: "5485" },
    { lng: -71.71536, lat: 44.612521, postalCode: "5840" },
    { lng: -73.21148, lat: 43.864419, postalCode: "5778" },
    { lng: -72.5717, lat: 44.715079, postalCode: "5652" },
    { lng: -72.54155, lat: 44.109793, postalCode: "5679" },
    { lng: -72.51835, lat: 42.768143, postalCode: "5354" },
    { lng: -72.53739, lat: 43.631076, postalCode: "5091" },
    { lng: -73.05258, lat: 44.129002, postalCode: "5443" },
    { lng: -72.429777, lat: 43.410672, postalCode: "5030" },
    { lng: -72.81212, lat: 43.876046, postalCode: "5767" },
    { lng: -73.105205, lat: 44.974765, postalCode: "5460" },
    { lng: -72.43328, lat: 44.152477, postalCode: "5649" },
    { lng: -72.45054, lat: 43.093695, postalCode: "5158" },
    { lng: -72.351, lat: 43.598261, postalCode: "5052" },
    { lng: -73.09998, lat: 43.810371, postalCode: "5733" },
    { lng: -72.78525, lat: 44.248198, postalCode: "5660" },
    { lng: -72.28964, lat: 44.800462, postalCode: "5845" },
    { lng: -72.92649, lat: 44.546538, postalCode: "5489" },
    { lng: -73.082525, lat: 44.442117, postalCode: "5407" },
    { lng: -72.80106, lat: 43.238921, postalCode: "5148" },
    { lng: -72.61661, lat: 43.186044, postalCode: "5146" },
    { lng: -73.141318, lat: 43.225264, postalCode: "5752" },
    { lng: -73.22126, lat: 44.484038, postalCode: "5401" },
    { lng: -73.06025, lat: 42.765401, postalCode: "5352" },
    { lng: -72.00996, lat: 44.412413, postalCode: "5819" },
    { lng: -73.012715, lat: 42.886696, postalCode: "5210" },
    { lng: -71.719436, lat: 44.680771, postalCode: "5901" },
    { lng: -72.64493, lat: 43.811958, postalCode: "5032" },
    { lng: -72.720362, lat: 42.99467, postalCode: "5141" },
    { lng: -73.06783, lat: 43.68805, postalCode: "5744" },
    { lng: -72.55327, lat: 43.568095, postalCode: "5071" },
    { lng: -72.31179, lat: 44.599177, postalCode: "5841" },
    { lng: -73.16602, lat: 42.977018, postalCode: "5262" },
    { lng: -73.04099, lat: 44.693805, postalCode: "5454" },
    { lng: -72.135804, lat: 44.46239, postalCode: "5861" },
    { lng: -72.72179, lat: 43.531612, postalCode: "5056" },
    { lng: -71.70906, lat: 44.413715, postalCode: "5904" },
    { lng: -73.134702, lat: 44.240375, postalCode: "5469" },
    { lng: -72.42387, lat: 43.775807, postalCode: "5065" },
    { lng: -72.588407, lat: 43.592039, postalCode: "5144" },
    { lng: -72.96317, lat: 44.486113, postalCode: "5465" },
    { lng: -72.32092, lat: 43.948258, postalCode: "5079" },
    { lng: -72.42611, lat: 43.486791, postalCode: "5089" },
    { lng: -72.88247, lat: 44.751801, postalCode: "5448" },
    { lng: -71.988258, lat: 44.481915, postalCode: "5850" },
    { lng: -72.135804, lat: 44.46239, postalCode: "5862" },
    { lng: -73.27354, lat: 44.831101, postalCode: "5474" },
    { lng: -72.818121, lat: 43.592039, postalCode: "5741" },
    { lng: -71.96653, lat: 44.881758, postalCode: "5853" },
    { lng: -72.60332, lat: 44.549391, postalCode: "5661" },
    { lng: -72.42902, lat: 44.876447, postalCode: "5874" },
    { lng: -72.68029, lat: 44.755398, postalCode: "5442" },
    { lng: -72.588407, lat: 43.592039, postalCode: "5049" },
    { lng: -72.61789, lat: 43.577762, postalCode: "5034" },
    { lng: -72.42365, lat: 44.295577, postalCode: "5667" },
    { lng: -72.990632, lat: 43.412792, postalCode: "5702" },
    { lng: -72.73703, lat: 43.761779, postalCode: "5772" },
    { lng: -72.031815, lat: 44.352579, postalCode: "5219" },
    { lng: -72.36825, lat: 43.662892, postalCode: "5001" },
    { lng: -72.67941, lat: 44.645219, postalCode: "5656" },
    { lng: -72.87914, lat: 43.028157, postalCode: "5360" },
    { lng: -72.98732, lat: 43.444268, postalCode: "5773" },
    { lng: -72.07174, lat: 44.075132, postalCode: "5051" },
    { lng: -73.27028, lat: 44.966836, postalCode: "5440" },
    { lng: -72.43372, lat: 43.720826, postalCode: "5084" },
    { lng: -73.19578, lat: 43.43357, postalCode: "5774" },
    { lng: -72.895849, lat: 44.824855, postalCode: "5447" },
    { lng: -72.34925, lat: 44.353823, postalCode: "5658" },
    { lng: -71.95414, lat: 44.673586, postalCode: "5871" },
    { lng: -72.4729, lat: 43.156451, postalCode: "5101" },
    { lng: -72.35975, lat: 44.678879, postalCode: "5827" },
    { lng: -73.2489, lat: 43.235771, postalCode: "5776" },
    { lng: -72.585018, lat: 44.259518, postalCode: "5633" },
    { lng: -72.49509, lat: 44.375726, postalCode: "5648" },
    { lng: -72.40659, lat: 44.457481, postalCode: "5681" },
    { lng: -72.05857, lat: 44.985004, postalCode: "5830" },
    { lng: -73.22253, lat: 44.255574, postalCode: "5473" },
    { lng: -72.12808, lat: 44.424043, postalCode: "5828" },
    { lng: -72.6799, lat: 43.608849, postalCode: "5035" },
    { lng: -72.25602, lat: 44.722453, postalCode: "5875" },
    { lng: -72.97999, lat: 44.304004, postalCode: "5462" },
    { lng: -72.64976, lat: 44.171238, postalCode: "5664" },
    { lng: -73.23273, lat: 43.619787, postalCode: "5750" },
    { lng: -72.78854, lat: 44.772886, postalCode: "5441" },
    { lng: -72.35822, lat: 44.504593, postalCode: "5843" },
    { lng: -72.59048, lat: 43.40422, postalCode: "5142" },
    { lng: -73.21603, lat: 43.521021, postalCode: "5764" },
    { lng: -72.20936, lat: 44.684488, postalCode: "5839" },
    { lng: -73.019801, lat: 44.362504, postalCode: "5466" },
    { lng: -73.13467, lat: 43.357161, postalCode: "5761" },
    { lng: -72.36228, lat: 43.830375, postalCode: "5070" },
    { lng: -72.58502, lat: 44.02591, postalCode: "5036" },
    { lng: -72.49053, lat: 44.354296, postalCode: "5640" },
    { lng: -72.28652, lat: 44.414576, postalCode: "5647" },
    { lng: -72.93145, lat: 43.735819, postalCode: "5737" },
    { lng: -72.48983, lat: 44.282269, postalCode: "5651" },
    { lng: -72.3082, lat: 43.676947, postalCode: "5088" },
    { lng: -73.172, lat: 44.006024, postalCode: "5753" },
    { lng: -73.25143, lat: 44.204926, postalCode: "5456" },
    { lng: -72.29086, lat: 43.735335, postalCode: "5055" },
    { lng: -72.84497, lat: 43.158484, postalCode: "5155" },
    { lng: -72.244107, lat: 44.562138, postalCode: "5864" },
    { lng: -72.74364, lat: 44.062311, postalCode: "5669" },
    { lng: -72.52693, lat: 44.541415, postalCode: "5657" },
    { lng: -72.559638, lat: 44.19906, postalCode: "5601" },
    { lng: -73.31452, lat: 44.645192, postalCode: "5486" },
    { lng: -72.585018, lat: 44.259518, postalCode: "5620" },
    { lng: -72.49443, lat: 44.194307, postalCode: "5641" },
    { lng: -73.31583, lat: 43.892373, postalCode: "5770" },
    { lng: -72.75062, lat: 42.934798, postalCode: "5351" },
    { lng: -72.895849, lat: 44.824855, postalCode: "5479" },
    { lng: -71.66238, lat: 44.682269, postalCode: "5905" },
    { lng: -73.0328, lat: 43.657716, postalCode: "5765" },
    { lng: -72.8367, lat: 44.184704, postalCode: "5673" },
    { lng: -72.895849, lat: 44.824855, postalCode: "5470" },
    { lng: -73.12311, lat: 43.477827, postalCode: "5757" },
    { lng: -73.25716, lat: 42.797636, postalCode: "5260" },
    { lng: -72.72492, lat: 43.149377, postalCode: "5359" },
    { lng: -73.015358, lat: 44.039878, postalCode: "5433" },
    { lng: -73.166, lat: 44.121825, postalCode: "5472" },
    { lng: -72.83138, lat: 43.768578, postalCode: "5762" },
    { lng: -71.83773, lat: 44.498374, postalCode: "5858" },
    { lng: -73.07824, lat: 43.065891, postalCode: "5252" },
    { lng: -72.21325, lat: 43.795686, postalCode: "5043" },
    { lng: -72.87814, lat: 44.738007, postalCode: "5418" },
    { lng: -72.80748, lat: 43.034152, postalCode: "5355" },
    { lng: -73.055156, lat: 43.026984, postalCode: "5768" },
    { lng: -72.54784, lat: 43.966821, postalCode: "5041" },
    { lng: -71.84683, lat: 44.803005, postalCode: "5846" },
    { lng: -73.03164, lat: 44.224103, postalCode: "5487" },
    { lng: -72.47767, lat: 43.300126, postalCode: "5156" },
    { lng: -73.09077, lat: 44.807555, postalCode: "5478" },
    { lng: -71.972088, lat: 44.503403, postalCode: "5863" },
    { lng: -72.26649, lat: 44.863351, postalCode: "5825" },
    { lng: -73.09376, lat: 44.501991, postalCode: "5452" },
    { lng: -72.89351, lat: 44.961552, postalCode: "5457" },
    { lng: -73.082525, lat: 44.442117, postalCode: "5406" },
    { lng: -72.9751, lat: 44.884125, postalCode: "5483" },
    { lng: -73.13469, lat: 44.641974, postalCode: "5468" },
    { lng: -71.87212, lat: 44.428537, postalCode: "5824" },
    { lng: -72.69222, lat: 42.9445, postalCode: "5362" },
    { lng: -73.01298, lat: 43.602211, postalCode: "5736" },
    { lng: -72.19921, lat: 44.936237, postalCode: "5855" },
    { lng: -73.35144, lat: 43.987529, postalCode: "5734" },
    { lng: -72.011815, lat: 44.6877, postalCode: "5267" },
    { lng: -72.79647, lat: 43.452914, postalCode: "5758" },
    { lng: -72.15478, lat: 44.815511, postalCode: "5860" },
    { lng: -72.02686, lat: 44.541352, postalCode: "5851" },
    { lng: -72.50846, lat: 44.711083, postalCode: "5653" },
    { lng: -73.2857, lat: 43.666758, postalCode: "5743" },
    { lng: -72.26075, lat: 43.884626, postalCode: "5058" },
    { lng: -73.30526, lat: 44.712593, postalCode: "5458" },
    { lng: -73.082525, lat: 44.442117, postalCode: "5449" },
    { lng: -72.585018, lat: 44.259518, postalCode: "5671" },
    { lng: -72.46942, lat: 44.158979, postalCode: "5678" },
    { lng: -72.720362, lat: 42.99467, postalCode: "5302" },
    { lng: -72.96325, lat: 43.617302, postalCode: "5701" },
    { lng: -72.456492, lat: 43.093972, postalCode: "5159" },
    { lng: -72.53053, lat: 43.334787, postalCode: "5150" },
    { lng: -72.138347, lat: 45.005783, postalCode: "5823" },
    { lng: -71.950519, lat: 44.497557, postalCode: "5849" },
    { lng: -71.969, lat: 44.82446, postalCode: "5833" },
    { lng: -73.24838, lat: 42.936801, postalCode: "5257" },
    { lng: -72.6026, lat: 43.735816, postalCode: "5031" },
    { lng: -72.87143, lat: 43.926595, postalCode: "5748" },
    { lng: -72.585018, lat: 44.259518, postalCode: "5604" },
    { lng: -80.86194, lat: 38.653616, postalCode: "26623" },
    { lng: -81.76361, lat: 38.619376, postalCode: "25124" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25339" },
    { lng: -81.25093, lat: 37.625307, postalCode: "25857" },
    { lng: -79.79533, lat: 39.561031, postalCode: "26542" },
    { lng: -81.60468, lat: 38.211477, postalCode: "25107" },
    { lng: -81.214825, lat: 37.7517, postalCode: "25873" },
    { lng: -81.17444, lat: 37.72329, postalCode: "25847" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25360" },
    { lng: -81.55299, lat: 39.076628, postalCode: "26169" },
    { lng: -80.24361, lat: 38.66362, postalCode: "26230" },
    { lng: -81.71704, lat: 37.997105, postalCode: "25142" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25332" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25324" },
    { lng: -80.86182, lat: 39.095644, postalCode: "26325" },
    { lng: -81.23769, lat: 37.733391, postalCode: "25827" },
    { lng: -81.16135, lat: 39.228729, postalCode: "26337" },
    { lng: -79.836473, lat: 39.680786, postalCode: "26507" },
    { lng: -81.35285, lat: 38.186871, postalCode: "25126" },
    { lng: -80.44338, lat: 37.665867, postalCode: "24974" },
    { lng: -80.64803, lat: 38.330842, postalCode: "26205" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25723" },
    { lng: -80.249302, lat: 39.280867, postalCode: "26630" },
    { lng: -80.58925, lat: 40.519953, postalCode: "26047" },
    { lng: -80.73186, lat: 39.287254, postalCode: "26436" },
    { lng: -82.11034, lat: 38.805615, postalCode: "25106" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25325" },
    { lng: -78.97189, lat: 39.038183, postalCode: "26836" },
    { lng: -79.79796, lat: 38.412244, postalCode: "24944" },
    { lng: -80.218538, lat: 39.514343, postalCode: "26555" },
    { lng: -81.180561, lat: 37.800481, postalCode: "25919" },
    { lng: -80.55325, lat: 37.841919, postalCode: "24916" },
    { lng: -78.02742, lat: 39.368967, postalCode: "25428" },
    { lng: -80.80006, lat: 38.833295, postalCode: "26611" },
    { lng: -81.88769, lat: 38.268079, postalCode: "25564" },
    { lng: -78.37183, lat: 39.571804, postalCode: "25422" },
    { lng: -81.497865, lat: 39.218276, postalCode: "26120" },
    { lng: -81.10364, lat: 37.728559, postalCode: "25832" },
    { lng: -81.28865, lat: 38.199924, postalCode: "25036" },
    { lng: -81.99936, lat: 37.828926, postalCode: "25653" },
    { lng: -80.10866, lat: 39.525505, postalCode: "26586" },
    { lng: -78.43726, lat: 39.500633, postalCode: "25434" },
    { lng: -79.89012, lat: 39.607447, postalCode: "26531" },
    { lng: -81.78924, lat: 37.950033, postalCode: "25047" },
    { lng: -77.807994, lat: 39.43028, postalCode: "25543" },
    { lng: -79.5003, lat: 39.149624, postalCode: "26292" },
    { lng: -80.91878, lat: 38.786153, postalCode: "26634" },
    { lng: -81.42024, lat: 37.949813, postalCode: "25048" },
    { lng: -80.92051, lat: 37.950115, postalCode: "25831" },
    { lng: -81.59684, lat: 37.444465, postalCode: "24842" },
    { lng: -80.575379, lat: 39.279818, postalCode: "26375" },
    { lng: -82.44551, lat: 38.30329, postalCode: "25535" },
    { lng: -80.1664, lat: 39.5017, postalCode: "26559" },
    { lng: -81.98755, lat: 37.76662, postalCode: "25612" },
    { lng: -81.34526, lat: 38.293438, postalCode: "25132" },
    { lng: -81.94543, lat: 38.229352, postalCode: "25573" },
    { lng: -80.5902, lat: 38.364123, postalCode: "26208" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25720" },
    { lng: -81.83274, lat: 38.906099, postalCode: "25262" },
    { lng: -79.54723, lat: 38.810555, postalCode: "26296" },
    { lng: -79.99344, lat: 38.422376, postalCode: "26209" },
    { lng: -80.02419, lat: 39.155551, postalCode: "26416" },
    { lng: -81.08555, lat: 38.38338, postalCode: "25019" },
    { lng: -81.081333, lat: 37.698351, postalCode: "25898" },
    { lng: -82.08584, lat: 38.280976, postalCode: "25523" },
    { lng: -79.59847, lat: 39.646876, postalCode: "26525" },
    { lng: -81.63028, lat: 38.350647, postalCode: "25301" },
    { lng: -81.42115, lat: 38.879098, postalCode: "25270" },
    { lng: -80.30851, lat: 39.48057, postalCode: "26572" },
    { lng: -81.064784, lat: 38.041293, postalCode: "25931" },
    { lng: -81.90281, lat: 38.743247, postalCode: "25123" },
    { lng: -82.34523, lat: 38.20651, postalCode: "25512" },
    { lng: -80.6844, lat: 39.051863, postalCode: "26321" },
    { lng: -81.61919, lat: 37.842289, postalCode: "25010" },
    { lng: -82.5456, lat: 38.101664, postalCode: "25514" },
    { lng: -80.99175, lat: 37.906682, postalCode: "25864" },
    { lng: -79.82003, lat: 39.497676, postalCode: "26520" },
    { lng: -79.74614, lat: 39.019639, postalCode: "26276" },
    { lng: -80.80217, lat: 37.878258, postalCode: "25986" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25711" },
    { lng: -82.13535, lat: 38.440316, postalCode: "25541" },
    { lng: -82.14774, lat: 37.627833, postalCode: "25688" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25721" },
    { lng: -81.80248, lat: 37.788146, postalCode: "25607" },
    { lng: -81.42028, lat: 38.214254, postalCode: "25086" },
    { lng: -81.44585, lat: 39.387718, postalCode: "26187" },
    { lng: -81.03747, lat: 38.557141, postalCode: "25113" },
    { lng: -81.19958, lat: 37.79064, postalCode: "25801" },
    { lng: -81.69541, lat: 37.291157, postalCode: "24894" },
    { lng: -81.13917, lat: 38.977792, postalCode: "26136" },
    { lng: -80.539672, lat: 37.552029, postalCode: "24942" },
    { lng: -78.779, lat: 39.084895, postalCode: "26801" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25727" },
    { lng: -81.22628, lat: 37.431153, postalCode: "24733" },
    { lng: -81.41706, lat: 37.418471, postalCode: "24868" },
    { lng: -81.229646, lat: 37.264098, postalCode: "24704" },
    { lng: -80.79867, lat: 39.768143, postalCode: "26055" },
    { lng: -81.79548, lat: 37.469496, postalCode: "24844" },
    { lng: -81.79553, lat: 37.530418, postalCode: "24845" },
    { lng: -81.62346, lat: 38.069687, postalCode: "25181" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24826" },
    { lng: -81.27334, lat: 38.679327, postalCode: "25259" },
    { lng: -81.07307, lat: 37.368167, postalCode: "24740" },
    { lng: -77.83296, lat: 39.358159, postalCode: "25442" },
    { lng: -82.37574, lat: 37.860845, postalCode: "25674" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25147" },
    { lng: -78.92736, lat: 39.248438, postalCode: "26852" },
    { lng: -81.14995, lat: 37.94251, postalCode: "25855" },
    { lng: -81.85711, lat: 37.690439, postalCode: "25611" },
    { lng: -77.95767, lat: 39.463781, postalCode: "25401" },
    { lng: -81.118491, lat: 37.784459, postalCode: "25004" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24899" },
    { lng: -80.83465, lat: 39.646099, postalCode: "26155" },
    { lng: -78.38374, lat: 39.344466, postalCode: "26817" },
    { lng: -81.90186, lat: 37.625805, postalCode: "25621" },
    { lng: -82.41924, lat: 38.421443, postalCode: "25703" },
    { lng: -80.467765, lat: 39.03525, postalCode: "26462" },
    { lng: -79.43805, lat: 39.101535, postalCode: "26260" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25776" },
    { lng: -81.84485, lat: 38.327452, postalCode: "25202" },
    { lng: -81.56282, lat: 38.131663, postalCode: "25049" },
    { lng: -81.84153, lat: 38.152035, postalCode: "25529" },
    { lng: -78.62152, lat: 39.18253, postalCode: "26714" },
    { lng: -79.54326, lat: 39.454805, postalCode: "26764" },
    { lng: -81.24459, lat: 38.939317, postalCode: "26141" },
    { lng: -81.12827, lat: 38.806907, postalCode: "25234" },
    { lng: -79.89057, lat: 38.827205, postalCode: "26253" },
    { lng: -81.53788, lat: 39.162229, postalCode: "26150" },
    { lng: -81.87853, lat: 37.729265, postalCode: "25635" },
    { lng: -82.431667, lat: 38.422115, postalCode: "25755" },
    { lng: -81.064784, lat: 38.041293, postalCode: "25912" },
    { lng: -82.00011, lat: 37.833826, postalCode: "25601" },
    { lng: -80.33566, lat: 39.328064, postalCode: "26369" },
    { lng: -81.39427, lat: 38.202669, postalCode: "25103" },
    { lng: -79.42206, lat: 38.844294, postalCode: "26886" },
    { lng: -81.398422, lat: 38.495072, postalCode: "25079" },
    { lng: -77.86203, lat: 39.279965, postalCode: "25414" },
    { lng: -81.11241, lat: 37.648813, postalCode: "25825" },
    { lng: -81.50968, lat: 37.365675, postalCode: "24888" },
    { lng: -81.40218, lat: 38.971224, postalCode: "26160" },
    { lng: -79.33975, lat: 38.65585, postalCode: "26807" },
    { lng: -80.599949, lat: 40.27932, postalCode: "26058" },
    { lng: -81.44608, lat: 37.68344, postalCode: "25875" },
    { lng: -80.35233, lat: 39.440245, postalCode: "26463" },
    { lng: -81.99083, lat: 39.005918, postalCode: "25247" },
    { lng: -80.27564, lat: 39.422397, postalCode: "26568" },
    { lng: -80.226855, lat: 38.899314, postalCode: "26229" },
    { lng: -80.54184, lat: 37.667594, postalCode: "24976" },
    { lng: -82.14416, lat: 37.705946, postalCode: "25670" },
    { lng: -80.71636, lat: 37.620418, postalCode: "24919" },
    { lng: -81.167549, lat: 37.758469, postalCode: "25911" },
    { lng: -81.79515, lat: 38.49008, postalCode: "25159" },
    { lng: -82.02759, lat: 38.72218, postalCode: "25187" },
    { lng: -77.81132, lat: 39.434479, postalCode: "25443" },
    { lng: -81.67951, lat: 37.742953, postalCode: "24827" },
    { lng: -79.98029, lat: 38.671662, postalCode: "26273" },
    { lng: -81.46602, lat: 38.451344, postalCode: "25071" },
    { lng: -81.6863, lat: 38.362374, postalCode: "25303" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25772" },
    { lng: -82.57461, lat: 38.385975, postalCode: "25530" },
    { lng: -77.95747, lat: 39.247155, postalCode: "25446" },
    { lng: -80.61391, lat: 38.224393, postalCode: "26202" },
    { lng: -80.36331, lat: 37.918994, postalCode: "24938" },
    { lng: -81.21276, lat: 38.149058, postalCode: "25090" },
    { lng: -78.49576, lat: 39.13982, postalCode: "26823" },
    { lng: -79.90371, lat: 39.329797, postalCode: "26440" },
    { lng: -80.84199, lat: 37.854735, postalCode: "25976" },
    { lng: -82.26827, lat: 37.705144, postalCode: "25661" },
    { lng: -79.967184, lat: 39.625302, postalCode: "26502" },
    { lng: -80.426874, lat: 37.975595, postalCode: "25961" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25350" },
    { lng: -81.576548, lat: 37.405828, postalCode: "24841" },
    { lng: -82.22311, lat: 38.44068, postalCode: "25545" },
    { lng: -82.12468, lat: 38.613255, postalCode: "25503" },
    { lng: -81.3169, lat: 37.764498, postalCode: "25932" },
    { lng: -81.51746, lat: 37.562494, postalCode: "24874" },
    { lng: -80.78465, lat: 37.424195, postalCode: "24963" },
    { lng: -80.48027, lat: 38.001124, postalCode: "24991" },
    { lng: -80.461492, lat: 37.774666, postalCode: "24902" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25365" },
    { lng: -81.16885, lat: 38.885826, postalCode: "26151" },
    { lng: -80.4595, lat: 38.60286, postalCode: "26217" },
    { lng: -80.67497, lat: 38.641439, postalCode: "26601" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25334" },
    { lng: -80.92668, lat: 39.183473, postalCode: "26421" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25728" },
    { lng: -81.5314, lat: 37.687988, postalCode: "25845" },
    { lng: -80.42691, lat: 39.106153, postalCode: "26378" },
    { lng: -80.27872, lat: 38.857567, postalCode: "26218" },
    { lng: -81.63578, lat: 38.143324, postalCode: "25024" },
    { lng: -80.55893, lat: 40.609272, postalCode: "26034" },
    { lng: -81.31644, lat: 37.63215, postalCode: "25853" },
    { lng: -81.81897, lat: 37.733955, postalCode: "25617" },
    { lng: -81.81583, lat: 37.957458, postalCode: "25149" },
    { lng: -81.38363, lat: 37.422523, postalCode: "24897" },
    { lng: -82.58178, lat: 38.238942, postalCode: "25555" },
    { lng: -82.06562, lat: 38.399499, postalCode: "25510" },
    { lng: -80.218538, lat: 39.514343, postalCode: "26560" },
    { lng: -81.19273, lat: 37.88169, postalCode: "25880" },
    { lng: -81.48654, lat: 37.427781, postalCode: "24853" },
    { lng: -80.55585, lat: 40.352943, postalCode: "26035" },
    { lng: -80.46172, lat: 38.766855, postalCode: "26376" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25356" },
    { lng: -80.7889, lat: 37.966816, postalCode: "25962" },
    { lng: -81.73418, lat: 38.329768, postalCode: "25309" },
    { lng: -80.44192, lat: 39.646936, postalCode: "26562" },
    { lng: -80.385344, lat: 39.285204, postalCode: "26461" },
    { lng: -81.24131, lat: 37.715335, postalCode: "25878" },
    { lng: -81.384755, lat: 37.793057, postalCode: "25180" },
    { lng: -80.75393, lat: 37.838288, postalCode: "24943" },
    { lng: -81.41415, lat: 37.385757, postalCode: "24831" },
    { lng: -79.54714, lat: 39.327576, postalCode: "26705" },
    { lng: -81.497865, lat: 39.218276, postalCode: "26102" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25774" },
    { lng: -80.23405, lat: 39.642393, postalCode: "26570" },
    { lng: -80.67752, lat: 39.835605, postalCode: "26039" },
    { lng: -81.18787, lat: 39.03594, postalCode: "26138" },
    { lng: -81.66048, lat: 37.683273, postalCode: "24857" },
    { lng: -79.53066, lat: 38.915141, postalCode: "26270" },
    { lng: -81.53722, lat: 37.741813, postalCode: "24854" },
    { lng: -77.77982, lat: 39.272324, postalCode: "25425" },
    { lng: -81.25595, lat: 39.173299, postalCode: "26161" },
    { lng: -81.15174, lat: 37.927809, postalCode: "25846" },
    { lng: -78.04157, lat: 39.323628, postalCode: "25413" },
    { lng: -82.28563, lat: 38.391209, postalCode: "25504" },
    { lng: -80.79366, lat: 38.704457, postalCode: "26624" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25362" },
    { lng: -80.39794, lat: 38.663739, postalCode: "26222" },
    { lng: -81.80117, lat: 38.792125, postalCode: "25241" },
    { lng: -80.13097, lat: 39.549346, postalCode: "26588" },
    { lng: -81.39537, lat: 38.617139, postalCode: "25286" },
    { lng: -82.10801, lat: 37.743063, postalCode: "25623" },
    { lng: -80.426874, lat: 37.975595, postalCode: "25967" },
    { lng: -81.33086, lat: 37.576978, postalCode: "25928" },
    { lng: -80.02665, lat: 39.343418, postalCode: "26354" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25122" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25333" },
    { lng: -81.47577, lat: 37.958702, postalCode: "25060" },
    { lng: -80.53003, lat: 39.974547, postalCode: "26036" },
    { lng: -81.14078, lat: 37.404113, postalCode: "24731" },
    { lng: -79.25455, lat: 38.98645, postalCode: "26855" },
    { lng: -80.586148, lat: 38.620506, postalCode: "26612" },
    { lng: -82.02827, lat: 38.621761, postalCode: "25082" },
    { lng: -82.29414, lat: 37.966899, postalCode: "25699" },
    { lng: -81.69288, lat: 37.416947, postalCode: "24824" },
    { lng: -80.76956, lat: 39.080329, postalCode: "26443" },
    { lng: -82.16397, lat: 37.617949, postalCode: "25678" },
    { lng: -79.69873, lat: 39.472924, postalCode: "26537" },
    { lng: -81.942704, lat: 37.986266, postalCode: "25203" },
    { lng: -81.23911, lat: 37.663234, postalCode: "25908" },
    { lng: -82.43904, lat: 38.403511, postalCode: "25701" },
    { lng: -82.27824, lat: 37.703827, postalCode: "25667" },
    { lng: -81.87113, lat: 37.801671, postalCode: "25654" },
    { lng: -82.191, lat: 38.214232, postalCode: "25506" },
    { lng: -80.47151, lat: 37.742719, postalCode: "24970" },
    { lng: -80.870081, lat: 39.450612, postalCode: "26434" },
    { lng: -82.06678, lat: 37.852728, postalCode: "25649" },
    { lng: -81.41815, lat: 37.714713, postalCode: "25848" },
    { lng: -80.86867, lat: 37.671295, postalCode: "25951" },
    { lng: -80.736884, lat: 38.706017, postalCode: "26618" },
    { lng: -77.877223, lat: 39.315914, postalCode: "25410" },
    { lng: -81.52639, lat: 39.285724, postalCode: "26104" },
    { lng: -79.933496, lat: 39.635272, postalCode: "26905" },
    { lng: -79.80521, lat: 39.509001, postalCode: "26547" },
    { lng: -80.54634, lat: 40.104818, postalCode: "26060" },
    { lng: -77.93415, lat: 39.349476, postalCode: "25430" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25182" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25724" },
    { lng: -81.36076, lat: 37.933384, postalCode: "25008" },
    { lng: -81.57309, lat: 37.666079, postalCode: "24849" },
    { lng: -80.72899, lat: 37.654267, postalCode: "24981" },
    { lng: -82.27267, lat: 37.818843, postalCode: "25676" },
    { lng: -81.95406, lat: 38.61324, postalCode: "25033" },
    { lng: -81.06189, lat: 39.270902, postalCode: "26346" },
    { lng: -81.66789, lat: 37.345693, postalCode: "24821" },
    { lng: -81.105803, lat: 37.416395, postalCode: "24738" },
    { lng: -79.95201, lat: 38.97639, postalCode: "26275" },
    { lng: -80.831256, lat: 38.913649, postalCode: "25258" },
    { lng: -81.18833, lat: 37.653792, postalCode: "25856" },
    { lng: -81.54688, lat: 37.352131, postalCode: "24836" },
    { lng: -81.56342, lat: 37.381834, postalCode: "24895" },
    { lng: -80.86438, lat: 37.772844, postalCode: "25985" },
    { lng: -80.1442, lat: 39.091657, postalCode: "26238" },
    { lng: -78.02628, lat: 39.442661, postalCode: "25440" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25713" },
    { lng: -81.78312, lat: 37.399793, postalCode: "24811" },
    { lng: -81.58564, lat: 38.313411, postalCode: "25304" },
    { lng: -81.53998, lat: 37.603344, postalCode: "24847" },
    { lng: -78.55592, lat: 39.50219, postalCode: "25431" },
    { lng: -81.22148, lat: 37.501743, postalCode: "24714" },
    { lng: -80.3464, lat: 37.602172, postalCode: "24941" },
    { lng: -81.62565, lat: 38.530623, postalCode: "25320" },
    { lng: -80.72476, lat: 38.07743, postalCode: "25981" },
    { lng: -81.77755, lat: 38.18125, postalCode: "25169" },
    { lng: -78.77489, lat: 39.616008, postalCode: "26767" },
    { lng: -81.8885, lat: 37.530963, postalCode: "24846" },
    { lng: -81.5432, lat: 37.319439, postalCode: "24830" },
    { lng: -81.03591, lat: 37.67906, postalCode: "25989" },
    { lng: -80.77811, lat: 39.293095, postalCode: "26456" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25707" },
    { lng: -81.53807, lat: 38.884, postalCode: "25252" },
    { lng: -81.53998, lat: 37.603344, postalCode: "25876" },
    { lng: -81.61753, lat: 38.732716, postalCode: "25279" },
    { lng: -81.99598, lat: 38.106045, postalCode: "25565" },
    { lng: -81.639389, lat: 38.354041, postalCode: "25389" },
    { lng: -81.30575, lat: 38.176382, postalCode: "25186" },
    { lng: -78.73978, lat: 38.973027, postalCode: "26810" },
    { lng: -81.11622, lat: 37.504558, postalCode: "25820" },
    { lng: -80.86864, lat: 38.163524, postalCode: "26684" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25712" },
    { lng: -80.90285, lat: 38.690755, postalCode: "26641" },
    { lng: -81.96519, lat: 37.878311, postalCode: "25639" },
    { lng: -81.57934, lat: 38.597265, postalCode: "25231" },
    { lng: -82.55881, lat: 38.396081, postalCode: "25507" },
    { lng: -81.54396, lat: 38.19411, postalCode: "25214" },
    { lng: -80.968967, lat: 39.288336, postalCode: "26145" },
    { lng: -80.80897, lat: 38.1755, postalCode: "26679" },
    { lng: -81.99433, lat: 37.808757, postalCode: "25636" },
    { lng: -81.72789, lat: 38.977445, postalCode: "26164" },
    { lng: -80.6091, lat: 40.063895, postalCode: "26059" },
    { lng: -78.91724, lat: 39.335771, postalCode: "26710" },
    { lng: -81.224458, lat: 37.748935, postalCode: "25802" },
    { lng: -80.29679, lat: 38.480062, postalCode: "26298" },
    { lng: -81.16398, lat: 38.391188, postalCode: "25111" },
    { lng: -81.5967, lat: 37.664685, postalCode: "24860" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25771" },
    { lng: -81.55661, lat: 38.23753, postalCode: "25315" },
    { lng: -81.89583, lat: 37.357831, postalCode: "24873" },
    { lng: -81.72566, lat: 38.179811, postalCode: "25009" },
    { lng: -81.06673, lat: 37.855763, postalCode: "25907" },
    { lng: -81.82633, lat: 38.378841, postalCode: "25177" },
    { lng: -81.70438, lat: 38.161661, postalCode: "25051" },
    { lng: -81.19244, lat: 37.865746, postalCode: "25818" },
    { lng: -81.38164, lat: 38.324795, postalCode: "25025" },
    { lng: -81.19769, lat: 38.323058, postalCode: "25125" },
    { lng: -80.74656, lat: 38.891042, postalCode: "26430" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25321" },
    { lng: -81.093678, lat: 37.848955, postalCode: "25934" },
    { lng: -80.3709, lat: 39.378137, postalCode: "26386" },
    { lng: -82.21671, lat: 37.863118, postalCode: "25671" },
    { lng: -81.392845, lat: 38.490789, postalCode: "25026" },
    { lng: -80.27183, lat: 39.480838, postalCode: "26563" },
    { lng: -80.14368, lat: 38.518735, postalCode: "26282" },
    { lng: -81.40244, lat: 37.343452, postalCode: "24856" },
    { lng: -81.05091, lat: 39.462447, postalCode: "26146" },
    { lng: -80.443556, lat: 37.792525, postalCode: "24971" },
    { lng: -81.62048, lat: 38.631892, postalCode: "25248" },
    { lng: -81.784532, lat: 37.790435, postalCode: "25631" },
    { lng: -81.00955, lat: 37.630988, postalCode: "25969" },
    { lng: -81.70763, lat: 38.803814, postalCode: "25271" },
    { lng: -80.71163, lat: 39.550395, postalCode: "26167" },
    { lng: -81.105803, lat: 37.416395, postalCode: "24729" },
    { lng: -79.688154, lat: 39.458091, postalCode: "26535" },
    { lng: -81.8321, lat: 38.417852, postalCode: "25143" },
    { lng: -82.12506, lat: 38.159971, postalCode: "25540" },
    { lng: -81.064784, lat: 38.041293, postalCode: "25859" },
    { lng: -81.66084, lat: 38.335969, postalCode: "25314" },
    { lng: -82.34373, lat: 37.786897, postalCode: "25685" },
    { lng: -81.82027, lat: 37.75979, postalCode: "25606" },
    { lng: -79.67466, lat: 39.141165, postalCode: "26287" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25725" },
    { lng: -80.74994, lat: 39.951586, postalCode: "26038" },
    { lng: -79.82219, lat: 39.067689, postalCode: "26283" },
    { lng: -81.53998, lat: 37.603344, postalCode: "24859" },
    { lng: -81.314633, lat: 38.731737, postalCode: "25256" },
    { lng: -79.33844, lat: 39.280652, postalCode: "26720" },
    { lng: -80.35527, lat: 39.163304, postalCode: "26385" },
    { lng: -82.418277, lat: 38.132114, postalCode: "25709" },
    { lng: -80.98148, lat: 38.268633, postalCode: "26667" },
    { lng: -80.13936, lat: 39.470949, postalCode: "26554" },
    { lng: -81.43081, lat: 37.338242, postalCode: "24808" },
    { lng: -81.56514, lat: 38.282497, postalCode: "25112" },
    { lng: -81.50071, lat: 37.677563, postalCode: "25916" },
    { lng: -80.38231, lat: 38.753031, postalCode: "26228" },
    { lng: -81.84577, lat: 37.72738, postalCode: "25634" },
    { lng: -81.28534, lat: 38.024108, postalCode: "25119" },
    { lng: -80.86627, lat: 38.462053, postalCode: "25211" },
    { lng: -80.94056, lat: 38.583094, postalCode: "25063" },
    { lng: -81.06772, lat: 38.991483, postalCode: "26137" },
    { lng: -81.69961, lat: 37.474577, postalCode: "24843" },
    { lng: -80.93276, lat: 39.604088, postalCode: "26159" },
    { lng: -81.09537, lat: 37.557975, postalCode: "25841" },
    { lng: -80.719742, lat: 39.270846, postalCode: "26328" },
    { lng: -81.27886, lat: 39.374773, postalCode: "26134" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24883" },
    { lng: -81.29736, lat: 37.48929, postalCode: "24719" },
    { lng: -81.77969, lat: 37.567007, postalCode: "24839" },
    { lng: -79.63241, lat: 39.539088, postalCode: "26519" },
    { lng: -80.73048, lat: 39.987177, postalCode: "26040" },
    { lng: -80.57981, lat: 38.790368, postalCode: "26631" },
    { lng: -79.95154, lat: 39.646497, postalCode: "26505" },
    { lng: -80.20915, lat: 38.997267, postalCode: "26201" },
    { lng: -81.997278, lat: 38.752312, postalCode: "25250" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24889" },
    { lng: -80.73336, lat: 37.675571, postalCode: "24962" },
    { lng: -80.9435, lat: 38.74076, postalCode: "26636" },
    { lng: -81.9827, lat: 37.904732, postalCode: "25624" },
    { lng: -80.426874, lat: 37.975595, postalCode: "24917" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25329" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25777" },
    { lng: -81.224458, lat: 37.748935, postalCode: "25927" },
    { lng: -80.5648, lat: 38.891517, postalCode: "26412" },
    { lng: -81.5151, lat: 38.224918, postalCode: "25015" },
    { lng: -80.74821, lat: 38.048182, postalCode: "25972" },
    { lng: -80.76748, lat: 37.515724, postalCode: "24918" },
    { lng: -81.6196, lat: 38.926713, postalCode: "25275" },
    { lng: -80.59452, lat: 37.901788, postalCode: "24931" },
    { lng: -81.85414, lat: 38.219996, postalCode: "25567" },
    { lng: -79.13703, lat: 38.959188, postalCode: "26847" },
    { lng: -80.67026, lat: 38.408153, postalCode: "26691" },
    { lng: -80.17617, lat: 39.558702, postalCode: "26574" },
    { lng: -81.34327, lat: 38.212287, postalCode: "25110" },
    { lng: -81.53106, lat: 38.310827, postalCode: "25306" },
    { lng: -80.72573, lat: 38.836102, postalCode: "26615" },
    { lng: -81.49308, lat: 37.568981, postalCode: "24867" },
    { lng: -81.10403, lat: 38.138419, postalCode: "25812" },
    { lng: -81.2145, lat: 37.2757, postalCode: "24701" },
    { lng: -80.00402, lat: 38.861656, postalCode: "26278" },
    { lng: -80.539672, lat: 37.552029, postalCode: "24993" },
    { lng: -81.42991, lat: 37.832443, postalCode: "25007" },
    { lng: -81.314633, lat: 38.731737, postalCode: "25046" },
    { lng: -82.06382, lat: 37.827404, postalCode: "25625" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25722" },
    { lng: -81.53625, lat: 37.641027, postalCode: "24880" },
    { lng: -81.60845, lat: 37.69629, postalCode: "24870" },
    { lng: -81.105803, lat: 37.416395, postalCode: "24751" },
    { lng: -79.63227, lat: 38.918159, postalCode: "26254" },
    { lng: -82.01954, lat: 37.962522, postalCode: "25508" },
    { lng: -80.73291, lat: 38.788013, postalCode: "26619" },
    { lng: -81.2457, lat: 38.072309, postalCode: "25173" },
    { lng: -81.57835, lat: 37.424498, postalCode: "24801" },
    { lng: -81.40762, lat: 39.070067, postalCode: "26143" },
    { lng: -81.29088, lat: 38.143496, postalCode: "25139" },
    { lng: -80.87329, lat: 38.30696, postalCode: "26651" },
    { lng: -82.36995, lat: 38.410745, postalCode: "25705" },
    { lng: -81.68729, lat: 37.922612, postalCode: "25021" },
    { lng: -79.23785, lat: 39.248761, postalCode: "26739" },
    { lng: -80.1652, lat: 39.268207, postalCode: "26424" },
    { lng: -80.38023, lat: 38.484777, postalCode: "26288" },
    { lng: -80.99151, lat: 38.128245, postalCode: "25854" },
    { lng: -79.37151, lat: 38.849407, postalCode: "26884" },
    { lng: -78.88245, lat: 38.878002, postalCode: "26812" },
    { lng: -81.18722, lat: 39.065862, postalCode: "26148" },
    { lng: -79.53068, lat: 38.626289, postalCode: "26804" },
    { lng: -81.19697, lat: 38.260339, postalCode: "25059" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25718" },
    { lng: -81.613794, lat: 38.335647, postalCode: "25317" },
    { lng: -81.66169, lat: 37.478583, postalCode: "24828" },
    { lng: -79.97335, lat: 39.69234, postalCode: "26541" },
    { lng: -79.96518, lat: 38.934414, postalCode: "26285" },
    { lng: -80.47432, lat: 38.881069, postalCode: "26447" },
    { lng: -81.902401, lat: 37.833204, postalCode: "25645" },
    { lng: -80.87533, lat: 39.481247, postalCode: "26149" },
    { lng: -82.10801, lat: 37.743063, postalCode: "25686" },
    { lng: -80.40294, lat: 39.204169, postalCode: "26451" },
    { lng: -81.46549, lat: 39.2059, postalCode: "26142" },
    { lng: -81.282759, lat: 37.802469, postalCode: "25851" },
    { lng: -81.768, lat: 37.35294, postalCode: "24879" },
    { lng: -81.43135, lat: 37.296401, postalCode: "24848" },
    { lng: -81.27625, lat: 37.425866, postalCode: "24736" },
    { lng: -81.34244, lat: 38.780168, postalCode: "25276" },
    { lng: -81.02943, lat: 39.059792, postalCode: "26178" },
    { lng: -77.877223, lat: 39.315914, postalCode: "25423" },
    { lng: -82.02174, lat: 37.849418, postalCode: "25637" },
    { lng: -80.16451, lat: 39.707009, postalCode: "26544" },
    { lng: -81.7733, lat: 38.021082, postalCode: "25205" },
    { lng: -79.962669, lat: 39.645276, postalCode: "26506" },
    { lng: -81.32536, lat: 37.737104, postalCode: "25865" },
    { lng: -81.84134, lat: 38.508675, postalCode: "25011" },
    { lng: -81.76168, lat: 38.425721, postalCode: "25313" },
    { lng: -81.84229, lat: 37.551372, postalCode: "24869" },
    { lng: -80, lat: 38.736144, postalCode: "26280" },
    { lng: -81.54206, lat: 39.323907, postalCode: "26105" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25717" },
    { lng: -80.95735, lat: 37.478519, postalCode: "25971" },
    { lng: -81.15739, lat: 38.504506, postalCode: "25150" },
    { lng: -82.16003, lat: 38.764623, postalCode: "25515" },
    { lng: -79.21669, lat: 38.633148, postalCode: "26802" },
    { lng: -81.612344, lat: 38.335798, postalCode: "25305" },
    { lng: -80.97408, lat: 39.547803, postalCode: "26175" },
    { lng: -80.57219, lat: 39.284467, postalCode: "26426" },
    { lng: -79.8446, lat: 38.920042, postalCode: "26241" },
    { lng: -82.15143, lat: 38.106001, postalCode: "25557" },
    { lng: -81.39377, lat: 38.242575, postalCode: "25039" },
    { lng: -80.00221, lat: 38.916124, postalCode: "26257" },
    { lng: -81.10724, lat: 37.618115, postalCode: "25843" },
    { lng: -81.47783, lat: 38.665227, postalCode: "25243" },
    { lng: -80.666712, lat: 39.575518, postalCode: "26561" },
    { lng: -81.06238, lat: 38.079509, postalCode: "25862" },
    { lng: -80.69639, lat: 38.355607, postalCode: "26660" },
    { lng: -80.09781, lat: 38.444873, postalCode: "26291" },
    { lng: -81.86082, lat: 38.05738, postalCode: "25053" },
    { lng: -82.12662, lat: 38.669561, postalCode: "25502" },
    { lng: -80.6868, lat: 40.071472, postalCode: "26003" },
    { lng: -81.8013, lat: 37.358272, postalCode: "24817" },
    { lng: -81.20064, lat: 38.136263, postalCode: "25115" },
    { lng: -80.426874, lat: 37.975595, postalCode: "24958" },
    { lng: -80.33785, lat: 39.380098, postalCode: "26366" },
    { lng: -81.38475, lat: 38.209912, postalCode: "25162" },
    { lng: -80.99702, lat: 38.040215, postalCode: "25942" },
    { lng: -80.80017, lat: 37.549702, postalCode: "24935" },
    { lng: -81.3639, lat: 38.186707, postalCode: "25102" },
    { lng: -80.59518, lat: 38.520078, postalCode: "26203" },
    { lng: -80.3159, lat: 38.792214, postalCode: "26234" },
    { lng: -79.80308, lat: 38.462522, postalCode: "24915" },
    { lng: -80.22808, lat: 38.114627, postalCode: "24946" },
    { lng: -80.86928, lat: 38.55291, postalCode: "26639" },
    { lng: -81.22913, lat: 38.618255, postalCode: "25251" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25719" },
    { lng: -80.831256, lat: 38.913649, postalCode: "26350" },
    { lng: -82.12599, lat: 37.669222, postalCode: "25696" },
    { lng: -81.53998, lat: 37.603344, postalCode: "25811" },
    { lng: -81.31241, lat: 38.084773, postalCode: "25161" },
    { lng: -78.69317, lat: 39.291196, postalCode: "26761" },
    { lng: -81.03387, lat: 38.048578, postalCode: "25837" },
    { lng: -81.064784, lat: 38.041293, postalCode: "25833" },
    { lng: -81.12923, lat: 38.566365, postalCode: "25285" },
    { lng: -82.10801, lat: 37.743063, postalCode: "25690" },
    { lng: -81.66166, lat: 38.414155, postalCode: "25312" },
    { lng: -81.90315, lat: 38.446225, postalCode: "25560" },
    { lng: -81.11672, lat: 37.467516, postalCode: "25922" },
    { lng: -80.25872, lat: 39.494727, postalCode: "26576" },
    { lng: -80.69604, lat: 37.543667, postalCode: "24945" },
    { lng: -82.27982, lat: 38.534701, postalCode: "25537" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25327" },
    { lng: -81.03691, lat: 38.642203, postalCode: "25141" },
    { lng: -80.61266, lat: 38.767682, postalCode: "26627" },
    { lng: -79.74674, lat: 38.780919, postalCode: "26268" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25770" },
    { lng: -81.95826, lat: 37.925145, postalCode: "25547" },
    { lng: -79.688154, lat: 39.458091, postalCode: "26524" },
    { lng: -81.75668, lat: 37.609388, postalCode: "24882" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25706" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25375" },
    { lng: -81.19842, lat: 38.382145, postalCode: "25088" },
    { lng: -79.23861, lat: 38.814289, postalCode: "26866" },
    { lng: -81.42559, lat: 37.409655, postalCode: "24855" },
    { lng: -81.74473, lat: 38.367255, postalCode: "25064" },
    { lng: -81.0032, lat: 37.448532, postalCode: "24712" },
    { lng: -81.20721, lat: 38.988098, postalCode: "26152" },
    { lng: -80.84886, lat: 38.939952, postalCode: "26351" },
    { lng: -80.95448, lat: 39.122023, postalCode: "26327" },
    { lng: -80.31872, lat: 39.368814, postalCode: "26361" },
    { lng: -82.17566, lat: 38.290859, postalCode: "25571" },
    { lng: -82.10751, lat: 38.87018, postalCode: "25550" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25729" },
    { lng: -81.20968, lat: 37.679582, postalCode: "25823" },
    { lng: -81.53288, lat: 37.978232, postalCode: "25209" },
    { lng: -79.93278, lat: 38.380196, postalCode: "24927" },
    { lng: -81.18846, lat: 38.682668, postalCode: "25281" },
    { lng: -78.12229, lat: 39.379281, postalCode: "25420" },
    { lng: -81.195122, lat: 37.787252, postalCode: "25926" },
    { lng: -80.64293, lat: 37.485348, postalCode: "24951" },
    { lng: -81.55208, lat: 37.249595, postalCode: "24884" },
    { lng: -82.30873, lat: 37.714369, postalCode: "25665" },
    { lng: -81.95921, lat: 37.837136, postalCode: "25646" },
    { lng: -81.24219, lat: 38.552755, postalCode: "25005" },
    { lng: -81.90109, lat: 37.929438, postalCode: "25121" },
    { lng: -80.43872, lat: 39.696744, postalCode: "26575" },
    { lng: -81.53706, lat: 39.265408, postalCode: "26101" },
    { lng: -80.80604, lat: 39.41849, postalCode: "26320" },
    { lng: -77.878957, lat: 39.349586, postalCode: "25429" },
    { lng: -80.34348, lat: 39.281289, postalCode: "26301" },
    { lng: -81.62947, lat: 37.594959, postalCode: "24818" },
    { lng: -81.05325, lat: 39.171784, postalCode: "26362" },
    { lng: -80.44493, lat: 37.80848, postalCode: "24901" },
    { lng: -79.07484, lat: 39.291478, postalCode: "26743" },
    { lng: -81.73002, lat: 37.355111, postalCode: "24813" },
    { lng: -81.497865, lat: 39.218276, postalCode: "26106" },
    { lng: -81.60628, lat: 37.898585, postalCode: "25204" },
    { lng: -81.36639, lat: 39.306083, postalCode: "26184" },
    { lng: -81.70802, lat: 37.80126, postalCode: "25630" },
    { lng: -81.85738, lat: 38.526637, postalCode: "25109" },
    { lng: -82.11462, lat: 38.219991, postalCode: "25544" },
    { lng: -81.85261, lat: 37.960963, postalCode: "25108" },
    { lng: -79.91798, lat: 38.758699, postalCode: "26293" },
    { lng: -81.37275, lat: 37.532524, postalCode: "24716" },
    { lng: -81.453786, lat: 37.854572, postalCode: "25062" },
    { lng: -81.66005, lat: 38.140732, postalCode: "25165" },
    { lng: -81.97414, lat: 38.923595, postalCode: "25253" },
    { lng: -77.8599, lat: 39.300223, postalCode: "25438" },
    { lng: -81.41377, lat: 37.77672, postalCode: "25817" },
    { lng: -80.093007, lat: 39.578512, postalCode: "26522" },
    { lng: -80.72757, lat: 38.012459, postalCode: "25958" },
    { lng: -80.53448, lat: 37.590774, postalCode: "24983" },
    { lng: -81.47554, lat: 37.325163, postalCode: "24871" },
    { lng: -82.1086, lat: 38.02644, postalCode: "25524" },
    { lng: -78.59401, lat: 39.285323, postalCode: "26704" },
    { lng: -81.36495, lat: 37.802995, postalCode: "25844" },
    { lng: -81.068913, lat: 39.198501, postalCode: "26407" },
    { lng: -82.37019, lat: 38.431116, postalCode: "25702" },
    { lng: -78.49655, lat: 39.199856, postalCode: "26865" },
    { lng: -81.17215, lat: 39.370217, postalCode: "26170" },
    { lng: -80.98431, lat: 38.7357, postalCode: "26638" },
    { lng: -80.48869, lat: 39.407957, postalCode: "26448" },
    { lng: -80.58446, lat: 39.81304, postalCode: "26033" },
    { lng: -82.22242, lat: 38.324409, postalCode: "25559" },
    { lng: -79.3654, lat: 39.264409, postalCode: "26707" },
    { lng: -82.49609, lat: 38.386966, postalCode: "25704" },
    { lng: -80.85231, lat: 39.018602, postalCode: "26342" },
    { lng: -81.064784, lat: 38.041293, postalCode: "25914" },
    { lng: -81.89024, lat: 37.45926, postalCode: "24872" },
    { lng: -80.50197, lat: 39.286975, postalCode: "26332" },
    { lng: -81.17428, lat: 38.585331, postalCode: "25266" },
    { lng: -80.92422, lat: 37.645397, postalCode: "25978" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25338" },
    { lng: -80.75434, lat: 38.245298, postalCode: "26662" },
    { lng: -80.07907, lat: 38.912609, postalCode: "26267" },
    { lng: -80.88462, lat: 38.090457, postalCode: "26680" },
    { lng: -81.19156, lat: 38.48444, postalCode: "25164" },
    { lng: -79.07088, lat: 39.153987, postalCode: "26731" },
    { lng: -81.56035, lat: 38.534661, postalCode: "25322" },
    { lng: -79.04681, lat: 39.476243, postalCode: "26750" },
    { lng: -79.7315, lat: 38.552106, postalCode: "24920" },
    { lng: -79.233536, lat: 39.070601, postalCode: "26734" },
    { lng: -81.42001, lat: 37.67337, postalCode: "25870" },
    { lng: -79.87873, lat: 39.436084, postalCode: "26374" },
    { lng: -80.034965, lat: 39.674575, postalCode: "26546" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24877" },
    { lng: -81.064784, lat: 38.041293, postalCode: "25185" },
    { lng: -80.657019, lat: 38.859246, postalCode: "26333" },
    { lng: -78.76381, lat: 39.497698, postalCode: "26719" },
    { lng: -81.22481, lat: 38.453331, postalCode: "25030" },
    { lng: -81.60347, lat: 37.585536, postalCode: "24898" },
    { lng: -78.659428, lat: 39.314071, postalCode: "26824" },
    { lng: -78.6218, lat: 39.066543, postalCode: "26851" },
    { lng: -80.96009, lat: 39.294877, postalCode: "26415" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25364" },
    { lng: -81.90578, lat: 38.509445, postalCode: "25213" },
    { lng: -82.04481, lat: 38.009633, postalCode: "25505" },
    { lng: -80.59377, lat: 40.167471, postalCode: "26074" },
    { lng: -81.92315, lat: 37.55612, postalCode: "25608" },
    { lng: -77.8804, lat: 39.58132, postalCode: "25419" },
    { lng: -78.78539, lat: 39.579717, postalCode: "26753" },
    { lng: -81.24499, lat: 38.12563, postalCode: "25040" },
    { lng: -82.27115, lat: 38.082632, postalCode: "25534" },
    { lng: -78.9458, lat: 39.184315, postalCode: "26845" },
    { lng: -81.66508, lat: 39.220948, postalCode: "26181" },
    { lng: -82.17149, lat: 38.558344, postalCode: "25520" },
    { lng: -81.35319, lat: 37.577807, postalCode: "25826" },
    { lng: -81.00561, lat: 37.712469, postalCode: "25918" },
    { lng: -80.38284, lat: 37.515131, postalCode: "24984" },
    { lng: -80.71039, lat: 39.185707, postalCode: "26411" },
    { lng: -80.49595, lat: 38.41012, postalCode: "26266" },
    { lng: -80.08514, lat: 39.226574, postalCode: "26349" },
    { lng: -80.57999, lat: 40.5323, postalCode: "26056" },
    { lng: -81.25882, lat: 38.129903, postalCode: "25002" },
    { lng: -81.81253, lat: 37.858735, postalCode: "25022" },
    { lng: -80.335, lat: 39.668566, postalCode: "26589" },
    { lng: -79.50612, lat: 39.261474, postalCode: "26716" },
    { lng: -79.17606, lat: 39.351231, postalCode: "26717" },
    { lng: -80.60204, lat: 39.42039, postalCode: "26339" },
    { lng: -82.0603, lat: 38.950609, postalCode: "25287" },
    { lng: -81.33047, lat: 38.175742, postalCode: "25136" },
    { lng: -80.66798, lat: 37.981358, postalCode: "25984" },
    { lng: -81.57171, lat: 38.04358, postalCode: "25148" },
    { lng: -81.36179, lat: 37.776961, postalCode: "25839" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25330" },
    { lng: -81.384, lat: 37.371122, postalCode: "24887" },
    { lng: -81.83666, lat: 37.909119, postalCode: "25183" },
    { lng: -81.11304, lat: 37.754656, postalCode: "25813" },
    { lng: -80.39927, lat: 38.716238, postalCode: "26215" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25715" },
    { lng: -80.92321, lat: 37.808181, postalCode: "25977" },
    { lng: -80.73701, lat: 39.912923, postalCode: "26041" },
    { lng: -80.57542, lat: 40.412067, postalCode: "26062" },
    { lng: -80.28798, lat: 39.258328, postalCode: "26323" },
    { lng: -78.47306, lat: 39.293252, postalCode: "26711" },
    { lng: -81.2324, lat: 37.589172, postalCode: "25902" },
    { lng: -80.09206, lat: 39.266262, postalCode: "26435" },
    { lng: -81.65044, lat: 37.25068, postalCode: "24815" },
    { lng: -81.105803, lat: 37.416395, postalCode: "24732" },
    { lng: -81.71199, lat: 37.948618, postalCode: "25028" },
    { lng: -80.91002, lat: 38.15798, postalCode: "26678" },
    { lng: -82.418277, lat: 38.132114, postalCode: "25519" },
    { lng: -80.65404, lat: 40.219614, postalCode: "26030" },
    { lng: -80.53196, lat: 39.513468, postalCode: "26437" },
    { lng: -82.13265, lat: 37.640357, postalCode: "25692" },
    { lng: -80.0072, lat: 39.660624, postalCode: "26543" },
    { lng: -81.8985, lat: 38.853643, postalCode: "25264" },
    { lng: -80.05957, lat: 38.196374, postalCode: "24954" },
    { lng: -78.6661, lat: 39.44763, postalCode: "26763" },
    { lng: -81.53998, lat: 37.603344, postalCode: "24896" },
    { lng: -81.09478, lat: 38.73796, postalCode: "25268" },
    { lng: -81.85517, lat: 37.757905, postalCode: "25628" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25061" },
    { lng: -81.2093, lat: 37.771364, postalCode: "25871" },
    { lng: -80.03629, lat: 38.52061, postalCode: "26294" },
    { lng: -81.30312, lat: 38.135059, postalCode: "25118" },
    { lng: -81.05964, lat: 37.930061, postalCode: "25936" },
    { lng: -80.70654, lat: 38.983677, postalCode: "26384" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25201" },
    { lng: -81.461063, lat: 38.401962, postalCode: "25156" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25331" },
    { lng: -80.093007, lat: 39.578512, postalCode: "26527" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25773" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25392" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25357" },
    { lng: -80.72323, lat: 38.465291, postalCode: "26610" },
    { lng: -80.13261, lat: 38.185341, postalCode: "24924" },
    { lng: -81.7338, lat: 37.664172, postalCode: "24823" },
    { lng: -80.12604, lat: 39.258358, postalCode: "26347" },
    { lng: -79.18014, lat: 39.089792, postalCode: "26833" },
    { lng: -81.62367, lat: 38.375397, postalCode: "25302" },
    { lng: -81.14533, lat: 37.982775, postalCode: "25901" },
    { lng: -81.79045, lat: 38.254483, postalCode: "25003" },
    { lng: -80.3586, lat: 39.527817, postalCode: "26582" },
    { lng: -80.093007, lat: 39.578512, postalCode: "26533" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25323" },
    { lng: -80.58889, lat: 40.330969, postalCode: "26037" },
    { lng: -81.70112, lat: 38.136987, postalCode: "25154" },
    { lng: -81.46418, lat: 38.108987, postalCode: "25054" },
    { lng: -81.901236, lat: 38.187456, postalCode: "25572" },
    { lng: -80.15881, lat: 39.220701, postalCode: "26334" },
    { lng: -80.385344, lat: 39.285204, postalCode: "26306" },
    { lng: -82.10801, lat: 37.743063, postalCode: "25687" },
    { lng: -79.76428, lat: 39.377589, postalCode: "26444" },
    { lng: -81.53998, lat: 37.603344, postalCode: "25943" },
    { lng: -80.14113, lat: 39.68903, postalCode: "26529" },
    { lng: -80.66112, lat: 38.153472, postalCode: "26676" },
    { lng: -79.09107, lat: 38.81801, postalCode: "26838" },
    { lng: -81.38193, lat: 37.596209, postalCode: "25882" },
    { lng: -78.05882, lat: 39.540636, postalCode: "25427" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25714" },
    { lng: -80.60454, lat: 40.616853, postalCode: "26050" },
    { lng: -80.29416, lat: 39.205852, postalCode: "26408" },
    { lng: -81.91651, lat: 37.779196, postalCode: "25632" },
    { lng: -80.36881, lat: 38.969579, postalCode: "26372" },
    { lng: -81.70173, lat: 37.459251, postalCode: "24816" },
    { lng: -80.41237, lat: 39.611767, postalCode: "26585" },
    { lng: -81.9896, lat: 38.427011, postalCode: "25526" },
    { lng: -80.91401, lat: 37.49671, postalCode: "25979" },
    { lng: -80.218538, lat: 39.514343, postalCode: "26566" },
    { lng: -80.72744, lat: 40.013637, postalCode: "26031" },
    { lng: -82.01452, lat: 37.734818, postalCode: "25638" },
    { lng: -81.25954, lat: 38.126873, postalCode: "25057" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25336" },
    { lng: -81.29336, lat: 38.317053, postalCode: "25160" },
    { lng: -82.13578, lat: 37.570813, postalCode: "25672" },
    { lng: -81.13105, lat: 38.485889, postalCode: "25133" },
    { lng: -82.27394, lat: 37.924415, postalCode: "25666" },
    { lng: -77.877223, lat: 39.315914, postalCode: "25432" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25778" },
    { lng: -78.57871, lat: 39.418178, postalCode: "25437" },
    { lng: -80.35569, lat: 38.059474, postalCode: "24966" },
    { lng: -81.68365, lat: 39.122989, postalCode: "26133" },
    { lng: -81.79154, lat: 38.038145, postalCode: "25130" },
    { lng: -79.95295, lat: 39.032644, postalCode: "26250" },
    { lng: -80.66528, lat: 38.856879, postalCode: "26335" },
    { lng: -80.870081, lat: 39.450612, postalCode: "26135" },
    { lng: -80.41067, lat: 37.869998, postalCode: "24957" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24832" },
    { lng: -80.96569, lat: 38.849663, postalCode: "25267" },
    { lng: -81.4311, lat: 38.046638, postalCode: "25075" },
    { lng: -79.68442, lat: 39.320034, postalCode: "26425" },
    { lng: -82.03943, lat: 37.800168, postalCode: "25652" },
    { lng: -80.53771, lat: 38.424309, postalCode: "26206" },
    { lng: -79.89947, lat: 39.61098, postalCode: "26508" },
    { lng: -81.44874, lat: 37.417857, postalCode: "24852" },
    { lng: -81.29994, lat: 37.32767, postalCode: "24724" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25326" },
    { lng: -81.69546, lat: 37.309666, postalCode: "24892" },
    { lng: -81.28483, lat: 38.151116, postalCode: "25031" },
    { lng: -81.75855, lat: 38.092103, postalCode: "25081" },
    { lng: -80.24008, lat: 37.861237, postalCode: "24986" },
    { lng: -80.27178, lat: 39.704464, postalCode: "26590" },
    { lng: -80.62283, lat: 39.09666, postalCode: "26338" },
    { lng: -81.19722, lat: 37.837706, postalCode: "25909" },
    { lng: -79.82996, lat: 39.39905, postalCode: "26410" },
    { lng: -79.98826, lat: 39.646705, postalCode: "26534" },
    { lng: -77.91403, lat: 39.210527, postalCode: "25441" },
    { lng: -81.13906, lat: 38.210552, postalCode: "26674" },
    { lng: -80.37481, lat: 37.741887, postalCode: "24925" },
    { lng: -81.49148, lat: 38.177823, postalCode: "25035" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25335" },
    { lng: -81.880455, lat: 38.475369, postalCode: "25569" },
    { lng: -81.31112, lat: 37.335297, postalCode: "24715" },
    { lng: -82.46238, lat: 38.116605, postalCode: "25517" },
    { lng: -80.53077, lat: 39.695772, postalCode: "26581" },
    { lng: -82.4254, lat: 37.938427, postalCode: "25669" },
    { lng: -79.63848, lat: 39.097545, postalCode: "26269" },
    { lng: -80.31889, lat: 39.346726, postalCode: "26438" },
    { lng: -80.47731, lat: 39.04101, postalCode: "26452" },
    { lng: -81.997278, lat: 38.752312, postalCode: "25095" },
    { lng: -81.44962, lat: 38.153818, postalCode: "25134" },
    { lng: -79.895639, lat: 39.636606, postalCode: "26503" },
    { lng: -81.10596, lat: 38.243168, postalCode: "26690" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25708" },
    { lng: -81.90361, lat: 37.861621, postalCode: "25076" },
    { lng: -78.74815, lat: 39.321688, postalCode: "26757" },
    { lng: -79.89397, lat: 39.230125, postalCode: "26405" },
    { lng: -82.03031, lat: 39.017309, postalCode: "25260" },
    { lng: -80.426874, lat: 37.975595, postalCode: "24961" },
    { lng: -81.6155, lat: 37.265686, postalCode: "24866" },
    { lng: -80.52942, lat: 37.743322, postalCode: "24936" },
    { lng: -79.33161, lat: 38.482224, postalCode: "26815" },
    { lng: -80.28438, lat: 39.392285, postalCode: "26431" },
    { lng: -81.35066, lat: 37.90907, postalCode: "25044" },
    { lng: -80.21137, lat: 39.718922, postalCode: "26521" },
    { lng: -81.21634, lat: 37.964622, postalCode: "25917" },
    { lng: -79.98796, lat: 39.628475, postalCode: "26501" },
    { lng: -81.36531, lat: 37.363882, postalCode: "24861" },
    { lng: -80.5275, lat: 39.472363, postalCode: "26348" },
    { lng: -78.95987, lat: 39.434856, postalCode: "26726" },
    { lng: -80.66613, lat: 40.190886, postalCode: "26075" },
    { lng: -80.093007, lat: 39.578512, postalCode: "26504" },
    { lng: -81.19688, lat: 38.176834, postalCode: "25085" },
    { lng: -81.96819, lat: 37.555503, postalCode: "25651" },
    { lng: -80.64053, lat: 39.485593, postalCode: "26377" },
    { lng: -81.540501, lat: 39.236427, postalCode: "26103" },
    { lng: -81.05101, lat: 38.151832, postalCode: "25938" },
    { lng: -81.33836, lat: 38.464079, postalCode: "25045" },
    { lng: -81.26263, lat: 37.70708, postalCode: "25921" },
    { lng: -81.75416, lat: 38.698253, postalCode: "25245" },
    { lng: -81.71468, lat: 37.972887, postalCode: "25206" },
    { lng: -79.40533, lat: 38.992079, postalCode: "26263" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25337" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25726" },
    { lng: -81.34359, lat: 37.683749, postalCode: "25920" },
    { lng: -81.44797, lat: 38.198471, postalCode: "25067" },
    { lng: -80.31744, lat: 39.344303, postalCode: "26404" },
    { lng: -81.53998, lat: 37.603344, postalCode: "25810" },
    { lng: -81.10284, lat: 38.835085, postalCode: "25261" },
    { lng: -80.93549, lat: 38.302121, postalCode: "26671" },
    { lng: -80.74638, lat: 37.597231, postalCode: "24985" },
    { lng: -79.89941, lat: 38.798094, postalCode: "26259" },
    { lng: -80.59709, lat: 40.266698, postalCode: "26070" },
    { lng: -81.48138, lat: 37.699568, postalCode: "25913" },
    { lng: -81.92567, lat: 38.169041, postalCode: "25501" },
    { lng: -81.98738, lat: 37.793612, postalCode: "25647" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25358" },
    { lng: -79.01717, lat: 39.068317, postalCode: "26818" },
    { lng: -78.44261, lat: 39.210787, postalCode: "26808" },
    { lng: -81.07278, lat: 38.911018, postalCode: "26147" },
    { lng: -78.011472, lat: 39.461663, postalCode: "25402" },
    { lng: -81.050662, lat: 38.465777, postalCode: "25018" },
    { lng: -81.71704, lat: 37.997105, postalCode: "25093" },
    { lng: -80.30858, lat: 39.26277, postalCode: "26302" },
    { lng: -80.877395, lat: 37.648541, postalCode: "25965" },
    { lng: -81.0082, lat: 38.46791, postalCode: "25043" },
    { lng: -78.66841, lat: 39.13957, postalCode: "26755" },
    { lng: -81.37333, lat: 38.077863, postalCode: "25083" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25361" },
    { lng: -80.25738, lat: 39.513513, postalCode: "26571" },
    { lng: -78.1676, lat: 39.372769, postalCode: "25421" },
    { lng: -81.07615, lat: 38.661766, postalCode: "25235" },
    { lng: -81.4451, lat: 37.850007, postalCode: "25174" },
    { lng: -81.67191, lat: 37.887883, postalCode: "25208" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25387" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25779" },
    { lng: -80.77039, lat: 37.798406, postalCode: "25966" },
    { lng: -80.5425, lat: 38.224781, postalCode: "26261" },
    { lng: -80.70048, lat: 38.218258, postalCode: "26681" },
    { lng: -81.497865, lat: 39.218276, postalCode: "26121" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24820" },
    { lng: -81.98991, lat: 38.238045, postalCode: "25521" },
    { lng: -80.83069, lat: 38.49119, postalCode: "26617" },
    { lng: -81.88002, lat: 38.547027, postalCode: "25168" },
    { lng: -82.21701, lat: 37.643043, postalCode: "25691" },
    { lng: -81.96731, lat: 38.988059, postalCode: "25265" },
    { lng: -81.653889, lat: 37.375246, postalCode: "24878" },
    { lng: -80.18033, lat: 38.735363, postalCode: "26224" },
    { lng: -80.65942, lat: 37.731717, postalCode: "24910" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25328" },
    { lng: -80.176171, lat: 39.446631, postalCode: "26578" },
    { lng: -81.26443, lat: 37.777391, postalCode: "25836" },
    { lng: -81.24781, lat: 37.352655, postalCode: "24737" },
    { lng: -81.28108, lat: 37.731521, postalCode: "25849" },
    { lng: -78.22783, lat: 39.567987, postalCode: "25411" },
    { lng: -81.3019, lat: 37.608562, postalCode: "25915" },
    { lng: -81.26724, lat: 37.935699, postalCode: "25904" },
    { lng: -81.21868, lat: 37.394221, postalCode: "24747" },
    { lng: -80.40589, lat: 38.85389, postalCode: "26343" },
    { lng: -80.9522, lat: 38.073479, postalCode: "25868" },
    { lng: -82.490494, lat: 38.343176, postalCode: "25562" },
    { lng: -81.84968, lat: 37.602126, postalCode: "24851" },
    { lng: -80.834152, lat: 38.318389, postalCode: "26675" },
    { lng: -81.48001, lat: 37.883223, postalCode: "25140" },
    { lng: -81.554655, lat: 38.296818, postalCode: "25396" },
    { lng: -80.26795, lat: 38.900064, postalCode: "26210" },
    { lng: -80.877395, lat: 37.648541, postalCode: "25988" },
    { lng: -81.10208, lat: 37.980561, postalCode: "25879" },
    { lng: -80.30336, lat: 39.520705, postalCode: "26587" },
    { lng: -82.04459, lat: 37.583945, postalCode: "25682" },
    { lng: -81.55043, lat: 38.770723, postalCode: "25244" },
    { lng: -78.52587, lat: 39.412828, postalCode: "25444" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25716" },
    { lng: -81.26966, lat: 38.052957, postalCode: "25152" },
    { lng: -81.69146, lat: 37.444527, postalCode: "24881" },
    { lng: -81.37836, lat: 39.179485, postalCode: "26180" },
    { lng: -79.90179, lat: 38.325205, postalCode: "24934" },
    { lng: -80.226855, lat: 38.899314, postalCode: "26219" },
    { lng: -80.58745, lat: 38.728405, postalCode: "26621" },
    { lng: -80.69864, lat: 38.553898, postalCode: "26629" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25775" },
    { lng: -81.58198, lat: 38.351204, postalCode: "25311" },
    { lng: -81.224458, lat: 37.748935, postalCode: "25860" },
    { lng: -79.43087, lat: 38.737991, postalCode: "26814" },
    { lng: -81.69408, lat: 37.62127, postalCode: "24822" },
    { lng: -80.67076, lat: 39.551892, postalCode: "26419" },
    { lng: -80.23537, lat: 38.752157, postalCode: "26236" },
    { lng: -80.27599, lat: 39.452034, postalCode: "26591" },
    { lng: -80.61007, lat: 37.94046, postalCode: "24950" },
    { lng: -79.60611, lat: 39.042297, postalCode: "26271" },
    { lng: -81.93548, lat: 38.538953, postalCode: "25070" },
    { lng: -81.82478, lat: 37.312239, postalCode: "24850" },
    { lng: -80.24199, lat: 39.296499, postalCode: "26330" },
    { lng: -81.16153, lat: 38.244559, postalCode: "26656" },
    { lng: -79.50217, lat: 38.99095, postalCode: "26289" },
    { lng: -81.902401, lat: 37.833204, postalCode: "25614" },
    { lng: -81.33026, lat: 37.520914, postalCode: "24726" },
    { lng: -81.12623, lat: 38.536815, postalCode: "25283" },
    { lng: -81.53998, lat: 37.603344, postalCode: "24834" },
    { lng: -81.709496, lat: 38.95192, postalCode: "26173" },
    { lng: -81.98826, lat: 37.688162, postalCode: "25644" },
    { lng: -81.136328, lat: 37.735161, postalCode: "25816" },
    { lng: -82.10801, lat: 37.743063, postalCode: "25694" },
    { lng: -81.84501, lat: 38.851872, postalCode: "25239" },
    { lng: -82.277401, lat: 38.413384, postalCode: "25710" },
    { lng: -81.11208, lat: 38.059415, postalCode: "25840" },
    { lng: -82.43909, lat: 38.225065, postalCode: "25570" },
    { lng: -80.54683, lat: 40.198956, postalCode: "26032" },
    { lng: -81.81441, lat: 37.686815, postalCode: "25650" },
    { lng: -80.15531, lat: 38.854653, postalCode: "26237" },
    { lng: -81.4629, lat: 37.40273, postalCode: "24829" },
    { lng: -81.55106, lat: 38.018753, postalCode: "25193" },
    { lng: -81.128631, lat: 37.837573, postalCode: "25906" },
    { lng: -82.10801, lat: 37.743063, postalCode: "25697" },
    { lng: -81.81354, lat: 37.978787, postalCode: "25114" },
    { lng: -80.95523, lat: 37.336554, postalCode: "24739" },
    { lng: -79.83449, lat: 38.55181, postalCode: "26264" },
    { lng: -80.77211, lat: 39.576419, postalCode: "26162" },
    { lng: -82.38072, lat: 38.011587, postalCode: "25511" },
    { lng: -81.1391, lat: 38.007725, postalCode: "25866" },
    { lng: -80.65773, lat: 37.893306, postalCode: "24977" },
    { lng: -81.93955, lat: 37.485276, postalCode: "24862" },
    { lng: -81.34504, lat: 37.421641, postalCode: "24825" },
    { lng: -80.61225, lat: 39.619007, postalCode: "26186" },
    { lng: -80.44628, lat: 39.285303, postalCode: "26422" },
    { lng: -78.6438, lat: 39.509416, postalCode: "26722" },
    { lng: -66.060533, lat: 18.410462, postalCode: "910" },
    { lng: -67.13422, lat: 18.448619, postalCode: "603" },
    { lng: -66.12827, lat: 18.246205, postalCode: "703" },
    { lng: -66.41528, lat: 18.31708, postalCode: "687" },
    { lng: -66.060533, lat: 18.410462, postalCode: "922" },
    { lng: -66.59965, lat: 17.999066, postalCode: "716" },
    { lng: -66.00561, lat: 18.346767, postalCode: "976" },
    { lng: -66.01194, lat: 18.401917, postalCode: "924" },
    { lng: -66.59243, lat: 18.212565, postalCode: "664" },
    { lng: -65.972495, lat: 18.393355, postalCode: "984" },
    { lng: -66.0577, lat: 18.45009, postalCode: "911" },
    { lng: -65.87605, lat: 18.427674, postalCode: "772" },
    { lng: -66.04502, lat: 18.233927, postalCode: "725" },
    { lng: -66.15377, lat: 18.112895, postalCode: "736" },
    { lng: -66.139293, lat: 18.443217, postalCode: "963" },
    { lng: -66.359104, lat: 18.077197, postalCode: "640" },
    { lng: -67.12085, lat: 18.139108, postalCode: "660" },
    { lng: -66.952589, lat: 18.069474, postalCode: "747" },
    { lng: -66.060533, lat: 18.410462, postalCode: "940" },
    { lng: -66.65218, lat: 18.013353, postalCode: "728" },
    { lng: -66.39457, lat: 17.98631, postalCode: "757" },
    { lng: -66.55218, lat: 18.049577, postalCode: "644" },
    { lng: -65.869939, lat: 18.1789, postalCode: "671" },
    { lng: -66.502103, lat: 18.044224, postalCode: "665" },
    { lng: -67.15222, lat: 18.08643, postalCode: "623" },
    { lng: -66.398302, lat: 18.443109, postalCode: "694" },
    { lng: -66.98104, lat: 18.332595, postalCode: "685" },
    { lng: -66.93993, lat: 17.964529, postalCode: "647" },
    { lng: -66.273278, lat: 18.142002, postalCode: "609" },
    { lng: -66.85174, lat: 18.186739, postalCode: "631" },
    { lng: -66.117219, lat: 17.979518, postalCode: "654" },
    { lng: -66.61217, lat: 18.457453, postalCode: "652" },
    { lng: -66.27689, lat: 18.442798, postalCode: "646" },
    { lng: -66.97604, lat: 18.241343, postalCode: "670" },
    { lng: -65.921356, lat: 18.228952, postalCode: "666" },
    { lng: -67.04226, lat: 18.017819, postalCode: "667" },
    { lng: -66.80217, lat: 18.279531, postalCode: "611" },
    { lng: -66.10889, lat: 18.366981, postalCode: "969" },
    { lng: -66.90097, lat: 17.992112, postalCode: "653" },
    { lng: -66.397138, lat: 18.452084, postalCode: "764" },
    { lng: -66.732732, lat: 18.458093, postalCode: "613" },
    { lng: -67.09867, lat: 18.495369, postalCode: "690" },
    { lng: -66.61727, lat: 18.022626, postalCode: "730" },
    { lng: -66.060533, lat: 18.410462, postalCode: "938" },
    { lng: -66.55575, lat: 18.455499, postalCode: "617" },
    { lng: -66.15943, lat: 18.387063, postalCode: "959" },
    { lng: -65.82277, lat: 18.366213, postalCode: "745" },
    { lng: -65.826595, lat: 18.150829, postalCode: "661" },
    { lng: -66.55939, lat: 18.068538, postalCode: "780" },
    { lng: -66.158728, lat: 18.176094, postalCode: "639" },
    { lng: -66.060533, lat: 18.410462, postalCode: "919" },
    { lng: -65.29257, lat: 18.311149, postalCode: "775" },
    { lng: -65.89703, lat: 18.072752, postalCode: "767" },
    { lng: -65.89089, lat: 18.35615, postalCode: "729" },
    { lng: -67.08424, lat: 18.37956, postalCode: "676" },
    { lng: -66.0513, lat: 18.422263, postalCode: "917" },
    { lng: -66.795603, lat: 18.018822, postalCode: "785" },
    { lng: -66.42265, lat: 18.217827, postalCode: "720" },
    { lng: -66.32305, lat: 18.304874, postalCode: "783" },
    { lng: -66.674506, lat: 18.429675, postalCode: "614" },
    { lng: -65.972495, lat: 18.393355, postalCode: "986" },
    { lng: -67.13699, lat: 18.498987, postalCode: "604" },
    { lng: -66.058033, lat: 18.212965, postalCode: "726" },
    { lng: -66.183467, lat: 18.194527, postalCode: "633" },
    { lng: -65.45603, lat: 18.125664, postalCode: "765" },
    { lng: -66.619165, lat: 18.019331, postalCode: "733" },
    { lng: -66.0127, lat: 18.431885, postalCode: "979" },
    { lng: -67.18024, lat: 18.363285, postalCode: "602" },
    { lng: -66.49835, lat: 18.308139, postalCode: "638" },
    { lng: -66.06764, lat: 18.442282, postalCode: "909" },
    { lng: -66.12198, lat: 18.413511, postalCode: "934" },
    { lng: -66.13847, lat: 18.437683, postalCode: "962" },
    { lng: -65.304732, lat: 18.308508, postalCode: "645" },
    { lng: -66.104657, lat: 18.256995, postalCode: "607" },
    { lng: -67.23675, lat: 18.336121, postalCode: "677" },
    { lng: -67.508068, lat: 18.219023, postalCode: "681" },
    { lng: -65.99313, lat: 18.409345, postalCode: "982" },
    { lng: -65.82269, lat: 18.147257, postalCode: "791" },
    { lng: -65.646529, lat: 18.262902, postalCode: "635" },
    { lng: -67.04524, lat: 18.092807, postalCode: "683" },
    { lng: -66.61348, lat: 18.40415, postalCode: "688" },
    { lng: -66.116795, lat: 17.976371, postalCode: "655" },
    { lng: -66.85587, lat: 18.06547, postalCode: "698" },
    { lng: -66.324284, lat: 18.420218, postalCode: "762" },
    { lng: -66.1025, lat: 18.408479, postalCode: "968" },
    { lng: -66.061459, lat: 17.96977, postalCode: "615" },
    { lng: -66.55868, lat: 18.003492, postalCode: "715" },
    { lng: -66.03806, lat: 18.410681, postalCode: "923" },
    { lng: -65.91316, lat: 18.224088, postalCode: "777" },
    { lng: -67.141486, lat: 18.465162, postalCode: "605" },
    { lng: -66.11876, lat: 18.329688, postalCode: "971" },
    { lng: -66.196503, lat: 18.457761, postalCode: "950" },
    { lng: -66.037318, lat: 18.235003, postalCode: "626" },
    { lng: -66.060533, lat: 18.410462, postalCode: "975" },
    { lng: -66.61192, lat: 18.077329, postalCode: "731" },
    { lng: -65.97582, lat: 18.414408, postalCode: "983" },
    { lng: -67.15428, lat: 18.208402, postalCode: "682" },
    { lng: -66.196503, lat: 18.457761, postalCode: "951" },
    { lng: -66.94864, lat: 18.073078, postalCode: "637" },
    { lng: -66.268717, lat: 18.128092, postalCode: "786" },
    { lng: -66.258108, lat: 18.358939, postalCode: "954" },
    { lng: -66.060533, lat: 18.410462, postalCode: "914" },
    { lng: -67.13604, lat: 18.288319, postalCode: "610" },
    { lng: -66.56773, lat: 18.363331, postalCode: "650" },
    { lng: -67.097726, lat: 18.493745, postalCode: "752" },
    { lng: -66.23414, lat: 18.36802, postalCode: "953" },
    { lng: -66.74947, lat: 18.180103, postalCode: "601" },
    { lng: -66.61374, lat: 18.004303, postalCode: "717" },
    { lng: -66.08633, lat: 18.394019, postalCode: "921" },
    { lng: -65.656182, lat: 18.333038, postalCode: "648" },
    { lng: -66.2267, lat: 18.223348, postalCode: "782" },
    { lng: -66.26541, lat: 18.12942, postalCode: "705" },
    { lng: -66.11522, lat: 18.398507, postalCode: "966" },
    { lng: -67.12655, lat: 18.205232, postalCode: "680" },
    { lng: -66.060533, lat: 18.410462, postalCode: "908" },
    { lng: -66.060533, lat: 18.410462, postalCode: "937" },
    { lng: -66.060533, lat: 18.410462, postalCode: "916" },
    { lng: -66.04888, lat: 18.436995, postalCode: "915" },
    { lng: -65.652484, lat: 18.326732, postalCode: "748" },
    { lng: -66.18669, lat: 18.369674, postalCode: "957" },
    { lng: -66.04256, lat: 18.450907, postalCode: "913" },
    { lng: -66.702364, lat: 18.265445, postalCode: "761" },
    { lng: -66.72602, lat: 18.055399, postalCode: "624" },
    { lng: -66.93275, lat: 18.442334, postalCode: "678" },
    { lng: -65.990099, lat: 18.337004, postalCode: "978" },
    { lng: -67.039706, lat: 18.113284, postalCode: "636" },
    { lng: -65.91018, lat: 18.014505, postalCode: "707" },
    { lng: -66.48697, lat: 18.426137, postalCode: "674" },
    { lng: -66.06494, lat: 18.417668, postalCode: "918" },
    { lng: -66.060533, lat: 18.410462, postalCode: "933" },
    { lng: -66.22291, lat: 17.970112, postalCode: "704" },
    { lng: -65.65987, lat: 18.258444, postalCode: "735" },
    { lng: -66.50289, lat: 18.036253, postalCode: "795" },
    { lng: -66.305827, lat: 18.185463, postalCode: "618" },
    { lng: -66.060533, lat: 18.410462, postalCode: "902" },
    { lng: -66.060533, lat: 18.410462, postalCode: "935" },
    { lng: -66.10079, lat: 18.46454, postalCode: "906" },
    { lng: -65.96831, lat: 18.15633, postalCode: "754" },
    { lng: -65.96275, lat: 18.372228, postalCode: "987" },
    { lng: -66.2042, lat: 18.433173, postalCode: "949" },
    { lng: -66.039087, lat: 18.232109, postalCode: "625" },
    { lng: -66.16033, lat: 18.412462, postalCode: "961" },
    { lng: -66.3611, lat: 18.092813, postalCode: "769" },
    { lng: -67.01973, lat: 18.478855, postalCode: "662" },
    { lng: -65.594769, lat: 18.264872, postalCode: "742" },
    { lng: -65.990099, lat: 18.337004, postalCode: "977" },
    { lng: -66.166014, lat: 18.34487, postalCode: "958" },
    { lng: -65.973605, lat: 18.254137, postalCode: "658" },
    { lng: -66.05028, lat: 18.400006, postalCode: "925" },
    { lng: -66.060533, lat: 18.410462, postalCode: "955" },
    { lng: -66.856181, lat: 18.030715, postalCode: "768" },
    { lng: -66.0562, lat: 18.361363, postalCode: "926" },
    { lng: -66.060533, lat: 18.410462, postalCode: "928" },
    { lng: -66.25098, lat: 18.294571, postalCode: "719" },
    { lng: -66.70519, lat: 18.268896, postalCode: "641" },
    { lng: -65.72133, lat: 18.361344, postalCode: "773" },
    { lng: -66.18014, lat: 18.429218, postalCode: "952" },
    { lng: -66.060533, lat: 18.410462, postalCode: "931" },
    { lng: -66.060533, lat: 18.410462, postalCode: "939" },
    { lng: -66.10786, lat: 18.465426, postalCode: "901" },
    { lng: -65.66116, lat: 18.32265, postalCode: "738" },
    { lng: -66.80039, lat: 18.432956, postalCode: "659" },
    { lng: -66.33186, lat: 18.419666, postalCode: "692" },
    { lng: -66.12779, lat: 17.984137, postalCode: "784" },
    { lng: -66.05552, lat: 17.987288, postalCode: "714" },
    { lng: -66.06867, lat: 18.39184, postalCode: "927" },
    { lng: -67.16745, lat: 18.003125, postalCode: "622" },
    { lng: -66.11703, lat: 18.431453, postalCode: "965" },
    { lng: -66.16271, lat: 18.16984, postalCode: "739" },
    { lng: -66.31058, lat: 18.204294, postalCode: "794" },
    { lng: -66.16643, lat: 18.34216, postalCode: "956" },
    { lng: -66.79168, lat: 18.038866, postalCode: "656" },
    { lng: -65.972495, lat: 18.393355, postalCode: "988" },
    { lng: -65.778288, lat: 18.364161, postalCode: "721" },
    { lng: -66.177083, lat: 18.111528, postalCode: "634" },
    { lng: -66.643934, lat: 17.999499, postalCode: "734" },
    { lng: -65.97791, lat: 18.258628, postalCode: "778" },
    { lng: -66.67669, lat: 18.426748, postalCode: "616" },
    { lng: -66.399836, lat: 18.452175, postalCode: "763" },
    { lng: -66.060533, lat: 18.410462, postalCode: "981" },
    { lng: -66.48208, lat: 18.126023, postalCode: "766" },
    { lng: -66.3921, lat: 18.440667, postalCode: "693" },
    { lng: -66.139274, lat: 18.102967, postalCode: "737" },
    { lng: -66.315194, lat: 18.341254, postalCode: "643" },
    { lng: -66.0131, lat: 18.023196, postalCode: "723" },
    { lng: -65.94691, lat: 18.374896, postalCode: "985" },
    { lng: -66.09069, lat: 18.41242, postalCode: "920" },
    { lng: -65.75765, lat: 18.160755, postalCode: "741" },
    { lng: -66.05928, lat: 18.445946, postalCode: "912" },
    { lng: -66.69879, lat: 18.449732, postalCode: "612" },
    { lng: -65.74293, lat: 18.22048, postalCode: "718" },
    { lng: -66.060533, lat: 18.410462, postalCode: "930" },
    { lng: -66.9588, lat: 18.182151, postalCode: "606" },
    { lng: -66.112295, lat: 18.359143, postalCode: "970" },
    { lng: -65.788499, lat: 18.138029, postalCode: "792" },
    { lng: -66.060533, lat: 18.410462, postalCode: "929" },
    { lng: -65.87088, lat: 18.18744, postalCode: "771" },
    { lng: -66.85644, lat: 18.435246, postalCode: "627" },
    { lng: -65.62761, lat: 18.331711, postalCode: "740" },
    { lng: -66.613742, lat: 18.021781, postalCode: "732" },
    { lng: -66.07798, lat: 18.451131, postalCode: "907" },
    { lng: -66.166014, lat: 18.34487, postalCode: "960" },
    { lng: -66.060533, lat: 18.410462, postalCode: "936" },
    { lng: -66.26534, lat: 17.993803, postalCode: "751" },
    { lng: -65.743058, lat: 18.206329, postalCode: "744" },
    { lng: -66.87503, lat: 18.288418, postalCode: "669" },
    { lng: -64.734694, lat: 17.734211, postalCode: "851" },
    { lng: -64.734694, lat: 17.734211, postalCode: "841" },
    { lng: -64.734694, lat: 17.734211, postalCode: "821" },
    { lng: -64.963715, lat: 18.322285, postalCode: "804" },
    { lng: -64.963715, lat: 18.322285, postalCode: "802" },
    { lng: -64.734694, lat: 17.734211, postalCode: "823" },
    { lng: -64.963715, lat: 18.322285, postalCode: "803" },
    { lng: -64.734694, lat: 17.734211, postalCode: "822" },
    { lng: -64.734694, lat: 17.734211, postalCode: "824" },
    { lng: -64.740737, lat: 18.32816, postalCode: "831" },
    { lng: -64.740737, lat: 18.32816, postalCode: "830" },
    { lng: -64.734694, lat: 17.734211, postalCode: "840" },
    { lng: -64.734694, lat: 17.734211, postalCode: "820" },
    { lng: -64.963715, lat: 18.322285, postalCode: "805" },
    { lng: -64.963715, lat: 18.322285, postalCode: "801" },
    { lng: -64.734694, lat: 17.734211, postalCode: "850" },
    { lng: -96.578137, lat: 49.510438, postalCode: "R0A 0R0" },
    { lng: -122.1412, lat: 49.0513, postalCode: "V4Z 0C1" },
    { lng: -104.569, lat: 50.4518, postalCode: "S4N 1T7" },
    { lng: -113.3343, lat: 51.6375, postalCode: "T0M 1X0" },
    { lng: -79.0865, lat: 43.8343, postalCode: "L1G 4M4" },
    { lng: -78.7821, lat: 44.7649, postalCode: "K0K 1L0" },
    { lng: -80.2635, lat: 43.1384, postalCode: "N3S 3L5" },
    { lng: -97.1387, lat: 49.9084, postalCode: "R2W 2Y5" },
    { lng: -79.8649, lat: 48.7575, postalCode: "P0H 2A0" },
    { lng: -113.5518, lat: 53.2684, postalCode: "T8A 3L1" },
    { lng: -97.0596, lat: 49.8586, postalCode: "R3L 0W7" },
    { lng: -79.2728, lat: 43.8936, postalCode: "L2G 6Z7" },
    { lng: -113.4958, lat: 53.5433, postalCode: "T5B 4W5" },
    { lng: -84.3355, lat: 46.5348, postalCode: "P6C 5Y3" },
    { lng: -117.6222, lat: 57.4375, postalCode: "T0H 2M0" },
    { lng: -79.4395, lat: 48.7567, postalCode: "P0L 1C0" },
    { lng: -116.4215, lat: 53.2526, postalCode: "V1L 6N2" },
    { lng: -119.489, lat: 49.8903, postalCode: "V1Z 1E9" },
    { lng: -119.3302, lat: 50.6619, postalCode: "V0H 1X2" },
    { lng: -78.9261, lat: 45.0599, postalCode: "P1H 1V3" },
    { lng: -80.5327, lat: 43.4401, postalCode: "N5W 2R6" },
    { lng: -113.9929, lat: 53.2785, postalCode: "T7A 1R3" },
    { lng: -101.3148, lat: 52.9544, postalCode: "R0L 1M0" },
    { lng: -112.7306, lat: 53.3533, postalCode: "T0B 3L0" },
    { lng: -101.6278, lat: 57.5984, postalCode: "S0J 0SO" },
    { lng: -64.3225, lat: 44.8173, postalCode: "B3G 1B5" },
    { lng: -61.3617, lat: 45.6227, postalCode: "B1A 2L6" },
    { lng: -80.5277, lat: 43.4643, postalCode: "N2L 3N6" },
    { lng: -81.2855, lat: 43.0149, postalCode: "N6G 2H1" },
    { lng: -79.4573, lat: 43.7186, postalCode: "M6N 5J1" },
    { lng: -77.3596, lat: 45.6256, postalCode: "K8A 1L3" },
    { lng: -80.3325, lat: 43.0316, postalCode: "N1H 5Z2" },
    { lng: -71.2503, lat: 48.4846, postalCode: "G0M 1R0" },
    { lng: -81.4379, lat: 44.5517, postalCode: "N0G 2V0" },
    { lng: -73.6882, lat: 45.4822, postalCode: "H8S 1R4" },
    { lng: -79.8233, lat: 43.6923, postalCode: "L6W 2C7" },
    { lng: -114.0183, lat: 50.9889, postalCode: "T2J 0R1" },
    { lng: -122.8132, lat: 49.2908, postalCode: "V3R 2A6" },
    { lng: -79.6341, lat: 43.8341, postalCode: "L4P 3B6" },
    { lng: -74.7388, lat: 45.9482, postalCode: "J0K 3K0" },
    { lng: -63.6042, lat: 45.6461, postalCode: "B0M 1B0" },
    { lng: -117.5827, lat: 53.3614, postalCode: "T7V 1N4" },
    { lng: -78.182, lat: 48.8323, postalCode: "J0B 2H0" },
    { lng: -102.0122, lat: 55.7397, postalCode: "S0G 0C0" },
    { lng: -65.8914, lat: 44.8466, postalCode: "B0W 2A0" },
    { lng: -79.782, lat: 44.0628, postalCode: "L9M 0A9" },
    { lng: -97.1332, lat: 49.8987, postalCode: "R2G 3G7" },
    { lng: -79.7411, lat: 43.6795, postalCode: "L9Y 3R4" },
    { lng: -111.3728, lat: 56.7091, postalCode: "T8V 5J3" },
    { lng: -122.8492, lat: 49.3279, postalCode: "V2V 6Y2" },
    { lng: -73.7429, lat: 45.4892, postalCode: "H8S 3T1" },
    { lng: -121.9778, lat: 49.0581, postalCode: "V0H 1J0" },
    { lng: -101.6339, lat: 57.0671, postalCode: "S0K 0Z0" },
    { lng: -121.9977, lat: 53.2909, postalCode: "V0N 3K0" },
    { lng: -81.4803, lat: 46.3281, postalCode: "K6V 6N2" },
    { lng: -77.6254, lat: 48.5489, postalCode: "J9T 3L7" },
    { lng: -112.8322, lat: 53.9511, postalCode: "T0E 0B0" },
    { lng: -65.4859, lat: 45.8016, postalCode: "E4C 3N7" },
    { lng: -122.8895, lat: 49.2145, postalCode: "V3T 1C9" },
    { lng: -79.3606, lat: 43.8378, postalCode: "L3K 1Z6" },
    { lng: -79.8649, lat: 48.7575, postalCode: "P0H 2M0" },
    { lng: -80.2576, lat: 43.1822, postalCode: "N3L 2T7" },
    { lng: -97.1531, lat: 49.9029, postalCode: "R2W 2N8" },
    { lng: -73.6668, lat: 45.4325, postalCode: "K7R 3T3" },
    { lng: -105.5271, lat: 50.2951, postalCode: "S6H 3C0" },
    { lng: -74.8682, lat: 45.9531, postalCode: "P1L 1M8" },
    { lng: -113.5919, lat: 51.6184, postalCode: "T0B 0S0" },
    { lng: -61.346, lat: 45.2019, postalCode: "B0K 1S0" },
    { lng: -75.742, lat: 45.0009, postalCode: "K0H 1B0" },
    { lng: -81.4379, lat: 44.5517, postalCode: "N0G 2V0" },
    { lng: -60.3065, lat: 46.2207, postalCode: "A0N 2C0" },
    { lng: -123.0508, lat: 48.545, postalCode: "V1T 4S3" },
    { lng: -80.9725, lat: 44.9734, postalCode: "N0H 2T0" },
    { lng: -79.4625, lat: 44.8109, postalCode: "K0K 1X0" },
    { lng: -97.0277, lat: 49.8844, postalCode: "R3C 3L8" },
    { lng: -81.2855, lat: 43.0149, postalCode: "N6G 5N7" },
    { lng: -80.5277, lat: 43.4643, postalCode: "N2H 6T7" },
    { lng: -110.6757, lat: 50.0349, postalCode: "T0B 1V0" },
    { lng: -74.042, lat: 45.5149, postalCode: "K8V 2M2" },
    { lng: -128.0848, lat: 52.3682, postalCode: "V8J 4A3" },
    { lng: -119.1088, lat: 51.8236, postalCode: "V0K 1V0" },
    { lng: -122.8276, lat: 49.2693, postalCode: "V2A 8V8" },
    { lng: -119.7605, lat: 51.2466, postalCode: "V0N 1H0" },
    { lng: -118.0803, lat: 52.8918, postalCode: "V0P 1G0" },
    { lng: -79.4418, lat: 46.2986, postalCode: "E2S 2N9" },
    { lng: -113.904, lat: 49.6518, postalCode: "T0K 0G0" },
    { lng: -81.4379, lat: 44.5517, postalCode: "N0G 2V0" },
    { lng: -111.785, lat: 53.2306, postalCode: "S0G 1N0" },
    { lng: -80.4503, lat: 43.4266, postalCode: "N2E 3Y9" },
    { lng: -76.7075, lat: 45.3938, postalCode: "Y0A 1C0" },
    { lng: -79.4625, lat: 44.8109, postalCode: "K0K 2K0" },
    { lng: -122.7653, lat: 53.8627, postalCode: "V2M 2L8" },
    { lng: -111.2375, lat: 57.5862, postalCode: "T0H 0J0" },
    { lng: -114.071, lat: 50.9641, postalCode: "T2H 2B3" },
    { lng: -96.9961, lat: 49.8928, postalCode: "R0A 0R0" },
    { lng: -122.7641, lat: 53.8933, postalCode: "V2M 3R8" },
    { lng: -73.7316, lat: 45.6664, postalCode: "J5Y 1S1" },
    { lng: -113.9831, lat: 51.0474, postalCode: "T2A 5Z1" },
    { lng: -113.4909, lat: 53.5412, postalCode: "T7X 1E4" },
    { lng: -122.8877, lat: 49.2546, postalCode: "V3K 6M2" },
    { lng: -111.3867, lat: 56.7256, postalCode: "T9M 1P4" },
    { lng: -66.2799, lat: 44.4001, postalCode: "B0V 1A0" },
    { lng: -84.8069, lat: 46.7203, postalCode: "P0C 1H0" },
    { lng: -99.2858, lat: 50.2869, postalCode: "R1A 0L4" },
    { lng: -122.4866, lat: 49.1158, postalCode: "V0X 1X0" },
    { lng: -103.5584, lat: 57.8838, postalCode: "S0J 0N0" },
    { lng: -79.2131, lat: 45.3314, postalCode: "P2A 0A4" },
    { lng: -122.7593, lat: 53.9221, postalCode: "V2C 2W8" },
    { lng: -105.7269, lat: 53.2165, postalCode: "S6V 5P9" },
    { lng: -79.7069, lat: 45.9563, postalCode: "P1H 1J6" },
    { lng: -97.1555, lat: 49.8721, postalCode: "R3C 0G5" },
    { lng: -62.0258, lat: 45.6213, postalCode: "A0H 1J0" },
    { lng: -65.7151, lat: 46.5481, postalCode: "E4E 1V5" },
    { lng: -98.9679, lat: 49.6748, postalCode: "R5G 1K1" },
    { lng: -125.0052, lat: 49.6788, postalCode: "V9X 1H3" },
    { lng: -79.8443, lat: 44.1585, postalCode: "L0G 1M0" },
    { lng: -79.4688, lat: 44.0435, postalCode: "L4N 8G3" },
    { lng: -122.753, lat: 53.3249, postalCode: "V1G 5A4" },
    { lng: -79.1501, lat: 43.8907, postalCode: "LIA 2V5" },
    { lng: -122.8187, lat: 53.8961, postalCode: "V8C 2K6" },
    { lng: -117.1714, lat: 58.5972, postalCode: "T7P 1R3" },
    { lng: -113.6408, lat: 53.5285, postalCode: "T5P 1B8" },
    { lng: -101.5041, lat: 53.0051, postalCode: "R0L 0J0" },
    { lng: -80.3001, lat: 43.4399, postalCode: "N3Y 1T2" },
    { lng: -81.3802, lat: 44.6729, postalCode: "N0A 1H0" },
    { lng: -78.9816, lat: 46.0308, postalCode: "K0M 1G0" },
    { lng: -66.3755, lat: 49.2043, postalCode: "G0C 3A0" },
    { lng: -81.4261, lat: 44.5597, postalCode: "N0G 2K0" },
    { lng: -119.892, lat: 50.8679, postalCode: "V0E 2X2" },
    { lng: -119.581, lat: 49.4674, postalCode: "V1R 3Y5" },
    { lng: -65.6762, lat: 47.6292, postalCode: "B0E 3B0" },
    { lng: -123.0344, lat: 49.0636, postalCode: "V5P 3R5" },
    { lng: -81.8021, lat: 45.0175, postalCode: "N0K 1M0" },
    { lng: -122.8009, lat: 53.0481, postalCode: "T4N 6R1" },
    { lng: -80.9426, lat: 44.9769, postalCode: "N0M 1G0" },
    { lng: -73.5829, lat: 46.2494, postalCode: "G3H 1R6" },
    { lng: -105.7432, lat: 52.0868, postalCode: "S0K 1Z0" },
    { lng: -79.6668, lat: 44.6009, postalCode: "L0K 1E0" },
    { lng: -79.621, lat: 43.8391, postalCode: "N9B 1S6" },
    { lng: -80.6542, lat: 46.0712, postalCode: "P0H 1V0" },
    { lng: -123.3715, lat: 48.5271, postalCode: "V8J 3W6" },
    { lng: -122.8904, lat: 49.3202, postalCode: "V0V 1B0" },
    { lng: -65.2611, lat: 45.2777, postalCode: "E5R 1R4" },
    { lng: -72.6903, lat: 45.2085, postalCode: "S0E 1E0" },
    { lng: -65.6576, lat: 45.9323, postalCode: "E4Z 1S3" },
    { lng: -76.5149, lat: 45.1879, postalCode: "K0E 1K0" },
    { lng: -81.2855, lat: 43.0149, postalCode: "N6G 5N7" },
    { lng: -80.5277, lat: 43.4643, postalCode: "N2H 6T7" },
    { lng: -110.6757, lat: 50.0349, postalCode: "T0B 1V0" },
    { lng: -74.042, lat: 45.5149, postalCode: "K8V 2M2" },
    { lng: -128.0848, lat: 52.3682, postalCode: "V8J 4A3" },
    { lng: -119.1088, lat: 51.8236, postalCode: "V0K 1V0" },
    { lng: -122.8276, lat: 49.2693, postalCode: "V2A 8V8" },
    { lng: -119.7605, lat: 51.2466, postalCode: "V0N 1H0" },
    { lng: -118.0803, lat: 52.8918, postalCode: "V0P 1G0" },
    { lng: -79.4418, lat: 46.2986, postalCode: "E2S 2N9" },
    { lng: -113.904, lat: 49.6518, postalCode: "T0K 0G0" },
    { lng: -111.785, lat: 53.2306, postalCode: "S0G 1N0" },
    { lng: -80.4503, lat: 43.4266, postalCode: "N2E 3Y9" },
    { lng: -76.7075, lat: 45.3938, postalCode: "Y0A 1C0" },
    { lng: -122.7653, lat: 53.8627, postalCode: "V2M 2L8" },
    { lng: -111.2375, lat: 57.5862, postalCode: "T0H 0J0" },
    { lng: -114.071, lat: 50.9641, postalCode: "T2H 2B3" },
    { lng: -96.9961, lat: 49.8928, postalCode: "R0A 0R0" },
    { lng: -122.7641, lat: 53.8933, postalCode: "V2M 3R8" },
    { lng: -113.9831, lat: 51.0474, postalCode: "T2A 5Z1" },
    { lng: -113.4909, lat: 53.5412, postalCode: "T7X 1E4" },
    { lng: -122.8877, lat: 49.2546, postalCode: "V3K 6M2" },
    { lng: -111.3867, lat: 56.7256, postalCode: "T9M 1P4" },
    { lng: -84.8069, lat: 46.7203, postalCode: "P0C 1H0" },
    { lng: -99.2858, lat: 50.2869, postalCode: "R1A 0L4" },
    { lng: -122.4866, lat: 49.1158, postalCode: "V0X 1X0" },
    { lng: -103.5584, lat: 57.8838, postalCode: "S0J 0N0" },
    { lng: -79.2131, lat: 45.3314, postalCode: "P2A 0A4" },
    { lng: -122.7593, lat: 53.9221, postalCode: "V2C 2W8" },
    { lng: -105.7269, lat: 53.2165, postalCode: "S6V 5P9" },
    { lng: -113.5919, lat: 51.6184, postalCode: "T0B 0S0" },
    { lng: -61.346, lat: 45.2019, postalCode: "B0K 1S0" },
    { lng: -75.742, lat: 45.0009, postalCode: "K0H 1B0" },
    { lng: -81.4379, lat: 44.5517, postalCode: "N0G 2V0" },
    { lng: -60.3065, lat: 46.2207, postalCode: "A0N 2C0" },
    { lng: -123.0508, lat: 48.545, postalCode: "V1T 4S3" },
    { lng: -80.9725, lat: 44.9734, postalCode: "N0H 2T0" },
    { lng: -79.4625, lat: 44.8109, postalCode: "K0K 1X0" },
    { lng: -97.0277, lat: 49.8844, postalCode: "R3C 3L8" },
    { lng: -80.5277, lat: 43.4643, postalCode: "N2H 6T7" },
    { lng: -119.892, lat: 50.8679, postalCode: "V0E 2X2" },
    { lng: -119.581, lat: 49.4674, postalCode: "V1R 3Y5" },
    { lng: -123.0344, lat: 49.0636, postalCode: "V5P 3R5" },
    { lng: -81.8021, lat: 45.0175, postalCode: "N0K 1M0" },
    { lng: -80.9426, lat: 44.9769, postalCode: "N0M 1G0" },
    { lng: -73.5829, lat: 46.2494, postalCode: "G3H 1R6" },
    { lng: -105.7432, lat: 52.0868, postalCode: "S0K 1Z0" },
    { lng: -79.6668, lat: 44.6009, postalCode: "L0K 1E0" },
    { lng: -79.621, lat: 43.8391, postalCode: "N9B 1S6" },
    { lng: -123.3715, lat: 48.5271, postalCode: "V8J 3W6" },
    { lng: -122.8904, lat: 49.3202, postalCode: "V0V 1B0" },
    { lng: -65.2611, lat: 45.2777, postalCode: "E5R 1R4" },
    { lng: -72.6903, lat: 45.2085, postalCode: "S0E 1E0" },
    { lng: -65.6576, lat: 45.9323, postalCode: "E4Z 1S3" },
    { lng: -76.5149, lat: 45.1879, postalCode: "K0E 1K0" },
    { lng: -110.6757, lat: 50.0349, postalCode: "T0B 1V0" },
    { lng: -128.0848, lat: 52.3682, postalCode: "V8J 4A3" },
    { lng: -119.1088, lat: 51.8236, postalCode: "V0K 1V0" },
    { lng: -122.8276, lat: 49.2693, postalCode: "V2A 8V8" },
    { lng: -119.7605, lat: 51.2466, postalCode: "V0N 1H0" },
    { lng: -118.0803, lat: 52.8918, postalCode: "V0P 1G0" },
    { lng: -79.4418, lat: 46.2986, postalCode: "E2S 2N9" },
    { lng: -113.904, lat: 49.6518, postalCode: "T0K 0G0" },
    { lng: -111.785, lat: 53.2306, postalCode: "S0G 1N0" },
    { lng: -80.4503, lat: 43.4266, postalCode: "N2E 3Y9" },
    { lng: -76.7075, lat: 45.3938, postalCode: "Y0A 1C0" },
    { lng: -122.7653, lat: 53.8627, postalCode: "V2M 2L8" },
    { lng: -111.2375, lat: 57.5862, postalCode: "T0H 0J0" },
    { lng: -114.071, lat: 50.9641, postalCode: "T2H 2B3" },
    { lng: -96.9961, lat: 49.8928, postalCode: "R0A 0R0" },
    { lng: -122.7641, lat: 53.8933, postalCode: "V2M 3R8" },
    { lng: -113.9831, lat: 51.0474, postalCode: "T2A 5Z1" },
    { lng: -113.4909, lat: 53.5412, postalCode: "T7X 1E4" },
    { lng: -122.8877, lat: 49.2546, postalCode: "V3K 6M2" },
    { lng: -111.3867, lat: 56.7256, postalCode: "T9M 1P4" },
    { lng: -84.8069, lat: 46.7203, postalCode: "P0C 1H0" },
    { lng: -99.2858, lat: 50.2869, postalCode: "R1A 0L4" },
    { lng: -122.4866, lat: 49.1158, postalCode: "V0X 1X0" },
    { lng: -103.5584, lat: 57.8838, postalCode: "S0J 0N0" },
    { lng: -79.2131, lat: 45.3314, postalCode: "P2A 0A4" },
    { lng: -122.7593, lat: 53.9221, postalCode: "V2C 2W8" },
    { lng: -105.7269, lat: 53.2165, postalCode: "S6V 5P9" },
    { lng: -113.5919, lat: 51.6184, postalCode: "T0B 0S0" },
    { lng: -61.346, lat: 45.2019, postalCode: "B0K 1S0" },
    { lng: -75.742, lat: 45.0009, postalCode: "K0H 1B0" },
    { lng: -81.4379, lat: 44.5517, postalCode: "N0G 2V0" },
    { lng: -60.3065, lat: 46.2207, postalCode: "A0N 2C0" },
    { lng: -123.0508, lat: 48.545, postalCode: "V1T 4S3" },
    { lng: -80.9725, lat: 44.9734, postalCode: "N0H 2T0" },
    { lng: -79.4625, lat: 44.8109, postalCode: "K0K 1X0" },
    { lng: -97.0277, lat: 49.8844, postalCode: "R3C 3L8" },
    { lng: -80.5277, lat: 43.4643, postalCode: "N2H 6T7" },
    { lng: -119.892, lat: 50.8679, postalCode: "V0E 2X2" },
    { lng: -119.581, lat: 49.4674, postalCode: "V1R 3Y5" },
    { lng: -123.0344, lat: 49.0636, postalCode: "V5P 3R5" },
    { lng: -81.8021, lat: 45.0175, postalCode: "N0K 1M0" },
    { lng: -80.9426, lat: 44.9769, postalCode: "N0M 1G0" },
    { lng: -73.5829, lat: 46.2494, postalCode: "G3H 1R6" },
    { lng: -105.7432, lat: 52.0868, postalCode: "S0K 1Z0" },
    { lng: -79.6668, lat: 44.6009, postalCode: "L0K 1E0" },
    { lng: -79.621, lat: 43.8391, postalCode: "N9B 1S6" },
    { lng: -123.3715, lat: 48.5271, postalCode: "V8J 3W6" },
    { lng: -122.8904, lat: 49.3202, postalCode: "V0V 1B0" },
    { lng: -65.2611, lat: 45.2777, postalCode: "E5R 1R4" },
    { lng: -72.6903, lat: 45.2085, postalCode: "S0E 1E0" },
    { lng: -65.6576, lat: 45.9323, postalCode: "E4Z 1S3" },
    { lng: -76.5149, lat: 45.1879, postalCode: "K0E 1K0" },
    { lng: -80.2421, lat: 43.1789, postalCode: "N3L -4C9" },
    { lng: -80.9084, lat: 49.0324, postalCode: "N9E 3W1" },
    { lng: -73.1269, lat: 45.4823, postalCode: "L3V 2S7" },
    { lng: -121.971, lat: 49.1272, postalCode: "V4K 3C1" },
    { lng: -72.4179, lat: 46.3091, postalCode: "V8K 2B2" },
    { lng: -74.0139, lat: 45.6319, postalCode: "J8R 3M2" },
    { lng: -107.0228, lat: 50.6329, postalCode: "S9H 0R9" },
    { lng: -65.2022, lat: 45.7686, postalCode: "E4H 1A4" },
    { lng: -97.1577, lat: 49.9096, postalCode: "R2W 2A2" },
    { lng: -98.5222, lat: 50.2667, postalCode: "N9C 1C6" },
    { lng: -119.1088, lat: 51.2466, postalCode: "V0K 1V0" },
    { lng: -79.471, lat: 44.3056, postalCode: "L9S 2E4" },
    { lng: -65.7292, lat: 45.3534, postalCode: "B0P 1G0" },
    { lng: -79.3402, lat: 44.9061, postalCode: "K0L 1E0" },
    { lng: -81.0912, lat: 42.9731, postalCode: "N4W 3G6" },
    { lng: -75.7285, lat: 45.2581, postalCode: "K2J 5C7" },
    { lng: -73.7613, lat: 45.8532, postalCode: "J0E 1X0" },
    { lng: -124.0609, lat: 49.369, postalCode: "V9G 1B8" },
    { lng: -79.3492, lat: 44.6333, postalCode: "P0E 1N0" },
    { lng: -113.586, lat: 53.2822, postalCode: "T6E 0K1" },
    { lng: -74.2991, lat: 45.5277, postalCode: "J2G 3N8" },
    { lng: -122.7394, lat: 53.887, postalCode: "V2N 2S5" },
    { lng: -60.0377, lat: 46.1851, postalCode: "B2A 2A5" },
    { lng: -79.2328, lat: 47.7973, postalCode: "J9X 2Y9" },
    { lng: -79.8534, lat: 43.5186, postalCode: "ROC1B0" },
    { lng: -79.8534, lat: 43.5186, postalCode: "ROC1B0" },
    { lng: -106.9998, lat: 52.1968, postalCode: "S0L 2A0" },
    { lng: -75.9242, lat: 45.0089, postalCode: "K0H 1X0" },
    { lng: -75.4271, lat: 45.2264, postalCode: "K4A 0Z6" },
    { lng: -75.5098, lat: 45.5321, postalCode: "K1L 6N7" },
    { lng: -113.8811, lat: 53.5566, postalCode: "T8X 6R1" },
    { lng: -73.9831, lat: 45.7935, postalCode: "J7X 1A1" },
    { lng: -65.7564, lat: 45.6737, postalCode: "J0Z 3H0" },
    { lng: -64.8712, lat: 45.6939, postalCode: "E4S 3V8" },
    { lng: -79.6861, lat: 44.4182, postalCode: "N0H 2L0" },
    { lng: -126.7782, lat: 52.4068, postalCode: "A0B 3A0" },
    { lng: -79.7082, lat: 44.4373, postalCode: "L0L 1T0" },
    { lng: -113.9123, lat: 53.5329, postalCode: "T6K" },
    { lng: -79.7082, lat: 44.4373, postalCode: "L0L1T0" },
    { lng: -74.0697, lat: 40.7081, postalCode: "1782 KD" },
    { lng: -75.1105, lat: 40.0115, postalCode: "19134-3605" },
    { lng: -75.5181, lat: 39.9818, postalCode: "19940-2431" },
    { lng: -75.1638, lat: 39.9873, postalCode: "19134-3605" },
    { lng: -75.1105, lat: 40.0115, postalCode: "19940-2431" },
    { lng: -75.2232, lat: 39.8336, postalCode: "19134-3605" },
    { lng: -75.1216, lat: 39.9943, postalCode: "19940-2431" },
    { lng: -75.2232, lat: 39.8336, postalCode: "19134-3605" },
    { lng: -75.1216, lat: 39.9943, postalCode: "19940-2431" },
    { lng: -75.1105, lat: 40.0115, postalCode: "19940-2431" },
    { lng: -77.442, lat: 39.9354, postalCode: "19940-2431" },
    { lng: -71.4418, lat: 42.8591, postalCode: "1841" },
    { lng: -71.3789, lat: 42.9032, postalCode: "4841" },
    { lng: -71.6205, lat: 42.8531, postalCode: "1821" },
    { lng: -70.9565, lat: 42.1738, postalCode: "2536" },
    { lng: -70.7612, lat: 43.3863, postalCode: "05048-9506" },
    { lng: -73.2874, lat: 42.2349, postalCode: "5257" },
    { lng: -72.5771, lat: 42.1946, postalCode: "01013-2917" },
    { lng: -72.5881, lat: 42.8424, postalCode: "1081" },
    { lng: -72.2433, lat: 42.9283, postalCode: "03743-2674" },
    { lng: -72.0397, lat: 42.8501, postalCode: "03743-4866" },
    { lng: -68.9321, lat: 44.7884, postalCode: "4401" },
    { lng: -68.8783, lat: 44.4476, postalCode: "4449" },
    { lng: -69.0568, lat: 44.2827, postalCode: "4841" },
    { lng: -70.7049, lat: 44.4617, postalCode: "4976" },
    { lng: -71.6882, lat: 43.5381, postalCode: "5701" },
    { lng: -72.2359, lat: 43.4383, postalCode: "5733" },
    { lng: -71.8564, lat: 41.6875, postalCode: "6239" },
    { lng: -72.0784, lat: 41.9463, postalCode: "6339" },
    { lng: -73.1805, lat: 41.2998, postalCode: "6611" },
    { lng: -73.1805, lat: 41.2998, postalCode: "6611" },
    { lng: -73.1805, lat: 41.2998, postalCode: "6611" },
    { lng: -74.2479, lat: 40.9189, postalCode: "7653" },
    { lng: -74.7327, lat: 40.022, postalCode: "8031" },
    { lng: -75.0122, lat: 40.0066, postalCode: "8035" },
    { lng: -74.9905, lat: 40.0462, postalCode: "8052" },
    { lng: -79.2882, lat: 42.0929, postalCode: "14701-4939" },
    { lng: -80.5814, lat: 41.0723, postalCode: "15063-2361" },
    { lng: -71.9436, lat: 41.8339, postalCode: "288" },
    { lng: -75.1152, lat: 39.9937, postalCode: "19134-3605" },
    { lng: -75.2278, lat: 39.8991, postalCode: "19940-2431" },
    { lng: -74.4173, lat: 40.8506, postalCode: "201" },
    { lng: -76.4093, lat: 38.2607, postalCode: "20646-4870" },
    { lng: -76.2295, lat: 39.9348, postalCode: "2213" },
    { lng: -77.4914, lat: 37.8475, postalCode: "22551" },
    { lng: -76.6018, lat: 37.1485, postalCode: "23402" },
    { lng: -76.2721, lat: 36.9041, postalCode: "23944-3812" },
    { lng: -81.7025, lat: 30.245, postalCode: "32210-6131" },
    { lng: -81.3674, lat: 28.5466, postalCode: "32805-1456" },
    { lng: -82.1574, lat: 28.5046, postalCode: "33538-6108" },
    { lng: -82.0177, lat: 28.6698, postalCode: "34785-9178" },
    { lng: -85.6171, lat: 34.6165, postalCode: "35957-8141" },
    { lng: -85.6777, lat: 34.6227, postalCode: "35960-" },
    { lng: -86.9679, lat: 35.4653, postalCode: "370" },
    { lng: -82.1223, lat: 36.0771, postalCode: "37650-1915" },
    { lng: -83.6977, lat: 37.3533, postalCode: "40440-9512" },
    { lng: -83.643, lat: 37.5275, postalCode: "40444-7642" },
    { lng: -84.2918, lat: 37.6485, postalCode: "40456-2846" },
    { lng: -73.758, lat: 41.7519, postalCode: "412 40" },
    { lng: -73.951, lat: 41.6981, postalCode: "4172" },
    { lng: -85.8606, lat: 37.0491, postalCode: "42501-1625" },
    { lng: -85.364, lat: 37.2639, postalCode: "42522" },
    { lng: -82.389, lat: 39.4291, postalCode: "43138-9071" },
    { lng: -82.3546, lat: 39.4024, postalCode: "435101" },
    { lng: -81.758, lat: 39.3912, postalCode: "437255" },
    { lng: -80.9566, lat: 40.6974, postalCode: "446601" },
    { lng: -84.0142, lat: 39.8914, postalCode: "4506845068" },
    { lng: -84.5667, lat: 39.6218, postalCode: "45612-9783" },
    { lng: -84.8542, lat: 39.9261, postalCode: "46148-1202" },
    { lng: -85.1363, lat: 39.8279, postalCode: "47362-2715" },
    { lng: -93.9847, lat: 45.2991, postalCode: "487" },
    { lng: -85.341, lat: 38.1903, postalCode: "49037" },
    { lng: -85.2877, lat: 39.1373, postalCode: "49245-9635" },
    { lng: -86.5992, lat: 45.8945, postalCode: "49861-9055" },
    { lng: -86.6219, lat: 46.7485, postalCode: "49935-7852" },
    { lng: -83.4165, lat: 42.9784, postalCode: "5000" },
    { lng: -90.2083, lat: 41.5407, postalCode: "51103-3202" },
    { lng: -95.9152, lat: 41.2588, postalCode: "51501-3726" },
    { lng: -90.2697, lat: 42.6648, postalCode: "53801-9504" },
    { lng: -89.2904, lat: 44.8969, postalCode: "54923-1278" },
    { lng: -71.4103, lat: 42.2465, postalCode: "5701 MN" },
    { lng: -79.686, lat: 43.3963, postalCode: "76066" },
    { lng: -97.2586, lat: 32.7061, postalCode: "767" },
    { lng: -85.9308, lat: 39.8345, postalCode: "60942-1729" },
    { lng: -89.6025, lat: 40.8106, postalCode: "61104-3041" },
    { lng: -88.6335, lat: 40.2255, postalCode: "61738-1487" },
    { lng: -89.1185, lat: 38.6827, postalCode: "62016-1018" },
    { lng: -90.5999, lat: 37.3248, postalCode: "63857-3429" },
    { lng: -93.177, lat: 39.3295, postalCode: "64628-2302" },
    { lng: -97.1144, lat: 38.4607, postalCode: "67114-4205" },
    { lng: -103.201, lat: 41.2741, postalCode: "69165-2146" },
    { lng: -91.3007, lat: 30.1888, postalCode: "70536" },
    { lng: -90.5404, lat: 36.8126, postalCode: "72421-8858" },
    { lng: -97.2383, lat: 32.942, postalCode: "76248-4911" },
    { lng: -94.8514, lat: 29.8655, postalCode: "77562-3906" },
    { lng: -96.5667, lat: 29.9862, postalCode: "78947-6389" },
    { lng: -101.5112, lat: 34.8905, postalCode: "79079-2409" },
    { lng: -106.0458, lat: 38.8838, postalCode: "81226-7502" },
    { lng: -116.3032, lat: 43.6364, postalCode: "83646" },
    { lng: -116.2764, lat: 43.5437, postalCode: "83709-5888" },
    { lng: -84.7215, lat: 38.1901, postalCode: "8472184721" },
    { lng: -111.549, lat: 33.4116, postalCode: "85119" },
    { lng: -111.6324, lat: 33.2438, postalCode: "85140" },
    { lng: -112.0794, lat: 33.6601, postalCode: "85396" },
    { lng: -111.5598, lat: 33.5364, postalCode: "85501-4349" },
    { lng: -110.7633, lat: 32.3645, postalCode: "8640286412" },
    { lng: -114.3783, lat: 34.5521, postalCode: "86409" },
    { lng: -107.6669, lat: 33.3006, postalCode: "88415-2407" },
    { lng: -116.3293, lat: 33.364, postalCode: "92272" },
    { lng: -117.2823, lat: 34.507, postalCode: "92345-5539" },
    { lng: -118.3998, lat: 46.0646, postalCode: "99362-2946" },
    { lng: -52.711, lat: 47.595, postalCode: "986" },
    { lng: -53.9946, lat: 48.4785, postalCode: "98" },
    { lng: -66.3256, lat: 45.3008, postalCode: "B0E3B0" },
    { lng: -63.0654, lat: 44.8939, postalCode: "B0P1G0" },
    { lng: -61.7285, lat: 47.2792, postalCode: "B0W2A0" },
    { lng: -64.2046, lat: 47.5226, postalCode: "B0v1a0" },
    { lng: -57.1602, lat: 47.8575, postalCode: "B1H1K1" },
    { lng: -58.5899, lat: 47.5054, postalCode: "B1a2l6" },
    { lng: -57.9085, lat: 47.6419, postalCode: "B2A2A5" },
    { lng: -60.574, lat: 48.9946, postalCode: "B3g1b5" },
    { lng: -60.2204, lat: 48.8816, postalCode: "B987EE" },
    { lng: -0.1041, lat: 51.6062, postalCode: "BH11 8BT" },
    { lng: -4.7075, lat: 57.2694, postalCode: "Bt42 1ln" },
    { lng: -3.706, lat: 53.4808, postalCode: "CA101JG" },
    { lng: -3.263, lat: 51.4965, postalCode: "CF398AE" },
    { lng: -0.0414, lat: 53.5501, postalCode: "DN38 6FE" },
    { lng: -4.6275, lat: 56.0088, postalCode: "Dd11 1pn" },
    { lng: -2.2069, lat: 52.3906, postalCode: "Dy10 1ss" },
    { lng: -0.0752, lat: 51.5485, postalCode: "E3l4v1" },
    { lng: -0.0634, lat: 51.508, postalCode: "E4C3N7" },
    { lng: -2.9184, lat: 51.533, postalCode: "E4Z1S3" },
    { lng: -0.0402, lat: 51.5378, postalCode: "E4e1v5" },
    { lng: -0.0326, lat: 51.5385, postalCode: "E4s3v8" },
    { lng: 0.0202, lat: 51.5423, postalCode: "E5R1R4" },
    { lng: -6.2088, lat: 51.5112, postalCode: "F51 Y294" },
    { lng: -0.225, lat: 51.6101, postalCode: "H8n2a3" },
    { lng: -0.0222, lat: 51.5632, postalCode: "H8s3t1" },
    { lng: 0.0009, lat: 51.5259, postalCode: "J0E1X0" },
    { lng: -6.3925, lat: 53.0131, postalCode: "J0k 3K0" },
    { lng: -73.6417, lat: 45.5017, postalCode: "J2G 7H3" },
    { lng: -73.79, lat: 45.4746, postalCode: "J5Y1S1" },
    { lng: -79.787, lat: 45.4779, postalCode: "J7X1 1A1" },
    { lng: -74.1, lat: 45.7317, postalCode: "J8R3M2" },
    { lng: -78.1507, lat: 45.9636, postalCode: "J9T3L7" },
    { lng: -0.9667, lat: 50.2833, postalCode: "K0E1K0" },
    { lng: -0.5886, lat: 49.9068, postalCode: "K0H1X0" },
    { lng: -1.0575, lat: 49.6363, postalCode: "K0k1x0" },
    { lng: -0.7785, lat: 49.9246, postalCode: "K0k2k0" },
    { lng: -78.1335, lat: 45.0621, postalCode: "K6v6n2" },
    { lng: -78.05, lat: 45.2667, postalCode: "K7R3T3" },
    { lng: -66.3833, lat: 45.85, postalCode: "K8v2m2" },
    { lng: -71.8167, lat: 47.45, postalCode: "KOL2W0" },
    { lng: -64.3294, lat: 44.5867, postalCode: "Koe1lo" },
    { lng: -79.7667, lat: 44.3833, postalCode: "L0L1T0" },
    { lng: -79.3167, lat: 44.1333, postalCode: "L1A 3v5" },
    { lng: -79.2544, lat: 43.7762, postalCode: "L2N-1H6" },
    { lng: -79.6576, lat: 43.4483, postalCode: "L2g6z7" },
    { lng: -79.1239, lat: 43.7709, postalCode: "L3k 1z6" },
    { lng: -79.2333, lat: 44.05, postalCode: "L4P3B6" },
    { lng: -79.82, lat: 43.23, postalCode: "L9A2X2" },
    { lng: -79.7585, lat: 44.2413, postalCode: "L9M1P4" },
    { lng: -79.3554, lat: 44.1466, postalCode: "L9S2E4" },
    { lng: -79.8075, lat: 44.1727, postalCode: "L9y3r4" },
    { lng: -3.1861, lat: 53.3412, postalCode: "LL18 2LB" },
    { lng: -5.9632, lat: 54.1792, postalCode: "Lia2v5" },
    { lng: -79.246, lat: 43.82, postalCode: "M1N 1R5" },
    { lng: -79.893, lat: 43.6798, postalCode: "M6n5j1" },
    { lng: -80.3167, lat: 43.5333, postalCode: "Mi" },
    { lng: -80.172, lat: 43.8035, postalCode: "N0A1H0" },
    { lng: -80.4897, lat: 43.4985, postalCode: "N0G2J0" },
    { lng: -80.4897, lat: 43.4985, postalCode: "N0G2V0" },
    { lng: -80.9011, lat: 43.1314, postalCode: "N0P 2C0" },
    { lng: -80.8375, lat: 43.1291, postalCode: "N0P1A0" },
    { lng: -80.1358, lat: 43.0509, postalCode: "N0h2t0" },
    { lng: -80.171, lat: 44.2973, postalCode: "N1H5Z2" },
    { lng: -80.2503, lat: 43.3666, postalCode: "N2L3N6" },
    { lng: -79.7333, lat: 43.0167, postalCode: "N3L2T7" },
    { lng: -79.5667, lat: 43.1167, postalCode: "N3Y1T2" },
    { lng: -79.7667, lat: 43.6667, postalCode: "N3l-4c9" },
    { lng: -78.9167, lat: 43.5, postalCode: "N4w3g6" },
    { lng: -80.9854, lat: 43.2389, postalCode: "N5W2R6" },
    { lng: -81.9, lat: 43.25, postalCode: "N5r5s8" },
    { lng: -82.3435, lat: 42.9961, postalCode: "N8T 2X9" },
    { lng: -82.3677, lat: 42.2941, postalCode: "N9B1S6" },
    { lng: -82.8164, lat: 42.1612, postalCode: "N9E3W1" },
    { lng: -82.2015, lat: 42.2462, postalCode: "N9c1c6" },
    { lng: -1.4901, lat: 53.5033, postalCode: "NE12 6GN" },
    { lng: -79.6961, lat: 43.4295, postalCode: "NOP1A0" },
    { lng: -90.577, lat: 43.1846, postalCode: "O4463" },
    { lng: -2.1972, lat: 53.63, postalCode: "OL13 0JH" },
    { lng: -79.7029, lat: 44.5757, postalCode: "P0C1H0" },
    { lng: -79.9108, lat: 44.6867, postalCode: "P0H 2m0" },
    { lng: -79.686, lat: 44.5757, postalCode: "P0H1V0" },
    { lng: -77.3678, lat: 44.731, postalCode: "P0L1C0" },
    { lng: -79.9603, lat: 44.6784, postalCode: "P0e1n0" },
    { lng: -80.9339, lat: 44.5235, postalCode: "P1H1J6" },
    { lng: -79.2839, lat: 44.4232, postalCode: "P1H1V3" },
    { lng: -79.3761, lat: 43.7471, postalCode: "PA11 3NB" },
    { lng: -78.0982, lat: 44.4884, postalCode: "PH2 7PX" },
    { lng: -80.3039, lat: 44.606, postalCode: "POM-1WO" },
    { lng: -0.1262, lat: 52.2488, postalCode: "R0L ojo" },
    { lng: -0.2356, lat: 52.2043, postalCode: "R0L1M0" },
    { lng: -81.6868, lat: 42.9961, postalCode: "R2W2A2" },
    { lng: -81.9923, lat: 42.9447, postalCode: "R3C3L8" },
    { lng: -81.1582, lat: 48.5197, postalCode: "Rinn3p1" },
    { lng: -63.5828, lat: 45.6177, postalCode: "Roc1B0" },
    { lng: -63.5828, lat: 45.6177, postalCode: "Roc1b0" },
    { lng: -64.9384, lat: 44.5404, postalCode: "S0E-1S0" },
    { lng: -61.0475, lat: 45.1057, postalCode: "S0J0N0" },
    { lng: -61.2352, lat: 45.1695, postalCode: "S0K0Z0" },
    { lng: -61.0912, lat: 45.1664, postalCode: "S0K1Z0" },
    { lng: -79.2652, lat: 43.7597, postalCode: "S0e1e0" },
    { lng: -75.2245, lat: 44.1744, postalCode: "S0l2a0" },
    { lng: -75.7199, lat: 44.8028, postalCode: "S4R 3H1" },
    { lng: -106.6731, lat: 52.1333, postalCode: "S6V 5p9" },
    { lng: -4.8552, lat: 53.2208, postalCode: "SA12 6ES" },
    { lng: -5.9932, lat: 54.8324, postalCode: "Soj 1lo" },
    { lng: -79.4732, lat: 44.6305, postalCode: "T0B0S0" },
    { lng: -79.3671, lat: 44.5733, postalCode: "T0B3L0" },
    { lng: -79.8124, lat: 44.2765, postalCode: "T0E0B0" },
    { lng: -79.915, lat: 44.7748, postalCode: "T0H2M0" },
    { lng: -114.0953, lat: 52.0731, postalCode: "T2A5Z1" },
    { lng: -106.566, lat: 53.1333, postalCode: "T2J0R1" },
    { lng: -63.5667, lat: 44.4167, postalCode: "T2h2b3" },
    { lng: -76.8, lat: 44.5167, postalCode: "T4n6r1" },
    { lng: -80.5, lat: 43.2667, postalCode: "T6e0k1" },
    { lng: -89.8, lat: 47.4333, postalCode: "T7V1N4" },
    { lng: -89.1333, lat: 48.3667, postalCode: "T7X1E4" },
    { lng: -98.3, lat: 49.7333, postalCode: "T7a1r3" },
    { lng: -93.0667, lat: 49.7, postalCode: "T7p1r3" },
    { lng: -106.6986, lat: 49.4167, postalCode: "T8V5J3" },
    { lng: -106.9764, lat: 49.1159, postalCode: "T8a 3l1" },
    { lng: -106.7836, lat: 49.4143, postalCode: "T8x 6r1" },
    { lng: 0.7734, lat: 51.3294, postalCode: "TN18 4HX" },
    { lng: -123.3618, lat: 49.6861, postalCode: "TOE-OYO" },
    { lng: -119.2652, lat: 50.2547, postalCode: "V0E2X2" },
    { lng: -118.7896, lat: 49.3378, postalCode: "V0H1J0" },
    { lng: -122.9486, lat: 49.1768, postalCode: "V0J 1Y5" },
    { lng: -123.738, lat: 49.2756, postalCode: "V0J- 2X2" },
    { lng: -126.0771, lat: 49.8153, postalCode: "V0N3K0" },
    { lng: -130.3242, lat: 54.3161, postalCode: "V0X1X0" },
    { lng: -123.7191, lat: 49.7003, postalCode: "V0h1x2" },
    { lng: -122.891, lat: 49.0297, postalCode: "V0v 1b0" },
    { lng: -124.0248, lat: 49.7256, postalCode: "V1R3Y5" },
    { lng: -73.7549, lat: 45.6123, postalCode: "V2M2L8" },
    { lng: -71.2829, lat: 46.0103, postalCode: "V2a8v8" },
    { lng: -123.944, lat: 49.2412, postalCode: "V2v6y2" },
    { lng: -72.9386, lat: 48.3636, postalCode: "V3k6m2" },
    { lng: -76.2839, lat: 45.9667, postalCode: "V3r 2a6" },
    { lng: -79.2833, lat: 43.9167, postalCode: "V3t 1c9" },
    { lng: -123.1063, lat: 49.1774, postalCode: "V5P3R5" },
    { lng: -122.8167, lat: 49.7, postalCode: "V8J3W6" },
    { lng: -123.8, lat: 49.1667, postalCode: "V8J4A3" },
    { lng: -123.895, lat: 49.1479, postalCode: "V8K2B2" },
    { lng: -123.9423, lat: 49.2598, postalCode: "V8c2k6" },
    { lng: -123.2833, lat: 48.8667, postalCode: "V8m0c4" },
    { lng: -129.0323, lat: 49.6865, postalCode: "V9X" },
    { lng: -117.3691, lat: 49.7774, postalCode: "V9X1H3" },
    { lng: -123.9339, lat: 48.8793, postalCode: "V9g1b8" },
    { lng: -123.972, lat: 48.7855, postalCode: "VOJ 2AO" },
    { lng: -119.2572, lat: 49.0549, postalCode: "VON1H0" },
    { lng: -123.6512, lat: 48.4481, postalCode: "Vislanda" },
    { lng: -79.8631, lat: 43.5844, postalCode: "W93dz" },
    { lng: -2.7386, lat: 53.6417, postalCode: "WN8 8DL" },
    { lng: -1.9482, lat: 52.1442, postalCode: "WR10 1BZ" },
    { lng: -3.0358, lat: 53.5186, postalCode: "X91YN8P" },
    { lng: -1.9637, lat: 52.677, postalCode: "Yoa 1co" },
    { lng: -73.4167, lat: 45.6333, postalCode: "b3r1s1" },
    { lng: -64.3353, lat: 44.0613, postalCode: "g0c3a0" },
    { lng: -81.8453, lat: 43.4346, postalCode: "k0m1g0" },
    { lng: -79.3742, lat: 43.2046, postalCode: "n0r1a0" },
    { lng: -80.2306, lat: 43.1009, postalCode: "s0j3bo" },
    { lng: -80.0165, lat: 43.3717, postalCode: "sobh0b0" },
    { lng: 48.2073, lat: 16.3724, postalCode: "1010" },
    { lng: 47.8011, lat: 13.044, postalCode: "4020" },
    { lng: 48.2088, lat: 16.3725, postalCode: "1030" },
    { lng: 47.8011, lat: 13.044, postalCode: "5020" },
    { lng: 48.2084, lat: 16.3725, postalCode: "1090" },
    { lng: 48.2033, lat: 14.3025, postalCode: "4020" },
    { lng: 47.8011, lat: 13.044, postalCode: "6020" },
    { lng: 48.2087, lat: 16.3724, postalCode: "1200" },
    { lng: 47.8011, lat: 13.044, postalCode: "5023" },
    { lng: 48.2086, lat: 16.3725, postalCode: "1070" },
    { lng: 47.8011, lat: 13.044, postalCode: "8053" },
    { lng: 48.2032, lat: 14.3033, postalCode: "4060" },
    { lng: 48.2089, lat: 16.3725, postalCode: "1020" },
    { lng: 47.8011, lat: 13.044, postalCode: "8045" },
    { lng: 47.8314, lat: 13.0382, postalCode: "5020" },
    { lng: 47.8011, lat: 13.044, postalCode: "5018" },
    { lng: 48.2085, lat: 16.3724, postalCode: "1080" },
    { lng: 47.8011, lat: 13.044, postalCode: "5026" },
    { lng: 48.2087, lat: 16.3726, postalCode: "1040" },
    { lng: 47.8011, lat: 13.044, postalCode: "4053" },
    { lng: 48.2086, lat: 16.3725, postalCode: "1071" },
    { lng: 47.8316, lat: 13.0402, postalCode: "6021" },
    { lng: 47.8066, lat: 13.0506, postalCode: "5071" },
    { lng: 47.824, lat: 13.0457, postalCode: "6024" },
    { lng: 50.8466, lat: 4.3528, postalCode: "1000" },
    { lng: 51.2154, lat: 4.4204, postalCode: "2000" },
    { lng: 51.0532, lat: 3.7306, postalCode: "9000" },
    { lng: 51.2152, lat: 4.4095, postalCode: "2018" },
    { lng: 50.828, lat: 4.3531, postalCode: "1070" },
    { lng: 51.2154, lat: 4.4204, postalCode: "2018" },
    { lng: 50.8499, lat: 4.3556, postalCode: "1040" },
    { lng: 50.8542, lat: 4.3587, postalCode: "1080" },
    { lng: 50.9512, lat: 4.1997, postalCode: "8800" },
    { lng: 51.2121, lat: 4.4377, postalCode: "2060" },
    { lng: 50.839, lat: 4.364, postalCode: "1060" },
    { lng: 51.2169, lat: 4.3995, postalCode: "9000" },
    { lng: 50.8498, lat: 4.3502, postalCode: "1050" },
    { lng: 50.8503, lat: 4.3512, postalCode: "1005" },
    { lng: 50.8525, lat: 4.3563, postalCode: "1006" },
    { lng: 50.853, lat: 4.3525, postalCode: "1007" },
    { lng: 50.8565, lat: 4.3551, postalCode: "1008" },
    { lng: 50.8571, lat: 4.3581, postalCode: "1009" },
    { lng: 50.8634, lat: 4.3544, postalCode: "1030" },
    { lng: 50.8731, lat: 4.3586, postalCode: "1049" },
    { lng: 50.8638, lat: 4.3643, postalCode: "1069" },
    { lng: 50.8547, lat: 4.3726, postalCode: "1077" },
    { lng: 50.857, lat: 4.3728, postalCode: "1081" },
    { lng: 50.8552, lat: 4.3661, postalCode: "1082" },
    { lng: 48.8618, lat: 2.335, postalCode: "75001" },
    { lng: 48.8426, lat: 2.2921, postalCode: "75015" },
    { lng: 48.8768, lat: 2.283, postalCode: "75016" },
    { lng: 48.8836, lat: 2.3196, postalCode: "75017" },
    { lng: 48.892, lat: 2.3439, postalCode: "75018" },
    { lng: 48.8776, lat: 2.3871, postalCode: "75019" },
    { lng: 48.8712, lat: 2.3831, postalCode: "75020" },
    { lng: 48.8592, lat: 2.3808, postalCode: "75011" },
    { lng: 48.8425, lat: 2.3845, postalCode: "75012" },
    { lng: 48.8306, lat: 2.3736, postalCode: "75013" },
    { lng: 48.8372, lat: 2.3499, postalCode: "75014" },
    { lng: 48.8655, lat: 2.3456, postalCode: "75002" },
    { lng: 48.8635, lat: 2.359, postalCode: "75003" },
    { lng: 48.8543, lat: 2.3561, postalCode: "75004" },
    { lng: 48.8546, lat: 2.3494, postalCode: "75005" },
    { lng: 48.8462, lat: 2.3445, postalCode: "75006" },
    { lng: 48.8561, lat: 2.3364, postalCode: "75007" },
    { lng: 48.8765, lat: 2.3087, postalCode: "75008" },
    { lng: 48.8825, lat: 2.3125, postalCode: "75009" },
    { lng: 48.8794, lat: 2.3417, postalCode: "75010" },
    { lng: 48.8734, lat: 2.2803, postalCode: "75116" },
    { lng: 48.8446, lat: 2.269, postalCode: "75115" },
    { lng: 48.8566, lat: 2.3533, postalCode: "75112" },
    { lng: 48.8686, lat: 2.293, postalCode: "75113" },
    { lng: 52.5163, lat: 13.3777, postalCode: "10115" },
    { lng: 52.5195, lat: 13.378, postalCode: "10117" },
    { lng: 52.5201, lat: 13.3967, postalCode: "10119" },
    { lng: 52.5255, lat: 13.3946, postalCode: "10178" },
    { lng: 52.5201, lat: 13.3989, postalCode: "10179" },
    { lng: 52.5103, lat: 13.4393, postalCode: "10243" },
    { lng: 52.5164, lat: 13.4653, postalCode: "10245" },
    { lng: 52.5166, lat: 13.4712, postalCode: "10247" },
    { lng: 52.5147, lat: 13.4756, postalCode: "10249" },
    { lng: 52.5171, lat: 13.4869, postalCode: "10315" },
    { lng: 52.5186, lat: 13.4952, postalCode: "10317" },
    { lng: 52.5173, lat: 13.5011, postalCode: "10318" },
    { lng: 52.5189, lat: 13.5021, postalCode: "10319" },
    { lng: 52.527, lat: 13.4081, postalCode: "10405" },
    { lng: 52.5291, lat: 13.4221, postalCode: "10407" },
    { lng: 52.5279, lat: 13.4323, postalCode: "10409" },
    { lng: 52.5398, lat: 13.429, postalCode: "10435" },
    { lng: 52.5371, lat: 13.4443, postalCode: "10437" },
    { lng: 52.5334, lat: 13.4566, postalCode: "10439" },
    { lng: 52.5264, lat: 13.3643, postalCode: "10551" },
    { lng: 52.5235, lat: 13.362, postalCode: "10553" },
    { lng: 52.5276, lat: 13.3617, postalCode: "10555" },
    { lng: 52.5298, lat: 13.3512, postalCode: "10557" },
    { lng: 52.5296, lat: 13.3577, postalCode: "10559" },
    { lng: 53.3498, lat: -6.2603, postalCode: "D01" },
    { lng: 53.337, lat: -6.2658, postalCode: "D02" },
    { lng: 53.3598, lat: -6.2988, postalCode: "D03" },
    { lng: 53.3397, lat: -6.2715, postalCode: "D04" },
    { lng: 53.3368, lat: -6.2606, postalCode: "D05" },
    { lng: 53.3379, lat: -6.2546, postalCode: "D06" },
    { lng: 53.3556, lat: -6.2683, postalCode: "D07" },
    { lng: 53.3346, lat: -6.2448, postalCode: "D08" },
    { lng: 53.367, lat: -6.2146, postalCode: "D09" },
    { lng: 53.3697, lat: -6.2208, postalCode: "D10" },
    { lng: 53.3467, lat: -6.2204, postalCode: "D11" },
    { lng: 53.3324, lat: -6.2441, postalCode: "D12" },
    { lng: 53.3294, lat: -6.2542, postalCode: "D13" },
    { lng: 53.3284, lat: -6.2665, postalCode: "D14" },
    { lng: 53.3226, lat: -6.3013, postalCode: "D15" },
    { lng: 53.319, lat: -6.2977, postalCode: "D16" },
    { lng: 53.3143, lat: -6.2703, postalCode: "D17" },
    { lng: 53.3207, lat: -6.2694, postalCode: "D18" },
    { lng: 53.329, lat: -6.296, postalCode: "D19" },
    { lng: 53.3379, lat: -6.3145, postalCode: "D20" },
    { lng: 53.3397, lat: -6.307, postalCode: "D21" },
    { lng: 53.346, lat: -6.3123, postalCode: "D22" },
    { lng: 53.3414, lat: -6.2925, postalCode: "D23" },
    { lng: 53.3455, lat: -6.2742, postalCode: "D24" },
    { lng: 41.903, lat: -12.4533, postalCode: "100" },
    { lng: 41.904, lat: -12.4513, postalCode: "118" },
    { lng: 41.9097, lat: -12.477, postalCode: "119" },
    { lng: 41.9102, lat: -12.4766, postalCode: "120" },
    { lng: 41.9103, lat: -12.4676, postalCode: "121" },
    { lng: 41.9118, lat: -12.4661, postalCode: "122" },
    { lng: 41.8919, lat: 12.5113, postalCode: "128" },
    { lng: 41.9222, lat: 12.532, postalCode: "133" },
    { lng: 41.9314, lat: 12.4992, postalCode: "135" },
    { lng: 41.9345, lat: 12.4618, postalCode: "136" },
    { lng: 41.9368, lat: 12.4998, postalCode: "137" },
    { lng: 41.9098, lat: 12.4301, postalCode: "139" },
    { lng: 41.8982, lat: 12.4873, postalCode: "141" },
    { lng: 41.8988, lat: 12.4344, postalCode: "142" },
    { lng: 41.8721, lat: 12.5054, postalCode: "144" },
    { lng: 41.8566, lat: 12.4759, postalCode: "146" },
    { lng: 41.8451, lat: 12.481, postalCode: "148" },
    { lng: 41.8477, lat: 12.5028, postalCode: "149" },
    { lng: 41.86, lat: 12.4761, postalCode: "151" },
    { lng: 41.8668, lat: 12.4584, postalCode: "153" },
    { lng: 41.8694, lat: 12.4294, postalCode: "154" },
    { lng: 41.8785, lat: 12.4445, postalCode: "155" },
    { lng: 41.8872, lat: 12.4276, postalCode: "156" },
    { lng: 41.8579, lat: 12.4213, postalCode: "157" },
    { lng: 41.8795, lat: 12.4146, postalCode: "158" },
    { lng: 41.8881, lat: 12.464, postalCode: "159" },
    { lng: 41.8939, lat: 12.4137, postalCode: "161" },
    { lng: 41.8743, lat: 12.4037, postalCode: "162" },
    { lng: 41.8466, lat: 12.4018, postalCode: "163" },
    { lng: 49.6116, lat: 6.1319, postalCode: "L-2920" },
    { lng: 49.6112, lat: 6.1303, postalCode: "L-2610" },
    { lng: 49.6101, lat: 6.1292, postalCode: "L-2540" },
    { lng: 49.6104, lat: 6.1305, postalCode: "L-2750" },
    { lng: 49.6101, lat: 6.1292, postalCode: "L-2430" },
    { lng: 49.6114, lat: 6.1337, postalCode: "L-2557" },
    { lng: 49.6102, lat: 6.1284, postalCode: "L-2611" },
    { lng: 49.6117, lat: 6.1303, postalCode: "L-2616" },
    { lng: 49.6116, lat: 6.1303, postalCode: "L-2617" },
    { lng: 49.6109, lat: 6.132, postalCode: "L-2632" },
    { lng: 49.6106, lat: 6.1322, postalCode: "L-2634" },
    { lng: 49.6096, lat: 6.1282, postalCode: "L-2665" },
    { lng: 49.6113, lat: 6.1297, postalCode: "L-2680" },
    { lng: 49.6095, lat: 6.1286, postalCode: "L-2716" },
    { lng: 49.6123, lat: 6.1308, postalCode: "L-2727" },
    { lng: 49.6115, lat: 6.1296, postalCode: "L-2753" },
    { lng: 49.6109, lat: 6.1302, postalCode: "L-2754" },
    { lng: 49.6113, lat: 6.1303, postalCode: "L-2757" },
    { lng: 49.6099, lat: 6.1295, postalCode: "L-2814" },
    { lng: 49.6102, lat: 6.1291, postalCode: "L-2832" },
    { lng: 49.6114, lat: 6.1292, postalCode: "L-2862" },
    { lng: 49.6109, lat: 6.1295, postalCode: "L-2923" },
    { lng: 49.6115, lat: 6.1323, postalCode: "L-2930" },
    { lng: 49.6106, lat: 6.1283, postalCode: "L-2957" },
    { lng: 52.3702, lat: 4.8952, postalCode: "1012" },
    { lng: 52.3567, lat: 4.9518, postalCode: "1054" },
    { lng: 52.3731, lat: 4.8925, postalCode: "1017" },
    { lng: 52.3738, lat: 4.8905, postalCode: "1018" },
    { lng: 52.3731, lat: 4.8925, postalCode: "1019" },
    { lng: 52.3755, lat: 4.8966, postalCode: "1021" },
    { lng: 52.3567, lat: 4.9518, postalCode: "1053" },
    { lng: 52.374, lat: 4.8961, postalCode: "1022" },
    { lng: 52.3744, lat: 4.9003, postalCode: "1023" },
    { lng: 52.3743, lat: 4.8972, postalCode: "1024" },
    { lng: 52.3744, lat: 4.9003, postalCode: "1025" },
    { lng: 52.3743, lat: 4.8972, postalCode: "1026" },
    { lng: 52.3744, lat: 4.9003, postalCode: "1027" },
    { lng: 52.3743, lat: 4.8972, postalCode: "1028" },
    { lng: 52.3744, lat: 4.9003, postalCode: "1029" },
    { lng: 52.3738, lat: 4.8905, postalCode: "1031" },
    { lng: 52.3567, lat: 4.9518, postalCode: "1052" },
    { lng: 52.3731, lat: 4.8925, postalCode: "1032" },
    { lng: 52.3567, lat: 4.9518, postalCode: "1051" },
    { lng: 52.374, lat: 4.8961, postalCode: "1033" },
    { lng: 52.3567, lat: 4.9518, postalCode: "1056" },
    { lng: 52.3744, lat: 4.9003, postalCode: "1034" },
    { lng: 52.3743, lat: 4.8972, postalCode: "1035" },
    { lng: 52.3744, lat: 4.9003, postalCode: "1036" },
    { lng: 38.7257, lat: -9.1503, postalCode: "1000-001" },
    { lng: 38.7281, lat: -9.1461, postalCode: "1000-002" },
    { lng: 38.7222, lat: -9.1393, postalCode: "1000-003" },
    { lng: 38.7203, lat: -9.1458, postalCode: "1000-004" },
    { lng: 38.7212, lat: -9.1473, postalCode: "1000-005" },
    { lng: 38.7259, lat: -9.1417, postalCode: "1000-006" },
    { lng: 38.7179, lat: -9.1466, postalCode: "1000-007" },
    { lng: 38.7162, lat: -9.1355, postalCode: "1000-008" },
    { lng: 38.7179, lat: -9.1466, postalCode: "1000-009" },
    { lng: 38.7213, lat: -9.1378, postalCode: "1000-010" },
    { lng: 38.7146, lat: -9.1362, postalCode: "1000-011" },
    { lng: 38.7114, lat: -9.137, postalCode: "1000-012" },
    { lng: 38.7224, lat: -9.1456, postalCode: "1000-013" },
    { lng: 38.7197, lat: -9.1465, postalCode: "1000-014" },
    { lng: 38.7247, lat: -9.1393, postalCode: "1000-015" },
    { lng: 38.7295, lat: -9.1452, postalCode: "1000-016" },
    { lng: 38.7244, lat: -9.1386, postalCode: "1000-017" },
    { lng: 38.7223, lat: -9.139, postalCode: "1000-018" },
    { lng: 38.7192, lat: -9.1393, postalCode: "1000-019" },
    { lng: 38.729, lat: -9.146, postalCode: "1000-020" },
    { lng: 38.7274, lat: -9.1506, postalCode: "1000-021" },
    { lng: 38.7212, lat: -9.1391, postalCode: "1000-022" },
    { lng: 38.722, lat: -9.142, postalCode: "1000-023" },
    { lng: 38.7294, lat: -9.1459, postalCode: "1000-024" },
    { lng: 38.7287, lat: -9.1405, postalCode: "1000-025" },
    { lng: 40.4381, lat: -3.676, postalCode: "28001" },
    { lng: 40.4172, lat: -3.7006, postalCode: "28002" },
    { lng: 40.4247, lat: -3.7008, postalCode: "28003" },
    { lng: 40.4242, lat: -3.6783, postalCode: "28004" },
    { lng: 40.4265, lat: -3.6738, postalCode: "28005" },
    { lng: 40.4254, lat: -3.6918, postalCode: "28006" },
    { lng: 40.4308, lat: -3.6796, postalCode: "28007" },
    { lng: 40.4351, lat: -3.6901, postalCode: "28008" },
    { lng: 40.4323, lat: -3.6942, postalCode: "28009" },
    { lng: 40.4428, lat: -3.6705, postalCode: "28010" },
    { lng: 40.4311, lat: -3.7128, postalCode: "28011" },
    { lng: 40.4327, lat: -3.7175, postalCode: "28012" },
    { lng: 40.4299, lat: -3.7165, postalCode: "28013" },
    { lng: 40.4241, lat: -3.715, postalCode: "28014" },
    { lng: 40.4251, lat: -3.7162, postalCode: "28015" },
    { lng: 40.4165, lat: -3.7053, postalCode: "28016" },
    { lng: 40.4354, lat: -3.6968, postalCode: "28017" },
    { lng: 40.4505, lat: -3.6747, postalCode: "28018" },
    { lng: 40.4544, lat: -3.6799, postalCode: "28019" },
    { lng: 40.4081, lat: -3.6792, postalCode: "28020" },
    { lng: 40.4345, lat: -3.7093, postalCode: "28021" },
    { lng: 40.4316, lat: -3.7101, postalCode: "28022" },
    { lng: 40.4112, lat: -3.7011, postalCode: "28023" },
    { lng: 40.4074, lat: -3.7068, postalCode: "28024" },
    { lng: 47.3775, lat: 8.539, postalCode: "8001" },
    { lng: 47.3731, lat: 8.5417, postalCode: "8002" },
    { lng: 47.3765, lat: 8.5371, postalCode: "8004" },
    { lng: 47.3742, lat: 8.5347, postalCode: "8005" },
    { lng: 47.3735, lat: 8.5152, postalCode: "8006" },
    { lng: 47.382, lat: 8.5409, postalCode: "8008" },
    { lng: 47.3828, lat: 8.5358, postalCode: "8037" },
    { lng: 47.3666, lat: 8.5552, postalCode: "8038" },
    { lng: 47.3797, lat: 8.5385, postalCode: "8039" },
    { lng: 47.3844, lat: 8.5175, postalCode: "8044" },
    { lng: 47.3743, lat: 8.4997, postalCode: "8045" },
    { lng: 47.3664, lat: 8.4957, postalCode: "8046" },
    { lng: 47.3535, lat: 8.4997, postalCode: "8047" },
    { lng: 47.3588, lat: 8.5097, postalCode: "8048" },
    { lng: 47.3482, lat: 8.4989, postalCode: "8049" },
    { lng: 47.3471, lat: 8.5236, postalCode: "8050" },
    { lng: 47.3423, lat: 8.5283, postalCode: "8051" },
    { lng: 47.3412, lat: 8.5134, postalCode: "8052" },
    { lng: 47.3834, lat: 8.4558, postalCode: "8053" },
    { lng: 47.3333, lat: 8.5503, postalCode: "8055" },
    { lng: 47.3674, lat: 8.5232, postalCode: "8057" },
    { lng: 47.3401, lat: 8.5805, postalCode: "8064" },
    { lng: 47.3494, lat: 8.5813, postalCode: "8065" },
    { lng: 47.3547, lat: 8.5556, postalCode: "8070" },
  ];
  export default zipCodeData;
  