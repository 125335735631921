let API_BASE_URL: string | undefined;

const hostname = window.location.hostname;

if (hostname === "cloud.streetteam.me") {
  API_BASE_URL = process.env.REACT_APP_CLOUD_URL;
} else if (hostname === "dev-cloud.streetteam.me") {
  API_BASE_URL = process.env.REACT_APP_DEV_URL;
} else if (hostname.includes("localhost")) {
  API_BASE_URL = process.env.REACT_APP_SANDBOX_URL;
} else if (hostname.includes("ampt.app")) {
  API_BASE_URL = window.location.origin;
} else {
  // Default case, or you can throw an error
  API_BASE_URL = process.env.REACT_APP_SANDBOX_URL;
}

if (!API_BASE_URL) {
  throw new Error("API_BASE_URL is not defined");
}

export { API_BASE_URL };
