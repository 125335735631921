export const FunnelCell = ({
  flex,
  data,
  bg,
  cellClasses,
  cellStyles,
  onClick,
}) => {
  const classes = `${cellClasses} d-flex p-2` || "d-flex p-2";

  let borderBottomColor;
  switch (bg) {
    default:
      borderBottomColor = "rgba(0, 0, 0, 0.12)"; // Default to semi-transparent black
  }

  return (
    <div
      className={classes}
      style={{
        flex: flex || 1,
        backgroundColor:
          bg === "green" ? "#46C983" : bg === "purple" ? "#3A2072" : "white",
        color: bg === "white" ? "black" : "white",
        borderBottom: `1px solid ${borderBottomColor}`, // Dynamic borderBottom color
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        cursor: onClick ? "pointer" : "default", // Set cursor to pointer if onClick is defined

        // fontSize: cellStyles?.fontSize || "18px",
        ...cellStyles,
      }}
      onClick={onClick}
    >
      {data ? data : "-"}
    </div>
  );
};
