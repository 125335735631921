import axios from "axios";
import { AxiosResponse } from "axios";
import { Connection, MetricsData, GoalData } from "./types";
import { API_BASE_URL } from "../../utils/constants";

export const api = axios.create({
  baseURL: API_BASE_URL,
});

export const fetchMetricsData = async (
  locationId: string,
  startDate: string,
  endDate: string
): Promise<MetricsData> => {
  try {
    // console.log("API_BASE_URL before request: ", API_BASE_URL); // Debugging line
    // console.log('api', api);

    // console.log("Axios baseURL:", api.defaults.baseURL);

    const response: AxiosResponse<MetricsData> = await api.get(
      `/metrics/${locationId}`,
      {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching metrics data:", error);
    throw error;
  }
};

export const saveGoalsData = async (
  locationId: string,
  goalData: GoalData
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await api.post(
      `/metrics/goals/${locationId}`,
      goalData
    );
    return response.data;
  } catch (error) {
    console.error("Error saving goal data:", error);
    throw error;
  }
};

export const fetchGoalsData = async (locationId: string): Promise<GoalData> => {
  try {
    const response: AxiosResponse<GoalData> = await api.get(
      `/metrics/goals/${locationId}`
    );
    // console.log("fetchGoalsData response", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching goals data:", error);
    throw error; // You might want to handle this error in your component
  }
};

export const fetchConnectionData = async (
  locationId: string
): Promise<Connection> => {
  try {
    const response = await api.get(`/connections/${locationId}/meta`);
    // console.log("fetchConnectionData response", response.data);
    return response.data;
    // example response:
  } catch (error) {
    console.error("Error fetching connection data:", error);
    throw error;
  }
};

export const saveConnectionData = async (
  locationId: string,
  connectionData: Connection
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await api.post(
      `/connections/${locationId}/meta`,
      connectionData
    );
    return response.data;
  } catch (error) {
    console.error("Error saving connection data:", error);
    throw error;
  }
};

export const syncMetaAds = async (locationId: string) => {
  try {
    const response = await api.post(`/connections/${locationId}/meta/sync`);
    if (response.status === 200) {
      // You can return some data or message if needed
      return response.data;
    }
  } catch (error) {
    console.error("Error while syncing meta ads:", error);
    throw error;
  }
};

export const fetchSpendData = async (
  metaAdAccountId: string,
  metaAccessToken: string,
  since: string, // "YYYY-MM-DD" format
  until: string // "YYYY-MM-DD" format
) => {
  try {
    const time_range = { since, until };
    const queryParams = {
      access_token: metaAccessToken,
      fields: "spend",
      time_range: JSON.stringify(time_range),
    };

    const response = await axios.get(
      `https://graph.facebook.com/v18.0/${metaAdAccountId}/insights`,
      {
        params: queryParams,
      }
    );

    const data = response.data; // assuming spend data is available in the data object
    const totalSpend = data.data[0]?.spend || 0; // extract the total spend value

    // console.log(`Total spend from ${since} to ${until}: ${totalSpend}`);

    return totalSpend;
  } catch (error) {
    console.error("Error fetching Meta ad spend:", error);
    return null;
  }
};
