import { useContext, useEffect, useState } from "react";
import { Accordion, Button, Container, Spinner } from "react-bootstrap";
import Milestones from "../../Milestones/Milestones";
import LocationContext from "../../../../../context/Locations/LocationContext";
import {
  MM_CRM_LOCATION_ID,
  ST_CRM_LOCATION_ID,
} from "../../../../../integrations/Highlevel/constants";
import { toast } from "react-toastify";
import axios from "axios";
import { API_BASE_URL } from "../../../../../utils/constants";
import { fetchMilestones, trackCustomAction } from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

const Launch = ({
  setCompleted,
  milestoneCards,
  milestoneData,
  // stepStatus,
  launchFields,
  setMilestoneData,
  setActive,
}) => {
  const {locationData} = useContext(LocationContext);
  const [refreshCount, setRefreshCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("optionA");
  const [completedLaunch, setCompletedLaunch] = useState(false);

  useEffect(() => {
    if (!locationData?.id || refreshCount < 1) return;

    setIsLoading(true); // Start loading

    async function getMilestones(locationId) {
      try {
        const data = await fetchMilestones(locationId);
        setMilestoneData(data);
      } catch (error) {
        console.error("Error fetching milestones:", error);
        toast.error("Error fetching milestones");
      } finally {
        setIsLoading(false); // End loading
      }
    }

    getMilestones(locationData?.id);
  }, [refreshCount, locationData?.id, setMilestoneData]);

  useEffect(() => {
    // console.log("milestoneData: ", milestoneData);
    // console.log("locationData?.owner?.email: ", locationData?.owner?.email);
    // console.log("stepStatus.launch: ", stepStatus.launch);
    const completeLaunchOnboarding = async () => {
      let email = locationData?.owner?.email;
      if (!email || !ST_CRM_LOCATION_ID) {
        toast.error(
          "Missing email or location ID (reach out to support@modernmusician.me)"
        );
        return;
      }

      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${String(
        today.getMonth() + 1
      ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

      const fields = {
        onboardingStep5: formattedDate,
        onboardingEndDate: formattedDate,
      };

      let formData = {
        location: { id: ST_CRM_LOCATION_ID },
        contact: { email },
        fields,
      };

      try {
        console.log("Submitting sales form with fields", fields);

        const response = await axios.post(
          `${API_BASE_URL}/form/submit`,
          formData
        );
        console.log(response.data);

        if (response.data.response === "success") {
          await trackCustomAction({
            location: { id: MM_CRM_LOCATION_ID },
            contact: { email: email },
            action_name: "ALC Step 5 Completed",
            properties: {}
          });
          await trackCustomAction({
            location: { id: MM_CRM_LOCATION_ID },
            contact: { email: email },
            action_name: "ALC Onboarding Completed",
            properties: {}
          });
          setCompleted("launch");
        } else {
          toast.error(
            "Something went wrong. Please try again or reach out to support@modernmusician.me."
          );
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Something went wrong. Please try again or reach out to support@modernmusician.me"
        );
      }
    };
    if (
      milestoneData?.metrics_count?.unique?.content > 0 &&
      milestoneData?.metrics_count?.unique?.registration > 0 &&
      milestoneData?.metrics_count?.value?.total > 0
    ) {
      if (!completedLaunch) {
        setCompletedLaunch(true);
        completeLaunchOnboarding();
      } else {
        setActive("");
      }
      return;
    }
  }, [
    milestoneData?.metrics_count?.unique?.content,
    milestoneData?.metrics_count?.unique?.registration,
    milestoneData?.metrics_count?.value?.total,
    locationData?.owner?.email,
    setCompleted,
    completedLaunch,
    setActive,
  ]);

  // update milestoneCards to link to the correct funnelLink to allow them to complete step

  milestoneCards = milestoneCards.map((card) => {
    // const currentCount = getMetricCurrentCount(
    //   milestoneData.metrics_count,
    //   card.category,
    //   card.formatType
    // );
    let funnelLinks = {
      streams:
        launchFields?.fans?.streamingLink ??
        locationData?.funnel_links?.streaming,
      fans:
        launchFields?.fans?.communityLink ??
        locationData?.funnel_links?.community,
      sales:
        launchFields?.fans?.starterPackLink ??
        locationData?.funnel_links?.starter_pack,
    };

    return {
      ...card,
      // currentCount,
      // title: `${currentCount}`,
      // description: `${card.title}`,
      // actionValue: card.actionValue,
      actionText: "Completed",
      funnelLink: funnelLinks[card.category],
      variant: "launch",
    };
  });

  return (
    <div>
      <Container
        className="my-4 d-flex justify-content-center flex-column"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          padding: "30px",
          maxWidth: "100vw",
        }}
      >
        <Milestones
          milestoneCards={milestoneCards}
          milestoneData={milestoneData}
        />
      </Container>
      <Container
        className="my-4 d-flex justify-content-center flex-column"
        style={{
          backgroundColor: "#fff",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          padding: "30px",
          maxWidth: "100vw",
        }}
      >
        <h2 className="text-center mt-3">How to Launch Your StreetTeam™</h2>

        <Container fluid className="d-flex justify-content-center">
          {/* <Row className="w-50 mt-3">Render spinner here</Row> */}
          {/* <Container
            className="my-4 d-flex justify-content-center flex-column"
            style={{
              backgroundColor: "#fff",
              border: "1px solid #e0e0e0",
              borderRadius: "10px",
              padding: "30px",
              maxWidth: "100vw",
            }}
          > */}
          {/* <h2 className="text-center mt-3">Create Your Music Relics™</h2> */}
          <div
            className="container d-flex flex-column mw-100"
            style={{ width: "500px" }}
          >
            <Accordion
              onSelect={(key) => setActiveKey(key)}
              className="mt-2 d-flex flex-column "
              activeKey={activeKey}
              flush
            >
              <Accordion.Item eventKey="optionA">
                <Accordion.Header>
                  <strong>Option A: Organic Launch</strong>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="video-container rounded">
                    <iframe
                      className="video-iframe rounded"
                      title="domainStep1Gif"
                      src="https://share.descript.com/embed/boet2XpE66v"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>{" "}
                  </div>
                  {/* Nested accordion for Option A steps goes here */}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="optionB">
                <Accordion.Header>
                  <strong>Option B: Boosted Launch</strong>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="video-container rounded">
                    <iframe
                      className="video-iframe rounded"
                      title="domainStep1Gif"
                      src="https://share.descript.com/embed/Rk9DvnMnGvz"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>{" "}
                  </div>{" "}
                </Accordion.Body>
              </Accordion.Item>
              <Button
                variant="secondary"
                className="mt-3 bg-gradient"
                onClick={() => setRefreshCount((prevCount) => prevCount + 1)}
              >
                {isLoading ? (
                  <Spinner className="me-2" size="sm" animation="border" />
                ) : (
                  <FontAwesomeIcon icon={faSync} size="sm" className="me-2" />
                )}
                Refresh Stats
              </Button>

              {/* Existing steps here as nested accordions under Option A or B */}
            </Accordion>
          </div>
        </Container>

        {/* <UploadRelic
          setCompleted={setCompleted}
          relicSubmissionType={"bronze"}
        /> */}
        {/* </Container> */}
      </Container>
    </div>
  );
};

export default Launch;
