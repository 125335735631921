const getValue = (data, path) => {
  return path.split(".").reduce((acc, segment) => acc?.[segment], data);
};

const formatPercentage = (value) => {
  const formattedValue = value.toFixed(2);
  return value === 0
    ? "-"
    : `${
        formattedValue.endsWith(".00")
          ? parseFloat(value).toFixed(0)
          : formattedValue
      }%`;
};

const formatCurrency = (value, decimals = 2, log) => {
  if (log) console.log("formatCurrency", value, decimals);

  let formattedValue = `$${parseFloat(value).toFixed(decimals)}`;
  if (formattedValue.endsWith(".00")) {
    formattedValue = `$${parseInt(value, 10).toLocaleString()}`;
  } else {
    formattedValue = `$${parseFloat(value).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })}`;
  }

  if (log) console.log("formattedValue", formattedValue);
  return formattedValue;
};

function getFormattedValue(value, type, log, decimals = 2) {
  let formattedValue;

  if (log) {
    console.log(value, type);
  }

  if (!value) {
    if (type === "currency") {
      return "$0";
    } else if (type === "percentage") {
      return "0%";
    }
  }
  if (type === "currency") {
    formattedValue = `$${parseFloat(value).toFixed(decimals)}`;
    if (formattedValue.endsWith(".00")) {
      formattedValue = `$${parseFloat(value).toFixed(0)}`;
    }
  } else if (type === "percentage") {
    formattedValue = `${parseFloat(value).toFixed(decimals)}%`;
    if (formattedValue.endsWith(".00%")) {
      formattedValue = `${parseFloat(value).toFixed(0)}%`;
    }
  } else if (type === "string") {
    let parsedValue = parseFloat(value);

    if (isNaN(parsedValue) || !isFinite(parsedValue)) {
      return "0";
    }
    formattedValue = value;
  } else if (type === "number") {
    formattedValue = parseFloat(value).toFixed(decimals);
    if (formattedValue.endsWith(".00")) {
      formattedValue = parseFloat(value).toFixed(0);
    }
  } else {
    formattedValue = value;
  }

  return formattedValue;
}

const calculateRate = (numerator, denominator) => {
  if (
    numerator !== undefined &&
    denominator !== undefined &&
    denominator !== 0
  ) {
    return parseFloat(((numerator / denominator) * 100).toFixed(2));
  }
  return 0;
};

const calculateValuePerMetric = (numerator, denominator) => {
  if (
    numerator !== undefined &&
    denominator !== undefined &&
    denominator !== 0
  ) {
    return parseFloat((numerator / denominator).toFixed(2));
  }
  return 0;
};

const formatMultiplier = (value, decimals = 2) => {
  return value === 0 ? "-" : `${parseFloat(value).toFixed(decimals)}x`;
};

export {
  getValue,
  formatPercentage,
  formatCurrency,
  formatMultiplier,
  calculateRate,
  calculateValuePerMetric,
  getFormattedValue,
};
