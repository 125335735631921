import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { Card, Form, Container, Row } from "react-bootstrap";
import { META_CLIENT_ID, META_REDIRECT_URI } from "./config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeta } from "@fortawesome/free-brands-svg-icons";
import { API_BASE_URL } from "../../utils/constants";

const MetaConnection = ({ authData, updateAdCampaignData }) => {
  const [pageOptions, setPageOptions] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [hasCalledCallback, setHasCalledCallback] = useState(false);
  // const [loading, setLoading] = useState(false);

  const handleAuthClick = () => {
    let scopes = [
      "ads_management",
      "ads_read",
      "business_management",
      "pages_manage_ads",
      "pages_manage_metadata",
      "pages_show_list",
      "pages_messaging",
    ];
    const META_AUTH_URL = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${META_CLIENT_ID}&redirect_uri=${META_REDIRECT_URI}&scope=${scopes.join(
      ","
    )}`;
    window.open(META_AUTH_URL, "_blank");
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const error = urlParams.get("error");
    let storedAdCampaign = JSON.parse(localStorage.getItem("adCampaignData"));

    if (code && !authData?.accessToken && !hasCalledCallback) {
      console.log("Authentication code:", code);
      setHasCalledCallback(true);

      axios
        .post(`${API_BASE_URL}/meta/callback`, {
          code,
          location_id: storedAdCampaign?.locationId,
        })
        .then((response) => {
          console.log("Authentication successful:", response.data);
          updateAdCampaignData("metaConnection", {
            accessToken: response.data.access_token, // Assuming response.data contains the access token
          });
          urlParams.delete("code"); // Remove the code parameter
          window.history.replaceState(null, null, "?" + urlParams.toString()); // Update the URL without reloading
        })
        .catch((error) => {
          console.error("Error during authentication:", error);
        });
    }

    if (error) {
      console.error("Error during Meta authentication:", error);
    }

    if (authData?.accessToken) {
      axios
        .get(`https://graph.facebook.com/v18.0/me/accounts`, {
          params: { access_token: authData.accessToken },
        })
        .then((response) => {
          setPageOptions(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching pages:", error);
        });
    }
  }, [updateAdCampaignData, authData, hasCalledCallback]);

  useEffect(() => {
    if (authData?.accessToken) {
      axios
        .get(`https://graph.facebook.com/v18.0/me/accounts`, {
          params: { access_token: authData.accessToken },
        })
        .then((response) => {
          setPageOptions(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching pages:", error);
        });
    }
  }, [authData?.accessToken]);

  useEffect(() => {
    console.log("authData?.pageId", authData?.pageId);
    if (authData?.pageId) {
      console.log("setting selected page");
      setSelectedPage(authData.pageId);
    }
  }, [authData?.pageId]);

  const handlePageSubmit = (selectedPageId) => {
    axios
      .post(`${API_BASE_URL}/meta/business`, {
        page_id: selectedPageId,
        access_token: authData?.accessToken,
      })
      .then((response) => {
        console.log("Page selection successful:", response.data);
        updateAdCampaignData("metaConnection", {
          ...authData,
          pageId: selectedPageId, // updating authData with the selected pageId
          isComplete: true,
        });
        setSelectedPage(selectedPageId);
      })
      .catch((error) => {
        console.error("Error submitting page selection:", error);
      });
  };

  return (
    <Card className="px-5 py-5">
      <FontAwesomeIcon icon={faMeta} size="3x" />
      <div className="m-2 d-flex flex-column align-items-center justify-content-center">
        <h2 className="my-1">Meta Authentication</h2>
        {authData?.accessToken ? (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <Button
              className="d-flex justify-content-center my-3"
              variant="success"
              disabled
            >
              Connected ✓
            </Button>
            <div>
              <span className="text-black" onClick={handleAuthClick}>
                {" "}
                Click here to reconnect
              </span>
            </div>
          </div>
        ) : (
          <Button className="mt-3" variant="primary" onClick={handleAuthClick}>
            Connect to Meta
          </Button>
        )}

        {authData?.accessToken && pageOptions.length > 0 && (
          <Container>
            <Form>
              <Row className="mt-3">
                <Form.Select
                  value={selectedPage || ""}
                  onChange={(e) => handlePageSubmit(e.target.value)}
                >
                  <option value="" disabled>
                    Select a page
                  </option>
                  {pageOptions.map((page) => (
                    <option key={page.id} value={page.id}>
                      {page.name}
                    </option>
                  ))}
                </Form.Select>
              </Row>
            </Form>
          </Container>
        )}
      </div>
    </Card>
  );
};

export default MetaConnection;
