import { useEffect } from "react";

const Page = (props) => {
  useEffect(() => {
    document.title = `${props.title} | StreetTeam` || "StreetTeam";
  }, [props.title]);
  return props.children;
};

export default Page;
