import { faShareFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { asyncForEach } from "../../utils";
import { getCustomValueId } from "../../api/highlevel/customValues";
import axios from "axios";
import { updateLocationData } from "../../api/streetTeam/locations";
import { getDomain } from "../../hooks/apiHelper";
import { toast } from "react-toastify";

const PublishModal = ({
  show,
  onHide,
  campaigns,
  isLoading,
  locationData,
  onUpdateLocationData,
  api_key,
}) => {
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [selectedCampaignsToPublish, setSelectedCampaignsToPublish] = useState(
    []
  );

  useEffect(() => {
    const campaignA = locationData?.campaigns?.find(
      (campaign) => campaign.cv_name === "ArtistAI Campaign A"
    );
    const campaignB = locationData?.campaigns?.find(
      (campaign) => campaign.cv_name === "ArtistAI Campaign B"
    );

    if (campaignA) {
      setSelectedCampaignsToPublish([campaignA.cv_value]);
      if (campaignB)
        setSelectedCampaignsToPublish([
          (campaignA.cv_value, campaignB.cv_value),
        ]);
    }
  }, [locationData]);

  function handleChangeCampaign(index, newValue) {
    console.log(newValue);
    if (!newValue?.target?.value) {
      return;
    }
    const newSelectedCampaignArr = selectedCampaignsToPublish;
    newSelectedCampaignArr[index] = newValue?.target?.value;
    setSelectedCampaignsToPublish(newSelectedCampaignArr);
  }

  const handlePublish = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${api_key}`,
    };

    try {
      setIsPublishLoading(true);
      let campaignsData = [];
      await asyncForEach(selectedCampaignsToPublish, async (c, index) => {
        const campaignName = `ArtistAI Campaign ${
          index === 0 ? "A" : index === 1 ? "B" : "C"
        }`;
        if (!locationData?.id) {
          toast.error(
            "Something went wrong publishing your campaign. Please reload this page and try again."
          );
          return;
        }
        const campaignFieldId = await getCustomValueId(
          api_key,
          locationData?.id,
          campaignName
        );

        const campaignResponse = await axios.put(
          `${getDomain()}/custom-values`,
          {
            location_id: locationData?.id,
            customValueId: campaignFieldId,
            customValueName: campaignName,
            value: c,
          },
          { headers }
        );

        campaignsData.push({
          cv_id: campaignResponse.data.id,
          cv_name: campaignName,
          cv_field_key: campaignResponse.data.fieldKey,
          cv_value: c,
        });
      });

      // After successful campaign publication, update location data
      const updatedLocationData = {
        ...locationData,
        campaigns: campaignsData,
      };
      await updateLocationData(locationData.id, updatedLocationData);
      onUpdateLocationData(updatedLocationData);
    } catch (error) {
      console.error("Error occurred during request: ", error);
      setIsPublishLoading(false);
    }
    setIsPublishLoading(false);
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Publish Campaign</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Campaign A</Form.Label>
          <Form.Control
            as="select"
            value={selectedCampaignsToPublish[0]}
            onChange={(e) => handleChangeCampaign(0, e)}
          >
            <option value="">Select a campaign</option>
            {campaigns.map((campaign) => (
              <option key={campaign.value.id} value={campaign.value.id}>
                {campaign.value.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="pt-2">
          <Form.Label>Campaign B (optional)</Form.Label>
          <Form.Control
            as="select"
            value={selectedCampaignsToPublish[1]}
            onChange={(e) => handleChangeCampaign(1, e)}
          >
            <option value="">Select a campaign</option>
            {campaigns.map((campaign) => (
              <option key={campaign.value.id} value={campaign.value.id}>
                {campaign.value.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-info" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="secondary"
          onClick={handlePublish}
          disabled={isLoading}
        >
          {isPublishLoading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              <FontAwesomeIcon className="me-2" icon={faShareFromSquare} />
              Publish
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PublishModal;
