const AvatarImage = ({ src, alt, ...props }) => {
  const fallbackAvatar =
    "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-512x488-rddkk3u9.png";

  return (
    <img
      src={src || fallbackAvatar}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = fallbackAvatar;
      }}
      alt={alt}
      {...props}
    />
  );
};

export default AvatarImage;
