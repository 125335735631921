import {
  Accordion,
  Button,
  Row,
  Col,
  Spinner,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLink,
  faClone,
  faRefresh,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

const DomainSetupStep2 = ({
  handleConnectDomainClick,
  domain,
  copied,
  ADD_DOMAIN_LINK,
  handleDomainValidation,
  domainValidationState,
  funnelLinks,
  handleLinkChange,
  handleLinkValidation,
  activeKey,
  setActiveKey,
  domainOption,
}) => {
  return (
    <div
      className="container d-flex flex-column mw-100"
      style={{ width: "500px" }}
    >
      <Accordion defaultActiveKey={activeKey} flush>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <strong>1. Clone the Fan Journey template.</strong>
          </Accordion.Header>
          <Accordion.Body className="d-flex justify-content-center flex-column">
            <div className="video-container">
              <iframe
                className="video-iframe"
                title="domainStep1Gif"
                src="https://share.descript.com/embed/nrYUPeKYMUl"
                frameBorder="0"
                allowFullScreen
              ></iframe>{" "}
            </div>
            <Button
              variant="secondary bg-gradient border-0"
              className="mt-3"
              onClick={() => {
                window.open(ADD_DOMAIN_LINK, "_blank", "width=1280,height=720");
                setActiveKey("2");
              }}
            >
              <FontAwesomeIcon className="me-2" icon={faClone} />
              Clone Template
            </Button>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <strong>2. Connect your domain.</strong>
          </Accordion.Header>
          <Accordion.Body>
            <div className="video-container mt-1">
              <iframe
                className="video-iframe"
                title="domainStep1Gif"
                src={
                  domainOption === "subdomain"
                    ? "https://share.descript.com/embed/ny7ZxJY89Lu"
                    : "https://share.descript.com/embed/6VH71B3DhYD"
                }
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <div className="d-flex justify-content-center flex-column">
              <Button
                variant="secondary"
                className="mt-3  bg-gradient border-0"
                onClick={() => handleConnectDomainClick(domain)}
              >
                <FontAwesomeIcon className="me-2" icon={faExternalLink} />
                {copied ? "Copied!" : "Connect Your Domain"}
              </Button>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <strong>3. Verify your website.</strong>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="mt-2 align-items-center">
              <Col className="d-flex align-items-center justify-content-center w-100">
                <Button
                  variant="secondary"
                  className="mt-0 me-2 w-100  bg-gradient border-0"
                  onClick={() => handleDomainValidation()} // Replace 'yourLinkKey' with the appropriate key if necessary
                >
                  <FontAwesomeIcon className="me-2" icon={faRefresh} />
                  Verify Now
                </Button>
                <div
                  // className="ms-2"
                  style={{ flexShrink: 0 }}
                  className=""
                >
                  {domainValidationState === "in_progress" ? (
                    <Spinner
                      animation="border"
                      size="sm"
                      className="ms-1"
                      style={{
                        // cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ) : domainValidationState === "validated" ? (
                    <FontAwesomeIcon
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                      className="text-success ms-1"
                      icon={faCheckCircle}
                      onClick={() => handleDomainValidation()}
                    />
                  ) : domainValidationState !== "idle" ? (
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip
                          id="tooltip-right"
                          className="ms-2 w-100"
                          // style={{ textAlign: "left" }}
                        >
                          We were unable to verify the domain: {domain}.{" "}
                          {<br></br>}Make sure to you've connected your domain
                          properly.
                        </Tooltip>
                      }
                    >
                      <FontAwesomeIcon
                        style={{
                          cursor: "pointer",
                          width: "20px",
                          height: "20px",
                        }}
                        className="text-danger ms-1"
                        icon={faTimesCircle}
                        onClick={() => handleDomainValidation()}
                      />
                    </OverlayTrigger>
                  ) : (
                    <></>
                  )}
                </div>

                {/* Insert verification logic and GIF overview */}
              </Col>
              <a
                href={domain}
                target="_blank"
                rel="noreferrer"
                className="mt-1 text-secondary text-center"
              >
                {domain}
              </a>
            </Row>
          </Accordion.Body>
        </Accordion.Item>{" "}
        {domainValidationState === "validated" && (
          <>
            {/* Step 2: Customize Your Links */}
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <strong>4. Verify your links.</strong>
              </Accordion.Header>
              <Accordion.Body>
                <Form.Group controlId="funnelLinks">
                  {Object.keys(funnelLinks).map((key) => (
                    <Row key={key} className="mt-2 align-items-center">
                      <Col>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="rounded-start"
                            style={{
                              backgroundColor: "#f0f0f0",
                              padding: "7px 8px",
                            }}
                          >
                            <span>{domain}</span>
                          </div>
                          <Form.Control
                            className="rounded-start-0 rounded-end"
                            type="text"
                            value={funnelLinks[key].path}
                            onChange={(e) =>
                              handleLinkChange(e.target.value, key)
                            }
                          />
                          <div
                            // className="ms-2"
                            style={{ flexShrink: 0 }}
                          >
                            {funnelLinks[key].validationState ===
                            "in_progress" ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="ms-1"
                                style={{
                                  // cursor: "pointer",
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                            ) : funnelLinks[key].validationState ===
                              "validated" ? (
                              <FontAwesomeIcon
                                style={{
                                  cursor: "pointer",
                                  width: "20px",
                                  height: "20px",
                                }}
                                className="text-success ms-1"
                                icon={faCheckCircle}
                                onClick={() => handleLinkValidation(key)}
                              />
                            ) : funnelLinks[key].validationState !== "idle" ? (
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip
                                    id="tooltip-right"
                                    className="ms-2 w-100"
                                    // style={{ textAlign: "left" }}
                                  >
                                    We were unable to verify the link. Make sure
                                    you set up your links correctly in Step 2.
                                  </Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  style={{
                                    cursor: "pointer",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  className="text-danger ms-1"
                                  icon={faTimesCircle}
                                  onClick={() => handleLinkValidation(key)}
                                />
                              </OverlayTrigger>
                            ) : (
                              <></>
                            )}
                          </div>{" "}
                        </div>
                      </Col>
                    </Row>
                  ))}

                  <div className="d-flex flex-column mt-2">
                    <Button
                      className="bg-gradient border-0"
                      variant="secondary"
                      onClick={() => {
                        Object.keys(funnelLinks).forEach(handleLinkValidation);
                      }}
                    >
                      <FontAwesomeIcon className="me-2" icon={faRefresh} />
                      Verify Links
                    </Button>
                  </div>
                </Form.Group>
              </Accordion.Body>
            </Accordion.Item>
          </>
        )}
      </Accordion>
    </div>
  );
};

export default DomainSetupStep2;
