import React, { useState, useEffect, useMemo, useCallback } from "react";
import LocationContext from "./LocationContext";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchLocationData } from "../../hooks/apiHelper";
import { verifySession } from "../../utils/cookies";

function LocationProvider({ children }) {
  const [locationData, setLocationData] = useState({});
  // const [contactData, setContactData] = useState({});
  const [error, setError] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  // Parse the URL using useMemo
  const parsedData = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      locationIdFromURL: queryParams.get("crm_location_id"),
      apiKeyFromURL: queryParams.get("api_key"),
      locationIdFromLocalStorage: localStorage.getItem("crm_location_id"),
      queryParams,
    };
  }, [location.search]);

  const fetchData = useCallback(async () => {
    const {
      locationIdFromURL,
      apiKeyFromURL,
      locationIdFromLocalStorage,
      queryParams,
    } = parsedData;

    try {
      let updatedLocationData = null;

      // Fetch location data if there's a location ID in the URL and it's different from the current locationData ID
      if (
        locationIdFromURL &&
        apiKeyFromURL &&
        locationData.id !== locationIdFromURL
      ) {
        updatedLocationData = await fetchLocationData(
          locationIdFromURL,
          apiKeyFromURL
        );

        if (updatedLocationData) {
          localStorage.setItem("crm_location_id", locationIdFromURL);

          queryParams.delete("crm_location_id");
          queryParams.delete("api_key");
          queryParams.delete("email");

          navigate(
            {
              pathname: location.pathname,
              search: queryParams.toString(),
            },
            { replace: true }
          );
        } else {
          setError("Failed to fetch location data.");
        }
      }

      // Fetch location data from local storage if there's no location ID in the URL and locationData ID is not set
      else if (
        locationIdFromLocalStorage &&
        !locationIdFromURL &&
        !locationData.id
      ) {
        const authed = await verifySession();
        if (!authed) {
          return;
        }
        // console.log("authed is", authed);
        updatedLocationData = await fetchLocationData(
          locationIdFromLocalStorage
        );
      }

      // Update state with new location data including contact data
      if (updatedLocationData) {
        setLocationData(updatedLocationData);
      }
    } catch (err) {
      setError("An error occurred while fetching data: " + err.message);
    }
  },[location.pathname, locationData.id,navigate,parsedData]);

  const refreshData = useCallback(async () => {
    const {
      locationIdFromLocalStorage,
    } = parsedData;
    const updatedLocationData = await fetchLocationData(
      locationIdFromLocalStorage
    );
    setLocationData(updatedLocationData)
  },[parsedData])

  useEffect(() => {
    fetchData();
  }, [
    fetchData
  ]);

  const contextValue = useMemo(() => {
    return { 
      locationData, 
      setLocationData, 
      error, 
      refreshData // Provide the fetchData function to refresh the data
    };
  }, [locationData, setLocationData, error, refreshData]);

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
}

export default LocationProvider;
