// Reference for using react-hook-form with react-bootstrap https://github.com/parthprajapati32/reacthookform/blob/84f1e1ce0210044313d8cd5123c7dc798724d140/src/components/BootstrapForm/index.js
// Reference for toastify progress bar https://fkhadra.github.io/react-toastify/use-a-controlled-progress-bar/
import React, { useContext, useEffect, useState } from "react";
import { Container, Form, Button, Row, InputGroup } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import LocationContext from "../../context/Locations/LocationContext";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const UploadRelicSubmission = () => {
  let queryParams = useQuery();
  const {locationData} = useContext(LocationContext);
  const crm_location_id = locationData?.id;
  const creator_name = locationData?.name;
  const email = locationData?.owner?.email;
  // let crm_location_id = queryParams.get("location_id");
  // let creator_name = queryParams.get("creator_name");

  const initState = {
    crm_location_id: crm_location_id,
    email: email,
    creator_name: creator_name,
    relic_type: queryParams.get("relic_type") || "moment",
    relic_title: queryParams.get("relic_title"),
    relic_description: "",
    initial_value: queryParams.get("initial_value") || "7",
    audio_file: "",
    artwork_file: "",
  };

  const initFormStep = {
    relic: "moment_1",
    relic_label: "Starter Moment",
  };

  const formSteps = [
    {
      relic: "moment_1",
      relic_label: "Moment",
      type: "moment",
      initial_value: "10",
    },
    {
      relic: "song_1",
      relic_label: "Song",
      type: "song",
      initial_value: "20",
    },
    {
      relic: "album_1",
      relic_label: "Album",
      type: "album",
      initial_value: "50",
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [initialValues, setInitialValues] = React.useState(initState);
  const [displayThanks, setDisplayThanks] = useState(false);
  const [finalWarning, setFinalWarning] = useState([]);
  const [formStep, setFormStep] = useState(initFormStep);
  const [form_id, setFormId] = useState();

  // UPLOAD SUBMISSION -- TODO move these functions to a utility folder
  const isProduction = window.location.host === "cloud.streetteam.me";
  const backend_url = isProduction
    ? `https://cloud.streetteam.me`
    : process.env.REACT_APP_SANDBOX_URL;
  const storage_url = isProduction
    ? `https://streetteam-storage-production.s3.amazonaws.com/new_media`
    : `https://streetteam-storage-dev.s3.amazonaws.com/new_media`;
  const apiUrl = isProduction
    ? `https://api.modern-musician.link`
    : `https://dev.modern-musician.link`; //this will get migrated to cloud.streetteam.me at some point

  const submitForm = async (formData) => {
    let result;
    const submitUrl = `${backend_url}/relics/form`;
    const audio_file = formData.audio_file[0];
    const artwork_file = formData.artwork_file[0];
    let submitData = {
      ...formData,
      form_id: form_id,
      original_audio_file_name: `${audio_file.name}`,
      original_artwork_file_name: `${artwork_file.name}`,
      audio_file: `${storage_url}/${getFileNameFromForm(
        audio_file,
        formData,
        form_id
      )}`,
      artwork_file: `${storage_url}/${getFileNameFromForm(
        artwork_file,
        formData,
        form_id
      )}`,
    };
    try {
      const response = await fetch(submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submitData),
      });
      result = await response.json();
    } catch (err) {
      console.log(`error submitting form data`, err);
      toast.warn("Something went wrong submitting your form. Please try again");
    }
    console.log(`form submit response is`, result);
    return result;
  };

  const submitCompletedRelics = async (form_id) => {
    let result;
    const submitUrl = `${backend_url}/relics/starter-relics-form`;
    let submitData = {
      form_id: form_id,
    };
    try {
      const response = await fetch(submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submitData),
      });
      result = await response.json();
    } catch (err) {
      console.log(`error submitting final form data`, err);
      toast.warn(
        "Something went wrong submitting your relics. Please contact support@modern-musician.me if problem persists"
      );
    }
    console.log(`form submit response is`, result);
    return result;
  };

  const getFileNameFromForm = (fileData, formData, form_id) => {
    console.log(`file name is`, fileData?.name);
    const name_split = fileData?.name?.split(".");
    let file_ext = name_split[name_split.length - 1];
    console.log(`file ext is`, file_ext);
    const fileName = `${formData.creator_name}_${formData.relic_title}_${formData.relic_type}_submission_${form_id}`;
    const cleanFileName = fileName
      .replace(/[^a-zA-Z0-9-_]/g, "-")
      .toLowerCase(); //clean title and creator name for URLs
    const cleanFileNameWithExtention = `${cleanFileName}.${file_ext}`;
    return cleanFileNameWithExtention;
  };

  const uploadFile = async (fileData, formData, form_id) => {
    const fileName = getFileNameFromForm(fileData, formData, form_id);
    const friendlyFileName = fileData?.name;
    const fileType = fileData?.type;

    //hit the backend API to get the signed URL
    await fetch(
      `${apiUrl}/signed-upload-url?fileName=${fileName}&fileType=${fileType}&location_id=new_media`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        console.log(`res is`, res);
        return res.json();
      })
      // then with that URL, upload the file
      .then((res) => {
        const resData = res?.data;
        console.log("res", res);
        console.log("resData", resData);
        let currentToast = null;
        axios
          .request({
            method: "PUT",
            url: resData,
            data: fileData,
            onUploadProgress: (p) => {
              console.log(`checking progress...`);
              const progress = p.loaded / p.total;
              // check if we already displayed a toast
              if (currentToast === null) {
                currentToast = toast(`⬆️ Uploading ${friendlyFileName}`, {
                  progress,
                });
                setFinalWarning((prevState) => [...prevState, currentToast]);
              } else {
                toast.update(currentToast, { progress });
                if (progress > 0.99) {
                  setFinalWarning((prevState) =>
                    prevState.filter((element) => element !== currentToast)
                  );
                }
              }
            },
          })
          .then((data) => {
            // Upload is done!
            // The remaining progress bar will be filled up
            // The toast will be closed when the transition ends
            if (currentToast !== null) {
              toast.done(currentToast);
              currentToast = null; // Reset the currentToast
            }
          });
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error(
          `Error uploading ${friendlyFileName}, please try uploading this file again`
        );
      });
  };
  console.log(`final warning array is `, finalWarning);
  const updateStep = async () => {
    const currentStepNumber = formSteps.findIndex(
      (item) => item.relic === formStep.relic
    );
    if (currentStepNumber + 1 < formSteps.length) {
      setFormStep(formSteps[currentStepNumber + 1]);
    } else {
      const result = await submitCompletedRelics(form_id);
      console.log(`submitting completed relics had result`, result);
      setDisplayThanks(true);
    }
  };

  const onSubmit = async (formData) => {
    console.log("FormData:::", formData);
    console.log("FormData:::", JSON.stringify(formData));
    const submittingToast = toast.loading(`Submitting...`);
    //submit the form data to our backend
    const submittedForm = await submitForm(formData);
    console.log(`submittedForm resulted in `, submittedForm);
    //upload the files to storage
    const file_array = [...formData.audio_file, ...formData.artwork_file];
    // const submission__id = submittedForm?.data?.createdRecords[0];
    for (let index = 0; index < file_array.length; index++) {
      let file_data = file_array[index];
      await uploadFile(file_data, formData, form_id);
    }
    //clear form
    toast.dismiss(submittingToast);
    updateStep();
  };

  const onError = (error) => {
    console.log("ERROR:::", error);
  };

  const {
    register,
    handleSubmit,
    reset,
    // watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: initialValues,
  });

  useEffect(() => {
    let defaultValues = initialValues;
    defaultValues.relic_type = formStep.type;
    defaultValues.initial_value = formStep.initial_value;
    reset(defaultValues);
  }, [formStep, reset, initialValues]);

  useEffect(() => {
    if (!form_id) {
      const new_id = crypto.randomUUID(); //form submission tracking
      setFormId(new_id);
    }
  }, [form_id, setFormId]);

  // function endSubmissions () {
  //   setDisplayThanks(true);
  //   console.log('end submissions')
  // }

  //UNCOMMENT this to watch the form change in the console in real time
  // React.useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     console.log(">>", value, name, type);
  //   });

  //   return () => subscription.unsubscribe();
  // }, [watch]);

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center gap-3" // Added flexbox centering
    >
      <ToastContainer
        position="top-center"
        // autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable
        pauseOnHover={false}
        theme="light"
      />
      {displayThanks ? (
        <Container className="text-center">
          {finalWarning.length !== 0 ? (
            <h1>
              Wait! Your files are still uploading. Wait until this message
              disapears before closing this window
            </h1>
          ) : (
            <h2>❤️ Done! You'll hear from our team soon ❤️</h2>
          )}
        </Container>
      ) : (
        <Container>
          <h5>{formStep.relic_label}</h5>
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <Form.Group className="mb-3 hidden" controlId="formLocationId">
              <Form.Label>Location ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="StreetTeam Location ID"
                {...register("crm_location_id", {
                  required: "Location ID is required",
                })}
              />
              {errors.location_id && (
                <Form.Text className="text-danger">
                  {errors.location_id.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group
              className={
                formStep.relic === "moment_1" ? "mb-3 disabled" : "hidden"
              }
              controlId="formEmail"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                disabled
                placeholder="Email Address"
                {...register("email", { required: "Email is Required" })}
              />
              {errors.email && (
                <Form.Text className="text-danger">
                  {errors.email.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="formCreatorName">
              <Form.Label>Creator Name</Form.Label>
              <Form.Control
                type="text"
                // disabled
                placeholder="Creator Name"
                {...register("creator_name", {
                  required: "Creator Name is Required",
                })}
              />
              {errors.creator_name && (
                <Form.Text className="text-danger">
                  {errors.creator_name.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group className="mb-3 hidden" controlId="form_relic_type">
              <Form.Label>Relic Type</Form.Label>
              <Form.Select
                name="relic_type"
                {...register("relic_type", {
                  required: "Please select a relic type",
                })}
              >
                <option value="">Please Select</option>
                <option value="moment">Moment</option>
                <option value="song">Song</option>
                <option value="album">Album</option>
              </Form.Select>
              {errors.relic_type && (
                <Form.Text className="text-danger">
                  {errors.relic_type.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="form_relic_title">
              <Form.Label className="mt-3">Relic Title</Form.Label>
              <Form.Control
                type="text"
                {...register("relic_title", {
                  required: "Please include a Relic title",
                })}
              />
              {errors.relic_title && (
                <Form.Text className="text-danger">
                  {errors.relic_title.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="form_relic_description">
              <Form.Label>Relic Description</Form.Label>
              <Form.Control
                as="textarea"
                {...register("relic_description", {
                  required: "Please include a Relic description",
                })}
              />
              {errors.relic_description && (
                <Form.Text className="text-danger">
                  {errors.relic_description.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="form_relic_description">
              <Form.Label>Initial Value</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  type="number"
                  {...register("initial_value", {
                    required: "Please include a Relic description",
                  })}
                />
              </InputGroup>
              {errors.initial_value && (
                <Form.Text className="text-danger">
                  {errors.initial_value.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="form_audio_file" className="mb-3">
              <Form.Label>Audio File</Form.Label>
              <Form.Control
                as="input"
                type="file"
                {...register("audio_file", {
                  required: "Please upload an audio file to continue",
                })}
              />
              {errors.audio_file && (
                <Form.Text className="text-danger">
                  {errors.audio_file.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="form_artwork_file" className="mb-3">
              <Form.Label>Artwork File</Form.Label>
              <Form.Control
                as="input"
                type="file"
                {...register("artwork_file", {
                  required: "Please upload an artwork file to continue",
                })}
              />
              {errors.artwork_file && (
                <Form.Text className="text-danger">
                  {errors.artwork_file.message}
                </Form.Text>
              )}
            </Form.Group>
            <Row>
              <div className="d-grid gap-2 mt-2">
                <Button variant="primary" type="submit">
                  Next
                </Button>
              </div>
            </Row>
            {/* <Row>
            <div className="d-grid gap-2 mt-2">
            <Button variant="secondary" onClick={endSubmissions}>
              Finished
            </Button>
            </div>
          </Row> */}
          </Form>
        </Container>
      )}
    </Container>
  );
};

export default UploadRelicSubmission;
