export const mapOptions = () => {
    return {
    scaleControl: true,
    zoomControl: true, // Add zoom control
    zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM // Set zoom control to right bottom
    },
    streetViewControl: true, // Add street view control
    streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM // Set street view control to right bottom
    },
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM // Set fullscreen control to right bottom
    },
    mapTypeControl: true,
    mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT // Set map type control to right bottom
    },
    styles: [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "hue": "#8b5cf6"
                 }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry",
            "stylers": [
                {
                    "hue": "#0077ff"
                },
                {
                    "gamma": 3.1
                }
            ]
        },
        {
            "featureType": "water",
            "stylers": [
                {
                    "hue": "#3a1f72"
                },
                {
                    "gamma": 0.44
                },
                {
                    "saturation": -33
                }
            ]
        },
        {
            "featureType": "poi.park",
            "stylers": [
                {
                    "hue": "#44ff00"
                },
                {
                    "saturation": -23
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "hue": "#007fff"
                },
                {
                    "gamma": 0.77
                },
                {
                    "saturation": 65
                },
                {
                    "lightness": 99
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "gamma": 0.11
                },
                {
                    "weight": 5.6
                },
                {
                    "saturation": 99
                },
                {
                    "hue": "#0091ff"
                },
                {
                    "lightness": -86
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "lightness": -48
                },
                {
                    "hue": "#ff5e00"
                },
                {
                    "gamma": 1.2
                },
                {
                    "saturation": -23
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "saturation": -64
                },
                {
                    "hue": "#ff9100"
                },
                {
                    "lightness": 16
                },
                {
                    "gamma": 0.47
                },
                {
                    "weight": 2.7
                }
            ]
        }
    ]
};
};


// Define your X set of map options here
export const mapOptionsX = () => {
    return {
    mapTypeId: 'hybrid', // alt 'satellite'
    scaleControl: true,
    zoomControl: true, // Add zoom control
    zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM // Set zoom control to right bottom
    },
    streetViewControl: true, // Add street view control
    streetViewControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM // Set street view control to right bottom
    },
    fullscreenControl: true,
    fullscreenControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM // Set fullscreen control to right bottom
    },
    mapTypeControl: true,
    mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.TOP_RIGHT // Set map type control to right bottom
    },
    styles: undefined,
};
};
