const DescriptVideoPlayer = ({ videoId }) => {
  const videoUrl = `https://share.descript.com/embed/${videoId}?transcript=false`;
  return (
    <div className="video-container">
      <iframe
        className="video-iframe"
        title={`descript-video-${videoId}`}
        src={videoUrl}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default DescriptVideoPlayer;
