import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Form } from "react-bootstrap";

const AdvancedSettings = ({
  steps,
  locationData,
  artistAiConfig,
  onUpdateConfig,
  onHighlightStep,
}) => {
  const [advancedSettingsOpen, setAdvancedSettingsOpen] = useState(false);
  const [previewPrompt, setPreviewPrompt] = useState("");

  const customPromptRef = useRef(null);
  const textTransformRef = useRef(null);
  const characterCountRef = useRef(null);

  useEffect(() => {
    const nextActionStepNew = steps.find((step) => !step.completed);
    const systemPrompt = [
      <span
        key="customPrompt"
        className="highlight"
        onClick={() => {
          setAdvancedSettingsOpen(true);
          setTimeout(() => customPromptRef?.current?.focus(), 50);
        }}
      >
        {artistAiConfig?.custom_system_prompt || "Add custom prompt"}
      </span>,
      ` Speak to fan in `,
      <span
        key="characterCount"
        className="highlight"
        onClick={() => {
          setAdvancedSettingsOpen(true);
          setTimeout(() => characterCountRef?.current?.focus(), 50);
        }}
      >{`<${artistAiConfig?.character_count} character`}</span>,
      ` messages. `,
      <span
        key="textTransform"
        className="highlight"
        onClick={() => {
          setAdvancedSettingsOpen(true);
          setTimeout(() => textTransformRef?.current?.focus(), 50);
        }}
      >
        {artistAiConfig?.text_transform === "Capitalize"
          ? ""
          : "Type in mostly lowercase text."}
      </span>,
      ` When natural and relevant, convert them to `,
      <span
        key="nextActionStepPrompt"
        className="highlight"
        onClick={() => onHighlightStep(nextActionStepNew?.id)}
      >{` ${
        nextActionStepNew?.prompt + ": " || "engage in lively conversation."
      } `}</span>,
      <span
        key="nextActionStepLink"
        className="highlight"
        onClick={() => onHighlightStep(nextActionStepNew?.id)}
      >
        {nextActionStepNew?.link?.redirect_url}
      </span>,
    ];
    setPreviewPrompt(systemPrompt);
  }, [artistAiConfig, locationData, steps, onHighlightStep]);

  return (
    <>
      <div className="d-flex flex-row mb-2 align-items-center">
        <div
          className="d-flex align-items-center justify-content-center fw-bold"
          style={{
            backgroundColor: "#36206e",
            borderRadius: "100%",
            width: 40,
            height: 40,
            color: "white",
            marginRight: 10,
          }}
        >
          1
        </div>
        <h5
          className="text-dark py-2 d-flex"
          style={{ flex: 3, marginBottom: 0 }}
        >
          Tell your Avatar how it should communicate with your fans
        </h5>
      </div>
      <div className="p-4 rounded-4" style={{ backgroundColor: "white" }}>
        {previewPrompt}
      </div>
      <div className="border rounded-2 px-4 py-2 border-lightgray mt-4">
        <div
          className="d-flex flex-row align-items-center"
          style={{ cursor: "pointer" }}
          onClick={() => setAdvancedSettingsOpen(!advancedSettingsOpen)}
        >
          <h5 className="text-dark mt-2 p-2">Configuration Options</h5>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
        {advancedSettingsOpen && (
          <>
            <p style={{ fontStyle: "italic", fontWeight: "bold" }}>
              *For advanced users only* This will override your default
              settings.
            </p>
            <Form>
              <Form.Group className="mb-2">
                <Form.Label>Custom Prompt</Form.Label>
                <Form.Control
                  ref={customPromptRef}
                  as="textarea"
                  rows={3}
                  value={artistAiConfig?.custom_system_prompt}
                  onChange={(e) =>
                    onUpdateConfig("custom_system_prompt", e?.target?.value)
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>
                  How should your Avatar follow up with your fans?
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={artistAiConfig?.follow_up_prompt}
                  onChange={(e) =>
                    onUpdateConfig("follow_up_prompt", e?.target?.value)
                  }
                />
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>Text Transform</Form.Label>
                <Form.Control
                  ref={textTransformRef}
                  className="custom-select"
                  as="select"
                  value={artistAiConfig?.text_transform}
                  onChange={(e) =>
                    onUpdateConfig("text_transform", e?.target?.value)
                  }
                >
                  <option value="">Select a text transform</option>
                  <option value="Lowercase">Lowercase</option>
                  <option value="Capitalize">Capitalize</option>
                </Form.Control>
              </Form.Group>

              <Form.Group className="mb-2">
                <Form.Label>
                  Character Count: {artistAiConfig?.character_count}
                </Form.Label>
                <Form.Control
                  ref={characterCountRef}
                  type="range"
                  min={20}
                  max={150}
                  value={artistAiConfig?.character_count}
                  onChange={(e) =>
                    onUpdateConfig("character_count", e?.target?.value)
                  }
                />
              </Form.Group>
              <Form.Group className="mb-2">
                <Form.Label>ArtistAI Model</Form.Label>
                <div style={{ display: "flex", justifyContent: "start" }}>
                  <ToggleButtonGroup
                    type="radio"
                    name="modelToggle"
                    value={artistAiConfig?.gpt_model}
                  >
                    <ToggleButton
                      variant="outline-info"
                      className={
                        artistAiConfig?.gpt_model === "gpt-3.5-turbo"
                          ? "activeToggle"
                          : "inactiveToggle"
                      }
                      value="gpt-3.5-turbo"
                      id="turbo"
                      onChange={(e) =>
                        onUpdateConfig("gpt_model", e?.target?.value)
                      }
                    >
                      Turbo
                    </ToggleButton>
                    <ToggleButton
                      variant="outline-info"
                      className={
                        artistAiConfig?.gpt_model === "gpt-4-turbo"
                          ? "activeToggle"
                          : "inactiveToggle"
                      }
                      value="gpt-4-turbo"
                      id="power"
                      onChange={(e) =>
                        onUpdateConfig("gpt_model", e?.target?.value)
                      }
                    >
                      Power
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Form.Group>
            </Form>
          </>
        )}
      </div>
    </>
  );
};
export default AdvancedSettings;
