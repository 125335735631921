// Convert hex to RGB
function hexToRgb(hex) {
  let bigint = parseInt(hex.slice(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return [r, g, b];
}

function hexToHsl(hex) {
  let [r, g, b] = hexToRgb(hex);
  [r, g, b] = [r / 255, g / 255, b / 255];
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const delta = max - min;
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);
    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  return [h * 360, s * 100, l * 100]; // values in range: h[0-360], s[0-100], l[0-100]
}

// Lighten color using HSL
function lightenColor(hex, percent) {
  const [h, s, l] = hexToHsl(hex);
  const newL = l + (percent / 100) * l; // Adjust lightness based on percentage
  return `hsla(${h}, ${s}%, ${newL}%, 1)`; // Return as HSLA string
}

// Function to generate gradient
function generateGradient(hex) {
  const lightenedColor = lightenColor(hex, -5); // 20% lighter for demonstration; you can adjust this value
  return `linear-gradient(to left, ${lightenedColor}, ${hex})`;
}

export { generateGradient, lightenColor };
